export default {
	SALARY_EXPORT_FORMAT: {
		AUS: 'aus',
		DEU: 'deu',
		DNK: 'dnk',
		FIN: 'fin',
		SWE: 'swe',
		NOR: 'nor',
		GBR: 'gbr',
		USA: 'usa',
		BEL: 'bel',
	},
	RECEIPT_TYPE: {
		DEFAULT: 'Default',
		SAUDI_ARABIC: 'Saudi Arabic',
	},
};
