import React from 'react';
import PropTypes from 'prop-types';

// components
import { Input, Icon, Button, ButtonGroup, Loader } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';
import HomeScreenRecommendationsDroppableCell from './components/homeScreenRecommendationsDroppableCell/homeScreenRecommendationsDroppableCell';

// phrases/ constants
import phrases from './homeScreenRecommendationsTable.phrases';
import constants from 'services/constants';

// lodash
import _isEmpty from 'lodash/isEmpty';
import _debounce from 'lodash/debounce';

// styles
import './homeScreenRecommendationsTable.css';

const HomeScreenRecommendationsTable = ({
	layoutName,
	timeSlots,
	editTileLayoutHomeScreenRecommendations,
	onEditClick,
	onDeleteClick,
	addHomeScreenRecommendations,
	deleteTimeSlot,
	productSlotToOcupy,
	homeScreenRecommendationProductLoading,
	homeScreenRecommendationDescriptionLoading,
}) => {
	const getTimeSlotTime = (time) => {
		return `${moment
			.utc(time.from, 'HH:mm:ss')
			.format(constants.timeShort)} - ${moment
			.utc(time.to, 'HH:mm:ss')
			.format(constants.timeShort)}`;
	};

	const onDrop = ({ item, productNumber, homeScreenRecommendationId }) => {
		const payload = {
			id: homeScreenRecommendationId,
			[`product_${productNumber}`]: item.id,
		};

		editTileLayoutHomeScreenRecommendations(payload, productNumber);
	};

	const onDescriptionChange = ({ homeScreenRecommendationId, value }) => {
		const payload = {
			id: homeScreenRecommendationId,
			description: value,
		};

		editTileLayoutHomeScreenRecommendations(payload);
	};

	const onDescriptionChangeDebounced = _debounce(onDescriptionChange, 300, {
		trailing: true,
	});

	const onRemoveProduct = ({ homeScreenRecommendationId, productNumber }) => {
		const payload = {
			id: homeScreenRecommendationId,
			[`product_${productNumber}`]: null,
		};

		editTileLayoutHomeScreenRecommendations(payload, productNumber);
	};

	const onEditClickWrapper = (timeSlot) => onEditClick(timeSlot);
	const onDeleteClickWrapper = (timeSlot) => onDeleteClick(timeSlot);
	const onAddRecommendationsWrapper = (timeSlot) => {
		deleteTimeSlot({ id: timeSlot.id });
		addHomeScreenRecommendations({ timeSlots: [timeSlot] });
	};

	return (
		<div className="home-screen-recommendations-table-wrapper">
			<TableList className="home-screen-recommendations-table-wrapper__table">
				<thead>
					<tr>
						<th
							colSpan={5}
							className="home-screen-recommendations-table-wrapper__table__header-cell--sticky-main-title home-screen-recommendations-table-wrapper__table__header-cell__main">
							{layoutName}
						</th>
					</tr>
					<tr>
						<th className="home-screen-recommendations-table-wrapper__table__header-cell--sticky-title home-screen-recommendations-table-wrapper__table__header-cell">
							{phrases.TIME}
						</th>
						<th className="home-screen-recommendations-table-wrapper__table__header-cell--sticky-title home-screen-recommendations-table-wrapper__table__header-cell">
							{phrases.GREY}
						</th>
						<th className="home-screen-recommendations-table-wrapper__table__header-cell--sticky-title home-screen-recommendations-table-wrapper__table__header-cell">
							{phrases.PURPLE}
						</th>
						<th className="home-screen-recommendations-table-wrapper__table__header-cell--sticky-title home-screen-recommendations-table-wrapper__table__header-cell">
							{phrases.YELLOW}
						</th>
						<th className="home-screen-recommendations-table-wrapper__table__header-cell--sticky-title home-screen-recommendations-table-wrapper__table__header-cell">
							{phrases.PINK}
						</th>
					</tr>
				</thead>
				<tbody>
					{!_isEmpty(timeSlots) &&
						timeSlots.map((entry, index) => (
							<React.Fragment key={index}>
								{/* time */}
								<TableListRow>
									<TableListCell
										colSpan={5}
										className="home-screen-recommendations-table-wrapper__table__header-cell__time-slot">
										{getTimeSlotTime(entry.time)}
										<ButtonGroup className="home-screen-recommendations-table-wrapper__table__header-cell__time-slot__button-group">
											<Button
												size="tiny"
												type="transparent"
												onClick={() => onEditClickWrapper(entry)}>
												<Icon name="edit" />
											</Button>
											<Button
												size="tiny"
												type="transparent"
												onClick={() => onDeleteClickWrapper(entry)}>
												<Icon name="delete" />
											</Button>
										</ButtonGroup>
									</TableListCell>
								</TableListRow>

								{_isEmpty(entry.homeScreenRecommendations) ? (
									<TableListRow>
										<TableListCell>
											{phrases.CLICK_TO_ADD_RECOMMENDATIONS}{' '}
											<Button
												shadow
												type="inverted"
												onClick={() => onAddRecommendationsWrapper(entry)}>
												<Icon name="add" />
											</Button>
										</TableListCell>
									</TableListRow>
								) : (
									<>
										{/* descriptions per tier */}
										<TableListRow>
											<TableListCell>{phrases.DESCRIPTION}</TableListCell>
											{entry.homeScreenRecommendations.map((entry, index) => (
												<TableListCell key={index}>
													<div className="home-screen-recommendations-table-wrapper__table__input-wrapper">
														<Input
															id={`recommendation-${index}`}
															placeholder={phrases.DESCRIPTION_PLACEHOLDER}
															defaultValue={entry.description}
															onChange={(e) =>
																onDescriptionChangeDebounced({
																	homeScreenRecommendationId: entry.id,
																	value: e.target.value,
																})
															}
															size="small"
														/>
														{homeScreenRecommendationDescriptionLoading &&
															homeScreenRecommendationDescriptionLoading.id ===
																entry.id && (
																<Loader
																	className="home-screen-recommendations-table-wrapper__table__input-wrapper__loader"
																	loading
																/>
															)}
													</div>
												</TableListCell>
											))}
										</TableListRow>

										{/* products per tier */}
										{[...Array(6)].map((entryTwo, indexTwo) => (
											<TableListRow key={indexTwo}>
												<TableListCell>
													{phrases.TILE} {indexTwo + 1}
												</TableListCell>
												{entry.homeScreenRecommendations.map(
													(entryThree, indexThree) => {
														const cellActive =
															productSlotToOcupy &&
															productSlotToOcupy.id === entryThree.id &&
															productSlotToOcupy.productSlot ===
																`product_${indexTwo + 1}`;
														const valueToAdd =
															cellActive && productSlotToOcupy.item;

														return (
															<HomeScreenRecommendationsDroppableCell
																key={indexThree}
																homeScreenRecommendationId={entryThree.id}
																productNumber={indexTwo + 1}
																product={entryThree[`product_${indexTwo + 1}`]}
																onDrop={onDrop}
																loading={
																	homeScreenRecommendationProductLoading &&
																	homeScreenRecommendationProductLoading.id ===
																		entryThree.id &&
																	homeScreenRecommendationProductLoading.productNumber ===
																		indexTwo + 1
																}
																cellActive={cellActive}
																valueToAdd={valueToAdd}
																removeProduct={onRemoveProduct}
															/>
														);
													}
												)}
											</TableListRow>
										))}
									</>
								)}

								<TableListRow />
							</React.Fragment>
						))}
				</tbody>
			</TableList>
		</div>
	);
};

HomeScreenRecommendationsTable.propTypes = {
	layoutName: PropTypes.string,
	timeSlots: PropTypes.array,
	editTileLayoutHomeScreenRecommendations: PropTypes.func,
	onEditClick: PropTypes.func,
	onDeleteClick: PropTypes.func,
	addHomeScreenRecommendations: PropTypes.func,
	deleteTimeSlot: PropTypes.func,
	productSlotToOcupy: PropTypes.object,
	homeScreenRecommendationProductLoading: PropTypes.object,
	homeScreenRecommendationDescriptionLoading: PropTypes.object,
};

export default HomeScreenRecommendationsTable;
