import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { fetchCompleteCollection } from 'api/fetchCompleteCollection';

/**
 * @function fetchPOSList
 * @description Fetch all productes values
 * @param {object} state
 */
export function fetchPOSList(state, filter = '') {
	let newFilter;
	if (state.filter) newFilter = state.filter;
	if (filter) newFilter = newFilter ? `${newFilter};${filter}` : filter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: newFilter,
	};

	return get(`/pos/tile_layouts`, params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editPOSList
 * @description edit
 */
export function editPOSList(posListId, data) {
	return post(`/pos/tile_layouts/${posListId}`, data)
		.then((res) => {
			setFeedback('Tile layout edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function editPOSLists
 * @description edit multiple
 */
export function editPOSLists(data) {
	return patch(`/pos/tile_layouts`, data)
		.then((res) => {
			setFeedback('Tile layouts edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function addPOSList
 * @description add
 */
export function addPOSList(data) {
	return post(`/pos/tile_layouts`, data)
		.then((res) => {
			setFeedback('Tile layout added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deletePOSList
 * @description del
 */
export function deletePOSList(productId) {
	return remove(`/pos/tile_layouts/${productId}`)
		.then((res) => {
			setFeedback('Tile layout deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function fetchLayoutTileGroups
 * @description fetch tile layout groups for list
 * @param {object} state
 */
export function fetchLayoutTileGroups(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get(`/pos/tile_layout_groups`, params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchProductProducts
 * @description fetch tile layout groups for list
 * @param {object} state
 */
export function fetchProductProducts(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get(`/product/products`, params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function submitTileLayoutTiles
 * @description edit
 */
export function submitTileLayoutTiles(data) {
	return post(`/pos/tile_layout_tiles`, data)
		.then((res) => {
			setFeedback('Tile layouts added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function fetchProductProducts
 * @description fetch tile layout groups for
 * @param {object} state
 */
export function fetchTileLayoutTiles(filter) {
	// Set params and filter
	const params = {
		limit: 300,
		filter,
	};

	return get(`/pos/tile_layout_tiles`, params)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function fetchAssets
 * @param {array} domain
 * @description Get assets
 */
export function fetchAssets({ layoutIds }) {
	const filter = `:domain=='App';(:type=='Menu Logo',:type=='Row Layout Product Detail',:type=='Row Layout Tile');(:asset_collection.products.tile_layout_tiles.tile_layout_group.tile_layout.id=IN=${layoutIds},:asset_collection.tile_layout_groups.tile_layout.id=IN=${layoutIds})`;

	return fetchCompleteCollection('/pos/assets', {
		filter,
		limit: 300,
	}).then((res) => {
		return res;
	});
}

/**
 * @function submitPositionRotations
 * @description submits new order of tile layout tiles
 */
export function submitPositionRotations(data) {
	return post('/pos/tile_layout_tiles/position_rotations', data)
		.then((res) => {
			setFeedback('Products reordered!', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
