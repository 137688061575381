'use strict';

import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './productBundleRules.phrases';

/**
 * @function fetchProductBundleRules
 * @description Fetch product bundle rules
 */
export function fetchProductBundleRules(state, filter) {
	const newFilter = state.filter ? `${state.filter};${filter}` : filter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: newFilter,
	};

	return get('/product/bundle_rules', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchGlobalBundleRules
 * @description Fetch global bundle rules
 */
export function fetchGlobalBundleRules(filter) {
	// Set params and filter
	const params = {
		filter
	};

	return get('/product/global_bundle_rules', params, null, null)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

export function addProductBundleRules(data) {
	return post('/product/bundle_rules', data)
		.then((res) => {
			setFeedback(phrases.PRODUCT_BUNDLE_RULES_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editProductBundleRules
 * @param {Object} payload - update object
 */
export function editProductBundleRules(payload) {
	return patch('/product/bundle_rules', payload)
		.then((res) => {
			setFeedback(phrases.PRODUCT_BUNDLE_RULES_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteProductBundleRule
 * @param {Number} id - id of a global bundle rule to delete
 */
export function deleteProductBundleRule(id) {
	return remove(`/product/bundle_rules/${id}`)
		.then((res) => {
			setFeedback(phrases.PRODUCT_BUNDLE_RULE_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
