'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';

import { Tabs, Input } from 'dumb';
import ProductSetOptions from './components/productSetOptions.component';

import _get from 'lodash/get';

import { setProductSet, updateProductSet, resetProductSet } from './store/productSets.actions';

import { fetchProductSet, editProductSet, addProductSet, deleteProductSet } from './store/productSets.service';

import phrases from './productSets.phrases';

// Wrapper for react-data
// Documentation can be found here https://react-table.js.org/
class ProductSets extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);

		this.state = {
			pages: null,
		};

		this.fetchData = this.fetchData.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', null),
				filterPath: ':name',
			},
		];
	}

	deleteEntry(id) {
		return deleteProductSet(id);
	}

	editEntry() {
		const { defaultValues } = this.props;

		const payload = {
			id: defaultValues.id,
			name: _get(defaultValues, 'name', ''),
		};

		return editProductSet(defaultValues.id, payload);
	}

	addEntry() {
		const { defaultValues, discount } = this.props;

		const payload = {
			discount: discount.id,
			name: _get(defaultValues, 'name', null),
		};

		return addProductSet(payload);
	}

	setInitialEditValues(data) {
		this.props.setProductSet(data);
	}

	getEditableCells() {
		const { defaultValues } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="name"
						value={_get(defaultValues, 'name', '')}
						onChange={(event) => this.editStoreEntry(event)}
					/>
				),
			},
		];
	}

	editStoreEntry(e) {
		const name = e.target ? e.target.value : e;
		this.props.updateProductSet({
			name,
		});
	}

	fetchData(state) {
		const { discount } = this.props;
		return fetchProductSet(discount.id, state);
	}

	render() {
		return (
			<ReactDataWrapper
				accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.PRODUCT_SETS}
				className="-striped -highlight"
				columns={this.columns}
				totalEntries={this.state.totalEntries} // Display the total number of pages
				fetchData={this.fetchData} // Request new data when things change
				filterable
				defaultPageSize={10}
				reduxKey={`sale/discount_product_set/${this.props.discount.id}`}
				manual
				subcomponent={(row) => {
					const tabContent = [
						{
							name: phrases.PRODUCT_VARIANT,
							component: <ProductSetOptions title={phrases.PRODUCT_VARIANT} productSet={row.original} />,
						},
					];
					return (
						<SubTable>
							<Tabs tabContent={tabContent} />
						</SubTable>
					);
				}}
				editEntry={(e) => this.editEntry(e)}
				editableCells={this.getEditableCells()}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetProductSet}
				actionsWidth={60}
				deleteEntry={this.deleteEntry}
				createEntry={this.addEntry}
			/>
		);
	}
}

ProductSets.propTypes = {
	discount: PropTypes.object,
	defaultValues: PropTypes.object,

	updateProductSet: PropTypes.func,
	setProductSet: PropTypes.func,
	resetProductSet: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setProductSet,
			updateProductSet,
			resetProductSet,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultValues: store.discountsProductSet.data.defaultProductSet,
	};
};

export default connectWithStore(ProductSets, mapStateToProps, mapDispatchToProps);
