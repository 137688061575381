import {
	UPDATE_NUTRITION_CATEGORY,
	SET_NUTRITION_CATEGORY,
	RESET_NUTRITION_CATEGORY
} from './nutritionCategories.actions';

const defaultState = {
	ui: {},
	data: {
		defaultNutritionCategories: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_NUTRITION_CATEGORY: {
		return {
			...state,
			data: {
				...state.data,
				defaultNutritionCategories: payload
			}
		};
	}

	case UPDATE_NUTRITION_CATEGORY: {
		return {
			...state,
			data: {
				...state.data,
				defaultNutritionCategories: {
					...state.data.defaultNutritionCategories,
					...payload
				}
			}
		};
	}

	case RESET_NUTRITION_CATEGORY: {
		return {
			...state,
			data: {
				...state.data,
				defaultNutritionCategories: {}
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
