export const RESET_STATE = 'HOME_SCREEN_RECOMMENDATION_TRANSLATIONS/RESET_STATE';
export const SET_HOME_SCREEN_RECOMMENDATION_TRANSLATION =
	'HOME_SCREEN_RECOMMENDATION_TRANSLATIONS/SET_HOME_SCREEN_RECOMMENDATION_TRANSLATION';
export const UPDATE_HOME_SCREEN_RECOMMENDATION_TRANSLATION =
	'HOME_SCREEN_RECOMMENDATION_TRANSLATIONS/UPDATE_HOME_SCREEN_RECOMMENDATION_TRANSLATION';

export const EDIT_BATCH_LIST_ITEM = 'HOME_SCREEN_RECOMMENDATION_TRANSLATIONS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM = 'HOME_SCREEN_RECOMMENDATION_TRANSLATIONS/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW = 'HOME_SCREEN_RECOMMENDATION_TRANSLATIONS/ADD_BATCH_LIST_ITEM_ROW';
export const RERENDER_TABLE = 'HOME_SCREEN_RECOMMENDATION_TRANSLATIONS/RERENDER_TABLE';

export function setGlobalIngredientComponentTranslation(payload) {
	return {
		type: SET_HOME_SCREEN_RECOMMENDATION_TRANSLATION,
		payload,
	};
}

export function updateGlobalIngredientComponentTranslation(payload) {
	return {
		type: UPDATE_HOME_SCREEN_RECOMMENDATION_TRANSLATION,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}

export function rerenderTable() {
	return {
		type: RERENDER_TABLE,
	};
}
