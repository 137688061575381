import React from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { setShowModal } from './store/invalidTenantAccount.actions';

// components
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// misc
import phrases from './invalidTenantAccount.phrases';

const style = (theme) => ({
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minWidth: 'initial',
	width: `calc(100% - ${theme.spacing(2)})`,
	minHeight: 200,
	bgcolor: 'background.paper',
	boxShadow: 12,
	p: 2,
	outline: 'none',
	[theme.breakpoints.up('sm')]: {
		width: 400,
		p: 5,
	},
});

const InvalidTenantAccount = () => {
	const dispatch = useDispatch();
	const { showModal } = useSelector((state) => state.invalidTenantAccount);

	const toggleModal = () => dispatch(setShowModal(!showModal));

	return (
		<Modal open={showModal} onClose={toggleModal}>
			<Box sx={style}>
				<IconButton
					sx={{
						position: 'absolute',
						top: 2,
						right: 2,
					}}
				>
					<CloseIcon onClick={toggleModal} />
				</IconButton>

				<Typography variant="h4" textAlign="center">
					{phrases.TITLE}
				</Typography>

				<Typography
					variant="body1"
					sx={{
						marginTop: 4,
					}}
					textAlign="center"
				>
					{phrases.TEXT}
				</Typography>

				<Box
					sx={{
						mt: 4,
						display: 'flex',
						justifyContent: 'center',
						align: 'center',
					}}
				>
					<Button onClick={toggleModal}>{phrases.BUTTON_TEXT}</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default InvalidTenantAccount;
