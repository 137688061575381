import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import Product from './components/product/product.component';
import ProductCategories from './components/productCategories/productCategories.component';
import ProductVariant from './components/productVariant/productVariant.component';
import ProductAddons from './components/productAddons/productAddons.component';
import ProductAddonRecommendations from './components/productAddonRecommendations/productAddonRecommendations.component';
import ProductRecommendations from './components/productRecommendations/productRecommendations.component';
import ProductBundles from './components/productBundles/productBundles.component';
import GlobalBundleRules from './components/globalBundleRules/globalBundleRules.component';

class ProductContainer extends Component {
	render() {
		const { setActiveTab } = this.props;

		return (
			<>
				<ProductCategories />
				<Product setActiveTab={setActiveTab} />
				<ProductVariant />
				<ProductRecommendations />
				<ProductAddons />
				<ProductAddonRecommendations />
				<ProductBundles />
				<GlobalBundleRules />
			</>
		);
	}
}

ProductContainer.propTypes = {
	setActiveTab: PropTypes.func,
};

export default ProductContainer;
