export default {
	TABLE_TITLE: 'Raw ingredient categories',
	RAW_INGREDIENT_CATEGORIES_ADDED: 'Raw ingredient categories added',
	RAW_INGREDIENT_CATEGORY_EDITED: 'Raw ingredient category edited',
	RAW_INGREDIENT_CATEGORIES_EDITED: 'Raw ingredient categories edited',
	RAW_INGREDIENT_CATEGORIES_DELETED: 'Raw ingredient categories deleted',

	// modal batch stuff
	MODAL_BATCH_STEP_LIST_TITLE: 'Add Raw ingredient categories',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'submit',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_LEGACY: 'Legacy',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row.'
};
