/**
 * @description Default sort order for groups in dailyReport
 */
export default {
	Adjustment: { name: 'Adjustment' },
	'Top-performer': { name: 'Top Performers (Top 25% Products)' },
	Legacy: { name: 'Legacy (>27M)' },
	Index: { name: 'Index (15M-27M)' },
	'Not Available': { name: 'Not Available' },
	'New Store': { name: 'New Stores (0M-15M)' }
};
