'use strict';

// Services
import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import ListComponent from 'common/components/list/list.component';

import CurrencyCountryToCurrencyList from './services/currencyCountryToCurrencyList.service';
import CurrencyCurrencyRatesList from './services/currencyCurrencyRatesList.service';
import CurrencyDenominations from './components/currencyDenominations/currencyDenominations.container';

const currencyCountryToCurrencyListReduxKey =
	'/administration/country_to_currency_relations';
const currencyCountryToCurrencyList = new CurrencyCountryToCurrencyList(
	currencyCountryToCurrencyListReduxKey
);
const currencyCountryToCurrencyListProps =
	currencyCountryToCurrencyList.getProps();

const currencyCurrencyRatesListReduxKey = '/administration/currency_rates';
const currencyCurrencyRatesList = new CurrencyCurrencyRatesList(
	currencyCurrencyRatesListReduxKey
);
const currencyCurrencyRatesListProps = currencyCurrencyRatesList.getProps();

export function CurrenciesDetailsContainer() {
	const { currencyId } = useParams();

	const currenciesIdFilter = `:currency.id=='${currencyId}'`;

	useEffect(() => {
		currencyCountryToCurrencyList.setFilterQuery(currenciesIdFilter);
		currencyCountryToCurrencyList.setData({ currency: currencyId });
		currencyCountryToCurrencyList.initView();

		currencyCurrencyRatesList.setFilterQuery(currenciesIdFilter);
		currencyCurrencyRatesList.setData({ currency: currencyId });
		currencyCurrencyRatesList.initView();
	}, [currencyId, currenciesIdFilter]);

	return (
		<>
			<ListComponent
				reduxKey={currencyCountryToCurrencyListReduxKey}
				{...currencyCountryToCurrencyListProps}
			/>
			<ListComponent
				reduxKey={currencyCurrencyRatesListReduxKey}
				{...currencyCurrencyRatesListProps}
			/>
			<CurrencyDenominations id={currencyId} />
		</>
	);
}
