// interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';
import { ICompanyCrud } from './types';

import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './companies.phrases';

function handleError(err: Error): Error {
	const error = formatErrorMessage(err);
	setFeedback(error, 0);

	throw error;
}

/**
 * @function fetchCompanies
 * @description Fetch all companies
 * @param {object} state
 */
export function fetchCompanies(state: IFetchDataProps): Promise<IApiRes> {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/administration/companies', params, null, state.headers)
		.then((res: IApiRes) => res)
		.catch(handleError);
}

/**
 * @function addCompanies
 * @description adds company
 */
export function addCompanies(data: ICompanyCrud): Promise<IApiRes> {
	return post('/administration/companies', data)
		.then((res: IApiRes) => {
			setFeedback(phrases.MARKET_RESPONSIBILITY_ADDED, 1);
			return res;
		})
		.catch(handleError);
}

/**
 * @function editCompanies
 * @description edits company
 */
export function editCompanies(
	id: number,
	data: Partial<ICompanyCrud>
): Promise<IApiRes> {
	return post(`/administration/companies/${id}`, data)
		.then((res: IApiRes) => {
			setFeedback(phrases.MARKET_RESPONSIBILITY_EDITED, 1);
			return res;
		})
		.catch(handleError);
}

/**
 * @function deleteCompanies
 * @description deletes companies
 */
export function deleteCompanies(id: number): Promise<IApiRes> {
	return remove(`/administration/companies/${id}`)
		.then((res: IApiRes) => {
			setFeedback(phrases.MARKET_RESPONSIBILITIY_DELETED, 1);
			return res;
		})
		.catch(handleError);
}
