/* eslint-disable react/prop-types */
import React from 'react';

// components
import { Input } from 'dumb';
import { DateFilterSelector } from 'reactDataWrapper/utilities';

// lodash
import _get from 'lodash/get';

export default function getColumns(reduxKey) {
	return [
		{
			Header: 'Name',
			accessor: 'name',
			id: 'name',
			filterPath: ':name',
		},
		{
			Header: 'Market region',
			id: 'market-region',
			accessor: (d) => _get(d, 'market_region.name', ''),
			filterPath: ':market_region.name',
		},
		{
			Header: 'Break registration',
			id: 'break-registration',
			accessor: 'break_registration',
			filterPath: ':break_registration',
			Cell: (d) => (
				<Input
					id={`${d.original.id}-break-registration`}
					type="checkbox"
					checked={d.value}
					disabled
				/>
			),
			filterable: false,
		},
		{
			Header: 'Opened',
			id: 'opened',
			accessor: 'opened',
			filterPath: ':opened',
			Filter: ({ column }) => (
				<DateFilterSelector
					reduxKey={reduxKey}
					columnId={column.id}
					dateIdentifier="from"
				/>
			),
		},
		{
			Header: 'Closed',
			accessor: 'closed',
			id: 'closed',
			filterPath: ':closed',
			Filter: ({ column }) => (
				<DateFilterSelector
					reduxKey={reduxKey}
					columnId={column.id}
					dateIdentifier="to"
				/>
			),
		},
		{
			Header: 'Sort order',
			accessor: 'sort_order',
			id: 'sort_order',
			filterPath: ':sort_order',
		},
	];
}
