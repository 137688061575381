import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './donutChart.css';

export default class DonutChart extends Component {
	render() {
		let {
			value,
			max,
			fontSize,
			valueLabel,
			strokeWidth,
			size,
			isPercent,
			color
		} = this.props;

		const halfsize = size * 0.5;
		const radius = halfsize - strokeWidth * 0.5;
		const circumference = 2 * Math.PI * radius;
		const strokeval = (value * circumference) / max;
		const dashval = strokeval + ' ' + circumference;

		const trackstyle = { strokeWidth: strokeWidth };
		const indicatorstyle = {
			strokeWidth: strokeWidth,
			strokeDasharray: dashval,
			stroke: color
		};
		const rotateval = 'rotate(-90 ' + halfsize + ',' + halfsize + ')';

		return (
			<svg width={size} height={size} className="donutchart">
				<circle
					r={radius}
					cx={halfsize}
					cy={halfsize}
					transform={rotateval}
					style={trackstyle}
					className="donutchart-track"
				/>
				<circle
					r={radius}
					cx={halfsize}
					cy={halfsize}
					transform={rotateval}
					style={indicatorstyle}
					className="donutchart-indicator"
				/>
				<text
					className="donutchart-text"
					x={halfsize}
					y={halfsize + 3}
					style={{ textAnchor: 'middle' }}>
					{value && (
						<tspan className="donutchart-text-val" style={{ fontSize }}>
							{Math.round(value)}
						</tspan>
					)}
					{isPercent && (
						<tspan
							className="donutchart-text-percent"
							style={{ fontSize: fontSize - 4 }}>
							%
						</tspan>
					)}
					{valueLabel && (
						<tspan
							className="donutchart-text-label"
							x={halfsize}
							y={halfsize + 10}>
							{valueLabel}
						</tspan>
					)}
				</text>
			</svg>
		);
	}
}

DonutChart.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	max: PropTypes.number,
	valueLabel: PropTypes.string, // label for the chart,
	strokeWidth: PropTypes.number, // width of chart line,
	size: PropTypes.number, // diameter of chart
	fontSize: PropTypes.number,
	isPercent: PropTypes.bool,
	color: PropTypes.string
};

DonutChart.defaultProps = {
	value: 50,
	max: 100,
	valueLabel: 'percentage', // label for the chart,
	strokeWidth: 26, // width of chart line,
	size: 116, // diameter of chart
	isPercent: true,
	fontSize: 22,
	color: '#009688'
};
