import { store } from 'appState';

export const TOGGLE_MODAL = 'USER_MODAL/TOGGLE_MODAL';

export const toggleModal = payload => ({ type: TOGGLE_MODAL, payload });

/**
 * @function dispatchToggleUserModal
 * @description show modal that suggests to visit hr :D
 */
export const dispatchToggleUserModal = (payload) => {
	store.dispatch(toggleModal(payload));
};
