'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, InputCleave, InputCollectionSelect } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

import _get from 'lodash/get';
import { enums } from '../../../salaryComponents.enums';

class BatchStepList extends Component {
	_onRemoveEntry(row) {
		this.props.removeBatchListItem(row.identifier);
	}

	_editListEntry(e, identifier, type) {
		const { batchList } = this.props;
		const value = e?.target?.value ?? e;
		const row = batchList.find((x) => x.identifier === identifier);

		this.props.editBatchListItem({
			...row,
			[type]: value,
		});
	}

	_editStoreDatesEntry(e, identifier, type) {
		const { batchList } = this.props;
		const value = e.target ? e.target.value : e;
		const row = batchList.find((x) => x.identifier === identifier);

		const active =
			type === 'from'
				? {
						from: value,
						to: _get(row, 'active.to', '9999-12-31'),
				  }
				: {
						from: _get(row, 'active.from', ''),
						to: value,
				  };

		this.props.editBatchListItem({
			...row,
			...{
				active,
			},
		});
	}

	render() {
		const { batchList } = this.props;

		return (
			<TableList>
				<thead>
					<tr>
						<th>#</th>
						<th>Name</th>
						<th>Salary group</th>
						<th>Global Salary Component</th>
						<th>Amount type</th>
						<th>Amount</th>
						<th>Frequency</th>
						<th>From</th>
						<th>To</th>
						<th>External export id</th>
						<th />
					</tr>
				</thead>
				<tbody>
					{batchList.map((x, i) => {
						return (
							<TableListRow key={x.identifier}>
								<TableListCell>{i + 1}</TableListCell>
								<TableListCell>
									<Input
										id={_get(x, 'id', '')}
										value={_get(x, 'name', '')}
										placeholder="Name"
										onChange={(event) =>
											this._editListEntry(event, x.identifier, 'name')
										}
									/>
								</TableListCell>
								<TableListCell>
									{_get(x, 'salary_group.name', '')}
								</TableListCell>
								<TableListCell>
									{_get(x, 'global_salary_component.name', '')}
								</TableListCell>

								<TableListCell>
									<Input
										type="select"
										id="amount-type"
										options={enums.AMOUNT_TYPE.map((item, i) => ({
											value: item,
											label: item,
										}))}
										onChange={(event) =>
											this._editListEntry(event, x.identifier, 'amount_type')
										}
										value={_get(x, 'amount_type', enums.AMOUNT_TYPE[0])}
									/>
								</TableListCell>

								<TableListCell>
									<InputCleave
										id="ammt"
										type="number"
										min={0}
										value={_get(x, 'amount', '')}
										placeholder="Amount"
										onChange={(event) =>
											this._editListEntry(event, x.identifier, 'amount')
										}
									/>
								</TableListCell>

								<TableListCell>
									<InputCollectionSelect
										id="frequency"
										options={enums.FREQUENCY.map((item, i) => ({
											value: item,
											label: item,
										}))}
										handleChange={(key, value) =>
											this._editListEntry(value, x.identifier, 'frequency')
										}
										value={_get(x, 'frequency', enums.FREQUENCY[0])}
										optionFormat={(entry) => ({
											value: entry,
											label: entry,
										})}
									/>
								</TableListCell>

								<TableListCell>
									<InputCleave
										id="active-from"
										placeholder="yyyy-mm-dd"
										options={{
											date: true,
											delimiter: '-',
											datePattern: ['Y', 'm', 'd'],
										}}
										value={_get(x, 'active.from', '')}
										onChange={(event) =>
											this._editStoreDatesEntry(event, x.identifier, 'from')
										}
									/>
								</TableListCell>
								<TableListCell>
									<InputCleave
										id="active-to"
										placeholder="yyyy-mm-dd"
										options={{
											date: true,
											delimiter: '-',
											datePattern: ['Y', 'm', 'd'],
										}}
										value={_get(x, 'active.to', '')}
										onChange={(event) =>
											this._editStoreDatesEntry(event, x.identifier, 'to')
										} // DEFAULT VALUE 9999-12-31
									/>
								</TableListCell>

								<TableListCell>
									<Input
										id="am-type"
										value={_get(x, 'external_export_id', '')}
										placeholder="External export ID"
										onChange={(event) =>
											this._editListEntry(
												event,
												x.identifier,
												'external_export_id'
											)
										}
									/>
								</TableListCell>
								<TableListCell>
									<Button
										type="negative"
										size="tiny"
										onClick={() => this._onRemoveEntry(x)}>
										<span className="icon icon--delete" />
									</Button>
								</TableListCell>
							</TableListRow>
						);
					})}
				</tbody>
			</TableList>
		);
	}
}

BatchStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

BatchStepList.defaultProps = {};

export default BatchStepList;
