'use strict';

import { ReportFactory as R } from 'report/factory';
import {
	ReportEndpointDataProvider,
	ReportDownloadCsvDataProvider,
} from 'report/dataProviders';
import { getParameters } from './../../auditReports.methods';
import moment from 'moment';

import {
	AuditSalaryAccountReportTable,
	AuditSalaryAccountReportWorkplaceTable,
} from './components';

import endpoints from 'endpoints.service';

/// ////////////////////////
// Audit Salary Accounts //
/// ////////////////////////

// Data provider
const AuditSalaryAccountsDataProvider = new ReportEndpointDataProvider(
	'/reporting/audit_salary_account_reports'
);
const AuditSalaryAccountsDownloadCsvDataProvider =
	new ReportDownloadCsvDataProvider(
		'CSV',
		endpoints.collection.reporting.audit_salary_account_reports,
		'audit_salary_account_reports'
	);

// Report
const AuditSalaryAccountsReport = R.report(
	'auditSalaryAccountsReport',
	AuditSalaryAccountsDataProvider
);

// Report metadata
const m = R.metadata();
AuditSalaryAccountsReport.setMetadata(
	m.metadata(
		m.title('Salary accounts audit'),
		m.subtitle(
			(d) =>
				`${moment(R.data(d, 'period.from')).format('DD-MM-YYYY')} - ${moment(
					R.data(d, 'period.to')
				).format('DD-MM-YYYY')}`
		),
		m.printTitle((d) => R.data(d, 'name'))
	)
);

AuditSalaryAccountsReport.setParameterMethod(getParameters);
AuditSalaryAccountsReport.addCsvDataProvider(
	AuditSalaryAccountsDownloadCsvDataProvider
);

// Components
AuditSalaryAccountsReport.setComponents({
	AuditSalaryAccountReportTable,
	AuditSalaryAccountReportWorkplaceTable,
});

export default AuditSalaryAccountsReport;
