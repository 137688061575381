'use strict';

import {
	RESET_STATE,
	UPDATE_BATCH_FORM,
	ADD_BATCH_LIST,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
} from './productVariantDiscountSet.actions';

const defaultState = {
	data: {
		discountSetData: {},
		batchFormData: {
			availableInApp: true,
		},
		batchList: [],
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case UPDATE_BATCH_FORM: {
			return {
				...state,
				data: {
					...state.data,
					batchFormData: {
						...state.data.batchFormData,
						...payload,
					},
				},
			};
		}

		case ADD_BATCH_LIST: {
			return {
				...state,
				data: {
					...state.data,
					batchList: payload,
				},
			};
		}

		case EDIT_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchList: state.data.batchList.map((entry) => {
						if (entry.identifier === payload.identifier) return payload;
						return entry;
					}),
				},
			};
		}

		case REMOVE_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchList: state.data.batchList.filter(
						(entry) => entry.identifier !== payload
					),
				},
			};
		}

		case RESET_STATE: {
			return defaultState;
		}

		default:
			return state;
	}
}

export default reducer;
