'use strict';

import _isEmpty from 'lodash/isEmpty';
import _findIndex from 'lodash/findIndex';
import _pullAt from 'lodash/pullAt';

/**
 * @function toggleFilterBarElement
 * @memberOf SERVICES.__models
 * @description
 * Toggles filter bar element based on element existance in this.settings.filterBar
 * @param  {object} header  header to toggle filterBar in
 * @param  {string} filterId  unique filter id
 */

module.exports = function (header, filterId) {
	return new Promise((resolve) => {
		// check if filterBar array exists on header, else add it
		header.filterBar = !_isEmpty(header.filterBar) ? header.filterBar : [];

		// check for unique filter combination in filter array
		var filterIndex = _findIndex(header.filterBar, function (f) {
			return f.filterId === filterId;
		});

		// is the unique filter combination (header.name + operator) already in use?
		// remove (toggle) if already being used
		if (filterIndex > -1) _pullAt(header.filterBar, filterIndex);
		// else push to filterBar array as new filter
		else header.filterBar.push({ filterId: filterId });

		// Resolve
		resolve(header);
	});
};
