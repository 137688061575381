import { SET_DATA, RESET_DATA } from './shiftDetailsModal.actions';

const initialState = {
	data: {},
};

function reducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_DATA:
			return {
				...state,
				data: {
					...state.data,
					...payload,
				},
			};

		case RESET_DATA:
			return initialState;

		default:
			return state;
	}
}

export default reducer;
