'use strict';

/**
 * @function runModelCallbacks
 * @memberOf SERVICES.__models
 * @description
 * Runs callbacks
 */

import _each from 'lodash/each';

module.exports = function () {
	var settings = this.getSettings();
	if (settings.callbacks.length > 0) {
		setTimeout(function () {
			_each(settings.callbacks, function (callback) {
				callback();
			});
		});
	}
};
