export function markFoundDiscountSets({ filteredDiscountSets, discountSets }) {
	return discountSets.map((discountSet) => {
		const foundFilteredDiscountSet = filteredDiscountSets.some(
			(filteredDiscountSet) => filteredDiscountSet.id === discountSet.id
		);

		return {
			...discountSet,
			productHasDiscountSet: foundFilteredDiscountSet,
		};
	});
}
