import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from 'dumb/report';
import constants from 'dumb/report/constants';
import _get from 'lodash/get';
import _isFinite from 'lodash/isFinite';
import moment from 'moment';

// import _padStart from 'lodash/padStart';

const stableDate = (stable, val) => {
	return stable && !stable.is_stable
		? moment(stable.stable_date).subtract(1, 'months').format('MMM-YY')
		: val;
};

const getSoc = (d, reportType) => {
	const soc = _get(
		d,
		'next_twelve_months.store_operational_contribution_percentage'
	);
	if (_isFinite(soc)) {
		const bodyStyles = window.getComputedStyle(document.body);

		if (soc >= 15)
			return { color: `rgb(${bodyStyles.getPropertyValue('--success')})` };
		else if (soc < 0)
			return { color: `rgb(${bodyStyles.getPropertyValue('--danger')})` };
		else if (soc < 15)
			return { color: `rgb(${bodyStyles.getPropertyValue('--warning')})` };
	}
};

const getDeltaStyle = (delta, reportType) => {
	if (_isFinite(delta)) {
		const bodyStyles = window.getComputedStyle(document.body);

		if (delta >= 5)
			return { color: `rgb(${bodyStyles.getPropertyValue('--success')})` };
		else if (delta <= -5)
			return { color: `rgb(${bodyStyles.getPropertyValue('--danger')})` };
	}
};

const DailyReportBaseModel = ({ entry, reportType }) => {
	return (
		<>
			<TableCell content={_get(entry, 'name', null)} alignLeft width="160px" />
			<TableCell
				cellType={constants.CELL_TYPES.INTEGER}
				alignRight
				width="32px"
				content={_get(entry, 'retail_size', null)}
			/>

			{/* TURNOVER WEEK DAYS 14 */}
			<TableCell
				cellType={constants.CELL_TYPES.INTEGER}
				width="60px"
				alignRight
				separate
				content={_get(entry, 'turnover_weekdays.monday', '')}
			/>
			<TableCell
				cellType={constants.CELL_TYPES.INTEGER}
				width="60px"
				alignRight
				content={_get(entry, 'turnover_weekdays.tuesday', '')}
			/>
			<TableCell
				cellType={constants.CELL_TYPES.INTEGER}
				width="60px"
				alignRight
				content={_get(entry, 'turnover_weekdays.wednesday', '')}
			/>
			<TableCell
				cellType={constants.CELL_TYPES.INTEGER}
				width="60px"
				alignRight
				content={_get(entry, 'turnover_weekdays.thursday', '')}
			/>
			<TableCell
				cellType={constants.CELL_TYPES.INTEGER}
				width="60px"
				alignRight
				content={_get(entry, 'turnover_weekdays.friday', '')}
			/>
			<TableCell
				cellType={constants.CELL_TYPES.INTEGER}
				width="60px"
				alignRight
				content={_get(entry, 'turnover_weekdays.saturday', '')}
			/>
			<TableCell
				cellType={constants.CELL_TYPES.INTEGER}
				width="60px"
				alignRight
				content={_get(entry, 'turnover_weekdays.sunday', '')}
			/>

			{/* Month Performance 4 */}
			<TableCell
				cellType={constants.CELL_TYPES.INTEGER}
				width="60px"
				alignRight
				separate
				content={_get(entry, 'month_performance.month_to_date', '')}
			/>
			<TableCell
				cellType={constants.CELL_TYPES.INTEGER}
				width="60px"
				alignRight
				content={_get(entry, 'month_performance.end_of_month', '')}
			/>

			<TableCell
				cellType={constants.CELL_TYPES.INTEGER}
				width="70px"
				alignRight
				content={_get(entry, 'month_performance.start_of_month', null)}
			/>
			<TableCell
				cellType={constants.CELL_TYPES.INTEGER}
				width="36px"
				alignRight
				content={_get(entry, 'month_performance.start_of_month_delta', null)}
			/>

			{/* next_twelve_months 10 */}
			<TableCell
				cellType={constants.CELL_TYPES.INTEGER}
				width="232px" // this might not be necessary -> we need to remove this once we add columns back
				// width="80px"
				alignRight
				separate
				content={_get(entry, 'next_twelve_months.turnover', '')}
			/>
			{/* <TableCell
				cellType={constants.CELL_TYPES.PERCENTAGE}
				alignRight
				width="36px"
				style={getSoc(entry, reportType)}
				content={_get(entry, 'next_twelve_months.location_cost', '')}
			/>

			<TableCell
				cellType={constants.CELL_TYPES.INTEGER}
				width="80px"
				alignRight
				content={_get(
					entry,
					'next_twelve_months.store_operational_contribution',
					''
				)}
			/>

			<TableCell
				cellType={constants.CELL_TYPES.PERCENTAGE}
				alignRight
				width="36px"
				content={_get(
					entry,
					'next_twelve_months.store_operational_contribution_percentage',
					''
				)}
			/> */}
			{/* Week SSS 3 */}

			<TableCell
				cellType={constants.CELL_TYPES.STRING}
				separate
				alignRight
				width="36px"
				style={
					entry.stable &&
					!entry.stable.is_stable && {
						color: `rgb(${window
							.getComputedStyle(document.body)
							.getPropertyValue('--grey-300')})`,
					}
				}
				content={stableDate(
					entry.stable,
					entry.week_same_store_sales.last_four_weeks
				)}
			/>

			<TableCell
				cellType={constants.CELL_TYPES.STRING}
				alignRight
				width="36px"
				style={
					entry.stable &&
					!entry.stable.is_stable && {
						color: `rgb(${window
							.getComputedStyle(document.body)
							.getPropertyValue('--grey-300')})`,
					}
				}
				content={stableDate(
					entry.stable,
					entry.week_same_store_sales.last_eight_weeks
				)}
			/>

			<TableCell
				cellType={constants.CELL_TYPES.STRING}
				alignRight
				width="36px"
				style={
					entry.stable &&
					!entry.stable.is_stable && {
						color: `rgb(${window
							.getComputedStyle(document.body)
							.getPropertyValue('--grey-300')})`,
					}
				}
				content={stableDate(
					entry.stable,
					entry.week_same_store_sales.last_twelve_weeks
				)}
			/>

			{/* Month same store sales 14 */}
			<TableCell
				cellType={constants.CELL_TYPES.STRING}
				width="36px"
				alignRight
				style={
					entry.stable &&
					!entry.stable.is_stable && {
						color: `rgb(${window
							.getComputedStyle(document.body)
							.getPropertyValue('--grey-300')})`,
					}
				}
				separate
				content={stableDate(
					entry.stable,
					entry.month_same_store_sales.month_to_date
				)}
			/>
			<TableCell
				cellType={constants.CELL_TYPES.STRING}
				width="36px"
				alignRight
				style={
					entry.stable &&
					!entry.stable.is_stable && {
						color: `rgb(${window
							.getComputedStyle(document.body)
							.getPropertyValue('--grey-300')})`,
					}
				}
				content={stableDate(
					entry.stable,
					entry.month_same_store_sales.end_of_month
				)}
			/>
			<TableCell
				cellType={constants.CELL_TYPES.STRING}
				width="36px"
				alignRight
				style={
					entry.stable &&
					!entry.stable.is_stable && {
						color: `rgb(${window
							.getComputedStyle(document.body)
							.getPropertyValue('--grey-300')})`,
					}
				}
				content={stableDate(
					entry.stable,
					entry.month_same_store_sales.last_three_months
				)}
			/>
			<TableCell
				cellType={constants.CELL_TYPES.STRING}
				width="36px"
				alignRight
				style={
					entry.stable &&
					!entry.stable.is_stable && {
						color: `rgb(${window
							.getComputedStyle(document.body)
							.getPropertyValue('--grey-300')})`,
					}
				}
				content={stableDate(
					entry.stable,
					entry.month_same_store_sales.last_six_months
				)}
			/>
			<TableCell
				cellType={constants.CELL_TYPES.STRING}
				width="36px"
				alignRight
				style={
					entry.stable &&
					!entry.stable.is_stable && {
						color: `rgb(${window
							.getComputedStyle(document.body)
							.getPropertyValue('--grey-300')})`,
					}
				}
				content={stableDate(
					entry.stable,
					entry.month_same_store_sales.last_twelve_months
				)}
			/>
			{/* <TableCell
						cellType={constants.CELL_TYPES.STRING}
						width="36px"
						alignRight
						style={
							entry.stable &&
							!entry.stable.is_stable && {
								color: `rgb(${window
									.getComputedStyle(document.body)
									.getPropertyValue('--grey-300')})`
							}
						}
						content={stableDate(
							entry.stable,
							entry.month_same_store_sales.next_twelve_months
						)}
					/> */}

			{/* YoY Growth 10 */}
			{/* <TableCell
						cellType={constants.CELL_TYPES.INTEGER}
						width="41px"
						alignRight
						separate
						content={_get(entry, 'year_over_year_growth.year_to_date', '')}
					/>
					<TableCell
						cellType={constants.CELL_TYPES.INTEGER}
						alignRight
						width="41px"
						content={_get(
							entry,
							'year_over_year_growth.next_twelve_months',
							''
						)}
					/> */}

			{/* Averages 5 */}
			<TableCell
				cellType={constants.CELL_TYPES.STRING}
				alignRight
				separate
				width="52px"
				content={() => {
					const transactions = entry.average_sales_stats.transactions
						? entry.average_sales_stats.transactions
						: 0;

					const transactionIndex = entry.average_sales_stats.transaction_index
						? entry.average_sales_stats.transaction_index
						: 0;

					if (transactionIndex === 0 && transactions === 0) return null;

					return `${transactions} / ${transactionIndex}`;
				}}
			/>

			<TableCell
				cellType={constants.CELL_TYPES.STRING}
				alignRight
				width="52px"
				content={() => {
					const productIndex = entry.average_sales_stats.product_index
						? entry.average_sales_stats.product_index
						: 0;

					const products = entry.average_sales_stats.products
						? entry.average_sales_stats.products
						: 0;

					if (productIndex === 0 && products === 0) return null;

					return `${products} / ${productIndex}`;
				}}
			/>
			<TableCell
				cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
				alignRight
				width="36px"
				content={_get(entry, 'average_sales_stats.basket', '')}
			/>
			{/* <TableCell
						cellType={constants.CELL_TYPES.INTEGER}
						alignRight
						width="36px"
						content={_get(entry, 'average_sales_stats.pos_turnover', '')}
					/>
					<TableCell
						cellType={constants.CELL_TYPES.INTEGER}
						alignRight
						width="36px"
						content={_get(entry, 'average_sales_stats.app_turnover', '')}
					/> */}
		</>
	);
};

DailyReportBaseModel.defaultProps = {
	entry: {},
};

DailyReportBaseModel.propTypes = {
	entry: PropTypes.object,
	reportType: PropTypes.string,
};

export default React.memo(DailyReportBaseModel);
