export const SET_WORKPLACE_POS_CONFIGURATION =
	'ORGANISATION_WORKPLACE_DETAILS_WORKPLACE_POS_CONFIGURATION/SET_WORKPLACE_POS_CONFIGURATION';
export const UPDATE_WORKPLACE_POS_CONFIGURATION =
	'ORGANISATION_WORKPLACE_DETAILS_WORKPLACE_POS_CONFIGURATION/UPDATE_WORKPLACE_POS_CONFIGURATION';

export function setWorkplacePosConfiguration(payload) {
	return {
		type: SET_WORKPLACE_POS_CONFIGURATION,
		payload,
	};
}

export function updateWorkplacePosConfiguration(payload) {
	return {
		type: UPDATE_WORKPLACE_POS_CONFIGURATION,
		payload,
	};
}
