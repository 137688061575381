'use strict';

import store from 'appState/store';
import { push } from 'redux-first-history';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');

export default class TurnoverAchievementsList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'badge',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'sort_order',
				type: 'string',
				canAdd: true,
				canEdit: true,
			},
		];

		this.rowActions = function (props, row) {
			return [
				{
					method: props.methods.handleSubNavigate.bind(this, row.id),
					icon: 'icon--arrow_forward',
					validExpressions: [],
					name: 'Details',
				},
			];
		};

		this.settings = {
			name: 'TurnoverAchievementsList',
			canAdd: true,
			canEdit: true,
			canCollapse: true,
			canDelete: true,
			alwaysRefresh: true,
			translationPath: 'REPORTING.TURNOVER_ACHIEVEMENTS',
			canManipulate: () => validAccess(undefined, ['Global BI Configuration']),

			endpoint: __endpoints.collection.reporting.turnover_achievements,
		};
		this.init();
		return this;
	}

	/**
	 * @function handleSubNavigate
	 * @description
	 * Handles navigation to sub pages
	 */
	handleSubNavigate(id) {
		store.dispatch(push(`${window.location.pathname}/${id}`));
	}

	/**
	 * @function extendMethods
	 * @description
	 * Extend methods object from listModel
	 */
	extendMethods() {
		return Object.assign(this.getMethods(), {
			handleSubNavigate: this.handleSubNavigate.bind(this),
		});
	}

	/**
	 * @function getProps
	 * @description
	 * Overwrite getProps with extended methods
	 */
	getProps() {
		return Object.assign(this.getState(), {
			methods: this.extendMethods(),
			settings: this.getSettings(),
			translations: this.getTranslations(),
		});
	}
}
