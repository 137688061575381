'use strict';

// Services
import DetailsModel from 'common/components/details/detailsModel.service';
import { validAccess } from 'accessControl';
const __endpoints = require('endpoints.service');
const __enums = require('enums.service');

export default class TrainingModulesDetails extends DetailsModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'sort_order',
				type: 'number',
				canAdd: true,
				canEdit: true,
			},
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'type',
				type: 'select',
				canAdd: true,
				canEdit: true,
				required: true,
				options: {
					enums: __enums.trainingModules,
				},
			},
			{
				name: 'grade_scale',
				type: 'select',
				canAdd: true,
				canEdit: true,
				required: true,
				reference: {
					key: 'name',
					list: '/training/grade_scales',
				},
			},
			{
				name: 'moneyball_position',
				type: 'select',
				canAdd: true,
				canEdit: true,
				required: false,
				reference: {
					key: 'name',
					list: '/hr/moneyball_positions',
				},
			},
			{
				name: 'badge_category',
				type: 'select',
				canAdd: true,
				canEdit: true,
				required: false,
				reference: {
					key: 'name',
					list: '/hr/badge_categories',
				},
			},
			{
				name: 'module_category',
				type: 'select',
				canAdd: true,
				canEdit: true,
				required: false,
				reference: {
					key: 'name',
					translationKey: 'MODULE_CATEGORY',
					list: '/training/module_categories',
				},
			},
		];

		this.settings = {
			name: 'TrainingModules',
			canAdd: true,
			canEdit: true,
			scrollWrapper: false,
			canDelete: true,
			canManipulate: () => validAccess(undefined, ['Global BI Configuration']),
			translationPath: 'TRAINING.MODULES',
			endpoint: __endpoints.collection.training.modules,
		};
		this.init();
		return this;
	}
}
