'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setWaitingTimeSchedule,
	updateWaitingTimeSchedule,
	resetWaitingTimeSchedule,
} from './store/waitingTimeSchedules.actions';

// services
import {
	fetchWaitingTimeSchedules,
	editWaitingTimeSchedule,
	editWaitingTimeSchedules,
	addWaitingTimeSchedule,
	deleteWaitingTimeSchedule,
} from './waitingTimeSchedules.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';
import { Input, Button, Icon } from 'dumb';

// sub table
import WaitingTimeScheduleEntries from './components/waitingTimeScheduleEntries/waitingTimeScheduleEntries.component';

import WaitingTimeSchedulesModalBatch from './components/batch/waitingTimeSchedulesModalBatch';

// phrases
import phrases from './waitingTimeSchedules.phrases';

const reduxKey = '/app/waiting_time_schedules';

class WaitingTimeSchedules extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.addWaitingTimeScheduleWrapper = this.addWaitingTimeScheduleWrapper.bind(
			this
		);
		this.fetchData = this.fetchData.bind(this);
		this.editEntry = this.editEntry.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: 'name',
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: 'description',
				filterPath: ':description',
			},
		];
	}

	editEntry() {
		const { defaultWaitingTimeSchedule } = this.props;

		const payload = {
			id: defaultWaitingTimeSchedule.id,
			name: defaultWaitingTimeSchedule.name,
			description: defaultWaitingTimeSchedule.description,
		};

		return editWaitingTimeSchedule(payload);
	}

	editMultiple(selectedRows) {
		const { defaultWaitingTimeSchedule } = this.props;

		const payload = {
			...(defaultWaitingTimeSchedule.name && {
				name: defaultWaitingTimeSchedule.name,
			}),
			...(defaultWaitingTimeSchedule.description && {
				description: defaultWaitingTimeSchedule.description,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editWaitingTimeSchedules({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteWaitingTimeSchedule(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
		};

		this.props.setWaitingTimeSchedule(payload);
	}

	getEditableCells() {
		const { defaultWaitingTimeSchedule } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={defaultWaitingTimeSchedule.name || ''}
						onChange={(event) => this.editStoreEntry('name', event)}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={defaultWaitingTimeSchedule.description || ''}
						onChange={(event) => this.editStoreEntry('description', event)}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const value = e.target.value;

		const updateObject = {
			[name]: value,
		};

		this.props.updateWaitingTimeSchedule(updateObject);
	}

	fetchData(state) {
		return fetchWaitingTimeSchedules(state);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetWaitingTimeSchedule();
	}

	addWaitingTimeScheduleWrapper(data) {
		return addWaitingTimeSchedule(data).then(() => {
			this.setState(() => ({
				key: moment.utc(),
			}));
		});
	}

	render() {
		const {
			batchList,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
		} = this.props;

		return (
			<>
				<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={reduxKey}
					manual
					subcomponent={(row) => {
						return (
							<SubTable>
								<WaitingTimeScheduleEntries
									waitingTimeSchedule={row.original}
								/>
							</SubTable>
						);
					}}
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetWaitingTimeSchedule}
					actionRender={
						<Button
							type="inverted"
							label="Batch"
							shadow
							onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
				/>

				<WaitingTimeSchedulesModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addWaitingTimeSchedule={this.addWaitingTimeScheduleWrapper}
				/>
			</>
		);
	}
}

WaitingTimeSchedules.propTypes = {
	defaultWaitingTimeSchedule: PropTypes.object,
	updateWaitingTimeSchedule: PropTypes.func,
	setWaitingTimeSchedule: PropTypes.func,
	resetWaitingTimeSchedule: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			editBatchListItem,
			removeBatchListItem,
			updateWaitingTimeSchedule,
			setWaitingTimeSchedule,
			resetWaitingTimeSchedule,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultWaitingTimeSchedule:
			store.waitingTimeSchedules.data.defaultWaitingTimeSchedule,
		batchList: store.waitingTimeSchedules.data.batchList,
	};
};

export default connectWithStore(
	WaitingTimeSchedules,
	mapStateToProps,
	mapDispatchToProps
);
