import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// eslint-disable-next-line react/display-name
export const withNavigateAndLocation = (WrappedComponent) => (props) => {
	const navigate = useNavigate();
	const location = useLocation();

	return <WrappedComponent navigate={navigate} location={location} {...props} />;
};

export default withNavigateAndLocation;
