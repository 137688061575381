'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _mapKeys from 'lodash/mapKeys';

class GoldModelHeaders extends Component {
	constructor(props) {
		super(props);
	}

	renderHeaders(firstRow) {
		// Headers array to be returned
		let headers = [];

		// Map keys from firstRow object
		_mapKeys(firstRow.dates, (cell, key) => {
			headers.push(
				<th key={key} className="list__header-cell">
					<span className="list__cell-contents">{key}</span>
				</th>
			);
		});

		return headers;
	}

	render() {
		const { firstRow } = this.props;
		return (
			<thead>
				<tr>{this.renderHeaders(firstRow)}</tr>
			</thead>
		);
	}
}

GoldModelHeaders.propTypes = {
	firstRow: PropTypes.object.isRequired
};

export default GoldModelHeaders;
