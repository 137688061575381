import React from 'react';

// components
import CsvDateRangeContainer from './../../../../common/shared/reusableComponents/list/csvDateRange/csvDateRange.container';

// services
import { fetchMarketTipsCsv } from './../markets.service';

type Props = {
	marketId: number;
	visible: boolean;
	onClose: () => void;
};

const MarketTips = ({ onClose, marketId, visible }: Props) => {
	const handleCSVExport = ({ from, to }: { from: string; to: string }) => {
		return fetchMarketTipsCsv({ marketId, from, to }).then(() => {
			onClose();
		});
	};

	return (
		<CsvDateRangeContainer
			handleCSVExport={handleCSVExport}
			handleClose={onClose}
			visible={visible}
		/>
	);
};

export default MarketTips;
