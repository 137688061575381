import React from 'react';
import GoldModel from 'finance/goldModel/components/goldModel/goldModel.component.js';
import GoldModelService from 'finance/goldModel/services/goldModel.service.js';

const goldModelService = new GoldModelService();
const goldModelServiceProps = goldModelService.getProps();

function GoldModelPage() {
	return <GoldModel {...goldModelServiceProps} />;
}

export default GoldModelPage;
