'use strict';

import {
	SET_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT,
	UPDATE_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT,
	RESET_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT,
} from './posConfigurationDiscountProductVariants.actions';

const defaultState = {
	ui: {},
	data: {
		posConfigurationDiscountProductVariantAssociatedDiscount: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT: {
			return {
				...state,
				data: {
					posConfigurationDiscountProductVariantAssociatedDiscount: payload,
				},
			};
		}

		case UPDATE_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT: {
			return {
				...state,
				data: {
					...state.data,
					posConfigurationDiscountProductVariantAssociatedDiscount: {
						...state.data
							.posConfigurationDiscountProductVariantAssociatedDiscount,
						...payload,
					},
				},
			};
		}

		case RESET_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT: {
			return {
				...state,
				data: {
					posConfigurationDiscountProductVariantAssociatedDiscount: {},
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
