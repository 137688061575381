import {
	UPDATE_PRODUCT_CATEGORY,
	SET_PRODUCT_CATEGORY,
	RESET_PRODUCT_CATEGORY,
} from './productCategories.actions';

const defaultState = {
	ui: {},
	data: {
		defaultProductCategories: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_PRODUCT_CATEGORY: {
		return {
			...state,
			data: {
				...state.data,
				defaultProductCategories: payload,
			},
		};
	}

	case UPDATE_PRODUCT_CATEGORY: {
		return {
			...state,
			data: {
				...state.data,
				defaultProductCategories: {
					...state.data.defaultProductCategories,
					...payload,
				},
			},
		};
	}

	case RESET_PRODUCT_CATEGORY: {
		return {
			...state,
			data: {
				...state.data,
				defaultProductCategories: {},
			},
		};
	}

	default:
		return state;
	}
}

export default reducer;
