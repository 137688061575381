import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// Action
import { showSearchModal } from 'services/globalSearch/actions';

// Ui
import { Box, Button as MuiButton, Fade } from '@mui/material';
import { Button } from 'dumb';
import SearchIcon from '@mui/icons-material/Search';

const GloabalSearchInput = ({ toggled }) => {
	const dispatch = useDispatch();

	return (
		<Box p={1}>
			<MuiButton
				variant="outlined"
				color="secondary"
				fullWidth
				sx={{
					color: '#fff',
					fontSize: 14,
					height: '45px',
					minWidth: '10px',
					borderColor: 'rgba(255, 255, 255, 0.5)',

					'&:hover': {
						borderColor: 'rgba(255, 255, 255, 1)',
					},

					'& .MuiButton-startIcon': {
						marginRight: toggled ? 1 : '0px',
						marginLeft: toggled ? -1 / 2 : '0px',
					},
				}}
				onClick={() => dispatch(showSearchModal(true))}
				startIcon={
					toggled ? (
						<Fade in={toggled} {...(toggled ? { timeout: 1000 } : {})}>
							<SearchIcon />
						</Fade>
					) : (
						<SearchIcon />
					)
				}
				endIcon={
					toggled ? (
						<Fade in={toggled} {...(toggled ? { timeout: 1000 } : {})}>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									transform: 'scale(0.7)',
								}}
							>
								<Button size="tiny" shadow type="inverted">
									{navigator.userAgent.indexOf('Win') !== -1 ? 'ctrl' : '⌘'}
								</Button>
								<Box px={1}>+</Box>
								<Button size="tiny" shadow type="inverted">
									K
								</Button>
							</Box>
						</Fade>
					) : null
				}
			>
				{toggled ? (
					<Fade in={toggled} {...(toggled ? { timeout: 1000 } : {})}>
						<Box
							sx={{
								width: '100%',
								textAlign: 'left',
								textTransform: 'capitalize',
								paddingLeft: 1,
							}}
							component="span"
						>
							Search...
						</Box>
					</Fade>
				) : null}
			</MuiButton>
		</Box>
	);
};

GloabalSearchInput.propTypes = {
	toggled: PropTypes.bool,
};

export default GloabalSearchInput;
