'use strict';

import React from 'react';

import PropTypes from 'prop-types';

function SubTable({ children }) {
	return <div className="react-table-wrapper__subcomponent">{children}</div>;
}

SubTable.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.object,
		PropTypes.elementType
	])
};

export default SubTable;
