'use strict';

class FileListDataProviderAbstract {

	constructor() {
		// Set type
		this.type = 'fileListDataProvider';
	}

}

export default FileListDataProviderAbstract;