export default function getSortOrderFromWorkplaceEmployment(
	workPlaceEmployment
) {
	const moneyballSubPositionSortOrder =
		workPlaceEmployment.employment?.current_moneyball_sub_position
			?.sort_order ?? 0;
	const moneyballPositionSortOrder =
		workPlaceEmployment.employment?.current_moneyball_sub_position
			?.moneyball_position?.sort_order ?? 0;

	// adding 2 sort orders next to one another allows us to sort first on moneyball position and then moneyball subpositions under that position
	return parseInt(
		`${moneyballPositionSortOrder}${moneyballSubPositionSortOrder}`
	);
}
