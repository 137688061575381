'use strict';

export default {
	HEADER: 'Hey! ',
	MESSAGE_OTHER: `Your account has no access. The following issues can be associated with lack of access: 
	- User emplyment date has not yet started
	- User has no moneyball position associated

	Your Please contact HR.`,
	MESSAGE_NO_USER_REFERENCE:
		'Your account has no user reference. Please contact HR.',
	MESSAGE_OUTRO:
		'In the mean time, you might not be able to visit certain pages of the site.',
	SUBMIT: 'Ok, got it',
};
