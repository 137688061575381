'use strict';

import React, { Component } from 'react';
import Loadable from 'react-loadable';
import PropTypes from 'prop-types';

import { Loader } from 'dumb';

const OpeningHours = Loadable({
	loader: () => import('./openingHours.container'),
	loading: Loader
});

class OpeningHoursLoader extends Component {
	render() {
		return <OpeningHours workplaceId={this.props.workplaceId} />;
	}
}

OpeningHoursLoader.propTypes = {
	workplaceId: PropTypes.number
};

export default OpeningHoursLoader;
