'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	updateBatchForm,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setMarketShiftAlignmentRules,
	updateMarketShiftAlignmentRules,
	resetState,
} from './store/marketShiftAlignmentRules.actions';

// services
import {
	fetchMarketShiftAlignmentRules,
	editMarketShiftAlignmentRule,
	editMarketShiftAlignmentRules,
	addMarketShiftAlignmentRules,
	deleteMarketShiftAlignmentRule,
} from './marketShiftAlignmentRules.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Input, Button, Icon, InputCollectionSelect } from 'dumb';
import MarketShiftAlignmentRulesModalBatch from './components/batch/marketShiftAlignmentRulesModalBatch';
import getColumns from 'reactDataWrapperColumns/shiftplanning/marketShiftAlignmentRules.columns';

// utils
import { getSelectOptionsFromEnums, getEditedValues } from 'services/utils';

// moment
import moment from 'moment';

// phrases/enums
import phrases from './marketShiftAlignmentRules.phrases';
import enums from './marketShiftAlignmentRules.enums';

const reduxKey = '/shiftplanning/market_shift_alignment_rules';

class MarketShiftAlignmentRules extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.addMarketShiftAlignmentRulesWrapper = this.addMarketShiftAlignmentRulesWrapper.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.editEntry = this.editEntry.bind(this);

		this.columns = getColumns(reduxKey);
	}

	editEntry() {
		const { marketShiftAlignmentRules, defaultMarketShiftAlignmentRules } = this.props;

		const editedValues = getEditedValues({
			oldData: defaultMarketShiftAlignmentRules,
			newData: marketShiftAlignmentRules,
		});

		const payload = {
			id: marketShiftAlignmentRules.id,
			minimum_deviation: editedValues.minimumDeviation ?? undefined,
			maximum_deviation: editedValues.maximumDeviation ?? undefined,
			registration_type: editedValues.registrationType ?? undefined,
			alignment_strategy: editedValues.alignmentStrategy ?? undefined,
		};

		return editMarketShiftAlignmentRule(payload);
	}

	editMultiple(selectedRows) {
		const { marketShiftAlignmentRules } = this.props;

		const payload = {
			...(marketShiftAlignmentRules.minimumDeviation && {
				minimum_deviation: marketShiftAlignmentRules.minimumDeviation,
			}),
			...(marketShiftAlignmentRules.maximumDeviation && {
				maximum_deviation: marketShiftAlignmentRules.maximumDeviation,
			}),
			...(marketShiftAlignmentRules.registrationType && {
				registration_type: marketShiftAlignmentRules.registrationType.value,
			}),
			...(marketShiftAlignmentRules.alignmentStrategy && {
				alignment_strategy: marketShiftAlignmentRules.alignmentStrategy.value,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editMarketShiftAlignmentRules({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteMarketShiftAlignmentRule(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			market: data.market.name,
			minimumDeviation: data.minimum_deviation,
			maximumDeviation: data.maximum_deviation,
			registrationType: {
				value: data.registration_type,
				label: data.registration_type,
			},
			alignmentStrategy: {
				value: data.alignment_strategy,
				label: data.alignment_strategy,
			},
			singleEdit: true,
		};

		this.props.setMarketShiftAlignmentRules(payload);
	}

	getEditableCells() {
		const { marketShiftAlignmentRules } = this.props;

		return [
			...(marketShiftAlignmentRules.singleEdit
				? [
						{
							header: 'Market',
							value: <span>{marketShiftAlignmentRules.market}</span>,
						},
				  ]
				: []),
			{
				header: 'Minumum deviation',
				value: (
					<Input
						id="minimum-deviation"
						type="number"
						clearable={false}
						placeholder="Enter minumum deviation..."
						value={marketShiftAlignmentRules.minimumDeviation}
						onChange={(e) => this.editStoreEntry('minimumDeviation', e)}
					/>
				),
			},
			{
				header: 'Maximum deviation',
				value: (
					<Input
						id="maximum-deviation"
						type="number"
						clearable={false}
						placeholder="Enter maximum deviation..."
						value={marketShiftAlignmentRules.maximumDeviation}
						onChange={(e) => this.editStoreEntry('maximumDeviation', e)}
					/>
				),
			},
			{
				header: 'Registration type',
				value: (
					<InputCollectionSelect
						id="registration-type"
						placeholder="Select registration type..."
						clearable={false}
						options={getSelectOptionsFromEnums(enums.registrationType)}
						onChange={(event) => this.editStoreEntry('registrationType', event)}
						value={marketShiftAlignmentRules.registrationType}
					/>
				),
			},
			{
				header: 'Alignment strategy',
				value: (
					<InputCollectionSelect
						id="alignment-strategy"
						placeholder="Select alignment strategy..."
						clearable={false}
						options={getSelectOptionsFromEnums(enums.alignmentStrategy)}
						onChange={(event) => this.editStoreEntry('alignmentStrategy', event)}
						value={marketShiftAlignmentRules.alignmentStrategy}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const value = e?.target?.value ?? e;

		const updateObject = {
			[name]: value,
		};

		this.props.updateMarketShiftAlignmentRules(updateObject);
	}

	fetchData(state) {
		return fetchMarketShiftAlignmentRules(state);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	addMarketShiftAlignmentRulesWrapper(data) {
		return addMarketShiftAlignmentRules(data).then(() =>
			this.setState(() => ({
				key: moment.utc(),
			}))
		);
	}

	render() {
		const { batchList, editBatchListItem, addBatchListItemRow, removeBatchListItem, marketFilter } = this.props;

		return (
			<>
				<ReactDataWrapper
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={reduxKey}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					setInitialEditValues={this.setInitialEditValues}
					accessAreasAllowedToEdit={['Global HR Configuration', 'Shiftplan Admin']}
					onModalClose={this.props.resetState}
					extraFilters={marketFilter.value ? `:market.id=='${marketFilter.value}'` : ''}
					actionRender={
						<Button type="inverted" label="Batch" shadow onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
				/>

				<MarketShiftAlignmentRulesModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addMarketShiftAlignmentRules={this.addMarketShiftAlignmentRulesWrapper}
				/>
			</>
		);
	}
}

MarketShiftAlignmentRules.propTypes = {
	marketShiftAlignmentRules: PropTypes.object,
	updateMarketShiftAlignmentRules: PropTypes.func,
	setMarketShiftAlignmentRules: PropTypes.func,
	resetState: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	defaultMarketShiftAlignmentRules: PropTypes.object,
	marketFilter: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			editBatchListItem,
			removeBatchListItem,
			updateMarketShiftAlignmentRules,
			setMarketShiftAlignmentRules,
			resetState,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		marketShiftAlignmentRules: store.globalAdministrationMarketShiftAlignmentRules.data.marketShiftAlignmentRules,
		defaultMarketShiftAlignmentRules:
			store.globalAdministrationMarketShiftAlignmentRules.data.defaultMarketShiftAlignmentRules,
		batchList: store.globalAdministrationMarketShiftAlignmentRules.data.batchList,
		marketFilter: store.filterSortColumnsData?.tables?.[reduxKey]?.custom?.market ?? {},
	};
};

export default connectWithStore(MarketShiftAlignmentRules, mapStateToProps, mapDispatchToProps);
