import React, { useState, ChangeEvent } from 'react';

// Redux
import {
	setDescription,
	setName,
	setBadge,
	setSortOrder,
	setFromDate,
	setToDate,
	resetData,
} from './store/moneyballPositionsTable.actions';
import { useAppDispatch, useAppSelector } from 'appState/storeHooks';
import { useNavigate } from 'react-router-dom';

// Services
import {
	fetchMoneyballPositions,
	deleteMoneyballPosition,
	createMoneyballPosition,
	updateMoneyballPosition,
} from './services/moneyballPositionsTable.service';
import moment from 'moment';
import constants from 'services/constants';
import { copyToClipboard } from '../../../../common/services/utils';
import { set as setFeedback } from 'feedback.vanilla.service.js';

// Components
import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import getColumns from 'reactDataWrapperColumns/hr/moneyballPositionsTable.columns';
import { Input, SingleDatePickerInput, Button, Icon } from 'dumb';

// Interfaces
import {
	IFetchDataProps,
	IReactDataTableWrapperRow,
} from 'types/reactDataWrapper';
import { IMoneyballPositionState } from './types';

// Phrases & Tools
import phrases from './moneyballPositionsTable.phrases';
import getEditedValues from 'services/utils/getEditedValues';
import { Box, Tooltip } from '@mui/material';

type Props = {
	reduxKey: string;
};

export default function MoneyballPositionsTable({
	reduxKey,
}: Props): JSX.Element {
	const moneyballPosition = useAppSelector((store) => store.moneyballPositions);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [originalData, setOriginalData] = useState<IMoneyballPositionState>();
	const [entryId, setEntryId] = useState<number>();
	const deleteEntry = (id: number) => {
		return deleteMoneyballPosition(id);
	};

	const inputsForModal = () => {
		return [
			{
				header: 'Name',
				value: (
					<Input
						dataCy="name"
						id="name"
						value={moneyballPosition.name}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							dispatch(setName(e.target.value))
						}
						placeholder="Enter Name"
						type="text"
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						dataCy="description"
						id="description"
						value={moneyballPosition.description}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							dispatch(setDescription(e.target.value))
						}
						placeholder="Enter Description"
						type="text"
					/>
				),
			},
			{
				header: 'Badge',
				value: (
					<Input
						dataCy="badge"
						id="badge"
						value={moneyballPosition.badge}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							dispatch(setBadge(e.target.value))
						}
						placeholder="Enter Badge"
						type="text"
					/>
				),
			},
			{
				header: 'Sort Order',
				value: (
					<Input
						dataCy="sort_order"
						id="sort_order"
						value={moneyballPosition.sort_order}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							dispatch(setSortOrder(parseInt(e.target.value)))
						}
						placeholder="Enter Sort Order"
						type="number"
					/>
				),
			},
			{
				header: 'From Date',
				value: (
					<SingleDatePickerInput
						id="from"
						selectedDate={moment(moneyballPosition.active.from).format(
							constants.shortDate
						)}
						onChange={(e: string) => dispatch(setFromDate(e))}
						placeholder="From"
					/>
				),
			},
			{
				header: 'To Date',
				value: (
					<SingleDatePickerInput
						id="to"
						selectedDate={moment(moneyballPosition.active.to).format(
							constants.shortDate
						)}
						onChange={(e: string) => dispatch(setToDate(e))}
						placeholder="To"
					/>
				),
			},
		];
	};

	const getPayload = (): IMoneyballPositionState => {
		const payload = {
			badge: moneyballPosition.badge,
			description: moneyballPosition.description,
			name: moneyballPosition.name,
			sort_order: moneyballPosition.sort_order,
			active: {
				from: moment(moneyballPosition.active.from).format(constants.shortDate),
				to: moment(moneyballPosition.active.to).format(constants.shortDate)
					? moment(moneyballPosition.active.to).format(constants.shortDate)
					: '',
			},
		};
		return payload;
	};

	const handleCreate = () => {
		const payload = getPayload();
		return createMoneyballPosition(payload);
	};

	const editEntry = () => {
		if (!originalData) return Promise.resolve(true);

		const payload = getPayload();

		const editedValues = getEditedValues({
			oldData: originalData,
			newData: payload,
		});

		return updateMoneyballPosition(
			entryId,
			editedValues as IMoneyballPositionState
		);
	};

	const onBoardingLink = (id: number) => {
		const onBoardingLink = `https://joejuice.typeform.com/to/sjC5NR?onboarding=${id}`;
		try {
			copyToClipboard(onBoardingLink);
			setFeedback(phrases.COPY_TO_CLIPBOARD, 1, 3000);
		} catch (error) {
			setFeedback(`${phrases.GRAB_IT_HERE} ${onBoardingLink}`, 0, 15000);
		}
	};

	const setInitialEditValues = (data: IHrMoneyballPositions) => {
		setOriginalData({
			name: data.name,
			description: data.description,
			badge: data.badge,
			sort_order: data.sort_order,
			active: {
				from: data.active.from,
				to: data.active.to,
			},
		});
		setEntryId(data.id);
		dispatch(setName(data.name));
		dispatch(setDescription(data.description));
		dispatch(setBadge(data.badge));
		dispatch(setSortOrder(data.sort_order));
		dispatch(setFromDate(data.active.from));
		dispatch(setToDate(data.active.to));
	};

	const loadData = (state: IFetchDataProps) => {
		return fetchMoneyballPositions(state);
	};

	const renderInlineButtons = (
		row: IReactDataTableWrapperRow<IHrMoneyballPositions>
	) => {
		return (
			<>
				<Tooltip title={phrases.DETAILS}>
					<Box sx={{ marginRight: 1 }}>
						<Button
							onClick={() =>
								navigate(`/hr/moneyball_positions/${row.original.id}`)
							}
							id="navigate"
							type="inverted"
							shadow
							size="micro"
							dataCy="rdw-details-button"
						>
							<Icon name="tune" />
						</Button>
					</Box>
				</Tooltip>

				<Tooltip title={phrases.COPY}>
					<Box>
						<Button
							dataCy="rdw-copy-button"
							onClick={() => onBoardingLink(row.original.id)}
							id="copy_to_clipboard"
							type="inverted"
							shadow
							size="micro"
						>
							<Icon name="content_copy" />
						</Button>
					</Box>
				</Tooltip>
			</>
		);
	};

	return (
		<ReactDataWrapper
			title="Moneyball positions table"
			columns={getColumns({ reduxKey })}
			reduxKey={reduxKey}
			editableCells={inputsForModal()}
			deleteEntry={deleteEntry}
			setInitialEditValues={setInitialEditValues}
			editEntry={editEntry}
			createEntry={handleCreate}
			onModalClose={() => dispatch(resetData())}
			editableCellsEdit={inputsForModal()}
			onModalCloseConfirm
			accessAreasAllowedToEdit={[
				'HR/MoneyballPositions::Edit',
				'Employment Admin',
				'Person Admin',
				'Organization Admin',
			]}
			fetchData={loadData}
			actions={(row: IReactDataTableWrapperRow<IHrMoneyballPositions>) =>
				renderInlineButtons(row)
			}
		/>
	);
}
