'use strict';

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import DetailsComponent from 'common/components/details/details.component';
import __apiFilter from 'apiFilter.service';
import SalaryGroupDetails from './../../services/salaryGroupDetails.service.js';

import SalaryComponents from 'salary/salaryComponents/salaryComponents.container.jsx';

const salaryGroupDetailsReduxKey = '/salary/salary_groups';
const salaryGroupDetails = new SalaryGroupDetails(salaryGroupDetailsReduxKey);
const salaryGroupDetailsProps = salaryGroupDetails.getProps();

export function SalaryGroupDetailsComponent() {
	const { salaryGroupId } = useParams();

	// Get common filter for querying where person = id
	const salaryGroupFilter = __apiFilter.defaults.fieldEqualsData('salary_group', salaryGroupId);
	salaryGroupDetails.setItemId(salaryGroupId);

	useEffect(() => {
		salaryGroupDetails.initView();
	}, [salaryGroupFilter, salaryGroupId]);

	return (
		<>
			<DetailsComponent reduxKey={salaryGroupDetailsReduxKey} {...salaryGroupDetailsProps} />

			<SalaryComponents salaryGroupId={salaryGroupId} />
		</>
	);
}
