'use strict';

import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchPosConfigurationDiscounts
 * @description Fetch shift tags to populate tag preset selector
 */
export function fetchPosConfigurationDiscounts(posConfigurationId, state) {
	const preFilter = `:pos_configuration_version.id=='${posConfigurationId}'`;
	const filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(`/pos/pos_configuration_discounts`, params)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchPosConfigurationDiscountProductVariants
 * @description Fetch shift tags to populate tag preset selector
 */
export function fetchPosConfigurationDiscountProductVariants(
	productVariantId,
	customFilter
) {
	const preFilter = `:pos_configuration_product_variant.id=='${productVariantId}'`;
	const filter = customFilter ? `${preFilter};(${customFilter})` : preFilter;

	// Set params and filter
	const params = {
		limit: 100,
		filter,
	};

	return get(`/pos/pos_configuration_discount_product_variants`, params)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editPosConfigurationDiscountProductVariants
 * @description edit
 */
export function editPosConfigurationDiscountProductVariants(
	variantId,
	payload
) {
	return post(
		`/pos/pos_configuration_discount_product_variants/${variantId}`,
		payload
	)
		.then((res) => {
			setFeedback('Discount edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addProductIngredient
 * @description edit
 */
export function addPosConfigurationDiscountProductVariants(data) {
	return post(`/pos/pos_configuration_discount_product_variants`, data)
		.then((res) => {
			setFeedback('Discount added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deletePosConfigurationDiscountProductVariants
 * @description edit
 */
export function deletePosConfigurationDiscountProductVariants(variantId) {
	return remove(`/pos/pos_configuration_discount_product_variants/${variantId}`)
		.then((res) => {
			setFeedback('Discount deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}
