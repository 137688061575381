'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { InputCollectionSelect, ButtonGroup, Button, Icon } from 'dumb';
import { ModalRow } from 'dumb/modal';

// lodash
import _isEmpty from 'lodash/isEmpty';

// constants/phrases/enums
import phrases from './../../../marketShiftRules.phrases';

// utils
import { getOptions } from 'services/utils/marketShiftRules';

class MarketShiftRulesBatchStepForm extends Component {
	editStoreEntry(e, type) {
		const { updateBatchForm, resetSelectedRows, marketReduxKey } = this.props;

		const value = e?.target?.value ?? e;

		if (_isEmpty(value)) {
			resetSelectedRows(marketReduxKey);
		}

		updateBatchForm({
			[type]: value,
		});
	}

	render() {
		const {
			batchFormData,
			toggleOverlay,
			marketReduxKey,
			marketFilter,
		} = this.props;

		return (
			<>
				<ModalRow>
					<ButtonGroup type="full-width">
						<InputCollectionSelect
							id="market"
							className="modal-batch-select"
							label={phrases.MODAL_BATCH_STEP_MARKET}
							placeholder={phrases.MODAL_BATCH_STEP_MARKET_PLACEHOLDER}
							value={batchFormData.market}
							handleChange={(key, value) =>
								this.editStoreEntry(value, 'market')
							}
							multi
							cache
							apiPath="/organization/markets"
							params={{
								limit: 30,
								filter: marketFilter,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>

						<Button
							type="inverted"
							shadow
							size="large"
							onClick={() => toggleOverlay(marketReduxKey)}>
							<Icon name="more" />
						</Button>
					</ButtonGroup>
				</ModalRow>
				<ModalRow>
					<InputCollectionSelect
						id="rule"
						label={phrases.MODAL_BATCH_STEP_RULE}
						placeholder={phrases.MODAL_BATCH_STEP_RULE_PLACEHOLDER}
						value={batchFormData.rule}
						handleChange={(key, value) => this.editStoreEntry(value, 'rule')}
						multi
						options={getOptions()}
					/>
				</ModalRow>
			</>
		);
	}
}

MarketShiftRulesBatchStepForm.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
	resetSelectedRows: PropTypes.func,
	marketReduxKey: PropTypes.string,
	marketFilter: PropTypes.string,
};

export default MarketShiftRulesBatchStepForm;
