export const UPDATE_BATCH_FORM =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNTS/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNTS/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNTS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNTS/REMOVE_BATCH_LIST_ITEM';

export const SET_POS_CONFIGURATION_DISCOUNTS =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNTS/SET_POS_CONFIGURATION_DISCOUNTS';
export const SET_POS_CONFIGURATION_DISCOUNTS_TABLE_FILTER =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNTS/SET_POS_CONFIGURATION_DISCOUNTS_TABLE_FILTER';
export const UPDATE_POS_CONFIGURATION_DISCOUNTS =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNTS/UPDATE_POS_CONFIGURATION_DISCOUNTS';
export const RESET_STATE =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNTS/RESET_STATE';
export const SET_POS_CONFIGURATION_DISCOUNT_LEGACY_FILTER =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNTS/SET_POS_CONFIGURATION_DISCOUNT_LEGACY_FILTER';

export function setLegacyFilter(payload) {
	return {
		type: SET_POS_CONFIGURATION_DISCOUNT_LEGACY_FILTER,
		payload,
	};
}

export function setPosConfigurationDiscounts(payload) {
	return {
		type: SET_POS_CONFIGURATION_DISCOUNTS,
		payload,
	};
}

export function setPosConfigurationDiscountsTableFilter(payload) {
	return {
		type: SET_POS_CONFIGURATION_DISCOUNTS_TABLE_FILTER,
		payload,
	};
}

export function updatePosConfigurationDiscounts(payload) {
	return {
		type: UPDATE_POS_CONFIGURATION_DISCOUNTS,
		payload,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}
