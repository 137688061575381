'use strict';

import { isTouchDevice } from 'detectMobile.vanilla.js';

import {
	SHIFT_BEING_UPDATED,
	SET_SHIFT_WIDTH
} from '../actions/shifts.actions';

const defaultState = {
	shiftBeingUpdated: null,
	shiftWidth: isTouchDevice() ? 30 : 20
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
	case SHIFT_BEING_UPDATED:
		return Object.assign({}, state, {
			shiftBeingUpdated: payload.shiftId,
		});

	case SET_SHIFT_WIDTH:
		return Object.assign({}, state, {
			shiftWidth: payload,
		});

	default:
		return state;
	}
}

export default reducer;
