import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

export function getFilter({
	posConfigurationVersions,
	ingredientPricingGroups
}) {
	// get array of version id's
	const versionIds = posConfigurationVersions.reduce(
		(acc, currentValue, index) => {
			acc =
				posConfigurationVersions.length - 1 === index
					? `${acc}'${currentValue.value.id}']`
					: `${acc}'${currentValue.value.id}',`;
			return acc;
		},
		'['
	);
	// get array of ingredient pricing group id's
	const groupIds = ingredientPricingGroups.reduce(
		(acc, currentValue, index) => {
			acc =
				ingredientPricingGroups.length - 1 === index
					? `${acc}'${currentValue.value.id}']`
					: `${acc}'${currentValue.value.id}',`;
			return acc;
		},
		'['
	);

	return `:pos_configuration_version.id=IN=${versionIds};:ingredient_pricing_group.id=IN=${groupIds}`;
}

/**
 * @func markValuesWithCanAdd
 * @param {Object} - contains mapepd data that we selected and data from the api
 * @description if combo of posConVer and ingPricGroup found in apiData then value
 * already exists and will be marked with canAdd: false
 */
export function markValuesWithCanAdd({ listData, apiData }) {
	return listData.map(entry => {
		const posConfigurationVersionId = entry.posConfigurationVersion.id;
		const ingredientPricingGroupId = entry.ingredientPricingGroup.id;

		const found = apiData.find(
			entry =>
				_get(entry, 'pos_configuration_version.id', null) ===
					posConfigurationVersionId &&
				_get(entry, 'ingredient_pricing_group.id', null) ===
					ingredientPricingGroupId
		);

		return {
			...entry,
			canAdd: _isEmpty(found)
		};
	});
}
