export default {
	TITLE: 'Shift clockin correction options',
	ENTRY_ADEDD: 'Shift clockin correction added',
	ENTRY_EDITED: 'Shift clockin correction edited',
	ENTRY_DELETED: 'Shift clockin correction deleted',

	COMMENT: 'Comment',
	COMMENT_PLACEHOLDER: 'Enter comment...',

	// batch
	BATCH_ADD: 'Batch',
	MODAL_BATCH_TITLE: 'Batch shift clockin correction',
	MODAL_BATCH_CONFIRM_BUTTON_LABEL: 'Submit',
	MODAL_BATCH_ADD_ROW_TOOLTIP: 'Add row',
};
