import React, { Suspense, Component } from 'react';
// import Map from './map.component';
import { MapFactory } from './factory/index';
import PropTypes from 'prop-types';
import defaultStyle from './map.style';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';

import './map.css';

const Map = React.lazy(() =>
	import(/* webpackChunkName: "map.component" */ './map.component')
);

class MapContainer extends Component {
	constructor(props) {
		super(props);

		this.mapFactory = new MapFactory.Map();
		this.markerLocation = {};

		this.state = {
			mounted: false,
			address: this.props.addressObjectDefault,
			notSavedData: {},
			settings: {
				zoom: 17,
				isMarkerShown: false,
				style: defaultStyle
			},
			mapKey: this.props.mapKey
		};

		// Binding this for methods to re-use them
		this.updateAddress = this.updateAddress.bind(this);
	}

	componentDidMount() {
		this.updateWorkplaceAddress();
	}

	updateWorkplaceAddress() {
		this.props.getWorkplaceAddress(this.props.workplaceId).then(data => {
			// We don't want to update the address controller at the first init, as we should go with the data from the api
			this.updateAddress(data, false);
			this.setState(() => ({ mounted: true }));
		});
	}

	formatLatitudeAndLongitude(data) {
		if (data.longitude) data.longitude = parseFloat(data.longitude);

		if (data.latitude) data.latitude = parseFloat(data.latitude);

		return data;
	}

	updateMarker(markerLocation) {
		this.markerLocation = markerLocation || this.props.markerLocation;

		// Setting state to waitingForApi = true
		this.props.waitingForApiHandler(true);

		this.mapFactory.getPlace(this.markerLocation).then(place => {
			const newAddress = {
				...this.state.address,
				...this.formatLatitudeAndLongitude(place)
			};

			if (this.markerLocation.location) {
				newAddress.latitude = this.markerLocation.location.lat;
				newAddress.longitude = this.markerLocation.location.lng;
			}

			if (!_isEqual(this.state.address, newAddress)) {
				this.updateAddress(newAddress);
			}

			// Setting state to waitingForApi = false
			this.props.waitingForApiHandler(false);
		});
	}

	/**
	 * @description Formatting, and updating the address then in the higher component
	 * @param address
	 * @param updateAddressController
	 * @param updateMarker
	 */
	updateAddress(address, updateAddressController = true, updateMarker = false) {
		address = {
			...address,
			...address
		};

		let isMarkerShown = true;

		if (!address.latitude || !address.longitude) {
			address.latitude = 55.68014;
			address.longitude = 12.582637;

			isMarkerShown = false;
		}

		address = this.formatLatitudeAndLongitude(address);

		this.setState(() => ({
			address: address,
			settings: {
				...this.state.settings,
				isMarkerShown: isMarkerShown
			}
		}));

		if (isMarkerShown && updateAddressController) {
			this.props.addressController(this.state.address, updateMarker);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps.markerLocation &&
			!_isEmpty(nextProps.markerLocation) &&
			nextProps.markerLocation !== this.markerLocation
		) {
			this.updateMarker(nextProps.markerLocation);
		}

		if (nextProps.mapKey && nextProps.mapKey !== this.state.mapKey) {
			this.setState(
				() => ({
					mapKey: nextProps.mapKey
				}),
				() => {
					this.updateWorkplaceAddress(true);
				}
			);
		}
	}

	render() {
		return this.state.mounted ? (
			<div className="map">
				<Suspense fallback={<div>Loading...</div>}>
					<Map
						{...{
							address: this.state.address,
							phrases: this.mapFactory.getPhrases(),
							getPlace: this.mapFactory.getPlace,
							settings: this.state.settings,
							updateAddress: this.updateAddress
						}}
					/>
				</Suspense>
			</div>
		) : null;
	}
}

MapContainer.defaultProps = {};

MapContainer.propTypes = {
	getWorkplaceAddress: PropTypes.func.isRequired,
	addressController: PropTypes.func.isRequired,
	markerLocation: PropTypes.object,
	addressObjectDefault: PropTypes.object.isRequired,
	mapKey: PropTypes.number.isRequired,
	waitingForApiHandler: PropTypes.func.isRequired
};

export default MapContainer;
