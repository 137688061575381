export const RESET_STATE = 'POS_ADD_MODAL/RESET_STATE';
export const LOADING = 'POS_ADD_MODAL/LOADING';
export const LOADING_PRODUCT = 'POS_ADD_MODAL/LOADING_PRODUCT';
export const EDIT_GROUP = 'POS_ADD_MODAL/EDIT_GROUP';
export const EDIT_PRODUCT_TILE = 'POS_ADD_MODAL/EDIT_PRODUCT_TILE';
export const ADD_PRODUCT_VARIANT = 'POS_ADD_MODAL/ADD_PRODUCT_VARIANT';
export const REMOVE_PRODUCT_VARIANT = 'POS_ADD_MODAL/REMOVE_PRODUCT_VARIANT';

export function removeProductVariant(payload) {
	return {
		type: REMOVE_PRODUCT_VARIANT,
		payload
	};
}

export function addProductVariant(payload) {
	return {
		type: ADD_PRODUCT_VARIANT,
		payload
	};
}

export function editProductTile(payload) {
	return {
		type: EDIT_PRODUCT_TILE,
		payload
	};
}

export function editGroup(payload) {
	return {
		type: EDIT_GROUP,
		payload
	};
}

export function setLoader(isLoading) {
	return {
		type: LOADING,
		payload: isLoading
	};
}

export function setLoaderProduct(isLoading) {
	return {
		type: LOADING_PRODUCT,
		payload: isLoading
	};
}

export function resetState() {
	return {
		type: RESET_STATE
	};
}