import React from 'react';
import PropTypes from 'prop-types';

// components
import { Text } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';
import ShiftTransferManagerApprovalApplicantList from './components/shiftTransferManagerApprovaApplicantList';
import { Tip } from 'dumb/tips';

// phrases
import phrases from './../../../../shiftTransferApproval.phrases';

// lodash
import _isEmpty from 'lodash/isEmpty';

// utils
import {
	formatShiftTime,
	getShiftDate,
	getShiftDay,
} from './../../../../utils';

const ShiftTransferManagerApprovalList = ({
	shiftTransfers,
	updateShiftTransfer,
}) => {
	const getShiftCell = (entry) => {
		const shiftDate = getShiftDate(entry.shift?.planned_period?.from);
		const timeFrom = formatShiftTime(entry.shift?.planned_period?.from);
		const timeTo = formatShiftTime(entry.shift?.planned_period?.to);
		const shiftDay = getShiftDay(entry.shift?.planned_period?.from);

		const concatenatedText = `${shiftDate} ${timeFrom} - ${timeTo} (${shiftDay})`;

		return (
			<div className="shift-transfer-approval-modal__table-wrapper__row-cell">
				<Text bold size="small" noWrap>
					{concatenatedText}
				</Text>
			</div>
		);
	};

	return (
		<>
			<div className="shift-transfer-approval-modal__tip-wrapper">
				<Tip
					className="shift-transfer-approval-modal__tip-wrapper__tip"
					content={phrases.MODAL_TIP_BM}
				/>
			</div>
			<div className="shift-transfer-approval-modal__table-wrapper">
				{!_isEmpty(shiftTransfers) && (
					<TableList>
						<thead>
							<tr>
								<th>{phrases.TABLE_LIST_BAR}</th>
								<th>{phrases.TABLE_LIST_SHIFT}</th>
								<th />
							</tr>
						</thead>
						<tbody>
							{shiftTransfers.map((entry) => {
								const moneyballBadge =
									entry.donor?.person?.moneyball_sub_position
										?.moneyball_position?.badge ?? '';
								const name = entry.donor?.person?.full_name ?? '';
								const id = entry.donor?.person?.id ?? '';

								return (
									<React.Fragment key={entry.id}>
										<TableListRow>
											{/* <TableListCell>
												<div className="shift-transfer-approval-modal__table-wrapper__row-cell">
													<Text
														bold
														size="tiny"
														className="shift-transfer-approval-modal__table-wrapper__row-cell__moneyball">
														{moneyballBadge}
													</Text>
													<Text
														bold
														size="small"
														noWrap
														className="shift-transfer-approval-modal__table-wrapper__row-cell--left-margin">
														{name}
													</Text>
												</div>
											</TableListCell> */}

											<TableListCell>
												<Text size="small" bold noWrap>
													{entry.shift?.workplace?.name}
												</Text>
											</TableListCell>

											<TableListCell>{getShiftCell(entry)}</TableListCell>

											<TableListCell />
										</TableListRow>
										{!_isEmpty(entry.applications) && (
											<TableListRow>
												<td colSpan="3">
													<ShiftTransferManagerApprovalApplicantList
														shiftTransfer={entry}
														updateShiftTransfer={updateShiftTransfer}
														ownerName={name}
														ownerId={id}
														ownerMoneyball={moneyballBadge}
													/>
												</td>
											</TableListRow>
										)}
									</React.Fragment>
								);
							})}
						</tbody>
					</TableList>
				)}
			</div>
		</>
	);
};

ShiftTransferManagerApprovalList.propTypes = {
	shiftTransfers: PropTypes.object,
	updateShiftTransfer: PropTypes.func,
};

export default ShiftTransferManagerApprovalList;
