'use strict';

var components = {
	ACTIVATE_PASSWORD: {
		HEADING__SUCCESS: 'Wooo!',
		SUBHEADING__SUCCESS: 'Your password was activated',
		BUTTON__SUCCESS: 'Move on!',
		HEADING__DEFAULT: 'New Password',
		SUBHEADING__DEFAULT: 'Choose your new password',
		LABEL: 'Password',
		PLACEHOLDER: 'Type your secret password here...',
		PLACEHOLDER_REPEAT: 'Do it again... ',
		ERROR__ENTER_PASSWORD: 'Please enter a password',
		ERROR__PASSWORD_NO_MATCH: 'Passwords do not match',
		ERROR__OOPS: 'Ooops - something went wrong! Try again!',
		BUTTON__SUBMIT: 'Activate'
	},
	BREAD_CRUMB: {
		BACK: 'Back'
	},
	CACHE_INVALIDATION: {
		HEADING: "You'rreeeee out(dated)!",
		DETAILS:
			'The infamous IT-crew just released a new (much cooler) version of workplanner.',
		HOW:
			'To get all the cool new features we suggest that you reload workplanner (or clear your cache if you keep seeing this message).',
		RELOAD: 'Reload workplanner',
		CLEAR: 'How to clear cache',
		CURRENTLY_RUNNING: 'You are currently running version:',
		SHOULD_BE_RUNNING: 'You should be running version:'
	},
	DETAILS: {
		SAVE: 'Update',
		CREATE: 'Create',
		NEW: 'New',
		BACK: 'Back',
		CANCEL: 'Cancel',
		DELETE: 'Delete',
		PASSWORD: 'Password',
		PASSWORD_REPEAT: 'Repeat password',
		CONFIRM_DELETE_TITLE: 'Delete item',
		CONFIRM_DELETE_MESSAGE: 'Are you sure you want to delete this item?',
		CONFIRM_DELETE_REJECT: 'Cancel',
		CONFIRM_DELETE_RESOLVE: 'Delete',
		UPDATED: 'Item updated!',
		DELETED: 'Item deleted!',
		CREATED: 'Item created!'
	},
	DRAWER_TO_BANK: {
		FOREIGN_ID: 'Foreign id',
		NAME: 'Store',
		DATE: 'Date',
		DEPOSIT: 'Drawer to bank',
		CLOSE: 'Close',
		CONFIRM: 'Confirm',
		DIFFERENCE: 'Difference',
		MISSING: 'Missing?',
		DESCRIPTION: 'Justify this audit',
		REGISTERED_AT_BANK: 'Registered at bank',
		DESC_DEPOSIT: 'Deposit',
		DESC_RECONCILED: 'is reconciled with',
		DESC_MISSING: 'is missing',
		DESC_AND: 'and has a value of',
		RESET: 'Reset',
		PREVIOUS_FINANCIAL_ADJUSTMENT: 'Previous financial adjustment',
		SAVE: 'Confirm',
		CURRENCY_TYPE: 'Currency type'
	},
	EMPLOYMENT_DOCUMENTS: {
		TITLE: 'Employment Documents',
		UPLOAD: {
			PICK_FILE: 'Pick file',
			UPLOAD: 'Upload',
			ERROR: 'Bummer - Something went wrong!',
			SUCCESS: 'File was uploaded correctly - coffee is on the house!'
		}
	},
	FILTER_BAR: {
		ADD_FILTER: 'Add filter',
		REMOVE_FILTERS: 'Please remove all filters before toggling the filter bar'
	},
	FORGOT_PASSWORD: {
		HEADING__SUCCESS: 'Success!',
		SUBHEADING__SUCCESS: 'Now go look at your inbox.',
		HEADING__DEFAULT: 'Forgot password?',
		SUBHEADING__DEFAULT: 'Enter your e-mail and hit reset!',
		LABEL: 'E-mail',
		PLACEHOLDER: 'Your e-mail here. Like right here...',
		ERROR__ENTER_EMAIL: 'Please enter your e-mail address',
		ERROR__UNKNOWN_EMAIL: 'Unknown e-mail address - try again!',
		ERROR__OOPS: 'Ooops - something went wrong! Try again!',
		BUTTON__SUBMIT: 'Reset'
	},
	FINANCIAL_POS_EXPORT: {
		TITLE: 'Financial Pos Export',
		BAR_NAME: 'Bar name',
		TOTAL: 'Total',
		D2B_OUTSTANDING: 'D2B outstanding'
	},
	INPUTS: {
		INPUT__REPEAT: ' repeat',
		SELECT__NO_RESULTS: 'No results',
		SELECT__SEARCH: 'Type to search',
		DATE_PLACEHOLDER: 'Select date',
		START_DATE_PLACEHOLDER: 'Start date',
		END_DATE_PLACEHOLDER: 'End date',
		REQUIRED: 'Required',
		INVALID_FIELDS: 'One or more input fields did not validate.',
		SIGN: 'Sign',
		SIMPLE_DATE_DAY_PLACEHOLDER: '31',
		SIMPLE_DATE_MONTH_PLACEHOLDER: '12',
		SIMPLE_DATE_YEAR_PLACEHOLDER: '9999'
	},
	IS_LOADING: {
		BAIL_OUT: 'Still waiting for the server... Want to bail out and try again?',
		BAIL_OUT_BUTTON: 'Bail out!'
	},
	IMAGE_CROPPER: {
		CROP_AND_UPLOAD: 'Save image',
		SCALE: 'Scale'
	},
	LIST: {
		ALL: 'All',
		COLUMNS: 'Columns',
		SEARCH: 'Search...',
		PICK_A_DATE: 'Pick a date...',
		ADDROW_CANCEL: 'Cancel',
		ADDROW_REPEAT: ' repeat',
		ADDROW_CREATE_FIRST:
			'Please create main entry before adding additional content...',
		EDIT: 'Edit',
		SIGN: 'Sign',
		DETAILS: 'Details',
		ADD: 'Add',
		FOLD: 'Fold',
		DELETE: 'Drop',
		FILTER: 'Filters',
		COLLAPSE: 'Hide',
		EXPORT: 'Export',
		CSVEXPORT: 'CSV',
		PDFEXPORT: 'PDF',
		POS_SESSIONS: {
			SIGN: 'Sign'
		},
		POS_CONFIGURATION_DETAILS: {
			LAYOUT: 'Views',
			PRICING: 'Pricings',
			CURRENCIES: 'Currency',
			DISCOUNTS: 'Discounts',
			DISCOUNT_PRICINGS: 'Set pricings',
			PUBLISHED: 'Already published versions can not be deleted',
			NO_DISCOUNT: 'There is no discount to delete',
			DUPLICATE: 'Duplicate'
		},
		SALARY_COMPONENTS: {
			RULES: 'Rules'
		},
		EMPLOYMENTS: {
			WORKPLACES: 'Details'
		},
		REFRESH: 'Refresh',
		MISSING_EMAIL: 'Please add email address and try again',
		ADD_USER: 'Add'
	},
	GOLD_MODEL: {
		CONTENT_TYPES: {
			TURNOVER: 'Turnover',
			LOCATION: 'Location',
			SALARY: 'Salary',
			DOC: 'Direct Operational Cost',
			CGS: 'Cost of Goods'
		},
		IMPORTS: {
			IMPORT_TITLE: 'Profit And Loss Matrice CSV Import',
			PICK_CSV: 'Pick CSV',
			UPLOAD_CSV: 'Upload CSV',
			UPLOAD: 'Upload',
			ERROR: 'Something went wrong - is the file formatted correctly?',
			SUCCESS: 'Records were imported correctly! Go grab a juice!',
			FORECASTS: 'Forecasts',
			OPERATION: 'Operation',
			BOARD: 'Board',
			SOM: 'SOM',
			SOY: 'SOY'
		},
		TAGS: {
			HEADING: 'Performance tags',
			ADD_TAG: 'Add'
		},
		CLOSE: 'Close',
		CONFIRM: 'Save',
		ACTUALS: 'Actuals',
		FORECASTS: 'Forecasts',
		OPERATION: 'Operation',
		IDEAL_IMPORT: 'Ideal',
		IMPORT: 'Import',
		TITLE: 'Gold model',
		TYPES: 'Types',
		PERIOD_TYPES: 'Period',
		MONTHLY: 'Monthly',
		DAILY: 'Daily',
		CSVEXAMPLE: 'CSV Example',
		DOWNLOAD_EXAMPLE: 'Download Example CSV'
	},
	SEARCHLIST: {
		SEARCH_IN: 'Search in',
		QUICKFILTER: 'Quick filter'
	},
	LOGIN: {
		HEADING__DEFAULT: 'Log in',
		SUBHEADING__DEFAULT: 'Workplanner',
		LABEL__USERNAME: 'Username',
		PLACEHOLDER__USERNAME: 'Joe Juicer',
		LABEL__PASSWORD: 'Password',
		PLACEHOLDER__PASSWORD: 'Your secret password',
		ERROR__ENTER_USERNAME: 'Please enter your username',
		ERROR__ENTER_PASSWORD: 'Please enter your password',
		ERROR__INVALID_CREDENTIALS:
			'Your credentials are incorrect. Please try again!',
		ERROR__INSUFFICIENT_RIGHTS:
			'Your account exists, but is not authorized to access Workplanner',
		BUTTON__SUBMIT: 'Log in',
		BUTTON__FORGOT_PASSWORD: 'Forgot password?'
	},
	POS_SESSION: {
		ADD_AUDIT: 'Add audit',
		AUDIT_TAXES: 'Audit taxes',
		TYPE: 'Type',
		RATE: 'Rate (%)',
		GROSS: 'Gross payments',
		DEPOSITS: 'Deposits',
		NEW_DEPOSIT: 'Create new deposit',
		TAX: 'Tax',
		NET: 'Net payments',
		TAX_CALCULATION: 'Tax input mode',
		NO_TAX_RATE_TEXT:
			'Bummer! Currently there are no valid tax rates associated with the market where the current POS Session took place.',
		NO_TAX_RATE_LINK:
			'Before you try again, you should add a tax rate to market:',
		TOTAL_GROSS_PAYMENTS_ERROR_1:
			'Gross value of taxes were less than the specified payments. ',
		TOTAL_GROSS_PAYMENTS_ERROR_2: 'Summed gross values for taxes were ',
		TOTAL_GROSS_PAYMENTS_ERROR_3: ' and summed payments were ',
		AUTO: 'Auto',
		MANUAL: 'Manual',
		SIGN_OFF_HEADING: 'Sign off',
		SIGN: 'Sign POS session',
		ALREADY_SIGNED: 'Already signed',
		SIGN_MULTI: {
			ALL: 'All',
			COLUMNS: 'Columns',
			DATE: 'Date',
			DIFFERENCE: 'Difference',
			NAME: 'Name',
			NONE: 'None',
			SELECT: 'Select',
			HEADING: 'Sign multiple pos sessions',
			SIGN: 'Sign selected',
			SORT: 'Sort'
		}
	},
	PROFILE: {
		NO_PERSON:
			'This account is not a personal account. Only administrators can change account types.'
	},
	RELOGIN: {
		HEADING: "Time's up!",
		SUBHEADING: 'This is a routine check',
		PARAGRAPH:
			'To continue using workplanner, prove you belong with Joe and re-enter your password.',
		LABEL__PASSWORD: 'Password',
		PLACEHOLDER__PASSWORD: 'Sshhhh... Your secret password',
		BUTTON__SUBMIT: 'Continue',
		BUTTON__FORGOT_PASSWORD: 'Arrh, forgot!'
	},
	SEARCH_LIST: {
		TITLE: 'Search List'
	},
	EXPORT_LIST: {
		TITLE: 'Export'
	},
	SHIFTPLANNER: {
		CLEAR: 'Clear',
		LEFTSIDE: {
			TITLE: 'STAFF',
			NAME: 'Name',
			WEEKLY_HOURS: 'Weekly hours'
		}
	},
	TRANSPORT: {
		NO_CHILDREN: 'This section has no subpages...'
	},
	USER_ACTIVATION_KEY: {
		HEADING: 'User Activation Key',
		INFO:
			'Send the user an activation key so he/she can create a password to use when logging into Workplanner.',
		BUTTON: 'Send activation key',
		ERROR__OOPS:
			'Something went wrong. Key was not sent. Refresh and try again or contact the administrator.',
		FEEDBACK__SENT: 'Activation key was sent to: '
	},
	ZOOMLIST: {
		GO_BACK: 'Go back...'
	}
};

module.exports = components;
