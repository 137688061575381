'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';

// different batch views
import MarkManagedClockinNoShow from './components/markManagedClockinNoShow';
import MarkManagedClockinAddBreak from './components/markManagedClockinAddBreak';
import AdjustClockedValues from './components/adjustClockedValues';
import AdjustBreakDates from './components/adjustBreakDates';

// utils
import moment from 'moment';
import constants from 'services/constants';
import phrases from './shiftClockinsModal.phrases';
import { constructRowToUpdate } from './utils';
import { set as setFeedback } from 'feedback.vanilla.service.js';

// enums
import enums from './../../shiftClockins.enums';

// styles
import './shiftClockinsModal.css';

class ShiftClockinsModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			isAdjustFlowValid: true,
		};

		this.onAdjustManagedClockinSubmit =
			this.onAdjustManagedClockinSubmit.bind(this);
		this.getIsAdjustFlowValid = this.getIsAdjustFlowValid.bind(this);
		this.validateInputs = this.validateInputs.bind(this);
		this.correctShiftClockinBreakWrapper =
			this.correctShiftClockinBreakWrapper.bind(this);
		this.getBreakEndsOnAnotherDay = this.getBreakEndsOnAnotherDay.bind(this);
		this.getIsAddBreakStepValid = this.getIsAddBreakStepValid.bind(this);
		this.adjustClockedDate = this.adjustClockedDate.bind(this);
		this.adjustBreakDate = this.adjustBreakDate.bind(this);

		this.modalRef = React.createRef();
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	onAdjustManagedClockinSubmit() {
		const {
			postManagedClockinCorrection,
			managedShiftClockin,
			handleClose,
			modalType,
		} = this.props;

		this.setState(() => ({
			loading: true,
		}));

		let payload = {
			id: managedShiftClockin.id,
		};

		if (modalType === enums.MODAL_TYPES.MARK_MANAGED_CLOCKIN_AS_NO_SHOW) {
			payload = {
				...payload,
				no_show: true,
			};
		}

		postManagedClockinCorrection(payload)
			.then(() => {
				this.setState(() => ({
					loading: false,
				}));

				handleClose();
			})
			.catch(() => {
				this.setState(() => ({
					loading: false,
				}));
			});
	}

	validateInputs(isValid) {
		this.setState(() => ({ isAdjustFlowValid: isValid }));
	}

	getIsAdjustFlowValid() {
		const { managedShiftClockin } = this.props;

		if (!managedShiftClockin) return;

		const fromValid = moment(
			managedShiftClockin.from,
			constants.dateFormat,
			true
		).isValid();
		const toValid = moment(
			managedShiftClockin.to,
			constants.dateFormat,
			true
		).isValid();

		return fromValid && toValid && this.state.isAdjustFlowValid;
	}

	getBreakEndsOnAnotherDay() {
		const { breakObject } = this.props;

		if (!breakObject.from || !breakObject.to) return false;

		const momentFrom = moment.utc(breakObject.from, constants.timeShort);
		const momentTo = moment.utc(breakObject.to, constants.timeShort);

		let breakEndsOnAnotherDay = false;
		if (momentTo.isBefore(momentFrom, 'minute')) breakEndsOnAnotherDay = true;

		return breakEndsOnAnotherDay;
	}

	correctShiftClockinBreakWrapper() {
		const { breakObject, managedShiftClockin, updateEntry, handleClose } =
			this.props;

		const breakEndsOnAnotherDay = this.getBreakEndsOnAnotherDay();
		const rowToUpdate = constructRowToUpdate({
			managedShiftClockin,
			breakObject,
			breakEndsOnAnotherDay,
		});

		updateEntry(rowToUpdate);
		handleClose();
	}

	getIsAddBreakStepValid() {
		const { breakObject } = this.props;

		if (!breakObject.from || !breakObject.to) return false;
		else return true;
	}

	adjustClockedDate() {
		const { managedShiftClockin, updateEntry, handleClose } = this.props;

		// get time from old date object
		const originalDate =
			managedShiftClockin.row.clocked[managedShiftClockin.type];
		const originalTime = moment
			.utc(originalDate, constants.dateFormat)
			.format(constants.timeShort);

		// new date object with old time - shortDate format
		const updatedDate = managedShiftClockin.clocked[managedShiftClockin.type];
		const newDate = moment
			.utc(`${updatedDate}T${originalTime}`, constants.dateFormat)
			.format(constants.dateFormat);

		// update the row
		const row = {
			...managedShiftClockin.row,
			clocked: {
				...managedShiftClockin.row.clocked,
				[managedShiftClockin.type]: newDate,
			},
		};

		updateEntry(row);
		handleClose();

		setFeedback(phrases.CLOCKIN_DATE_ADJUSTED, 1);
	}

	adjustBreakDate() {
		const { managedShiftClockin, updateEntry, handleClose } = this.props;

		// get time from old date object
		const breakId = managedShiftClockin.break.id;
		const breakEntity = managedShiftClockin.row.breaks.find(
			(entry) => entry.id === breakId
		);

		if (!breakEntity) return;

		const originalDate = breakEntity.clocked[managedShiftClockin.type];
		const originalTime = moment
			.utc(originalDate, constants.dateFormat)
			.format(constants.timeShort);

		// new date object with old time - shortDate format
		const updatedDate =
			managedShiftClockin.break.clocked[managedShiftClockin.type];
		const newDate = moment
			.utc(`${updatedDate}T${originalTime}`, constants.dateFormat)
			.format(constants.dateFormat);

		// update the row
		const row = {
			...managedShiftClockin.row,
			breaks: managedShiftClockin.row.breaks.map((entry) => {
				if (entry.id === breakId) {
					return {
						...entry,
						clocked: {
							...entry.clocked,
							[managedShiftClockin.type]: newDate,
						},
					};
				}

				return entry;
			}),
		};

		updateEntry(row);
		handleClose();

		setFeedback(phrases.BREAK_DATE_ADJUSTED, 1);
	}

	render() {
		const {
			handleClose,
			modalType,
			breakObject,
			addBreakModalUpdateBreak,
			managedShiftClockin,
			updateManagedShiftClockin,
		} = this.props;

		const steps = [
			...(modalType === enums.MODAL_TYPES.MARK_MANAGED_CLOCKIN_AS_NO_SHOW
				? [
						{
							component: <MarkManagedClockinNoShow />,
							title: phrases.MARK_MANAGED_CLOCKIN_AS_NO_SHOW,
							isValid: true,
							onNext: this.onAdjustManagedClockinSubmit,
							onBack: handleClose,
							loading: this.state.loading,
							confirmButtonLabel: phrases.MARK_AS_NO_SHOW,
							cancelButtonLabel: phrases.CANCEL,
							noScroll: false,
						},
				  ]
				: []),
			...(modalType === enums.MODAL_TYPES.ADD_BREAK
				? [
						{
							component: (
								<MarkManagedClockinAddBreak
									breakObject={breakObject}
									addBreakModalUpdateBreak={addBreakModalUpdateBreak}
									getBreakEndsOnAnotherDay={this.getBreakEndsOnAnotherDay}
								/>
							),
							title: phrases.ADD_BREAK,
							isValid: this.getIsAddBreakStepValid(),
							onNext: this.correctShiftClockinBreakWrapper,
							loading: this.state.loading,
							confirmButtonLabel: phrases.ADD,
							noScroll: false,
						},
				  ]
				: []),
			...(modalType === enums.MODAL_TYPES.ADJUST_CLOCKED
				? [
						{
							component: (
								<AdjustClockedValues
									managedShiftClockin={managedShiftClockin}
									updateManagedShiftClockin={updateManagedShiftClockin}
								/>
							),
							title: phrases.ADJUST_CLOCKED_DATE_TITLE,
							isValid: managedShiftClockin?.clocked[managedShiftClockin?.type],
							onNext: this.adjustClockedDate,
							loading: this.state.loading,
							confirmButtonLabel: phrases.ADJUST_CLOCKED_DATE_SUBMIT,
							noScroll: false,
						},
				  ]
				: []),
			...(modalType === enums.MODAL_TYPES.ADJUST_BREAK_DATES
				? [
						{
							component: (
								<AdjustBreakDates
									managedShiftClockin={managedShiftClockin}
									updateManagedShiftClockin={updateManagedShiftClockin}
								/>
							),
							title: phrases.ADJUST_BREAKS_TITLE,
							isValid:
								!!managedShiftClockin?.break?.clocked?.[
									managedShiftClockin?.type
								],
							onNext: this.adjustBreakDate,
							loading: this.state.loading,
							confirmButtonLabel: phrases.ADJUST_CLOCKED_DATE_SUBMIT,
							noScroll: false,
						},
				  ]
				: []),
		];

		console.log({ steps });

		const startStep = 0;

		return (
			<ModalStepper
				ref={this.modalRef}
				isOpen
				steps={steps}
				showStep={false}
				startStep={startStep}
				onClose={handleClose}
			/>
		);
	}
}

ShiftClockinsModal.propTypes = {
	handleClose: PropTypes.func,
	modalType: PropTypes.oneOf([enums.ADJUST_MANAGED_CLOCKIN]),
	managedShiftClockin: PropTypes.object,
	postManagedClockinCorrection: PropTypes.func,
	addBreakModalUpdateBreak: PropTypes.func,
	updateEntry: PropTypes.func,
	updateManagedShiftClockin: PropTypes.func,
	breakObject: PropTypes.object,
};

export default ShiftClockinsModal;
