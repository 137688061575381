import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setUiData } from '../store/tenantTransferModal.actions';

// components
import { InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';

// utils
import { fetchTenants } from './../tenantTransferModal.service';
import { getSelectOptions } from 'services/utils';

// phrases
import phrases from './../tenantTransferModal.phrases';

const TenantTransferModalStepOne = ({ userId, activeTenant }) => {
	const [tenants, setTenants] = useState([]);
	const dispatch = useDispatch();
	const { tenant } = useSelector((store) => store.tenantTransfer.ui);

	const onSelectChange = (key, value) => {
		const payload = {
			tenant: value,
		};

		dispatch(setUiData(payload));
	};

	useEffect(() => {
		fetchTenants({ userId }).then((res) => {
			setTenants(res.data);
		});
	}, [userId]);

	const tenantOptions = getSelectOptions(tenants).filter((entry) => entry.value !== activeTenant.id);

	return (
		<ModalRow>
			<InputCollectionSelect
				id="tenant-select"
				label={phrases.STEP_ONE_CANCEL_SELECT_LABEL}
				placeholder={phrases.STEP_ONE_CANCEL_SELECT_PLACEHOLDER}
				value={tenant}
				handleChange={onSelectChange}
				cache
				options={tenantOptions}
				optionFormat={(entry) => ({
					value: entry.id,
					label: entry.name,
				})}
				inputFilterFormat={(input) => `:name=like='%${input}%'`}
			/>
		</ModalRow>
	);
};

TenantTransferModalStepOne.propTypes = {
	userId: PropTypes.number,
	activeTenant: PropTypes.object,
};

export default TenantTransferModalStepOne;
