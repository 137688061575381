export const RESET_STATE = 'NUTRITION_VALUES/RESET_STATE';
export const SET_NUTRITION_VALUE = 'NUTRITION_VALUES/SET_NUTRITION_VALUE';
export const UPDATE_NUTRITION_VALUE = 'NUTRITION_VALUES/UPDATE_NUTRITION_VALUE';

export const UPDATE_BATCH_FORM = 'NUTRITION_VALUES/UPDATE_BATCH_FORM';
export const EDIT_BATCH_LIST_ITEM = 'NUTRITION_VALUES/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM = 'NUTRITION_VALUES/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST = 'NUTRITION_VALUES/ADD_BATCH_LIST';

/**
 * @param {object} payload
 */
export function setNutritionValue(payload) {
	return {
		type: SET_NUTRITION_VALUE,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function updateNutritionValue(payload) {
	return {
		type: UPDATE_NUTRITION_VALUE,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET_STATE,
	};
}

// BATCH
export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}
