import { ActionType } from 'types/redux';
import {
	IMarketResponsibility,
	IMarketResponsibilityBatch,
	IMarketResponsibilityEdit,
} from './../types';

export const SET_MARKET_RESPONSIBILITY =
	'MARKET_RESPONSIBLITIES/SET_MARKET_RESPONSIBILITY';
export const UPDATE_MARKET_RESPONSIBILITY =
	'MARKET_RESPONSIBLITIES/UPDATE_MARKET_RESPONSIBILITY';
export const RESET_MARKET_RESPONSIBILITY =
	'MARKET_RESPONSIBLITIES/RESET_MARKET_RESPONSIBILITY';

export const EDIT_BATCH_LIST_ITEM =
	'MARKET_RESPONSIBLITIES/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'MARKET_RESPONSIBLITIES/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'MARKET_RESPONSIBLITIES/ADD_BATCH_LIST_ITEM_ROW';
export const RERENDER_TABLE = 'MARKET_RESPONSIBLITIES/RERENDER_TABLE';

export function setMarketResponsiblity(
	payload: IMarketResponsibilityEdit
): ActionType {
	return {
		type: SET_MARKET_RESPONSIBILITY,
		payload,
	};
}

type updateMarketResponsiblityType = Partial<IMarketResponsibility>;
export function updateMarketResponsiblity(
	payload: updateMarketResponsiblityType
): ActionType {
	return {
		type: UPDATE_MARKET_RESPONSIBILITY,
		payload,
	};
}

export function resetMarketResponsiblity(): ActionType {
	return {
		type: RESET_MARKET_RESPONSIBILITY,
	};
}

type editBatchListItemType = Partial<IMarketResponsibilityBatch>;
export function editBatchListItem(payload: editBatchListItemType): ActionType {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload: string): ActionType {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow(): ActionType {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}

export function rerenderTable(): ActionType {
	return {
		type: RERENDER_TABLE,
	};
}
