import {
	RESET_STATE,
	LOADING,
	LOADING_PRODUCT,
	EDIT_GROUP,
	EDIT_PRODUCT_TILE
} from './addTileModal.actions';

const defaultState = {
	ui: {
		loading: false,
		loadingProduct: false
	},
	data: {
		productTile: {
			background_color: 'ffffff',
			description: '',
			// id: null,
			name: '',
			tile_layout_sub_group: null,
			product: null,
			position: null,
			title_color: '464646',
			type: 'Product'
		},
		group: {
			background_color: 'ffffff',
			name: '',
			description: '',
			asset_collection: null,
			position: null,
			show_in_menu: false,
			tile_position_focus: 1,
			title_color: '464646'
		}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case EDIT_PRODUCT_TILE: {
		return {
			...state,
			data: {
				...state.data,
				productTile: { ...state.data.productTile, ...payload }
			}
		};
	}

	case EDIT_GROUP: {
		return {
			...state,
			data: {
				...state.data,
				group: { ...state.data.group, ...payload }
			}
		};
	}

	case LOADING: {
		return {
			...state,
			ui: { ...state.ui, loading: payload }
		};
	}

	case LOADING_PRODUCT: {
		return {
			...state
		};
	}

	case RESET_STATE: {
		return defaultState;
	}

	default:
		return state;
	}
}

export default reducer;
