export default {
	TRANSFER_TYPE: {
		PUBLIC: 'Public',
		SWAP: 'Swap',
	},
	// states for the transfer of the shift itself
	TRANSFER: {
		AVAILABLE: 'Available',
		APPLIED: 'Applied',
		APPROVED: 'Approved',
		RETRACTED: 'Retracted',
		DECLINED: 'Declined',
	},
	// states for the applications for the transfer of the shift
	APPLICATIONS: {
		AWAITING_APPLICANT_APPROVAL: 'Awaiting Applicant Approval',
		AWAITING_MANAGER_APPROVAL: 'Awaiting Manager Approval',
		APPROVED: 'Approved',
		DECLINED: 'Declined',
		TRANSFER_RETRACTED: 'Transfer Retracted',
	},
	// different operations for editing a transfer
	OPERATIONS: {
		APPROVE_APPLICATION: 'Approve Application',
		DECLINE_APPLICATION: 'Decline Application',
		DECLINE: 'Decline',
		RETRACT: 'Retract',
		ACCEPT: 'Accept',
	},
	POLICIES: {
		MAKE_SHIFT_PUBLIC_AVAILABLE: 'Make Shift Public Available',
		SWAP_SHIFT: 'Swap Shift',
		REQUEST_PUBLIC_SHIFT: 'Request Public Shift',
		ACCEPT_SWAP: 'Accept Swap',
	},
	SHIFT_ACTIONS: {
		ADD: 'Add',
		UPDATE: 'Update',
		REMOVE: 'Remove',
	},
};
