'use strict';

import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import ReactDOM from 'react-dom';
import HotkeysService from 'hotkeys.service';

import { Button, Icon, Backdrop } from 'dumb';

import './overlaySide.css';

export default class OverlaySide extends PureComponent {
	constructor(props) {
		super(props);

		this._handleOnClose = this._handleOnClose.bind(this);
		this._handleCloseOnEsc = this._handleCloseOnEsc.bind(this);

		// get modal-root element to create portal.
		this.rootElement = document.createElement('div');
		this.rootElement.classList.add('j-overlay-side-container');

		document.body.appendChild(this.rootElement);

		// get view container - for disabling scroll when overlay open
		this.viewContainer = document.getElementsByClassName('view');

		HotkeysService.defineHotkeys({
			keys: 'esc',
			callback: (e, handler) => {
				e.preventDefault();

				switch (handler.key) {
					case 'esc':
						this._handleCloseOnEsc();
						break;
					default:
						break;
				}
			},
		});
	}

	_handleCloseOnEsc() {
		const { closeOnEsc, visible } = this.props;

		if (closeOnEsc && visible) this._handleOnClose();
	}

	_handleOnClose() {
		if (!this.props.onClose) {
			console.info('You didnt specify onClose ');
			return;
		}
		// remove no scroll class name
		// add class to .view (--no-scroll)

		this.viewContainer?.[0]?.classList?.remove('view--no-scroll');

		this.props.onClose();
	}

	render() {
		const { visible, zIndex, nospace, title } = this.props;

		const overlayClassnames = cx('overlay-side', {
			'overlay-side--active': visible,
			'overlay-side--nospace': nospace,
		});

		return ReactDOM.createPortal(
			<>
				<Backdrop isOpen={visible} closeBackdrop={this._handleOnClose} zIndex={zIndex - 1} />

				<CSSTransition
					classNames="overlay-side-transition"
					mountOnEnter
					unmountOnExit
					timeout={500}
					appear
					in={visible}
					className={overlayClassnames}
				>
					<div style={zIndex ? { zIndex: zIndex } : null}>
						<div className="overlay-side__header">
							{title && <h2>{title}</h2>}
							<Button type="inverted" size="medium" onClick={() => this._handleOnClose()}>
								<Icon name="close" />
							</Button>
						</div>
						<div className="overlay-side__content">{this.props.children}</div>
					</div>
				</CSSTransition>
			</>,
			this.rootElement
		);
	}
}

OverlaySide.defaultProps = {
	visible: false,
	closeOnEsc: false,
	onDragHeight: () => {},
	isDragging: () => {},
};

OverlaySide.propTypes = {
	closeOnEsc: PropTypes.bool,
	visible: PropTypes.bool.isRequired,
	zIndex: PropTypes.number,
	onClose: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
	nospace: PropTypes.bool,
	title: PropTypes.string,
};
