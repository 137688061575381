// lodash
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

// enums
import enums from './../productVariantBasketRecommendations.enums';

/**
 * @function getProductVariantBasketRecommendationFilter
 * @description returns a filter string (filters on product variants)
 */
export function getProductVariantBasketRecommendationFilter(
	productVariants,
	filterArray
) {
	let filterOne;
	if (!_isEmpty(productVariants)) {
		const arrayOfIds = productVariants.reduce((acc, currentValue, index) => {
			const id = currentValue.id;

			acc =
				productVariants.length - 1 === index
					? `${acc}'${id}']`
					: `${acc}'${id}',`;

			return acc;
		}, '[');

		filterOne = `:product_variant.id=IN=${arrayOfIds}`;
	}

	let filterTwo;
	if (!_isEmpty(filterArray)) {
		// remove productVariant filter as it's not relevant when querying for recommendations
		filterArray = filterArray.filter((entry) => entry.id !== 'productVariant');

		// custom way of adding classic FIQL filters product_variant_basket_recommendations endpoint
		filterTwo = filterArray.reduce((acc, currentValue, index) => {
			if (currentValue.id === 'recommendation')
				acc = acc
					? `${acc};:recommendation_priority.name=like='%${currentValue.value}%'`
					: `:recommendation_priority.name=like='%${currentValue.value}%'`;
			if (currentValue.id === 'exclusion')
				acc = acc
					? `${acc};:exclude.name=like='%${currentValue.value}%'`
					: `:exclude.name=like='%${currentValue.value}%'`;

			return acc;
		}, '');
	}

	let filterToReturn = '';
	if (filterOne) filterToReturn = filterOne;
	if (filterTwo)
		filterToReturn = filterToReturn
			? `${filterToReturn};${filterTwo}`
			: filterTwo;

	return filterToReturn;
}

/**
 * @function _getProductVariantsFilter
 * @description returns a filter string (filters on product variants)
 */
function _getProductVariantsFilter(productVariantBasketRecommendations) {
	const arrayOfIds = productVariantBasketRecommendations.reduce(
		(acc, currentValue, index) => {
			const id = _get(currentValue, 'product_variant.id', null);

			acc =
				productVariantBasketRecommendations.length - 1 === index
					? `${acc}'${id}']`
					: `${acc}'${id}',`;

			return acc;
		},
		'['
	);

	return `:id=IN=${arrayOfIds}`;
}

/**
 * @function mapBasketRecommendationsToProductVariants
 * @param {Object} object - contains product variants and basket recommendations (if any)
 * @description mapps all basket recommendations to product variants
 */
export function mapBasketRecommendationsToProductVariants({
	productVariants,
	productVariantBasketRecommendations,
}) {
	return productVariants.map((entry) => {
		// find associated recommendation
		const recommendation = productVariantBasketRecommendations.find(
			(e) => e.product_variant.id === entry.id
		);

		return {
			id: _uniqueId(),
			product_variant: entry,
			...(recommendation && {
				productVariantBasketRecommendationId: recommendation.id,
				recommendation_priority: recommendation.recommendation_priority || null,
				exclude: recommendation.exclude || null,
			}),
		};
	});
}

/**
 * @function mapProductVariantsToBasketRecommendations
 * @param {Object} object - contains product variants and basket recommendations (if any)
 * @description mapps all product variants to basket recommendations
 */
export function mapProductVariantsToBasketRecommendations({
	productVariants,
	productVariantBasketRecommendations,
}) {
	return productVariantBasketRecommendations
		.map((entry) => {
			// find product variant in question
			const productVariant = productVariants.find(
				(productVariant) => productVariant.id === entry.product_variant.id
			);

			return {
				id: _uniqueId(),
				product_variant: productVariant,
				productVariantBasketRecommendationId: entry.id,
				recommendation_priority: entry.recommendation_priority || null,
				exclude: entry.exclude || null,
			};
		})
		// filter out all recommendations with no product variant assigned to them
		// this makes sense if we're showing recommendations first
		.filter((x) => !!x.product_variant);
}

export function formatRecommendationPayload(basketRecommendation) {
	// format [{group_1: 1}, {group_2: 14}, {group_3: 10}...]
	const recommendations = (basketRecommendation.recommendations || []).reduce(
		(acc, currentValue, index) => {
			const entry = {
				[`group_${index + 1}`]: currentValue.value?.value?.id ?? null,
			};

			return {
				...acc,
				...entry,
			};
		},
		{}
	);

	return {
		name: basketRecommendation.name,
		type: enums.RECOMMENDATION,
		...(basketRecommendation.description && {
			description: basketRecommendation.description,
		}),
		...(basketRecommendation.sortOrder && {
			sort_order: basketRecommendation.sortOrder,
		}),
		...recommendations,
	};
}

export function formatExclusionPayload(basketExclusion) {
	// format [{group_1: 1}, {group_2: 14}, {group_3: 10}...]
	const exclusions = (basketExclusion.exclusions || []).reduce(
		(acc, currentValue, index) => {
			const entry = {
				[`group_${index + 1}`]: currentValue.value?.value?.id ?? null,
			};

			return {
				...acc,
				...entry,
			};
		},
		{}
	);

	return {
		name: basketExclusion.name,
		type: enums.EXCLUSION,
		...(basketExclusion.description && {
			description: basketExclusion.description,
		}),
		...(basketExclusion.sortOrder && {
			sort_order: basketExclusion.sortOrder,
		}),
		...exclusions,
	};
}

/**
 * @function splitDataIntoAddEdit
 * @param {Object} Object - contains data from the modal - all selected product variants + recommendation/exclusions that were either edited or added
 * @description splits the selected rows into 2 categories -> to add and to edit
 * We need this since product variants in the table don't have to have a recommendation added to them. If they do then we edit. If they don't, we add
 * @returns object containing formatted values to be added/edited
 */
export function splitDataIntoAddEdit({ batchFormData, res }) {
	return batchFormData.productVariants.reduce(
		(acc, currentValue, index) => {
			const toEdit = !!currentValue.productVariantBasketRecommendationId;

			const payload = {
				...(toEdit
					? {
							id: currentValue.productVariantBasketRecommendationId,
					  }
					: {
							product_variant: currentValue.productVariant?.id,
					  }),
				recommendation_priority: res[0],
				exclude: res[1],
			};

			if (toEdit) {
				return {
					...acc,
					dataToEdit: [...acc.dataToEdit, payload],
				};
			} else {
				return {
					...acc,
					dataToAdd: [...acc.dataToAdd, payload],
				};
			}
		},
		{ dataToAdd: [], dataToEdit: [] }
	);
}

export function formatDataForModal(entry) {
	return {
		productVariant: entry.product_variant,
		...(entry.recommendation_priority && {
			existingBasketRecommendation: {
				value: entry.recommendation_priority,
				label: entry.recommendation_priority.name,
			},
			productVariantBasketRecommendationId:
				entry.productVariantBasketRecommendationId,
		}),
		...(entry.exclude && {
			existingBasketExclusion: {
				value: entry.exclude,
				label: entry.exclude.name,
			},
		}),
	};
}

export function getNameFilter(state) {
	const productVariantFilter = _isEmpty(state.filterArray)
		? false
		: state.filterArray.find((entry) => entry.id === 'productVariant');

	return productVariantFilter
		? `:product_variant.name=like='%${productVariantFilter.value}%'`
		: '';
}

export function getIdFilter(productVariantBasketRecommendations) {
	return _isEmpty(productVariantBasketRecommendations)
		? ''
		: _getProductVariantsFilter(productVariantBasketRecommendations);
}

export function getProductCategoryFilter(state) {
	const productCategoryFilter = _isEmpty(state.filterArray)
		? false
		: state.filterArray.find((entry) => entry.id === 'productCategory');
	return productCategoryFilter
		? `:product.product_category.name=like='%${productCategoryFilter.value}%'`
		: '';
}

export function constructFinalProductVariantFilter({
	nameFilter,
	idFilter,
	categoryFilter,
	legacyFilterOn,
}) {
	let finalFilter = '';
	if (nameFilter) finalFilter = nameFilter;
	if (idFilter)
		finalFilter = finalFilter ? `${finalFilter};${idFilter}` : idFilter;
	if (categoryFilter)
		finalFilter = finalFilter
			? `${finalFilter};${categoryFilter}`
			: categoryFilter;
	if (legacyFilterOn)
		finalFilter = finalFilter
			? `${finalFilter};:legacy=='false'`
			: `:legacy=='false'`;

	return finalFilter;
}
