import { checkAllBusyHoursSet } from './../utils/reviewDay.utils';

import {
	// DAYS_OVERVIEW_FETCH_SUCCESS,
	SET_DAY_OCCUPATION,
	SET_REVIEW_COMMENT,
	DAY_SELECTED,
	WORKPLACE_SELECTED,
	RESET_STATE,
	LOADING,
	SET_DAY_REVIEW_VISIBLE,
	SET_CLOCKINGS_DATA,
	SET_CLOCK_OUT_TIME,
	UPDATE_CLOCKING_DATA,
	REMOVE_CLOCKING_BREAK,
	REMOVE_CLOCKING,
	UPDATE_SHIFT_REVIEW,
	UPDATE_ROGUE_CLOCKING,
	REMOVE_ASSOCIATED,
	ADD_SHIFTS_TO_REVIEW,
	ADD_CLOCKINGS_TO_REVIEW,
} from './dayReview.actions';

// Tools
import _get from 'lodash/get';

const defaultState = {
	ui: {
		dayReviews: {},
		busyHalfHourKeys: [],
		reviewComment: '',
		allBusyHoursResponded: false,
		loading: false,
		isOpen: false,
		workDayId: null,
		loadingManage: [],
	},
	data: {
		workplaceId: null,
		date: '',
		daysOverview: [],
		shifts: [],
		clockings: [],
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_DAY_REVIEW_VISIBLE: {
			return {
				...state,
				ui: { ...state.ui, isOpen: payload || !state.ui.isOpen },
			};
		}

		case RESET_STATE: {
			return defaultState;
		}

		case LOADING: {
			return { ...state, ui: { ...state.ui, loading: payload } };
		}

		// case DAYS_OVERVIEW_FETCH_SUCCESS: {
		// 	const daysOverviewKeys = Object.keys(payload);
		// 	const busyHalfHourKeys = daysOverviewKeys.filter((value) => {
		// 		return payload[value].heat === 'Too Busy';
		// 	});

		// 	const dayReviews = {};
		// 	let allBusyHoursSet = false;

		// 	// Check if the one of the day reviews has an id, this means all reviews will have an id and that this day has already been reviewed.
		// 	if (payload.length > 0 && payload[0].id) {
		// 		// If a day has been reviewed before set the reviewed times to the ui dayReviews. So that the user can see what review state was set.
		// 		busyHalfHourKeys.forEach((index) => {
		// 			dayReviews[index] = payload[index];
		// 		});

		// 		allBusyHoursSet = checkAllBusyHoursSet(busyHalfHourKeys, dayReviews);
		// 	}

		// 	return {
		// 		...state,
		// 		data: { ...state.data, daysOverview: payload },
		// 		ui: {
		// 			...state.ui,
		// 			allBusyHoursResponded: allBusyHoursSet,
		// 			dayReviews: dayReviews,
		// 			busyHalfHourKeys: busyHalfHourKeys,
		// 		},
		// 	};
		// }

		case SET_DAY_OCCUPATION: {
			// Set the proper grade to the proper day review
			const dayReview = {
				...state.data.daysOverview[payload.id],
				graded_heat: payload.occupation,
			};

			// Add the revised day review to the other day reviews
			const dayReviews = { ...state.ui.dayReviews, [payload.id]: dayReview };

			const allBusyHoursSet = checkAllBusyHoursSet(
				state.ui.busyHalfHourKeys,
				dayReviews
			);

			return {
				...state,
				ui: {
					...state.ui,
					allBusyHoursResponded: allBusyHoursSet,
					dayReviews: dayReviews,
				},
			};
		}

		case DAY_SELECTED: {
			return {
				...state,
				data: {
					...state.data,
					date: payload,
				},
			};
		}

		case WORKPLACE_SELECTED: {
			return {
				...state,
				data: {
					...state.data,
					workplaceId: payload,
				},
			};
		}

		case SET_REVIEW_COMMENT: {
			return {
				...state,
				ui: {
					...state.ui,
					reviewComment: payload,
				},
			};
		}

		case SET_CLOCK_OUT_TIME: {
			return {
				...state,
				data: {
					...state.data,
					clockings: state.data.clockings.map((clocking) => {
						if (payload.id === clocking.id) return payload;
						return clocking;
					}),
				},
			};
		}

		case SET_CLOCKINGS_DATA: {
			// only show if employee attached
			const shifts = payload.shifts.filter((x) => {
				return x.shift_employees.length > 0;
			});

			return {
				...state,
				ui: {
					...state.ui,
					workDayId: payload.workDayId, // Added to be able to close day
				},
				data: {
					...state.data,
					shifts,
					clockings: payload.clockings,
				},
			};
		}

		case UPDATE_SHIFT_REVIEW: {
			const shifts = state.data.shifts.map((x) => {
				if (!payload.id)
					console.info(
						'Something went wrong, ID of shift does not exist in review reducer!'
					);
				if (x.id === payload.id) {
					x = payload;
				}
				return x;
			});

			return {
				...state,
				data: {
					...state.data,
					shifts,
				},
			};
		}

		case UPDATE_CLOCKING_DATA: {
			let shifts = state.data.shifts;
			let clockings = state.data.clockings;

			const newClocking = payload.data.shift_employees[0].shift_clockings[0];

			// If shift does not exist - push
			const shiftExist = shifts.some((shift) => shift.id === payload.data.id);

			// Update existing shifts
			if (shiftExist) {
				shifts = shifts.map((shift) =>
					shift.id === payload.data.id
						? {
								...shift,
								planned_period: payload.data.planned_period
									? payload.data.planned_period
									: shift.planned_period,
								is_zero_correction: payload.data.is_zero_correction,
								shift_employees: shift.shift_employees.map((shiftEmployee) => {
									return {
										...shiftEmployee,
										shift_clockings:
											shiftEmployee.shift_clockings &&
											shiftEmployee.shift_clockings.length
												? shiftEmployee.shift_clockings.map((shiftClocking) => {
														return {
															...shiftClocking,
															...newClocking,
														};
												  })
												: [newClocking],
									};
								}),
						  }
						: shift
				);
			} else {
				shifts = [...shifts, payload.data];
			}

			// Filter out, remove loose clocking from list
			clockings = clockings.filter(
				(clocking) => clocking.id !== newClocking.id
			);

			return {
				...state,
				data: {
					...state.data,
					shifts,
					clockings,
				},
			};
		}

		case REMOVE_CLOCKING_BREAK: {
			let shifts = state.data.shifts;

			// Update existing shifts
			shifts = shifts.map((shift) =>
				shift.id === payload.shift.id
					? {
							...shift,
							shift_employees: shift.shift_employees.map((shiftEmployee) => {
								return {
									...shiftEmployee,
									shift_clockings: shiftEmployee.shift_clockings.map(
										(shiftClocking) => {
											const breaks = shiftClocking.shift_clocking_breaks.filter(
												(x) => x.id !== payload.breakId
											);
											return {
												...shiftClocking,
												shift_clocking_breaks: breaks,
											};
										}
									),
								};
							}),
					  }
					: shift
			);

			return {
				...state,
				data: {
					...state.data,
					shifts,
				},
			};
		}

		case REMOVE_CLOCKING: {
			const newClockings = state.data.clockings.filter((x) => x.id !== payload);

			return {
				...state,
				data: {
					...state.data,
					clockings: newClockings,
				},
			};
		}

		case UPDATE_ROGUE_CLOCKING: {
			return {
				...state,
				data: {
					...state.data,
					clockings: state.data.clockings.map((clocking) => {
						if (payload.id === clocking.id) return payload;
						return clocking;
					}),
				},
			};
		}

		case REMOVE_ASSOCIATED: {
			// Filter out clocking with specific personId and date
			const newClockings = state.data.clockings.filter((x) => {
				return x.person.id !== payload.personId;
			});

			// Filter out clocking with specific personId and date
			const newShifts = state.data.shifts.filter(
				(x) =>
					_get(x, 'shift_employees[0].employment.person.id', '') !==
					payload.personId
			);

			return {
				...state,
				data: {
					...state.data,
					shifts: newShifts,
					clockings: newClockings,
				},
			};
		}

		case ADD_SHIFTS_TO_REVIEW: {
			// filter out all shifts for that employee
			const personId = _get(
				payload,
				'[0].shift_employees[0].employment.person.id',
				undefined
			);

			// if shifts already exist, remove them
			const filteredShifts = [...state.data.shifts].filter(
				(entry) =>
					personId !==
					_get(entry, 'shift_employees[0].employment.person.id', null)
			);

			return {
				...state,
				data: {
					...state.data,
					shifts: [...filteredShifts, ...payload],
				},
			};
		}

		// case ADD_SHIFTS_TO_REVIEW: {
		// 	// if shifts already exist, remove them
		// 	const shiftsNoDuplicates = [...state.data.shifts].reduce(
		// 		(acc, currentValue) => {
		// 			const foundShift = payload.find(
		// 				(entry) => entry.id === currentValue.id
		// 			);

		// 			if (!foundShift) return [...acc, currentValue];

		// 			return acc;
		// 		},
		// 		[]
		// 	);

		// 	return {
		// 		...state,
		// 		data: {
		// 			...state.data,
		// 			shifts: [...shiftsNoDuplicates, ...payload],
		// 		},
		// 	};
		// }

		case ADD_CLOCKINGS_TO_REVIEW: {
			const { clockins, personId } = payload;

			// if clocking doesnt have a shift id its a rogue clockin
			// ex: if a juicer went to work w/o a shift, he can still create a clockin
			const rogueClockins = clockins.filter(
				(x) => !_get(x, 'shift_employee.shift.id', false)
			);

			// remove all clockins for the user
			const filteredClockins = [...state.data.clockings].filter(
				(entry) => _get(entry, 'person.id', null) !== personId
			);

			return {
				...state,
				data: {
					...state.data,
					clockings: [...filteredClockins, ...rogueClockins],
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
