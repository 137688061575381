'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

// external services
import _isNil from 'lodash/isNil';

import PrintViewService from './printView.service';
import PrintView from './printView.component';

import MailToComponent from './../mailToComponent/mailTo.component';

import './printView.css';

function printViewContainer(printComponent, options, customStyles) {
	class PrintViewContainer extends Component {
		constructor(props) {
			super(props);

			this.state = {
				error: null,
				success: null,
				loading: false,
				xslxLoading: false,
				pdfLoading: false,
				mailLoading: false,
				mailLoaded: false,
				mailToVisible: false,
				emailFormValid: false,
			};

			this.componentRoot = document.getElementById('printview-root');
			this.requiredInputFields = ['subject', 'content'];

			this.PrintViewService = new PrintViewService(options, customStyles);
			this.methods = this.PrintViewService.getMethods();
			this.phrases = this.PrintViewService.getPhrases();

			this.printToXlsx = this.printToXlsx.bind(this);
			this.printToPdf = this.printToPdf.bind(this);
			this.returnPDFAsBase64 = this.returnPDFAsBase64.bind(this);
			this.closeMailToComponent = this.closeMailToComponent.bind(this);
		}

		printToXlsx() {
			this.setState({ xslxLoading: true });

			this.methods
				.printToXlsx()
				.then(() => {
					this.setState({ xslxLoading: false });
				})
				.catch(() => {
					this.setState({ xslxLoading: false });
				});
		}

		printToPdf() {
			this.setState({ pdfLoading: true });

			this.methods
				.printToPdf()
				.then(() => {
					this.setState({ pdfLoading: false });
				})
				.catch(() => {
					this.setState({ pdfLoading: false });
				});
		}

		returnPDFAsBase64() {
			this.setState(() => ({ mailLoading: true, mailToVisible: true }));

			this.methods.returnPDFAsBase64().then(
				(base64) => {
					base64 = base64.split(',');
					const files = { [`${options.title}.pdf`]: base64[1] };
					this.setState(() => ({
						mailLoading: false,
						mailLoaded: true,
						files,
					}));
				},
				() => {
					this.setState(() => ({ error: true }));
				}
			);
		}

		closeMailToComponent() {
			this.setState({ mailToVisible: false });
		}

		render() {
			const { emails, showMailToComponent, active, emailSubject, emailBody } =
				this.props;

			const zIndex = 461;

			const filesLoaded = !_isNil(this.state.files);

			return active
				? ReactDOM.createPortal(
					<>
						<PrintView
							{...this.props}
							{...this.state}
							pdfMethod={this.printToPdf}
							xslxMethod={this.printToXlsx}
							mailMethod={this.returnPDFAsBase64}
							phrases={this.phrases}
							ComponentToPrint={printComponent}
							/>

						{showMailToComponent && (
						<MailToComponent
							handleClose={this.closeMailToComponent}
							visible={this.state.mailToVisible}
							emails={emails}
							error={this.state.error}
							success={this.state.success}
							files={this.state.files}
							filesLoaded={filesLoaded}
							loading={
										this.state.mailLoading ||
										this.state.pdfLoading ||
										this.state.loading
									}
							emailFormValid={this.state.mailLoaded}
							zIndex={zIndex}
							subject={emailSubject}
							content={emailBody}
								/>
							)}
					</>,
						this.componentRoot
				  )
				: null;
		}
	}

	PrintViewContainer.propTypes = {
		active: PropTypes.bool.isRequired,
		handleClose: PropTypes.func.isRequired,
		showMailToComponent: PropTypes.bool,
		emails: PropTypes.array,
		emailSubject: PropTypes.string,
		emailBody: PropTypes.string,
	};

	PrintViewContainer.defaultProps = {
		handleClose() {},
		showMailToComponent: true,
		showXLSX: false,
		emailSubject: '',
		emailBody: '',
	};

	return PrintViewContainer;
}

export default printViewContainer;
