import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';

import GlobalSalaryComponentsList from './services/globalSalaryComponentsList.service.js';

const globalSalaryComponentsListReduxKey = '/salary/global_salary_components';
const globalSalaryComponentsList = new GlobalSalaryComponentsList(globalSalaryComponentsListReduxKey);
const globalSalaryComponentsListProps = globalSalaryComponentsList.getProps();

export default function GlobalSalaryComponents() {
	useEffect(() => {
		globalSalaryComponentsList.initView();
	}, []);

	return <ListComponent reduxKey={globalSalaryComponentsListReduxKey} {...globalSalaryComponentsListProps} />;
}
