'use strict';

// enums
import enums from './../myShiftplanner.enums';

import {
	SET_FILTER_DISTANCE,
	SET_FILTERED_WORKPLACES,
	SET_SELECTED_WORKPLACE,
	SET_NEAREST_WORKPLACES,
	RESET_STATE,
} from './shiftMarketplaceFilters.actions';

const defaultState = {
	persist: true,
	filteredWorkplaces: [],
	selectedWorkplace: null,
	distance: enums.SHIFT_MARKETPLACE.DEFAULT_WORKPLACE_DISTANCE_FILTER,
	nearestWorkplaces: [],
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
		case SET_FILTER_DISTANCE: {
			return {
				...state,
				distance: payload,
			};
		}

		case SET_FILTERED_WORKPLACES: {
			return {
				...state,
				filteredWorkplaces: payload,
			};
		}

		case SET_SELECTED_WORKPLACE: {
			return {
				...state,
				selectedWorkplace: payload,
			};
		}

		case SET_NEAREST_WORKPLACES:
			return {
				...state,
				nearestWorkplaces: payload,
			};

		case RESET_STATE:
			return defaultState;

		default:
			return state;
	}
}

export default reducer;
