'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, Icon, Tooltip, Legend, Tag } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// constants/phrases
import phrases from './../../../nutritionValues.phrases';

// lodash
import _get from 'lodash/get';

class NutritionValuesBatchStepList extends Component {
	constructor(props) {
		super(props);

		this.cantAddBulletColor = 'ffe699';
	}

	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		this.props.editBatchListItem({
			identifier: row.identifier,
			[type]: e.target.value,
		});
	}

	render() {
		const { batchListData, removeBatchListItem, useGlobalResource } = this.props;

		return (
			<>
				<div className="modal-batch__legend">
					<Legend
						points={[
							{
								bulletColor: `#${this.cantAddBulletColor}`,
								label: phrases.MODAL_BATCH_STEP_LIST_LEGEND_CANT_ADD,
							},
						]}
					/>
				</div>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							{!useGlobalResource && <th>{phrases.MODAL_BATCH_STEP_MARKET}</th>}
							<th>{phrases.MODAL_BATCH_STEP_LIST_INGREDIENT}</th>
							<th>{phrases.MODAL_BATCH_STEP_LIST_NUTRITION_CATEGORY}</th>
							<th>{phrases.MODAL_BATCH_STEP_LIST_VALUE}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{batchListData.map((entry, i) => {
							const rowDisabled = !entry.canAdd;

							return (
								<TableListRow key={entry.identifier} type={rowDisabled && 'light'}>
									<TableListCell>
										<div className="nutrition-values__cant-add-wrapper">
											{rowDisabled && <Tag type="bullet" color={this.cantAddBulletColor} />}
											{i + 1}
										</div>
									</TableListCell>

									{!useGlobalResource && <TableListCell>{_get(entry, 'market.label', false)}</TableListCell>}
									<TableListCell>{_get(entry, 'ingredient.label', '')}</TableListCell>
									<TableListCell>{_get(entry, 'nutritionCategory.label', '')}</TableListCell>

									<TableListCell>
										<Input
											id={`${entry.identifier}-value}`}
											tabIndex={1}
											placeholder={phrases.MODAL_BATCH_STEP_VALUE_PLACEHOLDER}
											required
											disabled={rowDisabled}
											value={entry.value || ''}
											onChange={(event) => this._editListEntry(event, entry, 'value')}
										/>
									</TableListCell>

									<TableListCell>
										<Tooltip
											text={phrases.MODAL_BATCH_STEP_LIST_DELETE_ROW_BUTTON_LABEL}
											zIndex={600}
											renderData={(ref, onMouseEnter, onMouseLeave) => (
												<Button
													id={`${entry.identifier}-delete`}
													type="negative"
													size="tiny"
													disabled={rowDisabled}
													onClick={() => removeBatchListItem(entry)}
													refProp={ref}
													onMouseEnter={onMouseEnter}
													onMouseLeave={onMouseLeave}
												>
													<Icon name="delete" />
												</Button>
											)}
										/>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
			</>
		);
	}
}

NutritionValuesBatchStepList.propTypes = {
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	useGlobalResource: PropTypes.bool,
};

export default NutritionValuesBatchStepList;
