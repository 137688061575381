'use strict';
import { setCookie } from 'cookies';

/**
 * @function toggleHeader
 * @memberOf SERVICES.__models
 * @description
 * Hides / shows local header via $cookies
 * @param  {object} header  header to toggle localHidden for
 */

module.exports = function ( header) {
	return new Promise((resolve, reject) => {
		const settings = this.getSettings();
		if (header) {
			header.localHidden =
				header.localHidden === undefined ? !header.hidden : !header.localHidden;
			setCookie(settings.name + '-header-' + header.hash, header.localHidden);
		}
		resolve(header);
	});
};
