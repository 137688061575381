export const RESET_STATE = 'PRODUCT_BUNDLE_SET_TRANSLATIONS/RESET_STATE';
export const SET_PRODUCT_BUNDLE_SET_TRANSLATION = 'PRODUCT_BUNDLE_SET_TRANSLATIONS/SET_PRODUCT_BUNDLE_SET_TRANSLATION';
export const UPDATE_PRODUCT_BUNDLE_SET_TRANSLATION =
	'PRODUCT_BUNDLE_SET_TRANSLATIONS/UPDATE_PRODUCT_BUNDLE_SET_TRANSLATION';

export const EDIT_BATCH_LIST_ITEM = 'PRODUCT_BUNDLE_SET_TRANSLATIONS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM = 'PRODUCT_BUNDLE_SET_TRANSLATIONS/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW = 'PRODUCT_BUNDLE_SET_TRANSLATIONS/ADD_BATCH_LIST_ITEM_ROW';
export const RERENDER_TABLE = 'PRODUCT_BUNDLE_SET_TRANSLATIONS/RERENDER_TABLE';

export function setProductBundleSetTranslation(payload) {
	return {
		type: SET_PRODUCT_BUNDLE_SET_TRANSLATION,
		payload,
	};
}

export function updateProductBundleSetTranslation(payload) {
	return {
		type: UPDATE_PRODUCT_BUNDLE_SET_TRANSLATION,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}

export function rerenderTable() {
	return {
		type: RERENDER_TABLE,
	};
}
