export default {
	TITLE: 'Filters',
	FILTER_ADDED: 'Filter added!',
	FILTER_EDITED: 'Filter edited!',
	FILTERS_EDITED: 'Filters edited!',
	FILTER_DELETED: 'Filter deleted!',
	FILTER_PRODUCT_VARIANTS: 'Product variants',
	ASSETS: 'Assets',

	// batch form
	MODAL_BATCH_STEP_LIST_TITLE: 'Create Filters',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_ASSET_COLLECTION: 'Asset collection',
	MODAL_BATCH_STEP_ASSET_COLLECTION_TABLE_TITLE: 'Asset collections',
	MODAL_BATCH_STEP_ASSET_COLLECTION_PLACEHOLDER: 'Select asset collection...',
	MODAL_BATCH_STEP_SORT_ORDER: 'Sort order',
	MODAL_BATCH_STEP_SORT_ORDER_PLACEHOLDER: 'Enter sort order...',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add Filters',
};
