/**
 * @function mergeIngredientDiscounts
 * @description
 * @param {Object} discounts
 * @param {Object} productVariantDiscounts
 */
export function mergeIngredientDiscounts(discounts, productVariantDiscounts) {
	const discountedList = discounts.data.map((x) => {
		const dicountObject = productVariantDiscounts.data.find(
			(a) => a.pos_configuration_discount.id === x.id
		);

		if (dicountObject) {
			return { ...x, discounts: dicountObject };
		} else {
			return x;
		}
	});

	const productVariantList = {
		data: discountedList,
		paging: discounts.paging,
	};

	return productVariantList;
}
