import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './icon.css';

class Icon extends Component {
	render() {
		const {
			className,
			name,
			refProp,
			onMouseEnter,
			onMouseLeave,
			onClick,
		} = this.props;
		return (
			<svg
				className={`svg-icon ${className}`}
				onClick={onClick}
				ref={refProp}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}>
				<use xlinkHref={`spritemap.svg#sprite-ic_${name}_24px`} />
			</svg>
		);
	}
}

Icon.defaultProps = {
	onClick: () => {},
};

Icon.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	refProp: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	onClick: PropTypes.func,
};

export default Icon;
