'use strict';

import {
	SET_EOD_CONTROL,
	SET_TABLE_EXPANDED,
	SET_TABLE_CONTENT,
	SET_TABLE_ROW,
	CLEAN_EOD_CONTROL
} from './eodControl.actions';

const defaultState = {
	eodControl: {},
	expandedTables: {},
	posSessionTableContent: {}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {

	case SET_EOD_CONTROL:
		return {
			...state,
			eodControl: payload.eodControl
		};

	case SET_TABLE_EXPANDED:
		return {
			...state,
			expandedTables: {...state.expandedTables, ...{[payload.tableName]: payload.expanded}}
		};

	case SET_TABLE_CONTENT:
		return {
			...state,
			posSessionTableContent: {
				...state.posSessionTableContent,
				[payload.rowId]: payload.content
			}
		};

	case SET_TABLE_ROW:
		return {
			...state,
			eodControl: {...state.eodControl, ...{[payload.row.row.id]: payload.row}}
		};

	case CLEAN_EOD_CONTROL:
		return defaultState;

	default:
		return state;
	}
}

export default reducer;