'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { store } from 'appState';
// import cx from 'classnames';

import { ModalStepper, Overlay, FileUpload } from 'dumb';
import Checkbox from 'inputs/checkbox';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import { ReactDataWrapper } from 'reactDataWrapper';
import BatchStepForm from './components/batchStepForm';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';

// import _uniqueId from 'lodash/uniqueId';
import { isMobile } from 'detectMobile.vanilla';
import _get from 'lodash/get';

import phrases from './../../../productSets.phrases';

class ModalBatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showOverlay: false,
			modalLoading: false,
		};

		this.modalRef = React.createRef();

		// Binds
		this._goToStep = this._goToStep.bind(this);
		// this._batchSubmit = this._batchSubmit.bind(this);
		this.toggleOverlay = this.toggleOverlay.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.onOverlayOpen = this.onOverlayOpen.bind(this);

		this.columns = [
			{
				Header: 'Product variant',
				id: 'productVariant',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Product name',
				id: 'productName',
				accessor: (d) => _get(d, 'product.name', ''),
				filterPath: ':product.name',
			},
			{
				Header: 'Product category name',
				id: 'productCategoryName',
				accessor: (d) => _get(d, 'product.product_category.name', ''),
				filterPath: ':product.product_category.name',
			},
			{
				Header: 'Size',
				id: 'size',
				accessor: (d) => _get(d, 'size.name', ''),
				filterPath: ':size.name',
			},
			{
				Header: 'Fuel',
				id: 'fuel',
				accessor: (d) => _get(d, 'fuel', ''),
				filterPath: ':fuel',
			},
			{
				Header: 'Points',
				id: 'points',
				accessor: (d) => _get(d, 'points.points', ''),
				filterPath: ':points.points',
				filterable: false,
				sortable: false,
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: (d) => _get(d, 'legacy', ''),
				filterPath: ':legacy',
				filterable: false,
				Cell: (d) => {
					return (
						<Checkbox
							checked={_get(d, 'original.legacy', false)}
							disabled
							noBorder
						/>
					);
				},
			},
			{
				Header: 'Available for delivery',
				id: 'availableForDelivery',
				accessor: 'available_for_delivery',
				filterPath: ':available_for_delivery',
				filterable: false,
				Cell: (d) => {
					return (
						<Checkbox
							checked={_get(d, 'original.available_for_delivery', false)}
							disabled
							noBorder
						/>
					);
				},
			},
			{
				Header: 'Image',
				id: 'image',
				accessor: (d) => _get(d, 'image.filename', ''),
				filterPath: ':image.filename',
				filterable: false,
				sortable: false,
				Cell: (d) => {
					const url = _get(d, 'original.image.url', false);
					let file = null;
					if (url) {
						file = {
							url,
							filename: _get(d, 'original.image.filename', ''),
						};
					}
					return <FileUpload file={file} thumbnail disabled />;
				},
			},
			{
				Header: 'Asset collection',
				id: 'asset_collection',
				accessor: (d) => _get(d, 'asset_collection.name', ''),
				filterPath: ':asset_collection.name',
				filterable: false,
				sortable: false,
			},
		];
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	toggleOverlay() {
		const { productVariants } = this.props;

		let selectedRowsInList = _get(productVariants, 'ui.selectedRows', []);
		selectedRowsInList = selectedRowsInList.map((x) => {
			return {
				label: x.name,
				value: x,
			};
		});
		this.editStoreEntry(selectedRowsInList, 'product_variant');

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
		}));
	}

	editStoreEntry(e, type) {
		const { batchOptions } = this.props;

		const value = e && e.target ? e.target.value : e;

		this.props.updateBatchForm({
			...batchOptions,
			...{ [type]: value },
		});
	}

	fetchData(state) {
		return this.props.fetchProductVariants(state);
	}

	onOverlayOpen() {
		const { batchOptions } = this.props;

		batchOptions.product_variant.map((x) => {
			store.dispatch(
				actions.toggleRow({
					reduxKey: 'product/product_variants/batch',
					data: x.value,
				})
			);
		});
	}

	_batchSubmit() {
		const {
			batchOptions,
			handleClose,
			productSet,
			addProductSetOption,
		} = this.props;

		this.setState(() => ({
			modalLoading: true,
		}));

		if (!batchOptions.product_variant) return;

		Promise.all(
			batchOptions.product_variant.map((x) => {
				const payload = {
					discount_product_set: productSet.id,
					product_variant: _get(x, 'value.id', null),
				};

				return addProductSetOption(payload);
			})
		)
			.then(() => {
				setFeedback(phrases.MODAL_BATCH_VALUES_SUBMITTED, 1);

				handleClose();
				this.setState(() => ({
					modalLoading: false,
				}));
			})
			.catch(() => {
				handleClose();
				this.setState(() => ({
					modalLoading: false,
				}));
			});
	}

	render() {
		const {
			modalVisible,
			handleClose,
			batchOptions,
			updateBatchForm,
			excludedIds,
		} = this.props;
		const { showOverlay, modalLoading } = this.state;

		const isValidStep = !!batchOptions.product_variant.length;

		const steps = [
			{
				component: (
					<BatchStepForm
						batchOptions={batchOptions}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.toggleOverlay}
						excludedIds={excludedIds}
					/>
				),
				title: phrases.MODAL_BATCH_TITLE,
				isValid: isValidStep,
				onNext: () => this._batchSubmit(),
				loading: modalLoading,
				confirmButtonLabel: phrases.MODAL_BATCH_CONFIRM_BUTTON_TEXT,
				defaultStyles: true,
				noScroll: true,
				allowOverflow: true,
			},
		];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						className="modal-batch"
						ref={this.modalRef}
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						loading={this.state.modalLoading}
						onClose={showOverlay ? () => {} : handleClose}
					/>
				)}

				<Overlay
					zIndex={551}
					height={isMobile() ? 480 : 680}
					list
					visible={showOverlay}
					onClose={this.toggleOverlay}
					render={(height) => {
						return (
							<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
								title={phrases.MODAL_BATCH_OVERLAY_TABLE_TITLE}
								columns={this.columns}
								fetchData={this.fetchData}
								filterable
								disableFetchCsvButton
								enableMultiSelection
								batchSelection
								defaultPageSize={20}
								onInitialization={() => this.onOverlayOpen()}
								reduxKey="product/product_variants/batch"
								manual
								style={{
									maxHeight: `${height - 130}px`,
								}}
							/>
						);
					}}
				/>
			</>
		);
	}
}

ModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	batchOptions: PropTypes.object,

	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	fetchProductVariants: PropTypes.func,
	productVariants: PropTypes.object,
	addProductSetOption: PropTypes.func,
	productSet: PropTypes.object,
	excludedIds: PropTypes.array,
};

ModalBatch.defaultProps = {
	modalVisible: false,
	headers: [],
	editableCells: [],
	loading: false,
};

export default ModalBatch;
