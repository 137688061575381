'use strict';

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

// utils
import { saveAs } from 'file-saver';
import moment from 'moment';
import { confirmModal } from 'utils';

// redux
import { store, connectWithStore } from 'appState';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';
import RowStyles from 'reactDataWrapper/utilities/rowStyles';

// service
import {
	fetchOnboardings,
	deleteOnboarding,
	signOnboarding,
	exportOnboardings,
} from './onboardings.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import CsvDateRange from './components/csvDateRange/csvDateRange.component';
import { CreateOnboarding } from './components/createOnboarding/createOnboarding.component';
import { createOnboarding } from './components/createOnboarding/createOnboarding.service';
import { resetOnboardingData } from './components/createOnboarding/createOnboardings.actions.js';

import { ButtonLoader, Button, Icon, Tooltip, Modal } from 'dumb';
import { PageTips } from 'dumb/tips';

// tips to show
import tips from './onboardings.tips';

// lodash
import _get from 'lodash/get';
import { push } from 'redux-first-history';

// onboardingConstants
import constants from 'services/constants';
import phrases from './onboardings.phrases';

// styles
import './onboardings.css';

class Onboardings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			fetchingCsv: false,
			exportingOnboardings: false,
			filters: [
				{
					name: 'signed',
					enabled: true,
					filter: `:signed==null`,
				},
				{
					name: 'received',
					enabled: true,
					filter: `:received!=null`,
				},
				{
					name: 'expiredAt',
					enabled: false,
					filter: `:expired_at=lt='${moment
						.utc()
						.format('YYYY-MM-DD HH:mm:ss')}'`,
				},
			],
		};

		this.fetchData = this.fetchData.bind(this);
		this.getCsvModal = this.getCsvModal.bind(this);
		this.createNewOnboarding = this.createNewOnboarding.bind(this);
		this.onFetchCsv = this.onFetchCsv.bind(this);
		this.handleCSVExport = this.handleCSVExport.bind(this);
		this.getActionButton = this.getActionButton.bind(this);
		this.signOnboarding = this.signOnboarding.bind(this);
		this.getRowColor = this.getRowColor.bind(this);
		this._toggleCreateOnboarding = this._toggleCreateOnboarding.bind(this);
		this._handleCloseOnboarding = this._handleCloseOnboarding.bind(this);
		this._setFilter = this._setFilter.bind(this);
		this._exportOnboarding = this._exportOnboarding.bind(this);

		this.reduxKey = 'hr/onboardings';

		this.columns = [
			{
				Header: 'Id',
				id: 'id',
				accessor: 'id',
				width: 50,
				filterPath: ':id',
			},
			{
				Header: 'Market',
				id: 'market',
				accessor: (d) => _get(d, 'market.name', null),
				width: 200,
				filterPath: ':market.name',
			},
			{
				Header: 'Person',
				id: 'full_name',
				accessor: (d) => _get(d, 'person.identity.full_name', ''),
				width: 250,
				filterPath: ':person.identity.full_name',
			},
			{
				Header: 'Status',
				id: 'status',
				accessor: 'status',
				filterPath: ':status',
			},
			{
				Header: 'Received',
				id: 'received',
				accessor: 'received',
				filterPath: ':received',
			},
			{
				Header: 'Signed',
				id: 'signed',
				accessor: 'signed',
				filterPath: ':signed',
			},
			{
				Header: 'Signed By',
				id: 'signed_by',
				accessor: (d) => _get(d, 'signed_by.full_name', ''),
				filterPath: ':signed_by.full_name',
			},
			{
				Header: 'Expired at',
				id: 'expired_at',
				accessor: (d) => _get(d, 'expired_at', ''),
				filterPath: ':expired_at',
				Cell: (d) => {
					return (
						<span>
							{moment
								.utc(d.original.expired_at, 'YYYY-MM-DD HH:mm:ss')
								.format(constants.shortDate)}
						</span>
					);
				},
			},
		];
	}

	// deletes an onboarding
	deleteEntry(id) {
		return deleteOnboarding({ id });
	}

	// fetches onboardings
	fetchData(options) {
		this.setState(() => ({ loading: true }));

		return fetchOnboardings(options)
			.then((response) => {
				this.setState(() => ({
					loading: false,
				}));
				return response;
			})
			.catch(() => {
				this.setState(() => ({
					loading: false,
				}));
			});
	}

	_exportOnboarding() {
		this.setState(() => ({ exportingOnboardings: true }));

		const filter = this._createFilterString(this.state.filters);

		exportOnboardings({ filter }, this.reduxKey).finally(() => {
			this.setState(() => ({ exportingOnboardings: false }));
		});
	}

	_handleCloseOnboarding() {
		const onConfirm = () => {
			this.setState(() => ({ toggleCreateOnboardingsModal: false }));
			store.dispatch(resetOnboardingData());
		};

		// Show confirmation modal if user has filled in onboarding data
		if (
			Object.values(this.props.onboardingData).filter((value) => !!value).length
		) {
			confirmModal.show({
				data: {
					header: phrases.ABORT_CONFIRMATION_HEADER,
					subheader: phrases.ABORT_CONFIRMATION_SUBHEADER,
					confirmLabel: phrases.ABORT_CONFIRMATION_CONFIRM,
					cancelLabel: phrases.ABORT_CONFIRMATION_CANCEL,
				},
				onConfirm,
			});
		} else {
			onConfirm();
		}
	}

	_toggleCreateOnboarding(toggleCreateOnboardingsModal) {
		this.setState(() => ({ toggleCreateOnboardingsModal }));
	}

	isCreateNewOnboardingFromValid = ({
		market,
		firstName,
		lastName,
		emailAddress,
		phoneNumber,
		startDate,
		company,
		employmentType,
		moneyballSubPosition,
		salaryGroup,
	}) => {
		return (
			market?.value &&
			firstName &&
			lastName &&
			emailAddress &&
			phoneNumber &&
			startDate &&
			company?.value &&
			employmentType?.value &&
			moneyballSubPosition?.value &&
			salaryGroup?.value
		);
	};

	/**
	 * @function getCsvModal
	 * @description pops a custom csv modal with date range
	 * overwrites default csv from reactDataWrapper
	 */
	getCsvModal() {
		const { exportingOnboardings } = this.state;

		return (
			<>
				<Tooltip
					text={phrases.CSV_TOOLTIP}
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							type="inverted"
							label="CSV"
							shadow
							onClick={() => this.onFetchCsv()}
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}>
							<Icon name="file_download" />
						</Button>
					)}
				/>
				<Button
					type="inverted"
					label="Add"
					shadow
					onClick={() => {
						this._toggleCreateOnboarding(true);
					}}>
					<Icon name="add" />
				</Button>
				<Tooltip
					placement="left"
					text={phrases.EXPORT_ONBOARDING_TOOLTIP}
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							type="inverted"
							label="Export onboarding"
							shadow
							onClick={this._exportOnboarding}
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}>
							{exportingOnboardings ? (
								<ButtonLoader loading={exportingOnboardings} theme="dark" />
							) : (
								<Icon name="file_download" />
							)}
						</Button>
					)}
				/>
			</>
		);
	}

	/**
	 * @function _setFilter
	 * @param {String} type  - which filter to toggle
	 * @param {String} filter - filter to be applied when re-fetching new data from reactDataWrapper
	 */
	_setFilter(filterName) {
		const { filters } = this.state;

		// toggle the pressed filter's state
		const newFilters = filters.map((entry) => {
			if (entry.name === filterName)
				return {
					...entry,
					enabled: !entry.enabled,
				};
			else return entry;
		});

		// update filters
		this.setState(() => ({
			filters: newFilters,
		}));
	}

	/**
	 * @function _createFilterString
	 * @param {Array} arrayToReduce
	 * @description takes an array of filters and returns a filter string
	 */
	_createFilterString(arrayToReduce) {
		return arrayToReduce.reduce((acc, value) => {
			if (value.enabled)
				return acc === '' ? value.filter : `${acc};${value.filter}`;
			return acc;
		}, '');
	}

	/**
	 * @function getAdditionalFilters
	 * @description returns buttons for custom area components
	 */
	getAdditionalFilters() {
		const {
			filters: [signedFilter, receivedFilter, expiredAtFilter],
		} = this.state;

		return (
			<Fragment>
				<div className="onboardings__filter-section">
					<Button
						size="tiny"
						type={signedFilter.enabled ? '' : 'inverted'}
						shadow
						onClick={() => this._setFilter('signed')}>
						{phrases.ONBOARDING_FILTER_UNSIGNED}
					</Button>
					<Button
						size="tiny"
						type={receivedFilter.enabled ? '' : 'inverted'}
						shadow
						onClick={() => this._setFilter('received')}>
						{phrases.ONBOARDING_FILTER_RECEIVED}
					</Button>
					<Button
						size="tiny"
						type={expiredAtFilter.enabled ? '' : 'inverted'}
						shadow
						onClick={() => this._setFilter('expiredAt')}>
						{phrases.ONBOARDING_FILTER_EXPIRED}
					</Button>
				</div>
			</Fragment>
		);
	}

	/**
	 * @function onFetchCsv
	 * @description sets the state of fetching csv to true/false to pop open/close the modal
	 */
	onFetchCsv() {
		this.setState((prevState) => ({ fetchingCsv: !prevState.fetchingCsv }));
	}

	/**
	 * @function handleCSVExport
	 * @description Handler for fetching data as CSV. Fetches from the fetchData method, unless a override fetchCsv method is passed
	 */
	handleCSVExport(dates) {
		const filter = `:created=ge='${dates.from}';:created=le='${dates.to}'`;
		const limit = 300;

		const headers = {
			Accept: 'application/csv',
		};

		return this.fetchData({ filter, limit, headers }).then((csv) => {
			if (!csv)
				console.info('No csv data. Did you forget to return the promise?');

			const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });

			// outputs filename in format of "redux_key__2019_04_24"
			const csvFileName = `${this.reduxKey.replace(
				'/',
				'_'
			)}__${moment().format(constants.shortDate)}.csv`;
			const fileName = csvFileName;
			saveAs(blob, fileName);
		});
	}

	/**
	 *
	 * @param {object}
	 * 	@param {id} - id of the onboarding to sign
	 * @description signs the onboarding
	 */
	signOnboarding({ id }) {
		const {
			user: { user },
		} = this.props;

		const time = moment.utc().format(constants.dateFormat);

		const payload = {
			signed: time,
			signed_by: user.person.id,
			status: 'Signed',
		};

		signOnboarding({ id, body: payload }).then((res) => {
			// if res.code's first digit is 2, i.e. success
			if (('' + res.code)[0] === '2') {
				store.dispatch(
					actions.editEntry({ reduxKey: this.reduxKey, entry: res.data[0] })
				);
			}
		});
	}

	createNewOnboarding() {
		this.setState(() => ({
			creatingNewOnboarding: true,
		}));

		createOnboarding(this.props.onboardingData)
			.then(() => {
				store.dispatch(resetOnboardingData());
				this.setState(() => ({
					creatingNewOnboarding: false,
				}));
				this._toggleCreateOnboarding(false);
			})
			.catch(() => {
				this.setState(() => ({
					creatingNewOnboarding: false,
				}));
			});
	}

	// returns in-row action buttons for each row
	getActionButton(d) {
		return (
			<>
				{d.original.received && d.original.status === 'Unsigned' && (
					<Tooltip
						text={phrases.TOOLTIP}
						renderData={(ref, onMouseEnter, onMouseLeave) => (
							<Button
								type="inverted"
								shadow
								onClick={() => this.signOnboarding({ id: d.value })}
								size="micro"
								refProp={ref}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}>
								<Icon name="check" />
							</Button>
						)}
					/>
				)}
				<Button
					type="inverted"
					shadow
					onClick={() =>
						store.dispatch(push(`${window.location.pathname}/${d.value}`))
					}
					size="micro">
					<Icon name="tune" />
				</Button>
			</>
		);
	}

	/**
	 * @function getRowColor
	 * @param {Object} rowInfo - object from getTrProps from RDW
	 * @returns true or false based on the onboarding signing
	 */
	getRowColor(rowInfo) {
		const rowStyle = `rgba(${window
			.getComputedStyle(document.body)
			.getPropertyValue(RowStyles.HIGHLIGHTED)}, 1)`;

		if (rowInfo.original.signed) {
			return {
				background: rowStyle,
			};
		} else return {};
	}

	render() {
		const { fetchingCsv, filters, initialSort } = this.state;

		const filterString = this._createFilterString(filters);

		return (
			<>
				<PageTips tips={tips} id={this.reduxKey} />
				{fetchingCsv ? (
					<CsvDateRange
						visible={fetchingCsv}
						handleClose={this.onFetchCsv}
						handleCSVExport={this.handleCSVExport}
					/>
				) : null}
				<ReactDataWrapper
					title={phrases.TITLE}
					columns={this.columns}
					listSorting={initialSort}
					fetchData={this.fetchData}
					filterable
					disableFetchCsvButton
					defaultPageSize={30}
					reduxKey={this.reduxKey}
					deleteEntry={this.deleteEntry}
					inRowButtons
					manual
					actionRender={this.getCsvModal()}
					extraFilters={filterString}
					actions={this.getActionButton}
					customAreaComponents={this.getAdditionalFilters()}
					customRowStyling={this.getRowColor}
					actionsWidth={90}
					defaultSorted={[
						{
							id: 'received',
							desc: false,
						},
					]}
				/>

				{this.state.toggleCreateOnboardingsModal && (
					<Modal
						onClose={this._handleCloseOnboarding}
						confirmButtonLabel="Create Onboarding"
						type="confirmation"
						loading={this.state.creatingNewOnboarding}
						disabled={this.isCreateNewOnboardingFromValid(
							this.props.onboardingData
						)}
						onConfirmClick={(e) => this.createNewOnboarding()}
						onCancelClick={this._handleCloseOnboarding}>
						<CreateOnboarding />
					</Modal>
				)}
			</>
		);
	}
}

Onboardings.propTypes = {
	user: PropTypes.object,
	onboardingData: PropTypes.object,
};

const mapStateToPropsFactory = () => (store) => {
	return {
		user: _get(store, 'userData.user', {}),
		onboardingData: _get(store, 'createOnboarding.onboardingData', {}),
	};
};

export default connectWithStore(Onboardings, mapStateToPropsFactory);
