'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,
	setGlobalBundleRules,

	// regular stuff
	resetState,
} from './store/productBundleRules.actions';

// services
import {
	fetchProductBundleRules,
	fetchGlobalBundleRules,
	addProductBundleRules,
	deleteProductBundleRule,
} from './productBundleRules.service';

// utils
import { getGlobalBundleRulesFilter } from './utils';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Button, Icon } from 'dumb';
import ProductBundleRulesModalBatch from './components/batch/productBundleRulesModalBatch';
import GlobalBundleRulesWeekView from './../../../globalBundleRulesWeekView/globalBundleRulesWeekView';

// lodash
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

// phrases/constants
import phrases from './productBundleRules.phrases';
import constants from 'services/constants';

const reduxKey = '/product/bundle_rules';

class ProductBundleRules extends Component {
	constructor(props) {
		super(props);

		this.today = moment.utc().format(constants.shortDate);

		this.state = {
			key: moment(),
			showAddModal: false,
			showEditModal: false,
		};

		this.deleteEntry = this.deleteEntry.bind(this);
		this.toggleAddModal = this.toggleAddModal.bind(this);
		this.fetchDataWrapper = this.fetchDataWrapper.bind(this);
		this.addProductBundleRulesWrapper = this.addProductBundleRulesWrapper.bind(
			this
		);

		this.columns = [
			{
				Header: 'Global bundle rule',
				id: 'globalBundleRule',
				accessor: (d) => _get(d, 'global_bundle_rule.name', ''),
				filterPath: ':global_bundle_rule.name',
			},
		];
	}

	deleteEntry(id) {
		return deleteProductBundleRule(id);
	}

	toggleAddModal() {
		this.setState((prevState) => ({ showAddModal: !prevState.showAddModal }));
		this.props.resetState();
	}

	getActionButtons() {
		return (
			<Button type="inverted" label="Add" shadow onClick={this.toggleAddModal}>
				<Icon name="add" />
			</Button>
		);
	}

	addProductBundleRulesWrapper(data) {
		return addProductBundleRules(data)
			.then(() => {
				this.setState(() => ({ key: moment() }));
			})
			.catch((err) => {
				throw err;
			});
	}

	fetchDataWrapper(state) {
		const { productBundle, setGlobalBundleRules } = this.props;

		const filter = `:bundle.id=='${productBundle.id}'`;

		return fetchProductBundleRules(state, filter).then((res) => {
			const emptyResponse = _isEmpty(res.data);

			const filter = getGlobalBundleRulesFilter({
				productBundleRules: res.data,
			});

			const promiseToResolve = emptyResponse
				? Promise.resolve(true)
				: fetchGlobalBundleRules(filter);

			return promiseToResolve.then((resTwo) => {
				if (!emptyResponse) setGlobalBundleRules(resTwo.data);

				return res;
			});
		});
	}

	render() {
		const {
			batchListData,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
			addBatchList,
			productBundle,
			globalBundleRules,
		} = this.props;

		return (
			<>
				<div>
					<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
						key={this.state.key}
						title={phrases.TABLE_TITLE}
						columns={this.columns}
						fetchData={this.fetchDataWrapper}
						filterable
						defaultPageSize={10}
						reduxKey={reduxKey}
						manual
						deleteEntry={this.deleteEntry}
						onModalClose={this.props.resetState}
						actionRender={this.getActionButtons()}
					/>
					<GlobalBundleRulesWeekView data={globalBundleRules} />
				</div>

				<ProductBundleRulesModalBatch
					modalVisible={this.state.showAddModal}
					handleClose={this.toggleAddModal}
					batchListData={batchListData}
					addBatchList={addBatchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					batchAddData={this.addProductBundleRulesWrapper}
					productBundleId={productBundle.id}
				/>
			</>
		);
	}
}

ProductBundleRules.propTypes = {
	resetState: PropTypes.func,
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchList: PropTypes.func,
	productBundle: PropTypes.object,
	globalBundleRules: PropTypes.array,
	setGlobalBundleRules: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			resetState,
			addBatchListItemRow,
			setGlobalBundleRules,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		batchListData:
			store.salesConfigurationProductBundleRules.data.batchListData,
		globalBundleRules:
			store.salesConfigurationProductBundleRules.data.globalBundleRules,
		listProductBundleRules: _get(
			
			`listData[${reduxKey}].data.listData`,
			[]
		),
	};
};

export default connectWithStore(
	ProductBundleRules,
	mapStateToProps,
	mapDispatchToProps
);
