export const SET_DATA = 'SHIFT_DETAILS_MODAL/SET_DATA';
export const RESET_DATA = 'SHIFT_DETAILS_MODAL/RESET_DATA';

export function setData(payload) {
	return {
		type: SET_DATA,
		payload,
	};
}

export function resetData() {
	return {
		type: RESET_DATA,
	};
}
