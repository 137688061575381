import _uniqueId from 'lodash/uniqueId';
import _orderBy from 'lodash/orderBy';
import _compact from 'lodash/compact';

import constants from 'services/constants';
import moment from 'moment';

/**
 *
 * @param {Object} object - contains all bundles and prefered hour to pixel ratio
 * @description returns an object that will be used to style the calendar view
 * and later on when calculating bundle height etc...
 */
export function getBundleMetaData({ bundles, hourToPixelRatio }) {
	// get grid hours
	const gridHours = _getEarliestBundleHour(bundles);

	// get calendar height
	const height =
		// get day scope
		(gridHours.latestClosingHour - gridHours.startTimeOfDay) *
			// multiply by hour to pixel ratio to cover all hours
			hourToPixelRatio +
		// and add 3 more hours of padding (2 for hour indicators and 2 extra at the bottom)
		hourToPixelRatio * 4;

	// create shiftMetadata object
	return {
		height,
		earliestShiftHour: gridHours.earliestOpeningHour,
		latestShiftHour: gridHours.latestClosingHour,
		earliestShiftMinute: gridHours.earliestOpeningMinute,
		latestShiftMinute: gridHours.latestClosingMinute,
		startTimeOfDay: gridHours.startTimeOfDay,
	};
}

/**
 * @function _getEarliestBundleHour
 * @param {Array} bundles
 * @description returns grid hours with values like
 * 	earliestOpeningHour - what is the first bundle start time
		latestClosingHour - latest bundle end
		earliestOpeningMinute - same as first but in mins
		latestClosingMinute - same as second but in mins
		startTimeOfDay - hour of the first bundle
 */
function _getEarliestBundleHour(bundles) {
	let earliestOpeningHour,
		latestClosingHour,
		earliestOpeningMinute,
		latestClosingMinute;

	bundles.map((bundle) => {
		let startingHourOfBundle = moment.utc(
			bundle.time.from,
			constants.dayFormat
		);
		let startingMinuteOfBundle = startingHourOfBundle.clone();

		const mmtMidnight = startingHourOfBundle.clone().startOf('day');

		// get distance in hours
		startingHourOfBundle = distanceFromMidnight(
			mmtMidnight,
			startingHourOfBundle
		);
		// get distance in minutes
		startingMinuteOfBundle = distanceFromMidnight(
			mmtMidnight,
			startingMinuteOfBundle,
			'minutes'
		);

		if (earliestOpeningHour === undefined) {
			earliestOpeningHour = startingHourOfBundle;
			earliestOpeningMinute = startingMinuteOfBundle;
		}

		if (startingMinuteOfBundle < earliestOpeningMinute) {
			earliestOpeningHour = startingHourOfBundle;
			earliestOpeningMinute = startingMinuteOfBundle;
		}

		let endingHourOfShift = moment.utc(bundle.time.to, constants.dayFormat);
		let endingMinuteOfShift = endingHourOfShift.clone();

		endingHourOfShift = distanceFromMidnight(mmtMidnight, endingHourOfShift);
		endingMinuteOfShift = distanceFromMidnight(
			mmtMidnight,
			endingMinuteOfShift,
			'minutes'
		);

		if (latestClosingHour === undefined) {
			latestClosingHour = endingHourOfShift;
			latestClosingMinute = endingMinuteOfShift;
		}
		if (endingMinuteOfShift > latestClosingMinute) {
			latestClosingHour = endingHourOfShift;
			latestClosingMinute = endingMinuteOfShift;
		}
	});

	// get iterator based on starting time of the earliest bundle
	// if bundle starts before 2 then start form 0 (midnight)
	// if it starts after 2 then subtract 2 hours and start from there
	const startTimeOfDay = earliestOpeningHour < 2 ? 0 : earliestOpeningHour - 2;

	return {
		earliestOpeningHour,
		latestClosingHour,
		earliestOpeningMinute,
		latestClosingMinute,
		startTimeOfDay,
	};
}

/**
 * @function distanceFromMidnight
 * @param {Moment object} midnight
 * @param {Moment object} time
 * @description calculates distance from midnight in hours
 */
function distanceFromMidnight(midnight, time, units = 'hours') {
	return time.diff(midnight, units);
}

/**
 * @function getStackedShifts
 * @param {Array} bundles
 * @description returns stacked bundles
 */
export function getStackedBundleCards({ bundles, bundleMetaData }) {
	// get start of day
	const mmtMidnight = moment.utc().startOf('day');

	// get start of day and end of day in minutes
	const startOfDay = bundleMetaData.earliestShiftMinute; // starting minute of midnight
	const endOfDay = bundleMetaData.latestShiftMinute; // after 24hrs - 24 hours * 60 minutes

	const stackedShiftsArray = {
		emptySpacesArray: [],
		bundles: [],
		columnCount: 1,
	};

	// initially generate the first empty space before reducing
	let emptySpacesArray = [
		{
			id: _uniqueId(),
			start: startOfDay,
			end: endOfDay,
			column: 0,
		},
	];

	const stackedBundles = bundles.reduce((acc, currentBundle, i) => {
		// sort the array of spaces by column number - smallest first
		acc.emptySpacesArray = _orderBy(emptySpacesArray, ['column'], ['asc']);

		// shift times
		let bundleStartFormat = moment.utc(
			currentBundle.time.from,
			constants.dayFormat
		);
		let bundleEndFormat = moment.utc(
			currentBundle.time.to,
			constants.dayFormat
		);

		// calculate distances from mmtMidnight
		bundleStartFormat = bundleStartFormat.diff(mmtMidnight, 'minutes');
		bundleEndFormat = bundleEndFormat.diff(mmtMidnight, 'minutes');

		// loop through spaces and stop execution:
		// 1. when we find a space it fits in
		// 2. create a new column with a new space
		acc.emptySpacesArray.some((space, index) => {
			const spaceStart = space.start;
			const spaceEnd = space.end;

			// inclusive at space start and space end
			const isBetweenStart =
				bundleStartFormat >= spaceStart && bundleStartFormat < spaceEnd;
			const isBetweenEnd =
				bundleEndFormat > spaceStart && bundleEndFormat <= spaceEnd;

			if (isBetweenEnd && isBetweenStart) {
				// recalculate the spaces, we will add 2 extra always
				// unless the space start and end are the same, in that case we delete that space
				const res = _calculateColumnSpaces({
					spaceStart,
					spaceEnd,
					bundleStartFormat,
					bundleEndFormat,
					column: space.column,
				});

				// remove old space from empty spaces array (the one we fitted the bundle in, it will be replace with new space/s)
				const filteresSpacesArray = emptySpacesArray.filter(
					(spaceToFilter) => spaceToFilter.id !== space.id
				);

				// add new spaces into it
				emptySpacesArray = [...filteresSpacesArray, ...res];

				// update the accumulator and stop execution
				acc = {
					...acc,
					emptySpacesArray,
					bundles: [
						...acc.bundles,
						{
							...currentBundle,
							column: space.column,
						},
					],
				};
				return true;
			}
			// if we looped through all spaces, create a new column with new space
			else if (index === acc.emptySpacesArray.length - 1) {
				// get latest column - last equals biggest due to sorting - and increment by 1
				const column = space.column + 1;

				// get spaces
				const res = _calculateColumnSpaces({
					spaceStart: startOfDay,
					spaceEnd: endOfDay,
					bundleStartFormat,
					bundleEndFormat,
					column,
				});

				// add new spaces into it
				emptySpacesArray = [...emptySpacesArray, ...res];

				// update the accumulator and stop execution
				acc = {
					...acc,
					emptySpacesArray,
					bundles: [
						...acc.bundles,
						{
							...currentBundle,
							column,
						},
					],
					columnCount: acc.columnCount + 1,
				};
				return true;
			} else {
				return false;
			}
		});
		return acc;
	}, stackedShiftsArray);

	// calculate shift width and left prop
	return _stackBundles({
		bundles: stackedBundles.bundles,
		columns: stackedBundles.columnCount,
	});
}

/**
 * @function _calculateColumnSpaces
 * @param {Array} emptySpacesArray
 * @description takes a space the bundle fits in and creates two spaces out of one
 */
function _calculateColumnSpaces({
	spaceStart,
	spaceEnd,
	bundleStartFormat,
	bundleEndFormat,
	column,
}) {
	let spaceOne = {
		id: _uniqueId(),
		start: spaceStart,
		end: bundleStartFormat,
		column,
	};

	let spaceTwo = {
		id: _uniqueId(),
		start: bundleEndFormat,
		end: spaceEnd,
		column,
	};

	// check if they have the same start/end time
	if (spaceOne.start === spaceOne.end) spaceOne = null;
	if (spaceTwo.start === spaceTwo.end) spaceTwo = null;

	return _compact([spaceOne, spaceTwo]);
}

/**
 * @function _stackBundles
 * @param {Array} bundles
 * @param {Number} columns - number of columns
 * @description receives bundle array and number of columns and based on that generates
 * styles - width and left prop
 */
function _stackBundles({ bundles, columns }) {
	// width is always 100% divided by number of columns
	const width = 100 / columns;

	const mappedShifts = bundles.map((shift, index) => {
		return {
			...shift,
			style: {
				...shift.style,
				width: `${Math.round(width - 1)}%`,
				left: `${Math.round(shift.column * width)}%`,
			},
		};
	});

	return {
		bundles: mappedShifts,
		columns,
	};
}

/**
 * @function getBundlePlacing
 * @param {Object} object
 * @param {Object} bundle
 * @param {Array} tableHours
 * @param {Number} startTimeOfDay
 * @param {Number} hourToPixelRatio
 * @description returns coordinates for the shift card item
 * basically just returns distance from top and card height prop
 */
export function getBundlePlacing({
	bundle,
	tableHours,
	startTimeOfDay,
	hourToPixelRatio,
}) {
	// format bundle start date
	const bundleStart = bundle.time.from;
	const bundleEnd = bundle.time.to;

	let formattedStartTime = moment
		.utc(bundleStart, constants.dayFormat)
		.clone()
		.startOf('minute')
		.format(constants.dayFormat);

	// get from minute
	const fromMinute = moment
		.utc(bundleStart, constants.dayFormat)
		.clone()
		.format(constants.minute);

	// round down to hour
	if (fromMinute > 0 && fromMinute <= 15)
		formattedStartTime = moment
			.utc(bundleStart, constants.dayFormat)
			.startOf('hour')
			.format(constants.dayFormat);

	// round to half hour
	if (fromMinute > 15 && fromMinute < 46)
		formattedStartTime = moment
			.utc(bundleStart, constants.dayFormat)
			.clone()
			.startOf('hour')
			.add(30, 'minutes')
			.format(constants.dayFormat);

	// round up to nearest hour
	if (fromMinute > 45)
		formattedStartTime = moment
			.utc(bundleStart, constants.dayFormat)
			.add(1, 'hour')
			.startOf('hour')
			.format(constants.dayFormat);

	// get difference between bundles in hours
	const duration = moment
		.duration(
			moment
				.utc(bundleEnd, constants.dayFormat)
				.diff(moment.utc(bundleStart, constants.dayFormat))
		)
		.asHours();

	// find time from table hours to get distance from top
	const time = tableHours.find((time) => {
		return time.time === formattedStartTime;
	});

	// time.top + 40 is so that we have 2 hours of padding in front of the first bundle
	// we can add more if neccessary
	return {
		// we multiply the startTimeOfDay by 40 because startTimeOfDay is an idication of
		// an hour and every hour is presented by 40 px
		top: _getTopPlacing({
			hourToPixelRatio,
			top: time.top,
			startTimeOfDay,
		}),
		height: `${Math.floor(hourToPixelRatio * duration)}px`,
	};
}

/**
 * @function createTimeHourTable
 * @param {Number} hourDivide - if 1 passed it will return 24 half hour entries adding up to 12:00 noon, if 2 passed it will return entries adding up to midnight
 * @param {Number} hourToPixelRatio - hour to pixel ratio
 * @description returns 24/48 entries representing distance of each hour from the top of its parent container
 */
export function createTimeHourTable(hourDivide = 1, hourToPixelRatio = 10) {
	const hours = 24;
	const setHeigt = hourToPixelRatio / hourDivide;

	const dayHours = [];
	for (let i = 0; i < hours * hourDivide; i++) {
		dayHours[i] = i;
	}

	// Reduce to { time: 00:00, top: 0 };
	return dayHours.reduce((tally, current) => {
		tally.push({
			time: tally[current - 1]
				? moment(tally[current - 1].time, constants.dayFormat)
						.add(30, 'minutes')
						.format(constants.dayFormat)
				: moment().startOf('day').format(constants.dayFormat),
			top: tally[current - 1] ? tally[current - 1].top + setHeigt : 0,
		});
		return tally;
	}, []);
}

function _getTopPlacing({ hourToPixelRatio, top, startTimeOfDay }) {
	// bundle distance from top is {top}
	// one hour of bundle is 10px {hourToPixelRatio}
	// first get actual distance from top based on start time of day
	// example: bundle starts at 03:00 (top is then 3 x 10 = 30px) but day starts at 01:00
	// that logic would place the shift at 04:00 instead of 03:00 - that's why we multiply startTimeOfDay with hourToPixelRatio
	return `${top - startTimeOfDay * hourToPixelRatio}px`;
}
