export const SET_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT =
	'POS_PRODUCT_DISCOUNT/SET_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT';
export const UPDATE_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT =
	'POS_PRODUCT_DISCOUNT/UPDATE_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT';
export const RESET_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT =
	'POS_PRODUCT_DISCOUNT/RESET_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT';

export function setPosConfigurationDiscountProductVariant(payload) {
	return {
		type: SET_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT,
		payload,
	};
}

export function updatePosConfigurationDiscountProductVariant(payload) {
	return {
		type: UPDATE_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT,
		payload,
	};
}

export function resetPosConfigurationDiscountProductVariant() {
	return {
		type: RESET_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT,
	};
}
