export default {
	TABLE_TITLE: 'Workplace /POS Configuration relations',
	WORKPLACE_TO_POS_CONFIGURATION_EDITED:
		'Workplace to POS configuration relation edited.',
	MULTI_WORKPLACE_TO_POS_CONFIGURATION_EDITED:
		'Multiple workplace to POS configuration relation edited.',
	WORKPLACE_TO_POS_CONFIGURATION_DELETED:
		'Workplace to POS configuration relation deleted.',
	WORKPLACE_TO_POS_CONFIGURATION_ADDED:
		'Workplace to POS configuration relation added',
	WORKPLACE_TO_POS_CONFIGURATION_DUPLICATED:
		'Workplace to POS configuration relation duplicated',

	ACTIVE_FILTER_BUTTON: 'Active',
	MODAL_BATCH_STEP_FORM_TITLE_TIME: 'Select new dates',
	MODAL_BATCH_STEP_FORM_TITLE: 'Select other entries',
	MODAL_BATCH_STEP_POS_CONFIGURATION: 'POS configuration',
	MODAL_BATCH_STEP_POS_CONFIGURATION_PLACEHOLDER: 'Select POS configuration',
	MODAL_BATCH_STEP_WORKPLACE: 'Workplace',
	MODAL_BATCH_STEP_WORKPLACE_PLACEHOLDER: 'Workplace',
	MODAL_BATCH_STEP_ACTIVE_FROM: 'Active from',
	MODAL_BATCH_STEP_ACTIVE_FROM_NEW: 'New Active from',
	MODAL_BATCH_STEP_ACTIVE_TO: 'Active to',
	MODAL_BATCH_STEP_ACTIVE_TO_NEW: 'New Active to',
	MODAL_BATCH_STEP_PRODUCT_RECOMMENDATION: 'Product recommendation',
	MODAL_BATCH_STEP_PRODUCT_RECOMMENDATION_PLACEHOLDER:
		'Select product recommendation',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'Next',
	MODAL_BATCH_STEP_FORM_POS_CONFIGURATION_TABLE_TITLE: 'POS configurations',
	MODAL_BATCH_STEP_FORM_WORKPLACE_TABLE_TITLE: 'Workplaces',
	MODAL_BATCH_STEP_FORM_TABLE_LEGACY_FILTER: 'Legacy',
	MODAL_BATCH_STEP_FORM_TABLE_WORKPLACE_FILTER: 'Workplace open',
	MODAL_BATCH_STEP_ONE_FORM_TITLE: 'Select POS configurations',
	MODAL_BATCH_STEP_TWO_FORM_TITLE: 'Select workplaces',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'Back',
	MODAL_BATCH_STEP_LIST_LEGEND_CANT_ADD:
		'Workplace to POS configuration relation exists',
	MODAL_BATCH_STEP_LIST_TITLE: 'Confirm entries',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Submit',
	MODAL_BATCH_STEP_POS_TILE_LAYOUT: 'POS tyle layout',
	MODAL_BATCH_STEP_POS_TILE_LAYOUT_PLACEHOLDER: 'Select POS tile layout',
	MODAL_BATCH_STEP_APP_TILE_LAYOUT: 'App tile layout',
	MODAL_BATCH_STEP_APP_TILE_LAYOUT_PLACEHOLDER: 'Select app tile layout',
	MODAL_BATCH_STEP_INGREDIENT_CONFIGURATION: 'Ingredient configuration',
	MODAL_BATCH_STEP_INGREDIENT_CONFIGURATION_PLACEHOLDER:
		'Select ingredient configuration',
	DUPLICATE_AS_NEW_BUTTON_LABEL: 'Duplicate as new',
	DUPLICATE_AS_NEW_TOOLTIP:
		'Overwrite existing Workplace to pos configuration relations and create new',

	MODAL_BATCH_TAGS_TITLE: 'Selected Workplace to pos configuration relations',
	MODAL_BATCH_STEP_FORM_TITLE_POS_CONFIGURATION: 'Select Pos configuration',
	MODAL_BATCH_STEP_FORM_TITLE_WORKPLACE: 'Select workplace',
	MODAL_BATCH_MARKET_TAGS_TITLE: 'Showing workplaces for following markets:',
	MODAL_BATCH_STEP_GLOBAL_ROW_TIP: 'Select values to overwrite all rows',
	MODAL_BATCH_STEP_REVERT_TOOLTIP: 'Revert row',
	MODAL_BATCH_STEP_DUPLICATE_AS_NEW_LIST_TITLE:
		'End old and create new entries',
};
