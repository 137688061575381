export const ADD_REVIEWS = 'TRAINING_HISTORY_REVIEWS/ADD_REVIEWS';
export const UPDATE_REVIEW = 'TRAINING_HISTORY_REVIEWS/UPDATE_REVIEW';
export const SET_REVIEWER = 'TRAINING_HISTORY_REVIEWS/SET_REVIEWER';
export const RESET_REVIEWS = 'TRAINING_HISTORY_REVIEWS/RESET_REVIEWS';

export function addReviews(reviews = []) {
	return {
		type: ADD_REVIEWS,
		payload: {
			reviews
		}
	};
}

export function updateReview(updatedReview = {}, index) {
	return {
		type: UPDATE_REVIEW,
		payload: {
			updatedReview,
			index
		}
	};
}

export function setReviewer(reviewer = {}) {
	return {
		type: SET_REVIEWER,
		payload: {
			reviewer
		}
	};
}

export function resetReviews() {
	return {
		type: RESET_REVIEWS
	};
}
