export const SET_ONBOARDING_DATA = 'CREATE_ONBOARDING/SET_ONBOARDING_DATA';
export const RESET_ONBOARDING_DATA = 'CREATE_ONBOARDING/RESET_ONBOARDING_DATA';

export function setOnboardingData(key, val) {
	return {
		type: SET_ONBOARDING_DATA,
		payload: { key, val },
	};
}
export function resetOnboardingData() {
	return {
		type: RESET_ONBOARDING_DATA,
	};
}
