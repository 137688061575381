import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';
import _isEmpty from 'lodash/isEmpty';

/**
 * @function getMarketIds
 * @description loops through pos configurations and returns market id in an
 * array like ['1', '2', '3'....]
 */
export function getMarketsFilter(data) {
	const cleanData = _uniqBy(data, 'value.market.id');

	return cleanData.reduce((acc, currentValue, index) => {
		const id = _get(currentValue, 'value.market.id', null);

		acc = index === cleanData.length - 1 ? `${acc}'${id}']` : `${acc}'${id}',`;

		return acc;
	}, '[');
}

/**
 * @function getWorkplacePosConfigurationFilter
 * @param {Array} array - mapped values
 * @description loops through mapped values and puts id's into an array
 * so we can query the get endpoint and see what combos already exist
 * that way we ban the user from adding a combo
 */
export function getWorkplacePosConfigurationFilter(data) {
	const cleanWorkplaceIds = _uniqBy(data, 'workplace.id');

	// get workplace ids
	const workplaceIds = cleanWorkplaceIds.reduce((acc, currentValue, index) => {
		const id = _get(currentValue, 'workplace.id', null);

		acc =
			index === cleanWorkplaceIds.length - 1
				? `${acc}'${id}']`
				: `${acc}'${id}',`;

		return acc;
	}, '[');

	return `:workplace.id=IN=${workplaceIds};:active.from=le='${data[0].activeTo}';:active.to=ge='${data[0].activeFrom}'`;
}

/**
 * @function markValuesWithCanAdd
 * @param {Object} - contains mapped values and values from the api
 * @description maps through every listArray value and if it already exist
 * i.e. workplace found in res object, mark it with canAdd: false, otherwise
 * add canAdd: true
 */
export function markValuesWithCanAdd({ listArray, res }) {
	return listArray.map(entry => {
		const workplaceId = entry.workplace.id;

		const found = res.find(value => value.workplace.id === workplaceId);

		return {
			...entry,
			canAdd: _isEmpty(found),
			...(found && {
				activeFrom: found.active.from,
				activeTo: found.active.to
			})
		};
	});
}
