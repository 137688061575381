import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { SmartTable, Card, Loader, Tooltip, Button, Icon } from 'dumb';
import { Tip } from 'dumb/tips';

// services
import { fetchProducts } from './homeScreenRecommendations.service';

// utils
import { withDragDropContext } from 'utils';

// lodash
import _isEmpty from 'lodash/isEmpty';

// components
import HomeScreenRecommendationsTable from './components/homeScreenRecommendationsTable/homeScreenRecommendationsTable';
import HomeScreenRecommendationsProductsDragLayer from './components/homeScreenRecommendationsProductsDragLayer/homeScreenRecommendationsProductsDragLayer.component';

// phrases
import phrases from './homeScreenRecommendations.phrases';

class HomeScreenRecommendationsComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			productSlotToOcupy: null,
		};

		this.headers = [
			{ value: 'Product', sortable: true },
			{ value: 'Product category', width: 120 },
		];

		this.formatData = this.formatData.bind(this);
		this.toggleRowHovered = this.toggleRowHovered.bind(this);
		this.handleOnProductRowClick = this.handleOnProductRowClick.bind(this);
	}

	componentDidMount() {
		fetchProducts().then((res) => {
			this.props.setProducts(res);
		});
	}

	toggleRowHovered(isHovered, item) {
		const { timeSlots } = this.props;

		if (isHovered) {
			timeSlots.some((entry) => {
				return entry.homeScreenRecommendations.some((entryTwo) => {
					return [...Array(6)].some((entryThree, indexThree) => {
						if (entryTwo[`product_${indexThree + 1}`] === null) {
							const payload = {
								id: entryTwo.id,
								productSlot: `product_${indexThree + 1}`,
								item,
							};

							this.setState(() => ({
								productSlotToOcupy: payload,
							}));

							return true;
						}
					});
				});
			});
		} else this.setState(() => ({ productSlotToOcupy: null }));
	}

	formatData() {
		const { products } = this.props;

		return products.map((entry) => [
			{
				value: entry.name,
				data: entry,
			},
			{
				value: entry.product_category?.name,
			},
		]);
	}

	handleOnProductRowClick(product) {
		const { timeSlots, editTileLayoutHomeScreenRecommendations } = this.props;

		// not the nicest structure but for now it'll do
		timeSlots.some((entry) => {
			return entry.homeScreenRecommendations.some((entryTwo) => {
				return [...Array(6)].some((entryThree, indexThree) => {
					if (entryTwo[`product_${indexThree + 1}`] === null) {
						const payload = {
							id: entryTwo.id,
							[`product_${indexThree + 1}`]: product.id,
						};

						editTileLayoutHomeScreenRecommendations(payload, indexThree + 1);

						return true;
					}
				});
			});
		});
	}

	render() {
		const {
			timeSlots,
			layoutName,
			editTileLayoutHomeScreenRecommendations,
			homeScreenRecommendationLoading,
			onEditClick,
			loading,
			onDeleteClick,
			products,
			addHomeScreenRecommendations,
			deleteTimeSlot,
			homeScreenRecommendationProductLoading,
			homeScreenRecommendationDescriptionLoading,
			onDuplicateClick,
		} = this.props;

		return (
			<div className="home-screen-recommendations__wrapper">
				<div className="home-screen-recommendations__wrapper__tip-wrapper">
					<Tip
						className="home-screen-recommendations__wrapper__tip-wrapper__tip"
						content={phrases.CLICK_TO_ADD_TIP}
					/>
					<Tooltip
						text={phrases.DUPLICATE_TIP}
						renderData={(ref, onMouseEnter, onMouseLeave) => (
							<Button
								shadow
								type="inverted"
								label={phrases.DUPLICATE_CONFIRM_LABEL}
								onClick={onDuplicateClick}
								refProp={ref}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}>
								<Icon name="content_copy" />
							</Button>
						)}
					/>
				</div>

				<div className="home-screen-recommendations__wrapper__card-wrapper">
					<Card className="home-screen-recommendations__wrapper__card-wrapper__time-slots">
						{loading && (
							<div className="home-screen-recommendations__wrapper__card-wrapper__time-slots__loader">
								<Loader
									className="home-screen-recommendations__wrapper__card-wrapper__time-slots__loader__spinner"
									loading
								/>
							</div>
						)}
						<HomeScreenRecommendationsTable
							timeSlots={timeSlots}
							layoutName={layoutName}
							editTileLayoutHomeScreenRecommendations={
								editTileLayoutHomeScreenRecommendations
							}
							homeScreenRecommendationLoading={homeScreenRecommendationLoading}
							onEditClick={onEditClick}
							onDeleteClick={onDeleteClick}
							addHomeScreenRecommendations={addHomeScreenRecommendations}
							deleteTimeSlot={deleteTimeSlot}
							productSlotToOcupy={this.state.productSlotToOcupy}
							homeScreenRecommendationProductLoading={
								homeScreenRecommendationProductLoading
							}
							homeScreenRecommendationDescriptionLoading={
								homeScreenRecommendationDescriptionLoading
							}
						/>
					</Card>
					<Card className="home-screen-recommendations__wrapper__card-wrapper__products-table">
						{_isEmpty(products) ? (
							<Loader
								loading
								className="home-screen-recommendations__wrapper__card-wrapper__products-table__loader"
							/>
						) : (
							<SmartTable
								headers={this.headers}
								draggableRows
								theme="dark"
								search
								data={this.formatData()}
								formatDraggableOutput={(object) => object[0].data}
								loading={loading}
								onRowItemClick={this.handleOnProductRowClick}
								isRowHovered={this.toggleRowHovered}
							/>
						)}
						<HomeScreenRecommendationsProductsDragLayer />
					</Card>
				</div>
			</div>
		);
	}
}

HomeScreenRecommendationsComponent.propTypes = {
	timeSlots: PropTypes.array,
	layoutName: PropTypes.string,
	products: PropTypes.array,
	editTileLayoutHomeScreenRecommendations: PropTypes.func,
	onEditClick: PropTypes.func,
	homeScreenRecommendationLoading: PropTypes.object,
	loading: PropTypes.bool,
	onDeleteClick: PropTypes.func,
	setProducts: PropTypes.func,
	addHomeScreenRecommendations: PropTypes.func,
	deleteTimeSlot: PropTypes.func,
	homeScreenRecommendationProductLoading: PropTypes.object,
	homeScreenRecommendationDescriptionLoading: PropTypes.object,
	onDuplicateClick: PropTypes.func,
};

export default withDragDropContext(HomeScreenRecommendationsComponent);
