export const SET_PRODUCT_VARIANT_INGREDIENT_ALTERNATIVE =
	'PRODUCT_VARIANT_INGREDIENT_ALTERNATIVES/SET_PRODUCT_VARIANT_INGREDIENT_ALTERNATIVE';
export const UPDATE_PRODUCT_VARIANT_INGREDIENT_ALTERNATIVE =
	'PRODUCT_VARIANT_INGREDIENT_ALTERNATIVES/UPDATE_PRODUCT_VARIANT_INGREDIENT_ALTERNATIVE';

export const UPDATE_BATCH_FORM =
	'PRODUCT_VARIANT_INGREDIENT_ALTERNATIVES/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'PRODUCT_VARIANT_INGREDIENT_ALTERNATIVES/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'PRODUCT_VARIANT_INGREDIENT_ALTERNATIVES/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'PRODUCT_VARIANT_INGREDIENT_ALTERNATIVES/REMOVE_BATCH_LIST_ITEM';
export const RESET_STATE =
	'PRODUCT_VARIANT_INGREDIENT_ALTERNATIVES/RESET_STATE';

export function setProductVariantIngredientAlternative(payload) {
	return {
		type: SET_PRODUCT_VARIANT_INGREDIENT_ALTERNATIVE,
		payload,
	};
}

export function updateProductVariantIngredientAlternative(payload) {
	return {
		type: UPDATE_PRODUCT_VARIANT_INGREDIENT_ALTERNATIVE,
		payload,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}
