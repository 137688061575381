'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import moment from 'moment';
// components
import { Input, Button, Icon } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';
import PosTiersModalBatch from './components/batch/posTiersModalBatch';

import { fetchPosTiers, addPosTiers, deletePosTiers, editPosTier, editPosTiers } from './posTiers.service';

import {
	// batch stuff
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setPosTier,
	updatePosTier,
	resetPosTier,
} from './store/posTiers.actions';

// lodash
import _isEmpty from 'lodash/isEmpty';

// constants/phrases/utils
import phrases from './posTiers.phrases';
import { getEditedValues } from 'services/utils';

const reduxKey = '/pos/tiers';

class PosTiers extends Component {
	constructor(props) {
		super(props);

		this.state = {
			key: moment(),
			showModal: false,
		};

		this.fetchData = this.fetchData.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.getEditableCells = this.getEditableCells.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.addPosTiersWrapper = this.addPosTiersWrapper.bind(this);
		this.editMultiple = this.editMultiple.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: 'name',
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: 'description',
				filterPath: ':description',
			},
			{
				Header: 'Sort order',
				id: 'sortOrder',
				accessor: 'sort_order',
				filterPath: ':sort_order',
			},
			{
				Header: 'External Id',
				id: 'external_id',
				accessor: 'external_id',
				filterPath: ':external_id',
			},
		];
	}

	editEntry() {
		const { posTier, initialPosTier } = this.props;

		const editedValues = getEditedValues({
			newData: posTier,
			oldData: initialPosTier,
		});

		if (_isEmpty(editedValues)) return Promise.resolve(true);

		const payload = {
			id: posTier.id,
			name: editedValues.name ?? undefined,
			description: editedValues.description ?? undefined,
			sort_order: editedValues.sortOrder ?? undefined,
			external_id: editedValues.externalId ?? undefined,
		};

		return editPosTier(payload);
	}

	editMultiple(selectedRows) {
		const { posTier } = this.props;

		const payload = {
			...(posTier.name && {
				name: posTier.name,
			}),
			...(posTier.description && {
				description: posTier.description,
			}),
			...(posTier.sortOrder && {
				sort_order: posTier.sortOrder,
			}),
			...(posTier.externalId && {
				external_id: posTier.externalId,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editPosTiers({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deletePosTiers(id);
	}

	setInitialEditValues(data) {
		const { setPosTier } = this.props;

		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			sortOrder: data.sort_order,
			externalId: data.external_id,
		};

		setPosTier(payload);
	}

	getEditableCells() {
		const { posTier } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder={phrases.MODAL_BATCH_STEP_NAME_PLACEHOLDER}
						onChange={(e) => this.editStoreEntry('name', e.target.value)}
						required
						value={posTier.name}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder={phrases.MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER}
						onChange={(e) => this.editStoreEntry('description', e.target.value)}
						value={posTier.description}
					/>
				),
			},
			{
				header: 'Sort order',
				value: (
					<Input
						id="sortOrder"
						placeholder={phrases.MODAL_BATCH_STEP_SORT_ORDER_PLACEHOLDER}
						onChange={(e) => this.editStoreEntry('sortOrder', e.target.value)}
						value={posTier.sortOrder}
					/>
				),
			},
			{
				header: 'External ID',
				value: (
					<Input
						id="externalId"
						placeholder={phrases.MODAL_BATCH_STEP_EXTERNAL_ID_PLACEHOLDER}
						onChange={(e) => this.editStoreEntry('externalId', e.target.value)}
						value={posTier.externalId}
					/>
				),
			},
		];
	}

	editStoreEntry(name, value) {
		const { updatePosTier } = this.props;

		const payload = {
			[name]: value,
		};

		updatePosTier(payload);
	}

	fetchData(state) {
		return fetchPosTiers(state);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetPosTier();
	}

	addPosTiersWrapper(data) {
		return addPosTiers(data).then(() => {
			this.setState(() => ({
				key: moment(),
			}));
		});
	}

	render() {
		const { batchList, editBatchListItem, addBatchListItemRow, removeBatchListItem } = this.props;

		return (
			<>
				<ReactDataWrapper
					key={this.state.key}
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={reduxKey}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					deleteEntry={this.deleteEntry}
					editMultiple={this.editMultiple}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetPosTier}
					accessAreasAllowedToEdit={['Global Sales Configuration', 'Sales Configuration']}
					actionRender={
						<Button type="inverted" label="Batch" shadow onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
				/>

				<PosTiersModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addPosTiers={this.addPosTiersWrapper}
				/>
			</>
		);
	}
}

PosTiers.propTypes = {
	setPosTier: PropTypes.func,
	resetPosTier: PropTypes.func,
	posTier: PropTypes.object,
	initialPosTier: PropTypes.object,
	updatePosTier: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			addBatchListItemRow,
			setPosTier,
			updatePosTier,
			resetPosTier,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		posTier: store.globalAdministrationPosTiers.data.posTier,
		initialPosTier: store.globalAdministrationPosTiers.data.initialPosTier,
		batchList: store.globalAdministrationPosTiers.data.batchList,
	};
};

export default connectWithStore(PosTiers, mapStateToProps, mapDispatchToProps);
