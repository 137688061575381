import { get, post } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './workplaceOperationalResponsibilities.phrases';

/**
 * @function fetchWorkplaceOperationalResponsibilities
 * @description fetch workplace operational responsibilities
 * @param {object} state
 */
export function fetchWorkplaceOperationalResponsibilities({ state, filter }) {
	if (state.filter) filter = `${filter};${state.filter}`;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get('/hr/operational_responsibilities', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addWorkplaceOperationalResponsibilities
 * @description add workplace operational responsibilities
 */
export function addWorkplaceOperationalResponsibilities(data) {
	return post('/hr/operational_responsibilities', data)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_OPERATIONAL_RESPONSIBILITY_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
