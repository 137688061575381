'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { store, connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import { batch } from 'react-redux';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';
import { Input, InputCollectionSelect, Button, Icon, Tooltip, Toggle, Tabs } from 'dumb';
import ModalBatch from './components/modalBatch/modalBatch.container';
import HomeScreenRecommendations from './components/homeScreenRecommendations/homeScreenRecommendations.container';
import HomeScreenRecommendationTranslations from './components/homeScreenRecommendationTranslations/homeScreenRecommendationTranslations.component';
import POSConfigurationLayout from '../pos/posConfigurationLayout.container';
import WorkplacePosConfiguration from './../../posWorkplaceRelation/components/workplacePosConfiguration/workplacePosConfiguration.component';
import getColumns from 'reactDataWrapperColumns/pos/tileLayouts.columns';

import {
	setPOSList,
	updatePosListRow,
	resetState,
	updateBatchForm,
	addBatchList,
	resetBatch,
	editBatchListItem,
	removeBatchListItem,
	removeDuplicateLayoutListItem,
	editDuplicateLayoutListItem,
	updateTileLayoutTilePosition,
	markGroupAsSubmitted,
} from './store/tileLayout.actions';

import {
	fetchPOSList,
	editPOSList,
	editPOSLists,
	addPOSList,
	deletePOSList,
	fetchProductProducts,
	fetchTileLayoutTiles,
	fetchAssets,
	submitPositionRotations,
} from './tileLayout.service';

// lodash
import _get from 'lodash/get';

import phrases from './tileLayout.phrases';
import { enums } from './tileLayout.enums';

class TileLayouts extends Component {
	constructor(props) {
		super(props);

		this.reduxKey = 'pos/tile_layouts';
		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.duplicateTileLayout = this.duplicateTileLayout.bind(this);
		this._toggleModal = this._toggleModal.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.getExtraButtons = this.getExtraButtons.bind(this);
		this.getReduxKey = this.getReduxKey.bind(this);

		this.state = {
			showModal: false,
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this.columns = getColumns();
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}>
				Legacy
			</Button>
		);
	}

	addEntry() {
		const { defaultValues } = this.props;

		const payload = {
			name: _get(defaultValues, 'name', ''),
			description: _get(defaultValues, 'description', ''),
			type: _get(defaultValues, 'type.value', enums.TYPES[0]),
			rows: _get(defaultValues, 'rows', 1),
			columns: _get(defaultValues, 'columns', 1),
			...(_get(defaultValues, 'sub_type.value', false) && {
				sub_type: defaultValues.sub_type.value,
			}),
			...(_get(defaultValues, 'showCaseImage.value.id', false) && {
				show_case_image: defaultValues.showCaseImage.value.id,
			}),
		};

		return addPOSList(payload);
	}

	editEntry() {
		const { defaultValues } = this.props;

		const payload = {
			id: defaultValues.id,
			name: _get(defaultValues, 'name', ''),
			description: _get(defaultValues, 'description', ''),
			legacy: !!defaultValues.legacy,
			...(defaultValues.subType && {
				sub_type: defaultValues.subType.value,
			}),
			...(defaultValues.showCaseImage?.value && {
				show_case_image: defaultValues.showCaseImage?.value?.id,
			}),
		};

		return editPOSList(defaultValues.id, payload);
	}

	editMultiple(selectedRows) {
		const { defaultValues } = this.props;

		const payload = {
			...(defaultValues.name && {
				name: defaultValues.name,
			}),
			...(defaultValues.description && {
				description: defaultValues.description,
			}),
			...(defaultValues.subType && {
				sub_type: defaultValues.subType.value,
			}),
			...(defaultValues.showCaseImage && {
				show_case_image: defaultValues.showCaseImage?.value?.id,
			}),
			legacy: !!defaultValues.legacy,
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editPOSLists({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deletePOSList(id);
	}

	duplicateTileLayout(tileLayout) {
		const payload = {
			name: tileLayout.name,
			description: tileLayout.description,
			tile_layout: tileLayout.id,
		};

		// return addPOSList(payload);

		const newTileLayout = {
			...tileLayout,
			duplicated: true,
		};
		return addPOSList(payload).then(() => {
			this.props.editDuplicateLayoutListItem(newTileLayout);
		});
	}

	setInitialEditValues(data) {
		const payload = {
			...data,
			showCaseImage: data.show_case_image
				? {
						value: data.show_case_image,
						label: data.show_case_image.name,
				  }
				: null,
			subType: {
				value: data.sub_type,
				label: data.sub_type,
			},
			singleEdit: true,
		};

		this.props.setPOSList(payload);
	}

	getEditableCells() {
		const { defaultValues, listTileLayout } = this.props;

		const showSubTypeSelector = defaultValues.type === 'App' || defaultValues.type === 'Delivery';

		const selectedRows = _get(listTileLayout, 'ui.selectedRows', []);

		let canShowSubTypeSelector = false;
		if (selectedRows.length > 1) {
			canShowSubTypeSelector = selectedRows.every((entry) => entry.type === 'App' || entry.type === 'Delivery');
		}

		return [
			{
				header: phrases.TABLE_HEADER_LAYOUT_NAME,
				value: (
					<Input
						id="name"
						placeholder="name"
						value={_get(defaultValues, 'name', '')}
						onChange={(event) => this.editStoreEntry(event, 'name')}
					/>
				),
			},
			{
				header: phrases.TABLE_HEADER_DESCRIPTION,
				value: (
					<Input
						id="description"
						placeholder="description"
						value={_get(defaultValues, 'description', '')}
						onChange={(event) => this.editStoreEntry(event, 'description')}
					/>
				),
			},
			...(showSubTypeSelector || canShowSubTypeSelector
				? [
						{
							header: phrases.TABLE_HEADER_SUB_TYPE,
							value: (
								<InputCollectionSelect
									id="subType"
									placeholder="Select sub type"
									value={defaultValues.subType}
									handleChange={(key, value) => {
										this.editStoreEntry(value, 'subType');
									}}
									clearable={false}
									cache
									options={this._getSubTypeOptions()}
								/>
							),
						},
				  ]
				: []),
			{
				header: 'Show case image',
				value: (
					<InputCollectionSelect
						id="show-case-image"
						label={phrases.SHOW_CASE_IMAGE}
						placeholder={phrases.SHOW_CASE_IMAGE_PLACEHOLDER}
						value={defaultValues.showCaseImage}
						handleChange={(key, value) => {
							this.editStoreEntry(value, 'showCaseImage');
						}}
						clearable={false}
						cache
						apiPath="/pos/asset_collections"
						params={{
							limit: 30,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						tableTitle={phrases.SHOW_CASE_IMAGE_TABLE_TITLE}
						tableReduxKey="formAddLayout-/pos/asset_collections"
						tableColumns={[
							{
								Header: 'Name',
								id: 'name',
								accessor: 'name',
								filterPath: ':name',
							},
							{
								Header: 'Description',
								id: 'desc',
								accessor: 'description',
								filterPath: ':description',
							},
							{
								Header: 'Legacy',
								id: 'legacy',
								accessor: 'legacy',
								filterPath: ':legacy',
								filterable: false,
								Cell: (d) => {
									return <Input type="checkbox" checked={d.original.legacy} disabled />;
								},
							},
						]}
					/>
				),
			},
			{
				header: 'Legacy',
				value: (
					<Toggle
						id="legacy"
						toggled={defaultValues.legacy}
						onClick={(event) => this.editStoreEntry(event, 'legacy')}
					/>
				),
			},
		];
	}

	editStoreEntry(e, name) {
		const value = e.target ? e.target.value : e;

		const data = {
			[name]: value,
		};

		this.props.updatePosListRow(data);
	}

	_getSubTypeOptions() {
		return enums.SUB_TYPES.map((entry) => {
			return {
				value: entry,
				label: entry,
			};
		});
	}

	/**
	 * @method overrideOnExpandChange
	 * @description Overrides existing functionality for handling expanded rows in reactDataWrapper component
	 * @param {*} _
	 * @param {*} index
	 * @memberof TileLayouts
	 */
	overrideOnExpandChange(_, index) {
		// get existing redux store for this table

		const state = store.getState();
		const currentlyExpanded = state.listData[this.getReduxKey()].ui.expanded;

		batch(() => {
			// If clicked index is falsy
			if (!currentlyExpanded[index])
				store.dispatch(
					actions.onExpandChange({
						reduxKey: this.getReduxKey(),
						index,
					})
				);

			// map through already existing and set to false if true
			Object.keys(currentlyExpanded).map((key) => {
				if (currentlyExpanded[key] === true)
					store.dispatch(
						actions.onExpandChange({
							reduxKey: this.getReduxKey(),
							index: Number(key),
						})
					);
			});
		});
	}

	_toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
	}

	getExtraButtons() {
		return (
			<Tooltip
				text={phrases.ACTIONS_TOOLTIP}
				placement="left"
				renderData={(ref, onMouseEnter, onMouseLeave) => (
					<Button
						type="inverted"
						label={phrases.ACTIONS}
						shadow
						onClick={() => this._toggleModal()}
						refProp={ref}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}>
						<Icon name="add" />
					</Button>
				)}
			/>
		);
	}

	fetchData(state) {
		const { type, productVariantId } = this.props;

		let filter;
		if (type) filter = `:type=='${type}'`;
		if (productVariantId)
			filter = filter
				? `${filter};:tile_layout_groups.tile_layout_tiles.product.product_variants.id=='${productVariantId}'`
				: `:tile_layout_groups.tile_layout_tiles.product.product_variants.id=='${productVariantId}'`;

		return fetchPOSList(state, filter);
	}

	getReduxKey() {
		const { productVariantId, batchSelection, batchReduxKey } = this.props;

		if (batchSelection && batchReduxKey) return batchReduxKey;
		if (productVariantId) return `${this.reduxKey}-${productVariantId}`;

		return this.reduxKey;
	}

	render() {
		const {
			updateBatchForm,
			batchForm,
			listLayoutGroupsData,
			listProductsData,
			addBatchList,
			resetBatch,
			editBatchListItem,
			removeBatchListItem,
			removeDuplicateLayoutListItem,
			editDuplicateLayoutListItem,
			batchList,
			defaultValues,
			updatePosListRow,
			resetState,
			// batch props
			onInitialization,
			style,
			defaultPageSize,
			batchSelection,
			enableSingleSelection,
			type,
			updateTileLayoutTilePosition,
			markGroupAsSubmitted,
		} = this.props;

		const legacyFilter = this.state.legacyFilterOn ? this.state.legacyFilter : '';

		let tableTitle = phrases.TABLE_TITLE;
		if (type === 'App') tableTitle = phrases.TABLE_TITLE_APP;
		if (type === 'POS') tableTitle = phrases.TABLE_TITLE_POS;

		return (
			<>
				<ReactDataWrapper
					accessAreasAllowedToEdit={['Sales Configuration']}
					title={tableTitle}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={batchSelection ? defaultPageSize : 10}
					reduxKey={this.getReduxKey()}
					style={batchSelection ? style : {}}
					{...(batchSelection && { onInitialization })}
					batchSelection={batchSelection}
					enableSingleSelection={enableSingleSelection}
					manual
					subcomponent={(row) => {
						const tabContent = [
							{
								name: `POS Layout: ${row.original.type}`,
								component: (
									<POSConfigurationLayout
										layoutId={row.original.id}
										type={row.original.type}
										domain={row.original.domain}
										subType={row.original.sub_type}
									/>
								),
							},
							{
								name: phrases.STORES,
								component: <WorkplacePosConfiguration layout={row.original} defaultPageSize={10} />,
							},
							...(row.original.type === 'App'
								? [
										{
											name: phrases.HOME_SCREEN_RECOMMENDATIONS,
											component: (
												<HomeScreenRecommendations layoutId={row.original.id} layoutName={row.original.name} />
											),
										},
										{
											name: phrases.HOME_SCREEN_RECOMMENDATIONS_TRANSLATIONS,
											component: (
												<HomeScreenRecommendationTranslations id={row.original.id} layoutName={row.original.name} />
											),
										},
								  ]
								: []),
						];

						return (
							<SubTable>
								<Tabs tabContent={tabContent} />
							</SubTable>
						);
					}}
					onExpandChange={(newExpanded, index, event) => this.overrideOnExpandChange(newExpanded, index, event)}
					editEntry={(e) => this.editEntry(e)}
					editMultiple={this.editMultiple}
					editableCells={this.getEditableCells()}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					actionsWidth={60}
					deleteEntry={this.deleteEntry}
					actionRender={this.getExtraButtons()}
					customAreaComponents={this.getLegacyFilterButton()}
					extraFilters={legacyFilter}
				/>

				<ModalBatch
					modalVisible={this.state.showModal}
					handleClose={this._toggleModal}
					updateBatchForm={updateBatchForm}
					batchForm={batchForm}
					batchList={batchList}
					phrases={phrases}
					listLayoutGroupsData={listLayoutGroupsData}
					listProductsData={listProductsData}
					addBatchList={addBatchList}
					resetBatch={resetBatch}
					resetState={resetState}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					removeDuplicateLayoutListItem={removeDuplicateLayoutListItem}
					editDuplicateLayoutListItem={editDuplicateLayoutListItem}
					duplicateTileLayout={this.duplicateTileLayout}
					addEntry={this.addEntry}
					defaultValues={defaultValues}
					updatePosListRow={updatePosListRow}
					fetchProductProducts={fetchProductProducts}
					fetchTileLayoutTiles={fetchTileLayoutTiles}
					fetchAssets={fetchAssets}
					updateTileLayoutTilePosition={updateTileLayoutTilePosition}
					submitPositionRotations={submitPositionRotations}
					markGroupAsSubmitted={markGroupAsSubmitted}
				/>
			</>
		);
	}
}

TileLayouts.propTypes = {
	defaultValues: PropTypes.object,
	batchForm: PropTypes.object,
	listLayoutGroupsData: PropTypes.object,
	listProductsData: PropTypes.object,

	batchList: PropTypes.array,
	updatePosListRow: PropTypes.func,
	setPOSList: PropTypes.func,
	resetState: PropTypes.func,
	updateBatchForm: PropTypes.func,
	addBatchList: PropTypes.func,
	resetBatch: PropTypes.func,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	removeDuplicateLayoutListItem: PropTypes.func,
	editDuplicateLayoutListItem: PropTypes.func,
	listTileLayout: PropTypes.object,
	onInitialization: PropTypes.func,
	reduxKey: PropTypes.string,
	style: PropTypes.object,
	defaultPageSize: PropTypes.number,
	batchSelection: PropTypes.bool,
	enableSingleSelection: PropTypes.bool,
	type: PropTypes.string,
	updateTileLayoutTilePosition: PropTypes.func,
	markGroupAsSubmitted: PropTypes.func,
	productVariantId: PropTypes.number,
	batchReduxKey: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setPOSList,
			updatePosListRow,
			updateBatchForm,
			resetState,
			addBatchList,
			resetBatch,
			editBatchListItem,
			removeBatchListItem,
			removeDuplicateLayoutListItem,
			editDuplicateLayoutListItem,
			updateTileLayoutTilePosition,
			markGroupAsSubmitted,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultValues: store.salesConfigurationPOSLists.data.tileLayoutRow,
		batchForm: store.salesConfigurationPOSLists.data.batchForm,
		batchList: store.salesConfigurationPOSLists.data.batchList,
		listLayoutGroupsData: store.listData['tile_layouts/overlay/pos/tile_layout_groups/'],
		listProductsData: store.listData['tile_layouts/overlay/product/products/'],
		listTileLayout: store.listData['pos/tile_layouts'],
	};
};

export default connectWithStore(TileLayouts, mapStateToProps, mapDispatchToProps);
