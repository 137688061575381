import React, { useState, useRef } from 'react';

// redux
import { batch } from 'react-redux';
import {
	useAppDispatch,
	useAppSelector,
} from './../../../../appState/storeHooks';
import {
	setShowModal,
	resetData,
	setMetadata,
} from './store/removeShiftsFromEmployee.actions';

// redux-actions based on the source of using this modal
import { removeShiftsFromEmployeeById } from './../../../structure/shiftPlanner/actions/shifts.actions';
import { removeEntry } from 'reactDataWrapper/reactDataWrapper.actions';

// components
import { ModalStepper } from 'dumb';
import RemoveShiftsFromEmployeePageOne from './components/removeShiftsFromEmployeePageOne';
import RemoveShiftsFromEmployeePageTwo from './components/removeShiftsFromEmployeePageTwo';

// services
import { fetchShifts, removeShifts } from './removeShiftsFromEmployee.service';

// phrases/ enums...
import phrases from './removeShiftsFromEmployee.phrases';
import constants from 'services/constants';
import enums from 'services/enums/removeShiftsFromEmployee';

const RemoveShiftsFromEmployee = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const {
		showModal,
		metadata: { shifts, source, juicerDetailsReduxKey },
		uiData: { workplace, date, employment },
	} = useAppSelector((state) => state.removeShiftsFromEmployee);
	const [loading, setLoading] = useState(false);
	const modalRef = useRef<ModalStepper>(null);

	const toggleModal = (): void => {
		if (showModal) {
			dispatch(resetData());
			goToStep(1);
		}
		dispatch(setShowModal(!showModal));
	};
	const handleRemoveShifts = (): void => {
		if (submitButtonDisabled) return;

		setLoading(true);

		const payload = {
			workplaceId: workplace.value?.id,
			employmentId: employment.value?.id,
			date: date.format(constants.shortDate),
		};

		removeShifts(payload)
			.then(() => {
				// if called from shiftplanner, clear out all persons shifts
				if (source === enums.SHIFTPLANNER) {
					const idOfShiftsToRemove = shifts?.map((entry) => entry.id);
					dispatch(removeShiftsFromEmployeeById(idOfShiftsToRemove));
				}
				// if called form juicer details remove the shift from the shifts table
				if (source === enums.JUICER_DETAILS && juicerDetailsReduxKey) {
					batch(() => {
						shifts?.forEach((shift) => {
							dispatch(
								removeEntry({
									reduxKey: juicerDetailsReduxKey,
									id: shift.id,
								})
							);
						});
					});
				}
			})
			.then(() => {
				// stop loaders, reset data, and close modal
				setLoading(false);
				dispatch(resetData());
				toggleModal();
			})
			// if error just stop the loader for now
			.catch(() => setLoading(false));
	};
	const handleFetchShifts = (): void => {
		if (!employment || !date) return;

		setLoading(true);

		const payload = {
			employmentId: employment.value?.id,
			date: date.format(constants.shortDate),
		};

		fetchShifts(payload)
			.then((res) => {
				dispatch(setMetadata({ shifts: res }));
				goToStep(2);
			})
			.finally(() => setLoading(false));
	};
	const goToStep = (step: number): void => {
		const castedModalStepperReferenceObject = modalRef?.current;

		castedModalStepperReferenceObject?.goToStep(step);
	};

	const submitButtonDisabled = !workplace || !employment || !date;
	const steps = [
		{
			component: <RemoveShiftsFromEmployeePageOne />,
			title: phrases.TITLE,
			isValid: !submitButtonDisabled,
			onNext: handleFetchShifts,
			loading,
			confirmButtonLabel: phrases.VIEW_SHIFTS,
			cancelButtonLabel: phrases.BACK,
			noScroll: false,
			allowOverflow: true,
		},
		{
			component: <RemoveShiftsFromEmployeePageTwo />,
			title: phrases.SHIFTS_PAGE_TITLE,
			isValid: true,
			onNext: handleRemoveShifts,
			loading,
			confirmButtonLabel: phrases.SHIFTS_PAGE_ON_NEXT,
			cancelButtonLabel: phrases.BACK,
			noScroll: false,
			allowOverflow: true,
			onBack: () => goToStep(1),
		},
	];

	return (
		<ModalStepper
			ref={modalRef}
			isOpen={showModal}
			steps={steps}
			showStep
			onClose={toggleModal}
		/>
	);
};

export default RemoveShiftsFromEmployee;
