import {
	UPDATE_NUTRITION_VALUE,
	SET_NUTRITION_VALUE,
	RESET_NUTRITION_VALUE
} from './productVariantIngredientNutritionValues.actions';

const defaultState = {
	ui: {},
	data: {
		defaultNutritionValues: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_NUTRITION_VALUE: {
		return {
			...state,
			data: {
				...state.data,
				defaultNutritionValues: payload
			}
		};
	}

	case UPDATE_NUTRITION_VALUE: {
		return {
			...state,
			data: {
				...state.data,
				defaultNutritionValues: {
					...state.data.defaultNutritionValues,
					value: payload.value,
					ingredient: payload.ingredient,
					nutrition_category: payload.nutrition_category,
					market: payload.market
				}
			}
		};
	}

	case RESET_NUTRITION_VALUE: {
		return {
			...state,
			data: {
				...state.data,
				defaultNutritionValues: {}
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
