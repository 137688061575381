'use strict';

import {
	FETCH_PHP_REPORT,
	SET_PHP_REPORT,
	SET_REPORT_TYPE,
	SET_PHP_REPORT_DATES
} from './phpReport.actions';

const defaultState = {
	fetchingPhpReport: false,
	reportData: [],
	type: 'Hourly',
	dates: {}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
	case FETCH_PHP_REPORT:
		return Object.assign({}, state, {
			fetchingPhpReport: payload.fetchingPhpReport
		});

	case SET_PHP_REPORT: {
		return {
			...state,
			reportData: payload
		};
	}

	case SET_PHP_REPORT_DATES:
		return Object.assign({}, state, {
			dates: payload
		});

	case SET_REPORT_TYPE:
		return Object.assign({}, state, {
			type: payload
		});

	default:
		return state;
	}
}

export default reducer;
