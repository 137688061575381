'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { Input, Button, Icon } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';
import IngredientComponentsModalBatch from './components/batch/ingredientComponentsModalBatch';
import getColumns from 'reactDataWrapperColumns/product/productIngredientComponents.columns';
import IngredientComponentTranslations from './components/ingredientComponentTranslations/ingredientComponentTranslations.component';
import GlobalIngredientComponentTranslations from './components/globalIngredientComponentTranslations/globalIngredientComponentTranslations.component';
import SubTable from 'reactDataWrapper/components/subTable';

import {
	fetchIngredientComponents,
	addIngredientComponents,
	deleteIngredientComponents,
	editIngredientComponent,
	editIngredientComponents,
} from './ingredientComponents.service';

import {
	// batch stuff
	updateBatchForm,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setIngredientComponent,
	updateIngredientComponent,
	resetIngredientComponent,
} from './store/ingredientComponents.actions';

// lodash
import _isEmpty from 'lodash/isEmpty';
import _has from 'lodash/has';

// constants/phrases/utils
import phrases from './ingredientComponents.phrases';
import { getEditedValues } from 'services/utils';
import moment from 'moment';

// styles
import './ingredientComponents.css';

const reduxKey = '/product/ingredient_components';

class IngredientComponents extends Component {
	constructor(props) {
		super(props);

		this.state = {
			key: moment(),
			showModal: false,
		};

		this.fetchData = this.fetchData.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.getEditableCells = this.getEditableCells.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.addIngredientComponentsWrapper = this.addIngredientComponentsWrapper.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);

		this.columns = getColumns(this.props.useGlobalResource);
	}

	editEntry() {
		const { ingredientComponent, initialIngredientComponent, useGlobalResource } = this.props;

		const editedValues = getEditedValues({
			newData: ingredientComponent,
			oldData: initialIngredientComponent,
		});

		if (_isEmpty(editedValues)) return Promise.resolve(true);

		const payload = {
			id: ingredientComponent.id,
			name: editedValues.name ?? undefined,
			...(_has(editedValues, 'market') &&
				!useGlobalResource && {
					market: editedValues.market ? editedValues.market.id : null,
				}),
		};

		return editIngredientComponent(payload, useGlobalResource);
	}

	editMultiple(selectedRows) {
		const { ingredientComponent, useGlobalResource } = this.props;

		const payload = {
			...(ingredientComponent.name && {
				name: ingredientComponent.name,
			}),
			...(ingredientComponent.market &&
				!useGlobalResource && {
					market: ingredientComponent.market.value.id,
				}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editIngredientComponents(
			{
				batch: selectedRowsWithId,
			},
			useGlobalResource
		);
	}

	deleteEntry(id) {
		const { useGlobalResource } = this.props;

		return deleteIngredientComponents(id, useGlobalResource);
	}

	setInitialEditValues(data) {
		const { setIngredientComponent, useGlobalResource } = this.props;

		const payload = {
			id: data.id,
			name: data.name,
			...(!useGlobalResource && {
				market: data.market?.name ?? null,
			}),
		};

		setIngredientComponent(payload);
	}

	getEditableCells() {
		const { ingredientComponent, useGlobalResource } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder={phrases.MODAL_BATCH_STEP_NAME_PLACEHOLDER}
						onChange={(e) => this.editStoreEntry('name', e.target.value)}
						required
						value={ingredientComponent.name}
					/>
				),
			},
			...(useGlobalResource
				? []
				: [
						{
							header: 'Market',
							value: <span>{ingredientComponent.market}</span>,
						},
				  ]),
		];
	}

	editStoreEntry(name, value) {
		const { updateIngredientComponent } = this.props;

		const payload = {
			[name]: value,
		};

		updateIngredientComponent(payload);
	}

	fetchData(state) {
		const { ingredient, useGlobalResource } = this.props;

		const filter = `:ingredient.id=='${ingredient.id}'`;

		return fetchIngredientComponents({ state, filter }, useGlobalResource);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetIngredientComponent();
	}

	addIngredientComponentsWrapper(data, useGlobalResource) {
		return addIngredientComponents(data, useGlobalResource).then(() => {
			this.setState(() => ({
				key: moment(),
			}));
		});
	}

	render() {
		const {
			batchList,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
			updateBatchForm,
			batchForm,
			ingredient,
			useGlobalResource,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					accessAreasAllowedToEdit={['Sales Configuration']}
					key={this.state.key}
					title={useGlobalResource ? phrases.GLOBAL_TABLE_TITLE : phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={
						useGlobalResource ? `useGlobalResource-${reduxKey}-${ingredient.id}` : `${reduxKey}-${ingredient.id}`
					}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					deleteEntry={this.deleteEntry}
					editMultiple={this.editMultiple}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetIngredientComponent}
					actionRender={
						<Button type="inverted" label="Batch" shadow onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
					subcomponent={(row) => (
						<SubTable>
							{useGlobalResource ? (
								<GlobalIngredientComponentTranslations id={row.original?.id ?? null} />
							) : (
								<IngredientComponentTranslations id={row.original?.id ?? null} />
							)}
						</SubTable>
					)}
				/>

				<IngredientComponentsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addIngredientComponents={this.addIngredientComponentsWrapper}
					updateBatchForm={updateBatchForm}
					batchForm={batchForm}
					ingredientId={ingredient.id}
					useGlobalResource={useGlobalResource}
				/>
			</>
		);
	}
}

IngredientComponents.propTypes = {
	setIngredientComponent: PropTypes.func,
	resetIngredientComponent: PropTypes.func,
	ingredientComponent: PropTypes.object,
	initialIngredientComponent: PropTypes.object,
	updateIngredientComponent: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	updateBatchForm: PropTypes.func,
	ingredient: PropTypes.object,
	batchForm: PropTypes.object,
	useGlobalResource: PropTypes.bool,
};

IngredientComponents.defaultProps = {
	useGlobalResource: false,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			editBatchListItem,
			removeBatchListItem,
			addBatchListItemRow,
			setIngredientComponent,
			updateIngredientComponent,
			resetIngredientComponent,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		ingredientComponent: store.ingredientComponents.data.ingredientComponent,
		initialIngredientComponent: store.ingredientComponents.data.initialIngredientComponent,
		batchList: store.ingredientComponents.data.batchList,
		batchForm: store.ingredientComponents.data.batchForm,
	};
};

export default connectWithStore(IngredientComponents, mapStateToProps, mapDispatchToProps);
