import React, { Component } from 'react';

// redux
import { store, connectWithStore } from 'appState';
import {
	addRowToSelection,
	resetSelection,
} from 'reactDataWrapper/reactDataWrapper.actions';

import cx from 'classnames';
import PropTypes from 'prop-types';

// Components
// import Select from 'react-select';
import Select from 'react-selectV3';

import { Icon, Tooltip, Button, ButtonGroup, Overlay } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';

// Services
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { get } from 'api.vanilla.service';

// select styles
import { getStyles } from './collectionSelect.styles';

// phrases
import phrases from './phrases.collectionComponenet';

// enums
import enums from 'services/enums/collectionSelect';

// Other
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';

import './collectionSelect.css';

class CollectionSelect extends Component {
	constructor(props) {
		super(props);

		this.state = {
			fetchingOptions: false,
			options: [],
			// options: null,
			value: props.value,
			showOverlay: false,
		};

		this.defaultParams = {
			limit: 300,
		};

		this._onFocus = this._onFocus.bind(this);
		this._handleChange = this._handleChange.bind(this);
		this._handleFetchCal = this._handleFetchCal.bind(this);
		this.renderClear = this.renderClear.bind(this);
		this.renderSelect = this.renderSelect.bind(this);
		this._handleToggleOverlay = this._handleToggleOverlay.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.onOverlayOpen = this.onOverlayOpen.bind(this);

		this._handleTyping = _debounce(this._handleTyping, 400, {
			trailing: true,
		}).bind(this);
	}

	componentWillUnmount() {
		const { tableColumns, tableTitle, tableReduxKey } = this.props;

		// if showing table overlay, make sure to clear out selected rows on umount
		if (tableColumns && tableTitle && tableReduxKey) {
			this.resetSelectedRows(tableReduxKey);
		}
	}

	_handleChange(data = {}) {
		const { tableColumns, tableReduxKey, tableTitle } = this.props;

		const value = data || null;

		// if selection cleared and we have overlay shown clear the selected rows
		// in react table
		if (_isEmpty(value) && tableColumns && tableReduxKey && tableTitle)
			this.resetSelectedRows(this.props.tableReduxKey);

		this.setState(
			() => ({ value }),
			() => {
				this.props.handleChange(this.props.name, value);
			}
		);
	}

	_onFocus() {
		if (this.props.onFocus) this.props.onFocus();
		// if (!this.state.options || this.props.forceRefresh) {
		if (this.state.options.length === 0 || this.props.forceRefresh) {
			this._handleFetchCal();
		}
	}

	_handleTyping(input) {
		if (input) {
			this.props.inputFilterFormat && this._handleFetchCal(input);
		}
	}

	_handleFetchCal(input) {
		const {
			apiPath,
			params,
			optionFormat,
			inputFilterFormat,
			defaultOptions,
			apiFilter,
		} = this.props;
		if (!apiPath) return;

		// if something was entered, add fitler query
		let newParams = {
			...this.defaultParams,
			...params,
		};

		if (input) {
			let filter = inputFilterFormat(input);

			if (params.filter) {
				filter = params.filter + ';' + filter;
			}

			newParams = { ...params, filter };
		}
		if (!input && apiFilter) {
			newParams = { ...params, filter: apiFilter };
		}

		this.setState(() => ({ fetchingOptions: true }));
		get(apiPath, newParams)
			.then((result) => {
				let options =
					result.data &&
					result.data
						.map(
							optionFormat === null
								? (entry) => ({ value: entry.id, label: entry.name })
								: optionFormat
						)
						.filter((i) => i);

				if (defaultOptions) options = [...defaultOptions, ...options];
				this.setState(() => ({
					fetchingOptions: false,
					options,
				}));
			})
			.catch((e) => {
				setFeedback(e.message, 0);
				this.setState(() => ({ fetchingOptions: false }));
			});
	}

	_handleToggleOverlay() {
		const { listData, multi, optionFormat } = this.props;

		if (this.state.showOverlay) {
			const selectedRows = _get(listData, 'ui.selectedRows', []);

			if (!_isEmpty(selectedRows)) {
				let value;

				if (multi) {
					value = selectedRows.map((entry) => {
						return optionFormat === null
							? { label: entry.name, value: entry }
							: optionFormat(entry);
					});
				} else {
					value =
						optionFormat === null
							? {
									label: selectedRows[0].name,
									value: selectedRows[0],
							  }
							: optionFormat(selectedRows[0]);
				}

				this._handleChange(value);
			}
		}

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
		}));
	}

	onOverlayOpen() {
		const { value, tableReduxKey, multi } = this.props;

		if (_isEmpty(value)) return;

		this.resetSelectedRows(tableReduxKey);

		// set already selected data in rdw store as clicked
		if (multi)
			value.map((entry) => {
				store.dispatch(
					addRowToSelection({
						reduxKey: tableReduxKey,
						data: entry.value,
					})
				);
			});
		else
			store.dispatch(
				addRowToSelection({
					reduxKey: tableReduxKey,
					data: value.value,
				})
			);
	}

	resetSelectedRows(reduxKey) {
		const { listData } = this.props;

		if (listData) store.dispatch(resetSelection({ reduxKey }));
	}

	renderClear(params) {
		const {
			innerProps: { ref, ...restInnerProps },
		} = params;
		const { styleType } = this.props;

		return (
			<div
				{...restInnerProps}
				ref={ref}
				className={cx('j-input__select__select-clear', {
					'j-input__select__select-clear--hotdamnbar':
						styleType === enums.TYPE_HOTDAMNBAR,
				})}
				data-cy="input-collection-select-clear-icon">
				<Icon name="clear" />
			</div>
		);
	}

	renderSelect() {
		const {
			placeholder,
			name,
			clearable,
			className,
			disabled,
			loading,
			size,
			multi,
			styleType,
			required,
		} = this.props;
		const { value, options, fetchingOptions } = this.state;

		const customClass = cx('collection-select', { [className]: className });

		return (
			<Select
				value={value}
				name={name}
				isLoading={fetchingOptions || loading}
				className={customClass}
				onChange={this._handleChange}
				onInputChange={this._handleTyping}
				placeholder={placeholder}
				components={{
					...(clearable && {
						ClearIndicator: this.renderClear,
					}),
				}}
				{...this.props}
				classNamePrefix="j-input__select"
				options={this.props.options || options}
				onFocus={this._onFocus}
				menuPortalTarget={document.body}
				menuPosition="absolute"
				menuPlacement="auto"
				styles={getStyles({
					size,
					styleType,
					required,
				})}
				isMulti={multi}
				isDisabled={disabled}
				isClearable={clearable}
			/>
		);
	}

	fetchData(state) {
		const { apiPath, params: propParams } = this.props;

		// get any extra params that we might have passed
		const extraParams = _omit(propParams, [
			'filter',
			'limit',
			'offset',
			'sort',
		]);

		const params = {
			filter: state.filter,
			limit: state.limit,
			offset: state.offset,
			sort: state.sort,
			...extraParams,
		};

		return get(apiPath, params);
	}

	render() {
		const { tableColumns, tableTitle, tableReduxKey, multi, params, disabled } =
			this.props;

		const showOverlayButton = tableColumns && tableTitle && tableReduxKey;

		return showOverlayButton ? (
			<>
				<ButtonGroup alignCenter type="full-width">
					{this.renderSelect()}
					<div className="collection-select__more-button-wrapper">
						<Tooltip
							text={phrases.VIEW_MORE}
							zIndex={551}
							renderData={(ref, onMouseEnter, onMouseLeave) => (
								<Button
									type="inverted"
									shadow
									size="mediumInput"
									disabled={disabled}
									onClick={this._handleToggleOverlay}
									onMouseEnter={onMouseEnter}
									refProp={ref}
									onMouseLeave={onMouseLeave}>
									<Icon name="more_horiz" />
								</Button>
							)}
						/>
					</div>
				</ButtonGroup>
				<Overlay
					zIndex={551}
					height={850}
					list
					visible={this.state.showOverlay}
					onClose={this._handleToggleOverlay}>
					<ReactDataWrapper
						title={tableTitle}
						columns={tableColumns}
						fetchData={this.fetchData}
						filterable
						disableFetchCsvButton
						batchSelection
						defaultPageSize={20}
						onInitialization={this.onOverlayOpen}
						reduxKey={tableReduxKey}
						manual
						actionsWidth={0}
						style={{
							maxHeight: '720px',
						}}
						enableSingleSelection={!multi}
						enableMultiSelection={multi}
						extraFilters={params.filter || ''}
					/>
				</Overlay>
			</>
		) : (
			this.renderSelect()
		);
	}
}

CollectionSelect.defaultProps = {
	value: null,
	clearable: true,
	pathSegmentParameters: null,
	optionFormat: null,
	handleChange: () => {},
	options: null,
	multi: false,
	required: false,
};

CollectionSelect.propTypes = {
	apiPath: PropTypes.string,
	apiFilter: PropTypes.string,
	params: PropTypes.object,
	value: PropTypes.any,
	name: PropTypes.string,
	className: PropTypes.string,
	handleChange: PropTypes.func,
	onFocus: PropTypes.func,
	openOnFocus: PropTypes.bool,
	clearable: PropTypes.bool,
	cache: PropTypes.bool,
	optionFormat: PropTypes.func,
	multi: PropTypes.bool,
	inputFilterFormat: PropTypes.func,
	placeholder: PropTypes.string,
	defaultOptions: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.any.isRequired,
			label: PropTypes.string.isRequired,
		})
	),
	type: PropTypes.oneOf(['smart']),
	disabled: PropTypes.bool,
	forceRefresh: PropTypes.bool,
	defaultValue: PropTypes.object,
	loading: PropTypes.bool,
	options: PropTypes.array,
	size: PropTypes.string,
	styleType: PropTypes.oneOf(Object.keys(enums).map((entry) => enums[entry])),
	required: PropTypes.bool,

	// for included overlay
	tableReduxKey: PropTypes.string,
	tableColumns: PropTypes.array,
	tableTitle: PropTypes.string,
	listData: PropTypes.object,
};

const mapStateToProps = (store, ownProps) => {
	return {
		listData: store.listData[ownProps.tableReduxKey],
	};
};

export default connectWithStore(CollectionSelect, mapStateToProps);
