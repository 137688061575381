'use strict';

class ReportComponentResultSetAbstract {

	/**
	 * @function getResultSet
	 * @memberOf ReportComponentResultSetAbstract
	 * @description Gets results for current component
	 */
	getResults() {
		return this.results;
	}
}

export default ReportComponentResultSetAbstract;