'use strict';

// Services
import DetailsModel from 'common/components/details/detailsModel.service';
import { validAccess } from 'accessControl';
const __endpoints = require('endpoints.service');

export default class TilePictureAssetsDetails extends DetailsModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'description',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
		];

		this.settings = {
			name: 'TilePictureAssetsDetails',
			canAdd: true,
			canEdit: true,
			scrollWrapper: false,
			canDelete: true,
			translationPath: 'POS.TILE_PICTURE_ASSETS_DETAILS',
			canManipulate: () => validAccess(undefined, ['Global Sales Configuration']),
			endpoint: __endpoints.collection.pos.tile_picture_assets,
		};
		this.init();
		return this;
	}
}
