import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Head = ({ children, mainHeader, subheader }) => {
	const classNames = classnames('report-head', {
		'report-head--main-header': mainHeader,
		'report-head--sub-header': subheader
	});

	return <thead className={classNames}>{children}</thead>;
};

Head.defaultProps = {
	className: null,
	title: ''
};

Head.propTypes = {
	mainHeader: PropTypes.bool,
	children: PropTypes.node,
	subheader: PropTypes.bool
};

export default Head;
