export const SET_LIST_DATA = 'LIST_TABLE_WRAPPER/SET_LIST_DATA';
export const REMOVE_ENTRY = 'LIST_TABLE_WRAPPER/REMOVE_ENTRY';
export const SET_LIST_SEARCH_FILTER = 'LIST_TABLE_WRAPPER/SET_LIST_SEARCH_FILTER';
export const SET_LIST_SORTING = 'LIST_TABLE_WRAPPER/SET_LIST_SORTING';
export const SET_LIST_META_DATA = 'LIST_TABLE_WRAPPER/SET_LIST_META_DATA';
export const SET_LAST_ROW_SELECTED = 'LIST_TABLE_WRAPPER/SET_LAST_ROW_SELECTED';
export const TOGGLE_ROW = 'LIST_TABLE_WRAPPER/TOGGLE_ROW';
export const REMOVE_ROW_FROM_SELECTION = 'LIST_TABLE_WRAPPER/REMOVE_ROW_FROM_SELECTION';
export const ADD_ROW_TO_SELECTION = 'LIST_TABLE_WRAPPER/ADD_ROW_TO_SELECTION';
export const SHOW_EDIT_MODAL = 'LIST_TABLE_WRAPPER/SHOW_EDIT_MODAL';
export const INITIALIZE_REDUCER = 'LIST_TABLE_WRAPPER/INITIALIZE_REDUCER';
export const INITIALIZE_DATA = 'LIST_TABLE_WRAPPER/INITIALIZE_DATA';
export const EDIT_ENTRY = 'LIST_TABLE_WRAPPER/EDIT_ENTRY';
export const ADD_ENTRY = 'LIST_TABLE_WRAPPER/ADD_ENTRY';
export const RESET_SELECTION = 'LIST_TABLE_WRAPPER/RESET_SELECTION';
export const SET_PAGE_NUMBER = 'LIST_TABLE_WRAPPER/SET_PAGE_NUMBER';
export const SET_TOTAL_PAGE_NUMBER = 'LIST_TABLE_WRAPPER/SET_TOTAL_PAGE_NUMBER';
export const ON_EXPAND_CHANGE = 'LIST_TABLE_WRAPPER/ON_EXPAND_CHANGE';
export const SET_EXPANDED_TO_DEFAULT = 'LIST_TABLE/SET_EXPANDED_TO_DEFAULT';

/**
 * @param {Array} shiftplannerReport
 */
export function showEditModal({ reduxKey, showEditModal }) {
	return {
		type: SHOW_EDIT_MODAL,
		payload: {
			reduxKey,
			showEditModal,
		},
	};
}

/**
 * @param {Array} shiftplannerReport
 */
export function removeRowFromSelection({ reduxKey, data }) {
	return {
		type: REMOVE_ROW_FROM_SELECTION,
		payload: {
			reduxKey,
			data,
		},
	};
}
/**
 * @param {Array} shiftplannerReport
 */
export function addRowToSelection({ reduxKey, data }) {
	return {
		type: ADD_ROW_TO_SELECTION,
		payload: {
			reduxKey,
			data,
		},
	};
}

/**
 * @param {Array} shiftplannerReport
 */
export function setLastRowSelected({ reduxKey, data }) {
	return {
		type: SET_LAST_ROW_SELECTED,
		payload: {
			reduxKey,
			data,
		},
	};
}

/**
 * @param {Array} shiftplannerReport
 */
export function toggleRow({ reduxKey, data }) {
	return {
		type: TOGGLE_ROW,
		payload: {
			reduxKey,
			data,
		},
	};
}

/**
 * @param {Array} shiftplannerReport
 */
export function resetSelection({ reduxKey }) {
	return {
		type: RESET_SELECTION,
		payload: {
			reduxKey,
		},
	};
}

/**
 * @param {Array} shiftplannerReport
 */
export function setListData({ reduxKey, listData }) {
	return {
		type: SET_LIST_DATA,
		payload: {
			reduxKey,
			listData,
		},
	};
}

/**
 * @param {Array} shiftplannerReport
 */
export function removeEntry({ reduxKey, id }) {
	return {
		type: REMOVE_ENTRY,
		payload: {
			reduxKey,
			id,
		},
	};
}

/**
 * @param {Array} shiftplannerReport
 */
export function editEntry({ reduxKey, entry }) {
	return {
		type: EDIT_ENTRY,
		payload: {
			reduxKey,
			entry,
		},
	};
}

/**
 * @param {Array} shiftplannerReport
 */
export function addEntry({ reduxKey, entry }) {
	return {
		type: ADD_ENTRY,
		payload: {
			reduxKey,
			entry,
		},
	};
}

/**
 * @param {Array} shiftplannerReport
 */
export function setListSearchFilter({ reduxKey, listSearchFilter }) {
	return {
		type: SET_LIST_SEARCH_FILTER,
		payload: {
			reduxKey,
			listSearchFilter,
		},
	};
}

/**
 * @param {Array} shiftplannerReport
 */
export function setListMetaData({ reduxKey, metadata }) {
	return {
		type: SET_LIST_META_DATA,
		payload: {
			reduxKey,
			...metadata,
		},
	};
}

/**
 * @param {Array} shiftplannerReport
 */
export function setListSorting({ reduxKey, listSorting }) {
	return {
		type: SET_LIST_SORTING,
		payload: {
			reduxKey,
			listSorting,
		},
	};
}

/**
 * @param {Array} shiftplannerReport
 */
export function initializeReducer({ reduxKey }) {
	return {
		type: INITIALIZE_REDUCER,
		payload: {
			reduxKey,
		},
	};
}

/**
 * @param {Array} shiftplannerReport
 */
export function initializeData({ reduxKey }) {
	return {
		type: INITIALIZE_DATA,
		payload: {
			reduxKey,
		},
	};
}

/**
 * @param {Number} page
 */
export function setPageNumber({ reduxKey, page }) {
	return {
		type: SET_PAGE_NUMBER,
		payload: {
			reduxKey,
			page,
		},
	};
}

/**
 * @param {Number} pages
 */
export function setTotalPageNumber({ reduxKey, pages }) {
	return {
		type: SET_TOTAL_PAGE_NUMBER,
		payload: {
			reduxKey,
			pages,
		},
	};
}

/**
 * @param {Number} pages
 */
export function onExpandChange({ reduxKey, index }) {
	return {
		type: ON_EXPAND_CHANGE,
		payload: {
			reduxKey,
			index,
		},
	};
}

export function setExpandedToDefault({ reduxKey }) {
	return {
		type: SET_EXPANDED_TO_DEFAULT,
		payload: {
			reduxKey,
		},
	};
}
