'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { InputCollectionSelect, SingleDatePickerInput } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';
import { DateFilterSelector, FilterProvider } from 'reactDataWrapper/utilities';

import {
	fetchMarketPayrollPeriods,
	addMarketPayrollPeriod,
	deleteMarketPayrollPeriod,
	editMarketPayrollPeriod,
} from './organisationMarketPayrollPeriods.service';

import {
	updateMarketPayrollPeriod,
	resetState,
	setMarketPayrollPeriod,
} from './store/organisationMarketPayrollPeriods.actions';

// phrases/constants/enums
import phrases from './organisationMarketPayrollPeriods.phrases';
import constants from 'services/constants';
import enums from './organisationMarketPayrollPeriods.enums';
import collectionSelectEnums from 'services/enums/collectionSelect';

// styles
import './organisationMarketPayrollPeriods.css';

const reduxKey = 'organisation/salary/payroll_periods';

class MarketPayrollPeriods extends Component {
	constructor(props) {
		super(props);

		this.fetchData = this.fetchData.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.getEditableCells = this.getEditableCells.bind(this);

		this.columns = [
			{
				Header: 'Status',
				id: 'status',
				accessor: 'status',
				filterPath: ':status',
				Filter: ({ column }) => (
					<FilterProvider
						reduxKey={reduxKey}
						columnId={column.id}
						provide={(filterValue, persistToFilterStorage) => (
							<InputCollectionSelect
								id={column.id}
								name="status"
								value={filterValue}
								handleChange={(key, value) => {
									persistToFilterStorage({ handle: key, value });
								}}
								options={this.getStatusOptions()}
								styleType={collectionSelectEnums.TYPE_IN_TABLE}
							/>
						)}
					/>
				),
			},
			{
				Header: 'From',
				id: 'from',
				Cell: (d) => {
					const date = d.original.period?.from;

					return date ? (
						<span>
							{moment
								.utc(date, constants.dateFormat)
								.format(constants.shortDate)}
						</span>
					) : null;
				},
				filterPath: ':period.from',
				Filter: ({ column }) => (
					<DateFilterSelector reduxKey={reduxKey} columnId={column.id} />
				),
			},
			{
				Header: 'To',
				id: 'to',
				Cell: (d) => {
					const date = d.original.period?.to;

					return date ? (
						<span>
							{moment
								.utc(date, constants.dateFormat)
								.format(constants.shortDate)}
						</span>
					) : null;
				},
				filterPath: ':period.to',
				Filter: ({ column }) => (
					<DateFilterSelector reduxKey={reduxKey} columnId={column.id} />
				),
			},
		];
	}

	addEntry() {
		const { marketId, marketPayrollPeriod } = this.props;

		const payload = {
			market: marketId,
			...(marketPayrollPeriod.status && {
				status: marketPayrollPeriod.status.value,
			}),
			period: {
				...(marketPayrollPeriod.from && {
					from: moment
						.utc(marketPayrollPeriod.from)
						.format(constants.shortDate),
				}),
				...(marketPayrollPeriod.to && {
					to: moment.utc(marketPayrollPeriod.to).format(constants.shortDate),
				}),
			},
		};

		return addMarketPayrollPeriod(payload);
	}

	editEntry() {
		const { marketPayrollPeriod } = this.props;

		const payload = {
			status: marketPayrollPeriod.status.value,
			period: {
				from: moment.utc(marketPayrollPeriod.from).format(constants.shortDate),
				to: moment.utc(marketPayrollPeriod.to).format(constants.shortDate),
			},
		};

		return editMarketPayrollPeriod(marketPayrollPeriod.id, payload);
	}

	deleteEntry(id) {
		return deleteMarketPayrollPeriod(id);
	}

	getStatusOptions() {
		const options = [
			{
				label: enums.PAID,
				value: enums.PAID,
			},
			{
				label: enums.OPEN,
				value: enums.OPEN,
			},
		];

		return options;
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			status: {
				value: data.status,
				label: data.status,
			},
			from: data.period.from,
			to: data.period.to,
			editMode: true,
		};

		this.props.setMarketPayrollPeriod(payload);
	}

	getEditableCells() {
		const { marketPayrollPeriod } = this.props;

		return [
			{
				header: 'Status',
				value: (
					<InputCollectionSelect
						id="status"
						placeholder="Select status..."
						value={marketPayrollPeriod.status}
						handleChange={(key, value) => this.editStoreEntry('status', value)}
						options={this.getStatusOptions()}
						clearable={false}
					/>
				),
			},
			{
				header: 'From',
				value: (
					<SingleDatePickerInput
						id="from"
						onChange={(event) => this.editStoreEntry('from', event)}
						selectedDate={
							marketPayrollPeriod.from
								? moment
										.utc(marketPayrollPeriod.from)
										.format(constants.shortDate)
								: ''
						}
						noClockButton
					/>
				),
			},
			{
				header: 'To',
				value: (
					<SingleDatePickerInput
						id="to"
						onChange={(event) => this.editStoreEntry('to', event)}
						selectedDate={
							marketPayrollPeriod.to
								? moment.utc(marketPayrollPeriod.to).format(constants.shortDate)
								: ''
						}
						noClockButton
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const data = {
			[name]: e,
		};

		this.props.updateMarketPayrollPeriod(data);
	}

	fetchData(state) {
		const { marketId } = this.props;

		const filter = `:market.id=='${marketId}'`;

		return fetchMarketPayrollPeriods(state, filter);
	}

	render() {
		const { customFilterColumns } = this.props;

		return (
			<ReactDataWrapper
				title={phrases.TABLE_TITLE}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={10}
				reduxKey={reduxKey}
				manual
				compact
				createEntry={this.addEntry}
				editEntry={this.editEntry}
				deleteEntry={this.deleteEntry}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetState}
				editableCells={this.getEditableCells()}
				extraFilters={
					customFilterColumns.status &&
					`:status=='${customFilterColumns.status.value}'`
				}
				editModalTip={phrases.MODAL_TIP}
			/>
		);
	}
}

MarketPayrollPeriods.propTypes = {
	resetState: PropTypes.func,
	setMarketPayrollPeriod: PropTypes.func,
	updateMarketPayrollPeriod: PropTypes.func,
	marketId: PropTypes.number,
	marketPayrollPeriod: PropTypes.object,
	customFilterColumns: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateMarketPayrollPeriod,
			setMarketPayrollPeriod,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		marketPayrollPeriod:
			store.organisationMarketPayrollPeriods.data.marketPayrollPeriod,
		customFilterColumns:
			store.filterSortColumnsData?.tables[reduxKey]?.custom ?? {},
	};
};

export default connectWithStore(
	MarketPayrollPeriods,
	mapStateToProps,
	mapDispatchToProps
);
