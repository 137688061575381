export const SET_POS_CONFIGURATION_PRODUCT_VARIANT_INGREDIENT_VARIATION =
	'POS_PRODUCT_DISCOUNT/SET_POS_CONFIGURATION_PRODUCT_VARIANT_INGREDIENT_VARIATION';
export const RESET_POS_CONFIGURATION_PRODUCT_VARIANT_INGREDIENT_VARIATION =
	'POS_PRODUCT_DISCOUNT/RESET_POS_CONFIGURATION_PRODUCT_VARIANT_INGREDIENT_VARIATION';
export const UPDATE_POS_CONFIGURATION_PRODUCT_VARIANT_INGREDIENT_VARIATION =
	'POS_PRODUCT_DISCOUNT/UPDATE_POS_CONFIGURATION_PRODUCT_VARIANT_INGREDIENT_VARIATION';

// ing
/**
 * @param {object} payload
 */
export function setPosConfigurationProductVariantIngredientVariation(payload) {
	return {
		type: SET_POS_CONFIGURATION_PRODUCT_VARIANT_INGREDIENT_VARIATION,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function updatePosConfigurationProductVariantIngredientVariation(
	payload
) {
	return {
		type: UPDATE_POS_CONFIGURATION_PRODUCT_VARIANT_INGREDIENT_VARIATION,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetPosConfigurationProductVariantIngredientVariation() {
	return {
		type: RESET_POS_CONFIGURATION_PRODUCT_VARIANT_INGREDIENT_VARIATION,
	};
}
