'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connectWithStore, store } from 'appState';
import { batch } from 'react-redux';
import * as actions from './employeeMeals.actions';
import PrintViewService from 'printView/printView.service';

// Components
// import ButtonLoader from 'buttonLoader';
import { Button, Loader, ButtonLoader } from 'dumb';
import CollectionSelect from 'collectionSelect';
import Hotdamnbar from 'hotdamnbar';
import EmployeeMealsMarketsReport from './components/employeeMealsMarkets.component';
import EmployeeMealsPrWorkplacesReportWrapper from './components/employeeMealsPrWorkplaceReportWrapper.component';
import EmployeeMealsReportContainerHotbar from './employeeMeals.hotbar';

// Tools
import {
	fetchEmployeeMealsReport,
	fetchEmployeeMealsReportPrPerson,
	fetchEmployeeMealsReportPrWorkplace,
} from './services';
import { abortEmloyeeMealReport } from './services/employeeMeals.service';
import _isEmpty from 'lodash/isEmpty';

// Other
import phrases from './employeeMeals.phrases';
import constants from 'services/constants';
import './employeeMeals.css';
import _get from 'lodash/get';
import collectionSelectEnums from 'services/enums/collectionSelect';

import { getStringBasedOnSelectedValues } from './utilities/utils';

class EmployeeMealsReportContainer extends Component {
	constructor(props) {
		super(props);
		this.phrases = phrases;

		this.totalColSpan = 13;

		this.printId = 'employeeMealsReport';

		this.state = {
			loadingPdf: false,
			loadingCsv: false,
			selectedPerson: null,
		};

		this.onHotbarChange = this.onHotbarChange.bind(this);

		this.handleFetchEmployeeMealsPrWorkplace = this.handleFetchEmployeeMealsPrWorkplace.bind(
			this
		);
		this.handleFetchEmployeeMealsPrEmployee = this.handleFetchEmployeeMealsPrEmployee.bind(
			this
		);
		this.printToPdf = this.printToPdf.bind(this);
		this.printToCsv = this.printToCsv.bind(this);
		this._handleSelectEmployee = this._handleSelectEmployee.bind(this);
		this._handleSelectMarket = this._handleSelectMarket.bind(this);
		this._handleSelectWorkplace = this._handleSelectWorkplace.bind(this);

		this.PrintViewService = new PrintViewService(
			{ id: this.printId },
			'Employee Meals Report'
		);
	}

	/**
	 * @function handleSelectEmployee
	 * @description Select employee and set person/employee ID to reducer + load data
	 * @param target
	 * @param event
	 */
	_handleSelectEmployee(target, event) {
		const { hotbarData, selectedMarket } = this.props;

		// set person to store
		store.dispatch(actions.setEmployee(event));

		// if no hotbar data, return
		if (_isEmpty(hotbarData)) return;

		batch(() => {
			// set report as loading and clear current data in report
			store.dispatch(actions.setLoadingReport(true));
			store.dispatch(actions.cleanReportData());
		});

		const payload = {
			...hotbarData,
			...(selectedMarket && { marketId: selectedMarket.value }),
		};

		this.handleFetchEmployeeMeals(payload, _get(event, 'value', null));
	}

	_handleSelectMarket(target, event) {
		const { hotbarData, selectedPerson } = this.props;

		// set market to store
		store.dispatch(actions.setMarket(event));

		// if hotbar data is set, then we can proceed with call
		if (!_isEmpty(hotbarData)) {
			batch(() => {
				store.dispatch(actions.setLoadingReport(true));
				store.dispatch(actions.cleanReportData());
				store.dispatch(actions.setWorkplace(null));
			});

			const payload = {
				...hotbarData,
				...(event && { marketId: event.value }),
			};

			this.handleFetchEmployeeMeals(
				payload,
				_get(selectedPerson, 'value', null)
			);
		}
	}

	_handleSelectWorkplace(target, event) {
		const { hotbarData } = this.props;

		batch(() => {
			// clear any report data due to change
			store.dispatch(actions.cleanReportData());
			// clear selectedPerson and selectedMarket because workplace view is different
			store.dispatch(actions.setEmployee(null));
			store.dispatch(actions.setMarket(null));
			// set workplace to store
			store.dispatch(actions.setWorkplace(event));
		});

		// if hotbar data is set, then we can proceed with call
		if (!_isEmpty(hotbarData)) {
			store.dispatch(actions.setLoadingReport(true));

			if (event) {
				const workplace = {
					id: event.value,
					marketId: event.marketId,
				};

				this.handleFetchEmployeeMealsPrWorkplace(hotbarData, workplace);
			} else this.handleFetchEmployeeMeals(hotbarData);
		}
	}

	onHotbarChange(event) {
		this.setState(() => ({ hotBarEvent: event }));
		const { selectedPerson, selectedWorkplace, selectedMarket } = this.props;

		batch(() => {
			// Initiate loading and reset report
			store.dispatch(actions.cleanData());
			store.dispatch(actions.setLoadingReport(true));
		});

		const endDate = event.results.Period.getEndDate();
		const startDate = event.results.Period.getStartDate();
		const reportingCurrencyId = _get(
			event,
			`results['Reporting Currency'].results[0].value`,
			null
		);
		const marketId = _get(selectedMarket, 'value', null);
		const showProductsPrShift = 'ALL';

		const hotbarData = {
			endDate,
			startDate,
			reportingCurrencyId,
			marketId,
		};

		batch(() => {
			store.dispatch(actions.showProductsPrShifts(showProductsPrShift));
			store.dispatch(actions.setHotbarData(hotbarData));
		});

		const personId = selectedPerson ? selectedPerson.value : null;

		if (selectedWorkplace) {
			const formattedWorkplace = {
				id: selectedWorkplace.value,
				marketId: selectedWorkplace.marketId,
			};

			return this.handleFetchEmployeeMealsPrWorkplace(
				hotbarData,
				formattedWorkplace
			).then(() => {
				store.dispatch(actions.setLoadingReport(false));
			});
		} else {
			return this.handleFetchEmployeeMeals(hotbarData, personId);
		}
	}

	handleFetchEmployeeMeals(hotbarData, personId) {
		return fetchEmployeeMealsReport(hotbarData, personId).then(() => {
			store.dispatch(actions.setLoadingReport(false));
		});
	}

	handleFetchEmployeeMealsPrWorkplace(hotbarData, workplace) {
		return fetchEmployeeMealsReportPrWorkplace(hotbarData, workplace).then(
			() => {
				store.dispatch(actions.setLoadingReport(false));
			}
		);
	}

	handleFetchEmployeeMealsPrEmployee(marketId, workplaceId, personId) {
		return fetchEmployeeMealsReportPrPerson(
			this.props.hotbarData,
			marketId,
			workplaceId,
			personId
		);
	}

	/**
	 * @function printToPdf
	 * @description Download data as a pdf. Sends all visible markup to pdf service˘
	 */
	printToPdf() {
		this.setState(() => ({
			loadingPdf: true,
		}));
		this.PrintViewService.printToPdf().then(() => {
			this.setState(() => ({
				loadingPdf: false,
			}));
		});
	}

	/**
	 * @function printToCsv
	 * @description Download data as csv
	 */
	printToCsv() {
		this.setState(() => ({
			loadingCsv: true,
		}));
		fetchEmployeeMealsReport(this.props.hotbarData, null, {
			Accept: 'application/csv',
		}).then(() => {
			this.setState(() => ({
				loadingCsv: false,
			}));
		});
	}

	abortCalls() {
		abortEmloyeeMealReport();
	}

	_renderReport() {
		const {
			employeeMealsReport,
			employeeMealsReportPrWorkplace,
			employeeMealsReportPrPerson,
			hotbarData,
			showProductsPrShift,
			selectedPerson,
			selectedMarket,
			selectedWorkplace,
		} = this.props;

		if (
			_isEmpty(employeeMealsReport) &&
			_isEmpty(employeeMealsReportPrWorkplace)
		) {
			const noDataString = this.phrases.NO_DATA_IN_SELECTED_PERIOD;

			const multipleSearchOptionsString = getStringBasedOnSelectedValues([
				...(selectedPerson ? [selectedPerson.label] : []),
				...(selectedMarket ? [selectedMarket.label] : []),
				...(selectedWorkplace ? [selectedWorkplace.label] : []),
			]);

			return _isEmpty(multipleSearchOptionsString) ? (
				<div>{noDataString}</div>
			) : (
				<div>No data for {multipleSearchOptionsString}!</div>
			);
		}

		const period = `${hotbarData.startDate.format(
			constants.shortDate
		)} - ${hotbarData.endDate.format(constants.shortDate)}`;

		// render workplace
		if (
			_isEmpty(employeeMealsReport) &&
			!_isEmpty(employeeMealsReportPrWorkplace)
		) {
			return (
				<EmployeeMealsPrWorkplacesReportWrapper
					totalColSpan={this.totalColSpan}
					showProductsPrShift={showProductsPrShift}
					employeeMealsReportPrWorkplace={employeeMealsReportPrWorkplace}
					employeeMealsReportPrPerson={employeeMealsReportPrPerson}
					fetchEmployeeMealsPrEmployee={this.handleFetchEmployeeMealsPrEmployee}
					marketId={selectedWorkplace.marketId}
					period={period}
				/>
			);
		}
		// render normal report
		else {
			return Object.keys(employeeMealsReport).map((marketName) => {
				return (
					<EmployeeMealsMarketsReport
						key={marketName}
						totalColSpan={this.totalColSpan}
						marketName={marketName}
						reportData={employeeMealsReport[marketName]}
						hotbarData={hotbarData}
						showProductsPrShift={showProductsPrShift}
						employeeMealsReportPrWorkplace={employeeMealsReportPrWorkplace}
						employeeMealsReportPrPerson={employeeMealsReportPrPerson}
						fetchEmployeeMealsReportPrWorkplace={
							this.handleFetchEmployeeMealsPrWorkplace
						}
						fetchEmployeeMealsPrEmployee={
							this.handleFetchEmployeeMealsPrEmployee
						}
						selectedPerson={selectedPerson}
						period={period}
					/>
				);
			});
		}
	}

	render() {
		const {
			loadingReport,
			usersMarketId,
			selectedPerson,
			selectedMarket,
			selectedWorkplace,
		} = this.props;

		return (
			<div className="employee-meals-report">
				<div className="employee-meals-report__header">
					<Hotdamnbar
						onChange={this.onHotbarChange}
						hotbar={EmployeeMealsReportContainerHotbar}
						onFocusInitialFocus={this.abortCalls}
						onBlurCallback>
						<CollectionSelect
							value={selectedMarket}
							id="employeeMealsReportMarket"
							handleChange={this._handleSelectMarket}
							placeholder="Search for a market"
							clearable
							apiPath="/organization/markets"
							params={{
								limit: 50,
							}}
							optionFormat={(entry) => ({
								value: entry.id,
								label: entry.name,
							})}
							inputFilterFormat={(input) =>
								`:name=like='%${input}%',:id=like='%${input}%'`
							}
							styleType={collectionSelectEnums.TYPE_HOTDAMNBAR}
						/>
						<CollectionSelect
							value={selectedWorkplace}
							id="employeeMealsReportWorkplace"
							handleChange={this._handleSelectWorkplace}
							placeholder="Search for a workplace"
							clearable
							apiPath="/organization/workplaces"
							params={{
								limit: 50,
							}}
							optionFormat={(entry) => ({
								value: entry.id,
								label: entry.name,
								marketId: entry.market.id,
							})}
							inputFilterFormat={(input) =>
								`:id=like='%${input}%',:name=like='%${input}%'`
							}
							styleType={collectionSelectEnums.TYPE_HOTDAMNBAR}
						/>
						{usersMarketId && (
							<CollectionSelect
								value={selectedPerson}
								name="emlpoyee"
								id="employee-select-meals"
								handleChange={this._handleSelectEmployee}
								placeholder="Find an employee"
								clearable
								apiPath={`/organization/markets/${usersMarketId}/contacts`}
								params={{
									limit: 50,
								}}
								optionFormat={(entry) => ({
									value: entry.person.id,
									label: entry.person.identity.full_name,
								})}
								inputFilterFormat={(input) =>
									`:person.identity.full_name=like='%${input}%',:person.id=like='%${input}%'`
								}
								styleType={collectionSelectEnums.TYPE_HOTDAMNBAR}
							/>
						)}
					</Hotdamnbar>

					<div>
						<Button
							type="inverted"
							shadow
							label="CSV"
							onClick={this.printToCsv}>
							{this.state.loadingCsv ? (
								<ButtonLoader loading={this.state.loadingCsv} theme="dark" />
							) : (
								<span className="icon icon--file_download" />
							)}
						</Button>

						<Button
							type="inverted"
							shadow
							label="PDF"
							onClick={this.printToPdf}>
							{this.state.loadingPdf ? (
								<ButtonLoader loading={this.state.loadingPdf} theme="dark" />
							) : (
								<span className="icon icon--file_download" />
							)}
						</Button>
					</div>
				</div>

				{loadingReport ? (
					<div className="employee-meals-report__loader">
						<Loader size="large" logo>
							Loading
						</Loader>
					</div>
				) : (
					<div id={this.printId}>{this._renderReport()}</div>
				)}
			</div>
		);
	}
}

EmployeeMealsReportContainer.propTypes = {
	employeeMealsReport: PropTypes.object,
	hotbarData: PropTypes.object,
	loadingReport: PropTypes.bool,
	showProductsPrShift: PropTypes.string,
	employeeMealsReportPrWorkplace: PropTypes.array,
	employeeMealsReportPrPerson: PropTypes.array,
	selectedPerson: PropTypes.object,
	selectedMarket: PropTypes.object,
	selectedWorkplace: PropTypes.object,
	usersMarketId: PropTypes.number,
};

const mapStateToPropsFactory = (store) => {
	return {
		employeeMealsReport: store.employeeMealsReport.data.employeeMealsReport,
		employeeMealsReportPrWorkplace:
			store.employeeMealsReport.data.employeeMealsReportPrWorkplace,
		employeeMealsReportPrPerson:
			store.employeeMealsReport.data.employeeMealsReportPrPerson,
		hotbarData: store.employeeMealsReport.data.hotbarData,
		loadingReport: store.employeeMealsReport.ui.loadingReport,
		showProductsPrShift: store.employeeMealsReport.ui.showProductsPrShift,
		selectedPerson: store.employeeMealsReport.ui.selectedPerson,
		selectedMarket: store.employeeMealsReport.ui.selectedMarket,
		selectedWorkplace: store.employeeMealsReport.ui.selectedWorkplace,
		usersMarketId: _get(
			store,
			'userData.user.responsibilities.moneyballRoles[0].location.id',
			null
		),
	};
};

export default connectWithStore(
	EmployeeMealsReportContainer,
	mapStateToPropsFactory
);
