import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './ingredients.phrases';

/**
 * @function fetchDiscountIngredients
 * @description Fetch all discounts ingredient
 * @param {object} state
 */
export function fetchDiscountIngredients(discountId, state) {
	const preFilter = `:discount.id=='${discountId}'`;

	const filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter
	};

	return get(`/sale/discount_ingredients`, params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchIngredients
 * @description Fetch all ingredients
 * @param {object} state
 */
export function fetchIngredients(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get(`/product/ingredients`, params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addDiscountIngredient
 * @description add discount ingredient
 */
export function addDiscountIngredient(data) {
	return post(`/sale/discount_ingredients`, data)
		.then(res => {
			setFeedback(phrases.DISCOUNT_INGREDIENTS_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteDiscountIngredient
 * @description delete discount ingredient
 */
export function deleteDiscountIngredient(productSetId) {
	return remove(`/sale/discount_ingredients/${productSetId}`)
		.then(res => {
			setFeedback(phrases.DISCOUNT_INGREDIENT_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
