import {
	UPDATE_WAITING_TIME_PERFORMANCE_GRIDS,
	SET_WAITING_TIME_PERFORMANCE_GRIDS,
	RESET_STATE,
	ADD_BATCH_LIST,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	ADD_BATCH_LIST_ITEM_ROW,
} from './waitingTimePerformanceGrids.actions';

// utils
import _uniqueId from 'lodash/uniqueId';

const defaultState = {
	data: {
		waitingTimePerformanceGrids: {},
		defaultWaitingTimePerformanceGrids: {},
		batchList: [
			{
				identifier: _uniqueId('row-'),
			},
		],
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_WAITING_TIME_PERFORMANCE_GRIDS: {
			return {
				data: {
					...state.data,
					waitingTimePerformanceGrids: payload,
					defaultWaitingTimePerformanceGrids: payload,
				},
			};
		}

		case UPDATE_WAITING_TIME_PERFORMANCE_GRIDS: {
			return {
				data: {
					...state.data,
					waitingTimePerformanceGrids: {
						...state.data.waitingTimePerformanceGrids,
						...payload,
					},
				},
			};
		}

		case RESET_STATE: {
			return {
				...defaultState,
			};
		}

		case ADD_BATCH_LIST: {
			return {
				...state,
				data: {
					...state.data,
					batchList: payload,
				},
			};
		}

		case EDIT_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchList: state.data.batchList.map((entry) => {
						if (entry.identifier === payload.identifier) {
							return { ...entry, ...payload };
						}
						return entry;
					}),
				},
			};
		}

		case REMOVE_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchList: state.data.batchList.filter(
						(entry) => entry.identifier !== payload
					),
				},
			};
		}

		case ADD_BATCH_LIST_ITEM_ROW: {
			return {
				...state,
				data: {
					...state.data,
					batchList: [
						...state.data.batchList,
						{
							...defaultState.data.batchList[0],
							identifier: _uniqueId('row-'),
						},
					],
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
