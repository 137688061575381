import {
	UPDATE_INGREDIENT_CATEGORIES,
	SET_INGREDIENT_CATEGORIES,
	RESET_INGREDIENT_CATEGORIES
} from './ingredientCategories.actions';

const defaultState = {
	ui: {},
	data: {
		ingredientCatEntry: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_INGREDIENT_CATEGORIES: {
		return {
			...state,
			data: {
				...state.data,
				ingredientCatEntry: payload
			}
		};
	}

	case UPDATE_INGREDIENT_CATEGORIES: {
		return {
			...state,
			data: {
				...state.data,
				ingredientCatEntry: {
					...state.data.ingredientCatEntry,
					...payload
				}
			}
		};
	}

	case RESET_INGREDIENT_CATEGORIES: {
		return {
			...state,
			data: {
				...state.data,
				ingredientCatEntry: {}
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
