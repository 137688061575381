import {
	SET_METADATA,
	SET_MAPPED_DATA,
	SET_TOTAL_VALUES,
	SET_ADJUST_MODAL_DATA,
	UPDATE_ADJUST_MODAL_DATA,
	CLEAR_MODAL_DATA,
	UPDATE_CELL_VALUE,
	RESET_TABLE_DATA,
} from './drawerToBankMatrix.actions';

import enums from './../drawerToBankMatrix.enums';

const defaultState = {
	metadata: {},
	mappedData: [],
	totalValues: [],
	adjustModalData: {},
	signModalData: {},
	depositType: {
		value: enums.RECEIVED,
		label: enums.RECEIVED,
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_METADATA:
			return {
				...state,
				metadata: payload,
			};

		case SET_MAPPED_DATA:
			return {
				...state,
				mappedData: payload,
			};

		case SET_TOTAL_VALUES:
			return {
				...state,
				totalValues: payload,
			};

		case SET_ADJUST_MODAL_DATA:
			return {
				...state,
				adjustModalData: payload,
			};

		case UPDATE_ADJUST_MODAL_DATA:
			return {
				...state,
				adjustModalData: {
					...state.adjustModalData,
					posSessionDeposits: state.adjustModalData.posSessionDeposits.map(
						(entry) => {
							if (entry.id === payload.id)
								return {
									...entry,
									...payload,
								};

							return entry;
						}
					),
				},
			};

		case CLEAR_MODAL_DATA:
			return {
				...state,
				adjustModalData: defaultState.adjustModalData,
			};

		case UPDATE_CELL_VALUE: {
			return {
				...state,
				mappedData: state.mappedData.map((entry) => {
					if (entry.workplace?.id === payload.workplace?.id) {
						return payload;
					}

					return entry;
				}),
			};
		}

		case RESET_TABLE_DATA:
			return {
				...state,
				metadata: defaultState.metadata,
				mappedData: defaultState.mappedData,
				totalValues: defaultState.totalValues,
			};

		default:
			return state;
	}
}

export default reducer;
