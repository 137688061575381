import {
	RESET_EMPLOYMENT_POSITIONS,
	SET_EMPLOYMENT_POSITIONS,
	UPDATE_EMPLOYMENT_POSITIONS,
	SET_EMPLOYMENT,
} from './employmentPositionList.actions';

const defaultState = {
	data: {
		rowEntry: {},
	},
	employment: null,
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_EMPLOYMENT_POSITIONS: {
			return {
				...state,
				data: {
					...state.data,
					rowEntry: payload,
				},
			};
		}
		case SET_EMPLOYMENT: {
			return {
				...state,
				employment: payload,
			};
		}

		case UPDATE_EMPLOYMENT_POSITIONS: {
			return {
				...state,
				data: {
					...state.data,
					rowEntry: {
						...state.data.rowEntry,
						...payload,
					},
				},
			};
		}

		case RESET_EMPLOYMENT_POSITIONS: {
			return {
				...state,
				data: {
					...state.data,
					rowEntry: {},
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
