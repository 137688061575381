'use strict';

export const SET_FILE_LIST = 'FILE_LIST/SET_FILE_LIST';
export const SET_LOADING = 'FILE_LIST/SET_LOADING';

/**
 * @param {string} fileListName
 * @param {object} fileList
 */
export function setFileList(fileListName, fileList) {
	return {
		type: SET_FILE_LIST,
		payload: {
			fileListName,
			fileList
		}
	};
}

/**
 * @param {string} fileListName
 * @param {bool} loading
 */
export function setLoading(fileListName, loading) {
	return {
		type: SET_LOADING,
		payload: {
			fileListName,
			loading
		}
	};
}