import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

// phrases
import phrases from './ingredientAllergens.phrases';

function getUrl(useGlobalResource) {
	return useGlobalResource ? '/product/global_ingredient_allergens' : '/product/ingredient_allergens';
}

/**
 * @function fetchIngredientAllergenes
 * @description Fetch all ingredient allergenes values
 * @param {object} state
 */
export function fetchIngredientAllergenes(state, useGlobalResource) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	const url = getUrl(useGlobalResource);

	return get(url, params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addIngredientAllergenes
 * @description add ingredient allergen
 */
export function addIngredientAllergenes(data, useGlobalResource) {
	const url = getUrl(useGlobalResource);

	return post(url, data)
		.then((res) => {
			setFeedback(phrases.INGREDIENT_ALLERGENS_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editIngredientAllergen
 * @description edit ingredient allergen
 */
export function editIngredientAllergen(id, data, useGlobalResource) {
	const url = getUrl(useGlobalResource);

	return post(`${url}/${id}`, data)
		.then((res) => {
			setFeedback(phrases.INGREDIENT_ALLERGEN_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editIngredientAllergens
 * @description edit ingredient allergens
 */
export function editIngredientAllergens(data, useGlobalResource) {
	const url = getUrl(useGlobalResource);

	return patch(url, data)
		.then((res) => {
			setFeedback(phrases.INGREDIENT_ALLERGENS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteIngredientAllergenes
 * @description delete ingredient allergen
 */
export function deleteIngredientAllergenes(nutritionId, useGlobalResource) {
	const url = getUrl(useGlobalResource);

	return remove(`${url}/${nutritionId}`)
		.then((res) => {
			setFeedback(phrases.INGREDIENT_ALLERGENS_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
