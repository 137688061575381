import React, { useRef, useState, useEffect } from 'react';

// interfaces
import { IMarketTagsCRUD, IMarketTagsReduxValueBatch } from '../../types';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import {
	resetState,
	rerenderTable,
	setBatchFormData,
} from '../../store/marketTags.actions';

// components
import { ModalStepper } from 'dumb';
import MarketTagsModalStep from './components/marketTagsModalStep';
import MarketTagsModalForm from './components/marketTagsModalForm';

// services
import { addMarketTags } from '../../marketTags.service';

// phrases/ enums
import phrases from '../../marketTags.phrases';
import _uniqueId from 'lodash/uniqueId';

type Props = {
	onClose: () => void;
	source: 'market' | 'tag';
	marketId?: number;
	tagId?: number;
};

const MarketTagsModalBatch = ({
	onClose,
	source,
	marketId,
	tagId,
}: Props): JSX.Element => {
	const modalRef = useRef<ModalStepper>(null);
	const [loading, setLoading] = useState(false);
	const dispatch = useAppDispatch();
	const { batchForm, batchList } = useAppSelector((store) => ({
		batchList: store.marketTags.batchList,
		batchForm: store.marketTags.batchForm,
	}));

	const changeStep = (step: number) => () => {
		if (!modalRef.current) return;

		modalRef.current.goToStep(step);
	};

	const onBatchStepNext = () => {
		let arrayToSubmit: IMarketTagsReduxValueBatch[] = [];
		if (source === 'market' && batchForm.tags)
			arrayToSubmit = batchForm.tags.map((tag) => ({
				identifier: _uniqueId('row-'),
				tag,
				market: null,
				defaultLengthInMinutes: batchForm.defaultLengthInMinutes,
				minutesFromStartOfShift: batchForm.minutesFromStartOfShift,
				minutesFromEndOfShift: batchForm.minutesFromEndOfShift,
			}));
		if (source === 'tag' && batchForm.markets)
			arrayToSubmit = batchForm.markets.map((market) => ({
				identifier: _uniqueId('row-'),
				market,
				tag: null,
				defaultLengthInMinutes: batchForm.defaultLengthInMinutes,
				minutesFromStartOfShift: batchForm.minutesFromStartOfShift,
				minutesFromEndOfShift: batchForm.minutesFromEndOfShift,
			}));

		dispatch(setBatchFormData(arrayToSubmit));

		changeStep(2)();
	};

	const onSubmit = () => {
		setLoading(true);

		const payload: IMarketTagsCRUD[] = batchList.map((entry) => ({
			...(source === 'tag'
				? {
						tag: tagId,
						market: entry.market?.value?.id,
				  }
				: {
						market: marketId,
						tag: entry.tag?.value?.id,
				  }),
			default_length_in_minutes: entry.defaultLengthInMinutes,
			minutes_from_start_of_shift: entry.minutesFromStartOfShift,
			minutes_from_end_of_shift: entry.minutesFromEndOfShift,
		}));

		addMarketTags({ batch: payload })
			.then(() => {
				setLoading(false);
				dispatch(rerenderTable());
				onClose();
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const getIsStepValid = () => {
		if (batchForm.markets || batchForm.tags) return true;
		return false;
	};

	const steps = [
		{
			component: <MarketTagsModalForm source={source} />,
			title: phrases.MODAL_TITLE,
			onBack: onClose,
			onNext: onBatchStepNext,
			loading,
			isValid: getIsStepValid(),
			confirmButtonLabel: phrases.NEXT,
			noScroll: false,
		},
		{
			component: <MarketTagsModalStep source={source} />,
			title: phrases.MODAL_TITLE,
			onBack: changeStep(1),
			onNext: onSubmit,
			loading,
			isValid: true,
			confirmButtonLabel: phrases.SUBMIT,
			cancelButtonLabel: phrases.BACK,
			defaultStyles: false,
			noScroll: false,
		},
	];

	useEffect(() => {
		return () => {
			dispatch(resetState());
		};
	}, [dispatch]);

	return (
		<ModalStepper
			header={phrases.MODAL_TITLE}
			onClose={onClose}
			ref={modalRef}
			isOpen
			steps={steps}
			showStep={false}
		/>
	);
};

export default MarketTagsModalBatch;
