import PropTypes from 'prop-types';
import React from 'react';

import FileType from './subcomponents/fileType.component';
import FileTypePicker from './subcomponents/fileTypePicker.component';

const FileUpload = props => {
	const {
		defaultFileNameData,
		error,
		fileUpload,
		fileUploadState,
		methods,
		phrases,
		sending,
		children
	} = props;

	// Get file types
	const getFileTypes = () =>
		Object.keys(fileUploadState.fileTypes).map((key, index) => {
			const fileType = fileUploadState.fileTypes[key];
			return (
				<FileType
					key={index + fileType.getName() + fileType.getNumber()}
					{...{
						defaultFileNameData,
						fileUpload,
						fileUploadState,
						fileType,
						phrases,
						sending
					}}
				/>
			);
		});

	// Get upload title
	const fileUploadTitle =
		fileUpload.getFileTypesLength() < 2
			? phrases.TITLE_SINGLE
			: phrases.TITLE_MULTI;

	return (
		<div className="file-upload">
			<h3>{fileUploadTitle}</h3>

			{error && (
				<p className="file-upload__error">
					<strong>{phrases.ERROR}</strong>
					{typeof error !== 'boolean' && typeof error !== 'object' ? (
						<span>{error}</span>
					) : null}
				</p>
			)}

			<div className="file-upload__file-types">
				{getFileTypes()}

				<FileTypePicker
					{...{
						fileUpload,
						fileUploadState,
						phrases
					}}
				/>
			</div>

			<div className="file-upload__footer">
				{/* @TODO: use standard button, form lib */}
				<button
					disabled={fileUploadState.fileCounts === 0 || sending}
					onClick={methods.handleSendResultSet}
					className="button file-upload__upload-button">
					{fileUploadTitle}
				</button>

				{/* Additional settings, inputs may pass here */}
				{children && (
					<div className="file-upload__footer-child">{children}</div>
				)}
			</div>
		</div>
	);
};

FileUpload.defaultProps = {
	fileUpload: {},
	fileUploadState: {
		fileTypes: {},
		fileTypeTemplates: {},
		fileCounts: {}
	}
};

FileUpload.propTypes = {
	fileUpload: PropTypes.object.isRequired,
	fileUploadState: PropTypes.object,
	methods: PropTypes.object.isRequired
};

export default FileUpload;
