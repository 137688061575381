import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ListComponent from 'common/components/list/list.component';

import TurnoverAchievementsRulesList from './services/turnoverAchievementsRulesList.service';

const turnoverAchievementsRulesListReduxKey = '/reporting/turnover_achievements_rules';
const turnoverAchievementsRulesList = new TurnoverAchievementsRulesList(turnoverAchievementsRulesListReduxKey);
const turnoverAchievementsRulesListProps = turnoverAchievementsRulesList.getProps();

export function TurnoverAchievementsDetailsContainer() {
	const { turnoverAchievementId } = useParams();

	const turnoverAchievementIdFilter = `:turnover_achievement.id=='${turnoverAchievementId}'`;

	useEffect(() => {
		turnoverAchievementsRulesList.setFilterQuery(turnoverAchievementIdFilter);
		turnoverAchievementsRulesList.setData({
			turnoverAchievement: turnoverAchievementId,
		});
	}, [turnoverAchievementId, turnoverAchievementIdFilter]);

	return <ListComponent reduxKey={turnoverAchievementsRulesListReduxKey} {...turnoverAchievementsRulesListProps} />;
}
