'use strict';

import {
	RESET_PRODUCT_VARIANT_INGREDIENT,
	SET_PRODUCT_VARIANT_INGREDIENT,
	UPDATE_PRODUCT_VARIANT_INGREDIENT
} from './productVariantIngredients.actions';

const defaultState = {
	data: {
		ingredientData: {
			default: 1,
		}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_PRODUCT_VARIANT_INGREDIENT: {
		return {
			...state,
			data: {
				...state.data,
				ingredientData: payload
			}
		};
	}

	case UPDATE_PRODUCT_VARIANT_INGREDIENT: {
		return {
			...state,
			data: {
				...state.data,
				ingredientData: {
					...state.data.ingredientData,
					...payload
				}
			}
		};
	}

	case RESET_PRODUCT_VARIANT_INGREDIENT: {
		return {
			...state,
			data: {
				...state.data,
				ingredientData: {}
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
