'use strict';

import { HotbarComponentFactory as h } from 'hotdamnbar/factory';
import {
	HotbarEndpointDataProvider,
	HotbarStaticDataProvider
} from 'hotdamnbar/dataProviders';

const MarketsDataProvider = new HotbarEndpointDataProvider(
	'/organization/markets'
);

MarketsDataProvider.setSortOrder(':sort_order+');

const AuditTypeDataProvider = new HotbarStaticDataProvider([
	{
		value: 'Operation',
		label: 'Forecast'
	},
	{
		value: 'SOM',
		label: 'SOM'
	},
	{
		value: 'SOY',
		label: 'SOY'
	}
]);

const GoldModelHotbar = h.hotbar('goldModelHotbar');

GoldModelHotbar.add(h.select('Markets', true, MarketsDataProvider, false));
GoldModelHotbar.add(h.dateRange('Period', true, true));
GoldModelHotbar.add(h.select('View', true, AuditTypeDataProvider));

export default GoldModelHotbar;
