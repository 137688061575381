import React, { useRef, useState, useEffect } from 'react';

// interfaces
import { IMarketResponsibilityCrud } from '../../types';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import {
	resetMarketResponsiblity,
	rerenderTable,
} from './../../store/marketResponsibilities.actions';

// components
import { ModalStepper } from 'dumb';
import MarketResponsibilitiesModalStep from './components/marketResponsibilitiesModalStep';

// services
import { addMarketResponsibilities } from './../../marketResponsibilities.service';

// phrases/ enums
import phrases from './../../marketResponsibilities.phrases';

type Props = {
	onClose: () => void;
	employmentId: number;
};

const MarketResponsibilitiesModalBatch = ({
	onClose,
	employmentId,
}: Props): JSX.Element => {
	const modalRef = useRef() as React.LegacyRef<ModalStepper>;
	const [loading, setLoading] = useState(false);
	const dispatch = useAppDispatch();
	const batchList = useAppSelector(
		(store) => store.marketResponsibilities.batchList
	);

	const onNext = () => {
		setLoading(true);

		const payload: IMarketResponsibilityCrud[] = batchList.map(
			(entry): IMarketResponsibilityCrud => ({
				employment: employmentId,
				role: (entry.role?.value as IAdministrationMarketResponsibilitiesRole)
					.id,
				location: (
					entry.location?.value as IAdministrationMarketResponsibilitiesLocation
				).id,
				period: {
					from: entry.from,
					to: entry.to,
				},
			})
		);

		addMarketResponsibilities(payload)
			.then(() => {
				setLoading(false);
				dispatch(rerenderTable());
				onClose();
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const steps = [
		{
			component: <MarketResponsibilitiesModalStep />,
			title: phrases.MODAL_TITLE,
			onBack: onClose,
			onNext,
			loading,
			isValid: true,
			confirmButtonLabel: phrases.SUBMIT,
			defaultStyles: false,
			noScroll: false,
		},
	];

	useEffect(() => {
		return () => {
			dispatch(resetMarketResponsiblity());
		};
	}, [dispatch]);

	return (
		<ModalStepper
			header={phrases.MODAL_TITLE}
			onClose={onClose}
			ref={modalRef}
			isOpen
			steps={steps}
			showStep={false}
		/>
	);
};

export default MarketResponsibilitiesModalBatch;
