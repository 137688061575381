'use strict';

import prop from 'dot-prop-immutable';

import {
	SET_METADATA,
	SET_TABLES,
	SET_TABLE_CONTENT,
	SET_TABLE_EXPANDED,
	SET_ALL_TABLES_EXPANDED,
	RESET_REPORT
} from './report.actions';

const defaultState = {
	reports: {}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
		case RESET_REPORT:
			return prop.set(state, `reports.${payload.reportName}`, {});
		case SET_METADATA:
			return prop.set(state, `reports.${payload.reportName}.metadata`, payload.metadata);
		case SET_TABLES:
			return prop.set(state, `reports.${payload.reportName}.tables`, payload.tables);
		case SET_TABLE_CONTENT:
			return prop.set(state, `reports.${payload.reportName}.tables.entities.${payload.tableName}.content`, payload.content);
		case SET_TABLE_EXPANDED:
			return prop.set(state, `reports.${payload.reportName}.expandedTables.${payload.tableName}`, payload.expanded);
		case SET_ALL_TABLES_EXPANDED:
			return prop.set(state, `reports.${payload.reportName}.expandedTables`, payload.tableList);
		default:
			return state;
	}
}

export default reducer;