export const RESET_STATE =
	'GLOBAL_ADMINISTRATION_WAITING_TIME_PERFORMANCE_GRID_ENTRIES/RESET_STATE';
export const SET_WAITING_TIME_PERFORMANCE_GRID_ENTRIES =
	'GLOBAL_ADMINISTRATION_WAITING_TIME_PERFORMANCE_GRID_ENTRIES/SET_WAITING_TIME_PERFORMANCE_GRID_ENTRIES';
export const UPDATE_WAITING_TIME_PERFORMANCE_GRID_ENTRIES =
	'GLOBAL_ADMINISTRATION_WAITING_TIME_PERFORMANCE_GRID_ENTRIES/UPDATE_WAITING_TIME_PERFORMANCE_GRID_ENTRIES';

export const EDIT_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_WAITING_TIME_PERFORMANCE_GRID_ENTRIES/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_WAITING_TIME_PERFORMANCE_GRID_ENTRIES/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'GLOBAL_ADMINISTRATION_WAITING_TIME_PERFORMANCE_GRID_ENTRIES/ADD_BATCH_LIST_ITEM_ROW';

export function setWaitingTimePerformanceGridEntries(payload) {
	return {
		type: SET_WAITING_TIME_PERFORMANCE_GRID_ENTRIES,
		payload,
	};
}

export function updateWaitingTimePerformanceGridEntries(payload) {
	return {
		type: UPDATE_WAITING_TIME_PERFORMANCE_GRID_ENTRIES,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}
