'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';

// batch view
import ProductTranslationsBatchStepList from './components/productTranslationsBatchStepList';
import ProductTranslationsBatchStepForm from './components/productTranslationsBatchStepForm';

// lodash
import _isEmpty from 'lodash/isEmpty';
import _uniqueId from 'lodash/uniqueId';

// phrases
import phrases from '../../productTranslations.phrases';

class ProductTranslationsModalBatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};

		this.modalRef = React.createRef();

		this._onSubmitForm = this._onSubmitForm.bind(this);
		this._batchSubmit = this._batchSubmit.bind(this);
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_onSubmitForm() {
		const { batchForm, addBatchList } = this.props;

		const data = batchForm.languages.map((entry) => {
			return {
				identifier: _uniqueId('row-'),
				language: entry,
			};
		});

		addBatchList(data);

		this._goToStep(2);
	}

	_batchSubmit() {
		const {
			batchList,
			handleClose,
			addProductTranslations,
			product,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchList.map((entry) => {
			return {
				product: product.id,
				language: entry.language?.value?.id,
				...(entry.name && {
					name: entry.name,
				}),
				...(entry.description && {
					description: entry.description,
				}),
			};
		});

		addProductTranslations({ batch: payload })
			.then(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
				handleClose();
			})
			.catch(() =>
				this.setState(() => ({
					loadingModal: false,
				}))
			);
	}

	render() {
		const {
			modalVisible,
			handleClose,
			batchList,
			removeBatchListItem,
			batchForm,
			updateBatchForm,
			editBatchListItem,
		} = this.props;

		const steps = [
			{
				component: (
					<ProductTranslationsBatchStepForm
						batchForm={batchForm}
						updateBatchForm={updateBatchForm}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE,
				isValid: !_isEmpty(batchForm.languages),
				onNext: this._onSubmitForm,
				loading: false,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
			},
			{
				component: (
					<ProductTranslationsBatchStepList
						batchList={batchList}
						removeBatchListItem={removeBatchListItem}
						editBatchListItem={editBatchListItem}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: this._batchSubmit,
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return modalVisible ? (
			<ModalStepper
				isOpen={modalVisible}
				steps={steps}
				showStep={false}
				onClose={handleClose}
				ref={this.modalRef}
			/>
		) : null;
	}
}

ProductTranslationsModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	batchList: PropTypes.array,
	batchForm: PropTypes.object,
	removeBatchListItem: PropTypes.func,
	addProductTranslations: PropTypes.func,
	updateBatchForm: PropTypes.func,
	addBatchList: PropTypes.func,
	editBatchListItem: PropTypes.func,
	product: PropTypes.object,
};

export default ProductTranslationsModalBatch;
