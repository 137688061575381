import { ActionType } from 'types/redux';
import { ICurrencyDenominationReduxValue } from '../types';

export const SET_CURRENCY_DENOMINATION =
	'CURRENCY_DENOMINATIONS/SET_CURRENCY_DENOMINATION';
export const UPDATE_CURRENCY_DENOMINATION =
	'CURRENCY_DENOMINATIONS/UPDATE_CURRENCY_DENOMINATION';
export const RESET_STATE = 'CURRENCY_DENOMINATIONS/RESET_STATE';

export function setCurrencyDenomination(
	payload: ICurrencyDenominationReduxValue
): ActionType {
	return {
		type: SET_CURRENCY_DENOMINATION,
		payload,
	};
}

type updateCurrencyDenomination = Partial<ICurrencyDenominationReduxValue>;
export function updateCurrencyDenomination(
	payload: updateCurrencyDenomination
): ActionType {
	return {
		type: UPDATE_CURRENCY_DENOMINATION,
		payload,
	};
}

export function resetState(): ActionType {
	return {
		type: RESET_STATE,
	};
}
