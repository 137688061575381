import React, { Component } from 'react';

import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import { Body, Head, Row, Table, HeaderCell, TableCell } from 'dumb/report';

import constants from 'dumb/report/constants';

import PropTypes from 'prop-types';

class EmployeeMealsPrPerson extends Component {
	constructor(props) {
		super(props);

		this._renderRows = this._renderRows.bind(this);
		this._getTotalGrossTurnover = this._getTotalGrossTurnover.bind(this);
		this._getTotalProductCount = this._getTotalProductCount.bind(this);
	}

	_renderRows() {
		return this.props.reportData.map((entry, index) => {
			const name =
				entry?.product_variant?.name || entry?.ingredient?.name || 'Unknown';

			return (
				<Row
					key={entry.id}
					index={index}
					type="entry"
					totalColSpan={this.props.totalColSpan}>
					<TableCell colSpan="3" content={name} alignLeft />

					<TableCell
						cellType={constants.CELL_TYPES.INTEGER}
						colSpan="2"
						separate
						content={_get(entry, 'gross_turnover', null)}
						alignRight
					/>

					<TableCell
						cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
						colSpan="2"
						separate
						content={_get(entry, 'employee_meal.hours', null)}
						alignRight
					/>
					<TableCell
						cellType={constants.CELL_TYPES.INTEGER}
						colSpan="2"
						separate
						content={_get(entry, 'products', null)}
						alignRight
					/>
					<TableCell
						cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
						colSpan="2"
						separate
						content={_get(entry, 'employee_meal.products_per_shifts', null)}
						alignRight
					/>
					<TableCell
						cellType={constants.CELL_TYPES.INTEGER}
						colSpan="2"
						separate
						content={_get(entry, 'employee_meal.shifts', null)}
						alignRight
					/>
				</Row>
			);
		});
	}

	// @TODO: backend should give this;
	_getTotalGrossTurnover(data) {
		return data.reduce((sum, x) => sum + x.gross_turnover, 0);
	}

	_getTotalProductCount(data) {
		return data.reduce((sum, x) => sum + x.products, 0);
	}

	render() {
		return !_isEmpty(this.props.reportData) ? (
			<div className="employee-meals-report__person">
				<Table marginBottom fixed>
					<Head subheader>
						<Row>
							<HeaderCell colSpan="3" content="Product" alignLeft />
							<HeaderCell colSpan="2" content="Gross turnover" separate />
							<HeaderCell colSpan="2" content="" separate />
							<HeaderCell colSpan="2" content="#Products" separate />
							<HeaderCell colSpan="2" content="" separate />
							<HeaderCell colSpan="2" content="" separate />
						</Row>
					</Head>
					<Body>
						{this._renderRows()}

						{/* @TODO: remove after testing if sums are correct */}
						{/* <Row type="total">
							<TableCell colSpan="3" content={'Total'} alignLeft />
							<TableCell
								colSpan="2"
								content={this._getTotalGrossTurnover(this.props.reportData)}
								separate
								alignRight
							/>
							<TableCell colSpan="2" content={''} separate />
							<TableCell
								colSpan="2"
								content={this._getTotalProductCount(this.props.reportData)}
								separate
								alignRight
							/>
							<TableCell colSpan="2" content={''} separate />
							<TableCell colSpan="2" content={''} separate />
						</Row> */}
					</Body>
				</Table>
			</div>
		) : (
			<div style={{ margin: '10px' }}>No data registered</div>
		);
	}
}

EmployeeMealsPrPerson.propTypes = {
	reportData: PropTypes.array,
	totalColSpan: PropTypes.number,
};

EmployeeMealsPrPerson.defaultProps = {
	reportData: {},
};

export default EmployeeMealsPrPerson;
