import constants from 'dumb/report/constants';

import _get from 'lodash/get';

const tableEntries = {
	WorkplaceEntry: 'DailyReportWorkplaceEntry',
	WorkplaceGroupEntry: 'DailyReportWorkplaceGroupEntry',
	TerritoryEntry: 'DailyReportTerritoryEntry',
	TerritoryGroupEntry: 'DailyReportTerritoryGroupEntry',
	TerritoryStoreAreaTypeEntry: 'DailyReportTerritoryStoreAreaTypeEntry', // <---

	MarketEntry: 'DailyReportMarketEntry',
	MarketRegionEntry: 'DailyReportMarketRegionEntry',
	MarketRegionGroupEntry: 'DailyReportMarketRegionGroupEntry', // <---
	MarketRegionStoreAreaTypeEntry: 'DailyReportMarketRegionStoreAreaTypeEntry', // <---
	// MarketRegionStoreAreaTypeStoreLocationTypeEntry: 'DailyReportMarketRegionStoreAreaTypeStoreLocationTypeEntry', // <---

	ContinentEntry: 'DailyReportContinentEntry',
	ContinentGroupEntry: 'DailyReportContinentGroupEntry',
	ContinentStoreAreaTypeEntry: 'DailyReportContinentStoreAreaTypeEntry', // <---

	TotalEntry: 'DailyReportStoreTypeEntry',
	TotalGroupEntry: 'DailyReportStoreTypeGroupEntry',
	TotalStoreTypeStoreAreaTypeEntry: 'DailyReportStoreTypeStoreAreaTypeEntry' // <---
};

export default function dailyReportParser(tables) {
	// split the reports up into the what should be visible when expanded.
	// We call this outer and inner
	const reportsSplit = tables.reduce((acc, entry) => {
		switch (entry.entry_type) {
		// these cases go to the oute
		case tableEntries.TotalEntry: {
			acc = {
				...acc,
				Total: [
					{
						...entry,
						name: 'Total',
						type: constants.TABLE_TYPES.TABLE_TOTAL
					}
				]
			};

			break;
		}

		case tableEntries.TotalGroupEntry: {
			return {
				...acc,
				totalGroups: acc.totalGroups
					? [...acc.totalGroups, { ...entry, name: entry.group }]
					: [{ ...entry, name: entry.group }]
			};
		}

		case tableEntries.TotalStoreTypeStoreAreaTypeEntry: {
			return {
				...acc,
				totalStoreTypeStoreAreaTypeEntry: acc.totalStoreTypeStoreAreaTypeEntry
					? [
						...acc.totalStoreTypeStoreAreaTypeEntry,
						{ ...entry, name: entry.store_area_type.name }
						  ]
					: [{ ...entry, name: entry.store_area_type.name }]
			};
		}

		case tableEntries.ContinentGroupEntry: {
			return {
				...acc,
				continentGroups: acc.continentGroups
					? [
						...acc.continentGroups,
						{
							...entry,
							name: entry.group,
							sortOrder: entry.continent.sort_order
						}
						  ]
					: [
						{
							...entry,
							name: entry.group,
							sortOrder: entry.continent.sort_order
						}
						  ]
			};
		}
		case tableEntries.ContinentStoreAreaTypeEntry: {
			return {
				...acc,
				continentStoreAreaTypeEntry: acc.continentStoreAreaTypeEntry
					? [
						...acc.continentStoreAreaTypeEntry,
						{
							...entry,
							name: entry.store_area_type.name,
							sortOrder: entry.continent.sort_order
						}
						  ]
					: [
						{
							...entry,
							name: entry.store_area_type.name,
							sortOrder: entry.continent.sort_order
						}
						  ]
			};
		}

		case tableEntries.ContinentEntry: {
			return {
				...acc,
				continents: acc.continents
					? [
						...acc.continents,
						{
							...entry,
							name: entry.continent.name,
							sortOrder: entry.continent.sort_order
						}
						  ]
					: [
						{
							...entry,
							name: entry.continent.name,
							sortOrder: entry.continent.sort_order
						}
						  ]
			};
		}

		case tableEntries.MarketRegionEntry: {
			return {
				...acc,
				marketRegions: acc.marketRegions
					? [
						...acc.marketRegions,
						{
							...entry,
							name: entry.market_region.name,
							sortOrder: entry.market_region.sort_order
						}
						  ]
					: [
						{
							...entry,
							name: entry.market_region.name,
							sortOrder: entry.market_region.sort_order
						}
						  ]
			};
		}
		case tableEntries.MarketRegionGroupEntry: {
			return {
				...acc,
				marketRegionGroupEntries: acc.marketRegionGroupEntries
					? [
						...acc.marketRegionGroupEntries,
						{
							...entry,
							name: entry.group,
							sortOrder: entry.market_region.sort_order
						}
						  ]
					: [
						{
							...entry,
							name: entry.group,
							sortOrder: entry.market_region.sort_order
						}
						  ]
			};
		}
		case tableEntries.MarketRegionStoreAreaTypeEntry: {
			return {
				...acc,
				marketRegionStoreAreaTypeEntries: acc.marketRegionStoreAreaTypeEntries
					? [
						...acc.marketRegionStoreAreaTypeEntries,
						{
							...entry,
							name: entry.store_area_type.name,
							sortOrder: entry.market_region.sort_order
						}
						  ]
					: [
						{
							...entry,
							name: entry.store_area_type.name,
							sortOrder: entry.market_region.sort_order
						}
						  ]
			};
		}
		case tableEntries.MarketRegionStoreAreaTypeStoreLocationTypeEntry: {
			return {
				...acc,
				marketRegionStoreAreaTypeStoreLocationTypeEntries: acc.marketRegionStoreAreaTypeStoreLocationTypeEntries
					? [
						...acc.marketRegionStoreAreaTypeStoreLocationTypeEntries,
						{
							...entry,
							name: entry.store_area_type.name,
							sortOrder: entry.market_region.sort_order
						}
						  ]
					: [
						{
							...entry,
							name: entry.store_area_type.name,
							sortOrder: entry.market_region.sort_order
						}
						  ]
			};
		}

		case tableEntries.MarketEntry: {
			return {
				...acc,
				markets: acc.markets
					? [
						...acc.markets,
						{
							...entry,
							name: entry.market.name,
							sortOrder: entry.market.sort_order
						}
						  ]
					: [
						{
							...entry,
							name: entry.market.name,
							sortOrder: entry.market.sort_order
						}
						  ]
			};
		}

		case tableEntries.TerritoryEntry: {
			return {
				...acc,
				territories: acc.territories
					? [
						...acc.territories,
						{
							...entry,
							name: _get(entry, 'territory.name', 'Territory name'),
							sortOrder: _get(
								entry,
								'territory.sort_order',
								'Territory sort_order'
							)
						}
						  ]
					: [
						{
							...entry,
							name: _get(entry, 'territory.name', 'Territory name'),
							sortOrder: _get(
								entry,
								'territory.sort_order',
								'Territory sort_order'
							)
						}
						  ]
			};
		}

		case tableEntries.StoreAreaTypeEntry: {
			return {
				...acc,
				storeAreaTypes: acc.storeAreaTypes
					? [
						...acc.storeAreaTypes,
						{
							...entry,
							name: entry.store_area_type.name
						}
						  ]
					: [
						{
							...entry,
							name: entry.store_area_type.name
						}
						  ]
			};
		}
		case tableEntries.TerritoryGroupEntry: {
			return {
				...acc,
				groups: acc.groups
					? [
						...acc.groups,
						{
							...entry,
							name: entry.group,
							sortOrder: entry.group.sort_order
						}
						  ]
					: [
						{
							...entry,
							name: entry.group,
							sortOrder: entry.group.sort_order
						}
						  ]
			};
		}

		case tableEntries.TerritoryStoreAreaTypeEntry: {
			return {
				...acc,
				territoryStoreAreaTypeEntries: acc.territoryStoreAreaTypeEntries
					? [
						...acc.territoryStoreAreaTypeEntries,
						{
							...entry,
							name: entry.store_area_type.name
						}
						  ]
					: [
						{
							...entry,
							name: entry.store_area_type.name
						}
						  ]
			};
		}

		case tableEntries.WorkplaceEntry: {
			return {
				...acc,
				workplaces: acc.workplaces
					? [
						...acc.workplaces,
						{
							...entry,
							name: entry.workplace.name,
							sortOrder: entry.workplace.sort_order
						}
						  ]
					: [
						{
							...entry,
							name: entry.workplace.name,
							sortOrder: entry.workplace.sort_order
						}
						  ]
			};
		}

		default:
			break;
		}

		return acc;
	}, {});

	return {
		...reportsSplit
	};
}
