enum EntryTypeEnum {
	'Turnover' = 'Turnover',
	'Mobile payment' = 'MobilePayment',
	'Card payment' = 'CardPayment',
	'Joe app delivery turnover' = 'JoeAppDeliveryTurnover',
	'Joe app delivery mobile payment' = 'JoeAppDeliveryMobilePayment',
	'Foreign currency' = 'ForeignCurrency',
	'Begin cash' = 'BeginCash',
	'End cash' = 'EndCash',
	'Drawer to bank' = 'DrawerToBank',
	'Difference' = 'Difference',
}

enum TypeEnum {
	ONE = '1',
	TWO = '2',
}

export { EntryTypeEnum, TypeEnum };
