import React from 'react';
import PropTypes from 'prop-types';

// styles
import './buttonNotification.css';

const ButtonNotification = ({ notifications }) => {
	const notificationToShow = notifications > 9 ? '9+' : notifications;

	return <div className="button-notification">{notificationToShow}</div>;
};

ButtonNotification.propTypes = {
	notifications: PropTypes.number,
};

export default ButtonNotification;
