'use strict';

import MetadataTitleAbstract from './metadata.title.abstract';

/**
 * Subtitle
 * @param  {*} template
 */
class Subtitle extends MetadataTitleAbstract  {
	constructor(template) {
		super(template);

		// Set type
		this.type = 'subtitle';
	}
}

export default Subtitle;