import constants from "dumb/report/constants";
import _get from "lodash/get";

const tableEntries = {
	marketGroupEntry: "EmployeeMealsReportMarketEntry",
	workplaceEntry: "EmployeeMealsReportWorkplaceEntry"
};

export default function employeeMealsReportParser(tables) {

	// split the reports up into the what should be visible when expanded.
	// We call this outer and inner
	const reportsSplit = tables.reduce((acc, entry) => {

		switch (entry.entry_type) {

		// these cases go to the oute
		case tableEntries.marketGroupEntry: {

			const marketName = _get(entry, "market.name", null);

			acc = {
				...acc,
				[marketName]: acc[marketName]
					? {
						...acc[marketName],
						total: {
							...entry,
							type: constants.TABLE_TYPES.ENTRY
						}
					} : {
						total: [
							{
								...entry,
								type: constants.TABLE_TYPES.ENTRY
							}
						]
					}
			};

			break;
		}

		// these cases go to the inner
		case tableEntries.workplaceEntry: {

			const marketName = _get(entry, "workplace.market.name", null);

			acc = {
				...acc,
				[marketName]: acc[marketName]
					? {
						...acc[marketName],
						stores: [
							...acc[marketName]["stores"],
							{
								...entry,
								type: constants.TABLE_TYPES.ENTRY
							}
						]

					}
					: {
						stores: [
							{
								...entry,
								type: constants.TABLE_TYPES.ENTRY
							}
						]
					}
			};


			return acc;
		}

		default:
			break;
		}

		return acc;
	}, {});

	// const dailyReportOuter = _mapOuterReport(reportsSplit.dailyReportOuter);
	// const employeeMailsWorkplaceEntries = _mapInnerReport(reportsSplit.employeeMailsWorkplaceEntries);

	return {
		...reportsSplit
	};
}


// The below methods were originally used for mapping the daily report based on what type of row it was.
// Im keeping it here in case we need to redo the daily report at some point
// /**
//  * @function _mapOuterReport
//  * @private
//  * @description We need to add a type to each entry to determine how to show it.
//  * Maps over all and add a type depending on how we are defining it
//  * @param {Array} List of lines that all in all is a report
//  * @returns Array
//  */
// function _mapOuterReport(report) {
// 	return report.reduce((acc, entry) => {
// 		switch (entry.entry_type) {
// 		case tableEntries.tableEntry:
// 			return {
// 				...acc,
// 				"table total": {
// 					...entry,
// 					type: constants.TABLE_TYPES.TABLE_TOTAL
// 				}
// 			};

// 		case tableEntries.continentEntry: {
// 			return {
// 				...acc,
// 				[tableEntries.continentEntry]: {
// 					...entry,
// 					type: constants.TABLE_TYPES.SUBTOTAL
// 				}
// 			};
// 		}

// 		case tableEntries.marketRegionEntry: {
// 			const continentName = _get(entry, "market_region.continent.name", null);
// 			const marketRegionName = _get(entry, "market_region.name", null);

// 			return {
// 				...acc,
// 				[continentName]: {
// 					...acc[continentName],
// 					[marketRegionName]: {
// 						...acc[continentName][marketRegionName],
// 						total: {
// 							...entry,
// 							type: constants.TABLE_TYPES.SUB_SUBTOTAL
// 						}
// 					}
// 				}
// 			};
// 		}

// 		default:
// 			return {
// 				...acc,
// 				[constants.TABLE_TYPES.ENTRY]: {
// 					...entry,
// 					type: constants.TABLE_TYPES.ENTRY
// 				}
// 			};
// 		}
// 	}, {});
// }

// /**
//  * @function _mapInnerReport
//  * @private
//  * @description Map over the inner report and add types to the different entries
//  * @param {Array} List of lines that all in all is a report
//  * @returns Array
//  */
// function _mapInnerReport(report) {
// 	return report.reduce((acc, entry) => {
// 		switch (entry.entry_type) {
// 		case tableEntries.marketGroupEntry: {
// 			const marketName = _get(entry, "market.name", null);

// 			return {
// 				...acc,
// 				[marketName]: acc[marketName]
// 					? {
// 						...acc[marketName],
// 						[entry.group]: {
// 							...acc[marketName][entry.group],
// 							total: {
// 								...entry,
// 								type: constants.TABLE_TYPES.SUB_SUBTOTAL
// 							}
// 						}
// 					}
// 					: {
// 						[entry.group]: {
// 							stores: [],
// 							total: {
// 								...entry,
// 								type: constants.TABLE_TYPES.SUB_SUBTOTAL
// 							}
// 						}
// 					}
// 			};
// 		}

// 		case tableEntries.marketEntry: {
// 			const marketName = _get(entry, "workplace.market.name", null);

// 			return {
// 				...acc,
// 				[marketName]: acc[marketName]
// 					? {
// 						...acc[marketName],
// 						[entry.group]: {
// 							...acc[marketName][entry.group],
// 							stores: [
// 								...acc[marketName][entry.group]["stores"],
// 								{
// 									...entry,
// 									type: constants.TABLE_TYPES.ENTRY
// 								}
// 							]
// 						}
// 					}
// 					: {
// 						[entry.group]: {
// 							stores: [
// 								{
// 									...entry,
// 									type: constants.TABLE_TYPES.ENTRY
// 								}
// 							]
// 						}
// 					}
// 			};
// 		}

// 		default:
// 			return {
// 				...acc,
// 				[constants.TABLE_TYPES.ENTRY]: {
// 					...entry,
// 					type: constants.TABLE_TYPES.ENTRY
// 				}
// 			};
// 		}
// 	}, {});
// }
