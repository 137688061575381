'use strict';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';

import SelectInput from 'react-select';
import cx from 'classnames';
import _some from 'lodash/some';
import { Icon } from 'dumb';

class Select extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			selected:
				props.componentState !== null ? props.componentState.results : null,
			options: [],
			parameterValues: {},
			isTouched: false,
		};

		this.handleChange = this.handleChange.bind(this);
		this._fetchOptionsByValue = this._fetchOptionsByValue.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
		this.onFocus = this.onFocus.bind(this);
		// this.updateOptions = _debounce(this.updateOptions, 500);
		this.updateOptions = this.updateOptions.bind(this);
		// this._onInputChange = this._onInputChange.bind(this);
	}

	componentDidMount() {
		if (this.state.selected !== null) this.handleChange(this.state.selected);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.updateOptions(nextProps);
	}

	/**
	 * Updates selected options to match any deselected/selected dependency
	 */
	updateSelected() {
		if (this.state.selected !== null) {
			const syncedSelected = this.state.selected.filter((selectedOption) =>
				_some(this.state.options, selectedOption)
			);
			this.setState(() => ({ selected: syncedSelected }));
		}
	}

	/**
	 * Updates options if parameterValues have changed
	 * @param  {object} nextProps
	 */
	updateOptions(nextProps) {
		if (
			this.state.isTouched &&
			JSON.stringify(this.state.parameterValues) !==
				JSON.stringify(nextProps.component.dataProvider.parameterValues)
		) {
			this.getOptions(nextProps);
		}
	}

	/**
	 * Gets options from component (dataprovider)
	 * Sets dataProvider.parameterValues as state for future comparison and to know when to get new options
	 * @param  {object} props
	 */
	getOptions(props) {
		const { component, toggleStateLoading } = this.props;

		// Toggle loader from sub component
		toggleStateLoading();

		component
			.getOptions()
			.then((options) =>
				this.setState(
					() => ({
						options,
						parameterValues: {
							...props.component.dataProvider.parameterValues,
						},
					}),
					() => {
						toggleStateLoading(false);
						this.updateSelected();
					}
				)
			)
			.catch(() => {
				toggleStateLoading(false);
			});
	}

	_fetchOptionsByValue(value) {
		const { component } = this.props;

		component.getOptions(value).then((options) =>
			this.setState(() => ({
				options,
				parameterValues: {
					...this.props.component.dataProvider.parameterValues,
				},
			}))
		);
	}

	handleFocus() {
		this.selectInput.focus();
	}

	onFocus() {
		if (!this.state.isTouched) {
			this.getOptions(this.props);
			this.setState(() => ({ isTouched: true }));
		}
	}

	handleChange(event) {
		const { component, handleChange } = this.props;

		// Selected - always as array (or null if empty)
		const selected =
			component.multi || Array.isArray(event) || event === null
				? event
				: [event];

		// Update state locally
		this.setState(() => ({ selected }));

		// Send upwards and to component
		handleChange(component, selected);
	}

	// _onInputChange(event) {
	// }

	renderSelectedValues() {
		const { component } = this.props;
		const { selected } = this.state;

		// Only apply to multi
		if (!component.multi) return;

		// Only apply if array
		if (!Array.isArray(selected)) return;

		// Render simple count if too many selected values
		if (selected.length > 3) return `(${selected.length} ${component.title})`;
		// Otherwise render simple list of selected values
		else if (selected.length > 0)
			return selected.map((value) => `${value.label}`).join(', ');
	}

	renderClear() {
		return <Icon name="clear" className="select-clear" />;
	}

	render() {
		const { component, handleFocusChange, phrases } = this.props;

		const { selected, options } = this.state;

		const styles = cx('hotdamnbar__select__wrapper', {
			'hotdamnbar__select__wrapper--multiple-selected':
				Array.isArray(selected) && selected.length > 0,
		});

		// Get value
		let value = null;
		if (selected !== null && selected.length > 0)
			value = component.multi ? selected : selected[0];

		return (
			<div
				onClick={this.handleFocus}
				className={styles}
				data-cy="hotdamnbar-select"
				data-selected-values={this.renderSelectedValues()}>
				<SelectInput
					inputProps={{
						autoComplete: 'false',
						autoCorrect: 'false',
						spellCheck: 'false',
					}}
					ref={(input) => {
						this.selectInput = input;
					}}
					autoBlur={!component.multi}
					onBlur={handleFocusChange.bind(this, component)}
					clearable
					ignoreAccents={false}
					openOnFocus
					onFocus={this.onFocus}
					onInputChange={(event) => this._fetchOptionsByValue(event)}
					multi={component.multi}
					name={component.title}
					onChange={this.handleChange}
					// onInputChange={this._onInputChange.bind(this)}
					closeOnSelect={!component.multi}
					options={options}
					searchable
					value={value}
					noResultsText={false}
					placeholder={component.required ? phrases.SELECT : phrases.ALL}
					clearRenderer={this.renderClear}
				/>
			</div>
		);
	}
}

Select.propTypes = {
	component: PropTypes.object.isRequired,
	phrases: PropTypes.object,
	handleChange: PropTypes.func.isRequired,
	toggleStateLoading: PropTypes.func,
	handleFocusChange: PropTypes.func.isRequired,
};

export default Select;
