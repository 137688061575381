'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { store, connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { Input, Button, Icon, Toggle } from 'dumb';
import Checkbox from 'inputs/checkbox';

import { ReactDataWrapper } from 'reactDataWrapper';
import { submitBatchValues } from 'reactDataWrapper/utilities/batchHelper';

import {
	fetchRawIngredients,
	addRawIngredients,
	deleteRawIngredients,
	editRawIngredient,
	editRawIngredients
} from './rawIngredients.service';

import {
	setRawIngredientsModal,
	updateRawIngredients,
	resetState,
	updateBatchForm,
	resetBatch,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow
} from './store/rawIngredients.actions';

// components
import RawIngredientsModalBatch from './components/batch/rawIngredientsModalBatch';

// constants/phrases/tools
import phrases from './rawIngredients.phrases';
import _get from 'lodash/get';

class RawIngredients extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false
		};

		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.getEditableCells = this.getEditableCells.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this._toggleModal = this._toggleModal.bind(this);
		this.batchAdd = this.batchAdd.bind(this);
		this.fetchData = this.fetchData.bind(this);

		this.reduxKey = '/inventory/raw_ingredients';

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: d => _get(d, 'name', ''),
				filterPath: ':name'
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: d => _get(d, 'description', ''),
				filterPath: ':description'
			},
			{
				Header: 'Amount',
				id: 'amount',
				accessor: d => _get(d, 'amount', ''),
				filterPath: ':amount'
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: d => _get(d, 'legacy', ''),
				filterPath: ':legacy',
				filterable: false,
				Cell: d => <Checkbox noBorder disabled checked={d.original.legacy} />
			}
		];
	}

	editEntry() {
		const { rawIngredients } = this.props;

		const payload = {
			id: rawIngredients.id,
			name: rawIngredients.name,
			description: rawIngredients.description,
			legacy: rawIngredients.legacy
		};

		return editRawIngredient(payload);
	}

	editMultiple(selectedRows) {
		const { rawIngredients } = this.props;

		const payload = {
			...(rawIngredients.name && {
				name: rawIngredients.name
			}),
			...(rawIngredients.description && {
				description: rawIngredients.description
			}),
			legacy: !!rawIngredients.legacy
		};

		const selectedRowsWithId = selectedRows.map(row => {
			return {
				id: row.id,
				...payload
			};
		});

		return editRawIngredients({
			batch: selectedRowsWithId
		});
	}

	deleteEntry(id) {
		return deleteRawIngredients(id);
	}

	setInitialEditValues(data) {
		const { setRawIngredientsModal } = this.props;

		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			legacy: data.legacy,
			amount: data.amount
		};

		setRawIngredientsModal(payload);
	}

	getEditableCells() {
		const { rawIngredients, globalRawIngredient } = this.props;

		return [
			{
				header: 'Global raw ingredient',
				value: <span>{globalRawIngredient.name}</span>
			},
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={rawIngredients.name || ''}
						onChange={e => this.editStoreEntry('name', e.target.value)}
					/>
				)
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={rawIngredients.description}
						onChange={e => this.editStoreEntry('description', e.target.value)}
					/>
				)
			},
			{
				header: 'Legacy',
				value: (
					<Toggle
						id="legacy"
						onClick={e => this.editStoreEntry('legacy', e)}
						toggled={rawIngredients.legacy}
					/>
				)
			}
		];
	}

	editStoreEntry(name, value) {
		const { updateRawIngredients } = this.props;

		const payload = {
			[name]: value
		};

		updateRawIngredients(payload);
	}

	_toggleModal() {
		this.setState(prevState => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	batchAdd(payload) {
		const { globalRawIngredient } = this.props;

		return addRawIngredients(payload)
			.then(res => {
				const reduxKey = `${this.reduxKey}-${globalRawIngredient.id}`;

				submitBatchValues({ res, reduxKey });

				return res;
			})
			.catch(err => {
				throw err;
			});
	}

	fetchData(state) {
		const { globalRawIngredient } = this.props;

		const filter = `:global_raw_ingredient.id=='${globalRawIngredient.id}'`;

		return fetchRawIngredients(state, filter);
	}

	render() {
		const {
			batchList,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			updateBatchForm,
			addBatchListItemRow,
			batchFormData,
			globalRawIngredient
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={`${this.reduxKey}-${globalRawIngredient.id}`}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					deleteEntry={this.deleteEntry}
					editMultiple={this.editMultiple}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					actionRender={
						<>
							<Button
								type="inverted"
								label="Add"
								shadow
								onClick={() => this._toggleModal()}>
								<Icon name="add" />
							</Button>
						</>
					}
				/>

				<RawIngredientsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this._toggleModal}
					updateBatchForm={updateBatchForm}
					addBatchList={addBatchList}
					batchList={batchList}
					batchFormData={batchFormData}
					addRawIngredients={this.batchAdd}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					globalRawIngredient={globalRawIngredient}
				/>
			</>
		);
	}
}

RawIngredients.propTypes = {
	setRawIngredientsModal: PropTypes.func,
	resetState: PropTypes.func,
	updateRawIngredients: PropTypes.func,
	rawIngredients: PropTypes.object,
	batchList: PropTypes.array,
	addBatchList: PropTypes.func,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	updateBatchForm: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	batchFormData: PropTypes.object,
	globalRawIngredient: PropTypes.object
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			setRawIngredientsModal,
			updateRawIngredients,
			resetState,
			updateBatchForm,
			resetBatch,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			addBatchListItemRow
		},
		dispatch
	);
};

const mapStateToProps = store => {
	return {
		rawIngredients: store.globalAdministrationRawIngredients.data.rawIngredients,
		batchList: store.globalAdministrationRawIngredients.data.batchList,
		batchFormData: store.globalAdministrationRawIngredients.data.batchFormData
	};
};

export default connectWithStore(
	RawIngredients,
	mapStateToProps,
	mapDispatchToProps
);
