/* eslint-disable react/prop-types */
import React from 'react';

// components
import { InputCollectionSelect } from 'dumb';
import { DateFilterSelector, FilterProvider } from 'reactDataWrapper/utilities';

// utils
import collectionSelectEnums from 'services/enums/collectionSelect';
import { getSelectOptionsFromEnums } from 'services/utils';

// lodash
import _get from 'lodash/get';

export default function getColumns(reduxKey, enums) {
	return [
		{
			Header: 'Market',
			id: 'market',
			accessor: (d) => _get(d, 'market.name', ''),
			filterPath: ':market.name',
		},
		{
			Header: 'Type',
			id: 'type',
			accessor: 'type',
			filterPath: ':type',
			Filter: ({ column }) => (
				<FilterProvider
					reduxKey={reduxKey}
					columnId={column.id}
					provide={(filterValue, persistToFilterStorage) => (
						<InputCollectionSelect
							id={column.id}
							name="type"
							value={filterValue}
							handleChange={(key, value) => {
								persistToFilterStorage({ handle: key, value });
							}}
							styleType={collectionSelectEnums.TYPE_IN_TABLE}
							options={getSelectOptionsFromEnums(enums)}
						/>
					)}
				/>
			),
		},
		{
			Header: 'Text',
			id: 'text',
			accessor: 'text',
			filterPath: ':text',
			width: 500,
		},
		{
			Header: 'Active from',
			id: 'period-from',
			accessor: (d) => _get(d, 'period.from', ''),
			filterPath: ':period.from',
			width: 150,
			Filter: ({ column }) => (
				<DateFilterSelector reduxKey={reduxKey} columnId={column.id} />
			),
		},
		{
			Header: 'Active to',
			id: 'period-to',
			accessor: (d) => _get(d, 'period.to', ''),
			filterPath: ':period.to',
			width: 150,
			Filter: ({ column }) => (
				<DateFilterSelector reduxKey={reduxKey} columnId={column.id} />
			),
		},
	];
}
