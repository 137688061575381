'use strict';

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { Manager, Reference, Popper } from 'react-popper';
import { CSSTransition } from 'react-transition-group';

// components
import MenuWrapper from './components/menuWrapper/menuWrapper';

import './menu.css';

class Menu extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isShown: false,
		};

		this.onClick = this.onClick.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}

	onClick() {
		this.setState(() => ({ isShown: true }));
	}

	onBlur() {
		this.setState(() => ({ isShown: false }));
	}

	render() {
		const { id, children, zIndex, renderData, placement } = this.props;

		return (
			<Manager>
				<Reference>
					{({ ref }) => renderData(ref, this.onClick, this.state.isShown)}
				</Reference>
				{ReactDOM.createPortal(
					<CSSTransition
						timeout={200}
						classNames="j-menu"
						in={this.state.isShown}
						unmountOnExit>
						<Popper placement={placement}>
							{({ placement, ref, style }) => (
								<MenuWrapper
									id={id}
									onBlur={this.onBlur}
									className="j-menu__content"
									forwardedRef={ref}
									style={{ ...style, zIndex }}>
									{children}
								</MenuWrapper>
							)}
						</Popper>
					</CSSTransition>,
					document.querySelector('#popper')
				)}
			</Manager>
		);
	}
}

Menu.defaultProps = {
	placement: 'bottom-start',
};

Menu.propTypes = {
	id: PropTypes.string,
	renderData: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	zIndex: PropTypes.number,
	placement: PropTypes.string,
};

export default Menu;
