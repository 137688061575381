import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './filterProductVariantIngredientVariationDefaultReplacements.phrases';

/**
 * @function fetchFilterProductVariantIngredientVariationDefaultReplacements
 * @description Fetch all filter product variant ingredient variation default replacements
 * @param {object} state
 */
export function fetchFilterProductVariantIngredientVariationDefaultReplacements(
	state,
	filter
) {
	const newFilter = state.filter ? `${state.filter};${filter}` : filter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: newFilter,
	};

	return get(
		'/product/filter_product_variant_ingredient_variation_default_replacements',
		params,
		null,
		state.headers
	)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addFilterProductVariantIngredientVariationDefaultReplacement
 * @description add filter product variant ingredient variation default replacement
 */
export function addFilterProductVariantIngredientVariationDefaultReplacement(
	data
) {
	return post(
		'/product/filter_product_variant_ingredient_variation_default_replacements',
		data
	)
		.then((res) => {
			setFeedback(
				phrases.FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENT_ADDED,
				1
			);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editFilterProductVariantIngredientVariationDefaultReplacement
 * @description edit filter product variant ingredient variation default replacement
 */
export function editFilterProductVariantIngredientVariationDefaultReplacement(
	data
) {
	return post(
		`/product/filter_product_variant_ingredient_variation_default_replacements/${data.id}`,
		data
	)
		.then((res) => {
			setFeedback(
				phrases.FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENT_EDITED,
				1
			);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editFilterProductVariantIngredientVariationDefaultReplacements
 * @description edit filter product variant ingredient variation default replacements
 */
export function editFilterProductVariantIngredientVariationDefaultReplacements(
	data
) {
	return patch(
		'/product/filter_product_variant_ingredient_variation_default_replacements',
		data
	)
		.then((res) => {
			setFeedback(
				phrases.FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENTS_EDITED,
				1
			);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteFilterProductVariantIngredientVariationDefaultReplacement
 * @description delete filter product variant ingredient variation default replacements
 */
export function deleteFilterProductVariantIngredientVariationDefaultReplacement(
	id
) {
	return remove(
		`/product/filter_product_variant_ingredient_variation_default_replacements/${id}`
	)
		.then((res) => {
			setFeedback(
				phrases.FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENT_DELETED,
				1
			);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
