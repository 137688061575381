import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchAllAssets
 * @description Fetch all asset collections
 * @param {object} state
 */
export function fetchAllAssets(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get(`/pos/assets`, params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editAssets
 * @description edit
 */
export function editAssets(collectionId, data) {
	return post(`/pos/assets/${collectionId}`, data)
		.then(res => {
			setFeedback('Asset edited', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function addAssets
 * @description add
 */
export function addAssets(data) {
	return post(`/pos/assets`, data)
		.then(res => {
			setFeedback('Asset added', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteAssets
 * @description del
 */
export function deleteAssets(collectionId) {
	return remove(`/pos/assets/${collectionId}`)
		.then(res => {
			setFeedback('Asset deleted', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
