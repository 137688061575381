'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { store } from 'appState';

import { ReactDataWrapper } from 'reactDataWrapper';
import { setListFilter } from 'reactDataWrapper/filterSortColumnsData.actions';

import { Input, Button, Icon, Tooltip } from 'dumb';

// Tools
import _get from 'lodash/get';
import phrases from './tileLayouts.phrases';

// utils
import { getTileLayoutFilter, markFoundLayouts } from './utils';

import { fetchTileLayouts } from './tileLayouts.service';

class TileLayouts extends Component {
	constructor(props) {
		super(props);

		this.state = {
			pages: null,
			isSelectOpen: false,
			loadingTrList: [],
			loaderEditFile: false,
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this.reduxKey = `product-/pos/tile_layouts-${props.product.id}`;

		this.fetchData = this.fetchData.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);
		this._handleNavigate = this._handleNavigate.bind(this);
		this.getActionButtons = this.getActionButtons.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: (d) => _get(d, 'description', ''),
				filterPath: ':description',
			},
			{
				Header: 'Type',
				id: 'type',
				accessor: (d) => _get(d, 'type', null),
				filterPath: ':type',
			},
			{
				Header: 'Rows',
				id: 'rows',
				accessor: (d) => _get(d, 'rows', null),
				filterPath: ':rows',
			},
			{
				Header: 'Columns',
				id: 'columns',
				accessor: (d) => _get(d, 'columns', null),
				filterPath: ':columns',
			},
			{
				Header: 'Sub type',
				id: 'subType',
				accessor: (d) => _get(d, 'sub_type', null),
				filterPath: ':sub_type',
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: (d) => _get(d, 'legacy', ''),
				filterPath: ':legacy',
				Cell: (d) => {
					return (
						<Input
							id="checkbox-legacy"
							type="checkbox"
							checked={_get(d, 'original.legacy', false)}
							disabled
						/>
					);
				},
			},
			{
				Header: 'Product exists in layout',
				id: 'productExistsInLayout',
				accessor: 'productExistsInLayout',
				filterable: false,
				sortable: false,
				Cell: (d) => {
					return (
						<Input
							id="checkbox-exists"
							type="checkbox"
							checked={d.original.productExistsInLayout}
							disabled
						/>
					);
				},
			},
		];
	}

	fetchData(state) {
		const { product } = this.props;

		return fetchTileLayouts(state).then((res) => {
			const filter = getTileLayoutFilter({
				layouts: res.data,
				productId: product.id,
			});

			return fetchTileLayouts({ limit: 300 }, filter).then((resTwo) => {
				// cross reference second res with first and mark them with {... productExist: (true/false) ...}

				const markedValues = markFoundLayouts({
					filteredLayouts: resTwo.data,
					layouts: res.data,
				});

				return {
					...res,
					data: markedValues,
				};
			});
		});
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}>
				Legacy
			</Button>
		);
	}

	getActionButtons(d) {
		return (
			<Tooltip
				placement="left"
				text={phrases.TOOLIP_TEXT}
				renderData={(ref, onMouseEnter, onMouseLeave) => (
					<Button
						type="inverted"
						shadow
						onClick={() => this._handleNavigate(d.original)}
						size="micro"
						refProp={ref}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}>
						<Icon name="tune" />
					</Button>
				)}
			/>
		);
	}

	_handleNavigate(d) {
		const { setActiveTabSalesConfiguration } = this.props;

		// change layout table filters
		const layoutName = d.name;
		const layoutTableReduxKey = 'pos/tile_layouts';
		const filterObject = {
			reduxKey: layoutTableReduxKey,
			filter: [{ id: 'name', value: layoutName }],
		};

		store.dispatch(setListFilter(filterObject));

		// change tab position in local storage
		setActiveTabSalesConfiguration(5);
	}

	render() {
		const legacyFilter = this.state.legacyFilterOn
			? this.state.legacyFilter
			: '';

		return (
			<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.TABLE_TITLE}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={10}
				reduxKey={this.reduxKey}
				actionsWidth={30}
				showSearch
				customAreaComponents={this.getLegacyFilterButton()}
				extraFilters={legacyFilter}
				actions={this.getActionButtons}
			/>
		);
	}
}

TileLayouts.propTypes = {
	product: PropTypes.object,
	setActiveTabSalesConfiguration: PropTypes.func,
};

export default TileLayouts;
