'use strict';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// redux
import { bindActionCreators } from 'redux';
import { connectWithStore } from 'appState';
import { setShowModal, setMetadata } from 'smart/removeShiftsFromEmployee/store/removeShiftsFromEmployee.actions';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { DateFilterSelector } from 'reactDataWrapper/utilities';
import { Button, ButtonLoader, Icon, Tooltip } from 'dumb';

// third party
import _get from 'lodash/get';

// utils
import moment from 'moment';
import { fetchShifts } from './userShifts.service';
import { saveAs } from 'file-saver';

// phrases/ constants
import phrases from './userShifts.phrases';
import constants from 'services/constants';
import removeShiftsFromEmployeeEnums from 'services/enums/removeShiftsFromEmployee';

import './userShifts.css';

const reduxKey = 'shiftplanning/shifts';
class UserShifts extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
		};

		this.fetchData = this.fetchData.bind(this);
		this.getActionContent = this.getActionContent.bind(this);
		this.handleCSVExport = this.handleCSVExport.bind(this);
		this.handleRemoveShiftsFromEmployeeClick = this.handleRemoveShiftsFromEmployeeClick.bind(this);

		this.columns = [
			{
				Header: 'Workplace',
				id: 'workplace',
				accessor: (d) => _get(d, 'workplace.name', null),
				filterPath: ':workplace.name',
			},
			{
				Header: 'Work day',
				id: 'workDay',
				accessor: (d) => _get(d, 'planned_period.from', null),
				filterPath: ':planned_period.from',
				Cell: (d) => {
					const date = moment.utc(d.value, constants.dateFormat).format(constants.shortDate);
					const workday = moment.utc(d.value, constants.dateFormat).format(constants.nameOfWeekday);

					return (
						<div>
							<span>{date}</span>
							<span className="user-shifts__weekday">({workday})</span>
						</div>
					);
				},
				Filter: ({ column }) => (
					<DateFilterSelector
						reduxKey={reduxKey}
						columnId={column.id}
						dateIdentifier="from"
						withTimestamp
						formatStartDate={(date) => date && date.clone().utc().startOf('day')}
						formatEndDate={(date) => date && date.clone().utc().endOf('day').subtract(1, 'seconds')}
					/>
				),
			},
			{
				Header: 'From',
				id: 'from',
				accessor: (d) => _get(d, 'planned_period.from', null),
				filterPath: ':planned_period.from',
				Cell: (d) => {
					const time = moment.utc(d.value, constants.dateFormat).format(constants.timeShort);

					return <span>{time}</span>;
				},
				filterable: false,
				sortable: false,
			},
			{
				Header: 'To',
				id: 'to',
				accessor: (d) => _get(d, 'planned_period.to', null),
				filterPath: ':planned_period.to',
				Cell: (d) => {
					const time = moment.utc(d.value, constants.dateFormat).format(constants.timeShort);

					return <span>{time}</span>;
				},
				filterable: false,
				sortable: false,
			},
			{
				Header: 'Created',
				id: 'created',
				accessor: 'created',
				filterPath: ':created',
				show: false,
				Cell: (d) => {
					const date = moment.utc(d.value, constants.dateFormat).format(constants.shortDate);

					return <span>{date}</span>;
				},
				Filter: ({ column }) => <DateFilterSelector reduxKey={reduxKey} columnId={column.id} dateIdentifier="from" />,
			},
		];
	}

	getActionContent() {
		return (
			<>
				<Tooltip
					text={phrases.REMOVE_SHIFTS_FROM_EMPLOYEE_TOOLTIP}
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							type="inverted"
							label="Remove shifts"
							shadow
							onClick={this.handleRemoveShiftsFromEmployeeClick}
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
						>
							{this.state.loading ? <ButtonLoader loading /> : <Icon name="delete_sweep" />}
						</Button>
					)}
				/>
				<Tooltip
					text={phrases.EXPORT_CSV}
					placement="left"
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							type="inverted"
							label="CSV"
							shadow
							onClick={this.handleCSVExport}
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
						>
							{this.state.loading ? <ButtonLoader loading /> : <Icon name="file_download" />}
						</Button>
					)}
				/>
			</>
		);
	}

	handleRemoveShiftsFromEmployeeClick() {
		const { setMetadata, setShowModal, personId } = this.props;

		setShowModal(true);
		setMetadata({
			personId,
			source: removeShiftsFromEmployeeEnums.JUICER_DETAILS,
			juicerDetailsReduxKey: reduxKey,
		});
	}

	/**
	 * @function fetchData
	 * @param {Object} state
	 * @description get's data for the reactDataWrapper
	 */
	fetchData(state, headers = null) {
		const { personId } = this.props;

		const filter = `:shift_employees.employment.person.id=='${personId}'`;

		return fetchShifts(state, filter, headers);
	}

	handleCSVExport() {
		const { customFilters } = this.props;

		this.setState(() => ({ loading: true }));

		const workplaceFilter = customFilters.find((entry) => entry.id === 'workplace');
		const dateFilter = customFilters.find((entry) => entry.id === 'workDay');

		let filter;
		if (workplaceFilter) filter = `:workplace.name=like='%${workplaceFilter.value}%'`;
		if (dateFilter?.value?.startDate) {
			const fromDate = moment.utc(dateFilter.value?.startDate).format(constants.dateFormat);

			filter = filter ? `${filter};:planned_period.from=ge='${fromDate}'` : `:planned_period.from=ge='${fromDate}'`;
		}
		if (dateFilter?.value?.endDate) {
			const endDate = moment.utc(dateFilter.value?.endDate).format(constants.dateFormat);

			filter = filter ? `${filter};:planned_period.from=le='${endDate}'` : `:planned_period.from=le='${endDate}'`;
		}

		const limit = 300;

		const headers = {
			Accept: 'application/csv',
		};

		return this.fetchData({ limit, filter }, headers)
			.then((csv) => {
				const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });

				let csvNameDatePrefix = moment().format(constants.shortDate);
				if (dateFilter?.value?.startDate || dateFilter?.value?.endDate) {
					csvNameDatePrefix = moment
						.utc(dateFilter?.value?.startDate || dateFilter?.value?.endDate)
						.format(constants.shortDate);
				}

				// outputs filename in format of "redux_key__2019_04_24"
				const csvFileName = `${reduxKey.replace('/', '_')}__${csvNameDatePrefix}.csv`;
				const fileName = csvFileName;
				saveAs(blob, fileName);
			})
			.finally(() => this.setState(() => ({ loading: false })));
	}

	render() {
		return (
			<ReactDataWrapper
				title={phrases.TABLE_TITLE}
				columns={this.columns}
				fetchData={this.fetchData}
				defaultPageSize={5}
				reduxKey={reduxKey}
				manual
				inRowButtons
				actionsWidth={60}
				actionRender={this.getActionContent()}
				defaultSorted={[
					{
						id: 'from',
						desc: false,
					},
				]}
			/>
		);
	}
}

UserShifts.propTypes = {
	personId: PropTypes.number,
	customFilters: PropTypes.array,
	setMetadata: PropTypes.func,
	setShowModal: PropTypes.func,
};

UserShifts.defaultProps = {
	customFilters: [],
};

const mapStateToProps = (store) => {
	return {
		customFilters: store.filterSortColumnsData.tables?.[reduxKey]?.filters,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setMetadata,
			setShowModal,
		},
		dispatch
	);

export default connectWithStore(UserShifts, mapStateToProps, mapDispatchToProps);
