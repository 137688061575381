// API helpers
import { get, remove, post } from 'api.vanilla.service';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { formatErrorMessage } from 'api/helpers';

// Interfaces
import { EmployemnetWorkplacesProps, PayloadState, UpdateState } from './types';

// Phrases
import phrases from './employmentWorkplaces.phrases';

function handleError(err: Error): Error {
	const error = formatErrorMessage(err);
	setFeedback(error, 0);

	throw error;
}

export const deleteEmploymentWorkplace = (id: number): Promise<IApiRes> => {
	return remove(`/hr/employment_workplaces/${id}`)
		.then((response: IApiRes) => {
			setFeedback(phrases.EMPLOYMENT_WORKPLACE_REMOVED, 1);
			return response;
		})
		.catch(handleError);
};

export function fetchEmploymentWorkplaces({
	id,
	state,
}: EmployemnetWorkplacesProps): Promise<IApiRes> {
	let filter = `:employment.id=='${id}'`;
	if (state.filter) filter = `:employment.id=='${id}';${state.filter}`;

	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};
	return get('/hr/employment_workplaces', params)
		.then((res: IApiRes) => {
			return res;
		})
		.catch(handleError);
}

export const createEmploymentWorkplace = (
	payload: PayloadState
): Promise<IApiRes> => {
	return post('/hr/employment_workplaces', payload)
		.then((response: IApiRes) => {
			setFeedback(phrases.EMPLOYMENT_WORKPLACE_CREATED, 1);
			return response;
		})
		.catch((error: Error) => {
			const errorMessage = formatErrorMessage(error);
			setFeedback(errorMessage, 0);
		});
};

export const updateEmploymentWorkplace = ({
	payload,
	id,
}: UpdateState): Promise<IApiRes> => {
	return post(`/hr/employment_workplaces/${id}`, payload)
		.then((response: IApiRes) => {
			setFeedback(phrases.EMPLOYMENT_WORKPLACE_UPDATED, 1);
			return response;
		})
		.catch((error: Error) => {
			const errorMessage = formatErrorMessage(error);
			setFeedback(errorMessage, 0);
		});
};
