'use strict';

import _has from 'lodash/has';
import _isUndefined from 'lodash/isUndefined';
import _each from 'lodash/each';



module.exports = function() {
	// Get headers in question
	var headers = this.handleReturnVisibleHeaders();

	// Get collection in question
	var collection = this.getState().collection;

	// Define empty sumRow to return
	var sumRow = {};

	// Loop through (visible) headers
	_each(headers, function(header) {
		// Move forward for each header that has the .summarize child object present
		if (_has(header, 'summarize')) {
			// Get name of header
			var name = _has(header, 'reference') ? header.reference.newName : header.name;

			// Define headerSumValue to use further down
			var headerSumValue = 0;

			// Switch on summarize type (sum, median, etc.)
			switch (header.summarize.type) {
				case 'sum':
					// Loop through collection with type 'sum'
					_each(collection, function(item) {
						// Check for summarize condition, if not defined, just add to headerSumValue
						if (_isUndefined(header.summarize.condition)) headerSumValue = headerSumValue + item[name];

						// Else make sure the condition is true
						else if (header.summarize.condition(item[name])) headerSumValue = headerSumValue + item[name];
					});
					break;
			}
			sumRow[name] = headerSumValue;
		}
	}.bind(this));

	// Return sumRow
	return sumRow;
};