// interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';

import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './powerBiReportDataSets.phrases';

function handleError(err: Error): Error {
	const error = formatErrorMessage(err);
	setFeedback(error, 0);

	throw error;
}

/**
 * @function fetchPowerBiReportDataSets
 * @description Fetch all power bi report data sets
 * @param {object} state
 */
export function fetchPowerBiReportDataSets(
	state: IFetchDataProps,
	filter: string
): Promise<IApiRes> {
	let finalFilter = filter;
	if (state.filter) finalFilter = `${finalFilter};${state.filter}`;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: finalFilter,
	};

	return get('/reporting/power_bi_report_datasets', params, null, state.headers)
		.then((res: IApiRes) => res)
		.catch(handleError);
}

/**
 * @function addPowerBiReportDataSets
 * @description adds dynamicAccount
 */
export function addPowerBiReportDataSets(
	data: Record<string, unknown>
): Promise<IApiRes> {
	return post('/reporting/power_bi_report_datasets', data)
		.then((res: IApiRes) => {
			setFeedback(phrases.POWER_BI_REPORT_DATA_SET_ADDED, 1);
			return res;
		})
		.catch(handleError);
}

/**
 * @function editPowerBiReportDataSets
 * @description edits dynamicAccount
 */
export function editPowerBiReportDataSets(
	id: number,
	data: Record<string, unknown>
): Promise<IApiRes> {
	return post(`/reporting/power_bi_report_datasets/${id}`, data)
		.then((res: IApiRes) => {
			setFeedback(phrases.POWER_BI_REPORT_DATA_SET_EDITED, 1);
			return res;
		})
		.catch(handleError);
}

/**
 * @function deletePowerBiReportDataSets
 * @description deletes power
 */
export function deletePowerBiReportDataSets(id: number): Promise<IApiRes> {
	return remove(`/reporting/power_bi_report_datasets/${id}`)
		.then((res: IApiRes) => {
			setFeedback(phrases.POWER_BI_REPORT_DATA_SET_DELETED, 1);
			return res;
		})
		.catch(handleError);
}
