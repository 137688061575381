'use strict';

import React from 'react';

// interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';
import { ICompanyReduxValue, ICompanyCrud } from './types';
import { SelectOption } from 'types/select';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import { push } from 'redux-first-history';

// components
import { Button, Icon } from 'dumb';
import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import CompanyEditableCells from './components/companyEditableCells/companyEditableCells';
import getColumns from 'reactDataWrapperColumns/organization/companies.columns';

// mui
import { Tooltip, Box } from '@mui/material';

import {
	fetchCompanies,
	addCompanies,
	deleteCompanies,
	editCompanies,
} from './companies.service';

import {
	setCompany,
	updateCompany,
	resetCompany,
} from './store/companies.actions';

// constants/phrases/tools
import phrases from './companies.phrases';
import getEditedValues from 'services/utils/getEditedValues';
import copyTextToClipboard from 'services/utils/copyToClipboard';
import { set as setFeedback } from 'feedback.vanilla.service.js';

const reduxKey = '/administration/companies';
const Companies = (): JSX.Element => {
	// hooks
	const { customFilters, company, defaultCompany } = useAppSelector(
		(state) => ({
			customFilters: state.filterSortColumnsData.tables[reduxKey]?.custom ?? {},
			company: state.companies.data.company,
			defaultCompany: state.companies.data.defaultCompany,
		})
	);
	const dispatch = useAppDispatch();

	const addEntry = () => {
		const payload: ICompanyCrud = {
			name: company.name,
			market: company.market
				? (company.market?.value as IAdministrationMarket).id
				: null,
			opened: company.opened,
			closed: company.closed,
			merchant_account: company.merchantAccount,
			main_entity: company.mainEntity,
			legal_entity_code: company.legalEntityCode,
		};

		return addCompanies(payload);
	};

	const editEntry = () => {
		if (!company.id || !defaultCompany) return Promise.resolve(true);

		const editedValues = getEditedValues({
			oldData: defaultCompany,
			newData: company,
		});

		const payload: Partial<ICompanyCrud> = {
			name: editedValues?.name,
			opened: editedValues?.opened,
			closed: editedValues?.closed,
			merchant_account: editedValues?.merchantAccount,
			main_entity: editedValues?.mainEntity,
			legal_entity_code: company.legalEntityCode,
		};

		return editCompanies(company.id, payload);
	};

	const deleteEntry = (id: number) => {
		return deleteCompanies(id);
	};

	const setInitialEditValues = (data: IAdministrationCompany) => {
		const payload: ICompanyReduxValue = {
			id: data.id,
			name: data.name,
			market: {
				value: data.market,
				label: data.market.name,
			},
			legalEntityCode: data.legal_entity_code,
			merchantAccount: data.merchant_account,
			mainEntity: data.main_entity,
			opened: data.opened,
			closed: data.closed,
			editMode: true,
		};

		dispatch(setCompany(payload));
	};

	const editStoreEntry = (
		name: string,
		value: string | SelectOption | null | boolean
	): void => {
		if (name === 'closed' && value === null) {
			value = '9999-12-31';
		}

		const payload = {
			[name]: value,
		};

		dispatch(updateCompany(payload));
	};

	const onCopyClick = (row: IAdministrationCompany) => () => {
		const textToCopy = `https://joejuice.typeform.com/to/sjC5NR?onboarding=${row.id}`;
		copyTextToClipboard(textToCopy);
		setFeedback(phrases.COPY_ONBOARDING_LINK_COPIED);
	};

	const renderLink = (e: { original: IAdministrationCompany }) => {
		return (
			<Box
				sx={{
					display: 'flex',
					marginLeft: 'auto',
				}}
			>
				<Tooltip title={phrases.COPY_ONBOARDING_LINK}>
					<Box>
						<Button
							type="inverted"
							shadow
							onClick={onCopyClick(e.original)}
							size="micro"
						>
							<Icon name="content_copy" />
						</Button>
					</Box>
				</Tooltip>

				<Tooltip title={phrases.DETAILS_TOOLTIP}>
					<Box ml={0.5}>
						<Button
							type="inverted"
							shadow
							onClick={() =>
								dispatch(push(`${window.location.pathname}/${e.original.id}`))
							}
							size="micro"
						>
							<Icon name="tune" />
						</Button>
					</Box>
				</Tooltip>
			</Box>
		);
	};

	const getExtraFilters = (): string => {
		const marketFilter = customFilters?.market
			? `:market.id=='${(customFilters.market as SelectOption).value}'`
			: '';

		let filter = '';
		if (marketFilter)
			filter = filter ? `${filter};${marketFilter}` : marketFilter;

		return filter;
	};

	return (
		<ReactDataWrapper
			title={phrases.TABLE_TITLE}
			columns={getColumns(reduxKey)}
			fetchData={(state: IFetchDataProps): ReturnType<typeof fetchCompanies> =>
				fetchCompanies(state)
			}
			filterable
			defaultPageSize={20}
			reduxKey={reduxKey}
			manual
			accessAreasAllowedToEdit={[
				'Organisation/Workplaces::Edit',
				'Organization Admin',
			]}
			editableCells={CompanyEditableCells({
				company,
				editStoreEntry,
			})}
			actionsWidth={60}
			createEntry={addEntry}
			editEntry={editEntry}
			deleteEntry={deleteEntry}
			setInitialEditValues={setInitialEditValues}
			onModalClose={() => dispatch(resetCompany())}
			compact
			extraFilters={getExtraFilters()}
			actions={renderLink}
			onModalCloseConfirm
		/>
	);
};

export default Companies;
