export default {
	TITLE: 'Ingredient Replacements',
	FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENT_ADDED:
		'Filter product variant added!',
	FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENT_EDITED:
		'Filter Product Variant edited!',
	FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENTS_EDITED:
		'Filter product variants edited!',
	FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENT_DELETED:
		'Filter product variant deleted!',

	// batch form
	MODAL_BATCH_STEP_LIST_TITLE:
		'Create Filter Product Variant Ingredient Variation Default Replacements',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_PRODUCT_VARIANT_INGREDIENT: 'Product variant ingredient',
	MODAL_BATCH_STEP_PRODUCT_VARIANT_INGREDIENT_PLACEHOLDER:
		'Select product variant ingredient...',
	MODAL_BATCH_STEP_INGREDIENT_VARIATION_OPTION: 'Ingredient variation option',
	MODAL_BATCH_STEP_INGREDIENT_VARIATION_OPTION_PLACEHOLDER:
		'Select ingredient variation option...',
	MODAL_BATCH_STEP_SORT_ORDER: 'Sort order',
	MODAL_BATCH_STEP_SORT_ORDER_PLACEHOLDER: 'Enter sort order...',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Submit',
};
