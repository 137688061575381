'use strict';
import React from 'react';
import ImageCell from 'imageCell';

// Services
import ListModel from 'common/components/list/listModel.service';
const __endpoints = require('endpoints.service');

export default class ProductVariantsList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'product',
				type: 'select',
				canAdd: true,
				required: true,
				reference: {
					key: 'name',
					list: '/product/products',
				},
			},
			{
				name: 'fuel',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'available_for_delivery',
				type: 'bool',
				canAdd: true,
				canEdit: true,
				required: true,
			},

			{
				name: 'image',
				type: 'imageCell',
				canSort: false,
				canFilter: false,
				imageCell(row) {
					return <ImageCell id={row.id} image={row.image} endpoint="/product/product_variants" />;
				},
			},
			{
				name: 'size',
				type: 'select',
				canAdd: true,
				required: true,
				reference: {
					key: 'name',
					label: '<%= name %> (<%= size_group.name %>)',
					list: '/product/sizes',
				},
			},
		];
		this.settings = {
			name: 'ProductVariantsList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			pageTitle: true,
			translationPath: 'SALE.PRODUCT_VARIANTS',
			endpoint: __endpoints.collection.product.product_variants,
		};
		this.init();
		return this;
	}
}
