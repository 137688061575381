import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ListComponent from 'common/components/list/list.component';
import DetailsComponent from 'common/components/details/details.component';

import GlobalAccountsDetails from './services/globalAccountsDetails.service';
import GlobalAccountsAccountScheduleAccountsList from './services/globalAccountsAccountScheduleAccountsList.service';

const globalAccountsDetailsDetailsReduxKey = '/accounting/global_accounts-details';
const globalAccountsDetails = new GlobalAccountsDetails(globalAccountsDetailsDetailsReduxKey);
const globalAccountsDetailsProps = globalAccountsDetails.getProps();

const globalAccountsAccountScheduleAccountsListReduxKey = '/accounting/account_schedule_accounts';
const globalAccountsAccountScheduleAccountsList = new GlobalAccountsAccountScheduleAccountsList(
	globalAccountsAccountScheduleAccountsListReduxKey
);
const globalAccountsAccountScheduleAccountsListProps = globalAccountsAccountScheduleAccountsList.getProps();

export function GlobalAccountsDetailsContainer() {
	const { globalAccountId } = useParams();

	const globalAccountIdFilter = `:global_account.id=='${globalAccountId}'`;
	globalAccountsDetails.setItemId(globalAccountId);

	useEffect(() => {
		// Fetch selected employment position
		globalAccountsDetails.initView();

		globalAccountsAccountScheduleAccountsList.setFilterQuery(globalAccountIdFilter);
		globalAccountsAccountScheduleAccountsList.setData({ global_account: globalAccountId });
		globalAccountsAccountScheduleAccountsList.initView();
	}, [globalAccountId, globalAccountIdFilter]);

	return (
		<>
			<DetailsComponent reduxKey={globalAccountsDetailsDetailsReduxKey} {...globalAccountsDetailsProps} />

			<ListComponent
				reduxKey={globalAccountsAccountScheduleAccountsListReduxKey}
				{...globalAccountsAccountScheduleAccountsListProps}
			/>
		</>
	);
}
