import { get, post, remove, patch } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './salesConfigurationIngredientPricings.phrases';

export function fetchPosConfigurationIngredients(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get('/pos/pos_configuration_ingredients', params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editPosConfigurationIngredient
 * @description edit
 */
export function editPosConfigurationIngredient(id, data) {
	return post(`/pos/pos_configuration_ingredients/${id}`, data)
		.then(res => {
			setFeedback(phrases.INGREDIENT_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editBatchPosConfigurationIngredients
 * @description edit
 */
export function editBatchPosConfigurationIngredients(data) {
	return patch('/pos/pos_configuration_ingredients', data)
		.then(res => {
			setFeedback('Ingredient edited', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addPosConfigurationIngredients
 * @description edit
 */
export function addPosConfigurationIngredients(data) {
	return post('/pos/pos_configuration_ingredients', data)
		.then(res => {
			setFeedback(phrases.INGREDIENTS_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deletePosConfigurationIngredient
 * @description edit
 */
export function deletePosConfigurationIngredient(ingredientId) {
	return remove(`/pos/pos_configuration_ingredients/${ingredientId}?cascade=true`)
		.then(res => {
			setFeedback(phrases.INGREDIENT_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return err;
		});
}
