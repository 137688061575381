// enums
import { ShiftTransfer as enums } from 'services/enums';

// lodash
import _isEmpty from 'lodash/isEmpty';

export function getCanDeclineTransferOwn({ entry }) {
	let canDeclineTransfer = false;
	const transferTypePublic = entry.type === enums.TRANSFER_TYPE.PUBLIC;

	// if public, as long as no one applied we can cancel the tranfer
	if (transferTypePublic) {
		const emptyApplicants = _isEmpty(entry.applications);
		const stateNotRetractedDeclined =
			entry.state !== enums.TRANSFER.DECLINED &&
			entry.state !== enums.TRANSFER.RETRACTED;

		canDeclineTransfer = emptyApplicants && stateNotRetractedDeclined;
	}
	// if type swap and receiver didn't yet accept, we can cancel it
	if (
		!transferTypePublic &&
		entry.applications?.[0]?.state ===
			enums.APPLICATIONS.AWAITING_APPLICANT_APPROVAL
	)
		canDeclineTransfer = true;

	return canDeclineTransfer;
}

export function getAppliedPendingShiftTransferMetadata({ entry, personId }) {
	// find your application state and show it as transfer state
	const application = entry.applications.find(
		(entry) => entry.applicant?.person?.id === personId
	);
	const applicationState = application?.state ?? '';

	let canCancelTransfer = false;
	let canReapplyForShift = false;

	const transferTypePublic = entry.type === enums.TRANSFER_TYPE.PUBLIC;

	// both public and swap
	if (
		// transferTypePublic &&
		applicationState === enums.APPLICATIONS.AWAITING_MANAGER_APPROVAL
	) {
		canCancelTransfer = true;
	}
	// type public - we can reaply even if we declined it
	if (transferTypePublic && applicationState === enums.APPLICATIONS.DECLINED) {
		canReapplyForShift = true;
	}

	return { canCancelTransfer, canReapplyForShift, applicationState };
}
