'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Pagination from 'reusableComponents/list/pagination.component';
import AccordionHeaderRow from './accordionHeaderRow.component';
import AccordionRows from './accordionRows.component';

class Accordion extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<tr className="accordion accordion__list-wrapper list__row">
				<td colSpan={this.props.contentColLength}>
					<table className="list">
						<AccordionHeaderRow {...this.props} />
						<AccordionRows {...this.props} />
						<Pagination {...this.props.accordion} />
					</table>
				</td>
			</tr>
		);
	}
}

Accordion.propTypes = {
	accordion: PropTypes.object.isRequired,
	actionColLength: PropTypes.number.isRequired,
	contentColLength: PropTypes.number.isRequired
};

export default Accordion;
