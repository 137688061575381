'use strict';

import EmailDataContentAbstract from './emailData.content.abstract';

/**
 * Subject
 * @param  {*} template
 */
class Subject extends EmailDataContentAbstract  {
	constructor(template) {
		super(template);

		// Set type
		this.type = 'subject';
	}
}

export default Subject;