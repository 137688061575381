import {
	RESET_STATE,
	LOADING_TODOS,
	TOGGLE_TODO_OVERLAY,
	UPDATE_TASK_FORM,
	UPDATE_ADD_TODOS,
	DELETE_ADD_TODOS,
	UPDATE_FIND_TASK,
	SET_FIND_TODOS,
	SET_CREATED_TASK,
	RESET_STATE_MODALS,
	SET_EDIT_ROW
} from './workplaceTodos.actions';

const defaultState = {
	ui: {
		loading: false,
		showTodoOverlay: false
	},
	data: {
		taskForm: {
			title: '',
			description: ''
		},
		createdTask: {},
		taskTodos: [],
		findTask: {},
		editRow: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case LOADING_TODOS: {
		return { ...state, ui: { ...state.ui, loading: payload } };
	}

	case TOGGLE_TODO_OVERLAY: {
		return { ...state, ui: { ...state.ui, showTodoOverlay: payload } };
	}

	case RESET_STATE: {
		return defaultState;
	}

	case RESET_STATE_MODALS: {
		return {
			...state,
			data: {
				taskForm: {
					title: '',
					description: ''
				},
				createdTask: {},
				taskTodos: [],
				findTask: {},
				editRow: {}
			}
		};
	}

	// Add task+todos
	case UPDATE_TASK_FORM: {
		return {
			...state,
			data: {
				...state.data,
				taskForm: {
					...state.data.taskForm,
					...payload
				}
			}
		};
	}

	case UPDATE_ADD_TODOS: {
		const updateItem = state.data.taskTodos.filter(
			x => x.identifier === payload.identifier
		);

		return {
			...state,
			data: {
				...state.data,
				taskTodos: updateItem.length
					? state.data.taskTodos.map(todo => {
						if (todo.identifier === payload.identifier) {
							return payload;
						}
						return todo;
						  })
					: [...state.data.taskTodos, ...[payload]]
			}
		};
	}

	case DELETE_ADD_TODOS: {
		const taskTodos = state.data.taskTodos.filter(
			x => x.identifier !== payload
		);
		return {
			...state,
			data: {
				...state.data,
				taskTodos
			}
		};
	}

	case UPDATE_FIND_TASK: {
		return {
			...state,
			data: {
				...state.data,
				findTask: payload
			}
		};
	}

	case SET_FIND_TODOS: {
		return {
			...state,
			data: {
				...state.data,
				taskTodos: payload
			}
		};
	}

	case SET_CREATED_TASK: {
		return {
			...state,
			data: {
				...state.data,
				createdTask: payload
			}
		};
	}

	case SET_EDIT_ROW: {
		return {
			...state,
			data: {
				...state.data,
				editRow: payload
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
