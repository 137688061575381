export default {
	TABLE_TITLE: 'Product bundle sets product variants',

	PRODUCT_BUNDLE_SET_PRODUCT_VARIANT_ADDED:
		'Product bundle set product variant added!',
	PRODUCT_BUNDLE_SET_PRODUCT_VARIANT_EDITED:
		'Product bundle set product variant edited!',
	PRODUCT_BUNDLE_SET_PRODUCT_VARIANTS_EDITED:
		'Product bundle set product variants edited!',
	PRODUCT_BUNDLE_SET_PRODUCT_VARIANT_DELETED:
		'Product bundle set product variant deleted!',

	MODAL_BATCH_STEP_FORM_PRODUCT_VARIANTS_TABLE_TITLE: 'Product variants',
	MODAL_BATCH_STEP_FORM_TITLE: 'Select Product variants',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'next',
	MODAL_BATCH_STEP_LIST_TITLE: 'Confirm entries',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'submit',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'back',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_PRODUCT_VARIANT: 'Product variant',
	MODAL_BATCH_STEP_PRODUCT_VARIANT_PLACEHOLDER: 'Select product variant...'
};
