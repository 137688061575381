export const UPDATE_MARKET_SHIFT_RULE =
	'ORGANISATION_MARKET_SHIFT_RULES/UPDATE_MARKET_SHIFT_RULE';
export const RESET_STATE = 'ORGANISATION_MARKET_SHIFT_RULES/RESET_STATE';

export function updateMarketShiftRule(payload) {
	return {
		type: UPDATE_MARKET_SHIFT_RULE,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}
