'use strict';
import { get } from 'api.vanilla.service';

/**
 * @function loadItem
 * @memberOf SERVICES.__models
 * @description
 * Load item from API endpoint. Formats item before resolving.
 * @param  {string} endpoint  endpoint to get
 * @param  {number} id  id of item to load
 * @param  {object} params  params to get endpoint with
 */

import _has from 'lodash/has';

module.exports = function (endpoint, id, params) {
	return new Promise((resolve, reject) => {
		get(endpoint + id, params, undefined, undefined).then(
			function (response) {
				var formattedItem = _has(response, 'data')
					? this.itemFormat(response.data[0])
					: {};

				// Resolve formatted item
				resolve(formattedItem);
			}.bind(this)
		);
	});
};
