import React, { Component } from 'react';

import * as Sentry from '@sentry/browser';

import './errorBoundary.css';

export default class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, info) {
		// Display fallback UI
		this.setState({ hasError: true });

		Sentry.withScope((scope) => {
			scope.setExtra('info', info);
			scope.setExtra('error', error);
			Sentry.captureException(error);
			Sentry.captureMessage('Error in ErrorBoundary component', 'error');
		});
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className="error-boundary">
					<h3 className="error-boundary__item error-boundary--header">{this.props.errorHeader}</h3>
					<h4 className="error-boundary__item error-boundary--sub-header">{this.props.errorSubheader}</h4>
					<p className="error-boundary__item error-boundary__item--text">
						The error has been logged an we will look into it
					</p>
				</div>
			);
		}
		return this.props.children;
	}
}
