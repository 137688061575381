// API stuff
import { get, remove, post } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

// Interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';

// Phrases
import phrases from '../moneyballSubPositions.phrases';

export const fetchMoneyballSubPosition = (
	state: IFetchDataProps,
	moneyballPositionId: string | undefined
): Promise<IApiRes> => {
	let filter = `:moneyball_position.id=='${moneyballPositionId}'`;
	if (state.filter)
		filter = `:moneyball_position.id=='${moneyballPositionId}';${state.filter}`;

	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get('/hr/moneyball_sub_positions', params, null, state.headers)
		.then((response: IApiRes) => {
			return response;
		})
		.catch((error: Error) => {
			const errorMessage = formatErrorMessage(error);
			setFeedback(errorMessage, 0);
			throw error;
		});
};

export const deleteMoneyballSubPosition = (id: number): Promise<IApiRes> => {
	return remove(`/hr/moneyball_sub_positions/${id}`)
		.then((response: IApiRes) => {
			setFeedback(phrases.MONEYBALL_SUB_POSITIONS_REMOVED, 1);
			return response;
		})
		.catch((error: Error) => {
			const errorMessage = formatErrorMessage(error);
			setFeedback(errorMessage, 0);
		});
};

export const createMoneyballSubPosition = (
	payload: IHrMoneyballSubPositions
): Promise<IApiRes> => {
	return post('/hr/moneyball_sub_positions', payload)
		.then((response: IApiRes) => {
			setFeedback(phrases.NEW_MONEYBALL_SUB_POSITION_CREATED, 1);
			return response;
		})
		.catch((error: Error) => {
			const errorMessage = formatErrorMessage(error);
			setFeedback(errorMessage, 0);
		});
};

export const updateMoneyballSubPosition = (
	id: number,
	payload: IHrMoneyballSubPositions
): Promise<IApiRes> => {
	return post(`/hr/moneyball_sub_positions/${id}`, payload)
		.then((response: IApiRes) => {
			setFeedback(phrases.UPDATED_MONEYBALL_SUB_POSITION, 1);
			return response;
		})
		.catch((error: Error) => {
			const errorMessage = formatErrorMessage(error);
			setFeedback(errorMessage, 0);
		});
};
