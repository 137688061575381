'use strict';

import React, { useState } from 'react';

// interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';
import {
	IMarketResponsibilityCrud,
	IMarketResponsibility,
	IMarketResponsibilityEdit,
} from './types';
import { SelectOption } from 'types/select';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';

// components
import { Button, Icon } from 'dumb';
import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import MarketResponsiblitiesEditableCells from './components/marketResponsibilitiesEditableCells/marketResponsibilitiesEditableCells';
import MarketResponsibilitiesModalBatch from './components/batch/marketResponsibilitiesModalBatch';
// import
import getColumns from 'reactDataWrapperColumns/administration/marketResponsibilities.columns';

// mui
import { Tooltip, Box } from '@mui/material';

import {
	fetchMarketResponsibilities,
	deleteMarketResponsibilities,
	editMarketResponsibilities,
} from './marketResponsibilities.service';

import {
	setMarketResponsiblity,
	updateMarketResponsiblity,
	resetMarketResponsiblity,
} from './store/marketResponsibilities.actions';

// constants/phrases/tools
import phrases from './marketResponsibilities.phrases';
import getEditedValues from 'services/utils/getEditedValues';

const reduxKey = '/administration/market_responsibilities';

type Props = {
	employmentId: number;
};
const MarketResponsiblities = ({ employmentId }: Props): JSX.Element => {
	// hooks
	const {
		tableKey,
		customFilters,
		marketResponsibility,
		defaultMarketResponsibility,
	} = useAppSelector((state) => ({
		customFilters: state.filterSortColumnsData.tables[reduxKey]?.custom ?? {},
		tableKey: state.marketResponsibilities.tableKey,
		marketResponsibility:
			state.marketResponsibilities.data.marketResponsibility,
		defaultMarketResponsibility:
			state.marketResponsibilities.data.defaultMarketResponsibility,
	}));
	const dispatch = useAppDispatch();
	const [showBatchModal, setShowBatchModal] = useState(false);

	const editEntry = () => {
		if (!marketResponsibility.id || !defaultMarketResponsibility)
			return Promise.resolve(true);

		const editedValues = getEditedValues({
			oldData: defaultMarketResponsibility,
			newData: marketResponsibility,
		});

		const payload: Partial<IMarketResponsibilityCrud> = {
			location: editedValues?.location?.id,
			...((editedValues?.from || editedValues?.to) && {
				period: {
					from: editedValues.from || marketResponsibility.from,
					to: editedValues.to || marketResponsibility.to,
				},
			}),
		};

		return editMarketResponsibilities(marketResponsibility.id, payload);
	};

	const deleteEntry = (id: number) => {
		return deleteMarketResponsibilities(id);
	};

	const setInitialEditValues = (
		data: IAdministrationMarketResponsibilities
	) => {
		const payload: IMarketResponsibilityEdit = {
			id: data.id,
			role: data.role.name,
			location: data.location.name,
			from: data.period.from,
			to: data.period.to,
		};

		dispatch(setMarketResponsiblity(payload));
	};

	const editStoreEntry = (
		name: keyof IMarketResponsibility,
		value: string | SelectOption | null
	): void => {
		const payload = {
			[name]: value,
		};

		dispatch(updateMarketResponsiblity(payload));
	};

	const getExtraFilters = (): string => {
		const locationFilter = customFilters?.location
			? `:location.id=='${(customFilters.location as SelectOption).value}'`
			: '';

		let filter = '';
		if (locationFilter)
			filter = filter ? `${filter};${locationFilter}` : locationFilter;

		return filter;
	};

	const toggleShowBatchModal = () => setShowBatchModal(!showBatchModal);

	return (
		<>
			<ReactDataWrapper
				dataCy="market-responsibilities-table"
				key={tableKey}
				title={phrases.TABLE_TITLE}
				columns={getColumns(reduxKey)}
				fetchData={(
					state: IFetchDataProps
				): ReturnType<typeof fetchMarketResponsibilities> =>
					fetchMarketResponsibilities(state, employmentId)
				}
				filterable
				defaultPageSize={10}
				reduxKey={reduxKey}
				manual
				accessAreasAllowedToEdit={['Employment Admin', 'Person Admin']}
				editableCells={MarketResponsiblitiesEditableCells({
					marketResponsibility,
					editStoreEntry,
				})}
				actionsWidth={60}
				editEntry={editEntry}
				deleteEntry={deleteEntry}
				setInitialEditValues={setInitialEditValues}
				onModalClose={() => dispatch(resetMarketResponsiblity())}
				compact
				extraFilters={getExtraFilters()}
				onModalCloseConfirm
				enableMultiSelection={false}
				enableSingleSelection={true}
				actionRender={
					<Tooltip title={phrases.BATCH_ADD}>
						<Box ml={1.5}>
							<Button
								label="Add"
								shadow
								type="inverted"
								onClick={toggleShowBatchModal}
								dataCy="market-responsibilities-table-batch-add"
							>
								<Icon name="add" />
							</Button>
						</Box>
					</Tooltip>
				}
			/>

			{showBatchModal && (
				<MarketResponsibilitiesModalBatch
					onClose={toggleShowBatchModal}
					employmentId={employmentId}
				/>
			)}
		</>
	);
};

export default MarketResponsiblities;
