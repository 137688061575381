import hotkeys from 'hotkeys-js';
/**
 *
 * @module HotkeysService
 * @class HotkeysService
 * @description Wrapper for hotkeys-js (https://wangchujiang.com/hotkeys/)
 */
class HotkeysService {
	/**
	 * Creates an instance of HotkeysService.s
	 * @memberof HotkeysService
	 */
	constructor() {
		this.options = {
			element: document.getElementById('WP2')
		};

		this.boundKeys = [];
	}

	/**
	 * @method defineHotkeys
	 * @description wrapper for hotkeys method
	 * @param {string} [keys='']
	 * @param {object} [options={}]
	 * @param {function} callback
	 * @memberof HotkeysService
	 */

	defineHotkeys({ keys = '', options = {}, callback }) {
		this.boundKeys.push(keys);

		hotkeys(
			keys,
			{
				...this.options,
				options
			},
			callback
		);
	}

	/**
	 * @method setScope
	 * @description set new scope.  There can only be one active scope besides 'all'. By default 'all' is always active.
	 * @param {*} scope
	 * @returns {bool}
	 * @memberof HotkeysService
	 */
	setScope(scope) {
		return hotkeys.setScope(scope);
	}

	/**
	 * @method getScope
	 * @returns current scope
	 * @memberof HotkeysService
	 */
	getScope() {
		return hotkeys.getScope();
	}

	/**
	 * @method deleteScope
	 * @returns deletes scope and removes all hotkeys associated with it
	 * @memberof HotkeysService
	 */
	deleteScope(scope) {
		return hotkeys.deleteScope(scope);
	}

	/**
	 * @method unbind
	 * @description Similar to defining shortcuts, they can be unbound using hotkeys.unbind.
	 * @memberof HotkeysService
	 */
	unbind(keys = '', scope, functionToUnbind) {
		this.boundKeys.push(keys);
		hotkeys.unbind(keys, scope, functionToUnbind);
	}

	/**
	 * @method isPressed
	 * @description For example, hotkeys.isPressed(77) is true if the M key is currently pressed.
	 * @returns {bool} is key pressed or not
	 * @memberof HotkeysService
	 */
	isPressed(key) {
		return hotkeys.isPressed(key);
	}
}

export default new HotkeysService();
