'use strict';

class SessionStorage {
	constructor() {
		this._prefix = 'joeJuiceSessionStorage';
	}

	/**
	 * @method setItem
	 * @param {String} key - name of the page to save the tips visibility for
	 * @param {Object} value - value of the visibility
	 * @description sets an item in session storage
	 */
	setItem(key, value) {
		sessionStorage.setItem(`${this._prefix}-${key}`, value);
	}

	/**
	 * @method getItem
	 * @param {String} key - name of the key
	 * @description gets a value from session storage saved under the key
	 */
	getItem(key) {
		return sessionStorage.getItem(`${this._prefix}-${key}`);
	}

	/**
	 * @method removeItem
	 * @param {String} key - name of the key
	 * @description removes an item from session storage saved under the key
	 */
	removeItem(key) {
		sessionStorage.removeItem(`${this._prefix}-${key}`);
	}

	/**
	 * @method removeAll
	 * @description purges all from session storage
	 */
	removeAll() {
		sessionStorage.clear();
	}
}

export default SessionStorage;
