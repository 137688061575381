'use strict';

import { get } from 'api.vanilla.service';
import HotbarDataProviderAbstract from './hotbar.dataProvider.abstract';
import _debouncedPromise from 'debounce-promise';

class HotbarEndpointDataProvider extends HotbarDataProviderAbstract {
	constructor(endpoint, parameters = null, optionFormat = null, sortOrder = null, limit = 200) {
		super();

		// Set new collection
		this.setEndpoint(endpoint);

		// Set parameters
		this.parameters = parameters;

		this.limit = limit;

		this.typingFilterParameter = null;

		this.filter = null;

		this.abortController = undefined;

		// Set option format
		this.setOptionFormat(optionFormat === null ? (entry) => ({ value: entry.id, label: entry.name }) : optionFormat);

		// Set sortOrder
		this.setSortOrder(sortOrder);

		this.getDataAsOptions = _debouncedPromise(this.getDataAsOptions, 300, {
			leading: true,
			accumulate: false,
		}).bind(this);
	}

	/**
	 * @function setFilter
	 * @memberOf HotbarEndpointDataProvider
	 * @description Sets filter
	 */
	setFilter(filter) {
		this.filter = filter;
	}

	/**
	 * @function callback
	 * @memberOf HotbarEndpointDataProvider
	 * @description Set callback function to be called after api response is resolved
	 */
	setCallback(callback) {
		this.callback = callback;
	}

	/**
	 * @function setSortOrder
	 * @memberOf HotbarEndpointDataProvider
	 * @description Sets sorting order for current collection
	 * @param {object} sortOrder
	 */
	setSortOrder(sortOrder) {
		this.sortOrder = sortOrder;
	}

	/**
	 * @function setTypingFilterParameter
	 * @memberof HotbarEndpointDataProvider
	 * @description Sets sorting order for current collection
	 * @param {object} sortOrder
	 */
	setTypingFilterParameter(typingFilterParameter) {
		this.typingFilterParameter = typingFilterParameter;
	}

	/**
	 * @function setOptionFormat
	 * @memberOf HotbarEndpointDataProvider
	 * @description Setter for optionFormat
	 */
	setOptionFormat(optionFormat) {
		this.optionFormat = optionFormat;
	}

	/**
	 * @function setEndpoint
	 * @memberOf HotbarEndpointDataProvider
	 * @description Adds component to content array
	 * @param {string} endpoint
	 */
	setEndpoint(endpoint) {
		this.endpoint = endpoint;
	}

	/**
	 * @function getFilterFromParams
	 * @memberOf HotbarEndpointDataProvider
	 * @description Gets filter based on parameter values
	 */
	getFilterFromParams(value = null) {
		if (Object.keys(this.parameterValues).length === 0 && !value) return null;

		let filter = Object.keys(this.parameterValues).reduce((acc, paramName) => {
			const values = this.getParameter(paramName);

			if (values !== null)
				acc = values
					.map((value, index, arr) =>
						index === arr.length - 1
							? `:${this.parameters[paramName]}=='${value.value}'`
							: `:${this.parameters[paramName]}=='${value.value}',`
					)
					.join('');

			return acc;
		}, '');

		if (this.typingFilterParameter && value && filter)
			filter = `${filter};:${this.typingFilterParameter}=like='%${value}%'`;
		else if (this.typingFilterParameter && value) filter = `:${this.typingFilterParameter}=like='%${value}%'`;

		if (this.filter) filter = filter ? `${filter};${this.filter}` : this.filter;

		return filter;
	}

	/**
	 * @function getSortOrder
	 * @memberOf HotbarEndpointDataProvider
	 * @description Gets sorting order for current collection
	 */
	getSortOrder() {
		return this.sortOrder;
	}

	/**
	 * @function getOptionFormat
	 * @memberOf HotbarEndpointDataProvider
	 * @description Getter for optionFormat
	 */
	getOptionFormat() {
		return this.optionFormat;
	}

	getData(value) {
		if (this.abortController !== undefined) {
			// Cancel the previous request
			this.abortController.abort();
		}

		// Feature detect
		if ('AbortController' in window) {
			this.abortController = new AbortController();
			this.signal = this.abortController.signal;
		}

		const params = {
			filter: this.getFilterFromParams(value),
			limit: this.limit,
			sort: this.getSortOrder(),
		};

		return get(this.endpoint, params, undefined, undefined, this.signal)
			.then((response) => {
				if (this.callback) this.callback(response);
				return response;
			})
			.finally(() => {
				this.abortController = undefined;
				this.signal = undefined;
			});
	}

	/**
	 * @function getDataAsOptions
	 * @memberOf HotbarEndpointDataProvider
	 * @description Gets data formatted as options [{value, label}, ...]
	 */
	getDataAsOptions(value) {
		return this.getData(value).then((response) => {
			const optionFormatter = this.getOptionFormat();

			return response.data.map((entry) => optionFormatter(entry)).filter((i) => i);
		});
	}
}

export default HotbarEndpointDataProvider;
