'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, Icon, Legend, Tag } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

import _get from 'lodash/get';
import './listSummary.css';

class ListSummary extends Component {
	_onRemoveEntry(row) {
		this.props.removeBatchListItem(row.identifier);
	}

	_editListEntry(e, identifier, type) {
		const { batchList } = this.props;
		const value = e.target ? e.target.value : e;
		const row = batchList.find((x) => x.identifier === identifier);

		this.props.editBatchListItem({
			...row,
			...{ [type]: value },
		});
	}

	render() {
		const { batchList } = this.props;

		return (
			<>
				<h3 className="modal-batch__subheader">
					Showing {batchList.length} rows
				</h3>
				<div className="modal-batch__legend">
					<Legend
						points={[
							{
								bulletColor: `#ff7476`,
								label:
									'Skipped items, since they already exist in those groups',
							},
						]}
					/>
				</div>
				<TableList>
					<thead>
						<tr>
							<th>#</th>
							<th>Name</th>
							<th>Tile Layout Group</th>
							<th>Product</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((x, i) => {
							const rowDisabled = x.skip;
							return (
								<TableListRow key={x.identifier} type={rowDisabled && 'light'}>
									<TableListCell>
										{rowDisabled && <Tag type="bullet" color="ff7476" />}
										{i + 1}
									</TableListCell>
									<TableListCell>
										<Input
											id={`${_get(x, 'id', '')}-${i}`}
											value={_get(x, 'name', '')}
											placeholder="Name"
											disabled={rowDisabled}
											onChange={(event) =>
												this._editListEntry(event, x.identifier, 'name')
											}
										/>
									</TableListCell>
									<TableListCell>
										{_get(x, 'tile_layout_group.name', '')}
									</TableListCell>
									<TableListCell>{_get(x, 'product.name', '')}</TableListCell>
									<TableListCell>
										<div className="modal-batch__button">
											<Button
												type="negative"
												size="tiny"
												disabled={rowDisabled}
												onClick={() => this._onRemoveEntry(x)}>
												<Icon name="delete" />
											</Button>
										</div>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
			</>
		);
	}
}

ListSummary.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

ListSummary.defaultProps = {};

export default ListSummary;
