import {
	IEodControlInitialState,
	IEODDailySummations,
	IEODAudits,
	IEODCorrections,
	IEndOfDaySessions,
	IHotbar,
} from 'types/eodControl';
import { ActionType } from 'types/redux';
import moment from 'moment';

import {
	RESET_DATA,
	SET_LOADING,
	UPDATE_ROW,
	TOGGLE_AUDIT_MODAL,
	SET_AUDIT_MODAL_DATA,
	SET_AUDIT_MODAL_EOD_AUDITS,
	SET_AUDIT_MODAL_EOD_CORRECTIONS,
	SET_AUDIT_MODAL_EOD_SESSIONS,
	RESET_AUDIT_MODAL_DATA,
	SET_AUDIT_MODAL_LOADING,
	REMOVE_AUDIT_MODAL_EOD_SESSION,
	RE_RENDER_TABLE,
	SET_EOD_SESSION_TO_EDIT,
	UPDATE_AUDIT_MODAL_EOD_SESSION,
	SET_EOD_SESSIONS_TO_SPLIT,
	SET_HOTBAR_FILTER_PARAMS,
	SET_DATA_RECURSIVELY,
} from './eodControl.actions';

const initialState: IEodControlInitialState = {
	data: [],
	loading: false,
	tableKey: moment().unix(),
	filterFromHotbar: undefined,
	paramsFromHotbar: undefined,
	auditModal: {
		loading: false,
		shown: false,
		eodSummation: undefined,
		eodAudits: [],
		eodCorrections: [],
		eodSessions: [],
		eodSessionToEdit: undefined,
		eodSessionsToSplit: [],
	},
};

function reducer(state = initialState, action: ActionType): IEodControlInitialState {
	const { type, payload } = action;

	switch (type) {
	case RESET_DATA:
		return {
				...state,
				data: initialState.data,
			};

	case SET_DATA_RECURSIVELY:
		return {
				...state,
				data: [...state.data, ...(payload as IEODDailySummations[])],
			};

	case SET_LOADING:
		return {
				...state,
				loading: payload as boolean,
			};

	case UPDATE_ROW: {
		const castedPayload = payload as IEODDailySummations;

		return {
				...state,
				data: state.data.map((item: IEODDailySummations) => {
					if (item.date === castedPayload.date && item.workplace.id === castedPayload.workplace.id)
						return castedPayload;

					return item;
				}),
			};
	}

	case TOGGLE_AUDIT_MODAL:
		return {
				...state,
				auditModal: {
					...state.auditModal,
					shown: !state.auditModal?.shown,
				},
			};

	case SET_AUDIT_MODAL_DATA: {
		const castedPayload = payload as IEODDailySummations;

		return {
				...state,
				auditModal: {
					...state.auditModal,
					eodSummation: castedPayload,
				},
			};
	}

	case SET_AUDIT_MODAL_EOD_AUDITS: {
		const castedPayload = payload as IEODAudits[];

		return {
				...state,
				auditModal: {
					...state.auditModal,
					eodAudits: castedPayload,
				},
			};
	}

	case SET_AUDIT_MODAL_EOD_CORRECTIONS: {
		const castedPayload = payload as IEODCorrections[];

		return {
				...state,
				auditModal: {
					...state.auditModal,
					eodCorrections: castedPayload,
				},
			};
	}

	case SET_AUDIT_MODAL_EOD_SESSIONS: {
		const castedPayload = payload as IEndOfDaySessions[];

		return {
				...state,
				auditModal: {
					...state.auditModal,
					eodSessions: castedPayload,
				},
			};
	}

	case RESET_AUDIT_MODAL_DATA:
		return {
				...state,
				auditModal: initialState.auditModal,
			};

	case SET_AUDIT_MODAL_LOADING:
		return {
				...state,
				auditModal: {
					...state.auditModal,
					loading: payload as boolean,
				},
			};

	case REMOVE_AUDIT_MODAL_EOD_SESSION:
		return {
				...state,
				auditModal: {
					...state.auditModal,
					eodSessions: state.auditModal.eodSessions.filter(
						(session: IEndOfDaySessions) => session.id !== (payload as number)
					),
				},
			};

	case RE_RENDER_TABLE:
		return {
				...state,
				tableKey: moment().unix(),
			};

	case SET_EOD_SESSION_TO_EDIT:
		return {
				...state,
				auditModal: {
					...state.auditModal,
					eodSessionToEdit: payload as IEndOfDaySessions | undefined,
				},
			};

	case UPDATE_AUDIT_MODAL_EOD_SESSION: {
		const castedPayload = payload as IEndOfDaySessions;

		return {
				...state,
				auditModal: {
					...state.auditModal,
					eodSessions: state.auditModal.eodSessions.map((session: IEndOfDaySessions) => {
						if (session.id === castedPayload.id) return castedPayload;

						return session;
					}),
				},
			};
	}

	case SET_EOD_SESSIONS_TO_SPLIT:
		return {
				...state,
				auditModal: {
					...state.auditModal,
					eodSessionsToSplit: payload as IEndOfDaySessions[],
				},
			};

	case SET_HOTBAR_FILTER_PARAMS: {
		const castedPayload = payload as IHotbar;

		return {
				...state,
				filterFromHotbar: castedPayload.filter,
				paramsFromHotbar: castedPayload.params,
			};
	}

	default:
		return state;
	}
}

export default reducer;
