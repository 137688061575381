import {
	SET_LIST_DATA,
	INITIALIZE_REDUCER,
	INITIALIZE_DATA,
	SET_LIST_SEARCH_FILTER,
	SET_LIST_SORTING,
	REMOVE_ROW_FROM_SELECTION,
	ADD_ROW_TO_SELECTION,
	SET_LAST_ROW_SELECTED,
	TOGGLE_ROW,
	SHOW_EDIT_MODAL,
	SET_LIST_META_DATA,
	RESET_SELECTION,
	REMOVE_ENTRY,
	EDIT_ENTRY,
	ADD_ENTRY,
	SET_PAGE_NUMBER,
	SET_TOTAL_PAGE_NUMBER,
	ON_EXPAND_CHANGE,
	SET_EXPANDED_TO_DEFAULT,
} from './reactDataWrapper.actions';

import _uniqBy from 'lodash/uniqBy';

const defaultState = {
	ui: {
		loadingReport: false,
		page: 0,
		pages: null,
		totalEntries: null,
		pageSize: 10,
		selectedRows: [],
		showEditModal: false,
		expanded: {},
	},
	data: {
		listData: [],
		listSearchFilter: [],
		listSorting: [],
	},
};

function reducer(state = {}, action = {}) {
	const { type, payload } = action;
	switch (type) {
		case INITIALIZE_DATA: {
			const key = payload.reduxKey;

			return {
				...state,
				[key]: {
					...state[key],
					data: defaultState.data,
				},
			};
		}

		case INITIALIZE_REDUCER: {
			const key = payload.reduxKey;
			return {
				...state,
				[key]: defaultState,
			};
		}

		case SET_LIST_DATA: {
			const key = payload.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					data: {
						...state[key].data,
						listData: payload.listData,
					},
				},
			};
		}

		case EDIT_ENTRY: {
			const key = payload.reduxKey;
			const entry = payload.entry;
			const editedList = state[key].data.listData.map((row) => {
				if (entry.id === row.id) return entry;
				return row;
			});

			return {
				...state,
				[key]: {
					...state[key],
					data: {
						...state[key].data,
						listData: editedList,
					},
				},
			};
		}

		case ADD_ENTRY: {
			const key = payload.reduxKey;
			const entry = payload.entry;

			return Object.assign({}, state, {
				[key]: {
					...state[key],
					data: {
						...state[key].data,
						listData: _uniqBy([...state[key].data.listData, entry], 'id'),
					},
				},
			});
		}

		case REMOVE_ENTRY: {
			const key = payload.reduxKey;
			const id = payload.id;

			return {
				...state,
				[key]: {
					...state[key],
					data: {
						...state[key].data,
						listData: state[key].data.listData.filter((entry) => {
							return entry.id !== id;
						}),
					},
				},
			};
		}

		case SET_LIST_SEARCH_FILTER: {
			const key = payload.reduxKey;

			return {
				...state,
				[key]: {
					...state[key],
					data: {
						...state[key].data,
						listSearchFilter: payload.listSearchFilter,
					},
				},
			};
		}

		case SET_LIST_SORTING: {
			const key = payload.reduxKey;

			return {
				...state,
				[key]: {
					...state[key],
					data: {
						...state[key].data,
						listSorting: payload.listSorting,
						// filter: _uniqBy([...state[key].data.filter, ...payload.filter], 'id'),
					},
				},
			};
		}

		case REMOVE_ROW_FROM_SELECTION: {
			const key = payload.reduxKey;

			let selectedRows = state[key].ui.selectedRows;

			selectedRows = selectedRows.filter((x) => x.id !== payload.data.id);

			return {
				...state,
				[key]: {
					...state[key],
					ui: {
						...state[key].ui,
						selectedRows: _uniqBy([...selectedRows], 'id'),
					},
				},
			};
		}

		case ADD_ROW_TO_SELECTION: {
			const key = payload.reduxKey;

			return {
				...state,
				[key]: {
					...state[key],
					ui: {
						...state[key].ui,
						selectedRows: _uniqBy([...state[key].ui.selectedRows, payload.data], 'id'),
					},
				},
			};
		}

		case SET_LAST_ROW_SELECTED: {
			const key = payload.reduxKey;

			return {
				...state,
				[key]: {
					...state[key],
					ui: {
						...state[key].ui,
						lastSelectedRow: payload.data,
					},
				},
			};
		}

		case TOGGLE_ROW: {
			const key = payload.reduxKey;

			let selectedRows = state[key].ui.selectedRows;

			if (selectedRows.some((x) => x.id === payload.data.id)) {
				selectedRows = selectedRows.filter((x) => x.id !== payload.data.id);
			} else {
				selectedRows = [...selectedRows, payload.data];
			}

			return {
				...state,
				[key]: {
					...state[key],
					ui: {
						...state[key].ui,
						selectedRows: _uniqBy([...selectedRows], 'id'),
					},
				},
			};
		}

		case RESET_SELECTION: {
			const key = payload.reduxKey;

			return {
				...state,
				[key]: {
					...state[key],
					ui: {
						...state[key].ui,
						selectedRows: [],
					},
				},
			};
		}

		case SHOW_EDIT_MODAL: {
			const key = payload.reduxKey;

			return {
				...state,
				[key]: {
					...state[key],
					ui: {
						...state[key].ui,
						showEditModal: payload.showEditModal,
					},
				},
			};
		}

		case SET_LIST_META_DATA: {
			const key = payload.reduxKey;

			return {
				...state,
				[key]: {
					...state[key],
					ui: {
						...state[key].ui,
						page: payload.page,
						pages: payload.pages,
						totalEntries: payload.totalEntries,
						pageSize: payload.pageSize,
					},
				},
			};
		}

		case SET_PAGE_NUMBER: {
			const key = payload.reduxKey;

			return {
				...state,
				[key]: {
					...state[key],
					ui: {
						...state[key].ui,
						page: payload.page,
					},
				},
			};
		}

		case SET_TOTAL_PAGE_NUMBER: {
			const key = payload.reduxKey;

			return {
				...state,
				[key]: {
					...state[key],
					ui: {
						...state[key].ui,
						pages: payload.pages,
					},
				},
			};
		}
		case ON_EXPAND_CHANGE: {
			const key = payload.reduxKey;

			return Object.assign({}, state, {
				[key]: {
					...state[key],
					ui: {
						...state[key].ui,
						expanded: state[key].ui.expanded
							? {
									...state[key].ui.expanded,
									[payload.index]: !state[key].ui.expanded[payload.index],
							  }
							: {
									[payload.index]: true,
							  },
					},
				},
			});
		}

		case SET_EXPANDED_TO_DEFAULT: {
			const key = payload.reduxKey;

			return Object.assign({}, state, {
				[key]: {
					...state[key],
					ui: {
						...state[key].ui,
						expanded: defaultState.ui.expanded,
					},
				},
			});
		}

		default:
			return state;
	}
}

export default reducer;
