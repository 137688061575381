'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, Icon } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';
import { Tip } from 'dumb/tips';

// phrases
import phrases from './../../../posConfigurationProductVariantsPricing.phrases';

class NominalPercentageStepList extends Component {
	constructor(props) {
		super(props);

		this.noPosConfigurationProductVariantBulletColor = 'ff7476';
		this.posConfigurationDiscountProductVariantExistsBulletColor = 'ffe699';
	}

	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e.target ? e.target.value : e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: value },
		});
	}

	render() {
		const { batchList } = this.props;

		return (
			<>
				<div className="product-variant-pricings__tip-wrapper">
					<Tip content={phrases.MODAL_BATCH_STEP_LIST_PERCENTAGE_TIP} />
				</div>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_LIST_POS_CONFIGURATION_NAME}</th>
							<th>
								{phrases.MODAL_BATCH_STEP_LIST_POS_CONFIGURATION_VERSION_NAME}
							</th>
							<th>{phrases.MODAL_BATCH_STEP_LIST_PRODUCT_VARIANT}</th>

							<th>{phrases.MODAL_BATCH_STEP_LIST_TO_STAY_PRICE}</th>

							<th>{phrases.MODAL_BATCH_STEP_LIST_TO_STAY_PRICE_NEW}</th>

							<th>{phrases.MODAL_BATCH_STEP_LIST_TO_GO_PRICE}</th>

							<th>{phrases.MODAL_BATCH_STEP_LIST_TO_GO_PRICE_NEW}</th>

							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((entry, i) => {
							const deleteButtonDisabled = batchList.length === 1;

							return (
								<TableListRow key={entry.identifier}>
									<TableListCell>{i + 1}</TableListCell>
									<TableListCell>{entry.posConfigurationName}</TableListCell>
									<TableListCell>
										{entry.posConfigurationVersionName}
									</TableListCell>
									<TableListCell>{entry.productVariantName}</TableListCell>

									<TableListCell>{entry.toStayPrice}</TableListCell>

									<TableListCell>
										<Input
											id={`${entry.identifier}-toStayPriceNew`}
											type="number"
											value={entry.toStayPriceNew}
											placeholder="New to stay Price"
											onChange={(event) =>
												this._editListEntry(event, entry, 'toStayPriceNew')
											}
											required
										/>
									</TableListCell>

									<TableListCell>{entry.toGoPrice}</TableListCell>

									<TableListCell>
										<Input
											id={`${entry.identifier}-toGoPriceNew`}
											type="number"
											value={entry.toGoPriceNew}
											placeholder="New to go Price"
											onChange={(event) =>
												this._editListEntry(event, entry, 'toGoPriceNew')
											}
											required
										/>
									</TableListCell>

									<TableListCell>
										<Button
											type="negative"
											size="tiny"
											disabled={deleteButtonDisabled}
											onClick={() => this._onRemoveEntry(entry.identifier)}>
											<Icon name="delete" />
										</Button>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
			</>
		);
	}
}

NominalPercentageStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

export default NominalPercentageStepList;
