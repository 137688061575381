import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './languages.phrases';

/**
 * @function fetchLanguages
 * @description Fetch all languages
 * @param {object} state
 */
export function fetchLanguages(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/administration/languages', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addLanguages
 * @description add languages
 */
export function addLanguages(data) {
	return post('/administration/languages', data)
		.then((res) => {
			setFeedback(phrases.LANGUAGES_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editLanguage
 * @description edit language
 */
export function editLanguage(data) {
	return post(`/administration/languages/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.LANGUAGE_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editLanguages
 * @description edit languages
 */
export function editLanguages(data) {
	return patch('/administration/languages', data)
		.then((res) => {
			setFeedback(phrases.LANGUAGES_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteLanguages
 * @description delete language
 */
export function deleteLanguages(id) {
	return remove(`/administration/languages/${id}`)
		.then((res) => {
			setFeedback(phrases.LANGUAGES_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
