import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchIngredientAllergenes
 * @description Fetch all ingredient allergenes values
 * @param {object} state
 */
export function fetchIngredientAllergenes(state, filter) {
	const newFilter = state.filter ? `${state.filter};${filter}` : filter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: newFilter,
	};

	return get('/product/ingredient_allergens', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editIngredientAllergenes
 * @description edit
 */
export function editIngredientAllergenes(nutritionId, data) {
	return post(`/product/ingredient_allergens/${nutritionId}`, data)
		.then((res) => {
			setFeedback('Ingredient allergene edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function addIngredientAllergenes
 * @description add
 */
export function addIngredientAllergenes(data) {
	return post(`/product/ingredient_allergens`, data)
		.then((res) => {
			setFeedback('Ingredient allergene added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteIngredientAllergenes
 * @description del
 */
export function deleteIngredientAllergenes(nutritionId) {
	return remove(`/product/ingredient_allergens/${nutritionId}`)
		.then((res) => {
			setFeedback('Ingredient allergene deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
