import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';

import GlobalAccountsList from './services/globalAccountsList.service.js';

const globalAccountsListReduxKey = '/accounting/account_schedule_accounts';
const globalAccountsList = new GlobalAccountsList(globalAccountsListReduxKey);
const globalAccountsListProps = globalAccountsList.getProps();

export function GlobalAccounts() {
	useEffect(() => {
		globalAccountsList.initView();
	}, []);

	return <ListComponent reduxKey={globalAccountsListReduxKey} {...globalAccountsListProps} />;
}
