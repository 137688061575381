'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import { Button, Icon, Input } from 'dumb';

import _get from 'lodash/get';

import {
	resetState,
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
} from './store/productVariantDiscountSet.actions';

import {
	fetchProductVariantDiscountSets,
	addProductVariantDiscountSet,
	deleteProductVariantDiscountSet,
	fetchDiscounts,
} from './productVariantDiscountSet.service';

import phrases from './productVariantDiscountSet.phrases';
import moment from 'moment';

// utils
import { markFoundDiscountSets } from './utils';

// components
import ProductVariantDiscountSetModalBatch from './components/batch/productVariantDiscountSetModalBatch';

class ProductVariantDiscountSet extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			rdwTimestamp: moment.utc(),
		};

		this.deleteEntry = this.deleteEntry.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.addProductVariantDiscountSetWrapper =
			this.addProductVariantDiscountSetWrapper.bind(this);

		this.columns = [
			{
				Header: 'Discount',
				id: 'discount.name',
				accessor: (d) => _get(d, 'discount.name', null),
				filterPath: ':discount.name',
			},
			{
				Header: 'Set name',
				id: 'name',
				accessor: (d) => _get(d, 'name', null),
				filterPath: ':name',
			},
			{
				Header: 'Product has Discount set',
				id: 'productHasDiscountSet',
				accessor: 'productHasDiscountSet',
				filterable: false,
				sortable: false,
				Cell: (d) => {
					return (
						<Input
							id="product-has-discount-set"
							type="checkbox"
							checked={d.original.productHasDiscountSet}
							disabled
						/>
					);
				},
			},
		];
	}

	deleteEntry(id) {
		const { productVariant } = this.props;

		return deleteProductVariantDiscountSet(id, productVariant);
	}

	fetchData(state) {
		const { productVariant } = this.props;

		const filter = `:discount_product_set.discount_product_set_options.product_variant.id=='${productVariant.id}'`;

		return fetchProductVariantDiscountSets(state).then((res) => {
			return fetchProductVariantDiscountSets(state, filter).then((resTwo) => {
				const markedValues = markFoundDiscountSets({
					filteredDiscountSets: resTwo.data,
					discountSets: res.data,
				});

				return {
					...res,
					data: markedValues,
				};
			});
		});
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	addProductVariantDiscountSetWrapper(payload, data) {
		return addProductVariantDiscountSet(payload, data).then(() => {
			this.setState(() => ({ rdwTimestamp: moment.utc() }));
		});
	}

	render() {
		const {
			batchFormData,
			batchList,
			listDiscounts,
			updateBatchForm,
			removeBatchListItem,
			addBatchList,
			editBatchListItem,
			productVariant,
		} = this.props;

		return (
			<>
				<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
					key={this.state.rdwTimestamp}
					title={phrases.PRODUCT_DISCOUNT_SETS}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={`product-sale/discount_product_set/sub-${productVariant.id}`}
					manual
					onModalClose={this.props.resetState}
					deleteEntry={this.deleteEntry}
					editMultiple={this.editMultiplePrices}
					actionRender={
						<Button
							type="inverted"
							label="Batch"
							shadow
							onClick={() => this.toggleModal()}
						>
							<Icon name="add" />
						</Button>
					}
				/>

				<ProductVariantDiscountSetModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchFormData={batchFormData}
					updateBatchForm={updateBatchForm}
					fetchDiscounts={fetchDiscounts}
					listDiscounts={listDiscounts}
					addBatchList={addBatchList}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addProductVariantDiscountSet={
						this.addProductVariantDiscountSetWrapper
					}
					productVariant={productVariant}
				/>
			</>
		);
	}
}

ProductVariantDiscountSet.propTypes = {
	productVariant: PropTypes.object,
	resetState: PropTypes.func,
	batchFormData: PropTypes.object,
	batchList: PropTypes.array,
	listDiscounts: PropTypes.object,
	updateBatchForm: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchList: PropTypes.func,
	editBatchListItem: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			resetState,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			updateBatchForm,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		discountSetData: store.productVariantDiscountSet.data.discountSetData,
		metaList: store.listData['sale/discount_product_set/sub'],
		batchFormData: store.productVariantDiscountSet.data.batchFormData,
		batchList: store.productVariantDiscountSet.data.batchList,
		listDiscounts: store.listData['/sale/discounts'],
	};
};

export default connectWithStore(
	ProductVariantDiscountSet,
	mapStateToProps,
	mapDispatchToProps
);
