'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Tag, SectionCollapsable, Tooltip } from 'dumb';
import { ModalRow } from 'dumb/modal';
import { Tip } from 'dumb/tips';

// lodash
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';

// enums/phrases
import phrases from './../../../productVariantBasketRecommendations.phrases';
import enums from './../../../productVariantBasketRecommendations.enums';

class AddProductVariantBasketRecommandationsPreview extends Component {
	constructor(props) {
		super(props);

		this.renderSelectedProductVariants = this.renderSelectedProductVariants.bind(
			this
		);
	}

	editStoreEntry(e, type) {
		const { updateBatchForm, batchFormData } = this.props;

		const value = e.target ? e.target.value : e;

		updateBatchForm({
			basketExclusion: {
				...batchFormData.basketExclusion,
				[type]: value,
			},
		});
	}

	editExclusion(value, name) {
		const { batchFormData } = this.props;

		// filter out the same product variant - if already selected
		let exclusions = _get(batchFormData, 'basketExclusion.exclusions', []);
		exclusions = exclusions.filter((entry) => entry.name !== name);

		exclusions = [
			...exclusions,
			...(value
				? [
						{
							name,
							value,
						},
				  ]
				: []),
		];

		this.editStoreEntry(exclusions, 'exclusions');
	}

	_getExclusionValue(id) {
		const { batchFormData } = this.props;

		const productVariantValue = _get(
			batchFormData,
			'basketExclusion.exclusions',
			[]
		).find((entry) => entry.name === `productVariant-${id}`);

		return productVariantValue?.value ?? null;
	}

	_renderExistingGroups(batchFormData, type) {
		const values =
			type === enums.RECOMMENDATION
				? batchFormData.existingBasketRecommendation.value
				: batchFormData.existingBasketExclusion.value;

		// put all keys into an array and filter out all the keys that are not equal to 'group_...'
		const arrayOfKeys = Object.keys(values).filter(
			(entry) => entry.indexOf('group_') !== -1
		);

		return arrayOfKeys
			.map((entry, index) =>
				_get(values, `[${entry}].name`, false) ? (
					<Tooltip
						key={index}
						zIndex={600}
						text={`${
							type === enums.RECOMMENDATION
								? phrases.RECOMMENDATION
								: phrases.EXCLUSION
						} ${index + 1}`}
						renderData={(ref, onMouseEnter, onMouseLeave) => (
							<Tag
								color="ddd"
								refProp={ref}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}>
								{values[entry].name}
							</Tag>
						)}
					/>
				) : null
			)
			.filter((x) => x);
	}

	_renderNewGroups(batchFormData, type) {
		const values =
			type === enums.RECOMMENDATION
				? batchFormData.basketRecommendation.recommendations
				: batchFormData.basketExclusion.exclusions;

		if (_isEmpty(values)) return;

		return values.map((entry, index) => (
			<Tooltip
				key={index}
				zIndex={600}
				text={`${
					type === enums.RECOMMENDATION
						? phrases.RECOMMENDATION
						: phrases.EXCLUSION
				} ${index + 1}`}
				renderData={(ref, onMouseEnter, onMouseLeave) => (
					<Tag
						color="ddd"
						refProp={ref}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}>
						{entry.value.label}
					</Tag>
				)}
			/>
		));
	}

	renderSelectedProductVariants() {
		const { batchFormData } = this.props;

		return (
			<SectionCollapsable title={phrases.SELECTED_PRODUCT_VARIANTS}>
				<div className="add-product-variant-basket-recommendations__column">
					<div className="add-product-variant-basket-recommendations__row__wrap">
						{batchFormData.productVariants.map((entry, index) => (
							<Tag key={index} color="ddd">
								{entry.productVariant?.name}
							</Tag>
						))}
					</div>
				</div>
			</SectionCollapsable>
		);
	}

	render() {
		const { batchFormData } = this.props;

		return (
			<>
				{_isArray(batchFormData.productVariants) &&
					!_isEmpty(batchFormData.productVariants) && (
						<>
							<ModalRow>
								<Tip content={phrases.SELECTED_PRODUCT_VARIANTS_TOOLTIP} />
							</ModalRow>
							<ModalRow>{this.renderSelectedProductVariants()}</ModalRow>
						</>
					)}
				<ModalRow>
					<SectionCollapsable title={phrases.RECOMMENDATION} isOpen>
						<div add-product-variant-basket-recommendations__column>
							<div className="add-product-variant-basket-recommendations__row">
								<span className="add-product-variant-basket-recommendations__label">
									{phrases.NAME}:
								</span>
								<span className="add-product-variant-basket-recommendations__value ">
									{batchFormData.basketRecommendation?.name ??
										batchFormData.existingBasketRecommendation?.label}
								</span>
							</div>
							<div className="add-product-variant-basket-recommendations__column">
								<span className="add-product-variant-basket-recommendations__label">
									{phrases.REPLACEMENTS}:
								</span>
								<div className="add-product-variant-basket-recommendations__row__wrap">
									{batchFormData.basketRecommendation
										? this._renderNewGroups(batchFormData, enums.RECOMMENDATION)
										: this._renderExistingGroups(
												batchFormData,
												enums.RECOMMENDATION
										  )}
								</div>
							</div>
						</div>
					</SectionCollapsable>
				</ModalRow>
				<ModalRow>
					<SectionCollapsable title={phrases.EXCLUSION} isOpen>
						<div add-product-variant-basket-recommendations__column>
							<div className="add-product-variant-basket-recommendations__row">
								<span className="add-product-variant-basket-recommendations__label">
									{phrases.NAME}:
								</span>
								<span className="add-product-variant-basket-recommendations__value ">
									{batchFormData.basketExclusion?.name ??
										batchFormData.existingBasketExclusion?.label}
								</span>
							</div>
							<div className="add-product-variant-basket-recommendations__column">
								<span className="add-product-variant-basket-recommendations__label">
									{phrases.REPLACEMENTS}:
								</span>
								<div className="add-product-variant-basket-recommendations__row__wrap">
									{batchFormData.basketExclusion
										? this._renderNewGroups(batchFormData, enums.EXCLUSION)
										: this._renderExistingGroups(
												batchFormData,
												enums.EXCLUSION
										  )}
								</div>
							</div>
						</div>
					</SectionCollapsable>
				</ModalRow>
			</>
		);
	}
}

AddProductVariantBasketRecommandationsPreview.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
};

export default AddProductVariantBasketRecommandationsPreview;
