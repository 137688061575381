/**
 * @namespace __api
 * @memberOf SERVICES
 * @description
 * API call manager
 */

// Services
// import AuthService from 'auth.service';
// import { UserService } from 'user';
import { store } from 'appState';

import _forOwn from 'lodash/forOwn';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';
import { withScope, captureMessage } from '@sentry/browser';

// redux actions
import { dispatchSetShowModal } from './../smart/invalidTenantAccount/store/invalidTenantAccount.actions';

const __endpoints = require('endpoints.service');

// import { set as setFeedback } from 'feedback.vanilla.service.js';

/// //////////
// Private //
/// //////////

/**
 * @function _encodeUriPlus
 * @memberOf SERVICES.__api
 * @description Encodes uri component and adds a bit more
 * @param  {string} string  uri string to encode
 * @private
 */
function _encodeUriPlus(string) {
	const encodedString = encodeURIComponent(string)
		.replace(/!/g, '%21')
		.replace(/'/g, '%27')
		.replace(/\(/g, '%28')
		.replace(/\)/g, '%29')
		.replace(/\*/g, '%2A');
	return encodedString;
}

/**
 * @function param
 * @memberOf SERVICES.__api
 * @description Param; converts an object to x-www-form-urlencoded serialization.
 * @param  {object} obj  object of params to convert
 * @private
 */
function _param(obj) {
	if (!_isObject(obj)) return;

	let query = '';
	let fullSubName;
	let subName;
	let subValue;
	let innerObj;
	let i;

	_forOwn(obj, function (value, key) {
		if (_isArray(value)) {
			for (i = 0; i < value.length; ++i) {
				subValue = value[i];
				fullSubName = key + '[' + i + ']';
				innerObj = {};
				innerObj[fullSubName] = subValue;
				query += _param(innerObj) + '&';
			}
		} else if (_isObject(value)) {
			for (subName in value) {
				subValue = value[subName];
				fullSubName = key + '[' + subName + ']';
				innerObj = {};
				innerObj[fullSubName] = subValue;
				query += _param(innerObj) + '&';
			}
		} else if (value !== undefined && value !== null) {
			query += _encodeUriPlus(key) + '=' + _encodeUriPlus(value) + '&';
		}
	});

	return query.length ? query.substr(0, query.length - 1) : query;
}

/**
 * @function _generatePromise
 * @memberOf SERVICES.__api
 * @description
 * Generates API data promise
 * Depends deeply on {@link SERVICES.__hmac} and {@link SERVICES.__auth}.
 * @param  {string} method  GET, POST, DELETE etc.
 * @param  {string} path    uri path
 * @param  {object} data    data to handle in API call
 * @param  {object} params  params to base querystring on (x-www-form-urlencoded serialization)
 * @param  {object} headers additional headers
 * @return {object}         Promise which is a call to the JOE API
 * @private
 */
function _generatePromise(method, path, data, params, headers, upload, signal) {
	const uri = __endpoints.apiUri + path;

	let querystring = _param(params);

	querystring = querystring ? '?' + querystring : querystring;
	const url =
		__endpoints.apiHost + (querystring ? uri + '' + querystring : uri);
	const state = store.getState();
	const accessToken = state.userData.accessToken;
	const activeTenant = state.userData.activeTenant;

	let fetchData;

	headers = Object.assign(
		{},
		{
			Authorization: `Bearer ${accessToken}`,
			...(activeTenant && {
				'JOE-TENANT': activeTenant.id,
			}),
		},
		headers
	);

	/* Handle Upload */
	if (upload) {
		const body = new FormData();
		body.append('document', data);

		fetchData = [
			url,
			{
				method,
				headers,
				body,
				signal,
			},
		];
		/* Handle other headers */
	} else {
		headers = Object.assign(
			{},
			{
				'Content-Type': 'application/json',
			},
			headers
		);

		const body = method === 'GET' ? undefined : JSON.stringify(data);

		fetchData = [
			url,
			{
				method,
				headers,
				// The line below is good for local development
				// cache: "force-cache",
				body,
				signal,
			},
		];
	}

	return fetch(fetchData[0], fetchData[1]).then((response) => {
		// Wait for stream to complete before resolving
		if (response.headers.get('Content-Type').indexOf('application/json') > -1) {
			if (response.status === 204) return response;
			else {
				return response.json().then((json) => {
					return response.ok ? json : Promise.reject(json);
				});
			}
		} else
			return response.blob().then((blob) => {
				return response.ok ? blob : Promise.reject(blob);
			});
	});
}

/**
 * @function _call
 * @memberOf SERVICES.__api
 * @description
 * API call wrapper.
 * @param  {string} method  GET, POST, DELETE etc.
 * @param  {string} path    uri path
 * @param  {object} data    data to handle in API call
 * @param  {object} params  params to base querystring on (x-www-form-urlencoded serialization)
 * @param  {object} headers additional headers
 * @return {object}         Promise which is a call to the JOE API
 * @private
 */
function _call(method, path, data, params, headers, upload, signal) {
	return new Promise((resolve, reject) => {
		_generatePromise(method, path, data, params, headers, upload, signal).then(
			(response) => {
				resolve(response);
			},
			(response) => {
				const statusCode =
					response && response.code
						? _get(response, 'code', null)
						: _get(response, 'data.code', null);

				// User was unautherized. Open Auth0 lock
				if (statusCode && statusCode === 401) {
					// AuthService.renewToken();
				}

				// CALL was aborted
				if (
					statusCode === 20 &&
					response.message === 'The user aborted a request.'
				)
					return;

				console.warn(
					'api.' +
						method +
						'() - call failed to ' +
						(__endpoints.apiUri + path) +
						' with data ' +
						JSON.stringify(data) +
						'.',
					arguments
				);

				// If statuscode is null something is really screwed
				if (statusCode === 'null') {
					withScope((scope) => {
						scope.setExtra(response);
						captureMessage(
							'Something went really bad when trying to contact the api',
							'error'
						);
					});
				}

				// If statuscode is null something is really screwed
				if (statusCode && statusCode === 403) {
					// const user = UserService.getUser();
					// Sentry.withScope((scope) => {
					// 	scope.setExtra('response', response);
					// 	scope.setExtra('path', path);
					// 	scope.setExtra('user', user);
					// 	Sentry.captureMessage('403 - User could not get access to resource', 'warning');
					// });

					// if error due to tenant issues
					if (
						response?.message ===
						'Sorry - you tried authenticating with a invalid tenant account'
					)
						dispatchSetShowModal(true);
				}

				// Check for -1. -1 indicates timeout/cancel http requests which gets no response.
				// Timeout returns no response which will create an error
				if (response.status !== -1) {
					reject(response);
				}
			}
		);
	});
}

/// //////////
// Public //
/// //////////

/**
 * @function get
 * @description
 * Makes call to API with HTTP GET method via _call where full url is built
 * @param  {string} path       uri path (e.g. /profile/person_pictures)
 * @param  {object} data       data to handle in API call
 * @param  {object} params     params to base querystring on
 * @param  {object} headers    additional headers
 * @memberOf SERVICES.__api
 */
export function get(uri, params, data, headers, signal) {
	return _call('GET', uri, data, params, headers, false, signal);
}

/**
 * @function post
 * @description Makes call to API method via _call where full url is built
 * @param  {string} path       uri path (e.g. /profile/person_pictures)
 * @param  {object} data       data to handle in API call
 * @param  {object} headers    additional headers
 * @memberOf SERVICES.__api
 */
export function post(uri, data, headers, signal) {
	return _call('POST', uri, data, undefined, headers, false, signal);
}

/**
 * @function patch
 * @description Makes call to API method via _call where full url is built
 * @param  {string} path       uri path (e.g. /profile/person_pictures)
 * @param  {object} data       data to handle in API call
 * @param  {object} headers    additional headers
 * @memberOf SERVICES.__api
 */
export function patch(uri, data, headers, signal) {
	return _call('PATCH', uri, data, undefined, headers, false, signal);
}

/**
 * @function remove(delete is reserved in JavaScript)
 * @description Makes call to API with HTTP DELETE method via _call where full url is built
 * @param  {string} uri       uri path (e.g. /profile/person_pictures)
 * @param  {object} data       data to handle in API call
 * @param  {object} params     params to base querystring on
 * @param  {object} headers    additional headers
 * @memberOf SERVICES.__api
 */
export function remove(uri, data, params, headers, signal) {
	return _call('DELETE', uri, data, params, headers, false, signal);
}

/**
 * @function upload
 * @description Makes call to API method via _call where full url is built
 * @param  {string} path       uri path (e.g. /profile/person_pictures)
 * @param  {object} data       data to handle in API call
 * @param  {object} headers    additional headers
 * @memberOf SERVICES.__api
 */
export function upload(uri, data, headers, signal) {
	return _call('POST', uri, data, undefined, headers, true, signal);
}
