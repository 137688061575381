import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import TileDirectory from 'smart/tileDirectory/tileDirectory';

const AdyenReconciliationMatrix = React.lazy(() =>
	import(
		/* webpackChunkName: "adyenReconciliationMatrix" */ 'sales/adyenReconciliationMatrix/adyenReconciliationMatrix.container.jsx'
	)
);

const Dynamics365Matrix = React.lazy(() =>
	import(
		/* webpackChunkName: "dynamics365Matrix" */ 'sales/dynamics365Matrix/dynamics365Matrix.container.tsx'
	)
);

const EodControl = React.lazy(() =>
	import(
		/* webpackChunkName: "EodControl" */ 'sales/eodControl/eodControl.container.tsx'
	)
);

const EodControlOld = React.lazy(() =>
	import(
		/* webpackChunkName: "EodControlOld" */ 'sales/eodControlOld/eodControl.container.js'
	)
);

const AdyenReconciliation = React.lazy(() =>
	import(
		/* webpackChunkName: "AdyenReconciliation" */ 'sales/adyenReconciliation/adyenReconciliation.container.jsx'
	)
);

const SalesControl = React.lazy(() =>
	import(
		/* webpackChunkName: "SalesControl" */ 'sales/salesControl/salesControl.container.jsx'
	)
);

const DrawerToBankList = React.lazy(() =>
	import(
		/* webpackChunkName: "DrawerToBankList" */ 'sales/drawerToBankList/drawerToBankList.component'
	)
);

const DrawerToBankMatrix = React.lazy(() =>
	import(
		/* webpackChunkName: "DrawerToBankMatrix" */ 'sales/drawerToBankMatrix/drawerToBankMatrix.container.jsx'
	)
);

const FinancialPosExport = React.lazy(() =>
	import(
		/* webpackChunkName: "FinancialPosExport" */ 'sales/financialPosExport/component/financialPosExport/financialPosExport.js'
	)
);

const TransactionsLookup = React.lazy(() =>
	import(
		/* webpackChunkName: "TransactionsLookup" */ 'sales/transactionsLookup/transactionsLookup.container.js'
	)
);

export const salesRoutes = [
	{
		element: <Outlet />,
		path: 'sales',
		title: 'sales',
		allowedAccessControlLevels: ['Sales Audit Admin'],
		icon: require('@mui/icons-material/PointOfSaleOutlined').default,
		children: [
			{
				index: true,
				element: <TileDirectory pathName="sales" />,
			},
			{
				element: (
					<Suspense fallback="loading">
						<DrawerToBankList />
					</Suspense>
				),
				path: 'drawer-to-bank-list',
				title: 'Drawer to bank list',
				description: 'Manage bank deposits from stores',
				allowedAccessControlLevels: ['Sales Audit Admin'],
			},
			{
				element: (
					<Suspense fallback="loading">
						<EodControl />
					</Suspense>
				),
				path: 'eod-control',
				title: 'Eod control',
				description: 'Manage end of day',
				allowedAccessControlLevels: ['Sales Audit Admin'],
			},
			{
				element: (
					<Suspense fallback="loading">
						<EodControlOld />
					</Suspense>
				),
				path: 'eod-control-old',
				title: 'Eod control, old',
				description: '(Old) Manage end of day',
				allowedAccessControlLevels: ['Sales Audit Admin'],
			},
			{
				element: (
					<Suspense fallback="loading">
						<AdyenReconciliationMatrix />
					</Suspense>
				),

				path: 'reconciliation-matrix',
				title: 'Reconciliation matrix',
				description: 'Manage Reconciliation matrix',
				allowedAccessControlLevels: ['Sales Audit Admin'],
			},

			{
				element: (
					<Suspense fallback="loading">
						<SalesControl />
					</Suspense>
				),
				path: 'sales-control',
				title: 'Sales control',
				description: 'Manage sales control',
				allowedAccessControlLevels: ['Sales Audit Admin'],
			},
			{
				element: (
					<Suspense fallback="loading">
						<TransactionsLookup />
					</Suspense>
				),
				path: 'transactions-lookup',
				title: 'transactions lookup',
				description: 'Find specific Transactions',
				allowedAccessControlLevels: ['Salary Admin'],
			},

			{
				element: (
					<Suspense fallback="loading">
						<Dynamics365Matrix />
					</Suspense>
				),

				path: 'dynamics365-matrix',
				title: 'Dynamics365 matrix',
				description: 'Manage Revisions',
				allowedAccessControlLevels: ['Sales Audit Admin'],
			},
			{
				element: (
					<Suspense fallback="loading">
						<AdyenReconciliation />
					</Suspense>
				),
				path: 'settlement-bank-dates',
				title: 'Settlement Bank Dates',
				section: 'Credit Card',

				description: 'Manage settlement bank dates',
				allowedAccessControlLevels: ['Sales Audit Admin'],
			},

			{
				element: (
					<Suspense fallback="loading">
						<DrawerToBankMatrix />
					</Suspense>
				),
				path: 'drawer-to-bank-matrix',
				section: 'Drawer to bank',
				title: 'Drawer to bank matrix',
				description: 'Manage bank deposits from stores',
				allowedAccessControlLevels: ['Sales Audit Admin'],
			},
			{
				element: (
					<Suspense fallback="loading">
						<FinancialPosExport />
					</Suspense>
				),
				path: 'financial-pos-export',
				description: 'Manage Financial POS exports',
				title: 'Financial pos export',
				section: 'End of month exports',
				allowedAccessControlLevels: ['Sales Audit Admin'],
			},
		],
	},
];
