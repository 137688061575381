import React, { useState } from 'react';
import PropTypes from 'prop-types';

// redux
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
// actions
import { removeRowInteracted } from './../../../../../store/shiftClockins.actions';

// components
import { Button, Tooltip, Icon, ButtonLoader } from 'dumb';
// menu
import { Menu, MenuItem } from 'dumb/menu';

// utils
import {
	getManagedClockinPayload,
	getIsApproveButtonDisabled,
} from './../utils';
import enums from './../shiftClockinsTableColumns.enums';

// utils
import phrases from './../../../shiftClockinsTable.phrases';

const ManagedClockinsActions = ({
	row,
	onManagedClockinNoShowCellClick,
	postManagedClockinCorrection,
	reduxKey,
	onManagedClockinAddBreakClick,
	breakRegistrationPossible,
	setBreaksAsNoShow,
}) => {
	const handleClickNoShow = () => {
		onManagedClockinNoShowCellClick(row);
	};

	const handleClickAddBreak = () => {
		onManagedClockinAddBreakClick(row);
	};

	const approveDeviation = () => {
		setLoading(true);

		const payload = getManagedClockinPayload({
			row,
		});

		postManagedClockinCorrection(payload)
			.then(() => {
				dispatch(removeRowInteracted(row.id));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	// sets all breaks to no-show
	const setBreaksNoShow = () => {
		setBreaksAsNoShow({
			row,
		});
	};

	const selectedRows = useSelector((state) => {
		return state.listData?.[reduxKey]?.ui?.selectedRows ?? [];
	}, shallowEqual);
	const interactedRows = useSelector(
		(state) => state.shiftClockins?.interactedRows
	);

	const [loading, setLoading] = useState(false);

	const approveButtonDisabled = getIsApproveButtonDisabled({
		loading,
		row,
	});
	const showButtons =
		!!selectedRows.find((entry) => entry.id === row.id) ||
		!!interactedRows.find((entry) => entry === row.id);
	const dispatch = useDispatch();
	const clockinHandled =
		row.status === enums.ALIGNED || row.status === enums.UNPLANNED;

	return showButtons ? (
		<>
			<Tooltip
				text={phrases.TOOLTIP_APPROVE_CHANGE}
				renderData={(ref, onMouseEnter, onMouseLeave) => (
					<Button
						type={approveButtonDisabled ? 'inverted' : 'success'}
						shadow
						size="micro"
						disabled={approveButtonDisabled}
						onClick={approveDeviation}
						refProp={ref}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
					>
						{loading ? (
							<ButtonLoader size="micro" loading />
						) : (
							<Icon name="done" />
						)}
					</Button>
				)}
			/>
			<Menu
				renderData={(ref, onClick, onBlur) => (
					<Button
						id="menu"
						refProp={ref}
						type="inverted"
						onClick={onClick}
						onBlur={onBlur}
						size="micro"
					>
						<Icon name="more_vert" />
					</Button>
				)}
			>
				<MenuItem
					leftSection={<Icon name="not_interested" />}
					onClick={handleClickNoShow}
					disabled={clockinHandled}
				>
					{phrases.MARK_AS_NO_SHOW}
				</MenuItem>
				{breakRegistrationPossible && (
					<>
						<MenuItem
							leftSection={<Icon name="add" />}
							onClick={handleClickAddBreak}
							disabled={clockinHandled}
						>
							{phrases.ADD_BREAK}
						</MenuItem>
						<MenuItem
							leftSection={<Icon name="delete" />}
							onClick={setBreaksNoShow}
							disabled={clockinHandled}
						>
							{phrases.ZERO_LENGTH_BREAKS}
						</MenuItem>
					</>
				)}
			</Menu>
		</>
	) : null;
};

ManagedClockinsActions.propTypes = {
	row: PropTypes.object,
	onManagedClockinNoShowCellClick: PropTypes.func,
	postManagedClockinCorrection: PropTypes.func,
	reduxKey: PropTypes.string,
	onManagedClockinAddBreakClick: PropTypes.func,
	breakRegistrationPossible: PropTypes.bool,
	setBreaksAsNoShow: PropTypes.func,
};

export default ManagedClockinsActions;
