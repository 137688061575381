'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';

// different batch views
import GlobalBundleRulesBatchStepForm from './components/globalBundleRulesBatchStepForm';
import GlobalBundleRulesBatchStepList from './components/globalBundleRulesBatchStepList';

// phrases/ constants
import phrases from './../../globalBundleRules.phrases';
import constants from 'services/constants';

// loash
import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';

class GlobalBundleRulesModalBatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showOverlay: false,
			overlayType: null,
			activeFilterPosConfigurations: true,
		};

		this._getIsBatchStepFormValid = this._getIsBatchStepFormValid.bind(this);

		this.modalRef = React.createRef();
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_onSubmitForm() {
		const { batchFormData, addBatchList } = this.props;

		const listArray = batchFormData.weekday.map((entry) => {
			return {
				identifier: _uniqueId('row-'),
				weekday: entry,
				from: batchFormData.from || moment().format(constants.timeShort),
				to: batchFormData.to || '23:59',
			};
		});

		addBatchList(listArray);

		this._goToStep(2);
	}

	_batchSubmit() {
		const { batchListData, handleClose, batchAddData } = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchListData.map((entry) => {
			return {
				weekday: _get(entry, 'weekday.value', null),
				name: entry.name,
				description: entry.description,
				time: {
					from: moment
						.utc(entry.from, constants.timeShort)
						.format(constants.dayFormat),
					to: moment
						.utc(entry.to, constants.timeShort)
						.format(constants.dayFormat),
				},
			};
		});

		batchAddData({ batch: payload })
			.then(() => {
				handleClose();

				this.setState(() => ({
					loadingModal: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	_getIsBatchStepFormValid() {
		const { batchFormData } = this.props;

		const weekdayEmpty = _isEmpty(batchFormData.weekday);

		return !weekdayEmpty;
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchListData,
			editBatchListItem,
			removeBatchListItem,
		} = this.props;
		const { showOverlay } = this.state;

		const steps = [
			{
				component: (
					<GlobalBundleRulesBatchStepForm
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE,
				isValid: this._getIsBatchStepFormValid(),
				onNext: () => this._onSubmitForm(),
				loading: false,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				noScroll: false,
				allowOverflow: true,
			},
			{
				component: (
					<GlobalBundleRulesBatchStepList
						batchListData={batchListData}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
						loyaltyProductUrl={this.loyaltyProductUrl}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						className="pos-modal-batch"
						ref={this.modalRef}
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={showOverlay ? () => {} : handleClose}
					/>
				)}
			</>
		);
	}
}

GlobalBundleRulesModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	addBatchList: PropTypes.func,
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	batchAddData: PropTypes.func,
};

export default GlobalBundleRulesModalBatch;
