'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import { ReactDataWrapper } from 'reactDataWrapper';

// components
import { Input, Button, Icon, Toggle } from 'dumb';
import IngredientConfigurationIngredienbtsModalBatch from './components/batch/ingredientConfigurationIngredientsModalBatch';

// actions/services
import {
	// batch stuff
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	updateIngredientConfigurationIngredient,
	resetIngredientConfigurationIngredient,
	setIngredientConfigurationIngredient,
} from './store/ingredientConfigurationIngredients.actions';
import {
	fetchIngredientsConfigurationIngredients,
	addIngredientConfigurationIngredient,
	editIngredientConfigurationIngredient,
	editIngredientConfigurationIngredients,
	deleteIngredientConfigurationIngredient,
} from './ingredientConfigurationIngredients.service';

// lodash
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

// phrases
import phrases from './ingredientConfigurationIngredients.phrases';

class IngredientsConfigurationIngredient extends Component {
	constructor(props) {
		super(props);

		this.state = {
			batchAddFilter: '',
		};

		this.fetchData = this.fetchData.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.createIngredientFilter = this.createIngredientFilter.bind(this);
		this.toggleAddModal = this.toggleAddModal.bind(this);
		this.getActionButtons = this.getActionButtons.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.addIngredientConfigurationIngredientsWrapper = this.addIngredientConfigurationIngredientsWrapper.bind(
			this
		);

		this.columns = [
			{
				Header: 'Ingredient',
				id: 'ingredient',
				accessor: (d) => _get(d, 'ingredient.name', ''),
				filterPath: ':ingredient.name',
			},
			{
				Header: 'Available in app',
				id: 'available_in_app',
				accessor: (d) => _get(d, 'available_in_app', ''),
				Cell: (d) => {
					return (
						<Input
							type="checkbox"
							disabled
							checked={d.original.available_in_app}
						/>
					);
				},
				filterable: false,
				filterPath: ':available_in_app',
			},
		];
	}

	editEntry() {
		const { ingredientConfigurationIngredient } = this.props;

		const payload = {
			id: ingredientConfigurationIngredient.id,
			available_in_app: ingredientConfigurationIngredient.availableInApp,
		};

		return editIngredientConfigurationIngredient(
			ingredientConfigurationIngredient.id,
			payload
		);
	}

	editMultiple(selectedRows) {
		const { ingredientConfigurationIngredient } = this.props;

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				available_in_app:
					ingredientConfigurationIngredient.availableInApp ?? undefined,
			};
		});

		return editIngredientConfigurationIngredients({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteIngredientConfigurationIngredient(id);
	}

	setInitialEditValues(data) {
		const { setIngredientConfigurationIngredient } = this.props;

		const payload = {
			id: data.id,
			ingredient: data.ingredient.name,
			availableInApp: data.available_in_app,
		};

		setIngredientConfigurationIngredient(payload);
	}

	createIngredientFilter() {
		// fetch ingredient config ingredients
		const state = {
			limit: 300,
		};

		this.fetchData(state).then((res) => {
			if (_isEmpty(res.data)) return;

			const idsArray = res.data.reduce((acc, currentValue, index) => {
				const id = currentValue.ingredient?.id ?? null;

				acc =
					res.data.length - 1 === index ? `${acc}'${id}']` : `${acc}'${id}',`;

				return acc;
			}, '[');

			const filter = `:id=!IN=${idsArray}`;

			this.setState(() => ({ batchAddFilter: filter }));
		});
	}

	getEditableCellsEdit() {
		const { ingredientConfigurationIngredient } = this.props;

		return [
			{
				header:
					phrases.INGREDIENT_CONFIGURATION_INGREDIENTS_MODAL_HEADER_INGREDIENT,
				value: <span>{ingredientConfigurationIngredient.ingredient}</span>,
			},
			{
				header:
					phrases.INGREDIENT_CONFIGURATION_INGREDIENTS_MODAL_HEADER_AVAILABLE_IN_APP,
				value: (
					<Toggle
						id="ingredient-configuration-ingredient"
						toggled={ingredientConfigurationIngredient.availableInApp}
						onClick={(event) => this.editStoreEntry(event, 'availableInApp')}
					/>
				),
			},
		];
	}

	editStoreEntry(value, name) {
		const { updateIngredientConfigurationIngredient } = this.props;

		const updateObject = {
			[name]: value,
		};

		updateIngredientConfigurationIngredient(updateObject);
	}

	fetchData(state) {
		const { ingredientConfigurationId } = this.props;

		return fetchIngredientsConfigurationIngredients(
			state,
			ingredientConfigurationId
		);
	}

	addIngredientConfigurationIngredientsWrapper(data) {
		return addIngredientConfigurationIngredient(data)
			.then(() => this.setState(() => ({ key: moment() })))
			.catch((err) => {
				throw err;
			});
	}

	toggleAddModal() {
		if (!this.state.showAddModal) {
			this.createIngredientFilter();
		}

		this.setState((prevState) => ({ showAddModal: !prevState.showAddModal }));
		this.props.resetIngredientConfigurationIngredient();
	}

	getActionButtons() {
		return (
			<Button type="inverted" label="Add" shadow onClick={this.toggleAddModal}>
				<Icon name="add" />
			</Button>
		);
	}

	render() {
		const {
			resetIngredientConfigurationIngredient,
			ingredientConfigurationId,

			batchFormData,
			batchListData,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
			updateBatchForm,
			addBatchList,
		} = this.props;

		return (
			<>
				<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
					key={this.state.key}
					title={phrases.INGREDIENT_CONFIGURATION_INGREDIENTS_TABLE_TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={`pos/ingredient_configuration_ingredients/${ingredientConfigurationId}`}
					manual
					editEntry={(e) => this.editEntry(e)}
					editableCellsEdit={this.getEditableCellsEdit()}
					setInitialEditValues={this.setInitialEditValues}
					deleteEntry={this.deleteEntry}
					onModalClose={resetIngredientConfigurationIngredient}
					actionRender={this.getActionButtons()}
					editMultiple={this.editMultiple}
				/>

				<IngredientConfigurationIngredienbtsModalBatch
					modalVisible={this.state.showAddModal}
					handleClose={this.toggleAddModal}
					batchFormData={batchFormData}
					batchListData={batchListData}
					addBatchList={addBatchList}
					updateBatchForm={updateBatchForm}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					batchAddData={this.addIngredientConfigurationIngredientsWrapper}
					batchAddFilter={this.state.batchAddFilter}
					ingredientConfigurationId={ingredientConfigurationId}
				/>
			</>
		);
	}
}

IngredientsConfigurationIngredient.propTypes = {
	ingredientConfigurationIngredient: PropTypes.object,
	setIngredientConfigurationIngredient: PropTypes.func,
	resetIngredientConfigurationIngredient: PropTypes.func,
	updateIngredientConfigurationIngredient: PropTypes.func,
	ingredientConfigurationId: PropTypes.number,
	batchListData: PropTypes.array,
	batchFormData: PropTypes.object,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	updateBatchForm: PropTypes.func,
	addBatchList: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			addBatchListItemRow,
			updateIngredientConfigurationIngredient,
			resetIngredientConfigurationIngredient,
			setIngredientConfigurationIngredient,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		ingredientConfigurationIngredient:
			store.ingredientConfigurationIngredients.data
				.ingredientConfigurationIngredient,
		batchListData: store.ingredientConfigurationIngredients.data.batchListData,
		batchFormData: store.ingredientConfigurationIngredients.data.batchFormData,
	};
};

export default connectWithStore(
	IngredientsConfigurationIngredient,
	mapStateToProps,
	mapDispatchToProps
);
