export const RESET_ASSET_SUB =
	'SALES_CONFIGURATION_ASSETS_LEFT_ALIGNED/RESET_ASSET_UPLOAD_SUB';
export const SET_ASSET_SUB =
	'SALES_CONFIGURATION_ASSETS_LEFT_ALIGNED/SET_ASSET_UPLOAD_SUB';
export const UPDATE_ASSET_SUB =
	'SALES_CONFIGURATION_ASSETS_LEFT_ALIGNED/UPDATE_ASSET_UPLOAD_SUB';

/**
 * @param {object} payload
 */
export function setAsset(payload) {
	return {
		type: SET_ASSET_SUB,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateAsset(payload) {
	return {
		type: UPDATE_ASSET_SUB,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetAssets() {
	return {
		type: RESET_ASSET_SUB
	};
}
