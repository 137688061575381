import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchAllProductAddons
 * @description Fetch all ingredient addons
 * @param {object} state
 */
export function fetchAllProductAddons(state, extraFilter) {
	let filter;
	if (state.filter) filter = state.filter;
	if (extraFilter) filter = filter ? `${filter};${extraFilter}` : extraFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(`/product/addons`, params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editProductAddons
 * @description edit
 */
export function editProductAddons(addonId, data) {
	return post(`/product/addons/${addonId}`, data)
		.then((res) => {
			setFeedback('Product Addon edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editProductAddonsMultiple
 * @description edit multiple
 */
export function editProductAddonsMultiple(data) {
	return patch('/product/addons', data)
		.then((res) => {
			setFeedback('Product Addons edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function duplicateProductAddons
 * @description duplicates product addon
 */
export function duplicateProductAddons(addonId, data) {
	return post(`/product/addons/${addonId}/duplicate`, data)
		.then((res) => {
			setFeedback('Product Addon duplicated!', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function addProductAddons
 * @description add
 */
export function addProductAddons(data) {
	return post(`/product/addons`, data)
		.then((res) => {
			setFeedback('Product Addon added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteProductAddons
 * @description edit
 */
export function deleteProductAddons(addonId) {
	return remove(`/product/addons/${addonId}`)
		.then((res) => {
			setFeedback('Product Addon deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

// Product Variant Addons //
/**
 * @function fetchProductVariantAddons
 * @description Fetch Product variant addons
 * @param {number} addonId
 * @param {object} state
 */
export function fetchProductVariantAddons(addonId, state) {
	const preFilter = `:addon.id=='${addonId}'`;
	// let preFilter = '';

	const filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(`/product/product_variant_addons`, params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

// /**
//  * @function editProductVariantAddons
//  * @description edit
//  */
// export function editProductVariantAddons(productVariantId, data) {
// 	return post(`/product/product_variant_addons/${productVariantId}`, data)
// 		.then(res => {
// 			setFeedback('Ingredient edited', 1);
// 			return res;
// 		})
// 		.catch(err => {
// 			const errorMessage = formatErrorMessage(err);
// 			setFeedback(errorMessage, 0);
// 		});
// }

/**
 * @function addProductVariantAddons
 * @description add
 */
export function addProductVariantAddons(data) {
	return post(`/product/product_variant_addons`, data)
		.then((res) => {
			setFeedback('Product variant addon added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteProductVariantAddons
 * @description delete
 */
export function deleteProductVariantAddons(productVariantId) {
	return remove(`/product/product_variant_addons/${productVariantId}`)
		.then((res) => {
			setFeedback('Product variant addon deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
