import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ButtonLoader, Button, Icon } from 'dumb';

// utils/tools
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';
import { transformFile } from './utils/utils';

// phrases
import phrases from './fileUpload.phrases';

// styles
import './fileUpload.css';

export default class FileUpload extends Component {
	constructor(props) {
		super(props);

		this.state = {
			drag: false
		};

		this.fileInput = React.createRef();

		this._onChange = this._onChange.bind(this);
		this._handleDrag = this._handleDrag.bind(this);
		this._handleDragIn = this._handleDragIn.bind(this);
		this._handleDragOut = this._handleDragOut.bind(this);
		this._handleDrop = this._handleDrop.bind(this);

		this.id = _uniqueId('prefix-');
	}

	_handleDrag(e) {
		e.preventDefault();
		e.stopPropagation();
	}

	_handleDragIn(e) {
		e.preventDefault();
		e.stopPropagation();

		if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
			this.setState(() => ({ drag: true }));
		}
	}

	_handleDragOut(e) {
		e.preventDefault();
		e.stopPropagation();
		this.setState(() => ({ drag: false }));
	}

	_handleDrop(e) {
		e.preventDefault();
		e.stopPropagation();
		this.setState(() => ({ drag: false }));
		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			this._onChange(e.dataTransfer.files);
			e.dataTransfer.clearData();
		}
	}

	componentDidMount() {
		const input = this.fileInput.current;
		input.addEventListener('dragenter', this._handleDragIn);
		input.addEventListener('dragleave', this._handleDragOut);
		input.addEventListener('dragover', this._handleDrag);
		input.addEventListener('drop', this._handleDrop);
	}

	componentWillUnmount() {
		const input = this.fileInput.current;
		input.removeEventListener('dragenter', this._handleDragIn);
		input.removeEventListener('dragleave', this._handleDragOut);
		input.removeEventListener('dragover', this._handleDrag);
		input.removeEventListener('drop', this._handleDrop);
	}

	_onChange(e = {}) {
		const { onChange, resize } = this.props;

		if (!onChange) return;

		// Get file
		const file = e.target ? e.target.files[0] : e[0];

		// if faulty file uploaded, return
		if (!file) return;

		// if no resizing options passed return an original file
		if (_isEmpty(resize)) return onChange(file);

		// loop through resizing options and return promised resized images
		const newFiles = resize.map(entry => {
			const { width } = entry;

			return transformFile({ file, width });
		});

		// make sure all images are transformed before returning
		Promise.all(newFiles).then(resolvedImages => {
			resolvedImages = [...resolvedImages, file];

			onChange(resolvedImages);
		});
	}

	_onClear() {
		this.props.onClear();
	}

	render() {
		const {
			accept,
			file,
			loading,
			onClear,
			disabled,
			size,
			thumbnail,
			label,
			urlDownload,
			resize,
			refProp,
			onMouseEnter,
			onMouseLeave
		} = this.props;
		const { drag } = this.state;

		const classnames = cx('j-file-upload', {
			'j-file-upload--drag': drag,
			'j-file-upload--has-file': file,
			'j-file-upload--thumbnail': thumbnail,
			'j-file-upload--close': onClear,
			'j-file-upload--disabled': disabled,
			[`j-file-upload--${size}`]: size
		});

		const text = file
			? file.filename
			: label
				? `Add ${label}`
				: phrases.ADD_FILE;

		const img = file
			? file.url
				? file.url
				: `data:image/jpeg;base64,${file.data}`
			: false;

		const isOnClear = !!onClear;
		const shouldDisableInput = isOnClear && file;

		return (
			<div
				ref={refProp}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}>
				{/* Label */}
				{label && (
					<label
						className={cx('j-file-upload__title-label', {
							'j-file-upload__title-label--disabled': disabled
						})}
						htmlFor={this.id}>
						{label}
					</label>
				)}
				<div
					className={classnames}
					ref={this.fileInput}
					title={disabled ? phrases.DISABLED : ''}>
					<input
						type="file"
						accept={accept}
						disabled={shouldDisableInput || disabled}
						id={this.id}
						onChange={e => this._onChange(e)}
					/>

					{thumbnail && img && !urlDownload && (
						<div
							className="j-file-upload__thumbnail"
							style={{
								backgroundImage: `url('${img}')`,
								backgroundPosition: 'center',
								backgroundSize: 'cover',
								backgroundRepeat: 'no-repeat'
							}}
						/>
					)}

					{urlDownload && (
						<div className="j-file-upload__icon">
							<Button
								disabled={disabled}
								type="transparent"
								onClick={() => window.open(file.url, '_blank')}>
								<Icon name="file_download" />
							</Button>
						</div>
					)}

					<label htmlFor={this.id}>
						{(loading || !img) && (
							<div
								className={cx('j-file-upload__icon', {
									'j-file-upload__icon--disabled': disabled
								})}>
								<div className="j-file-upload__button">
									{loading ? (
										<ButtonLoader loading={loading} />
									) : disabled ? (
										<Icon name="file_upload_disabled" />
									) : (
										<Icon name="file_upload" />
									)}
								</div>
							</div>
						)}
						<span
							className={cx('j-file-upload__text', {
								'j-file-upload__text--on-clear': this.props.onClear
							})}>
							{resize && file ? `x${resize.length + 1}` : text}
						</span>
					</label>

					{this.props.onClear && img && (
						<div className="j-file-upload__icon j-file-upload__action">
							<Button
								label={label}
								disabled={loading || disabled}
								type="transparent"
								onClick={() => this._onClear()}>
								<span className="icon icon--delete" />
							</Button>
						</div>
					)}
				</div>
			</div>
		);
	}
}

FileUpload.defaultProps = {
	file: null,
	loading: false,
	disabled: false,
	thumbnail: false,
	label: ''
};

FileUpload.propTypes = {
	file: PropTypes.shape({
		filename: PropTypes.string,
		data: PropTypes.string,
		url: PropTypes.string
	}),
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	onClear: PropTypes.func,
	accept: PropTypes.string,
	size: PropTypes.string,
	loading: PropTypes.bool,
	thumbnail: PropTypes.bool,
	label: PropTypes.string,
	urlDownload: PropTypes.bool,
	resize: PropTypes.arrayOf(
		PropTypes.exact({ width: PropTypes.number, height: PropTypes.number })
	),
	refProp: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func
};
