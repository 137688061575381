const vitalsUrl = 'https://vitals.vercel-analytics.com/v1/vitals';

function getConnectionSpeed() {
	return 'connection' in navigator &&
		navigator.connection &&
		'effectiveType' in navigator.connection
		? navigator.connection.effectiveType
		: '';
}

export function sendToVercelAnalytics(metric) {
	const analyticsId = process.env.VERCEL_ANALYTICS_ID;
	if (!analyticsId) {
		console.info('VERCEL_ANALYTICS_ID doesnt exist');
		return;
	}

	const body = {
		id: metric.id, // v2-1653884975443-1839479248192
		dsn: analyticsId,
		page: window.location.pathname,
		href: window.location.href, // https://my-app.vercel.app/blog/my-test
		event_name: metric.name, // TTFB
		value: metric.value.toString(), // 60.20000000298023
		speed: getConnectionSpeed(), // 4g
	};

	const blob = new Blob([new URLSearchParams(body).toString()], {
		// This content type is necessary for `sendBeacon`
		type: 'application/x-www-form-urlencoded',
	});
	if (navigator.sendBeacon) {
		navigator.sendBeacon(vitalsUrl, blob);
	} else
		fetch(vitalsUrl, {
			body: blob,
			method: 'POST',
			credentials: 'omit',
			keepalive: true,
		});
}
