// Store
import { get } from 'api.vanilla.service';
import { store } from 'appState';
import * as posConfigurationLayout from '../posConfigurationLayout.actions';

// Tools
import { extractGridDataNew } from './utilities';

/**
 * @function getTileLayout
 * @param {int} layoutId
 * @description Get layout
 */
export function getTileLayout(layoutId) {
	// Loader
	store.dispatch(posConfigurationLayout.fetching(true));

	const uri = `/pos/tile_layouts/${layoutId}`;
	return get(uri).then(data => {
		const layoutData = data.data[0];

		const gridData = extractGridDataNew(layoutData); // rewrite
		store.dispatch(posConfigurationLayout.setGridData(gridData));
		store.dispatch(posConfigurationLayout.fetching(false));
	});
}

export function cleanUp() {
	store.dispatch(posConfigurationLayout.cleanUp());
}
