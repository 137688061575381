import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Inputs
import Cleave from 'cleave.js/react';
import { Label } from 'dumb';

import './input.css';

export default class InputCleave extends Component {
	constructor(props) {
		super(props);

		this.state = {
			cleave: null,
			cleaveRawValue: '',
		};

		this.onCleaveInit = this.onCleaveInit.bind(this);
		this.reset = this.reset.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value && this.props.value === '')
			this.reset();
	}

	reset() {
		if (!this.state.cleave) return;

		this.state.cleave.setRawValue('');
	}

	onCleaveInit(cleave) {
		this.setState({ cleave });
	}

	render() {
		const {
			id,
			label,
			className,
			value,
			options,
			onChange,
			disabled,
			noStyle,
			placeholder,
			autoComplete,
			onFocus,
			onBlur,
			required,
			size,
			tabIndex,
			onInit,
		} = this.props;

		const classN = classnames('j-input', {
			'j-input--no-style': noStyle,
			'j-input--required': required,
			'j-input--label': label,
			'j-input--disabled': disabled,
			[`j-input--${size}`]: !!size,
			className,
		});

		return (
			<div className={classN}>
				{/* Label */}
				{label && <Label htmlFor={id}>{label}</Label>}
				<Cleave
					className="j-input__input"
					placeholder={placeholder}
					id={id}
					name={id}
					value={value}
					options={options}
					onChange={onChange}
					onFocus={onFocus}
					onBlur={onBlur}
					disabled={disabled}
					autoComplete={autoComplete}
					tabIndex={tabIndex}
					onInit={onInit || this.onCleaveInit}
				/>
			</div>
		);
	}
}

InputCleave.defaultTypes = {
	tabIndex: 1,
	onInit: undefined,
};

InputCleave.propTypes = {
	// shared props
	id: PropTypes.string.isRequired,
	autoComplete: PropTypes.string,
	label: PropTypes.string,
	// requiredlabel: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	value: PropTypes.any, // ANY
	placeholder: PropTypes.string,
	size: PropTypes.string,
	required: PropTypes.bool,
	noStyle: PropTypes.bool,
	tabIndex: PropTypes.number,

	// react-select props
	options: PropTypes.object,

	// methods
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	onInit: PropTypes.func,
};
