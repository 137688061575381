export const UPDATE_BATCH_FORM =
	'SALES_CONFIGURATION_INGREDIENT_PRICING/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'SALES_CONFIGURATION_INGREDIENT_PRICING/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_INGREDIENT_PRICING/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_INGREDIENT_PRICING/REMOVE_BATCH_LIST_ITEM';

export const RESET_INGREDIENT_PRICING =
	'SALES_CONFIGURATION_INGREDIENT_PRICING/RESET_INGREDIENT_PRICING';
export const SET_INGREDIENT_PRICING =
	'SALES_CONFIGURATION_INGREDIENT_PRICING/SET_INGREDIENT_PRICING';
export const UPDATE_INGREDIENT_PRICING =
	'SALES_CONFIGURATION_INGREDIENT_PRICING/UPDATE_INGREDIENT_PRICING';
export const SET_POS_CONFIGURATION_INGREDIENT_PRICINGS_DATE_FILTER =
	'SALES_CONFIGURATION_INGREDIENT_PRICING/SET_POS_CONFIGURATION_INGREDIENT_PRICINGS_DATE_FILTER';
export const SET_POS_CONFIGURATION_INGREDIENT_PRICINGS_LEGACY_FILTER =
	'SALES_CONFIGURATION_INGREDIENT_PRICING/SET_POS_CONFIGURATION_INGREDIENT_PRICINGS_LEGACY_FILTER';

export function setLegacyFilter(payload) {
	return {
		type: SET_POS_CONFIGURATION_INGREDIENT_PRICINGS_LEGACY_FILTER,
		payload,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function setPosConfigurationIngredientPricingsDateFilter(payload) {
	return {
		type: SET_POS_CONFIGURATION_INGREDIENT_PRICINGS_DATE_FILTER,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function setIngredientPricing(payload) {
	return {
		type: SET_INGREDIENT_PRICING,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function updateIngredientPricing(payload) {
	return {
		type: UPDATE_INGREDIENT_PRICING,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET_INGREDIENT_PRICING,
	};
}
