'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');
// import regex from 'regex.service';

export default class SalaryComponentDailyOvertimeRulesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'minimum_hours',
				type: 'number',
				canAdd: true,
				canEdit: true,
				// regex: regex.number
			},
			{
				name: 'maximum_hours',
				type: 'number',
				canAdd: true,
				canEdit: true,
				// regex: regex.number
			},
		];
		this.settings = {
			name: 'SalaryComponentDailyOvertimeRulesList',
			allowEmpty: true,
			alwaysRefresh: true,
			canAdd: true,
			canEdit: true,
			canDelete: true,
			canManipulate: () => validAccess(undefined, ['Salary Admin']),
			translationPath: 'SALARY.SALARY_COMPONENT_DAILY_OVERTIME_RULES',
			endpoint: __endpoints.collection.salary.salary_component_daily_overtime_rules,
		};
		this.init();
		return this;
	}
}
