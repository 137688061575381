'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');

export default class SalaryComponentWeeklyOvertimeRulesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'weekly_hours',
				type: 'number',
				canAdd: true,
				canEdit: true,
			},
			{
				name: 'daily_hours',
				type: 'number',
				canAdd: true,
				canEdit: true,
			},
		];
		this.settings = {
			name: 'SalaryComponentWeeklyOvertimeRules',
			allowEmpty: true,
			canAdd: true,
			canEdit: true,
			canDelete: true,
			canManipulate: () => validAccess(undefined, ['Salary Admin']),
			translationPath: 'SALARY.SALARY_COMPONENT_WEEKLY_OVERTIME_RULES',
			endpoint: __endpoints.collection.salary.salary_component_weekly_overtime_rules,
		};
		this.init();
		return this;
	}
}
