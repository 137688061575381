import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cx from 'classnames';

import { Button, FileUpload, ButtonLoader, Modal } from 'dumb';

import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service';

/**
 * @namespace jtjDrawerToBankMatrice.component
 * @memberOf COMPONENTS
 */

class GoldModelImportCsv extends Component {
	constructor(props) {
		super(props);
		this.state = {
			file: null,
			filename: '',
			fileSelected: false,
			fileUploaded: false,
			error: false,
			isUploading: false,
			perspectiveType: 'Operation',
			type: 'forecasts',
			periodType: 'monthly',
		};

		this.forecastCsvString = `WorkplannerID;Year;Month;Day;Turnover;COGS;DOC;Salary;Location;Payback
		14;2017;11;;319633,1446;-53378,73515;-10452,00383;-78310,12043;-61533,96603;16`;
		this.actualsCsvString = `WorkplannerID;Year;Month;Day;Turnover;COGS;DOC;Salary;Location;Payback
		14;2017;11;;319633,1446;-53378,73515;-10452,00383;-78310,12043;-61533,96603;16`;

		this.onClose = this.onClose.bind(this);
		this.onFileSelect = this.onFileSelect.bind(this);
		this.onFileConfirmUpload = this.onFileConfirmUpload.bind(this);
		this.onChangeType = this.onChangeType.bind(this);
		this.onChangePeriodType = this.onChangePeriodType.bind(this);
		this.onChangePerspectiveType = this.onChangePerspectiveType.bind(this);
		this.uploadedTimeout = null;
		this._handleExampleDownload = this._handleExampleDownload.bind(this);
	}

	onFileConfirmUpload() {
		const { type, periodType, file, perspectiveType } = this.state;

		// Initiate visual loading
		this.setState({ isUploading: true });

		// Trigger handleImportCsv from service
		this.props.methods
			.handleImportCsv(file, type, periodType, perspectiveType)
			.then(
				() => {
					// On success then reset state and let the user know the file was uploaded
					this.setState({
						fileUploaded: true,
						fileSelected: false,
						file: {},
						error: false,
						isUploading: false,
					});

					// Remove notification after a while
					this.uploadedTimeout = setTimeout(() => {
						this.setState({
							fileUploaded: false,
							isUploading: false,
						});
						this.uploadedTimeout = null;
					}, 4000);
				},
				(err = 'error') => {
					// Let the user know there was an error with the file
					const errorMessage =
						typeof err === 'string' ? err : formatErrorMessage(err);
					setFeedback(errorMessage, 0);
					this.setState({
						error: true,
						fileUploaded: false,
						isUploading: false,
					});
				}
			);
	}

	onFileSelect(file) {
		// Add to state
		this.setState(() => ({
			file,
			filename: file.name,
			fileSelected: true,
			error: false,
		}));
	}

	onChangeType(event) {
		const type = event.target.value;

		this.setState(
			() => ({ type }),
			() => {
				if (
					this.state.perspectiveType === 'SOM' &&
					this.state.type !== 'forecasts'
				) {
					this.setState(() => ({ perspectiveType: 'Operation' }));
				}
			}
		);
	}

	onChangePeriodType(event) {
		const periodType = event.target.value;
		this.setState(() => ({ periodType }));
	}

	onChangePerspectiveType(event) {
		const perspectiveType = event.target.value;
		this.setState(() => ({ perspectiveType }));
	}

	renderTypes() {
		const { translations } = this.props;
		const types = [
			{
				value: 'forecasts',
				label: 'forecasts',
			},
			{
				value: 'actuals',
				label: 'actuals',
			},
		];
		// return _map(types, (type, key) => {

		return types.map((type, key) => {
			return (
				<span key={key}>
					<input
						type="radio"
						name="type"
						value={type.value}
						id={type.value}
						checked={this.state.type === type.value}
						onChange={this.onChangeType}
					/>
					<label htmlFor={type.value}>
						{translations[type.label.toUpperCase()]}
					</label>
				</span>
			);
		});
	}

	renderPeriodTypes() {
		const { translations } = this.props;
		const types = [
			{
				value: 'daily',
				label: 'daily',
			},
			{
				value: 'monthly',
				label: 'monthly',
			},
		];

		return types.map((type, key) => {
			return (
				<span key={key}>
					<input
						type="radio"
						name="periodtype"
						value={type.value}
						id={type.value}
						checked={this.state.periodType === type.value}
						onChange={this.onChangePeriodType}
					/>
					<label htmlFor={type.value}>
						{translations[type.label.toUpperCase()]}
					</label>
				</span>
			);
		});
	}

	renderPerspectiveTypes() {
		const { translations } = this.props;
		let types = [
			{
				value: 'Operation',
				label: 'Operation',
			},
			{
				value: 'Board',
				label: 'Board',
			},
		];

		if (this.state.type === 'forecasts') {
			types = [
				...types,
				{
					value: 'SOM',
					label: 'SOM'
				},
				{
					value: 'SOY',
					label: 'SOY'
				}
			];
		}

		// return _map(types, (type, key) => {
		return types.map((type, key) => {
			return (
				<span key={key}>
					<input
						type="radio"
						name="perspectiveType"
						value={type.value}
						id={type.value}
						checked={this.state.perspectiveType === type.value}
						onChange={this.onChangePerspectiveType}
					/>
					<label htmlFor={type.value}>{type.label.toUpperCase()}</label>
				</span>
			);
		});
	}

	componentWillUnmount() {
		clearTimeout(this.uploadedTimeout);
		this.setState({ fileUploaded: false });
		this.uploadedTimeout = null;
	}

	_handleExampleDownload() {
		const csvString = this.state.type
			? this.forecastCsvString
			: this.actualsCsvString;
		const csvData = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
		const csvURL = window.URL.createObjectURL(csvData);
		const tempLink = document.createElement('a');
		tempLink.href = csvURL;
		tempLink.setAttribute('download', `gold-model-example.csv`);
		tempLink.click();
	}

	onClose() {
		clearTimeout(this.uploadedTimeout);
		this.setState({ fileSelected: false, filename: null, fileUploaded: false});
		this.uploadedTimeout = null;
		this.props.handleClose();
	}

	render() {
		const {
			fileSelected,
			fileUploaded,
			error,
			isUploading,
			filename,
		} = this.state;

		const {
			translations,
			handleClose,
			modifierClassName,
			visible,
		} = this.props;

		return visible ? (
			<Modal
				onClose={this.onClose}
				onConfirmClick={handleClose}
				classname={modifierClassName}
				loading={this.state.isUploading}
				header={translations.TITLE}
				confirmButtonLabel="Close"
				isOpen={visible}>
				<FileUpload
					file={filename ? { filename } : null}
					text={translations.PICK_CSV}
					onChange={this.onFileSelect}
					accept=".csv"
				/>

				{fileSelected && (
					<div className="gold-model__file-upload__types">
						{this.renderPeriodTypes()}
					</div>
				)}

				{fileSelected && (
					<div className="gold-model__file-upload__types">
						{this.renderTypes()}
					</div>
				)}

				{fileSelected && (
					<div className="gold-model__file-upload__types">
						{this.renderPerspectiveTypes()}
					</div>
				)}

				{fileSelected && (
					<button
						disabled={isUploading}
						onClick={this.onFileConfirmUpload}
						className={cx('button gold-model__file-upload__upload', {
							'gold-model__file-upload__upload--uploading': isUploading,
						})}>
						<span>{translations.UPLOAD}</span>
						<ButtonLoader loading={isUploading} theme="dark" />
					</button>
				)}

				{error && (
					<span className="gold-model__file-upload__file-error">
						{translations.ERROR}
					</span>
				)}

				{fileUploaded && (
					<span className="gold-model__file-upload__file-success">
						{translations.SUCCESS}
					</span>
				)}

				{!fileSelected && (
					<div className="gold-model__file-upload__center">
						<h5>{translations.CSVEXAMPLE}</h5>
						<Button type="secondary" onClick={this._handleExampleDownload}>
							{translations.DOWNLOAD_EXAMPLE}
						</Button>
					</div>
				)}
			</Modal>
		) : null;
	}
}

GoldModelImportCsv.propTypes = {
	methods: PropTypes.object.isRequired,
	translations: PropTypes.object.isRequired,
	modifierClassName: PropTypes.string,
	handleClose: PropTypes.func,
	visible: PropTypes.bool,
};

export default GoldModelImportCsv;
