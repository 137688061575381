'use strict';

// redux stuff
import { store } from 'appState';
import { batch } from 'react-redux';
import * as actions from './../reactDataWrapper.actions';

export default function selectRow({
	reduxKey,
	rowInfo,
	selectedRows,
	listData,
	lastSelectedRow
}) {
	// get clicked row index
	const clickedRowIndex = rowInfo.index;
	const lastRowClicked = lastSelectedRow ? lastSelectedRow.index : null;
	const firstRowIndex = selectedRows[0].index;

	// determine bigger/smaller numbers
	const smallestIndex = Math.min(clickedRowIndex, firstRowIndex);
	const biggestIndex = Math.max(clickedRowIndex, firstRowIndex);

	// if we have only one element in the scope
	if (selectedRows.length === 1) {
		batch(() => {
			listData.map((value, index) => {
				if (index >= smallestIndex && index <= biggestIndex) {
					_addRow({ reduxKey, value, index });
				}
			});
		});
	}

	// if the last clicked is above the first clicked, add from last to clicked row
	else if (clickedRowIndex < lastRowClicked) {
		batch(() => {
			listData.map((value, index) => {
				// if the current elements from the array are inside the clicked scope
				if (index <= lastRowClicked && index >= clickedRowIndex) {
					_addRow({ reduxKey, value, index });
				}
			});
		});
	}

	// if the last clicked is bellow the first clicked, re-add already added ones and
	// add from last clicked to clicked
	else {
		// add the ones bellow the last row index
		batch(() => {
			listData.map((value, index) => {
				// if the current elements from the array are inside the clicked scope
				if (index >= lastRowClicked && index <= clickedRowIndex) {
					_addRow({ reduxKey, value, index });
				}
			});
		});
	}
}

function _addRow({ reduxKey, value, index }) {
	const data = {
		...value,
		index
	};

	store.dispatch(
		actions.addRowToSelection({
			reduxKey,
			data
		})
	);
}
