import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './waitingTimeGridEntries.phrases';

/**
 * @function fetchWaitingTimeGridEntries
 * @description Fetch all product recommendations
 * @param {object} state
 */
export function fetchWaitingTimeGridEntries(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get(
		'/administration/waiting_time_grid_entries',
		params,
		null,
		state.headers
	)
		.then(res => res)
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addWaitingTimeGridEntry
 * @description adds addon recommendation
 */
export function addWaitingTimeGridEntry(data) {
	return post('/administration/waiting_time_grid_entries', data)
		.then(res => {
			setFeedback(phrases.WAITING_TIME_GRID_ENTRY_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editWaitingTimeGridEntry
 * @description adds addon recommendation
 */
export function editWaitingTimeGridEntry(data, waitingTimeGridEntryId) {
	return post(
		`/administration/waiting_time_grid_entries/${waitingTimeGridEntryId}`,
		data
	)
		.then(res => {
			setFeedback(phrases.WAITING_TIME_GRID_ENTRY_EDIT, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteWaitingTimeGridEntry
 * @description deletes addon recommendations
 */
export function deleteWaitingTimeGridEntry(id) {
	return remove(`/administration/waiting_time_grid_entries/${id}`)
		.then(res => {
			setFeedback(phrases.WAITING_TIME_GRID_ENTRY_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
