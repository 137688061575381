'use strict';

import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './posConfigurationProductVariantIngredientVariations.phrases';

/**
 * @function fetchPosConfigurationProductVariantIngredientVariations
 * @description fetch all pos variant ingredient variations
 */
export function fetchPosConfigurationProductVariantIngredientVariations(
	productVariantId,
	state
) {
	const preFilter = `:pos_configuration_product_variant.id=='${productVariantId}'`;

	const filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(
		'/pos/pos_configuration_product_variant_ingredient_variations',
		params
	)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addPosConfigurationProductVariantIngredientVariation
 * @description add pos variant ingredient variation
 */
export function addPosConfigurationProductVariantIngredientVariation(data) {
	return post(
		'/pos/pos_configuration_product_variant_ingredient_variations',
		data
	)
		.then((res) => {
			setFeedback(phrases.POS_VARIANT_INGREDIENT_VARIATION_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deletePosConfigurationProductVariantIngredientVariation
 * @description deletes pos variant ingredient variation
 */
export function deletePosConfigurationProductVariantIngredientVariation(
	variantId
) {
	return remove(
		`/pos/pos_configuration_product_variant_ingredient_variations/${variantId}`
	)
		.then((res) => {
			setFeedback(phrases.POS_VARIANT_INGREDIENT_VARIATION_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
