import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import "./sub-header.css";

export default class SubHeader extends PureComponent {
	render() {
		const { children, className } = this.props;

		const classN = classnames('j-sub-header', {
			[`${className}`]: className
		});

		return <h3 className={classN}>{children}</h3>;
	}
}

SubHeader.propTypes = {
	// shared props
	className: PropTypes.string,
	children: PropTypes.any.isRequired
};
