import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import changeCase from 'change-case';

import { LocalStorageService } from 'localStorage';

import './tabs.css';

export default class Tabs extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};

		this.setActiveTab = this.setActiveTab.bind(this);
		this.handleFallbackTab = this.handleFallbackTab.bind(this);

		this.initialPathname = window.location.pathname;
	}

	componentDidMount() {
		const { useLocalStorage, defaultTab, id, useRouter, tabContent } =
			this.props;

		if (defaultTab || !useLocalStorage) {
			this.handleFallbackTab();
		} else {
			// get all tabs from local storage
			LocalStorageService.getItem('tab').then((tabs) => {
				// find key
				if (!tabs) {
					this.handleFallbackTab();
				} else {
					let foundTab = false;
					Object.keys(tabs).map((key) => {
						if (key === id) {
							const activeTab = tabs[key];
							this.setState(() => ({ activeTab }));

							if (useRouter) {
								const activeTabContent = tabContent[activeTab];
								this.changeUrl(activeTabContent.name);
							}

							foundTab = true;
						}
					});

					if (!foundTab) this.handleFallbackTab();
				}
			});
		}
	}

	handleFallbackTab() {
		const { defaultTab, useRouter, tabContent } = this.props;

		if (useRouter) {
			const activeTabContent = tabContent[defaultTab];
			this.changeUrl(activeTabContent.name);
		}

		this.setState(() => ({ activeTab: defaultTab }));
	}

	changeUrl(name) {
		const { id } = this.props;

		const pathname = changeCase.paramCase(name);
		const basePath = changeCase.paramCase(id);

		const nextPath = `${basePath}/${pathname}`;

		const data = { rand: Math.random() };
		window.history.pushState(data, name, nextPath);
	}

	setActiveTab(index) {
		const { useLocalStorage, defaultTab, id, useRouter, tabContent } =
			this.props;

		this.setState(() => ({ activeTab: index }));

		if (useRouter) {
			const activeTabContent = tabContent[index];
			this.changeUrl(activeTabContent.name);
		}

		// if defaultTab passed don't bother saving to local storage
		// since every time we load tabs it will default to defaultTab
		if (useLocalStorage && !defaultTab) {
			LocalStorageService.getItem('tab').then((tabs) => {
				const data = {
					...tabs,
					[id]: index,
				};

				LocalStorageService.setItem('tab', data);
			});
		}
	}

	render() {
		const { tabContent, className, height, passSetActiveTab } = this.props;
		const { activeTab } = this.state;

		const classN = classnames('j-tabs', {
			[`${className}`]: className,
		});

		return (
			<div className={classN}>
				<div className="j-tabs__tabnav">
					{tabContent.map((x, i) => {
						const tabNav = classnames('j-tabs__tabnav__button', {
							'j-tabs__tabnav__button--active': i === activeTab,
						});
						return (
							<button
								className={tabNav}
								key={i}
								data-cy="tabs-button"
								onClick={() => this.setActiveTab(i, x.name)}
							>
								{x.name}
							</button>
						);
					})}
				</div>

				{tabContent.map((x, i) => {
					const tabClass = classnames('j-tabs__tab', {
						'j-tabs__tab--active': i === activeTab,
						'j-tabs__tab--fixed-height': height,
					});
					return (
						<div key={i} className={tabClass} style={{ height }}>
							{i === activeTab ? (
								<x.component.type
									{...x.component.props}
									{...(passSetActiveTab
										? { setActiveTab: this.setActiveTab }
										: {})}
								/>
							) : null}
						</div>
					);
				})}
			</div>
		);
	}
}

Tabs.defaultProps = {
	defaultTab: 0,
	useLocalStorage: false,
	height: null,
	passSetActiveTab: true,
};

Tabs.propTypes = {
	passSetActiveTab: PropTypes.bool,
	useRouter: PropTypes.bool,
	useLocalStorage: PropTypes.bool,
	tabContent: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			component: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
		})
	).isRequired,
	defaultTab: PropTypes.number,
	className: PropTypes.string,
	height: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
