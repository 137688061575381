import React, { Component } from 'react';
import { store } from 'appState';

import { ReactDataWrapper } from 'reactDataWrapper';
import { Input, Button, Icon } from 'dumb';
import _get from 'lodash/get';
import { push } from 'redux-first-history';

import { fetchEmployments } from './persons.service';

class PersonsContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			pages: null,
		};

		this.fetchData = this.fetchData.bind(this);
		this._renderLink = this._renderLink.bind(this);

		this.columns = [
			{
				Header: 'Id',
				id: 'id',
				accessor: 'id', // String-based value accessors!
				width: 75,
				filterPath: ':id',
			},
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => d.full_name,
				filterPath: ':full_name',
			},
			{
				Header: 'Email',
				id: 'email',
				accessor: (d) => _get(d, 'person_email_address.email_address', null),

				Cell: (val) => <a href={`mailto:${val.value}`}>{val.value}</a>,
				filterPath: ':person_email_address.email_address', // should be prefixed with a :-sign, as it is used for filtering and sorting
			},
			{
				Header: 'Phone Number',
				id: 'phoneNumber',
				accessor: (d) => _get(d, 'person_phone_number.phone_number', null),
				width: 150,
				Cell: (val) => <span>{val.value}</span>,
				filterPath: ':person_phone_number.phone_number', // should be prefixed with a :-sign, as it is used for filtering and sorting
			},
			{
				Header: 'Birthday',
				// width: 150,
				id: 'bday',
				accessor: (d) => _get(d, 'birth_date', null),
				filterPath: ':birth_date',
			},
			{
				Header: 'Gender',
				width: 60,
				id: 'gender',
				accessor: (d) => _get(d, 'gender', null),
				filterPath: ':gender',
			},
			{
				Header: 'Active',
				width: 60,
				id: 'active',
				accessor: (d) => _get(d, 'active', null),
				Cell: (d) => <Input id="active-checkbox" type="checkbox" checked={d.value} disabled />,
				filterPath: ':active',
			},
			{
				Header: 'Nationality',
				width: 100,
				id: 'nationality',
				accessor: (d) => _get(d, 'nationality.name', null),
				filterPath: ':nationality.name',
			},
		];
	}

	fetchData(state) {
		// Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
		// You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
		this.setState(() => ({ loading: true }));
		// Request the data however you want.  Here, we'll use our mocked service we created earlier

		return fetchEmployments(state)
			.then((response) => {
				// Now just get the rows of data to your React Table (and update anything else like total pages or loading)
				this.setState(() => ({
					loading: false,
				}));
				return response;
			})
			.catch(() => {
				this.setState(() => ({
					loading: false,
				}));
			});
	}

	_renderLink(e) {
		return (
			<Button
				type="inverted"
				shadow
				title="Rules"
				onClick={() => store.dispatch(push(`hr/juicers/${e.original.id}`))}
				size="micro"
			>
				<Icon name="tune" />
			</Button>
		);
	}

	render() {
		return (
			<ReactDataWrapper
				title="People"
				className="-striped -highlight"
				columns={this.columns}
				totalEntries={this.state.totalEntries} // Display the total number of pages
				loading={this.state.loading}
				fetchData={this.fetchData} // Request new data when things change
				filterable
				disableFetchCsvButton
				enableMultiSelection={false}
				defaultPageSize={50}
				actionsWidth={32}
				reduxKey="hr/persons"
				manual // Forces table not to paginate or sort automatically, so we can handle it server-side
				actions={this._renderLink}
			/>
		);
	}
}

export default PersonsContainer;
