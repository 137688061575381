'use strict';

import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class QuickFilter extends Component {
	constructor(props) {
		super(props);

		this.onInputChangeFilter = this.onInputChangeFilter.bind(this);
		this.onButtonClickClearFilter = this.onButtonClickClearFilter.bind(this);
	}

	handleFilter(event) {
		this.props.methods.handleFilterSearch(event.target.value);
	}

	onInputChangeFilter(event) {
		event.preventDefault();
		event.persist();
		this.handleFilter(event);
	}

	onButtonClickClearFilter(event) {
		if (event) event.preventDefault();
		this.refs.search.value = '';
		this.props.methods.handleFilterSearch('');
		this.refs.search.focus();
	}

	componentDidMount() {
		this.handleFilter = _debounce(this.handleFilter, 600);
	}

	componentDidMount() {
		this.refs.search.focus();
	}

	render() {
		const { translations } = this.props;
		return (
			<div className="search-list__quick-filter">
				<h4>{translations.QUICKFILTER}</h4>
				<span className="search-list__quick-filter__input-wrapper">
					<input
						tabIndex="29"
						onChange={this.onInputChangeFilter}
						ref="search"
						className=""
						type="search"
						name="search"
						placeholder={this.props.translations.SEARCH}
						autoComplete="off"
					/>
				</span>
				<button
					className="button search-list__quick-filter__button icon icon--clear"
					tabIndex="-1"
					onClick={this.onButtonClickClearFilter}
				/>
			</div>
		);
	}
}

QuickFilter.propTypes = {
	methods: PropTypes.object.isRequired,
	translations: PropTypes.object.isRequired
};

export default QuickFilter;
