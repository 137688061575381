'use strict';

import { ReportFactory as R } from 'report/factory';

const t = R.table();

const AuditSalaryPayrollReportTable = t.table([
	t.column(
		'Stores',
		t.contentWrapper({
			body: [t.content(d => R.data(d, 'workplace.name'))],
			foot: [t.content('Total')]
		}),
		300
	),
	t.column(
		'Hours',
		[t.content(d => R.data(d, 'total_hours'), 'numberSingleDecimal')],
		200,
		'number'
	),
	t.column(
		'Total',
		[t.content(d => R.data(d, 'total_pay'), 'currency')],
		200,
		'currency'
	)
]);

AuditSalaryPayrollReportTable.setTitle(t.title(d => `${R.data(d, 'title')}`));
AuditSalaryPayrollReportTable.setTableType('foldable');

export default AuditSalaryPayrollReportTable;
