export default {
	TABLE_TITLE: 'Ingredient Alternatives',
	PRODUCT_VARIANT_INGREDIENT_ALTERNATIVES_ADDED: 'Alternative added',
	PRODUCT_VARIANT_INGREDIENT_ALTERNATIVE_EDITED: 'Alternative edited',
	PRODUCT_VARIANT_INGREDIENT_ALTERNATIVES_EDITED: 'Alternatives edited',
	PRODUCT_VARIANT_INGREDIENT_ALTERNATIVES_DELETED: 'Alternative deleted',

	// batch
	MODAL_BATCH_CHOICE_TITLE: 'Select an alternative',
	MODAL_BATCH_CHOICE_INGREDIENT: 'Ingredient alternatives',
	MODAL_BATCH_CHOICE_PRODUCT_VARIANT_GROUP: 'Product alternatives',
	MODAL_BATCH_CHOICE_NONE: 'Make excludable',
	MODAL_BATCH_STEP_PRODUCT_VARIANT_INGREDIENT: 'Product variant ingredient',
	MODAL_BATCH_STEP_PRODUCT_VARIANT_INGREDIENT_PLACEHOLDER:
		'Select product variant ingredient...',
	MODAL_BATCH_STEP_INGREDIENT: 'Ingredient',
	MODAL_BATCH_STEP_INGREDIENT_PLACEHOLDER: 'Select ingredient...',
	MODAL_BATCH_STEP_PRODUCT_VARIANT_GROUP: 'Product variant group',
	MODAL_BATCH_STEP_PRODUCT_VARIANT_GROUP_PLACEHOLDER:
		'Select product variant group...',
	PRODUCT_VARIANT_INGREDIENTS_TABLE_TITLE: 'Product variant ingredients',
	MODAL_BATCH_STEP_SORT_ORDER: 'Sort order',
	MODAL_BATCH_STEP_SORT_ORDER_PLACEHOLDER: 'Enter sort order...',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'Next',
	MODAL_BATCH_STEP_LIST_TITLE: 'Confirm entries',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Submit',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'Back',
	MODAL_BATCH_STEP_LIST_CANCEL_BUTTON_LABEL: 'Cancel',
	INGREDIENTS_TABLE_TITLE: 'Ingredients',
	PRODUCT_VARIANT_GROUPS_TITLE: 'Product variant groups',
	DELETE_ROW_TOOLTIP: 'Delete row',
	TYPE: 'Type',
};
