export const RESET_ALLERGEN =
	'SALES_CONFIGURATION/RESET_ALLERGEN';
export const SET_ALLERGEN =
	'SALES_CONFIGURATION/SET_ALLERGEN';
export const UPDATE_ALLERGEN =
	'SALES_CONFIGURATION/UPDATE_ALLERGEN';

/**
 * @param {object} payload
 */
export function setAllergen(payload) {
	return {
		type: SET_ALLERGEN,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateAllergen(payload) {
	return {
		type: UPDATE_ALLERGEN,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET_ALLERGEN
	};
}