/**
 * Created by mikkelmarkvardsen on 24/01/2017.
 */

import _findIndex from 'lodash/findIndex';

/**
 * Calculate amount of hours a person has.
 * @param {Array} mappedCollection
 * @param {Array} employersContribution
 * @returns {*}
 */
export default function mapEmployeeContributions(
	mappedCollection = [],
	employersContribution = []
) {
	let totalSalary = null;
	let averageSalaryPrHour = null;

	// Find position in array of the total of the currentworkplace where the description matches salarycomponent name
	const foundIndex = _findIndex(mappedCollection.totals, {
		description: 'Total',
	});

	// Total is calculated
	if (foundIndex !== -1) {
		const contribution = Number(employersContribution?.[0]?.contribution ?? 0);

		employersContribution =
			(mappedCollection.totals[foundIndex].salary / 100) * contribution;

		totalSalary =
			employersContribution + mappedCollection.totals[foundIndex].salary;

		averageSalaryPrHour =
			totalSalary / mappedCollection.totals[foundIndex].hoursWorked;
	}

	return {
		mappedCollection,
		employersContribution,
		totalSalary,
		averageSalaryPrHour,
	};
}
