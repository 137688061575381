/**
 * Created by mikkelmarkvardsen on 24/01/2017.
 */
/**
 * Calculate amount of hours a person has.
 * @param {array} salaryExports
 * @returns {*}
 */

import _isNull from 'lodash/isNull';
import _findIndex from 'lodash/findIndex';

export default function mapSalaryExports(salaryExports = []) {
	let mappedSalaryExports = {};
	const workplaceTotals = {};
	const totals = [];

	const totalsTotal = {
		description: 'Total',
		hoursWorked: 0,
		salary: 0,
	};

	// Iterate over all salary components
	mappedSalaryExports = salaryExports.reduce(
		(mappedSalaryExports, salaryExport) => {
			const workplaceName = salaryExport.workplace.name;
			const workplaceId = salaryExport.workplace.id;

			const employmentPersonName =
				salaryExport.employment?.person?.identity?.full_name ?? '';

			// check if employmentId exists in object and update according
			if (!mappedSalaryExports[workplaceName]) {
				mappedSalaryExports[workplaceName] = {
					id: workplaceId,
					employments: {},
				};
			}

			if (
				!mappedSalaryExports[workplaceName].employments[employmentPersonName]
			) {
				mappedSalaryExports[workplaceName].employments[employmentPersonName] = [
					{
						description: salaryExport.salary_component.name,
						hoursWorked: salaryExport.total.units,
						salary: salaryExport.total.amount,
					},
				];
			} else {
				if (
					mappedSalaryExports[workplaceName].employments[employmentPersonName]
						.description === salaryExport.salary_component.name
				) {
					mappedSalaryExports[workplaceName].employments[
						employmentPersonName
					].hoursWorked += salaryExport.total.units;
					mappedSalaryExports[workplaceName].employments[
						employmentPersonName
					].salary += salaryExport.total.amount;
				} else {
					mappedSalaryExports[workplaceName].employments[
						employmentPersonName
					].push({
						description: salaryExport.salary_component.name,
						hoursWorked: salaryExport.total.units,
						salary: salaryExport.total.amount,
					});
				}
			}

			// If workplaceTotals does not exists, init as array
			if (!workplaceTotals[workplaceName]) {
				workplaceTotals[workplaceName] = [];
			}

			// Find position in array of the total of the currentworkplace where the description matches salarycomponent name
			const foundObject = _findIndex(workplaceTotals[workplaceName], (e) => {
				return e.description === salaryExport.salary_component.name;
			});

			// If it does not exist add the component to the totals
			if (foundObject === -1) {
				workplaceTotals[workplaceName].push({
					description: salaryExport.salary_component.name,
					hoursWorked: salaryExport.total.units,
					salary: salaryExport.total.amount,
				});

				// Else sum up the current workplace total
			} else {
				workplaceTotals[workplaceName][foundObject].hoursWorked +=
					salaryExport.total.units;
				workplaceTotals[workplaceName][foundObject].salary +=
					salaryExport.total.amount;
			}

			return mappedSalaryExports;
		},
		mappedSalaryExports
	);

	// Sum of totals for all bars
	Object.keys(workplaceTotals).map((workplaceName) => {
		workplaceTotals[workplaceName].map((data, index) => {
			// Make sure object is a copy. Or else this will lead to problems.
			const workplace = Object.assign(
				{},
				workplaceTotals[workplaceName][index]
			);

			// Check if total for workplace is already present
			const foundTotal = _findIndex(
				workplaceTotals[workplaceName],
				(e) => e.description === 'Total'
			);

			// If not, push 'Total' to workplace Array
			if (foundTotal === -1) {
				workplaceTotals[workplaceName].push({
					description: 'Total',
					salary: workplaceTotals[workplaceName][index].salary,
					hoursWorked: workplaceTotals[workplaceName][index].hoursWorked,
				});
			} else {
				workplaceTotals[workplaceName][foundTotal].salary += workplace.salary;
				workplaceTotals[workplaceName][foundTotal].hoursWorked +=
					workplace.hoursWorked;
			}

			// Push to totals for all bars
			const foundObject = _findIndex(
				totals,
				(e) => e.description === workplace.description
			);

			// If not present, add to existing
			if (foundObject === -1) {
				totals.push(workplace);
			} else {
				totals[foundObject].hoursWorked += workplace.hoursWorked;
				totals[foundObject].salary += workplace.salary;
			}
		});
	});

	if (!_isNull(totals)) {
		// Sum up the totals of the totals of all the salary components
		totals.reduce((totalsTotal, salaryComponentTotal) => {
			totalsTotal.hoursWorked += salaryComponentTotal.hoursWorked;
			totalsTotal.salary += salaryComponentTotal.salary;
			return totalsTotal;
		}, totalsTotal);

		totals.push(totalsTotal);
	}

	return { mappedSalaryExports, workplaceTotals, totals };
}
