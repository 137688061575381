'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import Color from 'reusableComponents/inputs/color.component';
import {
	SectionCollapsable,
	InputCollectionSelect,
	Input,
	Label,
} from 'dumb';

// Tools
import _differenceWith from 'lodash/differenceWith';
import _get from 'lodash/get';
// import _isEmpty from 'lodash/isEmpty';

import './addProductForm.css';

class AddProductForm extends Component {
	constructor(props) {
		super(props);

		this.modalRef = React.createRef();

		this.state = {
			showColorPicker: false,
			showTextColorPicker: false,
			loader: false,
			previewIsOpen: true,
		};

		// Binds
		this._onInputUpdate = this._onInputUpdate.bind(this);
		this._handleProductVariantAdd = this._handleProductVariantAdd.bind(this);
		this._handleFlairAdd = this._handleFlairAdd.bind(this);
	}

	_getValueLabel(product) {
		if (product)
			return {
				value: product.product_variant,
				label: product.name,
				position: product.position,
			};
		else return null;
	}

	_toggleColorPicker(type) {
		if (type === 'text') {
			this.setState((prev) => ({
				showTextColorPicker: !prev.showTextColorPicker,
			}));
		} else {
			this.setState((prev) => ({
				showColorPicker: !prev.showColorPicker,
			}));
		}
	}

	_toggleTextColorPicker() {
		this.setState((prev) => ({
			showTextColorPicker: !prev.showTextColorPicker,
		}));
	}

	_onInputUpdate(e, type) {
		const { productTile } = this.props;

		const data = {
			...productTile,
		};

		switch (type) {
			case 'title': {
				const value = e.target.value;
				data.name = value;
				break;
			}

			case 'description': {
				const value = e.target.value;
				data.description = value;
				break;
			}

			case 'color':
				data.background_color = e.hex.replace('#', '');
				// this._toggleColorPicker();
				break;

			case 'colorText':
				data.title_color = e.hex.replace('#', '');
				// this._toggleColorPicker();
				break;
		}

		this.props.onProductTileUpdate(data);
	}

	_handleProductVariantAdd(e) {
		const product = e
			? {
					id: e.value,
					name: e.label,
			  }
			: null;

		this.props.onProductTileUpdate({ product, name: e?.label ?? null });
	}

	_handleFlairAdd(e) {
		const flair = e
			? {
					id: e.value,
					name: e.label,
			  }
			: null;

		this.props.onProductTileUpdate({ flair });
	}

	_onToggleSection() {
		this.setState(() => ({ previewIsOpen: !this.state.previewIsOpen }));
	}

	render() {
		const {
			showColorPicker,
			showTextColorPicker,
			loader,
			previewIsOpen,
		} = this.state;

		const {
			productTile,
			// productVariantList,
			posConfigurationsVariantsList,
			imageAssets,
			isTypePos,
			subTypeRow,
		} = this.props;

		const product = _get(productTile, 'product.id', false)
			? {
					label: _get(productTile, 'product.name', null),
					value: _get(productTile, 'product.id', null),
			  }
			: null;
		const flair = productTile.flair
			? {
					label: _get(productTile, 'flair.name', null),
					value: _get(productTile, 'flair.id', null),
			  }
			: null;
		const productFlair = _get(productTile, 'product.flair.name', null);

		const options = _differenceWith(
			posConfigurationsVariantsList,
			(x, y) => x.label === y.label
		);

		const imageLeft = imageAssets.find(
			(x) =>
				x.type === 'Product Ingredients Left' &&
				_get(x, 'asset_collection.id', null) ===
					_get(productTile, 'product.asset_collection.id', null)
		);
		const imageMain = imageAssets.find(
			(x) =>
				x.type === 'Product Details' &&
				_get(x, 'asset_collection.id', null) ===
					_get(productTile, 'product.asset_collection.id', null)
		);
		const imageRight = imageAssets.find(
			(x) =>
				x.type === 'Product Ingredients Right' &&
				_get(x, 'asset_collection.id', null) ===
					_get(productTile, 'product.asset_collection.id', null)
		);

		const detailImageRow = subTypeRow
			? imageAssets.find(
					(entry) =>
						_get(entry, 'asset_collection.id', null) ===
							_get(productTile, 'product.asset_collection.id', null) &&
						entry.type === 'Row Layout Product Detail'
			  )
			: null;

		let backgroundColor = productTile.background_color;
		let titleColor = productTile.title_color;

		// if pos and add mode, invert colors
		if (isTypePos && !productTile.id) {
			backgroundColor = productTile.title_color;
			titleColor = productTile.background_color;
		}

		return (
			<div className="add-tile-modal">
				<SectionCollapsable isOpen title="Product">
					<div className="add-tile-modal__row">
						<div className="add-tile-modal__cell">
							<InputCollectionSelect
								id="add-product-form-product"
								label="Product"
								placeholder="Select product..."
								value={product}
								options={options}
								isLoading={loader}
								disabled={loader}
								onChange={this._handleProductVariantAdd}
							/>
						</div>
						{productFlair && (
							<div className="add-tile-modal__cell">
								<Label>Flair</Label>
								<span className="add-tile-modal__product-flair">
									{productFlair}
								</span>
							</div>
						)}
					</div>
				</SectionCollapsable>
				<SectionCollapsable isOpen title="Tile settings">
					<div className="add-tile-modal__row">
						<div className="add-tile-modal__cell">
							<Input
								id="add-product-form-title"
								label="Title"
								placeholder="Enter title..."
								value={productTile.name}
								onChange={(e) => this._onInputUpdate(e, 'title')}
							/>
						</div>
						<div className="add-tile-modal__cell">
							<Input
								id="add-product-form-description"
								label="Description"
								placeholder="Enter description..."
								value={productTile.description}
								onChange={(e) => this._onInputUpdate(e, 'description')}
							/>
						</div>
					</div>
					{subTypeRow && (
						<div className="add-tile-modal__row">
							<div className="add-tile-modal__cell">
								<InputCollectionSelect
									id="add-product-form-flair"
									label="Flair"
									name="product tile flair"
									placeholder="Select Flair..."
									className="add-pipeline__input"
									value={flair}
									disabled={loader}
									multi={false}
									clearable
									apiPath="/pos/flairs"
									params={{
										limit: 30,
									}}
									onChange={this._handleFlairAdd}
								/>
							</div>
						</div>
					)}

					<div className="add-tile-modal__row">
						<div className="add-tile-modal__cell">
							<Label title="Background color">Background Color</Label>

							<div className="add-tile-modal__row__color">
								<button
									className="button"
									onClick={() => this._toggleColorPicker()}>
									Picker
								</button>
								<div
									className="add-tile-modal__row__color__inner"
									style={{
										backgroundColor: `#${backgroundColor}`,
									}}
								/>
								<span>#{backgroundColor}</span>
							</div>
							{showColorPicker && (
								<div className="add-tile-modal__color-picker">
									<Color
										onChange={(e) => this._onInputUpdate(e, 'color')}
										triangle="hide"
										type="Twitter"
										// color={`#${groupData.background_color}`}
									/>
								</div>
							)}
						</div>

						<div className="add-tile-modal__cell">
							<Label title="Background color">Text Color</Label>

							<div className="add-tile-modal__row__color">
								<button
									className="button"
									onClick={() => this._toggleColorPicker('text')}>
									Picker
								</button>
								<div
									className="add-tile-modal__row__color__inner"
									style={{
										backgroundColor: `#${titleColor}`,
									}}
								/>
								<span>#{titleColor}</span>
							</div>
							{showTextColorPicker && (
								<div className="add-tile-modal__color-picker add-tile-modal__color-picker--right">
									<Color
										onChange={(e) => this._onInputUpdate(e, 'colorText')}
										triangle="hide"
										type="Twitter"
									/>
								</div>
							)}
						</div>
					</div>
				</SectionCollapsable>

				<SectionCollapsable
					isOpen={previewIsOpen}
					onToggle={() => this._onToggleSection()}
					title="Preview">
					<div className="tile-preview">
						{imageAssets.length ? (
							<>
								{subTypeRow ? (
									detailImageRow && (
										<img
											className="tile-preview__main"
											src={detailImageRow.resolution_one.url}
										/>
									)
								) : (
									<>
										{imageLeft && imageLeft.resolution_one && (
											<img
												className="tile-preview__left"
												src={imageLeft.resolution_one.url}
											/>
										)}

										{imageMain && imageMain.resolution_one && (
											<img
												className="tile-preview__main"
												src={imageMain.resolution_one.url}
											/>
										)}

										{imageRight && imageRight.resolution_one && (
											<img
												className="tile-preview__right"
												src={imageRight.resolution_one.url}
											/>
										)}
									</>
								)}
							</>
						) : null}
						<div className="tile-preview__content">
							<h3>{productTile.name}</h3>
							<p>{productTile.description}</p>
						</div>
					</div>
				</SectionCollapsable>
			</div>
		);
	}
}

AddProductForm.propTypes = {
	// Passed in props
	onProductTileUpdate: PropTypes.func,
	productTile: PropTypes.object,
	posConfigurationsVariantsList: PropTypes.array,
	imageAssets: PropTypes.array,
	isTypePos: PropTypes.bool,
	subTypeRow: PropTypes.bool,
};

export default AddProductForm;
