import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './waitingTimePerformanceGridEntries.phrases';

/**
 * @function fetchWaitingTimePerformanceGridEntries
 * @description Fetch all waiting time peformance grid entries
 * @param {object} state
 */
export function fetchWaitingTimePerformanceGridEntries(state, filter) {
	if (state.filter) filter = `${filter};${state.filter}`;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(
		'/administration/waiting_time_performance_grid_entries',
		params,
		null,
		state.headers
	)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addWaitingTimePerformanceGridEntries
 * @description add waiting time peformance grid entries
 */
export function addWaitingTimePerformanceGridEntries(data) {
	return post('/administration/waiting_time_performance_grid_entries', data)
		.then((res) => {
			setFeedback(phrases.WAITING_TIME_PERFORMANCE_GRID_ENTRY_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editWaitingTimePerformanceGridEntry
 * @description edit waiting time peformance grid entry
 */
export function editWaitingTimePerformanceGridEntry(data) {
	return post(
		`/administration/waiting_time_performance_grid_entries/${data.id}`,
		data
	)
		.then((res) => {
			setFeedback(phrases.WAITING_TIME_PERFORMANCE_GRID_ENTRY_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function editWaitingTimePerformanceGridEntries
 * @description edit waiting time peformance grid entries
 */
export function editWaitingTimePerformanceGridEntries(data) {
	return patch('/administration/waiting_time_performance_grid_entries', data)
		.then((res) => {
			setFeedback(phrases.WAITING_TIME_PERFORMANCE_GRID_ENTRIES_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function deleteWaitingTimePerformanceGridEntries
 * @description delete waiting time peformance grid entries
 */
export function deleteWaitingTimePerformanceGridEntries(id) {
	return remove(`/administration/waiting_time_performance_grid_entries/${id}`)
		.then((res) => {
			setFeedback(phrases.WAITING_TIME_PERFORMANCE_GRID_ENTRY_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
