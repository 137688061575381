'use strict';

import { state as defaultListState } from './details.defaults';
import _omit from 'lodash/omit';
/**
 * @function reducer
 * @description Reduces state to new state
 * @param  {object} state  current state
 * @param  {object} action action to reduce from
 * @return {object}        reduced state
 */

const defaultState = {
	...defaultListState,
};

function reducer(state = {}, action = {}) {
	switch (action.type) {
		case 'LEGACY_DETAILS__INIT_REDUCER': {
			const key = action.payload.reduxKey;
			return {
				...state,
				[key]: defaultState,
			};
		}

		case 'LEGACY_DETAILS__CLEAR_DATA': {
			const key = action.payload.reduxKey;

			const newState = _omit(state, [key]);

			return {
				...newState,
			};
		}

		case 'LEGACY_DETAILS__SET_DATA': {
			const key = action.payload.reduxKey;

			return {
				...state,
				[key]: {
					...state[key],
					data: action.payload.value,
				},
			};
		}
		case 'LEGACY_DETAILS__SET_HEADERS': {
			const key = action.payload.reduxKey;

			return {
				...state,
				[key]: {
					...state[key],
					headers: action.payload.value,
				},
			};
		}
		case 'LEGACY_DETAILS__SET_ITEM': {
			const key = action.payload.reduxKey;

			return {
				...state,
				[key]: {
					...state[key],
					item: action.payload.value,
				},
			};
		}
		case 'LEGACY_DETAILS__SET_ITEM_ID': {
			const key = action.payload.reduxKey;

			return {
				...state,
				[key]: {
					...state[key],
					itemId: action.payload.value,
				},
			};
		}
		case 'LEGACY_DETAILS__SET_IS_CREATING': {
			const key = action.payload.reduxKey;

			return {
				...state,
				[key]: {
					...state[key],
					isCreating: action.payload.value,
				},
			};
		}
		case 'LEGACY_DETAILS__SET_IS_EDITING': {
			const key = action.payload.reduxKey;

			return {
				...state,
				[key]: {
					...state[key],
					isEditing: action.payload.value,
				},
			};
		}
		case 'LEGACY_DETAILS__SET_DETAILS_ACTIONS': {
			const key = action.payload.reduxKey;

			return {
				...state,
				[key]: {
					...state[key],
					detailsActions: action.payload.value,
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
