'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

// components
import { Icon } from 'dumb';

// lodash
import _get from 'lodash/get';

// styles
import './reorderProductTileLayoutGroup.css';

const ReorderProductTileLayoutGroup = (props) => {
	const {
		imageAsset,
		group,
		activeTileLayoutGroup,
		setActiveTileLayoutGroup,
	} = props;

	const style = {
		color: `#${group.title_color}`,
	};

	const groupActive = activeTileLayoutGroup === group.name;

	return (
		<div
			className={cx('reorder-product-tile-layout-group', {
				'reorder-product-tile-layout-group--active': groupActive,
				'reorder-product-tile-layout-group--submitted': group.submitted,
			})}
			onClick={() => setActiveTileLayoutGroup(group.name)}>
			{imageAsset ? (
				<img
					className="reorder-product-tile-layout-group__image"
					src={_get(imageAsset, 'resolution_one.url', null)}
					alt="Tile image"
				/>
			) : (
				<div className="reorder-product-tile-layout-group__image" />
			)}

			<span style={style} className="reorder-product-tile-layout-group__text">
				{group.name}
			</span>

			{group.submitted && <Icon name="check" />}
		</div>
	);
};

ReorderProductTileLayoutGroup.propTypes = {
	imageAsset: PropTypes.object,
	group: PropTypes.object,
	activeTileLayoutGroup: PropTypes.string,
	setActiveTileLayoutGroup: PropTypes.func,
};

export default ReorderProductTileLayoutGroup;
