import _get from 'lodash/get';

/**
 * @function getPosConfigurationProductVariantFilter
 * @param {Object} object
 * @param {Object.number} productVariantId - id of product variant to fetch
 * @param {Object.array} selectedRows - array of selected rows, used to get pos configuration version id's
 * @description returns a filter to be used when fetching pos configuration product variants
 */
export function getPosConfigurationProductVariantFilter({
	productVariantId,
	selectedRows,
}) {
	// get pos configuration version id's
	const ids = selectedRows.reduce((acc, currentValue, index) => {
		const id = _get(currentValue, 'pos_configuration_version.id', null);

		acc =
			index === selectedRows.length - 1 ? `${acc}'${id}']` : `${acc}'${id}',`;

		return acc;
	}, '[');

	const productVariantFilter = `:product_variant.id=='${productVariantId}'`;

	const filter = `${productVariantFilter};:pos_configuration_version.id=IN=${ids}`;

	return filter;
}
