import {
	SET_ASSET_SUB,
	UPDATE_ASSET_SUB,
	RESET_ASSET_SUB,
	UPDATE_DEFAULT_ASSET_COLLECTION,
} from './assetLeftAligned.actions';

const defaultState = {
	data: {
		defaultAssets: {},
		defaultAssetCollection: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_ASSET_SUB: {
		return {
			...state,
			data: {
				...state.data,
				defaultAssets: payload,
			},
		};
	}

	case UPDATE_ASSET_SUB: {
		return {
			...state,
			data: {
				...state.data,
				defaultAssets: {
					...state.data.defaultAssets,
					// asset_collection: payload.asset_collection,
					domain: payload.domain,
					type: payload.type,
					resolution_one: payload.resolution_one,
					resolution_two: payload.resolution_two,
					resolution_three: payload.resolution_three,
				},
			},
		};
	}

	case UPDATE_DEFAULT_ASSET_COLLECTION: {
		return {
			...state,
			data: {
				...state.data,
				defaultAssetCollection: {
					...state.data.defaultAssetCollection,
					...payload,
				},
			},
		};
	}

	case RESET_ASSET_SUB: {
		return defaultState;
	}

	default:
		return state;
	}
}

export default reducer;
