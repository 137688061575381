'use strict';

export const SET_KPIS = 'MYSHIFTPLANNER_KPI/SET_KPIS';
export const TOGGLE_FETCHING = 'MY SHIFTPLANNER_KPI/TOGGLE_FETCHING';
export const RESET_KPIS = 'MYSHIFTPLANNER_KPI/RESET_KPIS';
export const SET_END_OF_PERIOD = 'MYSHIFTPLANNER/SET_END_OF_PERIOD';
export const SET_START_OF_PERIOD = 'MYSHIFTPLANNER/SET_START_OF_PERIOD';
export const NUMBER_OF_WEEKS_TO_SHOW = 'MYSHIFTPLANNER/NUMBER_OF_WEEKS_TO_SHOW';
export const SHIFTS_BEING_UPDATED = 'MYSHIFTPLANNER/SHIFTS_BEING_UPDATED';
export const SET_DAY_CARD_WIDTH = 'MYSHIFTPLANNER/SET_DAY_CARD_WIDTH';
export const TOGGLE_STATS = 'MYSHIFTPLANNER/TOGGLE_STATS';
export const SET_MYSHIFTPLANNER_STATS = 'MYSHIFTPLANNER/SET_MYSHIFTPLANNER_STATS';
export const RESET_MYSHIFTPLANNER_STATS = 'MYSHIFTPLANNER/RESET_MYSHIFTPLANNER_STATS';
export const SET_MYSHIFTPLANNER_STATS_START_END_DATE = 'MYSHIFTPLANNER/SET_MYSHIFTPLANNER_STATS_START_END_DATE';
export const UPDATE_SHIFT = 'MYSHIFTPLANNER/UPDATE_SHIFT';
export const SET_SHIFTS = 'MYSHIFTPLANNER/SET_SHIFTS';
export const ADD_SHIFT = 'MYSHIFTPLANNER/ADD_SHIFT';
export const REMOVE_SHIFT = 'MYSHIFTPLANNER/REMOVE_SHIFT';
export const ADD_UNASSIGNED_SHIFTS = 'MYSHIFTPLANNER/ADD_UNASSIGNED_SHIFTS';
export const REMOVE_UNASSIGNED_SHIFTS = 'MYSHIFTPLANNER/REMOVE_UNASSIGNED_SHIFTS';
export const SET_IS_MOUNTED = 'MYSHIFTPLANNER/SET_IS_MOUNTED';
export const ADD_SHIFT_TRANSFER_SHIFTS = 'MYSHIFTPLANNER/ADD_SHIFT_TRANSFER_SHIFTS';
export const RESET_SHIFT_TRANSFER = 'MYSHIFTPLANNER/RESET_SHIFT_TRANSFER';
export const SET_SHIFT_TRANSFER_SHIFT = 'MYSHIFTPLANNER/SET_SHIFT_TRANSFER_SHIFT';
export const SET_SHIFT_TRANSFER_POLICY = 'MYSHIFTPLANNER/SET_SHIFT_TRANSFER_POLICY';

export function setShiftTransferPolicy(payload) {
	return {
		type: SET_SHIFT_TRANSFER_POLICY,
		payload,
	};
}

export function setMyShiftplannerStatsStartEndDate(payload) {
	return {
		type: SET_MYSHIFTPLANNER_STATS_START_END_DATE,
		payload,
	};
}

export function toggleStats(payload) {
	return {
		type: TOGGLE_STATS,
		payload,
	};
}

export function setDayCardWidth(payload) {
	return {
		type: SET_DAY_CARD_WIDTH,
		payload,
	};
}

export function setKpis(shiftplannerKpis, weekNumber) {
	return {
		type: SET_KPIS,
		payload: { shiftplannerKpis, weekNumber },
	};
}

export function resetKpis() {
	return {
		type: RESET_KPIS,
	};
}

export function toggleFetching(fetching) {
	return {
		type: TOGGLE_FETCHING,
		payload: { fetching },
	};
}

export function setStartOfPeriod(date) {
	return {
		type: SET_START_OF_PERIOD,
		payload: date,
	};
}

export function setEndOfPeriod(endOfPeriod = null) {
	return {
		type: SET_END_OF_PERIOD,
		payload: endOfPeriod,
	};
}

export function setNumberOfWeeksToShow(numberOfWeeksToShow = 0) {
	return {
		type: NUMBER_OF_WEEKS_TO_SHOW,
		payload: numberOfWeeksToShow,
	};
}

export function shiftsBeingUpdated(payload) {
	return {
		type: SHIFTS_BEING_UPDATED,
		payload,
	};
}

export function setMyShiftplannerStats(payload) {
	return {
		type: SET_MYSHIFTPLANNER_STATS,
		payload,
	};
}

export function resetMyShiftplannerStats() {
	return {
		type: RESET_MYSHIFTPLANNER_STATS,
	};
}

export function updateShift(payload) {
	return {
		type: UPDATE_SHIFT,
		payload,
	};
}

export function setShifts(payload) {
	return {
		type: SET_SHIFTS,
		payload,
	};
}

export function addUnassignedShifts(payload) {
	return {
		type: ADD_UNASSIGNED_SHIFTS,
		payload,
	};
}

export function removeUnassignedShifts() {
	return {
		type: REMOVE_UNASSIGNED_SHIFTS,
	};
}

export function addShift(payload) {
	return {
		type: ADD_SHIFT,
		payload,
	};
}

export function removeShift(payload) {
	return {
		type: REMOVE_SHIFT,
		payload,
	};
}

export function setIsMounted(payload) {
	return {
		type: SET_IS_MOUNTED,
		payload,
	};
}

export function addShiftTransferShifts(payload) {
	return {
		type: ADD_SHIFT_TRANSFER_SHIFTS,
		payload,
	};
}

export function resetShiftTransfer() {
	return {
		type: RESET_SHIFT_TRANSFER,
	};
}

export function setShiftTransferShift(payload) {
	return {
		type: SET_SHIFT_TRANSFER_SHIFT,
		payload,
	};
}
