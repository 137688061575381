import {
	SET_COLLECTION,
	SET_DATERANGE,
	SET_HOTBAR_FILTER,
	SET_SHOW_SALARY_EXPORTS,
	SET_SHOW_LOADER,
} from './salaryExports.actions';

import mapEmployeeContributions from './../services/utilities/mapEmployeeContributions.service';
import mapSalaryExports from './../services/utilities/mapSalaryExports.service';

const defaultState = {
	collection: [],
	dateRange: {},
	hotbarFilter: [],
	matrice: {},
	params: {
		offset: 0,
		limit: 300,
	},
	showSalaryExports: [],
	showLoader: false,
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_COLLECTION: {
			let collection = mapSalaryExports(
				payload.salaryExports,
				payload.employeeContributions
			);
			collection = mapEmployeeContributions(
				collection,
				payload.employeeContributions
			);

			return {
				...state,
				collection,
			};
		}

		case SET_DATERANGE: {
			return {
				...state,
				dateRange: payload,
			};
		}
		case SET_HOTBAR_FILTER: {
			return {
				...state,
				hotbarFilter: payload,
			};
		}
		case SET_SHOW_SALARY_EXPORTS: {
			return {
				...state,
				showSalaryExports: payload,
			};
		}

		case SET_SHOW_LOADER: {
			return {
				...state,
				showLoader: payload,
			};
		}

		default:
			return state;
	}
}

export default reducer;
