export default {
	MODAL_TITLE_MANAGER: 'Bar shift transfers',
	MODAL_TITLE_JUICER: 'Want new shifts?',
	CLOSE: 'Close',
	MODAL_TITLE_BM: 'Your juicers want to swap shifts',
	MODAL_TIP_BM:
		'You need to approve shift swaps in order to complete transfers. Once you complete the transfer, shiftplans will be automatically updated',
	MODAL_OWN_TRANSFERS_TOOLTIP:
		'All shift transfers need to be accepted by the bars BM',
	SHIFT_TRANSFER_APPROVED: 'Shift transfer approved',
	SHIFT_ACCEPTED: 'Shift accepted!',
	SHIFT_DECLINED: 'Shift declined!',
	SHIFT_TRANSFER_DECLINED: 'Shift transfer declined',
	APPROVED: 'Approved',
	DECLINED: 'Declined',
	APPROVE: 'Approve',
	ACCEPT: 'Accept',
	DECLINE: 'Decline',
	NO_POLICY_UUID: 'Cannot find policy for the operation',
	BACK: 'Back',
	ACCEPTED: 'Accepted',
	NEXT: 'Next',
	MODAL_TITLE_PENDING: 'Pending transfers',
	SHIFT_OWNER: 'Current shift owner',

	OVERTIME_WARNINGS_MODAL_HEADER: 'Overtime warnings',
	OVERTIME_WARNINGS_MODAL_SUBHEADER: 'The following requirements are not met:',
	OVERTIME_WARNINGS_MODAL_CONFIRM_LABEL: 'Acknowledge',

	// table list actions
	TABLE_LIST_NAME: 'Name',
	TABLE_LIST_SHIFT: 'Shift',
	TABLE_LIST_JUICER: 'Juicer',
	TABLE_LIST_SHIFT_OWNER: 'Shift owner',
	TABLE_LIST_BAR: 'Bar',
	TABLE_LIST_APPLICANTS: 'Applicants',
	TABLE_LIST_RECEIVER: 'Receiver',
	PUBLIC_TRANSFERS: 'Public transfers',
	SWAP_TRANSFERS: 'Swap transfers',

	// pending shift transfers
	SHIFTS_YOU_ARE_TRANSFERING: 'Shifts you are transfering',
	SHIFTS_YOU_APPLIED_FOR: 'Shifts you applied for',
	TABLE_LIST_STATE: 'State',
	CANCEL_TRANSFER: 'Cancel',
	REAPPLY_FOR_TRANSFER: 'Reapply',
	APPLIED_SHIFTS_TIP: "All shift changes need to be approved by the bar's BM",

	// shift transfer states in a more human readable form
	NO_APPLICANTS: 'No applicants',
	AWAITING_BM: 'Awaiting BM',
	UNASSIGNED_SHIFT: 'Unassigned shift',
};
