export const RESET_STATE = 'INGREDIENT_COMPONENT_TRANSLATIONS/RESET_STATE';
export const SET_INGREDIENT_COMPONENT_TRANSLATION =
	'INGREDIENT_COMPONENT_TRANSLATIONS/SET_INGREDIENT_COMPONENT_TRANSLATION';
export const UPDATE_INGREDIENT_COMPONENT_TRANSLATION =
	'INGREDIENT_COMPONENT_TRANSLATIONS/UPDATE_INGREDIENT_COMPONENT_TRANSLATION';

export const EDIT_BATCH_LIST_ITEM = 'INGREDIENT_COMPONENT_TRANSLATIONS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM = 'INGREDIENT_COMPONENT_TRANSLATIONS/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW = 'INGREDIENT_COMPONENT_TRANSLATIONS/ADD_BATCH_LIST_ITEM_ROW';
export const RERENDER_TABLE = 'INGREDIENT_COMPONENT_TRANSLATIONS/RERENDER_TABLE';

export function setIngredientComponentTranslation(payload) {
	return {
		type: SET_INGREDIENT_COMPONENT_TRANSLATION,
		payload,
	};
}

export function updateIgredientComponentTranslation(payload) {
	return {
		type: UPDATE_INGREDIENT_COMPONENT_TRANSLATION,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}

export function rerenderTable() {
	return {
		type: RERENDER_TABLE,
	};
}
