// interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';

import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './currencies.phrases';

function handleError(err: Error): Error {
	const error = formatErrorMessage(err);
	setFeedback(error, 0);

	throw error;
}

/**
 * @function fetchCurrencies
 * @description Fetch all companies
 * @param {object} state
 */
export function fetchCurrencies(state: IFetchDataProps): Promise<IApiRes> {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/administration/currencies', params, null, state.headers)
		.then((res: IApiRes) => res)
		.catch(handleError);
}

/**
 * @function addCurrencies
 * @description adds currency
 */
export function addCurrencies(data: Record<string, unknown>): Promise<IApiRes> {
	return post('/administration/currencies', data)
		.then((res: IApiRes) => {
			setFeedback(phrases.CURRENCY_ADDED, 1);
			return res;
		})
		.catch(handleError);
}

/**
 * @function editCurrencies
 * @description edits currency
 */
export function editCurrencies(
	id: number,
	data: Record<string, unknown>
): Promise<IApiRes> {
	return post(`/administration/currencies/${id}`, data)
		.then((res: IApiRes) => {
			setFeedback(phrases.CURRENCY_EDITED, 1);
			return res;
		})
		.catch(handleError);
}

/**
 * @function deleteCurrencies
 * @description deletes companies
 */
export function deleteCurrencies(id: number): Promise<IApiRes> {
	return remove(`/administration/currencies/${id}`)
		.then((res: IApiRes) => {
			setFeedback(phrases.CURRENCY_DELETED, 1);
			return res;
		})
		.catch(handleError);
}
