import * as Sentry from '@sentry/browser';

/**
 * @function transformFile
 * @param {Object} object - contains file/img and width that the image will be resized to
 * @description gets an image and specified width to be resized to
 * uses FileReader API and html5 Canvas to drawa new image
 */
export function transformFile({ file, width }) {
	return new Promise((resolve, reject) => {
		// get filename
		const fileName = file.name;
		const fileType = file.type;
		const reader = new FileReader();

		// read the file
		reader.readAsDataURL(file);

		// once the file is loaded start transforming it
		reader.onload = function (e) {
			const img = new Image();
			img.src = event.target.result;

			img.onload = () => {
				const aspectRatio = img.width / img.height;
				
				const height = width / aspectRatio;

				const canvas = document.createElement('canvas');
				canvas.width = width;
				canvas.height = height;

				// draw the new image
				const ctx = canvas.getContext('2d');
				ctx.drawImage(img, 0, 0, width, height);

				ctx.canvas.toBlob(
					(blob) => {
						const file = new File([blob], fileName, {
							type: fileType,
							lastModified: Date.now(),
						});

						resolve(file);
					},
					fileType,
					1
				);
			};

			img.onerror = (err) => {
				Sentry.withScope(scope => {
					scope.setExtra('error', err);
					Sentry.captureException(err);
					Sentry.captureMessage('Error in image onload', 'error');
				});
				reject(err);
			};
		};

		reader.onerror = (err) => {
			Sentry.withScope(scope => {
				scope.setExtra('error', err);
				Sentry.captureException(err);
				Sentry.captureMessage('Error in transformFile filereader', 'error');
			});
			reject(err);
		};
	});
}
