'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';

import ImagePreview from './components/imagePreview.component';
import { Loader } from 'dumb';

import './imageTileSelector.css';

export default class ImageTileSelector extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searching: false,
			numOfImagesLoaded: 20,
			images: props.images,
			loadingExtra: false
		};

		this._onSearch = _debounce(this._onSearch, 200);

		this.imageBody = document.getElementById('image-selector__body');
		this._scrollEventListener = this._scrollEventListener.bind(this);
	}

	componentDidMount() {
		document
			.getElementById('image-selector__body')
			.addEventListener('scroll', this._scrollEventListener);

		this.props.fetchImages &&
			this.props.fetchImages(0, this.props.initialNumOfImages).then(images => {
				this.setState(() => ({ images: images }));
			});

		this.setState({ numOfImagesLoaded: this.props.initialNumOfImages });
	}

	componentWillUnmount() {
		const element = document.getElementById('image-selector__body');

		if (element)
			element.removeEventListener('scroll', this.scrollEventListener);
	}

	_scrollEventListener() {
		if (!this.props.fetchImages) return;

		const imageBody = document.getElementById('image-selector__body');
		if (
			imageBody.scrollTop + imageBody.clientHeight >=
			imageBody.scrollHeight
		) {
			if (this.props.totalEntries > this.state.numOfImagesLoaded) {
				const imagesLoaded =
					this.state.numOfImagesLoaded + this.props.initialNumOfImages;
				this.setState(() => ({ loadingExtra: true }));

				this.props
					.fetchImages(this.state.numOfImagesLoaded, imagesLoaded)
					.then(images => {
						this.setState(prevState => ({
							images: [...prevState.images, ...images],
							numOfImagesLoaded: imagesLoaded,
							loadingExtra: false
						}));
					});
			}
		}
	}

	renderContent() {
		const { onSelect, selectedImage } = this.props;
		const { images } = this.state;

		if (images.length > 0)
			return images.map(image => (
				<ImagePreview
					image={image}
					key={image.id}
					onSelect={onSelect}
					selectedImageId={selectedImage.id}
				/>
			));

		return <div className="image-selector__placeholder">No images</div>;
	}

	_onSearch(value) {
		this.setState({ searching: true });
		this.props
			.onSearch(value)
			.then(images => {
				this.setState({
					searching: false,
					images: images,
					numOfImagesLoaded: 0
				});
			})
			.catch(() => {
				this.setState({ searching: false });
			});
	}

	render() {
		const { selectedImage, loading, height, onSearch } = this.props;
		const { searching, loadingExtra } = this.state;

		return (
			<div className="image-selector">
				<div className="image-selector__header">
					{onSearch && (
						<div className="image-selector__search-bar">
							<input
								type="text"
								placeholder="search"
								onChange={event => this._onSearch(event.target.value)}
							/>
							{searching && (
								<div className="image-selector__search-loader">
									<Loader size="small" />
								</div>
							)}
						</div>
					)}
					{selectedImage && (
						<div className="image-selector__selected-name">
							{selectedImage.name}
						</div>
					)}
				</div>
				<div
					style={{ height: `${height}px` }}
					className="image-selector__body"
					id="image-selector__body">
					{loading ? (
						<div className="image-selector__loader">
							<Loader size="medium" />
						</div>
					) : (
						this.renderContent()
					)}
				</div>
				{loadingExtra && (
					<div className="image-selector__loading-extra">
						<Loader size="medium" />
					</div>
				)}
			</div>
		);
	}
}

ImageTileSelector.defaultProps = {
	images: [],
	loading: false,
	selectedImage: {},
	initialNumOfImages: 25,
	height: 200,
	onSearch: null,
	fetchImages: null
};

ImageTileSelector.propTypes = {
	images: PropTypes.arrayOf(
		PropTypes.shape({
			src: PropTypes.string.isRequired,
			id: PropTypes.number.isRequired,
			name: PropTypes.number.isRequired
		})
	),
	onSelect: PropTypes.func,
	selectedImage: PropTypes.object,
	loading: PropTypes.bool,
	onSearch: PropTypes.func,
	fetchImages: PropTypes.func,
	initialNumOfImages: PropTypes.number,
	totalEntries: PropTypes.number,
	height: PropTypes.number
};
