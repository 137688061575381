'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';

// different batch views
import WorkplaceWaitingTimePerformanceGridsBatchStepForm from './components/workplaceWaitingTimePerformanceGridsBatchStepForm';
import WorkplaceWaitingTimePerformanceGridsBatchStepList from './components/workplaceWaitingTimePerformanceGridsBatchStepList';

// phrases/ constants/ moment
import phrases from './../../workplaceWaitingTimePerformanceGrids.phrases';
import constants from 'services/constants';
import moment from 'moment';

// loash
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';

class WorkplaceWaitingTimePerformanceGridsModalBatchAdd extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};

		this._onSubmitForm = this._onSubmitForm.bind(this);
		this._batchSubmit = this._batchSubmit.bind(this);

		this.modalRef = React.createRef();
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_onSubmitForm() {
		const { batchForm, addBatchList } = this.props;

		const listArray = batchForm.workplace.map((entry) => {
			return {
				identifier: _uniqueId('row-'),
				workplace: entry,
				periodFrom: batchForm.periodFrom
					? moment.utc(batchForm.periodFrom).format(constants.shortDate)
					: moment.utc().format(constants.shortDate),
				periodTo: batchForm.periodTo
					? moment.utc(batchForm.periodTo).format(constants.shortDate)
					: '9999-12-31',
			};
		});

		addBatchList(listArray);

		this._goToStep(2);
	}

	_batchSubmit() {
		const {
			batchList,
			handleClose,
			addWorkplaceWaitingTimePerformanceGrids,
			waitingTimePerformanceGridId,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchList.map((entry) => {
			return {
				waiting_time_performance_grid: waitingTimePerformanceGridId,
				workplace: entry?.workplace?.value?.id ?? null,
				period: {
					from: entry.periodFrom,
					to: entry.periodTo,
				},
			};
		});

		addWorkplaceWaitingTimePerformanceGrids({ batch: payload })
			.then(() => {
				this.setState(() => ({
					loadingModal: false,
				}));

				handleClose();
			})
			.catch(() =>
				this.setState(() => ({
					loadingModal: false,
				}))
			);
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchForm,
			handleClose,
			batchList,
			removeBatchListItem,
			workplaceFilter,
		} = this.props;

		const steps = [
			{
				component: (
					<WorkplaceWaitingTimePerformanceGridsBatchStepForm
						batchForm={batchForm}
						updateBatchForm={updateBatchForm}
						workplaceFilter={workplaceFilter}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE,
				isValid: !_isEmpty(batchForm.workplace),
				onNext: this._onSubmitForm,
				loading: false,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				noScroll: false,
				allowOverflow: true,
			},
			{
				component: (
					<WorkplaceWaitingTimePerformanceGridsBatchStepList
						batchList={batchList}
						removeBatchListItem={removeBatchListItem}
						loyaltyProductUrl={this.loyaltyProductUrl}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: this._batchSubmit,
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return modalVisible ? (
			<ModalStepper
				ref={this.modalRef}
				isOpen={modalVisible}
				steps={steps}
				showStep={false}
				onClose={handleClose}
			/>
		) : null;
	}
}

WorkplaceWaitingTimePerformanceGridsModalBatchAdd.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchForm: PropTypes.object,
	addBatchList: PropTypes.func,
	batchList: PropTypes.array,
	removeBatchListItem: PropTypes.func,
	addWorkplaceWaitingTimePerformanceGrids: PropTypes.func,
	waitingTimePerformanceGridId: PropTypes.number,
	workplaceFilter: PropTypes.string,
};

export default WorkplaceWaitingTimePerformanceGridsModalBatchAdd;
