import PropTypes from 'prop-types';

// redux
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
	setCustomColumnFilter,
	setListFilter,
} from './../filterSortColumnsData.actions';

// lodash
import _get from 'lodash/get';

// enums
import FilterProviderEnums from './filterProvider.enums';

/**
 * @function FilterValueProvider
 * @description fetches custom filter values from the list filter store and sets them into redux.
 * @returns filterValue - Value fetched from the redux store of filterSortColumnsData.
 */
const FilterValueProvider = ({ reduxKey, columnId, provide, filterKey }) => {
	if (!reduxKey) console.warn('No reduxKey specified');
	if (!columnId) console.warn('No columnId specified');

	// redux hooks
	const filterValue = useSelector((state) => {
		if (filterKey === FilterProviderEnums.CUSTOM)
			return _get(
				state,
				`filterSortColumnsData.tables[${reduxKey}][${filterKey}][${columnId}]`,
				null
			);
		if (filterKey === FilterProviderEnums.FILTERS) {
			const filtersArray = _get(
				state,
				`filterSortColumnsData.tables[${reduxKey}][${filterKey}]`,
				[]
			);

			return filtersArray.find((entry) => entry.id === columnId);
		}
	}, shallowEqual);
	const tableFilters = useSelector(
		(state) =>
			_get(state, `filterSortColumnsData.tables[${reduxKey}].filters`, []),
		shallowEqual
	);

	const dispatch = useDispatch();
	const persistToFilterStorage = ({
		handle,
		value,
		filterType,
		dateIdentifier,
	}) => {
		if (filterKey === FilterProviderEnums.CUSTOM)
			dispatch(setCustomColumnFilter({ reduxKey, handle, value }));
		if (filterKey === FilterProviderEnums.FILTERS) {
			const filter = [
				...tableFilters.filter((entry) => entry.id !== handle),
				...(value
					? [
							{
								id: handle,
								value,
								filterType,
								...(dateIdentifier && { dateIdentifier }),
							},
					  ]
					: []),
			];

			dispatch(
				setListFilter({
					reduxKey,
					filter,
				})
			);
		}
	};

	return provide(filterValue, persistToFilterStorage);
};

FilterValueProvider.proptypes = {
	provide: PropTypes.func,
	columnName: PropTypes.string,
	reduxKey: PropTypes.string,
	filterKey: PropTypes.string,
};

FilterValueProvider.defaultProps = {
	reduxKey: '',
	columnId: '',
	provide: () => {},
	filterKey: FilterProviderEnums.CUSTOM,
};

export default FilterValueProvider;
