import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './workplaceWaitingTimePerformanceGrids.phrases';

/**
 * @function fetchWorkplaceWaitingTimePerformanceGrids
 * @description Fetch all workplace waiting time peformance grid
 * @param {object} state
 */
export function fetchWorkplaceWaitingTimePerformanceGrids(state, filter) {
	if (state.filter) filter = `${filter};${state.filter}`;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(
		'/administration/workplace_waiting_time_performance_grids',
		params,
		null,
		state.headers
	)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addWorkplaceWaitingTimePerformanceGrids
 * @description add workplace waiting time peformance grids
 */
export function addWorkplaceWaitingTimePerformanceGrids(data) {
	return post('/administration/workplace_waiting_time_performance_grids', data)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_WAITING_TIME_PERFORMANCE_GRID_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editWorkplaceWaitingTimePerformanceGrid
 * @description edit workplace waiting time peformance grid
 */
export function editWorkplaceWaitingTimePerformanceGrid(data) {
	return post(
		`/administration/workplace_waiting_time_performance_grids/${data.id}`,
		data
	)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_WAITING_TIME_PERFORMANCE_GRID_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function editWorkplaceWaitingTimePerformanceGrids
 * @description edit workplace waiting time peformance grids
 */
export function editWorkplaceWaitingTimePerformanceGrids(data) {
	return patch('/administration/workplace_waiting_time_performance_grids', data)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_WAITING_TIME_PERFORMANCE_GRIDS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function deleteWorkplaceWaitingTimePerformanceGrids
 * @description delete workplace waiting time peformance grids
 */
export function deleteWorkplaceWaitingTimePerformanceGrids(id) {
	return remove(
		`/administration/workplace_waiting_time_performance_grids/${id}`
	)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_WAITING_TIME_PERFORMANCE_GRID_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
