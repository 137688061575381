import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';

import ProductionAreasList from './services/productionAreasList.service.js';

const productionAreasListReduxKey = '/product/production_areas';
const productionAreasList = new ProductionAreasList(productionAreasListReduxKey);
const productionAreasListProps = productionAreasList.getProps();

export function ProductionAreas() {
	useEffect(() => {
		productionAreasList.initView();
	}, []);

	return <ListComponent reduxKey={productionAreasListReduxKey} {...productionAreasListProps} />;
}
