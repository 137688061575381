import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './loader.css';
import img from './joe-logo.svg';

export default class Loader extends PureComponent {
	render() {
		const { children, className, size, active, logo, fullScreen, theme } =
			this.props;

		const wrapperCx = cx('j-loader-wrapper', {
			[`j-loader-wrapper--full-screen`]: fullScreen,
		});

		const classnames = cx('j-loader', {
			[`${className}`]: className,
			[`j-loader--${size}`]: size,
			[`j-loader--${active}`]: active,
			[`j-loader--logo`]: logo,
			[`j-loader--${theme}`]: theme,
			[`j-loader--text`]: children,
		});

		return (
			<div className={wrapperCx}>
				<div className={classnames}>
					{logo && (
						<Fragment>
							<img src={img} />
							<span>{children}</span>
						</Fragment>
					)}

					{!logo && (
						<Fragment>
							<div data-cy="jtj-loader" className="j-loader__spinner" />
							<span>{children}</span>
						</Fragment>
					)}
				</div>
			</div>
		);
	}
}

Loader.propTypes = {
	// shared props
	className: PropTypes.string,
	children: PropTypes.node,
	size: PropTypes.string,
	active: PropTypes.bool,
	theme: PropTypes.string,
	logo: PropTypes.bool,
	fullScreen: PropTypes.bool,
};
