export default {
	SHIFT_TRANSFER_MODAL: {
		ACCEPT_POLICY: 'accept policy',
		DECLINE_APPLICATION: 'decline application',
	},
	SHIFT_MARKETPLACE: {
		// 15 km
		DEFAULT_WORKPLACE_DISTANCE_FILTER: 15,
	},
};
