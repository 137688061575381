'use strict';

/**
 * @function collectionFormat
 * @memberOf SERVICES.__models
 * @description
 * Formats collection returned from API call
 * @param {array} collection  collection to format
 */

import _each from 'lodash/each';

module.exports = function(collection) {
	_each(collection, function(item) { // eslint-disable-line
		item = this.itemFormat(item);
	}.bind(this));
	return collection;
};