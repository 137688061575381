'use strict';
import constants from 'services/constants';
import moment from 'moment';
import {
	RESET_STATE,
	UPDATE_BATCH_FORM,
	ADD_BATCH_LIST,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	SET_POS_CONFIGURATION_DISCOUNTS,
	UPDATE_POS_CONFIGURATION_DISCOUNTS,
	SET_POS_CONFIGURATION_DISCOUNTS_TABLE_FILTER,
	SET_POS_CONFIGURATION_DISCOUNT_LEGACY_FILTER
} from './salesConfigurationPosConfigurationDiscounts.actions';

const todayDate = moment.utc().format(constants.shortDate);
const posConfigurationDiscountDateFilter = `:pos_configuration_version.active.from=le='${todayDate}';:pos_configuration_version.active.to=ge='${todayDate}'`;

const defaultState = {
	data: {
		defaultPosConfigurationDiscount: {},
		batchFormData: {
			availableInApp: true
		},
		batchList: []
	},
	posConfigurationDiscountDateFilter,
	legacyFilter: ':pos_configuration_version.pos_configuration.legacy==false',
	legacyFilterOn: true,
};

function reducer(state = defaultState, action) {
	const { payload, type } = action;

	switch (type) {
	case UPDATE_BATCH_FORM: {
		return {
			...state,
			data: {
				...state.data,
				batchFormData: {
					...state.data.batchFormData,
					...payload
				}
			}
		};
	}

	case ADD_BATCH_LIST: {
		return {
			...state,
			data: {
				...state.data,
				batchList: payload
			}
		};
	}

	case EDIT_BATCH_LIST_ITEM: {
		return {
			...state,
			data: {
				...state.data,
				batchList: state.data.batchList.map(entry => {
					if (entry.identifier === payload.identifier) return payload;
					return entry;
				})
			}
		};
	}

	case REMOVE_BATCH_LIST_ITEM: {
		return {
			...state,
			data: {
				...state.data,
				batchList: state.data.batchList.filter(
					entry => entry.identifier !== payload
				)
			}
		};
	}

	case SET_POS_CONFIGURATION_DISCOUNTS: {
		return {
			...state,
			data: {
				...state.data,
				defaultPosConfigurationDiscount: payload
			}
		};
	}

	case UPDATE_POS_CONFIGURATION_DISCOUNTS: {
		return {
			...state,
			data: {
				...state.data,
				defaultPosConfigurationDiscount: {
					...state.data.defaultPosConfigurationDiscount,
					...payload
				}
			}
		};
	}

	case SET_POS_CONFIGURATION_DISCOUNTS_TABLE_FILTER: {
		return {
			...state,
			posConfigurationDiscountDateFilter: payload
		};
	}

	case SET_POS_CONFIGURATION_DISCOUNT_LEGACY_FILTER: {
		return {
			...state,
			legacyFilter: payload,
		};
	}

	case RESET_STATE: {
		return {
			...state,
			data: defaultState.data
		};
	}

	default:
		// never return default state in default case, Filip!!!
		return state;
	}
}

export default reducer;
