import React, { Component } from 'react';
import cx from 'classnames';

// Components
import { Modal } from 'dumb';
import DefaultFooter from './components/defaultFooter';
import ConfirmationFooter from './components/confirmationFooter';

// Services
import confirmModal from '../../services/utils/confirmModal/confirmModal';

// Interfaces
import { StepObjectType } from './types';

type ModalStepperProps = {
	isOpen?: boolean;
	className?: string;
	steps: StepObjectType[];
	startStep?: number;
	showStep?: boolean;
	closeConfirm?: boolean;
	onClose: () => void;
	modalTip?: string;
	allowOverflow?: boolean;
	header?: string;
	dataCy?: string;
};

type ModalStepperState = {
	activeStep: number;
	disableBindKeys: boolean;
};

class ModalStepper extends Component<ModalStepperProps, ModalStepperState> {
	static defaultProps: { closeConfirm: boolean };
	constructor(props: ModalStepperProps) {
		super(props);

		this.state = {
			activeStep: props.startStep || 0,
			disableBindKeys: false,
		};

		this._goNext = this._goNext.bind(this);
		this._goBack = this._goBack.bind(this);
		this._onClose = this._onClose.bind(this);
		this.goToStep = this.goToStep.bind(this);
		this.toggleBindKeys = this.toggleBindKeys.bind(this);
	}

	/**
	 * @function goToStep
	 * @description Takes in number or empty (next), to allow changes of steps
	 */
	goToStep(step: number) {
		if (!step) {
			this.setState(() => ({ activeStep: this.state.activeStep + 1 }));
		} else {
			this.setState(() => ({ activeStep: Number(step) - 1 }));
		}
	}

	_goNext(stepObject: StepObjectType) {
		const { steps } = this.props;

		if (stepObject.onNext) {
			// Execute custom onNext()
			stepObject.onNext();
		} else if (steps.length - 1 === this.state.activeStep) {
			// If its the LAST step, there must be onNext()
			console.info('onNext prop is missing on this step!');
		} else {
			// Default, just go to next step!
			this.setState(() => ({ activeStep: this.state.activeStep + 1 }));
		}
	}

	_goBack(stepObject: StepObjectType) {
		const { steps } = this.props;

		if (stepObject.onBack) {
			// Execute custom onNext()
			stepObject.onBack();
		} else if (steps.length - 1 === this.state.activeStep) {
			// If its the FIRST step, there must be onBack()
			console.info('onBack prop is missing on this step!');
		} else {
			// Default, just go to prev step!
			this.setState(() => ({ activeStep: this.state.activeStep - 1 }));
		}
	}

	renderFooter() {
		const { steps } = this.props;
		const stepObject = steps[this.state.activeStep];
		const valid = stepObject.isValid;
		const noBack = stepObject.noBack;
		const customFooter = stepObject.customFooter;

		if (customFooter) {
			return customFooter;
		} else if (this.state.activeStep !== 0 && !noBack) {
			return (
				<ConfirmationFooter
					valid={valid}
					onConfirmClick={() => this._goNext(stepObject)}
					onCancelClick={() => this._goBack(stepObject)}
					confirmButtonLabel={stepObject.confirmButtonLabel}
					cancelButtonLabel={stepObject.cancelButtonLabel}
				/>
			);
		}

		return (
			<DefaultFooter
				onClick={() => this._goNext(stepObject)}
				buttonLabel={stepObject.confirmButtonLabel}
				valid={valid}
			/>
		);
	}

	_onClose() {
		const { steps, onClose, closeConfirm } = this.props;
		const stepObject = steps[this.state.activeStep];

		if (closeConfirm) {
			this.toggleBindKeys();
			confirmModal.show({
				data: {},
				onConfirm: stepObject.onClose ? stepObject.onClose : onClose,
				callback: this.toggleBindKeys,
			});
			return null;
		}
		return stepObject.onClose ? stepObject.onClose() : onClose();
	}

	toggleBindKeys() {
		this.setState(() => ({ disableBindKeys: !this.state.disableBindKeys }));
	}

	render() {
		const {
			steps,
			showStep,
			isOpen,
			className,
			allowOverflow,
			modalTip,
			dataCy,
		} = this.props;
		const stepObject = steps[this.state.activeStep];
		const renderComponent = React.cloneElement(stepObject.component);
		const stepsVisualized =
			showStep && `${this.state.activeStep + 1} of ${steps.length}`;
		const loading = stepObject.loading || false;
		const classNames = cx('modal-stepper', {
			[`${className}`]: className,
		});

		return isOpen ? (
			<Modal
				header={stepObject.title}
				isOpen={isOpen}
				dataCy={dataCy}
				disableKeyBindings={this.state.disableBindKeys}
				type="custom"
				steps={stepsVisualized}
				modalTip={modalTip}
				loading={loading}
				customFooter={this.renderFooter()}
				allowOverflow={allowOverflow}
				{...stepObject}
				onClose={() => this._onClose()}
				className={classNames}
			>
				{renderComponent}
			</Modal>
		) : null;
	}
}

ModalStepper.defaultProps = {
	closeConfirm: true,
};

export default ModalStepper;
