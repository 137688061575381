import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class DataBar extends Component {
	render() {
		const { width, height, label, color, value, animate, background, maxValue } = this.props;
		const divisable = Number(value)/Number(maxValue) || 0;
		const percentage = 1 - divisable;
		const h = value ? percentage * Number(height) : Number(height) - 0.2;
		const style = animate ? { "transition": "height 500ms, fill 300ms" } : null;

		return (
			<svg width={width} height={height} aria-label={label}>
				<rect width={width} height={height} fill={color} />
				<rect width={width} height={h} fill={background} style={style} />
			</svg>
		);
	}
};

DataBar.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	width: PropTypes.number,
	height: PropTypes.number,   // label for the chart,
	color: PropTypes.string,          // diameter of chart
	animate: PropTypes.bool,
	label: PropTypes.string,
	background: PropTypes.string,
	maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

DataBar.defaultProps = {
	value: 0.2,
	width: 100,         // the width of our meter
	height: 10,         // the height of our meter
	color: "#0078bc",
	background: "#ffffff",   // the fill color
	animate: true,     // if true, animate
	label: null,
	maxValue: 100
};