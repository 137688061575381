'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';
const __endpoints = require('endpoints.service');
const __enums = require('enums.service');

export default class MarketsTaxRatesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'type',
				type: 'select',
				canAdd: true,
				canEdit: true,
				required: true,
				options: {
					enums: __enums.taxRates,
				},
			},
			{
				name: 'rate',
				type: 'number',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'tax_purpose',
				type: 'select',
				canAdd: true,
				canEdit: true,
				required: true,
				options: {
					enums: __enums.taxPurpose,
				},
			},
			{
				name: 'external_export_id',
				type: 'string',
				canAdd: true,
				canEdit: true,
			},
			{
				name: 'dynamics_item_sales_tax_group',
				type: 'string',
				canAdd: true,
				canEdit: true,
			},
			{
				name: 'dynamics_sales_tax_group',
				type: 'string',
				canAdd: true,
				canEdit: true,
			},
			{
				name: 'start_date',
				type: 'date',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'end_date',
				type: 'date',
				canAdd: true,
				canEdit: true,
			},
		];
		this.settings = {
			name: 'MarketsTaxRatesList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			canCollapse: true,
			alwaysRefresh: true,
			canManipulate: () =>
				validAccess(undefined, [
					'Organisation/Markets::Edit',
					'Organization Admin',
				]),
			translationPath: 'ADMINISTRATION.MARKET_TAX_RATES',
			endpoint: __endpoints.collection.administration.market_tax_rates,
		};
		this.init();
		return this;
	}
}
