import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './flairs.phrases';

/**
 * @function fetchFlairs
 * @description Fetch all flairs
 */
export function fetchFlairs(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get('/pos/flairs', params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addFlairs
 * @description adds flair
 */
export function addFlairs(data) {
	return post('/pos/flairs', data)
		.then(res => {
			setFeedback(phrases.FLAIRS_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editFlair
 * @description edits flair
 */
export function editFlair(data) {
	return post(`/pos/flairs/${data.id}`, data)
		.then(res => {
			setFeedback(phrases.FLAIR_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editFlairs
 * @description edits multiple flairs
 */
export function editFlairs(data) {
	return patch('/pos/flairs', data)
		.then(res => {
			setFeedback(phrases.FLAIRS_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteFlairs
 * @description deletes flairs
 */
export function deleteFlairs(productId) {
	return remove(`/pos/flairs/${productId}`)
		.then(res => {
			setFeedback(phrases.FLAIRS_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
