export default {
	TABLE_TITLE: 'Global bundle rules',
	GLOBAL_BUNDLE_RULES_ADDED: 'Global bundle rules added',
	GLOBAL_BUNDLE_RULE_EDITED: 'Global bundle rule edited',
	GLOBAL_BUNDLE_RULES_EDITED: 'Global bundle rules edited',
	GLOBAL_BUNDLE_RULE_DELETED: 'Global bundle rules deleted',

	// batch
	MODAL_BATCH_STEP_FORM_TITLE: 'Select weekday and times',
	MODAL_BATCH_STEP_FORM_TITLE_EDIT: 'Duplicate pos configuration versions',

	MODAL_BATCH_STEP_FORM_TITLE_ADD: 'Add POS configuration(s)',
	MODAL_BATCH_STEP_MARKET: 'Market',
	MODAL_BATCH_STEP_MARKET_PLACEHOLDER: 'Select market...',

	MODAL_BATCH_STEP_WEEKDAY: 'Weekday',
	MODAL_BATCH_STEP_WEEKDAY_PLACEHOLDER: 'Select Weekday...',

	MODAL_BATCH_TABLE_TITLE: 'POS Configurations',
	MODAL_BATCH_ACTIVE_FROM_NEW: 'New Active from',
	MODAL_BATCH_ACTIVE_TO_NEW: 'New Active to',
	MODAL_BATCH_FROM: 'From',
	MODAL_BATCH_TO: 'To',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_STATE: 'State',
	MODAL_BATCH_STEP_STATE_PLACEHOLDER: 'Select state...',
	MODAL_BATCH_STEP_APP_TILE_LAYOUT: 'App tile layout',
	MODAL_BATCH_STEP_APP_TILE_LAYOUT_PLACEHOLDER: 'Select app tile layout...',
	MODAL_BATCH_STEP_POS_TILE_LAYOUT: 'Pos tile layout',
	MODAL_BATCH_STEP_POS_TILE_LAYOUT_PLACEHOLDER: 'Select pos tile layout',
	MODAL_BATCH_STEP_ACTIVE: 'Active',
	MODAL_BATCH_STEP_FORM_MARKET_TABLE_TITLE: 'Markets',
	MODAL_BATCH_STEP_FORM_POS_CONFIGURATION_VERSION_TABLE_TITLE:
		'Pos configuration versions',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'Next',
	MODAL_BATCH_STEP_LIST_TITLE: 'Confirm entries',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Submit',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'Back',
	MODAL_BATCH_STEP_FORM_TABLE_FILTER: 'Legacy',
};
