'use strict';

import PropTypes from 'prop-types';
import React from 'react';

import RowSubtable from './row.subtable.component';

const BodySubtable = props => {
	const { table } = props;

	/**
	 * Parses body from table content and layout object
	 */
	const parseBody = table.content.map((row, key) =>
		row && row.meta
			? [
				<RowSubtable
					data={row}
					key={`${row.meta.data.unique_identifier}_${key}_Table`}
					{...props}
				/>
			  ]
			: null
	);

	return <tbody>{parseBody}</tbody>;
};

BodySubtable.propTypes = {
	table: PropTypes.object.isRequired,
	tables: PropTypes.object.isRequired
};

export default BodySubtable;
