export const TOGGLE_MODAL = 'OPERATIONAL_RESPONSIBILITY_OVERVIEW/TOGGLE_MODAL';
export const SET_MODAL_DATA =
	'OPERATIONAL_RESPONSIBILITY_OVERVIEW/SET_MODAL_DATA';
export const UPDATE_MODAL_DATA =
	'OPERATIONAL_RESPONSIBILITY_OVERVIEW/UPDATE_MODAL_DATA';
export const SET_RESPONSIBILITY =
	'OPERATIONAL_RESPONSIBILITY_OVERVIEW/SET_RESPONSIBILITY';
export const UPDATE_RESPONSIBILITY =
	'OPERATIONAL_RESPONSIBILITY_OVERVIEW/UPDATE_RESPONSIBILITY';
export const RESET_RESPONSIBILITY =
	'OPERATIONAL_RESPONSIBILITY_OVERVIEW/RESET_RESPONSIBILITY';

export function toggleModal() {
	return {
		type: TOGGLE_MODAL,
	};
}

export function setModalData(payload) {
	return {
		type: SET_MODAL_DATA,
		payload,
	};
}

export function updateModalData(payload) {
	return {
		type: UPDATE_MODAL_DATA,
		payload,
	};
}

export function setResponsiblity(payload) {
	return {
		type: SET_RESPONSIBILITY,
		payload,
	};
}

export function updateResponsiblity(payload) {
	return {
		type: UPDATE_RESPONSIBILITY,
		payload,
	};
}

export function resetResponsiblity(payload) {
	return {
		type: RESET_RESPONSIBILITY,
	};
}
