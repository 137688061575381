import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import "./table-list.css";

export default class TableList extends PureComponent {
	render() {
		const { className, children } = this.props;

		const classN = classnames('j-table-list', {
			[`${className}`]: className,
			// [`j-table-list--no-space`]: noSpace
		});

		return (
			<table className={classN}>
				{children}

				{/* In case there are issues with rendering */}
				{/* {React.Children.map(children, (child, i) =>
					<div key={i} className="staff-details__section">
						{child}
					</div>
				)} */}
			</table>
		);
	}
}

TableList.propTypes = {
	// shared props
	className: PropTypes.string,
	// noSpace: PropTypes.bool,
	children: PropTypes.arrayOf(Object).isRequired

	// methods
};
