export default {
	TABLE_TITLE: 'Product variant pricings',
	PRODUCT_VARIANT_PRICING_EDITED: 'Product variant pricing edited!',
	PRODUCT_VARIANT_PRICING_DELETED: 'Product variant pricing deleted!',
	PRODUCT_VARIANT_PRICING_ADDED: 'Product variant pricings added!',
	MODAL_BATCH_STEP_FORM_TITLE: 'Batch add',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'next',
	MODAL_BATCH_STEP_TO_STAY_TAX: 'To stay tax',
	MODAL_BATCH_STEP_TO_GO_TAX: 'To go tax',
	MODAL_BATCH_STEP_LIST_TITLE: 'Confirm entries',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'submit',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'back',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_POS_CONFIGURATION_VERSION: 'Pos configuration version',
	MODAL_BATCH_STEP_PRODUCT_VARIANT: 'Product variant',
	MODAL_BATCH_STEP_ALTERNATIVE_ID: 'Alternative id',
	MODAL_BATCH_STEP_TO_STAY_PRICE: 'To stay price',
	MODAL_BATCH_STEP_TO_GO_PRICE: 'To go price',
	MODAL_BATCH_STEP_AVAILABLE_IN_APP: 'Available in app',
	MODAL_BATCH_STEP_FORM_POS_CONFIGURATION_VERSIONS_TABLE_TITLE:
		'Pos configuration versions',
	MODAL_BATCH_STEP_FORM_POS_CONFIGURATION_VERSIONS_TABLE_ACTIVE_FILTER:
		'Active',
	MODAL_BATCH_STEP_FORM_PRODUCT_VARIANTS_TABLE_TITLE: 'Product',
	LEGACY_FILTER_TOOLTIP_ACTIVE: 'Showing non legacy POS Configurations',
	LEGACY_FILTER_TOOLTIP_UNACTIVE: 'Showing all POS Configurations',
	MODAL_BATCH_STEP_LIST_LEGEND_CANT_ADD:
		'Pos configuration product variant pricing already exists',

	// edit nominal/percentage
	NOMINAL_PERCENTAGE_EDIT: 'Edit percentage/nominal',
	MODAL_BATCH_STEP_FORM_ADJUST_PRICES: 'Adjust Prices',
	MODAL_BATCH_STEP_FORM_NOMINAL_PERCENTAGE_CONFIRM: 'Next',
	MODAL_BATCH_STEP_FORM_ADJUSTMENT_TYPE: 'Adjustment Type',
	MODAL_BATCH_STEP_DISCOUNT_TYPE_PLACEHOLDER: 'Nominal/ percentage...',
	MODAL_BATCH_STEP_ROUND_TO_NEAREST: 'Round to nearest',
	MODAL_BATCH_STEP_ROUND_TO_NEAREST_PLACEHOLDER:
		'1, 0.5, 0.25, 0.1, 0.05, 0.01',
	MODAL_BATCH_STEP_FORM_ADJUST_TO_STAY_PRICE: 'Adjust To Stay Price by',
	MODAL_BATCH_STEP_ADJUSTMENT_AMOUNTS_TO_STAY_PERCENTAGE:
		'To stay percentage adjustment',
	MODAL_BATCH_STEP_FORM_ADJUST_TO_GO_PRICE: 'Adjust To Go Price by',
	MODAL_BATCH_STEP_ADJUSTMENT_AMOUNTS_TO_GO_PERCENTAGE:
		'To go percentage adjustment',
	MODAL_BATCH_STEP_DISCOUNT_AMOUNTS_PLACEHOLDER: 'Enter value...',
	MODAL_BATCH_TAG_TEXT: 'Selected product variant pricings',
	MODAL_BATCH_STEP_LIST_POS_CONFIGURATION_NAME: 'Pos configuration name',
	MODAL_BATCH_STEP_LIST_POS_CONFIGURATION_VERSION_NAME:
		'Pos configuration version name',
	MODAL_BATCH_STEP_LIST_PRODUCT_VARIANT: 'Product variant',
	MODAL_BATCH_STEP_LIST_TO_STAY_PRICE: 'To stay price',
	MODAL_BATCH_STEP_LIST_TO_STAY_PRICE_NEW: 'To stay price new',
	MODAL_BATCH_STEP_LIST_TO_GO_PRICE: 'To go price',
	MODAL_BATCH_STEP_LIST_TO_GO_PRICE_NEW: 'To go price new',
	MODAL_BATCH_STEP_NOMINAL_TIP:
		'Current prices will be adjusted by the entered amount',
	MODAL_BATCH_STEP_PERCENTAGE_TIP:
		'Current prices will be adjusted by the entered percentage',
	MODAL_BATCH_STEP_LIST_PERCENTAGE_TIP:
		'Fine adjust the new prices in this step',
	MODAL_BATCH_STEP_DELIVERY_PRICE: 'Delivery price',
	MODAL_BATCH_STEP_DELIVERY_TAX: 'Delivery tax',
};
