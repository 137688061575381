import React, { useState } from 'react';
import PropTypes from 'prop-types';

// redux
import { useDispatch, useSelector } from 'react-redux';
import {
	setNutritionCategoryTranslation,
	updateNutritionCategoryTranslation,
	resetState,
} from './store/nutritionCategoryTranslations.actions';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import NutritionCategoryTranslationsModalBatch from './components/batch/nutritionCategoryTranslationsModalBatch';
import { Tooltip, Icon, Button, Input } from 'dumb';

// services
import {
	fetchAllNutritionCategoryTranslations,
	deleteNutritionCategoryTranslation,
	editNutritionCategoryTranslation,
} from './nutritionCategoryTranslations.service';

// utils
import { getEditedValues } from 'services/utils';

// phrases
import phrases from './nutritionCategoryTranslations.phrases';

const columns = [
	{
		Header: 'Name',
		id: 'name',
		accessor: 'name',
		filterPath: ':name',
	},
	{
		Header: 'Language',
		id: 'language',
		accessor: 'language.name',
		filterPath: ':language.name',
	},
];

const NutritionCategoryTranslations = ({ id }) => {
	const dispatch = useDispatch();
	const { tableKey, nutritionCategoryTranslation, originalNutritionCategoryTranslation } = useSelector(
		(store) => store.nutritionCategoryTranslations
	);

	const [showBatchModal, setShowBatchModal] = useState(false);

	// crud
	const fetchData = (state) => fetchAllNutritionCategoryTranslations(id, state);
	const editEntry = () => {
		const valuesToEdit = getEditedValues({
			newData: nutritionCategoryTranslation,
			oldData: originalNutritionCategoryTranslation,
		});

		const payload = {
			id: nutritionCategoryTranslation.id,
			name: valuesToEdit.name,
		};

		return editNutritionCategoryTranslation(payload);
	};
	const deleteEntry = (id) => deleteNutritionCategoryTranslation(id);

	// crud prep
	const setInitialEditValues = (data) => {
		const body = {
			id: data.id,
			name: data.name,
			language: data.language.name,
		};

		dispatch(setNutritionCategoryTranslation(body));
	};
	const getEditableCells = () => [
		{
			header: 'Name',
			value: (
				<Input
					id="name"
					placeholder={phrases.NAME}
					value={nutritionCategoryTranslation.name}
					onChange={(event) => dispatch(updateNutritionCategoryTranslation({ name: event.target.value }))}
				/>
			),
		},
		{
			header: 'Language',
			value: <span>{nutritionCategoryTranslation.language}</span>,
		},
	];

	const toggleBatchModal = () => setShowBatchModal(!showBatchModal);
	const onRdwModalClose = () => dispatch(resetState());

	return (
		<>
			<ReactDataWrapper
				key={tableKey}
				accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.TITLE}
				columns={columns}
				fetchData={fetchData}
				filterable
				defaultPageSize={10}
				reduxKey={`${id}-product/nutrition_category_translations`}
				manual
				editableCells={getEditableCells()}
				setInitialEditValues={setInitialEditValues}
				onModalClose={onRdwModalClose}
				deleteEntry={deleteEntry}
				editEntry={editEntry}
				actionRender={
					<Tooltip
						text={phrases.BATCH_ADD}
						renderData={(ref, onMouseEnter, onMouseLeave) => (
							<Button
								label="Add"
								shadow
								type="inverted"
								onClick={toggleBatchModal}
								refProp={ref}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
							>
								<Icon name="add" />
							</Button>
						)}
					/>
				}
			/>

			{showBatchModal && <NutritionCategoryTranslationsModalBatch onClose={toggleBatchModal} allergenId={id} />}
		</>
	);
};

NutritionCategoryTranslations.propTypes = {
	id: PropTypes.number,
};

export default NutritionCategoryTranslations;
