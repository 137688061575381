export const RESET_ADDON_RECOMMENDATIONS =
	'SALES_CONFIGURATION_PRODUCT_ADDONS_ADDON_RECOMMENDATIONS/RESET_ADDON_RECOMMENDATIONS';
export const SET_ADDON_RECOMMENDATIONS =
	'SALES_CONFIGURATION_PRODUCT_ADDONS_ADDON_RECOMMENDATIONS/SET_ADDON_RECOMMENDATIONS';
export const UPDATE_ADDON_RECOMMENDATIONS =
	'SALES_CONFIGURATION_PRODUCT_ADDONS_ADDON_RECOMMENDATIONS/UPDATE_ADDON_RECOMMENDATIONS';

/**
 * @param {object} payload
 */
export function setAddonRecommendations(payload) {
	return {
		type: SET_ADDON_RECOMMENDATIONS,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateAddonRecommendations(payload) {
	return {
		type: UPDATE_ADDON_RECOMMENDATIONS,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetAddonRecommendations() {
	return {
		type: RESET_ADDON_RECOMMENDATIONS
	};
}
