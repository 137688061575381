export default {
	VACATION_TAB: 'Vacation',
	ILLNESS_TAB: 'Illness',

	VACATIONS_TABLE: 'Vacations',
	VACATION_ADDED: 'Vacation added!',
	VACATION_EDITED: 'Vacation edited!',
	VACATION_DELETED: 'Vacation deleted!',

	ILLNESS_TABLE: 'Illnesses',
	ILLNESS_ADDED: 'Illness added!',
	ILLNESS_EDITED: 'Illness edited!',
	ILLNESS_DELETED: 'Illness deleted!'
};
