export default {
	TITLE: 'Languages',
	LANGUAGES_ADDED: 'Languages added!',
	LANGUAGE_EDITED: 'Language edited!',
	LANGUAGES_EDITED: 'Languages edited!',
	LANGUAGES_DELETED: 'Languages deleted!',

	// batch form
	MODAL_BATCH_STEP_LIST_TITLE: 'Add languages',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_CODE: 'Code',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add entries',
};
