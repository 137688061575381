'use strict';
import _has from 'lodash/has';
import { get } from 'api.vanilla.service';

/**
 * @function fetchCompleteCollection
 * @memberOf SERVICES.__models
 * @description
 * Fetch collection from API endpoint. Formats collection before resolving. Returns as array where [collection, collectionLength]
 * This was used for lists data and is not the preferred way moving forward
 * @param  {string} endpoint  endpoint to get
 * @param  {object} params  params to get endpoint with
 * @param  {object} headers  Custom headers to be ser
 * @deprecated
 */

function queryStringToObject(str) {
	return str
		.replace(/(^\?)/, '')
		.split('&')
		.map(
			function(n) {
				return (n = n.split('=')), (this[n[0]] = n[1]), this;
			}.bind({})
		)[0];
}

export default function fetchCompleteCollection(
	endpoint,
	params,
	headers = undefined
) {
	return new Promise((resolve, reject) => {
		params = { limit: 200, ...params };

		let collection = [];
		const fetchCompleteCollection = function(params) {
			get(endpoint, params, undefined, headers)
				.then(response => {
					collection = _has(response, 'data')
						? [...collection, ...response.data]
						: collection;

					// We asked for a csv file. We dont need to format
					if (headers && headers.Accept && headers.Accept.indexOf('csv'))
						resolve(response);
					if (_has(response, 'paging') && _has(response.paging, 'next')) {
						let newParams = Object.assign(
							{},
							params,
							queryStringToObject(response.paging.next.split('?')[1])
						);
						fetchCompleteCollection(newParams);
					} else {
						resolve(collection);
					}
				})
				.catch(err => {
					reject(err);
				});
		};
		fetchCompleteCollection(params);
	});
}
