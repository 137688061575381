'use strict';

import {
	SET_COLLECTION,
	SET_DATERANGE,
	SET_HOTBAR_FILTER,
	SET_MATRICE,
	UPDATE_WORKPLACE,
	SET_SHOW_MATRICE,
	SET_PERIOD_TYPE,
} from '../actions/goldModel.actions';

const defaultState = {
	collection: [],
	dateRange: {},
	hotbarFilter: [],
	matrice: {},
	params: {
		offset: 0,
		limit: 300,
	},
	showMatrice: false,
	periodType: 'monthly',
};

/**
 * @function reducer
 * @description Reduces state to new state
 * @param  {object} state  current state
 * @param  {object} action action to reduce from
 * @return {object}        reduced state
 */
function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
		case SET_COLLECTION: {
			return Object.assign({}, state, { collection: payload });
		}
		case SET_DATERANGE: {
			return Object.assign({}, state, { dateRange: payload });
		}
		case SET_MATRICE: {
			return Object.assign({}, state, { matrice: payload });
		}
		case UPDATE_WORKPLACE: {
			const tags = payload.tags.map((tag) => ({
				id: tag.id,
				period: tag.period,
				tag: tag.tag,
			}));

			// dates[date.format('MM-YYYY')]

			return {
				...state,
				collection: state.collection.map((row) => {
					if (row.id === payload.workplaceId) {
						return {
							...row,
							workplace: {
								...row.workplace,
								active_store_tags: [...tags],
							},
						};
					}
					return row;
				}),
			};
		}

		case SET_SHOW_MATRICE: {
			return Object.assign({}, state, { showMatrice: payload });
		}
		case SET_HOTBAR_FILTER: {
			return Object.assign({}, state, { hotbarFilter: payload });
		}
		case SET_PERIOD_TYPE: {
			return Object.assign({}, state, { periodType: payload });
		}
		default: {
			return state;
		}
	}
}

export default reducer;
