import React from 'react';
import PropTypes from 'prop-types';

// components
import { Button, Text, Tag, ButtonLoader } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// phrases
import phrases from './../../../../../shiftTransferApproval.phrases';
import { ShiftTransfer as enums } from 'services/enums';

// utils
import { getCanDeclineTransferOwn } from './../utils';
import { getOwnPendingTransferState } from './../../../../../utils';

// lodash
import _isEmpty from 'lodash/isEmpty';

const ShiftTransferPendingTransfersOwn = ({
	ownShifts,
	getShiftDate,
	formatShiftTime,
	getShiftDay,
	updateShiftTransferWrapper,
	loading,
	defaultColor,
	positiveColor,
	negativeColor,
	getApplicantList,
}) => {
	const getShiftCell = (entry) => {
		const shiftDate = getShiftDate(entry.shift?.planned_period?.from);
		const timeFrom = formatShiftTime(entry.shift?.planned_period?.from);
		const timeTo = formatShiftTime(entry.shift?.planned_period?.to);
		const shiftDay = getShiftDay(entry.shift?.planned_period?.from);

		const concatenatedText = `${shiftDate} ${timeFrom} - ${timeTo} (${shiftDay})`;

		return (
			<div className="shift-transfer-approval-modal__table-wrapper__row-cell">
				<Text bold size="small" noWrap>
					{concatenatedText}
				</Text>
			</div>
		);
	};

	return (
		<div>
			<Text
				bold
				className="shift-transfer-approval-modal__table-wrapper__title">
				{phrases.SHIFTS_YOU_ARE_TRANSFERING}
			</Text>
			<div className="shift-transfer-approval-modal__table-wrapper">
				<TableList>
					<thead>
						<tr>
							<th width={180}>{phrases.TABLE_LIST_BAR}</th>
							<th width={200}>{phrases.TABLE_LIST_SHIFT}</th>
							<th>{phrases.TABLE_LIST_APPLICANTS}</th>
							<th>{phrases.TABLE_LIST_STATE}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{ownShifts.map((entry) => {
							const canDeclineTransfer = getCanDeclineTransferOwn({ entry });

							// get proper state to show
							const stateToShow = getOwnPendingTransferState(entry);

							return (
								<React.Fragment key={entry.id}>
									<TableListRow>
										<TableListCell>
											<Text size="small" bold noWrap>
												{entry.shift?.workplace?.name}
											</Text>
										</TableListCell>

										<TableListCell>{getShiftCell(entry)}</TableListCell>

										<TableListCell>
											{!_isEmpty(entry.applications) && getApplicantList(entry)}
										</TableListCell>

										<TableListCell>
											{stateToShow && (
												<Tag color={defaultColor} noMargin>
													<Text size="tiny" bold noWrap>
														{stateToShow}
													</Text>
												</Tag>
											)}
										</TableListCell>

										<TableListCell>
											<div className="shift-transfer-approval-modal__table-wrapper__row-cell shift-transfer-approval-modal__table-wrapper__row-cell--align-end">
												{canDeclineTransfer ? (
													<Button
														shadow
														type="negative"
														size="small"
														onClick={() =>
															updateShiftTransferWrapper({
																shiftTransfer: entry,
																operation: enums.OPERATIONS.RETRACT,
															})
														}
														disabled={loading.id === entry.id}>
														{loading.id === entry.id ? (
															<ButtonLoader loading />
														) : (
															phrases.CANCEL_TRANSFER
														)}
													</Button>
												) : null}
											</div>
										</TableListCell>
									</TableListRow>
								</React.Fragment>
							);
						})}
					</tbody>
				</TableList>
			</div>
		</div>
	);
};

ShiftTransferPendingTransfersOwn.propTypes = {
	ownShifts: PropTypes.array,
	getShiftDate: PropTypes.func,
	formatShiftTime: PropTypes.func,
	getShiftDay: PropTypes.func,
	updateShiftTransferWrapper: PropTypes.func,
	loading: PropTypes.bool,
	defaultColor: PropTypes.string,
	positiveColor: PropTypes.string,
	negativeColor: PropTypes.string,
	getApplicantList: PropTypes.func,
};

export default ShiftTransferPendingTransfersOwn;
