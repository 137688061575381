'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
const __endpoints = require('endpoints.service');

export default class ShiftPlanningMarketTagsList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'market',
				type: 'select',
				canAdd: true,
				canEdit: true,
				required: true,
				reference: {
					key: 'name',
					list: '/administration/markets',
				},
			},
		];
		this.settings = {
			name: 'ShiftPlanningMarketTagsList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			alwaysRefresh: true,
			translationPath: 'SHIFTPLANNING.MARKET_TAGS',
			endpoint: __endpoints.collection.shiftPlanning.market_tags,
		};
		this.init();
		return this;
	}
}
