export default {
	RECEIVED: 'Received',
	SETTLED: 'Settled',
	OUTSTANDING: 'Outstanding',
	ADJUST_MODAL: 'adjust modal',
	SIGN_MODAL: 'sign modal',
	LESS_THEN: 'Less then',
	GREATER_THEN_OR_EQUAL_TO: 'Greater then or equal to',
	CONFIRM_BEFORE_SIGN: 'Confirm before sign',
};
