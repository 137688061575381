'use strict';
export const LOADING_REPORT = 'DAILY_REPORT/LOADING_REPORT';
export const SET_HOTBAR_DATA = 'DAILY_REPORT/SET_HOTBAR_DATA';
export const CLEAN_DATA = 'DAILY_REPORT/CLEAN_DATA';
export const SET_DAILY_REPORT = 'DAILY_REPORT/SET_DAILY_REPORT';
export const TOGGLE_EXPAND_ALL_MARKETS =
	'DAILY_REPORT/TOGGLE_EXPAND_ALL_MARKETS';
export const TOGGLE_EXPAND_ALL_GROUPS = 'DAILY_REPORT/TOGGLE_EXPAND_ALL_GROUPS';
export const TOGGLE_EXPAND_MARKET = 'DAILY_REPORT/TOGGLE_EXPAND_MARKET';
export const TOGGLE_EXPAND_GROUP_WITHIN_MARKET =
	'DAILY_REPORT/TOGGLE_EXPAND_GROUP_WITHIN_MARKET';
export const DISABLE_ROW_INTERACTION = 'DAILY_REPORT/DISABLE_ROW_INTERACTION';
export const SET_DAILY_REPORT_EMAIL_RECIPIENTS =
	'DAILY_REPORT/SET_DAILY_REPORT_EMAIL_RECIPIENTS';

/**
 * @param {bool} report
 */
export function setLoadingReport(loadingReport) {
	return {
		type: LOADING_REPORT,
		payload: {
			loadingReport
		}
	};
}

/**
 * @param {bool} report
 */
export function toggleExpandAllMarkets(expand) {
	return {
		type: TOGGLE_EXPAND_ALL_MARKETS,
		payload: {
			expand
		}
	};
}

/**
 * @function toggleExpandMarket
 * @param {number} marketId
 */
export function toggleExpandMarket(marketId) {
	return {
		type: TOGGLE_EXPAND_MARKET,
		payload: {
			marketId
		}
	};
}

/**
 * @function toggleExpandGroupWithinMarket
 * @description Open up / expand a group selection within a market
 *
 * @export
 * @param {*} groupId
 * @param {*} marketId
 * @returns
 */
export function toggleExpandGroupWithinMarket(groupId, marketId) {
	return {
		type: TOGGLE_EXPAND_GROUP_WITHIN_MARKET,
		payload: {
			groupId,
			marketId
		}
	};
}

/**
 * @function toggleExpandGroupWithinMarket
 * @description Open up / expand a group selection within a market
 *
 * @export
 * @param {*} groupId
 * @param {*} marketId
 * @returns
 */
export function toggleExpandAllGroups(expand) {
	return {
		type: TOGGLE_EXPAND_ALL_GROUPS,
		payload: {
			expand
		}
	};
}

/**
 * @param {bool} report
 */
export function setDailyReport(dailyReport, franchiseReport, dailyReportRaw) {
	return {
		type: SET_DAILY_REPORT,
		payload: {
			dailyReport,
			franchiseReport,
			dailyReportRaw
		}
	};
}

/**
 * @param {bool} report
 */
export function cleanData() {
	return {
		type: CLEAN_DATA
	};
}

/**
 * @param {bool} report
 */
export function setHotbarData(hotbarData) {
	return {
		type: SET_HOTBAR_DATA,
		payload: {
			hotbarData
		}
	};
}

/**
 * @param {bool} report
 */
export function setDailyReportEmailRecipients(emailRecipients) {
	return {
		type: SET_DAILY_REPORT_EMAIL_RECIPIENTS,
		payload: {
			emailRecipients
		}
	};
}
