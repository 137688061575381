'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonGroup, InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';

import phrases from './../../../productBundleSetProductVariants.phrases';

import _isEmpty from 'lodash/isEmpty';

class ProductBundleSetProductVariantsBatchStepForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false
		};
	}

	editStoreEntry(e, type) {
		const { updateBatchForm, resetSelection, productVariantUrl } = this.props;

		const value = e.target ? e.target.value || e.target.checked : e;

		if (_isEmpty(value)) {
			resetSelection(productVariantUrl);
		}

		updateBatchForm({
			[type]: value
		});
	}

	render() {
		const { batchFormData, toggleOverlay, productVariantUrl } = this.props;

		return (
			<ModalRow>
				<ButtonGroup type="full-width">
					<InputCollectionSelect
						className="modal-batch-select"
						id="product_variant"
						label={phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT}
						placeholder={phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT_PLACEHOLDER}
						value={batchFormData.productVariant}
						handleChange={(key, value) =>
							this.editStoreEntry(value, 'productVariant')
						}
						multi
						cache
						apiPath={productVariantUrl}
						params={{
							limit: 50
						}}
						optionFormat={entry => ({
							value: entry,
							label: entry.name
						})}
						inputFilterFormat={input => `:name=like='%${input}%'`}
					/>

					<Button
						type="inverted"
						shadow
						size="large"
						onClick={() => toggleOverlay(productVariantUrl)}>
						<span className="icon icon--more" />
					</Button>
				</ButtonGroup>
			</ModalRow>
		);
	}
}

ProductBundleSetProductVariantsBatchStepForm.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
	resetSelection: PropTypes.func,
	productVariantUrl: PropTypes.string
};

export default ProductBundleSetProductVariantsBatchStepForm;
