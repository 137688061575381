/* BATCH STUFF  */
export const UPDATE_BATCH_FORM =
	'SALES_CONFIGURATION_PRODUCT_VARIANTS_DISCOUNT_SET/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'SALES_CONFIGURATION_PRODUCT_VARIANTS_DISCOUNT_SET/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_PRODUCT_VARIANTS_DISCOUNT_SET/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_PRODUCT_VARIANTS_DISCOUNT_SET/REMOVE_BATCH_LIST_ITEM';
export const RESET_STATE =
	'SALES_CONFIGURATION_PRODUCT_VARIANTS_DISCOUNT_SET/RESET_STATE';

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}
