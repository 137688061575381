import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isMobile } from 'detectMobile.vanilla';

// Componnets
import { Button, ButtonFeedback as Feedback } from 'dumb';
import CollectionSelect from 'collectionSelect';

// Other
import _isEqual from 'lodash/isEqual';
import collectionSelectEnums from 'services/enums/collectionSelect';

import './input-smart.css';

class InputSmartSelect extends Component {
	constructor(props) {
		super(props);

		this.date = new Date();

		this.state = {
			value: this.props.value === null ? undefined : this.props.value,
			key: this.date.getTime(),
			editable: false,
			loading: false,
		};

		this.onChange = this.onChange.bind(this);
	}

	selectInput() {
		if (!isMobile()) {
			this.setState(() => ({ editable: true }));
			this.props.toggleInputs({ disabled: false });
		}
	}

	handleClickOutside() {
		if (!isMobile() && this.state.editable) {
			this.onSubmit();
			this.setState(() => ({ editable: false }));
			this.props.toggleInputs({ disabled: true });
		}
	}

	onToggle() {
		this.setState((prevState) => ({ editable: !prevState.editable }));
	}

	onChange(target, e) {
		const event = e === null ? { value: null, label: null } : e;

		this.setState(() => ({
			loading: true,
			value: event,
		}));

		// If suplied onChange
		this.props.onChange(target, event).then(() => {
			this.setState(() => ({
				loading: false,
				responseStatus: 'success',
			}));
			this._resetFeedbackStatus();
			this.props.toggleInputs({ disabled: true });
		});
	}

	_resetFeedbackStatus() {
		setTimeout(() => {
			this.setState(() => ({
				responseStatus: '',
			}));
		}, 1000);
	}

	onSubmit() {
		if (isMobile()) this.onToggle();

		if (this.props.onSubmit)
			if (_isEqual(this.props.value, this.state.value)) {
				this.setState(() => ({ loading: true }));
				this.props
					.onSubmit(this.props.customParams(this.state.value))
					.then(() => {
						// Success. Order a pizza
						this.setState(() => ({
							loading: false,
							responseStatus: 'success',
						}));
						this._resetFeedbackStatus();
						// this.onToggle();
					})
					.catch(() => {
						this.setState(() => ({
							value: this.props.value,
							key: this.date.getTime(),
							loading: false,
							responseStatus: 'error',
						}));
						// this.onToggle();
					});
			}
	}

	_renderFeedback() {
		const { loading, responseStatus } = this.state;

		if (loading && !responseStatus) return null;

		return (
			<span
				style={{
					position: 'absolute',
					right: '0',
					margin: '5px 15px',
				}}
			>
				<Feedback showFeedback={this.state.responseStatus} />
			</span>
		);
	}

	render() {
		const {
			label,
			name,
			className,
			action,
			apiPath,
			placeholder,
			params,
			disabled,
			options,
			tabIndex,
			readOnly,
		} = this.props;
		const { editable, loading, value, key } = this.state;

		const cx = classnames('j-input-smart', {
			className: className,
		});

		const showDisabled = () => {
			if (readOnly !== undefined) return readOnly;
			if (disabled !== undefined) return disabled;
		};

		const inputClass = classnames({
			'j-input-smart__input--enabled': !showDisabled() && !isMobile(),
		});

		return (
			<div className={cx}>
				<div
					className="j-input-smart__element"
					onClick={(event) => this.selectInput(event)}
				>
					<div
						className={inputClass}
						onClick={(event) => this.selectInput(event)}
					>
						<CollectionSelect
							key={key}
							value={value}
							name={name}
							handleChange={this.onChange}
							placeholder={placeholder}
							apiPath={apiPath}
							params={params}
							options={options}
							loading={loading}
							tabIndex={tabIndex}
							styleType={collectionSelectEnums.TYPE_JUICER_DETAILS}
						/>
					</div>
					<label>{label}</label>
				</div>

				{this._renderFeedback()}

				{action && isMobile() && (
					<div className="j-input-smart__action">
						<Button
							disabled={loading || disabled}
							type="inverted"
							size="tiny"
							onClick={editable ? () => this.onSubmit() : () => this.onToggle()}
							state={editable ? 'success' : 'secondary'}
						>
							{editable ? (
								<span className="icon icon--done" />
							) : (
								<span className="icon icon--edit" />
							)}
						</Button>
					</div>
				)}
			</div>
		);
	}
}

InputSmartSelect.defaultProps = {
	action: true,
	type: 'text',
	placeholder: '',
	onChange: () => {},
	disabled: false,
};

InputSmartSelect.propTypes = {
	// shared props
	className: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.any,
	placeholder: PropTypes.string,
	action: PropTypes.bool,
	apiPath: PropTypes.string,
	params: PropTypes.object,
	disabled: PropTypes.bool,
	options: PropTypes.array,
	tabIndex: PropTypes.number,
	readOnly: PropTypes.bool,

	// Methods
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
	customParams: PropTypes.func,
	toggleInputs: PropTypes.func,
};

export default InputSmartSelect;
