import { useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

// redux actions
import { setShowModal as showClockinDeviationApprovalsModal } from './../clockinDeviationApproval/store/clockinDeviationApproval.actions';
import { setShowModal as showShiftTransfersModal } from './../shiftTransferApproval/store/shiftTransferApproval.actions';

// services
import { fetchShiftClockinsDeviationApprovals } from 'smart/clockinDeviationApproval/clockinDeviationApproval.service';
import { fetchShiftTransfers } from 'smart/shiftTransferApproval/shiftTransferApproval.service';

// utils
import { validAccess } from 'accessControl';
import { useAuth0 } from '@auth0/auth0-react';

// lodash
import _isEmpty from 'lodash/isEmpty';
import _debounce from 'lodash/debounce';

const getInitialShiftTransferAndApprovalsInfo = _debounce(
	async (userId, userJuicer, userManager, dispatch) => {
		let arrayOfFunctions = [
			fetchShiftTransfers({
				userJuicer,
				userManager,
				userId,
			}),
		];

		if (userId && userManager) {
			arrayOfFunctions = [
				...arrayOfFunctions,
				fetchShiftClockinsDeviationApprovals({
					userId: userId,
				}),
			];
		} else {
			arrayOfFunctions = [...arrayOfFunctions, false];
		}

		const [shiftTransfers, clockinDeviations] = await Promise.all(arrayOfFunctions);

		if (!_isEmpty(clockinDeviations?.data)) {
			dispatch(showClockinDeviationApprovalsModal(true));
		}
		if (!_isEmpty(shiftTransfers?.data?.[0]?.shift_transfers) || !_isEmpty(shiftTransfers?.data?.[0]?.swap_transfers)) {
			dispatch(showShiftTransfersModal(true));
		}
	},
	50,
	{ trailing: true }
);

const AppInit = () => {
	const dispatch = useDispatch();
	const { user, accessToken } = useSelector((state) => ({
		user: state.userData?.user,
		accessToken: state?.userData?.accessToken,
	}));

	const shiftplanManagement = validAccess(undefined, ['Shiftplan Manager']);
	const shiftplanAccess = validAccess(undefined, ['Shiftplan View']);
	const userJuicer = shiftplanAccess && !shiftplanManagement;
	const userManager = shiftplanManagement;
	const userId = user?.user?.person?.id;
	const userAreasNotEmpty = !_isEmpty(user?.areas);

	const { isAuthenticated } = useAuth0();

	useEffect(() => {
		const showNews = isAuthenticated && accessToken && userAreasNotEmpty;

		if (!showNews) return;

		getInitialShiftTransferAndApprovalsInfo(userId, userJuicer, userManager, dispatch);
	}, [dispatch, isAuthenticated, userAreasNotEmpty, userId, userJuicer, userManager, accessToken]);

	return null;
};

export default AppInit;
