export default {
	TITLE: 'Workplace grids',
	WORKPLACE_WAITING_TIME_PERFORMANCE_GRID_ADDED:
		'Workplace waiting time performance grids added!',
	WORKPLACE_WAITING_TIME_PERFORMANCE_GRID_EDITED:
		'Workplce waiting time performance grid edited!',
	WORKPLACE_WAITING_TIME_PERFORMANCE_GRIDS_EDITED:
		'Workplce waiting time performance grids edited!',
	WORKPLACE_WAITING_TIME_PERFORMANCE_GRID_DELETED:
		'Workplce waiting time performance grid deleted!',

	// batch form
	MODAL_BATCH_STEP_FORM_TITLE: 'Select entries',
	MODAL_BATCH_STEP_LIST_TITLE: 'Add workplace grid entries',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_WORKPLACE: 'Workplace',
	MODAL_BATCH_STEP_WORKPLACES: 'Workplaces',
	MODAL_BATCH_STEP_WORKPLACE_PLACEHOLDER: 'Select workplace...',
	MODAL_BATCH_STEP_PERIOD_FROM: 'From',
	MODAL_BATCH_STEP_PERIOD_TO: 'To',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'Next',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'Back',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add entries',
};
