export const RESET_STATE =
	'GLOBAL_ADMINISTRATION_SYSTEM_SOFTWARE_VERSIONS/RESET_STATE';
export const SET_SYSTEM_SOFTWARE_VERSIONS =
	'GLOBAL_ADMINISTRATION_SYSTEM_SOFTWARE_VERSIONS/SET_SYSTEM_SOFTWARE_VERSIONS';
export const UPDATE_SYSTEM_SOFTWARE_VERSIONS =
	'GLOBAL_ADMINISTRATION_SYSTEM_SOFTWARE_VERSIONS/UPDATE_SYSTEM_SOFTWARE_VERSIONS';

export const EDIT_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_SYSTEM_SOFTWARE_VERSIONS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_SYSTEM_SOFTWARE_VERSIONS/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'GLOBAL_ADMINISTRATION_SYSTEM_SOFTWARE_VERSIONS/ADD_BATCH_LIST_ITEM_ROW';

export function setSystemSoftwares(payload) {
	return {
		type: SET_SYSTEM_SOFTWARE_VERSIONS,
		payload,
	};
}

export function updateSystemSoftwares(payload) {
	return {
		type: UPDATE_SYSTEM_SOFTWARE_VERSIONS,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}
