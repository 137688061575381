'use strict';

import { FileUploadFactory as f } from 'fileUpload/factory';

const EmploymentDocumentsFileUpload = f.fileUpload(
	'employmentDocumentsFileUpload'
);

EmploymentDocumentsFileUpload.setFileTypeTemplates([
	f.fileType(
		'Contract',
		f.extensions(['PDF', 'JPG', 'PNG']),
		(d) => `Employment-agreement-${d.person}-${d.position}`,
		true
	),

	f.fileType(
		'Code of Conduct',
		f.extensions(['PDF', 'JPG', 'PNG']),
		(d) => `COC-${d.person}-${d.position}`,
		true
	),

	// f.fileType('Termination', f.extensions(['PDF', 'JPG']), d => `Termination-${d.person}-${d.position}`, true),
	f.fileType(
		'Warning',
		f.extensions(['PDF', 'JPG', 'PNG']),
		(d) => `Warning-${d.person}-${d.position}`,
		true
	),

	f.fileType(
		'Verbal Warning',
		f.extensions(['PDF', 'JPG', 'PNG']),
		(d) => `Verbal-Warning-${d.person}-${d.position}`,
		true
	),

	f.fileType(
		'Resignation',
		f.extensions(['PDF', 'JPG', 'PNG']),
		(d) => `Resignation-${d.person}-${d.position}`,
		true
	),

	f.fileType(
		'Documentation of COVID-19 Vaccination',
		f.extensions(['PDF', 'JPG', 'PNG']),
		(d) => `Document-of-covid_19-vaccination-${d.person}-${d.position}`,
		true
	),

	f.fileType(
		'I-9 Form',
		f.extensions(['PDF', 'JPG', 'PNG']),
		(d) => `I-9-Form-${d.person}-${d.position}`,
		true
	),

	// f.fileType('Hygiene Certificate', f.extensions(['PDF', 'JPG']), d => `Hygiene-certificate-${d.person}-${d.position}`, true),
	f.fileType('Other', null, null, true),
]);

export default EmploymentDocumentsFileUpload;
