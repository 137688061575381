export default {
	TITLE: 'Home Screen recommendation translations',
	BATCH_ADD: 'Click to add',

	MODAL_TITLE: 'Add home screen recommendation translations',
	DESCRIPTION: 'Description',
	DESCRIPTION_PLACEHOLDER: 'Enter description...',
	LANGUAGE: 'Language',
	LANGUAGE_PLACEHOLDER: 'Select language...',
	SUBMIT: 'Submit',
	ADD_ROW_TOOLTIP: 'Add row',
};
