'use strict';

import { get } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchProductVariantsWorkplaceRelations
 * @description Fetch all product variants workplace relations
 * @param {object} object - contains state from table, product variant id and date object
 */
export function fetchProductVariantsWorkplaceRelations({
	state,
	productVariantId,
	date,
}) {
	// custom scenario where api expects date filter as query param, not a filter dql string
	// only filter that can be set on the table is the custom are component date picker filter
	const params = {
		date: state.filter,
	};

	return get(
		`/product/product_variants/${productVariantId}/workplace_relations`,
		params,
		null,
		state.headers
	)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}
