'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { store } from 'appState';

import { Button, InputCollectionSelect, ButtonGroup, Overlay } from 'dumb';
import { Tip } from 'dumb/tips';
import { ReactDataWrapper } from 'reactDataWrapper';
import { getColumns } from 'reactDataWrapperColumns/product/products.columns';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';
import { fetchProductProducts } from '../../../../tileLayout.service';

import _get from 'lodash/get';
import phrases from '../../../../tileLayout.phrases';
import './formtSelectProduct.css';

class FormSelectProduct extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
			showOverlay: false,
		};

		this.onOverlayOpen = this.onOverlayOpen.bind(this);
		this.reduxKey = 'tile_layouts/overlay/product/products/';

		this.columns = getColumns();
	}

	editStoreEntry(e, type) {
		const { batchForm } = this.props;
		const value = e.target ? e.target.value : e;

		const payload = {
			...batchForm,
			...{ [type]: value },
		};

		this.props.updateBatchForm(payload);
	}

	onOverlayOpen(batchForm) {
		const products = _get(batchForm, 'products', []);
		if (products.length) {
			products.map((x) => {
				store.dispatch(
					actions.addRowToSelection({
						reduxKey: this.reduxKey,
						data: x.value,
					})
				);
			});
		}
	}

	_toggleOverlay() {
		const { listData } = this.props;

		if (this.state.showOverlay) {
			let selectedRowsInList = _get(listData, 'ui.selectedRows', []);

			selectedRowsInList = selectedRowsInList.map((x) => {
				return {
					label: x.name,
					value: x,
				};
			});

			this.editStoreEntry(selectedRowsInList, 'products');
		}

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
		}));
	}

	fetchData(state) {
		return fetchProductProducts(state);
	}

	render() {
		const { batchForm } = this.props;

		return (
			<div className="modal-batch__content">
				<div className="modal-batch__content__row">
					<ButtonGroup type="full-width">
						<InputCollectionSelect
							className="modal-batch-select"
							id="layout-col"
							required
							label={phrases.PRODUCT_TITLE}
							placeholder={phrases.PRODUCT_SELECT}
							value={batchForm.products}
							handleChange={(key, value) =>
								this.editStoreEntry(value, 'products')
							}
							multi
							cache
							apiPath="/product/products"
							params={{
								limit: 50,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>

						<Button
							type="inverted"
							shadow
							size="large"
							onClick={() => this._toggleOverlay()}>
							<span className="icon icon--more" />
						</Button>
					</ButtonGroup>
				</div>

				<Overlay
					zIndex={551}
					height={850}
					visible={this.state.showOverlay}
					onClose={() => this._toggleOverlay()}>
					<div className="modal-batch__overlay">
						<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
							title={phrases.PRODUCT_TITLE}
							columns={this.columns}
							fetchData={this.fetchData}
							filterable
							disableFetchCsvButton
							enableMultiSelection
							batchSelection
							defaultPageSize={20}
							onInitialization={() => this.onOverlayOpen(batchForm)}
							reduxKey={this.reduxKey}
							manual
							actionsWidth={0}
							style={{
								maxHeight: '720px',
							}}
						/>
					</div>
				</Overlay>

				<div className="form-select-layout__tip">
					<Tip content={phrases.TIP_PRODUCTS} />
				</div>
			</div>
		);
	}
}

FormSelectProduct.propTypes = {
	batchForm: PropTypes.object,
	updateBatchForm: PropTypes.func,
	listData: PropTypes.object,
};

FormSelectProduct.defaultProps = {
	batchForm: {},
};

export default FormSelectProduct;
