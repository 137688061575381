export default {
	MODAL_TITLE: 'Transfer tenancy',
	TENANT_TRANSFERED: 'Tenant transfered',

	// step one
	STEP_ONE_TITLE: 'Select tenant',
	STEP_ONE_CONFIRM_BUTTON_LABEL: 'Next',
	STEP_ONE_CANCEL_SELECT_LABEL: 'Tenant',
	STEP_ONE_CANCEL_SELECT_PLACEHOLDER: 'Select tenant...',

	// step two
	STEP_TWO_TITLE: 'Enter values',
	STEP_TWO_USERS_LABEL: 'User',
	STEP_TWO_USERS_PLACEHOLDER: 'Select user...',
	STEP_TWO_COMPANY_LABEL: 'Company',
	STEP_TWO_COMPANY_PLACEHOLDER: 'Select company...',
	STEP_TWO_WORKPLACE_LABEL: 'Workplace',
	STEP_TWO_WORKPLACE_PLACEHOLDER: 'Select workplace...',
	STEP_TWO_START_DATE_LABEL: 'Start date',
	STEP_TWO_START_DATE_PLACEHOLDER: 'Select start date...',
	STEP_TWO_END_DATE_LABEL: 'End date',
	STEP_TWO_END_DATE_PLACEHOLDER: 'Select end date...',
	STEP_TWO_HAS_FOOD_PERMIT_LABEL: 'Has food permit',
	STEP_TWO_IN_SALARY_EXPORT_LABEL: 'In salary export',
	STEP_TWO_ADMINISTRATIVE_ONLY_LABEL: 'Administrative only',
	STEP_TWO_APP_ALLOWANCE_LABEL: 'App allowance',
	STEP_TWO_APP_ALLOWANCE_PLACEHOLDER: 'Select app allowance...',
	STEP_TWO_APP_ALLOWANCE_TABLE_TITLE: 'App allowances',
	STEP_TWO_SALARY_EXPORT_ID_LABEL: 'Salary export id',
	STEP_TWO_SALARY_EXPORT_ID_PLACEHOLDER: 'Enter salary export id...',
	STEP_TWO_EMAIL_LABEL: 'Email',
	STEP_TWO_EMAIL_PLACEHOLDER: 'Enter email...',
	STEP_TWO_PHONE_LABEL: 'Phone',
	STEP_TWO_PHONE_PLACEHOLDER: 'Enter phone...',
	TERMINATE_CURRENT_EMPLOYMENT: 'Terminate current employment',

	STEP_TWO_CONFIRM_BUTTON_LABEL_TERMINATE: 'Go to terminate',
	STEP_TWO_CONFIRM_BUTTON_LABEL_DONT_TERMINATE: 'Confirm transfer',

	STEP_TWO_BACK: 'Back',

	// for status modal
	STEP_THREE_TITLE: 'Transfer tenancy',
	STEP_THREE_SUCCESS: 'Employment terminated successfully. Click on submit to transfer user to a new tenant.',
	STEP_THREE_FAIL: 'Employment termination failed. Go to step two and try again.',
	STEP_THREE_SUBMIT: 'Transfer tenant',
};
