// utils
import _get from 'lodash/get';

const marketColumns = [
	{
		Header: 'Name',
		id: 'name',
		accessor: d => _get(d, 'name', ''),
		filterPath: ':name'
	},
	{
		Header: 'Market region',
		id: 'marketRegion',
		accessor: d => _get(d, 'market_region.name', ''),
		filterPath: ':market_region.name'
	}
];

export default {
	marketColumns,
};
