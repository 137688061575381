'use strict';

import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import _get from 'lodash/get';

/**
 * @function fetchEmploymentPositions
 * @description Fetch all positions
 * @param {object} state
 */
export function fetchEmploymentPositions(state, employmentId, personId) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get(`/hr/persons/${personId}/employments/${employmentId}/employment_positions`, params, null, state.headers)
		.then((res) => {
			return fetchEmploymentDocument(employmentId)
				.then((docs) => {
					res.data.map((x) => {
						docs.data.map((file) => {
							if (_get(file, 'employment_position.id', null) === x.id) {
								x.document = file;
							}
						});
						return x;
					});
					return res;
				})
				.catch((err) => {
					const errorMessage = formatErrorMessage(err);
					setFeedback(errorMessage, 0);
					throw err;
				});
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editEmploymentPositions
 * @description edit
 */
export function editEmploymentPositions({ id, payload, employmentId, personId }) {
	return post(`/hr/persons/${personId}/employments/${employmentId}/employment_positions/${id}`, payload)
		.then((res) => {
			setFeedback('Position edited', 1);
			return {
				...res,
				data: [
					{
						...res.data[0],
						...(payload.document && {
							document: payload.document,
						}),
					},
				],
			};
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function addEmploymentPositions
 * @description add
 */
export function addEmploymentPositions(data) {
	return post(`/hr/employment_positions`, data)
		.then((res) => {
			setFeedback('Position added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteEmploymentPositions
 * @description edit
 */
export function deleteEmploymentPositions(id) {
	return remove(`/hr/employment_positions/${id}`)
		.then((res) => {
			setFeedback('Position deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function fetchEmploymentDocument
 * @description fetch docs
 */
export function fetchEmploymentDocument(employmentId) {
	const filter = `:employment.id=='${employmentId}';:type=='Contract'`;

	// Set params and filter
	const params = { filter };

	return get(`/hr/employment_documents`, params)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteEmploymentDocument
 * @description remove
 */
export function deleteEmploymentDocument(documentId) {
	return remove(`/hr/employment_documents/${documentId}`)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function uploadEmploymentDocument
 * @description upload
 */
export function uploadEmploymentDocument(payload) {
	return post(`/hr/employment_documents`, payload)
		.then((res) => {
			setFeedback('File uploaded', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function fetchEmployment
 * @param {Number} { employmentId } - id of a employment to get
 * @description gets employments for the logged-in person
 */
export function fetchEmployment({ employmentId }) {
	// Set params and filter
	const params = {
		limit: 10,
		offset: 0,
	};

	// make a call
	return get(`/hr/employments/${employmentId}`, params)
		.then((res) => res.data[0])
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
