/**
 * @function getFilter
 * @param
 * @description returns a filter to get all existing entries to cross them out when batch adding
 * @returns {String}
 */
export function getFilter(listArray) {
	let posConfigurationVersionIds = listArray.map(
		(entry) => entry.posConfigurationVersion.id
	);
	// remove duplicates
	posConfigurationVersionIds = [...new Set(posConfigurationVersionIds)];
	posConfigurationVersionIds = posConfigurationVersionIds.reduce(
		(acc, currentValue, index) => {
			acc =
				index === posConfigurationVersionIds.length - 1
					? `${acc}'${currentValue}'`
					: `${acc}'${currentValue}',`;

			return acc;
		},
		''
	);

	let ingredientIds = listArray.map((entry) => entry.ingredient.id);
	// remove duplicates
	ingredientIds = [...new Set(ingredientIds)];
	ingredientIds = ingredientIds.reduce((acc, currentValue, index) => {
		acc =
			index === ingredientIds.length - 1
				? `${acc}'${currentValue}'`
				: `${acc}'${currentValue}',`;

		return acc;
	}, '');

	// constuct FIQL filters for both and combine them at the end
	const posFilter = `:pos_configuration_version.id=IN=[${posConfigurationVersionIds}]`;
	const ingredientFilter = `:ingredient.id=IN=[${ingredientIds}]`;

	const filter = `${posFilter};${ingredientFilter}`;

	return filter;
}

/**
 * @function markValuesWithCanAdd
 * @param {Object} object - contains chosen values (listArray) and existing values
 * @description loops through existing values and if some of our chosen combinations already exists
 * it marks it with canAdd: false to avoid batch submitting later on
 */
export function markValuesWithCanAdd({ listArray, data }) {
	return listArray.map((value) => {
		const posConfigurationVersionId = value.posConfigurationVersion?.id ?? null;
		const ingredientId = value.ingredient?.id ?? null;

		// try to find an ingredient pricing with the same ids
		const existingIngredientPricing = data.some(
			(entry) =>
				entry.pos_configuration_version.id === posConfigurationVersionId &&
				entry.ingredient.id === ingredientId
		);

		return {
			...value,
			canAdd: !existingIngredientPricing,
		};
	});
}
