import {
	SET_DISCOUNT,
	UPDATE_DISCOUNT,
	RESET_DISCOUNT
} from './discounts.actions';

const defaultState = {
	ui: {},
	data: {
		defaultDiscount: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_DISCOUNT: {
		return {
			...state,
			data: {
				...state.data,
				defaultDiscount: payload
			}
		};
	}

	case UPDATE_DISCOUNT: {
		return {
			...state,
			data: {
				...state.data,
				defaultDiscount: {
					...state.data.defaultDiscount,
					...payload
				}
			}
		};
	}

	case RESET_DISCOUNT: {
		return {
			...state,
			data: {
				...state.data,
				defaultDiscount: {}
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
