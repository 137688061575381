export const RESET_STATE = 'DOCUSIGN_TEMPLATE_DOCUMENT_FIELDS/RESET_STATE';
export const SET_DOCUSIGN_TEMPLATE_DOCUMENT_FIELD =
	'DOCUSIGN_TEMPLATE_DOCUMENT_FIELDS/SET_DOCUSIGN_TEMPLATE_DOCUMENT_FIELD';
export const UPDATE_DOCUSIGN_TEMPLATE_DOCUMENT_FIELD =
	'DOCUSIGN_TEMPLATE_DOCUMENT_FIELDS/UPDATE_DOCUSIGN_TEMPLATE_DOCUMENT_FIELD';

export function setDocusignTemplateDocumentField(payload) {
	return {
		type: SET_DOCUSIGN_TEMPLATE_DOCUMENT_FIELD,
		payload,
	};
}

export function updateDocusignTemplateDocumentField(payload) {
	return {
		type: UPDATE_DOCUSIGN_TEMPLATE_DOCUMENT_FIELD,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}
