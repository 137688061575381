'use strict';

import numeral from 'numeral';
import _isFinite from 'lodash/isFinite';

/**
 * Content
 * @param  {*} template
 * @param  {string} contentType
 */
class Content {
	constructor(
		template,
		contentType,
		fallbackTemplate = '',
		contentSourceProp = 'data'
	) {
		// Set type
		this.type = 'content';

		/// ///////////
		// Template //
		/// ///////////

		// Define templateType
		this.templateType = typeof template;

		// Define template
		this.template = template;

		// Fallback template
		this.fallbackTemplateType = typeof fallbackTemplate;
		this.setFallbackTemplate(fallbackTemplate);

		/// //////////////
		// ContentType //
		/// //////////////

		// Check column for contentType
		if (contentType === undefined || contentType === null)
			this.contentType = null;
		else this.contentType = contentType === null ? 'string' : contentType;

		/// ////////////////
		// ContentSource //
		/// ////////////////

		this.setContentSourceProp(contentSourceProp);
	}

	/// //////////
	// Setters //
	/// //////////

	/**
	 * @function setFallbackTemplate
	 * @memberOf Content
	 * @description Setter for fallbackTemplate
	 */
	setFallbackTemplate(fallbackTemplate) {
		this.fallbackTemplate = fallbackTemplate;
	}

	/**
	 * @function setContentSourceProp
	 * @memberOf Content
	 * @description Setter for contentSourceProp
	 */
	setContentSourceProp(contentSourceProp) {
		this.contentSourceProp = contentSourceProp;
	}

	/// //////////
	// Getters //
	/// //////////

	/**
	 * @function getContentSourceProp
	 * @memberOf Content
	 * @description Getter for contentSourceProp
	 */
	getContentSourceProp() {
		return this.contentSourceProp;
	}

	/**
	 * @function getContent
	 * @memberOf Content
	 * @description
	 * Returns formatted content
	 * @param  {object} data
	 * @param  {object} column
	 */
	getContent(data, width) {
		const value = this.getTemplate(data);
		if (value) {
			switch (this.contentType) {
			case 'integer':
				return numeral(parseFloat(value)).format('0,0');
			case 'percentage':
				return _isFinite(value)
					? `${numeral(parseFloat(value)).format('0.0')} %`
					: 'N/A';
			case 'percentageNoDecimal':
				return _isFinite(value)
					? `${numeral(parseFloat(value)).format('0')} %`
					: 'N/A';
			case 'currency':
				return numeral(parseFloat(value)).format('0,0.00');
			case 'currencyFiveDecimal':
				return numeral(parseFloat(value)).format('0,0.00000');
			case 'currencyNoDecimal':
				return numeral(parseFloat(value)).format('0,0');
			case 'number':
				return numeral(parseFloat(value)).format('0,0.00');
			case 'numberSingleDecimal':
				return numeral(parseFloat(value)).format('0,0.0');
			case 'numberNoDecimal':
				return numeral(parseFloat(value)).format('0,0');
			case 'truncated':
				const charWidth = 5;
				const charBuffer = 4;
				return value.length > (width - charBuffer) / charWidth
					? `${value.substring(
						0,
						Math.round((width - charBuffer) / charWidth)
						  )}...`
					: value;
			default:
				return value;
			}
		} else return this.getFallbackTemplate(data);
	}

	/**
	 * @function getTemplate
	 * @memberOf Content
	 * @description
	 * Returns unformatted content
	 * @param  {object} data
	 */
	getTemplate(data) {
		switch (this.templateType) {
		case 'function':
			return this.template(data);
		case 'string':
			return this.template;
		}
	}

	/**
	 * @function getFallbackTemplate
	 * @memberOf Content
	 * @description Getter for fallbackTemplate
	 */
	getFallbackTemplate(data) {
		switch (this.fallbackTemplateType) {
		case 'function':
			return this.fallbackTemplate(data);
		case 'string':
			return this.fallbackTemplate;
		}
	}
}

export default Content;
