'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { Input } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';

import {
	addWaitingTimeGridEntry,
	editWaitingTimeGridEntry,
	deleteWaitingTimeGridEntry,
	fetchWaitingTimeGridEntries
} from './waitingTimeGridEntries.service';

// constants/phrases/tools
import phrases from './waitingTimeGridEntries.phrases';
import _get from 'lodash/get';

class WaitingTimeGridEntries extends Component {
	constructor(props) {
		super(props);

		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.fetchData = this.fetchData.bind(this);

		this.columns = [
			{
				Header: 'Fuel',
				id: 'fuel',
				accessor: d => _get(d, 'fuel', ''),
				filterPath: ':fuel_maximum'
			},
			{
				Header: 'Waiting time',
				id: 'waiting_time',
				accessor: d => {
					return _get(d, 'waiting_time', '');
				},
				filterPath: ':waiting_time'
			}
		];
	}

	deleteEntry(id) {
		return deleteWaitingTimeGridEntry(id);
	}

	addEntry() {
		const { waitingTimeGridEntry, waitingTimeGridId } = this.props;

		const payload = {
			waiting_time_grid: waitingTimeGridId,
			fuel: waitingTimeGridEntry.fuel,
			waiting_time: waitingTimeGridEntry.waiting_time
		};

		return addWaitingTimeGridEntry(payload);
	}


	editEntry() {
		const { waitingTimeGridEntry } = this.props;

		const payload = {
			id: waitingTimeGridEntry.id,
			fuel: waitingTimeGridEntry.fuel,
			waiting_time: waitingTimeGridEntry.waiting_time
		};

		return editWaitingTimeGridEntry(payload, waitingTimeGridEntry.id);
	}

	setInitialEditValues(data) {
		const { setWaitingTimeGridEntryModal } = this.props;

		const payload = {
			id: data.id,
			fuel: data.fuel,
			waiting_time: data.waiting_time
		};

		setWaitingTimeGridEntryModal(payload);
	}

	getEditableCells() {
		const { waitingTimeGridEntry } = this.props;

		return [
			{
				header: 'Fuel',
				value: (
					<Input
						id="fuel"
						type="number"
						placeholder="fuel"
						min="0" 
						max="100"
						value={waitingTimeGridEntry?.fuel || null}
						onChange={event => this.editStoreEntry('fuel', event)}
					/>
				)
			},
			{
				header: 'Waiting time',
				value: (
					<Input
						id="waiting_time"
						type="number"
						placeholder="Waiting time"
						value={waitingTimeGridEntry?.waiting_time || null}
						onChange={event => this.editStoreEntry('waiting_time', event)}
					/>
				)
			}
		];
	}

	editStoreEntry(name, event) {
		const { updateWaitingTimeGridEntry } = this.props;

		const payload = {
			[name]: event.target.value
		};

		updateWaitingTimeGridEntry(payload);
	}

	fetchData(state) {
		const { waitingTimeGridId } = this.props;

		state = {
			...state,
			filter: state.filter
				? `${state.filter};:waiting_time_grid.id=='${waitingTimeGridId}'`
				: `:waiting_time_grid.id=='${waitingTimeGridId}'`
		};

		return fetchWaitingTimeGridEntries(state);
	}

	render() {
		const { reduxKey, resetWaitingTimeGridEntryModal } = this.props;
		
		return (
			<ReactDataWrapper
				title={phrases.TABLE_TITLE}
				columns={this.columns}
				filterable
				reduxKey={reduxKey}
				manual
				fetchData={this.fetchData}
				editableCells={this.getEditableCells()}
				actionsWidth={60}
				defaultPageSize={100}
				defaultSorted={[
					{
						id: 'fuel',
						desc: false
					}
				]}
				createEntry={this.addEntry}
				editEntry={this.editEntry}
				deleteEntry={this.deleteEntry}
				editMultiple={this.editMultiple}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={resetWaitingTimeGridEntryModal}
				inRowButtons
			/>
		);
	}
}

WaitingTimeGridEntries.propTypes = {
	setWaitingTimeGridEntryModal: PropTypes.func,
	resetWaitingTimeGridEntryModal: PropTypes.func,
	reduxKey: PropTypes.string,
	updateWaitingTimeGridEntry: PropTypes.func,
	waitingTimeGridEntry: PropTypes.object,
	waitingTimeGridId: PropTypes.number
};

export default WaitingTimeGridEntries;
