'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import FileUpload from './fileUpload.component';
import * as Sentry from '@sentry/browser';

import { connectWithStore } from 'appState';

import './fileUpload.css';

class FileUploadContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			error: false,
			sending: false,
		};

		this.fileUploadMethods = {
			handleSendResultSet: this.handleSendResultSet.bind(this),
		};
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		console.error(error, errorInfo);

		Sentry.withScope((scope) => {
			scope.setExtra('info', errorInfo);
			scope.setExtra('error', error);
			Sentry.captureException(error);
			Sentry.captureMessage('Error in FileUploadContainer', 'error');
		});
	}

	/**
	 * Handler for returning the resultSet to outer component
	 * This is able to receive a promise, or a simple method.
	 * The method just triggers the .then() right away, so
	 * there is no showing of loaders/errors.
	 */
	handleSendResultSet() {
		const { fileUpload, onChange } = this.props;

		this.setState(() => ({ sending: true, error: false }));

		// Begin promise chain
		fileUpload
			.handleGetResultSet()

			// Send resultSet to outer component via onChange (also typically a promise)
			.then((resultSet) => onChange(resultSet))

			// Outer component promise complete (typically uploading the files to the server)
			.then(() => {
				// Reset all file types
				fileUpload.handleResetFileTypes();

				// Update number of file types
				this.setState(() => ({
					files: fileUpload.getFileTypesFiles().length,
					sending: false,
				}));
			})

			// Catch any errors and act responsible
			.catch((errorMessage) => {
				// Stop sending and show error
				this.setState(() => ({ sending: false, error: errorMessage || true }));
			});
	}

	componentDidMount() {
		const { fileUpload } = this.props;

		// Reset all file types
		fileUpload.handleResetFileTypes();
	}

	render() {
		const { fileUpload, fileUploadState, defaultFileNameData, children } =
			this.props;
		const { error, sending } = this.state;

		return (
			<FileUpload
				{...{
					defaultFileNameData,
					error,
					fileUpload,
					fileUploadState,
					methods: this.fileUploadMethods,
					phrases: fileUpload.getPhrases(),
					sending,
					children,
				}}
			/>
		);
	}
}

FileUploadContainer.defaultProps = {
	defaultFileNameData: null,
};

FileUploadContainer.propTypes = {
	fileUpload: PropTypes.object.isRequired,
	defaultFileNameData: PropTypes.object,
	children: PropTypes.object,
	fileUploadState: PropTypes.object,
	onChange: PropTypes.func,
};

const mapStateToPropsFactory = (initialStore, ownProps) => (store) => ({
	fileUploadState: {
		fileTypeTemplates: _get(
			store,
			`fileUpload.fileTypeTemplates[${ownProps.fileUpload.name}]`,
			{}
		),
		fileTypes: _get(
			store,
			`fileUpload.fileTypes[${ownProps.fileUpload.name}]`,
			{}
		),
		fileCounts: _get(
			store,
			`fileUpload.fileCounts[${ownProps.fileUpload.name}]`,
			0
		),
	},
});

export default connectWithStore(FileUploadContainer, mapStateToPropsFactory);
