'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// columns
import { InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';
import getSystemSoftwareColumns from 'reactDataWrapperColumns/administration/systemSoftwares.columns';
import getWorkplaceColumns from 'reactDataWrapperColumns/administration/workplaces.columns';

// utils
import phrases from './../../../workplaceSystemSoftwares.phrases';
import moment from 'moment';
import constants from 'services/constants';

class WorkplaceSystemSoftwaresBatchStepForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};

		this.getWorkplaceFilter = this.getWorkplaceFilter.bind(this);
	}

	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		updateBatchForm({
			[type]: e,
		});
	}

	getWorkplaceFilter() {
		const { workplaceTableMarketValue } = this.props;

		const dateFilter = `:closed=ge='${moment
			.utc()
			.format(constants.shortDate)}'`;
		const marketFilter = workplaceTableMarketValue
			? `:market.id=='${workplaceTableMarketValue}'`
			: '';

		let filter = dateFilter;
		if (marketFilter) filter = `${filter};${marketFilter}`;

		return filter;
	}

	render() {
		const { batchForm, systemSoftwareId, workplacesTableReduxKey } = this.props;

		return (
			<ModalRow>
				{systemSoftwareId ? (
					<InputCollectionSelect
						id="workplaces"
						label={phrases.MODAL_BATCH_STEP_WORKPLACES}
						placeholder={phrases.MODAL_BATCH_STEP_WORKPLACES_PLACEHOLDER}
						value={batchForm.workplaces}
						handleChange={(key, value) =>
							this.editStoreEntry(value, 'workplaces')
						}
						multi
						cache
						apiPath="/administration/workplaces"
						params={{
							limit: 30,
							filter: this.getWorkplaceFilter(),
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						tableColumns={getWorkplaceColumns(workplacesTableReduxKey)}
						tableTitle={phrases.MODAL_BATCH_STEP_WORKPLACES_TABLE_TITLE}
						tableReduxKey={workplacesTableReduxKey}
					/>
				) : (
					<InputCollectionSelect
						id="system-softwares"
						label={phrases.MODAL_BATCH_STEP_SYSTEM_SOFTWARES}
						placeholder={phrases.MODAL_BATCH_STEP_SYSTEM_SOFTWARES_PLACEHOLDER}
						value={batchForm.systemSoftwares}
						handleChange={(key, value) =>
							this.editStoreEntry(value, 'systemSoftwares')
						}
						multi
						cache
						apiPath="/administration/system_softwares"
						params={{
							limit: 30,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.title,
						})}
						inputFilterFormat={(input) => `:title=like='%${input}%'`}
						tableColumns={getSystemSoftwareColumns()}
						tableTitle={phrases.MODAL_BATCH_STEP_SYSTEM_SOFTWARES_TABLE_TITLE}
						tableReduxKey="workplace-batch-add/administration/system_softwares"
					/>
				)}
			</ModalRow>
		);
	}
}

WorkplaceSystemSoftwaresBatchStepForm.propTypes = {
	batchForm: PropTypes.object,
	updateBatchForm: PropTypes.func,
	systemSoftwareId: PropTypes.number,
	workplacesTableReduxKey: PropTypes.string,
	workplaceTableMarketValue: PropTypes.number,
};

export default WorkplaceSystemSoftwaresBatchStepForm;
