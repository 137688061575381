'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { InputCollectionSelect, TimePickerNew } from 'dumb';
import { ModalRow } from 'dumb/modal';
import GlobalBundleRulesWeekView from './../../../../globalBundleRulesWeekView/globalBundleRulesWeekView';

// utils
import enums from 'services/enums.service';

// constants/phrases/enums
import constants from 'services/constants';
import phrases from './../../../globalBundleRules.phrases';

class GlobalBundleRulesBatchStepForm extends Component {
	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		const value = e.target ? e.target.value : e;

		updateBatchForm({
			[type]: value,
		});
	}

	getTime(name, value) {
		if (value) return value;

		return name === 'from' ? moment().format(constants.timeShort) : '23:59';
	}

	getWeekViewData(batchFormData) {
		return (batchFormData.weekday || []).map((entry) => {
			return {
				weekday: entry.value,
				time: {
					from: batchFormData.from || moment().format(constants.timeShort),
					to: batchFormData.to || '23:59',
				},
			};
		});
	}

	render() {
		const { batchFormData } = this.props;

		const data = this.getWeekViewData(batchFormData);

		return (
			<>
				<ModalRow>
					<GlobalBundleRulesWeekView data={data} hourToPixelRatio={5} />
				</ModalRow>

				<ModalRow>
					<InputCollectionSelect
						id="weekday"
						label={phrases.MODAL_BATCH_STEP_WEEKDAY}
						placeholder={phrases.MODAL_BATCH_STEP_WEEKDAY_PLACEHOLDER}
						value={batchFormData.weekday}
						handleChange={(key, value) => this.editStoreEntry(value, 'weekday')}
						options={enums.weekdaysLabels}
						required
						multi
					/>
				</ModalRow>

				<ModalRow>
					<TimePickerNew
						id="from"
						label={phrases.MODAL_BATCH_FROM}
						onChange={(event) => this.editStoreEntry(event, 'from')}
						value={this.getTime('from', batchFormData.from)}
					/>
					<TimePickerNew
						id="to"
						label={phrases.MODAL_BATCH_TO}
						onChange={(event) => this.editStoreEntry(event, 'to')}
						value={this.getTime('to', batchFormData.to)}
					/>
				</ModalRow>
			</>
		);
	}
}

GlobalBundleRulesBatchStepForm.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
};

export default GlobalBundleRulesBatchStepForm;
