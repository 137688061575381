'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';

import Allergens from './components/allergens/allergens.component';

import _get from 'lodash/get';

import { setAllergenCategory, updateAllergenCategory, resetState } from './store/allergenCategories.actions';

import {
	fetchAllergenCategories,
	editAllergenCategories,
	addAllergenCategories,
	deleteAllergenCategories,
} from './store/allergenCategories.service';

import phrases from './allergenCategories.phrases';
import { Input, Button, Toggle } from 'dumb';

// Wrapper for react-data
// Documentation can be found here https://react-table.js.org/
class AllergeneCategories extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);

		this.state = {
			pages: null,
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this.fetchData = this.fetchData.bind(this);

		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: 'legacy',
				filterPath: ':legacy',
				filterable: false,
				Cell: (d) => {
					return <Input type="checkbox" checked={d.original.legacy} disabled />;
				},
			},
		];
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}
			>
				Legacy
			</Button>
		);
	}

	deleteEntry(id) {
		return deleteAllergenCategories(id);
	}

	editEntry() {
		const { defaultValues } = this.props;

		const payload = {
			id: defaultValues.id,
			name: _get(defaultValues, 'name', ''),
			legacy: !!defaultValues.legacy,
		};

		return editAllergenCategories(defaultValues.id, payload);
	}

	addEntry() {
		const { defaultValues } = this.props;

		const payload = {
			name: _get(defaultValues, 'name', ''),
		};

		return addAllergenCategories(payload);
	}

	setInitialEditValues(data) {
		this.props.setAllergenCategory(data);
	}

	getEditableCells(e) {
		const { defaultValues } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="name"
						value={_get(defaultValues, 'name', '')}
						onChange={(event) => this.editStoreEntry(event, 'name')}
					/>
				),
			},
			{
				header: 'Legacy',
				value: (
					<Toggle
						id="legacy"
						toggled={defaultValues.legacy}
						onClick={(event) => this.editStoreEntry(event, 'legacy')}
					/>
				),
			},
		];
	}

	editStoreEntry(e, name) {
		const value = e.target ? e.target.value : e;

		const updateObject = {
			[name]: value,
		};

		this.props.updateAllergenCategory(updateObject);
	}

	fetchData(state) {
		return fetchAllergenCategories(state);
	}

	render() {
		const legacyFilter = this.state.legacyFilterOn ? this.state.legacyFilter : '';

		return (
			<ReactDataWrapper
				accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.ALLERGEN_CATEGORIES}
				className="-striped -highlight"
				columns={this.columns}
				totalEntries={this.state.totalEntries} // Display the total number of pages
				fetchData={this.fetchData} // Request new data when things change
				filterable
				defaultPageSize={10}
				reduxKey="product/allergen_categories"
				manual // Forces table not to paginate or sort automatically, so we can handle it server-side
				editEntry={(e) => this.editEntry(e)}
				editableCells={this.getEditableCells()}
				subcomponent={(row) => {
					return (
						<SubTable>
							<Allergens title={phrases.ALLERGENES} allergenCategory={row.original} />
						</SubTable>
					);
				}}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetState}
				actionsWidth={60}
				deleteEntry={this.deleteEntry}
				createEntry={this.addEntry}
				customAreaComponents={this.getLegacyFilterButton()}
				extraFilters={legacyFilter}
			/>
		);
	}
}

AllergeneCategories.propTypes = {
	defaultValues: PropTypes.object,

	updateAllergenCategory: PropTypes.func,
	setAllergenCategory: PropTypes.func,
	resetState: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setAllergenCategory,
			updateAllergenCategory,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultValues: store.allergenCategories.data.defaultAllergenCategory,
	};
};

export default connectWithStore(AllergeneCategories, mapStateToProps, mapDispatchToProps);
