'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import Color from 'reusableComponents/inputs/color.component';
import { SectionCollapsable, Input } from 'dumb';

class AddGroupForm extends Component {
	constructor(props) {
		super(props);

		this.modalRef = React.createRef();

		this.state = {
			showColorPicker: false,
			showTextColorPicker: false
		};

		// Binds
		this._onInputUpdate = this._onInputUpdate.bind(this);
	}

	_toggleColorPicker() {
		this.setState(prev => ({
			showColorPicker: !prev.showColorPicker
		}));
	}

	_toggleTextColorPicker() {
		this.setState(prev => ({
			showTextColorPicker: !prev.showTextColorPicker
		}));
	}

	_onInputUpdate(e, type) {
		const { groupData } = this.props;

		const data = {
			...groupData
		};

		switch (type) {
		case 'title':
			data.name = e.target.value;
			break;

		case 'description':
			data.description = e.target.value;
			break;

		case 'color':
			data.background_color = e.hex.replace('#', '');
			break;

		case 'colorText':
			data.title_color = e.hex.replace('#', '');
			break;
		}

		this.props.onGroupFormUpdate(data);
	}

	render() {
		const { showColorPicker, showTextColorPicker } = this.state;
		const { groupData, isTypePos } = this.props;

		let backgroundColor = groupData.background_color;
		let titleColor = groupData.title_color;

		// if add, invert colors
		if (!groupData.id && isTypePos) {
			backgroundColor = groupData.title_color;
			titleColor = groupData.background_color;
		}

		return (
			<div className="pos-tile-modal">
				<SectionCollapsable isOpen title="Group settings">
					<div className="pos-tile-modal__row">
						<div className="pos-tile-modal__row__input">
							<Input
								label="Title"
								value={groupData.name}
								placeholder="Enter title..."
								onChange={e => this._onInputUpdate(e, 'title')}
							/>
						</div>

						<div className="pos-tile-modal__row__input">
							<Input
								label="Description"
								placeholder="Enter description..."
								value={groupData.description}
								onChange={e => this._onInputUpdate(e, 'description')}
							/>
						</div>
					</div>

					<div className="pos-tile-modal__row">
						<div className="pos-tile-modal__row__input">
							<label title="Background color">Background Color</label>

							<div className="pos-tile-modal__row__color">
								<button
									className="button"
									onClick={() => this._toggleColorPicker()}>
									Picker
								</button>
								<div
									className="pos-tile-modal__row__color__inner"
									style={{ backgroundColor: `#${backgroundColor}` }}
								/>
								<span>#{backgroundColor}</span>
							</div>
						</div>
					</div>

					{showColorPicker && (
						<div className="pos-tile-modal__color-picker">
							<Color
								onChange={e => this._onInputUpdate(e, 'color')}
								triangle="hide"
								type="Twitter"
								color={`#${backgroundColor}`}
							/>
						</div>
					)}

					<div className="pos-tile-modal__row">
						<div className="pos-tile-modal__row__input">
							<label title="Background color">Text Color</label>

							<div className="pos-tile-modal__row__color">
								<button
									className="button"
									onClick={() => this._toggleTextColorPicker()}>
									Picker
								</button>
								<div
									className="pos-tile-modal__row__color__inner"
									style={{ backgroundColor: `#${titleColor}` }}
								/>
								<span>#{titleColor}</span>
							</div>
						</div>
					</div>

					{showTextColorPicker && (
						<div className="pos-tile-modal__color-picker">
							<Color
								onChange={e => this._onInputUpdate(e, 'colorText')}
								triangle="hide"
								type="Twitter"
								color={`#${backgroundColor}`}
							/>
						</div>
					)}
				</SectionCollapsable>
			</div>
		);
	}
}

AddGroupForm.propTypes = {
	onGroupFormUpdate: PropTypes.func,
	groupData: PropTypes.object,
	isTypePos: PropTypes.bool
};

export default AddGroupForm;
