export default {
	TITLE: 'Manage clockins',
	DATE: 'Date',
	WORKPLACE: 'Workplace',
	EMPLOYEE: 'Employee',
	SHIFT: 'Shift',
	DEVIATION_REASON: 'Deviation reason',
	APPROVED_BY: 'Approved by',
	MANAGE: 'Manage',
	ID: 'ID',
	NAME: 'Name',
	PLANNED: 'Planned',
	CLOCKED: 'Clocked',
	START: 'Start',
	END: 'End',
	FULL: 'Full',
	CLOCK_IN: 'Clock in',
	CLOCK_OUT: 'Clock out',
	STATUS: 'Status',
	ACTION: 'Action',
	MANAGE_REASON: 'Manage reason',
	TOOLTIP_CORRECT_CLOCKIN: 'Correct clockin',
	TOOLTIP_APPROVE_CHANGE: 'Approve change',
	TOOLTIP_ZERO_LENGTH_BREAKS: 'Set planned breaks as zero-length',
	APPROVE_ALL: 'Approve all',
	NO_CLOCK_OUT_TOOLTIP: "Juicer didn't clockout",
	CLOCKINS_NOT_ON_SAME_DAY: 'Clockins not on same day, click to adjust',
	BREAKS_NOT_ON_SAME_DAY:
		'Breaks clocked value not on the same day, click to adjust',

	// menu
	MARK_AS_NO_SHOW: 'Mark as no show',
	ADD_BREAK: 'Add break',
	ZERO_LENGTH_BREAKS: 'Zero length planned breaks',
};
