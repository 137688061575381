import React from 'react';
import { InputCollectionSelect, Input, ModalRow } from 'dumb';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

export const AddressCreateModal = ({ personAddress, onChange }) => {
	return (
		<>
			<ModalRow>
				<Input
					id="Address"
					type="text"
					label="Address"
					placeholder="Address"
					value={_get(personAddress, 'address', '')}
					onChange={(event) =>
						onChange({ handle: 'address', value: event.target.value })
					}
				/>
			</ModalRow>
			<ModalRow>
				<InputCollectionSelect
					id="Country"
					label="Country"
					value={_get(personAddress, 'country', '')}
					name="country"
					onChange={(event) => onChange({ handle: 'country', value: event })}
					placeholder="Country"
					apiPath="/administration/countries"
					params={{
						limit: 300,
					}}
					tabIndex={0}
				/>
			</ModalRow>
		</>
	);
};

AddressCreateModal.propTypes = {
	personAddress: PropTypes.object,
	onChange: PropTypes.object,
};
