'use strict';

import { get, remove, post } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './../../vacationsIllness.phrases';
import { saveAs } from 'file-saver';
import constants from 'services/constants';
import moment from 'moment';

/**
 * @function fetchIllnesses
 * @description Fetches illnesses for person
 * @param {Object} Object with limit, offset, filter, header and sort
 * @param {Number} personId - id of a person to get the illnesses for
 */
export function fetchIllnesses(
	{ limit, offset, filter, sort, headers },
	personId
) {
	filter = filter
		? `${filter};:person.id=='${personId}'`
		: `:person.id=='${personId}'`;

	// Set params and filter
	const params = {
		limit,
		sort,
		offset,
		filter,
	};

	return get('/hr/person_illnesses', params, null, headers)
		.then((res) => {
			if (headers) {
				const blob = new Blob([res], { type: 'text/csv;charset=utf-8' });

				const csvFileName = `hr_person_illnesses__${moment().format(
					constants.shortDate
				)}.csv`;
				const fileName = csvFileName;

				saveAs(blob, fileName);
			}

			return res;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
		});
}

/**
 * @function deleteIllness
 * @param {Number} id - illness id
 * @description Delete an illness
 */
export function deleteIllness(id) {
	return remove(`/hr/person_illnesses/${id}`)
		.then((res) => {
			setFeedback(phrases.ILLNESS_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
			throw err;
		});
}

/**
 * @function editIllness
 * @param {Object} payload
 * @description Edit illness
 */
export function editIllness(payload) {
	return post(`/hr/person_illnesses/${payload.id}`, payload)
		.then((res) => {
			setFeedback(phrases.ILLNESS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
		});
}

/**
 * @function addIllness
 * @description add illness
 * @param {Object} payload object with data to update
 * @param {Number} payload.personId id of a person to create the illness for
 * @param {Object} payload.period object with periods
 * @param {String} payload.period.from from date
 * @param {String} payload.period.to to date
 */
export function addIllness(payload) {
	return post('/hr/person_illnesses', payload)
		.then((res) => {
			setFeedback(phrases.ILLNESS_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
		});
}
