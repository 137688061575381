import { ReportFactory as R } from 'report/factory';
import { ReportEndpointDataProvider, ReportDownloadCsvDataProvider } from 'report/dataProviders';

import CurrenciesPrMonthHotbar from './currenciesPrMonthReport.hotbar';
import { getParameters } from './currenciesPrMonthReport.methods';
import { ReportingCurrencyReportTable } from './components';

import endpoints from 'endpoints.service';

import {ImportCsvModalFactory as i} from 'importCsvModal/factory';

import ImportCSVReportAction from 'importCsvModal/importCsvModalAction.container.js';
import { uploadCurrenciesPrMonthCsv } from './services';

// Data provider
const CurrenciesPrMonthReportDataProvider = new ReportEndpointDataProvider('/reporting/reporting_currency_reports');

const CurrenciesPrMonthReportDownloadCsvDataProvider = new ReportDownloadCsvDataProvider('CSV', endpoints.collection.reporting.reporting_currencies, 'reporting_currencies');
const CurrenciesPrMonthCsvModalConfiguration = i.importCsvModal('Import');

CurrenciesPrMonthCsvModalConfiguration.setExample('ISOCODE;Year;Month;Value DKK;2018;1;2.00 DKK;2018;2;2.00 DKK;2018;3;1.00 DKK;2018;4;3.00 DKK;2018;5;3.00 DKK;2018;6;3.00 DKK;2018;7;3.00 DKK;2018;8;3.00 DKK;2018;9;3.00 DKK;2018;10;3.00 DKK;2018;11;3.00 DKK;2018;12;3.00');
CurrenciesPrMonthCsvModalConfiguration.setOnChangeFunction(uploadCurrenciesPrMonthCsv);

// Report
const CurrenciesPrMonthReport = R.report('dailyReport', CurrenciesPrMonthReportDataProvider, CurrenciesPrMonthHotbar);

// Report metadata
const m = R.metadata();
CurrenciesPrMonthReport.setMetadata(m.metadata(
	m.title('Currencies Pr Month'),
	m.subtitle(d => `${moment(R.data(d, 'period.from')).format('DD-MM-YYYY')} - ${moment(R.data(d, 'period.to')).format('DD-MM-YYYY')}`),
	m.printTitle(d => R.data(d, 'name'))
));

// Parameters
CurrenciesPrMonthReport.setParameterMethod(getParameters);
CurrenciesPrMonthReport.addCsvDataProvider(CurrenciesPrMonthReportDownloadCsvDataProvider);

// Components
CurrenciesPrMonthReport.setComponents({ReportingCurrencyReportTable});

// Add action to report action bar
CurrenciesPrMonthReport.addReportAction(ImportCSVReportAction, CurrenciesPrMonthCsvModalConfiguration);

export default CurrenciesPrMonthReport;