'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Button,
	Input,
	InputCollectionSelect,
	SingleDatePickerInput,
	Tooltip,
	Icon,
	ButtonLoader,
} from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';
import PosConfigurationVersionsBatchStepFormRowDuplicateAsNew from './posConfigurationVersionsBatchStepFormRowDuplicateAsNew';

// utils
import { getStateOptions } from './../utils';

// constants/phrases
import constants from 'services/constants';
import phrases from './../../../posConfigurationVersions.phrases';
import moment from 'moment';

class PosConfigurationVersionsBatchStepListDuplicateAsNew extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		let value;
		if (e === null) value = e;
		else value = e.target ? e.target.value : e;

		// if dates cleared, set them to today and infinite
		if ((type === 'activeFrom' || type === 'activeTo') && value === null) {
			value =
				type === 'activeFrom'
					? moment.utc().format(constants.shortDate)
					: '9999-12-31';
		}

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: value },
		});
	}

	render() {
		const {
			batchListData,
			batchFormData,
			updateBatchForm,
			posConfigurationReduxKey,
			posLayoutReduxKey,
			appLayoutReduxKey,
			toggleOverlay,
			loading,
			revertBatchListItem,
			submitRow,
			batchListItemLoadingId,
		} = this.props;

		return (
			<>
				<PosConfigurationVersionsBatchStepFormRowDuplicateAsNew
					batchFormData={batchFormData}
					updateBatchForm={updateBatchForm}
					posConfigurationReduxKey={posConfigurationReduxKey}
					posLayoutReduxKey={posLayoutReduxKey}
					appLayoutReduxKey={appLayoutReduxKey}
					toggleOverlay={toggleOverlay}
					loading={loading}
				/>

				<TableList className="pos-configuration-version-modal-batch-duplicate-as-new__table">
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_POS_CONFIGURATION}</th>
							<th>{phrases.MODAL_BATCH_STEP_NAME}</th>
							<th>{phrases.MODAL_BATCH_STEP_DESCRIPTION}</th>
							<th>{phrases.MODAL_BATCH_STEP_STATE}</th>
							<th>{phrases.MODAL_BATCH_STEP_APP_TILE_LAYOUT}</th>
							<th>{phrases.MODAL_BATCH_STEP_POS_TILE_LAYOUT}</th>
							<th>{phrases.MODAL_BATCH_STEP_DELIVERY_TILE_LAYOUT}</th>
							<th>{phrases.MODAL_BATCH_ACTIVE_FROM}</th>
							<th>{phrases.MODAL_BATCH_ACTIVE_TO}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{batchListData.map((entry, i) => {
							const rowSubmitted = entry.rowSubmitted;

							return (
								<TableListRow
									key={entry.identifier}
									type={rowSubmitted && 'light'}>
									<TableListCell width="30px">{i + 1}</TableListCell>
									<TableListCell width="200px">
										<InputCollectionSelect
											id={`${entry.identifier}-pos_configuration}`}
											value={entry.posConfiguration}
											handleChange={(key, value) =>
												this._editListEntry(value, entry, 'posConfiguration')
											}
											cache
											apiPath="/pos/pos_configurations"
											params={{
												limit: 30,
												filter: ':legacy==false',
											}}
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											inputFilterFormat={(input) => `:name=like='%${input}%'`}
											disabled={rowSubmitted}
										/>
									</TableListCell>

									<TableListCell width="180px">
										<Input
											id={`${entry.identifier}-name}`}
											placeholder={phrases.MODAL_BATCH_STEP_NAME_PLACEHOLDER}
											value={entry.name || ''}
											onChange={(event) =>
												this._editListEntry(event, entry, 'name')
											}
											disabled={rowSubmitted}
										/>
									</TableListCell>

									<TableListCell width="180px">
										<Input
											id={`${entry.identifier}-description`}
											placeholder={
												phrases.MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER
											}
											value={entry.description || ''}
											onChange={(event) =>
												this._editListEntry(event, entry, 'description')
											}
											disabled={rowSubmitted}
										/>
									</TableListCell>

									<TableListCell width="180px">
										<InputCollectionSelect
											id={`${entry.identifier}-state`}
											placeholder={phrases.MODAL_BATCH_STEP_STATE_PLACEHOLDER}
											value={entry.state}
											handleChange={(key, value) =>
												this._editListEntry(value, entry, 'state')
											}
											options={getStateOptions()}
											clearable={false}
											disabled={rowSubmitted}
										/>
									</TableListCell>

									<TableListCell width="200px">
										<InputCollectionSelect
											id={`${entry.identifier}-appTileLayout`}
											placeholder={
												phrases.MODAL_BATCH_STEP_APP_TILE_LAYOUT_PLACEHOLDER
											}
											value={entry.appTileLayout}
											handleChange={(key, value) =>
												this._editListEntry(value, entry, 'appTileLayout')
											}
											cache
											apiPath="/pos/tile_layouts"
											params={{
												limit: 30,
												filter: `:type=='App'`,
											}}
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											inputFilterFormat={(input) => `:name=like='%${input}%'`}
											disabled={rowSubmitted}
										/>
									</TableListCell>

									<TableListCell width="200px">
										<InputCollectionSelect
											id={`${entry.identifier}-posTileLayout`}
											placeholder={
												phrases.MODAL_BATCH_STEP_POS_TILE_LAYOUT_PLACEHOLDER
											}
											value={entry.posTileLayout}
											handleChange={(key, value) =>
												this._editListEntry(value, entry, 'posTileLayout')
											}
											cache
											apiPath="/pos/tile_layouts"
											params={{
												limit: 30,
												filter: `:type=='POS'`,
											}}
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											inputFilterFormat={(input) => `:name=like='%${input}%'`}
											disabled={rowSubmitted}
										/>
									</TableListCell>

									<TableListCell width="200px">
										<InputCollectionSelect
											id={`${entry.identifier}-delivery-tile-layout`}
											placeholder={
												phrases.MODAL_BATCH_STEP_DELIVERY_TILE_LAYOUT_PLACEHOLDER
											}
											value={entry.deliveryTileLayout}
											handleChange={(key, value) =>
												this._editListEntry(value, entry, 'deliveryTileLayout')
											}
											cache
											apiPath="/pos/tile_layouts"
											params={{
												limit: 30,
												filter: `:type=='Delivery'`,
											}}
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											inputFilterFormat={(input) => `:name=like='%${input}%'`}
											disabled={rowSubmitted}
										/>
									</TableListCell>

									<TableListCell width="180px">
										<SingleDatePickerInput
											id={`${entry.identifier}-activeFrom`}
											onChange={(event) =>
												this._editListEntry(event, entry, 'activeFrom')
											}
											selectedDate={
												entry.activeFrom
													? moment
															.utc(entry.activeFrom)
															.format(constants.shortDate)
													: ''
											}
											placeholder={entry.activeFromOld}
											noClockButton
											clearable
											disabled={rowSubmitted}
										/>
									</TableListCell>

									<TableListCell width="180px">
										<SingleDatePickerInput
											id={`${entry.identifier}-activeTo`}
											onChange={(event) =>
												this._editListEntry(event, entry, 'activeTo')
											}
											selectedDate={
												entry.activeTo
													? moment
															.utc(entry.activeTo)
															.format(constants.shortDate)
													: ''
											}
											placeholder={entry.activeToOld}
											noClockButton
											clearable
											disabled={rowSubmitted}
										/>
									</TableListCell>

									<TableListCell width="120px">
										<Tooltip
											zIndex={600}
											text={phrases.MODAL_BATCH_STEP_REVERT_TOOLTIP}
											visible={!rowSubmitted}
											renderData={(ref, onMouseEnter, onMouseLeave) => (
												<Button
													type="warning"
													size="tiny"
													disabled={rowSubmitted || !entry.rowEdited}
													onClick={() => revertBatchListItem(entry)}
													refProp={ref}
													onMouseEnter={onMouseEnter}
													onMouseLeave={onMouseLeave}>
													<Icon name="refresh" />
												</Button>
											)}
										/>
										{batchListData.length > 1 && (
											<Button
												type="negative"
												size="tiny"
												disabled={rowSubmitted}
												onClick={() => this._onRemoveEntry(entry.identifier)}>
												<Icon name="delete" />
											</Button>
										)}
										<Button
											type="success"
											size="tiny"
											disabled={rowSubmitted || batchListItemLoadingId}
											onClick={() => submitRow(entry)}>
											{batchListItemLoadingId === entry.identifier ? (
												<ButtonLoader loading />
											) : (
												<Icon size="micro" name="check" />
											)}
										</Button>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
			</>
		);
	}
}

PosConfigurationVersionsBatchStepListDuplicateAsNew.propTypes = {
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	posConfigurationReduxKey: PropTypes.string,
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
	revertBatchListItem: PropTypes.func,
	submitRow: PropTypes.func,
	appLayoutReduxKey: PropTypes.string,
	posLayoutReduxKey: PropTypes.string,
	loading: PropTypes.bool,
	batchListItemLoadingId: PropTypes.number,
};

export default PosConfigurationVersionsBatchStepListDuplicateAsNew;
