/* eslint-disable react/display-name */
'use strict';
import React from 'react';
import { connect } from 'react-redux';

const connectWithStore = (WrappedComponent, ...args) =>
	connect(...args)(WrappedComponent);

export default connectWithStore;

// export default connectWithStore;
/* eslint-enable react/display-name */
