'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Button,
	Icon,
	Tooltip,
	InputCollectionSelect,
	FileUpload,
	Input,
} from 'dumb';

import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';

// lodash
import _get from 'lodash/get';

// phrases
import phrases from './../../../filterProductVariants.phrases';

class FilterProductVariantsBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e.target ? e.target.value : e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: value },
		});
	}

	render() {
		const { batchList, addBatchListItemRow } = this.props;

		return (
			<>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT}</th>

							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((entry, i) => {
							const editButtonDisabled = batchList.length === 1;

							return (
								<TableListRow key={entry.identifier}>
									<TableListCell>{i + 1}</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id="productVariant"
											placeholder={
												phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT_PLACEHOLDER
											}
											value={entry.productVariant}
											handleChange={(key, value) =>
												this._editListEntry(value, entry, 'productVariant')
											}
											clearable={false}
											cache
											apiPath="/product/product_variants"
											params={{
												limit: 30,
											}}
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											inputFilterFormat={(input) => `:name=like='%${input}%'`}
											tableTitle="Product variants"
											tableReduxKey="filterProductVariants-/pos/product_variants"
											tableColumns={[
												{
													Header: 'Name',
													id: 'name',
													accessor: 'name',
													filterPath: ':name',
												},
												{
													Header: 'Product',
													id: 'prod',
													accessor: (d) => _get(d, 'product.name', ''),
													filterPath: ':product.name',
												},
												{
													Header: 'Category',
													id: 'category',
													accessor: (d) =>
														_get(d, 'product.product_category.name', ''),
													filterPath: ':product.product_category.name',
												},
												{
													Header: 'Size',
													id: 'size',
													accessor: (d) => _get(d, 'size.name', ''),
													filterPath: ':size.name',
												},
												{
													Header: 'Bundle',
													id: 'bundle',
													accessor: (d) => _get(d, 'bundle.name', ''),
													filterPath: ':bundle.name',
												},
												{
													Header: 'Fuel',
													id: 'fuel',
													accessor: (d) => _get(d, 'fuel', ''),
													filterPath: ':fuel',
												},
												{
													Header: 'Points',
													id: 'points',
													accessor: (d) => _get(d, 'points.points', ''),
													filterPath: ':points.points',
													filterable: false,
													sortable: false,
												},
												{
													Header: 'Image',
													id: 'image',
													accessor: (d) => _get(d, 'image.filename', ''),
													filterPath: ':image.filename',
													filterable: false,
													sortable: false,
													Cell: (d) => {
														const url = _get(d, 'original.image.url', false);
														let file = null;
														if (url) {
															file = {
																url,
																filename: _get(
																	d,
																	'original.image.filename',
																	''
																),
															};
														}
														return (
															<FileUpload file={file} thumbnail disabled />
														);
													},
												},
												{
													Header: 'Asset Collection',
													id: 'asset_collection',
													accessor: (d) =>
														_get(d, 'asset_collection.name', null),
													filterPath: ':asset_collection.name',
												},
												{
													Header: 'Legacy',
													id: 'legacy',
													accessor: (d) => _get(d, 'legacy', ''),
													filterPath: ':legacy',
													filterable: false,
													Cell: (d) => {
														return (
															<Input
																id="legacy"
																type="checkbox"
																checked={_get(d, 'original.legacy', false)}
																disabled
															/>
														);
													},
												},
												{
													Header: 'Available for delivery',
													id: 'availableForDelivery',
													accessor: 'available_for_delivery',
													filterPath: ':available_for_delivery',
													filterable: false,
													Cell: (d) => {
														return (
															<Input
																id="availableForDelivery"
																type="checkbox"
																checked={_get(
																	d,
																	'original.available_for_delivery',
																	false
																)}
																disabled
															/>
														);
													},
												},
											]}
										/>
									</TableListCell>

									<TableListCell>
										<Button
											type="negative"
											disabled={editButtonDisabled}
											size="tiny"
											onClick={() => this._onRemoveEntry(entry.identifier)}>
											<span className="icon icon--delete" />
										</Button>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
				<div className="filter-product-variants__add-new-row">
					<Tooltip
						text={phrases.MODAL_BATCH_STEP_LIST_TOOLTIP}
						zIndex="600"
						renderData={(ref, onMouseEnter, onMouseLeave) => (
							<Button
								type="secondary"
								onClick={addBatchListItemRow}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
								refProp={ref}>
								<Icon name="add" />
							</Button>
						)}
					/>
				</div>
			</>
		);
	}
}

FilterProductVariantsBatchStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
};

export default FilterProductVariantsBatchStepList;
