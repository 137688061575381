'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, Icon, Tag, ButtonLoader } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

import _get from 'lodash/get';
import './duplicateLayoutListSummary.css';

class DuplicateLayoutListSummary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: {},
		};

		this._setLoader = this._setLoader.bind(this);
	}

	_onRemoveEntry(tileLayout) {
		this.props.removeDuplicateLayoutListItem(tileLayout.id);
	}

	_setLoader(id, bool) {
		this.setState(() => ({
			loading: {
				...this.state.loading,
				[id]: bool,
			},
		}));
	}

	_onConfirmEntry(tileLayout) {
		this._setLoader(tileLayout.id, true);

		this.props
			.duplicateTileLayout(tileLayout)
			.then(() => {
				this._setLoader(tileLayout.id, false);
			})
			.catch(() => {
				this._setLoader(tileLayout.id, false);
			});
	}

	_editListEntry(e, id, type) {
		const { tileLayouts } = this.props;
		const value = e.target ? e.target.value : e;
		const row = tileLayouts.find((tileLayout) => tileLayout.id === id);

		this.props.editDuplicateLayoutListItem({
			...row,
			...{ [type]: value },
		});
	}

	render() {
		const { tileLayouts } = this.props;

		return (
			<>
				<h3 className="modal-batch__subheader">
					Showing {tileLayouts.length} rows
				</h3>
				<TableList>
					<thead>
						<tr>
							<th>#</th>
							<th>Name</th>
							<th>Description</th>
							<th>Type</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{tileLayouts.map((tileLayout, i) => {
							const duplicated = tileLayout.value?.duplicated;

							return (
								<TableListRow
									key={tileLayout.value.id}
									type={duplicated && 'light'}
								>
									<TableListCell>
										<div className="modal-batch__table-row-cell">
											{duplicated && (
												<Tag type="bullet" color="8AD09E" noBottomMargin />
											)}
											{i + 1}
										</div>
									</TableListCell>
									<TableListCell>
										<Input
											id={`${_get(tileLayout.value, 'name', '')}-${i}`}
											value={_get(tileLayout.value, 'name', '')}
											placeholder="Name"
											disabled={duplicated}
											onChange={(event) =>
												this._editListEntry(event, tileLayout.value.id, 'name')
											}
										/>
									</TableListCell>
									<TableListCell>
										<Input
											id={`${_get(tileLayout.value, 'description', '')}-${i}`}
											value={_get(tileLayout.value, 'description', '')}
											placeholder="Description"
											disabled={duplicated}
											onChange={(event) =>
												this._editListEntry(
													event,
													tileLayout.value.id,
													'description'
												)
											}
										/>
									</TableListCell>
									<TableListCell>
										{_get(tileLayout.value, 'type', '')}
									</TableListCell>
									<TableListCell>
										<div className="modal-batch__button">
											<Button
												type="success"
												size="tiny"
												disabled={
													duplicated || this.state.loading[tileLayout.value.id]
												}
												onClick={() => this._onConfirmEntry(tileLayout.value)}
											>
												{this.state.loading[tileLayout.value.id] ? (
													<ButtonLoader
														loading={this.state.loading[tileLayout.value.id]}
														theme="light"
													/>
												) : (
													<Icon name="check" />
												)}
											</Button>
											<Button
												type="negative"
												size="tiny"
												disabled={duplicated}
												onClick={() => this._onRemoveEntry(tileLayout.value)}
											>
												<Icon name="delete" />
											</Button>
										</div>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
			</>
		);
	}
}

DuplicateLayoutListSummary.propTypes = {
	tileLayouts: PropTypes.array,
	editDuplicateLayoutListItem: PropTypes.func,
	removeDuplicateLayoutListItem: PropTypes.func,
	duplicateTileLayout: PropTypes.func,
};

DuplicateLayoutListSummary.defaultProps = {
	tileLayouts: [],
};

export default DuplicateLayoutListSummary;
