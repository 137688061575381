'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// rdw actions
import { store } from 'appState';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';

// components
import { ModalStepper, Overlay } from 'dumb';

// different batch views
import IngredientVariationPricingStepForm from './components/ingredientVariationPricingStepForm';
import IngredientVariationPricingStepList from './components/ingredientVariationPricingStepList';

// tables for batch view
import PosConfigurationVersionTable from './../../../../../pos/components/posConfigurationVersions/posConfigurationVersions.component';

// phrases
import phrases from './../../posConfigurationIngredientPricingVariation.phrases';
import constants from 'services/constants';

// loooodash
import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';
import _uniqBy from 'lodash/uniqBy';
import _isEmpty from 'lodash/isEmpty';

class IngredientVariationPricingModalBatch extends Component {
	constructor(props) {
		super(props);

		this.posConfigurationVersionReduxKey =
			'ingredient-variation-pricing/pos/pos_configuration_versions';

		this.today = moment.utc().format(constants.shortDate);

		this.state = {
			showOverlay: false,
			overlayType: null,
			activeFilterOn: true,
			activeFilter: `:active.to=ge='${this.today}'`,
			allowOverflow: true,
		};

		this.handleToggleOverlay = this.handleToggleOverlay.bind(this);
		this.onOverlayOpen = this.onOverlayOpen.bind(this);
		this._getIsBatchStepFormValid = this._getIsBatchStepFormValid.bind(this);

		this.modalRef = React.createRef();
	}

	handleToggleOverlay(type) {
		const { listPosConfigurationVersions, updateBatchForm } = this.props;

		if (this.state.showOverlay) {
			const selectedRowsInList = _get(
				listPosConfigurationVersions,
				'ui.selectedRows',
				[]
			);

			updateBatchForm({
				posConfigurationVersion: selectedRowsInList.map((entry) => {
					return { label: entry.pos_configuration.name, value: entry };
				}),
			});
		}

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
			overlayType: prevState.overlayType ? '' : type,
		}));
	}

	onOverlayOpen() {
		const { batchFormData } = this.props;
		const { overlayType } = this.state;

		const dataToSetColumnsSelected = batchFormData.posConfigurationVersion;

		if (_isEmpty(dataToSetColumnsSelected)) return;
		// set already selected data in rdw store as clicked
		dataToSetColumnsSelected.map((entry) => {
			store.dispatch(
				actions.addRowToSelection({
					reduxKey: overlayType,
					data: entry.value,
				})
			);
		});
	}

	_onSubmitForm() {
		const { batchFormData, addBatchList } = this.props;

		const cleanVersions = _uniqBy(
			batchFormData.posConfigurationVersion,
			'value.id'
		);

		const listArray = cleanVersions.map((entry) => {
			return {
				identifier: _uniqueId('row-'), // used to identify what to edit on step#2
				posConfigurationVersion: entry.value,
				ingredientVariant: batchFormData.ingredientVariant,
				sortOrder: batchFormData.sortOrder,
			};
		});

		addBatchList(listArray);

		this._goToStep(2);
	}

	_goToStep(step) {
		this.setState(() => ({ allowOverflow: step === 1 }));

		this.modalRef.current.goToStep(step);
	}

	_batchSubmit() {
		const {
			batchList,
			handleClose,
			addPosConfigurationIngredientVariations,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchList.map((entry) => {
			return {
				pos_configuration_version: _get(
					entry,
					'posConfigurationVersion.id',
					null
				),
				name: entry.ingredientVariant || '',
				sort_order: entry.sortOrder,
			};
		});

		addPosConfigurationIngredientVariations({ batch: payload })
			.then(() => {
				handleClose();
				this.setState(() => ({
					loadingModal: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	_getIsBatchStepFormValid() {
		const { batchFormData } = this.props;

		return !_isEmpty(batchFormData.posConfigurationVersion);
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchList,
			editBatchListItem,
			removeBatchListItem,
		} = this.props;

		const steps = [
			{
				component: (
					<IngredientVariationPricingStepForm
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.handleToggleOverlay}
						posConfigurationVersionReduxKey={
							this.posConfigurationVersionReduxKey
						}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE,
				isValid: this._getIsBatchStepFormValid(),
				onNext: () => this._onSubmitForm(),
				loading: false,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				noScroll: false,
			},
			{
				component: (
					<IngredientVariationPricingStepList
						batchList={batchList}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						className="modal-batch"
						ref={this.modalRef}
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={this.state.showOverlay ? () => {} : handleClose}
						allowOverflow={this.state.allowOverflow}
					/>
				)}

				<Overlay
					zIndex={551}
					height={850}
					list
					visible={this.state.showOverlay}
					onClose={() => {
						this.handleToggleOverlay();
					}}>
					<PosConfigurationVersionTable
						batchSelection
						defaultPageSize={20}
						onInitialization={() => this.onOverlayOpen()}
						reduxKey={this.state.overlayType}
						style={{
							maxHeight: '720px',
						}}
					/>
				</Overlay>
			</>
		);
	}
}

IngredientVariationPricingModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	listPosConfigurationVersions: PropTypes.object,
	addBatchList: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addPosConfigurationIngredientVariations: PropTypes.func,
};

export default IngredientVariationPricingModalBatch;
