'use strict';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import StaffHeader from './staffHeader/staffHeader';
import StaffBody from './staffBody/staffBody';
import TrainingsAndExams from './trainingsAndExams/trainingsAndExams';
import { Button, ButtonLoader, Icon } from 'dumb';

// layout components from Mui - so we don't have to write css
import { Box } from '@mui/material';

// utils
import { isMobile } from 'detectMobile.vanilla';

class StaffDetails extends PureComponent {
	constructor(props) {
		super(props);

		this.renderEditButton = this.renderEditButton.bind(this);

		this.contentPadding = isMobile() ? 1 : 2;
	}

	renderEditButton() {
		const {
			savingUserDetails,
			editMode,
			toggleEditMode,
			saveEditedUserDetails,
			userUpdateStatus,
			phrases,
			userData,
		} = this.props;

		if (!userData.id) return null;

		if (savingUserDetails || userUpdateStatus !== '') {
			return (
				<Button size="tiny" type="inverted" shadow>
					<ButtonLoader loading={savingUserDetails} theme="dark" />
				</Button>
			);
		}

		if (editMode) {
			return (
				<Button
					size="tiny"
					shadow
					type="success"
					onClick={saveEditedUserDetails}
					dataCy="staff-details-modal-save-personal-data"
				>
					{phrases.SAVE_CHANGES}
				</Button>
			);
		}

		return (
			<Button
				size="tiny"
				shadow
				type="secondary"
				onClick={toggleEditMode}
				dataCy="staff-details-modal-edit-personal-data"
			>
				<Icon name="edit" />
				Edit
			</Button>
		);
	}

	render() {
		const {
			details,
			editMode,
			badge,
			children,
			isTrainingVisible,
			savingUserDetails,
			userUpdateStatus,
			saveEditedUserDetails,
			toggleEditMode,
			toggleTrainingHistory,
			updateEmail,
			updatePhoneNumber,
			updatePinCode,
			phrases,
			headerComponent,
			optionsArray,
			marketId,
			setGradeReviewRowData,
			updateGradeReviewRowData,
			gradeReviewRowData,
			resetGradeReviewRowData,
			userData,
			seniority,
			viewingLoggedInUser,
		} = this.props;

		const person = details.person;

		return (
			<>
				{!marketId ? (
					<Box
						sx={{
							paddingTop: (theme) => theme.spacing(2),
							textAlign: 'center',
						}}
					>
						<h2>{phrases.NO_MONEYBALL_POSITION}</h2>
						<h2>{phrases.CONTACT_HR_FOR_DETAILS}</h2>
					</Box>
				) : (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							position: 'relative',
							height: '100%',
							fontSize: 14,
						}}
					>
						<StaffHeader
							person={person}
							userId={userData.id}
							userName={userData.name}
							badge={badge}
							toggleEditMode={toggleEditMode}
							editMode={editMode}
							saveEdit={saveEditedUserDetails}
							toggleTrainingHistory={toggleTrainingHistory}
							isTrainingVisible={isTrainingVisible}
							saving={savingUserDetails}
							userUpdateStatus={userUpdateStatus}
							phrases={phrases}
							headerComponent={headerComponent}
							renderEditButton={this.renderEditButton}
							contentPadding={this.contentPadding}
						/>

						<StaffBody
							person={person}
							userPhoneNumber={userData.phoneNumber}
							userEmail={userData.email}
							userPin={userData.pin}
							updateEmail={updateEmail}
							updatePhoneNumber={updatePhoneNumber}
							updatePinCode={updatePinCode}
							editMode={editMode}
							phrases={phrases}
							optionsArray={optionsArray}
							details={details}
							seniority={seniority}
							viewingLoggedInUser={viewingLoggedInUser}
							contentPadding={this.contentPadding}
						/>

						{userData.id &&
							React.Children.map(children, (child, i) => (
								<div key={i} className="staff-details__section">
									{child}
								</div>
							))}
					</Box>
				)}

				{isTrainingVisible ? (
					<TrainingsAndExams
						isTrainingVisible={isTrainingVisible}
						toggleTrainingHistory={toggleTrainingHistory}
						userId={userData.id}
						setGradeReviewRowData={setGradeReviewRowData}
						updateGradeReviewRowData={updateGradeReviewRowData}
						gradeReviewRowData={gradeReviewRowData}
						resetGradeReviewRowData={resetGradeReviewRowData}
					/>
				) : null}
			</>
		);
	}
}

StaffDetails.defaultProps = {
	visible: false,
	fetching: false,
	headerComponent: null,
};

StaffDetails.propTypes = {
	phrases: PropTypes.object.isRequired,
	details: PropTypes.object.isRequired,
	headerComponent: PropTypes.func,
	optionsArray: PropTypes.arrayOf(Object),
	children: PropTypes.object,
	savingUserDetails: PropTypes.bool,
	userUpdateStatus: PropTypes.string,
	saveEditedUserDetails: PropTypes.func,
	toggleEditMode: PropTypes.func,
	toggleTrainingHistory: PropTypes.func,
	marketId: PropTypes.number,
	editMode: PropTypes.bool,
	badge: PropTypes.string,
	isTrainingVisible: PropTypes.bool,
	updatePhoneNumber: PropTypes.func.isRequired,
	updateEmail: PropTypes.func.isRequired,
	updatePinCode: PropTypes.func.isRequired,
	setGradeReviewRowData: PropTypes.func,
	updateGradeReviewRowData: PropTypes.func,
	resetGradeReviewRowData: PropTypes.func,
	gradeReviewRowData: PropTypes.object,
	userData: PropTypes.object,
	seniority: PropTypes.array,
	viewingLoggedInUser: PropTypes.bool,
};

export default StaffDetails;
