import prop from 'dot-prop-immutable';

import {
	SET_FILE_LIST,
	SET_LOADING
} from './fileList.actions';

const defaultState = {
	fileLists: {},
	loading: {}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
		case SET_FILE_LIST:
			return prop.set(state, `fileLists.${payload.fileListName}`, payload.fileList);
		case SET_LOADING:
			return prop.set(state, `loading.${payload.fileListName}`, payload.loading);
		default:
			return state;
	}
}

export default reducer;