'use strict';

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import onClickOutside from 'react-onclickoutside';

import _uniqueId from 'lodash/uniqueId';
import _compact from 'lodash/compact';
import _isArray from 'lodash/isArray';

// components for type checking
import MenuSection from './../menuSection/menuSection';

class MenuWrapper extends Component {
	handleClickOutside() {
		this.props.onBlur();
	}

	/**
	 * @method decorateChildren
	 * @param {Array} children - array of inner menu elements
	 * @description return MenuItem with it's onClick function decorated
	 */
	decorateChildren(children) {
		children = _compact(children);

		return children.map((component) => {
			// if MenuSection do a recursive call for it's children
			if (component.type === MenuSection) {
				return (
					<MenuSection key={_uniqueId('menu-item-')} {...component.props}>
						{/* little trick to make sure we always pass an array to decorateChildren() */}
						{this.decorateChildren(
							_isArray(component.props.children) ? component.props.children : [component.props.children]
						)}
					</MenuSection>
				);
			}
			// if  we pass a Fragment component with MenuItems or MenuSections nested inside, decoreate those guys instead
			if (component.type === Fragment) {
				const param = _isArray(component.props.children) ? component.props.children : [component.props.children];

				return this.decorateChildren(param);
			}

			return (
				<component.type
					key={_uniqueId('menu-item-')}
					{...component.props}
					onClick={this.decorateOnClick(component.props.onClick, component.props.disabled)}
				/>
			);
		});
	}

	decorateOnClick(onClick, disabled = false) {
		if (disabled) return onClick;

		return () => {
			// first execute onClick that menuItem had
			onClick();

			// lastly close the menu
			this.props.onBlur();
		};
	}

	render() {
		const { id, className, forwardedRef, style, children } = this.props;

		return (
			<div id={id} className={className} ref={forwardedRef} style={style}>
				{children ? (
					// little trick to make sure we always pass an array to decorateChildren()
					this.decorateChildren(_isArray(children) ? children : [children])
				) : (
					<span>menu should go here</span>
				)}
			</div>
		);
	}
}

MenuWrapper.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	forwardedRef: PropTypes.func,
	style: PropTypes.object,
	onClick: PropTypes.func,
	onBlur: PropTypes.func,
	children: PropTypes.oneOf([PropTypes.array, PropTypes.object]),
};

export default onClickOutside(MenuWrapper);
