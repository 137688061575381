'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// rdw actions
import { store } from 'appState';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';

// components
import { ModalStepper, Overlay, Button } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';

// different batch views
import MarketShiftRulesBatchStepForm from './components/marketShiftRulesBatchStepForm';
import MarketShiftRulesBatchStepList from './components/marketShiftRulesBatchStepList';

// phrases/ enums
import phrases from './../../marketShiftRules.phrases';
import constants from 'services/constants';

// columns
import TableColumns from './marketShiftRules.columns';

// loash
import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';
import _uniqBy from 'lodash/uniqBy';

class MarketShiftRulesModalBatch extends Component {
	constructor(props) {
		super(props);

		this.marketReduxKey = 'market-shift-rules-batch-/product/product_variants';
		this.today = moment.utc().format(constants.shortDate);

		this.state = {
			showOverlay: false,
			overlayType: null,
			activeFilterMarketsOn: true,
			activeFilterMarkets: `:closed=ge='${this.today}'`,
		};

		this._getIsBatchStepFormValid = this._getIsBatchStepFormValid.bind(this);
		this.handleToggleOverlay = this.handleToggleOverlay.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.onOverlayOpen = this.onOverlayOpen.bind(this);
		this.resetSelectedRows = this.resetSelectedRows.bind(this);
		this.getTableFilters = this.getTableFilters.bind(this);
		this.handleToggleActiveFilter = this.handleToggleActiveFilter.bind(this);

		this.marketColumns = TableColumns.marketColumns;

		this.modalRef = React.createRef();
	}

	handleToggleOverlay(type) {
		const { listMarkets, updateBatchForm } = this.props;

		if (this.state.showOverlay) {
			const selectedRowsInList = _get(listMarkets, 'ui.selectedRows', []);

			updateBatchForm({
				market: selectedRowsInList.map((entry) => {
					return { label: entry.name, value: entry };
				}),
			});
		}

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
			overlayType: prevState.overlayType ? '' : type,
		}));
	}

	fetchData(state) {
		const { fetchMarkets } = this.props;

		return fetchMarkets(state);
	}

	onOverlayOpen() {
		const { batchFormData } = this.props;
		const { overlayType } = this.state;

		if (_isEmpty(batchFormData.market)) return;

		// set already selected data in rdw store as clicked
		batchFormData.market.map((entry) => {
			store.dispatch(
				actions.addRowToSelection({
					reduxKey: overlayType,
					data: entry.value,
				})
			);
		});
	}

	resetSelectedRows(reduxKey) {
		const { listMarkets } = this.props;

		listMarkets && store.dispatch(actions.resetSelection({ reduxKey }));
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_onSubmitForm() {
		const { batchFormData, addBatchList } = this.props;

		const cleanMarkets = _uniqBy(batchFormData.market, 'value.id');

		const listArray = cleanMarkets.reduce((acc, currentMarket) => {
			const values = batchFormData.rule.map((rule) => ({
				identifier: _uniqueId('row-'),
				market: currentMarket,
				rule,
			}));

			return [...acc, ...values];
		}, []);

		addBatchList(listArray);

		this._goToStep(2);
	}

	_batchSubmit() {
		const { batchListData, handleClose, batchAddData } = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchListData.map((entry) => {
			return {
				...(entry.market && {
					market: _get(entry, 'market.value.id', null),
				}),
				...(entry.rule && {
					type: _get(entry, 'rule.value', ''),
				}),
			};
		});

		batchAddData({ batch: payload })
			.then(() => {
				handleClose();

				this.setState(() => ({
					loadingModal: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	_getIsBatchStepFormValid() {
		const { batchFormData } = this.props;

		const weekdayEmpty = _isEmpty(batchFormData.weekday);

		return !weekdayEmpty;
	}

	handleToggleActiveFilter() {
		this.setState((prevState) => ({
			activeFilterMarketsOn: !prevState.activeFilterMarketsOn,
			activeFilterMarkets: prevState.activeFilterMarketsOn
				? ''
				: `:closed=ge='${this.today}'`,
		}));
	}

	getTableFilters() {
		const { activeFilterMarketsOn } = this.state;

		return (
			<Button
				size="tiny"
				type={activeFilterMarketsOn ? '' : 'inverted'}
				shadow
				onClick={this.handleToggleActiveFilter}>
				{phrases.MODAL_BATCH_STEP_ACTIVE}
			</Button>
		);
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchListData,
			editBatchListItem,
			removeBatchListItem,
		} = this.props;
		const { showOverlay } = this.state;

		const steps = [
			{
				component: (
					<MarketShiftRulesBatchStepForm
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						marketReduxKey={this.marketReduxKey}
						toggleOverlay={this.handleToggleOverlay}
						resetSelectedRows={this.resetSelectedRows}
						marketFilter={this.state.activeFilterMarkets}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE,
				isValid: !_isEmpty(batchFormData.market) && !_isEmpty(batchFormData.rule),
				onNext: () => this._onSubmitForm(),
				loading: false,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				noScroll: false,
				allowOverflow: true,
			},
			{
				component: (
					<MarketShiftRulesBatchStepList
						batchListData={batchListData}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
						loyaltyProductUrl={this.loyaltyProductUrl}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						ref={this.modalRef}
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={showOverlay ? () => {} : handleClose}
					/>
				)}

				<Overlay
					zIndex={551}
					height={850}
					list
					visible={showOverlay}
					onClose={() => {
						this.handleToggleOverlay();
					}}>
					<ReactDataWrapper
						title={phrases.MODAL_BATCH_STEP_FORM_TABLE_TITLE}
						columns={this.marketColumns}
						fetchData={this.fetchData}
						filterable
						disableFetchCsvButton
						enableMultiSelection
						batchSelection
						defaultPageSize={20}
						onInitialization={() => this.onOverlayOpen()}
						reduxKey={this.state.overlayType}
						manual
						actionsWidth={0}
						style={{
							maxHeight: '720px',
						}}
						extraFilters={this.state.activeFilterMarkets}
						customAreaComponents={this.getTableFilters()}
					/>
				</Overlay>
			</>
		);
	}
}

MarketShiftRulesModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	addBatchList: PropTypes.func,
	fetchMarkets: PropTypes.func,
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	batchAddData: PropTypes.func,
	listMarkets: PropTypes.object,
};

export default MarketShiftRulesModalBatch;
