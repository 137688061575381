import { get, post, remove, patch } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchSalaryComponents
 * @description Fetch all discounts
 * @param {object} state
 */
export function fetchSalaryComponents(state, salarGroupId) {
	let filter = state.filter;

	if (salarGroupId) {
		const preFilter = `:salary_group.id=='${salarGroupId}'`;
		filter = state.filter ? `${preFilter};${state.filter}` : preFilter;
	}

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter
	};

	return get(`/salary/salary_components`, params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editSalaryComponent
 * @description edit
 */
export function editSalaryComponent(compId, data) {
	return post(`/salary/salary_components/${compId}`, data)
		.then(res => {
			setFeedback('Salary component edited', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			// return Promise.reject;
		});
}

/**
 * @function editBatchSalaryComponent
 * @description edit
 */
export function editBatchSalaryComponent(data) {
	return patch(`/salary/salary_components`, data)
		.then(res => {
			setFeedback('Salary components edited', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function addSalaryComponent
 * @description add
 */
export function addSalaryComponent(data) {
	return post(`/salary/salary_components`, data)
		.then(res => {
			setFeedback('Salary component added', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteSalaryComponent
 * @description edit
 */
export function deleteSalaryComponent(compId) {
	return remove(`/salary/salary_components/${compId}`)
		.then(res => {
			setFeedback('Salary Component deleted', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function fetchSalaryComponents
 * @description Fetch all discounts
 * @param {object} state
 */
export function fetchSalaryGroups(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get(`/salary/salary_groups`, params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchSalaryComponents
 * @description Fetch all discounts
 * @param {object} state
 */
export function fetchGlobalSalaryComponents(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get(`/salary/global_salary_components`, params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}
