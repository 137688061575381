'use strict';
import React from 'react';
import ImageCell from 'imageCell';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';
const __endpoints = require('endpoints.service');

export default class TrainingModuleBadgesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'image',
				type: 'imageCell',
				canSort: false,
				canFilter: false,
				imageCell(row) {
					return <ImageCell id={row.id} image={row.image} objectKey={'image'} endpoint={'/hr/badges'} />;
				},
			},
			{
				name: 'miniature',
				type: 'imageCell',
				canSort: false,
				canFilter: false,
				imageCell(row) {
					return <ImageCell id={row.id} image={row.miniature} objectKey={'miniature'} endpoint={'/hr/badges'} />;
				},
			},
		];

		this.settings = {
			name: 'TrainingModulesBadges',
			canAdd: false,
			canDelete: true,
			translationPath: 'HR.BADGES',
			canManipulate: () => validAccess(undefined, ['Global BI Configuration']),
			endpoint: __endpoints.collection.hr.badges,
		};
		this.init();
		return this;
	}
}
