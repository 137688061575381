'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';

import _get from 'lodash/get';

import phrases from './productVariantIngredientNutritionValues.phrases';

import {
	setNutritionValue,
	updateNutritionValue,
	resetState,
} from './store/productVariantIngredientNutritionValues.actions';

import {
	fetchAllNutritionValues,
	deleteNutritionValues,
	editNutritionValues,
	addNutritionValues,
	editBatchNutritionValues,
} from './productVariantIngredientNutritionValues.service';
import { Input, InputCollectionSelect } from 'dumb';

class ProductVariantIngredientNutritionValues extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editMultiple = this.editMultiple.bind(this);

		this.state = {
			pages: null,
		};

		this.fetchData = this.fetchData.bind(this);

		this.columns = [
			{
				Header: 'Market',
				id: 'market',
				accessor: (d) => _get(d, 'market.name', ''),
				filterPath: ':market.name',
			},
			{
				Header: 'Nutrition Category',
				id: 'nutcat',
				accessor: (d) => _get(d, 'nutrition_category.name', ''),
				filterPath: ':nutrition_category.name',
			},
			{
				Header: 'Value',
				id: 'val',
				accessor: (d) => _get(d, 'value', ''),
				filterPath: ':value',
			},
		];
	}

	addEntry() {
		const { defaultNutritionValues, ingredient } = this.props;

		const payload = {
			value: defaultNutritionValues.value,
			ingredient: ingredient && ingredient.id,
			nutrition_category: _get(defaultNutritionValues, 'nutrition_category.id', ''),
			market: _get(defaultNutritionValues, 'market.id', ''),
		};

		return addNutritionValues(payload);
	}

	editEntry() {
		const { defaultNutritionValues } = this.props;

		const payload = {
			value: defaultNutritionValues.value,
			nutrition_category: _get(defaultNutritionValues, 'nutrition_category.id', ''),
			id: defaultNutritionValues.id,
		};

		return editNutritionValues(defaultNutritionValues.id, payload);
	}

	editMultiple(selectedRows) {
		const { defaultNutritionValues } = this.props;

		const list = selectedRows.map((row) => {
			return {
				id: row.id,
				...defaultNutritionValues,
			};
		});

		const payload = {
			batch: list,
		};

		return editBatchNutritionValues(payload);
	}

	deleteEntry(id) {
		return deleteNutritionValues(id);
	}

	setInitialEditValues(data) {
		this.props.setNutritionValue(data);
	}

	getEditableCellsEdit(e) {
		const { defaultNutritionValues } = this.props;

		return [
			{
				header: 'Market',
				value: <span>{_get(defaultNutritionValues, 'market.name', '')}</span>,
			},
			{
				header: 'Ingredient',
				value: <span>{_get(defaultNutritionValues, 'ingredient.name', '')}</span>,
			},
			{
				header: 'Nutrition Category',
				value: <span>{_get(defaultNutritionValues, 'ingredient.name', '')}</span>,
			},
			{
				header: 'Value',
				value: (
					<Input
						id="value"
						placeholder="value"
						value={_get(defaultNutritionValues, 'value', '')}
						onChange={(event) => this.editStoreEntry(event, 'value')}
					/>
				),
			},
		];
	}

	getEditableCells() {
		const { defaultNutritionValues, ingredient } = this.props;

		return [
			{
				header: 'Market',
				value: (
					<InputCollectionSelect
						id="market"
						placeholder="select market"
						value={
							_get(defaultNutritionValues, 'market.id', false)
								? {
										value: _get(defaultNutritionValues, 'market.id', ''),
										label: `${_get(defaultNutritionValues, 'market.name', '')}`,
								  }
								: null
						}
						handleChange={(key, value) =>
							this.editStoreEntry(
								{
									id: value ? value.value : '',
									name: value ? value.label : '',
								},
								'market'
							)
						}
						required
						clearable={false}
						cache
						apiPath="/organization/markets"
						params={{
							limit: 300,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Ingredient',
				value: <span>{ingredient && ingredient.name}</span>,
			},
			{
				header: 'Nutrition Category',
				value: (
					<InputCollectionSelect
						id="nutrition_category"
						placeholder="select nutrition category"
						required
						value={
							_get(defaultNutritionValues, 'nutrition_category.id', false)
								? {
										value: _get(defaultNutritionValues, 'nutrition_category.id', ''),
										label: `${_get(defaultNutritionValues, 'nutrition_category.name', '')}`,
								  }
								: null
						}
						handleChange={(key, value) =>
							this.editStoreEntry(
								{
									id: value ? value.value : '',
									name: value ? value.label : '',
								},
								'nutrition_category'
							)
						}
						clearable={false}
						cache
						apiPath="/product/nutrition_categories"
						params={{
							limit: 300,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Value',
				value: (
					<Input
						id="value"
						placeholder="value"
						required
						value={_get(defaultNutritionValues, 'value', '')}
						onChange={(event) => this.editStoreEntry(event, 'value')}
					/>
				),
			},
		];
	}

	editStoreEntry(e, type) {
		const { defaultNutritionValues } = this.props;
		const value = e.target ? e.target.value : e;

		const updateObject = {
			value: defaultNutritionValues.value,
			nutrition_category: defaultNutritionValues.nutrition_category,
			market: defaultNutritionValues.market,
		};

		this.props.updateNutritionValue({
			...updateObject,
			...{ [type]: value },
		});
	}

	fetchData(state) {
		const { ingredient } = this.props;

		const filter = `:ingredient.id=='${ingredient.id}'`;

		return fetchAllNutritionValues(state, filter);
	}

	render() {
		const { ingredient } = this.props;

		return (
			<ReactDataWrapper
				accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.TABLE_TITLE}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={10}
				reduxKey={`product-productVariant-ingredient-pos/nutrition-values-${ingredient.id}`}
				manual
				editEntry={(e) => this.editEntry(e)}
				editMultiple={this.editMultiple}
				editableCells={this.getEditableCells()}
				editableCellsEdit={this.getEditableCellsEdit()}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetState}
				actionsWidth={60}
				deleteEntry={this.deleteEntry}
				createEntry={this.addEntry}
			/>
		);
	}
}

ProductVariantIngredientNutritionValues.propTypes = {
	defaultNutritionValues: PropTypes.object,
	ingredient: PropTypes.object,
	updateNutritionValue: PropTypes.func,
	setNutritionValue: PropTypes.func,
	resetState: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setNutritionValue,
			updateNutritionValue,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultNutritionValues: store.productProductVariantIngredientNutritionValues.data.defaultNutritionValues,
	};
};

export default connectWithStore(ProductVariantIngredientNutritionValues, mapStateToProps, mapDispatchToProps);
