'use strict';

// constants/enums
import constants from 'services/constants';
import enums from './../posConfigurationProductVariantsPricing.enums';
import moment from 'moment';

import {
	RESET_STATE,
	UPDATE_BATCH_FORM,
	ADD_BATCH_LIST,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	SET_PRODUCT_VARIANTS_PRICING,
	UPDATE_PRODUCT_VARIANTS_PRICING,
	SET_POS_CONFIGURATION_PRODUCT_VARIANT_PRICINGS_DATE_FILTER,
	SET_POS_CONFIGURATION_PRODUCT_VARIANT_PRICINGS_LEGACY_FILTER,
} from './posConfigurationProductVariantsPricing.actions';

const todayDate = moment.utc().format(constants.shortDate);
const posConfigurationProductVariantPricingsDateFilter = `:pos_configuration_version.active.from=le='${todayDate}';:pos_configuration_version.active.to=ge='${todayDate}'`;

const defaultState = {
	data: {
		productVariantPricing: {},
		batchFormData: {
			availableInApp: true,
			adjustmentType: {
				value: enums.NOMINAL,
				label: enums.NOMINAL,
			},
		},
		batchList: [],
	},
	posConfigurationProductVariantPricingsDateFilter,
	legacyFilter: ':pos_configuration_version.pos_configuration.legacy==false',
	legacyFilterOn: true,
};

function reducer(state = defaultState, action) {
	const { payload, type } = action;

	switch (type) {
		case UPDATE_BATCH_FORM: {
			return {
				...state,
				data: {
					...state.data,
					batchFormData: {
						...state.data.batchFormData,
						...payload,
					},
				},
			};
		}

		case ADD_BATCH_LIST: {
			return {
				...state,
				data: {
					...state.data,
					batchList: payload,
				},
			};
		}

		case EDIT_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchList: state.data.batchList.map((entry) => {
						if (entry.identifier === payload.identifier) return payload;
						return entry;
					}),
				},
			};
		}

		case REMOVE_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchList: state.data.batchList.filter(
						(entry) => entry.identifier !== payload
					),
				},
			};
		}

		case SET_PRODUCT_VARIANTS_PRICING: {
			return {
				...state,
				data: {
					...state.data,
					productVariantPricing: payload,
				},
			};
		}

		case UPDATE_PRODUCT_VARIANTS_PRICING: {
			return {
				...state,
				data: {
					...state.data,
					productVariantPricing: {
						...state.data.productVariantPricing,
						...payload,
					},
				},
			};
		}
		case SET_POS_CONFIGURATION_PRODUCT_VARIANT_PRICINGS_DATE_FILTER: {
			return {
				...state,
				posConfigurationProductVariantPricingsDateFilter: payload,
			};
		}

		case SET_POS_CONFIGURATION_PRODUCT_VARIANT_PRICINGS_LEGACY_FILTER: {
			return {
				...state,
				legacyFilter: payload,
			};
		}

		case RESET_STATE: {
			return {
				...state,
				data: defaultState.data,
			};
		}

		default:
			// never return default state in default case, Filip!!!
			return state;
	}
}

export default reducer;
