'use strict';

export const SET_LIST_FILTER = 'FILTER_COLUMNS_DATA/SET_LIST_FILTER';
export const SET_LIST_SORT = 'FILTER_COLUMNS_DATA/SET_LIST_SORT';
export const SET_CUSTOM_COLUMN_FILTER =
	'FILTER_COLUMNS_DATA/SET_CUSTOM_COLUMN_FILTER';
export const CLEAR_LIST_FILTERS_SORTING =
	'FILTER_COLUMNS_DATA/CLEAR_LIST_FILTERS_SORTING';

export function setListFilter(payload) {
	return {
		type: SET_LIST_FILTER,
		payload
	};
}

export function setCustomColumnFilter(payload) {
	return {
		type: SET_CUSTOM_COLUMN_FILTER,
		payload
	};
}

export function setListSorting(payload) {
	return {
		type: SET_LIST_SORT,
		payload
	};
}

export function clearListFiltersSorting(payload) {
	return {
		type: CLEAR_LIST_FILTERS_SORTING,
		payload
	};
}
