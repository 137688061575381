'use strict';

var headers = {
	USERS: {
		NAME: 'Name',
		EMAIL: 'Email',
		PASSWORD: 'Existing password',
		NEW_PASSWORD: 'New Password'
	},
	PERSONS: {
		AVATAR: 'Avatar',
		FIRST_NAME: 'First name',
		MIDDLE_NAME: 'Middle name',
		LAST_NAME: 'Last name',
		PERSON_EMAIL_ADDRESS: 'E-mail',
		PERSON_PHONE_NUMBER: 'Phone number',
		PERSON_ADDRESSES: 'Addresses',
		BIRTH_DATE: 'Birthday',
		ACTIVE: 'Active'
	},
	PERSON_ADDRESSES: {
		ADDRESS: 'Address',
		COUNTRY: 'Country'
	},
	PERSON_BANK_ACCOUNTS: {
		REGISTRATION_NUMBER: 'Registration number',
		ACCOUNT_NUMBER: 'Account number',
		BANK: 'Bank'
	},
	PERSON_EMAIL_ADDRESSES: {
		EMAIL_ADDRESS: 'Email address'
	},
	PERSON_PERSONAL_INFORMATION: {
		SOCIAL_SECURITY_NUMBER: 'Social security number'
	},
	PERSON_PHONE_NUMBERS: {
		PHONE_NUMBER: 'Phone number'
	}
};

var titles = {
	USERS: 'My profile',
	EMPLOYMENTS: 'Employments',
	PERSON_GROUPS: 'Groups',
	PERSON_GROUP_MEMBERS: 'Group members',
	PERSONS: 'People',
	PERSON_ADDRESSES: 'Addresses',
	PERSON_BANK_ACCOUNTS: 'Bank accounts',
	PERSON_EMAIL_ADDRESS: 'E-mail address',
	PERSON_PERSONAL_INFORMATION: 'Personal information',
	PERSON_PHONE_NUMBER: 'Phone number'
};

module.exports = {
	headers: headers,
	titles: titles
};