'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { Input, Tabs } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';

import WaitingTimeGridEntries from './components/waitingTimeGridEntries/waitingTimeGridEntries.component';
import WorkplaceWaitingTimeGrids from './components/workplaceWaitingTimeGrids/workplaceWaitingTimeGrids.component';

import {
	fetchWaitingTimeGrids,
	addWaitingTimeGrid,
	deleteWaitingTimeGrid,
	editWaitingTimeGrid,
} from './waitingTimeGrids.service';

import {
	setWaitingTimeGridModal,
	updateWaitingTimeGrid,
	setWaitingTimeGridEntryModal,
	updateWaitingTimeGridEntry,
	resetWaitingTimeGridModal,
	resetWaitingTimeGridEntryModal,
} from './store/waitingTimeGrids.actions';

// constants/phrases/tools
import phrases from './waitingTimeGrids.phrases';
import _get from 'lodash/get';

class WaitingTimeGrids extends Component {
	constructor(props) {
		super(props);

		this.fetchData = this.fetchData.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.getEditableCells = this.getEditableCells.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);

		this.reduxKey = '/administration/waiting_time_grids';

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
		];
	}

	deleteEntry(id) {
		return deleteWaitingTimeGrid(id);
	}

	editEntry() {
		const { waitingTimeGrid } = this.props;

		const payload = {
			id: waitingTimeGrid.id,
			name: waitingTimeGrid.name,
		};

		return editWaitingTimeGrid(payload);
	}

	addEntry() {
		const { waitingTimeGrid } = this.props;

		const payload = {
			name: waitingTimeGrid.name,
		};

		return addWaitingTimeGrid(payload);
	}

	setInitialEditValues(data) {
		const { setWaitingTimeGridModal } = this.props;

		const payload = {
			id: data.id,
			name: data.name,
		};

		setWaitingTimeGridModal(payload);
	}

	getEditableCells() {
		const { waitingTimeGrid } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Name"
						value={waitingTimeGrid?.name || null}
						onChange={(event) => this.editStoreEntry('name', event)}
					/>
				),
			},
		];
	}

	editStoreEntry(name, event) {
		const { updateWaitingTimeGrid } = this.props;

		const value = event.target.value;

		const payload = {
			[name]: value,
		};

		updateWaitingTimeGrid(payload);
	}

	fetchData(state) {
		return fetchWaitingTimeGrids(state);
	}

	render() {
		return (
			<ReactDataWrapper
				title={phrases.TABLE_TITLE}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={10}
				reduxKey={this.reduxKey}
				manual
				editableCells={this.getEditableCells()}
				actionsWidth={60}
				createEntry={this.addEntry}
				editEntry={this.editEntry}
				deleteEntry={this.deleteEntry}
				editMultiple={this.editMultiple}
				setInitialEditValues={this.setInitialEditValues}
				accessAreasAllowedToEdit={['Global Sales Configuration', 'Sales Configuration']}
				onModalClose={this.props.resetWaitingTimeGridModal}
				inRowButtons
				subcomponent={(row) => {
					const reduxKey = `${this.reduxKey}/${row.original.id}`;

					const tabContent = [
						{
							name: phrases.WORKPLACE_WAITING_TIME_GRIDS,
							component: (
								<WorkplaceWaitingTimeGrids waitingTimeGrid={row.original} />
							),
						},
						{
							name: phrases.WAITING_TIME_GRID_ENTRIES,
							component: (
								<WaitingTimeGridEntries
									waitingTimeGridId={row.original.id}
									reduxKey={reduxKey}
									updateWaitingTimeGridEntry={
										this.props.updateWaitingTimeGridEntry
									}
									waitingTimeGridEntry={this.props.waitingTimeGridEntry}
									setWaitingTimeGridEntryModal={
										this.props.setWaitingTimeGridEntryModal
									}
									resetWaitingTimeGridEntryModal={
										this.props.resetWaitingTimeGridEntryModal
									}
								/>
							),
						},
					];

					return (
						<SubTable>
							<Tabs tabContent={tabContent} />
						</SubTable>
					);
				}}
			/>
		);
	}
}

WaitingTimeGrids.propTypes = {
	setWaitingTimeGridModal: PropTypes.func,
	resetWaitingTimeGridEntryModal: PropTypes.func,
	resetWaitingTimeGridModal: PropTypes.func,
	updateWaitingTimeGrid: PropTypes.func,
	updateWaitingTimeGridEntry: PropTypes.func,
	setWaitingTimeGridEntryModal: PropTypes.func,
	waitingTimeGrid: PropTypes.object,
	waitingTimeGridEntry: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setWaitingTimeGridModal,
			updateWaitingTimeGrid,
			updateWaitingTimeGridEntry,
			setWaitingTimeGridEntryModal,
			resetWaitingTimeGridModal,
			resetWaitingTimeGridEntryModal,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		waitingTimeGrid:
			store.globalAdministrationWaitingTimeGrid.data.waitingTimeGrid,
		waitingTimeGridEntry:
			store.globalAdministrationWaitingTimeGrid.data.waitingTimeGridEntry,
	};
};

export default connectWithStore(
	WaitingTimeGrids,
	mapStateToProps,
	mapDispatchToProps
);
