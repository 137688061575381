import ListModel from 'common/components/list/listModel.service';

export default class OnboardingValuesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'onboarding_value_type',
				type: 'string',
				reference: {
					key: 'name',
				},
				width: '150',
			},
			{
				name: 'result',
				type: 'string',
				width: '100',
			},
			{
				name: 'existing_value',
				type: 'string',
				width: '100',
			},
			{
				name: 'new_value',
				type: 'string',
				canEdit: true,
			},
		];

		this.settings = {
			name: 'OnboardingValues',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			alwaysRefresh: true,
			translationPath: 'HR.ONBOARDING_VALUES',
		};

		this.init();
	}
}
