'use strict';

import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';

import CountriesList from './services/countriesList.service.js';

const countriesListReduxKey = '/administration/countries';
const countriesList = new CountriesList(countriesListReduxKey);
const countriesListProps = countriesList.getProps();

export function Countries() {
	useEffect(() => {
		countriesList.initView();
	}, []);

	return <ListComponent reduxKey={countriesListReduxKey} {...countriesListProps} />;
}
