'use strict';

import { store } from 'appState';

import phrases from 'report/report.phrases';
import { setMetadata, setTables, resetReport } from 'report/report.actions';
import { ReportResultSet } from 'report/resultSets';
import { set as setFeedback } from 'feedback.vanilla.service';

/**
 * Report
 * @param {string} name
 * @param {object} dataProvider
 * @param {object} hotbar
 */
class Report {
	constructor(name, dataProvider, hotbar = null) {
		// Set type
		this.type = 'report';

		// Name
		this.name = name;

		// Csv data providers
		this.csvDataProviders = [];
		this.reportActions = [];

		// Filter
		this.filter = null;

		this.feedbackTimout = 5000;

		// Parameters
		this.parameters = null;

		this.filterMethod = () => null;
		this.parameterMethod = () => null;

		// DataProvider
		this.dataProvider = dataProvider;

		// Hotbar
		this.hotbar = hotbar;

		// MailingListRecipientsDataProvider
		this.mailingListRecipientsDataProvider = null;

		// PrintViewToggleCallback
		this.printViewToggleCallback = () => {};

		// Email data
		this.emailData = null;
	}

	/// //////////////
	// Dispatchers //
	/// //////////////

	/**
	 * @function _dispatchResultSet
	 * @description Dispatches results to store
	 * @param {object} reportResultSet
	 * @private
	 */
	_dispatchResultSet(reportResultSet) {
		// Dispatch report
		store.dispatch(setMetadata(this.name, reportResultSet.getMetadata()));

		// Dispatch tables
		store.dispatch(setTables(this.name, reportResultSet.getTables()));
	}

	/// //////////
	// Setters //
	/// //////////

	/**
	 * @function setMetadata
	 * @memberOf Report
	 * @description Setter for metadata
	 */
	setMetadata(metadata) {
		this.metadata = metadata;
	}

	/**
	 * @function setComponents
	 * @memberOf Report
	 * @description Setter for components
	 */
	setComponents(components) {
		this.components = components;
	}

	/**
	 * @function setFilterMethod
	 * @memberOf Report
	 * @description Setter for filter method
	 */
	setFilterMethod(filterMethod) {
		this.filterMethod = filterMethod;
	}

	/**
	 * @function setParameterMethod
	 * @memberOf Report
	 * @description Setter for parameter method
	 */
	setParameterMethod(parameterMethod) {
		this.parameterMethod = parameterMethod;
	}

	/**
	 * @function setFeedbackTimeout
	 * @memberOf Report
	 * @description Setter for feedback timer. If api returns an error message, how long should it then be shown?
	 *
	 */
	setFeedbackTimeout(feedbackTimout) {
		this.feedbackTimout = feedbackTimout;
	}

	/**
	 * @function setParameters
	 * @memberOf Report
	 * @param parameters
	 */
	setParameters(parameters) {
		this.parameters = parameters;
	}

	/**
	 * @function setFilters
	 * @memberOf Report
	 * @param filter
	 */
	setFilter(filter) {
		this.filter = filter;
	}

	/**
	 * @function setPrintViewToggleCallback
	 * @memberOf Report
	 * @description Setter for printViewToggleCallback
	 */
	setPrintViewToggleCallback(printViewToggleCallback) {
		this.printViewToggleCallback = printViewToggleCallback;
	}

	/**
	 * @function setEmailData
	 * @memberOf Report
	 * @description Setter for emailData
	 */
	setEmailData(emailData) {
		this.emailData = emailData;
	}

	/// //////////
	// Getters //
	/// //////////

	/**
	 * @function getComponents
	 * @memberOf Report
	 * @description Getter for hotbar
	 */
	getComponents() {
		return this.components || null;
	}

	/**
	 * @function getHotbar
	 * @memberOf Report
	 * @description Getter for hotbar
	 */
	getHotbar() {
		return this.hotbar || null;
	}

	/**
	 * @function getPhrases
	 * @memberOf Report
	 * @description Getter for phrases
	 */
	getPhrases() {
		return phrases;
	}

	/**
	 * @function getCsvDataProviders
	 * @memberOf Report
	 * @description Getter for csvDataProviders
	 * @returns {Array}
	 */
	getCsvDataProviders() {
		return this.csvDataProviders;
	}

	/**
	 * @function getCsvDataProviders
	 * @memberOf Report
	 * @description Getter for csvDataProviders
	 * @returns {Array}
	 */
	getReportActions() {
		return this.reportActions;
	}

	/**
	 * @function getMaximumWidth
	 * @memberOf Report
	 * @description Gets maximum width for any component in report
	 */
	getMaximumWidth(tables) {
		return Math.max(
			...Object.keys(this.getComponents()).map(key => {
				const component = this.getComponents()[key];
				return component.type === 'table'
					? this.getComponents()[key].getWidth(tables)
					: 0;
			})
		);
	}

	/**
	 * @function getEmailData
	 * @memberOf Report
	 * @description Getter for emailData
	 */
	getEmailData() {
		return this.emailData;
	}

	/// /////////////////
	// Event handlers //
	/// /////////////////

	/**
	 * @function handleChange
	 * @memberOf Report
	 * @description Handler for when report should change
	 * @param {object} filter
	 * @param {object} parameters
	 */
	handleChange(filter, parameters, abortController) {
		this.setFilter(filter);
		this.setParameters(parameters);

		// Reset current report
		store.dispatch(resetReport(this.name));

		// Handle change in report context
		return (
			this.dataProvider
				.getResultSet(filter, parameters, abortController)

				// Dispatch report resultSet
				.then(resultSet =>
					this._dispatchResultSet(new ReportResultSet(resultSet))
				)

				// Error handling
				.catch(error => {
					if (
						error.message &&
						error.message === 'The user aborted a request.'
					) {
						console.info('aborted');
					} else {
						setFeedback(error.message, 0, this.feedbackTimout);
					}
				})
		);
	}

	/**
	 * @function handleHotbarChange
	 * @memberOf Report
	 * @description Handler for when hotbar changes
	 * @param {object} hotbar resultSet
	 */
	handleHotbarChange(hotbarResultSet) {
		// Get filter and parameters based on hotbar resultSet
		return (
			Promise.all([
				this.filterMethod(hotbarResultSet),
				this.parameterMethod(hotbarResultSet)
			])

				// Get resultSet from data provider
				.then(parameters => {
					return this.handleChange(parameters[0], parameters[1]);
				})
		);
	}

	/**
	 * @function handleCsvDownload
	 * @memberOf Report
	 * @description Handler for csv download
	 * @param {object} hotbar resultSet
	 */
	handleCsvDownload(csvDataProvider) {
		return csvDataProvider.downloadCsv(this.filter, this.parameters);
	}

	/**
	 * @function handlePrintViewToggleCallback
	 * @memberOf Report
	 * @description Handler running a callback when print view toggles
	 */
	handlePrintViewToggleCallback() {
		this.printViewToggleCallback();
	}

	/// ////////////
	// Utilities //
	/// ////////////

	/**
	 * @function addCsvDataProvider
	 * @memberOf Report
	 * @param csvDataProvider
	 */
	addCsvDataProvider(csvDataProvider) {
		this.csvDataProviders.push(csvDataProvider);
	}

	/**
	 * @function addReportAction
	 * @memberOf Report
	 * @param {object} Component
	 * @param {object} configuration
	 */
	addReportAction(Component, configuration) {
		this.reportActions.push({ Component, configuration });
	}

	/**
	 * @function addMailingListRecipientsDataProvider
	 * @memberOf Report
	 * @param {object} mailingListRecipientsDataProvider
	 */
	addMailingListRecipientsDataProvider(mailingListRecipientsDataProvider) {
		this.mailingListRecipientsDataProvider = mailingListRecipientsDataProvider;
	}
}

export default Report;
