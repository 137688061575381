export function formatSelectedPowerBiReports({ selectedRows }) {
	const formattedData = selectedRows.map((entry) => ({
		id: entry.id,
		name: entry.name,
		description: entry.description,
		sortOrder: entry.sort_order,
		powerBiId: entry.power_bi_id,
		...(entry.access_granting_area
			? {
					accessGrantingArea: {
						value: entry.access_granting_area,
						label: entry.access_granting_area.name,
					},
			  }
			: {
					accessGrantingArea: null,
			  }),
		type: {
			value: entry.type,
			label: entry.type.name,
		},
		...(entry.market
			? {
					market: {
						label: entry.market.name,
						value: entry.market,
					},
			  }
			: {
					market: null,
			  }),
	}));

	return formattedData;
}
