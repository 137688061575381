// 'use strict';

// module.exports =
// 	angular.module('jtj.structure.globalAdministration.tileSizes', [
// 		require('./services').name
// 	])
// 	.controller('TilePictureAssetsDetailsController', require('./tilePictureAssetsDetails.controller'))
// 	.controller('TileConfigurationsController', require('./tileConfigurations.controller'));


export { TileConfigurations } from './tileConfigurations.component';
export { TilePictureAssetsDetailsContainer as TilePictureAssetsDetails } from './tilePictureAssetsDetails.component';
