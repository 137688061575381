'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { Input, Button, Icon, Toggle } from 'dumb';
import Checkbox from 'inputs/checkbox';

import { ReactDataWrapper } from 'reactDataWrapper';
import { submitBatchValues } from 'reactDataWrapper/utilities/batchHelper';
import SubTable from 'reactDataWrapper/components/subTable';

import {
	fetchGlobalRawIngredients,
	addGlobalRawIngredients,
	deleteGlobalRawIngredients,
	editGlobalRawIngredient,
	editGlobalRawIngredients
} from './globalRawIngredients.service';

import {
	setRawIngredientCategoriesModal,
	updateRawIngredientCategories,
	resetState,
	updateBatchForm,
	resetBatch,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow
} from './store/globalRawIngredients.actions';

// components
import GlobalRawIngredientsModalBatch from './components/batch/globalRawIngredientsModalBatch';
import RawIngredients from './components/rawIngredients/rawIngredients';

// constants/phrases/tools
import phrases from './globalRawIngredients.phrases';
import _get from 'lodash/get';

class GlobalRawIngredients extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false
		};

		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.getEditableCells = this.getEditableCells.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this._toggleModal = this._toggleModal.bind(this);
		this.batchAdd = this.batchAdd.bind(this);
		this.fetchData = this.fetchData.bind(this);

		this.reduxKey = '/inventory/global_raw_ingredients';

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: d => _get(d, 'name', ''),
				filterPath: ':name'
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: d => _get(d, 'description', ''),
				filterPath: ':description'
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: d => _get(d, 'legacy', ''),
				filterPath: ':legacy',
				filterable: false,
				Cell: d => <Checkbox noBorder disabled checked={d.original.legacy} />
			}
		];
	}

	editEntry() {
		const { globalRawIngredient } = this.props;

		const payload = {
			id: globalRawIngredient.id,
			name: globalRawIngredient.name,
			description: globalRawIngredient.description,
			legacy: globalRawIngredient.legacy
		};

		return editGlobalRawIngredient(payload);
	}

	editMultiple(selectedRows) {
		const { globalRawIngredient } = this.props;

		const payload = {
			...(globalRawIngredient.name && {
				name: globalRawIngredient.name
			}),
			...(globalRawIngredient.description && {
				description: globalRawIngredient.description
			}),
			legacy: !!globalRawIngredient.legacy
		};

		const selectedRowsWithId = selectedRows.map(row => {
			return {
				id: row.id,
				...payload
			};
		});

		return editGlobalRawIngredients({
			batch: selectedRowsWithId
		});
	}

	deleteEntry(id) {
		return deleteGlobalRawIngredients(id);
	}

	setInitialEditValues(data) {
		const { setRawIngredientCategoriesModal } = this.props;

		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			legacy: data.legacy
		};

		setRawIngredientCategoriesModal(payload);
	}

	getEditableCells() {
		const { globalRawIngredient, rawIngredientCategory } = this.props;

		return [
			{
				header: 'Raw ingredient category',
				value: <span>{rawIngredientCategory.name}</span>
			},
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={globalRawIngredient.name || ''}
						onChange={e => this.editStoreEntry('name', e.target.value)}
					/>
				)
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={globalRawIngredient.description || ''}
						onChange={e => this.editStoreEntry('description', e.target.value)}
					/>
				)
			},
			{
				header: 'Legacy',
				value: (
					<Toggle
						id="legacy"
						onClick={e => this.editStoreEntry('legacy', e)}
						toggled={globalRawIngredient.legacy}
					/>
				)
			}
		];
	}

	editStoreEntry(name, value) {
		const { updateRawIngredientCategories } = this.props;

		const payload = {
			[name]: value
		};

		updateRawIngredientCategories(payload);
	}

	_toggleModal() {
		this.setState(prevState => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	batchAdd(payload) {
		const { rawIngredientCategory } = this.props;

		return addGlobalRawIngredients(payload)
			.then(res => {
				const reduxKey = `${this.reduxKey}-${rawIngredientCategory.id}`;

				submitBatchValues({ res, reduxKey });
				return res;
			})
			.catch(err => {
				throw err;
			});
	}

	fetchData(state) {
		const { rawIngredientCategory } = this.props;

		const filter = `:raw_ingredient_category.id=='${rawIngredientCategory.id}'`;

		return fetchGlobalRawIngredients(state, filter);
	}

	render() {
		const {
			batchList,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			updateBatchForm,
			addBatchListItemRow,
			rawIngredientCategory
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					accessAreasAllowedToEdit={['Global Sales Configuration', 'Sales Configuration']}
					reduxKey={`${this.reduxKey}-${rawIngredientCategory.id}`}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					deleteEntry={this.deleteEntry}
					editMultiple={this.editMultiple}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					actionRender={
						<>
							<Button
								type="inverted"
								label="Add"
								shadow
								onClick={() => this._toggleModal()}>
								<Icon name="add" />
							</Button>
						</>
					}
					subcomponent={row => (
						<SubTable>
							<RawIngredients globalRawIngredient={row.original} />
						</SubTable>
					)}
				/>

				<GlobalRawIngredientsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this._toggleModal}
					updateBatchForm={updateBatchForm}
					addBatchList={addBatchList}
					batchList={batchList}
					addGlobalRawIngredients={this.batchAdd}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					rawIngredientCategory={rawIngredientCategory}
				/>
			</>
		);
	}
}

GlobalRawIngredients.propTypes = {
	setRawIngredientCategoriesModal: PropTypes.func,
	resetState: PropTypes.func,
	updateRawIngredientCategories: PropTypes.func,
	globalRawIngredient: PropTypes.object,
	batchList: PropTypes.array,
	addBatchList: PropTypes.func,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	updateBatchForm: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	rawIngredientCategory: PropTypes.object
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			setRawIngredientCategoriesModal,
			updateRawIngredientCategories,
			resetState,
			updateBatchForm,
			resetBatch,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			addBatchListItemRow
		},
		dispatch
	);
};

const mapStateToProps = store => {
	return {
		globalRawIngredient:
			store.globalAdministrationGlobalRawIngredients.data.globalRawIngredient,
		batchList: store.globalAdministrationGlobalRawIngredients.data.batchList
	};
};

export default connectWithStore(
	GlobalRawIngredients,
	mapStateToProps,
	mapDispatchToProps
);
