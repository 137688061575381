import { store } from 'appState';

export const SET_MODAL_DATA = 'SHIFT_TRANSFER_DATA/SET_MODAL_DATA';
export const RESET_MODAL_DATA = 'SHIFT_TRANSFER_DATA/RESET_MODAL_DATA';
export const UPDATE_SHIFT_AND_PENDING_TRANSFER =
	'SHIFT_TRANSFER_DATA/UPDATE_SHIFT_AND_PENDING_TRANSFER';
export const UPDATE_SHIFT_AND_PENDING_SWAP =
	'SHIFT_TRANSFER_DATA/UPDATE_SHIFT_AND_PENDING_SWAP';
export const SET_SHOW_MODAL = 'SHIFT_TRANSFER_DATA/SET_SHOW_MODAL';
export const SET_SHOW_PENDING_TRANSFERS_MODAL =
	'SHIFT_TRANSFER_DATA/SET_SHOW_PENDING_TRANSFERS_MODAL';
export const SET_APPLICATION_DECISION =
	'SHIFT_TRANSFER_DATA/SET_APPLICATION_DECISION';
export const SET_SHOW_POLICIES_MODAL =
	'SHIFT_TRANSFER_DATA/SET_SHOW_POLICIES_MODAL';
export const SET_TRANSFER_DATA_TO_SUBMIT =
	'SHIFT_TRANSFER_DATA/SET_TRANSFER_DATA_TO_SUBMIT';
export const RESET_TRANSFER_DATA_TO_SUBMIT =
	'SHIFT_TRANSFER_DATA/RESET_TRANSFER_DATA_TO_SUBMIT';
export const SET_SHIFT_TRANSFER_POLICY =
	'SHIFT_TRANSFER_DATA/SET_SHIFT_TRANSFER_POLICY';
export const SET_PENDING_SHIFT_TRANSFERS =
	'SHIFT_TRANSFER_DATA/SET_PENDING_SHIFT_TRANSFERS';
export const SET_SHIFT_TRANSFERS = 'SHIFT_TRANSFER_DATA/SET_SHIFT_TRANSFERS';

export const setShiftTransfers = (payload) => ({
	type: SET_SHIFT_TRANSFERS,
	payload,
});

export const setModalData = (payload) => ({
	type: SET_MODAL_DATA,
	payload,
});

export const resetModalData = () => ({
	type: RESET_MODAL_DATA,
});

export const updateShiftAndPendingTransfer = (payload) => ({
	type: UPDATE_SHIFT_AND_PENDING_TRANSFER,
	payload,
});

export const updateShiftAndPendingSwap = (payload) => ({
	type: UPDATE_SHIFT_AND_PENDING_SWAP,
	payload,
});

export const setShowModal = (payload) => ({
	type: SET_SHOW_MODAL,
	payload,
});

export const setShiftTransferApplication = (payload) => ({
	type: SET_APPLICATION_DECISION,
	payload,
});

export const setShowPoliciesModal = (payload) => ({
	type: SET_SHOW_POLICIES_MODAL,
	payload,
});

export const setTransferDataToSubmit = (payload) => ({
	type: SET_TRANSFER_DATA_TO_SUBMIT,
	payload,
});

export const resetTransferDataToSubmit = (payload) => ({
	type: RESET_TRANSFER_DATA_TO_SUBMIT,
	payload,
});

export const setShiftTransferPolicy = (payload) => ({
	type: SET_SHIFT_TRANSFER_POLICY,
	payload,
});

export const setShowPendingTransfersModal = (payload) => ({
	type: SET_SHOW_PENDING_TRANSFERS_MODAL,
	payload,
});

export const setPendingShiftTransfers = (payload) => ({
	type: SET_PENDING_SHIFT_TRANSFERS,
	payload,
});

/**
 * @function dispatchSetShiftTransferModalData
 * @description set shiftTransfers for modal
 */
export const dispatchSetShiftTransferModalData = (payload) => {
	store.dispatch(setModalData(payload));
};

/**
 * @function dispatchSetPendingShiftTransfers
 * @description set pending shiftTransfers for modal
 */
export const dispatchSetPendingShiftTransfers = (payload) => {
	store.dispatch(setPendingShiftTransfers(payload));
};

/**
 * @function dispatchSetShiftTransfers
 * @description set shiftTransfers for modal
 */
export const dispatchSetShiftTransfers = (payload) => {
	store.dispatch(setShiftTransfers(payload));
};
