'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import cx from 'classnames';

import { Modal, Label } from 'dumb';
import { Tip } from 'dumb/tips/tip';

import './editTableCellModal.css';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';

class EditTableCellModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isSelectOpen: false,
		};

		/*
		For setting the state of the select, adding debouncer ensures that
		we set the state after we press esc to close the modal
		*/
		this.toggleSelectInput = _debounce(this.toggleSelectInput, 100, {
			trailing: true,
		}).bind(this);
		this._renderFullData = this._renderFullData.bind(this);
		// this._getTableHeaders = this._getTableHeaders.bind(this);
	}

	toggleSelectInput(touched) {
		this.setState(() => ({ isSelectOpen: touched }));
	}

	/**
	 * @function _renderFullData
	 * @description Renders the whole data set that is passed the data prop
	 */
	_renderFullData() {
		const { editableCells: data } = this.props;

		// append autoFocus property on the first element of the array
		if (_get(data, '[0].value', false)) {
			data[0].value = this._addExtraProp(data[0].value, {
				autoFocus: false,
			});
		}

		return (
			<div>
				{data.map((cellData, index) => {
					// add tabIndex property to every element
					cellData.value = this._addExtraProp(cellData.value, {
						tabIndex: 0,
					});

					cellData.value = this._addExtraProp(cellData.value, {
						label: cellData.header,
					});

					const passedComponentIsSelect =
						cellData?.value?.type?.name === 'InputCollectionSelect' ||
						cellData?.value?.type?.name === 'CollectionSelect' ||
						cellData?.value?.type?.name === 'Select';

					// if element is select, add onOpen and onClose to manage the state
					// this will also add it to other elements like image loader but it won't affect the functionality
					if (passedComponentIsSelect) {
						cellData.value = this._addExtraProp(cellData.value, {
							onOpen: () => {
								this.selectTouched(true);
							},
							onClose: () => {
								this.selectTouched(false);
							},
						});
					}

					const passedComponentIsNativeElement = !cellData?.value?.type?.name;

					return (
						<div
							className="react-table__modal-table__cell"
							key={cellData.value + index}
						>
							{passedComponentIsNativeElement && (
								<Label>{cellData.header}</Label>
							)}
							{cellData.value}
						</div>
					);
				})}
			</div>
		);
	}

	/**
	 * @function _addExtraProp
	 * @description takes the component and adds extra prop to it
	 * @param {React Component} Component - react component that we want to append props to
	 * @param {object} extraProps - extra props to append
	 */
	_addExtraProp(Component, extraProps) {
		return <Component.type {...Component.props} {...extraProps} />;
	}

	/**
	 * @function selectTouched
	 * @description fires when the select is opened and closed
	 * @param {*} touched - sets the state of the dropwodn to true or false
	 */
	selectTouched(touched) {
		this.toggleSelectInput(touched);
	}

	render() {
		const {
			modalVisible,
			handleClose,
			onConfirmClick,
			loading,
			modalZIndex,
			editModalTip,
			onModalCloseConfirm,
		} = this.props;

		return modalVisible ? (
			<Modal
				className="table-editor-modal"
				zIndex={modalZIndex}
				isOpen={modalVisible}
				onClose={handleClose}
				loading={loading}
				onConfirmClick={onConfirmClick}
				isSelectOpen={this.state.isSelectOpen}
				closeConfirm={onModalCloseConfirm}
				render={() => (
					<>
						{this._renderFullData()}
						{editModalTip && (
							<div className="react-table__modal-table__tip">
								<Tip content={editModalTip} />
							</div>
						)}
					</>
				)}
			/>
		) : null;
	}
}

EditTableCellModal.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	headers: PropTypes.array,
	editableCells: PropTypes.array,
	onConfirmClick: PropTypes.func,
	loading: PropTypes.bool,
	selectTouched: PropTypes.bool,
	modalZIndex: PropTypes.number,
	editModalTip: PropTypes.string,
	onModalCloseConfirm: PropTypes.bool,
};

EditTableCellModal.defaultProps = {
	modalVisible: false,
	headers: [],
	editableCells: [],
	loading: false,
};

export default EditTableCellModal;
