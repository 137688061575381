import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service';
var __endpoints = require('endpoints.service');

/**
 * @function updateUserActivationKey
 * @description used for setting and receting password
 */
export function updateUserActivationKey(payload) {
	return apiPostCall(`/authentication/user_activation_keys/${payload.id}`, payload)
		.then(res => {
			setFeedback('Password succesfully updated.', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function createUserActivationKey
 * @description used for resetting password.
 */
export function createUserActivationKey(payload) {
	return apiPostCall('/authentication/user_activation_keys', payload)
		.then(res => {
			setFeedback('Check your email.', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

function apiPostCall(path, payload) {
	return fetch(`${__endpoints.apiHost}${__endpoints.apiUri}${path}`, {
		method: 'POST',
		mode: 'cors',
		cache: 'no-cache',
		credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/json'
		},
		redirect: 'follow',
		referrer: 'no-referrer',
		body: JSON.stringify(payload)
	}).then(response => {
		if (response.status === 204) return response;
		else {
			return response.json().then(json => {
				return response.ok ? json : Promise.reject(json);
			});
		}
	}).catch(e => {
		throw e;
	});
}
