'use strict';

import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './workplaceWaitingTimeGrids.phrases';

/**
 * @function fetchWorkplaceWaitingTimeGrids
 * @description Fetch all pos configurations
 */
export function fetchWorkplaceWaitingTimeGrids(state, filter) {
	const newFilter = state.filter ? `${state.filter};${filter}` : filter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: newFilter,
	};

	return get(
		'/administration/workplaces_waiting_time_grids',
		params,
		null,
		state.headers
	)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchWorkplaces
 * @description Fetch all pos configurations
 */
export function fetchWorkplaces(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/administration/workplaces', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

export function addWorkplaceWaitingTimeGrid(data) {
	return post('/administration/workplaces_waiting_time_grids', data)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_WAITING_TIME_GRID_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editWorkplaceWaitingTimeGrid
 * @param {Object} payload - update object
 */
export function editWorkplaceWaitingTimeGrid(payload) {
	return post(
		`/administration/workplaces_waiting_time_grids/${payload.id}`,
		payload
	)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_WAITING_TIME_GRID_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editBatchWorkplaceWaitingTimeGrid
 * @param {Object} payload - update object
 */
export function editBatchWorkplaceWaitingTimeGrid(payload) {
	return patch('/administration/workplaces_waiting_time_grids', payload)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_WAITING_TIME_GRIDS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteWorkplaceWaitingTimeGrid
 * @param {Number} id - id of pos config version to delete
 */
export function deleteWorkplaceWaitingTimeGrid(id) {
	return remove(`/administration/workplaces_waiting_time_grids/${id}`)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_WAITING_TIME_GRIDS_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
