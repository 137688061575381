'use strict';

var headers = {
	ACCOUNT_SCHEDULES: {
		NAME: 'Name'
	},
	GLOBAL_ACCOUNTS: {
		NAME: 'Name',
		TYPE: 'Type'
	},
	COMPANY_ACCOUNT_SCHEDULES: {
		ACCOUNT_SCHEDULE: 'Account schedule'
	},
	ACCOUNT_SCHEDULE_ACCOUNTS: {
		GLOBAL_ACCOUNT: 'Global account',
		ACCOUNT_SCHEDULE: 'Account schedule',
		TYPE: 'Type',
		ACCOUNT: 'Account'
	},
	WORKPLACE_SALARY_ACCOUNTS: {
		GLOBAL_ACCOUNT: 'Global account'
	}
};

var titles = {
	WORKPLACE_SALARY_ACCOUNTS: 'Workplace salary accounts',
	ACCOUNT_SCHEDULE_ACCOUNTS: 'Account schedule accounts',
	ACCOUNT_SCHEDULES: 'Account schedules',
	GLOBAL_ACCOUNTS: 'Global accounts',
	COMPANY_ACCOUNT_SCHEDULES: 'Company / Account schedule relation'
};

module.exports = {
	headers: headers,
	titles: titles
};