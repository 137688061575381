'use strict';

import cx from 'classnames';
import _each from 'lodash/each';
import _every from 'lodash/every';
import _filter from 'lodash/filter';
import _findIndex from 'lodash/findIndex';
import _has from 'lodash/has';
import _map from 'lodash/map';
import _remove from 'lodash/remove';
import _some from 'lodash/some';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class HeaderRow extends Component {
	constructor(props) {
		super(props);

		this.sortByColumn = this.sortByColumn.bind(this);
	}

	/**
	 * @function sortByColumn
	 * @memberOf DIRECTIVES.jtjList.component.headers
	 * @description Sorts data
	 * @param {object} column The column to sort by
	 * @param {integer} key Key of the column to sort by in order to track order
	 */
	sortByColumn(column, key) {
		const validExpressionsToggleAdd = [
			this.props.collection.length < 1,
			this.props.settings.canAdd,
			_some(this.props.headers, 'canAdd'),
		];
		if (column.canSort && this.props.collection.length > 1) {
			const order = this._setSortOrderState(key);
			this.props.methods.handleSortByColumn(column, order);
		}

		// Alternate action if no data is to add new row
		else if (_every(validExpressionsToggleAdd)) {
			this.props.methods.handleToggleAddEditRow('add');
		}
	}

	/**
	 * @function _setSortOrderState
	 * @memberOf DIRECTIVES.jtjList.component.headers
	 * @description
	 * - Sets sort order on cell key in order to keep track
	 * - Sets state to remember and returns sortOrder directly
	 * @param {integer} key The key to add sortOrder for to this.state
	 * @return {string} sort order (asc or desc)
	 */
	_setSortOrderState(key) {
		const stateObj = {};
		let sortOrder = 'asc';
		if (this.state && this.state['sortOrder-' + key]) {
			sortOrder = this.state['sortOrder-' + key] === 'asc' ? 'desc' : undefined;
		}
		stateObj['sortOrder-' + key] = sortOrder;
		this.setState(stateObj);
		return sortOrder;
	}

	/**
	 * @function _getColGroups
	 * @memberOf DIRECTIVES.jtjList.component.headers
	 * @description Returns the colGroups to render column groups based on
	 * @return {array} array containing the colGroups if any
	 */
	_getColGroups() {
		const colGroups = [];
		const headers = _filter(this.props.headers, function (header) {
			return _some([!header.localHidden, _has(header, 'filterBar'), _has(header, 'alwaysHidden')]);
		});
		_each(headers, function (header) {
			// Do we have colGroup in header?
			if (_has(header, 'colGroup')) {
				// Is colGroup existing in array already? - then add to counter
				const colGroupIndex = _findIndex(colGroups, function (cG) {
					return cG.name === header.colGroup;
				});
				if (header.emptyColGroup) colGroups.push({ empty: true });
				else if (colGroupIndex > -1) colGroups[colGroupIndex].length = colGroups[colGroupIndex].length + 1;
				// Or not - then create
				else {
					colGroups.push({
						name: header.colGroup,
						label: header.colGroupLabel,
						length: 1,
					});
				}
			}
		});
		return colGroups;
	}

	/**
	 * @function _renderEmptyHeaders
	 * @memberOf DIRECTIVES.jtjList.component.headers
	 * @description Renders empty headers based on prop for specific actions (delete, edit, lock etc.)
	 */
	_renderEmptyHeaders() {
		const emptyHeaderLength = this.props.methods.handleCountRowActions(this.props);
		const emptyHeaders = [];
		for (let i = emptyHeaderLength - 1; i >= 0; i--) {
			emptyHeaders.push(<th className="list__header-cell" key={i} />);
		}
		return emptyHeaders;
	}

	/**
	 * @function _renderColGroups
	 * @memberOf DIRECTIVES.jtjList.component.headers
	 * @description Renders column groups
	 */
	_renderColGroups() {
		const colGroupBrutto = this._getColGroups();
		const colGroupNetto = this._getColGroups();
		_remove(colGroupNetto, 'empty');
		if (colGroupNetto.length > 0) {
			const colGroupsRender = _map(colGroupBrutto, function (group, key) {
				if (group.empty) return <th key={key} className="list__header-cell" />;
				else {
					return (
						<th key={key} colSpan={group.length} className="list__header-cell list__header-cell--colgroup">
							<span className="list__cell-contents">{group.label}</span>
						</th>
					);
				}
			});

			return (
				<tr className="list__row--colgroup">
					{colGroupsRender}
					{this._renderEmptyHeaders()}
				</tr>
			);
		}
	}

	/**
	 * @function _renderRowActionHeaders
	 * @memberOf DIRECTIVES.jtjList.component.headers
	 * @description Renders row actions headers if valid
	 */
	_renderRowActionHeaders() {
		const { rowActions, mouseoverAction, translations } = this.props;
		if (!rowActions) return;
		return _map(rowActions(this.props, {}), function (action, key) {
			
			if (!_every(action.validExpressions)) return null;

			const headerClassNames = cx('list__header-cell', 'list__header-cell--action', {
				'list__header-cell--fake-hover': mouseoverAction === key,
			});
			return (
				<th key={key} className={headerClassNames}>
					<span className="list__cell-contents">{translations[action.name.toUpperCase()]}</span>
				</th>
			);
		});
	}

	/**
	 * @function _renderHeaders
	 * @memberOf DIRECTIVES.jtjList.component.headers
	 * @description Renders headers
	 */
	_renderHeaders() {
		return _map(
			this.props.methods.handleReturnVisibleHeaders(),
			function (cell, key) {
				const headerClassNames = cx('list__header-cell', 'list__header-cell--' + cell.type, {
					'list__header-cell--can-sort': cell.canSort && this.props.collection.length > 1,
				});
				const cellClassNames = cx('list__cell-contents', {
					'list__cell-contents--sort-asc':
						this.props.sort && this.props.sort.column === cell && this.props.sort.order === 'asc',
					'list__cell-contents--sort-desc':
						this.props.sort && this.props.sort.column === cell && this.props.sort.order === 'desc',
				});
				const cellStyles = {
					width: cell.width ? cell.width + 'px' : 'auto',
				};
				const headerText = this.props.settings.collapseHeader ? cell.label : cell.label?.replace(/ /g, '\xA0');
				return (
					<th
						key={key}
						style={cellStyles}
						className={headerClassNames}
						onClick={this.sortByColumn.bind(this, cell, key)}>
						<span className={cellClassNames}>{headerText}</span>
					</th>
				);
			}.bind(this)
		);
	}

	render() {
		return (
			<thead>
				{this._renderColGroups()}
				<tr>
					{this._renderHeaders()}
					{this._renderRowActionHeaders()}
				</tr>
			</thead>
		);
	}
}

HeaderRow.propTypes = {
	collection: PropTypes.array.isRequired,
	headers: PropTypes.array.isRequired,
	methods: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
	sort: PropTypes.object,
};

export default HeaderRow;
