import React from 'react';
import { Outlet } from 'react-router-dom';
import { Companies, CompanyDetails } from 'structure/organisation/companies';
import { Territories } from 'structure/organisation/territories';
import { Workplaces, WorkplaceDetails } from 'structure/organisation/workplaces';
import { Markets, MarketDetails } from 'structure/organisation/markets';

import TileDirectory from 'smart/tileDirectory/tileDirectory';

export const organizationRoutes = [
	{
		path: 'organization',
		title: 'organization',
		element: <Outlet />,
		icon: require('@mui/icons-material/CorporateFareOutlined').default,
		children: [
			{
				index: true,
				element: <TileDirectory pathName="organization" />,
			},
			{
				element: <Outlet />,
				path: 'companies',
				title: 'companies',
				description: 'View legal companies globally',
				allowedAccessControlLevels: ['Organization Admin', 'Organization View'],
				children: [
					{
						element: <Companies />,
						index: true,
						allowedAccessControlLevels: ['Organization Admin', 'Organization View'],
					},
					{
						element: <CompanyDetails />,
						path: 'new',
						title: 'Company details',
						allowedAccessControlLevels: ['Organization Admin', 'Organization View'],
					},
					{
						element: <CompanyDetails />,
						path: ':companyId',
						title: 'Company details',
						allowedAccessControlLevels: ['Organization Admin', 'Organization View'],
					},
				],
			},

			{
				element: <Outlet />,
				path: 'markets',
				title: 'markets',
				description: 'Create new and update existing markets',
				allowedAccessControlLevels: ['Organization Admin', 'Organization View'],
				children: [
					{
						element: <Markets />,
						index: true,
						allowedAccessControlLevels: ['Organization Admin', 'Organization View'],
					},
					{
						element: <MarketDetails />,
						path: ':marketId',
						title: 'Market details',
						allowedAccessControlLevels: ['Organization Admin', 'Organization View'],
					},
				],
			},

			{
				path: 'territories',
				title: 'territories',
				description: 'View Territories',
				element: <Territories />,
			},

			{
				element: <Outlet />,
				path: 'workplaces',
				title: 'workplaces',
				description: 'View workplaces',
				allowedAccessControlLevels: ['Organization Admin', 'Organization View'],
				children: [
					{
						element: <Workplaces />,
						index: true,
						allowedAccessControlLevels: ['Organization Admin', 'Organization View'],
					},
					{
						element: <WorkplaceDetails />,
						path: ':workplaceId',
						title: 'Workplace details',
						allowedAccessControlLevels: ['Organization Admin', 'Organization View'],
					},
				],
			},
		],
	},
];
