import _isEmpty from 'lodash/isEmpty';

/**
 * @function getTileLayoutFilter
 * @param {Object} object - contains layouts and product id
 */
export function getTileLayoutFilter({ layouts, productId }) {
	const initialFilter = `:tile_layout_groups.tile_layout_tiles.product=='${productId}'`;

	if (_isEmpty(layouts)) return initialFilter;

	const ids = layouts.reduce((acc, currentValue, index) => {
		const id = currentValue.id;

		acc = index === layouts.length - 1 ? `${acc}'${id}']` : `${acc}'${id}',`;

		return acc;
	}, '[');

	return `${initialFilter};:id=IN=${ids}`;
}

export function markFoundLayouts({ filteredLayouts, layouts }) {
	return layouts.map((layout) => {
		const foundFilteredLayout = filteredLayouts.some(
			(filteredLayout) => filteredLayout.id === layout.id
		);

		return {
			...layout,
			productExistsInLayout: foundFilteredLayout,
		};
	});
}
