'use strict';

import localForage from 'localforage';

import { LocalStorageError } from './localStorage.errors';

class LocalStorage {
	constructor() {
		this._prefix = 'joejuice';

		// set config with fallback to avoid failing in incognito on older devices
		localForage.config({
			driver: [
				localForage.LOCALSTORAGE,
				localForage.INDEXEDDB,
				localForage.WEBSQL
			],
			name: 'wp2',
			storeName: 'wp2',
			version: 3
		});
	}

	/**
	 * @function getItem
	 * @description Gets data from local storage based on key (localForage)
	 * @param {string} item - item to get from local storage
	 * @memberOf LocalStorage
	 */
	getItem(item) {
		return new Promise((resolve, reject) => {
			localForage
				.getItem(this._prefix + item)
				.then(value => resolve(value))
				.catch(error => {
					reject(
						new LocalStorageError(
							`localStorage.getItem() [Failed to get ${item}], ${error}`
						)
					);
				});
		});
	}

	/**
	 * @function setItem
	 * @description Sets data from local storage based on item (localForage)
	 * @param {string} item - item to set in local storage
	 * @param {object} value - data to set
	 * @memberOf LocalStorage
	 */
	setItem(item, value) {
		return new Promise((resolve, reject) => {
			localForage
				.setItem(this._prefix + item, value)
				.then(() => resolve())
				.catch(error => {
					reject(
						new LocalStorageError(
							`localStorage.setItem() [Failed to set ${item}], ${error}`
						)
					);
				});
		});
	}

	/**
	 * @function removeItem
	 * @description remove data from local storage based on key (localForage)
	 * @param {string} item - item to get from local storage
	 * @memberOf LocalStorage
	 */
	removeItem(item) {
		return new Promise((resolve, reject) => {
			localForage
				.removeItem(this._prefix + item)
				.then(value => resolve(value))
				.catch(error => {
					reject(
						new LocalStorageError(
							`localStorage.removeItem() [Failed to remove  ${item}], ${error}`
						)
					);
				});
		});
	}

	/**
	 * @function clear
	 * @description Clears localstorage/localforage
	 * @memberOf LocalStorage
	 */
	clear() {
		return new Promise((resolve, reject) => {
			localForage
				.clear()
				.then(() => resolve())
				.catch(error => {
					reject(
						new LocalStorageError(
							`localStorage.clear() [Failed to set clear localStorage with error ${error}`
						)
					);
				});
		});
	}

	/**
	 * @function getItemNative
	 * @description Gets data from local storage based on key (native)
	 * @param {string} item - item to get from local storage
	 * @memberOf LocalStorage
	 */
	getItemNative(item) {
		return localStorage.getItem(this._prefix + item);
	}

	/**
	 * @function setItemNative
	 * @description Sets data from local storage based on item (native)
	 * @param {string} item - item to set in local storage
	 * @param {object} value - data to set
	 * @memberOf LocalStorage
	 */
	setItemNative(item, value) {
		localStorage.setItem(this._prefix + item, value);
	}
}

export default new LocalStorage();
