import { store } from 'appState';

import * as actions from './../employeeMeals.actions';
import { get } from 'api.vanilla.service';
var __endpoints = require('endpoints.service');

export default function fetchEmployeeMealsReportPrPerson({
	endDate,
	startDate,
	reportingCurrencyId
} = {},
marketId = null, workplaceId = null, personId = null) {

	const employeeMealsReportEndpoint = `${
		__endpoints.collection.reporting.employee_meals_reports.index.path
	}/markets/${marketId}/workplaces/${workplaceId}/persons/${personId}/person_table?from=${startDate.format('YYYY-MM-DD')}&to=${endDate.format('YYYY-MM-DD')}&reporting_currency=${reportingCurrencyId}&type=Entries`;

	return get(employeeMealsReportEndpoint).then(response => {
		let report = response.data;
		store.dispatch(actions.setEmployeeMealsReportPrPerson(report));
	});
}
