import { store } from 'appState';

// utils
import { fuzzySearch } from 'utils';

// lodash
import _get from 'lodash/get';

/**
 * @function getFilteredListData
 * @param {Object} object - contains all of the rows from the endpoint
 * @description loops through set filers and applies them on all of the rows
 * this is a frontend solution because api doesnt allow for fql on
 * /product/product_variants/:id/workplace_relations
 */
export function getFilteredListData({ duplicateListData }) {
	const storeState = store.getState();
	const tableFilters = _get(
		storeState,
		'productVariantWorkplaceRelations.tableFilters',
		{}
	);

	if (tableFilters.workplace)
		duplicateListData = fuzzySearch({
			itemsArray: duplicateListData,
			keys: ['workplace.name'],
			searchKeyword: tableFilters.workplace,
		});
	if (tableFilters.market)
		duplicateListData = fuzzySearch({
			itemsArray: duplicateListData,
			keys: ['market.name'],
			searchKeyword: tableFilters.market,
		});
	if (
		tableFilters.availableOnPos === true ||
		tableFilters.availableOnPos === false
	)
		duplicateListData = duplicateListData.filter(
			(entry) => entry.available_on_pos === tableFilters.availableOnPos
		);
	if (
		tableFilters.availableOnApp === true ||
		tableFilters.availableOnApp === false
	)
		duplicateListData = duplicateListData.filter(
			(entry) => entry.available_on_app === tableFilters.availableOnApp
		);

	return duplicateListData;
}
