import React, { useEffect } from 'react';

import EmploymentPositionsList from './components/juicerDetails/components/employmentPositionList/employmentPositionList.component';
import EmploymentWorkplaceResponsibilitiesList from './services/employmentWorkplaceResponsibilitiesList.service.js';
import EmploymentBankAccountsList from './services/employmentBankAccountsList.service.js';
import EmploymentDocuments from './components/juicerDetails/components/employmentDocuments/employmentDocuments.container.js';
import MarketResponsiblities from './components/juicerDetails/components/marketResponsibilities/marketResponsibilities.container';
import ListComponent from 'common/components/list/list.component';
import EmploymentWorkplaces from '../juicers/components/juicerDetails/components/employmentWorkplaces/employmentWorkplaces';

import { useParams } from 'react-router-dom';

const employmentWorkplaceResponsibilitiesListReduxKey =
	'/administration/workplace_responsibilities';
const employmentWorkplaceResponsibilitiesList =
	new EmploymentWorkplaceResponsibilitiesList(
		employmentWorkplaceResponsibilitiesListReduxKey
	);
const employmentWorkplaceResponsibilitiesListProps =
	employmentWorkplaceResponsibilitiesList.getProps();

const employmentBankAccountsListReduxKey = '/hr/employment_bank_accounts';
const employmentBankAccountsList = new EmploymentBankAccountsList(
	employmentBankAccountsListReduxKey
);
const employmentBankAccountsListProps = employmentBankAccountsList.getProps();

export const EmploymentDetails = () => {
	const { employmentId, personId } = useParams();

	const employmentIdFilter = `:employment.id=='${employmentId}'`;

	const employmentDocumentsProps = {
		employmentId,
		personId,
		defaultFileNameData: {},
	};

	useEffect(() => {
		// Fetch selected employment position

		employmentWorkplaceResponsibilitiesList.setFilterQuery(employmentIdFilter);
		employmentWorkplaceResponsibilitiesList.setData({
			employment: employmentId,
		});
		employmentWorkplaceResponsibilitiesList.initView();

		employmentBankAccountsList.setFilterQuery(employmentIdFilter);
		employmentBankAccountsList.setData({ employment: employmentId });
		employmentBankAccountsList.initView();
	}, [employmentIdFilter, employmentId]);

	return (
		<>
			<EmploymentPositionsList
				employmentId={employmentId}
				personId={personId}
			/>

			<EmploymentWorkplaces />

			<ListComponent
				reduxKey={employmentWorkplaceResponsibilitiesListReduxKey}
				{...employmentWorkplaceResponsibilitiesListProps}
			/>

			<MarketResponsiblities employmentId={employmentId} />

			<ListComponent
				reduxKey={employmentBankAccountsListReduxKey}
				{...employmentBankAccountsListProps}
			/>

			<EmploymentDocuments {...employmentDocumentsProps} />
		</>
	);
};
