// interface
import { ActionType } from 'types/redux';
import { ICurrencyReduxValue, ICurrencyReduxState } from '../types';

import {
	SET_CURRENCY,
	UPDATE_CURRENCY,
	RESET_STATE,
} from './currencies.actions';

const defaultState: ICurrencyReduxState = {
	data: {
		defaultCurrency: null,
		currency: {
			code: null,
			description: null,
			supportedDecimals: null,
			currentCurrencyRate: null,
		},
	},
};

function reducer(
	state = defaultState,
	action: ActionType
): ICurrencyReduxState {
	const { type, payload } = action;

	switch (type) {
	case SET_CURRENCY: {
		const castedPayload = payload as ICurrencyReduxValue;

		return {
				...state,
				data: {
					currency: castedPayload,
					defaultCurrency: castedPayload,
				},
			};
	}

	case UPDATE_CURRENCY: {
		const castedPayload = payload as Partial<ICurrencyReduxValue>;

		return {
				...state,
				data: {
					...state.data,
					currency: {
						...state.data.currency,
						...castedPayload,
					},
				},
			};
	}

	case RESET_STATE: {
		return defaultState;
	}

	default:
		return state;
	}
}

export default reducer;
