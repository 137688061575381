import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchAllAssets
 * @description Fetch all asset collections
 * @param {object} state
 */
export function fetchAllAssets(state, assetCollectionId, list) {
	const preFilter = `:asset_collection.id=='${assetCollectionId}'`;

	const filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(`/pos/assets`, params, null, state.headers)
		.then((res) => {
			let mergedList = list;

			res.data.map((x) => {
				mergedList = mergedList.map((y) => {
					if (x.domain === y.domain && x.type === y.type) {
						return x;
					} else {
						return y;
					}
				});
			});

			res.data = mergedList;
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editAssets
 * @description edit
 */
export function editAssets(collectionId, data) {
	return post(`/pos/assets/${collectionId}`, data)
		.then((res) => {
			setFeedback('Pos asset edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function addAssets
 * @description add
 */
export function addAssets(data) {
	return post(`/pos/assets`, data)
		.then((res) => {
			setFeedback('Pos asset added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteAssets
 * @description del
 */
export function deleteAssets(collectionId) {
	return remove(`/pos/assets/${collectionId}`)
		.then((res) => {
			setFeedback('Pos asset deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addAssetCollection
 * @description add
 */
export function addAssetCollection(data) {
	return post(`/pos/asset_collections`, data)
		.then((res) => {
			setFeedback('Asset collection added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}
