export const UPDATE_BATCH_FORM =
	'SALES_CONFIGURATION_INGREDIENT_PRICING_GROUPS/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'SALES_CONFIGURATION_INGREDIENT_PRICING_GROUPS/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_INGREDIENT_PRICING_GROUPS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_INGREDIENT_PRICING_GROUPS/REMOVE_BATCH_LIST_ITEM';
export const SET_INGREDIENT_PRICING_GROUP =
	'SALES_CONFIGURATION_INGREDIENT_PRICING_GROUPS/SET_INGREDIENT_PRICING_GROUP';
export const UPDATE_INGREDIENT_PRICING_GROUP =
	'SALES_CONFIGURATION_INGREDIENT_PRICING_GROUPS/UPDATE_INGREDIENT_PRICING_GROUP';
export const RESET_STATE =
	'SALES_CONFIGURATION_INGREDIENT_PRICING_GROUPS/RESET_STATE';

export function setIngredientPricingGroup(payload) {
	return {
		type: SET_INGREDIENT_PRICING_GROUP,
		payload
	};
}

export function updateIngredientPricingGroup(payload) {
	return {
		type: UPDATE_INGREDIENT_PRICING_GROUP,
		payload
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload
	};
}

export function resetState() {
	return {
		type: RESET_STATE
	};
}
