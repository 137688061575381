import enums from './../enums/marketShiftRules';

/**
 * @function _getOptions
 * @description returns shift rule options for collection select
 */
export function getOptions() {
	return Object.keys(enums).map((key) => ({
		label: enums[key],
		value: enums[key],
	}));
}
