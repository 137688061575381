export const UPDATE_BATCH_FORM =
	'SALES_CONFIGURATION_PRODUCT_VARIANTS_PRICING/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'SALES_CONFIGURATION_PRODUCT_VARIANTS_PRICING/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_PRODUCT_VARIANTS_PRICING/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_PRODUCT_VARIANTS_PRICING/REMOVE_BATCH_LIST_ITEM';
export const SET_PRODUCT_VARIANTS_PRICING =
	'SALES_CONFIGURATION_PRODUCT_VARIANTS_PRICING/SET_PRODUCT_VARIANTS_PRICING';
export const UPDATE_PRODUCT_VARIANTS_PRICING =
	'SALES_CONFIGURATION_PRODUCT_VARIANTS_PRICING/UPDATE_PRODUCT_VARIANTS_PRICING';
export const RESET_STATE =
	'SALES_CONFIGURATION_PRODUCT_VARIANTS_PRICING/RESET_STATE';

export const SET_POS_CONFIGURATION_PRODUCT_VARIANT_PRICINGS_DATE_FILTER =
	'SALES_CONFIGURATION_PRODUCT_VARIANTS_PRICING/SET_POS_CONFIGURATION_PRODUCT_VARIANT_PRICINGS_DATE_FILTER';
export const SET_POS_CONFIGURATION_PRODUCT_VARIANT_PRICINGS_LEGACY_FILTER =
	'SALES_CONFIGURATION_PRODUCT_VARIANTS_PRICING/SET_POS_CONFIGURATION_PRODUCT_VARIANT_PRICINGS_LEGACY_FILTER';

export function setProductVariantsPricing(payload) {
	return {
		type: SET_PRODUCT_VARIANTS_PRICING,
		payload,
	};
}
export function setPosConfigurationProductVariantPricingsDateFilter(payload) {
	return {
		type: SET_POS_CONFIGURATION_PRODUCT_VARIANT_PRICINGS_DATE_FILTER,
		payload,
	};
}

export function setLegacyFilter(payload) {
	return {
		type: SET_POS_CONFIGURATION_PRODUCT_VARIANT_PRICINGS_LEGACY_FILTER,
		payload,
	};
}

export function updateProductVariantPricing(payload) {
	return {
		type: UPDATE_PRODUCT_VARIANTS_PRICING,
		payload,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}
