'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Icon, ButtonGroup, InputCollectionSelect } from 'dumb';
import { Tip } from 'dumb/tips';
import { ModalRow } from 'dumb/modal';

import phrases from './../../salesConfigurationPosConfigurationDiscountIngredients.phrases';

class ModalBatchStepFormDiscounts extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false
		};

		this.posConfigurationDiscounts = '/pos/pos_configuration_discounts';
	}

	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		updateBatchForm({
			[type]: e
		});
	}

	render() {
		const { batchFormData, toggleOverlay } = this.props;

		return (
			<>
				<ModalRow>
					<ButtonGroup type="full-width">
						<InputCollectionSelect
							className="modal-batch-select"
							id="pos_configuration_version"
							placeholder="Select discount"
							value={batchFormData.posConfigurationDiscount}
							handleChange={(x, value) =>
								this.editStoreEntry(value, 'posConfigurationDiscount')
							}
							multi
							cache
							apiPath={this.posConfigurationDiscounts}
							params={{
								limit: 50
							}}
							optionFormat={entry => ({
								value: entry,
								label: entry.discount.name
							})}
							inputFilterFormat={input => `:discount.name=like='%${input}%'`}
						/>

						<Button
							type="inverted"
							shadow
							size="large"
							onClick={() => toggleOverlay(this.posConfigurationDiscounts)}>
							<Icon name="more" />
						</Button>
					</ButtonGroup>
				</ModalRow>
				<Tip content={phrases.MODAL_BATCH_STEP_FORM_DISCOUNTS_TIP} />
			</>
		);
	}
}

ModalBatchStepFormDiscounts.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func
};

export default ModalBatchStepFormDiscounts;
