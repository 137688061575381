'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Icon, Tag, Legend, InputCollectionSelect } from 'dumb';
import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';

// phrases
import phrases from './../../../ingredients.phrases';

// lodash
import _get from 'lodash/get';

class PosConfigurationDiscountsModalBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e.target ? e.target.value || e.target.checked : e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: value },
		});
	}

	render() {
		const { batchList, ingredientUrl } = this.props;

		const cantAddBulletColor = 'ffe699';

		return (
			<>
				<div className="modal-batch__legend">
					<Legend
						points={[
							{
								bulletColor: `#${cantAddBulletColor}`,
								label: phrases.MODAL_BATCH_STEP_LIST_LEGEND_CANT_ADD,
							},
						]}
					/>
				</div>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_DISCOUNT}</th>
							<th>{phrases.MODAL_BATCH_STEP_INGREDIENT}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((entry, i) => {
							const rowDisabled = !entry.canAdd;

							return (
								<TableListRow
									key={entry.identifier}
									type={rowDisabled && 'light'}>
									<TableListCell>
										{rowDisabled && (
											<Tag type="bullet" color={cantAddBulletColor} />
										)}
										{i + 1}
									</TableListCell>
									<TableListCell>
										{_get(entry, 'discount.name', '')}
									</TableListCell>
									<TableListCell>
										<div className="modal-batch__list__cell">
											<InputCollectionSelect
												id={`ingredient-${entry.identifier}`}
												value={entry.ingredient}
												handleChange={(key, event) =>
													this._editListEntry(event, entry, 'ingredient')
												}
												clearable={false}
												cache
												optionFormat={(entry) => ({
													value: entry,
													label: entry.name,
												})}
												apiPath={ingredientUrl}
												params={{
													limit: 30,
												}}
												required
												disabled={rowDisabled}
											/>
										</div>
									</TableListCell>

									<TableListCell>
										<Button
											id={`${entry.identifier}-delete`}
											type="negative"
											size="tiny"
											onClick={() => this._onRemoveEntry(entry.identifier)}>
											<Icon name="delete" />
										</Button>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
			</>
		);
	}
}

PosConfigurationDiscountsModalBatchStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	ingredientUrl: PropTypes.string,
};

PosConfigurationDiscountsModalBatchStepList.defaultProps = {};

export default PosConfigurationDiscountsModalBatchStepList;
