'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, Icon, Tooltip, InputCollectionSelect } from 'dumb';

import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';

// phrases/ constants
import phrases from './../../../filters.phrases';

class FiltersBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e?.target?.value ?? e;

		// edits the row in the store
		this.props.editBatchListItem({
			identifier: row.identifier,
			[type]: value,
		});
	}

	render() {
		const { batchList, addBatchListItemRow } = this.props;

		return (
			<>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_NAME}</th>
							<th>{phrases.MODAL_BATCH_STEP_DESCRIPTION}</th>
							<th>{phrases.MODAL_BATCH_STEP_ASSET_COLLECTION}</th>
							<th>{phrases.MODAL_BATCH_STEP_SORT_ORDER}</th>

							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((entry, i) => {
							const editButtonDisabled = batchList.length === 1;

							return (
								<TableListRow key={entry.identifier}>
									<TableListCell>{i + 1}</TableListCell>

									<TableListCell>
										<Input
											id={`${entry.identifier}-name`}
											placeholder={phrases.MODAL_BATCH_STEP_NAME_PLACEHOLDER}
											value={entry.name || ''}
											onChange={(event) =>
												this._editListEntry(event, entry, 'name')
											}
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`${entry.identifier}-description`}
											placeholder={
												phrases.MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER
											}
											value={entry.description || ''}
											onChange={(event) =>
												this._editListEntry(event, entry, 'description')
											}
										/>
									</TableListCell>
									<TableListCell>
										<InputCollectionSelect
											id={`${entry.identifier}-assetCollection`}
											placeholder={
												phrases.MODAL_BATCH_STEP_ASSET_COLLECTION_PLACEHOLDER
											}
											value={entry.assetCollection}
											handleChange={(key, value) => {
												this._editListEntry(value, entry, 'assetCollection');
											}}
											clearable
											cache
											apiPath="/pos/asset_collections"
											params={{
												limit: 30,
											}}
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											inputFilterFormat={(input) => `:name=like='%${input}%'`}
											tableColumns={[
												{
													Header: 'Name',
													id: 'name',
													accessor: 'name',
													filterPath: ':name',
												},
												{
													Header: 'Description',
													id: 'desc',
													accessor: 'description',
													filterPath: ':description',
												},
												{
													Header: 'Legacy',
													id: 'legacy',
													accessor: 'legacy',
													filterPath: ':legacy',
													filterable: false,
													Cell: (d) => {
														return (
															<Input
																type="checkbox"
																checked={d.original.legacy}
																disabled
															/>
														);
													},
												},
											]}
											tableReduxKey={`${entry.identifier}-productFilters-assetCollectionTable`}
											tableTitle={
												phrases.MODAL_BATCH_STEP_ASSET_COLLECTION_TABLE_TITLE
											}
										/>
									</TableListCell>
									<TableListCell>
										<Input
											id={`${entry.identifier}-sortOrder`}
											type="number"
											placeholder={
												phrases.MODAL_BATCH_STEP_SORT_ORDER_PLACEHOLDER
											}
											value={entry.sortOrder || ''}
											onChange={(event) =>
												this._editListEntry(event, entry, 'sortOrder')
											}
										/>
									</TableListCell>

									<TableListCell>
										<Button
											type="negative"
											size="tiny"
											disabled={editButtonDisabled}
											onClick={() => this._onRemoveEntry(entry.identifier)}>
											<Icon name="delete" />
										</Button>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
				<div className="filters__add-new-row">
					<Tooltip
						text={phrases.MODAL_BATCH_STEP_LIST_TOOLTIP}
						zIndex="600"
						renderData={(ref, onMouseEnter, onMouseLeave) => (
							<Button
								type="secondary"
								onClick={addBatchListItemRow}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
								refProp={ref}>
								<Icon name="add" />
							</Button>
						)}
					/>
				</div>
			</>
		);
	}
}

FiltersBatchStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
};

export default FiltersBatchStepList;
