import {
	SET_SALARY_COMPONENT,
	UPDATE_SALARY_COMPONENT,
	RESET_SALARY_COMPONENT,
	UPDATE_BATCH,
	RESET_BATCH,
	BATCH_LIST_ADD,
	BATCH_LIST_EDIT_ITEM,
	BATCH_LIST_REMOVE_ITEM
} from './salaryComponents.actions';

const defaultState = {
	ui: {},
	data: {
		salaryComponent: {},
		batchOptions: {
			name: '',
			salary_group: [],
			global_salary_component: [],
			amount_type: '',
			amount: '',
			frequency: '',
			external_export_id: '',
			active: {}
		},
		batchList: []
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_SALARY_COMPONENT: {
		return {
			...state,
			data: {
				...state.data,
				salaryComponent: payload
			}
		};
	}

	case UPDATE_SALARY_COMPONENT: {
		return {
			...state,
			data: {
				...state.data,
				salaryComponent: {
					...state.data.salaryComponent,
					market: payload.market,
					salary_group: payload.salary_group,
					global_salary_component: payload.global_salary_component,
					name: payload.name,
					amount_type: payload.amount_type,
					amount: payload.amount,
					frequency: payload.frequency,
					external_export_id: payload.external_export_id,
					active: payload.active
				}
			}
		};
	}

	case RESET_SALARY_COMPONENT: {
		return {
			...state,
			data: {
				...state.data,
				salaryComponent: {}
			}
		};
	}
	case UPDATE_BATCH: {
		return {
			...state,
			data: {
				...state.data,
				batchOptions: {
					...state.data.batchOptions,
					...payload
				}
			}
		};
	}

	case BATCH_LIST_ADD: {
		return {
			...state,
			data: {
				...state.data,
				batchList: payload
			}
		};
	}

	case RESET_BATCH: {
		return {
			...state,
			data: {
				...state.data,
				batchOptions: defaultState.data.batchOptions,
				batchList: defaultState.data.batchList
			}
		};
	}

	case BATCH_LIST_EDIT_ITEM: {
		const batchList = state.data.batchList.map(x => {
			if (x.identifier === payload.identifier) {
				return { ...x, ...payload };
			} else {
				return x;
			}
		});

		return {
			...state,
			data: {
				...state.data,
				batchList
			}
		};
	}

	case BATCH_LIST_REMOVE_ITEM: {
		const batchList = state.data.batchList.filter(
			x => x.identifier !== payload
		);

		return {
			...state,
			data: {
				...state.data,
				batchList: batchList
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
