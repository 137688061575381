'use strict';

import FileUpload from './fileUpload';
import FileType from './fileUpload.fileType';
import FileExtensions from './fileUpload.extensions';

class FileUploadFactory {

	/**
	 * @function fileUpload
	 * @memberof FileUploadFactory
	 * @param {string} name
	 */
	fileUpload(name) {
		return new FileUpload(name);
	}

	/**
	 * @function fileType
	 * @memberof FileUploadFactory
	 * @param {string} name
	 * @param {array} extensions
	 * @param {string/function} defaultFileName
	 * @param {bool} showAsDefault
	 * @param {number} id
	 */
	fileType(name, extensions, defaultFileName, showAsDefault, id) {
		return new FileType(name, extensions, defaultFileName, showAsDefault, id);
	}

	/**
	 * @function extensions
	 * @memberof FileUploadFactory
	 * @param {array} extensions
	 */
	extensions(extensions) {
		return new FileExtensions(extensions);
	}

}

export default new FileUploadFactory();