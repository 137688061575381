'use strict';

import _extend from 'lodash/extend';
import { set as setFeedback } from 'feedback.vanilla.service';

/**
 * @function loadItem
 * @memberOf SERVICES.__models
 * @description
 * Load item from API endpoint. Formats item before resolving.
 * @param  {number} id  id of item to load
 */

module.exports = function (id) {
	return new Promise((resolve, reject) => {
		this.fetchItem(
			this.getSettings().endpoint.find.path,
			id,
			_extend({}, this.getState().params),
			true
		).then(
			function (itemData) {
				resolve(itemData);
			},
			function (response) {
				// Run callbacks
				this.runModelCallbacks();

				// Give feedback
				setFeedback(response.code + ': ' + response.message, 0);

				// Reject
				reject(response);
			}.bind(this)
		);
	});
};
