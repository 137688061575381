'use strict';

import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';

import BanksList from './services/banksList.service.js';

const banksListReduxKey = '/administration/banks';
const banksList = new BanksList(banksListReduxKey);
const banksListProps = banksList.getProps();

export function Banks() {
	useEffect(() => {
		banksList.initView();
	}, []);

	return <ListComponent reduxKey={banksListReduxKey} {...banksListProps} />;
}
