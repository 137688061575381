'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import { FileUpload, InputCollectionSelect, Tooltip } from 'dumb';

import _get from 'lodash/get';

import { convertBinaryToBase64, ImageHelpers } from 'utils';

import {
	setAsset,
	updateAsset,
	resetAssets,
	setImgResizerUsed,
} from './store/assets.actions';
import { enums } from './../../assets.enums';

import {
	fetchAllAssets,
	editAssets,
	addAssets,
	deleteAssets,
} from './assets.service';

import phrases from './assets.phrases';

class Assets extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this._getToolTipText = this._getToolTipText.bind(this);

		this.state = {
			resolutionOneLoading: false,
			resolutionTwoLoading: false,
			resolutionThreeLoading: false,
			imagesMetaData: [],
		};

		this.fetchData = this.fetchData.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'asset_collection',
				accessor: (d) => _get(d, 'asset_collection.name', ''),
				filterPath: ':asset_collection.name',
			},
			{
				Header: 'Domain',
				id: 'domain',
				accessor: (d) => _get(d, 'domain', ''),
				filterPath: ':domain',
			},
			{
				Header: 'Type',
				id: 'type',
				accessor: (d) => _get(d, 'type', ''),
				filterPath: ':type',
			},
			{
				Header: 'Resolution One',
				id: 'res1',
				accessor: (d) => _get(d, 'resolution_one.url', ''),
				filterPath: ':resolution_one.filename',
				filterable: false,
				sortable: false,
				Cell: (d) => {
					if (_get(d, 'original.resolution_one.filename', false)) {
						const url = _get(d, 'original.resolution_one.url', '');

						return (
							<Tooltip
								text={this._getToolTipText()}
								renderData={(ref, onMouseEnter, onMouseLeave) => (
									<img
										title={_get(d, 'original.resolution_one.filename', '')}
										src={url}
										ref={ref}
										onMouseEnter={() => this.onImageEnter(onMouseEnter, url)}
										onMouseLeave={onMouseLeave}
									/>
								)}
							/>
						);
					} else {
						return <span />;
					}
				},
			},
			{
				Header: 'Resolution Two',
				id: 'res2',
				accessor: (d) => _get(d, 'resolution_two.url', ''),
				filterPath: ':resolution_two.filename',
				filterable: false,
				sortable: false,
				Cell: (d) => {
					if (_get(d, 'original.resolution_two.filename', false)) {
						const url = _get(d, 'original.resolution_two.url', '');

						return (
							<Tooltip
								text={this._getToolTipText()}
								renderData={(ref, onMouseEnter, onMouseLeave) => (
									<img
										title={_get(d, 'original.resolution_two.filename', '')}
										src={url}
										ref={ref}
										onMouseEnter={() => this.onImageEnter(onMouseEnter, url)}
										onMouseLeave={onMouseLeave}
									/>
								)}
							/>
						);
					} else {
						return <span />;
					}
				},
			},
			{
				Header: 'Resolution Three',
				id: 'res2',
				accessor: (d) => _get(d, 'resolution_three.url', ''),
				filterPath: ':resolution_three.filename',
				filterable: false,
				sortable: false,
				Cell: (d) => {
					if (_get(d, 'original.resolution_three.filename', false)) {
						const url = _get(d, 'original.resolution_three.url', '');

						return (
							<Tooltip
								text={this._getToolTipText(d, 'res3')}
								renderData={(ref, onMouseEnter, onMouseLeave) => (
									<img
										title={_get(d, 'original.resolution_three.filename', '')}
										src={url}
										ref={ref}
										onMouseEnter={() => this.onImageEnter(onMouseEnter, url)}
										onMouseLeave={onMouseLeave}
									/>
								)}
							/>
						);
					} else {
						return <span />;
					}
				},
			},
		];
	}

	onImageEnter(onMouseEnter, url) {
		if (!url) return;

		ImageHelpers.getImageMetadata(url).then((res) => {
			this.setState(() => ({
				image: {
					height: res.height,
					width: res.width,
				},
			}));
		});

		onMouseEnter();
	}

	_getToolTipText() {
		return `Width: ${_get(this.state, 'image.width', 0)}px, Height: ${_get(
			this.state,
			'image.height',
			0
		)}px`;
	}

	deleteEntry(id) {
		return deleteAssets(id);
	}

	editEntry() {
		const { defaultValues } = this.props;

		const payload = {
			asset_collection: _get(defaultValues, 'asset_collection.id', ''),
			domain: _get(defaultValues, 'domain.value', enums.DOMAINS[0]),
			type: _get(defaultValues, 'type.value', enums.TYPES[0]),
			resolution_one: _get(defaultValues, 'resolution_one', null),
			resolution_two: _get(defaultValues, 'resolution_two', null),
			resolution_three: _get(defaultValues, 'resolution_three', null),
		};

		return editAssets(defaultValues.id, payload);
	}

	addEntry() {
		const { defaultValues } = this.props;

		const payload = {
			asset_collection: _get(defaultValues, 'asset_collection.id', ''),
			domain: _get(defaultValues, 'domain.value', enums.DOMAINS[0]),
			type: _get(defaultValues, 'type.value', enums.TYPES[0]),
			resolution_one: _get(defaultValues, 'resolution_one', null),
			resolution_two: _get(defaultValues, 'resolution_two', null),
			resolution_three: _get(defaultValues, 'resolution_three', null),
		};

		return addAssets(payload);
	}

	setInitialEditValues(data) {
		this.props.setAsset(data);
	}

	getEditableCells() {
		const { defaultValues, imgResizerUsed } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<div>
						<InputCollectionSelect
							id="asset_collection"
							placeholder="select asset collection"
							value={
								_get(defaultValues, 'asset_collection.id', false)
									? {
											value: _get(defaultValues, 'asset_collection.id', ''),
											label: `${_get(
												defaultValues,
												'asset_collection.name',
												''
											)}`,
									  }
									: null
							}
							handleChange={(key, value) =>
								this.editStoreEntry(
									{
										id: value ? value.value : '',
										name: value ? value.label : '',
									},
									'asset_collection'
								)
							}
							clearable={false}
							cache
							apiPath="/pos/asset_collections"
							params={{
								limit: 300,
							}}
							optionFormat={(entry) => ({
								value: entry.id,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>
					</div>
				),
			},
			{
				header: 'Domain',
				value: (
					<div>
						<InputCollectionSelect
							id="domains"
							value={
								defaultValues.domain || {
									value: enums.DOMAINS[0],
									label: enums.DOMAINS[0],
								}
							}
							onChange={(event) => this.editStoreEntry(event, 'domain')}
							options={enums.DOMAINS.map((x) => ({ value: x, label: x }))}
							clearable={false}
						/>
					</div>
				),
			},
			{
				header: 'Type',
				value: (
					<div>
						<InputCollectionSelect
							id="Type"
							value={
								defaultValues.type || {
									value: enums.TYPES[0],
									label: enums.TYPES[0],
								}
							}
							onChange={(event) => this.editStoreEntry(event, 'type')}
							options={enums.TYPES.map((x) => ({ value: x, label: x }))}
							clearable={false}
						/>
					</div>
				),
			},
			{
				header: 'Auto resize',
				value: (
					<FileUpload
						onChange={(files) => this.editStoreImage(null, files)}
						file={
							imgResizerUsed
								? _get(defaultValues, 'resolution_one', null)
								: null
						}
						resize={[{ width: 300 }, { width: 600 }]}
						thumbnail
					/>
				),
			},
			{
				header: 'Resolution one',
				value: (
					<FileUpload
						onChange={(file) => this.editStoreImage('resolution_one', file)}
						file={_get(defaultValues, 'resolution_one', null)}
						loading={this.state.resolutionOneLoading}
						thumbnail
					/>
				),
			},
			{
				header: 'Resolution two',
				value: (
					<FileUpload
						onChange={(file) => this.editStoreImage('resolution_two', file)}
						file={_get(defaultValues, 'resolution_two', null)}
						loading={this.state.resolutionTwoLoading}
						thumbnail
					/>
				),
			},
			{
				header: 'Resolution three',
				value: (
					<FileUpload
						onChange={(file) => this.editStoreImage('resolution_three', file)}
						file={_get(defaultValues, 'resolution_three', null)}
						loading={this.state.resolutionThreeLoading}
						thumbnail
					/>
				),
			},
		];
	}

	editStoreImage(type, files) {
		const { setImgResizerUsed } = this.props;

		if (!files.length) {
			this.setState(() => ({ [`${type}_loading`]: true }));

			setImgResizerUsed(false);

			convertBinaryToBase64(files)
				.then((image64) => {
					this.setState(() => ({ [`${type}_loading`]: false }));

					this.editStoreEntry(
						{
							filename: type,
							data: image64,
						},
						type
					);
				})
				.catch(() => this.setState(() => ({ [`${type}_loading`]: false })));
		} else {
			this.setState(() => ({
				resolutionOneLoading: true,
				resolutionTwoLoading: true,
				resolutionThreeLoading: true,
			}));

			setImgResizerUsed(true);

			files.map((entry, i) => {
				convertBinaryToBase64(entry)
					.then((image64) => {
						this.setState(() => ({
							resolutionOneLoading: false,
							resolutionTwoLoading: false,
							resolutionThreeLoading: false,
						}));

						const {
							fileName,
							fileExtention,
						} = ImageHelpers.getImageNameExtention(entry.name);

						let objectName = 'resolution_one';
						let filename = `${fileName}-x1.${fileExtention}`;
						if (i === 1) {
							objectName = 'resolution_two';
							filename = `${fileName}-x2.${fileExtention}`;
						}
						if (i === 2) {
							objectName = 'resolution_three';
							filename = `${fileName}-x3.${fileExtention}`;
						}

						this.editStoreEntry(
							{
								filename,
								data: image64,
							},
							objectName
						);
					})
					.catch(() =>
						this.setState(() => ({
							resolutionOneLoading: false,
							resolutionTwoLoading: false,
							resolutionThreeLoading: false,
						}))
					);
			});
		}
	}

	editStoreEntry(e, type) {
		const payload = {
			[type]: e,
		};

		this.props.updateAsset(payload);
	}

	fetchData(state) {
		return fetchAllAssets(state);
	}

	render() {
		return (
			<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.ASSETS}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={10}
				reduxKey="pos/assets"
				manual
				editEntry={(e) => this.editEntry(e)}
				editableCells={this.getEditableCells()}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetAssets}
				actionsWidth={60}
				deleteEntry={this.deleteEntry}
				
				createEntry={this.addEntry}
				editModalTip={phrases.MODAL_TIP}
			/>
		);
	}
}

Assets.propTypes = {
	defaultValues: PropTypes.object,

	updateAsset: PropTypes.func,
	setAsset: PropTypes.func,
	resetAssets: PropTypes.func,
	setImgResizerUsed: PropTypes.func,
	imgResizerUsed: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setAsset,
			updateAsset,
			resetAssets,
			setImgResizerUsed,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultValues: store.salesConfigurationAssets.data.defaultAssets,
		imgResizerUsed: store.salesConfigurationAssets.data.imgResizerUsed,
	};
};

export default connectWithStore(
	Assets,
	mapStateToProps,
	mapDispatchToProps
);
