'use strict';

import Fuse from 'fuse.js';

// Util file that accepts an items array, keys array, threshold and search word
// return a sorted array based on options

/*     EXAMPLE OF OPTIONS ARRAY     */
// const options = {
//   shouldSort: true,
//   threshold: 0.3,
//   location: 0,
//   distance: 100,
//   maxPatternLength: 32,
//   minMatchCharLength: 1,
//   keys: [
//     "title",
//     "author.firstName"
//   ]
// };

/**
 * @function fuzzySearch
 * @param {Object} object
 * @param {Array} object.itemsArray - array of items to search through
 * @param {Array} object.keys - keys to look into for comparison
 * @param {Double} object.threshold - fuzzy search threshold - 0.0 needs a perfect match while 1.0 matches everything
 * @param {String} object.searchKeyword - word to search against
 * @param {Object} object.extraOptions - extra options to further tweek the search
 */
export default function fuzzySearch({
	itemsArray,
	keys,
	threshold,
	searchKeyword,
	extraOptions
}) {
	const options = {
		keys,
		threshold: threshold || 0.3,
		...(extraOptions && { ...extraOptions })
	};

	const fuse = new Fuse(itemsArray, options);
	return fuse.search(searchKeyword);
}
