import {
	SET_TIME_SLOTS,
	UPDATE_BATCH_TIME_SLOT,
	ADD_NEW_TIME_SLOT_ROW,
	RESET_ADD_TIME_SLOTS_MODAL,
	DELETE_TIME_SLOT_ROW,
	SET_PRODUCTS,
	UPDATE_HOME_SCREEN_RECOMMENDATION,
	SET_TIME_SLOT_TO_EDIT,
	UPDATE_TIME_SLOT_TO_EDIT,
	RESET_EDIT_TIME_SLOT_MODAL,
	UPDATE_TIME_SLOT,
	RESET_DATA,
	SET_TIME_SLOT_TO_DELETE,
	RESET_DELETE_TIME_SLOT_MODAL,
	DELETE_TIME_SLOT,
	SET_TILE_LAYOUT_TO_DUPLICATE,
	RESET_TILE_LAYOUT_TO_DUPLICATE,
} from './homeScreenRecommendations.actions';

// constants
import constants from 'services/constants';
import moment from 'moment';

// lodash
import _uniqueId from 'lodash/uniqueId';
import _omit from 'lodash/omit';

const defaultState = {
	timeSlots: [],
	batchTimeSlots: [
		{
			identifier: _uniqueId('row-'),
			timeFrom: '00:00',
			timeTo: '23:59',
		},
	],
	products: [],
	timeSlotToEdit: {},
	tileLayoutToDuplicate: {},
	timeSlotToDelete: null,
};

export default function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_TIME_SLOTS:
			return {
				...state,
				timeSlots: [...state.timeSlots, ...payload].sort((a, b) => {
					if (!a.time.from || !b.time.from) return 0;

					const timeFromA = moment.utc(a.time.from, constants.dayFormat);
					const timeFromB = moment.utc(b.time.from, constants.dayFormat);

					if (timeFromA.isBefore(timeFromB)) return -1;
					else return 1;
				}),
			};

		case UPDATE_HOME_SCREEN_RECOMMENDATION:
			return {
				...state,
				timeSlots: state.timeSlots.map((entry) => {
					if (entry.id === payload.tile_layout_time_slot?.id) {
						return {
							...entry,
							homeScreenRecommendations: entry.homeScreenRecommendations.map(
								(entryTwo) => {
									if (entryTwo.id === payload.id) {
										const recommendation = _omit(
											payload,
											'tile_layout_time_slot'
										);

										return recommendation;
									}

									return entryTwo;
								}
							),
						};
					}

					return entry;
				}),
			};

		case ADD_NEW_TIME_SLOT_ROW:
			return {
				...state,
				batchTimeSlots: [
					...state.batchTimeSlots,
					{
						...defaultState.batchTimeSlots[0],
						identifier: _uniqueId('row-'),
					},
				],
			};

		case UPDATE_BATCH_TIME_SLOT:
			return {
				...state,
				batchTimeSlots: state.batchTimeSlots.map((entry) => {
					if (entry.identifier === payload.identifier) {
						return {
							...entry,
							...payload,
						};
					}

					return entry;
				}),
			};

		case RESET_ADD_TIME_SLOTS_MODAL:
			return {
				...state,
				batchTimeSlots: defaultState.batchTimeSlots,
			};

		case DELETE_TIME_SLOT_ROW:
			return {
				...state,
				batchTimeSlots: state.batchTimeSlots.filter(
					(entry) => entry.identifier !== payload.identifier
				),
			};

		case SET_PRODUCTS:
			return {
				...state,
				products: payload,
			};

		case SET_TIME_SLOT_TO_EDIT:
			return {
				...state,
				timeSlotToEdit: payload,
			};

		case UPDATE_TIME_SLOT_TO_EDIT:
			return {
				...state,
				timeSlotToEdit: {
					...state.timeSlotToEdit,
					...payload,
				},
			};

		case RESET_EDIT_TIME_SLOT_MODAL:
			return {
				...state,
				timeSlotToEdit: defaultState.timeSlotToEdit,
			};

		case UPDATE_TIME_SLOT:
			return {
				...state,
				timeSlots: state.timeSlots.map((entry) => {
					if (entry.id === payload.id)
						return {
							...entry,
							...payload,
						};

					return entry;
				}),
			};

		case SET_TIME_SLOT_TO_DELETE:
			return {
				...state,
				timeSlotToDelete: payload,
			};

		case RESET_DELETE_TIME_SLOT_MODAL:
			return {
				...state,
				timeSlotToDelete: defaultState.timeSlotToDelete,
			};

		case DELETE_TIME_SLOT:
			return {
				...state,
				timeSlots: state.timeSlots.filter((entry) => entry.id !== payload.id),
			};

		case RESET_DATA:
			return defaultState;

		case SET_TILE_LAYOUT_TO_DUPLICATE:
			return {
				...state,
				tileLayoutToDuplicate: payload,
			};

		case RESET_TILE_LAYOUT_TO_DUPLICATE:
			return {
				...state,
				tileLayoutToDuplicate: defaultState.tileLayoutToDuplicate,
			};

		default:
			return state;
	}
}
