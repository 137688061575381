'use strict';

export const SET_COLUMNS = 'TOGGLE_COLUMNS/SET_COLUMNS';
export const INITIALIZE_COLUMNS = 'TOGGLE_COLUMNS/INITIALIZE_COLUMNS';

export function setToggleColumns(payload) {
	return {
		type: SET_COLUMNS,
		payload
	};
}

export function initializeColumns(payload) {
	return {
		type: INITIALIZE_COLUMNS,
		payload
	};
}
