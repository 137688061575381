import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './table.css';

const Table = ({ children, marginBottom, fixed }) => {
	const classNames = classnames('report-table', {
		'report-table--margin-bottom': marginBottom,
		'report-table--fixed': fixed,
	});

	return <table className={classNames}>{children}</table>;
};

Table.displayName = 'Table';

Table.defaultProps = {
	className: null,
	marginBottom: false,
	fixed: false,
};

Table.propTypes = {
	fixed: PropTypes.bool,
	marginBottom: PropTypes.bool,
	children: PropTypes.node,
};

export { Table };
