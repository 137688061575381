import React from 'react';
import PropTypes from 'prop-types';
import { HeaderCell, Row, Head } from 'dumb/report';
import dailyReportConstants from './../dailyReport.constants';

const DailyReportHeaderBaseModel = React.memo(
	({ entry, reportType, firstColumnEntry }) => {
		return (
			<Head>
				<Row>
					<HeaderCell colSpan="1" width="160px" content="Group" alignLeft />
					<HeaderCell colSpan="1" width="36px" content="" />
					<HeaderCell
						separate
						colSpan="7"
						width="420px"
						content="Turnover Week Days"
					/>
					<HeaderCell
						separate
						width="233px"
						colSpan="4"
						content="Month Performance"
					/>

					<HeaderCell separate colSpan="1" width="232px" content="NTM" />
					<HeaderCell separate colSpan="3" width="90px" content="Week SSS" />

					<HeaderCell separate colSpan="5" width="420px" content="MONTH SSS" />

					<HeaderCell separate colSpan="3" width="120px" content="Averages" />
				</Row>

				{/* // ========= Subheader ================== */}

				<Row subheader>
					{/* GROUP */}
					<HeaderCell
						width="160px"
						colSpan="1"
						content={firstColumnEntry}
						alignLeft
					/>

					<HeaderCell width="36px" colSpan="1" content="SQM " alignRight />

					{/* Turnover week days */}
					<HeaderCell
						colSpan="1"
						width="60px"
						alignRight
						content="M"
						separate
					/>
					<HeaderCell colSpan="1" width="60px" alignRight content="T" />
					<HeaderCell colSpan="1" width="60px" alignRight content="W" />
					<HeaderCell colSpan="1" width="60px" alignRight content="T" />
					<HeaderCell colSpan="1" width="60px" alignRight content="F" />
					<HeaderCell colSpan="1" width="60px" alignRight content="S" />
					<HeaderCell colSpan="1" width="60px" alignRight content="S" />

					{/*  Month Performance */}
					<HeaderCell
						colSpan="1"
						width="48px"
						alignRight
						content="TO M2D"
						separate
					/>
					<HeaderCell colSpan="1" width="48px" alignRight content="TO EOM" />
					<HeaderCell colSpan="1" width="48px" alignRight content="SOM" />
					<HeaderCell colSpan="1" width="38px" alignRight content="SOMΔ" />

					{/* NTM */}
					<HeaderCell
						colSpan="1"
						width="232px" // we need to remove this once we add columns back
						// width="80px"
						alignRight
						content="Turnover"
						separate
					/>
					{/* <HeaderCell colSpan="1" width="38px" alignRight content="LC%" />
					<HeaderCell colSpan="1" width="80px" alignRight content="SOC" />
					<HeaderCell colSpan="1" width="38px" alignRight content="SOC%" /> */}

					{/* Week SSS */}

					<HeaderCell
						colSpan="1"
						alignRight
						width="36px"
						content="L4W"
						separate
					/>
					<HeaderCell colSpan="1" width="36px" alignRight content="L8W" />
					<HeaderCell colSpan="1" width="36px" alignRight content="L12W" />

					{/* Month SSS  */}
					<HeaderCell
						colSpan="1"
						width="36px"
						alignRight
						content="M2d"
						separate
					/>
					<HeaderCell colSpan="1" width="36px" alignRight content="EOM" />
					<HeaderCell colSpan="1" width="36px" alignRight content="L3M" />
					<HeaderCell colSpan="1" width="36px" alignRight content="L6M" />
					<HeaderCell colSpan="1" width="36px" alignRight content="L12M" />
					{/* <HeaderCell
									colSpan="1"
									width="36px"
									alignRight
									content="N12M"
								/> */}

					{/* YoY Growth */}
					{/* <HeaderCell
									colSpan="1"
									width={'41px'}
									alignRight
									content={'Y2D'}
									separate
								/>
								<HeaderCell
									colSpan="1"
									width={'41px'}
									alignRight
									content={'N12M'}
								/> */}

					{/* Averages */}
					<HeaderCell width="52px" alignRight content="TRANS" separate />
					<HeaderCell width="38px" alignRight content="Prod" />
					<HeaderCell width="38px" alignRight content="T. SZ" />
					{/* <HeaderCell width="38px" alignRight content="POS" />
								<HeaderCell colSpan="1" width="38px" alignRight content="App" /> */}
				</Row>
			</Head>
		);
	}
);

DailyReportHeaderBaseModel.defaultProps = {
	entry: {},
	firstColumnEntry: 'Group',
};

DailyReportHeaderBaseModel.propTypes = {
	entry: PropTypes.object,
	firstColumnEntry: PropTypes.string,
	reportType: PropTypes.string,
};

export default DailyReportHeaderBaseModel;
