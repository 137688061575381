'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';
import { Tip } from 'dumb/tips';
import { DateFilterSelector } from 'reactDataWrapper/utilities';

// lodash
import _get from 'lodash/get';

import phrases from './../../../salesConfigurationPosConfigurationDiscountProductVariant.phrases';

class SalesConfigurationPosConfigurationDiscountProductVariantBatchDiscountsAddStepForm extends Component {
	constructor(props) {
		super(props);

		this.posConfigurationDiscounts = '/pos/pos_configuration_discounts';

		this._getFilter = this._getFilter.bind(this);
	}

	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		const value = e ?? [];

		updateBatchForm({
			[type]: value,
		});
	}

	_getFilter() {
		const { batchFormData, productVariant } = this.props;

		let filter = batchFormData.posConfigurationDiscountFilter;

		if (productVariant)
			filter = `${filter};:discount.discount_product_sets.discount_product_set_options.product_variant=='${productVariant.id}'`;

		return filter;
	}

	render() {
		const { batchFormData } = this.props;

		return (
			<>
				<ModalRow>
					<Tip
						content={phrases.BATCH_ADD_POS_CONFIGURATION_DISCOUNT_FILTER_TIP}
						icon="priority_high"
					/>
				</ModalRow>
				<ModalRow>
					<InputCollectionSelect
						id="discount"
						placeholder={phrases.MODAL_BATCH_STEP_FORM_DISCOUNTS_PLACEHOLDER}
						value={batchFormData.posConfigurationDiscount}
						handleChange={(x, value) =>
							this.editStoreEntry(value, 'posConfigurationDiscount')
						}
						multi
						cache
						apiPath={this.posConfigurationDiscounts}
						params={{
							limit: 30,
							filter: this._getFilter(),
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.discount.name,
						})}
						inputFilterFormat={(input) => `:discount.name=like='%${input}%'`}
						tableColumns={[
							{
								Header: 'Pos configuration',
								id: 'posConfiguration',
								accessor: (d) =>
									_get(
										d,
										'pos_configuration_version.pos_configuration.name',
										''
									),
								filterPath: ':pos_configuration_version.pos_configuration.name',
							},
							{
								Header: 'Pos configuration version',
								id: 'posConfigurationVersion',
								accessor: (d) => _get(d, 'pos_configuration_version.name', ''),
								filterPath: ':pos_configuration_version.name',
							},
							{
								Header: 'Active from',
								id: 'activeFrom',
								accessor: (d) =>
									_get(d, 'pos_configuration_version.active.from', ''),
								filterPath: ':pos_configuration_version.active.from',
								Filter: ({ column }) => (
									<DateFilterSelector
										reduxKey="globalProductDiscounts-/pos/pos_configuration_discounts"
										columnId={column.id}
										zIndex={600}
									/>
								),
							},
							{
								Header: 'Active To',
								id: 'activeTo',
								accessor: (d) =>
									_get(d, 'pos_configuration_version.active.to', ''),
								filterPath: ':pos_configuration_version.active.to',
								Filter: ({ column }) => (
									<DateFilterSelector
										reduxKey="globalProductDiscounts-/pos/pos_configuration_discounts"
										columnId={column.id}
										zIndex={600}
									/>
								),
							},
							{
								Header: 'Global discount',
								id: 'globalDiscount',
								accessor: (d) => _get(d, 'discount.name', ''),
								filterPath: ':discount.name',
							},
							{
								Header: 'Discount name',
								id: 'discountName',
								accessor: (d) => d.name || '',
								filterPath: ':name',
							},
							{
								Header: 'Discount description',
								id: 'discountDescription',
								accessor: (d) => d.description || '',
								filterPath: ':description',
							},
							{
								Header: 'Loyalty product',
								id: 'loyaltyProduct',
								accessor: (d) =>
									_get(d, 'loyalty_product.product_variant.name', ''),
								filterPath: ':loyalty_product.product_variant.name',
							},
						]}
						tableReduxKey="globalProductDiscounts-/pos/pos_configuration_discounts"
						tableTitle={
							phrases.MODAL_BATCH_STEP_FORM_POS_CONFIGURATION_DISCOUNTS_TABLE_TITLE
						}
					/>
				</ModalRow>
				<Tip content={phrases.MODAL_BATCH_STEP_FORM_DISCOUNTS_TIP} />
			</>
		);
	}
}

SalesConfigurationPosConfigurationDiscountProductVariantBatchDiscountsAddStepForm.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	productVariant: PropTypes.object,
};

export default SalesConfigurationPosConfigurationDiscountProductVariantBatchDiscountsAddStepForm;
