export const RESET_STATE =
	'GLOBAL_ADMINISTRATION_MARKET_SHIFT_DEVIATION_REASONS/RESET_STATE';
export const SET_MARKET_DEVIATION_REASON =
	'GLOBAL_ADMINISTRATION_MARKET_SHIFT_DEVIATION_REASONS/SET_MARKET_DEVIATION_REASON';
export const UPDATE_MARKET_DEVIATION_REASON =
	'GLOBAL_ADMINISTRATION_MARKET_SHIFT_DEVIATION_REASONS/UPDATE_MARKET_DEVIATION_REASON';

export const EDIT_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_MARKET_SHIFT_DEVIATION_REASONS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_MARKET_SHIFT_DEVIATION_REASONS/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'GLOBAL_ADMINISTRATION_MARKET_SHIFT_DEVIATION_REASONS/ADD_BATCH_LIST_ITEM_ROW';

export function setMarketShiftDeviationReason(payload) {
	return {
		type: SET_MARKET_DEVIATION_REASON,
		payload,
	};
}

export function updateMarketShiftDeviationReasons(payload) {
	return {
		type: UPDATE_MARKET_DEVIATION_REASON,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}
