import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import "./list-item.css";

export default class ListItem extends Component {
	render() {
		const {
			children,
			className,
			id,
			name,
			value,
			isChecked,
			onChange
		} = this.props;

		const classNames = classnames('j-list-item', {
			[`${className}`]: className,
			[`is-checked`]: isChecked
		});

		return (
			<label htmlFor={id} className={classNames}>
				<input
					id={id}
					name={name}
					defaultChecked={isChecked || false}
					value={value}
					type="checkbox"
					onChange={onChange}
				/>
				{children}
			</label>
		);
	}
}

ListItem.propTypes = {
	// shared props
	id: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.any,
	children: PropTypes.any,
	className: PropTypes.string,
	isChecked: PropTypes.bool,

	// func
	onChange: PropTypes.func
};
