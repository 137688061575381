'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, TableList, InputCollectionSelect, Icon } from 'dumb';

// phrases/ constants
import phrases from './../../../rawIngredientConfigurationRawIngredients.phrases';

// lodash
import _get from 'lodash/get';

class rawIngredientConfigurationsModalStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: e }
		});
	}

	render() {
		const { batchList, rawIngredientsUrl } = this.props;

		return (
			<>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_GLOBAL_RAW_INGREDIENT}</th>
							<th>{phrases.MODAL_BATCH_RAW_INGREDIENT}</th>

							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((entry, i) => {
							return (
								<tr key={entry.identifier}>
									<td>{i + 1}</td>

									<td>
										{_get(
											entry,
											'rawIngredient.value.global_raw_ingredient.name',
											''
										)}
									</td>

									<td>
										<div className="raw-ingredient-modal-batch__list__cell">
											<InputCollectionSelect
												id={`rawIngredient-${i}`}
												value={entry.rawIngredient}
												handleChange={(key, value) =>
													this._editListEntry(value, entry, 'rawIngredient')
												}
												cache
												apiPath={rawIngredientsUrl}
												params={{
													limit: 300
												}}
												optionFormat={entry => ({
													value: entry,
													label: entry.name
												})}
												inputFilterFormat={input => `:name=like='%${input}%'`}
												clearable={false}
											/>
										</div>
									</td>

									<td>
										<Button
											id={`delete-${i}`}
											type="negative"
											size="tiny"
											onClick={() => this._onRemoveEntry(entry.identifier)}>
											<Icon name="delete" />
										</Button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</TableList>
			</>
		);
	}
}

rawIngredientConfigurationsModalStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	rawIngredientsUrl: PropTypes.string
};

export default rawIngredientConfigurationsModalStepList;
