import {
	UPDATE_HOME_SCREEN_RECOMMENDATION_TRANSLATION,
	SET_HOME_SCREEN_RECOMMENDATION_TRANSLATION,
	RESET_STATE,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	ADD_BATCH_LIST_ITEM_ROW,
	RERENDER_TABLE,
} from './homeScreenRecommendationTranslations.actions';

// utils
import _uniqueId from 'lodash/uniqueId';
import moment from 'moment';

const defaultState = {
	tableKey: moment().unix(),
	homeScreenRecommendationTranslation: {},
	originalHomeScreenRecommendationTranslation: {},
	batchList: [
		{
			identifier: _uniqueId('row-'),
			description: '',
			language: null,
		},
	],
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case RERENDER_TABLE:
			return {
				...state,
				tableKey: moment().unix(),
			};

		case SET_HOME_SCREEN_RECOMMENDATION_TRANSLATION:
			return {
				...state,
				homeScreenRecommendationTranslation: payload,
				originalHomeScreenRecommendationTranslation: payload,
			};

		case UPDATE_HOME_SCREEN_RECOMMENDATION_TRANSLATION:
			return {
				...state,
				homeScreenRecommendationTranslation: {
					...state.homeScreenRecommendationTranslation,
					...payload,
				},
			};

		case RESET_STATE:
			return defaultState;

		case EDIT_BATCH_LIST_ITEM:
			return {
				...state,
				batchList: state.batchList.map((entry) => {
					if (entry.identifier === payload.identifier) return payload;
					return entry;
				}),
			};

		case REMOVE_BATCH_LIST_ITEM:
			return {
				...state,
				batchList: state.batchList.filter((entry) => entry.identifier !== payload),
			};

		case ADD_BATCH_LIST_ITEM_ROW:
			return {
				...state,
				batchList: [
					...state.batchList,
					{
						...defaultState.batchList[0],
						identifier: _uniqueId('row-'),
					},
				],
			};

		default:
			return state;
	}
}

export default reducer;
