'use strict';

/**
 * @function _getParameters
 * @description Extracts parameters from hotbar resultSet
 * @param {object} resultSet
 * @return {object} parameter objects
 */
const getParameters = resultSet => {
	const results = resultSet.results;

	// Check for correct results from hotbar
	if (results['Date'] !== undefined) {
		// Get date range from hotbar daterange input
		const date = {
			from: results['Date'].getDate().clone().startOf('year').format('YYYY'),
			to: results['Date'].getDate().clone().add(1,'year').startOf('year').format('YYYY'),
		};
		
		
		// Resolve promise with date
		return Promise.resolve(date);
	}

	// Return error if not present
	else return Promise.reject(new Error('getFilter(): Hotbar result set is missing Date.'));
};

export { getParameters };