import {
	UPDATE_INGREDIENTS,
	SET_INGREDIENTS,
	RESET_INGREDIENTS,
	UPDATE_BATCH,
	RESET_BATCH,
	BATCH_LIST_ADD,
	BATCH_LIST_EDIT_ITEM,
	BATCH_LIST_REMOVE_ITEM,
} from './ingredients.actions';

const defaultState = {
	ui: {},
	data: {
		ingredientEntry: {},
		batchOptions: {
			name: '',
			asset_collection: null,
			ingredient_category: [],
			ingredient_pricing_group: [],
			description: '',
			availabilityAdministration: true,
		},
		batchList: [],
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_INGREDIENTS: {
			return {
				...state,
				data: {
					...state.data,
					ingredientEntry: payload,
				},
			};
		}

		case UPDATE_INGREDIENTS: {
			return {
				...state,
				data: {
					...state.data,
					ingredientEntry: {
						...state.data.ingredientEntry,
						...payload,
					},
				},
			};
		}

		case RESET_INGREDIENTS: {
			return {
				...state,
				data: {
					...state.data,
					ingredientEntry: {},
				},
			};
		}

		case UPDATE_BATCH: {
			return {
				...state,
				data: {
					...state.data,
					batchOptions: {
						...state.data.batchOptions,
						...payload,
					},
				},
			};
		}

		case BATCH_LIST_ADD: {
			return {
				...state,
				data: {
					...state.data,
					batchList: payload,
				},
			};
		}

		case RESET_BATCH: {
			return {
				...state,
				data: {
					...state.data,
					batchOptions: defaultState.data.batchOptions,
					batchList: defaultState.data.batchList,
				},
			};
		}

		case BATCH_LIST_EDIT_ITEM: {
			const batchList = state.data.batchList.map((x) => {
				if (x.identifier === payload.identifier) {
					return payload;
				} else {
					return x;
				}
			});

			return {
				...state,
				data: {
					...state.data,
					batchList: batchList,
				},
			};
		}

		case BATCH_LIST_REMOVE_ITEM: {
			const batchList = state.data.batchList.filter(
				(x) => x.identifier !== payload
			);

			return {
				...state,
				data: {
					...state.data,
					batchList: batchList,
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
