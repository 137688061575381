'use strict';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { batchAddRow, batchDeleteRow, batchUpdateValue } from './../../store/shiftClockinCorrectionOptions.actions';

// components
import { ModalStepper } from 'dumb';

// batch view
import ShiftClockinCorrectionOptionsBatchStepList from './components/shiftClockinCorrectionOptionsBatchStepList';

// phrases
import phrases from './../../shiftClockinCorrectionOptions.phrases';

// services
import { addShiftClockinCorrectionOptions } from './../../shiftClockinCorrectionOptions.service';

import './shiftClockinCorrectionOptionsModalBatch.css';

const ShiftClockinCorrectionOptionsModalBatch = ({ onSubmit, onClose }) => {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const { batchValues } = useSelector((store) => store.shiftClockinCorrectionOptions);

	const batchSubmit = () => {
		setLoading(true);

		const payload = batchValues.map((entry) => {
			return {
				comment: entry.comment,
			};
		});

		addShiftClockinCorrectionOptions({ batch: payload })
			.then(() => {
				setLoading(false);
				onSubmit();
			})
			.catch(() => {
				setLoading(false);
			});
	};
	const editBatchValue = (value) => dispatch(batchUpdateValue(value));
	const addRow = () => dispatch(batchAddRow());
	const deleteRow = (index) => dispatch(batchDeleteRow(index));

	const steps = [
		{
			component: (
				<ShiftClockinCorrectionOptionsBatchStepList
					batchValues={batchValues}
					editBatchValue={editBatchValue}
					deleteRow={deleteRow}
					addRow={addRow}
				/>
			),
			title: phrases.MODAL_BATCH_TITLE,
			isValid: batchValues.every((entry) => entry.comment),
			onNext: batchSubmit,
			loading,
			confirmButtonLabel: phrases.MODAL_BATCH_CONFIRM_BUTTON_LABEL,
			defaultStyles: false,
			noScroll: false,
		},
	];

	return <ModalStepper isOpen steps={steps} showStep={false} onClose={onClose} />;
};

ShiftClockinCorrectionOptionsModalBatch.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default ShiftClockinCorrectionOptionsModalBatch;
