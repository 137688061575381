import React from 'react';

// different tables
import ProductVariantGroups from './components/productVariantGroups/productVariantGroups.component';
import ProductVariantBasketRecommendations from './components/productVariantBasketRecommendations/productVariantBasketRecommendations.component';
import BasketRecommendationPriorities from './components/basketRecommendationPriorities/basketRecommendationPriorities.component';
import FilterGroups from './components/filterGroups/filterGroups.component';
import WaitingTimeSchedules from './components/waitingTimeSchedules/waitingTimeSchedules.component';

function OperationalExcellence(props) {
	return (
		<>
			<FilterGroups />
			<ProductVariantGroups />
			<ProductVariantBasketRecommendations />
			<BasketRecommendationPriorities />
			<WaitingTimeSchedules />
		</>
	);
}

export default OperationalExcellence;
