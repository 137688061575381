import React from 'react';
import PropTypes from 'prop-types';

// components
import { Tabs } from 'dumb';
import SubTable from 'reactDataWrapper/components/subTable';

// tables
import PosConfigurationAssociatedIngredients from './components/posConfigurationAssociatedIngredients/posConfigurationAssociatedIngredients.component';
import PosConfigurationProductVariantIngredientVariations from './components/posConfigurationProductVariantIngredientVariations/posConfigurationProductVariantIngredientVariations.component';
import PosConfigurationDiscountProductVariants from './components/posConfigurationDiscountProductVariants/posConfigurationDiscountProductVariants.component';

// phrases
import phrases from './subTables.phrases';

const SubTables = ({ row }) => {
	const tabContent = [
		{
			name: phrases.ASSOCIATED_INGREDIENTS,
			component: (
				<PosConfigurationAssociatedIngredients
					posConfigurationVersionId={row.original.pos_configuration_version.id}
					productVariantId={row.original.id}
				/>
			),
		},
		{
			name: phrases.PRODUCT_VARIANT_INGREDIENT_VARIATIONS,
			component: (
				<PosConfigurationProductVariantIngredientVariations
					posConfigurationId={row.original.pos_configuration_version.pos_configuration.id}
					productVariantId={row.original.id}
				/>
			),
		},
		{
			name: phrases.INGREDIENT_ASSOCIATED_DISCOUNTS,
			component: (
				<PosConfigurationDiscountProductVariants
					posConfigurationId={row.original.pos_configuration_version.pos_configuration.id}
					productVariantId={row.original.id}
				/>
			),
		},
	];
	return (
		<SubTable>
			<Tabs tabContent={tabContent} />
		</SubTable>
	);
};

SubTables.propTypes = {
	row: PropTypes.object,
};

export default SubTables;
