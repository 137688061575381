export default {
	TABLE_TITLE: 'Product Addon recommendations',
	TABLE_TITLE_INNER: 'Addon ingredients',
	ADDON_RECOMMENDATION_DELETED: 'Addon recommendation deleted.',
	ADDON_RECOMMENDATION_ADDED: 'Addon recommendation added.',
	ADDON_RECOMMENDATION_EDITED: 'Addon recommendation edited.',
	ADDON_INGREDIENT_ADDED: 'Addon ingredient added.',
	ADDON_INGREDIENT_EDITED: 'Addon ingredient edited.',
	ADDON_INGREDIENT_DELETED: 'Addon ingredient deleted.'
};
