'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	addBatchList,
	updateBatchForm,
	removeBatchListItem,

	// regular stuff
	setProductTranslation,
	updateProductTranslation,
	resetState,
	editBatchListItem,
} from './store/productTranslations.actions';

// services
import {
	fetchProductTranslations,
	addProductTranslations,
	deleteProductTranslations,
	editProductTranslation,
	editProductTranslations,
} from './productTranslations.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Button, Icon, Input } from 'dumb';
import ProductTranslationsModalBatch from './components/batch/productTranslationsModalBatch';
import getProductTranslationsColumns from 'reactDataWrapperColumns/product/productTranslations.columns';

// utils
import moment from 'moment';
import phrases from './productTranslations.phrases';
import { getEditedValues } from 'services/utils';

const reduxKey = '/product/product_translations';

class ProductTranslations extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment().unix(),
		};

		this.deleteEntry = this.deleteEntry.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.addProductTranslationsWrapper = this.addProductTranslationsWrapper.bind(
			this
		);
		this.fetchData = this.fetchData.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);

		this.columns = getProductTranslationsColumns();
	}

	editEntry(data) {
		const { productTranslation, originalProductTranslation } = this.props;

		const editedValues = getEditedValues({
			oldData: originalProductTranslation,
			newData: productTranslation,
		});

		const payload = data || {
			id: productTranslation.id,
			name: editedValues.name ?? undefined,
			description: editedValues.description ?? undefined,
		};

		return editProductTranslation(payload);
	}

	editMultiple(selectedRows) {
		const { productTranslation } = this.props;

		const payload = {
			...(productTranslation.name && {
				name: productTranslation.name,
			}),
			...(productTranslation.description && {
				description: productTranslation.description,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editProductTranslations({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteProductTranslations(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			language: data.language?.name ?? '',
			name: data.name,
			description: data.description,
		};

		this.props.setProductTranslation(payload);
	}

	fetchData(state) {
		const { product } = this.props;

		const filter = `:product.id=='${product.id}'`;

		return fetchProductTranslations(state, filter);
	}

	getEditableCells() {
		const { productTranslation } = this.props;

		return [
			{
				header: 'Language',
				value: <span>{productTranslation.language}</span>,
			},
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={productTranslation?.name ?? ''}
						onChange={(e) => this.editStoreEntry(e.target.value, 'name')}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={productTranslation?.description ?? ''}
						onChange={(e) => this.editStoreEntry(e.target.value, 'description')}
					/>
				),
			},
		];
	}

	editStoreEntry(e, type) {
		this.props.updateProductTranslation({
			[type]: e,
		});
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	addProductTranslationsWrapper(data) {
		return addProductTranslations(data).then(() =>
			this.setState(() => ({
				key: moment().unix(),
			}))
		);
	}

	render() {
		const {
			batchList,
			removeBatchListItem,
			addBatchList,
			batchForm,
			updateBatchForm,
			product,
			editBatchListItem,
		} = this.props;

		return (
			<>
				<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={`${reduxKey}-${product.id}`}
					manual
					editEntry={this.editEntry}
					editMultiple={this.editMultiple}
					editableCells={this.getEditableCells()}
					setInitialEditValues={this.setInitialEditValues}
					deleteEntry={this.deleteEntry}
					onModalClose={this.props.resetState}
					actionRender={
						<Button
							type="inverted"
							label="Batch"
							shadow
							onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
				/>

				<ProductTranslationsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					batchForm={batchForm}
					removeBatchListItem={removeBatchListItem}
					addBatchList={addBatchList}
					addProductTranslations={this.addProductTranslationsWrapper}
					updateBatchForm={updateBatchForm}
					product={product}
					editBatchListItem={editBatchListItem}
				/>
			</>
		);
	}
}

ProductTranslations.propTypes = {
	resetState: PropTypes.func,
	batchList: PropTypes.array,
	removeBatchListItem: PropTypes.func,
	batchForm: PropTypes.object,
	addBatchList: PropTypes.func,
	updateBatchForm: PropTypes.func,
	product: PropTypes.object,
	updateProductTranslation: PropTypes.func,
	productTranslation: PropTypes.func,
	originalProductTranslation: PropTypes.object,
	setProductTranslation: PropTypes.func,
	editBatchListItem: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			removeBatchListItem,
			setProductTranslation,
			resetState,
			addBatchList,
			updateProductTranslation,
			editBatchListItem,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		productTranslation: store.productTranslations.data.productTranslation,
		originalProductTranslation:
			store.productTranslations.data.originalProductTranslation,
		batchList: store.productTranslations.data.batchList,
		batchForm: store.productTranslations.data.batchForm,
	};
};

export default connectWithStore(
	ProductTranslations,
	mapStateToProps,
	mapDispatchToProps
);
