export default {
	FROM: 'from',
	TO: 'to',
	BREAK: 'break',
	ALIGNED: 'Aligned',
	UNPLANNED: 'Unplanned',
	CLOCKIN_DEVIATION: {
		PENDING_CONFIRMATION: 'Pending confirmation',
		CONFIRMED: 'Confirmed',
		DECLINED: 'Declined',
	},
};
