import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './profile-picture.css';
import img from './joe-logo.svg';

export default class ProfilePicture extends PureComponent {
	render() {
		const { className, size, center } = this.props;

		const classnames = cx('j-profile-picture', {
			[`${className}`]: className,
			[`j-profile-picture--${size}`]: size
		});

		const classContainer = cx('j-profile-picture__container', {
			[`j-profile-picture__container--center`]: center
		});

		return (
			<div className={classContainer}>
				<div className={classnames}>
					<img src={img} />
				</div>
			</div>
		);
	}
}

ProfilePicture.propTypes = {
	className: PropTypes.string,
	size: PropTypes.string,
	center: PropTypes.bool,
};
