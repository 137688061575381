import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { DragSource, DropTarget } from 'react-dnd';

import _get from 'lodash/get';

class Card extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			isHovering: false
		};

		this.cardRef = React.createRef();
	}

	onMouseEnter() {
		this.setState({ isHovering: true });
	}

	onMouseLeave() {
		this.setState({ isHovering: false });
	}

	handleDeleteClick(e) {
		const { handleDeleteClick, item } = this.props;

		e.preventDefault();
		e.stopPropagation();

		handleDeleteClick(item.id, e);
	}

	renderContent() {
		const { item, setMenuItemInFocus, groupItemInFocus } = this.props;
		const activeGroup =
			groupItemInFocus === item.id ? 'category-item__wrapper--active' : '';

		const style = {
			color: `#${item.title_color}`
		};

		return (
			<div
				className={`category-item__wrapper ${activeGroup}`}
				onClick={() => setMenuItemInFocus(item.id)}>
				<span style={style} className="category-item__text">
					{item.name}
				</span>
				{this.state.isHovering && (
					<div className="category-item__icon-section">
						<div
							onClick={e => this.props.handleEditClick(e)}
							className="category-item__icon-section__icon">
							<span className="icon icon--edit" />
						</div>
						<div
							onClick={e => this.handleDeleteClick(e)}
							className="category-item__icon-section__icon">
							<span className="icon icon--delete" />
						</div>
					</div>
				)}
			</div>
		);
	}

	render() {
		const {
			isDragging,
			connectDragSource,
			connectDropTarget,
			imageAsset
		} = this.props;
		const opacity = isDragging ? 0 : 1;

		return connectDragSource(
			connectDropTarget(
				<div
					ref={this.cardRef}
					style={{ opacity }}
					className="category-item"
					onMouseEnter={() => this.onMouseEnter()}
					onMouseLeave={() => this.onMouseLeave()}>
					{imageAsset ? (
						<img
							className="category-item__image "
							src={_get(imageAsset, 'resolution_one.url', null)}
							alt="Tile image"
						/>
					) : (
						<div className="category-item__image" />
					)}

					{this.renderContent()}
				</div>
			)
		);
	}
}

export default DropTarget(
	'loyaltyCategory',
	{
		hover(props, monitor) {
			const dragIndex = monitor.getItem().index;
			const hoverIndex = props.index;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}

			props.moveCard(dragIndex, hoverIndex);
			monitor.getItem().index = hoverIndex;
		},
		drop(props, monitor) {
			props.onDrop();
		}
	},
	connect => ({
		connectDropTarget: connect.dropTarget()
	})
)(
	DragSource(
		'loyaltyCategory',
		{
			beginDrag: props => ({
				id: props.id,
				index: props.index
			})
		},
		(connect, monitor) => ({
			connectDragSource: connect.dragSource(),
			isDragging: monitor.isDragging()
		})
	)(Card)
);

Card.propTypes = {
	isDragging: PropTypes.bool,
	connectDragSource: PropTypes.func,
	connectDropTarget: PropTypes.func,
	handleDeleteClick: PropTypes.func,
	handleEditClick: PropTypes.func,
	imageAsset: PropTypes.object,
	item: PropTypes.object,
	setMenuItemInFocus: PropTypes.func,
	groupItemInFocus: PropTypes.number
};
