import React from 'react';
import { DragLayer } from 'react-dnd';
import PropTypes from 'prop-types';

import './homeScreenRecommendationsProductsDragLayer.css';

import _get from 'lodash/get';

const layerStyles = {
	position: 'fixed',
	pointerEvents: 'none',
	zIndex: 100,
	left: 0,
	top: 0,
	width: '100%',
	height: '100%',
};

function getItemStyles(props) {
	const { currentOffset } = props;

	if (!currentOffset) {
		return {
			display: 'none',
		};
	}

	const { y } = currentOffset;
	const xPosition = window.event.clientX - 50;
	const transform = `translate(${xPosition}px, ${y}px)`;

	return {
		transform: transform,
		WebkitTransform: transform,
	};
}

const HomeScreenRecommendationsProductsDragLayer = (props) => {
	const { item, isDragging } = props;

	if (!isDragging) {
		return null;
	}

	return (
		<div style={layerStyles}>
			<div style={getItemStyles(props)}>
				<div className="home-screen-recommendations__products-drag-layer">
					<span>{item.name}</span>
				</div>
			</div>
		</div>
	);
};

function collect(monitor) {
	return {
		item: monitor.getItem(),
		itemType: monitor.getItemType(),
		currentOffset: monitor.getSourceClientOffset(),
		isDragging: monitor.isDragging(),
	};
}

HomeScreenRecommendationsProductsDragLayer.propTypes = {
	item: PropTypes.object,
	isDragging: PropTypes.bool,
};

export default DragLayer(collect)(HomeScreenRecommendationsProductsDragLayer);
