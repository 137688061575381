import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Row } from './row.component';
import './row.css';

const RowExpandable = React.memo(
	({
		className,
		children,
		type,
		index,
		onClick,
		renderExpanded,
		totalColSpan,
		renderExpandedHeader,
		expanded,
	}) => {
		const classNames = classnames('report-row', className, {
			[`report-tr--${type}`]: type,
			'report-tr--expandable': renderExpanded,
			'report-tr--expanded': expanded,
			'report-tr--render-expanded-header': expanded && renderExpandedHeader,
		});

		return expanded ? (
			<>
				{renderExpandedHeader !== false && (
					<Row className={classNames} onClick={onClick}>
						{renderExpandedHeader ? (
							<td colSpan={totalColSpan}>{renderExpandedHeader}</td>
						) : (
							children
						)}
					</Row>
				)}
				<tr colSpan={totalColSpan}>
					<td colSpan={totalColSpan}>{renderExpanded(index)}</td>
				</tr>
			</>
		) : (
			<Row className={classNames} onClick={onClick} index={index}>
				{children}
			</Row>
		);
	}
);

RowExpandable.displayName = 'RowExpandable';

RowExpandable.defaultProps = {
	className: '',
	index: null,
	totalColSpan: null,
	renderExpanded: null,
	renderExpandedHeader: false,
};

RowExpandable.propTypes = {
	className: PropTypes.object,
	index: PropTypes.number,
	totalColSpan: PropTypes.number,
	renderExpanded: PropTypes.func,
	onClick: PropTypes.func,
	renderExpandedHeader: PropTypes.string,
	type: PropTypes.string,
	expanded: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export { RowExpandable };
