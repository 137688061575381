import {
	RESET,
	SET_INPUT,
	SET_LOADER
} from './passwordForms.actions';

const defaultState = {
	ui: {
		loader: false
	},
	data: {
		activePassword: '',
		activeRepeat: '',
		resetEmail: ''
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_INPUT: {
			return {
				...state,
				data: {
					...state.data,
					[payload.type]: payload.value
				}
			};
		}

		case SET_LOADER: {
			return {
				...state,
				ui: {
					...state.ui,
					loader: payload
				}
			};
		}

		case RESET: {
			return defaultState;
		}

		default:
			return state;
	}
}

export default reducer;
