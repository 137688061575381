'use strict';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _debounce from 'lodash/debounce';

import './numberOfWeeksSelector.css';

class NumberOfWeeksSelector extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			numberOfWeeksToShow: props.numberOfWeeksToShow
		};

		this.debounceConfig = {
			leading: false,
			trailing: true
		};

		this.debouncedSetNumberOfWeeks = _debounce(
			this.debouncedSetNumberOfWeeks,
			300,
			this.debounceConfig
		);
	}

	changeNumberOfWeeks(operation) {
		const { numberOfWeeksToShow } = this.state;
		if (operation === 'add') {
			this.setNumberOfWeeks(numberOfWeeksToShow + 1);
		} else {
			numberOfWeeksToShow === 1
				? this.setNumberOfWeeks(1)
				: this.setNumberOfWeeks(numberOfWeeksToShow - 1);
		}
	}

	setNumberOfWeeks(numberOfWeeksToShow) {
		this.setState(
			() => ({ numberOfWeeksToShow }),
			() => {
				this.debouncedSetNumberOfWeeks(this.state.numberOfWeeksToShow);
			}
		);
	}

	/**
	 * Debounced function
	 * @param numberOfWeeksToShow
	 */
	debouncedSetNumberOfWeeks(numberOfWeeksToShow) {
		const { setNumberOfWeeksToShow } = this.props;
		setNumberOfWeeksToShow(numberOfWeeksToShow);
	}

	render() {
		const weekSelectorClassnames = classnames('number-of-weeks-selector', {});

		const { numberOfWeeksToShow } = this.state;

		return (
			<div className={weekSelectorClassnames}>
				<div
					className="number-of-weeks-selector__selector"
					data-label="# Weeks to show">
					<i
						onClick={() => this.changeNumberOfWeeks('subtract')}
						className="button icon icon--keyboard_arrow_left"
					/>
					<span>
						{this.props.numberOfWeeksToShow > numberOfWeeksToShow
							? this.props.numberOfWeeksToShow
							: numberOfWeeksToShow}
					</span>
					<i
						onClick={() => this.changeNumberOfWeeks('add')}
						className="button icon icon--keyboard_arrow_right"
					/>
				</div>
			</div>
		);
	}
}

NumberOfWeeksSelector.propTypes = {
	numberOfWeeksToShow: PropTypes.number,
	setNumberOfWeeksToShow: PropTypes.func
};

export default NumberOfWeeksSelector;
