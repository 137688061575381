'use strict';

/**
 * @function initAccordionTranslations
 * @memberOf SERVICES.__models
 * @description
 * Translates accordion labels based on {@link translate} and settings.translationPath
 * @param  {service} translations  {@link translations}
 * @param  {array} accordions  accordions to translate
 */

import _each from 'lodash/each';

module.exports = function(translations, accordions) {
	var settings = this.getSettings();
	_each(accordions, function(accordion) {
		if (!accordion.label) accordion.label = translations.getSingle('HEADERS.' + settings.translationPath + '.' + accordion.name.toUpperCase());
	});
	this.setAccordionsBase(accordions);
};