'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';

// different batch views
import AssociatedIngredientsStepForm from './components/associatedIngredientsBatchStepForm';
import AssociatedIngredientsStepList from './components/associatedIngredientsBatchStepList';

// phrases
import phrases from './../../posConfigurationAssociatedIngredients.phrases';

// utils
import { getFilter, markValuesWithCanAdd } from './../../utils';

// loooodash
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';

// styles
import './associatedIngredientsModalBatch.css';

class AssociatedIngredientsModalBatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};

		this._onSubmitForm = this._onSubmitForm.bind(this);
		this.modalRef = React.createRef();
	}

	_onSubmitForm() {
		const { batchFormData, addBatchList, fetchPosConfigurationIngredientAddons, productVariantId } = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const data = batchFormData.posConfigurationIngredient.map((entry) => {
			return {
				identifier: _uniqueId('row-'),
				posConfigurationIngredient: entry,
				minimum: batchFormData.minimum,
				maximum: batchFormData.maximum,
				default: batchFormData.default,
				deductible: batchFormData.deductible,
				sortOrder: batchFormData.sortOrder,
			};
		});

		// we should get all already added values here
		const filter = getFilter(data);

		fetchPosConfigurationIngredientAddons(productVariantId, { filter })
			.then((res) => {
				const markedValues = markValuesWithCanAdd({
					valuesToAdd: data,
					existingValues: res.data,
				});

				addBatchList(markedValues);

				this._goToStep(2);
			})
			.finally(() =>
				this.setState(() => ({
					loadingModal: false,
				}))
			);
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_batchSubmit() {
		const { batchListData, handleClose, addAssociatedIngredientsWrapper, productVariantId } = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchListData
			.filter((entry) => entry.canAdd)
			.map((entry) => {
				return {
					pos_configuration_ingredient: entry.posConfigurationIngredient?.value?.id,
					pos_configuration_product_variant: productVariantId,
					minimum: entry.minimum,
					default: entry.default,
					maximum: entry.maximum,
					deductible: !!entry.deductible,
					sort_order: entry.sortOrder,
				};
			});

		addAssociatedIngredientsWrapper({ batch: payload })
			.then(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
				handleClose();
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchListData,
			editBatchListItem,
			removeBatchListItem,
			posConfigurationVersionId,
		} = this.props;

		const steps = [
			{
				component: (
					<AssociatedIngredientsStepForm
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						posConfigurationVersionId={posConfigurationVersionId}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE,
				isValid: !_isEmpty(batchFormData.posConfigurationIngredient),
				onNext: this._onSubmitForm,
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
			},
			{
				component: (
					<AssociatedIngredientsStepList
						batchListData={batchListData}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return modalVisible ? (
			<ModalStepper
				className="modal-batch"
				ref={this.modalRef}
				isOpen={modalVisible}
				steps={steps}
				showStep={false}
				onClose={handleClose}
			/>
		) : null;
	}
}

AssociatedIngredientsModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	addBatchList: PropTypes.func,
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addAssociatedIngredientsWrapper: PropTypes.func,
	fetchPosConfigurationIngredientAddons: PropTypes.func,
	productVariantId: PropTypes.number,
	posConfigurationVersionId: PropTypes.number,
};

export default AssociatedIngredientsModalBatch;
