import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './filters.phrases';

/**
 * @function fetchFilters
 * @description Fetch all filter groups
 * @param {object} state
 */
export function fetchFilters(state, filter) {
	const newFilter = state.filter ? `${state.filter};${filter}` : filter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: newFilter,
	};

	return get('/product/filters', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addFilter
 * @description add filter group
 */
export function addFilter(data) {
	return post('/product/filters', data)
		.then((res) => {
			setFeedback(phrases.FILTER_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editFilter
 * @description edit filter group
 */
export function editFilter(data) {
	return post(`/product/filters/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.FILTER_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function editFilters
 * @description edit filter groups
 */
export function editFilters(data) {
	return patch('/product/filters', data)
		.then((res) => {
			setFeedback(phrases.FILTERS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function deleteFilter
 * @description delete filter groups
 */
export function deleteFilter(id) {
	return remove(`/product/filters/${id}`)
		.then((res) => {
			setFeedback(phrases.FILTER_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
