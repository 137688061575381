'use strict';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';

import cx from 'classnames';

import TableDefault from './table.default.component';

class RowSubtable extends PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		const {
			activeInteractionClassName,
			component,
			components,
			data,
			expandedTables,
			report,
			tables,
			tableTitle,
			lazyContent,
			phrases
		} = this.props;

		// Define totalColumns
		let totalColumns;

		// Switch on children type to get total number of columns (groups or columns)
		switch (component.getChildrenType()) {
		case 'group':
			totalColumns = component.content.reduce(
				(acc, value) => acc + value.getLength(),
				0
			);
			break;
		case 'column':
			totalColumns = component.content.length;
			break;
		}

		// Styles for row
		const styles = cx('report-old__subtable', {
			...activeInteractionClassName
		});

		return (
			<tr className={styles}>
				<td colSpan={totalColumns}>
					<TableDefault
						component={
							lazyContent
								? components[component.getLazySubTableKey()]
								: components[data.meta.data.type]
						}
						components={components}
						expandedTables={expandedTables}
						report={report}
						phrases={phrases}
						table={data}
						tables={tables}
						tableTitle={tableTitle}
						lazyContent={lazyContent}
					/>
				</td>
			</tr>
		);
	}
}

RowSubtable.defaultProps = {
	activeInteractionClassName: null
};

RowSubtable.propTypes = {
	activeInteractionClassName: PropTypes.object,
	data: PropTypes.object.isRequired,
	components: PropTypes.object.isRequired,
	component: PropTypes.object.isRequired
};

export default RowSubtable;
