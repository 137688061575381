import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchAllGlobalBundleRuleTranslations
 */
export function fetchAllGlobalBundleRuleTranslations(id, state) {
	const preFilter = `:global_bundle_rule.id=='${id}'`;
	const filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(`/product/global_bundle_rule_translations`, params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addGlobalBundleRuleTranslations
 * @description add
 */
export function addGlobalBundleRuleTranslations(data) {
	return post(`/product/global_bundle_rule_translations`, data)
		.then((res) => {
			setFeedback('Global bundle rule translations added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editGlobalBundleRuleTranslations
 */
export function editGlobalBundleRuleTranslations(data) {
	return post(`/product/global_bundle_rule_translations/${data.id}`, data)
		.then((res) => {
			setFeedback('Global bundle rule translations edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteGlobalBundleRuleTranslation
 */
export function deleteGlobalBundleRuleTranslation(id) {
	return remove(`/product/global_bundle_rule_translations/${id}`)
		.then((res) => {
			setFeedback('Global bundle rule translations deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
