import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';

// import TrainingModulesList from './services/trainingModulesList.service.js';
// import TrainingModuleCategoriesList from './services/trainingModuleCategoriesList.service.js';
import TrainingGradeScalesList from './services/trainingGradeScalesList.service.js';

// const trainingModulesListReduxKey = '/training/modules';
// const trainingModulesList = new TrainingModulesList(trainingModulesListReduxKey);
// const trainingModulesListProps = trainingModulesList.getProps();

// const trainingModuleCategoriesListReduxKey = '/training/module_categories';
// const trainingModuleCategoriesList = new TrainingModuleCategoriesList(trainingModuleCategoriesListReduxKey);
// const trainingModuleCategoriesListProps = trainingModuleCategoriesList.getProps();

const trainingGradeScalesListReduxKey = '/training/grade_scales';
const trainingGradeScalesList = new TrainingGradeScalesList(trainingGradeScalesListReduxKey);
const trainingGradeScalesListProps = trainingGradeScalesList.getProps();

export function ModulesAndExams() {
	useEffect(() => {
		// trainingModulesList.initView();
		// trainingModuleCategoriesList.initView();
		trainingGradeScalesList.initView();
	}, []);

	return (
		<>
			<ListComponent reduxKey={trainingGradeScalesListReduxKey} {...trainingGradeScalesListProps} />
			{/* <ListComponent reduxKey={trainingModuleCategoriesListReduxKey} {...trainingModuleCategoriesListProps} />
			<ListComponent reduxKey={trainingModulesListReduxKey} {...trainingModulesListProps} /> */}
		</>
	);
}
