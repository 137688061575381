import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { store, connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ButtonTile, Overlay, ModalStepper, Button } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';
import UpdateRatesList from './components/updateRatesList/updateRatesList';
import AddNewSalaryComponentForm from './components/addNewSalaryComponent/addNewSalaryComponent';
import AddNewSalaryComponentList from './components/addNewSalaryComponent/addNewSalaryComponentList';

import * as actions from 'reactDataWrapper/reactDataWrapper.actions';

import {
	setEntryUpdateForm,
	editEntryUpdateForm,
	resetEntryUpdateForm,
	editDateUpdate,
	updateBatchForm,
	addBatchList,
	resetBatch,
	editBatchListItem,
	removeBatchListItem,
	editEntryUpdateDelete,
} from './store/editModalChoice.actions';

import { addSalaryComponents } from './store/editModalChoice.service';
import { fetchSalaryGroups } from '../../store/salaryComponents.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import _uniqueId from 'lodash/uniqueId';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import './editModalChoice.css';
import phrases from './editModalChoice.phrases';

const reduxKey = 'salary/salary_groups/batch';

class ModalEditChoices extends Component {
	constructor(props) {
		super(props);

		this.today = moment.utc().format('YYYY-MM-DD');

		this.state = {
			showOverlay: false,
			overlayType: '',
			loadingModal: false,
			activeFilterOn: true,
			activeFilter: `:active.to=ge='${this.today}'`,
		};

		this.modalRef = React.createRef();

		// Binds
		this._goToStep = this._goToStep.bind(this);
		this._setActiveFilter = this._setActiveFilter.bind(this);
		this.onOverlayOpen = this.onOverlayOpen.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.handleToggleOverlay = this.handleToggleOverlay.bind(this);
		this.onHandleClose = this.onHandleClose.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Moneyball Sub position',
				id: 'mon-sub',
				accessor: (d) => _get(d, 'moneyball_sub_position.description', ''),
				filterPath: ':moneyball_sub_position.description',
			},
			{
				Header: 'Market',
				id: 'market',
				accessor: (d) => _get(d, 'market.name', ''),
				filterPath: ':market.name',
			},
			{
				Header: 'From',
				id: 'activefrom',
				accessor: (d) => _get(d, 'active.from', ''),
				filterPath: ':active.from',
			},
			{
				Header: 'To',
				id: 'activto',
				accessor: (d) => _get(d, 'active.to', ''),
				filterPath: ':active.to',
			},
		];
	}

	fetchData(state) {
		return fetchSalaryGroups(state);
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	editStoreEntry(e, type) {
		const { batchOptions } = this.props;

		const value = e && e.target ? e.target.value : e;

		this.props.updateBatchForm({
			...batchOptions,
			...{ [type]: value },
		});
	}

	handleToggleOverlay(type) {
		const { listGlobalSalaryGroups } = this.props;

		if (this.state.showOverlay) {
			switch (this.state.overlayType) {
				case 'salary_group': {
					let selectedRowsInList = _get(listGlobalSalaryGroups, 'ui.selectedRows', []);

					selectedRowsInList = selectedRowsInList.map((x) => {
						return {
							label: x.name,
							value: x,
						};
					});
					this.editStoreEntry(selectedRowsInList, 'salary_group');
					break;
				}
				default:
				// nothing happens :)
			}
		}

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
			overlayType: prevState.overlayType ? '' : type,
		}));
	}

	onOverlayOpen() {
		const { batchOptions } = this.props;
		const { overlayType } = this.state;

		switch (overlayType) {
			case 'salary_group':
				if (batchOptions.salary_group.length) {
					// Copy ingredient cats from select to
					batchOptions.salary_group.map((x) => {
						store.dispatch(
							actions.addRowToSelection({
								reduxKey,
								data: x.value,
							})
						);
					});
				}
				break;
		}
	}

	_onSubmitForm() {
		const { batchOptions, rows } = this.props;

		// Arrange new lsit
		const listArray = batchOptions.salary_group.reduce((acc, x) => {
			const list = rows.map((row) => {
				return {
					identifier: _uniqueId('row-'), // used to identify what to edit on step#2
					id: row.id,
					name: row.name,
					global_salary_component: row.global_salary_component,
					salary_group: x.value,
					amount_type: row.amount_type,
					amount: batchOptions.amount || row.amount,
					frequency: row.frequency,
					active: batchOptions.active,
					external_export_id: row.external_export_id,
				};
			});

			return [...acc, ...list];
		}, []);

		this.props.addBatchList(listArray);

		this._goToStep(4);
	}

	_onSubmitAddList() {
		const { batchList } = this.props;

		const data = batchList.map((x) => {
			return {
				// id: x.id,
				amount: x.amount,
				active_from: x.active.from,
				salary_component: x.id,
				salary_group: x.salary_group.id,
			};
		});

		addSalaryComponents({ batch: data })
			.then((x) => {
				setFeedback('Salary components added', 1);

				x.data.map((x) => {
					store.dispatch(
						actions.addEntry({
							reduxKey: 'salary/salary_components',
							entry: x,
						})
					);
				});

				store.dispatch(actions.resetSelection({ reduxKey: 'salary/salary_components' }));
				this.onHandleClose();
			})
			.catch((err) => {
				const errorMessage = formatErrorMessage(err);
				setFeedback(errorMessage, 0);
			});
	}

	_onSubmitRateUpdates() {
		const { rateEditRows, rateEffectiveFrom } = this.props;

		const batch = rateEditRows.map((x) => {
			return {
				// id: x.id,
				amount: x.amount,
				effective_from: rateEffectiveFrom,
				salary_component: x.id,
			};
		});

		addSalaryComponents({ batch })
			.then((x) => {
				setFeedback('Salary components added', 1);

				x.data.map((x) => {
					const isExistingSalaryComponent = batch.some((entry) => entry.salary_component === x.id);

					if (isExistingSalaryComponent) {
						store.dispatch(
							actions.editEntry({
								reduxKey: 'salary/salary_components',
								entry: x,
							})
						);
					} else {
						store.dispatch(
							actions.addEntry({
								reduxKey: 'salary/salary_components',
								entry: x,
							})
						);
					}
				});

				store.dispatch(actions.resetSelection({ reduxKey: 'salary/salary_components' }));
				this.onHandleClose();
			})
			.catch((err) => {
				const errorMessage = formatErrorMessage(err);
				setFeedback(errorMessage, 0);
			});
	}

	_setActiveFilter() {
		this.setState((prevState) => ({
			activeFilterOn: !prevState.activeFilterOn,
			activeFilter: prevState.activeFilterOn ? '' : `:active.to=ge='${this.today}'`,
		}));
	}

	getAdditionalFilters() {
		const { activeFilterOn } = this.state;

		return (
			<div className="salary-component__filters ">
				<Button type={activeFilterOn ? '' : 'inverted'} shadow title="Filter active" onClick={this._setActiveFilter}>
					Active
				</Button>
			</div>
		);
	}

	onHandleClose() {
		this.props.resetBatch();
		this.props.handleClose();
	}

	render() {
		const {
			modalVisible,
			rows,
			rateEditRows,
			editEntryUpdateForm,
			editDateUpdate,
			resetEntryUpdateForm,
			setEntryUpdateForm,
			batchOptions,
			batchList,
			updateBatchForm,
			editBatchListItem,
			removeBatchListItem,
			rateEffectiveFrom,
			editEntryUpdateDelete,
		} = this.props;
		const { showOverlay } = this.state;

		const isValidStep = true;
		const isValidRates = moment(rateEffectiveFrom, 'YYYY-MM-DD', true).isValid();
		const isValidAddForm = !!((batchOptions.salary_group.length > 0) & !_isEmpty(batchOptions.active));

		const steps = [
			{
				component: (
					<div className="edit-modal-choice__content">
						<ButtonTile icon="print" type="inverted" shadow onClick={() => this._goToStep(3)}>
							{phrases.ADD_NEW_SALARY_COMPONENT}
						</ButtonTile>
						<ButtonTile icon="file_download" type="inverted" shadow onClick={() => this._goToStep(2)}>
							{phrases.UPDATE_RATES}
						</ButtonTile>
					</div>
				),
				title: 'Create new salary components or change existing rates',
				// defaultStyles: true,
				isValid: isValidStep,
				customFooter: <></>,
				loading: false,
			},
			{
				component: (
					<UpdateRatesList
						rows={rows}
						rateEditRows={rateEditRows}
						setEntryUpdateForm={setEntryUpdateForm}
						editEntryUpdateForm={editEntryUpdateForm}
						resetEntryUpdateForm={resetEntryUpdateForm}
						editDateUpdate={editDateUpdate}
						editEntryUpdateDelete={editEntryUpdateDelete}
						rateEffectiveFrom={rateEffectiveFrom}
					/>
				),
				title: phrases.UPDATE_SALARY_RATES,
				isValid: isValidRates,
				onBack: () => this._goToStep(1),
				onNext: () => this._onSubmitRateUpdates(),
				loading: false,
				confirmButtonLabel: 'submit',
				cancelButtonLabel: 'back',
				defaultStyles: false,
				noScroll: true,
			},
			{
				component: (
					<AddNewSalaryComponentForm
						batchOptions={batchOptions}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.handleToggleOverlay}
					/>
				),
				title: 'Copy to new salary group',
				isValid: isValidAddForm,
				onNext: () => this._onSubmitForm(),
				onBack: () => this._goToStep(1),
				loading: false,
				confirmButtonLabel: 'next',
				cancelButtonLabel: 'back',
				noScroll: true,
			},
			{
				component: (
					<AddNewSalaryComponentList
						batchList={batchList}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
					/>
				),
				title: 'Copy to new salary group',
				isValid: true,
				onNext: () => this._onSubmitAddList(),
				onBack: () => this._goToStep(3),
				loading: false,
				confirmButtonLabel: 'Submit',
				cancelButtonLabel: 'back',
				defaultStyles: false,
				noScroll: true,
			},
		];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						className="edit-modal-choice"
						ref={this.modalRef}
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={this.onHandleClose}
					/>
				)}

				<Overlay zIndex={551} height={850} list visible={showOverlay} onClose={this.handleToggleOverlay}>
					<ReactDataWrapper
						title="Salary group"
						className="-striped -highlight"
						columns={this.columns}
						totalEntries={this.state.totalEntries} // Display the total number of pages
						fetchData={this.fetchData} // Request new batchOptions when things change
						filterable
						disableFetchCsvButton
						enableMultiSelection
						batchSelection
						defaultPageSize={20}
						onInitialization={() => this.onOverlayOpen()}
						reduxKey={reduxKey}
						manual
						actionsWidth={0}
						style={{
							maxHeight: '720px',
						}}
						extraFilters={`${this.state.activeFilter}`}
						customAreaComponents={this.getAdditionalFilters()}
					/>
				</Overlay>
			</>
		);
	}
}

ModalEditChoices.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	rows: PropTypes.array,
	rateEffectiveFrom: PropTypes.string,
	rateEditRows: PropTypes.array,

	// overlay
	listGlobalSalaryGroups: PropTypes.object,

	// edit rates/amount
	setEntryUpdateForm: PropTypes.func,
	editEntryUpdateForm: PropTypes.func,
	resetEntryUpdateForm: PropTypes.func,
	editDateUpdate: PropTypes.func,
	editEntryUpdateDelete: PropTypes.func,

	// batchAdd with a twist
	batchOptions: PropTypes.object,
	batchList: PropTypes.array,
	updateBatchForm: PropTypes.func,
	addBatchList: PropTypes.func,
	resetBatch: PropTypes.func,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

ModalEditChoices.defaultProps = {
	modalVisible: false,
	loading: false,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setEntryUpdateForm,
			editEntryUpdateForm,
			resetEntryUpdateForm,
			editDateUpdate,
			editEntryUpdateDelete,

			updateBatchForm,
			addBatchList,
			resetBatch,
			editBatchListItem,
			removeBatchListItem,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		rateEditRows: store.salaryComponentsEditModal.data.rateEditRows,
		rateEffectiveFrom: store.salaryComponentsEditModal.data.rateEffectiveFrom,
		batchOptions: store.salaryComponentsEditModal.data.batchOptions,
		batchList: store.salaryComponentsEditModal.data.batchList,
		listGlobalSalaryGroups: store.listData[reduxKey],
	};
};

export default connectWithStore(ModalEditChoices, mapStateToProps, mapDispatchToProps);
