export const RESET_STATE = 'LOYALTY_ADD_GROUP_MODAL/RESET_STATE';
export const LOADING = 'LOYALTY_ADD_GROUP_MODAL/LOADING';
export const LOADING_PRODUCT = 'LOYALTY_ADD_GROUP_MODAL/LOADING_PRODUCT';
export const EDIT_GROUP = 'LOYALTY_ADD_GROUP_MODAL/EDIT_GROUP';

export function editGroup(payload) {
	return {
		type: EDIT_GROUP,
		payload
	};
}

export function setLoader(isLoading) {
	return {
		type: LOADING,
		payload: isLoading
	};
}

export function setLoaderProduct(isLoading) {
	return {
		type: LOADING_PRODUCT,
		payload: isLoading
	};
}

export function resetState() {
	return {
		type: RESET_STATE
	};
}
