import PropTypes from 'prop-types';
import cx from 'classnames';
import React from 'react';

import './checkboxInput.css';

const CheckboxInput = props => {
	let input;

	const change = e => {
		props.handleChange(e);
	};

	const checkboxCx = cx('checkbox-input', {
		'checkbox-input--checked': props.checked
	});

	return (
		<div className={checkboxCx}>
			<input
				type="checkbox"
				autoComplete="off"
				value={props.value}
				id={props.value}
				disabled={props.disabled}
				checked={props.checked}
				onChange={change}
				onDoubleClick={props.DoubleClickHandler}
				ref={c => {
					if (c) {
						input = c;
						c.indeterminate = props.indeterminate;
					}
				}}
			/>
			<label className="icon icon--check" htmlFor={props.value}>
				<span>{props.text}</span>
			</label>
		</div>
	);
};

CheckboxInput.proptypes = {
	name: PropTypes.string.isRequired,
	checked: PropTypes.bool,
	handleChange: PropTypes.func,
	DoubleClickHandler: PropTypes.func,
	indeterminate: PropTypes.func,
	text: PropTypes.string
};

CheckboxInput.defaultProps = {
	handleChange: () => {}
};

export default CheckboxInput;
