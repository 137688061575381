export const SET_SERACH_MODAL = 'GLOBAL_SEARCH/SET_SERACH_MODAL';
export const SET_SEARCH_RESULTS = 'SET_SERACH_MODAL/SET_SEARCH_RESULTS';

/**
 * @function showSearchModal
 * @param {boolean} payload
 */
export function showSearchModal(payload) {
	return {
		type: SET_SERACH_MODAL,
		payload,
	};
}

/**
 * @function setSearchResults
 * @param {array }searchResults
 */
export function setSearchResults(payload) {
	return {
		type: SET_SEARCH_RESULTS,
		payload,
	};
}
