'use strict';

import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './ingredientConfigurationIngredients.phrases';

/**
 * @function fetchIngredientsConfigurationIngredients
 * @description Fetch all ingredient configuration ingredients
 * @param {object} state
 */
export function fetchIngredientsConfigurationIngredients(
	state,
	ingredientConfigurationId
) {
	const filter = state.filter
		? `${state.filter};:ingredient_configuration.id=='${ingredientConfigurationId}'`
		: `:ingredient_configuration.id=='${ingredientConfigurationId}'`;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(
		'/pos/ingredient_configuration_ingredients',
		params,
		null,
		state.headers
	)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addIngredientConfigurationIngredient
 * @description adds an ingredient configuration
 */
export function addIngredientConfigurationIngredient(data) {
	return post('/pos/ingredient_configuration_ingredients', data)
		.then((res) => {
			setFeedback(phrases.INGREDIENT_CONFIGURATION_INGREDIENT_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editIngredientConfigurationIngredient
 * @description edits ingredient configuration ingredient
 */
export function editIngredientConfigurationIngredient(
	ingredientConfigurationIngredientId,
	data
) {
	return post(
		`/pos/ingredient_configuration_ingredients/${ingredientConfigurationIngredientId}`,
		data
	)
		.then((res) => {
			setFeedback(phrases.INGREDIENT_CONFIGURATION_INGREDIENT_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editIngredientConfigurationIngredients
 * @description edits multiple ingredient configuration ingredients
 */
export function editIngredientConfigurationIngredients(data) {
	return patch('/pos/ingredient_configuration_ingredients', data)
		.then((res) => {
			setFeedback(phrases.INGREDIENT_CONFIGURATION_INGREDIENTS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteIngredientConfigurationIngredient
 * @description deletes an ingredient configuration ingredient
 */
export function deleteIngredientConfigurationIngredient(
	ingredientConfigurationIngredientId
) {
	return remove(
		`/pos/ingredient_configuration_ingredients/${ingredientConfigurationIngredientId}`
	)
		.then((res) => {
			setFeedback(phrases.INGREDIENT_CONFIGURATION_INGREDIENT_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
