/* eslint-disable react/prop-types */
import React from 'react';

// components
import { Input, InputCollectionSelect } from 'dumb';
import { DateFilterSelector, FilterProvider } from 'reactDataWrapper/utilities';

// utils
import collectionSelectEnums from 'services/enums/collectionSelect';

// lodash
import _get from 'lodash/get';

export default function getColumns(reduxKey) {
	return [
		{
			Header: 'Name',
			accessor: 'name',
			id: 'name',
			filterPath: ':name',
		},
		{
			Header: 'Country',
			id: 'country',
			accessor: (d) => _get(d, 'country.name', ''),
			filterPath: ':country.name',
			Filter: ({ column }) => (
				<FilterProvider
					reduxKey={reduxKey}
					columnId={column.id}
					provide={(filterValue, persistToFilterStorage) => (
						<InputCollectionSelect
							id={column.id}
							name="country"
							value={filterValue}
							handleChange={(key, value) => {
								persistToFilterStorage({ handle: key, value });
							}}
							apiPath="/administration/countries"
							params={{
								limit: 30,
							}}
							inputType
							styleType={collectionSelectEnums.TYPE_IN_TABLE}
							optionFormat={(entry) => ({
								value: entry.id,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>
					)}
				/>
			),
		},
		{
			Header: 'Market region',
			id: 'market-region',
			accessor: (d) => _get(d, 'market_region.name', ''),
			filterPath: ':market_region.name',
		},
		{
			Header: 'Tax label',
			id: 'tax-label',
			accessor: 'tax_label',
			filterPath: ':tax_label',
		},
		{
			Header: 'Default break tag',
			id: 'default-break-tag',
			accessor: (d) => _get(d, 'default_break_tag.name', ''),
			filterPath: ':default_break_tag.name',
		},
		{
			Header: 'Language',
			id: 'language',
			accessor: (d) => _get(d, 'language.name', ''),
			filterPath: ':language.name',
		},
		{
			Header: 'Clockin alignment required',
			id: 'clocking-alignment-required',
			accessor: 'clocking_alignment_required',
			filterPath: ':clocking_alignment_required',
			Cell: (d) => (
				<Input
					id={`${d.original.id}-clocking-alignment-required`}
					type="checkbox"
					checked={d.value}
					disabled
				/>
			),
			filterable: false,
		},
		{
			Header: 'Break registration',
			id: 'break-registration',
			accessor: 'break_registration',
			filterPath: ':break_registration',
			Cell: (d) => (
				<Input
					id={`${d.original.id}-break-registration`}
					type="checkbox"
					checked={d.value}
					disabled
				/>
			),
			filterable: false,
		},
		{
			Header: 'Auto close day',
			id: 'auto-close-day',
			accessor: 'auto_close_day',
			filterPath: ':auto_close_day',
			Cell: (d) => (
				<Input
					id={`${d.original.id}-auto-close-day`}
					type="checkbox"
					checked={d.value}
					disabled
				/>
			),
			filterable: false,
		},
		{
			Header: 'Require approval for employee meals',
			id: 'require-approval-for-employee-meals',
			accessor: 'require_approval_for_employee_meals',
			filterPath: ':require_approval_for_employee_meals',
			Cell: (d) => (
				<Input
					id={`${d.original.id}-require-approval-for-employee-meals`}
					type="checkbox"
					checked={d.value}
					disabled
				/>
			),
			filterable: false,
		},
		{
			Header: 'Skip end of day count',
			id: 'skip-end-of-day-count',
			accessor: 'skip_end_of_day_count',
			filterPath: ':skip_end_of_day_count',
			Cell: (d) => (
				<Input
					id={`${d.original.id}-skip-end-of-day-count`}
					type="checkbox"
					checked={d.value}
					disabled
				/>
			),
			filterable: false,
		},
		{
			Header: 'Receipt type',
			id: 'receipt-type',
			accessor: 'receipt_type',
			filterPath: ':receipt_type',
			filterable: false,
		},
		{
			Header: 'Opened',
			id: 'opened',
			accessor: 'opened',
			filterPath: ':opened',
			Filter: ({ column }) => (
				<DateFilterSelector
					reduxKey={reduxKey}
					columnId={column.id}
					dateIdentifier="from"
				/>
			),
		},
		{
			Header: 'Closed',
			accessor: 'closed',
			id: 'closed',
			filterPath: ':closed',
			Filter: ({ column }) => (
				<DateFilterSelector
					reduxKey={reduxKey}
					columnId={column.id}
					dateIdentifier="to"
				/>
			),
		},
	];
}
