import { IColumnHeader } from 'types/reactDataWrapper';

export default function getColumns(): IColumnHeader<IAdministrationCurrencyDenomination>[] {
	const columns: IColumnHeader<IAdministrationCurrencyDenomination>[] = [
		{
			Header: 'Denomination',
			id: 'denomination',
			accessor: 'denomination',
			filterPath: ':denomination',
		},
		{
			Header: 'Type',
			id: 'type',
			accessor: 'type',
			filterPath: ':type',
		},
	];

	return columns;
}
