import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CSSTransition } from 'react-transition-group';

import './buttonFeedback.css';

const ButtonFeedback = props => {
	const { theme, showFeedback } = props;

	const feedbackClassNames = cx('button-feedback icon', {
		'button-feedback--dark': theme === 'dark',
		'button-feedback--light': theme === 'light',
		[`button-feedback--${showFeedback}`]: showFeedback !== null
	});

	return showFeedback === 'success' || showFeedback === 'error' ? (
		<CSSTransition
			classNames="button-feedback-transition"
			mountOnEnter
			unmountOnExit
			timeout={250}
			appear
			in={showFeedback === 'success' || showFeedback === 'error'}>
			<span key="button-feedback" className={feedbackClassNames} />
		</CSSTransition>
	) : null;
};

ButtonFeedback.propTypes = {
	showFeedback: PropTypes.oneOf(['success', 'error', '']),
	theme: PropTypes.string
};

export default ButtonFeedback;
