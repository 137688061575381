'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	removeBatchListItem,
	addBatchList,
	updateBatchForm,
	editBatchListItem,

	// regular stuff
	setWorkplaceSystemSoftwares,
	updateWorkplaceSystemSoftwares,
	resetState,
} from './store/workplaceSystemSoftwares.actions';

// services
import {
	fetchWorkplaceSystemSoftwares,
	editWorkplaceSystemSoftware,
	addWorkplaceSystemSoftwares,
	deleteWorkplaceSystemSoftwares,
	editWorkplaceSystemSoftwares,
} from './workplaceSystemSoftwares.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { InputCollectionSelect, Button, Icon } from 'dumb';
import WorkplaceSystemSoftwaresModalBatch from './components/batch/workplaceSystemSoftwaresModalBatch';
import getColumns from 'reactDataWrapperColumns/administration/workplaceSystemSoftware.columns';
import getSystemSoftwareVersionsColumns from 'reactDataWrapperColumns/administration/systemSoftwareVersions.columns';

// lodash
import _has from 'lodash/has';

// utils
import { getEditedValues } from 'services/utils';

// moment
import moment from 'moment';

// phrases
import phrases from './workplaceSystemSoftwares.phrases';

const reduxKey = '/administration/workplace_system_softwares';
const workplacesTableReduxKey = 'workplace-batch-add/administration/workplaces';

class WorkplaceSystemSoftwares extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment().unix(),
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.addSystemSoftwareWrapper = this.addSystemSoftwareWrapper.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.editMultiple = this.editMultiple.bind(this);

		this.columns = getColumns({
			hasSystemSoftwareId: !!this.props.systemSoftwareId,
		});
	}

	editEntry() {
		const { workplaceSystemSoftwares, originalWorkplaceSystemSoftwares } = this.props;

		const editedValues = getEditedValues({
			oldData: originalWorkplaceSystemSoftwares,
			newData: workplaceSystemSoftwares,
		});

		const payload = {
			id: workplaceSystemSoftwares.id,
			...(_has(editedValues, 'currentVersion')
				? {
						system_software_version: editedValues.currentVersion?.id ?? null,
				  }
				: {}),
		};

		return editWorkplaceSystemSoftware(payload);
	}

	editMultiple(selectedRows) {
		const { workplaceSystemSoftwares } = this.props;

		const payload = {
			...(_has(workplaceSystemSoftwares, 'currentVersion')
				? {
						system_software_version: workplaceSystemSoftwares.currentVersion?.value?.id ?? null,
				  }
				: {}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editWorkplaceSystemSoftwares({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteWorkplaceSystemSoftwares(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			workplace: data.workplace,
			softwareSystem: data.system_software,
			currentVersion: data.system_software_version
				? {
						value: data.system_software_version,
						label: data.system_software_version.version,
				  }
				: null,
			singleEdit: true,
		};

		this.props.setWorkplaceSystemSoftwares(payload);
	}

	getEditableCells() {
		const { workplaceSystemSoftwares, systemSoftwareId } = this.props;

		return [
			...(systemSoftwareId
				? [
						...(workplaceSystemSoftwares.singleEdit
							? [
									{
										header: 'Workplace',
										value: <span>{workplaceSystemSoftwares.workplace?.name}</span>,
									},
							  ]
							: []),
				  ]
				: [
						{
							header: 'System software',
							value: <span>{workplaceSystemSoftwares.softwareSystem?.title}</span>,
						},
				  ]),
			{
				header: 'Current version',
				value: (
					<InputCollectionSelect
						id="current-version"
						placeholder={phrases.MODAL_BATCH_STEP_SYSTEM_SOFTWARE_VERSIONS}
						value={workplaceSystemSoftwares.currentVersion}
						handleChange={(key, value) => this.editStoreEntry('currentVersion', value)}
						cache
						apiPath="/administration/system_software_versions"
						params={{
							limit: 30,
							filter: workplaceSystemSoftwares.singleEdit
								? `:system_software.id=='${workplaceSystemSoftwares.softwareSystem?.id}'`
								: `:system_software.id=='${systemSoftwareId}'`,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.version,
						})}
						inputFilterFormat={(input) => `:title=like='%${input}%'`}
						tableColumns={getSystemSoftwareVersionsColumns()}
						tableTitle={phrases.MODAL_BATCH_STEP_SYSTEM_SOFTWARE_VERSIONS_TABLE_TITLE}
						tableReduxKey="workplace-batch-add/administration/system_softwares"
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const { updateWorkplaceSystemSoftwares } = this.props;

		const updateObject = {
			[name]: e,
		};

		updateWorkplaceSystemSoftwares(updateObject);
	}

	fetchData(state) {
		const { workplaceId, systemSoftwareId } = this.props;

		let filter;
		if (systemSoftwareId) filter = `:system_software.id=='${systemSoftwareId}'`;
		else filter = `:workplace.id=='${workplaceId}'`;

		return fetchWorkplaceSystemSoftwares(state, filter);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	addSystemSoftwareWrapper(data) {
		return addWorkplaceSystemSoftwares(data).then(() =>
			this.setState(() => ({
				key: moment().unix(),
			}))
		);
	}

	render() {
		const {
			batchList,
			removeBatchListItem,
			workplaceId,
			addBatchList,
			updateBatchForm,
			batchForm,
			editBatchListItem,
			systemSoftwareId,
			workplaceTableMarketValue,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					accessAreasAllowedToEdit={[
						'Organisation/Workplaces::Edit',
						'Organization Admin',
						'Global Sales Configuration',
					]}
					reduxKey={systemSoftwareId ? `system-software-${systemSoftwareId}-${reduxKey}` : reduxKey}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					{...(systemSoftwareId
						? {
								editMultiple: this.editMultiple,
								enableMultiSelection: true,
						  }
						: {
								enableSingleSelection: true,
								enableMultiSelection: false,
						  })}
					deleteEntry={this.deleteEntry}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					actionRender={
						<Button type="inverted" label="Batch" shadow onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
				/>

				<WorkplaceSystemSoftwaresModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					removeBatchListItem={removeBatchListItem}
					addWorkplaceSystemSoftwares={this.addSystemSoftwareWrapper}
					workplaceId={workplaceId}
					addBatchList={addBatchList}
					updateBatchForm={updateBatchForm}
					batchForm={batchForm}
					editBatchListItem={editBatchListItem}
					systemSoftwareId={systemSoftwareId}
					fetchWorkplaceSystemSoftwares={fetchWorkplaceSystemSoftwares}
					workplacesTableReduxKey={workplacesTableReduxKey}
					workplaceTableMarketValue={workplaceTableMarketValue}
				/>
			</>
		);
	}
}

WorkplaceSystemSoftwares.propTypes = {
	workplaceSystemSoftwares: PropTypes.object,
	updateWorkplaceSystemSoftwares: PropTypes.func,
	setWorkplaceSystemSoftwares: PropTypes.func,
	resetState: PropTypes.func,
	batchList: PropTypes.array,
	removeBatchListItem: PropTypes.func,
	originalWorkplaceSystemSoftwares: PropTypes.object,
	workplaceId: PropTypes.number,
	addBatchList: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchForm: PropTypes.object,
	editBatchListItem: PropTypes.func,
	systemSoftwareId: PropTypes.number,
	workplaceTableMarketValue: PropTypes.number,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			removeBatchListItem,
			updateWorkplaceSystemSoftwares,
			setWorkplaceSystemSoftwares,
			resetState,
			addBatchList,
			updateBatchForm,
			editBatchListItem,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		workplaceSystemSoftwares: store.workplaceSystemSoftwares.data.workplaceSystemSoftwares,
		originalWorkplaceSystemSoftwares: store.workplaceSystemSoftwares.data.originalWorkplaceSystemSoftwares,
		batchList: store.workplaceSystemSoftwares.data.batchList,
		batchForm: store.workplaceSystemSoftwares.data.batchForm,
		workplaceTableMarketValue:
			store.filterSortColumnsData.tables?.[workplacesTableReduxKey]?.custom?.market?.value ?? undefined,
	};
};

export default connectWithStore(WorkplaceSystemSoftwares, mapStateToProps, mapDispatchToProps);
