'use strict';
import React from 'react';
import ImageCell from 'imageCell';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');
const __enums = require('enums.service');

export default class BadgeLevelsList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'image',
				type: 'imageCell',
				canSort: false,
				canFilter: false,
				imageCell(row) {
					return (
						<ImageCell
							id={row.id}
							image={row.image}
							endpoint={'/hr/badge_levels'}
						/>
					);
				},
			},
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'description',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: false,
			},
			{
				name: 'type',
				type: 'select',
				canAdd: true,
				canEdit: true,
				required: false,
				options: {
					enums: __enums.badgeCategories,
				},
			},
		];
		this.settings = {
			name: 'BadgeLevelsList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			pageTitle: true,
			searchList: {
				canAdd: true,
				canDelete: true,
			},
			canManipulate: () => validAccess(undefined, ['Global BI Configuration']),

			translationPath: 'HR.BADGE_LEVELS',
			endpoint: __endpoints.collection.hr.badge_levels,
		};
		this.init();
		return this;
	}
}
