import React from 'react';

import { InputCollectionSelect } from 'dumb';

// enums
import enums from './productVariantBasketRecommendations.enums';

// lodash
import _get from 'lodash/get';

export function getTableColumns({ editRow }) {
	const getInputCollectionSelect = (row, name, type) => {
		return row.value ? (
			<InputCollectionSelect
				id={row.original.id}
				value={{
					label: row.value.name,
					value: row.value.id,
				}}
				handleChange={(key, value) =>
					editRow({
						name,
						value: _get(value, 'value.id', null),
						basketRecommendationPriorityId:
							type === enums.RECOMMENDATION
								? _get(row, 'original.recommendation_priority.id', null)
								: _get(row, 'original.exclude.id', null),
						type,
					})
				}
				cache
				apiPath="/product/product_variant_groups"
				params={{
					limit: 30,
					filter: `:type=='${type}'`,
				}}
				optionFormat={(entry) => ({
					value: entry,
					label: entry.name,
				})}
				inputFilterFormat={(input) => `:name=like='%${input}%'`}
				size="small"
			/>
		) : null;
	};

	return [
		{
			Header: 'Product Variant',
			id: 'productVariant',
			accessor: (d) => _get(d, 'product_variant.name', ''),
			// this is not actually used but will throw a warning and a notification to the view if left unset
			filterPath: ':product_variant.name',
		},
		{
			Header: 'Product category',
			id: 'productCategory',
			accessor: (d) =>
				_get(d, 'product_variant.product.product_category.name', ''),
			// this is not actually used but will throw a warning and a notification to the view if left unset
			filterPath: ':product_variant.product.product_category.name',
		},
		{
			Header: 'Recommendation',
			id: 'recommendation',
			accessor: (d) => _get(d, 'recommendation_priority.name', ''),
			// this is not actually used but will throw a warning and a notification to the view if left unset
			filterPath: 'recommendation_priority.name',
			sortable: false,
		},
		{
			Header: 'Exclusion',
			id: 'exclusion',
			accessor: (d) => _get(d, 'exclude.name', ''),
			// this is not actually used but will throw a warning and a notification to the view if left unset
			filterPath: ':exclude.name',
			sortable: false,
		},
		{
			Header: 'Rec 1',
			id: 'group_1',
			accessor: (d) => _get(d, 'recommendation_priority.group_1', ''),
			filterable: false,
			sortable: false,
			minWidth: 180,
			Cell: (d) => getInputCollectionSelect(d, 'group_1', enums.RECOMMENDATION),
		},
		{
			Header: 'Rec 2',
			id: 'group_2',
			accessor: (d) => _get(d, 'recommendation_priority.group_2', ''),
			filterable: false,
			sortable: false,
			minWidth: 180,
			Cell: (d) => getInputCollectionSelect(d, 'group_2', enums.RECOMMENDATION),
		},
		{
			Header: 'Rec 3',
			id: 'group_3',
			accessor: (d) => _get(d, 'recommendation_priority.group_3', ''),
			filterable: false,
			sortable: false,
			minWidth: 180,
			Cell: (d) => getInputCollectionSelect(d, 'group_3', enums.RECOMMENDATION),
		},
		{
			Header: 'Rec 4',
			id: 'group_4',
			accessor: (d) => _get(d, 'recommendation_priority.group_4', ''),
			filterable: false,
			sortable: false,
			minWidth: 180,
			Cell: (d) => getInputCollectionSelect(d, 'group_4', enums.RECOMMENDATION),
		},
		{
			Header: 'Rec 5',
			id: 'group_5',
			accessor: (d) => _get(d, 'recommendation_priority.group_5', ''),
			filterable: false,
			sortable: false,
			minWidth: 180,
			Cell: (d) => getInputCollectionSelect(d, 'group_5', enums.RECOMMENDATION),
		},
		{
			Header: 'Rec 6',
			id: 'group_6',
			accessor: (d) => _get(d, 'recommendation_priority.group_6', ''),
			filterable: false,
			sortable: false,
			minWidth: 180,
			Cell: (d) => getInputCollectionSelect(d, 'group_6', enums.RECOMMENDATION),
		},
		{
			Header: 'Rec 7',
			id: 'group_7',
			accessor: (d) => _get(d, 'recommendation_priority.group_7', ''),
			filterable: false,
			sortable: false,
			minWidth: 180,
			Cell: (d) => getInputCollectionSelect(d, 'group_7', enums.RECOMMENDATION),
		},
		{
			Header: 'Rec 8',
			id: 'group_8',
			accessor: (d) => _get(d, 'recommendation_priority.group_8', ''),
			filterable: false,
			sortable: false,
			minWidth: 180,
			Cell: (d) => getInputCollectionSelect(d, 'group_8', enums.RECOMMENDATION),
		},
		{
			Header: 'Rec 9',
			id: 'group_9',
			accessor: (d) => _get(d, 'recommendation_priority.group_9', ''),
			filterable: false,
			sortable: false,
			minWidth: 180,
			Cell: (d) => getInputCollectionSelect(d, 'group_9', enums.RECOMMENDATION),
		},
		{
			Header: 'Rec 10',
			id: 'group_10',
			accessor: (d) => _get(d, 'recommendation_priority.group_10', ''),
			filterable: false,
			sortable: false,
			minWidth: 180,
			Cell: (d) =>
				getInputCollectionSelect(d, 'group_10', enums.RECOMMENDATION),
		},
		{
			Header: 'Rec 11',
			id: 'group_11',
			accessor: (d) => _get(d, 'recommendation_priority.group_11', ''),
			filterable: false,
			sortable: false,
			minWidth: 180,
			Cell: (d) =>
				getInputCollectionSelect(d, 'group_11', enums.RECOMMENDATION),
		},
		{
			Header: 'Rec 12',
			id: 'group_12',
			accessor: (d) => _get(d, 'recommendation_priority.group_12', ''),
			filterable: false,
			sortable: false,
			minWidth: 180,
			Cell: (d) =>
				getInputCollectionSelect(d, 'group_12', enums.RECOMMENDATION),
		},
		{
			Header: 'Rec 13',
			id: 'group_13',
			accessor: (d) => _get(d, 'recommendation_priority.group_13', ''),
			filterable: false,
			sortable: false,
			minWidth: 180,
			Cell: (d) =>
				getInputCollectionSelect(d, 'group_13', enums.RECOMMENDATION),
		},
		{
			Header: 'Rec 14',
			id: 'group_14',
			accessor: (d) => _get(d, 'recommendation_priority.group_14', ''),
			filterable: false,
			sortable: false,
			minWidth: 180,
			Cell: (d) =>
				getInputCollectionSelect(d, 'group_14', enums.RECOMMENDATION),
		},
		{
			Header: 'Rec 15',
			id: 'group_15',
			accessor: (d) => _get(d, 'recommendation_priority.group_15', ''),
			filterable: false,
			sortable: false,
			minWidth: 180,
			Cell: (d) =>
				getInputCollectionSelect(d, 'group_15', enums.RECOMMENDATION),
		},
		{
			Header: 'Exclusion 1',
			id: 'exclusionOne',
			accessor: (d) => _get(d, 'exclude.group_1', ''),
			filterable: false,
			sortable: false,
			minWidth: 180,
			Cell: (d) => getInputCollectionSelect(d, 'group_1', enums.EXCLUSION),
		},
		{
			Header: 'Exclusion 2',
			id: 'exclusionTwo',
			accessor: (d) => _get(d, 'exclude.group_2', ''),
			filterable: false,
			sortable: false,
			minWidth: 180,
			Cell: (d) => getInputCollectionSelect(d, 'group_2', enums.EXCLUSION),
		},
		{
			Header: 'Exclusion 3',
			id: 'exclusionThree',
			accessor: (d) => _get(d, 'exclude.group_3', ''),
			filterable: false,
			sortable: false,
			minWidth: 180,
			Cell: (d) => getInputCollectionSelect(d, 'group_3', enums.EXCLUSION),
		},
	];
}
