export default {
	INGREDIENT_ALLERGENS: 'Ingredient allergens',
	GLOBAL_INGREDIENT_ALLERGENS: 'Global Ingredient allergens',
	INGREDIENT_ALLERGENS_ADDED: 'Ingredient allergens added',
	INGREDIENT_ALLERGEN_EDITED: 'Ingredient allergen edited',
	INGREDIENT_ALLERGENS_EDITED: 'Ingredient allergens edited',
	INGREDIENT_ALLERGENS_DELETED: 'Ingredient allergens deleted',

	// BATCH
	MODAL_BATCH_INGREDIENT: 'Ingredient',
	MODAL_BATCH_INGREDIENT_PLACEHOLDER: 'Select ingredients...',
	MODAL_BATCH_INGREDIENT_TABLE_NAME: 'Ingredients',
	MODAL_BATCH_MARKET: 'Market',
	MODAL_BATCH_MARKET_PLACEHOLDER: 'Select markets...',
	MODAL_BATCH_MARKET_TABLE_NAME: 'Markets',
	MODAL_BATCH_DEGREE: 'Degree',
	MODAL_BATCH_DEGREE_PLACEHOLDER: 'Select degree...',
	MODAL_BATCH_ALLERGEN: 'Allergen',
	MODAL_BATCH_ALLERGEN_PLACEHOLDER: 'Select allergen...',
	MODAL_BATCH_STEP_FORM_TITLE: 'Select entries',
	MODAL_BATCH_STEP_FORM_NEXT_BUTTON_LABEL: 'Next',

	// BATCH LIST
	MODAL_BATCH_STEP_LIST_TITLE: 'Confirm entries',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Submit',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'Back',
};
