import React from 'react';
import PropTypes from 'prop-types';

// Components
import ProductVariant from './../product/components/productVariant/productVariant.component';
import Ingredients from './../ingredients/components/ingredients/ingredients.component';

const ProductContainer = ({ haveSalesConfigurationReadOnlyAccess }) => {
	return (
		<>
			<ProductVariant
				haveSalesConfigurationReadOnlyAccess={haveSalesConfigurationReadOnlyAccess}
			/>
			<Ingredients />
		</>
	);
};

ProductContainer.propTypes = {
	haveSalesConfigurationReadOnlyAccess: PropTypes.bool,
};

export default ProductContainer;
