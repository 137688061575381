'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// regular stuff
	setPosConfigurationVersionCurrency,
	updatePosConfigurationVersionCurrency,
	resetState,
} from './store/posConfigurationVersionCurrencies.actions';

// services
import {
	fetchPosConfigurationVersionCurrencies,
	editPosConfigurationVersionCurrency,
	addPosConfigurationVersionCurrency,
	deletePosConfigurationVersionCurrency,
} from './posConfigurationVersionCurrencies.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';

import { Input, InputCollectionSelect } from 'dumb';

// lodash
import _get from 'lodash/get';

// phrases/constants
import phrases from './posConfigurationVersionCurrencies.phrases';

const reduxKey = '/pos/pos_configuration_currencies';

class PosConfigurationVersionCurrencies extends Component {
	constructor(props) {
		super(props);

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.fetchData = this.fetchData.bind(this);

		this.columns = [
			{
				Header: 'Currency',
				id: 'currency',
				accessor: (d) => _get(d, 'currency.code', ''),
				filterPath: ':currency.code',
			},
			{
				Header: 'Markup',
				id: 'markup',
				accessor: (d) => `${d.markup}%`,
				filterPath: ':markup',
			},
		];
	}

	addEntry() {
		const {
			defaultPosConfigurationVersionCurrency,
			posConfigurationVersionId,
		} = this.props;

		const payload = {
			id: defaultPosConfigurationVersionCurrency.id,
			pos_configuration_version: posConfigurationVersionId,
			currency: _get(
				defaultPosConfigurationVersionCurrency,
				'currency.value',
				null
			),
			markup: defaultPosConfigurationVersionCurrency.markup.replace('%', ''),
		};

		return addPosConfigurationVersionCurrency(payload);
	}

	editEntry() {
		const { defaultPosConfigurationVersionCurrency } = this.props;

		const payload = {
			id: defaultPosConfigurationVersionCurrency.id,
			markup: defaultPosConfigurationVersionCurrency.markup.replace('%', ''),
		};

		return editPosConfigurationVersionCurrency(payload);
	}

	deleteEntry(id) {
		return deletePosConfigurationVersionCurrency(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			currency: data.currency.code,
			markup: data.markup,
			editMode: true,
		};

		this.props.setPosConfigurationVersionCurrency(payload);
	}

	getEditableCells() {
		const { defaultPosConfigurationVersionCurrency } = this.props;

		const editMode = !!defaultPosConfigurationVersionCurrency.editMode;

		return [
			...(editMode
				? [
						{
							header: 'Currency',
							value: (
								<span>{defaultPosConfigurationVersionCurrency.currency}</span>
							),
						},
				  ]
				: [
						{
							header: 'Currency',
							value: (
								<InputCollectionSelect
									id="currency"
									placeholder="Select currency..."
									value={defaultPosConfigurationVersionCurrency.currency}
									handleChange={(key, value) =>
										this.editStoreEntry('currency', value)
									}
									cache
									apiPath="/administration/currencies"
									params={{
										limit: 30,
									}}
									optionFormat={(entry) => ({
										value: entry.id,
										label: entry.code,
									})}
									inputFilterFormat={(input) => `:code=like='%${input}%'`}
								/>
							),
						},
				  ]),
			{
				header: 'Markup',
				value: (
					<Input
						id="markup"
						placeholder="Enter markup in %..."
						value={this.getMarkupValue(
							defaultPosConfigurationVersionCurrency.markup
						)}
						onChange={(event) => this.editStoreEntry('markup', event)}
					/>
				),
			},
		];
	}

	getMarkupValue(markup) {
		if (!markup) return '';

		// remove % if present
		markup = markup.replace('%', '');

		return `${markup}%`;
	}

	editStoreEntry(name, e) {
		let value;

		if (e === null) value = e;
		else value = e.target ? e.target.value : e;

		const updateObject = {
			[name]: value,
		};

		this.props.updatePosConfigurationVersionCurrency(updateObject);
	}

	fetchData(state) {
		const { posConfigurationVersionId } = this.props;

		const extraFilter = `:pos_configuration_version.id=='${posConfigurationVersionId}'`;

		return fetchPosConfigurationVersionCurrencies(state, extraFilter);
	}

	render() {
		return (
			<ReactDataWrapper
				accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.TABLE_TITLE}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={5}
				reduxKey={`${reduxKey}-${this.props.posConfigurationVersionId}`}
				manual
				createEntry={this.addEntry}
				editEntry={this.editEntry}
				deleteEntry={this.deleteEntry}
				setInitialEditValues={this.setInitialEditValues}
				editableCells={this.getEditableCells()}
				onModalClose={this.props.resetState}
				actionsWidth={30}
			/>
		);
	}
}

PosConfigurationVersionCurrencies.propTypes = {
	defaultPosConfigurationVersionCurrency: PropTypes.object,
	updatePosConfigurationVersionCurrency: PropTypes.func,
	setPosConfigurationVersionCurrency: PropTypes.func,
	resetState: PropTypes.func,
	posConfigurationVersionId: PropTypes.number,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updatePosConfigurationVersionCurrency,
			setPosConfigurationVersionCurrency,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultPosConfigurationVersionCurrency:
			store.salesConfigurationPosConfigurationVersionCurrencies.data
				.defaultPosConfigurationVersionCurrency,
	};
};

export default connectWithStore(
	PosConfigurationVersionCurrencies,
	mapStateToProps,
	mapDispatchToProps
);
