'use strict';

import React from 'react';

import WorkplacePosConfiguration from './components/workplacePosConfiguration/workplacePosConfiguration.component';

const Workplace = () => {
	return <WorkplacePosConfiguration />;
};

export default Workplace;
