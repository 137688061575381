export default {
	TITLE: 'Onboardings',
	ONBOARDING_FILTER_UNSIGNED: 'Unsigned',
	ONBOARDING_FILTER_RECEIVED: 'Received',
	TOOLTIP: 'Sign onboarding',
	ONBOARDING_FILTER_EXPIRED: 'Expired',
	CSV_TOOLTIP: 'Click to get onboardings csv',
	EXPORT_ONBOARDING_TOOLTIP: 'Click to get onboardings export csv',
	NO_EMPLOYMENT_POSITION: 'No active Employment position, cannot send document!',
	ABORT_CONFIRMATION_HEADER: 'Abort onboarding',
	ABORT_CONFIRMATION_SUBHEADER: 'Are you sure you want to abort this onboarding?',
	ABORT_CONFIRMATION_CONFIRM: 'Yes',
	ABORT_CONFIRMATION_CANCEL: 'No',
};
