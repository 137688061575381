export default {
	TABLE_TITLE: 'Pos Configuration ingredient variation pricings',
	INGREDIENT_PRICING_VARIANT_EDITED: 'Ingredient variation pricing edited.',
	INGREDIENT_PRICING_VARIANT_DELETED: 'Ingredient variation pricing deleted.',
	INGREDIENT_PRICING_VARIANT_ADDED: 'Ingredient variation pricing added',

	MODAL_BATCH_STEP_FORM_TITLE: 'Batch add',
	MODAL_BATCH_STEP_POS_CONFIGURATION_VERSION: 'POS configuration version',
	MODAL_BATCH_STEP_INGREDIENT_VARIANT: 'Ingredient variant',
	MODAL_BATCH_STEP_SORT_ORDER: 'Sort order',
	MODAL_BATCH_STEP_FORM_POS_CONFIGURATION_VERSIONS_TABLE_ACTIVE_FILTER:
		'Active',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'Next',

	MODAL_BATCH_STEP_LIST_TITLE: 'Confirm values',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_FORM_POS_CONFIGURATION_VERSIONS_TABLE_TITLE:
		'POS configuration versions',

	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Submit',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'Back'
};
