'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _get from 'lodash/get';
import GoldModelDataDetails from './goldModelDataDetails.component.js';

class GoldModelRow extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeDetail: null
		};

		this.onDataDetailsClick = this.onDataDetailsClick.bind(this);
		this.onDataDetailsCloseHandler = this.onDataDetailsCloseHandler.bind(this);
	}

	onDataDetailsClick(key) {
		this.props.isModalActive();
		this.setState({ activeDetail: key });
	}

	onDataDetailsCloseHandler() {
		this.props.isModalActive();
		this.setState({ activeDetail: null });
	}

	renderCells() {
		const { contentType, row, name, workplace } = this.props;

		// Map keys from row object
		return Object.keys(row).map(key => {
			const storeTags = _get(row[key], 'workplace.active_store_tags', []);

			const classnames = cx('list__cell', {
				'list__cell--has-tags': storeTags.length > 0,
				'list__cell--adjusted': row[key].adjusted === true,
				'list__cell--row-header': key === 'rowName'
			});

			// Handle date cell as part of self containing table
			if (key === 'rowName')
				return (
					<td className={classnames} key={key}>
						<span className="list__cell-contents">{row[key]}</span>
					</td>
				);
			else
				return (
					<td key={key} className={classnames}>
						<span
							className="list__cell-contents list__cell-contents--currency"
							onClick={this.onDataDetailsClick.bind(
								this,
								`open-${key}-${name}`
							)}>
							{numeral(parseFloat(row[key][contentType])).format('0,0')}
							<GoldModelDataDetails
								key={key + 'details'}
								data={Object.assign({}, row[key], {
									date: key,
									workplace: workplace
								})}
								visible={this.state.activeDetail === `open-${key}-${name}`}
								title={`${name}, ${key}`}
								handleClose={this.onDataDetailsCloseHandler}
								modifierClassName="gold-model"
								{...this.props}
							/>
						</span>
					</td>
				);
		});
	}

	render() {
		return <tr className="list__row">{this.renderCells()}</tr>;
	}
}

GoldModelRow.defaultProps = {
	contentType: 'turnover'
};

GoldModelRow.propTypes = {
	row: PropTypes.object.isRequired,
	contentType: PropTypes.string,
	isModalActive: PropTypes.func.isRequired
};

export default GoldModelRow;
