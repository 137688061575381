export const RESET_STATE = 'POS_CONFIGURATION_TILE_LAYOUT_GROUP_TRANSLATIONS/RESET_STATE';
export const SET_POS_CONFIGURATION_TILE_LAYOUT_GROUP_TRANSLATION =
	'POS_CONFIGURATION_TILE_LAYOUT_GROUP_TRANSLATIONS/SET_POS_CONFIGURATION_TILE_LAYOUT_GROUP_TRANSLATION';
export const UPDATE_POS_CONFIGURATION_TILE_LAYOUT_GROUP_TRANSLATION =
	'POS_CONFIGURATION_TILE_LAYOUT_GROUP_TRANSLATIONS/UPDATE_POS_CONFIGURATION_TILE_LAYOUT_GROUP_TRANSLATION';

export const EDIT_BATCH_LIST_ITEM = 'POS_CONFIGURATION_TILE_LAYOUT_GROUP_TRANSLATIONS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM = 'POS_CONFIGURATION_TILE_LAYOUT_GROUP_TRANSLATIONS/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW = 'POS_CONFIGURATION_TILE_LAYOUT_GROUP_TRANSLATIONS/ADD_BATCH_LIST_ITEM_ROW';
export const RERENDER_TABLE = 'POS_CONFIGURATION_TILE_LAYOUT_GROUP_TRANSLATIONS/RERENDER_TABLE';

export function setPosConfigurationTileLayoutGroupTranslation(payload) {
	return {
		type: SET_POS_CONFIGURATION_TILE_LAYOUT_GROUP_TRANSLATION,
		payload,
	};
}

export function updatePosConfigurationTileLayoutGroupTranslation(payload) {
	return {
		type: UPDATE_POS_CONFIGURATION_TILE_LAYOUT_GROUP_TRANSLATION,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}

export function rerenderTable() {
	return {
		type: RERENDER_TABLE,
	};
}
