'use strict';

import React, { PureComponent } from 'react';

import { store, connectWithStore } from 'appState';

import DynamicPosViewFactory from './factory/dynamicPosView.factory';
import DynamicPosViewComponent from './dynamicPosView.component';

import './dynamicPosView.css';

class DynamicPosViewContainer extends PureComponent {
	constructor(props) {
		super(props);

		this.dynamicPosView = DynamicPosViewFactory.dynamicPosView(
			props.report.parameters,
			props.report.filter
		);
	}

	render() {
		const {
			marketWorkplaceTables,
			marketHourlyTables,
			marketProductTables,
			workplaceHourlyTables,
			workplaceProductTables,
			expandedTables
		} = this.props;

		const dynamicPosViewState = {
			marketWorkplaceTables,
			marketHourlyTables,
			marketProductTables,
			workplaceHourlyTables,
			workplaceProductTables
		};

		return (
			<DynamicPosViewComponent
				{...this.props}
				phrases={this.dynamicPosView.getPhrases()}
				dynamicPosView={this.dynamicPosView}
				dynamicPosViewState={dynamicPosViewState}
				expandedTables={expandedTables}
			/>
		);
	}
}

DynamicPosViewContainer.defaultProps = {
	expandedTables: {}
};

const mapStateToProps = store => ({
	marketWorkplaceTables: store.dynamicPosView.marketWorkplaceTables,
	marketHourlyTables: store.dynamicPosView.marketHourlyTables,
	marketProductTables: store.dynamicPosView.marketProductTables,
	workplaceHourlyTables: store.dynamicPosView.workplaceHourlyTables,
	workplaceProductTables: store.dynamicPosView.workplaceProductTables,
	expandedTables: store.dynamicPosView.expandedTables
});

export default connectWithStore(
	DynamicPosViewContainer,
	mapStateToProps
);
