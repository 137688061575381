'use strict';

/**
 * @namespace __headerHash
 * @memberOf SERVICES
 * @description
 * Service for returning unique hash based on header (be it from a list or whatever)
 */

import _has from 'lodash/has';

/////////////
// Private //
/////////////

/**
 * @function hash
 * @description Generate hash from string
 * @param {string} string string to create hash from
 * @memberOf SERVICES.__headerHash
 * @private
 */
function hash(string) {
	var hash = 0, i, chr, len;
	if (string.length == 0) return hash;
	for (i = 0, len = string.length; i < len; i++) {
		chr = string.charCodeAt(i);
		hash = ((hash << 5) - hash) + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return Math.abs(hash);
}

////////////
// Public //
////////////

/**
 * @function headerHash
 * @description Generate headerHash based on header object
 * @param {object} header
 * @memberOf SERVICES.__headerHash
 */
function headerHash(header) {
	if (_has(header, 'reference') && _has(header.reference, 'translationKey')) return hash(header.name + header.reference.translationKey);
	else if (_has(header, 'reference')) return hash(header.name + header.reference.key);
	else return hash(header.name);
}

module.exports = headerHash;