import React, { useState } from 'react';

// interfaces/ enums
import { SelectOption } from 'types/select';
import { ModalTypeEnums } from '../productVariantNutritionAllergenInfo.enums';

// components
import { Box, useTheme, Button } from '@mui/material';
import { InputCollectionSelect, SingleDatePickerInput } from 'dumb';

// misc
import phrases from '../productVariantNutritionAllergenInfo.phrases';
import constants from 'services/constants';
import { Moment } from 'moment';

type Props = {
	onPageChange: (workplace: SelectOption, date: string, pageType: ModalTypeEnums) => void;
};

const ProductVariantNutritionAllergenInfoPageOne = ({ onPageChange }: Props): JSX.Element => {
	const theme = useTheme();

	const [date, setDate] = useState<Moment | null>(null);
	const [workplace, setWorkplace] = useState<SelectOption | null>(null);

	const handleSetDate = (date: Moment): void => setDate(date);
	const handleSetWorkplace = (_: string, value: SelectOption | null): void => setWorkplace(value);
	const onButtonClick = (pageType: ModalTypeEnums) => (): void => {
		if (!workplace || !date) return;

		onPageChange(workplace, date.format(constants.shortDate), pageType);
	};

	const buttonsDisabled = !workplace || !date;

	return (
		<Box>
			<SingleDatePickerInput
				id="date"
				label={phrases.DATE_LABEL}
				placeholder={phrases.DATE_PLACEHOLDER}
				onChange={handleSetDate}
				selectedDate={date?.format(constants.shortDate) ?? ''}
				noClockButton
				zIndex={theme.zIndex.modal + 1}
			/>

			<Box mt={1}>
				<InputCollectionSelect
					id="workplace"
					label={phrases.WORKPLACE_LABEL}
					placeholder={phrases.WORKPLACE_PLACEHOLDER}
					value={workplace}
					handleChange={handleSetWorkplace}
					cache
					apiPath="/organization/workplaces"
					params={{
						limit: 30,
					}}
					optionFormat={(entry: Record<string, any>): SelectOption => ({
						value: entry.id,
						label: entry.name,
					})}
					inputFilterFormat={(input: string): string => `:name=like='%${input}%'`}
				/>
			</Box>

			<Box
				mt={6}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Button onClick={onButtonClick(ModalTypeEnums.PAGE_TWO_LIST)} disabled={buttonsDisabled}>
					{phrases.VIEW_ALLERGENS_NUTRITION_LABEL}
				</Button>
			</Box>
		</Box>
	);
};

export default ProductVariantNutritionAllergenInfoPageOne;
