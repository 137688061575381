import React from 'react';
import PropTypes from 'prop-types';

// components
import { ButtonTile } from 'dumb';
import { ModalRow } from 'dumb/modal';

// phrases/ enums
import phrases from './../sendDocuSignDocument.phrases';
import enums from './../sendDocuSignDocument.enums';

const SendDocuSignDocumentType = ({ setType }) => {
	return (
		<ModalRow className="send-docu-sign-document__type-wrapper">
			<ButtonTile
				icon="add"
				type="inverted"
				shadow
				onClick={() => setType(enums.SEND_CONTRACT)}>
				{phrases.SEND_CONTRACT}
			</ButtonTile>
			<ButtonTile
				icon="add"
				type="inverted"
				shadow
				onClick={() => setType(enums.SEND_APPENDIX)}>
				{phrases.SEND_APPENDIX}
			</ButtonTile>
		</ModalRow>
	);
};

SendDocuSignDocumentType.propTypes = {
	setType: PropTypes.func,
};

export default SendDocuSignDocumentType;
