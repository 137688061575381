'use strict';

import { HotbarComponentAbstract } from 'hotdamnbar/factory';

/**
 * Separator
 */
class Separator extends HotbarComponentAbstract {

	constructor() {
		super('separator', false);

		// Set type
		this.type = 'separator';
	}

}

export default Separator;