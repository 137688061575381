'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Password extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { disabled, header, onChange, onKeyDown, translations } = this.props;

		return (
			<span>
				<input
					type="password"
					ref="password"
					autoComplete="off"
					autoFocus={header.focus}
					onChange={onChange}
					onKeyDown={onKeyDown}
					name={header.name}
					placeholder={header.label}
					disabled={disabled}
				/>
				<br />
				<input
					type="password"
					ref="passwordCheck"
					autoComplete="off"
					onChange={onChange}
					onKeyDown={onKeyDown}
					placeholder={header.label + translations.INPUT__REPEAT}
					disabled={disabled}
				/>
			</span>
		);
	}
}

Password.propTypes = {
	disabled: PropTypes.bool,
	header: PropTypes.object,
	onChange: PropTypes.func,
	onKeyDown: PropTypes.func,
	translations: PropTypes.object
};

export default Password;
