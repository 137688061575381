export const SET_COLLECTION = 'SALARY_EXPORTS/SET_COLLECTION';
export const SET_DATERANGE = 'SALARY_EXPORTS/SET_DATERANGE';
export const SET_HOTBAR_FILTER = 'SALARY_EXPORTS/SET_HOTBAR_FILTER';
export const SET_SHOW_SALARY_EXPORTS = 'SALARY_EXPORTS/SET_SHOW_SALARY_EXPORTS';
export const SET_SHOW_LOADER = 'SALARY_EXPORTS/SET_SHOW_LOADER';

/// ///////////////////////////////
// Action dispatchers / Setters //
/// ///////////////////////////////
/**
 * @param {array} collection
 */
export function setCollection(payload) {
	return {
		type: SET_COLLECTION,
		payload,
	};
}

/**
 * @param {object} dateRange
 */
export function setDateRange(dateRange) {
	return {
		type: SET_DATERANGE,
		payload: dateRange,
	};
}

/**
 * @param {array} filter
 */
export function setHotBarFilter(filter) {
	return {
		type: SET_HOTBAR_FILTER,
		payload: filter,
	};
}

/**
 * @param {array} showSalaryExports
 */
export function setShowSalaryExports(payload) {
	return {
		type: SET_SHOW_SALARY_EXPORTS,
		payload,
	};
}

export function setShowLoader(payload) {
	return {
		type: SET_SHOW_LOADER,
		payload,
	};
}
