'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');

export default class BanksList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
		];
		this.settings = {
			name: 'BanksList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			pageTitle: true,
			searchList: {
				canAdd: true,
				canDelete: true,
			},
			canManipulate: () => validAccess(undefined, ['Global BI Configuration']),
			translationPath: 'ADMINISTRATION.BANKS',
			endpoint: __endpoints.collection.administration.banks,
		};
		this.init();
		return this;
	}
}
