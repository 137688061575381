'use strict';

import PropTypes from 'prop-types';
import React from 'react';

import RowEntry from './row.entry.component';
import RowSubtable from './row.subtable.component';

const BodyUnfoldedInverted = props => {
	const { component, tables, table } = props;

	/**
	 * Parses body from table content and layout object
	 */
	const parseBody = table.content.map(row => {
		let uniqueRowKey = `${row.meta.data.unique_identifier}_Entry`;
		let uniqueTableKey = `${row.meta.data.unique_identifier}_Table`;

		return [
			<RowSubtable
				key={uniqueTableKey}
				activeInteractionClassName={{
					'report-old__subtable--unfolded-inverted': true
				}}
				data={row}
				{...props}
			/>,
			<RowEntry
				key={uniqueRowKey}
				tables={tables}
				activeInteractionClassName={{
					'report-old__entry--unfolded-inverted': true
				}}
				context="body"
				data={row.total.data}
				meta={row}
				component={component}
			/>
		];
	});

	return <tbody>{parseBody}</tbody>;
};

BodyUnfoldedInverted.propTypes = {
	table: PropTypes.object.isRequired,
	tables: PropTypes.object.isRequired
};

export default BodyUnfoldedInverted;
