import {
	UPDATE_WORKPLACE_SYSTEM_SOFTWARES,
	SET_WORKPLACE_SYSTEM_SOFTWARES,
	RESET_STATE,
	ADD_BATCH_LIST,
	REMOVE_BATCH_LIST_ITEM,
	UPDATE_BATCH_FORM,
	EDIT_BATCH_LIST_ITEM,
} from './workplaceSystemSoftwares.actions';

// utils
import _uniqueId from 'lodash/uniqueId';

const defaultState = {
	data: {
		workplaceSystemSoftwares: {},
		originalWorkplaceSystemSoftwares: {},
		batchForm: {},
		batchList: [
			{
				identifier: _uniqueId('row-'),
			},
		],
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_WORKPLACE_SYSTEM_SOFTWARES: {
			return {
				data: {
					...state.data,
					workplaceSystemSoftwares: payload,
					originalWorkplaceSystemSoftwares: payload,
				},
			};
		}

		case UPDATE_WORKPLACE_SYSTEM_SOFTWARES: {
			return {
				data: {
					...state.data,
					workplaceSystemSoftwares: {
						...state.data.workplaceSystemSoftwares,
						...payload,
					},
				},
			};
		}

		case RESET_STATE: {
			return {
				...defaultState,
			};
		}

		case ADD_BATCH_LIST: {
			return {
				...state,
				data: {
					...state.data,
					batchList: payload,
				},
			};
		}

		case REMOVE_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchList: state.data.batchList.filter(
						(entry) => entry.identifier !== payload
					),
				},
			};
		}

		case UPDATE_BATCH_FORM: {
			return {
				...state,
				data: {
					...state.data,
					batchForm: {
						...state.data.batchForm,
						...payload,
					},
				},
			};
		}

		case EDIT_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchList: state.data.batchList.map((entry) => {
						if (entry.identifier === payload.identifier) {
							return { ...entry, ...payload };
						}
						return entry;
					}),
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
