'use strict';

import { HotbarComponentFactory as h } from 'hotdamnbar/factory';
import {
	HotbarEndpointDataProvider,
	HotbarStaticDataProvider
} from 'hotdamnbar/dataProviders';

const ReportingCurrenciesDataProvider = new HotbarEndpointDataProvider(
	'/reporting/reporting_currencies'
);

const MarketsDataProvider = new HotbarEndpointDataProvider(
	'/organization/markets'
);
MarketsDataProvider.setSortOrder(':sort_order+');
MarketsDataProvider.setTypingFilterParameter('market.name');

const StoresDataProvider = new HotbarEndpointDataProvider(
	'/shiftplanning/workplaces',
	{ market: 'market.id' },
	entry => {
		if (entry.state === 'Operating' && entry.type === 'Store') {
			return { value: entry.id, label: entry.name, market: entry.market };
		}
	}
);
StoresDataProvider.setSortOrder(':sort_order+');
StoresDataProvider.setTypingFilterParameter('workplace.name');

// Week days
const WeekdaysDataProvider = new HotbarStaticDataProvider([
	{
		value: 0,
		label: 'Monday'
	},
	{
		value: 1,
		label: 'Tuesday'
	},
	{
		value: 2,
		label: 'Wednesday'
	},
	{
		value: 3,
		label: 'Thursday'
	},
	{
		value: 4,
		label: 'Friday'
	},
	{
		value: 5,
		label: 'Saturday'
	},
	{
		value: 6,
		label: 'Sunday'
	}
]);

// Add relations

// Set option format
ReportingCurrenciesDataProvider.setOptionFormat((entry, resultSet) => ({
	value: entry.id,
	label: entry.currency.code
}));

// Market / STores som i nuværende pos report
const PosReportHotbar = h.hotbar('posReportHotbar');
PosReportHotbar.add(h.select('Markets', false, MarketsDataProvider, true));
PosReportHotbar.add(h.select('Stores', false, StoresDataProvider, true), {
	market: 'Markets'
});
PosReportHotbar.add(h.select('Week Days', false, WeekdaysDataProvider, true));
PosReportHotbar.add(h.dateRange('Period', true));
PosReportHotbar.add(
	h.select('Reporting Currency', true, ReportingCurrenciesDataProvider)
);

export default PosReportHotbar;
