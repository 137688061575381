export const RESET_BASKET_RECOMMENDATION_PRIORITY =
	'SALES_CONFIGURATION_PRODUCT_BUNDLES/RESET_BASKET_RECOMMENDATION_PRIORITY';
export const UPDATE_BATCH_FORM =
	'SALES_CONFIGURATION_PRODUCT_BUNDLES/UPDATE_BATCH_FORM';

/**
 * @param {object} payload
 */
export function resetBasketRecommendationPriority() {
	return {
		type: RESET_BASKET_RECOMMENDATION_PRIORITY,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}
