'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { Card, Button } from 'dumb';

import ReorderProductTileLayoutTile from './components/reorderProductTileLayoutTile/reorderProductTileLayoutTile';
import AppLayoutLoader from 'modules/structure/salesConfiguration/components/layout/pos/appLayout/components/appLayoutLoader/appLayoutLoader';

// utils
import { withDragDropContext } from 'utils';

// lodash
import _get from 'lodash/get';

// phrases
import phrases from './../../reorderProducts.phrases';

// styles
import './reorderProductTileLayoutTiles.css';

class ReorderProductTileLayoutTiles extends Component {
	constructor(props) {
		super(props);

		this.moveCard = this.moveCard.bind(this);
	}

	moveCard(dragIndex, hoverIndex) {
		const { updateTileLayoutTilePosition } = this.props;

		updateTileLayoutTilePosition({ dragIndex, hoverIndex });
	}

	render() {
		const {
			removeTile,
			fetching,
			batchForm,
			submitGroup,
			groupName,
		} = this.props;

		return (
			<Card className="reorder-product-tile-layout-tiles">
				{fetching && <AppLayoutLoader loading />}

				<div className="reorder-product-tile-layout-tiles__scroll-area">
					<div className="reorder-product-tile-layout-tiles__scroll-area__content-wrapper">
						{batchForm.shownTileLayoutTiles
							.sort((a, b) => a.position - b.position)
							.map((entry, index) => {
								const imageAsset = batchForm.assets.find(
									(asset) =>
										asset.asset_collection.id ===
											_get(entry, 'product.asset_collection.id', null) &&
										asset.type === 'Row Layout Tile'
								);

								return (
									<ReorderProductTileLayoutTile
										id={entry.id}
										index={index}
										key={entry.id}
										imageAsset={imageAsset}
										tile={entry}
										removeTile={removeTile}
										moveCard={(dragIndex, hoverIndex) =>
											this.moveCard(dragIndex, hoverIndex)
										}
									/>
								);
							})}
					</div>
				</div>

				<div className="reorder-product-tile-layout-tiles__footer">
					<Button
						shadow
						size="tiny"
						type="success"
						onClick={(e) => {
							submitGroup(groupName);
						}}>
						{phrases.REORDER_TILE_LAYOUT_TILES}
					</Button>
				</div>
			</Card>
		);
	}
}

ReorderProductTileLayoutTiles.propTypes = {
	removeTile: PropTypes.func,
	fetching: PropTypes.bool,
	batchForm: PropTypes.object,
	updateTileLayoutTilePosition: PropTypes.func,
	submitGroup: PropTypes.func,
	groupName: PropTypes.string,
};

export default withDragDropContext(ReorderProductTileLayoutTiles);
