'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonGroup, InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';

import phrases from './../../../workplacePosConfiguration.phrases';

class WorkplacePosConfigurationBatchPosConfigurationsAdd extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};
	}

	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		updateBatchForm({
			[type]: e,
		});
	}

	render() {
		const {
			batchFormData,
			toggleOverlay,
			posConfigurationReduxKey,
		} = this.props;

		return (
			<>
				<ModalRow>
					<ButtonGroup type="full-width">
						<InputCollectionSelect
							className="workplace-pos-configuration__modal-batch-select"
							id="pos_configuration"
							label={phrases.MODAL_BATCH_STEP_POS_CONFIGURATION}
							placeholder={
								phrases.MODAL_BATCH_STEP_POS_CONFIGURATION_PLACEHOLDER
							}
							value={batchFormData.posConfiguration}
							handleChange={(key, value) =>
								this.editStoreEntry(value, 'posConfiguration')
							}
							multi
							cache
							apiPath="/pos/pos_configurations"
							params={{
								limit: 50,
								filter: ':legacy==false',
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>

						<Button
							type="inverted"
							shadow
							size="large"
							onClick={() => toggleOverlay(posConfigurationReduxKey)}>
							<span className="icon icon--more" />
						</Button>
					</ButtonGroup>
				</ModalRow>
			</>
		);
	}
}

WorkplacePosConfigurationBatchPosConfigurationsAdd.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
	posConfigurationReduxKey: PropTypes.string,
};

export default WorkplacePosConfigurationBatchPosConfigurationsAdd;
