'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input, InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';

// lodash
import _get from 'lodash/get';

import phrases from './../../../productVariantBasketRecommendations.phrases';

class AddProductVariantBasketRecommandationsAddExclusion extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};
	}

	editStoreEntry(e, type) {
		const { updateBatchForm, batchFormData } = this.props;

		const value = e.target ? e.target.value : e;

		updateBatchForm({
			basketExclusion: {
				...batchFormData.basketExclusion,
				[type]: value,
			},
		});
	}

	editExclusion(value, name) {
		const { batchFormData } = this.props;

		// filter out the same product variant group - if already selected
		let exclusions = _get(batchFormData, 'basketExclusion.exclusions', []);
		exclusions = exclusions.filter((entry) => entry.name !== name);

		exclusions = [
			...exclusions,
			...(value
				? [
						{
							name,
							value,
						},
				  ]
				: []),
		];

		this.editStoreEntry(exclusions, 'exclusions');
	}

	_getExclusionValue(id) {
		const { batchFormData } = this.props;

		const productVariantValue = _get(
			batchFormData,
			'basketExclusion.exclusions',
			[]
		).find((entry) => entry.name === `productVariant-${id}`);

		return productVariantValue?.value ?? null;
	}

	render() {
		const { batchFormData } = this.props;

		return (
			<>
				<ModalRow>
					<Input
						id="name"
						required
						label={phrases.NAME}
						placeholder={phrases.NAME_PLACEHOLDER}
						onChange={(event) => this.editStoreEntry(event, 'name')}
						value={_get(batchFormData, 'basketExclusion.name', '')}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						id="description"
						label={phrases.DESCRIPTION}
						placeholder={phrases.DESCRIPTION_PLACEHOLDER}
						onChange={(event) => this.editStoreEntry(event, 'description')}
						value={_get(batchFormData, 'basketExclusion.description', '')}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						id="sortOrder"
						type="number"
						label={phrases.SORT_ORDER}
						placeholder={phrases.SORT_ORDER_PLACEHOLDER}
						onChange={(event) => this.editStoreEntry(event, 'sortOrder')}
						value={_get(batchFormData, 'basketExclusion.sortOrder', '')}
					/>
				</ModalRow>

				<ModalRow>
					<div className="add-product-variant-basket-recommendations__scroll-area-wrapper">
						<div className="add-product-variant-basket-recommendations__scroll-area-wrapper__header">
							<span>{phrases.ADD_EXCLUSIONS}</span>
							{!!_get(batchFormData, 'basketExclusion.exclusions', [])
								.length && (
								<span className="add-product-variant-basket-recommendations__scroll-area-wrapper__header__amount">
									(
									{_get(batchFormData, 'basketExclusion.exclusions', []).length}
									)
								</span>
							)}
						</div>
						<div className="add-product-variant-basket-recommendations__scroll-area-wrapper__scroll">
							{[...Array(3).keys()].map((entry) => (
								<ModalRow key={entry}>
									<InputCollectionSelect
										id={`exclusion-${entry}`}
										label={`${phrases.REPLACEMENT} ${entry + 1}`}
										placeholder={phrases.REPLACEMENT_PLACEHOLDER}
										value={this._getExclusionValue(entry)}
										handleChange={(key, value) =>
											this.editExclusion(value, `productVariant-${entry}`)
										}
										cache
										apiPath="/product/product_variant_groups"
										params={{
											limit: 30,
										}}
										optionFormat={(entry) => ({
											value: entry,
											label: entry.name,
										})}
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
									/>
								</ModalRow>
							))}
						</div>
					</div>
				</ModalRow>
			</>
		);
	}
}

AddProductVariantBasketRecommandationsAddExclusion.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
};

export default AddProductVariantBasketRecommandationsAddExclusion;
