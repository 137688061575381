'use strict';

import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import constants from 'services/constants';
import { UserService } from 'user';

/**
 * @namespace __accessHelpers
 * @memberOf SERVICES
 * @description Misc helpers for handling access control level
 */

/**
 * @function validAccess
 * @description
 * 		Returns true if users areas roles are in the list of allowed areas.
 * 		Can be used as a helper function to determine whether a feature should be accessible or not.
 * @export
 * @param {Array} [userAreas=[]] - Optional. The passed userroles are merged with the ones from the store.
 * Its not always possible to pass userroles, maybe because the access needs to be allowed/denied deep within a component
 * @param {Array} [allowedUserAreas=[]]
 * @return {boolean} Valid state or not
 */
export function validAccess(userAreas = [], allowedUserAreas = []) {
	const userData = UserService?.getUser()?.areas || [];

	userAreas = [...new Set([...userAreas, ...userData])];
	const userRoles = Array.isArray(userAreas) ? userAreas : [userAreas];
	return userRoles.some((userRole) =>
		[...allowedUserAreas, constants.admin].includes(userRole)
	);
}

/**
 * @function isStateAccessible
 * @memberOf SERVICES.
 * @description
 * Returns true if there are any substates that are accessible.
 * If no states are provided or every substate is hidden, true is returned.
 * This method works is support for filterStates in order to not show any
 * empty parents. If this function is not used, all parents will be shown if
 * there is access to them, no matter which children (if any accessible)
 * they have.
 *
 * @param  {array}   states     States to check
 * @return {boolean}            Any accessible substates
 */
export function isStateAccessible(state = {}, userRole = []) {
	if (_isEmpty(state) || _isEmpty(userRole)) return false;

	if (userRole.includes(constants.admin)) return true;
	// if (state.data.accessControlLevel.includes('transport')) return true;

	// // If menu is transport
	if (!state?.allowedAccessControlLevels && !state.children) return true;

	if (state?.allowedAccessControlLevels?.includes('transport')) {
		// If it has no children, then show

		if (_isEmpty(state.children))
			return validAccess(userRole, state.allowedAccessControlLevels);

		// If all children is accessible, show
		const everyChildIsAccessible = state.children.every((child) =>
			validAccess(userRole, child.allowedAccessControlLevels)
		);

		if (everyChildIsAccessible) return true;
	}

	// Can this step be skipped? Are there substates or visible substates?
	// If not skipping (true), then check if any substates with access to.
	// Function is applied recursive, if the allowedAccessControlLevels of the
	// substate is 'transport', as this is merely a transport layer, and
	// should be disregarded in this function.
	if (
		!_isEmpty(state.children) &&
		state.children.some((child) =>
			validAccess(userRole, child.allowedAccessControlLevels)
		)
	)
		return state.children.map((child) => isStateAccessible(child, userRole));
	else return validAccess(userRole, state.allowedAccessControlLevels);
}

/**
 * @function getHighestAccessRight
 * @memberOf SERVICES.
 * @description
 * Returns the highest level access right of the current user.
 * This method is used when only one access right is needed rather
 * than an array
 *
 * @return {string}            Highest level access role
 */
export function getHighestAccessRight() {
	const userData = UserService.getUser();

	let userRole = constants.juicer;
	const userRoles = _get(userData, 'userRoles', []);

	if (userRoles.includes(constants.barManager)) userRole = constants.barManager;

	//  Code below should be added when shiftplan role is ready to roll out
	// Set to regionalManager if userRoles containts 'Regional Manager'
	// if (userRoles.includes(constants.regionalManager)) userRole = constants.regionalManager;

	// Special case. Only if the selected market is responsibilty of the particular shiftplan-role user
	// if (userRoles.includes(constants.shiftplan)) {

	// 	// Map through the different responsibility roles to extract location_type and location
	// 	Object.values(userData.responsibilities).reduce((acc, responsibilityTypes) => ([
	// 		...acc,
	// 		...responsibilityTypes.map(responsibilityType => ({
	// 			location_type: responsibilityType.location_type,
	// 			location: responsibilityType.location
	// 		}))
	// 	]), []).map(userResponsibility => {

	// 		// Use the extracted location and location_type to check up against the current workplace
	// 		const userResponsibilityLocationType = userResponsibility.location_type ? userResponsibility.location_type.toLowerCase() : null;
	// 		if (userResponsibilityLocationType && workplace[userResponsibilityLocationType] === userResponsibility.location.id) {
	// 			userRole = constants.shiftplan;
	// 		}
	// 	});
	// }

	// Set to admin if userRoles containts 'admin'
	if (userRoles.includes(constants.admin)) userRole = constants.admin;

	return userRole;
}

/**
 * @function getUserHasAccessToSensitivePersonInformation
 * @description Determines if user has access to sensitive person information
 * @returns {boolean}
 */
export function getUserHasAccessToSensitivePersonInformation() {
	const userData = UserService.getUser();

	const userAreas = userData?.areas || [];

	const userHasAccess = userAreas.some(
		(entry) => entry === 'PersonSensitiveInformation::Edit'
	);

	return userHasAccess;
}
