'use strict';

import { HotbarComponentFactory as h } from 'hotdamnbar/factory';
import {
	HotbarEndpointDataProvider,
	HotbarStaticDataProvider
} from 'hotdamnbar/dataProviders';

const CompaniesDataProvider = new HotbarEndpointDataProvider(
	'/administration/companies'
);

const AuditTypeDataProvider = new HotbarStaticDataProvider([
	{
		value: 'Account',
		label: 'Account'
	},
	{
		value: 'Payroll',
		label: 'Payroll'
	}
]);

const AuditReportsHotbar = h.hotbar('auditReportsHotbar');

AuditReportsHotbar.add(
	h.select('Companies', false, CompaniesDataProvider, true)
);
AuditReportsHotbar.add(h.dateRange('Period', true, true));
AuditReportsHotbar.add(h.select('View', true, AuditTypeDataProvider));

export default AuditReportsHotbar;
