import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './inputWrapper.css';

export default class DateInputWrapper extends PureComponent {
	render() {
		const { children, className } = this.props;

		const classN = classnames('j-date-input-wrapper', {
			[`${className}`]: className
		});

		return <div className={classN}>{children}</div>;
	}
}

DateInputWrapper.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired
};
