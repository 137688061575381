import React, { Component } from 'react';

import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import { Row, TableCell } from 'dumb/report';

import { Loader } from 'dumb';
import constants from 'dumb/report/constants';

import PropTypes from 'prop-types';
import EmployeeMealsPrPerson from './employeeMealsPrPerson.component';

class EmployeeMealsPrWorkplace extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expandedRows: new Set(),
			loading: [],
		};

		this._getTotalGrossTurnover = this._getTotalGrossTurnover.bind(this);
		this._getTotalProductCount = this._getTotalProductCount.bind(this);
	}

	/**
	 * @method _onExpandHandler
	 * @memberof EmployeeMealsPrWorkplace
	 * @param {integer} rowNumber - the index of the row being clicked
	 * @param {Object} entry - The clicked entry
	 */
	_onExpandHandler(rowNumber, entry) {
		const personId = entry.person.id;
		const workplaceId = entry.workplace.id;

		this.state.expandedRows.has(rowNumber)
			? this.state.expandedRows.delete(rowNumber)
			: this.state.expandedRows.add(rowNumber);

		this.setState(
			(prevState) => ({
				loading: [...prevState.loading, rowNumber],
				expandedRows: prevState.expandedRows,
			}),
			() => {
				this.props
					.fetchEmployeeMealsPrEmployee(
						this.props.marketId,
						workplaceId,
						personId
					)
					.then(() => {
						this.setState(() => ({
							loading: this.state.loading.filter((x) => x !== rowNumber),
						}));
					});
			}
		);
	}

	// @TODO: until backend get their shit together ^.^
	_getTotalGrossTurnover(data) {
		return data.reduce((sum, x) => sum + x.employee_meal.gross_turnover, 0);
	}

	_getTotalProductCount(data) {
		return data.reduce((sum, x) => sum + x.employee_meal.products, 0);
	}

	_renderRows() {
		return !_isEmpty(this.props.reportData)
			? this.props.reportData.map((entry, index) => {
					let showEmployee = true;
					if (
						this.props.showProductsPrShift !== 'ALL' &&
						this.props.showProductsPrShift === 'LESS_OR_EQUAL_TO_3'
					) {
						showEmployee = entry.employee_meal.products_per_shifts <= 3;
					} else if (
						this.props.showProductsPrShift !== 'ALL' &&
						this.props.showProductsPrShift === 'MORE_THAN_3'
					) {
						showEmployee = entry.employee_meal.products_per_shifts > 3;
					}

					return showEmployee ? (
						<Row
							key={entry.id}
							index={index}
							type="entry"
							totalColSpan={this.props.totalColSpan}
							onClick={() => this._onExpandHandler(index, entry)}
							expanded={this.state.expandedRows.has(index)}
							renderExpanded={(rowNumber) => {
								const personId = this.props.reportData[rowNumber].person.id;
								const reportData =
									this.props.employeeMealsReportPrPerson.filter(
										(entry) => entry.person.id === personId
									);

								if (this.state.loading.includes(index) && _isEmpty(reportData))
									return <Loader />;

								return (
									<EmployeeMealsPrPerson
										totalColSpan={this.props.totalColSpan}
										reportData={reportData}
									/>
								);
							}}
						>
							<TableCell
								colSpan="3"
								content={
									_get(entry, 'person.identity.name', null) ||
									_get(entry, 'person.name', null)
								}
								alignLeft
							/>

							<TableCell
								cellType={constants.CELL_TYPES.INTEGER}
								separate
								colSpan="2"
								content={_get(entry, 'employee_meal.gross_turnover', null)}
								alignRight
							/>

							<TableCell
								cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
								colSpan="2"
								separate
								content={_get(entry, 'employee_meal.hours', null)}
								alignRight
							/>
							<TableCell
								cellType={constants.CELL_TYPES.INTEGER}
								colSpan="2"
								separate
								content={_get(entry, 'employee_meal.products', null)}
								alignRight
							/>
							<TableCell
								cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
								colSpan="2"
								separate
								content={_get(entry, 'employee_meal.products_per_shifts', null)}
								alignRight
							/>
							<TableCell
								cellType={constants.CELL_TYPES.INTEGER}
								colSpan="2"
								separate
								content={_get(entry, 'employee_meal.shifts', null)}
								alignRight
							/>
						</Row>
					) : null;
			  })
			: null;
	}

	render() {
		return this._renderRows();
	}
}

EmployeeMealsPrWorkplace.propTypes = {
	reportData: PropTypes.array,
	employeeMealsReportPrPerson: PropTypes.array,
	totalColSpan: PropTypes.number,
	marketId: PropTypes.number,
	showProductsPrShift: PropTypes.string,
	fetchEmployeeMealsPrEmployee: PropTypes.func,
};

EmployeeMealsPrWorkplace.defaultProps = {
	reportData: [],
};

export default EmployeeMealsPrWorkplace;
