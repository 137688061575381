'use strict';

import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';

import StoreAreaTypesList from './services/storeAreaTypesList.service.js';
import StoreContinentTagsList from './services/storeContinentTagsList.service.js';
import StoreForecastTagsList from './services/storeForecastTagsList.service.js';
import StoreLocationTypesList from './services/storeLocationTypesList.service.js';
import StoreRegionsList from './services/storeRegionsList.service.js';

const storeAreaTypesListReduxKey = '/administration/store_area_types';
const storeAreaTypesList = new StoreAreaTypesList(storeAreaTypesListReduxKey);
const storeAreaTypesListProps = storeAreaTypesList.getProps();

const storeContinentTagsListReduxKey = '/administration/store_continent_tags';
const storeContinentTagsList = new StoreContinentTagsList(storeContinentTagsListReduxKey);
const storeContinentTagsListProps = storeContinentTagsList.getProps();

const storeForecastTagsListReduxKey = '/administration/store_forecast_tags';
const storeForecastTagsList = new StoreForecastTagsList(storeForecastTagsListReduxKey);
const storeForecastTagsListProps = storeForecastTagsList.getProps();

const storeLocationTypesListReduxKey = '/administration/store_forecast_tags';
const storeLocationTypesList = new StoreLocationTypesList(storeLocationTypesListReduxKey);
const storeLocationTypesListProps = storeLocationTypesList.getProps();

const storeRegionsListReduxKey = '/administration/store_regions';
const storeRegionsList = new StoreRegionsList(storeRegionsListReduxKey);
const storeRegionsListProps = storeRegionsList.getProps();

export function BITags() {
	useEffect(() => {
		storeAreaTypesList.initView();
		storeContinentTagsList.initView();
		storeForecastTagsList.initView();
		storeLocationTypesList.initView();
		storeRegionsList.initView();
	}, []);

	return (
		<>
			<ListComponent reduxKey={storeAreaTypesListReduxKey} {...storeAreaTypesListProps} />
			<ListComponent reduxKey={storeContinentTagsListReduxKey} {...storeContinentTagsListProps} />
			<ListComponent reduxKey={storeForecastTagsListReduxKey} {...storeForecastTagsListProps} />
			<ListComponent reduxKey={storeLocationTypesListReduxKey} {...storeLocationTypesListProps} />
			<ListComponent reduxKey={storeRegionsListReduxKey} {...storeRegionsListProps} />
		</>
	);
}
