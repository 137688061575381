'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Icon, Input, Tooltip } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// phrases/ enums
import phrases from './../../../productVariantIngredientAlternatives.phrases';
import enums from './../../../productVariantIngredientAlternatives.enums';

// lodash
import _get from 'lodash/get';

class ProductVariantIngredientAlternativesBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: e },
		});
	}

	render() {
		const { batchFormData, batchListData } = this.props;

		return (
			<TableList>
				<thead>
					<tr>
						<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
						<th>{phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT_INGREDIENT}</th>

						{batchFormData.type === enums.ALTERNATIVE_INGREDIENT && (
							<th>{phrases.MODAL_BATCH_STEP_INGREDIENT}</th>
						)}

						{batchFormData.type === enums.ALTERNATIVE_PRODUCTS && (
							<th>{phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT_GROUP}</th>
						)}

						<th>{phrases.MODAL_BATCH_STEP_SORT_ORDER}</th>
						<th />
					</tr>
				</thead>
				<tbody>
					{batchListData.map((entry, i) => {
						return (
							<TableListRow key={entry.identifier}>
								<TableListCell>{i + 1}</TableListCell>
								<TableListCell>
									{_get(entry, 'productVariantIngredient.label', '')}
								</TableListCell>

								{batchFormData.type === enums.ALTERNATIVE_INGREDIENT && (
									<TableListCell>
										{_get(entry, 'ingredient.label', '')}
									</TableListCell>
								)}

								{batchFormData.type === enums.ALTERNATIVE_PRODUCTS && (
									<TableListCell>
										{_get(entry, 'productVariantGroup.label', '')}
									</TableListCell>
								)}

								<TableListCell>
									<Input
										id={`${entry.identifier}-sort-order`}
										placeholder={
											phrases.MODAL_BATCH_STEP_SORT_ORDER_PLACEHOLDER
										}
										type="number"
										value={entry.sortOrder}
										onChange={(e) =>
											this._editListEntry(e.target.value, entry, 'sortOrder')
										}
									/>
								</TableListCell>

								<TableListCell>
									<Tooltip
										text={phrases.DELETE_ROW_TOOLTIP}
										placement="left"
										zIndex={600}
										renderData={(ref, onMouseEnter, onMouseLeave) => (
											<Button
												id={`${entry.identifier}-delete`}
												type="negative"
												size="tiny"
												onClick={() => this._onRemoveEntry(entry.identifier)}
												refProp={ref}
												onMouseEnter={onMouseEnter}
												onMouseLeave={onMouseLeave}>
												<Icon name="delete" />
											</Button>
										)}
									/>
								</TableListCell>
							</TableListRow>
						);
					})}
				</tbody>
			</TableList>
		);
	}
}

ProductVariantIngredientAlternativesBatchStepList.propTypes = {
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	batchFormData: PropTypes.object,
};

export default ProductVariantIngredientAlternativesBatchStepList;
