import React, { ReactNode, Component, ChangeEvent } from 'react';

// components
import { Button, Modal, ButtonLoader, Input, Icon, Tooltip } from 'dumb';
import { Menu, MenuItem } from 'dumb/menu';

// utils/mics
import phrases from '../reactDataWrapper.phrases';
import _isEmpty from 'lodash/isEmpty';
import _uniqueId from 'lodash/uniqueId';

import './actions.css';

type Props = {
	toggleEditModal: (
		_: { original: Record<string, unknown> | null },
		e: ChangeEvent<HTMLButtonElement>
	) => void;
	resetSelection: () => void;
	selectedRows: unknown[];
	toggleCreateModal: () => void;
	deleteRows: () => Promise<unknown>;
	canDelete: boolean;
	canManipulate: () => void | boolean;
	disableFetchCsvButton: boolean;
	fetchCsv: () => Promise<unknown>;
	fetchData: () => void;
	selectAll: () => void;
	toggleColumns: () => void;
	render: () => ReactNode;
	showSearch: boolean;
	onInputChange: (value: string) => void;
	filterSortColumnsData: {
		sorting: Record<string, unknown>;
		filters: Record<string, unknown>[];
	};
	clearTableFiltersSorting: () => void;
	batchSelection: boolean;
	canMultiEdit: boolean;
	canSingleEdit: boolean;
	enableMultiSelection: boolean;
	hideMenu: boolean;
};
type State = {
	showDeleteModal: boolean;
	loadingDelete: boolean;
	fetchingCsv: boolean;
};

class Actions extends Component<Props, State> {
	static defaultProps: Partial<Props> = {
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		resetSelection: () => {},
		selectedRows: [],
		fetchCsv: () => Promise.resolve(true),
		disableFetchCsvButton: false,
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			showDeleteModal: false,
			loadingDelete: false,
			fetchingCsv: false,
		};

		// bind all functions correctly
		this.onFetchCsv = this.onFetchCsv.bind(this);
		this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
		this.onDelete = this.onDelete.bind(this);
	}

	toggleDeleteModal() {
		this.setState((prevState) => ({
			showDeleteModal: !prevState.showDeleteModal,
		}));
	}

	onDelete() {
		this.setState(() => ({ loadingDelete: true }));
		this.props
			.deleteRows()
			.then(() => {
				this.setState(() => ({ loadingDelete: false }));
				this.toggleDeleteModal();
			})
			.catch(() => {
				this.setState(() => ({ loadingDelete: false }));
			});
	}

	onFetchCsv() {
		this.setState(() => ({ fetchingCsv: true }));
		this.props.fetchCsv().finally(() => {
			this.setState(() => ({ fetchingCsv: false }));
		});
	}

	render() {
		const {
			toggleEditModal,
			toggleCreateModal,
			resetSelection,
			selectedRows,
			canManipulate,
			canDelete,
			selectAll,
			disableFetchCsvButton,
			fetchData,
			toggleColumns,
			showSearch,
			onInputChange,
			filterSortColumnsData,
			clearTableFiltersSorting,
			batchSelection,
			canMultiEdit,
			canSingleEdit,
			enableMultiSelection,
			hideMenu,
		} = this.props;

		const { showDeleteModal, fetchingCsv } = this.state;

		let enableEditButton = false;
		if (selectedRows.length === 1 && canSingleEdit) enableEditButton = true;
		if (selectedRows.length > 1 && canMultiEdit) enableEditButton = true;

		const showClearSortFilterButton =
			!_isEmpty(filterSortColumnsData.sorting) ||
			!_isEmpty(filterSortColumnsData.filters);

		return (
			<div className="actions-menu">
				{showSearch && (
					<div className="actions-menu__search">
						<Input
							id={`${_uniqueId('row-')}-actions-menu-search`}
							placeholder={phrases.INPUT_PLACEHOLDER}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								onInputChange(e.target.value)
							}
						/>
					</div>
				)}
				{selectAll && (
					<>
						{selectedRows.length >= 1 && !batchSelection && (
							<>
								{canDelete ? (
									<Button
										type="inverted"
										label="Delete"
										shadow
										dataCy="react-data-wrapper-delete-button"
										onClick={this.toggleDeleteModal}
									>
										<Icon name="delete" />
									</Button>
								) : null}

								{enableEditButton ? (
									<Button
										type="inverted"
										label="Edit"
										shadow
										onClick={(e: ChangeEvent<HTMLButtonElement>) =>
											toggleEditModal({ original: null }, e)
										}
										dataCy="react-data-wrapper-edit-button"
									>
										<Icon name="edit" />
									</Button>
								) : null}
							</>
						)}

						{showClearSortFilterButton ? (
							<Tooltip
								text="Clears selected sorting and filtering on the list"
								renderData={(ref, onMouseEnter, onMouseLeave) => (
									<Button
										type="inverted"
										label={'Filters/\nsorting'}
										shadow
										onClick={clearTableFiltersSorting}
										refProp={ref}
										onMouseEnter={onMouseEnter}
										onMouseLeave={onMouseLeave}
									>
										<Icon name="clear" />
									</Button>
								)}
							/>
						) : null}

						{enableMultiSelection ? (
							<Button
								type="inverted"
								label={selectedRows.length ? 'Unselect' : 'All'}
								shadow
								onClick={selectedRows.length ? resetSelection : selectAll}
							>
								<Icon name={selectedRows.length ? 'refresh' : 'select_all'} />
							</Button>
						) : null}
					</>
				)}

				{!!canManipulate && !batchSelection && (
					<Button
						type="inverted"
						label="Add"
						shadow
						onClick={toggleCreateModal}
						dataCy="react-data-wrapper-add-button"
					>
						<Icon name="add" />
					</Button>
				)}

				{!hideMenu && (
					<Menu
						renderData={(ref: unknown, onClick: unknown, onBlur: unknown) => (
							<Button
								label="More"
								type="inverted"
								shadow
								onClick={onClick}
								onBlur={onBlur}
								refProp={ref}
							>
								<Icon name="more_vert" />
							</Button>
						)}
						zIndex={650}
					>
						{!disableFetchCsvButton && (
							<MenuItem
								leftSection={
									fetchingCsv ? (
										<ButtonLoader loading={fetchingCsv} theme="dark" />
									) : (
										<Icon name="file_download" />
									)
								}
								onClick={this.onFetchCsv}
							>
								CSV
							</MenuItem>
						)}
						{toggleColumns && (
							<MenuItem
								leftSection={<Icon name="view_week" />}
								onClick={toggleColumns}
							>
								Columns
							</MenuItem>
						)}
						<MenuItem leftSection={<Icon name="refresh" />} onClick={fetchData}>
							Refresh
						</MenuItem>
					</Menu>
				)}

				{!batchSelection && this.props.render()}

				{showDeleteModal && (
					<Modal
						onClose={this.toggleDeleteModal}
						confirmButtonLabel="Delete"
						type="confirmation"
						loading={this.state.loadingDelete}
						onConfirmClick={this.onDelete}
						onCancelClick={this.toggleDeleteModal}
						isOpen={showDeleteModal}
						zIndex="550"
					>
						<div className="actions-menu__delete-modal-text">
							Delete {selectedRows.length} entries
						</div>
					</Modal>
				)}
			</div>
		);
	}
}

export default Actions;
