'use strict';

import {
	SET_VACATIONS,
	UPDATE_VACATION,
	RESET_STATE,
} from './vacationsTable.actions';

const defaultState = {
	data: {
		vacationData: {},
	},
};

export default (state = defaultState, action) => {
	const { type, payload } = action;

	switch (type) {
		case SET_VACATIONS:
			return {
				...state,
				data: {
					vacationData: payload,
				},
			};

		case UPDATE_VACATION:
			return {
				...state,
				data: {
					vacationData: {
						...state.data.vacationData,
						...payload,
					},
				},
			};

		case RESET_STATE:
			return {
				...defaultState,
			};

		default:
			return state;
	}
};
