// Interfaces
import { IEmploymentWorkplaceReduxState } from '../types';

// Actions
import {
	SET_WORKPLACE,
	SET_FROM_DATE,
	RESET_REDUX_STATE,
	SET_TO_DATE,
} from './employmentWorkplaces.actions';

// Tools
import moment from 'moment';
import constants from 'services/constants';

type ActionProps = {
	type: string;
	payload: IEmploymentWorkplaceReduxState;
};

const defaultState: IEmploymentWorkplaceReduxState = {
	workplace: null,
	fromDate: moment().format(constants.shortDate),
	toDate: moment('9999-12-31').format(constants.shortDate),
};

function reducer(
	state = defaultState,
	action: ActionProps
): IEmploymentWorkplaceReduxState {
	const { type, payload } = action;

	switch (type) {
	case SET_WORKPLACE:
		return {
				...state,
				workplace: payload,
			};

	case SET_FROM_DATE:
		return {
				...state,
				fromDate: payload,
			};

	case SET_TO_DATE:
		return {
				...state,
				toDate: payload,
			};

	case RESET_REDUX_STATE:
		return defaultState;

	default:
		return state;
	}
}

export default reducer;
