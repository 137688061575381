'use strict';

import _each from 'lodash/each';

module.exports = function(headers) {
	_each(headers, function(header, key) {
		switch (header.type) {
			case 'action':
				// Move to end of array - always
				headers.push(headers.splice(key,1)[0]);
				break;
		}
	});
	this.setHeaders(headers);
};