'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';

// components
import { Button, Input, Icon } from 'dumb';
import IngredientPricingGroupModalBatch from './components/ingredientPricingGroupsModalBatch';

import _get from 'lodash/get';

import {
	resetState,
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	setIngredientPricingGroup,
	updateIngredientPricingGroup,
} from './store/posConfigurationIngredientPricingGroups.actions';

import {
	fetchPosConfigurationIngredientPricingGroups,
	deletePosConfigurationIngredientPricingGroup,
	addPosConfigurationIngredientPricingGroups,
	editPosConfigurationIngredientPricingGroup,
	batchEditPosConfigurationIngredientPricingGroup,
} from './posConfigurationIngredientPricingGroups.service';

import phrases from './posConfigurationIngredientPricingGroups.phrases';

class PosConfigurationIngredientPricingGroups extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editMultiplePrices = this.editMultiplePrices.bind(this);

		this.columns = [
			{
				Header: 'POS Configuration',
				id: 'posConfiguration',
				accessor: (d) =>
					_get(d, 'pos_configuration_version.pos_configuration.name', null),
				filterPath: ':pos_configuration_version.pos_configuration.name',
			},
			{
				Header: 'POS Configuration Version',
				id: 'posConfigurationVersion',
				accessor: (d) => _get(d, 'pos_configuration_version.name', null),
				filterPath: ':pos_configuration_version.name',
			},
			{
				Header: 'Ingredient pricing group',
				id: 'ingredient_pricing_group',
				accessor: (d) => _get(d, 'ingredient_pricing_group.name', ''),
				filterPath: ':ingredient_pricing_group.name',
			},
			{
				Header: 'To stay price',
				id: 'to_stay_price',
				accessor: (d) => _get(d, 'to_stay_price', ''),
				filterPath: ':to_stay_price',
			},
			{
				Header: 'To go price',
				id: 'to_go_price',
				accessor: (d) => _get(d, 'to_go_price', ''),
				filterPath: ':to_go_price',
			},
		];
	}

	deleteEntry(id) {
		return deletePosConfigurationIngredientPricingGroup(id);
	}

	editEntry() {
		const { ingredientPricingGroup } = this.props;

		const payload = {
			id: ingredientPricingGroup.id,
			to_stay_price: ingredientPricingGroup.toStayPrice,
			to_go_price: ingredientPricingGroup.toGoPrice,
		};

		return editPosConfigurationIngredientPricingGroup(payload);
	}

	editMultiplePrices(selectedRows) {
		const { ingredientPricingGroup } = this.props;

		const payload = {
			...(ingredientPricingGroup.toGoPrice && {
				to_go_price: ingredientPricingGroup.toGoPrice,
			}),
			...(ingredientPricingGroup.toStayPrice && {
				to_stay_price: ingredientPricingGroup.toStayPrice,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				pos_configuration_version: row.pos_configuration_version.id,
				ingredient_pricing_group: row.ingredient_pricing_group.id,
				...payload,
			};
		});

		return batchEditPosConfigurationIngredientPricingGroup({
			batch: selectedRowsWithId,
		});
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			posConfigurationVersion: data.pos_configuration_version.name,
			ingredientPricingGroup: data.ingredient_pricing_group.name,
			toStayPrice: data.to_stay_price,
			toGoPrice: data.to_go_price,
		};

		this.props.setIngredientPricingGroup(payload);
	}

	getEditableCells() {
		const { ingredientPricingGroup } = this.props;

		return [
			...(ingredientPricingGroup.id
				? [
					{
						header: 'Pos configuration verson',
						value: (
							<span>{ingredientPricingGroup.posConfigurationVersion}</span>
						),
					},
					{
						header: 'Ingredient pricing group',
						value: (
							<span>{ingredientPricingGroup.ingredientPricingGroup}</span>
						),
					},
				  ]
				: []),
			{
				header: 'To Stay Price',
				value: (
					<Input
						id="toStayPrice"
						type="number"
						placeholder="1234,56"
						onChange={(e) => this.editStoreEntry(e.target.value, 'toStayPrice')}
						value={ingredientPricingGroup.toStayPrice}
					/>
				),
			},
			{
				header: 'To Go Price',
				value: (
					<Input
						id="toGoPrice"
						type="number"
						placeholder="1234,56"
						onChange={(e) => this.editStoreEntry(e.target.value, 'toGoPrice')}
						value={ingredientPricingGroup.toGoPrice}
					/>
				),
			},
		];
	}

	editStoreEntry(e, name) {
		const payload = {
			[name]: e,
		};

		this.props.updateIngredientPricingGroup(payload);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	render() {
		const {
			updateBatchForm,
			batchFormData,
			listIngredientPricingGroups,
			listPosConfigurationVersions,
			addBatchList,
			batchList,
			editBatchListItem,
			removeBatchListItem,
			resetState,
		} = this.props;

		return (
			<>
				<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={fetchPosConfigurationIngredientPricingGroups}
					filterable
					defaultPageSize={50}
					reduxKey="/pos/pos_configuration_ingredient_pricing_groups"
					manual
					editEntry={() => this.editEntry()}
					editableCells={this.getEditableCells()}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={resetState}
					actionsWidth={90}
					editMultiple={this.editMultiplePrices}
					deleteEntry={this.deleteEntry}
					deleteConfirm
					actionRender={
						<Button
							type="inverted"
							label="Add"
							shadow
							onClick={() => this.toggleModal()}>
							<Icon name="add" />
						</Button>
					}
				/>

				<IngredientPricingGroupModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchFormData={batchFormData}
					updateBatchForm={updateBatchForm}
					fetchPosConfigurationIngredientPricingGroups={
						fetchPosConfigurationIngredientPricingGroups
					}
					listPosConfigurationVersions={listPosConfigurationVersions}
					listIngredientPricingGroups={listIngredientPricingGroups}
					addBatchList={addBatchList}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addPosConfigurationIngredientPricingGroups={
						addPosConfigurationIngredientPricingGroups
					}
				/>
			</>
		);
	}
}

PosConfigurationIngredientPricingGroups.propTypes = {
	ingredientPricingGroup: PropTypes.object,
	listPosConfigurationVersions: PropTypes.object,
	listIngredientPricingGroups: PropTypes.object,
	setIngredientPricingGroup: PropTypes.func,
	resetState: PropTypes.func,
	updateBatchForm: PropTypes.func,
	addBatchList: PropTypes.func,
	batchFormData: PropTypes.object,
	batchList: PropTypes.object,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	updateIngredientPricingGroup: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			resetState,
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			setIngredientPricingGroup,
			updateIngredientPricingGroup,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		batchFormData:
			store.posConfigurationIngredientPricingGroups.data.batchFormData,
		batchList: store.posConfigurationIngredientPricingGroups.data.batchList,
		listPosConfigurationVersions:
			store.listData[
				'ingredient-pricing-groups/pos/pos_configuration_versions'
			],
		listIngredientPricingGroups:
			store.listData[
				'ingredient-pricing-groups/product/ingredient_pricing_groups'
			],
		ingredientPricingGroup:
			store.posConfigurationIngredientPricingGroups.data.ingredientPricingGroup,
	};
};

export default connectWithStore(
	PosConfigurationIngredientPricingGroups,
	mapStateToProps,
	mapDispatchToProps
);
