'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';

import _get from 'lodash/get';
import { enums } from './productCategories.enums';

import { setProductCategory, updateProductCategory, resetState } from './store/productCategories.actions';

import {
	fetchProductCategories,
	addProductCategories,
	deleteProductCategories,
	editProductCategories,
} from './productCategories.service';

import phrases from './productCategories.phrases';
import { Input, InputCollectionSelect, Button, Toggle } from 'dumb';

// Wrapper for react-data
// Documentation can be found here https://react-table.js.org/
class ProductCategories extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);

		this.state = {
			pages: null,
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this.fetchData = this.fetchData.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Category name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Category group',
				id: 'category_group',
				accessor: (d) => _get(d, 'category_group', ''),
				filterPath: ':category_group',
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: 'legacy',
				filterPath: ':legacy',
				filterable: false,
				Cell: (d) => {
					return <Input id="legacy" type="checkbox" checked={d.original.legacy} disabled />;
				},
			},
		];
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}
			>
				Legacy
			</Button>
		);
	}

	deleteEntry(id) {
		return deleteProductCategories(id);
	}

	addEntry() {
		const { rowEntry } = this.props;

		const payload = {
			category_group: _get(rowEntry, 'category_group.value', enums.CATEGORY_GROUP[0]),
			name: _get(rowEntry, 'name', ''),
		};

		return addProductCategories(payload);
	}

	editEntry() {
		const { rowEntry } = this.props;

		const payload = {
			category_group: _get(rowEntry, 'category_group.value', enums.CATEGORY_GROUP[0]),
			name: _get(rowEntry, 'name', ''),
			legacy: !!rowEntry.legacy,
		};

		return editProductCategories(rowEntry.id, payload);
	}

	setInitialEditValues(data) {
		this.props.setProductCategory(data);
	}

	getEditableCellsEdit() {
		const { rowEntry } = this.props;

		return [
			{
				header: 'Category name',
				value: (
					<Input
						id="name"
						placeholder="Name"
						value={_get(rowEntry, 'name', '')}
						onChange={(event) => this.editStoreEntry(event, 'name')}
					/>
				),
			},
			{
				header: 'Category group',
				value: <span>{_get(rowEntry, 'category_group.value', enums.CATEGORY_GROUP[0])}</span>,
			},
			{
				header: 'Legacy',
				value: (
					<Toggle id="legacy" toggled={rowEntry.legacy} onClick={(event) => this.editStoreEntry(event, 'legacy')} />
				),
			},
		];
	}

	getEditableCells(e) {
		const { rowEntry } = this.props;

		return [
			{
				header: 'Category name',
				value: (
					<Input
						id="name"
						placeholder="name"
						value={_get(rowEntry, 'name', '')}
						onChange={(event) => this.editStoreEntry(event, 'name')}
					/>
				),
			},
			{
				header: 'Category group',
				value: (
					<InputCollectionSelect
						id="category_group"
						value={_get(rowEntry, 'category_group', {
							value: enums.CATEGORY_GROUP[0],
							label: enums.CATEGORY_GROUP[0],
						})}
						onChange={(event) => this.editStoreEntry(event, 'category_group')}
						options={enums.CATEGORY_GROUP.map((x) => ({ value: x, label: x }))}
						clearable={false}
					/>
				),
			},
		];
	}

	editStoreEntry(e, name) {
		const value = e.target ? e.target.value : e;

		const updateObject = {
			[name]: value,
		};

		this.props.updateProductCategory(updateObject);
	}

	fetchData(state) {
		return fetchProductCategories(state);
	}

	render() {
		const legacyFilter = this.state.legacyFilterOn ? this.state.legacyFilter : '';

		return (
			<ReactDataWrapper
				accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.PRODUCT_CATEGORIES}
				className="-striped -highlight"
				columns={this.columns}
				totalEntries={this.state.totalEntries} // Display the total number of pages
				fetchData={this.fetchData} // Request new data when things change
				filterable
				defaultPageSize={10}
				reduxKey="product/product_categories"
				manual // Forces table not to paginate or sort automatically, so we can handle it server-side
				editableCells={this.getEditableCells()}
				editableCellsEdit={this.getEditableCellsEdit()}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetState}
				actionsWidth={30}
				deleteEntry={this.deleteEntry}
				createEntry={this.addEntry}
				editEntry={this.editEntry}
				customAreaComponents={this.getLegacyFilterButton()}
				extraFilters={legacyFilter}
			/>
		);
	}
}

ProductCategories.propTypes = {
	rowEntry: PropTypes.object,

	updateProductCategory: PropTypes.func,
	setProductCategory: PropTypes.func,
	resetState: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setProductCategory,
			updateProductCategory,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		rowEntry: store.productCategories.data.defaultProductCategories,
	};
};

export default connectWithStore(ProductCategories, mapStateToProps, mapDispatchToProps);
