'use strict';

/**
 * Group
 * @param  {string} name
 * @param  {array} content
 */
class Group {
	constructor(name, content, modifierClasses = []) {
		// Set type
		this.type = 'group';

		/// ///////
		// Name //
		/// ///////

		// Check group for name
		this.name = name;

		/// //////////
		// Content //
		/// //////////

		// Check group for correct type
		this.content = content;

		/// //////////////////
		// ModifierClasses //
		/// //////////////////

		// If bodyContent is array, make sure that all children are of type 'content'
		this.modifierClasses = modifierClasses;
	}

	/**
	 * @function getWidth
	 * @memberOf Group
	 * @description
	 * Returns width
	 */
	getWidth(data) {
		return this.content.reduce(
			(acc, column) => (acc = acc + column.getWidth(data)),
			0
		);
	}

	/**
	 * @function getLength
	 * @memberOf Group
	 * @description
	 * Returns length
	 */
	getLength() {
		return this.content.length;
	}

	/**
	 * @function getModifierClassNames
	 * @memberOf Column
	 * @description
	 * Returns modifier classes as cx expression
	 * @param  {object} data
	 */
	getModifierClassNames(data) {
		if (this.modifierClasses.length > 0) {
			return this.modifierClasses.reduce((acc, modifierClass) => {
				acc[modifierClass.className] = modifierClass.expression(data);
				return acc;
			}, {});
		} else return {};
	}
}

export default Group;
