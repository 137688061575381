import {
	UPDATE_PRODUCT_BUNDLE,
	SET_PRODUCT_BUNDLE,
	RESET_PRODUCT_BUNDLE,
	ADD_BATCH_LIST,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	ADD_BATCH_LIST_ITEM_ROW
} from './productBundles.actions';

// utils
import _uniqueId from 'lodash/uniqueId';

const defaultState = {
	data: {
		defaultProductBundle: {},
		batchList: [
			{
				identifier: _uniqueId('row-'),
				name: '',
				description: '',
				sortOrder: null
			}
		]
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_PRODUCT_BUNDLE: {
		return {
			data: {
				defaultProductBundle: payload
			}
		};
	}

	case UPDATE_PRODUCT_BUNDLE: {
		return {
			data: {
				defaultProductBundle: {
					...state.data.defaultProductBundle,
					...payload
				}
			}
		};
	}

	case RESET_PRODUCT_BUNDLE: {
		return {
			...defaultState
		};
	}

	case ADD_BATCH_LIST: {
		return {
			...state,
			data: {
				...state.data,
				batchList: payload
			}
		};
	}

	case EDIT_BATCH_LIST_ITEM: {
		return {
			...state,
			data: {
				...state.data,
				batchList: state.data.batchList.map(entry => {
					if (entry.identifier === payload.identifier) return payload;
					return entry;
				})
			}
		};
	}

	case REMOVE_BATCH_LIST_ITEM: {
		return {
			...state,
			data: {
				...state.data,
				batchList: state.data.batchList.filter(
					entry => entry.identifier !== payload
				)
			}
		};
	}

	case ADD_BATCH_LIST_ITEM_ROW: {
		return {
			...state,
			data: {
				...state.data,
				batchList: [
					...state.data.batchList,
					{
						...defaultState.data.batchList[0],
						identifier: _uniqueId('row-')
					}
				]
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
