import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchAllDiscounts
 * @description Fetch all discounts
 * @param {object} state
 */
export function fetchAllDiscounts(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get(`/sale/discounts`, params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editDiscount
 * @description edit
 */
export function editDiscount(addonId, data) {
	return post(`/sale/discounts/${addonId}`, data)
		.then(res => {
			setFeedback('Discount edited', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function addDiscount
 * @description add
 */
export function addDiscount(data) {
	return post(`/sale/discounts`, data)
		.then(res => {
			setFeedback('Discount added', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteDiscount
 * @description edit
 */
export function deleteDiscount(discountId) {
	return remove(`/sale/discounts/${discountId}`)
		.then(res => {
			setFeedback('Discount deleted', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
