'use strict';

import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';

import BadgeCategoriesList from './services/badgeCategoriesList.service.js';
import BadgeLevelsList from './services/badgeLevelsList.service';

const badgeCategoriesListReduxKey = '/hr/badge_categories';
const badgeCategoriesList = new BadgeCategoriesList(
	badgeCategoriesListReduxKey
);
const badgeCategoriesListProps = badgeCategoriesList.getProps();

const badgeLevelsListReduxKey = '/hr/badge_levels';
const badgeLevelsList = new BadgeLevelsList(badgeLevelsListReduxKey);
const badgeLevelsListProps = badgeLevelsList.getProps();

export function Badges() {
	useEffect(() => {
		badgeCategoriesList.initView();
		badgeLevelsList.initView();
	}, []);

	return (
		<>
			<ListComponent
				reduxKey={badgeCategoriesListReduxKey}
				{...badgeCategoriesListProps}
			/>
			<ListComponent
				reduxKey={badgeLevelsListReduxKey}
				{...badgeLevelsListProps}
			/>
		</>
	);
}
