'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import { Input, InputCollectionSelect } from 'dumb';

import _get from 'lodash/get';

import {
	setIngredientVariantOptions,
	updateIngredientVariantOptions,
	resetState
} from './store/ingredientVariantOptions.actions';

import {
	fetchPosConfigurationIngredientVariantOptions,
	editPosConfigurationIngredientVariantOptions,
	deletePosConfigurationIngredientVariantOptions,
	addPosConfigurationIngredientVariantOptions
} from './ingredientVariantOptions.service';

import phrases from './ingredientVariantOptions.phrases';

class IngredientVariantOptions extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.fetchData = this.fetchData.bind(this);

		this.columns = [
			{
				Header: 'Ingredient Variant',
				id: 'product',
				accessor: d =>
					_get(d, 'pos_configuration_ingredient.ingredient.name', null),
				filterPath: ':pos_configuration_ingredient.ingredient.name'
			},
			{
				Header: 'Sort order',
				id: 'sort_order',
				accessor: d => _get(d, 'sort_order', null),
				filterPath: ':sort_order'
			},
			{
				Header: 'Default Choice',
				id: 'default',
				accessor: d => _get(d, 'default', null),
				filterPath: ':default',
				filterable: false,
				sortable: false,
				Cell: d => {
					return (
						<input
							type="checkbox"
							checked={_get(d, 'original.default', false)}
							disabled
						/>
					);
				}
			}
		];
	}

	deleteEntry(id) {
		return deletePosConfigurationIngredientVariantOptions(id);
	}

	editEntry() {
		const { ingredientVariantOptions } = this.props;

		const payload = {
			id: ingredientVariantOptions.id,
			sort_order: Number(_get(ingredientVariantOptions, 'sortOrder', null)),
			default: _get(ingredientVariantOptions, 'default', false)
		};

		return editPosConfigurationIngredientVariantOptions(payload);
	}

	addEntry() {
		const { ingredientVariantOptions, ingredientVariant } = this.props;

		const payload = {
			default: ingredientVariantOptions.default ?? false,
			sort_order: ingredientVariantOptions.sortOrder,
			pos_configuration_ingredient_variation: ingredientVariant.id,
			pos_configuration_ingredient: _get(
				ingredientVariantOptions,
				'ingredient.value',
				null
			)
		};

		return addPosConfigurationIngredientVariantOptions(payload);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			ingredient: _get(
				data,
				'pos_configuration_ingredient.ingredient.name',
				null
			),
			sortOrder: data.sort_order,
			default: data.default
		};

		this.props.setIngredientVariantOptions(payload);
	}

	getEditableCells() {
		const { ingredientVariantOptions, ingredientVariant } = this.props;

		return [
			...(ingredientVariantOptions.id
				? [
					{
						header: 'Ingredient variant',
						value: <span>{ingredientVariantOptions.ingredient}</span>
					}
				  ]
				: [
					{
						header: 'Ingredient variant',
						value: (
							<InputCollectionSelect
								name="select-ingredient"
								value={ingredientVariantOptions.ingredient}
								handleChange={(key, value) =>
									this.editStoreEntry('ingredient', value)
								}
								clearable={false}
								cache
								apiPath="/pos/pos_configuration_ingredients"
								params={{
									limit: 300,
									filter: `:pos_configuration_version.id=='${_get(
										ingredientVariant,
										'pos_configuration_version.id',
										''
									)}'`
								}}
								optionFormat={entry => ({
									value: entry.id,
									label: entry.ingredient.name
								})}
								inputFilterFormat={input =>
									`:ingredient.name=like='%${input}%'`
								}
							/>
						)
					}
				  ]),
			{
				header: 'Sort order',
				value: (
					<Input
						value={_get(ingredientVariantOptions, 'sortOrder', '')}
						type="number"
						placeholder="Enter sort order..."
						onChange={event =>
							this.editStoreEntry('sortOrder', event.target.value)
						}
					/>
				)
			},
			{
				header: 'Default Choice',
				value: (
					<Input
						type="checkbox"
						checked={ingredientVariantOptions.default}
						onChange={event =>
							this.editStoreEntry('default', event.target.checked)
						}
					/>
				)
			}
		];
	}

	editStoreEntry(name, value) {
		const payload = {
			[name]: value
		};

		this.props.updateIngredientVariantOptions(payload);
	}

	fetchData(state) {
		const { ingredientVariant } = this.props;

		state = {
			...state,
			filter: state.filter
				? `${state.filter};:pos_configuration_ingredient_variation.id=='${ingredientVariant.id}'`
				: `:pos_configuration_ingredient_variation.id=='${ingredientVariant.id}'`
		};

		return fetchPosConfigurationIngredientVariantOptions(state);
	}

	render() {
		return (
			<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.TABLE_NAME}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={5}
				reduxKey={`pos/ingredient-variants-price-options/${this.props.ingredientVariant.id}`}
				manual
				editEntry={e => this.editEntry(e)}
				editableCells={this.getEditableCells()}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetState}
				actionsWidth={60}
				deleteEntry={this.deleteEntry}
				
				createEntry={this.addEntry}
			/>
		);
	}
}

IngredientVariantOptions.propTypes = {
	ingredientVariant: PropTypes.object,
	ingredientVariantOptions: PropTypes.object,
	updateIngredientVariantOptions: PropTypes.func,
	setIngredientVariantOptions: PropTypes.func,
	resetState: PropTypes.func
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			setIngredientVariantOptions,
			updateIngredientVariantOptions,
			resetState
		},
		dispatch
	);
};

const mapStateToProps = store => {
	return {
		ingredientVariantOptions:
			store.salesConfigurationPosConfigurationIngredientVariantOptions.data
				.ingredientVariantOptions
	};
};

export default connectWithStore(
	IngredientVariantOptions,
	mapStateToProps,
	mapDispatchToProps
);
