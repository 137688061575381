import constants from 'services/constants';
import moment from 'moment';

export function formatShiftTime(time) {
	return moment.utc(time, constants.dateFormat).format(constants.timeShort);
}

export function getShiftDate(shift) {
	return moment.utc(shift, constants.dateFormat).format(constants.shortDate);
}

export function getShiftDay(shift) {
	return moment
		.utc(shift, constants.dateFormat)
		.format(constants.nameOfWeekday);
}
