import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Dungeons N' Dragons
import { DragSource, DropTarget } from 'react-dnd';

// components
import { Icon } from 'dumb';

// util
import _get from 'lodash/get';
import cx from 'classnames';
import { getFlairToDisplay } from './utils';

import './itemRow.css';

const ProductCard = props => {
	const getFlair = entry => {
		const flairToDisplay = getFlairToDisplay(entry);

		if (!flairToDisplay) return null;

		const flairText = flairToDisplay.name;
		const backgroundColor = flairToDisplay.background_color || '000000';
		const titleColor = flairToDisplay.title_color || 'ffffff';

		return (
			<div
				className="item-row__flair"
				style={{ backgroundColor: `#${backgroundColor}` }}>
				<span style={{ color: `#${titleColor}` }}>{flairText}</span>
			</div>
		);
	};

	const {
		imageAsset,
		tile,
		removeTile,
		editTile,
		isDragging,
		connectDragSource,
		connectDropTarget
	} = props;

	const [hovering, setHovering] = useState(false);

	return connectDragSource(
		connectDropTarget(
			<div
				className={cx('item-row', {
					'item-row--hovering': hovering
				})}
				style={{ opacity: isDragging ? 0 : 1 }}
				onMouseEnter={() => setHovering(true)}
				onMouseLeave={() => setHovering(false)}>
				<div
					className="item-row__image"
					style={{
						backgroundImage: `url('${_get(
							imageAsset,
							'resolution_one.url',
							null
						)}')`,
						backgroundPosition: 'center',
						backgroundSize: 'cover'
					}}
				/>
				<div className="item-row__content">
					<span className="item-row__content__name">{tile.name}</span>
					<span className="item-row__content__description">
						{tile.description}
					</span>
				</div>

				{getFlair(tile)}

				{hovering && (
					<div className="item-row__crud">
						<Icon
							className="item-row__crud__icon"
							name="edit"
							onClick={() => editTile(tile)}
						/>
						<Icon
							className="item-row__crud__icon"
							name="delete"
							onClick={() => removeTile(tile.id)}
						/>
					</div>
				)}
			</div>
		)
	);
};

ProductCard.propTypes = {
	type: PropTypes.string,
	tile: PropTypes.object,
	imageAsset: PropTypes.string,
	removeTile: PropTypes.func,
	editTile: PropTypes.func,
	addTile: PropTypes.func,
	id: PropTypes.number,
	index: PropTypes.numebr
};

export default DropTarget(
	'productCard',
	{
		hover(props, monitor) {
			const dragIndex = monitor.getItem().index;
			const hoverIndex = props.index;
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}

			props.moveCard(dragIndex, hoverIndex);
			monitor.getItem().index = hoverIndex;
		},
		drop(props, monitor) {
			props.onDrop();
		}
	},
	connect => ({
		connectDropTarget: connect.dropTarget()
	})
)(
	DragSource(
		'productCard',
		{
			beginDrag: props => ({
				id: props.id,
				index: props.index
			})
		},
		(connect, monitor) => ({
			connectDragSource: connect.dragSource(),
			isDragging: monitor.isDragging()
		})
	)(ProductCard)
);
