'use strict';

import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import Phrases from './../terminateEmployee.phrases';
import { editEntry } from 'reactDataWrapper/reactDataWrapper.actions';
import _isEmpty from 'lodash/isEmpty';
import { store } from 'appState';
import moment from 'moment';

export const fetchAssignedShifts = (employeeId) => {
	const today = moment().format('YYYY-MM-DD');

	const params = {
		filter: `:shift_employees.employment.person.id=='${employeeId}';(:planned_period.from=ge='${today}';:planned_period.to=le='9999-12-31')`,
	};

	return get('/shiftplanning/shifts', params)
		.then((shifts) => {
			return shifts;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
		});
};

/**
 * @function postEmployeeTermination
 * @description Terminates employment for an employee
 * @param {Number} employmentId
 * @param {String} selectedDate
 * @param {String} selectedReason
 * @param {Object} resignationDocument - contains uploaded document (can be null)
 * @param {Object} otherDocument - contains uploaded document (can be null)
 *
 */
export const postEmployeeTermination = ({
	employmentId,
	selectedDate,
	selectedReason,
	resignationDocument,
	otherDocument,
}) => {
	const payload = {
		employment: employmentId,
		termination_reason: selectedReason,
		end_date: selectedDate,
	};

	return post('/hr/terminations', payload)
		.then((res) => {
			// if document uploaded
			if (resignationDocument || otherDocument) {
				const promisesToResolve = [resignationDocument, otherDocument]
					.map((document) => {
						if (!_isEmpty(document)) {
							const payload = {
								employment: employmentId,
								type: document.type,
								document: {
									filename: document.filename,
									data: document.data,
								},
							};

							return post('/hr/employment_documents', payload);
						}
					})
					.filter((x) => x);

				Promise.all(promisesToResolve)
					.then(() => {
						setFeedback(Phrases.EMPLOYMENT_TERMINATED_DOCUMENT_UPLOADED, 1);
						return res;
					})
					.catch((err) => {
						const error = formatErrorMessage(err);
						setFeedback(error, 0);
					});
			}
			setFeedback(Phrases.EMPLOYMENT_TERMINATED, 1);
			return res;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
			throw err;
		});
};

export const fetchTerminationReasons = () => {
	return get('/hr/termination_reasons')
		.then((reasons) => {
			return reasons;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
		});
};

/**
 * @function removeTermination
 * @description Delete termination from an employment. Used to rollback termination set on an employment
 * @param {Number} employmentId
 */
export const removeTermination = (rowData, reduxKey) => {
	return remove(`/hr/terminations/${rowData.termination.id}`)
		.then((res) => {
			const updatedRowData = {
				...rowData,
				ended: '9999-12-31',
				termination: null,
			};

			store.dispatch(editEntry({ reduxKey, entry: updatedRowData }));

			setFeedback(Phrases.TERMINATION_ROLLED_BACK, 1);

			return res;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
		});
};
