import {
	SET_VACATIONS,
	VACATION_ADDED,
	VACATION_DELETED,
	FETCH_TOGGLED,
	UPDATE_VACATION,
	RESET_VACATION
} from './employeeVacation.actions';

const defaultState = {
	vacations: [],
	fetching: false,
	data: {}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_VACATIONS: {
		return {
			...state,
			vacations: payload
		};
	}

	case FETCH_TOGGLED: {
		return {
			...state,
			fetching: payload
		};
	}

	case VACATION_ADDED: {
		return {
			...state,
			vacations: [...state.vacations, ...payload]
		};
	}

	case VACATION_DELETED: {
		const vacations = state.vacations.filter(x => x.id !== payload);

		if (vacations) {
			return {
				...state,
				vacations
			};
		} else {
			return {
				...state,
				vacations: []
			};
		}
	}

	case UPDATE_VACATION: {
		return {
			...state,
			data: {
				...state.data,
				...payload
			}
		};
	}

	case RESET_VACATION: {
		return {
			...state,
			data: {
				description: '',
				from: null,
				to: null,
				factor: null
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
