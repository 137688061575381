'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'dumb';
import Date from 'reusableComponents/inputs/date/date.component';
import phrases from './csvDateRange.phrases';
import constants from 'services/constants';
import moment from 'moment';
import './csvDateRange.css';

class CsvDateRange extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dates: {
				from: moment().subtract(1, 'month').format(constants.shortDate),
				to: moment().format(constants.shortDate),
			},
			loading: false,
		};

		this.handleDownloadCSV = this.handleDownloadCSV.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
	}

	/**
	 * @function handleDownloadCSV
	 * @description calls a handleCsvExport function from the parent and closes the modal
	 */
	handleDownloadCSV() {
		const { dates } = this.state;
		const { handleCSVExport, handleClose } = this.props;

		this.setState((prevState) => ({ loading: !prevState.loading }));
		handleCSVExport(dates)
			.then(() => {
				this.setState((prevState) => ({ loading: !prevState.loading }));
				handleClose();
			})
			.catch(() => {
				this.setState((prevState) => ({ loading: !prevState.loading }));
				handleClose();
			});
	}

	/**
	 * @function handleDateChange
	 * @param {Object} dates with fields startDate and endDate
	 * @description sets the new dates in state so we can use them when calling handleDownloadCSV
	 */
	handleDateChange(dates) {
		if (!dates.startDate || !dates.endDate) return;

		const formattedDates = {
			from: dates.startDate.format(constants.shortDate),
			to: dates.endDate.format(constants.shortDate),
		};

		this.setState(() => ({ dates: formattedDates }));
	}

	render() {
		const { dates, loading } = this.state;
		const { handleClose } = this.props;

		return (
			<Modal
				confirmButtonLabel="Download"
				className="new-csv-date-range__modal"
				onClose={handleClose}
				onConfirmClick={this.handleDownloadCSV}
				loading={loading}
				header={phrases.CSV_DATE_RANGE_HEADER}
			>
				<div className="new-csv-date-range__modal__content">
					<Date
						onChange={(dates) => this.handleDateChange(dates)}
						type="range"
						initialStartDate={dates.from}
						initialEndDate={dates.to}
						openDirection="down"
						showClearDate={false}
						zIndex={500}
					/>
				</div>
			</Modal>
		);
	}
}

CsvDateRange.propTypes = {
	handleCSVExport: PropTypes.func,
	handleClose: PropTypes.func,
};

export default CsvDateRange;
