'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// rdw actions
import { store } from 'appState';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';

// components
import { ModalStepper, Overlay, SingleDatePickerInput, Button } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';

// different batch views
import WorkplaceWaitingTimeGridsBatchStepForm from './components/workplaceWaitingTimeGridsBatchStepForm';
import WorkplaceWaitingTimeGridsBatchStepList from './components/workplaceWaitingTimeGridsBatchStepList';

// phrases/ constants
import phrases from './../../workplaceWaitingTimeGrids.phrases';
import constants from 'services/constants';
import moment from 'moment';

// loash
import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';
import _uniqBy from 'lodash/uniqBy';

class WorkplaceWaitingTimeGridsModalBatch extends Component {
	constructor(props) {
		super(props);

		this.workplaceReduxKey =
			'workplace-waiting-time-grids/administration/workplaces';

		this.state = {
			showOverlay: false,
			overlayType: null,
			workplaceFilter: `:state=='Operating'`,
			activeFilterWorkplaces: true,
		};

		this.handleToggleOverlay = this.handleToggleOverlay.bind(this);
		this.onOverlayOpen = this.onOverlayOpen.bind(this);
		this._getIsBatchStepFormValid = this._getIsBatchStepFormValid.bind(this);
		this.resetSelectedRows = this.resetSelectedRows.bind(this);
		this._onDateFilterChange = this._onDateFilterChange.bind(this);
		this.handleToggleActiveFilter = this.handleToggleActiveFilter.bind(this);

		this.modalRef = React.createRef();

		this.workplaceColumns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', null),
				filterPath: ':name',
			},
			{
				Header: 'Type',
				id: 'type',
				accessor: (d) => _get(d, 'type', null),
				filterPath: ':type',
			},
			{
				Header: 'Market',
				id: 'market',
				accessor: (d) => _get(d, 'market.name', null),
				filterPath: ':market.name',
			},
			{
				Header: 'State',
				id: 'state',
				accessor: (d) => _get(d, 'state', null),
				filterPath: ':state',
			},
			{
				Header: 'Opened',
				id: 'opened',
				accessor: (d) => _get(d, 'opened', null),
				filterPath: ':opened',
			},
			{
				Header: 'Grid',
				id: 'grid',
				accessor: (d) => _get(d, 'grid.name', null),
				filterPath: ':grid.name',
			},
			{
				Header: 'Workplace salary account',
				id: 'workplaceSalaryAccount',
				accessor: (d) =>
					_get(d, 'workplace_salary_account.global_account.name', null),
				filterPath: ':workplace_salary_account.global_account.name',
			},
		];
	}

	handleToggleOverlay(type) {
		const { listWorkplaces, updateBatchForm } = this.props;

		if (this.state.overlayType) {
			const selectedRowsInList = _get(listWorkplaces, 'ui.selectedRows', []);

			updateBatchForm({
				workplace: selectedRowsInList.map((entry) => {
					return { label: entry.name, value: entry };
				}),
			});
		}

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
			overlayType: prevState.overlayType ? '' : type,
		}));
	}

	onOverlayOpen() {
		const { batchFormData } = this.props;
		const { overlayType } = this.state;

		if (_isEmpty(batchFormData.workplace)) return;

		this.resetSelectedRows(overlayType);

		batchFormData.workplace.map((entry) => {
			store.dispatch(
				actions.addRowToSelection({
					reduxKey: overlayType,
					data: entry.value,
				})
			);
		});
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_onSubmitForm() {
		const { batchFormData, addBatchList } = this.props;

		// clear duplicates
		const cleanWorkplaces = _uniqBy(batchFormData.workplace, 'value.id');

		const listArray = cleanWorkplaces.map((entry) => {
			return {
				identifier: _uniqueId('row-'),
				workplace: entry,
				from: batchFormData.from || moment.utc().format(constants.shortDate),
				to: batchFormData.to || '9999-12-31',
			};
		});

		addBatchList(listArray);

		this._goToStep(2);
	}

	_batchSubmit() {
		const {
			batchListData,
			handleClose,
			batchAddData,
			waitingTimeGridId,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchListData.map((entry) => {
			return {
				waiting_time_grid: waitingTimeGridId,
				workplace: _get(entry, 'workplace.value.id', null),
				period: {
					from: moment.utc(entry.from).format(constants.shortDate),
					to: moment.utc(entry.to).format(constants.shortDate),
				},
			};
		});

		batchAddData({ batch: payload })
			.then(() => {
				handleClose();

				this.resetSelectedRows(this.workplaceReduxKey);

				this.setState(() => ({
					loadingModal: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	_getIsBatchStepFormValid() {
		const { batchFormData } = this.props;

		return !_isEmpty(batchFormData.workplace);
	}

	resetSelectedRows(reduxKey) {
		const { listWorkplaces } = this.props;

		listWorkplaces && store.dispatch(actions.resetSelection({ reduxKey }));
	}

	_onDateFilterChange(date) {
		const formattedDate = date
			? moment.utc(date).format(constants.shortDate)
			: null;

		const filterString = formattedDate
			? `:pos_configuration_version.active.from=le='${formattedDate}';:pos_configuration_version.active.to=ge='${formattedDate}'`
			: '';

		this.setState(() => ({ posConfigurationVersionDateFilter: filterString }));
	}

	getAdditionalPosConfigurationDiscountTableFilters() {
		const { posConfigurationVersionDateFilter } = this.state;

		return (
			<SingleDatePickerInput
				id="posConfigDiscountDatePicker"
				label="Select active date"
				type="single"
				appendToBody
				fixed
				onChange={this._onDateFilterChange}
				selectedDate={posConfigurationVersionDateFilter}
				noClockButton
				clearable
				clearButtonDisabled={!posConfigurationVersionDateFilter}
			/>
		);
	}

	handleToggleActiveFilter() {
		this.setState((prevState) => ({
			activeFilterWorkplaces: !prevState.activeFilterWorkplaces,
		}));
	}

	getCustomAreaComponents() {
		const { activeFilterWorkplaces } = this.state;

		return (
			<Button
				size="tiny"
				type={activeFilterWorkplaces ? '' : 'inverted'}
				shadow
				onClick={this.handleToggleActiveFilter}>
				{phrases.MODAL_BATCH_STEP_FORM_TABLE_FILTER}
			</Button>
		);
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchListData,
			editBatchListItem,
			removeBatchListItem,
			fetchWorkplaces,
		} = this.props;
		const { showOverlay } = this.state;

		const steps = [
			{
				component: (
					<WorkplaceWaitingTimeGridsBatchStepForm
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.handleToggleOverlay}
						workplaceReduxKey={this.workplaceReduxKey}
						resetSelection={this.resetSelectedRows}
						workplaceFilter={this.state.workplaceFilter}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE,
				isValid: this._getIsBatchStepFormValid(),
				onNext: () => this._onSubmitForm(),
				loading: false,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				noScroll: false,
				allowOverflow: true,
			},
			{
				component: (
					<WorkplaceWaitingTimeGridsBatchStepList
						batchListData={batchListData}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
						loyaltyProductUrl={this.loyaltyProductUrl}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						className="pos-modal-batch"
						ref={this.modalRef}
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={showOverlay ? () => {} : handleClose}
					/>
				)}

				<Overlay
					zIndex={551}
					height={850}
					list
					visible={showOverlay}
					onClose={() => {
						this.handleToggleOverlay();
					}}>
					<ReactDataWrapper
						title={phrases.MODAL_BATCH_TABLE_TITLE}
						columns={this.workplaceColumns}
						fetchData={fetchWorkplaces}
						filterable
						disableFetchCsvButton
						enableMultiSelection
						batchSelection
						defaultPageSize={20}
						onInitialization={this.onOverlayOpen}
						reduxKey={this.workplaceReduxKey}
						manual
						actionsWidth={0}
						style={{
							maxHeight: '720px',
						}}
						customAreaComponents={this.getCustomAreaComponents()}
						extraFilters={
							this.state.activeFilterWorkplaces
								? this.state.workplaceFilter
								: ''
						}
					/>
				</Overlay>
			</>
		);
	}
}

WorkplaceWaitingTimeGridsModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	listWorkplaces: PropTypes.object,
	addBatchList: PropTypes.func,
	fetchWorkplaces: PropTypes.func,
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	batchAddData: PropTypes.func,
	waitingTimeGridId: PropTypes.number,
};

export default WorkplaceWaitingTimeGridsModalBatch;
