import {
	UPDATE_INGREDIENT_VARIATION,
	SET_INGREDIENT_VARIATIONS,
	RESET_INGREDIENT_VARIATIONS,
} from './ingredientVariations.actions';

const defaultState = {
	ui: {},
	data: {
		ingredientVariation: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_INGREDIENT_VARIATIONS: {
			return {
				...state,
				data: {
					...state.data,
					ingredientVariation: payload,
				},
			};
		}

		case UPDATE_INGREDIENT_VARIATION: {
			return {
				...state,
				data: {
					...state.data,
					ingredientVariation: {
						...state.data.ingredientVariation,
						...payload,
					},
				},
			};
		}

		case RESET_INGREDIENT_VARIATIONS: {
			return {
				...state,
				data: {
					...state.data,
					ingredientVariation: {},
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
