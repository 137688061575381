import React from 'react';
import PropTypes from 'prop-types';

// components
import { InputCollectionSelect, Input, Modal, ModalRow } from 'dumb';

// utils
import phrases from './../../../staffDetails.phrases';

const GradeReviewModal = ({
	editTrainingExamData,
	updateModuleExecution,
	toggleAddReviewCommentModal,
	gradeReviewRowData,
	modalOpen,
	loading,
}) => {
	return (
		<Modal
			onClose={toggleAddReviewCommentModal}
			valid={gradeReviewRowData?.comment ?? false}
			confirmButtonLabel={
				gradeReviewRowData.isReview
					? phrases.ADD_REVIEW_CONFIRM_LABEL
					: phrases.ADD_GRADE_CONFIRM_LABEL
			}
			header={
				gradeReviewRowData.isReview ? phrases.ADD_REVIEW : phrases.ADD_GRADE
			}
			isOpen={modalOpen}
			onConfirmClick={updateModuleExecution}
			loading={loading}
			allowOverflow
			zIndex="550"
		>
			<>
				<ModalRow>
					<InputCollectionSelect
						id="moduleGradeLevel"
						label={phrases.MODAL_GRADE_INPUT}
						placeholder={phrases.MODAL_GRADE_INPUT_PLACEHOLDER}
						clearable={false}
						ignoreAccents={false}
						multi={false}
						apiPath="/training/module_grade_levels"
						apiFilter={`:module.id=='${gradeReviewRowData?.moduleId ?? ''}'`}
						value={gradeReviewRowData.moduleGradeLevel || undefined}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.grade_level.name,
						})}
						onChange={(value) =>
							editTrainingExamData('moduleGradeLevel', value)
						}
						forceRefresh
						dataCy="module-grade-level-select"
					/>
				</ModalRow>
				{gradeReviewRowData.isReview && (
					<ModalRow>
						<Input
							id="comment"
							placeholder={phrases.MODAL_REVIEW_INPUT_PLACEHOLDER}
							label={phrases.MODAL_REVIEW_INPUT}
							onChange={(value) => editTrainingExamData('comment', value)}
							value={gradeReviewRowData.comment}
							dataCy="module-grade-level-input"
						/>
					</ModalRow>
				)}
			</>
		</Modal>
	);
};

GradeReviewModal.propTypes = {
	gradeReviewRowData: PropTypes.object,
	modalOpen: PropTypes.bool,
	loading: PropTypes.bool,
	editTrainingExamData: PropTypes.func,
	updateModuleExecution: PropTypes.func,
	toggleAddReviewCommentModal: PropTypes.func,
};

export default GradeReviewModal;
