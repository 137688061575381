'use strict';

export const SET_TERMINATION_DATE = 'EMPLOYEE_TERMINATION/SET_TERMINATION_DATE';
export const SET_TERMINATION_REASON = 'EMPLOYEE_TERMINATION/SET_TERMINATION_REASON';
export const SET_ASSIGNED_SHIFTS = 'EMPLOYEE_TERMINATION/SET_ASSIGNED_SHIFTS';
export const SET_TERMINATION_REASONS = 'EMPLOYEE_TERMINATION/SET_TERMINATION_REASONS';
export const RESET_STATE = 'EMPLOYEE_TERMINATION/RESET_STATE';
export const SET_EMPLOYMENT_DOCUMENT = 'EMPLOYEE_TERMINATION/SET_EMPLOYMENT_DOCUMENT';
export const TOGGLE_MODAL_VISIBLE = 'EMPLOYEE_TERMINATION/TOGGLE_MODAL_VISIBLE';
export const SET_DEFAULT_EMPLOYMENT_DATA = 'EMPLOYEE_TERMINATION/SET_DEFAULT_EMPLOYMENT_DATA';
export const RESET_TERMINATE_THROUGH_TENANT_TRANSFER_DATA =
	'EMPLOYEE_TERMINATION/RESET_TERMINATE_THROUGH_TENANT_TRANSFER_DATA';

// Exported Actions
export const setTerminationDate = (payload) => ({
	type: SET_TERMINATION_DATE,
	payload,
});

export const setTerminationReason = (payload) => ({
	type: SET_TERMINATION_REASON,
	payload,
});

export const setTerminationReasons = (payload) => ({
	type: SET_TERMINATION_REASONS,
	payload,
});

export const setAssignedShifts = (payload) => ({
	type: SET_ASSIGNED_SHIFTS,
	payload,
});

export const resetState = () => ({ type: RESET_STATE });

export const setEmploymentDocument = (payload) => ({
	type: SET_EMPLOYMENT_DOCUMENT,
	payload,
});

export const toggleModalVisible = () => ({
	type: TOGGLE_MODAL_VISIBLE,
});

export const resetTerminateThroughTenantTransferData = () => ({
	type: RESET_TERMINATE_THROUGH_TENANT_TRANSFER_DATA,
});

export const setDefaultEmploymentData = (payload) => ({
	type: SET_DEFAULT_EMPLOYMENT_DATA,
	payload,
});
