'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';
const __endpoints = require('endpoints.service');

export default class PosConfigurationLoyaltyTypesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'external_id',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'description',
				type: 'string',
				canAdd: true,
				required: true,
			},
		];
		this.settings = {
			name: 'PosConfigurationLoyaltyTypesList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			pageTitle: true,
			translationPath: 'CAMPAIGNING.POS_CONFIGURATION_LOYALTY_TYPES_LIST',
			canManipulate: () => validAccess(undefined, ['Global Sales Configuration']),

			endpoint: __endpoints.collection.campaigning.loyalty_types,
		};
		this.init();
		return this;
	}
}
