'use strict';

// Services
import * as translations from 'translations.service';

export default class DateService {

	//////////////////////
	// Public Functions //
	//////////////////////

	GetTranslations() {
		return translations.getObject([
			'COMPONENTS.INPUTS.DATE_PLACEHOLDER',
			'COMPONENTS.INPUTS.START_DATE_PLACEHOLDER',
			'COMPONENTS.INPUTS.END_DATE_PLACEHOLDER',
			'COMPONENTS.INPUTS.SIMPLE_DATE_DAY_PLACEHOLDER',
			'COMPONENTS.INPUTS.SIMPLE_DATE_MONTH_PLACEHOLDER',
			'COMPONENTS.INPUTS.SIMPLE_DATE_YEAR_PLACEHOLDER'
		]);
	}

}