'use strict';

var headers = {
	POS_CONFIGURATION_LOYALTY_TYPES_LIST: {
		EXTERNAL_ID: 'External ID',
		DESCRIPTION: 'Description',
		NAME: 'Name'
	},

};

var titles = {
	POS_CONFIGURATION_LOYALTY_TYPES_LIST: 'POS Configuration Loyalty Types List',
};

module.exports = {
	headers: headers,
	titles: titles
};