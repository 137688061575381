'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, SingleDatePickerInput } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// constants/phrases
import constants from 'services/constants';
import phrases from './../../../workplaceWaitingTimeGrids.phrases';

// lodash
import _get from 'lodash/get';

class WorkplaceWaitingTimeGridsBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e.target ? e.target.value || e.target.checked : e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: value },
		});
	}

	_getSelectedDate(date, name) {
		if (date) return moment.utc(date).format(constants.shortDate);

		return name === 'from'
			? moment.utc().format(constants.shortDate)
			: '9999-12-31';
	}

	render() {
		const { batchListData } = this.props;

		return (
			<TableList>
				<thead>
					<tr>
						<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
						<th>{phrases.MODAL_BATCH_STEP_WORKPLACE}</th>
						<th>{phrases.MODAL_BATCH_FROM}</th>
						<th>{phrases.MODAL_BATCH_TO}</th>
						<th />
					</tr>
				</thead>
				<tbody>
					{batchListData.map((entry, i) => {
						return (
							<TableListRow key={entry.identifier}>
								<TableListCell>{i + 1}</TableListCell>
								<TableListCell>
									{_get(entry, 'workplace.label', '')}
								</TableListCell>

								<TableListCell>
									<SingleDatePickerInput
										id={`${entry.identifier}-from`}
										onChange={(event) =>
											this._editListEntry(event, entry, 'from')
										}
										selectedDate={this._getSelectedDate(entry.from, 'from')}
										noClockButton
										clearable
									/>
								</TableListCell>
								<TableListCell>
									<SingleDatePickerInput
										id={`${entry.identifier}-to`}
										onChange={(event) =>
											this._editListEntry(event, entry, 'to')
										}
										selectedDate={this._getSelectedDate(entry.to, 'to')}
										noClockButton
										clearable
									/>
								</TableListCell>

								<TableListCell>
									<Button
										id={`${entry.identifier}-delete`}
										type="negative"
										size="tiny"
										onClick={() => this._onRemoveEntry(entry.identifier)}>
										<span className="icon icon--delete" />
									</Button>
								</TableListCell>
							</TableListRow>
						);
					})}
				</tbody>
			</TableList>
		);
	}
}

WorkplaceWaitingTimeGridsBatchStepList.propTypes = {
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

export default WorkplaceWaitingTimeGridsBatchStepList;
