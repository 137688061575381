import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './workplaces.phrases';

/**
 * @function fetchWorkplaces
 * @description Fetch all workplaces
 * @param {object} state
 */
export function fetchWorkplaces(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/administration/workplaces', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editWorkplaces
 * @description edits workplace
 */
export function editWorkplaces(data) {
	return post(`/administration/workplaces/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editMultipleWorkplaces
 * @description edits multiple workplaces
 */
export function editMultipleWorkplaces(data) {
	return patch('/administration/workplaces', data)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function addWorkplaces
 * @description adds workplace
 */
export function addWorkplaces(data) {
	return post('/administration/workplaces', data)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteWorkplaces
 * @description deletes workplaces
 */
export function deleteWorkplaces(id) {
	return remove(`/administration/workplaces/${id}`)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
