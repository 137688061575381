export default {
	TITLE: 'Entries',
	WAITING_TIME_PERFORMANCE_GRID_ENTRY_ADDED:
		'Waiting time performance grid entries added!',
	WAITING_TIME_PERFORMANCE_GRID_ENTRY_EDITED:
		'Waiting time performance grid entry edited!',
	WAITING_TIME_PERFORMANCE_GRID_ENTRIES_EDITED:
		'Waiting time performance grid entries edited!',
	WAITING_TIME_PERFORMANCE_GRID_ENTRY_DELETED:
		'Waiting time performance grid entry deleted!',

	// batch form
	MODAL_BATCH_STEP_LIST_TITLE: 'Add waiting time performance grid entries',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_BADGE: 'Badge',
	MODAL_BATCH_STEP_VALUE: 'Value',
	MODAL_BATCH_STEP_PERCENTAGE: 'Percentage',
	MODAL_BATCH_STEP_MINIMUM_ORDERS: 'Minimum orders',

	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add entries',
};
