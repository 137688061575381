'use strict';

import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './productBundleSetProductVariants.phrases';

/**
 * @function fetchProductBundleSetProductVariants
 * @description Fetch product bundle sets product variants for a product bundle
 */
export function fetchProductBundleSetProductVariants(extraFilter, state) {
	const filter = state.filter ? `${state.filter};${extraFilter}` : extraFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter
	};

	return get(
		'/product/bundle_set_product_variants',
		params,
		null,
		state.headers
	)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

export function fetchProductVariants(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get('/product/product_variants', params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteProductBundleSetProductVariants
 * @param {Number} id - id of a product bundle set to delete
 */
export function deleteProductBundleSetProductVariants(id) {
	return remove(`/product/bundle_set_product_variants/${id}`)
		.then(res => {
			setFeedback(phrases.PRODUCT_BUNDLE_SET_PRODUCT_VARIANT_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

export function addProductBundleSetProductVariants(data) {
	return post('/product/bundle_set_product_variants', data)
		.then(res => {
			setFeedback(phrases.PRODUCT_BUNDLE_SET_PRODUCT_VARIANT_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
