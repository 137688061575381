export const SET_METADATA = 'DRAWER_TO_BANK_MATRIX/SET_METADATA';
export const SET_MAPPED_DATA = 'DRAWER_TO_BANK_MATRIX/SET_MAPPED_DATA';
export const SET_TOTAL_VALUES = 'DRAWER_TO_BANK_MATRIX/SET_TOTAL_VALUES';
export const SET_ADJUST_MODAL_DATA =
	'DRAWER_TO_BANK_MATRIX/SET_ADJUST_MODAL_DATA';
export const UPDATE_ADJUST_MODAL_DATA =
	'DRAWER_TO_BANK_MATRIX/UPDATE_ADJUST_MODAL_DATA';
export const CLEAR_MODAL_DATA = 'DRAWER_TO_BANK_MATRIX/CLEAR_MODAL_DATA';
export const UPDATE_CELL_VALUE =
	'DRAWER_TO_BANK_MATRIX/UPDATE_CELL_VALUE ';
export const RESET_TABLE_DATA = 'DRAWER_TO_BANK_MATRIX/RESET_TABLE_DATA';

export function setMetadata(payload) {
	return {
		type: SET_METADATA,
		payload,
	};
}

export function setMappedData(payload) {
	return {
		type: SET_MAPPED_DATA,
		payload,
	};
}

export function setTotalValues(payload) {
	return {
		type: SET_TOTAL_VALUES,
		payload,
	};
}

export function setAdjustModalData(payload) {
	return {
		type: SET_ADJUST_MODAL_DATA,
		payload,
	};
}

export function updateAdjustModalData(payload) {
	return {
		type: UPDATE_ADJUST_MODAL_DATA,
		payload,
	};
}

export function clearModalData() {
	return {
		type: CLEAR_MODAL_DATA,
	};
}

export function updateCellValue(payload) {
	return {
		type: UPDATE_CELL_VALUE,
		payload,
	};
}

export function resetTableData() {
	return {
		type: RESET_TABLE_DATA,
	};
}
