'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');

export default class TilePictureAssetsList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'description',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
		];

		this.settings = {
			name: 'TilePictureAssetsList',
			canAdd: true,
			canDelete: true,
			hasDetails: true,
			pageTitle: true,
			translationPath: 'POS.TILE_PICTURE_ASSETS',
			canManipulate: () => validAccess(undefined, ['Global Sales Configuration']),

			endpoint: __endpoints.collection.pos.tile_picture_assets,
		};
		this.init();
		return this;
	}
}
