export const SET_ENTRY_UPDATE = 'SALARY_EDIT_MODAL/SET_ENTRY_UPDATE';
export const EDIT_ENTRY_UPDATE = 'SALARY_EDIT_MODAL/EDIT_ENTRY_UPDATE';
export const RESET_ENTRY_UPDATE = 'SALARY_EDIT_MODAL/RESET_ENTRY_UPDATE';
export const EDIT_DATE_UPDATE = 'SALARY_EDIT_MODAL/EDIT_DATE_UPDATE';
export const EDIT_ENTRY_REMOVE = 'SALARY_EDIT_MODAL/EDIT_ENTRY_REMOVE';

export const UPDATE_BATCH = 'SALARY_EDIT_MODAL/UPDATE_BATCH';
export const RESET_BATCH = 'SALARY_EDIT_MODAL/RESET_BATCH';
export const BATCH_LIST_ADD = 'SALARY_EDIT_MODAL/BATCH_LIST_ADD';
export const BATCH_LIST_EDIT_ITEM = 'SALARY_EDIT_MODAL/BATCH_LIST_EDIT_ITEM';
export const BATCH_LIST_REMOVE_ITEM = 'SALARY_EDIT_MODAL/BATCH_LIST_REMOVE_ITEM';

/**
 * @param {object} payload
 */
export function setEntryUpdateForm(payload) {
	return {
		type: SET_ENTRY_UPDATE,
		payload
	};
}

/**
 * @param {object} payload
 */
export function editEntryUpdateForm(payload) {
	return {
		type: EDIT_ENTRY_UPDATE,
		payload
	};
}

/**
 * @param {object} payload
 */
export function editDateUpdate(payload) {
	return {
		type: EDIT_DATE_UPDATE,
		payload
	};
}

/**
 * @param {object} payload
 */
export function editEntryUpdateDelete(payload) {
	return {
		type: EDIT_ENTRY_REMOVE,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetEntryUpdateForm() {
	return {
		type: RESET_ENTRY_UPDATE
	};
}

// Add vatch

/**
 * @param {object} payload
 */
export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH,
		payload
	};
}

/**
 * @param {object} payload
 */
export function addBatchList(payload) {
	return {
		type: BATCH_LIST_ADD,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetBatch() {
	return {
		type: RESET_BATCH
	};
}

/**
 * @param {object} payload
 */
export function editBatchListItem(payload) {
	return {
		type: BATCH_LIST_EDIT_ITEM,
		payload
	};
}

/**
 * @param {string} payload ID
 */
export function removeBatchListItem(payload) {
	return {
		type: BATCH_LIST_REMOVE_ITEM,
		payload
	};
}
