import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';

import ProductVariantsList from './services/productVariantsList.service.js';

const productVariantsListReduxKey = '/product/product_variants';
const productVariantsList = new ProductVariantsList(productVariantsListReduxKey);
const productVariantsListProps = productVariantsList.getProps();

export function ProductVariants() {
	useEffect(() => {
		productVariantsList.initView();
	}, []);

	return <ListComponent reduxKey={productVariantsListReduxKey} {...productVariantsListProps} />;
}
