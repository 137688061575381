export const VACATION_ADDED = 'EMPLOYEE_VACATIONS/VACATION_ADDED';
export const VACATION_DELETED = 'EMPLOYEE_VACATIONS/VACATION_DELETED';
export const FETCH_TOGGLED = 'EMPLOYEE_VACATIONS/FETCH_TOGGLED';
export const SET_VACATIONS = 'EMPLOYEE_VACATIONS/SET_VACATIONS';
export const UPDATE_VACATION = 'EMPLOYEE_VACATIONS/UPDATE_VACATION';
export const RESET_VACATION = 'EMPLOYEE_VACATIONS/RESET_VACATION';

export function setLoader(payload) {
	return {
		type: FETCH_TOGGLED,
		payload
	};
}

export function vacationsFetched(payload) {
	return {
		type: SET_VACATIONS,
		payload
	};
}

export function vacationDeleted(payload) {
	return {
		type: VACATION_DELETED,
		payload
	};
}

export function vacationAdded(payload) {
	return {
		type: VACATION_ADDED,
		payload
	};
}

export function updateVacation(payload) {
	return {
		type: UPDATE_VACATION,
		payload
	};
}

export function resetVacation() {
	return {
		type: RESET_VACATION
	};
}
