import { store } from 'appState';
export const ADD_LOADER = 'FINANCIAL_POS_EXPORT/ADD_LOADER';
export const SET_COLLECTION = 'FINANCIAL_POS_EXPORT/SET_COLLECTION';
export const SET_PRINT_DATA = 'FINANCIAL_POS_EXPORT/SET_PRINT_DATA';
export const SET_DATERANGE = 'FINANCIAL_POS_EXPORT/SET_DATERANGE';
export const SET_MARKET = 'FINANCIAL_POS_EXPORT/SET_MARKET';
export const SET_STORES = 'FINANCIAL_POS_EXPORT/SET_STORES';
export const SET_HOTBAR_FILTER = 'FINANCIAL_POS_EXPORT/SET_HOTBAR_FILTER';
export const SET_TOGGLE_PRINT_VIEW =
	'FINANCIAL_POS_EXPORT/SET_TOGGLE_PRINT_VIEW';
export const SET_HEADER = 'FINANCIAL_POS_EXPORT/SET_HEADER';
export const SET_SHOW_SALARY_EXPORTS =
	'FINANCIAL_POS_EXPORT/SET_SHOW_SALARY_EXPORTS';
export const SET_PERIOD_BOOKED = 'FINANCIAL_POS_EXPORT/SET_PERIOD_BOOKED';

export function setCollection(collection) {
	store.dispatch({
		type: SET_COLLECTION,
		payload: collection,
	});
}

/**
 * @param {object} payload
 */
export function toggleLoader(payload) {
	return {
		type: ADD_LOADER,
		payload,
	};
}

/**
 * @param {array} collection
 */
export function setPrintData(printData) {
	store.dispatch({
		type: SET_PRINT_DATA,
		payload: printData,
	});
}

/**
 * @param {object} dateRange
 */
export function setDateRange(dateRange) {
	store.dispatch({
		type: SET_DATERANGE,
		payload: dateRange,
	});
}

/**
 * @param {object} market
 */
export function setMarket(market) {
	store.dispatch({
		type: SET_MARKET,
		payload: market,
	});
}

/**
 * @param {array} stores
 */
export function setStores(stores) {
	store.dispatch({
		type: SET_STORES,
		payload: stores,
	});
}

/**
 * @param {array} filter
 */
export function setHotBarFilter(filter) {
	store.dispatch({
		type: SET_HOTBAR_FILTER,
		payload: filter,
	});
}

/**
 * @param {array} filter
 */
export function togglePrintView(isPrintViewVisible) {
	store.dispatch({
		type: SET_TOGGLE_PRINT_VIEW,
		payload: isPrintViewVisible,
	});
}

/**
 * @param {array} filter
 */
export function setHeader(header) {
	store.dispatch({
		type: SET_HEADER,
		payload: header,
	});
}

/**
 * @param {boolean} showSalaryExports
 */
export function setShowSalaryExports(showSalaryExports) {
	store.dispatch({
		type: SET_SHOW_SALARY_EXPORTS,
		payload: showSalaryExports,
	});
}

/**
 * @param {boolean} showSalaryExports
 */
export function setPeriodBooked(isPeriodBooked) {
	store.dispatch({
		type: SET_PERIOD_BOOKED,
		payload: isPeriodBooked,
	});
}
