'use strict';

import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { fetchCompleteCollection } from 'api/fetchCompleteCollection';

import phrases from './salesConfigurationPosConfigurationDiscountProductVariant.phrases';

/**
 * @function fetchProductVariants
 * @description Fetch product variants
 * @param {number} versionId
 * @param {object} state
 */
export function fetchProductVariants(
	state,
	shouldFetchCompleteCollection = false
) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	const request = shouldFetchCompleteCollection
		? fetchCompleteCollection(`/pos/pos_configuration_product_variants`, params)
		: get(
				`/pos/pos_configuration_product_variants`,
				params,
				null,
				state.headers
		  );

	return Promise.resolve(request)
		.then((res) => {
			res = shouldFetchCompleteCollection
				? {
						data: res,
				  }
				: res;

			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchPosConfigurationDiscountProductVariants
 * @description Fetch discount product variants
 * @param {number} discountId
 * @param {string} customFilter
 */
export function fetchPosConfigurationDiscountProductVariants({
	state = {},
	shouldFetchCompleteCollection = false,
	posConfigurationDiscountProductVariantsFilter = null,
	productVariantFilter = null,
}) {
	let filter;
	if (state.filter) filter = state.filter;
	if (productVariantFilter)
		filter = filter
			? `${filter};${productVariantFilter}`
			: productVariantFilter;

	const params = {
		...(shouldFetchCompleteCollection
			? {
					limit: 300,
					filter: posConfigurationDiscountProductVariantsFilter,
			  }
			: {
					limit: state.limit,
					sort: state.sort,
					offset: state.offset,
					filter,
			  }),
	};

	const request = shouldFetchCompleteCollection
		? fetchCompleteCollection(
				`/pos/pos_configuration_discount_product_variants`,
				params,
				// add supoort for downloading csv - fetchCompleteCollection expects headers as third param
				state.headers || {}
		  )
		: get(
				`/pos/pos_configuration_discount_product_variants`,
				params,
				null,
				// add supoort for downloading csv
				state.headers || {}
		  );

	return request
		.then((res) => {
			res = shouldFetchCompleteCollection
				? {
						data: res,
				  }
				: res;

			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

export function addPosConfigurationDiscountProductVariant(data) {
	return post('/pos/pos_configuration_discount_product_variants', data)
		.then((res) => {
			setFeedback(phrases.POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANTS_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
			throw err;
		});
}

/**
 * @function editPosConfigurationDiscountProductVariants
 * @description edit
 */
export function editPosConfigurationDiscountProductVariants(data) {
	return patch('/pos/pos_configuration_discount_product_variants', data)
		.then((res) => {
			setFeedback(
				phrases.POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANTS_EDITED,
				1
			);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteDiscountProductVariants
 * @description edit
 */
export function deletePosConfigurationDiscountProductVariant(filter) {
	return remove(`/pos/pos_configuration_discount_product_variants${filter}`)
		.then((res) => {
			setFeedback(
				phrases.POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANTS_DELETED,
				1
			);

			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}
