'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { store, connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setWorkplaceWaitingTimeGrid,
	updateWorkplaceWaitingTimeGrid,
	resetState,
} from './store/workplaceWaitingTimeGrids.actions';

// services
import {
	fetchWorkplaces,
	fetchWorkplaceWaitingTimeGrids,
	editWorkplaceWaitingTimeGrid,
	editBatchWorkplaceWaitingTimeGrid,
	addWorkplaceWaitingTimeGrid,
	deleteWorkplaceWaitingTimeGrid,
} from './workplaceWaitingTimeGrids.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { DateFilterSelector } from 'reactDataWrapper/utilities';

import { Button, Icon, SingleDatePickerInput } from 'dumb';

import WorkplaceWaitingTimeGridsModalBatch from './components/batch/workplaceWaitingTimeGridsModalBatch';

// lodash
import _get from 'lodash/get';

// phrases/constants
import phrases from './workplaceWaitingTimeGrids.phrases';
import constants from 'services/constants';

class WorkplaceWaitingTimeGrids extends Component {
	constructor(props) {
		super(props);

		this.today = moment.utc().format(constants.shortDate);

		this.state = {
			key: moment(),
			showAddModal: false,
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleAddModal = this.toggleAddModal.bind(this);
		this.fetchDataWrapper = this.fetchDataWrapper.bind(this);

		this.reduxKey = `waiting-time-grids/administration/workplaces_waiting_time_grids-${props.waitingTimeGrid.id}`;

		this.columns = [
			{
				Header: 'Workplace',
				id: 'workplace',
				accessor: (d) => _get(d, 'workplace.name', ''),
				filterPath: ':workplace.name',
			},
			{
				Header: 'Market',
				id: 'market',
				accessor: (d) => _get(d, 'workplace.market.name', ''),
				filterPath: ':workplace.market.name',
			},
			{
				Header: 'From',
				id: 'from',
				filterPath: ':period.from',
				accessor: (d) => _get(d, 'period.from', ''),
				Filter: ({ column }) => (
					<DateFilterSelector
						reduxKey={this.reduxKey}
						columnId={column.id}
						dateIdentifier="from"
					/>
				),
			},
			{
				Header: 'To',
				id: 'to',
				filterPath: ':period.to',
				accessor: (d) => _get(d, 'period.to', ''),
				Filter: ({ column }) => (
					<DateFilterSelector
						reduxKey={this.reduxKey}
						columnId={column.id}
						dateIdentifier="to"
					/>
				),
			},
		];
	}

	editEntry() {
		const { defaultWorkplaceWaitingTimeGrid } = this.props;

		const payload = {
			id: defaultWorkplaceWaitingTimeGrid.id,
			workplace: _get(defaultWorkplaceWaitingTimeGrid, 'workplace.value', null),
			period: {
				from: moment
					.utc(defaultWorkplaceWaitingTimeGrid.from)
					.format(constants.shortDate),
				to: moment
					.utc(defaultWorkplaceWaitingTimeGrid.to)
					.format(constants.shortDate),
			},
		};

		return editWorkplaceWaitingTimeGrid(payload);
	}

	editMultiple(selectedRows) {
		const { defaultWorkplaceWaitingTimeGrid } = this.props;

		const payload = {
			...(defaultWorkplaceWaitingTimeGrid.from ||
			defaultWorkplaceWaitingTimeGrid.to
				? {
						period: {
							...(defaultWorkplaceWaitingTimeGrid.from && {
								from: moment
									.utc(defaultWorkplaceWaitingTimeGrid.from)
									.format(constants.shortDate),
							}),
							...(defaultWorkplaceWaitingTimeGrid.to && {
								to: moment
									.utc(defaultWorkplaceWaitingTimeGrid.to)
									.format(constants.shortDate),
							}),
						},
				  }
				: {}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editBatchWorkplaceWaitingTimeGrid({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteWorkplaceWaitingTimeGrid(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			workplace: data.workplace.name,
			market: data.workplace.market.name,
			from: data.period.from,
			to: data.period.to,
			singleEdit: true,
		};

		this.props.setWorkplaceWaitingTimeGrid(payload);
	}

	_getSelectedDate(date, name, singleEditMode) {
		if (!date && !singleEditMode) return '';

		if (date) return moment.utc(date).format(constants.shortDate);

		return name === 'from'
			? this.today
			: moment.utc('9999-12-31').format(constants.shortDate);
	}

	getEditableCells() {
		const { defaultWorkplaceWaitingTimeGrid } = this.props;

		const singleEditMode = defaultWorkplaceWaitingTimeGrid.singleEdit;

		return [
			...(singleEditMode
				? [
						{
							header: 'Workplace',
							value: <span>{defaultWorkplaceWaitingTimeGrid.workplace}</span>,
						},
						{
							header: 'Market',
							value: <span>{defaultWorkplaceWaitingTimeGrid.market}</span>,
						},
				  ]
				: []),
			{
				header: 'From',
				value: (
					<SingleDatePickerInput
						id="from"
						onChange={(event) => this.editStoreEntry('from', event)}
						selectedDate={this._getSelectedDate(
							defaultWorkplaceWaitingTimeGrid.from,
							'from',
							singleEditMode
						)}
						noClockButton
						clearable
					/>
				),
			},
			{
				header: 'To',
				value: (
					<SingleDatePickerInput
						id="to"
						onChange={(event) => this.editStoreEntry('to', event)}
						selectedDate={this._getSelectedDate(
							defaultWorkplaceWaitingTimeGrid.to,
							'to',
							singleEditMode
						)}
						noClockButton
						clearable
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		let value;

		if (e === null) value = e;
		else value = e.target ? e.target.value : e;

		const updateObject = {
			[name]: value,
		};

		this.props.updateWorkplaceWaitingTimeGrid(updateObject);
	}

	toggleAddModal() {
		this.setState((prevState) => ({ showAddModal: !prevState.showAddModal }));
		this.props.resetState();
	}

	getActionButtons() {
		return (
			<Button type="inverted" label="Add" shadow onClick={this.toggleAddModal}>
				<Icon name="add" />
			</Button>
		);
	}

	fetchDataWrapper(state) {
		const { waitingTimeGrid } = this.props;

		const filter = `:waiting_time_grid.id=='${waitingTimeGrid.id}'`;

		return fetchWorkplaceWaitingTimeGrids(state, filter);
	}

	render() {
		const {
			batchFormData,
			batchListData,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
			listWorkplaces,
			updateBatchForm,
			addBatchList,
			waitingTimeGrid,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					key={this.state.key}
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={this.fetchDataWrapper}
					filterable
					defaultPageSize={10}
					reduxKey={this.reduxKey}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					actionRender={this.getActionButtons()}
				/>

				<WorkplaceWaitingTimeGridsModalBatch
					modalVisible={this.state.showAddModal}
					handleClose={this.toggleAddModal}
					batchFormData={batchFormData}
					batchListData={batchListData}
					addBatchList={addBatchList}
					updateBatchForm={updateBatchForm}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					batchAddData={addWorkplaceWaitingTimeGrid}
					fetchWorkplaces={fetchWorkplaces}
					listWorkplaces={listWorkplaces}
					waitingTimeGridId={waitingTimeGrid.id}
				/>
			</>
		);
	}
}

WorkplaceWaitingTimeGrids.propTypes = {
	defaultWorkplaceWaitingTimeGrid: PropTypes.object,
	updateWorkplaceWaitingTimeGrid: PropTypes.func,
	setWorkplaceWaitingTimeGrid: PropTypes.func,
	resetState: PropTypes.func,
	batchListData: PropTypes.array,
	batchFormData: PropTypes.object,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	listWorkplaces: PropTypes.object,
	updateBatchForm: PropTypes.func,
	addBatchList: PropTypes.func,
	waitingTimeGrid: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			updateWorkplaceWaitingTimeGrid,
			setWorkplaceWaitingTimeGrid,
			resetState,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultWorkplaceWaitingTimeGrid:
			store.globalAdministrationWorkplaceWaitingTimeGrids.data
				.defaultWorkplaceWaitingTimeGrid,
		batchListData:
			store.globalAdministrationWorkplaceWaitingTimeGrids.data.batchListData,
		batchFormData:
			store.globalAdministrationWorkplaceWaitingTimeGrids.data.batchFormData,
		listWorkplaces:
			store.listData['workplace-waiting-time-grids/administration/workplaces'],
	};
};

export default connectWithStore(
	WorkplaceWaitingTimeGrids,
	mapStateToProps,
	mapDispatchToProps
);
