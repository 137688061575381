import React from 'react';
import PropTypes from 'prop-types';

// components
import { SingleDatePickerInput } from 'dumb';
import { ModalRow } from 'dumb/modal';

// utils
import moment from 'moment';
import constants from 'services/constants';
import phrases from './../shiftClockinsModal.phrases';

const AdjustBreakDates = ({
	managedShiftClockin,
	updateManagedShiftClockin,
}) => {
	const onChange = (e) => {
		if (!managedShiftClockin?.row || !managedShiftClockin?.break) return;

		let value;
		if (e === null || !moment.isMoment(e)) value = '';
		else value = e.format(constants.shortDate);

		const payload = {
			...managedShiftClockin,
			break: {
				...managedShiftClockin.break,
				clocked: {
					...managedShiftClockin.break.clocked,
					[type]: value,
				},
			},
		};

		updateManagedShiftClockin(payload);
	};

	const type = managedShiftClockin.type;
	const date = moment
		.utc(managedShiftClockin.break?.clocked?.[type], constants.dateFormat)
		.format(constants.shortDate);

	// shift dates
	const shiftDateFrom = moment
		.utc(managedShiftClockin?.row?.planned?.from, constants.dateFormat)
		.format(constants.shortDate);
	const shiftDateTo = moment
		.utc(managedShiftClockin?.row?.planned?.to, constants.dateFormat)
		.format(constants.shortDate);
	const originalShiftTimes = `${shiftDateFrom} -> ${shiftDateTo}`;

	return (
		<>
			<ModalRow>
				<div className="shift-clockins-modal__column">
					<span>{phrases.SHIFT_DATE}</span>
					<span>{originalShiftTimes}</span>
				</div>
			</ModalRow>
			<ModalRow>
				<SingleDatePickerInput
					id="adjust-clocked-date"
					label={phrases.ADJUST_BREAK_DATE}
					type="single"
					appendToBody
					fixed
					onChange={onChange}
					selectedDate={date || ''}
					noClockButton
				/>
			</ModalRow>
		</>
	);
};

AdjustBreakDates.propTypes = {
	managedShiftClockin: PropTypes.object,
	updateManagedShiftClockin: PropTypes.func,
};

export default AdjustBreakDates;
