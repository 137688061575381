'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');

export default class EmploymentBankAccountsList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'bank',
				type: 'select',
				required: true,
				canAdd: true,
				canEdit: true,
				reference: {
					key: 'name',
					list: '/administration/banks',
				},
			},
			{
				name: 'account_number',
				type: 'string',
				required: false,
				canAdd: true,
				canEdit: true,
			},
			{
				name: 'registration_number',
				type: 'string',
				required: false,
				canAdd: true,
				canEdit: true,
			},
			{
				name: 'sort_code',
				type: 'string',
				required: false,
				canAdd: true,
				canEdit: true,
			},
			{
				name: 'iban',
				type: 'string',
				required: false,
				canAdd: true,
				canEdit: true,
			},
			{
				name: 'swift',
				type: 'string',
				required: false,
				canAdd: true,
				canEdit: true,
			},
		];
		this.settings = {
			name: 'EmploymentBankAccounts',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			canCollapse: true,
			alwaysRefresh: true,
			canManipulate: () => validAccess(undefined, ['Employment Admin', 'Person Admin']),
			translationPath: 'HR.EMPLOYMENT_BANK_ACCOUNTS',
			endpoint: __endpoints.collection.hr.employment_bank_accounts,
		};
		this.init();
		return this;
	}
}
