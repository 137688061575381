'use strict';

import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';
import _get from 'lodash/get';

class Number extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			value: ''
		};

		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}

	onChange(component, event) {
		event.persist();
		this.setState(() => ({
			value: event.target.value
		}));
	}

	onBlur(component, event) {
		event.persist();
		this.props.handleChange(component, event.target.rawValue);
		this.props.handleFocusChange(component, event.target.rawValue);
	}

	componentDidMount() {
		const { component } = this.props;

		// Set selected values to carry over persistent state
		component.setSelectedValues(
			_get(this.props, 'componentState.results.value', '')
		);

		// Set state
		this.setState(() => ({
			value: _get(this.props, 'componentState.results.value', '')
		}));
	}

	render() {
		const { component } = this.props;

		return (
			<Cleave
				options={{
					numeral: true,
					numeralDecimalMark: ',',
					delimiter: '.'
				}}
				placeholder={component.getPlaceholder()}
				onBlur={event => this.onBlur(component, event)}
				onChange={event => this.onChange(component, event)}
				value={this.state.value}
			/>
		);
	}
}

Number.propTypes = {
	component: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleFocusChange: PropTypes.func.isRequired
};

export default Number;
