import { Head, HeaderCell, Body, Row, Table, TableCell } from 'dumb/report';
import constants from 'dumb/report/constants';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import getWaitingTimeValue from './../utilities/getWaitingTimeValue';

const SLOW = 'Slow';
const BUSY = 'Busy';
const TOO_BUSY = 'Too Busy';

/**
 * @function _getHourlyIntervals
 * @description Small helper function for formatting the halfhourly times correctly
 * @param {integer} hour
 * @param {integer} halfHour
 * @return string : e.g 6:00 - 6:30 if hour is 6 and halfHour is 1 or 6:30 - 7:00 if hour is 6 and halfHour is 2
 */
function _getHourlyIntervals(hour, halfHour) {
	const halfHours = { 1: '00', 2: '30' };
	const endPeriod =
		halfHour === 2
			? `${hour + 1}:${halfHours[halfHour - 1]}`
			: `${hour}:${halfHours[halfHour + 1]}`;
	return `${hour}:${halfHours[halfHour]} - ${endPeriod}`;
}

class ShiftplannerReportPrWorkplace extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			expandedRows: new Set(),
		};
	}

	/**
	 * @function _addConditionalClassnames
	 * @private
	 * @param {object} value
	 * @param {string} path
	 * @param {isFuture} path to condition that should be met in order for
	 * @param {numberOfProducts} path to products
	 */
	_addConditionalClassnames(
		value,
		path,
		isFuture,
		numberOfProducts,
		isAllStar
	) {
		const future = _get(value, isFuture, false);
		const products = _get(value, numberOfProducts, 0);
		const allStar = _get(value, isAllStar, false);

		return !future && products > 0
			? [
					{ 'shiftplanner-report__heat--slow': _get(value, path) === SLOW },
					{ 'shiftplanner-report__heat--busy': _get(value, path) === BUSY },
					{
						'shiftplanner-report__heat--too-busy':
							_get(value, path) === TOO_BUSY,
					},
					{ 'shiftplanner-report__heat--golden': allStar === true },
			  ]
			: [];
	}

	_renderRows() {
		const { showSummary } = this.props;

		return !_isEmpty(this.props.reportData)
			? this.props.reportData.map((entry, index) => {
					const hourEntry = this.props.showHalfHourly
						? _getHourlyIntervals(entry.hour, entry.half_hour)
						: `${entry.hour}:00 - ${entry.hour + 1}:00`;

					return (
						<Row
							key={entry.id}
							index={index}
							type="entry"
							totalColSpan={this.props.totalColSpan}>
							<TableCell colSpan="6" content={hourEntry} alignLeft />

							{/* Monday */}
							<TableCell
								cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
								colSpan="1"
								alignRight
								separate
								content={_get(entry, 'monday.employees', null)}
								classNames={this._addConditionalClassnames(
									entry,
									'monday.heat',
									'monday.future',
									'monday.fuel_cost',
									'monday.all_star'
								)}
							/>
							<TableCell
								cellType={constants.CELL_TYPES.INTEGER}
								colSpan="1"
								alignRight
								content={_get(entry, 'monday.optimal_employees', null)}
								classNames={this._addConditionalClassnames(
									entry,
									'monday.heat',
									'monday.future',
									'monday.fuel_cost',
									'monday.all_star'
								)}
							/>
							<TableCell
								cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
								colSpan="1"
								alignRight
								content={getWaitingTimeValue(entry, 'monday')}
								classNames={this._addConditionalClassnames(
									entry,
									'monday.heat',
									'monday.future',
									'monday.fuel_cost',
									'monday.all_star'
								)}
							/>
							<TableCell
								cellType={constants.CELL_TYPES.INTEGER}
								colSpan="1"
								alignRight
								content={_get(entry, 'monday.fuel_cost', null)}
								classNames={this._addConditionalClassnames(
									entry,
									'monday.heat',
									'monday.future',
									'monday.fuel_cost',
									'monday.all_star'
								)}
							/>

							{/* Tuesday */}
							<TableCell
								cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
								colSpan="1"
								alignRight
								separate
								content={_get(entry, 'tuesday.employees', null)}
								classNames={this._addConditionalClassnames(
									entry,
									'tuesday.heat',
									'tuesday.future',
									'tuesday.fuel_cost',
									'tuesday.all_star'
								)}
							/>
							<TableCell
								cellType={constants.CELL_TYPES.INTEGER}
								colSpan="1"
								alignRight
								content={_get(entry, 'tuesday.optimal_employees', null)}
								classNames={this._addConditionalClassnames(
									entry,
									'tuesday.heat',
									'tuesday.future',
									'tuesday.fuel_cost',
									'tuesday.all_star'
								)}
							/>
							<TableCell
								cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
								colSpan="1"
								alignRight
								content={getWaitingTimeValue(entry, 'tuesday')}
								classNames={this._addConditionalClassnames(
									entry,
									'tuesday.heat',
									'tuesday.future',
									'tuesday.fuel_cost',
									'tuesday.all_star'
								)}
							/>
							<TableCell
								cellType={constants.CELL_TYPES.INTEGER}
								colSpan="1"
								alignRight
								content={_get(entry, 'tuesday.fuel_cost', null)}
								classNames={this._addConditionalClassnames(
									entry,
									'tuesday.heat',
									'tuesday.future',
									'tuesday.fuel_cost',
									'tuesday.all_star'
								)}
							/>

							{/* Wednesday */}
							<TableCell
								cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
								colSpan="1"
								alignRight
								separate
								content={_get(entry, 'wednesday.employees', null)}
								classNames={this._addConditionalClassnames(
									entry,
									'wednesday.heat',
									'wednesday.future',
									'wednesday.fuel_cost',
									'wednesday.all_star'
								)}
							/>
							<TableCell
								cellType={constants.CELL_TYPES.INTEGER}
								colSpan="1"
								alignRight
								content={_get(entry, 'wednesday.optimal_employees', null)}
								classNames={this._addConditionalClassnames(
									entry,
									'wednesday.heat',
									'wednesday.future',
									'wednesday.fuel_cost',
									'wednesday.all_star'
								)}
							/>
							<TableCell
								cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
								colSpan="1"
								alignRight
								content={getWaitingTimeValue(entry, 'wednesday')}
								classNames={this._addConditionalClassnames(
									entry,
									'wednesday.heat',
									'wednesday.future',
									'wednesday.fuel_cost',
									'wednesday.all_star'
								)}
							/>
							<TableCell
								cellType={constants.CELL_TYPES.INTEGER}
								colSpan="1"
								alignRight
								content={_get(entry, 'wednesday.fuel_cost', null)}
								classNames={this._addConditionalClassnames(
									entry,
									'wednesday.heat',
									'wednesday.future',
									'wednesday.fuel_cost',
									'wednesday.all_star'
								)}
							/>

							{/* Thursday */}
							<TableCell
								cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
								colSpan="1"
								alignRight
								separate
								content={_get(entry, 'thursday.employees', null)}
								classNames={this._addConditionalClassnames(
									entry,
									'thursday.heat',
									'thursday.future',
									'thursday.fuel_cost',
									'thursday.all_star'
								)}
							/>
							<TableCell
								cellType={constants.CELL_TYPES.INTEGER}
								colSpan="1"
								alignRight
								content={_get(entry, 'thursday.optimal_employees', null)}
								classNames={this._addConditionalClassnames(
									entry,
									'thursday.heat',
									'thursday.future',
									'thursday.fuel_cost',
									'thursday.all_star'
								)}
							/>
							<TableCell
								cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
								colSpan="1"
								alignRight
								content={getWaitingTimeValue(entry, 'thursday')}
								classNames={this._addConditionalClassnames(
									entry,
									'thursday.heat',
									'thursday.future',
									'thursday.fuel_cost',
									'thursday.all_star'
								)}
							/>
							<TableCell
								cellType={constants.CELL_TYPES.INTEGER}
								colSpan="1"
								alignRight
								content={_get(entry, 'thursday.fuel_cost', null)}
								classNames={this._addConditionalClassnames(
									entry,
									'thursday.heat',
									'thursday.future',
									'thursday.fuel_cost',
									'thursday.all_star'
								)}
							/>

							{/* Friday */}
							<TableCell
								cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
								colSpan="1"
								alignRight
								separate
								content={_get(entry, 'friday.employees', null)}
								classNames={this._addConditionalClassnames(
									entry,
									'friday.heat',
									'friday.future',
									'friday.fuel_cost',
									'friday.all_star'
								)}
							/>
							<TableCell
								cellType={constants.CELL_TYPES.INTEGER}
								colSpan="1"
								alignRight
								content={_get(entry, 'friday.optimal_employees', null)}
								classNames={this._addConditionalClassnames(
									entry,
									'friday.heat',
									'friday.future',
									'friday.fuel_cost',
									'friday.all_star'
								)}
							/>
							<TableCell
								cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
								colSpan="1"
								alignRight
								content={getWaitingTimeValue(entry, 'friday')}
								classNames={this._addConditionalClassnames(
									entry,
									'friday.heat',
									'friday.future',
									'friday.fuel_cost',
									'friday.all_star'
								)}
							/>
							<TableCell
								cellType={constants.CELL_TYPES.INTEGER}
								colSpan="1"
								alignRight
								content={_get(entry, 'friday.fuel_cost', null)}
								classNames={this._addConditionalClassnames(
									entry,
									'friday.heat',
									'friday.future',
									'friday.fuel_cost',
									'friday.all_star'
								)}
							/>

							{/* saturday */}
							<TableCell
								cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
								colSpan="1"
								alignRight
								separate
								content={_get(entry, 'saturday.employees', null)}
								classNames={this._addConditionalClassnames(
									entry,
									'saturday.heat',
									'saturday.future',
									'saturday.fuel_cost',
									'saturday.all_star'
								)}
							/>
							<TableCell
								cellType={constants.CELL_TYPES.INTEGER}
								colSpan="1"
								alignRight
								content={_get(entry, 'saturday.optimal_employees', null)}
								classNames={this._addConditionalClassnames(
									entry,
									'saturday.heat',
									'saturday.future',
									'saturday.fuel_cost',
									'saturday.all_star'
								)}
							/>
							<TableCell
								cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
								colSpan="1"
								alignRight
								content={getWaitingTimeValue(entry, 'saturday')}
								classNames={this._addConditionalClassnames(
									entry,
									'saturday.heat',
									'saturday.future',
									'saturday.fuel_cost',
									'saturday.all_star'
								)}
							/>
							<TableCell
								cellType={constants.CELL_TYPES.INTEGER}
								colSpan="1"
								alignRight
								content={_get(entry, 'saturday.fuel_cost', null)}
								classNames={this._addConditionalClassnames(
									entry,
									'saturday.heat',
									'saturday.future',
									'saturday.fuel_cost',
									'saturday.all_star'
								)}
							/>

							{/* sunday */}
							<TableCell
								cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
								colSpan="1"
								alignRight
								separate
								content={_get(entry, 'sunday.employees', null)}
								classNames={this._addConditionalClassnames(
									entry,
									'sunday.heat',
									'sunday.future',
									'sunday.fuel_cost',
									'sunday.all_star'
								)}
							/>
							<TableCell
								cellType={constants.CELL_TYPES.INTEGER}
								colSpan="1"
								alignRight
								content={_get(entry, 'sunday.optimal_employees', null)}
								classNames={this._addConditionalClassnames(
									entry,
									'sunday.heat',
									'sunday.future',
									'sunday.fuel_cost',
									'sunday.all_star'
								)}
							/>
							<TableCell
								cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
								colSpan="1"
								alignRight
								content={getWaitingTimeValue(entry, 'sunday')}
								classNames={this._addConditionalClassnames(
									entry,
									'sunday.heat',
									'sunday.future',
									'sunday.fuel_cost',
									'sunday.all_star'
								)}
							/>
							<TableCell
								cellType={constants.CELL_TYPES.INTEGER}
								colSpan="1"
								alignRight
								content={_get(entry, 'sunday.fuel_cost', null)}
								classNames={this._addConditionalClassnames(
									entry,
									'sunday.heat',
									'sunday.future',
									'sunday.fuel_cost',
									'sunday.all_star'
								)}
							/>

							{/* Summary */}
							{showSummary && (
								<Fragment>
									<TableCell
										cellType={constants.CELL_TYPES.PERCENTAGE}
										colSpan="1"
										alignRight
										separate
										content={_get(entry, 'summary.slow_hours', null)}
									/>
									<TableCell
										cellType={constants.CELL_TYPES.PERCENTAGE}
										colSpan="1"
										alignRight
										content={_get(entry, 'summary.optimal_hours', null)}
									/>
									<TableCell
										cellType={constants.CELL_TYPES.PERCENTAGE}
										colSpan="1"
										alignRight
										content={_get(entry, 'summary.busy_hours', null)}
									/>
									<TableCell
										cellType={constants.CELL_TYPES.PERCENTAGE}
										colSpan="1"
										alignRight
										content={_get(entry, 'summary.too_busy_hours', null)}
									/>
									<TableCell
										cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
										colSpan="1"
										alignRight
										content={_get(
											entry,
											'summary.average_products_sold_hourly',
											null
										)}
									/>
								</Fragment>
							)}
						</Row>
					);
			  })
			: null;
	}

	render() {
		const { showHead, reportHeader, showSummary } = this.props;

		return (
			<div className="shiftplanner-report__workplaces">
				<Table marginBottom fixed>
					{showHead && (
						<Fragment>
							<Head>
								<Row>
									<HeaderCell colSpan="6" content={reportHeader} alignLeft />
									<HeaderCell colSpan="4" separate content="Monday" />
									<HeaderCell colSpan="4" separate content="Tuesday" />
									<HeaderCell colSpan="4" separate content="Wednesday" />
									<HeaderCell colSpan="4" separate content="Thursday" />
									<HeaderCell colSpan="4" separate content="Friday" />
									<HeaderCell colSpan="4" separate content="Saturday" />
									<HeaderCell colSpan="4" separate content="Sunday" />
									{showSummary && (
										<HeaderCell colSpan="5" separate content="Summary" />
									)}
								</Row>

								<Row subheader>
									<HeaderCell colSpan="6" content="Workplace" alignLeft />

									<HeaderCell
										colSpan="1"
										content="M. H."
										tooltip="Man Hours"
										separate
									/>
									<HeaderCell
										colSpan="1"
										content="O. H."
										tooltip="Optimal Hours"
									/>
									<HeaderCell
										colSpan="1"
										content="AWT"
										tooltip="Average Waiting Time"
									/>
									<HeaderCell
										colSpan="1"
										content="W. P."
										tooltip="Weighed Products"
									/>

									<HeaderCell
										colSpan="1"
										content="M. H."
										tooltip="Man Hours"
										separate
									/>
									<HeaderCell
										colSpan="1"
										content="O. H."
										tooltip="Optimal Hours"
									/>
									<HeaderCell
										colSpan="1"
										content="AWT"
										tooltip="Average Waiting Time"
									/>
									<HeaderCell
										colSpan="1"
										content="W. P."
										tooltip="Weighed Products"
									/>

									<HeaderCell
										colSpan="1"
										content="M. H."
										tooltip="Man Hours"
										separate
									/>
									<HeaderCell
										colSpan="1"
										content="O. H."
										tooltip="Optimal Hours"
									/>
									<HeaderCell
										colSpan="1"
										content="AWT"
										tooltip="Average Waiting Time"
									/>
									<HeaderCell
										colSpan="1"
										content="W. P."
										tooltip="Weighed Products"
									/>

									<HeaderCell
										colSpan="1"
										content="M. H."
										tooltip="Man Hours"
										separate
									/>
									<HeaderCell
										colSpan="1"
										content="O. H."
										tooltip="Optimal Hours"
									/>
									<HeaderCell
										colSpan="1"
										content="AWT"
										tooltip="Average Waiting Time"
									/>
									<HeaderCell
										colSpan="1"
										content="W. P."
										tooltip="Weighed Products"
									/>

									<HeaderCell
										colSpan="1"
										content="M. H."
										tooltip="Man Hours"
										separate
									/>
									<HeaderCell
										colSpan="1"
										content="O. H."
										tooltip="Optimal Hours"
									/>
									<HeaderCell
										colSpan="1"
										content="AWT"
										tooltip="Average Waiting Time"
									/>
									<HeaderCell
										colSpan="1"
										content="W. P."
										tooltip="Weighed Products"
									/>

									<HeaderCell
										colSpan="1"
										content="M. H."
										tooltip="Man Hours"
										separate
									/>
									<HeaderCell
										colSpan="1"
										content="O. H."
										tooltip="Optimal Hours"
									/>
									<HeaderCell
										colSpan="1"
										content="AWT"
										tooltip="Average Waiting Time"
									/>
									<HeaderCell
										colSpan="1"
										content="W. P."
										tooltip="Weighed Products"
									/>

									<HeaderCell
										colSpan="1"
										content="M. H."
										tooltip="Man Hours"
										separate
									/>
									<HeaderCell
										colSpan="1"
										content="O. H."
										tooltip="Optimal Hours"
									/>
									<HeaderCell
										colSpan="1"
										content="AWT"
										tooltip="Average Waiting Time"
									/>
									<HeaderCell
										colSpan="1"
										content="W. P."
										tooltip="Weighed Products"
									/>
									{showSummary && (
										<Fragment>
											<HeaderCell colSpan="1" content="Slow" separate />
											<HeaderCell colSpan="1" content="Op." />
											<HeaderCell colSpan="1" content="Busy" />
											<HeaderCell colSpan="1" content="T/Busy" />
											<HeaderCell colSpan="1" content="P/H" />
										</Fragment>
									)}
								</Row>
							</Head>
						</Fragment>
					)}
					<Body>{this._renderRows()}</Body>
				</Table>
			</div>
		);
	}
}

ShiftplannerReportPrWorkplace.propTypes = {
	reportData: PropTypes.array,
	totalColSpan: PropTypes.number,
	showHalfHourly: PropTypes.bool,
	showHead: PropTypes.bool,
	showSummary: PropTypes.bool,
	reportHeader: PropTypes.string,
};

ShiftplannerReportPrWorkplace.defaultProps = {
	reportData: {},
	showSummary: true,
};

export default ShiftplannerReportPrWorkplace;
