'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import phrases from './vacationsIllness.phrases';

import { Tabs } from 'dumb';
import VacationsTable from './vacationsTable/vacationsTable.container';
import IllnessTable from './illnessTable/illnessTable.container';

function VacationIllnessWrapper({ personId }) {
	const tabContent = [
		{
			name: phrases.ILLNESS_TAB,
			component: <IllnessTable personId={personId} />
		},
		{
			name: phrases.VACATION_TAB,
			component: <VacationsTable personId={personId} />
		}
	];

	return (
		<Tabs
			useLocalStorage
			tabContent={tabContent}
			id="juicerVacationIllnessWrapper"
		/>
	);
}

VacationIllnessWrapper.propTypes = {
	personId: PropTypes.number
};

export default VacationIllnessWrapper;
