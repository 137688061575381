'use strict';

/**
 * Column
 * @param  {string} name
 * @param  {array|method} content
 * @param  {method} footContent
 * @param  {number} width
 */
class Column {
	constructor(
		name,
		content,
		width,
		contentType,
		modifierClasses = [],
		colSpan
	) {
		// Set type
		this.type = 'column';

		// Set content type container
		this.content = {};

		/// ///////
		// Name //
		/// ///////

		// Check column for name
		this.name = name;

		/// //////////
		// Content //
		/// //////////

		const isContentWrapper = content.type === 'contentWrapper';

		// Set content based on contentWrapper or not
		this.content.body = isContentWrapper ? content.body : content;
		this.content.foot =
			isContentWrapper && content.foot ? content.foot : this.content.body;
		this.content.title =
			isContentWrapper && content.title ? content.title : this.content.body;

		/// ////////
		// Width //
		/// ////////

		// Always a width
		this.width = width || null;

		/// //////////////
		// ContentType //
		/// //////////////

		// Check column for contentType
		if (contentType === undefined) this.contentType = null;
		else this.contentType = contentType === null ? 'string' : contentType;

		/// //////////////////
		// ModifierClasses //
		/// //////////////////

		// If bodyContent is array, make sure that all children are of type 'content'
		this.modifierClasses = modifierClasses;

		/// ///////////
		// ColSpan //
		/// //////////

		// Check column for colSpan
		if (colSpan === undefined) this.colSpan = 1;
		else this.colSpan = colSpan;
	}

	/**
	 * @function getContent
	 * @memberOf Column
	 * @description
	 * Returns content based on context of content requested
	 * @param  {string} context
	 */
	getContent(context = 'body') {
		// return Array.isArray(this.content) ? this.content.body : this.content[context];
		return this.content[context];
	}

	/**
	 * @function getModifierClassNames
	 * @memberOf Column
	 * @description
	 * Returns modifier classes as cx expression
	 * @param  {object} data
	 */
	getModifierClassNames(data) {
		if (this.modifierClasses.length > 0) {
			return this.modifierClasses.reduce((acc, modifierClass) => {
				acc[modifierClass.className] = modifierClass.expression(data);
				return acc;
			}, {});
		} else return {};
	}

	/**
	 * @function getName
	 * @memberOf Column
	 * @description
	 * Returns unformatted content
	 * @param  {object} data
	 */
	getName(data) {
		switch (typeof this.name) {
		case 'function':
			return this.name(data);
		case 'string':
			return this.name;
		}
	}

	/**
	 * @function getWidth
	 * @memberOf Column
	 * @description
	 * Returns column width
	 * @param  {object} data
	 */
	getWidth(data) {
		if (data && this.width && typeof this.width === 'object')
			return this.width.getWidth(data);
		return this.width;
	}
}

export default Column;
