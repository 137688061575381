'use strict';

import {
	SET_ILLNESS,
	UPDATE_ILLNESS,
	RESET_STATE
} from './illnessTable.actions';

const defaultState = {
	data: {
		illnessData: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_ILLNESS: {
		return {
			...state,
			data: {
				illnessData: payload
			}
		};
	}

	case UPDATE_ILLNESS: {
		return {
			...state,
			data: {
				illnessData: {
					...state.data.illnessData,
					...payload
				}
			}
		};
	}

	case RESET_STATE: {
		return {
			...defaultState
		};
	}

	default:
		return state;
	}
}

export default reducer;
