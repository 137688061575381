'use strict';

import React from 'react';

// components
import PosConfigurationIngredientPricingVariation from './components/posConfigurationIngredientPricingVariation/posConfigurationIngredientPricingVariation.container';
import PosConfigurationProductVariantPricings from './components/posConfigurationProductVariantsPricing/posConfigurationProductVariantsPricing.container';
import PosConfigurationIngredientPricingGroups from './components/posConfigurationIngredientPricingGroups/posConfigurationIngredientPricingGroups.container';
import SalesConfigurationIngredientPricings from './components/salesConfigurationIngredientPricings/salesConfigurationIngredientPricings.container';

function PricingContainer() {
	return (
		<>
			<PosConfigurationProductVariantPricings />
			<SalesConfigurationIngredientPricings />
			<PosConfigurationIngredientPricingVariation />
			<PosConfigurationIngredientPricingGroups />
		</>
	);
}

export default PricingContainer;
