import { set as setFeedback } from 'feedback.vanilla.service.js';
import _isEmpty from 'lodash/isEmpty';

/**
 * @function addSorting
 * @description Parses filterPath parameter and
 * @param {Array} filteredColumns
 */
export default function addSorting(
	sortedColumns = [],
	columns = [],
	defaultSorted = []
) {

	// if defaultSorted passed, simply use that
	const sortedArray = _isEmpty(sortedColumns) ? defaultSorted : sortedColumns;

	return sortedArray
		.map((entry, index, arr) => {
			const sortedColumn = columns.find(column => column.id === entry.id);

			if (!sortedColumn) {
				setFeedback('No id added to column', 0);
				return;
			}

			if (!sortedColumn.filterPath) {
				setFeedback(
					`No filterpath added to Column with id ${sortedColumn.id}`,
					0
				);
				return;
			}

			return index === arr.length - 1
				? `${sortedColumn.filterPath}${entry.desc ? '+' : '-'}`
				: `${sortedColumn.filterPath}${entry.desc ? '+' : '-'},`;
		})
		.join('');
}
