import React from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import {
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,
} from './../../../store/ingredientComponentTranslations.actions';

// components
import { InputCollectionSelect, Input, Button, Icon, Tooltip } from 'dumb';
import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';

// phrases
import phrases from './../../../ingredientComponentTranslations.phrases';

const IngredientComponentTranslationsModalStep = () => {
	const dispatch = useDispatch();
	const batchList = useSelector((store) => store.ingredientComponentTranslations.batchList);

	const handleUpdateEntry = (row, type, value) => {
		const payload = {
			...row,
			[type]: value,
		};

		dispatch(editBatchListItem(payload));
	};
	const handleDeleteRow = (identifier) => () => dispatch(removeBatchListItem(identifier));
	const handleAddRow = () => dispatch(addBatchListItemRow());

	return (
		<>
			<TableList>
				<thead>
					<tr>
						<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
						<th>{phrases.NAME}</th>
						<th>{phrases.LANGUAGE}</th>

						<th />
					</tr>
				</thead>
				<tbody>
					{batchList.map((entry, i) => {
						const editButtonDisabled = batchList.length === 1;

						return (
							<TableListRow key={entry.identifier}>
								<TableListCell>{i + 1}</TableListCell>

								<TableListCell>
									<Input
										id="name"
										placeholder={phrases.NAME_PLACEHOLDER}
										value={entry.name || ''}
										onChange={(event) => handleUpdateEntry(entry, 'name', event.target.value)}
									/>
								</TableListCell>

								<TableListCell>
									<InputCollectionSelect
										id="language"
										placeholder={phrases.LANGUAGE_PLACEHOLDER}
										value={entry.language}
										handleChange={(key, value) => handleUpdateEntry(entry, 'language', value)}
										cache
										apiParams={{
											limit: 30,
										}}
										apiPath="/administration/languages"
										optionFormat={(entry) => ({
											value: entry,
											label: entry.name,
										})}
										clearable={false}
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
									/>
								</TableListCell>

								<TableListCell>
									<Button
										type="negative"
										size="tiny"
										disabled={editButtonDisabled}
										onClick={handleDeleteRow(entry.identifier)}
									>
										<Icon name="delete" />
									</Button>
								</TableListCell>
							</TableListRow>
						);
					})}
				</tbody>
			</TableList>
			<div className="filter-groups__add-new-row">
				<Tooltip
					text={phrases.ADD_ROW_TOOLTIP}
					zIndex="600"
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							type="secondary"
							onClick={handleAddRow}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
							refProp={ref}
						>
							<Icon name="add" />
						</Button>
					)}
				/>
			</div>
		</>
	);
};

IngredientComponentTranslationsModalStep.propTypes = {};

export default IngredientComponentTranslationsModalStep;
