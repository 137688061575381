export const SET_POWER_BI_REPORTS =
	'POWER_BI_REPORTS_DETAILS/SET_POWER_BI_REPORTS';
export const SET_POWER_BI_REPORT_ACCESS_TOKEN_DATA =
	'POWER_BI_REPORTS_DETAILS/SET_POWER_BI_REPORT_ACCESS_TOKEN_DATA';
export const RESET_POWER_BI_REPORT_ACCESS_TOKEN_DATA =
	'POWER_BI_REPORTS_DETAILS/RESET_POWER_BI_REPORT_ACCESS_TOKEN_DATA';

export function setPowerBIReports(payload) {
	return {
		type: SET_POWER_BI_REPORTS,
		payload,
	};
}

export function setPowerBIReportAccessTokenData(payload) {
	return {
		type: SET_POWER_BI_REPORT_ACCESS_TOKEN_DATA,
		payload,
	};
}

export function resetPowerBIReportAccessTokenData() {
	return {
		type: RESET_POWER_BI_REPORT_ACCESS_TOKEN_DATA,
	};
}
