'use strict';

import { ReportFactory as R } from 'report/factory';
import PosReportBaseColumnsService from './posReportBaseColumns.service';

const t = R.table();

const StoresView = t.table([
	t.group('Stores', [
		t.column('Stores',
			t.contentWrapper({
				body: [t.content(d => `${R.data(d, 'workplace.name').toUpperCase()}`, 'truncated')],
			}), PosReportBaseColumnsService.getFirstColumnWidth()
		),
	]),
	...PosReportBaseColumnsService.generate(true)
]);

StoresView.setHeader(false);
StoresView.setTotal(false);

export default StoresView;