import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import './checkbox.css';

class CheckBox extends PureComponent {
	render() {
		const { label, labelPosition, onClick, checked, disabled, dataCy } =
			this.props;

		return (
			<label
				className={
					labelPosition
						? 'checkbox-container-' + labelPosition
						: 'checkbox-container'
				}
			>
				{label}
				<input
					data-cy={dataCy}
					disabled={disabled}
					checked={checked}
					onClick={onClick}
					onChange={() => {}}
					name="overwrite"
					type="checkbox"
				/>
				<span className="checkbox" />
			</label>
		);
	}
}

CheckBox.defaultProps = {
	label: null,
	labelPosition: null,
	checked: false,
	onClick: function () {},
};

CheckBox.propTypes = {
	label: PropTypes.string,
	labelPosition: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	dataCy: PropTypes.string,
};

export default CheckBox;
