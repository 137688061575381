'use strict';

import PropTypes from 'prop-types';
import React, { useState } from 'react';

// components
import { Modal } from 'dumb';
import Date from 'reusableComponents/inputs/date/date.component';

import phrases from './csvDateRange.phrases';
import constants from 'services/constants';

import './csvDateRange.css';

function CsvDateRange({ handleClose, handleCSVExport }) {
	/**
	 * @function handleDownloadCSV
	 * @description calls a handleCsvExport function from the parent and closes the modal
	 */
	const handleDownloadCSV = () => {
		setLoading(true);
		handleCSVExport(dates).then(() => {
			setLoading(false);
		});
	};

	/**
	 * @function handleDateChange
	 * @param {Object} dates with fields startDate and endDate
	 * @description sets the new dates in state so we can use them when calling handleDownloadCSV
	 */
	const handleDateChange = (dates) => {
		if (!dates.startDate || !dates.endDate) return;

		const formattedDates = {
			from: dates.startDate.format(constants.shortDate),
			to: dates.endDate.format(constants.shortDate),
		};

		setDates(formattedDates);
	};

	const [dates, setDates] = useState({
		from: moment.utc().startOf('month').format(constants.shortDate),
		to: moment.utc().endOf('month').format(constants.shortDate),
	});
	const [loading, setLoading] = useState(false);

	return (
		<Modal
			confirmButtonLabel="Download"
			className="new-csv-date-range__modal"
			onClose={handleClose}
			onConfirmClick={handleDownloadCSV}
			loading={loading}
			allowOverflow
			zIndex={450}
			header={phrases.CSV_DATE_RANGE_HEADER}>
			<div className="new-csv-date-range__modal__content">
				<Date
					onChange={(dates) => handleDateChange(dates)}
					type="range"
					initialStartDate={dates.from}
					initialEndDate={dates.to}
					openDirection="down"
					showClearDate={false}
					zIndex={500}
					displayFormat={constants.shortDate}
				/>
			</div>
		</Modal>
	);
}

CsvDateRange.propTypes = {
	handleCSVExport: PropTypes.func,
	handleClose: PropTypes.func,
};

export default CsvDateRange;
