export const enums = {
	DOMAINS: ['POS', 'App', 'The List'],
	TYPES: [
		'Allergy', // a
		'Ingredient Abbreviation', // L
		'Ingredient Tile', // a
		'Menu Logo', // a
		'Product Abbreviation', // L
		'Product Details', // A
		'Product Ingredients Left', // A
		'Product Ingredients Right', // a
		'Product Tile', // !L
		'Product Variant Abbreviation', // L
		'Show Case Image',
	],
	POS_TYPES: ['Product Tile'],
	APP_TYPES: [
		'Allergy', // a
		'Ingredient Tile', // a
		'Menu Logo', // a
		'Product Details', // A
		'Product Ingredients Left', // A
		'Product Ingredients Right', // a
		'Product Recommendation',
		'Product Tile',
		'Row Layout Product Detail',
		'Row Layout Tile',
		'Show Case Image',
	],
	LIST_TYPES: [
		'Ingredient Abbreviation', // L
		'Product Abbreviation', // L
		'Product Variant Abbreviation', // L
	]
};
