/* eslint-disable react/prop-types */
import React from 'react';

// components
import { InputCollectionSelect } from 'dumb';
import { FilterProvider } from 'reactDataWrapper/utilities';

// utils
import collectionSelectEnums from 'services/enums/collectionSelect';

// lodash
import _get from 'lodash/get';

export default function getColumns(reduxKey) {
	return [
		{
			Header: 'Market',
			id: 'market',
			accessor: (d) => _get(d, 'market.name', ''),
			filterPath: ':market.name',
			Filter: ({ column }) => (
				<FilterProvider
					reduxKey={reduxKey}
					columnId={column.id}
					provide={(filterValue, persistToFilterStorage) => (
						<InputCollectionSelect
							id={column.id}
							name="market"
							value={filterValue}
							handleChange={(key, value) => {
								persistToFilterStorage({ handle: key, value });
							}}
							placeholder="Select market..."
							styleType={collectionSelectEnums.TYPE_IN_TABLE}
							apiPath="/administration/markets"
							params={{
								limit: 30,
							}}
						/>
					)}
				/>
			),
		},
		{
			Header: 'Registration type',
			id: 'registration_type',
			accessor: 'registration_type',
			filterPath: ':registration_type',
		},
		{
			Header: 'Alignment strategy',
			id: 'alignment_strategy',
			accessor: 'alignment_strategy',
			filterPath: ':alignment_strategy',
		},
		{
			Header: 'Minimum deviation',
			id: 'minimum_deviation',
			accessor: 'minimum_deviation',
			filterPath: ':minimum_deviation',
		},
		{
			Header: 'Maximum deviation',
			id: 'maximum_deviation',
			accessor: 'maximum_deviation',
			filterPath: ':maximum_deviation',
		},
	];
}
