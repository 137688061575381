import React from 'react';

const SvgJedi = props => (
	<svg width="1em" height="1em" viewBox="0 0 36 36" {...props}>
		<g fillRule="nonzero" fill="none">
			<path
				d="M12.283 33.381c-3.394-1.697-6.81 1.648-6.81 1.648-1.736.911-4.382-1.952-4.382-1.952-1.128-1.474 1.258-6.593 1.258-6.593.982-1.31 3.219-10.87 4.258-15.489A9.897 9.897 0 0 1 8.87 6.588l4.187-4.707C14.391-.348 18.183.46 18.183.46s3.34-.09 4.12.518c.78.607 6.116 6.897 6.637 7.418.52.52.824 6.116 1.215 7.157.39 1.041 2.732 10.932 3.817 12.277 1.084 1.344 1.605 4.294.954 4.815-.65.52-3.34 2.993-3.34 2.993s-1.475.433-2.733-1.345c-1.258-1.779-4.728-1.128-5.466-.824-.737.303-4.771 3.08-11.104-.087z"
				fill="#A4A4A4"
			/>
			<path
				d="M28.031 31.218c-1.44-1.27-3.02-1.67-3.94-1.51-.92.16-2.21.01-2.55 1-.35.99-.9.85-.9.85s-.46.38-.51-.13c-.05-.5-1.03-.74-2.25.43-1.23 1.17-1.41.18-1.41.18s-1.46-1.04-1.72-.61c-.25.44-.54.45-.96-.13-.42-.57-1.5-.5-1.5-.5-.61-.76-5.08 1.12-5.08 1.12l-1.18.52c-1.01.45-.91-.16-.8-.48.02-.05.04-.09.05-.12.01-.01.01-.03.02-.05 1.3-3.02 4.65-10.87 5.55-13.74 1.13-3.57 1.74-6.91 6.73-7.04 0 0 3.68-.09 5.98 4.77 0 0 5.77 14.97 6.64 16.18 0 0 1.28 2.31-2.17-.74z"
				fill="#C2C2C2"
			/>
			<path
				d="M9.386 21.842s1.771-5.25 2.67-7.325c.898-2.075 2.756-3.936 5.944-3.511 0 0 3.536-.45 6.205 5.96 2.67 6.411 2.335 6.403 2.335 6.403S15.104 31.47 9.386 21.842z"
				fill="#F9D9C0"
			/>
			<path
				d="M9.386 21.842l2.644 1.527-.06.56 1.295.654 2.458-1.37v.742l1.884 1.426 2.297-1.252-.28-.815 2.037 1.187 2.29-.092-.36-.547 2.644-1.715 1.36 3.85-1.359 2.313-1.002-.356s-1.135 1.863-2.244 2.78c0 0-.164 1.575-2.927 2.635 0 0 .469-.971.295-1.345 0 0-2.015.69-2.544 1.888 0 0-3.186-1.108-3.587-1.719l-.255.74s-1.146-1.14-1.83-1.657c-.683-.518-1.816-2.472-1.816-2.472l-.628.08-.227-1.696-1.543-1.603 1.458-3.743z"
				fill="#F4F4F4"
			/>
			<g fill="#D6B6A1">
				<path d="M17.291 20.538l-.02.34c-.03 1.1-1.02 2.33-1.06 2.38-.08.09-.18.14-.29.14-.09 0-.17-.03-.24-.09a.367.367 0 0 1-.05-.52c.24-.3.87-1.23.89-1.93l.02-.36c.03-.76.09-2.32-.31-2.73-.49-.12-3.14-.72-5.33-.24.1-.28.2-.55.29-.81 2.47-.4 5.18.32 5.31.35.05.01.09.03.13.06.71.53.72 1.91.66 3.41zM24.431 17.948c-2.62-.47-5.25.4-5.28.41-.04.01-.08.02-.12.02-.16 0-.3-.1-.35-.26-.07-.2.04-.41.23-.47.11-.04 2.55-.84 5.19-.5v.01c.12.28.23.54.33.79z" />
			</g>
			<path
				d="M26.331 22.448l-.1-.3c-.31-.9-.9-2.46-2.03-5.18-2.67-6.41-6.2-5.96-6.2-5.96-3.19-.43-5.05 1.43-5.95 3.51-.89 2.07-2.67 7.32-2.67 7.32l-1.45 3.75.59.61.86-2.21s.2-.57.48-1.41c.05-.12.09-.25.14-.38.6-1.72 1.5-4.25 2.05-5.54.9-2.07 2.76-3.93 5.95-3.51 0 0 3.53-.45 6.2 5.96a160.3 160.3 0 0 1 1.4 3.45c.17.45.31.83.42 1.15.08.22.15.41.21.58l.06.17.83 2.34.47-.8-1.26-3.55z"
				fill="#000"
				opacity={0.1}
			/>
			<g fill="#D8D6D6">
				<path d="M20.172 28.763a.378.378 0 0 1-.245-.09l-.902-.779H15.72l-1.049.63a.376.376 0 0 1-.387-.643l1.14-.683a.374.374 0 0 1 .193-.054h3.546c.09 0 .177.032.246.09l1.007.87a.376.376 0 0 1-.245.66zM18.338 29.684h-2.194a.375.375 0 0 1 0-.75h2.194a.375.375 0 0 1 0 .75z" />
			</g>
			<path
				d="M2.349 26.484s3.565-2.974 4.667-8.943c0 0-1.963 4.774-3.46 5.723 0 0-.916 3.041-1.207 3.22z"
				fill="#424242"
			/>
			<path
				d="M33.8 27.563s-2.363-1.673-3.792-5.53c0 0 1.785 2.59 2.841 3.08 0 0 .78 2.184.951 2.45z"
				fill="#464646"
			/>
		</g>
	</svg>
);

export default SvgJedi;