'use strict';

/**
 * @function refreshCollection
 * @memberOf SERVICES.__models
 * @description
 * Refresh collection from API endpoint
 */

module.exports = function() {
	this.loadCollection().then(function(collectionData) {
		this.setCollection(collectionData[0]);
		this.setCollectionLength(collectionData[1]);
	}.bind(this));
};