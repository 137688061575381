import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import useFilteredRoutes from './useFilteredRoutes';

// Create context
export const JoeRouterProvider = createContext();

const RouterListProvider = ({ children }) => {
	const { routes, filteredRoutes } = useFilteredRoutes();

	return (
		<JoeRouterProvider.Provider value={{ routes, filteredRoutes }}>
			{children}
		</JoeRouterProvider.Provider>
	);
};

RouterListProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default RouterListProvider;
