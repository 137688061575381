import {
	UPDATE_BATCH_FORM,
	ADD_BATCH_LIST,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	UPDATE_INGREDIENT_ALLERGEN,
	SET_INGREDIENT_ALLERGEN,
	RESET_INGREDIENT_ALLERGEN,
} from './ingredientAllergens.actions';

const defaultState = {
	data: {
		ingredientAllergen: {},
		defaultIngredientAllergen: {},
		batchFormData: {},
		batchListData: [],
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case UPDATE_BATCH_FORM: {
			return {
				...state,
				data: {
					...state.data,
					batchFormData: {
						...state.data.batchFormData,
						...payload,
					},
				},
			};
		}

		case ADD_BATCH_LIST: {
			return {
				...state,
				data: {
					...state.data,
					batchListData: payload,
				},
			};
		}

		case EDIT_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchListData: state.data.batchListData.map((entry) => {
						if (entry.identifier === payload.identifier) return payload;
						return entry;
					}),
				},
			};
		}

		case REMOVE_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchListData: state.data.batchListData.filter(
						(entry) => entry.identifier !== payload
					),
				},
			};
		}

		case SET_INGREDIENT_ALLERGEN: {
			return {
				...state,
				data: {
					...state.data,
					ingredientAllergen: payload,
					defaultIngredientAllergen: payload,
				},
			};
		}

		case UPDATE_INGREDIENT_ALLERGEN: {
			return {
				...state,
				data: {
					...state.data,
					ingredientAllergen: {
						...state.data.ingredientAllergen,
						...payload,
					},
				},
			};
		}

		case RESET_INGREDIENT_ALLERGEN: {
			return defaultState;
		}

		default:
			return state;
	}
}

export default reducer;
