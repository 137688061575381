// interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';
import { IMarketTagsCRUD, IMarketTagsBatchCRUD } from './types';

import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './marketTags.phrases';

function handleError(err: Error): Error {
	const error = formatErrorMessage(err);
	setFeedback(error, 0);

	throw error;
}

type fetchMarketTags = {
	state: IFetchDataProps;
	marketId?: number;
	tagId?: number;
};
/**
 * @function fetchMarketTags
 * @description Fetch all companies
 * @param {object} state
 */
export function fetchMarketTags({
	state,
	marketId,
	tagId,
}: fetchMarketTags): Promise<IApiRes<IShiftplanningMarketTag>> {
	let filter = state.filter || '';
	if (marketId)
		filter = filter
			? `${filter};:market.id=='${marketId}'`
			: `:market.id=='${marketId}'`;
	if (tagId)
		filter = filter ? `${filter};:tag.id=='${tagId}'` : `:tag.id=='${tagId}'`;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get('/shiftplanning/market_tags', params, null, state.headers)
		.then((res: IApiRes<IShiftplanningMarketTag>) => res)
		.catch(handleError);
}

/**
 * @function addMarketTags
 * @description adds marketTag
 */
export function addMarketTags(
	data: IMarketTagsBatchCRUD
): Promise<IApiRes<IShiftplanningMarketTag>> {
	return post('/shiftplanning/market_tags', data)
		.then((res: IApiRes<IShiftplanningMarketTag>) => {
			setFeedback(phrases.MARKET_TAGS_ADDED, 1);
			return res;
		})
		.catch(handleError);
}

/**
 * @function editMarketTag
 * @description edits marketTag
 */
export function editMarketTag(
	id: number,
	data: Partial<IMarketTagsCRUD>
): Promise<IApiRes<IShiftplanningMarketTag>> {
	return post(`/shiftplanning/market_tags/${id}`, data)
		.then((res: IApiRes<IShiftplanningMarketTag>) => {
			setFeedback(phrases.MARKET_TAG_EDITED, 1);
			return res;
		})
		.catch(handleError);
}

/**
 * @function editMarketTags
 * @description edits multiple market tags
 */
export function editMarketTags(
	data: Partial<IMarketTagsCRUD>[]
): Promise<IApiRes<IShiftplanningMarketTag>> {
	return patch('/shiftplanning/market_tags', { batch: data })
		.then((res: IApiRes<IShiftplanningMarketTag>) => {
			setFeedback(phrases.MARKET_TAGS_EDITED, 1);
			return res;
		})
		.catch(handleError);
}

/**
 * @function deleteMarketTags
 * @description deletes companies
 */
export function deleteMarketTags(
	id: number
): Promise<IApiRes<IShiftplanningMarketTag>> {
	return remove(`/shiftplanning/market_tags/${id}`)
		.then((res: IApiRes<IShiftplanningMarketTag>) => {
			setFeedback(phrases.MARKET_TAGS_DELETED, 1);
			return res;
		})
		.catch(handleError);
}
