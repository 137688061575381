import { get } from 'api.vanilla.service';
var __endpoints = require('endpoints.service');

export default function fetchDailyReportEmailRecipients() {
	const dailyReportEmailRecipientsEndpoint =
		__endpoints.collection.administration.mailinglist_recipients.index.path;

	const params = {
		limit: 300,
		filter: `:type=='Daily Report Operation'`,
	};

	return get(dailyReportEmailRecipientsEndpoint, params, null).then(
		(response) => response.data
	);
}
