export const RESET_FILTER_PRODUCT_VARIANT = 'SALES_CONFIGURATION_FILTER_PRODUCT_VARIANTS/RESET_FILTER_PRODUCT_VARIANT';
export const SET_FILTER_PRODUCT_VARIANT = 'SALES_CONFIGURATION_FILTER_PRODUCT_VARIANTS/SET_FILTER_PRODUCT_VARIANT';
export const UPDATE_FILTER_PRODUCT_VARIANT = 'SALES_CONFIGURATION_FILTER_PRODUCT_VARIANTS/UPDATE_FILTER_PRODUCT_VARIANT';

export const UPDATE_BATCH_FORM =
	'SALES_CONFIGURATION_FILTER_PRODUCT_VARIANTS/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'SALES_CONFIGURATION_FILTER_PRODUCT_VARIANTS/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_FILTER_PRODUCT_VARIANTS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_FILTER_PRODUCT_VARIANTS/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'SALES_CONFIGURATION_FILTER_PRODUCT_VARIANTS/ADD_BATCH_LIST_ITEM_ROW';

/**
 * @param {object} payload
 */
export function setFilterProductVariant(payload) {
	return {
		type: SET_FILTER_PRODUCT_VARIANT,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function updateFilterProductVariant(payload) {
	return {
		type: UPDATE_FILTER_PRODUCT_VARIANT,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetFilterProductVariant() {
	return {
		type: RESET_FILTER_PRODUCT_VARIANT,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}
