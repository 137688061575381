export default {
	TABLE_TITLE: 'Market shift rules',
	MARKET_SHIFT_RULE_ADDED: 'Market shift rule added',
	MARKET_SHIFT_RULE_EDITED: 'Market shift rule edited',
	MARKET_SHIFT_RULE_DELETED: 'Market shift rule deleted',

	// batch
	MODAL_BATCH_STEP_FORM_TITLE: 'Select markets',
	MODAL_BATCH_STEP_FORM_TITLE_EDIT: 'Duplicate pos configuration versions',

	MODAL_BATCH_STEP_MARKET: 'Market',
	MODAL_BATCH_STEP_MARKET_PLACEHOLDER: 'Select markets...',
	MODAL_BATCH_STEP_RULE: 'Rule',
	MODAL_BATCH_STEP_RULE_PLACEHOLDER: 'Select rules...',
	MODAL_BATCH_STEP_FORM_TABLE_TITLE: 'Markets',

	MODAL_BATCH_STEP_ACTIVE: 'Active',
	MODAL_BATCH_STEP_FORM_MARKET_TABLE_TITLE: 'Markets',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'Next',
	MODAL_BATCH_STEP_LIST_TITLE: 'Confirm entries',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Submit',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'Back',
};
