// interface
import { ActionType } from 'types/redux';
import {
	IPowerBiReportDatasetReduxValue,
	IPowerBiReportDatasetReduxState,
	IPowerBiReportDatasetReduxValueBatch,
} from '../types';

// utils
import moment from 'moment';
import _uniqueId from 'lodash/uniqueId';

import {
	SET_POWER_BI_REPORT_DATA_SET,
	UPDATE_POWER_BI_REPORT_DATA_SET,
	RESET_STATE,

	// batch
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	ADD_BATCH_LIST_ITEM_ROW,
	RERENDER_TABLE,
} from './powerBiReportDataSets.actions';

const defaultState: IPowerBiReportDatasetReduxState = {
	tableKey: moment().unix(),
	data: {
		powerBiReportDataset: {
			id: undefined,
			dataset_id: null,
		},
	},
	batchList: [
		{
			identifier: _uniqueId('row-'),
			dataset_id: null,
		},
	],
};

function reducer(
	state = defaultState,
	action: ActionType
): IPowerBiReportDatasetReduxState {
	const { type, payload } = action;

	switch (type) {
	case SET_POWER_BI_REPORT_DATA_SET: {
		const castedPayload = payload as IPowerBiReportDatasetReduxValue;

		return {
				...state,
				data: {
					powerBiReportDataset: castedPayload,
				},
			};
	}

	case UPDATE_POWER_BI_REPORT_DATA_SET: {
		const castedPayload = payload as Partial<IPowerBiReportDatasetReduxValue>;

		return {
				...state,
				data: {
					...state.data,
					powerBiReportDataset: {
						...state.data.powerBiReportDataset,
						...castedPayload,
					},
				},
			};
	}

	case RESET_STATE: {
		return defaultState;
	}

	case RERENDER_TABLE:
		return {
				...state,
				tableKey: moment().unix(),
			};

	case EDIT_BATCH_LIST_ITEM: {
		const castedPayload =
				payload as Partial<IPowerBiReportDatasetReduxValueBatch>;

		return {
				...state,
				batchList: state.batchList.map((entry) => {
					if (entry.identifier === castedPayload.identifier) {
						return { ...entry, ...castedPayload };
					}
					return entry;
				}),
			};
	}

	case REMOVE_BATCH_LIST_ITEM: {
		const castedPayload = payload as string;

		return {
				...state,
				batchList: state.batchList.filter(
					(entry) => entry.identifier !== castedPayload
				),
			};
	}

	case ADD_BATCH_LIST_ITEM_ROW:
		return {
				...state,
				batchList: [
					...state.batchList,
					{
						...defaultState.batchList[0],
						identifier: _uniqueId('row-'),
					},
				],
			};

	default:
		return state;
	}
}

export default reducer;
