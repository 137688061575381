export const RESET_PRODUCT_VARIANT_GROUP =
	'SALES_CONFIGURATION_PRODUCT_VARIANT_GROUPS/RESET_PRODUCT_VARIANT_GROUP';
export const SET_PRODUCT_VARIANT_GROUP =
	'SALES_CONFIGURATION_PRODUCT_VARIANT_GROUPS/SET_PRODUCT_VARIANT_GROUP';
export const UPDATE_PRODUCT_VARIANT_GROUP =
	'SALES_CONFIGURATION_PRODUCT_VARIANT_GROUPS/UPDATE_PRODUCT_VARIANT_GROUP';

export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_PRODUCT_VARIANT_GROUPS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_PRODUCT_VARIANT_GROUPS/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'SALES_CONFIGURATION_PRODUCT_VARIANT_GROUPS/ADD_BATCH_LIST_ITEM_ROW';

export function setProductVariantGroup(payload) {
	return {
		type: SET_PRODUCT_VARIANT_GROUP,
		payload,
	};
}

export function updateProductVariantGroup(payload) {
	return {
		type: UPDATE_PRODUCT_VARIANT_GROUP,
		payload,
	};
}

export function resetProductVariantGroup() {
	return {
		type: RESET_PRODUCT_VARIANT_GROUP,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}
