'use strict';

import ListModel from 'common/components/list/listModel.service';

// Services
const __endpoints = require('endpoints.service');
const __enums = require('enums.service');

export default class ProductCategoriesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'category_group',
				type: 'select',
				canAdd: true,
				required: true,
				options: {
					enums: __enums.categoryGroups,
				},
			},
		];
		this.settings = {
			name: 'ProductCategoriesList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			translationPath: 'SALE.PRODUCT_CATEGORIES',
			endpoint: __endpoints.collection.product.product_categories,
		};
		this.init();
		return this;
	}
}
