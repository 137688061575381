import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import PasswordForms from 'structure/auth/passwordForms.jsx';
import { App, GuardedApp } from './app';

function AppWrapper() {
	const location = useLocation();
	const { pathname } = location;
	const isReset = !!(pathname.split('/')[1] === 'reset');
	const isActivatePassword = !!(pathname.split('/')[1] === 'activate-password');

	return isReset || isActivatePassword ? (
		<Routes>
			<Route path="reset" element={<PasswordForms />} />
			<Route path="activate-password" element={<PasswordForms />} />
		</Routes>
	) : process.env.CYPRESS_TEST === 'true' ? (
		<App />
	) : (
		<GuardedApp />
	);
}

export default AppWrapper;
