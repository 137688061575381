'use strict';

import { connectWithStore } from 'appState';
import cx from 'classnames';
import Hotdamnbar from 'hotdamnbar';
import _each from 'lodash/each';
import _map from 'lodash/map';
import _some from 'lodash/some';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import GoldModelHotbar from '../../services/goldModel.hotbar';
import Controls from './components/goldModelControls.component';
import IdealControls from './components/idealsModelControl/idealModelControl.component';
import GoldModelDownloadCsv from './components/goldModelDownloadCsv.component';
import Headers from './components/goldModelHeaders.component';
import Row from './components/goldModelRow.component';
import { Typography } from '@mui/material';

import './goldModel.css';
/**
 * @namespace jtjGoldModel.component
 * @memberOf COMPONENTS
 * @example
 * <react-component name="jtjGoldModel" props="props"></react-component>
 */

class GoldModel extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isModalActive: false,
			scrollWrapperElement: null,
			scrollWrapperEventMounted: false,
			contentType: 'turnover',
		};

		this.onHotbarChange = this.onHotbarChange.bind(this);
		this.onMouseWheel = this.onMouseWheel.bind(this);
		this.onContentTypeChange = this.onContentTypeChange.bind(this);
		this.onPeriodTypeChange = this.onPeriodTypeChange.bind(this);
		this.attachPreventHistoryBack = this.attachPreventHistoryBack.bind(this);
		this.isModalActive = this.isModalActive.bind(this);
	}

	// Prevent going back in history with mousewheel left scroll
	attachPreventHistoryBack() {
		if (this.scrollWrapper && !this.state.scrollWrapperEventMounted) {
			this.scrollWrapper.addEventListener('mousewheel', this.onMouseWheel);
			this.setState({ scrollWrapperEventMounted: true });
		}
	}

	onMouseWheel(event) {
		const element = this.scrollWrapper;
		const deltaX = event.deltaX || event.wheelDeltaX;

		if (!deltaX) return;

		const conditions = [
			element.scrollLeft + element.offsetWidth === element.scrollWidth && deltaX > 0,
			element.scrollLeft === 0 && deltaX < 0,
		];

		if (_some(conditions)) event.preventDefault();
	}

	onHotbarChange(event) {
		this.props.methods.handleHotbarChange(event);
	}

	isModalActive() {
		this.setState((prevState) => ({ isModalActive: !prevState.isModalActive }));
	}

	// Focus on date cell in this collection
	getRowNames(collection) {
		const newCollection = [];
		_each(collection, (row) => {
			newCollection.push({
				rowName: row.name,
			});
		});
		return newCollection;
	}

	onPeriodTypeChange(type) {
		const { methods } = this.props;
		methods.handleSetPeriodType(type);
	}

	onContentTypeChange(type) {
		this.setState({ contentType: type });
	}

	renderPeriodTypeChooser() {
		const { translations, periodType } = this.props;
		const types = ['monthly', 'daily'];

		return _map(types, (type) => {
			const typeCx = cx('gold-model__content-select__content-type', {
				'gold-model__content-select__content-type--active': type === periodType,
			});
			return (
				<li key={type} onClick={this.onPeriodTypeChange.bind(this, type)} className={typeCx}>
					{translations[type.toUpperCase()]}
				</li>
			);
		});
	}

	renderContentTypeChooser() {
		const { translations } = this.props;
		const types = ['turnover', 'location', 'salary', 'doc', 'cgs'];

		return _map(types, (type) => {
			const typeCx = cx('gold-model__content-select__content-type', {
				'gold-model__content-select__content-type--active': type === this.state.contentType,
			});
			return (
				<li key={type} onClick={this.onContentTypeChange.bind(this, type)} className={typeCx}>
					{translations[type.toUpperCase()]}
				</li>
			);
		});
	}

	renderNameRows(collection) {
		// Map collection to rows
		return _map(collection, (row, key) => {
			return <Row key={key} row={row} isModalActive={this.isModalActive} {...this.props} />;
		});
	}

	renderRows(collection) {
		// Map collection to rows
		return collection.map((row, key) => {
			return (
				<Row
					key={key}
					row={row.dates}
					name={row.name}
					workplace={row.id}
					contentType={this.state.contentType}
					isModalActive={this.isModalActive}
					{...this.props}
				/>
			);
		});
	}

	componentDidUpdate() {
		this.attachPreventHistoryBack();
	}

	render() {
		const { collection, showMatrice } = this.props;

		const listCx = cx('list', 'list--tiny', 'list--matrice', {});

		return (
			<div className="gold-model">
				<Typography variant="h1" component="h1">
					{this.props.translations.TITLE}
				</Typography>

				<Hotdamnbar onChange={this.onHotbarChange} hotbar={GoldModelHotbar} />

				<div className="gold-model__control-wrapper">
					<Controls {...this.props} />
					{/* {showMatrice &&
						<ul data-label={this.props.translations.PERIOD_TYPES} className="gold-model__content-select">
							{this.renderPeriodTypeChooser()}
						</ul>
					} */}
					<IdealControls {...this.props} />
					{/* {showMatrice &&
						<ul data-label={this.props.translations.PERIOD_TYPES} className="gold-model__content-select">
							{this.renderPeriodTypeChooser()}
						</ul>
					} */}

					{showMatrice && (
						<>
							<ul data-label={this.props.translations.TYPES} className="gold-model__content-select">
								{this.renderContentTypeChooser()}
							</ul>

							<GoldModelDownloadCsv handleFetchCsv={this.props.methods.handleFetchCsv} />
						</>
					)}
				</div>

				{showMatrice && (
					<div className="list-lefter-wrapper">
						<table className="list list--tiny list--matrice list--lefter">
							<thead>
								<tr>
									<th className="list__header-cell">
										<span className="list__cell-contents">&nbsp;</span>
									</th>
								</tr>
							</thead>
							<tbody>{this.renderNameRows(this.getRowNames(collection))}</tbody>
						</table>
						<div
							className="list-scroll-wrapper"
							ref={(ref) => {
								this.scrollWrapper = ref;
							}}>
							<table className={listCx}>
								{collection.length > 0 && <Headers firstRow={collection[0]} />}
								<tbody>{this.renderRows(collection)}</tbody>
							</table>
						</div>
					</div>
				)}
			</div>
		);
	}
}

GoldModel.propTypes = {
	collection: PropTypes.array.isRequired,
	showMatrice: PropTypes.bool.isRequired,
	periodType: PropTypes.string.isRequired,
	methods: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => {
	return {
		collection: store.goldModel.collection,
		translations: store.goldModel.translations,
		showMatrice: store.goldModel.showMatrice,
		periodType: store.goldModel.periodType,
	};
};

export default connectWithStore(GoldModel, mapStateToProps);
