import React, { useState } from 'react';
import PropTypes from 'prop-types';

// components
import { Text } from 'dumb';
import { ModalRow } from 'dumb/modal';
import ShiftTransferPendingTransfersOwn from './components/shiftTransferPendingTransfersOwn';
import ShiftTransferPendingTransfersApplied from './components/shiftTransferPendingTransfersApplied';

// phrases/ enums
import { ShiftTransfer as enums } from 'services/enums';
import shiftTransferApprovalEnums from './../../../../shiftTransferApproval.enums';

// utils
import {
	formatShiftTime,
	getShiftDate,
	getShiftDay,
} from './../../../../utils';

// lodash
import _isEmpty from 'lodash/isEmpty';

// tag color constants
const defaultColor = shiftTransferApprovalEnums.COLORS.DEFAULT;
const positiveColor = shiftTransferApprovalEnums.COLORS.POSITIVE;
const negativeColor = shiftTransferApprovalEnums.COLORS.NEGATIVE;

const ShiftTransferPendingTransfersList = ({
	pendingTransfers,
	updateShiftTransfer,
	user,
	handleUpdateShiftTransferApplication,
}) => {
	const updateShiftTransferWrapper = ({ shiftTransfer, operation }) => {
		const payload = { shiftTransfer, operation };

		setLoading({ id: shiftTransfer.id });

		updateShiftTransfer(payload).finally(() => setLoading(initialLoadingState));
	};

	const updateShiftTransferApplicationWrapper = ({
		shiftTransfer,
		operation,
	}) => {
		const payload = { shiftTransfer, operation, applied: true };

		// if declining, set loaders
		if (operation === enums.OPERATIONS.DECLINE) {
			setLoading({ id: shiftTransfer.id });

			handleUpdateShiftTransferApplication(payload).finally(() =>
				setLoading(initialLoadingState)
			);
		}
		// if not declining we will open a policy modal and therefor handleUpdateShiftTransferApplication doesn't return a promise
		else handleUpdateShiftTransferApplication(payload);
	};

	const getApplicantList = (shiftTransfer) => {
		return (
			<ul className="shift-transfer-approval-modal__table-wrapper__row-cell__applicants">
				{shiftTransfer.applications.map((entry) => {
					const name = entry.applicant?.person?.full_name ?? '';

					return (
						<li
							className="shift-transfer-approval-modal__table-wrapper__row-cell__applicants__applicant"
							key={entry.id}>
							<Text size="tiny">{name}</Text>
						</li>
					);
				})}
			</ul>
		);
	};

	const initialLoadingState = { id: null };
	const [loading, setLoading] = useState(initialLoadingState);

	const personId = user.user?.person?.id;

	const ownShifts = pendingTransfers.filter(
		(entry) => entry.donor?.person?.id === personId
	);
	const appliedShifts = pendingTransfers.filter(
		(entry) => entry.donor?.person?.id !== personId
	);

	return (
		<>
			{!_isEmpty(ownShifts) && (
				<ModalRow>
					<ShiftTransferPendingTransfersOwn
						ownShifts={ownShifts}
						getShiftDate={getShiftDate}
						formatShiftTime={formatShiftTime}
						getShiftDay={getShiftDay}
						updateShiftTransferWrapper={updateShiftTransferWrapper}
						loading={loading}
						defaultColor={defaultColor}
						positiveColor={positiveColor}
						negativeColor={negativeColor}
						getApplicantList={getApplicantList}
					/>
				</ModalRow>
			)}
			{!_isEmpty(appliedShifts) && (
				<ModalRow className="shift-transfer-approval-modal__applied-shifts">
					<ShiftTransferPendingTransfersApplied
						appliedShifts={appliedShifts}
						getShiftDate={getShiftDate}
						formatShiftTime={formatShiftTime}
						getShiftDay={getShiftDay}
						updateShiftTransferApplicationWrapper={
							updateShiftTransferApplicationWrapper
						}
						loading={loading}
						personId={personId}
						getApplicantList={getApplicantList}
						defaultColor={defaultColor}
					/>
				</ModalRow>
			)}
		</>
	);
};

ShiftTransferPendingTransfersList.propTypes = {
	pendingTransfers: PropTypes.array,
	updateShiftTransfer: PropTypes.func,
	handleUpdateShiftTransferApplication: PropTypes.func,
	user: PropTypes.object,
};

export default ShiftTransferPendingTransfersList;
