'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// classnames
import cx from 'classnames';

import {
	SingleDatePickerInput,
	InputCollectionSelect,
	ButtonGroup,
	Button,
	Icon,
} from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';
import { Tip } from 'dumb/tips';

// constants/phrases
import phrases from './../../../workplacePosConfiguration.phrases';
import constants from 'services/constants';

class WorkplacePosConfigurationBatchStepFormRowDuplicateAsNew extends Component {
	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		// get value from event
		let value;
		if (e === null) value = e;
		else value = e.target ? e.target.value : e;

		// if dates cleared, set them to today and infinite
		if ((type === 'activeFrom' || type === 'activeTo') && value === null) {
			value =
				type === 'activeFrom'
					? moment.utc().format(constants.shortDate)
					: '9999-12-31';
		}

		updateBatchForm({
			[type]: value,
		});
	}

	_getSelectedDate(date, name) {
		if (date) return moment.utc(date).format(constants.shortDate);

		return name === 'to' ? '9999-12-31' : '';
	}

	render() {
		const {
			batchFormData,
			posConfigurationReduxKey,
			ingredientConfigurationReduxKey,
			productRecommendationReduxKey,
			appLayoutReduxKey,
			posLayoutReduxKey,
			toggleOverlay,
			loading,
		} = this.props;

		return (
			<div
				className={cx(
					'workplace-pos-configuration__modal-batch__duplicate-as-new__global-entries',
					{
						'workplace-pos-configuration__modal-batch__duplicate-as-new__global-entries--active': !loading,
					}
				)}>
				<Tip content={phrases.MODAL_BATCH_STEP_GLOBAL_ROW_TIP} />
				<TableList>
					<thead>
						<tr>
							<th />
							<th>{phrases.MODAL_BATCH_STEP_POS_CONFIGURATION}</th>
							<th>{phrases.MODAL_BATCH_STEP_WORKPLACE}</th>
							<th>{phrases.MODAL_BATCH_STEP_PRODUCT_RECOMMENDATION}</th>
							<th>{phrases.MODAL_BATCH_STEP_POS_TILE_LAYOUT}</th>
							<th>{phrases.MODAL_BATCH_STEP_APP_TILE_LAYOUT}</th>
							<th>{phrases.MODAL_BATCH_STEP_INGREDIENT_CONFIGURATION}</th>
							<th>{phrases.MODAL_BATCH_STEP_ACTIVE_FROM}</th>
							<th>{phrases.MODAL_BATCH_STEP_ACTIVE_TO}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						<TableListRow>
							<TableListCell width="30px" />
							<TableListCell width="200px">
								<ButtonGroup type="flex">
									<InputCollectionSelect
										id="pos_configuration"
										placeholder={
											phrases.MODAL_BATCH_STEP_POS_CONFIGURATION_PLACEHOLDER
										}
										value={batchFormData.posConfiguration}
										handleChange={(key, value) =>
											this.editStoreEntry(value, 'posConfiguration')
										}
										cache
										apiPath="/pos/pos_configurations"
										params={{
											limit: 30,
											filter: ':legacy==false',
										}}
										optionFormat={(entry) => ({
											value: entry,
											label: entry.name,
										})}
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
									/>
									<Button
										type="inverted"
										shadow
										size="medium"
										onClick={() => toggleOverlay(posConfigurationReduxKey)}>
										<Icon name="more" />
									</Button>
								</ButtonGroup>
							</TableListCell>

							<TableListCell width="180px">
								<InputCollectionSelect
									id="workplace"
									placeholder={phrases.MODAL_BATCH_STEP_WORKPLACE_PLACEHOLDER}
									value={batchFormData.workplace}
									handleChange={(key, value) =>
										this.editStoreEntry(value, 'workplace')
									}
									cache
									apiPath="/administration/workplaces"
									params={{
										limit: 50,
										filter: batchFormData.marketsFilter,
									}}
									optionFormat={(entry) => ({
										value: entry,
										label: entry.name,
									})}
									inputFilterFormat={(input) => `:name=like='%${input}%'`}
								/>
							</TableListCell>

							<TableListCell width="200px">
								<ButtonGroup type="flex">
									<InputCollectionSelect
										id="productRecommendation"
										placeholder={
											phrases.MODAL_BATCH_STEP_PRODUCT_RECOMMENDATION_PLACEHOLDER
										}
										value={batchFormData.productRecommendation}
										handleChange={(key, value) =>
											this.editStoreEntry(value, 'productRecommendation')
										}
										cache
										optionFormat={(entry) => ({
											value: entry,
											label: entry.name,
										})}
										apiPath="/pos/product_recommendations"
										params={{
											limit: 30,
										}}
									/>
									<Button
										type="inverted"
										shadow
										size="medium"
										onClick={() =>
											toggleOverlay(productRecommendationReduxKey)
										}>
										<Icon name="more" />
									</Button>
								</ButtonGroup>
							</TableListCell>

							<TableListCell width="200px">
								<ButtonGroup type="flex">
									<InputCollectionSelect
										id="posTileLayout"
										placeholder={
											phrases.MODAL_BATCH_STEP_POS_TILE_LAYOUT_PLACEHOLDER
										}
										value={batchFormData.posTileLayout}
										handleChange={(key, value) =>
											this.editStoreEntry(value, 'posTileLayout')
										}
										cache
										optionFormat={(entry) => ({
											value: entry,
											label: `${entry.name} - ${entry.description}`,
										})}
										apiPath="/pos/tile_layouts"
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
										params={{
											limit: 30,
											filter: `:type=='POS'`,
										}}
									/>
									<Button
										type="inverted"
										shadow
										size="medium"
										onClick={() => toggleOverlay(posLayoutReduxKey)}>
										<Icon name="more" />
									</Button>
								</ButtonGroup>
							</TableListCell>

							<TableListCell width="200px">
								<ButtonGroup type="flex">
									<InputCollectionSelect
										id="appTileLayout"
										placeholder={
											phrases.MODAL_BATCH_STEP_APP_TILE_LAYOUT_PLACEHOLDER
										}
										value={batchFormData.appTileLayout}
										handleChange={(key, value) =>
											this.editStoreEntry(value, 'appTileLayout')
										}
										cache
										optionFormat={(entry) => ({
											value: entry,
											label: `${entry.name} - ${entry.description}`,
										})}
										apiPath="/pos/tile_layouts"
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
										params={{
											limit: 30,
											filter: `:type=='App'`,
										}}
									/>
									<Button
										type="inverted"
										shadow
										size="medium"
										onClick={() => toggleOverlay(appLayoutReduxKey)}>
										<Icon name="more" />
									</Button>
								</ButtonGroup>
							</TableListCell>

							<TableListCell width="200px">
								<ButtonGroup type="flex">
									<InputCollectionSelect
										id="ingredientConfiguration"
										placeholder={
											phrases.MODAL_BATCH_STEP_INGREDIENT_CONFIGURATION_PLACEHOLDER
										}
										value={batchFormData.ingredientConfiguration}
										handleChange={(key, value) =>
											this.editStoreEntry(value, 'ingredientConfiguration')
										}
										cache
										optionFormat={(entry) => ({
											value: entry,
											label: entry.name,
										})}
										apiPath="/pos/ingredient_configurations"
										params={{
											limit: 30,
										}}
									/>
									<Button
										type="inverted"
										shadow
										size="medium"
										onClick={() =>
											toggleOverlay(ingredientConfigurationReduxKey)
										}>
										<Icon name="more" />
									</Button>
								</ButtonGroup>
							</TableListCell>

							<TableListCell width="180px">
								<SingleDatePickerInput
									id="activeFrom"
									onChange={(event) => this.editStoreEntry(event, 'activeFrom')}
									selectedDate={this._getSelectedDate(
										batchFormData.activeFrom,
										'from'
									)}
									noClockButton
									clearable
								/>
							</TableListCell>

							<TableListCell width="180px">
								<SingleDatePickerInput
									id="activeTo"
									onChange={(event) => this.editStoreEntry(event, 'activeTo')}
									selectedDate={this._getSelectedDate(
										batchFormData.activeTo,
										'to'
									)}
									noClockButton
									clearable
								/>
							</TableListCell>

							<TableListCell width="100px" />
						</TableListRow>
					</tbody>
				</TableList>
			</div>
		);
	}
}

WorkplacePosConfigurationBatchStepFormRowDuplicateAsNew.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
	posConfigurationReduxKey: PropTypes.string,
	ingredientConfigurationReduxKey: PropTypes.string,
	productRecommendationReduxKey: PropTypes.string,
	appLayoutReduxKey: PropTypes.string,
	posLayoutReduxKey: PropTypes.string,
	loading: PropTypes.bool,
};

export default WorkplacePosConfigurationBatchStepFormRowDuplicateAsNew;
