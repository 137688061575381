import { get } from 'api.vanilla.service';
import { saveAs } from 'file-saver';
import { fetchCompleteCollection } from 'api/fetchCompleteCollection';
import moment from 'moment';

const CSV_FILE_NAME = `Contacts-${moment().format('YYYYMMDD_HHmmss')}.csv`;

/**
 * @function fetchContacts
 * @description Function for fetching contacts based on marketId
 * @param {*} param0
 */
export function fetchContacts({ limit, offset, filter, sort }) {
	// Set endpoint based on half_hourly or hourly selection in hotbar
	const uri = `/organization/contacts`;

	// Set params and filter
	const params = {
		limit,
		sort,
		offset,
		filter,
	};

	return get(uri, params);
}

export function fetchContactsAsCsv({ limit, offset, filter, sort }, marketId) {
	// Set endpoint based on half_hourly or hourly selection in hotbar
	const uri = `/organization/markets/${marketId}/contacts`;

	// Set params and filter
	const params = {
		limit: 300,
		sort,
		offset,
		// filter
	};

	const csvHeader = {
		Accept: 'application/csv',
	};

	return fetchCompleteCollection(uri, params, csvHeader).then((csv) => {
		const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
		const fileName = CSV_FILE_NAME;
		saveAs(blob, fileName);
	});
}
