'use strict';

import React, { Component } from 'react';
import { store, connectWithStore } from 'appState';

import DynamicPosViewHeaderComponent from './dynamicPosViewHeader.component';

class DynamicPosViewHeaderContainer extends Component {
	render() {
		return <DynamicPosViewHeaderComponent {...this.props} />;
	}
}

DynamicPosViewHeaderContainer.defaultProps = {
	hoverGroup: {}
};

const mapStateToProps = store => ({
	hoverGroup: store.dynamicPosView.hoverGroup
});

export default connectWithStore(
	DynamicPosViewHeaderContainer,
	mapStateToProps
);
