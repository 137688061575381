'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { Input, Button, Icon } from 'dumb';
import ColorPicker from './components/colorPicker/colorPicker';

import { ReactDataWrapper } from 'reactDataWrapper';
import { submitBatchValues } from 'reactDataWrapper/utilities/batchHelper';

import {
	fetchFlairs,
	addFlairs,
	editFlair,
	editFlairs,
	deleteFlairs
} from './flairs.service';

import {
	setFlairsModal,
	updateFlairs,
	resetState,
	updateBatchForm,
	resetBatch,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow
} from './store/flairs.actions';

// constants/phrases/tools
import phrases from './flairs.phrases';
import _get from 'lodash/get';

// components
import FlairsModalBatch from './components/batch/flairsModalBatch';

import './flairs.css';

class Flairs extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showTitleColorPicker: false,
			showBackgroundColorPicker: false,
			showModal: false
		};

		this.fetchData = this.fetchData.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.getEditableCells = this.getEditableCells.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.toggleTextColorPicker = this.toggleTextColorPicker.bind(this);
		this.toggleBackgroundColorPicker = this.toggleBackgroundColorPicker.bind(
			this
		);
		this._toggleModal = this._toggleModal.bind(this);
		this.batchAdd = this.batchAdd.bind(this);
		this.onEditModalClose = this.onEditModalClose.bind(this);
		this.editMultiple = this.editMultiple.bind(this);

		this.reduxKey = '/pos/flairs';
		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: d => _get(d, 'name', ''),
				filterPath: ':name'
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: d => _get(d, 'description', ''),
				filterPath: ':description'
			},
			{
				Header: 'External name',
				id: 'externalName',
				accessor: d => _get(d, 'external_name', ''),
				filterPath: ':external_name'
			},
			{
				Header: 'External description',
				id: 'externalDescription',
				accessor: d => _get(d, 'external_description', ''),
				filterPath: ':external_description'
			},
			{
				Header: 'Title color',
				id: 'titleColor',
				filterPath: ':title_color',
				Cell: d => (
					<div className="flairs__table__color-wrapper">
						<div
							className="flairs__table__color-wrapper__color"
							style={{
								backgroundColor: `#${_get(d, 'original.title_color', '')}`
							}}
						/>
						<span>#{_get(d, 'original.title_color', '')}</span>
					</div>
				)
			},
			{
				Header: 'Background color',
				id: 'backgroundColor',
				filterPath: ':background_color',
				Cell: d => (
					<div className="flairs__table__color-wrapper">
						<div
							className="flairs__table__color-wrapper__color"
							style={{
								backgroundColor: `#${_get(d, 'original.background_color', '')}`
							}}
						/>
						<span>#{_get(d, 'original.background_color', '')}</span>
					</div>
				)
			},
			{
				Header: 'Sort order',
				id: 'sortOrder',
				accessor: d => _get(d, 'sort_order', ''),
				filterPath: ':sort_order'
			}
		];
	}

	editEntry() {
		const { flairs } = this.props;

		const payload = {
			id: flairs.id,
			name: flairs.name,
			external_name: flairs.externalName,
			title_color: flairs.titleColor,
			background_color: flairs.backgroundColor,
			...(flairs.description && {
				description: flairs.description
			}),
			...(flairs.externalDescription && {
				external_description: flairs.externalDescription
			}),
			...(flairs.sortOrder && {
				sort_order: flairs.sortOrder
			})
		};

		return editFlair(payload);
	}

	editMultiple(selectedRows) {
		const { flairs } = this.props;

		const payload = {
			...(flairs.name && {
				name: flairs.name
			}),
			...(flairs.description && {
				description: flairs.description
			}),
			...(flairs.externalName && {
				external_name: flairs.externalName
			}),
			...(flairs.externalDescription && {
				external_description: flairs.externalDescription
			}),
			...(flairs.titleColor && {
				title_color: flairs.titleColor
			}),
			...(flairs.backgroundColor && {
				background_color: flairs.backgroundColor
			}),
			...(flairs.sortOrder && {
				sort_order: flairs.sortOrder
			})
		};

		const selectedRowsWithId = selectedRows.map(row => {
			return {
				id: row.id,
				...payload
			};
		});

		return editFlairs({
			batch: selectedRowsWithId
		});
	}

	deleteEntry(id) {
		return deleteFlairs(id);
	}

	setInitialEditValues(data) {
		const { setFlairsModal } = this.props;

		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			externalName: data.external_name,
			externalDescription: data.external_description,
			backgroundColor: data.background_color,
			titleColor: data.title_color,
			sortOrder: data.sort_order
		};

		setFlairsModal(payload);
	}

	toggleTextColorPicker() {
		this.setState(prev => ({
			showBackgroundColorPicker: false,
			showTitleColorPicker: !prev.showTitleColorPicker
		}));
	}

	toggleBackgroundColorPicker() {
		this.setState(prev => ({
			showTitleColorPicker: false,
			showBackgroundColorPicker: !prev.showBackgroundColorPicker
		}));
	}

	getEditableCells() {
		const { flairs } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={flairs?.name}
						onChange={e => this.editStoreEntry('name', e.target.value)}
					/>
				)
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={flairs?.description}
						onChange={e => this.editStoreEntry('description', e.target.value)}
					/>
				)
			},
			{
				header: 'External name',
				value: (
					<Input
						id="externalName"
						placeholder="Enter external name..."
						value={flairs?.externalName}
						onChange={e => this.editStoreEntry('externalName', e.target.value)}
					/>
				)
			},
			{
				header: 'External description',
				value: (
					<Input
						id="externalDescription"
						placeholder="Enter external description..."
						value={flairs?.externalDescription}
						onChange={e =>
							this.editStoreEntry('externalDescription', e.target.value)
						}
					/>
				)
			},
			{
				header: 'Title color',
				value: (
					<ColorPicker
						isOpen={this.state.showTitleColorPicker}
						onClick={this.toggleTextColorPicker}
						onColorChange={e => this.editStoreEntry('titleColor', e)}
						value={flairs?.titleColor}
					/>
				)
			},
			{
				header: 'Background color',
				value: (
					<ColorPicker
						isOpen={this.state.showBackgroundColorPicker}
						onClick={this.toggleBackgroundColorPicker}
						onColorChange={e => this.editStoreEntry('backgroundColor', e)}
						value={flairs?.backgroundColor}
					/>
				)
			},
			{
				header: 'Sort order',
				value: (
					<Input
						id="sortOrder"
						type="number"
						placeholder="Enter sort order..."
						value={flairs?.sortOrder || ''}
						onChange={e => this.editStoreEntry('sortOrder', e.target.value)}
					/>
				)
			}
		];
	}

	editStoreEntry(name, value) {
		const { updateFlairs } = this.props;

		const payload = {
			[name]: value
		};

		updateFlairs(payload);

		if (name === 'titleColor' || name === 'backgroundColor')
			this.setState(() => ({
				showBackgroundColorPicker: false,
				showTitleColorPicker: false
			}));
	}

	fetchData(state) {
		return fetchFlairs(state);
	}

	_toggleModal() {
		this.setState(prevState => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	batchAdd(payload) {
		return addFlairs(payload).then(res => {
			submitBatchValues({ res, reduxKey: this.reduxKey });
		});
	}

	onEditModalClose() {
		this.setState(
			() => ({
				showBackgroundColorPicker: false,
				showTitleColorPicker: false
			}),
			() => {
				this.props.resetState();
			}
		);
	}

	render() {
		const {
			batchList,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			addBatchListItemRow
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={this.reduxKey}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					deleteEntry={this.deleteEntry}
					editMultiple={this.editMultiple}
					setInitialEditValues={this.setInitialEditValues}
					accessAreasAllowedToEdit={['Global Sales Configuration', 'Sales Configuration']}

					onModalClose={this.onEditModalClose}
					actionRender={
						<>
							<Button
								type="inverted"
								label="Add"
								shadow
								onClick={() => this._toggleModal()}>
								<Icon name="add" />
							</Button>
						</>
					}
				/>

				<FlairsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this._toggleModal}
					addBatchList={addBatchList}
					batchList={batchList}
					batchAdd={this.batchAdd}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
				/>
			</>
		);
	}
}

Flairs.propTypes = {
	setFlairsModal: PropTypes.func,
	resetState: PropTypes.func,
	updateFlairs: PropTypes.func,
	flairs: PropTypes.object,
	batchList: PropTypes.array,
	addBatchList: PropTypes.func,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			setFlairsModal,
			updateFlairs,
			resetState,
			updateBatchForm,
			resetBatch,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			addBatchListItemRow
		},
		dispatch
	);
};

const mapStateToProps = store => {
	return {
		flairs: store.globalAdministrationFlairs.data.flairs,
		batchList: store.globalAdministrationFlairs.data.batchList
	};
};

export default connectWithStore(
	Flairs,
	mapStateToProps,
	mapDispatchToProps
);
