export const ADD_GLOBAL_NOTIFICATIONS = 'GLOBAL_NOTIFICATIONS/ADD_GLOBAL_NOTIFICATIONS';
export const CLEAR_GLOBAL_NOTIFICATOINS = 'GLOBAL_NOTIFICATIONS/CLEAR_GLOBAL_NOTIFICATOINS';

export const addGlobalNotifications = (payload) => {
	return {
		type: ADD_GLOBAL_NOTIFICATIONS,
		payload,
	};
};

export const clearGlobalNotifications = () => ({
	type: CLEAR_GLOBAL_NOTIFICATOINS,
});
