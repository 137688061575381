'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Cell from 'reusableComponents/list/cell.component';

import _has from 'lodash/has';
import _map from 'lodash/map';

class SumRow extends Component {
	constructor(props) {
		super(props);
	}

	/**
	 * @function _renderCells
	 * @memberOf DIRECTIVES.jtjList.component.sumRow
	 * @description Renders cells in list
	 */
	_renderCells() {
		return _map(
			this.props.methods.handleReturnVisibleHeaders(),
			function(cell, cellKey) {
				if (_has(cell, 'summarize')) {
					return (
						<Cell
							key={cellKey}
							cell={cell}
							row={this.props.methods.handleReturnSumRow()}
							methods={this.props.methods}
							translations={this.props.translations}
							firstColumn={false}
						/>
					);
				} else return <td className="list__cell" key={cellKey} />;
			}.bind(this)
		);
	}

	/**
	 * @function _renderEmptyCells
	 * @memberOf DIRECTIVES.jtjList.component.headers
	 * @description Renders empty headers based on prop for specific actions (delete, edit, lock etc.)
	 */
	_renderEmptyCells() {
		var emptyCellLength = this.props.methods.handleCountRowActions(this.props);
		var emptyCells = [];
		for (var i = emptyCellLength - 1; i >= 0; i--) {
			emptyCells.push(<td className="list__cell" key={i} />);
		}
		return emptyCells;
	}

	render() {
		return (
			<tbody>
				<tr className="list__row list__row--sum-row">
					{this._renderCells()}
					{this._renderEmptyCells()}
				</tr>
			</tbody>
		);
	}
}

SumRow.propTypes = {
	collection: PropTypes.array.isRequired,
	headers: PropTypes.array.isRequired,
	methods: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired
};

export default SumRow;
