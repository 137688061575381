import React from 'react';
import { Outlet } from 'react-router-dom';
import { BITags } from 'structure/globalAdministration/biTags/biTags.component.js';
import { Badges } from 'structure/globalAdministration/badges/badges.component.js';
import { Banks } from 'structure/globalAdministration/banks/banks.component.js';
import { Categories } from 'structure/globalAdministration/categories/categories.component.js';
import { Continents } from 'structure/globalAdministration/continents/continents.component.js';
import {
	Countries,
	CountriesDetails,
} from 'structure/globalAdministration/countries';
import {
	GlobalAccounts,
	GlobalAccountsDetails,
} from 'structure/globalAdministration/globalAccounts';
import { GlobalDiscounts } from 'structure/globalAdministration/globalDiscounts';
import { EmployeeMealAppAllowances } from 'structure/globalAdministration/employeeMealAppAllowances';
import { StoreAccessTypes } from 'structure/globalAdministration/storeAccessTypes';
import { MarketOvertimeRules } from 'structure/globalAdministration/marketOvertimeRules';
import {
	Currencies,
	CurrenciesDetails,
} from 'structure/globalAdministration/currencies';
import { Flairs } from 'structure/globalAdministration/flairs';
import { Grids, GridsDetails } from 'structure/globalAdministration/grids';
import { GlobalSalaryComponents } from 'structure/globalAdministration/globalSalaryComponents';
import { MarketGracePeriods } from 'structure/globalAdministration/marketGracePeriods';
import { Ingredients } from 'structure/globalAdministration/ingredients';
import { MailinglistRecipients } from 'structure/globalAdministration/mailinglistRecipients';
import { MarketRegions } from 'structure/globalAdministration/marketRegions';
import { MarketShiftDeviationReasons } from 'structure/globalAdministration/marketShiftDeviationReasons';
import { MarketShiftAlignmentRules } from 'structure/globalAdministration/marketShiftAlignmentRules';
import { MarketShiftRules } from 'structure/globalAdministration/marketShiftRules';
import {
	ModulesAndExams,
	TrainingGradeScalesDetails,
	TrainingModulesDetails,
} from 'structure/globalAdministration/modulesAndExams';
import { Nationalities } from 'structure/globalAdministration/nationalities';
import { Languages } from 'structure/globalAdministration/languages';
import { Products } from 'structure/globalAdministration/products';
import { ProductionAreas } from 'structure/globalAdministration/productionAreas';
import { PersonAttributeTypes } from 'structure/globalAdministration/personAttributeTypes';
import { PosConfigurationLoyaltyTypes } from 'structure/globalAdministration/posConfigurationLoyaltyTypes';
import { PosTiers } from 'structure/globalAdministration/posTiers';
import { ProductVariants } from 'structure/globalAdministration/productVariants';
import { PowerBIReports } from 'structure/globalAdministration/powerBIReports';
import { RawIngredients } from 'structure/globalAdministration/rawIngredients';
import { ReportingMarkets } from 'structure/globalAdministration/reportingMarkets';
import { ShiftplanSeniority } from 'structure/globalAdministration/shiftplanSeniority';
import { Sizes } from 'structure/globalAdministration/sizes';
import { SystemSoftwares } from 'structure/globalAdministration/systemSoftwares';
import { Tags, TagsDetails } from 'structure/globalAdministration/tags';
import {
	TileConfigurations,
	TilePictureAssetsDetails,
} from 'structure/globalAdministration/tileConfigurations';
import {
	TurnoverAchievements,
	TurnoverAchievementsDetails,
} from 'structure/globalAdministration/turnoverAchievements';
import { WaitingTimeGrids } from 'structure/globalAdministration/waitingTimeGrids';
import { WaitingTimePerformanceGrids } from 'structure/globalAdministration/waitingTimePerformanceGrids';
import { WorkforceNews } from 'structure/globalAdministration/workforceNews';
import { ShiftTransferPolicies } from 'structure/globalAdministration/shiftTransferPolicies';
import { ShiftClockinCorrectionOptions } from 'structure/globalAdministration/shiftClockinCorrectionOptions';
import { DynamicAccounts } from 'structure/globalAdministration/dynamicAccounts';
import { VacationDescriptionPresets } from 'structure/globalAdministration/vacationDescriptionPresets';

import TileDirectory from 'smart/tileDirectory/tileDirectory';

export const globalAdministrationRoutes = [
	{
		element: <Outlet />,
		path: 'global-administration',
		title: 'Global administration',
		allowedAccessControlLevels: ['Sales Audit Admin'],
		icon: require('@mui/icons-material/DisplaySettingsOutlined').default,
		children: [
			{
				index: true,
				element: <TileDirectory pathName="global-administration" />,
			},
			{
				element: <BITags />,
				path: 'bi-tags',
				title: 'bi-tags',
				section: 'REPORTING',
				description: 'Manage BI tags',
				allowedAccessControlLevels: ['Global BI Configuration'],
			},
			{
				element: <Continents />,
				path: 'continents',
				title: 'continents',
				description: 'Create new and update existing Continents',

				allowedAccessControlLevels: ['Global BI Configuration'],
			},

			{
				element: <Outlet />,
				path: 'countries',
				title: 'countries',
				description: 'See list of countries',

				allowedAccessControlLevels: ['Global BI Configuration'],
				children: [
					{
						element: <Countries />,
						index: true,
						allowedAccessControlLevels: ['Global BI Configuration'],
					},
					{
						element: <CountriesDetails />,
						path: ':countryId',
						title: 'country details',

						allowedAccessControlLevels: ['Global BI Configuration'],
					},
				],
			},

			{
				element: <Outlet />,
				path: 'exchange-rates',
				title: 'exchange-rates',
				description: 'See list of Exchange Rates',
				keywords: [
					'currency',
					'currencies',
					'rates',
					'valuta',
					'denominations',
				],
				allowedAccessControlLevels: ['Global BI Configuration'],
				children: [
					{
						element: <Currencies />,
						index: true,
						allowedAccessControlLevels: ['Global BI Configuration'],
					},
					{
						element: <CurrenciesDetails />,
						path: ':currencyId',
						title: 'exchange-rates details',

						allowedAccessControlLevels: ['Global BI Configuration'],
					},
				],
			},
			{
				element: <MailinglistRecipients />,
				path: 'mailinglist-recipients',
				title: 'Mailinglist recipients',
				description: 'Manage Mailinglist Recipients',
				allowedAccessControlLevels: ['Global BI Configuration'],
			},
			{
				element: <MarketRegions />,
				path: 'market-regions',
				title: 'Market regions',
				description: 'Manage MarketRegions',
				allowedAccessControlLevels: ['Global BI Configuration'],
			},
			{
				element: <PosTiers />,
				path: 'pos-tiers',
				title: 'Pos tiers',
				description: 'Manage pos tiers',
				allowedAccessControlLevels: [
					'Global Sales Configuration',
					'Sales Configuration',
				],
			},
			{
				element: <PowerBIReports />,
				path: 'power-bi-reports',
				title: 'Power bi reports',
				description: 'Manage Power BI Reports',
				allowedAccessControlLevels: [
					'Global BI Configuration',
					'Global HR Configuration',
				],
			},
			{
				element: <ReportingMarkets />,
				path: 'reporting-markets',
				title: 'Reporting markets',
				description: 'Manage reporting markets',
				allowedAccessControlLevels: ['Global BI Configuration'],
			},

			{
				element: <Outlet />,
				path: 'tags',
				title: 'tags',
				description: 'See list of Tags',
				allowedAccessControlLevels: ['Global HR Configuration'],
				children: [
					{
						element: <Tags />,
						index: true,
						allowedAccessControlLevels: ['Global HR Configuration'],
					},
					{
						element: <TagsDetails />,
						path: ':tagId',
						title: 'Tag details',

						allowedAccessControlLevels: ['Global HR Configuration'],
					},
				],
			},
			{
				element: <Outlet />,
				path: 'turnover-achievements',
				title: 'Turnover achievements',
				description: 'Manage Turnover achievements',
				allowedAccessControlLevels: ['Global BI Configuration'],
				children: [
					{
						element: <TurnoverAchievements />,
						index: true,
						allowedAccessControlLevels: ['Global BI Configuration'],
					},
					{
						element: <TurnoverAchievementsDetails />,
						path: ':turnoverAchievementId',
						title: 'Turnover achievements details',

						allowedAccessControlLevels: ['Global BI Configuration'],
					},
				],
			},

			{
				element: <WorkforceNews />,
				path: 'workforce-news',
				title: 'Workforce news',
				description: 'Manage Workforce news',
				allowedAccessControlLevels: [
					'Global Sales Configuration',
					'Sales Configuration',
				],
			},

			{
				element: <Badges />,
				path: 'badges',
				title: 'badges',
				description: 'Manage training badges',
				section: 'HR',
				allowedAccessControlLevels: ['Global BI Configuration'],
			},
			{
				element: <Banks />,
				path: 'banks',
				title: 'banks',
				description: 'See list of banks',
				allowedAccessControlLevels: ['Global BI Configuration'],
			},
			{
				element: <EmployeeMealAppAllowances />,
				path: 'employee-meal-app-allowances',
				title: 'Employee meal app allowances',
				description: 'See list of Employee meal app allowances',

				allowedAccessControlLevels: ['Global HR Configuration'],
			},

			{
				element: <GlobalSalaryComponents />,
				path: 'global-salary-components',
				title: 'Global salary components',
				description: 'Manage global salary components',
				allowedAccessControlLevels: ['Global HR Configuration'],
			},
			{
				element: <Outlet />,
				path: 'grids',
				title: 'grids',
				description: 'Manage grids',
				allowedAccessControlLevels: ['Global BI Configuration'],
				children: [
					{
						element: <Grids />,
						index: true,
						allowedAccessControlLevels: ['Global BI Configuration'],
					},
					{
						element: <GridsDetails />,
						path: ':gridId',
						title: 'grids details',

						allowedAccessControlLevels: ['Global BI Configuration'],
					},
				],
			},
			{
				element: <MarketGracePeriods />,
				path: 'market-grace-periods',
				title: 'Market grace periods',
				description: 'Manage market grace periods',
				allowedAccessControlLevels: [
					'Global HR Configuration',
					'Shiftplan Admin',
				],
			},
			{
				element: <MarketOvertimeRules />,
				path: 'market-overtime-rules',
				title: 'Market overtime rules',
				description: 'Add some market rules.',

				allowedAccessControlLevels: ['Global HR Configuration'],
			},
			{
				element: <MarketShiftDeviationReasons />,
				path: 'market-shift-deviation-reasons',
				title: 'Market shift deviation reasons',
				description: 'Manage Market shift deviation reasons',
				allowedAccessControlLevels: [
					'Global HR Configuration',
					'Shiftplan Admin',
				],
			},
			{
				element: <MarketShiftRules />,
				path: 'market-shift-rules',
				title: 'Market shift rules',
				description: 'Manage Market shift rules',
				allowedAccessControlLevels: [
					'Global Sales Configuration',
					'Sales Configuration',
				],
			},

			{
				element: <Outlet />,
				path: 'modules-and-exams',
				title: 'Modules and exams',
				description: 'Manage training modules and Exams',
				allowedAccessControlLevels: ['Global BI Configuration'],
				children: [
					{
						element: <ModulesAndExams />,
						index: true,
						allowedAccessControlLevels: ['Global BI Configuration'],
					},
					{
						element: <TrainingGradeScalesDetails />,
						path: 'scale/:scaleId',
						title: 'scale details',

						allowedAccessControlLevels: ['Global BI Configuration'],
					},
					{
						element: <TrainingModulesDetails />,
						path: 'modules/:moduleId',
						title: 'scale details',

						allowedAccessControlLevels: ['Global BI Configuration'],
					},
				],
			},
			{
				element: <Nationalities />,
				path: 'nationalities',
				title: 'nationalities',
				description: 'See list of nationalities',
				allowedAccessControlLevels: ['Global HR Configuration'],
			},
			{
				element: <PersonAttributeTypes />,
				path: 'person-attribute-types',
				title: 'Person attribute types',
				description: 'Manage person attribute types',
				allowedAccessControlLevels: ['Global HR Configuration'],
			},
			{
				element: <ShiftTransferPolicies />,
				path: 'shift-transfer-policies',
				title: 'Shift transfer policies',
				description: 'Manage Shift transfer policies',
				allowedAccessControlLevels: ['Global HR Configuration'],
			},
			{
				element: <ShiftplanSeniority />,
				path: 'shiftplan-seniority',
				title: 'Shiftplan seniority',
				description: 'Manage shiftplan seniority',
				allowedAccessControlLevels: [
					'Global Sales Configuration',
					'Shiftplan Admin',
				],
			},

			{
				element: <StoreAccessTypes />,
				path: 'store-access-types',
				title: 'Store access types',
				description: 'Store access types',

				allowedAccessControlLevels: ['Global HR Configuration'],
			},
			{
				element: <WaitingTimeGrids />,
				path: 'waiting-time-grids',
				title: 'Waiting time grids',
				description: 'Manage Waiting time grids',
				allowedAccessControlLevels: [
					'Global Sales Configuration',
					'Sales Configuration',
				],
			},
			{
				element: <WaitingTimePerformanceGrids />,
				path: 'waiting-time-performance-grids',
				title: 'Waiting time grids',
				description: 'Manage Waiting time performance grids',
				allowedAccessControlLevels: ['Global Sales Configuration'],
			},
			{
				element: <ShiftClockinCorrectionOptions />,
				path: 'shift-clockin-correction-options',
				title: 'Shift clockin correction options',
				description: 'Manage shift clockin correction options',
				allowedAccessControlLevels: [
					'Global HR Configuration',
					'Shiftplan Admin',
				],
			},
			{
				element: <VacationDescriptionPresets />,
				path: 'vacation-description-presets',
				title: 'Vacation description presets',
				description: 'Manage vacation description presets',
				allowedAccessControlLevels: ['Global BI Configuration'],
			},
			{
				element: <Categories />,
				path: 'categories',
				title: 'categories',
				section: 'SALES',
				description: 'Manage ingredient, product and size categories',
				allowedAccessControlLevels: ['Global Sales Configuration'],
			},
			{
				element: <Outlet />,
				path: 'global-accounts',
				title: 'Global accounts',
				description: 'See list of global accounts',

				allowedAccessControlLevels: ['Global HR Configuration'],
				children: [
					{
						element: <GlobalAccounts />,
						index: true,
						allowedAccessControlLevels: ['Global HR Configuration'],
					},
					{
						element: <GlobalAccountsDetails />,
						path: ':globalAccountId',
						title: 'Global accounts details',

						allowedAccessControlLevels: ['Global HR Configuration'],
					},
				],
			},
			{
				element: <GlobalDiscounts />,
				path: 'global-discounts',
				title: 'Global Discounts',
				description: 'See list of global discounts',

				allowedAccessControlLevels: ['Global Sales Configuration'],
			},

			{
				element: <Flairs />,
				path: 'flairs',
				title: 'flairs',
				description: 'Manage Flairs',
				allowedAccessControlLevels: [
					'Global Sales Configuration',
					'Sales Configuration',
				],
			},

			{
				element: <Ingredients />,
				path: 'ingredients',
				title: 'Ingredients',
				description: 'Manage ingredients',
				allowedAccessControlLevels: ['Global Sales Configuration'],
			},

			{
				element: <MarketShiftAlignmentRules />,
				path: 'market-shift-alignment-rules',
				title: 'Market shift alignment rules',
				description: 'Manage market shift alignment rules',
				allowedAccessControlLevels: [
					'Global HR Configuration',
					'Shiftplan Admin',
				],
			},

			{
				element: <Languages />,
				path: 'languages',
				title: 'Languages',
				description: 'Manage languages',
				allowedAccessControlLevels: ['Global Sales Configuration'],
			},
			{
				element: <Products />,
				path: 'products',
				title: 'Products',
				description: 'Manage products',
				allowedAccessControlLevels: ['Global Sales Configuration'],
			},
			{
				element: <ProductionAreas />,
				path: 'production-areas',
				title: 'Production Areas',
				description: 'Manage production areas',
				allowedAccessControlLevels: ['Global BI Configuration'],
			},

			{
				element: <PosConfigurationLoyaltyTypes />,
				path: 'pos-configuration-loyalty-types',
				title: 'Pos configuration loyalty types',
				description: 'Manage POS Configuration Loyalty Types',
				allowedAccessControlLevels: ['Global Sales Configuration'],
			},

			{
				element: <ProductVariants />,
				path: 'product-variants',
				title: 'Product variants',
				description: 'Manage product variants',
				allowedAccessControlLevels: [
					'Global BI Configuration',
					'Global HR Configuration',
				],
			},

			{
				element: <RawIngredients />,
				path: 'raw-ingredients',
				title: 'Raw ingredients',
				description: 'Manage raw ingredients',
				allowedAccessControlLevels: [
					'Global Sales Configuration',
					'Sales Configuration',
				],
			},

			{
				element: <Sizes />,
				path: 'sizes',
				title: 'Sizes',
				description: 'Manage sizes',
				allowedAccessControlLevels: ['Global Sales Configuration'],
			},
			{
				element: <SystemSoftwares />,
				path: 'system-softwares',
				title: 'System softwares',
				description: 'Manage system softwares',
				allowedAccessControlLevels: ['Global Sales Configuration'],
			},

			{
				element: <Outlet />,
				path: 'tile-configuration',
				title: 'Tile configuration',
				description: 'Manage Tile configurations',
				allowedAccessControlLevels: ['Global Sales Configuration'],
				children: [
					{
						element: <TileConfigurations />,
						index: true,
						allowedAccessControlLevels: ['Global Sales Configuration'],
					},
					{
						element: <TilePictureAssetsDetails />,
						path: ':tilePictureAssetId',
						title: 'Tile picture assets details',

						allowedAccessControlLevels: ['Global Sales Configuration'],
					},
				],
			},
			{
				element: <DynamicAccounts />,
				path: 'dynamic-accounts',
				title: 'Dynamic accounts',
				description: 'Manage dynamic accounts',
				section: 'Finance',
				allowedAccessControlLevels: [
					'Global BI Configuration',
					'Global HR Configuration',
				],
			},
		],
	},
];
