'use strict';

import { ReportFactory as R } from 'report/factory';
import PosReportBaseColumnsService from './posReportBaseColumns.service';
import DynamicPosView from './../customComponents/dynamicPosView';
import DynamicPosViewHeader from './../customComponents/dynamicPosViewHeader';

const t = R.table();

const PosReportContinentTable = t.table([
	t.column('', [
		t.content(d => `${R.data(d, 'meta.data.market_region.name').toUpperCase()} in ${R.data(d, 'meta.data.currency_code')}`, 'truncated', null, 'meta')
	], PosReportBaseColumnsService.getFirstColumnWidth()),
	...PosReportBaseColumnsService.generate()
]);

PosReportContinentTable.setHeader(false);
PosReportContinentTable.setTotal(false);
PosReportContinentTable.setClassName('pos-reports__continent-table');

const c = R.customComponent();
const DynamicPosViewHeaderComponent = c.customComponent('DynamicPosViewHeader', DynamicPosViewHeader);
const DynamicPosViewComponent = c.customComponent('DynamicPosView', DynamicPosView);

PosReportContinentTable.setTableType('customComponentUnfoldedInverted');
PosReportContinentTable.setCustomComponents([DynamicPosViewHeaderComponent, DynamicPosViewComponent]);

export default PosReportContinentTable;