'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setWorkplaceExpectedWaitingTimes,
	updateWorkplaceExpectedWaitingTimes,
	resetFilterGroup,
} from './store/filterGroups.actions';

// services
import {
	fetchFilterGroups,
	editFilterGroup,
	editFilterGroups,
	addFilterGroup,
	deleteFilterGroup,
} from './filterGroups.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';
import { Input, Button, Icon } from 'dumb';

// sub table
import Filters from './components/filters/filters.component';

import FilterGroupsModalBatch from './components/batch/filterGroupsModalBatch';

// phrases
import phrases from './filterGroups.phrases';

const reduxKey = '/product/filter_groups';

class FilterGroups extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.addFilterGroupWrapper = this.addFilterGroupWrapper.bind(this);
		this.fetchData = this.fetchData.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: 'name',
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: 'description',
				filterPath: ':description',
			},
			{
				Header: 'Sort order',
				id: 'sort_order',
				accessor: 'sort_order',
				filterPath: ':sort_order',
			},
		];
	}

	editEntry() {
		const { defaultFilterGroup } = this.props;

		const payload = {
			id: defaultFilterGroup.id,
			name: defaultFilterGroup.name,
			description: defaultFilterGroup.description,
			sort_order: defaultFilterGroup.sortOrder,
		};

		return editFilterGroup(payload);
	}

	editMultiple(selectedRows) {
		const { defaultFilterGroup } = this.props;

		const payload = {
			...(defaultFilterGroup.name && {
				name: defaultFilterGroup.name,
			}),
			...(defaultFilterGroup.description && {
				description: defaultFilterGroup.description,
			}),
			...(defaultFilterGroup.sortOrder && {
				sort_order: defaultFilterGroup.sortOrder,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editFilterGroups({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteFilterGroup(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			sortOrder: data.sort_order,
		};

		this.props.setWorkplaceExpectedWaitingTimes(payload);
	}

	getEditableCells() {
		const { defaultFilterGroup } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={defaultFilterGroup.name || ''}
						onChange={(event) => this.editStoreEntry('name', event)}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={defaultFilterGroup.description || ''}
						onChange={(event) => this.editStoreEntry('description', event)}
					/>
				),
			},
			{
				header: 'Sort order',
				value: (
					<Input
						id="sort_order"
						type="number"
						placeholder="Enter sort order..."
						value={defaultFilterGroup.sortOrder || null}
						onChange={(event) => this.editStoreEntry('sortOrder', event)}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const value = e.target ? e.target.value : e;

		const updateObject = {
			[name]: value,
		};

		this.props.updateWorkplaceExpectedWaitingTimes(updateObject);
	}

	fetchData(state) {
		return fetchFilterGroups(state);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetFilterGroup();
	}

	addFilterGroupWrapper(data) {
		return addFilterGroup(data).then(() => {
			this.setState(() => ({
				key: moment.utc(),
			}));
		});
	}

	render() {
		const {
			batchList,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
		} = this.props;

		return (
			<>
				<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={reduxKey}
					manual
					subcomponent={(row) => {
						return (
							<SubTable>
								<Filters filterGroup={row.original} />
							</SubTable>
						);
					}}
					editableCells={this.getEditableCells()}
					editEntry={(e) => this.editEntry(e)}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetFilterGroup}
					actionRender={
						<Button
							type="inverted"
							label="Batch"
							shadow
							onClick={() => this.toggleModal()}>
							<Icon name="add" />
						</Button>
					}
				/>

				<FilterGroupsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addFilterGroup={this.addFilterGroupWrapper}
				/>
			</>
		);
	}
}

FilterGroups.propTypes = {
	defaultFilterGroup: PropTypes.object,
	updateWorkplaceExpectedWaitingTimes: PropTypes.func,
	setWorkplaceExpectedWaitingTimes: PropTypes.func,
	resetFilterGroup: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			updateWorkplaceExpectedWaitingTimes,
			setWorkplaceExpectedWaitingTimes,
			resetFilterGroup,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultFilterGroup: store.filterGroups.data.defaultFilterGroup,
		batchList: store.filterGroups.data.batchList,
	};
};

export default connectWithStore(
	FilterGroups,
	mapStateToProps,
	mapDispatchToProps
);
