import PropTypes from 'prop-types';
import React, { Component } from 'react';
import phrases from './../../juicerDetails.phrases';

import { ReactDataWrapper } from 'reactDataWrapper';

import {
	fetchTrainings,
	deleteTraining,
	postPatchTraining,
	getPeriodEdit,
} from './trainings.service';
import _get from 'lodash/get';

import {
	editTrainingData,
	setEditableTrainingData,
	resetState,
} from './store/trainings.actions';
import { store, connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// date and time picker components
import Date from 'reusableComponents/inputs/date/date.component';
import { TimePickerNew, InputCollectionSelect } from 'dumb';

// constants
import constants from 'services/constants';

// Helpers for error messages
import { set as setFeedback } from 'feedback.vanilla.service';
import { formatErrorMessage } from 'api/helpers';

// styles
import './trainingExam.css';

// Wrapper for react-data
// Documentation can be found here https://react-table.js.org/
class TrainingsContainer extends Component {
	constructor(props) {
		super(props);

		this.fetchData = this.fetchData.bind(this);
		this.getEditableCells = this.getEditableCells.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.resetState = this.resetState.bind(this);

		this.columns = [
			{
				Header: 'Module',
				width: 150,
				id: 'module',
				accessor: (d) => _get(d, 'module.name', null),
				filterPath: ':module.name',
			},
			{
				Header: 'Trainer',
				width: 100,
				id: 'trainer',
				accessor: (d) => d.trainer.full_name,
				filterPath: ':trainer.full_name',
			},
			{
				Header: 'Type',
				width: 70,
				id: 'type',
				accessor: (d) => _get(d, 'module.type', null),
				filterPath: ':module.type',
			},
			{
				Header: 'From',
				width: 120,
				id: 'from',
				Cell: (d) => (
					<span className="number">
						{d.value !== null
							? moment.utc(d.value).format('YYYY-MM-DD HH:mm:ss')
							: ''}
					</span>
				),
				accessor: (d) => _get(d, 'period.from', null),
				filterPath: ':period.from',
			},
			{
				Header: 'To',
				width: 120,
				Cell: (d) => (
					<span className="number">
						{d.value !== null
							? moment.utc(d.value).format('YYYY-MM-DD HH:mm:ss')
							: ''}
					</span>
				),
				id: 'to',
				accessor: (d) => _get(d, 'period.to', null),
				filterPath: ':period.to',
			},

			{
				Header: 'Workplace',
				width: 150,
				id: 'workplace',
				accessor: (d) => _get(d, 'workplace.name', null),
				filterPath: ':workplace.name',
			},
			{
				Header: 'Grade level',
				width: 80,
				id: 'gradeLevel',
				accessor: (d) => _get(d, 'module_grade_level.grade_level.name', null),
				filterPath: ':module_grade_level.grade_level.name',
			},
		];
	}

	deleteEntry(id) {
		return deleteTraining({ id });
	}

	addEntry() {
		const { editedValues } = this.props;
		const personId = this.props.personId;

		// construct a payload object
		const payload = {
			person: personId,
			module: _get(editedValues, 'module.value', null),
			trainer: _get(editedValues, 'trainer.value'),
			workplace: _get(editedValues, 'workplace.value'),
			type: 'Unplanned',
			module_grade_level: _get(editedValues, 'module_grade_level.value'),
		};

		return postPatchTraining(null, payload);
	}

	editEntry() {
		const { editedValues, defaultValues } = this.props;
		const id = defaultValues.id;

		// construct a payload object
		const payload = {
			module: _get(editedValues, 'module.value'),
			trainer: _get(editedValues, 'trainer.value'),
			workplace: _get(editedValues, 'workplace.value'),
			...(defaultValues.type === 'Shift' && {
				period: getPeriodEdit({
					defaultValues,
					editedValues,
				}),
			}),
			module_grade_level: _get(editedValues, 'module_grade_level.value'),
		};

		// get rid of faulty key values (present when we're editing data)
		for (const key of Object.keys(payload)) {
			if (!payload[key]) delete payload[key];
		}

		return postPatchTraining(id, payload);
	}

	setInitialEditValues(data) {
		// clear the editedValues from the store
		// CollectionSelect will take values either from defaultValues or editedValues
		// so we need to clear the state so there's no leftover data
		this.resetState();

		this.props.setEditableTrainingData({
			id: data.id,
			personId: data.personId,
			type: data.type,
			module: {
				value: _get(data, 'module.id', null),
				label: _get(data, 'module.name', null),
				type: _get(data, 'module.type', null),
			},
			trainer: {
				value: _get(data, 'trainer.id', null),
				label: _get(data, 'trainer.full_name', null),
			},
			from: data.period
				? moment.utc(data.period.from, constants.dateFormat)
				: null,
			to: data.period ? moment.utc(data.period.to, constants.dateFormat) : null,
			workplace: {
				value: _get(data, 'workplace.id', null),
				label: _get(data, 'workplace.name', null),
			},
			module_grade_level: {
				value: _get(data, 'module_grade_level.id', null),
				label: _get(data, 'module_grade_level.grade_level.name', null),
			},
			editMode: true,
		});
	}

	resetState() {
		this.props.resetState();
	}

	fetchData(state) {
		const { personId } = this.props;

		const filter = `:person.id=='${personId}'`;
		const sort = ':module.sort_order+';

		return fetchTrainings(
			{
				offset: state.offset,
				limit: state.limit,
				sort: state.sort,
				filter: state.filter,
			},
			filter,
			sort
		)
			.then((response) => {
				return response;
			})
			.catch((e) => {
				// throw an error when fetching data fails
				const errorMsg = formatErrorMessage(e);
				setFeedback(errorMsg, 0);
			});
	}

	getEditedValueFormat(name, value) {
		const { editedValues } = this.props;

		if (name === 'from' || name === 'to')
			return { period: { ...editedValues.period, [name]: value } };

		return { [name]: value };
	}

	editTrainingData(name, value) {
		const { editTrainingData } = this.props;
		const payload = this.getEditedValueFormat(name, value);

		editTrainingData(payload);
	}

	/**
	 * @function _getSelectedDate
	 * @param {String} name - from/to
	 * @description returns a date for the date picker
	 * @note has to be a default date object from moment without any formatting!
	 */
	_getSelectedDate(name) {
		const editedDate =
			_get(this.props.editedValues, `period.${name}`, null) ||
			_get(this.props.defaultValues, name, null);

		if (editedDate) {
			return moment(editedDate).clone();
		}
	}

	/**
	 * @function _getSelectedDate
	 * @param {String} value - from/to
	 * @param {String} time - HH/MM - for hour/minute
	 */
	_getSelectedTime(value, time) {
		// get time from or to
		const editedTime = _get(this.props.defaultValues, value, null);

		if (editedTime) {
			// return correct time value
			// utc to get neutral time zone not ours (+2 hours otherwise)
			// .clone to ensure immutability
			return moment.utc(editedTime, constants.dateFormat).clone().format(time);
		}

		// if we're entering new data
		const enteredTime = this.props.editedValues;

		if (enteredTime && enteredTime[value]) return enteredTime[value];
	}

	/**
	 *
	 * @param {String} name - from or to hour
	 * @param {String} e - can be either hour or minute
	 * @param {String/Number} value - actual value that we enter
	 */
	openedDateChange(name, e) {
		const { editTrainingData, editedValues } = this.props;

		const payload = {
			period: {
				...editedValues.period,
				[`${name}Time`]: e,
			},
		};

		editTrainingData(payload);
	}

	getEditableCells() {
		const { editedValues, defaultValues } = this.props;

		const cells = [
			...(defaultValues.editMode
				? []
				: [
					{
						header: 'Module',
						value: (
							<InputCollectionSelect
								clearable={false}
								ignoreAccents={false}
								multi={false}
								onChange={(value) => this.editTrainingData('module', value)}
								value={
									_get(editedValues, 'module', '') ||
										_get(defaultValues, 'module', '')
								}
								apiPath="/training/modules"
								searchable
								optionFormat={(entry) => ({
									value: entry.id,
									label: `${entry.name} (${entry.type})`,
									type: entry.type,
								})}
								inputFilterFormat={(input) =>
									`:module.name=like='%${input}%'`
								}
							/>
						),
					},
				  ]),
			{
				header: 'Trainer',
				value: (
					<InputCollectionSelect
						clearable={false}
						ignoreAccents={false}
						multi={false}
						apiPath="/hr/persons"
						onChange={(value) => this.editTrainingData('trainer', value)}
						value={
							_get(editedValues, 'trainer', '') ||
							_get(defaultValues, 'trainer', '')
						}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.full_name,
						})}
						inputFilterFormat={(input) =>
							`:full_name=like='%${input}%',:id=like='%${input}%'`
						}
						searchable
					/>
				),
			},
			defaultValues &&
				defaultValues.type === 'Shift' && {
				header: 'From',
				value: (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}>
						<Date
							type="single"
							onChange={(date) =>
								this.editTrainingData(
									'from',
									moment(date).format('YYYY-MM-DD')
								)
							}
							selectedDate={this._getSelectedDate('from')}
							appendToBody
						/>
						<TimePickerNew
							value={this._getSelectedTime('from', 'HH:mm')}
							onChange={(e, value) => this.openedDateChange('from', e)}
						/>
					</div>
				),
			},
			defaultValues &&
				defaultValues.type === 'Shift' && {
				header: 'To',
				value: (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}>
						<Date
							type="single"
							onChange={(date) =>
								this.editTrainingData('to', moment(date).format('YYYY-MM-DD'))
							}
							selectedDate={this._getSelectedDate('to')}
							appendToBody
						/>
						<TimePickerNew
							value={this._getSelectedTime('to', 'HH:mm')}
							onChange={(e, value) => this.openedDateChange('to', e)}
						/>
					</div>
				),
			},
			{
				header: 'Workplace',
				value: (
					<InputCollectionSelect
						clearable={false}
						ignoreAccents={false}
						multi={false}
						onChange={(value) => this.editTrainingData('workplace', value)}
						value={
							_get(editedValues, 'workplace', null) ||
							_get(defaultValues, 'workplace', null)
						}
						apiPath="/organization/workplaces"
						searchable
						inputFilterFormat={(input) => `:workplace.name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Grade Level',
				value: (
					<InputCollectionSelect
						clearable={false}
						ignoreAccents={false}
						multi={false}
						apiPath="/training/module_grade_levels"
						apiFilter={`:module.id=='${
							_get(editedValues, 'module.value', null) ||
							_get(defaultValues, 'module.value', null)
						}'`}
						value={
							_get(editedValues, 'module_grade_level', null) ||
							_get(defaultValues, 'module_grade_level', null)
						}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.grade_level.name,
						})}
						onChange={(value) =>
							this.editTrainingData('module_grade_level', value)
						}
						forceRefresh
						disabled={
							!(
								_get(editedValues, 'module', '') ||
								_get(defaultValues, 'module', '')
							)
						}
					/>
				),
			},
		];

		return cells.filter((i) => i);
	}

	render() {
		const reduxKey = `training/module_executions`;

		return (
			<div className="trainings-exam-table">
				<div className="trainings-exam-table__wrapper">
					<ReactDataWrapper
						title={phrases.TRAINING_EXAM_TABLE}
						columns={this.columns}
						editEntry={() => this.editEntry()}
						createEntry={() => this.addEntry()}
						fetchData={this.fetchData}
						accessAreasAllowedToEdit={['Employment Admin', 'Person Admin']}
						deleteEntry={(id) => this.deleteEntry(id)}
						editableCells={this.getEditableCells()}
						setInitialEditValues={this.setInitialEditValues}
						filterable
						defaultPageSize={10}
						reduxKey={reduxKey}
						onModalClose={this.resetState}
						manual
					/>
				</div>
			</div>
		);
	}
}

TrainingsContainer.defaultProps = {
	data: [],
	columns: [],
};

TrainingsContainer.propTypes = {
	editTrainingData: PropTypes.func,
	editedValues: PropTypes.object,
	setEditableTrainingData: PropTypes.func,
	resetState: PropTypes.func,
	defaultValues: PropTypes.object,
	personId: PropTypes.number,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			editTrainingData,
			setEditableTrainingData,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		editedValues: store.trainings.editedValues,
		defaultValues: store.trainings.defaultValues,
	};
};

export default connectWithStore(
	TrainingsContainer,
	mapStateToProps,
	mapDispatchToProps
);
