'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Button,
	Tag,
	Legend,
	InputCollectionSelect,
	SingleDatePickerInput,
} from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// phrases/ constants
import phrases from './../../../workplacePosConfiguration.phrases';
import constants from 'services/constants';

// lodash
import _get from 'lodash/get';

class WorkplacePosConfigurationBatchStepListAdd extends Component {
	constructor(props) {
		super(props);

		this.cantAddBulletColor = 'ffe699';
	}

	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e.target ? e.target.value : e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: value },
		});
	}

	_getCollectionSelectValue(entry) {
		if (!entry) return;
		return entry.value && entry.label
			? entry
			: {
				value: entry.id,
				label: entry.name,
			  };
	}

	render() {
		const {
			batchListData,
			productRecommendationUrl,
			tileLayoutUrl,
			ingredientConfigurationUrl,
		} = this.props;

		return (
			<>
				<div className="workplace-pos-configuration__modal-batch__legend">
					<Legend
						points={[
							{
								bulletColor: `#${this.cantAddBulletColor}`,
								label: phrases.MODAL_BATCH_STEP_LIST_LEGEND_CANT_ADD,
							},
						]}
					/>
				</div>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_POS_CONFIGURATION}</th>
							<th>{phrases.MODAL_BATCH_STEP_WORKPLACE}</th>
							<th>{phrases.MODAL_BATCH_STEP_PRODUCT_RECOMMENDATION}</th>
							<th>{phrases.MODAL_BATCH_STEP_POS_TILE_LAYOUT}</th>
							<th>{phrases.MODAL_BATCH_STEP_APP_TILE_LAYOUT}</th>
							<th>{phrases.MODAL_BATCH_STEP_INGREDIENT_CONFIGURATION}</th>
							<th>{phrases.MODAL_BATCH_STEP_ACTIVE_FROM}</th>
							<th>{phrases.MODAL_BATCH_STEP_ACTIVE_TO}</th>

							<th />
						</tr>
					</thead>
					<tbody>
						{batchListData.map((entry, i) => {
							const rowDisabled = !entry.canAdd;

							return (
								<TableListRow
									key={entry.identifier}
									type={rowDisabled && 'light'}>
									<TableListCell>
										{rowDisabled && (
											<Tag type="bullet" color={this.cantAddBulletColor} />
										)}
										{i + 1}
									</TableListCell>
									<TableListCell>
										{_get(entry, 'posConfiguration.name', '')}
									</TableListCell>
									<TableListCell>
										{_get(entry, 'workplace.name', '')}
									</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id={`productRecommendation-${entry.identifier}`}
											value={this._getCollectionSelectValue(
												entry.productRecommendation
											)}
											handleChange={(key, event) =>
												this._editListEntry(
													event,
													entry,
													'productRecommendation'
												)
											}
											clearable={false}
											cache
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											apiPath={productRecommendationUrl}
											params={{
												limit: 30,
											}}
											required
											disabled={rowDisabled}
										/>
									</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id={`posTileLayout-${entry.identifier}`}
											placeholder={
												phrases.MODAL_BATCH_STEP_POS_TILE_LAYOUT_PLACEHOLDER
											}
											value={this._getCollectionSelectValue(
												entry.posTileLayout
											)}
											handleChange={(key, event) =>
												this._editListEntry(event, entry, 'posTileLayout')
											}
											clearable={false}
											cache
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											apiPath={tileLayoutUrl}
											inputFilterFormat={(input) => `:name=like='%${input}%'`}
											params={{
												limit: 30,
												filter: `:type=='POS'`,
											}}
											required
											disabled={rowDisabled}
										/>
									</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id={`appTileLayout-${entry.identifier}`}
											placeholder={
												phrases.MODAL_BATCH_STEP_APP_TILE_LAYOUT_PLACEHOLDER
											}
											value={this._getCollectionSelectValue(
												entry.appTileLayout
											)}
											handleChange={(key, event) =>
												this._editListEntry(event, entry, 'appTileLayout')
											}
											clearable={false}
											cache
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											apiPath={tileLayoutUrl}
											inputFilterFormat={(input) => `:name=like='%${input}%'`}
											params={{
												limit: 30,
												filter: `:type=='App'`,
											}}
											required
											disabled={rowDisabled}
										/>
									</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id={`ingredientConfiguration-${entry.identifier}`}
											placeholder={
												phrases.MODAL_BATCH_STEP_INGREDIENT_CONFIGURATION_PLACEHOLDER
											}
											value={this._getCollectionSelectValue(
												entry.ingredientConfiguration
											)}
											handleChange={(key, event) =>
												this._editListEntry(
													event,
													entry,
													'ingredientConfiguration'
												)
											}
											clearable={false}
											cache
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											apiPath={ingredientConfigurationUrl}
											params={{
												limit: 30,
											}}
											required
											disabled={rowDisabled}
										/>
									</TableListCell>

									<TableListCell>
										<SingleDatePickerInput
											id={`activeFrom-${entry.identifier}`}
											onChange={(event) =>
												this._editListEntry(event, entry, 'activeFrom')
											}
											selectedDate={moment
												.utc(entry.activeFrom)
												.format(constants.shortDate)}
											noClockButton
											required
											disabled={rowDisabled}
										/>
									</TableListCell>

									<TableListCell>
										<SingleDatePickerInput
											id={`activeTo-${entry.identifier}`}
											onChange={(event) =>
												this._editListEntry(event, entry, 'activeTo')
											}
											selectedDate={moment
												.utc(entry.activeTo)
												.format(constants.shortDate)}
											noClockButton
											required
											disabled={rowDisabled}
										/>
									</TableListCell>

									<TableListCell>
										{!rowDisabled && (
											<Button
												type="negative"
												size="tiny"
												onClick={() => this._onRemoveEntry(entry.identifier)}>
												<span className="icon icon--delete" />
											</Button>
										)}
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
			</>
		);
	}
}

WorkplacePosConfigurationBatchStepListAdd.propTypes = {
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	productRecommendationUrl: PropTypes.string,
	tileLayoutUrl: PropTypes.string,
	ingredientConfigurationUrl: PropTypes.string,
};

WorkplacePosConfigurationBatchStepListAdd.defaultProps = {};

export default WorkplacePosConfigurationBatchStepListAdd;
