'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';
const __endpoints = require('endpoints.service');

export default class WorkplaceTillStations extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'number',
				type: 'number',
				canAdd: true,
				canEdit: true,
				required: true,
			},
		];
		this.settings = {
			name: 'WorkplaceTillStations',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			canCollapse: true,
			alwaysRefresh: true,
			canManipulate: () => validAccess(undefined, ['Organisation/Markets::Edit', 'Organization Admin']),
			translationPath: 'POS.TILL_STATIONS',
			endpoint: __endpoints.collection.pos.till_stations,
		};

		this.init();
		return this;
	}
}
