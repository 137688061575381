import React, { Suspense } from 'react';

// import Shiftplanner from 'shiftPlanner/components/shiftplanner.container';
const Shiftplanner = React.lazy(() =>
	import(/* webpackChunkName: "shiftplanner" */ 'shiftPlanner/components/shiftplanner.container')
);

export const shiftPlannerRoutes = [
	{
		element: (
			<Suspense fallback={'Loading'}>
				<Shiftplanner />
			</Suspense>
		),
		path: 'shift-planner',
		title: 'Shift planning',
		description: 'Shift planning and scheduling',
		keywords: ['shiftplan', 'sf', 'shift', 'plan', 'planning', 'shiftpla'],
		icon: require('@mui/icons-material/DateRangeOutlined').default,
		allowedAccessControlLevels: ['Shiftplan View', 'Shiftplan Manager', 'Shiftplan Admin'],
	},
];
