/* eslint-disable react/display-name */
import React from 'react';

// interfaces
import { IMarketResponsibility, IMarketResponsibilityEdit } from '../../types';
import { SelectOption } from 'types/select';

// components
import { SingleDatePickerInput } from 'dumb';

// phrases
import moment, { Moment } from 'moment';
import constants from 'services/constants';

type Props = {
	marketResponsibility: IMarketResponsibilityEdit;
	editStoreEntry: (
		name: keyof IMarketResponsibility,
		value: string | SelectOption | null
	) => void;
};

const MarketResponsiblitiesEditableCells = ({
	marketResponsibility,
	editStoreEntry,
}: Props): { header: string; value: JSX.Element }[] => {
	return [
		{
			header: 'Role',
			value: <span>{marketResponsibility.role}</span>,
		},
		{
			header: 'Location',
			value: <span>{marketResponsibility.location}</span>,
		},
		{
			header: 'From',
			value: (
				<SingleDatePickerInput
					id="from"
					onChange={(event: Moment) =>
						editStoreEntry('from', event.format(constants.shortDate))
					}
					selectedDate={
						marketResponsibility.from || moment().format(constants.shortDate)
					}
					noClockButton
					required
				/>
			),
		},
		{
			header: 'To',
			value: (
				<SingleDatePickerInput
					id="to"
					onChange={(event: Moment) =>
						editStoreEntry('to', event.format(constants.shortDate))
					}
					selectedDate={marketResponsibility.to || '9999-12-31'}
					noClockButton
					clearable={marketResponsibility.to !== '9999-12-31'}
				/>
			),
		},
	];
};

export default MarketResponsiblitiesEditableCells;
