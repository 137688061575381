// redux
import { store } from 'appState';
import {
	setData,
	toggleModal,
} from 'modules/common/dumb/modal/confirmModal/store/confirmModal.actions';

const ConfirmModal = {
	show: onShow,
};

export function onShow(data) {
	// first set the data into the confirmModal reducer
	store.dispatch(setData(data));

	// then open the modal
	store.dispatch(toggleModal());
}

export default ConfirmModal;
