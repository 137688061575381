'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import phoneNumbers from './inputPhoneNumbers';

// Inputs
import { Label, Icon, Input } from 'dumb';
import CollectionSelect from 'collectionSelect';

import collectionSelectEnums from 'services/enums/collectionSelect';

import './input.css';

function InputPhone({
	id,
	label,
	className,
	phoneNumber,
	disabled,
	placeholder,
	required,
	onChange,
	readOnly,
	onClick,
	onBlur,
	submitValue,
	enableKeyPress,
	inJuicerDetails,
	enableValueCopy,
	copyTooltipZindex,
	dataCy,
}) {
	/**
	 * @function _handleSelectChange
	 * @param {Object} e
	 * @description handles the change of the select dropdown
	 * if country code present it will clear it and add a new one
	 */
	const _handleSelectChange = (e) => {
		if (!e) return;

		// get country code or null if X pressed
		const countryCode = e.value || e;

		// check if there's a country code associated with the number
		const phoneNumberWithoutCountryCode = _getCountrySelectValue()
			? phoneNumber.split(' ')[1]
			: phoneNumber;

		const value = getNewPhoneNumberString(
			countryCode,
			phoneNumberWithoutCountryCode
		);

		onChange(value, true);
	};

	/**
	 * @function _handleInputChange
	 * @param {Object} e
	 * @description when input changes, takes the number without the country code
	 * and adds it back from the value passed down from props and submit the joined strings
	 */
	const _handleInputChange = (e) => {
		let value = e.target ? e.target.value : e;

		// check if value from props has a country code
		const countryCode = _getCountrySelectValue();
		if (countryCode) {
			value = getNewPhoneNumberString(countryCode.value, value);
		}

		onChange(value);
	};

	const getNewPhoneNumberString = (countryCode, number) => {
		const newPhoneNumber = `${countryCode} ${number}`;

		return newPhoneNumber;
	};

	/**
	 * @function _getCountrySelectValue
	 * @description gets a whole phone number string and returns the values
	 * between the parantheses which in our case is the country code
	 * @usage both for displaying drop down values in the select and
	 * to get the country code from the phone number
	 */
	const _getCountrySelectValue = () => {
		const match = phoneNumber.split(' ');

		const prefix = match[0];

		const country = phoneNumbers.countries.find(
			(number) => number.value === prefix
		);

		if (!country) return;

		return {
			...country,
			label: country.label.substring(0, 3),
		};
	};

	const _handlekeyPress = () => {
		if (!enableKeyPress) return;

		if (event.key === 'Enter') {
			submitValue(event.key);
		}
		if (event.key === 'Esc') {
			onBlur(event.key);
		}
	};

	const _handleKeyDown = (event) => {
		if (!enableKeyPress) return;

		// enter
		if (event.which === 13) {
			submitValue(event.key);
		}
		// esc
		if (event.which === 27) {
			onBlur(event.which);
		}
	};

	const classN = classnames('j-input', {
		'j-input--required': required,
		'j-input--disabled': disabled || readOnly,
		className,
	});

	let phoneNumberToDisplay = phoneNumber;

	// get country code if exists
	let countryCode = _getCountrySelectValue();
	if (countryCode) {
		// get phone number without the country code (displayed in the input field)
		phoneNumberToDisplay = phoneNumber.split(' ')[1];
		countryCode = countryCode.value;
	}

	return (
		<div className={classN}>
			{label && <Label htmlFor={id}>{label}</Label>}
			<div className="j-input__phone-wrapper" onClick={onClick}>
				<CollectionSelect
					className="j-input__phone-wrapper__select"
					placeholder="Code"
					id={id}
					name={id}
					options={phoneNumbers.countries}
					onChange={_handleSelectChange}
					value={_getCountrySelectValue()}
					clearable={false}
					backspaceRemoves={false}
					disabled={readOnly}
					onBlur={onBlur}
					styleType={
						inJuicerDetails
							? collectionSelectEnums.TYPE_JUICER_DETAILS_PHONE
							: collectionSelectEnums.TYPE_PHONE
					}
				/>
				<Icon
					className="j-input__select j-input__phone-wrapper__arrows"
					name="dropdown-arrows"
				/>
				<span className="j-input__phone-wrapper__country-code-span">
					{countryCode || '--'}
				</span>
				<Input
					id={id + 'input-phone'}
					name={id}
					className="j-input__input"
					placeholder={placeholder}
					disabled={disabled}
					readOnly={readOnly}
					value={phoneNumberToDisplay}
					onChange={_handleInputChange}
					clearable="true" // non-existant prop for inputs, however prompts warning if true passed as default
					onBlur={onBlur}
					onKeyDown={_handleKeyDown}
					onKeyPress={_handlekeyPress}
					enableValueCopy={enableValueCopy}
					copyTooltipZindex={copyTooltipZindex}
					fullWidth
					dataCy={dataCy}
				/>
			</div>
		</div>
	);
}

InputPhone.defaultProps = {
	onClick: () => {},
	onBlur: () => {},
	dataCy: '',
};

InputPhone.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	phoneNumber: PropTypes.string,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	readOnly: PropTypes.bool,
	enableKeyPress: PropTypes.bool,
	inJuicerDetails: PropTypes.bool,
	enableValueCopy: PropTypes.bool,
	copyTooltipZindex: PropTypes.number,
	dataCy: PropTypes.string,

	// methods
	onChange: PropTypes.func,
	onClick: PropTypes.func,
	onBlur: PropTypes.func,
	submitValue: PropTypes.func,
};

export default InputPhone;
