import React, { ChangeEvent } from 'react';

// interfaces
import { IVacationescriptionPresetBatch } from '../../../types';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import {
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,
} from './../../../store/vacationDescriptionPresets.actions';

// components
import { Input } from 'dumb';
import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';
import { Box, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

// utils
import phrases from './../../../vacationDescriptionPresets.phrases';

const VacationDescriptionPresetsModalStep = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const batchList = useAppSelector(
		(store) => store.vacationDescriptionPresets.batchList
	);

	const handleUpdateEntry = (
		row: IVacationescriptionPresetBatch,
		type: keyof IVacationescriptionPresetBatch,
		value: string | null
	) => {
		const payload: Partial<IVacationescriptionPresetBatch> = {
			identifier: row.identifier,
			[type]: value,
		};

		dispatch(editBatchListItem(payload));
	};
	const handleDeleteRow = (identifier: string) => () =>
		dispatch(removeBatchListItem(identifier));
	const handleAddRow = () => dispatch(addBatchListItemRow());

	return (
		<>
			<TableList>
				<thead>
					<tr>
						<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
						<th>{phrases.MODAL_BATCH_STEP_LIST_SUBJECT}</th>
						<th>{phrases.MODAL_BATCH_STEP_LIST_DESCRIPTION}</th>

						<th />
					</tr>
				</thead>
				<tbody>
					{batchList.map((entry, i) => {
						const deleteRowButtonDisabled = batchList.length === 1;

						return (
							<TableListRow key={entry.identifier}>
								<TableListCell>{i + 1}</TableListCell>

								<TableListCell>
									<Input
										id="subject"
										dataCy="market-responsibilities-batch-add-subject"
										placeholder="Enter subject..."
										value={entry.subject}
										onChange={(e: ChangeEvent<HTMLInputElement>) =>
											handleUpdateEntry(entry, 'subject', e.target.value)
										}
										required
									/>
								</TableListCell>

								<TableListCell>
									<Input
										id="description"
										dataCy="market-responsibilities-batch-add-description"
										placeholder="Enter description..."
										value={entry.description}
										onChange={(e: ChangeEvent<HTMLInputElement>) =>
											handleUpdateEntry(entry, 'description', e.target.value)
										}
										required
									/>
								</TableListCell>

								<TableListCell>
									<Tooltip title={phrases.DELETE_ROW}>
										<IconButton
											onClick={handleDeleteRow(entry.identifier)}
											data-cy="market-responsibilities-batch-add-delete-row"
											disabled={deleteRowButtonDisabled}
										>
											<DeleteIcon
												sx={{
													color: (theme) => theme.palette.warning.main,
												}}
											/>
										</IconButton>
									</Tooltip>
								</TableListCell>
							</TableListRow>
						);
					})}
				</tbody>
			</TableList>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					mt: 2,
				}}
			>
				<Tooltip title={phrases.ADD_ROW_TOOLTIP}>
					<IconButton
						onClick={handleAddRow}
						data-cy="market-responsibilities-batch-add-add-row"
					>
						<AddIcon />
					</IconButton>
				</Tooltip>
			</Box>
		</>
	);
};

export default VacationDescriptionPresetsModalStep;
