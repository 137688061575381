import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { useSelector } from 'react-redux';
import logo from './logo-mark.svg';
import './pageLoader.css';

export function PageLoader() {
	const pageLoader = useSelector((store) => store.pageLoader);

	return (
		<CSSTransition classNames="page-loader-animations" in={pageLoader.loading !== false} unmountOnExit timeout={3000}>
			<div className="page-loader">
				<img src={logo} alt="Logo" title="Joe & The Juice Workplanner" className="page-loader__logo" />
				<h2 className="page-loader__title">WORKPLANNER</h2>
				<div className="page-loader__spinner" />
			</div>
		</CSSTransition>
	);
}
