'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { InputCollectionSelect, Toggle } from 'dumb';
import { ModalRow } from 'dumb/modal';

// lodash
import _get from 'lodash/get';

// constants/phrases/enums
import phrases from './../../../ingredientConfigurationIngredients.phrases';

class IngredientConfigurationIngredientsBatchStepForm extends Component {
	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		updateBatchForm({
			[type]: e,
		});
	}

	render() {
		const { batchFormData, batchAddFilter } = this.props;

		return (
			<>
				<ModalRow>
					<InputCollectionSelect
						id="ingredient-configuration-ingredient-name"
						placeholder="Select ingredient configuration ingredient"
						value={batchFormData.ingredients}
						apiPath="/product/ingredients"
						params={{
							limit: 30,
							filter: batchAddFilter,
						}}
						handleChange={(name, event) =>
							this.editStoreEntry(event, 'ingredients')
						}
						cache
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						multi
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						tableReduxKey="ingredient-configuration-ingredient-modal-add/product/ingredients"
						tableTitle={phrases.INGREDIENTS_TABLE_TITLE}
						tableColumns={[
							{
								Header: 'Name',
								id: 'name',
								accessor: (d) => _get(d, 'name', ''),
								filterPath: ':name',
							},
							{
								Header: 'Ingredient category',
								id: 'ingredient_category',
								accessor: (d) => _get(d, 'ingredient_category.name', ''),
								filterPath: ':ingredient_category.name',
							},
							{
								Header: 'Fuel',
								id: 'fuel',
								accessor: (d) => _get(d, 'fuel', ''),
								filterPath: ':fuel',
							},
							{
								Header: 'Asset Collection',
								id: 'asset_collection',
								accessor: (d) => _get(d, 'asset_collection.name', null),
								filterPath: ':asset_collection.name',
							},
							{
								Header: 'Ingredient Pricing Group',
								id: 'ingredient_pricing_group',
								accessor: (d) => _get(d, 'ingredient_pricing_group.name', null),
								filterPath: ':ingredient_pricing_group.name',
							},
							{
								Header: 'List name',
								id: 'list_name',
								accessor: 'list_name',
								filterPath: ':list_name',
							},
							{
								Header: 'Operation type',
								id: 'operation_type',
								accessor: 'operation_type',
								filterPath: ':operation_type',
							},
							{
								Header: 'Availability administration',
								id: 'availability-administration',
								accessor: 'availability_administration',
								filterPath: ':availability_administration',
								filterable: false,
								Cell: (d) => {
									return (
										<input
											type="checkbox"
											checked={_get(
												d,
												'original.availability_administration',
												false
											)}
											disabled
										/>
									);
								},
							},
							{
								Header: 'Legacy',
								id: 'legacy',
								accessor: (d) => _get(d, 'legacy', ''),
								filterPath: ':legacy',
								filterable: false,
								Cell: (d) => {
									return (
										<input
											type="checkbox"
											checked={_get(d, 'original.legacy', false)}
											disabled
										/>
									);
								},
							},
						]}
					/>
				</ModalRow>
				<ModalRow>
					<Toggle
						id="available-in-app"
						label={
							phrases.INGREDIENT_CONFIGURATION_INGREDIENTS_MODAL_HEADER_AVAILABLE_IN_APP
						}
						toggled={batchFormData.availableInApp}
						onClick={(e) => this.editStoreEntry(e, 'availableInApp')}
					/>
				</ModalRow>
			</>
		);
	}
}

IngredientConfigurationIngredientsBatchStepForm.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	batchAddFilter: PropTypes.string,
};

export default IngredientConfigurationIngredientsBatchStepForm;
