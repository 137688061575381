import React from 'react';
import PropTypes from 'prop-types';

// components
import { Modal, InputCleave } from 'dumb';
import { ModalRow } from 'dumb/modal';

// phrases
import phrases from './../../homeScreenRecommendations.phrases';

const EditTimeSlotModal = ({
	onClose,
	onConfirmClick,
	timeSlotToEdit,
	updateTimeSlotToEdit,
	loading,
}) => {
	const updateTimeSlotWrapper = (value, type) => {
		const payload = {
			[type]: value,
		};

		updateTimeSlotToEdit(payload);
	};

	return (
		<Modal
			isOpen
			loading={loading}
			onClose={onClose}
			onConfirmClick={onConfirmClick}
			header={phrases.EDIT_TIME_SLOT}>
			<ModalRow>
				<InputCleave
					id="time-from"
					tabIndex={1}
					label={phrases.TIME_FROM}
					options={{
						time: true,
						timePattern: ['h', 'm'],
					}}
					value={timeSlotToEdit.timeFrom}
					placeholder="hh:mm"
					onChange={(event) =>
						updateTimeSlotWrapper(event.target.value, 'timeFrom')
					}
					autoComplete="off"
				/>
				<InputCleave
					id="time-to"
					tabIndex={1}
					label={phrases.TIME_TO}
					options={{
						time: true,
						timePattern: ['h', 'm'],
					}}
					value={timeSlotToEdit.timeTo}
					placeholder="hh:mm"
					onChange={(event) =>
						updateTimeSlotWrapper(event.target.value, 'timeTo')
					}
					autoComplete="off"
				/>
			</ModalRow>
		</Modal>
	);
};

EditTimeSlotModal.propTypes = {
	onClose: PropTypes.func,
	onConfirmClick: PropTypes.func,
	timeSlotToEdit: PropTypes.object,
	updateTimeSlotToEdit: PropTypes.func,
	loading: PropTypes.bool,
};

export default EditTimeSlotModal;
