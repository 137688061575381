import { set as setFeedback } from 'feedback.vanilla.service.js';
import FilterProviderEnums from './filterProvider.enums';

// lodash
import _get from 'lodash/get';

// constants
import constants from 'services/constants';

/**
 * @function addFilter
 * @description Parses filterPath parameter and
 * @param {Array} filteredColumns
 * @param {Array} columns
 * @param {String} extraFilters
 */
export default function addFilter(
	filteredColumns = [],
	columns = [],
	extraFilters = '',
	listSearchFilter
) {
	if (!filteredColumns) filteredColumns = [];

	let filters = filteredColumns
		.filter((e) => e.value)
		.map((entry, index, arr) => {
			const filteredColumn = columns.find((column) => column.id === entry.id);

			if (!filteredColumn) return;

			if (!filteredColumn.Header) {
				setFeedback(
					`No id added to column with Header ${filteredColumn.Header}`,
					0
				);
				return;
			}

			if (!filteredColumn.filterPath) {
				setFeedback(
					`No filterpath added to Column with id ${filteredColumn.id}`,
					0
				);
				return;
			}

			let filterString;

			if (
				entry.filterType === FilterProviderEnums.DATE ||
				entry.filterType === FilterProviderEnums.DATE_WITH_TIMESTAMP
			) {
				const formatOption =
					entry.filterType === FilterProviderEnums.DATE
						? constants.shortDate
						: constants.dateFormat;

				const filterStringStart = _get(entry, 'value.startDate', false)
					? `${filteredColumn.filterPath}=ge='${moment
							.utc(_get(entry, 'value.startDate', ''))
							.format(formatOption)}'`
					: null;
				const filterStringEnd = _get(entry, 'value.endDate', false)
					? `${filteredColumn.filterPath}=le='${moment
							.utc(_get(entry, 'value.endDate', ''))
							.format(formatOption)}'`
					: null;

				if (filterStringStart) filterString = filterStringStart;
				if (filterStringEnd)
					filterString = filterString
						? `${filterString};${filterStringEnd}`
						: filterStringEnd;
			} else
				filterString = `${filteredColumn.filterPath}=like='%${entry.value}%'`;

			// append original column filterPath with the filter value
			return index === arr.length - 1 ? filterString : `${filterString};`;
		})
		.join('');

	let searchFilter = '';
	if (listSearchFilter) {
		searchFilter = listSearchFilter.reduce((acc, currentValue, index) => {
			acc = index === 0 ? '(' : acc;

			return index === listSearchFilter.length - 1
				? `${acc}${currentValue.filterPath}=like='%${currentValue.value}%')`
				: `${acc}${currentValue.filterPath}=like='%${currentValue.value}%',`;
		}, '');
	}

	// add extra filters
	filters = extraFilters
		? filters
			? `${filters};${extraFilters}`
			: extraFilters
		: filters;

	// add search filters
	return searchFilter
		? filters
			? `${filters};${searchFilter}`
			: searchFilter
		: filters;
}
