import { v4 as getUuid } from 'uuid';
// util to get the correct date when adding a break
import { getDate } from './../../shiftClockinsTable/components/columns/utils';

// utils
import moment from 'moment';
import constants from 'services/constants';

export function constructRowToUpdate({
	breakEndsOnAnotherDay,
	managedShiftClockin,
	breakObject,
}) {
	// get from date (all breaks are based on from date, if the break end is on another day, we add a day)
	let date = getDate({ type: 'from', entry: managedShiftClockin });

	const breakFrom = `${date}T${breakObject.from}:00+00:00`;

	if (breakEndsOnAnotherDay) {
		date = moment
			.utc(date, constants.shortDate)
			.add(1, 'day')
			.format(constants.shortDate);
	}
	const breakTo = `${date}T${breakObject.to}:00+00:00`;

	const payload = {
		...managedShiftClockin,
		breaks: [
			...managedShiftClockin.breaks,
			{
				id: getUuid(),
				planned: { from: null, to: null },
				clocked: { from: null, to: null },
				final: {
					from: breakFrom,
					to: breakTo,
				},
			},
		],
	};

	return payload;
}
