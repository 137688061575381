export const RESET_PRODUCT_VARIANT_INGREDIENT =
	'SALES_CONFIGURATION_PRODUCT_PRODUCT_VARIANT_INGREDIENTS/RESET_PRODUCT_VARIANT_INGREDIENT';
export const SET_PRODUCT_VARIANT_INGREDIENT =
	'SALES_CONFIGURATION_PRODUCT_PRODUCT_VARIANT_INGREDIENTS/SET_PRODUCT_VARIANT_INGREDIENT';
export const UPDATE_PRODUCT_VARIANT_INGREDIENT =
	'SALES_CONFIGURATION_PRODUCT_PRODUCT_VARIANT_INGREDIENTS/UPDATE_PRODUCT_VARIANT_INGREDIENT';

/**
 * @param {object} payload
 */
export function setProductVariantIngredients(payload) {
	return {
		type: SET_PRODUCT_VARIANT_INGREDIENT,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateProductVariantIngredients(payload) {
	return {
		type: UPDATE_PRODUCT_VARIANT_INGREDIENT,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetProductVariantIngredients() {
	return {
		type: RESET_PRODUCT_VARIANT_INGREDIENT
	};
}
