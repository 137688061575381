'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { InputCollectionSelect } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';

import {
	fetchProductRecommendationProductVariants,
	addProductRecommendationProductVariant,
	deleteProductRecommendationProductVariant
} from './productRecommendationProductVariants.service';

import {
	setProductRecommendationProductVariants,
	updateProductRecommendationProductVariant,
	resetState
} from './store/productRecommendationProductVariants.actions';

// constants/phrases/tools
import phrases from './productRecommendationProductVariants.phrases';
import _get from 'lodash/get';

class ProductRecommendationProductVariants extends Component {
	constructor(props) {
		super(props);

		this.fetchData = this.fetchData.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);

		this.columns = [
			{
				Header: 'Product variant',
				id: 'productVariant',
				accessor: d => _get(d, 'product_variant.name', ''),
				filterPath: ':product_variant.name'
			}
		];
	}

	deleteEntry(id) {
		return deleteProductRecommendationProductVariant(id);
	}

	addEntry() {
		const {
			productRecommendationProductVariant,
			productRecommendationId
		} = this.props;

		const payload = {
			product_variant: _get(
				productRecommendationProductVariant,
				'productVariant.value',
				null
			),
			product_recommendation: productRecommendationId
		};

		return addProductRecommendationProductVariant(payload);
	}

	setInitialEditValues(data) {
		const { setProductRecommendationProductVariants } = this.props;

		const payload = {
			id: data.id,
			productVariant: {
				value: data.product_variant.id,
				label: data.product_variant.name
			}
		};

		setProductRecommendationProductVariants(payload);
	}

	getEditableCells() {
		const { productRecommendationProductVariant } = this.props;

		return [
			{
				header: 'Product variant name',
				value: (
					<InputCollectionSelect
						id="productVariant"
						placeholder="Select product variant"
						value={productRecommendationProductVariant.productVariant || null}
						handleChange={(name, event) => this.editStoreEntry(name, event)}
						clearable
						cache
						apiPath="/product/product_variants"
						params={{
							limit: 30
						}}
						optionFormat={entry => ({
							value: entry.id,
							label: entry.name
						})}
						inputFilterFormat={input => `:name=like='%${input}%'`}
					/>
				)
			}
		];
	}

	editStoreEntry(name, event) {
		const { updateProductRecommendationProductVariant } = this.props;

		const payload = {
			[name]: event
		};

		updateProductRecommendationProductVariant(payload);
	}

	fetchData(state) {
		const { productRecommendationId } = this.props;

		state = {
			...state,
			filter: state.filter
				? `${state.filter};:product_recommendation.id=='${productRecommendationId}'`
				: `:product_recommendation.id=='${productRecommendationId}'`
		};

		return fetchProductRecommendationProductVariants(state);
	}

	render() {
		const { productRecommendationId } = this.props;

		return (
			<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.TABLE_TITLE}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={10}
				reduxKey={`/pos/product_recommendation_product_variants_${productRecommendationId}`}
				manual
				editableCells={this.getEditableCells()}
				actionsWidth={30}
				createEntry={this.addEntry}
				deleteEntry={this.deleteEntry}
				editMultiple={this.editMultiple}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetState}
				
			/>
		);
	}
}

ProductRecommendationProductVariants.propTypes = {
	setProductRecommendationProductVariants: PropTypes.func,
	resetState: PropTypes.func,
	updateProductRecommendationProductVariant: PropTypes.func,
	productRecommendationProductVariant: PropTypes.object,
	productRecommendationId: PropTypes.number
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			setProductRecommendationProductVariants,
			updateProductRecommendationProductVariant,
			resetState
		},
		dispatch
	);
};

const mapStateToProps = store => {
	return {
		productRecommendationProductVariant:
			store.salesConfigurationProductRecommendationProductVariant.data
				.productRecommendationProductVariant
	};
};

export default connectWithStore(
	ProductRecommendationProductVariants,
	mapStateToProps,
	mapDispatchToProps
);
