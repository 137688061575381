'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input, InputCollectionSelect, Tag, Tooltip } from 'dumb';
import { ModalRow } from 'dumb/modal';
import { Tip } from 'dumb/tips';

// phrases/ enums
import phrases from './../../../posConfigurationProductVariantsPricing.phrases';
import enums from './../../../posConfigurationProductVariantsPricing.enums';

// utils
import {
	getAdjustmentTypeOptions,
	getRoundToNearestOptions,
} from './../../../utils';

// lodash
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

class NominalPercentageStepForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};
	}

	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		const value = _get(e, 'target', false) ? e.target.value : e;

		updateBatchForm({
			[type]: value,
		});
	}

	_getTags() {
		const { batchList } = this.props;

		return batchList.map((entry, index) => {
			return (
				<Tooltip
					key={index}
					zIndex={600}
					text={`${entry.toStayPrice} / ${entry.toGoPrice}`}
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Tag
							color="dddddd"
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}>
							{`${entry.posConfigurationVersionName} - ${entry.productVariantName}`}
						</Tag>
					)}
				/>
			);
		});
	}

	render() {
		const { batchFormData } = this.props;

		const showNominalInputs =
			_get(batchFormData, 'adjustmentType.value', '') === enums.NOMINAL;
		const showPercentageInputs =
			_get(batchFormData, 'adjustmentType.value', '') === enums.PERCENTAGE &&
			!_isEmpty(batchFormData.roundToNearest);

		return (
			<>
				<div className="product-variant-pricings__tags-wrapper">
					<span>{phrases.MODAL_BATCH_TAG_TEXT}</span>
					<div className="product-variant-pricings__tags-wrapper__tags">
						{this._getTags()}
					</div>
				</div>

				<ModalRow>
					<div className="product-variant-pricings__discount-type-wrapper">
						<InputCollectionSelect
							id="adjustmentType"
							label={phrases.MODAL_BATCH_STEP_FORM_ADJUSTMENT_TYPE}
							placeholder={phrases.MODAL_BATCH_STEP_DISCOUNT_TYPE_PLACEHOLDER}
							options={getAdjustmentTypeOptions()}
							value={batchFormData.adjustmentType}
							onChange={(value) => this.editStoreEntry(value, 'adjustmentType')}
							clearable={false}
						/>
						{!showNominalInputs && (
							<InputCollectionSelect
								id="roundToNearest"
								label={phrases.MODAL_BATCH_STEP_ROUND_TO_NEAREST}
								placeholder={
									phrases.MODAL_BATCH_STEP_ROUND_TO_NEAREST_PLACEHOLDER
								}
								options={getRoundToNearestOptions()}
								value={batchFormData.roundToNearest}
								onChange={(value) =>
									this.editStoreEntry(value, 'roundToNearest')
								}
								clearable={false}
							/>
						)}
					</div>
				</ModalRow>

				<ModalRow>
					<Tip
						content={
							showNominalInputs
								? phrases.MODAL_BATCH_STEP_NOMINAL_TIP
								: phrases.MODAL_BATCH_STEP_PERCENTAGE_TIP
						}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						id="toStayPrice"
						type="number"
						disabled={!showNominalInputs && !showPercentageInputs}
						label={
							showNominalInputs
								? phrases.MODAL_BATCH_STEP_FORM_ADJUST_TO_STAY_PRICE
								: phrases.MODAL_BATCH_STEP_ADJUSTMENT_AMOUNTS_TO_STAY_PERCENTAGE
						}
						placeholder={phrases.MODAL_BATCH_STEP_DISCOUNT_AMOUNTS_PLACEHOLDER}
						value={
							showNominalInputs
								? batchFormData.toStayPrice || ''
								: batchFormData.toStayPricePercentage || ''
						}
						onChange={(value) =>
							showNominalInputs
								? this.editStoreEntry(value, 'toStayPrice')
								: this.editStoreEntry(value, 'toStayPricePercentage')
						}
					/>
				</ModalRow>
				<ModalRow>
					<Input
						id="toGoPrice"
						type="number"
						disabled={!showNominalInputs && !showPercentageInputs}
						label={
							showNominalInputs
								? phrases.MODAL_BATCH_STEP_FORM_ADJUST_TO_GO_PRICE
								: phrases.MODAL_BATCH_STEP_ADJUSTMENT_AMOUNTS_TO_GO_PERCENTAGE
						}
						placeholder={phrases.MODAL_BATCH_STEP_DISCOUNT_AMOUNTS_PLACEHOLDER}
						value={
							showNominalInputs
								? batchFormData.toGoPrice || ''
								: batchFormData.toGoPricePercentage || ''
						}
						onChange={(value) =>
							showNominalInputs
								? this.editStoreEntry(value, 'toGoPrice')
								: this.editStoreEntry(value, 'toGoPricePercentage')
						}
					/>
				</ModalRow>
			</>
		);
	}
}

NominalPercentageStepForm.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	batchList: PropTypes.array,
};

export default NominalPercentageStepForm;
