import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';

import HotkeysService from 'hotkeys.service';
import DefaultFooter from './components/defaultFooter';
import ConfirmationFooter from './components/confirmationFooter';
import { Loader, Backdrop } from 'dumb';
import { Typography } from '@mui/material';

// Services
import { confirmModal } from 'utils';
import './modal.css';

class Modal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			/*
			for the CollectionSelect element - when it's open set the state to true so that the
			esc pressed on modal doesn't close both the select and the modal but first the select
			*/
			isSelectOpen: false,
		};

		this.node = document.createElement('div');
		document.body.appendChild(this.node);

		// this._onkeyDown = this._onkeyDown.bind(this);
		this._onkeyEscDown = this._onkeyEscDown.bind(this);
		this._onkeyEnterDown = this._onkeyEnterDown.bind(this);
		this._handleClickOutside = this._handleClickOutside.bind(this);
		this._bindHotkeys = this._bindHotkeys.bind(this);
		this._setConfirmModal = this._setConfirmModal.bind(this);
	}

	_handleClickOutside(e) {
		const { onClose, loading, closeConfirm } = this.props;

		if (!loading) {
			if (closeConfirm) {
				this._setConfirmModal();
			} else {
				onClose();
			}
		}
	}

	_setConfirmModal() {
		const { onClose } = this.props;
		HotkeysService.unbind('esc, enter');
		confirmModal.show({
			data: {},
			onConfirm: onClose,
			callback: this._bindHotkeys,
		});
	}

	_onkeyEscDown() {
		const {
			isSelectOpen,
			required,
			loading,
			onClose,
			isOpen,
			closeConfirm,
			disableKeyBindings,
		} = this.props;

		if (!isOpen) return;

		// if escape is pressed and any select element is not open
		if (
			!loading &&
			!required &&
			!isSelectOpen &&
			onClose &&
			!disableKeyBindings
		) {
			if (closeConfirm) {
				this._setConfirmModal();
			} else {
				onClose();
			}
		}
	}

	_onkeyEnterDown() {
		const {
			isSelectOpen,
			required,
			loading,
			onConfirmClick,
			valid,
			isOpen,
			disableKeyBindings,
		} = this.props;

		if (!isOpen) return;

		// if enter is pressed select the item from the collectionselect if open, if select closed then submit the modal
		if (!loading && !required && valid && !isSelectOpen && !disableKeyBindings)
			onConfirmClick();
	}

	componentDidMount() {
		const { zIndex } = this.props;
		// Add class
		this.node.className = 'j-modal--wrapper';
		this.node.style.zIndex = zIndex;
		document.body.classList.add('modal-active');

		this._bindHotkeys();
	}

	_bindHotkeys() {
		HotkeysService.defineHotkeys({
			keys: 'esc, enter',
			callback: (event, handler) => {
				event.preventDefault();

				switch (handler.key) {
					case 'esc':
						this._onkeyEscDown(event);
						break;

					case 'enter':
						this._onkeyEnterDown(event);
						break;
					default:
						break;
				}
			},
		});
	}

	componentWillUnmount() {
		// this.dim.removeEventListener('click', this._handleClickOutside, false);
		HotkeysService.unbind('esc, enter');
		if (this.node) document.body.removeChild(this.node);
		document.body.classList.remove('modal-active');
	}

	renderFooter() {
		const {
			type,
			onConfirmClick,
			onCancelClick,
			confirmButtonLabel,
			cancelButtonLabel,
			valid,
			loading,
			onClose,
		} = this.props;

		if (type === 'confirmation') {
			return (
				<ConfirmationFooter
					valid={valid}
					onConfirmClick={onConfirmClick}
					onCancelClick={onCancelClick}
					confirmButtonLabel={confirmButtonLabel}
					cancelButtonLabel={cancelButtonLabel}
					onClose={onClose}
					tabIndex={0}
				/>
			);
		}

		return (
			<DefaultFooter
				onClick={() => {
					if (!loading) onConfirmClick();
				}}
				buttonLabel={confirmButtonLabel}
				valid={valid}
				tabIndex={0}
			/>
		);
	}

	render() {
		const {
			children,
			className,
			header,
			isOpen,
			type,
			loading,
			steps,
			customFooter,
			noScroll,
			defaultStyles,
			allowOverflow,
			zIndex,
			modalTip,
			dataCy,
			noContentPadding,
		} = this.props;

		const classnames = cx('j-modal', {
			'j-modal--loading': loading,
			'j-modal--no-scroll': noScroll,
			// eslint-disable-next-line no-unneeded-ternary
			'j-modal--default': defaultStyles === false ? false : true, // we can't just pass defaultStyles because if we don't pass any we want to default to them,
			'j-modal--allow-overflow': allowOverflow,
			'j-modal--no-content-padding': noContentPadding,
			[`${className}`]: className,
		});

		const contentClassnames = cx('j-modal__content', {
			'j-modal__content--no-padding': noContentPadding,
		});

		return ReactDOM.createPortal(
			<CSSTransition
				classNames="j-modal__outer"
				timeout={200}
				in={isOpen}
				mountOnEnter
				appear
			>
				<>
					<Backdrop
						isOpen={isOpen}
						closeBackdrop={this._handleClickOutside}
						zIndex={zIndex}
					/>

					<div
						className={classnames}
						style={{ zIndex: zIndex + 1 }}
						data-cy={dataCy}
					>
						{/* Header */}
						<div className="j-modal__header">
							{steps && <span className="j-modal__header__steps">{steps}</span>}
							{modalTip && (
								<span className="j-modal__header__modal-tip">{modalTip}</span>
							)}

							<Typography variant="h4" component="h2">
								{header}
							</Typography>
						</div>

						{/* Content */}
						<div className={contentClassnames}>
							{children || (this.props.render && this.props.render())}
							{/* {children || this.props.render(this._renderPartialData, this._renderFullData)} */}
						</div>

						{/* Footer */}
						{(type !== 'custom' || customFooter) && (
							<div className="j-modal__action">
								{customFooter || this.renderFooter()}
							</div>
						)}

						{/* Loader */}
						{loading && (
							<div className="j-modal__loader">
								<Loader active={loading} size="medium" />
							</div>
						)}
					</div>
				</>
			</CSSTransition>,
			this.node
		);
	}
}

Modal.defaultProps = {
	headers: [],
	editableCells: [],
	loading: false,
	header: '',
	valid: true,
	type: '',
	isOpen: true,
	className: '',
	zIndex: 550,
	defaultStyles: true,
	onClose: () => {
		// console.info('"onClose" prop was not defined in Modal component.');
	},
	onConfirmClick: () => {
		console.info('"onConfirmClick" prop was not defined in Modal component.');
	},
	allowOverflow: false,
	noContentPadding: false,
};

Modal.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
	]),
	onClose: PropTypes.func,
	className: PropTypes.string,
	header: PropTypes.string,
	type: PropTypes.string,
	dataCy: PropTypes.string,
	isOpen: PropTypes.bool,
	loading: PropTypes.bool,

	noScroll: PropTypes.bool,
	valid: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	steps: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	zIndex: PropTypes.number,
	render: PropTypes.func,

	// Ads
	defaultStyles: PropTypes.bool,

	// Whether the modal is required to be filled, makes the modal not closable with escape and outside click
	required: PropTypes.bool,

	// Footer Props
	confirmButtonLabel: PropTypes.string,
	cancelButtonLabel: PropTypes.string,
	modalTip: PropTypes.string,
	onConfirmClick: PropTypes.func,
	onCancelClick: PropTypes.func,
	customFooter: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
	]),

	// For managing open selects and focused input fields
	isSelectOpen: PropTypes.bool,
	allowOverflow: PropTypes.bool,
	closeConfirm: PropTypes.bool,
	disableKeyBindings: PropTypes.bool,
	noContentPadding: PropTypes.bool,
};

export default Modal;
