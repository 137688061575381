import { store } from 'appState';
import { set as setFeedback } from 'feedback.vanilla.service';
import { formatErrorMessage } from 'api/helpers';

import {
	getOpeningHours,
	postOpeningHours as post
} from './openingHours.service';

export const PERIOD_SET = 'OPENING_HOURS/PERIOD_SET';
export const OPENING_HOURS_FETCHED = 'OPENING_HOURS/OPENING_HOURS_FETCHED';
export const FETCH_TOGGLED = 'OPENING_HOURS/FETCH_TOGGLED';
export const NUMBER_OF_WEEKS = 'OPENING_HOURS/NUMBER_OF_WEEKS';
export const TOGGLE_EDIT_MODE = 'OPENING_HOURS/TOGGLE_EDIT_MODE';
export const INLINE_HOURS_EDITED = 'OPENING_HOURS/INLINE_HOURS_EDITED';

export function toggleEditMode(mode = null) {
	return {
		type: TOGGLE_EDIT_MODE,
		payload: {
			mode
		}
	};
}

export function setPeriod(selectedWeek, endOfPeriod) {
	return {
		type: PERIOD_SET,
		payload: {
			selectedWeek,
			endOfPeriod
		}
	};
}

export function setFetch(fetching) {
	return {
		type: FETCH_TOGGLED,
		payload: {
			fetching
		}
	};
}

export function openingHoursFetched(openingHours) {
	return {
		type: OPENING_HOURS_FETCHED,
		payload: {
			openingHours
		}
	};
}

export function setNumberOfWeeksToShow(numberOfWeeks) {
	return {
		type: NUMBER_OF_WEEKS,
		payload: {
			numberOfWeeks
		}
	};
}

export function editInlineHours(day, time) {
	return {
		type: INLINE_HOURS_EDITED,
		payload: {
			day,
			time
		}
	};
}

export function fetchOpeningHours(workplaceId, period) {
	store.dispatch(setFetch(true));
	return getOpeningHours(workplaceId, period.from, period.to).then(
		openingHours => {
			store.dispatch(setFetch(false));
			store.dispatch(openingHoursFetched(openingHours));
		}
	);
}

export function postOpeningHours(workplaceId, period, data) {
	store.dispatch(setFetch(true));
	return post(workplaceId, period, data)
		.then(response => {
			const openingHours = response.data;
			store.dispatch(openingHoursFetched(openingHours));
			store.dispatch(toggleEditMode(false));
			store.dispatch(setFetch(false));
		})
		.catch(error => {
			const errorMsg = formatErrorMessage(error);
			setFeedback(errorMsg, 0);
			store.dispatch(setFetch(false));
		});
}
