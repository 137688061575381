import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ListComponent from 'common/components/list/list.component';
import DetailsComponent from 'common/components/details/details.component';

import TilePictureAssetsDetails from './services/tilePictureAssetsDetails.service';
import TilePictureAssetSizesList from './services/tilePictureAssetSizesList.service';

const tilePictureAssetsDetailsReduxKey = '/pos.tile_picture_assets-details';
const tilePictureAssetsDetails = new TilePictureAssetsDetails(tilePictureAssetsDetailsReduxKey);
const tilePictureAssetsDetailsProps = tilePictureAssetsDetails.getProps();

const tilePictureAssetSizesListReduxKey = '/pos/tile_picture_asset_sizes';
const tilePictureAssetSizesList = new TilePictureAssetSizesList(tilePictureAssetSizesListReduxKey);
const tilePictureAssetSizesListProps = tilePictureAssetSizesList.getProps();

export function TilePictureAssetsDetailsContainer() {
	const { tilePictureAssetId } = useParams();

	const tilePictureAssetFilter = `:tile_picture_asset.id=='${tilePictureAssetId}'`;
	tilePictureAssetsDetails.setItemId(tilePictureAssetId);

	useEffect(() => {
		// Fetch selected employment position
		tilePictureAssetsDetails.initView();

		tilePictureAssetSizesList.setFilterQuery(tilePictureAssetFilter);
		tilePictureAssetSizesList.setData({
			tile_picture_asset: tilePictureAssetId,
		});
	}, [tilePictureAssetId, tilePictureAssetFilter]);

	return (
		<>
			<DetailsComponent reduxKey={tilePictureAssetsDetailsReduxKey} {...tilePictureAssetsDetailsProps} />

			<ListComponent reduxKey={tilePictureAssetSizesListReduxKey} {...tilePictureAssetSizesListProps} />
		</>
	);
}
