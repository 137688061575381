'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { store, connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import { editEntry as editReactDatawrapperEntry } from 'reactDataWrapper/reactDataWrapper.actions';
import getColumns from 'reactDataWrapperColumns/product/productVariants.columns';

// components
import SubTablesWrapper from './components/subTablesWrapper/subTablesWrapper';
import { Input, InputCollectionSelect, FileUpload, Button, Toggle, Tooltip, Icon } from 'dumb';
import ProductVariantNutritionAllergenInfo from './components/productVariantNutritionAllergenInfo/productVariantNutritionAllergenInfo';

// Tools
import { convertBinaryToBase64 } from 'utils';
import _get from 'lodash/get';
import phrases from './productVariant.phrases';

import { setProductVariant, updateProductVariant, resetProductVariant } from './store/productVariant.actions';

import {
	fetchProductVariants,
	deleteProductVariants,
	editProductVariants,
	addProductVariant,
	editBatchProductVariants,
} from './productVariant.service';

class ProductVariant extends Component {
	constructor(props) {
		super(props);

		this.state = {
			pages: null,
			isSelectOpen: false,
			loadingTrList: [],
			loaderEditFile: false,
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
			showNutritionAllergenModal: false,
		};

		this.reduxKey = 'product/product-variants';

		this.editEntry = this.editEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.editStoreImage = this.editStoreImage.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);
		this.getReduxKey = this.getReduxKey.bind(this);
		this.editUploadImage = this.editUploadImage.bind(this);
		this.getActionButtons = this.getActionButtons.bind(this);
		this.toggleNutritionAllergenModal = this.toggleNutritionAllergenModal.bind(this);
		this.getSelectedProductVariant = this.getSelectedProductVariant.bind(this);

		this.columns = getColumns({
			loadingTrList: this.state.loadingTrList,
			editUploadImage: this.editUploadImage,
			assetCollectionId: this.props.assetCollectionId,
		});
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}>
				Legacy
			</Button>
		);
	}

	addEntry() {
		const { productVariantData, assetCollectionId, product } = this.props;

		const payload = {
			name: _get(productVariantData, 'name', ''),
			product: product ? product.id : _get(productVariantData, 'product.id', ''),
			size: _get(productVariantData, 'size.id', ''),
			fuel: _get(productVariantData, 'fuel', ''),
			image: _get(productVariantData, 'image', ''),
			...((productVariantData.asset_collection || assetCollectionId) && {
				asset_collection: productVariantData?.asset_collection?.id || assetCollectionId,
			}),
			...(_get(productVariantData, 'bundle.id', '') && {
				bundle: productVariantData.bundle.id,
			}),
			points: {
				points: parseInt(_get(productVariantData, 'points.points', 0)),
			},
			available_for_delivery: productVariantData.available_for_delivery ?? true,
		};

		return addProductVariant(payload);
	}

	editEntry(imageData, productVariantId) {
		const { productVariantData, assetCollectionId } = this.props;

		const id = productVariantData.id || productVariantId;

		const payload = {
			id,
			...(productVariantData.fuel && {
				fuel: productVariantData.fuel,
			}),
			...(productVariantData.name && {
				name: productVariantData.name,
			}),
			...(productVariantData?.bundle?.id && {
				bundle: productVariantData.bundle.id,
			}),
			asset_collection: assetCollectionId || productVariantData?.asset_collection?.id,
			...(_get(imageData, 'data', false) && {
				image: imageData,
			}),
			...(_get(productVariantData, 'points.points', false) && {
				points: {
					points: productVariantData.points.points,
				},
			}),
			...(_get(productVariantData, 'size.id') && {
				size: productVariantData.size.id,
			}),
			legacy: !!productVariantData.legacy,
			available_for_delivery: productVariantData.available_for_delivery,
		};

		return editProductVariants(id, payload);
	}

	editMultiple(selectedRows) {
		const { productVariantData } = this.props;

		const list = selectedRows.map((row) => {
			return {
				id: row.id,
				...productVariantData,
				...(productVariantData?.bundle?.id && {
					bundle: productVariantData.bundle.id,
				}),
				...(productVariantData.asset_collection && {
					asset_collection: productVariantData?.asset_collection?.id,
				}),
				...(_get(productVariantData, 'size.id') && {
					size: productVariantData.size.id,
				}),
				legacy: !!productVariantData.legacy,
			};
		});

		const payload = {
			batch: list,
		};

		return editBatchProductVariants(payload);
	}

	deleteEntry(id) {
		return deleteProductVariants(id);
	}

	setInitialEditValues(data) {
		this.props.setProductVariant(data);
	}

	getEditableCells() {
		const { productVariantData, assetCollectionId, product } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="name"
						value={_get(productVariantData, 'name', '')}
						onChange={(event) => this.editStoreEntry(event, 'name')}
					/>
				),
			},
			{
				header: 'Product',
				value: product ? (
					<span>{product.name}</span>
				) : (
					<InputCollectionSelect
						id="product"
						placeholder="select product"
						value={
							_get(productVariantData, 'product.id', false)
								? {
										value: _get(productVariantData, 'product.id', ''),
										label: `${_get(productVariantData, 'product.name', '')}`,
								  }
								: null
						}
						handleChange={(key, value) =>
							this.editStoreEntry(
								{
									id: value ? value.value : '',
									name: value ? value.label : '',
								},
								'product'
							)
						}
						clearable
						cache
						apiPath="/product/products"
						params={{
							limit: 300,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Size',
				value: (
					<InputCollectionSelect
						id="size"
						placeholder="select size"
						value={
							_get(productVariantData, 'size.id', false)
								? {
										value: _get(productVariantData, 'size.id', ''),
										label: `${_get(productVariantData, 'size.name', '')}`,
								  }
								: null
						}
						handleChange={(key, value) =>
							this.editStoreEntry(
								{
									id: value ? value.value : '',
									name: value ? value.label : '',
								},
								'size'
							)
						}
						clearable={false}
						cache
						apiPath="/product/sizes"
						params={{
							limit: 300,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Bundle',
				value: (
					<InputCollectionSelect
						id="bundle"
						placeholder="select bundle"
						value={
							_get(productVariantData, 'bundle.id', false)
								? {
										value: _get(productVariantData, 'bundle.id', ''),
										label: `${_get(productVariantData, 'bundle.name', '')}`,
								  }
								: null
						}
						handleChange={(key, value) =>
							this.editStoreEntry(
								{
									id: value ? value.value : '',
									name: value ? value.label : '',
								},
								'bundle'
							)
						}
						clearable={false}
						cache
						apiPath="/product/bundles"
						params={{
							limit: 300,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Points',
				value: (
					<Input
						id="points"
						placeholder="points"
						value={_get(productVariantData, 'points.points', '')}
						type="number"
						onChange={(event) => this.editStoreEntry(event, 'points')}
					/>
				),
			},
			{
				header: 'Fuel',
				value: (
					<Input
						id="fuel"
						placeholder="fuel"
						value={_get(productVariantData, 'fuel', '')}
						type="number"
						onChange={(event) => this.editStoreEntry(event, 'fuel')}
					/>
				),
			},
			{
				header: 'Image',
				value: (
					<FileUpload
						onChange={(file) => this.editStoreImage('image', file)}
						file={_get(productVariantData, 'image', null)}
						loading={this.state.loaderEditFile}
					/>
				),
			},
			...(assetCollectionId
				? []
				: [
						{
							header: 'Asset Collection',
							value: (
								<InputCollectionSelect
									id="ass-col"
									placeholder="select asset collection"
									value={
										_get(productVariantData, 'asset_collection.id', false)
											? {
													value: _get(productVariantData, 'asset_collection.id', ''),
													label: `${_get(productVariantData, 'asset_collection.name', '')}`,
											  }
											: null
									}
									handleChange={(key, value) =>
										this.editStoreEntry(
											{
												id: value ? value.value : '',
												name: value ? value.label : '',
											},
											'asset_collection'
										)
									}
									clearable
									cache
									apiPath="/pos/asset_collections"
									params={{
										limit: 300,
									}}
									optionFormat={(entry) => ({
										value: entry.id,
										label: entry.name,
									})}
									inputFilterFormat={(input) => `:name=like='%${input}%'`}
								/>
							),
						},
				  ]),
			{
				header: 'Available for delivery',
				value: (
					<Toggle
						id="availableForDelivery"
						toggled={productVariantData.available_for_delivery ?? true}
						onClick={(event) => this.editStoreEntry(event, 'available_for_delivery')}
					/>
				),
			},
		];
	}

	getEditableCellsEdit() {
		const { productVariantData, assetCollectionId, product } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="name"
						value={_get(productVariantData, 'name', '')}
						onChange={(event) => this.editStoreEntry(event, 'name')}
					/>
				),
			},
			{
				header: 'Product',
				value: <span>{product ? product.name : productVariantData.product?.name}</span>,
			},
			{
				header: 'Size',
				value: (
					<InputCollectionSelect
						id="size"
						placeholder="select size"
						value={
							_get(productVariantData, 'size.id', false)
								? {
										value: _get(productVariantData, 'size.id', ''),
										label: `${_get(productVariantData, 'size.name', '')}`,
								  }
								: null
						}
						handleChange={(key, value) =>
							this.editStoreEntry(
								{
									id: value ? value.value : '',
									name: value ? value.label : '',
								},
								'size'
							)
						}
						clearable={false}
						cache
						apiPath="/product/sizes"
						params={{
							limit: 300,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Bundle',
				value: (
					<InputCollectionSelect
						id="bundle"
						placeholder="select bundle"
						value={
							_get(productVariantData, 'bundle.id', false)
								? {
										value: _get(productVariantData, 'bundle.id', ''),
										label: `${_get(productVariantData, 'bundle.name', '')}`,
								  }
								: null
						}
						handleChange={(key, value) =>
							this.editStoreEntry(
								{
									id: value ? value.value : '',
									name: value ? value.label : '',
								},
								'bundle'
							)
						}
						clearable={false}
						cache
						apiPath="/product/bundles"
						params={{
							limit: 300,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Points',
				value: (
					<Input
						id="points"
						placeholder="points"
						value={_get(productVariantData, 'points.points', '')}
						type="number"
						onChange={(event) => this.editStoreEntry(event, 'points')}
					/>
				),
			},
			{
				header: 'Legacy',
				value: (
					<Toggle
						id="legacy"
						toggled={!!productVariantData.legacy}
						onClick={(event) => this.editStoreEntry(event, 'legacy')}
					/>
				),
			},
			{
				header: 'Fuel',
				value: (
					<Input
						id="fuel"
						placeholder="fuel"
						value={_get(productVariantData, 'fuel', '')}
						type="number"
						onChange={(event) => this.editStoreEntry(event, 'fuel')}
					/>
				),
			},
			{
				header: 'Image',
				value: (
					<FileUpload
						onChange={(file) => this.editStoreImage('image', file)}
						file={_get(productVariantData, 'image', null)}
						loading={this.state.loaderEditFile}
					/>
				),
			},
			...(assetCollectionId
				? []
				: [
						{
							header: 'Asset Collection',
							value: (
								<InputCollectionSelect
									id="ass-col"
									placeholder="select asset collection"
									value={
										_get(productVariantData, 'asset_collection.id', false)
											? {
													value: _get(productVariantData, 'asset_collection.id', ''),
													label: `${_get(productVariantData, 'asset_collection.name', '')}`,
											  }
											: null
									}
									handleChange={(key, value) =>
										this.editStoreEntry(
											{
												id: value ? value.value : '',
												name: value ? value.label : '',
											},
											'asset_collection'
										)
									}
									clearable
									cache
									apiPath="/pos/asset_collections"
									params={{
										limit: 300,
									}}
									optionFormat={(entry) => ({
										value: entry.id,
										label: entry.name,
									})}
									inputFilterFormat={(input) => `:name=like='%${input}%'`}
								/>
							),
						},
				  ]),
			{
				header: 'Available for delivery',
				value: (
					<Toggle
						id="availableForDelivery"
						toggled={productVariantData.available_for_delivery}
						onClick={(event) => this.editStoreEntry(event, 'available_for_delivery')}
					/>
				),
			},
		];
	}

	editUploadImage(type, e, data) {
		const id = _get(data, 'original.id', false);
		// const row = _get(data, 'original', {}); // Should always have certain things

		this.setState(() => ({
			loadingTrList: [...this.state.loadingTrList, { row: data.viewIndex, id: data.column.id }],
		}));

		convertBinaryToBase64(e)
			.then((image64) => {
				if (!id) {
					console.info('There is missing ID of row!');
					return;
				}

				// Edit after upload
				this.editEntry(
					{
						filename: e.name,
						data: image64,
					},
					id
				).then((response) => {
					store.dispatch(
						editReactDatawrapperEntry({
							reduxKey: this.reduxKey,
							entry: response.data[0],
						})
					);

					this.setState(() => ({
						loadingTrList: this.state.loadingTrList.filter((x) => x.row !== data.viewIndex && x.id !== data.column.id),
					}));
				});
			})
			.catch(() => {
				this.setState(() => ({
					loadingTrList: this.state.loadingTrList.filter((x) => x.row !== data.viewIndex && x.id !== data.column.id),
				}));
			});
	}

	editStoreImage(type, e) {
		this.setState({ [type]: true });

		convertBinaryToBase64(e)
			.then((image64) => {
				this.setState({ [type]: false });

				this.editStoreEntry(
					{
						filename: e.name,
						data: image64,
					},
					type
				);
			})
			.catch((e) => this.setState({ [type]: false }));
	}

	editStoreEntry(e, type) {
		const { productVariantData } = this.props;

		let value;
		if (type === 'legacy') value = e;
		else value = e.target ? e.target.value : e;

		let payload;
		if (type === 'points') {
			payload = {
				...productVariantData,
				points: {
					id: _get(productVariantData, 'points.id', null),
					points: value,
				},
			};
		} else {
			payload = {
				...productVariantData,
				[type]: value,
			};
		}

		this.props.updateProductVariant(payload);
	}

	fetchData(state) {
		const { assetCollectionId, product } = this.props;

		let filter;
		if (assetCollectionId) {
			filter = `:asset_collection.id=='${assetCollectionId}'`;
		}
		if (product) filter = filter ? `${filter}:product.id=='${product.id}'` : `:product.id=='${product.id}'`;

		return fetchProductVariants(state, filter);
	}

	getReduxKey() {
		const { reduxKey: batchReduxKey, batchSelection, assetCollectionId, product } = this.props;

		// if batchReduxKey passed, use that (legacy overlay)
		if (batchSelection && batchReduxKey) return batchReduxKey;
		// if used under product table
		if (product) return `${this.reduxKey}-${product.id}`;
		// if used under assetCollection table
		if (assetCollectionId) return `${this.reduxKey}-${assetCollectionId}`;

		// else return regular redux key
		return this.reduxKey;
	}

	toggleNutritionAllergenModal() {
		this.setState((prevState) => ({ showNutritionAllergenModal: !prevState.showNutritionAllergenModal }));
	}

	getActionButtons() {
		const productVariant = this.getSelectedProductVariant();

		return productVariant ? (
			<Tooltip
				placement="left"
				text={phrases.NUTRITION_ALLERGEN_TOOLTIP}
				renderData={(ref, onMouseEnter, onMouseLeave) => (
					<Button
						type="inverted"
						label={phrases.NUTRITION_ALLERGEN_LABEL}
						shadow
						onClick={this.toggleNutritionAllergenModal}
						refProp={ref}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}>
						<Icon name="info_outline" />
					</Button>
				)}
			/>
		) : null;
	}

	getSelectedProductVariant() {
		const { listData } = this.props;

		const reduxKey = this.getReduxKey();
		const selectedRows = listData[reduxKey]?.ui?.selectedRows ?? [];

		if (selectedRows.length === 1) {
			return selectedRows[0];
		}

		return null;
	}

	render() {
		const {
			// batchSelection props
			onInitialization,
			style,
			defaultPageSize,
			batchSelection,
			haveSalesConfigurationReadOnlyAccess,
		} = this.props;

		const legacyFilter = this.state.legacyFilterOn ? this.state.legacyFilter : '';

		return (
			<>
				<ReactDataWrapper
					accessAreasAllowedToEdit={['Sales Configuration']}
					title={phrases.PRODUCT_VARIANT}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={batchSelection ? defaultPageSize : 10}
					reduxKey={this.getReduxKey()}
					style={batchSelection ? style : {}}
					{...(batchSelection && { onInitialization })}
					batchSelection={batchSelection}
					manual
					subcomponent={(row) => (
						<SubTablesWrapper row={row} haveSalesConfigurationReadOnlyAccess={haveSalesConfigurationReadOnlyAccess} />
					)}
					editEntry={this.editEntry}
					editMultiple={this.editMultiple}
					editableCells={this.getEditableCells()}
					editableCellsEdit={this.getEditableCellsEdit()}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetProductVariant}
					deleteEntry={this.deleteEntry}
					createEntry={this.addEntry}
					showSearch
					customAreaComponents={this.getLegacyFilterButton()}
					extraFilters={legacyFilter}
					actionRender={this.getActionButtons()}
				/>

				{this.state.showNutritionAllergenModal && (
					<ProductVariantNutritionAllergenInfo
						onClose={this.toggleNutritionAllergenModal}
						productVariantId={this.getSelectedProductVariant()?.id ?? null}
					/>
				)}
			</>
		);
	}
}

ProductVariant.propTypes = {
	productVariantData: PropTypes.object,
	setProductVariant: PropTypes.func,
	updateProductVariant: PropTypes.func,
	resetProductVariant: PropTypes.func,
	onInitialization: PropTypes.func,
	reduxKey: PropTypes.string,
	style: PropTypes.object,
	defaultPageSize: PropTypes.number,
	batchSelection: PropTypes.bool,
	haveSalesConfigurationReadOnlyAccess: PropTypes.bool,
	assetCollectionId: PropTypes.number,
	product: PropTypes.object,
	listData: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setProductVariant,
			updateProductVariant,
			resetProductVariant,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		productVariantData: store.productVariants.data.productVariantData,
		listData: store.listData,
	};
};

export default connectWithStore(ProductVariant, mapStateToProps, mapDispatchToProps);
