'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { formatErrorMessage } from 'api/helpers';
import { FileUpload, Modal } from 'dumb';
import { set as setfeedback } from 'feedback.vanilla.service';

import phrases from './idealModelImportCsv.phrases';

/**
 * @namespace IdealModelImportCsv
 * @memberof components
 */

class IdealModelImportCsv extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isUploading: false,
		};

		this.onClose = this.onClose.bind(this);
		this.onFileSelect = this.onFileSelect.bind(this);
	}

	onFileSelect(file) {
		// get file
		this.setState(() => ({
			isUploading: true,
		}));

		const perspectiveType = 'SOM';
		const type = 'forecasts';
		const periodType = 'monthly';

		this.props.methods
			.handleImportCsv(file, type, periodType, perspectiveType, true)
			.then(
				() => {
					this.setState(() => ({
						isUploading: false,
					}));
					setfeedback('Successfully uploaded', 1);
					this.props.handleClose();
				},
				(err = 'error') => {
					const message = err.code ? formatErrorMessage(err) : err;
					setfeedback(message, 0);
					this.setState(() => ({
						isUploading: false,
					}));
				}
			);
	}

	onClose() {
		this.setState({ fileUploaded: false });
		this.props.handleClose();
	}

	render() {
		const { handleClose, modifierClassName, visible } = this.props;

		return visible ? (
			<Modal
				onClose={handleClose}
				onConfirmClick={handleClose}
				classname={modifierClassName}
				confirmButtonLabel={phrases.MODAL_CONFIRM}
				loading={this.state.isUploading}
				header={phrases.IDEAL_SALARY}
				isOpen={visible}>
				<p>{phrases.PLEASE_UPLOAD}</p>
				<FileUpload text="test" onChange={this.onFileSelect} accept=".csv" />
			</Modal>
		) : null;
	}
}

IdealModelImportCsv.propTypes = {
	methods: PropTypes.object,
	modifierClassName: PropTypes.string,
	handleClose: PropTypes.func,
	visible: PropTypes.bool,
};

export default IdealModelImportCsv;
