import _get from 'lodash/get';

export default function getColumns(global = false) {
	return [
		{
			Header: 'Id',
			id: 'id',
			accessor: (d) => _get(d, 'id', null),
			show: false,
			filterPath: ':id',
		},
		{
			Header: 'Name',
			id: 'name',
			accessor: 'name',
			filterPath: ':name',
		},
		...(global
			? []
			: [
					{
						Header: 'Market',
						id: 'market',
						accessor: 'market.name',
						filterPath: ':market.name',
					},
			  ]),
	];
}
