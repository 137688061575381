'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, InputCollectionSelect } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// constants/phrases/moment
import constants from 'services/constants';
import phrases from './../../../posConfigurationVersions.phrases';
import moment from 'moment';

// lodash
import _get from 'lodash/get';

class PosConfigurationVersionsBatchStepListAdd extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e.target ? e.target.value || e.target.checked : e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: value },
		});
	}

	render() {
		const { batchListData } = this.props;

		const showDates = !!(
			batchListData[0].activeFrom || batchListData[0].activeTo
		);

		return (
			<TableList>
				<thead>
					<tr>
						<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
						<th>{phrases.MODAL_BATCH_STEP_POS_CONFIGURATION}</th>
						<th>{phrases.MODAL_BATCH_STEP_NAME}</th>
						<th>{phrases.MODAL_BATCH_STEP_DESCRIPTION}</th>
						<th>{phrases.MODAL_BATCH_STEP_STATE}</th>
						<th>{phrases.MODAL_BATCH_STEP_APP_TILE_LAYOUT}</th>
						<th>{phrases.MODAL_BATCH_STEP_POS_TILE_LAYOUT}</th>
						<th>{phrases.MODAL_BATCH_STEP_DELIVERY_TILE_LAYOUT}</th>

						{showDates && (
							<>
								<th>{phrases.MODAL_BATCH_ACTIVE_FROM}</th>
								<th>{phrases.MODAL_BATCH_ACTIVE_TO}</th>
							</>
						)}
						<th />
					</tr>
				</thead>
				<tbody>
					{batchListData.map((entry, i) => {
						return (
							<TableListRow key={entry.identifier}>
								<TableListCell>{i + 1}</TableListCell>
								<TableListCell>
									{_get(entry, 'posConfiguration.label', '')}
								</TableListCell>

								<TableListCell>
									<Input
										id={`${entry.identifier}-name}`}
										dataCy="pos-configuration-versions-batch-add-modal-name-textfield"
										placeholder={phrases.MODAL_BATCH_STEP_NAME_PLACEHOLDER}
										value={entry.name || ''}
										onChange={(event) =>
											this._editListEntry(event, entry, 'name')
										}
									/>
								</TableListCell>

								<TableListCell>
									<Input
										id={`${entry.identifier}-description`}
										dataCy="pos-configuration-versions-batch-add-modal-description-textfield"
										placeholder={
											phrases.MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER
										}
										value={entry.description || ''}
										onChange={(event) =>
											this._editListEntry(event, entry, 'description')
										}
									/>
								</TableListCell>

								<TableListCell>{_get(entry, 'state.value', '')}</TableListCell>

								<TableListCell>
									<InputCollectionSelect
										id={`${entry.identifier}-appTileLayout`}
										placeholder={
											phrases.MODAL_BATCH_STEP_APP_TILE_LAYOUT_PLACEHOLDER
										}
										value={entry.appTileLayout}
										handleChange={(key, value) =>
											this._editListEntry(value, entry, 'appTileLayout')
										}
										cache
										apiPath="/pos/tile_layouts"
										params={{
											limit: 30,
											filter: `:type=='App'`,
										}}
										optionFormat={(entry) => ({
											value: entry,
											label: entry.name,
										})}
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
										tableTitle={
											phrases.MODAL_BATCH_STEP_APP_TILE_LAYOUT_TABLE_TITLE
										}
										tableReduxKey={`/pos/tile_layouts/type=='App'-${entry.identifier}`}
										tableColumns={[
											{
												Header: 'Name',
												id: 'name',
												accessor: (d) => _get(d, 'name', ''),
												filterPath: ':name',
											},
											{
												Header: 'Description',
												id: 'description',
												accessor: (d) => _get(d, 'description', ''),
												filterPath: ':description',
											},
											{
												Header: 'Type',
												id: 'type',
												accessor: (d) => _get(d, 'type', null),
												filterPath: ':type',
											},
											{
												Header: 'Rows',
												id: 'rows',
												accessor: (d) => _get(d, 'rows', null),
												filterPath: ':rows',
											},
											{
												Header: 'Columns',
												id: 'columns',
												accessor: (d) => _get(d, 'columns', null),
												filterPath: ':columns',
											},
											{
												Header: 'Sub type',
												id: 'subType',
												accessor: (d) => _get(d, 'sub_type', null),
												filterPath: ':sub_type',
											},
											{
												Header: 'Legacy',
												id: 'legacy',
												accessor: 'legacy',
												filterPath: ':legacy',
												filterable: false,
												Cell: (d) => {
													return (
														<Input
															type="checkbox"
															checked={d.original.legacy}
															disabled
														/>
													);
												},
											},
										]}
									/>
								</TableListCell>

								<TableListCell>
									<InputCollectionSelect
										id={`${entry.identifier}-posTileLayout`}
										placeholder={
											phrases.MODAL_BATCH_STEP_POS_TILE_LAYOUT_PLACEHOLDER
										}
										value={entry.posTileLayout}
										handleChange={(key, value) =>
											this._editListEntry(value, entry, 'posTileLayout')
										}
										cache
										apiPath="/pos/tile_layouts"
										params={{
											limit: 30,
											filter: `:type=='POS'`,
										}}
										optionFormat={(entry) => ({
											value: entry,
											label: entry.name,
										})}
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
										tableTitle={
											phrases.MODAL_BATCH_STEP_POS_TILE_LAYOUT_TABLE_TITLE
										}
										tableReduxKey={`/pos/tile_layouts/type=='POS'-${entry.identifier}`}
										tableColumns={[
											{
												Header: 'Name',
												id: 'name',
												accessor: (d) => _get(d, 'name', ''),
												filterPath: ':name',
											},
											{
												Header: 'Description',
												id: 'description',
												accessor: (d) => _get(d, 'description', ''),
												filterPath: ':description',
											},
											{
												Header: 'Type',
												id: 'type',
												accessor: (d) => _get(d, 'type', null),
												filterPath: ':type',
											},
											{
												Header: 'Rows',
												id: 'rows',
												accessor: (d) => _get(d, 'rows', null),
												filterPath: ':rows',
											},
											{
												Header: 'Columns',
												id: 'columns',
												accessor: (d) => _get(d, 'columns', null),
												filterPath: ':columns',
											},
											{
												Header: 'Sub type',
												id: 'subType',
												accessor: (d) => _get(d, 'sub_type', null),
												filterPath: ':sub_type',
											},
											{
												Header: 'Legacy',
												id: 'legacy',
												accessor: 'legacy',
												filterPath: ':legacy',
												filterable: false,
												Cell: (d) => {
													return (
														<Input
															type="checkbox"
															checked={d.original.legacy}
															disabled
														/>
													);
												},
											},
										]}
									/>
								</TableListCell>

								<TableListCell>
									<InputCollectionSelect
										id={`${entry.identifier}-deliveryTileLayout`}
										placeholder={
											phrases.MODAL_BATCH_STEP_DELIVERY_TILE_LAYOUT_PLACEHOLDER
										}
										value={entry.deliveryTileLayout}
										handleChange={(key, value) =>
											this._editListEntry(value, entry, 'deliveryTileLayout')
										}
										cache
										apiPath="/pos/tile_layouts"
										params={{
											limit: 30,
											filter: `:type=='Delivery'`,
										}}
										optionFormat={(entry) => ({
											value: entry,
											label: entry.name,
										})}
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
										tableTitle={
											phrases.MODAL_BATCH_STEP_DELIVERY_TILE_LAYOUT_TABLE_TITLE
										}
										tableReduxKey={`/pos/tile_layouts/type=='Delivery'-${entry.identifier}`}
										tableColumns={[
											{
												Header: 'Name',
												id: 'name',
												accessor: (d) => _get(d, 'name', ''),
												filterPath: ':name',
											},
											{
												Header: 'Description',
												id: 'description',
												accessor: (d) => _get(d, 'description', ''),
												filterPath: ':description',
											},
											{
												Header: 'Type',
												id: 'type',
												accessor: (d) => _get(d, 'type', null),
												filterPath: ':type',
											},
											{
												Header: 'Rows',
												id: 'rows',
												accessor: (d) => _get(d, 'rows', null),
												filterPath: ':rows',
											},
											{
												Header: 'Columns',
												id: 'columns',
												accessor: (d) => _get(d, 'columns', null),
												filterPath: ':columns',
											},
											{
												Header: 'Sub type',
												id: 'subType',
												accessor: (d) => _get(d, 'sub_type', null),
												filterPath: ':sub_type',
											},
											{
												Header: 'Legacy',
												id: 'legacy',
												accessor: 'legacy',
												filterPath: ':legacy',
												filterable: false,
												Cell: (d) => {
													return (
														<Input
															type="checkbox"
															checked={d.original.legacy}
															disabled
														/>
													);
												},
											},
										]}
									/>
								</TableListCell>

								{showDates && (
									<>
										<TableListCell>
											{entry.activeFrom
												? moment
														.utc(entry.activeFrom)
														.format(constants.shortDate)
												: ''}
										</TableListCell>
										<TableListCell>
											{entry.activeTo
												? moment.utc(entry.activeTo).format(constants.shortDate)
												: ''}
										</TableListCell>
									</>
								)}

								<TableListCell>
									<Button
										id={`${entry.identifier}-delete`}
										type="negative"
										size="tiny"
										onClick={() => this._onRemoveEntry(entry.identifier)}
									>
										<span className="icon icon--delete" />
									</Button>
								</TableListCell>
							</TableListRow>
						);
					})}
				</tbody>
			</TableList>
		);
	}
}

PosConfigurationVersionsBatchStepListAdd.propTypes = {
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

export default PosConfigurationVersionsBatchStepListAdd;
