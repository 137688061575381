'use strict';

import {
	EDIT_STORE_ACCESS_TYPES,
	SET_STORE_ACCESS_TYPES,
	RESET_STATE
} from './personStoreAccess.actions';

const initialState = {
	personStoreAccess: {}
};

export default (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
	case SET_STORE_ACCESS_TYPES:
		return {
			...state,
			personStoreAccess: payload
		};

	case EDIT_STORE_ACCESS_TYPES:
		return {
			...state,
			personStoreAccess: {
				...state.personStoreAccess,
				...payload
			}
		};

	case RESET_STATE:
		return {
			...initialState
		};

	default:
		return state;
	}
};
