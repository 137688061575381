'use strict';

/**
 * @namespace __searchList
 * @memberOf SERVICES
 * @description
 * Service for communicating with search list
 */

// Services
import _isUndefined from 'lodash/isUndefined';
import * as translations from 'translations.service';

export default class SearchListService {

	//////////////////////
	// Public Functions //
	//////////////////////

	GetProps(searchList, searchListFilter = undefined, searchListData = undefined) {
		let list = searchList;

		// Remove any searchListFilters present
		list.setFilters({});
		list.setIsBarFiltering(false);
		list.setIsSearchFiltering(false);
		list.setIsSearchList(true);

		// Handle filter and data if present
		if (!_isUndefined(searchListFilter)) list.setFilterQuery(searchListFilter);
		if (!_isUndefined(searchListData)) list.setData(searchListData);

		// Init view
		list.initView();

		return list.getProps();
	}

	GetTranslations(searchList) {
		return Object.assign({
			title: `${translations.getSingle('COMPONENTS.SEARCHLIST.SEARCH_IN')} ${searchList.getState().title}`
		}, translations.getObject([
			'COMPONENTS.SEARCHLIST.QUICKFILTER',
			'COMPONENTS.LIST.SEARCH'
		]));
	}

}