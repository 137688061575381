export default {
	ADJUST_MANAGED_CLOCKIN: 'Adjust managed clockin',
	MARK_MANAGED_CLOCKIN_AS_NO_SHOW: 'No show',
	TOOLTIP_MARK_CLOCKIN_AS_NO_SHOW:
		"Marking clockin as no show will delete it's related shift.",
	MARK_CLOCKIN_AS_NO_SHOW: 'Are you sure you want to mark clockin as no show?',
	MARK_AS_NO_SHOW: 'Mark as no show',
	CANCEL: 'Cancel',
	ADD_BREAK: 'Add break',
	ADD: 'Add',
	EXTRA_DAY: '+1',
	ADJUST_CLOCKED_DATE: 'Adjust clocked date',
	ADJUST_BREAK_DATE: 'Adjust break date',
	ADJUST_CLOCKED_DATE_TITLE: 'Adjust date',
	ADJUST_CLOCKED_DATE_SUBMIT: 'Adjust',
	CLOCKIN_DATE_ADJUSTED: 'Clockin date adjusted, submit the row to confirm',
	SHIFT_DATE: 'Shift dates',
	ADJUST_BREAKS_TITLE: 'Adjust break dates',
	BREAK_DATE_ADJUSTED: 'Break date adjusted, submit the row to confirm',
};
