'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Icon, Tooltip, InputCollectionSelect, Input } from 'dumb';

import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';

// lodash
import _get from 'lodash/get';

// phrases
import phrases from './../../../filterProductVariantIngredientVariationDefaultReplacements.phrases';

class FilterProductVariantIngiredientVariationDefaultReplacementsBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e.target ? e.target.value : e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: value },
		});
	}

	render() {
		const { batchList, addBatchListItemRow, filterProductVariant } = this.props;

		return (
			<>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT_INGREDIENT}</th>
							<th>{phrases.MODAL_BATCH_STEP_INGREDIENT_VARIATION_OPTION}</th>
							<th>{phrases.MODAL_BATCH_STEP_SORT_ORDER}</th>

							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((entry, i) => {
							const ingredientVariationOptionExists = !!entry.productVariantIngredient;
							const editButtonDisabled = batchList.length === 1;

							return (
								<TableListRow key={entry.identifier}>
									<TableListCell>{i + 1}</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id={`productVariantIngredient-${entry.identifier}`}
											placeholder={
												phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT_INGREDIENT_PLACEHOLDER
											}
											value={entry.productVariantIngredient}
											handleChange={(key, value) =>
												this._editListEntry(
													value,
													entry,
													'productVariantIngredient'
												)
											}
											clearable
											cache
											apiPath="/product/product_variant_ingredients"
											params={{
												limit: 30,
												filter: `:product_variant.id=='${_get(
													filterProductVariant,
													'product_variant.id',
													null
												)}';:ingredient_variation!=null`,
											}}
											optionFormat={(entry) => ({
												value: entry,
												label: _get(entry, 'ingredient.name', ''),
											})}
											inputFilterFormat={(input) =>
												`:ingredient.name=like='%${input}%'`
											}
											tableColumns={[
												{
													Header: 'Ingredient',
													id: 'ingredient',
													accessor: (d) => _get(d, 'ingredient.name', ''),
													filterPath: ':ingredient.name',
												},
												{
													Header: 'Ingredient variation',
													id: 'ingredient_variation',
													accessor: (d) =>
														_get(d, 'ingredient_variation.name', ''),
													filterPath: ':ingredient_variation.name',
												},
												{
													Header: 'Default',
													id: 'default',
													accessor: 'default',
													filterPath: ':default',
												},
												{
													Header: 'Ingredient amount',
													id: 'ingredient_amount',
													accessor: (d) => _get(d, 'ingredient_amount', ''),
													filterPath: ':ingredient_amount',
												},
												{
													Header: 'Excludable',
													id: 'excludable',
													filterPath: ':excludable',
													filterable: false,
													Cell: (d) => {
														return (
															<Input
																id="legacy"
																type="checkbox"
																checked={d.original.excludable}
																disabled
															/>
														);
													},
												},
												{
													Header: 'Available in app',
													id: 'available_in_app',
													filterPath: ':available_in_app',
													filterable: false,
													Cell: (d) => {
														return (
															<Input
																id="legacy-available"
																type="checkbox"
																checked={d.original.available_in_app}
																disabled
															/>
														);
													},
												},
												{
													Header: 'Sort order',
													id: 'sortOrder',
													accessor: 'sort_order',
													filterPath: ':sort_order',
												},
												{
													Header: 'Ingredient variation selection required',
													id: 'ingredientVariationSelectionRequired',
													filterPath:
														':ingredient_variation_selection_required',
													filterable: false,
													Cell: (d) => {
														return (
															<Input
																type="checkbox"
																checked={
																	d.original
																		.ingredient_variation_selection_required
																}
																disabled
															/>
														);
													},
												},
											]}
											tableTitle="Product variant ingredients"
											tableReduxKey="filterProductVariantIngredientVariationDefaultReplacement-/product/product_variant_ingredients"
										/>
									</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id={`ingredientVariationOption-${entry.identifier}`}
											placeholder={
												phrases.MODAL_BATCH_STEP_INGREDIENT_VARIATION_OPTION_PLACEHOLDER
											}
											value={entry.ingredientVariationOption}
											handleChange={(key, value) =>
												this._editListEntry(
													value,
													entry,
													'ingredientVariationOption'
												)
											}
											clearable
											cache
											disabled={!ingredientVariationOptionExists}
											apiPath="/product/ingredient_variation_options"
											params={{
												limit: 30,
												filter: `:ingredient_variation.id=='${_get(
													entry,
													'productVariantIngredient.value.ingredient_variation.id',
													null
												)}'`,
											}}
											optionFormat={(entry) => ({
												value: entry,
												label: _get(entry, 'ingredient.name', ''),
											})}
											inputFilterFormat={(input) =>
												`:ingredient.name=like='%${input}%'`
											}
											tableColumns={[
												{
													Header: 'Ingredient',
													id: 'ingredient.name',
													accessor: (d) => _get(d, 'ingredient.name', null),
													filterPath: ':ingredient.name',
												},
												{
													Header: 'Sort order',
													id: 'sortord',
													accessor: (d) => _get(d, 'sort_order', ''),
													filterPath: ':sort_order',
												},
												{
													Header: 'Ingredient amount',
													id: 'ingredient_amount',
													accessor: (d) => _get(d, 'ingredient_amount', null),
													filterPath: ':ingredient_amount',
												},
											]}
											tableTitle="Ingredient variation options"
											tableReduxKey="filterProductVariantIngredientVariationDefaultReplacement-/product/ingredient_variation_options"
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`sortOrder-${entry.identifier}`}
											placeholder={
												phrases.MODAL_BATCH_STEP_SORT_ORDER_PLACEHOLDER
											}
											type="number"
											onChange={(e) =>
												this._editListEntry(e, entry, 'sortOrder')
											}
											value={entry.sortOrder}
										/>
									</TableListCell>

									<TableListCell>
										<Button
											type="negative"
											size="tiny"
											disabled={editButtonDisabled}
											onClick={() => this._onRemoveEntry(entry.identifier)}>
											<span className="icon icon--delete" />
										</Button>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
				<div className="filter-product-variant-ingredient-variation-default-replacements__add-new-row">
					<Tooltip
						text={phrases.MODAL_BATCH_STEP_LIST_TOOLTIP}
						zIndex="600"
						renderData={(ref, onMouseEnter, onMouseLeave) => (
							<Button
								type="secondary"
								onClick={addBatchListItemRow}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
								refProp={ref}>
								<Icon name="add" />
							</Button>
						)}
					/>
				</div>
			</>
		);
	}
}

FilterProductVariantIngiredientVariationDefaultReplacementsBatchStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	filterProductVariant: PropTypes.object,
};

export default FilterProductVariantIngiredientVariationDefaultReplacementsBatchStepList;
