export const SET_PRODUCT_RECOMMENDATION_PRODUCT_VARIANT =
	'SALES_CONFIGURATION_PRODUCT_RECOMMENDATION_PRODUCT_VARIANTS/SET_PRODUCT_RECOMMENDATION_PRODUCT_VARIANT';
export const UPDATE_PRODUCT_RECOMMENDATION_PRODUCT_VARIANT =
	'SALES_CONFIGURATION_PRODUCT_RECOMMENDATION_PRODUCT_VARIANTS/UPDATE_PRODUCT_RECOMMENDATION_PRODUCT_VARIANT';
export const RESET_STATE =
	'SALES_CONFIGURATION_PRODUCT_RECOMMENDATION_PRODUCT_VARIANTS/RESET_STATE';

/**
 * @param {object} payload
 */
export function setProductRecommendationProductVariants(payload) {
	return {
		type: SET_PRODUCT_RECOMMENDATION_PRODUCT_VARIANT,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateProductRecommendationProductVariant(payload) {
	return {
		type: UPDATE_PRODUCT_RECOMMENDATION_PRODUCT_VARIANT,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET_STATE
	};
}
