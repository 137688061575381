import { ActionType } from 'types/redux';
import {
	IDynamicAccountReduxValue,
	IDynamicAccountReduxValueBatch,
} from '../types';

export const SET_DYNAMIC_ACCOUNT = 'DYNAMIC_ACCOUNTS/SET_DYNAMIC_ACCOUNT';
export const UPDATE_DYNAMIC_ACCOUNT = 'DYNAMIC_ACCOUNTS/UPDATE_DYNAMIC_ACCOUNT';
export const RESET_STATE = 'DYNAMIC_ACCOUNTS/RESET_STATE';

// redux stuff
export const EDIT_BATCH_LIST_ITEM = 'DYNAMIC_ACCOUNTS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM = 'DYNAMIC_ACCOUNTS/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'DYNAMIC_ACCOUNTS/ADD_BATCH_LIST_ITEM_ROW';
export const RERENDER_TABLE = 'DYNAMIC_ACCOUNTS/RERENDER_TABLE';

export function setDynamicAccount(
	payload: IDynamicAccountReduxValue
): ActionType {
	return {
		type: SET_DYNAMIC_ACCOUNT,
		payload,
	};
}

type updateDynamicAccount = Partial<IDynamicAccountReduxValue>;
export function updateDynamicAccount(
	payload: updateDynamicAccount
): ActionType {
	return {
		type: UPDATE_DYNAMIC_ACCOUNT,
		payload,
	};
}

export function resetState(): ActionType {
	return {
		type: RESET_STATE,
	};
}

type editBatchListItem = Partial<IDynamicAccountReduxValueBatch>;
export function editBatchListItem(payload: editBatchListItem): ActionType {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload: string): ActionType {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow(): ActionType {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}

export function rerenderTable(): ActionType {
	return {
		type: RERENDER_TABLE,
	};
}
