'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// rdw actions
import { store } from 'appState';
import {
	resetSelection,
	addRowToSelection,
} from 'reactDataWrapper/reactDataWrapper.actions';

// components
import {
	ModalStepper,
	Overlay,
	SingleDatePickerInput,
	Input,
	Button,
} from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';

// different batch views
import PosConfigurationVersionsBatchStepFormAdd from './components/posConfigurationVersionsBatchStepFormAdd';
import PosConfigurationVersionsBatchStepListAdd from './components/posConfigurationVersionsBatchStepListAdd';

// phrases/ constants/ moment
import phrases from './../../posConfigurationVersions.phrases';
import constants from 'services/constants';
import moment from 'moment';

// loash
import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';
import _uniqBy from 'lodash/uniqBy';

class PosConfigurationVersionsModalBatchAdd extends Component {
	constructor(props) {
		super(props);

		this.posConfigurationUrl =
			'posConfigurationVersionBatch/pos/pos_configurations';

		this.state = {
			showOverlay: false,
			overlayType: null,
			posConfigurationFilter: `:legacy=='false'`,
			activeFilterPosConfigurations: true,
		};

		this.handleToggleOverlay = this.handleToggleOverlay.bind(this);
		this.onOverlayOpen = this.onOverlayOpen.bind(this);
		this._getIsBatchStepFormValid = this._getIsBatchStepFormValid.bind(this);
		this.resetSelectedRows = this.resetSelectedRows.bind(this);
		this._onDateFilterChange = this._onDateFilterChange.bind(this);
		this.handleToggleActiveFilter = this.handleToggleActiveFilter.bind(this);

		this.modalRef = React.createRef();

		this.posConfigurationColumns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', null),
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: (d) => _get(d, 'description', null),
				filterPath: ':description',
			},
			{
				Header: 'Market',
				id: 'market',
				accessor: (d) => _get(d, 'market.name', null),
				filterPath: ':market.name',
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: (d) => _get(d, 'legacy', null),
				filterPath: ':legacy',
				filterable: false,
				Cell: (d) => {
					return (
						<Input
							type="checkbox"
							checked={_get(d, 'original.legacy', false)}
							disabled
						/>
					);
				},
			},
		];
	}

	handleToggleOverlay(type) {
		const { listPosConfigurations, updateBatchForm } = this.props;

		if (this.state.overlayType) {
			const selectedRowsInList = _get(
				listPosConfigurations,
				'ui.selectedRows',
				[]
			);

			updateBatchForm({
				posConfiguration: selectedRowsInList.map((entry) => {
					return { label: entry.name, value: entry };
				}),
			});
		}

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
			overlayType: prevState.overlayType ? '' : type,
		}));
	}

	onOverlayOpen() {
		const { batchFormData } = this.props;
		const { overlayType } = this.state;

		if (_isEmpty(batchFormData.posConfiguration)) return;

		this.resetSelectedRows(overlayType);

		batchFormData.posConfiguration.map((entry) => {
			store.dispatch(
				addRowToSelection({
					reduxKey: overlayType,
					data: entry.value,
				})
			);
		});
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_onSubmitForm() {
		const { batchFormData, addBatchList } = this.props;

		// clear duplicates
		const cleanPosConfigurations = _uniqBy(
			batchFormData.posConfiguration,
			'value.id'
		);

		const listArray = cleanPosConfigurations.map((entry) => {
			return {
				identifier: _uniqueId('row-'),
				posConfiguration: entry,
				state: batchFormData.state,
				activeFrom:
					batchFormData.activeFrom || moment.utc().format(constants.shortDate),
				activeTo: batchFormData.activeTo || '9999-12-31',
			};
		});

		addBatchList(listArray);

		this._goToStep(2);
	}

	_batchSubmit() {
		const { batchListData, handleClose, batchAddData } = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchListData.map((entry) => {
			// strip "\t" from the name and description - it occurs whenever we copy the text from column and paste it in the text field
			const nameStripped = entry.name.replace(/\t/g, '');
			const descriptionStripped = entry.description.replace(/\t/g, '');

			return {
				pos_configuration: _get(entry, 'posConfiguration.value.id', null),
				name: nameStripped,
				description: descriptionStripped,
				state: _get(entry, 'state.label', null),
				...(entry.appTileLayout && {
					app_tile_layout: entry.appTileLayout.value.id,
				}),
				...(entry.posTileLayout && {
					pos_tile_layout: entry.posTileLayout.value.id,
				}),
				...(entry.deliveryTileLayout && {
					delivery_tile_layout: entry.deliveryTileLayout.value.id,
				}),
				...((entry.activeFrom || entry.activeTo) && {
					active: {
						...(entry.activeFrom && {
							from: moment.utc(entry.activeFrom).format(constants.shortDate),
						}),
						...(entry.activeTo && {
							to: moment.utc(entry.activeTo).format(constants.shortDate),
						}),
					},
				}),
			};
		});

		batchAddData({ batch: payload })
			.then(() => {
				handleClose();

				this.resetSelectedRows(this.posConfigurationUrl);

				this.setState(() => ({
					loadingModal: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	_getIsBatchStepFormValid() {
		const { batchFormData } = this.props;

		return !_isEmpty(batchFormData.posConfiguration);
	}

	resetSelectedRows(reduxKey) {
		if (this.props.listPosConfigurations)
			store.dispatch(resetSelection({ reduxKey }));
	}

	_onDateFilterChange(date) {
		const formattedDate = date
			? moment.utc(date).format(constants.shortDate)
			: null;

		const filterString = formattedDate
			? `:pos_configuration_version.active.from=le='${formattedDate}';:pos_configuration_version.active.to=ge='${formattedDate}'`
			: '';

		this.setState(() => ({ posConfigurationVersionDateFilter: filterString }));
	}

	getAdditionalPosConfigurationDiscountTableFilters() {
		const { posConfigurationVersionDateFilter } = this.state;

		return (
			<SingleDatePickerInput
				id="posConfigDiscountDatePicker"
				label="Select active date"
				type="single"
				appendToBody
				fixed
				onChange={this._onDateFilterChange}
				selectedDate={posConfigurationVersionDateFilter}
				noClockButton
				clearButtonDisabled={!posConfigurationVersionDateFilter}
			/>
		);
	}

	handleToggleActiveFilter() {
		this.setState((prevState) => ({
			activeFilterPosConfigurations: !prevState.activeFilterPosConfigurations,
		}));
	}

	getAdditionalPosConfigurationTableFilters() {
		const { activeFilterPosConfigurations } = this.state;

		return (
			<Button
				size="tiny"
				type={activeFilterPosConfigurations ? '' : 'inverted'}
				shadow
				onClick={this.handleToggleActiveFilter}
			>
				{phrases.MODAL_BATCH_STEP_FORM_TABLE_FILTER}
			</Button>
		);
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchListData,
			editBatchListItem,
			removeBatchListItem,
			fetchPosConfigurations,
		} = this.props;
		const { showOverlay } = this.state;

		const steps = [
			{
				component: (
					<PosConfigurationVersionsBatchStepFormAdd
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.handleToggleOverlay}
						posConfigurationUrl={this.posConfigurationUrl}
						resetSelection={this.resetSelectedRows}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE,
				isValid: this._getIsBatchStepFormValid(),
				onNext: () => this._onSubmitForm(),
				loading: false,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				noScroll: false,
				allowOverflow: true,
			},
			{
				component: (
					<PosConfigurationVersionsBatchStepListAdd
						batchListData={batchListData}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
						loyaltyProductUrl={this.loyaltyProductUrl}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						className="pos-modal-batch"
						ref={this.modalRef}
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={showOverlay ? () => {} : handleClose}
						dataCy="pos-configuration-versions-batch-modal"
					/>
				)}

				<Overlay
					zIndex={551}
					height={850}
					list
					visible={showOverlay}
					onClose={() => {
						this.handleToggleOverlay();
					}}
				>
					<ReactDataWrapper
						accessAreasAllowedToEdit={['Sales Configuration']}
						title={phrases.MODAL_BATCH_TABLE_TITLE}
						columns={this.posConfigurationColumns}
						fetchData={fetchPosConfigurations}
						filterable
						disableFetchCsvButton
						enableMultiSelection
						batchSelection
						defaultPageSize={20}
						onInitialization={this.onOverlayOpen}
						reduxKey={this.posConfigurationUrl}
						manual
						actionsWidth={0}
						style={{
							maxHeight: '720px',
						}}
						customAreaComponents={this.getAdditionalPosConfigurationTableFilters()}
						extraFilters={
							this.state.activeFilterPosConfigurations
								? this.state.posConfigurationFilter
								: ''
						}
					/>
				</Overlay>
			</>
		);
	}
}

PosConfigurationVersionsModalBatchAdd.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	listPosConfigurations: PropTypes.object,
	addBatchList: PropTypes.func,
	fetchPosConfigurations: PropTypes.func,
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	batchAddData: PropTypes.func,
};

export default PosConfigurationVersionsModalBatchAdd;
