export default {
	TABLE_TITLE: 'Global raw ingredients',
	GLOBAL_RAW_INGREDIENT_ADDED: 'Global raw ingredient added',
	GLOBAL_RAW_INGREDIENT_EDITED: 'Global raw ingredient edited',
	GLOBAL_RAW_INGREDIENTS_EDITED: 'Global raw ingredients edited',
	GLOBAL_RAW_INGREDIENTS_DELETED: 'Global raw ingredient deleted',

	// modal batch stuff
	MODAL_BATCH_STEP_LIST_TITLE: 'Add Raw ingredient categories',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'submit',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_LEGACY: 'Legacy',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row.',
	MODAL_BATCH_STEP_LIST_TIP: 'Raw ingredient category:'
};
