import {
	SET_ENTRY_UPDATE,
	EDIT_ENTRY_UPDATE,
	RESET_ENTRY_UPDATE,
	EDIT_DATE_UPDATE,
	EDIT_ENTRY_REMOVE,

	UPDATE_BATCH,
	BATCH_LIST_ADD,
	BATCH_LIST_EDIT_ITEM,
	BATCH_LIST_REMOVE_ITEM,
	RESET_BATCH,
} from './editModalChoice.actions';

const defaultState = {
	ui: {},
	data: {
		rateEditRows: [], // Rate page list (rows) edited form data
		rateEffectiveFrom: '',
		batchOptions: {
			salary_group: [],
			amount: null,
			active: {}
		},
		batchList: []
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_ENTRY_UPDATE: {
			return {
				...state,
				data: {
					...state.data,
					rateEditRows: payload
				}
			};
		}

		case EDIT_ENTRY_UPDATE: {
			return {
				...state,
				data: {
					...state.data,
					rateEditRows: state.data.rateEditRows.map(x => {
						if (x.identifier === payload.identifier) return payload;
						return x;
					})
				}
			};
		}

		case EDIT_ENTRY_REMOVE: {
			const rateEditRows = state.data.rateEditRows.filter(
				x => x.id !== payload
			);
	
			return {
				...state,
				data: {
					...state.data,
					rateEditRows
				}
			};
		}

		case EDIT_DATE_UPDATE: {
			return {
				...state,
				data: {
					...state.data,
					rateEffectiveFrom: payload
				}
			};
		}

		case RESET_ENTRY_UPDATE: {
			return {
				...state,
				data: {
					...state.data,
					rateEditRows: []
				}
			};
		}

		// Batch add
		case UPDATE_BATCH: {
			return {
				...state,
				data: {
					...state.data,
					batchOptions: {
						...state.data.batchOptions,
						...payload
					}
				}
			};
		}
	
		case BATCH_LIST_ADD: {
			return {
				...state,
				data: {
					...state.data,
					batchList: payload
				}
			};
		}
	
		case RESET_BATCH: {
			return {
				...state,
				data: defaultState.data
			};
		}
	
		case BATCH_LIST_EDIT_ITEM: {
			const batchList = state.data.batchList.map(x => {
				if (x.identifier === payload.identifier) {
					return payload;
				} else {
					return x;
				}
			});
	
			return {
				...state,
				data: {
					...state.data,
					batchList: batchList
				}
			};
		}
	
		case BATCH_LIST_REMOVE_ITEM: {
			const batchList = state.data.batchList.filter(
				x => x.identifier !== payload
			);
	
			return {
				...state,
				data: {
					...state.data,
					batchList: batchList
				}
			};
		}

		default:
			return state;
	}
}

export default reducer;