import constants from 'services/constants';
import phrases from './../shiftTransferApproval.phrases';
import moment from 'moment';
import { ShiftTransfer as enums } from 'services/enums';
import { getHumanReadableState } from 'services/shiftTransfer';

// lodash
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';

export function formatShiftTime(time) {
	return moment.utc(time, constants.dateFormat).format(constants.timeShort);
}

export function getShiftDate(shift) {
	return moment.utc(shift, constants.dateFormat).format(constants.shortDate);
}

export function getShiftDay(shift) {
	return moment
		.utc(shift, constants.dateFormat)
		.format(constants.nameOfWeekday);
}

/**
 * @func formatShiftTransfers
 * @param {Object} Object.data
 * @description sorts all transfers applicants under shift transfers
 * @returns {Array}
 */
export function formatShiftTransfers({ data }) {
	return data.reduce((acc, currentValue, index) => {
		const transferExists = acc.some(
			(entry) => entry.id === currentValue.transfer?.id
		);

		let accToReturn;

		if (transferExists) {
			accToReturn = acc.map((entry) => {
				if (entry.id === currentValue.transfer?.id) {
					return {
						...entry,
						applications: [
							...entry.applications,
							_omit(currentValue, 'transfer'),
						],
					};
				}

				return entry;
			});
		} else {
			accToReturn = [
				...acc,
				{
					...currentValue.transfer,
					applications: [_omit(currentValue, 'transfer')],
				},
			];
		}

		return accToReturn;
	}, []);
}

export function getMarketIdFromShiftTransfers({
	shiftTransfers,
	swapTransfers,
}) {
	if (!_isEmpty(shiftTransfers)) {
		return shiftTransfers[0]?.shift?.workplace?.market?.id;
	}
	if (!_isEmpty(swapTransfers)) {
		return swapTransfers[0]?.shift?.workplace?.market?.id;
	}
}

/**
 * @function getSwapApplicationPayload
 * @param {*} param0
 * @description Similar to getSwapApplicationToSubmit in the mobile view, this function creates the payload to
 * submit to the api. Difference is that we don't have an array of pre made decisions but we alrady know what
 * to submit to the api since the user clicked the button.
 */
export function getSwapApplicationPayload({
	transferDataToSubmit,
	shiftTransferPolicy,
	user,
}) {
	const { shiftTransfer, operation, applied } = transferDataToSubmit;

	// if we're already applied, reuse policy uuid when making changes
	// and get the correct application id
	let applicationId, policyUuid;
	if (applied) {
		const personId = user?.user?.person?.id;

		// find the application to update
		const application = shiftTransfer.applications.find(
			(entry) => entry.applicant?.person?.id === personId
		);

		applicationId = application.id;
		policyUuid = application.applicant_accepted_policy?.uuid;
	} else {
		// if we're accepting a shfit swap, we're the only applicant
		applicationId = shiftTransfer.applications?.[0]?.id;
		policyUuid = shiftTransferPolicy.uuid;
	}

	return {
		shiftId: shiftTransfer.shift?.id,
		transferId: shiftTransfer.id,
		operation,
		applicationId,
		policy_uuid: policyUuid,
	};
}

export function sortShiftTransfers({
	shiftTransfers,
	swapTransfers,
	pendingTransfers,
	userId,
}) {
	const sortedTransfers = sortTransfersChronologically(shiftTransfers);
	const sortedSwaps = sortTransfersChronologically(swapTransfers);
	let sortedPendingTransfers = sortTransfersChronologically(pendingTransfers);
	sortedPendingTransfers = _sortPendingByAppliedOwn({
		pendingTransfers: sortedPendingTransfers,
		userId,
	});

	return {
		sortedSwaps,
		sortedTransfers,
		sortedPendingTransfers,
	};
}

export function getUpdateShiftTransferPayload({
	shiftTransfer,
	operation,
	application,
}) {
	// if as a manager we decline a shift transfer of type swap (not public)
	// we should omit the application, and set the operation to Decline instead of Decline Application
	// that will decline the whole transfer
	if (
		shiftTransfer.type === enums.TRANSFER_TYPE.SWAP &&
		operation === enums.OPERATIONS.DECLINE_APPLICATION
	) {
		operation = enums.OPERATIONS.DECLINE;
	}

	const approvingApplication =
		operation === enums.OPERATIONS.APPROVE_APPLICATION;

	// get the application
	const applicationObject = shiftTransfer.applications.find(
		(entry) => entry.id === application
	);
	// get his employment id (applicant id is employmentId)
	const employmentId = applicationObject?.applicant?.id ?? null;

	const potentialWarningsPayload = {
		employmentId,
		from: shiftTransfer.shift?.planned_period?.from,
		to: shiftTransfer.shift?.planned_period?.to,
		workplaceId: shiftTransfer.shift?.workplace?.id,
	};

	const updateShiftTransferPayload = {
		shiftId: shiftTransfer.shift?.id,
		transferId: shiftTransfer.id,
		// if we're not declining an transfer, pass the application
		...(operation !== enums.OPERATIONS.DECLINE && {
			application,
		}),
		operation,
	};

	return {
		updateShiftTransferPayload,
		potentialWarningsPayload,
		approvingApplication,
	};
}

// ! not in use for now
export function getOvertimeAcknowledgementsPayload({ warningTypes, shift }) {
	const formattedPayload = warningTypes.map((entry) => ({
		shift_employee: shift?.shift_employees?.[0]?.id ?? null,
		type: entry,
	}));

	const payload = {
		batch: formattedPayload,
	};

	return payload;
}

export function getCanDeclineTransferOwn({ entry }) {
	let canDeclineTransfer = false;
	const transferTypePublic = entry.type === enums.TRANSFER_TYPE.PUBLIC;

	// if public, as long as no one applied we can cancel the tranfer
	if (transferTypePublic) {
		const emptyApplicants = _isEmpty(entry.applications);
		const stateNotRetractedDeclined =
			entry.state !== enums.TRANSFER.DECLINED &&
			entry.state !== enums.TRANSFER.RETRACTED;

		canDeclineTransfer = emptyApplicants && stateNotRetractedDeclined;
	}
	// if type swap and receiver didn't yet accept, we can cancel it
	if (
		!transferTypePublic &&
		entry.applications?.[0]?.state ===
			enums.APPLICATIONS.AWAITING_APPLICANT_APPROVAL
	)
		canDeclineTransfer = true;

	return canDeclineTransfer;
}

export function getAppliedPendingShiftTransferMetadata({ entry, personId }) {
	// find your application state and show it as transfer state
	const application = entry.applications.find(
		(entry) => entry.applicant?.person?.id === personId
	);
	const applicationState = application?.state ?? '';

	let canCancelTransfer = false;
	let canReapplyForShift = false;

	const transferTypePublic = entry.type === enums.TRANSFER_TYPE.PUBLIC;

	// both public and swap
	if (
		// transferTypePublic &&
		applicationState === enums.APPLICATIONS.AWAITING_MANAGER_APPROVAL
	) {
		canCancelTransfer = true;
	}
	// type public - we can reaply even if we declined it
	if (transferTypePublic && applicationState === enums.APPLICATIONS.DECLINED) {
		canReapplyForShift = true;
	}

	return { canCancelTransfer, canReapplyForShift, applicationState };
}

/**
 * @function getOwnPendingTransferState
 * @param {Object} shift transfer - shift transfer object
 * @description returns a more human readable state for each shift transfer
 * @returns {String}
 */
export function getOwnPendingTransferState(entry) {
	const transferTypePublic = entry.type === enums.TRANSFER_TYPE.PUBLIC;
	const transferTypeSwap = entry.type === enums.TRANSFER_TYPE.SWAP;
	const hasApplicants = !_isEmpty(entry.applications);
	const applicantState = entry.applications?.[0]?.state ?? null;

	if (transferTypePublic && !hasApplicants) return phrases.NO_APPLICANTS;
	if (transferTypePublic && hasApplicants)
		return phrases.AWAITING_MANAGER_APPROVAL;
	if (transferTypeSwap) return getHumanReadableState(applicantState);

	return applicantState;
}

export function sortTransfersChronologically(transfers) {
	return transfers.sort((a, b) => {
		const dateA = moment.utc(a.shift?.planned_period?.from);
		const dateB = moment.utc(b.shift?.planned_period?.from);

		if (dateA.isBefore(dateB, 'minute')) return -1;
		if (dateA.isAfter(dateB, 'minute')) return 1;

		return 0;
	});
}

// PRIVATE

// sorts so that first entries are own transfers and then applied ones
function _sortPendingByAppliedOwn({ pendingTransfers, userId }) {
	return pendingTransfers.sort((a) => {
		const ownTransfer = a.donor?.person?.id === userId;

		if (ownTransfer) return -1;
		if (!ownTransfer) return 1;

		return 0;
	});
}
