'use strict';

import cx from 'classnames';
import _each from 'lodash/each';
import _filter from 'lodash/filter';
import _has from 'lodash/has';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';

import _some from 'lodash/some';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class HeaderRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sortOrder: {}
		};

		this.onColumnHeaderClick = this.onColumnHeaderClick.bind(this);
	}

	_setSortOrderState(name) {
		let sort = 'asc';
		if (
			_has(this.state.sortOrder, name) &&
			!_isUndefined(this.state.sortOrder[name])
		) {
			sort = this.state.sortOrder[name] === 'asc' ? 'desc' : undefined;
		}
		const sortObj = { [name]: sort };
		this.setState({ sortOrder: sortObj });
		return sort;
	}

	onColumnHeaderClick(column) {
		if (column.canSort)
			this.props.methods.handleSortByColumn(
				column,
				this._setSortOrderState(column.name)
			);
	}

	getColGroups() {
		const colGroups = [];
		const headers = _filter(this.props.headers, header => {
			return _some([
				!header.localHidden,
				_has(header, 'filterBar'),
				_has(header, 'alwaysHidden')
			]);
		});
		_each(headers, function(header) {
			// Do we have colGroup in header?
			if (_has(header, 'colGroup')) {
				// Is colGroup existing in array already? - then add to counter
				const colGroupIndex = _findIndex(colGroups, function(cG) {
					return cG.name === header.colGroup;
				});
				if (header.emptyColGroup) colGroups.push({ empty: true });
				else if (colGroupIndex > -1)
					colGroups[colGroupIndex].length = colGroups[colGroupIndex].length + 1;
				// Or not - then create
				else {
					colGroups.push({
						name: header.colGroup,
						label: header.colGroupLabel,
						length: 1
					});
				}
			}
		});
		return colGroups;
	}

	renderColGroups() {
		var colGroupBrutto = this.getColGroups();
		var colGroupNetto = this.getColGroups();
		_remove(colGroupNetto, 'empty');
		if (colGroupNetto.length > 0)
			return (
				<tr className="list__row--colgroup">
					{_map(colGroupBrutto, (group, key) => {
						if (group.empty)
							return <th key={key} className="list__header-cell" />;
						else
							return (
								<th
									key={key}
									colSpan={group.length}
									className="list__header-cell list__header-cell--colgroup">
									<span className="list__cell-contents">{group.label}</span>
								</th>
							);
					})}
				</tr>
			);
	}

	renderHeaders() {
		const { methods, sort, collection, settings } = this.props;

		return _map(methods.handleReturnVisibleHeaders(), (cell, key) => {
			var headerCx = cx(
				'list__header-cell',
				'list__header-cell--' + cell.type,
				{
					'list__header-cell--can-sort': cell.canSort && collection.length > 1
				}
			);
			var cellCx = cx('list__cell-contents', {
				' icon icon--expand_less list__cell-contents--sort-asc':
					sort && sort.column === cell && sort.order === 'asc',
				'icon icon--expand_more list__cell-contents--sort-desc':
					sort && sort.column === cell && sort.order === 'desc'
			});
			var headerText = settings.collapseHeader
				? cell.label
				: cell.label.replace(/ /g, '\xA0');
			return (
				<th
					key={key}
					className={headerCx}
					onClick={this.onColumnHeaderClick.bind(this, cell)}>
					<span className={cellCx}>{headerText}</span>
				</th>
			);
		});
	}

	render() {
		return (
			<thead>
				{this.renderColGroups()}
				<tr>{this.renderHeaders()}</tr>
			</thead>
		);
	}
}

HeaderRow.proptypes = {
	collection: PropTypes.array.isRequired,
	headers: PropTypes.array.isRequired,
	methods: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
	sort: PropTypes.object
};

export default HeaderRow;
