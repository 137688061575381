import {
	SET_MANAGED_SHIFT_CLOCKIN,
	UPDATE_MANAGED_SHIFT_CLOCKIN,
	RESET_MANAGED_SHIFT_CLOCKINS,
	SET_MODAL_TYPE,
	TOGGLE_MODAL,
	SET_STATUS_FILTER,
	SET_ROW_INTERACTED,
	REMOVE_ROW_INTERACTED,
	ADD_BREAK_MODAL_UPDATE_BREAK,
	ADD_BREAK_MODAL_RESET_BREAK,
} from './shiftClockins.actions';

import { getStatusFilterOptions } from './../utils';

const defaultState = {
	data: {
		managedShiftClockin: null,
		break: {
			from: null,
			to: null,
			comment: null,
		},
	},
	modalType: null,
	modalVisible: false,
	filters: {
		status: getStatusFilterOptions()[1],
	},
	interactedRows: [],
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_MANAGED_SHIFT_CLOCKIN:
			return {
				...state,
				data: {
					...state.data,
					managedShiftClockin: payload,
				},
			};

		case UPDATE_MANAGED_SHIFT_CLOCKIN:
			return {
				...state,
				data: {
					...state.data,
					managedShiftClockin: {
						...state.data.managedShiftClockin,
						...payload,
					},
				},
			};

		case RESET_MANAGED_SHIFT_CLOCKINS:
			return {
				...state,
				data: defaultState.data,
			};

		case SET_MODAL_TYPE:
			return {
				...state,
				modalType: payload,
			};

		case TOGGLE_MODAL:
			return {
				...state,
				modalVisible: !state.modalVisible,
			};

		case SET_STATUS_FILTER:
			return {
				...state,
				filters: {
					...state.filters,
					status: payload,
				},
			};

		case SET_ROW_INTERACTED: {
			const rowAlreadyInteracted = state.interactedRows.find(
				(entry) => entry === payload
			);

			return {
				...state,
				...(!rowAlreadyInteracted && {
					interactedRows: [...state.interactedRows, payload],
				}),
			};
		}

		case REMOVE_ROW_INTERACTED:
			return {
				...state,
				interactedRows: state.interactedRows.filter(
					(entry) => entry !== payload
				),
			};

		case ADD_BREAK_MODAL_UPDATE_BREAK:
			return {
				...state,
				data: {
					...state.data,
					break: {
						...state.data.break,
						...payload,
					},
				},
			};

		case ADD_BREAK_MODAL_RESET_BREAK:
			return {
				...state,
				data: {
					...state.data,
					break: defaultState.data.break,
				},
			};

		default:
			return state;
	}
}

export default reducer;
