'use strict';

export const TOGGLE_ADD_SHIFT_MODAL = 'ADD_SHIFT_MODAL/TOGGLE_ADD_SHIFT_MODAL';
export const SET_ADD_SHIFT_MODAL_DATA =
	'ADD_SHIFT_MODAL/SET_ADD_SHIFT_MODAL_DATA';
export const UPDATE_ACTIVE_SHIFT = 'ADD_SHIFT_MODAL/UPDATE_ACTIVE_SHIFT';
export const ADD_CLOCKING_TO_ACTIVE_SHIFT =
	'ADD_SHIFT_MODAL/ADD_CLOCKING_TO_ACTIVE_SHIFT';
export const ACTIVE_SHIFT_TOUCHED = 'ADD_SHIFT_MODAL/ACTIVE_SHIFT_TOUCHED';
export const REMOVE_MODULE_EXECUTION_FROM_ACTIVE_SHIFT =
	'ADD_SHIFT_MODAL/REMOVE_MODULE_EXECUTION_FROM_ACTIVE_SHIFT';
export const REMOVE_EMPLOYEE_FROM_ACTIVE_SHIFT =
	'ADD_SHIFT_MODAL/REMOVE_EMPLOYEE_FROM_ACTIVE_SHIFT';
export const REMOVE_TAG_FROM_ACTIVE_SHIFT =
	'SHIFTS/REMOVE_TAG_FROM_ACTIVE_SHIFT';
export const ADD_TRAINING_MODULE_TO_ACTIVE_SHIFT =
	'SHIFTS/ADD_TRAINING_MODULE_TO_ACTIVE_SHIFT';
export const UPDATE_TRAINING_MODULE_IN_ACTIVE_SHIFT =
	'SHIFTS/UPDATE_TRAINING_MODULE_IN_ACTIVE_SHIFT';
export const SET_MODULE_EXECUTION_DATA = 'SHIFTS/SET_MODULE_EXECUTION_DATA';
export const SET_TAG_DATA = 'SHIFTS/SET_TAG_DATA';
export const SET_SELECTED_TAG_TYPE = 'SHIFTS/SET_SELECTED_TAG_TYPE';
export const RESET_SELECTED_TAG_TYPE = 'SHIFTS/RESET_SELECTED_TAG_TYPE';
export const RESET_TAG_DATA = 'SHIFTS/RESET_TAG_DATA';
export const RESET_MODULE_EXECUTION_DATA = 'SHIFTS/RESET_MODULE_EXECUTION_DATA';
export const RESET_SHIFT_MODAL_TAGS = 'SHIFTS/RESET_SHIFT_MODAL_TAGS';
export const SET_MARKET_SHIFT_RULES = 'SHIFTS/SET_MARKET_SHIFT_RULES';
export const CLEAR_MARKET_SHIFT_RULES = 'SHIFTS/CLEAR_MARKET_SHIFT_RULES';
export const SET_SHIFT_BREAK_DATA = 'SHIFTS/SET_SHIFT_BREAK_DATA';
export const RESET_SHIFT_BREAK_DATA = 'SHIFTS/RESET_SHIFT_BREAK_DATA';
export const UPDATE_SHIFT_BREAK_DATA = 'SHIFTS/UPDATE_SHIFT_BREAK_DATA';
export const REMOVE_SHIFT_BREAK_FROM_ACTIVE_SHIFT =
	'SHIFTS/REMOVE_SHIFT_BREAK_FROM_ACTIVE_SHIFT';
export const SET_TAG_DATA_TO_EDIT = 'SHIFTS/SET_TAG_DATA_TO_EDIT';
export const RESET_TAG_DATA_TO_EDIT = 'SHIFTS/RESET_TAG_DATA_TO_EDIT';

export function setTagDataToEdit(payload) {
	return {
		type: SET_TAG_DATA_TO_EDIT,
		payload,
	};
}

export function resetTagDataToEdit() {
	return {
		type: RESET_TAG_DATA_TO_EDIT,
	};
}

export function removeShiftBreakFromActiveShift(payload) {
	return {
		type: REMOVE_SHIFT_BREAK_FROM_ACTIVE_SHIFT,
		payload,
	};
}

export function setShiftBreakData(payload) {
	return {
		type: SET_SHIFT_BREAK_DATA,
		payload,
	};
}

export function updateShiftBreakData(payload) {
	return {
		type: UPDATE_SHIFT_BREAK_DATA,
		payload,
	};
}

export function resetShiftBreakData() {
	return {
		type: RESET_SHIFT_BREAK_DATA,
	};
}

export function clearMarketShiftRules() {
	return {
		type: CLEAR_MARKET_SHIFT_RULES,
	};
}

export function setMarketShiftRules(payload) {
	return {
		type: SET_MARKET_SHIFT_RULES,
		payload,
	};
}

export function resetShiftModalTags(payload) {
	return {
		type: RESET_SHIFT_MODAL_TAGS,
		payload,
	};
}

export function resetSelectedTagType() {
	return {
		type: RESET_SELECTED_TAG_TYPE,
	};
}

export function setSelectedTagType(payload) {
	return {
		type: SET_SELECTED_TAG_TYPE,
		payload,
	};
}

export function setTagData(payload) {
	return {
		type: SET_TAG_DATA,
		payload,
	};
}

export function resetTagData() {
	return {
		type: RESET_TAG_DATA,
	};
}

export function setModuleExecutionData(payload) {
	return {
		type: SET_MODULE_EXECUTION_DATA,
		payload,
	};
}

export function resetModuleExecutionData() {
	return {
		type: RESET_MODULE_EXECUTION_DATA,
	};
}

/**
 * @param {string} showAddShiftModal
 */
export function toggleAddShiftModal(showAddShiftModal) {
	return {
		type: TOGGLE_ADD_SHIFT_MODAL,
		payload: {
			showAddShiftModal,
		},
	};
}

/**
 * @function setAddShiftModalData
 * @param activeShift
 * @returns {{type: string, payload: {activeShift: {}}}}
 */
export function setAddShiftModalData(activeShift = {}) {
	return {
		type: SET_ADD_SHIFT_MODAL_DATA,
		payload: {
			activeShift,
		},
	};
}

/**
 * @function updateActiveShift
 * @param activeShift
 * @returns {{type: string, payload: {activeShift: {}}}}
 */
export function updateActiveShift(updatedShift = {}) {
	return {
		type: UPDATE_ACTIVE_SHIFT,
		payload: {
			updatedShift,
		},
	};
}
/**
 * @function activeShiftTouched
 * @param activeShift
 * @param touched Bool
 * @returns {{type: string, payload: {touched: bool}}}
 */
export function activeShiftTouched(activeShiftTouched = false) {
	return {
		type: ACTIVE_SHIFT_TOUCHED,
		payload: {
			activeShiftTouched,
		},
	};
}

/**
 * @param {object} shift
 */
export function removeTagFromActiveShift(index) {
	return {
		type: REMOVE_TAG_FROM_ACTIVE_SHIFT,
		payload: { index },
	};
}

/**
 * @param {object} shift
 */
export function addClockingToActiveShift(clocking) {
	return {
		type: ADD_CLOCKING_TO_ACTIVE_SHIFT,
		payload: { clocking },
	};
}

/**
 * @param {object} shift
 */
export function removeEmployeeFromActiveShift(shiftEmployee) {
	return {
		type: REMOVE_EMPLOYEE_FROM_ACTIVE_SHIFT,
		payload: { shiftEmployee },
	};
}

/**
 * @param {object} shift
 */
export function removeModuleExecutionFromActiveShift(moduleExecution, shiftId) {
	return {
		type: REMOVE_MODULE_EXECUTION_FROM_ACTIVE_SHIFT,
		payload: { moduleExecution, shiftId },
	};
}

/**
 * @function addTrainingModuleToActiveShift
 * @param trainingModule
 */
export function addTrainingModuleToActiveShift(trainingModule) {
	return {
		type: ADD_TRAINING_MODULE_TO_ACTIVE_SHIFT,
		payload: {
			trainingModule,
		},
	};
}

/**
 * @function addTrainingModuleToActiveShift
 * @param trainingModule
 */
export function updateTrainingModuleInActiveShift(trainingModule) {
	return {
		type: UPDATE_TRAINING_MODULE_IN_ACTIVE_SHIFT,
		payload: {
			trainingModule,
		},
	};
}
