'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { Button, Input, InputCollectionSelect, Icon, Tooltip } from 'dumb';
import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';
import getMarketColumns from 'reactDataWrapperColumns/administration/markets.columns';
import getPowerBiReportTypeColumns from 'reactDataWrapperColumns/reporting/powerBiReportTypes.columns';

// phrases/ constants
import phrases from './../../../powerBIReports.phrases';

class PowerBIReportsBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		const value = e?.target?.value ?? e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			[type]: value,
		});
	}

	render() {
		const { batchList, addBatchListItemRow } = this.props;

		return (
			<>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_NAME}</th>
							<th>{phrases.MODAL_BATCH_STEP_DESCRIPTION}</th>
							<th>{phrases.MODAL_BATCH_STEP_TYPE}</th>
							<th>{phrases.MODAL_BATCH_STEP_MARKET}</th>
							<th>{phrases.MODAL_BATCH_STEP_SORT_ORDER}</th>
							<th>{phrases.MODAL_BATCH_STEP_POWER_BI_ID}</th>
							<th>{phrases.MODAL_BATCH_STEP_ACCESS_GRANTING_AREA}</th>

							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((entry, i) => {
							const editButtonDisabled = batchList.length === 1;
							const marketReduxKey = `power-bi-reports-${entry.identifier}/administration/markets`;

							return (
								<TableListRow key={entry.identifier}>
									<TableListCell>{i + 1}</TableListCell>

									<TableListCell>
										<Input
											id={`name-${entry.identifier}`}
											placeholder={phrases.MODAL_BATCH_STEP_NAME_PLACEHOLDER}
											value={entry.name || ''}
											onChange={(event) =>
												this._editListEntry(event, entry, 'name')
											}
											required
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`description-${entry.identifier}`}
											placeholder={
												phrases.MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER
											}
											value={entry.description || ''}
											onChange={(event) =>
												this._editListEntry(event, entry, 'description')
											}
										/>
									</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id="type"
											name="type"
											placeholder="Select type..."
											apiPath="/reporting/power_bi_report_types"
											value={entry.type}
											handleChange={(key, event) =>
												this._editListEntry(event, entry, 'type')
											}
											params={{
												limit: 30,
											}}
											cache
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											inputFilterFormat={(input) => `:name=like='%${input}%'`}
											clearable={false}
											required
											tableColumns={getPowerBiReportTypeColumns()}
											tableReduxKey={`power-bi-reports-${entry.identifier}/reporting/power_bi_report_types`}
											tableTitle={phrases.MODAL_BATCH_STEP_TYPES}
										/>
									</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id="market"
											name="market"
											placeholder="Select market..."
											apiPath="/administration/markets"
											value={entry.market}
											handleChange={(key, event) =>
												this._editListEntry(event, entry, 'market')
											}
											params={{
												limit: 30,
											}}
											cache
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											inputFilterFormat={(input) => `:name=like='%${input}%'`}
											clearable={false}
											required
											tableColumns={getMarketColumns(marketReduxKey)}
											tableReduxKey={marketReduxKey}
											tableTitle={phrases.MODAL_BATCH_STEP_MARKETS}
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`sortOrder-${entry.identifier}`}
											type="number"
											placeholder={
												phrases.MODAL_BATCH_STEP_SORT_ORDER_PLACEHOLDER
											}
											value={entry.sortOrder || ''}
											onChange={(event) =>
												this._editListEntry(event, entry, 'sortOrder')
											}
										/>
									</TableListCell>
									<TableListCell>
										<Input
											id={`powerBIID-${entry.identifier}`}
											placeholder={
												phrases.MODAL_BATCH_STEP_POWER_BI_ID_PLACEHOLDER
											}
											value={entry.powerBiId || ''}
											onChange={(event) =>
												this._editListEntry(event, entry, 'powerBiId')
											}
											required
										/>
									</TableListCell>
									<TableListCell>
										<InputCollectionSelect
											id={`accessGrantingArea-${entry.identifier}`}
											placeholder={
												phrases.MODAL_BATCH_STEP_ACCESS_GRANTING_AREA_PLACEHOLDER
											}
											apiPath="/authentication/areas"
											value={entry.accessGrantingArea}
											handleChange={(key, event) =>
												this._editListEntry(event, entry, 'accessGrantingArea')
											}
											params={{
												limit: 30,
											}}
											cache
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											inputFilterFormat={(input) => `:name=like='%${input}%'`}
											// little hack since they share the same columns
											tableColumns={getPowerBiReportTypeColumns()}
											tableReduxKey={`power-bi-reports-${entry.identifier}/authentication/areas`}
											tableTitle={
												phrases.MODAL_BATCH_STEP_ACCESS_GRANTING_AREAS
											}
										/>
									</TableListCell>

									<TableListCell>
										<Button
											type="negative"
											size="tiny"
											disabled={editButtonDisabled}
											onClick={() => this._onRemoveEntry(entry.identifier)}>
											<span className="icon icon--delete" />
										</Button>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
				<div className="modal-batch__add-new-row">
					<Tooltip
						text={phrases.MODAL_BATCH_STEP_LIST_TOOLTIP}
						zIndex="600"
						renderData={(ref, onMouseEnter, onMouseLeave) => (
							<Button
								type="secondary"
								onClick={addBatchListItemRow}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
								refProp={ref}>
								<Icon name="add" />
							</Button>
						)}
					/>
				</div>
			</>
		);
	}
}

PowerBIReportsBatchStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
};

export default PowerBIReportsBatchStepList;
