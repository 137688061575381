'use strict';

/**
 * EmailData
 * @param  {object} title
 * @param  {object} body
 * @param  {object} printTitle
 */
class EmailData {
	constructor(subject, body, toMailingListDataProvider = null) {
		// Set type
		this.type = 'metadata';

		// Subject
		this.subject = subject;

		// Body
		this.body = body;

		// ToMailingListDataProvider
		this.toMailingListDataProvider = toMailingListDataProvider;
	}

	/**
	 * @function getSubject
	 * @param {object} data
	 * @return {string}
	 */
	getSubject(data) {
		return this.subject.getTemplate(data);
	}

	/**
	 * @function getBody
	 * @param {object} data
	 * @return {string}
	 */
	getBody(data) {
		return this.body.getTemplate(data);
	}

	/**
	 * @function getToMailingList
	 * @return {array (promise)}
	 */
	getToMailingList() {
		return this.toMailingListDataProvider === null ? Promise.resolve([]) : this.toMailingListDataProvider.getResultSet();
	}
}

export default EmailData;