'use strict';

/**
 * @function toggleAccordion
 * @memberOf SERVICES.__models
 * @description
 * Toggle accordions on list by adding to/removing from array
 * @param  {number} id  id to toggle accordion in
 */

import _extend from 'lodash/extend';
import _has from 'lodash/has';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import _some from 'lodash/some';

// Services
var __apiFilter = require('apiFilter.service');

module.exports = function (id, useFilter = true) {
	return new Promise((resolve, reject) => {
		var state = this.getState();

		// Check if current id is existing accordion
		var hasAccordion = _has(state.accordions, id);

		// Remove if already existing
		if (hasAccordion) resolve(_omit(state.accordions, id));
		// Add if not
		else {
			// Loop through list of accordions defined in settings
			Promise.all(
				_map(state.accordionsBase, function (accordion) {
					var list = angular.copy(accordion.list);

					if (useFilter) {
						// Add data and filter settings to accordion-list
						var filter = __apiFilter.defaults.fieldEqualsData(
							accordion.key,
							id
						);

						// Get common filter for querying where accordion key = id
						// Add filter to params and data for POST for each list model
						list.setFilterQuery(filter);
						var newDataObj = {};
						newDataObj[accordion.key] = id;
						list.setData(newDataObj);
					}

					// Get headers (it is possible to define an array of headers to be used)
					var accordionHeaders = _has(accordion, 'headers')
						? list.getState().headers.filter(function (header) {
								return _some(accordion.headers, { name: header.name });
						  })
						: list.getState().headers;

					// Overwrite params
					list.setParams(_extend(list.getState().params, accordion.params));

					// Get collection to add to accordion list view
					return list.loadCollection(true).then(function (collectionData) {
						// Update state on accordion list
						list.setCollection(collectionData[0]);
						list.setCollectionLength(collectionData[1]);

						// Return object with data
						return _extend(list.getState(), {
							headers: accordionHeaders,
							id: id,
							label: accordion.label,
							name: accordion.name,
							settings: _extend(list.getSettings(), accordion.settings),
							methods: list.getMethods(),
						});
					});
				})
			).then(function (accordions) {
				// Create object with id as key and data from promise as child
				var newAccordionObj = {};
				newAccordionObj[id] = { accordions: accordions };
				resolve(_extend(state.accordions, newAccordionObj));
			});
		}
	});
};
