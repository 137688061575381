'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');

export default class CompanyAccountSchedulesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'account_schedule',
				type: 'select',
				canAdd: true,
				required: true,
				reference: {
					key: 'name',
					searchFields: ['account_schedule.name', 'company.name'],
					label: '<%= company.name %> - <%= account_schedule.name %>',
					list: '/accounting/company_account_schedules',
				},
			},
		];
		this.settings = {
			name: 'CompanyAccountSchedulesList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			canCollapse: true,
			canManipulate: () => validAccess(undefined, ['Organisation/Companies::Edit', 'Organization Admin']),
			alwaysRefresh: true,
			translationPath: 'ACCOUNTING.COMPANY_ACCOUNT_SCHEDULES',
			endpoint: __endpoints.collection.accounting.company_account_schedules,
		};
		this.init();
		return this;
	}
}
