export const RESET_INGREDIENTS = 'SALES_CONFIGURATION/RESET_INGREDIENTS';
export const SET_INGREDIENTS = 'SALES_CONFIGURATION/SET_INGREDIENTS';
export const UPDATE_INGREDIENTS = 'SALES_CONFIGURATION/UPDATE_INGREDIENTS';
export const UPDATE_BATCH = 'SALES_CONFIGURATION/UPDATE_BATCH';
export const RESET_BATCH = 'SALES_CONFIGURATION/RESET_BATCH';
export const BATCH_LIST_ADD = 'SALES_CONFIGURATION/BATCH_LIST_ADD';
export const BATCH_LIST_EDIT_ITEM = 'SALES_CONFIGURATION/BATCH_LIST_EDIT_ITEM';
export const BATCH_LIST_REMOVE_ITEM =
	'SALES_CONFIGURATION/BATCH_LIST_REMOVE_ITEM';

/**
 * @param {object} payload
 */
export function setIngredient(payload) {
	return {
		type: SET_INGREDIENTS,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateIngredient(payload) {
	return {
		type: UPDATE_INGREDIENTS,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET_INGREDIENTS
	};
}

/**
 * @param {object} payload
 */
export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH,
		payload
	};
}

/**
 * @param {object} payload
 */
export function addBatchList(payload) {
	return {
		type: BATCH_LIST_ADD,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetBatch() {
	return {
		type: RESET_BATCH
	};
}

/**
 * @param {object} payload
 */
export function editBatchListItem(payload) {
	return {
		type: BATCH_LIST_EDIT_ITEM,
		payload
	};
}

/**
 * @param {string} payload ID
 */
export function removeBatchListItem(payload) {
	return {
		type: BATCH_LIST_REMOVE_ITEM,
		payload
	};
}
