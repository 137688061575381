'use strict';

import _uniqueId from 'lodash/uniqueId';

import {
	SET_FLAIRS_MODAL,
	UPATE_FLAIRS,
	RESET_STATE,
	ADD_BATCH_LIST,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	ADD_BATCH_LIST_ITEM_ROW
} from './flairs.actions';

const defaultState = {
	data: {
		flairs: {},
		batchList: [
			{
				identifier: _uniqueId('row-'),
				titleColor: 'ffffff',
				backgroundColor: '000000'
			}
		],
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_FLAIRS_MODAL: {
		return {
			...state,
			data: {
				flairs: payload
			}
		};
	}

	case UPATE_FLAIRS: {
		return {
			...state,
			data: {
				flairs: {
					...state.data.flairs,
					...payload
				}
			}
		};
	}

	case RESET_STATE: {
		return defaultState;
	}

	case ADD_BATCH_LIST: {
		return {
			...state,
			data: {
				...state.data,
				batchList: payload
			}
		};
	}

	case EDIT_BATCH_LIST_ITEM: {
		return {
			...state,
			data: {
				...state.data,
				batchList: state.data.batchList.map(entry => {
					if (entry.identifier === payload.identifier) return payload;
					return entry;
				})
			}
		};
	}

	case REMOVE_BATCH_LIST_ITEM: {
		return {
			...state,
			data: {
				...state.data,
				batchList: state.data.batchList.filter(
					entry => entry.identifier !== payload
				)
			}
		};
	}

	case ADD_BATCH_LIST_ITEM_ROW: {
		return {
			...state,
			data: {
				...state.data,
				batchList: [
					...state.data.batchList,
					{
						...defaultState.data.batchList[0],
						identifier: _uniqueId('row-')
					}
				]
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
