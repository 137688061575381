'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	resetState,
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	setPosConfigurationDiscounts,
	updatePosConfigurationDiscounts,
	setPosConfigurationDiscountsTableFilter,
	setLegacyFilter,
} from './store/salesConfigurationPosConfigurationDiscounts.actions';

// rDW actions
import { setListData } from 'reactDataWrapper/reactDataWrapper.actions';

// rDW
import { ReactDataWrapper } from 'reactDataWrapper';
import { DateFilterSelector } from 'reactDataWrapper/utilities';

// components
import {
	Button,
	Input,
	Icon,
	InputCollectionSelect,
	SingleDatePickerInput,
	Tooltip,
} from 'dumb';
import SalesConfigurationPosConfigurationDiscountsModalBatch from './components/batch/salesConfigurationPosConfigurationDiscountsModalBatch';

// lodash
import _get from 'lodash/get';

import {
	fetchPosConfigurationDiscounts,
	editPosConfigurationDiscount,
	editPosConfigurationDiscounts,
	deletePosConfigurationDiscounts,
	addPosConfigurationDiscounts,
	fetchPosConfigurationProductVariants,
} from './salesConfigurationPosConfigurationDiscounts.service';

// constants/phrases
import constants from 'services/constants';
import phrases from './salesConfigurationPosConfigurationDiscounts.phrases';

// utils
import { getPosConfigurationProductVariantFilter } from './utils';

import './salesConfigurationPosConfigurationDiscounts.css';

const reduxKey = 'salesConfiguration-pos/pos_configuration_discounts';

class SalesConfigurationPosConfigurationDiscounts extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this._onDateFilterChange = this._onDateFilterChange.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);

		this.columns = [
			{
				Header: 'Pos configuration',
				id: 'posConfiguration',
				accessor: (d) =>
					_get(d, 'pos_configuration_version.pos_configuration.name', ''),
				filterPath: ':pos_configuration_version.pos_configuration.name',
			},
			{
				Header: 'Pos configuration version',
				id: 'posConfigurationVersion',
				accessor: (d) => _get(d, 'pos_configuration_version.name', ''),
				filterPath: ':pos_configuration_version.name',
			},
			{
				Header: 'Active from',
				id: 'activeFrom',
				accessor: (d) => _get(d, 'pos_configuration_version.active.from', ''),
				filterPath: ':pos_configuration_version.active.from',
				Filter: ({ column }) => (
					<DateFilterSelector reduxKey={reduxKey} columnId={column.id} />
				),
			},
			{
				Header: 'Active To',
				id: 'activeTo',
				accessor: (d) => _get(d, 'pos_configuration_version.active.to', ''),
				filterPath: ':pos_configuration_version.active.to',
				Filter: ({ column }) => (
					<DateFilterSelector reduxKey={reduxKey} columnId={column.id} />
				),
			},
			{
				Header: 'Global discount',
				id: 'globalDiscount',
				accessor: (d) => _get(d, 'discount.name', ''),
				filterPath: ':discount.name',
			},
			{
				Header: 'Discount name',
				id: 'discountName',
				accessor: (d) => d.name || '',
				filterPath: ':name',
			},
			{
				Header: 'Discount description',
				id: 'discountDescription',
				accessor: (d) => d.description || '',
				filterPath: ':description',
			},
			{
				Header: 'Loyalty product',
				id: 'loyaltyProduct',
				accessor: (d) => _get(d, 'loyalty_product.product_variant.name', ''),
				filterPath: ':loyalty_product.product_variant.name',
			},
		];
	}

	editEntry() {
		const { defaultPosConfigurationDiscount } = this.props;

		const payload = {
			id: defaultPosConfigurationDiscount.id,
			name: defaultPosConfigurationDiscount.name,
			description: defaultPosConfigurationDiscount.description,
			discount: defaultPosConfigurationDiscount.discount.value,
			loyalty_product: _get(
				defaultPosConfigurationDiscount,
				'loyaltyProduct.value',
				null
			),
		};

		return editPosConfigurationDiscount(payload);
	}

	editMultiple(selectedRows) {
		const { defaultPosConfigurationDiscount } = this.props;

		const convertProductVariants =
			defaultPosConfigurationDiscount.loyaltyProduct;

		const payload = {
			...(defaultPosConfigurationDiscount.name && {
				name: defaultPosConfigurationDiscount.name,
			}),
			...(defaultPosConfigurationDiscount.description && {
				description: defaultPosConfigurationDiscount.description,
			}),
		};

		const filter = getPosConfigurationProductVariantFilter({
			productVariantId: _get(
				defaultPosConfigurationDiscount,
				'loyaltyProduct.value',
				null
			),
			selectedRows,
		});

		const funcToExecute = convertProductVariants
			? fetchPosConfigurationProductVariants({ filter })
			: new Promise((resolve) => resolve(true));

		return funcToExecute.then((res) => {
			const selectedRowsWithId = selectedRows.map((row) => {
				// find pos config product variant where id matches
				const posConfigurationProductVariant =
					convertProductVariants &&
					res.find(
						(entry) =>
							_get(entry, 'pos_configuration_version.id', null) ===
							_get(row, 'pos_configuration_version.id', null)
					);

				return {
					id: row.id,
					...payload,
					...(posConfigurationProductVariant && {
						loyalty_product: posConfigurationProductVariant.id,
					}),
				};
			});

			return editPosConfigurationDiscounts({
				batch: selectedRowsWithId,
			});
		});
	}

	deleteEntry(id) {
		return deletePosConfigurationDiscounts(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			posConfiguration: data.pos_configuration_version.pos_configuration,
			posConfigurationVersion: data.pos_configuration_version,
			activeFrom: _get(data, 'pos_configuration_version.active.from', ''),
			activeTo: _get(data, 'pos_configuration_version.active.to', ''),
			discount: data.discount.name,
			...(data.loyalty_product && {
				loyaltyProduct: {
					value: data.loyalty_product.id,
					label: data.loyalty_product.product_variant.name,
				},
			}),
			name: data.name,
			description: data.description,
			editSingle: true,
		};

		this.props.setPosConfigurationDiscounts(payload);
	}

	getEditableCellsEdit() {
		const { defaultPosConfigurationDiscount } = this.props;

		// loyalty products is a pretty unique scenario and has 2 cases:
		// if you're editing a single row then we fetch from /pos/pos_configuration_product_variants as expected
		// if you're fetching for multiple values then we fetch product_variants and make it seem like youre fetching loyalty products, saga continues in editMultiple function

		const loyaltyProductEndpoint = defaultPosConfigurationDiscount.editSingle
			? '/pos/pos_configuration_product_variants'
			: '/product/product_variants';

		return [
			...(defaultPosConfigurationDiscount.editSingle
				? [
						{
							header: 'Pos configuration',
							value: (
								<span>
									{_get(
										defaultPosConfigurationDiscount,
										'posConfiguration.name',
										''
									)}
								</span>
							),
						},
						{
							header: 'Pos configuration version',
							value: (
								<span>
									{_get(
										defaultPosConfigurationDiscount,
										'posConfigurationVersion.name',
										''
									)}
								</span>
							),
						},
						{
							header: 'Active from',
							value: (
								<span>
									{moment
										.utc(defaultPosConfigurationDiscount.activeFrom)
										.format(constants.shortDate)}
								</span>
							),
						},
						{
							header: 'Active to',
							value: (
								<span>
									{moment
										.utc(defaultPosConfigurationDiscount.activeTo)
										.format(constants.shortDate)}
								</span>
							),
						},
						{
							header: 'Global discount',
							value: <span>{defaultPosConfigurationDiscount.discount}</span>,
						},
				  ]
				: []),
			{
				header: 'Discount name',
				value: (
					<Input
						id="name"
						placeholder="Enter discount name..."
						value={defaultPosConfigurationDiscount.name || ''}
						onChange={(event) => this.editStoreEntry('name', event)}
					/>
				),
			},
			{
				header: 'Discount description',
				value: (
					<Input
						id="description"
						placeholder="Enter discount description..."
						value={defaultPosConfigurationDiscount.description || ''}
						onChange={(event) => this.editStoreEntry('description', event)}
					/>
				),
			},
			{
				header: 'Loyalty product',
				value: (
					<InputCollectionSelect
						id="loyaltyProduct"
						placeholder="Enter loyalty product..."
						value={defaultPosConfigurationDiscount.loyaltyProduct}
						handleChange={(key, value) => {
							this.editStoreEntry('loyaltyProduct', value);
						}}
						clearable
						cache
						apiPath={loyaltyProductEndpoint}
						params={{
							limit: 30,
							...(defaultPosConfigurationDiscount.editSingle && {
								filter: `:pos_configuration_version.id=='${_get(
									defaultPosConfigurationDiscount,
									'posConfigurationVersion.id',
									null
								)}'`,
							}),
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: defaultPosConfigurationDiscount.editSingle
								? entry.product_variant.name
								: entry.name,
						})}
						inputFilterFormat={(input) =>
							defaultPosConfigurationDiscount.editSingle
								? `:product_variant.name=like='%${input}%'`
								: `:name=like='%${input}%'`
						}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		let value;
		if (e === null) value = e;
		else value = e.target ? e.target.value : e;

		const payload = {
			[name]: value,
		};

		this.props.updatePosConfigurationDiscounts(payload);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	_onDateFilterChange(date) {
		const { setPosConfigurationDiscountsTableFilter } = this.props;

		const formattedDate = date
			? moment.utc(date).format(constants.shortDate)
			: null;

		const filterString = formattedDate
			? `:pos_configuration_version.active.from=le='${formattedDate}';:pos_configuration_version.active.to=ge='${formattedDate}'`
			: '';

		setPosConfigurationDiscountsTableFilter(filterString);
	}

	toggleLegacyFilter() {
		const { setLegacyFilter, legacyFilter } = this.props;

		const filter = legacyFilter
			? ''
			: ':pos_configuration_version.pos_configuration.legacy==false';

		setLegacyFilter(filter);
	}

	getAdditionalPosConfigurationDiscountTableFilters() {
		const { posConfigurationDiscountDateFilter, legacyFilter } = this.props;

		return (
			<div className="pos-configuration-discount__filter-area">
				<SingleDatePickerInput
					id="posConfigDiscountDatePicker"
					label="Select active date"
					type="single"
					appendToBody
					fixed
					onChange={this._onDateFilterChange}
					selectedDate={posConfigurationDiscountDateFilter}
					noClockButton
					clearButtonDisabled={!posConfigurationDiscountDateFilter}
					clearable
				/>
				<Tooltip
					text={
						legacyFilter
							? phrases.LEGACY_FILTER_TOOLTIP_ACTIVE
							: phrases.LEGACY_FILTER_TOOLTIP_UNACTIVE
					}
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							id="legacyFilterButton"
							className="pos-configuration-product-variants-pricing__filter-area__legacy-button"
							onClick={this.toggleLegacyFilter}
							type={legacyFilter ? '' : 'inverted'}
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}>
							Legacy
						</Button>
					)}
				/>
			</div>
		);
	}

	getExtraFilters() {
		const { posConfigurationDiscountDateFilter, legacyFilter } = this.props;

		let filter = posConfigurationDiscountDateFilter;
		if (legacyFilter && posConfigurationDiscountDateFilter)
			filter = `${filter};${legacyFilter}`;
		if (!posConfigurationDiscountDateFilter) filter = legacyFilter;

		return filter;
	}

	render() {
		const {
			updateBatchForm,
			batchFormData,
			listDiscounts,
			listPosConfigurationVersions,
			addBatchList,
			batchList,
			editBatchListItem,
			removeBatchListItem,
			resetState,
			posConfigurationVersionId,
			// batchSelection props
			onInitialization,
			reduxKey: batchReduxKey,
			style,
			defaultPageSize,
			batchSelection,
		} = this.props;

		return (
			<>
				<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={fetchPosConfigurationDiscounts}
					defaultPageSize={batchSelection ? defaultPageSize : 25}
					reduxKey={batchSelection ? batchReduxKey : reduxKey}
					style={batchSelection ? style : {}}
					{...(batchSelection && { onInitialization })}
					batchSelection={batchSelection}
					filterable
					manual
					editEntry={() => this.editEntry()}
					editableCellsEdit={this.getEditableCellsEdit()}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={resetState}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					deleteConfirm
					actionRender={
						<Button
							type="inverted"
							label="Add"
							shadow
							onClick={() => this.toggleModal()}>
							<Icon name="add" />
						</Button>
					}
					customAreaComponents={this.getAdditionalPosConfigurationDiscountTableFilters()}
					extraFilters={this.getExtraFilters()}
				/>

				<SalesConfigurationPosConfigurationDiscountsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchFormData={batchFormData}
					updateBatchForm={updateBatchForm}
					listDiscounts={listDiscounts}
					addBatchList={addBatchList}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					batchAddData={addPosConfigurationDiscounts}
					posConfigurationVersionId={posConfigurationVersionId}
					listPosConfigurationVersions={listPosConfigurationVersions}
				/>
			</>
		);
	}
}

SalesConfigurationPosConfigurationDiscounts.propTypes = {
	defaultPosConfigurationDiscount: PropTypes.object,
	listDiscounts: PropTypes.object,
	listPosConfigurationVersions: PropTypes.object,
	setPosConfigurationDiscounts: PropTypes.func,
	resetState: PropTypes.func,
	updateBatchForm: PropTypes.func,
	addBatchList: PropTypes.func,
	batchFormData: PropTypes.object,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	updatePosConfigurationDiscounts: PropTypes.func,
	setPosConfigurationDiscountsTableFilter: PropTypes.func,
	posConfigurationVersionId: PropTypes.number,
	posConfigurationDiscountDateFilter: PropTypes.string,
	setLegacyFilter: PropTypes.func,
	legacyFilter: PropTypes.string,
	onInitialization: PropTypes.func,
	reduxKey: PropTypes.string,
	style: PropTypes.object,
	defaultPageSize: PropTypes.number,
	batchSelection: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			resetState,
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			setPosConfigurationDiscounts,
			updatePosConfigurationDiscounts,
			setPosConfigurationDiscountsTableFilter,
			setListData,
			setLegacyFilter,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		batchFormData:
			store.salesConfigurationPosConfigurationDiscounts.data.batchFormData,
		batchList: store.salesConfigurationPosConfigurationDiscounts.data.batchList,
		posConfigurationDiscountDateFilter:
			store.salesConfigurationPosConfigurationDiscounts
				.posConfigurationDiscountDateFilter,
		listDiscounts:
			store.listData['global-pos-configuration-discounts/sale/discounts'],
		listPosConfigurationVersions:
			store.listData[
				'global-pos-configuration-discounts/pos/pos_configuration_versions'
			],
		defaultPosConfigurationDiscount:
			store.salesConfigurationPosConfigurationDiscounts.data
				.defaultPosConfigurationDiscount,
		legacyFilter:
			store.salesConfigurationPosConfigurationDiscounts.legacyFilter,
	};
};

export default connectWithStore(
	SalesConfigurationPosConfigurationDiscounts,
	mapStateToProps,
	mapDispatchToProps
);
