import React, { useState, ChangeEvent } from 'react';

// React router
import { useParams } from 'react-router-dom';

// Components
import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import getColumns from 'reactDataWrapperColumns/hr/moneyballSubPosition.columns';
import {
	Input,
	CheckBox,
	SingleDatePickerInput,
	InputCollectionSelect,
} from 'dumb';

// Mui
import { Box } from '@mui/material';

// Phrases & Roles & Tools
import phrases from './moneyballSubPositions.phrases';
import getEditedValues from 'services/utils/getEditedValues';

// Services
import {
	fetchMoneyballSubPosition,
	deleteMoneyballSubPosition,
	createMoneyballSubPosition,
	updateMoneyballSubPosition,
} from './services/moneyballSubPositions.service';
import moment from 'moment';
import constants from 'services/constants';

// Interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';
import { InitialState } from './types';
import { SelectOption } from 'types/select';

type Props = {
	reduxKey: string;
};

export default function MoneyballSubPositions({
	reduxKey,
}: Props): JSX.Element {
	const { moneyballPositionId } = useParams();

	const [originalData, setOriginalData] = useState<IHrMoneyballSubPositions>();
	const [entryId, setEntryId] = useState<number>(0);
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [role, setRole] = useState<SelectOption | undefined>();
	const [sortOrder, setSortOrder] = useState(0);
	const [teamPositionCategory, setTeamPositionCategory] = useState<
		SelectOption | undefined
	>();
	const [productivity, setProductivity] = useState(0);
	const [badge, setBadge] = useState('');
	const [canTrain, setCanTrain] = useState(false);

	const [fromDate, setFromDate] = useState(
		moment().format(constants.shortDate)
	);
	const [toDate, setToDate] = useState(
		moment('9999-12-31').format(constants.shortDate)
	);

	const deleteEntry = (id: number) => {
		return deleteMoneyballSubPosition(id);
	};

	const loadData = (state: IFetchDataProps) => {
		return fetchMoneyballSubPosition(state, moneyballPositionId);
	};

	const optionsForTeamCategory = [
		{ label: 'Trainee', value: 'Trainee' },
		{ label: 'Juicer', value: 'Juicer' },
		{ label: 'Manager', value: 'Manager' },
	];

	const resetState = () => {
		setName('');
		setDescription('');
		setRole(undefined);
		setSortOrder(0);
		setTeamPositionCategory(undefined);
		setProductivity(0);
		setBadge('');
		setCanTrain(false);
		setFromDate(moment().format(constants.shortDate));
		setToDate(moment('9999-12-31').format(constants.shortDate));
	};

	const handleChange = (name: string, value: SelectOption) => {
		setTeamPositionCategory(value);
	};

	const handleChangeRole = (name: string, value: SelectOption) => {
		setRole(value);
	};

	const inputsForModal = () => {
		return [
			{
				header: 'Name',
				value: (
					<Input
						required
						dataCy="rdw-name"
						id="name"
						value={name}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							setName(e.target.value)
						}
						placeholder="Enter Name"
						type="text"
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						required
						dataCy="rdw-description"
						id="description"
						value={description}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							setDescription(e.target.value)
						}
						placeholder="Enter Description"
						type="text"
					/>
				),
			},
			{
				header: 'Role',
				value: (
					<InputCollectionSelect
						required
						dataCy="rdw-role"
						value={role}
						cache
						params={{
							limit: 30,
						}}
						apiPath="/authentication/roles"
						handleChange={(_: unknown, value: SelectOption) =>
							handleChangeRole('type', value)
						}
					/>
				),
			},
			{
				header: 'Sort Order',
				value: (
					<Input
						dataCy="rdw-sort-order"
						value={sortOrder}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							setSortOrder(parseInt(e.target.value))
						}
						placeholder="Enter Sort Order"
						type="number"
					/>
				),
			},
			{
				header: 'Team Position Category',
				value: (
					<InputCollectionSelect
						required
						dataCy="rdw-team-position-category"
						value={teamPositionCategory}
						cache
						params={{
							limit: 30,
						}}
						handleChange={(_: unknown, value: SelectOption) =>
							handleChange('type', value)
						}
						options={optionsForTeamCategory}
					/>
				),
			},
			{
				header: 'Productivity',
				value: (
					<Input
						dataCy="rdw-productivity"
						value={productivity}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							setProductivity(parseInt(e.target.value))
						}
						placeholder="Enter Productivity"
						type="number"
					/>
				),
			},
			{
				header: 'Can Train',
				value: (
					<Box>
						<CheckBox
							dataCy="rdw-can-train"
							labelPosition="lol"
							checked={canTrain}
							onClick={() => setCanTrain(!canTrain)}
						/>
					</Box>
				),
			},
			{
				header: 'Badge',
				value: (
					<Input
						required
						dataCy="rdw-badge"
						value={badge}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							setBadge(e.target.value)
						}
						placeholder="Enter Badge"
						type="text"
					/>
				),
			},
			{
				header: 'From Date',
				value: (
					<SingleDatePickerInput
						dataCy="rdw-from-date-button"
						selectedDate={moment(fromDate).format(constants.shortDate)}
						onChange={(e: string) => setFromDate(e)}
						placeholder="From"
					/>
				),
			},
			{
				header: 'To Date',
				value: (
					<SingleDatePickerInput
						dataCy="rdw-to-date-button"
						selectedDate={moment(toDate).format(constants.shortDate)}
						onChange={(e: string) => setToDate(e)}
						placeholder="To"
					/>
				),
			},
		];
	};

	const getPayload = (): IHrMoneyballSubPositions => {
		const payload = {
			moneyball_position: parseInt(moneyballPositionId as string),
			name: name,
			description: description,
			productivity: productivity,
			team_position_category: teamPositionCategory?.label,
			badge: badge,
			sort_order: sortOrder,
			role: role?.value,
			can_train: canTrain,
			active: {
				from: moment(fromDate).format(constants.shortDate),
				to: moment(toDate).format(constants.shortDate),
			},
		};

		return payload;
	};

	const handleCreate = () => {
		const payload = getPayload();
		return createMoneyballSubPosition(payload);
	};

	const setInitialEditValues = (data: InitialState) => {
		setOriginalData({
			moneyball_position: data.moneyball_position,
			name: data.name,
			description: data.description,
			sort_order: data.sort_order,
			productivity: data.productivity,
			team_position_category: data.team_position_category,
			badge: data.badge,
			role: data.role.name,
			can_train: data.can_train,
			active: {
				from: data.active.from,
				to: data.active.to,
			},
		});
		setEntryId(data.id);
		setName(data.name);
		setDescription(data.description);
		setRole({ label: data.role.name } as SelectOption);
		setSortOrder(data.sort_order);
		setTeamPositionCategory({
			label: data.team_position_category,
		} as SelectOption);
		setProductivity(data.productivity);
		setCanTrain(data.can_train);
		setBadge(data.badge);
		setFromDate(data.active.from);
		setToDate(data.active.to);
	};

	const handleEdit = () => {
		if (!originalData) return Promise.resolve(true);

		const payload = getPayload();

		const editedValues = getEditedValues({
			oldData: originalData,
			newData: payload,
		});
		return updateMoneyballSubPosition(
			entryId,
			editedValues as IHrMoneyballSubPositions
		);
	};

	return (
		<ReactDataWrapper
			title={phrases.MONEYBALL_SUB_POSITIONS}
			reduxKey={reduxKey}
			columns={getColumns({ reduxKey })}
			fetchData={loadData}
			deleteEntry={deleteEntry}
			createEntry={handleCreate}
			editEntry={handleEdit}
			setInitialEditValues={setInitialEditValues}
			editableCells={inputsForModal()}
			editableCellsEdit={inputsForModal()}
			onModalClose={resetState}
			defaultPageSize={10}
			accessAreasAllowedToEdit={[
				'HR/MoneyballPositions::Edit',
				'Employment Admin',
				'Person Admin',
				'Organization Admin',
			]}
		/>
	);
}
