import React, { Component } from 'react';

// Components
import TileLayouts from './tileLayouts/tileLayout.component';
// import POSLayout from './pos/posConfigurationLayout.container';

class LayoutContainer extends Component {
	render() {
		return <TileLayouts />;
	}
}

LayoutContainer.propTypes = {};

export default LayoutContainer;
