import {
	SET_PRODUCT_ADDONS_ENTRIES,
	UPDATE_PRODUCT_ADDONS_ENTRIES,
	RESET_PRODUCT_ADDONS_ENTRIES,
	RESET_PRODUCT_VARIANT_ADDONS,
	SET_PRODUCT_VARIANT_ADDONS,
	UPDATE_PRODUCT_VARIANT_ADDONS
} from './productAddons.actions';

const defaultState = {
	ui: {},
	data: {
		defaultProductAddons: {},
		defaultProductAddonsIngredients: {},
		defaultProductVariantAddons: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_PRODUCT_ADDONS_ENTRIES: {
		return {
			...state,
			data: {
				...state.data,
				defaultProductAddons: payload
			}
		};
	}

	case UPDATE_PRODUCT_ADDONS_ENTRIES: {
		return {
			...state,
			data: {
				...state.data,
				defaultProductAddons: {
					...state.data.defaultProductAddons,
					...payload
				}
			}
		};
	}

	case RESET_PRODUCT_ADDONS_ENTRIES: {
		return {
			...state,
			data: {
				...state.data,
				defaultProductAddons: {}
			}
		};
	}

	case SET_PRODUCT_VARIANT_ADDONS: {
		return {
			...state,
			data: {
				...state.data,
				defaultProductVariantAddons: payload
			}
		};
	}

	case UPDATE_PRODUCT_VARIANT_ADDONS: {
		return {
			...state,
			data: {
				...state.data,
				defaultProductVariantAddons: {
					...state.data.defaultProductVariantAddons,
					product_variant: payload.product_variant
				}
			}
		};
	}

	case RESET_PRODUCT_VARIANT_ADDONS: {
		return {
			...state,
			data: {
				...state.data,
				defaultProductVariantAddons: {}
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
