'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, Toggle, Legend, Tag } from 'dumb';
import { ModalRow } from 'dumb/modal';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// phrases
import phrases from './../../../salesConfigurationIngredientPricings.phrases';

// lodash
import _get from 'lodash/get';

class IngredientPricingBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e.target ? e.target.value || e.target.checked : e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: value },
		});
	}

	render() {
		const { batchList } = this.props;

		const cantAddBulletColor = 'ffe699';

		return (
			<>
				<ModalRow>
					<div className="modal-batch__legend">
						<Legend
							points={[
								{
									bulletColor: `#${cantAddBulletColor}`,
									label: phrases.MODAL_BATCH_STEP_LIST_LEGEND_CANT_ADD,
								},
							]}
						/>
					</div>
				</ModalRow>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_POS_CONFIGURATION_VERSION}</th>
							<th>{phrases.MODAL_BATCH_STEP_INGREDIENT}</th>
							<th>{phrases.MODAL_BATCH_STEP_ALTERNATIVE_ID}</th>
							<th>{phrases.MODAL_BATCH_STEP_TO_STAY_PRICE}</th>
							<th>{phrases.MODAL_BATCH_STEP_TO_GO_PRICE}</th>
							<th>{phrases.MODAL_BATCH_STEP_DELIVERY_PRICE}</th>
							<th>{phrases.MODAL_BATCH_STEP_AVAILABLE_IN_APP}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((entry, i) => {
							const rowDisabled = !entry.canAdd;

							return (
								<TableListRow key={entry.identifier}>
									<TableListCell>
										<div className="pos-configuration-ingredient-pricing__cell-row">
											{rowDisabled && <Tag type="bullet" color={cantAddBulletColor} noBottomMargin />}
											{i + 1}
										</div>
									</TableListCell>
									<TableListCell>{_get(entry, 'posConfigurationVersion.name', '')}</TableListCell>
									<TableListCell>{_get(entry, 'ingredient.name', '')}</TableListCell>

									<TableListCell>
										<Input
											id="alternativeId"
											placeholder="Alternative Id"
											type="number"
											value={_get(entry, 'alternativeId', '')}
											onChange={(e) => this._editListEntry(e, entry, 'alternativeId')}
											disabled={rowDisabled}
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id="toStayPrice"
											type="number"
											value={entry.toStayPrice}
											placeholder="To stay price"
											onChange={(event) => this._editListEntry(event, entry, 'toStayPrice')}
											required
											disabled={rowDisabled}
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id="toGoPrice"
											type="number"
											value={entry.toGoPrice}
											placeholder="To go price"
											onChange={(event) => this._editListEntry(event, entry, 'toGoPrice')}
											required
											disabled={rowDisabled}
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id="delivery-price"
											type="number"
											value={entry.deliveryPrice}
											placeholder="Delivery price..."
											onChange={(event) => this._editListEntry(event, entry, 'deliveryPrice')}
											required
											disabled={rowDisabled}
										/>
									</TableListCell>

									<TableListCell>
										<Toggle
											id="available-in-app"
											toggled={entry.availableInApp}
											onClick={(event) => this._editListEntry(event, entry, 'availableInApp')}
											disabled={rowDisabled}
										/>
									</TableListCell>
									<TableListCell>
										<Button
											type="negative"
											size="tiny"
											disabled={rowDisabled}
											onClick={() => this._onRemoveEntry(entry.identifier)}
										>
											<span className="icon icon--delete" />
										</Button>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
			</>
		);
	}
}

IngredientPricingBatchStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

IngredientPricingBatchStepList.defaultProps = {};

export default IngredientPricingBatchStepList;
