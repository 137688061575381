import { SET_SERACH_MODAL, SET_SEARCH_RESULTS } from '../actions/globalSearch.actions';

const defaultState = { showModal: false, results: [] };

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
		case SET_SERACH_MODAL:
			return {
				...state,
				showModal: payload,
			};

		case SET_SEARCH_RESULTS:
			return {
				...state,
				results: payload,
			};

		default:
			return state;
	}
}

export default reducer;
