'use strict';

import PropTypes from 'prop-types';
import React from 'react';
import Date from 'reusableComponents/inputs/date/date.component';

const SingleDate = ({
	component,
	componentState,
	handleChange,
	handleFocusChange
}) => {
	return (
		<Date
			type="single"
			onChange={event => handleChange(component, event)}
			onFocusChange={event => handleFocusChange(component, event)}
			shouldTriggerOnChange
			initialDate={
				component.defaultDate !== null
					? component.defaultDate
					: componentState !== null
						? componentState.results
						: null
			}
		/>
	);
};

SingleDate.propTypes = {
	component: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleFocusChange: PropTypes.func.isRequired
};

export default SingleDate;
