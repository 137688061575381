import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './posConfigurationTileLayouts.phrases';

/**
 * @function fetchPosConfigurationTileLayouts
 * @description Fetch all languages
 * @param {object} state
 */
export function fetchPosConfigurationTileLayouts(state, filter) {
	if (state.filter) filter = `${filter};${state.filter}`;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get('/pos/pos_configuration_tile_layouts', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addPosConfigurationTileLayout
 * @description add languages
 */
export function addPosConfigurationTileLayout(data) {
	return post('/pos/pos_configuration_tile_layouts', data)
		.then((res) => {
			setFeedback(phrases.ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editPosConfigurationTileLayouts
 * @description edit posConfigurationTileLayout
 */
export function editPosConfigurationTileLayouts(data) {
	return post(`/pos/pos_configuration_tile_layouts/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deletePosConfigurationTileLayouts
 * @description delete posConfigurationTileLayout
 */
export function deletePosConfigurationTileLayouts(id) {
	return remove(`/pos/pos_configuration_tile_layouts/${id}`)
		.then((res) => {
			setFeedback(phrases.DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
