'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// cool shit to make page tips alive!
import ReactDOM from 'react-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// utilities
import { getRandomNumber, filterTips } from './store/pageTips.utilities';
import SessionStorage from './store/pageTipsSessionStorage';
import { isMobile } from 'detectMobile.vanilla';

// lodash
import _isEmpty from 'lodash/isEmpty';

// components
import { Tip } from './../tip/';

// style
import './pageTips.css';

// mui
import { Box } from '@mui/material';

class PageTips extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentIndex: 0,
			visible: true,
		};

		this._changeTipCounter = this._changeTipCounter.bind(this);
		this.hideTips = this.hideTips.bind(this);
		this.showNextTip = this.showNextTip.bind(this);
		this._startTipInterval = this._startTipInterval.bind(this);

		this.tipsToShow = [];

		this.SessionStorage = new SessionStorage();
		this.node = document.createElement('div');
		document.body.appendChild(this.node);
	}

	componentDidMount() {
		const { tips, id } = this.props;

		// check if any visibility presets saved in session storage
		const sessionState = this.SessionStorage.getItem(
			`${id}-pagetips-visibility`
		);

		// check whether we should hide tips
		if (sessionState === 'false' || isMobile())
			this.setState(() => ({ visible: false }));
		else {
			// otherweise start the interval timer and filter tips to show
			this._startTipInterval();

			// runs tips through filter and returns only ones that our userRole allows
			this.tipsToShow = filterTips({ tips });

			this._changeTipCounter();
		}
	}

	componentWillUnmount() {
		this._clearTipInterval();
	}

	/**
	 * @method _startTipInterval
	 * @description starts tip interval
	 */
	_startTipInterval() {
		const { timer } = this.props;

		this.interval = setInterval(() => this._changeTipCounter(), timer);
	}

	/**
	 * @method _clearInterval
	 * @description clears tip interval
	 */
	_clearTipInterval() {
		clearInterval(this.interval);
	}

	/**
	 * @method _changeTipCounter
	 * @description changes currentIndex in state to a random (not true random) number
	 * @description random number is in scope from 0 to last element - 1 of the tipsToShow array
	 */
	_changeTipCounter() {
		// get random number
		const randomNumber = getRandomNumber({ arrayOfTips: this.tipsToShow });

		this.setState(() => ({ currentIndex: randomNumber }));
	}

	/**
	 * @method hideTips
	 * @description hides tips and stops the interval to stop re-rendering
	 * @description sets the tip id and visibility in session storage
	 */
	hideTips() {
		const { id } = this.props;

		this.SessionStorage.setItem(`${id}-pagetips-visibility`, false);

		this.setState(() => ({
			visible: false,
		}));

		this._clearTipInterval();
	}

	/**
	 * @method showNextTip
	 * @description shows next tip by changing currentIndex to a random number
	 * @description it stops interval execution and starts it again after new index has been set
	 */
	showNextTip() {
		this._clearTipInterval();
		this._changeTipCounter();
		this._startTipInterval();
	}

	render() {
		const { currentIndex, visible } = this.state;
		const { style } = this.props;

		return visible && !_isEmpty(this.tipsToShow)
			? ReactDOM.createPortal(
				<TransitionGroup className="page-tips__wrapper" style={style}>
					<CSSTransition key={currentIndex} timeout={400} classNames="fade">
						<Box className="page-tips__wrapper__content">
							<Tip
								onTipClick={this.showNextTip}
								onTipCloseClick={this.hideTips}
								content={this.tipsToShow[currentIndex].tip}
								icon={this.tipsToShow[currentIndex].icon}
								iconClose={this.tipsToShow[currentIndex].iconClose}
								hoverable
								/>
						</Box>
					</CSSTransition>
				</TransitionGroup>,
					this.node
			  )
			: null;
	}
}

PageTips.defaultProps = {
	timer: 60000,
	style: {},
};

PageTips.propTypes = {
	tips: PropTypes.array,
	id: PropTypes.string.isRequired,
	timer: PropTypes.number,
	style: PropTypes.object,
};

export default PageTips;
