'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setWaitingTimePerformanceGridEntries,
	updateWaitingTimePerformanceGridEntries,
	resetState,
} from './store/waitingTimePerformanceGridEntries.actions';

// services
import {
	fetchWaitingTimePerformanceGridEntries,
	editWaitingTimePerformanceGridEntry,
	editWaitingTimePerformanceGridEntries,
	addWaitingTimePerformanceGridEntries,
	deleteWaitingTimePerformanceGridEntries,
} from './waitingTimePerformanceGridEntries.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Input, Button, Icon } from 'dumb';
import WaitingTimePerformanceGridsModalBatch from './components/batch/waitingTimePerformanceGridEntriesModalBatch';

// utils
import { getEditedValues } from 'services/utils';

// moment
import moment from 'moment';

// phrases
import phrases from './waitingTimePerformanceGridEntries.phrases';

const reduxKey = '/administration/waiting_time_performance_grid_entries';

class WaitingTimePerformanceGridEntries extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.addWaitingTimePerformanceGridEntriesWrapper = this.addWaitingTimePerformanceGridEntriesWrapper.bind(
			this
		);
		this.fetchData = this.fetchData.bind(this);
		this.editEntry = this.editEntry.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: 'name',
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: 'description',
				filterPath: ':description',
			},
			{
				Header: 'Badge',
				id: 'badge',
				accessor: 'badge',
				filterPath: ':badge',
			},
			{
				Header: 'Value',
				id: 'value',
				accessor: 'value',
				filterPath: ':value',
			},
			{
				Header: 'Percentage',
				id: 'percentage',
				accessor: 'percentage',
				filterPath: ':percentage',
			},
			{
				Header: 'Minimum orders',
				id: 'minimum-orders',
				accessor: 'minimum_orders',
				filterPath: ':minimum_orders',
			},
		];
	}

	editEntry() {
		const {
			waitingTimePerformanceGridEntries,
			defaultWaitingTimePerformanceGridEntries,
		} = this.props;

		const editedValues = getEditedValues({
			oldData: defaultWaitingTimePerformanceGridEntries,
			newData: waitingTimePerformanceGridEntries,
		});

		const payload = {
			id: waitingTimePerformanceGridEntries.id,
			name: editedValues.name ?? undefined,
			description: editedValues.description ?? undefined,
			badge: editedValues.badge ?? undefined,
			value: editedValues.value ?? undefined,
			percentage: editedValues.percentage ?? undefined,
			minimum_orders: editedValues.minimumOrders ?? undefined,
		};

		return editWaitingTimePerformanceGridEntry(payload);
	}

	editMultiple(selectedRows) {
		const { waitingTimePerformanceGridEntries } = this.props;

		const payload = {
			...(waitingTimePerformanceGridEntries.name && {
				name: waitingTimePerformanceGridEntries.name,
			}),
			...(waitingTimePerformanceGridEntries.description && {
				description: waitingTimePerformanceGridEntries.description,
			}),
			...(waitingTimePerformanceGridEntries.badge && {
				badge: waitingTimePerformanceGridEntries.badge,
			}),
			...(waitingTimePerformanceGridEntries.value && {
				value: waitingTimePerformanceGridEntries.value,
			}),
			...(waitingTimePerformanceGridEntries.percentage && {
				percentage: waitingTimePerformanceGridEntries.percentage,
			}),
			...(waitingTimePerformanceGridEntries.minimumOrders && {
				minimum_orders: waitingTimePerformanceGridEntries.minimumOrders,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editWaitingTimePerformanceGridEntries({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteWaitingTimePerformanceGridEntries(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			badge: data.badge,
			value: data.value,
			percentage: data.percentage,
			minimumOrders: data.minimum_orders,
			singleEdit: true,
		};

		this.props.setWaitingTimePerformanceGridEntries(payload);
	}

	getEditableCells() {
		const { waitingTimePerformanceGridEntries } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={waitingTimePerformanceGridEntries.name}
						onChange={(e) => this.editStoreEntry('name', e)}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={waitingTimePerformanceGridEntries.description}
						onChange={(e) => this.editStoreEntry('description', e)}
					/>
				),
			},
			{
				header: 'Badge',
				value: (
					<Input
						id="badge"
						placeholder="Enter badge..."
						value={waitingTimePerformanceGridEntries.badge}
						onChange={(e) => this.editStoreEntry('badge', e)}
					/>
				),
			},
			{
				header: 'Value',
				value: (
					<Input
						id="value"
						placeholder="Enter value..."
						value={waitingTimePerformanceGridEntries.value}
						onChange={(e) => this.editStoreEntry('value', e)}
					/>
				),
			},
			{
				header: 'Percentage',
				value: (
					<Input
						id="percentage"
						placeholder="Enter percentage..."
						value={waitingTimePerformanceGridEntries.percentage}
						onChange={(e) => this.editStoreEntry('percentage', e)}
					/>
				),
			},
			{
				header: 'Minimum orders',
				value: (
					<Input
						id="minimum-orders"
						placeholder="Enter minimum orders..."
						value={waitingTimePerformanceGridEntries.minimumOrders}
						onChange={(e) => this.editStoreEntry('minimumOrders', e)}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const { updateWaitingTimePerformanceGridEntries } = this.props;

		const value = e.target.value;

		const updateObject = {
			[name]: value,
		};

		updateWaitingTimePerformanceGridEntries(updateObject);
	}

	fetchData(state) {
		const { waitingTimePerformanceGridId } = this.props;

		const filter = `:waiting_time_performance_grid.id=='${waitingTimePerformanceGridId}'`;

		return fetchWaitingTimePerformanceGridEntries(state, filter);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	addWaitingTimePerformanceGridEntriesWrapper(data) {
		return addWaitingTimePerformanceGridEntries(data).then(() =>
			this.setState(() => ({
				key: moment.utc(),
			}))
		);
	}

	render() {
		const {
			batchList,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
			waitingTimePerformanceGridId,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={reduxKey}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					accessAreasAllowedToEdit={['Global Sales Configuration', 'Sales Configuration']}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					actionRender={
						<Button
							type="inverted"
							label="Batch"
							shadow
							onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
				/>

				<WaitingTimePerformanceGridsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addWaitingTimePerformanceGridEntries={
						this.addWaitingTimePerformanceGridEntriesWrapper
					}
					waitingTimePerformanceGridId={waitingTimePerformanceGridId}
				/>
			</>
		);
	}
}

WaitingTimePerformanceGridEntries.propTypes = {
	waitingTimePerformanceGridEntries: PropTypes.object,
	updateWaitingTimePerformanceGridEntries: PropTypes.func,
	setWaitingTimePerformanceGridEntries: PropTypes.func,
	resetState: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	defaultWaitingTimePerformanceGridEntries: PropTypes.object,
	waitingTimePerformanceGridId: PropTypes.number,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			editBatchListItem,
			removeBatchListItem,
			updateWaitingTimePerformanceGridEntries,
			setWaitingTimePerformanceGridEntries,
			resetState,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		waitingTimePerformanceGridEntries:
			store.globalAdministrationWaitingTimePerformanceGridEntries.data
				.waitingTimePerformanceGridEntries,
		defaultWaitingTimePerformanceGridEntries:
			store.globalAdministrationWaitingTimePerformanceGridEntries.data
				.defaultWaitingTimePerformanceGridEntries,
		batchList:
			store.globalAdministrationWaitingTimePerformanceGridEntries.data
				.batchList,
	};
};

export default connectWithStore(
	WaitingTimePerformanceGridEntries,
	mapStateToProps,
	mapDispatchToProps
);
