import React, { Component } from 'react';
import PropTypes from 'prop-types';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import { batch } from 'react-redux';
// actions
import {
	updateShiftAndPendingTransfer,
	updateShiftAndPendingSwap,
	setShiftTransferApplication,
	setShowModal,
	setShowPoliciesModal,
	setTransferDataToSubmit,
	resetTransferDataToSubmit,
	setShiftTransferPolicy,
	setShowPendingTransfersModal,
} from './store/shiftTransferApproval.actions';

// components
import ShiftTransferApprovalMobileView from './components/shiftTransferApprovalMobileView/shiftTransferApprovalMobileView';
import ShiftTransferApprovalDesktopView from './components/shiftTransferApprovalDesktopView/shiftTransferApprovalDesktopView';
import ShiftTransferPoliciesModal from './components/shiftTransferPoliciesModal/shiftTransferPoliciesModal';
import { confirmModal } from 'utils';

// service
import {
	editShiftTransfer,
	editShiftTransferApplication,
	getShiftTransferPolicies,
	fetchPotentialWarnings,
} from './shiftTransferApproval.service';

// utils
import { isMobile } from 'detectMobile.vanilla';
import { validAccess } from 'accessControl';
import {
	formatShiftTransfers,
	getMarketIdFromShiftTransfers,
	getSwapApplicationPayload,
	getUpdateShiftTransferPayload,
} from './utils';
import phrases from './shiftTransferApproval.phrases';
import { ShiftTransfer as enums } from 'services/enums';

// lodash
import _isEmpty from 'lodash/isEmpty';

// style
import './shiftTransferApproval.css';

class ShiftDeviationApproval extends Component {
	constructor(props) {
		super(props);

		this.isMobile = isMobile();

		this._toggleModal = this._toggleModal.bind(this);
		this.renderDesktopView = this.renderDesktopView.bind(this);
		this.renderMobileView = this.renderMobileView.bind(this);
		this.updateShiftTransferApplication =
			this.updateShiftTransferApplication.bind(this);
		this.updateShiftTransfer = this.updateShiftTransfer.bind(this);
		this.setShowPoliciesModalWrapper =
			this.setShowPoliciesModalWrapper.bind(this);
		this.resetShowPoliciesModalWrapper =
			this.resetShowPoliciesModalWrapper.bind(this);
		this.handleUpdateShiftTransfer = this.handleUpdateShiftTransfer.bind(this);
		this.handleShowOvertimeWarnings =
			this.handleShowOvertimeWarnings.bind(this);
		this.handleUpdateShiftTransferApplication =
			this.handleUpdateShiftTransferApplication.bind(this);
	}

	componentDidUpdate() {
		const {
			showModal,
			shiftTransferPolicy,
			swapTransfers,
			shiftTransfers,
			setShiftTransferPolicy,
		} = this.props;

		// when shift transfers and swaps are laoded, fetch policies
		if (showModal && _isEmpty(shiftTransferPolicy)) {
			const marketId = getMarketIdFromShiftTransfers({
				swapTransfers: swapTransfers,
				shiftTransfers: shiftTransfers,
			});

			getShiftTransferPolicies({ marketId }).then((res) =>
				setShiftTransferPolicy(res.data[0])
			);
		}
	}

	_toggleModal() {
		const { setShowModal, setShowPendingTransfersModal } = this.props;

		setShowModal(false);
		setShowPendingTransfersModal(false);
	}

	// when accepting/declining as a bar manager
	updateShiftTransfer({ shiftTransfer, operation, application }) {
		const {
			updateShiftTransferPayload,
			potentialWarningsPayload,
			approvingApplication,
		} = getUpdateShiftTransferPayload({
			shiftTransfer,
			operation,
			application,
		});

		// if we're approving application, make sure we display any warnings to the BM
		// things like - juicer already has 5 shifts per week, he worked over 40 hours...
		const functionToExecute = approvingApplication
			? () => fetchPotentialWarnings(potentialWarningsPayload)
			: () => Promise.resolve(true);

		return functionToExecute()
			.then((res) => {
				// check if we need to show warnings
				if (approvingApplication && !_isEmpty(res?.data)) {
					return this.handleShowOvertimeWarnings({
						shiftWarnings: res,
						shiftTransfer,
						updateShiftTransferPayload,
						approvingApplication,
						potentialWarningsPayload,
					});
				}

				return this.handleUpdateShiftTransfer({
					data: updateShiftTransferPayload,
					approvingApplication,
				});
			})
			.catch((err) => console.error(err.message));
	}

	handleShowOvertimeWarnings({
		shiftWarnings,
		updateShiftTransferPayload,
		approvingApplication,
	}) {
		const warningMessages = shiftWarnings.data.map((entry) => entry.message);

		// I love JS - kinda scary tho
		return new Promise((resolve, reject) => {
			confirmModal.show({
				data: {
					header: phrases.OVERTIME_WARNINGS_MODAL_HEADER,
					subheader: phrases.OVERTIME_WARNINGS_MODAL_SUBHEADER,
					messages: warningMessages,
					confirmLabel: phrases.OVERTIME_WARNINGS_MODAL_CONFIRM_LABEL,
				},
				onConfirm: () => {
					this.handleUpdateShiftTransfer({
						data: updateShiftTransferPayload,
						approvingApplication,
					}).then(() => {
						resolve(true);
					});
				},
				onCancel: () => {
					resolve(true);
				},
			});
		});
	}

	handleUpdateShiftTransfer(payload) {
		const { updateShiftAndPendingTransfer } = this.props;

		return editShiftTransfer(payload)
			.then((res) => {
				updateShiftAndPendingTransfer(res.data[0]);
			})
			.catch((err) => console.error(err.message));
	}

	handleUpdateShiftTransferApplication(data) {
		// if declining an operation, proceed to edit
		if (data.operation === enums.OPERATIONS.DECLINE)
			return this.updateShiftTransferApplication(data);
		// show policy modal before action
		else return this.setShowPoliciesModalWrapper(data);
	}

	// when accepting/declining own shift swaps
	updateShiftTransferApplication(payload) {
		const {
			updateShiftAndPendingSwap,
			transferDataToSubmit,
			shiftTransferPolicy,
			user,
		} = this.props;

		// if declining a swap we directly call this function so no need
		// to fetch the data from redux
		const dataToSubmit = payload || transferDataToSubmit;

		const data = getSwapApplicationPayload({
			transferDataToSubmit: dataToSubmit,
			shiftTransferPolicy,
			user,
		});

		return editShiftTransferApplication(data)
			.then((res) => {
				// format first then redux
				const formatted = formatShiftTransfers({
					data: res.data,
				})[0];

				updateShiftAndPendingSwap(formatted);
			})
			.catch((err) => {
				throw err;
			});
	}

	renderDesktopView() {
		const {
			shiftTransfers,
			swapTransfers,
			showPendingTransfersModal,
			pendingTransfers,
			user,
		} = this.props;

		return (
			<ShiftTransferApprovalDesktopView
				swapTransfers={swapTransfers}
				shiftTransfers={shiftTransfers}
				onClose={this._toggleModal}
				updateShiftTransfer={this.updateShiftTransfer}
				handleUpdateShiftTransferApplication={
					this.handleUpdateShiftTransferApplication
				}
				showPendingTransfersModal={showPendingTransfersModal}
				pendingTransfers={pendingTransfers}
				user={user}
			/>
		);
	}

	renderMobileView() {
		const {
			shiftTransfers,
			swapTransfers,
			setShiftTransferApplication,
			applicationDecisions,
			pendingTransfers,
			user,
			showPendingTransfersModal,
		} = this.props;

		return (
			<ShiftTransferApprovalMobileView
				shiftTransfers={shiftTransfers}
				swapTransfers={swapTransfers}
				updateShiftTransfer={this.updateShiftTransfer}
				onClose={this._toggleModal}
				setShiftTransferApplication={setShiftTransferApplication}
				applicationDecisions={applicationDecisions}
				handleUpdateShiftTransferApplication={
					this.handleUpdateShiftTransferApplication
				}
				showPendingTransfersModal={showPendingTransfersModal}
				pendingTransfers={pendingTransfers}
				user={user}
			/>
		);
	}

	_getUserMoneyball() {
		const shiftplanAccess = validAccess(undefined, ['Shiftplan View']);
		const shiftplanManagement = validAccess(undefined, ['Shiftplan Manager']);

		const userJuicer = shiftplanAccess && !shiftplanManagement;
		const userManager = shiftplanManagement;

		return { userJuicer, userManager };
	}

	setShowPoliciesModalWrapper(dataToSubmit) {
		const {
			setShowPoliciesModal,
			setTransferDataToSubmit,
			setShiftTransferPolicy,
			user,
		} = this.props;

		batch(() => {
			// if previously applied, show the previously accepted policy
			if (dataToSubmit.applied) {
				const personId = user?.user?.person?.id;

				// find the application we are applying for
				const application = dataToSubmit.shiftTransfer.applications.find(
					(entry) => entry.applicant?.person?.id === personId
				);

				const policyUuid = application.applicant_accepted_policy;

				setShiftTransferPolicy(policyUuid);
			}

			setTransferDataToSubmit(dataToSubmit);
			setShowPoliciesModal(true);
		});
	}

	resetShowPoliciesModalWrapper() {
		const { setShowPoliciesModal, resetTransferDataToSubmit } = this.props;

		batch(() => {
			resetTransferDataToSubmit();
			setShowPoliciesModal(false);
		});
	}

	render() {
		const {
			showModal,
			shiftTransfers,
			swapTransfers,
			showPoliciesModal,
			shiftTransferPolicy,
			pendingTransfers,
			showPendingTransfersModal,
		} = this.props;

		if (!showModal && !showPendingTransfersModal) return null;

		if (
			_isEmpty(shiftTransfers) &&
			_isEmpty(swapTransfers) &&
			_isEmpty(pendingTransfers)
		)
			return null;

		return (
			<>
				{this.isMobile ? this.renderMobileView() : this.renderDesktopView()}
				{showPoliciesModal && (
					<ShiftTransferPoliciesModal
						onClose={this.resetShowPoliciesModalWrapper}
						shiftTransferPolicy={shiftTransferPolicy}
						onAccept={this.updateShiftTransferApplication}
					/>
				)}
			</>
		);
	}
}

ShiftDeviationApproval.propTypes = {
	updateShiftAndPendingTransfer: PropTypes.func,
	shiftTransfers: PropTypes.array,
	swapTransfers: PropTypes.array,
	showModal: PropTypes.bool,
	setShiftTransferApplication: PropTypes.func,
	setShowModal: PropTypes.func,
	applicationDecisions: PropTypes.array,
	updateShiftAndPendingSwap: PropTypes.func,
	showPoliciesModal: PropTypes.bool,
	setShowPoliciesModal: PropTypes.func,
	transferDataToSubmit: PropTypes.object,
	setTransferDataToSubmit: PropTypes.func,
	resetTransferDataToSubmit: PropTypes.func,
	shiftTransferPolicy: PropTypes.object,
	setShiftTransferPolicy: PropTypes.func,
	pendingTransfers: PropTypes.array,
	showPendingTransfersModal: PropTypes.bool,
	setShowPendingTransfersModal: PropTypes.func,
	user: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateShiftAndPendingTransfer,
			setShiftTransferApplication,
			setShowModal,
			updateShiftAndPendingSwap,
			setShowPoliciesModal,
			setTransferDataToSubmit,
			resetTransferDataToSubmit,
			setShiftTransferPolicy,
			setShowPendingTransfersModal,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		shiftTransfers: store.shiftTransferApproval.shiftTransfers,
		swapTransfers: store.shiftTransferApproval.swapTransfers,
		applicationDecisions: store.shiftTransferApproval.applicationDecisions,
		showModal: store.shiftTransferApproval.showModal,
		showPoliciesModal: store.shiftTransferApproval.showPoliciesModal,
		transferDataToSubmit: store.shiftTransferApproval.transferDataToSubmit,
		shiftTransferPolicy: store.shiftTransferApproval.shiftTransferPolicy,
		user: store.userData?.user ?? {},
		pendingTransfers: store.shiftTransferApproval.pendingTransfers,
		showPendingTransfersModal:
			store.shiftTransferApproval.showPendingTransfersModal,
	};
};

export default connectWithStore(
	ShiftDeviationApproval,
	mapStateToProps,
	mapDispatchToProps
);
