export const SET_INGREDIENT_VARIANT_OPTIONS =
	'SALES_CONFIGURATION_INGREDIENT_VARIANT_OPTIONS/SET_INGREDIENT_VARIANT_OPTIONS';
export const UPDATE_INGREDIENT_VARIANT_OPTIONS =
	'SALES_CONFIGURATION_INGREDIENT_VARIANT_OPTIONS/UPDATE_INGREDIENT_VARIANT_OPTIONS';
export const RESET_STATE =
	'SALES_CONFIGURATION_INGREDIENT_VARIANT_OPTIONS/RESET_STATE';

export function setIngredientVariantOptions(payload) {
	return {
		type: SET_INGREDIENT_VARIANT_OPTIONS,
		payload
	};
}

export function updateIngredientVariantOptions(payload) {
	return {
		type: UPDATE_INGREDIENT_VARIANT_OPTIONS,
		payload
	};
}

export function resetState() {
	return {
		type: RESET_STATE
	};
}
