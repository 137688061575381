export const enums = {
	CATEGORY_GROUP: [
		'Juice',
		'Shake',
		'Coffee',
		'Sandwich',
		'Salad',
		'Breakfast',
		'Loyalty card',
		'Misc'
	]
};
