import {
	SET_MODAL_DATA,
	UPDATE_SHIFT_AND_PENDING_TRANSFER,
	RESET_MODAL_DATA,
	SET_SHOW_MODAL,
	SET_APPLICATION_DECISION,
	UPDATE_SHIFT_AND_PENDING_SWAP,
	SET_SHOW_POLICIES_MODAL,
	SET_TRANSFER_DATA_TO_SUBMIT,
	RESET_TRANSFER_DATA_TO_SUBMIT,
	SET_SHIFT_TRANSFER_POLICY,
	SET_SHOW_PENDING_TRANSFERS_MODAL,
	SET_PENDING_SHIFT_TRANSFERS,
	SET_SHIFT_TRANSFERS,
} from './shiftTransferApproval.actions';

const defaultState = {
	shiftTransfers: null,
	swapTransfers: null,
	pendingTransfers: null,
	showModal: false,
	showPendingTransfersModal: false,
	showPoliciesModal: false,
	transferDataToSubmit: {},
	shiftTransferPolicy: {},
	applicationDecisions: [],
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_MODAL_DATA:
			return {
				...state,
				shiftTransfers: payload.shiftTransfers,
				swapTransfers: payload.swapTransfers,
				pendingTransfers: payload.pendingTransfers,
			};

		case UPDATE_SHIFT_AND_PENDING_TRANSFER:
			return {
				...state,
				shiftTransfers: state.shiftTransfers.map((entry) => {
					if (entry.id === payload.id) return payload;

					return entry;
				}),
				pendingTransfers: state.pendingTransfers.map((entry) => {
					if (entry.id === payload.id) return payload;

					return entry;
				}),
			};

		case UPDATE_SHIFT_AND_PENDING_SWAP:
			return {
				...state,
				swapTransfers: state.swapTransfers.map((entry) => {
					if (entry.id === payload.id) return payload;

					return entry;
				}),
				pendingTransfers: state.pendingTransfers.map((entry) => {
					if (entry.id === payload.id) return payload;

					return entry;
				}),
			};

		case RESET_MODAL_DATA:
			return {
				...state,
				showModal: false,
			};
		// return defaultState;

		case SET_SHOW_MODAL:
			return {
				...state,
				showModal: payload,
			};

		case SET_APPLICATION_DECISION:
			return {
				...state,
				applicationDecisions: payload,
			};

		case SET_SHOW_POLICIES_MODAL:
			return {
				...state,
				showPoliciesModal: payload,
			};

		case SET_TRANSFER_DATA_TO_SUBMIT:
			return {
				...state,
				transferDataToSubmit: payload,
			};

		case RESET_TRANSFER_DATA_TO_SUBMIT:
			return {
				...state,
				transferDataToSubmit: defaultState.transferDataToSubmit,
			};

		case SET_SHIFT_TRANSFER_POLICY:
			return {
				...state,
				shiftTransferPolicy: payload,
			};

		case SET_SHOW_PENDING_TRANSFERS_MODAL:
			return {
				...state,
				showPendingTransfersModal: payload,
			};

		case SET_PENDING_SHIFT_TRANSFERS:
			return {
				...state,
				pendingTransfers: payload,
			};

		case SET_SHIFT_TRANSFERS:
			return {
				...state,
				shiftTransfers: payload,
			};

		default:
			return state;
	}
}

export default reducer;
