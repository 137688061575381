'use strict';

import { store } from 'appState';
import moment from 'moment';
import { setFileList, setLoading } from 'fileList/fileList.actions';
import phrases from 'fileList/fileList.phrases';

/**
 * FileList
 * @param {string} name
 * @param {object} dataProvider
 */
class FileList {
	constructor(name, dataProvider, canDelete = true, canDownload = true) {
		// Set type
		this.type = 'fileList';

		// Name
		this.name = name;

		// Permissions
		this.setCanDelete(canDelete);
		this.setCanDownload(canDownload);

		// Templates
		this.typeTemplate = null;
		this.dateTemplate = moment();
		this.fileNameTemplate = null;
		this.setDataProvider(dataProvider);
	}

	/// //////////
	// Setters //
	/// //////////

	/**
	 * @function setCanDownload
	 * @memberOf FileList
	 * @description Setter for canDownload
	 */
	setCanDownload(canDownload) {
		this.canDownload = canDownload;
	}

	/**
	 * @function setCanDelete
	 * @memberOf FileList
	 * @description Setter for canDelete
	 */
	setCanDelete(canDelete) {
		this.canDelete = canDelete;
	}

	/**
	 * @function setDataProvider
	 * @memberOf FileList
	 * @description Setter for dataProvider
	 */
	setDataProvider(dataProvider) {
		this.dataProvider = dataProvider;
	}

	/**
	 * @function setTypeTemplate
	 * @memberOf FileList
	 * @description Setter for typeTemplate
	 */
	setTypeTemplate(typeTemplate) {
		this.typeTemplate = typeTemplate;
	}

	/**
	 * @function setDateTemplate
	 * @memberOf FileList
	 * @description Setter for dateTemplate
	 */
	setDateTemplate(dateTemplate) {
		this.dateTemplate = dateTemplate;
	}

	/**
	 * @function setFileNameTemplate
	 * @memberOf FileList
	 * @description Setter for fileNameTemplate
	 */
	setFileNameTemplate(fileNameTemplate) {
		this.fileNameTemplate = fileNameTemplate;
	}

	/// //////////
	// Getters //
	/// //////////

	/**
	 * @function getTypeTemplate
	 * @memberOf FileList
	 * @description Getter for typeTemplate
	 */
	getTypeTemplate(file) {
		return this.typeTemplate !== null ? this.typeTemplate(file) : null;
	}

	/**
	 * @function getDateTemplate
	 * @memberOf FileList
	 * @description Getter for dateTemplate
	 */
	getDateTemplate(file) {
		return this.dateTemplate !== null
			? this.dateTemplate(file).format('DD-MM-YYYY HH:mm:ss')
			: null;
	}

	/**
	 * @function getFileNameTemplate
	 * @memberOf FileList
	 * @description Getter for fileNameTemplate
	 */
	getFileNameTemplate(file) {
		return this.fileNameTemplate !== null ? this.fileNameTemplate(file) : null;
	}

	/**
	 * @function getCanDownload
	 * @memberOf FileList
	 * @description Getter for canDownload
	 */
	getCanDownload() {
		return this.canDownload;
	}

	/**
	 * @function getCanDelete
	 * @memberOf FileList
	 * @description Getter for canDelete
	 */
	getCanDelete() {
		return this.canDelete;
	}

	/**
	 * @function getPhrases
	 * @memberOf FileList
	 * @description Getter for phrases
	 */
	getPhrases() {
		return phrases;
	}

	/// /////////////////
	// Event handlers //
	/// /////////////////

	/**
	 * @function handleDeleteFile
	 * @memberOf FileList
	 * @description Handler for deleting a file
	 * @param {object} file
	 */
	handleDeleteFile(file) {
		// Initiate loading
		store.dispatch(setLoading(this.name, true));

		// Set retquest to delete file
		this.dataProvider.deleteFile(file).then(() => {
			// Stop loading
			store.dispatch(setLoading(this.name, false));

			// Update
			this.handleUpdateFileList();
		});
	}

	/**
	 * @function handleUpdateFileList
	 * @memberOf FileList
	 * @description Handler for updating entire list
	 * @param {object} event
	 */
	handleUpdateFileList() {
		// Initiate loading
		store.dispatch(setLoading(this.name, true));

		// Get file list from dataprovider
		this.dataProvider.getData().then(
			fileList => {
				// Dispatch file list to store
				store.dispatch(setFileList(this.name, fileList));

				// Stop loading
				store.dispatch(setLoading(this.name, false));
			},
			() => {}
		);
	}

	/// ////////////
	// Utilities //
	/// ////////////

	/**
	 * @function setFilter
	 * @memberOf FileList
	 * @description Sets filter on dataprovider
	 * @param {object} event
	 */
	setFilter(filter) {
		this.dataProvider.setFilter(filter);
	}
}

export default FileList;
