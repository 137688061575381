export const SET_ADDON_INGREDIENT =
	'SALES_CONFIGURATION_ADDON_RECOMMENDATIONS_INGREDIENTS/SET_ADDON_INGREDIENT';
export const UPDATE_ADDON_INGREDIENT =
	'SALES_CONFIGURATION_ADDON_RECOMMENDATIONS_INGREDIENTS/UPDATE_ADDON_INGREDIENT';
export const RESET_STATE =
	'SALES_CONFIGURATION_ADDON_RECOMMENDATIONS_INGREDIENTS/RESET_STATE';

/**
 * @param {object} payload
 */
export function setAddonIngredient(payload) {
	return {
		type: SET_ADDON_INGREDIENT,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateAddonIngredient(payload) {
	return {
		type: UPDATE_ADDON_INGREDIENT,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET_STATE
	};
}
