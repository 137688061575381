'use strict';

import {
	SET_POWER_BI_REPORT,
	UPDATE_POWER_BI_REPORT,
	RESET_POWER_BI_REPORT,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	ADD_BATCH_LIST_ITEM_ROW,
	SET_SELECTED_POWER_BI_REPORTS,
	EDIT_GLOBAL_COPY_REPORT_VALUE,
	EDIT_SELECTED_POWER_BI_REPORT,
	EDIT_MARKETS,
} from './powerBIReports.actions';

// utils
import _uniqueId from 'lodash/uniqueId';

const defaultState = {
	data: {
		powerBIReport: {},
		originalPowerBIReport: {},
		copyPowerBiReport: {
			// used to overwrite selected reports
			globalPowerBiReport: {
				name: '',
				description: '',
				type: null,
				market: null,
				sortOrder: null,
				powerBiId: '',
				accessGrantingArea: null,
			},
			// these are the values we will possibly edit
			selectedReports: [],
			// these are the original values we will use to determine what values were edited
			originalSelectedReports: [],
			// first step where we select global markets
			markets: [],
		},
		batchList: [
			{
				identifier: _uniqueId('row-'),
				name: '',
				description: '',
				sortOrder: null,
				powerBIReportId: '',
				accessGrantingArea: null,
			},
		],
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case EDIT_MARKETS:
			return {
				...state,
				data: {
					...state.data,
					copyPowerBiReport: {
						...state.data.copyPowerBiReport,
						markets: payload,
					},
				},
			};

		case SET_SELECTED_POWER_BI_REPORTS:
			return {
				...state,
				data: {
					...state.data,
					copyPowerBiReport: {
						...state.data.copyPowerBiReport,
						selectedReports: payload,
						originalSelectedReports: payload,
					},
				},
			};

		case EDIT_GLOBAL_COPY_REPORT_VALUE:
			return {
				...state,
				data: {
					...state.data,
					copyPowerBiReport: {
						...state.data.copyPowerBiReport,
						globalPowerBiReport: {
							...state.data.copyPowerBiReport.globalPowerBiReport,
							...payload,
						},
						selectedReports: state.data.copyPowerBiReport.selectedReports.map((entry) => {
							return {
								...entry,
								...payload,
							};
						}),
					},
				},
			};

		case EDIT_SELECTED_POWER_BI_REPORT:
			return {
				...state,
				data: {
					...state.data,
					copyPowerBiReport: {
						...state.data.copyPowerBiReport,
						selectedReports: state.data.copyPowerBiReport.selectedReports.map((entry) => {
							if (entry.id === payload.id)
								return {
									...entry,
									...payload,
								};

							return entry;
						}),
					},
				},
			};

		case SET_POWER_BI_REPORT: {
			return {
				...state,
				data: {
					...state.data,
					powerBIReport: payload,
					originalPowerBIReport: payload,
				},
			};
		}

		case UPDATE_POWER_BI_REPORT: {
			return {
				...state,
				data: {
					...state.data,
					powerBIReport: {
						...state.data.powerBIReport,
						...payload,
					},
				},
			};
		}

		case RESET_POWER_BI_REPORT: {
			return defaultState;
		}

		case EDIT_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchList: state.data.batchList.map((entry) => {
						if (entry.identifier === payload.identifier) return payload;
						return entry;
					}),
				},
			};
		}

		case REMOVE_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchList: state.data.batchList.filter((entry) => entry.identifier !== payload),
				},
			};
		}

		case ADD_BATCH_LIST_ITEM_ROW: {
			return {
				...state,
				data: {
					...state.data,
					batchList: [
						...state.data.batchList,
						{
							...defaultState.data.batchList[0],
							identifier: _uniqueId('row-'),
						},
					],
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
