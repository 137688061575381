export default [{
	value: 'Juicer',
	label: 'Juicer'
}, {
	value: 'Bar Manager',
	label: 'Bar Manager'
}, {
	value: 'Regional Manager',
	label: 'Regional Manager'
}, {
	value: 'HR Manager',
	label: 'HR Manager'
}, {
	value: 'HQ HR Manager',
	label: 'HQ HR Manager'
}, {
	value: 'Finance',
	label: 'Finance'
}, {
	value: 'Retail',
	label: 'Retail'
}, {
	value: 'GM',
	label: 'GM'
}, {
	value: 'Operational Manager',
	label: 'Operational Manager'
}, {
	value: 'Shiftplan',
	label: 'Shiftplan'
}, {
	value: 'Admin',
	label: 'Admin'
}];
