import React from 'react';
import { Input } from 'dumb';
import _get from 'lodash/get';

export default [
	{
		Header: 'Person id',
		id: 'person_ID',
		accessor: 'person.id',
		width: 65,
		filterPath: ':person.id',
	},
	{
		Header: 'Name',
		id: 'name',
		accessor: (d) => d.person?.identity?.full_name ?? '',
		filterPath: ':person.identity.full_name',
	},
	{
		Header: 'Badge',
		width: 75,
		id: 'badge',
		accessor: (d) => _get(d, 'current_moneyball_sub_position.badge', null),
		filterPath: ':current_moneyball_sub_position.badge',
	},
	{
		Header: 'Type',
		id: 'type',
		show: false,
		accessor: (d) => _get(d, 'current_employment_position.employment_type.type'),
		filterPath: ':current_employment_position.employment_type.type',
	},
	{
		Header: 'Workplace',
		id: 'workplace',
		accessor: (d) => _get(d, 'workplace.name', null),
		filterPath: ':workplace.name',
	},

	{
		Header: 'Email',
		id: 'email',
		accessor: (d) => _get(d, 'person.person_email_address.email_address', null),
		Cell: (val) => <a href={`mailto:${val.value}`}>{val.value}</a>,
		filterPath: ':person.person_email_address.email_address',
	},
	{
		Header: 'Phone Number',
		id: 'phoneNumber',
		accessor: (d) => _get(d, 'person.person_phone_number.phone_number', null),
		width: 100,
		Cell: (val) => <span>{val.value}</span>,
		filterPath: ':person.person_phone_number.phone_number',
	},
	{
		Header: 'Birthday',
		width: 90,
		id: 'bday',
		accessor: (d) => _get(d, 'person.identity.birth_date', null),
		filterPath: ':person.identity.birth_date',
	},
	{
		Header: 'Company',
		id: 'company',
		accessor: (d) => _get(d, 'company.name', null),
		filterPath: ':company.name',
	},
	{
		Header: 'Market',
		width: 100,
		id: 'market',
		accessor: (d) => _get(d, 'person.identity.market.name', null),
		filterPath: ':person.identity.market.name',
	},
	{
		Header: 'Termination',
		id: 'astermination',
		show: false,
		accessor: (d) => _get(d, 'termination.termination_reason', {}),
		filterPath: ':termination.termination_reason.description',
		Cell: (d) => {
			return d.value.description && d.value.type ? <span>{`${d.value.description} (${d.value.type})`}</span> : null;
		},
	},
	{
		Header: 'Started',
		width: 70,
		id: 'started',
		accessor: (d) => _get(d, 'started', null),
		filterPath: ':started',
	},
	{
		Header: 'Ended',
		width: 70,
		id: 'ended',
		accessor: (d) => _get(d, 'ended', null),
		filterPath: ':ended',
	},
	{
		Header: 'App allowance',
		id: 'app_allowance',
		accessor: (d) => _get(d, 'employee_meal_app_allowance.name', ''),
		filterPath: ':employee_meal_app_allowance.name',
	},
	{
		Header: 'Contract',
		width: 70,
		id: 'has_contract',
		accessor: (d) => _get(d, 'has_contract', null),
		Cell: (d) => <Input type="checkbox" checked={d.value} disabled />,
		filterPath: ':has_contract',
		filterable: false,
	},
	{
		Header: 'Employment position contract',
		id: 'employment_position_contract',
		minWidth: 125,
		accessor: (d) => _get(d, 'current_employment_position.has_contract', null),
		Cell: (d) => (
			<Input type="checkbox" checked={_get(d, 'original.current_employment_position.has_contract', false)} disabled />
		),
		filterPath: ':current_employment_position.has_contract',
		filterable: false,
	},
	{
		Header: 'C.O.C.',
		width: 50,
		id: 'has_code_of_conduct',
		accessor: (d) => _get(d, 'has_code_of_conduct', null),
		Cell: (d) => <Input type="checkbox" checked={d.value} disabled />,
		filterPath: ':has_code_of_conduct',
		filterable: false,
	},
	{
		Header: 'Resignation',
		width: 70,
		id: 'has_resignation',
		accessor: (d) => _get(d, 'has_resignation', null),
		Cell: (d) => <Input type="checkbox" checked={d.value} disabled />,
		filterPath: ':has_resignation',
		filterable: false,
	},
	{
		Header: 'Payroll',
		width: 55,
		id: 'include_in_salary_export',
		accessor: (d) => _get(d, 'include_in_salary_export', null),
		Cell: (d) => <Input type="checkbox" checked={d.value} disabled />,
		filterPath: ':include_in_salary_export',
		filterable: false,
	},
	{
		Header: 'Has foodpermit',
		width: 100,
		id: 'has_food_permit',
		accessor: 'has_food_permit',
		Cell: (d) => <Input type="checkbox" checked={d.value} disabled />,
		filterPath: ':has_food_permit',
		filterable: false,
	},
];
