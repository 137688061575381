'use strict';

var shared = {
	ABOUT: {
		TITLE: 'About Workplanner'
	},
	SIDEBAR_LOGOUT: 'LOG OUT',
	WORKPLANNER_VERSION: 'Workplanner v.',
	SITE_TITLE: 'Workplanner - Joe & The Juice'
};

module.exports = shared;