/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';

// lodash
import _get from 'lodash/get';

// components
import { Tag, Tooltip } from 'dumb';
import ManagedClockinsActions from './components/managedClockinsActions';
import ManagedClockinsTimeCell from './components/managedClockinsTimeCell';
import ManagedClockinsFinalCell from './components/managedClockinsFinalCell';
import ClockinDeviationCell from './components/clockinDeviation';
import BreakDateAdjustmentCell from './components/breakDateAdjustmentCell';

// constants/ phrases
import constants from 'services/constants';
import { getDate, getClockinWarningColor, getBreakWarningColor } from './utils';
import enums from './shiftClockinsTableColumns.enums';

// moment
import moment from 'moment';

const ShiftClockinsTableColumns = ({
	onManagedClockinNoShowCellClick,
	postManagedClockinCorrection,
	reduxKey,
	breakRegistrationPossible,
	onManagedClockinAddBreakClick,
	setBreaksAsNoShow,
	editClockedValue,
}) => {
	return [
		{
			Header: 'Date',
			columns: [
				{
					Header: 'Date',
					id: 'date',
					accessor: 'date',
					filterable: false,
					sortable: false,
					width: 65,
					Cell: (d) => {
						const date = moment
							.utc(d.value, constants.shortDate)
							.format(
								`${constants.nameOfWeekdayShort} ${constants.dayMonthFormat}`
							);

						return date;
					},
				},
			],
		},
		{
			Header: 'Workplace',
			columns: [
				{
					Header: 'Workplace',
					id: 'workplace',
					accessor: (d) => _get(d, 'workplace.name', ''),
					filterable: false,
					sortable: false,
					width: 150,
				},
			],
		},
		{
			Header: 'Employee',
			columns: [
				{
					Header: 'Name',
					id: 'employee-name',
					accessor: (d) => _get(d, 'employment.person.identity.full_name', ''),
					filterable: false,
					width: 130,
					sortable: false,
					Cell: (d) => {
						const id = d.original?.employment?.person?.id;
						const fullName = d.original?.employment?.person?.full_name;

						return <span>{`(${id}) ${fullName}`}</span>;
					},
				},
			],
		},
		{
			Header: 'Shift start',
			columns: [
				{
					Header: 'Planned',
					accessor: 'planned',
					filterable: false,
					sortable: false,
					width: 70,
					id: 'planned-from',
					Cell: (d) => (
						<ManagedClockinsTimeCell
							rowId={d.original.id}
							value={d.value?.from}
							reduxKey={reduxKey}
							update={(d, value) => ({
								...d,
								final: { ...d.final, from: value },
							})}
							row={d.original}
						/>
					),
				},
				{
					Header: 'Clocked',
					accessor: 'clocked',
					filterable: false,
					sortable: false,
					id: 'clocked-from',
					width: 70,
					Cell: (d) => (
						<ManagedClockinsTimeCell
							rowId={d.original.id}
							value={d.value?.from}
							textColor={getClockinWarningColor({
								planned: d.original?.planned.from,
								clocked: d.value?.from,
								type: enums.FROM,
							})}
							reduxKey={reduxKey}
							update={(d, value) => ({
								...d,
								final: { ...d.final, from: value },
							})}
							row={d.original}
						/>
					),
				},
				{
					Header: 'Final',
					filterable: false,
					sortable: false,
					id: 'final-from',
					width: 100,
					accessor: 'final',
					Cell: (d) => {
						const date = getDate({ type: 'from', entry: d.original });

						return (
							<ManagedClockinsFinalCell
								rowId={d.original.id}
								row={d.original}
								value={d.value?.from}
								date={date}
								reduxKey={reduxKey}
								update={(row, value) => ({
									...row,
									final: { ...row.final, from: value },
								})}
							/>
						);
					},
				},
				{
					Header: '',
					filterable: false,
					sortable: false,
					id: 'status-from',
					width: 48,
					accessor: 'clock_in_deviation',
					Cell: (d) => (
						<ClockinDeviationCell
							deviation={d.value}
							editClockedValue={editClockedValue}
							row={d.original}
							type="from"
						/>
					),
				},
			],
		},
		{
			Header: 'Shift end',
			columns: [
				{
					Header: 'Planned',
					accessor: 'planned',
					filterable: false,
					sortable: false,
					width: 70,
					id: 'planned-to',
					Cell: (d) => (
						<ManagedClockinsTimeCell
							rowId={d.original.id}
							value={d.value?.to}
							reduxKey={reduxKey}
							update={(d, value) => ({
								...d,
								final: { ...d.final, to: value },
							})}
							row={d.original}
						/>
					),
				},
				{
					Header: 'Clocked',
					accessor: 'clocked',
					filterable: false,
					sortable: false,
					id: 'clocked-to',
					width: 70,
					Cell: (d) => (
						<ManagedClockinsTimeCell
							rowId={d.original.id}
							value={d.value?.to}
							textColor={getClockinWarningColor({
								planned: d.original?.planned.to,
								clocked: d.value?.to,
								type: enums.TO,
							})}
							reduxKey={reduxKey}
							update={(d, value) => ({
								...d,
								final: { ...d.final, to: value },
							})}
							row={d.original}
						/>
					),
				},
				{
					Header: 'Final',
					filterable: false,
					sortable: false,
					id: 'final-to',
					width: 100,
					accessor: 'final',
					Cell: (d) => {
						let date = getDate({ type: 'to', entry: d.original });

						// if date is infinite date, set it to start date
						if (date === '9999-12-31') {
							date = getDate({ type: 'from', entry: d.original });
						}

						const valueFrom = d.value?.from;
						const valueTo = d.value?.to;

						const dayDifference = moment
							.duration(
								moment
									.utc(valueTo, constants.dateFormat)
									.diff(moment.utc(valueFrom, constants.dateFormat))
							)
							.as('day');
						const roundedDayDifference = Math.round(Number(dayDifference));

						return (
							<ManagedClockinsFinalCell
								rowId={d.original.id}
								value={d.value?.to}
								date={date}
								reduxKey={reduxKey}
								update={(d, value) => ({
									...d,
									final: { ...d.final, to: value },
								})}
								row={d.original}
								dayDifference={roundedDayDifference}
							/>
						);
					},
				},
				{
					Header: '',
					filterable: false,
					sortable: false,
					id: 'status-to',
					width: 48,
					accessor: 'clock_out_deviation',
					Cell: (d) => (
						<ClockinDeviationCell
							deviation={d.value}
							editClockedValue={editClockedValue}
							row={d.original}
							type="to"
						/>
					),
				},
			],
		},
		...(breakRegistrationPossible
			? [
					{
						Header: 'Breaks from',
						columns: [
							{
								Header: 'Planned',
								filterable: false,
								sortable: false,
								id: 'breaks-planned-from',
								width: 70,
								accessor: 'breaks',
								Cell: (d) => {
									return d.value.map((entry) => (
										<div
											className="shift-clockins__table-wrapper__cell-wrapper"
											key={entry.id}
										>
											<ManagedClockinsTimeCell
												rowId={d.original.id}
												value={entry.planned?.from}
												row={d.original}
												reduxKey={reduxKey}
												update={(d, value) => ({
													...d,
													breaks: d.breaks.map((clockinBreak) => {
														if (clockinBreak.id === entry.id) {
															return {
																...clockinBreak,
																final: {
																	...clockinBreak.final,
																	from: value,
																},
															};
														}

														return clockinBreak;
													}),
												})}
												entry={d.original}
											/>
										</div>
									));
								},
							},
							{
								Header: 'Clocked',
								filterable: false,
								sortable: false,
								id: 'breaks-clocked-from',
								width: 70,
								accessor: 'breaks',
								Cell: (d) => {
									return d.value.map((entry) => (
										<div
											className="shift-clockins__table-wrapper__cell-wrapper"
											key={entry.id}
										>
											<ManagedClockinsTimeCell
												reduxKey={reduxKey}
												update={(d, value) => ({
													...d,
													breaks: d.breaks.map((clockinBreak) => {
														if (clockinBreak.id === entry.id) {
															return {
																...clockinBreak,
																final: {
																	...clockinBreak.final,
																	from: value,
																},
															};
														}

														return clockinBreak;
													}),
												})}
												row={d.original}
												rowId={d.original.id}
												value={entry.clocked?.from}
												textColor={getBreakWarningColor({
													breakPlanned: entry.planned,
													breakClocked: entry.clocked,
												})}
											/>
										</div>
									));
								},
							},
							{
								Header: 'Final',
								filterable: false,
								sortable: false,
								id: 'breaks-final-from',
								accessor: 'breaks',
								width: 100,
								Cell: (d) =>
									d.value.map((entry) => {
										// if break.from is later then shift end, flag it as it's wrong - probably due to break logging error or juicer forgetting to clock out or log the break
										const shiftFinalTo = d.original?.final?.to;
										const valueFrom = entry.final?.from;

										const momentBreakFrom = moment.utc(
											valueFrom,
											constants.dateFormat
										);
										const momentShiftEnd = moment.utc(
											shiftFinalTo,
											constants.dateFormat
										);

										let flagBreak = false;
										if (momentBreakFrom.isAfter(momentShiftEnd, 'minute'))
											flagBreak = true;

										return (
											<div
												className="shift-clockins__table-wrapper__cell-wrapper"
												key={entry.id}
											>
												<ManagedClockinsFinalCell
													reduxKey={reduxKey}
													rowId={d.original.id}
													value={valueFrom}
													date={getDate({ type: 'from', entry })}
													row={d.original}
													update={(d, value) => ({
														...d,
														breaks: d.breaks.map((clockinBreak) => {
															if (clockinBreak.id === entry.id) {
																return {
																	...clockinBreak,
																	final: {
																		...clockinBreak.final,
																		from: value,
																	},
																};
															}

															return clockinBreak;
														}),
													})}
													{...(flagBreak && {
														dayDifference: 1,
														dayDifferenceTooltip:
															'Break start is after shift end, go to menu to adjust',
													})}
												/>
											</div>
										);
									}),
							},
							{
								Header: '',
								filterable: false,
								sortable: false,
								id: 'status-break-from',
								width: 30,
								accessor: 'breaks',
								Cell: (d) =>
									d.value.map((entry, index) => (
										<BreakDateAdjustmentCell
											key={index}
											breakEntity={entry}
											row={d.original}
											type="from"
										/>
									)),
							},
						],
					},
					{
						Header: 'Breaks to',
						columns: [
							{
								Header: 'Planned',
								filterable: false,
								sortable: false,
								id: 'breaks-planned-to',
								accessor: 'breaks',
								width: 70,
								Cell: (d) => {
									return d.value.map((entry) => (
										<div
											className="shift-clockins__table-wrapper__cell-wrapper"
											key={entry.id}
										>
											<ManagedClockinsTimeCell
												rowId={d.original.id}
												value={entry.planned?.to}
												reduxKey={reduxKey}
												update={(d, value) => ({
													...d,
													breaks: d.breaks.map((clockinBreak) => {
														if (clockinBreak.id === entry.id) {
															return {
																...clockinBreak,
																final: {
																	...clockinBreak.final,
																	to: value,
																},
															};
														}

														return clockinBreak;
													}),
												})}
												row={d.original}
											/>
										</div>
									));
								},
							},
							{
								Header: 'Clocked',
								filterable: false,
								sortable: false,
								id: 'breaks-clocked-to',
								accessor: 'breaks',
								width: 70,
								Cell: (d) => {
									return d.value.map((entry) => (
										<div
											className="shift-clockins__table-wrapper__cell-wrapper"
											key={entry.id}
										>
											<ManagedClockinsTimeCell
												rowId={d.original.id}
												value={entry.clocked?.to}
												textColor={getBreakWarningColor({
													breakPlanned: entry.planned,
													breakClocked: entry.clocked,
												})}
												reduxKey={reduxKey}
												update={(d, value) => ({
													...d,
													breaks: d.breaks.map((clockinBreak) => {
														if (clockinBreak.id === entry.id) {
															return {
																...clockinBreak,
																final: {
																	...clockinBreak.final,
																	to: value,
																},
															};
														}

														return clockinBreak;
													}),
												})}
												row={d.original}
											/>
										</div>
									));
								},
							},
							{
								Header: 'Final',
								filterable: false,
								sortable: false,
								id: 'breaks-final-to',
								accessor: 'breaks',
								width: 100,
								Cell: (d) =>
									d.value.map((entry) => {
										let date = getDate({ type: 'to', entry });

										// if date is infinite date, set it to start date
										if (date === '9999-12-31') {
											date = getDate({ type: 'from', entry });
										}

										// if break.to is later then shift end, flag it as it's wrong - probably due to break logging error or juicer forgetting to clock out or log the break
										const shiftFinalTo = d.original?.final?.to;
										const valueTo = entry.final?.to;

										const momentBreakTo = moment.utc(
											valueTo,
											constants.dateFormat
										);
										const momentShiftEnd = moment.utc(
											shiftFinalTo,
											constants.dateFormat
										);

										let flagBreak = false;
										if (momentBreakTo.isAfter(momentShiftEnd, 'minute'))
											flagBreak = true;

										return (
											<div
												className="shift-clockins__table-wrapper__cell-wrapper"
												key={entry.id}
											>
												<ManagedClockinsFinalCell
													rowId={d.original.id}
													value={valueTo}
													date={date}
													reduxKey={reduxKey}
													update={(d, value) => ({
														...d,
														breaks: d.breaks.map((clockinBreak) => {
															if (clockinBreak.id === entry.id) {
																return {
																	...clockinBreak,
																	final: {
																		...clockinBreak.final,
																		to: value,
																	},
																};
															}

															return clockinBreak;
														}),
													})}
													row={d.original}
													{...(flagBreak && {
														dayDifference: 1,
														dayDifferenceTooltip:
															'Break end is after shift end, go to menu to adjust',
													})}
												/>
											</div>
										);
									}),
							},
							{
								Header: '',
								filterable: false,
								sortable: false,
								id: 'status-break-to',
								width: 30,
								accessor: 'breaks',
								Cell: (d) =>
									d.value.map((entry, index) => (
										<BreakDateAdjustmentCell
											key={index}
											breakEntity={entry}
											row={d.original}
											type="to"
										/>
									)),
							},
						],
					},
			  ]
			: []),
		{
			Header: 'Manage',
			columns: [
				{
					Header: 'Status',
					filterable: false,
					sortable: false,
					id: 'manageStatus',
					accessor: 'status',
					width: 50,
					Cell: (d) => {
						if (d.value !== enums.ALIGNED) return null;

						return (
							<Tooltip
								text={d.value}
								renderData={(ref, onMouseEnter, onMouseLeave) => (
									<Tag
										noBottomMargin
										type="bullet"
										color="8AD09E"
										refProp={ref}
										onMouseEnter={onMouseEnter}
										onMouseLeave={onMouseLeave}
									/>
								)}
							/>
						);
					},
				},
				{
					Header: 'Actions',
					filterable: false,
					sortable: false,
					id: 'manageAction',
					Cell: (d) => {
						return (
							<ManagedClockinsActions
								row={d.original}
								onManagedClockinNoShowCellClick={
									onManagedClockinNoShowCellClick
								}
								onManagedClockinAddBreakClick={onManagedClockinAddBreakClick}
								postManagedClockinCorrection={postManagedClockinCorrection}
								reduxKey={reduxKey}
								breakRegistrationPossible={breakRegistrationPossible}
								setBreaksAsNoShow={setBreaksAsNoShow}
							/>
						);
					},
				},
			],
		},
	];
};

ShiftClockinsTableColumns.propTypes = {
	onManagedClockinCorrectionCellClick: PropTypes.func,
	onManagedClockinNoShowCellClick: PropTypes.func,
	reduxKey: PropTypes.string,
	breakRegistrationPossible: PropTypes.bool,
	onManagedClockinAddBreakClick: PropTypes.func,
	setBreaksAsNoShow: PropTypes.func,
	editClockedValue: PropTypes.func,
};

export default ShiftClockinsTableColumns;
