import {
	SHOW_MODAL,
	SET_DATA,
	RESET_STATE,
	UPDATE_DATA,
} from './sendDocuSignDocument.actions';

const defaultState = {
	data: {},
	modalVisible: false,
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_DATA: {
			return {
				...state,
				data: {
					...state.data,
					...payload,
				},
			};
		}

		case SHOW_MODAL: {
			return {
				...state,
				modalVisible: payload,
			};
		}

		case RESET_STATE: {
			return {
				...defaultState,
			};
		}

		case UPDATE_DATA: {
			return {
				...state,
				data: {
					...state.data,
					...payload,
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
