export const RESET = 'PASSWORD/RESET';
export const SET_INPUT = 'PASSWORD/SET_INPUT';
export const SET_LOADER = 'PASSWORD/SET_LOADER';

/**
 * @param {object} payload
 */
export function setInputValue(payload) {
	return {
		type: SET_INPUT,
		payload
	};
}

/**
 * @param {object} payload
 */
export function setLoader(payload) {
	return {
		type: SET_LOADER,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET
	};
}
