'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// classnames
import cx from 'classnames';

import {
	SingleDatePickerInput,
	InputCollectionSelect,
	ButtonGroup,
	Button,
	Icon,
	Input,
} from 'dumb';
import { Tip } from 'dumb/tips';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// utils
import { getSelectedDate, getStateOptions } from './../utils';

// lodash
import _get from 'lodash/get';

// constants/phrases
import constants from 'services/constants';
import phrases from './../../../posConfigurationVersions.phrases';
import moment from 'moment';

class PosConfigurationVersionsBatchStepFormRowDuplicateAsNew extends Component {
	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		let value;
		if (e === null) value = e;
		else value = e.target ? e.target.value : e;

		// if dates cleared, set them to today and infinite
		if ((type === 'activeFrom' || type === 'activeTo') && value === null) {
			value =
				type === 'activeFrom'
					? moment.utc().format(constants.shortDate)
					: '9999-12-31';
		}

		updateBatchForm({
			[type]: value,
		});
	}

	render() {
		const {
			batchFormData,
			posConfigurationReduxKey,
			posLayoutReduxKey,
			appLayoutReduxKey,
			toggleOverlay,
			loading,
		} = this.props;

		return (
			<div
				className={cx(
					'pos-configuration-version-modal-batch-duplicate-as-new__global-entries',
					{
						'pos-configuration-version-modal-batch-duplicate-as-new__global-entries--active': !loading,
					}
				)}>
				<Tip content={phrases.MODAL_BATCH_STEP_GLOBAL_ROW_TIP} />
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_POS_CONFIGURATION}</th>
							<th>{phrases.MODAL_BATCH_STEP_NAME}</th>
							<th>{phrases.MODAL_BATCH_STEP_DESCRIPTION}</th>
							<th>{phrases.MODAL_BATCH_STEP_STATE}</th>
							<th>{phrases.MODAL_BATCH_STEP_APP_TILE_LAYOUT}</th>
							<th>{phrases.MODAL_BATCH_STEP_POS_TILE_LAYOUT}</th>
							<th>{phrases.MODAL_BATCH_STEP_DELIVERY_TILE_LAYOUT}</th>
							<th>{phrases.MODAL_BATCH_ACTIVE_FROM}</th>
							<th>{phrases.MODAL_BATCH_ACTIVE_TO}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						<TableListRow>
							<TableListCell width="30px" />
							<TableListCell width="200px">
								<ButtonGroup type="flex">
									<InputCollectionSelect
										id="pos_configuration"
										placeholder={
											phrases.MODAL_BATCH_STEP_POS_CONFIGURATION_PLACEHOLDER
										}
										value={batchFormData.posConfiguration}
										handleChange={(key, value) =>
											this.editStoreEntry(value, 'posConfiguration')
										}
										cache
										apiPath="/pos/pos_configurations"
										params={{
											limit: 30,
											filter: ':legacy==false',
										}}
										optionFormat={(entry) => ({
											value: entry,
											label: entry.name,
										})}
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
									/>
									<Button
										type="inverted"
										shadow
										size="medium"
										onClick={() => toggleOverlay(posConfigurationReduxKey)}>
										<Icon name="more" />
									</Button>
								</ButtonGroup>
							</TableListCell>

							<TableListCell width="180px">
								<Input
									id="name"
									placeholder={phrases.MODAL_BATCH_STEP_NAME_PLACEHOLDER}
									value={batchFormData.name || ''}
									onChange={(event) => this.editStoreEntry(event, 'name')}
								/>
							</TableListCell>

							<TableListCell width="180px">
								<Input
									id="description"
									placeholder={phrases.MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER}
									value={batchFormData.description || ''}
									onChange={(event) =>
										this.editStoreEntry(event, 'description')
									}
								/>
							</TableListCell>

							<TableListCell width="180px">
								<InputCollectionSelect
									id="state"
									placeholder={phrases.MODAL_BATCH_STEP_STATE_PLACEHOLDER}
									value={batchFormData.state}
									handleChange={(key, value) =>
										this.editStoreEntry(value, 'state')
									}
									options={getStateOptions()}
									clearable={false}
								/>
							</TableListCell>

							<TableListCell width="200px">
								<ButtonGroup type="flex">
									<InputCollectionSelect
										id="appTileLayout"
										placeholder={
											phrases.MODAL_BATCH_STEP_APP_TILE_LAYOUT_PLACEHOLDER
										}
										value={batchFormData.appTileLayout}
										handleChange={(key, value) =>
											this.editStoreEntry(value, 'appTileLayout')
										}
										cache
										apiPath="/pos/tile_layouts"
										params={{
											limit: 30,
											filter: `:type=='App'`,
										}}
										optionFormat={(entry) => ({
											value: entry,
											label: entry.name,
										})}
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
									/>
									<Button
										type="inverted"
										shadow
										size="medium"
										onClick={() => toggleOverlay(appLayoutReduxKey)}>
										<Icon name="more" />
									</Button>
								</ButtonGroup>
							</TableListCell>

							<TableListCell width="200px">
								<ButtonGroup type="flex">
									<InputCollectionSelect
										id="posTileLayout"
										placeholder={
											phrases.MODAL_BATCH_STEP_POS_TILE_LAYOUT_PLACEHOLDER
										}
										value={batchFormData.posTileLayout}
										handleChange={(key, value) =>
											this.editStoreEntry(value, 'posTileLayout')
										}
										cache
										apiPath="/pos/tile_layouts"
										params={{
											limit: 30,
											filter: `:type=='POS'`,
										}}
										optionFormat={(entry) => ({
											value: entry,
											label: entry.name,
										})}
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
									/>
									<Button
										type="inverted"
										shadow
										size="medium"
										onClick={() => toggleOverlay(posLayoutReduxKey)}>
										<Icon name="more" />
									</Button>
								</ButtonGroup>
							</TableListCell>

							<TableListCell width="200px">
								<InputCollectionSelect
									id="delivery-tile-layout"
									placeholder={
										phrases.MODAL_BATCH_STEP_DELIVERY_TILE_LAYOUT_PLACEHOLDER
									}
									value={batchFormData.deliveryTileLayout}
									handleChange={(key, value) =>
										this.editStoreEntry(value, 'deliveryTileLayout')
									}
									cache
									apiPath="/pos/tile_layouts"
									params={{
										limit: 30,
										filter: `:type=='Delivery'`,
									}}
									optionFormat={(entry) => ({
										value: entry,
										label: entry.name,
									})}
									inputFilterFormat={(input) => `:name=like='%${input}%'`}
									tableTitle={
										phrases.MODAL_BATCH_STEP_DELIVERY_TILE_LAYOUT_TABLE_TITLE
									}
									tableReduxKey={`/pos/tile_layouts/type=='Delivery'-duplicate-as-new-form-row`}
									tableColumns={[
										{
											Header: 'Name',
											id: 'name',
											accessor: (d) => _get(d, 'name', ''),
											filterPath: ':name',
										},
										{
											Header: 'Description',
											id: 'description',
											accessor: (d) => _get(d, 'description', ''),
											filterPath: ':description',
										},
										{
											Header: 'Type',
											id: 'type',
											accessor: (d) => _get(d, 'type', null),
											filterPath: ':type',
										},
										{
											Header: 'Rows',
											id: 'rows',
											accessor: (d) => _get(d, 'rows', null),
											filterPath: ':rows',
										},
										{
											Header: 'Columns',
											id: 'columns',
											accessor: (d) => _get(d, 'columns', null),
											filterPath: ':columns',
										},
										{
											Header: 'Sub type',
											id: 'subType',
											accessor: (d) => _get(d, 'sub_type', null),
											filterPath: ':sub_type',
										},
										{
											Header: 'Legacy',
											id: 'legacy',
											accessor: 'legacy',
											filterPath: ':legacy',
											filterable: false,
											Cell: (d) => {
												return (
													<Input
														type="checkbox"
														checked={d.original.legacy}
														disabled
													/>
												);
											},
										},
									]}
								/>
							</TableListCell>

							<TableListCell width="180px">
								<SingleDatePickerInput
									id="activeFrom"
									onChange={(event) => this.editStoreEntry(event, 'activeFrom')}
									selectedDate={getSelectedDate(
										batchFormData.activeFrom,
										'from'
									)}
									noClockButton
									clearable
								/>
							</TableListCell>

							<TableListCell width="180px">
								<SingleDatePickerInput
									id="activeTo"
									onChange={(event) => this.editStoreEntry(event, 'activeTo')}
									selectedDate={getSelectedDate(batchFormData.activeTo, 'to')}
									noClockButton
									clearable
								/>
							</TableListCell>

							<TableListCell width="120px" />
						</TableListRow>
					</tbody>
				</TableList>
			</div>
		);
	}
}

PosConfigurationVersionsBatchStepFormRowDuplicateAsNew.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
	posConfigurationReduxKey: PropTypes.string,
	appLayoutReduxKey: PropTypes.string,
	posLayoutReduxKey: PropTypes.string,
	loading: PropTypes.bool,
};

export default PosConfigurationVersionsBatchStepFormRowDuplicateAsNew;
