'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { Button } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// phrases
import phrases from './../../../workplaceWaitingTimePerformanceGrids.phrases';

class WorkplaceWaitingTimePerformanceGridsBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	render() {
		const { batchList } = this.props;

		return (
			<TableList>
				<thead>
					<tr>
						<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
						<th>{phrases.MODAL_BATCH_STEP_WORKPLACE}</th>
						<th>{phrases.MODAL_BATCH_STEP_PERIOD_FROM}</th>
						<th>{phrases.MODAL_BATCH_STEP_PERIOD_TO}</th>
						<th />
					</tr>
				</thead>
				<tbody>
					{batchList.map((entry, i) => {
						return (
							<TableListRow key={entry.identifier}>
								<TableListCell>{i + 1}</TableListCell>
								<TableListCell>{entry?.workplace?.label}</TableListCell>

								<TableListCell>{entry?.periodFrom}</TableListCell>

								<TableListCell>{entry?.periodTo}</TableListCell>

								<TableListCell>
									<Button
										id={`${entry.identifier}-delete`}
										type="negative"
										size="tiny"
										onClick={() => this._onRemoveEntry(entry.identifier)}>
										<span className="icon icon--delete" />
									</Button>
								</TableListCell>
							</TableListRow>
						);
					})}
				</tbody>
			</TableList>
		);
	}
}

WorkplaceWaitingTimePerformanceGridsBatchStepList.propTypes = {
	batchList: PropTypes.array,
	removeBatchListItem: PropTypes.func,
};

export default WorkplaceWaitingTimePerformanceGridsBatchStepList;
