'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';
const __endpoints = require('endpoints.service');

export default class SizesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'sort_order',
				type: 'number',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'size_group',
				type: 'select',
				canAdd: true,
				required: true,
				reference: {
					key: 'name',
					list: '/product/size_categories',
				},
			},
		];
		this.settings = {
			name: 'SizesList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			pageTitle: true,
			translationPath: 'SALE.SIZES',
			canManipulate: () => validAccess(undefined, ['Global BI Configuration']),

			endpoint: __endpoints.collection.product.sizes,
		};
		this.init();
		return this;
	}
}
