'use strict';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';
import { isProduction } from 'environment';
import { UserService } from 'user';
import { validAccess } from 'accessControl';
import { Integrations } from '@sentry/tracing';

export default function initSentry() {
	if (isProduction()) {
		console.info('Using the Sentry exception handler.');
		Sentry.init({
			dsn: 'https://9ea8f351c20d4879a34febd68448e170@sentry.io/128416',
			release: __webpack_hash__,
			integrations: [
				new Integrations.BrowserTracing({
					tracingOrigins: ['api2.joejuice.com', 'api2test.joejuice.com'],
				}),
			],
			tracesSampleRate: 0.2, // Be sure to lower this in production

			// ignoreErrors: [
			// 	'transition superseded',
			// 	"null is not an object (evaluating 'this.frameMINI.set')"
			// ],
			blacklistUrls: [
				// Facebook flakiness
				/graph\.facebook\.com/i,

				// Facebook blocked
				/connect\.facebook\.net\/en_US\/all\.js/i,

				/static\.woopra\.com\/js\/woopra\.js/i,

				// Chrome extensions
				/extensions\//i,
				/^chrome:\/\//i,
				/usersnap2-/,
			],
		});

		const userData = UserService.getUser();
		Sentry.setUser({ id: userData?.user?.person?.id });
		Sentry.configureScope((scope) => {
			LogRocket.getSessionURL((sessionsUrl) => {
				validAccess(undefined, ['Shiftplan Manager', 'Shiftplan Admin']) &&
					scope.setExtra('logRocket session:', sessionsUrl);
			});
		});

		return function (exception = null, cause = null) {
			console.info(exception, cause);
			exception &&
				Sentry.withScope((scope) => {
					scope.setExtra(exception);
					Sentry.captureMessage(cause || null);
				});
		};
	} else {
		console.info('Using the default logging exception handler');
		return function (exception, cause) {
			console.error(exception, cause);
		};
	}
}
