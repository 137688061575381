'use strict';

import React, { Component } from 'react';

import HourlyViewHeader from './hourlyView.header.component';
import HourlyViewBody from './hourlyView.body.component';

class HourlyView extends Component {
	constructor(props) {
		super(props);

		this.columns = this.generateColumns();
	}

	generateColumns() {
		// Add actions to all columns
		const content = this.props.components['HourlyView'].content;
		return {
			stores: {
				group: 'stores',
				cells: {
					stores: content[0]
				}
			},
			total: {
				group: 'average',
				cells: {
					turnover: content[1],
					products: content[2],
					employees: content[3],
					phpMax: content[4]
				}
			},
			// average: {
			// 	group: 'average',
			// 	cells: {
			// 		turnover: content[5],
			// 		products: content[6],
			// 		employees: content[7],
			// 		phpMax: content[8]
			// 	}
			// },
			juice: {
				group: 'juice',
				cells: {
					turnover: content[5],
					products: content[6],
					percentage: content[7]
				}
			},
			shake: {
				group: 'shake',
				cells: {
					turnover: content[8],
					products: content[9],
					percentage: content[10]
				}
			},
			sandwich: {
				group: 'sandwich',
				cells: {
					turnover: content[11],
					products: content[12],
					percentage: content[13]
				}
			},
			coffee: {
				group: 'coffee',
				cells: {
					turnover: content[14],
					products: content[15],
					percentage: content[16]
				}
			},
			loyaltycard: {
				group: 'loyaltycard',
				cells: {
					turnover: content[17],
					products: content[18],
					percentage: content[19]
				}
			},
			breakfast: {
				group: 'breakfast',
				cells: {
					turnover: content[20],
					products: content[21],
					percentage: content[22]
				}
			},
			counterproduct: {
				group: 'counterproduct',
				cells: {
					turnover: content[23],
					products: content[24],
					percentage: content[25]
				}
			},

			salad: {
				group: 'salad',
				cells: {
					turnover: content[26],
					products: content[27],
					percentage: content[28]
				}
			},
			topping: {
				group: 'topping',
				cells: {
					turnover: content[29],
					products: content[30],
					percentage: content[31]
				}
			},
			misc: {
				group: 'misc',
				cells: {
					turnover: content[32],
					products: content[33],
					percentage: content[34]
				}
			},
			employeeMeals: {
				group: 'employeeMeals',
				cells: {
					total: content[35],
					percentage: content[36]
				}
			},
			employeeDiscounts: {
				group: 'employee',
				cells: {
					total: content[37],
					percentage: content[38]
				}
			},
			employeeMealDiscounts: {
				group: 'employeeMealsDiscount',
				cells: {
					total: content[39],
					percentage: content[40]
				}
			},
			loyaltyDiscounts: {
				group: 'loyaltyDiscounts',
				cells: {
					total: content[41],
					percentage: content[42]
				}
			},
			neighbourDiscounts: {
				group: 'neighbourDiscounts',
				cells: {
					total: content[43],
					percentage: content[44]
				}
			}
		};
	}

	render() {
		return (
			<table className="dynamic-pos-view__hourly-view">
				<HourlyViewHeader
					{...{
						...this.props,
						columns: this.columns
					}}
				/>
				<HourlyViewBody
					{...{
						...this.props,
						columns: this.columns
					}}
				/>
			</table>
		);
	}
}

export default HourlyView;
