// lodash
import _isEmpty from 'lodash/isEmpty';

// enums/ phrases
import { ShiftTransfer as enums } from 'services/enums';
import phrases from './../shiftTransferApprovalMobileView.phrases';

/**
 * @function getIfAnyApplicationInteracted
 * @param {Object} { applicationDecisions } - array of certain application decisions that were not yet submitted
 * @description Identifies if we interacted with at least one application
 * @returns {Boolean}
 */
export function getIfAnyApplicationInteracted({ applicationDecisions }) {
	return applicationDecisions.some((entry) => entry.operation !== null);
}

/**
 * @function getApplicationsReadyToSubmit
 * @param {Object} { shiftTransfer, applicationDecisions }
 * @description sends applications to the api. If at least one of them is approved we send only THAT one (api will reject all others).
 * If no accepted ones, we look for rejected ones and submit those ones to the api
 * @returns {Array} array to loop through and make subsequent calls to the api to approve/reject applications
 */
export function getApplicationsReadyToSubmit({
	shiftTransfer,
	applicationDecisions,
}) {
	// if there is an application that was approved, only send that one to the api
	// api will automatically reject all other applications
	const approvedApplication = applicationDecisions.find(
		(entry) => entry.operation === enums.OPERATIONS.APPROVE_APPLICATION
	);

	if (approvedApplication) {
		const payload = {
			shiftTransfer,
			operation: approvedApplication.operation,
			application: approvedApplication.id,
		};

		return [payload];
	}

	// get all rejected
	const rejectedApplications = applicationDecisions.filter(
		(entry) => entry.operation === enums.OPERATIONS.DECLINE_APPLICATION
	);

	if (_isEmpty(rejectedApplications)) return [];

	// if there isnt an approved application and only rejected ones, format those to be sent to the api
	return rejectedApplications.map((entry) => {
		const payload = {
			shiftTransfer,
			operation: entry.operation,
			application: entry.id,
		};

		return payload;
	});
}

export function getSwapApplicationToSubmit({
	shiftTransfer,
	applicationDecisions,
	applied,
}) {
	const operation =
		applicationDecisions[0].operation === enums.OPERATIONS.APPROVE_APPLICATION
			? enums.OPERATIONS.ACCEPT
			: enums.OPERATIONS.DECLINE;

	return {
		shiftTransfer,
		operation,
		...(applied && { applied }),
	};
}

/**
 * @function getApplicationDecisions
 * @param {Object} {applicationDecisions, payload} - array of current applications and the one we are toggling
 * @description function used for both manager view when managing multiple applications for a shift
 * If multiple applications and one approved, decline all others. Function works as a toggle as well (if we click on approve application twice it will toggle it to its default state - null)
 * @returns {Array}
 */
export function getApplicationDecisions({ applicationDecisions, payload }) {
	// if approving one application, reject all others
	return applicationDecisions.map((entry) => {
		// find the application
		// if it has the same operation as the one we're trying to set, nullify it (mimics toggle behaviour)
		if (entry.id === payload.id)
			return {
				...entry,
				operation:
					entry.operation === payload.operation ? null : payload.operation,
			};

		// reject all other applications if we are approving one of them
		return {
			...entry,
			operation:
				payload.operation === enums.OPERATIONS.APPROVE_APPLICATION
					? enums.OPERATIONS.DECLINE_APPLICATION
					: entry.operation,
		};
	});
}

export function getOperationlessApplicationDecisions({ applications }) {
	return applications.map((entry) => {
		return {
			id: entry.id,
			operation: null,
		};
	});
}

export function getConfirmButtonLabel({ lastStep, anyApplicationInteracted }) {
	if (anyApplicationInteracted) return phrases.SUBMIT;
	if (lastStep) return phrases.CLOSE;

	return phrases.NEXT;
}
