import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './storeAccessTypes.phrases';

/**
 * @function fetchStoreAccessTypes
 * @description Fetch all types
 * @param {object} state
 */
export function fetchStoreAccessTypes(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/administration/store_access_types', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addStoreAccessType
 * @description add type
 */
export function addStoreAccessType(data) {
	return post('/administration/store_access_types', data)
		.then((res) => {
			setFeedback(phrases.EMPLOYEE_MEAL_APP_ALLOWANCE_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editStoreAccessType
 * @description edit type
 */
export function editStoreAccessType(data) {
	return post(`/administration/store_access_types/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.EMPLOYEE_MEAL_APP_ALLOWANCE_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function editStoreAccessTypes
 * @description edit types multiple
 */
export function editStoreAccessTypes(data) {
	return patch('/administration/store_access_types', data)
		.then((res) => {
			setFeedback(phrases.EMPLOYEE_MEAL_APP_ALLOWANCES_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function deleteStoreAccessType
 * @description delete types
 */
export function deleteStoreAccessType(id) {
	return remove(`/administration/store_access_types/${id}`)
		.then((res) => {
			setFeedback(phrases.EMPLOYEE_MEAL_APP_ALLOWANCE_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
