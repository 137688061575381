/* eslint-disable react/prop-types */
import { IColumnHeader } from 'types/reactDataWrapper';

export default function getColumns(): IColumnHeader<IAdministrationCurrencies>[] {
	const columns: IColumnHeader<IAdministrationCurrencies>[] = [
		{
			Header: 'Code',
			id: 'account-number',
			accessor: 'code',
			filterPath: ':code',
		},
		{
			Header: 'Description',
			id: 'description',
			accessor: 'description',
			filterPath: ':description',
		},
		{
			Header: 'Supported decimals',
			id: 'supported-decimals',
			accessor: 'supported_decimals',
			filterPath: ':supported_decimals',
		},
	];

	return columns;
}
