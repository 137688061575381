'use strict';

import cx from 'classnames';
import _every from 'lodash/every';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DateService from './date.service';

class SimpleDate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			simpleFocused: false,
			invalidDate: false
		};

		this.DateService = new DateService();
		this.translations = this.DateService.GetTranslations();

		this.onSimpleDateChange = this.onSimpleDateChange.bind(this);
		this.onSimpleDateFocus = this.onSimpleDateFocus.bind(this);
		this.onSimpleDateBlur = this.onSimpleDateBlur.bind(this);
		this.onSimpleDateKeyDown = this.onSimpleDateKeyDown.bind(this);
		this.onSimpleDatePaste = this.onSimpleDatePaste.bind(this);
	}

	focus() {
		this.simpleDateFocusSelect('day');
	}

	onSimpleDatePaste(event) {
		this.setDateFromPaste(
			moment(event.nativeEvent.clipboardData.getData('Text'), 'DD-MM-YYYY')
		);
	}

	setDateFromPaste(date) {
		// Are date input values valid date input?
		const validSimpleDate = [
			moment(date, 'DD').isValid(),
			moment(date, 'MM').isValid(),
			moment(date, 'YYYY').isValid()
		];

		// Everything is valid
		if (_every(...validSimpleDate)) {
			this.simpleDateDay.value = moment(date).format('DD');
			this.simpleDateMonth.value = moment(date).format('MM');
			this.simpleDateYear.value = moment(date).format('YYYY');
			this.setSimpleDate();
		}
	}

	setSimpleDate() {
		const { onChange } = this.props;

		// Are date input lengths valid?
		const validDateLength = [
			this.simpleDateDay.value.length === 2,
			this.simpleDateMonth.value.length === 2,
			this.simpleDateYear.value.length === 4
		];

		// Are date input values valid date input?
		const validSimpleDate = [
			moment(this.simpleDateDay.value, 'DD').isValid(),
			moment(this.simpleDateMonth.value, 'MM').isValid(),
			moment(this.simpleDateYear.value, 'YYYY').isValid()
		];

		// Everything is valid
		if (_every([...validDateLength, ...validSimpleDate])) {
			this.setState({ invalidDate: false });
			const date = moment(
				`${this.simpleDateDay.value}-${this.simpleDateMonth.value}-${this.simpleDateYear.value}`,
				'DD-MM-YYYY'
			);
			onChange(date);
		}

		// Lengths are valid, date input not
		else if (_every(validDateLength) && !_every(validSimpleDate))
			this.setState({ invalidDate: true });
		// Default, just let the user type
		else this.setState({ invalidDate: false });
	}

	simpleDateFocusSelect(type) {
		switch (type) {
		case 'day':
			this.simpleDateDay.focus();
			this.simpleDateDay.setSelectionRange(
				0,
				this.simpleDateDay.value.length
			);
			break;
		case 'month':
			this.simpleDateMonth.focus();
			this.simpleDateMonth.setSelectionRange(
				0,
				this.simpleDateMonth.value.length
			);
			break;
		case 'year':
			this.simpleDateYear.focus();
			this.simpleDateYear.setSelectionRange(
				0,
				this.simpleDateYear.value.length
			);
			break;
		}
	}

	onSimpleDateChange(type, event) {
		switch (type) {
		case 'day':
			if (event.target.value.length === 2) {
				this.simpleDateFocusSelect('month');
				this.setSimpleDate();
			}
			break;
		case 'month':
			if (event.target.value.length === 2) {
				this.simpleDateFocusSelect('year');
				this.setSimpleDate();
			}
			break;
		case 'year':
			if (event.target.value.length === 4) this.setSimpleDate();
			break;
		}
	}

	onSimpleDateKeyDown(type, event) {
		// Enter key
		if (event.nativeEvent.keyCode === 13) {
			event.target.blur();
			this.setState({ simpleFocused: false });
			this.props.onFocusChange({ simpleFocused: null });
		}

		// Other key events
		else {
			switch (type) {
			case 'month':
				if (
					event.nativeEvent.keyCode === 8 &&
						this.simpleDateMonth.value.length === 0
				)
					this.simpleDateFocusSelect('day');
				break;
			case 'year':
				if (
					event.nativeEvent.keyCode === 8 &&
						this.simpleDateYear.value.length === 0
				)
					this.simpleDateFocusSelect('month');
				break;
			}
		}
	}

	onSimpleDateFocus(type) {
		const dayValueLength = this.simpleDateDay.value.length;
		const monthValueLength = this.simpleDateMonth.value.length;
		switch (type) {
		case 'day': {
			this.simpleDateFocusSelect('day');
			break;
		}
		case 'month': {
			if (dayValueLength === 0) this.simpleDateFocusSelect('day');
			else this.simpleDateFocusSelect('month');
			break;
		}
		case 'year': {
			if (dayValueLength === 0) this.simpleDateFocusSelect('day');
			else if (monthValueLength === 0) this.simpleDateFocusSelect('month');
			else this.simpleDateFocusSelect('year');
		}
		}
		this.setState({ simpleFocused: true });
		this.props.onFocusChange({ simpleFocused: true });
	}

	onSimpleDateBlur() {
		this.setState({ simpleFocused: false });
		this.props.onFocusChange({ simpleFocused: null });
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { date } = nextProps;

		if (date && moment.isMoment(date)) {
			this.simpleDateDay.value = date.format('DD');
			this.simpleDateMonth.value = date.format('MM');
			this.simpleDateYear.value = date.format('YYYY');
		}
	}

	renderDay() {
		let { disabled, date } = this.props;

		return (
			<span key="day" className="date__simple-date__inputs__input-wrapper">
				<input
					type="text"
					size="2"
					disabled={disabled}
					ref={input => {
						this.simpleDateDay = input;
					}}
					defaultValue={moment.isMoment(date) ? date.format('DD') : ''}
					onPaste={this.onSimpleDatePaste}
					onFocus={event => this.onSimpleDateFocus('day', event)}
					onChange={event => this.onSimpleDateChange('day', event)}
					onKeyDown={event => this.onSimpleDateKeyDown('day', event)}
					onBlur={this.onSimpleDateBlur}
					placeholder={this.translations.SIMPLE_DATE_DAY_PLACEHOLDER}
					maxLength="2"
				/>
			</span>
		);
	}

	renderMonth() {
		let { disabled, date } = this.props;

		return (
			<span key="month" className="date__simple-date__inputs__input-wrapper">
				<input
					type="text"
					size="2"
					disabled={disabled}
					ref={input => {
						this.simpleDateMonth = input;
					}}
					defaultValue={moment.isMoment(date) ? date.format('MM') : ''}
					onPaste={this.onSimpleDatePaste}
					onFocus={event => this.onSimpleDateFocus('month', event)}
					onChange={event => this.onSimpleDateChange('month', event)}
					onKeyDown={event => this.onSimpleDateKeyDown('month', event)}
					onBlur={this.onSimpleDateBlur}
					placeholder={this.translations.SIMPLE_DATE_MONTH_PLACEHOLDER}
					maxLength="2"
				/>
			</span>
		);
	}

	renderYear() {
		let { disabled, date } = this.props;

		return (
			<span key="year" className="date__simple-date__inputs__input-wrapper">
				<input
					type="text"
					size="4"
					disabled={disabled}
					ref={input => {
						this.simpleDateYear = input;
					}}
					defaultValue={moment.isMoment(date) ? date.format('YYYY') : ''}
					onPaste={this.onSimpleDatePaste}
					onFocus={event => this.onSimpleDateFocus('year', event)}
					onChange={event => this.onSimpleDateChange('year', event)}
					onKeyDown={event => this.onSimpleDateKeyDown('year', event)}
					onBlur={this.onSimpleDateBlur}
					placeholder={this.translations.SIMPLE_DATE_YEAR_PLACEHOLDER}
					maxLength="4"
				/>
			</span>
		);
	}

	renderInputs() {
		let { yearFirst } = this.props;
		let order = [this.renderDay(), this.renderMonth(), this.renderYear()];

		if (yearFirst) {
			return order.reverse().map(input => input);
		}
		return order.map((input, index) => input);
	}

	render() {
		const { date, additionalDateFormat } = this.props;

		const { simpleFocused, invalidDate } = this.state;

		const dateCx = cx('date__simple-date__inputs', {
			'date__simple-date__inputs--focused': simpleFocused,
			'date__simple-date__inputs--invalid': invalidDate
		});

		return (
			<div className="date__simple-date">
				<div
					className={dateCx}
					onClick={() =>
						!simpleFocused ? this.onSimpleDateFocus(this, 'year') : null
					}>
					{this.renderInputs()}
					{additionalDateFormat !== null && !invalidDate && (
						<span className="date__simple-date__additional-date-format">
							{date.format(additionalDateFormat)}
						</span>
					)}
				</div>
			</div>
		);
	}
}

SimpleDate.defaultProps = {
	additionalDateFormat: null,
	yearFirst: false,
	onFocusChange() {}
};

SimpleDate.propTypes = {
	onChange: PropTypes.func.isRequired,
	onFocusChange: PropTypes.func,
	additionalDateFormat: PropTypes.string,
	yearFirst: PropTypes.bool,
	disabled: PropTypes.bool,
	date: PropTypes.object
};

export default SimpleDate;
