import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './label.css';

const Label = ({ children, className, disabled, htmlFor, type }) => {
	const classN = classnames('j-label', {
		'j-label--disabled': disabled,
		[`j-label--${type}`]: !!type,
		[`${className}`]: className,
	});

	return (
		<label {...(htmlFor && { htmlFor })} className={classN}>
			{children}
		</label>
	);
};

Label.propTypes = {
	className: PropTypes.string,
	children: PropTypes.any.isRequired,
	disabled: PropTypes.bool,
	htmlFor: PropTypes.string,
	type: PropTypes.string,
};

export default Label;
