export const SET_WORKFORCE_NEWS_MODAL =
	'WORKFORCE_NEWS/SET_WAITING_TIME_GRIDS';
export const UPDATE_WORKFORCE_NEWS =
	'WORKFORCE_NEWS/UPDATE_WORKFORCE_NEWS';
export const RESET_WORKFORCE_NEWS_MODAL =
	'WORKFORCE_NEWS/RESET_WORKFORCE_NEWS_MODAL';

/**
 * @param {object} payload
 */
export function setWorkforceNewsModal(payload) {
	return {
		type: SET_WORKFORCE_NEWS_MODAL,
		payload
	};
}
/**
 * @param {object} payload
 */
export function updateWorkforceNews(payload) {
	return {
		type: UPDATE_WORKFORCE_NEWS,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetWorkforceNewsModal() {
	return {
		type: RESET_WORKFORCE_NEWS_MODAL
	};
}
