import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './imagePreview.css';

const ImagePreview = props => {
	const { image, onSelect, selectedImageId } = props;

	const className = cx('image-preview', {
		'image-preview--selected': selectedImageId === image.id
	});
	return (
		<div className={className} onClick={() => onSelect(image)}>
			<img src={image.src} alt={image.name} />
		</div>
	);
};

ImagePreview.propTypes = {
	image: PropTypes.object,
	onSelect: PropTypes.func,
	selectedImageId: PropTypes.number
};

ImagePreview.defaultProps = {
	image: {},
	onSelect: () => {}
};

export default ImagePreview;
