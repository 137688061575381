export default {
	TABLE_TITLE: 'Market tags',
	MARKET_TAGS_ADDED: 'Market tag added',
	MARKET_TAG_EDITED: 'Market tag edited',
	MARKET_TAGS_EDITED: 'Market tags edited',
	MARKET_TAGS_DELETED: 'Market tag deleted',

	// batch
	MODAL_TITLE: 'Add market tags',

	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	TAG: 'Tag',
	TAG_PLACEHOLDER: 'Select tags...',
	MARKET: 'Market',
	MARKETS: 'Markets',
	MARKET_PLACEHOLDER: 'Select markets...',
	DEFAULT_LENGTH_IN_MINUTES: 'Default length',
	DEFAULT_LENGTH_IN_MINUTES_PLACEHOLDER: 'Enter Default length (in minutes)...',
	MINUTES_FROM_START_OF_SHIFT: 'Minutes from start',
	MINUTES_FROM_START_OF_SHIFT_PLACEHOLDER:
		'Enter Minutes from start (in minutes)...',
	MINUTES_FROM_END_OF_SHIFT: 'Minutes from end',
	MINUTES_FROM_END_OF_SHIFT_PLACEHOLDER:
		'Enter Minutes from end (in minutes)...',
	ADD_ROW_TOOLTIP: 'Click to add row',
	DELETE_ROW_TOOLTIP: 'Remove row',
	BATCH_ADD: 'Add multiple',
	MODAL_BATCH_TIP_ONE:
		'You can only enter minutes from start or minutes from end, not both',
	MODAL_BATCH_TIP_TWO:
		'"Default length" cannot be longer then "Minutes from end" as the Tag will finish after the shift',

	SUBMIT: 'Confirm',
	NEXT: 'Next',
	BACK: 'Back',
};
