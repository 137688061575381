// React stuff
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'appState/storeHooks';

// Components
import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import { InputCollectionSelect, SingleDatePickerInput } from 'dumb';
import { Typography } from '@mui/material';

// Interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';
import { PayloadState, InitialEditValues } from './types';
import { SelectOption } from 'types/select';
import { Moment } from 'moment';

// Phrases & Columns
import phrases from './employmentWorkplaces.phrases';
import getColumns from 'reactDataWrapperColumns/hr/employmentWorkplaces.columns';

// Services
import {
	fetchEmploymentWorkplaces,
	deleteEmploymentWorkplace,
	createEmploymentWorkplace,
	updateEmploymentWorkplace,
} from './employmentWorkplaces.service';
import moment from 'moment';
import constants from 'services/constants';
import getEditedValues from 'services/utils/getEditedValues';

// Redux
import {
	setWorkplace,
	setFromDate,
	setToDate,
	resetReduxState,
} from './store/employmentWorkplaces.actions';

export default function EmploymentWorkplaces(): JSX.Element {
	const employmentWorkplace = useAppSelector(
		(state) => state.employmentWorkplaces
	);
	const dispatch = useAppDispatch();
	const { employmentId } = useParams();
	const reduxKey = '/hr/employment_workplaces';

	const [rowId, setRowId] = useState<number | undefined>();

	const loadEmploymentWorkplaces = (state: IFetchDataProps) => {
		return fetchEmploymentWorkplaces({ id: employmentId, state });
	};

	const deleteEntry = (id: number) => {
		return deleteEmploymentWorkplace(id);
	};

	const getPayload = (): PayloadState => {
		const payload = {
			employment: employmentId,
			workplace: employmentWorkplace.workplace.value.toString(),
			period: {
				from: moment(employmentWorkplace.fromDate).format(constants.shortDate),
				to: moment(employmentWorkplace.toDate).format(constants.shortDate),
			},
		};

		return payload;
	};

	const createEntry = () => {
		const payload = getPayload();
		return createEmploymentWorkplace(payload);
	};

	const editEntry = () => {
		const payload = getPayload();

		const oldData: PayloadState = {
			employment: employmentId,
			workplace: employmentWorkplace.workplace,
			period: {
				from: employmentWorkplace.fromDate,
				to: employmentWorkplace.toDate,
			},
		};

		const editedValues = getEditedValues({
			oldData: oldData,
			newData: payload,
		});
		return updateEmploymentWorkplace({
			payload: editedValues as PayloadState,
			id: rowId,
		});
	};

	const setInitialEditValues = (data: InitialEditValues) => {
		const { id, period, workplace } = data;

		setRowId(id);
		dispatch(setFromDate(period.from));
		dispatch(setToDate(period.to));
		dispatch(setWorkplace({ value: workplace.id, label: workplace.name }));
	};

	const resetState = () => {
		setRowId(undefined);
		dispatch(resetReduxState());
	};

	const inputsForModal = () => {
		return [
			{
				header: 'Workplace',
				value: (
					<InputCollectionSelect
						value={employmentWorkplace.workplace}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						cache
						params={{
							limit: 30,
						}}
						apiPath="/organization/workplaces"
						searchable
						handleChange={(_: unknown, value: SelectOption) =>
							dispatch(setWorkplace(value))
						}
						apiFilter={':state==\'Operating\''}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'From Date',
				value: (
					<SingleDatePickerInput
						selectedDate={moment(employmentWorkplace.fromDate).format(
							constants.shortDate
						)}
						onChange={(e: Moment) => dispatch(setFromDate(e))}
						placeholder="From"
					/>
				),
			},
			{
				header: 'To Date',
				value: (
					<SingleDatePickerInput
						selectedDate={moment(employmentWorkplace.toDate).format(
							constants.shortDate
						)}
						onChange={(e: Moment) => dispatch(setToDate(e))}
						placeholder="To"
					/>
				),
			},
		];
	};

	const inputsForEditModal = () => {
		return [
			{
				header: 'Workplace',
				value: (
					<Typography variant="body1">
						{employmentWorkplace?.workplace?.label}
					</Typography>
				),
			},
			{
				header: 'From Date',
				value: (
					<SingleDatePickerInput
						selectedDate={moment(employmentWorkplace.fromDate).format(
							constants.shortDate
						)}
						onChange={(e: Moment) => dispatch(setFromDate(e))}
						placeholder="From"
					/>
				),
			},
			{
				header: 'To Date',
				value: (
					<SingleDatePickerInput
						selectedDate={moment(employmentWorkplace.toDate).format(
							constants.shortDate
						)}
						onChange={(e: Moment) => dispatch(setToDate(e))}
						placeholder="To"
					/>
				),
			},
		];
	};

	return (
		<ReactDataWrapper
			dataCy="employment-worplaces-table"
			title={phrases.EMPLOYMENT_WORKPLACES}
			reduxKey={reduxKey}
			columns={getColumns({ reduxKey })}
			fetchData={loadEmploymentWorkplaces}
			deleteEntry={deleteEntry}
			createEntry={createEntry}
			editableCells={inputsForModal()}
			editableCellsEdit={inputsForEditModal()}
			editEntry={editEntry}
			setInitialEditValues={setInitialEditValues}
			onModalClose={resetState}
			defaultPageSize={10}
			accessAreasAllowedToEdit={['Employment Admin', 'Person Admin']}
		/>
	);
}
