const defaultStyle = [{
	'featureType': 'all',
	'elementType': 'geometry',
	'stylers': [{'color': '#4e4e4e'}, {'visibility': 'on'}]
}, {'featureType': 'all', 'elementType': 'labels', 'stylers': [{'visibility': 'off'}]}, {
	'featureType': 'all',
	'elementType': 'labels.text',
	'stylers': [{'lightness': '0'}]
}, {
	'featureType': 'administrative',
	'elementType': 'all',
	'stylers': [{'visibility': 'off'}]
}, {
	'featureType': 'administrative',
	'elementType': 'geometry',
	'stylers': [{'visibility': 'off'}]
}, {
	'featureType': 'administrative',
	'elementType': 'labels',
	'stylers': [{'visibility': 'off'}]
}, {
	'featureType': 'administrative.locality',
	'elementType': 'all',
	'stylers': [{'visibility': 'simplified'}, {'color': '#cccccc'}, {'weight': '1'}]
}, {
	'featureType': 'administrative.neighborhood',
	'elementType': 'all',
	'stylers': [{'visibility': 'simplified'}, {'weight': '1'}]
}, {
	'featureType': 'administrative.neighborhood',
	'elementType': 'labels.text',
	'stylers': [{'visibility': 'simplified'}, {'weight': '1'}, {'gamma': '1.00'}, {'lightness': '49'}, {'saturation': '1'}, {'color': '#e1e1e1'}]
}, {
	'featureType': 'landscape',
	'elementType': 'geometry',
	'stylers': [{'visibility': 'on'}, {'color': '#4e4e4e'}]
}, {
	'featureType': 'landscape.man_made',
	'elementType': 'all',
	'stylers': [{'visibility': 'off'}]
}, {
	'featureType': 'landscape.man_made',
	'elementType': 'labels',
	'stylers': [{'visibility': 'off'}]
}, {
	'featureType': 'landscape.natural.landcover',
	'elementType': 'all',
	'stylers': [{'visibility': 'off'}, {'color': '#080000'}]
}, {
	'featureType': 'landscape.natural.landcover',
	'elementType': 'labels',
	'stylers': [{'visibility': 'off'}]
}, {'featureType': 'poi', 'elementType': 'all', 'stylers': [{'visibility': 'off'}]}, {
	'featureType': 'poi',
	'elementType': 'labels',
	'stylers': [{'visibility': 'off'}]
}, {
	'featureType': 'road',
	'elementType': 'geometry',
	'stylers': [{'visibility': 'on'}, {'color': '#222222'}, {'lightness': '0'}, {'gamma': '1'}, {'weight': '0'}]
}, {'featureType': 'road', 'elementType': 'labels', 'stylers': [{'visibility': 'off'}]}, {
	'featureType': 'road.local',
	'elementType': 'labels',
	'stylers': [{'visibility': 'simplified'}, {'color': '#cccccc'}, {'weight': '1'}]
}, {
	'featureType': 'road.local',
	'elementType': 'labels.icon',
	'stylers': [{'visibility': 'off'}]
}, {'featureType': 'transit', 'elementType': 'all', 'stylers': [{'visibility': 'off'}]}, {
	'featureType': 'transit',
	'elementType': 'geometry',
	'stylers': [{'visibility': 'on'}, {'color': '#222222'}]
}, {
	'featureType': 'transit',
	'elementType': 'labels',
	'stylers': [{'visibility': 'off'}]
}, {
	'featureType': 'transit.station',
	'elementType': 'all',
	'stylers': [{'weight': '1'}, {'gamma': '1.00'}, {'visibility': 'simplified'}, {'invert_lightness': true}, {'saturation': '-100'}, {'lightness': '3'}]
}, {
	'featureType': 'transit.station.airport',
	'elementType': 'geometry.fill',
	'stylers': [{'visibility': 'off'}]
}, {
	'featureType': 'transit.station.airport',
	'elementType': 'labels',
	'stylers': [{'visibility': 'simplified'}]
}, {
	'featureType': 'transit.station.airport',
	'elementType': 'labels.icon',
	'stylers': [{'saturation': '-100'}, {'lightness': '1'}, {'gamma': '1.00'}]
}, {
	'featureType': 'transit.station.bus',
	'elementType': 'all',
	'stylers': [{'visibility': 'off'}]
}, {
	'featureType': 'transit.station.rail',
	'elementType': 'all',
	'stylers': [{'visibility': 'on'}]
}, {
	'featureType': 'water',
	'elementType': 'all',
	'stylers': [{'visibility': 'on'}, {'color': '#222222'}, {'saturation': '0'}]
}, {
	'featureType': 'water',
	'elementType': 'geometry',
	'stylers': [{'visibility': 'on'}, {'color': '#222222'}]
}, {
	'featureType': 'water', 'elementType': 'labels', 'stylers': [{'visibility': 'off'}]
}];

export default defaultStyle;