import React, { useRef, useState } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import PropTypes from 'prop-types';
// import { Icon }  from 'dumb';

import _get from 'lodash/get';

import './itemGrid.css';

const ItemCard = ({
	name,
	isDragging,
	connectDragSource,
	connectDropTarget,
	backgroundColor,
	textColor,
	description,
	imageAsset,
	openTileModal,
	removeTile,
	id
}) => {
	const cardRef = useRef();

	const [isHovering, setIsHovering] = useState(false);

	const opacity = isDragging ? 0 : 1;

	return connectDragSource(
		connectDropTarget(
			<div
				ref={cardRef}
				style={{ background: `#${backgroundColor}`, opacity }}
				className="item-grid"
				onMouseEnter={() => setIsHovering(true)}
				onMouseLeave={() => setIsHovering(false)}>
				{imageAsset && (
					<img
						className="item-grid__image"
						src={_get(imageAsset, 'resolution_one.url', null)}
						alt="Tile imageAsset"
					/>
				)}
				{isHovering && (
					<div className="category-item__icon-section">
						<div
							onClick={() => openTileModal()}
							className="category-item__icon-section__icon">
							<span className="icon icon--edit" />
						</div>
						<div
							onClick={() => removeTile(id)}
							className="category-item__icon-section__icon">
							<span className="icon icon--delete" />
						</div>
					</div>
				)}
				<div
					style={{ opacity, color: `#${textColor}` }}
					className="item-grid__title">
					{name}
				</div>
				<div
					style={{ opacity, color: `#${textColor}` }}
					className="item-grid__sub-text">
					{description}
				</div>
			</div>
		)
	);
};

export default DropTarget(
	'itemCard',
	{
		hover(props, monitor) {
			const dragIndex = monitor.getItem().index;
			const hoverIndex = props.index;
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}

			props.moveCard(dragIndex, hoverIndex);
			monitor.getItem().index = hoverIndex;
		},
		drop(props, monitor) {
			props.onDrop();
		}
	},
	connect => ({
		connectDropTarget: connect.dropTarget()
	})
)(
	DragSource(
		'itemCard',
		{
			beginDrag: props => ({
				id: props.id,
				index: props.index
			})
		},
		(connect, monitor) => ({
			connectDragSource: connect.dragSource(),
			isDragging: monitor.isDragging()
		})
	)(ItemCard)
);

ItemCard.propTypes = {
	id: PropTypes.number,
	name: PropTypes.string,
	description: PropTypes.string,
	isDragging: PropTypes.bool,
	connectDragSource: PropTypes.func,
	connectDropTarget: PropTypes.func,
	backgroundColor: PropTypes.string,
	textColor: PropTypes.string,
	imageAsset: PropTypes.array,
	removeTile: PropTypes.func,
	openTileModal: PropTypes.func
};
