'use strict';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// lodash
import _isFunction from 'lodash/isFunction';
import { push } from 'redux-first-history';
import store from 'appState/store';
// components
import { ButtonFeedbackGroup, Icon, Button, Tooltip, IconButton } from 'dumb';
import { Box, Typography } from '@mui/material';

// utls
import { validAccess } from 'accessControl';
import { isMobile } from 'detectMobile.vanilla';

import img from './joe-avatar.png';

class StaffHeader extends PureComponent {
	constructor(props) {
		super(props);

		this.goToJuicerPage = this.goToJuicerPage.bind(this);
		this.getUserVisualName = this.getUserVisualName.bind(this);
		this.isMobile = isMobile();
	}

	// todo: this function is not used
	renderEditButton() {
		const {
			saving,
			editMode,
			toggleEditMode,
			saveEdit,
			userUpdateStatus,
			phrases,
		} = this.props;

		if (saving || userUpdateStatus !== '') {
			return (
				<Button type="secondary">
					<ButtonFeedbackGroup
						feedbackType={userUpdateStatus}
						fetching={saving}
						theme="light"
					/>
				</Button>
			);
		}

		if (editMode) {
			return (
				<Button type="success" shadow onClick={saveEdit}>
					{phrases.SAVE_CHANGES}
				</Button>
			);
		}

		return (
			<Button type="secondary" shadow onClick={toggleEditMode}>
				<Icon name="edit" />
			</Button>
		);
	}

	goToJuicerPage() {
		const { userId } = this.props;

		const allowNavigationToJuicersPage = validAccess(undefined, [
			'Shiftplan Admin',
		]);

		if (!allowNavigationToJuicersPage) return;

		store.dispatch(push(`/hr/juicers/${userId}`));
	}

	getUserVisualName() {
		const { userId, badge, userName } = this.props;

		let userVisualName = `(${userId})`;
		if (badge) userVisualName = `${userVisualName} - ${badge}`;
		if (userName)
			userVisualName = badge
				? `${userVisualName} ${userName}`
				: `${userVisualName} - ${userName}`;

		return userVisualName;
	}

	render() {
		const {
			toggleTrainingHistory,
			phrases,
			headerComponent,
			userId,
			renderEditButton,
			contentPadding,
		} = this.props;

		const allowNavigationToJuicersPage = validAccess(undefined, [
			'Shiftplan Admin',
		]);
		const userVisualName = this.getUserVisualName();
		const renderPersonalData =
			validAccess(undefined, ['Shiftplan Manager', 'Shiftplan Admin']) &&
			userId;

		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						paddingX: contentPadding,
					}}
				>
					{/* to separate edit button from rest of content */}
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<img
							src={img}
							alt="joe-avatar"
							title="Joe & The Juice"
							className="avatar"
						/>

						<Box ml={1}>
							<Typography variant="h6" fontWeight="bold">
								{userVisualName}
							</Typography>
						</Box>

						{allowNavigationToJuicersPage && (
							<Tooltip
								zIndex={500}
								visible={allowNavigationToJuicersPage}
								content={phrases.EMPLOYEE_DETAILS_TOOLTIP}
								renderData={(ref, onMouseEnter, onMouseLeave) => (
									<IconButton
										ref={ref}
										onMouseEnter={onMouseEnter}
										onMouseLeave={onMouseLeave}
										onClick={this.goToJuicerPage}
									>
										<Icon name="open_in_new" />
									</IconButton>
								)}
							/>
						)}
					</Box>

					{renderEditButton()}
				</Box>

				{renderPersonalData && (
					<Box
						sx={{
							marginTop: 2,
							display: 'flex',
							alignItems: 'center',
							flexWrap: 'wrap',
							backgroundColor: '#dddddd',
							padding: contentPadding,
							...(this.isMobile && {
								// half padding from top on mobile as buttons themselves add half padding from top
								paddingTop: contentPadding / 2,
								'& > .j-button': {
									marginTop: contentPadding / 2,
								},
							}),
						}}
					>
						<Tooltip
							zIndex={500}
							text={phrases.TRAININGS_EXAMS_TOOLTIP}
							renderData={(ref, onMouseEnter, onMouseLeave) => (
								<Button
									shadow
									size="tiny"
									type="secondary"
									onClick={toggleTrainingHistory}
									refProp={ref}
									onMouseEnter={onMouseEnter}
									onMouseLeave={onMouseLeave}
								>
									{phrases.TRAININGS_EXAMS}
								</Button>
							)}
						/>

						{_isFunction(headerComponent) && headerComponent()}
					</Box>
				)}
			</Box>
		);
	}
}

StaffHeader.propTypes = {
	phrases: PropTypes.object.isRequired,
	toggleEditMode: PropTypes.func,
	editMode: PropTypes.bool,
	saveEdit: PropTypes.func,
	toggleTrainingHistory: PropTypes.func,
	saving: PropTypes.bool,
	userUpdateStatus: PropTypes.string,
	userName: PropTypes.string,
	headerComponent: PropTypes.func,
	renderEditButton: PropTypes.func,
	userId: PropTypes.number,
	badge: PropTypes.string,
	contentPadding: PropTypes.number,
};

export default StaffHeader;
