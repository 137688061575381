'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');

export default class PersonAttributeTypesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
		];
		this.settings = {
			name: 'PersonAttributeTypesList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			pageTitle: true,
			searchList: {
				canAdd: true,
				canDelete: true,
			},
			translationPath: 'HR.PERSON_ATTRIBUTE_TYPES',
			canManipulate: () => validAccess(undefined, ['Global HR Configuration']),
			endpoint: __endpoints.collection.hr.person_attribute_types,
		};
		this.init();
		return this;
	}
}
