'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, InputCollectionSelect, Toggle } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// utils
import { getOperationTypeOptions } from './../../../utils';

import _get from 'lodash/get';

class BatchStepList extends Component {
	_onRemoveEntry(row) {
		this.props.removeBatchListItem(row.identifier);
	}

	_editListEntry(e, row, type) {
		const value = e.target ? e.target.value : e;

		this.props.editBatchListItem({
			...row,
			...{ [type]: value },
		});
	}

	render() {
		const { batchList } = this.props;

		return (
			<TableList>
				<thead>
					<tr>
						<th>#</th>
						<th>Name</th>
						<th>Ingredient Category</th>
						<th>Fuel</th>
						<th>Asset Collection</th>
						<th>Ingredient Pricing Group</th>
						<th>List Name</th>
						<th>Operation Type</th>
						<th>Availability Administration</th>

						<th />
					</tr>
				</thead>
				<tbody>
					{batchList.map((x, i) => {
						return (
							<TableListRow key={i}>
								<TableListCell>{i + 1}</TableListCell>

								<TableListCell>
									<Input
										id={`name-${x.identifier}`}
										value={_get(x, 'name', '')}
										placeholder="Name"
										onChange={(event) => this._editListEntry(event, x, 'name')}
									/>
								</TableListCell>

								<TableListCell>
									{_get(x, 'ingredient_category.name', '')}
								</TableListCell>

								<TableListCell>
									<Input
										id={`fuel-${x.identifier}`}
										value={_get(x, 'fuel', '')}
										placeholder="Fuel"
										onChange={(event) => this._editListEntry(event, x, 'fuel')}
									/>
								</TableListCell>

								<TableListCell>
									<InputCollectionSelect
										id={`assetCollection-${x.identifier}`}
										className="modal-batch-select"
										placeholder="Select asset collection"
										value={{
											label: _get(x, 'asset_collection.label', ''),
											value: _get(x, 'asset_collection.value', null),
										}}
										handleChange={(key, value) =>
											this._editListEntry(value, x, 'asset_collection')
										}
										clearable={false}
										cache
										apiPath="/pos/asset_collections"
										params={{
											limit: 300,
										}}
										optionFormat={(entry) => ({
											value: entry,
											label: entry.name,
										})}
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
									/>
								</TableListCell>

								<TableListCell>
									{_get(x, 'ingredient_pricing_group.name', '')}
								</TableListCell>

								<TableListCell>
									<Input
										id={`listName-${x.identifier}`}
										placeholder="Enter list name..."
										value={x.listName}
										onChange={(event) =>
											this._editListEntry(event, x, 'listName')
										}
									/>
								</TableListCell>

								<TableListCell>
									<InputCollectionSelect
										id={`operationType-${x.identifier}`}
										placeholder="Select operation type..."
										value={x?.operationType}
										handleChange={(key, value) =>
											this._editListEntry(value, x, 'operationType')
										}
										cache
										clearable={false}
										options={getOperationTypeOptions()}
									/>
								</TableListCell>

								<TableListCell>
									<Toggle
										id={`availabilityAdministration-${x.identifier}`}
										toggled={x.availabilityAdministration}
										onClick={(e) =>
											this._editListEntry(e, x, 'availabilityAdministration')
										}
									/>
								</TableListCell>

								<TableListCell>
									<Button
										type="negative"
										size="tiny"
										onClick={() => this._onRemoveEntry(x)}>
										<span className="icon icon--delete" />
									</Button>
								</TableListCell>
							</TableListRow>
						);
					})}
				</tbody>
			</TableList>
		);
	}
}

BatchStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

BatchStepList.defaultProps = {};

export default BatchStepList;
