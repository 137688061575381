'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _map from 'lodash/map';

class AccordionHeaderRow extends Component {
	constructor(props) {
		super(props);
	}

	/**
	 * @function _renderTitle
	 * @memberOf DIRECTIVES.jtjList.component.accordionHeaderRow
	 * @description Renders title row
	 */
	_renderTitle() {
		if (this.props.accordion.settings.showTitle !== false) {
			var headerClassNames = cx('list__row', 'accordion__title');
			var cellClassNames = cx('list__cell-contents');
			return (
				<tr>
					<th
						colSpan={this.props.accordion.headers.length}
						className={headerClassNames}>
						<span className={cellClassNames}>{this.props.accordion.label}</span>
					</th>
				</tr>
			);
		}
	}

	/**
	 * @function _renderHeaders
	 * @memberOf DIRECTIVES.jtjList.component.accordionHeaderRow
	 * @description Renders headers
	 */
	_renderHeaders() {
		if (this.props.accordion.settings.showHeaders !== false) {
			return _map(
				this.props.accordion.methods.handleReturnVisibleHeaders(),
				function(cell, key) {
					var headerClassNames = cx(
						'list__header-cell',
						'list__header-cell--' + cell.type
					);
					var cellClassNames = cx('list__cell-contents');
					var cellStyles = {
						width: cell.width ? cell.width + 'px' : 'auto'
					};
					var headerText = this.props.accordion.settings.collapseHeader
						? cell.label
						: cell.label.replace(/ /g, '\xA0');
					return (
						<th key={key} style={cellStyles} className={headerClassNames}>
							<span className={cellClassNames}>{headerText}</span>
						</th>
					);
				}.bind(this)
			);
		}
	}

	render() {
		return (
			<thead>
				{this._renderTitle()}
				<tr>{this._renderHeaders()}</tr>
			</thead>
		);
	}
}

AccordionHeaderRow.propTypes = {
	accordion: PropTypes.object.isRequired
};

export default AccordionHeaderRow;
