'use strict';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';

import PrintView from 'printView';

import TableWrapper from './subcomponents/table';

const printOptions = {
	id: 'ReportPrintView',
	xlsxElementClass: 'report-old__table',
	landscape: true,
	title: 'Report'
};

const customPrintStyles = {
	'.report-old__table-wrapper': 'page-break-after: always;'
};

class ReportPrintView extends PureComponent {
	constructor(props) {
		super(props);
	}

	// Return all tables as TableWrapper
	getTables() {
		const { phrases, tables } = this.props;
		return tables === null ? (
			<h4 className="report-old__no-data-error">{phrases.NO_TABLES}</h4>
		) : (
			tables.result.map((key, index) => (
				<TableWrapper
					key={key + index}
					table={tables.entities[key]}
					{...this.props}
				/>
			))
		);
	}

	componentDidMount() {
		const { report } = this.props;
		setTimeout(() => report.handlePrintViewToggleCallback(), 400);
	}

	render() {
		const { active, metadata, report, modifierClassName } = this.props;

		// Update printOptions title
		printOptions.title = report.metadata.getPrintTitle(metadata);

		if (active)
			return (
				<div
					className={`report-old ${report.name} ${modifierClassName}`}
					id="ReportPrintView">
					<div className="report-old__meta-wrapper">
						<h1>{report.metadata.getTitle(metadata)}</h1>
						<h2>{report.metadata.getSubtitle(metadata)}</h2>
					</div>
					{this.getTables()}
				</div>
			);
		else return null;
	}
}

ReportPrintView.propTypes = {
	active: PropTypes.bool.isRequired,
	metadata: PropTypes.object.isRequired,
	report: PropTypes.object.isRequired,
	tables: PropTypes.object.isRequired
};

export default PrintView(ReportPrintView, printOptions, customPrintStyles);
