import { SET_DRAWER } from '../actions/appSettings.actions';

const defaultState = { drawerShown: true };

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
		case SET_DRAWER:
			return {
				...state,
				drawerShown: payload,
			};

		default:
			return state;
	}
}

export default reducer;
