import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './../productAddonRecommendations.phrases';

/**
 * @function fetchAddonIngredients
 * @description Fetch all addon ingredients
 * @param {object} state
 */
export function fetchAddonIngredients(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get(
		'/product/addon_recommendation_addon_ingredients',
		params,
		null,
		state.headers
	)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editAddonIngredient
 * @description edits addon ingredient
 */
export function editAddonIngredient(data) {
	return post(
		`/product/addon_recommendation_addon_ingredients/${data.id}`,
		data
	)
		.then(res => {
			setFeedback(phrases.ADDON_INGREDIENT_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function addAddonIngredient
 * @description adds addon ingredient
 */
export function addAddonIngredient(data) {
	return post('/product/addon_recommendation_addon_ingredients', data)
		.then(res => {
			setFeedback(phrases.ADDON_INGREDIENT_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteAddonIngredient
 * @description deletes addon ingredient
 */
export function deleteAddonIngredient(productId) {
	return remove(`/product/addon_recommendation_addon_ingredients/${productId}`)
		.then(res => {
			setFeedback(phrases.ADDON_INGREDIENT_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
