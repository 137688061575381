'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';

// different batch views
import ProductBundleSetsBatchStepList from './components/productBundleSetsBatchStepList';

// phrases
import phrases from './../../productBundleSets.phrases';

// lodash
import _get from 'lodash/get';

class ProductBundleSetsModalBatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};

		this.productRecommendationUrl = '/pos/product_recommendations';
		this.assetCollectionUrl = '/pos/asset_collections';
	}

	_batchSubmit() {
		const {
			batchList,
			handleClose,
			batchAddData,
			productBundleId,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchList.map((entry) => {
			return {
				bundle: productBundleId,
				...(entry.productRecommendation && {
					product_recommendation: _get(
						entry,
						'productRecommendation.value.id',
						null
					),
				}),
				name: entry.name,
				description: entry.description,
				sort_order: entry.sortOrder,
				...(entry.optional && { optional: entry.optional }),
				...(entry.assetCollection && {
					asset_collection: _get(entry, 'assetCollection.value.id', null),
				}),
			};
		});

		batchAddData({ batch: payload })
			.then(() => {
				handleClose();
				this.setState(() => ({
					loadingModal: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	render() {
		const {
			modalVisible,
			handleClose,
			batchList,
			editBatchListItem,
			removeBatchListItem,
			addBatchListItemRow,
		} = this.props;

		const steps = [
			{
				component: (
					<ProductBundleSetsBatchStepList
						batchList={batchList}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
						productRecommendationUrl={this.productRecommendationUrl}
						assetCollectionUrl={this.assetCollectionUrl}
						addBatchListItemRow={addBatchListItemRow}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return modalVisible ? (
			<ModalStepper
				isOpen={modalVisible}
				steps={steps}
				showStep={false}
				onClose={handleClose}
			/>
		) : null;
	}
}

ProductBundleSetsModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	batchAddData: PropTypes.func,
	productBundleId: PropTypes.number,
};

export default ProductBundleSetsModalBatch;
