import { UPDATE_VALUE, SET_VALUE, RESET_VALUE } from './tags.actions';

const defaultState = {
	value: {},
	originalValue: {},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_VALUE:
			return {
				value: payload,
				originalValue: payload,
			};

		case UPDATE_VALUE:
			return {
				...state,
				value: {
					...state.value,
					...payload,
				},
			};

		case RESET_VALUE:
			return defaultState;

		default:
			return state;
	}
}

export default reducer;
