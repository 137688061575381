'use strict';

import prop from 'dot-prop-immutable';

import {
	SET_MARKET_WORKPLACE_TABLE,
	SET_MARKET_HOURLY_TABLE,
	SET_MARKET_PRODUCT_TABLE,
	SET_WORKPLACE_HOURLY_TABLE,
	SET_WORKPLACE_PRODUCT_TABLE,
	TOGGLE_TABLE,
	TOGGLE_PRODUCT_TABLE,
	SET_MARKET_HOVER_GROUP
} from './dynamicPosView.actions';

const defaultState = {
	marketWorkplaceTables: {},
	marketHourlyTables: {},
	marketProductTables: {},
	workplaceHourlyTables: {},
	workplaceProductTables: {},
	hoverGroup: {},
	expandedTables: {
		market: {},
		hourly: {},
		product: {
			juice: {},
			shake: {},
			sandwich: {},
			coffee: {},
			loyaltycard: {},
			counterproduct: {},
			topping: {},
			breakfast: {},
			salad: {},
			misc: {}
		}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
	case SET_MARKET_WORKPLACE_TABLE:
		return prop.set(
			state,
			`marketWorkplaceTables.${payload.id}`,
			payload.workplaceTable
		);
	case SET_MARKET_HOURLY_TABLE:
		return prop.set(
			state,
			`marketHourlyTables.${payload.id}`,
			payload.hourlyTable
		);
	case SET_MARKET_PRODUCT_TABLE:
		return prop.set(
			state,
			`marketProductTables.${payload.id}`,
			payload.productTable
		);
	case SET_WORKPLACE_HOURLY_TABLE:
		return prop.set(
			state,
			`workplaceHourlyTables.${payload.id}`,
			payload.hourlyTable
		);
	case SET_WORKPLACE_PRODUCT_TABLE:
		return prop.set(
			state,
			`workplaceProductTables.${payload.id}`,
			payload.productTable
		);
	case TOGGLE_TABLE:
		return prop.set(
			state,
			`expandedTables.${payload.type}.${payload.id}`,
			payload.toggled
		);
	case TOGGLE_PRODUCT_TABLE:
		return prop.set(
			state,
			`expandedTables.product.${payload.product}.${payload.id}`,
			payload.toggled
		);
	case SET_MARKET_HOVER_GROUP:
		return prop.set(state, `hoverGroup.${payload.table}`, payload.group);
	default:
		return state;
	}
}

export default reducer;
