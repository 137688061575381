import React, { useState } from 'react';
import PropTypes from 'prop-types';

// components
import { Modal, Text } from 'dumb';
import { ModalRow } from 'dumb/modal';

// phrases
import phrases from './shiftTransferPoliciesModal.phrases';

// styles
import './shiftTransferPoliciesModal.css';

const ShiftTransferPoliciesModal = ({
	onClose,
	shiftTransferPolicy,
	onAccept,
}) => {
	const onAcceptWrapper = () => {
		setLoading(true);

		onAccept().finally(() => {
			setLoading(false);
			onClose();
		});
	};

	const [loading, setLoading] = useState(false);

	return (
		<Modal
			header={phrases.TITLE}
			onCancelClick={onClose}
			type="confirmation"
			onConfirmClick={onAcceptWrapper}
			onClose={onClose}
			confirmButtonLabel={phrases.ACCEPT}
			cancelButtonLabel={phrases.DECLINE}
			loading={loading}>
			<ModalRow>
				<div className="shift-transfer-policies-modal__text-wrapper">
					<Text alignCenter>{shiftTransferPolicy.text}</Text>
				</div>
			</ModalRow>
		</Modal>
	);
};

ShiftTransferPoliciesModal.propTypes = {
	onClose: PropTypes.func,
	shiftTransferPolicy: PropTypes.object,
	onAccept: PropTypes.func,
};

export default ShiftTransferPoliciesModal;
