'use strict';

/**
 * ClassName
 * @param  {method} expression
 * @param  {string} className
 */
class ClassName {
	constructor(className, expression = null) {
		// Set type
		this.type = 'className';

		/// ////////////
		// ClassName //
		/// ////////////

		this.className = className;

		/// /////////////
		// Expression //
		/// /////////////

		this.expression = expression === null ? () => true : expression;
	}
}

export default ClassName;
