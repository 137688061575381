export default {
	TABLE_TITLE: 'Global product discounts',
	POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANTS_ADDED:
		'Pos configuration discount product variants added!',
	POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANTS_EDITED:
		'Pos configuration discount product variants edited',
	POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANTS_DELETED:
		'Pos configuration discount product variants deleted',

	MODAL_BATCH_STEP_FORM_DISCOUNTS: 'Choose discounts',
	MODAL_BATCH_STEP_FORM_DISCOUNTS_TIP:
		'Choose any number of discounts that will later be applied to product variants',
	MODAL_BATCH_STEP_FORM_PRODUCT_VARIANTS: 'Choose product variants',
	MODAL_BATCH_STEP_FORM_DISCOUNT_AMOUNT: 'Choose discount amounts',
	MODAL_BATCH_STEP_FORM_SELECT_PRODUCT_VARIANTS: 'Select product variants',
	MODAL_BATCH_STEP_FORM_PRODUCT_VARIANTS_PLACEHOLDER:
		'Select product variants...',
	MODAL_BATCH_STEP_FORM_SELECT_DISCOUNT_AMOUNTS: 'Select discount amounts',
	MODAL_BATCH_STEP_FORM_DISCOUNTS_PLACEHOLDER: 'Select discounts...',
	MODAL_BATCH_STEP_FORM_DISCOUNT_AMOUNTS_CONFIRM_BUTTON_LABEL:
		'Submit selection',
	MODAL_BATCH_STEP_FORM_PRODUCT_VARIANTS_BACK_BUTTON_LABEL: 'Back to discounts',
	MODAL_BATCH_STEP_FORM_DISCOUNT_AMOUNTS_BACK_BUTTON_LABEL:
		'Back to product variants',
	MODAL_BATCH_STEP_FORM_POS_CONFIGURATION_DISCOUNTS_TABLE_TITLE:
		'Pos configuration discounts',
	MODAL_BATCH_STEP_FORM_POS_CONFIGURATION_PRODUCT_VARIANTS_TABLE_TITLE:
		'Product variants',
	MODAL_BATCH_STEP_DISCOUNT_AMOUNTS_TO_STAY_DISCOUNT: 'To stay discount price',
	MODAL_BATCH_STEP_DISCOUNT_AMOUNTS_TO_STAY_DISCOUNT_PERCENTAGE:
		'To Stay Discount Price as percent of regular price',
	MODAL_BATCH_STEP_DISCOUNT_AMOUNTS_TO_GO_DISCOUNT: 'To go discount price',
	MODAL_BATCH_STEP_DISCOUNT_AMOUNTS_TO_GO_DISCOUNT_PERCENTAGE:
		'To Go Discount Price as percent of regular price',
	MODAL_BATCH_STEP_DISCOUNT_AMOUNTS_PLACEHOLDER: '123.45',
	MODAL_BATCH_STEP_DISCOUNT_AMOUNTS_PLACEHOLDER_PERCENTAGE: '%',
	MODAL_BATCH_STEP_LIST_TITLE: 'Review and submit',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Submit discounts',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'Back to discount amounts',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: 'Number',
	MODAL_BATCH_STEP_LIST_POS_CONFIGURATION_NAME: 'Pos configuration',
	MODAL_BATCH_STEP_LIST_POS_CONFIGURATION_VERSION_NAME:
		'Pos configuration version',
	MODAL_BATCH_STEP_LIST_POS_CONFIGURATION_DISCOUNT:
		'Pos configuration discount',
	MODAL_BATCH_STEP_LIST_PRODUCT_VARIANT: 'Product variant',
	MODAL_BATCH_STEP_LIST_POS_CONFIGURATION_PRODUCT_VARIANT_TO_STAY_PRICE:
		'To stay price',
	MODAL_BATCH_STEP_LIST_POS_CONFIGURATION_PRODUCT_VARIANT_TO_GO_PRICE:
		'To go price',
	MODAL_BATCH_STEP_LIST_TO_STAY_DISCOUNT: 'To stay discount price',
	MODAL_BATCH_STEP_LIST_TO_STAY_DISCOUNT_NEW: 'NEW To stay discount price',
	MODAL_BATCH_STEP_LIST_TO_GO_DISCOUNT: 'To go discount price',
	MODAL_BATCH_STEP_LIST_TO_GO_DISCOUNT_NEW: 'NEW To go discount price',
	MODAL_BATCH_STEP_LIST_ROW_DISABLED:
		'Discounts already exist or no Pos configuration product variant found.',
	MODAL_BATCH_STEP_LIST_LEGEND_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT_EXISTS:
		'Pos configuration discount product variant already exists',
	MODAL_BATCH_STEP_LIST_LEGEND_NO_POS_CONFIGURATION_PRODUCT_VARIANT:
		'No pos configuration discount product variant found',
	MODAL_BATCH_NO_POS_CONFIGURATION_PRODUCT_VARIANTS_FOUND: `Didn't find pos configuration product variants for any of discount/product variant combinations`,
	MODAL_BATCH_POS_CONFIGURATION_DISCOUNT_TABLE_FILTER_CLEAR_FILTER: 'Clear',
	MODAL_BATCH_TAG_TEXT: 'Selected POS Configuration Discount Product Variants:',
	LEGACY_FILTER_TOOLTIP_ACTIVE: 'Showing non legacy POS Configurations',
	LEGACY_FILTER_TOOLTIP_UNACTIVE: 'Showing all POS Configurations',
	BATCH_ADD_POS_CONFIGURATION_DISCOUNT_FILTER_TIP: 'Active date selected in the table will be applied here!',
};
