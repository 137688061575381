'use strict';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';

// REDUX PERSIST
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// import { LocalStorageService } from 'localStorage';

// //////////////////////
// Component reducers //
// //////////////////////

import * as shiftPlanner from 'shiftPlanner/reducers';

// sales
import salesControl from 'sales/salesControl/store/salesControl.reducer';
import financialPosExport from 'sales/financialPosExport/store/financialPosExport.reducer';
import adyenReconciliation from 'sales/adyenReconciliation/store/adyenReconciliation.reducer';
import adyenReconciliationMatrix from 'sales/adyenReconciliationMatrix/store/adyenReconciliationMatrix.reducer';
import drawerToBankMatrix from 'sales/drawerToBankMatrix/store/drawerToBankMatrix.reducer';
import transactionsLookup from 'sales/transactionsLookup/store/transactionsLookup.reducer';
import eodControl from 'sales/eodControl/store/eodControl.reducer';
import eodControlOld from 'sales/eodControlOld/eodControl.reducer';
import posSessionCreator from 'sales/eodControlOld/subcomponents/posSessionCreator/store/reducer';
import dynamics365Matrix from 'sales/dynamics365Matrix/store/dynamics365Matrix.reducer';

import goldModel from 'finance/goldModel/reducers/goldModel.reducer';

// reports
import dynamicPosView from 'reports/posReports/customComponents/dynamicPosView/dynamicPosView.reducer';
import employeeMealsReport from 'reports/employeeMealsReport/employeeMeals.reducer';
import shiftplannerReport from 'reports/shiftplannerReport/shiftplannerReport.reducer';
import dailyReport from 'reports/dailyReports/dailyReport.reducer';
import kpiReport from 'reports/kpiReport/store/kpiReport.reducer';
import powerBIReport from 'reports/powerBIReport/store/powerBIReport.reducer';

import fileList from 'fileList/fileList.reducer';
import fileUpload from 'fileUpload/fileUpload.reducer';
import hotbar from 'hotdamnbar/hotdamnbar.reducer';
import report from 'report/report.reducer';
import trainingHistoryReviews from 'shiftPlanner/components/trainingHistoryReview/trainingHistoryReview.reducer';
import pageLoader from 'modules/common/smart/pageLoader/store/pageLoader.reducer.js';
import globalAdministrationWaitingTimeGrid from 'globalAdministration/waitingTimeGrids/store/waitingTimeGrids.reducer.js';
import employmentPositionList from 'modules/structure/hr/juicers/components/juicerDetails/components/employmentPositionList/store/employmentPositionList.reducer';
import globalAdministrationWorkforceNews from 'globalAdministration/workforceNews/store/workforceNews.reducer';
import globalAdministrationFlairs from 'globalAdministration/flairs/store/flairs.reducer';
import globalAdministrationMarketShiftRules from 'globalAdministration/marketShiftRules/store/marketShiftRules.reducer';
import globalAdministrationPowerBIReports from 'globalAdministration/powerBIReports/store/powerBIReports.reducer';
import globalAdministrationPowerBIReportDatasets from 'globalAdministration/powerBIReports/components/powerBiReportDataSets/store/powerBiReportDataSets.reducer';
import globalAdministrationPowerBIReportTypes from 'globalAdministration/powerBIReportTypes/store/powerBIReportTypes.reducer';
import globalAdministrationShiftplanSeniority from 'globalAdministration/shiftplanSeniority/store/shiftplanSeniority.reducer';
import globalAdministrationEmployeeMealAppAllowances from 'globalAdministration/employeeMealAppAllowances/store/employeeMealAppAllowances.reducer';
import globalAdministrationPosTiers from 'globalAdministration/posTiers/store/posTiers.reducer';
import globalAdministrationStoreAccessTypes from 'globalAdministration/storeAccessTypes/store/storeAccessTypes.reducer';
import globalAdministrationWaitingTimePerformanceGrids from 'globalAdministration/waitingTimePerformanceGrids/store/waitingTimePerformanceGrids.reducer';
import globalAdministrationWaitingTimePerformanceGridEntries from 'globalAdministration/waitingTimePerformanceGrids/components/waitingTimePerformanceGridEntries/store/waitingTimePerformanceGridEntries.reducer';
import globalAdministrationWorkplaceWaitingTimePerformanceGrids from 'globalAdministration/waitingTimePerformanceGrids/components/workplaceWaitingTimePerformanceGrids/store/workplaceWaitingTimePerformanceGrids.reducer';
import globalAdministrationSystemSoftwares from 'globalAdministration/systemSoftwares/store/systemSoftwares.reducer';
import globalAdministrationSystemSoftwareVersions from 'globalAdministration/systemSoftwares/components/systemSoftwareVersions/store/systemSoftwareVersions.reducer';
import currencies from 'globalAdministration/currencies/store/currencies.reducer';

// global configuration raw ingredients
import globalAdministrationRawIngredientCategories from 'globalAdministration/rawIngredients/components/rawIngredientCategories/store/rawIngredientCategories.reducer';
import globalAdministrationGlobalRawIngredients from 'globalAdministration/rawIngredients/components/rawIngredientCategories/components/globalRawIngredients/store/globalRawIngredients.reducer';
import globalAdministrationRawIngredients from 'globalAdministration/rawIngredients/components/rawIngredientCategories/components/globalRawIngredients/components/rawIngredients/store/rawIngredients.reducer';
import globalAdministrationRawIngredientConfigurations from 'globalAdministration/rawIngredients/components/rawIngredientConfigurations/store/rawIngredientConfigurations.reducer';
import globalAdministrationRawIngredientConfigurationRawIngredients from 'globalAdministration/rawIngredients/components/rawIngredientConfigurations/components/rawIngredientConfigurationRawIngredients/store/rawIngredientConfigurationRawIngredients.reducer';
import globalAdministrationWorkplaceWaitingTimeGrids from 'globalAdministration/waitingTimeGrids/components/workplaceWaitingTimeGrids/store/workplaceWaitingTimeGrids.reducer';
import globalAdministrationMarketOvertimeRules from 'globalAdministration/marketOvertimeRules/store/marketOvertimeRules.reducer.js';
import globalAdministrationMarketShiftDeviationReasons from 'globalAdministration/marketShiftDeviationReasons/store/marketShiftDeviationReasons.reducer.js';
import globalAdministrationMarketGracePeriods from 'globalAdministration/marketGracePeriods/store/marketGracePeriods.reducer';
import globalAdministrationMarketShiftAlignmentRules from 'globalAdministration/marketShiftAlignmentRules/store/marketShiftAlignmentRules.reducer.js';
import globalAdministrationShiftTransferPolicies from 'globalAdministration/shiftTransferPolicies/store/shiftTransferPolicies.reducer';
import globalAdministrationLanguages from 'globalAdministration/languages/store/languages.reducer';
import tags from 'globalAdministration/tags/store/tags.reducer';
import shiftClockinCorrectionOptions from 'globalAdministration/shiftClockinCorrectionOptions/store/shiftClockinCorrectionOptions.reducer';
import dynamicAccounts from 'globalAdministration/dynamicAccounts/store/dynamicAccounts.reducer';
import vacationDescriptionPresets from 'globalAdministration/vacationDescriptionPresets/store/vacationDescriptionPresets.reducer';
import marketTags from 'globalAdministration/tags/components/marketTags/store/marketTags.reducer';
import currencyDenominations from 'globalAdministration/currencies/components/currencyDenominations/store/currencyDenominations.reducer';

// Salary
import salaryComponents from 'salary/salaryComponents/store/salaryComponents.reducer';
import salaryComponentsEditModal from 'salary/salaryComponents/components/editModalChoice/store/editModalChoice.reducer';
import salaryGroups from 'salary/salaryGroups/components/salaryGroups/store/salaryGroups.reducer';
import salaryExports from 'salary/salaryExports/store/salaryExports.reducer.js';
import shiftClockins from 'modules/structure/hr/shiftClockins/store/shiftClockins.reducer';
import docuSignTemplates from 'modules/structure/salary/docuSignTemplates/store/docuSignTemplates.reducer';
import docuSignTemplateDocuments from 'modules/structure/salary/docuSignTemplates/components/docuSignTemplateDocuments/store/docuSignTemplateDocuments.reducer';
import docuSignTemplateDocumentFields from 'modules/structure/salary/docuSignTemplates/components/docuSignTemplateDocuments/components/docuSignTemplateDocumentFields/store/docuSignTemplateDocumentFields.reducer';
import docuSignTemplateRoles from 'modules/structure/salary/docuSignTemplates/components/docuSignTemplateRoles/store/docuSignTemplateRoles.reducer';

// Shiftplanner (we could move them here?)
import clockingReport from 'shiftPlanner/components/clockinOutReport/store/clockinOutReport.reducer';
import personIllness from 'modules/structure/shiftPlanner/components/personIllness/store/personIllness.reducer';
import employeeVacations from 'shiftPlanner/components/employeeVacation/store/employeeVacation.reducer';
import * as myShiftPlanner from 'modules/structure/myShiftplanner/store';
import shiftplannerWeeklyTodos from 'modules/structure/shiftPlanner/components/shiftPlannerWorkplaceAdministration/components/weeklyRequirements/components/workplaceTodos/store/workplaceTodos.reducer';

// Sales Configuration
import * as salesConfiguration from 'modules/structure/salesConfiguration/salesConfiguration.reducer'; // Global index for all

import productAddons from 'modules/structure/salesConfiguration/components/product/components/productAddons/store/productAddons.reducer';
import productVariants from 'modules/structure/salesConfiguration/components/product/components/productVariant/store/productVariant.reducer';
import productVariantAddons from 'modules/structure/salesConfiguration/components/product/components/productVariant/components/subTablesWrapper/components/productVariantAddons/store/productVariantAddons.reducer';
import productVariantIngredients from 'modules/structure/salesConfiguration/components/product/components/productVariant/components/subTablesWrapper/components/productVariantIngredients/store/productVariantIngredients.reducer';
import product from 'modules/structure/salesConfiguration/components/product/components/product/store/product.reducer';
import assetCollection from 'modules/structure/salesConfiguration/components/assets/components/assetCollection/store/assetCollection.reducer';
import productNutritionCategories from 'modules/structure/salesConfiguration/components/nutrition/components/nutritionCategories/store/nutritionCategories.reducer';
import productNutritionValues from 'modules/structure/salesConfiguration/components/nutrition/components/nutritionValues/store/nutritionValues.reducer';
import ingredientAllergens from 'modules/structure/salesConfiguration/components/nutrition/components/ingredientAllergens/store/ingredientAllergens.reducer';
import allergens from 'modules/structure/salesConfiguration/components/nutrition/components/allergenCategories/components/allergens/store/allergens.reducer';
import allergenCategories from 'modules/structure/salesConfiguration/components/nutrition/components/allergenCategories/store/allergenCategories.reducer';
import productCategories from 'modules/structure/salesConfiguration/components/product/components/productCategories/store/productCategories.reducer';
import discounts from 'modules/structure/salesConfiguration/components/discounts/components/discounts/store/discounts.reducer';
import discountsProductSet from 'modules/structure/salesConfiguration/components/discounts/components/discounts/components/productSets/store/productSets.reducer';
import discountsProductSetOptions from 'modules/structure/salesConfiguration/components/discounts/components/discounts/components/productSets/components/store/productSetOptions.reducer';
import discountsIngredients from 'modules/structure/salesConfiguration/components/discounts/components/discounts/components/ingredients/store/ingredients.reducer';
import ingredients from 'modules/structure/salesConfiguration/components/ingredients/components/ingredients/store/ingredients.reducer';
import ingredientCategories from 'modules/structure/salesConfiguration/components/ingredients/components/ingredientCategories/store/ingredientCategories.reducer';
import ingredientPricingGroups from 'modules/structure/salesConfiguration/components/ingredients/components/ingredientPricingGroups/store/ingredientPricingGroups.reducer';
import ingredientVariations from 'modules/structure/salesConfiguration/components/ingredients/components/ingredientVariations/store/ingredientVariations.reducer';
import ingredientVariationOptions from 'modules/structure/salesConfiguration/components/ingredients/components/ingredientVariations/components/ingredientVariationOptions/store/ingredientVariationOptions.reducer';
import ingredientConfigurations from 'modules/structure/salesConfiguration/components/ingredients/components/ingredientConfigurations/store/ingredientConfigurations.reducer';
import ingredientConfigurationIngredients from 'modules/structure/salesConfiguration/components/ingredients/components/ingredientConfigurations/components/ingredientConfigurationIngredients/store/ingredientConfigurationIngredients.reducer';
import allergenTranslations from 'modules/structure/salesConfiguration/components/nutrition/components/allergenCategories/components/allergens/components/allergenTranslations/store/allergenTranslations.reducer';

// Sales configuration layout
import salesConfigurationPOSaddTile from 'modules/structure/salesConfiguration/components/layout/pos/addTileModal/store/addTileModal.reducer.js';
import salesConfigurationLoyaltyAddGroup from 'modules/structure/salesConfiguration/components/layout/pos/addGroupModal/store/addGroupModal.reducer.js';
import salesConfigurationPOSLayout from 'modules/structure/salesConfiguration/components/layout/pos/posConfigurationLayout.reducer';
import salesConfigurationPOSLists from 'modules/structure/salesConfiguration/components/layout/tileLayouts/store/tileLayout.reducer';

// HR
import moneyballPositionsTable from '../modules/structure/hr/moneyballPositions/moneyballPositionsTable/store/moneyballPositionsTable.reducer';
import employmentWorkplaces from '../modules/structure/hr/juicers/components/juicerDetails/components/employmentWorkplaces/store/employmentWorkplaces.reducer';

// Juicers related
// import personDetails from 'hr/juicers/components/personDetails/store/personDetails.reducer';
import juicerDetails from 'hr/juicers/components/juicerDetails/store/juicerDetails.reducer';
import trainings from 'hr/juicers/components/juicerDetails/components/trainings/store/trainings.reducer';
import illnessTable from 'hr/juicers/components/juicerDetails/components/vacationIllnessWrapper/illnessTable/store/illnessTable.reducer';
import vacationsTable from 'hr/juicers/components/juicerDetails/components/vacationIllnessWrapper/vacationsTable/store/vacationsTable.reducer';
import employeeTermination from 'hr/juicers/components/juicerDetails/components/terminateEmployee/store/terminateEmployee.reducer';
import employmentDocuments from 'hr/juicers/components/juicerDetails/components/employmentDocuments/store/employmentDocuments.reducer';
import employments from 'hr/juicers/components/juicerDetails/components/employmentsCard/store/employmentsCard.reducer';
import juicerUsersTable from 'hr/juicers/components/juicerDetails/components/attributesUsersWrapper/components/usersTable/store/usersTable.reducer';
import juicerPersonAttributesTable from 'hr/juicers/components/juicerDetails/components/attributesUsersWrapper/components/personAttributesTable/store/personAttributesTable.reducer';
import employmentList from 'modules/structure/hr/juicers/components/juicerDetails/components/employmentsList/store/employmentsList.reducer';
import personStoreAccess from 'modules/structure/hr/juicers/components/juicerDetails/components/personStoreAccess/store/personStoreAccess.reducer';
import juicerOperationalResponsibilities from 'modules/structure/hr/juicers/components/juicerDetails/components/attributesUsersWrapper/components/juicerOperationalResponsibilities/store/juicerOperationalResponsibilities.reducer';
import operationalResponsibilityOverview from 'modules/structure/hr/operationalResponsibilityOverview/store/operationalResponsibilityOverview.reducer';
import createOnboarding from 'hr/onboardings/components/createOnboarding/createOnboardings.reducer';
import tenantTransfer from 'modules/structure/hr/juicers/components/juicerDetails/components/employmentsList/components/tenantTransferModal/store/tenantTransferModal.reducer';
import marketResponsibilities from 'modules/structure/hr/juicers/components/juicerDetails/components/marketResponsibilities/store/marketResponsibilities.reducer';

// user management
import accessControlAdministration from 'modules/structure/userManagement/accessControlAdministration/store/accessControlAdministration.reducer';
import users from 'modules/structure/userManagement/users/store/users.reducer';

// Global
import staffDetails from 'staffDetails/store/staffDetails.reducer';
import listData from 'reactDataWrapper/reactDataWrapper.reducer';
import legacyListData from 'common/components/list/list.reducer';
import legacyDetailsData from 'common/components/details/details.reducer';
import filterSortColumnsData from 'reactDataWrapper/filterSortColumnsData.reducer';
import toggleColumnsData from 'reactDataWrapper/components/toggleColumns/store/toggleColumns.reducer';
import missingMoneyballNotificationModal from 'modules/common/smart/missingMoneyballNotificationModal/store/missingMoneyballNotificationModal.reducer';
import clockinDeviationApproval from 'modules/common/smart/clockinDeviationApproval/store/clockinDeviationApproval.reducer';
import shiftTransferApproval from 'modules/common/smart/shiftTransferApproval/store/shiftTransferApproval.reducer';
import sendDocuSignDocument from 'modules/common/components/sendDocuSignDocument/store/sendDocuSignDocument.reducer';

// organisation
import openingHours from 'organisation/workplaces/components/openingHours/store/openingHours.reducer';
import openingHoursEditor from 'organisation/workplaces/components/openingHours/components/openingHoursEditor/store/openingHoursEditor.reducer';
import organisationMarketShiftRules from 'organisation/markets/components/organisationMarketShiftRules/store/organisationMarketShiftRules.reducer';
import organisationMarketPayrollPeriods from 'organisation/markets/components/organisationMarketPayrollPeriods/store/organisationMarketPayrollPeriods.reducer';
import workplacePosConfigurationRelationWrapper from 'organisation/workplaces/components/workplacePosConfigurationRelationWrapper/store/workplacePosConfigurationRelationWrapper.reducer';
import workplaceExpectedWaitingTimes from 'organisation/workplaces/components/workplaceExpectedWaitingTimes/store/workplaceExpectedWaitingTimes.reducer';
import markets from 'organisation/markets/store/markets.reducer';
import workplaces from 'organisation/workplaces/store/workplaces.reducer';
import workplaceOperationalResponsibilities from 'organisation/workplaces/components/workplaceOperationalResponsibilities/store/workplaceOperationalResponsibilities.reducer';
import workplaceSystemSoftwares from 'organisation/workplaces/components/workplaceSystemSoftwares/store/workplaceSystemSoftwares.reducer';
import companies from 'organisation/companies/store/companies.reducer';
import storeFrontImages from '../modules/structure/organisation/workplaces/components/storeFrontImage/store/storeFrontImages.reducer';

import passwordForms from 'modules/structure/auth/store/passwordForms.reducer';
import shiftDetailsModalReducer from 'shiftDetailsModal/store/shiftDetailsModal.reducer';

// New?
// Yes
// lol
import { userReducer } from 'user/reducers';
import globalSearch from 'services/globalSearch/reducers/globalSearch.reducer';
import appSettings from 'services/appSettings/reducers/appSettings.reducer';
import notifier from 'modules/common/smart/notifier/notifier.reducer';
import globalNotifications from 'modules/common/reducers/globalNotifications.reducer';
import invalidTenantAccount from 'modules/common/smart/invalidTenantAccount/store/invalidTenantAccount.reducer';
import removeShiftsFromEmployee from 'modules/common/smart/removeShiftsFromEmployee/store/removeShiftsFromEmployee.reducer';
import confirmModalReducer from 'modules/common/dumb/modal/confirmModal/store/confirmModal.reducer';

const { createReduxHistory, routerMiddleware, routerReducer } =
	createReduxHistoryContext({
		history: createBrowserHistory(),
		// other options if needed
	});

const reducers = combineReducers({
	currencies,
	currencyDenominations,
	marketTags,
	marketResponsibilities,
	vacationDescriptionPresets,
	invalidTenantAccount,
	removeShiftsFromEmployee,
	allergenTranslations,
	shiftClockinCorrectionOptions,
	eodControl,
	tags,
	dynamics365Matrix,
	staffDetails,
	employmentWorkplaces,
	router: routerReducer,
	userData: userReducer,
	globalNotifications,
	appSettings,
	notifier,
	globalSearch,
	...shiftPlanner,
	...myShiftPlanner,
	// kpiReport,
	accessControlAdministration,
	allergenCategories,
	allergens,
	storeFronts: storeFrontImages,
	moneyballPositions: moneyballPositionsTable,
	assetCollection,
	clockingReport,
	dailyReport,
	discounts,
	discountsIngredients,
	discountsProductSet,
	discountsProductSetOptions,
	dynamicPosView,
	employeeMealsReport,
	employeeTermination,
	employeeVacations,
	employmentDocuments,
	employmentList,
	employmentPositionList,
	employments,
	fileList,
	fileUpload,
	filterSortColumnsData,
	financialPosExport,
	goldModel,
	hotbar,
	illnessTable,
	ingredientAllergens,
	ingredientCategories,
	ingredientConfigurationIngredients,
	ingredientConfigurations,
	ingredientPricingGroups,
	ingredients,
	ingredientVariationOptions,
	ingredientVariations,
	juicerDetails,
	juicerPersonAttributesTable,
	juicerUsersTable,
	listData,
	missingMoneyballNotificationModal,
	openingHours,
	openingHoursEditor,
	pageLoader,
	passwordForms,
	personIllness,
	product,
	productAddons,
	productCategories,
	productNutritionCategories,
	productNutritionValues,
	productVariantAddons,
	productVariantIngredients,
	productVariants,
	report,
	salaryComponents,
	salaryComponentsEditModal,
	salaryGroups,
	salaryExports,
	salesConfigurationLoyaltyAddGroup,
	salesConfigurationPOSaddTile,
	salesConfigurationPOSLayout,
	salesConfigurationPOSLists,
	salesControl,
	shiftplannerReport,
	shiftplannerWeeklyTodos,
	toggleColumnsData,
	trainingHistoryReviews,
	kpiReport,
	trainings,
	vacationsTable,
	globalAdministrationWaitingTimeGrid,
	globalAdministrationWorkforceNews,
	globalAdministrationFlairs,
	globalAdministrationRawIngredientCategories,
	globalAdministrationGlobalRawIngredients,
	globalAdministrationRawIngredients,
	globalAdministrationRawIngredientConfigurations,
	globalAdministrationRawIngredientConfigurationRawIngredients,
	globalAdministrationWorkplaceWaitingTimeGrids,
	globalAdministrationMarketShiftRules,
	organisationMarketShiftRules,
	organisationMarketPayrollPeriods,
	workplacePosConfigurationRelationWrapper,
	powerBIReport,
	globalAdministrationPowerBIReports,
	globalAdministrationPowerBIReportDatasets,
	globalAdministrationShiftplanSeniority,
	workplaceExpectedWaitingTimes,
	globalAdministrationEmployeeMealAppAllowances,
	globalAdministrationStoreAccessTypes,
	personStoreAccess,
	users,
	adyenReconciliationMatrix,
	adyenReconciliation,
	globalAdministrationMarketOvertimeRules,
	globalAdministrationPosTiers,
	drawerToBankMatrix,
	globalAdministrationMarketShiftDeviationReasons,
	globalAdministrationMarketGracePeriods,
	globalAdministrationMarketShiftAlignmentRules,
	workplaces,
	markets,
	shiftClockins,
	workplaceOperationalResponsibilities,
	juicerOperationalResponsibilities,
	operationalResponsibilityOverview,
	clockinDeviationApproval,
	globalAdministrationWaitingTimePerformanceGrids,
	globalAdministrationWaitingTimePerformanceGridEntries,
	globalAdministrationWorkplaceWaitingTimePerformanceGrids,
	shiftDetailsModalReducer,
	shiftTransferApproval,
	globalAdministrationShiftTransferPolicies,
	globalAdministrationSystemSoftwares,
	globalAdministrationSystemSoftwareVersions,
	workplaceSystemSoftwares,
	globalAdministrationLanguages,
	legacyListData,
	legacyDetailsData,
	createOnboarding,
	docuSignTemplates,
	docuSignTemplateDocuments,
	docuSignTemplateDocumentFields,
	docuSignTemplateRoles,
	sendDocuSignDocument,
	transactionsLookup,
	globalAdministrationPowerBIReportTypes,
	tenantTransfer,
	posSessionCreator,
	eodControlOld,
	companies,
	dynamicAccounts,
	confirmModalReducer,
	...salesConfiguration,
});

const middlewares = [routerMiddleware];
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Persis configuration
const persistConfig = {
	key: 'userData',
	storage: storage,
	whitelist: ['userData', 'hotbar'], // only navigation will be persisted
};
const persistedReducer = persistReducer(persistConfig, reducers);

// Create store
function jtjStore(preloadedState) {
	const store = createStore(
		persistedReducer,
		preloadedState,
		composeEnhancer(applyMiddleware(...middlewares))
	);

	return store;
}

const store = jtjStore();

export const persistor = persistStore(store);
export const history = createReduxHistory(store);
export default store;
