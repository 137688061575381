import React, { Component } from 'react';

import PropTypes from 'prop-types';
import DraggableRow from './components/draggableRow.component';
import { Loader } from 'dumb';
import Jedi from '../../../../../images/icons/icons/jedi';

import classnames from 'classnames';

import './smartTable.css';

class SmartTable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isToggled: false,
			isSearching: true,
			filteredData: [],
			searchValue: '',
			sortOrder: null,
			noResults: false,
		};

		this._getTableData = this._getTableData.bind(this);
		this._toggleTable = this._toggleTable.bind(this);
		this._renderFullData = this._renderFullData.bind(this);
		this._renderPartialData = this._renderPartialData.bind(this);
	}

	/**
	 * @function _renderDraggableData
	 * @description Renders the whole data set that is passed as the data prop and rows are draggable
	 */
	_renderDraggableData(renderedData) {
		const {
			activeRows,
			formatDraggableOutput,
			onRowItemClick,
			isRowHovered,
		} = this.props;

		return renderedData.map((rowData, index) => {
			return (
				<DraggableRow
					key={index}
					output={formatDraggableOutput}
					activeRows={activeRows}
					data={rowData}
					index={index}
					type="tile"
					onClick={onRowItemClick}
					isRowHovered={isRowHovered}
				/>
			);
		});
	}

	/**
	 * @function _renderFullData
	 * @description Renders the whole data set that is passed the data prop
	 */
	_renderFullData(renderedData) {
		const { activeRows, draggableRows } = this.props;

		if (draggableRows) return this._renderDraggableData(renderedData);

		return renderedData.map((rowData, index) => {
			return (
				<tr
					key={index}
					className={activeRows.includes(index) ? 'row--active' : ''}>
					{rowData.map((cellData, index) => {
						return <td key={cellData.value + index}>{cellData.value}</td>;
					})}
				</tr>
			);
		});
	}

	_renderPartialData() {
		const { numOfInitialRows, data } = this.props;
		const rows = [];

		for (let i = 0; i < numOfInitialRows; i++) {
			rows.push(
				<tr key={i} className="collapsable-table__default-row">
					{data[i].map((cellData, index) => {
						return <td key={cellData.value + index}>{cellData.value}</td>;
					})}
				</tr>
			);
		}

		return rows;
	}

	/**
	 * @function _getTableData
	 * @description returns the appropraite data for toggled/untoggled table
	 */
	_getTableData() {
		const { data, collapsable } = this.props;
		const { isToggled } = this.state;

		const renderedDataOrder = this._getRenderData(data);

		if (renderedDataOrder.length > 0) {
			if (collapsable && !isToggled) return this._renderPartialData();

			return this._renderFullData(renderedDataOrder);
		}
	}

	/**
	 * @function _getRenderData
	 * @description returns the dat
	 */
	_getRenderData() {
		const { data } = this.props;
		const { filteredData, isSearching, searchValue } = this.state;

		// If is searching and is not emptry string
		if (isSearching && searchValue !== '') return filteredData;
		// If there is a sort order
		if (this.state.sortOrder) return this._getDataFromSortOrder();
		// If no search or sort
		else return data;
	}

	/**
	 * @function _getDataFromSortOrder
	 * @description returns array of data from sort order
	 */
	_getDataFromSortOrder() {
		const { data } = this.props;

		return this.state.sortOrder.order.map((item) => {
			return data[item.index];
		});
	}

	_toggleTable() {
		this.setState((prevState) => ({ isToggled: !prevState.isToggled }));
	}

	_onSearch(value) {
		this.setState({ searchValue: value });

		const filteredData = value ? this._filterData(value) : this.props.data;
		this.setState({ filteredData });
	}

	_filterData(value) {
		const { data } = this.props;
		return data.filter((rowData) => {
			let matches = false;

			Object.keys(rowData).forEach((key) => {
				if (
					rowData[key].value &&
					rowData[key].value.toLowerCase().includes(value.toLowerCase())
				)
					matches = true;
			});

			if (matches) return rowData;
		});
	}

	_onSearchToggled() {
		this.setState({ isSearching: true });
	}

	_onSearchClose() {
		this.setState({ isSearching: false, searchValue: '', filteredData: [] });
	}

	// Table is sorted and an array of indexes is returned. The sorted data is not returned, an array of indexes is returned so that if
	// the table has complex data types or components as values the state is not bloated.
	_sortTable(headerIndex, header) {
		if (!header.sortable) return;

		const { data } = this.props;
		const { sortOrder } = this.state;
		let sort = 0;

		if (sortOrder && headerIndex === sortOrder.index) {
			if (sortOrder.sort === 1) return this.setState({ sortOrder: null });
			else sort = sortOrder.sort + 1;
		}

		const indexedArray = data.map((item, index) => {
			return { index: index, value: item[headerIndex].value };
		});
		const higherOrder = sort === 0 ? -1 : 1;
		const lowerOrder = sort === 0 ? 1 : -1;

		const order = indexedArray.sort((item, item2) => {
			const value = item.value;
			const value2 = item2.value;

			if (value < value2) {
				return higherOrder;
			}
			if (value > value2) {
				return lowerOrder;
			}
			return 0;
		});

		this.setState({ sortOrder: { index: headerIndex, order, sort } });
	}

	render() {
		const {
			headers,
			initialTopOffset,
			collapsable,
			theme,
			shadow,
			search,
			loading,
			data,
			draggableRows,
		} = this.props;
		const { isToggled, searchValue, sortOrder } = this.state;

		const offSet = !isToggled ? initialTopOffset : 0;
		const background = !isToggled ? 'initial' : '#fff';

		const tableWrapperStyles = {
			top: `${offSet}px`,
			transition: 'top .2s linear',
		};
		const containerStyles = {
			background: background,
			transition: 'background .2s ease-in',
		};

		const classname = classnames('smart-table', {
			'smart-table--dark': theme === 'dark',
			'smart-table--shadow': shadow,
			'smart-table--search': search,
		});

		return (
			<div className={classname} style={containerStyles}>
				{/* {search && (
					<Fragment>
						<CSSTransition
							classNames="slide-left"
							in={!isSearching}
							unmountOnExit
							mountOnEnter
							timeout={200}>
							<div
								className="smart-table__search-flag"
								onClick={() => this._onSearchToggled()}>
								<span className="icon icon--search" />
							</div>
						</CSSTransition>
						<CSSTransition
							classNames="expand-right"
							in={isSearching}
							unmountOnExit
							mountOnEnter
							timeout={200}>
							<div className="smart-table__search-bar">
								<input
									placeholder="Search"
									value={searchValue}
									onChange={event => this._onSearch(event.target.value)}
								/>
								<span
									className="icon icon--close"
									onClick={() => this._onSearchClose()}
								/>
							</div>
						</CSSTransition>
					</Fragment>
				)} */}

				{search && (
					<div className="smart-table__search-bar">
						<input
							placeholder="Search"
							value={searchValue}
							onChange={(event) => this._onSearch(event.target.value)}
						/>
						{/* <span
						className="icon icon--close"
						onClick={() => this._onSearchClose()}
					/> */}
					</div>
				)}
				<div className="smart-table__content-wrapper">
					<div
						className="smart-table__table-wrapper"
						style={tableWrapperStyles}>
						<div className="smart-table__scroll-wrapper">
							<table className="smart-table__table">
								<thead className="smart-table__header-wrapper">
									<tr>
										{headers.map((header, index) => (
											<th
												style={{ minWidth: header.width, width: header.width }}
												key={header.value + index}
												onClick={() => this._sortTable(index, header)}>
												{header.value}
												{sortOrder && index === sortOrder.index && (
													<span
														className="smart-table__sort-icon"
														style={
															sortOrder.sort === 0
																? { borderTop: 'solid 2px #8AD09E', top: '0' }
																: {
																		borderBottom: 'solid 2px #8AD09E',
																		bottom: '-1px',
																  }
														}
													/>
												)}
											</th>
										))}
									</tr>
								</thead>
								<tbody
									className={classnames('smart-table__body-wrapper', {
										'smart-table__body-wrapper--draggable': draggableRows,
									})}>
									{this._getTableData()}
								</tbody>
							</table>
						</div>
						{data.length === 0 && (
							<div className="smart-table__no-results">
								<span className="smart-table__no-results__text">No data</span>
							</div>
						)}
						{this.state.isSearching &&
							searchValue !== '' &&
							this.state.filteredData.length === 0 && (
								<div className="smart-table__no-results">
									<div className="smart-table__no-results__text">
										<Jedi height="65px" width="65px" />
										<div>Looking for some juice?</div>
									</div>
								</div>
							)}
						{loading && (
							<div className="smart-table__no-results">
								<Loader />
							</div>
						)}
						{collapsable && (
							<div className="smart-table__toggle-wrapper">
								<div
									className="smart-table__toggle"
									onClick={this._toggleTable}>
									{isToggled ? (
										<span className="icon icon--keyboard_arrow_up" />
									) : (
										<span className="icon icon--keyboard_arrow_down" />
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

SmartTable.propTypes = {
	// {value: "data"}
	data: PropTypes.array,
	// {value: "data", width: 100}
	headers: PropTypes.array,
	// Initial rows shown when table is collapsed
	numOfInitialRows: PropTypes.number,
	// Used for collapsable table, travel distance
	initialTopOffset: PropTypes.number,
	// Is table collapsable
	collapsable: PropTypes.bool,
	// Are rows draggable
	draggableRows: PropTypes.bool,
	// Rows that are active are marked with green
	activeRows: PropTypes.array,
	// Color theme for table
	theme: PropTypes.string,
	// Have shadow style
	shadow: PropTypes.bool,
	// Table searchability
	search: PropTypes.bool,
	loading: PropTypes.bool,
	formatDraggableOutput: PropTypes.func,
	onRowItemClick: PropTypes.func,
	isRowHovered: PropTypes.func,
};

SmartTable.defaultProps = {
	data: [],
	headers: [],
	numOfInitialRows: 1,
	initialTopOffset: 0,
	collapsable: false,
	draggableRows: false,
	activeRows: [],
	theme: 'light',
	search: false,
	sortable: true,
	loading: false,
	onRowItemClick: undefined,
	// formatDraggableOutput: e => ({ id: e.id, name: e.value })
};

export default SmartTable;
