export const UPDATE_INGREDIENT_CONFIGURATION =
	'INGREDIENT_CONFIGURATIONS/UPDATE_INGREDIENT_CONFIGURATION';
export const RESET_INGREDIENT_CONFIGURATION =
	'INGREDIENT_CONFIGURATIONS/RESET_INGREDIENT_CONFIGURATION';
export const SET_INGREDIENT_CONFIGURATION =
	'INGREDIENT_CONFIGURATIONS/SET_INGREDIENT_CONFIGURATION';

export function updateIngredientConfiguration(payload) {
	return {
		type: UPDATE_INGREDIENT_CONFIGURATION,
		payload
	};
}

export function resetIngredientConfiguration() {
	return {
		type: RESET_INGREDIENT_CONFIGURATION
	};
}

export function setIngredientConfiguration(payload) {
	return {
		type: SET_INGREDIENT_CONFIGURATION,
		payload
	};
}
