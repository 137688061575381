export default {
	SELECT_DISCOUNT: 'Select discount',
	PRODUCT_DISCOUNT_SETS: 'Discount set',
	DISCOUNT_SET_ADDED: 'Product discount associated.',
	DISCOUNT_SET_DELETED: 'Product set deleted.',

	// modal bathc
	MODAL_BATCH_STEP_FORM_DISCOUNT_TABLE_TITLE: 'Discounts',
	MODAL_BATCH_STEP_FORM_TITLE: 'Choose discounts',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'Next',
	MODAL_BATCH_STEP_LIST_TITLE: 'Confirm entries',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Submit',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'Back',
	MODAL_BATCH_STEP_DISCOUNT: 'Discount',
	MODAL_BATCH_STEP_DISCOUNT_PLACEHOLDER: 'Choose discount...',
	MODAL_BATCH_STEP_SET_NAME: 'Set name',
	MODAL_BATCH_STEP_SET_NAME_PLACEHOLDER: 'Choose set name...',
	MODAL_BATCH_STEP_FORM_DISCOUNTS_FILTER: 'Legacy',
};
