'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Date from 'reusableComponents/inputs/date/date.component';
import Text from 'reusableComponents/inputs/text.component';
import Select from 'reusableComponents/inputs/select.component';
import Checkbox from 'reusableComponents/inputs/checkbox.legacy.component';

import DirectInputService from './directInput.service';

class DirectInput extends Component {
	constructor(props) {
		super(props);

		this.DirectInputService = new DirectInputService();
		this.translations = this.DirectInputService.GetTranslations();
	}

	render() {
		const {
			inputFamily,
			inputFamiliyMember,
			onKeyDown,
			onBlur,
			onChange,
			initialValue,
			config
		} = this.props;

		switch (inputFamily) {
		case 'input':
			return (
				<Text
					type={inputFamiliyMember}
					onKeyDown={onKeyDown}
					onBlur={onBlur}
					onChange={onChange}
					initialValue={initialValue}
				/>
			);
		case 'checkbox':
			return (
				<Checkbox
					onKeyDown={onKeyDown}
					onChange={onBlur}
					initialValue={initialValue}
				/>
			);
		case 'select':
			return (
				<Select
					onChange={onChange}
					initialValue={initialValue}
					labelTemplate={config.labelTemplate}
					name={config.name}
					options={config.options}
					searchFields={config.searchFields}
					searchList={config.list}
					searchListData={config.searchListData}
					searchListFilter={config.searchListFilter}
				/>
			);
		case 'date': {
			return (
				<Date onChange={onChange} type="single" initialDate={initialValue} />
			);
		}
		default:
			return null;
		}
	}
}

DirectInput.propTypes = {
	onChange: PropTypes.func,
	onBlur: PropTypes.func.isRequired,
	onKeyDown: PropTypes.func,
	inputFamily: PropTypes.string.isRequired,
	inputFamilyMember: PropTypes.string,
	initialValue: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
		PropTypes.number,
		PropTypes.bool
	])
};

export default DirectInput;
