import { SET_CURRENT_STORE_FRONT } from './storeFrontImages.actions';
import { StoreFrontDefaultState, currentStoreFront } from '../types';
import { ActionType } from 'types/redux';

const defaultState: StoreFrontDefaultState = {
	currentStoreFront: undefined,
};

function reducer(
	state = defaultState,
	action: ActionType
): StoreFrontDefaultState {
	const { type, payload } = action;

	if (type === SET_CURRENT_STORE_FRONT) {
		return {
			currentStoreFront: payload as undefined | currentStoreFront,
		};
	} else {
		return state;
	}
}

export default reducer;
