/* eslint-disable react/prop-types */
import { IColumnHeader } from 'types/reactDataWrapper';

type SourceProps = 'market' | 'tag';

export default function getColumns(
	source: SourceProps
): IColumnHeader<IShiftplanningMarketTag>[] {
	const columns: IColumnHeader<IShiftplanningMarketTag>[] = [
		...(source === 'market'
			? [
					{
						Header: 'Tag',
						id: 'tag',
						accessor: (d: IShiftplanningMarketTag) => d.tag.name,
						filterPath: ':tag.name',
					},
			  ]
			: [
					{
						Header: 'Market',
						id: 'market',
						accessor: (d: IShiftplanningMarketTag) => d.market.name,
						filterPath: ':market.name',
					},
			  ]),
		{
			Header: 'Default length',
			id: 'default-length',
			accessor: 'default_length_in_minutes',
			filterPath: ':default_length_in_minutes',
		},
		{
			Header: 'Mins from start',
			id: 'minutes-from-start',
			accessor: 'minutes_from_start_of_shift',
			filterPath: ':minutes_from_start_of_shift',
		},
		{
			Header: 'Mins from end',
			id: 'minutes-from-end',
			accessor: 'minutes_from_end_of_shift',
			filterPath: ':minutes_from_end_of_shift',
		},
	];

	return columns;
}
