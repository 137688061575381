'use strict';

import {
	HotbarDateRangeResultSet,
	HotbarSelectResultSet,
	HotbarSingleDateResultSet,
	HotbarNumberResultSet
} from 'hotdamnbar/resultSets';

class HotbarResultSet {

	constructor(hotbar) {
		// Set empty results
		this.results = {};

		// Add hotbar component to resultSet
		this.addComponentResultSets(hotbar.getComponents());
	}

	/**
	 * @function addComponentResultSets
	 * @memberOf HotbarResultSet
	 * @description Add resultset for all components
	 * @param {object} components
	 */
	addComponentResultSets(components) {
		Object.keys(components).forEach(key => this.addComponentResultSet(components[key]));
	}

	/**
	 * @function addComponentResultSet
	 * @memberOf HotbarResultSet
	 * @description Add resultset for indiviual component
	 * @param {object} component
	 */
	addComponentResultSet(component) {
		let componentResultSet;
		switch (component.type) {
			case 'dateRange':
				componentResultSet = new HotbarDateRangeResultSet(component);
				break;
			case 'select':
				componentResultSet = new HotbarSelectResultSet(component);
				break;
			case 'singleDate':
				componentResultSet = new HotbarSingleDateResultSet(component);
				break;
			case 'number':
				componentResultSet = new HotbarNumberResultSet(component);
				break;
			case 'separator':
				return;
		}
		this.results[component.title] = componentResultSet;
	}
}

export default HotbarResultSet;