import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchAllergenCategories
 * @description Fetch all allergenes cats
 * @param {object} state 
 */
export function fetchAllergenCategories(state) {

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get(
		`/product/allergen_categories`,
		params,
		null,
		state.headers
	)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editAllergenCategories
 * @description edit
 */
export function editAllergenCategories(allergenId, data) {
	return post(`/product/allergen_categories/${allergenId}`, data)
		.then(res => {
			setFeedback('Allergen edited', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function addAllergens
 * @description add
 */
export function addAllergenCategories(data) {
	return post(`/product/allergen_categories`, data)
		.then(res => {
			setFeedback('Allergen added', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteAllergenCategories
 * @description del
 */
export function deleteAllergenCategories(allergenId) {
	return remove(`/product/allergen_categories/${allergenId}`)
		.then(res => {
			setFeedback('Allergen deleted', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}