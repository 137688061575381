'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// rdw actions
import { store } from 'appState';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';

// components
import { ModalStepper, Overlay, Button } from 'dumb';
import Checkbox from 'inputs/checkbox';
import { ReactDataWrapper } from 'reactDataWrapper';

// different batch views
import WorkplacePosConfigurationBatchPosConfigurationsAdd from './components/workplacePosConfigurationBatchPosConfigurationsAdd';
import WorkplacePosConfigurationBatchWorkplacesAdd from './components/workplacePosConfigurationBatchWorkplacesAdd';
import WorkplacePosConfigurationBatchStepFormAdd from './components/workplacePosConfigurationBatchStepFormAdd';
import WorkplacePosConfigurationBatchStepListAdd from './components/workplacePosConfigurationBatchStepListAdd';

// utils
import {
	getMarketsFilter,
	getWorkplacePosConfigurationFilter,
	markValuesWithCanAdd,
} from './../../utils/util';

// phrases
import phrases from './../../workplacePosConfiguration.phrases';
import constants from 'services/constants';

// loooodash
import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';
import _uniqBy from 'lodash/uniqBy';
import _isEmpty from 'lodash/isEmpty';

class WorkplacePosConfigurationModalBatchAdd extends Component {
	constructor(props) {
		super(props);

		this.today = moment.utc().format(constants.shortDate);

		this.posConfigurationReduxKey =
			'workplace-pos-configuration-batch-/pos/pos_configurations';
		this.workplaceReduxKey =
			'workplace-pos-configuration-batch-/administration/workplaces';
		this.productRecommendationUrl = '/pos/product_recommendations';
		this.tileLayoutUrl = '/pos/tile_layouts';
		this.ingredientConfigurationUrl = '/pos/ingredient_configurations';

		this.state = {
			showOverlay: false,
			overlayType: null,
			activeFilterOn: true,
			activeFilter: `:active.to=ge='${this.today}'`,
			allowOverflow: true,
			legacyFilterOn: true,
			legacyFilter: ':legacy==false',
			workplaceFilterOn: true,
			workplaceFilter: `:closed=ge='${this.today}'`,
		};

		this.handleToggleOverlay = this.handleToggleOverlay.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.onOverlayOpen = this.onOverlayOpen.bind(this);
		this.handleToggleActiveFilter = this.handleToggleActiveFilter.bind(this);
		this._getTableExtraFilters = this._getTableExtraFilters.bind(this);
		this._getTableFilterButtons = this._getTableFilterButtons.bind(this);
		this._submitPosConfigurations = this._submitPosConfigurations.bind(this);

		this.modalRef = React.createRef();

		this.columnsPosConfiguration = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: (d) => _get(d, 'description', ''),
				filterPath: ':description',
			},
			{
				Header: 'Market',
				id: 'market',
				accessor: (d) => _get(d, 'market.name', ''),
				filterPath: ':market.name',
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: (d) => _get(d, 'legacy', ''),
				Cell: (d) => {
					return (
						<Checkbox
							id="tableCheckboxDisabled"
							disabled
							checked={d.original.legacy}
							noBorder
						/>
					);
				},
				filterPath: ':legacy',
				filterable: false,
			},
		];
		this.columnsWorkplace = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Type',
				id: 'type',
				accessor: (d) => _get(d, 'type', ''),
				filterPath: ':type',
			},
			{
				Header: 'Market',
				id: 'market',
				accessor: (d) => _get(d, 'market.name', ''),
				filterPath: ':market.name',
			},
			{
				Header: 'Time zone',
				id: 'timeZone',
				accessor: (d) => _get(d, 'time_zone', ''),
				filterPath: ':time_zone',
			},
			{
				Header: 'Sort order',
				id: 'sortOrder',
				accessor: (d) => _get(d, 'sort_order', ''),
				filterPath: ':sort_order',
				filterable: false,
			},
		];
	}

	handleToggleOverlay(type) {
		const {
			listPosConfigurations,
			listWorkplaces,
			updateBatchForm,
		} = this.props;

		if (this.state.showOverlay) {
			if (this.state.overlayType === this.posConfigurationReduxKey) {
				const selectedRowsInList = _get(
					listPosConfigurations,
					'ui.selectedRows',
					[]
				);

				updateBatchForm({
					posConfiguration: selectedRowsInList.map((entry) => {
						return { label: entry.name, value: entry };
					}),
				});
			} else {
				const selectedRowsInList = _get(listWorkplaces, 'ui.selectedRows', []);

				updateBatchForm({
					workplace: selectedRowsInList.map((entry) => {
						return { label: entry.name, value: entry };
					}),
				});
			}
		}

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
			overlayType: prevState.overlayType ? '' : type,
		}));
	}

	onOverlayOpen() {
		const { batchFormData } = this.props;

		const dataToSetColumnsSelected =
			this.state.overlayType === this.posConfigurationReduxKey
				? batchFormData.posConfiguration
				: batchFormData.workplace;

		if (_isEmpty(dataToSetColumnsSelected)) return;

		// set already selected data in rdw store as clicked
		dataToSetColumnsSelected.map((entry) => {
			store.dispatch(
				actions.addRowToSelection({
					reduxKey: this.state.overlayType,
					data: entry.value,
				})
			);
		});
	}

	_submitPosConfigurations() {
		const { batchFormData, updateBatchForm } = this.props;

		// get all market id's to filter workplaces on
		const marketsFilter = getMarketsFilter(batchFormData.posConfiguration);

		updateBatchForm({
			marketsFilter,
		});

		this._goToStep(2);
	}

	_onSubmitForm() {
		const {
			batchFormData,
			addBatchList,
			fetchWorkplaceToPosConfigurationRelations,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const cleanPosConfigurations = _uniqBy(
			batchFormData.posConfiguration,
			'value.id'
		);
		const cleanWorkplaces = _uniqBy(batchFormData.workplace, 'value.id');

		const listArray = cleanPosConfigurations.reduce((acc, x) => {
			const marketId = x.value.market.id;

			// find all workplaces for marketId
			const workplaces = cleanWorkplaces.filter(
				(entry) => entry.value.market.id === marketId
			);

			let list = [];
			if (!_isEmpty(workplaces))
				list = workplaces.map((y) => {
					return {
						identifier: _uniqueId('row-'), // used to identify what to edit on step#2
						posConfiguration: x.value,
						workplace: y.value,
						productRecommendation: batchFormData?.productRecommendation?.value,
						posTileLayout: batchFormData?.posTileLayout?.value,
						appTileLayout: batchFormData?.appTileLayout?.value,
						ingredientConfiguration:
							batchFormData?.ingredientConfiguration?.value,
						activeFrom:
							batchFormData?.activeFrom ||
							moment.utc().format(constants.shortDate),
						activeTo:
							batchFormData?.activeTo ||
							moment.utc('9999-12-31').format(constants.shortDate),
					};
				});

			return [...acc, ...list];
		}, []);

		// get filter with workplace ids and active periods
		const filter = getWorkplacePosConfigurationFilter(listArray);

		// fetch complete collection
		fetchWorkplaceToPosConfigurationRelations({
			filter,
			shouldFetchCompleteCollection: true,
		})
			.then((res) => {
				// mark values that exist with canAdd: false
				const markedValues = markValuesWithCanAdd({ listArray, res: res.data });

				addBatchList(markedValues);

				this.setState(() => ({
					loadingModal: false,
				}));

				this._goToStep(4);
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	_goToStep(step) {
		this.setState(() => ({ allowOverflow: step !== 4 }));

		this.modalRef.current.goToStep(step);
	}

	_batchSubmit() {
		const {
			batchListData,
			handleClose,
			addWorkplaceToPosConfigurationRelation,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		// filter out the ones we cant add
		const payload = batchListData
			.filter((entry) => entry.canAdd)
			.map((entry) => {
				return {
					pos_configuration: _get(entry, 'posConfiguration.id', null),
					workplace: _get(entry, 'workplace.id', null),
					product_recommendation: _get(entry, 'productRecommendation.id', null),
					pos_tile_layout: _get(entry, 'posTileLayout.id', null),
					app_tile_layout: _get(entry, 'appTileLayout.id', null),
					ingredient_configuration: _get(
						entry,
						'ingredientConfiguration.id',
						null
					),
					active: {
						from: moment
							.utc(_get(entry, 'activeFrom', null))
							.format(constants.shortDate),
						to: moment
							.utc(_get(entry, 'activeTo', null))
							.format(constants.shortDate),
					},
				};
			});

		addWorkplaceToPosConfigurationRelation({ batch: payload })
			.then(() => {
				handleClose();
				this.setState(() => ({
					loadingModal: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	handleToggleActiveFilter() {
		this.setState((prevState) => ({
			activeFilterOn: !prevState.activeFilterOn,
			activeFilter: prevState.activeFilterOn
				? ''
				: `:active.to=ge='${this.today}'`,
		}));
	}

	fetchData(state) {
		const { fetchPosConfigurations, fetchWorkplaces } = this.props;

		if (this.state.overlayType === this.posConfigurationReduxKey)
			return fetchPosConfigurations(state);
		else return fetchWorkplaces(state);
	}

	_getTableExtraFilters() {
		const { batchFormData } = this.props;

		if (
			this.state.overlayType === this.posConfigurationReduxKey &&
			this.state.legacyFilterOn
		)
			return this.state.legacyFilter;
		if (this.state.overlayType === this.workplaceReduxKey)
			return this.state.workplaceFilterOn
				? `${this.state.workplaceFilter};:market.id=IN=${batchFormData.marketsFilter}`
				: `:market.id=IN=${batchFormData.marketsFilter}`;
	}

	_getTableFilterButtons() {
		let buttonType, buttonText, buttonOnClick;

		if (this.state.overlayType === this.posConfigurationReduxKey) {
			buttonType = this.state.legacyFilterOn ? '' : 'inverted';
			buttonText = phrases.MODAL_BATCH_STEP_FORM_TABLE_LEGACY_FILTER;
			buttonOnClick = () =>
				this.setState((prevState) => ({
					legacyFilterOn: !prevState.legacyFilterOn,
				}));
		} else {
			buttonType = this.state.workplaceFilterOn ? '' : 'inverted';
			buttonText = phrases.MODAL_BATCH_STEP_FORM_TABLE_WORKPLACE_FILTER;
			buttonOnClick = () =>
				this.setState((prevState) => ({
					workplaceFilterOn: !prevState.workplaceFilterOn,
				}));
		}

		return (
			<div className="salary-component__filters ">
				<Button size="tiny" type={buttonType} shadow onClick={buttonOnClick}>
					{buttonText}
				</Button>
			</div>
		);
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchListData,
			editBatchListItem,
			removeBatchListItem,
		} = this.props;
		const steps = [
			{
				component: (
					<WorkplacePosConfigurationBatchPosConfigurationsAdd
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.handleToggleOverlay}
						posConfigurationReduxKey={this.posConfigurationReduxKey}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_ONE_FORM_TITLE,
				isValid: !_isEmpty(batchFormData.posConfiguration),
				onNext: () => this._submitPosConfigurations(2),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				noScroll: false,
			},
			{
				component: (
					<WorkplacePosConfigurationBatchWorkplacesAdd
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.handleToggleOverlay}
						workplaceReduxKey={this.workplaceReduxKey}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_TWO_FORM_TITLE,
				isValid: !_isEmpty(batchFormData.workplace),
				onNext: () => this._goToStep(3),
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				noScroll: false,
			},
			{
				component: (
					<WorkplacePosConfigurationBatchStepFormAdd
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.handleToggleOverlay}
						productRecommendationUrl={this.productRecommendationUrl}
						tileLayoutUrl={this.tileLayoutUrl}
						ingredientConfigurationUrl={this.ingredientConfigurationUrl}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE,
				isValid: !_isEmpty(batchFormData.productRecommendation),
				onNext: () => this._onSubmitForm(),
				onBack: () => this._goToStep(2),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				noScroll: false,
			},
			{
				component: (
					<WorkplacePosConfigurationBatchStepListAdd
						batchListData={batchListData}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
						productRecommendationUrl={this.productRecommendationUrl}
						tileLayoutUrl={this.tileLayoutUrl}
						ingredientConfigurationUrl={this.ingredientConfigurationUrl}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				onBack: () => this._goToStep(3),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						className="workplace-pos-configuration__modal-batch"
						ref={this.modalRef}
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={this.state.showOverlay ? () => {} : handleClose}
						allowOverflow={this.state.allowOverflow}
					/>
				)}

				{this.state.showOverlay && (
					<Overlay
						zIndex={551}
						height={850}
						list
						visible={this.state.showOverlay}
						onClose={() => {
							this.handleToggleOverlay();
						}}>
						<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
							title={
								this.state.overlayType === this.posConfigurationReduxKey
									? phrases.MODAL_BATCH_STEP_FORM_POS_CONFIGURATION_TABLE_TITLE
									: phrases.MODAL_BATCH_STEP_FORM_WORKPLACE_TABLE_TITLE
							}
							columns={
								this.state.overlayType === this.posConfigurationReduxKey
									? this.columnsPosConfiguration
									: this.columnsWorkplace
							}
							fetchData={this.fetchData}
							filterable
							disableFetchCsvButton
							batchSelection
							defaultPageSize={20}
							customAreaComponents={this._getTableFilterButtons()}
							extraFilters={this._getTableExtraFilters()}
							onInitialization={() => this.onOverlayOpen()}
							reduxKey={this.state.overlayType}
							manual
							actionsWidth={0}
							style={{
								maxHeight: '720px',
							}}
						/>
					</Overlay>
				)}
			</>
		);
	}
}

WorkplacePosConfigurationModalBatchAdd.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	listPosConfigurations: PropTypes.object,
	listWorkplaces: PropTypes.object,
	addBatchList: PropTypes.func,
	fetchPosConfigurations: PropTypes.func,
	fetchWorkplaces: PropTypes.func,
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addWorkplaceToPosConfigurationRelation: PropTypes.func,
	fetchWorkplaceToPosConfigurationRelations: PropTypes.func,
};

export default WorkplacePosConfigurationModalBatchAdd;
