'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { Input, Button, Icon } from 'dumb';

import { ReactDataWrapper } from 'reactDataWrapper';
import { submitBatchValues } from 'reactDataWrapper/utilities/batchHelper';
import SubTable from 'reactDataWrapper/components/subTable';

import {
	fetchRawIngredientConfigurations,
	addRawIngredientConfigurations,
	deleteRawIngredientConfiguration,
	editRawIngredientConfiguration,
	editRawIngredientConfigurations
} from './rawIngredientConfigurations.service';

import {
	setRawIngredientConfigurationsModal,
	updateRawIngredientConfigurations,
	resetState,
	updateBatchForm,
	resetBatch,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow
} from './store/rawIngredientConfigurations.actions';

// components
import ModalBatch from './components/batch/rawIngredientConfigurationsModalBatch';
import RawIngredientConfigurationRawIngredients from './components/rawIngredientConfigurationRawIngredients/rawIngredientConfigurationRawIngredients';

// constants/phrases/tools
import phrases from './rawIngredientConfigurations.phrases';
import _get from 'lodash/get';

// styles
import './style.css';

class RawIngredientConfigurations extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false
		};

		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.getEditableCells = this.getEditableCells.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this._toggleModal = this._toggleModal.bind(this);
		this.batchAdd = this.batchAdd.bind(this);

		this.reduxKey = '/inventory/raw_ingredient_configurations';

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: d => _get(d, 'name', ''),
				filterPath: ':name'
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: d => _get(d, 'description', ''),
				filterPath: ':description'
			}
		];
	}

	editEntry() {
		const { rawIngredientConfigurations } = this.props;

		const payload = {
			id: rawIngredientConfigurations.id,
			name: rawIngredientConfigurations.name,
			description: rawIngredientConfigurations.description
		};

		return editRawIngredientConfiguration(payload);
	}

	editMultiple(selectedRows) {
		const { rawIngredientConfigurations } = this.props;

		const payload = {
			...(rawIngredientConfigurations.name && {
				name: rawIngredientConfigurations.name
			}),
			...(rawIngredientConfigurations.description && {
				description: rawIngredientConfigurations.description
			})
		};

		const selectedRowsWithId = selectedRows.map(row => {
			return {
				id: row.id,
				...payload
			};
		});

		return editRawIngredientConfigurations({
			batch: selectedRowsWithId
		});
	}

	deleteEntry(id) {
		return deleteRawIngredientConfiguration(id);
	}

	setInitialEditValues(data) {
		const { setRawIngredientConfigurationsModal } = this.props;

		const payload = {
			id: data.id,
			name: data.name,
			description: data.description
		};

		setRawIngredientConfigurationsModal(payload);
	}

	getEditableCells() {
		const { rawIngredientConfigurations } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={rawIngredientConfigurations.name || ''}
						onChange={e => this.editStoreEntry('name', e.target.value)}
					/>
				)
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={rawIngredientConfigurations.description}
						onChange={e => this.editStoreEntry('description', e.target.value)}
					/>
				)
			}
		];
	}

	editStoreEntry(name, value) {
		const { updateRawIngredientConfigurations } = this.props;

		const payload = {
			[name]: value
		};

		updateRawIngredientConfigurations(payload);
	}

	_toggleModal() {
		this.setState(prevState => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	batchAdd(payload) {
		return addRawIngredientConfigurations(payload)
			.then(res => {
				submitBatchValues({ res, reduxKey: this.reduxKey });
				return res;
			})
			.catch(err => {
				throw err;
			});
	}

	render() {
		const {
			batchList,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			updateBatchForm,
			addBatchListItemRow
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={fetchRawIngredientConfigurations}
					filterable
					defaultPageSize={10}
					reduxKey={this.reduxKey}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					accessAreasAllowedToEdit={['Global Sales Configuration', 'Sales Configuration']}
					deleteEntry={this.deleteEntry}
					editMultiple={this.editMultiple}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					actionRender={
						<Button
							type="inverted"
							label="Add"
							shadow
							onClick={() => this._toggleModal()}>
							<Icon name="add" />
						</Button>
					}
					subcomponent={row => (
						<SubTable>
							<RawIngredientConfigurationRawIngredients
								rawIngredientConfiguration={row.original}
							/>
						</SubTable>
					)}
				/>

				<ModalBatch
					modalVisible={this.state.showModal}
					handleClose={this._toggleModal}
					updateBatchForm={updateBatchForm}
					addBatchList={addBatchList}
					batchList={batchList}
					addRawIngredientConfigurations={this.batchAdd}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
				/>
			</>
		);
	}
}

RawIngredientConfigurations.propTypes = {
	setRawIngredientConfigurationsModal: PropTypes.func,
	resetState: PropTypes.func,
	updateRawIngredientConfigurations: PropTypes.func,
	rawIngredientConfigurations: PropTypes.object,
	batchList: PropTypes.array,
	addBatchList: PropTypes.func,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	updateBatchForm: PropTypes.func,
	addBatchListItemRow: PropTypes.func
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			setRawIngredientConfigurationsModal,
			updateRawIngredientConfigurations,
			resetState,
			updateBatchForm,
			resetBatch,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			addBatchListItemRow
		},
		dispatch
	);
};

const mapStateToProps = store => {
	return {
		rawIngredientConfigurations:
			store.globalAdministrationRawIngredientConfigurations.data
				.rawIngredientConfigurations,
		batchList:
			store.globalAdministrationRawIngredientConfigurations.data.batchList
	};
};

export default connectWithStore(
	RawIngredientConfigurations,
	mapStateToProps,
	mapDispatchToProps
);
