'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';
const __endpoints = require('endpoints.service');

export default class IngredientsList extends ListModel  {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'ingredient_category',
				type: 'select',
				canAdd: true,
				required: true,
				reference: {
					key: 'name',
					list: '/product/ingredient_categories',
				},
			},
		];
		this.settings = {
			name: 'IngredientsList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			pageTitle: true,
			translationPath: 'SALE.INGREDIENTS',
			canManipulate: () => validAccess(undefined, ['Global BI Configuration']),
			endpoint: __endpoints.collection.product.ingredients,
		};
		this.init();
		return this;
	}
}
