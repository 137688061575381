'use strict';

export const TOGGLE_DAY_SETTINGS_MODAL = 'DAY_SETTINGS/TOGGLE_DAY_SETTINGS_MODAL';
export const SET_DAY_SETTINGS_MODAL_DATA = 'DAY_SETTINGS/SET_DAY_SETTINGS_MODAL_DATA';

/**
 * @param {string} argument
 */
export function toggleDaySettingsModal(showDaySettingsModal) {
	return {
		type: TOGGLE_DAY_SETTINGS_MODAL,
		payload: {
			showDaySettingsModal,
		}
	};
}

/**
 * @param {string} argument
 */
export function setDaySettingsModalData(dateOfDaySettings, openingsHours) {
	return {
		type: SET_DAY_SETTINGS_MODAL_DATA,
		payload: {
			dateOfDaySettings,
			openingsHours
		}
	};
}