import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchAllNutritionCategoryTranslations
 * @description Fetch all allergen translations
 */
export function fetchAllNutritionCategoryTranslations(id, state) {
	const preFilter = `:nutrition_category.id=='${id}'`;
	const filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(`/product/nutrition_category_translations`, params, null, state.headers).catch((err) => {
		const errorMessage = formatErrorMessage(err);
		setFeedback(errorMessage, 0);
	});
}

/**
 * @function addNutritionCategoryTranslations
 * @description add
 */
export function addNutritionCategoryTranslations(data) {
	return post(`/product/nutrition_category_translations`, data)
		.then((res) => {
			setFeedback('Nutrition category translation added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editNutritionCategoryTranslation
 */
export function editNutritionCategoryTranslation(data) {
	return post(`/product/nutrition_category_translations/${data.id}`, data)
		.then((res) => {
			setFeedback('Nutrition category translation edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteNutritionCategoryTranslation
 */
export function deleteNutritionCategoryTranslation(id) {
	return remove(`/product/nutrition_category_translations/${id}`)
		.then((res) => {
			setFeedback('Nutrition category translation deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
