'use strict';

export default {
	TRAINING_EXAM_TABLE: 'Trainings & Exams',
	PERSONAL_DETAILS_CARD: 'Personal Details',
	ADDRESS_DETAILS_CARD: 'Address details',
	CONTACT_DETAILS_CARD: 'Contact Details',
	JUICER_STATUS_CARD: 'Juicer Status',
	USERS_TABLE: 'Users',
	USERS_TABLE_ADD_USER_MODAL_TEXT: 'Are you sure you want to add a new user?',
	USERS_TABLE_ADD_USER_MODAL_HEADER: 'Add user',
	USERS_TABLE_ADD_USER_MODAL_HEADER_TOGGLE_USER: 'Toggle user',
	USERS_TABLE_ADD_USER_MODAL_TEXT_TOGGLE_USER_ACTIVE:
		'Are you sure you want to activate user?',
	USERS_TABLE_ADD_USER_MODAL_TEXT_TOGGLE_USER_UNACTIVE:
		'Are you sure you want to deactivate user?',
	USERS_TABLE_ADD_USER_BUTTON: 'Add',
	USERS_TABLE_TOGGLE_USER_BUTTON: 'Toggle',
	CREATE: 'Create',
	NO_ADDRESS_REGISTERED: 'No address registered',
	USERS_TABLE_ADD_USER_TOGGLE_USER_TOOLTIP: "Toggle user's active state",
	APP_USER: 'App user:',
	HOURS_WORKED: 'Hours worked:',
	RESET_2FA: 'Reset 2FA',
	TWO_FACTOR_RESET: '2FA reset',
};
