'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { batch } from 'react-redux';

import { Button, Input, InputCollectionSelect, Icon } from 'dumb';
import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';
import { ModalRow } from 'dumb/modal';
import getMarketColumns from 'reactDataWrapperColumns/administration/markets.columns';

// phrases/ constants/utils
import phrases from './../../../ingredientComponents.phrases';

class IngredientComponentsBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		const value = e?.target?.value ?? e;

		// edits the row in the store
		this.props.editBatchListItem({
			identifier: row.identifier,
			[type]: value,
		});
	}

	_setGlobalMarket(name, value) {
		const { updateBatchForm, batchList } = this.props;

		const payload = {
			[name]: value,
		};

		batch(() => {
			updateBatchForm(payload);
			for (const row of batchList) {
				this._editListEntry(value, row, name);
			}
		});
	}

	render() {
		const { batchForm, batchList, addBatchListItemRow, useGlobalResource } = this.props;

		return (
			<>
				{!useGlobalResource && (
					<ModalRow className="ingredient-components__global-market-wrapper">
						<InputCollectionSelect
							id="market"
							label={phrases.MODAL_BATCH_STEP_MARKET}
							placeholder={phrases.MODAL_BATCH_STEP_MARKET_PLACEHOLDER_GLOBAL}
							value={batchForm.market}
							handleChange={(key, value) => this._setGlobalMarket('market', value)}
							cache
							apiPath="/administration/markets"
							params={{
								limit: 30,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
							tableColumns={getMarketColumns()}
							tableTitle={phrases.MODAL_BATCH_STEP_MARKET}
							tableReduxKey="ingredient-components-batch-add/administration/markets"
						/>
					</ModalRow>
				)}

				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_NAME}</th>
							{!useGlobalResource && <th>{phrases.MODAL_BATCH_STEP_MARKET}</th>}

							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((entry, i) => {
							const deleteRowButtonDisabled = batchList.length === 1;

							return (
								<TableListRow key={entry.identifier}>
									<TableListCell>{i + 1}</TableListCell>

									<TableListCell>
										<Input
											id="name"
											placeholder={phrases.MODAL_BATCH_STEP_NAME_PLACEHOLDER}
											onChange={(e) => this._editListEntry(e, entry, 'name')}
											required
											value={entry.name}
										/>
									</TableListCell>

									{!useGlobalResource && (
										<TableListCell>
											<InputCollectionSelect
												id="market"
												placeholder={phrases.MODAL_BATCH_STEP_MARKET_PLACEHOLDER}
												value={entry.market}
												handleChange={(key, value) => this._editListEntry(value, entry, 'market')}
												cache
												required
												apiPath="/administration/markets"
												params={{
													limit: 30,
												}}
												clearable={false}
												optionFormat={(entry) => ({
													value: entry,
													label: entry.name,
												})}
												inputFilterFormat={(input) => `:name=like='%${input}%'`}
												tableColumns={getMarketColumns()}
												tableTitle={phrases.MODAL_BATCH_STEP_MARKET}
												tableReduxKey={`ingredient-components-${entry.identifier}/administration/markets`}
											/>
										</TableListCell>
									)}

									<TableListCell>
										<Button
											type="negative"
											size="tiny"
											disabled={deleteRowButtonDisabled}
											onClick={() => this._onRemoveEntry(entry.identifier)}
										>
											<Icon name="delete" />
										</Button>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>

				<ModalRow
					typeAddNewRow
					tooltipText={phrases.MODAL_BATCH_STEP_LIST_ADD_NEW_ROW_TOOLTIP}
					tooltipZIndex={600}
					onClick={addBatchListItemRow}
				/>
			</>
		);
	}
}

IngredientComponentsBatchStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	batchForm: PropTypes.object,
	updateBatchForm: PropTypes.func,
	useGlobalResource: PropTypes.bool,
};

export default IngredientComponentsBatchStepList;
