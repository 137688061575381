'use strict';

export const EDIT_PERSON_ATTRIBUTE =
	'JUICER_DETAILS_PERSON_ATTRIBUTES/EDIT_PERSON_ATTRIBUTE';
export const SET_PERSON_ATTRIBUTE =
	'JUICER_DETAILS_PERSON_ATTRIBUTES/SET_PERSON_ATTRIBUTE';
export const RESET_STATE = 'JUICER_DETAILS_PERSON_ATTRIBUTES/RESET_STATE';

export const editPersonAttribute = payload => {
	return { type: EDIT_PERSON_ATTRIBUTE, payload };
};

export const setPersonAttribute = payload => {
	return { type: SET_PERSON_ATTRIBUTE, payload };
};

export const resetState = () => {
	return { type: RESET_STATE };
};
