// Services
export const STAFF_DETAILS_FETCHED = 'STAFF_DETAILS/STAFF_DETAILS_FETCHED';
export const PIN_FETCHED = 'STAFF_DETAILS/PIN_FETCHED';

export const EMAIL_UPDATED = 'STAFF_DETAILS/EMAIL_UPDATED';
export const PHONE_NUMBER_UPDATED = 'STAFF_DETAILS/PHONE_NUMBER_UPDATED';
export const PIN_UPDATED = 'STAFF_DETAILS/PIN_UPDATED';

export const FETCH_TOGGLED = 'STAFF_DETAILS/FETCH_TOGGLED';
export const EDIT_MODE_TOGGLED = 'STAFF_DETAILS/EDIT_MODE_TOGGLED';
export const TRAINING_HISTORY_TOGGLED = 'STAFF_DETAILS/TRAINING_HISTORY_TOGGLED';
export const USER_DETAILS_SAVE_TOGGLED = 'STAFF_DETAILS/USER_DETAILS_SAVE_TOGGLED';
export const USER_DETAILS_UPDATED = 'STAFF_DETAILS/USER_DETAILS_UPDATED';
export const SET_NAME = 'STAFF_DETAILS/SET_NAME';
export const SET_BADGE = 'STAFF_DETAILS/SET_BADGE';
export const SET_ID = 'STAFF_DETAILS/SET_ID';
export const CLEAR_STAFF_DETAILS_DATA = 'STAFF_DETAILS/CLEAR_STAFF_DETAILS_DATA';

export const SET_GRADE_REVIEW_ROW_DATA = 'STAFF_DETAILS/SET_GRADE_REVIEW_ROW_DATA';
export const UPDATE_GRADE_REVIEW_ROW_DATA = 'STAFF_DETAILS/UPDATE_GRADE_REVIEW_ROW_DATA';
export const RESET_GRADE_REVIEW_ROW_DATA = 'STAFF_DETAILS/RESET_GRADE_REVIEW_ROW_DATA';
export const SET_USER_SENIORITY = 'STAFF_DETAILS/SET_USER_SENIORITY';
export const RESET_USER_SENIORITY = 'STAFF_DETAILS/RESET_USER_SENIORITY';
export const SET_USER_DETAILS = 'STAFF_DETAILS/SET_USER_DETAILS';
export const RESET_STATE = 'STAFF_DETAILS/RESET_STATE';

// --------------
// Local Actions
// --------------

export const _staffDetailsFetched = (payload) => ({
	type: STAFF_DETAILS_FETCHED,
	payload,
});
export const _staffPinFetched = (payload) => ({ type: PIN_FETCHED, payload });

// toggle used for showing whether user details are being saved
export function _toggleUserDetailsSaving(saving) {
	return {
		type: USER_DETAILS_SAVE_TOGGLED,
		payload: {
			saving,
		},
	};
}

// status of user update call
export function _userDetailsUpdated(status) {
	return {
		type: USER_DETAILS_UPDATED,
		payload: {
			status,
		},
	};
}

// ----------------
// Exported Actions
// ----------------

export function setGradeReviewRowData(payload) {
	return {
		type: SET_GRADE_REVIEW_ROW_DATA,
		payload,
	};
}

export function updateGradeReviewRowData(payload) {
	return {
		type: UPDATE_GRADE_REVIEW_ROW_DATA,
		payload,
	};
}

export function resetGradeReviewRowData(payload) {
	return {
		type: RESET_GRADE_REVIEW_ROW_DATA,
		payload,
	};
}

// toggle whether user details can be edited
export function toggleEditMode(editMode) {
	return {
		type: EDIT_MODE_TOGGLED,
		payload: {
			editMode,
		},
	};
}

export function updatePhoneNumber(phoneNumber) {
	return {
		type: PHONE_NUMBER_UPDATED,
		payload: {
			phoneNumber,
		},
	};
}

export function setUserSeniority(payload) {
	return {
		type: SET_USER_SENIORITY,
		payload,
	};
}

export function resetUserSeniority() {
	return {
		type: RESET_USER_SENIORITY,
	};
}

export function setUserDetails(payload) {
	return {
		type: SET_USER_DETAILS,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export const clearStaffDetailsData = () => ({ type: CLEAR_STAFF_DETAILS_DATA });
export const updatePinCode = (payload) => ({ type: PIN_UPDATED, payload });
export const setName = (payload) => ({ type: SET_NAME, payload });
export const setBadge = (payload) => ({ type: SET_BADGE, payload });
export const setId = (payload) => ({ type: SET_ID, payload });

export function updateEmail(email) {
	return {
		type: EMAIL_UPDATED,
		payload: {
			email,
		},
	};
}

// set whether staff details are being fetched
export function setFetching(fetching) {
	return {
		type: FETCH_TOGGLED,
		payload: {
			fetching,
		},
	};
}

export function toggleTrainingHistory(isVisible) {
	return {
		type: TRAINING_HISTORY_TOGGLED,
		payload: {
			isVisible,
		},
	};
}
