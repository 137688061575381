import { ActionType } from 'types/redux';
import { ICurrencyReduxValue } from '../types';

export const SET_CURRENCY = 'CURRENCIES/SET_CURRENCY';
export const UPDATE_CURRENCY = 'CURRENCIES/UPDATE_CURRENCY';
export const RESET_STATE = 'CURRENCIES/RESET_STATE';

export function setDynamicAccount(payload: ICurrencyReduxValue): ActionType {
	return {
		type: SET_CURRENCY,
		payload,
	};
}

type updateDynamicAccount = Partial<ICurrencyReduxValue>;
export function updateDynamicAccount(
	payload: updateDynamicAccount
): ActionType {
	return {
		type: UPDATE_CURRENCY,
		payload,
	};
}

export function resetState(): ActionType {
	return {
		type: RESET_STATE,
	};
}
