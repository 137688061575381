export const SET_USER = 'USER/SET_USER';
export const SET_ACCESS_TOKEN = 'USER/SET_ACCESS_TOKEN';
export const SET_IS_EXPIRED = 'USER/SET_IS_EXPIRED';
export const SET_TENANTS = 'USER/SET_TENANTS';
export const SET_ACTIVE_TENANT = 'USER/SET_ACTIVE_TENANT';
export const SET_EXPIRES_IN = 'USER/SET_EXPIRES_IN';
export const CLEAR_USER = 'USER/CLEAR_USER';

/**
 * @function setUser
 * @param user : []
 * @returns {{type: string, payload: {user: Object}}}
 */
export function setUser(user = {}) {
	return {
		type: SET_USER,
		payload: {
			user,
		},
	};
}

/**
 * @function setAccessToken
 * @param user : []
 * @returns {{type: string, payload: {user: Object}}}
 */
export function setAccessToken(accessToken = '') {
	return {
		type: SET_ACCESS_TOKEN,
		payload: {
			accessToken,
		},
	};
}

/**
 * @function setTenants
 * @param tenants : []
 * @returns {{type: string, payload: {user: Object}}}
 */
export function setTenants(tenants = []) {
	return {
		type: SET_TENANTS,
		payload: {
			tenants,
		},
	};
}

/**
 * @function setActiveTenant
 * @param tenants : []
 * @returns {{type: string, payload: {user: Object}}}
 */
export function setActiveTenant(activeTenant = null) {
	return {
		type: SET_ACTIVE_TENANT,
		payload: {
			activeTenant,
		},
	};
}

/**
 * @function clearUser
 * @returns {{type: string}00}
 */
export function clearUser() {
	return {
		type: CLEAR_USER,
	};
}
