// interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';

// utils
import { get, post } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './currencyDenominations.phrases';

function handleError(err: Error): Error {
	const error = formatErrorMessage(err);
	setFeedback(error, 0);

	throw error;
}

/**
 * @function fetchCurrencyDenominations
 * @description Fetch all companies
 * @param {object} state
 */
export function fetchCurrencyDenominations(
	id: number,
	state: IFetchDataProps
): Promise<IApiRes> {
	let filter = `:currency.id=='${id}'`;
	if (state.filter) filter = `${filter};${state.filter}`;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(
		'/administration/currency_denominations',
		params,
		null,
		state.headers
	)
		.then((res: IApiRes) => res)
		.catch(handleError);
}

/**
 * @function editCurrencyDenominations
 * @description edits currencyDenomination
 */
export function editCurrencyDenominations(
	id: number,
	data: Record<string, unknown>
): Promise<IApiRes> {
	return post(`/administration/currency_denominations/${id}`, data)
		.then((res: IApiRes) => {
			setFeedback(phrases.CURRENCY_DENOMINATIONS_EDITED, 1);
			return res;
		})
		.catch(handleError);
}
