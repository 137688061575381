'use strict';

export const SET_SHIFTPLANNER_REPORT = 'SHIFTPLANNER/SET_SHIFTPLANNER_REPORT';
export const SET_SHIFTPLANNER_REPORT_PR_WORKPLACE = 'SHIFTPLANNER/SET_SHIFTPLANNER_REPORT_PR_WORKPLACE';
export const SHOW_HALF_HOURLY = 'SHIFTPLANNER/SHOW_HALF_HOURLY';
export const LOADING_REPORT = 'SHIFTPLANNER/LOADING_REPORT';
export const SET_HOTBAR_DATA = 'SHIFTPLANNER/SET_HOTBAR_DATA';
export const CLEAN_DATA = 'SHIFTPLANNER/CLEAN_DATA';

/**
 * @param {Array} shiftplannerReport
 */
export function setShiftplannerReport(shiftplannerReport) {
	return {
		type: SET_SHIFTPLANNER_REPORT,
		payload: {
			shiftplannerReport
		}
	};
}

/**
 * @param {Array} shiftplannerReportPrWorkplace
 */
export function setShiftplannerReportPrWorkplace(shiftplannerReportPrWorkplace) {
	return {
		type: SET_SHIFTPLANNER_REPORT_PR_WORKPLACE,
		payload: {
			shiftplannerReportPrWorkplace
		}
	};
}

/**
 * @param {bool} loadingReport
 */
export function setLoadingReport(loadingReport) {
	return {
		type: LOADING_REPORT,
		payload: {
			loadingReport
		}
	};
}

/**
 */
export function cleanData() {
	return {
		type: CLEAN_DATA
	};
}

/**
 * @param {Object} hotbarData
 */
export function setHotbarData(hotbarData) {
	return {
		type: SET_HOTBAR_DATA,
		payload: {
			hotbarData
		}
	};
}

/**
 * @param {bool} showHalfHourly
 */
export function showHalfHourly(showHalfHourly) {
	return {
		type: SHOW_HALF_HOURLY,
		payload: {
			showHalfHourly
		}
	};
}
