import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './filterGroups.phrases';

/**
 * @function fetchFilterGroups
 * @description Fetch all filter groups
 * @param {object} state
 */
export function fetchFilterGroups(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/product/filter_groups', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addFilterGroup
 * @description add filter group
 */
export function addFilterGroup(data) {
	return post('/product/filter_groups', data)
		.then((res) => {
			setFeedback(phrases.FILTER_GROUP_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editFilterGroup
 * @description edit filter group
 */
export function editFilterGroup(data) {
	return post(`/product/filter_groups/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.FILTER_GROUP_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function editFilterGroups
 * @description edit filter groups
 */
export function editFilterGroups(data) {
	return patch('/product/filter_groups', data)
		.then((res) => {
			setFeedback(phrases.FILTER_GROUPS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function deleteFilterGroup
 * @description delete filter groups
 */
export function deleteFilterGroup(id) {
	return remove(`/product/filter_groups/${id}`)
		.then((res) => {
			setFeedback(phrases.FILTER_GROUP_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
