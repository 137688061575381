'use strict';

/**
 * Object containing nested reducers to highen readability for main reducer
 * @type {Object}
 */

import _extend from 'lodash/extend';
import { state as defaultListState } from './list.defaults';

const nestedReducers = {
	filters: function (state, action) {
		switch (action.type) {
			case 'SET_FILTER_FILTERBAR':
				return _extend(state, { filterBar: action.value.filterBar });
			case 'SET_FILTER_SEARCH':
				return _extend(state, { search: action.value.search });
			case 'SET_FILTER_QUERY':
				return _extend(state, { query: action.value.state });
			default:
				return state;
		}
	},
	headerFilterBar: function (state, action) {
		switch (action.type) {
			case 'SET_HEADER_FILTERBAR':
				state[action.value.indexHeader].filterBar[action.value.indexFilter] = action.value;
				return state;
		}
	},
	params: function (state, action) {
		switch (action.type) {
			case 'SET_PARAM_OFFSET':
				return _extend(state, { offset: action.value.offset });
			case 'SET_PARAM_SORT':
				return _extend(state, { sort: action.value.sort });
			case 'SET_PARAM_LIMIT':
				return _extend(state, { limit: action.value.limit });
			default:
				return state;
		}
	},
	sort: function (state, action) {
		switch (action.type) {
			case 'SET_SORT_COLUMN':
				return _extend(state, { column: action.value.column });
			case 'SET_SORT_ORDER': {
				return _extend(state, { order: action.value.order });
			}
		}
	},
};

const defaultState = {
	...defaultListState,
};

/**
 * @function reducer
 * @description Reduces state to new state
 * @param  {object} state  current state
 * @param  {object} action action to reduce from
 * @return {object}        reduced state
 */

function reducer(state = {}, action = {}) {
	switch (action.type) {
		case 'INIT_REDUCER': {
			const key = action.value.reduxKey;
			return {
				...state,
				[key]: defaultState,
			};
		}

		case 'SET_ACCORDIONS': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					accordions: {
						...state[key].accordions,
						accordions: action.value.accordions,
					},
				},
			};
		}

		case 'SET_ACCORDIONS_BASE': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					accordionsBase: action.value.accordionsBase,
				},
			};
		}

		case 'SET_COLLECTION': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					collection: action.value.collection,
				},
			};
		}

		case 'SET_COLLECTION_LENGTH': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					collectionLength: action.value.collectionLength,
				},
			};
		}

		case 'SET_DATA': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					data: {
						...state[key].data,
						...action.value.data,
					},
				},
			};
		}

		case 'SET_DELAYED_REFRESH': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					delayedRefresh: action.value.delayedRefresh,
				},
			};
		}

		case 'SET_EDIT_ROW_ID': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					editRowId: action.value.editRowId,
				},
			};
		}

		case 'SET_FILTER_QUERY': {
			const key = action?.value?.reduxKey;
			return {
				...state,

				[key]: {
					...state[key],
					filters: {
						...state[key].filters,
						query: action.value.queryFilter,
					},
				},
			};
		}
		// case 'SET_FILTER_FILTERBAR':
		// case 'SET_FILTER_SEARCH': {
		// 	const key = action?.value?.reduxKey;
		// 	const val = action?.value;

		// 	return {
		// 		...state,

		// 		[key]: {
		// 			...state[key],
		// 			filters: {
		// 				...state[key].filters,
		// 				filterFilterbar: action.value.filterFilterbar,
		// 			},
		// 		},
		// 	};
		// }

		case 'SET_FILTERS': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					filters: {
						...state[key].filters,
						filters: action.value.filters,
					},
				},
			};
		}

		case 'SET_FILTER_OPERATORS': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					filterOperators: {
						...state[key].filterOperators,
						filterOperators: action.value.filterOperators,
					},
				},
			};
		}

		case 'SET_HEADERS': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					headers: action.value.headers,
				},
			};
		}
		case 'SET_HEADER_FILTERBAR': {
			const key = action?.value?.reduxKey;

			return _extend(state, {
				headers: nestedReducers.headerFilterBar(state[key].headers, action),
			});
		}
		case 'SET_HOTBAR': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					hotbar: {
						...state[key].hotbar,
						hotbar: action.value.hotbar,
					},
				},
			};
		}

		case 'SET_IS_ADDING': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],

					isAdding: action.value.isAdding,
				},
			};
		}

		case 'SET_IS_BAR_FILTERING': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],

					isBarFiltering: action.value.isBarFiltering,
				},
			};
		}

		case 'SET_IS_COLLAPSED': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],

					isCollapsed: action.value.isCollapsed,
				},
			};
		}

		case 'SET_IS_CREATING': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],

					isCreating: action.value.isCreating,
				},
			};
		}

		case 'SET_IS_COLUMN_SELECTING': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],

					isColumnSelecting: action.value.isColumnSelecting,
				},
			};
		}

		case 'SET_CSV_DATE_RANGE_VISIBLE': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],

					isCSVDateRangeVisible: action.value.isCSVDateRangeVisible,
				},
			};
		}

		case 'SET_IS_EXPORTING': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],

					isExporting: action.value.isExporting,
				},
			};
		}

		case 'SET_IS_EDITING': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],

					isEditing: action.value.isEditing,
				},
			};
		}

		case 'SET_IS_SEARCH_FILTERING': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					isSearchFiltering: action.value.isSearchFiltering,
				},
			};
		}

		case 'SET_IS_SEARCH_LIST': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					isSearchList: action.value.isSearchList,
				},
			};
		}

		case 'SET_MODEL': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					model: {
						...state[key].model,
						model: action.value.model,
					},
				},
			};
		}

		case 'SET_LIST_ACTIONS': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					listActions: action.value.listActions,
				},
			};
		}

		case 'SET_PARAM_LIMIT':
		case 'SET_PARAM_OFFSET':
		case 'SET_PARAM_SORT': {
			const key = action?.value?.reduxKey;

			return {
				...state,
				[key]: {
					...state[key],
					params: nestedReducers.params(state[key].params, action),
				},
			};
		}
		case 'SET_PARAMS': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					params: action.value.params,
				},
			};
		}

		case 'SET_ROW_ACTIONS': {
			const key = action?.value?.reduxKey;

			return {
				...state,
				[key]: {
					...state[key],
					rowActions: state[key].rowActions,
				},
			};
		}

		case 'SET_SELECTED_ROW_ID': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					selectedRowId: action.value.selectedRowId,
				},
			};
		}

		case 'SET_SHOW_LIST': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					showList: action.value.showList,
				},
			};
		}

		case 'SET_ERROR': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					error: {
						...state[key].error,
						error: action.value.error,
					},
					showList: false,
				},
			};
		}

		case 'SET_SORT_COLUMN':
		case 'SET_SORT_ORDER': {
			const key = action?.value?.reduxKey;

			return {
				...state,
				[key]: {
					...state[key],
					sort: nestedReducers.sort(state[key].params, action),
				},
			};
		}
		case 'SET_TITLE': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					title: action.value.title,
				},
			};
		}

		case 'SET_PRINT_VIEW_HEADER': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					printViewHeader: {
						...state[key].printViewHeader,
						printViewHeader: action.value.printViewHeader,
					},
				},
			};
		}

		case 'TOGGLE_PRINT_VIEW': {
			const key = action?.value?.reduxKey;
			return {
				...state,
				[key]: {
					...state[key],
					togglePrintView: action.value.togglePrintView,
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
