'use strict';

var headers = {
	RESOURCES: {
		NAME: 'Name',
		DESCRIPTION: 'Description'
	},
	RESOURCE_ACCESS_CONTROL_LISTS: {
		RESOURCE: 'Resource',
		USER_ROLE: 'User role',
		ACTION: 'Action'
	},
	RESOURCE_FIELD_ACCESS_CONTROL_LISTS: {
		RESOURCE: 'Resource',
		USER_ROLE: 'User role',
		FIELD: 'Field'
	},
	USERS: {
		NAME: 'Name',
		EMAIL: 'E-mail',
		USERNAME: 'User name',
		PERSON: 'Person',
		ACTIVE: 'Active',
		PASSWORD: 'Password',
		USER_TO_USER_ROLE_RELATIONS: 'Roles'
	},
	USER_ACCESS_TOKENS: {
		USER: 'User',
		KEY: 'Key',
		CREATED: 'Created',
		VALID_TO: 'Valid to'
	},
	USER_ROLES: {
		NAME: 'Name',
		DESCRIPTION: 'Description'
	},
	USER_TO_USER_ROLE_RELATIONS: {
		USER: 'User',
		USER_ROLE: 'User role'
	}
};

var titles = {
	RESOURCES: 'Resources',
	RESOURCE_ACCESS_CONTROL_LISTS: 'Resource Access Management',
	RESOURCE_FIELD_ACCESS_CONTROL_LISTS: 'Resource Field Access Management',
	USERS: 'Users',
	USER_ACCESS_TOKENS: 'Sessions',
	USER_ROLES: 'Roles',
	USER_CUSTOM_ROLES: 'Custom role',
	USER_TO_USER_ROLE_RELATIONS: 'User / User role relation'
};

module.exports = {
	headers: headers,
	titles: titles
};
