export default {
	TABLE_TITLE: 'Product bundle rules',
	PRODUCT_BUNDLE_RULES_ADDED: 'Product bundle rules added',
	PRODUCT_BUNDLE_RULE_EDITED: 'Product bundle rule edited',
	PRODUCT_BUNDLE_RULES_EDITED: 'Product bundle rules edited',
	PRODUCT_BUNDLE_RULE_DELETED: 'Product bundle rules deleted',
	MULTI_EDIT_NOT_ALLOWED: 'Multi edit is not allowed!',

	// batch
	MODAL_BATCH_STEP_LIST_TITLE: 'Confirm entries',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Submit',
	MODAL_BATCH_STEP_GLOBAL_BUNDLE_RULE: 'Global bundle rule',
	MODAL_BATCH_STEP_GLOBAL_BUNDLE_RULE_PLACEHOLDER:
		'Select Global bundle rule...',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Add a new row',
};
