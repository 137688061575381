import { post } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './workplacePosConfigurationRelationWrapper.phrases';

export function addWorkplaceToPosConfigurationRelation(data) {
	return post('/pos/workplace_to_pos_configuration_relations', data)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_TO_POS_CONFIGURATION_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
