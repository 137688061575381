import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './buttonGroup.css';

export default class ButtonGroup extends PureComponent {
	// constructor(props) {
	// 	super(props);
	// 	this.state = {};
	// }

	render() {
		const { children, alignCenter, className, type, layout } = this.props;

		const classNames = cx('j-button-group', {
			[`j-button-group--${type}`]: type,
			[`j-button-group--${layout}`]: layout,
			'j-button-group--align-center': alignCenter,
			[`${className}`]: className,
		});

		return (
			<div className={classNames} {...this.props}>
				{children}
			</div>
		);
	}
}

ButtonGroup.propTypes = {
	// shared props
	className: PropTypes.string,
	type: PropTypes.string,
	layout: PropTypes.string,
	children: PropTypes.node,
	alignCenter: PropTypes.bool,
};
