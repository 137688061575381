'use strict';

import cx from 'classnames';
import _every from 'lodash/every';
import _map from 'lodash/map';
import _without from 'lodash/without';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './controls.css';

// Components
import SearchFilter from './searchFilter.component';
import CsvDateRange from '../csvDateRange/csvDateRange.container';

class Controls extends Component {

	/**
	 * @function _renderListActions
	 * @memberOf DIRECTIVES.jtjList.component
	 * @description Renders all actions for current list based on listActions from state
	 */
	_renderListActions() {
		if (!this.props?.listActions) return;
		return _without(
			_map(
				this.props.listActions(this.props),
				function (action, key) {
					var buttonClass = cx('button button--white', {
						'button--active': action.active && _every(action.active),
					});
					if (_every(action.validExpressions)) {
						var iconClass = cx('icon', action.icon);
						return (
							<li
								key={key}
								data-label={this.props.translations[action.name.toUpperCase()]}>
								<a onClick={action.method} className={buttonClass}>
									<span className={iconClass} />
								</a>
							</li>
						);
					}
				}.bind(this)
			),
			undefined
		);
	}

	render() {
		if (this.props.showList) {
			return (
				<aside className="controls">
					<CsvDateRange
						handleClose={this.props.methods.handleSetIsCSVDateRangeVisible}
						visible={this.props.isCSVDateRangeVisible}
						handleCSVExport={this.props.methods.handleCSVExportWithRange}
					/>
					{!this.props.isSearchList && <SearchFilter {...this.props} />}
					<ul className="controls__bar">{this._renderListActions()}</ul>
				</aside>
			);
		} else return null;
	}
}

Controls.propTypes = {
	headers: PropTypes.array.isRequired,
	isAdding: PropTypes.bool,
	methods: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
	showList: PropTypes.bool,
};

export default Controls;
