import * as React from 'react';

// components
import { Button, Link, Typography, Breadcrumbs, Box } from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

// utils
import { sentence } from 'change-case';

// phrases
import phrases from './bradcrumbs.phrases';

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

export default function BreadcrumbsComponent() {
	const location = useLocation();
	const navigate = useNavigate();

	const pathnames = location.pathname
		.split('/')
		.filter((x) => x)
		.slice(0, 2);

	const onBackButtonClick = () => navigate(-1);

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			{pathnames.length > 0 && (
				<Button
					size="small"
					onClick={onBackButtonClick}
					sx={{
						marginRight: 1.5,
					}}
				>
					{phrases.BACK}
				</Button>
			)}

			<Breadcrumbs aria-label="breadcrumb">
				<LinkRouter underline="hover" color="inherit" to="/">
					{phrases.HOME}
				</LinkRouter>
				{pathnames.map((value, index) => {
					if (!isNaN(value)) return null;

					const last = index === pathnames.length - 1;
					const to = `${pathnames.slice(0, index + 1).join('/')}`;

					return last ? (
						<Typography color="text.primary" key={to}>
							{sentence(value)}
						</Typography>
					) : (
						<LinkRouter underline="hover" color="inherit" to={to} key={to}>
							{sentence(value)}
						</LinkRouter>
					);
				})}
			</Breadcrumbs>
		</Box>
	);
}
