// Interfaces
import { DynamicsMappedData, DynamicsMetaData } from '../types';

export const SET_MAPPED_DATA = 'DYNAMICS365_MATRIX/SET_MAPPED_DATA';
export const SET_META_DATA = 'DYNAMICS365_MATRIX/SET_META_DATA';
export const RESET_DATA = 'DYNAMICS365_MATRIX/RESET_DATA';

export function setMappedData(payload: DynamicsMappedData[] | undefined) {
	return {
		type: SET_MAPPED_DATA,
		payload,
	};
}

export function setMetaData(payload: DynamicsMetaData | undefined) {
	return {
		type: SET_META_DATA,
		payload,
	};
}

export function resetData() {
	return {
		type: RESET_DATA,
	};
}
