'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setGlobalBundleRule,
	updateGlobalBundleRule,
	resetState,
} from './store/globalBundleRules.actions';

// services
import {
	fetchGlobalBundleRules,
	editGlobalBundleRule,
	editGlobalBundleRules,
	addGlobalBundleRules,
	deleteGlobalBundleRule,
} from './globalBundleRules.service';

// utils
import enums from 'services/enums.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';
import { Input, Button, Icon, TimePickerNew, InputCollectionSelect } from 'dumb';

import GlobalBundleRulesModalBatch from './components/batch/globalBundleRulesModalBatch';
import GlobalBundleRuleTranslations from './components/globalBundleRuleTranslations/globalBundleRuleTranslations.component';

// lodash
import _get from 'lodash/get';

// phrases/constants
import phrases from './globalBundleRules.phrases';
import constants from 'services/constants';
import moment from 'moment';

const reduxKey = '/product/global_bundle_rules';

class GlobalBundleRules extends Component {
	constructor(props) {
		super(props);

		this.today = moment.utc().format(constants.shortDate);

		this.state = {
			key: moment(),
			showAddModal: false,
			showEditModal: false,
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleAddModal = this.toggleAddModal.bind(this);
		this.addGlobalBundleRulesWrapper = this.addGlobalBundleRulesWrapper.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: 'name',
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: 'description',
				filterPath: ':description',
			},
			{
				Header: 'Weekday',
				id: 'weekday',
				accessor: 'weekday',
				filterPath: ':weekday',
			},
			{
				Header: 'From',
				id: 'from',
				accessor: (d) => _get(d, 'time.from', ''),
				filterPath: ':time.from',
				filterable: false,
			},
			{
				Header: 'To',
				id: 'to',
				accessor: (d) => _get(d, 'time.to', ''),
				filterPath: ':time.to',
				filterable: false,
			},
		];
	}

	editEntry() {
		const { defaultGlobalBundleRule } = this.props;

		const payload = {
			id: defaultGlobalBundleRule.id,
			name: defaultGlobalBundleRule.name,
			description: defaultGlobalBundleRule.description,
			weekday: _get(defaultGlobalBundleRule, 'weekday.value', null),
			time: {
				from: moment.utc(defaultGlobalBundleRule.from, constants.timeShort).format(constants.dayFormat),
				to: moment.utc(defaultGlobalBundleRule.to, constants.timeShort).format(constants.dayFormat),
			},
		};

		return editGlobalBundleRule(payload);
	}

	editMultiple(selectedRows) {
		const { defaultGlobalBundleRule } = this.props;

		const payload = {
			...(defaultGlobalBundleRule.name && {
				name: defaultGlobalBundleRule.name,
			}),
			...(defaultGlobalBundleRule.description && {
				description: defaultGlobalBundleRule.description,
			}),
			...(defaultGlobalBundleRule.weekday && {
				weekday: defaultGlobalBundleRule.weekday.value,
			}),
			...((defaultGlobalBundleRule.from || defaultGlobalBundleRule.to) && {
				time: {
					...(defaultGlobalBundleRule.from && {
						from: moment.utc(defaultGlobalBundleRule.from, constants.timeShort).format(constants.dayFormat),
					}),
					...(defaultGlobalBundleRule.to && {
						to: moment.utc(defaultGlobalBundleRule.to, constants.timeShort).format(constants.dayFormat),
					}),
				},
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editGlobalBundleRules({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteGlobalBundleRule(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			weekday: {
				label: data.weekday,
				value: data.weekday,
			},
			from: _get(data, 'time.from', null),
			to: _get(data, 'time.to', null),
			singleEdit: true,
		};

		this.props.setGlobalBundleRule(payload);
	}

	getTime(name, value) {
		if (value) return value;

		return name === 'from' ? moment().format(constants.timeShort) : '23:59';
	}

	getEditableCells() {
		const { defaultGlobalBundleRule } = this.props;

		const singleEditMode = defaultGlobalBundleRule.singleEdit;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={defaultGlobalBundleRule.name || ''}
						onChange={(event) => this.editStoreEntry('name', event)}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={defaultGlobalBundleRule.description || ''}
						onChange={(event) => this.editStoreEntry('description', event)}
					/>
				),
			},
			{
				header: 'Weekday',
				value: (
					<InputCollectionSelect
						id="weekday"
						placeholder="Select weekday..."
						value={defaultGlobalBundleRule.weekday}
						handleChange={(key, value) => this.editStoreEntry('weekday', value)}
						options={enums.weekdaysLabels}
					/>
				),
			},
			{
				header: 'From',
				value: (
					<TimePickerNew
						id="from"
						onChange={(event) => this.editStoreEntry('from', event)}
						value={singleEditMode ? this.getTime('from', defaultGlobalBundleRule.from) : defaultGlobalBundleRule.from}
					/>
				),
			},
			{
				header: 'To',
				value: (
					<TimePickerNew
						id="to"
						onChange={(event) => this.editStoreEntry('to', event)}
						value={singleEditMode ? this.getTime('to', defaultGlobalBundleRule.to) : defaultGlobalBundleRule.to}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const value = e.target ? e.target.value : e;

		const updateObject = {
			[name]: value,
		};

		this.props.updateGlobalBundleRule(updateObject);
	}

	toggleAddModal() {
		this.setState((prevState) => ({ showAddModal: !prevState.showAddModal }));
		this.props.resetState();
	}

	getActionButtons() {
		return (
			<Button type="inverted" label="Add" shadow onClick={this.toggleAddModal}>
				<Icon name="add" />
			</Button>
		);
	}

	addGlobalBundleRulesWrapper(data) {
		return addGlobalBundleRules(data)
			.then(() => {
				this.setState(() => ({ key: moment() }));
			})
			.catch((err) => {
				throw err;
			});
	}

	render() {
		const {
			batchFormData,
			batchListData,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
			updateBatchForm,
			addBatchList,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					accessAreasAllowedToEdit={['Sales Configuration']}
					key={this.state.key}
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={fetchGlobalBundleRules}
					filterable
					defaultPageSize={10}
					reduxKey={reduxKey}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					actionRender={this.getActionButtons()}
					subcomponent={(row) => (
						<SubTable>
							<GlobalBundleRuleTranslations id={row.original?.id ?? null} />
						</SubTable>
					)}
				/>

				<GlobalBundleRulesModalBatch
					modalVisible={this.state.showAddModal}
					handleClose={this.toggleAddModal}
					batchFormData={batchFormData}
					batchListData={batchListData}
					addBatchList={addBatchList}
					updateBatchForm={updateBatchForm}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					batchAddData={this.addGlobalBundleRulesWrapper}
				/>
			</>
		);
	}
}

GlobalBundleRules.propTypes = {
	defaultGlobalBundleRule: PropTypes.object,
	updateGlobalBundleRule: PropTypes.func,
	setGlobalBundleRule: PropTypes.func,
	resetState: PropTypes.func,
	batchListData: PropTypes.array,
	batchFormData: PropTypes.object,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	updateBatchForm: PropTypes.func,
	addBatchList: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			updateGlobalBundleRule,
			setGlobalBundleRule,
			resetState,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultGlobalBundleRule: store.salesConfigurationGlobalBundleRules.data.defaultGlobalBundleRule,
		batchListData: store.salesConfigurationGlobalBundleRules.data.batchListData,
		batchFormData: store.salesConfigurationGlobalBundleRules.data.batchFormData,
	};
};

export default connectWithStore(GlobalBundleRules, mapStateToProps, mapDispatchToProps);
