import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './systemSoftwareVersions.phrases';

/**
 * @function fetchSystemSoftwareVersions
 * @description Fetch all system software versions
 * @param {object} state
 */
export function fetchSystemSoftwareVersions(state, filter) {
	if (state.filter) filter = `${filter};${state.filter}`;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(
		'/administration/system_software_versions',
		params,
		null,
		state.headers
	)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addSystemSoftwareVersions
 * @description add system software versions
 */
export function addSystemSoftwareVersions(data) {
	return post('/administration/system_software_versions', data)
		.then((res) => {
			setFeedback(phrases.SYSTEM_SOFTWARE_VERSIONS_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editSystemSoftwareVersion
 * @description edit system software version
 */
export function editSystemSoftwareVersion(data) {
	return post(`/administration/system_software_versions/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.SYSTEM_SOFTWARE_VERSION_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function deleteSystemSoftwareVersion
 * @description delete system software version
 */
export function deleteSystemSoftwareVersion(id) {
	return remove(`/administration/system_software_versions/${id}`)
		.then((res) => {
			setFeedback(phrases.SYSTEM_SOFTWARE_VERSION_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
