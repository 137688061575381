import {
	SET_METADATA,
	SET_MAPPED_DATA,
	SET_TOTAL_VALUES,
	RESET_TABLE_DATA,
	SET_ADJUST_MODAL_DATA,
	UPDATE_ADJUST_MODAL_DATA,
	SET_SIGN_MODAL_DATA,
	CLEAR_MODAL_DATA,
	SET_DEPOSIT_TYPE,
	UPDATE_CELL_VALUE,
	UPDATE_SIGN_MODAL_DATA,
	SET_PRINT_VIEW_METADATA,
	SET_FILTER_OPERATOR,
	SET_FILTER_DIFFERENCE,
} from './adyenReconciliationMatrix.actions';

import enums from './../adyenReconciliationMatrix.enums';

const defaultState = {
	metadata: {},
	mappedData: [],
	totalValues: [],
	adjustModalData: {},
	signModalData: {},
	depositType: {
		value: enums.RECEIVED,
		label: enums.RECEIVED,
	},
	filter: {
		operator: null,
		difference: null,
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_DEPOSIT_TYPE:
			return {
				...state,
				depositType: payload,
			};

		case SET_METADATA:
			return {
				...state,
				metadata: payload,
			};

		case SET_MAPPED_DATA:
			return {
				...state,
				mappedData: payload,
			};

		case SET_TOTAL_VALUES:
			return {
				...state,
				totalValues: payload,
			};

		case RESET_TABLE_DATA:
			return {
				...state,
				metadata: defaultState.metadata,
				mappedData: defaultState.mappedData,
				totalValues: defaultState.totalValues,
			};

		case SET_ADJUST_MODAL_DATA:
			return {
				...state,
				adjustModalData: payload,
			};

		case UPDATE_ADJUST_MODAL_DATA:
			return {
				...state,
				adjustModalData: {
					...state.adjustModalData,
					...payload,
				},
			};

		case SET_SIGN_MODAL_DATA:
			return {
				...state,
				signModalData: payload,
			};

		case CLEAR_MODAL_DATA:
			return {
				...state,
				adjustModalData: defaultState.adjustModalData,
				signModalData: defaultState.signModalData,
			};

		case UPDATE_CELL_VALUE: {
			return {
				...state,
				mappedData: state.mappedData.map((entry) => {
					if (entry.workplace?.id === payload.workplace?.id) {
						return {
							...entry,
							period: entry.period.map((entryTwo) => {
								if (entryTwo.date === payload.formattedValue.date)
									return payload.formattedValue;

								return entryTwo;
							}),
						};
					}

					return entry;
				}),
			};
		}

		case UPDATE_SIGN_MODAL_DATA: {
			return {
				...state,
				signModalData: {
					...state.signModalData,
					storesWithReceivedDifferences: state.signModalData.storesWithReceivedDifferences.map(
						(entry) => {
							if (entry.workplace?.id === payload.workplace?.id) {
								return {
									...entry,
									period: entry.period.map((entry) => {
										if (entry.date === payload.formattedValue.date) {
											return payload.formattedValue;
										}

										return entry;
									}),
								};
							}

							return entry;
						}
					),
				},
			};
		}

		case SET_PRINT_VIEW_METADATA:
			return {
				...state,
				metadata: {
					...state.metadata,
					printViewMetadata: payload,
				},
			};

		case SET_FILTER_OPERATOR:
			return {
				...state,
				filter: {
					...state.filter,
					operator: payload,
				},
			};

		case SET_FILTER_DIFFERENCE:
			return {
				...state,
				filter: {
					...state.filter,
					difference: payload,
				},
			};

		default:
			return state;
	}
}

export default reducer;
