'use strict';

import { getCookie } from 'cookies';

/**
 * @function loadLocalHeaders
 * @memberOf SERVICES.__models
 * @description
 * Shows/hides headers locally via $cookies
 * @param  {array} headers  headers to load from local setting
 */

import _each from 'lodash/each';

// Services
const __headerHash = require('headerHash.service');

module.exports = function (headers) {
	const settings = this.getSettings();
	_each(headers, function (header) {
		// Check (and set) if local header is hidden
		const localHeaderHidden = getCookie(
			settings.name + '-header-' + __headerHash(header)
		);
		if (localHeaderHidden !== undefined) header.localHidden = localHeaderHidden;
	});

	this.setHeaders(headers);
};
