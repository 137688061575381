import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeSnackbar } from './notifier.actions';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

let displayed = [];

const Notifier = () => {
	const dispatch = useDispatch();
	const notifications = useSelector((store) => store.notifier.notifications);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const storeDisplayed = (id) => {
		displayed = [...displayed, id];
	};

	const removeDisplayed = (id) => {
		displayed = [...displayed.filter((key) => id !== key)];
	};

	React.useEffect(() => {
		notifications.forEach(
			({ key, message, options = {}, dismissed = false }) => {
				if (dismissed) {
					// dismiss snackbar using notistack
					closeSnackbar(key);
					return;
				}

				// do nothing if snackbar is already displayed
				if (displayed.includes(key)) return;

				const updatedOptions = {
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
					autoHideDuration: 4000,
					action: (key) => (
						<React.Fragment>
							<IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={() => closeSnackbar(key)}
							>
								<CloseIcon fontSize="small" />
							</IconButton>
						</React.Fragment>
					),
					...options,
				};

				// display snackbar using notistack
				enqueueSnackbar(message, {
					key,
					...updatedOptions,
					onClose: (event, reason, myKey) => {
						if (options.onClose) {
							options.onClose(event, reason, myKey);
						}
					},
					onExited: (event, myKey) => {
						// remove this snackbar from redux store
						dispatch(removeSnackbar(myKey));
						removeDisplayed(myKey);
					},
				});

				// keep track of snackbars that we've displayed
				storeDisplayed(key);
			}
		);
	}, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

	return null;
};

export default Notifier;
