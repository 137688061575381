'use strict';

import {
	SET_WORKPLACE_POS_CONFIGURATION,
	UPDATE_WORKPLACE_POS_CONFIGURATION,
} from './workplacePosConfigurationRelationWrapper.actions';

const defaultState = {
	data: {
		workplacePosConfiguration: {},
	},
};

function reducer(state = defaultState, action) {
	const { payload, type } = action;

	switch (type) {
	case SET_WORKPLACE_POS_CONFIGURATION: {
		return {
			...state,
			data: {
				...state.data,
				workplacePosConfiguration: payload,
			},
		};
	}

	case UPDATE_WORKPLACE_POS_CONFIGURATION: {
		return {
			...state,
			data: {
				...state.data,
				workplacePosConfiguration: {
					...state.data.workplacePosConfiguration,
					...payload,
				},
			},
		};
	}

	default:
		// never return default state in default case, Filip!!!
		return state;
	}
}

export default reducer;
