import {
	CLEAR_STAFF_DETAILS_DATA,
	EDIT_MODE_TOGGLED,
	EMAIL_UPDATED,
	FETCH_TOGGLED,
	PHONE_NUMBER_UPDATED,
	PIN_FETCHED,
	PIN_UPDATED,
	SET_BADGE,
	SET_ID,
	SET_NAME,
	STAFF_DETAILS_FETCHED,
	TRAINING_HISTORY_TOGGLED,
	USER_DETAILS_SAVE_TOGGLED,
	USER_DETAILS_UPDATED,
	SET_GRADE_REVIEW_ROW_DATA,
	UPDATE_GRADE_REVIEW_ROW_DATA,
	RESET_GRADE_REVIEW_ROW_DATA,
	SET_USER_SENIORITY,
	RESET_USER_SENIORITY,
	SET_USER_DETAILS,
	RESET_STATE,
} from './staffDetails.actions';

const defaultState = {
	details: {},
	pinData: {},
	fetching: false,
	editMode: false,
	isTrainingVisible: false,
	savingUserDetails: false,
	email: '',
	phoneNumber: '',
	pin: null,
	userUpdateStatus: '',
	qualificationData: {},
	gradeReviewRowData: {},
	userSeniority: {},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
		case SET_USER_DETAILS:
			return {
				...state,
				id: payload.id,
				name: payload.name,
				email: payload.email,
				phoneNumber: payload.phoneNumber,
				badge: payload.badge,
			};

		case RESET_STATE:
			return defaultState;

		case CLEAR_STAFF_DETAILS_DATA:
			return { ...state, ...defaultState };

		case STAFF_DETAILS_FETCHED:
			return { ...state, details: payload };

		case PIN_FETCHED:
			return { ...state, pinData: payload };

		case PIN_UPDATED:
			return { ...state, pin: payload };

		case SET_ID:
			return { ...state, id: payload };

		case SET_BADGE:
			return { ...state, badge: payload };

		case SET_NAME:
			return { ...state, name: payload };

		case PHONE_NUMBER_UPDATED: {
			const phoneNumber = { phoneNumber: payload.phoneNumber };
			return Object.assign({}, state, phoneNumber);
		}
		case EMAIL_UPDATED: {
			const email = { email: payload.email };
			return Object.assign({}, state, email);
		}
		case EDIT_MODE_TOGGLED: {
			const editMode = { editMode: payload.editMode };
			return Object.assign({}, state, editMode);
		}
		case TRAINING_HISTORY_TOGGLED: {
			const isTrainingVisible = { isTrainingVisible: payload.isVisible };
			return Object.assign({}, state, isTrainingVisible);
		}
		case USER_DETAILS_SAVE_TOGGLED: {
			const savingUserDetails = { savingUserDetails: payload.saving };
			return Object.assign({}, state, savingUserDetails);
		}
		case USER_DETAILS_UPDATED: {
			const userUpdateStatus = { userUpdateStatus: payload.status };
			return Object.assign({}, state, userUpdateStatus);
		}

		case FETCH_TOGGLED: {
			const fetching = { fetching: payload.fetching };
			return Object.assign({}, state, fetching);
		}

		case SET_GRADE_REVIEW_ROW_DATA: {
			return {
				...state,
				gradeReviewRowData: payload,
			};
		}

		case RESET_GRADE_REVIEW_ROW_DATA: {
			return {
				...state,
				gradeReviewRowData: {},
			};
		}

		case UPDATE_GRADE_REVIEW_ROW_DATA: {
			return {
				...state,
				gradeReviewRowData: {
					...state.gradeReviewRowData,
					...payload,
				},
			};
		}

		case SET_USER_SENIORITY: {
			return {
				...state,
				userSeniority: payload,
			};
		}

		case RESET_USER_SENIORITY: {
			return {
				...state,
				userSeniority: defaultState.userSeniority,
			};
		}

		default:
			return state;
	}
}

export default reducer;
