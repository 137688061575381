export const SET_DRAWER = 'APP_SETTINGS/SET_DRAWER';

/**
 * @function setDrawerOpen
 * @param {boolean} payload
 */
export function setDrawerOpen(payload) {
	return {
		type: SET_DRAWER,
		payload,
	};
}
