export const getGlobalBundleRulesFilter = ({ productBundleRules }) => {
	// get global bundle rules id's
	const ids = productBundleRules.reduce((acc, currentValue, index) => {
		const id = currentValue.global_bundle_rule.id;

		acc =
			index === productBundleRules.length - 1
				? `${acc}'${id}']`
				: `${acc}'${id}',`;

		return acc;
	}, '[');

	return `:global_bundle_rule.id=IN=${ids}`;
};
