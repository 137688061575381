'use strict';

export const RESET_INGREDIENT_PRICING_GROUPS =
	'SALES_CONFIGURATION/RESET_INGREDIENT_PRICING_GROUPS';
export const SET_INGREDIENT_PRICINGS_GROUPS_TO_EDIT =
	'SALES_CONFIGURATION/SET_INGREDIENT_PRICINGS_GROUPS_TO_EDIT';
export const UPDATE_INGREDIENT_PRICING_GROUPS =
	'SALES_CONFIGURATION/UPDATE_INGREDIENT_PRICING_GROUPS';

/**
 * @param {object} payload
 */
export function setIngredientPricingGroupToEdit(payload) {
	return {
		type: SET_INGREDIENT_PRICINGS_GROUPS_TO_EDIT,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateIngredientPricingGroups(payload) {
	return {
		type: UPDATE_INGREDIENT_PRICING_GROUPS,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET_INGREDIENT_PRICING_GROUPS
	};
}
