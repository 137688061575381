'use strict';

// Services
import * as translations from 'translations.service';

export default class DirectInputService {

	//////////////////////
	// Public Functions //
	//////////////////////

	GetTranslations() {
		return translations.getObject([
			'COMPONENTS.INPUTS.DATE_PLACEHOLDER'
		]);
	}

}