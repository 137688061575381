'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';

// batch view
import PosTiersBatchStepList from './components/posTiersBatchStepList';

// phrases
import phrases from './../../posTiers.phrases';

class PosTiersModalBatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};

		this._batchSubmit = this._batchSubmit.bind(this);
	}

	_batchSubmit() {
		const { batchList, handleClose, addPosTiers } = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchList.map((entry) => {
			return {
				name: entry.name,
				...(entry.description && { description: entry.description }),
				...(entry.sortOrder && { sort_order: entry.sortOrder }),
				...(entry.externalId && { external_id: entry.externalId }),
			};
		});

		addPosTiers({ batch: payload })
			.then(() => {
				handleClose();
			})
			.finally(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	render() {
		const {
			modalVisible,
			handleClose,
			batchList,
			editBatchListItem,
			removeBatchListItem,
			addBatchListItemRow,
		} = this.props;

		const steps = [
			{
				component: (
					<PosTiersBatchStepList
						batchList={batchList}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
						productRecommendationUrl={this.productRecommendationUrl}
						tileLayoutUrl={this.tileLayoutUrl}
						ingredientConfigurationUrl={this.ingredientConfigurationUrl}
						addBatchListItemRow={addBatchListItemRow}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: this._batchSubmit,
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return modalVisible ? (
			<ModalStepper
				isOpen={modalVisible}
				steps={steps}
				showStep={false}
				onClose={handleClose}
			/>
		) : null;
	}
}

PosTiersModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	addPosTiers: PropTypes.func,
};

export default PosTiersModalBatch;
