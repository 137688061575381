'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { DateFilterSelector } from 'reactDataWrapper/utilities';
import { Button, Input, SingleDatePickerInput, Icon, Tooltip } from 'dumb';

// lodash
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

// phrases/constants/moment
import constants from 'services/constants';
import phrases from './salesConfigurationPosConfigurationDiscountProductVariant.phrases';
import moment from 'moment';

// batch modal
import SalesConfigurationPosConfigurationDiscountProductVariantModalBatchAdd from './components/batchAdd/salesConfigurationPosConfigurationDiscountProductVariantModalBatchAdd.component';
import SalesConfigurationPosConfigurationDiscountProductVariantModalBatchEdit from './components/batchEdit/salesConfigurationPosConfigurationDiscountProductVariantModalBatchEdit.component';

import {
	setDateFilters,
	addBatchList,
	updateBatchForm,
	resetState,
	editBatchListItem,
	removeBatchListItem,
	setLegacyFilter,
} from './store/salesConfigurationPosConfigurationDiscountProductVariant.actions';

import {
	fetchProductVariants,
	fetchPosConfigurationDiscountProductVariants,
	addPosConfigurationDiscountProductVariant,
	editPosConfigurationDiscountProductVariants,
	deletePosConfigurationDiscountProductVariant,
} from './salesConfigurationPosConfigurationDiscountProductVariant.service';

// hary styles
import './salesConfigurationPosConfigurationDiscountProductVariant.css';

const reduxKey = 'sales_configuration/pos_configuration_product_variants';
class SalesConfigurationPosConfigurationDiscountProductVariant extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showAddModal: false,
			showEditModal: false,
		};

		this.deleteMultiple = this.deleteMultiple.bind(this);
		this.toggleAddModal = this.toggleAddModal.bind(this);
		this.toggleEditModal = this.toggleEditModal.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.getAdditionalPosConfigurationDiscountTableFilters =
			this.getAdditionalPosConfigurationDiscountTableFilters.bind(this);
		this._onPosConfigurationDiscountTableFilterChange =
			this._onPosConfigurationDiscountTableFilterChange.bind(this);
		this.getActionButtons = this.getActionButtons.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);
		this.getReduxKey = this.getReduxKey.bind(this);

		this.columns = [
			{
				Header: 'Id',
				id: 'ID',
				accessor: 'realId',
				filterPath: ':id',
				show: false,
			},
			{
				Header: 'POS Configuration',
				id: 'posConfiguration',
				accessor: (d) =>
					_get(
						d,
						'pos_configuration_product_variant.pos_configuration_version.pos_configuration.name',
						null
					),
				filterPath:
					':pos_configuration_product_variant.pos_configuration_version.pos_configuration.name',
				width: 150,
			},
			{
				Header: 'POS Configuration Version',
				id: 'posConfigurationVersion',
				accessor: (d) =>
					_get(
						d,
						'pos_configuration_product_variant.pos_configuration_version.name',
						null
					),
				filterPath:
					':pos_configuration_product_variant.pos_configuration_version.name',
				width: 170,
			},
			{
				Header: 'Discount name',
				id: 'posConfigurationDiscountName',
				accessor: (d) => _get(d, 'pos_configuration_discount.name', null),
				width: 120,
				filterPath: ':pos_configuration_discount.name',
			},
			{
				Header: 'Market name',
				id: 'marketName',
				accessor: (d) =>
					_get(
						d,
						'pos_configuration_product_variant.pos_configuration_version.pos_configuration.market.name',
						null
					),
				filterPath:
					':pos_configuration_product_variant.pos_configuration_version.pos_configuration.market.name',
			},
			{
				Header: 'Active from',
				id: 'activeFrom',
				Cell: (d) => {
					const date = _get(
						d,
						'original.pos_configuration_product_variant.pos_configuration_version.active.from',
						false
					);

					return date ? (
						<span>{moment.utc(date).format(constants.shortDate)}</span>
					) : null;
				},
				filterPath:
					':pos_configuration_product_variant.pos_configuration_version.active.from',
				Filter: ({ column }) => (
					<DateFilterSelector
						reduxKey={this.getReduxKey()}
						columnId={column.id}
					/>
				),
			},
			{
				Header: 'Active to',
				id: 'activeTo',
				Cell: (d) => {
					const date = _get(
						d,
						'original.pos_configuration_product_variant.pos_configuration_version.active.to',
						false
					);

					return date ? (
						<span>{moment.utc(date).format(constants.shortDate)}</span>
					) : null;
				},
				filterPath:
					':pos_configuration_product_variant.pos_configuration_version.active.to',
				Filter: ({ column }) => (
					<DateFilterSelector
						reduxKey={this.getReduxKey()}
						columnId={column.id}
					/>
				),
			},
			...(this.props.productVariant
				? []
				: [
						{
							Header: 'Product variant',
							id: 'product',
							accessor: (d) =>
								_get(
									d,
									'pos_configuration_product_variant.product_variant.name',
									null
								),
							filterPath:
								':pos_configuration_product_variant.product_variant.name',
						},
				  ]),
			{
				Header: 'Category',
				width: 80,
				id: 'cat',
				accessor: (d) =>
					_get(
						d,
						'pos_configuration_product_variant.product_variant.product.product_category.name',
						null
					),
				filterPath:
					':pos_configuration_product_variant.product_variant.product.product_category.name',
			},
			{
				Header: 'To Stay Tax',
				width: 80,
				id: 'tstax',
				accessor: (d) =>
					_get(d, 'pos_configuration_product_variant.to_stay_tax.rate', null),
				filterPath: ':pos_configuration_product_variant.to_stay_tax.rate',
			},
			{
				Header: 'To Stay Price',
				width: 80,
				id: 'tsprice',
				accessor: (d) =>
					_get(d, 'pos_configuration_product_variant.to_stay_price', null),
				filterPath: ':pos_configuration_product_variant.to_stay_price',
			},
			{
				Header: 'To Stay Discount',
				width: 120,
				id: 'tsdiscount',
				accessor: (d) => _get(d, 'to_stay_discount_price', null),
				filterPath: ':to_stay_discount_price',
			},
			{
				Header: 'To Go Tax',
				width: 80,
				id: 'tgtax',
				accessor: (d) =>
					_get(d, 'pos_configuration_product_variant.to_go_tax.rate', null),
				filterPath: ':pos_configuration_product_variant.to_go_tax.rate',
			},
			{
				Header: 'To Go Price',
				width: 80,
				id: 'tgprice',
				accessor: 'pos_configuration_product_variant.to_go_price',
				filterPath: ':pos_configuration_product_variant.to_go_price',
			},
			{
				Header: 'To Go Discount',
				width: 120,
				id: 'tgdiscount',
				accessor: (d) => _get(d, 'to_go_discount_price', null),
				filterPath: ':to_go_discount_price',
			},
			{
				Header: 'Available in app',
				width: 100,
				id: 'availableInAppCheckbox',
				accessor: (d) => d.available_in_app,
				Cell: (d) => {
					return (
						<Input
							id="availableInAppTableCheckbox"
							disabled
							checked={_get(
								d,
								'original.pos_configuration_product_variant.available_in_app',
								false
							)}
							type="checkbox"
						/>
					);
				},
				filterPath: ':pos_configuration_product_variant.available_in_app',
				filterable: false,
			},
		];
	}

	deleteMultiple(selectedRows) {
		let filter = selectedRows
			// .filter(entry => entry.discount || !_isEmpty(entry.discount))
			.map((entry, index, arr) => {
				return index === arr.length - 1 ? `'${entry.id}'` : `'${entry.id}',`;
			})
			.join('');

		filter = `?filter=:id=IN=[${filter}]`;

		return deletePosConfigurationDiscountProductVariant(filter);
	}

	fetchData(state) {
		const { productVariant } = this.props;

		const filter = productVariant
			? `:pos_configuration_product_variant.product_variant.id=='${productVariant.id}'`
			: null;

		return fetchPosConfigurationDiscountProductVariants({
			state,
			productVariantFilter: filter,
		});
	}

	toggleAddModal() {
		if (this.state.showAddModal) {
			this.props.resetState();
		}

		this.setState((prevState) => ({ showAddModal: !prevState.showAddModal }));
	}

	toggleEditModal() {
		const { listPosConfigurationDiscountProductVariants, addBatchList } =
			this.props;

		if (!this.state.showEditModal) {
			const listArray = _get(
				listPosConfigurationDiscountProductVariants,
				'ui.selectedRows',
				[]
			).map((entry) => {
				return {
					identifier: entry.id,
					posConfigurationName: _get(
						entry,
						'pos_configuration_product_variant.pos_configuration_version.pos_configuration.name',
						''
					),
					posConfigurationVersionName: _get(
						entry,
						'pos_configuration_product_variant.pos_configuration_version.name',
						''
					),
					productVariantName:
						entry.pos_configuration_product_variant.product_variant.name,
					posConfigurationDiscountName: _get(
						entry,
						'pos_configuration_discount.name',
						''
					),
					toGoPrice: entry.pos_configuration_product_variant.to_go_price,
					toStayPrice: entry.pos_configuration_product_variant.to_stay_price,
					toGoDiscount: entry.to_go_discount_price,
					toStayDiscount: entry.to_stay_discount_price,
				};
			});

			addBatchList(listArray);
		} else {
			this.props.resetState();
		}

		this.setState((prevState) => ({ showEditModal: !prevState.showEditModal }));
	}

	_onPosConfigurationDiscountTableFilterChange(date) {
		const { setDateFilters } = this.props;

		const formattedDate = date
			? moment.utc(date).format(constants.shortDate)
			: null;

		setDateFilters(formattedDate);
	}

	toggleLegacyFilter() {
		const { setLegacyFilter, legacyFilter } = this.props;

		const filter = legacyFilter
			? ''
			: ':pos_configuration_version.pos_configuration.legacy==false';

		setLegacyFilter(filter);
	}

	getAdditionalPosConfigurationDiscountTableFilters() {
		const { posConfigurationDiscountProductVariantDateFilter, legacyFilter } =
			this.props;

		return (
			<div className="pos-configuration-discount-product-variant__filter-area">
				<SingleDatePickerInput
					id="posConfigDiscountDatePicker"
					label="Select active date"
					type="single"
					appendToBody
					fixed
					onChange={this._onPosConfigurationDiscountTableFilterChange}
					selectedDate={posConfigurationDiscountProductVariantDateFilter}
					noClockButton
					clearButtonDisabled={
						!posConfigurationDiscountProductVariantDateFilter
					}
					clearable
				/>
				<Tooltip
					text={
						legacyFilter
							? phrases.LEGACY_FILTER_TOOLTIP_ACTIVE
							: phrases.LEGACY_FILTER_TOOLTIP_UNACTIVE
					}
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							id="legacyFilterButton"
							className="pos-configuration-product-variants-pricing__filter-area__legacy-button"
							onClick={this.toggleLegacyFilter}
							type={legacyFilter ? '' : 'inverted'}
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
						>
							Legacy
						</Button>
					)}
				/>
			</div>
		);
	}

	getActionButtons() {
		const { listPosConfigurationDiscountProductVariants, productVariant } =
			this.props;

		const selectedRows = _get(
			listPosConfigurationDiscountProductVariants,
			'ui.selectedRows',
			[]
		);

		return (
			<>
				{/* only show edit button when editing original table, not as subtable */}
				{!_isEmpty(selectedRows) && !productVariant && (
					<Button
						type="inverted"
						label="Edit"
						shadow
						onClick={this.toggleEditModal}
					>
						<Icon name="edit" />
					</Button>
				)}
				<Button
					type="inverted"
					label="Add"
					shadow
					onClick={this.toggleAddModal}
				>
					<Icon name="add" />
				</Button>
			</>
		);
	}

	getExtraFilters() {
		const { posConfigurationDiscountProductVariantDateFilter, legacyFilter } =
			this.props;

		let filter = posConfigurationDiscountProductVariantDateFilter;
		if (legacyFilter && posConfigurationDiscountProductVariantDateFilter)
			filter = `${filter};${legacyFilter}`;
		if (!posConfigurationDiscountProductVariantDateFilter)
			filter = legacyFilter;

		return filter;
	}

	getReduxKey() {
		const { productVariant } = this.props;

		if (productVariant) return `${reduxKey}-${productVariant.id}`;

		return reduxKey;
	}

	render() {
		const {
			batchFormData,
			updateBatchForm,
			addBatchList,
			batchListData,
			editBatchListItem,
			removeBatchListItem,
			productVariant,
			posConfigurationDiscountProductVariantDateFilter,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					title={phrases.TABLE_TITLE}
					accessAreasAllowedToEdit={['Sales Configuration']}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={this.getReduxKey()}
					manual
					deleteMultiple={this.deleteMultiple}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					actionRender={this.getActionButtons()}
					customAreaComponents={this.getAdditionalPosConfigurationDiscountTableFilters()}
					hideIdColumn
					extraFilters={this.getExtraFilters()}
				/>

				{/* ADD MODAL */}
				<SalesConfigurationPosConfigurationDiscountProductVariantModalBatchAdd
					modalVisible={this.state.showAddModal}
					handleClose={this.toggleAddModal}
					batchFormData={batchFormData}
					updateBatchForm={updateBatchForm}
					addBatchList={addBatchList}
					batchListData={batchListData}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					fetchPosConfigurationProductVariants={fetchProductVariants}
					addPosConfigurationDiscountProductVariant={
						addPosConfigurationDiscountProductVariant
					}
					fetchPosConfigurationDiscountProductVariants={
						fetchPosConfigurationDiscountProductVariants
					}
					productVariant={productVariant}
					posConfigurationDiscountProductVariantDateFilter={
						posConfigurationDiscountProductVariantDateFilter
					}
				/>

				{/* EDIT MODAL */}
				<SalesConfigurationPosConfigurationDiscountProductVariantModalBatchEdit
					modalVisible={this.state.showEditModal}
					handleClose={this.toggleEditModal}
					batchFormData={batchFormData}
					updateBatchForm={updateBatchForm}
					addBatchList={addBatchList}
					batchListData={batchListData}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					editPosConfigurationDiscountProductVariants={
						editPosConfigurationDiscountProductVariants
					}
					reduxKey={this.getReduxKey()}
				/>
			</>
		);
	}
}

SalesConfigurationPosConfigurationDiscountProductVariant.propTypes = {
	resetState: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	addBatchList: PropTypes.func,
	batchListData: PropTypes.object,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	setDateFilters: PropTypes.func,
	posConfigurationDiscountProductVariantDateFilter: PropTypes.string,
	listPosConfigurationDiscountProductVariants: PropTypes.object,
	setLegacyFilter: PropTypes.func,
	legacyFilter: PropTypes.string,
	productVariant: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			resetState,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			setDateFilters,
			setLegacyFilter,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		batchFormData:
			store.salesConfigurationPosConfigurationDiscountProductVariant
				.batchFormData,
		globalProductDiscountsListData: store.listData[reduxKey]?.data?.listData,
		listPosConfigurationDiscountProductVariants: store.listData[reduxKey],
		batchListData:
			store.salesConfigurationPosConfigurationDiscountProductVariant
				.batchListData,
		posConfigurationDiscountProductVariantDateFilter:
			store.salesConfigurationPosConfigurationDiscountProductVariant
				.posConfigurationDiscountProductVariantDateFilter,
		legacyFilter:
			store.salesConfigurationPosConfigurationDiscountProductVariant
				.legacyFilter,
	};
};

export default connectWithStore(
	SalesConfigurationPosConfigurationDiscountProductVariant,
	mapStateToProps,
	mapDispatchToProps
);
