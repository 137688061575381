import React, { useState } from 'react';
import PropTypes from 'prop-types';

// components
import { ButtonGroup, Button, Icon, ButtonLoader, Label, Tag } from 'dumb';
import { ModalRow } from 'dumb/modal';

// utils
import enums from './../../../clockinDeviationApproval.enums';
import phrases from './../clockinDeviationApprovalMobileView.phrases';

// utils
import { formatShiftTime, getShiftDate, getShiftDay } from './../../../utils';

const ClockinDeviationApprovalMobileViewPage = ({
	deviation,
	updateDeviation,
	deviationApprovedColor,
	deviationDeclinedColor,
}) => {
	const confirmDeviation = () => {
		setUpdatingDeviation({ status: enums.CONFIRMED });

		return updateDeviation({
			deviationId: deviation.id,
			status: enums.CONFIRMED,
		}).then(() => setUpdatingDeviation(null));
	};

	const declineDeviation = () => {
		setUpdatingDeviation({ status: enums.DECLINED });

		return updateDeviation({
			deviationId: deviation.id,
			status: enums.DECLINED,
		}).then(() => setUpdatingDeviation(null));
	};

	// hooks
	const [updatingDeviation, setUpdatingDeviation] = useState(null);

	const deviationInteracted =
		deviation.status === enums.CONFIRMED || deviation.status === enums.DECLINED;

	return (
		<div className="clockin-deviation-approval-modal__mobile-view">
			<ModalRow>
				<h3 className="clockin-deviation-approval-modal__mobile-view__h3">
					{deviation?.employment?.person?.full_name ?? ''} has{' '}
					{deviation.delta_description}
				</h3>
			</ModalRow>
			<ModalRow>
				<div>
					<Label>{phrases.WORKPLACE}</Label>
					<p>{deviation?.workplace?.name}</p>
				</div>
			</ModalRow>
			<ModalRow>
				<div>
					<Label>{phrases.SHIFT_TIME}</Label>
					<div className="clockin-deviation-approval-modal__mobile-view__times-wrapper">
						<p>{getShiftDate(deviation.shift?.planned?.from)}</p>
						<div className="clockin-deviation-approval-modal__mobile-view__times-wrapper__weekday">
							({getShiftDay(deviation.shift?.planned?.from)})
						</div>
						<p>{', '}</p>
						<p>
							{`${formatShiftTime(
								deviation.shift?.planned?.from
							)} - ${formatShiftTime(deviation.shift?.planned?.to)}`}
						</p>
					</div>
				</div>
			</ModalRow>
			<ModalRow>
				<div>
					<Label>{phrases.DEVIATION_REASON}</Label>
					<p>{deviation.deviation_reason?.type}</p>
				</div>
			</ModalRow>
			<ModalRow>
				<div>
					<Label>{phrases.DEVIAITON_DESCRIPTION}</Label>
					<p>{deviation.deviation_reason?.description}</p>
				</div>
			</ModalRow>
			{/* <ModalRow>
				<div>
					<Label>{phrases.EVENT}</Label>
					<p>{deviation.delta_description}</p>
				</div>
			</ModalRow> */}
			<ModalRow>
				<div className="clockin-deviation-approval-modal__mobile-view__actions-wrapper">
					<ButtonGroup>
						<Button
							shadow
							type="success"
							size="large"
							onClick={confirmDeviation}
						>
							{updatingDeviation &&
							updatingDeviation?.status === enums.CONFIRMED ? (
								<ButtonLoader loading />
							) : (
								<Icon name="thumb_up" />
							)}
						</Button>
						<div className="clockin-deviation-approval-modal__mobile-view__actions-wrapper__button-wrapper">
							<Button
								shadow
								size="large"
								type="negative"
								onClick={declineDeviation}
							>
								{updatingDeviation &&
								updatingDeviation?.status === enums.DECLINED ? (
									<ButtonLoader loading />
								) : (
									<Icon name="thumb_down" />
								)}
							</Button>
						</div>
					</ButtonGroup>
				</div>
			</ModalRow>
			{deviationInteracted && (
				<ModalRow>
					<div className="clockin-deviation-approval-modal__mobile-view__tag-wrapper">
						<Tag
							type="bullet"
							color={
								deviation.status === enums.CONFIRMED
									? deviationApprovedColor
									: deviationDeclinedColor
							}
							noSpacing
						/>
						<Label>
							{deviation.status === enums.CONFIRMED
								? phrases.APPROVED
								: phrases.DECLINED}
						</Label>
					</div>
				</ModalRow>
			)}
		</div>
	);
};

ClockinDeviationApprovalMobileViewPage.propTypes = {
	deviation: PropTypes.array,
	updateDeviation: PropTypes.func,
	deviationApprovedColor: PropTypes.string,
	deviationDeclinedColor: PropTypes.string,
};

export default ClockinDeviationApprovalMobileViewPage;
