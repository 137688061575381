export default {
	TABLE_TITLE: 'Flairs',
	FLAIRS_ADDED: 'Flairs added',
	FLAIR_EDITED: 'Flair edited',
	FLAIRS_EDITED: 'Flairs edited',
	FLAIRS_DELETED: 'Flairs deleted',

	// modal batch stuff
	MODAL_BATCH_STEP_FORM_NAME: 'Name',
	MODAL_BATCH_STEP_FORM_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_EXTERNAL_NAME: 'External name',
	MODAL_BATCH_STEP_EXTERNAL_NAME_PLACEHOLDER: 'Enter External name...',
	MODAL_BATCH_STEP_FORM_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_FORM_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_EXTERNAL_DESCRIPTION: 'External description',
	MODAL_BATCH_STEP_EXTERNAL_DESCRIPTION_PLACEHOLDER:
		'Enter External description...',
	MODAL_BATCH_STEP_FORM_SORT_ORDER: 'Sort order',
	MODAL_BATCH_STEP_FORM_SORT_ORDER_PLACEHOLDER: 'Enter Sort order...',
	MODAL_BATCH_STEP_FORM_TITLE_COLOR: 'Title color',
	MODAL_BATCH_STEP_FORM_BACKGROUND_COLOR: 'Background color',

	MODAL_BATCH_STEP_FORM_GLOBAL_RAW_INGREDIENT: 'Global raw ingredient',
	MODAL_BATCH_STEP_FORM_GLOBAL_RAW_INGREDIENT_PLACEHOLDER:
		'Select Global raw ingredient...',
	MODAL_BATCH_STEP_FORM_AMOUNT: 'Amount',
	MODAL_BATCH_STEP_FORM_AMOUNT_PLACEHOLDER: 'Enter amount...',
	MODAL_BATCH_STEP_FORM_LEGACY: 'Legacy',
	MODAL_BATCH_STEP_LIST_TITLE: 'Add Flairs',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Submit flairs',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_LEGACY: 'Legacy',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row.',
	MODAL_BATCH_STEP_LIST_TOOLTIP_MAX_ROWS:
		'You can add max 10 rows at the time!',
	MODAL_BATCH_LIST_TIP: 'Click on the Tune icon to select a color for the Flair'
};
