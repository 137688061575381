import React, { useEffect } from 'react';
import TerritoriesList from './services/territoriesList.service.js';
import ListComponent from 'common/components/list/list.component';

const territoriesListReduxKey = '/administration/territories';
const territoriesList = new TerritoriesList(territoriesListReduxKey);
const territoriesListProps = territoriesList.getProps();

export function Territories() {

	useEffect(() => {
		territoriesList.initView();
	}, []);

	return <ListComponent reduxKey={territoriesListReduxKey} {...territoriesListProps} />;
}
