import { ActionType } from 'types/redux';
import { IMarketTagsReduxValue, IMarketTagsReduxValueBatch } from '../types';

export const SET_MARKET_TAG = 'MARKET_TAGS/SET_MARKET_TAG';
export const UPDATE_MARKET_TAG = 'MARKET_TAGS/UPDATE_MARKET_TAG';
export const RESET_STATE = 'MARKET_TAGS/RESET_STATE';

// redux stuff
export const EDIT_BATCH_FORM = 'MARKET_TAGS/EDIT_BATCH_FORM';
export const SET_BATCH_FORM_DATA = 'MARKET_TAGS/SET_BATCH_FORM_DATA';
export const EDIT_BATCH_LIST_ITEM = 'MARKET_TAGS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM = 'MARKET_TAGS/REMOVE_BATCH_LIST_ITEM';
export const RERENDER_TABLE = 'MARKET_TAGS/RERENDER_TABLE';

export function setmarketTag(payload: IMarketTagsReduxValue): ActionType {
	return {
		type: SET_MARKET_TAG,
		payload,
	};
}

type updatemarketTag = Partial<IMarketTagsReduxValue>;
export function updatemarketTag(payload: updatemarketTag): ActionType {
	return {
		type: UPDATE_MARKET_TAG,
		payload,
	};
}

export function resetState(): ActionType {
	return {
		type: RESET_STATE,
	};
}

export function editBatchForm(
	payload: Partial<IMarketTagsReduxValue>
): ActionType {
	return {
		type: EDIT_BATCH_FORM,
		payload,
	};
}

export function setBatchFormData(
	payload: IMarketTagsReduxValueBatch[]
): ActionType {
	return {
		type: SET_BATCH_FORM_DATA,
		payload,
	};
}

type editBatchListItem = Partial<IMarketTagsReduxValueBatch>;
export function editBatchListItem(payload: editBatchListItem): ActionType {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload: string): ActionType {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function rerenderTable(): ActionType {
	return {
		type: RERENDER_TABLE,
	};
}
