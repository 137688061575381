export const UPDATE_BATCH_FORM =
	'SALES_CONFIGURATION_POS_CONFIGURATION/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'SALES_CONFIGURATION_POS_CONFIGURATION/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_POS_CONFIGURATION/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_POS_CONFIGURATION/REMOVE_BATCH_LIST_ITEM';

export const SET_POS_CONFIGURATION =
	'SALES_CONFIGURATION_POS_CONFIGURATION/SET_POS_CONFIGURATION';
export const UPDATE_POS_CONFIGURATION =
	'SALES_CONFIGURATION_POS_CONFIGURATION/UPDATE_POS_CONFIGURATION';
export const RESET_STATE = 'SALES_CONFIGURATION_POS_CONFIGURATION/RESET_STATE';

export function setPosConfiguration(payload) {
	return {
		type: SET_POS_CONFIGURATION,
		payload
	};
}

export function updatePosConfiguration(payload) {
	return {
		type: UPDATE_POS_CONFIGURATION,
		payload
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload
	};
}

export function resetState() {
	return {
		type: RESET_STATE
	};
}
