'use strict';

import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './employmentsList.phrases';

/**
 * @function fetchEmploymentList
 * @description Fetch employments belonging to person
 * @param {object} state
 */
export function fetchEmploymentList(state, personId) {
	const { limit, sort, offset, headers } = state;

	// add active employments filter if exists
	let filter = `:person.id=='${personId}'`;
	filter = state.filter ? `${filter};${state.filter}` : filter;

	// Set params and filter
	const params = {
		limit,
		sort,
		offset,
		filter
	};

	return get('/hr/employments', params, null, headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addEmploymentList
 * @description add employment
 */
export function addEmploymentList(data) {
	return post('/hr/employments', data)
		.then(res => {
			setFeedback(phrases.EMPLOYMENT_LIST_ADDED, 1);

			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editEmploymentList
 * @description edit employment
 */
export function editEmploymentList(data) {
	const employmentId = data.id;

	return post(`/hr/employments/${employmentId}`, data)
		.then(res => {
			const shouldUpdateTerminationReason = !!data.terminationReason;

			const updateTerminationReason = shouldUpdateTerminationReason
				? post(`/hr/terminations/${data.terminationId}`, {
					termination_reason: data.terminationReason
				  })
				: new Promise(resolve => resolve(true));

			return updateTerminationReason.then(terminationRes => {
				setFeedback(phrases.EMPLOYMENT_LIST_EDITED, 1);

				const updatedRes = shouldUpdateTerminationReason
					? {
						...res,
						data: [
							{
								...res.data[0],
								termination: {
									...res.data[0].termination,
									termination_reason: {
										...terminationRes.data[0].termination_reason
									}
								}
							}
						]
					  }
					: res;

				return updatedRes;
			});
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function deleteEmploymentList
 * @description delete employment
 */
export function deleteEmploymentList(productId) {
	return remove(`/hr/employments/${productId}`)
		.then(res => {
			setFeedback(phrases.EMPLOYMENT_LIST_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
