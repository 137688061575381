import React, { Suspense } from 'react';

const Intro = React.lazy(() => import(/* webpackChunkName: "Intro" */ 'intro/intro.container'));

export const introRoute = [
	{
		element: (
			<Suspense fallback={<div>Loading...</div>}>
				<Intro />
			</Suspense>
		),
		path: '/',
		title: 'Home',
		icon: require('@mui/icons-material/PlayArrowOutlined').default,
	},
];
