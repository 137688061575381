// lodash
import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';

/**
 * @function getTileLayoutGroupsIds
 * @param {Array} tileLayoutGroups - array of seleceted tile layout groups
 * @description returns ids of all tile layout groups
 */
export function getTileLayoutGroupsIds(tileLayoutGroups) {
	return tileLayoutGroups.reduce((acc, currentValue, index) => {
		const id = _get(currentValue, 'value.id', null);

		acc =
			index === tileLayoutGroups.length - 1
				? `${acc}'${id}']`
				: `${acc}'${id}',`;

		return acc;
	}, '[');
}

/**
 * @func getTileLayoutIds
 * @param {Object} object - contains selected tile layout groups
 * @description returns ids of all tile layouts
 */
export function getTileLayoutIds({ tileLayoutGroups }) {
	return tileLayoutGroups.reduce((acc, currentValue, index) => {
		const id = _get(currentValue, 'value.tile_layout.id', null);

		acc =
			index === tileLayoutGroups.length - 1
				? `${acc}'${id}']`
				: `${acc}'${id}',`;

		return acc;
	}, '[');
}

/**
 * @function getReorderProductsMetadata
 * @param {Object} object - contains batchForm object with data and all fetched tile layout tiles
 * @description returns metadata that will be used to handle reordering tile layout tiles more gracefully
 * uniqueTileLayoutGroups and uniqueTileLayoutTiles are the ones shown in the view
 * they are all unique groups and tiles based on selected layout groups
 */
export function getReorderProductsMetadata({ batchForm, tileLayoutTiles }) {
	// get rid of duplicate data
	let uniqueTileLayoutGroups = _uniqBy(
		batchForm.tile_layout_groups,
		'value.name'
	);
	let uniqueTileLayoutTiles = _uniqBy(tileLayoutTiles, 'name');

	// omit unnecessary keys
	uniqueTileLayoutGroups = uniqueTileLayoutGroups.map((entry) => ({
		id: _get(entry, 'value.id', null),
		asset_collection: _get(entry, 'value.asset_collection', null),
		name: _get(entry, 'value.name', null),
	}));
	uniqueTileLayoutTiles = uniqueTileLayoutTiles.map((entry) => ({
		id: entry.id,
		tile_layout_group: entry.tile_layout_group,
		name: entry.name,
		description: entry.description,
		product: entry.product,
		position: entry.position,
	}));

	return {
		uniqueTileLayoutGroups,
		uniqueTileLayoutTiles,
	};
}
