import React, { Suspense } from 'react';

const MyShiftplanner = React.lazy(() =>
	import(/* webpackChunkName: "MyShiftplanner" */ 'structure/myShiftplanner/myShiftplanner.container')
);

export const myShiftPlannerRoutes = [
	{
		element: (
			<Suspense fallback={<div>Loading...</div>}>
				<MyShiftplanner />
			</Suspense>
		),
		path: 'my-shiftplanner',
		title: 'My shiftplanner',
		icon: require('@mui/icons-material/EventOutlined').default,
		allowedAccessControlLevels: ['Shiftplan View', 'Shiftplan Manager', 'Shiftplan Admin'],
	},
];
