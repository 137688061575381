'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import SelectInput from 'react-select';

import Date from 'reusableComponents/inputs/date/date.component';
import Select from 'reusableComponents/inputs/select.component';
import Time from 'reusableComponents/inputs/time.component';
import DefaultInput from 'reusableComponents/inputs/default.component';

import _get from 'lodash/get';
import _has from 'lodash/has';
import _isNull from 'lodash/isNull';
import _head from 'lodash/head';
import _findKey from 'lodash/findKey';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _isArray from 'lodash/isArray';

class Filter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: '',
			filterId: null,
			operator: '',
			operators: [],
			sortType: '',
			sortTypes: [],
			inputFamily: '',
			isTouched: false,
			shouldDebounce: false
		};

		this._setValue = this._setValue.bind(this);
		this._setSortType = this._setSortType.bind(this);
		this._setOperator = this._setOperator.bind(this);
		this._clearValue = this._clearValue.bind(this);
		this._removeFilter = this._removeFilter.bind(this);
	}

	/**
	 * @function _initOperators
	 * @memberOf COMPONENTS.filterBar.filter
	 * @description
	 * Initiates list of operators and also the current selected operator, which
	 * is depending on whether filterHeader is active.
	 */
	_initOperators() {
		// Set operator array on component load
		// Operators are header.type specific, unless anything else is defined in
		// header.reference.operators
		var operatorDefaults;
		if (
			_has(this.props.header, 'reference') &&
			this.props.header.reference.operators
		) {
			operatorDefaults = this.props.header.reference.operators;
		} else {
			switch (this.props.header.type) {
			case 'bool':
				operatorDefaults = ['=='];
				break;
			case 'currency':
				operatorDefaults = ['==', '=gt=', '=lt=', '=ge=', '=le='];
				break;
			case 'date':
				operatorDefaults = ['time.=!='];
				break;
			case 'datetime':
				operatorDefaults = ['time.=!='];
				break;
			case 'time':
				operatorDefaults = [
					'time.=lt=',
					'time.=gt=',
					'time.=ge=',
					'time.=le=',
					'time.=='
				];
				break;
			case 'number':
				operatorDefaults = ['==', '=gt=', '=lt=', '=ge=', '=le='];
				break;
			case 'select':
				operatorDefaults = ['=='];
				break;

				// mail, string
			default:
				operatorDefaults = ['=like=', '=='];
				break;
			}
		}

		// Map operatorDefaults to value/label
		var operators = _map(
			operatorDefaults,
			function(operator) {
				return {
					value: operator,
					label: _get(this.props.filterOperators, operator)
				};
			}.bind(this)
		);

		// Set current operator based on active filterHeader
		var operator = this.props.filter.active
			? this.props.filter.operator
			: _head(operators);
		this.setState({
			operators: operators,
			operator: operator
		});
		return [operator, operators];
	}

	/**
	 * @function _initSortTypes
	 * @memberOf COMPONENTS.filterBar.filter
	 * @description
	 * Initiates list of sort types and also the current selected sort type, which
	 * is depending on whether filterHeader is active.
	 */
	_initSortTypes() {
		// Set sort by array on component load
		var sortTypeDefaults;
		switch (this.props.header.type) {
		case 'currency':
			sortTypeDefaults = ['sort.default', 'sort.absolute'];
			break;
		case 'number':
			sortTypeDefaults = ['sort.default', 'sort.absolute'];
			break;
		}

		// Map operatorDefaults to value/label
		var sortTypes = _map(
			sortTypeDefaults,
			function(sortType) {
				return {
					value: sortType,
					label: _get(this.props.filterOperators, sortType)
				};
			}.bind(this)
		);

		// Set current sortType based on active filterHeader
		var sortType = this.props.filter.active
			? this.props.filter.sortType
			: _head(sortTypes);
		this.setState({
			sortTypes: sortTypes,
			sortType: sortType
		});
		return [sortType, sortTypes];
	}

	/**
	 * @function _hasDefaultValue
	 * @memberOf COMPONENTS.filterBar.filter
	 * @description
	 * Handles if header has a default value attached.
	 */
	_hasDefaultValue() {
		if (this.props.filter.defaultValue) {
			var value = this.props.filter.defaultValue;
			switch (this.props.header.type) {
			case 'date':
				this.setState({
					value: value,
					date: value
				});
				break;
			case 'datetime':
				this.setState({
					value: value,
					date: value
				});
				break;
			case 'time':
				this.setState({
					value: value,
					time: value
				});
				break;
			}
		}
		this.setState({ isTouched: true });
	}

	/**
	 * @function _getInputFamily
	 * @memberOf COMPONENTS.filterBar.filter
	 * @description
	 * Gets input family for current input type to minimize number of 'ifs'
	 * as some of the input types are treated the same styling wise
	 */
	_getInputFamily() {
		var type = this.props.header.type;
		var families = {
			checkbox: ['bool'],
			date: ['date', 'datetime'],
			time: ['time'],
			input: ['string', 'longString', 'mail', 'tel', 'number', 'currency'],
			select: ['multi', 'select']
		};
		var inputFamily = _findKey(families, function(value, key) {
			if (_includes(value, type)) {
				return key;
			}
		});
		this.setState({ inputFamily: inputFamily });
		return inputFamily;
	}

	/**
	 * @function _inputClassNames
	 * @memberOf COMPONENTS.filterBar.filter
	 * @description Class names
	 */
	_inputClassNames() {
		return cx('data-input', {
			'data-input__input': this.state.inputFamily === 'input'
		});
	}

	/**
	 * @function _getDefaultValue
	 * @memberOf COMPONENTS.filterBar.filter
	 * @description
	 * Gets default value of any given type input field. Returns empty if not
	 * this.props.header.active.
	 */
	_getDefaultValue() {
		if (this.props.filter.active) {
			switch (this.state.inputFamily) {
			case 'checkbox':
				return {
					value: this.props.filter.filter.value,
					label: this.props.filter.filter.label
				};
			case 'date':
				return this.props.filter.filter;
			case 'time':
				return this.props.filter.filter;
			case 'select':
				return {
					value: this.props.filter.filter.value,
					label: this.props.filter.filter.label
				};

				// currency, email, number, text
			default:
				return this.props.filter.filter;
			}
		}
	}

	/**
	 * @function _setDefaultState
	 * @memberOf COMPONENTS.filterBar.filter
	 * @description
	 * Sets default state values if this.props.header.active
	 */
	_setDefaultState() {
		if (this.props.filter.active) {
			switch (this._getInputFamily()) {
			case 'date':
				this.setState({ value: this._getDefaultValue() });
				break;
			case 'time':
				this.setState({
					value: this._getDefaultValue(),
					time: this._getDefaultValue()
				});
				break;

				// currency, email, number, text + checkbox, select
			default:
				this.setState({ value: this._getDefaultValue() });
				break;
			}
		}
	}

	/**
	 * @function _setValue
	 * @memberOf COMPONENTS.filterBar.filter
	 * @description
	 * Sets value as state based on input family.
	 * Calls filter method from filterBar component.
	 */
	_setValue(event) {
		var value;
		switch (this.state.inputFamily) {
		case 'checkbox':
			value = event;
			break;
		case 'date':
			value = event;
			break;
		case 'time':
			value = event;
			this.setState({ time: event });
			break;
		case 'select':
			value = _isArray(event) ? {} : event;
			break;

			// currency, email, number, text
		default:
			value = event.target.value;
			break;
		}
		this.props.doFilter(
			this.props.header,
			value,
			this.state.operator,
			this.props.filter.filterId,
			this.state.sortType,
			this.state.shouldDebounce
		);
		this.setState({ value: value, isTouched: true });
	}

	/**
	 * @function _clearValue
	 * @memberOf COMPONENTS.filterBar.filter
	 * @description
	 * Sets value as state based on input family.
	 * Clears value
	 */
	_clearValue() {
		if (this.props.filter.active)
			this.props.doFilter(
				this.props.header,
				'',
				this.state.operator,
				this.props.filter.filterId,
				this.state.sortType,
				this.state.shouldDebounce
			);
		else
			this.props.doFilter(
				this.props.header,
				this.state.value,
				this.state.operator,
				this.props.filter.filterId,
				this.state.sortType,
				this.state.shouldDebounce
			);
	}

	/**
	 * @function _removeFilter
	 * @memberOf COMPONENTS.filterBar.filter
	 * @description Removes current filter from filterBar and clears filter value
	 */
	_removeFilter() {
		this._clearValue();
		this.props.methods.handleToggleFilterBarElement(
			this.props.header,
			this.props.filter.filterId
		);
	}

	/**
	 * @function _setOperator
	 * @memberOf COMPONENTS.filterBar.filter
	 * @description Sets operator for current filter
	 */
	_setOperator(event) {
		this.setState({ operator: event });
		this.props.doFilter(
			this.props.header,
			this.state.value,
			event,
			this.props.filter.filterId,
			this.state.sortType,
			this.state.shouldDebounce
		);
	}

	/**
	 * @function _setSortType
	 * @memberOf COMPONENTS.filterBar.filter
	 * @description Sets operator for current filter
	 */
	_setSortType(event) {
		this.setState({ sortType: event });
		this.props.doFilter(
			this.props.header,
			this.state.value,
			this.state.operator,
			this.props.filter.filterId,
			event,
			this.state.shouldDebounce
		);
	}

	_setDebounce() {
		switch (this.state.inputFamily) {
		case 'date':
			this.setState({ shouldDebounce: false });
			break;

			// currency, email, number, text
		default:
			this.setState({ shouldDebounce: true });
			break;
		}
	}

	/**
	 * @function _renderInput
	 * @memberOf COMPONENTS.filterBar.filter
	 */
	_renderInput() {
		var header = this.props.header;
		var name = _has(header, 'reference')
			? header.reference.newName
			: header.name;
		switch (this.state.inputFamily) {
		case 'checkbox':
			// Special case
			// Don't use CheckboxInput as
			var boolOptions = [
				{ value: true, label: 'True' },
				{ value: false, label: 'False' }
			];
			return (
				<Select
					ref={input => {
						this.filterInput = input;
					}}
					clearable={false}
					labelTemplate={
						_has(header.reference, 'label')
							? header.reference.label
							: '<%= name %>'
					}
					name={name}
					onChange={this._setValue}
					options={boolOptions}
					initialValue={this.state.value}
				/>
			);
		case 'date':
			return (
				<Date
					ref={input => {
						this.filterInput = input;
					}}
					onChange={this._setValue}
					initialStartDate={
						moment.isMoment(this.state.value.startDate)
							? this.state.value.startDate
							: null
					}
				/>
			);
		case 'time':
			return (
				<Time
					ref={input => {
						this.filterInput = input;
					}}
					showMinutes
					onChange={this._setValue}
					initialTime={this.state.value}
				/>
			);
		case 'select':
			return (
				<Select
					ref={input => {
						this.filterInput = input;
					}}
					labelTemplate={
						_has(header.reference, 'label')
							? header.reference.label
							: '<%= name %>'
					}
					name={name}
					onChange={this._setValue}
					options={
						_has(header, 'options') && _has(header.options, 'options')
							? header.options.options
							: undefined
					}
					searchFields={
						_has(header.reference, 'searchFields')
							? header.reference.searchFields
							: undefined
					}
					searchList={
						_has(header.reference, 'list') ? header.reference.list : undefined
					}
					searchListData={
						_has(header.reference, 'searchListData')
							? header.reference.searchListData
							: undefined
					}
					searchListFilter={
						_has(header.reference, 'searchListFilter')
							? header.reference.searchListFilter
							: undefined
					}
					value={this.state.value}
				/>
			);
		default:
			return (
				<DefaultInput
					{...this.props}
					ref={input => {
						this.filterInput = input;
					}}
					header={this.props.header}
					onChange={this._setValue}
					forceText
					defaultValue={this._getDefaultValue()}
				/>
			);
		}
	}

	/**
	 * @function _renderOperator
	 * @memberOf COMPONENTS.filterBar.filter
	 */
	_renderOperator() {
		return (
			<span className="data-input filter-bar__filter__operator">
				<div className="select">
					<SelectInput
						value={this.state.operator}
						onChange={this._setOperator}
						disabled={this.state.operators.length < 2}
						name="operator"
						searchable={false}
						clearable={false}
						options={this.state.operators}
					/>
				</div>
			</span>
		);
	}

	/**
	 * @function _renderSortType
	 * @memberOf COMPONENTS.filterBar.filter
	 */
	_renderSortType() {
		if (
			this.props.header.type === 'number' ||
			this.props.header.type === 'currency'
		) {
			return (
				<span className="data-input filter-bar__filter__operator">
					<div className="select">
						<SelectInput
							value={this.state.sortType}
							onChange={this._setSortType}
							name="sortType"
							searchable={false}
							clearable={false}
							options={this.state.sortTypes}
						/>
					</div>
				</span>
			);
		} else return null;
	}

	/**
	 * @function _renderClearButton
	 * @memberOf COMPONENTS.filterBar.filter
	 */
	_renderClearButton() {
		if (this.props.filter.active)
			return (
				<button className="button filter-bar__filter__clear-button icon icon--radio_button_checked" />
			);
		else if (_isNull(this.state.value) || this.state.value === '') return null;
		else
			return (
				<button className="button filter-bar__filter__clear-button icon icon--radio_button_unchecked" />
			);
	}

	componentDidMount() {
		this._getInputFamily();
		this._initOperators();
		this._initSortTypes();
		this._setDefaultState();
		this._hasDefaultValue();
		this._setDebounce();
	}

	componentDidUpdate() {
		this.filterInput.focus();
	}

	UNSAFE_componentWillReceiveProps() {
		this._setDebounce();
	}

	render() {
		var filterClassNames = cx('filter-bar__form-field', 'filter-bar__filter', {
			'filter-bar__filter--active': this.props.filter.active
		});
		var header = this.props.header;
		var colGroup =
			_has(header, 'colGroupLabel') && !_has(header, 'emptyColGroup')
				? header.colGroupLabel + ', '
				: '';
		var label = colGroup + header.label;
		return (
			<div title={label} className={filterClassNames}>
				<span onClick={this._clearValue} className="filter-bar__filter__header">
					{label}
					{this._renderClearButton()}
				</span>
				{this._renderOperator()}
				{this._renderSortType()}
				<span className={this._inputClassNames()}>{this._renderInput()}</span>
				<button
					onClick={this._removeFilter}
					className="button filter-bar__filter__remove-button icon icon--clear"
				/>
			</div>
		);
	}
}

Filter.propTypes = {
	doFilter: PropTypes.func.isRequired,
	filter: PropTypes.object.isRequired,
	filterOperators: PropTypes.object.isRequired,
	header: PropTypes.object.isRequired
};

export default Filter;
