'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';
const __enums = require('enums.service');
const __endpoints = require('endpoints.service');

export default class GlobalDiscountsList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
				canCreate: true,
				canEdit: true,
				span: 3
			},
			{
				name: 'description',
				type: 'string',
				canCreate: true,
				canEdit: true,
				span: 3
			},
			{
				name: 'type',
				type: 'select',
				canCreate: true,
				canEdit: true,
				required: true,
				span: 3,
				options: {
					enums: __enums.discountTypes,
				},
			},
			{
				name: 'loyalty_type',
				type: 'select',
				canCreate: true,
				canEdit: true,
				span: 3,
				reference: {
					name: 'loyalty_type',
					list: '/campaigning/loyalty_types',
				},
			},
		];
		this.settings = {
			name: 'GlobalDiscountsList',
			pageTitle: true,
			canAdd: true,
			canEdit: true,
			translationPath: 'SALE.DISCOUNTS',
			canManipulate: () => validAccess(undefined, ['Global BI Configuration']),
			endpoint: __endpoints.collection.sale.discounts,
		};
		this.init();
		return this;
	}
}
