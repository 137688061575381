import {
	UPDATE_PRODUCT_VARIANT_BASKET_RECOMMENDATION,
	SET_PRODUCT_VARIANT_BASKET_RECOMMENDATION,
	RESET_PRODUCT_VARIANT_BASKET_RECOMMENDATION,
	UPDATE_BATCH_FORM,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
} from './productVariantBasketRecommendations.actions';

const defaultState = {
	data: {
		defaultProductVariantBasketRecommendations: {},
		batchFormData: {
			productVariant: null,
			basketRecommendation: null,
			basketExclusion: null,
			existingBasketRecommendation: null,
			existingBasketExclusion: null,
		},
		batchListData: [],
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_PRODUCT_VARIANT_BASKET_RECOMMENDATION: {
			return {
				data: {
					defaultProductVariantBasketRecommendations: payload,
				},
			};
		}

		case UPDATE_PRODUCT_VARIANT_BASKET_RECOMMENDATION: {
			return {
				data: {
					defaultProductVariantBasketRecommendations: {
						...state.data.defaultProductVariantBasketRecommendations,
						...payload,
					},
				},
			};
		}

		case RESET_PRODUCT_VARIANT_BASKET_RECOMMENDATION: {
			return {
				...defaultState,
			};
		}

		case UPDATE_BATCH_FORM: {
			return {
				data: {
					...state.data,
					batchFormData: {
						...state.data.batchFormData,
						...payload,
					},
				},
			};
		}

		case EDIT_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchListData: state.data.batchListData.map((entry) => {
						if (entry.identifier === payload.identifier) return payload;
						return entry;
					}),
				},
			};
		}

		case REMOVE_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchListData: state.data.batchListData.filter(
						(entry) => entry.identifier !== payload
					),
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
