import {
	RESET_STATE,
	LOADING_CLOPES,
	LOADING_OVERHEADS,
	SET_DEFAULT_CLOPES,
	SET_WEEKLY_OVERHEADS,
	UPDATE_OVERHEADS,
	REMOVE_OVERHEADS,
	UPDATE_CLOPES,
	REMOVE_CLOPES
} from './weeklyRequirements.actions';

const defaultState = {
	ui: {
		loadingOverheads: false,
		loadingClopes: false
	},
	data: {
		overheads: [],
		clopes: []
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_DEFAULT_CLOPES: {
		return {
			...state,
			data: { ...state.data, clopes: payload }
		};
	}

	case SET_WEEKLY_OVERHEADS: {
		return {
			...state,
			data: { ...state.data, overheads: payload }
		};
	}

	case LOADING_CLOPES: {
		return { ...state, ui: { ...state.ui, loadingClopes: payload } };
	}

	case LOADING_OVERHEADS: {
		return { ...state, ui: { ...state.ui, loadingOverheads: payload } };
	}

	case UPDATE_OVERHEADS: {
		const newOverheads = state.data.overheads.filter(
			x => x.id !== payload.id
		);

		return {
			...state,
			data: {
				...state.data,
				overheads: [...newOverheads, ...[payload]]
			},
			ui: { ...state.ui, loadingOverheads: false }
		};
	}

	case REMOVE_OVERHEADS: {
		const overheads = state.data.overheads.filter(x => x.id !== payload);
		return {
			...state,
			data: {
				...state.data,
				overheads
			},
			ui: { ...state.ui, loadingOverheads: false }
		};
	}

	case UPDATE_CLOPES: {
		const clopesWithout = state.data.clopes.filter(x => x.id !== payload.id);
		return {
			...state,
			data: {
				...state.data,
				clopes: [...clopesWithout, ...[payload]]
			},
			ui: { ...state.ui, loadingClopes: false }
		};
	}

	case REMOVE_CLOPES: {
		const clopes = state.data.clopes.filter(x => x.id !== payload);
		return {
			...state,
			data: {
				...state.data,
				clopes
			},
			ui: { ...state.ui, loadingClopes: false }
		};
	}

	case RESET_STATE: {
		return defaultState;
	}

	default:
		return state;
	}
}

export default reducer;
