'use strict';

export const SET_EOD_CONTROL = 'EOD_CONTROL/SET_EOD_CONTROL';
export const SET_TABLE_EXPANDED = 'EOD_CONTROL/SET_TABLE_EXPANDED';
export const SET_TABLE_CONTENT = 'EOD_CONTROL/SET_TABLE_CONTENT';
export const SET_TABLE_ROW = 'EOD_CONTROL/SET_TABLE_ROW';
export const CLEAN_EOD_CONTROL = 'EOD_CONTROL/CLEAN_EOD_CONTROL';

/**
 * @param {object} eodControl
 */
export function setEodControl(eodControl) {
	return {
		type: SET_EOD_CONTROL,
		payload: {
			eodControl
		}
	};
}

/**
 * @param {string} tableName
 * @param {bool} expanded
 */
export function setTableExpanded(tableName, expanded) {
	return {
		type: SET_TABLE_EXPANDED,
		payload: {
			tableName,
			expanded
		}
	};
}

/**
 * @param {string} tableName
 * @param {object} content
 */
export function setTableContent(rowId, content) {
	return {
		type: SET_TABLE_CONTENT,
		payload: {
			rowId,
			content
		}
	};
}

/**
 * @param {object} row
 */
export function setTableRow(row) {
	return {
		type: SET_TABLE_ROW,
		payload: {
			row
		}
	};
}


/**
 * @description clean EOD control
 */
export function cleanEodControl() {
	return {
		type: CLEAN_EOD_CONTROL
	};
}