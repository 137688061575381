/* eslint-disable react/prop-types */
import { IColumnHeader } from 'types/reactDataWrapper';

export default function getColumns(): IColumnHeader<IReportingPowerBiDataSet>[] {
	const columns: IColumnHeader<IReportingPowerBiDataSet>[] = [
		{
			Header: 'Dataset id',
			id: 'dataset-id',
			accessor: 'dataset_id',
			filterPath: ':dataset_id',
		},
	];

	return columns;
}
