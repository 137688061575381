'use strict';

const headers = {
	BANKS: {
		ID: 'id',
		NAME: 'Name',
	},
	COUNTRIES: {
		ID: 'id',
		NAME: 'Name',
		ALPHA_2_CODE: 'A2 code',
		ALPHA_3_CODE: 'A3 code',
		NUMERIC_CODE: 'No. code',
		ISO_3166_2_CODE: 'ISO3166 2',
		NATIONALITY: 'Nationality',
	},
	COUNTRY_TO_CURRENCY_RELATIONS: {
		ID: 'id',
		COUNTRY: 'Country',
		CURRENCY: 'Currency',
		START_DATE: 'Start date',
		END_DATE: 'End date',
	},
	CURRENCIES: {
		ID: 'id',
		CODE: 'Code',
		DESCRIPTION: 'Description',
		SUPPORTED_DECIMALS: 'Supported decimals',
		CURRENCY_RATES: 'Rate',
	},
	CURRENCY_DENOMINATIONS: {
		ID: 'id',
		CURRENCY: 'Currency',
		DENOMINATION: 'Denomination',
		TYPE: 'Type',
		NAME: 'Name',
	},
	CURRENCY_RATES: {
		ID: 'id',
		CURRENCY: 'Currency',
		RATE: 'Rate',
		START_DATE: 'Start date',
		END_DATE: 'End date',
	},
	MARKET_TAX_RATES: {
		ID: 'id',
		NAME: 'Name',
		TYPE: 'Type',
		RATE: 'Rate',
		TAX_PURPOSE: 'Tax purpose',
		EXTERNAL_EXPORT_ID: 'Ext. export id',
		DYNAMICS_ITEM_SALES_TAX_GROUP: 'Dynamics item sales tax group',
		DYNAMICS_SALES_TAX_GROUP: 'Dynamics sales tax group',
		START_DATE: 'Start date',
		END_DATE: 'End date',
	},
	MARKETS: {
		ID: 'id',
		NAME: 'Name',
		COUNTRY: 'Country',
		MARKET_REGION: 'Market Region',
		COMPANIES: 'Companies',
		DEFAULT_BREAK_TAG: 'Default break tag',
		TAX_LABEL: 'Tax label',
		OPENED: 'Opened',
		CLOSED: 'Closed',
		MARKET: 'Market',
		SALARY_EXPORT_FORMAT: 'Salary export format',
		DEFAULT_SALARY_FORMAT: 'Default salary format',
		DEFAULT_SALARY_GROUP: 'Default salary group',
		PRECISE_SHIFTPLANNING: 'Minute based shiftplanning',
		CLOCKING_ALIGNMENT_REQUIRED: 'Clocking alignment required',
	},
	MARKET_REGIONS: {
		ID: 'id',
		NAME: 'name',
		SORT_ORDER: 'Sort order',
		CONTINENT: 'Continent',
	},
	REGION_MANAGERS: {
		ID: 'id',
		WORKPLACE: 'Workplace',
		EMPLOYMENT: 'Employment',
		REGION: 'Region',
		FROM: 'From',
		TO: 'TO',
	},

	MARKET_REGIONS_MARKET: {
		ID: 'id',
		NAME: 'name',
		COUNTRY: 'Country',
		TAX_LABEL: 'Tax label',
		OPENED: 'Opened',
		CLOSED: 'Closed',
	},

	COMPANIES: {
		ID: 'id',
		NAME: 'Name',
		MARKET: 'Market',
		MAIN_ENTITY: 'Main entity',
		MERCHANT_ACCOUNT: 'Merchant account',
		OPENED: 'Opened',
		CLOSED: 'Closed',
		COMPANY_ACCOUNT_SCHEDULE: 'Account schedule',
	},
	TERRITORIES: {
		ID: 'id',
		NAME: 'Name',
		MARKET: 'Market',
		OPENED: 'Opened',
		CLOSED: 'Closed',
	},
	COMPANY_ADDRESSES: {
		ID: 'id',
		COUNTRY: 'Country',
		ADDRESS: 'Address',
	},
	COMPANY_BANK_ACCOUNTS: {
		ID: 'id',
		NAME: 'Name',
		BANK: 'Bank',
		BANK_CODE: 'Bank code',
		BANK_ACCOUNT_NUMBER: 'Bank account number',
		SWIFT: 'SWIFT',
		IBAN: 'IBAN',
		GIRO: 'Giro',
		OPENED: 'Opened',
		CLOSED: 'Closed',
	},
	COMPANY_CAPITAL_TRANSACTIONS: {
		ID: 'id',
		SENDER: 'Sender',
		RECEIVER: 'Receiver',
		DATE: 'Date',
		PAID_AMOUNT: 'Paid amount',
		PAID_CURRENCY: 'Paid currency',
		RECEIVED_AMOUNT: 'Received amount',
		RECEIVED_CURRENCY: 'Received currency',
		EXCHANGE_RATE: 'Exchange rate',
		MANUAL_CONVERSION: 'Manual conversion',
	},
	COMPANY_FISCAL_YEARS: {
		ID: 'id',
		START_DATE: 'Start date',
		END_DATE: 'End date',
	},
	CONTINENTS: {
		ID: 'id',
		NAME: 'Name',
		ABBRIVIATIONS: 'Abbriviations',
		SORT_ORDER: 'Sort order',
	},
	COMPANY_TO_WORKPLACE_RELATIONS: {
		ID: 'id',
		WORKPLACE: 'Workplace',
		COMPANY: 'Company',
		START_DATE: 'Start date',
		END_DATE: 'End date',
	},
	COMPANY_VAT_NUMBERS: {
		VAT_NUMBER: 'VAT number',
		START_DATE: 'Start date',
		END_DATE: 'End date',
	},
	STORES: {
		ID: 'id',
		NAME: 'Name',
		TYPE: 'Type',
		MARKET: 'Market',
		OPENED: 'Opened',
		CLOSED: 'Closed',
		COMPANY: 'Company',
		COMPANY_TO_WORKPLACE_RELATIONS: 'Company',
	},
	TAGS: {
		ID: 'id',
		TAG: 'Tag',
		FROM: 'From',
		TO: 'To',
		TAGS: 'Tags',
		TYPE: 'Type',
		NAME: 'Name',
		EMPLOYMENT: 'Employment',
	},

	MAILINGLIST_RECIPIENTS: {
		NAME: 'Name',
		EMAIL: 'Email',
	},

	NATIONALITIES: {
		NAME: 'Name',
		MARKET: 'Market',
		SORT_ORDER: 'Sort order',
	},

	MAILINGLIST_RECIPIENTS_SHIFTPLANNER: {
		NAME: 'Name',
		EMAIL: 'Email',
	},

	WORKPLACE_OPENING_HOURS: {
		WEEKDAY: 'Weekday',
		CLOSED: 'Closed',
		TIME_FROM: 'From (time)',
		TIME_TO: 'To (time)',
		ACTIVE_FROM: 'From (active)',
		ACTIVE_TO: 'To (active)',
		PRIORITY: 'Priority',
	},
	WORKPLACE_ADDRESSES: {
		COUNTRY: 'Country',
		ADDRESS: 'Address',
		CITY: 'City',
		ZIP: 'Zip code',
		LATITUDE: 'Latitude',
		LONGITUDE: 'Longitude',
	},
	WORKPLACE_SIZES: {
		TYPE: 'Type',
		SIZE: 'M²',
		RETAIL: 'Retail',
	},
	WORKPLACES: {
		ID: 'Id',
		NAME: 'Name',
		TYPE: 'Type',
		MARKET: 'Market',
		TIME_ZONE: 'Time zone',
		OPENED: 'Opened',
		TERRITORY: 'Territory',
		WORKPLACE_SALARY_ACCOUNT: 'Workplace salary account',
		CLOSED: 'Closed',
		COMPANY: 'Company',
		COMPANY_TO_WORKPLACE_RELATIONS: 'Company',
		FRANCHISE_STORE: 'Franchise',
		GRID: 'Grid',
		REGION: 'Region',
		STORE_AREA_TYPE: 'Area type',
		STORE_CONTINENT_TAG: 'Continent tag',
		STORE_FORECAST_TAG: 'Forecast tag',
		STORE_LOCATION_TYPE: 'Location type',
		STORE_REGION: 'Store region',
		STORE_FIRST_YEAR_FORECAST: 'First year forecast',
		STATE: 'State',
		STORE_SIZE: 'M²',
		AUDIT_NUMBER: 'Audit number',
	},
	REGIONS: {
		ID: 'Id',
		NAME: 'Name',
		MARKET: 'Market',
	},
	STORE_AREA_TYPES: {
		NAME: 'Name',
		SORT_ORDER: 'Sort order',
	},
	STORE_CONTINENT_TAGS: {
		NAME: 'Name',
	},
	STORE_FORECAST_TAGS: {
		NAME: 'Name',
	},
	STORE_LOCATION_TYPES: {
		NAME: 'Name',
		SORT_ORDER: 'Sort order',
	},
	STORE_REGIONS: {
		NAME: 'Name',
	},
	STORE_FRANCHISE_FEES: {
		FEE: 'Fee',
		FROM: 'From',
		TO: 'To',
	},
	TAX_CARDS: {
		NAME: 'Name',
		MARKET: 'Market',
	},
	TAX_TABLES: {
		NAME: 'Name',
		MARKET: 'Market',
	},
	MARKET_RESPONSIBILITIES: {
		ROLE: 'Role',
		FROM: 'From',
		TO: 'To',
		LOCATION: 'Location',
	},
	WORKPLACE_RESPONSIBILITIES: {
		ROLE: 'Role',
		FROM: 'From',
		TO: 'To',
		LOCATION: 'Location',
	},
	WORKPLACE_ATTRIBUTES: {
		TYPE: 'Type',
		VALUE: 'value',
		NAME: 'Name',
		DESCRIPTION: 'Description',
	},
	WORKPLACE_THIRD_PARTY_REFERENCES: {
		OPEN_WEATHER_CITY_ID: 'Open weather city id',
		DELIVEROO_RESTAURANT: 'Deliveroo restaurant',
		ADYEN_MERCHANT_ACCOUNT: 'Adyen merchant account',
	},
	REGION_RESPONSIBILITIES: {
		ROLE: 'Role',
		FROM: 'From',
		TO: 'To',
		LOCATION: 'Location',
	},
};

const titles = {
	BANKS: 'Banks',
	CONTINENTS: 'Continents',
	COUNTRIES: 'Countries',
	COUNTRY_TO_CURRENCY_RELATIONS: 'Country / currency relation',
	CURRENCIES: 'Currencies',
	CURRENCY_DENOMINATIONS: 'Currency denominations',
	CURRENCY_RATES: 'Currency rates',
	MARKET_TAX_RATES: 'Tax rates',
	MARKETS: 'Markets',
	MARKET_REGIONS: 'Market regions',
	MARKET_REGIONS_MARKET: 'Market regions market',
	MAILINGLIST_RECIPIENTS: 'Mailinglist recipients',
	REGION_MANAGERS: 'Regions managers',
	COMPANIES: 'Companies',
	NATIONALITIES: 'Nationalities',
	COMPANY_ADDRESSES: 'Addresses',
	COMPANY_BANK_ACCOUNTS: 'Bank accounts',
	COMPANY_CAPITAL_TRANSACTIONS: 'Capital transactions',
	COMPANY_FISCAL_YEARS: 'Fiscal Years',
	COMPANY_TO_WORKPLACE_RELATIONS: 'Company / workplace relation',
	COMPANY_VAT_NUMBERS: 'VAT Numbers',
	STORES: 'Stores',
	TAGS: 'Tags',
	WORKPLACE_ADDRESSES: 'Addresses',
	WORKPLACE_OPENING_HOURS: 'Opening hours',
	WORKPLACE_SIZES: 'Workplace sizes',
	WORKPLACES: 'Workplaces',
	REGIONS: 'Regions',
	STORE_AREA_TYPES: 'Store area types',
	STORE_CONTINENT_TAGS: 'Store continent tags',
	STORE_FORECAST_TAGS: 'Store forecast tags',
	STORE_LOCATION_TYPES: 'Store location types',
	STORE_REGIONS: 'Store regions',
	STORE_FRANCHISE_FEES: 'Store Franchise fees',
	TAX_CARDS: 'Tax cards',
	TERRITORIES: 'Territories',
	TAX_TABLES: 'Tax labels',
	MARKET_RESPONSIBILITIES: 'Market responsibilities',
	WORKPLACE_RESPONSIBILITIES: 'Workplace responsibilities',
	WORKPLACE_ATTRIBUTES: 'Workplace attributes',
	WORKPLACE_THIRD_PARTY_REFERENCES: 'Workplace third party references',
	REGION_RESPONSIBILITIES: 'Region responsibilities',
};

module.exports = {
	headers: headers,
	titles: titles,
};
