import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'detectMobile.vanilla';

import ContentLoader from 'react-content-loader';

// Components
import { Card, InputSmart, InputSmartSelect, Button, Modal } from 'dumb';
import { AddressCreateModal } from './addressCreateModal';

// Utils
import _get from 'lodash/get';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { formatErrorMessage } from 'api/helpers';

// Phrases
import phrases from './../../juicerDetails.phrases';

// css
import './addressDetailsCard.css';

class AddressDetailsCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			key: 'loaded',
			disabled: true,
			modalIsOpen: false,
		};

		this._onAddressChange = this._onAddressChange.bind(this);
		this.handleCreateAddress = this.handleCreateAddress.bind(this);
		this.toggleInputs = this.toggleInputs.bind(this);
		this.renderAddressZip = this.renderAddressZip.bind(this);
		this._getAddressInput = this._getAddressInput.bind(this);
		this._getZipInput = this._getZipInput.bind(this);
	}

	handleCreateAddress() {
		const { addressData, postAddressData, personData } = this.props;

		const uri = 'person_addresses';

		const country = _get(addressData, 'country.value', false);

		if (!country) {
			setFeedback('Select country', 0);
		}

		const payload = {
			address: addressData.address,
			country,
			person: personData.id,
		};

		this.setState(() => ({ creatingAddress: true }));
		return postAddressData({
			uri,
			payload,
		})
			.then(() => {
				this.setState({
					modalIsOpen: false,
					creatingAddress: false,
					key: 'address-added',
				});
				setFeedback('Address created', 1);
			})
			.catch((err) => {
				const error = formatErrorMessage(err);
				setFeedback(error, 1);

				this.setState({ modalIsOpen: false, creatingAddress: false });
			});
	}

	_onAddressChange(handle, params, val) {
		const { personData, postAddressData } = this.props;
		const payload = {
			country: _get(personData, 'person_address.country.id', null),
			address: _get(personData, 'person_address.address', null),
			city: _get(personData, 'person_address.city', null),
		};

		payload[handle] = params.value;

		const uri = `person_addresses/${personData.person_address.id}`;

		return postAddressData({
			uri,
			payload,
		});
	}

	getLoaderLines() {
		if (!isMobile())
			return (
				<>
					<rect x="0" y="0" rx="3" ry="3" width={200} height="40" />,
					<rect x="0" y="50" rx="3" ry="3" width={350} height="40" />,
					<rect x="0" y="100" rx="3" ry="3" width={350} height="40" />
				</>
			);

		return (
			<>
				<rect x="0" y="0" rx="3" ry="3" width={200} height="37" />,
				<rect x="0" y="50" rx="3" ry="3" width={350} height="37" />,
				<rect x="0" y="100" rx="3" ry="3" width={350} height="37" />
			</>
		);
	}

	/**
	 * @function toggleInputsActive
	 * @description sets the state of the component as being used or unsed
	 * @description therefor toggling all inputs in the group as enabled or disabled
	 * @param active {boolean}
	 */
	toggleInputs({ disabled }) {
		// check data type
		if (typeof disabled !== 'boolean') return;

		this.setState(() => ({ disabled }));
	}

	_renderCreateModal() {
		const { addressData, createAddressData } = this.props;

		return (
			<div className="address-details-card__overlay">
				<div className="address-details-card__overlay__inner">
					<p>{phrases.NO_ADDRESS_REGISTERED}</p>
					<Button onClick={() => this.setState({ modalIsOpen: true })}>{phrases.CREATE}</Button>

					{this.state.modalIsOpen ? (
						<Modal
							className="table-editor-modal"
							isOpen={this.state.modalIsOpen}
							type="confirmation"
							onClose={() => this.setState({ modalIsOpen: false })}
							loading={this.state.creatingAddress}
							onConfirmClick={this.handleCreateAddress}
							isSelectOpen={this.state.isSelectOpen}
						>
							<AddressCreateModal personAddress={addressData} onChange={createAddressData} />
						</Modal>
					) : null}
				</div>
			</div>
		);
	}

	_getAddressInput() {
		const { personId, personData } = this.props;

		return (
			<InputSmart
				id="Address"
				label="Address"
				customParams={(value) => ({
					id: personId,
					value,
					key: 'address',
				})}
				name="address"
				onChange={(val) => this._onAddressChange('address', val)}
				value={_get(personData, 'person_address.address', null)}
				placeholder="Enter address..."
				tabIndex={0}
				readOnly={this.state.disabled}
				toggleInputs={this.toggleInputs}
			/>
		);
	}

	_getZipInput() {
		const { personId, personData } = this.props;

		return (
			<InputSmart
				id="zipcode"
				label="Zip Code"
				customParams={(value) => ({
					id: personId,
					value,
					key: 'zip',
				})}
				name="zip"
				onChange={(val) => this._onAddressChange('zip', val)}
				value={_get(personData, 'person_address.zip', null)}
				placeholder="Enter zip code..."
				tabIndex={0}
				readOnly={this.state.disabled}
				toggleInputs={this.toggleInputs}
			/>
		);
	}

	renderAddressZip() {
		if (isMobile())
			return (
				<>
					{this._getAddressInput()}
					{this._getZipInput()}
				</>
			);
		else
			return (
				<div className="address-details-card__row">
					<div className="address-details-card__row__address">{this._getAddressInput()}</div>
					<div>{this._getZipInput()}</div>
				</div>
			);
	}

	render() {
		const { personData, loading } = this.props;

		const hasAddress = _get(personData, 'person_address.id', false);

		return (
			<div className="address-details-card">
				<Card className="juicer-details__small">
					{loading && (
						<ContentLoader height={140} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
							{this.getLoaderLines()}
						</ContentLoader>
					)}
					{!loading ? (
						<Fragment key={this.state.key}>
							<h3 className="card-name">{phrases.ADDRESS_DETAILS_CARD}</h3>
							{this.renderAddressZip()}

							<InputSmartSelect
								id="Country"
								label="Country"
								value={
									personData.person_address && {
										value: personData.person_address.country.id,
										label: personData.person_address.country.name,
									}
								}
								name="country"
								onChange={(target, val) => this._onAddressChange(target, val)}
								placeholder="[empty]"
								apiPath="/administration/countries"
								params={{
									limit: 300,
								}}
								tabIndex={0}
								toggleInputs={this.toggleInputs}
							/>

							<InputSmart
								id="city"
								label="City"
								value={_get(personData, 'person_address.city', null)}
								onChange={(val) => this._onAddressChange('city', val)}
								placeholder="Enter City..."
								readOnly={this.state.disabled}
								tabIndex={0}
								customParams={(value) => ({
									value,
								})}
								toggleInputs={this.toggleInputs}
							/>
						</Fragment>
					) : null}
					{!hasAddress && !loading ? this._renderCreateModal() : null}
				</Card>
			</div>
		);
	}
}

AddressDetailsCard.propTypes = {
	personId: PropTypes.number,
	personData: PropTypes.object,
	addressData: PropTypes.object,
	loading: PropTypes.bool,
	postAddressData: PropTypes.func,
	createAddressData: PropTypes.func,
};

export default AddressDetailsCard;
