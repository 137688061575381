import * as Sentry from '@sentry/browser';

/**
 * @function getSelectOptionsFromEnums
 * @description loops through the keys of passed enums object and returns an
 * array of options to be used inside of our CollectionSelect components
 * @returns undefined value for empty enums or enums that are not an object
 * and an array of select options if enums pass checks
 */
export default function getSelectOptionsFromEnums(enums) {
	try {
		return Object.keys(enums).map((key) => ({
			label: enums[key],
			value: enums[key],
		}));
	} catch (error) {
		console.error(error.message);
		Sentry.withScope((scope) => {
			scope.setExtra('error', error);
			Sentry.captureException(error);
			Sentry.captureMessage('Error in getSelectOptionsFromEnums util', 'error');
		});
	}
}
