import React from 'react';
import PropTypes from 'prop-types';

// components
import { Tabs } from 'dumb';
import SubTable from 'reactDataWrapper/components/subTable';
import PosConfigurationVersionCurrencies from './../posConfigurationVersionCurrencies/posConfigurationVersionCurrencies';
import PosConfigurationTileLayouts from './../posConfigurationTileLayouts/posConfigurationTileLayouts.container';

// phrases
import phrases from './subTables.phrases';

const PosConfigurationVersionSubTables = ({ row }) => {
	const tabContent = [
		{
			name: phrases.CURRENCIES,
			component: (
				<PosConfigurationVersionCurrencies
					posConfigurationVersionId={row.original.id}
				/>
			),
		},
		{
			name: phrases.TILE_LAYOUTS,
			component: <PosConfigurationTileLayouts id={row.original.id} />,
		},
	];

	return (
		<SubTable>
			<Tabs tabContent={tabContent} />
		</SubTable>
	);
};

PosConfigurationVersionSubTables.propTypes = {
	row: PropTypes.object,
};

export default PosConfigurationVersionSubTables;
