'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Text extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {
			className,
			defaultValue,
			disabled,
			focus,
			name,
			onChange,
			onKeyDown,
			placeholder,
			type
		} = this.props;

		var inputType;

		switch (type) {
		case 'mail':
			inputType = 'email';
			break;
		case 'number':
			inputType = 'number';
			break;
		case 'tel':
			inputType = 'tel';
			break;
		case 'password':
			inputType = 'password';
			break;
		default:
			inputType = 'text';
			break;
		}

		return (
			<input
				type={inputType}
				autoComplete="off"
				name={name}
				autoFocus={focus}
				onKeyDown={onKeyDown}
				onChange={onChange}
				className={className}
				placeholder={placeholder}
				disabled={disabled}
				defaultValue={defaultValue}
			/>
		);
	}
}

Text.propTypes = {
	className: PropTypes.string,
	defaultValue: PropTypes.string,
	disabled: PropTypes.bool,
	focus: PropTypes.bool,
	name: PropTypes.string,
	onChange: PropTypes.func,
	onKeyDown: PropTypes.func,
	placeholder: PropTypes.string,
	type: PropTypes.string
};

export default Text;
