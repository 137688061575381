'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input, InputCollectionSelect, Toggle } from 'dumb';
import { ModalRow } from 'dumb/modal';
import { Tip } from 'dumb/tips';
import getPosConfigurationVersionColumns from 'reactDataWrapperColumns/pos/posConfigurationVersions.columns';
import getIngredientColumns from 'reactDataWrapperColumns/product/ingredients.columns';

// phrases/ constants/ moment
import constants from 'services/constants';
import moment from 'moment';
import phrases from './../../../salesConfigurationIngredientPricings.phrases';

class IngredientPricingBatchStepForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};
	}

	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		updateBatchForm({
			[type]: e,
		});
	}

	render() {
		const { batchFormData } = this.props;

		return (
			<>
				<ModalRow>
					<InputCollectionSelect
						id="pos_configuration_version"
						label={phrases.MODAL_BATCH_STEP_POS_CONFIGURATION_VERSION}
						placeholder="Select configuration version"
						value={batchFormData.posConfigurationVersion}
						handleChange={(key, value) => this.editStoreEntry(value, 'posConfigurationVersion')}
						multi
						required
						cache
						apiPath="/pos/pos_configuration_versions"
						params={{
							limit: 50,
							filter: `:active.to=ge='${moment.utc().format(constants.shortDate)}';:pos_configuration.legacy==false`,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.pos_configuration.name,
						})}
						inputFilterFormat={(input) => `:pos_configuration.name=like='%${input}%'`}
						tableTitle={phrases.MODAL_BATCH_STEP_POS_CONFIGURATION_VERSIONS}
						tableReduxKey="ingredient-pricings-modal-batch/pos/pos_configuration_versions"
						tableColumns={getPosConfigurationVersionColumns(
							'ingredient-pricings-modal-batch/pos/pos_configuration_versions',
							600
						)}
					/>
				</ModalRow>

				<ModalRow>
					<InputCollectionSelect
						id="product_variant"
						label={phrases.MODAL_BATCH_STEP_INGREDIENT}
						placeholder="Select ingredient"
						value={batchFormData.ingredient}
						handleChange={(key, value) => this.editStoreEntry(value, 'ingredient')}
						multi
						required
						cache
						apiPath="/product/ingredients"
						params={{
							limit: 50,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						tableTitle={phrases.MODAL_BATCH_STEP_INGREDIENTS}
						tableReduxKey="ingredient-pricings-modal-batch/product/ingredients"
						tableColumns={getIngredientColumns()}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						label={phrases.MODAL_BATCH_STEP_ALTERNATIVE_ID}
						placeholder="Alternative Id"
						type="number"
						id="alternative-id"
						onChange={(e) => this.editStoreEntry(e.target.value, 'alternativeId')}
						value={batchFormData.alternativeId || ''}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						label={phrases.MODAL_BATCH_STEP_TO_STAY_PRICE}
						id="to_stay_price"
						type="number"
						placeholder="1234,56"
						onChange={(e) => this.editStoreEntry(e.target.value, 'toStayPrice')}
						value={batchFormData.toStayPrice || ''}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						label={phrases.MODAL_BATCH_STEP_TO_GO_PRICE}
						id="to_go_price"
						type="number"
						placeholder="1234,56"
						onChange={(e) => this.editStoreEntry(e.target.value, 'toGoPrice')}
						value={batchFormData.toGoPrice || ''}
					/>
				</ModalRow>

				<ModalRow>
					<Toggle
						id="availableInAppCheckbox"
						label={phrases.MODAL_BATCH_STEP_AVAILABLE_IN_APP}
						toggled={batchFormData.availableInApp || false}
						onClick={(e) => this.editStoreEntry(e, 'availableInApp')}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						label={phrases.MODAL_BATCH_STEP_DELIVERY_PRICE}
						id="delivery-price"
						placeholder="Enter delivery price..."
						onChange={(e) => this.editStoreEntry(e.target.value, 'deliveryPrice')}
						value={batchFormData.deliveryPrice || ''}
					/>
				</ModalRow>

				<ModalRow>
					<Tip content={phrases.MODAL_BATCH_STEP_TIP} />
				</ModalRow>
			</>
		);
	}
}

IngredientPricingBatchStepForm.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
};

export default IngredientPricingBatchStepForm;
