import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

/**
 * @function handleToggleOverlayHelper
 * @param {Object} objet - contains all list metadata from redux, all lists redux keys and currently opened table (overlayType)
 * @description finds out what table is open, finds the selected row and adds it to data object under a proper key -> key is based on the tables redux key
 */
export function handleToggleOverlayHelper({
	overlayType,
	posConfigurationReduxKey,
	listPosConfigurations,
	productRecommendationReduxKey,
	listProductRecommendations,
	ingredientConfigurationReduxKey,
	listIngredientConfigurations,
	posLayoutReduxKey,
	listPosTileLayout,
	appLayoutReduxKey,
	listAppTileLayout,
}) {
	let data;
	let selectedRowsInList = [];

	if (overlayType === posConfigurationReduxKey) {
		selectedRowsInList = _get(listPosConfigurations, 'ui.selectedRows', []);

		if (!_isEmpty(selectedRowsInList)) {
			data = {
				posConfiguration: {
					label: selectedRowsInList[0].name,
					value: selectedRowsInList[0],
				},
			};
		}
	}
	if (overlayType === productRecommendationReduxKey) {
		selectedRowsInList = _get(
			listProductRecommendations,
			'ui.selectedRows',
			[]
		);

		if (!_isEmpty(selectedRowsInList)) {
			data = {
				productRecommendation: {
					label: selectedRowsInList[0].name,
					value: selectedRowsInList[0],
				},
			};
		}
	}
	if (overlayType === ingredientConfigurationReduxKey) {
		selectedRowsInList = _get(
			listIngredientConfigurations,
			'ui.selectedRows',
			[]
		);

		if (!_isEmpty(selectedRowsInList)) {
			data = {
				ingredientConfiguration: {
					label: selectedRowsInList[0].name,
					value: selectedRowsInList[0],
				},
			};
		}
	}
	if (overlayType === posLayoutReduxKey) {
		selectedRowsInList = _get(listPosTileLayout, 'ui.selectedRows', []);

		if (!_isEmpty(selectedRowsInList)) {
			data = {
				posTileLayout: {
					label: selectedRowsInList[0].name,
					value: selectedRowsInList[0],
				},
			};
		}
	}
	if (overlayType === appLayoutReduxKey) {
		selectedRowsInList = _get(listAppTileLayout, 'ui.selectedRows', []);

		if (!_isEmpty(selectedRowsInList)) {
			data = {
				appTileLayout: {
					label: selectedRowsInList[0].name,
					value: selectedRowsInList[0],
				},
			};
		}
	}

	return data;
}
