'use strict';

/**
 * Metadata
 * @param  {object} title
 * @param  {object} subtitle
 * @param  {object} printTitle
 */
class Metadata {
	constructor(title, subtitle, printTitle) {
		// Set type
		this.type = 'metadata';

		/// ////////
		// Title //
		/// ////////

		this.title = title;

		/// ///////////
		// Subtitle //
		/// ///////////

		this.subtitle = subtitle;

		/// /////////////
		// PrintTitle //
		/// /////////////
		this.printTitle = printTitle;
	}

	/**
	 * @function getTitle
	 * @param {object} data
	 * @return {string}
	 */
	getTitle(data) {
		return this.title.getTemplate(data);
	}

	/**
	 * @function getSubtitle
	 * @param {object} data
	 * @return {string}
	 */
	getSubtitle(data) {
		return this.subtitle.getTemplate(data);
	}

	/**
	 * @function getPrintTitle
	 * @param {object} data
	 * @return {string}
	 */
	getPrintTitle(data) {
		return this.printTitle.getTemplate(data);
	}
}

export default Metadata;
