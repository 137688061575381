'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	setWorkplaceOperationalResponsibilities,
	updateWorkplaceOperationalResponsibilities,
	resetWorkplaceOperationalResponsibilities,
} from './store/workplaceOperationalResponsibilities.actions';

// services
import {
	fetchWorkplaceOperationalResponsibilities,
	addWorkplaceOperationalResponsibilities,
} from './workplaceOperationalResponsibilities.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { FilterProvider } from 'reactDataWrapper/utilities';
import { Button, Icon, InputCollectionSelect, SingleDatePickerInput, Tooltip } from 'dumb';
import columns from './workplaceOperationalResponsibilities.columns';

// lodash
import _get from 'lodash/get';

// utils
import { push } from 'redux-first-history';
import store from 'appState/store';
import moment from 'moment';
import { getSelectOptionsFromEnums } from 'services/utils';
import enums from './workplaceOperationalResponsibilities.enums';

// phrases/constants/enums
import phrases from './workplaceOperationalResponsibilities.phrases';
import constants from 'services/constants';
import collectionSelectEnums from 'services/enums/collectionSelect';

const reduxKey = 'workplace-details/hr/operational_responsibilities';

class WorkplaceOperationalResponsibilities extends Component {
	constructor(props) {
		super(props);

		this.state = {
			key: moment(),
			showModal: false,
			tableFilterActive: moment.utc().format(constants.shortDate),
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.renderLink = this.renderLink.bind(this);
		this.getTableDateSelector = this.getTableDateSelector.bind(this);
		this.getExtraFilters = this.getExtraFilters.bind(this);
		this.onDateFilterChange = this.onDateFilterChange.bind(this);
		this.addEntry = this.addEntry.bind(this);

		this.columns = [
			{
				Header: 'Person',
				id: 'person',
				accessor: (d) => _get(d, 'person.identity.full_name', ''),
				filterPath: `:person.identity.full_name`,
			},
			{
				Header: 'Responsibility',
				id: 'responsibility',
				accessor: 'responsibility',
				filterPath: `:responsibility`,
				Filter: ({ column }) => (
					<FilterProvider
						reduxKey={reduxKey}
						columnId={column.id}
						provide={(filterValue, persistToFilterStorage) => (
							<InputCollectionSelect
								id={column.id}
								name="responsibility-filter"
								value={filterValue}
								handleChange={(key, value) => {
									persistToFilterStorage({ handle: 'responsibility', value });
								}}
								placeholder="Select responsibility..."
								styleType={collectionSelectEnums.TYPE_IN_TABLE}
								options={getSelectOptionsFromEnums(enums)}
							/>
						)}
					/>
				),
			},
			{
				Header: 'From',
				id: 'from',
				accessor: (d) => _get(d, 'period.from', ''),
				filterPath: `:period.from`,
				filterable: false,
			},
			{
				Header: 'To',
				id: 'to',
				accessor: (d) => _get(d, 'period.to', ''),
				filterPath: `:period.to`,
				filterable: false,
			},
		];
	}

	_getSelectedDate(date, name) {
		if (date) return moment.utc(date).format(constants.shortDate);

		return name === 'from' ? moment.utc().format(constants.shortDate) : '9999-12-31';
	}

	addEntry() {
		const { workplaceId, workplaceOperationalResponsibilities } = this.props;

		const payload = {
			workplace: workplaceId,
			person: workplaceOperationalResponsibilities.person?.value?.person?.id ?? undefined,
			responsibility: workplaceOperationalResponsibilities.responsibility?.value ?? undefined,
			period: {
				from: workplaceOperationalResponsibilities.activeFrom
					? moment
							.utc(workplaceOperationalResponsibilities.activeFrom, constants.dateFormat)
							.format(constants.shortDate)
					: moment.utc().format(constants.shortDate),
				to: workplaceOperationalResponsibilities.activeTo
					? moment.utc(workplaceOperationalResponsibilities.activeTo, constants.dateFormat).format(constants.shortDate)
					: '9999-12-31',
			},
		};

		return addWorkplaceOperationalResponsibilities(payload)
			.then((res) => {
				this.setState(() => ({ key: moment() }));
				return res;
			})
			.then((err) => {
				throw err;
			});
	}

	getEditableCells() {
		const { workplaceOperationalResponsibilities } = this.props;

		return [
			{
				header: 'Person',
				value: (
					<InputCollectionSelect
						id="Person"
						placeholder="Select a person..."
						value={workplaceOperationalResponsibilities.person}
						handleChange={(key, value) => this.editStoreEntry('person', value)}
						cache
						apiPath="/hr/employments"
						params={{
							limit: 30,
							filter: `:ended=ge='${moment.utc().format(constants.shortDate)}'`,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.person.identity.full_name,
						})}
						inputFilterFormat={(input) => `:person.identity.full_name=like='%${input}%'`}
						tableColumns={columns}
						tableTitle={phrases.EMPLOYMENTS_TABLE_TITLE}
						tableReduxKey="worklplace-details-operational-responsiblity-overview/hr/employments-5"
						clearable={false}
					/>
				),
			},
			{
				header: 'Responsibility',
				value: (
					<InputCollectionSelect
						id="responsiblity"
						placeholder="Select a responsibility..."
						value={workplaceOperationalResponsibilities.responsibility}
						handleChange={(key, value) => this.editStoreEntry('responsibility', value)}
						options={getSelectOptionsFromEnums(enums)}
						clearable={false}
					/>
				),
			},
			{
				header: 'Active from',
				value: (
					<SingleDatePickerInput
						id="activeFrom"
						onChange={(event) => this.editStoreEntry('activeFrom', event)}
						selectedDate={this._getSelectedDate(workplaceOperationalResponsibilities.activeFrom, 'from')}
						noClockButton
					/>
				),
			},
			{
				header: 'Active to',
				value: (
					<SingleDatePickerInput
						id="activeTo"
						onChange={(event) => this.editStoreEntry('activeTo', event)}
						selectedDate={this._getSelectedDate(workplaceOperationalResponsibilities.activeTo, 'to')}
						noClockButton
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const { updateWorkplaceOperationalResponsibilities } = this.props;

		const value = e?.target?.value ?? e;

		const updateObject = {
			[name]: value,
		};

		updateWorkplaceOperationalResponsibilities(updateObject);
	}

	fetchData(state) {
		const { workplaceId } = this.props;

		const filter = `:workplace.id=='${workplaceId}'`;

		return fetchWorkplaceOperationalResponsibilities({ state, filter });
	}

	renderLink(d) {
		return (
			<Tooltip
				text={phrases.JUICER_DETAILS_TOOLTIP}
				placement="left"
				renderData={(ref, onMouseEnter, onMouseLeave) => (
					<Button
						type="inverted"
						shadow
						title="Rules"
						onClick={() => store.dispatch(push(`/hr/juicers/${d.original.person?.id}`))}
						size="micro"
						refProp={ref}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
					>
						<Icon name="tune" />
					</Button>
				)}
			/>
		);
	}

	getExtraFilters() {
		const { customFilter } = this.props;
		const { tableFilterActive } = this.state;

		if (!tableFilterActive && !customFilter?.responsibility) return;

		const dateFilter = tableFilterActive
			? `:period.from=le='${tableFilterActive}';:period.to=ge='${tableFilterActive}'`
			: '';
		const responsibilityFilter = customFilter?.responsibility
			? `:responsibility=='${customFilter.responsibility.value}'`
			: '';

		let filter;
		if (dateFilter) filter = dateFilter;
		if (responsibilityFilter) filter = filter ? `${filter};${responsibilityFilter}` : responsibilityFilter;

		return filter;
	}

	getTableDateSelector() {
		return (
			<SingleDatePickerInput
				id="operationalResponsibilitiesDatePicker"
				label={phrases.TABLE_FILTER_ACTIVE}
				type="single"
				appendToBody
				fixed
				onChange={this.onDateFilterChange}
				selectedDate={this.state.tableFilterActive}
				noClockButton
				clearButtonDisabled={!this.state.tableFilterActive}
				clearable
			/>
		);
	}

	onDateFilterChange(e) {
		if (!e) {
			this.setState(() => ({ tableFilterActive: '' }));
			return;
		}

		const date = e.format(constants.shortDate);

		this.setState(() => ({ tableFilterActive: date }));
	}

	render() {
		const { resetWorkplaceOperationalResponsibilities } = this.props;

		return (
			<ReactDataWrapper
				key={this.state.key}
				title={phrases.TITLE}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={5}
				reduxKey={reduxKey}
				manual
				actionsWidth={30}
				accessAreasAllowedToEdit={['Organization Admin', 'Organization View']}
				defaultSorted={[
					{
						id: 'from',
						desc: false,
					},
				]}
				createEntry={this.addEntry}
				editableCells={this.getEditableCells()}
				actions={this.renderLink}
				enableMultiSelection={false}
				enableSingleSelection
				customAreaComponents={this.getTableDateSelector()}
				extraFilters={this.getExtraFilters()}
				onModalClose={resetWorkplaceOperationalResponsibilities}
			/>
		);
	}
}

WorkplaceOperationalResponsibilities.propTypes = {
	workplaceOperationalResponsibilities: PropTypes.object,
	updateWorkplaceOperationalResponsibilities: PropTypes.func,
	resetWorkplaceOperationalResponsibilities: PropTypes.func,
	workplaceId: PropTypes.number,
	customFilter: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateWorkplaceOperationalResponsibilities,
			setWorkplaceOperationalResponsibilities,
			resetWorkplaceOperationalResponsibilities,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		workplaceOperationalResponsibilities:
			store.workplaceOperationalResponsibilities.data.workplaceOperationalResponsibilities,
		customFilter: store.filterSortColumnsData?.tables?.[reduxKey]?.custom,
	};
};

export default connectWithStore(WorkplaceOperationalResponsibilities, mapStateToProps, mapDispatchToProps);
