export const RESET_VALUE = 'TAGS/RESET_VALUE';
export const SET_VALUE = 'TAGS/SET_VALUE';
export const UPDATE_VALUE = 'TAGS/UPDATE_VALUE';

export const setValue = (payload) => ({
	type: SET_VALUE,
	payload,
});

export const updateValue = (payload) => ({
	type: UPDATE_VALUE,
	payload,
});

export const resetState = () => ({
	type: RESET_VALUE,
});
