'use strict';
import _each from 'lodash/each';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isArray from 'lodash/isArray';
import _isNull from 'lodash/isNull';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import _template from 'lodash/template';

/**
 * @function itemFormat
 * @memberOf SERVICES.__models
 * @description
 * Formats single items returned from  API call.
 * Basically just manages complex data types (such as relational data and complex data formats)
 * @param  {object} item  item to format
 */

module.exports = function (item) {
	var instance = this;

	// If already formatted just return
	if (item && item.isFormatted) {
		return item;
	}

	// Handle different header cases
	_each(instance.getState().headers, function (header) {
		// Begin setting value
		var value = item[header.name];

		// Support non-existing headers
		if (!_isUndefined(value)) {
			// Format relational data based on in-object children ressources
			if (_has(header, 'reference')) {
				// Reference
				var ref = header.reference;

				// Query
				var query = _has(ref, 'query') ? ref.query : false;

				// Query target
				var queryTarget = item[header.name];

				// New header
				var newHeader =
					ref.newName ||
					header.name +
						'__' +
						(query ? query.replace('.', '_') + '__' : '') +
						ref.key;

				// Should we handle a query?
				if (query) {
					// Check if item type is array or object
					// we assume that it is either object or array
					// is base item array or...
					if (_isArray(queryTarget)) {
						// Begin new array of items
						var newItems = [];

						// Loop through each child in item[header.name] array
						_each(item[header.name], function (child) {
							// Assign new query target
							queryTarget = _get(child, ref.query);

							// Push to new array of items
							newItems.push(queryTarget);
						});

						// Assign to new header key as array
						// Handle if ref has label (concatenated fields)
						if (_has(ref, 'label')) {
							item[newHeader] = _map(newItems, function (item) {
								if (_has(item, 'name')) {
									item.name = _template(ref.label)(item);
								}
								return item;
							});
						} else item[newHeader] = newItems;
					}

					// ... object
					else {
						// Assign new query target
						queryTarget = _get(value, ref.query, {});

						if (queryTarget === null) item[newHeader] = null;
						// Assign to new header key - use key if not array
						// Handle if ref has label (concatenated fields)
						else if (_has(ref, 'label'))
							item[newHeader] = _isArray(queryTarget)
								? queryTarget
								: _template(ref.label)(queryTarget);
						else
							item[newHeader] = _isArray(queryTarget)
								? queryTarget
								: queryTarget[ref.key];
					}
				}

				// Should we handle a time?
				else if (
					header.type === 'date' ||
					header.type === 'datetime' ||
					header.type === 'time' ||
					header.type === 'timetime'
				) {
					if (_isNull(item[header.name])) item[newHeader] = null;
					else item[newHeader] = item[header.name][header.reference.key];
				} else {
					// Assign to new header key - use key if not array
					// Handle if ref has label (concatenated fields)
					if (_isNull(queryTarget)) item[newHeader] = null;
					else if (_has(ref, 'label')) {
						if (_isArray(queryTarget)) {
							item[newHeader] = queryTarget;
						} else {
							try {
								const compiled = _template(ref.label);
								item[newHeader] = compiled(queryTarget);
							} catch (error) {
								item[newHeader] = queryTarget[ref.key];
							}
						}
					} else
						item[newHeader] = _isArray(queryTarget)
							? queryTarget
							: queryTarget[ref.key];
				}
			}

			// Format date data types
			else if (
				header.type === 'date' ||
				header.type === 'datetime' ||
				header.type === 'time' ||
				header.type === 'timetime'
			)
				item[header.name] = item[header.name];
		}
	});

	item.isFormatted = true;

	return item;
};
