'use strict';

import Report from './report';

import TableFactory from './table';
import MetadataFactory from './metadata';
import EmailDataFactory from './emailData';
import CustomComponentFactory from './customComponent';

import Data from './report.data';

class ReportFactory {

	/**
	 * @function data
	 * @memberOf ReportFactory
	 * @description Wrapper for getting data from object
	 * @param {object} data
	 * @param {string} path
	 * @param {*} defaultVale
	 */
	data(data, path, defaultValue) {
		return Data(data, path, defaultValue);
	}

	/**
	 * @function report
	 * @memberOf ReportFactory
	 * @param {string} name
	 * @param {object} dataProvider
	 * @param {object} hotbar
	 */
	report(name, dataProvider, hotbar) {
		return new Report(name, dataProvider, hotbar);
	}

	/**
	 * @function metadata
	 * @memberOf ReportFactory
	 */
	metadata() {
		return new MetadataFactory();
	}

	/**
	 * @function emailData
	 * @memberOf ReportFactory
	 */
	emailData() {
		return new EmailDataFactory();
	}

	/**
	 * @function table
	 * @memberOf ReportFactory
	 */
	table() {
		return new TableFactory();
	}

	/**
	 * @function customComponent
	 * @memberOf ReportFactory
	 */
	customComponent() {
		return new CustomComponentFactory();
	}

}

export default new ReportFactory();