'use strict';

import { get, post } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service';

// phrases
import phrases from './../../../../juicerDetails.phrases';

/**
 * @function fetchUsers
 * @description Fetch all users
 * @param {Object} Object with limit, offset, filter and sort
 * @param {String} initialFilter
 * @returns Promise
 */
export function fetchUsers({ limit, offset, filter, sort }, initialFilter) {
	if (filter) {
		filter = `${initialFilter};${filter}`;
	} else {
		filter = initialFilter;
	}

	// Set params and filter
	const params = {
		limit,
		sort,
		offset,
		filter,
	};

	return get('/authentication/users', params).catch((err) => {
		const error = formatErrorMessage(err);
		setFeedback(error, 0);
	});
}

/**
 * @function editUser
 * @description Edit user
 * @param {number} vacationId with id
 * @param {Object} payload
 * @returns Promise
 */
export function editUser(userId, payload) {
	const userEndpoint = `/authentication/users/${userId}`;

	return post(userEndpoint, payload)
		.then((res) => {
			setFeedback('User edited!', 1);
			return res;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
			throw err;
		});
}

/**
 * @function createUser
 * @description Edit user
 * @param {number} personId with id
 * @param {Object} id with id
 * @returns Promise
 */
export function createUser(payload) {
	return post(`/authentication/users`, payload)
		.then((res) => {
			setFeedback('User created!', 1);
			return res;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
		});
}

/**
 * @function reset2Fa
 * @description resets multi factor authentication for a user
 */
export function reset2Fa(payload) {
	return post(
		`/authentication/users/${payload.id}/multi_factor_authentication`,
		payload
	)
		.then(() => {
			setFeedback(phrases.TWO_FACTOR_RESET, 1);
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
