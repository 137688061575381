'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import { setUser, updateUser, resetUser } from './store/users.actions';

// components
import { Input, Toggle, Tooltip, Button, ButtonLoader, Icon, InputCollectionSelect } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';
import { FilterProvider, DateFilterSelector } from 'reactDataWrapper/utilities';

// services
import { fetchUsers, deleteUsers, addUser, editUser, editUsers, sendActivationCode, reset2Fa } from './users.service';

// utils
import { getEditedValues } from 'services/utils';
import { getActiveEmployments } from './utils';

// phrases/enums/constants
import phrases from './users.phrases';
import collectionSelectEnums from 'services/enums/collectionSelect';
import constants from 'services/constants';
import moment from 'moment';

// lodash
import _get from 'lodash/get';

const reduxKey = 'userManagement-/authentication/users';

class Users extends Component {
	constructor(props) {
		super(props);

		this.fetchData = this.fetchData.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.getEditableCellsEdit = this.getEditableCellsEdit.bind(this);
		this.toggleShowActiveEmploymentsFilter = this.toggleShowActiveEmploymentsFilter.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this._renderLink = this._renderLink.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.reset2Fa = this.reset2Fa.bind(this);

		this.today = moment.utc().format(constants.shortDate);
		this.personsOverlayReduxKey = 'users-table/hr/persons';

		this.state = {
			showActiveEmployments: true,
			sendingActivationCodeEmail: null,
			reset2Fa: null,
		};

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: 'name',
				filterPath: ':name',
			},
			{
				Header: 'User name',
				id: 'userName',
				accessor: 'username',
				filterPath: ':username',
			},
			{
				Header: 'E-mail',
				id: 'email',
				accessor: 'email',
				filterPath: ':email',
			},
			{
				Header: 'Person',
				id: 'person',
				accessor: (d) => _get(d, 'person.identity.full_name', ''),
				filterPath: ':person.identity.full_name',
			},
			{
				Header: 'Market',
				id: 'market',
				accessor: (d) => _get(d, 'person.identity.market.name', ''),
				filterPath: ':person.identity.market.name',
				Filter: ({ column }) => (
					<FilterProvider
						reduxKey={reduxKey}
						columnId={column.id}
						provide={(filterValue, persistToFilterStorage) => (
							<InputCollectionSelect
								id={column.id}
								name="market"
								value={filterValue}
								handleChange={(key, value) => {
									persistToFilterStorage({ handle: key, value });
								}}
								styleType={collectionSelectEnums.TYPE_IN_TABLE}
								apiPath="/administration/markets"
								placeholder={phrases.MARKET_SELECT_PLACEHOLDER}
								params={{
									limit: 30,
								}}
								optionFormat={(entry) => ({
									value: entry.id,
									label: entry.name,
								})}
								inputFilterFormat={(value) => `:name=like='%${value}%'`}
							/>
						)}
					/>
				),
			},
			{
				Header: 'Active Moneyball position',
				id: 'moneyballPosition',
				accessor: (d) => _get(d, 'person.employments', ''),
				width: 160,
				Cell: (d) => this._getElement(d.value, 'current_moneyball_sub_position.moneyball_position.name'),
				filterPath: `:person.employments.current_moneyball_sub_position.moneyball_position.name`,
			},
			{
				Header: 'Active Moneyball sub position',
				id: 'moneyballSubPosition',
				width: 160,
				accessor: (d) => _get(d, 'person.employments', ''),
				filterPath: `:person.employments.current_moneyball_sub_position.name`,
				Cell: (d) => this._getElement(d.value, 'current_moneyball_sub_position.name'),
			},
			{
				Header: 'started',
				id: 'employmentStarted',
				accessor: (d) => _get(d, 'person.employments', ''),
				Cell: (d) => this._getElement(d.value, 'started'),
				filterPath: `:person.employments.started`,
				filterable: false,
			},
			{
				Header: 'Workplace',
				id: 'workplace',
				accessor: (d) => _get(d, 'person.employments', ''),
				filterPath: `:person.employments.workplace.name`,
				Cell: (d) => this._getElement(d.value, 'workplace.name'),
				Filter: ({ column }) => (
					<FilterProvider
						reduxKey={reduxKey}
						columnId={column.id}
						provide={(filterValue, persistToFilterStorage) => (
							<InputCollectionSelect
								id={column.id}
								name="workplace"
								value={filterValue}
								handleChange={(key, value) => {
									persistToFilterStorage({ handle: key, value });
								}}
								styleType={collectionSelectEnums.TYPE_IN_TABLE}
								apiPath="/administration/workplaces"
								placeholder={phrases.WORKPLACE_SELECT_PLACEHOLDER}
								params={{
									limit: 30,
								}}
								optionFormat={(entry) => ({
									value: entry.id,
									label: entry.name,
								})}
								inputFilterFormat={(value) => `:name=like='%${value}%'`}
							/>
						)}
					/>
				),
			},
			{
				Header: 'Active',
				id: 'active',
				accessor: 'active',
				filterPath: ':active',
				filterable: false,
				width: 50,
				Cell: (d) => <Input id="active" checked={d.value} disabled type="checkbox" />,
			},
			{
				Header: 'Beta',
				id: 'beta',
				accessor: 'is_stage_user',
				filterPath: ':is_stage_user',
				filterable: false,
				width: 50,
				Cell: (d) => <Input id="beta" checked={d.value} disabled type="checkbox" />,
			},
			{
				Header: '2FA',
				id: '2fa',
				accessor: 'two_factor_authentication_required',
				filterPath: ':two_factor_authentication_required',
				filterable: false,
				width: 50,
				Cell: (d) => <Input id="2fa" checked={d.value} disabled type="checkbox" />,
			},
		];
	}

	_getElement(row, path) {
		if (!row) return <span />;

		const activeEmployments = getActiveEmployments({
			row,
			today: this.today,
		});

		return (
			<>
				{activeEmployments.map((activeEmployment, index) => {
					return (
						<div style={{ marginBottom: '5px' }} key={index}>
							{_get(activeEmployment, path, '')}
						</div>
					);
				})}
			</>
		);
	}

	renderShowActiveEmploymentsButton() {
		return (
			<Button
				id="showActiveEmploymentsButton"
				size="tiny"
				onClick={this.toggleShowActiveEmploymentsFilter}
				type={this.state.showActiveEmployments ? '' : 'inverted'}
			>
				{phrases.SHOW_ACTIVE_EMPLOYMENTS_ONLY}
			</Button>
		);
	}

	toggleShowActiveEmploymentsFilter() {
		this.setState((prevState) => ({
			showActiveEmployments: !prevState.showActiveEmployments,
		}));
	}

	addEntry() {
		const { user } = this.props;

		const payload = {
			name: user.name,
			email: user.email,
			username: user.username,
			person: user.person?.value?.id,
			active: user.active,
			is_stage_user: !!user.isStageUser,
			two_factor_authentication_required: !!user.twoFactorAuthenticationRequired,
		};

		return addUser(payload);
	}

	editEntry() {
		const { user, initialUser } = this.props;

		const editedValues = getEditedValues({
			newData: user,
			oldData: initialUser,
		});

		const payload = {
			id: user.id,
			name: editedValues.name ?? undefined,
			username: editedValues.username ?? undefined,
			email: editedValues.email ?? undefined,
			active: editedValues.active ?? undefined,
			is_stage_user: editedValues.isStageUser ?? undefined,
			two_factor_authentication_required: editedValues.twoFactorAuthenticationRequired ?? undefined,
		};

		return editUser(payload);
	}

	editMultiple(selectedRows) {
		const { user } = this.props;

		const payload = {
			active: user.active ?? user.active,
			is_stage_user: user.isStageUser ?? user.isStageUser,
			two_factor_authentication_required: user.twoFactorAuthenticationRequired ?? user.twoFactorAuthenticationRequired,
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editUsers({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteUsers(id);
	}

	setInitialEditValues(data) {
		const { setUser } = this.props;

		const payload = {
			id: data.id,
			name: data.name,
			username: data.username,
			email: data.email,
			person: data.person?.full_name,
			active: data.active,
			isStageUser: data.is_stage_user,
			twoFactorAuthenticationRequired: data.two_factor_authentication_required,
			employments: data.person?.employments ?? null,
			singleEdit: true,
		};

		setUser(payload);
	}

	getEditableCells() {
		const { user } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={user.name}
						onChange={(e) => this.editStoreEntry('name', e.target.value)}
					/>
				),
			},
			{
				header: 'Username',
				value: (
					<Input
						id="username"
						placeholder="Enter username..."
						value={user.username}
						onChange={(e) => this.editStoreEntry('username', e.target.value)}
					/>
				),
			},
			{
				header: 'Email',
				value: (
					<Input
						id="email"
						placeholder="Enter email..."
						value={user.email}
						onChange={(e) => this.editStoreEntry('email', e.target.value)}
					/>
				),
			},
			{
				header: 'Person',
				value: (
					<InputCollectionSelect
						id="person"
						placeholder="Select a person..."
						apiPath="/hr/persons"
						value={user.person}
						clearable={false}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.full_name,
						})}
						handleChange={(key, value) => this.editStoreEntry('person', value)}
						params={{ limit: 30 }}
						inputFilterFormat={(input) => `:full_name=like='%${input}%'`}
						tableReduxKey={this.personsOverlayReduxKey}
						tableTitle={phrases.PERSONS_TABLE_TITLE}
						tableColumns={[
							{
								Header: 'Name',
								id: 'name',
								accessor: 'full_name',
								filterPath: ':full_name',
							},
							{
								Header: 'Email',
								id: 'email',
								accessor: (d) => _get(d, 'person_email_address.email_address', null),

								Cell: (val) => <a href={`mailto:${val.value}`}>{val.value}</a>,
								filterPath: ':person_email_address.email_address',
							},
							{
								Header: 'Phone Number',
								id: 'phoneNumber',
								accessor: (d) => _get(d, 'person_phone_number.phone_number', null),
								width: 150,
								Cell: (val) => <span>{val.value}</span>,
								filterPath: ':person_phone_number.phone_number',
							},
							{
								Header: 'Birthday',
								id: 'bday',
								accessor: 'birth_date',
								filterPath: ':birth_date',
								Filter: ({ column }) => (
									<DateFilterSelector
										reduxKey={this.personsOverlayReduxKey}
										columnId={column.id}
										dateIdentifier="from"
										zIndex={600}
									/>
								),
							},
							{
								Header: 'Gender',
								width: 60,
								id: 'gender',
								accessor: 'gender',
								filterPath: ':gender',
							},
							{
								Header: 'Active',
								width: 60,
								id: 'active',
								accessor: 'active',
								Cell: (d) => <Input id="active-checkbox" type="checkbox" checked={d.value} disabled />,
								filterPath: ':active',
								filterable: false,
							},
							{
								Header: 'Nationality',
								width: 100,
								id: 'nationality',
								accessor: (d) => _get(d, 'nationality.name', null),
								filterPath: ':nationality.name',
							},
						]}
					/>
				),
			},
			{
				header: 'Active',
				value: <Toggle id="active" toggled={user.active} onClick={(e) => this.editStoreEntry('active', e)} />,
			},
			{
				header: 'Beta',
				value: <Toggle id="beta" toggled={user.isStageUser} onClick={(e) => this.editStoreEntry('isStageUser', e)} />,
			},
			{
				header: '2FA',
				value: (
					<Toggle
						id="2fa"
						toggled={user.twoFactorAuthenticationRequired}
						onClick={(e) => this.editStoreEntry('twoFactorAuthenticationRequired', e)}
					/>
				),
			},
		];
	}

	getEditableCellsEdit() {
		const { user } = this.props;

		return [
			...(user.singleEdit
				? [
						{
							header: 'Name',
							value: (
								<Input
									id="name"
									placement="Enter name..."
									value={user.name}
									onChange={(e) => this.editStoreEntry('name', e.target.value)}
								/>
							),
						},
						{
							header: 'Username',
							value: (
								<Input
									id="username"
									placement="Enter username..."
									value={user.username}
									onChange={(e) => this.editStoreEntry('username', e.target.value)}
								/>
							),
						},
						{
							header: 'Email',
							value: (
								<Input
									id="email"
									placement="Enter email..."
									value={user.email}
									onChange={(e) => this.editStoreEntry('email', e.target.value)}
								/>
							),
						},
						{
							header: 'Person',
							value: <span>{user.person}</span>,
						},
						{
							header: 'Moneyball position',
							value: this._getElement(user.employments, 'current_moneyball_sub_position.moneyball_position.name'),
						},
						{
							header: 'Moneyball sub position',
							value: this._getElement(user.employments, 'current_moneyball_sub_position.name'),
						},
						{
							header: 'Started',
							value: this._getElement(user.employments, 'started'),
						},
						{
							header: 'Workplace',
							value: this._getElement(user.employments, 'workplace.name'),
						},
				  ]
				: []),
			{
				header: 'Active',
				value: <Toggle id="active" toggled={user.active} onClick={(e) => this.editStoreEntry('active', e)} />,
			},
			{
				header: 'Beta',
				value: <Toggle id="beta" toggled={user.isStageUser} onClick={(e) => this.editStoreEntry('isStageUser', e)} />,
			},
			{
				header: '2FA',
				value: (
					<Toggle
						id="2fa"
						toggled={user.twoFactorAuthenticationRequired}
						onClick={(e) => this.editStoreEntry('twoFactorAuthenticationRequired', e)}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const { updateUser } = this.props;

		const payload = {
			[name]: e,
		};

		updateUser(payload);
	}

	fetchData(state) {
		return fetchUsers(state);
	}

	sendActivationCodeWrapper(email) {
		if (!email) return;

		const payload = {
			email,
		};

		this.setState(() => ({ sendingActivationCodeEmail: email }));

		sendActivationCode(payload).then(() => this.setState(() => ({ sendingActivationCodeEmail: null })));
	}

	reset2Fa(id) {
		this.setState(() => ({
			reseting2Fa: id,
		}));

		const payload = {
			id,
			reset: true,
		};

		reset2Fa(payload).finally(() => this.setState(() => ({ reseting2Fa: null })));
	}

	_renderLink(e) {
		const email = e.original?.email ?? '';
		const id = e.original?.id ?? null;

		const sendingActivationCode = this.state.sendingActivationCodeEmail === email;
		const reseting2Fa = this.state.reseting2Fa === id;

		return (
			<>
				<Tooltip
					text={phrases.SEND_ACTIVATION_KEY_TOOLTIP}
					placement="left"
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							id="sendActivationKey"
							shadow
							type="inverted"
							size="micro"
							onClick={() => {
								this.sendActivationCodeWrapper(email);
							}}
							disabled={sendingActivationCode}
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
						>
							{sendingActivationCode ? <ButtonLoader loading /> : <Icon name="vpn_key" />}
						</Button>
					)}
				/>
				<Tooltip
					text={phrases.RESET_2FA}
					placement="left"
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							id="reset-2fa"
							shadow
							type="inverted"
							size="micro"
							onClick={() => {
								this.reset2Fa(id);
							}}
							disabled={reseting2Fa}
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
						>
							{reseting2Fa ? <ButtonLoader loading /> : <Icon name="replay" />}
						</Button>
					)}
				/>
			</>
		);
	}

	_getExtraFilters(customFilterColumns, showActiveEmployments) {
		let filter;
		if (customFilterColumns.custom?.market) filter = `:person.identity.market.id=='${customFilterColumns.custom.market.value}'`;
		if (customFilterColumns.custom?.workplace)
			filter = filter
				? `${filter};:person.employments.workplace.id=='${customFilterColumns.custom.workplace.value}'`
				: `:person.employments.workplace.id=='${customFilterColumns.custom.workplace.value}'`;

		if (showActiveEmployments) {
			const preFilter = `:person.employments.started=le='${this.today}';:person.employments.ended=ge='${this.today}';:person.employments.current_employment_position.id!=null`;
			filter = filter ? `${filter};${preFilter}` : preFilter;
		}

		return filter;
	}

	render() {
		const { customFilterColumns } = this.props;

		return (
			<ReactDataWrapper
				title={phrases.TABLE_TITLE}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={50}
				reduxKey={reduxKey}
				accessAreasAllowedToEdit={['User Admin']}
				manual
				editableCells={this.getEditableCells()}
				editableCellsEdit={this.getEditableCellsEdit()}
				createEntry={this.addEntry}
				editEntry={this.editEntry}
				deleteEntry={this.deleteEntry}
				editMultiple={this.editMultiple}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetUser}
				actions={this._renderLink}
				actionsWidth={60}
				customAreaComponents={this.renderShowActiveEmploymentsButton()}
				extraFilters={this._getExtraFilters(customFilterColumns, this.state.showActiveEmployments)}
			/>
		);
	}
}

Users.propTypes = {
	setUser: PropTypes.func,
	updateUser: PropTypes.func,
	user: PropTypes.object,
	initialUser: PropTypes.object,
	customFilterColumns: PropTypes.object,
	resetUser: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setUser,
			updateUser,
			resetUser,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		user: store.users.data.user,
		initialUser: store.users.data.initialUser,
		batchList: store.users.data.batchList,
		customFilterColumns: store.filterSortColumnsData?.tables[reduxKey] ?? {},
	};
};

export default connectWithStore(Users, mapStateToProps, mapDispatchToProps);
