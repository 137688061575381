import _uniq from 'lodash/uniq';
import _isEmpty from 'lodash/isEmpty';

/**
 * @function getFilter
 * @param {Object} mappedNutritionValues - mapped values with market, ingredient and nutrition category ID's
 * @description gets a filter that will return us any already existing entries
 * so we can cross them out when batch adding to avoid unique constrain violations on the api
 * @returns {String}
 */
export function getFilter({ mappedNutritionValues }) {
	// get ids in an array
	// markets can be null, if null make sure id's array is empty
	const marketIds = mappedNutritionValues
		.map((entry) => entry.market?.value?.id ?? null)
		.filter((x) => x);
	const ingredientIds = mappedNutritionValues.map(
		(entry) => entry.ingredient?.value?.id ?? ''
	);
	const nutritionCategoryIds = mappedNutritionValues.map(
		(entry) => entry.nutritionCategory?.value?.id ?? ''
	);

	// get rid of duplicates
	const marketIdsClean = _uniq(marketIds);
	const ingredientIdsClean = _uniq(ingredientIds);
	const nutritionCategoryIdsClean = _uniq(nutritionCategoryIds);

	// group ids
	const marketGroup = marketIdsClean.reduce((acc, currentValue, index) => {
		return index === marketIdsClean.length - 1
			? `${acc}'${currentValue}']`
			: `${acc}'${currentValue}',`;
	}, '[');

	const ingredientGroup = ingredientIdsClean.reduce(
		(acc, currentValue, index) => {
			return index === ingredientIdsClean.length - 1
				? `${acc}'${currentValue}']`
				: `${acc}'${currentValue}',`;
		},
		'['
	);
	const nutritionCategoryGroup = nutritionCategoryIdsClean.reduce(
		(acc, currentValue, index) => {
			return index === nutritionCategoryIdsClean.length - 1
				? `${acc}'${currentValue}']`
				: `${acc}'${currentValue}',`;
		},
		'['
	);

	let filter = `:ingredient.id=IN=${ingredientGroup};:nutrition_category.id=IN=${nutritionCategoryGroup}`;
	if (!_isEmpty(marketIdsClean))
		filter = `${filter};:market.id=IN=${marketGroup}`;

	return filter;

	// return `:market.id=IN=${marketGroup};:ingredient.id=IN=${ingredientGroup};:nutrition_category.id=IN=${nutritionCategoryGroup}`;
}

/**
 * @function markValuesWithCanAdd
 * @param {Object} { existingValues, newValues } - already added values and the ones we want to add
 * @description checks if the values have already been added. If so, it marks them with canAdd: false, otherwise it's canAdd: true
 * @returns {Array}
 */
export function markValuesWithCanAdd({ existingValues, newValues }) {
	return newValues.map((entry) => {
		const marketId = entry.market?.value?.id ?? null;
		const ingredientId = entry.ingredient?.value?.id ?? null;
		const nutritionCategoryId = entry.nutritionCategory?.value?.id ?? null;

		const valueExists = existingValues.find((entry) => {
			if (
				(entry.market?.id ?? null) === marketId &&
				entry.ingredient.id === ingredientId &&
				entry.nutrition_category.id === nutritionCategoryId
			)
				return true;
		});

		return {
			...entry,
			canAdd: !valueExists,
		};
	});
}
