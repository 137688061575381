import React, { Component } from 'react';
import PropTypes from 'prop-types';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
// actions
import {
	// regular stuff
	showModal,
	setData,
	resetState,
	updateData,
} from './store/sendDocuSignDocument.actions';

// components
import { ModalStepper } from 'dumb';
import SendDocuSignDocumentType from './components/sendDocuSignDocumentType';
import SendDocuSignDocumentEditValues from './components/sendDocuSignDocumentEditValues';

// servies
import {
	fetchContract,
	fetchAppendix,
	createEnvelope,
} from './sendDocuSignDocument.service';

// phrases/ enums
import phrases from './sendDocuSignDocument.phrases';
import enums from './sendDocuSignDocument.enums';

// lodash
import _isEmpty from 'lodash/isEmpty';

// styles
import './sendDocuSignDocument.css';

class SendDocuSignDocument extends Component {
	constructor(props) {
		super(props);

		this.state = { loading: false };
		this._goToStep = this._goToStep.bind(this);
		this.setType = this.setType.bind(this);
		this._handleModalClose = this._handleModalClose.bind(this);
		this._getIsStepValid = this._getIsStepValid.bind(this);
		this._sendContract = this._sendContract.bind(this);
		this.editEnvelope = this.editEnvelope.bind(this);

		this.modalRef = React.createRef();
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_handleModalClose() {
		const { showModal, resetState } = this.props;

		resetState();
		showModal(false);
	}

	setType(value) {
		const { setData, data } = this.props;

		this.setState(() => ({ loading: true }));

		const payload = {
			employmentPositionId: data.employmentPositionId,
		};

		const functionToExecute =
			value === enums.SEND_APPENDIX
				? () => fetchAppendix(payload)
				: () => fetchContract(payload);

		functionToExecute()
			.then((res) => {
				const payload = {
					type: value,
					envelopes: res,
				};

				setData(payload);

				this._goToStep(2);
			})
			.catch((err) => console.error(err.message))
			.finally(() => this.setState(() => ({ loading: false })));
	}

	_getIsStepValid(envelope) {
		if (!envelope) return false;

		if (_isEmpty(envelope.fields)) return true;

		// check that every field has a final value set
		const everyFieldHasFinalValueSet = envelope.fields.every(
			(entry) => entry.final
		);

		return everyFieldHasFinalValueSet;
	}

	_sendContract() {
		const { data } = this.props;

		this.setState(() => ({ loading: true }));

		const payload = {
			recipients: data.envelopes.map((entry) => ({
				// conditionally add signing_group_id if it exists
				...(entry.template_role?.signing_group_id && {
					signing_group_id: entry.template_role.signing_group_id,
				}),
				email: entry.email,
				template_role: entry.template_role.id,
				name: entry.name,
				fields: entry.fields.map((entry) => ({
					template_document_field: entry.template_document_field.id,
					value: entry.final,
				})),
			})),
		};

		createEnvelope({
			employmentPositionId: data.employmentPositionId,
			type: data.type,
			payload,
		})
			.then(() => {
				this.setState(() => ({ loading: false }));
				this._handleModalClose();
			})
			.catch((err) => {
				console.error(err.message);
				this.setState(() => ({ loading: false }));
			});
	}

	editEnvelope(envelope) {
		const { updateData, data } = this.props;

		// update envelope inside our envelope
		const updatedData = {
			envelopes: data.envelopes.map((entry) => {
				if (entry.template_role.name === envelope.template_role.name)
					return envelope;

				return entry;
			}),
		};

		// merge the newly created envelope object with the rest
		updateData(updatedData);
	}

	render() {
		const { modalVisible, data } = this.props;

		const employeeEnvelope = data?.envelopes?.find(
			(entry) => entry.template_role.type === enums.ROLES.EMPLOYEE
		);
		const managerEnvelope = data?.envelopes?.find(
			(entry) => entry.template_role.type === enums.ROLES.MANAGER
		);

		const steps = [
			{
				component: <SendDocuSignDocumentType setType={this.setType} />,
				title: phrases.TYPE_TITLE,
				loading: this.state.loading,
				noScroll: false,
				customFooter: <></>,
			},
			{
				component: (
					<SendDocuSignDocumentEditValues
						envelope={employeeEnvelope}
						editEnvelope={this.editEnvelope}
						asEmployee
					/>
				),
				title: phrases.EMPLOYEE_FIELDS_TITLE,
				isValid: this._getIsStepValid(employeeEnvelope),
				onNext: () => this._goToStep(3),
				loading: this.state.loading,
				confirmButtonLabel: phrases.NEXT,
				cancelButtonLabel: phrases.BACK,
				noScroll: false,
				allowOverflow: true,
				onBack: () => this._goToStep(1),
			},
			{
				component: (
					<SendDocuSignDocumentEditValues
						envelope={managerEnvelope}
						editEnvelope={this.editEnvelope}
						asManager
					/>
				),
				title: phrases.MANAGER_FIELDS_TITLE,
				isValid: this._getIsStepValid(managerEnvelope),
				onNext: this._sendContract,
				loading: this.state.loading,
				confirmButtonLabel: phrases.SEND_CONTRACT,
				cancelButtonLabel: phrases.BACK,
				noScroll: false,
				allowOverflow: true,
				onBack: () => this._goToStep(2),
			},
		];

		return modalVisible ? (
			<ModalStepper
				ref={this.modalRef}
				isOpen={modalVisible}
				steps={steps}
				showStep
				onClose={this._handleModalClose}
				modalTip={data.type}
			/>
		) : null;
	}
}

SendDocuSignDocument.propTypes = {
	resetState: PropTypes.func,
	updateData: PropTypes.func,
	showModal: PropTypes.func,
	data: PropTypes.object,
	setData: PropTypes.func,
	modalVisible: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setData,
			showModal,
			resetState,
			updateData,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		data: store.sendDocuSignDocument.data,
		modalVisible: store.sendDocuSignDocument.modalVisible,
	};
};

export default connectWithStore(
	SendDocuSignDocument,
	mapStateToProps,
	mapDispatchToProps
);
