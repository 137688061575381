export const RESET_PRODUCT_BUNDLE = 'SALES_CONFIGURATION_PRODUCT_BUNDLES/RESET_PRODUCT_BUNDLE';
export const SET_PRODUCT_BUNDLE = 'SALES_CONFIGURATION_PRODUCT_BUNDLES/SET_PRODUCT_BUNDLE';
export const UPDATE_PRODUCT_BUNDLE =
	'SALES_CONFIGURATION_PRODUCT_BUNDLES/UPDATE_PRODUCT_BUNDLE';

export const UPDATE_BATCH_FORM = 'SALES_CONFIGURATION_PRODUCT_BUNDLES/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST = 'SALES_CONFIGURATION_PRODUCT_BUNDLES/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM = 'SALES_CONFIGURATION_PRODUCT_BUNDLES/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_PRODUCT_BUNDLES/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'SALES_CONFIGURATION_PRODUCT_BUNDLES/ADD_BATCH_LIST_ITEM_ROW';

/**
 * @param {object} payload
 */
export function setProductBundle(payload) {
	return {
		type: SET_PRODUCT_BUNDLE,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateProductBundle(payload) {
	return {
		type: UPDATE_PRODUCT_BUNDLE,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetProductBundle() {
	return {
		type: RESET_PRODUCT_BUNDLE
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW
	};
}
