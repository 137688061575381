'use strict';

import cx from 'classnames';
import _each from 'lodash/each';
import _every from 'lodash/every';
import _has from 'lodash/has';
import _map from 'lodash/map';
import _some from 'lodash/some';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DataInput from 'reusableComponents/dataInput/dataInput.component';
import Cell from 'reusableComponents/list/cell.component';

class AddRow extends Component {
	constructor(props) {
		super(props);

		this.state = {
			submitted: false,
		};

		this.updateRow = this.updateRow.bind(this);
	}

	/**
	 * @function updateRow
	 * @memberOf DIRECTIVES.jtjList.component.addRow
	 * @description Saves row
	 */
	updateRow() {
		var args = {};
		var headers = {};
		var header, name, value;

		// Set as submitted to activate validation
		this.setState({ submitted: true });

		// Check for validity
		var isValid = _every(this.refs, function (ref) {
			return ref.getValidity();
		});

		// Check for touched
		var isTouched = _some(this.refs, function (ref) {
			return ref.isTouched();
		});

		if (isValid && (isTouched || this.props.settings.allowEmpty)) {
			// Loop through each header and build header object
			_each(this.refs, function (ref) {
				// Only add edit data to object if actually touched or checkbox
				var validToAdd = [ref.isTouched(), ref._getInputFamily() === 'checkbox'];
				if (_some(validToAdd)) {
					// All contents from header model
					header = ref.getDefaultData();

					// Value of whatever type input field
					value = ref.getValue();

					// Name
					name = _has(header, 'reference') ? header.reference.newName : header.name;

					// Check if endtime is midnight. If yes, subtract 1 second
					if (name === 'time__to' && moment.isMoment(value) && value.format('HH:mm:ss') === '00:00:00') {
						value = value.subtract(1, 's');
					}

					// Build headers object with old data and new data
					headers[name] = header;
					headers[name].data = value;
				}
			});

			// Add to args
			args.headers = headers;
			if (this.props.isAdding) args.type = 'add';
			else if (this.props.isEditing) {
				args.type = 'edit';
				args.id = this.props.editRowId;
			}

			// List directive method
			this.props.methods
				.handleCreateEditItem(args)
				.then(() => {
					this.setState({ submitted: false });
				})
				.catch(() => {
					this.setState({ submitted: false });
				});
		} else {
			this.setState({ submitted: false });
			this.props.methods.feedback(this.props.translations.INVALID_FIELDS, 0); // Send feedback to user
		}
	}

	/**
	 * @function _renderEditRowCells
	 * @memberOf DIRECTIVES.jtjList.component.addRow
	 * @description Renders cells for edit row (or just normal cell if not editable)
	 * @return {object}
	 */
	_renderEditRowCells() {
		return _map(
			this.props.methods.handleReturnVisibleHeaders(true),
			function (cell, cellKey) {
				var validExpressions = [this.props.isAdding && cell.canAdd, this.props.isEditing && cell.canEdit];
				if (_some(validExpressions) && !cell.hidden) {
					var name = _has(cell, 'reference') ? cell.reference.newName : cell.name;

					return (
						<td key={cellKey} className="list__cell">
							<span className={this._inputWrapperClassNames()}>
								<DataInput
									header={cell}
									ref={name}
									disabled={cell.disabled}
									submitted={this.state.submitted}
									item={this.props.row}
									submit={this.updateRow}
									{...this.props}
								/>
							</span>
						</td>
					);
				} else if (cell.type === 'action') return null;
				else {
					return <Cell key={cellKey} cell={cell} row={this.props.row} {...this.props} />;
				}
			}.bind(this)
		);
	}

	/**
	 * @function _renderEditRowSaveCell
	 * @memberOf DIRECTIVES.jtjList.component.addRow
	 * @description Renders cells with buttons for saving added row
	 * @return {object}
	 */
	_renderEditRowSaveCell() {
		var tinyButtonExpressions = [
			this.props.collection.length < 1,
			this.props.actionColLength < 2,
			this.props.actionColLength > 1 && this.props.collection.length < 2 && !this.props.isAdding,
		];
		var isTiny = _some(tinyButtonExpressions);
		var buttonClassNames = {
			cancel: cx(
				'button icon icon--clear list__cell__save-column__button',
				'button--primary list__cell__save-column__button--cancel',
				{
					'list__cell__save-column__button--tiny': isTiny,
				}
			),
			save: cx(
				'button icon icon--done list__cell__save-column__button',
				'button button--pink list__cell__save-column__button--save',
				{
					'list__cell__save-column__button--tiny': isTiny,
				}
			),
		};
		return (
			<td colSpan={this.props.actionColLength} className="list__cell list__cell__save-column">
				<span className="list__cell-contents list__cell-contents--center">
					<button
						className={buttonClassNames.cancel}
						tabIndex="-1"
						onClick={this.props.methods.handleToggleAddEditRow.bind(this)}
					/>
					<button disabled={this.state.submitted} className={buttonClassNames.save} onClick={this.updateRow} />
				</span>
			</td>
		);
	}

	/**
	 * @function _inputWrapperClassNames
	 * @memberOf DIRECTIVES.jtjList.component.addRow
	 * @description Class names
	 */
	_inputWrapperClassNames() {
		return cx('list__form-field', 'list__cell-contents', {
			'state--submitted': this.state.submitted,
		});
	}

	render() {
		return (
			<tr className="list__row list__row--add">
				{this._renderEditRowCells()}
				{this._renderEditRowSaveCell()}
			</tr>
		);
	}
}

AddRow.propTypes = {
	actionColLength: PropTypes.number.isRequired,
	editRowId: PropTypes.number,
	headers: PropTypes.array.isRequired,
	isAdding: PropTypes.bool.isRequired,
	isEditing: PropTypes.bool.isRequired,
	methods: PropTypes.object.isRequired,
	row: PropTypes.object,
	settings: PropTypes.object.isRequired,
	translations: PropTypes.object.isRequired,
};

export default AddRow;
