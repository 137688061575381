import React from 'react';

// lodash
import _get from 'lodash/get';

export default function getColumns() {
	return [
		{
			Header: 'Layout name',
			id: 'name',
			accessor: (d) => _get(d, 'name', ''),
			filterPath: ':name',
		},
		{
			Header: 'Description',
			id: 'description',
			accessor: (d) => _get(d, 'description', ''),
			filterPath: ':description',
		},
		{
			Header: 'Type',
			id: 'type',
			accessor: (d) => _get(d, 'type', null),
			filterPath: ':type',
		},
		{
			Header: 'Rows',
			id: 'rows',
			accessor: (d) => _get(d, 'rows', null),
			filterPath: ':rows',
		},
		{
			Header: 'Columns',
			id: 'columns',
			accessor: (d) => _get(d, 'columns', null),
			filterPath: ':columns',
		},
		{
			Header: 'Sub type',
			id: 'subType',
			accessor: (d) => _get(d, 'sub_type', null),
			filterPath: ':sub_type',
		},
		{
			Header: 'Show case image',
			id: 'show-case-image',
			accessor: (d) => _get(d, 'show_case_image.name', null),
			filterPath: ':show_case_image.name',
		},
		{
			Header: 'Legacy',
			id: 'legacy',
			accessor: 'legacy',
			filterPath: ':legacy',
			filterable: false,
			Cell: (d) => {
				return <input type="checkbox" checked={d.original.legacy} disabled />;
			},
		},
	];
}
