'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, ButtonGroup, InputCollectionSelect, Toggle, Tag } from 'dumb';
import { ModalRow } from 'dumb/modal';

// lodash
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

// utils
import constants from 'services/constants';
import phrases from './../../../posConfigurationProductVariantsPricing.phrases';
import moment from 'moment';

class ProductVariantsPricingStepForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};
	}

	editStoreEntry(e, type) {
		const { updateBatchForm, resetSelectedRows, posConfigurationVersionReduxKey, productVariantReduxKey } = this.props;

		const value = e?.target?.value ?? e;

		if (_isEmpty(e) && (type === 'posConfigurationVersion' || type === 'productVariant')) {
			resetSelectedRows(type === 'posConfigurationVersion' ? posConfigurationVersionReduxKey : productVariantReduxKey);
		}

		updateBatchForm({
			[type]: value,
		});
	}

	_getProductTag() {
		const { batchFormData } = this.props;

		return <Tag color="dddddd">{_get(batchFormData, 'productVariant[0].label', null)}</Tag>;
	}

	render() {
		const { batchFormData, toggleOverlay, posConfigurationVersionReduxKey, productVariantReduxKey } = this.props;

		return (
			<>
				<ModalRow>
					{batchFormData.productVariantPropPassed ? (
						<div className="pos-configuration-product-variants-pricing__modal-batch__workplace-markets">
							<span>{phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT}</span>
							<div className="pos-configuration-product-variants-pricing__modal-batch__workplace-markets__market-tags">
								{this._getProductTag()}
							</div>
						</div>
					) : (
						<ModalRow>
							<ButtonGroup type="full-width">
								<InputCollectionSelect
									className="modal-batch-select"
									id="product_variant"
									label={phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT}
									placeholder="Select product variant..."
									value={batchFormData.productVariant}
									handleChange={(key, value) => this.editStoreEntry(value, 'productVariant')}
									multi
									cache
									apiPath="/product/product_variants"
									params={{
										limit: 50,
									}}
									optionFormat={(entry) => ({
										value: entry,
										label: entry.name,
									})}
									inputFilterFormat={(input) => `:name=like='%${input}%'`}
								/>

								<Button type="inverted" shadow size="large" onClick={() => toggleOverlay(productVariantReduxKey)}>
									<span className="icon icon--more" />
								</Button>
							</ButtonGroup>
						</ModalRow>
					)}
				</ModalRow>

				<ModalRow>
					<ButtonGroup type="full-width">
						<InputCollectionSelect
							className="modal-batch-select"
							id="pos_configuration_version"
							label={phrases.MODAL_BATCH_STEP_POS_CONFIGURATION_VERSION}
							placeholder="Select Pos configuration version..."
							value={batchFormData.posConfigurationVersion}
							handleChange={(key, value) => this.editStoreEntry(value, 'posConfigurationVersion')}
							multi
							cache
							apiPath="/pos/pos_configuration_versions"
							params={{
								limit: 50,
								filter: `:active.to=ge='${moment.utc().format(constants.shortDate)}'`,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.pos_configuration.name,
							})}
							inputFilterFormat={(input) => `:pos_configuration.name=like='%${input}%'`}
						/>

						<Button type="inverted" shadow size="large" onClick={() => toggleOverlay(posConfigurationVersionReduxKey)}>
							<span className="icon icon--more" />
						</Button>
					</ButtonGroup>
				</ModalRow>

				<ModalRow>
					<Input
						label={phrases.MODAL_BATCH_STEP_ALTERNATIVE_ID}
						placeholder="Enter Alternative id..."
						type="number"
						required
						id="alternative-id"
						onChange={(event) => this.editStoreEntry(event, 'alternativeId')}
						value={batchFormData.alternativeId || ''}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						label={phrases.MODAL_BATCH_STEP_TO_STAY_PRICE}
						id="to_stay_price"
						type="number"
						placeholder="Enter To stay price..."
						onChange={(event) => this.editStoreEntry(event, 'toStayPrice')}
						value={batchFormData.toStayPrice || ''}
					/>
				</ModalRow>

				<ModalRow>
					<InputCollectionSelect
						id="stepFormToStayTax"
						label={phrases.MODAL_BATCH_STEP_TO_STAY_TAX}
						handleChange={(key, value) => this.editStoreEntry(value, 'toStayTax')}
						cache
						placeholder="Select To stay tax..."
						optionFormat={(entry) => ({
							value: entry.id,
							label: `${entry.name} (${entry.rate}% ${entry.type}) - ${entry.market.name}`,
						})}
						value={batchFormData.toStayTax || undefined}
						apiPath="/administration/market_tax_rates"
						inputFilterFormat={(input) =>
							`:name=like='%${input}%',:rate=like='%${input}%',:market.name=like='%${input}%'`
						}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						label={phrases.MODAL_BATCH_STEP_TO_GO_PRICE}
						id="to_go_price"
						type="number"
						placeholder="Enter To go price..."
						onChange={(event) => this.editStoreEntry(event, 'toGoPrice')}
						value={batchFormData.toGoPrice || ''}
					/>
				</ModalRow>

				<ModalRow>
					<InputCollectionSelect
						id="stepFormToGoTax"
						label={phrases.MODAL_BATCH_STEP_TO_GO_TAX}
						handleChange={(key, value) => this.editStoreEntry(value, 'toGoTax')}
						cache
						placeholder="Select To go tax..."
						optionFormat={(entry) => ({
							value: entry.id,
							label: `${entry.name} (${entry.rate}%) ${entry.type}) - ${entry.market.name}`,
						})}
						value={batchFormData.toGoTax || undefined}
						apiPath="/administration/market_tax_rates"
						inputFilterFormat={(input) =>
							`:name=like='%${input}%',:rate=like='%${input}%',:market.name=like='%${input}%'`
						}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						id="delivery-price"
						label={phrases.MODAL_BATCH_STEP_DELIVERY_PRICE}
						type="number"
						placeholder="Enter delivery price..."
						onChange={(event) => this.editStoreEntry(event, 'deliveryPrice')}
						value={batchFormData.deliveryPrice}
					/>
				</ModalRow>

				<ModalRow>
					<InputCollectionSelect
						id="delivery-tax"
						label={phrases.MODAL_BATCH_STEP_DELIVERY_TAX}
						value={batchFormData.deliveryTax}
						handleChange={(key, value) => this.editStoreEntry(value, 'deliveryTax')}
						clearable={false}
						cache
						placeholder="Select Delivery tax..."
						optionFormat={(entry) => ({
							value: entry.id,
							label: `${entry.name} (${entry.rate}%)`,
						})}
						apiPath="/administration/market_tax_rates"
						params={{
							limit: 30,
						}}
					/>
				</ModalRow>

				<ModalRow>
					<Toggle
						id="toggle"
						label={phrases.MODAL_BATCH_STEP_AVAILABLE_IN_APP}
						toggled={batchFormData.availableInApp}
						onClick={(e) => this.editStoreEntry(e, 'availableInApp')}
					/>
				</ModalRow>
			</>
		);
	}
}

ProductVariantsPricingStepForm.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
	resetSelectedRows: PropTypes.func,
	posConfigurationVersionReduxKey: PropTypes.string,
	productVariantReduxKey: PropTypes.string,
	productVariant: PropTypes.object,
};

export default ProductVariantsPricingStepForm;
