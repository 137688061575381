'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { InputCollectionSelect } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';

import {
	fetchMarketShiftRules,
	addMarketShiftRule,
	deleteMarketShiftRule,
} from './organisationMarketShiftRules.service';

import { updateMarketShiftRule, resetState } from './store/organisationMarketShiftRules.actions';

// phrases/constants/enums
import phrases from './organisationMarketShiftRules.phrases';

// options for input select
import { getOptions } from 'services/utils/marketShiftRules';

// lodash
import _get from 'lodash/get';

class OrganisationMarketShiftRules extends Component {
	constructor(props) {
		super(props);

		this.fetchData = this.fetchData.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.getEditableCells = this.getEditableCells.bind(this);

		this.reduxKey = 'organisation/shiftplanning/market_shift_rules';

		this.columns = [
			{
				Header: 'Rule',
				id: 'rule',
				accessor: 'type',
				filterPath: ':type',
			},
		];
	}

	addEntry() {
		const { marketId, marketShiftRule } = this.props;

		const payload = {
			market: marketId,
			type: _get(marketShiftRule, 'rule.value', null),
		};

		return addMarketShiftRule(payload);
	}

	deleteEntry(id) {
		return deleteMarketShiftRule(id);
	}

	getEditableCells() {
		const { marketShiftRule } = this.props;

		return [
			{
				header: 'Rule',
				value: (
					<InputCollectionSelect
						id="rule"
						placeholder="Select rule..."
						value={marketShiftRule.rule}
						handleChange={(key, value) => this.editStoreEntry(value, 'rule')}
						options={getOptions()}
						clearable={false}
					/>
				),
			},
		];
	}

	editStoreEntry(e, name) {
		const data = {
			[name]: e,
		};

		// function here
		this.props.updateMarketShiftRule(data);
	}

	toggleAddModal() {
		this.setState((prevState) => ({ showAddModal: !prevState.showAddModal }));
		this.props.resetState();
	}

	fetchData(state) {
		const { marketId } = this.props;

		const filter = `:market.id=='${marketId}'`;

		return fetchMarketShiftRules(state, filter);
	}

	render() {
		return (
			<ReactDataWrapper
				title={phrases.TABLE_TITLE}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				accessAreasAllowedToEdit={['Organisation/Workplaces::Edit', 'Organization Admin', 'Global Sales Configuration', 'Sales Configuration']}

				
				defaultPageSize={10}
				reduxKey={this.reduxKey}
				manual
				compact
				deleteEntry={this.deleteEntry}
				createEntry={this.addEntry}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetState}
				editableCells={this.getEditableCells()}
			/>
		);
	}
}

OrganisationMarketShiftRules.propTypes = {
	resetState: PropTypes.func,
	updateMarketShiftRule: PropTypes.func,
	marketId: PropTypes.number,
	marketShiftRule: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateMarketShiftRule,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		marketShiftRule: store.organisationMarketShiftRules.data.marketShiftRule,
	};
};

export default connectWithStore(OrganisationMarketShiftRules, mapStateToProps, mapDispatchToProps);
