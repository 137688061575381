'use strict';

import { get, post, remove, patch } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchProductVariants
 * @description Fetch all product variants
 * @param {object} state
 */
export function fetchProductVariants(state, extraFilter) {
	let filter;
	if (state.filter) filter = state.filter;
	if (extraFilter) filter = filter ? `${filter};${extraFilter}` : extraFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	// get product variants
	return get('/product/product_variants', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editProductVariants
 * @description edit product variants
 */
export function editProductVariants(productVariantId, data) {
	return post(`/product/product_variants/${productVariantId}`, data)
		.then((res) => {
			setFeedback('Product variant edited', 1);
			return res;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
		});
}

/**
 * @function editBatchProductVariants
 * @description edit multiple product variants
 */
export function editBatchProductVariants(data) {
	return patch(`/product/product_variants`, data)
		.then((res) => {
			setFeedback('Product variant edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function addProductVariant
 * @description add product variants
 */
export function addProductVariant(data) {
	return post('/product/product_variants', data)
		.then((res) => {
			setFeedback('Product variant added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteProductVariants
 * @param { Object } payload - object containing productVariantId field and productPointId (optional)
 * @description deleted product variant
 */
export function deleteProductVariants(id) {
	return remove(`/product/product_variants/${id}`)
		.then((res) => {
			setFeedback('Product variant deleted', 1);
			return res;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
			throw err;
		});
}
