'use strict';

import cx from 'classnames';
import _every from 'lodash/every';
import _isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import CollectionSelect from 'collectionSelect';

import React, { Component } from 'react';
import Date from 'reusableComponents/inputs/date/date.component';
import moment from 'moment';
/**
 * @namespace jtjDrawerToBankMatrice.component
 * @memberOf COMPONENTS
 */

class GoldModelDataDetailsTags extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tags: [],
			tagsList: {},
			isAdding: false,
			invalid: false,
			tag: null,
			from: null,
			to: null,
		};

		this.onClickAdd = this.onClickAdd.bind(this);
		this.onClickRemove = this.onClickRemove.bind(this);
		this.onClickAddConfirm = this.onClickAddConfirm.bind(this);
		this.onChange = this.onChange.bind(this);
		this.updateTags = this.updateTags.bind(this);
	}

	updateTags() {
		const { methods, workplace, date } = this.props;

		methods.handleGetGoldModelTags(workplace, date).then((data) => {
			this.setState({ tags: data });
		});
	}

	onChange(type, e) {
		switch (type) {
			case 'tag':
				this.setState({
					tag: e,
				});
				break;
			case 'range':
				this.setState({
					from: e.startDate && e.startDate.format('YYYY-MM-DD'),
					to: e.endDate && e.endDate.format('YYYY-MM-DD'),
				});
				break;
		}
	}

	onChangeExisting(tagId, range) {
		const period = {
			from: range.startDate && range.startDate.format('YYYY-MM-DD'),
			to: range.endDate && range.endDate.format('YYYY-MM-DD'),
		};

		this.setState((prevState) => ({
			tags: prevState.tags.map((tag) => {
				if (tag.id === tagId)
					return {
						...tag,
						period,
						period__from: period.from,
						period__to: period.to,
					};
				return tag;
			}),
		}));
	}

	onClickAdd(e) {
		e.preventDefault();
		this.setState({
			isAdding: !this.state.isAdding,
			invalid: false,
			tag: null,
			from: null,
			to: null,
		});
	}

	onClickRemove(tag, key, e) {
		e.preventDefault();
		const { methods } = this.props;
		methods.handleRemoveTag(tag.id).then(() => {
			this.updateTags();
		});
	}

	onClickUpdate(tagId, e) {
		e.preventDefault();

		const { methods } = this.props;

		const period = this.state.tags
			.filter((tag) => tag.id === tagId)
			.reduce(
				(acc, tag) => ({
					from: tag.period.from,
					to: tag.period.to,
				}),
				{}
			);

		methods.handleUpdateTag(tagId, period);
	}

	onClickAddConfirm(e) {
		e.preventDefault();
		const { tag, from, to } = this.state;
		const { workplace, methods } = this.props;

		// Are all fields valid?
		const valid = !_every([tag, from, to], _isNull);

		if (valid) {
			this.setState({ invalid: false });
			methods.handleAddTag({ workplace, tag: tag.value, from, to }).then(() => {
				// Add data to tags
				this.setState({
					isAdding: false,
					invalid: false,
					tag: null,
					from: null,
					to: null,
				});
				this.updateTags();
			});
		} else this.setState({ invalid: true });
	}

	renderTags() {
		const { tags } = this.state;

		return tags.map((tag, key) => (
			<div key={key}>
				<div className="gold-model__details__tags__taglist tag">
					<span>{tag.tag.name}</span>
				</div>
				<Date
					startDate={moment.utc(tag.period.from, 'YYYY-MM-DD')}
					endDate={moment.utc(tag.period.to, 'YYYY-MM-DD')}
					onChange={this.onChangeExisting.bind(this, tag.id)}
					type="range"
					withPortal={false}
				/>

				<div className="gold-model__details__tags__taglist__buttons">
					<button
						onClick={this.onClickUpdate.bind(this, tag.id)}
						className="button icon icon--done gold-model__details__tags__edit"
					/>
					<button
						onClick={this.onClickRemove.bind(this, tag, key)}
						className="button icon icon--clear gold-model__details__tags__remove"
					/>
				</div>
			</div>
		));
	}

	componentDidMount() {
		const { methods, workplace, date } = this.props;

		methods.handleGetGoldModelTags(workplace, date).then((data) => {
			this.setState({ tags: data });
		});
	}

	render() {
		const { isAdding, invalid, tag } = this.state;

		const { translations, methods } = this.props;

		return (
			<div className="gold-model__details__tags">
				<h4>{translations.HEADING}</h4>
				<button
					onClick={this.onClickAdd}
					className={cx(
						'button icon icon--add gold-model__details__tags__add',
						{
							' icon--close gold-model__details__tags__add--active': isAdding,
						}
					)}
				/>
				{isAdding && (
					<div
						className={cx('gold-model__details__tags__add-tag', {
							'gold-model__details__tags__add-tag--invalid': invalid,
						})}>
						<div className="select select--search select--no-search">
							<CollectionSelect
								value={{ value: tag?.value, label: tag?.label }}
								handleChange={(key, value) => {
									this.onChange('tag', value);
								}}
								clearable={false}
								cache
								apiPath="/tagging/tags"
								params={{
									limit: 300,
									filter: methods.handleGetGoldModelTagListFilter(),
								}}
								inputFilterFormat={(input) => `:name=like='%${input}%'`}
							/>
						</div>
						<Date
							withPortal={false}
							onChange={this.onChange.bind(this, 'range')}
							type="range"
						/>
						<button
							className="button icon icon--done gold-model__details__tags__add-tag__add-button"
							onClick={this.onClickAddConfirm}
						/>
					</div>
				)}
				<div className="gold-model__details__tags__taglist">
					{this.renderTags()}
				</div>
			</div>
		);
	}
}

GoldModelDataDetailsTags.propTypes = {
	methods: PropTypes.object.isRequired,
	translations: PropTypes.object.isRequired,
	date: PropTypes.string.isRequired,
	periodType: PropTypes.string.isRequired,
};

export default GoldModelDataDetailsTags;
