export const UPDATE_BATCH_FORM =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNT_INGREDIENT/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNT_INGREDIENT/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNT_INGREDIENT/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNT_INGREDIENT/REMOVE_BATCH_LIST_ITEM';
export const SET_POS_CONFIGURATION_DISCOUNT_INGREDIENT =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNT_INGREDIENT/SET_POS_CONFIGURATION_DISCOUNT_INGREDIENT';
export const SET_POS_CONFIGURATION_DISCOUNT_INGREDIENT_DATE_FILTER =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNT_INGREDIENT/SET_POS_CONFIGURATION_DISCOUNT_INGREDIENT_DATE_FILTER';
export const UPDATE_POS_CONFIGURATION_DISCOUNT_INGREDIENT =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNT_INGREDIENT/UPDATE_POS_CONFIGURATION_DISCOUNT_INGREDIENT';
export const RESET_STATE =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNT_INGREDIENT/RESET_STATE';
export const SET_POS_CONFIGURATION_DISCOUNT_INGREDIENT_LEGACY_FILTER =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNT_INGREDIENT/SET_POS_CONFIGURATION_DISCOUNT_INGREDIENT_LEGACY_FILTER';

export function setLegacyFilter(payload) {
	return {
		type: SET_POS_CONFIGURATION_DISCOUNT_INGREDIENT_LEGACY_FILTER,
		payload,
	};
}

export function setPosConfigurationDiscountIngredientDateFilter(payload) {
	return {
		type: SET_POS_CONFIGURATION_DISCOUNT_INGREDIENT_DATE_FILTER,
		payload
	};
}

export function setPosConfigurationDiscountIngredient(payload) {
	return {
		type: SET_POS_CONFIGURATION_DISCOUNT_INGREDIENT,
		payload
	};
}

export function updatePosConfigurationDiscountIngredient(payload) {
	return {
		type: UPDATE_POS_CONFIGURATION_DISCOUNT_INGREDIENT,
		payload
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload
	};
}

export function resetState() {
	return {
		type: RESET_STATE
	};
}
