'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { InputCollectionSelect, Input } from 'dumb';
import { ModalRow } from 'dumb/modal';

// phrases/enums
import phrases from './../../../productVariantIngredientAlternatives.phrases';
import enums from './../../../productVariantIngredientAlternatives.enums';

// lodash
import _get from 'lodash/get';

// utils
// import { getOptions } from 'services/utils/productVariantIngredientAlternatives';

class ProductVariantIngredientAlternativesBatchStepForm extends Component {
	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		const value = e?.target?.value ?? e;

		updateBatchForm({
			[type]: value,
		});
	}

	render() {
		const { batchFormData } = this.props;

		return (
			<>
				<ModalRow>
					<InputCollectionSelect
						id="product-variant-ingredient"
						label={phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT_INGREDIENT}
						placeholder={
							phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT_INGREDIENT_PLACEHOLDER
						}
						value={batchFormData.productVariantIngredient}
						handleChange={(key, value) =>
							this.editStoreEntry(value, 'productVariantIngredient')
						}
						multi
						cache
						disabled={batchFormData.productVariantIngredientDisabled}
						apiPath="/product/product_variant_ingredients"
						params={{
							limit: 30,
							filter: ':product_variant.legacy==false',
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: `${entry?.product_variant?.name} - ${entry?.ingredient?.name}`,
						})}
						inputFilterFormat={(input) =>
							`:product_variant.name=like='%${input}%';:ingredient.name=like='%${input}%'`
						}
						tableReduxKey="productVariantInredientAlternatives-/product/product_variant_ingredients"
						tableTitle={phrases.PRODUCT_VARIANT_INGREDIENTS_TABLE_TITLE}
						tableColumns={[
							{
								Header: 'Product variant',
								id: 'product-variant',
								accessor: (d) => _get(d, 'product_variant.name', ''),
								filterPath: ':product_variant.name',
							},
							{
								Header: 'Ingredient',
								id: 'ingredient',
								accessor: (d) => _get(d, 'ingredient.name', ''),
								filterPath: ':ingredient.name',
							},
							{
								Header: 'Ingredient variation',
								id: 'ingredient_variation',
								accessor: (d) => _get(d, 'ingredient_variation.name', ''),
								filterPath: ':ingredient_variation.name',
							},
							{
								Header: 'Default',
								id: 'default',
								accessor: 'default',
								filterPath: ':default',
							},
							{
								Header: 'Ingredient amount',
								id: 'ingredient_amount',
								accessor: (d) => _get(d, 'ingredient_amount', ''),
								filterPath: ':ingredient_amount',
							},
							{
								Header: 'Excludable',
								id: 'excludable',
								filterPath: ':excludable',
								filterable: false,
								Cell: (d) => {
									return (
										<Input
											id="checkbox-exc"
											type="checkbox"
											checked={d.original.excludable}
											disabled
										/>
									);
								},
							},
							{
								Header: 'Available in app',
								id: 'available_in_app',
								filterPath: ':available_in_app',
								filterable: false,
								Cell: (d) => {
									return (
										<Input
											id="checkbox-avail"
											type="checkbox"
											checked={d.original.available_in_app}
											disabled
										/>
									);
								},
							},
							{
								Header: 'Sort order',
								id: 'sortOrder',
								accessor: 'sort_order',
								filterPath: ':sort_order',
							},
							{
								Header: 'Ingredient variation selection required',
								id: 'ingredientVariationSelectionRequired',
								filterPath: ':ingredient_variation_selection_required',
								filterable: false,
								Cell: (d) => {
									return (
										<Input
											id="checkbox-ingvar"
											type="checkbox"
											checked={
												d.original.ingredient_variation_selection_required
											}
											disabled
										/>
									);
								},
							},
						]}
					/>
				</ModalRow>

				{batchFormData.type === enums.ALTERNATIVE_INGREDIENT && (
					<ModalRow>
						<InputCollectionSelect
							id="ingredient"
							label={phrases.MODAL_BATCH_STEP_INGREDIENT}
							placeholder={phrases.MODAL_BATCH_STEP_INGREDIENT_PLACEHOLDER}
							value={batchFormData.ingredient}
							handleChange={(key, value) =>
								this.editStoreEntry(value, 'ingredient')
							}
							multi
							cache
							apiPath="/product/ingredients"
							params={{
								limit: 30,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
							tableReduxKey="productVariantInredientAlternatives-/product/ingredients"
							tableTitle={phrases.INGREDIENTS_TABLE_TITLE}
							tableColumns={[
								{
									Header: 'Name',
									id: 'name',
									accessor: (d) => _get(d, 'name', ''),
									filterPath: ':name',
								},
								{
									Header: 'Ingredient category',
									id: 'ingredient_category',
									accessor: (d) => _get(d, 'ingredient_category.name', ''),
									filterPath: ':ingredient_category.name',
								},
								{
									Header: 'Fuel',
									id: 'fuel',
									accessor: (d) => _get(d, 'fuel', ''),
									filterPath: ':fuel',
								},
								{
									Header: 'Asset Collection',
									id: 'asset_collection',
									accessor: (d) => _get(d, 'asset_collection.name', null),
									filterPath: ':asset_collection.name',
								},
								{
									Header: 'Ingredient Pricing Group',
									id: 'ingredient_pricing_group',
									accessor: (d) =>
										_get(d, 'ingredient_pricing_group.name', null),
									filterPath: ':ingredient_pricing_group.name',
								},
								{
									Header: 'List name',
									id: 'list_name',
									accessor: 'list_name',
									filterPath: ':list_name',
								},
								{
									Header: 'Operation type',
									id: 'operation_type',
									accessor: 'operation_type',
									filterPath: ':operation_type',
								},
								{
									Header: 'Availability administration',
									id: 'availabilityAdministration',
									accessor: 'availability_administration',
									filterPath: ':availability_administration',
									filterable: false,
									Cell: (d) => {
										return (
											<Input
												type="checkbox"
												checked={_get(
													d,
													'original.availability_administration',
													false
												)}
												disabled
											/>
										);
									},
								},
								{
									Header: 'Legacy',
									id: 'legacy',
									accessor: (d) => _get(d, 'legacy', ''),
									filterPath: ':legacy',
									filterable: false,
									Cell: (d) => {
										return (
											<Input
												type="checkbox"
												checked={_get(d, 'original.legacy', false)}
												disabled
											/>
										);
									},
								},
							]}
						/>
					</ModalRow>
				)}

				{batchFormData.type === enums.ALTERNATIVE_PRODUCTS && (
					<ModalRow>
						<InputCollectionSelect
							id="product-variant-group"
							label={phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT_GROUP}
							placeholder={
								phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT_GROUP_PLACEHOLDER
							}
							value={batchFormData.productVariantGroup}
							handleChange={(key, value) =>
								this.editStoreEntry(value, 'productVariantGroup')
							}
							multi
							cache
							apiPath="/product/product_variant_groups"
							params={{
								limit: 30,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
							tableReduxKey="productVariantInredientAlternatives-/product/product_variant_groups"
							tableTitle={phrases.PRODUCT_VARIANT_GROUPS_TITLE}
							tableColumns={[
								{
									Header: 'Name',
									id: 'name',
									accessor: (d) => _get(d, 'name', ''),
									filterPath: ':name',
								},
								{
									Header: 'Description',
									id: 'desc',
									accessor: (d) => _get(d, 'description', ''),
									filterPath: ':description',
								},
								{
									Header: 'Sort order',
									id: 'sortOrder',
									accessor: (d) => _get(d, 'sort_order', ''),
									filterPath: ':sort_order',
								},
								{
									Header: 'Legacy',
									id: 'legacy',
									accessor: 'legacy',
									filterPath: ':legacy',
									filterable: false,
									Cell: (d) => {
										return (
											<Input
												id="checkbox"
												type="checkbox"
												checked={d.original.legacy}
												disabled
											/>
										);
									},
								},
							]}
						/>
					</ModalRow>
				)}
			</>
		);
	}
}

ProductVariantIngredientAlternativesBatchStepForm.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
};

export default ProductVariantIngredientAlternativesBatchStepForm;
