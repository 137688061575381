'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputCleave } from 'dumb';

import './timePicker.css';

class TimePickerNew extends Component {
	onChange(e) {
		const { onChange } = this.props;

		onChange(e.target.value);
	}

	render() {
		const { value, disabled, label, size, dataCy } = this.props;
		return (
			<div className="j-time-picker" data-cy={dataCy}>
				<InputCleave
					id={label}
					label={label}
					type="time"
					options={{
						time: true,
						timePattern: ['h', 'm'],
					}}
					placeholder="HH:mm"
					onChange={(e) => this.onChange(e)}
					value={value}
					disabled={disabled}
					size={size}
				/>
			</div>
		);
	}
}

TimePickerNew.defaultProps = {
	disabled: false,
	dataCy: '',
};

TimePickerNew.propTypes = {
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	value: PropTypes.string,
	label: PropTypes.string,
	size: PropTypes.string,
	dataCy: PropTypes.string,
};

export default TimePickerNew;
