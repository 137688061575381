'use strict';
import { getCookie } from 'cookies';

/**
 * @function initListCollapsed
 * @memberOf SERVICES.__models
 * @description
 * Sets collapsed if locally collapsed
 */

module.exports = function () {
	var isCollapsed = !!getCookie(this.getSettings().name + '-collapsed');
	this.setIsCollapsed(isCollapsed);
};
