'use strict';

// Services
import DetailsModel from 'common/components/details/detailsModel.service';
const __endpoints = require('endpoints.service');

export default class CountriesDetails extends DetailsModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
				span: 2,
			},
			{
				name: 'alpha_2_code',
				type: 'string',
				span: 1,
			},
			{
				name: 'alpha_3_code',
				type: 'string',
				span: 1,
			},
			{
				name: 'numeric_code',
				type: 'string',
				span: 1,
			},
			{
				name: 'iso_3166_2_code',
				type: 'string',
				span: 1,
			},
			{
				name: 'nationality',
				type: 'select',
				canAdd: true,
				canEdit: true,
				reference: {
					key: 'name',
					list: '/administration/nationalities',
				},
			},
		];
		this.settings = {
			name: 'CountriesDetails',
			translationPath: 'ADMINISTRATION.COUNTRIES',
			endpoint: __endpoints.collection.administration.countries,
		};
		this.init();
		return this;
	}
}
