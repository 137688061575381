import React from 'react';
import PropTypes from 'prop-types';

// components
import { ModalRow } from 'dumb/modal';
import { Label, Text, Tag } from 'dumb';
import { Tip } from 'dumb/tips';
import ShiftTransferPendingTransfersMobalViewPageApplied from './components/shiftTransferPendingTransfersMobileViewPageApplied';
import ShiftTransferPendingTransfersMobalViewPageOwn from './components/shiftTransferPendingTransfersMobileViewPageOwn';
import ShiftTransferApprovalMobileViewPageApplicantList from './../shiftTransferApprovalMobileViewPage/components/shiftTransferApprovalMobileViewPageApplicantList/shiftTransferApprovalMobileViewPageApplicantList';

// utils
import phrases from './../../shiftTransferApprovalMobileView.phrases';
import globalPhrases from './../../../../shiftTransferApproval.phrases';
import { ShiftTransfer as enums } from 'services/enums';
import shiftTransferApprovalEnums from './../../../../shiftTransferApproval.enums';

// utils
import {
	formatShiftTime,
	getShiftDate,
	getShiftDay,
	getCanDeclineTransferOwn,
	getAppliedPendingShiftTransferMetadata,
	getOwnPendingTransferState,
} from './../../../../utils';

// lodash
import _isEmpty from 'lodash/isEmpty';

// tag color constants
const defaultColor = shiftTransferApprovalEnums.COLORS.DEFAULT;

const ShiftTransferPendingTransfersMobileViewPage = ({
	shiftTransfer,
	ownTransfer,
	personId,
	updateShiftTransfer,
	updateShiftTransferApplicationWrapper,
}) => {
	const transferTime = `${getShiftDate(
		shiftTransfer.shift?.planned_period?.from
	)}  ${formatShiftTime(
		shiftTransfer.shift?.planned_period?.from
	)} - ${formatShiftTime(shiftTransfer.shift?.planned_period?.to)}`;

	const donor = shiftTransfer?.donor?.person?.full_name;
	const applicant =
		shiftTransfer?.applications?.[0]?.applicant?.person?.full_name;
	const workplace = shiftTransfer?.shift?.workplace?.name;

	const publicTransfer = shiftTransfer.type === enums.TRANSFER_TYPE.PUBLIC;
	const swapTransfer = shiftTransfer.type === enums.TRANSFER_TYPE.SWAP;

	let heading;
	if (ownTransfer && publicTransfer) {
		heading = `You want to transfer your shift in ${workplace}`;
	} else if (ownTransfer && swapTransfer) {
		heading = `You want to transfer your shift to ${applicant} in ${workplace}`;
	} else if (!ownTransfer && publicTransfer) {
		heading = `You applied for a public shift in ${workplace}`;
	} else {
		heading = `${donor} wants you to cover his shift in ${workplace}`;
	}

	let canCancelTransfer, canReapplyForShift, stateToShow;
	if (ownTransfer) {
		canCancelTransfer = getCanDeclineTransferOwn({ entry: shiftTransfer });
		stateToShow = getOwnPendingTransferState(shiftTransfer);
	} else {
		const metadata = getAppliedPendingShiftTransferMetadata({
			entry: shiftTransfer,
			personId,
		});

		canCancelTransfer = metadata.canCancelTransfer;
		canReapplyForShift = metadata.canReapplyForShift;
	}

	return (
		<div className="shift-transfer-approval-modal__mobile-view">
			<ModalRow>
				<Text bold alignCenter size="large">
					{heading}
				</Text>
			</ModalRow>

			<ModalRow className="shift-transfer-approval-modal__mobile-view__section">
				{ownTransfer ? (
					<div>
						<Label>{phrases.STATE}</Label>
						<Tag color={defaultColor}>
							<Text size="small" bold>
								{stateToShow}
							</Text>
						</Tag>
					</div>
				) : (
					<Tip content={globalPhrases.APPLIED_SHIFTS_TIP} />
				)}
			</ModalRow>

			<ModalRow>
				<div>
					<Label>{phrases.SHIFT}</Label>
					<div className="shift-transfer-approval-modal__mobile-view__times-wrapper">
						<Text bold>{transferTime}</Text>
						<Text
							size="tiny"
							className="shift-transfer-approval-modal__mobile-view__times-wrapper__weekday">
							({getShiftDay(shiftTransfer.shift?.planned_period?.from)})
						</Text>
					</div>
				</div>
			</ModalRow>

			{!_isEmpty(shiftTransfer.applications) && (
				<ModalRow>
					<div>
						<Label>{phrases.APPLICANTS}</Label>
						<ShiftTransferApprovalMobileViewPageApplicantList
							shiftTransfer={shiftTransfer}
							noActions
						/>
					</div>
				</ModalRow>
			)}
			{ownTransfer ? (
				<ShiftTransferPendingTransfersMobalViewPageOwn
					shiftTransfer={shiftTransfer}
					canCancelTransfer={canCancelTransfer}
					updateShiftTransfer={updateShiftTransfer}
				/>
			) : (
				<ShiftTransferPendingTransfersMobalViewPageApplied
					shiftTransfer={shiftTransfer}
					canReapplyForShift={canReapplyForShift}
					canCancelTransfer={canCancelTransfer}
					updateShiftTransferApplicationWrapper={
						updateShiftTransferApplicationWrapper
					}
				/>
			)}
		</div>
	);
};

ShiftTransferPendingTransfersMobileViewPage.propTypes = {
	shiftTransfer: PropTypes.object,
	ownTransfer: PropTypes.bool,
	personId: PropTypes.number,
	updateShiftTransfer: PropTypes.func,
	updateShiftTransferApplicationWrapper: PropTypes.func,
};

export default ShiftTransferPendingTransfersMobileViewPage;
