'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, Tag, Legend } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// phrases
import phrases from './../../posConfigurationIngredientPricingGroups.phrases';

// lodash
import _get from 'lodash/get';

class IngredientPricingGroupStepList extends Component {
	constructor(props) {
		super(props);

		this.cantAddColor = 'ffe699';
	}

	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e.target ? e.target.value || e.target.checked : e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: value },
		});
	}

	render() {
		const { batchList } = this.props;

		return (
			<>
				<div className="product-variant-modal__legend">
					<Legend
						points={[
							{
								bulletColor: `#${this.cantAddColor}`,
								label: phrases.MODAL_BATCH_STEP_LIST_LEGEND_CANT_ADD,
							},
						]}
					/>
				</div>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_POS_CONFIGURATION_VERSION}</th>
							<th>{phrases.MODAL_BATCH_STEP_INGREDIENT_PRICING_GROUP}</th>
							<th>{phrases.MODAL_BATCH_STEP_TO_STAY_PRICE}</th>
							<th>{phrases.MODAL_BATCH_STEP_TO_GO_PRICE}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((entry, i) => {
							const rowDisabled = !entry.canAdd;

							return (
								<TableListRow
									key={entry.identifier}
									type={rowDisabled && 'light'}>
									<TableListCell>
										{rowDisabled && (
											<Tag type="bullet" color={this.cantAddColor} />
										)}
										{i + 1}
									</TableListCell>
									<TableListCell>
										{_get(entry, 'posConfigurationVersion.name', '')}
									</TableListCell>
									<TableListCell>
										{_get(entry, 'ingredientPricingGroup.name', '')}
									</TableListCell>

									<TableListCell>
										<Input
											id="toStayPrice"
											type="number"
											value={entry.toStayPrice}
											placeholder="To stay price"
											onChange={(event) =>
												this._editListEntry(event, entry, 'toStayPrice')
											}
											required={!rowDisabled}
											disabled={rowDisabled}
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id="toGoPrice"
											type="number"
											value={entry.toGoPrice}
											placeholder="To go price"
											onChange={(event) =>
												this._editListEntry(event, entry, 'toGoPrice')
											}
											required={!rowDisabled}
											disabled={rowDisabled}
										/>
									</TableListCell>

									<TableListCell>
										{!rowDisabled && (
											<Button
												type="negative"
												size="tiny"
												onClick={() => this._onRemoveEntry(entry.identifier)}>
												<span className="icon icon--delete" />
											</Button>
										)}
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
			</>
		);
	}
}

IngredientPricingGroupStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

IngredientPricingGroupStepList.defaultProps = {};

export default IngredientPricingGroupStepList;
