'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, TableList, Input, Icon, Tooltip } from 'dumb';

// phrases/ constants
import phrases from './../../../rawIngredientCategories.phrases';

class RawIngredientCategoriesModalStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e.target ? e.target.value : e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: value }
		});
	}

	render() {
		const { batchList, addBatchListItemRow } = this.props;

		return (
			<>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_NAME}</th>
							<th>{phrases.MODAL_BATCH_STEP_DESCRIPTION}</th>

							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((entry, i) => {
							return (
								<tr key={entry.identifier}>
									<td>{i + 1}</td>


									<td>
										<div className="raw-ingredient-modal-batch__list__cell">
											<Input
												id={`name-${i}`}
												required
												placeholder={phrases.MODAL_BATCH_STEP_NAME_PLACEHOLDER}
												value={entry.name || ''}
												onChange={event =>
													this._editListEntry(event, entry, 'name')
												}
											/>
										</div>
									</td>

									<td>
										<div className="raw-ingredient-modal-batch__list__cell">
											<Input
												id={`description-${i}`}
												placeholder={
													phrases.MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER
												}
												value={entry.description || ''}
												onChange={event =>
													this._editListEntry(event, entry, 'description')
												}
											/>
										</div>
									</td>

									<td>
										<Button
											id={`delete-${i}`}
											type="negative"
											size="tiny"
											onClick={() => this._onRemoveEntry(entry.identifier)}>
											<Icon name="delete" />
										</Button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</TableList>
				<div className="raw-ingredient-modal-batch__add-new-row">
					<Tooltip
						text={phrases.MODAL_BATCH_STEP_LIST_TOOLTIP}
						zIndex="600"
						renderData={(ref, onMouseEnter, onMouseLeave) => (
							<Button
								type="secondary"
								onClick={addBatchListItemRow}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
								refProp={ref}>
								<Icon name="add" />
							</Button>
						)}
					/>
				</div>
			</>
		);
	}
}

RawIngredientCategoriesModalStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func
};

export default RawIngredientCategoriesModalStepList;
