import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ListComponent from 'common/components/list/list.component';
import DetailsComponent from 'common/components/details/details.component';

import TagsDetails from './services/tagsDetails.service';
import ShiftPlanningMarketTagsList from './services/shiftPlanningMarketTagsList.service';
import ShiftPlanningWorkplaceTagsList from './services/shiftPlanningWorkplaceTagsList.service';

const tagsDetailsReduxKey = '/tagging/tags-details';
const tagsDetails = new TagsDetails(tagsDetailsReduxKey);
const tagsDetailsProps = tagsDetails.getProps();

const shiftPlanningMarketTagsListReduxKey = '/global-adm/shiftPlanning/market_tags';
const shiftPlanningMarketTagsList = new ShiftPlanningMarketTagsList(shiftPlanningMarketTagsListReduxKey);
const shiftPlanningMarketTagsListProps = shiftPlanningMarketTagsList.getProps();

const shiftPlanningWorkplaceTagsListReduxKey = '/shiftPlanning/workplace_tags';
const shiftPlanningWorkplaceTagsList = new ShiftPlanningWorkplaceTagsList(shiftPlanningWorkplaceTagsListReduxKey);
const shiftPlanningWorkplaceTagsListProps = shiftPlanningWorkplaceTagsList.getProps();

export function TagsDetailsContainer() {
	const { tagId } = useParams();

	const tagIdFilter = `:tag.id=='${tagId}'`;
	tagsDetails.setItemId(tagId);

	useEffect(() => {
		// Fetch selected employment position
		tagsDetails.initView();

		shiftPlanningMarketTagsList.setFilterQuery(tagIdFilter);
		shiftPlanningMarketTagsList.setData({
			tag: tagId,
		});

		shiftPlanningWorkplaceTagsList.setFilterQuery(tagIdFilter);
		shiftPlanningWorkplaceTagsList.setData({
			tag: tagId,
		});
	}, [tagId, tagIdFilter]);

	return (
		<>
			<DetailsComponent reduxKey={tagsDetailsReduxKey} {...tagsDetailsProps} />

			<ListComponent reduxKey={shiftPlanningMarketTagsListReduxKey} {...shiftPlanningMarketTagsListProps} />
			<ListComponent reduxKey={shiftPlanningWorkplaceTagsListReduxKey} {...shiftPlanningWorkplaceTagsListProps} />
		</>
	);
}
