'use strict';

// Services
import DetailsModel from 'common/components/details/detailsModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');

export default class SalaryComponentsDetails extends DetailsModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'global_salary_component',
				type: 'string',
				reference: {
					key: 'name',
					label: '<%= name %> (<%= type %>)',
					translationKey: 'GLOBAL_SALARY_COMPONENT_NAME',
				},
			},
			{
				name: 'name',
				type: 'string',
			},
			{
				name: 'amount_type',
				type: 'string',
			},
			{
				name: 'amount',
				type: 'currency',
			},
			{
				name: 'frequency',
				type: 'string',
			},
			{
				name: 'active',
				type: 'date',
				reference: {
					key: 'from',
					translationKey: 'FROM',
				},
			},
			{
				name: 'active',
				type: 'date',
				reference: {
					key: 'to',
					translationKey: 'TO',
				},
			},
		];
		this.settings = {
			name: 'SalaryComponentsDetails',
			alwaysRefresh: true,
			canManipulate: () => validAccess(undefined, ['Salary Admin']),
			translationPath: 'SALARY.SALARY_COMPONENTS',
			endpoint: __endpoints.collection.salary.salary_components,
		};
		this.init();
		return this;
	}
}
