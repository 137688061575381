import { SET_EMPLOYMENTS, SET_MODAL_VISIBLITY, EDIT_ROW_DATA, SET_EDITABLE_EMPLOYMENT, SET_EDITED_EMPLOYMENT, ADD_NEW_EMPLOYMENT, TOGGLE_LOADING } from './employmentsCard.actions';

const initialState = {
	data: {
		employments: [],
		editedValues: {}
	},
	ui: {
		modalVisible: false,
		editedRow: {},
		isEditMode: false,
		loading: false
	}
};

export default (state = initialState, action) => {
	switch (action.type) {

	case SET_EMPLOYMENTS:
		return {
			...state,
			data: {
				...state.data,
				employments: action.employments
			}
		};

	case SET_EDITABLE_EMPLOYMENT:
		return {
			...state,
			ui: {
				editedRow: action.employment
			}
		};

	case SET_MODAL_VISIBLITY:
		return {
			...state,
			ui: {
				...state.ui,
				modalVisible: action.payload,
				isEditMode: action.isEditMode
			}
		};
	
	case EDIT_ROW_DATA:
		return {
			...state,
			data: {
				...state.data,
				editedValues: {...state.data.editedValues, ...action.payload}
			},
			ui: {
				...state.ui,
				editedRow: {...state.ui.editedRow, ...action.payload}
			}
		};

	case SET_EDITED_EMPLOYMENT: {
		let employments = state.data.employments.map(employment => {
			if(employment.id === action.employment.id) {
				return action.employment;
			}
			return employment;
		});

		return {
			...state,
			data: {
				...state.data,
				employments: employments
			}
		};
	}

	case ADD_NEW_EMPLOYMENT: {
		const employments = state.data.employments.slice(0);
		employments.unshift(action.employment);
		return {
			...state,
			data: {
				...state.data,
				employments: employments
			}
		};
	}

	case TOGGLE_LOADING:
		return {
			...state,
			ui: {
				...state.ui,
				loading: action.payload
			}
		};

	default:
		return state;
	}
};
