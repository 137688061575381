'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	updateBatchForm,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setLanguages,
	updateLanguages,
	resetState,
} from './store/languages.actions';

// services
import {
	fetchLanguages,
	editLanguage,
	editLanguages,
	addLanguages,
	deleteLanguages,
} from './languages.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Input, Button, Icon } from 'dumb';
import LanguagesModalBatch from './components/batch/languagesModalBatch';
import getLanguageColumns from 'reactDataWrapperColumns/administration/languages.columns';

// utils
import { getEditedValues } from 'services/utils';

// moment
import moment from 'moment';

// phrases
import phrases from './languages.phrases';

const reduxKey = '/administration/languages';

class Languages extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.addLanguagesWrapper = this.addLanguagesWrapper.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.editEntry = this.editEntry.bind(this);

		this.columns = getLanguageColumns();
	}

	editEntry() {
		const { language, originalLanguage } = this.props;

		const editedValues = getEditedValues({
			oldData: originalLanguage,
			newData: language,
		});

		const payload = {
			id: language.id,
			description: editedValues.description ?? undefined,
			code: editedValues.code ?? undefined,
		};

		return editLanguage(payload);
	}

	editMultiple(selectedRows) {
		const { language } = this.props;

		const payload = {
			...(language.description && {
				description: language.description,
			}),
			...(language.code && {
				code: language.code,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editLanguages({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteLanguages(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			code: data.code,
			singleEdit: true,
		};

		this.props.setLanguages(payload);
	}

	getEditableCells() {
		const { language } = this.props;

		return [
			...(language.singleEdit
				? [
						{
							header: 'Name',
							value: <span>{language.name}</span>,
						},
				  ]
				: []),
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={language.description}
						onChange={(e) => this.editStoreEntry('description', e)}
					/>
				),
			},
			{
				header: 'Code',
				value: (
					<Input
						id="code"
						placeholder="Enter code..."
						value={language.code}
						onChange={(e) => this.editStoreEntry('code', e)}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const { updateLanguages } = this.props;

		const value = e.target.value;

		const updateObject = {
			[name]: value,
		};

		updateLanguages(updateObject);
	}

	fetchData(state) {
		return fetchLanguages(state);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	addLanguagesWrapper(data) {
		return addLanguages(data).then(() =>
			this.setState(() => ({
				key: moment.utc(),
			}))
		);
	}

	render() {
		const {
			batchList,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={reduxKey}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					accessAreasAllowedToEdit={['Global Sales Configuration']}
					actionRender={
						<Button
							type="inverted"
							label="Batch"
							shadow
							onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
				/>

				<LanguagesModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addLanguages={this.addLanguagesWrapper}
				/>
			</>
		);
	}
}

Languages.propTypes = {
	language: PropTypes.object,
	updateLanguages: PropTypes.func,
	setLanguages: PropTypes.func,
	resetState: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	originalLanguage: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			editBatchListItem,
			removeBatchListItem,
			updateLanguages,
			setLanguages,
			resetState,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		language: store.globalAdministrationLanguages.data.language,
		originalLanguage: store.globalAdministrationLanguages.data.originalLanguage,
		batchList: store.globalAdministrationLanguages.data.batchList,
	};
};

export default connectWithStore(
	Languages,
	mapStateToProps,
	mapDispatchToProps
);
