import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './systemSoftwares.phrases';

/**
 * @function fetchSystemSoftwares
 * @description Fetch all system softwares
 * @param {object} state
 */
export function fetchSystemSoftwares(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/administration/system_softwares', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addSystemSoftwares
 * @description add system softwares
 */
export function addSystemSoftwares(data) {
	return post('/administration/system_softwares', data)
		.then((res) => {
			setFeedback(phrases.SYSTEM_SOFTWARES_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editSystemSoftware
 * @description edit system software
 */
export function editSystemSoftware(data) {
	return post(`/administration/system_softwares/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.SYSTEM_SOFTWARE_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function deleteSystemSoftware
 * @description delete system software
 */
export function deleteSystemSoftware(id) {
	return remove(`/administration/system_softwares/${id}`)
		.then((res) => {
			setFeedback(phrases.SYSTEM_SOFTWARES_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
