export const FETCHING_CLOCKINGS = 'CLOCKINGS/FETCHING_CLOCKINGS';
export const SET_CLOCKINGS = 'CLOCKINGS/SET_CLOCKINGS';
export const CLEAN_CLOCKINGS = 'CLOCKINGS/CLEAN_CLOCKINGS';
export const ALIGN_CLOCKINGS = 'CLOCKINGS/ALIGN_CLOCKINGS';
export const REMOVE_CLOCKING = 'CLOCKINGS/REMOVE_CLOCKING';
export const UPDATE_CLOCKING = 'CLOCKINGS/UPDATE_CLOCKING';
export const ADD_UNASSIGNED_CLOCKING = 'CLOCKINGS/ADD_UNASSIGNED_CLOCKING';
export const REMOVE_PERSON_CLOCKINS = 'CLOCKINGS/REMOVE_PERSON_CLOCKINS';
export const ADD_PERSON_CLOCKINS = 'CLOCKINGS/ADD_PERSON_CLOCKINS';


/**
 * @param {string} argument
 */
export function cleanClockings() {
	return {
		type: CLEAN_CLOCKINGS
	};
}

/**
 * @param {string} argument
 */
export function fetchingClockings(fetchingClockings) {
	return {
		type: FETCHING_CLOCKINGS,
		payload: {
			fetchingClockings
		}
	};
}

/**
 * @param {string} argument
 */
export function addUnassignedClocking(clocking) {
	return {
		type: ADD_UNASSIGNED_CLOCKING,
		payload: {
			clocking
		}
	};
}

/**
 * @param {string} argument
 */
export function setClockings(clockings) {
	return {
		type: SET_CLOCKINGS,
		payload: {
			clockings
		}
	};
}

/**
 * @param {string} argument
 */
export function updateClocking(clocking) {
	return {
		type: UPDATE_CLOCKING,
		payload: clocking
	};
}

/**
 * @param {string} argument
 */
export function removeClocking(payload) {
	return {
		type: REMOVE_CLOCKING,
		payload
	};
}

/**
 * @param {number} clockings
 */
export function addClockings(payload) {
	return {
		type: ADD_PERSON_CLOCKINS,
		payload
	};
}

/**
 * @param {number} clockings
 */
export function removePersonClockins(payload) {
	return {
		type: REMOVE_PERSON_CLOCKINS,
		payload
	};
}