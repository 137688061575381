import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import numeral from 'numeral';
import constants from './../constants';
import './tableCell.css';

/**
 * @function getModifierClassNames
 * @memberof TableCell
 * @description
 * Returns modifier classes as cx expression
 * @param  {object} data
 */
function getModifierClassNames(classnames = []) {
	if (classnames.length > 0) {
		return classnames.reduce((acc, modifierClass) => {
			const key = Object.keys(modifierClass)[0];
			acc[Object.keys(modifierClass)[0]] = modifierClass[key];
			return acc;
		}, {});
	} else return {};
}

/**
 * @function getContent
 * @memberOf Content
 * @description
 * Returns formatted content
 * @param  {object} data
 * @param  {object} column
 */
function getContent(content, type) {
	if (content) {
		switch (type) {
			case constants.CELL_TYPES.INTEGER:
				return numeral(parseFloat(content)).format('0,0');
			case constants.CELL_TYPES.INTEGER_DIVIDED_BY_THOUSAND:
				return `${numeral(parseFloat(content) / 1000).format('0,0')}K`;
			case constants.CELL_TYPES.PERCENTAGE:
				return `${numeral(parseFloat(content)).format('0.0')}%`;
			case constants.CELL_TYPES.PERCENTAGE_NO_DECIMAL:
				return `${numeral(parseFloat(content)).format('0')}%`;
			case constants.CELL_TYPES.CURRENCY:
				return numeral(parseFloat(content)).format('0,0.00');
			case constants.CELL_TYPES.CURRENCY_NO_DECIMAL:
				return numeral(parseFloat(content)).format('0,0');
			case constants.CELL_TYPES.NUMBER:
				return numeral(parseFloat(content)).format('0,0.00');
			case constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL:
				return numeral(parseFloat(content)).format('0,0.0');
			case constants.CELL_TYPES.NUMBER_NO_DECIMAL:
				return numeral(parseFloat(content)).format('0.0');
			default:
				return content;
		}
	} else return '\u00a0';
}

const TableCell = React.memo(function tableCell({
	content,
	colSpan,
	cellType,
	separate,
	alignLeft,
	alignRight,
	classNames,
	uppercase,
	style,
	width,
}) {
	const cx = classnames('report-td', {
		'report-td--separate': separate,
		'report-td--align-left': alignLeft,
		'report-td--align-right': alignRight,
		'report-td--uppercase': uppercase,
		...getModifierClassNames(classNames),
	});

	const val = typeof content === 'function' ? content() : content;

	return (
		<td colSpan={colSpan} style={{ ...style }} className={cx}>
			<span style={{ width, maxWidth: width, minWidth: width }}>
				{getContent(val, cellType)}
			</span>
		</td>
	);
});

TableCell.displayName = 'TableCell';

TableCell.defaultProps = {
	content: '',
	separate: false,
	alignLeft: false,
	alignRight: false,
	uppercase: false,
	colSpan: null,
	style: {},
	width: null,
	cellType: 'string',
	classNames: [],
};

TableCell.propTypes = {
	content: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.func,
		PropTypes.number,
	]),
	colSpan: PropTypes.number,
	cellType: PropTypes.string,
	classNames: PropTypes.array,
	separate: PropTypes.bool,
	style: PropTypes.object,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	alignLeft: PropTypes.bool,
	uppercase: PropTypes.bool,
	alignRight: PropTypes.bool,
};

export { TableCell };
