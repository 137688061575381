// lodash
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';

// constants
import constants from 'services/constants';
import enums from './../shiftClockins.enums';
import phrases from './../shiftClockins.phrases';

import moment from 'moment';

export function getFilter({ data, statusFilter }) {
	const marketFilter = data?.Markets?.results?.[0]?.value
		? `:workplace.market.id=='${data?.Markets?.results?.[0]?.value}'`
		: null;

	const storeFilter = data?.Stores?.results?.[0]?.value
		? `:workplace.id=='${data?.Stores?.results?.[0]?.value}'`
		: null;

	let employmentIds;
	if (!_isEmpty(data.Employees.results)) {
		employmentIds = data.Employees.results.reduce(
			(acc, currentValue, index) => {
				acc =
					index === data.Employees.results.length - 1
						? `${acc}'${currentValue.value}']`
						: `${acc}'${currentValue.value}',`;

				return acc;
			},
			'['
		);
	}

	let filter;
	if (storeFilter) filter = storeFilter;
	if (marketFilter)
		filter = filter ? `${filter};${marketFilter}` : marketFilter;

	const startDate =
		data.Period?.results?.startDate?.format?.(constants.shortDate) || null;
	const endDate =
		data.Period?.results?.endDate?.format?.(constants.shortDate) || null;

	return { filter, startDate, endDate, employmentIds, statusFilter };
}

export function getStatusFilterOptions() {
	return [
		{
			label: phrases.ALIGNED,
			value: enums.STATUS_FILTER_ALIGNED,
		},
		{
			label: phrases.UNALIGNED,
			value: enums.STATUS_FILTER_UNALIGNED,
		},
	];
}

/**
 * @function getBreakRegistrationPossible
 * @param {Object} hotbar - we're interested in Markets and Stores objects
 * @description figures out if we can show break columns or not
 * @returns finds key from market, if no markets, returns true
 */
export function getBreakRegistrationPossible(hotbar) {
	// first check for markets
	if (_isArray(hotbar.Markets?.results) && !_isEmpty(hotbar.Markets?.results)) {
		const breakRegistrationPossible =
			hotbar.Markets?.results?.[0]?.market?.break_registration;

		return !!breakRegistrationPossible;
	}

	// if not market selected, check under store's market
	if (_isArray(hotbar.Stores?.results) && !_isEmpty(hotbar.Stores?.results)) {
		const breakRegistrationPossible =
			hotbar.Stores?.results?.[0]?.market?.break_registration;

		return !!breakRegistrationPossible;
	}

	return true;
}

function _formatDateDown(date) {
	if (!date) return null;

	return moment
		.utc(date, constants.dateFormat)
		.startOf('minute')
		.format(constants.dateFormat);
}

/**
 * @function formatShiftClockins
 * @param {Array} data
 * @description runs through every date value we will use and rounds it down to start of the minute
 * @returns {Array}
 */
export function formatShiftClockins(data) {
	return data.map((entry) => {
		// edge case: is the final value is the clocked value, we take the clocked value with seconds. Thats the only case. So only when we click the clocked and doesnt change it afterwards
		let finalValueFromIsClockedValue = false;
		if (
			entry.clocked.from !== null &&
			entry.final.from !== null &&
			entry.clocked.from === entry.final.from
		)
			finalValueFromIsClockedValue = true;
		let finalValueToIsClockedValue = false;
		if (
			entry.clocked.to !== null &&
			entry.final.to !== null &&
			entry.clocked.to === entry.final.to
		)
			finalValueToIsClockedValue = true;

		return {
			...entry,
			planned: {
				from: _formatDateDown(entry.planned.from),
				to: _formatDateDown(entry.planned.to),
			},
			clocked: {
				from: finalValueFromIsClockedValue
					? entry.clocked.from
					: _formatDateDown(entry.clocked.from),
				to: finalValueToIsClockedValue
					? entry.clocked.to
					: _formatDateDown(entry.clocked.to),
			},
			final: {
				from: finalValueFromIsClockedValue
					? entry.clocked.from
					: _formatDateDown(entry.final.from),
				to: finalValueToIsClockedValue
					? entry.clocked.to
					: _formatDateDown(entry.final.to),
			},
			breaks: entry.breaks.map((entry) => ({
				...entry,
				planned: {
					from: _formatDateDown(entry.planned.from),
					to: _formatDateDown(entry.planned.to),
				},
				clocked: {
					from: _formatDateDown(entry.clocked.from),
					to: _formatDateDown(entry.clocked.to),
				},
				final: {
					from: _formatDateDown(entry.final.from),
					to: _formatDateDown(entry.final.to),
				},
			})),
		};
	});
}

/**
 * @function getNoShowBreaksToPropagate
 * @param {Object} row (row we want to no-show breaks for)
 * @description Sets final break values to planned periods
 * @returns {Object}
 */
export function getNoShowBreaksToPropagate({ row }) {
	const rowUpdatedBreaks = row.breaks.map((entry) => {
		// find break's planned from period
		const plannedFrom = entry.planned.from || null;

		// if no planned period, don't no-show that break (nothing to no show it on)
		if (!plannedFrom) return entry;

		return {
			...entry,
			final: {
				from: plannedFrom,
				to: plannedFrom,
			},
		};
	});

	// merge new updated breaks into the row
	const rowToReturn = {
		...row,
		breaks: rowUpdatedBreaks,
	};

	return rowToReturn;
}
