import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './weekNumber.css';

export default class DateWeekNumber extends PureComponent {
	render() {
		const { day, showWeekNumber } = this.props;

		return (
			<>
				{showWeekNumber &&
					day.isSame(day.clone().startOf('isoweek'), 'day') && (
						<span className="j-date-week-number">
							{day.format('W')}
						</span>
					)}
				{day.format('D')}{' '}
			</>
		);
	}
}

DateWeekNumber.propTypes = {
	showWeekNumber: PropTypes.bool,
	day: PropTypes.object
};
