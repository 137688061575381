'use strict';

/**
 * @function initHeaderPermissions
 * @memberOf SERVICES.__models
 * @description
 * Adds default header permissions to all headers
 * @param  {array} headers headers to add permissions to
 */

import _isUndefined from 'lodash/isUndefined';
import _each from 'lodash/each';

module.exports = function(headers) {
	_each(headers, function(header) {
		header.canFilter = _isUndefined(header.canFilter) ? true : header.canFilter;
		header.canSort = _isUndefined(header.canSort) ? true : header.canSort;
		header.canAdd = _isUndefined(header.canAdd) ? false : header.canAdd;
		header.canEdit = _isUndefined(header.canEdit) ? false : header.canEdit;
		header.canCreate = _isUndefined(header.canCreate) ? false : header.canCreate;
	});
	this.setHeaders(headers);
};