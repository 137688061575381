export default {
	TABLE_TITLE: 'Raw ingredient configuration raw ingredients',
	RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS_ADDED:
		'Raw ingredient configuration raw ingredients added',
	RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENT_EDITED:
		'Raw ingredient configuration raw ingredient edited',
	RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS_EDITED:
		'Raw ingredient configuration raw ingredients edited',
	RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS_DELETED:
		'Raw ingredient configuration raw ingredients deleted',

	// modal batch stuff
	MODAL_BATCH_STEP_LIST_TITLE: 'Add Raw ingredient configurations',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'submit',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_GLOBAL_RAW_INGREDIENT: 'Global raw ingredient',
	MODAL_BATCH_RAW_INGREDIENT: 'Raw ingredient',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row.',
	MODAL_BATCH_STEP_FORM_TITLE: 'Add Raw ingredients',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'Next',
	MODAL_BATCH_STEP_LIST_CANCEL_BUTTON_LABEL: 'Back',
	MODAL_BATCH_STEP_RAW_INGREDIENT: 'Raw ingredient',
	MODAL_BATCH_STEP_RAW_INGREDIENT_PLACEHOLDER: 'Select Raw ingredient...',
	MODAL_BATCH_TABLE_TITLE: 'Raw ingredients'
};
