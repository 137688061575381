import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchTags
 * @description Fetch all tags values
 * @param {object} state
 */
export function fetchTags(state, extraFilter) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/tagging/tags', params)
		.then((res) => res)
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addTag
 * @description add a tag
 */
export function addTag(data) {
	return post('/tagging/tags', data)
		.then((res) => {
			setFeedback('Tag added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editTag
 * @description edit a single tag
 */
export function editTag(data) {
	return post(`/tagging/tags/${data.id}`, data)
		.then((res) => {
			setFeedback('Tag edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteTag
 * @description delete a tag
 */
export function deleteTag(productId) {
	return remove(`/tagging/tags/${productId}`)
		.then((res) => {
			setFeedback('Tag deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
