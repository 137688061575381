// actions
import {
	SET_TENANTS,
	SET_TENANT_OPTIONS,
	SET_UI_DATA,
	RESET_DATA,
	SET_EMPLOYMENT_TERMINATED,
} from './tenantTransferModal.actions';

// utils
import moment from 'moment';
import constants from 'services/constants';

const defaultState = {
	// list of all tenants to choose from
	tenants: [],
	// tenant options - companies, available tenants, users...
	tenantOptions: {},
	// state that is being set by terminateEmployee.container when we terminate the employee. It can be a boolean of undefined
	// when our tenantTransferModal component detects a change from undefined to true/false it shows the appropriate screen where the user can navigate
	// to either last step or submit the tenant transfer
	employmentTerminated: undefined,
	ui: {
		tenant: null,
		user: null,
		company: null,
		workplace: null,
		startDate: moment.utc().format(constants.shortDate),
		endDate: '9999-12-31',
		hasFoodPermit: false,
		inSalaryExport: false,
		administrativeOnly: false,
		appAllowance: null,
		salaryExportId: '',
		email: '',
		phone: '',
		phoneCountryCode: '',
		terminateCurrentEmployment: false,
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_EMPLOYMENT_TERMINATED:
			return {
				...state,
				employmentTerminated: payload,
			};

		case SET_TENANTS: {
			return {
				...state,
				tenants: payload,
			};
		}

		case SET_TENANT_OPTIONS: {
			return {
				...state,
				tenantOptions: payload,
			};
		}

		case SET_UI_DATA: {
			return {
				...state,
				ui: {
					...state.ui,
					...payload,
				},
			};
		}

		case RESET_DATA: {
			return defaultState;
		}

		default:
			return state;
	}
}

export default reducer;
