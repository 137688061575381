export default {
	TITLE: 'System softwares',
	SYSTEM_SOFTWARES_ADDED: 'System softwares added!',
	SYSTEM_SOFTWARE_EDITED: 'System software edited!',
	SYSTEM_SOFTWARES_DELETED: 'System softwares deleted!',

	// sub tables
	WORKPLACE_SYSTEM_SOFTWARE_VERSIONS: 'Workplace system softwares versions',
	SYSTEM_SOFTWARE_VERSIONS: 'System software versions',

	// batch form
	MODAL_BATCH_STEP_LIST_TITLE: 'Add system softwares',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_TITLE: 'Title',
	MODAL_BATCH_STEP_TITLE_PLACEHOLDER: 'Enter title...',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add entries',
};
