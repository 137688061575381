import React from 'react';

import { Input } from 'dumb';

import _get from 'lodash/get';

export function getTableColumns() {
	return [
		{
			Header: 'Name',
			id: 'name',
			accessor: 'name',
			filterPath: ':name',
		},
		{
			Header: 'Description',
			id: 'description',
			accessor: 'description',
			filterPath: ':description',
		},
		{
			Header: 'Type',
			id: 'type',
			accessor: 'type',
			filterPath: ':type',
		},
		{
			Header: 'Sort order',
			id: 'sortOrder',
			accessor: 'sort_order',
			filterPath: ':sort_order',
		},
		{
			Header: 'Replacement 1',
			id: 'group_1',
			accessor: (d) => _get(d, 'group_1.name', ''),
			filterPath: ':group_1.name',
		},
		{
			Header: 'Replacement 2',
			id: 'group_2',
			accessor: (d) => _get(d, 'group_2.name', ''),
			filterPath: ':group_2.name',
		},
		{
			Header: 'Replacement 3',
			id: 'group_3',
			accessor: (d) => _get(d, 'group_3.name', ''),
			filterPath: ':group_3.name',
		},
		{
			Header: 'Replacement 4',
			id: 'group_4',
			accessor: (d) => _get(d, 'group_4.name', ''),
			filterPath: ':group_4.name',
		},
		{
			Header: 'Replacement 5',
			id: 'group_5',
			accessor: (d) => _get(d, 'group_5.name', ''),
			filterPath: ':group_5.name',
		},
		{
			Header: 'Replacement 6',
			id: 'group_6',
			accessor: (d) => _get(d, 'group_6.name', ''),
			filterPath: ':group_6.name',
		},
		{
			Header: 'Replacement 7',
			id: 'group_7',
			accessor: (d) => _get(d, 'group_7.name', ''),
			filterPath: ':group_7.name',
		},
		{
			Header: 'Replacement 8',
			id: 'group_8',
			accessor: (d) => _get(d, 'group_8.name', ''),
			filterPath: ':group_8.name',
		},
		{
			Header: 'Replacement 9',
			id: 'group_9',
			accessor: (d) => _get(d, 'group_9.name', ''),
			filterPath: ':group_9.name',
		},
		{
			Header: 'Replacement 10',
			id: 'group_10',
			accessor: (d) => _get(d, 'group_10.name', ''),
			filterPath: ':group_10.name',
		},
		{
			Header: 'Replacement 11',
			id: 'group_11',
			accessor: (d) => _get(d, 'group_11.name', ''),
			filterPath: ':group_11.name',
		},
		{
			Header: 'Replacement 12',
			id: 'group_12',
			accessor: (d) => _get(d, 'group_12.name', ''),
			filterPath: ':group_12.name',
		},
		{
			Header: 'Replacement 13',
			id: 'group_13',
			accessor: (d) => _get(d, 'group_13.name', ''),
			filterPath: ':group_13.name',
		},
		{
			Header: 'Replacement 14',
			id: 'group_14',
			accessor: (d) => _get(d, 'group_14.name', ''),
			filterPath: ':group_14.name',
		},
		{
			Header: 'Replacement 15',
			id: 'group_15',
			accessor: (d) => _get(d, 'group_15.name', ''),
			filterPath: ':group_15.name',
		},
		{
			Header: 'Legacy',
			id: 'legacy',
			filterPath: ':legacy',
			filterable: false,
			Cell: (d) => (
				<Input
					id={`${d.original?.id}-legacy`}
					type="checkbox"
					disabled
					checked={d.original?.legacy}
				/>
			),
		},
	];
}
