export const RESET_STATE =
	'GLOBAL_ADMINISTRATION_STORE_ACCESS_TYPES/RESET_STATE';
export const SET_STORE_ACCESS_TYPE =
	'GLOBAL_ADMINISTRATION_STORE_ACCESS_TYPES/SET_STORE_ACCESS_TYPE';
export const UPDATE_STORE_ACCESS_TYPE =
	'GLOBAL_ADMINISTRATION_STORE_ACCESS_TYPES/UPDATE_STORE_ACCESS_TYPE';

export const EDIT_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_STORE_ACCESS_TYPES/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_STORE_ACCESS_TYPES/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'GLOBAL_ADMINISTRATION_STORE_ACCESS_TYPES/ADD_BATCH_LIST_ITEM_ROW';

export function setStoreAccessType(payload) {
	return {
		type: SET_STORE_ACCESS_TYPE,
		payload,
	};
}

export function updateStoreAccessType(payload) {
	return {
		type: UPDATE_STORE_ACCESS_TYPE,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}
