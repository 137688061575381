'use strict';

import { HotbarComponentResultSetAbstract } from 'hotdamnbar/resultSets';

class HotbarDateRangeResultSet extends HotbarComponentResultSetAbstract {

	constructor(component) {
		super(component);

		// Set optional end date
		this.optionalEndDate = component.optionalEndDate;
	}

	/**
	 * @function getStartDate
	 * @memberOf HotbarDateRangeResultSet
	 * @description Gets start date from date range
	 */
	getStartDate() {
		return this.results !== null ? this.results.startDate : null;
	}

	/**
	 * @function getEndDate
	 * @memberOf HotbarDateRangeResultSet
	 * @description Gets end date from date range
	 */
	getEndDate() {
		return this.results !== null ? (
			this.optionalEndDate && this.results.endDate === null ? this.results.startDate : this.results.endDate
		) : null;
	}

}

export default HotbarDateRangeResultSet;