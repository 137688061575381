import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { isMobile } from 'detectMobile.vanilla';
import _throttle from 'lodash/throttle';
import { Backdrop, Icon } from 'dumb';
import './overlay.css';

// function overLayComponent(ComponentInOverlay) {
class Overlay extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			height: props.height,
			dragging: false,
		};

		this.onMouseDown = this.onMouseDown.bind(this);
		this.onMouseUp = this.onMouseUp.bind(this);
		this.onMouseMove = _throttle(this.onMouseMove, 17, {
			trailing: true,
		}).bind(this);
		this._onkeyDown = this._onkeyDown.bind(this);
		this._handleClickOutside = this._handleClickOutside.bind(this);
		this.onEnter = this.onEnter.bind(this);
		this._getContent = this._getContent.bind(this);

		// get modal-root element to create portal.
		this.rootElement = document.getElementById('overlay-root');
	}

	// we could get away with not having this (and just having the listeners on
	// our div), but then the experience would be possibly be janky. If there's
	// anything w/ a higher z-index that gets in the way, then you're toast,
	// etc.
	componentDidUpdate(props, state) {
		if (this.state.dragging && !state.dragging) {
			document.addEventListener('mousemove', this.onMouseMove);
			document.addEventListener('mouseup', this.onMouseUp);
		} else if (!this.state.dragging && state.dragging) {
			document.removeEventListener('mousemove', this.onMouseMove);
			document.removeEventListener('mouseup', this.onMouseUp);
		}
	}

	_onkeyDown(event) {
		// if escape is pressed and any select element is not open
		if (event.keyCode === 27 && this.props.onClose && !this.props.disableKeys) {
			document.removeEventListener('keydown', this._onkeyDown);
			this.props.onClose();
		}
	}

	// calculate relative position to the mouse and set dragging=true
	onMouseDown(e) {
		// only left mouse button
		if (e.button !== 0) return;
		this.setState(() => ({
			dragging: true,
		}));
		this.props.isDragging(true);
		e.stopPropagation();
		e.preventDefault();
	}

	onMouseUp(e) {
		this.setState(() => ({
			dragging: false,
		}));
		this.props.isDragging(false);
		e.stopPropagation();
		e.preventDefault();
	}

	onMouseMove(e) {
		if (!this.state.dragging) return;

		const height = window.innerHeight - e.clientY;

		// Dont allow overlay to be smaller than 50 px in height
		if (height < 50) return;

		this.setState(
			() => ({ height }),
			() => {
				this.props.onDragHeight(height);
			}
		);

		e.stopPropagation();
		e.preventDefault();
	}

	onEnter() {
		// Esc
		document.addEventListener('keydown', this._onkeyDown, false);
	}

	onExit() {
		document.removeEventListener('keydown', this._onkeyDown);
	}

	_handleClickOutside() {
		const { onClose } = this.props;

		if (onClose) {
			onClose();
		}
	}

	_getContent() {
		const {
			visible,
			style,
			zIndex,
			children,
			list,
			noBackdrop,
			onClose,
			render,
		} = this.props;
		const { height, dragging } = this.state;

		const overlayClassnames = cx('overlay', {
			'overlay--active': visible,
			'overlay--dragging': dragging,
			'overlay--list': list,
		});

		const styles = zIndex
			? {
					height: `${height}px`,
					zIndex: zIndex,
					...style,
			  }
			: { height: `${height}px`, ...style };

		return (
			<CSSTransition
				classNames="overlay-transition"
				component="div"
				timeout={500}
				in={visible}
				mountOnEnter
				appear
				onExit={this.onExit}
				onEnter={this.onEnter}
				unmountOnExit
				style={styles}
				className={overlayClassnames}>
				<div>
					<div className="overlay__inner">
						<div
							className="overlay__inner__handle"
							ref={(elm) => (this.handle = elm)}
							onMouseDown={this.onMouseDown}
						/>
						{noBackdrop && (
							<Icon
								className="overlay__inner__close-icon"
								name="close"
								onClick={onClose}
							/>
						)}
						{render ? render(height) : children}
					</div>
				</div>
			</CSSTransition>
		);
	}

	render() {
		const { visible, zIndex, onClose, noBackdrop } = this.props;

		return ReactDOM.createPortal(
			<>
				{noBackdrop ? (
					this._getContent()
				) : (
					<Backdrop
						zIndex={zIndex}
						isOpen={visible}
						closeBackdrop={() => onClose()}>
						{this._getContent()}
					</Backdrop>
				)}
			</>,
			this.rootElement
		);
	}
}

Overlay.defaultProps = {
	visible: false,
	height: 200,
	onDragHeight: () => {},
	isDragging: () => {},
	noBackdrop: false,
};

Overlay.propTypes = {
	visible: PropTypes.bool.isRequired,
	style: PropTypes.object,
	height: PropTypes.number.isRequired,
	onDragHeight: PropTypes.func,
	isDragging: PropTypes.func,
	zIndex: PropTypes.number,
	children: PropTypes.any,
	onClose: PropTypes.func,
	list: PropTypes.bool,
	disableKeys: PropTypes.bool,
	noBackdrop: PropTypes.bool,
	render: PropTypes.func,
};

export default Overlay;
