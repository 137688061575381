export const UPDATE_MARKET_PAYROLL_PERIOD =
	'ORGANISATION_MARKET_PAYROLL_PERIODS/UPDATE_MARKET_PAYROLL_PERIOD';
export const RESET_STATE = 'ORGANISATION_MARKET_PAYROLL_PERIODS/RESET_STATE';
export const SET_MARKET_PAYROLL_PERIOD =
	'ORGANISATION_MARKET_PAYROLL_PERIODS/SET_MARKET_PAYROLL_PERIOD';

export function updateMarketPayrollPeriod(payload) {
	return {
		type: UPDATE_MARKET_PAYROLL_PERIOD,
		payload,
	};
}

export function setMarketPayrollPeriod(payload) {
	return {
		type: SET_MARKET_PAYROLL_PERIOD,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}
