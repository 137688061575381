'use strict';

import { HotbarComponentResultSetAbstract } from 'hotdamnbar/resultSets';

class HotbarNumberResultSet extends HotbarComponentResultSetAbstract {

	constructor(component) {
		super(component);

		// Set results
		this.results = {
			value: isNaN(parseInt(component.getSelectedValues(), 10))
				? ''
				: parseInt(component.getSelectedValues(), 10)
		};
	}
}

export default HotbarNumberResultSet;