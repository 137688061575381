import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DailyReportBaseModel from './dailyReportBaseModel.component';
import _isEmpty from 'lodash/isEmpty';
import _uniqueId from 'lodash/uniqueId';
import sortBySortOrder from './../utilities/sortBySortOrder';
import groupSortOrder from './../utilities/groupSortOrder';
import DailyReportHeaderBaseModel from './dailyReportHeaderBaseModel.component';
import dailyReportConstants from './../dailyReport.constants';
import { toggleExpandGroupWithinMarket } from './../dailyReport.actions';
import { store } from 'appState';

// Components
import { Body, Row, Table, Footer } from 'dumb/report';

class DailyReportMarket extends PureComponent {
	/**
	 * @method _onExpandHandler
	 * @memberof DailyReportMarket
	 * @param {Object} entry - The clicked row
	 */
	_onExpandHandler(entry) {
		store.dispatch(toggleExpandGroupWithinMarket(entry.id));
	}

	_renderWorkplaces(workplaces) {
		const workplacesTable = workplaces
			.sort(sortBySortOrder)
			.map((workplace, index) => {
				const component = (
					<Row key={workplace.id} index={index} type="entry">
						<DailyReportBaseModel
							entry={workplace}
							reportType={this.props.hotbarData.perspective}
						/>
					</Row>
				);

				return component;
			});

		return <Body>{workplacesTable}</Body>;
	}

	_renderStoreAreaTypeEntries() {
		const {
			territoryStoreAreaTypeEntries,
			workplaces,
			totalColSpan,
		} = this.props;

		const workplacesFiltered = workplaces.filter(
			(workplace) => workplace.group !== 'Adjustment'
		);

		return (territoryStoreAreaTypeEntries || [])
			.sort(sortBySortOrder)
			.map((territoryStoreAreaTypeEntry, index) => {
				return (
					<Row
						key={territoryStoreAreaTypeEntry.id}
						type="entry"
						index={index}
						totalColSpan={totalColSpan}
						onClick={() => this._onExpandHandler(territoryStoreAreaTypeEntry)}
						expanded={
							this.props.expandedGroups.includes(
								territoryStoreAreaTypeEntry.id
							) || this.props.forceExpandGroups
						}
						renderExpandedHeader={false}
						renderExpanded={() => {
							const marketGroup = workplacesFiltered
								.sort(sortBySortOrder)
								.map((workplace) =>
									workplace.store_area_type.name ===
									territoryStoreAreaTypeEntry.store_area_type.name ? (
										<Row
											key={workplace.id}
											type="entry"
											onClick={() =>
												this._onExpandHandler(territoryStoreAreaTypeEntry)
											}
											index={_uniqueId('territoryStoreAreaTypeEntry_')}>
											<DailyReportBaseModel
												entry={workplace}
												reportType={this.props.hotbarData.perspective}
											/>
										</Row>
									) : null
								)
								.filter((i) => i); // filter out false or undefined values

							const entry = {
								...territoryStoreAreaTypeEntry,
								...(territoryStoreAreaTypeEntry.group === 'New Store' && {
									month_same_store_sales: {},
									week_same_store_sales: {},
								}),
							};

							marketGroup.push(
								<Row key={territoryStoreAreaTypeEntry.id} type="group-total">
									<DailyReportBaseModel
										totalColSpan={totalColSpan}
										entry={entry}
										reportType={this.props.hotbarData.perspective}
									/>
								</Row>
							);

							return (
								<Table>
									<Body>{marketGroup}</Body>
								</Table>
							);
						}}>
						<DailyReportBaseModel
							entry={territoryStoreAreaTypeEntry}
							reportType={this.props.hotbarData.perspective}
						/>
					</Row>
				);
			});
	}

	_renderGroups() {
		const { groups, workplaces, totalColSpan } = this.props;

		let manualIndex = 0;
		return groups.map((group, index) => {
			// Dont show if group name is 'Not Available'
			if (group.group === 'Not Available') return;

			return (
				<Row
					key={group.id}
					type="entry"
					index={index}
					totalColSpan={totalColSpan}
					onClick={() => this._onExpandHandler(group)}
					expanded={
						this.props.expandedGroups.includes(group.id) ||
						this.props.forceExpandGroups
					}
					renderExpandedHeader={false}
					renderExpanded={() => {
						const marketGroup = workplaces
							.sort(sortBySortOrder)
							.map((workplace) => {
								if (workplace.group === group.group) {
									const component = (
										<Row key={workplace.id} type="entry" index={manualIndex}>
											<DailyReportBaseModel
												entry={workplace}
												reportType={this.props.hotbarData.perspective}
											/>
										</Row>
									);
									manualIndex = manualIndex + 1;
									return component;
								} else return false;
							})
							.filter((i) => i); // filter out false or undefined values

						const entry = {
							...group,
							name: groupSortOrder[group.group].name,
							...(group.group === 'New Store' && {
								month_same_store_sales: {},
								week_same_store_sales: {},
							}),
						};

						marketGroup.push(
							<Row key={group.id} type="group-total">
								<DailyReportBaseModel
									totalColSpan={totalColSpan}
									entry={entry}
									reportType={this.props.hotbarData.perspective}
								/>
							</Row>
						);

						return (
							<Table>
								<Body>{marketGroup}</Body>
							</Table>
						);
					}}>
					<DailyReportBaseModel
						entry={group}
						reportType={this.props.hotbarData.perspective}
					/>
				</Row>
			);
		});
	}

	_renderWithGroups() {
		return (
			<Body>
				{this._renderGroups()}
				{this._renderStoreAreaTypeEntries()}
			</Body>
		);
	}

	_renderGroupsAndWorkplaces() {
		const { isYoungMarket, workplaces } = this.props;

		return isYoungMarket
			? this._renderWorkplaces(workplaces)
			: this._renderWithGroups();
	}

	_renderTotal() {
		return !_isEmpty(this.props.total) ? (
			<Footer>
				<Row key={this.props.total.id} type="total" borderBottom>
					<DailyReportBaseModel
						entry={this.props.total}
						reportType={this.props.hotbarData.perspective}
					/>
				</Row>
			</Footer>
		) : null;
	}

	render() {
		const { hotbarData } = this.props;

		return (
			<Table>
				<DailyReportHeaderBaseModel
					reportType={hotbarData.perspective}
					firstColumnEntry="Stores"
				/>
				{this._renderGroupsAndWorkplaces()}
				{this._renderTotal()}
			</Table>
		);
	}
}

DailyReportMarket.propTypes = {
	hotbarData: PropTypes.object,
	workplaces: PropTypes.array,
	groups: PropTypes.array,
	totalColSpan: PropTypes.number,
	isYoungMarket: PropTypes.bool,
	expandedGroups: PropTypes.array,
	territoryStoreAreaTypeEntries: PropTypes.array,
	total: PropTypes.object,
	forceExpandGroups: PropTypes.bool,
};

DailyReportMarket.defaultProps = {
	reportData: {},
	isYoungMarket: true,
	expandedGroups: [],
};

export default DailyReportMarket;
