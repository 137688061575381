'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { Input } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';

// utils0
import _get from 'lodash/get';

import { setAllergen, updateAllergen, resetState } from './store/allergens.actions';
import { fetchAllergens, editAllergens, addAllergens, deleteAllergens } from './allergens.service';

// Wrapper for react-data
// Documentation can be found here https://react-table.js.org/
class Allergenes extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);

		this.state = {
			pages: null,
		};

		this.fetchData = this.fetchData.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
		];
	}

	deleteEntry(id) {
		return deleteAllergens(id);
	}

	editEntry() {
		const { defaultValues, allergenCategory } = this.props;

		const payload = {
			name: _get(defaultValues, 'name', ''),
			allergen_category: allergenCategory.id,
			id: defaultValues.id,
		};

		return editAllergens(defaultValues.id, payload);
	}

	addEntry() {
		const { defaultValues, allergenCategory } = this.props;

		const payload = {
			allergen_category: allergenCategory.id,
			name: _get(defaultValues, 'name', ''),
		};

		return addAllergens(payload);
	}

	setInitialEditValues(data) {
		this.props.setAllergen(data);
	}

	getEditableCells(e) {
		const { defaultValues } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="name"
						value={_get(defaultValues, 'name', '')}
						onChange={(event) => this.editStoreEntry(event, 'name')}
					/>
				),
			},
		];
	}

	editStoreEntry(e, type) {
		const { defaultValues } = this.props;
		const value = e.target ? e.target.value : e;

		const updateObject = {
			name: defaultValues.name,
			allergen_category: defaultValues.allergen_category,
		};

		this.props.updateAllergen({
			...updateObject,
			...{ [type]: value },
		});
	}

	fetchData(state) {
		const { allergenCategory } = this.props;
		return fetchAllergens(allergenCategory.id, state);
	}

	render() {
		return (
			<ReactDataWrapper
				accessAreasAllowedToEdit={['Sales Configuration']}
				title={this.props.title}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={10}
				reduxKey="product/allergens"
				manual // Forces table not to paginate or sort automatically, so we can handle it server-side
				editableCells={this.getEditableCells()}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetState}
				deleteEntry={this.deleteEntry}
				createEntry={this.addEntry}
			/>
		);
	}
}

Allergenes.propTypes = {
	defaultValues: PropTypes.object,
	allergenCategory: PropTypes.object,
	title: PropTypes.string,
	updateAllergen: PropTypes.func,
	setAllergen: PropTypes.func,
	resetState: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setAllergen,
			updateAllergen,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultValues: store.allergens.data.defaultAllergen,
	};
};

export default connectWithStore(Allergenes, mapStateToProps, mapDispatchToProps);
