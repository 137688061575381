import React from 'react';
import PropTypes from 'prop-types';

// components
import { Button, SectionCollapsable } from 'dumb';
import { ModalRow } from 'dumb/modal';

// phrases/ enums
import globalPhrases from './../../../../../shiftTransferApproval.phrases';
import phrases from './../../../shiftTransferApprovalMobileView.phrases';
import { ShiftTransfer as enums } from 'services/enums';

const ShiftTransferPendingTransfersMobalViewPageApplied = ({
	shiftTransfer,
	canCancelTransfer,
	canReapplyForShift,
	updateShiftTransferApplicationWrapper,
}) => {
	const handlePendingShiftTransfer = (operation) => {
		const payload = {
			operation,
			shiftTransfer,
		};

		updateShiftTransferApplicationWrapper(payload);
	};

	if (!canCancelTransfer && !canReapplyForShift) return null;

	return (
		<ModalRow className="shift-transfer-approval-modal__mobile-view__section">
			<SectionCollapsable title={phrases.ACTIONS}>
				<div className="shift-transfer-approval-modal__mobile-view__action">
					{canCancelTransfer && (
						<Button
							shadow
							type="negative"
							onClick={() =>
								handlePendingShiftTransfer(enums.OPERATIONS.DECLINE)
							}>
							{globalPhrases.CANCEL_TRANSFER}
						</Button>
					)}
					{canReapplyForShift && (
						<Button
							shadow
							type="success"
							onClick={() =>
								handlePendingShiftTransfer(enums.OPERATIONS.ACCEPT)
							}>
							{globalPhrases.REAPPLY_FOR_TRANSFER}
						</Button>
					)}
				</div>
			</SectionCollapsable>
		</ModalRow>
	);
};

ShiftTransferPendingTransfersMobalViewPageApplied.propTypes = {
	shiftTransfer: PropTypes.object,
	canCancelTransfer: PropTypes.bool,
	canReapplyForShift: PropTypes.bool,
	updateShiftTransferApplicationWrapper: PropTypes.func,
};

export default ShiftTransferPendingTransfersMobalViewPageApplied;
