// interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';
import { IDynamicAccountCRUD, IDynamicAccountBatchCRUD } from './types';

import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './dynamicAccounts.phrases';

function handleError(err: Error): Error {
	const error = formatErrorMessage(err);
	setFeedback(error, 0);

	throw error;
}

/**
 * @function fetchDynamicAccounts
 * @description Fetch all companies
 * @param {object} state
 */
export function fetchDynamicAccounts(state: IFetchDataProps): Promise<IApiRes> {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/financial/dynamics_accounts', params, null, state.headers)
		.then((res: IApiRes) => res)
		.catch(handleError);
}

/**
 * @function addDynamicAccounts
 * @description adds dynamicAccount
 */
export function addDynamicAccounts(
	data: IDynamicAccountBatchCRUD
): Promise<IApiRes> {
	return post('/financial/dynamics_accounts', data)
		.then((res: IApiRes) => {
			setFeedback(phrases.DYNAMIC_ACCOUNT_ADDED, 1);
			return res;
		})
		.catch(handleError);
}

/**
 * @function editDynamicAccounts
 * @description edits dynamicAccount
 */
export function editDynamicAccounts(
	id: number,
	data: Partial<IDynamicAccountCRUD>
): Promise<IApiRes> {
	return post(`/financial/dynamics_accounts/${id}`, data)
		.then((res: IApiRes) => {
			setFeedback(phrases.DYNAMIC_ACCOUNT_EDITED, 1);
			return res;
		})
		.catch(handleError);
}

/**
 * @function deleteDynamicAccounts
 * @description deletes companies
 */
export function deleteDynamicAccounts(id: number): Promise<IApiRes> {
	return remove(`/financial/dynamics_accounts/${id}`)
		.then((res: IApiRes) => {
			setFeedback(phrases.DYNAMIC_ACCOUNTS_DELETED, 1);
			return res;
		})
		.catch(handleError);
}
