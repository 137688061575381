'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';
import getMarketColumns from 'reactDataWrapperColumns/administration/markets.columns';
import getNutritionCategoryColumns from 'reactDataWrapperColumns/product/nutritionCategories.columns';
import getIngredientColumns from 'reactDataWrapperColumns/product/ingredients.columns';

// phrases
import phrases from './../../../nutritionValues.phrases';

class NutritionValuesBatchStepFormMarket extends Component {
	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		updateBatchForm({
			[type]: e,
		});
	}

	render() {
		const { batchFormData, useGlobalResource } = this.props;

		return (
			<>
				{!useGlobalResource && (
					<ModalRow>
						<InputCollectionSelect
							id="market"
							label={phrases.MODAL_BATCH_STEP_MARKET}
							placeholder={phrases.MODAL_BATCH_STEP_MARKET_PLACEHOLDER}
							value={batchFormData.markets}
							handleChange={(key, value) => this.editStoreEntry(value, 'markets')}
							cache
							multi
							required
							apiPath="/administration/markets"
							params={{
								limit: 30,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
							tableColumns={getMarketColumns()}
							tableTitle={phrases.MODAL_BATCH_STEP_MARKET_TABLE_TITLE}
							tableReduxKey="nutrition-values/administration/markets"
						/>
					</ModalRow>
				)}
				<ModalRow>
					<InputCollectionSelect
						id="ingredients"
						label={phrases.MODAL_BATCH_STEP_INGREDIENTS}
						placeholder={phrases.MODAL_BATCH_STEP_INGREDIENTS_PLACEHOLDER}
						value={batchFormData.ingredients}
						handleChange={(key, value) => this.editStoreEntry(value, 'ingredients')}
						required
						multi
						cache
						apiPath="/product/ingredients"
						params={{
							limit: 30,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						tableColumns={getIngredientColumns()}
						tableTitle={phrases.MODAL_BATCH_STEP_INGREDIENTS_TABLE_TITLE}
						tableReduxKey="nutrition-values/product/ingredients"
					/>
				</ModalRow>
				<ModalRow>
					<InputCollectionSelect
						id="nutritionCategories"
						label={phrases.MODAL_BATCH_STEP_NUTRITION_CATEGORIES}
						placeholder={phrases.MODAL_BATCH_STEP_NUTRITION_CATEGORIES_PLACEHOLDER}
						value={batchFormData.nutritionCategories}
						handleChange={(key, value) => this.editStoreEntry(value, 'nutritionCategories')}
						required
						multi
						cache
						apiPath="/product/nutrition_categories"
						params={{
							limit: 30,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						tableColumns={getNutritionCategoryColumns()}
						tableTitle={phrases.MODAL_BATCH_STEP_NUTRITION_CATEGORIES_TABLE_TITLE}
						tableReduxKey="nutrition-values/product/nutrition_categories"
					/>
				</ModalRow>
			</>
		);
	}
}

NutritionValuesBatchStepFormMarket.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	useGlobalResource: PropTypes.bool,
};

export default NutritionValuesBatchStepFormMarket;
