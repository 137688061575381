'use strict';

export function getStringBasedOnSelectedValues(valueArray) {
	return valueArray.reduce((acc, currentValue, index) => {
		acc =
			index === valueArray.length - 1
				? `${acc}${currentValue}`
				: `${acc}${currentValue}, `;
		return acc;
	}, '');
}
