'use strict';

var headers = {
	TILL_STATIONS: {
		NUMBER: 'Number'
	},
	POS_CONFIGURATIONS: {
		NAME: 'Name',
		DESCRIPTION: 'Description',
		MARKET: 'Market'
	},
	POS_CONFIGURATION_VERSIONS: {
		NAME: 'Name',
		DESCRIPTION: 'Description',
		STATE: 'State',
		FROM: 'From',
		TO: 'To',
		DELETE: 'Drop',
		POS_TILE_LAYOUT: 'Pos tile layout',
		APP_TILE_LAYOUT: 'App tile layout'
	},
	POS_CONFIGURATION_CURRENCIES: {
		CURRENCY: 'Currency',
		MARKUP: 'Markup'
	},
	POS_CONFIGURATION_PRODUCT_VARIANTS: {
		PRODUCT_VARIANT: 'Product Variant',
		ALTERNATIVE_ID: 'Alternative ID',
		TO_STAY_PRICE: 'To Stay (price)',
		TO_GO_PRICE: 'To Go (price)',
		TO_STAY_TAX: 'To Stay (tax)',
		TO_GO_TAX: 'To Go (tax)',
		POS_CONFIGURATION_PRODUCT_VARIANT: 'Product variant',
		TO_STAY_DISCOUNT_PRICE: 'To stay discount price',
		TO_GO_DISCOUNT_PRICE: 'To go discount price'
	},
	POS_CONFIGURATION_INGREDIENT_ADDONS: {
		POS_CONFIGURATION_PRODUCT_VARIANT: 'Product Variant',
		POS_CONFIGURATION_INGREDIENT: 'Product Ingredient',
		MINIMUM: 'Minimum',
		DEFAULT: 'Default',
		MAXIMUM: 'Maximum',
		DEDUCTIBLE: 'Deductible',
		SORT_ORDER: 'Sort Order'
	},
	POS_CONFIGURATION_PRODUCT_ADDONS: {
		POS_CONFIGURATION_PRODUCT_VARIANT: 'Product Variant',
		POS_CONFIGURATION_INGREDIENT: 'Product Ingredient',
		MINIMUM: 'Minimum',
		DEFAULT: 'Default',
		MAXIMUM: 'Maximum',
		DEDUCTIBLE: 'Deductible',
		SORT_ORDER: 'Sort Order'
	},
	POS_CONFIGURATION_INGREDIENTS: {
		INGREDIENT: 'Ingredient',
		ALTERNATIVE_ID: 'Alternative ID',
		TO_STAY_PRICE: 'To Stay (price)',
		TO_GO_PRICE: 'To Go (price)',
		TO_STAY_TAX: 'To Stay (tax)',
		TO_GO_TAX: 'To Go (tax)',
		POS_CONFIGURATION_INGREDIENT: 'Ingredient',
		TO_STAY_DISCOUNT_PRICE: 'To stay discount price',
		TO_GO_DISCOUNT_PRICE: 'To go discount price'
	},
	POS_CONFIGURATION_DISCOUNTS: {
		NAME: 'Name',
		DESCRIPTION: 'Description',
		LOYALTY_TYPE: 'Loyalty type',
		TYPE: 'Type',
		DISCOUNT: 'Discount',
		LOYALTY_PRODUCT: 'Loyalty product'
	},
	TILE_PICTURE_ASSETS: {
		NAME: 'Name',
		DESCRIPTION: 'Description'
	},
	TILE_PICTURE_ASSETS_DETAILS: {
		NAME: 'Name',
		DESCRIPTION: 'Description'
	},
	TILE_PICTURE_ASSET_SIZES: {
		TILE_SIZE: 'Tile Size',
		IMAGE: 'Image'
	},
	POS_CONFIGURATION_DISCOUNT_PRICINGS: {
		TO_GO_DISCOUNT_PRICE: 'To go (discount price)',
		TO_STAY_DISCOUNT_PRICE: 'To stay (discount price)'
	},
	WORKPLACE_TO_POS_CONFIGURATION_RELATIONS: {
		POS_CONFIGURATION: 'POS Configuration',
		PRODUCT_RECOMMENDATION: 'Product recommendation',
		INGREDIENT_CONFIGURATION: 'Ingredient configuration',
		WORKPLACE: 'Workplace',
		APP_TILE_LAYOUT: 'App tile layout',
		POS_TILE_LAYOUT: 'Pos tile layout',
		FROM: 'From',
		TO: 'To'
	},
	TILE_SIZES: {
		NAME: 'Name',
		SORT_ORDER: 'Sort Order',
		TYPE: 'Type',
		X_DIMENSION: 'X-dimension',
		Y_DIMENSION: 'Y-dimension'
	},
	POS_CONFIGURATION_TILE_LAYOUTS_LIST: {
		NAME: 'Name',
		VERSION_NAME: 'Version name',
		DESCRIPTION: 'Description',
		ROWS: 'Rows',
		COLUMNS: 'Columns'
	}
};

var titles = {
	POS_CONFIGURATION_PRODUCT_VARIANTS: 'Product Variants pricing',
	POS_CONFIGURATION_INGREDIENTS: 'Ingredients pricing',
	POS_CONFIGURATIONS: 'POS Configurations',
	POS_CONFIGURATION_INGREDIENT_ADDONS: 'Associated products',
	POS_CONFIGURATION_PRODUCT_ADDONS: 'Associated ingredients',
	TILE_SIZES: 'Tile sizes',
	TILE_PICTURE_ASSET_SIZES: 'Tile Picture Asset Sizes',
	TILE_PICTURE_ASSETS: 'Tile Picture Assets',
	TILE_PICTURE_ASSETS_DETAILS: 'Tile Picture Assets Details',
	POS_CONFIGURATION_VERSIONS: 'Versions',
	POS_CONFIGURATION_CURRENCIES: 'Currencies',
	POS_CONFIGURATION_DISCOUNTS: 'Discounts',
	POS_CONFIGURATION_DISCOUNT_PRICINGS: 'Discount pricings',
	POS_CONFIGURATION_TILE_LAYOUTS_LIST: 'POS Configurations Tile Layouts',
	WORKPLACE_TO_POS_CONFIGURATION_RELATIONS:
		'Workplace / POS Configuration relations',
	TILL_STATIONS: 'Till stations'
};

var colgroups = {
	POS_CONFIGURATION_INGREDIENTS: {
		INGREDIENT: 'Ingredient',
		PRICING: 'Pricing',
		TAX: 'Tax'
	},
	POS_CONFIGURATION_PRODUCT_VARIANTS: {
		PRODUCT: 'Product',
		PRICING: 'Pricing',
		TAX: 'Tax'
	},
	POS_CONFIGURATION_DISCOUNT_PRICINGS: {
		DISCOUNTS: 'Discounts'
	}
};

module.exports = {
	headers: headers,
	titles: titles,
	colgroups: colgroups
};
