export const RESET_STATE = 'SHIFTPLANNER/WORKPLACE_TODOS/RESET_STATE';
export const RESET_STATE_MODALS = 'SHIFTPLANNER/WORKPLACE_TODOS/RESET_STATE_MODALS';
export const LOADING_TODOS = 'SHIFTPLANNER/WORKPLACE_TODOS/LOADING_TODOS';
export const TOGGLE_TODO_OVERLAY = 'SHIFTPLANNER/WORKPLACE_TODOS/TOGGLE_TODO_OVERLAY';

export const UPDATE_TASK_FORM = 'SHIFTPLANNER/WORKPLACE_TODOS/UPDATE_TASK_FORM';
export const UPDATE_ADD_TODOS = 'SHIFTPLANNER/WORKPLACE_TODOS/UPDATE_ADD_TODOS';
export const DELETE_ADD_TODOS = 'SHIFTPLANNER/WORKPLACE_TODOS/DELETE_ADD_TODOS';
export const SET_CREATED_TASK = 'SHIFTPLANNER/WORKPLACE_TODOS/SET_CREATED_TASK';

export const UPDATE_FIND_TASK = 'SHIFTPLANNER/WORKPLACE_TODOS/UPDATE_FIND_TASK';
export const EDIT_FIND_TODOS = 'SHIFTPLANNER/WORKPLACE_TODOS/EDIT_FIND_TODOS';
export const SET_FIND_TODOS = 'SHIFTPLANNER/WORKPLACE_TODOS/SET_FIND_TODOS';
export const DELETE_TODO = 'SHIFTPLANNER/WORKPLACE_TODOS/DELETE_TODO';
export const SET_EDIT_ROW = 'SHIFTPLANNER/WORKPLACE_TODOS/SET_EDIT_ROW';

/**
 * @param {number} id
 */
export function clearTodoState() {
	return {
		type: RESET_STATE
	};
}

/**
 * @param {number} id
 */
export function clearTodoModalState() {
	return {
		type: RESET_STATE_MODALS
	};
}

/**
 * @param {number} id
 */
export function toggleTodoOverlay(payload) {
	return {
		type: TOGGLE_TODO_OVERLAY,
		payload
	};
}

/**
 * @param {number} id
 */
export function setLoadingTodos(payload) {
	return {
		type: LOADING_TODOS,
		payload
	};
}

// Add task+todos
/**
 * @param {object} payload
 */
export function updateTaskForm(payload) {
	return {
		type: UPDATE_TASK_FORM,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateAddTodos(payload) {
	return {
		type: UPDATE_ADD_TODOS,
		payload
	};
}

/**
 * @param {object} payload
 */
export function deleteTodo(payload) {
	return {
		type: DELETE_ADD_TODOS,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateFindTask(payload) {
	return {
		type: UPDATE_FIND_TASK,
		payload
	};
}

/**
 * @param {object} payload
 */
export function editFindTodos(payload) {
	return {
		type: EDIT_FIND_TODOS,
		payload
	};
}

/**
 * @param {object} payload
 */
export function setTaskTodos(payload) {
	return {
		type: SET_FIND_TODOS,
		payload
	};
}

/**
 * @param {object} payload
 */
export function setCreatedTask(payload) {
	return {
		type: SET_CREATED_TASK,
		payload
	};
}

/**
 * @param {object} payload
 */
export function setEditRow(payload) {
	return {
		type: SET_EDIT_ROW,
		payload
	};
}