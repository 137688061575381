export const RESET_STATE = 'SALES_CONFIGURATION_LAYOUT/RESET_STATE';
export const LOADING = 'SALES_CONFIGURATION_LAYOUT/LOADING';
export const LOADING_PRODUCT = 'SALES_CONFIGURATION_LAYOUT/LOADING_PRODUCT';
export const EDIT_GROUP = 'SALES_CONFIGURATION_LAYOUT/EDIT_GROUP';
export const EDIT_PRODUCT_TILE = 'SALES_CONFIGURATION_LAYOUT/EDIT_PRODUCT_TILE';

export function editProductTile(payload) {
	return {
		type: EDIT_PRODUCT_TILE,
		payload
	};
}

export function editGroup(payload) {
	return {
		type: EDIT_GROUP,
		payload
	};
}

export function setLoader(isLoading) {
	return {
		type: LOADING,
		payload: isLoading
	};
}

export function setLoaderProduct(isLoading) {
	return {
		type: LOADING_PRODUCT,
		payload: isLoading
	};
}

export function resetState() {
	return {
		type: RESET_STATE
	};
}
