import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

// components
import ShiftClockinsHourlyStatsTable from './components/shiftClockinsHourlyStatsTable';

import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';
import ShiftClockinsTableColumns from './components/columns/shiftClockinsTableColumns';

// phrases
import phrases from './shiftClockinsTable.phrases';

// style
import './shiftClockinsTable.css';

const ShiftClockinsTable = ({
	loading,
	fetchData,
	tableKey,
	fetchShiftClockinHourlyStats,
	reduxKey,
	onManagedClockinNoShowCellClick,
	postManagedClockinCorrection,
	breakRegistrationPossible,
	getActionButtons,
	onManagedClockinAddBreakClick,
	setBreaksAsNoShow,
	editClockedValue,
}) => {
	return (
		<div
			className={cx('shift-clockins__table-wrapper', {
				'shift-clockins__table-wrapper--loading': loading,
			})}
		>
			<ReactDataWrapper
				title={phrases.TITLE}
				key={tableKey}
				columns={ShiftClockinsTableColumns({
					onManagedClockinNoShowCellClick,
					postManagedClockinCorrection,
					reduxKey,
					breakRegistrationPossible,
					onManagedClockinAddBreakClick,
					setBreaksAsNoShow,
					editClockedValue,
				})}
				filterable
				fetchData={fetchData}
				defaultPageSize={50}
				reduxKey={reduxKey}
				manual
				subcomponent={(row) => (
					<SubTable>
						<ShiftClockinsHourlyStatsTable
							workplaceId={row.original?.workplace?.id ?? null}
							date={row.original?.date}
							fetchShiftClockinHourlyStats={fetchShiftClockinHourlyStats}
						/>
					</SubTable>
				)}
				compact
				enableMultiSelection={false}
				enableSingleSelection
				actionRender={getActionButtons()}
			/>
		</div>
	);
};

ShiftClockinsTable.propTypes = {
	loading: PropTypes.bool,
	fetchData: PropTypes.func,
	tableKey: PropTypes.object,
	onManagedClockinNoShowCellClick: PropTypes.func,
	fetchShiftClockinHourlyStats: PropTypes.func,
	reduxKey: PropTypes.string,
	postManagedClockinCorrection: PropTypes.func,
	breakRegistrationPossible: PropTypes.bool,
	getActionButtons: PropTypes.func,
	onManagedClockinAddBreakClick: PropTypes.func,
	setBreaksAsNoShow: PropTypes.func,
	editClockedValue: PropTypes.func,
};

export default ShiftClockinsTable;
