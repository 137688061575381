'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setFilter,
	updateFilter,
	resetFilter,
} from './store/filters.actions';

// services
import {
	fetchFilters,
	editFilter,
	editFilters,
	addFilter,
	deleteFilter,
} from './filters.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';
import { Input, InputCollectionSelect, Button, Icon, Tabs } from 'dumb';

import FilterProductVariants from './components/filterProductVariants/filterProductVariants.component';
import FiltersModalBatch from './components/batch/filtersModalBatch';
import Assets from './../../../../../product/components/product/components/assetsLeftAligned/assetLeftAligned.component';

// phrases
import phrases from './filters.phrases';

const reduxKey = '/product/filters';

class Filters extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.addFilterWrapper = this.addFilterWrapper.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.getSubComponent = this.getSubComponent.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.reloadTable = this.reloadTable.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: 'name',
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: 'description',
				filterPath: ':description',
			},
			{
				Header: 'Asset collection',
				id: 'assetCollection',
				accessor: (d) => d.asset_collection?.name ?? '',
				filterPath: ':asset_collection.name',
			},
			{
				Header: 'Sort order',
				id: 'sort_order',
				accessor: 'sort_order',
				filterPath: ':sort_order',
			},
		];
	}

	editEntry(data) {
		const { defaultFilterGroup } = this.props;

		// data gets passed from assetsLeftAligned when adding a new asset collection to
		// the filter
		const payload = data || {
			id: defaultFilterGroup.id,
			name: defaultFilterGroup.name,
			description: defaultFilterGroup.description,
			asset_collection: defaultFilterGroup.assetCollection?.value?.id ?? null,
			...(defaultFilterGroup.sortOrder && {
				sort_order: defaultFilterGroup.sortOrder,
			}),
		};

		return editFilter(payload);
	}

	editMultiple(selectedRows) {
		const { defaultFilterGroup } = this.props;

		const payload = {
			...(defaultFilterGroup.name && {
				name: defaultFilterGroup.name,
			}),
			...(defaultFilterGroup.description && {
				description: defaultFilterGroup.description,
			}),
			...(defaultFilterGroup.sortOrder && {
				sort_order: defaultFilterGroup.sortOrder,
			}),
			...(defaultFilterGroup.assetCollection && {
				asset_collection: defaultFilterGroup.assetCollection.value?.id,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editFilters({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteFilter(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			...(data.asset_collection && {
				assetCollection: {
					value: data.asset_collection,
					label: data.asset_collection.name,
				},
			}),
			sortOrder: data.sort_order,
		};

		this.props.setFilter(payload);
	}

	getEditableCells(e) {
		const { defaultFilterGroup } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={defaultFilterGroup.name || ''}
						onChange={(event) => this.editStoreEntry('name', event)}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={defaultFilterGroup.description || ''}
						onChange={(event) => this.editStoreEntry('description', event)}
					/>
				),
			},
			{
				header: 'Asset collection',
				value: (
					<InputCollectionSelect
						id="assetCollection"
						placeholder={phrases.MODAL_BATCH_STEP_ASSET_COLLECTION_PLACEHOLDER}
						value={defaultFilterGroup.assetCollection}
						handleChange={(key, value) => {
							this.editStoreEntry('assetCollection', value);
						}}
						clearable
						cache
						apiPath="/pos/asset_collections"
						params={{
							limit: 30,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						tableColumns={[
							{
								Header: 'Name',
								id: 'name',
								accessor: 'name',
								filterPath: ':name',
							},
							{
								Header: 'Description',
								id: 'desc',
								accessor: 'description',
								filterPath: ':description',
							},
							{
								Header: 'Legacy',
								id: 'legacy',
								accessor: 'legacy',
								filterPath: ':legacy',
								filterable: false,
								Cell: (d) => {
									return (
										<Input
											type="checkbox"
											checked={d.original.legacy}
											disabled
										/>
									);
								},
							},
						]}
						tableReduxKey="productFilters-assetCollectionTable"
						tableTitle={phrases.MODAL_BATCH_STEP_ASSET_COLLECTION_TABLE_TITLE}
					/>
				),
			},
			{
				header: 'Sort order',
				value: (
					<Input
						id="sort_order"
						type="number"
						placeholder="Enter sort order..."
						value={defaultFilterGroup.sortOrder || null}
						onChange={(event) => this.editStoreEntry('sortOrder', event)}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const value = e?.target?.value ?? e;

		const updateObject = {
			[name]: value,
		};

		this.props.updateFilter(updateObject);
	}

	fetchData(state) {
		const { filterGroup } = this.props;

		const filter = `:filter_group.id=='${filterGroup.id}'`;

		return fetchFilters(state, filter);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetFilter();
	}

	addFilterWrapper(data) {
		return addFilter(data).then(() => {
			this.setState(() => ({
				key: moment.utc(),
			}));
		});
	}

	reloadTable() {
		this.setState(() => ({
			key: moment.utc(),
		}));
	}

	getSubComponent(row) {
		const tabContent = [
			{
				name: phrases.FILTER_PRODUCT_VARIANTS,
				component: <FilterProductVariants filter={row.original} />,
			},
			{
				name: phrases.ASSETS,
				component: (
					<Assets
						reduxKey={`product/filter-${row.original.id}-pos/assets/${
							row.original.asset_collection?.id || ''
						}-aligned`}
						assetCollectionId={row.original.asset_collection?.id}
						filterId={row.original.id}
						editFilter={this.editEntry}
						reloadFilterTable={this.reloadTable}
					/>
				),
			},
		];

		return (
			<SubTable>
				<Tabs tabContent={tabContent} />
			</SubTable>
		);
	}

	render() {
		const {
			batchList,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
			filterGroup,
		} = this.props;

		return (
			<>
				<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={`${reduxKey}-${filterGroup.id}`}
					manual
					subcomponent={this.getSubComponent}
					editableCells={this.getEditableCells()}
					editEntry={(e) => this.editEntry(e)}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetFilter}
					actionRender={
						<Button
							type="inverted"
							label="Batch"
							shadow
							onClick={() => this.toggleModal()}>
							<Icon name="add" />
						</Button>
					}
				/>

				<FiltersModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addFilter={this.addFilterWrapper}
					filterGroup={filterGroup}
				/>
			</>
		);
	}
}

Filters.propTypes = {
	defaultFilterGroup: PropTypes.object,

	updateFilter: PropTypes.func,
	setFilter: PropTypes.func,
	resetFilter: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	filterGroup: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			updateFilter,
			setFilter,
			resetFilter,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultFilterGroup: store.filters.data.defaultFilterGroup,
		batchList: store.filters.data.batchList,
	};
};

export default connectWithStore(
	Filters,
	mapStateToProps,
	mapDispatchToProps
);
