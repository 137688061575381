'use strict';
import _omitBy from 'lodash/omitBy';
import _findKey from 'lodash/findKey';
import _uniqBy from 'lodash/uniqBy';

import {
	ADD_PRODUCTS_VARIANT_TO_TILE,
	CLEAR_TILES,
	FETCHING,
	REMOVE_GROUP,
	REMOVE_PRODUCTS_VARIANT_FROM_TILE,
	REMOVE_TILE,
	SET_ACTIVE_TILE,
	SET_GRID_DATA,
	SET_GROUP_IN_FOCUS,
	SET_GROUPS,
	SET_IMAGE_ASSETS_ON_ACTIVE_TILE,
	SET_IMAGE_ASSETS,
	SET_TILES_IN_GROUP,
	SET_VARIANTS_LIST,
	UPDATE_PRODUCTS_VARIANT_ON_TILE,
	UPDATE_TILE_POSITION,
	UPDATE_EXISTING_TILE_PICTURE_ASSET,
	UPDATE_TILE,
	CLEAN_UP,
	SET_FULL_VARIANTS_LIST,
	SET_LOADING,
	SET_MOUNTED,
} from './posConfigurationLayout.actions';

const defaultState = {
	ui: {
		loading: false,
	},
	activeTile: {},
	grid: {},
	gridMetaData: {},
	gridSize: null,
	groupItemInFocus: null,
	groups: {},
	imageAssets: [],
	posConfigurationsVariantsList: [],
	tilesInGroup: {},
	posFullConfigurationsVariantsList: [],
	mounted: null,
};

function posReducer(state = defaultState, action) {
	let copy = null;
	const { type, payload } = action;
	switch (type) {
		case SET_FULL_VARIANTS_LIST:
			return { ...state, posFullConfigurationsVariantsList: payload };

		case SET_TILES_IN_GROUP:
			// remove tiles in group if payload is empty
			// return prop.set(state, 'tilesInGroup', { ...state.tilesInGroup, ...payload.tilesInGroup });

			return {
				...state,
				tilesInGroup: { ...state.tilesInGroup, ...payload.tilesInGroup },
			};

		case SET_LOADING:
			return {
				...state,
				ui: { ...state.ui, loading: payload },
			};

		case UPDATE_TILE:
			const tile = payload.tile;
			const position = tile.position;

			// remove tiles in group if payload is empty
			return {
				...state,
				tilesInGroup: {
					...state.tilesInGroup,
					[position]: {
						...state.tilesInGroup[position],
						...tile,
					},
				},
			};

		case SET_GROUP_IN_FOCUS:
			// return prop.set(state, 'groupItemInFocus', payload.groupItemInFocus);
			return {
				...state,
				groupItemInFocus: payload.groupItemInFocus,
			};

		case SET_ACTIVE_TILE:
			// return prop.set(state, 'activeTile', payload.activeTile);
			return {
				...state,
				activeTile: payload.activeTile,
			};

		case REMOVE_TILE:
			copy = Object.assign({}, state.tilesInGroup);

			// Remove from object where id === payload
			copy = _omitBy(copy, (o) => {
				return o.id === payload.tileId;
			});

			// return prop.set(state, 'tilesInGroup', {
			// 	...copy
			// });

			return {
				...state,
				tilesInGroup: { ...copy },
			};

		case CLEAR_TILES:
			// return prop.set(state, 'tilesInGroup', {});
			return {
				...state,
				tilesInGroup: {},
			};

		case SET_VARIANTS_LIST:
			// return prop.set(state, 'posConfigurationsVariantsList', payload.posConfigurationsVariantsList);
			return {
				...state,
				posConfigurationsVariantsList: payload.posConfigurationsVariantsList,
			};

		case SET_GROUPS:
			// return prop.set(state, 'groups', {
			// 	...state.groups,
			// 	...payload.groups
			// });

			return {
				...state,
				groups: {
					...state.groups,
					...payload.groups,
				},
			};

		case REMOVE_GROUP:
			copy = Object.assign({}, state.groups);
			delete copy[payload.groupId];

			// return prop.set(state, 'groups', {
			// 	...copy
			// });

			return {
				...state,
				groups: {
					...copy,
				},
			};

		case UPDATE_TILE_POSITION:
			return Object.assign({}, state, {
				tilesInGroup: payload.updatedTiles,
			});

		case ADD_PRODUCTS_VARIANT_TO_TILE:
			const tilesInGroup = state.tilesInGroup;
			const groupTileProductVariant = payload.groupTileProductVariant;
			const tileProductVariantOwner =
				groupTileProductVariant.pos_configuration_tile_layout_tile;
			const tileProductOwner = _findKey(tilesInGroup, [
				'id',
				tileProductVariantOwner,
			]);
			let posConfigurationLayoutTileProductTileVariants =
				tilesInGroup[tileProductOwner]
					.pos_configuration_tile_layout_tile_product_variants || [];
			posConfigurationLayoutTileProductTileVariants = _uniqBy(
				[
					...posConfigurationLayoutTileProductTileVariants,
					groupTileProductVariant,
				],
				'id'
			);

			// When adding a new tile with a corresponding tileProductVariant, 'pos_configuration_tile_layout_tile_product_variants' is null, where it should be an array with the corresponding variants
			return {
				...state,
				tilesInGroup: {
					...tilesInGroup,
					[tileProductOwner]: {
						...tilesInGroup[tileProductOwner],
						pos_configuration_tile_layout_tile_product_variants:
							posConfigurationLayoutTileProductTileVariants,
					},
				},
				activeTile: {
					...state.activeTile,
					pos_configuration_tile_layout_tile_product_variants:
						posConfigurationLayoutTileProductTileVariants,
				},
			};

		case REMOVE_PRODUCTS_VARIANT_FROM_TILE:
			const { tileProductTileVariantId, tileId } = payload;

			const foundKey = _findKey(state.tilesInGroup, ['id', tileId]);

			if (!foundKey) return state;

			return {
				...state,
				tilesInGroup: state.tilesInGroup[foundKey]
					? {
							...state.tilesInGroup,
							[foundKey]: {
								...state.tilesInGroup[foundKey],
								pos_configuration_tile_layout_tile_product_variants:
									state.tilesInGroup[
										foundKey
									].pos_configuration_tile_layout_tile_product_variants.filter(
										(tileProductTileVariant) =>
											tileProductTileVariant.id !== tileProductTileVariantId
									),
							},
					  }
					: state.tilesInGroup,

				activeTile: {
					...state.activeTile,
					pos_configuration_tile_layout_tile_product_variants:
						state.tilesInGroup[
							foundKey
						].pos_configuration_tile_layout_tile_product_variants.filter(
							(tileProductTileVariant) =>
								tileProductTileVariant.id !== tileProductTileVariantId
						),
				},
			};

		// case SET_IMAGE_ASSETS_ON_ACTIVE_TILE:
		// 	const activeTilePosition = payload.activeTile.position;
		// 	const tilePictureAssetSizes = Object.keys(
		// 		payload.tilePictureAssetSizes
		// 	).map(key => payload.tilePictureAssetSizes[key]);

		// 	return {
		// 		...state,
		// 		tilesInGroup: {
		// 			...state.tilesInGroup,
		// 			[activeTilePosition]: {
		// 				...state.tilesInGroup[activeTilePosition],
		// 				tile_picture_asset: {
		// 					...state.tilesInGroup[activeTilePosition].tile_picture_asset,
		// 					tile_picture_asset_sizes: tilePictureAssetSizes
		// 				}
		// 			}
		// 		},
		// 		activeTile: {
		// 			...state.activeTile,
		// 			tile_picture_asset: {
		// 				...state.activeTile.tile_picture_asset,
		// 				tile_picture_asset_sizes: tilePictureAssetSizes
		// 			}
		// 		}
		// 	};

		case UPDATE_PRODUCTS_VARIANT_ON_TILE:
			const tileToUpdate = _findKey(state.tilesInGroup, ['id', payload.tileId]);

			return {
				...state,
				tilesInGroup: {
					...state.tilesInGroup,
					[tileToUpdate]: {
						...state.tilesInGroup[tileToUpdate],
						pos_configuration_tile_layout_tile_product_variants:
							state.tilesInGroup[
								tileToUpdate
							].pos_configuration_tile_layout_tile_product_variants.map(
								(tileProductTileVariant) =>
									tileProductTileVariant.id ===
									payload.tileLayoutTileProductVariant.id
										? {
												...tileProductTileVariant,
												...payload.tileLayoutTileProductVariant,
										  }
										: tileProductTileVariant
							),
					},
				},
				activeTile: {
					...state.activeTile,
					pos_configuration_tile_layout_tile_product_variants:
						state.tilesInGroup[
							tileToUpdate
						].pos_configuration_tile_layout_tile_product_variants.map(
							(tileProductTileVariant) =>
								tileProductTileVariant.id ===
								payload.tileLayoutTileProductVariant.id
									? {
											...tileProductTileVariant,
											...payload.tileLayoutTileProductVariant,
									  }
									: tileProductTileVariant
						),
				},
			};

		case SET_IMAGE_ASSETS:
			const imageAssets = [...state.imageAssets, ...payload.imageAssets];

			return {
				...state,
				imageAssets: _uniqBy(imageAssets, (imageAsset) => imageAsset.tileId),
			};

		case UPDATE_EXISTING_TILE_PICTURE_ASSET:
			return {
				...state,
				imageAssets: state.imageAssets.map((imageAsset) =>
					imageAsset.tileId === payload.imageAsset.tileId
						? { ...imageAsset, ...payload.imageAsset }
						: imageAsset
				),
			};

		case CLEAN_UP:
			return {
				...defaultState,
			};

		case SET_GRID_DATA:
			// Payload in this case consists of grid, gridMetaData and gridSize
			// and prop.set dont seem to accept my data unless i use prop.merge.
			// But prop.merge is 10 times slower than object assign in this case
			return Object.assign({}, state, payload);

		// Fetching data. This should be called in collections
		case FETCHING:
			// return prop.set(state, 'fetching', payload);
			return {
				...state,
				fetching: payload,
			};

		case SET_MOUNTED:
			return {
				...state,
				mounted: payload,
			};

		default:
			return state;
	}
}

export default posReducer;
