'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './text.css';

class Text extends Component {
	constructor(props) {
		super(props);
		this.focus = this.focus.bind(this);
	}

	getInputType(type) {
		switch (type) {
		case 'password':
			return 'password';
		case 'mail':
			return 'email';
		case 'number':
			return 'text';
		case 'tel':
			return 'tel';
		default:
			return 'text';
		}
	}

	focus() {
		this.text.focus();
	}

	render() {
		const {
			onChange,
			onBlur,
			onKeyDown,
			type,
			placeholder,
			initialValue
		} = this.props;

		const textCx = cx('text');

		return (
			<div className={textCx}>
				<input
					ref={input => {
						this.text = input;
					}}
					type={this.getInputType(type)}
					placeholder={placeholder}
					defaultValue={initialValue}
					onKeyDown={onKeyDown}
					onBlur={onBlur}
					onChange={onChange}
				/>
			</div>
		);
	}
}

Text.defaultProps = {
	placeholder: '...'
};

Text.propTypes = {
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	placeholder: PropTypes.string,
	initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	type: PropTypes.string
};

export default Text;
