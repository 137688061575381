'use strict';

import { store } from 'appState';
import { get, post } from 'api.vanilla.service';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { formatErrorMessage } from 'api/helpers';

export const CREATE_ADDRESS_DATA = 'PERSON_DETAILS/CREATE_ADDRESS_DATA';
export const SET_PERSON_DATA = 'PERSON_DETAILS/SET_PERSON_DATA';
export const SET_PERSON_PERSONAL_INFORMATION =
	'PERSON_DETAILS/SET_PERSON_PERSONAL_INFORMATION';
export const SET_CUSTOM_DATA = 'PERSON_DETAILS/SET_CUSTOM_DATA';
export const SET_LOADER = 'PERSON_DETAILS/SET_LOADER';
export const SET_INPUT_DATA = 'PERSON_DETAILS/SET_INPUT_DATA';
export const PERSIST_ADDRESS_DATA = 'PERSON_DETAILS/PERSIST_ADDRESS_DATA';
export const SET_SENIORITY = 'PERSON_DETAILS/SET_SENIORITY';
export const RESET_SENIORITY = 'PERSON_DETAILS/RESET_SENIORITY';

/**
 * @function setLoader
 * @description Sets whether the whole form is loading
 * @param {Boolean} payload personData
 */
export const setLoader = (payload) => {
	return { type: SET_LOADER, payload };
};

/**
 * @function setPersonData
 * @description Sets person data to reducer from API
 * @param {Object} payload personData
 */
export const setPersonData = (payload) => {
	return { type: SET_PERSON_DATA, payload };
};
/**
 * @function setPersonData
 * @description Sets person data to reducer from API
 * @param {Object} payload personData
 */
export const setPersonPersonalInformation = (payload) => {
	return { type: SET_PERSON_PERSONAL_INFORMATION, payload };
};

/**
 * @function setCustomData
 * @description Sets custom data to reducer from API
 * @param {Object} handle handler key for personData object
 * @param {Object} data The payload to merge
 */
export const setCustomData = (handle, data) => {
	return { type: SET_CUSTOM_DATA, payload: { handle, data } };
};

/**
 * @function createAddressData
 * @description Set address to reducer in create form
 * @param {Object} payload address
 */
export const createAddressData = (payload) => {
	return { type: CREATE_ADDRESS_DATA, payload };
};

/**
 * @function createAddressData
 * @description Set address to reducer in create form
 * @param {Object} payload address
 */
export const persistAddressData = (payload) => {
	return { type: PERSIST_ADDRESS_DATA, payload };
};

/**
 * @function setTextInput
 * @description Sets person data to reducer from API
 * @param {Object} payload personData
 */
export const setTextInput = (payload) => {
	return { type: SET_INPUT_DATA, payload };
};

export function setSeniority(payload) {
	return {
		type: SET_SENIORITY,
		payload,
	};
}

export function resetSeniority() {
	return {
		type: RESET_SENIORITY,
	};
}

// ************************
// SERVER SIDE ACTIONS
// ************************

/**
 * @function fetchPersonData
 * @description Gets the selected task from local storage
 * @param {Number} personId personData
 */
export const fetchPersonData = (
	personId,
	userHasAccessToSensitivePersonInfo
) => {
	// Loader
	store.dispatch(setLoader(true));

	// Call
	return get(`/hr/persons/${personId}`)
		.then((result) => {
			// Dispatch results
			store.dispatch(setPersonData(result.data[0]));

			// don't fetch sensitive info if we don't have access to it
			// api doesn't return anything here so no real reason to not fetch anything but still a percuation
			if (userHasAccessToSensitivePersonInfo) {
				fetchPersonPersonalInformation(personId);
			}

			fetchSeniority({ personId });

			// Loader
			store.dispatch(setLoader(false));
		})
		.catch((err) => {
			const errorMsg = formatErrorMessage(err);
			setFeedback(errorMsg, 0);

			// Loader
			store.dispatch(setLoader(false));
		});
};

/**
 * @function fetchPersonData
 * @description Gets the selected task from local storage
 * @param {Number} personId personData
 */
export const fetchPersonPersonalInformation = (personId) => {
	const apiUrl = '/hr/person_personal_information';

	const params = {
		filter: `:person.id=='${personId}'`,
	};

	// Loader
	store.dispatch(setLoader(true));

	// Call
	return get(apiUrl, params)
		.then((result) => {
			// Dispatch results
			store.dispatch(
				setCustomData('person_personal_information', result.data[0])
			);

			// Loader
			store.dispatch(setLoader(false));
		})
		.catch((err) => {
			const errorMsg = formatErrorMessage(err);
			setFeedback(errorMsg, 0);

			// Loader
			store.dispatch(setLoader(false));
		});
};

function fetchSeniority({ personId }) {
	return get(`/shiftplanning/persons/${personId}/seniority`)
		.then((res) => store.dispatch(setSeniority(res.data[0])))
		.catch((err) =>
			console.info(
				`Error occured when fetching seniority for the user: ${formatErrorMessage(
					err
				)}`
			)
		);
}

/**
 * @function postPersonData
 * @description Post/update various person details
 * @param {Object} params example {first_name: 'Jackob'}
 */
export const postPersonData = (params) => {
	const apiUrl = `/hr/${params.uri}`;

	const url = params.id ? `${apiUrl}/${params.id}` : `${apiUrl}`;

	// Call
	return post(url, { [params.key]: params.value })
		.then((result) => {
			store.dispatch(setPersonData(result.data[0]));
		})
		.catch((err) => {
			const errorMsg = formatErrorMessage(err);
			setFeedback(errorMsg, 0);
		});
};

/**
 * @function postCustomData
 * @description Posts custom data
 * @param {Object} params example {phone_number: '+4599904044'}
 */
export const postCustomData = (params, handle) => {
	const uri = params.id ? `${params.uri}/${params.id}` : `${params.uri}`;

	return post(`/hr/${uri}`, params.payload)
		.then((result) => {
			store.dispatch(setCustomData(handle, result.data[0]));
		})
		.catch((err) => {
			const errorMsg = formatErrorMessage(err);
			setFeedback(errorMsg, 0);
		});
};

/**
 * @function postCustomData
 * @description Posts custom data
 * @param {Object} params example {phone_number: '+4599904044'}
 */
export const postAddressData = (params) => {
	// Call
	return post(`/hr/${params.uri}`, params.payload)
		.then((result) => {
			store.dispatch(persistAddressData(result.data[0]));
		})
		.catch((err) => {
			const errorMsg = formatErrorMessage(err);
			setFeedback(errorMsg, 0);
		});
};
