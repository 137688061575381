import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';

import MarketRegionsList from './services/marketRegionsList.service.js';

const marketRegionsListReduxKey = '/administration/market_regions';
const marketRegionsList = new MarketRegionsList(marketRegionsListReduxKey);
const marketRegionsListProps = marketRegionsList.getProps();

export function MarketRegions() {
	useEffect(() => {
		marketRegionsList.initView();
	}, []);

	return <ListComponent reduxKey={marketRegionsListReduxKey} {...marketRegionsListProps} />;
}
