'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class ProductSplitViewBody extends Component {
	getContents(contentContainer, width, data) {
		let mappedContents = [];

		for (let i = 0, len = contentContainer.length; i < len; i++) {
			const content = contentContainer[i];

			// Get styles
			const styles = cx('report-old__content', {
				'report-old__content--currency': content.contentType === 'currency'
			});
			mappedContents = [
				...mappedContents,
				<span key={i} className={styles}>
					{content.getContent(data, width) || '\u00a0'}
				</span>
			];
		}
		return mappedContents;
	}

	getColumns(row) {
		const { columns, tables } = this.props;

		return Object.keys(columns).map(columnKey => {
			// Get column in question
			const column = columns[columnKey];

			// Iterate over column cells
			return Object.keys(column.cells).map(cellKey => {
				// Get cell in question
				const cell = column.cells[cellKey];

				const defaultClassNames = {
					'report-old__cell--currency': cell.contentType === 'currency', // Right aligned
					'report-old__cell--number': cell.contentType === 'number', // Right aligned
					'report-old__cell--mixed': cell.contentType === 'mixed', // Center aligned
					'report-old__cell--left': cell.contentType === 'left' // left aligned
				};

				// Combine default class names with modifier class names
				const styles = cx('report-old__cell', {
					...defaultClassNames,
					...cell.getModifierClassNames(row.content)
				});

				// Get width
				const width = cell.getWidth(tables) ? cell.getWidth(tables) : 'auto';

				// Return
				return (
					<td key={cellKey} className={styles} style={{ width }}>
						{this.getContents(cell.getContent(), width, row.data)}
					</td>
				);
			});
		});
	}

	getRows() {
		const { productTable, expandedProducts, expandedId } = this.props;

		return (
			productTable.content &&
			productTable.content.map((row, key) => {
				const category = row.data.product.category
					? row.data.product.category
						.toLowerCase()
						.replace('.', '')
						.replace(' ', '')
					: 'none';
				return expandedProducts[category] &&
					expandedProducts[category][expandedId] ? (
						<tr key={key + '__entry'} className="report-old__entry">
							{this.getColumns(row)}
						</tr>
					) : null;
			})
		);
	}

	render() {
		return <tbody>{this.getRows()}</tbody>;
	}
}

ProductSplitViewBody.defaultProps = {
	productTable: {}
};

ProductSplitViewBody.propTypes = {
	expandedProducts: PropTypes.object,
	expandedId: PropTypes.string,
	tables: PropTypes.object.isRequired,
	columns: PropTypes.object.isRequired,
	productTable: PropTypes.object
};

export default ProductSplitViewBody;
