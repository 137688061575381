import React, { useState } from 'react';
import PropTypes from 'prop-types';

// redux
import { useDispatch, useSelector } from 'react-redux';
import {
	setProductBundleSetTranslation,
	updateProductBundleSetTranslation,
	resetState,
} from './store/productBundleSetTranslations.actions';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import ProductBundleSetTranslationsModalBatch from './components/batch/productBundleSetTranslationsModalBatch';
import { Tooltip, Icon, Button, Input } from 'dumb';

// services
import {
	fetchAllProductBundleSetTranslations,
	deleteProductBundleSetTranslation,
	editProductBundleSetTranslations,
} from './productBundleSetTranslations.service';

// utils
import { getEditedValues } from 'services/utils';

// phrases
import phrases from './productBundleSetTranslations.phrases';

const columns = [
	{
		Header: 'Name',
		id: 'name',
		accessor: 'name',
		filterPath: ':name',
	},
	{
		Header: 'Language',
		id: 'language',
		accessor: 'language.name',
		filterPath: ':language.name',
	},
];

const ProductBundleSetTranslations = ({ id }) => {
	const dispatch = useDispatch();
	const { tableKey, productBundleSetTranslation, originalProductBundleSetTranslation } = useSelector(
		(store) => store.productBundleSetTranslations
	);

	const [showBatchModal, setShowBatchModal] = useState(false);

	// crud
	const fetchData = (state) => fetchAllProductBundleSetTranslations(id, state);
	const editEntry = () => {
		const valuesToEdit = getEditedValues({
			newData: productBundleSetTranslation,
			oldData: originalProductBundleSetTranslation,
		});

		const payload = {
			id: productBundleSetTranslation.id,
			name: valuesToEdit.name,
		};

		return editProductBundleSetTranslations(payload);
	};
	const deleteEntry = (id) => deleteProductBundleSetTranslation(id);

	// crud prep
	const setInitialEditValues = (data) => {
		const body = {
			id: data.id,
			name: data.name,
			language: data.language.name,
		};

		dispatch(setProductBundleSetTranslation(body));
	};
	const getEditableCells = () => [
		{
			header: 'Name',
			value: (
				<Input
					id="name"
					placeholder={phrases.NAME}
					value={productBundleSetTranslation.name}
					onChange={(event) => dispatch(updateProductBundleSetTranslation({ name: event.target.value }))}
				/>
			),
		},
		{
			header: 'Language',
			value: <span>{productBundleSetTranslation.language}</span>,
		},
	];

	const toggleBatchModal = () => setShowBatchModal(!showBatchModal);
	const onRdwModalClose = () => dispatch(resetState());

	return (
		<>
			<ReactDataWrapper
				key={tableKey}
				accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.TITLE}
				columns={columns}
				fetchData={fetchData}
				filterable
				defaultPageSize={10}
				reduxKey={`${id}-product/bundle_set_translations`}
				manual
				editableCells={getEditableCells()}
				setInitialEditValues={setInitialEditValues}
				onModalClose={onRdwModalClose}
				deleteEntry={deleteEntry}
				editEntry={editEntry}
				actionRender={
					<Tooltip
						text={phrases.BATCH_ADD}
						renderData={(ref, onMouseEnter, onMouseLeave) => (
							<Button
								label="Add"
								shadow
								type="inverted"
								onClick={toggleBatchModal}
								refProp={ref}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
							>
								<Icon name="add" />
							</Button>
						)}
					/>
				}
			/>

			{showBatchModal && <ProductBundleSetTranslationsModalBatch onClose={toggleBatchModal} id={id} />}
		</>
	);
};

ProductBundleSetTranslations.propTypes = {
	id: PropTypes.number,
};

export default ProductBundleSetTranslations;
