export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_PRODUCT_BUNDLE_SETS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_PRODUCT_BUNDLE_SETS/REMOVE_BATCH_LIST_ITEM';

export const SET_PRODUCT_BUNDLE_SETS =
	'SALES_CONFIGURATION_PRODUCT_BUNDLE_SETS/SET_PRODUCT_BUNDLE_SETS';
export const UPDATE_PRODUCT_BUNDLE_SET =
	'SALES_CONFIGURATION_PRODUCT_BUNDLE_SETS/UPDATE_PRODUCT_BUNDLE_SET';
export const RESET_STATE =
	'SALES_CONFIGURATION_PRODUCT_BUNDLE_SETS/RESET_STATE';
export const ADD_BATCH_LIST_ITEM_ROW =
	'SALES_CONFIGURATION_PRODUCT_BUNDLES/ADD_BATCH_LIST_ITEM_ROW';

export function setProductBundleSets(payload) {
	return {
		type: SET_PRODUCT_BUNDLE_SETS,
		payload
	};
}

export function updateProductBundleSet(payload) {
	return {
		type: UPDATE_PRODUCT_BUNDLE_SET,
		payload
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload
	};
}

export function resetState() {
	return {
		type: RESET_STATE
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW
	};
}
