'use strict';

/**
 * @function cleanData
 * @memberOf SERVICES.__models
 * @description
 * Cleans data before sending it to the API - basically interprets data formatted by .formatItem
 * @param {object} data  data to clean
 */

import _each from 'lodash/each';
import _has from 'lodash/has';
import _isObject from 'lodash/isObject';
import _find from 'lodash/find';
import moment from 'moment';

function dataType(type, prop) {
	switch (type) {
		case 'date':
			return moment(prop.data).format('YYYY-MM-DD');
		case 'time':
			return moment(prop.data).format('HH:mm:ss');
		case 'datetime':
			return moment.utc(prop.data).format('YYYY-MM-DDTHH:mm:ssZ');
		case 'timetime':
			return moment.utc(prop.data).format('YYYY-MM-DDTHH:mm:ssZ');
		case 'select':
			return _isObject(prop.data) ? prop.data.value : prop.data;
		case 'multi':
			return prop;
		case 'integer':
			return parseInt(prop, 10);
		case 'currency':
		case 'number':
			return parseFloat(prop.data.toString().split(',').join('.'));
		default:
			return _isObject(prop) ? prop.data : prop;
	}
}

module.exports = function (data) {
	var cleanData = {};
	var state = this.getState();

	// Loop through all data objects
	_each(data, function (prop, name) {
		// Find header via unique header hash
		var header = _find(state.headers, { hash: prop.hash });

		// Continue if header is present
		if (header) {
			// Get data to store from prop, based on type
			var dataToStore = dataType(header.type, prop);

			// We need to be able to return data the same way we got it, e.g. in nested objects.
			// We can check for this structure by examining if a given header:
			// a) has a header.reference
			// b) that the header.reference does not have a list property
			// List properties are used when the data item should be loaded directly from another
			// list service, and the data should then be sent back as the primary header e.g. cleanData[header.name]
			if (
				_has(header, 'reference') &&
				(!_has(header.reference, 'list') || _has(header.reference, 'nested'))
			) {
				// Check if child object is already created in cleanData. If not then create it.
				if (_has(cleanData, header.name))
					cleanData[header.name][header.reference.key] = dataToStore;
				else {
					cleanData[header.name] = {};
					cleanData[header.name][header.reference.key] = dataToStore;
				}
			}

			// Set cleanData as header.name
			else cleanData[header.name] = dataToStore;
		}

		// No header was present, lets just send it anyway
		else cleanData[name] = prop;
	});

	return cleanData;
};
