import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './ingredientAssociationWithProducts.phrases';

/**
 * @function fetchProductVariantIngredientVariations
 * @description Fetch Product variant ingredient variations
 * @param {number} productVariantId
 * @param {object} state
 */
export function fetchProductVariantIngredientVariations(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get(
		`/pos/pos_configuration_product_variant_ingredient_variations`,
		params,
		null,
		state.headers
	)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteProductVariantIngredientVariations
 * @description delete
 */
export function deleteProductVariantIngredientVariations(ingredientId) {
	return remove(
		`/pos/pos_configuration_product_variant_ingredient_variations/${ingredientId}`
	)
		.then(res => {
			setFeedback(phrases.INGREDIENT_ASSOCIATION_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function addProductVariantIngredientVariations
 * @description add
 */
export function addProductVariantIngredientVariations(data) {
	return post(
		`/pos/pos_configuration_product_variant_ingredient_variations`,
		data
	)
		.then(res => {
			setFeedback(phrases.INGREDIENT_ASSOCIATION_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}
