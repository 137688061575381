'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { Input } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';

import ProductRecommendationProductVariants from './components/productRecommendationProductVariants/productRecommendationProductVariants.component';

import {
	fetchProductRecommendation,
	addProductRecommendation,
	deleteProductRecommendation,
	editProductRecommendation,
} from './productRecommendations.service';

import {
	setProductRecommendation,
	updateProductRecommendation,
	resetState,
} from './store/productRecommendations.actions';

// constants/phrases/tools
import phrases from './productRecommendations.phrases';
import _get from 'lodash/get';

class ProductRecommendations extends Component {
	constructor(props) {
		super(props);

		this.fetchData = this.fetchData.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);

		this.reduxKey = '/pos/product_recommendations';
		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: (d) => _get(d, 'description', ''),
				filterPath: ':description',
			},
		];
	}

	deleteEntry(id) {
		return deleteProductRecommendation(id);
	}

	editEntry() {
		const { productRecommendation } = this.props;

		const payload = {
			id: productRecommendation.id,
			name: productRecommendation.name,
			...(productRecommendation.description && {
				description: productRecommendation.description,
			}),
		};

		return editProductRecommendation(payload);
	}

	addEntry() {
		const { productRecommendation } = this.props;

		const payload = {
			name: productRecommendation.name,
			description: productRecommendation.description,
		};

		return addProductRecommendation(payload);
	}

	setInitialEditValues(data) {
		const { setProductRecommendation } = this.props;

		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
		};

		setProductRecommendation(payload);
	}

	getEditableCells() {
		const { productRecommendation } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Name"
						value={productRecommendation.name || null}
						onChange={(event) => this.editStoreEntry('name', event)}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Description"
						value={productRecommendation.description || null}
						onChange={(event) => this.editStoreEntry('description', event)}
					/>
				),
			},
		];
	}

	editStoreEntry(name, event) {
		const { updateProductRecommendation } = this.props;

		const value = event.target.value;

		const payload = {
			[name]: value,
		};

		updateProductRecommendation(payload);
	}

	fetchData(state) {
		return fetchProductRecommendation(state);
	}

	render() {
		const {
			// batch props
			onInitialization,
			reduxKey: batchReduxKey,
			style,
			defaultPageSize,
			batchSelection,
			enableSingleSelection,
		} = this.props;

		return (
			<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.TABLE_TITLE}
				columns={this.columns}
				fetchData={this.fetchData}
				defaultPageSize={batchSelection ? defaultPageSize : 10}
				reduxKey={batchSelection ? batchReduxKey : this.reduxKey}
				style={batchSelection ? style : {}}
				{...(batchSelection && { onInitialization })}
				batchSelection={batchSelection}
				enableSingleSelection={enableSingleSelection}
				filterable
				manual
				editableCells={this.getEditableCells()}
				actionsWidth={60}
				createEntry={this.addEntry}
				editEntry={this.editEntry}
				deleteEntry={this.deleteEntry}
				editMultiple={this.editMultiple}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetState}
				subcomponent={(row) => {
					return (
						<SubTable>
							<ProductRecommendationProductVariants
								productRecommendationId={row.original.id}
							/>
						</SubTable>
					);
				}}
			/>
		);
	}
}

ProductRecommendations.propTypes = {
	setProductRecommendation: PropTypes.func,
	resetState: PropTypes.func,
	updateProductRecommendation: PropTypes.func,
	productRecommendation: PropTypes.object,
	onInitialization: PropTypes.func,
	reduxKey: PropTypes.string,
	style: PropTypes.object,
	defaultPageSize: PropTypes.number,
	batchSelection: PropTypes.bool,
	enableSingleSelection: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setProductRecommendation,
			updateProductRecommendation,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		productRecommendation:
			store.salesConfigurationProductRecommendations.data.productRecommendation,
	};
};

export default connectWithStore(
	ProductRecommendations,
	mapStateToProps,
	mapDispatchToProps
);
