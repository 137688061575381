'use strict';

/**
 * @namespace __listModel
 * @memberOf SERVICES
 * @description
 * Model for all list components
 * @example
 * var list = new __listModel();
 */

// Tools
import { saveAs } from 'file-saver';
import _each from 'lodash/each';
import _every from 'lodash/every';
import _extend from 'lodash/extend';
import _filter from 'lodash/filter';
import _has from 'lodash/has';
import _isNull from 'lodash/isNull';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import _merge from 'lodash/merge';
import _some from 'lodash/some';
import _findIndex from 'lodash/findIndex';
import _union from 'lodash/union';
import _pickBy from 'lodash/pickBy';
import _isNaN from 'lodash/isNaN';
import _size from 'lodash/size';
import _reject from 'lodash/reject';
import { confirmModal } from 'utils';

// Services
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service';
import * as translations from 'translations.service';
import moment from 'moment';
import store from 'appState/store';
import { push } from 'redux-first-history';
import { fetchCompleteCollection as fcc } from 'services/api/fetchCompleteCollection';

const __apiFilter = require('apiFilter.service');

const dispatchers = require('./list.dispatchers');
const defaults = require('./list.defaults');

// Services
const __enums = require('enums.service');
/// //////////////
// Constructor //
/// //////////////

export default class ListModel {
	constructor(reduxKey) {
		this.reduxKey = reduxKey;
		return this;
	}

	/// ///////////////////////////////
	// Action dispatchers / Setters //
	/// ///////////////////////////////

	initReducer() {
		return store.dispatch(dispatchers.initReducer({ reduxKey: this.reduxKey }));
	}

	setAccordions(accordions) {
		return store.dispatch(dispatchers.setAccordions({ reduxKey: this.reduxKey, accordions }));
	}

	setAccordionsBase(accordionsBase) {
		return store.dispatch(dispatchers.setAccordionsBase({ reduxKey: this.reduxKey, accordionsBase }));
	}

	setCollection(collection) {
		return store.dispatch(dispatchers.setCollection({ reduxKey: this.reduxKey, collection }));
	}

	setError(error) {
		return store.dispatch(dispatchers.setError({ reduxKey: this.reduxKey, error }));
	}

	setCollectionLength(collectionLength) {
		return store.dispatch(
			dispatchers.setCollectionLength({
				reduxKey: this.reduxKey,
				collectionLength,
			})
		);
	}

	setData(data) {
		return store.dispatch(dispatchers.setData({ reduxKey: this.reduxKey, data }));
	}

	setDelayedRefresh(delayedRefresh) {
		return store.dispatch(dispatchers.setDelayedRefresh({ reduxKey: this.reduxKey, delayedRefresh }));
	}

	setEditRowId(editRowId) {
		return store.dispatch(dispatchers.setEditRowId({ reduxKey: this.reduxKey, editRowId }));
	}

	setFilterFilterBar(filterBarFilter) {
		return store.dispatch(
			dispatchers.setFilterFilterBar({
				reduxKey: this.reduxKey,
				filterBarFilter,
			})
		);
	}

	setFilterOperators(filterOperators) {
		return store.dispatch(
			dispatchers.setFilterOperators({
				reduxKey: this.reduxKey,
				filterOperators,
			})
		);
	}

	setFilterQuery(queryFilter) {
		return store.dispatch(dispatchers.setFilterQuery({ reduxKey: this.reduxKey, queryFilter }));
	}

	setFilterSearch(searchFilter) {
		return store.dispatch(dispatchers.setFilterSearch({ reduxKey: this.reduxKey, searchFilter }));
	}

	setFilters(filters) {
		return store.dispatch(dispatchers.setFilters({ reduxKey: this.reduxKey, filters }));
	}

	setHotbar(hotbar) {
		return store.dispatch(dispatchers.setHotbar({ reduxKey: this.reduxKey, hotbar }));
	}

	setHeaderFilterBar(headerFilterBar, indexHeader, indexFilter) {
		return store.dispatch(
			dispatchers.setHeaderFilterBar({
				reduxKey: this.reduxKey,
				headerFilterBar,
				indexHeader,
				indexFilter,
			})
		);
	}

	setHeaders(headers) {
		return store.dispatch(dispatchers.setHeaders({ reduxKey: this.reduxKey, headers }));
	}

	setIsAdding(isAdding) {
		return store.dispatch(dispatchers.setIsAdding({ reduxKey: this.reduxKey, isAdding }));
	}

	setIsBarFiltering(isBarFiltering) {
		return store.dispatch(dispatchers.setIsBarFiltering({ reduxKey: this.reduxKey, isBarFiltering }));
	}

	setIsCollapsed(isCollapsed) {
		return store.dispatch(dispatchers.setIsCollapsed({ reduxKey: this.reduxKey, isCollapsed }));
	}

	setIsCreating(isCreating) {
		return store.dispatch(dispatchers.setIsCreating({ reduxKey: this.reduxKey, isCreating }));
	}

	setIsColumnSelecting(isColumnSelecting) {
		return store.dispatch(
			dispatchers.setIsColumnSelecting({
				reduxKey: this.reduxKey,
				isColumnSelecting,
			})
		);
	}

	setIsCSVDateRangeVisible(isCSVDateRangeVisible) {
		return store.dispatch(
			dispatchers.setIsCSVDateRangeVisible({
				reduxKey: this.reduxKey,
				isCSVDateRangeVisible,
			})
		);
	}

	setIsEditing(isEditing) {
		return store.dispatch(dispatchers.setIsEditing({ reduxKey: this.reduxKey, isEditing }));
	}

	setIsExporting(isExporting) {
		return store.dispatch(dispatchers.setIsExporting({ reduxKey: this.reduxKey, isExporting }));
	}

	setIsSearchFiltering(isSearchFiltering) {
		return store.dispatch(
			dispatchers.setIsSearchFiltering({
				reduxKey: this.reduxKey,
				isSearchFiltering,
			})
		);
	}

	setIsSearchList(isSearchList) {
		return store.dispatch(dispatchers.setIsSearchList({ reduxKey: this.reduxKey, isSearchList }));
	}

	setListActions(listActions) {
		return store.dispatch(dispatchers.setListActions({ reduxKey: this.reduxKey, listActions }));
	}

	setParamLimit(limit) {
		return store.dispatch(dispatchers.setParamLimit({ reduxKey: this.reduxKey, limit }));
	}

	setParamOffset(offset) {
		return store.dispatch(dispatchers.setParamOffset({ reduxKey: this.reduxKey, offset }));
	}

	setParamSort(sort) {
		return store.dispatch(dispatchers.setParamSort({ reduxKey: this.reduxKey, sort }));
	}

	setParams(params) {
		return store.dispatch(dispatchers.setParams({ reduxKey: this.reduxKey, params }));
	}

	setRowActions(rowActions) {
		return store.dispatch(dispatchers.setRowActions({ reduxKey: this.reduxKey, rowActions }));
	}

	setShowList(showList) {
		return store.dispatch(dispatchers.setShowList({ reduxKey: this.reduxKey, showList }));
	}

	setSelectedRowId(selectedRowId) {
		return store.dispatch(dispatchers.setSelectedRowId({ reduxKey: this.reduxKey, selectedRowId }));
	}

	setSortColumn(column) {
		return store.dispatch(dispatchers.setSortColumn({ reduxKey: this.reduxKey, column }));
	}

	setSortOrder(order) {
		return store.dispatch(dispatchers.setSortOrder({ reduxKey: this.reduxKey, order }));
	}

	setTitle(title) {
		return store.dispatch(dispatchers.setTitle({ reduxKey: this.reduxKey, title }));
	}

	setPrintViewHeader(title, subtitle) {
		return store.dispatch(
			dispatchers.setPrintViewHeader({
				reduxKey: this.reduxKey,
				title,
				subtitle,
			})
		);
	}

	togglePrint(togglePrintView) {
		return store.dispatch(dispatchers.togglePrint({ reduxKey: this.reduxKey, togglePrintView }));
	}

	/// //////////////////
	// Helpers, shared //
	/// //////////////////
	initApiErrorHeaders(headers, errors) {
		return require('helpers/initApiErrorHeaders').call(this, headers, errors);
	}

	initDefaultHeaders(headers) {
		return require('helpers/initDefaultHeaders').call(this, translations, headers);
	}

	initDefaultHeaderPermissions(headers) {
		return require('helpers/initDefaultHeaderPermissions').call(this, headers);
	}

	initHeaderEnumOptions(headers) {
		return require('helpers/initHeaderEnumOptions').call(this, headers);
	}

	initHeaderFocus(headers) {
		return require('helpers/initHeaderFocus').call(this, headers);
	}

	initHeaderHash(headers) {
		return require('helpers/initHeaderHash').call(this, headers);
	}

	initHeaderReferenceName(headers) {
		return require('helpers/initHeaderReferenceName').call(this, headers);
	}

	initHeaderTranslations(headers) {
		return require('helpers/initHeaderTranslations').call(this, translations, headers);
	}

	cleanData(data) {
		return require('helpers/cleanData').call(this, data);
	}

	collectionFormat(collection) {
		return require('helpers/collectionFormat').call(this, collection);
	}

	fetchCollection(endpoint, params) {
		return require('helpers/fetchCollection').call(this, endpoint, params);
	}

	fetchCompleteCollection(endpoint, params, headers) {
		return fcc(endpoint, params, headers);
	}

	itemCreate(data) {
		return require('helpers/itemCreate').call(this, data);
	}

	itemDelete(id) {
		return require('helpers/itemDelete').call(this, id);
	}

	itemFormat(item) {
		return require('helpers/itemFormat').call(this, item);
	}

	itemUpdate(data, id) {
		return require('helpers/itemUpdate').call(this, data, id);
	}

	runModelCallbacks() {
		return require('helpers/runModelCallbacks').call(this);
	}

	calculateSumRow() {
		return require('./helpers/calculateSumRow').call(this);
	}

	initAccordionTranslations(accordions) {
		return require('./helpers/initAccordionTranslations').call(this, translations, accordions);
	}

	initColGroupTranslations(headers) {
		return require('./helpers/initColGroupTranslations').call(this, translations, headers);
	}

	initFilterBars(headers) {
		return require('./helpers/initFilterBars').call(this, headers);
	}

	initHeaderSort(headers) {
		return require('./helpers/initHeaderSort').call(this, headers);
	}

	filterBarSearch(header, filter, operator, filterId, sortType) {
		return require('./helpers/filterBarSearch').call(this, header, filter, operator, filterId, sortType);
	}

	filterSearch(query) {
		return require('./helpers/filterSearch').call(this, query);
	}

	loadCollection() {
		return require('./helpers/loadCollection').call(this);
	}

	loadListCollapsed() {
		return require('./helpers/loadListCollapsed').call(this);
	}

	loadLocalHeaders(headers) {
		return require('./helpers/loadLocalHeaders').call(this, headers);
	}

	paginationNavigate(indicator) {
		return require('./helpers/paginationNavigate').call(this, indicator);
	}

	refreshCollection() {
		return require('./helpers/refreshCollection').call(this);
	}

	sortByColumn(header, sortOrder) {
		return require('./helpers/sortByColumn').call(this, header, sortOrder);
	}

	toggleAccordion(itemId, useFilter) {
		return require('./helpers/toggleAccordion').call(this, itemId, useFilter);
	}

	toggleColumn(header) {
		return require('./helpers/toggleColumn').call(this, header);
	}

	toggleFilterBarElement(header, filterId) {
		return require('./helpers/toggleFilterBarElement').call(this, header, filterId);
	}

	toggleListCollapse() {
		return require('./helpers/toggleListCollapse').call(this);
	}

	/// //////////////////////////
	// Model specific handlers //
	/// //////////////////////////
	/**
	 * @function handleCountRowActions
	 * @memberOf SERVICES.__listModel
	 * @description handler for counting row actions
	 */
	handleCountRowActions() {
		const state = this.getState();
		let validRowActions = 0;
		_each(state.rowActions(this.getProps(), {}), function (action) {
			if (_every(action.validExpressions)) validRowActions = validRowActions + 1;
		});
		_each(state.headers, function (header) {
			if (_has(header, 'action')) validRowActions = validRowActions + 1;
		});
		return validRowActions;
	}

	/**
	 * @function handleCSVExport
	 * @memberOf SERVICES.__listModel
	 * @description handler for exporting to csv
	 */
	handleCSVExport() {
		const state = this.getState();
		const settings = this.getSettings();
		const collectionParams = _extend({}, state.params, __apiFilter.concatenate(state.filters));

		const csvHeader = {
			Accept: 'application/csv',
		};

		this.fetchCompleteCollection(settings.endpoint.index.path, collectionParams, csvHeader).then((csv) => {
			const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
			const fileName = `${state.title}-${moment().format('YYYYMMDD_HHmmss')}.csv`;
			saveAs(blob, fileName);
		});
	}

	/**
	 * @function handleCSVExportWithRange
	 * @memberOf SERVICES.__listModel
	 * @description handler for exporting a csv with a selected date range. This function only handles downloading
	 * the CSV with a passed in date range and closing the date rande model but does not handle the opening of the
	 * CSV date range modal.
	 * @param  {object} args arguments containing 2 dates: to and from. {to: 'YYYY-MM-DD', from: 'YYYY-MM-DD'}
	 */
	handleCSVExportWithRange(params) {
		const state = this.getState();
		const settings = this.getSettings();

		const collectionParams = _extend({}, state.params, params, __apiFilter.concatenate(state.filters));

		const csvHeader = {
			Accept: 'application/csv',
		};

		this.fetchCompleteCollection(settings.endpoint.index.path, collectionParams, csvHeader)
			.then((csv) => {
				const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
				const fileName = `${state.title}-${params.from}/${params.to}.csv`;
				saveAs(blob, fileName);
				this.setIsCSVDateRangeVisible(!state.isCSVDateRangeVisible);
			})
			.catch((error) => {
				const errorMsg = formatErrorMessage(error);
				setFeedback(errorMsg, 0);
			});
	}

	/**
	 * @function handleSetIsCSVDateRangeVisible
	 * @memberOf SERVICES.__listModel
	 * @description handler for opening csv date range modal
	 */
	handleSetIsCSVDateRangeVisible() {
		const state = this.getState();
		this.setIsCSVDateRangeVisible(!state.isCSVDateRangeVisible);
	}

	/**
	 * @function handleToggleCSVDownload
	 * @memberOf SERVICES.__listModel
	 * @description handler for opening printView
	 */
	handleTogglePrintView() {
		const togglePrintView = this.getState().togglePrintView;
		this.togglePrint(!togglePrintView);
	}

	/**
	 * @function handleCreateEditItem
	 * @memberOf SERVICES.__listModel
	 * @description handler for creating and editing items - returns promise as notification
	 * @param  {object} args arguments containing data to create or update items with
	 */
	handleCreateEditItem(args) {
		return new Promise((resolve, reject) => {
			const state = this.getState();
			const collection = state.collection;
			if (args.type === 'add') {
				this.itemCreate(args.headers)
					.then(
						function (item) {
							// Add data to top of current collection so user easily can see it is added
							collection.unshift(item);
							this.setCollection(collection);

							// Set new length
							this.setCollectionLength(state.collectionLength + 1);

							// Disable adding row
							this.setIsAdding(false);
							setFeedback(translations.getSingle('COMPONENTS.DETAILS.CREATED'), 1);
							resolve(item);
						}.bind(this)
					)
					.catch((err) => {
						reject(err);
					});
			} else if (args.type === 'edit') {
				this.itemUpdate(args.headers, args.id)
					.then(
						function (item) {
							// Update row in collection
							const itemIndex = _findIndex(collection, function (c) {
								return c.id === args.id;
							});
							collection[itemIndex] = item;
							this.setCollection(collection);

							// Disable editing row
							this.setIsEditing(false);
							this.setEditRowId(null);
							setFeedback(translations.getSingle('COMPONENTS.DETAILS.UPDATED'), 1);
							resolve();
						}.bind(this)
					)
					.catch((err) => {
						reject(err);
					});
			}
		});
	}

	/**
	 * @function handleDeleteItem
	 * @memberOf SERVICES.__listModel
	 * @description handler for deleting items
	 * @param  {object} item item to delete
	 */
	handleDeleteItem(item, event) {
		if (event) event.stopPropagation();

		confirmModal.show({
			data: {
				header: translations.getSingle('COMPONENTS.DETAILS.CONFIRM_DELETE_TITLE'),
				subheader: translations.getSingle('COMPONENTS.DETAILS.CONFIRM_DELETE_MESSAGE'),
				confirmLabel: translations.getSingle('COMPONENTS.DETAILS.CONFIRM_DELETE_RESOLVE'),
				cancelLabel: translations.getSingle('COMPONENTS.DETAILS.CONFIRM_DELETE_REJECT'),
			},
			onConfirm: () => {
				return this.itemDelete(item.id).then((id) => {
					const state = this.getState();

					// Set new collection (Update collection by removing id)
					this.setCollection(_reject(state.collection, { id }));

					// Set new collection length
					this.setCollectionLength(state.collectionLength - 1);
					setFeedback(translations.getSingle('COMPONENTS.DETAILS.DELETED'), 1);
				});
			},
			onCancel: (error) => {
				Error('Something went wrong handleUnassignClocking', error);
			},
		});
	}

	/**
	 * @function handleFilterBarSearch
	 * @memberOf SERVICES.__listModel
	 * @description handler for searches in filterbar - wraps listmodel method and updates collectionData
	 * @param  {object} header   header to add filter to
	 * @param  {string} filter   query (can be object)
	 * @param  {object} operator which operator to use
	 * @param  {string} filterId unique filter id
	 */
	handleFilterBarSearch(header, filter, operator, filterId, sortType) {
		this.filterBarSearch(header, filter, operator, filterId, sortType)
			.then(
				function () {
					return this.loadCollection();
				}.bind(this)
			)
			.then(
				function (collectionData) {
					this.setCollection(collectionData[0]);
					this.setCollectionLength(collectionData[1]);
				}.bind(this)
			);
	}

	/**
	 * @function handleFilterSearch
	 * @memberOf SERVICES.__listModel
	 * @description handler for searches in filter - wraps listmodel method and updates collectionData
	 * @param  {object} header   header to add filter to
	 */
	handleFilterSearch(query) {
		this.filterSearch(query).then(
			function (collectionData) {
				this.setCollection(collectionData[0]);
				this.setCollectionLength(collectionData[1]);
			}.bind(this)
		);
	}

	/**
	 * @function handleMouseOverAction
	 * @memberOf SERVICES.__listModel
	 * @description handler for setting which
	 * @param  {object} action   action index which is currently beeing hovered
	 */
	handleMouseOverAction(action) {
		this.setMouseOverAction(action);
	}

	/**
	 * @function handleNavigate
	 * @memberOf SERVICES.__listModel
	 * @description handler for navigating to difference states from components
	 * @param {string} where where to go to
	 * @param {number} id    if there is an id to navigate to
	 */
	handleNavigate(where, item, event) {
		if (event) event.stopPropagation();

		switch (where) {
			case 'create':
				store.dispatch(push(`${window.location.pathname}/new`));

				break;
			case 'details':
				store.dispatch(push(`${window.location.pathname}/${item.id}`));

				break;
		}
	}

	/**
	 * @function handlePaginationNavigate
	 * @memberOf SERVICES.__listModel
	 * @description handler for navigation in pagination - wraps listmodel method and updates collectionData
	 * @param  {string} indicator indicator as to where to navigate to in pagination
	 */
	handlePaginationNavigate(indicator) {
		this.paginationNavigate(indicator).then(
			function (collectionData) {
				this.setCollection(collectionData[0]);
				this.setCollectionLength(collectionData[1]);
			}.bind(this)
		);
	}

	/**
	 * @function handleRefreshCollection
	 * @memberOf SERVICES.__listModel
	 * @description handler for refreshing collection - updates collectionData and returns promise as notification
	 * Also updates accordions if any are present on the list
	 * @return {object} promise
	 */
	handleRefreshCollection() {
		return new Promise((resolve, reject) => {
			const state = this.getState();
			this.loadCollection().then(
				function (collectionData) {
					this.setCollection(collectionData[0]);
					this.setCollectionLength(collectionData[1]);

					// Update any accordions as well
					if (_size(state.accordions) > 0) {
						Promise.all(
							_each(state.accordions, function (accordionObject) {
								return _map(accordionObject.accordions, function (accordion) {
									return accordion.methods.handleRefreshCollection();
								});
							})
						).then(function () {
							resolve();
						});
					} else resolve();
				}.bind(this)
			);
		});
	}

	/**
	 * @function handleResetList
	 * @memberOf SERVICES.__listModel
	 * @description resets list to initial state and settings
	 */
	handleResetList() {
		this.init();
		this.setDelayedRefresh(true);
	}

	/**
	 * @function handleReturnSumRow
	 * @memberOf SERVICES.__listModel
	 * @description returns sum row for headers that has summarize
	 * @return {array} sum row numbers for rendering in components
	 */
	handleReturnSumRow() {
		return this.calculateSumRow();
	}

	/**
	 * @function handleReturnVisibleHeaders
	 * @memberOf SERVICES.__listModel
	 * @description returns visible headers based on rules set below
	 * @return {array} visible headers for rendering in components
	 */
	handleReturnVisibleHeaders(required) {
		const state = this.getState();
		const headers = state.headers;
		return _filter(headers, function (header) {
			if (required && header.required) return true;
			else return !_some([header.localHidden, header.onlyFilterBar, header.alwaysHidden]);
		});
	}

	/**
	 * @function handleSelectRow
	 * @memberOf SERVICES.__listModel
	 * @description handler for selecting row
	 * @param  {number} id row id to select
	 */
	handleSelectRow(id) {
		const state = this.getState();
		const setSelectedRowId = _isNull(state.selectedRowId) || state.selectedRowId !== id ? id : null;
		this.setSelectedRowId(setSelectedRowId);
	}

	/**
	 * @function handleSortByColumn
	 * @memberOf SERVICES.__listModel
	 * @description handler for sorting lists by columns - wraps listmodel method and updates collectionData
	 * @param  {object} column column (header) to sort by
	 * @param  {string} order  asc or desc
	 */
	handleSortByColumn(column, order) {
		this.sortByColumn(column, order).then(
			function (collectionData) {
				this.setSortColumn(column);
				this.setSortOrder(order);
				this.setCollection(collectionData[0]);
				this.setCollectionLength(collectionData[1]);
			}.bind(this)
		);
	}

	/**
	 * @function handleToggleAccordion
	 * @memberOf SERVICES.__listModel
	 * @description handler for toggling accordions - wraps listmodel method and updates accordion state
	 * @param  {number} itemId toggle accordion for itemId
	 */
	handleToggleAccordion(item, event) {
		if (event) event.stopPropagation();
		this.toggleAccordion(item.id).then(
			function (accordions) {
				this.setAccordions(accordions);
			}.bind(this)
		);
	}

	/**
	 * @function handleToggleAddEditRow
	 * @memberOf SERVICES.__listModel
	 * @description handler for toggling add or edit row
	 * @param  {string} type either add or edit
	 * @param  {object} item item to edit if type = edit
	 */
	handleToggleAddEditRow(type, item, event) {
		if (event) event.stopPropagation();
		const state = this.getState();
		if (_size(_pickBy(state.data, _isNaN)) === 0) {
			switch (type) {
				case 'add':
					this.setIsEditing(false);
					this.setIsAdding(!state.isAdding);
					this.setEditRowId(null);

					break;
				case 'edit':
					this.setIsAdding(false);
					if (_isNull(state.editRowId)) this.setIsEditing(!state.isEditing);
					this.setEditRowId(item.id);

					break;
				default:
					this.setIsAdding(false);
					this.setIsEditing(false);
					this.setEditRowId(null);

					break;
			}
		} else setFeedback(translations.getSingle('COMPONENTS.LIST.ADDROW_CREATE_FIRST'), 0);
	}

	/**
	 * @function handleToggleIsBarFiltering
	 * @memberOf SERVICES.__listModel
	 * @description handler for toggling bar filtering - disallow if any filters are present
	 */
	handleToggleIsBarFiltering() {
		const state = this.getState();
		const activeHeaders = _some(state.headers, function (header) {
			return _some(header.filterBar, 'active');
		});
		if (state.isBarFiltering && activeHeaders) {
			setFeedback(translations.getSingle('COMPONENTS.FILTER_BAR.REMOVE_FILTERS'), 0);
		} else this.setIsBarFiltering(!state.isBarFiltering);
	}

	/**
	 * @function handleToggleControls
	 * @memberOf SERVICES.__listModel
	 * @description handler for toggling different controls
	 * @param  {string} control control button to toggle
	 */
	handleToggleControls(control) {
		const state = this.getState();
		switch (control) {
			case 'isCollapsed':
				this.setIsCollapsed(!state.isCollapsed);
				this.toggleListCollapse();
				break;
			case 'isColumnSelecting':
				this.setIsColumnSelecting(!state.isColumnSelecting);
				break;
			case 'isSearchFiltering':
				this.setIsSearchFiltering(!state.isSearchFiltering);
				break;
		}
	}

	/**
	 * @function handleToggleColumn
	 * @memberOf SERVICES.__listModel
	 * @description handler for toggling columns in lists
	 * @param  {object} header column (header) to toggle
	 */
	handleToggleColumn(header) {
		this.toggleColumn(header).then(
			function (header) {
				const headers = this.getState().headers;
				const index = _findIndex(headers, function (h) {
					return h.hash === header.hash;
				});
				headers[index] = header;
				this.setHeaders(headers);
			}.bind(this)
		);
	}

	/**
	 * @function handleToggleFilterBarElement
	 * @memberOf SERVICES.__listModel
	 * @description handler for toggling filterbar elements
	 * @param  {object} header   header to base filterbar element on
	 * @param  {string} filterId unique filter id
	 */
	handleToggleFilterBarElement(header, filterId) {
		this.toggleFilterBarElement(header, filterId).then(
			function (header) {
				const headers = this.getState().headers;
				const index = _findIndex(headers, function (h) {
					return h.hash === header.hash;
				});
				headers[index] = header;
				this.setHeaders(headers);
			}.bind(this)
		);
	}

	/**
	 * @function handleToggleExportList
	 * @memberOf SERVICES.__listModel
	 * @description handler for toggling export list
	 */
	handleToggleExportList() {
		const state = this.getState();
		this.setIsExporting(!state.isExporting);
	}

	/// /////////////////////////////////////////////////
	// Props = State, Methods, Translations, Settings //
	/// /////////////////////////////////////////////////
	/**
	 * @function getMethods
	 * @memberOf SERVICES.__listModel
	 * @description returns handler methods for components
	 * @return {object} handler methods
	 */
	getMethods() {
		return {
			feedback: setFeedback,
			handleCountRowActions: this.handleCountRowActions.bind(this),
			handleCSVExport: this.handleCSVExport.bind(this),
			handleCSVExportWithRange: this.handleCSVExportWithRange.bind(this),
			handleCreateEditItem: this.handleCreateEditItem.bind(this),
			handleToggleExportList: this.handleToggleExportList.bind(this),
			handleDeleteItem: this.handleDeleteItem.bind(this),
			handleFilterBarSearch: this.handleFilterBarSearch.bind(this),
			handleFilterSearch: this.handleFilterSearch.bind(this),
			handleNavigate: this.handleNavigate.bind(this),
			handleMouseOverAction: this.handleMouseOverAction.bind(this),
			handlePaginationNavigate: this.handlePaginationNavigate.bind(this),
			handleRefreshCollection: this.handleRefreshCollection.bind(this),
			handleResetList: this.handleResetList.bind(this),
			handleReturnSumRow: this.handleReturnSumRow.bind(this),
			handleReturnVisibleHeaders: this.handleReturnVisibleHeaders.bind(this),
			handleSelectRow: this.handleSelectRow.bind(this),
			handleSortByColumn: this.handleSortByColumn.bind(this),
			handleToggleAccordion: this.handleToggleAccordion.bind(this),
			handleToggleAddEditRow: this.handleToggleAddEditRow.bind(this),
			handleToggleColumn: this.handleToggleColumn.bind(this),
			handleToggleControls: this.handleToggleControls.bind(this),
			handleToggleIsBarFiltering: this.handleToggleIsBarFiltering.bind(this),
			handleToggleFilterBarElement: this.handleToggleFilterBarElement.bind(this),
			handleInitView: this.initView.bind(this),
			handleTogglePrintView: this.handleTogglePrintView.bind(this),
			handleSetIsCSVDateRangeVisible: this.handleSetIsCSVDateRangeVisible.bind(this),
		};
	}

	/**
	 * @function getSettings
	 * @memberOf SERVICES.__listModel
	 * @description returns settings for components
	 * @return {object} settings
	 */
	getSettings() {
		return this.settings;
	}

	/**
	 * @function getState
	 * @memberOf SERVICES.__listModel
	 * @description returns state for components
	 * @return {object} current state
	 */
	getState() {
		return store.getState()?.legacyListData[this.reduxKey];
	}

	/**
	 * @function getTranslations
	 * @memberOf SERVICES.__listModel
	 * @description returns translations for components
	 * @return {object} translations
	 */
	getTranslations() {
		return translations.getObject([
			'COMPONENTS.FILTER_BAR.ADD_FILTER',
			'COMPONENTS.INPUTS.INPUT__REPEAT',
			'COMPONENTS.INPUTS.INVALID_FIELDS',
			'COMPONENTS.INPUTS.REQUIRED',
			'COMPONENTS.INPUTS.SELECT__NO_RESULTS',
			'COMPONENTS.INPUTS.SELECT__SEARCH',
			'COMPONENTS.LIST.ALL',
			'COMPONENTS.LIST.SIGN',
			'COMPONENTS.LIST.ADD',
			'COMPONENTS.LIST.COLLAPSE',
			'COMPONENTS.LIST.COLUMNS',
			'COMPONENTS.LIST.DELETE',
			'COMPONENTS.LIST.EDIT',
			'COMPONENTS.LIST.EXPORT',
			'COMPONENTS.LIST.FILTER',
			'COMPONENTS.LIST.FOLD',
			'COMPONENTS.LIST.PICK_A_DATE',
			'COMPONENTS.LIST.REFRESH',
			'COMPONENTS.LIST.SEARCH',
			'COMPONENTS.LIST.CSVEXPORT',
			'COMPONENTS.LIST.PDFEXPORT',
			'COMPONENTS.LIST.DETAILS',
		]);
	}

	/**
	 * @function getProps
	 * @memberOf SERVICES.__listModel
	 * @description returns props for components
	 * @return {object} props
	 */
	getProps() {
		return _extend(this.getState(), {
			methods: this.getMethods(),
			settings: this.getSettings(),
			translations: this.getTranslations(),
		});
	}

	/**
	 * @function getSubset
	 * @memberOf SERVICES.__listModel
	 * @description returns subset of list, mostly used in other services where the full list slows down the app
	 * @return {array} subset
	 */
	getSubset(subset) {
		const listSubset = {};
		_each(
			subset,
			function (item) {
				listSubset[item] = this[item].bind(this);
			}.bind(this)
		);
		return listSubset;
	}

	/// /////////////
	// Initiation //
	/// /////////////
	/**
	 * @function initView
	 * @memberOf SERVICES.__listModel
	 * @description
	 * Initiates view
	 */
	initView(complete, params = {}) {
		return new Promise((resolve, reject) => {
			const state = this.getState();
			const settings = this.getSettings();

			if (state.delayedRefresh || state.alwaysRefresh || settings.alwaysRefresh || state.isSearchList) {
				// Reset params
				if (state.delayedRefresh || settings.alwaysRefresh) {
					this.setParams(_merge({}, defaults.params, state.params, this.params, params));
				}

				// Reset delayedRefresh
				if (state.delayedRefresh) this.setDelayedRefresh(false);

				this.loadCollection(complete).then(
					function (collectionData) {
						if (!_isUndefined(collectionData)) {
							this.setCollection(collectionData[0]);
							this.setCollectionLength(collectionData[1]);

							this.setShowList(true);
							resolve();
						} else reject();
					}.bind(this),
					function (err) {
						err = err.data ? err.data : err;
						this.setError(err);
					}.bind(this)
				);
			}
		});
	}

	/**
	 * @function init
	 * @memberOf SERVICES.__listModel
	 * @description
	 * Initiates model: settings, headers, view
	 */
	init() {
		// Make defaultState this state
		this.state = _merge({}, defaults.state);

		// Merge default settings and model settings
		this.settings = _merge({}, defaults.settings, this.settings);

		// if (
		// 	!this.reduxKey.includes(this.settings?.endpoint?.index?.path) ||
		// 	!this.settings?.endpoint?.index?.path.includes(this.reduxKey)
		// ) {
		// 	console.log(this.settings.name);
		// }

		this.initReducer();

		// Make params part of state
		this.setParams(_merge({}, defaults.params, this.params));

		// Make default listActions and model listActions part of state
		this.setListActions(
			function (props) {
				return _has(this, 'listActions')
					? _union(defaults.listActions(props), this.listActions(props))
					: defaults.listActions(props);
			}.bind(this)
		);
		// Make default rowActions and model rowActions part of state

		this.setRowActions(function (props, row) {
			_has(this, 'rowActions')
				? _union(this.rowActions(props, row), defaults.rowActions(props, row))
				: defaults.rowActions(props, row);
		});

		// Make translated title part of state
		this.setTitle(translations.getSingle('LIST_TITLES.' + this.getSettings().translationPath));

		// Make headers part of state
		this.setHeaders(this.headers);

		// Make filterOperators part of state
		this.setFilterOperators(__enums.filterOperators);

		// Make any accordions part of state
		this.setAccordionsBase(this.getSettings().accordions);

		// Check if isBarFiltering
		this.setIsBarFiltering(_some(this.getState().headers, 'filterBar'));

		// Init default headers
		this.initDefaultHeaders(this.getState().headers);

		// Init default header permissions
		this.initDefaultHeaderPermissions(this.getState().headers);

		// Init translated labels for headers
		this.initHeaderTranslations(this.getState().headers);

		// Init reference name to headers
		this.initHeaderReferenceName(this.getState().headers);

		// Init header hash
		this.initHeaderHash(this.getState().headers);

		// Init enum options
		this.initHeaderEnumOptions(this.getState().headers);

		// Init sort of headers based on type
		this.initHeaderSort(this.getState().headers);

		// Init focus to first available header
		this.initHeaderFocus(this.getState().headers);

		// Init translated labels for colGroups
		this.initColGroupTranslations(this.getState().headers);

		// Init translated labels for accordions
		this.initAccordionTranslations(this.getState().accordionsBase);

		// Init filterBars
		this.initFilterBars(this.getState().headers);

		// Load local headers
		this.loadLocalHeaders(this.getState().headers);

		// Load list collapsed
		this.loadListCollapsed();

		// this.initView();
	}
}
