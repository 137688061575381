'use strict';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';
import _compact from 'lodash/compact';

/**
 * @function mapPosConfigurationProductVariantsForDiscounts
 * @param {Object} object - contains our selected values and retrived values from api call
 * @description adds posConfigurationProductVariant object to our mapped data object
 * if it matches for productVariantId and posConfigurationVersionId
 * THIS IS USEFUL BECAUSE LATER WE FILTER OUT VALUES THAT ALREADY HAVE A posConfigurationDiscountProductVariant added
 */
export function mapPosConfigurationProductVariantsForDiscounts({
	discountData,
	posConfigurationProductVariants,
	batchFormData
}) {
	return discountData.map(discount => {
		const productVariantId = _get(discount, 'productVariant.id', null);
		const posConfigurationVersionId = _get(
			discount,
			'posConfigurationDiscount.pos_configuration_version.id',
			null
		);

		const posConfigurationProductVariant = posConfigurationProductVariants.find(
			entry => {
				if (
					entry.pos_configuration_version.id === posConfigurationVersionId &&
					entry.product_variant.id === productVariantId
				)
					return true;
				return false;
			}
		);

		// add discount values based on discount type choosen
		if (_get(batchFormData, 'discountType.value', '') === 'Nominal') {
			discount = {
				...discount,
				toStayDiscount: batchFormData.toStayDiscount,
				toGoDiscount: batchFormData.toGoDiscount
			};
		}
		// percentage choosen
		else {
			const toStayPrice = _get(
				posConfigurationProductVariant,
				'to_stay_price',
				null
			);
			const toGoPrice = _get(
				posConfigurationProductVariant,
				'to_go_price',
				null
			);

			// calculate results
			const calculatedPrices = calculateDiscounts({
				toStayPrice,
				toGoPrice,
				batchFormData
			});

			discount = {
				...discount,
				toStayDiscount: calculatedPrices.toStayDiscount.toString(),
				toGoDiscount: calculatedPrices.toGoDiscount.toString()
			};
		}

		return {
			...discount,
			posConfigurationProductVariant: posConfigurationProductVariant || null
		};
	});
}

/**
 * @function getPosConfigurationProductVariantFilter
 * @param {Objet} object - contains array of mapped values for table in last step
 * @description returns a filter that will get us all posConfigurationProductVariants for any of produtVariant and posConfigurationVersion combo we choose
 */
export function getPosConfigurationProductVariantFilter({
	discountArray,
	productVariantArray
}) {
	// get array of product variant id's
	const productVariantIds = productVariantArray.reduce(
		(acc, currentValue, index) => {
			const id = currentValue.value.id;

			acc =
				index === productVariantArray.length - 1
					? `${acc}'${id}']`
					: `${acc}'${id}',`;
			return acc;
		},
		'['
	);

	// put all pos config versions in an array so we can eliminate duplicates
	let posConfigVersionArray = discountArray.map(entry => {
		return _get(entry, 'value.pos_configuration_version.id', null);
	});

	// in case no pos_configuration_version.id
	posConfigVersionArray = _compact(posConfigVersionArray);
	// we can have multiple same ids
	posConfigVersionArray = _uniq(posConfigVersionArray);

	// get array of pos config version id's
	const posConfigurationVersionIds = posConfigVersionArray.reduce(
		(acc, currentValue, index) => {
			const id = currentValue;

			acc =
				index === posConfigVersionArray.length - 1
					? `${acc}'${id}']`
					: `${acc}'${id}',`;
			return acc;
		},
		'['
	);

	return `:pos_configuration_version.id=IN=${posConfigurationVersionIds};:product_variant.id=IN=${productVariantIds}`;
}

/**
 * @function getPosConfigurationDiscountProductVariantFilter
 * @param {Object} object - contains retrived posConfigDisProdVariants from api and selected posConfigurationDiscounts
 * @description returns a filter used to retrive all existing posConfigDisProdVariants so we can filter out our current
 * selected ones and mark as 'canAdd: false'
 * filter like -> :pos_configuration_product_variant.product_variant.id=IN=['1', '2'...];:pos_configuration_discount=IN=['2', ...]
 */
export function getPosConfigurationDiscountProductVariantFilter({
	posConfigurationDiscount,
	productVariantArray
}) {
	// get array of product variant id's
	const productVariantIds = productVariantArray.reduce(
		(acc, currentValue, index) => {
			const productVariantId = currentValue.value.id;

			acc =
				index === productVariantArray.length - 1
					? `${acc}'${productVariantId}']`
					: `${acc}'${productVariantId}',`;
			return acc;
		},
		'['
	);

	// get array of discount id's
	const discountIds = posConfigurationDiscount.reduce(
		(acc, currentValue, index) => {
			const discountId = currentValue.value.id;

			acc =
				index === posConfigurationDiscount.length - 1
					? `${acc}'${discountId}']`
					: `${acc}'${discountId}',`;
			return acc;
		},
		'['
	);

	return `:pos_configuration_discount.id=IN=${discountIds};:pos_configuration_product_variant.product_variant.id=IN=${productVariantIds}`;
}

/**
 * @function markSelectedValuesWithCanAdd
 * @param {Object} object - contains selected data with posConfigProdVariants and posConfigurationDiscountProductVariants retrived from the api
 * @description loops through our selected values and if we get a match for posConfigurationDiscountId and posConfigurationProductVariantId
 * it means that discount already exists hence adding 'canAdd: false'
 */
export function markSelectedValuesWithCanAdd({
	listArrayWithPosConfigurationProductVariantIds,
	posConfigurationDiscountProductVariants
}) {
	return listArrayWithPosConfigurationProductVariantIds.map(entry => {
		if (!entry.posConfigurationProductVariant)
			return { ...entry, canAdd: false };

		const posConfigurationDiscountId = entry.posConfigurationDiscount.id;
		const posConfigurationProductVariantId =
			entry.posConfigurationProductVariant.id;

		// try to find pos configuration discount product variant if it exists
		const foundPosConfigurationDiscountProductVariant = posConfigurationDiscountProductVariants.find(
			entry =>
				_get(entry, 'pos_configuration_discount.id', null) ===
					posConfigurationDiscountId &&
				_get(entry, 'pos_configuration_product_variant.id', null) ===
					posConfigurationProductVariantId
		);

		return {
			...entry,
			canAdd: _isEmpty(foundPosConfigurationDiscountProductVariant),
			// spread discount amounts if already exists (just for visuals)
			...(_get(
				foundPosConfigurationDiscountProductVariant,
				'to_stay_discount_price',
				false
			) && {
				toStayDiscount:
					foundPosConfigurationDiscountProductVariant.to_stay_discount_price
			}),
			...(_get(
				foundPosConfigurationDiscountProductVariant,
				'to_go_discount_price',
				false
			) && {
				toGoDiscount:
					foundPosConfigurationDiscountProductVariant.to_go_discount_price
			})
		};
	});
}

export function calculateDiscounts({ toStayPrice, toGoPrice, batchFormData }) {
	// apply discount rates to prices based on percentage
	let toStayDiscount =
		toStayPrice * (batchFormData.toStayDiscountPercentage / 100);
	let toGoDiscount = toGoPrice * (batchFormData.toGoDiscountPercentage / 100);

	// round up prices
	switch (_get(batchFormData, 'roundToNearest.value', '1')) {
	case '1':
		toStayDiscount = Math.round(toStayDiscount / 1) * 1;
		toGoDiscount = Math.round(toGoDiscount / 1) * 1;
		break;
	case '0.5':
		toStayDiscount = Math.round(toStayDiscount / 0.5) * 0.5;
		toGoDiscount = Math.round(toGoDiscount / 0.5) * 0.5;
		break;
	case '0.25':
		toStayDiscount = Math.round(toStayDiscount / 0.25) * 0.25;
		toGoDiscount = Math.round(toGoDiscount / 0.25) * 0.25;
		break;
	case '0.1':
		toStayDiscount = Number(
			(Math.round(toStayDiscount / 0.1) * 0.1).toFixed(2)
		);
		toGoDiscount = Number((Math.round(toGoDiscount / 0.1) * 0.1).toFixed(2));
		break;
	case '0.05':
		toStayDiscount = Number(
			(Math.round(toStayDiscount * 20) / 20).toFixed(2)
		);
		toGoDiscount = Number((Math.round(toGoDiscount * 20) / 20).toFixed(2));
		break;
	case '0.01':
		toStayDiscount = Number(
			(Math.round(toStayDiscount / 0.01) * 0.01).toFixed(3)
		);
		toGoDiscount = Number(
			(Math.round(toGoDiscount / 0.01) * 0.01).toFixed(3)
		);
		break;
	default:
		break;
	}

	return {
		toStayDiscount,
		toGoDiscount
	};
}
