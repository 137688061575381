import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { store } from 'appState';
import phrases from './storeFrontImage.phrases';
import { setCurrentStoreFront } from './store/storeFrontImages.actions';

// Interfaces
import { StorePayloadResponse } from './types';

export const getStoreFrontImageById = (id: number) => {
	const params = {
		filter: `:workplace.id=='${id}'`,
	};
	return get('/administration/workplace_store_fronts', params)
		.then((response: IApiRes<IStoreFront>) => {
			store.dispatch(setCurrentStoreFront(response.data[0]));
			return response;
		})
		.catch((error: Error) => {
			const errorMessage = formatErrorMessage(error);
			setFeedback(errorMessage, 0);
			throw error;
		});
};

export const createStoreFront = (payload: StorePayloadResponse) => {
	return post('/administration/workplace_store_fronts', payload)
		.then((response: IApiRes<IStoreFront>) => {
			setFeedback(phrases.ATTACHMENT_ADDED, 1);
			store.dispatch(setCurrentStoreFront(response.data[0]));
			return response;
		})
		.catch((error: Error) => {
			const errorMessage = formatErrorMessage(error);
			setFeedback(errorMessage, 0);
			throw error;
		});
};

export const removeStoreFront = (id: number) => {
	return remove(`/administration/workplace_store_fronts/${id}`)
		.then((response: IApiRes) => {
			setFeedback(phrases.ATTACHMENT_REMOVED, 1);
			store.dispatch(setCurrentStoreFront(undefined));
			return response;
		})
		.catch((error: Error) => {
			const errorMessage = formatErrorMessage(error);
			setFeedback(errorMessage, 0);
			throw error;
		});
};

export const updateStoreFront = (id: number, payload: StorePayloadResponse) => {
	return post(`/administration/workplace_store_fronts/${id}`, payload)
		.then((response: IApiRes<IStoreFront>) => {
			setFeedback(phrases.ATTACHMENT_UPDATED, 1);
			store.dispatch(setCurrentStoreFront(response.data[0]));
			return response;
		})
		.catch((error: Error) => {
			const errorMessage = formatErrorMessage(error);
			setFeedback(errorMessage, 0);
			throw error;
		});
};
