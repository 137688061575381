import { get, post, remove, patch } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchIngredientVariationOpt
 * @description Fetch all discounts
 * @param {object} state
 */
export function fetchIngredientVariationOpt(ingredientVariationId, state) {
	const preFilter = `:ingredient_variation.id=='${ingredientVariationId}'`;

	const filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter
	};

	return get(
		`/product/ingredient_variation_options`,
		params,
		null,
		state.headers
	)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addIngredientVariationOpt
 * @description add
 */
export function addIngredientVariationOpt(payload) {
	return post(`/product/ingredient_variation_options`, payload)
		.then(res => {
			setFeedback('Ingred variant option added', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editIngredientVariation
 * @description edit
 */
export function editIngredientVariationOpt(ingVarOptId, data) {
	return post(`/product/ingredient_variation_options/${ingVarOptId}`, data)
		.then(res => {
			setFeedback('Ingred variant option edited', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function editBatchIngredientVariationOpt
 * @description edit
 */
export function editBatchIngredientVariationOpt(data) {
	return patch(`/product/ingredient_variation_options`, data)
		.then(res => {
			setFeedback('Addon ingredients edited', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteIngredientVariationOpt
 * @description del
 */
export function deleteIngredientVariationOpt(IngredientVariationOptId) {
	return remove(
		`/product/ingredient_variation_options/${IngredientVariationOptId}`
	)
		.then(res => {
			setFeedback('Ingred variant option deleted', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
