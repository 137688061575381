'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Icon, Toggle } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// phrases
import phrases from './../../../ingredientConfigurationIngredients.phrases';

class IngredientConfigurationIngredientsBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// edits the row in the store
		this.props.editBatchListItem({
			identifier: row.identifier,
			[type]: e,
		});
	}

	render() {
		const { batchListData } = this.props;

		return (
			<TableList>
				<thead>
					<tr>
						<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
						<th>
							{
								phrases.INGREDIENT_CONFIGURATION_INGREDIENTS_MODAL_HEADER_INGREDIENT
							}
						</th>
						<th>
							{
								phrases.INGREDIENT_CONFIGURATION_INGREDIENTS_MODAL_HEADER_AVAILABLE_IN_APP
							}
						</th>
						<th />
					</tr>
				</thead>
				<tbody>
					{batchListData.map((entry, i) => {
						return (
							<TableListRow key={entry.identifier}>
								<TableListCell>{i + 1}</TableListCell>
								<TableListCell>{entry.ingredient?.label}</TableListCell>

								<TableListCell>
									<Toggle
										id={`${entry.identifier}-name}`}
										toggled={!!entry.availableInApp}
										onClick={(event) =>
											this._editListEntry(event, entry, 'availableInApp')
										}
									/>
								</TableListCell>

								<TableListCell>
									<Button
										id={`${entry.identifier}-delete`}
										type="negative"
										size="tiny"
										onClick={() => this._onRemoveEntry(entry.identifier)}>
										<Icon name="delete" />
									</Button>
								</TableListCell>
							</TableListRow>
						);
					})}
				</tbody>
			</TableList>
		);
	}
}

IngredientConfigurationIngredientsBatchStepList.propTypes = {
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

export default IngredientConfigurationIngredientsBatchStepList;
