export const SET_TIME_SLOTS = 'HOME_SCREEN_RECOMMENDATIONS/SET_TIME_SLOTS';
export const UPDATE_HOME_SCREEN_RECOMMENDATION =
	'HOME_SCREEN_RECOMMENDATIONS/UPDATE_HOME_SCREEN_RECOMMENDATION';
export const UPDATE_BATCH_TIME_SLOT =
	'HOME_SCREEN_RECOMMENDATIONS/UPDATE_BATCH_TIME_SLOT';
export const ADD_NEW_TIME_SLOT_ROW =
	'HOME_SCREEN_RECOMMENDATIONS/ADD_NEW_TIME_SLOT_ROW';
export const RESET_ADD_TIME_SLOTS_MODAL =
	'HOME_SCREEN_RECOMMENDATIONS/RESET_ADD_TIME_SLOTS_MODAL';
export const DELETE_TIME_SLOT_ROW =
	'HOME_SCREEN_RECOMMENDATIONS/DELETE_TIME_SLOT_ROW';
export const SET_PRODUCTS = 'HOME_SCREEN_RECOMMENDATIONS/SET_PRODUCTS';
export const SET_TIME_SLOT_TO_EDIT =
	'HOME_SCREEN_RECOMMENDATIONS/SET_TIME_SLOT_TO_EDIT';
export const UPDATE_TIME_SLOT_TO_EDIT =
	'HOME_SCREEN_RECOMMENDATIONS/UPDATE_TIME_SLOT_TO_EDIT';
export const RESET_EDIT_TIME_SLOT_MODAL =
	'HOME_SCREEN_RECOMMENDATIONS/RESET_EDIT_TIME_SLOT_MODAL';
export const UPDATE_TIME_SLOT = 'HOME_SCREEN_RECOMMENDATIONS/UPDATE_TIME_SLOT';
export const RESET_DATA = 'HOME_SCREEN_RECOMMENDATIONS/RESET_DATA';
export const SET_TIME_SLOT_TO_DELETE =
	'HOME_SCREEN_RECOMMENDATIONS/SET_TIME_SLOT_TO_DELETE';
export const RESET_DELETE_TIME_SLOT_MODAL =
	'HOME_SCREEN_RECOMMENDATIONS/RESET_DELETE_TIME_SLOT_MODAL';
export const DELETE_TIME_SLOT = 'HOME_SCREEN_RECOMMENDATIONS/DELETE_TIME_SLOT';
export const SET_TILE_LAYOUT_TO_DUPLICATE =
	'HOME_SCREEN_RECOMMENDATIONS/SET_TILE_LAYOUT_TO_DUPLICATE';
export const RESET_TILE_LAYOUT_TO_DUPLICATE =
	'HOME_SCREEN_RECOMMENDATIONS/RESET_TILE_LAYOUT_TO_DUPLICATE';

export function setTimeSlots(payload) {
	return {
		type: SET_TIME_SLOTS,
		payload,
	};
}

export function updateHomeScreenRecommendation(payload) {
	return {
		type: UPDATE_HOME_SCREEN_RECOMMENDATION,
		payload,
	};
}

export function updateBatchTimeSlot(payload) {
	return {
		type: UPDATE_BATCH_TIME_SLOT,
		payload,
	};
}

export function addNewTimeSlotRow() {
	return {
		type: ADD_NEW_TIME_SLOT_ROW,
	};
}

export function resetAddTimeSlotsModal() {
	return {
		type: RESET_ADD_TIME_SLOTS_MODAL,
	};
}

export function deleteTimeSlotRow(payload) {
	return {
		type: DELETE_TIME_SLOT_ROW,
		payload,
	};
}

export function setProducts(payload) {
	return {
		type: SET_PRODUCTS,
		payload,
	};
}

export function setTimeSlotToEdit(payload) {
	return {
		type: SET_TIME_SLOT_TO_EDIT,
		payload,
	};
}

export function updateTimeSlotToEdit(payload) {
	return {
		type: UPDATE_TIME_SLOT_TO_EDIT,
		payload,
	};
}

export function resetEditTimeSlotModal() {
	return {
		type: RESET_EDIT_TIME_SLOT_MODAL,
	};
}

export function updateTimeSlot(payload) {
	return {
		type: UPDATE_TIME_SLOT,
		payload,
	};
}

export function setTimeSlotToDelete(payload) {
	return {
		type: SET_TIME_SLOT_TO_DELETE,
		payload,
	};
}

export function deleteTimeSlot(payload) {
	return {
		type: DELETE_TIME_SLOT,
		payload,
	};
}

export function resetData() {
	return {
		type: RESET_DATA,
	};
}

export function resetDeleteTimeSlotModal() {
	return {
		type: RESET_DELETE_TIME_SLOT_MODAL,
	};
}

export function setTileLayoutToDuplicate(payload) {
	return {
		type: SET_TILE_LAYOUT_TO_DUPLICATE,
		payload,
	};
}

export function resetTileLayoutToDuplicate() {
	return {
		type: RESET_TILE_LAYOUT_TO_DUPLICATE,
	};
}
