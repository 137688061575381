'use strict';

import { ReportTablesResultSet } from 'report/resultSets';

class ReportResultSet {
	constructor(resultSet) {
		// Set metadata
		this.setMetadata(resultSet);

		// Set tables
		this.setTables(resultSet);
	}

	/// //////////
	// Setters //
	/// //////////

	/**
	 * @function setMetadata
	 * @memberOf ReportResultSet
	 * @description
	 * Extract metadata from resultSet and sets as result
	 * @param {object} resultSet
	 */
	setMetadata(resultSet) {
		const report = resultSet.getFirst
			? resultSet.getFirst()
			: resultSet.data[0];

		this.metadata = {
			name: report.name ? report.name : null,
			period: report.period ? report.period : null,
			type: report.meta ? report.meta.data.type : null
		};
	}

	/**
	 * @function setTables
	 * @memberOf ReportResultSet
	 * @description
	 * Set tables as new report tables resultset
	 * @param {object} resultSet
	 */
	setTables(resultSet) {
		this.tables = new ReportTablesResultSet(resultSet);
	}

	/// //////////
	// Getters //
	/// //////////

	/**
	 * @function getMetadata
	 * @memberOf ReportResultSet
	 * @description
	 * Gets metadata
	 */
	getMetadata() {
		return this.metadata || null;
	}

	/**
	 * @function getTables
	 * @memberOf ReportResultSet
	 * @description
	 * Gets tables results
	 */
	getTables() {
		return this.tables.getResults() || null;
	}
}

export default ReportResultSet;
