'use strict';

import { store } from 'appState';

import { setHotbar } from 'hotdamnbar/hotdamnbar.actions';
import { HotbarResultSet } from 'hotdamnbar/resultSets';
import phrases from 'hotdamnbar/hotdamnbar.phrases';

/**
 * Hotbar
 * @param  {object} title
 * @param  {array} content
 */
class Hotbar {
	constructor(title) {
		// Set type
		this.type = 'hotbar';

		// Set empty content
		this.components = {};

		/// ////////
		// Title //
		/// ////////

		this.title = title;
	}

	/// //////////////
	// Dispatchers //
	/// //////////////

	/**
	 * @function _dispatchHotbarResultSet
	 * @memberOf Hotbar
	 * @description Dispatches hotbar resultset
	 * @private
	 */
	_dispatchHotbarResultSet(resultSet) {
		store.dispatch(setHotbar(this.title, resultSet));
	}

	/// //////////
	// Getters //
	/// //////////

	/**
	 * @function getComponents
	 * @memberOf Hotbar
	 * @description Gets all components
	 */
	getComponents() {
		return this.components;
	}

	/**
	 * @function getComponent
	 * @memberOf Hotbar
	 * @description Gets single component
	 */
	getComponent(componentIdentifier) {
		return this.getComponents()[componentIdentifier];
	}

	/**
	 * @function getPhrases
	 * @memberOf Hotbar
	 * @description Getter for phrases
	 */
	getPhrases() {
		return phrases;
	}

	/**
	 * @function getResultSet
	 * @memberOf Hotbar
	 * @description Gets resultSet as HotbarResultSet
	 */
	getResultSet() {
		return new HotbarResultSet(this);
	}

	/// /////////////////
	// Event handlers //
	/// /////////////////

	/**
	 * @function handleUpdateHotbarResultSet
	 * @memberOf Hotbar
	 * @description Sends resultset to store and returns
	 */
	handleUpdateHotbarResultSet() {
		// Get result set
		const resultSet = this.getResultSet();

		// Dispatch resultSet to hotbar store
		this._dispatchHotbarResultSet(resultSet);

		// Return resultSet to container > outer component
		return resultSet;
	}

	/// //////////
	// Utility //
	/// //////////

	/**
	 * @function handleDependencies
	 * @memberOf Hotbar
	 * @description Handler for handling dependencies in hotbar component
	 * @param {object} component
	 */
	handleDependencies(viewComponent) {
		// Set identifier for view component
		const viewComponentIdentifier = viewComponent.title;

		// Look through components for any matching dependencies
		Object.keys(this.getComponents()).forEach(componentIdentifier => {
			// Get hotbar component
			const hotbarComponent = this.getComponents()[componentIdentifier];

			// Check if any dependencies to view component are present
			if (
				hotbarComponent.dependencies === null ||
				!hotbarComponent.hasDependency(viewComponentIdentifier)
			)
				return;

			// Get dependency key
			const dependencyKey = hotbarComponent.getDependencyKey(
				viewComponentIdentifier
			);

			// Get dependency values
			const dependencyValues = this.getComponent(
				viewComponentIdentifier
			).getSelectedValues();

			// Provide data provider with data
			hotbarComponent.setDataProviderParameter(dependencyKey, dependencyValues);
		});
	}

	/**
	 * @function add
	 * @memberOf Hotbar
	 * @description Adds component to components dictionary
	 * @param {object} component
	 */
	add(component, dependencies = null) {
		component.setDependencies(dependencies);
		this.components[component.title] = component;
	}

	/**
	 * @function isValid
	 * @memberOf Hotbar
	 * @description Checks each component in hotbar for validity
	 */
	isValid() {
		return Object.keys(this.getComponents()).reduce(
			(acc, componentIdentifier) => {
				// Get component
				const component = this.getComponents()[componentIdentifier];

				// Check for general validity
				if (!component.isValid()) acc = false;

				// Check if component has been set, if required
				if (component.isRequired() && !component.isSet()) acc = false;

				return acc;
			},
			true
		);
	}
}

export default Hotbar;
