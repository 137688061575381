import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// components
import { Text } from 'dumb';
import ShiftTransferStatus from './../../../../../shiftTransferStatus';

// phrases/ constants/ utils
import phrases from './../../../../../../shiftTransferApproval.phrases';
import {
	getOperationlessApplicationDecisions,
	getApplicationDecisions,
} from './../../../../utils';
import { ShiftTransfer as enums } from 'services/enums';

// styles
import './shiftTransferApprovalMobileViewPageApplicantList.css';

class ShiftTransferApprovalMobileViewPageApplicantList extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		this.handleDeviation = this.handleDeviation.bind(this);
	}

	componentDidMount() {
		const {
			shiftTransfer,
			setShiftTransferApplication,
			noActions,
		} = this.props;

		if (noActions) return;

		// set all applications in the reducer
		const applicationDecisions = getOperationlessApplicationDecisions({
			applications: shiftTransfer.applications,
		});

		setShiftTransferApplication(applicationDecisions);
	}

	handleDeviation(entry, operation) {
		const { setShiftTransferApplication, applicationDecisions } = this.props;

		const payload = {
			operation,
			id: entry.id,
		};

		const newApplicationDecisions = getApplicationDecisions({
			applicationDecisions,
			payload,
		});

		setShiftTransferApplication(newApplicationDecisions);
	}

	render() {
		const { shiftTransfer, applicationDecisions, noActions } = this.props;

		return (
			<div className="shift-transfer-approval-mobile-applicant-list">
				{/* <Text bold>{tableHeader}</Text> */}
				{shiftTransfer.applications.map((entry) => {
					// state from the db itself - already submitted decision
					const applicationDeclined =
						entry.state === enums.APPLICATIONS.DECLINED;
					const applicationApproved =
						entry.state === enums.APPLICATIONS.APPROVED;

					// decision on the frontend before submitting to the api
					// in order to submit user has to click on the submit button at the bottom of the modal
					// why? - a safe guard to prevent mis-tapping
					let applicationDecisionApproved, applicationDecisionDeclined;
					if (!noActions) {
						applicationDecisionApproved = applicationDecisions.some(
							(entryTwo) =>
								entryTwo.operation === enums.OPERATIONS.APPROVE_APPLICATION &&
								entryTwo.id === entry.id
						);
						applicationDecisionDeclined = applicationDecisions.some(
							(entryTwo) =>
								entryTwo.operation === enums.OPERATIONS.DECLINE_APPLICATION &&
								entryTwo.id === entry.id
						);
					}

					const name = entry.applicant?.person?.full_name ?? '';
					const moneyballBadge =
						entry.applicant?.person?.moneyball_sub_position?.moneyball_position
							?.badge ?? '';

					const applicationInteracted =
						applicationApproved || applicationDeclined;
					const strikeThroughApplicant =
						!noActions && (applicationDecisionDeclined || applicationDeclined);

					return (
						<div
							className="shift-transfer-approval-mobile-applicant-list__entry"
							key={entry.id}>
							<div
								className={cx(
									'shift-transfer-approval-modal__table-wrapper__row-cell',
									{
										'shift-transfer-approval-modal__table-wrapper__row-cell--declined': strikeThroughApplicant,
									}
								)}>
								<Text className="shift-transfer-approval-modal__table-wrapper__row-cell shift-transfer-approval-modal__table-wrapper__row-cell__badge">
									{moneyballBadge}
								</Text>
								<Text
									bold
									className="shift-transfer-approval-modal__table-wrapper__row-cell--left-margin shift-transfer-approval-modal__table-wrapper__row-cell__name">
									{name}
								</Text>
							</div>

							{!noActions && (
								<>
									{applicationInteracted ? (
										<ShiftTransferStatus state={entry.state} />
									) : (
										<div className="shift-transfer-approval-modal__table-wrapper__row-cell shift-transfer-approval-modal__table-wrapper__row-cell--align-end">
											<label className="shift-transfer-approval-modal__table-wrapper__row-cell">
												<input
													id={`radio-1-${entry.id}`}
													type="radio"
													value="Yes"
													name={`yes-${entry.id}`}
													checked={applicationDecisionApproved}
													onChange={() =>
														this.handleDeviation(
															entry,
															enums.OPERATIONS.APPROVE_APPLICATION
														)
													}
												/>{' '}
												<Text
													className="shift-transfer-approval-modal__table-wrapper__row-cell--left-margin"
													size="small">
													{phrases.APPROVE}
												</Text>
											</label>
											<label className="shift-transfer-approval-modal__table-wrapper__row-cell shift-transfer-approval-modal__table-wrapper__row-cell--left-margin">
												<input
													id={`radio-2-${entry.id}`}
													type="radio"
													value="No"
													name={`no-${entry.id}`}
													checked={applicationDecisionDeclined}
													onChange={() =>
														this.handleDeviation(
															entry,
															enums.OPERATIONS.DECLINE_APPLICATION
														)
													}
												/>{' '}
												<Text
													className="shift-transfer-approval-modal__table-wrapper__row-cell--left-margin"
													size="small">
													{phrases.DECLINE}
												</Text>
											</label>
										</div>
									)}
								</>
							)}
						</div>
					);
				})}
			</div>
		);
	}
}

ShiftTransferApprovalMobileViewPageApplicantList.defaultProps = {
	noActions: false,
};

ShiftTransferApprovalMobileViewPageApplicantList.propTypes = {
	shiftTransfer: PropTypes.object,
	setShiftTransferApplication: PropTypes.func,
	applicationDecisions: PropTypes.array,
	noActions: PropTypes.bool,
};

export default ShiftTransferApprovalMobileViewPageApplicantList;
