import { store } from 'appState';

export const SHOW_MODAL = 'DOCUSIGN_SEND_DOCUMENT/SHOW_MODAL';
export const RESET_STATE = 'DOCUSIGN_SEND_DOCUMENT/RESET_STATE';
export const SET_DATA = 'DOCUSIGN_SEND_DOCUMENT/SET_DATA';
export const UPDATE_DATA = 'DOCUSIGN_SEND_DOCUMENT/UPDATE_DATA';

export function showModal(payload) {
	return {
		type: SHOW_MODAL,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function setData(payload) {
	return {
		type: SET_DATA,
		payload,
	};
}

export function updateData(payload) {
	return {
		type: UPDATE_DATA,
		payload,
	};
}

// functions with dispatchers
export function setShowModalAndData(payload) {
	store.dispatch(setData(payload));
	store.dispatch(showModal(true));
}
