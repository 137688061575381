'use strict';

export const SET_EMPLOYEE_MEALS_REPORT =
	'EMPLOYEE_MEALS/SET_EMPLOYEE_MEALS_REPORT';
export const SET_EMPLOYEE_MEALS_REPORT_PR_WORKPLACE =
	'EMPLOYEE_MEALS/SET_EMPLOYEE_MEALS_REPORT_PR_WORKPLACE';
export const SET_EMPLOYEE_MEALS_REPORT_PR_PERSON =
	'EMPLOYEE_MEALS/SET_EMPLOYEE_MEALS_REPORT_PR_PERSON';
export const SHOW_PRODUCTS_PR_SHIFT = 'EMPLOYEE_MEALS/SHOW_PRODUCTS_PR_SHIFT';
export const LOADING_REPORT = 'EMPLOYEE_MEALS/LOADING_REPORT';
export const SET_HOTBAR_DATA = 'EMPLOYEE_MEALS/SET_HOTBAR_DATA';
export const CLEAN_DATA = 'EMPLOYEE_MEALS/CLEAN_DATA';
export const CLEAN_REPORT_DATA = 'EMPLOYEE_MEALS/CLEAN_REPORT_DATA';

export const SET_EMPLOYEE = 'EMPLOYEE_MEALS/SET_EMPLOYEE';
export const SET_MARKET = 'EMPLOYEE_MEALS/SET_MARKET';
export const SET_WORKPLACE = 'EMPLOYEE_MEALS/SET_WORKPLACE';

/**
 * @param {object} report
 */
export function setEmployeeMealsReport(employeeMealsReport) {
	return {
		type: SET_EMPLOYEE_MEALS_REPORT,
		payload: {
			employeeMealsReport
		}
	};
}

/**
 * @param {object} report
 */
export function setEmployeeMealsReportPrWorkplace(
	employeeMealsReportPrWorkplace
) {
	return {
		type: SET_EMPLOYEE_MEALS_REPORT_PR_WORKPLACE,
		payload: {
			employeeMealsReportPrWorkplace
		}
	};
}

/**
 * @param {object} employeeMealsReportPrPerson
 */
export function setEmployeeMealsReportPrPerson(employeeMealsReportPrPerson) {
	return {
		type: SET_EMPLOYEE_MEALS_REPORT_PR_PERSON,
		payload: {
			employeeMealsReportPrPerson
		}
	};
}

/**
 * @param {bool} report
 */
export function setLoadingReport(loadingReport) {
	return {
		type: LOADING_REPORT,
		payload: {
			loadingReport
		}
	};
}

/**
 * @param {bool} report
 */
export function cleanData() {
	return {
		type: CLEAN_DATA
	};
}

export function cleanReportData() {
	return {
		type: CLEAN_REPORT_DATA
	};
}

/**
 * @param {bool} report
 */
export function setHotbarData(hotbarData) {
	return {
		type: SET_HOTBAR_DATA,
		payload: {
			hotbarData
		}
	};
}

/**
 * @param {bool} report
 */
export const setEmployee = payload => ({ type: SET_EMPLOYEE, payload });
export const setMarket = payload => ({ type: SET_MARKET, payload });
export const setWorkplace = payload => ({ type: SET_WORKPLACE, payload });

/**
 * @param {bool} report
 */
export function showProductsPrShifts(showProductsPrShift) {
	return {
		type: SHOW_PRODUCTS_PR_SHIFT,
		payload: {
			showProductsPrShift
		}
	};
}
