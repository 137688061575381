'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input, InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';

import _get from 'lodash/get';
import { enums } from '../../../../tileLayout.enums';

// phrases
import phrases from './../../../../tileLayout.phrases';

class FormAddLayout extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
			showOverlay: false,
		};
	}

	editStoreEntry(e, name) {
		const value = e.target ? e.target.value : e;

		this.props.updatePosListRow({ [name]: value });
	}

	_getSubTypeOptions() {
		return enums.SUB_TYPES.map((entry) => {
			return {
				value: entry,
				label: entry,
			};
		});
	}

	render() {
		const { defaultValues } = this.props;

		const enableSubTypeSelector =
			_get(defaultValues, 'type.value', null) === 'App' ||
			_get(defaultValues, 'type.value', null) === 'Delivery';

		return (
			<>
				<ModalRow>
					<Input
						id="name"
						placeholder="name"
						label="Name"
						value={_get(defaultValues, 'name', '')}
						onChange={(event) => this.editStoreEntry(event, 'name')}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						id="description"
						label="Description"
						placeholder="description"
						value={_get(defaultValues, 'description', '')}
						onChange={(event) => this.editStoreEntry(event, 'description')}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						id="Type"
						label="Type"
						type="select"
						value={_get(defaultValues, 'type', {
							value: enums.TYPES[0],
							label: enums.TYPES[0],
						})}
						onChange={(event) => this.editStoreEntry(event, 'type')}
						options={enums.TYPES.map((x) => ({ value: x, label: x }))}
						clearable={false}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						id="rows"
						label="Rows"
						placeholder="rows"
						type="number"
						min={1}
						max={50}
						value={_get(defaultValues, 'rows', 1)}
						onChange={(event) => this.editStoreEntry(event, 'rows')}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						id="columns"
						label="Columns"
						placeholder="columns"
						type="number"
						min="1"
						max="50"
						value={_get(defaultValues, 'columns', 1)}
						onChange={(event) => this.editStoreEntry(event, 'columns')}
					/>
				</ModalRow>

				<ModalRow>
					<InputCollectionSelect
						id="subType"
						label="Sub Type"
						placeholder="Select sub type"
						value={
							defaultValues.sub_type || {
								value: enums.SUB_TYPES[0],
								label: enums.SUB_TYPES[0],
							}
						}
						handleChange={(key, value) => {
							this.editStoreEntry(value, 'sub_type');
						}}
						clearable={false}
						cache
						options={this._getSubTypeOptions()}
						disabled={!enableSubTypeSelector}
					/>
				</ModalRow>

				<ModalRow>
					<InputCollectionSelect
						id="show-case-image"
						label={phrases.SHOW_CASE_IMAGE}
						placeholder={phrases.SHOW_CASE_IMAGE_PLACEHOLDER}
						value={defaultValues.showCaseImage}
						handleChange={(key, value) => {
							this.editStoreEntry(value, 'showCaseImage');
						}}
						clearable={false}
						cache
						apiPath="/pos/asset_collections"
						params={{
							limit: 30,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						tableTitle={phrases.SHOW_CASE_IMAGE_TABLE_TITLE}
						tableReduxKey="formAddLayout-/pos/asset_collections"
						tableColumns={[
							{
								Header: 'Name',
								id: 'name',
								accessor: 'name',
								filterPath: ':name',
							},
							{
								Header: 'Description',
								id: 'desc',
								accessor: 'description',
								filterPath: ':description',
							},
							{
								Header: 'Legacy',
								id: 'legacy',
								accessor: 'legacy',
								filterPath: ':legacy',
								filterable: false,
								Cell: (d) => {
									return (
										<Input
											type="checkbox"
											checked={d.original.legacy}
											disabled
										/>
									);
								},
							},
						]}
					/>
				</ModalRow>
			</>
		);
	}
}

FormAddLayout.propTypes = {
	defaultValues: PropTypes.object,
	updatePosListRow: PropTypes.func,
};

export default FormAddLayout;
