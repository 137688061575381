import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';
import ShiftTransferManagerApprovalList from './components/shiftTransferManagerApprovalList/shiftTransferManagerApprovalList';
import ShiftTransferJuicerApprovalList from './components/shiftTransferJuicerApprovalList/shiftTransferJuicerApprovalList';
import ShiftTransferPendingTransfersList from './components/shiftTransferPendingTransfersList/shiftTransferPendingTransfersList';

// utils
import phrases from './../../shiftTransferApproval.phrases';

// lodash
import _isEmpty from 'lodash/isEmpty';

class ShiftTransferApprovalDesktopView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};

		this.modalRef = React.createRef();
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	render() {
		const {
			shiftTransfers,
			onClose,
			updateShiftTransfer,
			swapTransfers,
			handleUpdateShiftTransferApplication,
			showPendingTransfersModal,
			pendingTransfers,
			user,
		} = this.props;

		const noShiftTransfers = _isEmpty(shiftTransfers);
		const noSwapTransfers = _isEmpty(swapTransfers);
		const noPendingTransfers = _isEmpty(pendingTransfers);

		const steps = [
			...(noSwapTransfers || showPendingTransfersModal
				? []
				: [
						{
							component: (
								<div className="shift-transfer-approval-modal">
									<ShiftTransferJuicerApprovalList
										swapTransfers={swapTransfers}
										handleUpdateShiftTransferApplication={
											handleUpdateShiftTransferApplication
										}
									/>
								</div>
							),
							title: phrases.MODAL_TITLE_JUICER,
							isValid: true,
							onNext: noShiftTransfers ? onClose : () => this._goToStep(2),
							loading: this.state.loadingModal,
							confirmButtonLabel: noShiftTransfers
								? phrases.CLOSE
								: phrases.NEXT,
							noScroll: false,
						},
				  ]),
			...(noShiftTransfers || showPendingTransfersModal
				? []
				: [
						{
							component: (
								<div className="shift-transfer-approval-modal">
									<ShiftTransferManagerApprovalList
										shiftTransfers={shiftTransfers}
										updateShiftTransfer={updateShiftTransfer}
									/>
								</div>
							),
							title: phrases.MODAL_TITLE_BM,
							isValid: true,
							onNext: onClose,
							onBack: () => this._goToStep(1),
							cancelButtonLabel: phrases.BACK,
							loading: this.state.loadingModal,
							confirmButtonLabel: phrases.CLOSE,
							noScroll: false,
						},
				  ]),
			...(noPendingTransfers || !showPendingTransfersModal
				? []
				: [
						{
							component: (
								<div className="shift-transfer-approval-modal">
									<ShiftTransferPendingTransfersList
										pendingTransfers={pendingTransfers}
										updateShiftTransfer={updateShiftTransfer}
										handleUpdateShiftTransferApplication={
											handleUpdateShiftTransferApplication
										}
										user={user}
									/>
								</div>
							),
							title: phrases.MODAL_TITLE_PENDING,
							isValid: true,
							onNext: onClose,
							noBack: true,
							confirmButtonLabel: phrases.CLOSE,
							loading: this.state.loadingModal,
							noScroll: false,
						},
				  ]),
		];

		return (
			<ModalStepper
				ref={this.modalRef}
				isOpen
				steps={steps}
				onClose={onClose}
				showStep
			/>
		);
	}
}

ShiftTransferApprovalDesktopView.propTypes = {
	shiftTransfers: PropTypes.array,
	swapTransfers: PropTypes.array,
	onClose: PropTypes.func,
	updateShiftTransfer: PropTypes.func,
	handleUpdateShiftTransferApplication: PropTypes.func,
	pendingTransfers: PropTypes.array,
	showPendingTransfersModal: PropTypes.bool,
	user: PropTypes.object,
};

export default ShiftTransferApprovalDesktopView;
