'use strict';

// utilities
import { sortKpiReports } from './../utils/kpiReportParser';

import {
	LOADING_REPORT,
	SET_HOTBAR_DATA,
	CLEAN_DATA,
	SET_KPI_REPORT,
	TOGGLE_EXPAND_ROW
} from './kpiReport.actions';

const defaultState = {
	ui: {
		loadingReport: false,
		expandedWorkplaces: []
	},
	data: {
		hotbarData: {
			markets: [],
			date: {}
		},
		kpiReport: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
	case TOGGLE_EXPAND_ROW: {
		const rowToOpen = state.ui.expandedWorkplaces.find(
			expandedWorkplace => expandedWorkplace.id === payload.id
		);

		return {
			...state,
			ui: {
				...state.ui,
				expandedWorkplaces: [
					...state.ui.expandedWorkplaces.filter(
						expandedWorkplace => expandedWorkplace.id !== payload.id
					),
					{
						id: payload.id,
						opened: rowToOpen ? !rowToOpen.opened : true
					}
				]
			}
		};
	}

	case LOADING_REPORT: {
		return {
			...state,
			ui: {
				...state.ui,
				loadingReport: payload.loadingReport
			}
		};
	}

	case SET_KPI_REPORT: {
		// sort kpi reports by categories
		const sortedReports = sortKpiReports(payload.kpiReport);

		return {
			...state,
			data: {
				...state.data,
				kpiReport: sortedReports
			}
		};
	}

	case SET_HOTBAR_DATA: {
		return {
			...state,
			data: {
				...state.data,
				...payload
			}
		};
	}

	case CLEAN_DATA: {
		return {
			...state,
			data: defaultState.data
		};
	}

	default:
		return state;
	}
}

export default reducer;
