'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { Card, Tag } from 'dumb';

import ReorderProductTileLayoutGroups from './components/reorderProductTileLayoutGroups/reorderProductTileLayoutGroups';
import ReorderProductTileLayoutTiles from './components/reorderProductTileLayoutTiles/reorderProductTileLayoutTiles';

// phrases/enums
import phrases from './reorderProducts.phrases';

// lodash
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

// styles
import './reorderProducts.css';

class ReorderProducts extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeTileLayoutGroup: null,
		};

		this.setActiveTileLayoutGroup = this.setActiveTileLayoutGroup.bind(this);
		this._getTags = this._getTags.bind(this);
		this.submitGroup = this.submitGroup.bind(this);
	}

	componentDidMount() {
		const { batchForm } = this.props;
		if (!_isEmpty(batchForm.uniqueTileLayoutGroups))
			this.setActiveTileLayoutGroup(batchForm.uniqueTileLayoutGroups[0].name);
	}

	setActiveTileLayoutGroup(name) {
		const { batchForm, updateBatchForm } = this.props;

		if (this.state.activeTileLayoutGroup === name) return;

		this.setState(() => ({ activeTileLayoutGroup: name }));

		updateBatchForm({
			shownTileLayoutTiles: batchForm.uniqueTileLayoutTiles.filter(
				(entry) => entry.tile_layout_group.name === name
			),
		});
	}

	_getTags() {
		const { batchForm } = this.props;
		const { activeTileLayoutGroup } = this.state;

		// find all the groups with the same name as selected one
		const layouts = batchForm.tile_layout_groups
			.filter(
				(entry) => _get(entry, 'value.name', '') === activeTileLayoutGroup
			)
			.map((entry) => `${_get(entry, 'value.tile_layout.name', '')}`);

		return layouts.map((entry, index) => {
			return (
				<Tag color="dddddd" key={index}>
					{entry}
				</Tag>
			);
		});
	}

	submitGroup(name) {
		const {
			batchForm,
			submitPositionRotations,
			markGroupAsSubmitted,
			setLoading,
		} = this.props;

		setLoading(true);

		const mappedValues = batchForm.shownTileLayoutTiles.reduce(
			(acc, currentValue) => {
				// find all other tile layout tiles and add position from shown tiles
				const payload = batchForm.tileLayoutTiles
					.filter((entry) => entry.product.id === currentValue.product.id)
					.map((entry) => ({ id: entry.id, position: currentValue.position }));

				return [...acc, ...payload];
			},
			[]
		);

		const payload = {
			rotations: mappedValues,
		};

		submitPositionRotations(payload)
			.then(() => {
				markGroupAsSubmitted({ name });
				setLoading(false);
			})
			.catch(() => setLoading(false));
	}

	render() {
		const { batchForm, updateTileLayoutTilePosition } = this.props;

		return (
			<Card className="reorder-products">
				<div className="reorder-products__selected-layouts">
					<span>{phrases.PARENT_LAYOUTS}</span>
					<div className="reorder-products__selected-layouts__tags">
						{this._getTags()}
					</div>
				</div>
				<div className="reorder-products__wrapper">
					<ReorderProductTileLayoutGroups
						batchForm={batchForm}
						activeTileLayoutGroup={this.state.activeTileLayoutGroup}
						setActiveTileLayoutGroup={this.setActiveTileLayoutGroup}
					/>
					<ReorderProductTileLayoutTiles
						batchForm={batchForm}
						updateTileLayoutTilePosition={updateTileLayoutTilePosition}
						submitGroup={this.submitGroup}
						groupName={this.state.activeTileLayoutGroup}
					/>
				</div>
			</Card>
		);
	}
}

ReorderProducts.propTypes = {
	batchForm: PropTypes.object,
	updateTileLayoutTilePosition: PropTypes.func,
	updateBatchForm: PropTypes.func,
	submitPositionRotations: PropTypes.func,
	markGroupAsSubmitted: PropTypes.func,
	setLoading: PropTypes.func,
};

export default ReorderProducts;
