export const types = [
	// 'Max Hourly',
	// 'Max Daily',
	// 'Max length without break',
	// 'Max days per week',
	// 'Minimum hours per day',
	'Max Daily',
	'Max Weekly',
	'Max length without break',
	'Max days per week',
	'Minimum hours per day',
	'Minimum hours between shifts',
];
export const units = ['Minute', 'Hour', 'Day'];
