export const RESET_ASSET_COLLECTION =
	'SALES_CONFIGURATION/RESET_ASSET_COLLECTION';
export const SET_ASSET_COLLECTION =
	'SALES_CONFIGURATION/SET_ASSET_COLLECTION';
export const UPDATE_ASSET_COLLECTION =
	'SALES_CONFIGURATION/UPDATE_ASSET_COLLECTION';

/**
 * @param {object} payload
 */
export function setAssetCollection(payload) {
	return {
		type: SET_ASSET_COLLECTION,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateAssetCollection(payload) {
	return {
		type: UPDATE_ASSET_COLLECTION,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetAssetCollection() {
	return {
		type: RESET_ASSET_COLLECTION
	};
}