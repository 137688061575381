'use strict';

import salesConfigurationAssetsUpload from './components/assets/components/assetCollection/components/assetsLeftAligned/store/assetLeftAligned.reducer';
import salesConfigurationAssets from './components/assets/components/assets/store/assets.reducer';
import posConfigurationProductVariantsPricing from './components/pricing/components/posConfigurationProductVariantsPricing/store/posConfigurationProductVariantsPricing.reducer';
import salesConfigurationProductAddonRecommendations from './components/product/components/productAddonRecommendations/store/productAddonRecommendations.reducer';

import salesConfigurationPosConfigurationDiscountProductVariant from './components/globalDiscounts/components/salesConfigurationPosConfigurationDiscountProductVariant/store/salesConfigurationPosConfigurationDiscountProductVariant.reducer';
import salesConfigurationPosConfigurationDiscounts from './components/globalDiscounts/components/salesConfigurationPosConfigurationDiscounts/store/salesConfigurationPosConfigurationDiscounts.reducer';
import salesConfigurationPosConfigurationDiscountIngredient from './components/globalDiscounts/components/salesConfigurationPosConfigurationDiscountIngredients/store/salesConfigurationPosConfigurationDiscountIngredients.reducer';

import salesConfigurationAddonRecommendationsIngredients from './components/product/components/productAddonRecommendations/components/store/addonIngredient.reducer';

import posConfigurationIngredientPricingGroups from './components/pricing/components/posConfigurationIngredientPricingGroups/store/posConfigurationIngredientPricingGroups.reducer';
import posConfigurationIngredientPricingVariation from './components/pricing/components/posConfigurationIngredientPricingVariation/store/posConfigurationIngredientPricingVariation.reducer';
import salesConfigurationPosConfigurationIngredientVariantOptions from './components/pricing/components/posConfigurationIngredientPricingVariation/components/ingredientVariantOptions/store/ingredientVariantOptions.reducer';
import salesConfigurationPosConfigurationIngredientAssociationWithProducts from './components/pricing/components/posConfigurationIngredientPricingVariation/components/ingredientAssociationWithProducts/store/ingredientAssociationWithProducts.reducer';
import salesConfigurationProductRecommendations from './components/product/components/productRecommendations/store/productRecommendations.reducer';
import salesConfigurationProductRecommendationProductVariant from './components/product/components/productRecommendations/components/productRecommendationProductVariants/store/productRecommendationProductVariants.reducer';
import salesConfigurationIngredientPricings from './components/pricing/components/salesConfigurationIngredientPricings/store/salesConfigurationIngredientPricings.reducer';
import workplacePosConfiguration from './components/posWorkplaceRelation/components/workplacePosConfiguration/store/workplacePosConfiguration.reducer';
import posConfigurationAssociatedIngredients from './components/pricing/components/posConfigurationProductVariantsPricing/components/subTables/components/posConfigurationAssociatedIngredients/store/posConfigurationAssociatedIngredients.reducer';
import posConfigurationDiscountProductVariants from './components/pricing/components/posConfigurationProductVariantsPricing/components/subTables/components/posConfigurationDiscountProductVariants/store/posConfigurationDiscountProductVariants.reducer';
import posConfigurationProductVariantIngredientVariations from './components/pricing/components/posConfigurationProductVariantsPricing/components/subTables/components/posConfigurationProductVariantIngredientVariations/store/posConfigurationProductVariantIngredientVariations.reducer';

import salesConfigurationProductBundle from './components/product/components/productBundles/store/productBundles.reducer';
import salesConfigurationProductBundleSets from './components/product/components/productBundles/components/productBundleSets/store/productBundleSets.reducer';
import salesConfigurationProductBundleSetProductVariants from './components/product/components/productBundles/components/productBundleSets/components/productBundleSetProductVariants/store/productBundleSetProductVariants.reducer';

import productAddonRecommendations from './components/product/components/productAddons/components/addonRecommendations/store/addonRecommendations.reducer';
import productAddonIngredients from './components/product/components/productAddons/components/addonIngredient/store/addonIngredient.reducer';
import salesConfigurationGlobalBundleRules from './components/product/components/globalBundleRules/store/globalBundleRules.reducer';
import salesConfigurationProductBundleRules from './components/product/components/productBundles/components/productBundleRules/store/productBundleRules.reducer';
import productAssetLeftAligned from './components/product/components/product/components/assetsLeftAligned/store/assetLeftAligned.reducer';
import productVariantIngredientAlternatives from './components/ingredients/components/productVariantIngredientAlternatives/store/productVariantIngredientAlternatives.reducer';
import productVariants from './components/product/components/productVariant/store/productVariant.reducer';
import productProductVariantAddons from './components/product/components/productVariant/components/subTablesWrapper/components/productVariantAddons/store/productVariantAddons.reducer';
import productVariantDiscountSet from './components/product/components/productVariant/components/subTablesWrapper/components/productVariantDiscountSet/store/productVariantDiscountSet.reducer';
import productProductVariantIngredients from './components/product/components/productVariant/components/subTablesWrapper/components/productVariantIngredients/store/productVariantIngredients.reducer';
import productProductVariantIngredientNutritionValues from './components/product/components/productVariant/components/subTablesWrapper/components/productVariantIngredients/components/productVariantIngredientNutritionValues/store/productVariantIngredientNutritionValues.reducer';
import productProductVariantIngredientAllergens from './components/product/components/productVariant/components/subTablesWrapper/components/productVariantIngredients/components/productVariantIngredientAllergens/store/productVariantIngredientAllergens.reducer';
import productVariantWorkplaceRelations from './components/product/components/productVariant/components/subTablesWrapper/components/productVariantWorkplaceRelations/store/productVariantWorkplaceRelations.reducer';
import productBundleTranslations from './components/product/components/productBundles/components/productBundleTranslations/store/productBundleTranslations.reducer';
import productBundleSetTranslations from './components/product/components/productBundles/components/productBundleSets/components/productBundleSetTranslations/store/productBundleSetTranslations.reducer';
import globalBundleRuleTranslations from './components/product/components/globalBundleRules/components/globalBundleRuleTranslations/store/globalBundleRuleTranslations.reducer';

import productTranslations from './components/product/components/product/components/productTranslations/store/productTranslations.reducer';
import ingredientTranslations from './components/ingredients/components/ingredients/components/ingredientTranslations/store/ingredientTranslations.reducer';
import ingredientComponents from './components/ingredients/components/ingredients/components/ingredientComponents/store/ingredientComponents.reducer';
import ingredientComponentTranslations from './components/ingredients/components/ingredients/components/ingredientComponents/components/ingredientComponentTranslations/store/ingredientComponentTranslations.reducer';
import globalIngredientComponentTranslations from './components/ingredients/components/ingredients/components/ingredientComponents/components/globalIngredientComponentTranslations/store/globalIngredientComponentTranslations.reducer';
import productVariantTranslations from './components/product/components/productVariant/components/subTablesWrapper/components/productVariantTranslations/store/productVariantTranslations.reducer';

// pos stuff
import salesConfigurationPosConfigurations from './components/pos/components/posConfigurations/store/posConfigurations.reducer';
import salesConfigurationPosConfigurationVersions from './components/pos/components/posConfigurationVersions/store/posConfigurationVersions.reducer';
import salesConfigurationPosConfigurationVersionCurrencies from './components/pos/components/posConfigurationVersions/components/posConfigurationVersionCurrencies/store/posConfigurationVersionCurrencies.reducer';
import posConfigurationLayout from './components/pos/components/posConfigurationVersions/components/posConfigurationTileLayouts/components/posConfigurationLayout/posConfigurationLayout.reducer';
import posAddTile from './components/pos/components/posConfigurationVersions/components/posConfigurationTileLayouts/components/posConfigurationLayout/posContainer/components/addTileModal/store/addTileModal.reducer';

// operational excellence
import basketRecommendationPriorities from './components/operationalExcellence/components/basketRecommendationPriorities/store/basketRecommendationPriorities.reducer';
import productVariantGroups from './components/operationalExcellence/components/productVariantGroups/store/productVariantGroups.reducer';
import productVariantGroupProductVariants from './components/operationalExcellence/components/productVariantGroups/components/productVariantGroupProductVariants/store/productVariantGroupProductVariants.reducer';
import productVariantBasketRecommendations from './components/operationalExcellence/components/productVariantBasketRecommendations/store/productVariantBasketRecommendations.reducer';
import filterGroups from './components/operationalExcellence/components/filterGroups/store/filterGroups.reducer';
import filters from './components/operationalExcellence/components/filterGroups/components/filters/store/filters.reducer';
import filterProductVariants from './components/operationalExcellence/components/filterGroups/components/filters/components/filterProductVariants/store/filterProductVariants.reducer';
import filterProductVariantIngredientVariationDefaultReplacements from './components/operationalExcellence/components/filterGroups/components/filters/components/filterProductVariants/components/filterProductVariantIngredientVariationDefaultReplacements/store/filterProductVariantIngredientVariationDefaultReplacements.reducer';
import waitingTimeSchedules from './components/operationalExcellence/components/waitingTimeSchedules/store/waitingTimeSchedules.reducer';
import waitingTimeScheduleEntries from './components/operationalExcellence/components/waitingTimeSchedules/components/waitingTimeScheduleEntries/store/waitingTimeScheduleEntries.reducer';

// layout
import homeScreenRecommendations from './components/layout/tileLayouts/components/homeScreenRecommendations/store/homeScreenRecommendations.reducer';
import homeScreenRecommendationTranslations from './components/layout/tileLayouts/components/homeScreenRecommendationTranslations/store/homeScreenRecommendationTranslations.reducer';
import posConfigurationTileLayoutsReducer from './components/pos/components/posConfigurationVersions/components/posConfigurationTileLayouts/store/posConfigurationTileLayouts.reducer';
import posConfigurationTileLayoutGroupTranslations from './components/pos/components/posConfigurationVersions/components/posConfigurationTileLayouts/components/posConfigurationTileLayoutGroupTranslations/store/posConfigurationTileLayoutGroupTranslations.reducer';

import nutritionCategoryTranslations from './components/nutrition/components/nutritionCategories/component/nutritionCategoryTranslations/store/nutritionCategoryTranslations.reducer';

export {
	nutritionCategoryTranslations,
	posConfigurationTileLayoutGroupTranslations,
	salesConfigurationAssetsUpload,
	salesConfigurationAssets,
	posConfigurationProductVariantsPricing,
	salesConfigurationPosConfigurationDiscountProductVariant,
	salesConfigurationProductAddonRecommendations,
	salesConfigurationAddonRecommendationsIngredients,
	salesConfigurationPosConfigurationDiscountIngredient,
	posConfigurationIngredientPricingVariation,
	salesConfigurationPosConfigurationIngredientVariantOptions,
	salesConfigurationPosConfigurationIngredientAssociationWithProducts,
	salesConfigurationProductRecommendations,
	salesConfigurationProductRecommendationProductVariant,
	posConfigurationIngredientPricingGroups,
	salesConfigurationIngredientPricings,
	workplacePosConfiguration,
	salesConfigurationProductBundle,
	salesConfigurationProductBundleSets,
	salesConfigurationProductBundleSetProductVariants,
	productAddonRecommendations,
	productAddonIngredients,
	salesConfigurationPosConfigurationDiscounts,
	salesConfigurationPosConfigurations,
	salesConfigurationPosConfigurationVersions,
	salesConfigurationPosConfigurationVersionCurrencies,
	salesConfigurationGlobalBundleRules,
	salesConfigurationProductBundleRules,
	productAssetLeftAligned,
	productVariants,
	productProductVariantAddons,
	productVariantDiscountSet,
	productProductVariantIngredients,
	productProductVariantIngredientNutritionValues,
	productProductVariantIngredientAllergens,
	productVariantWorkplaceRelations,
	basketRecommendationPriorities,
	productVariantGroups,
	productVariantGroupProductVariants,
	productVariantBasketRecommendations,
	filterGroups,
	filters,
	filterProductVariants,
	filterProductVariantIngredientVariationDefaultReplacements,
	waitingTimeSchedules,
	waitingTimeScheduleEntries,
	homeScreenRecommendations,
	productVariantIngredientAlternatives,
	productTranslations,
	ingredientTranslations,
	productVariantTranslations,
	ingredientComponents,
	posConfigurationTileLayoutsReducer,
	posConfigurationAssociatedIngredients,
	posConfigurationDiscountProductVariants,
	posConfigurationProductVariantIngredientVariations,
	posConfigurationLayout,
	posAddTile,
	ingredientComponentTranslations,
	globalIngredientComponentTranslations,
	homeScreenRecommendationTranslations,
	productBundleTranslations,
	productBundleSetTranslations,
	globalBundleRuleTranslations,
};
