'use strict';

// Services
import store from 'appState/store';
import { push } from 'redux-first-history';
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');

export default class TrainingGradeScalesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);

		this.headers = [
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
		];

		this.rowActions = function (props, row) {
			return [
				{
					method: props.methods.handleSubNavigate.bind(this, row.id),
					icon: 'icon--edit',
					validExpressions: [],
					name: 'Edit',
				},
			];
		};

		this.settings = {
			name: 'TrainingGradeScales',
			canAdd: true,
			canEdit: false,
			scrollWrapper: false,
			canDelete: true,
			canManipulate: () => validAccess(undefined, ['Global BI Configuration']),
			translationPath: 'TRAINING.GRADE_SCALES',
			endpoint: __endpoints.collection.training.grade_scales,
		};
		this.init();
		return this;
	}

	/**
	 * @function handleSubNavigate
	 * @description
	 * Handles navigation to sub pages
	 */
	handleSubNavigate(id) {
		store.dispatch(push(`${window.location.pathname}/scale/${id}`));
	}

	/**
	 * @function extendMethods
	 * @description
	 * Extend methods object from listModel
	 */
	extendMethods() {
		return Object.assign(this.getMethods(), {
			handleSubNavigate: this.handleSubNavigate.bind(this),
		});
	}

	/**
	 * @function getProps
	 * @description
	 * Overwrite getProps with extended methods
	 */
	getProps() {
		return Object.assign(this.getState(), {
			methods: this.extendMethods(),
			settings: this.getSettings(),
			translations: this.getTranslations(),
		});
	}
}
