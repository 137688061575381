import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ModalStepper, ButtonTile, InputCollectionSelect, Tooltip } from 'dumb';

// components
import AddProductVariantBasketRecommandationsAddRecommendation from './components/addProductVariantBasketRecommendationsAddRecommendation';
import AddProductVariantBasketRecommandationsAddExclusion from './components/addProductVariantBasketRecommendationsAddExclusion';
import AddProductVariantBasketRecommandationsPreview from './components/addProductVariantBasketRecommendationsPreview';

// lodash
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';

// utils
import {
	formatRecommendationPayload,
	formatExclusionPayload,
	splitDataIntoAddEdit,
} from './../../utils';

// moment
import moment from 'moment';

// phrases/constants/enums
import phrases from '../../productVariantBasketRecommendations.phrases';
import constants from 'services/constants';
import enums from './../../productVariantBasketRecommendations.enums';

class AddProductVariantBasketRecommendations extends Component {
	constructor(props) {
		super(props);

		this.today = moment.utc().format(constants.shortDate);
		this.modalRef = React.createRef();

		this.state = {
			allowOverflow: true,
			loadingModal: false,
		};

		this.onSubmit = this.onSubmit.bind(this);
		this._getRecommendationId = this._getRecommendationId.bind(this);
		this._getExclusionId = this._getExclusionId.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(res, basketRecommendation) {
		const {
			editProductVariantBasketRecommendationWrapper,
			addProductVariantBasketRecommendationWrapper,
		} = this.props;

		const editMode = !!basketRecommendation.productVariantBasketRecommendationId;

		const payload = {
			...(editMode
				? {
						id: basketRecommendation.productVariantBasketRecommendationId,
				  }
				: {
						product_variant: _get(
							basketRecommendation,
							'productVariant.id',
							null
						),
				  }),
			recommendation_priority: res[0],
			exclude: res[1],
		};

		const funcToExecute = editMode
			? editProductVariantBasketRecommendationWrapper(payload)
			: addProductVariantBasketRecommendationWrapper(payload);

		return funcToExecute;
	}

	onSubmit() {
		const {
			batchFormData,
			handleClose,
			editProductVariantBasketRecommendations,
			addProductVariantBasketRecommendation,
			reRenderTable,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		// determine whether existing chosen or create new chosen
		// if existing chosen, simply grab ids and submit
		// if new chosen, format the data and sumbmit first for priorities and then recommendation with that priority
		Promise.all([this._getRecommendationId(), this._getExclusionId()]).then(
			(res) => {
				// multi submit mode
				if (
					_isArray(batchFormData.productVariants) &&
					!_isEmpty(batchFormData.productVariants)
				) {
					const { dataToAdd, dataToEdit } = splitDataIntoAddEdit({
						batchFormData,
						res,
					});

					Promise.all([
						addProductVariantBasketRecommendation({ batch: dataToAdd }),
						editProductVariantBasketRecommendations({ batch: dataToEdit }),
					])
						.then(reRenderTable)
						.then(handleClose)
						.finally(() => this.setState(() => ({ loadingModal: false })));
				}
				// single submit mode
				else {
					this.handleSubmit(res, batchFormData)
						.then(handleClose)
						.finally(() => this.setState(() => ({ loadingModal: false })));
				}
			}
		);
	}

	_getRecommendationId() {
		const { batchFormData, addBasketRecommendationPriority } = this.props;

		if (_isEmpty(batchFormData.basketRecommendation)) {
			// check if existingBasketRecommendation has a field called edited. If so, edit it. If not, return existing id

			return batchFormData.existingBasketRecommendation?.value?.id ?? null;
		}

		// format the data
		const formattedPayload = formatRecommendationPayload(
			batchFormData.basketRecommendation
		);

		return addBasketRecommendationPriority(formattedPayload).then(
			(res) => res.data[0].id
		);
	}

	_getExclusionId() {
		const { batchFormData, addBasketRecommendationPriority } = this.props;

		if (_isEmpty(batchFormData.basketExclusion)) {
			return batchFormData.existingBasketExclusion?.value?.id ?? null;
		}

		// format the data
		const formattedPayload = formatExclusionPayload(
			batchFormData.basketExclusion
		);

		return addBasketRecommendationPriority(formattedPayload).then(
			(res) => res.data[0].id
		);
	}

	_goToStep(step) {
		const shouldAllowOverFlow = step === 2 || step === 4;

		this.setState(() => ({ allowOverflow: shouldAllowOverFlow }));

		this.modalRef.current.goToStep(step);
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
		} = this.props;

		const steps = [
			// STEP 1
			{
				component: (
					<div className="add-product-variant-basket-recommendations__choice-page">
						<InputCollectionSelect
							id="recommendationPriority"
							key="recommendationPriority"
							label={phrases.RECOMMENDATION}
							placeholder={phrases.RECOMMENDATION_PLACEHOLDER}
							value={batchFormData.existingBasketRecommendation}
							handleChange={(key, value) =>
								updateBatchForm({
									existingBasketRecommendation: value,
								})
							}
							cache
							apiPath="/pos/basket_recommendation_priorities"
							params={{
								limit: 30,
								filter: `:type=='${enums.RECOMMENDATION}'`,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>
						<div className="add-product-variant-basket-recommendations__choice-page__section">
							<Tooltip
								zIndex={600}
								text={phrases.ADD_RECOMMENDATION_PAGE_TITLE_TOOLTIP}
								renderData={(ref, onMouseEnter, onMouseLeave) => (
									<ButtonTile
										refProp={ref}
										onMouseEnter={onMouseEnter}
										onMouseLeave={onMouseLeave}
										icon="add"
										type="inverted"
										shadow
										onClick={() => this._goToStep(2)}>
										{phrases.ADD}
									</ButtonTile>
								)}
							/>
						</div>
					</div>
				),
				title: phrases.ADD_RECOMMENDATION_PAGE_TITLE,
				loading: this.state.loadingModal,
				allowOverflow: true,
				confirmButtonLabel: phrases.CONFIRM_BUTTON_LABEL,
				isValid: !_isEmpty(batchFormData.existingBasketRecommendation),
				onNext: () => this._goToStep(3),
			},
			// STEP 2
			{
				component: (
					<AddProductVariantBasketRecommandationsAddRecommendation
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
					/>
				),
				title: phrases.ADD_RECOMMENDATION_TITLE,
				isValid: !!_get(batchFormData, 'basketRecommendation.name', false),
				onBack: () => this._goToStep(1),
				onNext: () => this._goToStep(3),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.BACK_BUTTON_LABEL,
				noScroll: false,
			},
			// STEP 3
			{
				component: (
					<div className="add-product-variant-basket-recommendations__choice-page">
						<InputCollectionSelect
							key="exclusionPriority"
							id="exclusionPriority"
							label={phrases.EXCLUSION}
							placeholder={phrases.EXCLUSION_PLACEHOLDER}
							value={batchFormData.existingBasketExclusion}
							handleChange={(key, value) =>
								updateBatchForm({
									existingBasketExclusion: value,
								})
							}
							cache
							apiPath="/pos/basket_recommendation_priorities"
							params={{
								limit: 30,
								filter: `:type=='${enums.EXCLUSION}'`,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>
						<div className="add-product-variant-basket-recommendations__choice-page__section">
							<Tooltip
								zIndex={600}
								text={phrases.EXCLUSION_PAGE_TOOLTIP}
								renderData={(ref, onMouseEnter, onMouseLeave) => (
									<ButtonTile
										refProp={ref}
										onMouseEnter={onMouseEnter}
										onMouseLeave={onMouseLeave}
										icon="add"
										type="inverted"
										shadow
										onClick={() => this._goToStep(4)}>
										{phrases.ADD}
									</ButtonTile>
								)}
							/>
						</div>
					</div>
				),
				title: phrases.EXCLUSION_PAGE_TITLE,
				loading: this.state.loadingModal,
				allowOverflow: true,
				confirmButtonLabel: phrases.CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.BACK_BUTTON_LABEL,
				isValid: !_isEmpty(batchFormData.existingBasketExclusion),
				onNext: () => this._goToStep(5),
				onBack: () => this._goToStep(1),
			},
			// STEP 4
			{
				component: (
					<AddProductVariantBasketRecommandationsAddExclusion
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
					/>
				),
				title: phrases.ADD_EXCLUSION_TITLE,
				isValid: !!_get(batchFormData, 'basketExclusion.name', false),
				onBack: () => this._goToStep(3),
				onNext: () => this._goToStep(5),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.BACK_BUTTON_LABEL,
				noScroll: false,
			},
			// STEP 5
			{
				component: (
					<AddProductVariantBasketRecommandationsPreview
						batchFormData={batchFormData}
					/>
				),
				title: phrases.PREVIEW,
				isValid: true,
				onBack: () => this._goToStep(1),
				onNext: this.onSubmit,
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.SUBMIT,
				cancelButtonLabel: phrases.BACK_TO_BEGINNING,
				noScroll: false,
			},
		];

		return modalVisible ? (
			<ModalStepper
				ref={this.modalRef}
				isOpen={modalVisible}
				steps={steps}
				showStep={false}
				onClose={handleClose}
				allowOverflow={this.state.allowOverflow}
			/>
		) : null;
	}
}

AddProductVariantBasketRecommendations.defaultProps = {
	modalVisible: false,
	headers: [],
	editableCells: [],
	loading: false,
};

AddProductVariantBasketRecommendations.propTypes = {
	modalVisible: PropTypes.bool,
	updateBatchForm: PropTypes.func,
	handleClose: PropTypes.func,
	batchFormData: PropTypes.object,
	addProductVariantBasketRecommendationWrapper: PropTypes.func,
	addBasketRecommendationPriority: PropTypes.func,
	editProductVariantBasketRecommendationWrapper: PropTypes.func,
	editProductVariantBasketRecommendations: PropTypes.func,
	addProductVariantBasketRecommendation: PropTypes.func,
	reRenderTable: PropTypes.func,
};

export default AddProductVariantBasketRecommendations;
