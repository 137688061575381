'use strict';

import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './posConfigurationVersionCurrencies.phrases';

/**
 * @function fetchPosConfigurationVersionCurrencies
 * @description Fetch all pos configuration version currencies
 */
export function fetchPosConfigurationVersionCurrencies(state, extraFilter) {
	const filter = state.filter ? `${state.filter};${extraFilter}` : extraFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get('/pos/pos_configuration_currencies', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

export function addPosConfigurationVersionCurrency(data) {
	return post('/pos/pos_configuration_currencies', data)
		.then((res) => {
			setFeedback(phrases.POS_CONFIGURATION_VERSION_CURRENCY_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editPosConfigurationVersionCurrency
 * @param {Object} payload - update object
 */
export function editPosConfigurationVersionCurrency(payload) {
	return post(`/pos/pos_configuration_currencies/${payload.id}`, payload)
		.then((res) => {
			setFeedback(phrases.POS_CONFIGURATION_VERSION_CURRENCY_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deletePosConfigurationVersionCurrency
 * @param {Number} id - id of pos config version to delete
 */
export function deletePosConfigurationVersionCurrency(id) {
	return remove(`/pos/pos_configuration_currencies/${id}`)
		.then((res) => {
			setFeedback(phrases.POS_CONFIGURATION_VERSION_CURRENCY_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
