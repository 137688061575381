import _get from 'lodash/get';

/**
 * @function getFlairToDisplay
 * @param {Object} entry - tile layout tile object
 * @description prioritizes a flair to display based on sort order
 */
export function getFlairToDisplay(entry) {
	const productFlair = _get(entry, 'product.flair', null);
	const tileFlair = entry.flair;

	if (!productFlair) return tileFlair;
	if (!tileFlair) return productFlair;

	return productFlair.sort_order < tileFlair.sort_order
		? productFlair
		: tileFlair;
}
