import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ButtonLoader, Icon } from 'dumb';

import TableWrapper from './subcomponents/table';

class Report extends PureComponent {
	constructor(props) {
		super(props);

		this._csvDownload = this._csvDownload.bind(this);

		this.state = {};
	}

	// Return all tables as TableWrapper
	getTables() {
		const { phrases, tables } = this.props;

		return tables === null ? (
			<h4 className="report-old__no-data-error">{phrases.NO_TABLES}</h4>
		) : (
			tables.result.map((key, index) => (
				<TableWrapper
					key={key + index}
					table={tables.entities[key]}
					{...this.props}
				/>
			))
		);
	}

	_csvDownload(report, csvDataProvider) {
		this.setState(() => ({ [csvDataProvider.name]: true }));
		report
			.handleCsvDownload(csvDataProvider)
			.then(() => {
				this.setState(() => ({ [csvDataProvider.name]: false }));
			})
			.catch(() => {
				this.setState(() => ({ [csvDataProvider.name]: false }));
			});
	}

	_renderReportActions(report) {
		return report
			.getReportActions()
			.map(({ Component, configuration }) => (
				<Component config={configuration} key={configuration.type} />
			));
	}

	_renderCsvButtons(report) {
		return report.getCsvDataProviders().map(csvDataProvider => {
			const fetching = this.state[csvDataProvider.name] || false;

			return (
				<a
					onClick={() => this._csvDownload(report, csvDataProvider)}
					key={csvDataProvider.name}
					data-label={csvDataProvider.name}>
					{fetching ? (
						<ButtonLoader loading={fetching} theme="dark" />
					) : (
						<Icon name="file_download" />
					)}
				</a>
			);
		});
	}

	render() {
		const { metadata, methods, phrases, report } = this.props;

		return (
			<div className="report-old">
				<div className="report-old__meta-wrapper">
					<h1>{report.metadata.getTitle(metadata)}</h1>
					<h2>{report.metadata.getSubtitle(metadata)}</h2>
					<aside className="report-old__actions">
						<a
							onClick={methods.onPrintViewToggle}
							data-label={phrases.ACTIONS.EXPORT}>
							<Icon name="print" />
						</a>
						{this._renderCsvButtons(report)}
						{this._renderReportActions(report)}
					</aside>
				</div>
				{this.getTables()}
			</div>
		);
	}
}

Report.propTypes = {
	metadata: PropTypes.object.isRequired,
	methods: PropTypes.object.isRequired,
	phrases: PropTypes.object.isRequired,
	report: PropTypes.object.isRequired,
	tables: PropTypes.object.isRequired
};

export default Report;
