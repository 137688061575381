export const SET_FORECASTING = 'SHIFTPLANNER/SET_FORECASTING';
export const UPDATE_SHIFTPLANNER_FORECASTING = 'SHIFTPLANNER/UPDATE_SHIFTPLANNER_FORECASTING';
export const TOGGLE_FORECASTING = 'SHIFTPLANNER/TOGGLE_FORECASTING';
export const TOGGLE_FORECASTING_GHOST = 'SHIFTPLANNER/TOGGLE_FORECASTING_GHOST';
export const LOADING_FORECASTING = 'SHIFTPLANNER/LOADING_FORECASTING';
export const CLEAN_FORECASTING = 'SHIFTPLANNER/CLEAN_FORECASTING';

/**
 * @function setShiftplannerForecasting
 * @param payload : {forecastData: object, shifts: object}
 * @returns {{type: string, payload: {regionalManagers: Array}}}
 */
export function setShiftplannerForecasting(payload = {}) {
	return {
		type: SET_FORECASTING,
		payload
	};
}

/**
 * @function updateShiftplannerForecasting
 * @param payload : { shifts: object}
 * @returns {{type: string, payload: {regionalManagers: Array}}}
 */
export function updateShiftplannerForecasting(payload = {}) {
	return {
		type: UPDATE_SHIFTPLANNER_FORECASTING,
		payload
	};
}

export function toggleForecasting() {
	return {
		type: TOGGLE_FORECASTING
	};
}
export function cleanForecasting() {
	return {
		type: CLEAN_FORECASTING
	};
}

export function toggleForecastingGhost() {
	return {
		type: TOGGLE_FORECASTING_GHOST
	};
}

// used for both visuals
export function loadingForecasting(payload) {
	return {
		type: LOADING_FORECASTING,
		payload
	};
}