import React from 'react';
import PropTypes from 'prop-types';

import './legend.css';

const Legend = props => {
	return (
		<div className="legend">
			{props.points.map((point, index) => {
				const style = {
					backgroundColor: point.bulletColor,
				};

				return (
					<div className="legend__point" key={index}>
						<span className="legend__bullet" style={style} />
						<span key={index}>{point.label}</span>
					</div>
				);
			})}
		</div>
	);
};

Legend.propTypes = {
	points: PropTypes.arrayOf(
		PropTypes.shape({
			bulletColor: PropTypes.any.isRequired,
			label: PropTypes.string.isRequired
		})
	)
};

export default Legend;
