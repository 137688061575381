'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setSystemSoftwares,
	updateSystemSoftwares,
	resetState,
} from './store/systemSoftwares.actions';

// services
import {
	fetchSystemSoftwares,
	editSystemSoftware,
	addSystemSoftwares,
	deleteSystemSoftware,
} from './systemSoftwares.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { InputCollectionSelect, Button, Icon, Tabs } from 'dumb';
import SystemSoftwaresModalBatch from './components/batch/systemSoftwaresModalBatch';
import SubTable from 'reactDataWrapper/components/subTable';
import SystemSoftwareVersions from './components/systemSoftwareVersions/systemSoftwareVersions.container';
import getColumns from 'reactDataWrapperColumns/administration/systemSoftwares.columns';
import WorkplaceSystemSoftwares from './../../organisation/workplaces/components/workplaceSystemSoftwares/workplaceSystemSoftwares.container';

// lodash
import _has from 'lodash/has';

// utils
import { getEditedValues } from 'services/utils';

// moment
import moment from 'moment';

// phrases
import phrases from './systemSoftwares.phrases';

const reduxKey = '/administration/system_softwares';

class SystemSoftwares extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment().unix(),
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.addSystemSoftwareWrapper = this.addSystemSoftwareWrapper.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.editEntry = this.editEntry.bind(this);

		this.columns = getColumns();
	}

	editEntry() {
		const { systemSoftwares, originalSystemSoftwares } = this.props;

		const editedValues = getEditedValues({
			oldData: originalSystemSoftwares,
			newData: systemSoftwares,
		});

		const payload = {
			id: systemSoftwares.id,
			title: editedValues.title ?? undefined,
			...(_has(editedValues, 'currentVersion')
				? {
						system_software_version: editedValues.currentVersion,
				  }
				: {}),
		};

		return editSystemSoftware(payload);
	}

	deleteEntry(id) {
		return deleteSystemSoftware(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			title: data.title,
			currentVersion: data.system_software_version
				? {
						value: data.system_software_version?.id,
						label: data.system_software_version?.version,
				  }
				: null,
			singleEdit: true,
		};

		this.props.setSystemSoftwares(payload);
	}

	getEditableCells() {
		const { systemSoftwares } = this.props;

		return [
			{
				header: 'Title',
				value: <span>{systemSoftwares.title}</span>,
			},
			...(systemSoftwares.singleEdit
				? [
						{
							header: 'Current version',
							value: (
								<InputCollectionSelect
									id="current-version"
									placeholder="Select current version..."
									value={systemSoftwares?.currentVersion}
									handleChange={(key, value) =>
										this.editStoreEntry('currentVersion', value)
									}
									optionFormat={(entry) => ({
										value: entry.id,
										label: entry.version,
									})}
									params={{
										limit: 30,
										filter: `:system_software.id=='${systemSoftwares.id}'`,
									}}
									apiPath="/administration/system_software_versions"
								/>
							),
						},
				  ]
				: []),
		];
	}

	editStoreEntry(name, e) {
		const { updateSystemSoftwares } = this.props;

		const value = e?.target?.value ?? e;

		const updateObject = {
			[name]: value,
		};

		updateSystemSoftwares(updateObject);
	}

	fetchData(state) {
		return fetchSystemSoftwares(state);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	addSystemSoftwareWrapper(data) {
		return addSystemSoftwares(data).then(() =>
			this.setState(() => ({
				key: moment().unix(),
			}))
		);
	}

	render() {
		const {
			batchList,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={reduxKey}
					accessAreasAllowedToEdit={['Global Sales Configuration']}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					deleteEntry={this.deleteEntry}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					enableSingleSelection
					enableMultiSelection={false}
					actionRender={
						<Button
							type="inverted"
							label="Batch"
							shadow
							onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
					subcomponent={(row) => {
						const tabContent = [
							{
								name: phrases.SYSTEM_SOFTWARE_VERSIONS,
								component: (
									<SystemSoftwareVersions systemSoftwareId={row.original?.id} />
								),
							},
							{
								name: phrases.WORKPLACE_SYSTEM_SOFTWARE_VERSIONS,
								component: (
									<WorkplaceSystemSoftwares
										systemSoftwareId={row.original?.id}
									/>
								),
							},
						];

						return (
							<SubTable>
								<Tabs tabContent={tabContent} />
							</SubTable>
						);
					}}
				/>

				<SystemSoftwaresModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addSystemSoftwares={this.addSystemSoftwareWrapper}
				/>
			</>
		);
	}
}

SystemSoftwares.propTypes = {
	systemSoftwares: PropTypes.object,
	updateSystemSoftwares: PropTypes.func,
	setSystemSoftwares: PropTypes.func,
	resetState: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	originalSystemSoftwares: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			editBatchListItem,
			removeBatchListItem,
			updateSystemSoftwares,
			setSystemSoftwares,
			resetState,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		systemSoftwares:
			store.globalAdministrationSystemSoftwares.data.systemSoftwares,
		originalSystemSoftwares:
			store.globalAdministrationSystemSoftwares.data.originalSystemSoftwares,
		batchList: store.globalAdministrationSystemSoftwares.data.batchList,
	};
};

export default connectWithStore(
	SystemSoftwares,
	mapStateToProps,
	mapDispatchToProps
);
