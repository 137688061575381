export default {
	TITLE: 'Market overtime rules',
	MARKET_OVERTIME_RULE_ADDED: 'Market rules added!',
	MARKET_OVERTIME_RULE_EDITED: 'Market rule edited!',
	MARKET_OVERTIME_RULES_EDITED: 'Market rules edited!',
	MARKET_OVERTIME_RULE_DELETED: 'Market rule deleted!',

	// batch form
	MODAL_BATCH_STEP_LIST_TITLE: 'Add market overtime rules',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_MARKET: 'Market',
	MODAL_BATCH_STEP_AMOUNT: 'Amount',
	MODAL_BATCH_STEP_UNIT: 'Unit',
	MODAL_BATCH_STEP_TYPE: 'Type',
	MODAL_BATCH_STEP_SECOND_AMOUNT: 'Second amount',
	MODAL_BATCH_STEP_SECOND_UNIT: 'Second uni',
	MODAL_BATCH_STEP_FROM: 'From',
	MODAL_BATCH_STEP_TO: 'To',

	MODAL_BATCH_STEP_MARKET_PLACEHOLDER: 'Select market...',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add rules',
};
