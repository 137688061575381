import { get, post } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

// constants/ phrases/ enums
import constants from 'services/constants';
import phrases from './shiftClockins.phrases';
import enums from './shiftClockins.enums';
import { formatShiftClockins } from './utils';

// utils
import { saveAs } from 'file-saver';
import moment from 'moment';

/**
 * @function fetchShiftClockins
 * @description Fetch all product bundles
 * @param {object} state
 */
export function fetchShiftClockins({ filters, csv = false, state }) {
	const params = {
		filter: filters.filter,
		limit: state.limit,
		offset: state.offset,
		sort: state.sort,
		from: filters.startDate,
		to: filters.endDate,
		employees: filters.employmentIds,
		...(filters.statusFilter && {
			aligned:
				filters.statusFilter.value === enums.STATUS_FILTER_ALIGNED ? 1 : 0,
		}),
	};

	return get(
		'/shiftplanning/managed_clockings',
		params,
		null,
		csv ? { Accept: 'application/csv' } : null
	)
		.then((res) => {
			if (csv) {
				if (!res)
					console.info('No csv data. Did you forget to return the promise?');

				const blob = new Blob([res], { type: 'text/csv;charset=utf-8' });
				const csvFileName = `shift__clockin__${moment().format(
					constants.shortDate
				)}.csv`;
				const fileName = csvFileName;
				saveAs(blob, fileName, { autoBom: false });
				return;
			}

			const formattedRes = formatShiftClockins(res.data);

			return {
				...res,
				data: formattedRes,
			};
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchShiftClockinHourlyStats
 * @description Fetch all product bundles
 * @param {object} state
 */
export function fetchShiftClockinHourlyStats({ workplaceId, date, state }) {
	const params = {
		filter: state.filter,
		limit: state.limit,
		offset: state.offset,
		sort: state.sort,
	};

	const url = `/shiftplanning/workplaces/${workplaceId}/date/${date}/hourly_stats`;

	return get(url, params)
		.then((res) => res)
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchShiftClockinDeviationExport
 * @description Fetch shift clockin deviation export
 */
export function fetchShiftClockinDeviationExport(payload) {
	return get('/shiftplanning/clocking_deviation_export', payload, null, {
		Accept: 'application/csv',
	})
		.then((res) => {
			const blob = new Blob([res], { type: 'text/csv;charset=utf-8' });
			const csvFileName = `shift__clocking__deviation__export__${moment().format(
				constants.shortDate
			)}.csv`;
			const fileName = csvFileName;
			saveAs(blob, fileName, { autoBom: false });
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function postManagedClockinCorrection
 * @description post csv to bank reconciliation endpoint
 */
export function postManagedClockinCorrection(data) {
	return post(`/shiftplanning/managed_clockings/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.MANAGED_CLOCKIN_CORRECTED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function postCloseOpenWorkdays
 * @description All open workdays qualified for automatic close will be closed.
 */
export function postCloseOpenWorkdays() {
	return post(`/shiftplanning/close_work_days`, { close: true })
		.then((res) => {
			setFeedback(phrases.SUCCESS, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function correctShiftClockinBreak
 * @description adds a new break to a shfit clockin
 */
export function correctShiftClockinBreak(body) {
	return post('/shiftplanning/shift_clocking_break_corrections', body)
		.then((res) => {
			setFeedback(phrases.SHIFT_CLOCKIN_BREAK_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
