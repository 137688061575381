export default {
	ADDON_INGREDIENTS: 'Ingredients',
	PRODUCT_VARIANT_ADDONS: 'Product Variant',
	PRODUCT_ADDONS: 'Product Addons',
	ADDON_RECOMMENDATIONS: 'Addon recommendations',
	TOOLIP_TEXT: 'Duplicate addon',
	DUPLICATE_PRODUCT_ADDON: 'Duplicate Product Addon',
	DUPLICATE_PRODUCT_ADDON_CONFIRM_BUTTON: 'Duplicate',
	DUPLICATE_PRODUCT_ADDON_CANCEL_BUTTON: 'Cancel',
	DUPLICATE_PRODUCT_ADDON_NAME: 'Name',
	DUPLICATE_PRODUCT_ADDON_NAME_PLACEHOLDER: 'Enter name...',
	DUPLICATE_PRODUCT_ADDON_DESCRIPTION: 'Description',
	DUPLICATE_PRODUCT_ADDON_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	DUPLICATE_PRODUCT_ADDON_BUTTON_LABEL: 'Duplicate',
};
