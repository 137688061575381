export default {
	TABLE_TITLE: 'Layouts',
	TABLE_TITLE_APP: 'App Layouts',
	TABLE_TITLE_POS: 'POS Layouts',
	ACTIONS: 'Actions',
	ACTIONS_TOOLTIP: 'Custom layout actions',
	REORDER_PRODUCTS: 'Reorder products',
	TABLE_HEADER_LAYOUT_NAME: 'Layout name',
	TABLE_HEADER_DESCRIPTION: 'Description',
	TABLE_HEADER_TYPE: 'Type',
	TABLE_HEADER_ROWS: 'Rows',
	TABLE_HEADER_COLUMNS: 'Columns',
	TABLE_HEADER_SUB_TYPE: 'Sub type',
	NEW_LAYOUT: 'New layout',
	INSERT_PRODUCTS: 'Insert products',
	DELETE_PRODUCTS: 'Delete products',
	DUPLICATE_LAYOUT: 'Duplicate layout',
	SORT_PRODUCTS: 'Sort products',
	TILE_LAYOUTS: 'Tile layouts',
	TILE_LAYOUTS_SELECT: 'Select Tile layouts',
	TILE_LAYOUT_GROUPS: 'Tile Layout Groups',
	TILE_LAYOUT_GROUPS_SELECT: 'Select tile layout group',
	TIP_LAYOUTS: 'Select layout you want to duplicate.',
	TIP_GROUPS: 'Select layout groups where you want to insert products.',
	TIP_PRODUCTS: 'Select products that will be added to all groups',
	PRODUCT_TITLE: 'Products',
	PRODUCT_SELECT: 'Select products',
	STORES: 'Stores',
	REORDER_PRODUCTS_SELECT_GROUPS_TITLE: 'Select groups',
	REORDER_PRODUCTS_SELECT_GROUPS_TIP:
		'Select groups to reorder their products in the next step',
	REORDER_PRODUCTS_SELECTED_LAYOUTS: 'Selected layouts:',
	HOME_SCREEN_RECOMMENDATIONS: 'Home screen recommendations',
	HOME_SCREEN_RECOMMENDATIONS_TRANSLATIONS: 'Home screen recommendation translations',
	SHOW_CASE_IMAGE: 'Show case image',
	SHOW_CASE_IMAGE_PLACEHOLDER: 'Select Show case image...',
	SHOW_CASE_IMAGE_TABLE_TITLE: 'Show case imageS',
};
