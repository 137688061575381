'use strict';

export const EDIT_STORE_ACCESS_TYPES =
	'PERSON_STORE_ACCESS/EDIT_STORE_ACCESS_TYPES';
export const SET_STORE_ACCESS_TYPES =
	'PERSON_STORE_ACCESS/SET_STORE_ACCESS_TYPES';
export const RESET_STATE = 'PERSON_STORE_ACCESS/RESET_STATE';

export const editPersonStoreAccess = payload => {
	return { type: EDIT_STORE_ACCESS_TYPES, payload };
};

export const setPersonStoreAccess = payload => {
	return { type: SET_STORE_ACCESS_TYPES, payload };
};

export const resetState = () => {
	return { type: RESET_STATE };
};
