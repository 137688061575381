// interface
import { ActionType } from 'types/redux';
import {
	IMarketTagsReduxValue,
	IMarketTagsReducer,
	IMarketTagsReduxValueBatch,
} from '../types';

// utils
import moment from 'moment';
import _uniqueId from 'lodash/uniqueId';

import {
	SET_MARKET_TAG,
	UPDATE_MARKET_TAG,
	RESET_STATE,

	// batch
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	RERENDER_TABLE,
	EDIT_BATCH_FORM,
	SET_BATCH_FORM_DATA,
} from './marketTags.actions';

const defaultData = {
	tag: null,
	market: null,
	defaultLengthInMinutes: null,
	minutesFromStartOfShift: null,
	minutesFromEndOfShift: null,
};

const defaultState: IMarketTagsReducer = {
	tableKey: moment().unix(),
	data: {
		defaultMarketTag: null,
		marketTag: defaultData,
	},
	batchForm: {
		tags: null,
		markets: null,
		defaultLengthInMinutes: null,
		minutesFromStartOfShift: null,
		minutesFromEndOfShift: null,
	},
	batchList: [
		{
			identifier: _uniqueId('row-'),
			...defaultData,
		},
	],
};

function reducer(state = defaultState, action: ActionType): IMarketTagsReducer {
	const { type, payload } = action;

	switch (type) {
	case SET_MARKET_TAG: {
		const castedPayload = payload as IMarketTagsReduxValue;

		return {
				...state,
				data: {
					marketTag: castedPayload,
					defaultMarketTag: castedPayload,
				},
			};
	}

	case UPDATE_MARKET_TAG: {
		const castedPayload = payload as Partial<IMarketTagsReduxValue>;

		return {
				...state,
				data: {
					...state.data,
					marketTag: {
						...state.data.marketTag,
						...castedPayload,
					},
				},
			};
	}

	case RESET_STATE: {
		return defaultState;
	}

	case RERENDER_TABLE:
		return {
				...state,
				tableKey: moment().unix(),
			};

	case SET_BATCH_FORM_DATA: {
		const castedPayload = payload as IMarketTagsReduxValueBatch[];

		return {
				...state,
				batchList: castedPayload,
			};
	}

	case EDIT_BATCH_FORM: {
		const castedPayload = payload as Partial<IMarketTagsReduxValue>;

		return {
				...state,
				batchForm: {
					...state.batchForm,
					...castedPayload,
				},
			};
	}

	case EDIT_BATCH_LIST_ITEM: {
		const castedPayload = payload as Partial<IMarketTagsReduxValueBatch>;

		return {
				...state,
				batchList: state.batchList.map((entry) => {
					if (entry.identifier === castedPayload.identifier) {
						return { ...entry, ...castedPayload };
					}
					return entry;
				}),
			};
	}

	case REMOVE_BATCH_LIST_ITEM: {
		const castedPayload = payload as string;

		return {
				...state,
				batchList: state.batchList.filter(
					(entry) => entry.identifier !== castedPayload
				),
			};
	}

	default:
		return state;
	}
}

export default reducer;
