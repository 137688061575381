import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

// Style
import { styled } from '@mui/material/styles';
import { isMobile } from 'detectMobile.vanilla';
import useMediaQuery from '@mui/material/useMediaQuery';

// Redux
import { setPowerBIReports } from 'reports/powerBIReport/store/powerBIReport.actions';
import { showSearchModal } from 'services/globalSearch/actions';
import { setDrawerOpen } from 'services/appSettings/actions';
import _uniqBy from 'lodash/uniqBy';
import _debounce from 'lodash/debounce';

// UI
import {
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Collapse,
	Drawer as MuiDrawer,
	Divider,
	Typography,
	Tooltip,
	IconButton,
	Box,
	Fade,
	Backdrop,
	Fab,
} from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ViewSidebarOutlinedIcon from '@mui/icons-material/ViewSidebarOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
// import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import TenantSelect from 'smart/tenantSelect/tenantSelect';
import GlobalSearchInput from 'smart/globalSearchInput/globalSearchInput';
import GlobalSearchModal from 'smart/globalSearchModal/globalSearchModal';
import MenuIcon from '@mui/icons-material/Menu';

// Utils
import { fetchPowerBIReports } from './drawer.service';
import { formatPowerBIReports } from 'services/navigation';
import HotkeysService from 'hotkeys.service';

const openedMixin = (theme) => {
	return {
		width: theme.settings.drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	};
};

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	// overflowX: 'hidden',
	width: 0,

	[theme.breakpoints.up('md')]: {
		width: `calc(${theme.spacing(7)} + 1px)`,
	},
});

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: theme.settings.drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',

	'& .MuiDrawer-paper': {
		backgroundColor: process.env.wpApiHost.includes('api2test.joejuice.com')
			? theme.palette.customColours.navbarDevelopMain
			: theme.palette.secondary.dark,
		boxShadow: theme.shadows[3],
		borderRadius: 0,
		zIndex: 250,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',

		...(open && {
			...openedMixin(theme),
			// '& .MuiDrawer-paper': openedMixin(theme),
		}),

		...(!open && {
			...closedMixin(theme),
			// '& .MuiDrawer-paper': closedMixin(theme),
		}),

		'& ul': {
			padding: 0,
		},
	},
}));

const ListItemTextStyling = {
	fontFamily: 'Roboto Condensed',
	fontSize: 14,
	fontWeight: 600,
	letterSpacing: '0.5px',
	textTransform: 'uppercase',
};

const sharedListStyling = {
	color: '#fff',

	'& .MuiSvgIcon-root': {
		fontSize: 22,
		color: 'rgba(255, 255, 255, 0.5)',
	},

	'& .MuiListItemText-primary': ListItemTextStyling,

	'& .MuiListItemSecondaryAction-root': {
		display: 'flex',
	},
};

const JoeNavList = styled(List)(({ theme }) => ({
	backgroundColor: process.env.wpApiHost.includes('api2test.joejuice.com')
		? theme.palette.customColours.navbarDevelopMain
		: theme.palette.secondary.dark,

	'& .MuiListItem-root': {
		paddingTop: theme.spacing(1 / 2),
		paddingBottom: theme.spacing(1 / 2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(3),

		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
		},

		'&:hover': {
			backgroundColor: process.env.wpApiHost.includes('api2test.joejuice.com')
				? theme.palette.customColours.navbarDevelopSecondary
				: 'rgba(255, 255, 255, 0.08)',
		},

		// Very specific to JoeNavNested
		'&.Mui-selected': {
			backgroundColor: process.env.wpApiHost.includes('api2test.joejuice.com')
				? theme.palette.customColours.navbarDevelopMain
				: theme.palette.secondary.light,
		},
	},

	'& .MuiListItemIcon-root': {
		minWidth: 0,
		marginRight: theme.spacing(2),
	},

	...sharedListStyling,
}));

const JoeNavNested = styled(List)(({ theme }) => ({
	backgroundColor: process.env.wpApiHost.includes('api2test.joejuice.com')
		? theme.palette.customColours.navbarDevelopSecondary
		: 'rgba(255, 255, 255, 0.08)',

	'& .MuiListItem-root': {
		paddingLeft: theme.spacing(6.75),
		paddingRight: 24,

		'&:hover': {
			backgroundColor: process.env.wpApiHost.includes('api2test.joejuice.com')
				? theme.palette.customColours.navbarDevelopSecondary
				: 'rgba(255, 255, 255, 0.08)',
		},
	},

	...sharedListStyling,
}));

const JoeDivider = styled(Divider)({
	backgroundColor: 'rgba(255, 255, 255, 0.05)',
	borderBottom: 'none',
});

const getDebouncedPowerBIReports = _debounce(
	async (userId, dispatch) => {
		let powerBIReports = await fetchPowerBIReports(userId);

		powerBIReports = formatPowerBIReports({ data: powerBIReports });
		dispatch(setPowerBIReports(powerBIReports));
	},
	50,
	{ trailing: true }
);

export function DrawerContainer({
	filteredRoutes,
	userId,
	accessToken,
	powerBIReports,
	handleLogout,
	isAuthenticated,
}) {
	const [expanded, setExpanded] = useState({});
	const [hovered, setHovered] = useState(!isMobile());
	const [hoveredListItem, setHoveredListItem] = useState('');

	const dispatch = useDispatch();
	const location = useLocation();
	const { pathname } = location;
	const splitLocation = pathname.split('/');

	const { showModal } = useSelector(
		(state) => ({
			showModal: state.globalSearch.showModal,
		}),
		shallowEqual
	);

	const isAboveMdBreakpoint = useMediaQuery((theme) =>
		theme.breakpoints.up('md')
	);
	const isAboveSmBreakpoint = useMediaQuery((theme) =>
		theme.breakpoints.up('sm')
	);

	const drawerShown = useSelector((state) => state.appSettings.drawerShown);
	const juicerId = useSelector(
		(state) => state.userData?.user?.user?.person?.id ?? null
	);
	const isHoveredOrDrawerShown = drawerShown || hovered;

	useEffect(() => {
		async function fetchReports() {
			if (!accessToken || !isAuthenticated) return;

			getDebouncedPowerBIReports(userId, dispatch);
		}

		fetchReports();
	}, [userId, accessToken, isAuthenticated, dispatch]);

	useEffect(() => {
		const hotkeysShortcuts = 'ctrl+k, cmd+k';

		HotkeysService.defineHotkeys({
			keys: hotkeysShortcuts,
			callback: (event, handler) => {
				event.preventDefault();
				switch (handler.key) {
					case 'cmd+k':
					case 'ctrl+k':
						dispatch(showSearchModal(true));
						break;
					default:
						break;
				}
			},
		});

		return () => {
			HotkeysService.unbind(hotkeysShortcuts);
		};
	}, [dispatch]);

	useEffect(() => {
		if (!isHoveredOrDrawerShown) {
			setExpanded([]);
		}
	}, [isHoveredOrDrawerShown]);

	const handleClickAway = () => {
		if (isAboveMdBreakpoint) return;
		dispatch(setDrawerOpen(false));
		setHovered(false);
	};

	const handleToggleCollapsed = (element) => {
		if (!element?.children?.length) {
			if (isAboveMdBreakpoint) return;
			return handleClickAway();
		}

		setExpanded({ ...expanded, [element.title]: !expanded[element.title] });
	};

	const handleSectionClick = () => {
		handleClickAway();
	};

	const getRightIconParentSecondary = (element, hasChildren) => {
		if (!isHoveredOrDrawerShown) {
			return null;
		}

		if (hasChildren && expanded[element.title]) {
			return (
				<Fade
					in={isHoveredOrDrawerShown}
					{...(isHoveredOrDrawerShown ? { timeout: 200 } : {})}
				>
					<KeyboardArrowDownOutlinedIcon />
				</Fade>
			);
		}

		if (hasChildren && hoveredListItem === element.path) {
			return <KeyboardArrowDownOutlinedIcon />;
		}

		if (element.external) {
			return (
				<Fade
					in={isHoveredOrDrawerShown}
					{...(isHoveredOrDrawerShown ? { timeout: 1000 } : {})}
				>
					<OpenInNewOutlinedIcon />
				</Fade>
			);
		}

		return null;
	};

	const renderList = () => {
		return (
			<JoeNavList>
				{filteredRoutes
					.filter((el) => !el.index)
					.map((element) => {
						if (element.index) return null;

						if (powerBIReports?.length && element.path === 'reports') {
							element.children = _uniqBy(
								[...element.children, ...powerBIReports],
								'path'
							);
						}

						const hasChildren = !!element?.children?.length;
						const item = (
							<ListItemIcon>
								{element.icon ? <element.icon /> : <ArticleOutlinedIcon />}
							</ListItemIcon>
						);
						const locationPath = splitLocation[1] || '/';
						// Ludiwg's juicer id
						const showAllowedRoles = juicerId === 2803 || juicerId === 6679;
						const allowedAccessControlLevelsString =
							element.allowedAccessControlLevels?.join(', ');

						// get first word in a title - to be used when running e2e test
						const titleFirstWord = element.title?.split?.(' ')[0] || '';

						return (
							<Fragment key={element.path}>
								<ListItem
									sx={{ fontSize: 12 }}
									button
									onMouseEnter={() => setHoveredListItem(element.path)}
									onMouseLeave={() => setHoveredListItem('')}
									onClick={() =>
										element.external
											? window.open(element.path, '_blank')
											: handleToggleCollapsed(element)
									}
									secondaryAction={getRightIconParentSecondary(
										element,
										hasChildren
									)}
									component={Link}
									selected={locationPath === element.path}
									to={element.external ? '' : element.path}
									// we are rendering the new property "data-cy-link" so we can test all links
									data-cy-link={element.external ? element.path : ''}
									data-cy={`drawer-list-item-${titleFirstWord}`}
								>
									{hovered ? (
										item
									) : (
										<Tooltip
											title={element.title}
											enterDelay={0}
											placement="right"
										>
											{item}
										</Tooltip>
									)}
									<Tooltip
										title={allowedAccessControlLevelsString}
										open={showAllowedRoles ? undefined : false}
									>
										<ListItemText
											primaryTypographyProps={{ noWrap: true }}
											primary={element.title}
										/>
									</Tooltip>
								</ListItem>

								{/* Children nested list */}
								{hasChildren ? (
									<Collapse
										in={expanded[element.title]}
										timeout="auto"
										unmountOnExit
									>
										<JoeNavNested disablePadding>
											{element.children
												.filter((el) => !el.index)
												.map((child) => {
													const allowedAccessControlLevelsString =
														child.allowedAccessControlLevels?.join(', ');

													return (
														<Fragment key={`child-${child.title}`}>
															{child?.section && (
																<ListItem
																	data-cy="drawer-children-divider"
																	sx={{
																		backgroundColor:
																			process.env.wpApiHost.includes(
																				'api2test.joejuice.com'
																			)
																				? 'rgba(183, 144, 163, 0.65)'
																				: 'rgba(79, 82, 85, 0.5)',
																	}}
																>
																	<Typography
																		sx={{
																			...ListItemTextStyling,
																			fontSize: 12,
																		}}
																	>
																		{child.section}
																	</Typography>
																</ListItem>
															)}
															<ListItem
																sx={{ pl: 4, fontSize: 12 }}
																button
																onMouseEnter={() =>
																	setHoveredListItem(child.path)
																}
																onMouseLeave={() => setHoveredListItem('')}
																onClick={() =>
																	child.external
																		? window.open(child.path, '_blank')
																		: handleSectionClick()
																}
																// secondaryAction={
																// 	hoveredListItem === child.path && child.external ? <OpenInNewOutlinedIcon /> : null
																// }
																secondaryAction={getRightIconParentSecondary(
																	child,
																	false
																)}
																selected={splitLocation[2] === child.path}
																component={child.external ? 'li' : Link}
																to={
																	child.external
																		? ''
																		: element.path + '/' + child.path
																}
															>
																<Tooltip
																	title={allowedAccessControlLevelsString}
																	open={showAllowedRoles ? undefined : false}
																>
																	<ListItemText
																		primaryTypographyProps={{
																			noWrap: true,
																			sx: { color: 'rgb(255 255 255 / 75%)' },
																		}}
																		primary={child.title}
																	/>
																</Tooltip>
															</ListItem>
														</Fragment>
													);
												})}
										</JoeNavNested>
									</Collapse>
								) : null}
							</Fragment>
						);
					})}
			</JoeNavList>
		);
	};

	const toggleNavigationSwitch = () => {
		dispatch(setDrawerOpen(!drawerShown));
	};

	return (
		<>
			{!isAboveMdBreakpoint ? (
				<Backdrop
					sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={isHoveredOrDrawerShown}
					onClick={handleClickAway}
				></Backdrop>
			) : null}
			<Drawer
				sx={{
					position: 'relative',
					zIndex: (theme) => theme.zIndex.drawer + 2,
				}}
				open={isHoveredOrDrawerShown}
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
				variant={'permanent'}
				anchor="left"
			>
				<Box
					sx={{
						marginBottom: '45px',
					}}
				>
					{/* Tenancy */}
					<JoeDivider />
					<Box
						sx={{
							display: 'flex',
						}}
					>
						<Box
							sx={{
								width: {
									xs: '100%',
									sm: isHoveredOrDrawerShown ? 'calc(100% - 48px)' : '100%',
								},
							}}
						>
							<TenantSelect
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
								}}
								toggled={isHoveredOrDrawerShown}
							/>
						</Box>
						{isAboveSmBreakpoint ? (
							<Tooltip
								title={'Lock navigation to left side'}
								enterDelay={0}
								placement="right"
							>
								<IconButton
									sx={(theme) => ({
										color: drawerShown
											? 'rgb(255 255 255 / 100%)'
											: 'rgb(255 255 255 / 40%)',
										width: '48px',
										marginRight: theme.spacing(1),
										transition: theme.transitions.create('color'),
										backgroundColor: 'transparent',
										'&:hover': {
											color: 'rgb(255 255 255 / 100%)',
										},
										transform: 'rotate(180deg)',
									})}
									aria-label="toggle navigation drawer"
									onClick={toggleNavigationSwitch}
								>
									<ViewSidebarOutlinedIcon
										sx={{
											width: '20px',
										}}
									/>
								</IconButton>
							</Tooltip>
						) : null}
					</Box>
					<JoeDivider />

					{/* Search */}
					<GlobalSearchInput toggled={isHoveredOrDrawerShown} />

					<JoeDivider />

					{/* Main nav */}
					<nav aria-label="main">{renderList()}</nav>
					<JoeDivider />
				</Box>

				<JoeNavList
					sx={{
						position: 'fixed',
						bottom: 0,
						left: 0,
						width: 'inherit',
						overflow: 'hidden',
						paddingBottom: '8px !important',
					}}
				>
					<ListItem sx={{ fontSize: 12 }} button onClick={handleLogout}>
						{hovered ? (
							<ListItemIcon>
								<LogoutOutlinedIcon />
							</ListItemIcon>
						) : (
							<Tooltip title={'Logout'} enterDelay={0} placement="right">
								<ListItemIcon>
									<LogoutOutlinedIcon />
								</ListItemIcon>
							</Tooltip>
						)}
						<ListItemText
							primaryTypographyProps={{ noWrap: true }}
							primary={'Log out'}
						/>
					</ListItem>
				</JoeNavList>

				{showModal ? (
					<GlobalSearchModal
						setDrawerHower={() => setHovered(false)}
						showModal={showModal}
					/>
				) : null}
			</Drawer>

			{!isAboveMdBreakpoint ? (
				<Fab
					sx={{
						position: 'fixed',
						bottom: (theme) => theme.spacing(1),
						left: (theme) => theme.spacing(1),
						zIndex: (theme) => theme.zIndex.drawer,
						boxShadow: 'none',
					}}
					color="secondary"
					aria-label="navigation"
					onClick={() => setHovered(true)}
				>
					<MenuIcon />
				</Fab>
			) : null}
		</>
	);
}

DrawerContainer.propTypes = {
	filteredRoutes: PropTypes.array,
	userId: PropTypes.string,
	accessToken: PropTypes.string,
	powerBIReports: PropTypes.array,
	isAuthenticated: PropTypes.bool,
	handleLogout: PropTypes.func,
};
