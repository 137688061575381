'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, InputCollectionSelect } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// phrases
import phrases from './../../../salesConfigurationPosConfigurationDiscounts.phrases';

// lodash
import _get from 'lodash/get';

class PosConfigurationDiscountsModalBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e?.target?.value ?? e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			[type]: value,
		});
	}

	render() {
		const { batchList, loyaltyProductUrl } = this.props;

		return (
			<TableList>
				<thead>
					<tr>
						<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
						<th>{phrases.MODAL_BATCH_STEP_POS_CONFIGURATION_VERSION}</th>
						<th>{phrases.MODAL_BATCH_STEP_DISCOUNT}</th>
						<th>{phrases.MODAL_BATCH_STEP_LOYALTY_PRODUCT}</th>
						<th>{phrases.MODAL_BATCH_STEP_NAME}</th>
						<th>{phrases.MODAL_BATCH_STEP_DESCRIPTION}</th>
						<th />
					</tr>
				</thead>
				<tbody>
					{batchList.map((entry, i) => {
						return (
							<TableListRow key={entry.identifier}>
								<TableListCell>{i + 1}</TableListCell>
								<TableListCell>
									{_get(entry, 'posConfigurationVersion.label', '')}
								</TableListCell>
								<TableListCell>
									{_get(entry, 'discount.label', '')}
								</TableListCell>
								<TableListCell>
									<InputCollectionSelect
										id="loyaltyProduct"
										className="modal-batch-select"
										placeholder={
											phrases.MODAL_BATCH_STEP_LOYALTY_PRODUCT_PLACEHOLDER
										}
										value={entry.loyaltyProduct}
										handleChange={(key, value) =>
											this._editListEntry(value, entry, 'loyaltyProduct')
										}
										cache
										apiPath={loyaltyProductUrl}
										params={{
											limit: 30,
											filter: `:pos_configuration_version.id=='${entry.posConfigurationVersion.value.id}'`,
										}}
										optionFormat={(entry) => ({
											value: entry,
											label: _get(entry, 'product_variant.name', ''),
										})}
										inputFilterFormat={(input) =>
											`:product_variant.name=like='%${input}%'`
										}
									/>
								</TableListCell>
								<TableListCell>
									<Input
										id={`${entry.identifier}-name}`}
										placeholder={phrases.MODAL_BATCH_STEP_NAME_PLACEHOLDER}
										value={entry.name || ''}
										onChange={(event) =>
											this._editListEntry(event, entry, 'name')
										}
									/>
								</TableListCell>

								<TableListCell>
									<Input
										id={`${entry.identifier}-description`}
										placeholder={
											phrases.MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER
										}
										value={entry.description || ''}
										onChange={(event) =>
											this._editListEntry(event, entry, 'description')
										}
									/>
								</TableListCell>

								<TableListCell>
									<Button
										id={`${entry.identifier}-delete`}
										type="negative"
										size="tiny"
										onClick={() => this._onRemoveEntry(entry.identifier)}>
										<span className="icon icon--delete" />
									</Button>
								</TableListCell>
							</TableListRow>
						);
					})}
				</tbody>
			</TableList>
		);
	}
}

PosConfigurationDiscountsModalBatchStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	loyaltyProductUrl: PropTypes.string,
};

PosConfigurationDiscountsModalBatchStepList.defaultProps = {};

export default PosConfigurationDiscountsModalBatchStepList;
