export default {
	TABLE_TITLE: 'Pos tiers',
	POS_TIERS_ADDED: 'Pos tiers added',
	POS_TIER_EDITED: 'Pos tier edited',
	POS_TIERS_EDITED: 'Pos tiers edited',
	POS_TIERS_DELETED: 'Pos tiers deleted',

	// modal batch
	MODAL_BATCH_STEP_LIST_TITLE: 'Add pos tiers',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_SORT_ORDER: 'Sort order',
	MODAL_BATCH_STEP_EXTERNAL_ID: 'External id',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Select name...',
	MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_SORT_ORDER_PLACEHOLDER: 'Enter sort order...',
	MODAL_BATCH_STEP_EXTERNAL_ID_PLACEHOLDER: 'Enter external id...',
	MODAL_BATCH_STEP_LIST_ADD_NEW_ROW_TOOLTIP: 'Add new row',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Submit',
};
