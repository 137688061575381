import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';

import PosConfigurationLoyaltyTypesList from './services/posConfigurationsLoyaltyTypesList.service.js';

const posConfigurationLoyaltyTypesListReduxKey = '/campaigning/loyalty_types';
const posConfigurationLoyaltyTypesList = new PosConfigurationLoyaltyTypesList(posConfigurationLoyaltyTypesListReduxKey);
const posConfigurationLoyaltyTypesListProps = posConfigurationLoyaltyTypesList.getProps();

export function PosConfigurationLoyaltyTypes() {
	useEffect(() => {
		posConfigurationLoyaltyTypesList.initView();
	}, []);

	return (
		<ListComponent reduxKey={posConfigurationLoyaltyTypesListReduxKey} {...posConfigurationLoyaltyTypesListProps} />
	);
}
