export const UPDATE_BATCH_FORM =
	'GLOBAL_ADMINISTRATION_WORKPLACE_WAITING_TIME_GRIDS/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'GLOBAL_ADMINISTRATION_WORKPLACE_WAITING_TIME_GRIDS/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_WORKPLACE_WAITING_TIME_GRIDS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_WORKPLACE_WAITING_TIME_GRIDS/REMOVE_BATCH_LIST_ITEM';

export const SET_WORKPLACE_WAITING_TIME_GRID =
	'GLOBAL_ADMINISTRATION_WORKPLACE_WAITING_TIME_GRIDS/SET_WORKPLACE_WAITING_TIME_GRID';
export const UPDATE_WORKPLACE_WAITING_TIME_GRID =
	'GLOBAL_ADMINISTRATION_WORKPLACE_WAITING_TIME_GRIDS/UPDATE_WORKPLACE_WAITING_TIME_GRID';
export const RESET_STATE =
	'GLOBAL_ADMINISTRATION_WORKPLACE_WAITING_TIME_GRIDS/RESET_STATE';

export function setWorkplaceWaitingTimeGrid(payload) {
	return {
		type: SET_WORKPLACE_WAITING_TIME_GRID,
		payload,
	};
}

export function updateWorkplaceWaitingTimeGrid(payload) {
	return {
		type: UPDATE_WORKPLACE_WAITING_TIME_GRID,
		payload,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}
