'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';

// components
import { Input, Button, Toggle } from 'dumb';
import IngredientConfigurationIngredients from './components/ingredientConfigurationIngredients/ingredientConfigurationIngredients.component';

// actions/services
import {
	updateIngredientConfiguration,
	resetIngredientConfiguration,
	setIngredientConfiguration,
} from './store/ingredientConfigurations.actions';
import {
	fetchIngredientsConfigurations,
	addIngredientConfiguration,
	editIngredientConfiguration,
	deleteIngredientConfiguration,
} from './ingredientConfigurations.service';

// lodash
import _get from 'lodash/get';

// phrases/constants
import phrases from './ingredientConfigurations.phrases';
import constants from 'services/constants';

class IngredientsConfigurations extends Component {
	constructor(props) {
		super(props);

		this.state = {
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this.fetchData = this.fetchData.bind(this);
		this.createEntry = this.createEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);

		this.reduxKey = 'pos/ingredient_configurations';

		this.columns = [
			{
				Header: 'Ingredient configuration name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Created',
				id: 'created_at',
				accessor: (d) => _get(d, 'created', ''),
				show: false,
				Cell: (d) => {
					return (
						<span>
							{moment.utc(d.original.created).format(constants.shortDate)}
						</span>
					);
				},
				filterPath: ':created',
			},
			{
				Header: 'Deleted',
				id: 'deleted_at',
				accessor: (d) => _get(d, 'deleted', ''),
				show: false,
				Cell: (d) => {
					return (
						<span>
							{moment.utc(d.original.deleted).format(constants.shortDate)}
						</span>
					);
				},
				filterPath: ':deleted',
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: 'legacy',
				filterPath: ':legacy',
				filterable: false,
				Cell: (d) => {
					return <Input type="checkbox" checked={d.original.legacy} disabled />;
				},
			},
		];
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}>
				Legacy
			</Button>
		);
	}

	createEntry() {
		const { ingredientConfiguration } = this.props;

		return addIngredientConfiguration(ingredientConfiguration);
	}

	editEntry() {
		const { ingredientConfiguration } = this.props;

		const payload = {
			name: ingredientConfiguration.name,
			legacy: !!ingredientConfiguration.legacy,
		};

		return editIngredientConfiguration(ingredientConfiguration.id, payload);
	}

	deleteEntry(id) {
		return deleteIngredientConfiguration(id);
	}

	setInitialEditValues(data) {
		const { setIngredientConfiguration } = this.props;

		setIngredientConfiguration(data);
	}

	getEditableCells() {
		const { ingredientConfiguration } = this.props;

		return [
			{
				header: phrases.INGREDIENT_CONFIGURATION_MODAL_HEADER_NAME,
				value: (
					<Input
						id="ingredientConfigurationName"
						placeholder="Ingredient configuration name"
						value={_get(ingredientConfiguration, 'name', '')}
						onChange={(event) =>
							this.editStoreEntry(event.target.value, 'name')
						}
					/>
				),
			},
			{
				header: phrases.INGREDIENT_CONFIGURATION_MODAL_HEADER_LEGACY,
				value: (
					<Toggle
						id="legacy"
						toggled={ingredientConfiguration.legacy}
						onClick={(event) => this.editStoreEntry(event, 'legacy')}
					/>
				),
			},
		];
	}

	editStoreEntry(value, name) {
		const { updateIngredientConfiguration } = this.props;

		const updateObject = {
			[name]: value,
		};

		updateIngredientConfiguration(updateObject);
	}

	fetchData(state) {
		return fetchIngredientsConfigurations(state);
	}

	render() {
		const {
			resetIngredientConfiguration, // batch props
			onInitialization,
			reduxKey: batchReduxKey,
			style,
			defaultPageSize,
			batchSelection,
			enableSingleSelection,
		} = this.props;

		const legacyFilter = this.state.legacyFilterOn
			? this.state.legacyFilter
			: '';

		return (
			<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.INGREDIENT_CONFIGURATIONS_TABLE_TITLE}
				columns={this.columns}
				defaultPageSize={batchSelection ? defaultPageSize : 10}
				reduxKey={batchSelection ? batchReduxKey : this.reduxKey}
				style={batchSelection ? style : {}}
				{...(batchSelection && { onInitialization })}
				batchSelection={batchSelection}
				enableSingleSelection={enableSingleSelection}
				fetchData={this.fetchData}
				filterable
				manual
				createEntry={this.createEntry}
				editEntry={(e) => this.editEntry(e)}
				editableCells={this.getEditableCells()}
				setInitialEditValues={this.setInitialEditValues}
				actionsWidth={60}
				deleteEntry={this.deleteEntry}
				onModalClose={resetIngredientConfiguration}
				subcomponent={(row) => {
					return (
						<SubTable>
							<IngredientConfigurationIngredients
								ingredientConfigurationId={row.original.id}
							/>
						</SubTable>
					);
				}}
				customAreaComponents={this.getLegacyFilterButton()}
				extraFilters={legacyFilter}
			/>
		);
	}
}

IngredientsConfigurations.propTypes = {
	ingredientConfiguration: PropTypes.object,
	setIngredientConfiguration: PropTypes.func,
	resetIngredientConfiguration: PropTypes.func,
	updateIngredientConfiguration: PropTypes.func,
	onInitialization: PropTypes.func,
	reduxKey: PropTypes.string,
	style: PropTypes.object,
	defaultPageSize: PropTypes.number,
	batchSelection: PropTypes.bool,
	enableSingleSelection: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateIngredientConfiguration,
			resetIngredientConfiguration,
			setIngredientConfiguration,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		ingredientConfiguration:
			store.ingredientConfigurations.data.ingredientConfiguration,
	};
};

export default connectWithStore(
	IngredientsConfigurations,
	mapStateToProps,
	mapDispatchToProps
);
