/**
 * @function getColorCoding
 * @param {Objct} { maximumHours, minimumHours }
 * @description returns a color name and a color variable name
 */
export default function getColorCoding({ maximumHours, minimumHours }) {
	if (maximumHours <= 10) return { color: 'red', variableName: '--danger' };
	if (minimumHours >= 300 && maximumHours <= 900)
		return {
			color: 'yellow',
			variableName: '--brand--yellow',
		};
	if (minimumHours >= 962)
		return {
			color: 'green',
			variableName: '--success',
		};
}
