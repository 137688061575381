export const RESET_JUICER_OPERATIONAL_RESPONSIBILITY =
	'ORGANISATION_WORKPLACES_OPERATIONAL_RESPONSIBILITIES/RESET_JUICER_OPERATIONAL_RESPONSIBILITY';
export const UPDATE_JUICER_OPERATIONAL_RESPONSIBILITY =
	'ORGANISATION_WORKPLACES_OPERATIONAL_RESPONSIBILITIES/UPDATE_JUICER_OPERATIONAL_RESPONSIBILITY';

/**
 * @param {object} payload
 */
export function updateJuicerOperationalResponsibilities(payload) {
	return {
		type: UPDATE_JUICER_OPERATIONAL_RESPONSIBILITY,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetJuicerOperationalResponsibilities() {
	return {
		type: RESET_JUICER_OPERATIONAL_RESPONSIBILITY,
	};
}
