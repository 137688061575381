'use strict';

const headers = {
	GLOBAL_JOB_TITLES: {
		ACTIVE: 'Active',
		BADGE: 'Badge',
		DESCRIPTION: 'Description',
		PRODUCTIVITY: 'Productivity',
		SORT_ORDER: 'Sort order',
		FROM: 'From',
		MONEYBALL_POSITION: 'Moneyball position',
		MARKET: 'Market',
		NAME: 'Name',
		TO: 'To',
	},
	GLOBAL_JOB_TITLE_LEVELS: {
		ACTIVE: 'Active',
		BADGE: 'Badge',
		DESCRIPTION: 'Description',
		PRODUCTIVITY: 'Productivity',
		SORT_ORDER: 'Sort order',
		FROM: 'From',
		MONEYBALL_POSITION: 'Moneyball position',
		MARKET: 'Market',
		NAME: 'Name',
		TO: 'To',
	},
	SALARY_COMPONENT_DAILY_OVERTIME_RULES: {
		MINIMUM_HOURS: 'Minimum hours',
		MAXIMUM_HOURS: 'Maximum hours',
	},
	SALARY_COMPONENT_WEEKLY_OVERTIME_RULES: {
		WEEKLY_HOURS: 'Hours pr week',
		DAILY_HOURS: 'Hours pr day',
	},
	SALARY_COMPONENT_DAYS_PER_WEEK_RULES: {
		MAXIMUM: 'Maximum workings days',
	},
	SALARY_COMPONENT_DAILY_SUPPLEMENT_RULES: {
		MINIMUM_HOURS: 'Minimum shift length',
		MAXIMUM_HOURS: 'Maximum shift length',
		BREAK_HOURS: 'Break hours',
	},
	SALARY_COMPONENT_SPLIT_SHIFT_RULES: {
		LOWER_LIMIT_HOURS: 'Lower limit hours',
	},

	SALARY_GROUPS: {
		ACTIVE: 'Active',
		MONEYBALL_SUB_POSITION: 'Moneyball sub position',
		DESCRIPTION: 'Description',
		FROM: 'From',
		MARKET: 'Market',
		NAME: 'Name',
		TO: 'To',
		TEMPLATE_CONTRACT: 'Template Contract',
		TEMPLATE_APPENDIX: 'Template Appendix',
	},
	EMPLOYEE_CONTRIBUTIONS: {
		CONTRIBUTION: 'Contribution (%)',
		FROM: 'From',
		TO: 'To',
	},
	SALARY_COMPONENTS_RULES: {
		WORKPLACE: 'Workplace',
		WEEKDAY: 'Weekday',
		MARKET: 'Market',
		AMOUNT_TYPE: 'Amount type',
		AMOUNT: 'Amount',
		FREQUENCY: 'Frequency',
		TYPE: 'Type',
		FROM: 'From',
		NAME: 'Name',
		TO: 'To',
		TAG: 'Tag',
	},
	SALARY_COMPONENTS: {
		SALARY_GROUP_NAME: 'Salary group',
		GLOBAL_SALARY_COMPONENT_NAME: 'Global salary component',
		MARKET: 'Market',
		AMOUNT_TYPE: 'Amount type',
		AMOUNT: 'Amount',
		FREQUENCY: 'Frequency',
		TYPE: 'Type',
		FROM: 'From',
		NAME: 'Name',
		TO: 'To',
		EXTERNAL_EXPORT_ID: 'External Export ID',
	},
	REPORTING_SALARY: {
		AMOUNT: 'Amount',
		SALARY_GROUP: 'Salary group',
		FROM: 'From',
		MARKET: 'Market',
		NAME: 'Name',
		TO: 'To',
	},
	GLOBAL_SALARY_COMPONENTS: {
		TYPE: 'Type',
		DESCRIPTION: 'Description',
		FROM: 'From',
		NAME: 'Name',
		TO: 'To',
	},
	COMPANY_PAYROLL_PERIODS: {
		FREQUENCY: 'Frequency',
		PERIOD_START: 'Period start (1 ... 31)',
	},
};

const titles = {
	GLOBAL_JOB_TITLES: 'Global moneyball',
	GLOBAL_JOB_TITLE_LEVELS: 'Global moneyball levels',
	SALARY_GROUPS: 'Salary groups',
	EMPLOYEE_CONTRIBUTIONS: 'Employer contributions',
	REPORTING_SALARY: 'Reporting salary',
	GLOBAL_SALARY_COMPONENTS: 'Global salary components',
	SALARY_COMPONENTS: 'Salary components',
	SALARY_COMPONENTS_RULES: 'Salary component rules',
	COMPANY_PAYROLL_PERIODS: 'Payroll periods',
	SALARY_COMPONENT_DAILY_OVERTIME_RULES: 'Daily overtime',
	SALARY_COMPONENT_WEEKLY_OVERTIME_RULES: 'Weekly overtime',
	SALARY_COMPONENT_DAYS_PER_WEEK_RULES: 'Max working days per week',
	SALARY_COMPONENT_DAILY_SUPPLEMENT_RULES:
		'Daily supplement for missing breaks',
	SALARY_COMPONENT_SPLIT_SHIFT_RULES: 'Minimum hours between shifts',
};

module.exports = {
	headers: headers,
	titles: titles,
};
