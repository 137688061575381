export const RESET_WAITING_TIME_SCHEDULE_ENTRY =
	'SALES_CONFIGURATION_WAITING_TIME_SCHEDULE_ENTRIES/RESET_WAITING_TIME_SCHEDULE_ENTRY';
export const SET_WAITING_TIME_SCHEDULE_ENTRY =
	'SALES_CONFIGURATION_WAITING_TIME_SCHEDULE_ENTRIES/SET_WAITING_TIME_SCHEDULE_ENTRY';
export const UPDATE_WAITING_TIME_SCHEDULE_ENTRY =
	'SALES_CONFIGURATION_WAITING_TIME_SCHEDULE_ENTRIES/UPDATE_WAITING_TIME_SCHEDULE_ENTRY';

export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_WAITING_TIME_SCHEDULE_ENTRIES/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_WAITING_TIME_SCHEDULE_ENTRIES/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'SALES_CONFIGURATION_WAITING_TIME_SCHEDULE_ENTRIES/ADD_BATCH_LIST_ITEM_ROW';

/**
 * @param {object} payload
 */
export function setWaitingTimeScheduleEntry(payload) {
	return {
		type: SET_WAITING_TIME_SCHEDULE_ENTRY,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function updateWaitingTimeScheduleEntry(payload) {
	return {
		type: UPDATE_WAITING_TIME_SCHEDULE_ENTRY,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetWaitingTimeScheduleEntry() {
	return {
		type: RESET_WAITING_TIME_SCHEDULE_ENTRY,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}
