'use strict';

import ReportDataProviderAbstract from './report.dataProvider.abstract';

import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service';

import { saveAs } from 'file-saver';
import { get } from 'api.vanilla.service';

class ReportDownloadCsvDataProvider extends ReportDataProviderAbstract {
	constructor(name = 'csv', endpoint = null, fileName = 'filename') {
		super();

		// Set endpoint
		this.setEndpoint(endpoint);

		// Name
		this.setName(name);

		// fileName
		this.setFileName(fileName);
	}

	/**
	 * @function setEndpoint
	 * @memberOf ReportDownloadCsvDataProvider
	 * @description Set endpoint
	 * @param endpoint : {string}
	 */
	setEndpoint(endpoint) {
		this.endpoint = endpoint;
	}

	/**
	 * @function setName
	 * @memberOf ReportDownloadCsvDataProvider
	 * @description Set endpoint
	 * @param name: {string}
	 */
	setName(name) {
		this.name = name;
	}

	/**
	 * @function setFileName
	 * @memberOf ReportDownloadCsvDataProvider
	 * @description Set endpoint
	 * @param fileName: {string}
	 */
	setFileName(fileName) {
		this.fileName = fileName;
	}

	/**
	 * @function downloadCsv
	 * @memberOf ReportDownloadCsvDataProvider
	 * @description DownCsv
	 * @param {object} filter
	 * @param {object} parameters
	 */
	downloadCsv(filter, parameters) {
		return new Promise((resolve, reject) => {
			const params = {
				filter: filter,
				...parameters
			};

			const csvHeader = {
				Accept: 'application/csv'
			};

			return get(this.endpoint.index.path, params, null, csvHeader)
				.then(csv => {
					const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
					const fileName = `${this.fileName}-${moment(parameters.from).format(
						'YYYYMMDD'
					)}-${moment(parameters.to).format('YYYYMMDD')}.csv`;

					saveAs(blob, fileName);
					resolve();
				})
				.catch(err => {
					const errorMsg = formatErrorMessage(err);
					setFeedback(errorMsg, 0);

					reject(err);
				});
		});
	}
}

export default ReportDownloadCsvDataProvider;
