'use strict';
import { formatErrorMessage } from 'api/helpers';
import { remove } from 'api.vanilla.service';
import { set as setFeedback } from 'feedback.vanilla.service';

/**
 * @function itemDelete
 * @memberOf SERVICES.__models
 * @description
 * Delete single item in collection by ID
 * @param  {number} id  id of item to delete
 */

module.exports = function (id) {
	return new Promise((resolve, reject) => {
		var state = this.getState();

		// Delete single item
		remove(this.getSettings().endpoint.delete.path + id).then(
			function () {
				// Run callbacks
				this.runModelCallbacks();

				// Reset API errors
				this.initApiErrorHeaders(state.headers);

				// Resolve
				resolve(id);
			}.bind(this),
			function (response) {
				// Set API errors
				this.initApiErrorHeaders(state.headers, response.data);

				const errorMsg = formatErrorMessage(response.data);

				// Give feedback
				setFeedback(errorMsg, 0);

				// Reject
				reject(response);
			}.bind(this)
		);
	});
};
