'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';

const  __endpoints = require('endpoints.service');

export default class CompanyAddressesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'country',
				type: 'select',
				canAdd: true,
				canEdit: true,
				required: true,
				reference: {
					key: 'name',
					list: '/administration/countries',
				},
			},
			{
				name: 'address',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
		];
		this.settings = {
			name: 'CompanyCompanyAddressesList',
			canAdd: true,
			canEdit: true,
			canManipulate: () => validAccess(undefined, ['Organisation/Companies::Edit', 'Organization Admin']),

			canDelete: true,
			canCollapse: true,
			alwaysRefresh: true,
			translationPath: 'ADMINISTRATION.COMPANY_ADDRESSES',
			endpoint: __endpoints.collection.administration.company_addresses,
		};
		this.init();
		return this;
	}
}
