import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Store
import { store } from 'appState';
import {
	editProductTile,
	editGroup,
} from './../addTileModal/store/addTileModal.actions';
import * as posConfigurationLayout from '../posConfigurationLayout.actions';

// Components
import Group from './components/group/group.component';
import PosMenu from './components/posMenu/posMenu.component';
import AddTileModal from './../addTileModal/addTileModal';
import { ButtonLoader } from 'dumb';

// Tools
import _filter from 'lodash/filter';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

// Other
import constants from './../utilities/constants';
import './posLayout.css';

class PosLayoutComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			grid: props.grid,
			showAddTileModal: false,
			showExistingModal: false,
			activeTileCoordinates: {},
			fetchingTile: false,
			activeTile: {},
		};

		this.setPage = this.setPage.bind(this);
		this.toggleAddTileModal = this.toggleAddTileModal.bind(this);
	}

	toggleAddTileModal(
		tile = {},
		activeTilePosition = null,
		activeTileCoordinates,
		activeTileType = ''
	) {
		this.setState(() => ({
			activeTilePosition,
			activeTileCoordinates,
			activeTile: tile,
			activeTileType,
		}));

		// Start loaders
		this.setState(() => ({ fetchingTile: true }));

		// Open Modal
		this.setState((prevState) => ({
			showAddTileModal: !prevState.showAddTileModal,
		}));

		// Depending on which type of tile, variant or new product we interact
		switch (activeTileType) {
			case constants.TILE_TYPE.VARIANT:
				// Product Variant passed from the list to an empty tile
				// in this case its varaint passed not tile

				// Set Tile object
				store.dispatch(
					editProductTile({
						description: '',
						id: null,
						name: _get(tile, 'label', 'unnamed!'),
						tile_layout_group: null,
						product: { name: tile.label, id: tile.value },
						position: activeTilePosition,
						tile_picture_asset: null,
						type: 'Product',
					})
				);
				break;

			case constants.TILE_TYPE.PRODUCT:
				// Tile that is PRODUCT selected
				store.dispatch(posConfigurationLayout.setActiveTile(tile));
				store.dispatch(editProductTile(tile));
				break;

			case constants.TILE_TYPE.GROUP:
				// Tile that is GROUP selected
				store.dispatch(posConfigurationLayout.setActiveTile(tile));
				store.dispatch(editGroup(tile));
				break;

			default:
			// Nothing happens by default
		}

		// By default stop loaders
		this.setState(() => ({ fetchingTile: false }));
	}

	_getFormattedPosition(activeTileCoordinates) {
		return !_isEmpty(activeTileCoordinates)
			? `Position (${activeTileCoordinates.page}, ${activeTileCoordinates.x}, ${activeTileCoordinates.y})`
			: '';
	}

	/**
	 * Set visible page in group
	 * @param value
	 */
	setPage(value = 0) {
		value = Number(value);
		const { page, setPage } = this.props;
		page < 1 ? (value = 1) : (value = page + value);
		setPage(value);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (!_isEqual(this.state.activeTile, nextProps.activeTile)) {
			this.setState(() => ({
				activeTile: nextProps.activeTile,
			}));
		}
	}

	render() {
		const {
			addLayoutGroup,
			deleteLayoutGroup,
			removeTile,
			fetching,
			grid,
			gridDimensions,
			gridMetaData,
			gridSize,
			groupItemInFocus,
			groups,
			imageAssets,
			page,
			posConfigurationsVariantsList,
			rotateTiles,
			setMenuItemInFocus,
			setPage,
			tilesInGroup,
			updateLayoutGroup,
			updateTilePosition,
			addTile,
			updateTile,
			ui,
			type,
		} = this.props;

		const menuGroups = _filter(groups, { show_in_menu: true });

		const currentGroup = groups[groupItemInFocus];

		let currentGroupName;
		if (currentGroup) {
			currentGroupName = currentGroup.referenced_by_tile
				? `${currentGroup.referenced_by_tile.tile_layout_group.name} -> ${currentGroup.referenced_by_tile.name}`
				: currentGroup.name;
		}

		return (
			<div className="pos-container">
				<div className="pos-container__header">
					<div className="pos-container__header__metadata">
						<h1>{gridMetaData.name}</h1>
						<p>
							{gridMetaData.description}
							<ButtonLoader loading={fetching} theme="dark" />
						</p>
					</div>
					<div className="pos-container__header__paginator">
						<button
							disabled={page === 1}
							className="button button--primary"
							onClick={() => this.setPage('-1')}>
							Previous page page
						</button>
						<button
							className="button button--primary"
							onClick={() => this.setPage('1')}>
							Next page
						</button>
					</div>
				</div>
				{menuGroups.length === 0 ? (
					<h1 className="pos-container__no-content">
						Click Add Group to get started
					</h1>
				) : null}

				{menuGroups.length > 0 && (
					<>
						<span>Active Group: {currentGroupName}</span>
						<Group
							grid={grid}
							gridDimensions={gridDimensions}
							gridSize={gridSize}
							imageAssets={imageAssets}
							openTileModal={this.toggleAddTileModal}
							page={page}
							rotateTiles={rotateTiles}
							setMenuItemInFocus={setMenuItemInFocus}
							setPage={setPage}
							tiles={tilesInGroup}
							removeTile={removeTile}
							updateTilePosition={updateTilePosition}
							fetching={fetching}
							groups={groups}
							ui={ui}
						/>
					</>
				)}
				<PosMenu
					addLayoutGroup={addLayoutGroup}
					deleteLayoutGroup={deleteLayoutGroup}
					itemInFocus={groupItemInFocus}
					items={menuGroups}
					setMenuItemInFocus={setMenuItemInFocus}
					updateLayoutGroup={updateLayoutGroup}
				/>

				<AddTileModal
					isOpen={this.state.showAddTileModal}
					handleClose={this.toggleAddTileModal}
					layoutId={this.props.layoutId}
					imageAssets={imageAssets}
					addTile={addTile}
					updateTile={updateTile}
					layoutGroupId={groupItemInFocus}
					position={this.state.activeTilePosition}
					activeTileCoordinates={this.state.activeTileCoordinates}
					posConfigurationsVariantsList={posConfigurationsVariantsList}
					loadingTile={this.state.fetchingTile}
					activeTile={this.state.activeTile}
					type={type}
					isTypePos
				/>
			</div>
		);
	}
}

PosLayoutComponent.defaultProps = {
	gridSize: null,
	tiles: [],
	groups: [],
	grid: {},
	activeTile: {},
};

PosLayoutComponent.propTypes = {
	activeTile: PropTypes.object,
	addLayoutGroup: PropTypes.func,
	addTile: PropTypes.func,
	deleteLayoutGroup: PropTypes.func,
	fetching: PropTypes.bool,
	grid: PropTypes.object,
	gridDimensions: PropTypes.object,
	gridMetaData: PropTypes.object,
	gridSize: PropTypes.number,
	groupItemInFocus: PropTypes.number,
	groups: PropTypes.object,
	imageAssets: PropTypes.array,
	layoutId: PropTypes.number,
	page: PropTypes.number,
	posConfigurationsVariantsList: PropTypes.array,
	removeTile: PropTypes.func,
	rotateTiles: PropTypes.func,
	setMenuItemInFocus: PropTypes.func,
	setPage: PropTypes.func,
	tilesInGroup: PropTypes.array,
	type: PropTypes.string,
	ui: PropTypes.object,
	updateLayoutGroup: PropTypes.func,
	updateTile: PropTypes.func,
	updateTilePosition: PropTypes.func,
};

export default PosLayoutComponent;
