export const RESET_STATE = 'SHIFTPLANNER_WORKPLACE_TODOS/RESET_STATE';
export const RESET_FORM = 'SHIFTPLANNER_WORKPLACE_TODOS/RESET_FORM';
export const SET_TODOS = 'SHIFTPLANNER_WORKPLACE_TODOS/SET_TODOS';
export const LOADING_TODOS = 'SHIFTPLANNER_WORKPLACE_TODOS/LOADING_TODOS';
export const UPDATE_TODO = 'SHIFTPLANNER_WORKPLACE_TODOS/UPDATE_TODO';
export const REMOVE_TODO = 'SHIFTPLANNER_WORKPLACE_TODOS/REMOVE_TODO';
export const SET_INPUT = 'SHIFTPLANNER_WORKPLACE_TODOS/SET_INPUT';

/**
 * @param {object} payload
 */
export function setInputValue(payload) {
	return {
		type: SET_INPUT,
		payload
	};
}

/**
 * @param {object} clopes
 */
export function setWorkplaceTodo(payload) {
	return {
		type: SET_TODOS,
		payload
	};
}

/**
 * @param {bool} bool
 */
export function setLoadingTodos(payload) {
	return {
		type: LOADING_TODOS,
		payload
	};
}

/**
 * @param {bool} bool
 */
export function updateTodos(payload) {
	return {
		type: UPDATE_TODO,
		payload
	};
}

/**
 * @param {bool} bool
 */
export function removeTodos(payload) {
	return {
		type: REMOVE_TODO,
		payload
	};
}

/**
 * @param {number} id
 */
export function clearState() {
	return {
		type: RESET_STATE
	};
}

/**
 * @param {number} id
 */
export function clearForm() {
	return {
		type: RESET_FORM
	};
}
