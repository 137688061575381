'use strict';

/**
 * @function _getFilter
 * @description Extracts filter from hotbar resultSet
 * @param {object} resultSet
 * @return {object} filter object
 */
const getFilter = resultSet => {
	const results = resultSet.results;

	// Check for correct results from hotbar
	if (results.Companies !== undefined) {
		// Results
		const companiesResults = results.Companies.getResults();

		// Filters
		const companiesFilter =
			companiesResults === null
				? []
				: companiesResults
					.map((result, index, arr) =>
						index === arr.length - 1
							? `:company.id=='${result.value}'`
							: `:company.id=='${result.value}',`
					)
					.join('');

		// Resolve promise with filter
		return companiesFilter;
	}

	// Return error if not present
	else throw new Error('getFilter(): Hotbar result set is missing Companies.');
};

/**
 * @function _getParameters
 * @description Extracts parameters from hotbar resultSet
 * @param {object} resultSet
 * @return {object} parameter objects
 */
const getParameters = resultSet => {
	// Get results
	const results = resultSet.results;

	// Check for correct results from hotbar
	if (results.Period !== undefined) {
		// Get date range from hotbar daterange results
		const dateRange = {
			from: results.Period.getStartDate().format('YYYY-MM-DD'),
			to: results.Period.getEndDate().format('YYYY-MM-DD')
		};

		// Resolve promise with dateRange
		return dateRange;
	}

	// Return error if not present
	else
		throw new Error(
			'getDateParametrers(): Hotbar result set is missing Period.'
		);
};

export { getFilter, getParameters };
