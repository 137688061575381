'use strict';

/**
 * @function initHeaderEnumOptions
 * @memberOf SERVICES.__models
 * @description
 * Adds options to selects where options are enums
 * @param  {array} headers  headers to add enum options to
 */

import _each from 'lodash/each';
import _filter from 'lodash/filter';
import _has from 'lodash/has';

module.exports = function(headers) {
	_each(_filter(headers, 'options'), function(header) {
		if (_has(header.options, 'enums')) {
			header.options.options = header.options.enums;
		}
	});
	this.setHeaders(headers);
};