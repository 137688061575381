// lodash
import _get from 'lodash/get';

/**
 * @function getFilter
 * @param {Object} object - contains posConfigurationsVersions and productVariant
 * @description returns a filter to get all existing data to know what to filter out
 */
export function getFilter(data) {
	const posConfigurationIngredientIds = data.reduce(
		(acc, currentValue, index) => {
			const id = _get(
				currentValue,
				'posConfigurationIngredient.value.id',
				null
			);

			const toReturn =
				index === data.length - 1 ? `${acc}'${id}']` : `${acc}'${id}',`;

			return toReturn;
		},
		'['
	);

	const filter = `:pos_configuration_ingredient.id=IN=${posConfigurationIngredientIds}`;
	return filter;
}

/**
 * @function markValuesWithCanAdd
 * @param {Object} object - contains chosen values (valuesToAdd) and existing product variant pricings
 * @description loops through existing values and if some of our chosen combinations already exist
 * it marks it with canAdd: false to avoid adding
 */
export function markValuesWithCanAdd({ valuesToAdd, existingValues }) {
	return valuesToAdd.map((value) => {
		const posConfigurationIngredientId = _get(
			value,
			'posConfigurationIngredient.value.id',
			null
		);

		// try to find a product variant pricing with the same ids
		const existingProductVariantPricing = existingValues.some(
			(entry) =>
				entry.pos_configuration_ingredient.id === posConfigurationIngredientId
		);

		return {
			...value,
			canAdd: !existingProductVariantPricing,
		};
	});
}
