'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonGroup, InputCollectionSelect, Icon } from 'dumb';
import { ModalRow } from 'dumb/modal';

// loooodash
import _isEmpty from 'lodash/isEmpty';

import phrases from './../../../rawIngredientConfigurationRawIngredients.phrases';

class BatchStepForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showOverlay: false,
			overlayType: '',
			loadingModal: false
		};
	}

	editStoreEntry(e, type) {
		const { batchFormData, resetSelection, rawIngredientsUrl } = this.props;

		const value = e.target ? e.target.value : e;

		// if we cleared the select, also clear the selected rows in overlay table
		if (_isEmpty(value)) {
			resetSelection(rawIngredientsUrl);
		}

		this.props.updateBatchForm({
			...batchFormData,
			...{ [type]: value }
		});
	}

	render() {
		const { batchFormData, toggleOverlay, rawIngredientsUrl } = this.props;

		return (
			<>
				<ModalRow>
					<ButtonGroup type="full-width">
						<InputCollectionSelect
							id="rawIngredient"
							className="raw-ingredient-modal-batch-select"
							label={phrases.MODAL_BATCH_STEP_RAW_INGREDIENT}
							placeholder={phrases.MODAL_BATCH_STEP_RAW_INGREDIENT_PLACEHOLDER}
							value={batchFormData.rawIngredients}
							handleChange={(key, value) =>
								this.editStoreEntry(value, 'rawIngredients')
							}
							multi
							cache
							apiPath={rawIngredientsUrl}
							params={{
								limit: 300
							}}
							optionFormat={entry => ({
								value: entry,
								label: entry.name
							})}
							inputFilterFormat={input => `:name=like='%${input}%'`}
						/>

						<Button
							type="inverted"
							shadow
							size="large"
							onClick={() => toggleOverlay(rawIngredientsUrl)}>
							<Icon name="more" />
						</Button>
					</ButtonGroup>
				</ModalRow>
			</>
		);
	}
}

BatchStepForm.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
	resetSelection: PropTypes.func,
	rawIngredientsUrl: PropTypes.string
};

BatchStepForm.defaultProps = {};

export default BatchStepForm;
