'use strict';

const entryTypes = {
	MARKET_ENTRY: 'KeyPerformanceIndicatorReportMarketEntry',
	WORKPLACE_ENTRY: 'KeyPerformanceIndicatorReportWorkplaceEntry',
	HOURLY_ENTRY: 'KeyPerformanceIndicatorReportWorkplaceHourlyEntry',
	ACCOUNT_ENTRY: 'KeyPerformanceIndicatorReportAccountEntry'
};

export function sortKpiReports(reports) {
	// we sort in four categories - market, workplace, hourly and account (totals)
	const accumulator = {
		marketEntry: [],
		workplaceEntry: [],
		hourlyEntry: [],
		accountEntry: []
	};

	const sortedReports = reports.reduce((acc, currentReport) => {
		switch (currentReport.entry_type) {
		case entryTypes.MARKET_ENTRY:
			acc = {
				...acc,
				marketEntry: [
					...acc.marketEntry,
					{
						...currentReport
					}
				]
			};
			break;
		case entryTypes.WORKPLACE_ENTRY:
			acc = {
				...acc,
				workplaceEntry: [
					...acc.workplaceEntry,
					{
						...currentReport
					}
				]
			};
			break;
		case entryTypes.HOURLY_ENTRY:
			acc = {
				...acc,
				hourlyEntry: [
					...acc.hourlyEntry,
					{
						...currentReport
					}
				]
			};
			break;
		case entryTypes.ACCOUNT_ENTRY:
			acc = {
				...acc,
				accountEntry: [
					...acc.accountEntry,
					{
						...currentReport
					}
				]
			};
			break;
		default:
			break;
		}
		return acc;
	}, accumulator);

	return sortedReports;
}
