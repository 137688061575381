export const SET_USER = 'USERS/SET_USER';
export const UPDATE_USER = 'USERS/UPDATE_USER';
export const RESET_USER = 'USERS/RESET_USER';

export function setUser(payload) {
	return {
		type: SET_USER,
		payload,
	};
}

export function updateUser(payload) {
	return {
		type: UPDATE_USER,
		payload,
	};
}

export function resetUser() {
	return {
		type: RESET_USER,
	};
}
