export default {
	TABLE_TITLE: 'Market responsibilities',
	MARKET_RESPONSIBILITY_ADDED: 'Market responsibility added',
	MARKET_RESPONSIBILITY_EDITED: 'Market responsibility edited',
	MARKET_RESPONSIBILITIES_EDITED: 'Market responsibilities edited',
	MARKET_RESPONSIBILITIY_DELETED: 'Market responsibility deleted',
	DETAILS_TOOLTIP: 'Details',
	LEGACY: 'Legacy',
	MARKETS_TITLE: 'Markets',

	// batch
	BATCH_ADD: 'Add',
	MODAL_TITLE: 'Add market responsibilities',
	SUBMIT: 'Submit',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_LIST_ROLE: 'Role',
	MODAL_BATCH_STEP_LIST_FROM: 'From',
	MODAL_BATCH_STEP_LIST_TO: 'To',
	MODAL_BATCH_STEP_LIST_LOCATION: 'Location',
	ADD_ROW_TOOLTIP: 'Add row',
	DELETE_ROW: 'Delete row',
};
