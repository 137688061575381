import constants from 'services/constants';
import _find from 'lodash/find';
import moment from 'moment';

/**
 * Extract Employees assigned to shifts that has no connection to current workplace
 * @param shifts
 * @param currentStartOfPeriod
 * @param endOfPeriod
 * @param existingEmployees
 * @returns {*}
 */
export default function extractExternalEmployees(
	shifts = [],
	currentStartOfPeriod = {},
	endOfPeriod = {},
	existingEmployees = []
) {
	const externalShiftEmployees = [];

	const startOfWeek = currentStartOfPeriod.clone().startOf('isoweek');

	shifts.forEach((shift) => {
		const startOfShift = moment.utc(
			shift.planned_period.from,
			constants.dateFormat
		);
		const isStartTimeInCurrentVisiblePeriod = startOfShift.isBetween(
			startOfWeek,
			endOfPeriod,
			'days',
			'[]'
		);

		// If shift is outside current visible period, return shift
		if (!isStartTimeInCurrentVisiblePeriod) return shift;

		return shift.shift_employees.map((shiftEmployee) => {
			const employeeExists = _find(
				externalShiftEmployees,
				(o) => o.employment.id === shiftEmployee.employment.id
			);

			// Check if employee exists in workplace employees array
			const employeeExistsInExistingEmployements = _find(
				existingEmployees,
				(o) => o.employment.id === shiftEmployee.employment.id
			);

			if (!employeeExists && !employeeExistsInExistingEmployements) {
				externalShiftEmployees.push(shiftEmployee);
			}
		});
	});

	return externalShiftEmployees;
}
