'use strict';

import * as addShiftModal from 'shiftPlanner/components/addShift/addShift.actions';
import * as daySettingsModal from 'shiftPlanner/components/daySettings/daySettings.actions';
import * as phpReport from 'shiftPlanner/components/phpOverview/store/phpReport.actions';
import * as shifts from './shifts.actions';
import * as employees from './shiftPlannerEmployees.actions';
import * as clockings from './shiftPlannerClockings.actions';
import * as weatherForecast from './shiftPlannerWeather.actions';
import * as workDays from 'shiftPlanner/components/dayHeader/services/workDays/workDays.actions';
import * as shiftPlannerForecasting from './../components/weekviewContainer/weekView/forecasting/store/shiftplannerForecasting.actions';
import * as shadowEmployee from './shiftPlannerShadowEmployees.actions';
import * as workplaceTodos from 'shiftPlanner/components/workplaceTodos/store/workplaceTodos.actions';
import * as warningActions from './shiftWarnings.actions';

export default {
	...addShiftModal,
	...daySettingsModal,
	...phpReport,
	...clockings,
	...shifts,
	...workDays,
	...weatherForecast,
	...employees,
	...shiftPlannerForecasting,
	...shadowEmployee,
	...workplaceTodos,
	...warningActions,
};
