'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';
import { Input, InputCollectionSelect, Toggle, Tabs } from 'dumb';

// inner tables
import ProductVariantIngredientNutritionValues from './components/productVariantIngredientNutritionValues/productVariantIngredientNutritionValues.component';
import ProductVariantIngredientAllergens from './components/productVariantIngredientAllergens/productVariantIngredientAllergens.component';
import ProductVariantIngredientAlternatives from './../../../../../../../ingredients/components/productVariantIngredientAlternatives/productVariantIngredientAlternatives.container';

import _get from 'lodash/get';

import {
	setProductVariantIngredients,
	updateProductVariantIngredients,
	resetProductVariantIngredients,
} from './store/productVariantIngredients.actions';

import {
	fetchProductVariantIngredients,
	editProductVariantIngredient,
	addProductVariantIngredient,
	deleteProductVariantIngredient,
	editProductVariantIngredients,
} from './productVariantIngredients.service';

// phrases
import phrases from './productVariantsIngredients.phrases';

class ProductVariantIngredients extends Component {
	constructor(props) {
		super(props);

		this.fetchData = this.fetchData.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.editMultiple = this.editMultiple.bind(this);

		this.columns = [
			{
				Header: 'Ingredient',
				id: 'ingredient',
				accessor: (d) => _get(d, 'ingredient.name', ''),
				filterPath: ':ingredient.name',
			},
			{
				Header: 'Ingredient variation',
				id: 'ingredient_variation',
				accessor: (d) => _get(d, 'ingredient_variation.name', ''),
				filterPath: ':ingredient_variation.name',
			},
			{
				Header: 'Default',
				id: 'default',
				accessor: 'default',
				filterPath: ':default',
			},
			{
				Header: 'Ingredient amount',
				id: 'ingredient_amount',
				accessor: (d) => _get(d, 'ingredient_amount', ''),
				filterPath: ':ingredient_amount',
			},
			{
				Header: 'Excludable',
				id: 'excludable',
				filterPath: ':excludable',
				filterable: false,
				Cell: (d) => {
					return (
						<Input
							id="checkbox-exc"
							type="checkbox"
							checked={d.original.excludable}
							disabled
						/>
					);
				},
			},
			{
				Header: 'Available in app',
				id: 'available_in_app',
				filterPath: ':available_in_app',
				filterable: false,
				Cell: (d) => {
					return (
						<Input
							id="checkbox-avail"
							type="checkbox"
							checked={d.original.available_in_app}
							disabled
						/>
					);
				},
			},
			{
				Header: 'Sort order',
				id: 'sortOrder',
				accessor: 'sort_order',
				filterPath: ':sort_order',
			},
			{
				Header: 'Ingredient variation selection required',
				id: 'ingredientVariationSelectionRequired',
				filterPath: ':ingredient_variation_selection_required',
				filterable: false,
				Cell: (d) => {
					return (
						<Input
							id="checkbox-ingvar"
							type="checkbox"
							checked={d.original.ingredient_variation_selection_required}
							disabled
						/>
					);
				},
			},
		];
	}

	addEntry() {
		const { ingredientData, productVariant } = this.props;

		const payload = {
			product_variant: productVariant.id,
			ingredient: _get(ingredientData, 'ingredient.value', null),
			default: ingredientData.default,
			ingredient_amount: ingredientData.ingredientAmount,
			excludable: !!ingredientData.excludable,
			available_in_app: !!ingredientData.availableInApp,
			...(ingredientData.sortOrder && {
				sort_order: ingredientData.sortOrder,
			}),
			...(ingredientData.ingredientVariation && {
				ingredient_variation: _get(
					ingredientData,
					'ingredientVariation.value',
					null
				),
			}),
			...(ingredientData.ingredientVariation && {
				ingredient_variation_selection_required: !!ingredientData.ingredientVariationSelectionRequired,
			}),
		};

		return addProductVariantIngredient(payload);
	}

	editEntry() {
		const { ingredientData } = this.props;

		const payload = {
			default: ingredientData.default,
			ingredient_amount: ingredientData.ingredientAmount,
			excludable: ingredientData.excludable,
			available_in_app: ingredientData.availableInApp,
			sort_order: ingredientData.sortOrder,
			...(ingredientData.ingredientVariation && {
				ingredient_variation: ingredientData.ingredientVariation.value,
			}),
			...(ingredientData.ingredientVariation && {
				ingredient_variation_selection_required: !!ingredientData.ingredientVariationSelectionRequired,
			}),
		};

		return editProductVariantIngredient(ingredientData.id, payload);
	}

	editMultiple(selectedRows) {
		const { ingredientData } = this.props;

		const allValuesHaveIngredientVariation = selectedRows.every(
			(entry) => entry.ingredient_variation
		);

		const values = {
			...(ingredientData.ingredientVariation && {
				ingredient_variation: ingredientData.ingredientVariation.value,
			}),
			...(ingredientData.default && {
				default: ingredientData.default,
			}),
			...(ingredientData.ingredientAmount && {
				ingredient_amount: ingredientData.ingredientAmount,
			}),
			...(ingredientData.sortOrder && {
				sort_order: ingredientData.sortOrder,
			}),

			excludable: !!ingredientData.excludable,
			available_in_app: !!ingredientData.availableInApp,

			...(allValuesHaveIngredientVariation && {
				ingredient_variation_selection_required: !!ingredientData.ingredientVariationSelectionRequired,
			}),
		};

		const list = selectedRows.map((row) => {
			return {
				id: row.id,
				...values,
			};
		});

		const payload = {
			batch: list,
		};

		return editProductVariantIngredients(payload);
	}

	deleteEntry(id) {
		return deleteProductVariantIngredient(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			ingredient: data.ingredient.name,
			...(data.ingredient_variation && {
				ingredientVariation: {
					value: _get(data, 'ingredient_variation.id', null),
					label: _get(data, 'ingredient_variation.name', null),
				},
			}),
			default: data.default,
			ingredientAmount: data.ingredient_amount,
			excludable: data.excludable,
			availableInApp: data.available_in_app,
			sortOrder: data.sort_order,
			ingredientVariationSelectionRequired:
				data.ingredient_variation_selection_required,
			editMode: true,
		};

		this.props.setProductVariantIngredients(payload);
	}

	getEditableCells() {
		const { ingredientData } = this.props;

		const toggleEnabled = !!ingredientData.ingredientVariation;
		const editMode = ingredientData.editMode;

		return [
			...(editMode
				? [
						{
							header: 'Ingredient',
							value: <span>{ingredientData.ingredient}</span>,
						},
				  ]
				: [
						{
							header: 'Ingredient',
							value: (
								<InputCollectionSelect
									id="ingredient"
									placeholder="select ingredient"
									value={ingredientData.ingredient}
									handleChange={(key, e) =>
										this.editStoreEntry('ingredient', e)
									}
									required
									clearable
									cache
									apiPath="/product/ingredients"
									params={{
										limit: 50,
									}}
									optionFormat={(entry) => ({
										value: entry.id,
										label: entry.name,
									})}
									inputFilterFormat={(input) => `:name=like='%${input}%'`}
								/>
							),
						},
				  ]),
			{
				header: 'Ingredient variation',
				value: (
					<InputCollectionSelect
						placeholder="select ingredient variant"
						value={ingredientData.ingredientVariation}
						handleChange={(key, e) =>
							this.editStoreEntry('ingredientVariation', e)
						}
						clearable
						cache
						apiPath="/product/ingredient_variations"
						params={{
							limit: 50,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Default',
				value: (
					<Input
						id="default"
						placeholder="default"
						type="number"
						min="1"
						value={ingredientData.default}
						onChange={(e) => this.editStoreEntry('default', e.target.value)}
					/>
				),
			},
			{
				header: 'Ingredient amount',
				value: (
					<Input
						id="number"
						type="number"
						placeholder="amount"
						required
						min={0}
						value={ingredientData.ingredientAmount}
						onChange={(e) =>
							this.editStoreEntry('ingredientAmount', e.target.value)
						}
					/>
				),
			},
			{
				header: 'Excludable',
				value: (
					<Toggle
						id="excludable"
						toggled={_get(ingredientData, 'excludable', null)}
						onClick={(e) => this.editStoreEntry('excludable', e)}
					/>
				),
			},
			{
				header: 'Available in app',
				value: (
					<Toggle
						id="availableInApp"
						toggled={_get(ingredientData, 'availableInApp', null)}
						onClick={(e) => this.editStoreEntry('availableInApp', e)}
					/>
				),
			},
			{
				header: 'Sort order',
				value: (
					<Input
						id="sortOrder"
						type="number"
						placeholder="Enter sort order..."
						value={ingredientData.sortOrder || ''}
						onChange={(e) => this.editStoreEntry('sortOrder', e.target.value)}
					/>
				),
			},
			{
				header: 'Ingredient variation selection required',
				value: (
					<Toggle
						id="ingredientVariationSelectionRequired"
						disabled={!toggleEnabled}
						toggled={ingredientData.ingredientVariationSelectionRequired}
						onClick={(e) =>
							this.editStoreEntry('ingredientVariationSelectionRequired', e)
						}
					/>
				),
			},
		];
	}

	editStoreEntry(type, value) {
		this.props.updateProductVariantIngredients({
			[type]: value,
			...(type === 'ingredientVariation' &&
				value === null && { ingredientVariationSelectionRequired: null }),
		});
	}

	fetchData(state) {
		const { productVariant } = this.props;
		return fetchProductVariantIngredients(productVariant.id, state);
	}

	render() {
		return (
			<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.TABLE_TITLE}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={10}
				reduxKey={`product-product/variant-ingredient/${this.props.productVariant.id}`}
				manual
				editEntry={this.editEntry}
				editMultiple={this.editMultiple}
				editableCells={this.getEditableCells()}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetProductVariantIngredients}
				actionsWidth={60}
				deleteEntry={this.deleteEntry}
				createEntry={this.addEntry}
				subcomponent={(row) => {
					const tabContent = [
						{
							name: phrases.NUTRITION_VALUES,
							component: (
								<ProductVariantIngredientNutritionValues
									ingredient={_get(row, 'original.ingredient', null)}
								/>
							),
						},
						{
							name: phrases.ALLERGENS,
							component: (
								<ProductVariantIngredientAllergens
									ingredient={_get(row, 'original.ingredient', null)}
								/>
							),
						},
						{
							name: phrases.ALTERNATIVES,
							component: (
								<ProductVariantIngredientAlternatives
									productVariantIngredient={row.original}
								/>
							),
						},
					];
					return (
						<SubTable>
							<Tabs tabContent={tabContent} />
						</SubTable>
					);
				}}
			/>
		);
	}
}

ProductVariantIngredients.propTypes = {
	productVariant: PropTypes.object,
	ingredientData: PropTypes.object,
	updateProductVariantIngredients: PropTypes.func,
	setProductVariantIngredients: PropTypes.func,
	resetProductVariantIngredients: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setProductVariantIngredients,
			updateProductVariantIngredients,
			resetProductVariantIngredients,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		ingredientData: store.productProductVariantIngredients.data.ingredientData,
	};
};

export default connectWithStore(
	ProductVariantIngredients,
	mapStateToProps,
	mapDispatchToProps
);
