'use strict';

class ReportDataProviderAbstract {

	constructor() {
		// Set type
		this.type = 'dataProvider';
	}

}

export default ReportDataProviderAbstract;