import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './waitingTimeGrids.phrases';

/**
 * @function fetchWaitingTimeGrids
 * @description Fetch all product recommendations
 * @param {object} state
 */
export function fetchWaitingTimeGrids(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get('/administration/waiting_time_grids', params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editWaitingTimeGrid
 * @description edits waiting time grid
 */
export function editWaitingTimeGrid(data) {
	return post(`/administration/waiting_time_grids/${data.id}`, data)
		.then(res => {
			setFeedback(phrases.WAITING_TIME_GRID_EDIT, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function addWaitingTimeGrid
 * @description adds waiting time grid
 */
export function addWaitingTimeGrid(data) {
	return post('/administration/waiting_time_grids', data)
		.then(res => {
			setFeedback(phrases.WAITING_TIME_GRID_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteWaitingTimeGrid
 * @description deletes waiting time grids
 */
export function deleteWaitingTimeGrid(productId) {
	return remove(`/administration/waiting_time_grids/${productId}?cascade=true`)
		.then(res => {
			setFeedback(phrases.WAITING_TIME_GRID_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
