'use strict';

var headers = {
	WORKPLACE_RENTS: {
		BASECOST: 'Base cost',
		PERCENTAGE: 'Percentage',
		CONTRACTABLE: 'Contractable',
		NONCONTRACTABLE: 'Non contractable',
		RENTOUT: 'Rent out',
		FROM: 'From',
		TO: 'To'
	},
	FORECASTING_SCENARIOS: {
		NAME: 'Name',
		DESCRIPTION: 'Description',
		TYPE: 'Type'
	},
	INDEX_ADJUSTMENTS: {
		TAG: 'Tag',
		FROM: 'From',
		TO: 'To'
	}
};

var titles = {
	INDEX_ADJUSTMENTS: 'Index Adjustments',
	FORECASTING_SCENARIOS: 'Forecasting Scenarios',
	WORKPLACE_RENTS: 'Workplace rents'
};

module.exports = {
	headers: headers,
	titles: titles
};