export const SET_ACTIVE_DATES = 'OPENING_HOURS_EDITOR/SET_ACTIVE_DATES';
export const SET_PRIORITIZE = 'OPENING_HOURS_EDITOR/SET_PRIORITIZE';
export const SET_OVERWRITE = 'OPENING_HOURS_EDITOR/SET_OVERWRITE';
export const OPENING_HOURS_EDITED = 'OPENING_HOURS_EDITOR/OPENING_HOURS_EDITED';
export const SET_DAY_CLOSED = 'OPENING_HOURS_EDITOR/SET_DAY_CLOSED';
export const EDITABLE_WEEK_UPDATED = 'OPENING_HOURS_EDITOR/EDITABLE_WEEK_UPDATED';
export const RESET_STATE = 'OPENING_HOURS/RESET_STATE';

// LOCAL ACTIONS

export function editActiveDates(name, date) {
	return {
		type: SET_ACTIVE_DATES,
		payload: {
			name,
			date
		}
	};
}

export function setPrioritized(prioritize) {
	return {
		type: SET_PRIORITIZE,
		payload: {
			prioritize
		}
	};
}

export function setOverwrite(allowOverwrite) {
	return {
		type: SET_OVERWRITE,
		payload: {
			allowOverwrite
		}
	};
}

export function updateEditableWeek(week) {
	return {
		type: EDITABLE_WEEK_UPDATED,
		payload: {
			week
		}
	};
}

export function editWeekdayOpeningHours(index, handle, value) {
	return {
		type: OPENING_HOURS_EDITED,
		payload: {
			index,
			handle,
			value
		}
	};
}

export function setDayClosed(index, isClosed) {
	return {
		type: SET_DAY_CLOSED,
		payload: {
			index,
			isClosed
		}
	};
}

export function resetState() {
	return {
		type: RESET_STATE
	};
}
