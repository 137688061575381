import React, { Component } from 'react';
import PropTypes from 'prop-types';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// redux actions
import {
	setTimeSlots,
	updateBatchTimeSlot,
	addNewTimeSlotRow,
	resetAddTimeSlotsModal,
	deleteTimeSlotRow,
	setProducts,
	updateHomeScreenRecommendation,
	setTimeSlotToEdit,
	updateTimeSlotToEdit,
	resetEditTimeSlotModal,
	updateTimeSlot,
	resetData,
	setTimeSlotToDelete,
	resetDeleteTimeSlotModal,
	deleteTimeSlot,
	setTileLayoutToDuplicate,
	resetTileLayoutToDuplicate,
} from './store/homeScreenRecommendations.actions';

// components
import { Button } from 'dumb';
import { Tip } from 'dumb/tips';
import AddTimeSlotModal from './components/addTimeSlotModal/addTimeSlotModal';
import EditTimeSlotModal from './components/editTimeSlotModal/editTimeSlotModal';
import DeleteTimeSlotModal from './components/deleteTimeSlotModal/deleteTimeSlotModal';
import HomeScreenRecommendationsComponent from './homeScreenRecommendations.component';
import DuplicateRecommendationModal from './components/duplicateRecommendationModal/duplicateRecommendationModal';

// service
import {
	fetchTileLayoutHomeScreenRecommendations,
	addPosTileLayoutTimeSlots,
	fetchPosTiers,
	addTileLayoutHomeScreenRecommendations,
	editTileLayoutHomeScreenRecommendations,
	editPosTileLayoutTimeSlots,
	deletePosTileLayoutTimeSlots,
	fetchTileLayoutTimeSlots,
	duplicateTileLayoutTimeSlots,
} from './homeScreenRecommendations.service';
import {
	createHomeScreenRecommendationsPayload,
	formatTimeSlots,
} from './utils';

// lodash
import _isEmpty from 'lodash/isEmpty';

// phrases/ constants
import phrases from './homeScreenRecommendations.phrases';
import constants from 'services/constants';

import moment from 'moment';

// styles
import './homeScreenRecommendations.css';

class HomeScreenRecommendations extends Component {
	constructor(props) {
		super(props);

		this.state = {
			addTimeSlotModalVisible: false,
			editTimeSlotModalVisible: false,
			deleteTimeSlotModalVisible: false,
			duplicateRecommendationModalVisible: false,
			loading: false,
			homeScreenRecommendationDescriptionLoading: null,
			homeScreenRecommendationProductLoading: null,
		};

		this.toggleAddTimeSlotModal = this.toggleAddTimeSlotModal.bind(this);
		this.submitTimeSlots = this.submitTimeSlots.bind(this);
		this.editTileLayoutHomeScreenRecommendationsWrapper = this.editTileLayoutHomeScreenRecommendationsWrapper.bind(
			this
		);
		this.toggleEditTimeSlotModal = this.toggleEditTimeSlotModal.bind(this);
		this.onEditClick = this.onEditClick.bind(this);
		this.deleteTimeSlot = this.deleteTimeSlot.bind(this);
		this.editTimeSlot = this.editTimeSlot.bind(this);
		this.onDeleteClick = this.onDeleteClick.bind(this);
		this.toggleDeleteTimeSlotModal = this.toggleDeleteTimeSlotModal.bind(this);
		this.addHomeScreenRecommendations = this.addHomeScreenRecommendations.bind(
			this
		);
		this.toggleDuplicateRecommendationModal = this.toggleDuplicateRecommendationModal.bind(
			this
		);
		this.duplicateTileLayout = this.duplicateTileLayout.bind(this);
	}

	componentDidMount() {
		const { layoutId, setTimeSlots } = this.props;

		const filter = `:tile_layout_time_slot.tile_layout=='${layoutId}'`;

		this.setState(() => ({ loading: true }));

		fetchTileLayoutHomeScreenRecommendations(filter)
			.then((res) => {
				// if recommendations empty there's a slight chance smth went wrong when creating
				// entries and that a time slot exists, check if time slot exists
				if (_isEmpty(res)) {
					const filter = `:tile_layout.id=='${layoutId}'`;

					fetchTileLayoutTimeSlots(filter).then((res) => {
						const formattedTimeSlots = formatTimeSlots(res.data);

						setTimeSlots(formattedTimeSlots);
					});
				}

				setTimeSlots(res);
			})
			.finally(() => this.setState(() => ({ loading: false })));
	}

	componentWillUnmount() {
		this.props.resetData();
	}

	toggleAddTimeSlotModal() {
		const { resetAddTimeSlotsModal } = this.props;

		if (this.state.addTimeSlotModalVisible) resetAddTimeSlotsModal();

		this.setState((prevState) => ({
			addTimeSlotModalVisible: !prevState.addTimeSlotModalVisible,
		}));
	}

	toggleEditTimeSlotModal() {
		const { resetEditTimeSlotModal } = this.props;

		if (this.state.editTimeSlotModalVisible) resetEditTimeSlotModal();

		this.setState((prevState) => ({
			editTimeSlotModalVisible: !prevState.editTimeSlotModalVisible,
		}));
	}

	toggleDeleteTimeSlotModal() {
		const { resetDeleteTimeSlotModal } = this.props;

		if (this.state.deleteTimeSlotModalVisible) resetDeleteTimeSlotModal();

		this.setState((prevState) => ({
			deleteTimeSlotModalVisible: !prevState.deleteTimeSlotModalVisible,
		}));
	}

	toggleDuplicateRecommendationModal() {
		const { resetTileLayoutToDuplicate } = this.props;

		if (this.state.duplicateRecommendationModalVisible)
			resetTileLayoutToDuplicate();

		this.setState((prevState) => ({
			duplicateRecommendationModalVisible: !prevState.duplicateRecommendationModalVisible,
		}));
	}

	duplicateTileLayout() {
		const { layoutId, tileLayoutToDuplicate } = this.props;

		if (!tileLayoutToDuplicate.tileLayout) return;

		this.setState(() => ({ loading: true }));

		const body = {
			// current tile layout to duplicate from
			tile_layout: layoutId,
		};

		duplicateTileLayoutTimeSlots(
			tileLayoutToDuplicate.tileLayout?.value?.id,
			body
		).finally(() => {
			this.setState(() => ({ loading: false }));
			this.toggleDuplicateRecommendationModal();
		});
	}

	onEditClick(timeSlot) {
		const { setTimeSlotToEdit } = this.props;

		if (!timeSlot.time?.from || !timeSlot.time?.to) return;

		// format times
		const timeFrom = moment
			.utc(timeSlot.time.from, constants.dayFormat)
			.format(constants.timeShort);
		const timeTo = moment
			.utc(timeSlot.time.to, constants.dayFormat)
			.format(constants.timeShort);

		const payload = {
			id: timeSlot.id,
			timeFrom,
			timeTo,
		};

		setTimeSlotToEdit(payload);

		this.toggleEditTimeSlotModal();
	}

	onDeleteClick(timeSlot) {
		const { setTimeSlotToDelete } = this.props;

		if (!timeSlot.id) return;

		const payload = {
			id: timeSlot.id,
		};

		setTimeSlotToDelete(payload);

		this.toggleDeleteTimeSlotModal();
	}

	submitTimeSlots() {
		const { batchTimeSlots, layoutId } = this.props;

		this.setState(() => ({ loading: true }));

		// format payload
		const payload = batchTimeSlots.map((entry) => ({
			tile_layout: layoutId,
			time: {
				from: moment
					.utc(entry.timeFrom, constants.timeShort)
					.format(constants.dayFormat),
				to: moment
					.utc(entry.timeTo, constants.timeShort)
					.format(constants.dayFormat),
			},
		}));

		addPosTileLayoutTimeSlots({ batch: payload })
			.then((res) => {
				return this.addHomeScreenRecommendations({ timeSlots: res.data });
			})
			.finally(() => this.setState(() => ({ loading: false })));
	}

	addHomeScreenRecommendations({ timeSlots }) {
		const { setTimeSlots } = this.props;

		return fetchPosTiers().then((resTwo) => {
			const payload = createHomeScreenRecommendationsPayload({
				timeSlots,
				posTiers: resTwo.data,
			});

			// add tile layout home screen recommendations
			addTileLayoutHomeScreenRecommendations({ batch: payload }).then(
				(resThree) => {
					setTimeSlots(resThree);

					// this.toggleAddTimeSlotModal(); -> this would make more sense but to keep things simple we're doing setState directly until api implements transactions
					this.setState(() => ({
						addTimeSlotModalVisible: false,
					}));
				}
			);
		});
	}

	editTimeSlot() {
		const { timeSlotToEdit, updateTimeSlot } = this.props;

		this.setState(() => ({ loading: true }));

		const payload = {
			id: timeSlotToEdit.id,
			time: {
				from: moment
					.utc(timeSlotToEdit.timeFrom, constants.timeShort)
					.format(constants.dayFormat),
				to: moment
					.utc(timeSlotToEdit.timeTo, constants.timeShort)
					.format(constants.dayFormat),
			},
		};

		editPosTileLayoutTimeSlots(payload)
			.then((res) => {
				// updateTimeSlot
				const payload = {
					id: res.data[0].id,
					time: res.data[0].time,
				};

				updateTimeSlot(payload);

				this.toggleEditTimeSlotModal();
			})
			.finally(() => this.setState(() => ({ loading: false })));
	}

	deleteTimeSlot() {
		const { timeSlotToDelete, deleteTimeSlot } = this.props;

		this.setState(() => ({ loading: true }));

		const payload = {
			id: timeSlotToDelete.id,
		};

		deletePosTileLayoutTimeSlots(payload)
			.then(() => {
				deleteTimeSlot(payload);

				this.toggleDeleteTimeSlotModal();
			})
			.finally(() => this.setState(() => ({ loading: false })));
	}

	editTileLayoutHomeScreenRecommendationsWrapper(
		payload,
		productNumber = null
	) {
		const { updateHomeScreenRecommendation } = this.props;

		if (productNumber) {
			this.setState(() => ({
				homeScreenRecommendationProductLoading: {
					id: payload.id,
					productNumber,
				},
			}));
		} else {
			this.setState(() => ({
				homeScreenRecommendationDescriptionLoading: {
					id: payload.id,
				},
			}));
		}

		return editTileLayoutHomeScreenRecommendations(payload)
			.then((res) => {
				updateHomeScreenRecommendation(res.data[0]);
			})
			.finally(() =>
				this.setState(() => ({
					homeScreenRecommendationDescriptionLoading: null,
					homeScreenRecommendationProductLoading: null,
				}))
			);
	}

	render() {
		const {
			batchTimeSlots,
			updateBatchTimeSlot,
			addNewTimeSlotRow,
			deleteTimeSlotRow,
			timeSlots,
			layoutName,
			products,
			timeSlotToEdit,
			updateTimeSlotToEdit,
			setProducts,
			deleteTimeSlot,
			setTileLayoutToDuplicate,
			tileLayoutToDuplicate,
			layoutId,
		} = this.props;

		return (
			<div className="home-screen-recommendations">
				{_isEmpty(timeSlots) ? (
					this.state.loading ? null : (
						<div className="home-screen-recommendations__tip">
							<Tip content={phrases.NO_TIME_SLOTS_ADDED_TIP} />
						</div>
					)
				) : (
					<HomeScreenRecommendationsComponent
						layoutName={layoutName}
						timeSlots={timeSlots}
						products={products}
						editTileLayoutHomeScreenRecommendations={
							this.editTileLayoutHomeScreenRecommendationsWrapper
						}
						onEditClick={this.onEditClick}
						loading={this.state.loading}
						onDeleteClick={this.onDeleteClick}
						setProducts={setProducts}
						addHomeScreenRecommendations={this.addHomeScreenRecommendations}
						deleteTimeSlot={deleteTimeSlot}
						homeScreenRecommendationDescriptionLoading={
							this.state.homeScreenRecommendationDescriptionLoading
						}
						homeScreenRecommendationProductLoading={
							this.state.homeScreenRecommendationProductLoading
						}
						onDuplicateClick={this.toggleDuplicateRecommendationModal}
					/>
				)}

				<div className="home-screen-recommendations__footer">
					<Button type="inverted" shadow onClick={this.toggleAddTimeSlotModal}>
						{phrases.ADD_TIME_SLOT}
					</Button>
				</div>

				{this.state.addTimeSlotModalVisible && (
					<AddTimeSlotModal
						onClose={this.toggleAddTimeSlotModal}
						batchTimeSlots={batchTimeSlots}
						updateBatchTimeSlot={updateBatchTimeSlot}
						addNewTimeSlotRow={addNewTimeSlotRow}
						deleteTimeSlotRow={deleteTimeSlotRow}
						onConfirmClick={this.submitTimeSlots}
						loading={this.state.loading}
					/>
				)}

				{this.state.editTimeSlotModalVisible && (
					<EditTimeSlotModal
						onClose={this.toggleEditTimeSlotModal}
						onConfirmClick={this.editTimeSlot}
						timeSlotToEdit={timeSlotToEdit}
						updateTimeSlotToEdit={updateTimeSlotToEdit}
						loading={this.state.loading}
					/>
				)}

				{this.state.deleteTimeSlotModalVisible && (
					<DeleteTimeSlotModal
						onClose={this.toggleDeleteTimeSlotModal}
						onConfirmClick={this.deleteTimeSlot}
						loading={this.state.loading}
					/>
				)}

				{this.state.duplicateRecommendationModalVisible && (
					<DuplicateRecommendationModal
						onClose={this.toggleDuplicateRecommendationModal}
						onConfirmClick={this.duplicateTileLayout}
						loading={this.state.loading}
						setTileLayoutToDuplicate={setTileLayoutToDuplicate}
						tileLayoutToDuplicate={tileLayoutToDuplicate}
						layoutId={layoutId}
					/>
				)}
			</div>
		);
	}
}

HomeScreenRecommendations.propTypes = {
	layoutId: PropTypes.number,
	setTimeSlots: PropTypes.func,
	timeSlots: PropTypes.array,
	updateBatchTimeSlot: PropTypes.func,
	addNewTimeSlotRow: PropTypes.func,
	resetAddTimeSlotsModal: PropTypes.func,
	deleteTimeSlotRow: PropTypes.func,
	batchTimeSlots: PropTypes.array,
	products: PropTypes.array,
	layoutName: PropTypes.string,
	setProducts: PropTypes.func,
	updateHomeScreenRecommendation: PropTypes.func,
	setTimeSlotToEdit: PropTypes.func,
	updateTimeSlotToEdit: PropTypes.func,
	resetEditTimeSlotModal: PropTypes.func,
	updateTimeSlot: PropTypes.func,
	timeSlotToEdit: PropTypes.object,
	resetData: PropTypes.func,
	setTimeSlotToDelete: PropTypes.func,
	resetDeleteTimeSlotModal: PropTypes.func,
	deleteTimeSlot: PropTypes.func,
	timeSlotToDelete: PropTypes.object,
	setTileLayoutToDuplicate: PropTypes.func,
	resetTileLayoutToDuplicate: PropTypes.func,
	tileLayoutToDuplicate: PropTypes.object,
};

const mapStateToProps = (store) => ({
	tileLayoutTimeSlots: store.homeScreenRecommendations.tileLayoutTimeSlots,
	batchTimeSlots: store.homeScreenRecommendations.batchTimeSlots,
	products: store.homeScreenRecommendations.products,
	timeSlots: store.homeScreenRecommendations.timeSlots,
	timeSlotToEdit: store.homeScreenRecommendations.timeSlotToEdit,
	timeSlotToDelete: store.homeScreenRecommendations.timeSlotToDelete,
	tileLayoutToDuplicate: store.homeScreenRecommendations.tileLayoutToDuplicate,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setTimeSlots,
			updateBatchTimeSlot,
			addNewTimeSlotRow,
			resetAddTimeSlotsModal,
			deleteTimeSlotRow,
			setProducts,
			updateHomeScreenRecommendation,
			setTimeSlotToEdit,
			updateTimeSlotToEdit,
			resetEditTimeSlotModal,
			updateTimeSlot,
			resetData,
			setTimeSlotToDelete,
			resetDeleteTimeSlotModal,
			deleteTimeSlot,
			setTileLayoutToDuplicate,
			resetTileLayoutToDuplicate,
		},
		dispatch
	);

export default connectWithStore(
	HomeScreenRecommendations,
	mapStateToProps,
	mapDispatchToProps
);
