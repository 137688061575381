export default {
	TABLE_TITLE: 'Raw ingredients',
	RAW_INGREDIENTS_ADDED: 'Raw ingredients added',
	RAW_INGREDIENT_EDITED: 'Raw ingredient edited',
	RAW_INGREDIENTS_EDITED: 'Raw ingredients edited',
	RAW_INGREDIENTS_DELETED: 'Raw ingredients deleted',

	// modal batch stuff
	MODAL_BATCH_STEP_FORM_NAME: 'Name',
	MODAL_BATCH_STEP_FORM_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_FORM_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_FORM_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_FORM_GLOBAL_RAW_INGREDIENT: 'Global raw ingredient',
	MODAL_BATCH_STEP_FORM_GLOBAL_RAW_INGREDIENT_PLACEHOLDER:
		'Select Global raw ingredient...',
	MODAL_BATCH_STEP_FORM_AMOUNT: 'Amount',
	MODAL_BATCH_STEP_FORM_AMOUNT_PLACEHOLDER: 'Enter amount...',
	MODAL_BATCH_STEP_FORM_LEGACY: 'Legacy',
	MODAL_BATCH_STEP_LIST_TITLE: 'Add Raw ingredients',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'submit',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_LEGACY: 'Legacy',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row.',
	MODAL_BATCH_LIST_TIP: 'Global raw ingredient: '
};
