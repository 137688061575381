export const UPDATE_BATCH_FORM =
	'SALES_CONFIGURATION_INGREDIENT_ALLERGENS/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'SALES_CONFIGURATION_INGREDIENT_ALLERGENS/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_INGREDIENT_ALLERGENS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_INGREDIENT_ALLERGENS/REMOVE_BATCH_LIST_ITEM';

export const RESET_INGREDIENT_ALLERGEN =
	'SALES_CONFIGURATION_INGREDIENT_ALLERGENS/RESET_INGREDIENT_ALLERGEN';
export const SET_INGREDIENT_ALLERGEN =
	'SALES_CONFIGURATION_INGREDIENT_ALLERGENS/SET_INGREDIENT_ALLERGEN';
export const UPDATE_INGREDIENT_ALLERGEN =
	'SALES_CONFIGURATION_INGREDIENT_ALLERGENS/UPDATE_INGREDIENT_ALLERGEN';

/**
 * @param {object} payload
 */
export function setIngredientAllergen(payload) {
	return {
		type: SET_INGREDIENT_ALLERGEN,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function updateIngredientAllergen(payload) {
	return {
		type: UPDATE_INGREDIENT_ALLERGEN,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET_INGREDIENT_ALLERGEN,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}
