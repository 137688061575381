import { post } from 'api.vanilla.service';
import * as Sentry from '@sentry/browser';
import __endpoints from 'endpoints.service';

/**
 * @function sendMail
 * @memberOf COMPONENTS.PrintView
 * @description Handler for sending mail
 */
export function sendMail(data) {
	return post(__endpoints.collection.utilities.mails.create.path, data).then(
		response => response,
		err => {
			Sentry.withScope(scope => {
				scope.setExtra('data', data);
				Sentry.captureException(err);
				Sentry.captureMessage('Mail did not send', 'error');
			});
			return err;
		}
	);
}
