'use strict';

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import DetailsComponent from 'common/components/details/details.component';
import ListComponent from 'common/components/list/list.component';
import __apiFilter from 'apiFilter.service';
import SalaryComponentDetails from './services/salaryComponentsDetails.service.js';

import SalaryComponentsRulesList from 'salary/salaryComponents/services/salaryComponentsRulesList.service.js';
import SalaryComponentsDailyOvertimeRulesList from 'salary/salaryComponents/services/salaryComponentDailyOvertimeRulesList.service.js';
import SalaryComponentsWeeklyOvertimeRulesList from 'salary/salaryComponents/services/salaryComponentWeeklyOvertimeRulesList.service.js';
import SalaryComponentDailySupplementRulesList from 'salary/salaryComponents/services/salaryComponentDailySupplementRulesList.service.js';
import SalaryComponentDaysPrWeekRulesList from 'salary/salaryComponents/services/salaryComponentDaysPrWeekRulesList.service.js';
import SalaryComponentSplitShiftRulesList from 'salary/salaryComponents/services/salaryComponentSplitShiftRules.service.js';

const salaryComponentDetailsReduxKey = '/salary/salary_components';
const salaryComponentDetails = new SalaryComponentDetails(salaryComponentDetailsReduxKey);
const salaryComponentDetailsProps = salaryComponentDetails.getProps();

const salaryComponentRulesReduxKey = '/salary/salary_component_rules';
const salaryComponentsRulesList = new SalaryComponentsRulesList(salaryComponentRulesReduxKey);
const salaryComponentsRulesListProps = salaryComponentsRulesList.getProps();

const salaryComponentsDailyOvertimeRulesListReduxKey = '/salary/salary_component_daily_overtime_rules';
const salaryComponentsDailyOvertimeRulesList = new SalaryComponentsDailyOvertimeRulesList(
	salaryComponentsDailyOvertimeRulesListReduxKey
);
const salaryComponentsDailyOvertimeRulesListProps = salaryComponentsDailyOvertimeRulesList.getProps();

const salaryComponentsWeeklyOvertimeRulesListReduxKey = '/salary/salary_component_weekly_overtime_rules';
const salaryComponentsWeeklyOvertimeRulesList = new SalaryComponentsWeeklyOvertimeRulesList(
	salaryComponentsWeeklyOvertimeRulesListReduxKey
);
const salaryComponentsWeeklyOvertimeRulesListProps = salaryComponentsWeeklyOvertimeRulesList.getProps();

const salaryComponentDailySupplementRulesListReduxKey = '/salary/salary_component_daily_supplement_rules';
const salaryComponentDailySupplementRulesList = new SalaryComponentDailySupplementRulesList(
	salaryComponentDailySupplementRulesListReduxKey
);
const salaryComponentDailySupplementRulesListProps = salaryComponentDailySupplementRulesList.getProps();

const salaryComponentDaysPrWeekRulesListReduxKey = '/salary/salary_component_days_per_week_rules';
const salaryComponentDaysPrWeekRulesList = new SalaryComponentDaysPrWeekRulesList(
	salaryComponentDaysPrWeekRulesListReduxKey
);
const salaryComponentDaysPrWeekRulesListProps = salaryComponentDaysPrWeekRulesList.getProps();

const salaryComponentSplitShiftRulesListReduxKey = '/salary/salary_component_split_shift_rules';
const salaryComponentSplitShiftRulesList = new SalaryComponentSplitShiftRulesList(
	salaryComponentSplitShiftRulesListReduxKey
);
const salaryComponentSplitShiftRulesListProps = salaryComponentSplitShiftRulesList.getProps();

export function SalaryComponentDetailsComponent() {
	const { salaryComponentId } = useParams();

	// Get common filter for querying where person = id
	const salaryComponentFilter = __apiFilter.defaults.fieldEqualsData('salary_component', salaryComponentId);

	salaryComponentDetails.setItemId(salaryComponentId);
	useEffect(() => {
		salaryComponentDetails.initView();

		salaryComponentsRulesList.setFilterQuery(salaryComponentFilter);
		salaryComponentsRulesList.setData({ salary_component: salaryComponentId });
		salaryComponentsRulesList.initView();

		salaryComponentsDailyOvertimeRulesList.setFilterQuery(salaryComponentFilter);
		salaryComponentsDailyOvertimeRulesList.setData({
			salary_component: salaryComponentId,
		});

		salaryComponentsDailyOvertimeRulesList.initView();

		salaryComponentsWeeklyOvertimeRulesList.setFilterQuery(salaryComponentFilter);
		salaryComponentsWeeklyOvertimeRulesList.setData({
			salary_component: salaryComponentId,
		});
		salaryComponentsWeeklyOvertimeRulesList.initView();

		salaryComponentDailySupplementRulesList.setFilterQuery(salaryComponentFilter);
		salaryComponentDailySupplementRulesList.setData({
			salary_component: salaryComponentId,
		});
		salaryComponentDailySupplementRulesList.initView();

		salaryComponentDaysPrWeekRulesList.setFilterQuery(salaryComponentFilter);
		salaryComponentDaysPrWeekRulesList.setData({
			salary_component: salaryComponentId,
		});
		salaryComponentDaysPrWeekRulesList.initView();

		salaryComponentSplitShiftRulesList.setFilterQuery(salaryComponentFilter);
		salaryComponentSplitShiftRulesList.setData({
			salary_component: salaryComponentId,
		});
		salaryComponentSplitShiftRulesList.initView();
	}, [salaryComponentFilter, salaryComponentId]);

	return (
		<>
			<DetailsComponent reduxKey={salaryComponentDetailsReduxKey} {...salaryComponentDetailsProps} />

			<ListComponent reduxKey={salaryComponentRulesReduxKey} {...salaryComponentsRulesListProps} />

			<ListComponent
				reduxKey={salaryComponentsDailyOvertimeRulesListReduxKey}
				{...salaryComponentsDailyOvertimeRulesListProps}
			/>
			<ListComponent
				reduxKey={salaryComponentsWeeklyOvertimeRulesListReduxKey}
				{...salaryComponentsWeeklyOvertimeRulesListProps}
			/>

			<ListComponent
				reduxKey={salaryComponentDaysPrWeekRulesListReduxKey}
				{...salaryComponentDaysPrWeekRulesListProps}
			/>

			<ListComponent
				reduxKey={salaryComponentDailySupplementRulesListReduxKey}
				{...salaryComponentDailySupplementRulesListProps}
			/>

			<ListComponent
				reduxKey={salaryComponentSplitShiftRulesListReduxKey}
				{...salaryComponentSplitShiftRulesListProps}
			/>
		</>
	);
}
