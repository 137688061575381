'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// rdw actions
import { store } from 'appState';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';

// components
import { ModalStepper, Overlay } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';
import Checkbox from 'inputs/checkbox';

// different batch views
import BatchStepList from './components/rawIngredientConfigurationRawIngredientsModalStepList';
import BatchStepForm from './components/rawIngredientConfigurationRawIngredientsModalStepForm';

// phrases
import phrases from './../../rawIngredientConfigurationRawIngredients.phrases';

// looodash
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _uniqueId from 'lodash/uniqueId';
import _uniqBy from 'lodash/uniqBy';

class rawIngredientConfigurationsModalBatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showOverlay: false,
			overlayType: '',
			loadingModal: false
		};

		this.rawIngredientsUrl = '/inventory/raw_ingredients';

		// Binds
		this._batchSubmit = this._batchSubmit.bind(this);
		this.handleToggleOverlay = this.handleToggleOverlay.bind(this);
		this.onOverlayOpen = this.onOverlayOpen.bind(this);
		this.resetSelectedRows = this.resetSelectedRows.bind(this);
		this.handleCloseWrapper = this.handleCloseWrapper.bind(this);

		this.modalRef = React.createRef();
		this.columns = [
			{
				Header: 'Global Raw ingredient',
				id: 'globalRawIngredient',
				accessor: d => _get(d, 'global_raw_ingredient.name', ''),
				filterPath: ':global_raw_ingredient.name'
			},
			{
				Header: 'Name',
				id: 'name',
				accessor: d => _get(d, 'name', ''),
				filterPath: ':name'
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: d => _get(d, 'description', ''),
				filterPath: ':description'
			},
			{
				Header: 'Amount',
				id: 'amount',
				accessor: d => _get(d, 'amount', ''),
				filterPath: ':amount'
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: d => _get(d, 'legacy', ''),
				filterPath: ':legacy',
				filterable: false,
				Cell: d => <Checkbox noBorder disabled checked={d.original.legacy} />
			}
		];
	}

	_onSubmitForm() {
		const { batchFormData, addBatchList } = this.props;

		// clear duplicates
		const cleanRowIngredients = _uniqBy(
			batchFormData.rawIngredients,
			'value.id'
		);

		const listArray = cleanRowIngredients.map(entry => {
			return {
				identifier: _uniqueId('row-'), // used to identify what to edit on step#2
				rawIngredient: entry
			};
		});

		addBatchList(listArray);

		this._goToStep(2);
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_batchSubmit() {
		const { batchList, rawIngredientConfigurationId } = this.props;

		this.setState(() => ({
			loadingModal: true
		}));

		// eliminate values with no rawIngredient selected
		const cleanRawIngredients = batchList.filter(entry => entry.rawIngredient);

		const payload = cleanRawIngredients.map(entry => {
			return {
				raw_ingredient: entry.rawIngredient.value.id,
				raw_ingredient_configuration: rawIngredientConfigurationId
			};
		});

		this.props
			.addRawIngredientConfigurationRawIngredients({ batch: payload })

			.then(e => {
				this.handleCloseWrapper();
				this.setState(() => ({
					loadingModal: false
				}));
			})
			.catch(e => {
				this.setState(() => ({
					loadingModal: false
				}));
			});
	}

	_getIsStepValid() {
		return true;
	}

	handleToggleOverlay(type) {
		const { listRawIngredients, updateBatchForm } = this.props;

		if (this.state.showOverlay) {
			const selectedRowsInList = _get(
				listRawIngredients,
				'ui.selectedRows',
				[]
			);

			updateBatchForm({
				rawIngredients: selectedRowsInList.map(entry => {
					return { label: entry.name, value: entry };
				})
			});
		}

		this.setState(prevState => ({
			showOverlay: !prevState.showOverlay,
			overlayType: prevState.overlayType ? '' : type
		}));
	}

	onOverlayOpen() {
		const { batchFormData } = this.props;
		const { overlayType } = this.state;

		if (_isEmpty(batchFormData.rawIngredients)) return;

		// set already selected data in rdw store as clicked
		batchFormData.rawIngredients.map(entry => {
			store.dispatch(
				actions.addRowToSelection({
					reduxKey: overlayType,
					data: entry.value
				})
			);
		});
	}

	resetSelectedRows(reduxKey) {
		const { listRawIngredients } = this.props;

		listRawIngredients && store.dispatch(actions.resetSelection({ reduxKey }));
	}

	handleCloseWrapper() {
		const { handleClose } = this.props;

		this.resetSelectedRows(this.rawIngredientsUrl);

		handleClose();
	}

	render() {
		const {
			modalVisible,
			removeBatchListItem,
			editBatchListItem,
			batchList,
			batchFormData,
			updateBatchForm,
			fetchRawIngredients
		} = this.props;
		const { showOverlay } = this.state;

		const steps = [
			{
				component: (
					<BatchStepForm
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.handleToggleOverlay}
						rawIngredientsUrl={this.rawIngredientsUrl}
						resetSelection={this.resetSelectedRows}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE,
				isValid: this._getIsStepValid(),
				onNext: () => this._onSubmitForm(),
				loading: false,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				defaultStyles: true,
				noScroll: true,
				allowOverflow: true
			},
			{
				component: (
					<BatchStepList
						batchList={batchList}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
						rawIngredientsUrl={this.rawIngredientsUrl}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CANCEL_BUTTON_LABEL,
				defaultStyles: false
			}
		];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						ref={this.modalRef}
						className="modal-batch"
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={showOverlay ? () => {} : this.handleCloseWrapper}
						closeConfirm
					/>
				)}

				<Overlay
					zIndex={551}
					height={850}
					list
					visible={showOverlay}
					onClose={() => {
						this.handleToggleOverlay();
					}}>
					<ReactDataWrapper
						title={phrases.MODAL_BATCH_TABLE_TITLE}
						columns={this.columns}
						fetchData={fetchRawIngredients}
						filterable
						disableFetchCsvButton
						enableMultiSelection
						batchSelection
						defaultPageSize={20}
						onInitialization={this.onOverlayOpen}
						reduxKey={this.state.overlayType}
						manual
						actionsWidth={0}
						style={{
							maxHeight: '720px'
						}}
					/>
				</Overlay>
			</>
		);
	}
}

rawIngredientConfigurationsModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	batchList: PropTypes.array,
	handleClose: PropTypes.func,
	addRawIngredientConfigurationRawIngredients: PropTypes.func,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	fetchRawIngredients: PropTypes.func,
	listRawIngredients: PropTypes.array,
	addBatchList: PropTypes.func,
	rawIngredientConfigurationId: PropTypes.number
};

rawIngredientConfigurationsModalBatch.defaultProps = {
	modalVisible: false,
	loading: false
};

export default rawIngredientConfigurationsModalBatch;
