'use strict';

import _each from 'lodash/each';
import _isUndefined from 'lodash/isUndefined';
import _size from 'lodash/size';


/**
 * @namespace __apiFilter
 * @memberOf SERVICES
 * @description
 * Wrapper for apiFilter - Returns correctly formatted filters for API calls
 * Includes all methods for setting filters based on API filtering settings
 */

/**
 * @function defaults
 * @description
 * Wrapper for often used filters.
 * Params are variable from default to default
 * @return {string} filter string
 * @memberOf SERVICES.__apiFilter
 */
var defaults = {
	fieldEqualsData: function(field, data) {
		return create([
			{
				fields: [
					{
						field: field + '.id',
						operator: '==',
						data: data
					}
				]
			}
		]);
	}
};

/**
 * @function create
 * @description
 * - Creates filter string based on filter array.
 * - Look through API documentation to find possible values/combinations
 * - The array should be formatted as follows:
 *
 *		var filter = [
 *			{
 *				joiningOperator: ';',
 *				fields: [
 *					{
 *						field: 'person',
 *						operator: '==',
 *						data: 1,
 *					}
 *				]
 *			},
 *			{
 *				joiningOperator: ',',
 *				fields: [
 *					{
 *						field: 'child',
 *						operator: '==',
 *						data: 3,
 *						joiningOperator: ';',
 *					},
 *					{
 *						field: 'woman',
 *						operator: '!=',
 *						data: 4
 *					}
 *				]
 *			},
 *			{
 *				fields: [
 *					{
 *						field: 'person',
 *						operator: '==',
 *						data: 1,
 *						joiningOperator: ',',
 *					},
 *					{
 *						field: 'child',
 *						operator: '==',
 *						data: 3,
 *						joiningOperator: ';',
 *					},
 *					{
 *						field: 'woman',
 *						operator: '!=',
 *						data: 4
 *					}
 *				]
 *			}
 *		];
 *
	 * @param  {array} filter    uri path
 * @return {string} filter string
 * @memberOf SERVICES.__apiFilter
 */
function create(filter) {
	// Begin filter string
	var filterStr = '';

	// Loop through each filter group
	_each(filter, function(filterGroup) {
		// Begin string for each filter group
		var filterGroupStr = '';
		var filterField;

		// Is group multi or single fielded?
		var filterFields = filterGroup.fields.length > 1 ? 'multi' : 'single';

		// Loop through each filter in each filter group
		_each(filterGroup.fields, function(f) {
			// Get field joining operator if needed (multi fields)
			var fieldJoiningOperator = !_isUndefined(f.joiningOperator) && filterFields === 'multi' ? f.joiningOperator : '';

			// Absolute field or not
			filterField = f.absolute ? '$abs(:' + f.field + ')' : ':' + f.field;

			// Add to filter group string based on field, operator, data and joining operator
			filterGroupStr += filterField + f.operator + '\'' + f.data + '\'' + fieldJoiningOperator;
		});

		// Add paranthesis if group is multi fielded
		filterStr += filterFields === 'multi' ? '(' + filterGroupStr + ')' : filterGroupStr;

		// Add joining operator for each filter group if needed
		filterStr = _isUndefined(filterGroup.joiningOperator) ? filterStr : filterStr + filterGroup.joiningOperator;
	});
	return filterStr;
}

/**
 * @function concatenate
 * @description
 * Concats all filter strings into one object ready for merging as param
 * @param {object} filtersObj Object containing filter info from model
 * @return {object} filter: filter string
 * @memberOf SERVICES.__apiFilter
 */
function concatenate(filtersObj) {
	if (_size(filtersObj) > 0) {
		var filterStr = '';
		filterStr += _isUndefined(filtersObj.query) ? '' : filtersObj.query + ';';
		filterStr += _isUndefined(filtersObj.search) ? '' : filtersObj.search.filter + ';';
		filterStr += _isUndefined(filtersObj.filterBar) ? '' : filtersObj.filterBar + ';';
		filterStr = filterStr.slice(0, -1);
		return { filter: filterStr };
	}
	else return;
}

exports.defaults = defaults;
exports.create = create;
exports.concatenate = concatenate;