export const RESET_STATE =
	'GLOBAL_ADMINISTRATION_MARKET_OVERTIME_RULES/RESET_STATE';
export const SET_MARKET_OVERTIME_RULE =
	'GLOBAL_ADMINISTRATION_MARKET_OVERTIME_RULES/SET_MARKET_OVERTIME_RULE';
export const UPDATE_MARKET_OVERTIME_RULE =
	'GLOBAL_ADMINISTRATION_MARKET_OVERTIME_RULES/UPDATE_MARKET_OVERTIME_RULE';

export const EDIT_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_MARKET_OVERTIME_RULES/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_MARKET_OVERTIME_RULES/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'GLOBAL_ADMINISTRATION_MARKET_OVERTIME_RULES/ADD_BATCH_LIST_ITEM_ROW';

export function setMarketOvertimeRules(payload) {
	return {
		type: SET_MARKET_OVERTIME_RULE,
		payload,
	};
}

export function updateMarketOvertimeRules(payload) {
	return {
		type: UPDATE_MARKET_OVERTIME_RULE,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}
