export default [{
	value: 'Juicer',
	label: 'Juicer'
}, {
	value: 'Bar Manager',
	label: 'Bar Manager'
}, {
	value: 'Shiftplan',
	label: 'Shiftplan'
}, {
	value: 'Admin',
	label: 'Admin'
}];