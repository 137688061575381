'use strict';

import {
	EDIT_PERSON_ATTRIBUTE,
	SET_PERSON_ATTRIBUTE,
	RESET_STATE
} from './personAttributesTable.actions';

const initialState = {
	personAttributeToEdit: {}
};

export default (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
	case SET_PERSON_ATTRIBUTE:
		return {
			...state,
			personAttributeToEdit: payload
		};

	case EDIT_PERSON_ATTRIBUTE:
		return {
			...state,
			personAttributeToEdit: {
				...state.personAttributeToEdit,
				...payload
			}
		};

	case RESET_STATE:
		return {
			...initialState
		};

	default:
		return state;
	}
};
