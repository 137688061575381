'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// rdw actions
import { store } from 'appState';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';

// tables for batch view
import PosConfigurationDiscountsTable from './../../salesConfigurationPosConfigurationDiscounts/salesConfigurationPosConfigurationDiscounts.component';
import IngredientsTable from './../../../../ingredients/components/ingredients/ingredients.component';

// components
import { ModalStepper, Overlay } from 'dumb';

// different batch views
import ModalBatchStepFormDiscounts from './components/modalBatchStepFormDiscounts';
import ModalBatchStepFormIngredients from './components/modalBatchStepFormIngredients';
import ModalBatchStepFormDiscountAmounts from './components/modalBatchStepFormDiscountAmounts';
import ModalBatchStepList from './components/modalBatchStepList';

// phrases
import phrases from './../salesConfigurationPosConfigurationDiscountIngredients.phrases';
import constants from 'services/constants';

// utils
import {
	mapPosConfigurationIngredientsForDiscounts,
	getPosConfigurationIngredientFilter,
	getPosConfigurationDiscountIngredientFilter,
	markSelectedValuesWithCanAdd,
} from './../utils/utils';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { isMobile } from 'detectMobile.vanilla';

// loooodash
import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';
import _uniqBy from 'lodash/uniqBy';

class ModalBatch extends Component {
	constructor(props) {
		super(props);

		this.today = moment.utc().format(constants.shortDate);

		this.state = {
			showOverlay: false,
			overlayType: null,
			legacyFilterOn: true,
			legacyFilter: `:legacy=='false'`,
			allowModalOverflow: true,
		};

		this.handleToggleOverlay = this.handleToggleOverlay.bind(this);
		this.onOverlayOpen = this.onOverlayOpen.bind(this);

		this.modalRef = React.createRef();
	}

	handleToggleOverlay(type) {
		const {
			listPosConfigurationDiscounts,
			listIngredients,
			updateBatchForm,
		} = this.props;

		if (this.state.showOverlay) {
			if (this.state.overlayType === '/pos/pos_configuration_discounts') {
				const selectedRowsInList = _get(
					listPosConfigurationDiscounts,
					'ui.selectedRows',
					[]
				);

				updateBatchForm({
					posConfigurationDiscount: selectedRowsInList.map((entry) => {
						return { label: entry.discount.name, value: entry };
					}),
				});
			} else {
				const selectedRowsInList = _get(listIngredients, 'ui.selectedRows', []);

				updateBatchForm({
					ingredients: selectedRowsInList.map((entry) => {
						return { label: entry.name, value: entry };
					}),
				});
			}
		}

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
			overlayType: prevState.overlayType ? '' : type,
		}));
	}

	onOverlayOpen() {
		const { batchFormData } = this.props;
		const { overlayType } = this.state;

		const dataToSetColumnsSelected =
			overlayType === '/pos/pos_configuration_discounts'
				? batchFormData.posConfigurationDiscount
				: batchFormData.ingredients;

		if (_isEmpty(dataToSetColumnsSelected)) return;

		// set already selected data in rdw store as clicked
		dataToSetColumnsSelected.map((entry) => {
			store.dispatch(
				actions.addRowToSelection({
					reduxKey: overlayType,
					data: entry.value,
				})
			);
		});
	}

	_onSubmitForm() {
		const {
			batchFormData,
			addBatchList,
			fetchPosConfigurationIngredients,
			fetchPosConfigurationDiscountIngredients,
		} = this.props;

		this.setState(() => ({ loadingModal: true }));

		// get rid of duplicate discounts and ingredients (we can have duplicates because of the tables)
		const cleanDiscounts = _uniqBy(
			batchFormData.posConfigurationDiscount,
			'value.id'
		);
		const cleanIngredients = _uniqBy(batchFormData.ingredients, 'value.id');

		const listArray = cleanDiscounts.reduce((acc, x) => {
			const list = cleanIngredients.map((y) => {
				return {
					identifier: _uniqueId('row-'), // used to identify what to edit on step#4
					posConfigurationDiscount: x.value,
					ingredient: y.value,
				};
			});

			return [...acc, ...list];
		}, []);

		// create super cool filter that will fetch pos config ingredients - we might not get all of them since they technically don't have to exist
		const posConfigurationIngredientFilter = getPosConfigurationIngredientFilter(
			{
				discountsArray: cleanDiscounts,
				ingredientsArray: cleanIngredients,
			}
		);

		const fetchCompleteCollection = true;

		fetchPosConfigurationIngredients(
			{
				filter: posConfigurationIngredientFilter,
				limit: 300,
			},
			fetchCompleteCollection
		).then((res) => {
			if (_isEmpty(res.data)) {
				setFeedback(
					phrases.MODAL_BATCH_NO_POS_CONFIGURATION_INGREDIENTS_FOUND,
					0
				);

				this.setState(() => ({ loadingModal: false }));

				return;
			}

			// add pos configuration ingredient to mapped data
			const listArrayWithPosConfigurationIngredientIds = mapPosConfigurationIngredientsForDiscounts(
				{
					discountData: listArray,
					posConfigurationIngredients: res.data,
					batchFormData,
				}
			);

			// filter that gets all existing discount ingredients for our selected pos configuration discounts and pos config. ingredients
			const posConfigurationDiscountIngredientFilter = getPosConfigurationDiscountIngredientFilter(
				{
					ingredients: cleanIngredients,
					posConfigurationDiscount: batchFormData.posConfigurationDiscount,
				}
			);

			fetchPosConfigurationDiscountIngredients(
				posConfigurationDiscountIngredientFilter,
				fetchCompleteCollection
			).then((res) => {
				// if pos config dis ing already exists, add 'canAdd: false'
				const filteredListArray = markSelectedValuesWithCanAdd({
					list: listArrayWithPosConfigurationIngredientIds,
					posConfigurationDiscountIngredient: res.data,
				});

				addBatchList(filteredListArray);

				this.setState(() => ({ loadingModal: false }));

				this._goToStep(4);
			});
		});
	}

	_goToStep(step) {
		if (step === 4) this.setState(() => ({ allowModalOverflow: false }));
		if (step !== 4 && this.state.allowModalOverflow === false)
			this.setState(() => ({ allowModalOverflow: true }));

		this.modalRef.current.goToStep(step);
	}

	_batchSubmit() {
		const {
			batchListData,
			handleClose,
			addPosConfigurationDiscountIngredient,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchListData
			// remove all values that have a canAdd flag set to false
			.filter((entry) => entry.canAdd)
			// map through the rest and construct a payload object
			.map((entry) => {
				return {
					pos_configuration_discount: _get(
						entry,
						'posConfigurationDiscount.id',
						null
					),
					pos_configuration_ingredient: _get(
						entry,
						'posConfigurationIngredient.id',
						null
					),
					to_stay_discount_price: _get(entry, 'toStayDiscount', 0).replace(
						',',
						'.'
					),
					to_go_discount_price: _get(entry, 'toGoDiscount', 0).replace(
						',',
						'.'
					),
				};
			});

		addPosConfigurationDiscountIngredient({ batch: payload })
			.then(() => {
				handleClose();
				this.setState(() => ({
					loadingModal: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	getProductVariantsFilter() {
		const { batchFormData } = this.props;
		const { legacyFilterOn, legacyFilter } = this.state;

		let filterStarter =
			':discount_ingredients.discount.pos_configuration_discounts=in=[';

		filterStarter = legacyFilterOn
			? `${legacyFilter};${filterStarter}`
			: filterStarter;

		return _get(batchFormData, 'posConfigurationDiscount', []).reduce(
			(acc, currentValue, index) => {
				acc =
					batchFormData.posConfigurationDiscount.length - 1 === index
						? `${acc}'${currentValue.value.id}']`
						: `${acc}'${currentValue.value.id}',`;

				return acc;
			},
			filterStarter
		);
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchListData,
			editBatchListItem,
			removeBatchListItem,
		} = this.props;
		const { showOverlay } = this.state;

		const ingredientsFilter = this.getProductVariantsFilter();

		const steps = [
			{
				component: (
					<ModalBatchStepFormDiscounts
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.handleToggleOverlay}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_DISCOUNTS,
				isValid: !_isEmpty(batchFormData.posConfigurationDiscount),
				onNext: () => this._goToStep(2),
				loading: false,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_SELECT_INGREDIENTS,
				noScroll: false,
			},
			{
				component: (
					<ModalBatchStepFormIngredients
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.handleToggleOverlay}
						ingredientsFilter={ingredientsFilter}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_INGREDIENTS,
				isValid: !_isEmpty(batchFormData.ingredients),
				onNext: () => this._goToStep(3),
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel:
					phrases.MODAL_BATCH_STEP_FORM_SELECT_DISCOUNT_AMOUNTS,
				cancelButtonLabel:
					phrases.MODAL_BATCH_STEP_FORM_INGREDIENTS_BACK_BUTTON_LABEL,
				noScroll: false,
			},
			{
				component: (
					<ModalBatchStepFormDiscountAmounts
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_DISCOUNT_AMOUNT,
				isValid: true,
				onNext: () => this._onSubmitForm(),
				onBack: () => this._goToStep(2),
				loading: this.state.loadingModal,
				confirmButtonLabel:
					phrases.MODAL_BATCH_STEP_FORM_DISCOUNT_AMOUNTS_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel:
					phrases.MODAL_BATCH_STEP_FORM_DISCOUNT_AMOUNTS_BACK_BUTTON_LABEL,
				noScroll: false,
			},
			{
				component: (
					<ModalBatchStepList
						batchListData={batchListData}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				onBack: () => this._goToStep(3),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						className="modal-batch"
						ref={this.modalRef}
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={showOverlay ? () => {} : handleClose}
						allowOverflow={this.state.allowModalOverflow}
					/>
				)}

				<Overlay
					zIndex={551}
					height={isMobile() ? 480 : 680}
					visible={showOverlay}
					list
					onClose={() => {
						this.handleToggleOverlay();
					}}
					render={(height) => {
						return (
							<>
								{this.state.overlayType === '/product/ingredients' && (
									<IngredientsTable
										batchSelection
										defaultPageSize={20}
										onInitialization={() => this.onOverlayOpen()}
										reduxKey={this.state.overlayType}
										style={{
											maxHeight: `${height - 130}px`,
										}}
									/>
								)}
								{this.state.overlayType ===
									'/pos/pos_configuration_discounts' && (
									<PosConfigurationDiscountsTable
										batchSelection
										defaultPageSize={20}
										onInitialization={() => this.onOverlayOpen()}
										reduxKey={this.state.overlayType}
										style={{
											maxHeight: `${height - 130}px`,
										}}
									/>
								)}
							</>
						);
					}}
				/>
			</>
		);
	}
}

ModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	listPosConfigurationDiscounts: PropTypes.object,
	listIngredients: PropTypes.object,
	addBatchList: PropTypes.func,
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addPosConfigurationDiscountIngredient: PropTypes.func,
	fetchPosConfigurationDiscountIngredients: PropTypes.func,
	fetchPosConfigurationIngredients: PropTypes.func,
};

export default ModalBatch;
