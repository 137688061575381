import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SingleDatePicker from 'react-dates/lib/components/SingleDatePicker';
import DateInputWrapper from '../components/inputWrapper';
import DateWeekNumber from '../components/weekNumber';

import { renderCustomCaption } from '../dates.utils';
import phrases from '../dates.phrases';
import moment from 'moment';
import './singleDatePicker.css';

export default class DateSingleDatePicker extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			focused: null,
			date: null,
		};

		// Binds
		this.onSingleDateChange = this.onSingleDateChange.bind(this);
		// this.renderCustomCaption = this.renderCustomCaption.bind(this);
	}

	onSingleDateChange(date) {
		const { onChange } = this.props;

		this.setState(
			() => ({ date, focused: false }),
			() => {
				onChange(date);
			}
		);
	}

	initialVisibleMonth(date) {
		if (!moment.isMoment(date) || moment(date).isSame('9999-12-31', 'year')) return moment();
		else return date;
	}

	render() {
		const {
			// selectedDate,
			className,
			showWeekNumber,
			disabled,
			withPortal,
			appendToBody,
			numberOfMonths,
			placeholder,
		} = this.props;
		const { date, focused } = this.state;

		const classN = classnames('j-date-single-date-picker', {
			[`${className}`]: className,
		});

		return (
			<DateInputWrapper className={classN}>
				<SingleDatePicker
					id={moment().toISOString()}
					// date={selectedDate || date}
					date={date} // momentPropTypes.momentObj or null
					onDateChange={this.onSingleDateChange}
					numberOfMonths={numberOfMonths}
					focused={focused}
					onFocusChange={({ focused }) => this.setState({ focused })} // isRequired
					renderDayContents={(day) => {
						return <DateWeekNumber showWeekNumber={showWeekNumber} day={day} />;
					}}
					initialVisibleMonth={() => this.initialVisibleMonth(date)}
					disabled={disabled}
					withPortal={withPortal}
					placeholder={placeholder}
					appendToBody={appendToBody}
					hideKeyboardShortcutsPanel
					renderMonthElement={renderCustomCaption}
				/>
			</DateInputWrapper>
		);
	}
}

DateSingleDatePicker.defaultProps = {
	appendToBody: false,
	placeholder: phrases.DATE_PLACEHOLDER,
	numberOfMonths: 2,
	withPortal: true,
	showWeekNumber: false,
};

DateSingleDatePicker.propTypes = {
	className: PropTypes.string,

	// Predefined initial states
	// selectedDate: PropTypes.object,
	disabled: PropTypes.bool,
	numberOfMonths: PropTypes.number,
	placeholder: PropTypes.string,

	// Function & Methods
	onChange: PropTypes.func,

	// Extra options
	showWeekNumber: PropTypes.bool,
	withPortal: PropTypes.bool,
	appendToBody: PropTypes.bool,
};
