export const RESET_STATE = 'GLOBAL_ADMINISTRATION_LANGUAGES/RESET_STATE';
export const SET_LANGUAGES = 'GLOBAL_ADMINISTRATION_LANGUAGES/SET_LANGUAGES';
export const UPDATE_LANGUAGES =
	'GLOBAL_ADMINISTRATION_LANGUAGES/UPDATE_LANGUAGES';

export const EDIT_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_LANGUAGES/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_LANGUAGES/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'GLOBAL_ADMINISTRATION_LANGUAGES/ADD_BATCH_LIST_ITEM_ROW';

export function setLanguages(payload) {
	return {
		type: SET_LANGUAGES,
		payload,
	};
}

export function updateLanguages(payload) {
	return {
		type: UPDATE_LANGUAGES,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}
