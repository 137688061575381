export const RESET_STATE =
	'GLOBAL_ADMINISTRATION_MARKET_SHIFT_ALIGNMENT_RULES/RESET_STATE';
export const SET_MARKET_SHIFT_ALIGNMENT_RULES =
	'GLOBAL_ADMINISTRATION_MARKET_SHIFT_ALIGNMENT_RULES/SET_MARKET_SHIFT_ALIGNMENT_RULES';
export const UPDATE_MARKET_SHIFT_ALIGNMENT_RULES =
	'GLOBAL_ADMINISTRATION_MARKET_SHIFT_ALIGNMENT_RULES/UPDATE_MARKET_SHIFT_ALIGNMENT_RULES';

export const EDIT_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_MARKET_SHIFT_ALIGNMENT_RULES/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_MARKET_SHIFT_ALIGNMENT_RULES/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'GLOBAL_ADMINISTRATION_MARKET_SHIFT_ALIGNMENT_RULES/ADD_BATCH_LIST_ITEM_ROW';

export function setMarketShiftAlignmentRules(payload) {
	return {
		type: SET_MARKET_SHIFT_ALIGNMENT_RULES,
		payload,
	};
}

export function updateMarketShiftAlignmentRules(payload) {
	return {
		type: UPDATE_MARKET_SHIFT_ALIGNMENT_RULES,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}
