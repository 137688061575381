'use strict';

/**
 * @namespace __endpoints
 * @memberOf SERVICES
 * @description
 * Wrapper for endpoints
 * All methods and data related to endpoints
 */

import config from 'config';
var collection = require('endpoints');

var apiHost = config.api.protocol + '://' + config.api.host;
var apiUri = config.api.version
	? config.api.path + config.api.version
	: config.api.path;

exports.apiHost = apiHost;
exports.apiUri = apiUri;
exports.collection = collection;
