'use strict';

import cx from 'classnames';
import React from 'react';

import { ButtonLoader, Icon } from 'dumb';

const PrintView = props => {
	const {
		active,
		ComponentToPrint,
		handleClose,
		mailLoading,
		mailMethod,
		pdfLoading,
		pdfMethod,
		phrases,
		showMailToComponent,
		showXLSX,
		xslxLoading,
		xslxMethod
	} = props;

	return (
		<div className={cx('print-view', { 'print-view--active': active })}>
			<a onClick={handleClose} className="print-view__close">
				<Icon name="close" />
			</a>
			<div className="print-view__meta-wrapper">
				<h2>{phrases.PRINT_VIEW}</h2>
				<aside className="print-view__actions">
					<a onClick={pdfMethod} data-label={phrases.CONTROLS.PDF}>
						{!pdfLoading && <Icon name="file_download" />}
						<ButtonLoader loading={pdfLoading} theme="dark" />
					</a>
					{showXLSX && (
						<a onClick={xslxMethod} data-label={phrases.CONTROLS.XLSX}>
							{!xslxLoading && <Icon name="file_download" />}
							<ButtonLoader loading={xslxLoading} theme="dark" />
						</a>
					)}
					{showMailToComponent && (
						<a onClick={mailMethod} data-label={phrases.CONTROLS.MAIL}>
							{!mailLoading && <Icon name="mail" />}
							<ButtonLoader loading={mailLoading} theme="dark" />
						</a>
					)}
				</aside>
			</div>
			<ComponentToPrint {...props} />
		</div>
	);
};

export default PrintView;
