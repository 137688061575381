/* eslint-disable indent */

// Actions
import {
	SET_MAPPED_DATA,
	SET_META_DATA,
	RESET_DATA,
} from './dynamics365Matrix.actions';

// Interfaces
import { ActionType } from 'types/redux';
import {
	IDynamics365MatrixInitialState,
	DynamicsMappedData,
	DynamicsMetaData,
} from '../types';

const initialState: IDynamics365MatrixInitialState = {
	mappedData: undefined,
	metaData: undefined,
};

function reducer(
	state = initialState,
	action: ActionType
): IDynamics365MatrixInitialState {
	const { type, payload } = action;

	switch (type) {
		case SET_MAPPED_DATA:
			return {
				...state,
				mappedData: payload as DynamicsMappedData[],
			};

		case SET_META_DATA:
			return {
				...state,
				metaData: payload as DynamicsMetaData,
			};

		case RESET_DATA:
			return initialState;

		default:
			return state;
	}
}

export default reducer;
