'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CustomInputService from './customInput.service';

import DirectInput from 'reusableComponents/directInput/directInput.component';

import _has from 'lodash/has';
import _every from 'lodash/every';
import _some from 'lodash/some';
import _debounce from 'lodash/debounce';
import _isObject from 'lodash/isObject';
import _isArray from 'lodash/isArray';

import './customInput.css';

class CustomInput extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isTouched: false,
			isValid: {
				regex: true,
				required: true
			}
		};

		this.changeTimer = null;

		this.CustomInputService = new CustomInputService();

		this.onBlur = this.onBlur.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onChangeDebounce = this.onChangeDebounce.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
	}

	updateHeader(event) {
		const { header, row, onChange } = this.props;

		// Get value based on input type
		const inputFamily = this.CustomInputService.getInputFamily(header.type);
		let value;
		switch (inputFamily) {
		case 'input':
			value = event.target.value;
			break;
		case 'checkbox':
			value = event.target.checked;
			break;
		case 'date':
			value = event;
			break;
		case 'select':
			value = _has(event, 'value') ? event.value : null;
			break;
		}

		// Get validity
		const valid = _every(this.getValidity(value));

		// Proceed of valid and isTouched
		if (valid && (this.state.isTouched || inputFamily === 'checkbox')) {
			// Get name
			let name = _has(header, 'reference')
				? header.reference.newName
				: header.name;

			// Define headers
			const headers = {
				[name]: Object.assign(header, { data: value })
			};

			// Send to onChange
			onChange({ headers, row });
		}
	}

	onKeyDown() {
		// Kept for later
	}

	onBlur(event) {
		// Set state isTouched
		this.setState({ isTouched: true });

		// Kill changeTimer
		clearTimeout(this.changeTimer);

		// Update header
		this.updateHeader(event);
	}

	onChangeDebounce(event) {
		// Send event to onChange
		if (_has(event, 'persist')) event.persist();
		this.onChange(event);

		// Set state isTouched
		this.setState({ isTouched: true });
	}

	onChange(event) {
		// Initiate changeTimer (autosave)
		this.changeTimer = setTimeout(() => {
			// Update header
			this.updateHeader(event);
		}, 1000);
	}

	getValidity(value) {
		// Does header need to test a regex
		const regex =
			_has(this.props.header, 'regex') && value
				? this.props.header.regex.expression.test(value)
				: true;

		// Is header required
		const required = _has(this.props.header, 'required') ? value : true;

		// Test
		const isValid = {
			regex,
			required
		};

		// Set state and return isValid
		this.setState({ isValid });
		return isValid;
	}

	getInputCx(header) {
		const validityExpressions = [
			!_every(this.state.isValid),
			header.fieldError
		];

		return cx('data-input', 'custom-input', {
			'data-input--currency': header.type === 'currency',
			'state--invalid': _some(validityExpressions)
		});
	}

	getConfigurationObject(header) {
		const inputFamily = this.CustomInputService.getInputFamily(header.type);
		const input = header.customInput;
		switch (inputFamily) {
		case 'select':
			return {
				labelTemplate: _has(input, 'label') ? input.label : '<%= name %>',
				name: input.name,
				options:
						_has(input, 'options') && _isArray(input.options)
							? input.options
							: undefined,
				searchFields: _has(header.reference, 'searchFields')
					? header.reference.searchFields
					: undefined,
				searchList:
						_has(input, 'options') && _isObject(input.options)
							? input.options
							: undefined,
				searchListData: _has(input, 'searchListData')
					? input.searchListData
					: undefined,
				searchListFilter: _has(input, 'searchListFilter')
					? input.searchListFilter
					: undefined
			};
		}
	}

	renderFeedback(header) {
		let errorMsg;

		// Find type of feedback
		if (this.state.isTouched && _has(header, 'fieldError'))
			errorMsg = header.fieldError;
		else if (!this.state.isValid.regex) errorMsg = header.regex.error;
		else if (!this.state.isValid.required)
			errorMsg = this.props.translations.REQUIRED;

		// Get cx
		const feedbackCx = cx('data-input__feedback', {
			'data-input__feedback--error':
				(this.state.isTouched && !_every(this.state.isValid)) ||
				header.fieldError
		});

		return <span className={feedbackCx}>{errorMsg}</span>;
	}

	componentDidMount() {
		// Add debounce for onChange event
		this.onChange = _debounce(this.onChange, 200);
	}

	render() {
		const { row, header } = this.props;

		// Get value
		var initialValue = _has(header.customInput, 'defaultValue')
			? header.customInput.defaultValue(row)
			: null;

		return (
			<span className="list__form-field list__cell-contents">
				<span className={this.getInputCx(header)}>
					<DirectInput
						inputFamily={this.CustomInputService.getInputFamily(header.type)}
						inputFamilyMember={header.type}
						config={this.getConfigurationObject(header)}
						onBlur={this.onBlur}
						onChange={this.onChangeDebounce}
						onKeyDown={this.onKeyDown}
						initialValue={initialValue}
					/>
					{this.renderFeedback(header)}
				</span>
			</span>
		);
	}
}

CustomInput.propTypes = {
	row: PropTypes.object,
	header: PropTypes.object,
	onChange: PropTypes.func.isRequired
};

export default CustomInput;
