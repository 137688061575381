'use strict';

import { HotbarComponentFactory as h } from 'hotdamnbar/factory';
import { HotbarEndpointDataProvider } from 'hotdamnbar/dataProviders';

const ReportingCurrenciesDataProvider = new HotbarEndpointDataProvider(
	'/reporting/reporting_currencies'
);

// Set option format
ReportingCurrenciesDataProvider.setOptionFormat((entry, resultSet) => ({
	value: entry.id,
	label: entry.currency.code
}));

const EmployeeMealsReport = h.hotbar('employeeMealsReportHotbar');

EmployeeMealsReport.add(h.dateRange('Period', true, true));
EmployeeMealsReport.add(
	h.select('Reporting Currency', true, ReportingCurrenciesDataProvider)
);

export default EmployeeMealsReport;
