'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// rdw actions
import { store } from 'appState';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';
import { batch } from 'react-redux';

// components
import { ModalStepper, Overlay } from 'dumb';

// different batch views
import PosConfigurationVersionsBatchStepListDuplicateAsNew from './components/posConfigurationVersionsBatchStepListDuplicateAsNew';

// table components for overlay
import PosConfigurationsTable from './../../../../../pos/components/posConfigurations/posConfigurations.component';
import TileLayoutsTable from './../../../../../layout/tileLayouts/tileLayout.component';

// phrases/ constants
import phrases from './../../posConfigurationVersions.phrases';
import constants from 'services/constants';
import moment from 'moment';

// utils
import { handleToggleOverlayHelper } from './utils';

// lodash
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

// styles
import './posConfigurationVersionsModalBatchDuplicateAsNew.css';

class PosConfigurationVersionsModalBatchDuplicateAsNew extends Component {
	constructor(props) {
		super(props);

		this.posConfigurationReduxKey =
			'posConfigurationVersionBatch/pos/pos_configurations';
		this.posLayoutReduxKey =
			'posConfigurationVersionBatch-pos/pos/tile_layouts';
		this.appLayoutReduxKey =
			'posConfigurationVersionBatch-app/pos/tile_layouts';

		this.state = {
			loadingModal: false,
			showOverlay: false,
			overlayType: null,
		};

		this.handleToggleOverlay = this.handleToggleOverlay.bind(this);
		this.onOverlayOpen = this.onOverlayOpen.bind(this);
		this.updateBatchFormWrapper = this.updateBatchFormWrapper.bind(this);
		this.submitRow = this.submitRow.bind(this);

		this.modalRef = React.createRef();
	}

	handleToggleOverlay(type) {
		const {
			listPosConfigurations,
			listPosTileLayout,
			listAppTileLayout,
		} = this.props;

		if (this.state.showOverlay) {
			const data = handleToggleOverlayHelper({
				overlayType: this.state.overlayType,
				posConfigurationReduxKey: this.posConfigurationReduxKey,
				listPosConfigurations,
				posLayoutReduxKey: this.posLayoutReduxKey,
				listPosTileLayout,
				appLayoutReduxKey: this.appLayoutReduxKey,
				listAppTileLayout,
			});

			if (!_isEmpty(data)) this.updateBatchFormWrapper(data);
		}

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
			overlayType: prevState.overlayType ? '' : type,
		}));
	}

	onOverlayOpen() {
		const { batchFormData } = this.props;

		let dataToSetColumnsSelected;
		switch (this.state.overlayType) {
			case this.posConfigurationReduxKey:
				dataToSetColumnsSelected = batchFormData.posConfiguration;
				break;
			case this.appLayoutReduxKey:
				dataToSetColumnsSelected = batchFormData.appTileLayout;
				break;
			case this.posLayoutReduxKey:
				dataToSetColumnsSelected = batchFormData.posTileLayout;
				break;
			default:
				break;
		}

		if (_isEmpty(dataToSetColumnsSelected)) return;

		// set already selected data in rdw store as clicked
		store.dispatch(
			actions.addRowToSelection({
				reduxKey: this.state.overlayType,
				data: dataToSetColumnsSelected.value,
			})
		);
	}

	submitRow(row) {
		const {
			batchAddData,
			editBatchListItem,
			setBatchListItemLoading,
		} = this.props;

		setBatchListItemLoading(row.identifier);

		const payload = {
			pos_configuration_version: row.identifier,
			pos_configuration: _get(row, 'posConfiguration.value.id', null),
			name: row.name,
			description: row.description,
			state: _get(row, 'state.value', null),
			...(row.appTileLayout && {
				app_tile_layout: row.appTileLayout.value.id,
			}),
			...(row.posTileLayout && {
				pos_tile_layout: row.posTileLayout.value.id,
			}),
			...(row.deliveryTileLayout && {
				delivery_tile_layout: row.deliveryTileLayout.value.id,
			}),
			active: {
				...(row.activeFrom && {
					from: moment.utc(row.activeFrom).format(constants.shortDate),
				}),
				...(row.activeTo && {
					to: moment.utc(row.activeTo).format(constants.shortDate),
				}),
			},
		};

		batchAddData(payload)
			.then(() => {
				batch(() => {
					setBatchListItemLoading(null);
					editBatchListItem({
						...row,
						rowSubmitted: true,
					});
				});
			})
			.catch(() => {
				setBatchListItemLoading(null);
			});
	}

	updateBatchFormWrapper(data) {
		const { updateBatchForm, editBatchListItems } = this.props;

		batch(() => {
			editBatchListItems(data);
			updateBatchForm(data);
		});
	}

	render() {
		const {
			modalVisible,
			batchFormData,
			handleClose,
			batchListData,
			editBatchListItem,
			removeBatchListItem,
			revertBatchListItem,
			batchListItemLoadingId,
		} = this.props;

		const steps = [
			{
				component: (
					<PosConfigurationVersionsBatchStepListDuplicateAsNew
						batchListData={batchListData}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
						updateBatchForm={this.updateBatchFormWrapper}
						loyaltyProductUrl={this.loyaltyProductUrl}
						batchFormData={batchFormData}
						posConfigurationReduxKey={this.posConfigurationReduxKey}
						posLayoutReduxKey={this.posLayoutReduxKey}
						appLayoutReduxKey={this.appLayoutReduxKey}
						toggleOverlay={this.handleToggleOverlay}
						loading={this.state.loadingModal}
						revertBatchListItem={revertBatchListItem}
						submitRow={this.submitRow}
						batchListItemLoadingId={batchListItemLoadingId}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_DUPLICATE_AS_NEW_LIST_TITLE,
				isValid: true,
				onNext: () => handleClose(),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CLOSE_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						ref={this.modalRef}
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={handleClose}
					/>
				)}
				<Overlay
					zIndex={551}
					height={850}
					list
					visible={this.state.showOverlay}
					onClose={() => {
						this.handleToggleOverlay();
					}}>
					{this.state.overlayType === this.posConfigurationReduxKey && (
						<PosConfigurationsTable
							batchSelection
							reduxKey={this.state.overlayType}
							onInitialization={this.onOverlayOpen}
							defaultPageSize={20}
							style={{
								maxHeight: '720px',
							}}
							enableSingleSelection
						/>
					)}
					{this.state.overlayType === this.appLayoutReduxKey && (
						<TileLayoutsTable
							batchSelection
							reduxKey={this.state.overlayType}
							onInitialization={this.onOverlayOpen}
							defaultPageSize={20}
							style={{
								maxHeight: '720px',
							}}
							enableSingleSelection
							type="App"
						/>
					)}
					{this.state.overlayType === this.posLayoutReduxKey && (
						<TileLayoutsTable
							batchSelection
							reduxKey={this.state.overlayType}
							onInitialization={this.onOverlayOpen}
							defaultPageSize={20}
							style={{
								maxHeight: '720px',
							}}
							enableSingleSelection
							type="POS"
						/>
					)}
				</Overlay>
			</>
		);
	}
}

PosConfigurationVersionsModalBatchDuplicateAsNew.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	batchAddData: PropTypes.func,
	revertBatchListItem: PropTypes.func,
	editBatchListItems: PropTypes.func,
	setBatchListItemLoading: PropTypes.func,
	batchListItemLoadingId: PropTypes.number,

	listPosConfigurations: PropTypes.object,
	listPosTileLayout: PropTypes.object,
	listAppTileLayout: PropTypes.object,
};

export default PosConfigurationVersionsModalBatchDuplicateAsNew;
