// redux
import { store } from 'appState';
import actions from 'shiftPlanner/actions';

// utils
import { ShiftTransfer as ShiftTransferEnums } from 'services/enums';
import { getShiftActionToPerform } from 'services/shiftTransfer';

export function conditionallyUpdateShifts({ shiftToUpdate }) {
	// get redux state
	const state = _getState();

	const isMounted = state.shiftPlanner.isMounted;

	if (!isMounted) return;

	const {
		workplaceEmployments,
		currentStartOfPeriod,
		endOfPeriod,
		shifts,
	} = state.shiftPlanner;

	const shiftAction = getShiftActionToPerform({
		shiftToUpdate,
		persons: workplaceEmployments,
		currentStartOfPeriod,
		endOfPeriod,
		shifts,
		isShiftplanner: true,
	});

	if (!shiftAction) return;

	if (shiftAction === ShiftTransferEnums.SHIFT_ACTIONS.UPDATE) {
		store.dispatch(actions.addUpdatedShift(shiftToUpdate));
	}
}

function _getState() {
	return store.getState();
}
