import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import ListComponent from 'common/components/list/list.component';

import WorkplaceAddress from './components/workplaceAddress/workplaceAddress.container.js';
import CompanyToWorkplacesList from './services/companyToWorkplacesList.service.js';
import WorkplaceTillStationsList from './services/workplaceTillStationsList.service.js';
import WorkplaceSizesList from './services/workplaceSizesList.service.js';
import WorkplaceIndexAdjustmentsList from './services/workplaceIndexAdjustmentsList.service.js';
import WorkplaceAttributesList from './services/workplaceAttributesList.service.js';
import WorkplaceThirdPartyReferencesList from './services/workplaceThirdPartyReferencesList.service.js';
import WorkplaceSalaryAccounts from './services/workplaceSalaryAccountsList.service';

import WorkplacePosConfigurationRelationsComponent from './components/workplacePosConfigurationRelationWrapper/workplacePosConfigurationRelationWrapper.js';
import WorkplaceOperationalResponsibilities from './components/workplaceOperationalResponsibilities/workplaceOperationalResponsibilities.component.jsx';
import WorkplaceSystemSoftwares from './components/workplaceSystemSoftwares/workplaceSystemSoftwares.container.jsx';
import OpeningHours from './components/openingHours/openingHours.loader.js';

import StoreFrontImage from './components/storeFrontImage/storeFrontImage';

const workplaceSalaryAccountsListReduxKey =
	'/accounting/workplace_salary_accounts';
const workplaceSalaryAccountsList = new WorkplaceSalaryAccounts(
	workplaceSalaryAccountsListReduxKey
);
const workplaceSalaryAccountsListProps = workplaceSalaryAccountsList.getProps();

const companyToWorkplacesListReduxKey =
	'/administration/workplace_company_to_workplace_relations';
const companyToWorkplacesList = new CompanyToWorkplacesList(
	companyToWorkplacesListReduxKey
);
const companyToWorkplacesListProps = companyToWorkplacesList.getProps();

const workplaceTillStationsListReduxKey = '/pos/till_stations';
const workplaceTillStationsList = new WorkplaceTillStationsList(
	workplaceTillStationsListReduxKey
);
const workplaceTillStationsListProps = workplaceTillStationsList.getProps();

const workplaceSizesListReduxKey = '/administration/workplace_sizes';
const workplaceSizesList = new WorkplaceSizesList(workplaceSizesListReduxKey);
const workplaceSizesListProps = workplaceSizesList.getProps();

const workplaceIndexAdjustmentsListReduxKey = '/financial/store_tags';
const workplaceIndexAdjustmentsList = new WorkplaceIndexAdjustmentsList(
	workplaceIndexAdjustmentsListReduxKey
);
const workplaceIndexAdjustmentsListProps =
	workplaceIndexAdjustmentsList.getProps();

const workplaceAttributesListReduxKey = '/administration/workplace_attributes';
const workplaceAttributesList = new WorkplaceAttributesList(
	workplaceAttributesListReduxKey
);
const workplaceAttributesListProps = workplaceAttributesList.getProps();

const workplaceThirdPartyReferencesListReduxKey =
	'/administration/workplace_third_party_references';
const workplaceThirdPartyReferencesList = new WorkplaceThirdPartyReferencesList(
	workplaceThirdPartyReferencesListReduxKey
);
const workplaceThirdPartyReferencesListProps =
	workplaceThirdPartyReferencesList.getProps();

export const WorkplaceDetails = () => {
	const { workplaceId } = useParams();

	const workplaceIdFilter = `:workplace.id=='${workplaceId}'`;

	useEffect(() => {
		workplaceSalaryAccountsList.setFilterQuery(workplaceIdFilter);
		workplaceSalaryAccountsList.setData({ workplace: workplaceId });
		workplaceSalaryAccountsList.initView();

		companyToWorkplacesList.setFilterQuery(workplaceIdFilter);
		companyToWorkplacesList.setData({ workplace: workplaceId });
		companyToWorkplacesList.initView();

		workplaceTillStationsList.setFilterQuery(workplaceIdFilter);
		workplaceTillStationsList.setData({ workplace: workplaceId });
		workplaceTillStationsList.initView();

		workplaceSizesList.setFilterQuery(workplaceIdFilter);
		workplaceSizesList.setData({ workplace: workplaceId });
		workplaceSizesList.initView();

		workplaceIndexAdjustmentsList.setFilterQuery(workplaceIdFilter);
		workplaceIndexAdjustmentsList.setData({ workplace: workplaceId });
		workplaceIndexAdjustmentsList.initView();

		workplaceAttributesList.setFilterQuery(workplaceIdFilter);
		workplaceAttributesList.setData({ workplace: workplaceId });
		workplaceAttributesList.initView();

		workplaceThirdPartyReferencesList.setFilterQuery(workplaceIdFilter);
		workplaceThirdPartyReferencesList.setData({ workplace: workplaceId });
		workplaceThirdPartyReferencesList.initView();
	}, [workplaceId, workplaceIdFilter]);

	return (
		<>
			<StoreFrontImage workplaceId={workplaceId} />

			<WorkplaceAddress workplaceId={workplaceId} />

			<ListComponent
				reduxKey={companyToWorkplacesListReduxKey}
				{...companyToWorkplacesListProps}
			/>

			<WorkplacePosConfigurationRelationsComponent workplaceId={workplaceId} />

			<ListComponent
				reduxKey={workplaceTillStationsListReduxKey}
				{...workplaceTillStationsListProps}
			/>

			<OpeningHours workplaceId={workplaceId} />

			<ListComponent
				reduxKey={workplaceSalaryAccountsListReduxKey}
				{...workplaceSalaryAccountsListProps}
			/>

			<ListComponent
				reduxKey={workplaceSizesListReduxKey}
				{...workplaceSizesListProps}
			/>

			<ListComponent
				reduxKey={workplaceIndexAdjustmentsListReduxKey}
				{...workplaceIndexAdjustmentsListProps}
			/>

			<ListComponent
				reduxKey={workplaceAttributesListReduxKey}
				{...workplaceAttributesListProps}
			/>

			<ListComponent
				reduxKey={workplaceThirdPartyReferencesListReduxKey}
				{...workplaceThirdPartyReferencesListProps}
			/>

			<WorkplaceOperationalResponsibilities workplaceId={workplaceId} />
			<WorkplaceSystemSoftwares workplaceId={workplaceId} />
		</>
	);
};

WorkplaceDetails.propTypes = {
	workplaceId: PropTypes.number,
};
