import React from 'react';
import PropTypes from 'prop-types';

// components
import { Button, Input, Icon } from 'dumb';
import Color from 'reusableComponents/inputs/color.component';

// styles
import './colorPicker.css';

export default function ColorPicker(props) {
	const { isOpen, onClick, onColorChange, value } = props;

	return (
		<>
			<div className="flairs__row__color">
				<div className="flairs__row__color__wrapper">
					<Button
						className="flairs__row__color__wrapper__button"
						type="inverted"
						shadow
						onClick={onClick}>
						<Icon name="tune" />
					</Button>
					<div
						className="flairs__row__color__wrapper__color-indicator"
						style={{ backgroundColor: `#${value}` }}
						onClick={onClick}
					/>
				</div>
				<div className="flairs__row__color__input-wrapper">
					<Input
						value={`#${value || ''}`}
						onChange={e => onColorChange(e.target.value.replace('#', ''))}
						clearable
					/>
					<Icon
						className="flairs__row__color__input-wrapper__close-icon"
						name="close"
						onClick={() => onColorChange('')}
					/>
				</div>
			</div>
			{isOpen && (
				<div className="flairs__color-picker">
					<Color
						onChange={e => onColorChange(e.hex.replace('#', ''))}
						triangle="hide"
						type="Twitter"
						color={`#${value}`}
					/>
				</div>
			)}
		</>
	);
}

ColorPicker.propTypes = {
	isOpen: PropTypes.bool,
	onClick: PropTypes.func,
	onColorChange: PropTypes.func,
	value: PropTypes.string
};
