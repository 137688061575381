'use strict';

import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './ingredientVariantOptions.phrases';

export function fetchPosConfigurationIngredientVariantOptions(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get(
		'/pos/pos_configuration_ingredient_variation_options',
		params,
		null,
		state.headers
	)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

export function editPosConfigurationIngredientVariantOptions(payload) {
	return post(
		`/pos/pos_configuration_ingredient_variation_options/${payload.id}`,
		payload
	)
		.then(res => {
			setFeedback(phrases.INGREDIENT_VARIANT_OPTION_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deletePosConfigurationIngredientVariantOptions
 * @param {Number} id - id of a product variant to delete
 */
export function deletePosConfigurationIngredientVariantOptions(id) {
	return remove(`/pos/pos_configuration_ingredient_variation_options/${id}`)
		.then(res => {
			setFeedback(phrases.INGREDIENT_VARIANT_OPTION_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

export function addPosConfigurationIngredientVariantOptions(data) {
	return post('/pos/pos_configuration_ingredient_variation_options', data)
		.then(res => {
			setFeedback(phrases.INGREDIENT_VARIANT_OPTION_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}
