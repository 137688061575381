'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, Icon } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// phrases
import phrases from './../../../posConfigurations.phrases';

// lodash
import _get from 'lodash/get';

class PosConfigurationsBatchStepListAdd extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e.target ? e.target.value || e.target.checked : e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: value },
		});
	}

	render() {
		const { batchList } = this.props;

		return (
			<TableList>
				<thead>
					<tr>
						<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
						<th>{phrases.MODAL_BATCH_STEP_MARKET}</th>
						<th>{phrases.MODAL_BATCH_STEP_NAME}</th>
						<th>{phrases.MODAL_BATCH_STEP_DESCRIPTION}</th>
						<th />
					</tr>
				</thead>
				<tbody>
					{batchList.map((entry, i) => {
						return (
							<TableListRow key={entry.identifier}>
								<TableListCell>{i + 1}</TableListCell>
								<TableListCell>{_get(entry, 'market.name', '')}</TableListCell>

								<TableListCell>
									<Input
										id={`name-${i}`}
										placeholder={phrases.MODAL_BATCH_STEP_NAME_PLACEHOLDER}
										value={entry.name || ''}
										onChange={(e) => this._editListEntry(e, entry, 'name')}
										required
									/>
								</TableListCell>

								<TableListCell>
									<Input
										id={`description-${i}`}
										value={entry.description || ''}
										placeholder={phrases.MODAL_BATCH_DESCRIPTION_PLACEHOLDER}
										onChange={(event) =>
											this._editListEntry(event, entry, 'description')
										}
										required
									/>
								</TableListCell>

								<TableListCell>
									<Button
										type="negative"
										size="tiny"
										onClick={() => this._onRemoveEntry(entry.identifier)}>
										<Icon name="delete" />
									</Button>
								</TableListCell>
							</TableListRow>
						);
					})}
				</tbody>
			</TableList>
		);
	}
}

PosConfigurationsBatchStepListAdd.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

PosConfigurationsBatchStepListAdd.defaultProps = {};

export default PosConfigurationsBatchStepListAdd;
