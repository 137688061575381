'use strict';

import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { fetchCompleteCollection } from 'api/fetchCompleteCollection';

/**
 * @function fetchPosConfigurationIngredients
 * @description Fetch product variants
 * @param {number} versionId
 * @param {object} state
 */
export function fetchPosConfigurationIngredients(
	state,
	shouldFetchCompleteCollection = false
) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	const request = shouldFetchCompleteCollection
		? fetchCompleteCollection(
				`/pos/pos_configuration_ingredients`,
				params,
				state.headers
		  )
		: get(`/pos/pos_configuration_ingredients`, params, null, state.headers);

	return Promise.resolve(request)
		.then((res) => {
			res = shouldFetchCompleteCollection
				? {
						data: res,
				  }
				: res;

			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchPosConfigurationDiscountIngredients
 * @description Fetch discount product variants
 * @param {number} discountId
 * @param {string} customFilter
 */
export function fetchPosConfigurationDiscountIngredients(
	customFilter,
	shouldFetchCompleteCollection
) {
	const filter = `(${customFilter})`;

	// Set params and filter
	const params = {
		limit: 500,
		filter,
	};

	const request = shouldFetchCompleteCollection
		? fetchCompleteCollection(
				`/pos/pos_configuration_discount_ingredients`,
				params
		  )
		: get(`/pos/pos_configuration_discount_ingredients`, params);

	return Promise.resolve(request)
		.then((res) => {
			res = shouldFetchCompleteCollection
				? {
						data: res,
				  }
				: res;

			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

export function addPosConfigurationDiscountIngredient(data) {
	return post('/pos/pos_configuration_discount_ingredients', data)
		.then((res) => {
			setFeedback(
				data.batch
					? 'Pos configuration discount ingredients added!'
					: 'Pos configuration discount ingredient added',
				1
			);
			return res;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
			throw err;
		});
}

/**
 * @function editDiscountIngredient
 * @description edit
 */
export function editDiscountIngredient(id, data) {
	return post(`/pos/pos_configuration_discount_ingredients/${id}`, data)
		.then((res) => {
			setFeedback('Pos configuration discount ingredient edited.', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteDiscountIngredients
 * @description edit
 */
export function deletePosConfigurationDiscountIngredient(id) {
	return remove(`/pos/pos_configuration_discount_ingredients/${id}`)
		.then((res) => {
			setFeedback('Successfully Deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}
