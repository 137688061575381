'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';

import { connectWithStore } from 'appState';

import Hotdamnbar from './hotdamnbar.component';

import './hotdamnbar.css';

class HotdamnbarContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeComponent: null,
			touchedComponent: false,
			stateLoading: false,
			valueChanged: false,
		};

		this.methods = {
			handleChange: this.handleChange.bind(this),
			handleComponentFocus: this.handleComponentFocus.bind(this),
			handleComponentFocusChange: this.handleComponentFocusChange.bind(this),
			toggleStateLoading: this.toggleStateLoading.bind(this),
			handleBlur: this.handleBlur.bind(this),
		};
	}

	componentDidMount() {
		const { hotbar, hotbarState } = this.props;
		if (hotbarState !== null) {
			const components = hotbar.getComponents();
			Object.keys(components).forEach((key) =>
				hotbar.handleDependencies(components[key])
			);
			this.setState(
				() => ({ touchedComponent: true }),
				() => {
					this.handleSendResultSet(true);
				}
			);
		}
	}

	toggleStateLoading(stateLoading) {
		this.setState((prevState) => ({
			stateLoading: _isNil(stateLoading)
				? !prevState.stateLoading
				: stateLoading,
		}));
	}

	/**
	 * Handler for returning the resultSet to outer component
	 * @param  {object} component
	 */
	handleSendResultSet(firedOnMount = false) {
		setTimeout(() => {
			const { activeComponent, touchedComponent } = this.state;
			const { hotbar, onChange, onBlurCallback, fireOnChangeOnMount } =
				this.props;

			// active component must be null, touchedComponent must be true (change indicated) and hotbar components must be valid
			if (
				(activeComponent === null && touchedComponent && hotbar.isValid()) ||
				(onBlurCallback && hotbar.isValid())
			) {
				// Emit change to outer componont

				if (onBlurCallback) {
					if (this.state.valueChanged) {
						onChange(hotbar.handleUpdateHotbarResultSet()).then(() => {
							this.setState(() => ({ valueChanged: false }));
						});
					}
				} else {
					if (!fireOnChangeOnMount && firedOnMount) {
						hotbar.handleUpdateHotbarResultSet();
					} else {
						onChange(hotbar.handleUpdateHotbarResultSet());
					}
				}

				// Reset touchedComponent
				this.setState(() => ({ touchedComponent: false }));
			}
		}, 50);
	}

	/**
	 * Handler for setting current component as focused element
	 * @param  {object} component
	 */
	handleComponentFocus(component) {
		// Initial Focus, excellent for AbortController
		// this.props.onFocusInitialFocus();

		// Set active comp
		this.setState(() => ({ activeComponent: component }));
	}

	/**
	 * Handler for when component focus changes - blurs.
	 * This is case specific, depending on component type that changes focus.
	 * @param  {object} component
	 * @param  {object} event
	 */
	handleComponentFocusChange(component, event) {
		// if blur event is handled by abort controller
		const callback = !this.props.onBlurCallback
			? this.handleSendResultSet()
			: () => {};
		// Which component type?
		switch (component.type) {
			case 'dateRange':
				this.setState((prevState) => {
					if (event === null || event.focused === null) {
						// Only set to null if component emits null-event and previous type was dateRange
						if (_get(prevState, 'activeComponent.type', null) === 'dateRange')
							return { activeComponent: null };
					}
				}, callback);
				break;

			case 'select':
				this.setState((prevState) => {
					if (prevState.activeComponent !== null)
						this.props.hotbar.handleDependencies(prevState.activeComponent);
					return { activeComponent: null };
				}, callback);
				break;

			case 'singleDate':
				this.setState(() => {
					if (!event.focused && !event.simpleFocused)
						return { activeComponent: null };
				}, callback);
				break;

			default:
				this.setState(() => ({ activeComponent: null }), callback);
				break;
		}
	}

	/**
	 * Handler for change coming from components
	 * @param  {object} component
	 * @param  {object} event
	 */
	handleChange(component, event) {
		this.props.onFocusInitialFocus();

		// Always send to component
		component.setSelectedValues(event);
		// Default new state
		let newState = { touchedComponent: true, valueChanged: true };
		// Which type of component?
		switch (component.type) {
			case 'select':
				newState = { ...newState };
				break;
			default:
				break;
		}

		// Indicate change has happened
		this.setState(() => newState);
	}

	/**
	 * @function handleBlur
	 * @public
	 * @description handles onblur event, also depends of onBlurCallback is true
	 */
	handleBlur() {
		if (this.props.onBlurCallback) {
			this.handleSendResultSet();
		}
	}

	render() {
		const { hotbar, hotbarState, loading, children } = this.props;
		const { stateLoading } = this.state;

		return (
			<Hotdamnbar
				activeComponent={this.state.activeComponent}
				hotbar={hotbar}
				hotbarState={hotbarState}
				loading={loading || stateLoading}
				methods={this.methods}>
				{children}
			</Hotdamnbar>
		);
	}
}

HotdamnbarContainer.defaultProps = {
	onFocusInitialFocus: () => {},
	onBlurCallback: false,
	fireOnChangeOnMount: true,
};

HotdamnbarContainer.propTypes = {
	hotbar: PropTypes.object.isRequired,
	loading: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	onFocusInitialFocus: PropTypes.func,
	onBlurCallback: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
	hotbarState: PropTypes.object,
	fireOnChangeOnMount: PropTypes.bool,
};

const mapStateToPropsFactory = (initialStore, ownProps) => (store) => ({
	hotbarState: _get(store, `hotbar.hotbars[${ownProps.hotbar.title}]`, null),
});

export default connectWithStore(HotdamnbarContainer, mapStateToPropsFactory);
