import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './row.css';

const Row = React.memo(function Row({
	className,
	children,
	type,
	onClick,
	subheader,
	index,
	borderBottom
}) {
	const classNames = classnames('report-tr', className, {
		[`report-tr--${type}`]: type,
		'report-tr--subheader': subheader,
		'report-tr--border-bottom': borderBottom,
		'report-tr--even': index % 2 === 0
	});

	return (
		<tr className={classNames} onClick={onClick}>
			{children}
		</tr>
	);
});

Row.displayName = 'Row';

Row.defaultProps = {
	className: '',
	index: null,
	onClick: () => {}
};

Row.propTypes = {
	className: PropTypes.object,
	index: PropTypes.number,
	subheader: PropTypes.bool,
	borderBottom: PropTypes.bool,
	onClick: PropTypes.func,
	type: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
		PropTypes.number
	]).isRequired
};

export { Row };
