export const SET_TABLE_FILTER =
	'PRODUCT_VARIANT_WORKPLACE_RELATIONS/SET_TABLE_FILTER';
export const CLEAR_TABLE_FILTERS =
	'PRODUCT_VARIANT_WORKPLACE_RELATIONS/CLEAR_TABLE_FILTERS';
export const SET_DUPLICATE_LIST_DATA =
	'PRODUCT_VARIANT_WORKPLACE_RELATIONS/SET_DUPLICATE_LIST_DATA';

export function setTableFilter(payload) {
	return {
		type: SET_TABLE_FILTER,
		payload,
	};
}

export function clearTableFilters(payload) {
	return {
		type: CLEAR_TABLE_FILTERS,
		payload,
	};
}

export function setDuplicateListData(payload) {
	return {
		type: SET_DUPLICATE_LIST_DATA,
		payload,
	};
}
