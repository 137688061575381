'use strict';

// Lodash
import _isEqual from 'lodash/isEqual';
import _isUndefined from 'lodash/isUndefined';
import _isString from 'lodash/isString';
import _template from 'lodash/template';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import SelectInput from 'react-select';

import SelectService from './select.service';

import SearchList from 'searchList/searchList.component';

import './select.css';

class Select extends Component {
	constructor(props) {
		super(props);

		this.state = {
			cleared: false,
			searchListActive: false,
			value: props.initialValue
		};

		this.SelectService = new SelectService();
		this.translations = this.SelectService.GetTranslations();

		this.getAsyncOptions = this.getAsyncOptions.bind(this);
		this.toggleSearchList = this.toggleSearchList.bind(this);
		this.onSearchListSelect = this.onSearchListSelect.bind(this);
		this.onSelect = this.onSelect.bind(this);
		this.warnings = this.warnings.bind(this);

		this.config = {
			labelTemplate: props.labelTemplate,
			searchFields: props.searchFields,
			searchList: props.searchList,
			searchListData: props.searchListData,
			searchListFilter: props.searchListFilter,
			searchListLimit: props.searchListLimit,
		};
	}

	warnings() {
		const { labelTemplate, searchList } = this.props;
		if (!_isUndefined(searchList) && _isUndefined(labelTemplate))
			console.warn(
				`Prop \`labelTemplate\` is missing from select.component. SearchList is ${
					searchList.getState().title
				}.`
			);
	}

	focus() {
		// this.selectInput.focus();
	}

	getAsyncOptions(term, callback) {
		this.SelectService.LoadAsyncOptions(this.config, term, callback).then(
			options => {
				callback(null, { options: options });
			}
		);
	}

	toggleSearchList() {
		// Reset list
		// This is needed due to angular and its scope apply.
		// When angular is out, we can actually clone with
		// _cloneWithProto and use list a different place.

		if (_isString(this.props.searchList)) return;
		this.props.searchList.handleResetList();

		// Change state
		this.setState({ searchListActive: !this.state.searchListActive });
	}

	onSearchListSelect(item) {
		const { labelTemplate, onChange } = this.props;

		// Trigger change from parent component
		onChange({ value: item.id, label: _template(labelTemplate)(item) });

		// Set local state
		this.setState({
			value: { value: item.id, label: _template(labelTemplate)(item) }
		});

		// Toggle search list
		this.toggleSearchList();
	}

	onSelect(event) {
		const { onChange } = this.props;
		onChange(event);
		this.setState({ value: event, cleared: true });
	}

	setInitialValue() {
		const { initialValue } = this.props;

		this.setState({
			value: initialValue
		});
	}

	componentDidMount() {
		this.warnings();
	}

	componentDidMount() {
		this.setInitialValue();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { initialValue, labelTemplate, searchFields, searchList, searchListData, searchListFilter, searchListLimit } =
			nextProps;

		this.config = {
			labelTemplate,
			searchFields,
			searchList,
			searchListData,
			searchListFilter,
			searchListLimit,
		};

		if (
			!_isEqual(this.state.value, nextProps.initialValue) &&
			!this.state.cleared
		) {
			this.setState(() => {
				return {
					value: initialValue
				};
			});
		}
	}

	render() {
		const {
			disabled,
			name,
			options,
			searchList,
			onInputKeyDown,
			showSearchList,
			searchListData,
			searchListFilter,
			searchListLimit,
			isLoading,
		} = this.props;

		const { value } = this.state;

		const selectCx = cx('select', {
			'select--search': !disabled && _isUndefined(options),
			'select--no-search': !showSearchList
		});

		if (!_isUndefined(options))
			return (
				<div className={selectCx}>
					<SelectInput
						ref={input => {
							this.selectInput = input;
						}}
						multi={false}
						cache={false}
						searchable
						ignoreAccents={false}
						clearable
						autoBlur
						name={name}
						value={value}
						onChange={this.onSelect}
						isLoading={isLoading}
						disabled={disabled}
						onInputKeyDown={onInputKeyDown}
						placeholder={this.translations.SELECT_SEARCH}
						options={options}
					/>
				</div>
			);
		else
			return (
				<div className={selectCx} key={searchListFilter}>
					<SelectInput.Async
						filterOption={() => true}
						ref={input => {
							this.selectInput = input;
						}}
						multi={false}
						cache={false}
						ignoreAccents={false}
						minimumInput={2}
						clearable
						autoBlur
						name={name}
						value={value}
						onInputKeyDown={onInputKeyDown}
						onChange={this.onSelect}
						disabled={disabled}
						isLoading={isLoading}
						placeholder={this.translations.SELECT_SEARCH}
						loadOptions={this.getAsyncOptions}
						noResultsText={this.translations.SELECT_NO_RESULTS}
						searchPromptText={this.translations.SELECT_SEARCH}
					/>

					{!disabled && showSearchList && !_isString(this.props.searchList) && (
						<a
							onClick={this.toggleSearchList}
							className="select__search-button">
							<span className="icon icon--keyboard_control" />
						</a>
					)}

					{showSearchList && (
						<SearchList
							searchList={searchList}
							searchListData={searchListData}
							searchListFilter={searchListFilter}
							searchListLimit={searchListLimit}
							handleSelect={this.onSearchListSelect}
							handleClose={this.toggleSearchList}
							visible={this.state.searchListActive}
						/>
					)}
				</div>
			);
	}
}

Select.propTypes = {
	disabled: PropTypes.bool,
	isLoading: PropTypes.bool,
	labelTemplate: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	options: PropTypes.array,
	searchList: PropTypes.object,
	searchListData: PropTypes.object,
	searchListFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	searchListLimit: PropTypes.number,
	initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	searchFields: PropTypes.object,
	onInputKeyDown: PropTypes.func,
	showSearchList: PropTypes.bool
};

Select.defaultProps = {
	clearable: false,
	disabled: false,
	options: undefined,
	onInputKeyDown() {},
	showSearchList: true,
	isLoading: false
};

export default Select;
