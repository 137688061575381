import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchAllergens
 * @description Fetch all allergenes values
 * @param {object} state
 */
export function fetchAllergens(allergenCategoryId, state) {
	const preFilter = `:allergen_category.id=='${allergenCategoryId}'`;

	const filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(`/product/allergens`, params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editAllergens
 * @description edit
 */
export function editAllergens(allergenId, data) {
	return post(`/product/allergens/${allergenId}`, data)
		.then((res) => {
			setFeedback('Allergen edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function addAllergens
 * @description add
 */
export function addAllergens(data) {
	return post(`/product/allergens`, data)
		.then((res) => {
			setFeedback('Allergen added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteAllergens
 * @description del
 */
export function deleteAllergens(allergenId) {
	return remove(`/product/allergens/${allergenId}`)
		.then((res) => {
			setFeedback('Allergen deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
