'use strict';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';

import cx from 'classnames';

class RowEntry extends PureComponent {
	constructor(props) {
		super(props);
	}

	/**
	 * Extract content table
	 * @param  {array} contentContainer
	 */
	getContents(contentContainer, width) {
		let mappedContents = [];

		for (let i = 0, len = contentContainer.length; i < len; i++) {
			const content = contentContainer[i];

			// Get styles
			const styles = cx('report-old__content', {
				'report-old__content--currency': content.contentType === 'currency'
			});
			mappedContents = [
				...mappedContents,
				<span key={i} className={styles}>
					{content.getContent(
						this.props[content.getContentSourceProp()],
						width
					) || '\u00a0'}
				</span>
			];
		}
		return mappedContents;
	}

	/**
	 * Get columns
	 * @param  {array} columnContainer
	 */
	getColumns(columnContainer) {
		const { tables, context } = this.props;

		let mappedColumns = [];

		for (let i = 0, len = columnContainer.length; i < len; i++) {
			const column = columnContainer[i];

			// Default dynamic class names
			const defaultClassNames = {
				'report-old__cell--currency': column.contentType === 'currency', // Right aligned
				'report-old__cell--number': column.contentType === 'number', // Right aligned
				'report-old__cell--mixed': column.contentType === 'mixed', // Center aligned
				'report-old__cell--left': column.contentType === 'left' // left aligned
			};

			// Combine default class names with modifier class names
			const styles = cx('report-old__cell', {
				...defaultClassNames,
				...column.getModifierClassNames(this.props.data)
			});

			// Get width
			const width = column.getWidth(tables) ? column.getWidth(tables) : 'auto';
			mappedColumns = [
				...mappedColumns,
				<td
					key={i + column.type}
					className={styles}
					style={{ width: width, maxWidth: width }}>
					{this.getContents(column.getContent(context), width)}
				</td>
			];
		}
		return mappedColumns;
	}

	render() {
		const {
			activeInteractionClassName,
			interaction,
			component,
			modifierClassNames
		} = this.props;

		// Define rowContent
		let rowContent;

		// Switch on children type to get row content (groups or columns)
		switch (component.getChildrenType()) {
		case 'group':
			rowContent = component.content.map(group =>
				this.getColumns(group.content)
			);
			break;
		case 'column':
			rowContent = this.getColumns(component.content);
			break;
		}

		// Styles for row
		const styles = cx('report-old__entry', modifierClassNames, {
			'report-old__entry--has-interaction': interaction,
			...activeInteractionClassName
		});

		return (
			<tr onClick={interaction} className={styles}>
				{rowContent}
			</tr>
		);
	}
}

RowEntry.defaultValues = {
	activeInteractionClassName: null,
	interaction: null
};

RowEntry.propTypes = {
	activeInteractionClassName: PropTypes.object,
	context: PropTypes.oneOf(['body', 'foot', 'title']).isRequired,
	data: PropTypes.object.isRequired,
	interaction: PropTypes.func,
	component: PropTypes.object.isRequired,
	modifierClassNames: PropTypes.string,
	tables: PropTypes.object.isRequired
};

export default RowEntry;
