export default {
	TITLE: 'Production time grids',
	WAITING_TIME_SCHEDULE_ADDED: 'Production time grids added!',
	WAITING_TIME_SCHEDULE_EDITED: 'Production time grid edited!',
	WAITING_TIME_SCHEDULES_EDITED: 'Production time grids edited!',
	WAITING_TIME_SCHEDULE_DELETED: 'Production time grid deleted!',

	// batch form
	MODAL_BATCH_STEP_LIST_TITLE: 'Create waiting time schedules',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add waiting time schedules',
};
