import React from 'react';
import PropTypes from 'prop-types';

// styles
import './hotdamnbarLabelProvider.css';

const HotdamnbarLabelProvider = ({ label, children }) => {
	return (
		<div className="hotdamnbar-label-provider">
			{label && (
				<label className="hotdamnbar-label-provider__label">{label}</label>
			)}
			{children}
		</div>
	);
};

HotdamnbarLabelProvider.propTypes = {
	label: PropTypes.string,
	children: PropTypes.string,
};

export default HotdamnbarLabelProvider;
