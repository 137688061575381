import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// components
import FilterValueProvider from './../filterProvider.component';
import DayPickerRange from 'reusableComponents/inputs/date/date.component';
import ReactDOM from 'react-dom';
import { Manager, Reference, Popper } from 'react-popper';
import { Card, Button, Icon, Backdrop } from 'dumb';

// phrases/ constatns
import phrases from './dateFilterSelector.phrases';
import constants from 'services/constants';

// utils
import { isMobile } from 'detectMobile.vanilla';

// enums
import FilterProviderEnums from './../filterProvider.enums';

// styles
import './dateFilterSelector.css';

const DateFilterSelector = ({
	reduxKey,
	columnId,
	type,
	zIndex,
	withTimestamp,
	formatStartDate,
	formatEndDate,
}) => {
	if (!reduxKey) console.warn(phrases.NO_REDUX_KEY);
	if (!columnId) console.warn(phrases.NO_COLUMN_ID);

	const onInputChange = (e, persistToFilterStorage) => {
		const isValueValid =
			moment.isMoment(e.startDate) || moment.isMoment(e.endDate);

		let value = null;
		if (isValueValid) {
			value = {
				startDate: formatStartDate(e.startDate),
				endDate: formatEndDate(e.endDate)
			};
		}

		persistToFilterStorage({
			handle: columnId,
			value,
			filterType: withTimestamp
				? FilterProviderEnums.DATE_WITH_TIMESTAMP
				: FilterProviderEnums.DATE,
		});
	};

	const getPreviewText = (filterValue) => {
		if (!filterValue) return phrases.NO_FILTER_VALUE;

		const { startDate, endDate } = filterValue.value;

		return `${
			startDate
				? moment.utc(startDate).format(constants.dayMonthFormat)
				: phrases.INFINITE
		} - ${
			endDate
				? moment.utc(endDate).format(constants.dayMonthFormat)
				: phrases.INFINITE
		}`;
	};

	const onClearIconClick = (e, persistToFilterStorage) => {
		e.stopPropagation();

		persistToFilterStorage({
			handle: columnId,
			value: null,
			filterType: FilterProviderEnums.DATE,
		});
	};

	const toggleDateSelector = () => setIsShown(!isShown);
	const hideDateSelector = () => setIsShown(false);

	const [isShown, setIsShown] = useState(false);

	return (
		<FilterValueProvider
			reduxKey={reduxKey}
			columnId={columnId}
			filterKey={FilterProviderEnums.FILTERS}
			provide={(filterValue, persistToFilterStorage) => (
				<Manager>
					<Reference>
						{({ ref }) => (
							<Button
								refProp={ref}
								type="in-table-header"
								onClick={toggleDateSelector}>
								<div className="react-table-wrapper__date-filter-selector__preview">
									<span className="react-table-wrapper__date-filter-selector__preview__text">
										{getPreviewText(filterValue)}
									</span>
									{filterValue && !isMobile() && (
										<Icon
											className="react-table-wrapper__date-filter-selector__preview__icon"
											name="close"
											onClick={(e) =>
												onClearIconClick(e, persistToFilterStorage)
											}
										/>
									)}
								</div>
							</Button>
						)}
					</Reference>
					{ReactDOM.createPortal(
						<>
							<Backdrop
								isOpen={isShown}
								closeBackdrop={hideDateSelector}
								zIndex={zIndex}
							/>

							{isShown && (
								<Popper placement="bottom">
									{({ ref, style }) => (
										<div ref={ref} style={{ ...style, zIndex: zIndex + 1 }}>
											<Card className="react-table-wrapper__date-filter-selector__card">
												<Icon
													className="react-table-wrapper__date-filter-selector__card__close"
													name="close"
													onClick={hideDateSelector}
												/>

												<h3>{phrases.TITLE}</h3>
												<DayPickerRange
													initialStartDate={
														filterValue ? filterValue.value.startDate : null
													}
													initialEndDate={
														filterValue ? filterValue.value.endDate : null
													}
													onChange={(e) =>
														onInputChange(
															e,
															persistToFilterStorage,
															filterValue
														)
													}
													type="range"
													withPortal
													reopenPickerOnClearDate
													minimumNights={0}
													showClearDate
													inTable
													onClose={hideDateSelector}
													zIndex={zIndex + 2}
												/>
											</Card>
										</div>
									)}
								</Popper>
							)}
						</>,
						document.querySelector('#popper')
					)}
				</Manager>
			)}
		/>
	);
};

DateFilterSelector.defaultProps = {
	type: 'single',
	zIndex: 101,
	withTimestamp: false,
	formatStartDate: (date) => date,
	formatEndDate: (date) => date,
};

DateFilterSelector.propTypes = {
	reduxKey: PropTypes.string,
	columnId: PropTypes.string,
	type: PropTypes.bool,
	zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	withTimestamp: PropTypes.bool,
	formatStartDate: PropTypes.func,
	formatEndDate: PropTypes.func,
};

export default DateFilterSelector;
