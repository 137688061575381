import React, { Component } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { ButtonLoader } from 'dumb';

import './posMenuItem.css';

import { CSSTransition } from 'react-transition-group';

class PosMenuItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			fetching: false
		};

		// We need a flag to figure out if the component is mounted since we are doing hover animations.
		// When the promise is resolved in deletePosMenuItem we try to setState but at this point the component
		// it can be removed from the DOM
		this.mounted = false;

		this.onEnter = this.onEnter.bind(this);
		this.onLeave = this.onLeave.bind(this);
		this.selectGroupInFocus = this.selectGroupInFocus.bind(this);
		this.editPosMenuItem = this.editPosMenuItem.bind(this);
		this.deletePosMenuItem = this.deletePosMenuItem.bind(this);
	}

	componentDidMount() {
		this.mounted = true;
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	onEnter() {
		this.mounted && this.setState({ show: true });
	}

	onLeave() {
		this.mounted && this.setState({ show: false });
	}

	/**
	 * editPosMenuItem
	 * @description Open up addGroupModal to modify group data
	 */
	editPosMenuItem(e) {
		e.stopPropagation();
		this.props.editPosMenuItem(this.props.item.id);
	}

	/**
	 * removePosMenuItem
	 * @description Click to delete group menu item
	 */
	deletePosMenuItem(e) {
		e.stopPropagation();
		this.mounted && this.setState(() => ({ fetching: true }));
		this.props.deleteLayoutGroup(this.props.item.id).then(() => {
			this.mounted && this.setState(() => ({ fetching: false }));
		});
	}

	/**
	 * selectGroupInFocus
	 * @description Open up group in grid
	 */
	selectGroupInFocus() {
		this.props.setMenuItemInFocus(this.props.item.id);
	}

	render() {
		const { item, itemInFocus } = this.props;
		const { fetching } = this.state;

		const posMenuItemClassnames = cx('button pos-menu-item', {
			'pos-menu-item--is-focused': itemInFocus,
			'pos-menu-item--is-fetching': fetching,
			'pos-menu-item--is-temporary': item.temporary
		});

		const style = {
			backgroundColor: `#${item.background_color}`,
			color: `#${item.title_color}`
		};

		return (
			<div
				className={posMenuItemClassnames}
				onMouseEnter={this.onEnter}
				onMouseLeave={this.onLeave}
				style={style}
				onClick={this.selectGroupInFocus}>
				<span>{item.name}</span>
				<CSSTransition
					classNames="button-group"
					timeout={500}
					in={this.state.show && !item.temporary}
					mountOnEnter
					unmountOnExit>
					<div className="pos-menu-item__button-group">
						<span
							onClick={this.editPosMenuItem}
							className="icon icon--settings"
						/>
						<span
							onClick={this.deletePosMenuItem}
							className="icon icon--delete"
						/>
					</div>
				</CSSTransition>

				<ButtonLoader loading={fetching} />
			</div>
		);
	}
}

PosMenuItem.propTypes = {
	item: PropTypes.object,
	itemInFocus: PropTypes.bool,
	deleteLayoutGroup: PropTypes.func,
	setMenuItemInFocus: PropTypes.func,
	editPosMenuItem: PropTypes.func
};

PosMenuItem.defaultProps = {
	item: {},
	itemInFocus: false
};

export default PosMenuItem;
