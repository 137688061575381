/**
 * @function distanceFromMidnight
 * @description returns the difference between 2 moment objects in hours
 * @param {Array} midnight - moment object
 * @param {Array} time - moment object
 * @return returns the distance to mmidnight in hours, e.g if midnight is a moment object on the 24th
 * and the time is a moment object at 06:00 the 25th, the distance will be 30 hours
 */
export const distanceFromMidnight = (
	midnight,
	time,
	unit = 'hour',
	returnFloat = false
) => {
	return time.diff(midnight, unit, returnFloat);
};
