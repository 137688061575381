// interfaces
import { IRemoveShiftsFromEmployeeApiRes200, IRemoveShiftsFromEmployeeShift } from 'types/removeShiftsFromEmployee';

import { get, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

type removeShifts = {
	workplaceId: number;
	date: string;
	employmentId: number;
};
export function removeShifts({ workplaceId, date, employmentId }: removeShifts): Promise<unknown> {
	const url = `/shiftplanning/employments/${employmentId}/shift_employees?from=${date}&workplace=${workplaceId}`;

	return remove(url)
		.then((res: IRemoveShiftsFromEmployeeApiRes200) => {
			setFeedback(res.message, 1);
		})
		.catch((err: unknown) => {
			setFeedback(formatErrorMessage(err), 0);
			throw err;
		});
}

type getShifts = {
	employmentId: number;
	date: string;
};
export function fetchShifts({ employmentId, date }: getShifts): Promise<IRemoveShiftsFromEmployeeShift[]> {
	const params = {
		sort: ':planned_period.from-',
		filter: `:planned_period.from=ge='${date}';:shift_employees.employment.id=='${employmentId}'`,
	};

	return get('/shiftplanning/shifts', params)
		.then((res: IApiRes) => {
			const castedRes = res.data as IRemoveShiftsFromEmployeeShift[];
			return castedRes;
		})
		.catch((err: unknown) => {
			setFeedback(formatErrorMessage(err), 0);
			throw err;
		});
}
