import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchAllProductBundleSetTranslations
 */
export function fetchAllProductBundleSetTranslations(id, state) {
	const preFilter = `:bundle_set.id=='${id}'`;
	const filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(`/product/bundle_set_translations`, params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addProductBundleSetTranslations
 * @description add
 */
export function addProductBundleSetTranslations(data) {
	return post(`/product/bundle_set_translations`, data)
		.then((res) => {
			setFeedback('Product bundle set translation added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editProductBundleSetTranslations
 */
export function editProductBundleSetTranslations(data) {
	return post(`/product/bundle_set_translations/${data.id}`, data)
		.then((res) => {
			setFeedback('Product bundle set translation edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteProductBundleSetTranslation
 */
export function deleteProductBundleSetTranslation(id) {
	return remove(`/product/bundle_set_translations/${id}`)
		.then((res) => {
			setFeedback('Product bundle set translation deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
