'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ImageCell from './imageCell.component';
import './imageCell.css';
import ImageCellService from './imageCell.service';

class ImageCellContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			image: props.image,
			loading: false,
			error: false
		};

		this.handleSetFile = this.handleSetFile.bind(this);

		this.ImageCellService = new ImageCellService(
			props.id,
			props.endpoint,
			props.objectKey
		);
	}

	handleSetFile(file, fileName) {
		// If errors then reset image
		if (file === null && fileName === null) {
			this.setState(() => ({ image: null }));
			return;
		}

		// Start loader
		this.setState(() => ({ loading: true }));

		// Extract (and set) image from file selected
		this.ImageCellService.extractBase64(file)
			.then(image => {
				// Update image in state
				const updatedImage = { filename: fileName, url: image };

				this.setState(() => ({ image: updatedImage }));

				// Initate upload
				return this.ImageCellService.handleUploadImage(
					file,
					fileName,
					this.props.objectKey
				);
			})

			// Stop loader
			.then(() => this.setState(() => ({ loading: false })))

			// Errors in server interaction
			.catch(() =>
				this.setState(() => ({ loading: false, image: null, error: true }))
			);
	}

	render() {
		const { image, loading, error } = this.state;

		return (
			<ImageCell
				imageCell={this.ImageCellService}
				loading={loading}
				image={image}
				error={error}
				phrases={this.ImageCellService.getPhrases()}
				handleSetFile={this.handleSetFile}
			/>
		);
	}
}

ImageCellContainer.propTypes = {
	objectKey: PropTypes.string.isRequired,
	id: PropTypes.number.isRequired,
	image: PropTypes.object.isRequired,
	endpoint: PropTypes.string.isRequired
};

export default ImageCellContainer;
