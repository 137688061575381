'use strict';

import {
	SET_RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS_MODAL,
	UPDATE_RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS,
	RESET_STATE,
	ADD_BATCH_LIST,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	UPDATE_BATCH_FORM
} from './rawIngredientConfigurationRawIngredients.actions';

const defaultState = {
	data: {
		rawIngredientConfigurationRawIngredients: {},
		batchList: [],
		batchFormData: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS_MODAL: {
		return {
			...state,
			data: {
				rawIngredientConfigurationRawIngredients: payload
			}
		};
	}

	case UPDATE_RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS: {
		return {
			...state,
			data: {
				rawIngredientConfigurationRawIngredients: {
					...state.data.rawIngredientConfigurationRawIngredients,
					...payload
				}
			}
		};
	}

	case RESET_STATE: {
		return defaultState;
	}

	case ADD_BATCH_LIST: {
		return {
			...state,
			data: {
				...state.data,
				batchList: payload
			}
		};
	}

	case UPDATE_BATCH_FORM: {
		return {
			...state,
			data: {
				...state.data,
				batchFormData: {
					...state.data.batchFormData,
					...payload
				}
			}
		};
	}

	case EDIT_BATCH_LIST_ITEM: {
		return {
			...state,
			data: {
				...state.data,
				batchList: state.data.batchList.map(entry => {
					if (entry.identifier === payload.identifier) return payload;
					return entry;
				})
			}
		};
	}

	case REMOVE_BATCH_LIST_ITEM: {
		return {
			...state,
			data: {
				...state.data,
				batchList: state.data.batchList.filter(
					entry => entry.identifier !== payload
				)
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
