'use strict';

import _find from 'lodash/find';

import { SET_CLOSED_DAYS, UPDATE_CLOSED_DAYS } from './workDays.actions';

const defaultState = {
	closedDays: {}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
	case SET_CLOSED_DAYS:
		let closedDays = {};
		payload.closedDays.map(closedDay => {
			let day = closedDay.date;
			if (!closedDays[day]) closedDays[day] = {};
			let closedDayExists = _find(closedDays[day], { id: closedDays.id });
			if (!closedDayExists) closedDays[day] = closedDay;
		});

		return Object.assign({}, state, {
			closedDays
		});

	case UPDATE_CLOSED_DAYS:
		return Object.assign({}, state, {
			closedDays: {
				...state.closedDays,
				[payload.key]: payload.closedDay
			}
		});

	default:
		return state;
	}
}

export default reducer;
