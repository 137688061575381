export const SET_POWER_BI_REPORT = 'POWER_BI_REPORTS/SET_POWER_BI_REPORT';
export const UPDATE_POWER_BI_REPORT = 'POWER_BI_REPORTS/UPDATE_POWER_BI_REPORT';
export const RESET_POWER_BI_REPORT = 'POWER_BI_REPORTS/RESET_POWER_BI_REPORT';

export const ADD_BATCH_LIST = 'POWER_BI_REPORTS/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM = 'POWER_BI_REPORTS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM = 'POWER_BI_REPORTS/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'POWER_BI_REPORTS/ADD_BATCH_LIST_ITEM_ROW';
export const SET_SELECTED_POWER_BI_REPORTS =
	'POWER_BI_REPORTS/SET_SELECTED_POWER_BI_REPORTS';
export const EDIT_GLOBAL_COPY_REPORT_VALUE =
	'POWER_BI_REPORTS/EDIT_GLOBAL_COPY_REPORT_VALUE';
export const EDIT_SELECTED_POWER_BI_REPORT =
	'POWER_BI_REPORTS/EDIT_SELECTED_POWER_BI_REPORT';
export const EDIT_MARKETS = 'POWER_BI_REPORTS/EDIT_MARKETS';

export function setSelectedPowerBiReports(payload) {
	return {
		type: SET_SELECTED_POWER_BI_REPORTS,
		payload,
	};
}

export function editGlobalCopyReportValue(payload) {
	return {
		type: EDIT_GLOBAL_COPY_REPORT_VALUE,
		payload,
	};
}

export function editSelectedPowerBiReport(payload) {
	return {
		type: EDIT_SELECTED_POWER_BI_REPORT,
		payload,
	};
}

export function setPowerBIReport(payload) {
	return {
		type: SET_POWER_BI_REPORT,
		payload,
	};
}

export function updatePowerBIReport(payload) {
	return {
		type: UPDATE_POWER_BI_REPORT,
		payload,
	};
}

export function resetPowerBIReport() {
	return {
		type: RESET_POWER_BI_REPORT,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}

export function editMarkets(payload) {
	return {
		type: EDIT_MARKETS,
		payload,
	};
}
