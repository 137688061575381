/**
 * @function getOperationTypeOptions
 * @description Returns array of operation types for collectionSelect component
 */
export function getOperationTypeOptions() {
	return [
		{
			value: 'Addition',
			label: 'Addition',
		},
		{
			value: 'Deduction',
			label: 'Deduction',
		},
	];
}
