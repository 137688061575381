import React from 'react';

import { DateFilterSelector } from 'reactDataWrapper/utilities';

type Props = {
	reduxKey: string;
};

const columns = ({ reduxKey }: Props) => {
	return [
		{
			Header: 'Name',
			id: 'name',
			accessor: (d: IHrMoneyballPositions) => d.name,
			filterPath: ':name',
		},
		{
			Header: 'Description',
			id: 'description',
			accessor: (d: IHrMoneyballPositions) => d.description,
			filterPath: ':description',
		},
		{
			Header: 'Badge',
			id: 'badge',
			accessor: (d: IHrMoneyballPositions) => d.badge,
			filterPath: ':badge',
		},
		{
			Header: 'Sort Order',
			id: 'sort_order',
			accessor: (d: IHrMoneyballPositions) => d.sort_order,
			filterPath: ':sort_order',
		},
		{
			Header: 'From',
			id: 'from',
			accessor: (d: IHrMoneyballPositions) => d.active.from,
			filterPath: ':active.from',
			Filter: ({
				column,
			}: {
				column: {
					id: string;
				};
			}) => (
				<DateFilterSelector
					reduxKey={reduxKey}
					columnId={column.id}
					dateIdentifier="from"
				/>
			),
		},
		{
			Header: 'To',
			id: 'to',
			accessor: (d: IHrMoneyballPositions) => d.active.to,
			filterPath: ':active.to',
			Filter: ({ column }: { column: { id: string } }) => (
				<DateFilterSelector
					reduxKey={reduxKey}
					columnId={column.id}
					dateIdentifier="to"
				/>
			),
		},
	];
};

export default columns;
