'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StoresViewHeader from './storesView.header.component';
import StoresViewBody from './storesView.body.component';

class StoresView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: {
				market: null,
				hourly: null,
				product: null
			},
			hover: {}
		};

		this.startHover = this.startHover.bind(this);
		this.stopHover = this.stopHover.bind(this);
		this.expandHourly = this.expandHourly.bind(this);
		this.expandProduct = this.expandProduct.bind(this);
		this.generateColumns = this.generateColumns.bind(this);

		this.columns = this.generateColumns();
	}

	startHover(group) {
		this.setState(() => ({ hover: { group } }));
	}

	stopHover() {
		this.setState(() => ({ hover: {} }));
	}

	expandTotal(row) {
		// Initiate loading
		this.setState(prevState => ({
			loading: {
				...prevState.loading,
				market: row.id
			}
		}));

		// Toggle market table
		this.props.dynamicPosView.handleToggleTable('total', row.id);

		// Get content for market table
		this.props.dynamicPosView
			.handleGetMarketWorkplaceTable(row.data.workplace.id)
			.then(() => {
				// Stop loading
				this.setState(prevState => ({
					loading: {
						...prevState.loading,
						market: null
					}
				}));
			});
	}

	expandHourly(row) {
		const { market } = this.props;

		// Initiate loading
		this.setState(prevState => ({
			loading: {
				...prevState.loading,
				hourly: row.id
			}
		}));

		// Toggle hourly table
		this.props.dynamicPosView.handleToggleTable('hourly', row.id);

		// Get content for hourly table
		this.props.dynamicPosView
			.handleGetMarketAndWorkplaceHourlyTable(market, row.data.workplace.id)
			.then(() => {
				// Stop loading
				this.setState(prevState => ({
					loading: {
						...prevState.loading,
						hourly: null
					}
				}));
			});
	}

	expandProduct(product, row) {
		const { market } = this.props;

		// Initiate loading
		this.setState(prevState => ({
			loading: {
				...prevState.loading,
				product: row.id
			}
		}));

		// Toggle market table
		this.props.dynamicPosView.handleToggleProductTable(product, row.id);

		// Get content for market table
		this.props.dynamicPosView
			.handleGetMarketAndWorkplaceProductTable(market, row.data.workplace.id)
			.then(() => {
				// Stop loading
				this.setState(prevState => ({
					loading: {
						...prevState.loading,
						product: null
					}
				}));
			});
	}

	generateColumns() {
		// Add actions to all columns
		const groups = this.props.components['StoresView'].content;

		return {
			stores: {
				group: 'market',
				action: null,
				cells: {
					stores: groups[0].content[0]
				}
			},
			total: {
				group: 'average',
				action: row => this.expandHourly(row),
				cells: {
					turnover: groups[1].content[0],
					products: groups[1].content[1],
					employees: groups[1].content[2],
					phpMax: groups[1].content[3]
				}
			},
			// average: {
			// 	group: 'average',
			// 	action: row => this.expandHourly(row),
			// 	cells: {
			// 		turnover: groups[2].content[0],
			// 		products: groups[2].content[1],
			// 		employees: groups[2].content[2],
			// 		phpMax: groups[2].content[3]
			// 	}
			// },
			juice: {
				group: 'juice',
				action: row => this.expandProduct('juice', row),
				cells: {
					turnover: groups[2].content[0],
					products: groups[2].content[1],
					percentage: groups[2].content[2]
				}
			},
			shake: {
				group: 'shake',
				action: row => this.expandProduct('shake', row),
				cells: {
					turnover: groups[2].content[3],
					products: groups[2].content[4],
					percentage: groups[2].content[5]
				}
			},
			sandwich: {
				group: 'sandwich',
				action: row => this.expandProduct('sandwich', row),
				cells: {
					turnover: groups[2].content[6],
					products: groups[2].content[7],
					percentage: groups[2].content[8]
				}
			},
			coffee: {
				group: 'coffee',
				action: row => this.expandProduct('coffee', row),
				cells: {
					turnover: groups[2].content[9],
					products: groups[2].content[10],
					percentage: groups[2].content[11]
				}
			},
			loyaltycard: {
				group: 'loyaltycard',
				action: row => this.expandProduct('loyaltycard', row),
				cells: {
					turnover: groups[2].content[12],
					products: groups[2].content[13],
					percentage: groups[2].content[14]
				}
			},
			breakfast: {
				group: 'breakfast',
				action: row => this.expandProduct('breakfast', row),
				cells: {
					turnover: groups[2].content[15],
					products: groups[2].content[16],
					percentage: groups[2].content[17]
				}
			},

			counterproduct: {
				group: 'counterproduct',
				action: row => this.expandProduct('counterproduct', row),
				cells: {
					turnover: groups[2].content[18],
					products: groups[2].content[19],
					percentage: groups[2].content[20]
				}
			},

			salad: {
				group: 'salad',
				action: row => this.expandProduct('salad', row),
				cells: {
					turnover: groups[2].content[21],
					products: groups[2].content[22],
					percentage: groups[2].content[23]
				}
			},

			topping: {
				group: 'topping',
				action: row => this.expandProduct('topping', row),
				cells: {
					turnover: groups[2].content[24],
					products: groups[2].content[25],
					percentage: groups[2].content[26]
				}
			},
			misc: {
				group: 'misc',
				action: row => this.expandProduct('misc', row),
				cells: {
					turnover: groups[2].content[27],
					products: groups[2].content[28],
					percentage: groups[2].content[29]
				}
			},
			employeeMeals: {
				group: 'employeeMeals',
				action: null,
				cells: {
					total: groups[3].content[0],
					percentage: groups[3].content[1]
				}
			},
			employeeDiscounts: {
				group: 'employee',
				action: null,
				cells: {
					total: groups[4].content[0],
					percentage: groups[4].content[1]
				}
			},
			employeeMealDiscounts: {
				group: 'employeeMealsDiscounts',
				action: null,
				cells: {
					total: groups[4].content[2],
					percentage: groups[4].content[3]
				}
			},
			loyaltyDiscounts: {
				group: 'loyaltyDiscounts',
				action: null,
				cells: {
					total: groups[4].content[4],
					percentage: groups[4].content[5]
				}
			},
			neighbourDiscounts: {
				group: 'neighbourDiscounts',
				action: null,
				cells: {
					total: groups[4].content[6],
					percentage: groups[4].content[7]
				}
			}
		};
	}

	render() {
		const { hover, loading } = this.state;
		const { startHover, stopHover, columns } = this;

		return (
			<table className="dynamic-pos-view__stores-view">
				<StoresViewHeader
					{...{
						...this.props,
						columns,
						hover
					}}
				/>
				<StoresViewBody
					{...{
						...this.props,
						loading,
						columns,
						startHover,
						stopHover
					}}
				/>
			</table>
		);
	}
}

StoresViewBody.propTypes = {
	market: PropTypes.number
};

export default StoresView;
