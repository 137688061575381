'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, Icon, InputCollectionSelect, InputCleave } from 'dumb';
import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';
import { ModalRow } from 'dumb/modal';

// phrases/ constants
import { types, units } from '../../../marketOvertimeRules.enums';
import phrases from '../../../marketOvertimeRules.phrases';

// Tools
import _get from 'lodash/get';

class MarketOvertimeRulesBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		const value = e.target ? e.target.value : e;

		// edits the row in the store
		this.props.editBatchListItem({
			identifier: row.identifier,
			[type]: value,
		});
	}

	editStoreDateEntry(e, row, name) {
		const value = e.target ? e.target.value : e;

		const active =
			name === 'from'
				? {
						from: value,
						to: _get(row, 'active.to', '9999-12-31'),
				  }
				: {
						from: _get(row, 'active.from', ''),
						to: value,
				  };

		// edits the row in the store
		this.props.editBatchListItem({
			identifier: row.identifier,
			...{ active },
		});
	}

	render() {
		const { batchList, addBatchListItemRow } = this.props;

		return (
			<>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_MARKET}</th>
							<th>{phrases.MODAL_BATCH_STEP_TYPE}</th>
							<th>{phrases.MODAL_BATCH_STEP_AMOUNT}</th>
							<th>{phrases.MODAL_BATCH_STEP_UNIT}</th>
							<th>{phrases.MODAL_BATCH_STEP_SECOND_AMOUNT}</th>
							<th>{phrases.MODAL_BATCH_STEP_SECOND_UNIT}</th>
							<th>{phrases.MODAL_BATCH_STEP_FROM}</th>
							<th>{phrases.MODAL_BATCH_STEP_TO}</th>

							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((entry, i) => {
							const deleteButtonDisabled = batchList.length === 1;

							return (
								<TableListRow key={entry.identifier}>
									<TableListCell>{i + 1}</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id={`name-${entry.identifier}`}
											clearable={false}
											placeholder={phrases.MARKET}
											value={entry?.market || null}
											onChange={(e) => this._editListEntry(e, entry, 'market')}
											optionFormat={(entry) => ({
												value: entry.id,
												label: entry.name,
											})}
											required
											apiPath="/organization/markets"
										/>
									</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id={`type-${entry.identifier}`}
											clearable={false}
											placeholder='Select type...'
											options={types.map((item, i) => ({
												value: item,
												label: item,
											}))}
											required
											onChange={(e) => this._editListEntry(e, entry, 'type')}
											value={_get(entry, 'type', null)}
										/>
									</TableListCell>
									<TableListCell>
										<Input
											id={`type-${entry.identifier}`}
											type="number"
											clearable={false}
											placeholder="Enter amount..."
											value={entry.amount || ''}
											required
											onChange={(event) =>
												this._editListEntry(event, entry, 'amount')
											}
										/>
									</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											placeholder='Select unit...'
											id={`unit-${entry.identifier}`}
											clearable={false}
											required
											options={units.map((item, i) => ({
												value: item,
												label: item,
											}))}
											onChange={(event) =>
												this._editListEntry(event, entry, 'unit')
											}
											value={_get(entry, 'unit', null)}
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`second_amount-${entry.identifier}`}
											type="number"
											placeholder="Add second amount..."
											value={entry.second_amount || ''}
											onChange={(event) =>
												this._editListEntry(event, entry, 'second_amount')
											}
										/>
									</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											placeholder='Select secondary unit...'
											id={`second_unit-${entry.identifier}`}
											clearable={false}
											options={units.map((item, i) => ({
												value: item,
												label: item,
											}))}
											onChange={(event) =>
												this._editListEntry(event, entry, 'second_unit')
											}
											value={_get(entry, 'second_unit', null)}
										/>
									</TableListCell>

									<TableListCell>
										<InputCleave
											id="active-from"
											placeholder="yyyy-mm-dd"
											required
											options={{
												date: true,
												delimiter: '-',
												datePattern: ['Y', 'm', 'd'],
											}}
											value={_get(entry, 'active.from', '')}
											onChange={(event) =>
												this._editListEntry(event, entry, 'from')
											}
										/>
									</TableListCell>

									<TableListCell>
										<InputCleave
											id="active-to"
											placeholder="yyyy-mm-dd"
											options={{
												date: true,
												delimiter: '-',
												datePattern: ['Y', 'm', 'd'],
											}}
											value={_get(entry, 'active.to', '9999-12-31')}
											onChange={(event) =>
												this._editListEntry(event, entry, 'to')
											}
										/>
									</TableListCell>

									<TableListCell>
										<Button
											type="negative"
											size="tiny"
											disabled={deleteButtonDisabled}
											onClick={() => this._onRemoveEntry(entry.identifier)}>
											<Icon name="delete" />
										</Button>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>

				<ModalRow
					typeAddNewRow
					tooltipZIndex={600}
					tooltipText={phrases.MODAL_BATCH_STEP_LIST_TOOLTIP}
					onClick={addBatchListItemRow}
				/>
			</>
		);
	}
}

MarketOvertimeRulesBatchStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
};

export default MarketOvertimeRulesBatchStepList;
