'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');
const __regex = require('regex.service');

export default class MailinglistRecipientsList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'email',
				type: 'mail',
				regex: __regex.email,
				canAdd: true,
				canEdit: true,
				required: true,
			},
		];
		this.settings = {
			name: 'MailinglistRecipients',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			pageTitle: true,
			canManipulate: () => validAccess(undefined, ['Global BI Configuration']),
			searchList: {
				canAdd: true,
				canDelete: true,
			},
			translationPath: 'ADMINISTRATION.MAILINGLIST_RECIPIENTS',
			endpoint: __endpoints.collection.administration.mailinglist_recipients,
		};
		this.init();
		return this;
	}
}
