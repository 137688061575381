'use strict';

/**
 * HotbarComponentAbstract
 * @param  {string} title
 * @param  {bool} required
 */
class HotbarComponentFactoryAbstract {
	constructor(title, required = false) {
		/// ////////
		// Title //
		/// ////////

		this.title = title;

		/// ///////////
		// Required //
		/// ///////////

		this.required = required;

		/// ////////
		// Value //
		/// ////////

		this.value = null;
	}

	/**
	 * @function setSelectedValues
	 * @memberOf HotbarComponentAbstract
	 * @description Sets value of current component
	 * @param {object} event
	 */
	setSelectedValues(event) {
		this.value = event;
	}

	/**
	 * @function getSelectedValues
	 * @memberOf HotbarComponentAbstract
	 * @description Gets value of current component
	 */
	getSelectedValues() {
		return this.value || null;
	}

	/**
	 * @function setDependencies
	 * @memberOf HotbarComponentAbstract
	 * @description Sets dependencies
	 * @param {object} dependencies
	 */
	setDependencies(dependencies) {
		this.dependencies = dependencies;
	}

	/**
	 * @function hasDependency
	 * @memberOf HotbarComponentAbstract
	 * @description Checks if dependency is available
	 * @param {string} componentIdentifier
	 */
	hasDependency(componentIdentifier) {
		if (this.dependencies === null) return false;
		return (
			Object.keys(this.dependencies)
				.map(key => this.dependencies[key])
				.indexOf(componentIdentifier) > -1
		);
	}

	/**
	 * @function getDependencyKey
	 * @memberOf HotbarComponentAbstract
	 * @description Gets dependency key based on value
	 * @param {string} componentIdentifier
	 */
	getDependencyKey(componentIdentifier) {
		if (!this.hasDependency(componentIdentifier)) return false;
		return Object.keys(this.dependencies).filter(
			key => this.dependencies[key] === componentIdentifier
		)[0];
	}

	/**
	 * @function isRequired
	 * @memberOf HotbarComponentAbstract
	 * @description Is component required
	 */
	isRequired() {
		return this.required;
	}

	/**
	 * @function isSet
	 * @memberOf HotbarComponentAbstract
	 * @description Is component set
	 */
	isSet() {
		return this.getSelectedValues() !== null;
	}

	/**
	 * @function isValid
	 * @memberOf HotbarComponentAbstract
	 * @description Is component valid - a general default that just returns true
	 */
	isValid() {
		return true;
	}
}

export default HotbarComponentFactoryAbstract;
