'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import {
	// batch stuff
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setProductBundle,
	updateProductBundle,
	resetProductBundle,
} from './store/shiftplanSeniority.actions';

// services
import {
	fetchShiftplanSeniorities,
	editShiftplanSeniority,
	editShiftplanSeniorities,
	addShiftplanSeniority,
	deleteShiftplanSeniority,
} from './shiftplanSeniority.service';
import { getColorCoding } from 'services/utils';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Input, Button, Icon } from 'dumb';

import ShiftplanSeniorityModalBatch from './components/batch/shiftplanSeniorityModalBatch';

// phrases
import phrases from './shiftplanSeniority.phrases';

class ProductBundles extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.addProductBundleWrapper = this.addProductBundleWrapper.bind(this);
		this.fetchData = this.fetchData.bind(this);

		this.columns = [
			{
				Header: 'Color',
				id: 'color',
				filterable: false,
				sortable: false,
				width: 50,
				Cell: (d) => {
					const maximumHours = d.original?.maximum_hours;
					const minimumHours = d.original?.minimum_hours;

					const colorCoding = getColorCoding({
						maximumHours,
						minimumHours,
					});

					return colorCoding ? (
						<div
							style={{
								backgroundColor: `rgb(${window
									.getComputedStyle(document.body)
									.getPropertyValue(colorCoding.variableName)})`,
								height: 10,
								width: 10,
								borderRadius: 10,
							}}
						/>
					) : null;
				},
			},
			{
				Header: 'Name',
				id: 'name',
				accessor: 'name',
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'desc',
				accessor: 'description',
				filterPath: ':description',
			},
			{
				Header: 'Badge',
				id: 'badge',
				accessor: 'badge',
				filterPath: ':badge',
			},
			{
				Header: 'Minimum hours',
				id: 'minimumHours',
				accessor: 'minimum_hours',
				filterPath: ':minimum_hours',
			},
			{
				Header: 'Maximum hours',
				id: 'maximumHours',
				accessor: 'maximum_hours',
				filterPath: ':maximum_hours',
			},
		];
	}

	editEntry() {
		const { defaultShiftplanSeniority } = this.props;

		const payload = {
			id: defaultShiftplanSeniority.id,
			name: defaultShiftplanSeniority.name,
			description: defaultShiftplanSeniority.description,
			badge: defaultShiftplanSeniority.badge,
		};

		return editShiftplanSeniority(payload);
	}

	editMultiple(selectedRows) {
		const { defaultShiftplanSeniority } = this.props;

		const payload = {
			...(defaultShiftplanSeniority.name && {
				name: defaultShiftplanSeniority.name,
			}),
			...(defaultShiftplanSeniority.description && {
				description: defaultShiftplanSeniority.description,
			}),
			...(defaultShiftplanSeniority.badge && {
				badge: defaultShiftplanSeniority.badge,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editShiftplanSeniorities({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteShiftplanSeniority(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			badge: data.badge,
		};

		this.props.setProductBundle(payload);
	}

	getEditableCells() {
		const { defaultShiftplanSeniority } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={defaultShiftplanSeniority.name || ''}
						onChange={(event) => this.editStoreEntry('name', event)}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={defaultShiftplanSeniority.description || ''}
						onChange={(event) => this.editStoreEntry('description', event)}
					/>
				),
			},
			{
				header: 'Badge',
				value: (
					<Input
						id="badge"
						placeholder="Enter badge..."
						value={defaultShiftplanSeniority.badge || null}
						onChange={(event) => this.editStoreEntry('badge', event)}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const value = e.target.value;

		const updateObject = {
			[name]: value,
		};

		this.props.updateProductBundle(updateObject);
	}

	fetchData(state) {
		return fetchShiftplanSeniorities(state);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetProductBundle();
	}

	addProductBundleWrapper(data) {
		return addShiftplanSeniority(data).then(() => {
			this.setState(() => ({
				key: moment.utc(),
			}));
		});
	}

	render() {
		const { batchList, editBatchListItem, addBatchListItemRow, removeBatchListItem } = this.props;

		return (
			<>
				<ReactDataWrapper
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey="/shiftplanning/seniority_levels"
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					accessAreasAllowedToEdit={['Global Sales Configuration', 'Shiftplan Admin']}

					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetProductBundle}
					actionRender={
						<Button type="inverted" label="Batch" shadow onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
				/>

				<ShiftplanSeniorityModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addShiftplanSeniority={this.addProductBundleWrapper}
				/>
			</>
		);
	}
}

ProductBundles.propTypes = {
	defaultShiftplanSeniority: PropTypes.object,

	updateProductBundle: PropTypes.func,
	setProductBundle: PropTypes.func,
	resetProductBundle: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			editBatchListItem,
			removeBatchListItem,
			updateProductBundle,
			setProductBundle,
			resetProductBundle,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultShiftplanSeniority: store.globalAdministrationShiftplanSeniority.data.defaultShiftplanSeniority,
		batchList: store.globalAdministrationShiftplanSeniority.data.batchList,
	};
};

export default connectWithStore(ProductBundles, mapStateToProps, mapDispatchToProps);
