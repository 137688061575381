import { get } from 'api.vanilla.service';
import { store } from 'appState';
import { saveAs } from 'file-saver';
import * as actions from './../employeeMeals.actions';
import employeeMealsReportParser from './../utilities/employeeMealsReportParser';

let abortController = new AbortController();
let signal = abortController.signal;

export default function fetchEmployeeMealsReport(
	{ endDate, startDate, reportingCurrencyId, marketId } = {},
	personId = null,
	headers
) {
	const params = {
		filter: marketId ? `:market.id=='${marketId}'` : null,
		reporting_currency: reportingCurrencyId,
		to: endDate.format('YYYY-MM-DD'),
		from: startDate.format('YYYY-MM-DD'),
		type: 'Entries',
		person: personId
	};

	return get(
		'/reporting/employee_meals_reports',
		params,
		null,
		headers,
		signal
	).then(response => {
		// If headers are present, that means we are trying to fetch the data as a csv
		if (headers) {
			const blob = new Blob([response], { type: 'text/csv;charset=utf-8' });
			const fileName = `employee_meals_${params.from}_${params.to}.csv`;
			saveAs(blob, fileName);

			// If not, its business as usual
		} else {
			let report = response.data;

			report = employeeMealsReportParser(report);

			store.dispatch(actions.setEmployeeMealsReport(report));
		}
	});
}

/**
 * @function abortFetchShiftplannerReport
 * @public
 * @description Aborts meal report hotbar calls
 */
export function abortEmloyeeMealReport() {
	abortController.abort();

	abortController = new AbortController();
	signal = abortController.signal;
}
