'use strict';

// Native
import React, { Component } from 'react';
// Redux
import PropTypes from 'prop-types';
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// actions/services
import {
	setIngredientPricingGroupToEdit,
	updateIngredientPricingGroups,
	resetState,
} from './store/ingredientPricingGroups.actions';
import {
	fetchIngredientPricingGroups,
	editIngredientPricingGroup,
	addIngredientPricingGroup,
	deleteIngredientPricingGroup,
} from './store/ingredientPricingGroups.service';

// Components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Input, Button, Toggle } from 'dumb';

// Other
import _get from 'lodash/get';
import Phrases from './ingredientPricingGroups.phrases';

class IngredientPricing extends Component {
	constructor(props) {
		super(props);

		this.state = {
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this.reduxKey = 'product/ingredient_pricings';

		this.columns = [
			{
				Header: Phrases.TABLE_MODAL_HEADER_CATEGORY,
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: 'legacy',
				filterPath: ':legacy',
				filterable: false,
				Cell: (d) => {
					return <Input type="checkbox" checked={d.original.legacy} disabled />;
				},
			},
		];

		this.addEntry = this.addEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.resetState = this.resetState.bind(this);
		this.editIngredientPricingGroup = this.editIngredientPricingGroup.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}
			>
				Legacy
			</Button>
		);
	}

	/**
	 * @function fetchData
	 * @param {Object} state
	 * @description returns ingredient pricings
	 */
	fetchData(state) {
		return fetchIngredientPricingGroups(state);
	}

	/**
	 * @function deleteEntry
	 * @param {Number} id
	 * @description deletes ingredient pricing
	 */
	deleteEntry(id) {
		return deleteIngredientPricingGroup(id);
	}

	/**
	 * @function editEntry
	 * @description edits a single unit
	 */
	editEntry() {
		const { editedValues } = this.props;

		const payload = {
			name: _get(editedValues, 'name', ''),
			legacy: !!editedValues.legacy,
		};

		return editIngredientPricingGroup(editedValues.id, payload);
	}

	/**
	 * @function addEntry
	 * @description adds an entry
	 */
	addEntry() {
		const { editedValues } = this.props;

		const payload = {
			name: _get(editedValues, 'name', ''),
		};

		return addIngredientPricingGroup(payload);
	}

	/**
	 * @function resetState
	 * @description resets the state to 0
	 */
	resetState() {
		this.props.resetState();
	}

	setInitialEditValues(data) {
		const { setIngredientPricingGroupToEdit } = this.props;

		this.resetState();

		const payload = {
			id: data.id,
			name: data.name,
		};

		setIngredientPricingGroupToEdit(payload);
	}

	getEditableCells(e) {
		const { editedValues } = this.props;

		return [
			{
				header: Phrases.TABLE_MODAL_HEADER_CATEGORY,
				value: (
					<Input
						id="name"
						placeholder="name"
						value={_get(editedValues, 'name', '')}
						onChange={(event) => this.editIngredientPricingGroup(event, 'name')}
					/>
				),
			},
			{
				header: 'Legacy',
				value: (
					<Toggle
						id="legacy"
						toggled={editedValues.legacy}
						onClick={(event) => this.editIngredientPricingGroup(event, 'legacy')}
					/>
				),
			},
		];
	}

	/**
	 * @function editIngredientPricingGroup
	 * @param {Object} e - event
	 * @param {String} type - type that we're editing
	 * @description add a new ingredient pricing
	 */
	editIngredientPricingGroup(e, type) {
		const value = e.target ? e.target.value : e;

		this.props.updateIngredientPricingGroups({
			[type]: value,
		});
	}

	render() {
		const {
			// batchSelection props
			onInitialization,
			reduxKey: batchReduxKey,
			style,
			defaultPageSize,
			batchSelection,
		} = this.props;

		const legacyFilter = this.state.legacyFilterOn ? this.state.legacyFilter : '';

		return (
			<ReactDataWrapper
				accessAreasAllowedToEdit={['Sales Configuration']}
				title={Phrases.INGREDIENT_PRICINGS}
				defaultPageSize={batchSelection ? defaultPageSize : 10}
				reduxKey={batchSelection ? batchReduxKey : this.reduxKey}
				style={batchSelection ? style : {}}
				{...(batchSelection && { onInitialization, batchSelection: true })}
				batchSelection={batchSelection}
				columns={this.columns}
				fetchData={this.fetchData} // Request new data when things change
				filterable
				manual // Forces table not to paginate or sort automatically, so we can handle it server-side
				editEntry={(e) => this.editEntry(e)}
				editableCells={this.getEditableCells()}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetState}
				actionsWidth={60}
				deleteEntry={this.deleteEntry}
				createEntry={this.addEntry}
				multiEdit={false}
				multiSelect={false}
				customAreaComponents={this.getLegacyFilterButton()}
				extraFilters={legacyFilter}
			/>
		);
	}
}

IngredientPricing.propTypes = {
	editedValues: PropTypes.object,
	updateIngredientPricingGroups: PropTypes.func,
	resetState: PropTypes.func,
	setIngredientPricingGroupToEdit: PropTypes.func,
	onInitialization: PropTypes.func,
	reduxKey: PropTypes.string,
	style: PropTypes.object,
	defaultPageSize: PropTypes.number,
	batchSelection: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setIngredientPricingGroupToEdit,
			updateIngredientPricingGroups,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		editedValues: store.ingredientPricingGroups.data.editedValues,
	};
};

export default connectWithStore(IngredientPricing, mapStateToProps, mapDispatchToProps);
