'use strict';

export const SET_METADATA = 'REPORT/SET_METADATA';
export const SET_TABLES = 'REPORT/SET_TABLES';
export const SET_TABLE_CONTENT = 'REPORT/SET_TABLE_CONTENT';
export const SET_TABLE_EXPANDED = 'REPORT/SET_TABLE_EXPANDED';
export const SET_ALL_TABLES_EXPANDED = 'REPORT/SET_ALL_TABLES_EXPANDED';
export const RESET_REPORT = 'REPORT/RESET_REPORT';

/**
 * @param {string} reportName
 * @param {object} metadata
 */
export function setMetadata(reportName, metadata) {
	return {
		type: SET_METADATA,
		payload: {
			reportName,
			metadata
		}
	};
}

/**
 * @param {string} reportName
 * @param {object} tables
 */
export function setTables(reportName, tables) {
	return {
		type: SET_TABLES,
		payload: {
			reportName,
			tables
		}
	};
}

/**
 * @param {string} reportName
 * @param {object} tables
 */
export function setTableContent(reportName, tableName, content) {
	return {
		type: SET_TABLE_CONTENT,
		payload: {
			reportName,
			tableName,
			content
		}
	};
}

/**
 * @param {string} reportName
 * @param {string} tableName
 * @param {boolean} expanded
 */
export function setTableExpanded(reportName, tableName, expanded) {
	return {
		type: SET_TABLE_EXPANDED,
		payload: {
			reportName,
			tableName,
			expanded
		}
	};
}

/**
 * @param {string} reportName
 * @param {string} tableList
 */
export function setAllTablesExpanded(reportName, tableList) {
	return {
		type: SET_ALL_TABLES_EXPANDED,
		payload: {
			reportName,
			tableList
		}
	};
}

/**
 * @param {string} reportName
 */
export function resetReport(reportName) {
	return {
		type: RESET_REPORT,
		payload: {
			reportName
		}
	};
}