'use strict';

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import ErrorBoundary from 'reusableComponents/errorBoundary';

import './modal.css';

function modal(WrappedComponent) {
	class Modal extends Component {
		constructor(props) {
			super(props);
			this.handleKeypress = this.handleKeypress.bind(this);

			// get modal-root element to create portal.
			this.rootElement = document.getElementById('modal-root');
		}

		/**
		 * If visible attach event listener for esc keypress
		 * @param nextProps
		 */
		UNSAFE_componentWillReceiveProps(nextProps) {
			if (nextProps.visible) {
				document.addEventListener('keydown', this.handleKeypress, false);
			} else {
				document.removeEventListener('keydown', this.handleKeypress);
			}
		}

		componentWillUnmount() {
			document.removeEventListener('keydown', this.handleKeypress);
		}

		/**
		 * Press escape to close modal
		 * @param e
		 */
		handleKeypress(e) {
			const { handleClose, visible } = this.props;

			if ((visible === true && e.charCode == 27) || e.keyCode == 27) {
				document.removeEventListener('keydown', this.handleKeypress);
				handleClose();
			}
		}

		close(e) {
			e.preventDefault();
			e.stopPropagation();
			const { handleClose } = this.props;

			handleClose();
		}

		stopPropagation(e) {
			e.stopPropagation();
		}

		render() {
			const {
				visible,
				handleClose,
				title,
				modifierClassName,
				zIndex
			} = this.props;

			return ReactDOM.createPortal(
				<CSSTransition
					classNames="modal--fade"
					timeout={200}
					in={visible}
					mountOnEnter
					unmountOnExit>
					<div
						className={cx('modal__overtake', modifierClassName)}
						style={zIndex ? { zIndex: zIndex } : null}
						onClick={e => this.close(e)}>
						<div
							className="modal__window"
							onClick={e => this.stopPropagation(e)}
							onDoubleClick={e => this.stopPropagation(e)}
							style={zIndex ? { zIndex: zIndex + 2 } : null}>
							<div className="modal__header">
								{title}
								<span
									className="icon icon--clear modal__header__close"
									onClick={e => handleClose(e)}
								/>
							</div>
							<div className="modal__content">
								<ErrorBoundary
									errorHeader="An error accoured"
									errorSubheader="Refresh the browser to make sure you are not working on corrupted data">
									<WrappedComponent {...this.props} />
								</ErrorBoundary>
							</div>
						</div>
					</div>
				</CSSTransition>,
				this.rootElement
			);
		}
	}

	Modal.defaultProps = {
		visible: false,
		title: '',
		zIndex: null,
		modifierClassName: '',
		handleClose() {}
	};

	Modal.propTypes = {
		visible: PropTypes.bool.isRequired,
		title: PropTypes.string,
		modifierClassName: PropTypes.string,
		handleClose: PropTypes.func.isRequired
	};

	return Modal;
}

export default modal;
