'use strict';

import _uniqueId from 'lodash/uniqueId';

import {
	SET_RAW_INGREDIENT_CATEGORIES_MODAL,
	UPDATE_RAW_INGREDIENT_CATEGORIES,
	RESET_STATE,
	ADD_BATCH_LIST,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	ADD_BATCH_LIST_ITEM_ROW,
	UPDATE_BATCH_FORM
} from './rawIngredients.actions';

const defaultState = {
	data: {
		rawIngredients: {},
		batchList: [
			{
				identifier: _uniqueId('row-'),
				name: '',
				description: ''
			}
		],
		batchFormData: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_RAW_INGREDIENT_CATEGORIES_MODAL: {
		return {
			...state,
			data: {
				rawIngredients: payload
			}
		};
	}

	case UPDATE_RAW_INGREDIENT_CATEGORIES: {
		return {
			...state,
			data: {
				rawIngredients: {
					...state.data.rawIngredients,
					...payload
				}
			}
		};
	}

	case RESET_STATE: {
		return defaultState;
	}

	case UPDATE_BATCH_FORM: {
		return {
			...state,
			data: {
				...state.data,
				batchFormData: {
					...state.data.batchFormData,
					...payload
				}
			}
		};
	}

	case ADD_BATCH_LIST: {
		return {
			...state,
			data: {
				...state.data,
				batchList: payload
			}
		};
	}

	case EDIT_BATCH_LIST_ITEM: {
		return {
			...state,
			data: {
				...state.data,
				batchList: state.data.batchList.map(entry => {
					if (entry.identifier === payload.identifier) return payload;
					return entry;
				})
			}
		};
	}

	case REMOVE_BATCH_LIST_ITEM: {
		return {
			...state,
			data: {
				...state.data,
				batchList: state.data.batchList.filter(
					entry => entry.identifier !== payload
				)
			}
		};
	}

	case ADD_BATCH_LIST_ITEM_ROW: {
		return {
			...state,
			data: {
				...state.data,
				batchList: [
					...state.data.batchList,
					{
						...defaultState.data.batchList[0],
						identifier: _uniqueId('row-')
					}
				]
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
