import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import {
	addUsersnapService,
	initSentry,
	initLogrocket,
} from 'services/tracking';
import { isProduction } from 'environment';
import AppWrapper from './appWrapper';

import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { PersistGate } from 'redux-persist/es/integration/react';
import store, { history, persistor } from 'appState/store';
import Auth0ProviderWithHistory from 'services/auth0history';
import RouterListProvider from './config/routerProvider';
import AccessRightsProvider from './config/accessRightsProvider';
import setupLogRocketReact from 'logrocket-react';
import LogRocket from 'logrocket';
import { SnackbarProvider } from 'notistack';
import invokeStore from './invokeStore';
import { inject as injectVercelAnalytics } from '@vercel/analytics';

// MUI
import { ThemeProvider } from '@mui/material/styles';
import { Box, CircularProgress } from '@mui/material';
import { Notifier } from 'smart';
import theme from '../config/theme';
import { sendToVercelAnalytics } from '../vitals';
import reportWebVitals from '../reportWebVitals';

invokeStore(store);

// analytics tracking
injectVercelAnalytics();
reportWebVitals(sendToVercelAnalytics);

export default function Root() {
	useEffect(() => {
		initLogrocket();

		addUsersnapService();

		initSentry();
	}, []);

	return (
		// <React.StrictMode>
		<Provider store={store}>
			<PersistGate
				loading={
					<Box sx={{ display: 'flex' }}>
						<CircularProgress />
					</Box>
				}
				persistor={persistor}>
				{/* Notificaiton bar */}
				<Router history={history}>
					<ThemeProvider theme={theme}>
						<SnackbarProvider maxSnack={3}>
							<Auth0ProviderWithHistory>
								<AccessRightsProvider>
									<RouterListProvider>
										<AppWrapper />
										<Notifier />
									</RouterListProvider>
								</AccessRightsProvider>
							</Auth0ProviderWithHistory>
						</SnackbarProvider>
					</ThemeProvider>
				</Router>
			</PersistGate>
		</Provider>
		// </React.StrictMode>
	);
}
