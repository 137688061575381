export const RESET_WORKPLACE_EXPECTED_WAITING_TIMES =
	'ORGANISATION_WORKPLACES_WORKPLACE_EXPECTED_WAITING_TIMES/RESET_WORKPLACE_EXPECTED_WAITING_TIMES';
export const SET_WORKPLACE_EXPECTED_WAITING_TIMES =
	'ORGANISATION_WORKPLACES_WORKPLACE_EXPECTED_WAITING_TIMES/SET_WORKPLACE_EXPECTED_WAITING_TIMES';
export const UPDATE_WORKPLACE_EXPECTED_WAITING_TIMES =
	'ORGANISATION_WORKPLACES_WORKPLACE_EXPECTED_WAITING_TIMES/UPDATE_WORKPLACE_EXPECTED_WAITING_TIMES';

export const EDIT_BATCH_LIST_ITEM =
	'ORGANISATION_WORKPLACES_WORKPLACE_EXPECTED_WAITING_TIMES/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'ORGANISATION_WORKPLACES_WORKPLACE_EXPECTED_WAITING_TIMES/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'ORGANISATION_WORKPLACES_WORKPLACE_EXPECTED_WAITING_TIMES/ADD_BATCH_LIST_ITEM_ROW';

/**
 * @param {object} payload
 */
export function setWorkplaceExpectedWaitingTimes(payload) {
	return {
		type: SET_WORKPLACE_EXPECTED_WAITING_TIMES,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function updateWorkplaceExpectedWaitingTimes(payload) {
	return {
		type: UPDATE_WORKPLACE_EXPECTED_WAITING_TIMES,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetWorkplaceExpectedWaitingTimes() {
	return {
		type: RESET_WORKPLACE_EXPECTED_WAITING_TIMES,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}
