export default {
	TABLE_TITLE: 'Companies',
	MARKET_RESPONSIBILITY_ADDED: 'Company added',
	MARKET_RESPONSIBILITY_EDITED: 'Company edited',
	MARKET_RESPONSIBILITIES_EDITED: 'Companies edited',
	MARKET_RESPONSIBILITIY_DELETED: 'Company deleted',
	DETAILS_TOOLTIP: 'Details',
	LEGACY: 'Legacy',
	COPY_ONBOARDING_LINK: 'Click to copy onboarding link',
	COPY_ONBOARDING_LINK_COPIED: 'Link copied to clipboard!',
};
