'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  connectWithStore } from 'appState';

import { getProducts } from './services/products.service.js';

import {
	updateTilePosition,
	rotateTiles,
	addTile,
	updateTile,
	removeTile,
} from './services/tileLayoutTiles.service';

import { updatePositions } from './posConfigurationLayout.actions';

// New Service Layer
import { getTileLayout, cleanUp } from './services/tileLayout.service';

import {
	getTileLayoutGroups,
	getLayoutGroup,
	addLayoutGroup,
	updateLayoutGroup,
	deleteLayoutGroup,
	rotateLayoutGroup,
} from './services/tileLayoutGroups.service';

import { getAssets } from './services/assets.service';

// Components
import AppLayout from './appLayout/appLayout.container';
import PosLayout from './posLayout/posLayout.container';

// Other
import phrases from './posConfigurationLayout.phrases';
import './posConfigurationLayout.css';

class POSConfigurationLayout extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeTile: {},
			grid: {},
			gridMetaData: [],
			gridSize: null,
			groupItemInFocus: null,
			page: 1,
			posConfigurationsVariantsList: [],
			tiles: [],
			tilesInGroup: {},
			images: [],
			loading: false,
			selectedImage: {},
			totalEntries: 0,
		};
		this.phrases = phrases;

		this.addLayoutGroup = this.addLayoutGroup.bind(this);
		this.addTile = this.addTile.bind(this);

		this.deleteLayoutGroup = this.deleteLayoutGroup.bind(this);

		this.rotateTiles = this.rotateTiles.bind(this);
		this.setMenuItemInFocus = this.setMenuItemInFocus.bind(this);
		this.setPage = this.setPage.bind(this);
		this.updateLayoutGroup = this.updateLayoutGroup.bind(this);
		this.updateTile = this.updateTile.bind(this);
		this.updateTilePosition = this.updateTilePosition.bind(this);
		this.removeTile = this.removeTile.bind(this);
	}

	componentDidMount() {
		getTileLayout(this.props.layoutId);
		getTileLayoutGroups(this.props.layoutId);
		getProducts(this.props.versionId);
		getAssets({
			type: this.props.type,
			layoutId: this.props.layoutId,
			subType: this.props.subType,
		});
	}

	componentWillUnmount() {
		cleanUp();
	}

	/**
	 * @function updatePositions
	 * @deprecated !!!!! <----- This is to die soon
	 * @param dragIndex
	 * @param hoverIndex
	 */
	updatePositions(dragIndex, hoverIndex) {
		updatePositions(dragIndex, hoverIndex);
	}

	/**
	 * @function updateTilePosition
	 * @deprecated !!!!! <----- This is to die soon
	 * @param dragIndex
	 * @param hoverIndex
	 */
	updateTilePosition(dragIndex, hoverIndex) {
		const tilesInGroup = this.props.tilesInGroup;
		updateTilePosition(dragIndex, hoverIndex, tilesInGroup);
	}

	/**
	 * @function updateTilePosition
	 */
	rotateTiles(payload, isArray) {
		rotateTiles(payload, isArray);
	}

	/**
	 * @function setMenuItemInFocus
	 * @param groupId
	 */
	setMenuItemInFocus(id = null, type) {
		// Set page to the first page
		this.setPage(1);

		// Get group and show group layout
		return getLayoutGroup(id, type);
	}

	/**
	 * @function addLayoutGroup
	 * @param data
	 */
	addLayoutGroup(data = {}) {
		return addLayoutGroup(data, this.props.layoutId);
	}

	/**
	 * @function updateLayoutGroup
	 * @param data
	 * @param groupId
	 * @returns {Promise.<TResult>|*|{anyOf}}
	 */
	updateLayoutGroup(data = {}, groupId = null) {
		return updateLayoutGroup(data, groupId);
	}

	/**
	 * @function deleteLayoutGroup
	 * @param groupId
	 * @returns {Promise.<TResult>|*|{anyOf}}
	 */
	deleteLayoutGroup(groupId = null) {
		return deleteLayoutGroup(groupId);
	}

	/**
	 * @function updateTile
	 * @param data
	 */
	updateTile(data) {
		return updateTile(
			data,
			this.props.tilesInGroup,
			this.props.groupItemInFocus
		);
	}

	/**
	 * @function addTile
	 * @param data
	 */
	addTile(data = {}) {
		return addTile(
			data,
			this.props.groupItemInFocus,
			this.props.tilesInGroup,
			this.props.layoutId
		);
	}

	/**
	 * @function removeTile
	 * @param tileId
	 * @param tileProductVariantId
	 */
	removeTile(tileId = null) {
		return removeTile(tileId);
	}

	/**
	 * @function setPage
	 * @param page
	 */
	setPage(page = 1) {
		this.setState(() => ({ page }));
	}

	render() {
		const { page } = this.state;

		const {
			activeTile,
			fetching,
			grid,
			gridDimensions,
			gridMetaData,
			gridSize,
			groupItemInFocus,
			groups,
			imageAssets,
			posConfigurationsVariantsList,
			posFullConfigurationsVariantsList,
			tilesInGroup,
			posConfUi,
			type,
			layoutId,
			subType,
		} = this.props;

		return type === 'App' || type === 'Delivery' ? (
			<AppLayout
				posFullConfigurationsVariantsList={posFullConfigurationsVariantsList}
				activeTile={activeTile}
				addLayoutGroup={this.addLayoutGroup}
				addTile={this.addTile}
				deleteLayoutGroup={this.deleteLayoutGroup}
				removeTile={this.removeTile}
				fetching={fetching}
				grid={grid}
				gridDimensions={gridDimensions}
				gridMetaData={gridMetaData}
				gridSize={gridSize}
				groupItemInFocus={groupItemInFocus}
				groups={groups}
				imageAssets={imageAssets}
				page={page}
				phrases={this.phrases}
				posConfigurationsVariantsList={posConfigurationsVariantsList}
				rotateTiles={this.rotateTiles}
				rotateLayoutGroup={rotateLayoutGroup}
				setMenuItemInFocus={this.setMenuItemInFocus}
				tilesInGroup={tilesInGroup}
				updateLayoutGroup={this.updateLayoutGroup}
				updateTile={this.updateTile}
				layoutId={layoutId}
				type={type}
				subType={subType}
			/>
		) : (
			<PosLayout
				activeTile={activeTile}
				addTile={this.addTile}
				removeTile={this.removeTile}
				fetching={fetching}
				addLayoutGroup={this.addLayoutGroup}
				updateLayoutGroup={this.updateLayoutGroup}
				deleteLayoutGroup={this.deleteLayoutGroup}
				grid={grid}
				gridDimensions={gridDimensions}
				gridMetaData={gridMetaData}
				gridSize={gridSize}
				groupItemInFocus={groupItemInFocus}
				groups={groups}
				imageAssets={imageAssets}
				page={page}
				phrases={this.phrases}
				posConfigurationsVariantsList={posConfigurationsVariantsList}
				posFullConfigurationsVariantsList={posFullConfigurationsVariantsList}
				rotateTiles={this.rotateTiles}
				setMenuItemInFocus={this.setMenuItemInFocus}
				setPage={this.setPage}
				tilesInGroup={tilesInGroup}
				updateTile={this.updateTile}
				updateTilePosition={this.updateTilePosition}
				layoutId={layoutId}
				posConfUi={posConfUi}
				type={type}
			/>
		);
	}
}

POSConfigurationLayout.defaultProps = {
	activeTile: {},
	fetching: false,
	grid: null,
	gridMetaData: {},
	gridSize: null,
	groupItemInFocus: null,
	groups: {},
	assets: [],
	posConfigurationsVariantsList: [],
	tilesInGroup: null,
};

POSConfigurationLayout.propTypes = {
	layoutId: PropTypes.number,
	versionId: PropTypes.number,
	tilesInGroup: PropTypes.array,
	groupItemInFocus: PropTypes.number,
	activeTile: PropTypes.object,
	fetching: PropTypes.bool,
	grid: PropTypes.object,
	gridDimensions: PropTypes.array,
	gridMetaData: PropTypes.object,
	gridSize: PropTypes.number,
	groups: PropTypes.object,
	imageAssets: PropTypes.array,
	posConfigurationsVariantsList: PropTypes.array,
	posFullConfigurationsVariantsList: PropTypes.array,
	posConfUi: PropTypes.object,
	type: PropTypes.string,
	subType: PropTypes.string,
};

const mapStateToPropsFactory = () => (store) => {
	return {
		activeTile: store.salesConfigurationPOSLayout.activeTile,
		fetching: store.salesConfigurationPOSLayout.fetching,
		grid: store.salesConfigurationPOSLayout.grid,
		gridDimensions: store.salesConfigurationPOSLayout.gridDimensions,
		gridMetaData: store.salesConfigurationPOSLayout.gridMetaData,
		gridSize: store.salesConfigurationPOSLayout.gridSize,
		groupItemInFocus: store.salesConfigurationPOSLayout.groupItemInFocus,
		groups: store.salesConfigurationPOSLayout.groups,
		imageAssets: store.salesConfigurationPOSLayout.assets,
		posConfigurationsVariantsList:
			store.salesConfigurationPOSLayout.posConfigurationsVariantsList,
		posFullConfigurationsVariantsList:
			store.salesConfigurationPOSLayout.posFullConfigurationsVariantsList,
		tilesInGroup: store.salesConfigurationPOSLayout.tilesInGroup,
		posConfUi: store.salesConfigurationPOSLayout.ui,
	};
};

export default connectWithStore(
	POSConfigurationLayout,
	mapStateToPropsFactory
);
