'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// rdw actions
import { store } from 'appState';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';

// components
import { ModalStepper, Overlay } from 'dumb';

// tables for batch view
import PosConfigurationVersionTable from './../../../../pos/components/posConfigurationVersions/posConfigurationVersions.component';
import IngredientPricingGroupsTable from './../../../../ingredients/components/ingredientPricingGroups/ingredientPricingGroups.component';

// different batch views
import IngredientPricingGroupsStepForm from './components/ingredientPricingGroupsStepFrom';
import IngredientPricingGroupsStepList from './components/ingredientPricingGroupsStepList';

// utils
import { getFilter, markValuesWithCanAdd } from './../utils/utils';

// phrases
import phrases from './../posConfigurationIngredientPricingGroups.phrases';
import constants from 'services/constants';

// loooodash
import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';
import _uniqBy from 'lodash/uniqBy';
import _isEmpty from 'lodash/isEmpty';

class IngredientPricingGroupsModalBatch extends Component {
	constructor(props) {
		super(props);

		this.today = moment.utc().format(constants.shortDate);
		this.posConfigurationVersionReduxKey =
			'ingredient-pricing-groups/pos/pos_configuration_versions';
		this.ingredientPricingGroupReduxKey =
			'ingredient-pricing-groups/product/ingredient_pricing_groups';

		this.state = {
			showOverlay: false,
			overlayType: null,
			activeFilterOn: true,
			activeFilter: `:active.to=ge='${this.today}'`,
			allowOverflow: true,
		};

		this.handleToggleOverlay = this.handleToggleOverlay.bind(this);
		this.onOverlayOpen = this.onOverlayOpen.bind(this);
		this._getIsBatchStepFormValid = this._getIsBatchStepFormValid.bind(this);

		this.modalRef = React.createRef();
	}

	handleToggleOverlay(type) {
		const {
			listPosConfigurationVersions,
			listIngredientPricingGroups,
			updateBatchForm,
		} = this.props;

		if (this.state.showOverlay) {
			if (this.state.overlayType === this.posConfigurationVersionReduxKey) {
				const selectedRowsInList = _get(
					listPosConfigurationVersions,
					'ui.selectedRows',
					[]
				);

				updateBatchForm({
					posConfigurationVersion: selectedRowsInList.map((entry) => {
						return { label: entry.pos_configuration.name, value: entry };
					}),
				});
			} else {
				const selectedRowsInList = _get(
					listIngredientPricingGroups,
					'ui.selectedRows',
					[]
				);

				updateBatchForm({
					ingredientPricingGroup: selectedRowsInList.map((entry) => {
						return { label: entry.name, value: entry };
					}),
				});
			}
		}

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
			overlayType: prevState.overlayType ? '' : type,
		}));
	}

	onOverlayOpen() {
		const { batchFormData } = this.props;

		const dataToSetColumnsSelected =
			this.state.overlayType === this.posConfigurationVersionReduxKey
				? batchFormData.posConfigurationVersion
				: batchFormData.ingredientPricingGroup;

		if (_isEmpty(dataToSetColumnsSelected)) return;
		// set already selected data in rdw store as clicked
		dataToSetColumnsSelected.map((entry) => {
			store.dispatch(
				actions.addRowToSelection({
					reduxKey: this.state.overlayType,
					data: entry.value,
				})
			);
		});
	}

	_onSubmitForm() {
		const {
			batchFormData,
			addBatchList,
			fetchPosConfigurationIngredientPricingGroups,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const cleanVersions = _uniqBy(
			batchFormData.posConfigurationVersion,
			'value.id'
		);
		const cleanIngredients = _uniqBy(
			batchFormData.ingredientPricingGroup,
			'value.id'
		);

		const listArray = cleanVersions.reduce((acc, x) => {
			const list = cleanIngredients.map((y) => {
				return {
					identifier: _uniqueId('row-'), // used to identify what to edit on step#2
					posConfigurationVersion: x.value,
					ingredientPricingGroup: y.value,
					toStayPrice: batchFormData.toStayPrice,
					toGoPrice: batchFormData.toGoPrice,
				};
			});

			return [...acc, ...list];
		}, []);

		// get all existing ingredient pricing groups to filter out the ones we cannot add
		const filter = getFilter({
			posConfigurationVersions: cleanVersions,
			ingredientPricingGroups: cleanIngredients,
		});

		fetchPosConfigurationIngredientPricingGroups({ filter }, true)
			.then((res) => {
				this.setState(() => ({
					loadingModal: false,
				}));

				// filter the ones that already exist
				const filtered = markValuesWithCanAdd({
					listData: listArray,
					apiData: res.data,
				});

				addBatchList(filtered);

				this._goToStep(2);
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	_goToStep(step) {
		this.setState(() => ({ allowOverflow: step === 1 }));

		this.modalRef.current.goToStep(step);
	}

	_batchSubmit() {
		const {
			batchList,
			handleClose,
			addPosConfigurationIngredientPricingGroups,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		// filter out the ones we cant add
		const payload = batchList
			.filter((entry) => entry.canAdd)
			.map((entry) => {
				return {
					pos_configuration_version: _get(
						entry,
						'posConfigurationVersion.id',
						null
					),
					ingredient_pricing_group: _get(
						entry,
						'ingredientPricingGroup.id',
						null
					),
					to_stay_price: _get(entry, 'toStayPrice', '').replace(',', '.'),
					to_go_price: _get(entry, 'toGoPrice', '').replace(',', '.'),
				};
			});

		addPosConfigurationIngredientPricingGroups({ batch: payload })
			.then(() => {
				handleClose();
				this.setState(() => ({
					loadingModal: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	_getIsBatchStepFormValid() {
		const { batchFormData } = this.props;

		const isPosConfigurationVersionEmpty = _isEmpty(
			batchFormData.posConfigurationVersion
		);
		const isIngredientEmpty = _isEmpty(batchFormData.ingredientPricingGroup);

		if (!isPosConfigurationVersionEmpty && !isIngredientEmpty) return true;
		else return false;
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchList,
			editBatchListItem,
			removeBatchListItem,
		} = this.props;
		const steps = [
			{
				component: (
					<IngredientPricingGroupsStepForm
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.handleToggleOverlay}
						posConfigurationVersionReduxKey={
							this.posConfigurationVersionReduxKey
						}
						ingredientPricingGroupReduxKey={this.ingredientPricingGroupReduxKey}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE,
				isValid: this._getIsBatchStepFormValid(),
				onNext: () => this._onSubmitForm(),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				noScroll: false,
			},
			{
				component: (
					<IngredientPricingGroupsStepList
						batchList={batchList}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						className="modal-batch"
						ref={this.modalRef}
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={this.state.showOverlay ? () => {} : handleClose}
						allowOverflow={this.state.allowOverflow}
					/>
				)}

				<Overlay
					zIndex={551}
					height={850}
					list
					visible={this.state.showOverlay}
					onClose={() => {
						this.handleToggleOverlay();
					}}>
					{this.state.overlayType === this.posConfigurationVersionReduxKey && (
						<PosConfigurationVersionTable
							batchSelection
							defaultPageSize={20}
							onInitialization={() => this.onOverlayOpen()}
							reduxKey={this.state.overlayType}
							style={{
								maxHeight: '720px',
							}}
						/>
					)}
					{this.state.overlayType === this.ingredientPricingGroupReduxKey && (
						<IngredientPricingGroupsTable
							batchSelection
							defaultPageSize={20}
							onInitialization={() => this.onOverlayOpen()}
							reduxKey={this.state.overlayType}
							style={{
								maxHeight: '720px',
							}}
						/>
					)}
				</Overlay>
			</>
		);
	}
}

IngredientPricingGroupsModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	listPosConfigurationVersions: PropTypes.object,
	listIngredientPricingGroups: PropTypes.object,
	addBatchList: PropTypes.func,
	fetchPosConfigurationIngredientPricingGroups: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addPosConfigurationIngredientPricingGroups: PropTypes.func,
};

export default IngredientPricingGroupsModalBatch;
