export const RESET_WAITING_TIME_SCHEDULE =
	'SALES_CONFIGURATION_WAITING_TIME_SCHEDULES/RESET_WAITING_TIME_SCHEDULE';
export const SET_WAITING_TIME_SCHEDULE =
	'SALES_CONFIGURATION_WAITING_TIME_SCHEDULES/SET_WAITING_TIME_SCHEDULE';
export const UPDATE_WAITING_TIME_SCHEDULE =
	'SALES_CONFIGURATION_WAITING_TIME_SCHEDULES/UPDATE_WAITING_TIME_SCHEDULE';

export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_WAITING_TIME_SCHEDULES/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_WAITING_TIME_SCHEDULES/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'SALES_CONFIGURATION_WAITING_TIME_SCHEDULES/ADD_BATCH_LIST_ITEM_ROW';

/**
 * @param {object} payload
 */
export function setWaitingTimeSchedule(payload) {
	return {
		type: SET_WAITING_TIME_SCHEDULE,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function updateWaitingTimeSchedule(payload) {
	return {
		type: UPDATE_WAITING_TIME_SCHEDULE,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetWaitingTimeSchedule() {
	return {
		type: RESET_WAITING_TIME_SCHEDULE,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}
