'use strict';

import moment from 'moment';
import DetailsModel from 'common/components/details/detailsModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');
export default class SalaryGroupDetails extends DetailsModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'moneyball_sub_position',
				type: 'select',
				canEdit: true,
				canCreate: true,
				required: true,
				reference: {
					key: 'name',
					label: '<%= name %> (<%= moneyball_position.name %>)',
					list: '/hr/moneyball_sub_positions',
				},
				span: 2,
			},
			{
				name: 'market',
				type: 'select',
				required: true,
				canEdit: true,
				canCreate: true,
				reference: {
					key: 'name',
					list: '/administration/markets',
					translationKey: 'market',
				},
				span: 2,
			},
			{
				name: 'name',
				type: 'string',
				required: true,
				canEdit: true,
				canCreate: true,
				span: 2,
			},
			{
				name: 'active',
				type: 'date',
				canEdit: true,
				canCreate: true,
				required: true,
				defaultValue: moment(),
				reference: {
					key: 'from',
					translationKey: 'FROM',
				},
				span: 3,
			},
			{
				name: 'active',
				type: 'date',
				canEdit: true,
				canCreate: true,
				reference: {
					key: 'to',
					translationKey: 'TO',
				},
				span: 3,
			},
			{
				name: 'description',
				type: 'longString',
				canEdit: true,
				canCreate: true,
				span: 6,
			},
			{
				name: 'template_contract',
				canEdit: true,
				span: 3,
				type: 'select',
				reference: {
					key: 'name',
					label: '<%= name %>',
					list: '/signing/templates',
					searchListFilter: ':configured==true;:legacy==false',
					searchListLimit: 300,
				},
			},
			{
				name: 'template_appendix',
				canEdit: true,
				span: 3,
				type: 'select',
				reference: {
					key: 'name',
					label: '<%= name %>',
					list: '/signing/templates',
					searchListFilter: ':configured==true;:legacy==false',
					searchListLimit: 300,
				},
			},
		];
		this.settings = {
			name: 'SalaryGroupDetails',
			canCreate: true,
			canEdit: true,
			canDelete: true,
			canManipulate: () => validAccess(undefined, ['Salary Admin']),
			translationPath: 'SALARY.SALARY_GROUPS',
			endpoint: __endpoints.collection.salary.salary_groups,
		};
		this.init();
		return this;
	}
}
