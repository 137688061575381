'use strict';

var headers = {
	VACATIONS: {
		SUBJECT: 'Subject',
		DESCRIPTION: 'Description',
		TO: 'To',
		FROM: 'From'
	},
	MARKET_TAGS: {
		TAG: 'Tag',
		MARKET: 'Market'
	},
	WORKPLACE_TAGS: {
		TAG: 'Tag',
		WORKPLACE: 'Workplace'
	},
};

var titles = {
	MARKET_TAGS: 'Market Tags',
	WORKPLACE_TAGS: 'Workplace Tags',
	VACATIONS: 'Vacations',
};

module.exports = {
	headers: headers,
	titles: titles
};