'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// rdw actions
import { store } from 'appState';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';

// components
import { ModalStepper, Overlay, Button, Input } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';

// different batch views
import SalesConfigurationDiscountIngredientsBatchStepList from './components/salesConfigurationDiscountIngredientsBatchStepList';
import SalesConfigurationPosConfigurationDiscountsBatchIngredient from './components/salesConfigurationDiscountIngredientsBatchIngredient';

// phrases/ constants
import phrases from './../../ingredients.phrases';

// utils
import { getDiscountIngredientsFilter, markValuesWithCanAdd } from './uitls';
import { isMobile } from 'detectMobile.vanilla';

// loash
import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';
import _uniqBy from 'lodash/uniqBy';

// styles
import './salesConfigurationDiscountIngredientsModalBatch.css';

class SalesConfigurationDiscountIngredientsModalBatch extends Component {
	constructor(props) {
		super(props);

		this.ingredientUrl = '/product/ingredients';

		this.state = {
			ingredientsLegacyFilterOn: true,
			ingredientsLegacyFilter: `:legacy=='false'`,
			showOverlay: false,
			overlayType: null,
		};

		this.handleToggleOverlay = this.handleToggleOverlay.bind(this);
		this.onOverlayOpen = this.onOverlayOpen.bind(this);
		this.resetSelectedRows = this.resetSelectedRows.bind(this);
		this._onSubmitForm = this._onSubmitForm.bind(this);

		this.modalRef = React.createRef();

		this.columnsIngredients = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Ingredient category',
				id: 'ingredient_category',
				accessor: (d) => _get(d, 'ingredient_category.name', ''),
				filterPath: ':ingredient_category.name',
			},
			{
				Header: 'Fuel',
				id: 'fuel',
				accessor: (d) => _get(d, 'fuel', ''),
				filterPath: ':fuel',
			},
			{
				Header: 'Asset Collection',
				id: 'asset_collection',
				accessor: (d) => _get(d, 'asset_collection.name', null),
				filterPath: ':asset_collection.name',
			},
			{
				Header: 'Ingredient Pricing Group',
				id: 'ingredient_pricing_group',
				accessor: (d) => _get(d, 'ingredient_pricing_group.name', null),
				filterPath: ':ingredient_pricing_group.name',
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: (d) => _get(d, 'legacy', ''),
				filterPath: ':legacy',
				Cell: (d) => {
					return (
						<Input
							type="checkbox"
							checked={_get(d, 'original.legacy', false)}
							disabled
						/>
					);
				},
			},
		];
	}

	handleToggleOverlay(type) {
		const { listIngredients, updateBatchForm } = this.props;

		if (this.state.overlayType === this.ingredientUrl) {
			const selectedRowsInList = _get(listIngredients, 'ui.selectedRows', []);

			updateBatchForm({
				ingredient: selectedRowsInList.map((entry) => {
					return { label: entry.name, value: entry };
				}),
			});
		}

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
			overlayType: prevState.overlayType ? '' : type,
		}));
	}

	onOverlayOpen() {
		const { batchFormData } = this.props;
		const { overlayType } = this.state;

		if (_isEmpty(batchFormData.ingredient)) return;

		batchFormData.ingredient.map((entry) => {
			store.dispatch(
				actions.addRowToSelection({
					reduxKey: overlayType,
					data: entry.value,
				})
			);
		});
	}

	_onSubmitForm() {
		const {
			batchFormData,
			addBatchList,
			discount,
			fetchDiscountIngredients,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		// clear duplicates
		const cleanIngredients = _uniqBy(batchFormData.ingredient, 'value.id');

		const listArray = cleanIngredients.map((entry) => {
			return {
				identifier: _uniqueId('row-'),
				ingredient: entry,
				discount,
			};
		});

		const filter = getDiscountIngredientsFilter({
			ingredients: cleanIngredients,
		});

		// get all existing discount ingredients for the filter
		fetchDiscountIngredients(discount.id, { filter })
			.then((res) => {
				this.setState(() => ({
					loadingModal: false,
				}));

				const finalValues = markValuesWithCanAdd({
					discountIngredients: res.data,
					data: listArray,
				});

				addBatchList(finalValues);

				this._goToStep(2);
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_batchSubmit() {
		const { discount, batchList, handleClose, batchAddData } = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		// filter out and create payload
		const payload = batchList
			.filter((entry) => entry.canAdd)
			.map((entry) => {
				return {
					discount: discount.id,
					ingredient: _get(entry, 'ingredient.value.id', null),
				};
			});

		batchAddData({ batch: payload })
			.then(() => {
				handleClose();

				this.resetSelectedRows(this.ingredientUrl);

				this.setState(() => ({
					loadingModal: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	_getIsBatchStepFormVersionValid() {
		const { batchFormData } = this.props;

		return !_isEmpty(batchFormData.ingredient);
	}

	resetSelectedRows(reduxKey) {
		const { listIngredients } = this.props;

		if (reduxKey === this.ingredientUrl)
			listIngredients && store.dispatch(actions.resetSelection({ reduxKey }));
	}

	getAdditionalTableFilters() {
		const { ingredientsLegacyFilterOn } = this.state;

		return (
			<Button
				id="discountIngredientLegacyButton"
				size="tiny"
				shadow
				type={ingredientsLegacyFilterOn ? '' : 'inverted'}
				onClick={() =>
					this.setState((prevState) => ({
						ingredientsLegacyFilterOn: !prevState.ingredientsLegacyFilterOn,
					}))
				}>
				Legacy
			</Button>
		);
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchList,
			editBatchListItem,
			removeBatchListItem,
			fetchIngredients,
		} = this.props;
		const {
			showOverlay,
			loadingModal,
			ingredientsLegacyFilterOn,
			ingredientsLegacyFilter,
		} = this.state;

		const steps = [
			{
				component: (
					<SalesConfigurationPosConfigurationDiscountsBatchIngredient
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.handleToggleOverlay}
						ingredientUrl={this.ingredientUrl}
						resetSelection={this.resetSelectedRows}
						ingredientsLegacyFilter={ingredientsLegacyFilter}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE_VERSION,
				isValid: this._getIsBatchStepFormVersionValid(),
				onNext: this._onSubmitForm,
				loading: loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				noScroll: false,
				allowOverflow: true,
			},
			{
				component: (
					<SalesConfigurationDiscountIngredientsBatchStepList
						batchList={batchList}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
						ingredientUrl={this.ingredientUrl}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						className="modal-batch"
						ref={this.modalRef}
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={showOverlay ? () => {} : handleClose}
					/>
				)}

				<Overlay
					zIndex={551}
					height={isMobile() ? 480 : 680}
					list
					visible={showOverlay}
					onClose={() => {
						this.handleToggleOverlay();
					}}
					render={(height) => {
						return (
							<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
								title={phrases.MODAL_BATCH_STEP_FORM_INGREDIENTS_TABLE_TITLE}
								columns={this.columnsIngredients}
								fetchData={fetchIngredients}
								filterable
								disableFetchCsvButton
								enableMultiSelection
								batchSelection
								defaultPageSize={20}
								onInitialization={this.onOverlayOpen}
								reduxKey={this.state.overlayType}
								manual
								actionsWidth={0}
								style={{
									maxHeight: `${height - 130}px`,
								}}
								customAreaComponents={this.getAdditionalTableFilters()}
								extraFilters={
									ingredientsLegacyFilterOn ? ingredientsLegacyFilter : ''
								}
							/>
						);
					}}
				/>
			</>
		);
	}
}

SalesConfigurationDiscountIngredientsModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	listIngredients: PropTypes.object,
	addBatchList: PropTypes.func,
	fetchIngredients: PropTypes.func,
	fetchDiscountIngredients: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	batchAddData: PropTypes.func,
	discount: PropTypes.object,
};

export default SalesConfigurationDiscountIngredientsModalBatch;
