'use strict';

import constants from 'services/constants';
import moment from 'moment';

/**
 * @function getFactorSelectDisabled
 * @description returns bool value to enable/disable factor selector
 * @description if dates on the same day factor selector is enabled
 */
export function getFactorSelectDisabled(vacationIllnessData) {
	const from = vacationIllnessData.from
		? vacationIllnessData.from
		: moment.utc().format(constants.shortDate);
	const to = vacationIllnessData.to
		? vacationIllnessData.to
		: moment.utc().format(constants.shortDate);

	return !moment.utc(from).isSame(moment.utc(to), 'day');
}

/**
 * @method _getSelectedDate
 * @param {String} key - from/to
 * @description if we're in add mode it goes to else and returns today's date for "from"
 */
export function getSelectedDate(key, vacationIllnessData) {
	const date = vacationIllnessData[key];

	return date
		? moment.utc(date).format(constants.shortDate)
		: moment.utc().format(constants.shortDate);
}
