'use strict';

import _has from 'lodash/has';
import _each from 'lodash/each';


module.exports = function(headers) {
	var operatorDefaults;
	_each(headers, function(header) {
		_each(header.filterBar, function(filter) {
			if (_has(filter, 'defaultValue')) {
				if (_has(header, 'reference') && header.reference.operators) operatorDefaults = header.reference.operators;
				else {
					switch (header.type) {
						case 'bool': operatorDefaults = ['==']; break;
						case 'currency': operatorDefaults = ['==', '=gt=', '=lt=', '=ge=', '=le=']; break;
						case 'date': operatorDefaults = ['time.=!=']; break;
						case 'datetime': operatorDefaults = ['time.=!=']; break;
						case 'time': operatorDefaults = ['time.==', 'time.=gt=', 'time.=lt=', 'time.=ge=', 'time.=le=']; break;
						case 'number': operatorDefaults = ['==', '=gt=', '=lt=', '=ge=', '=le=']; break;
						case 'select': operatorDefaults = ['==']; break;

						// mail, string
						default: operatorDefaults = ['=like=', '==']; break;
					}
				}

				if (_has(filter, 'operator')) operatorDefaults = [filter.operator];

				this.filterBarSearch(header, filter.defaultValue, operatorDefaults[0], filter.filterId, 'default');
			}
		}.bind(this));
	}.bind(this));
};