export const RESET_STATE = 'ALLERGEN_TRANSLATIONS/RESET_STATE';
export const SET_ALLERGEN_TRANSLATION = 'ALLERGEN_TRANSLATIONS/SET_ALLERGEN_TRANSLATION';
export const UPDATE_ALLERGEN_TRANSLATION = 'ALLERGEN_TRANSLATIONS/UPDATE_ALLERGEN_TRANSLATION';

export const EDIT_BATCH_LIST_ITEM = 'ALLERGEN_TRANSLATIONS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM = 'ALLERGEN_TRANSLATIONS/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW = 'ALLERGEN_TRANSLATIONS/ADD_BATCH_LIST_ITEM_ROW';
export const RE_RENDER_TABLE = 'ALLERGEN_TRANSLATIONS/RE_RENDER_TABLE';

export function setAllergenTranslation(payload) {
	return {
		type: SET_ALLERGEN_TRANSLATION,
		payload,
	};
}

export function updateAllergenTranslation(payload) {
	return {
		type: UPDATE_ALLERGEN_TRANSLATION,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}

export function reRenderTable() {
	return {
		type: RE_RENDER_TABLE,
	};
}
