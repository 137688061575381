'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
const __endpoints = require('endpoints.service');
const __regex = require('regex.service');

export default class GridEntriesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'employees',
				type: 'number',
				regex: __regex.number,
				required: true,
				canAdd: true,
				canEdit: true,
			},
			{
				name: 'minimum',
				type: 'number',
				regex: __regex.number,
				required: true,
				canAdd: true,
				canEdit: true,
			},
			{
				name: 'optimal',
				type: 'number',
				regex: __regex.number,
				required: true,
				canAdd: true,
				canEdit: true,
			},
			{
				name: 'maximum',
				type: 'number',
				regex: __regex.number,
				required: true,
				canAdd: true,
				canEdit: true,
			},
		];

		this.settings = {
			name: 'GridEntriesList',
			alwaysRefresh: true,
			canAdd: true,
			canEdit: true,
			canDelete: true,
			translationPath: 'HR.GRID_ENTRIES',
			endpoint: __endpoints.collection.hr.grid_entries,
		};

		this.init();
		return this;
	}
}
