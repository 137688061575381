'use strict';

// import ButtonLoader from 'buttonLoader';
import { ButtonLoader } from 'dumb';
import cx from 'classnames';
import Cleave from 'cleave.js/react';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _isNull from 'lodash/isNull';
import _isUndefined from 'lodash/isUndefined';
import Modal from 'modal/modal.component';
import PropTypes from 'prop-types';

/**
 * @namespace jtjDrawerToBankMatrice.component
 * @memberOf COMPONENTS
 */

import React, { PureComponent } from 'react';

import GoldModelDetailsTags from './goldModelDataDetailsTags.component';

class GoldModelDataDetails extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			inputData: {},
			valid: true,
			submitting: false,
			inputChanged: false,
			actuals: {},
			forecasts: {},
			fetchData: false
		};
		this.onUpdateData = this.onUpdateData.bind(this);
		this.onInput = this.onInput.bind(this);
	}

	onInput(object, key, event) {
		const val = event.target.value;

		this.setState(() => ({
			[object]: {
				...this.state[object],
				[key]: val
			},
			inputChanged: true
		}));
	}

	onUpdateData(event) {
		event.preventDefault();

		// Get props and state
		const { methods, data, workplace, periodType } = this.props;
		const { inputData, forecasts, actuals } = this.state;

		// Method for getting update value based on input fields and data from API
		const getUpdateValue = (input = {}, data) => {
			let defaultValue =
				_isNull(data) || _isUndefined(data) ? null : data.toString();
			return input === defaultValue ||
				(_isEmpty(input) && _isNull(defaultValue))
				? null
				: numeral(input).value();
		};

		// Object with dataBasis, based on input fields
		const dataBasis = {
			actuals: {
				turnover: getUpdateValue(
					actuals.turnoverInput,
					inputData.actuals.turnover
				),
				location: getUpdateValue(
					actuals.locationInput,
					inputData.actuals.location
				),
				salary: getUpdateValue(actuals.salaryInput, inputData.actuals.salary),
				doc: getUpdateValue(actuals.docInput, inputData.actuals.doc),
				cgs: getUpdateValue(actuals.cgsInput, inputData.actuals.cgs)
			},
			forecasts: {
				turnover: getUpdateValue(
					forecasts.turnoverInput,
					inputData.forecasts.turnover
				),
				location: getUpdateValue(
					forecasts.locationInput,
					inputData.forecasts.location
				),
				salary: getUpdateValue(
					forecasts.salaryInput,
					inputData.forecasts.salary
				),
				doc: getUpdateValue(forecasts.docInput, inputData.forecasts.doc),
				cgs: getUpdateValue(forecasts.cgsInput, inputData.forecasts.cgs)
			}
		};

		this.setState(() => ({ valid: true, submitting: true }));

		// Define base metadata
		const metaData = {
			month:
				periodType === 'monthly'
					? moment(data.date, 'MM-YYYY').format('M')
					: null,
			year:
				periodType === 'monthly'
					? moment(data.date, 'MM-YYYY').format('YYYY')
					: null,
			date: periodType === 'daily' ? data.date : null,
			actualsId: _has(inputData, 'actuals.id') ? inputData.actuals.id : null,
			forecastsId: _has(inputData, 'forecasts.id')
				? inputData.forecasts.id
				: null,
			workplace
		};

		// Update
		methods.handleSubmitChange(dataBasis, metaData).then(
			() => {
				this.setState(() => ({ submitting: false }));

				this.props.handleClose();
			},
			() => {
				// error
				this.setState(() => ({ submitting: false }));
			}
		);
	}

	componentDidMount() {
		const { data, methods, periodType, workplace } = this.props;

		const detailsData = {
			month:
				periodType === 'monthly'
					? moment(data.date, 'MM-YYYY').format('MM')
					: null,
			year:
				periodType === 'monthly'
					? moment(data.date, 'MM-YYYY').format('YYYY')
					: null,
			date: periodType === 'daily' ? data.date : null,
			workplace: workplace
		};

		this.setState(() => ({
			fetchData: true
		}));

		methods.handleGetDetails(detailsData).then(inputData => {
			const actuals = inputData.actuals;
			const forecasts = inputData.forecasts;

			this.setState(() => ({
				fetchData: false,
				inputData,
				actuals: {
					turnoverInput: !actuals.turnover
						? ''
						: actuals.turnover.toString().replace('.', ','),
					locationInput: !actuals.location
						? ''
						: actuals.location.toString().replace('.', ','),
					salaryInput: !actuals.salary
						? ''
						: actuals.salary.toString().replace('.', ','),
					docInput: !actuals.doc
						? ''
						: actuals.doc.toString().replace('.', ','),
					cgsInput: !actuals.cgs ? '' : actuals.cgs.toString().replace('.', ',')
				},
				forecasts: {
					turnoverInput: !forecasts.turnover
						? ''
						: forecasts.turnover.toString().replace('.', ','),
					locationInput: !forecasts.location
						? ''
						: forecasts.location.toString().replace('.', ','),
					salaryInput: !forecasts.salary
						? ''
						: forecasts.salary.toString().replace('.', ','),
					docInput: !forecasts.doc
						? ''
						: forecasts.doc.toString().replace('.', ','),
					cgsInput: !forecasts.cgs
						? ''
						: forecasts.cgs.toString().replace('.', ',')
				}
			}));
		});
	}

	render() {
		const {
			handleClose,
			translations,
			methods,
			data,
			periodType,
			workplace
		} = this.props;

		const {
			inputChanged,
			valid,
			actuals,
			submitting,
			forecasts,
			fetchData
		} = this.state;

		const goldModelCx = cx('gold-model__details', {
			'gold-model__details--invalid': !valid,
			'gold-model__details--fetching': fetchData
		});

		return (
			<div className={goldModelCx}>
				<div className="gold-model__details__modal-overlay">
					<ButtonLoader loading={fetchData} theme="dark" />
				</div>
				<form
					noValidate
					className="gold-model__details__data-input-wrapper"
					onSubmit={this.onUpdateData}
					autoComplete="off">
					<h5 className="gold-model__details__data-input-wrapper__header">
						<span>{translations.ACTUALS}</span>
						<span>{translations.FORECASTS}</span>
					</h5>

					<div className="gold-model__details__data gold-model__details__data--focus">
						<span>{translations.TURNOVER}</span>
						<span>
							<Cleave
								options={{
									numeral: true,
									numeralDecimalMark: ',',
									delimiter: '.'
								}}
								type="text"
								value={actuals.turnoverInput}
								onChange={event =>
									this.onInput('actuals', 'turnoverInput', event)
								}
								autoComplete="off"
							/>
							<Cleave
								options={{
									numeral: true,
									numeralDecimalMark: ',',
									delimiter: '.'
								}}
								type="text"
								value={forecasts.turnoverInput}
								onChange={event =>
									this.onInput('forecasts', 'turnoverInput', event)
								}
								autoComplete="off"
							/>
						</span>
					</div>

					<div className="gold-model__details__data gold-model__details__data--focus">
						<span>{translations.LOCATION}</span>
						<span>
							<Cleave
								options={{
									numeral: true,
									numeralDecimalMark: ',',
									delimiter: '.'
								}}
								type="text"
								value={actuals.locationInput}
								onChange={event =>
									this.onInput('actuals', 'locationInput', event)
								}
								autoComplete="off"
							/>
							<Cleave
								options={{
									numeral: true,
									numeralDecimalMark: ',',
									delimiter: '.'
								}}
								value={forecasts.locationInput}
								type="text"
								onChange={event =>
									this.onInput('forecasts', 'locationInput', event)
								}
								autoComplete="off"
							/>
						</span>
					</div>

					<div className="gold-model__details__data gold-model__details__data--focus">
						<span>{translations.SALARY}</span>
						<span>
							<Cleave
								options={{
									numeral: true,
									numeralDecimalMark: ',',
									delimiter: '.'
								}}
								value={actuals.salaryInput}
								type="text"
								onChange={event =>
									this.onInput('actuals', 'salaryInput', event)
								}
								autoComplete="off"
							/>
							<Cleave
								options={{
									numeral: true,
									numeralDecimalMark: ',',
									delimiter: '.'
								}}
								value={forecasts.salaryInput}
								type="text"
								onChange={event =>
									this.onInput('forecasts', 'salaryInput', event)
								}
								autoComplete="off"
							/>
						</span>
					</div>

					<div className="gold-model__details__data gold-model__details__data--focus">
						<span>{translations.DOC}</span>
						<span>
							<Cleave
								options={{
									numeral: true,
									numeralDecimalMark: ',',
									delimiter: '.'
								}}
								value={actuals.docInput}
								type="text"
								onChange={event => this.onInput('actuals', 'docInput', event)}
								autoComplete="off"
							/>
							<Cleave
								options={{
									numeral: true,
									numeralDecimalMark: ',',
									delimiter: '.'
								}}
								value={forecasts.docInput}
								type="text"
								onChange={event => this.onInput('forecasts', 'docInput', event)}
								autoComplete="off"
							/>
						</span>
					</div>

					<div className="gold-model__details__data gold-model__details__data--focus">
						<span>{translations.CGS}</span>
						<span>
							<Cleave
								options={{
									numeral: true,
									numeralDecimalMark: ',',
									delimiter: '.'
								}}
								value={actuals.cgsInput}
								type="text"
								onChange={event => this.onInput('actuals', 'cgsInput', event)}
								autoComplete="off"
							/>
							<Cleave
								options={{
									numeral: true,
									numeralDecimalMark: ',',
									delimiter: '.'
								}}
								value={forecasts.cgsInput}
								type="text"
								onChange={event => this.onInput('forecasts', 'cgsInput', event)}
								autoComplete="off"
							/>
						</span>
					</div>

					<GoldModelDetailsTags
						translations={translations}
						methods={methods}
						workplace={workplace}
						date={data.date}
						periodType={periodType}
					/>

					<div className="gold-model__details__buttons">
						<a
							className="button gold-model__details__button gold-model__details__button--other"
							onClick={handleClose}>
							{this.props.translations.CLOSE}
						</a>
						<button
							className="button gold-model__details__button gold-model__details__button--submit"
							disabled={!inputChanged || submitting}
							type="submit">
							{this.props.translations.CONFIRM}
						</button>
					</div>
				</form>
			</div>
		);
	}
}

GoldModelDataDetails.propTypes = {
	data: PropTypes.object.isRequired,
	methods: PropTypes.object.isRequired,
	handleClose: PropTypes.func.isRequired,
	translations: PropTypes.object.isRequired,
	workplace: PropTypes.number.isRequired,
	periodType: PropTypes.string.isRequired
};

export default Modal(GoldModelDataDetails);
