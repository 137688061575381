'use strict';

import { ReportFactory as R } from 'report/factory';

const t = R.table();

const ReportingCurrencyReportTable = t.table([
	t.group('ISO', [
		t.column(
			'ISO',
			[t.content(d => R.data(d, 'reporting_currency.currency.code'), 'string')],
			70,
			'left'
		)
	]),
	t.group('Period', [
		t.column(
			'january',
			[t.content(d => R.data(d, 'january'), 'currencyFiveDecimal')],
			70,
			'currencyFiveDecimal'
		),
		t.column(
			'february',
			[t.content(d => R.data(d, 'february'), 'currencyFiveDecimal')],
			70,
			'currencyFiveDecimal'
		),
		t.column(
			'march',
			[t.content(d => R.data(d, 'march'), 'currencyFiveDecimal')],
			70,
			'currencyFiveDecimal'
		),
		t.column(
			'april',
			[t.content(d => R.data(d, 'april'), 'currencyFiveDecimal')],
			70,
			'currencyFiveDecimal'
		),
		t.column(
			'may',
			[t.content(d => R.data(d, 'may'), 'currencyFiveDecimal')],
			70,
			'currencyFiveDecimal'
		),
		t.column(
			'june',
			[t.content(d => R.data(d, 'june'), 'currencyFiveDecimal')],
			70,
			'currencyFiveDecimal'
		),
		t.column(
			'july',
			[t.content(d => R.data(d, 'july'), 'currencyFiveDecimal')],
			70,
			'currencyFiveDecimal'
		),
		t.column(
			'august',
			[t.content(d => R.data(d, 'august'), 'currencyFiveDecimal')],
			70,
			'currencyFiveDecimal'
		),
		t.column(
			'september',
			[t.content(d => R.data(d, 'september'), 'currencyFiveDecimal')],
			70,
			'currencyFiveDecimal'
		),
		t.column(
			'october',
			[t.content(d => R.data(d, 'october'), 'currencyFiveDecimal')],
			70,
			'currencyFiveDecimal'
		),
		t.column(
			'november',
			[t.content(d => R.data(d, 'november'), 'currencyFiveDecimal')],
			70,
			'currencyFiveDecimal'
		),
		t.column(
			'december',
			[t.content(d => R.data(d, 'december'), 'currencyFiveDecimal')],
			70,
			'currencyFiveDecimal'
		)
	])
]);

// ReportingCurrencyReportTable.setTitle(t.title(d => `${R.data(d, 'meta.data.market.name')} in ${R.data(d, 'meta.data.currency')}`));

export default ReportingCurrencyReportTable;
