export const RESET_STATE = 'WEEKLY_REQUIREMENTS/RESET_STATE';
export const LOADING = 'WEEKLY_REQUIREMENTS/LOADING';
export const SET_DEFAULT_CLOPES = 'WEEKLY_REQUIREMENTS/SET_DEFAULT_CLOPES';
export const SET_WEEKLY_OVERHEADS = 'WEEKLY_REQUIREMENTS/SET_WEEKLY_OVERHEADS';
export const LOADING_CLOPES = 'WEEKLY_REQUIREMENTS/LOADING_CLOPES';
export const LOADING_OVERHEADS = 'WEEKLY_REQUIREMENTS/LOADING_OVERHEADS';
export const UPDATE_OVERHEADS = 'WEEKLY_REQUIREMENTS/UPDATE_OVERHEADS';
export const REMOVE_OVERHEADS = 'WEEKLY_REQUIREMENTS/REMOVE_OVERHEADS';
export const UPDATE_CLOPES = 'WEEKLY_REQUIREMENTS/UPDATE_CLOPES';
export const REMOVE_CLOPES = 'WEEKLY_REQUIREMENTS/REMOVE_CLOPES';

/**
 * @param {object} clopes
 */
export function setDefaultClopes(payload) {
	return {
		type: SET_DEFAULT_CLOPES,
		payload
	};
}

/**
 * @param {object} overheads
 */
export function setWeeklyOverheads(payload) {
	return {
		type: SET_WEEKLY_OVERHEADS,
		payload
	};
}

/**
 * @param {bool} bool
 */
export function setLoadingOverheads(payload) {
	return {
		type: LOADING_OVERHEADS,
		payload
	};
}

/**
 * @param {bool} bool
 */
export function setLoadingClopes(payload) {
	return {
		type: LOADING_CLOPES,
		payload
	};
}

/**
 * @param {bool} bool
 */
export function updateOverheads(payload) {
	return {
		type: UPDATE_OVERHEADS,
		payload
	};
}

/**
 * @param {number} id
 */
export function removeOverhead(payload) {
	return {
		type: REMOVE_OVERHEADS,
		payload
	};
}

/**
 * @param {bool} bool
 */
export function updateClopes(payload) {
	return {
		type: UPDATE_CLOPES,
		payload
	};
}

/**
 * @param {number} id
 */
export function removeClopes(payload) {
	return {
		type: REMOVE_CLOPES,
		payload
	};
}

/**
 * @param {number} id
 */
export function clearState() {
	return {
		type: RESET_STATE
	};
}
