import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactCollapsible from 'react-collapsible';

import './collapsible.css';

// Wrapper for react-collapsible
// Documentation can be found here https://github.com/glennflanagan/react-collapsible

class Collapsible extends Component {
	render() {
		const {
			classParentString,
			trigger,
			open,
			easing,
			overflowWhenOpen
		} = this.props;

		return (
			<ReactCollapsible
				classParentString={classParentString}
				transitionTime={200}
				easing={easing}
				open={open}
				overflowWhenOpen={overflowWhenOpen}
				trigger={trigger}>
				{this.props.children}
			</ReactCollapsible>
		);
	}
}

Collapsible.defaultProps = {
	collapsible: {},
	classParentString: 'collapsible',
	open: false,
	overflowWhenOpen: 'visible',
	easing: 'ease-out'
};

Collapsible.propTypes = {
	trigger: PropTypes.string,
	easing: PropTypes.string,
	open: PropTypes.bool,
	overflowWhenOpen: PropTypes.string,
	classParentString: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
};

export default Collapsible;
