'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { store, connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import { FileUpload, Button, Icon, Tooltip } from 'dumb';
import AssetsLeftAlignedCreateAssignModal from './components/assetsLeftAlignedCreateAssignModal/assetsLeftAlignedCreateAssignModal';

import _get from 'lodash/get';

import { convertBinaryToBase64, ImageHelpers } from 'utils';

import * as actions from 'reactDataWrapper/reactDataWrapper.actions';
import {
	setAsset,
	updateAsset,
	resetAssets,
	updateDefaultAssetCollection,
} from './store/assetLeftAligned.actions';
import { enums } from './assetsLeftAligned.enums';

import {
	fetchAllAssets,
	editAssets,
	addAssets,
	deleteAssets,
	addAssetCollection,
} from './assetLeftAligned.service';

import phrases from './assetLeftAligned.phrases';

import './assetsLeftAligned.css';

class AssetLeftAligned extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingTrList: [],
			imagesMetaData: [],
			image: {},
			modalVisible: false,
		};

		this._deleteEntry = this._deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this._getToolTipText = this._getToolTipText.bind(this);
		this.onImageEnter = this.onImageEnter.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.renderDeleteButton = this.renderDeleteButton.bind(this);
		this._deleteEntry = this._deleteEntry.bind(this);

		this.columns = [
			{
				Header: 'Domain',
				id: 'domain',
				accessor: (d) => _get(d, 'domain', ''),
				filterPath: ':domain',
				filterable: false,
				sortable: false,
			},
			{
				Header: 'Type',
				id: 'type',
				accessor: (d) => _get(d, 'type', ''),
				filterPath: ':type',
				filterable: false,
				sortable: false,
			},
			{
				Header: 'Auto resize',
				id: 'resAuto',
				accessor: (d) => _get(d, 'resolution_one.url', ''),
				filterPath: ':resolution_one.filename',
				filterable: false,
				sortable: false,
				width: 165,
				Cell: (d) => (
					<Tooltip
						zIndex={600}
						text={phrases.IMAGE_RESIZE_TOOLTIP}
						renderData={(ref, onMouseEnter, onMouseLeave) => (
							<FileUpload
								onChange={(file) => this.editUploadImage(null, file, d)}
								thumbnail
								disabled={this.state.loadingTrList.some(
									(x) => x.row === d.viewIndex
								)}
								loading={this.state.loadingTrList.some(
									(x) => x.row === d.viewIndex && x.id === d.column.id
								)}
								resize={[{ width: 300 }, { width: 600 }]}
								refProp={ref}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
							/>
						)}
					/>
				),
			},
			{
				Header: 'Resolution One',
				id: 'res1',
				accessor: (d) => _get(d, 'resolution_one.url', ''),
				filterPath: ':resolution_one.filename',
				filterable: false,
				sortable: false,
				Cell: (d) => {
					const url = _get(d, 'original.resolution_one.url', false);
					let file = null;
					if (url) {
						file = {
							url,
							filename: _get(d, 'original.resolution_one.filename', ''),
						};
					}
					const tooltipText = this._getToolTipText();

					return (
						<Tooltip
							visible={!!tooltipText}
							text={tooltipText}
							renderData={(ref, onMouseEnter, onMouseLeave) => (
								<FileUpload
									onChange={(file) =>
										this.editUploadImage('resolution_one', file, d)
									}
									file={file}
									thumbnail
									disabled={this.state.loadingTrList.some(
										(x) => x.row === d.viewIndex
									)}
									loading={this.state.loadingTrList.some(
										(x) => x.row === d.viewIndex && x.id === d.column.id
									)}
									refProp={ref}
									onMouseEnter={() => this.onImageEnter(onMouseEnter, url)}
									onMouseLeave={onMouseLeave}
								/>
							)}
						/>
					);
				},
			},
			{
				Header: 'Resolution Two',
				id: 'res2',
				accessor: (d) => _get(d, 'resolution_two.url', ''),
				filterPath: ':resolution_two.filename',
				filterable: false,
				sortable: false,
				Cell: (d) => {
					const url = _get(d, 'original.resolution_two.url', false);
					let file = null;
					if (url) {
						file = {
							url,
							filename: _get(d, 'original.resolution_two.filename', ''),
						};
					}

					const tooltipText = this._getToolTipText();

					return (
						<Tooltip
							visible={!!tooltipText}
							text={tooltipText}
							renderData={(ref, onMouseEnter, onMouseLeave) => (
								<FileUpload
									onChange={(file) =>
										this.editUploadImage('resolution_two', file, d)
									}
									file={file}
									thumbnail
									disabled={this.state.loadingTrList.some(
										(x) => x.row === d.viewIndex
									)}
									loading={this.state.loadingTrList.some(
										(x) => x.row === d.viewIndex && x.id === d.column.id
									)}
									refProp={ref}
									onMouseEnter={() => this.onImageEnter(onMouseEnter, url)}
									onMouseLeave={onMouseLeave}
								/>
							)}
						/>
					);
				},
			},
			{
				Header: 'Resolution Three',
				id: 'res3',
				accessor: (d) => _get(d, 'resolution_three.url', ''),
				filterPath: ':resolution_three.filename',
				filterable: false,
				sortable: false,
				Cell: (d) => {
					const url = _get(d, 'original.resolution_three.url', false);
					let file = null;
					if (url) {
						file = {
							url,
							filename: _get(d, 'original.resolution_three.filename', ''),
						};
					}

					const tooltipText = this._getToolTipText();

					return (
						<Tooltip
							visible={!!tooltipText}
							text={tooltipText}
							renderData={(ref, onMouseEnter, onMouseLeave) => (
								<FileUpload
									onChange={(file) =>
										this.editUploadImage('resolution_three', file, d)
									}
									key={url}
									file={file}
									thumbnail
									disabled={this.state.loadingTrList.some(
										(x) => x.row === d.viewIndex
									)}
									loading={this.state.loadingTrList.some(
										(x) => x.row === d.viewIndex && x.id === d.column.id
									)}
									refProp={ref}
									onMouseEnter={() => this.onImageEnter(onMouseEnter, url)}
									onMouseLeave={onMouseLeave}
								/>
							)}
						/>
					);
				},
			},
		];
	}

	onImageEnter(onMouseEnter, url) {
		if (!url) return;

		ImageHelpers.getImageMetadata(url).then((res) => {
			this.setState(() => ({
				image: {
					height: res.height,
					width: res.width,
				},
			}));
		});

		onMouseEnter();
	}

	_getToolTipText() {
		return `Width: ${_get(this.state, 'image.width', 0)}px, Height: ${_get(
			this.state,
			'image.height',
			0
		)}px`;
	}

	editUploadImage(type, file, data) {
		const id = _get(data, 'original.id', false);
		// Should always have certain things
		const row = data.original || {};

		// if a single file uploaded
		if (!file.length) {
			this.setState(() => ({
				loadingTrList: [
					...this.state.loadingTrList,
					{ row: data.viewIndex, id: data.column.id },
				],
			}));

			convertBinaryToBase64(file)
				.then((image64) => {
					const payload = [
						row,
						type,
						{
							filename: file.name,
							data: image64,
						},
					];

					const promise = id
						? this.editEntry(...payload)
						: this.addEntry(...payload);

					promise.finally((res) => {
						this.setState(() => ({
							loadingTrList: this.state.loadingTrList.filter(
								(x) => x.row !== data.viewIndex && x.id !== data.column.id
							),
						}));
					});
				})
				.catch(() => {
					this.setState(() => ({
						loadingTrList: this.state.loadingTrList.filter(
							(x) => x.row !== data.viewIndex && x.id !== data.column.id
						),
					}));
				});
		} else {
			// set all three columns as loading
			this.setState(() => ({
				loadingTrList: [
					{ row: data.viewIndex, id: data.column.id },
					{ row: data.viewIndex, id: 'res1' },
					{ row: data.viewIndex, id: 'res2' },
					{ row: data.viewIndex, id: 'res3' },
				],
			}));

			// convert all images to base64
			const convertedImagesArray = file.map((entry, i) => {
				return convertBinaryToBase64(entry)
					.then((image64) => {
						const {
							fileName,
							fileExtention,
						} = ImageHelpers.getImageNameExtention(entry.name);

						let name = 'resolution_one';
						let filename = `${fileName}-x1.${fileExtention}`;
						if (i === 1) {
							name = 'resolution_two';
							filename = `${fileName}-x2.${fileExtention}`;
						}
						if (i === 2) {
							name = 'resolution_three';
							filename = `${fileName}-x3.${fileExtention}`;
						}

						return {
							name,
							data: {
								filename,
								data: image64,
							},
						};
					})
					.catch(() => {
						this.setState(() => ({
							loadingTrList: [],
						}));
					});
			});

			// waut for all images to be converted
			Promise.all(convertedImagesArray)
				.then((res) => {
					const payload = {
						[res[0].name]: res[0].data,
						[res[1].name]: res[1].data,
						[res[2].name]: res[2].data,
					};

					const functionPayload = [row, null, null, payload];

					const promise = row.id
						? this.editEntry(...functionPayload)
						: this.addEntry(...functionPayload);

					// add or update images
					promise.finally((res) => {
						this.setState(() => ({
							loadingTrList: [],
						}));
					});
				})
				.catch(() => {
					// stop loaders
					this.setState(() => ({
						loadingTrList: [],
					}));
				});
		}
	}

	_deleteEntry(id) {
		const { dataList, reduxKey } = this.props;
		deleteAssets(id).then((x) => {
			const updatedList = dataList.data.listData.map((x) => {
				if (x.id === id) {
					return { domain: x.domain, type: x.type };
				} else {
					return x;
				}
			});

			store.dispatch(
				actions.setListData({
					reduxKey,
					listData: updatedList,
				})
			);
		});
	}

	editEntry(row, type, value, batch = null) {
		const { reduxKey } = this.props;

		const payload = batch || { [type]: value };

		return editAssets(row.id, payload)
			.then((x) => {
				store.dispatch(
					actions.editEntry({
						reduxKey,
						entry: x.data[0],
					})
				);
				return x;
			})
			.catch((x) => {
				return x;
			});
	}

	addEntry(row, type, value, batch = null) {
		const { dataList, assetCollectionId, reduxKey } = this.props;

		const payload = {
			asset_collection: assetCollectionId || null,
			domain: _get(row, 'domain', 'this should not be empty!'),
			type: _get(row, 'type', 'this should not be empty!'),
		};

		return addAssets({
			...payload,
			...(batch || { [type]: value }),
		})
			.then((x) => {
				const newEntry = x.data[0];
				const updatedList = dataList.data.listData.map((y) => {
					if (newEntry.domain === y.domain && newEntry.type === y.type) {
						return newEntry;
					} else {
						return y;
					}
				});

				store.dispatch(
					actions.setListData({
						reduxKey,
						listData: updatedList,
					})
				);
			})
			.catch((x) => {
				console.info(x);
				return x;
			});
	}

	setInitialEditValues(data) {
		this.props.setAsset(data);
	}

	editStoreImage(type, e) {
		this.setState({ [type]: true });

		convertBinaryToBase64(e)
			.then((image64) => {
				this.setState({ [type]: false });

				this.editStoreEntry(
					{
						filename: e.name,
						data: image64,
					},
					type
				);
			})
			.catch((e) => this.setState({ [type]: false }));
	}

	fetchData(state) {
		const { assetCollectionId } = this.props;

		const data = enums.DOMAINS.reduce((acc, x) => {
			switch (x) {
				case 'POS':
					acc = [
						...acc,
						{
							domain: 'POS',
							type: 'Product Tile',
						},
					];
					return acc;

				case 'App':
					enums.APP_TYPES.map((x) => {
						acc = [
							...acc,
							{
								domain: 'App',
								type: x,
							},
						];
					});
					return acc;

				case 'The List':
					enums.LIST_TYPES.map((x) => {
						acc = [
							...acc,
							{
								domain: 'The List',
								type: x,
							},
						];
					});
					return acc;
			}
		}, []);

		return fetchAllAssets(state, assetCollectionId || null, data);
	}

	renderDeleteButton(d) {
		const id = _get(d, 'original.id', null);
		return id ? (
			<Tooltip
				text={phrases.DELETE_ASSOCIATION_TO_ASSET_COLLECTION}
				placement="left"
				renderData={(ref, onMouseEnter, onMouseLeave) => (
					<Button
						type="inverted"
						shadow
						onClick={this._deleteEntry}
						size="micro"
						refProp={ref}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}>
						<Icon name="delete" />
					</Button>
				)}
			/>
		) : null;
	}

	toggleModal() {
		this.setState((prevState) => ({ modalVisible: !prevState.modalVisible }));
	}

	render() {
		const {
			productId,
			filterId,
			editFilter,
			assetCollectionId,
			updateDefaultAssetCollection,
			defaultAssetCollection,
			reloadProductTable,
			reloadFilterTable,
			editProduct,
			resetAssets,
			reduxKey,
		} = this.props;

		return (
			<>
				{assetCollectionId ? (
					<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
						title={phrases.ASSETS}
						columns={this.columns}
						fetchData={this.fetchData}
						filterable
						defaultPageSize={15}
						reduxKey={reduxKey}
						manual
						setInitialEditValues={this.setInitialEditValues}
						onModalClose={this.props.resetAssets}
						actionsWidth={30}
						disableFetchCsvButton
						actions={this.renderDeleteButton}
						enableMultiSelection={false}
					/>
				) : (
					<div className="product-asset-collection_left_aligned__get-started">
						<span className="product-asset-collection_left_aligned__get-started__text">
							{phrases.NO_ASSET_COLLECTION}
						</span>
						<span
							className="product-asset-collection_left_aligned__get-started__text product-asset-collection_left_aligned__get-started__text--interaction"
							onClick={this.toggleModal}>
							{phrases.NO_ASSET_COLLECTION_INTERACTION}
						</span>
					</div>
				)}

				<AssetsLeftAlignedCreateAssignModal
					modalVisible={this.state.modalVisible}
					handleClose={this.toggleModal}
					updateDefaultAssetCollection={updateDefaultAssetCollection}
					defaultAssetCollection={defaultAssetCollection}
					productId={productId}
					addAssetCollection={addAssetCollection}
					editProduct={editProduct}
					reloadTable={reloadProductTable || reloadFilterTable}
					filterId={filterId}
					editFilter={editFilter}
					resetAssets={resetAssets}
				/>
			</>
		);
	}
}

AssetLeftAligned.propTypes = {
	productId: PropTypes.number,
	assetCollectionId: PropTypes.number,
	dataList: PropTypes.object,
	setAsset: PropTypes.func,
	resetAssets: PropTypes.func,
	updateDefaultAssetCollection: PropTypes.func,
	defaultAssetCollection: PropTypes.func,
	reloadProductTable: PropTypes.func,
	editFilter: PropTypes.func,
	reloadFilterTable: PropTypes.func,
	editProduct: PropTypes.func,
	filterId: PropTypes.number,
	reduxKey: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setAsset,
			updateAsset,
			resetAssets,
			updateDefaultAssetCollection,
		},
		dispatch
	);
};

const mapStateToProps = (initialStore, ownProps) => (store) => {
	return {
		imgResizerUsed: store.productAssetLeftAligned.data.imgResizerUsed,
		defaultAssetCollection:
			store.productAssetLeftAligned.data.defaultAssetCollection,
		dataList:
			store.listData[
				`product-pos/assets/${ownProps.assetCollectionId || ''}-aligned`
			],
	};
};

export default connectWithStore(
	AssetLeftAligned,
	mapStateToProps,
	mapDispatchToProps
);
