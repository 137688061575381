import {
	SET_USER,
	CLEAR_USER,
	SET_ACCESS_TOKEN,
	SET_TENANTS,
	SET_ACTIVE_TENANT,
} from '../actions/user.actions';

const defaultState = {
	persist: true,
	user: null,
	activeTenant: null,
	tenants: [],
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
		case SET_USER:
			return {
				...state,
				user: payload.user,
			};

		case SET_ACCESS_TOKEN: {
			return {
				...state,
				accessToken: payload.accessToken,
			};
		}
		case SET_TENANTS: {
			return {
				...state,
				tenants: payload.tenants,
			};
		}

		case SET_ACTIVE_TENANT: {
			return {
				...state,
				activeTenant: payload.activeTenant,
			};
		}

		case CLEAR_USER:
			return {
				...state,
				user: null,
			};

		default:
			return state;
	}
}

export default reducer;
