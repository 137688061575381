'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	updateBatchForm,
	removeBatchListItem,
	addBatchList,

	// regular stuff
	setWaitingTimePerformanceGridEntries,
	updateWaitingTimePerformanceGridEntries,
	resetState,
} from './store/workplaceWaitingTimePerformanceGrids.actions';

// services
import {
	fetchWorkplaceWaitingTimePerformanceGrids,
	editWorkplaceWaitingTimePerformanceGrid,
	editWorkplaceWaitingTimePerformanceGrids,
	addWorkplaceWaitingTimePerformanceGrids,
	deleteWorkplaceWaitingTimePerformanceGrids,
} from './workplaceWaitingTimePerformanceGrids.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Button, Icon, SingleDatePickerInput } from 'dumb';
import WaitingTimePerformanceGridsModalBatch from './components/batch/workplaceWaitingTimePerformanceGridsModalBatch';
import { DateFilterSelector } from 'reactDataWrapper/utilities';

// utils
import { getEditedValues } from 'services/utils';
import { getWorkplaceFilter } from './utils';

// lodash
import _get from 'lodash/get';

// phrases/ utils
import phrases from './workplaceWaitingTimePerformanceGrids.phrases';
import moment from 'moment';
import constants from 'services/constants';

const reduxKey = '/administration/workplace_waiting_time_performance_grids';

class WaitingTimePerformanceGridEntries extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
			workplaceFilter: null,
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.addWorkplaceWaitingTimePerformanceGridsWrapper = this.addWorkplaceWaitingTimePerformanceGridsWrapper.bind(
			this
		);
		this.fetchData = this.fetchData.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.getWorkplaceFilter = this.getWorkplaceFilter.bind(this);

		this.columns = [
			{
				Header: 'Workplace',
				id: 'workplace',
				accessor: (d) => _get(d, 'workplace.name'),
				filterPath: ':workplace.name',
			},
			{
				Header: 'From',
				id: 'period-from',
				accessor: (d) => _get(d, 'period.from'),
				filterPath: ':period.from',
				Filter: ({ column }) => (
					<DateFilterSelector
						reduxKey={reduxKey}
						columnId={column.id}
						dateIdentifier="from"
					/>
				),
			},
			{
				Header: 'To',
				id: 'period-to',
				accessor: (d) => _get(d, 'period.to'),
				filterPath: ':period.to',
				Filter: ({ column }) => (
					<DateFilterSelector
						reduxKey={reduxKey}
						columnId={column.id}
						dateIdentifier="from"
					/>
				),
			},
		];
	}

	editEntry() {
		const {
			workplaceWaitingTimePerformanceGrids,
			defaultWorkplaceWaitingTimePerformanceGrids,
		} = this.props;

		const editedValues = getEditedValues({
			oldData: defaultWorkplaceWaitingTimePerformanceGrids,
			newData: workplaceWaitingTimePerformanceGrids,
		});

		const payload = {
			id: workplaceWaitingTimePerformanceGrids.id,
			...((editedValues.periodFrom || editedValues.periodTo) && {
				period: {
					from: editedValues.periodFrom
						? moment
								.utc(editedValues.periodFrom, constants.dateFormat)
								.format(constants.shortDate)
						: undefined,
					to: editedValues.periodTo
						? moment
								.utc(editedValues.periodTo, constants.dateFormat)
								.format(constants.shortDate)
						: undefined,
				},
			}),
		};

		return editWorkplaceWaitingTimePerformanceGrid(payload);
	}

	editMultiple(selectedRows) {
		const { workplaceWaitingTimePerformanceGrids } = this.props;

		const payload = {
			...((workplaceWaitingTimePerformanceGrids.periodFrom ||
				workplaceWaitingTimePerformanceGrids.periodTo) && {
				period: {
					...(workplaceWaitingTimePerformanceGrids.periodFrom && {
						from: moment
							.utc(
								workplaceWaitingTimePerformanceGrids.periodFrom,
								constants.dateFormat
							)
							.format(constants.shortDate),
					}),
					...(workplaceWaitingTimePerformanceGrids.periodTo && {
						to: moment
							.utc(
								workplaceWaitingTimePerformanceGrids.periodTo,
								constants.dateFormat
							)
							.format(constants.shortDate),
					}),
				},
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editWorkplaceWaitingTimePerformanceGrids({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteWorkplaceWaitingTimePerformanceGrids(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			workplace: {
				value: data.workplace,
				label: data.workplace.name,
			},
			periodFrom: data.period.from,
			periodTo: data.period.to,
			singleEdit: true,
		};

		this.props.setWaitingTimePerformanceGridEntries(payload);
	}

	getEditableCells() {
		const { workplaceWaitingTimePerformanceGrids } = this.props;

		return [
			...(workplaceWaitingTimePerformanceGrids.singleEdit
				? [
						{
							header: 'Workplace',
							value: (
								<span>
									{workplaceWaitingTimePerformanceGrids.workplace?.label}
								</span>
							),
						},
				  ]
				: []),
			{
				header: 'From',
				value: (
					<SingleDatePickerInput
						id="period-from"
						label={phrases.MODAL_BATCH_STEP_PERIOD_FROM}
						onChange={(event) => this.editStoreEntry('periodFrom', event)}
						selectedDate={
							workplaceWaitingTimePerformanceGrids.periodFrom
								? moment
										.utc(
											workplaceWaitingTimePerformanceGrids.periodFrom,
											constants.dateFormat
										)
										.format(constants.shortDate)
								: ''
						}
						noClockButton
						clearable
					/>
				),
			},
			{
				header: 'To',
				value: (
					<SingleDatePickerInput
						id="period-to"
						label={phrases.MODAL_BATCH_STEP_PERIOD_TO}
						onChange={(event) => this.editStoreEntry('periodTo', event)}
						selectedDate={
							workplaceWaitingTimePerformanceGrids.periodTo
								? moment
										.utc(
											workplaceWaitingTimePerformanceGrids.periodTo,
											constants.dateFormat
										)
										.format(constants.shortDate)
								: ''
						}
						noClockButton
						clearable
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const { updateWaitingTimePerformanceGridEntries } = this.props;

		const value = e?.target?.value ?? e;

		const updateObject = {
			[name]: value,
		};

		updateWaitingTimePerformanceGridEntries(updateObject);
	}

	fetchData(state) {
		const { waitingTimePerformanceGridId } = this.props;

		const filter = `:waiting_time_performance_grid.id=='${waitingTimePerformanceGridId}'`;

		return fetchWorkplaceWaitingTimePerformanceGrids(state, filter);
	}

	toggleModal() {
		let workplaceFilter = null;
		if (!this.state.showModal) workplaceFilter = this.getWorkplaceFilter();

		this.setState((prevState) => ({
			showModal: !prevState.showModal,
			workplaceFilter,
		}));
		this.props.resetState();
	}

	getWorkplaceFilter() {
		const { listData } = this.props;

		const filter = getWorkplaceFilter(listData);

		return filter;
	}

	addWorkplaceWaitingTimePerformanceGridsWrapper(data) {
		return addWorkplaceWaitingTimePerformanceGrids(data).then(() =>
			this.setState(() => ({
				key: moment.utc(),
			}))
		);
	}

	render() {
		const {
			batchList,
			removeBatchListItem,
			waitingTimePerformanceGridId,
			batchForm,
			addBatchList,
			updateBatchForm,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={reduxKey}
					accessAreasAllowedToEdit={['Global Sales Configuration', 'Sales Configuration']}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					actionRender={
						<Button
							type="inverted"
							label="Batch"
							shadow
							onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
				/>

				<WaitingTimePerformanceGridsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					removeBatchListItem={removeBatchListItem}
					addWorkplaceWaitingTimePerformanceGrids={
						this.addWorkplaceWaitingTimePerformanceGridsWrapper
					}
					waitingTimePerformanceGridId={waitingTimePerformanceGridId}
					batchForm={batchForm}
					addBatchList={addBatchList}
					updateBatchForm={updateBatchForm}
					workplaceFilter={this.state.workplaceFilter}
				/>
			</>
		);
	}
}

WaitingTimePerformanceGridEntries.propTypes = {
	workplaceWaitingTimePerformanceGrids: PropTypes.object,
	updateWaitingTimePerformanceGridEntries: PropTypes.func,
	setWaitingTimePerformanceGridEntries: PropTypes.func,
	resetState: PropTypes.func,
	batchList: PropTypes.array,
	removeBatchListItem: PropTypes.func,
	defaultWorkplaceWaitingTimePerformanceGrids: PropTypes.object,
	batchForm: PropTypes.object,
	waitingTimePerformanceGridId: PropTypes.number,
	addBatchList: PropTypes.func,
	updateBatchForm: PropTypes.func,
	listData: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			removeBatchListItem,
			updateWaitingTimePerformanceGridEntries,
			setWaitingTimePerformanceGridEntries,
			resetState,
			addBatchList,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		workplaceWaitingTimePerformanceGrids:
			store.globalAdministrationWorkplaceWaitingTimePerformanceGrids.data
				.workplaceWaitingTimePerformanceGrids,
		defaultWorkplaceWaitingTimePerformanceGrids:
			store.globalAdministrationWorkplaceWaitingTimePerformanceGrids.data
				.defaultWorkplaceWaitingTimePerformanceGrids,
		batchList:
			store.globalAdministrationWorkplaceWaitingTimePerformanceGrids.data
				.batchList,
		batchForm:
			store.globalAdministrationWorkplaceWaitingTimePerformanceGrids.data
				.batchForm,
		listData: store.listData?.[reduxKey]?.data?.listData ?? [],
	};
};

export default connectWithStore(
	WaitingTimePerformanceGridEntries,
	mapStateToProps,
	mapDispatchToProps
);
