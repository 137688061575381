'use strict';

/**
 * ContentWrapper
 * @param  {body} content
 * @param  {foot} content
 * @param  {title} content
 */
class ContentWrapper {
	constructor({ body = null, foot, title }) {
		// Set type
		this.type = 'contentWrapper';

		this.body = body;

		this.foot = foot;

		this.title = title;
	}
}

export default ContentWrapper;
