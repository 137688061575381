import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// components
import { Tag, Icon } from 'dumb';
import { Tip } from 'dumb/tips';
import { ModalRow } from 'dumb/modal';

// utils
import phrases from './../clockinDeviationApprovalMobileView.phrases';
import enums from './../../../clockinDeviationApproval.enums';

const ClockinDeviationApprovalMobileViewPreview = ({ data }) => {
	return (
		<div>
			<div className="clockin-deviation-approval-modal__mobile-view__preview__tip-wrapper">
				<Tip content={phrases.PREVIEW_STEP_TIP} />
			</div>

			<div className="clockin-deviation-approval-modal__mobile-view__preview__entries">
				{data.map((entry, index) => {
					return (
						<ModalRow key={index}>
							<div className="clockin-deviation-approval-modal__mobile-view__preview__entries__entry">
								<Icon
									className={cx(
										'clockin-deviation-approval-modal__mobile-view__preview__entries__entry__icon',
										{
											'clockin-deviation-approval-modal__mobile-view__preview__entries__entry__icon--confirmed':
												entry.status === enums.CONFIRMED,
										}
									)}
									name={
										entry.status === enums.CONFIRMED ? 'thumb_up' : 'thumb_down'
									}
								/>
								<span>{entry.employment?.person?.full_name}</span>
								<div className="clockin-deviation-approval-modal__mobile-view__preview__entries__entry__tag-wrapper">
									<Tag>{entry.deviation_reason?.type}</Tag>
								</div>
							</div>
						</ModalRow>
					);
				})}
			</div>
		</div>
	);
};

ClockinDeviationApprovalMobileViewPreview.propTypes = {
	data: PropTypes.array,
};

export default ClockinDeviationApprovalMobileViewPreview;
