export const UPDATE_INGREDIENT_CONFIGURATION_INGREDIENT =
	'INGREDIENT_CONFIGURATIONS/UPDATE_INGREDIENT_CONFIGURATION_INGREDIENT';
export const RESET_INGREDIENT_CONFIGURATION_INGREDIENT =
	'INGREDIENT_CONFIGURATIONS/RESET_INGREDIENT_CONFIGURATION_INGREDIENT';
export const SET_INGREDIENT_CONFIGURATION_INGREDIENT =
	'INGREDIENT_CONFIGURATIONS/SET_INGREDIENT_CONFIGURATION_INGREDIENT';

// batch
export const UPDATE_BATCH_FORM =
	'INGREDIENT_CONFIGURATIONS/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'INGREDIENT_CONFIGURATIONS/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'INGREDIENT_CONFIGURATIONS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'INGREDIENT_CONFIGURATIONS/REMOVE_BATCH_LIST_ITEM';

export function updateIngredientConfigurationIngredient(payload) {
	return {
		type: UPDATE_INGREDIENT_CONFIGURATION_INGREDIENT,
		payload,
	};
}

export function resetIngredientConfigurationIngredient() {
	return {
		type: RESET_INGREDIENT_CONFIGURATION_INGREDIENT,
	};
}

export function setIngredientConfigurationIngredient(payload) {
	return {
		type: SET_INGREDIENT_CONFIGURATION_INGREDIENT,
		payload,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}
