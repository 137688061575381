/* eslint-disable react/prop-types */

import React from 'react';

// components
import { DateFilterSelector } from 'reactDataWrapper/utilities';

// utils
import moment from 'moment';
import constants from 'services/constants';

// lodash
import _get from 'lodash/get';

export default function getColumns(reduxKey) {
	return [
		{
			Header: 'POS Configuration',
			id: 'posConfiguration',
			accessor: (d) =>
				_get(d, 'pos_configuration_version.pos_configuration.name', null),
			filterPath: ':pos_configuration_version.pos_configuration.name',
		},
		{
			Header: 'Pos configuration version',
			id: 'posConfigurationVersion',
			accessor: (d) => _get(d, 'pos_configuration_version.name', null),
			filterPath: ':pos_configuration_version.name',
		},
		{
			Header: 'Pos configuration description',
			id: 'posConfigurationDescription',
			show: false,
			accessor: (d) => _get(d, 'pos_configuration_version.description', null),
			filterPath: ':pos_configuration_version.description',
		},

		{
			Header: 'Active from',
			width: 100,
			id: 'activeFrom',
			accessor: (d) => _get(d, 'pos_configuration_version.active.from', null),
			Cell: (d) => {
				// eslint-disable-next-line camelcase
				if (!d.original?.pos_configuration_version?.active) return null;
				return (
					<span>
						{moment
							.utc(d.original.pos_configuration_version.active.from)
							.format(constants.shortDate)}
					</span>
				);
			},
			filterPath: ':pos_configuration_version.active.from',
			Filter: ({ column }) => (
				<DateFilterSelector reduxKey={reduxKey} columnId={column.id} />
			),
		},
		{
			Header: 'Active to',
			width: 100,
			id: 'activeTo',
			accessor: (d) => _get(d, 'pos_configuration_version.active.to', null),
			Cell: (d) => {
				// eslint-disable-next-line camelcase
				if (!d.original?.pos_configuration_version?.active) return null;

				return (
					<span>
						{moment
							.utc(d.original.pos_configuration_version.active.to)
							.format(constants.shortDate)}
					</span>
				);
			},
			filterPath: ':pos_configuration_version.active.to',
			Filter: ({ column }) => (
				<DateFilterSelector reduxKey={reduxKey} columnId={column.id} />
			),
		},

		{
			Header: 'Ingredient',
			id: 'Ingredient',
			accessor: (d) => _get(d, 'ingredient.name', null),
			filterPath: ':ingredient.name',
		},
		{
			Header: 'Ingredient category',
			id: 'ingredientCategory',
			accessor: (d) => _get(d, 'ingredient.ingredient_category.name', null),
			filterPath: ':ingredient.ingredient_category.name',
		},
		{
			Header: 'Market',
			id: 'market',
			accessor: (d) =>
				_get(
					d,
					'pos_configuration_version.pos_configuration.market.name',
					null
				),
			filterPath: ':pos_configuration_version.pos_configuration.market.name',
		},
		{
			Header: 'Available in app',
			width: 100,
			id: 'availableInapp',
			accessor: 'available_in_app',
			filterable: false,
			Cell: (d) => {
				return (
					<input
						id="availableInAppTableCheckbox"
						type="checkbox"
						checked={d.original.available_in_app}
						disabled
					/>
				);
			},
			filterPath: ':available_in_app',
		},
		{
			Header: 'Alternative Id',
			width: 80,
			id: 'cat',
			accessor: (d) => _get(d, 'alternative_id', null),
			filterPath: ':alternative_id',
		},
		{
			Header: 'To Stay Price',
			width: 80,
			id: 'tsprice',
			accessor: 'to_stay_price',
			filterPath: ':to_stay_price',
		},
		{
			Header: 'To Go Price',
			width: 80,
			id: 'tgprice',
			accessor: 'to_go_price',
			filterPath: ':to_go_price',
		},
		{
			Header: 'Delivery price',
			width: 80,
			id: 'delivery-price',
			accessor: 'delivery_price',
			filterPath: ':delivery_price',
		},
	];
}
