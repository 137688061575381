import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './ingredientTranslations.phrases';

/**
 * @function fetchIngredientTranslations
 * @description Fetch all ingredient translations
 * @param {object} state
 */
export function fetchIngredientTranslations(state, filter) {
	if (state.filter) filter = `${filter};${state.filter}`;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get('/product/ingredient_translations', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addIngredientTranslations
 * @description add ingredient translations
 */
export function addIngredientTranslations(data) {
	return post('/product/ingredient_translations', data)
		.then((res) => {
			setFeedback(phrases.INGREDIENT_TRANSLATIONS_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editIngredientTranslation
 * @description edit ingredient translation
 */
export function editIngredientTranslation(data) {
	return post(`/product/ingredient_translations/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.INGREDIENT_TRANSLATION_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editIngredientTranslations
 * @description edit ingredient translations
 */
export function editIngredientTranslations(data) {
	return patch('/product/ingredient_translations', data)
		.then((res) => {
			setFeedback(phrases.INGREDIENT_TRANSLATIONS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteIngredientTranslations
 * @description delete ingredient translation
 */
export function deleteIngredientTranslations(id) {
	return remove(`/product/ingredient_translations/${id}`)
		.then((res) => {
			setFeedback(phrases.INGREDIENT_TRANSLATIONS_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
