import React, { ChangeEvent } from 'react';

// interfaces
import { IMarketTagsReduxValueBatch } from '../../../types';
import { SelectOption } from 'types/select';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import {
	editBatchListItem,
	removeBatchListItem,
} from '../../../store/marketTags.actions';

// components
import { Input } from 'dumb';
import { ModalRow } from 'dumb/modal';
import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';
import { IconButton, Tooltip as MuiTooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MarketTagsModalTip from './marketTagsModalTip';

// phrases
import phrases from '../../../marketTags.phrases';

type Props = {
	source: 'market' | 'tag';
};

const MarketTagsModalStep = ({ source }: Props): JSX.Element => {
	const dispatch = useAppDispatch();
	const batchList = useAppSelector((store) => store.marketTags.batchList);

	const handleUpdateEntry = (
		row: IMarketTagsReduxValueBatch,
		type: keyof IMarketTagsReduxValueBatch,
		value: number | null | SelectOption
	) => {
		const payload: Partial<IMarketTagsReduxValueBatch> = {
			identifier: row.identifier,
			[type]: value,
		};

		dispatch(editBatchListItem(payload));
	};
	const handleDeleteRow = (identifier: string) => () =>
		dispatch(removeBatchListItem(identifier));

	return (
		<>
			<ModalRow>
				<MarketTagsModalTip page="two" />
			</ModalRow>

			<TableList>
				<thead>
					<tr>
						<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>

						{source === 'tag' ? (
							<th>{phrases.MARKET}</th>
						) : (
							<th>{phrases.TAG}</th>
						)}

						<th>{phrases.DEFAULT_LENGTH_IN_MINUTES}</th>
						<th>{phrases.MINUTES_FROM_START_OF_SHIFT}</th>
						<th>{phrases.MINUTES_FROM_END_OF_SHIFT}</th>

						<th />
					</tr>
				</thead>
				<tbody>
					{batchList.map((entry, i) => {
						const deleteRowButtonDisabled = batchList.length === 1;

						return (
							<TableListRow key={entry.identifier}>
								<TableListCell>{i + 1}</TableListCell>

								{source === 'tag' ? (
									<TableListCell>{entry.market?.label}</TableListCell>
								) : (
									<TableListCell>{entry.tag?.label}</TableListCell>
								)}

								<TableListCell>
									<Input
										id="default-length-in-minutes"
										dataCy="market-tag-default-length-in-minutes"
										placeholder={phrases.DEFAULT_LENGTH_IN_MINUTES_PLACEHOLDER}
										type="number"
										required
										value={entry.defaultLengthInMinutes}
										onChange={(e: ChangeEvent<HTMLInputElement>) =>
											handleUpdateEntry(
												entry,
												'defaultLengthInMinutes',
												e.target.value as unknown as number
											)
										}
									/>
								</TableListCell>

								<TableListCell>
									<Input
										id="minutes-from-start-of-shift"
										dataCy="market-tag-minutes-from-start-of-shift"
										placeholder={
											phrases.MINUTES_FROM_START_OF_SHIFT_PLACEHOLDER
										}
										type="number"
										value={entry.minutesFromStartOfShift}
										disabled={!!entry.minutesFromEndOfShift}
										onChange={(e: ChangeEvent<HTMLInputElement>) =>
											handleUpdateEntry(
												entry,
												'minutesFromStartOfShift',
												e.target.value as unknown as number
											)
										}
									/>
								</TableListCell>

								<TableListCell>
									<Input
										id="minutes-from-end-of-shift"
										dataCy="market-tag-minutes-from-end-of-shift"
										placeholder={phrases.MINUTES_FROM_END_OF_SHIFT_PLACEHOLDER}
										type="number"
										value={entry.minutesFromEndOfShift}
										disabled={!!entry.minutesFromStartOfShift}
										onChange={(e: ChangeEvent<HTMLInputElement>) =>
											handleUpdateEntry(
												entry,
												'minutesFromEndOfShift',
												e.target.value as unknown as number
											)
										}
									/>
								</TableListCell>

								<TableListCell>
									<MuiTooltip title={phrases.DELETE_ROW_TOOLTIP}>
										<IconButton
											onClick={handleDeleteRow(entry.identifier)}
											disabled={deleteRowButtonDisabled}
										>
											<DeleteIcon
												sx={{
													color: (theme) => theme.palette.warning.main,
												}}
											/>
										</IconButton>
									</MuiTooltip>
								</TableListCell>
							</TableListRow>
						);
					})}
				</tbody>
			</TableList>
		</>
	);
};

export default MarketTagsModalStep;
