import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

// styles
import './tableListRow.css';

const TableListRow = (props) => {
	return (
		<tr
			className={cx('table-list-row', {
				'table-list-row--light': props.type === 'light',
			})}>
			{props.children}
		</tr>
	);
};

TableListRow.propTypes = {
	children: PropTypes.array,
	type: PropTypes.string,
};

export default TableListRow;
