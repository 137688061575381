import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { store } from 'appState';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';

import { ModalStepper, Overlay, Button } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';
import BatchStepForm from './components/batchStepForm';
import BatchStepList from './components/batchStepList';

import _uniqueId from 'lodash/uniqueId';
import _get from 'lodash/get';
import { enums } from '../../salaryComponents.enums';
import moment from 'moment';

class ModalBatch extends Component {
	constructor(props) {
		super(props);

		this.today = moment.utc().format('YYYY-MM-DD');

		this.state = {
			showOverlay: false,
			overlayType: '',
			loadingModal: false,
			activeFilterOn: true,
			activeFilter: `:active.to=ge='${this.today}'`,
		};

		this.modalRef = React.createRef();

		// Binds
		this._goToStep = this._goToStep.bind(this);
		this._batchSubmit = this._batchSubmit.bind(this);
		this.handleToggleOverlay = this.handleToggleOverlay.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.onOverlayOpen = this.onOverlayOpen.bind(this);
		this._setActiveFilter = this._setActiveFilter.bind(this);

		this.columnsGlobalSalary = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
		];

		this.columnsSalaryGroups = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Moneyball Sub position',
				id: 'mon-sub',
				accessor: (d) => _get(d, 'moneyball_sub_position.description', ''),
				filterPath: ':moneyball_sub_position.description',
			},
			{
				Header: 'Market',
				id: 'market',
				accessor: (d) => _get(d, 'market.name', ''),
				filterPath: ':market.name',
			},
			{
				Header: 'From',
				id: 'activefrom',
				accessor: (d) => _get(d, 'active.from', ''),
				filterPath: ':active.from',
			},
			{
				Header: 'To',
				id: 'activto',
				accessor: (d) => _get(d, 'active.to', ''),
				filterPath: ':active.to',
			},
		];
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	handleToggleOverlay(type) {
		const { listGlobalSalaryGroups, listGlobalSalaryComponents } = this.props;

		if (this.state.showOverlay) {
			switch (this.state.overlayType) {
				case 'salary_group': {
					let selectedRowsInList = _get(listGlobalSalaryGroups, 'ui.selectedRows', []);

					selectedRowsInList = selectedRowsInList.map((x) => {
						return {
							label: x.name,
							value: x,
						};
					});
					this.editStoreEntry(selectedRowsInList, 'salary_group');
					break;
				}
				case 'global_salary_component': {
					let selectedRowsInList = _get(listGlobalSalaryComponents, 'ui.selectedRows', []);
					selectedRowsInList = selectedRowsInList.map((x) => {
						return {
							label: x.name,
							value: x,
						};
					});
					this.editStoreEntry(selectedRowsInList, 'global_salary_component');
					break;
				}
				default:
				// nothing happens :)
			}
		}

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
			overlayType: prevState.overlayType ? '' : type,
		}));
	}

	editStoreEntry(e, type) {
		const { batchOptions } = this.props;

		const value = e && e.target ? e.target.value : e;

		this.props.updateBatchForm({
			...batchOptions,
			...{ [type]: value },
		});
	}

	fetchData(state) {
		if (this.state.overlayType === 'salary_group') {
			// ingredient_pricing_groups
			return this.props.fetchSalaryGroups(state);
		} else {
			// ingredient_category
			return this.props.fetchGlobalSalaryComponents(state);
		}
	}

	onOverlayOpen() {
		const { batchOptions } = this.props;
		const { overlayType } = this.state;

		switch (overlayType) {
			case 'salary_group':
				if (batchOptions.salary_group.length) {
					// Copy ingredient cats from select to
					batchOptions.salary_group.map((x) => {
						store.dispatch(
							actions.addRowToSelection({
								reduxKey: 'salary/salary_groups/batch',
								data: x.value,
							})
						);
					});
				}
				break;

			case 'global_salary_component':
				if (batchOptions.global_salary_component.length) {
					// Copy assets coll from select to
					batchOptions.global_salary_component.map((x) => {
						store.dispatch(
							actions.addRowToSelection({
								reduxKey: 'salary/global_salary_components/batch',
								data: x.value,
							})
						);
					});
				}
				break;
		}
	}

	_onSubmitForm() {
		const { batchOptions, salaryGroupId } = this.props;

		let listArray = [];

		// Arrange new lsit
		if (salaryGroupId) {
			listArray = batchOptions.global_salary_component.map((y) => {
				const frequency = batchOptions?.frequency ?? {
					value: enums.FREQUENCY[0],
					label: enums.FREQUENCY[0],
				};

				return {
					identifier: _uniqueId('row-'), // used to identify what to edit on step#2
					name: batchOptions.name,
					global_salary_component: y.value,
					salary_group: salaryGroupId,
					amount_type: _get(batchOptions, 'amount_type.value', enums.AMOUNT_TYPE[0]),
					amount: batchOptions.amount,
					frequency,
					active: batchOptions.active,
					external_export_id: batchOptions.external_export_id,
				};
			});
		} else {
			listArray = batchOptions.salary_group.reduce((acc, x) => {
				const list = batchOptions.global_salary_component.map((y) => {
					const frequency = batchOptions?.frequency ?? {
						value: enums.FREQUENCY[0],
						label: enums.FREQUENCY[0],
					};

					return {
						identifier: _uniqueId('row-'), // used to identify what to edit on step#2
						name: batchOptions.name,
						global_salary_component: y.value,
						salary_group: x.value,
						amount_type: _get(batchOptions, 'amount_type.value', enums.AMOUNT_TYPE[0]),
						amount: batchOptions.amount,
						frequency,
						active: batchOptions.active,
						external_export_id: batchOptions.external_export_id,
					};
				});

				return [...acc, ...list];
			}, []);
		}

		this.props.addBatchList(listArray);

		this._goToStep(2);
	}

	_batchSubmit() {
		const { batchList, handleClose, salaryGroupId } = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		let payload = batchList.map((x) => {
			const amount = _get(x, 'amount', '').replace(',', '.');

			return {
				salary_group: salaryGroupId || _get(x, 'salary_group.id', ''),
				global_salary_component: _get(x, 'global_salary_component.id', ''),
				name: _get(x, 'name', ''),
				amount_type: _get(x, 'amount_type.value', enums.AMOUNT_TYPE[0]),
				amount: amount,
				frequency: x?.frequency?.value ?? enums.FREQUENCY[0],
				active: {
					from: _get(x, 'active.from', ''),
					to: _get(x, 'active.to', '9999-12-31'),
				},
				external_export_id: _get(x, 'external_export_id', ''),
			};
		});

		payload = {
			batch: payload,
		};

		this.props
			.addSalaryComponent(payload)

			.then((e) => {
				handleClose();
				this.setState(() => ({
					loadingModal: false,
				}));
			})

			.catch((e) => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	_setActiveFilter() {
		this.setState((prevState) => ({
			activeFilterOn: !prevState.activeFilterOn,
			activeFilter: prevState.activeFilterOn ? '' : `:active.to=ge='${this.today}'`,
		}));
	}

	getAdditionalFilters() {
		const { activeFilterOn } = this.state;

		return (
			<div className="salary-component__filters ">
				<Button type={activeFilterOn ? '' : 'inverted'} shadow title="Filter active" onClick={this._setActiveFilter}>
					Active
				</Button>
			</div>
		);
	}

	render() {
		const {
			modalVisible,
			handleClose,
			batchOptions,
			updateBatchForm,
			removeBatchListItem,
			editBatchListItem,
			batchList,
			salaryGroupId,
		} = this.props;
		const { showOverlay } = this.state;

		const isValidStep = !!(
			(batchOptions.global_salary_component?.length ?? 0) &&
			(salaryGroupId || (batchOptions.salary_group?.length ?? 0))
		);

		const steps = [
			{
				component: (
					<BatchStepForm
						salaryGroupId={salaryGroupId}
						batchOptions={batchOptions}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.handleToggleOverlay}
					/>
				),
				title: 'Batch add',
				isValid: isValidStep,
				onNext: () => this._onSubmitForm(),
				loading: false,
				confirmButtonLabel: 'next',
				noScroll: true,
			},
			{
				component: (
					<BatchStepList
						batchList={batchList}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
					/>
				),
				title: 'Confirm entries',
				isValid: true,
				onNext: () => this._batchSubmit(),
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: 'submit',
				cancelButtonLabel: 'back',
				defaultStyles: false,
				noScroll: true,
			},
		];

		const columns = this.state.overlayType
			? this.state.overlayType === 'salary_group'
				? this.columnsSalaryGroups
				: this.columnsGlobalSalary
			: [];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						className="modal-batch"
						ref={this.modalRef}
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={showOverlay ? () => {} : handleClose}
					/>
				)}

				<Overlay zIndex={551} height={850} list visible={showOverlay} onClose={this.handleToggleOverlay}>
					<ReactDataWrapper
						title={this.state.overlayType === 'salary_group' ? 'Salary group' : 'Global Salary Component'}
						className="-striped -highlight"
						columns={columns}
						// totalEntries={this.state.totalEntries} // Display the total number of pages
						fetchData={this.fetchData} // Request new batchOptions when things change
						filterable
						disableFetchCsvButton
						enableMultiSelection
						batchSelection
						defaultPageSize={20}
						onInitialization={() => this.onOverlayOpen()}
						reduxKey={
							this.state.overlayType === 'salary_group'
								? 'salary/salary_groups/batch'
								: 'salary/global_salary_components/batch'
						}
						manual
						actionsWidth={0}
						style={{
							maxHeight: '720px',
						}}
						extraFilters={this.state.overlayType === 'salary_group' ? `${this.state.activeFilter}` : null}
						customAreaComponents={this.state.overlayType === 'salary_group' ? this.getAdditionalFilters() : null}
					/>
				</Overlay>
			</>
		);
	}
}

ModalBatch.propTypes = {
	salaryGroupId: PropTypes.number,
	modalVisible: PropTypes.bool,
	batchOptions: PropTypes.object,
	listGlobalSalaryGroups: PropTypes.object,
	listGlobalSalaryComponents: PropTypes.object,
	batchList: PropTypes.array,

	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	addBatchList: PropTypes.func,
	fetchGlobalSalaryComponents: PropTypes.func,
	fetchSalaryGroups: PropTypes.func,
	addSalaryComponent: PropTypes.func,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

ModalBatch.defaultProps = {
	modalVisible: false,
	headers: [],
	editableCells: [],
	loading: false,
};

export default ModalBatch;
