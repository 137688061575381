import React, { Component } from 'react';
import PropTypes from 'prop-types';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import { batch } from 'react-redux';
import { editEntry } from 'reactDataWrapper/reactDataWrapper.actions';

// actions
import {
	setManagedShiftClockin,
	updateManagedShiftClockin,
	resetManagedShiftClockin,
	setModalType,
	toggleModal,
	setStatusFilter,
	addBreakModalUpdateBreak,
	addBreakModalResetBreak,
} from './store/shiftClockins.actions';

// services
import {
	fetchShiftClockins,
	postCloseOpenWorkdays,
	postManagedClockinCorrection,
	fetchShiftClockinHourlyStats,
	correctShiftClockinBreak,
} from './shiftClockins.services';

// utils
import {
	getFilter,
	getBreakRegistrationPossible,
	getStatusFilterOptions,
	getNoShowBreaksToPropagate,
} from './utils';

// components
import { Button, Icon, ButtonLoader, Tooltip } from 'dumb';
import HotdamnbarLabelProvider from 'hotdamnbar/subcomponents/hotdamnbarLabelProvider/hotdamnbarLabelProvider';
import ShiftClockinsTable from './components/shiftClockinsTable/shiftClockinsTable';
import ShiftClockinsModal from './components/shiftClockinsModal/shiftClockinsModal';
import CollectionSelect from 'collectionSelect';
import ShiftDeviationExportModal from './components/shiftDeviationExportModal/shiftDeviationExportModal';

// hotbar
import Hotdamnbar from 'hotdamnbar';
import AdyenReconsilidationHotbar from './shiftClockins.hotbar';

// moment
import moment from 'moment';

// phrases/ enums
import phrases from './shiftClockins.phrases';
import enums from './shiftClockins.enums';
import collectionSelectEnums from 'services/enums/collectionSelect';

// styles
import './shiftClockins.css';

const reduxKey = '/shiftplanning/managed_clockings';

class ShiftClockins extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			key: '',
			breakRegistrationPossible: false,
			showTable: false,
			downloadingCsv: false,
			showDeviationExportModal: false,
		};

		this.reRenderTable = this.reRenderTable.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.updateEntry = this.updateEntry.bind(this);
		this.onManagedClockinNoShowCellClick =
			this.onManagedClockinNoShowCellClick.bind(this);
		this.postManagedClockinCorrectionWrapper =
			this.postManagedClockinCorrectionWrapper.bind(this);
		this.handleStatusFilterChange = this.handleStatusFilterChange.bind(this);
		this._getCanShowTable = this._getCanShowTable.bind(this);
		this.getTableActionButtons = this.getTableActionButtons.bind(this);
		this.onManagedClockinAddBreakClick =
			this.onManagedClockinAddBreakClick.bind(this);
		this.setBreaksAsNoShow = this.setBreaksAsNoShow.bind(this);
		this.editClockedValue = this.editClockedValue.bind(this);
		this.toggleShiftDeviationExportModal =
			this.toggleShiftDeviationExportModal.bind(this);
	}

	componentDidMount() {
		// check if enough hotbar value selected to show the table
		const canShowTable = this._getCanShowTable();

		if (!canShowTable) return;

		// before we render the table, check if market already selected and if break reg. possible
		const breakRegistrationPossible = getBreakRegistrationPossible(
			this.props.hotbar
		);

		this.setState(() => ({ showTable: true, breakRegistrationPossible }));
	}

	handleStatusFilterChange(value) {
		const { setStatusFilter } = this.props;

		setStatusFilter(value);
		this.reRenderTable();
	}

	toggleModal() {
		const {
			toggleModal,
			modalVisible,
			resetManagedShiftClockin,
			addBreakModalResetBreak,
		} = this.props;

		if (modalVisible) {
			addBreakModalResetBreak();
			resetManagedShiftClockin();
		}

		toggleModal();
	}

	reRenderTable() {
		const { hotbar } = this.props;

		const breakRegistrationPossible = getBreakRegistrationPossible(hotbar);

		// trigger a rDW re-render - dirty as fuck
		this.setState(() => ({
			key: moment().unix(),
			breakRegistrationPossible,
			showTable: true,
		}));
	}

	fetchData(state, csv = false) {
		const { hotbar, statusFilter } = this.props;

		if (
			!hotbar?.Period?.results?.startDate ||
			!hotbar?.Period?.results?.endDate
		)
			return;

		this.setState(() => ({
			...(csv ? { downloadingCsv: true } : { loading: true }),
		}));

		const filters = getFilter({ data: hotbar, statusFilter });

		return fetchShiftClockins({ filters, state, csv }).finally(() => {
			this.setState(() => ({
				loading: false,
				downloadingCsv: false,
			}));
		});
	}

	handlePostCloseOpenWorkdays() {
		this.setState(() => ({
			loadingPostCloseOpenWorkdays: true,
		}));

		return postCloseOpenWorkdays()
			.then(() => {
				this.reRenderTable();
			})
			.finally(() => {
				this.setState(() => ({
					loadingPostCloseOpenWorkdays: false,
				}));
			});
	}

	onManagedClockinNoShowCellClick(row) {
		const { setModalType, setManagedShiftClockin } = this.props;

		const payload = {
			id: row.id,
		};

		// set type in redux
		batch(() => {
			setModalType(enums.MODAL_TYPES.MARK_MANAGED_CLOCKIN_AS_NO_SHOW);
			setManagedShiftClockin(payload);
			this.toggleModal();
		});
	}

	onManagedClockinAddBreakClick(row) {
		const { setModalType, setManagedShiftClockin } = this.props;

		// set type in redux
		batch(() => {
			setModalType(enums.MODAL_TYPES.ADD_BREAK);
			setManagedShiftClockin(row);
			this.toggleModal();
		});
	}

	editClockedValue(row, type) {
		const { setModalType, setManagedShiftClockin } = this.props;

		const payload = {
			row,
			clocked: row.clocked,
			type,
		};

		// set type in redux
		batch(() => {
			setModalType(enums.MODAL_TYPES.ADJUST_CLOCKED);
			setManagedShiftClockin(payload);
			this.toggleModal();
		});
	}

	// updates rDW row and cell values for our time pickers
	updateEntry(entry) {
		const { editEntry } = this.props;

		const payload = {
			reduxKey,
			entry,
		};

		batch(() => {
			editEntry(payload);
		});
	}

	postManagedClockinCorrectionWrapper(payload) {
		return postManagedClockinCorrection(payload)
			.then((res) => {
				const entry = res.data?.[0];

				this.updateEntry(entry);
			})
			.catch((err) => {
				throw err;
			});
	}

	_getCanShowTable() {
		const { hotbar } = this.props;

		return (
			hotbar?.Period?.results?.startDate && hotbar?.Period?.results?.endDate
		);
	}

	getTableActionButtons() {
		const {
			downloadingCsv,
			loadingPostCloseOpenWorkdays,
			downloadingDeviationExport,
		} = this.state;

		return (
			<>
				<Tooltip
					zIndex={500}
					text={phrases.CLOSE_OPEN_DAYS_TOOLTIP}
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							className="shift-clockins__header__right-section__button"
							label={phrases.CLOSE_DAYS}
							type="inverted"
							shadow
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
							disabled={loadingPostCloseOpenWorkdays}
							onClick={() => this.handlePostCloseOpenWorkdays()}>
							{loadingPostCloseOpenWorkdays ? (
								<ButtonLoader loading />
							) : (
								<Icon name="lock_outline" />
							)}
						</Button>
					)}
				/>
				<Button
					className="shift-clockins__header__right-section__button"
					label={phrases.CSV_DOWNLOAD}
					type="inverted"
					shadow
					disabled={downloadingCsv}
					onClick={() => this.fetchData({}, true)}>
					{downloadingCsv ? (
						<ButtonLoader loading />
					) : (
						<Icon name="file_download" />
					)}
				</Button>
				<Tooltip
					placement="left"
					text={phrases.DEVIATION_EXPORT_TOOLTIP}
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							dataCy="clockin-deviation-export-button"
							className="shift-clockins__header__right-section__button"
							label={phrases.DEVIATION_EXPORT}
							type="inverted"
							shadow
							disabled={downloadingDeviationExport}
							onClick={this.toggleShiftDeviationExportModal}
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}>
							<Icon name="file_download" />
						</Button>
					)}
				/>
			</>
		);
	}

	toggleShiftDeviationExportModal() {
		this.setState((prevState) => ({
			showDeviationExportModal: !prevState.showDeviationExportModal,
		}));
	}

	setBreaksAsNoShow({ row }) {
		const { editEntry } = this.props;

		const updatedRow = getNoShowBreaksToPropagate({ row });

		editEntry({ entry: updatedRow, reduxKey });
	}

	render() {
		const {
			modalVisible,
			modalType,
			managedShiftClockin,
			statusFilter,
			addBreakModalUpdateBreak,
			breakObject,
			updateManagedShiftClockin,
			hotbar,
		} = this.props;

		return (
			<div className="shift-clockins">
				<div className="shift-clockins__header">
					<Hotdamnbar
						onChange={this.reRenderTable}
						hotbar={AdyenReconsilidationHotbar}
						loading={this.state.loading}
						fireOnChangeOnMount={false}>
						<HotdamnbarLabelProvider
							key="status"
							label={phrases.SELECT_STATUS_LABEL}>
							<CollectionSelect
								id="status"
								styleType={collectionSelectEnums.TYPE_HOTDAMNBAR}
								placeholder={phrases.SELECT_STATUS}
								value={statusFilter}
								handleChange={(key, value) =>
									this.handleStatusFilterChange(value)
								}
								options={getStatusFilterOptions()}
							/>
						</HotdamnbarLabelProvider>
					</Hotdamnbar>
				</div>

				{this.state.showTable ? (
					<ShiftClockinsTable
						loading={this.state.loading}
						fetchData={this.fetchData}
						tableKey={this.state.key}
						onManagedClockinNoShowCellClick={
							this.onManagedClockinNoShowCellClick
						}
						onManagedClockinAddBreakClick={this.onManagedClockinAddBreakClick}
						fetchShiftClockinHourlyStats={fetchShiftClockinHourlyStats}
						reduxKey={reduxKey}
						postManagedClockinCorrection={
							this.postManagedClockinCorrectionWrapper
						}
						breakRegistrationPossible={this.state.breakRegistrationPossible}
						getActionButtons={this.getTableActionButtons}
						setBreaksAsNoShow={this.setBreaksAsNoShow}
						editClockedValue={this.editClockedValue}
					/>
				) : (
					<p className="shift-clockins__no-data">{phrases.NO_DATES_SELECTED}</p>
				)}

				{modalVisible && (
					<ShiftClockinsModal
						modalType={modalType}
						managedShiftClockin={managedShiftClockin}
						handleClose={this.toggleModal}
						postManagedClockinCorrection={
							this.postManagedClockinCorrectionWrapper
						}
						addBreakModalUpdateBreak={addBreakModalUpdateBreak}
						breakObject={breakObject}
						correctShiftClockinBreak={correctShiftClockinBreak}
						updateEntry={this.updateEntry}
						updateManagedShiftClockin={updateManagedShiftClockin}
					/>
				)}

				{this.state.showDeviationExportModal && (
					<ShiftDeviationExportModal
						onClose={this.toggleShiftDeviationExportModal}
						hotbar={hotbar}
					/>
				)}
			</div>
		);
	}
}

ShiftClockins.propTypes = {
	hotbar: PropTypes.object,
	setModalType: PropTypes.func,
	toggleModal: PropTypes.func,
	setManagedShiftClockin: PropTypes.func,
	modalVisible: PropTypes.bool,
	modalType: PropTypes.oneOf([enums.ADJUST_MANAGED_CLOCKIN]),
	managedShiftClockin: PropTypes.object,
	resetManagedShiftClockin: PropTypes.func,
	editEntry: PropTypes.func,
	setStatusFilter: PropTypes.func,
	statusFilter: PropTypes.object,
	addBreakModalUpdateBreak: PropTypes.func,
	addBreakModalResetBreak: PropTypes.func,
	updateManagedShiftClockin: PropTypes.func,
	breakObject: PropTypes.object,
};

const mapStateToProps = (store) => ({
	hotbar: store.hotbar.hotbars.shiftClockins?.results,
	managedShiftClockin: store.shiftClockins.data.managedShiftClockin,
	modalVisible: store.shiftClockins.modalVisible,
	modalType: store.shiftClockins.modalType,
	statusFilter: store.shiftClockins.filters.status,
	breakObject: store.shiftClockins.data.break,
});

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setManagedShiftClockin,
			updateManagedShiftClockin,
			resetManagedShiftClockin,
			setModalType,
			toggleModal,
			editEntry,
			setStatusFilter,
			addBreakModalUpdateBreak,
			addBreakModalResetBreak,
		},
		dispatch
	);
};

export default connectWithStore(
	ShiftClockins,
	mapStateToProps,
	mapDispatchToProps
);
