'use strict';
import React from 'react';
import ImageCell from 'imageCell';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');

export default class TilePictureAssetSizesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'tile_size',
				type: 'select',
				canAdd: true,
				canEdit: true,
				required: true,
				reference: {
					key: 'name',
					list: 'pos/tile_sizes',
				},
			},
			{
				name: 'image',
				type: 'imageCell',
				canSort: false,
				canFilter: false,
				imageCell(row) {
					return <ImageCell id={row.id} image={row.image} endpoint={'/pos/tile_picture_asset_sizes'} />;
				},
			},
		];

		this.settings = {
			name: 'TilePictureAssetSizesList',
			canAdd: true,
			canDelete: true,
			alwaysRefresh: true,
			pageTitle: true,
			translationPath: 'POS.TILE_PICTURE_ASSET_SIZES',
			canManipulate: () => validAccess(undefined, ['Global Sales Configuration']),
			endpoint: __endpoints.collection.pos.tile_picture_asset_sizes,
		};
		this.init();
		return this;
	}
}
