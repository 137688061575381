import React, { useState } from 'react';
import PropTypes from 'prop-types';

// components
import { Modal, ModalRow, Input } from 'dumb';
import { Typography } from '@mui/material';

// services
import { fetchShiftClockinDeviationExport } from './../../shiftClockins.services';
import constants from 'services/constants';

const ShiftDeviationExportModal = ({ hotbar, onClose }) => {
	const [loading, setLoading] = useState(false);
	const [acceptedDeviation, setAcceptedDeviation] = useState(7);

	const onExportClick = () => {
		const marketId = market?.value;

		const valid = getIsValid();

		if (!valid) return;

		setLoading(true);

		let filter = `:workplace.market.id=='${marketId}'`;
		if (hotbar?.Stores?.results?.[0]?.value)
			filter = `:workplace.id=='${hotbar?.Stores?.results?.[0]?.value}'`;

		const payload = {
			filter,
			from: startDate,
			to: endDate,
			accepted_deviation: acceptedDeviation,
		};

		fetchShiftClockinDeviationExport(payload)
			.then(() => {
				setLoading(false);
				onClose();
			})
			.catch(() => setLoading(false));
	};
	const onAcceptedDeviationChange = (e) => {
		setAcceptedDeviation(e.target.value);
	};
	const getIsValid = () => {
		const marketId = market?.value;

		return !!acceptedDeviation && !!marketId;
	};

	const startDate =
		hotbar?.Period?.results?.startDate?.format?.(constants.shortDate) || null;
	const endDate =
		hotbar?.Period?.results?.endDate?.format?.(constants.shortDate) || null;
	const market = hotbar?.Markets?.results?.[0] || null;
	const marketName = market?.label || '';
	const workplaceName = hotbar?.Stores?.results?.[0]?.label || '';

	return (
		<Modal
			open
			header="Export clockin deviations"
			loading={loading}
			onClose={onClose}
			type="confirmation"
			onConfirmClick={onExportClick}
			onCancelClick={onClose}
			confirmButtonLabel="Export"
			cancelButtonLabel="Cancel"
			valid={getIsValid()}
		>
			<ModalRow>
				{market ? (
					<Typography textAlign="center">
						Exporting {marketName}
						{workplaceName ? `/${workplaceName}` : ''} from {startDate} to{' '}
						{endDate}
					</Typography>
				) : (
					<Typography textAlign="center" fontWeight="bold">
						You need to select a market in order to export deviations
					</Typography>
				)}
			</ModalRow>

			{market && (
				<ModalRow>
					<Input
						label="Accepted deviation (mins)"
						placeholder="Enter accepted deviation (in minutes)..."
						value={acceptedDeviation}
						onChange={onAcceptedDeviationChange}
						type="number"
					/>
				</ModalRow>
			)}
		</Modal>
	);
};

ShiftDeviationExportModal.propTypes = {
	hotbar: PropTypes.object,
	onClose: PropTypes.func,
};

export default ShiftDeviationExportModal;
