function getImageMetadata(url) {
	return new Promise((resolve, reject) => {
		const image = new Image();

		image.src = url;

		image.onload = () => {
			const payload = {
				width: image.width,
				height: image.height
			};

			resolve(payload);
		};
	});
}

function getImageNameExtention(name) {
	// get last occurence of .
	const period = name.lastIndexOf('.');

	// get filename
	const fileName = name.substring(0, period);

	// get extention name
	const fileExtention = name.substring(period + 1);

	return { fileName, fileExtention };
}

export default {
	getImageMetadata,
	getImageNameExtention
};
