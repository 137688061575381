'use strict';

import Hotdamnbar from 'hotdamnbar';
import _debounce from 'lodash/debounce';
import _isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Controls from 'reusableComponents/list/controls/controls.component';
import FilterBar from 'reusableComponents/list/filterBar/filterBar.component';
import Default from './components/default.component';
import * as Sentry from '@sentry/browser';
import _get from 'lodash/get';
import './list.css';
import { connectWithStore } from 'appState';
import { settings as defaultListSettings } from './list.defaults';

//
import { Typography } from '@mui/material';

class List extends Component {
	constructor(props) {
		super(props);

		this.state = {
			scrollWrapper: null,
			firstColumnOffset: 0,
		};

		this.handleFirstColumnOffset = this.handleFirstColumnOffset.bind(this);
	}

	/**
	 * @function handleFirstColumnOffset
	 * @memberOf jtjList.component
	 * @description Event handler for setting column offset
	 */
	handleFirstColumnOffset(event) {
		this.setState({ firstColumnOffset: event.srcElement.scrollLeft });
	}

	/**
	 * @function _addEventListeners
	 * @memberOf jtjList.component
	 * @description Add event listeners for this component
	 */
	_addEventListeners() {
		if (this.props.settings.scrollWrapper) {
			// Scroll wrapper if this.props.settings.scrollwrapper
			const scrollWrapper = document.getElementById(this.props.title);
			this.setState({ scrollWrapper: scrollWrapper });

			if (!_isNull(scrollWrapper)) scrollWrapper.addEventListener('scroll', this.handleFirstColumnOffset);
		}
	}

	componentDidCatch(error, info) {
		// Display fallback UI
		this.setState({ hasError: true });

		Sentry.withScope((scope) => {
			scope.setExtra('info', info);
			scope.setExtra('error', error);
			Sentry.captureException(error);
			Sentry.captureMessage('Error in List.component component', 'error');
		});
	}

	/**
	 * @function _removeEventListeners
	 * @memberOf jtjList.component
	 * @description Remove event listeners for this component
	 */
	_removeEventListeners() {
		if (this.props.settings.scrollWrapper && !_isNull(this.state.scrollWrapper))
			this.state.scrollWrapper.removeEventListener('scroll', this.handleFirstColumnOffset);
	}

	/**
	 * @function _renderList
	 * @memberOf jtjList.component
	 * @description Renders list
	 */
	_renderList() {
		if (!this.props.isCollapsed) {
			if (this.props.settings.scrollWrapper) {
				return (
					<div className="list-scroll-wrapper" id={this.props.title}>
						<Default {...this.props} firstColumnOffset={this.state.firstColumnOffset} />
					</div>
				);
			} else return <Default {...this.props} />;
		} else return null;
	}

	/**
	 * @function _renderLength
	 * @memberOf jtjList.component
	 * @description Render length if collapsed
	 */
	_renderLength() {
		if (!this.props.isCollapsed) return null;

		return <b>({this.props.collectionLength})</b>;
	}

	componentDidMount() {
		if (this.props.settings.scrollWrapper) this.handleFirstColumnOffset = _debounce(this.handleFirstColumnOffset, 400);
		this._addEventListeners();
	}

	componentWillUnmount() {
		this._removeEventListeners();
	}

	render() {
		if (this.props.showList) {
			return (
				<div className="list-wrapper">
					{!_isNull(this.props.settings.hotbarConfig) && (
						<Hotdamnbar onChange={this.props.methods.handleHotbarChange} hotbar={this.props.settings.hotbarConfig} />
					)}
					<Controls {...this.props} />
					{this.props.settings.pageTitle && (
						<Typography variant="h1" component="h1" gutterBottom>
							{this.props.title} {this._renderLength()}
						</Typography>
					)}
					{!this.props.settings.pageTitle && (
						<h2>
							{this.props.title} {this._renderLength()}
						</h2>
					)}
					<FilterBar {...this.props} />

					{this._renderList()}
				</div>
			);
		} else return null;
	}
}

List.defaultProps = {
	settings: defaultListSettings,
};

List.propTypes = {
	isCollapsed: PropTypes.bool,
	collectionLength: PropTypes.number,
	showList: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	settings: PropTypes.object.isRequired,
};

const mapStateToPropsFactory = (initialStore, ownProps) => (store) => {
	return {
		accordions: _get(store, `legacyListData.[${ownProps.reduxKey}].accordions`, {}),

		accordionsBase: _get(store, `legacyListData.[${ownProps.reduxKey}].accordionsBase`, []),
		collection: _get(store, `legacyListData.[${ownProps.reduxKey}].collection`, []),
		collectionLength: _get(store, `legacyListData.[${ownProps.reduxKey}].collectionLength`, null),
		data: _get(store, `legacyListData.[${ownProps.reduxKey}].data`, {}),
		error: _get(store, `legacyListData.[${ownProps.reduxKey}].error`, {}),
		delayedRefresh: _get(store, `legacyListData.[${ownProps.reduxKey}].delayedRefresh`, false),
		editRowId: _get(store, `legacyListData.[${ownProps.reduxKey}].editRowId`, null),
		filterOperators: _get(store, `legacyListData.[${ownProps.reduxKey}].filterOperators`, {}),
		filters: _get(store, `legacyListData.[${ownProps.reduxKey}].filters`, {}),
		headers: _get(store, `legacyListData.[${ownProps.reduxKey}].headers`, []),
		hotbar: _get(store, `legacyListData.[${ownProps.reduxKey}].hotbar`, {}),
		isAdding: _get(store, `legacyListData.[${ownProps.reduxKey}].isAdding`, false),
		isBarFiltering: _get(store, `legacyListData.[${ownProps.reduxKey}].isBarFiltering`, false),
		isCollapsed: _get(store, `legacyListData.[${ownProps.reduxKey}].isCollapsed`, false),
		isColumnSelecting: _get(store, `legacyListData.[${ownProps.reduxKey}].isColumnSelecting`, false),
		isCreating: _get(store, `legacyListData.[${ownProps.reduxKey}].isCreating`, false),
		isEditing: _get(store, `legacyListData.[${ownProps.reduxKey}].isEditing`, false),
		isExporting: _get(store, `legacyListData.[${ownProps.reduxKey}].isExporting`, false),
		isSearchFiltering: _get(store, `legacyListData.[${ownProps.reduxKey}].isSearchFiltering`, false),
		isSearchList: _get(store, `legacyListData.[${ownProps.reduxKey}].isSearchList`, false),
		model: _get(store, `legacyListData.[${ownProps.reduxKey}].model`, {}),
		params: _get(store, `legacyListData.[${ownProps.reduxKey}].params`, {}),

		selectedRowId: _get(store, `legacyListData.[${ownProps.reduxKey}].selectedRowId`, null),
		rowActions: _get(store, `legacyListData.[${ownProps.reduxKey}].rowActions`, null),
		showList: _get(store, `legacyListData.[${ownProps.reduxKey}].showList`, true),
		sort: _get(store, `legacyListData.[${ownProps.reduxKey}].sort`, {}),
		title: _get(store, `legacyListData.[${ownProps.reduxKey}].title`, null),
		toggleShowControls: _get(store, `legacyListData.[${ownProps.reduxKey}].toggleShowControls`, false),
		togglePrintView: _get(store, `legacyListData.[${ownProps.reduxKey}].togglePrintView`, false),
	};
};

export default connectWithStore(List, mapStateToPropsFactory);
