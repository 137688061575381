'use strict';

/**
 * @function initApiErrorHeaders
 * @memberOf SERVICES.__models
 * @description
 * Loops through potential errors from API and puts them into the header where they belong.
 * @param  {array} headers  headers to loop through
 * @param  {array} errors  errors from api
 */

import _each from 'lodash/each';
import _find from 'lodash/find';
import _isUndefined from 'lodash/isUndefined';

module.exports = function(headers, errors) {
	// Reset / Set each header field = null
	_each(headers, function(header) {
		header.fieldError = null;
	});

	// Set errors where they are
	_each(errors, function(error) {
		if (!_isUndefined(_find(headers, {'name': error.field}))) {
			_find(headers, {'name': error.field}).fieldError = error.message;
		}
	});
	this.setHeaders(headers);
};