export default {
	TABLE_TITLE: 'Pos configuration ingredient pricing groups',

	MODAL_BATCH_STEP_FORM_TITLE: 'Batch add',
	MODAL_BATCH_STEP_POS_CONFIGURATION_VERSION: 'POS configuration version',
	MODAL_BATCH_STEP_INGREDIENT_PRICING_GROUP: 'Ingredient pricing group',
	MODAL_BATCH_STEP_TO_STAY_PRICE: 'To stay price',
	MODAL_BATCH_STEP_TO_GO_PRICE: 'To go price',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'Next',

	MODAL_BATCH_STEP_LIST_TITLE: 'Confirm entries',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Confirm',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'Back',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',

	MODAL_BATCH_STEP_FORM_POS_CONFIGURATION_VERSIONS_TABLE_TITLE:
		'POS configuration version',
	MODAL_BATCH_STEP_FORM_INGREDIENT_PRICING_GROUPS_TABLE_TITLE:
		'Ingredient pricing groups',
	MODAL_BATCH_STEP_FORM_POS_CONFIGURATION_VERSIONS_TABLE_ACTIVE_FILTER:
		'Active',

	INGREDIENT_PRICING_GROUPS_ADDED: 'Ingredient pricing groups added',
	INGREDIENT_PRICING_GROUP_EDITED: 'Ingredient pricing group edited',
	INGREDIENT_PRICING_GROUPS_EDITED: 'Ingredient pricing groups edited',
	INGREDIENT_PRICING_GROUP_DELETED: 'Ingredient pricing groups deleted',
	MODAL_BATCH_STEP_LIST_LEGEND_CANT_ADD:
		'Pos configuration ingredient pricing group exists',
	MODAL_BATCH_STEP_LIST_LEGEND_CAN_ADD:
		'Pos configuration ingredient pricing group doesnt exist'
};
