export default {
	name: 'jtj-workplanner',
	version: '1.0.0',
	env: process.env.NODE_ENV,
	redirectBetaUsers: process.env.REDIRECT_BETA_USERS,
	newsUrl: process.env.NEWS_URL,
	api: {
		protocol: 'https',
		host: process.env.wpApiHost,
		path: process.env.wpApiPath,
		version: process.env.wpApiVersion,
	},
	auth0: {
		clientId: process.env.auth0ClientId,
		domain: process.env.auth0Domain,
		connection: process.env.auth0Connection,
	},
	usersnap: {
		key: process.env.usersnap,
	},
	featureFlags: {
		// same id in prod and dev for holland and UK market
		marketsWithShiftTransferEnabled: [1, 29, 6],
		enableDocuSign: true,
	},
};
