'use strict';

import constants from 'services/constants';
import { validAccess } from 'accessControl';

/**
 * @function filterTips
 * @param {Object} object - object with tips and user role
 * @param {Array} object.tips - array of tip objects sorted by access rights (juicer, barManager...)
 * @param {String} object.userRole - user role of a logged in user
 * @description takes an array of tips and returns a filtered array with tips visible based on current user role
 */
export function filterTips({ tips }) {
	// get userRole access rights
	let userRoleAccessRight = 1;

	if (validAccess(undefined, ['Shiftplan Manager'])) userRoleAccessRight = 2;
	if (validAccess(undefined, ['Shiftplan Manager', 'Shiftplan Admin']))
		userRoleAccessRight = 3;

	return tips.reduce((acc, currentTip) => {
		// get tip access rights
		let tipAccessRights = 1;
		if ([constants.barManager].includes(currentTip.accessLevel))
			tipAccessRights = 2;
		if (
			[
				constants.hrManager,
				constants.regionalManager,
				constants.admin,
			].includes(currentTip.accessLevel)
		)
			tipAccessRights = 3;

		return [
			...acc,
			// compare with user access rights
			...(userRoleAccessRight >= tipAccessRights ? currentTip.tips : []),
		];
	}, []);
}

/**
 * @function getRandomNumber
 * @param {Object} object
 * @param {Array} array - array
 * @description returns a random number where min is 0 and max is length of array - 1
 */
export function getRandomNumber({ arrayOfTips }) {
	return Math.floor(Math.random() * arrayOfTips.length);
}
