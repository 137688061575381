'use strict';

/**
 * Title
 * @param  {*} template
 */
class Title {
	constructor(template, metaTemplate = null) {
		// Set type
		this.type = 'title';

		/// ///////////
		// Template //
		/// ///////////

		// Check template

		// Define templateType
		this.templateType = typeof template;

		// Define template
		this.template = template;

		this.metaTemplate = metaTemplate;
	}

	/**
	 * @function getTemplate
	 * @memberOf Title
	 * @description
	 * Returns unformatted content
	 * @param  {object} data
	 */
	getTemplate(data) {
		switch (this.templateType) {
		case 'function':
			return this.template(data);
		case 'string':
			return this.template;
		}
	}

	/**
	 * @function getMetaTemplate
	 * @memberOf Title
	 * @description
	 * Returns unformatted content
	 * @param  {object} data
	 */
	getMetaTemplate(data) {
		switch (typeof this.metaTemplate) {
		case 'function':
			return this.metaTemplate(data);
		case 'string':
			return this.metaTemplate;
		default:
			return this.metaTemplate;
		}
	}
}

export default Title;
