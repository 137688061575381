export default {
	TITLE: 'Product bundle translations',
	BATCH_ADD: 'Click to add',

	MODAL_TITLE: 'Add product bundle translations',
	NAME: 'Name',
	NAME_PLACEHOLDER: 'Enter name...',
	LANGUAGE: 'Language',
	LANGUAGE_PLACEHOLDER: 'Select language...',
	SUBMIT: 'Submit',
	ADD_ROW_TOOLTIP: 'Add row',
};
