'use strict';

import React from 'react';
import PropTypes from 'prop-types';

// utils
import phrases from './../employeeMeals.phrases';
import _get from 'lodash/get';

import EmployeeMealsPrWorkplacesReport from './employeeMealsPrWorkplace.component';
import { Body, Head, HeaderCell, Row, Table, Title, Report } from 'dumb/report';

function EmployeeMealsPrWorkplacesReportWrapper({
	totalColSpan,
	showProductsPrShift,
	employeeMealsReportPrWorkplace,
	employeeMealsReportPrPerson,
	fetchEmployeeMealsPrEmployee,
	marketId,
	period
}) {
	const workplaceName = _get(
		employeeMealsReportPrWorkplace[0],
		'workplace.name',
		null
	);

	const reportTitle = `Employee meals for ${workplaceName}`;

	return (
		<Report>
			<Title title={reportTitle} />
			<Title title={period} rightAligned />
			<Table fixed>
				<Head>
					<Row>
						<HeaderCell
							colSpan="3"
							content={phrases.TABLE_HEADER_EMPLOYEE}
							alignLeft
						/>
						<HeaderCell
							separate
							colSpan="2"
							content={phrases.TABLE_HEADER_GROSS_TURNOVER}
						/>
						<HeaderCell
							separate
							colSpan="2"
							content={phrases.TABLE_HEADER_HOURS}
						/>
						<HeaderCell
							separate
							colSpan="2"
							content={phrases.TABLE_HEADER_PRODUCTS}
						/>
						<HeaderCell
							separate
							colSpan="2"
							content={phrases.TABLE_HEADER_PRODUCTS_PER_SHIFTS}
						/>
						<HeaderCell
							separate
							colSpan="2"
							content={phrases.TABLE_HEADER_SHIFTS}
						/>
					</Row>
				</Head>
				<Body>
					<EmployeeMealsPrWorkplacesReport
						totalColSpan={totalColSpan}
						showProductsPrShift={showProductsPrShift}
						employeeMealsReportPrPerson={employeeMealsReportPrPerson}
						fetchEmployeeMealsPrEmployee={fetchEmployeeMealsPrEmployee}
						reportData={employeeMealsReportPrWorkplace}
						marketId={marketId}
					/>
				</Body>
			</Table>
		</Report>
	);
}

EmployeeMealsPrWorkplacesReportWrapper.propTypes = {
	totalColSpan: PropTypes.number,
	showProductsPrShift: PropTypes.bool,
	employeeMealsReportPrWorkplace: PropTypes.array,
	employeeMealsReportPrPerson: PropTypes.array,
	fetchEmployeeMealsPrEmployee: PropTypes.func,
	marketId: PropTypes.number,
	period: PropTypes.string
};

export default EmployeeMealsPrWorkplacesReportWrapper;
