import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { resetState, rerenderTable } from './../../store/globalIngredientComponentTranslations.actions';

// components
import { ModalStepper } from 'dumb';
import GlobalIngredientComponentTranslateModalStep from './components/globalIngredientComponentTranslationsModalStep';

// services
import { addGlobalIngredientComponentTranslations } from './../../globalIngredientComponentTranslations.service';

// phrases
import phrases from './../../globalIngredientComponentTranslations.phrases';

const GlobalIngredientComponentTranslationsModalBatch = ({ allergenId, onClose }) => {
	const modalRef = useRef();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const batchList = useSelector((store) => store.globalIngredientComponentTranslations.batchList);

	const onNext = () => {
		setLoading(true);

		const payload = batchList.map((entry) => ({
			global_ingredient_component: allergenId,
			name: entry.name,
			language: entry.language?.value?.id ?? null,
		}));

		addGlobalIngredientComponentTranslations({ batch: payload })
			.then(() => {
				setLoading(false);
				dispatch(rerenderTable());
				onClose();
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const steps = [
		{
			component: <GlobalIngredientComponentTranslateModalStep />,
			title: phrases.MODAL_TITLE,
			onBack: onClose,
			onNext,
			loading,
			isValid: true,
			confirmButtonLabel: phrases.SUBMIT,
			defaultStyles: false,
			noScroll: false,
		},
	];

	useEffect(() => {
		return () => {
			dispatch(resetState());
		};
	}, [dispatch]);

	return (
		<ModalStepper header={phrases.MODAL_TITLE} onClose={onClose} ref={modalRef} isOpen steps={steps} showStep={false} />
	);
};

GlobalIngredientComponentTranslationsModalBatch.propTypes = {
	onClose: PropTypes.func,
	allergenId: PropTypes.number,
};

export default GlobalIngredientComponentTranslationsModalBatch;
