export const RESET_PRODUCT_VARIANT =
	'SALES_CONFIGURATION/RESET_PRODUCT_VARIANT';
export const SET_PRODUCT_VARIANT = 'SALES_CONFIGURATION/SET_PRODUCT_VARIANT';
export const UPDATE_PRODUCT_VARIANT =
	'SALES_CONFIGURATION/UPDATE_PRODUCT_VARIANT';

/**
 * @param {object} payload
 */
export function setProductVariant(payload) {
	return {
		type: SET_PRODUCT_VARIANT,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateProductVariant(payload) {
	return {
		type: UPDATE_PRODUCT_VARIANT,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetProductVariant() {
	return {
		type: RESET_PRODUCT_VARIANT
	};
}
