import moment from 'moment';
import _get from 'lodash/get';

import {
	PERIOD_SET,
	OPENING_HOURS_FETCHED,
	FETCH_TOGGLED,
	NUMBER_OF_WEEKS,
	TOGGLE_EDIT_MODE,
	INLINE_HOURS_EDITED
} from './openingHours.actions';

const defaultState = {
	selectedPeriod: {
		from: moment().startOf('isoweek'),
		to: moment()
			.add(4 - 1, 'weeks')
			.endOf('isoWeek')
	},
	numberOfWeeks: 4,
	openingHours: {},
	fetching: false,
	editMode: false,
	editedDate: {}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
	case TOGGLE_EDIT_MODE: {
		return Object.assign({}, state, {
			...state,
			editMode: payload.mode !== null ? payload.mode : !state.editMode
		});
	}

	case PERIOD_SET: {
		return Object.assign({}, state, {
			...state,
			selectedPeriod: {
				from: payload.selectedWeek,
				to: payload.endOfPeriod
			}
		});
	}

	case OPENING_HOURS_FETCHED: {
		const weeks = {};
		const { openingHours } = payload;

		for (let i = 0; i < openingHours.length; i++) {
			const weekNumber = moment(openingHours[i].date, 'YYYY-MM-DD').isoWeek();

			if (!isNaN(weekNumber)) {
				if (typeof weeks[weekNumber] === 'undefined') {
					weeks[weekNumber] = [];
				}

				weeks[weekNumber].push(openingHours[i]);
			}
		}

		const weeklyOpeningHours = { openingHours: weeks };
		return Object.assign({}, state, weeklyOpeningHours);
	}
	case FETCH_TOGGLED: {
		const fetching = { fetching: payload.fetching };
		return Object.assign({}, state, fetching);
	}
	case NUMBER_OF_WEEKS: {
		const numberOfWeeks = { numberOfWeeks: payload.numberOfWeeks };
		return Object.assign({}, state, numberOfWeeks);
	}

	case INLINE_HOURS_EDITED: 
		return Object.assign({}, state, {
			...state,
			editedDate: {
				date: payload.day,
				time: {
					...state.editedDate.time,
					...payload.time
				}
			}
		});

	default:
		return state;
	}
}

export default reducer;
