import React, { useState } from 'react';
import PropTypes from 'prop-types';

// redux
import { useDispatch, useSelector } from 'react-redux';
import {
	setPosConfigurationTileLayoutGroupTranslation,
	updatePosConfigurationTileLayoutGroupTranslation,
	resetState,
} from './store/posConfigurationTileLayoutGroupTranslations.actions';

// components
import SubTable from 'reactDataWrapper/components/subTable';
import { ReactDataWrapper } from 'reactDataWrapper';
import PosConfigurationTileLayoutGroupTranslationsModalBatch from './components/batch/posConfigurationTileLayoutGroupTranslationsModalBatch';
import { Tooltip, Icon, Button, Input } from 'dumb';

// services
import {
	fetchAllTileLayoutGroupTranslations,
	fetchAllTileLayoutGroups,
	deleteTileLayoutGroupTranslation,
	editTileLayoutGroupTranslation,
} from './posConfigurationTileLayoutGroupTranslations.service';

// utils
import { getEditedValues } from 'services/utils';

// phrases
import phrases from './posConfigurationTileLayoutGroupTranslations.phrases';

const posConfigurationTileLayoutGroupTranslationColumns = [
	{
		Header: 'Name',
		id: 'name',
		accessor: 'name',
		filterPath: ':name',
	},
	{
		Header: 'Language',
		id: 'language',
		accessor: 'language.name',
		filterPath: ':language.name',
	},
];

const posConfigurationTileLayoutGroupColumns = [
	{
		Header: 'Name',
		id: 'name',
		accessor: 'name',
		filterPath: ':name',
	},
];

const PosConfigurationTileLayoutGroupTranslations = ({ id }) => {
	const dispatch = useDispatch();
	const { tableKey, posConfigurationTileLayoutGroupTranslation, originalPosConfigurationTileLayoutGroupTranslation } =
		useSelector((store) => store.posConfigurationTileLayoutGroupTranslations);

	const [showBatchModal, setShowBatchModal] = useState(false);

	// crud
	const editEntry = () => {
		const valuesToEdit = getEditedValues({
			newData: posConfigurationTileLayoutGroupTranslation,
			oldData: originalPosConfigurationTileLayoutGroupTranslation,
		});

		const payload = {
			id: posConfigurationTileLayoutGroupTranslation.id,
			name: valuesToEdit.name,
		};

		return editTileLayoutGroupTranslation(payload);
	};
	const deleteEntry = (id) => deleteTileLayoutGroupTranslation(id);

	// crud prep
	const setInitialEditValues = (data) => {
		const body = {
			id: data.id,
			name: data.name,
			language: data.language.name,
		};

		dispatch(setPosConfigurationTileLayoutGroupTranslation(body));
	};
	const getEditableCells = () => [
		{
			header: 'Name',
			value: (
				<Input
					id="name"
					placeholder={phrases.NAME}
					value={posConfigurationTileLayoutGroupTranslation.name}
					onChange={(event) => dispatch(updatePosConfigurationTileLayoutGroupTranslation({ name: event.target.value }))}
				/>
			),
		},
		{
			header: 'Language',
			value: <span>{posConfigurationTileLayoutGroupTranslation.language}</span>,
		},
	];

	const toggleBatchModal = () => setShowBatchModal(!showBatchModal);
	const onRdwModalClose = () => dispatch(resetState());

	return (
		<>
			<ReactDataWrapper
				accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.TITLE_GROUPS}
				columns={posConfigurationTileLayoutGroupColumns}
				fetchData={(state) => fetchAllTileLayoutGroups(id, state)}
				filterable
				defaultPageSize={10}
				reduxKey={`${id}-/pos/pos_configuration_tile_layout_groups`}
				manual
				subcomponent={(row) => (
					<SubTable>
						<>
							<ReactDataWrapper
								key={tableKey}
								accessAreasAllowedToEdit={['Sales Configuration']}
								title={phrases.TITLE_GROUP_TRANSLATIONS}
								columns={posConfigurationTileLayoutGroupTranslationColumns}
								fetchData={(state) => fetchAllTileLayoutGroupTranslations(row.original.id, state)}
								filterable
								defaultPageSize={10}
								reduxKey={`${row.original.id}-pos/pos_configuration_tile_layout_group_translations`}
								manual
								editableCells={getEditableCells()}
								setInitialEditValues={setInitialEditValues}
								onModalClose={onRdwModalClose}
								deleteEntry={deleteEntry}
								editEntry={editEntry}
								actionRender={
									<Tooltip
										text={phrases.BATCH_ADD}
										renderData={(ref, onMouseEnter, onMouseLeave) => (
											<Button
												label="Add"
												shadow
												type="inverted"
												onClick={toggleBatchModal}
												refProp={ref}
												onMouseEnter={onMouseEnter}
												onMouseLeave={onMouseLeave}
											>
												<Icon name="add" />
											</Button>
										)}
									/>
								}
							/>
							{showBatchModal && (
								<PosConfigurationTileLayoutGroupTranslationsModalBatch
									onClose={toggleBatchModal}
									id={row.original.id}
								/>
							)}
						</>
					</SubTable>
				)}
			/>
		</>
	);
};

PosConfigurationTileLayoutGroupTranslations.propTypes = {
	id: PropTypes.number,
};

export default PosConfigurationTileLayoutGroupTranslations;
