'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';
const __endpoints = require('endpoints.service');

export default class GridsList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
			},
		];
		this.settings = {
			name: 'GridsList',
			canCreate: true,
			hasDetails: true,
			pageTitle: true,
			canManipulate: () => validAccess(undefined, ['Global BI Configuration']),
			translationPath: 'HR.GRIDS',
			endpoint: __endpoints.collection.hr.grids,
		};
		this.init();
		return this;
	}
}
