'use strict';

import PropTypes from 'prop-types';
import cx from 'classnames';
import React from 'react';

import RowEntry from './row.entry.component';
import RowSubtable from './row.subtable.component';

const BodySummed = props => {
	const { component, tables, table } = props;

	/**
	 * Parses body from table content and layout object
	 */
	const parseBody = table.content.map((row, key) =>
		row && row.meta
			? [
				<RowSubtable
					data={row}
					key={`${row.meta.data.unique_identifier}_${key}_Table`}
					{...props}
				/>,
				<RowEntry
					key={`${row.meta.data.unique_identifier}_${key}_Entry`}
					activeInteractionClassName={{ 'report-old__entry--summed': true }}
					tables={tables}
					component={component}
					context="body"
					data={row.total.data}
					meta={row}
					modifierClassNames={cx({ 'report-old__entry--odd': key % 2 === 0 })}
				/>
			  ]
			: null
	);

	return <tbody>{parseBody}</tbody>;
};

BodySummed.propTypes = {
	table: PropTypes.object.isRequired,
	tables: PropTypes.object.isRequired
};

export default BodySummed;
