import moment from 'moment';

/**
 * @function groupToShifts
 * @memberOf SERVICES.ShiftPlannerEmployees
 * @description Groups times to shifts
 * @param {object} groupedData, all forecasting grouped in days
 * @param {object} timeTable, constant timeTable where hour relates to top position
 */
export function groupToShifts(groupedData, timeTable) {
	let ghostData = {};

	Object.keys(groupedData).forEach(day => {
		const dayData = groupedData[day];

		// Group by column vs employee
		const groupedByOptimal = _groupByOptimal(dayData);

		let ghostShifts = [];
		Object.keys(groupedByOptimal).forEach(column => {
			const shifts = groupedByOptimal[column].reduce((array, x) => {
				// If something in array exist
				if (array.length) {
					// Check if its continuation and extend shift
					if (array[array.length - 1].to === x.from) {
						// Get last entry
						const prevCont = array[array.length - 1];

						prevCont.to = x.to;
						prevCont.top = timeTable.filter(
							t => t.time === prevCont.from
						)[0].top;
						prevCont.height =
							moment.utc(x.to, 'HH:mm:ss').diff(
								moment.utc(prevCont.from, 'HH:mm:ss'),
								'hours',
								true
							) *
							2 *
							12.5;
						return array;

						// Add new shift
					} else {
						return [
							...array,
							{
								from: x.from,
								to: x.to,
								top: timeTable.filter(t => t.time === x.from)[0].top,
								height: 12.5,
								column
							}
						];
					}

					// If column array does not exist, its first item
				} else {
					// push very first entry & set last max entry
					return (array = [
						{
							from: x.from,
							to: x.to,
							top: timeTable.filter(t => t.time === x.from)[0].top,
							height: 12.5,
							column
						}
					]);
				}
			}, []);

			ghostShifts = [...ghostShifts, ...shifts];
		});

		// Combine each day shifts
		ghostData = {
			...ghostData,
			[day]: ghostData[day] ? [...ghostData[day], ghostShifts] : ghostShifts
		};
	});

	return ghostData;
}

// /**
//  * @function groupToShiftsByShifts
//  * @memberOf SERVICES.ShiftPlannerEmployees
//  * @description Groups times to shifts, but in relation tu current shifts
//  * @param {object} groupedData, all forecasting grouped in days
//  * @param {object} timeTable, constant timeTable where hour relates to top position
//  * @param {object} shifts, shifts array
//  */
// export function groupToShiftsByShifts(groupedData, timeTable, shifts) {
// 	let ghostData = {};

// 	console.log(shifts, groupedData);

// 	Object.keys(shifts).reduce((viewPeriodArray, day) => {

// 		if(groupedData[day] && shifts[day]) {

// 			const ghostShifts = groupedData[day].reduce((dayArry, x, index) => {

// 				const constructedObject = {
// 					from: x.from,
// 					to: x.to,
// 					column: x.column
// 				};

// 				// IF shift end sooner than forecasting shift
// 				// Cut/end the forecasting shift and push it to the forecasting pool
// 				if(shifts[day][index]) {
// 					const shiftTo = moment.utc(shifts[day][index].planned_period.to).format('HH:mm:ss');

// 					// console.log('Shift / Forecast', shiftTo, x.to);
// 					// console.log('Does shift end before forecast shift?',  moment(shiftTo, 'HH:mm:ss').isBefore(moment(x.to, 'HH:mm:ss'), 'hours'));

// 					if(moment(shiftTo, 'HH:mm:ss').isBefore(moment(x.to, 'HH:mm:ss'), 'hours')) {
// 						constructedObject.to = shiftTo;

// 						const createdNewForecastLeftover = {
// 							from: shiftTo,
// 							to: x.to
// 						};
// 					}
// 				}

// 				return [...dayArry, ...constructedObject];
// 			}, []);

// 			console.log('new shifts', ghostShifts);
// 		}

// 		viewPeriodArray = {...viewPeriodArray, ...shifts};
// 	}, {});
// 	return ghostData;
// }

/**
 * @function createTimeHourTable
 * @memberOf utils forecastting shiftplanner
 * @description Groups times to shifts
 * @param {object} hour divide, height of time unit in shiftplanner
 */
export function createTimeHourTable(hourDivide = 1, height = 25) {
	const hours = 24;
	const setHeigt = height / hourDivide;

	let dayHours = [];
	for (let i = 0; i < hours * hourDivide; i++) {
		dayHours[i] = i;
	}

	// Reduce to { time: 00:00, top: 0 };
	return dayHours.reduce((tally, current) => {
		tally.push({
			time: tally[current - 1]
				? moment(tally[current - 1].time, 'HH:mm:ss')
					.add(30, 'minutes')
					.format('HH:mm:ss')
				: moment()
					.startOf('day')
					.format('HH:mm:ss'),
			top: tally[current - 1] ? tally[current - 1].top + setHeigt : 0
		});
		return tally;
	}, []);
}

/**
 * @function _groupByOptimal
 * @memberOf utils forecastting shiftplanner
 * @description Takes that specific days data and puts them into column number key shifts
 * @param {object} dayData that days
 */
function _groupByOptimal(dayData) {
	return dayData.reduce((array, x) => {
		const constructedObject = {
			from: x.from,
			to: x.to,
			employees: x.employees
			// actual: x.actual
		};

		let newArray = array;
		for (let i = 0; i < x.employees; i++) {
			newArray = {
				...newArray,
				[i + 1]: newArray[i + 1]
					? [...newArray[i + 1], constructedObject]
					: [constructedObject]
			};
		}

		return { ...array, ...newArray };
	}, {});
}


export function isWithinPeriod(timeFrom, timeTo, periodObj) {
	return (
		timeFrom.isSameOrAfter(moment.utc(periodObj.from), 'minute') &&
			timeTo.isSameOrBefore(moment.utc(periodObj.to), 'minute')
	);
}