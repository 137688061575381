
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ListComponent from 'common/components/list/list.component';
import DetailsComponent from 'common/components/details/details.component';

import GridsDetails from './services/gridsDetails.service';
import GridEntriesList from './services/gridEntriesList.service';

const gridsDetailsReduxKey = '/hr/grids-details';
const gridsDetails = new GridsDetails(gridsDetailsReduxKey);
const gridsDetailsProps = gridsDetails.getProps();

const gridEntriesListReduxKey = '/hr/grid_entries';
const gridEntriesList = new GridEntriesList(gridEntriesListReduxKey);
const gridEntriesListProps = gridEntriesList.getProps();

export function GridsDetailsContainer() {
	const { gridId } = useParams();

	const gridIdFilter = `:grid.id=='${gridId}'`;
	gridsDetails.setItemId(gridId);

	useEffect(() => {
		// Fetch selected employment position
		gridsDetails.initView();

		gridEntriesList.setFilterQuery(gridIdFilter);
		gridEntriesList.setData({
			grid: gridId,
		});


	}, [gridId, gridIdFilter]);

	return (
		<>
			<DetailsComponent reduxKey={gridsDetailsReduxKey} {...gridsDetailsProps} />

			<ListComponent reduxKey={gridEntriesListReduxKey} {...gridEntriesListProps} />
		</>
	);
}
