'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';

// components
import { InputCollectionSelect, Button, Input, Icon, Toggle } from 'dumb';
import moment from 'moment';

// modal batch
import PosConfigurationsModalBatch from './components/batch/posConfigurationsModalBatch';

import _get from 'lodash/get';

import {
	resetState,
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	setPosConfiguration,
	updatePosConfiguration,
} from './store/posConfigurations.actions';

import {
	fetchPosConfigurations,
	fetchMarkets,
	addPosConfiguration,
	editPosConfiguration,
	deletePosConfiguration,
	editBatchPosConfiguration,
} from './posConfigurations.service';

import phrases from './posConfigurations.phrases';
import constants from 'services/constants';

const reduxKey = '/pos/pos_configurations';

class PosConfigurations extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this.editEntry = this.editEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', null),
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: (d) => _get(d, 'description', null),
				filterPath: ':description',
			},
			{
				Header: 'Market',
				id: 'market',
				accessor: (d) => _get(d, 'market.name', null),
				filterPath: ':market.name',
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: (d) => _get(d, 'legacy', null),
				filterPath: ':legacy',
				filterable: false,
				Cell: (d) => {
					return (
						<Input
							type="checkbox"
							checked={_get(d, 'original.legacy', false)}
							disabled
						/>
					);
				},
			},
		];
	}

	editEntry() {
		const { posConfiguration } = this.props;

		const payload = {
			id: posConfiguration.id,
			name: posConfiguration.name,
			description: posConfiguration.description,
			legacy: posConfiguration.legacy,
		};

		return editPosConfiguration(payload);
	}

	deleteEntry(id) {
		return deletePosConfiguration(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			market: _get(data, 'market.name', ''),
			legacy: data.legacy,
		};

		this.props.setPosConfiguration(payload);
	}

	getEditableCells() {
		const { posConfiguration } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Name"
						value={posConfiguration.name || ''}
						onChange={(e) => this.editStoreEntry(e.target.value, 'name')}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Description"
						value={posConfiguration.description || ''}
						onChange={(e) => this.editStoreEntry(e.target.value, 'description')}
					/>
				),
			},
			{
				header: 'Market',
				value: (
					<InputCollectionSelect
						id="market"
						value={posConfiguration.market}
						handleChange={(key, value) => this.editStoreEntry(value, 'market')}
						clearable={false}
						cache
						placeholder="Select market"
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						apiPath="/administration/markets"
						params={{
							limit: 30,
							filter: `:closed=ge='${moment
								.utc()
								.format(constants.shortDate)}'`,
						}}
					/>
				),
			},
		];
	}

	getEditableCellsEdit() {
		const { posConfiguration } = this.props;

		const editMode = !!posConfiguration.id;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Name"
						value={posConfiguration.name || ''}
						onChange={(e) => this.editStoreEntry(e.target.value, 'name')}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Description"
						value={posConfiguration.description || ''}
						onChange={(e) => this.editStoreEntry(e.target.value, 'description')}
					/>
				),
			},
			...(editMode
				? [
						{
							header: 'Market',
							value: <span>{posConfiguration.market}</span>,
						},
				  ]
				: []),
			{
				header: 'Legacy',
				value: (
					<Toggle
						id="legacy"
						toggled={posConfiguration.legacy}
						onClick={(e) => this.editStoreEntry(e, 'legacy')}
					/>
				),
			},
		];
	}

	editStoreEntry(e, name) {
		const payload = {
			[name]: e,
		};

		this.props.updatePosConfiguration(payload);
	}

	editMultiple(selectedRows) {
		const { posConfiguration } = this.props;

		const payload = {
			...(posConfiguration.name && {
				name: posConfiguration.name,
			}),
			...(posConfiguration.description && {
				description: posConfiguration.description,
			}),
			legacy: posConfiguration.legacy ?? false,
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editBatchPosConfiguration({
			batch: selectedRowsWithId,
		});
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}>
				Legacy
			</Button>
		);
	}

	getActionButtons() {
		return (
			<Button
				type="inverted"
				label="Add"
				shadow
				onClick={() => this.toggleModal()}>
				<Icon name="add" />
			</Button>
		);
	}

	render() {
		const {
			updateBatchForm,
			batchFormData,
			listMarkets,
			listPosConfigurationVersions,
			addBatchList,
			batchList,
			editBatchListItem,
			removeBatchListItem,
			resetState,
			// batch props
			onInitialization,
			reduxKey: batchReduxKey,
			style,
			defaultPageSize,
			batchSelection,
			enableSingleSelection,
		} = this.props;

		const legacyFilter = this.state.legacyFilterOn
			? this.state.legacyFilter
			: '';

		return (
			<>
				<ReactDataWrapper
					accessAreasAllowedToEdit={['Sales Configuration']}
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={fetchPosConfigurations}
					defaultPageSize={batchSelection ? defaultPageSize : 50}
					reduxKey={batchSelection ? batchReduxKey : reduxKey}
					style={batchSelection ? style : {}}
					{...(batchSelection && { onInitialization })}
					batchSelection={batchSelection}
					enableSingleSelection={enableSingleSelection}
					filterable
					manual
					onModalClose={resetState}
					editableCells={this.getEditableCells()}
					editableCellsEdit={this.getEditableCellsEdit()}
					setInitialEditValues={this.setInitialEditValues}
					customAreaComponents={this.getLegacyFilterButton()}
					editEntry={this.editEntry}
					deleteEntry={this.deleteEntry}
					deleteConfirm
					editMultiple={this.editMultiple}
					extraFilters={legacyFilter}
					actionRender={this.getActionButtons()}
				/>

				<PosConfigurationsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchFormData={batchFormData}
					updateBatchForm={updateBatchForm}
					listMarkets={listMarkets}
					listPosConfigurationVersions={listPosConfigurationVersions}
					fetchMarkets={fetchMarkets}
					fetchPosConfigurations={fetchPosConfigurations}
					addBatchList={addBatchList}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addPosConfiguration={addPosConfiguration}
				/>
			</>
		);
	}
}

PosConfigurations.propTypes = {
	posConfiguration: PropTypes.object,
	listMarkets: PropTypes.object,
	listPosConfigurationVersions: PropTypes.object,
	setPosConfiguration: PropTypes.func,
	resetState: PropTypes.func,
	updateBatchForm: PropTypes.func,
	addBatchList: PropTypes.func,
	batchFormData: PropTypes.object,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	updatePosConfiguration: PropTypes.func,
	onInitialization: PropTypes.func,
	reduxKey: PropTypes.string,
	style: PropTypes.object,
	defaultPageSize: PropTypes.number,
	batchSelection: PropTypes.bool,
	enableSingleSelection: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			resetState,
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			setPosConfiguration,
			updatePosConfiguration,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		batchFormData: store.salesConfigurationPosConfigurations.data.batchFormData,
		batchList: store.salesConfigurationPosConfigurations.data.batchList,
		listMarkets: store.listData['posConfigurationBatch/organization/markets'],
		listPosConfigurationVersions:
			store.listData['posConfigurationBatch/pos/pos_configuration_versions'],
		listPosConfigurations: store.listData[reduxKey],
		posConfiguration:
			store.salesConfigurationPosConfigurations.data.posConfiguration,
	};
};

export default connectWithStore(
	PosConfigurations,
	mapStateToProps,
	mapDispatchToProps
);
