'use strict';

import constants from 'services/constants';
import moment from 'moment';
import {
	RESET_STATE,
	UPDATE_BATCH_FORM,
	ADD_BATCH_LIST,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	SET_WORKPLACE_POS_CONFIGURATION,
	UPDATE_WORKPLACE_POS_CONFIGURATION,
	SET_WORKPLACE_TO_POS_CONFIGURATION_DATE_FILTER,
	EDIT_BATCH_LIST_ITEMS,
	ADD_BATCH_LIST_DUPLICATE,
	REVERT_BATCH_LIST_ITEM,
	SET_WORKPLACE_TO_POS_CONFIGURATION_DATE,
} from './workplacePosConfiguration.actions';

const todayDate = moment.utc().format(constants.shortDate);

const defaultState = {
	data: {
		workplacePosConfiguration: {},
		batchFormData: {},
		batchListData: [],
		batchListDataDuplicate: [],
	},
	posWorkplaceToPosConfigurationDate: todayDate,
};

function reducer(state = defaultState, action) {
	const { payload, type } = action;

	switch (type) {
	case UPDATE_BATCH_FORM: {
		return {
			...state,
			data: {
				...state.data,
				batchFormData: {
					...state.data.batchFormData,
					...payload,
				},
			},
		};
	}

	case ADD_BATCH_LIST: {
		return {
			...state,
			data: {
				...state.data,
				batchListData: payload,
			},
		};
	}

	case ADD_BATCH_LIST_DUPLICATE: {
		return {
			...state,
			data: {
				...state.data,
				batchListDataDuplicate: payload,
			},
		};
	}

	case REVERT_BATCH_LIST_ITEM: {
		return {
			...state,
			data: {
				...state.data,
				batchListData: state.data.batchListData.map((entry) => {
					if (entry.identifier === payload.identifier) {
						// find the original entry in duplicate array
						const og = state.data.batchListDataDuplicate.find(
							(ogEntry) => ogEntry.identifier === entry.identifier
						);
						return {
							...og,
							rowEdited: false,
						};
					}

					return entry;
				}),
			},
		};
	}

	case EDIT_BATCH_LIST_ITEM: {
		return {
			...state,
			data: {
				...state.data,
				batchListData: state.data.batchListData.map((entry) => {
					if (entry.identifier === payload.identifier)
						return {
							...payload,
							rowEdited: true,
						};
					return entry;
				}),
			},
		};
	}

	case EDIT_BATCH_LIST_ITEMS: {
		return {
			...state,
			data: {
				...state.data,
				batchListData: state.data.batchListData.map((entry) => ({
					...entry,
					...payload,
					rowEdited: true,
				})),
			},
		};
	}

	case REMOVE_BATCH_LIST_ITEM: {
		return {
			...state,
			data: {
				...state.data,
				batchListData: state.data.batchListData.filter(
					(entry) => entry.identifier !== payload
				),
			},
		};
	}

	case SET_WORKPLACE_POS_CONFIGURATION: {
		return {
			...state,
			data: {
				...state.data,
				workplacePosConfiguration: payload,
			},
		};
	}

	case UPDATE_WORKPLACE_POS_CONFIGURATION: {
		return {
			...state,
			data: {
				...state.data,
				workplacePosConfiguration: {
					...state.data.workplacePosConfiguration,
					...payload,
				},
			},
		};
	}

	case SET_WORKPLACE_TO_POS_CONFIGURATION_DATE_FILTER: {
		return {
			...state,
			posWorkplaceToPosConfigurationDateFilter: payload,
		};
	}

	case SET_WORKPLACE_TO_POS_CONFIGURATION_DATE: {
		return {
			...state,
			posWorkplaceToPosConfigurationDate: payload,
		};
	}

	case RESET_STATE: {
		return {
			...state,
			data: defaultState.data,
		};
	}

	default:
		// never return default state in default case, Filip!!!
		return state;
	}
}

export default reducer;
