// import constants from './constants';
import moment from 'moment';

/**
 * @function shiftsRequiringAlignment
 * @description returns only alignment required shifts
 * @param {Array} shifts : Array of of shifts on the selected day
 * @param {Object} date : date in YYYY-MM-DD format
 */
export function shiftsRequiringAlignment(shifts, date) {
	let shiftsToReview = [];

	// Stupid, this date needs to come from somewhere else
	// Now we need to wait for it from store -.-
	if (!date || !shifts) return;

	if (shifts) {
		shiftsToReview = shifts.reduce((acc, shift) => {
			const employee = shift.shift_employees[0];

			// check if shift is assigned OR shift has clockings
			if (employee && employee.shift_clockings.length > 0) {
				if (
					!moment
						.utc(employee.planned_period.from)
						.isSame(employee.shift_clockings[0].clocked.from, 'minutes') ||
					!moment
						.utc(employee.planned_period.to)
						.isSame(employee.shift_clockings[0].clocked.to, 'minutes')
				) {
					return [...acc, shift];
				}
			}
			return acc;
		}, []);
	}

	return shiftsToReview;
}

/**
 * @function checkAllBusyHoursSet
 * @param {array} busyHalfHourKeys
 * @param {object} dayReviews
 * @description Checks whether all busy hours have been responded to
 */
export function checkAllBusyHoursSet(busyHalfHourKeys, dayReviews) {
	let allReviewed = true;

	busyHalfHourKeys.forEach((id) => {
		if (!dayReviews[id]) {
			allReviewed = false;
		}
	});

	return allReviewed;
}

/**
 * @description Return shift clockout status. Currently we allow up to 100 hours to consider notClocked out!
 * @param checkIfClockedOut
 * @param {date} from
 * @param {date} fo
 * @returns {bool}
 */
export function checkIfClockedOut(from, to) {
	let isClockOut = true;
	const endTo = moment.utc(to, 'YYYY-MM-DDTHH:mm:ss');
	const startFrom = moment.utc(from, 'YYYY-MM-DDTHH:mm:ss');
	if (endTo.isValid()) {
		if (Math.abs(endTo.diff(startFrom, 'hours')) > 100) {
			isClockOut = false;
		}
	} else {
		isClockOut = false;
	}
	return isClockOut;
}

/**
 * @description Return shift alignement status.
 * @param checkIfAligned
 * @param {object} shift
 * @param {object} clocking
 * @returns {bool}
 */
export function checkIfAligned(shift, clocking) {
	if (!shift || !shift.planned_period) return;
	const from = moment
		.utc(shift.planned_period.from, 'YYYY-MM-DDTHH:mm:ss')
		.isSame(moment.utc(clocking.valid.from, 'YYYY-MM-DDTHH:mm:ss'), 'minute');

	const to = moment
		.utc(shift.planned_period.to, 'YYYY-MM-DDTHH:mm:ss')
		.isSame(moment.utc(clocking.valid.to, 'YYYY-MM-DDTHH:mm:ss'), 'minute');
	return from && to;
}
