'use strict';

import React, { useState } from 'react';

// interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';
import { IVacationDescriptionPreset } from './types';
import { SelectOption } from 'types/select';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';

// components
import { Button, Icon } from 'dumb';
import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import VacationDescriptionPresetsEditableCells from './components/vacationDescriptionPresetsEditableCells/vacationDescriptionPresets';
import VacationDescriptionPresetsModalBatch from './components/batch/vacationDescriptionPresetsModalBatch';
// import
import getColumns from 'reactDataWrapperColumns/shiftplanning/vacationDescriptionPresets.columns';

// mui
import { Tooltip, Box } from '@mui/material';

import {
	fetchVacationDescriptionPresets,
	deleteVacationDescriptionPresets,
	editVacationDescriptionPresets,
} from './vacationDescriptionPresets.service';

import {
	setVacationDescriptionPreset,
	updateVacationDescriptionPreset,
	resetVacationDescriptionPreset,
} from './store/vacationDescriptionPresets.actions';

// constants/phrases/tools
import phrases from './vacationDescriptionPresets.phrases';
import getEditedValues from 'services/utils/getEditedValues';

const reduxKey = '/shiftplanning/vacation_description_presets';

const VacationDescriptionPresets = (): JSX.Element => {
	// hooks
	const {
		tableKey,
		customFilters,
		vacationDescriptionPreset,
		defaultVacationescriptionPreset,
	} = useAppSelector((state) => ({
		customFilters: state.filterSortColumnsData.tables[reduxKey]?.custom ?? {},
		tableKey: state.vacationDescriptionPresets.tableKey,
		vacationDescriptionPreset:
			state.vacationDescriptionPresets.data.vacationDescriptionPreset,
		defaultVacationescriptionPreset:
			state.vacationDescriptionPresets.data.defaultVacationescriptionPreset,
	}));
	const dispatch = useAppDispatch();
	const [showBatchModal, setShowBatchModal] = useState(false);

	const editEntry = () => {
		if (!vacationDescriptionPreset.id || !defaultVacationescriptionPreset)
			return Promise.resolve(true);

		const editedValues = getEditedValues({
			oldData: defaultVacationescriptionPreset,
			newData: vacationDescriptionPreset,
		});

		const payload: Partial<IVacationDescriptionPreset> = {
			subject: editedValues?.subject,
			description: editedValues?.description,
		};

		return editVacationDescriptionPresets(
			vacationDescriptionPreset.id,
			payload
		);
	};

	const deleteEntry = (id: number) => {
		return deleteVacationDescriptionPresets(id);
	};

	const setInitialEditValues = (data: IVacationDescriptionPreset) => {
		const payload: IVacationDescriptionPreset = {
			id: data.id,
			subject: data.subject,
			description: data.description,
		};

		dispatch(setVacationDescriptionPreset(payload));
	};

	const editStoreEntry = (
		name: keyof IVacationDescriptionPreset,
		value: string | SelectOption | null
	): void => {
		const payload = {
			[name]: value,
		};

		dispatch(updateVacationDescriptionPreset(payload));
	};

	const getExtraFilters = (): string => {
		const locationFilter = customFilters?.location
			? `:location.id=='${(customFilters.location as SelectOption).value}'`
			: '';

		let filter = '';
		if (locationFilter)
			filter = filter ? `${filter};${locationFilter}` : locationFilter;

		return filter;
	};

	const toggleShowBatchModal = () => setShowBatchModal(!showBatchModal);

	return (
		<>
			<ReactDataWrapper
				dataCy="vacation-description-presets-table"
				key={tableKey}
				title={phrases.TABLE_TITLE}
				columns={getColumns()}
				fetchData={(
					state: IFetchDataProps
				): ReturnType<typeof fetchVacationDescriptionPresets> =>
					fetchVacationDescriptionPresets(state)
				}
				filterable
				defaultPageSize={10}
				reduxKey={reduxKey}
				manual
				accessAreasAllowedToEdit={[
					'GlobalAdministration/VacationDescriptionPresets::Edit',
					'Organization Admin',
					'Global HR Configuration',
				]}
				editableCells={VacationDescriptionPresetsEditableCells({
					vacationDescriptionPreset,
					editStoreEntry,
				})}
				actionsWidth={60}
				editEntry={editEntry}
				deleteEntry={deleteEntry}
				setInitialEditValues={setInitialEditValues}
				onModalClose={() => dispatch(resetVacationDescriptionPreset())}
				compact
				extraFilters={getExtraFilters()}
				onModalCloseConfirm
				enableMultiSelection={false}
				enableSingleSelection={true}
				actionRender={
					<Tooltip title={phrases.BATCH_ADD}>
						<Box ml={1}>
							<Button
								label="Add"
								shadow
								type="inverted"
								onClick={toggleShowBatchModal}
								dataCy="batch-add"
							>
								<Icon name="add" />
							</Button>
						</Box>
					</Tooltip>
				}
			/>

			{showBatchModal && (
				<VacationDescriptionPresetsModalBatch onClose={toggleShowBatchModal} />
			)}
		</>
	);
};

export default VacationDescriptionPresets;
