'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { batch } from 'react-redux';

// components
import { Button, Icon, InputCollectionSelect, Legend, Tag } from 'dumb';
import { ModalRow } from 'dumb/modal';
import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';
import getColumns from 'reactDataWrapperColumns/administration/systemSoftwareVersions.columns';

// phrases
import phrases from '../../../workplaceSystemSoftwares.phrases';

class WorkplaceSystemSoftwaresBatchStepList extends Component {
	constructor(props) {
		super(props);

		this.cantAddBulletColor = 'ffe699';
	}

	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		const value = e?.target?.value ?? e;

		// edits the row in the store
		this.props.editBatchListItem({
			identifier: row.identifier,
			[type]: value,
		});
	}

	_editAllSystemSoftvareVersions(name, value) {
		const { updateBatchForm, batchList } = this.props;

		batch(() => {
			const payload = {
				[name]: value,
			};

			updateBatchForm(payload);

			batchList.map((entry) => {
				if (entry.canAdd) {
					this._editListEntry(value, entry, 'systemSoftwareVersion');
				}
			});
		});
	}

	render() {
		const { batchList, systemSoftwareId, batchForm } = this.props;

		return (
			<>
				{systemSoftwareId && (
					<ModalRow className="workplace-system-softwares__modal-batch__section workplace-system-softwares__modal-batch__section--global">
						<InputCollectionSelect
							id="system-software-version"
							label={phrases.MODAL_BATCH_STEP_SYSTEM_SOFTWARES_GLOBAL}
							placeholder={
								phrases.MODAL_BATCH_STEP_SYSTEM_SOFTWARES_GLOBAL_PLACEHOLDER
							}
							value={batchForm.globalSystemSoftwareVersion}
							handleChange={(key, value) =>
								this._editAllSystemSoftvareVersions(
									'globalSystemSoftwareVersion',
									value
								)
							}
							cache
							apiPath="/administration/system_software_versions"
							params={{
								limit: 30,
								filter: `:system_software.id=='${systemSoftwareId}'`,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.version,
							})}
							inputFilterFormat={(input) => `:version=like='%${input}%'`}
							tableColumns={getColumns()}
							tableTitle={phrases.MODAL_BATCH_STEP_SYSTEM_SOFTWARE_VERSIONS}
							tableReduxKey="workplace-batch-add/administration/system_software_versions"
						/>
					</ModalRow>
				)}
				<ModalRow className="workplace-system-softwares__modal-batch__section">
					<Legend
						points={[
							{
								bulletColor: `#${this.cantAddBulletColor}`,
								label: phrases.MODAL_BATCH_STEP_LIST_LEGEND_CANT_ADD,
							},
						]}
					/>
				</ModalRow>

				<ModalRow>
					<TableList>
						<thead>
							<tr>
								<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
								{systemSoftwareId ? (
									<th>{phrases.MODAL_BATCH_STEP_WORKPLACES}</th>
								) : (
									<th>{phrases.MODAL_BATCH_STEP_SYSTEM_SOFTWARES}</th>
								)}
								<th>{phrases.MODAL_BATCH_STEP_SYSTEM_SOFTWARE_VERSION}</th>

								<th />
							</tr>
						</thead>
						<tbody>
							{batchList.map((entry, i) => {
								const rowDisabled = !entry.canAdd;
								const deleteButtonDisabled =
									batchList.length === 1 || rowDisabled;

								return (
									<TableListRow
										key={entry.identifier}
										type={rowDisabled && 'light'}>
										<TableListCell>
											<div className="workplace-system-softwares__modal-batch__tag-wrapper">
												{rowDisabled && (
													<Tag type="bullet" color={this.cantAddBulletColor} />
												)}
												{i + 1}
											</div>
										</TableListCell>

										<TableListCell>
											{systemSoftwareId ? (
												<span>{entry.workplace?.label ?? ''}</span>
											) : (
												<span>{entry.systemSoftware?.label ?? ''}</span>
											)}
										</TableListCell>

										<TableListCell>
											<InputCollectionSelect
												id={`system-software-version-${entry.identifier}`}
												placeholder={
													phrases.MODAL_BATCH_STEP_SYSTEM_SOFTWARES_PLACEHOLDER
												}
												value={entry.systemSoftwareVersion}
												handleChange={(key, value) =>
													this._editListEntry(
														value,
														entry,
														'systemSoftwareVersion'
													)
												}
												cache
												disabled={rowDisabled}
												apiPath="/administration/system_software_versions"
												params={{
													limit: 30,
													filter: systemSoftwareId
														? `:system_software.id=='${systemSoftwareId}'`
														: `:system_software.id=='${entry.systemSoftware?.value?.id}'`,
												}}
												optionFormat={(entry) => ({
													value: entry,
													label: entry.version,
												})}
												inputFilterFormat={(input) =>
													`:version=like='%${input}%'`
												}
												tableColumns={getColumns()}
												tableTitle={
													phrases.MODAL_BATCH_STEP_SYSTEM_SOFTWARE_VERSIONS
												}
												tableReduxKey={`workplace-batch-add-${entry.identifier}/administration/system_software_versions`}
											/>
										</TableListCell>

										<TableListCell>
											<Button
												type="negative"
												size="tiny"
												disabled={deleteButtonDisabled}
												onClick={() => this._onRemoveEntry(entry.identifier)}>
												<Icon name="delete" />
											</Button>
										</TableListCell>
									</TableListRow>
								);
							})}
						</tbody>
					</TableList>
				</ModalRow>
			</>
		);
	}
}

WorkplaceSystemSoftwaresBatchStepList.propTypes = {
	batchList: PropTypes.array,
	removeBatchListItem: PropTypes.func,
	editBatchListItem: PropTypes.func,
	systemSoftwareId: PropTypes.number,
	batchForm: PropTypes.object,
	updateBatchForm: PropTypes.func,
};

export default WorkplaceSystemSoftwaresBatchStepList;
