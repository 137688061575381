export default {
	TITLE: 'Translations',
	INGREDIENT_TRANSLATIONS_ADDED: 'Translations added!',
	INGREDIENT_TRANSLATION_EDITED: 'Translation edited!',
	INGREDIENT_TRANSLATIONS_EDITED: 'Translations edited!',
	INGREDIENT_TRANSLATIONS_DELETED: 'Translations deleted!',

	// batch form
	MODAL_BATCH_STEP_FORM_TITLE: 'Select languages',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'Next',
	MODAL_HEADER_LANGUAGE: 'Language',
	MODAL_HEADER_LANGUAGE_PLACEHOLDER: 'Select languages...',
	MODAL_BATCH_STEP_LIST_TITLE: 'Add translations',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_LANGUAGE: 'Language',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add entries',
	LANGUAGES_TABLE_TITLE: 'Languages',
	MODAL_BATCH_STEP_LIST_NAME: 'Name',
	MODAL_BATCH_STEP_LIST_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_LIST_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_LIST_DESCRIPTION_PLACEHOLDER: 'Enter description...',
};
