import React, { Component } from 'react';

// Components
import AssetCollecitons from './components/assetCollection/assetCollection.component';
import Assets from './components/assets/assets.component';

class AssetsContainer extends Component {
	render() {
		return (
			<>
				<AssetCollecitons />
				<Assets />
			</>
		);
	}
}

AssetsContainer.propTypes = {};

export default AssetsContainer;
