import {
	SET_ADYEN_RECONCILIATION_DATA,
	UPDATE_ADYEN_RECONCILIATION_DATA,
	RESET_ADYEN_RECONCILIATION_DATA,
} from './adyenReconciliation.actions';

const defaultState = {
	data: {
		adyenReconciliation: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_ADYEN_RECONCILIATION_DATA:
			return {
				data: {
					adyenReconciliation: payload,
				},
			};

		case UPDATE_ADYEN_RECONCILIATION_DATA:
			return {
				data: {
					adyenReconciliation: {
						...state.data.adyenReconciliation,
						...payload,
					},
				},
			};

		case RESET_ADYEN_RECONCILIATION_DATA:
			return defaultState;

		default:
			return state;
	}
}

export default reducer;
