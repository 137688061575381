import React from 'react';
import PropTypes from 'prop-types';

// components
import { Label, Text, SectionCollapsable } from 'dumb';
import { ModalRow } from 'dumb/modal';
import ShiftTransferApprovalMobileViewPageApplicantList from './components/shiftTransferApprovalMobileViewPageApplicantList/shiftTransferApprovalMobileViewPageApplicantList';
import ShiftTransferApprovalMobileViewPageJuicerView from './components/shiftTransferApprovalMobileViewPageJuicerView';

// utils
import phrases from './../../shiftTransferApprovalMobileView.phrases';
import { ShiftTransfer as enums } from 'services/enums';

// utils
import {
	formatShiftTime,
	getShiftDate,
	getShiftDay,
} from './../../../../utils';

const ShiftTransferApprovalMobileViewPage = ({
	shiftTransfer,
	setShiftTransferApplication,
	applicationDecisions,
	ownTransfer,
}) => {
	const transferTime = `${getShiftDate(
		shiftTransfer.shift?.planned_period?.from
	)}  ${formatShiftTime(
		shiftTransfer.shift?.planned_period?.from
	)} - ${formatShiftTime(shiftTransfer.shift?.planned_period?.to)}`;

	const donor = shiftTransfer?.donor?.person?.full_name;
	const applicant =
		shiftTransfer?.applications?.[0]?.applicant?.person?.full_name;
	const workplace = shiftTransfer?.shift?.workplace?.name;

	let heading;
	if (ownTransfer) {
		heading = `${donor} wants to transfer a shift to you in ${workplace}`;
	} else {
		if (shiftTransfer?.type === enums.TRANSFER_TYPE.SWAP)
			heading = `${donor} wants to transfer a shift to ${applicant} in ${workplace}`;
		if (shiftTransfer?.type === enums.TRANSFER_TYPE.PUBLIC)
			heading = `${donor} wants to get rid of their shift in ${workplace}`;
		// if unassigned shift set public by a BM
		if (
			shiftTransfer?.type === enums.TRANSFER_TYPE.PUBLIC &&
			shiftTransfer?.donor === null
		)
			heading = `Unassigned shift in ${workplace}`;
	}

	return (
		<div className="shift-transfer-approval-modal__mobile-view">
			<ModalRow>
				<Text bold alignCenter size="large">
					{heading}
				</Text>
			</ModalRow>

			<ModalRow className="shift-transfer-approval-modal__mobile-view__section">
				<div>
					<Label>{phrases.SHIFT}</Label>
					<div className="shift-transfer-approval-modal__mobile-view__times-wrapper">
						<Text bold>{transferTime}</Text>
						<Text
							size="tiny"
							className="shift-transfer-approval-modal__mobile-view__times-wrapper__weekday"
						>
							({getShiftDay(shiftTransfer.shift?.planned_period?.from)})
						</Text>
					</div>
				</div>
			</ModalRow>

			<ModalRow className="shift-transfer-approval-modal__mobile-view__section">
				<SectionCollapsable
					title={ownTransfer ? phrases.ACTIONS : phrases.APPLICANTS}
				>
					{ownTransfer ? (
						<ShiftTransferApprovalMobileViewPageJuicerView
							shiftTransfer={shiftTransfer}
							setShiftTransferApplication={setShiftTransferApplication}
							applicationDecisions={applicationDecisions}
						/>
					) : (
						<div className="shift-transfer-approval-modal__mobile-view__applicants">
							<ShiftTransferApprovalMobileViewPageApplicantList
								shiftTransfer={shiftTransfer}
								setShiftTransferApplication={setShiftTransferApplication}
								applicationDecisions={applicationDecisions}
							/>
						</div>
					)}
				</SectionCollapsable>
			</ModalRow>
		</div>
	);
};

ShiftTransferApprovalMobileViewPage.propTypes = {
	shiftTransfer: PropTypes.object,
	setShiftTransferApplication: PropTypes.func,
	applicationDecisions: PropTypes.array,
	ownTransfer: PropTypes.bool,
};

export default ShiftTransferApprovalMobileViewPage;
