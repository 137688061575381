'use strict';

import {
	RESET_STATE,
	UPDATE_MARKET_SHIFT_RULE,
} from './organisationMarketShiftRules.actions';

const defaultState = {
	data: {
		marketShiftRule: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case UPDATE_MARKET_SHIFT_RULE: {
		return {
			...state,
			data: {
				marketShiftRule: {
					...state.data.marketShiftRule,
					...payload,
				},
			},
		};
	}

	case RESET_STATE: {
		return defaultState;
	}

	default:
		return state;
	}
}

export default reducer;
