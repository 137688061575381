export const SET_ADYEN_RECONCILIATION_DATA =
	'ADYEN_RECONCILIATION/SET_ADYEN_RECONCILIATION_DATA';
export const UPDATE_ADYEN_RECONCILIATION_DATA =
	'ADYEN_RECONCILIATION/UPDATE_ADYEN_RECONCILIATION_DATA';
export const RESET_ADYEN_RECONCILIATION_DATA =
	'ADYEN_RECONCILIATION/RESET_ADYEN_RECONCILIATION_DATA';

export function setAdyenReconciliationData(payload) {
	return {
		type: SET_ADYEN_RECONCILIATION_DATA,
		payload,
	};
}

export function updateAdyenReconciliationData(payload) {
	return {
		type: UPDATE_ADYEN_RECONCILIATION_DATA,
		payload,
	};
}

export function resetAdyenReconciliationData() {
	return {
		type: RESET_ADYEN_RECONCILIATION_DATA,
	};
}
