'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');

export default class SalaryComponentDaysPerWeekRulesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'maximum',
				type: 'string',
				canAdd: true,
				canEdit: true,
			},
		];
		this.settings = {
			name: 'SalaryComponentDaysPerWeekRulesList',
			allowEmpty: true,
			alwaysRefresh: true,
			canAdd: true,
			canEdit: true,
			canDelete: true,
			canManipulate: () => validAccess(undefined, ['Salary Admin']),
			translationPath: 'SALARY.SALARY_COMPONENT_DAYS_PER_WEEK_RULES',
			endpoint: __endpoints.collection.salary.salary_component_days_per_week_rules,
		};
		this.init();
		return this;
	}
}
