'use strict';

import prop from 'dot-prop-immutable';

import { SET_HOTBAR, RESET_HOTBARS } from './hotdamnbar.actions';

const defaultState = {
	hotbars: {},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
		case SET_HOTBAR:
			return prop.set(state, `hotbars.${payload.hotbarName}`, payload.hotbar);
		case RESET_HOTBARS:
			return {
				...state,
				hotbars: defaultState,
			};
		default:
			return state;
	}
}

export default reducer;
