'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import { InputCollectionSelect, Input } from 'dumb';

import _get from 'lodash/get';

import {
	setIngredientVariationOption,
	updateIngredientVariationOption,
	resetIngredientVariationOption,
} from './store/ingredientVariationOptions.actions';

import {
	fetchIngredientVariationOpt,
	addIngredientVariationOpt,
	editIngredientVariationOpt,
	deleteIngredientVariationOpt,
	editBatchIngredientVariationOpt,
} from './store/ingredientVariationOptions.service';

import phrases from './ingredientVariationOptions.phrases';

// Wrapper for react-data
// Documentation can be found here https://react-table.js.org/
class IngredientsVariationsOptions extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editMultiple = this.editMultiple.bind(this);

		this.state = {
			pages: null,
		};

		this.fetchData = this.fetchData.bind(this);

		this.columns = [
			{
				Header: 'Ingredient',
				id: 'ingredient.name',
				accessor: (d) => _get(d, 'ingredient.name', null),
				filterPath: ':ingredient.name',
			},
			{
				Header: 'Sort order',
				id: 'sortord',
				accessor: (d) => _get(d, 'sort_order', ''),
				filterPath: ':sort_order',
			},
			{
				Header: 'Ingredient amount',
				id: 'ingredient_amount',
				accessor: (d) => _get(d, 'ingredient_amount', null),
				filterPath: ':ingredient_amount',
			},
		];
	}

	deleteEntry(id) {
		return deleteIngredientVariationOpt(id);
	}

	editMultiple(selectedRows) {
		const { defaultValues } = this.props;

		const list = selectedRows.map((row) => {
			return {
				id: row.id,
				...defaultValues,
			};
		});

		const payload = {
			batch: list,
		};

		editBatchIngredientVariationOpt(payload);
	}

	editEntry() {
		const { defaultValues } = this.props;

		const payload = {
			ingredient: _get(defaultValues, 'ingredient.id', null),
			sort_order: _get(defaultValues, 'sort_order', undefined),
			ingredient_amount: _get(defaultValues, 'ingredient_amount', null),
		};

		return editIngredientVariationOpt(defaultValues.id, payload);
	}

	addEntry() {
		const { defaultValues, ingredientVariant } = this.props;

		const data = {
			ingredient_variation: ingredientVariant.id,
			ingredient: _get(defaultValues, 'ingredient.id', null),
			sort_order: _get(defaultValues, 'sort_order', undefined),
			ingredient_amount: _get(defaultValues, 'ingredient_amount', null),
		};

		return addIngredientVariationOpt(data);
	}

	setInitialEditValues(data) {
		this.props.setIngredientVariationOption(data);
	}

	getEditableCellsEdit() {
		const { defaultValues } = this.props;

		return [
			{
				header: 'Ingredient',
				value: <span>{_get(defaultValues, 'ingredient.name', '')}</span>,
			},
			{
				header: 'Sort Order',
				value: (
					<Input
						id="sort_order"
						placeholder="sort order"
						value={_get(defaultValues, 'sort_order', '')}
						onChange={(event) => this.editStoreEntry(event, 'sort_order')}
					/>
				),
			},
			{
				header: 'Ingredient amount',
				value: (
					<Input
						id="ingredient_amount"
						type="number"
						placeholder="Ingredient amount"
						value={_get(defaultValues, 'ingredient_amount', '')}
						onChange={(event) => this.editStoreEntry(event, 'ingredient_amount')}
					/>
				),
			},
		];
	}

	getEditableCells() {
		const { defaultValues, metaList } = this.props;

		let filter = '';
		if (metaList && metaList.data.listData.length) {
			const list = metaList.data.listData.map((x) => `'${x.ingredient.id}'`);
			filter = `:id=!IN=[${list}]`;
		}

		// @TODO: the cool way to fix this magic
		// let filter = `:ingredient_variation_options.id!='${ingredientVariant.id}'`;

		return [
			{
				header: 'Ingredient',
				value: (
					<InputCollectionSelect
						id="ingredient"
						value={{
							value: _get(defaultValues, 'ingredient.id', ''),
							label: `${_get(defaultValues, 'ingredient.name', '')}`,
						}}
						handleChange={(key, value) =>
							this.editStoreEntry(
								{
									id: value ? value.value : '',
									name: value ? value.label : '',
								},
								'ingredient'
							)
						}
						clearable
						cache
						required
						apiPath="/product/ingredients"
						params={{
							limit: 300,
							filter,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Sort Order',
				value: (
					<Input
						id="sort_order"
						type="number"
						placeholder="sort order"
						value={_get(defaultValues, 'sort_order', '')}
						onChange={(event) => this.editStoreEntry(event, 'sort_order')}
					/>
				),
			},
			{
				header: 'Ingredient amount',
				value: (
					<Input
						id="ingredient_amount"
						type="number"
						required
						placeholder="Ingredient amount"
						value={_get(defaultValues, 'ingredient_amount', '')}
						onChange={(event) => this.editStoreEntry(event, 'ingredient_amount')}
					/>
				),
			},
		];
	}

	editStoreEntry(e, type) {
		const { defaultValues } = this.props;
		const value = e.target ? e.target.value : e;

		const updateObject = {
			ingredient: defaultValues.ingredient,
			sort_order: defaultValues.sort_order,
			ingredient_amount: defaultValues.ingredient_amount,
		};

		this.props.updateIngredientVariationOption({
			...updateObject,
			...{ [type]: value },
		});
	}

	fetchData(state) {
		const { ingredientVariant } = this.props;
		return fetchIngredientVariationOpt(ingredientVariant.id, state);
	}

	render() {
		return (
			<ReactDataWrapper
				accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.INGREDIENT_VARIATION_OPTIONS}
				className="-striped -highlight"
				columns={this.columns}
				totalEntries={this.state.totalEntries} // Display the total number of pages
				fetchData={this.fetchData} // Request new data when things change
				filterable
				defaultPageSize={10}
				reduxKey="sale/ingredient_variations_options/sub"
				manual
				editMultiple={this.editMultiple}
				editEntry={(e) => this.editEntry(e)}
				editableCells={this.getEditableCells()}
				editableCellsEdit={this.getEditableCellsEdit()}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetIngredientVariationOption}
				actionsWidth={60}
				deleteEntry={this.deleteEntry}
				createEntry={this.addEntry}
			/>
		);
	}
}

IngredientsVariationsOptions.propTypes = {
	ingredientVariant: PropTypes.object,
	defaultValues: PropTypes.object,
	metaList: PropTypes.object,

	updateIngredientVariationOption: PropTypes.func,
	setIngredientVariationOption: PropTypes.func,
	resetIngredientVariationOption: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setIngredientVariationOption,
			updateIngredientVariationOption,
			resetIngredientVariationOption,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultValues: store.ingredientVariationOptions.data.ingredientVariationOptRow,
		metaList: store.listData['sale/ingredient_variations_options/sub'],
	};
};

export default connectWithStore(IngredientsVariationsOptions, mapStateToProps, mapDispatchToProps);
