// interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';
import { IVacationDescriptionPreset } from './types';

import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './vacationDescriptionPresets.phrases';

function handleError(err: Error): Error {
	const error = formatErrorMessage(err);
	setFeedback(error, 0);

	throw error;
}

/**
 * @function fetchVacationDescriptionPresets
 * @description Fetch all companies
 * @param {object} state
 */
export function fetchVacationDescriptionPresets(
	state: IFetchDataProps
): Promise<IApiRes> {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get(
		'/shiftplanning/vacation_description_presets',
		params,
		null,
		state.headers
	)
		.then((res: IApiRes) => res)
		.catch(handleError);
}

/**
 * @function addVacationDescriptionPresets
 * @description adds vacationDescriptionPreset
 */
export function addVacationDescriptionPresets(
	data: IVacationDescriptionPreset[]
): Promise<IApiRes> {
	return post('/shiftplanning/vacation_description_presets', { batch: data })
		.then((res: IApiRes) => {
			setFeedback(phrases.ENTRY_ADDED, 1);
			return res;
		})
		.catch(handleError);
}
/**
 * @function editVacationDescriptionPresets
 * @description edits company
 */
export function editVacationDescriptionPresets(
	id: number,
	data: Partial<IVacationDescriptionPreset>
): Promise<IApiRes> {
	return post(`/shiftplanning/vacation_description_presets/${id}`, data)
		.then((res: IApiRes) => {
			setFeedback(phrases.ENTRY_EDITED, 1);
			return res;
		})
		.catch(handleError);
}

/**
 * @function deleteVacationDescriptionPresets
 * @description deletes companies
 */
export function deleteVacationDescriptionPresets(id: number): Promise<IApiRes> {
	return remove(`/shiftplanning/vacation_description_presets/${id}`)
		.then((res: IApiRes) => {
			setFeedback(phrases.ENTRIES_DELETED, 1);
			return res;
		})
		.catch(handleError);
}
