'use strict';

import { get, post } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './tenantTransferModal.phrases';

/**
 * @function fetchTenants
 * @description Fetch tenants for the select
 * to be able to filter out some data ourselves (api doesn't support filtering on this endpoint)
 */
export function fetchTenants({ userId }) {
	return get(`/profile/users/${userId}/tenants`)
		.then((res) => res)
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchTenantTransferOptions
 * @description Fetch tenant options for specific users
 */
export function fetchTenantTransferOptions({ tenantId, employmentId }) {
	return get(
		`/hr/tenants/${tenantId}/employments/${employmentId}/transfer_options`
	)
		.then((res) => res)
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function postTransferTenant
 * @description Transfer employee to another tenant
 */
export function postTransferTenant({ tenantId, body }) {
	return post(`/hr/tenants/${tenantId}/employments`, body, {
		'JOE-TENANT': tenantId,
	})
		.then((res) => {
			setFeedback(phrases.TENANT_TRANSFERED, 1);

			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
