import React, { useState } from 'react';

// interfaces/ enums
import { SelectOption } from 'types/select';
import { ModalTypeEnums } from './productVariantNutritionAllergenInfo.enums';

// components
import { Modal, Box, Typography, IconButton, Theme, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ProductVariantNutritionAllergenInfoPageOne from '././components/productVariantNutritionAllergenInfoPageOne';
import ProductVariantNutritionAllergenInfoList from './components/productVariantNutritionAllergenInfoList';

// services
import {
	fetchProductAllergenInformation,
	fetchProductNutritionInformation,
} from './productVariantNutritionAllergenInfo.services';

// misc
import phrases from './productVariantNutritionAllergenInfo.phrases';

const style = (theme: Theme) => ({
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minWidth: 'initial',
	width: `calc(100% - ${theme.spacing(2)})`,
	bgcolor: 'background.paper',
	boxShadow: 12,
	p: 2,
	outline: 'none',
	overflow: 'scroll',
	minHeight: 200,
	maxHeight: '80vh',
	[theme.breakpoints.up('sm')]: {
		width: 400,
		p: 5,
	},
});

type Props = {
	onClose: () => void;
	productVariantId: string | null;
};

const ProductVariantNutritionAllergenInfo = ({ onClose, productVariantId }: Props): JSX.Element => {
	const [page, setPage] = useState<ModalTypeEnums>(ModalTypeEnums.PAGE_ONE_CHOICE);
	const [loading, setLoading] = useState(false);
	const [allergens, setAllergens] = useState<IProductVariantAllergenInfo[] | null>(null);
	const [nutritionInfos, setNutritionInfos] = useState<IProductVariantNutritionInfo[] | null>(null);

	const onPageOneChange = (workplace: SelectOption, date: string, pageType: ModalTypeEnums): void => {
		if (!productVariantId) return;

		setPage(pageType);
		setLoading(true);

		const payload = {
			workplaceId: workplace.value as string,
			date,
			productVariantId,
		};

		Promise.all([fetchProductAllergenInformation(payload), fetchProductNutritionInformation(payload)])
			.then(([allergenInfo, nutritionInfo]) => {
				setAllergens(allergenInfo);
				setNutritionInfos(nutritionInfo);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const goToFirstPage = (): void => {
		setPage(ModalTypeEnums.PAGE_ONE_CHOICE);
	};
	const clearData = (): void => {
		setAllergens(null);
		setNutritionInfos(null);
	};

	return (
		<Modal open onClose={onClose}>
			<Box sx={style}>
				{loading && (
					<Box
						sx={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							zIndex: 1,
						}}
					>
						<CircularProgress />
					</Box>
				)}

				<IconButton
					sx={{
						position: 'absolute',
						top: (theme) => theme.spacing(2),
						right: (theme) => theme.spacing(2),
					}}
				>
					<CloseIcon onClick={onClose} />
				</IconButton>

				<Typography variant="h4" textAlign="center">
					{phrases.TITLE}
				</Typography>

				<Box mt={4}>
					{page === ModalTypeEnums.PAGE_ONE_CHOICE && (
						<ProductVariantNutritionAllergenInfoPageOne onPageChange={onPageOneChange} />
					)}
					{page === ModalTypeEnums.PAGE_TWO_LIST && (
						<ProductVariantNutritionAllergenInfoList
							allergens={allergens}
							nutritionInfos={nutritionInfos}
							onBack={goToFirstPage}
							onUnmount={clearData}
						/>
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default ProductVariantNutritionAllergenInfo;
