import React from 'react';
import PropTypes from 'prop-types';

// components
import { Card } from 'dumb';
import ReorderProductTileLayoutGroup from './components/reorderProductTileLayoutGroup/reorderProductTileLayoutGroup';

// styles
import './reorderProductTileLayoutGroups.css';

const ReorderProductsTileLayoutGroups = (props) => {
	const {
		batchForm,
		activeTileLayoutGroup,
		setActiveTileLayoutGroup,
		submitGroup,
	} = props;

	return (
		<Card className="reorder-product-tile-layout-groups">
			{batchForm.uniqueTileLayoutGroups.map((entry, i) => {
				return (
					<ReorderProductTileLayoutGroup
						key={entry.id}
						group={entry}
						imageAsset={batchForm.assets.find(
							(asset) =>
								entry.asset_collection &&
								asset.asset_collection.id === entry.asset_collection.id
						)}
						activeTileLayoutGroup={activeTileLayoutGroup}
						setActiveTileLayoutGroup={setActiveTileLayoutGroup}
						submitGroup={submitGroup}
					/>
				);
			})}
		</Card>
	);
};

ReorderProductsTileLayoutGroups.propTypes = {
	batchForm: PropTypes.object,
	activeTileLayoutGroup: PropTypes.string,
	setActiveTileLayoutGroup: PropTypes.func,
	submitGroup: PropTypes.func,
};

export default ReorderProductsTileLayoutGroups;
