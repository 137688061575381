import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './marketGracePeriods.phrases';

/**
 * @function fetchMarketGracePeriods
 * @description Fetch all market grace periods
 */
export function fetchMarketGracePeriods(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/shiftplanning/market_grace_periods', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addMarketGracePeriod
 * @description add market grace period
 */
export function addMarketGracePeriod(data) {
	return post('/shiftplanning/market_grace_periods', data)
		.then((res) => {
			setFeedback(phrases.MARKET_GRACE_PERIOD_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editMarketGracePeriod
 * @description edit market grace period
 */
export function editMarketGracePeriod(data) {
	return post(`/shiftplanning/market_grace_periods/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.MARKET_GRACE_PERIOD_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editMarketGracePeriods
 * @description edit market grace periods
 */
export function editMarketGracePeriods(data) {
	return patch('/shiftplanning/market_grace_periods', data)
		.then((res) => {
			setFeedback(phrases.MARKET_GRACE_PERIODS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteMarketGracePeriod
 * @description delete market grace periods
 */
export function deleteMarketGracePeriod(id) {
	return remove(`/shiftplanning/market_grace_periods/${id}`)
		.then((res) => {
			setFeedback(phrases.MARKET_GRACE_PERIOD_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
