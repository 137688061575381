export const RESET_STATE =
	'GLOBAL_ADMINISTRATION_MARKET_GRACE_PERIODS/RESET_STATE';
export const SET_MARKET_GRACE_PERIOD =
	'GLOBAL_ADMINISTRATION_MARKET_GRACE_PERIODS/SET_MARKET_GRACE_PERIOD';
export const UPDATE_MARKET_GRACE_PERIOD =
	'GLOBAL_ADMINISTRATION_MARKET_GRACE_PERIODS/UPDATE_MARKET_GRACE_PERIOD';

export const EDIT_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_MARKET_GRACE_PERIODS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_MARKET_GRACE_PERIODS/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'GLOBAL_ADMINISTRATION_MARKET_GRACE_PERIODS/ADD_BATCH_LIST_ITEM_ROW';

export function setMarketGracePeriods(payload) {
	return {
		type: SET_MARKET_GRACE_PERIOD,
		payload,
	};
}

export function updateMarketGracePeriods(payload) {
	return {
		type: UPDATE_MARKET_GRACE_PERIOD,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}
