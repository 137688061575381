'use strict';

import { get, remove } from 'api.vanilla.service';
import FileListDataProviderAbstract from './fileList.dataProvider.abstract';

class FileListEndpointDataProvider extends FileListDataProviderAbstract {
	constructor(endpoint) {
		super();

		// Set new collection
		this.setEndpoint(endpoint);
		this.type = null;
		this.date = null;
		this.fileName = null;

		this.filter = null;
	}

	/**
	 * @function setType
	 * @memberOf FileListEndpointDataProvider
	 * @description Set type query
	 * @param {function}
	 */
	setType(query) {
		this.type = query;
	}

	/**
	 * @function setDate
	 * @memberOf FileListEndpointDataProvider
	 * @description Set date query
	 * @param {function}
	 */
	setDate(query) {
		this.date = query;
	}

	/**
	 * @function setFileName
	 * @memberOf FileListEndpointDataProvider
	 * @description Set file name query
	 * @param {function}
	 */
	setFileName(query) {
		this.fileName = query;
	}

	/**
	 * @function setEndpoint
	 * @memberOf FileListEndpointDataProvider
	 * @description Set collection
	 * @param {string}
	 */
	setEndpoint(endpoint) {
		this.endpoint = endpoint;
	}

	/**
	 * @function setParams
	 * @memberOf FileListEndpointDataProvider
	 * @description Set filter for current file list
	 * @param {string}
	 */
	setFilter(filter) {
		this.filter = filter;
	}

	/**
	 * @function deleteFile
	 * @memberOf FileListEndpointDataProvider
	 * @description Deletes files
	 * @param {object}
	 */
	deleteFile(file) {
		return remove(`${this.endpoint}/${file.id}`);
	}

	/**
	 * @function getData
	 * @memberOf HotbarEndpointDataProvider
	 * @description Gets data via paginator
	 */
	getData(value) {
		const params = {
			filter: this.filter,
			limit: this.limit
		};

		return get(this.endpoint, params).then(response => {
			return response.data;
		});
	}
}

export default FileListEndpointDataProvider;
