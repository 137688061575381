'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// rdw actions
import { store } from 'appState';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';

// components
import { ModalStepper, Overlay, FileUpload, Input, Button } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';

// different batch views
import BatchStepForm from './components/productBundleSetProductVariantsBatchStepForm';
import BatchStepList from './components/productBundleSetProductVariantsBatchStepList';

// phrases
import phrases from './../../productBundleSetProductVariants.phrases';

// loooodash
import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';
import _uniqBy from 'lodash/uniqBy';

class ProductBundleSetProductVariantsModalBatch extends Component {
	constructor(props) {
		super(props);

		this.productVariantUrl = '/product/product_variants';

		this.state = {
			showOverlay: false,
			overlayType: null,
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this._getIsBatchStepFormValid = this._getIsBatchStepFormValid.bind(this);
		this.handleToggleOverlay = this.handleToggleOverlay.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.onOverlayOpen = this.onOverlayOpen.bind(this);
		this.resetSelectedRows = this.resetSelectedRows.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);

		this.modalRef = React.createRef();

		this.columnsProductVariant = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Product',
				id: 'prod',
				accessor: (d) => _get(d, 'product.name', ''),
				filterPath: ':product.name',
			},
			{
				Header: 'Product category',
				id: 'prodCategory',
				accessor: (d) => _get(d, 'product.product_category.name', ''),
				filterPath: ':product.product_category.name',
			},
			{
				Header: 'Size',
				id: 'size',
				accessor: (d) => _get(d, 'size.name', ''),
				filterPath: ':size.name',
			},
			{
				Header: 'Fuel',
				id: 'fuel',
				accessor: (d) => _get(d, 'fuel', ''),
				filterPath: ':fuel',
			},
			{
				Header: 'Points',
				id: 'points',
				accessor: (d) => _get(d, 'points.points', ''),
				filterPath: ':points.points',
				filterable: false,
				sortable: false,
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: (d) => _get(d, 'legacy', ''),
				filterPath: ':legacy',
				filterable: false,
				Cell: (d) => {
					return (
						<Input
							id="checkbox"
							type="checkbox"
							checked={_get(d, 'original.legacy', false)}
							disabled
						/>
					);
				},
			},
			{
				Header: 'Available for delivery',
				id: 'availableForDelivery',
				accessor: 'available_for_delivery',
				filterPath: ':available_for_delivery',
				filterable: false,
				Cell: (d) => {
					return (
						<Input
							id="availableForDelivery"
							type="checkbox"
							checked={d.value ?? false}
							disabled
						/>
					);
				},
			},
			{
				Header: 'Image',
				id: 'image',
				accessor: (d) => _get(d, 'image.filename', ''),
				filterPath: ':image.filename',
				filterable: false,
				sortable: false,
				Cell: (d) => {
					const url = _get(d, 'original.image.url', false);
					let file = null;
					if (url) {
						file = {
							url,
							filename: _get(d, 'original.image.filename', ''),
						};
					}
					return <FileUpload file={file} thumbnail disabled />;
				},
			},
			{
				Header: 'Asset collection',
				id: 'asset_collection',
				accessor: (d) => _get(d, 'asset_collection.name', ''),
				filterPath: ':asset_collection.name',
				filterable: false,
				sortable: false,
			},
		];
	}

	handleToggleOverlay(type) {
		const { listProductVariants, updateBatchForm } = this.props;

		if (this.state.showOverlay) {
			const selectedRowsInList = _get(
				listProductVariants,
				'ui.selectedRows',
				[]
			);

			updateBatchForm({
				productVariant: selectedRowsInList.map((entry) => {
					return { label: entry.name, value: entry };
				}),
			});
		}

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
			overlayType: prevState.overlayType ? '' : type,
		}));
	}

	onOverlayOpen() {
		const { batchFormData } = this.props;
		const { overlayType } = this.state;

		if (_isEmpty(batchFormData.productVariant)) return;

		// set already selected data in rdw store as clicked
		batchFormData.productVariant.map((entry) => {
			store.dispatch(
				actions.addRowToSelection({
					reduxKey: overlayType,
					data: entry.value,
				})
			);
		});
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}
			>
				Legacy
			</Button>
		);
	}

	fetchData(state) {
		const { fetchProductVariants } = this.props;

		return fetchProductVariants(state);
	}

	_onSubmitForm() {
		const { batchFormData, addBatchList, productBundleSet } = this.props;

		// clear duplicates
		const cleanProductVariants = _uniqBy(
			batchFormData.productVariant,
			'value.id'
		);

		const listArray = cleanProductVariants.map((entry) => {
			return {
				identifier: _uniqueId('row-'), // used to identify what to edit on step#2
				productVariant: entry,
				productBundleSet,
			};
		});

		addBatchList(listArray);

		this._goToStep(2);
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_batchSubmit() {
		const { batchList, handleClose, batchAddData, productBundleSet } =
			this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		// get rid of duplciate values once again
		const cleanArray = _uniqBy(batchList, 'productVariant.value.id');

		const payload = cleanArray.map((entry) => {
			return {
				bundle_set: productBundleSet.id,
				product_variant: _get(entry, 'productVariant.value.id', null),
			};
		});

		batchAddData({ batch: payload })
			.then(() => {
				handleClose();
				this.setState(() => ({
					loadingModal: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	_getIsBatchStepFormValid() {
		const { batchFormData } = this.props;

		return !_isEmpty(batchFormData.productVariant);
	}

	resetSelectedRows(reduxKey) {
		const { listProductVariants } = this.props;

		listProductVariants && store.dispatch(actions.resetSelection({ reduxKey }));
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchList,
			editBatchListItem,
			removeBatchListItem,
		} = this.props;
		const { showOverlay } = this.state;

		const steps = [
			{
				component: (
					<BatchStepForm
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.handleToggleOverlay}
						productVariantUrl={this.productVariantUrl}
						resetSelection={this.resetSelectedRows}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE,
				isValid: this._getIsBatchStepFormValid(),
				onNext: () => this._onSubmitForm(),
				loading: false,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				noScroll: false,
				allowOverflow: true,
			},
			{
				component: (
					<BatchStepList
						batchList={batchList}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
						productVariantUrl={this.productVariantUrl}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		const columns = this.columnsProductVariant;

		const legacyFilter = this.state.legacyFilterOn
			? this.state.legacyFilter
			: '';

		return (
			<>
				{modalVisible && (
					<ModalStepper
						className="modal-batch"
						ref={this.modalRef}
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={showOverlay ? () => {} : handleClose}
					/>
				)}

				<Overlay
					zIndex={551}
					height={850}
					list
					visible={showOverlay}
					onClose={() => {
						this.handleToggleOverlay();
					}}
				>
					<ReactDataWrapper
						accessAreasAllowedToEdit={['Sales Configuration']}
						title={phrases.MODAL_BATCH_STEP_FORM_PRODUCT_VARIANTS_TABLE_TITLE}
						columns={columns}
						fetchData={this.fetchData}
						customAreaComponents={this.getLegacyFilterButton()}
						filterable
						disableFetchCsvButton
						enableMultiSelection
						batchSelection
						defaultPageSize={20}
						onInitialization={() => this.onOverlayOpen()}
						extraFilters={legacyFilter}
						reduxKey={this.state.overlayType}
						manual
						actionsWidth={0}
						style={{
							maxHeight: '720px',
						}}
					/>
				</Overlay>
			</>
		);
	}
}

ProductBundleSetProductVariantsModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	listProductVariants: PropTypes.object,
	addBatchList: PropTypes.func,
	fetchProductVariants: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	batchAddData: PropTypes.func,
	productBundleSet: PropTypes.string,
};

export default ProductBundleSetProductVariantsModalBatch;
