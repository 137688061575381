import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './productVariantGroups.phrases';

/**
 * @function fetchProductVariantGroups
 * @description Fetch all product variant groups
 * @param {object} state
 */
export function fetchProductVariantGroups(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/product/product_variant_groups', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addProductVariantGroups
 * @description add product variant group
 */
export function addProductVariantGroups(data) {
	return post('/product/product_variant_groups', data)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_GROUP_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editProductVariantGroup
 * @description edit product variant group
 */
export function editProductVariantGroup(data) {
	return post(`/product/product_variant_groups/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_GROUP_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function editProductVariantGroups
 * @description edit product variant groups
 */
export function editProductVariantGroups(data) {
	return patch('/product/product_variant_groups', data)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_GROUPS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function deleteProductVariantGroup
 * @description delete product variant group
 */
export function deleteProductVariantGroup(id) {
	return remove(`/product/product_variant_groups/${id}`)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_GROUP_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
