export const SET_RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS_MODAL =
	'GLOBAL_ADMINISTRATION_RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS/SET_RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS_MODAL';
export const UPDATE_RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS =
	'GLOBAL_ADMINISTRATION_RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS/UPDATE_RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS';
export const RESET_STATE =
	'GLOBAL_ADMINISTRATION_RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS/RESET_STATE';

export const UPDATE_BATCH_FORM =
	'GLOBAL_ADMINISTRATION_RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'GLOBAL_ADMINISTRATION_RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS/REMOVE_BATCH_LIST_ITEM';

export function setRawIngredientConfigurationsModal(payload) {
	return {
		type: SET_RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS_MODAL,
		payload
	};
}
export function updateRawIngredientConfigurations(payload) {
	return {
		type: UPDATE_RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS,
		payload
	};
}

export function resetState() {
	return {
		type: RESET_STATE
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload
	};
}
