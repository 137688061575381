export default function getColumns() {
	return [
		{
			Header: 'Version',
			id: 'version',
			accessor: 'version',
			filterPath: ':version',
		},
		{
			Header: 'Plist',
			id: 'p-list',
			accessor: 'plist',
			filterPath: ':plist',
		},
	];
}
