import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// import AccessControlAdministration from 'structure/userManagement/accessControlAdministration/accessControlAdministration.container.jsx';
import Users from 'structure/userManagement/users/users.container.jsx';
import TileDirectory from 'smart/tileDirectory/tileDirectory';

const AccessControlAdministration = React.lazy(() =>
	import(
		/* webpackChunkName: "AccessControlAdministration" */ 'structure/userManagement/accessControlAdministration/accessControlAdministration.container.jsx'
	)
);

export const userManagementRoutes = [
	{
		element: <Outlet />,
		path: 'user-management',
		title: 'User management',
		icon: require('@mui/icons-material/ManageAccountsOutlined').default,
		allowedAccessControlLevels: ['User Admin'],
		children: [
			{
				index: true,
				element: <TileDirectory pathName="user-management" />,
			},
			{
				element: (
					<Suspense fallback={<div>Loading...</div>}>
						<AccessControlAdministration />
					</Suspense>
				),
				path: 'access-control-administration',
				title: 'Access control administration',
				description: 'Manage users & roles',
				allowedAccessControlLevels: ['User Admin'],
			},
			{
				element: <Users />,
				path: 'users',
				title: 'users',
				description: 'Manage users',
				allowedAccessControlLevels: ['User Admin'],
			},
		],
	},
];
