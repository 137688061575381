'use strict';

import _uniqBy from 'lodash/uniqBy';
import moment from 'moment';

import {
	SET_CLOCKINGS,
	FETCHING_CLOCKINGS,
	CLEAN_CLOCKINGS,
	REMOVE_CLOCKING,
	UPDATE_CLOCKING,
	ADD_UNASSIGNED_CLOCKING,
	ADD_PERSON_CLOCKINS,
	REMOVE_PERSON_CLOCKINS,
} from '../actions/shiftPlannerClockings.actions';

import _get from 'lodash/get';

const defaultState = {
	data: {
		clockings: [],
	},
	ui: {
		fetchingClockings: false,
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
		case SET_CLOCKINGS:
			return {
				...state,
				data: {
					...state.data,
					clockings: payload.clockings,
				},
			};

		case ADD_UNASSIGNED_CLOCKING: {
			return {
				...state,
				data: {
					...state.data,
					clockings: _uniqBy([...state.data.clockings, payload.clocking], 'id'),
				},
			};
		}

		case FETCHING_CLOCKINGS:
			return {
				...state,
				ui: {
					...state.ui,
					fetchingClockings: payload.fetchingClockings,
				},
			};

		case CLEAN_CLOCKINGS: {
			return {
				...state,
				data: defaultState.data,
				ui: defaultState.ui,
			};
		}

		case UPDATE_CLOCKING: {
			return {
				...state,
				data: {
					...state.data,
					clockings: state.data.clockings.map((clocking) => {
						if (payload.id === clocking.id) return payload;
						return clocking;
					}),
				},
			};
		}

		case REMOVE_CLOCKING: {
			const clockingData = _get(state, 'data.clockings', []);
			const clockings = clockingData.filter((x) => x.id !== payload.id);

			return {
				...state,
				data: {
					...state.data,
					clockings,
				},
			};
		}

		case ADD_PERSON_CLOCKINS: {
			const newAddons = payload.filter((x) => {
				if (!_get(x, 'shift_employee.shift.id', false)) {
					return true;
				}
			});

			return {
				...state,
				data: {
					...state.data,
					clockings: [...state.data.clockings, ...newAddons],
				},
			};
		}

		case REMOVE_PERSON_CLOCKINS: {
			// Remove clockings that are specific person ID or is The same day
			const newClockings = state.data.clockings.filter((x) => {
				return (
					x.person.id !== payload.personId ||
					!moment(x.valid.from, 'YYYY-MM-DDTHH:ss:00+00:00').isSame(
						moment(payload.date),
						'day'
					)
				);
			});

			return {
				...state,
				data: {
					...state.data,
					clockings: newClockings,
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
