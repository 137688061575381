'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	updateBatchForm,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setStoreAccessType,
	updateStoreAccessType,
	resetState,
} from './store/storeAccessTypes.actions';

// services
import {
	fetchStoreAccessTypes,
	editStoreAccessType,
	editStoreAccessTypes,
	addStoreAccessType,
	deleteStoreAccessType,
} from './storeAccessTypes.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Input, Button, Icon } from 'dumb';

import StoreAccessTypesModalBatch from './components/batch/storeAccessTypesModalBatch';

// phrases/constants
import phrases from './storeAccessTypes.phrases';

class StoreAccessTypes extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.addStoreAccessTypesWrapper = this.addStoreAccessTypesWrapper.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.editEntry = this.editEntry.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: 'name',
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'desc',
				accessor: 'description',
				filterPath: ':description',
			},
		];
	}

	editEntry() {
		const { storeAccessType } = this.props;

		const payload = {
			id: storeAccessType.id,
			name: storeAccessType.name,
			description: storeAccessType.description,
		};

		return editStoreAccessType(payload);
	}

	editMultiple(selectedRows) {
		const { storeAccessType } = this.props;

		const payload = {
			...(storeAccessType.name && {
				name: storeAccessType.name,
			}),
			...(storeAccessType.description && {
				description: storeAccessType.description,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editStoreAccessTypes({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteStoreAccessType(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
		};

		this.props.setStoreAccessType(payload);
	}

	getEditableCells() {
		const { storeAccessType } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={storeAccessType.name || ''}
						onChange={(event) => this.editStoreEntry('name', event)}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={storeAccessType.description || ''}
						onChange={(event) => this.editStoreEntry('description', event)}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const value = e.target.value;

		const updateObject = {
			[name]: value,
		};

		this.props.updateStoreAccessType(updateObject);
	}

	fetchData(state) {
		return fetchStoreAccessTypes(state);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	addStoreAccessTypesWrapper(data) {
		return addStoreAccessType(data).then(() =>
			this.setState(() => ({
				key: moment.utc(),
			}))
		);
	}

	render() {
		const { batchList, editBatchListItem, addBatchListItemRow, removeBatchListItem } = this.props;

		return (
			<>
				<ReactDataWrapper
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey="/administration/store_access_types"
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					editMultiple={this.editMultiple}
					accessAreasAllowedToEdit={['Global HR Configuration']}
					deleteEntry={this.deleteEntry}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					actionRender={
						<Button type="inverted" label="Batch" shadow onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
				/>

				<StoreAccessTypesModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addStoreAccessType={this.addStoreAccessTypesWrapper}
				/>
			</>
		);
	}
}

StoreAccessTypes.propTypes = {
	storeAccessType: PropTypes.object,

	updateStoreAccessType: PropTypes.func,
	setStoreAccessType: PropTypes.func,
	resetState: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			editBatchListItem,
			removeBatchListItem,
			updateStoreAccessType,
			setStoreAccessType,
			resetState,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		storeAccessType: store.globalAdministrationStoreAccessTypes.data.storeAccessType,
		batchList: store.globalAdministrationStoreAccessTypes.data.batchList,
	};
};

export default connectWithStore(StoreAccessTypes, mapStateToProps, mapDispatchToProps);
