'use strict';

import {
	SET_SHIFT_TRANSFER_POLICY,
	UPDATE_SHIFT_TRANSFER_POLICY,
	RESET_SHIFT_TRANSFER_POLICY,
} from './shiftTransferPolicies.actions';

// utils
import constants from 'services/constants';
import moment from 'moment';

const defaultState = {
	data: {
		shiftTransferPolicy: {
			activeFrom: moment.utc().format(constants.shortDate),
			activeTo: '9999-12-31',
		},
		originalShiftTransferPolicy: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_SHIFT_TRANSFER_POLICY: {
			return {
				...state,
				data: {
					shiftTransferPolicy: payload,
					originalShiftTransferPolicy: payload,
				},
			};
		}

		case UPDATE_SHIFT_TRANSFER_POLICY: {
			return {
				...state,
				data: {
					...state.data,
					shiftTransferPolicy: {
						...state.data.shiftTransferPolicy,
						...payload,
					},
				},
			};
		}

		case RESET_SHIFT_TRANSFER_POLICY: {
			return defaultState;
		}

		default:
			return state;
	}
}

export default reducer;
