export default {
	MODAL_TITLE: 'Welcome! You have shifts to approve',
	CLOSE: 'Close',
	MODAL_TIP:
		'The following employees have worked additional time outside planned hours. Please verify that this is with your approval',
	CLOCKIN_DEVIATION_APPROVED: 'Clockin deviation approved',
	CLOCKIN_DEVIATION_DECLINED: 'Clockin deviation declined',
	APPROVED: 'Approved',
	DECLINED: 'Declined',
	APPROVE: 'Approve',
	DECLINE: 'Decline',
	ALL_DEVIATIONS_NEED_TO_BE_ADDRESSED:
		'All deviations need to be addressed before proceeding',

	// table list actions
	TABLE_LIST_NAME: 'Name',
	TABLE_LIST_DATE: 'Date',
	TABLE_LIST_SHIFT: 'Shift',
	TABLE_LIST_WORKPLACE: 'Workplace',
	TABLE_LIST_DEVIATION_REASON_TYPE: 'Deviation reason type',
	TABLE_LIST_EVENT: 'Event',
};
