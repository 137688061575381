export default {
	TRAININGS_EXAMS: 'Trainings and exams',
	EMPLOYEE_DETAILS_TOOLTIP: 'Click to view juicer details.',
	TRAININGS_EXAMS_TOOLTIP: 'Click to view trainings and exams',
	SAVE_CHANGES: 'Save changes',
	EMAIL: 'Email: ',
	MOBILE: 'Mobile: ',
	PIN: 'Pin: ',
	SENORITY: 'Senority: ',
	NO_MONEYBALL_POSITION: `You don't have a Moneyball position`,
	CONTACT_HR_FOR_DETAILS: 'Contact HR for details',
	TRAININGS_EXAMS_TABLE: 'Trainings and exams',
	ADD_REVIEW: 'Add review',
	ADD_REVIEW_TOOLTIP: 'Add grade or review.',
	ADD_GRADE: 'Add grade',
	ADD_REVIEW_CONFIRM_LABEL: 'Submit review',
	ADD_GRADE_CONFIRM_LABEL: 'Submit grade',
	MODAL_GRADE_INPUT: 'Grade',
	MODAL_REVIEW_INPUT: 'Review',
	CLOSE_MODAL_BUTTON_TEXT: 'Close',
	MODAL_GRADE_INPUT_PLACEHOLDER: 'Select a grade',
	MODAL_REVIEW_INPUT_PLACEHOLDER: 'Write a review'
};
