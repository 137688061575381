export const SET_EMPLOYMENT = 'HR/EMPLOYMENT_DETAILS/SET_EMPLOYMENT';

export const RESET_EMPLOYMENT_POSITIONS = 'HR/EMPLOYMENT_DETAILS/RESET_EMPLOYMENT_POSITIONS';
export const SET_EMPLOYMENT_POSITIONS = 'HR/EMPLOYMENT_DETAILS/SET_EMPLOYMENT_POSITIONS';
export const UPDATE_EMPLOYMENT_POSITIONS = 'HR/EMPLOYMENT_DETAILS/UPDATE_EMPLOYMENT_POSITIONS';

/**
 * @param {object} payload
 */
export function setEmployment(payload) {
	return {
		type: SET_EMPLOYMENT,
		payload,
	};
}
/**
 * @param {object} payload
 */
export function setEmploymentPosition(payload) {
	return {
		type: SET_EMPLOYMENT_POSITIONS,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function updateEmploymentPosition(payload) {
	return {
		type: UPDATE_EMPLOYMENT_POSITIONS,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetEmploymentPosition() {
	return {
		type: RESET_EMPLOYMENT_POSITIONS,
	};
}
