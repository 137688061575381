export default {
	TITLE: 'Store access types',
	STORE_ACCESS_TYPE_ADDED: 'Store Access Types added!',
	STORE_ACCESS_TYPE_EDITED: 'Store Access Type edited!',
	STORE_ACCESS_TYPES_EDITED: 'Store Access Types edited!',
	STORE_ACCESS_TYPE_DELETED: 'Store Access Type deleted!',

	// batch form
	MODAL_BATCH_STEP_LIST_TITLE: 'Create access types',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add app allowances',
};
