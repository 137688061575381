export const UPDATE_BATCH_FORM =
	'SALES_CONFIGURATION_WORKPLACE_POS_CONFIGURATION/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'SALES_CONFIGURATION_WORKPLACE_POS_CONFIGURATION/ADD_BATCH_LIST';
export const ADD_BATCH_LIST_DUPLICATE =
	'SALES_CONFIGURATION_WORKPLACE_POS_CONFIGURATION/ADD_BATCH_LIST_DUPLICATE';
export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_WORKPLACE_POS_CONFIGURATION/EDIT_BATCH_LIST_ITEM';
export const REVERT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_WORKPLACE_POS_CONFIGURATION/REVERT_BATCH_LIST_ITEM';
export const EDIT_BATCH_LIST_ITEMS =
	'SALES_CONFIGURATION_WORKPLACE_POS_CONFIGURATION/EDIT_BATCH_LIST_ITEMS';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_WORKPLACE_POS_CONFIGURATION/REMOVE_BATCH_LIST_ITEM';

export const SET_WORKPLACE_POS_CONFIGURATION =
	'SALES_CONFIGURATION_WORKPLACE_POS_CONFIGURATION/SET_WORKPLACE_POS_CONFIGURATION';
export const UPDATE_WORKPLACE_POS_CONFIGURATION =
	'SALES_CONFIGURATION_WORKPLACE_POS_CONFIGURATION/UPDATE_WORKPLACE_POS_CONFIGURATION';
export const SET_WORKPLACE_TO_POS_CONFIGURATION_DATE_FILTER =
	'SALES_CONFIGURATION_WORKPLACE_POS_CONFIGURATION/SET_WORKPLACE_TO_POS_CONFIGURATION_DATE_FILTER';
export const SET_WORKPLACE_TO_POS_CONFIGURATION_DATE =
	'SALES_CONFIGURATION_WORKPLACE_POS_CONFIGURATION/SET_WORKPLACE_TO_POS_CONFIGURATION_DATE';
export const RESET_STATE =
	'SALES_CONFIGURATION_WORKPLACE_POS_CONFIGURATION/RESET_STATE';

export function setWorkplacePosConfiguration(payload) {
	return {
		type: SET_WORKPLACE_POS_CONFIGURATION,
		payload,
	};
}

export function updateWorkplacePosConfiguration(payload) {
	return {
		type: UPDATE_WORKPLACE_POS_CONFIGURATION,
		payload,
	};
}

export function setWorkplaceToPosConfigurationDateFilter(payload) {
	return {
		type: SET_WORKPLACE_TO_POS_CONFIGURATION_DATE_FILTER,
		payload,
	};
}

export function setWorkplaceToPosConfigurationDate(payload) {
	return {
		type: SET_WORKPLACE_TO_POS_CONFIGURATION_DATE,
		payload,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function addBatchListDuplicate(payload) {
	return {
		type: ADD_BATCH_LIST_DUPLICATE,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function revertBatchListItem(payload) {
	return {
		type: REVERT_BATCH_LIST_ITEM,
		payload,
	};
}

export function editBatchListItems(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEMS,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}
