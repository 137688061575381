export const RESET_INGREDIENT_VARIATIONS =
	'SALES_CONFIGURATION/RESET_INGREDIENT_VARIATIONS';
export const SET_INGREDIENT_VARIATIONS =
	'SALES_CONFIGURATION/SET_INGREDIENT_VARIATION';
export const UPDATE_INGREDIENT_VARIATION =
	'SALES_CONFIGURATION/UPDATE_INGREDIENT_VARIATION';

/**
 * @param {object} payload
 */
export function setIngredientVariations(payload) {
	return {
		type: SET_INGREDIENT_VARIATIONS,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateIngredientVariation(payload) {
	return {
		type: UPDATE_INGREDIENT_VARIATION,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET_INGREDIENT_VARIATIONS
	};
}