import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './addonRecommendations.phrases';

/**
 * @function fetchAddonRecommendations
 * @description Fetch Product variant addons
 * @param {number} addonId
 * @param {object} state
 */
export function fetchAddonRecommendations(addonId, state) {
	const preFilter = `:addon.id=='${addonId}'`;
	const filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter
	};

	return get('/product/addon_recommendations', params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addAddonRecommendations
 * @description adds product addon recommendation
 */
export function addAddonRecommendations(data) {
	return post(`/product/addon_recommendations`, data)
		.then(res => {
			setFeedback(phrases.ADDON_RECOMMENDATION_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editAddonRecommendations
 * @description edits product addon recommendation
 */
export function editAddonRecommendations(id, payload) {
	return post(`/product/addon_recommendations/${id}`, payload)
		.then(res => {
			setFeedback(phrases.ADDON_RECOMMENDATION_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteAddonRecommendations
 * @description deletes product addon recommendation
 */
export function deleteAddonRecommendations(productVariantId) {
	return remove(`/product/addon_recommendations/${productVariantId}`)
		.then(res => {
			setFeedback(phrases.ADDON_RECOMMENDATION_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
