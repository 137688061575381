'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';

// different batch views
import ProductBundleRulesBatchStepList from './components/productBundleRulesBatchStepList';

// phrases/ constants
import phrases from './../../productBundleRules.phrases';

// loash
import _get from 'lodash/get';

class ProductBundleRulesModalBatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};
	}

	_batchSubmit() {
		const {
			batchListData,
			handleClose,
			batchAddData,
			productBundleId,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchListData.map((entry) => {
			return {
				bundle: productBundleId,
				global_bundle_rule: _get(entry, 'globalBundleRule.value.id', null),
			};
		});

		batchAddData({ batch: payload })
			.then(() => {
				handleClose();

				this.setState(() => ({
					loadingModal: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	render() {
		const {
			modalVisible,
			handleClose,
			batchListData,
			editBatchListItem,
			removeBatchListItem,
			addBatchListItemRow,
		} = this.props;

		const steps = [
			{
				component: (
					<ProductBundleRulesBatchStepList
						batchListData={batchListData}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
						loyaltyProductUrl={this.loyaltyProductUrl}
						addBatchListItemRow={addBatchListItemRow}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={handleClose}
					/>
				)}
			</>
		);
	}
}

ProductBundleRulesModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	batchAddData: PropTypes.func,
	productBundleId: PropTypes.number,
};

export default ProductBundleRulesModalBatch;
