export default {
	TITLE: 'Waiting time performance grids',
	WAITING_TIME_PERFORMANCE_GRID_ENTRIES_TITLE: 'Entries',
	WORKPLACE_WAITING_TIME_PERFORMANCE_GRIDS_TITLE: 'Workplace grids',
	WAITING_TIME_PERFORMANCE_GRID_ADDED: 'Waiting time performance grids added!',
	WAITING_TIME_PERFORMANCE_GRID_EDITED: 'Waiting time performance grid edited!',
	WAITING_TIME_PERFORMANCE_GRIDS_EDITED:
		'Waiting time performance grids edited!',
	WAITING_TIME_PERFORMANCE_GRID_DELETED:
		'Waiting time performance grid deleted!',

	// batch form
	MODAL_BATCH_STEP_LIST_TITLE: 'Add waiting time performance grids',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',

	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add entries',
};
