// src/utils/AuthService.js
import { validAccess } from 'accessControl';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { isProduction } from 'environment';
import { store } from 'appState';

/**
 *
 * @function track
 * @description Add tracking to logrocket, but depending on the user.
 * @param {*} event - event to be tracked at logrocket
 */
export default function trackWithLogRocket(event) {
	if (validAccess(undefined, ['Shiftplan Manager', 'Shiftplan Admin']))
		LogRocket.track(event);
}

export function initLogrocket() {
	// if not in production, don't initialize
	if (!isProduction()) return;

	// wait for new user data to be set before initializing logrocket
	setTimeout(() => {
		// if shiftplanner manager or above, initialize
		const managerRole = validAccess(undefined, [
			'Shiftplan Manager',
			'Shiftplan Admin',
		]);

		// if juicer, sample every other juicer
		const juicerId = store.getState()?.userData?.user?.user?.person?.id ?? null;

		if ((juicerId && juicerId % 2 === 0) || managerRole) {
			LogRocket.init('ydjtdv/joe-and-the-juice', {
				release: __webpack_hash__,
			});
			setupLogRocketReact(LogRocket);

			console.info('Initialized LogRocket');
		}
	}, 10000);
}
