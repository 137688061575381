import React from 'react';

// redux
import { useAppDispatch, useAppSelector } from './../../../../../appState/storeHooks';
import { setUiData } from './../store/removeShiftsFromEmployee.actions';

// interfaces
import { SelectOption } from 'types/select';
import { Moment } from 'moment';

// components
import { Box } from '@mui/material';
import { InputCollectionSelect, SingleDatePickerInput } from 'dumb';
import getEmploymentColumns from 'reactDataWrapperColumns/hr/employments.columns';
import getWorkplaceColumns from 'reactDataWrapperColumns/administration/workplaces.columns';

// misc
import phrases from './../removeShiftsFromEmployee.phrases';
import constants from 'services/constants';
import moment from 'moment';

const RemoveShiftsFromEmployeePageOne = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const {
		metadata: { personId },
		uiData: { workplace, date, employment },
	} = useAppSelector((state) => state.removeShiftsFromEmployee);

	const handleSetWorkplace = (_: string, workplace: SelectOption | null): void => dispatch(setUiData({ workplace }));
	const handleSetEmployee = (_: string, employment: SelectOption | null): void => dispatch(setUiData({ employment }));
	const handleSetDate = (date: Moment): void => dispatch(setUiData({ date }));

	return (
		<Box>
			<InputCollectionSelect
				id="workplace"
				label={phrases.WORKPLACE_LABEL}
				placeholder={phrases.WORKPLACE_PLACEHOLDER}
				value={workplace}
				handleChange={handleSetWorkplace}
				cache
				apiPath="/organization/workplaces"
				params={{
					limit: 30,
				}}
				optionFormat={(entry: Record<string, any>): SelectOption => ({
					value: entry,
					label: entry.name,
				})}
				inputFilterFormat={(input: string): string => `:name=like='%${input}%'`}
				tableColumns={getWorkplaceColumns()}
				tableTitle={phrases.WORKPLACE_TABLE_TITLE}
				tableReduxKey="remove-shifts/organization/workplaces"
			/>

			<Box mt={1}>
				<InputCollectionSelect
					id="employment"
					label={phrases.EMPLOYMENT_LABEL}
					placeholder={phrases.EMPLOYMENT_PLACEHOLDER}
					value={employment}
					handleChange={handleSetEmployee}
					cache
					apiPath="/hr/employments"
					params={{
						limit: 30,
						filter: `:person.id=='${personId}';:ended=ge='${moment.utc().format(constants.shortDate)}'`,
					}}
					optionFormat={(entry: Record<string, any>): SelectOption => ({
						value: entry,
						label: `${entry.company?.name ?? ''} - ${entry.current_moneyball_sub_position?.description ?? ''}`,
					})}
					inputFilterFormat={(input: string): string => `:name=like='%${input}%'`}
					tableColumns={getEmploymentColumns()}
					tableReduxKey="remove-shifts/hr/employments"
					tableTitle={phrases.EMPLOYMENT_TABLE_TITLE}
				/>
			</Box>

			<Box mt={1}>
				<SingleDatePickerInput
					id="date"
					label={phrases.DATE_LABEL}
					placeholder={phrases.DATE_PLACEHOLDER}
					onChange={handleSetDate}
					selectedDate={date?.format(constants.shortDate) ?? ''}
					noClockButton
				/>
			</Box>
		</Box>
	);
};

export default RemoveShiftsFromEmployeePageOne;
