'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { connectWithStore } from 'appState';

import Hotdamnbar from 'hotdamnbar';

import Report from './report.component';
import ReportPrintView from './report.printView.component';

import './report.css';

class ReportContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			printViewActive: false,
			recipients: [],
		};

		this.methods = {
			onHotbarChange: this.onHotbarChange.bind(this),
			onPrintViewToggle: this.onPrintViewToggle.bind(this),
		};
	}

	onPrintViewToggle() {
		const { report } = this.props;
		const { printViewActive } = this.state;

		// Get email recipients
		!printViewActive &&
			report.getEmailData() &&
			report
				.getEmailData()
				.getToMailingList()
				.then((recipients) => this.setState(() => ({ recipients })));

		// Enable print state
		this.setState((prevState) => ({
			printViewActive: !prevState.printViewActive,
		}));
	}

	onHotbarChange(event) {
		// Initiate loading and reset report
		this.setState(() => ({ loading: true }));

		// Treat hotbar changes
		this.props.report.handleHotbarChange(event).then(() => {
			// Update report and stop loading
			this.setState(() => ({ loading: false }));
		});
	}

	render() {
		const { report, tables, metadata, modifierClassName } = this.props;

		const { loading, printViewActive, recipients } = this.state;

		// Default is empty report view
		let reportView = null;

		const emailData = report.getEmailData();

		// If report is correct and has contents, return report and report print view
		// otherwise return no result information

		if (metadata !== null) {
			// Set report view
			reportView =
				tables == null ? (
					<h4 className="report-old__no-data-error">
						{report.getPhrases().NO_RESULT}
					</h4>
				) : (
					[
						<Report
							key="report"
							components={report.getComponents()}
							methods={this.methods}
							phrases={report.getPhrases()}
							report={report}
							metadata={metadata}
							tables={tables}
						/>,
						<ReportPrintView
							active={printViewActive}
							handleClose={this.methods.onPrintViewToggle}
							showMailToComponent
							showXLSX
							key="reportPrintView"
							components={report.getComponents()}
							methods={this.methods}
							phrases={report.getPhrases()}
							report={report}
							metadata={metadata}
							modifierClassName={modifierClassName}
							tables={tables}
							emails={recipients}
							emailSubject={emailData ? emailData.getSubject(metadata) : ''}
							emailBody={emailData ? emailData.getBody(metadata) : ''}
						/>,
					]
				);
		}

		return (
			<div className={`report-old-wrapper ${report.name}`}>
				{report.getHotbar() && (
					<Hotdamnbar
						onChange={this.methods.onHotbarChange}
						hotbar={report.getHotbar()}
						loading={loading}
					/>
				)}
				{reportView}
			</div>
		);
	}
}

ReportContainer.propTypes = {
	report: PropTypes.object.isRequired,
	modifierClassName: PropTypes.string,
};

ReportContainer.defaultProps = {
	modifierClassName: '',
};

const mapStateToPropsFactory = (initialStore, ownProps) => (store) => ({
	metadata: _get(
		store,
		`report.reports[${ownProps.report.name}].metadata`,
		null
	),
	tables: _get(store, `report.reports[${ownProps.report.name}].tables`, null),
});

export default connectWithStore(ReportContainer, mapStateToPropsFactory);
