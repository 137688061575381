'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
const __endpoints = require('endpoints.service');

export default class CountryToCurrencyList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'currency',
				type: 'select',
				canAdd: true,
				required: true,
				reference: {
					key: 'code',
					searchFields: ['code'],
					label: '<%= code %>',
					list: '/administration/currencies',
				},
			},
			{
				name: 'start_date',
				type: 'date',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'end_date',
				type: 'date',
				canAdd: true,
				canEdit: true,
			},
		];
		this.settings = {
			name: 'CountryToCurrencyList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			canCollapse: true,
			alwaysRefresh: true,
			translationPath: 'ADMINISTRATION.COUNTRY_TO_CURRENCY_RELATIONS',
			endpoint:
				__endpoints.collection.administration.country_to_currency_relations,
		};
		this.init();
		return this;
	}
}
