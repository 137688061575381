import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './line-header.css';

export default class LineHeader extends PureComponent {
	render() {
		const { children, className } = this.props;

		const classN = classnames('j-line-header', {
			[`${className}`]: className
		});

		return (
			<h3 className={classN}>
				<span className="j-line-header__header">{children}</span>
				<span className="j-line-header__hr" />
			</h3>
		);
	}
}

LineHeader.propTypes = {
	// shared props
	className: PropTypes.string,
	children: PropTypes.any.isRequired
};
