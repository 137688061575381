export const RESET_FILTER_GROUP =
	'SALES_CONFIGURATION_FILTER_GROUPS/RESET_FILTER_GROUP';
export const SET_FILTER_GROUP =
	'SALES_CONFIGURATION_FILTER_GROUPS/SET_FILTER_GROUP';
export const UPDATE_FILTER_GROUP =
	'SALES_CONFIGURATION_FILTER_GROUPS/UPDATE_FILTER_GROUP';

export const UPDATE_BATCH_FORM =
	'SALES_CONFIGURATION_FILTER_GROUPS/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'SALES_CONFIGURATION_FILTER_GROUPS/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_FILTER_GROUPS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_FILTER_GROUPS/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'SALES_CONFIGURATION_FILTER_GROUPS/ADD_BATCH_LIST_ITEM_ROW';

/**
 * @param {object} payload
 */
export function setWorkplaceExpectedWaitingTimes(payload) {
	return {
		type: SET_FILTER_GROUP,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function updateWorkplaceExpectedWaitingTimes(payload) {
	return {
		type: UPDATE_FILTER_GROUP,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetFilterGroup() {
	return {
		type: RESET_FILTER_GROUP,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}
