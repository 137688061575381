'use strict';

export const ADD_FILE_TYPE_TEMPLATE = 'FILE_UPLOAD/ADD_FILE_TYPE_TEMPLATE';
export const ADD_FILE_TYPE = 'FILE_UPLOAD/ADD_FILE_TYPE';
export const RESET_FILE_TYPES = 'FILE_UPLOAD/RESET_FILE_TYPES';
export const SET_FILE_COUNT = 'FILE_UPLOAD/SET_FILE_COUNT';


/**
 * @param {string} fileUploadName
 * @param {object} fileTypeTemplate
 */
export function addFileTypeTemplate(fileUploadName, fileTypeTemplate) {
	return {
		type: ADD_FILE_TYPE_TEMPLATE,
		payload: {
			fileUploadName,
			fileTypeTemplate
		}
	};
}

/**
 * @param {string} fileUploadName
 * @param {string} fileTypeIdentifier
 * @param {object} fileType
 */
export function addFileType(fileUploadName, fileTypeIdentifier, fileType) {
	return {
		type: ADD_FILE_TYPE,
		payload: {
			fileUploadName,
			fileTypeIdentifier,
			fileType
		}
	};
}

/**
 * @param {string} fileUploadName
 * @param {number} fileCount
 */
export function setFileCount(fileUploadName, fileCount) {
	return {
		type: SET_FILE_COUNT,
		payload: {
			fileUploadName,
			fileCount
		}
	};
}

/**
 * @param {string} fileUploadName
 * @param {string} fileTypeIdentifier
 * @param {object} fileType
 */
export function resetFileTypes(fileUploadName) {
	return {
		type: RESET_FILE_TYPES,
		payload: {
			fileUploadName
		}
	};
}