/**
 * Created by mikkelmarkvardsen on 24/10/2016.
 */
'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import './backdrop.css';

// Components
const BackDrop = ({ isOpen, zIndex, children, closeBackdrop }) => {
	const backdropStyles = {
		zIndex,
	};

	const renderWithChildren = (children, backdropStyles) => {
		return (
			<>
				<div
					style={backdropStyles}
					className="backdrop"
					onClick={() => closeBackdrop(false)}
				/>

				{children}
			</>
		);
	};

	return (
		<CSSTransition
			classNames="backdrop-transition"
			component="div"
			timeout={200}
			in={isOpen}
			mountOnEnter
			appear
			unmountOnExit>
			{children ? (
				renderWithChildren(children, backdropStyles)
			) : (
				<div
					style={backdropStyles}
					className="backdrop"
					onClick={() => closeBackdrop(false)}
				/>
			)}
		</CSSTransition>
	);
};

BackDrop.defaultProps = {
	isOpen: false,
	zIndex: null,
};

BackDrop.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
		zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	closeBackdrop: PropTypes.func,
};

export default BackDrop;
