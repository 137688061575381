export const RESET_NUTRITION_CATEGORY =
	'SALES_CONFIGURATION/RESET_NUTRITION_CATEGORY';
export const SET_NUTRITION_CATEGORY =
	'SALES_CONFIGURATION/SET_NUTRITION_CATEGORY';
export const UPDATE_NUTRITION_CATEGORY =
	'SALES_CONFIGURATION/UPDATE_NUTRITION_CATEGORY';

/**
 * @param {object} payload
 */
export function setNutritionCategory(payload) {
	return {
		type: SET_NUTRITION_CATEGORY,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateNutritionCategory(payload) {
	return {
		type: UPDATE_NUTRITION_CATEGORY,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET_NUTRITION_CATEGORY
	};
}