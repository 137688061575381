'use strict';

import React from 'react';

import PropTypes from 'prop-types';

import { Modal } from 'dumb';

function UserModal({
	isOpen,
	onConfirmClick,
	loading,
	onClose,
	header,
	content,
	confirmButtonLabel
}) {
	return (
		<Modal
			type="confirmation"
			className="table-editor-modal"
			isOpen={isOpen}
			onClose={onClose}
			loading={loading}
			onConfirmClick={onConfirmClick}
			confirmButtonLabel={confirmButtonLabel}
			header={header}>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<span>{content}</span>
			</div>
		</Modal>
	);
}

UserModal.propTypes = {
	isOpen: PropTypes.bool,
	onConfirmClick: PropTypes.func,
	loading: PropTypes.bool,
	onClose: PropTypes.func,
	header: PropTypes.string,
	content: PropTypes.string,
	confirmButtonLabel: PropTypes.string
};

export default UserModal;
