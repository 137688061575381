import {
	SET_PRODUCT_SET_OPTION,
	UPDATE_PRODUCT_SET_OPTION,
	RESET_PRODUCT_SET_OPTIONS,
	UPDATE_BATCH,
	RESET_BATCH
} from './productSetOptions.actions';

const defaultState = {
	ui: {},
	data: {
		defaultProductSetOptions: {},
		batchOptions: {
			product_variant: []
		}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_PRODUCT_SET_OPTION: {
		return {
			...state,
			data: {
				...state.data,
				defaultProductSetOptions: payload
			}
		};
	}

	case UPDATE_PRODUCT_SET_OPTION: {
		return {
			...state,
			data: {
				...state.data,
				defaultProductSetOptions: {
					...state.data.defaultProductSetOptions,
					product_variant: payload.product_variant
				}
			}
		};
	}

	case RESET_PRODUCT_SET_OPTIONS: {
		return {
			...state,
			data: {
				...state.data,
				defaultProductSetOptions: {}
			}
		};
	}

	case UPDATE_BATCH: {
		return {
			...state,
			data: {
				...state.data,
				batchOptions: {
					...state.data.batchOptions,
					...payload
				}
			}
		};
	}

	case RESET_BATCH: {
		return {
			...state,
			data: {
				...state.data,
				batchOptions: defaultState.data.batchOptions
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
