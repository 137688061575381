import _get from 'lodash/get';

/**
 * @function formatErrorMessage
 * @description Takes an error object thrown from the api and formats all messages in the error object so its readable
 * @param {Object} response
 * @return {String} errorMessage
 *
 *
 * @example
 * And error object
 *  const errorResponse = {
 * 		"code":400,
 * 		"status":"error",
 * 		"data":[
 *	 		{
 * 				"shift_employees":"Element with index '0' failed to be created with the following message: 'Employment has already a shift for the given period'"
 * 			}
 * 		],
 * 		"message":"Domain object was not valid"
 * 	}
 *
 * const formattedError = formatErrorMessage(errorResponse);
 *
 * // Output formatted error
 * console.log(formattedError)	 //Element with index '0' failed to be created with the following message: 'Employment has already a shift for the given period' (shift_employees)
 */
export default function formatErrorMessage(response) {
	// Error msg
	let errorMessage;
	const errorArray = _get(response, 'data', []);

	// Collect errors
	if (errorArray && errorArray.length > 0) {
		errorMessage =
			errorArray.map(error =>
				Object.keys(error).map(errorLine =>
					`${error[errorLine]} (${errorLine})`)
			).join(',');
	}
	else errorMessage = `${response.code}: ${response.message}`;
	return errorMessage;
}