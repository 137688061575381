import { store } from 'appState';

export const SET_SHOW_MODAL = 'INVALID_TENANT_ACCOUNT/SET_SHOW_MODAL';

export function setShowModal(payload) {
	return {
		type: SET_SHOW_MODAL,
		payload,
	};
}

// action dispatchers
export function dispatchSetShowModal(payload) {
	store.dispatch(setShowModal(payload));
}
