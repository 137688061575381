export const RESET_FILTER = 'SALES_CONFIGURATION_FILTERS/RESET_FILTER';
export const SET_FILTER = 'SALES_CONFIGURATION_FILTERS/SET_FILTER';
export const UPDATE_FILTER = 'SALES_CONFIGURATION_FILTERS/UPDATE_FILTER';

export const UPDATE_BATCH_FORM =
	'SALES_CONFIGURATION_FILTERS/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'SALES_CONFIGURATION_FILTERS/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_FILTERS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_FILTERS/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'SALES_CONFIGURATION_FILTERS/ADD_BATCH_LIST_ITEM_ROW';

/**
 * @param {object} payload
 */
export function setFilter(payload) {
	return {
		type: SET_FILTER,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function updateFilter(payload) {
	return {
		type: UPDATE_FILTER,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetFilter() {
	return {
		type: RESET_FILTER,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}
