'use strict';

/**
 * @function sortByColumn
 * @memberOf SERVICES.__models
 * @description
 * Sorts collection based on column (header), either ascending or descending or none
 * @param  {object} header  header (column) to sort by
 * @param  {string} sortOrder  order to sort by
 */

import _has from 'lodash/has';
import _each from 'lodash/each';
import { set as setFeedback } from 'feedback.vanilla.service';

module.exports = function (header, sortOrder) {
	return new Promise((resolve, reject) => {
		let order;
		let sort;
		let absolute = false;
		let sortHeaders = false;

		if (header.canSort) {
			// Set order to sort
			switch (sortOrder) {
				case 'asc':
					order = '+';
					break;
				case 'desc':
					order = '-';
					break;
				default:
					order = '';
					break;
			}

			// Check for any sorting options
			if (_has(header, 'sortOptions')) {
				// Check for sort headers
				if (_has(header.sortOptions, 'sortHeaders')) sortHeaders = true;

				// Check for sort absolute
				if (header.sortOptions.absolute) absolute = true;
			}

			// Find sort base (based on sortHeaders or not)
			if (sortHeaders) {
				let sortOptions = '';
				_each(header.sortOptions.sortHeaders, function (option) {
					// Find columnName based on if header has reference to child resource or not
					const columnName = _has(header, 'reference') ? header.name + '.' + option : option;
					sortOptions += ':' + columnName + order + ',';
				});
				sortOptions = sortOptions.slice(0, -1);
				sort = order.length > 0 ? sortOptions : '';
			} else {
				// Find columnName based on if header has reference to child resource or not
				// Is also based on if there is a query included in the reference.
				let columnName;
				if (_has(header, 'reference')) {
					columnName = _has(header.reference, 'query')
						? header.name + '.' + header.reference.query + '.' + header.reference.key
						: header.name + '.' + header.reference.key;
				} else columnName = header.name;

				// Set sort
				sort = order.length > 0 ? ':' + columnName : '';

				// Apply different options
				// Absolute?
				sort = absolute && order.length > 0 ? '$abs(' + sort + ')' : sort;

				// Apply order
				sort = sort + order;
			}

			// Set sort param
			this.setParamSort(sort);
		}

		// Get collection again with updated filters
		this.loadCollection().then(
			function (collectionData) {
				resolve(collectionData);
			},
			function (response) {
				setFeedback(response.code + ': ' + response.message, 0);
				reject(response);
			}
		);
	});
};
