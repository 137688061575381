import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import "./list-items.css";

export default class ListItems extends PureComponent {
	render() {
		const { children, className } = this.props;

		const classNames = classnames('j-list-items', {
			[`${className}`]: className
		});

		return (
			<ul className={classNames}>
				{React.Children.map(children, (child, i) => (
					<li className="j-list-items-item" key={i}>
						{child}
					</li>
				))}
			</ul>
		);
	}
}

ListItems.propTypes = {
	// shared props
	children: PropTypes.arrayOf(Object).isRequired,
	className: PropTypes.string
};
