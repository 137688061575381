export const RESET_SALARY_COMPONENT = 'SALARY/RESET_SALARY_COMPONENT';
export const SET_SALARY_COMPONENT = 'SALARY/SET_SALARY_COMPONENT';
export const UPDATE_SALARY_COMPONENT = 'SALARY/UPDATE_SALARY_COMPONENT';
export const UPDATE_BATCH = 'SALARY/UPDATE_BATCH';
export const RESET_BATCH = 'SALARY/RESET_BATCH';
export const BATCH_LIST_ADD = 'SALARY/BATCH_LIST_ADD';
export const BATCH_LIST_EDIT_ITEM = 'SALARY/BATCH_LIST_EDIT_ITEM';
export const BATCH_LIST_REMOVE_ITEM = 'SALARY/BATCH_LIST_REMOVE_ITEM';

/**
 * @param {object} payload
 */
export function setSalaryComponent(payload) {
	return {
		type: SET_SALARY_COMPONENT,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateSalaryComponent(payload) {
	return {
		type: UPDATE_SALARY_COMPONENT,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetSalaryComponent() {
	return {
		type: RESET_SALARY_COMPONENT
	};
}

/**
 * @param {object} payload
 */
export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH,
		payload
	};
}

/**
 * @param {object} payload
 */
export function addBatchList(payload) {
	return {
		type: BATCH_LIST_ADD,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetBatch() {
	return {
		type: RESET_BATCH
	};
}

/**
 * @param {object} payload
 */
export function editBatchListItem(payload) {
	return {
		type: BATCH_LIST_EDIT_ITEM,
		payload
	};
}

/**
 * @param {string} payload ID
 */
export function removeBatchListItem(payload) {
	return {
		type: BATCH_LIST_REMOVE_ITEM,
		payload
	};
}
