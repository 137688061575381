export default {
	NAME: 'Name',
	EMAIL: 'Email',
	FIELD: 'Field',
	WP2_VALUE: 'WP2	Value',
	FINAL: 'Final',
	BACK: 'Back',
	NEXT: 'Next',
	NO_FIELDS: 'No fields to fill out, you can proceed...',

	SEND_CONTRACT: 'Send Contract',
	SEND_APPENDIX: 'Send Appendix',
	TYPE_TITLE: 'Send contract or appendix',
	EMPLOYEE_FIELDS_TITLE: 'Employee fields',
	MANAGER_FIELDS_TITLE: 'Manager fields',
	CONTRACT_SENT: 'Contract sent!',
	APPENDIX_SENT: 'Appendix sent!',
	NO_EMPLOYEE_ROLE_CONFIGURED: 'No employee role configured!',
	NO_MANAGER_ROLE_CONFIGURED: 'No manager role configured!',
	NO_ROLE_TIP_URL: 'Go to Salary -> DocuSign to configure roles properly',
	NOT_FOUND: 'Not found',
	RECEIVER_EMAIL: 'This document will be sent to email:',
	RECEIVER_SIGNING_GROUP: 'This document will be sent to signing group:',
};
