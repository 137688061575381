import {
	UPDATE_DATA,
	SET_DATA,
	RESET_DATA,
} from './posConfigurationTileLayouts.actions';

const defaultState = {
	data: {
		posConfigurationTileLayout: {},
		originalPosConfigurationTileLayout: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_DATA: {
			return {
				data: {
					...state.data,
					posConfigurationTileLayout: payload,
					originalPosConfigurationTileLayout: payload,
				},
			};
		}

		case UPDATE_DATA: {
			return {
				data: {
					...state.data,
					posConfigurationTileLayout: {
						...state.data.posConfigurationTileLayout,
						...payload,
					},
				},
			};
		}

		case RESET_DATA: {
			return {
				...defaultState,
			};
		}

		default:
			return state;
	}
}

export default reducer;
