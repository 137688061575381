export default {
	UPDATE_RATES: 'Update rates',
	UPDATE_SALARY_RATES: 'Update salary component rates',
	MARKET: 'Market',
	AMOUNT: 'Amount',
	NAME: 'Name',
	NEW_AMOUNT: 'New amount',
	AMOUNT_TYPE: 'Amount type',
	GLOBAL_SALARY_COMPONENT: 'Global Salary Component',
	MONEYBALL_SUB_POSITION: 'Moneyball sub position',
	RESET: 'Reset',
	REMOVE: 'Remove',
	ADD_NEW_SALARY_COMPONENT: 'Add new',
	FREQUENCY:'Frequency',
	FROM: 'From',
	TO: 'To',
	EXTERNAL_EXPORT_ID: 'External export ID',
	AFFECTIVE_TIME: 'affective time is before active time.'
};