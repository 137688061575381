import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { Text } from 'dumb';
import ShiftTransferStatus from './../../../../shiftTransferStatus';

// utils
import {
	getOperationlessApplicationDecisions,
	getApplicationDecisions,
} from './../../../utils';

// enums/ phrases
import { ShiftTransfer as enums } from 'services/enums';
import phrases from './../../../../../shiftTransferApproval.phrases';

class ShiftTransferApprovalMobileViewPageJuicerView extends Component {
	constructor(props) {
		super(props);

		this.handleShiftApplicationDecision = this.handleShiftApplicationDecision.bind(
			this
		);
	}

	componentDidMount() {
		const { shiftTransfer, setShiftTransferApplication } = this.props;

		// set all applications in the reducer (in this case it's only one, the one we want to accept or decline as a receiver of the shift)
		const applicationDecisions = getOperationlessApplicationDecisions({
			applications: shiftTransfer.applications,
		});

		setShiftTransferApplication(applicationDecisions);
	}

	handleShiftApplicationDecision(operation) {
		const {
			shiftTransfer,
			setShiftTransferApplication,
			applicationDecisions,
		} = this.props;

		const payload = {
			operation,
			id: shiftTransfer.applications?.[0]?.id,
		};

		// not really neccessary to use a same function for this but
		// its useful cause it will toggle any operation we choose to do
		const newApplicationDecisions = getApplicationDecisions({
			applicationDecisions,
			payload,
		});

		setShiftTransferApplication(newApplicationDecisions);
	}

	render() {
		const { shiftTransfer, applicationDecisions } = this.props;

		const applicationState = shiftTransfer.applications?.[0]?.state;
		const swapInteracted =
			applicationState !== enums.APPLICATIONS.AWAITING_APPLICANT_APPROVAL;

		const applicationId = shiftTransfer?.applications?.[0]?.id;
		const applicationDecisionApproved = applicationDecisions.some(
			(entryTwo) =>
				entryTwo.operation === enums.OPERATIONS.APPROVE_APPLICATION &&
				entryTwo.id === applicationId
		);
		const applicationDecisionDeclined = applicationDecisions.some(
			(entryTwo) =>
				entryTwo.operation === enums.OPERATIONS.DECLINE_APPLICATION &&
				entryTwo.id === applicationId
		);

		return (
			<div className="shift-transfer-approval-modal__mobile-view__actions-wrapper">
				{swapInteracted ? (
					<ShiftTransferStatus state={applicationState} />
				) : (
					<div className="shift-transfer-approval-modal__table-wrapper__row-cell">
						<label className="shift-transfer-approval-modal__table-wrapper__row-cell">
							<input
								id="radio-1"
								type="radio"
								value="Yes"
								name="yes"
								checked={applicationDecisionApproved}
								onChange={() =>
									this.handleShiftApplicationDecision(
										enums.OPERATIONS.APPROVE_APPLICATION
									)
								}
							/>{' '}
							<Text
								className="shift-transfer-approval-modal__table-wrapper__row-cell--left-margin"
								size="medium">
								{phrases.ACCEPT}
							</Text>
						</label>
						<label className="shift-transfer-approval-modal__table-wrapper__row-cell shift-transfer-approval-modal__table-wrapper__row-cell--left-margin-big">
							<input
								id="radio-2"
								type="radio"
								value="No"
								name="no"
								checked={applicationDecisionDeclined}
								onChange={() =>
									this.handleShiftApplicationDecision(
										enums.OPERATIONS.DECLINE_APPLICATION
									)
								}
							/>{' '}
							<Text
								className="shift-transfer-approval-modal__table-wrapper__row-cell--left-margin"
								size="medium">
								{phrases.DECLINE}
							</Text>
						</label>
					</div>
				)}
			</div>
		);
	}
}

ShiftTransferApprovalMobileViewPageJuicerView.propTypes = {
	shiftTransfer: PropTypes.object,
	setShiftTransferApplication: PropTypes.func,
	applicationDecisions: PropTypes.array,
};

export default ShiftTransferApprovalMobileViewPageJuicerView;
