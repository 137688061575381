export const RESET_REPORT = 'SHIFTPLANNER_CLOCKIN_REPORT/RESET_REPORT';
export const SET_REPORT = 'SHIFTPLANNER_CLOCKIN_REPORT/SET_REPORT';
export const SET_LOADER = 'SHIFTPLANNER_CLOCKIN_REPORT/SET_LOADER';

/**
 * @param {object} payload
 */
export function setCockingReport(payload) {
	return {
		type: SET_REPORT,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetClockingReport() {
	return {
		type: RESET_REPORT
	};
}

/**
 * @param {object} payload
 */
export function setLoader(payload) {
	return {
		type: SET_LOADER,
		payload
	};
}
