'use strict';

import ListModel from 'common/components/list/listModel.service';

// Services
const __endpoints = require('endpoints.service');

export default class IngredientCategoriesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
		];
		this.settings = {
			name: 'IngredientCategoriesList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			translationPath: 'SALE.INGREDIENT_CATEGORIES',
			endpoint: __endpoints.collection.product.ingredient_categories,
		};
		this.init();
		return this;
	}
}
