'use strict';

import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import React from 'react';

import TableDefault from './table.default.component';

const TableWrapper = props => {
	const { components, report, table, expandedTables, metadata, tables } = props;
	// Define table layout
	const component = components[table.meta.data.type];

	return component ? (
		<div
			className={
				component
					? cx('report-old__table-wrapper', component.wrapperClassName)
					: null
			}
			style={{ width: report.getMaximumWidth(tables) + 10 + 'px' }}>
			{component.getTitle(table) && (
				<h3>
					{component.getTitle(table)}
					{component.getMetaTitle(metadata) && (
						<span>{component.getMetaTitle(metadata)}</span>
					)}
				</h3>
			)}
			<TableDefault
				{...{
					...props,
					expandedTables,
					component,
					tableTitle: props.table.title
				}}
			/>
		</div>
	) : null;
};

TableWrapper.propTypes = {
	components: PropTypes.object.isRequired,
	expandedTables: PropTypes.object,
	report: PropTypes.object.isRequired,
	table: PropTypes.object.isRequired
};

const mapStateToPropsFactory = (initialStore, ownProps) => store => ({
	expandedTables: _get(
		store,
		`report.reports[${ownProps.report.name}].expandedTables`,
		{}
	)
});

export default connect(mapStateToPropsFactory)(TableWrapper);
