'use strict';

import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';
import IngredientCategoriesList from './services/ingredientCategoriesList.service.js';
import ProductCategoriesList from './services/productCategoriesList.service.js';
import SizeCategoriesList from './services/sizeCategoriesList.service.js';

const ingredientCategoriesListReduxKey = '/product/ingredient_categories';
const ingredientCategoriesList = new IngredientCategoriesList(
	ingredientCategoriesListReduxKey
);
const ingredientCategoriesListProps = ingredientCategoriesList.getProps();

const productCategoriesListReduxKey = '/product/product_categories';
const productCategoriesList = new ProductCategoriesList(
	productCategoriesListReduxKey
);
const productCategoriesListProps = productCategoriesList.getProps();

const sizeCategoriesListReduxKey = '/product/size_groups';
const sizeCategoriesList = new SizeCategoriesList(sizeCategoriesListReduxKey);
const sizeCategoriesListProps = sizeCategoriesList.getProps();

export function Categories() {
	useEffect(() => {
		ingredientCategoriesList.initView();
		productCategoriesList.initView();
		sizeCategoriesList.initView();
	}, []);

	return (
		<>
			<ListComponent
				reduxKey={ingredientCategoriesListReduxKey}
				{...ingredientCategoriesListProps}
			/>

			<ListComponent
				reduxKey={productCategoriesListReduxKey}
				{...productCategoriesListProps}
			/>

			<ListComponent
				reduxKey={sizeCategoriesListReduxKey}
				{...sizeCategoriesListProps}
			/>
		</>
	);
}
