import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './workforceNews.phrases';

/**
 * @function fetchWorkforceNews
 * @description Fetch all workforce news
 * @param {object} state
 */
export function fetchWorkforceNews(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get('/shiftplanning/workforce_news', params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editWorkforceNews
 * @description edits workforce news
 */
export function editWorkforceNews(data) {
	return post(`/shiftplanning/workforce_news/${data.id}`, data)
		.then(res => {
			setFeedback(phrases.WORKFORCE_NEWS_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function addWorkforceNews
 * @description adds workforce news
 */
export function addWorkforceNews(data) {
	return post('/shiftplanning/workforce_news', data)
		.then(res => {
			setFeedback(phrases.WORKFORCE_NEWS_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteWorkforceNews
 * @description deletes workforce news
 */
export function deleteWorkforceNews(productId) {
	return remove(`/shiftplanning/workforce_news/${productId}`)
		.then(res => {
			setFeedback(phrases.WORKFORCE_NEWS_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
