import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// redux
import { useDispatch, useSelector, batch } from 'react-redux';
import { setTenantOptions, resetData, setUiData } from './store/tenantTransferModal.actions';
import { setActiveTenant } from 'services/user/actions';
import { resetHotbars } from 'hotdamnbar/hotdamnbar.actions';
import {
	setDefaultEmploymentData,
	toggleModalVisible,
} from './../../../terminateEmployee/store/terminateEmployee.actions';

// components
import { ModalStepper } from 'dumb';
import TenantTransferModalStepOne from './components/tenantTransferModalStepOne';
import TenantTransferModalStepTwo from './components/tenantTransferModalStepTwo';
import TenantTransferModalStepThree from './components/tenantTransferModalStepThree';

// utils
import moment from 'moment';
import constants from 'services/constants';

// services
import { postTransferTenant, fetchTenantTransferOptions } from './tenantTransferModal.service';
import { getUserOptions } from './utils';

// phrases
import phrases from './tenantTransferModal.phrases';

// style
import './tenantTransferModal.css';

const TenantTransferModal = ({ employment, onClose }) => {
	const modalRef = useRef();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const {
		employmentTerminated,
		ui: {
			tenant,
			user,
			company,
			startDate,
			endDate,
			hasFoodPermit,
			inSalaryExport,
			salaryExportId,
			phone,
			email,
			phoneCountryCode,
			administrativeOnly,
			terminateCurrentEmployment,
		},
		tenantOptions,
	} = useSelector((store) => store.tenantTransfer);
	const { user: userFromAuth, activeTenant, tenants } = useSelector((state) => state.userData);

	const onStepOneNext = () => {
		if (!tenant) return;

		setLoading(true);

		const payload = {
			employmentId: employment.id,
			tenantId: tenant.value ?? '',
		};

		fetchTenantTransferOptions(payload)
			.then((res) => {
				dispatch(setTenantOptions(res?.data?.[0] ?? null));

				// deconstruct data to set in redux
				// eslint-disable-next-line camelcase
				const { users, person_phone_number, person_email_address } = res?.data?.[0] ?? {};

				// set user options
				const userSelectOptions = getUserOptions(users);

				// set the ui data in redux
				const uiDataObject = {
					...(userSelectOptions.length === 1 && { user: userSelectOptions[0] }),
					...(person_phone_number?.phone_number && { phone: person_phone_number.phone_number }),
					...(person_phone_number?.country_code && { phoneCountryCode: person_phone_number.country_code }),
					...(person_email_address?.email_address && { email: person_email_address.email_address }),
				};

				dispatch(setUiData(uiDataObject));

				modalRef.current.goToStep(2);
			})
			.finally(() => setLoading(false));
	};

	const handleStepTwoOnNext = () => {
		// if we selected to terminate employment, open the termination modal with pre-filled values and wait for that modal to do it's thing
		if (terminateCurrentEmployment) {
			dispatch(setDefaultEmploymentData(employment));
			dispatch(toggleModalVisible());
		} else onSubmit();
	};

	const onSubmit = () => {
		setLoading(true);

		const body = {
			user: user?.value,
			identity: {
				person: tenantOptions.identity?.person?.id ?? null,
				first_name: tenantOptions.identity?.first_name ?? '',
				last_name: tenantOptions.identity?.last_name ?? '',
				birth_date: tenantOptions.identity?.birth_date ?? '',
			},
			company: company?.value,
			started: moment.utc(startDate).format(constants.shortDate),
			ended: moment.utc(endDate).format(constants.shortDate),
			include_in_salary_export: inSalaryExport,
			has_food_permit: hasFoodPermit,
			administrative_only: administrativeOnly,
			salary_export_id: salaryExportId,
			person_phone_number: {
				country_code: phoneCountryCode,
				phone_number: phone,
			},
			person_email_address: {
				email_address: email,
			},
		};

		postTransferTenant({ tenantId: tenant?.value, body })
			.then(() => {
				setLoading(false);
				onClose();

				// apply a different tenant and relaod page
				if (activeTenant.id !== tenant?.value) handleApplyNewTenant();
			})
			.catch(() => setLoading(false));
	};
	const handleApplyNewTenant = () => {
		// find tenant in question
		const tenantToApply = tenants.find((t) => t.id === tenant?.value);

		if (!tenantToApply) return;

		batch(() => {
			dispatch(setActiveTenant(tenantToApply));
			dispatch(resetHotbars());
		});

		// Throwback, like a good ol' rock song
		setTimeout(() => {
			window.location.reload(true);
		}, 0);
	};

	const isStepTwoValid = !!user?.value && !!company?.value;
	const steps = [
		{
			component: <TenantTransferModalStepOne userId={userFromAuth?.user?.id ?? null} activeTenant={activeTenant} />,
			title: phrases.STEP_ONE_TITLE,
			isValid: !!tenant,
			onBack: onClose,
			onNext: onStepOneNext,
			loading,
			confirmButtonLabel: phrases.STEP_ONE_CONFIRM_BUTTON_LABEL,
			noScroll: false,
		},
		{
			component: <TenantTransferModalStepTwo tenantOptions={tenantOptions} employment={employment} />,
			title: phrases.STEP_TWO_TITLE,
			isValid: isStepTwoValid,
			onBack: () => modalRef.current.goToStep(1),
			onNext: handleStepTwoOnNext,
			loading,
			confirmButtonLabel: terminateCurrentEmployment
				? phrases.STEP_TWO_CONFIRM_BUTTON_LABEL_TERMINATE
				: phrases.STEP_TWO_CONFIRM_BUTTON_LABEL_DONT_TERMINATE,
			cancelButtonLabel: phrases.STEP_TWO_BACK,
			noScroll: false,
		},
		// this component shows after we try to termiante the employment
		{
			component: <TenantTransferModalStepThree />,
			title: phrases.STEP_THREE_TITLE,
			isValid: employmentTerminated === true,
			onBack: () => modalRef.current.goToStep(2),
			onNext: onSubmit,
			loading,
			confirmButtonLabel: phrases.STEP_THREE_SUBMIT,
			cancelButtonLabel: phrases.STEP_TWO_BACK,
			noScroll: false,
		},
	];

	useEffect(() => {
		return () => {
			dispatch(resetData());
		};
	}, [dispatch]);

	useEffect(() => {
		if (employmentTerminated !== undefined) modalRef.current.goToStep(3);
	}, [employmentTerminated]);

	return (
		<ModalStepper header={phrases.MODAL_TITLE} onClose={onClose} ref={modalRef} isOpen steps={steps} showStep={false} />
	);
};

TenantTransferModal.propTypes = {
	employment: PropTypes.object,
	onClose: PropTypes.func,
};

export default TenantTransferModal;
