'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Button, Icon, InputCollectionSelect, Input } from 'dumb';
import IngredientAllergensModalBatch from './components/batch/ingredientAllergensModalBatch';

import {
	// batch stuff
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	setIngredientAllergen,
	updateIngredientAllergen,
	resetState,
} from './store/ingredientAllergens.actions';

// utils
import phrases from './ingredientAllergens.phrases';
import enums from './ingredientAllergens.enums';
import { getSelectOptionsFromEnums, getEditedValues } from 'services/utils';

// services
import {
	fetchIngredientAllergenes,
	editIngredientAllergen,
	editIngredientAllergens,
	addIngredientAllergenes,
	deleteIngredientAllergenes,
} from './ingredientAllergens.service';

// lodash
import _get from 'lodash/get';

class IngredientAllergens extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.getActionButtons = this.getActionButtons.bind(this);
		this.toggleAddModal = this.toggleAddModal.bind(this);
		this.editMultiple = this.editMultiple.bind(this);

		this.state = {
			showAddModal: false,
		};

		this.fetchData = this.fetchData.bind(this);

		this.columns = [
			{
				Header: 'Ingredient Name',
				id: 'ingredient',
				accessor: (d) => _get(d, 'ingredient.name', ''),
				filterPath: ':ingredient.name',
			},
			{
				Header: 'Degree of',
				id: 'degree_of',
				accessor: (d) => _get(d, 'degree_of', ''),
				filterPath: ':degree_of',
			},
			{
				Header: 'Allergen',
				id: 'allergen',
				accessor: (d) => _get(d, 'allergen.name', ''),
				filterPath: ':allergen.name',
			},
			...(this.props.useGlobalResource
				? []
				: [
						{
							Header: 'Market',
							id: 'market',
							accessor: (d) => _get(d, 'market.name', ''),
							filterPath: ':market.name',
						},
				  ]),
		];
	}

	editEntry() {
		const { ingredientAllergen, defaultIngredientAllergen, useGlobalResource } = this.props;

		const editedValues = getEditedValues({
			oldData: defaultIngredientAllergen,
			newData: ingredientAllergen,
		});

		const payload = {
			...(editedValues.degree && {
				degree_of: editedValues.degree,
			}),
			...(editedValues.allergen && {
				allergen: editedValues.allergen,
			}),
		};

		return editIngredientAllergen(ingredientAllergen.id, payload, useGlobalResource);
	}

	editMultiple(selectedRows) {
		const { ingredientAllergen, useGlobalResource } = this.props;

		const payload = {
			...(ingredientAllergen.degree && {
				degree_of: ingredientAllergen.degree.value,
			}),
			...(ingredientAllergen.allergen && {
				allergen: ingredientAllergen.allergen.value,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editIngredientAllergens(
			{
				batch: selectedRowsWithId,
			},
			useGlobalResource
		);
	}

	deleteEntry(id) {
		const { useGlobalResource } = this.props;
		return deleteIngredientAllergenes(id, useGlobalResource);
	}

	setInitialEditValues(data) {
		const { useGlobalResource } = this.props;

		const payload = {
			id: data.id,
			...(!useGlobalResource && {
				market: data.market.name,
			}),
			ingredient: data.ingredient.name,
			degree: {
				value: data.degree_of,
				label: data.degree_of,
			},
			allergen: {
				value: data.allergen.id,
				label: data.allergen.name,
			},
		};

		this.props.setIngredientAllergen(payload);
	}

	getEditableCellsEdit() {
		const { ingredientAllergen, useGlobalResource } = this.props;

		const singleEdit = !!ingredientAllergen?.id;

		return [
			...(singleEdit
				? [
						{
							header: 'Ingredient',
							value: <span>{ingredientAllergen.ingredient}</span>,
						},
				  ]
				: []),
			{
				header: 'Degree of',
				value: (
					<Input
						id="degree_of"
						type="select"
						value={ingredientAllergen.degree}
						onChange={(event) => this.editStoreEntry(event, 'degree')}
						options={getSelectOptionsFromEnums(enums)}
						clearable={false}
					/>
				),
			},
			{
				header: 'Allergen',
				value: (
					<InputCollectionSelect
						id="allergen"
						placeholder="Select allergen.."
						value={ingredientAllergen.allergen}
						handleChange={(key, value) => this.editStoreEntry(value, 'allergen')}
						clearable={false}
						cache
						apiPath="/product/allergens"
						params={{
							limit: 30,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			...(singleEdit && !useGlobalResource
				? [
						{
							header: 'Market',
							value: <span>{ingredientAllergen.market}</span>,
						},
				  ]
				: []),
		];
	}

	editStoreEntry(e, type) {
		const value = e.target ? e.target.value : e;

		const payload = {
			[type]: value,
		};

		this.props.updateIngredientAllergen(payload);
	}

	fetchData(state) {
		const { useGlobalResource } = this.props;

		return fetchIngredientAllergenes(state, useGlobalResource);
	}

	toggleAddModal() {
		this.setState((prevState) => ({ showAddModal: !prevState.showAddModal }));
		this.props.resetState();
	}

	getActionButtons() {
		return (
			<Button type="inverted" label="Add" shadow onClick={this.toggleAddModal}>
				<Icon name="add" />
			</Button>
		);
	}

	render() {
		const {
			batchFormData,
			batchListData,
			editBatchListItem,
			removeBatchListItem,
			updateBatchForm,
			addBatchList,
			useGlobalResource,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					accessAreasAllowedToEdit={['Sales Configuration']}
					title={useGlobalResource ? phrases.GLOBAL_INGREDIENT_ALLERGENS : phrases.INGREDIENT_ALLERGENS}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={useGlobalResource ? 'pos/global_ingredient_allergenes' : 'pos/ingredient_allergenes'}
					manual
					editEntry={(e) => this.editEntry(e)}
					editMultiple={this.editMultiple}
					editableCellsEdit={this.getEditableCellsEdit()}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					deleteEntry={this.deleteEntry}
					actionRender={this.getActionButtons()}
				/>

				<IngredientAllergensModalBatch
					modalVisible={this.state.showAddModal}
					handleClose={this.toggleAddModal}
					batchFormData={batchFormData}
					batchListData={batchListData}
					addBatchList={addBatchList}
					updateBatchForm={updateBatchForm}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					batchAddData={addIngredientAllergenes}
					useGlobalResource={useGlobalResource}
				/>
			</>
		);
	}
}

IngredientAllergens.propTypes = {
	ingredientAllergen: PropTypes.object,
	defaultIngredientAllergen: PropTypes.object,
	updateIngredientAllergen: PropTypes.func,
	setIngredientAllergen: PropTypes.func,
	resetState: PropTypes.func,
	batchListData: PropTypes.array,
	batchFormData: PropTypes.object,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	updateBatchForm: PropTypes.func,
	addBatchList: PropTypes.func,
	useGlobalResource: PropTypes.bool,
};

IngredientAllergens.defaultProps = {
	useGlobalResource: false,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setIngredientAllergen,
			updateIngredientAllergen,
			resetState,
			// batch stuff
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		ingredientAllergen: store.ingredientAllergens.data.ingredientAllergen,
		defaultIngredientAllergen: store.ingredientAllergens.data.defaultIngredientAllergen,
		batchListData: store.ingredientAllergens.data.batchListData,
		batchFormData: store.ingredientAllergens.data.batchFormData,
	};
};

export default connectWithStore(IngredientAllergens, mapStateToProps, mapDispatchToProps);
