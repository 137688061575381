import React, { Fragment, useEffect } from 'react';

// components
import { Box, Typography, Button, List, ListItem, ListItemText, Divider } from '@mui/material';

// phrases
import phrases from '../productVariantNutritionAllergenInfo.phrases';

type Props = {
	allergens: IProductVariantAllergenInfo[] | null;
	nutritionInfos: IProductVariantNutritionInfo[] | null;
	onBack: () => void;
	onUnmount: () => void;
};

const ProductVariantNutritionAllergenInfoList = ({
	nutritionInfos,
	allergens,
	onBack,
	onUnmount,
}: Props): JSX.Element => {
	const getNoData = (): JSX.Element => (
		<ListItem>
			<ListItemText
				primary={phrases.NO_DATA}
				primaryTypographyProps={{
					textAlign: 'center',
					variant: 'subtitle2',
					color: 'secondary',
				}}
			/>
		</ListItem>
	);

	useEffect(() => {
		return () => {
			onUnmount();
		};
	}, []);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Typography fontWeight="bold" textTransform="uppercase" variant="h6">
				{phrases.ALLERGENS}
			</Typography>

			<List
				sx={{
					width: '100%',
				}}
			>
				{allergens?.length === 0 && getNoData()}

				{allergens?.map((entry, index) => (
					<Fragment key={index}>
						<ListItem
							secondaryAction={
								<ListItemText
									primary={entry.degree}
									primaryTypographyProps={{
										fontWeight: 'bold',
										textTransform: 'uppercase',
									}}
								/>
							}
						>
							<ListItemText
								primary={entry.name}
								primaryTypographyProps={{
									fontWeight: 'bold',
									textTransform: 'uppercase',
								}}
							/>
						</ListItem>
						<Divider component="li" />
					</Fragment>
				))}
			</List>

			<Box
				sx={{
					marginTop: 4,
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Typography fontWeight="bold" textTransform="uppercase" variant="h6">
					{phrases.NUTRITION_INFO}
				</Typography>

				<List
					sx={{
						width: '100%',
					}}
				>
					{nutritionInfos?.length === 0 && getNoData()}

					{nutritionInfos?.map((entry, index) => (
						<Fragment key={index}>
							<ListItem
								secondaryAction={
									<ListItemText
										primary={Math.round(entry.value).toString()}
										primaryTypographyProps={{
											fontWeight: 'bold',
											textTransform: 'uppercase',
										}}
									/>
								}
							>
								<ListItemText
									primary={entry.name}
									primaryTypographyProps={{
										fontWeight: 'bold',
										textTransform: 'uppercase',
									}}
								/>
							</ListItem>
							<Divider component="li" />
						</Fragment>
					))}
				</List>
			</Box>

			<Box mt={6}>
				<Button onClick={onBack}>{phrases.BACK}</Button>
			</Box>
		</Box>
	);
};

export default ProductVariantNutritionAllergenInfoList;
