export const RESET_INGREDIENT_VAR_OPTION =
	'SALES_CONFIGURATION/RESET_PRODUCT_DISCOUNT_SET';
export const SET_INGREDIENT_VAR_OPTION =
	'SALES_CONFIGURATION/SET_PRODUCT_DISCOUNT_SET';
export const UPDATE_INGREDIENT_VAR_OPTION =
	'SALES_CONFIGURATION/UPDATE_PRODUCT_DISCOUNT_SET';

/**
 * @param {object} payload
 */
export function setIngredientVariationOption(payload) {
	return {
		type: SET_INGREDIENT_VAR_OPTION,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateIngredientVariationOption(payload) {
	return {
		type: UPDATE_INGREDIENT_VAR_OPTION,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetIngredientVariationOption() {
	return {
		type: RESET_INGREDIENT_VAR_OPTION
	};
}