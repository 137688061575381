import { get } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

type FunctionProps = {
	workplaceId: string;
	productVariantId: string;
	date: string;
};

export function fetchProductAllergenInformation({
	workplaceId,
	productVariantId,
	date,
}: FunctionProps): Promise<IProductVariantAllergenInfo[]> {
	return get(
		`/product/workplaces/${workplaceId}/product_variants/${productVariantId}/allergen_information?date=${date}`
	)
		.then((res: IApiRes) => {
			const data = res.data as IProductVariantAllergenInfo[];

			return data;
		})
		.catch((err: unknown) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

export function fetchProductNutritionInformation({
	workplaceId,
	productVariantId,
	date,
}: FunctionProps): Promise<IProductVariantNutritionInfo[]> {
	return get(
		`/product/workplaces/${workplaceId}/product_variants/${productVariantId}/nutrition_information?date=${date}`
	)
		.then((res: IApiRes) => {
			const data = res.data as IProductVariantNutritionInfo[];

			return data;
		})
		.catch((err: unknown) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}
