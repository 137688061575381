'use strict';

import moment from 'moment';

// lodash
import _isEmpty from 'lodash/isEmpty';

import {
	SET_KPIS,
	TOGGLE_FETCHING,
	RESET_KPIS,
	SET_START_OF_PERIOD,
	SET_END_OF_PERIOD,
	NUMBER_OF_WEEKS_TO_SHOW,
	SHIFTS_BEING_UPDATED,
	SET_DAY_CARD_WIDTH,
	TOGGLE_STATS,
	SET_MYSHIFTPLANNER_STATS,
	RESET_MYSHIFTPLANNER_STATS,
	SET_MYSHIFTPLANNER_STATS_START_END_DATE,
	UPDATE_SHIFT,
	SET_SHIFTS,
	ADD_UNASSIGNED_SHIFTS,
	REMOVE_UNASSIGNED_SHIFTS,
	ADD_SHIFT,
	REMOVE_SHIFT,
	SET_IS_MOUNTED,
	ADD_SHIFT_TRANSFER_SHIFTS,
	RESET_SHIFT_TRANSFER,
	SET_SHIFT_TRANSFER_SHIFT,
	SET_SHIFT_TRANSFER_POLICY,
} from './myShiftplanner.actions';

// initially showing 8 weeks in advance
const currentStartOfPeriod = moment.utc().startOf('isoweek').isoWeekday(1);
const numberOfWeeksToShow = 8;
const endOfPeriod = currentStartOfPeriod.clone().add(numberOfWeeksToShow, 'weeks');

const defaultState = {
	isMounted: false,
	shifts: [],
	fetching: false,
	currentStartOfPeriod: currentStartOfPeriod.clone(),
	endOfPeriod: endOfPeriod.clone(),
	numberOfWeeksToShow,
	shiftsBeingUpdated: false,
	daysToShow: false,
	showStats: false,
	myShiftplannerStats: {
		ui: {},
		data: {},
	},
	shiftTransfer: {
		policy: null,
		shift: null,
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
		case SET_DAY_CARD_WIDTH: {
			return {
				...state,
				daysToShow: payload.style,
			};
		}

		case SET_KPIS: {
			return {
				...state,
				[payload.weekNumber]: {
					...state[payload.weekNumber],
					...payload.shiftplannerKpis,
				},
			};
		}

		case RESET_KPIS: {
			return defaultState;
		}

		case TOGGLE_FETCHING: {
			return {
				...state,
				fetching: payload.fetching,
			};
		}

		case SET_START_OF_PERIOD: {
			return {
				...state,
				currentStartOfPeriod: payload,
			};
		}

		case SET_END_OF_PERIOD: {
			return {
				...state,
				endOfPeriod: payload,
			};
		}

		case NUMBER_OF_WEEKS_TO_SHOW: {
			return {
				...state,
				numberOfWeeksToShow: payload < 1 ? 1 : payload,
			};
		}

		case SHIFTS_BEING_UPDATED: {
			return {
				...state,
				shiftsBeingUpdated: payload,
			};
		}

		case TOGGLE_STATS: {
			return {
				...state,
				showStats: payload,
			};
		}

		case SET_MYSHIFTPLANNER_STATS: {
			return {
				...state,
				myShiftplannerStats: {
					...state.myShiftplannerStats,
					data: payload,
				},
			};
		}

		case SET_MYSHIFTPLANNER_STATS_START_END_DATE: {
			return {
				...state,
				myShiftplannerStats: {
					...state.myShiftplannerStats,
					ui: payload,
				},
			};
		}

		case RESET_MYSHIFTPLANNER_STATS: {
			return {
				...state,
				myShiftplannerStats: defaultState.myShiftplannerStats,
			};
		}

		case UPDATE_SHIFT:
			return {
				...state,
				shifts: state.shifts.map((entry) => {
					if (entry.id === payload.id) {
						return {
							...entry,
							...payload,
						};
					}

					return entry;
				}),
			};

		case SET_SHIFTS:
			return {
				...state,
				shifts: payload,
			};

		case ADD_UNASSIGNED_SHIFTS:
			return {
				...state,
				shifts: [...state.shifts, ...payload],
			};

		case REMOVE_UNASSIGNED_SHIFTS:
			return {
				...state,
				shifts: state.shifts.filter((entry) => !_isEmpty(entry.shift_employees)),
			};

		case ADD_SHIFT:
			return {
				...state,
				shifts: [...state.shifts, payload],
			};

		case REMOVE_SHIFT:
			return {
				...state,
				shifts: state.shifts.filter((entry) => entry.id !== payload.id),
			};

		case SET_IS_MOUNTED:
			return {
				...state,
				isMounted: payload,
			};

		case ADD_SHIFT_TRANSFER_SHIFTS:
			return {
				...state,
				shifts: [...state.shifts, ...payload],
			};

		case SET_SHIFT_TRANSFER_SHIFT:
			return {
				...state,
				shiftTransfer: {
					...state.shiftTransfer,
					shift: payload,
				},
			};

		case RESET_SHIFT_TRANSFER:
			return {
				...state,
				shiftTransfer: defaultState.shiftTransfer,
			};

		case SET_SHIFT_TRANSFER_POLICY:
			return {
				...state,
				shiftTransfer: {
					...state.shiftTransfer,
					policy: payload,
				},
			};

		default:
			return state;
	}
}

export default reducer;
