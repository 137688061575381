'use strict';

/**
 * @function loadCollection
 * @memberOf SERVICES.__models
 * @description
 * Loads formatted list collection via fetchCollection.
 */

import _extend from 'lodash/extend';

// Services
const __apiFilter = require('apiFilter.service');

module.exports = function (complete) {
	return new Promise((resolve, reject) => {
		const state = this.getState();
		const settings = this.getSettings();
		const collectionParams = _extend(
			{},
			state.params,
			__apiFilter.concatenate(state.filters)
		);


		if (complete)
			this.fetchCompleteCollection(
				settings.endpoint.index.path,
				collectionParams
			).then(
				function (collectionData) {
					resolve(collectionData);
				},
				(err) => {
					reject(err);
				}
			);
		else
			this.fetchCollection(settings.endpoint.index.path, collectionParams).then(
				function (collectionData) {
					resolve(collectionData);
				},
				(err) => {
					reject(err);
				}
			);
	});
};
