'use strict';
import moment from 'moment';

/**
 * @module filterId
 * @description Sets datetime-unique filter id when wanting multiple filters in one header
 * @param {string} name name of header to set filter in
 */

function filterId(name) {
	return name + '__' + moment().format();
}

module.exports = filterId;
