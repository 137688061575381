'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { InputCollectionSelect, SingleDatePickerInput } from 'dumb';
import { ModalRow } from 'dumb/modal';

// constants/phrases/moment
import constants from 'services/constants';
import phrases from './../../../workplaceWaitingTimePerformanceGrids.phrases';
import moment from 'moment';

// columns
import getColumns from 'reactDataWrapperColumns/administration/workplaces.columns';

class WorkplaceWaitingTimePerformanceGridsBatchStepForm extends Component {
	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		const value = e?.target?.value ?? e;

		updateBatchForm({
			[type]: value,
		});
	}

	render() {
		const { batchForm, workplaceFilter } = this.props;

		return (
			<>
				<ModalRow>
					<InputCollectionSelect
						id="posConfiguration"
						label={phrases.MODAL_BATCH_STEP_WORKPLACE}
						placeholder={phrases.MODAL_BATCH_STEP_WORKPLACE_PLACEHOLDER}
						value={batchForm.workplace}
						handleChange={(key, value) =>
							this.editStoreEntry(value, 'workplace')
						}
						required
						multi
						cache
						apiPath="/administration/workplaces"
						params={{
							limit: 30,
							filter: workplaceFilter,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						tableColumns={getColumns()}
						tableTitle={phrases.MODAL_BATCH_STEP_WORKPLACES}
						tableReduxKey="workplaceWaitingTimePerformanceGrids-add-modal-/administration/workplaces"
					/>
				</ModalRow>

				<ModalRow>
					<SingleDatePickerInput
						id="period-from"
						label={phrases.MODAL_BATCH_STEP_PERIOD_FROM}
						onChange={(event) => this.editStoreEntry(event, 'periodFrom')}
						selectedDate={
							batchForm.periodFrom
								? moment.utc(batchForm.periodFrom).format(constants.shortDate)
								: ''
						}
						noClockButton
					/>
				</ModalRow>

				<ModalRow>
					<SingleDatePickerInput
						id="period-to"
						label={phrases.MODAL_BATCH_STEP_PERIOD_TO}
						onChange={(event) => this.editStoreEntry(event, 'periodTo')}
						selectedDate={
							batchForm.periodTo
								? moment.utc(batchForm.periodTo).format(constants.shortDate)
								: ''
						}
						noClockButton
					/>
				</ModalRow>
			</>
		);
	}
}

WorkplaceWaitingTimePerformanceGridsBatchStepForm.propTypes = {
	batchForm: PropTypes.object,
	updateBatchForm: PropTypes.func,
	workplaceFilter: PropTypes.string,
};

export default WorkplaceWaitingTimePerformanceGridsBatchStepForm;
