export const UPDATE_BATCH_FORM =
	'SALES_CONFIGURATION_POS_CONFIGURATION_VERSION/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'SALES_CONFIGURATION_POS_CONFIGURATION_VERSION/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_POS_CONFIGURATION_VERSION/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_POS_CONFIGURATION_VERSION/REMOVE_BATCH_LIST_ITEM';
export const REVERT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_POS_CONFIGURATION_VERSION/REVERT_BATCH_LIST_ITEM';
export const EDIT_BATCH_LIST_ITEMS =
	'SALES_CONFIGURATION_POS_CONFIGURATION_VERSION/EDIT_BATCH_LIST_ITEMS';
export const ADD_BATCH_LIST_DUPLICATE =
	'SALES_CONFIGURATION_POS_CONFIGURATION_VERSION/ADD_BATCH_LIST_DUPLICATE';
export const SET_BATCH_LIST_ITEM_LOADING =
	'SALES_CONFIGURATION_POS_CONFIGURATION_VERSION/SET_BATCH_LIST_ITEM_LOADING';

export const SET_POS_CONFIGURATION_VERSION =
	'SALES_CONFIGURATION_POS_CONFIGURATION_VERSION/SET_POS_CONFIGURATION_VERSION';
export const UPDATE_POS_CONFIGURATION_VERSION =
	'SALES_CONFIGURATION_POS_CONFIGURATION_VERSION/UPDATE_POS_CONFIGURATION_VERSION';
export const RESET_STATE =
	'SALES_CONFIGURATION_POS_CONFIGURATION_VERSION/RESET_STATE';

export function setBatchListItemLoading(payload) {
	return {
		type: SET_BATCH_LIST_ITEM_LOADING,
		payload,
	};
}

export function addBatchListDuplicate(payload) {
	return {
		type: ADD_BATCH_LIST_DUPLICATE,
		payload,
	};
}

export function revertBatchListItem(payload) {
	return {
		type: REVERT_BATCH_LIST_ITEM,
		payload,
	};
}

export function editBatchListItems(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEMS,
		payload,
	};
}

export function setPosConfigurationVersion(payload) {
	return {
		type: SET_POS_CONFIGURATION_VERSION,
		payload,
	};
}

export function updatePosConfigurationVersion(payload) {
	return {
		type: UPDATE_POS_CONFIGURATION_VERSION,
		payload,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}
