import { store } from 'appState';
import shiftplannerReportParser from './../utilities/shiftplannerReportParser';
import * as actions from './../shiftplannerReport.actions';
import { get } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service';
import moment from 'moment';

import { saveAs } from 'file-saver';
import _isEmpty from 'lodash/isEmpty';
const __endpoints = require('endpoints.service');

let abortController = new AbortController();
let signal = abortController.signal;

export default function fetchShiftplannerReport({
	endDate,
	startDate,
	marketsId,
	storesId,
} = {}) {
	// Set params and filter
	const params = {
		filter: _filter(marketsId, storesId),
		to: endDate.format('YYYY-MM-DD'),
		from: startDate.format('YYYY-MM-DD'),
		type: 'Entries',
	};

	return get(
		__endpoints.collection.reporting.shiftplanner_reports.index.path,
		params,
		undefined,
		undefined,
		signal
	)
		.then((response) => {
			let report = response.data;
			report = shiftplannerReportParser(report);
			store.dispatch(actions.setShiftplannerReport(report));
		})
		.catch((err) => {
			const errorMsg = formatErrorMessage(err);
			setFeedback(errorMsg, 0);
		});
}

/**
 * @function abortFetchShiftplannerReport
 * @public
 * @description Funciton to abort call for fetching SP report. Mainly used in
 */
export function abortFetchShiftplannerReport() {
	abortController.abort();

	abortController = new AbortController();
	signal = abortController.signal;
}

/**
 * @function _filter
 * @private
 * @description Constructs filter to be used on the lazy loaded shiftplanner report.
 * @param {array} marketsId : Array of objects of markets
 * @param {array} storesId : Array of objects of stores
 */
function _filter(marketsId = [], storesId) {
	const noStoresSelected = _isEmpty(storesId);
	// Is no stores are selected in the hotdamnbar, search for markets instead
	if (noStoresSelected && marketsId) {
		return marketsId
			.map((market, index, arr) =>
				index === arr.length - 1
					? `:workplace.market.id=='${market.value}'`
					: `:workplace.market.id=='${market.value}',`
			)
			.join('');
	} else {
		const storesIdToMap = storesId || [];

		return storesIdToMap
			.map((store, index, arr) => {
				return index === arr.length - 1
					? `:workplace.id=='${store.value}'`
					: `:workplace.id=='${store.value}',`;
			})
			.join('');
	}
}

export function downloadShiftplannerCSV(marketsId, workplaces, period) {
	const csvHeader = { Accept: 'application/csv' };
	const params = {
		filter: _filter(marketsId, workplaces),
		to: period.to,
		from: period.from,
		type: 'Entries',
	};

	return get(
		__endpoints.collection.reporting.shiftplanner_reports.index.path,
		params,
		null,
		csvHeader
	)
		.then((csv) => {
			const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
			const fileName = `ShiftplannerReport-${moment(period.from).format(
				'YYYYMMDD'
			)}-${moment(period.to).format('YYYYMMDD')}.csv`;

			saveAs(blob, fileName);
		})
		.catch((err) => {
			const errorMsg = formatErrorMessage(err);
			setFeedback(errorMsg, 0);
		});
}
