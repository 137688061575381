'use strict';

var headers = {
	BEGINNING_OF_DAYS: {
		TOTAL_CASH_IN_DRAWER: 'Cash',
	},
	END_OF_DAYS: {
		TOTAL_COUNTED_CASH_IN_DRAWER: 'Cash',
		TOTAL_COUNTED_CARD: 'Card',
		TOTAL_COUNTED_FOREIGN_CURRENCY: 'FC',
		TOTAL_COUNTED_DRAWER_TO_BANK: 'D2B',
		TOTAL_DIFFERENCE: 'Diff',
	},
	DISCOUNTS: {
		NAME: 'Name',
		DESCRIPTION: 'Description',
		TYPE: 'Type',
		LOYALTY_TYPE: 'Loyalty Type',
	},
	INGREDIENTS: {
		NAME: 'Name',
		INGREDIENT_CATEGORY: 'Category',
	},
	INGREDIENT_CATEGORIES: {
		NAME: 'Name',
	},
	MEMBER_DISCOUNT_PROGRAMS: {
		NAME: 'Name',
		DESCRIPTION: 'Description',
	},
	PRODUCTS: {
		NAME: 'Name',
		PRODUCT_CATEGORY: 'Category',
	},
	PRODUCT_CATEGORIES: {
		NAME: 'Name',
		CATEGORY_GROUP: 'Category Group',
	},
	PRODUCT_VARIANTS: {
		NAME: 'Name',
		PRODUCT: 'Product',
		FUEL: 'Fuel',
		IMAGE: 'Image',
		SIZE: 'Size',
		AVAILABLE_FOR_DELIVERY: 'Available for delivery',
	},
	PRODUCT_VARIANT_CATEGORIES: {
		NAME: 'Name',
	},
	POS_ITEMS: {
		NAME: 'Name',
		TYPE: 'Type',
		PRODUCT: 'Product',
		INGREDIENT: 'Ingredient',
		POS_ITEM_INGREDIENT_VARIANTS: 'Ingredient variants',
		POS_ITEM_PRODUCT_VARIANTS: 'Product variants',
	},
	POS_ITEM_INGREDIENT_VARIANTS: {
		POS_ITEM: 'Point of sale item',
		INGREDIENT: 'Ingredient',
	},
	POS_ITEM_PRODUCT_VARIANTS: {
		POS_ITEM: 'Point of sale item',
		PRODUCT: 'Product',
		PRODUCT_VARIANT: 'Product variant',
	},
	POS_SESSION_AUDITS: {
		DATE: 'Date',
		WORKPLACE: 'Workplace',
		OPENED: 'Opened',
		CLOSED: 'Closed',
		DESCRIPTION: 'Description',
		REGISTER: 'Register',
	},
	POS_SESSIONS: {
		FOREIGN_ID: 'Foreign id',
		DATE: 'Date',
		LOCKED: 'Locked',
		WORKPLACE: 'Store',
		POS_SESSION_AUDITS_LIST: 'Audits',
		POS_SESSION_AUDIT_TAXES: 'Audit Taxes',
		POS_SOFTWARE: 'POS software',
		POS_SOFTWARE_VERSION: 'POS software version',
		REGISTER: 'Cash Register',
		QUANTITY: 'Products',
		RETAIL_PRICE: 'Price',
		DISCOUNT: 'Discount',
		VAT: 'VAT',
		SALES_TAX: 'Sales tax',
		TRANSACTIONS: 'Trans.',
		TRANSACTION_LINES: 'Lines',
		OPENED: 'Opened',
		CLOSED: 'Closed',
		TOTAL: 'Total',
		REDEEMED: 'Redeemed',
		DEPOSITED: 'Deposited',
		USER: 'User',
		CREATED: 'Created',
		CARD: 'Card',
		CASH: 'Cash',
		MOBILE_PAYMENT: 'Mobile',
		DESCRIPTION: 'Reason',
		TYPE: 'Type',
		SIGNED: 'Sign',
		EMPTY: '',
	},
	POS_SESSION_DEPOSITS: {
		POS_SESSION: 'POS Session',
		WORKPLACE: 'Store',
		DATE: 'Date',
		FOREIGN_ID: 'Foreign ID',
		TYPE: 'Type',
		MISSING: 'Missing',
		RECONCILED: 'Reconciled',
		DEPOSIT: 'Deposit',
		DRAWER_TO_BANK: 'Drawer To Bank',
		DIFFERENCE: 'Difference',
		DEPOSIT_DIFFERENCE: 'Previous financial adjustment',
		REGISTERED_AT_BANK: 'Registered at bank',
		REGISTERED: 'Date',
		CURRENCY_TYPE: 'Currency type',
	},
	TRANSACTIONS: {
		TRANSACTION_NUMBER: 'Number',
		TRANSACTION_TIME: 'Time',
		CASHIER: 'Cashier',
		RECEIPT: 'Receipt',
		CUSTOMER: 'Customer',
		FOREIGN_ID: 'Foreign id',
		QUANTITY: 'Products',
		RETAIL_PRICE: 'Price',
		DISCOUNT: 'Discount',
		VAT: 'VAT',
		SALES_TAX: 'Sales tax',
		TRANSACTION_LINES: 'Lines',
		OPENED: 'Opened',
		CLOSED: 'Closed',
	},
	TRANSACTION_LINES: {
		LINE_NUMBER: 'Line number',
		POS_ITEM: 'POS item',
		CUSTOMER: 'Customer',
		QUANTITY: 'Products',
		RETAIL_PRICE: 'Price',
		DISCOUNT: 'Discount',
		VAT: 'VAT',
		VAT_RATE: 'VAT rate',
		SALES_TAX: 'Sales tax',
		SALES_TAX_RATE: 'Tax rate',
		OPENED: 'Opened',
		CLOSED: 'Closed',
	},
	SIZES: {
		NAME: 'Name',
		SORT_ORDER: 'Order',
		SIZE_GROUP: 'Category',
	},
	SIZE_GROUPS: {
		NAME: 'Name',
	},
};

var titles = {
	END_OF_DAYS: 'Sales control',
	INGREDIENTS: 'Ingredients',
	INGREDIENT_CATEGORIES: 'Ingredient categories',
	MEMBER_DISCOUNT_PROGRAMS: 'Member Discount Programs',
	PRODUCTS: 'Products',
	PRODUCT_CATEGORIES: 'Product categories',
	PRODUCT_VARIANTS: 'Product variants',
	PRODUCT_VARIANT_CATEGORIES: 'Variant categories',
	POS_ITEMS: 'Product list',
	POS_ITEM_INGREDIENT_VARIANTS: 'POS item ingredient variants',
	POS_ITEM_PRODUCT_VARIANTS: 'POS item product variants',
	POS_SESSIONS: 'Point of sale sessions',
	POS_SESSIONS_DETAILS: 'Totals',
	POS_SESSION_AUDITS: 'Audits',
	POS_SESSION_DEPOSITS: 'Drawer To Bank List',
	TRANSACTIONS: 'Transactions',
	TRANSACTION_LINES: 'Transaction lines',
	SIZES: 'Sizes',
	SIZE_GROUPS: 'Size categories',
	DISCOUNTS: 'Discounts',
};

var colgroups = {
	POS_SESSIONS: {
		LOCATION_AND_TIME: 'Location and time',
		TOTALS_FROM_SALE: 'Totals from sale',
		TRANSACTION: 'Transactions',
		POS_SESSION_DATA: 'POS Session data',
		BEGIN_COUNT: 'Begin',
		PAYMENTS: 'Turnover',
		DEPOSITS: 'Deposits',
		END_COUNT: 'End',
		DIFFERENCES: 'Differences',
		PREPAYMENT: 'Prepayment',
		AUDIT: 'Audit',
	},
	TRANSACTIONS: {
		TOTALS_FROM_SALE: 'Totals from sale',
		TRANSACTION: 'Transactions',
	},
	TRANSACTION_LINES: {
		TOTALS_FROM_SALE: 'Totals from sale',
		TRANSACTION: 'Transactions',
		RATES: 'Rates',
	},
};

module.exports = {
	headers: headers,
	titles: titles,
	colgroups: colgroups,
};
