'use strict';

class LocalStorageError {
	constructor(message) {
		this.name = 'LocalStorageError';

		if (typeof Error.captureStackTrace === 'function') Error.captureStackTrace(this, this.constructor.name);
		else this.stack = (new Error(message)).stack;
	}
}

export { LocalStorageError };