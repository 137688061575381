import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper, ButtonTile } from 'dumb';
import AddAssetCollection from './components/addAssetCollection';
import AssignAssetCollection from './components/assignAssetCollection';

// phrases/constants
import phrases from '../../assetLeftAligned.phrases';

// lodash
import _isEmpty from 'lodash/isEmpty';

class AssetsLeftAlignedCreateAssignModal extends Component {
	constructor(props) {
		super(props);

		this.modalRef = React.createRef();

		this.state = {
			allowOverflow: false,
			modalLoading: false,
		};

		this._goToStep = this._goToStep.bind(this);
		this._onSubmitAddForm = this._onSubmitAddForm.bind(this);
		this._batchAddSubmit = this._batchAddSubmit.bind(this);
	}

	componentWillUnmount() {
		this.props.resetAssets();
	}

	_goToStep(step) {
		const shouldAllowOverFlow = step === 3;

		this.setState(() => ({ allowOverflow: shouldAllowOverFlow }));

		this.modalRef.current.goToStep(step);
	}

	_onSubmitAddForm() {
		const {
			filterId,
			editFilter,
			productId,
			defaultAssetCollection,
			handleClose,
			addAssetCollection,
			editProduct,
			reloadTable,
		} = this.props;

		this.setState(() => ({ modalLoading: true }));

		const payload = {
			name: defaultAssetCollection.name,
			description: defaultAssetCollection.description,
		};

		addAssetCollection(payload)
			.then((res) => {
				const assetCollectionId = res.data[0].id;

				const payload = {
					id: productId || filterId,
					asset_collection: assetCollectionId,
				};

				const funcToExecute = productId
					? editProduct(payload)
					: editFilter(payload);

				funcToExecute.then(() => {
					this.setState(() => ({ modalLoading: false }));
					handleClose();
					reloadTable();
				});
			})
			.catch(() => {
				this.setState(() => ({ modalLoading: false }));
			});
	}

	_batchAddSubmit() {
		const {
			filterId,
			editFilter,
			productId,
			defaultAssetCollection,
			handleClose,
			editProduct,
			reloadTable,
		} = this.props;

		this.setState(() => ({ modalLoading: true }));

		const payload = {
			id: productId || filterId,
			asset_collection: defaultAssetCollection.assetCollection.value,
		};

		const funcToExecute = productId
			? editProduct(payload)
			: editFilter(payload);

		funcToExecute.then(() => {
			this.setState(() => ({ modalLoading: false }));
			handleClose();
			reloadTable();
		});
	}

	render() {
		const {
			modalVisible,
			handleClose,
			defaultAssetCollection,
			updateDefaultAssetCollection,
			productId,
		} = this.props;

		const steps = [
			{
				component: (
					<div className="edit-modal-choice__content">
						<ButtonTile
							icon="add"
							type="inverted"
							shadow
							onClick={() => this._goToStep(2)}>
							{phrases.MODAL_ADD_ASSET_COLLECTION}
						</ButtonTile>
						<ButtonTile
							icon="add"
							type="inverted"
							shadow
							onClick={() => this._goToStep(3)}>
							{phrases.MODAL_ASSIGN_ASSET_COLLECTION}
						</ButtonTile>
					</div>
				),
				title: phrases.MODAL_MAIN_MENU_TITLE,
				customFooter: <></>,
				loading: false,
				allowOverflow: true,
			},
			{
				component: (
					<AddAssetCollection
						assigningToProduct={!!productId}
						defaultAssetCollection={defaultAssetCollection}
						updateDefaultAssetCollection={updateDefaultAssetCollection}
					/>
				),
				title: phrases.MODAL_ADD_ASSET_COLLECTION,
				onBack: () => this._goToStep(1),
				onNext: () => this._onSubmitAddForm(),
				isValid: true,
				loading: false,
				confirmButtonLabel: phrases.MODAL_STEP_FORM_CONFIRM,
				cancelButtonLabel: phrases.MODAL_STEP_LIST_BACK_BUTTON_LABEL,
				noScroll: false,
			},
			{
				component: (
					<AssignAssetCollection
						defaultAssetCollection={defaultAssetCollection}
						updateDefaultAssetCollection={updateDefaultAssetCollection}
					/>
				),
				title: phrases.MODAL_STEP_FORM_TITLE_ASSIGN,
				isValid: !_isEmpty(defaultAssetCollection.assetCollection),
				onNext: () => this._batchAddSubmit(),
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_STEP_FORM_CONFIRM,
				cancelButtonLabel: phrases.MODAL_STEP_LIST_BACK_BUTTON_LABEL,
				noScroll: false,
			},
		];

		return modalVisible ? (
			<ModalStepper
				ref={this.modalRef}
				isOpen={modalVisible}
				steps={steps}
				showStep={false}
				onClose={handleClose}
				allowOverflow={this.state.allowOverflow}
			/>
		) : null;
	}
}

AssetsLeftAlignedCreateAssignModal.propTypes = {
	defaultAssetCollection: PropTypes.object,
	productId: PropTypes.number,
	handleClose: PropTypes.func,
	addAssetCollection: PropTypes.func,
	editProduct: PropTypes.func,
	reloadTable: PropTypes.func,
	modalVisible: PropTypes.bool,
	updateDefaultAssetCollection: PropTypes.bool,
	editFilter: PropTypes.string,
	filterId: PropTypes.number,
	resetAssets: PropTypes.func,
};

export default AssetsLeftAlignedCreateAssignModal;
