/**
 * @function getChildrenRoutes
 * @description this will take first level children from seciton and post it
 * @param {string} pathName
 * @param {array} routes
 * @returns {object} section and pages
 */
export const getChildrenRoutes = (pathName, routes, parent) => {
	let levelArray = routes;
	if (parent) {
		levelArray = levelArray.find((route) => route.path === parent)?.children || [];
	}

	const section = levelArray.find((route) => route.path === pathName);
	const children = section?.children ?? { section, pages: [] };

	if (children) {
		return {
			section,
			pages: children
				.map((child) => {
					if (child.index || child.hidden) return;
					return child;
				})
				.filter((x) => x),
		};
	}
};
