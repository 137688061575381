'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { InputCollectionSelect, Input, FileUpload } from 'dumb';
import { ModalRow } from 'dumb/modal';

// lodash
import _get from 'lodash/get';

// phrases
import phrases from './../../../salesConfigurationPosConfigurationDiscountProductVariant.phrases';

class SalesConfigurationPosConfigurationDiscountProductVariantBatchProductVariantsAddStepForm extends Component {
	constructor(props) {
		super(props);

		this.productVariants = '/product/product_variants';
	}

	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		updateBatchForm({
			[type]: e,
		});
	}

	render() {
		const { batchFormData, productVariantsFilter } = this.props;

		return (
			<ModalRow>
				<InputCollectionSelect
					id="productVariant"
					placeholder={
						phrases.MODAL_BATCH_STEP_FORM_PRODUCT_VARIANTS_PLACEHOLDER
					}
					value={batchFormData.productVariants}
					handleChange={(x, value) =>
						this.editStoreEntry(value, 'productVariants')
					}
					multi
					cache
					apiPath={this.productVariants}
					params={{
						limit: 50,
						filter: productVariantsFilter,
					}}
					optionFormat={(entry) => ({
						value: entry,
						label: entry.name,
					})}
					inputFilterFormat={(input) => `:name=like='%${input}%'`}
					tableColumns={[
						{
							Header: 'Name',
							id: 'name',
							accessor: 'name',
							filterPath: ':name',
						},
						{
							Header: 'Product',
							id: 'prod',
							accessor: (d) => _get(d, 'product.name', ''),
							filterPath: ':product.name',
						},

						{
							Header: 'Size',
							id: 'size',
							accessor: (d) => _get(d, 'size.name', ''),
							filterPath: ':size.name',
						},
						{
							Header: 'Bundle',
							id: 'bundle',
							accessor: (d) => _get(d, 'bundle.name', ''),
							filterPath: ':bundle.name',
						},
						{
							Header: 'Fuel',
							id: 'fuel',
							accessor: 'fuel',
							filterPath: ':fuel',
						},
						{
							Header: 'Points',
							id: 'points',
							accessor: (d) => _get(d, 'points.points', ''),
							filterPath: ':points.points',
							filterable: false,
							sortable: false,
						},
						{
							Header: 'Image',
							id: 'image',
							accessor: (d) => _get(d, 'image.filename', ''),
							filterPath: ':image.filename',
							filterable: false,
							sortable: false,
							Cell: (d) => {
								const url = _get(d, 'original.image.url', false);
								let file = null;
								if (url) {
									file = {
										url,
										filename: _get(d, 'original.image.filename', ''),
									};
								}
								return <FileUpload file={file} thumbnail disabled />;
							},
						},
						{
							Header: 'Asset Collection',
							id: 'asset_collection',
							accessor: (d) => _get(d, 'asset_collection.name', null),
							filterPath: ':asset_collection.name',
						},
						{
							Header: 'Legacy',
							id: 'legacy',
							accessor: 'legacy',
							filterPath: ':legacy',
							Cell: (d) => {
								return (
									<Input
										id="legacy"
										type="checkbox"
										checked={_get(d, 'original.legacy', false)}
										disabled
									/>
								);
							},
						},
					]}
					tableReduxKey={`globalProductDiscounts-${this.productVariants}`}
					tableTitle={
						phrases.MODAL_BATCH_STEP_FORM_POS_CONFIGURATION_PRODUCT_VARIANTS_TABLE_TITLE
					}
				/>
			</ModalRow>
		);
	}
}

SalesConfigurationPosConfigurationDiscountProductVariantBatchProductVariantsAddStepForm.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	productVariantsFilter: PropTypes.string,
};

export default SalesConfigurationPosConfigurationDiscountProductVariantBatchProductVariantsAddStepForm;
