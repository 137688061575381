'use strict';

var errors = {
	NO_DATA: 'Unable to get data.',
	UNKNOWN: 'Unknown error.',
	STATE_CHANGE: 'An unexpected error occurred while trying to get the page you requested. If we keep messing this up, please contact the administrator!',
	PAGE_404_TITLE: 'Page not found',
	PAGE_404_TEXT: 'Oops, we cant seem to locate your order. If we keep messing this up, please contact the administrator!',
	PAGE_UNKNOWN_TITLE: 'What the F*** happended?!',
	PAGE_UNKNOWN_TEXT: 'Oops, an unknown error occurred. If we keep messing this up, please contact the administrator!',
	NO_ACCESS: 'Seems like you tried accessing a page you don\'t have access to...',
	NO_RESOURCE_ACCESS: 'Sorry - Your user doesn\'t have access to do this.',
	NO_ACCESS_CONTROLS: 'Access control levels undefined for this state'
};

module.exports = errors;