'use strict';

import React, { Component } from 'react';
import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// redux actions
import { editProductTile } from './../addTileModal/store/addTileModal.actions';
import * as posConfigurationLayout from './../posConfigurationLayout.actions';

import PropTypes from 'prop-types';

// Components
import AppLayoutComponent from './appLayout.component';

class AppLayout extends Component {
	render() {
		const {
			posFullConfigurationsVariantsList,
			activeTile,
			addLayoutGroup,
			addTile,
			deleteLayoutGroup,
			removeTile,
			fetching,
			grid,
			gridDimensions,
			gridMetaData,
			gridSize,
			groupItemInFocus,
			groups,
			imageAssets,
			layout,
			page,
			posConfigurationsVariantsList,
			rotateTiles,
			rotateLayoutGroup,
			setMenuItemInFocus,
			tilesInGroup,
			updateLayoutGroup,
			updateTile,
			layoutId,
			type,
			editProductTile,
			showAddTileModal,
			updatePositionsInApp,
			setActiveTile,
			setActiveTileType,
			setShowAddTileModal,
			subType
		} = this.props;

		return (
			<AppLayoutComponent
				posFullConfigurationsVariantsList={posFullConfigurationsVariantsList}
				activeTile={activeTile}
				addLayoutGroup={addLayoutGroup}
				addTile={addTile}
				deleteLayoutGroup={deleteLayoutGroup}
				removeTile={removeTile}
				fetching={fetching}
				grid={grid}
				gridDimensions={gridDimensions}
				gridMetaData={gridMetaData}
				gridSize={gridSize}
				groupItemInFocus={groupItemInFocus}
				groups={groups}
				imageAssets={imageAssets}
				layout={layout}
				page={page}
				posConfigurationsVariantsList={posConfigurationsVariantsList}
				rotateTiles={rotateTiles}
				setMenuItemInFocus={setMenuItemInFocus}
				tilesInGroup={tilesInGroup}
				updateLayoutGroup={updateLayoutGroup}
				updateTile={updateTile}
				layoutId={layoutId}
				type={type}
				rotateLayoutGroup={rotateLayoutGroup}
				showAddTileModal={showAddTileModal}
				editProductTile={editProductTile}
				updatePositionsInApp={updatePositionsInApp}
				setActiveTile={setActiveTile}
				setActiveTileType={setActiveTileType}
				setShowAddTileModal={setShowAddTileModal}
				subType={subType}
			/>
		);
	}
}

AppLayout.propTypes = {
	posFullConfigurationsVariantsList: PropTypes.array,
	rotateLayoutGroup: PropTypes.func,
	layoutId: PropTypes.number,
	tilesInGroup: PropTypes.array,
	groupItemInFocus: PropTypes.number,
	activeTile: PropTypes.object,
	fetching: PropTypes.bool,
	grid: PropTypes.object,
	gridDimensions: PropTypes.array,
	gridMetaData: PropTypes.object,
	gridSize: PropTypes.number,
	groups: PropTypes.object,
	imageAssets: PropTypes.array,
	posConfigurationsVariantsList: PropTypes.array,
	addLayoutGroup: PropTypes.func,
	addTile: PropTypes.func,
	deleteLayoutGroup: PropTypes.func,
	removeTile: PropTypes.func,
	updateLayoutGroup: PropTypes.func,
	updateTile: PropTypes.func,
	layout: PropTypes.object,
	page: PropTypes.number,
	rotateTiles: PropTypes.func,
	setMenuItemInFocus: PropTypes.func,
	type: PropTypes.string,
	updatePositionsInApp: PropTypes.func,
	setActiveTileType: PropTypes.func,
	setShowAddTileModal: PropTypes.func,
	showAddTileModal: PropTypes.bool,
	setActiveTile: PropTypes.func,
	editProductTile: PropTypes.func,
	subType: PropTypes.string
};

const mapStateToPropsFactory = store => {
	return {
		fetchingTile: store.salesConfigurationPOSLayout.fetchingTile,
		showAddTileModal: store.salesConfigurationPOSLayout.showAddTileModal
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			editProductTile,
			updatePositionsInApp: posConfigurationLayout.updatePositionsInApp,
			setActiveTile: posConfigurationLayout.setActiveTile,
			setActiveTileType: posConfigurationLayout.setActiveTileType,
			setShowAddTileModal: posConfigurationLayout.setShowAddTileModal
		},
		dispatch
	);
};

export default connectWithStore(
	AppLayout,
	mapStateToPropsFactory,
	mapDispatchToProps
);
