import React from 'react';

// Components
import RawIngredientCategories from './components/rawIngredientCategories/rawIngredientCategories';
import RawIngredientConfigurations from './components/rawIngredientConfigurations/rawIngredientConfigurations';

// styles for modal batch
import './rawIngredients.css';

export default function RawIngredientsContainer() {
	return (
		<div className="raw-ingredients">
			<RawIngredientCategories />
			<RawIngredientConfigurations />
		</div>
	);
}
