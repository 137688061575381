import constants from 'services/constants';
import moment from 'moment';
import { distanceFromMidnight } from './distanceFromMidnight';

/**
 * @function mapEarliestAndLatestOpeningHours
 * @description Extract earliest opening hour and latest closing hour to be used to visualize
 * the opening days in shiftplanner.
 * @param {Array} openingHours
 * @param {Array} shifts
 */
export default function mapEarliestAndLatestOpeningHours(
	openingHours = {},
	shifts = []
) {
	let earliestOpeningHour;
	let latestClosingHour;
	for (const key in openingHours) {
		if (openingHours[key]) {
			const openingHourEntity = openingHours[key];

			// Only check opening hours of days that are open (closed === false)
			if (openingHourEntity && openingHourEntity.period) {
				const openingHour = moment.utc(
					openingHourEntity.period.from,
					constants.dateFormat
				);

				const closingHour = moment.utc(
					openingHourEntity.period.to,
					constants.dateFormat
				);

				const mmtMidnight = openingHour.clone().startOf('day');

				const openingHourDistanceToMidnight = distanceFromMidnight(
					mmtMidnight,
					openingHour
				);

				const closingHourDistanceToMidnight = distanceFromMidnight(
					mmtMidnight,
					closingHour
				);

				if (earliestOpeningHour === undefined)
					earliestOpeningHour = openingHourDistanceToMidnight;
				if (earliestOpeningHour > openingHourDistanceToMidnight) {
					earliestOpeningHour = openingHourDistanceToMidnight;
				}

				if (latestClosingHour === undefined)
					latestClosingHour = closingHourDistanceToMidnight;
				if (latestClosingHour < closingHourDistanceToMidnight) {
					latestClosingHour = closingHourDistanceToMidnight;
				}

				// Loop throgh shifts to see if any shifts are earlier or later than current opening hours
				shifts.forEach((shift) => {
					let startingHourOfShift = moment.utc(
						shift.planned_period.from,
						constants.dateFormat
					);

					const mmtMidnight = startingHourOfShift.clone().startOf('day');
					startingHourOfShift = distanceFromMidnight(
						mmtMidnight,
						startingHourOfShift
					);

					if (earliestOpeningHour > startingHourOfShift) {
						earliestOpeningHour = startingHourOfShift;
					}

					let endingHourOfShift = moment.utc(
						shift.planned_period.to,
						constants.dateFormat
					);
					endingHourOfShift = distanceFromMidnight(
						mmtMidnight,
						endingHourOfShift
					);

					if (latestClosingHour < endingHourOfShift) {
						latestClosingHour = endingHourOfShift;
					}
				});
			}
		}
	}

	return [earliestOpeningHour, latestClosingHour];
}
