'use strict';

var headers = {
	GRADE_SCALES: {
		NAME: 'Name'
	},
	MODULES: {
		SORT_ORDER: 'Sort order',
		NAME: 'Name',
		TYPE: 'Type',
		GRADE_SCALE: 'Grade scale',
		MONEYBALL_POSITION: 'Moneyball position',
		BADGE_CATEGORY: 'Badge Category',
		BADGE_CATEGORY_CREATE: 'Badge Category(create)',
		BADGE_CATEGORY_READ: 'Badge Category(read)',
		MODULE_CATEGORY: 'Module Category'
	},
	GRADE_LEVELS: {
		NAME: 'Name',
		VALUE: 'Value',
		COLOR: 'Color',
		MONEYBALL_POSITION: 'Moneyball position',
		BADGE_CATEGORY: 'Badge Category',
	},
	MODULE_EXECUTIONS_TRAININGS: {
		TRAINER_NAME: 'Trainer name',
		TYPE: 'Type',
		VALUE: 'Value',
		MODULE: 'Module',
		FROM: 'From',
		TO: 'to',
		WORKPLACE: 'Workplace',
		MODULE_GRADE_LEVEL: 'Grade level',
	},
	MODULE_EXECUTIONS_EXAMS: {
		TRAINER_NAME: 'Trainer name',
		TYPE: 'Type',
		VALUE: 'Value',
		MODULE: 'Module',
		FROM: 'From',
		TO: 'to',
		WORKPLACE: 'Workplace',
		MODULE_GRADE_LEVEL: 'Grade level',
	},
	MODULE_GRADE_LEVELS: {
		IMAGE: 'Image',
		MINIATURE: 'miniature',
		BADGE_LEVEL: 'Badge Level',
		GRADE_LEVEL: 'Grade Level',
	},
	MODULE_CATEGORIES: {
		NAME: 'Name',
		SORT_ORDER: 'Sort order'
	}
};

var titles = {
	GRADE_LEVELS: 'Grade levels',
	GRADE_SCALES: 'Grade scales',
	MODULE_CATEGORIES: 'Module Categories',
	MODULE_GRADE_LEVELS: 'Grade images',
	MODULE_EXECUTIONS_TRAININGS: 'Trainings',
	MODULE_EXECUTIONS_EXAMS: 'Exams',
	MODULES: 'Modules'
};

module.exports = {
	headers: headers,
	titles: titles
};