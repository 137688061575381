export default {
	TABLE_TITLE: 'Power BI Reports',
	POWER_BI_REPORTS_ADDED: 'Power BI Reports added',
	POWER_BI_REPORT_EDITED: 'Power BI Report edited',
	POWER_BI_REPORTS_EDITED: 'Power BI Reports edited',
	POWER_BI_REPORTS_DELETED: 'Power BI Reports deleted',
	VIEW_POWER_BI_REPORT_TOOLTIP: 'View report',
	BATCH: 'Batch',
	COPY: 'Copy',
	COPY_TOOLTIP: 'Click to copy selected reports',

	// batch form
	MODAL_BATCH_STEP_LIST_TITLE: 'Create Power BI Reports',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_SORT_ORDER: 'Sort order',
	MODAL_BATCH_STEP_SORT_ORDER_PLACEHOLDER: 'Enter sort order...',
	MODAL_BATCH_STEP_POWER_BI_ID: 'Power BI ID',
	MODAL_BATCH_STEP_POWER_BI_ID_PLACEHOLDER: 'Enter power bi id...',
	MODAL_BATCH_STEP_ACCESS_GRANTING_AREA: 'Access Granting Area',
	MODAL_BATCH_STEP_ACCESS_GRANTING_AREAS: 'Access Granting Areas',
	MODAL_BATCH_STEP_ACCESS_GRANTING_AREA_PLACEHOLDER: 'Enter access granting area...',
	MODAL_BATCH_STEP_MARKET: 'Market',
	MODAL_BATCH_STEP_MARKETS_PLACEHOLDER: 'Select markets...',
	MODAL_BATCH_STEP_MARKETS: 'Markets',
	MODAL_BATCH_STEP_TYPE: 'Type',
	MODAL_BATCH_STEP_TYPES: 'Types',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add Power BI Reports',

	// copy report modal
	MODAL_COPY_REPORT_TITLE: 'Copy Power Bi reports',
	MODAL_COPY_REPORT_TIP: 'Use the top most row to update values in all selected reports',
	MODAL_COPY_REPORT_CONFIRM: 'Submit',
	MODAL_COPY_REPORT_CANCEL: 'Cancel',
	MODAL_GLOBAL_MARKETS_TITLE: 'Select markets',
	MODAL_GLOBAL_MARKETS_BUTTON_LABEL: 'Next',
	MODAL_COPY_REPORT_BACK: 'Back',
};
