'use strict';

// Services
import DetailsModel from 'common/components/details/detailsModel.service';
const __endpoints = require('endpoints.service');

export default class GridsDetails extends DetailsModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
				canCreate: true,
				canEdit: true,
				required: true,
			},
		];
		this.settings = {
			name: 'GridDetails',
			canCreate: true,
			canEdit: true,
			canDelete: true,
			translationPath: 'HR.GRIDS',
			endpoint: __endpoints.collection.hr.grids,
		};
		this.init();
		return this;
	}
}
