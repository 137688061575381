'use strict';

// redux
import { store } from 'appState';
import { updateShift, removeShift, addShift } from './../store/myShiftplanner.actions';
import { ShiftTransfer as ShiftTransferEnums } from 'services/enums';

import constants from 'services/constants';

// lodash
import _compact from 'lodash/compact';
import _orderBy from 'lodash/orderBy';
import _uniqueId from 'lodash/uniqueId';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';

import moment from 'moment';

import sortShifts from 'utils/sort/sortShifts';
import { getShiftActionToPerform } from 'services/shiftTransfer';

export function getStackedShiftsAndMetaData(payload) {
	const { shifts, hourToPixelRatio, stackShifts, isMobile } = payload;

	if (_isEmpty(shifts)) return { shifts: [], shiftsMetadata: {} };

	// get shift meta data (earliest, latest, day card height...)
	let shiftsMetadata = getShiftsMetaData({ shifts, hourToPixelRatio });

	// sort shifts
	let sortedShifts = sortShiftsPerDay({ shifts });

	// if desktop and multiple people selected, stack them
	if (stackShifts && !isMobile) {
		// stack shifts and get styles
		const reduced = _stackAndGetCustomStyles({
			sortedShifts,
			shiftsMetadata,
		});

		shiftsMetadata = {
			...shiftsMetadata,
			daysToShow: reduced.columnsStyles.style,
		};

		sortedShifts = reduced.shifts;
	}

	// since sorted shifts are double nested array, reduce them to make a single array of shifts
	sortedShifts = sortedShifts.reduce((acc, currentArrayOfShifts) => [...acc, ...currentArrayOfShifts], []);

	return {
		sortedShifts,
		shiftsMetadata,
	};
}

export function getShiftsMetaData({ shifts, hourToPixelRatio }) {
	// get grid hours
	const gridHours = getEarliestShiftHour(shifts);

	// get calendar height
	const height =
		// get day scope
		(gridHours.latestClosingHour - gridHours.startTimeOfDay) *
			// multiply by hour to pixel ratio to cover all hours
			hourToPixelRatio +
		// and add 3 more hours of padding (2 for hour indicators and 2 extra at the bottom)
		hourToPixelRatio * 4;

	// create shiftMetadata object
	return {
		height,
		earliestShiftHour: gridHours.earliestOpeningHour,
		latestShiftHour: gridHours.latestClosingHour,
		earliestShiftMinute: gridHours.earliestOpeningMinute,
		latestShiftMinute: gridHours.latestClosingMinute,
		startTimeOfDay: gridHours.startTimeOfDay,
	};
}

export function sortShiftsPerDay({ shifts }) {
	// sort shifts to days
	// creates double nested array where each entry is a group of shifts belonging to the same day
	const shiftsPerDay = shifts.reduce((acc, currentShift) => {
		const day = moment.utc(currentShift.planned_period.from).format(constants.shortDate);

		// if we have day already add the shift inside
		if (acc[day]) {
			acc = {
				...acc,
				[day]: [...acc[day], currentShift],
			};
		} else
			acc = {
				...acc,
				[day]: [currentShift],
			};

		return acc;
	}, {});

	// sort shifts in each day from earliest to latest
	return Object.keys(shiftsPerDay).map((day) => {
		// get array of shifts
		const dayShifts = shiftsPerDay[day];

		// return sorted shifts
		return sortShifts(dayShifts);
	});
}

/**
 * @function getEarliestShiftHour
 * @param {Array} shifts
 * @description gets earliest shift hour
 */
export function getEarliestShiftHour(shifts) {
	let earliestOpeningHour, latestClosingHour, earliestOpeningMinute, latestClosingMinute;

	shifts.map((shift) => {
		let startingHourOfShift = moment.utc(shift.planned_period.from, constants.dateFormat);
		let startingMinuteOfShift = startingHourOfShift.clone();

		const mmtMidnight = startingHourOfShift.clone().startOf('day');

		// get distance in hours
		startingHourOfShift = distanceFromMidnight(mmtMidnight, startingHourOfShift);
		// get distance in minutes
		startingMinuteOfShift = distanceFromMidnight(mmtMidnight, startingMinuteOfShift, 'minutes');

		if (earliestOpeningHour === undefined) {
			earliestOpeningHour = startingHourOfShift;
			earliestOpeningMinute = startingMinuteOfShift;
		}

		if (startingMinuteOfShift < earliestOpeningMinute) {
			earliestOpeningHour = startingHourOfShift;
			earliestOpeningMinute = startingMinuteOfShift;
		}

		let endingHourOfShift = moment.utc(shift.planned_period.to, constants.dateFormat);
		let endingMinuteOfShift = endingHourOfShift.clone();

		endingHourOfShift = distanceFromMidnight(mmtMidnight, endingHourOfShift);
		endingMinuteOfShift = distanceFromMidnight(mmtMidnight, endingMinuteOfShift, 'minutes');

		if (latestClosingHour === undefined) {
			latestClosingHour = endingHourOfShift;
			latestClosingMinute = endingMinuteOfShift;
		}
		if (endingMinuteOfShift > latestClosingMinute) {
			latestClosingHour = endingHourOfShift;
			latestClosingMinute = endingMinuteOfShift;
		}
	});

	// get iterator based on starting time of the earliest shift
	// if shift starts before 2 then start form 0 (midnight)
	// if it starts after 2 then subtract 2 hours and start from there
	const startTimeOfDay = earliestOpeningHour < 2 ? 0 : earliestOpeningHour - 2;

	return {
		earliestOpeningHour,
		latestClosingHour,
		earliestOpeningMinute,
		latestClosingMinute,
		startTimeOfDay,
	};
}

/**
 * @function _getShiftPlacing
 * @param {Object} shift
 * @param {Array} tableHours
 * @description returns coordinates for the shift card item
 * @description basically just returns distance from top and card height prop
 */
export function getShiftPlacing({ shift, tableHours, startTimeOfDay, hourToPixelRatio }) {
	// format shift start date
	const shiftStart = shift.planned_period.from;
	const shiftEnd = shift.planned_period.to;

	let formattedStartTime = moment.utc(shiftStart).clone().startOf('minute').format(constants.dayFormat);

	// get from minute
	const fromMinute = moment.utc(shiftStart).clone().format(constants.minute);

	// round down to hour
	if (fromMinute > 0 && fromMinute <= 15)
		formattedStartTime = moment.utc(shiftStart).startOf('hour').format(constants.dayFormat);

	// round to half hour
	if (fromMinute > 15 && fromMinute < 46)
		formattedStartTime = moment.utc(shiftStart).clone().startOf('hour').add(30, 'minutes').format(constants.dayFormat);

	// round up to nearest hour
	if (fromMinute > 45)
		formattedStartTime = moment.utc(shiftStart).add(1, 'hour').startOf('hour').format(constants.dayFormat);

	// get difference between shifts in hours
	const duration = moment.duration(moment(shiftEnd).diff(moment(shiftStart))).asHours();

	// find time from table hours to get distance from top
	const time = tableHours.find((time) => {
		return time.time === formattedStartTime;
	});

	// time.top + 40 is so that we have 2 hours of padding in front of the first shift
	// we can add more if neccessary
	return {
		// we multiply the startTimeOfDay by 40 because startTimeOfDay is an idication of
		// an hour and every hour is presented by 40 px
		top: _getShiftTopPlacing({
			hourToPixelRatio,
			top: time.top,
			startTimeOfDay,
		}),
		height: `${Math.floor(hourToPixelRatio * duration)}px`,
	};
}

/**
 * @function _stackAndGetCustomStyles
 * @param {Array} sortedShifts
 * @param {Object} shiftsMetadata
 * @description stacks shifts for each day and gets column styles based on the day with most columns
 */
function _stackAndGetCustomStyles({ sortedShifts, shiftsMetadata }) {
	return sortedShifts.reduce(
		(acc, arrayOfShifts) => {
			const stackedShiftsData = getStackedShifts({
				shifts: arrayOfShifts,
				shiftsMetadata,
			});

			const stackedShifts = stackedShiftsData.shifts;
			const columns = stackedShiftsData.columns;

			const dayCardStyle = calculateDayWidth(columns);

			let addNewStyles = true;
			if (_get(acc, 'columnsStyles.columns', false)) {
				addNewStyles = columns > acc.columnsStyles.columns;
			}

			acc = {
				shifts: [...acc.shifts, stackedShifts],
				columnsStyles: {
					columns: addNewStyles ? columns : acc.columnsStyles.columns,
					style: addNewStyles ? dayCardStyle : acc.columnsStyles.style,
				},
			};

			return acc;
		},
		{ shifts: [], columnsStyles: {} }
	);
}

/**
 * @function getStackedShifts
 * @param {Array} shifts
 * @description loops through days and in each day through different shifts.
 * If there are 2 shifts on the same day with overlapping shift times, it will put the
 * latter shift into a 2nd column
 */
export function getStackedShifts({ shifts, shiftsMetadata }) {
	// get first shifts from object - to get it's start of day
	const shiftDay = shifts[0].planned_period.from;

	// get start of day
	const mmtMidnight = moment.utc(shiftDay).startOf('day');

	// get start of day and end of day in minutes
	const startOfDay = shiftsMetadata.earliestShiftMinute;
	const endOfDay = shiftsMetadata.latestShiftMinute;

	// initially generate the first empty space before reducing
	let emptySpacesArray = [
		{
			id: _uniqueId(),
			start: startOfDay,
			end: endOfDay,
			column: 0,
		},
	];

	const stackedShifts = shifts.map((entry) => {
		// sort the array of spaces by column number - smallest first
		emptySpacesArray = _orderBy(emptySpacesArray, ['column'], ['asc']);

		// shift times from midnight in mintues
		const shiftStartFormat = moment.utc(entry.planned_period.from).diff(mmtMidnight, 'minutes');
		const shiftEndFormat = moment.utc(entry.planned_period.to).diff(mmtMidnight, 'minutes');

		// find a space the shift fits into, return new spaces and the colunn the shift belongs to
		const { newSpaces, column } = _recalculateSpaces({
			emptySpacesArray,
			shiftStartFormat,
			shiftEndFormat,
			startOfDay,
			endOfDay,
		});

		emptySpacesArray = newSpaces;

		return {
			...entry,
			column,
		};
	});

	const numberOfColumns = _getHighestColumnCount(stackedShifts);

	// calculate shift width and left prop
	const positionedShifts = _stackShifts({
		shifts: stackedShifts,
		columns: numberOfColumns,
	});

	return positionedShifts;
}

/**
 * @function distanceFromMidnight
 * @param {Moment object} midnight
 * @param {Moment object} time
 * @description calculates distance from midnight in hours
 */
function distanceFromMidnight(midnight, time, units = 'hours') {
	return time.diff(midnight, units);
}

/**
 * @function _calculateColumnSpaces
 * @param {Array} emptySpacesArray
 * @description takes a space the shift fits in and creates two spaces out of one
 */
function _calculateColumnSpaces({ spaceStart, spaceEnd, shiftStartFormat, shiftEndFormat, column }) {
	let spaceOne = {
		id: _uniqueId(),
		start: spaceStart,
		end: shiftStartFormat,
		column,
	};

	let spaceTwo = {
		id: _uniqueId(),
		start: shiftEndFormat,
		end: spaceEnd,
		column,
	};

	// check if they have the same start/end time
	if (spaceOne.start === spaceOne.end) spaceOne = null;
	if (spaceTwo.start === spaceTwo.end) spaceTwo = null;

	// if the shift fits perfectly into the space, we need to create a new one
	if (spaceOne === null && spaceTwo === null) {
		return [
			{
				id: _uniqueId(),
				start: spaceStart,
				end: spaceEnd,
				column: column + 1,
			},
		];
	}

	return _compact([spaceOne, spaceTwo]);
}

/**
 * @function stackShifts
 * @param {Array} shifts
 * @param {Number} columns - number of columns
 * @description receives shift array and number of columns and based on that generates
 * @description styles - width and left prop
 */
function _stackShifts({ shifts, columns }) {
	// width is always 100% divided by number of columns
	const width = 100 / columns;

	const mappedShifts = shifts.map((shift, index) => {
		return {
			...shift,
			style: {
				...shift.style,
				width: `${Math.round(width - 1)}%`,
				left: `${Math.round(shift.column * width)}%`,
			},
		};
	});

	return {
		shifts: mappedShifts,
		columns,
	};
}

/**
 * @function calculateDayWidth
 * @param {Number} columns
 * @description calculates day width based on columns
 * @cases
 * 	1. 3 columns - 5 days initially
 * 	2. 4 columns - 4 days initially
 *  3. 5 columns - 3 days initially
 * 	4. 6/7 columns - 2 days initially
 */
export function calculateDayWidth(columns) {
	switch (columns) {
		case 2:
			return 'seven-days';
		case 3:
			return 'five-days';
		case 4:
			return 'four-days';
		case 5:
			return 'three-days';
		case 6:
		case 7:
			return 'two-days';
		default:
			return false;
	}
}

export function conditionallyUpdateShifts({ shiftToUpdate }) {
	const state = _getState();

	const isMounted = state.myShiftPlanner.isMounted;

	if (!isMounted) return;

	const personId = _get(state.userData, 'user.user.person.id', null);
	const persons = state.myShiftPlannerPersons.persons;
	const { currentStartOfPeriod, endOfPeriod, shifts } = state.myShiftPlanner;

	let extendedPersons = [...persons, { value: personId }];
	extendedPersons = extendedPersons.map((entry) => ({ id: entry.value }));

	const shiftAction = getShiftActionToPerform({
		shiftToUpdate,
		persons: extendedPersons,
		currentStartOfPeriod,
		endOfPeriod,
		shifts,
		isShiftplanner: false,
	});

	if (!shiftAction) return;

	if (shiftAction === ShiftTransferEnums.SHIFT_ACTIONS.ADD) {
		store.dispatch(addShift(shiftToUpdate));
	}
	if (shiftAction === ShiftTransferEnums.SHIFT_ACTIONS.UPDATE) {
		store.dispatch(updateShift(shiftToUpdate));
	}
	if (shiftAction === ShiftTransferEnums.SHIFT_ACTIONS.REMOVE) {
		store.dispatch(removeShift(shiftToUpdate));
	}
}

export function getShiftsFilter({ currentStartOfPeriod, asShiftMarketplace, persons, personId, endOfPeriod }) {
	let filter = `:planned_period.from=ge='${currentStartOfPeriod}';:planned_period.from=le='${endOfPeriod}';`;

	// if more persons passed construct filter string
	// if shift marketplace view, don't fetch shifts from person selector (person selector won't even be shown)
	if (!_isEmpty(persons) && !asShiftMarketplace) {
		// :shift_employees.employment.person.id=IN=['1', '2', '3'...]
		const personsFilter = persons.reduce((acc, currentValue, currentIndex) => {
			const id = currentValue.value;

			acc = persons.length - 1 === currentIndex ? `${acc}'${id}']` : `${acc}'${id}',`;

			return acc;
		}, `:shift_employees.employment.person.id=IN=['${personId}',`);
		filter = `${filter}${personsFilter}`;
	} else {
		filter = `${filter}:shift_employees.employment.person.id=='${personId}'`;
	}

	return filter;
}

export function getShiftTransfersFilter({ filteredWorkplaces }) {
	const workplacesFilter = filteredWorkplaces.reduce((acc, currentValue, currentIndex) => {
		const id = currentValue ?? null;

		acc = filteredWorkplaces.length - 1 === currentIndex ? `${acc}'${id}']` : `${acc}'${id}',`;

		return acc;
	}, `:shift.workplace.id=IN=[`);

	return workplacesFilter;
}

export function getShiftTransferPoliciesFilter({ marketId }) {
	const now = moment.utc().format(constants.shortDate);
	const filter = `:market.id=='${marketId}';:period.from=le='${now}';:period.to=ge='${now}';:type=='${ShiftTransferEnums.POLICIES.REQUEST_PUBLIC_SHIFT}'`;

	return filter;
}

export function formatShiftTransferShifts(shiftTransfers) {
	return shiftTransfers.map((entry) => ({
		...entry.shift,
		shiftTransfer: _omit(entry, ['shift']),
	}));
}

/**
 * @function mergeNewShiftTransferApplication
 * @param {Object} old data and new data
 * @description merges a newly created shif transfer application into al already
 * existing array of shift transfer applications
 * @returns {Object}
 */
export function mergeNewShiftTransferApplication({ shift, res }) {
	// add newly created application to the shift obejct
	const formattedShift = {
		...shift,
		shiftTransfer: {
			...shift.shiftTransfer,
			applications: [...shift.shiftTransfer.applications, _omit(res.data[0], ['transfer'])],
		},
	};

	return formattedShift;
}

/**
 * @function mergeUpdatedShiftTransferApplication
 * @param {Object} old data and new data
 * @description merges an updated shif transfer application into al already
 * existing array of shift transfer applications
 * @returns {Object}
 */
export function mergeUpdatedShiftTransferApplication({ data, res }) {
	// replace/update the new application in the applications array
	const updatedApplications = data.shift.shiftTransfer.applications.map((entry) => {
		if (entry.id === res.data[0].id) return _omit(res.data[0], ['transfer']);

		return entry;
	});

	const formattedShiftTransferShift = {
		...data.shift,
		shiftTransfer: {
			...data.shift.shiftTransfer,
			applications: updatedApplications,
		},
	};

	return formattedShiftTransferShift;
}

export function getAreUnassignedShiftsVisible(shifts) {
	return shifts.some((entry) => _isEmpty(entry.shift_employees));
}

function _getState() {
	return store.getState();
}

function _getShiftTopPlacing({ hourToPixelRatio, top, startTimeOfDay }) {
	// shift distance from top is {top}
	// one hour of shift is 40px {hourToPixelRatio}
	// first get actual distance from top based on start time of day
	// in the end add 40px so that our headers are always above first shift
	// example: shift starts at 03:00 (top is then 3 x 40 = 120px) but day starts at 01:00
	// that logic would place the shift at 04:00 instead of 03:00 - that's why we multiply startTimeOfDay with hourToPixelRatio
	return `${top - startTimeOfDay * hourToPixelRatio + hourToPixelRatio}px`;
}

function _getHighestColumnCount(shifts) {
	let highestColumnCount = 0;

	for (const shift of shifts) {
		if (shift.column > highestColumnCount) highestColumnCount = shift.column;
	}

	return highestColumnCount + 1;
}

function _recalculateSpaces({ emptySpacesArray, shiftStartFormat, shiftEndFormat, startOfDay, endOfDay }) {
	// find the space for the shift
	const space = emptySpacesArray.find((entry) => {
		const spaceStart = entry.start;
		const spaceEnd = entry.end;

		// does the shift fit into the space
		const shiftStartFits = shiftStartFormat >= spaceStart && shiftStartFormat < spaceEnd;
		const shiftEndFits = shiftEndFormat > spaceStart && shiftEndFormat <= spaceEnd;

		if (shiftEndFits && shiftStartFits) {
			return true;
		}
	});

	if (space) {
		// recalculate the spaces, we will add 2 extra always
		// unless the entry start and end are the same, in that case we delete that entry
		const res = _calculateColumnSpaces({
			spaceStart: space.start,
			spaceEnd: space.end,
			shiftStartFormat,
			shiftEndFormat,
			column: space.column,
		});

		// remove that space from empty spaces array (the one we fitted the shift in, it will be replace with new entry/s)
		const filteredSpacesArray = emptySpacesArray.filter((spaceToFilter) => spaceToFilter.id !== space.id);

		// add new spaces into it
		emptySpacesArray = [...filteredSpacesArray, ...res];

		return {
			newSpaces: emptySpacesArray,
			column: space.column,
		};
	} else {
		let biggestColumn = 0;
		for (const space of emptySpacesArray) {
			if (space.column > biggestColumn) biggestColumn = space.column;
		}
		// bigggest column + 1 to go into an other column (since shift didn't fit into previous ones)
		biggestColumn = biggestColumn + 1;

		// automatically create 2 new spaces in which our shift fits
		const newSpaces = _calculateColumnSpaces({
			spaceStart: startOfDay,
			spaceEnd: endOfDay,
			shiftStartFormat,
			shiftEndFormat,
			column: biggestColumn,
		});

		// add new spaces into it
		emptySpacesArray = [...emptySpacesArray, ...newSpaces];

		return {
			newSpaces: emptySpacesArray,
			column: biggestColumn,
		};
	}
}
