'use strict';

import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './ingredientConfigurations.phrases';

/**
 * @function fetchIngredientsConfigurations
 * @description Fetch all ingredient configurations
 * @param {object} state
 */
export function fetchIngredientsConfigurations(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get('/pos/ingredient_configurations', params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addIngredientConfiguration
 * @description adds an ingredient configuration
 */
export function addIngredientConfiguration(data) {
	return post('/pos/ingredient_configurations', data)
		.then(res => {
			setFeedback(phrases.INGREDIENT_CONFIGURATION_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editIngredientConfiguration
 * @description edits ingredient configuration
 */
export function editIngredientConfiguration(ingredientConfigurationId, data) {
	return post(
		`/pos/ingredient_configurations/${ingredientConfigurationId}`,
		data
	)
		.then(res => {
			setFeedback(phrases.INGREDIENT_CONFIGURATION_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteIngredientConfiguration
 * @description deletes an ingredient configuration
 */
export function deleteIngredientConfiguration(ingredientConfigurationId) {
	return remove(`/pos/ingredient_configurations/${ingredientConfigurationId}`)
		.then(res => {
			setFeedback(phrases.INGREDIENT_CONFIGURATION_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
