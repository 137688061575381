import { ActionType } from 'types/redux';
import { ICompanyReduxValue } from './../types';

export const SET_COMPANY = 'COMPANIES/SET_COMPANY';
export const UPDATE_COMPANY = 'COMPANIES/UPDATE_COMPANY';
export const RESET_COMPANY = 'COMPANIES/RESET_COMPANY';

export function setCompany(payload: ICompanyReduxValue): ActionType {
	return {
		type: SET_COMPANY,
		payload,
	};
}

type updateCompany = Partial<ICompanyReduxValue>;
export function updateCompany(payload: updateCompany): ActionType {
	return {
		type: UPDATE_COMPANY,
		payload,
	};
}

export function resetCompany(): ActionType {
	return {
		type: RESET_COMPANY,
	};
}
