'use strict';

import {
	SET_POS_CONFIGURATION_PRODUCT_VARIANT_INGREDIENT_VARIATION,
	RESET_POS_CONFIGURATION_PRODUCT_VARIANT_INGREDIENT_VARIATION,
	UPDATE_POS_CONFIGURATION_PRODUCT_VARIANT_INGREDIENT_VARIATION,
} from './posConfigurationProductVariantIngredientVariations.actions';

const defaultState = {
	data: {
		posConfigurationProductVariantIngredientVariation: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_POS_CONFIGURATION_PRODUCT_VARIANT_INGREDIENT_VARIATION: {
			return {
				...state,
				data: {
					...state.data,
					posConfigurationProductVariantIngredientVariation: payload,
				},
			};
		}

		case UPDATE_POS_CONFIGURATION_PRODUCT_VARIANT_INGREDIENT_VARIATION: {
			return {
				...state,
				data: {
					...state.data,
					posConfigurationProductVariantIngredientVariation: payload,
				},
			};
		}

		case RESET_POS_CONFIGURATION_PRODUCT_VARIANT_INGREDIENT_VARIATION: {
			return {
				...state,
				data: {
					...state.data,
					posConfigurationProductVariantIngredientVariation: {},
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
