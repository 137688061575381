import {
	LOADING_REPORT,
	SET_HOTBAR_DATA,
	CLEAN_DATA,
	SET_DAILY_REPORT,
	TOGGLE_EXPAND_ALL_MARKETS,
	TOGGLE_EXPAND_ALL_GROUPS,
	TOGGLE_EXPAND_MARKET,
	TOGGLE_EXPAND_GROUP_WITHIN_MARKET,
	SET_DAILY_REPORT_EMAIL_RECIPIENTS,
} from './dailyReport.actions';

const defaultState = {
	ui: {
		loadingReport: false,
		expandedMarkets: [],
		expandedGroups: [],
	},
	data: {
		hotbarData: {},
		dailyReport: {},
		franchiseReport: {},
		emailRecipients: [],
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
		case LOADING_REPORT: {
			return {
				...state,
				ui: {
					...state.ui,
					loadingReport: payload.loadingReport,
				},
			};
		}

		case SET_DAILY_REPORT: {
			return {
				...state,
				data: {
					...state.data,
					...payload,
				},
			};
		}

		case SET_HOTBAR_DATA: {
			return {
				...state,
				data: {
					...state.data,
					...payload,
				},
			};
		}

		case CLEAN_DATA: {
			return {
				...state,
				data: defaultState.data,
				ui: defaultState.ui,
			};
		}

		case SET_DAILY_REPORT_EMAIL_RECIPIENTS: {
			return {
				...state,
				data: {
					...state.data,
					emailRecipients: payload.emailRecipients.map(
						(recipient) => recipient.email
					),
				},
			};
		}

		case TOGGLE_EXPAND_ALL_MARKETS: {
			const allMarkets = [
				...(state.data.dailyReport.markets || []),
				...(state.data.franchiseReport.markets || []),
			];
			const marketIds = payload.expand
				? allMarkets.map((entry) => entry.market.id)
				: [];

			return {
				...state,
				ui: {
					...state.ui,
					expandedMarkets: marketIds,
				},
			};
		}

		case TOGGLE_EXPAND_ALL_GROUPS: {
			const allGroups = [
				...(state.data.dailyReport.groups || []),
				...(state.data.franchiseReport.groups || []),
				...(state.data.dailyReport.territoryStoreAreaTypeEntries || []),
				...(state.data.franchiseReport.territoryStoreAreaTypeEntries || []),
			];
			const groupIds = payload.expand ? allGroups.map((entry) => entry.id) : [];

			return {
				...state,
				ui: {
					...state.ui,
					expandedGroups: groupIds,
				},
			};
		}

		case TOGGLE_EXPAND_MARKET: {
			const expandedMarkets = new Set(state.ui.expandedMarkets);

			expandedMarkets.has(payload.marketId)
				? expandedMarkets.delete(payload.marketId)
				: expandedMarkets.add(payload.marketId);

			return {
				...state,
				ui: {
					...state.ui,
					expandedMarkets: [...expandedMarkets],
				},
			};
		}

		case TOGGLE_EXPAND_GROUP_WITHIN_MARKET: {
			const expandedGroups = new Set(state.ui.expandedGroups);

			expandedGroups.has(payload.groupId)
				? expandedGroups.delete(payload.groupId)
				: expandedGroups.add(payload.groupId);

			return {
				...state,
				ui: {
					...state.ui,
					expandedGroups: [...expandedGroups],
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
