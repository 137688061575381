import {
	SET_REPORT,
	RESET_REPORT,
	SET_LOADER
} from './clockinOutReport.actions';

const defaultState = {
	ui: {
		loading: false
	},
	data: {}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_REPORT: {
		return {
			...state,
			data: payload
		};
	}

	case RESET_REPORT: {
		return {
			...state,
			data: {}
		};
	}

	case SET_LOADER: {
		return {
			...state,
			ui: {
				...state.ui,
				loading: payload
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
