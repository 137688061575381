'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Icon, ButtonGroup, InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';

class ModalBatchStepFormIngredients extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false
		};

		this.ingredients = '/product/ingredients';
	}

	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		updateBatchForm({
			[type]: e
		});
	}

	render() {
		const { batchFormData, toggleOverlay, ingredientsFilter } = this.props;

		return (
			<ModalRow>
				<ButtonGroup type="full-width">
					<InputCollectionSelect
						className="modal-batch-select"
						id="ingredients"
						placeholder="Select ingredients"
						value={batchFormData.ingredients}
						handleChange={(x, value) =>
							this.editStoreEntry(value, 'ingredients')
						}
						multi
						cache
						apiPath={this.ingredients}
						params={{
							limit: 50,
							filter: ingredientsFilter
						}}
						optionFormat={entry => ({
							value: entry,
							label: entry.name
						})}
						inputFilterFormat={input => `:ingredient.name=like='%${input}%'`}
					/>

					<Button
						type="inverted"
						shadow
						size="large"
						onClick={() => toggleOverlay(this.ingredients)}>
						<Icon name="more" />
					</Button>
				</ButtonGroup>
			</ModalRow>
		);
	}
}

ModalBatchStepFormIngredients.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
	ingredientsFilter: PropTypes.string
};

export default ModalBatchStepFormIngredients;
