'use strict';

import { SET_USER, UPDATE_USER, RESET_USER } from './users.actions';

const defaultState = {
	data: {
		user: {
			active: true,
		},
		initialUser: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_USER: {
			return {
				...state,
				data: {
					initialUser: payload,
					user: payload,
				},
			};
		}

		case UPDATE_USER: {
			return {
				...state,
				data: {
					...state.data,
					user: {
						...state.data.user,
						...payload,
					},
				},
			};
		}

		case RESET_USER: {
			return {
				...defaultState,
			};
		}

		default:
			return state;
	}
}

export default reducer;
