export const RESET_PRODUCT_VARIANT_ADDON =
	'SALES_CONFIGURATION_PRODUCT_VARIANT_ADD_ON/RESET_PRODUCT_VARIANT_ADDON';
export const UPDATE_PRODUCT_VARIANT_ADDON =
	'SALES_CONFIGURATION_PRODUCT_VARIANT_ADD_ON/UPDATE_PRODUCT_VARIANT_ADDON';
export const SET_PRODUCT_VARIANT_ADDON =
	'SALES_CONFIGURATION_PRODUCT_VARIANT_ADD_ON/SET_PRODUCT_VARIANT_ADDON';

/**
 * @param {object} payload
 */
export function updateProductVariantAddon(payload) {
	return {
		type: UPDATE_PRODUCT_VARIANT_ADDON,
		payload,
	};
}

export function setProductVariantAddon(payload) {
	return {
		type: SET_PRODUCT_VARIANT_ADDON,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetProductVariantAddon() {
	return {
		type: RESET_PRODUCT_VARIANT_ADDON,
	};
}
