import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './organisationMarketPayrollPeriods.phrases';

/**
 * @function fetchMarketPayrollPeriods
 * @description Fetch all market payroll periods
 * @param {object} state
 */
export function fetchMarketPayrollPeriods(state, newFilter) {
	const filter = state.filter ? `${state.filter};${newFilter}` : newFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get('/salary/payroll_periods', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addMarketPayrollPeriod
 * @description adds market payroll period
 */
export function addMarketPayrollPeriod(data) {
	return post('/salary/payroll_periods', data)
		.then((res) => {
			setFeedback(phrases.MARKET_PAYROLL_PERIOD_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editMarketPayrollPeriod
 * @description edits market payroll period
 */
export function editMarketPayrollPeriod(id, data) {
	return post(`/salary/payroll_periods/${id}`, data)
		.then((res) => {
			setFeedback(phrases.MARKET_PAYROLL_PERIOD_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteMarketPayrollPeriod
 * @description deletes market payroll period
 */
export function deleteMarketPayrollPeriod(productId) {
	return remove(`/salary/payroll_periods/${productId}`)
		.then((res) => {
			setFeedback(phrases.MARKET_PAYROLL_PERIOD_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
