/* eslint-disable react/prop-types */
import React from 'react';

// components
import { InputCollectionSelect } from 'dumb';
import { DateFilterSelector, FilterProvider } from 'reactDataWrapper/utilities';

import { IColumnHeader } from 'types/reactDataWrapper';
import { SelectOption } from 'types/select';

// enums
import collectionSelectEnums from 'services/enums/collectionSelect';

export default function getColumns(
	reduxKey: string
): IColumnHeader<IAdministrationMarketResponsibilities>[] {
	const columns: IColumnHeader<IAdministrationMarketResponsibilities>[] = [
		{
			Header: 'Role',
			id: 'role',
			accessor: (d) => d.role?.name ?? '',
			filterPath: ':role.name',
		},

		{
			Header: 'Location',
			id: 'location',
			accessor: (d) => d.location?.name,
			filterPath: ':location.name',
			Filter: ({ column }) => (
				<FilterProvider
					reduxKey={reduxKey}
					columnId={column.id}
					provide={(
						filterValue: SelectOption,
						persistToFilterStorage: ({
							handle,
							value,
						}: {
							handle: string;
							value: SelectOption;
						}) => void
					): JSX.Element => (
						<InputCollectionSelect
							id={column.id}
							name="location"
							value={filterValue}
							handleChange={(key: string, value: SelectOption): void => {
								persistToFilterStorage({ handle: key, value });
							}}
							styleType={collectionSelectEnums.TYPE_IN_TABLE}
							cache
							apiPath="/administration/markets"
							params={{
								limit: 30,
							}}
							optionFormat={(entry: IAdministrationMarket): SelectOption => ({
								value: entry.id,
								label: entry.name,
							})}
							inputFilterFormat={(input: string): string =>
								`:name=like='%${input}%'`
							}
						/>
					)}
				/>
			),
		},
		{
			Header: 'From',
			id: 'from',
			accessor: (d) => d.period?.from ?? '',
			filterPath: ':period.from',
			Filter: ({ column }) => (
				<DateFilterSelector
					reduxKey={reduxKey}
					columnId={column.id}
					dateIdentifier="from"
				/>
			),
		},
		{
			Header: 'To',
			id: 'to',
			accessor: (d) => d.period?.to ?? '',
			filterPath: ':period.to',
			Filter: ({ column }) => (
				<DateFilterSelector
					reduxKey={reduxKey}
					columnId={column.id}
					dateIdentifier="to"
				/>
			),
		},
	];

	return columns;
}
