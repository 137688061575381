import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchProductCategories
 * @description Fetch all productes values
 * @param {object} state
 */
export function fetchProductCategories(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get(`/product/product_categories`, params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addProductCategories
 * @description add
 */
export function addProductCategories(data) {
	return post(`/product/product_categories`, data)
		.then((res) => {
			setFeedback('product category added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editProductCategories
 * @description add
 */
export function editProductCategories(id, data) {
	return post(`/product/product_categories/${id}`, data)
		.then((res) => {
			setFeedback('product category edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteProductCategories
 * @description del
 */
export function deleteProductCategories(productId) {
	return remove(`/product/product_categories/${productId}`)
		.then((res) => {
			setFeedback('product category deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
