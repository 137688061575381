import React, { useRef, useState, useEffect } from 'react';

// interfaces
import { IDynamicAccountCRUD } from './../../types';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import {
	resetState,
	rerenderTable,
} from './../../store/dynamicAccounts.actions';

// components
import { ModalStepper } from 'dumb';
import DynamicAccountsModalStep from './components/dynamicAccountsModalStep';

// services
import { addDynamicAccounts } from './../../dynamicAccounts.service';

// phrases/ enums
import phrases from './../../dynamicAccounts.phrases';
import { EntryTypeEnum } from 'types/enums';

type Props = {
	onClose: () => void;
};

const DynamicAccountsModalBatch = ({ onClose }: Props): JSX.Element => {
	const modalRef = useRef() as React.LegacyRef<ModalStepper>;
	const [loading, setLoading] = useState(false);
	const dispatch = useAppDispatch();
	const batchList = useAppSelector((store) => store.dynamicAccounts.batchList);

	const onNext = () => {
		setLoading(true);

		const payload: IDynamicAccountCRUD[] = batchList.map((entry) => {
			// get indexes of enum objects
			const indexOfEntryTypeEnum = Object.keys(EntryTypeEnum).indexOf(
				entry.entryType?.value ?? ''
			);

			const valueOfEntryTypeEnum =
				Object.values(EntryTypeEnum)[indexOfEntryTypeEnum];

			return {
				account_number: entry.accountNumber,
				type: entry.type?.value,
				entry_type: valueOfEntryTypeEnum,
			};
		});

		addDynamicAccounts({ batch: payload })
			.then(() => {
				setLoading(false);
				dispatch(rerenderTable());
				onClose();
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const steps = [
		{
			component: <DynamicAccountsModalStep />,
			title: phrases.MODAL_TITLE,
			onBack: onClose,
			onNext,
			loading,
			isValid: true,
			confirmButtonLabel: phrases.SUBMIT,
			defaultStyles: false,
			noScroll: false,
		},
	];

	useEffect(() => {
		return () => {
			dispatch(resetState());
		};
	}, [dispatch]);

	return (
		<ModalStepper
			header={phrases.MODAL_TITLE}
			onClose={onClose}
			ref={modalRef}
			isOpen
			steps={steps}
			showStep={false}
		/>
	);
};

export default DynamicAccountsModalBatch;
