import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './text.css';

const Text = ({
	children,
	className,
	size,
	alignCenter,
	ellipsis,
	bold,
	noWrap,
	refProp,
	onMouseEnter,
	onMouseLeave,
}) => {
	const classN = classnames('j-text', {
		'j-text--align-center': !!alignCenter,
		'j-text--ellipsis': !!ellipsis,
		'j-text--bold': !!bold,
		'j-text--no-wrap': !!noWrap,
		[`j-text--${size}`]: !!size,
		[`${className}`]: className,
	});

	return (
		<p
			className={classN}
			ref={refProp}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}>
			{children}
		</p>
	);
};

Text.defaultProps = {
	size: 'medium',
};

Text.propTypes = {
	className: PropTypes.string,
	children: PropTypes.any.isRequired,
	alignCenter: PropTypes.bool,
	bold: PropTypes.bool,
	size: PropTypes.string,
	ellipsis: PropTypes.bool,
	noWrap: PropTypes.bool,
	refProp: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
};

export default Text;
