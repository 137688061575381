'use strict';

/**
 * @function initDefaultHeaders
 * @memberOf SERVICES.__models
 * @description
 * Adds default headers to headers
 * @param  {service} translations  {@link translations}
 * @param  {array} headers  headers to add prefix and suffix headers to
 */

import _each from 'lodash/each';
import _findIndex from 'lodash/findIndex';

module.exports = function(translations, headers) {
	const prefixHeaders = [
		{
			name: 'id',
			type: 'id',
			label: translations.getSingle('MODELS.HEADERS.ID'),
			hidden: true,
			localHidden: true,
			colGroup: 'id',
			colGroupLabel: translations.getSingle('MODELS.COLGROUPS.ID'),
			emptyColGroup: true
		}
	];
	const suffixHeaders = [
		{
			name: 'created',
			type: 'datetime',
			label: translations.getSingle('MODELS.HEADERS.CREATED'),
			hidden: true,
			localHidden: true,
			canFilter: false,
			colGroup: 'timestamps',
			colGroupLabel: translations.getSingle('MODELS.COLGROUPS.TIMESTAMPS'),
			emptyColGroup: true
		}, {
			name: 'updated',
			type: 'datetime',
			label: translations.getSingle('MODELS.HEADERS.UPDATED'),
			hidden: true,
			localHidden: true,
			canFilter: false,
			colGroup: 'timestamps',
			colGroupLabel: translations.getSingle('MODELS.COLGROUPS.TIMESTAMPS'),
			emptyColGroup: true
		}
	];

	_each(prefixHeaders, function(h) {
		if (_findIndex(headers, {name: h.name}) === -1) headers.unshift(h);
	});

	_each(suffixHeaders, function(h) {
		if (_findIndex(headers, {name: h.name}) === -1) headers.push(h);
	});

	this.setHeaders(headers);
};