export const SET_FILTER = 'TRANSACTIONS_LOOKUP/SET_FILTER';
export const RESET_FILTER = 'TRANSACTIONS_LOOKUP/RESET_FILTER';

export function setFilter(payload) {
	return {
		type: SET_FILTER,
		payload,
	};
}

export function resetFilter(payload) {
	return {
		type: RESET_FILTER,
		payload,
	};
}
