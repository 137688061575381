import React, { Component, ErrorInfo } from 'react';

// components
import { Box, Typography } from '@mui/material';

// utils
import { withScope, captureException, captureMessage } from '@sentry/browser';

type MyState = {
	hasError: boolean;
};

type MyProps = {
	children: JSX.Element;
};

class ErrorBoundary extends Component<MyProps, MyState> {
	state: MyState = {
		hasError: false,
	};

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		// You can also log the error to an error reporting service
		withScope((scope) => {
			scope.setExtra('error', error);
			scope.setExtra('errorInfi', errorInfo);
			captureException(error);
			captureMessage('Error boundry caught error!');
		});
	}

	render() {
		if (this.state.hasError) {
			return (
				<Box
					sx={{
						marginTop: '10%',
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Typography variant="h3">Oops... Something went wrong...</Typography>
					<Typography variant="body1">
						Contact HQ Support by clicking on the &quot;Issues or
						feedback?&quot; button bellow
					</Typography>
					<Box mt={2}>
						<a href={window.location.pathname}>Refresh page</a>
					</Box>
				</Box>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
