import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { resetState, rerenderTable } from './../../store/nutritionCategoryTranslations.actions';

// components
import { ModalStepper } from 'dumb';
import NutritionCategoryTranslationsModalStep from './components/nutritionCategoryTranslationsModalStep';

// services
import { addNutritionCategoryTranslations } from './../../nutritionCategoryTranslations.service';

// phrases
import phrases from './../../nutritionCategoryTranslations.phrases';

const NutritionCategoryTranslationsModalBatch = ({ allergenId, onClose }) => {
	const modalRef = useRef();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const batchList = useSelector((store) => store.nutritionCategoryTranslations.batchList);

	const onNext = () => {
		setLoading(true);

		const payload = batchList.map((entry) => ({
			nutrition_category: allergenId,
			name: entry.name,
			language: entry.language?.value?.id ?? null,
		}));

		addNutritionCategoryTranslations({ batch: payload })
			.then(() => {
				setLoading(false);
				dispatch(rerenderTable());
				onClose();
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const steps = [
		{
			component: <NutritionCategoryTranslationsModalStep />,
			title: phrases.MODAL_TITLE,
			onBack: onClose,
			onNext,
			loading,
			isValid: true,
			confirmButtonLabel: phrases.SUBMIT,
			defaultStyles: false,
			noScroll: false,
		},
	];

	useEffect(() => {
		return () => {
			dispatch(resetState());
		};
	}, [dispatch]);

	return (
		<ModalStepper header={phrases.MODAL_TITLE} onClose={onClose} ref={modalRef} isOpen steps={steps} showStep={false} />
	);
};

NutritionCategoryTranslationsModalBatch.propTypes = {
	onClose: PropTypes.func,
	allergenId: PropTypes.number,
};

export default NutritionCategoryTranslationsModalBatch;
