'use strict';

import { get, post, remove } from 'api.vanilla.service';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { formatErrorMessage } from 'services/api/helpers';
import moment from 'moment';

// constants
import constants from 'services/constants';

// utils
import { saveAs } from 'file-saver';

/**
 * @function fetchOnboardings
 * @description Function for fetching onboardings
 * @param {*} param0
 * @param {*} marketId
 */
export function fetchOnboardings({ limit, offset, filter, sort, headers }) {
	// Set params and filter
	const params = {
		limit,
		offset,
		filter,
		sort,
	};

	return get('/hr/onboardings', params, null, headers);
}

/**
 * @function signOnboarding
 * @param {object} {}
 * @param {number} id - id of the onboarding to sign
 * @param {object} body - info like time, status...
 */
export function signOnboarding({ id, body }) {
	return post(`/hr/onboardings/${id}`, body)
		.then((res) => {
			setFeedback('Onboarding signed', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 1);
			return err;
		});
}

/**
 * @function deleteOnboarding
 * @param {object} {}
 * @param {number} id - id of the onboarding to delete
 */
export function deleteOnboarding({ id }) {
	return remove(`/hr/onboardings/${id}`)
		.then((res) => {
			setFeedback('Onboarding deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 1);
			throw err;
		});
}

/**
 * @function exportOnboardings
 * @description exports onboardings
 */
export function exportOnboardings(extraParams = {}, reduxKey) {
	const headers = {
		Accept: 'application/csv',
	};

	return get('/hr/onboarding_exports', extraParams, null, headers)
		.then((csv) => {
			const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });

			// outputs filename in format of "redux_key__2019_04_24"
			const csvFileName = `${reduxKey.replace('/', '_')}__export__${moment().format(constants.shortDate)}.csv`;
			const fileName = csvFileName;
			saveAs(blob, fileName);
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 1);
			throw err;
		});
}
