'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setProductBundle,
	updateProductBundle,
	resetProductBundle,
} from './store/productBundles.actions';

// services
import {
	fetchProductBundles,
	editProductBundle,
	editProductBundles,
	addProductBundle,
	deleteProductBundle,
} from './productBundles.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';
import { Input, Button, Icon, Tabs, Toggle } from 'dumb';

import ProductBundlesModalBatch from './components/batch/productBundlesModalBatch';
import ProductBudleSets from './components/productBundleSets/productBundleSets.component';
import ProductBundleRules from './components/productBundleRules/productBundleRules.components';
import ProductBundleTranslations from './components/productBundleTranslations/productBundleTranslations.component';

// lodash
import _get from 'lodash/get';

// phrases/constants
import phrases from './productBundles.phrases';
import constants from 'services/constants';
import moment from 'moment';

class ProductBundles extends Component {
	constructor(props) {
		super(props);

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.addProductBundleWrapper = this.addProductBundleWrapper.bind(this);

		this.state = {
			showModal: false,
			key: moment.utc(),
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this.fetchData = this.fetchData.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'desc',
				accessor: (d) => _get(d, 'description', ''),
				filterPath: ':description',
			},
			{
				Header: 'Sort order',
				id: 'sortOrder',
				accessor: (d) => _get(d, 'sort_order', ''),
				filterPath: ':sort_order',
			},
			{
				Header: 'Created',
				id: 'created',
				accessor: (d) => _get(d, 'created', ''),
				filterPath: ':created',
				filterable: false,
				Cell: (d) => <span>{moment.utc(d.original.created).format(constants.shortDate)}</span>,
			},
			{
				Header: 'Updated',
				id: 'updated',
				accessor: (d) => _get(d, 'updated', ''),
				filterPath: ':updated',
				filterable: false,
				Cell: (d) => <span>{moment.utc(d.original.updated).format(constants.shortDate)}</span>,
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: 'legacy',
				filterPath: ':legacy',
				filterable: false,
				Cell: (d) => {
					return <Input id="checkbox" type="checkbox" checked={d.original.legacy} disabled />;
				},
			},
		];
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}
			>
				Legacy
			</Button>
		);
	}

	editEntry() {
		const { defaultProductBundle } = this.props;

		const payload = {
			id: defaultProductBundle.id,
			name: defaultProductBundle.name,
			description: defaultProductBundle.description,
			sort_order: defaultProductBundle.sortOrder,
			legacy: !!defaultProductBundle.legacy,
		};

		return editProductBundle(payload);
	}

	editMultiple(selectedRows) {
		const { defaultProductBundle } = this.props;

		const payload = {
			...(defaultProductBundle.name && {
				name: defaultProductBundle.name,
			}),
			...(defaultProductBundle.description && {
				description: defaultProductBundle.description,
			}),
			...(defaultProductBundle.sortOrder && {
				sort_order: defaultProductBundle.sortOrder,
			}),
			legacy: !!defaultProductBundle.legacy,
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editProductBundles({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteProductBundle(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			sortOrder: data.sort_order,
			legacy: data.legacy,
		};

		this.props.setProductBundle(payload);
	}

	getEditableCells(e) {
		const { defaultProductBundle } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={defaultProductBundle.name || ''}
						onChange={(event) => this.editStoreEntry('name', event)}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={defaultProductBundle.description || ''}
						onChange={(event) => this.editStoreEntry('description', event)}
					/>
				),
			},
			{
				header: 'Sort order',
				value: (
					<Input
						id="sort_order"
						type="number"
						placeholder="Enter sort order..."
						value={defaultProductBundle.sortOrder || null}
						onChange={(event) => this.editStoreEntry('sortOrder', event)}
					/>
				),
			},
			{
				header: 'Legacy',
				value: (
					<Toggle
						id="legacy"
						toggled={!!defaultProductBundle.legacy}
						onClick={(event) => this.editStoreEntry('legacy', event)}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const value = e.target ? e.target.value : e;

		const updateObject = {
			[name]: value,
		};

		this.props.updateProductBundle(updateObject);
	}

	fetchData(state) {
		return fetchProductBundles(state);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetProductBundle();
	}

	addProductBundleWrapper(data) {
		return addProductBundle(data).then(() => {
			this.setState(() => ({
				key: moment.utc(),
			}));
		});
	}

	render() {
		const { batchList, editBatchListItem, addBatchListItemRow, removeBatchListItem } = this.props;

		const legacyFilter = this.state.legacyFilterOn ? this.state.legacyFilter : '';

		return (
			<>
				<ReactDataWrapper
					accessAreasAllowedToEdit={['Sales Configuration']}
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey="product/bundles"
					manual
					subcomponent={(row) => {
						const tabContent = [
							{
								name: phrases.PRODUCT_BUDLE_SETS,
								component: <ProductBudleSets productBundle={row.original} />,
							},
							{
								name: phrases.PRODUCT_BUNDLE_RULES,
								component: <ProductBundleRules productBundle={row.original} />,
							},
							{
								name: phrases.PRODUCT_BUNDLE_TRANSLATIONS,
								component: <ProductBundleTranslations id={row.original.id} />,
							},
						];

						return (
							<SubTable>
								<Tabs tabContent={tabContent} />
							</SubTable>
						);
					}}
					editableCells={this.getEditableCells()}
					editEntry={(e) => this.editEntry(e)}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetProductBundle}
					actionRender={
						<Button type="inverted" label="Batch" shadow onClick={() => this.toggleModal()}>
							<Icon name="add" />
						</Button>
					}
					customAreaComponents={this.getLegacyFilterButton()}
					extraFilters={legacyFilter}
				/>

				<ProductBundlesModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addProductBundle={this.addProductBundleWrapper}
				/>
			</>
		);
	}
}

ProductBundles.propTypes = {
	defaultProductBundle: PropTypes.object,

	updateProductBundle: PropTypes.func,
	setProductBundle: PropTypes.func,
	resetProductBundle: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			updateProductBundle,
			setProductBundle,
			resetProductBundle,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultProductBundle: store.salesConfigurationProductBundle.data.defaultProductBundle,
		batchList: store.salesConfigurationProductBundle.data.batchList,
	};
};

export default connectWithStore(ProductBundles, mapStateToProps, mapDispatchToProps);
