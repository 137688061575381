'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');
const __enums = require('enums.service');

export default class TileSizesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'sort_order',
				type: 'number',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'type',
				type: 'select',
				canAdd: true,
				canEdit: true,
				required: true,
				options: {
					enums: __enums.tileSizes,
				},
			},
			{
				name: 'x_dimension',
				type: 'number',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'y_dimension',
				type: 'number',
				canAdd: true,
				canEdit: true,
				required: true,
			},
		];
		this.settings = {
			name: 'TileSizesList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			pageTitle: true,
			translationPath: 'POS.TILE_SIZES',
			canManipulate: () => validAccess(undefined, ['Global Sales Configuration']),

			endpoint: __endpoints.collection.pos.tile_sizes,
		};
		this.init();
		return this;
	}
}
