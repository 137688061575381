'use strict';

import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';

class StoresViewHeader extends PureComponent {
	getHeader() {
		const groupContainer = this.props.components['StoresView'].content;
		const colSpan = groupContainer.reduce((acc, key, index) => {
			acc = acc + groupContainer[index].getLength();
			return acc;
		}, 0);
		return (
			<th colSpan={colSpan}>
				<h3 className="dynamic-pos-view__stores-view__header">
					{this.props.workplaceTable.title}
				</h3>
			</th>
		);
	}

	getGroups() {
		const groupContainer = this.props.components['StoresView'].content;
		return groupContainer.map((group, key) => (
			<th
				key={key + group.type}
				className={cx('report-old__group-cell', {
					...group.getModifierClassNames({})
				})}
				colSpan={group.getLength()}
				style={{ width: group.getWidth(this.props.tables) + 'px' }}>
				{group.name}
			</th>
		));
	}

	getColumns() {
		const { hover, columns, tables } = this.props;
		let previousCellName = '';
		let productsColumn = 0;

		return Object.keys(columns).map(columnKey => {
			// Get column in question
			const column = columns[columnKey];

			// Iterate over column cells
			return Object.keys(column.cells).map((cellKey, index) => {
				// Get cell in question
				const cell = column.cells[cellKey];
				const styles = cx('report-old__header-cell', {
					'report-old__header-cell--first': index === 0, // first entry
					'report-old__header-cell--active':
						(productsColumn !== 0 && cell.name === 'Products') ||
						(productsColumn !== 1 && previousCellName === 'Products'),
					'report-old__header-cell--currency': cell.contentType === 'currency',
					'report-old__header-cell--number': cell.contentType === 'number',
					'report-old__header-cell--mixed': cell.contentType === 'mixed',
					'report-old__header-cell--left': cell.contentType === 'left',
					'report-old__cell--hover-active': hover.group === column.group,
					...cell.getModifierClassNames({})
				});
				previousCellName = cell.name;
				if (cell.name === 'Products') productsColumn++;
				return (
					<th
						key={cellKey + cell.type}
						className={styles}
						colSpan={cell.colSpan}
						style={{
							width: cell.getWidth(tables) ? cell.getWidth(tables) : 'auto'
						}}>
						{cell.getName()}
					</th>
				);
			});
		});
	}

	render() {
		return (
			<thead>
				<tr>{this.getHeader()}</tr>
				<tr className="report-old__groups">{this.getGroups()}</tr>
				<tr className="report-old__headers">{this.getColumns()}</tr>
			</thead>
		);
	}
}

StoresViewHeader.defaultProps = {
	workplaceTable: {}
};

StoresViewHeader.propTypes = {
	hover: PropTypes.object.isRequired,
	workplaceTable: PropTypes.object.isRequired,
	components: PropTypes.object.isRequired,
	tables: PropTypes.object.isRequired,
	columns: PropTypes.object.isRequired
};

export default StoresViewHeader;
