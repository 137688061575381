'use strict';

/**
 * @function initHeaderReferenceName
 * @memberOf SERVICES.__models
 * @description
 * Sets the property newName on every .reference object
 * @param  {array} headers  headers to set newName in header.reference
 */

import _each from 'lodash/each';
import _has from 'lodash/has';

module.exports = function(headers) {
	_each(headers, function(header) {
		if (_has(header, 'reference')) {
			// Reference
			var ref = header.reference;

			// Query
			var query = (_has(ref, 'query')) ? ref.query : false;
			var newHeader = header.name + '__' + (query ? query.replace('.', '_') + '__' : '') + ref.key;

			// Set newName
			ref.newName = newHeader;
		}
	});
	this.setHeaders(headers);
};