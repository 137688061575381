export default {
	NO_DATES_SELECTED: 'Select dates to begin',
	MANAGED_CLOCKIN_CORRECTED: 'Managed clockin corrected',
	SELECT_STATUS: 'Select status',
	ALIGNED: 'Aligned',
	UNALIGNED: 'Unaligned',
	CSV_DOWNLOAD: 'CSV',
	CLOSE_DAYS: 'Close days',
	SUCCESS: 'Success',
	CLOSE_OPEN_DAYS_TOOLTIP: 'Close open days qualified for being closed',
	SELECT_STATUS_LABEL: 'Status',
	SHIFT_CLOCKIN_BREAK_ADDED: 'Shift clockin break added',
	DEVIATION_EXPORT: 'Deviation export',
	DEVIATION_EXPORT_TOOLTIP: 'Click to download the deviation export',
};
