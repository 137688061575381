import React from 'react';
import PropTypes from 'prop-types';

// Dungeons N' Dragons
import { DropTarget } from 'react-dnd';

// components
import { Icon } from 'dumb';

// utils
import phrases from './itemDropZone.phrases';
import cx from 'classnames';

import './itemDropZone.css';

const ItemDropZone = props => {
	const { openTileModal, connectDropTarget, isOverCurrent, subTypeRow } = props;

	const className = subTypeRow
		? 'pos-layout__drop-zone__row'
		: 'pos-layout__drop-zone__grid';

	return connectDropTarget(
		<div
			className={cx(className, {
				[`${className}--hovered`]: isOverCurrent
			})}
			onClick={openTileModal}>
			<Icon className={`${className}__icon`} name="add" />
			<span className={`${className}__text`}>{phrases.ADD_TILE}</span>
		</div>
	);
};

ItemDropZone.propTypes = {
	openTileModal: PropTypes.func,
	subTypeRow: PropTypes.string
};

export default DropTarget(
	'Product-variant',
	{
		drop(props, monitor) {
			const item = monitor.getItem();

			props.onDrop(item);
		},

		hover(props, monitor) {
			if (monitor.didDrop()) {
				// If you want, you can check whether some nested
				// target already handled drop
				return;
			}

			// You can do something with it
			monitor.isOver({ shallow: true });
		}
	},
	(connect, monitor) => ({
		connectDropTarget: connect.dropTarget(),
		isOverCurrent: monitor.isOver({ shallow: true }),
		canDrop: monitor.canDrop()
	})
)(ItemDropZone);
