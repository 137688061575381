import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './powerBIReports.phrases';

/**
 * @function fetchPowerBIReports
 * @description Fetch all power bi reports
 * @param {object} state
 */
export function fetchPowerBIReports(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/reporting/power_bi_reports', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addPowerBIReports
 * @description adds power bi reports
 */
export function addPowerBIReports(data) {
	return post('/reporting/power_bi_reports', data)
		.then((res) => {
			setFeedback(phrases.POWER_BI_REPORTS_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editPowerBIReport
 * @description edits a single power bi report
 */
export function editPowerBIReport(data) {
	return post(`/reporting/power_bi_reports/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.POWER_BI_REPORT_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editPowerBIReports
 * @description edits one or more power bi report
 */
export function editPowerBIReports(data) {
	return patch('/reporting/power_bi_reports', data)
		.then((res) => {
			setFeedback(phrases.POWER_BI_REPORTS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deletePowerBIReports
 * @description deletes power bi reports
 */
export function deletePowerBIReports(productId) {
	return remove(`/reporting/power_bi_reports/${productId}`)
		.then((res) => {
			setFeedback(phrases.POWER_BI_REPORTS_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
