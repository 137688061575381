// interfaces
import {
	IEODDailySummations,
	IEODAudits,
	IEODCorrections,
	IEndOfDaySessions,
	IEODPosSessionsSplit,
	IHotbar,
} from 'types/eodControl';
import { ActionType } from 'types/redux';

export const RESET_DATA = 'EOD_CONTROL_V2/RESET_DATA';
export const SET_DATA_RECURSIVELY = 'EOD_CONTROL_V2/SET_DATA_RECURSIVELY';
export const SET_LOADING = 'EOD_CONTROL_V2/SET_LOADING';
export const UPDATE_ROW = 'EOD_CONTROL_V2/UPDATE_ROW';
export const TOGGLE_AUDIT_MODAL = 'EOD_CONTROL_V2/TOGGLE_AUDIT_MODAL';
export const SET_AUDIT_MODAL_DATA = 'EOD_CONTROL_V2/SET_AUDIT_MODAL_DATA';
export const RESET_AUDIT_MODAL_DATA = 'EOD_CONTROL_V2/RESET_AUDIT_MODAL_DATA';
export const SET_AUDIT_MODAL_EOD_AUDITS = 'EOD_CONTROL_V2/SET_AUDIT_MODAL_EOD_AUDITS';
export const SET_AUDIT_MODAL_EOD_CORRECTIONS = 'EOD_CONTROL_V2/SET_AUDIT_MODAL_EOD_CORRECTIONS';
export const SET_AUDIT_MODAL_EOD_SESSIONS = 'EOD_CONTROL_V2/SET_AUDIT_MODAL_EOD_SESSIONS';
export const REMOVE_AUDIT_MODAL_EOD_SESSION = 'EOD_CONTROL_V2/REMOVE_AUDIT_MODAL_EOD_SESSION';
export const UPDATE_AUDIT_MODAL_EOD_SESSION = 'EOD_CONTROL_V2/UPDATE_AUDIT_MODAL_EOD_SESSION';
export const SET_AUDIT_MODAL_LOADING = 'EOD_CONTROL_V2/SET_AUDIT_MODAL_LOADING';
export const RE_RENDER_TABLE = 'EOD_CONTROL_V2/RE_RENDER_TABLE';
export const SET_EOD_SESSION_TO_EDIT = 'EOD_CONTROL_V2/SET_EOD_SESSION_TO_EDIT';
export const SET_EOD_SESSIONS_TO_SPLIT = 'EOD_CONTROL_V2/SET_EOD_SESSIONS_TO_SPLIT';
export const SET_HOTBAR_FILTER_PARAMS = 'EOD_CONTROL_V2/SET_HOTBAR_FILTER_PARAMS';

export const setHotbarFilterParams = (payload: IHotbar): ActionType => ({
	type: SET_HOTBAR_FILTER_PARAMS,
	payload,
});

export const resetData = (): ActionType => ({
	type: RESET_DATA,
});

export const setDataRecursively = (payload: IEODDailySummations[]): ActionType => ({
	type: SET_DATA_RECURSIVELY,
	payload,
});

export const setLoading = (payload: boolean): ActionType => ({
	type: SET_LOADING,
	payload,
});

export const updateRow = (payload: IEODDailySummations): ActionType => ({
	type: UPDATE_ROW,
	payload,
});

export const toggleAuditModal = (): ActionType => ({
	type: TOGGLE_AUDIT_MODAL,
});

export const setAuditModalData = (payload: IEODDailySummations): ActionType => ({
	type: SET_AUDIT_MODAL_DATA,
	payload,
});

export const setAuditModalEodAudits = (payload: IEODAudits[]): ActionType => ({
	type: SET_AUDIT_MODAL_EOD_AUDITS,
	payload,
});

export const setAuditModalEodCorrections = (payload: IEODCorrections[]): ActionType => ({
	type: SET_AUDIT_MODAL_EOD_CORRECTIONS,
	payload,
});

export const setAuditModalEodSessions = (payload: IEndOfDaySessions[]): ActionType => ({
	type: SET_AUDIT_MODAL_EOD_SESSIONS,
	payload,
});

export const resetAuditModalData = (): ActionType => ({
	type: RESET_AUDIT_MODAL_DATA,
});

export const setAuditModalLoading = (payload: boolean): ActionType => ({
	type: SET_AUDIT_MODAL_LOADING,
	payload,
});

export const removeAuditModalEodSession = (payload: number): ActionType => ({
	type: REMOVE_AUDIT_MODAL_EOD_SESSION,
	payload,
});

export const reRenderTable = (): ActionType => ({
	type: RE_RENDER_TABLE,
});

export const setEodSessionToEdit = (payload: IEndOfDaySessions | undefined): ActionType => ({
	type: SET_EOD_SESSION_TO_EDIT,
	payload,
});

export const updateAuditModalEodSession = (payload: IEndOfDaySessions): ActionType => ({
	type: UPDATE_AUDIT_MODAL_EOD_SESSION,
	payload,
});

export const setEodSessionsToSplit = (payload: IEODPosSessionsSplit[]): ActionType => ({
	type: SET_EOD_SESSIONS_TO_SPLIT,
	payload,
});
