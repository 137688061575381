'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, ButtonGroup, InputCollectionSelect, Tag } from 'dumb';
import { ModalRow } from 'dumb/modal';

import phrases from './../../../salesConfigurationPosConfigurationDiscounts.phrases';

import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';

class PosConfigurationDiscountsModalBatchStepForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};
	}

	editStoreEntry(e, type) {
		const {
			updateBatchForm,
			resetSelection,
			discountUrlReduxKey,
			loyaltyProductUrl,
		} = this.props;

		const value = e?.target?.value ?? e;

		// if we cleared the select, also clear the selected rows in overlay table
		if (type === 'loyaltyProduct' && _isEmpty(value)) {
			resetSelection(loyaltyProductUrl);
		}
		if (type === 'discount' && _isEmpty(value)) {
			resetSelection(discountUrlReduxKey);
		}

		updateBatchForm({
			[type]: value,
		});
	}

	_getTags() {
		const { batchFormData } = this.props;

		// get rid of duplicates
		const posConfigurationVersions = _uniqBy(
			batchFormData.posConfigurationVersion,
			'value.id'
		);

		return posConfigurationVersions.map((entry, index) => {
			return (
				<Tag color="dddddd" key={index}>
					{_get(entry, 'value.name', null)}
				</Tag>
			);
		});
	}

	render() {
		const { batchFormData, toggleOverlay, discountUrlReduxKey } = this.props;

		return (
			<>
				<div className="modal-batch__pos-configuration-discounts">
					<span>{phrases.MODAL_BATCH_STEP_FORM_TAGS_TEXT}</span>
					<div className="modal-batch__pos-configuration-discounts__tags">
						{this._getTags()}
					</div>
				</div>
				<ModalRow>
					<ButtonGroup type="full-width">
						<InputCollectionSelect
							id="discount"
							className="modal-batch-select"
							label={phrases.MODAL_BATCH_STEP_DISCOUNT}
							placeholder={phrases.MODAL_BATCH_STEP_DISCOUNT_PLACEHOLDER}
							value={batchFormData.discount}
							handleChange={(key, value) =>
								this.editStoreEntry(value, 'discount')
							}
							required
							multi
							cache
							apiPath="/sale/discounts"
							params={{
								limit: 50,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>

						<Button
							type="inverted"
							shadow
							size="large"
							onClick={() => toggleOverlay(discountUrlReduxKey)}>
							<span className="icon icon--more" />
						</Button>
					</ButtonGroup>
				</ModalRow>

				<ModalRow>
					<Input
						id="name"
						required
						label={phrases.MODAL_BATCH_STEP_NAME}
						placeholder={phrases.MODAL_BATCH_STEP_NAME_PLACEHOLDER}
						onChange={(event) => this.editStoreEntry(event, 'name')}
						value={batchFormData.name || ''}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						id="description"
						required
						label={phrases.MODAL_BATCH_STEP_DESCRIPTION}
						placeholder={phrases.MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER}
						onChange={(event) => this.editStoreEntry(event, 'description')}
						value={batchFormData.description || ''}
					/>
				</ModalRow>
			</>
		);
	}
}

PosConfigurationDiscountsModalBatchStepForm.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
	resetSelection: PropTypes.func,
	discountUrlReduxKey: PropTypes.string,
	loyaltyProductUrl: PropTypes.string,
};

export default PosConfigurationDiscountsModalBatchStepForm;
