import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// components
import { Button, Icon, Tooltip, ButtonLoader, Text } from 'dumb';
import ShiftTransferStatus from './../../../../shiftTransferStatus';

// phrases/ constants/ utils
import phrases from './../../../../../shiftTransferApproval.phrases';
import { ShiftTransfer as enums } from 'services/enums';

// styles
import './shiftTransferManagerApprovalApplicantList.css';

const ShiftTransferManagerApprovalApplicantList = ({
	shiftTransfer,
	updateShiftTransfer,
	ownerName,
	ownerId,
	ownerMoneyball,
}) => {
	const handleShiftTransfer = (entry, operation) => {
		setUpdatingTransfer({ id: entry.id, operation });

		const payload = {
			shiftTransfer,
			operation,
			application: entry.id,
		};

		updateShiftTransfer(payload).finally(() => setUpdatingTransfer(null));
	};

	// hooks
	const [updatingTransfer, setUpdatingTransfer] = useState(null);

	const unassignedShift = !ownerName && !ownerId;

	return (
		<div className="shift-transfer-approval-applicant-list">
			<div className="shift-transfer-approval-applicant-list__owner-wrapper">
				<div className="shift-transfer-approval-modal__table-wrapper__row-cell">
					{unassignedShift ? (
						<Text size="small" ellipsis>
							{phrases.UNASSIGNED_SHIFT}
						</Text>
					) : (
						<>
							<Text
								size="tiny"
								className="shift-transfer-approval-modal__table-wrapper__row-cell--right-margin shift-transfer-approval-applicant-list__juicer-id-column"
							>
								{ownerId}
							</Text>
							<Text size="small" ellipsis>
								{ownerMoneyball}
							</Text>
							<Text
								bold
								size="small"
								ellipsis
								className="shift-transfer-approval-modal__table-wrapper__row-cell--left-margin shift-transfer-approval-modal__table-wrapper__row-cell__name"
							>
								{ownerName}
							</Text>
						</>
					)}
				</div>

				<Text
					size="small"
					ellipsis
					className="shift-transfer-approval-modal__table-wrapper__row-cell--left-margin shift-transfer-approval-modal__table-wrapper__row-cell__name"
				>
					{phrases.SHIFT_OWNER}
				</Text>
			</div>

			{shiftTransfer.applications.map((entry) => {
				const applicationApproved = entry.state === enums.APPLICATIONS.APPROVED;
				const applicationDeclined = entry.state === enums.APPLICATIONS.DECLINED;
				const showButtons = !applicationApproved && !applicationDeclined;

				const id = entry.applicant?.person?.id ?? '';
				const name = entry.applicant?.person?.full_name ?? '';
				const moneyballBadge =
					entry.applicant?.person?.moneyball_sub_position?.moneyball_position
						?.badge ?? '';

				return (
					<div
						className={cx('shift-transfer-approval-applicant-list__entry', {
							'shift-transfer-approval-applicant-list__entry--declined':
								applicationDeclined,
						})}
						key={entry.id}
					>
						<div
							className={cx(
								'shift-transfer-approval-modal__table-wrapper__row-cell',
								{
									'shift-transfer-approval-modal__table-wrapper__row-cell--declined':
										applicationDeclined,
								}
							)}
						>
							<Text
								size="tiny"
								className="shift-transfer-approval-modal__table-wrapper__row-cell--right-margin shift-transfer-approval-applicant-list__juicer-id-column"
							>
								{id}
							</Text>
							<Text size="small" ellipsis>
								{moneyballBadge}
							</Text>
							<Text
								bold
								size="small"
								ellipsis
								className="shift-transfer-approval-modal__table-wrapper__row-cell--left-margin shift-transfer-approval-modal__table-wrapper__row-cell__name"
							>
								{name}
							</Text>
						</div>

						{showButtons ? (
							<div className="shift-transfer-approval-modal__table-wrapper__row-cell shift-transfer-approval-modal__table-wrapper__row-cell--align-end">
								<Tooltip
									text={phrases.APPROVE}
									zIndex={600}
									renderData={(ref, onMouseEnter, onMouseLeave) => (
										<Button
											shadow
											type="success"
											size="small"
											onClick={() =>
												handleShiftTransfer(
													entry,
													enums.OPERATIONS.APPROVE_APPLICATION
												)
											}
											refProp={ref}
											onMouseEnter={onMouseEnter}
											onMouseLeave={onMouseLeave}
										>
											{updatingTransfer?.id === entry.id &&
											updatingTransfer?.operation ===
												enums.OPERATIONS.APPROVE_APPLICATION ? (
													<ButtonLoader loading />
											) : (
												<Icon name="thumb_up" />
											)}
										</Button>
									)}
								/>
								<div className="shift-transfer-approval-modal__table-wrapper__row-cell--left-margin">
									<Tooltip
										text={phrases.DECLINE}
										zIndex={600}
										renderData={(ref, onMouseEnter, onMouseLeave) => (
											<Button
												shadow
												size="small"
												type="negative"
												onClick={() =>
													handleShiftTransfer(
														entry,
														enums.OPERATIONS.DECLINE_APPLICATION
													)
												}
												refProp={ref}
												onMouseEnter={onMouseEnter}
												onMouseLeave={onMouseLeave}
											>
												{updatingTransfer?.id === entry.id &&
												updatingTransfer?.operation ===
													enums.OPERATIONS.DECLINE_APPLICATION ? (
														<ButtonLoader loading />
												) : (
													<Icon name="thumb_down" />
												)}
											</Button>
										)}
									/>
								</div>
							</div>
						) : (
							<ShiftTransferStatus state={entry.state} />
						)}
					</div>
				);
			})}
		</div>
	);
};

ShiftTransferManagerApprovalApplicantList.propTypes = {
	shiftTransfer: PropTypes.object,
	updateShiftTransfer: PropTypes.func,
	ownerName: PropTypes.string,
	ownerId: PropTypes.number,
	ownerMoneyball: PropTypes.string,
};

export default ShiftTransferManagerApprovalApplicantList;
