
import config from 'config';

/**
 * @function
 * @description returns true if env is production
 */
export function isProduction() {
	return config.env === 'production';
}


/**
 * @function
 * @description returns true if env is not production
 */
export function isDevelopment() {
	return config.env !== 'production';
}
