import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import _groupBy from 'lodash/groupBy';
import _get from 'lodash/get';

// Components
import { Icon } from 'dumb';

class FileList extends PureComponent {
	getSectionName(id, index = 'other') {
		const { phrases, categoryList } = this.props;
		const name =
			categoryList && categoryList.filter(item => item.value === Number(id));

		return (
			<h4 key={index} className="file-list__file-name">
				{name && name.length
					? name[0].label
					: phrases.DEFAULT_FILE_LIST_CATEGORY}
			</h4>
		);
	}

	getFile(file) {
		const { fileList, fileListState } = this.props;

		return (
			<div className="file-list__file" key={file.id}>
				<div className="file-list__file-name">
					<p title={file.document.filename}>{file.document.filename}</p>
				</div>
				<div className="file-list__file-meta-data">
					<h5>
						{file.type !== null && <span>{file.type}</span>}
						{file.updated}
					</h5>
				</div>
				<div className="file-list__file-actions">
					{fileList.getCanDelete() && !fileListState.loading && (
						<div
							className="file-list__action"
							onClick={() => fileList.handleDeleteFile(file)}>
							<Icon name="clear" />
						</div>
					)}
					{fileList.getCanDownload() && !fileListState.loading && (
						<a download className="file-list__action" href={file.document.url}>
							<Icon name="file_download" />
						</a>
					)}
				</div>
			</div>
		);
	}

	getCategorizedFiles() {
		const { fileListState } = this.props;

		// Group by "employment_position"; Potentially switch to parameter
		let groupedFileList = {};

		groupedFileList = _groupBy(fileListState.data, 'employment_position.id');

		// Extract files that do not have "employment_position" set
		let uncategorized = [];
		if (groupedFileList.null) {
			uncategorized = groupedFileList.null;
			delete groupedFileList.null;
		}

		return (
			<div>
				{/* Ordered list of Files */}
				{Object.keys(groupedFileList)
					.reverse()
					.map((x, index) => (
						<div className="file-list__section" key={index}>
							{this.getSectionName(x, index)}
							<div className="file-list__files">
								{groupedFileList[x].map(file => this.getFile(file))}
							</div>
						</div>
					))}

				{/* Other files */}
				{uncategorized.length > 0 && (
					<div className="file-list__section">
						{this.getSectionName(null)}
						<div className="file-list__files">
							{uncategorized.map(file => {
								return this.getFile(file);
							})}
						</div>
					</div>
				)}
			</div>
		);
	}

	getFiles() {
		const { fileListState } = this.props;


		const data = _get(fileListState, 'data', []);
		return data.map(file => this.getFile(file));
	}

	render() {
		const { phrases, fileListState, categoryList } = this.props;


		return (
			<div className="file-list">
				<h3>
					{phrases.FILE_LIST}
					{fileListState.loading && (
						<div className="file-list__spinner">
							<div className="file-list__spinner__spinner" />
						</div>
					)}
				</h3>

				{!categoryList && (
					<div className="file-list__files">{this.getFiles()}</div>
				)}

				{categoryList && this.getCategorizedFiles()}
			</div>
		);
	}
}

FileList.defaultProps = {
	fileList: {},
	fileListState: {
		data: [],
		loading: false
	}
};

FileList.propTypes = {
	fileList: PropTypes.object.isRequired,
	fileListState: PropTypes.object,
	selectedPositionId: PropTypes.number,
	categoryList: PropTypes.array
};

export default FileList;
