'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { Input, InputCollectionSelect, Toggle } from 'dumb';
import { ModalRow } from 'dumb/modal';

// lodash
import _get from 'lodash/get';

// phrases
import phrases from './../../../posConfigurationAssociatedIngredients.phrases';

class AssociatedIngredientsStepForm extends Component {
	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		const value = e?.target?.value ?? e;

		updateBatchForm({
			[type]: value,
		});
	}

	render() {
		const { batchFormData, posConfigurationVersionId } = this.props;

		return (
			<>
				<ModalRow>
					<InputCollectionSelect
						id="pos-configuration-ingredient-modal-step-form"
						label={phrases.MODAL_HEADER_POS_CONFIGURATION_INGREDIENT}
						placeholder="Select ingredient..."
						value={batchFormData.posConfigurationIngredient}
						apiPath="/pos/pos_configuration_ingredients"
						params={{
							filter: `:pos_configuration_version.id=='${posConfigurationVersionId}'`,
							// filter: `:pos_configuration_version.pos_configuration.id=='${posConfigurationId}'`,
						}}
						handleChange={(key, event) => this.editStoreEntry(event, 'posConfigurationIngredient')}
						inputFilterFormat={(input) => `:ingredient.name=like='%${input}%'`}
						optionFormat={(entry) => ({
							value: entry,
							label: entry?.ingredient?.name ?? '',
						})}
						multi
						tableColumns={[
							{
								Header: 'Ingredient',
								id: 'ingredient',
								accessor: (d) => _get(d, 'ingredient.name', ''),
								filterPath: ':ingredient.name',
							},
							{
								Header: 'POS Configuration Version',
								id: 'pos-configuration-version',
								accessor: (d) => _get(d, 'pos_configuration_version.name', ''),
								filterPath: ':pos_configuration_version.name',
							},
							{
								Header: 'POS Configuration',
								id: 'pos-configuration',
								accessor: (d) => _get(d, 'pos_configuration_version.pos_configuration.name', ''),
								filterPath: ':pos_configuration_version.pos_configuration.name',
							},
							{
								Header: 'To go price',
								id: 'to-go-price',
								accessor: (d) => _get(d, 'to_go_price', ''),
								filterPath: ':to_go_price',
							},
							{
								Header: 'To stay price',
								id: 'to-stay-price',
								accessor: (d) => _get(d, 'to_stay_price', ''),
								filterPath: ':to_stay_price',
							},
						]}
						tableTitle={phrases.MODAL_BATCH_STEP_POS_CONFIGURATION_INGREDIENT_TABLE_TITLE}
						tableReduxKey="modal-batch-add/pos/pos_configuration_ingredients"
					/>
				</ModalRow>

				<ModalRow>
					<Input
						id="minimum"
						label={phrases.MODAL_HEADER_MINIMUM}
						placeholder="Enter minimum..."
						type="number"
						onChange={(event) => this.editStoreEntry(event, 'minimum')}
						value={batchFormData.minimum}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						id="default"
						label={phrases.MODAL_HEADER_DEFAULT}
						placeholder="Enter default..."
						type="number"
						onChange={(event) => this.editStoreEntry(event, 'default')}
						value={batchFormData.default}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						id="maximum"
						label={phrases.MODAL_HEADER_MAXIMUM}
						placeholder="Enter maximum..."
						type="number"
						onChange={(event) => this.editStoreEntry(event, 'maximum')}
						value={batchFormData.maximum}
					/>
				</ModalRow>

				<ModalRow>
					<Toggle
						id="deductible"
						label={phrases.MODAL_HEADER_DEDUCTIBLE}
						toggled={batchFormData.deductible}
						onClick={(e) => this.editStoreEntry(e, 'deductible')}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						label={phrases.MODAL_HEADER_SORT_ORDER}
						id="sort-order"
						type="number"
						placeholder="Enter sort order..."
						onChange={(event) => this.editStoreEntry(event, 'sortOrder')}
						value={batchFormData.sortOrder}
					/>
				</ModalRow>
			</>
		);
	}
}

AssociatedIngredientsStepForm.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	posConfigurationVersionId: PropTypes.number,
};

export default AssociatedIngredientsStepForm;
