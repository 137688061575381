'use strict';

import PropTypes from 'prop-types';
import cx from 'classnames';
import React from 'react';

import './textArea.css';

const TextArea = props => {
	const change = e => {
		props.onChange(e);
	};

	const textAreaCx = cx('text-area', {});

	const style = { resize: props.resize ? 'none' : 'initial' };

	return (
		<textarea
			name={props.name}
			cols={props.cols}
			rows={props.rows}
			onChange={change}
			placeholder={props.placeholder}
			className={textAreaCx}
			disabled={props.disabled}
			style={style}
			maxLength={props.maxLength}
			required={props.required}
			defaultValue={props.defaultValue}
		/>
	);
};

TextArea.proptypes = {
	name: PropTypes.string.isRequired
};

TextArea.defaultProps = {
	onChange() {},
	name: 'text-area',
	cols: '40',
	placeholder: '',
	rows: '3',
	disabled: false,
	maxLength: null,
	required: false,
	resize: false,
	defaultValue: ''
};

export default TextArea;
