export const RESET_WORKPLACE_OPERATIONAL_RESPONSIBILITY =
	'ORGANISATION_WORKPLACES_OPERATIONAL_RESPONSIBILITIES/RESET_WORKPLACE_OPERATIONAL_RESPONSIBILITY';
export const UPDATE_WORKPLACE_OPERATIONAL_RESPONSIBILITY =
	'ORGANISATION_WORKPLACES_OPERATIONAL_RESPONSIBILITIES/UPDATE_WORKPLACE_OPERATIONAL_RESPONSIBILITY';

/**
 * @param {object} payload
 */
export function updateWorkplaceOperationalResponsibilities(payload) {
	return {
		type: UPDATE_WORKPLACE_OPERATIONAL_RESPONSIBILITY,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetWorkplaceOperationalResponsibilities() {
	return {
		type: RESET_WORKPLACE_OPERATIONAL_RESPONSIBILITY,
	};
}
