'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Button,
	Input,
	ButtonGroup,
	InputCollectionSelect,
	InputCleave
} from 'dumb';
import { ModalRow } from 'dumb/modal';

import _get from 'lodash/get';

class AddNewSalaryComponentForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false
		};
	}

	editStoreEntry(e, type) {
		const { batchOptions } = this.props;
		const value = e.target ? e.target.value : e;

		this.props.updateBatchForm({
			...batchOptions,
			...{ [type]: value }
		});
	}

	editStoreDatesEntry(e, type) {
		const { batchOptions } = this.props;
		const value = e.target ? e.target.value : e;

		const updateObject = {
			// global_salary_component: batchOptions.global_salary_component,
			salary_group: batchOptions.salary_group,
			amount: batchOptions.amount,
			active: batchOptions.active
		};

		const data =
			type === 'from'
				? {
					...updateObject,
					...{
						active: {
							from: value,
							to: _get(batchOptions, 'active.to', '9999-12-31')
						}
					}
				  }
				: {
					...updateObject,
					...{
						active: {
							from: _get(batchOptions, 'active.from', ''),
							to: value
						}
					}
				  };

		this.props.updateBatchForm(data);
	}

	render() {
		const { batchOptions, toggleOverlay } = this.props;

		return (
			<>
				<ModalRow>
					<ButtonGroup type="full-width">
						<InputCollectionSelect
							className="modal-batch-select"
							id="salary_groups"
							label="Salary Groups"
							placeholder="Select salary groups"
							value={batchOptions.salary_group}
							handleChange={(key, value) =>
								this.editStoreEntry(value, 'salary_group')
							}
							multi
							cache
							apiPath="/salary/salary_groups"
							params={{
								limit: 50
							}}
							optionFormat={entry => ({
								value: entry,
								label: entry.name
							})}
							inputFilterFormat={input => `:name=like='%${input}%'`}
						/>

						<Button
							type="inverted"
							shadow
							size="large"
							onClick={() => toggleOverlay('salary_group')}>
							<span className="icon icon--more" />
						</Button>
					</ButtonGroup>
				</ModalRow>

				<ModalRow>
					<Input
						label="Amount"
						id="amount"
						placeholder="amount"
						type="number"
						min={0}
						value={batchOptions.value ?? undefined}
						onChange={event => this.editStoreEntry(event, 'amount')}
					/>
				</ModalRow>

				<ModalRow>
					<InputCleave
						label="Active from"
						id="active-from"
						placeholder="yyyy-mm-dd"
						required
						options={{
							date: true,
							delimiter: '-',
							datePattern: ['Y', 'm', 'd']
						}}
						value={_get(batchOptions, 'active.from', '')}
						onChange={event => this.editStoreDatesEntry(event, 'from')}
					/>
				</ModalRow>
			</>
		);
	}
}

AddNewSalaryComponentForm.defaultProps = {};

AddNewSalaryComponentForm.propTypes = {
	batchOptions: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func
};

export default AddNewSalaryComponentForm;
