import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';

import GridsList from './services/gridsList.service.js';

const gridsListReduxKey = '/hr/grids';
const gridsList = new GridsList(gridsListReduxKey);
const gridsListProps = gridsList.getProps();

export function Grids() {
	useEffect(() => {
		gridsList.initView();
	}, []);

	return <ListComponent reduxKey={gridsListReduxKey} {...gridsListProps} />;
}
