'use strict';

import PropTypes from 'prop-types';
import React from 'react';
import Date from 'reusableComponents/inputs/date/date.component';

const DateRange = ({
	component,
	componentState,
	handleChange,
	handleFocusChange,
	withPortal
}) => (
	<Date
		type="range"
		withPortal={withPortal}
		reopenPickerOnClearDate
		onChange={event => handleChange(component, event)}
		onFocusChange={event => handleFocusChange(component, event)}
		shouldTriggerOnChange
		minimumNights={0}
		initialStartDate={
			component.defaultStartDate !== null
				? component.defaultStartDate
				: componentState !== null
					? componentState.results.startDate
					: null
		}
		initialEndDate={
			component.defaultEndDate !== null
				? component.defaultEndDate
				: componentState !== null
					? componentState.results.endDate
					: null
		}
	/>
);

DateRange.propTypes = {
	withPortal: PropTypes.bool,
	component: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleFocusChange: PropTypes.func.isRequired,
	componentState: PropTypes.object
};

export default DateRange;
