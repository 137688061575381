import React, { Suspense } from 'react';

// import { MyShiftplanner } from 'structure/myShiftplanner/myShiftplanner.container';

const MyShiftplanner = React.lazy(() =>
	import(/* webpackChunkName: "MyShiftplanner" */ 'structure/myShiftplanner/myShiftplanner.container')
);

export const shiftMarketplaceRoutes = [
	{
		element: (
			<Suspense fallback={<div>Loading...</div>}>
				<MyShiftplanner asShiftMarketplace />
			</Suspense>
		),
		path: 'shift-marketplace',
		title: 'Shift marketplace',
		icon: require('@mui/icons-material/StorefrontOutlined').default,
		allowedAccessControlLevels: ['Shiftplan View', 'Shiftplan Manager', 'Shiftplan Admin'],
	},
];
