import { UPDATE_PRODUCT, SET_PRODUCT, RESET_PRODUCT } from './product.actions';

const defaultState = {
	data: {
		defaultProduct: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_PRODUCT: {
		return {
			data: {
				defaultProduct: payload
			}
		};
	}

	case UPDATE_PRODUCT: {
		return {
			data: {
				defaultProduct: {
					...state.data.defaultProduct,
					...payload
				}
			}
		};
	}

	case RESET_PRODUCT: {
		return defaultState;
	}

	default:
		return state;
	}
}

export default reducer;
