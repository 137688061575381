/**
 * @function fetchPostman
 * @memberOf helper
 * @description
 * Helper to fetch postman mock api
 * @param  {string} url  Url, example
 * @param  {string} locationParameters simply add everything that goes after url with PARAMS!
 * @param  {string} method  GET / POST... default GET
 */

export default function fetchPostman(url, locationParameters, method = 'GET') {
	// const strippedUrl =

	const fullPath = `${url}${locationParameters}`;

	var request = new Request(fullPath, {
		method: method,
		headers: {
			'Content-Type': 'application/json; charset=utf-8'
		}
	});

	return fetch(request).then(x => x.json());
}

/* EXAMPLE */
// const url = 'https://293efdcb-34eb-4f1b-ba56-b86ede80a002.mock.pstmn.io';
// const locationParameters = `forecast/${forecastScenarioId}/missing_stores`;

// Get data
// fetchPostman(url, locationParameters)
// 	.then(x => {
// 		setTimeout(() => store.dispatch(actions.getMissingStores(x.data)), 2000);
// 	})
// 	.catch(x => {
// 		store.dispatch(actions.fetchingMissingStores(false));
// 	});
