'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';
import BatchStepList from './components/rawIngredientConfigurationsModalStepList';

// phrases
import phrases from './../../rawIngredientConfigurations.phrases';

class rawIngredientConfigurationsModalBatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showOverlay: false,
			overlayType: '',
			loadingModal: false
		};

		// Binds
		this._batchSubmit = this._batchSubmit.bind(this);
	}

	_batchSubmit() {
		const { batchList, handleClose } = this.props;

		this.setState(() => ({
			loadingModal: true
		}));

		const payload = batchList.map(entry => {
			return {
				name: entry.name,
				description: entry.description
			};
		});

		this.props
			.addRawIngredientConfigurations({ batch: payload })

			.then(e => {
				handleClose();
				this.setState(() => ({
					loadingModal: false
				}));
			})
			.catch(e => {
				this.setState(() => ({
					loadingModal: false
				}));
			});
	}

	render() {
		const {
			modalVisible,
			handleClose,
			removeBatchListItem,
			editBatchListItem,
			batchList,
			addBatchListItemRow
		} = this.props;
		const { showOverlay } = this.state;

		const steps = [
			{
				component: (
					<BatchStepList
						batchList={batchList}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
						addBatchListItemRow={addBatchListItemRow}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				defaultStyles: false
			}
		];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						className="modal-batch"
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={showOverlay ? () => {} : handleClose}
						closeConfirm
					/>
				)}
			</>
		);
	}
}

rawIngredientConfigurationsModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	batchList: PropTypes.array,
	handleClose: PropTypes.func,
	addRawIngredientConfigurations: PropTypes.func,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func
};

rawIngredientConfigurationsModalBatch.defaultProps = {
	modalVisible: false,
	loading: false
};

export default rawIngredientConfigurationsModalBatch;
