'use strict';

import {
	SET_WORKPLACE,
	UPDATE_WORKPLACE,
	RESET_WORKPLACE,
} from './workplaces.actions';

const defaultState = {
	data: {
		defaultWorkplace: {},
		workplace: {
			name: '',
			franchise: false,
			openingOffset: 0,
			closingOffset: 0,
		},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_WORKPLACE: {
			return {
				...state,
				data: {
					workplace: payload,
					defaultWorkplace: payload,
				},
			};
		}

		case UPDATE_WORKPLACE: {
			return {
				...state,
				data: {
					...state.data,
					workplace: {
						...state.data.workplace,
						...payload,
					},
				},
			};
		}

		case RESET_WORKPLACE: {
			return defaultState;
		}

		default:
			return state;
	}
}

export default reducer;
