'use strict';

export default {
	TILE_TYPE: {
		PRODUCT: 'Product',
		VARIANT: 'Product-variant',
		GROUP: 'Group',
		BG_COLOR: '464646',
		COLOR: 'ffffff'
	}
};
