// lodash
import _get from 'lodash/get';

export default function getColumns({ hasSystemSoftwareId }) {
	return [
		...(hasSystemSoftwareId
			? [
					{
						Header: 'Workplace',
						id: 'workplace',
						accessor: (d) => _get(d, 'workplace.name', ''),
						filterPath: ':workplace.name',
					},
					{
						Header: 'Market',
						id: 'market',
						accessor: (d) => _get(d, 'workplace.market.name', ''),
						filterPath: ':workplace.market.name',
					},
			  ]
			: [
					{
						Header: 'Software system',
						id: 'software-system-title',
						accessor: (d) => _get(d, 'system_software.title', ''),
						filterPath: ':system_software.title',
					},
			  ]),
		{
			Header: 'Software system version',
			id: 'software-system-version',
			accessor: (d) => _get(d, 'system_software_version.version', ''),
			filterPath: ':system_software_version.version',
		},
	];
}
