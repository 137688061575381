import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ReactDataWrapper } from 'reactDataWrapper';

class ShiftClockinsHourlyStatsTable extends Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				Header: 'Hour',
				id: 'hour',
				accessor: 'hour',
				filterPath: ':hour',
				filterable: false,
				sortable: false,
			},
			{
				Header: 'M.H.',
				id: 'employees',
				accessor: 'employees',
				filterPath: ':employees',
				filterable: false,
				sortable: false,
			},
			{
				Header: 'O.H.',
				id: 'optimal-employees',
				accessor: 'optimal_employees',
				filterPath: ':optimal_employees',
				filterable: false,
				sortable: false,
			},
			{
				Header: 'A.W.T.',
				id: 'average-waiting-time',
				accessor: 'average_waiting_time',
				filterPath: ':average_waiting_time',
				filterable: false,
				sortable: false,
			},
			{
				Header: 'W.P.',
				id: 'fuel-cost',
				accessor: 'fuel_cost',
				filterPath: ':fuel_cost',
				filterable: false,
				sortable: false,
			},
		];

		this.fetchData = this.fetchData.bind(this);
		this.getCustomRowStyling = this.getCustomRowStyling.bind(this);
	}

	getCustomRowStyling(row) {
		let backgroundColor;

		switch (row.original?.heat) {
			case 'Optimal':
				backgroundColor = 'white';
				break;
			case 'Slow':
				backgroundColor = '--slow';
				break;
			case 'Busy':
				backgroundColor = '--busy';
				break;
			case 'Too Busy':
				backgroundColor = '--too-busy';
				break;
			default:
				break;
		}

		return {
			backgroundColor: `rgb(${window
				.getComputedStyle(document.body)
				.getPropertyValue(backgroundColor)})`,
		};
	}

	fetchData(state) {
		const { fetchShiftClockinHourlyStats, workplaceId, date } = this.props;

		return fetchShiftClockinHourlyStats({ state, workplaceId, date });
	}

	render() {
		return (
			<div className="shift-clockins__table-wrapper__stats-table-wrapper">
				<ReactDataWrapper
					columns={this.columns}
					filterable
					fetchData={this.fetchData}
					defaultPageSize={10}
					reduxKey="/shiftplanning/workplaces/<workplaceId>/date/<date>/hourly_stats"
					manual
					hideMenu
					enableSingleSelection={false}
					enableMultiSelection={false}
					customRowStyling={this.getCustomRowStyling}
					showPagination={false}
					compact
				/>
			</div>
		);
	}
}

ShiftClockinsHourlyStatsTable.propTypes = {
	fetchShiftClockinHourlyStats: PropTypes.func,
	workplaceId: PropTypes.number,
	date: PropTypes.string,
};

export default ShiftClockinsHourlyStatsTable;
