'use strict';

import React, { Component } from 'react';

import Hotdamnbar from 'hotdamnbar';
import Report from 'report';

import AuditReportsHotbar from './auditReports.hotbar';
import SalaryAuditReports from './auditReports';

class SalaryAuditReportsContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			report: null
		};

		this.methods = {
			onHotbarChange: this.onHotbarChange.bind(this)
		};

		this.SalaryAuditReportsService = new SalaryAuditReports();
	}

	onHotbarChange(event) {
		// Initiate loading and reset report
		this.setState(() => ({ loading: true }));

		// Treat hotbar changes
		this.SalaryAuditReportsService.handleHotbarChange(event).then(report => {
			// Update report and stop loading
			this.setState(() => ({ loading: false, report }));
		});
	}

	render() {
		const { loading, report } = this.state;

		return (
			<div className="audit-reports">
				<Hotdamnbar
					onChange={this.methods.onHotbarChange}
					hotbar={AuditReportsHotbar}
					loading={loading}
				/>
				{report && <Report report={report} key={report.name} />}
			</div>
		);
	}
}

export default SalaryAuditReportsContainer;
