'use strict';
import _extend from 'lodash/extend';
import _get from 'lodash/get';
import _has from 'lodash/has';
import { post } from 'api.vanilla.service';
import { set as setFeedback } from 'feedback.vanilla.service';
/**
 * @function itemUpdate
 * @memberOf SERVICES.__models
 * @description
 * Update item from provided data
 * @param  {object} data  data to update item with
 * @param  {number} id  id of item to delete
 */

module.exports = function (data, id) {
	return new Promise((resolve, reject) => {
		var state = this.getState();

		// Post data to API endpoint to update item
		post(
			this.getSettings().endpoint.update.path + id,
			_extend({}, this.cleanData(data))
		).then(
			function (response) {
				var formattedItem = _has(response, 'data')
					? this.itemFormat(response.data[0])
					: {};

				// Run callbacks
				this.runModelCallbacks();

				// Reset API errors
				this.initApiErrorHeaders(state.headers);

				// Resolve updated item
				resolve(formattedItem);
			}.bind(this),
			function (response) {
				// Set API errors
				this.initApiErrorHeaders(state.headers, response.data);

				// Error msg
				let errorMsg;

				const errorArray = _get(response, 'data.data', []);

				// Collect errors
				if (errorArray.length > 0) {
					errorMsg = errorArray
						.map((error) =>
							Object.keys(error).map(
								(errorLine) => `${error[errorLine]} (${errorLine})`
							)
						)
						.join(',');
				} else errorMsg = `${response.code}: ${response.message}`;

				// Give feedback
				setFeedback(errorMsg, 0);

				// Reject
				reject(response);
			}.bind(this)
		);
	});
};
