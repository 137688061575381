'use strict';

import React, { ChangeEvent, useState, MutableRefObject } from 'react';

// interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';
import { IDynamicAccountReduxValue, IDynamicAccountCRUD } from './types';
import { SelectOption } from 'types/select';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';

// components
import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import getColumns from 'reactDataWrapperColumns/financial/dynamicAccounts.columns';
import DynamicAccountsModalBatch from './components/batch/dynamicAccountsModalBatch';

// components
import { Input, InputCollectionSelect, Button, Tooltip, Icon } from 'dumb';

import {
	fetchDynamicAccounts,
	deleteDynamicAccounts,
	editDynamicAccounts,
} from './dynamicAccounts.service';

import {
	setDynamicAccount,
	updateDynamicAccount,
	resetState,
} from './store/dynamicAccounts.actions';

// constants/phrases/tools
import phrases from './dynamicAccounts.phrases';
import getEditedValues from 'services/utils/getEditedValues';

// utils
import {
	getEntryTypeSelectOptions,
	getTypeSelectOptions,
	getEntryTypeLabel,
} from './dynamicAccounts.utils';

const reduxKey = '/financial/dynstamics_accounts';

const DynamicAccounts = (): JSX.Element => {
	// hooks
	const { dynamicAccount, defaultDynamicAccount, tableKey } = useAppSelector(
		(state) => ({
			dynamicAccount: state.dynamicAccounts.data.dynamicAccount,
			defaultDynamicAccount: state.dynamicAccounts.data.defaultDynamicAccount,
			tableKey: state.dynamicAccounts.tableKey,
		})
	);
	const dispatch = useAppDispatch();
	const [showBatchModal, setShowBatchModal] = useState(false);

	const editEntry = () => {
		if (!dynamicAccount.id || !defaultDynamicAccount)
			return Promise.resolve(true);

		const editedValues = getEditedValues({
			oldData: defaultDynamicAccount,
			newData: dynamicAccount,
		});

		const payload: Partial<IDynamicAccountCRUD> = {
			account_number: editedValues?.accountNumber,
			type: editedValues?.type,
		};

		return editDynamicAccounts(dynamicAccount.id, payload);
	};

	const deleteEntry = (id: number) => {
		return deleteDynamicAccounts(id);
	};

	const setInitialEditValues = (data: IFinancialDynamicAccount) => {
		const payload: IDynamicAccountReduxValue = {
			id: data.id,
			accountNumber: `${data.account_number}`,
			entryType: {
				value: data.entry_type,
				label: getEntryTypeLabel(data.entry_type),
			},
			type: {
				value: data.type,
				label: `${data.type}`,
			},
			editMode: true,
		};

		dispatch(setDynamicAccount(payload));
	};

	const editStoreEntry = (
		name: string,
		value: string | SelectOption | null | boolean
	): void => {
		if (name === 'closed' && value === null) {
			value = '9999-12-31';
		}

		const payload = {
			[name]: value,
		};

		dispatch(updateDynamicAccount(payload));
	};

	const getEditableCells = () => {
		return [
			{
				header: 'Account number',
				value: (
					<Input
						id="account-number"
						placeholder="Enter account number..."
						value={dynamicAccount.accountNumber}
						onChange={(event: ChangeEvent<HTMLInputElement>) =>
							editStoreEntry('accountNumber', event.target.value)
						}
						required
					/>
				),
			},
			{
				header: 'Type',
				value: (
					<InputCollectionSelect
						id="type"
						placeholder="Select type..."
						value={dynamicAccount.type}
						handleChange={(_: unknown, value: SelectOption) =>
							editStoreEntry('type', value)
						}
						required={!dynamicAccount.editMode}
						clearable={false}
						options={getTypeSelectOptions()}
					/>
				),
			},
			{
				header: 'Entry type',
				value: (
					<InputCollectionSelect
						id="entry-type"
						placeholder="Select entry type..."
						value={dynamicAccount.entryType}
						handleChange={(_: unknown, value: SelectOption) =>
							editStoreEntry('entryType', value)
						}
						required={!dynamicAccount.editMode}
						clearable={false}
						options={getEntryTypeSelectOptions()}
						disabled
					/>
				),
			},
		];
	};

	const toggleShowBatchModal = () => setShowBatchModal(!showBatchModal);

	return (
		<>
			<ReactDataWrapper
				key={tableKey}
				title={phrases.TABLE_TITLE}
				columns={getColumns()}
				fetchData={(
					state: IFetchDataProps
				): ReturnType<typeof fetchDynamicAccounts> =>
					fetchDynamicAccounts(state)
				}
				filterable
				defaultPageSize={10}
				reduxKey={reduxKey}
				manual
				accessAreasAllowedToEdit={[
					'Organisation/Workplaces::Edit',
					'Organization Admin',
				]}
				editableCells={getEditableCells()}
				actionsWidth={80}
				editEntry={editEntry}
				deleteEntry={deleteEntry}
				setInitialEditValues={setInitialEditValues}
				onModalClose={() => dispatch(resetState())}
				onModalCloseConfirm
				actionRender={
					<Tooltip
						text={phrases.BATCH_ADD}
						renderData={(
							ref: MutableRefObject<undefined>,
							onMouseEnter: () => void,
							onMouseLeave: () => void
						) => (
							<Button
								label="Add"
								shadow
								type="inverted"
								onClick={toggleShowBatchModal}
								refProp={ref}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}>
								<Icon name="add" />
							</Button>
						)}
					/>
				}
			/>

			{showBatchModal && (
				<DynamicAccountsModalBatch onClose={toggleShowBatchModal} />
			)}
		</>
	);
};

export default DynamicAccounts;
