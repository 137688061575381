'use strict';

// Lodash
import _isUndefined from 'lodash/isUndefined';
import _last from 'lodash/last';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import _isString from 'lodash/isString';
import _template from 'lodash/template';

// Services
import * as translations from 'translations.service';
import { get } from 'api.vanilla.service';
var __apiFilter = require('apiFilter.service');

export default class SelectService {
	/// ///////////////////
	// Public Functions //
	/// ///////////////////

	LoadAsyncOptions(config, term) {
		return new Promise((resolve, reject) => {
			let searchFilter;
			let termSearchFilter;
			const additionalSearchListFilter = config.searchListFilter;

			// Define label - name is default unless anything
			// else is declared in header.reference.label
			const label = _isUndefined(config.labelTemplate)
				? '<%= name %>'
				: config.labelTemplate;

			// Define endpoint - list.settings.endpoint is default
			const endpoint = _isString(config.searchList)
				? config.searchList
				: config.searchList.settings.endpoint.index.path;

			// Only activate term-relevant fields if term has any length
			if (term.length > 0) {
				// Define searchFields - name is default unless anything
				// else is declared in header.reference.searchFields
				const searchFields = _isUndefined(config.searchFields)
					? ['name']
					: config.searchFields;

				// Loop through searchFields to add field and data
				const fields = _map(searchFields, field => {
					return {
						field: field,
						operator: '=like=',
						data: '%' + term + '%',
						joiningOperator: ','
					};
				});

				fields[fields.length - 1] = _omit(_last(fields), 'joiningOperator');

				// Set search filter
				termSearchFilter = __apiFilter.create([{ fields: fields }]);
			}

			searchFilter = [
				_isUndefined(termSearchFilter) ? null : `(${termSearchFilter})`,
				_isUndefined(additionalSearchListFilter)
					? null
					: `(${additionalSearchListFilter})`
			];

			searchFilter = searchFilter.filter(filter => filter !== null).join(';');

			// Get list data


			get(
				endpoint,
				{ filter: searchFilter, ...(config.searchListLimit ? { limit: config.searchListLimit } : {}) },
				undefined,
				undefined
			).then(
				(response) => {
					const data = response.data;
					const options = _map(data, function(item) {
						return {
							value: item.id.toString(),
							label: _template(label)(item)
						};
					});

					resolve(options);
				},
				response => {
					reject(response);
				}
			);
		});
	}

	GetTranslations() {
		return translations.getObject([
			'COMPONENTS.INPUTS.SELECT__NO_RESULTS',
			'COMPONENTS.INPUTS.SELECT__SEARCH'
		]);
	}
}
