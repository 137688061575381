import _isEmpty from 'lodash/isEmpty';

/**
 * @function formatRecepients
 * @param {Arrray} recepients 
 * @description adds a new key inside fields array, calls it 'final' and sets the value of value field to final field
 * @returns {Array}
 */
export function formatRecepients(recepients) {
	return recepients.data.map((entry) => {
		if (!_isEmpty(entry.fields)) {
			return {
				...entry,
				fields: entry.fields.map((entry) => ({
					...entry,
					final: entry.value,
				})),
			};
		}

		return entry;
	});
}
