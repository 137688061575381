'use strict';

export const EDIT_USER = 'JUICER_DETAILS_USERS/EDIT_USER';
export const SET_USER = 'JUICER_DETAILS_USERS/SET_USER';
export const RESET_STATE = 'JUICER_DETAILS_USERS/RESET_STATE';

export const editUsers = payload => {
	return { type: EDIT_USER, payload };
};

export const setUsers = payload => {
	return { type: SET_USER, payload };
};

export const resetState = () => {
	return { type: RESET_STATE };
};
