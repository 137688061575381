/**
 * @function sortBySortOrder
 * @description Sorts entry based on sort order. Helper function for arrays
 */
export default function sortBySortOrder(a, b) {
	// Return collection to sort order
	if (a.sortOrder < b.sortOrder) return -1;
	if (a.sortOrder > b.sortOrder) return 1;
	return 0;
}
