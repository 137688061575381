import React from 'react';

// interfaces
import { IMarketResponsibilityBatch } from '../../../types';
import { SelectOption } from 'types/select';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import {
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,
} from './../../../store/marketResponsibilities.actions';

// components
import { InputCollectionSelect, SingleDatePickerInput } from 'dumb';
import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';
import { Box, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import getMarketColumns from 'reactDataWrapperColumns/organization/markets.columns';

// utils
import phrases from './../../../marketResponsibilities.phrases';
import moment, { Moment } from 'moment';
import constants from 'services/constants';

const MarketResponsibilitiesModalStep = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const batchList = useAppSelector(
		(store) => store.marketResponsibilities.batchList
	);

	const handleUpdateEntry = (
		row: IMarketResponsibilityBatch,
		type: keyof IMarketResponsibilityBatch,
		value: string | null | SelectOption
	) => {
		const payload: Partial<IMarketResponsibilityBatch> = {
			identifier: row.identifier,
			[type]: value,
		};

		dispatch(editBatchListItem(payload));
	};
	const handleDeleteRow = (identifier: string) => () =>
		dispatch(removeBatchListItem(identifier));
	const handleAddRow = () => dispatch(addBatchListItemRow());

	return (
		<>
			<TableList>
				<thead>
					<tr>
						<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
						<th>{phrases.MODAL_BATCH_STEP_LIST_ROLE}</th>
						<th>{phrases.MODAL_BATCH_STEP_LIST_LOCATION}</th>
						<th>{phrases.MODAL_BATCH_STEP_LIST_FROM}</th>
						<th>{phrases.MODAL_BATCH_STEP_LIST_TO}</th>

						<th />
					</tr>
				</thead>
				<tbody>
					{batchList.map((entry, i) => {
						const deleteRowButtonDisabled = batchList.length === 1;

						return (
							<TableListRow key={entry.identifier}>
								<TableListCell>{i + 1}</TableListCell>

								<TableListCell>
									<InputCollectionSelect
										id="role"
										dataCy="market-responsibilities-batch-add-select-role"
										placeholder="Select role..."
										value={entry.role}
										handleChange={(_: unknown, value: SelectOption) =>
											handleUpdateEntry(entry, 'role', value)
										}
										cache
										apiPath="/authentication/roles"
										params={{
											limit: 30,
										}}
										clearable={false}
										optionFormat={(entry: any) => ({
											value: entry,
											label: entry.name,
										})}
										inputFilterFormat={(input: string) =>
											`:name=like='%${input}%'`
										}
									/>
								</TableListCell>

								<TableListCell>
									<InputCollectionSelect
										id="location"
										dataCy="market-responsibilities-batch-add-select-location"
										placeholder="Select location..."
										value={entry.location}
										handleChange={(_: unknown, value: SelectOption) =>
											handleUpdateEntry(entry, 'location', value)
										}
										cache
										apiPath="/administration/markets"
										params={{
											limit: 30,
										}}
										clearable={false}
										optionFormat={(entry: any) => ({
											value: entry,
											label: entry.name,
										})}
										inputFilterFormat={(input: string) =>
											`:name=like='%${input}%'`
										}
										tableColumns={getMarketColumns()}
										tableReduxKey="/administration/markets-marketResponsibility-edit-modal"
										tableTitle={phrases.MARKETS_TITLE}
									/>
								</TableListCell>

								<TableListCell>
									<SingleDatePickerInput
										id="from"
										dataCy="market-responsibilities-batch-add-date-from"
										onChange={(event: Moment) =>
											handleUpdateEntry(
												entry,
												'from',
												event.format(constants.shortDate)
											)
										}
										selectedDate={
											entry.from || moment().format(constants.shortDate)
										}
										noClockButton
										required
									/>
								</TableListCell>

								<TableListCell>
									<SingleDatePickerInput
										id="to"
										dataCy="market-responsibilities-batch-add-date-to"
										onChange={(event: Moment) =>
											handleUpdateEntry(
												entry,
												'to',
												event.format(constants.shortDate)
											)
										}
										selectedDate={entry.to || '9999-12-31'}
										clearable={entry.to !== '9999-12-31'}
										noClockButton
										required
									/>
								</TableListCell>

								<TableListCell>
									<Tooltip title={phrases.DELETE_ROW}>
										<IconButton
											onClick={handleDeleteRow(entry.identifier)}
											data-cy="market-responsibilities-batch-add-delete-row"
											disabled={deleteRowButtonDisabled}>
											<DeleteIcon
												sx={{
													color: (theme) => theme.palette.warning.main,
												}}
											/>
										</IconButton>
									</Tooltip>
								</TableListCell>
							</TableListRow>
						);
					})}
				</tbody>
			</TableList>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					mt: 2,
				}}>
				<Tooltip title={phrases.ADD_ROW_TOOLTIP}>
					<IconButton
						onClick={handleAddRow}
						data-cy="market-responsibilities-batch-add-add-row">
						<AddIcon />
					</IconButton>
				</Tooltip>
			</Box>
		</>
	);
};

export default MarketResponsibilitiesModalStep;
