import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import OnboardingValuesList from 'hr/onboardings/services/onboardingValuesList.service.js';
import DetailsComponent from 'common/components/details/details.component';
import ListComponent from 'common/components/list/list.component';
import __apiFilter from 'apiFilter.service';
import __endpoints from 'endpoints.service';
import OnboardingDetails from 'hr/onboardings/services/onboardingsDetails.service.js';

const reduxKey = '/hr/onboardings/value_comparisons';
const onboardingValuesList = new OnboardingValuesList(reduxKey);
const onboardingValuesListProps = onboardingValuesList.getProps();

const onboardingDetailsReduxKey = '/hr/employment_workplaces';
const onboardingDetails = new OnboardingDetails(onboardingDetailsReduxKey);
const onboardingDetailsProps = onboardingDetails.getProps();

export function OnboardingDetailsComponent() {
	const { onboardingId } = useParams();

	// Get common filter for querying where person = id
	const personIdFilter = __apiFilter.defaults.fieldEqualsData('onboarding', onboardingId);

	// Overwrite endpoint value
	onboardingValuesList.settings.endpoint = {
		create: {
			action: 'create',
			method: 'POST',
			path: `/hr/onboardings/${onboardingId}/value_comparisons`,
			resource: 'Onboardings',
			url: __endpoints.apiHost + __endpoints.apiUri + `/hr/onboardings/${onboardingId}/value_comparisons`,
		},
		delete: {
			action: 'delete',
			method: 'DELETE',
			path: `/hr/onboardings/${onboardingId}/value_comparisons/`,
			resource: 'Onboardings',
			url: __endpoints.apiHost + __endpoints.apiUri + `/hr/onboardings/${onboardingId}/value_comparisons/`,
		},
		find: {
			action: 'find',
			method: 'GET',
			path: `/hr/onboardings/${onboardingId}/value_comparisons/`,
			resource: 'Onboardings',
			url: __endpoints.apiHost + __endpoints.apiUri + `/hr/onboardings/${onboardingId}/value_comparisons/`,
		},
		index: {
			action: 'index',
			method: 'GET',
			path: `/hr/onboardings/${onboardingId}/value_comparisons`,
			resource: 'Onboardings',
			url: __endpoints.apiHost + __endpoints.apiUri + `/hr/onboardings/${onboardingId}/value_comparisons`,
		},
		update: {
			action: 'update',
			method: 'POST',
			path: `/hr/onboardings/${onboardingId}/value_comparisons/`,
			resource: 'Onboardings',
			url: __endpoints.apiHost + __endpoints.apiUri + `/hr/onboardings/${onboardingId}/value_comparisons/`,
		},
	};

	useEffect(() => {
		onboardingDetails.setItemId(onboardingId);
		onboardingValuesList.setFilterQuery(personIdFilter);

		onboardingDetails.initView();
		onboardingValuesList.initView();
	}, [personIdFilter, onboardingId]);

	return (
		<>
			<DetailsComponent reduxKey={onboardingDetailsReduxKey} {...onboardingDetailsProps} />

			<ListComponent reduxKey={reduxKey} {...onboardingValuesListProps} />
		</>
	);
}
