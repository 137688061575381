import React from 'react';

// redux
import { useSelector } from 'react-redux';

// components
import { ModalRow } from 'dumb';
import { Typography } from '@mui/material';

// misc
import phrases from './../tenantTransferModal.phrases';

const TenantTransferModalStepThree = () => {
	const { employmentTerminated } = useSelector((store) => store.tenantTransfer);

	if (employmentTerminated === undefined) return null;

	return (
		<ModalRow>
			<Typography>{employmentTerminated ? phrases.STEP_THREE_SUCCESS : phrases.STEP_THREE_FAIL}</Typography>
		</ModalRow>
	);
};

export default TenantTransferModalStepThree;
