'use strict';

class HotbarDataProviderAbstract {

	constructor() {
		// Set type
		this.type = 'dataProvider';

		// Set default empty parameter values
		this.parameterValues = {};
	}

	/**
	 * @function setParameter
 	 * @memberOf HotbarDataProviderAbstract
	 * @description Set parameters based on component value;
	 * @param {string} paramName
	 * @param {array} value
	 */
	setParameter(paramName, value) {
		this.parameterValues[paramName] = value;
	}

	/**
	 * @function getParameter
 	 * @memberOf HotbarDataProviderAbstract
	 * @description Get parameters based on component values;
	 * @param {string} paramName
	 */
	getParameter(paramName) {
		return this.parameterValues[paramName] || null;
	}

}

export default HotbarDataProviderAbstract;