import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Store
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import { setListData } from 'reactDataWrapper/reactDataWrapper.actions';
import {
	fetchPersonData,
	postPersonData,
	postCustomData,
	postAddressData,
	createAddressData,
	clearOptimisticAddressData,
	resetSeniority,
} from './store/juicerDetails.actions';

// Components
import { ProfilePicture, Loader, Card } from 'dumb';
import { Typography, Box } from '@mui/material';
import PersonDetails from './components/personDetails.component';
import AddressDetailsCard from './components/addressDetailsCard/addressDetailsCard.component';
import ContactDetailsCard from './components/contactsCard/contactsCard.component';
import EmploymentList from './components/employmentsList/employmentsList.container';
import VacationIllnessWrapper from './components/vacationIllnessWrapper/vacationIllnessWrapper.component';
import UserShifts from './components/userShifts/userShifts.component';
import Trainings from './components/trainings/trainings.container';
import TerminateEmployeeContainer from './components/terminateEmployee/terminateEmployee.container';
import AttributesUsersWrapper from './components/attributesUsersWrapper/attributesUsersWrapper.container';

// Tools
import './juicerDetails.container.css';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

// utils
import { getColorCoding, withRouter } from 'services/utils';
import { getUserHasAccessToSensitivePersonInformation } from 'accessControl';

import phrases from './juicerDetails.phrases';

class JuicerDetails extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showTerminationModal: false,
			terminationModalData: null,
		};

		this.userHasAccessToSensitivePersonInfo =
			getUserHasAccessToSensitivePersonInformation();
	}

	componentDidMount() {
		fetchPersonData(
			this.props.params.personId,
			this.userHasAccessToSensitivePersonInfo
		);
	}

	componentWillUnmount() {
		this.props.resetSeniority();
	}

	render() {
		const {
			personUi,
			personData,
			params,
			addressData,
			personPersonalInformation,
			createAddressData,
			employmentsList,
			setListData,
			seniority,
		} = this.props;

		let variableName;
		if (!_isEmpty(seniority)) {
			const maximumHours = seniority.seniority_level?.maximum_hours;
			const minimumHours = seniority.seniority_level?.minimum_hours;

			const coding = getColorCoding({
				maximumHours,
				minimumHours,
			});

			variableName = coding && coding.variableName;
		}

		return (
			<div className="juicer-details">
				<ProfilePicture size="large" center />

				{/* <h2 className="juicer-details__title"> */}
				<Typography variant="h4" component="h2" align="center">
					{personUi.loading && <Loader />}
					{!personUi.loading && personData && <>{personData.full_name}&nbsp;</>}
					({params.personId})
				</Typography>

				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Typography variant="h5" component="h3" sx={{ pr: 1 }}>
						{phrases.APP_USER}{' '}
						<input type="checkbox" checked={!!personData.app_user} readOnly />
					</Typography>

					{!_isEmpty(seniority) && (
						<>
							{/* <h3 className="juicer-details__sub-header--left-margin"> */}
							<Typography variant="h5" component="h3" sx={{ pr: 1 }}>
								{phrases.HOURS_WORKED} {seniority.total_hours_worked}
							</Typography>

							<Box
								style={{
									backgroundColor: `rgb(${window
										.getComputedStyle(document.body)
										.getPropertyValue(variableName)})`,
									height: 10,
									width: 10,
									borderRadius: 10,
								}}
							/>
						</>
					)}
				</Box>

				<div className="juicer-details__card-wrapper">
					<div className="juicer-details__card">
						<PersonDetails
							personId={params.personId}
							personData={personData}
							personPersonalInformation={personPersonalInformation}
							loading={personUi.loading}
							postPersonData={postPersonData}
							postCustomData={postCustomData}
							userHasAccessToSensitivePersonInfo={
								this.userHasAccessToSensitivePersonInfo
							}
						/>
					</div>

					{this.userHasAccessToSensitivePersonInfo && (
						<>
							<div className="juicer-details__card">
								<AddressDetailsCard
									personId={params.personId}
									personData={personData}
									loading={personUi.loading}
									postAddressData={postAddressData}
									createAddressData={createAddressData}
									addressData={addressData}
								/>
							</div>
							<div className="juicer-details__card">
								<ContactDetailsCard
									personId={params.personId}
									personData={personData}
									loading={personUi.loading}
									postCustomData={postCustomData}
								/>
							</div>
						</>
					)}
				</div>

				<Card type="small">
					<EmploymentList personId={params.personId} />
				</Card>

				<div className="juicer-details__card-wrapper__tables">
					<Card type="small">
						<Trainings personId={params.personId} />
					</Card>
					<Card type="small">
						<VacationIllnessWrapper personId={params.personId} />
					</Card>
					<Card type="small">
						<UserShifts personId={params.personId} />
					</Card>
					<Card type="small">
						<AttributesUsersWrapper
							personId={params.personId}
							personData={personData}
						/>
					</Card>
				</div>
				<div className="juicer-details__terminate">
					<TerminateEmployeeContainer
						personId={params.personId}
						personData={personData}
						employmentsList={employmentsList}
						setListData={setListData}
						showTerminationModal={this.state.showTerminationModal}
						terminationModalData={this.state.terminationModalData}
					/>
				</div>
			</div>
		);
	}
}

JuicerDetails.propTypes = {
	personUi: PropTypes.object,
	addressData: PropTypes.object,
	personPersonalInformation: PropTypes.object,
	params: PropTypes.object,
	createAddressData: PropTypes.func,
	seniority: PropTypes.object,
	personData: PropTypes.object,
	resetSeniority: PropTypes.func,
	employmentsList: PropTypes.array,
	setListData: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			createAddressData,
			clearOptimisticAddressData,
			setListData,
			resetSeniority,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		personUi: store.juicerDetails.ui,
		personData: store.juicerDetails.data.personData,
		seniority: store.juicerDetails.data.seniority,
		personPersonalInformation:
			store.juicerDetails.data.personPersonalInformation,
		addressData: store.juicerDetails.optimistic.addressData,
		employmentsList: _get(
			store,
			`listData.['hr/employments'].data.listData`,
			[]
		),
	};
};

export default withRouter(
	connectWithStore(JuicerDetails, mapStateToProps, mapDispatchToProps)
);
