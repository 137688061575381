'use strict';

import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';

import ContinentsList from './services/continentsList.service.js';

const continentsListReduxKey = '/administration/continents';
const continentsList = new ContinentsList(continentsListReduxKey);
const continentsListProps = continentsList.getProps();

export function Continents() {
	useEffect(() => {
		continentsList.initView();
	}, []);

	return (
		<ListComponent reduxKey={continentsListReduxKey} {...continentsListProps} />
	);
}
