'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';
import getMarketColumns from 'reactDataWrapperColumns/organization/markets.columns';

// constants/phrases/enums
import phrases from './../../../powerBIReports.phrases';

class CopyPowerBiReportModalMarkets extends Component {
	render() {
		const { markets, editMarkets } = this.props;

		return (
			<ModalRow>
				<InputCollectionSelect
					id="market"
					className="modal-batch-select"
					label={phrases.MODAL_BATCH_STEP_MARKETS}
					placeholder={phrases.MODAL_BATCH_STEP_MARKETS_PLACEHOLDER}
					value={markets}
					handleChange={(key, value) => editMarkets(value)}
					multi
					cache
					apiPath="/organization/markets"
					params={{
						limit: 30,
					}}
					optionFormat={(entry) => ({
						value: entry,
						label: entry.name,
					})}
					inputFilterFormat={(input) => `:name=like='%${input}%'`}
					tableColumns={getMarketColumns()}
					tableTitle={phrases.MODAL_BATCH_STEP_MARKETS}
					tableReduxKey="power-bi-reports/organization/markets"
				/>
			</ModalRow>
		);
	}
}

CopyPowerBiReportModalMarkets.propTypes = {
	markets: PropTypes.array,
	editMarkets: PropTypes.func,
};

export default CopyPowerBiReportModalMarkets;
