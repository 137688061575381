export const RESET_STATE = 'DOCUSIGN_TEMPLATE_ROLES/RESET_STATE';
export const SET_DOCUSIGN_TEMPLATE =
	'DOCUSIGN_TEMPLATE_ROLES/SET_DOCUSIGN_TEMPLATE';
export const UPDATE_DOCUSIGN_TEMPLATE =
	'DOCUSIGN_TEMPLATE_ROLES/UPDATE_DOCUSIGN_TEMPLATE';

export function setDocusignTemplateRole(payload) {
	return {
		type: SET_DOCUSIGN_TEMPLATE,
		payload,
	};
}

export function updateDocusignTemplateRole(payload) {
	return {
		type: UPDATE_DOCUSIGN_TEMPLATE,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}
