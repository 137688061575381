'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { InputCollectionSelect, FileUpload, Input } from 'dumb';
import { ModalRow } from 'dumb/modal';

// lodash
import _get from 'lodash/get';

// phrases/enums
import phrases from './../../../productVariantGroupProductVariants.phrases';

class ProductVariantGroupProductVariantsBatchStepForm extends Component {
	constructor(props) {
		super(props);

		this._editStoreEntry = this._editStoreEntry.bind(this);
	}

	_editStoreEntry(type, e) {
		const { updateBatchForm } = this.props;

		updateBatchForm({
			[type]: e,
		});
	}

	render() {
		const { batchForm } = this.props;

		return (
			<ModalRow>
				<InputCollectionSelect
					id="productVariant"
					placeholder={phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT_PLACEHOLDER}
					value={batchForm.productVariant}
					name="productVariant"
					handleChange={this._editStoreEntry}
					cache
					apiPath="/product/product_variants"
					params={{
						limit: 30,
					}}
					multi
					optionFormat={(entry) => ({
						value: entry,
						label: entry.name,
					})}
					inputFilterFormat={(input) => `:name=like='%${input}%'`}
					tableTitle="Product variants"
					tableReduxKey="filterProductVariants-/pos/product_variants"
					tableColumns={[
						{
							Header: 'Name',
							id: 'name',
							accessor: 'name',
							filterPath: ':name',
						},
						{
							Header: 'Product',
							id: 'prod',
							accessor: (d) => _get(d, 'product.name', ''),
							filterPath: ':product.name',
						},
						{
							Header: 'Category',
							id: 'category',
							accessor: (d) => _get(d, 'product.product_category.name', ''),
							filterPath: ':product.product_category.name',
						},
						{
							Header: 'Size',
							id: 'size',
							accessor: (d) => _get(d, 'size.name', ''),
							filterPath: ':size.name',
						},
						{
							Header: 'Bundle',
							id: 'bundle',
							accessor: (d) => _get(d, 'bundle.name', ''),
							filterPath: ':bundle.name',
						},
						{
							Header: 'Fuel',
							id: 'fuel',
							accessor: (d) => _get(d, 'fuel', ''),
							filterPath: ':fuel',
						},
						{
							Header: 'Points',
							id: 'points',
							accessor: (d) => _get(d, 'points.points', ''),
							filterPath: ':points.points',
							filterable: false,
							sortable: false,
						},
						{
							Header: 'Image',
							id: 'image',
							accessor: (d) => _get(d, 'image.filename', ''),
							filterPath: ':image.filename',
							filterable: false,
							sortable: false,
							Cell: (d) => {
								const url = _get(d, 'original.image.url', false);
								let file = null;
								if (url) {
									file = {
										url,
										filename: _get(d, 'original.image.filename', ''),
									};
								}
								return <FileUpload file={file} thumbnail disabled />;
							},
						},
						{
							Header: 'Asset Collection',
							id: 'asset_collection',
							accessor: (d) => _get(d, 'asset_collection.name', null),
							filterPath: ':asset_collection.name',
						},
						{
							Header: 'Legacy',
							id: 'legacy',
							accessor: (d) => _get(d, 'legacy', ''),
							filterPath: ':legacy',
							Cell: (d) => {
								return (
									<Input
										id="legacy"
										type="checkbox"
										checked={_get(d, 'original.legacy', false)}
										disabled
									/>
								);
							},
						},
					]}
				/>
			</ModalRow>
		);
	}
}

ProductVariantGroupProductVariantsBatchStepForm.propTypes = {
	batchForm: PropTypes.object,
	updateBatchForm: PropTypes.func,
};

export default ProductVariantGroupProductVariantsBatchStepForm;
