export const RESET_STATE =
	'GLOBAL_ADMINISTRATION_EMPLOYEE_MEAL_APP_ALLOWANCE/RESET_STATE';
export const SET_EMPLOYEE_MEAL_APP_ALLOWANCE =
	'GLOBAL_ADMINISTRATION_EMPLOYEE_MEAL_APP_ALLOWANCE/SET_EMPLOYEE_MEAL_APP_ALLOWANCE';
export const UPDATE_EMPLOYEE_MEAL_APP_ALLOWANCE =
	'GLOBAL_ADMINISTRATION_EMPLOYEE_MEAL_APP_ALLOWANCE/UPDATE_EMPLOYEE_MEAL_APP_ALLOWANCE';

export const EDIT_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_EMPLOYEE_MEAL_APP_ALLOWANCE/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_EMPLOYEE_MEAL_APP_ALLOWANCE/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'GLOBAL_ADMINISTRATION_EMPLOYEE_MEAL_APP_ALLOWANCE/ADD_BATCH_LIST_ITEM_ROW';

export function setEmployeeMealAppAllowance(payload) {
	return {
		type: SET_EMPLOYEE_MEAL_APP_ALLOWANCE,
		payload,
	};
}

export function updateEmployeeMealAppAllowance(payload) {
	return {
		type: UPDATE_EMPLOYEE_MEAL_APP_ALLOWANCE,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}
