'use strict';

import _isEmpty from 'lodash/isEmpty';

/**
 * @function _getFilter
 * @description Extracts filter from hotbar resultSet
 * @param {object} resultSet
 * @return {object} filter object
 */
const getFilter = (resultSet) => {
	const results = resultSet.results;

	// Check for correct results from hotbar
	if (results.Markets !== undefined || results.Stores !== undefined) {
		// Results
		let marketsResults = results.Markets.getResults();
		const storesResults = results.Stores.getResults() || [];

		// Get store market ids so these can be removed from marketResults
		const storeMarketIds = !storesResults
			? []
			: storesResults.reduce((acc, store) => {
					if (acc.indexOf(store.market) === -1) acc.push(store.market);
					return acc;
			  }, []);

		// Update marketsResults and remove any markets already covered by stores
		marketsResults = !marketsResults
			? []
			: marketsResults.filter(
					(market) => storeMarketIds.indexOf(market.value) === -1
			  );

		const marketsFilter = _filterHelper(marketsResults, 'workplace.market.id');
		const storesFilter = _filterHelper(storesResults, 'workplace.id');

		// Accumulated filter
		let filter;

		// If both market and stores are selected, we should only filter on selected stores
		if (!_isEmpty(marketsFilter) && !_isEmpty(storesFilter)) {
			filter = storesFilter;

			// If only market is selected, we should only filter on market
		} else if (!_isEmpty(marketsFilter)) {
			filter = marketsFilter;

			// If only stores are selected, we should also only filter on market
		} else if (!_isEmpty(storesFilter)) {
			filter = storesFilter;
		}

		// Resolve promise with filter
		return Promise.resolve(filter);
	}

	// Return error if not present
	else
		return Promise.reject(
			new Error('getFilter(): Hotbar result set is missing Markets or Stores.')
		);
};

/**
 * @function _getParameters
 * @description Extracts parameters from hotbar resultSet
 * @param {object} resultSet
 * @return {object} parameter objects
 */
const getParameters = (resultSet) => {
	const results = resultSet.results;

	const parameters = {};

	// Check for correct results from hotbar
	if (results.Period === undefined)
		return Promise.reject(
			new Error('getFilter(): Hotbar result set is missing Period.')
		);

	// Get date range from hotbar
	parameters.from = results.Period.getStartDate().format('YYYY-MM-DD');
	parameters.to = results.Period.getEndDate().format('YYYY-MM-DD');

	// Check for correct results from hotbar
	if (results['Reporting Currency'] === undefined)
		return Promise.reject(
			new Error('getFilter(): Hotbar result set is missing Reporting Currency.')
		);

	// Get currency reporting id from hotbar
	parameters.reporting_currency = results[
		'Reporting Currency'
	].getResults()[0].value;

	// Week days
	if (
		(results['Week Days'] !== undefined || results['Week Days'] !== null) &&
		results['Week Days'].getResults() &&
		results['Week Days'].getResults().length > 0
	)
		parameters.weekdays = results['Week Days']
			.getResults()
			.map((weekday) => weekday.value)
			.join(',');

	// Resolve promise with parameters
	return Promise.resolve(parameters);
};

/**
 * @function _filterHelper
 * @private
 * @description Helper for creating filterstrings
 * @param {*} arr - array to map over
 * @param {*} filterString - e.g. :market.id
 * @returns {String} - string, e.g. ":market.id=='1',:market.id=='2'"
 */
function _filterHelper(arr, filterString) {
	return arr
		.map((result, index, arr) =>
			index === arr.length - 1
				? `:${filterString}=='${result.value}'`
				: `:${filterString}=='${result.value}',`
		)
		.join('');
}

export { getFilter, getParameters };
