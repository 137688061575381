export default {
	ASSETS: 'Assets',
	IMAGE_RESIZE_TOOLTIP: 'Uploaded image will be downscaled into into 2 images.',
	NO_ASSET_COLLECTION: 'No Asset collection associated',
	NO_ASSET_COLLECTION_INTERACTION: 'CLICK HERE TO GET STARTED',

	MODAL_ADD_ASSET_COLLECTION: 'Add Asset collection',
	MODAL_ASSIGN_ASSET_COLLECTION: 'Assign Asset collection',
	MODAL_MAIN_MENU_TITLE: 'Create or assign Asset collection',
	MODAL_STEP_FORM_CONFIRM: 'Submit',
	MODAL_STEP_LIST_BACK_BUTTON_LABEL: 'Back',
	MODAL_NAME: 'Name',
	MODAL_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_DESCRIPTION: 'Description',
	MODAL_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_ADD_ASSET_COLLECTION_TIP_PRODUCT:
		'Created Asset collection will be automatically assigned to the product',
	MODAL_ADD_ASSET_COLLECTION_TIP_FILTER:
		'Created Asset collection will be automatically assigned to the filter',
	MODAL_ASSET_COLLECTION: 'Asset collection',
	MODAL_ASSET_COLLECTION_PLACEHOLDER: 'Select Asset collection...',
	MODAL_STEP_FORM_TITLE_ASSIGN: 'Assign an existing Asset collection',
	DELETE_ASSOCIATION_TO_ASSET_COLLECTION:
		'Delete association to asset collection',
};
