'use strict';

// Services
import DetailsModel from 'common/components/details/detailsModel.service';

const __endpoints = require('endpoints.service');
const __enums = require('enums.service');

export default class GlobalAccountsDetails extends DetailsModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
				canCreate: true,
				canEdit: true,
				required: true,
				span: 3,
			},
			{
				name: 'type',
				type: 'select',
				canCreate: true,
				canEdit: true,
				span: 3,
				options: {
					enums: __enums.globalAccountTypes,
				},
			},
		];
		this.settings = {
			name: 'GlobalAccountsDetails',
			pageTitle: true,
			canCreate: true,
			canEdit: true,
			canDelete: true,
			translationPath: 'ACCOUNTING.GLOBAL_ACCOUNTS',
			endpoint: __endpoints.collection.accounting.global_accounts,
		};
		this.init();
		return this;
	}
}
