'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// rdw actions
import { store } from 'appState';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';
import { batch } from 'react-redux';

// components
import { ModalStepper } from 'dumb';

// different batch views
import SalesConfigurationPosConfigurationDiscountProductVariantBatchDiscountAmountsEdit from './components/salesConfigurationPosConfigurationDiscountProductVariantBatchDiscountAmountsEdit';
import SalesConfigurationPosConfigurationDiscountProductVariantBatchListEdit from './components/salesConfigurationPosConfigurationDiscountProductVariantBatchListEdit';

// phrases
import phrases from './../../salesConfigurationPosConfigurationDiscountProductVariant.phrases';

// utils
import { calculateDiscounts } from './../../utils/utils';

// loooodash
import _get from 'lodash/get';

class SalesConfigurationPosConfigurationDiscountProductVariantModalBatchEdit extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showOverlay: false,
			allowModalOverflow: true
		};

		this.modalRef = React.createRef();
	}

	_onSubmitForm() {
		const { batchFormData, batchListData, addBatchList } = this.props;

		// add new discounts
		const decoratedListData = batchListData.map(entry => {
			if (_get(batchFormData, 'discountType.value', '') === 'Nominal') {
				return {
					...entry,
					toStayDiscountNew: batchFormData.toStayDiscount,
					toGoDiscountNew: batchFormData.toGoDiscount
				};
			} else {
				// calculate discounts based on percentages chosen
				const calculatedPrices = calculateDiscounts({
					toStayPrice: entry.toStayPrice,
					toGoPrice: entry.toGoPrice,
					batchFormData
				});

				return {
					...entry,
					toStayDiscountNew: calculatedPrices.toStayDiscount.toString(),
					toGoDiscountNew: calculatedPrices.toGoDiscount.toString()
				};
			}
		});

		addBatchList(decoratedListData);

		this._goToStep(2);
	}

	_goToStep(step) {
		if (step === 2) this.setState(() => ({ allowModalOverflow: false }));
		if (step !== 2 && this.state.allowModalOverflow === false)
			this.setState(() => ({ allowModalOverflow: true }));

		this.modalRef.current.goToStep(step);
	}

	_batchSubmit() {
		const {
			batchListData,
			handleClose,
			editPosConfigurationDiscountProductVariants,
			reduxKey
		} = this.props;

		this.setState(() => ({
			loadingModal: true
		}));

		const payload = batchListData.map(entry => {
			return {
				id: entry.identifier,
				to_stay_discount_price: _get(entry, 'toStayDiscountNew', 0).replace(
					',',
					'.'
				),
				to_go_discount_price: _get(entry, 'toGoDiscountNew', 0).replace(
					',',
					'.'
				)
			};
		});

		editPosConfigurationDiscountProductVariants({ batch: payload })
			.then(res => {
				// actions bellow mimic rDW's procedure when editing multiple rows
				batch(() => {
					// reset table selection
					store.dispatch(actions.resetSelection({ reduxKey }));

					res.data.map(value => {
						store.dispatch(actions.editEntry({ reduxKey, entry: value }));
					});
				});

				handleClose();
				this.setState(() => ({
					loadingModal: false
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false
				}));
			});
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchListData,
			editBatchListItem,
			removeBatchListItem
		} = this.props;

		const steps = [
			{
				component: (
					<SalesConfigurationPosConfigurationDiscountProductVariantBatchDiscountAmountsEdit
						batchFormData={batchFormData}
						batchListData={batchListData}
						updateBatchForm={updateBatchForm}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_DISCOUNT_AMOUNT,
				isValid: true,
				onNext: () => this._onSubmitForm(),
				loading: this.state.loadingModal,
				confirmButtonLabel:
					phrases.MODAL_BATCH_STEP_FORM_DISCOUNT_AMOUNTS_CONFIRM_BUTTON_LABEL,
				noScroll: false
			},
			{
				component: (
					<SalesConfigurationPosConfigurationDiscountProductVariantBatchListEdit
						batchListData={batchListData}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false
			}
		];

		return modalVisible ? (
			<ModalStepper
				ref={this.modalRef}
				isOpen={modalVisible}
				steps={steps}
				showStep={false}
				onClose={handleClose}
				allowOverflow={this.state.allowModalOverflow}
			/>
		) : null;
	}
}

SalesConfigurationPosConfigurationDiscountProductVariantModalBatchEdit.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	addBatchList: PropTypes.func,
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	editPosConfigurationDiscountProductVariants: PropTypes.func,
	reduxKey: PropTypes.string
};

export default SalesConfigurationPosConfigurationDiscountProductVariantModalBatchEdit;
