import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchAllTileLayoutGroupTranslations
 * @description Fetch all allergen translations
 */
export function fetchAllTileLayoutGroupTranslations(id, state) {
	const preFilter = `:pos_configuration_tile_layout_group.id=='${id}'`;
	const filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get('/pos/pos_configuration_tile_layout_group_translations', params).catch((err) => {
		const errorMessage = formatErrorMessage(err);
		setFeedback(errorMessage, 0);
	});
}

/**
 * @function fetchAllTileLayoutGroups
 */
export function fetchAllTileLayoutGroups(id, state) {
	const preFilter = `:pos_configuration_tile_layout.id=='${id}'`;
	const filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get('/pos/pos_configuration_tile_layout_groups', params)
		.then((res) => res)
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addTileLayoutGroupTranslations
 * @description add
 */
export function addTileLayoutGroupTranslations(data) {
	return post(`/pos/pos_configuration_tile_layout_group_translations`, data)
		.then((res) => {
			setFeedback('Tile layout group translations added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editTileLayoutGroupTranslation
 */
export function editTileLayoutGroupTranslation(data) {
	return post(`/pos/pos_configuration_tile_layout_group_translations/${data.id}`, data)
		.then((res) => {
			setFeedback('Tile layout group translation edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteTileLayoutGroupTranslation
 */
export function deleteTileLayoutGroupTranslation(id) {
	return remove(`/pos/pos_configuration_tile_layout_group_translations/${id}`)
		.then((res) => {
			setFeedback('Tile layout group translation deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
