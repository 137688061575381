'use strict';

import cx from 'classnames';
import _every from 'lodash/every';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import './time.css';

class Time extends Component {
	constructor(props) {
		super(props);

		this.state = {
			focused: false,
			invalidTime: false,
			touchedHours: false,
			touchedMinutes: false,
		};

		this.timeFocusSelect = this.timeFocusSelect.bind(this);
	}

	onKeyDown(type, event) {
		switch (type) {
			case 'hours':
				if (
					event.nativeEvent.keyCode === 8 &&
					this.timeHours.value.length === 0
				) {
					this.setState({ touchedHours: false });
				}
				break;
			case 'minutes':
				if (
					event.nativeEvent.keyCode === 8 &&
					this.timeMinutes.value.length === 0
				) {
					this.setState({ touchedMinutes: false });
					this.timeFocusSelect('hours');
				}
				break;
		}
	}

	timeFocusSelect(type) {
		switch (type) {
			case 'hours':
				this.timeHours.focus();
				setTimeout(() => {
					this.timeHours.setSelectionRange(0, this.timeHours.value.length);
				}, 0);
				break;
			case 'minutes':
				this.timeMinutes.focus();
				setTimeout(() => {
					this.timeMinutes.setSelectionRange(0, this.timeMinutes.value.length);
				}, 0);
				break;
		}
	}

	onFocus(type) {
		switch (type) {
			case 'hours': {
				this.timeFocusSelect('hours');
				this.setState({ touchedHours: true });
				break;
			}
			case 'minutes': {
				if (this.timeHours.value.length === 0) this.timeFocusSelect('hours');
				else this.timeFocusSelect('minutes');
				this.setState({ touchedMinutes: true });
				break;
			}
		}
		this.setState({ focused: true });
	}

	onBlur() {
		this.setTime();
		this.setState({ focused: false });
	}

	onChange(type, event) {
		switch (type) {
			case 'hours':
				if (event.target.value.length === 2 && !this.state.touchedMinutes) {
					this.timeFocusSelect('minutes');
				}
				break;
		}
	}

	setTime() {
		const { onChange } = this.props;

		// Are time input lengths valid?
		const validTimeLength = [
			this.timeHours.value.length === 1 || this.timeHours.value.length === 2,
			this.timeMinutes.value.length === 1 ||
				this.timeMinutes.value.length === 2,
		];

		// Are time input values valid time input?
		const validTime = [
			moment(this.timeHours.value, 'HH').isValid(),
			moment(this.timeMinutes.value, 'ss').isValid(),
		];

		const touchedTime = [this.state.touchedHours, this.state.touchedMinutes];

		// Everything is valid
		if (_every([...validTimeLength, ...validTime, ...touchedTime])) {
			this.setState({ invalidTime: false });
			const time = moment(
				`${this.timeHours.value}-${this.timeMinutes.value}`,
				'HH-mm'
			);
			onChange(time);
		} else if (this.state.touchedHours && !this.state.touchedMinutes)
			this.timeMinutes.value = '00';
		// Lengths are valid, time input not
		else if (_every(validTimeLength) && !_every(validTime))
			this.setState({ invalidTime: true });
		// Default, just let the user type
		else this.setState({ invalidTime: false });
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { initialTime } = nextProps;
		if (initialTime && moment.isMoment(initialTime)) {
			this.timeHours.value = initialTime.format('HH');
			this.timeMinutes.value = initialTime.format('mm');
		}
	}

	render() {
		const { showMinutes, disabled, initialTime } = this.props;

		const { focused } = this.state;

		const timeCx = cx('time', {
			'time--focused': focused,
			'time--disabled': disabled,
		});

		return (
			<div className={timeCx}>
				<span className="time__input-wrapper">
					<input
						defaultValue={
							moment.isMoment(initialTime) ? initialTime.format('HH') : ''
						}
						ref={(input) => (this.timeHours = input)}
						maxLength="2"
						size="2"
						placeholder="00"
						disabled={disabled}
						onFocus={this.onFocus.bind(this, 'hours')}
						onBlur={this.onBlur.bind(this, 'hours')}
						onKeyDown={this.onKeyDown.bind(this, 'hours')}
						onChange={this.onChange.bind(this, 'hours')}
					/>
				</span>
				{showMinutes && (
					<span className="time__input-wrapper">
						<input
							defaultValue={
								moment.isMoment(initialTime) ? initialTime.format('mm') : ''
							}
							ref={(input) => (this.timeMinutes = input)}
							maxLength="2"
							size="2"
							placeholder="00"
							disabled={disabled}
							onFocus={this.onFocus.bind(this, 'minutes')}
							onBlur={this.onBlur.bind(this, 'minutes')}
							onKeyDown={this.onKeyDown.bind(this, 'minutes')}
							onChange={this.onChange.bind(this, 'minutes')}
						/>
					</span>
				)}
			</div>
		);
	}
}

Time.defaultProps = {
	initialTime: moment(),
	disabled: false,
};

Time.propTypes = {
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	showMinutes: PropTypes.bool,
	showSeconds: PropTypes.bool,
	initialTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Time;
