import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import { ButtonLoader } from 'dumb';

class ImageCell extends PureComponent {
	constructor(props) {
		super(props);

		this.noErrors = {
			tooManyFiles: false,
			wrongExtension: false
		};

		this.state = {
			dragOver: false,
			...this.noErrors
		};

		this.imageCellMethods = {
			handleSetFile: this.handleSetFile.bind(this),
			onDragEnter: this.onDragEnter.bind(this),
			onDragFileDrop: this.onDragFileDrop.bind(this),
			onDragFileEnd: this.onDragFileEnd.bind(this),
			onDragLeave: this.onDragLeave.bind(this),
			onDragOver: this.onDragOver.bind(this),
			onSelectFile: this.onSelectFile.bind(this)
		};
	}

	handleSetFile(file, fileName) {
		const { handleSetFile } = this.props;

		handleSetFile(file, fileName);
	}

	onSelectFile(event) {
		const { imageCell } = this.props;
		event.stopPropagation();
		event.preventDefault();
		event.nativeEvent.stopImmediatePropagation();

		// Extract file from event
		const file = event.target.files[0];

		// Validate file extension
		if (
			!imageCell.validateFileExtension(
				imageCell.extractFileExtension(file.name)
			)
		) {
			// Alert that extensions are wrong
			this.setState(
				() => ({ wrongExtension: true, tooManyFiles: false, dragOver: false }),
				this.imageCellMethods.handleSetFile(null, null)
			);
			return;
		}

		// Update
		this.imageCellMethods.handleSetFile(file, file.name);
	}

	onDragFileDrop(event) {
		const { imageCell } = this.props;

		// Reset dragOvere
		this.setState(() => ({ dragOver: false }));

		event.preventDefault();

		// Extract file(s) from event
		const files = event.nativeEvent.dataTransfer.files;

		// Only one file is allowed for drop
		if (files.length !== 1) {
			// Alert that only one file is allowed per file type
			this.setState(
				() => ({ tooManyFiles: true, wrongExtension: false, dragOver: false }),
				this.imageCellMethods.handleSetFile(null, null)
			);
			return;
		}

		// Get file
		const file = files[0];

		// Validate file extension
		if (
			!imageCell.validateFileExtension(
				imageCell.extractFileExtension(file.name)
			)
		) {
			// Alert that extensions are wrong
			this.setState(
				() => ({ wrongExtension: true, dragOver: false }),
				this.imageCellMethods.handleSetFile(null, null)
			);
			return;
		}

		// Update
		this.imageCellMethods.handleSetFile(file, file.name);
	}

	onDragFileEnd(event) {
		event.preventDefault();

		// Clean up
		event.nativeEvent.dataTransfer.clearData();

		// Reset dragOvere
		this.setState(() => ({ dragOver: false }));
	}

	onDragOver(event) {
		event.preventDefault();
	}

	onDragEnter(event) {
		event.preventDefault();
		this.setState(() => ({ dragOver: true }));
	}

	onDragLeave(event) {
		event.preventDefault();
		this.setState(() => ({ dragOver: false }));
	}

	render() {
		const { imageCell, image, phrases, loading, error } = this.props;

		const { dragOver, tooManyFiles, wrongExtension } = this.state;

		const style =
			image && image.url ? { backgroundImage: `url("${image.url}")` } : null;

		return (
			<div className="image-cell">
				<div
					className={cx('image-cell__image-drop-zone', {
						'image-cell__image-drop-zone--dropping': dragOver,
						'image-cell__image-drop-zone--image': image !== null,
						'image-cell__image-drop-zone--error':
							tooManyFiles || wrongExtension || error
					})}>
					{loading && (
						<div className="image-cell__loader">
							<ButtonLoader theme="dark" {...{ loading }} />
						</div>
					)}

					<input
						type="file"
						accept={imageCell.getExtensionsAsAccept()}
						id={`${imageCell.getIdentifier()}_imageCell`}
						onChange={this.imageCellMethods.onSelectFile}
					/>
					<label
						style={style}
						htmlFor={`${imageCell.getIdentifier()}_imageCell`}
						onDragEnter={this.imageCellMethods.onDragEnter}
						onDragOver={this.imageCellMethods.onDragOver}
						onDragLeave={this.imageCellMethods.onDragLeave}
						onDrop={this.imageCellMethods.onDragFileDrop}
						onDragEnd={this.imageCellMethods.onDragFileEnd}>
						{!image ||
							(image.url === null && (
								<span>
									{!tooManyFiles && !wrongExtension && !error
										? phrases.DROP_FILE
										: null}
									{tooManyFiles ? phrases.TOO_MANY_FILES : null}
									{wrongExtension ? `${phrases.WRONG_EXTENSION}` : null}
									{error ? `${phrases.ERROR}` : null}
								</span>
							))}
					</label>
				</div>
			</div>
		);
	}
}

ImageCell.defaultProps = {
	imageCell: {}
};

ImageCell.propTypes = {
	imageCell: PropTypes.object.isRequired,
	image: PropTypes.object,
	phrases: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	handleSetFile: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired
};

export default ImageCell;
