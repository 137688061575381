export const SET_VACATIONS = 'JUICER_DETAILS_VACATIONS_TABLE/SET_VACATIONS';
export const UPDATE_VACATION = 'JUICER_DETAILS_VACATIONS_TABLE/UPDATE_VACATION';
export const RESET_STATE = 'JUICER_DETAILS_VACATIONS_TABLE/RESET_STATE';

export const setVacationData = payload => {
	return { type: SET_VACATIONS, payload };
};

export const updateVacationData = payload => {
	return { type: UPDATE_VACATION, payload };
};

export const resetState = () => {
	return { type: RESET_STATE };
};
