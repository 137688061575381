'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Cell from 'reusableComponents/list/cell.component';
import AddRow from 'reusableComponents/list/addRow.component';
import Accordion from './accordion/accordion.component';

import _has from 'lodash/has';
import _every from 'lodash/every';
import _map from 'lodash/map';
import _each from 'lodash/each';
import _without from 'lodash/without';

class Rows extends Component {
	constructor(props) {
		super(props);

		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.selectRow = this.selectRow.bind(this);
	}

	/**
	 * @function handleKeyDown
	 * @memberOf DIRECTIVES.jtjList.component.rows
	 * @description Handles key down event on focused row
	 * @param {object} item The row object
	 * @param {object} event The click event
	 */
	handleKeyDown(item, event) {
		if (this.props.settings.canSelect && this.props.selectedRowId && event.nativeEvent.keyCode === 13) {
			const args = {
				name: 'jtjSearchList',
				selectedItem: item,
			};

			this.props.methods.handleConfirmSelectRow(args);
		}
	}

	/**
	 * @function selectRow
	 * @memberOf DIRECTIVES.jtjList.component.rows
	 * @description Edits row
	 * @param {object} item The row object
	 */
	selectRow(item) {
		if (this.props.settings.canSelect) {
			this.props.methods.handleSelectRow(item.id);
		}
	}

	/**
	 * @function _conditionalClass
	 * @memberOf DIRECTIVES.jtjList.component.rows
	 * @description Checks row for conditionalClassNames
	 * @param  {object} row  Object containing row data which is specific to each row
	 * @return {object}      ClassName and bool
	 */
	_conditionalClass(row) {
		if (_has(this.props.settings, 'conditionalClassNames')) {
			const conditionalClassNamesObj = {};
			_each(this.props.settings.conditionalClassNames, function (c) {
				conditionalClassNamesObj[c.className] = c.condition(row);
			});
			return conditionalClassNamesObj;
		} else return {};
	}

	/**
	 * @function _renderRowActions
	 * @memberOf DIRECTIVES.jtjList.component.rows
	 * @description Renders all actions for current row based on rowActions from state
	 * @param  {object} row  Object containing row data which is specific to each row
	 */
	_renderRowActions(row) {
		return _without(
			_map(
				this.props.rowActions(this.props, row),
				function (action, key) {
					if (_every(action.validExpressions)) {
						const iconClass = cx(
							'list__cell-contents list__cell-contents--link list__cell-contents--center icon',
							action.icon
						);
						return (
							<td onClick={action.method.bind(this)} className="list__cell list__cell__action-column" key={key}>
								<a data-text={this.props.translations[action.name.toUpperCase()]} className={iconClass} />
							</td>
						);
					}
				}.bind(this)
			),
			undefined
		);
	}

	/**
	 * @function _renderAddRow
	 * @memberOf DIRECTIVES.jtjList.component.rows
	 * @description Renders add row if user can add and adding has been initiated
	 */
	_renderAddRow() {
		if (this.props.isAdding) {
			return <AddRow actionColLength={this.props.methods.handleCountRowActions()} {...this.props} />;
		}
	}

	/**
	 * @function _renderEditRow
	 * @memberOf DIRECTIVES.jtjList.component.rows
	 * @description Renders edit row if user can edit and row id is right
	 */
	_renderEditRow(row, rowKey) {
		return (
			<AddRow key={rowKey} row={row} actionColLength={this.props.methods.handleCountRowActions()} {...this.props} />
		);
	}

	/**
	 * @function _renderCells
	 * @memberOf DIRECTIVES.jtjList.component.rows
	 * @description Renders cells in list
	 */
	_renderCells(row) {
		return _map(
			this.props.methods.handleReturnVisibleHeaders(),
			function (cell, cellKey) {
				return (
					<Cell
						key={_has(row, 'id') ? row.id + cellKey : cellKey}
						cell={cell}
						row={row}
						methods={this.props.methods}
						translations={this.props.translations}
						firstColumn={cellKey === 0}
						firstColumnOffset={this.props.firstColumnOffset}
					/>
				);
			}.bind(this)
		);
	}

	/**
	 * @function _renderAccordions
	 * @memberOf DIRECTIVES.jtjList.component.rows
	 * @description Renders the accordions
	 */
	_renderAccordions(row) {
		const accordionKeyId = this.props.settings.accordions[0].useAccordionKey
			? row[this.props.settings.accordions[0].key].id
			: row.id;
		return _map(
			this.props.accordions[accordionKeyId].accordions,
			function (accordion, accordionKey) {
				return (
					<Accordion
						{...this.props}
						key={accordionKey}
						contentColLength={this.props.methods.handleReturnVisibleHeaders().length}
						actionColLength={this.props.methods.handleCountRowActions()}
						accordion={accordion}
					/>
				);
			}.bind(this)
		);
	}

	/**
	 * @function _renderDefaultRow
	 * @memberOf DIRECTIVES.jtjList.component.rows
	 * @description Renders the default row in list
	 */
	_renderDefaultRow(row, rowKey, rowClassNames) {
		return (
			<tr onKeyDown={this.handleKeyDown.bind(this, row)} key={rowKey} className={rowClassNames} tabIndex={100}>
				{this._renderCells(row)}
				{this._renderRowActions(row)}
			</tr>
		);
	}

	/**
	 * @function _renderRows
	 * @memberOf DIRECTIVES.jtjList.component.rows
	 * @description Renders rows in list
	 */
	_renderRows() {
		return _map(
			this.props.collection,
			function (row, rowKey) {
				const isEditRow = this.props.editRowId === row.id;
				const isSelectedRow = this.props.selectedRowId === row.id;
				const accordionKeyId =
					this.props.settings.accordions.length > 0 && this.props.settings.accordions[0].useAccordionKey
						? row[this.props.settings.accordions[0].key].id
						: row.id;
				const isAccordionRow = _has(this.props.accordions, accordionKeyId);
				const rowClassNames = cx(
					'list__row',
					Object.assign(this._conditionalClass(row), {
						'list__row--can-select': this.props.settings.canSelect,
						'list__row--selected': isSelectedRow,
						'list__row--highlight': !isAccordionRow,
						'list__row--allow-empty': this.props.settings.allowEmpty,
						'list__row--accordion-chosen': isAccordionRow,
					})
				);

				// Render row with edit capabilities of row.id is editRow
				if (isEditRow) return this._renderEditRow(row, rowKey);
				// Render normal row + accordion row which is a large td with room for a table
				else if (isAccordionRow) {
					return [this._renderDefaultRow(row, rowKey, rowClassNames), this._renderAccordions(row, rowKey)];
				}

				// Otherwise just render normal row
				else return this._renderDefaultRow(row, rowKey, rowClassNames);
			}.bind(this)
		);
	}

	render() {
		return (
			<tbody>
				{this._renderAddRow()}
				{this._renderRows()}
			</tbody>
		);
	}
}

Rows.propTypes = {
	accordions: PropTypes.object,
	collection: PropTypes.array.isRequired,
	editRowId: PropTypes.number,
	headers: PropTypes.array.isRequired,
	isAdding: PropTypes.bool.isRequired,
	isEditing: PropTypes.bool.isRequired,
	methods: PropTypes.object.isRequired,
	selectRowId: PropTypes.number,
	settings: PropTypes.object.isRequired,
};

export default Rows;
