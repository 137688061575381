import {
	UPDATE_DOCUSIGN_TEMPLATE_DOCUMENT,
	SET_DOCUSIGN_TEMPLATE_DOCUMENT,
	RESET_STATE,
} from './docuSignTemplateDocuments.actions';

const defaultState = {
	data: {
		docusignTemplateDocument: {},
		defaultDocusignTemplateDocument: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_DOCUSIGN_TEMPLATE_DOCUMENT: {
			return {
				data: {
					...state.data,
					docusignTemplateDocument: payload,
					defaultDocusignTemplateDocument: payload,
				},
			};
		}

		case UPDATE_DOCUSIGN_TEMPLATE_DOCUMENT: {
			return {
				data: {
					...state.data,
					docusignTemplateDocument: {
						...state.data.docusignTemplateDocument,
						...payload,
					},
				},
			};
		}

		case RESET_STATE: {
			return {
				...defaultState,
			};
		}

		default:
			return state;
	}
}

export default reducer;
