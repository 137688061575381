'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setFilterProductVariantIngredientVariationDefaultReplacement,
	updateFilterProductVariantIngredientVariationDefaultReplacement,
	resetFilterProductVariantIngredientVariationDefaultReplacement,
} from './store/filterProductVariantIngredientVariationDefaultReplacements.actions';

// services
import {
	fetchFilterProductVariantIngredientVariationDefaultReplacements,
	editFilterProductVariantIngredientVariationDefaultReplacement,
	editFilterProductVariantIngredientVariationDefaultReplacements,
	addFilterProductVariantIngredientVariationDefaultReplacement,
	deleteFilterProductVariantIngredientVariationDefaultReplacement,
} from './filterProductVariantIngredientVariationDefaultReplacements.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { InputCollectionSelect, Input, Button, Icon } from 'dumb';

import FilterProductVariantIngredientVariationDefaultReplacementsModalBatch from './components/batch/filterProductVariantIngredientVariationDefaultReplacementsModalBatch';

// lodash
import _get from 'lodash/get';

// phrases
import phrases from './filterProductVariantIngredientVariationDefaultReplacements.phrases';

const reduxKey =
	'/product/filter_product_variant_ingredient_variation_default_replacements';

class FilterProductVariantIngredientVariationDefaultReplacements extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
		};

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.addFilterProductVariantWrapper = this.addFilterProductVariantWrapper.bind(
			this
		);
		this.fetchData = this.fetchData.bind(this);

		this.columns = [
			{
				Header: 'Product variant ingredient',
				id: 'productVariantIngredient',
				accessor: (d) =>
					_get(d, 'product_variant_ingredient.ingredient.name', ''),
				filterPath: ':product_variant_ingredient.ingredient.name',
			},
			{
				Header: 'Replacement ingredient variation option',
				id: 'replacementIngredientVariationOption',
				accessor: (d) =>
					_get(
						d,
						'replacement_ingredient_variation_option.ingredient.name',
						''
					),
				filterPath: ':replacement_ingredient_variation_option.ingredient.name',
			},
			{
				Header: 'Sort order',
				id: 'sortOrder',
				accessor: 'sort_order',
				filterPath: ':sort_order',
			},
		];
	}

	editEntry() {
		const {
			defaultFilterProductVariantIngredientVariationDefaultReplacement,
		} = this.props;

		const payload = {
			id: defaultFilterProductVariantIngredientVariationDefaultReplacement.id,
			product_variant_ingredient:
				defaultFilterProductVariantIngredientVariationDefaultReplacement
					.productVariantIngredient.value.id,
			replacement_ingredient_variation_option:
				defaultFilterProductVariantIngredientVariationDefaultReplacement
					.ingredientVariationOption.value.id,
			sort_order:
				defaultFilterProductVariantIngredientVariationDefaultReplacement.sortOrder,
		};

		return editFilterProductVariantIngredientVariationDefaultReplacement(
			payload
		);
	}

	editMultiple(selectedRows) {
		const {
			defaultFilterProductVariantIngredientVariationDefaultReplacement,
		} = this.props;

		const payload = {
			...(defaultFilterProductVariantIngredientVariationDefaultReplacement.sortOrder && {
				sort_order:
					defaultFilterProductVariantIngredientVariationDefaultReplacement.sortOrder,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editFilterProductVariantIngredientVariationDefaultReplacements({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteFilterProductVariantIngredientVariationDefaultReplacement(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			productVariantIngredient: {
				value: data.product_variant_ingredient,
				label: _get(data, 'product_variant_ingredient.ingredient.name', ''),
			},
			ingredientVariationOption: {
				value: data.replacement_ingredient_variation_option,
				label: _get(
					data,
					'replacement_ingredient_variation_option.ingredient.name',
					''
				),
			},
			sortOrder: data.sort_order,
			editSingle: true,
		};

		this.props.setFilterProductVariantIngredientVariationDefaultReplacement(
			payload
		);
	}

	getEditableCells(e) {
		const {
			defaultFilterProductVariantIngredientVariationDefaultReplacement,
			filterProductVariant,
		} = this.props;

		return [
			...(defaultFilterProductVariantIngredientVariationDefaultReplacement.editSingle
				? [
						{
							header: 'Product variant ingredient',
							value: (
								<InputCollectionSelect
									id="productVariantIngredient"
									value={
										defaultFilterProductVariantIngredientVariationDefaultReplacement.productVariantIngredient
									}
									handleChange={(key, value) =>
										this.editStoreEntry('productVariantIngredient', value)
									}
									cache
									apiPath="/product/product_variant_ingredients"
									params={{
										limit: 30,
										filter: `:product_variant.id=='${_get(
											filterProductVariant,
											'product_variant.id',
											null
										)}';:ingredient_variation!=null`,
									}}
									optionFormat={(entry) => ({
										value: entry,
										label: _get(entry, 'ingredient.name', ''),
									})}
									inputFilterFormat={(input) =>
										`:ingredient.name=like='%${input}%'`
									}
									clearable={false}
								/>
							),
						},
						{
							header: 'Ingredient variation option',
							value: (
								<InputCollectionSelect
									id="ingredientVariationOption"
									value={
										defaultFilterProductVariantIngredientVariationDefaultReplacement.ingredientVariationOption
									}
									handleChange={(key, value) =>
										this.editStoreEntry('ingredientVariationOption', value)
									}
									cache
									apiPath="/product/ingredient_variation_options"
									params={{
										limit: 30,
										filter: `:ingredient_variation.id=='${_get(
											defaultFilterProductVariantIngredientVariationDefaultReplacement,
											'productVariantIngredient.value.ingredient_variation.id',
											null
										)}'`,
									}}
									optionFormat={(entry) => ({
										value: entry,
										label: _get(entry, 'ingredient.name', ''),
									})}
									inputFilterFormat={(input) =>
										`:ingredient.name=like='%${input}%'`
									}
									clearable={false}
								/>
							),
						},
				  ]
				: []),
			{
				header: 'Sort order',
				value: (
					<Input
						id="sort_order"
						placeholder="Enter sort order..."
						value={
							defaultFilterProductVariantIngredientVariationDefaultReplacement.sortOrder
						}
						onChange={(e) => this.editStoreEntry('sortOrder', e.target.value)}
						type="number"
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const updateObject = {
			[name]: e,
		};

		this.props.updateFilterProductVariantIngredientVariationDefaultReplacement(
			updateObject
		);
	}

	fetchData(state) {
		const { filterProductVariant } = this.props;

		const queryFilter = `:filter_product_variant.id=='${filterProductVariant.id}'`;

		return fetchFilterProductVariantIngredientVariationDefaultReplacements(
			state,
			queryFilter
		);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetFilterProductVariantIngredientVariationDefaultReplacement();
	}

	addFilterProductVariantWrapper(data) {
		return addFilterProductVariantIngredientVariationDefaultReplacement(
			data
		).then(() => {
			this.setState(() => ({
				key: moment.utc(),
			}));
		});
	}

	render() {
		const {
			batchList,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
			filterProductVariant,
		} = this.props;

		return (
			<>
				<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={`${reduxKey}-${filterProductVariant.id}`}
					manual
					editableCells={this.getEditableCells()}
					editEntry={(e) => this.editEntry(e)}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={
						this.props
							.resetFilterProductVariantIngredientVariationDefaultReplacement
					}
					actionRender={
						<Button
							type="inverted"
							label="Batch"
							shadow
							onClick={() => this.toggleModal()}>
							<Icon name="add" />
						</Button>
					}
				/>

				<FilterProductVariantIngredientVariationDefaultReplacementsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addFilterProductVariantIngredientVariationDefaultReplacement={
						this.addFilterProductVariantWrapper
					}
					filterProductVariant={filterProductVariant}
				/>
			</>
		);
	}
}

FilterProductVariantIngredientVariationDefaultReplacements.propTypes = {
	defaultFilterProductVariantIngredientVariationDefaultReplacement:
		PropTypes.object,
	updateFilterProductVariantIngredientVariationDefaultReplacement:
		PropTypes.func,
	setFilterProductVariantIngredientVariationDefaultReplacement: PropTypes.func,

	resetFilterProductVariantIngredientVariationDefaultReplacement:
		PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	filterProductVariant: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			updateFilterProductVariantIngredientVariationDefaultReplacement,
			setFilterProductVariantIngredientVariationDefaultReplacement,
			resetFilterProductVariantIngredientVariationDefaultReplacement,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultFilterProductVariantIngredientVariationDefaultReplacement:
			store.filterProductVariantIngredientVariationDefaultReplacements.data
				.defaultFilterProductVariantIngredientVariationDefaultReplacement,
		batchList:
			store.filterProductVariantIngredientVariationDefaultReplacements.data
				.batchList,
	};
};

export default connectWithStore(
	FilterProductVariantIngredientVariationDefaultReplacements,
	mapStateToProps,
	mapDispatchToProps
);
