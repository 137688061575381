'use strict';

import { HotbarComponentAbstract } from 'hotdamnbar/factory';

/**
 * Number
 * @param  {string} title
 * @param  {bool} required
 * @param  {string} placeholder
 */
class Number extends HotbarComponentAbstract {
	constructor(title, required = false, placeholder = null) {
		super(title, required);

		// Set type
		this.type = 'number';

		// Placeholder
		this.placeholder = placeholder;
	}

	/**
	 * @function getPlaceholder
	 * @memberOf Number
	 * @description Gets placeholder
	 */
	getPlaceholder() {
		return this.placeholder;
	}

}

export default Number;