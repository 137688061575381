'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { Input, InputCollectionSelect } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';

import AddonIngredients from './components/addonIngredients.component';

import {
	fetchProductAddonRecommendation,
	addProductAddonRecommendation,
	deleteProductAddonRecommendation,
	editProductAddonRecommendation
} from './productAddonRecommendations.service';

import {
	setProductAddonRecommendation,
	updateProductAddonRecommendation,
	resetState
} from './store/productAddonRecommendations.actions';

// constants/phrases/tools
import phrases from './productAddonRecommendations.phrases';
import _get from 'lodash/get';

class ProductAddonRecommendations extends Component {
	constructor(props) {
		super(props);

		this.fetchData = this.fetchData.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: d => _get(d, 'name', ''),
				filterPath: ':name'
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: d => _get(d, 'description', ''),
				filterPath: ':description'
			},
			{
				Header: 'Addon name',
				id: 'addonName',
				accessor: d => _get(d, 'addon.name', ''),
				filterPath: ':addon.name'
			},
			{
				Header: 'type',
				id: 'type',
				accessor: d => _get(d, 'type', ''),
				filterPath: ':type'
			},
			{
				Header: 'Sort order',
				id: 'sortOrder',
				accessor: d => _get(d, 'sort_order', ''),
				filterPath: ':sort_order',
				width: 70
			}
		];
	}

	deleteEntry(id) {
		return deleteProductAddonRecommendation(id);
	}

	editEntry() {
		const { productAddonRecommendation } = this.props;

		const payload = {
			id: productAddonRecommendation.id,
			...(productAddonRecommendation.name && {
				name: productAddonRecommendation.name
			}),
			...(productAddonRecommendation.description && {
				description: productAddonRecommendation.description
			}),
			...(productAddonRecommendation.sortOrder && {
				sort_order: productAddonRecommendation.sortOrder
			})
		};

		return editProductAddonRecommendation(payload);
	}

	addEntry() {
		const { productAddonRecommendation } = this.props;

		const payload = {
			name: productAddonRecommendation.name,
			description: productAddonRecommendation.description,
			sort_order: productAddonRecommendation.sortOrder,
			...(_get(productAddonRecommendation, 'addon.value', false) && {
				addon: productAddonRecommendation.addon.value
			})
		};

		return addProductAddonRecommendation(payload);
	}

	setInitialEditValues(data) {
		const { setProductAddonRecommendation } = this.props;

		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			addon: {
				value: _get(data, 'addon.id', null),
				label: _get(data, 'addon.name', null)
			},
			sortOrder: data.sort_order,
			editMode: !!data.id
		};

		setProductAddonRecommendation(payload);
	}

	getEditableCells() {
		const { productAddonRecommendation } = this.props;

		return [
			{
				header: 'Addon name',
				value: (
					<Input
						id="addonName"
						placeholder="Addon name"
						value={productAddonRecommendation.name || null}
						onChange={event => this.editStoreEntry('name', event)}
					/>
				)
			},
			{
				header: 'Description',
				value: (
					<Input
						id="addonDescription"
						placeholder="Addon description"
						value={productAddonRecommendation.description || null}
						onChange={event => this.editStoreEntry('description', event)}
					/>
				)
			},
			...(productAddonRecommendation.editMode
				? []
				: [
					{
						header: 'Addon',
						value: (
							<InputCollectionSelect
								id="addon"
								placeholder="Select addon"
								value={productAddonRecommendation.addon || null}
								handleChange={(name, event) =>
									this.editStoreEntry(name, event)
								}
								clearable
								cache
								apiPath="/product/addons"
								params={{
									limit: 30
								}}
								optionFormat={entry => ({
									value: entry.id,
									label: entry.name
								})}
								inputFilterFormat={input => `:name=like='%${input}%'`}
							/>
						)
					}
				  ]),
			{
				header: 'Sort order',
				value: (
					<Input
						id="addonSortOrder"
						type="number"
						placeholder="Sort order"
						value={productAddonRecommendation.sortOrder || null}
						onChange={event => this.editStoreEntry('sortOrder', event)}
					/>
				)
			}
		];
	}

	editStoreEntry(name, event) {
		const { updateProductAddonRecommendation } = this.props;

		const value = event.target ? event.target.value : event;

		const payload = {
			[name]: value
		};

		updateProductAddonRecommendation(payload);
	}

	fetchData(state) {
		return fetchProductAddonRecommendation(state);
	}

	render() {
		return (
			<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.TABLE_TITLE}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={10}
				reduxKey="product/addon_recommendations"
				manual
				editableCells={this.getEditableCells()}
				actionsWidth={60}
				createEntry={this.addEntry}
				editEntry={this.editEntry}
				deleteEntry={this.deleteEntry}
				editMultiple={this.editMultiple}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetState}
				
				subcomponent={row => {
					return (
						<SubTable>
							<AddonIngredients
								title={phrases.ADDON_INGREDIENTS}
								productAddonRecommendationId={row.original.id}
								addonId={row.original.addon.id}
							/>
						</SubTable>
					);
				}}
			/>
		);
	}
}

ProductAddonRecommendations.propTypes = {
	setProductAddonRecommendation: PropTypes.func,
	resetState: PropTypes.func,
	updateProductAddonRecommendation: PropTypes.func,
	productAddonRecommendation: PropTypes.object
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			setProductAddonRecommendation,
			updateProductAddonRecommendation,
			resetState
		},
		dispatch
	);
};

const mapStateToProps = store => {
	return {
		productAddonRecommendation:
			store.salesConfigurationProductAddonRecommendations.data.productAddonRecommendation
	};
};

export default connectWithStore(
	ProductAddonRecommendations,
	mapStateToProps,
	mapDispatchToProps
);
