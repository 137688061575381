'use strict';

import React, { Component } from 'react';

import MarketsViewBody from './marketsView.body.component';

class MarketsView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: {
				market: null,
				hourly: null,
				product: null
			}
		};

		this.startHover = this.startHover.bind(this);
		this.stopHover = this.stopHover.bind(this);
		this.expandMarket = this.expandMarket.bind(this);
		this.expandHourly = this.expandHourly.bind(this);
		this.expandProduct = this.expandProduct.bind(this);
		this.generateColumns = this.generateColumns.bind(this);

		this.columns = this.generateColumns();
	}

	startHover(group) {
		this.props.dynamicPosView.handleStartHover(this.props.tableTitle, group);
	}

	stopHover() {
		this.props.dynamicPosView.handleStopHover(this.props.tableTitle);
	}

	expandMarket(row) {
		// Initiate loading
		this.setState(prevState => ({
			loading: {
				...prevState.loading,
				market: row.id
			}
		}));

		// Toggle market table
		this.props.dynamicPosView.handleToggleTable('market', row.id);

		// Get content for market table
		this.props.dynamicPosView
			.handleGetMarketWorkplaceTable(row.data.market.id)
			.then(() => {
				// Stop loading
				this.setState(prevState => ({
					loading: {
						...prevState.loading,
						market: null
					}
				}));
			});
	}

	expandHourly(row) {
		// Initiate loading
		this.setState(prevState => ({
			loading: {
				...prevState.loading,
				hourly: row.id
			}
		}));

		// Toggle hourly table
		this.props.dynamicPosView.handleToggleTable('hourly', row.id);

		// Get content for hourly table
		this.props.dynamicPosView
			.handleGetMarketHourlyTable(row.data.market.id)
			.then(() => {
				// Stop loading
				this.setState(prevState => ({
					loading: {
						...prevState.loading,
						hourly: null
					}
				}));
			});
	}

	expandProduct(product, row) {
		// Initiate loading
		this.setState(prevState => ({
			loading: {
				...prevState.loading,
				product: row.id
			}
		}));

		// Toggle market table
		this.props.dynamicPosView.handleToggleProductTable(product, row.id);

		// Get content for market table
		this.props.dynamicPosView
			.handleGetMarketProductTable(row.data.market.id)
			.then(() => {
				// Stop loading
				this.setState(prevState => ({
					loading: {
						...prevState.loading,
						product: null
					}
				}));
			});
	}

	generateColumns() {
		// Add actions to all columns
		const content = this.props.components['MarketsView'].content;

		return {
			stores: {
				group: 'stores',
				action: row => this.expandMarket(row),
				cells: {
					stores: content[0]
				}
			},
			total: {
				group: 'average',
				action: row => this.expandHourly(row),
				cells: {
					turnover: content[1],
					products: content[2],
					employees: content[3],
					phpMax: content[4]
				}
			},
			// average: {
			// 	group: 'average',
			// 	action: row => this.expandHourly(row),
			// 	cells: {
			// 		turnover: content[5],
			// 		products: content[6],
			// 		employees: content[7],
			// 		phpMax: content[8]
			// 	}
			// },
			juice: {
				group: 'juice',
				action: row => this.expandProduct('juice', row),
				cells: {
					turnover: content[5],
					products: content[6],
					percentage: content[7]
				}
			},
			shake: {
				group: 'shake',
				action: row => this.expandProduct('shake', row),
				cells: {
					turnover: content[8],
					products: content[9],
					percentage: content[10]
				}
			},
			sandwich: {
				group: 'sandwich',
				action: row => this.expandProduct('sandwich', row),
				cells: {
					turnover: content[11],
					products: content[12],
					percentage: content[13]
				}
			},
			coffee: {
				group: 'coffee',
				action: row => this.expandProduct('coffee', row),
				cells: {
					turnover: content[14],
					products: content[15],
					percentage: content[16]
				}
			},
			loyaltycard: {
				group: 'loyaltycard',
				action: row => this.expandProduct('loyaltycard', row),
				cells: {
					turnover: content[17],
					products: content[18],
					percentage: content[19]
				}
			},
			breakfast: {
				group: 'breakfast',
				action: row => this.expandProduct('breakfast', row),
				cells: {
					turnover: content[20],
					products: content[21],
					percentage: content[22]
				}
			},
			counterproduct: {
				group: 'counterproduct',
				action: row => this.expandProduct('counterproduct', row),
				cells: {
					turnover: content[23],
					products: content[24],
					percentage: content[25]
				}
			},
			salad: {
				group: 'salad',
				action: row => this.expandProduct('salad', row),
				cells: {
					turnover: content[26],
					products: content[27],
					percentage: content[28]
				}
			},
			topping: {
				group: 'topping',
				action: row => this.expandProduct('topping', row),
				cells: {
					turnover: content[29],
					products: content[30],
					percentage: content[31]
				}
			},
			misc: {
				group: 'misc',
				action: row => this.expandProduct('misc', row),
				cells: {
					turnover: content[32],
					products: content[33],
					percentage: content[34]
				}
			},
			employeeMeals: {
				group: 'employeeMeals',
				action: row => this.expandMarket(row),
				cells: {
					total: content[35],
					percentage: content[36]
				}
			},
			employeeDiscounts: {
				group: 'employee',
				cells: {
					total: content[37],
					percentage: content[38]
				}
			},
			employeeMealDiscounts: {
				group: 'employeeMealsDiscounts',
				cells: {
					total: content[39],
					percentage: content[40]
				}
			},
			loyaltyDiscounts: {
				group: 'loyaltyDiscounts',
				cells: {
					total: content[41],
					percentage: content[42]
				}
			},
			neighbourDiscounts: {
				group: 'neighbourDiscounts',
				cells: {
					total: content[43],
					percentage: content[44]
				}
			}
		};
	}

	render() {
		const { loading } = this.state;
		const { columns, startHover, stopHover } = this;

		return (
			<table className="dynamic-pos-view__markets-view">
				<MarketsViewBody
					{...{
						...this.props,
						columns,
						startHover,
						stopHover,
						loading
					}}
				/>
			</table>
		);
	}
}

export default MarketsView;
