'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper, ButtonTile } from 'dumb';
import { ModalRow } from 'dumb/modal';

// different batch views
import ProductVariantIngredientAlternativesBatchStepForm from './components/productVariantIngredientAlternativesBatchStepForm';
import ProductVariantIngredientAlternativesBatchStepList from './components/productVariantIngredientAlternativesBatchStepList';

// phrases/ enums/ moment
import phrases from './../../productVariantIngredientAlternatives.phrases';
import constants from 'services/constants';
import moment from 'moment';
import enums from './../../productVariantIngredientAlternatives.enums';

// loash
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';

// styles
import './productVariantIngredientAlternativesModalBatch.css';

class ProductVariantIngredientAlternativesModalBatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};

		this.today = moment.utc().format(constants.shortDate);

		this._batchSubmit = this._batchSubmit.bind(this);
		this._goToStep = this._goToStep.bind(this);
		this._onSubmitForm = this._onSubmitForm.bind(this);
		this._setChoice = this._setChoice.bind(this);

		this.modalRef = React.createRef();
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_onSubmitForm() {
		const { batchFormData, addBatchList } = this.props;

		const listArray = batchFormData.productVariantIngredient.reduce(
			(acc, currentValue) => {
				const payload = {
					productVariantIngredient: currentValue,
					type: batchFormData.type,
				};

				// initial state of values array - in case either without the ingredient type selected
				// or productVariantGroup/ingredient arrays are empty
				let values = [
					{
						...payload,
						identifier: _uniqueId('row-'),
					},
				];

				if (batchFormData.type === enums.ALTERNATIVE_PRODUCTS && !_isEmpty(batchFormData.productVariantGroup)) {
					values = batchFormData.productVariantGroup.map((entry) => ({
						...payload,
						identifier: _uniqueId('row-'),
						productVariantGroup: entry,
					}));
				}
				if (batchFormData.type === enums.ALTERNATIVE_INGREDIENT && !_isEmpty(batchFormData.ingredient)) {
					values = batchFormData.ingredient.map((entry) => ({
						...payload,
						identifier: _uniqueId('row-'),
						ingredient: entry,
					}));
				}

				return [...acc, ...values];
			},
			[]
		);

		addBatchList(listArray);

		this._goToStep(3);
	}

	_batchSubmit() {
		const { batchListData, handleClose, batchAddData } = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchListData.map((entry) => {
			return {
				type: entry.type,
				product_variant_ingredient: entry.productVariantIngredient?.value?.id,
				sort_order: entry.sortOrder,
				...(entry.type === enums.ALTERNATIVE_PRODUCTS &&
					entry.productVariantGroup && {
						product_variant_group: entry.productVariantGroup.value?.id,
					}),
				...(entry.type === enums.ALTERNATIVE_INGREDIENT &&
					entry.ingredient && {
						ingredient: entry.ingredient.value?.id,
					}),
			};
		});

		batchAddData({ batch: payload })
			.then(() => {
				this.setState(() => ({
					loadingModal: false,
				}));

				handleClose();
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	_setChoice(type) {
		const { updateBatchForm } = this.props;

		updateBatchForm({ type });

		this._goToStep(2);
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchListData,
			editBatchListItem,
			removeBatchListItem,
		} = this.props;

		const steps = [
			{
				component: (
					<ModalRow className="product-variant-ingredient-alternatives__modal__choice-wrapper">
						<ButtonTile
							icon="add"
							type="inverted"
							shadow
							onClick={() => this._setChoice(enums.ALTERNATIVE_INGREDIENT)}>
							{phrases.MODAL_BATCH_CHOICE_INGREDIENT}
						</ButtonTile>
						<ButtonTile
							icon="add"
							type="inverted"
							shadow
							onClick={() => this._setChoice(enums.ALTERNATIVE_PRODUCTS)}>
							{phrases.MODAL_BATCH_CHOICE_PRODUCT_VARIANT_GROUP}
						</ButtonTile>
						<ButtonTile
							icon="add"
							type="inverted"
							shadow
							onClick={() => this._setChoice(enums.WITHOUT_THE_INGREDIENT)}>
							{phrases.MODAL_BATCH_CHOICE_NONE}
						</ButtonTile>
					</ModalRow>
				),
				title: phrases.MODAL_BATCH_CHOICE_TITLE,
				isValid: true,
				noScroll: false,
				allowOverflow: true,
				customFooter: <></>,
			},
			{
				component: (
					<ProductVariantIngredientAlternativesBatchStepForm
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE,
				isValid: !_isEmpty(batchFormData.productVariantIngredient),
				onNext: this._onSubmitForm,
				loading: false,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				noScroll: false,
				allowOverflow: true,
				onBack: () => this._goToStep(1),
			},
			{
				component: (
					<ProductVariantIngredientAlternativesBatchStepList
						batchListData={batchListData}
						batchFormData={batchFormData}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: this._batchSubmit,
				onBack: () => this._goToStep(2),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return modalVisible ? (
			<ModalStepper
				ref={this.modalRef}
				isOpen={modalVisible}
				steps={steps}
				showStep={false}
				onClose={handleClose}
			/>
		) : null;
	}
}

ProductVariantIngredientAlternativesModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	addBatchList: PropTypes.func,
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	batchAddData: PropTypes.func,
	productVariantIngredient: PropTypes.object,
};

export default ProductVariantIngredientAlternativesModalBatch;
