'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import SearchListService from './searchList.service';

import QuickFilter from './components/quickFilter.component';
import HeaderRow from './components/headerRow.component';
import Rows from './components/rows.component';
import Pagination from 'reusableComponents/list/pagination.component';
import RollUp from 'rollUp/rollUp.component';
import ColumnSelect from 'reusableComponents/list/controls/columnSelect.component';
import Controls from 'reusableComponents/list/controls/controls.component';

import './searchList.css';

class SearchList extends Component {
	constructor(props) {
		super(props);
		this.SearchListService = new SearchListService();
		this.searchListProps = this.SearchListService.GetProps(
			props.searchList,
			props.searchListFilter,
			props.searchListData
		);
		this.translations = this.SearchListService.GetTranslations(
			props.searchList
		);
	}

	render() {
		const { handleSelect } = this.props;

		const searchListCx = cx('search-list', 'list', {
			'list--tiny': this.searchListProps.settings.tinyText
		});

		return (
			<div>
				<Controls {...this.searchListProps} />
				<h2>{this.translations.title}</h2>
				<QuickFilter
					{...this.searchListProps}
					translations={this.translations}
				/>
				<ColumnSelect {...this.searchListProps} />
				<table className={searchListCx}>
					<HeaderRow {...this.searchListProps} />
					<Rows handleSelect={handleSelect} {...this.searchListProps} />
					<Pagination {...this.searchListProps} />
				</table>
			</div>
		);
	}
}

SearchList.propTypes = {
	handleSelect: PropTypes.func.isRequired,
	searchList: PropTypes.object.isRequired,
	searchListFilter: PropTypes.string
};

export default RollUp(SearchList);
