export default {
	TITLE: 'Shiftplan seniorities',
	SHIFTPLAN_SENIORITY_ADDED: 'Shiftplan seniorities added!',
	SHIFTPLAN_SENIORITY_EDITED: 'Shiftplan seniority edited!',
	SHIFTPLAN_SENIORITIES_EDITED: 'Shiftplan seniorities edited!',
	SHIFTPLAN_SENIORITY_DELETED: 'Shiftplan seniority deleted!',

	// batch form
	MODAL_BATCH_STEP_LIST_TITLE: 'Create shiftplan seniorities',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_BADGE: 'Badge',
	MODAL_BATCH_STEP_MINIMUM_HOURS: 'Minimum hours',
	MODAL_BATCH_STEP_MINIMUM_HOURS_PLACEHOLDER: 'Enter minimum hours...',
	MODAL_BATCH_STEP_MAXIMUM_HOURS: 'Maximum hours',
	MODAL_BATCH_STEP_MAXIMUM_HOURS_PLACEHOLDER: 'Enter maximum hours...',
	MODAL_BATCH_STEP_BADGE_PLACEHOLDER: 'Enter badge...',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add shiftplan seniorities',
};
