export const RESET_STATE = 'DOCUSIGN_TEMPLATE_DOCUMENTS/RESET_STATE';
export const SET_DOCUSIGN_TEMPLATE_DOCUMENT =
	'DOCUSIGN_TEMPLATE_DOCUMENTS/SET_DOCUSIGN_TEMPLATE_DOCUMENT';
export const UPDATE_DOCUSIGN_TEMPLATE_DOCUMENT =
	'DOCUSIGN_TEMPLATE_DOCUMENTS/UPDATE_DOCUSIGN_TEMPLATE_DOCUMENT';

export function setDocusignTemplateDocument(payload) {
	return {
		type: SET_DOCUSIGN_TEMPLATE_DOCUMENT,
		payload,
	};
}

export function updateDocusignTemplateDocument(payload) {
	return {
		type: UPDATE_DOCUSIGN_TEMPLATE_DOCUMENT,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}
