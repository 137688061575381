import { store } from 'appState';
import { set as setFeedback } from 'feedback.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { get, post } from 'api.vanilla.service';

export const MODAL_TOGGLED = 'POS_SESSION_CREATOR/MODAL_TOGGLED';
export const DATE_CHANGED = 'POS_SESSION_CREATOR/DATE_CHANGED';
export const CLOSED_DATE_CHANGED = 'POS_SESSION_CREATOR/CLOSED_DATE_CHANGED';
export const OPENED_DATE_CHANGED = 'POS_SESSION_CREATOR/OPENED_DATE_CHANGED';
export const WORKPLACE_SELECTED = 'POS_SESSION_CREATOR/WORKPLACE_SELECTED';
export const WORKPLACES_FETCHED = 'POS_SESSION/WORKPLACES_FETCHED';
export const REGISTER_VALUE_CHANGED = 'POS_SESSION/REGISTER_VALUE_CHANGED';
export const DESCRIPTION_VALUE_CHANGED =
	'POS_SESSION/DESCRIPTION_VALUE_CHANGED';
export const CLEAR_STATE = 'POS_SESSION/CLEAR_STATE';

// exported actions

export const toggleModal = isToggled => ({
	type: MODAL_TOGGLED,
	payload: isToggled
});
export const dateChange = selectedDate => ({
	type: DATE_CHANGED,
	payload: selectedDate
});
export const openedDateChange = (time, type) => ({
	type: OPENED_DATE_CHANGED,
	payload: { time, type }
});
export const closedDateChange = (time, type) => ({
	type: CLOSED_DATE_CHANGED,
	payload: { time, type }
});
export const selectWorkplace = workplace => ({
	type: WORKPLACE_SELECTED,
	payload: workplace
});
export const updateRegister = registerValue => ({
	type: REGISTER_VALUE_CHANGED,
	payload: registerValue
});
export const updateDescription = description => ({
	type: DESCRIPTION_VALUE_CHANGED,
	payload: description
});
export const clearState = () => ({
	type: CLEAR_STATE
});

// Local actions

const workplacesFetched = workplaces => ({
	type: WORKPLACES_FETCHED,
	payload: workplaces
});

// API calls
export const createPosSession = data => {
	return post('/sale/pos_session_audits', data)
		.then(() => {
			setFeedback('POS session successfully created', 1);
			store.dispatch(toggleModal(false));
		})
		.catch(err => {
			setFeedback(formatErrorMessage(err), 0);
		});
};

export const fetchWorkplaces = () => {
	return get('/administration/workplaces', { limit: 150 })
		.then(response => {
			store.dispatch(workplacesFetched(response.data));
		})
		.catch(err => {
			setFeedback(err.message, 0);
		});
};

export function fetchWorkplacesByName(workplaceName) {
	const filter = `:name=like='%${workplaceName}%'`;

	return get('/administration/workplaces', { filter, limit: 25 })
		.then(response => {
			store.dispatch(workplacesFetched(response.data));
		})
		.catch(err => {
			setFeedback(err.message, 0);
		});
}
