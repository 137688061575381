export default {
	TITLE: 'System softwares',
	WORKPLACE_SYSTEM_SOFTWARES_ADDED: 'System softwares added!',
	WORKPLACE_SYSTEM_SOFTWARE_EDITED: 'System software edited!',
	WORKPLACE_SYSTEM_SOFTWARES_EDITED: 'System softwares edited!',
	WORKPLACE_SYSTEM_SOFTWARES_DELETED: 'System softwares deleted!',

	// batch form
	MODAL_BATCH_STEP_LIST_TITLE: 'Add workplace system softwares',
	MODAL_BATCH_STEP_FORM_TITLE_SYSTEM_SOFTWARES: 'Select system softwares',
	MODAL_BATCH_STEP_FORM_TITLE_WORKPLACES: 'Select workplaces',
	MODAL_BATCH_STEP_SYSTEM_SOFTWARES: 'System software',
	MODAL_BATCH_STEP_SYSTEM_SOFTWARES_PLACEHOLDER: 'Select system software...',
	MODAL_BATCH_STEP_WORKPLACES: 'Workplace',
	MODAL_BATCH_STEP_WORKPLACES_PLACEHOLDER: 'Select workplaces...',
	MODAL_BATCH_STEP_SYSTEM_SOFTWARE_VERSIONS: 'System software versions',
	MODAL_BATCH_STEP_SYSTEM_SOFTWARE_VERSION: 'System software version',
	MODAL_BATCH_STEP_CONFIRM: 'Confirm',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'Back',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_TITLE: 'Title',
	MODAL_BATCH_STEP_TITLE_PLACEHOLDER: 'Enter title...',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add entries',
	MODAL_BATCH_STEP_SYSTEM_SOFTWARES_TABLE_TITLE: 'System softwares',
	MODAL_BATCH_STEP_WORKPLACES_TABLE_TITLE: 'Workplaces',
	MODAL_BATCH_STEP_SYSTEM_SOFTWARE_VERSIONS_TABLE_TITLE:
		'System software versions',
	MODAL_BATCH_STEP_NEXT: 'Next',
	MODAL_BATCH_STEP_LIST_LEGEND_CANT_ADD: 'Value already exists',
	MODAL_BATCH_STEP_SYSTEM_SOFTWARES_GLOBAL: 'Global system software verions',
	MODAL_BATCH_STEP_SYSTEM_SOFTWARES_GLOBAL_PLACEHOLDER:
		'Select system software versions...',
};
