import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './marketShiftDeviationReasons.phrases';

/**
 * @function fetchMarketShiftDeviationReasons
 * @description Fetch all market shift deviation reasons
 * @param {object} state
 */
export function fetchMarketShiftDeviationReasons(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get(
		'/shiftplanning/market_shift_deviation_reasons',
		params,
		null,
		state.headers
	)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addMarketShiftDeviationReason
 * @description add market shift deviation reasons
 */
export function addMarketShiftDeviationReason(data) {
	return post('/shiftplanning/market_shift_deviation_reasons', data)
		.then((res) => {
			setFeedback(phrases.MARKET_OVERTIME_RULE_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editMarketShiftDeviationReason
 * @description edit market shift deviation reasons
 */
export function editMarketShiftDeviationReason(data) {
	return post(`/shiftplanning/market_shift_deviation_reasons/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.MARKET_OVERTIME_RULE_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editMarketShiftDeviationReasons
 * @description edit market shift deviation reasons
 */
export function editMarketShiftDeviationReasons(data) {
	return patch('/shiftplanning/market_shift_deviation_reasons', data)
		.then((res) => {
			setFeedback(phrases.MARKET_OVERTIME_RULES_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteMarketShiftDeviationReason
 * @description delete market shift deviation reasons
 */
export function deleteMarketShiftDeviationReason(id) {
	return remove(`/shiftplanning/market_shift_deviation_reasons/${id}`)
		.then((res) => {
			setFeedback(phrases.MARKET_OVERTIME_RULE_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
