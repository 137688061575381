'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Input, Toggle } from 'dumb';
import Checkbox from 'inputs/checkbox';

import {
	setAddonRecommendations,
	updateAddonRecommendations,
	resetAddonRecommendations
} from './store/addonRecommendations.actions';

import {
	fetchAddonRecommendations,
	deleteAddonRecommendations,
	editAddonRecommendations,
	addAddonRecommendations
} from './addonRecommendations.service';

// tools
import _get from 'lodash/get';

// phrases
import phrases from './addonRecommendations.phrases';

class AddonRecommendations extends Component {
	constructor(props) {
		super(props);

		this._editStoreEntry = this._editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.fetchData = this.fetchData.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: d => d.name,
				filterPath: ':name'
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: d => d.description,
				filterPath: ':description'
			},
			{
				Header: 'Addon name',
				id: 'addonName',
				accessor: d => _get(d, 'addon.name', ''),
				filterPath: ':addon.name'
			},
			{
				Header: 'Type',
				id: 'type',
				accessor: d => d.type,
				filterPath: ':type'
			},
			{
				Header: 'Sort order',
				id: 'sort_order',
				accessor: d => d.sort_order,
				filterPath: ':sort_order'
			},
			{
				Header: 'Choice required',
				id: 'choice_required',
				accessor: d => d.choice_required,
				filterPath: ':choice_required',
				filterable: false,
				Cell: d => (
					<Checkbox checked={d.original.choice_required} disabled noBorder />
				)
			}
		];
	}

	addEntry() {
		const { addonRecommendation, addon } = this.props;

		const payload = {
			addon: addon.id,
			name: addonRecommendation.name,
			description: addonRecommendation.description,
			sort_order: addonRecommendation.sortOrder,
			choice_required: !!addonRecommendation.choiceRequired
		};

		return addAddonRecommendations(payload);
	}

	editEntry() {
		const { addonRecommendation } = this.props;

		const payload = {
			name: addonRecommendation.name,
			description: addonRecommendation.description,
			sort_order: addonRecommendation.sortOrder,
			choice_required: !!addonRecommendation.choiceRequired
		};

		return editAddonRecommendations(addonRecommendation.id, payload);
	}

	deleteEntry(id) {
		return deleteAddonRecommendations(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			sortOrder: data.sort_order,
			choiceRequired: data.choice_required
		};

		this.props.setAddonRecommendations(payload);
	}

	getEditableCells() {
		const { addonRecommendation } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="name"
						type="text"
						value={addonRecommendation.name}
						onChange={event => this._editStoreEntry('name', event)}
					/>
				)
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="description"
						type="text"
						value={addonRecommendation.description}
						onChange={event => this._editStoreEntry('description', event)}
					/>
				)
			},
			{
				header: 'Sort Order',
				value: (
					<Input
						id="sort_order"
						placeholder="sort order"
						type="number"
						min="0"
						value={addonRecommendation.sortOrder}
						onChange={event => this._editStoreEntry('sortOrder', event)}
					/>
				)
			},
			{
				header: 'Choice required',
				value: (
					<Toggle
						id="choiceRequired"
						toggled={addonRecommendation.choiceRequired}
						onClick={event => this._editStoreEntry('choiceRequired', event)}
					/>
				)
			}
		];
	}

	_editStoreEntry(name, e) {
		const value = e.target ? e.target.value : e;

		this.props.updateAddonRecommendations({
			[name]: value
		});
	}

	fetchData(state) {
		const { addon } = this.props;
		return fetchAddonRecommendations(addon.id, state);
	}

	render() {
		return (
			<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.TITLE}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={10}
				reduxKey={`/product/addon_recommendations/${this.props.addon.id}`}
				manual
				editableCells={this.getEditableCells()}
				editableCellsEdit={this.getEditableCells()}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetAddonRecommendations}
				actionsWidth={30}
				deleteEntry={this.deleteEntry}
				editEntry={this.editEntry}
				createEntry={this.addEntry}
				inRowButtons
			/>
		);
	}
}

AddonRecommendations.propTypes = {
	addon: PropTypes.object,
	addonRecommendation: PropTypes.object,
	updateAddonRecommendations: PropTypes.func,
	setAddonRecommendations: PropTypes.func,
	resetAddonRecommendations: PropTypes.func
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			setAddonRecommendations,
			updateAddonRecommendations,
			resetAddonRecommendations
		},
		dispatch
	);
};

const mapStateToProps = store => {
	return {
		addonRecommendation:
			store.productAddonRecommendations.data.addonRecommendation
	};
};

export default connectWithStore(
	AddonRecommendations,
	mapStateToProps,
	mapDispatchToProps
);
