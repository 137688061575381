export default {
	TABLE_TITLE: 'Data sets',
	POWER_BI_REPORT_DATA_SET_ADDED: 'Dataset added',
	POWER_BI_REPORT_DATA_SET_EDITED: 'Dataset edited',
	POWER_BI_REPORT_DATA_SET_DELETED: 'Dataset deleted',

	// batch
	MODAL_TITLE: 'Add datasets',

	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_LIST_DATASET: 'Dataset id',
	ADD_ROW_TOOLTIP: 'Click to add row',
	BATCH_ADD: 'Add multiple',

	SUBMIT: 'Confirm',
};
