'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';
const __endpoints = require('endpoints.service');
const __apiFilter = require('apiFilter.service');

export default class WorkplaceIndexAdjustmentsList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'tag',
				type: 'select',
				canAdd: true,
				canEdit: true,
				required: true,
				reference: {
					key: 'name',
					list: '/tagging/tags',
					searchListFilter: __apiFilter.create([
						{
							fields: [
								{
									field: 'type',
									operator: '==',
									data: 'Store',
								},
							],
						},
					]),
				},
			},
			{
				name: 'period',
				type: 'date',
				canAdd: true,
				canEdit: true,
				required: true,
				reference: {
					key: 'from',
					translationKey: 'FROM',
				},
			},
			{
				name: 'period',
				type: 'date',
				canAdd: true,
				canEdit: true,
				required: true,
				reference: {
					key: 'to',
					translationKey: 'TO',
				},
			},
		];
		this.settings = {
			name: 'WorkplaceWorkplaceStoreTagsList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			canCollapse: true,
			alwaysRefresh: true,
			canManipulate: () => validAccess(undefined, ['Organisation/Workplaces::Edit', 'Organization Admin']),
			translationPath: 'FINANCIAL.INDEX_ADJUSTMENTS',
			endpoint: __endpoints.collection.financial.store_tags,
		};
		this.init();
		return this;
	}
}
