'use strict';

import cx from 'classnames';
import _debounce from 'lodash/debounce';
import _has from 'lodash/has';
import _isNumber from 'lodash/isNumber';
import _floor from 'lodash/floor';
import _isNaN from 'lodash/isNaN';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './pagination.css';

class Pagination extends Component {
	constructor(props) {
		super(props);

		this.navigate = this.navigate.bind(this);
	}

	/**
	 * @function navigate
	 * @memberOf DIRECTIVES.jtjList.component.pagination
	 * @description Navigate to new place (+,-,)
	 * @param {object} column The column to sort by
	 * @param {integer} key Key of the column to sort by in order to track order
	 */
	navigate(indicator) {
		this.props.methods.handlePaginationNavigate(indicator);
	}

	/**
	 * @function _handlePaginationInputChange
	 * @memberOf DIRECTIVES.jtjList.component.pagination
	 * @description Navigate to new page
	 * @param {object} event
	 */
	_handlePaginationInputChange(event) {
		var pageNumber;
		var newPage = parseInt(event.target.value, 10);
		var allPages =
			_floor((this.props.collectionLength - 1) / this.props.params.limit) + 1;

		// navigate to new page if valid page
		if (
			_isNumber(newPage) &&
			!_isNaN(newPage) &&
			newPage <= allPages &&
			newPage > 0
		)
			this.navigate(newPage - 1);
		// if not valid page, just ignore
		else {
			pageNumber = _floor(this.props.params.offset / this.props.params.limit);
			event.target.value = pageNumber + 1;
		}

		// deselect, focus, select again
		event.target.blur();
		event.target.focus();
		event.target.select();
	}

	/**
	 * @function _handlePaginationInputChangeEvent
	 * @memberOf DIRECTIVES.jtjList.component.pagination
	 * @description Event wrapper in order to debounce
	 * @param {object} event
	 */
	_handlePaginationInputChangeEvent(event) {
		event.persist();
		this._handlePaginationInputChange(event);
	}

	/**
	 * @function _handlePaginationInputSelectClick
	 * @memberOf DIRECTIVES.jtjList.component.pagination
	 * @description Select content on click
	 * @param {object} event
	 */
	_handlePaginationInputSelectClick(event) {
		event.target.select();
	}

	/**
	 * @function _renderJumpTo
	 * @memberOf DIRECTIVES.jtjList.component.pagination
	 */
	_renderJumpTo() {
		var pageNumber =
			_floor(this.props.params.offset / this.props.params.limit) + 1;
		return (
			<div className="pagination__jump">
				<input
					ref="jump"
					onClick={this._handlePaginationInputSelectClick}
					onChange={this._handlePaginationInputChangeEvent}
					type="text"
					defaultValue={pageNumber}
				/>
			</div>
		);
	}

	/**
	 * @function _renderFastPreviousButton
	 * @memberOf DIRECTIVES.jtjList.component.pagination
	 */
	_renderFastPreviousButton() {
		var allPages =
			_floor((this.props.collectionLength - 1) / this.props.params.limit) + 1;
		if (allPages > 20) {
			if (this.props.params.offset > 0 && allPages > 20) {
				return (
					<li onClick={this.navigate.bind(this, '--')}>
						<span className="icon icon--chevron_left pagination__main pagination__main--previous" />
						<span className="icon icon--chevron_left pagination__main pagination__main--previous" />
					</li>
				);
			} else {
				return (
					<li>
						<span className="icon icon--chevron_left pagination__main pagination__main--previous pagination__main--disabled" />
						<span className="icon icon--chevron_left pagination__main pagination__main--previous pagination__main--disabled" />
					</li>
				);
			}
		}
	}

	/**
	 * @function _renderPreviousButton
	 * @memberOf DIRECTIVES.jtjList.component.pagination
	 */
	_renderPreviousButton() {
		if (this.props.params.offset > 0) {
			return (
				<li onClick={this.navigate.bind(this, '-')}>
					<span className="icon icon--chevron_left pagination__main pagination__main--previous" />
				</li>
			);
		} else {
			return (
				<li>
					<span className="icon icon--chevron_left pagination__main pagination__main--previous pagination__main--disabled" />
				</li>
			);
		}
	}

	/**
	 * @function _renderFastNextButton
	 * @memberOf DIRECTIVES.jtjList.component.pagination
	 */
	_renderFastNextButton() {
		var allPages =
			_floor((this.props.collectionLength - 1) / this.props.params.limit) + 1;
		if (allPages > 20) {
			if (
				this.props.params.offset <
				this.props.collectionLength - this.props.params.limit
			) {
				return (
					<li onClick={this.navigate.bind(this, '++')}>
						<span className="icon icon--chevron_right pagination__main pagination__main--next" />
						<span className="icon icon--chevron_right pagination__main pagination__main--next" />
					</li>
				);
			} else {
				return (
					<li>
						<span className="icon icon--chevron_right pagination__main pagination__main--next pagination__main--disabled" />
						<span className="icon icon--chevron_right pagination__main pagination__main--next pagination__main--disabled" />
					</li>
				);
			}
		}
	}

	/**
	 * @function _renderNextButton
	 * @memberOf DIRECTIVES.jtjList.component.pagination
	 */
	_renderNextButton() {
		if (
			this.props.params.offset <
			this.props.collectionLength - this.props.params.limit
		) {
			return (
				<li onClick={this.navigate.bind(this, '+')}>
					<span className="icon icon--chevron_right pagination__main pagination__main--next" />
				</li>
			);
		} else {
			return (
				<li>
					<span className="icon icon--chevron_right pagination__main pagination__main--next pagination__main--disabled" />
				</li>
			);
		}
	}

	/**
	 * @function _renderPaging
	 * @memberOf DIRECTIVES.jtjList.component.pagination
	 */
	_renderPaging() {
		// get misc numbers to base calculations and rules upon
		var allPages =
			_floor((this.props.collectionLength - 1) / this.props.params.limit) + 1;
		var pageNumber =
			_floor(this.props.params.offset / this.props.params.limit) + 1;
		var bookmarkOffset = 4;
		var firstVisiblePage;
		var lastVisiblePage;

		// handle right side of middle of pagination
		if (
			pageNumber + bookmarkOffset === allPages - 1 ||
			pageNumber + bookmarkOffset > allPages ||
			pageNumber + bookmarkOffset === allPages
		) {
			firstVisiblePage = allPages - bookmarkOffset * 2 - 2;
			lastVisiblePage = allPages;
		}

		// handle left side of middle of pagination
		else if (pageNumber + bookmarkOffset < allPages) {
			firstVisiblePage =
				pageNumber - bookmarkOffset - 1 > 1
					? pageNumber - bookmarkOffset - 1
					: 1;
			if (firstVisiblePage > 1)
				lastVisiblePage = pageNumber + bookmarkOffset - 1;
			else lastVisiblePage = 1 + bookmarkOffset * 2;
		}

		// paging array
		var paging = [];

		for (var i = 0; i < allPages; i++) {
			// classnames for bookmark depending on i
			var bookmarkClassNames = cx('pagination__number', {
				'pagination__number--active':
					i * this.props.params.limit === this.props.params.offset,
				'pagination__number--end':
					(i === 0 || i === allPages - 1) &&
					!(i + 1 >= firstVisiblePage && i - 1 <= lastVisiblePage)
			});

			// jsx code for bookmark
			var bookmark = (
				<li key={i} onClick={this.navigate.bind(this, i)}>
					<span className={bookmarkClassNames}>{i + 1}</span>
				</li>
			);

			// push bookmark if first or last page
			if (i === 0 || i === allPages - 1) paging.push(bookmark);
			// push bookmark if page is within visible pages
			else if (i >= firstVisiblePage && i <= lastVisiblePage)
				paging.push(bookmark);
		}
		return paging;
	}

	componentDidMount() {
		// debounce input event
		this._handlePaginationInputChange = _debounce(
			this._handlePaginationInputChange,
			300
		);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// show new page value
		if (_has(this.refs, 'jump'))
			this.refs.jump.value =
				_floor(nextProps.params.offset / nextProps.params.limit) + 1;
	}

	render() {
		if (this.props.collectionLength > this.props.params.limit) {
			return (
				<tfoot className="pagination">
					<tr>
						<td
							colSpan={
								this.props.methods.handleReturnVisibleHeaders().length +
								this.props.methods.handleCountRowActions()
							}>
							<ul className="pagination__list">
								{this._renderFastPreviousButton()}
								{this._renderPreviousButton()}
								{this._renderPaging()}
								{this._renderNextButton()}
								{this._renderFastNextButton()}
							</ul>
							{this._renderJumpTo()}
						</td>
					</tr>
				</tfoot>
			);
		} else return null;
	}
}

Pagination.propTypes = {
	collectionLength: PropTypes.number,
	methods: PropTypes.object.isRequired
};

export default Pagination;
