// interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';
import { IMarketResponsibilityCrud } from './types';

import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './marketResponsibilities.phrases';

function handleError(err: Error): Error {
	const error = formatErrorMessage(err);
	setFeedback(error, 0);

	throw error;
}

/**
 * @function fetchMarketResponsibilities
 * @description Fetch all market responsiblities
 * @param {object} state
 */
export function fetchMarketResponsibilities(
	state: IFetchDataProps,
	employmentId: number
): Promise<IApiRes> {
	let filter = `:employment.id=='${employmentId}'`;
	if (state.filter) filter = `${filter};${state.filter}`;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(
		'/administration/market_responsibilities',
		params,
		null,
		state.headers
	)
		.then((res: IApiRes) => res)
		.catch(handleError);
}

/**
 * @function addMarketResponsibilities
 * @description adds marketResponsibility
 */
export function addMarketResponsibilities(
	data: IMarketResponsibilityCrud[]
): Promise<IApiRes> {
	return post('/administration/market_responsibilities', { batch: data })
		.then((res: IApiRes) => {
			setFeedback(phrases.MARKET_RESPONSIBILITY_ADDED, 1);
			return res;
		})
		.catch(handleError);
}

/**
 * @function editMarketResponsibilities
 * @description edits marketResponsibility
 */
export function editMarketResponsibilities(
	id: number,
	data: Partial<IMarketResponsibilityCrud>
): Promise<IApiRes> {
	return post(`/administration/market_responsibilities/${id}`, data)
		.then((res: IApiRes) => {
			setFeedback(phrases.MARKET_RESPONSIBILITY_EDITED, 1);
			return res;
		})
		.catch(handleError);
}

/**
 * @function deleteMarketResponsibilities
 * @description deletes market responsiblities
 */
export function deleteMarketResponsibilities(id: number): Promise<IApiRes> {
	return remove(`/administration/market_responsibilities/${id}`)
		.then((res: IApiRes) => {
			setFeedback(phrases.MARKET_RESPONSIBILITIY_DELETED, 1);
			return res;
		})
		.catch(handleError);
}
