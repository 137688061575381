'use strict';

/**
 * PageTitleAbstract
 * @param  {*} template
 */
class PageTitleAbstract {
	constructor(template) {
		// Set type
		this.type = 'PageTitleAbstract';

		/// ///////////
		// Template //
		/// ///////////

		// Define templateType
		this.templateType = typeof template;

		// Define template
		this.template = template;
	}

	/**
	 * @function getTemplate
	 * @memberOf PageTitleAbstract
	 * @description
	 * Returns unformatted content
	 * @param  {object} data
	 */
	getTemplate(data) {
		switch (this.templateType) {
		case 'function':
			return this.template(data);
		case 'string':
			return this.template;
		}
	}
}

export default PageTitleAbstract;
