/**
 * @function getDiscountIngredientsFilter
 * @param {Object} discount - discount to add ingredients to
 * @param {Array} ingredients - array of ingredietns to loop through
 * @description Creates a filter that checks what ingredients are already
 * belonging to the discount
 */
export function getDiscountIngredientsFilter({ ingredients }) {
	const ingredientIds = ingredients.reduce((acc, currentValue, index) => {
		acc = ingredients.length - 1 === index
			? `${acc}'${currentValue.value.id}'`
			: `${acc}'${currentValue.value.id}',`;

		return acc;
	}, '');

	return `:ingredient.id=IN=[${ingredientIds}]`;
}

export function markValuesWithCanAdd({discountIngredients, data}) {
	return data.map(entry => {
		// find discount ingredient with the same ingredient id
		const foundValue = discountIngredients.some(discountIngredient => discountIngredient.ingredient.id === entry.ingredient.value.id);

		return {
			...entry,
			canAdd: !foundValue
		};
	});
}
