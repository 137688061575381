'use strict';

import _get from 'lodash/get';

import {
	TOGGLE_ADD_SHIFT_MODAL,
	SET_ADD_SHIFT_MODAL_DATA,
	UPDATE_ACTIVE_SHIFT,
	REMOVE_TAG_FROM_ACTIVE_SHIFT,
	REMOVE_EMPLOYEE_FROM_ACTIVE_SHIFT,
	REMOVE_MODULE_EXECUTION_FROM_ACTIVE_SHIFT,
	ACTIVE_SHIFT_TOUCHED,
	ADD_CLOCKING_TO_ACTIVE_SHIFT,
	ADD_TRAINING_MODULE_TO_ACTIVE_SHIFT,
	SET_MODULE_EXECUTION_DATA,
	SET_TAG_DATA,
	SET_SELECTED_TAG_TYPE,
	RESET_SELECTED_TAG_TYPE,
	RESET_TAG_DATA,
	RESET_MODULE_EXECUTION_DATA,
	SET_TAGS,
	RESET_SHIFT_MODAL_TAGS,
	SET_MARKET_SHIFT_RULES,
	CLEAR_MARKET_SHIFT_RULES,
	SET_SHIFT_BREAK_DATA,
	UPDATE_SHIFT_BREAK_DATA,
	RESET_SHIFT_BREAK_DATA,
	REMOVE_SHIFT_BREAK_FROM_ACTIVE_SHIFT,
	SET_TAG_DATA_TO_EDIT,
	RESET_TAG_DATA_TO_EDIT,
	UPDATE_TRAINING_MODULE_IN_ACTIVE_SHIFT,
} from './addShift.actions';

const defaultState = {
	showAddShiftModal: false,
	activeShiftTouched: false,
	activeShift: {},
	activeDay: {},
	shiftModalTags: {
		moduleExecution: {
			module: null,
			period: null,
			person: null,
			shift: null,
			tag: null,
			trainer: null,
		},
		tag: {
			tagId: null,
			period: null,
		},
		selectedTagType: {},
		tagToEdit: null,
	},
	originalShiftEmployee: null,
	marketShiftRules: [],
	shiftBreak: {},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
		case TOGGLE_ADD_SHIFT_MODAL:
			return Object.assign({}, state, {
				...payload,
			});

		case SET_ADD_SHIFT_MODAL_DATA:
			return {
				...state,
				activeShift: payload.activeShift,
			};

		case ACTIVE_SHIFT_TOUCHED:
			return Object.assign({}, state, {
				activeShiftTouched: payload.activeShiftTouched,
			});

		case UPDATE_ACTIVE_SHIFT:
			return Object.assign({}, state, {
				activeShift: {
					...state.activeShift,
					...payload.updatedShift,
				},
			});

		case REMOVE_TAG_FROM_ACTIVE_SHIFT: {
			return Object.assign({}, state, {
				activeShift: {
					...state.activeShift,
					shift_tags: (state?.activeShift?.shift_tags ?? []).filter(
						(_, index) => {
							return index !== payload.index;
						}
					),
				},
			});
		}
		case ADD_CLOCKING_TO_ACTIVE_SHIFT: {
			return Object.assign({}, state, {
				activeShift: {
					...state.activeShift,
					shift_employees: [
						{
							...state.activeShift.shift_employees[0],
							shift_clockings:
								state.activeShift.shift_employees[0].shift_clockings.map(
									// eslint-disable-next-line camelcase
									(shift_clocking) => {
										if (shift_clocking.id === payload.clocking.id)
											return payload.clocking;
										// eslint-disable-next-line camelcase
										return shift_clocking;
									}
								),
						},
					],
				},
			});
		}

		case ADD_TRAINING_MODULE_TO_ACTIVE_SHIFT: {
			// Congratulations, you have added a module execution.
			// But now you want to show it to the user...
			// We find the day of the module execution and the id of the shift we want to add the module execution to by mapping through
			// the shifts on that particular day.
			// consisting of existing module executions and the new one.
			// When we find the id of the shift that matches to one the module has been added to, return a new array of module executions
			const shiftTag = payload.trainingModule.shift_tag;

			return Object.assign({}, state, {
				activeShift: {
					...state.activeShift,
					module_executions: [
						...state.activeShift.module_executions,
						payload.trainingModule,
					],
					shift_tags: [...state.activeShift.shift_tags, shiftTag],
				},
			});
		}

		case UPDATE_TRAINING_MODULE_IN_ACTIVE_SHIFT: {
			return {
				...state,
				activeShift: {
					...state.activeShift,
					module_executions: state.activeShift.module_executions.map(
						(entry) => {
							if (entry.id === payload.trainingModule.id)
								return {
									...entry,
									...payload.trainingModule,
								};

							return entry;
						}
					),
				},
			};
		}

		case REMOVE_EMPLOYEE_FROM_ACTIVE_SHIFT: {
			const newShiftEmployees = _get(state, 'activeShift.shift_employees', []);

			return Object.assign({}, state, {
				activeShift: {
					...state.activeShift,
					shift_employees: newShiftEmployees.filter(
						(shiftEmployee) => shiftEmployee.id !== payload.shiftEmployee.id
					),
				},
			});
		}

		case REMOVE_MODULE_EXECUTION_FROM_ACTIVE_SHIFT: {
			return Object.assign({}, state, {
				activeShift: {
					...state.activeShift,
					module_executions: (
						state?.activeShift?.module_executions || []
					).filter(
						(moduleExecution) =>
							moduleExecution.id !== payload.moduleExecution.id
					),
					shift_tags: (state.activeShift?.shift_tags ?? []).filter(
						// eslint-disable-next-line camelcase
						(shiftTag) =>
							shiftTag.tag.id !== payload?.moduleExecution?.shift_tag?.tag?.id
					),
				},
			});
		}

		case SET_MODULE_EXECUTION_DATA: {
			return {
				...state,
				shiftModalTags: {
					...state.shiftModalTags,
					moduleExecution: {
						...state.shiftModalTags.moduleExecution,
						...payload,
					},
				},
			};
		}

		case SET_TAG_DATA: {
			return {
				...state,
				shiftModalTags: {
					...state.shiftModalTags,
					tag: {
						...state.shiftModalTags.tag,
						...payload,
					},
				},
			};
		}

		case SET_SELECTED_TAG_TYPE: {
			return {
				...state,
				shiftModalTags: {
					...state.shiftModalTags,
					selectedTagType: {
						...state.shiftModalTags.selectedTagType,
						...payload,
					},
				},
			};
		}

		case RESET_SELECTED_TAG_TYPE: {
			return {
				...state,
				shiftModalTags: {
					...state.shiftModalTags,
					selectedTagType: defaultState.shiftModalTags.selectedTagType,
				},
			};
		}

		case RESET_TAG_DATA: {
			return {
				...state,
				shiftModalTags: {
					...state.shiftModalTags,
					tag: defaultState.shiftModalTags.tag,
				},
			};
		}

		case RESET_MODULE_EXECUTION_DATA: {
			return {
				...state,
				shiftModalTags: {
					...state.shiftModalTags,
					moduleExecution: defaultState.shiftModalTags.moduleExecution,
				},
			};
		}

		case SET_TAGS: {
			return {
				...state,
				shiftModalTags: {
					...state.shiftModalTags,
					tags: payload,
				},
			};
		}

		case RESET_SHIFT_MODAL_TAGS: {
			return {
				...state,
				shiftModalTags: defaultState.shiftModalTags,
			};
		}

		case SET_MARKET_SHIFT_RULES: {
			return {
				...state,
				marketShiftRules: payload,
			};
		}

		case CLEAR_MARKET_SHIFT_RULES: {
			return {
				...state,
				marketShiftRules: defaultState.marketShiftRules,
			};
		}

		case SET_SHIFT_BREAK_DATA:
			return {
				...state,
				shiftBreak: payload,
			};

		case UPDATE_SHIFT_BREAK_DATA:
			return {
				...state,
				shiftBreak: {
					...state.shiftBreak,
					...payload,
				},
			};

		case RESET_SHIFT_BREAK_DATA:
			return {
				...state,
				shiftBreak: defaultState.shiftBreak,
			};

		case REMOVE_SHIFT_BREAK_FROM_ACTIVE_SHIFT:
			return {
				...state,
				activeShift: {
					...state.activeShift,
					shift_breaks: (state.activeShift?.shift_breaks || []).filter(
						(entry) => {
							return entry.id !== payload.id;
						}
					),
					shift_tags: (state.activeShift?.shift_tags || []).filter((entry) => {
						if (payload.addMode && entry.shiftBreakId === payload.id)
							return false;
						if (!payload.addMode && entry.id === payload.shiftTagId)
							return false;

						return true;
					}),
				},
			};

		case SET_TAG_DATA_TO_EDIT:
			return {
				...state,
				shiftModalTags: {
					...state.shiftModalTags,
					tagToEdit: {
						...state.shiftModalTags.tagToEdit,
						...payload,
					},
				},
			};

		case RESET_TAG_DATA_TO_EDIT:
			return {
				...state,
				shiftModalTags: {
					...state.shiftModalTags,
					tagToEdit: defaultState.shiftModalTags.tagToEdit,
				},
			};

		default:
			return state;
	}
}

export default reducer;
