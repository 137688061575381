'use strict';

import {
	RESET_STATE,
	SET_POS_CONFIGURATION_VERSION_CURRENCY,
	UPDATE_POS_CONFIGURATION_VERSION_CURRENCY,
} from './posConfigurationVersionCurrencies.actions';

const defaultState = {
	data: {
		defaultPosConfigurationVersionCurrency: {},
	},
};

function reducer(state = defaultState, action) {
	const { payload, type } = action;

	switch (type) {
	case SET_POS_CONFIGURATION_VERSION_CURRENCY: {
		return {
			...state,
			data: {
				...state.data,
				defaultPosConfigurationVersionCurrency: payload,
			},
		};
	}

	case UPDATE_POS_CONFIGURATION_VERSION_CURRENCY: {
		return {
			...state,
			data: {
				...state.data,
				defaultPosConfigurationVersionCurrency: {
					...state.data.defaultPosConfigurationVersionCurrency,
					...payload,
				},
			},
		};
	}

	case RESET_STATE: {
		return defaultState;
	}

	default:
		// never return default state in default case, Filip!!!
		return state;
	}
}

export default reducer;
