export default {
	TABLE_TITLE: 'Users',
	USER_ADDED: 'User added',
	USER_EDITED: 'User edited',
	USERS_EDITED: 'Users edited',
	USERS_DELETED: 'Users deleted',
	ACTIVATION_CODE_SENT: 'Activation code sent',
	USER_DETAILS_TOOLTIP: 'User details',
	SEND_ACTIVATION_KEY_TOOLTIP: 'Send activation key',
	MARKET_SELECT_PLACEHOLDER: 'Select market...',
	WORKPLACE_SELECT_PLACEHOLDER: 'Select workplace...',
	SHOW_ACTIVE_EMPLOYMENTS_ONLY: 'Show active employments only',
	PERSONS_TABLE_TITLE: 'People',
	RESET_2FA: 'Reset 2FA',
	TWO_FACTOR_RESET: '2FA reset',
};
