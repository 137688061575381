import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchAllIngredientComponentTranslations
 * @description Fetch all allergen translations
 */
export function fetchAllIngredientComponentTranslations(id, state) {
	const preFilter = `:ingredient_component.id=='${id}'`;
	const filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(`/product/ingredient_component_translations`, params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addIngredientComponentTranslations
 * @description add
 */
export function addIngredientComponentTranslations(data) {
	return post(`/product/ingredient_component_translations`, data)
		.then((res) => {
			setFeedback('Ingredient component translation added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editIngredientComponentTranslation
 */
export function editIngredientComponentTranslation(data) {
	return post(`/product/ingredient_component_translations/${data.id}`, data)
		.then((res) => {
			setFeedback('Ingredient component translation edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteIngredientComponentTranslation
 */
export function deleteIngredientComponentTranslation(id) {
	return remove(`/product/ingredient_component_translations/${id}`)
		.then((res) => {
			setFeedback('Ingredient component translation deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
