export default function getColumns() {
	return [
		{
			Header: 'Comment',
			id: 'comment',
			accessor: 'comment',
			filterPath: ':comment',
		},
	];
}
