import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';
import ClockinDeviationApprovalMobileViewPage from './components/clockinDeviationApprovalMobileViewPage';
import ClockinDeviationApprovalMobileViewPreview from './components/clockinDeviationApprovalMobileViewPreview';

// utils
import phrases from './clockinDeviationApprovalMobileView.phrases';
import enums from './../../clockinDeviationApproval.enums';

// style
import './clockinDeviationApprovalMobileView.css';

class ClockinDeviationApprovalMobileView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};

		this._goToStep = this._goToStep.bind(this);
		this._goStepBack = this._goStepBack.bind(this);

		this.modalRef = React.createRef();
	}

	_goToStep(index) {
		this.modalRef.current.goToStep(index + 2);
	}

	_goStepBack(index) {
		this.modalRef.current.goToStep(index);
	}

	render() {
		const {
			data,
			updateDeviation,
			resetModalData,
			deviationApprovedColor,
			deviationDeclinedColor,
			checkIfAllDeviationsAddressed,
		} = this.props;

		// a bit dodgy but manually append last step - aka. overview step
		const dataCopy = [...data, { lastStep: true }];

		const steps = dataCopy.map((entry, index) => {
			if (entry.lastStep)
				return {
					component: (
						<ClockinDeviationApprovalMobileViewPreview
							key={entry.id}
							data={data}
							deviationApprovedColor={deviationApprovedColor}
							deviationDeclinedColor={deviationDeclinedColor}
						/>
					),
					title: phrases.PREVIEW_STEP_TITLE,
					isValid: true,
					onNext: resetModalData,
					onBack: () => this._goStepBack(index),
					cancelButtonLabel: phrases.BACK,
					loading: false,
					confirmButtonLabel: phrases.FINISH,
					noScroll: false,
				};

			const isStepValid =
				entry.status === enums.CONFIRMED || entry.status === enums.DECLINED;

			const title = phrases.DEVIATION_TO_APPROVE;
			const onNext = () => this._goToStep(index);
			const isntFirstStep = index > 0;

			return {
				component: (
					<ClockinDeviationApprovalMobileViewPage
						key={entry.id}
						deviation={entry}
						updateDeviation={updateDeviation}
						deviationApprovedColor={deviationApprovedColor}
						deviationDeclinedColor={deviationDeclinedColor}
					/>
				),
				title,
				isValid: isStepValid,
				onNext,
				...(isntFirstStep && {
					onBack: () => this._goStepBack(index),
					cancelButtonLabel: phrases.BACK,
				}),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.NEXT,
				noScroll: false,
			};
		});

		return (
			<ModalStepper
				ref={this.modalRef}
				isOpen
				steps={steps}
				showStep
				onClose={checkIfAllDeviationsAddressed}
				closeConfirm={false}
			/>
		);
	}
}

ClockinDeviationApprovalMobileView.propTypes = {
	data: PropTypes.array,
	updateDeviation: PropTypes.func,
	resetModalData: PropTypes.func,
	deviationApprovedColor: PropTypes.string,
	deviationDeclinedColor: PropTypes.string,
	checkIfAllDeviationsAddressed: PropTypes.func,
};

export default ClockinDeviationApprovalMobileView;
