'use strict';

// Shifts
export const SHIFT_BEING_UPDATED = 'SHIFTS/SHIFT_BEING_UPDATED';
export const SET_SHIFT_WIDTH = 'SHIFTS/SET_SHIFT_WIDTH';
export const MERGE_CLOCKINGS_TO_SHIFTS = 'SHIFTS/MERGE_CLOCKINGS_TO_SHIFTS';
export const REMOVE_CLOCKING_FROM_SHIFT = 'SHIFTS/REMOVE_CLOCKING_FROM_SHIFT';
export const MERGE_REVIEW_SHIFTS = 'SHIFTS/MERGE_REVIEW_SHIFTS';
export const REMOVE_SHIFT = 'SHIFTS/REMOVE_SHIFT';
export const REMOVE_PERSON_SHIFTS = 'SHIFTS/REMOVE_PERSON_SHIFTS';
export const ADD_PERSON_SHIFTS = 'SHIFTS/ADD_PERSON_SHIFTS';
export const DELETE_SHADOW_EMPLOYEE_SHIFTS = 'SHIFTS/DELETE_SHADOW_EMPLOYEE_SHIFTS';
export const REMOVE_SHIFTS_FROM_EMPLOYEE_BY_ID = 'SHIFTS/REMOVE_SHIFTS_FROM_EMPLOYEE_BY_ID';

// Period
export const SET_START_OF_PERIOD = 'SHIFTS/SET_START_OF_PERIOD';
export const SET_END_OF_PERIOD = 'SHIFTS/SET_END_OF_PERIOD';

// Vacations
export const SET_VACATIONS = 'SHIFTS/SET_VACATIONS';
export const UPDATE_VACATION = 'SHIFTS/UPDATE_VACATIONS';
export const DELETE_VACATION = 'SHIFTS/DELETE_VACATION';

// Illness
export const SET_ILLNESS = 'SHIFTS/SET_ILLNESS';
export const UPDATE_ILLNESS = 'SHIFTS/UPDATE_ILLNESS';
export const DELETE_ILLNESS = 'SHIFTS/DELETE_ILLNESS';

// Training Mddule
export const REMOVE_TRAINING_MODULE_FROM_SHIFT = 'SHIFTS/REMOVE_TRAINING_MODULE_FROM_SHIFT';
export const ADD_TRAINING_MODULE_TO_ACTIVE_SHIFT = 'SHIFTS/ADD_TRAINING_MODULE_TO_ACTIVE_SHIFT';
export const ADD_TRAINING_MODULE_TO_SHIFT = 'SHIFTS/ADD_TRAINING_MODULE_TO_SHIFT';
export const UPDATE_EXISTING_TRAINING_MODULE = 'SHIFTS/UPDATE_EXISTING_TRAINING_MODULE';
export const TOGGLE_READ_ONLY_RIGHTS = 'SHIFTS/TOGGLE_READ_ONLY_RIGHTS';
export const TOGGLE_WEEKVIEW_DATE_RANGE_TYPE = 'SHIFTS/TOGGLE_WEEKVIEW_DATE_RANGE_TYPE';

// Ungraded module executions
export const MARK_SHIFTS_WITH_UNGRADED_MODULE_EXECUTIONS = 'SHIFTS/MARK_SHIFTS_WITH_UNGRADED_MODULE_EXECUTIONS';
export const UPDATE_SHIFTS_WITH_UNGRADED_MODULE_EXECUTIONS = 'SHIFTS/UPDATE_SHIFTS_WITH_UNGRADED_MODULE_EXECUTIONS';

export const SET_TAG_TYPES = 'SHIFTS/SET_TAG_TYPES';

export const SET_SHIFTPLANNER_MOUNTED = 'SHIFTS/SET_SHIFTPLANNER_MOUNTED';

/**
 * @param {string} argument
 */
export function shiftBeingUpdated(shiftId) {
	return {
		type: SHIFT_BEING_UPDATED,
		payload: {
			shiftId,
		},
	};
}

// Old shiftplanner start from this point.
// These actions should be split into meaningful contexts

/**
 * @param {object} shifts
 */
export function setShifts(shifts) {
	return {
		type: 'SET_SHIFTS',
		payload: shifts,
	};
}

/**
 * @param {object} shifts
 */
export function removeClockingFromShift(clocking, shiftId) {
	return {
		type: REMOVE_CLOCKING_FROM_SHIFT,
		payload: { clocking, shiftId },
	};
}

/**
 * @param {object} shifts
 */
export function setShiftWidth(shiftWidth) {
	return {
		type: SET_SHIFT_WIDTH,
		payload: shiftWidth,
	};
}

/**
 * @param {object} vacations
 */
export function setVacations(payload) {
	return {
		type: SET_VACATIONS,
		payload,
	};
}

export function updateVacation(payload) {
	return {
		type: UPDATE_VACATION,
		payload,
	};
}

/**
 * @param {object} vacationId
 */
export function deleteVacation(payload) {
	return {
		type: DELETE_VACATION,
		payload,
	};
}

/**
 * @param {object} illnesses
 */
export function setIllness(illnesses) {
	return {
		type: SET_ILLNESS,
		payload: illnesses,
	};
}

/**
 * @param {object} illnesses
 */
export function updateIllness(illnesses) {
	return {
		type: UPDATE_ILLNESS,
		payload: illnesses,
	};
}

/**
 * @param {object} illnessId
 */
export function deleteIllness(illnessId) {
	return {
		type: DELETE_ILLNESS,
		payload: illnessId,
	};
}

/**
 * @function cleanData
 * @description clean all data related to shifts
 */
export function cleanData() {
	return {
		type: 'CLEAN',
	};
}

/**
 * @function setWorkplaceDetails
 * @param {object} workplaceDetails
 */
export function setWorkplaceDetails(workplaceDetails) {
	return {
		type: 'SET_WORKPLACE_DETAILS',
		payload: workplaceDetails,
	};
}

/**
 * @function showCalendarView
 * @param {object} show
 */
export function showCalendarView(show) {
	return {
		type: 'SHOW_CALENDAR_VIEW',
		payload: show,
	};
}

/**
 * @function setEndOfPeriod
 * @param {object} endOfPeriod
 */
export function setEndOfPeriod(endOfPeriod = null) {
	return {
		type: SET_END_OF_PERIOD,
		payload: endOfPeriod,
	};
}

/**
 * @function toggleWeekviewDateRangeType
 */
export function toggleWeekviewDateRangeType() {
	return {
		type: TOGGLE_WEEKVIEW_DATE_RANGE_TYPE,
	};
}

/**
 * @function setNumberOfWeeksToShow
 * @param {number} numberOfWeeksToShow
 */
export function setNumberOfWeeksToShow(numberOfWeeksToShow = 0) {
	return {
		type: 'NUMBER_OF_WEEKS_TO_SHOW',
		payload: numberOfWeeksToShow,
	};
}

/**
 * @function addShift
 * @param {object} day
 * @param {object} shift
 */
export function addShift(shift) {
	return {
		type: 'ADD_SHIFT',
		payload: shift,
	};
}

/**
 * @function mergeClockingsToShifts
 * @param {object} clockings
 * @description this is temprary, also and extra check so we could show all people in the table, even those who only have clockings
 */
export function mergeClockingsToShifts(payload) {
	return {
		type: MERGE_CLOCKINGS_TO_SHIFTS,
		payload,
	};
}

/**
 * @function mergeReviewShifts
 * @param {object} shift
 * @description merge review shifts and clockings to shfitplanner store.
 */
export function mergeReviewShifts(payload) {
	return {
		type: MERGE_REVIEW_SHIFTS,
		payload,
	};
}

/**
 * @function removeShift
 * @param {object} day
 * @param {object} shift
 */
export function removeShift(shift) {
	return {
		type: REMOVE_SHIFT,
		payload: shift,
	};
}

/**
 * @function addUpdatedShift
 * @param {object} shift
 * @param {object} dayOfOriginalShift
 */
export function addUpdatedShift(shift) {
	return {
		type: 'UPDATE_SHIFT',
		payload: shift,
	};
}

/**
 * @function addUpdatedEmployment
 * @param {object} day
 * @param {object} employment
 * @param {object} dayOfOriginalShift
 */
export function addUpdatedEmployment(employment, dayOfOriginalShift) {
	return {
		type: 'UPDATE_EMPLOYMENT',
		payload: employment,
		dayOfOriginalShift,
	};
}

/**
 * @function setWorkplaceEmployements
 * @param {object} workplaceEmployments
 */
export function setWorkplaceEmployements(workplaceEmployments) {
	return {
		type: 'SET_WORKPLACE_EMPLOYMENTS',
		payload: workplaceEmployments,
	};
}

/**
 * @function setStartOfPeriod
 * @param {object} date
 */
export function setStartOfPeriod(date) {
	return {
		type: SET_START_OF_PERIOD,
		payload: date,
	};
}

export function setPersonDetails(person) {
	return {
		type: 'SET_PERSON_DETAILS',
		payload: person,
	};
}

/**
 * @function removeTagFromState
 * @param {number} tagId
 * @param {object} day
 */
export function removeTagFromState(tagId) {
	return {
		type: 'REMOVE_SHIFT_TAG',
		payload: {
			tagId,
		},
	};
}

/**
 * @function showPrintView
 * @param {boolean} isExporting
 */
export function showPrintView(isExporting) {
	return {
		type: 'SET_IS_EXPORTING_SHIFTPLAN',
		payload: isExporting,
	};
}

/**
 * @function changeShiftplannerView
 * @param {boolean} activeView
 */
export function changeShiftplannerView(activeView) {
	return {
		type: 'CHANGE_SHIFTPLANNER_VIEW',
		payload: activeView,
	};
}

/**
 * @function togglePhpReport
 */
export function togglePhpReport() {
	return {
		type: 'TOGGLE_PHP_REPORT',
	};
}

/**
 * @function removeEmployeeFromState
 * @param {number} shiftEmployeeId
 * @param {boolean} day
 */
export function removeEmployeeFromState(shiftEmployeeId, day) {
	return {
		type: 'REMOVE_SHIFT_EMPLOYEE',
		payload: {
			shiftEmployeeId,
			day,
		},
	};
}

/**
 * @function setOpeningHours
 * @param {object} openingHours
 */
export function setOpeningHours(openingHours) {
	return {
		type: 'SET_OPENING_HOURS',
		payload: openingHours,
	};
}

/**
 * @function updateOpeningHours
 * @param {object} openingHours
 */
export function updateOpeningHours(openingHours) {
	return {
		type: 'UPDATE_OPENING_HOURS',
		payload: openingHours,
	};
}

/**
 * @function updateOpeningHours
 * @param {object} openingHours
 */
export function updateSingleOpeningHour(payload) {
	return {
		type: 'UPDATE_SINGLE_OPENING_HOUR',
		payload,
	};
}

/**
 * @function setEmploymentWorkplaces
 * @param {list} employmentWorkplaces
 */
export function setEmploymentWorkplaces(employmentWorkplaces = []) {
	return {
		type: 'SET_EMPLOYMENT_WORKPLACES',
		payload: employmentWorkplaces,
	};
}

/**
 * @function removeShiftsFromPeriod
 * @param {object} from
 * @param {object} to
 */
export function removeShiftsFromPeriod(from, to) {
	return {
		type: 'REMOVE_SHIFTS_FROM_PERIOD',
		payload: {
			from,
			to,
		},
	};
}

/**
 * @function readOnlyRights
 * @param {boolean} readOnlyRights
 */
export function toggleReadOnlyRights(readOnlyRights) {
	return {
		type: TOGGLE_READ_ONLY_RIGHTS,
		payload: readOnlyRights,
	};
}

// Trainingplanner tags

/**
 * @function addTrainingModuleToShift
 * @param trainingModule
 */
export function addTrainingModuleToShift(trainingModule) {
	return {
		type: ADD_TRAINING_MODULE_TO_SHIFT,
		payload: {
			trainingModule,
		},
	};
}

// Trainingplanner tags

/**
 * @function updateExistingTrainingModule
 * @param trainingModule
 */
export function updateExistingTrainingModule(trainingModule) {
	return {
		type: UPDATE_EXISTING_TRAINING_MODULE,
		payload: {
			trainingModule,
		},
	};
}

/**
 * @function removeTrainingModuleFromShift
 * @param trainingModule
 */
export function removeTrainingModuleFromShift(trainingModule, shiftId) {
	return {
		type: REMOVE_TRAINING_MODULE_FROM_SHIFT,
		payload: {
			trainingModule,
			shiftId,
		},
	};
}

/**
 * @param {number} shifts
 */
export function addPersonShift(payload) {
	return {
		type: ADD_PERSON_SHIFTS,
		payload,
	};
}

/**
 * @param {number} personId
 */
export function removePersonShift(payload) {
	return {
		type: REMOVE_PERSON_SHIFTS,
		payload,
	};
}

export function deleteShadowEmployeeShifts(payload) {
	return {
		type: DELETE_SHADOW_EMPLOYEE_SHIFTS,
		payload,
	};
}

export function markShiftsWithUngradedModuleExecutions(payload) {
	return {
		type: MARK_SHIFTS_WITH_UNGRADED_MODULE_EXECUTIONS,
		payload,
	};
}

export function updateShiftsWithUngradedModuleExecutions(payload) {
	return {
		type: UPDATE_SHIFTS_WITH_UNGRADED_MODULE_EXECUTIONS,
		payload,
	};
}

export function setTagTypes(payload) {
	return {
		type: SET_TAG_TYPES,
		payload,
	};
}

export function setShiftplannerMounted(payload) {
	return {
		type: SET_SHIFTPLANNER_MOUNTED,
		payload,
	};
}

// gets am array of shift id's and removes them from store
export function removeShiftsFromEmployeeById(payload) {
	return {
		type: REMOVE_SHIFTS_FROM_EMPLOYEE_BY_ID,
		payload,
	};
}
