import React, { Component } from 'react';
import { store } from 'appState';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Button, Icon, Tooltip } from 'dumb';
import CsvDateRange from './components/csvDateRange/csvDateRange.component';

// utils/tools
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import constants from 'services/constants';
import moment from 'moment';

import { fetchEmployments, fetchEmploymentsAsCsv } from './juicers.service';
import { saveAs } from 'file-saver';
import { push } from 'redux-first-history';

import phrases from './juicers.phrases';

// style
import './juicers.css';

const reduxKey = 'hr/employments/list';

class Juicers extends Component {
	constructor(props) {
		super(props);

		this.state = {
			pages: null,
			activeFilterOn: false,
			employmentPositionContractOn: true,
			activeFilter: '',
		};

		this.fetchData = this.fetchData.bind(this);
		this.toggleShowCsvModal = this.toggleShowCsvModal.bind(this);
		this._setActiveFilter = this._setActiveFilter.bind(this);
		this.handleCSVExport = this.handleCSVExport.bind(this);
		this._setEmploymentPositionContractFilter = this._setEmploymentPositionContractFilter.bind(this);
		this.getExtraFilters = this.getExtraFilters.bind(this);
		this._renderLink = this._renderLink.bind(this);
		this.getActionButtons = this.getActionButtons.bind(this);

		this.columns = [
			{
				Header: 'Person id',
				id: 'person_ID',
				accessor: 'person.id',
				width: 65,
				filterPath: ':person.id',
			},
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => d.person?.identity?.full_name ?? '',
				filterPath: ':person.identity.full_name',
			},
			{
				Header: 'Badge',
				width: 75,
				id: 'badge',
				accessor: (d) => _get(d, 'current_moneyball_sub_position.badge', null),
				filterPath: ':current_moneyball_sub_position.badge',
			},
			{
				Header: 'Type',
				id: 'type',
				show: false,
				accessor: (d) => _get(d, 'current_employment_position.employment_type.type'),
				filterPath: ':current_employment_position.employment_type.type',
			},
			{
				Header: 'Workplace',
				id: 'workplace',
				accessor: (d) => _get(d, 'workplace.name', null),
				filterPath: ':workplace.name',
			},

			{
				Header: 'Email',
				id: 'email',
				accessor: (d) => _get(d, 'person.person_email_address.email_address', null),
				Cell: (val) => <a href={`mailto:${val.value}`}>{val.value}</a>,
				filterPath: ':person.person_email_address.email_address',
			},
			{
				Header: 'Phone Number',
				id: 'phoneNumber',
				accessor: (d) => _get(d, 'person.person_phone_number.phone_number', null),
				width: 100,
				Cell: (val) => <span>{val.value}</span>,
				filterPath: ':person.person_phone_number.phone_number',
			},
			{
				Header: 'Birthday',
				width: 90,
				id: 'bday',
				accessor: (d) => _get(d, 'person.identity.birth_date', null),
				filterPath: ':person.identity.birth_date',
			},
			{
				Header: 'Company',
				id: 'company',
				accessor: (d) => _get(d, 'company.name', null),
				filterPath: ':company.name',
			},
			{
				Header: 'Market',
				width: 100,
				id: 'market',
				accessor: (d) => _get(d, 'person.identity.market.name', null),
				filterPath: ':person.identity.market.name',
			},
			{
				Header: 'Termination',
				id: 'astermination',
				show: false,
				accessor: (d) => _get(d, 'termination.termination_reason', {}),
				filterPath: ':termination.termination_reason.description',
				Cell: (d) => {
					return d.value.description && d.value.type ? <span>{`${d.value.description} (${d.value.type})`}</span> : null;
				},
			},
			{
				Header: 'Started',
				width: 70,
				id: 'started',
				accessor: (d) => _get(d, 'started', null),
				filterPath: ':started',
			},
			{
				Header: 'Ended',
				width: 70,
				id: 'ended',
				accessor: (d) => _get(d, 'ended', null),
				filterPath: ':ended',
			},
			{
				Header: 'Administrative only',
				id: 'administrative-only',
				width: 120,
				Cell: (d) => {
					return (
						<div className="juicers-list__checkbox-wrapper">
							<input type="checkbox" checked={!!d.value} readOnly />
						</div>
					);
				},
				accessor: 'administrative_only',
				filterPath: ':administrative_only',
				filterable: false,
			},
			{
				Header: 'Has app user',
				id: 'app_user',
				width: 80,
				Cell: (d) => {
					return (
						<div className="juicers-list__checkbox-wrapper">
							<input type="checkbox" checked={!!d.value} readOnly />
						</div>
					);
				},
				accessor: (d) => _get(d, 'person.app_user', false),
				filterPath: ':person.app_user',
				filterable: false,
			},
			{
				Header: 'App allowance',
				id: 'app_allowance',
				accessor: (d) => _get(d, 'employee_meal_app_allowance.name', ''),
				filterPath: ':employee_meal_app_allowance.name',
			},
			{
				Header: 'Contract',
				width: 70,
				id: 'has_contract',
				accessor: (d) => _get(d, 'has_contract', null),
				Cell: (d) => (
					<div className="juicers-list__checkbox-wrapper">
						<input type="checkbox" checked={d.value} readOnly />
					</div>
				),
				filterPath: ':has_contract',
				filterable: false,
			},
			{
				Header: 'Employment position contract',
				id: 'employment_position_contract',
				minWidth: 125,
				accessor: (d) => _get(d, 'current_employment_position.has_contract', null),
				Cell: (d) => (
					<div className="juicers-list__checkbox-wrapper">
						<input
							type="checkbox"
							checked={_get(d, 'original.current_employment_position.has_contract', false)}
							readOnly
						/>
					</div>
				),
				filterPath: ':current_employment_position.has_contract',
				filterable: false,
			},
			{
				Header: 'C.O.C.',
				width: 50,
				id: 'has_code_of_conduct',
				accessor: (d) => _get(d, 'has_code_of_conduct', null),
				Cell: (d) => (
					<div className="juicers-list__checkbox-wrapper">
						<input type="checkbox" checked={d.value} readOnly />
					</div>
				),
				filterPath: ':has_code_of_conduct',
				filterable: false,
			},
			{
				Header: 'Resignation',
				width: 70,
				id: 'has_resignation',
				accessor: (d) => _get(d, 'has_resignation', null),
				Cell: (d) => (
					<div className="juicers-list__checkbox-wrapper">
						<input type="checkbox" checked={d.value} readOnly />
					</div>
				),
				filterPath: ':has_resignation',
				filterable: false,
			},
			{
				Header: 'Payroll',
				width: 55,
				id: 'include_in_salary_export',
				accessor: (d) => _get(d, 'include_in_salary_export', null),
				Cell: (d) => (
					<div className="juicers-list__checkbox-wrapper">
						<input type="checkbox" checked={d.value} readOnly />
					</div>
				),
				filterPath: ':include_in_salary_export',
				filterable: false,
			},
			{
				Header: 'Has foodpermit',
				width: 100,
				id: 'has_food_permit',
				accessor: 'has_food_permit',
				Cell: (d) => (
					<div className="juicers-list__checkbox-wrapper">
						<input type="checkbox" checked={d.value} readOnly />
					</div>
				),
				filterPath: ':has_food_permit',
				filterable: false,
			},
			{
				Header: 'Vaccine',
				width: 100,
				id: 'has-documentation-for-vaccination',
				accessor: 'has_documentation_for_vaccination',
				Cell: (d) => (
					<div className="juicers-list__checkbox-wrapper">
						<input type="checkbox" checked={d.value} readOnly />
					</div>
				),

				filterPath: ':has_documentation_for_vaccination',
				filterable: false,
			},
			{
				Header: 'Pending envelopes',
				width: 100,
				id: 'has-pending-envelopes',
				accessor: 'has_pending_envelopes',
				Cell: (d) => {
					const hasPendingEnvelopes = !_isEmpty(d.original.pending_envelopes);
					const tooltipText = hasPendingEnvelopes
						? d.original.pending_envelopes.map((entry) => `${entry.type} (${entry.status})`).join(', ')
						: '';

					return (
						<Tooltip
							text={tooltipText}
							visible={hasPendingEnvelopes}
							renderData={(ref, onMouseEnter, onMouseLeave) => (
								<div
									className="juicers-list__checkbox-wrapper"
									ref={ref}
									onMouseEnter={onMouseEnter}
									onMouseLeave={onMouseLeave}
								>
									<input type="checkbox" checked={d.value} readOnly />
								</div>
							)}
						/>
					);
				},
				filterPath: ':has_pending_envelopes',
				filterable: false,
			},
		];
	}

	_setActiveFilter() {
		this.setState((prevState) => ({
			activeFilterOn: !prevState.activeFilterOn,
		}));
	}

	_setEmploymentPositionContractFilter() {
		this.setState((prevState) => ({
			employmentPositionContractOn: !prevState.employmentPositionContractOn,
		}));
	}

	fetchData(state) {
		return fetchEmployments(state);
	}

	fetchDataAsCvs(state) {
		// Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
		// You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
		this.setState(() => ({ loading: true }));
		// Request the data however you want.  Here, we'll use our mocked service we created earlier

		return fetchEmploymentsAsCsv(state)
			.then((response) => {
				// Now just get the rows of data to your React Table (and update anything else like total pages or loading)
				this.setState(() => ({
					loading: false,
				}));
				return response;
			})
			.catch(() => {
				this.setState(() => ({
					loading: false,
				}));
			});
	}

	_renderLink(e) {
		return (
			<Button
				type="inverted"
				shadow
				title="Rules"
				onClick={
					() => store.dispatch(push(`hr/juicers/${e.original.person.id}`))

					// go('.details', { id: e.original.person.id })
				}
				size="micro"
			>
				<Icon name="tune" />
			</Button>
		);
	}

	getAdditionalFilters() {
		const { activeFilterOn, employmentPositionContractOn } = this.state;

		return (
			<div className="salary-component__filters">
				<Button type={activeFilterOn ? '' : 'inverted'} shadow size="small" onClick={this._setActiveFilter}>
					{activeFilterOn ? 'Juicer Bank: on' : 'Juicer Bank: off'}
				</Button>
				<Button
					type={employmentPositionContractOn ? 'inverted' : ''}
					shadow
					size="small"
					onClick={this._setEmploymentPositionContractFilter}
				>
					No Employment position contract
				</Button>
			</div>
		);
	}

	/**
	 * @function getActionButtons
	 * @description pops a custom csv modal with date range
	 * overwrites default csv from reactDataWrapper
	 */
	getActionButtons() {
		return (
			<Button type="inverted" label="CSV" shadow onClick={this.toggleShowCsvModal}>
				<Icon name="file_download" />
			</Button>
		);
	}

	/**
	 * @function handleCSVExport
	 * @description Handler for fetching data as CSV. Fetches from the fetchData method, unless a override fetchCsv method is passed
	 */
	handleCSVExport(dates) {
		const { from, to } = dates;
		const limit = 300;

		const headers = {
			Accept: 'application/csv',
		};

		return this.fetchData({ limit, headers, from, to }).then((csv) => {
			if (!csv) console.info('No csv data. Did you forget to return the promise?');

			const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });

			// outputs filename in format of "redux_key__2019_04_24"
			const csvFileName = `${reduxKey.replace('/', '_')}__${moment().format(constants.shortDate)}.csv`;
			const fileName = csvFileName;
			saveAs(blob, fileName);

			this.toggleShowCsvModal();
		});
	}

	toggleShowCsvModal() {
		this.setState((prevState) => ({
			showCsvModal: !prevState.showCsvModal,
		}));
	}

	getExtraFilters() {
		const { activeFilterOn, employmentPositionContractOn } = this.state;

		const activeFilter = `:workplace.id==null`;
		const employmentPositionContractFilter = `:current_employment_position.has_contract==false`;

		let filter;

		if (activeFilterOn) filter = activeFilter;
		if (!employmentPositionContractOn)
			filter = filter ? `${filter};${employmentPositionContractFilter}` : employmentPositionContractFilter;

		return filter;
	}

	render() {
		const { showCsvModal } = this.state;

		return (
			<>
				{showCsvModal ? (
					<CsvDateRange handleClose={this.toggleShowCsvModal} handleCSVExport={this.handleCSVExport} />
				) : null}
				<ReactDataWrapper
					title={phrases.TITLE}
					actionRender={this.getActionButtons()}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					accessAreasAllowedToEdit={['Employment Admin', 'Person Admin']}
					disableFetchCsvButton
					enableMultiSelection={false}
					defaultPageSize={50}
					actionsWidth={30}
					reduxKey={reduxKey}
					manual
					showSearch
					customAreaComponents={this.getAdditionalFilters()}
					extraFilters={this.getExtraFilters()}
					actions={this._renderLink}
				/>
			</>
		);
	}
}

export default Juicers;
