'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import { Button, Input, Icon, SingleDatePickerInput, Tooltip } from 'dumb';
import getColumns from 'reactDataWrapperColumns/pos/posConfigurationIngredients.columns';

// lodash
import _get from 'lodash/get';

import constants from 'services/constants';

import {
	setIngredientPricing,
	updateIngredientPricing,
	resetState,
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	setPosConfigurationIngredientPricingsDateFilter,
	setLegacyFilter,
} from './store/salesConfigurationIngredientPricings.actions';

import {
	fetchPosConfigurationIngredients,
	deletePosConfigurationIngredient,
	editPosConfigurationIngredient,
	addPosConfigurationIngredients,
	editBatchPosConfigurationIngredients,
} from './salesConfigurationIngredientPricings.service';

import phrases from './salesConfigurationIngredientPricings.phrases';
import moment from 'moment';

import IngredientPricingModalBatch from './components/modalBatch/ingredientPricingModalBatch';

import './salesConfigurationIngredientPricings.css';

const reduxKey = 'sales_configuration/pos/pos_configuration_ingredients';

class salesConfigurationIngredientPricings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			pages: null,
			showModal: false,
		};

		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this._onPosConfigurationIngredientPricingsFilterChange =
			this._onPosConfigurationIngredientPricingsFilterChange.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);
		this.fetchData = this.fetchData.bind(this);

		this.columns = getColumns(reduxKey);
	}

	editEntry() {
		const { ingredientPricing } = this.props;

		const payload = {
			...(ingredientPricing.alternativeId && {
				alternative_id: ingredientPricing.alternativeId,
			}),
			available_in_app: ingredientPricing.availableInApp,
			to_stay_price: ingredientPricing.toStayPrice,
			to_go_price: ingredientPricing.toGoPrice,
			delivery_price: ingredientPricing.deliveryPrice === '' ? null : ingredientPricing.deliveryPrice,
		};

		return editPosConfigurationIngredient(ingredientPricing.id, payload);
	}

	editMultiple(selectedRows) {
		const { ingredientPricing } = this.props;

		const list = selectedRows.map((row) => {
			return {
				id: row.id,
				...(ingredientPricing.toStayPrice && {
					to_stay_price: ingredientPricing.toStayPrice,
				}),
				...(ingredientPricing.toGoPrice && {
					to_go_price: ingredientPricing.toGoPrice,
				}),
				available_in_app: ingredientPricing.availableInApp,
				...(ingredientPricing.alternative_id && {
					alternative_id: ingredientPricing.alternative_id,
				}),
			};
		});

		const payload = {
			batch: list,
		};

		return editBatchPosConfigurationIngredients(payload);
	}

	deleteEntry(id) {
		return deletePosConfigurationIngredient(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			posConfigurationVersion: {
				name: _get(data, 'pos_configuration_version.name', ''),
				description: _get(data, 'pos_configuration_version.description', ''),
			},
			ingredient: _get(data, 'ingredient.name', ''),
			alternativeId: data.alternative_id,
			toStayPrice: data.to_stay_price,
			toGoPrice: data.to_go_price,
			availableInApp: data.available_in_app,
			deliveryPrice: data.delivery_price,
		};

		this.props.setIngredientPricing(payload);
	}

	getEditableCells() {
		const { ingredientPricing } = this.props;

		return [
			// if ingredientPricing.id is undefined that means we're batch editing
			// if it has a value that means we're editing a single value so display
			...(ingredientPricing.id
				? [
						{
							header: 'Pos configuration version',
							value: <span>{_get(ingredientPricing, 'posConfigurationVersion.name', '')}</span>,
						},
						{
							header: 'Pos configuration description',
							value: <span>{_get(ingredientPricing, 'posConfigurationVersion.description', '')}</span>,
						},
						{
							header: 'Ingredient',
							value: <span>{ingredientPricing.ingredient}</span>,
						},
				  ]
				: []),
			{
				header: 'Alternative Id',
				value: (
					<Input
						id="alternative_id"
						type="number"
						placeholder="Alternative id"
						value={_get(ingredientPricing, 'alternativeId', '')}
						onChange={(event) => this.editStoreEntry('alternativeId', event.target.value)}
					/>
				),
			},
			{
				header: 'To Stay Price',
				value: (
					<Input
						id="to_stay_price"
						type="number"
						placeholder="1234,56"
						value={_get(ingredientPricing, 'toStayPrice', '')}
						onChange={(event) => this.editStoreEntry('toStayPrice', event.target.value)}
					/>
				),
			},
			{
				header: 'To Go Price',
				value: (
					<Input
						id="to_go_price"
						type="number"
						placeholder="1234,56"
						value={_get(ingredientPricing, 'toGoPrice', '')}
						onChange={(event) => this.editStoreEntry('toGoPrice', event.target.value)}
					/>
				),
			},
			{
				header: 'Available in app',
				value: (
					<Input
						id="availableInApp"
						type="checkbox"
						onChange={(event) => this.editStoreEntry('availableInApp', event.target.checked)}
						checked={ingredientPricing.availableInApp}
					/>
				),
			},
			{
				header: 'Delivery price',
				value: (
					<Input
						id="delivery-price"
						placeholder="Enter delivery price..."
						value={_get(ingredientPricing, 'deliveryPrice', '')}
						onChange={(event) => this.editStoreEntry('deliveryPrice', event.target.value)}
					/>
				),
			},
		];
	}

	editStoreEntry(name, value) {
		const payload = {
			[name]: value,
		};

		this.props.updateIngredientPricing(payload);
	}

	fetchData(state) {
		return fetchPosConfigurationIngredients(state);
	}

	// redirect(data, e) {
	// 	e.stopPropagation();

	// 	const ingredientId = data.id;
	// 	const posConfigurationVersionId = _get(
	// 		data,
	// 		'pos_configuration_version.id',
	// 		null
	// 	);
	// 	const posConfigurationId = _get(
	// 		data,
	// 		'pos_configuration_version.pos_configuration.id',
	// 		null
	// 	);

	// 	go(
	// 		'jtj.globalAdministration.pos-configuration.details.pricings.ingredients-details',
	// 		{
	// 			id: posConfigurationId,
	// 			versionId: posConfigurationVersionId,
	// 			pricingId: ingredientId,
	// 		}
	// 	);
	// }

	// renderDetailsButton(d) {
	// 	return (
	// 		<Button
	// 			key="details"
	// 			type="inverted"
	// 			shadow
	// 			onClick={(e) => this.redirect(d.original, e)}
	// 			size="micro">
	// 			<Icon name="tune" />
	// 		</Button>
	// 	);
	// }

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	_onPosConfigurationIngredientPricingsFilterChange(date) {
		const { setPosConfigurationIngredientPricingsDateFilter } = this.props;

		const formattedDate = date ? moment.utc(date).format(constants.shortDate) : null;

		const filterString = formattedDate
			? `:pos_configuration_version.active.from=le='${formattedDate}';:pos_configuration_version.active.to=ge='${formattedDate}'`
			: '';

		setPosConfigurationIngredientPricingsDateFilter(filterString);
	}

	toggleLegacyFilter() {
		const { setLegacyFilter, legacyFilter } = this.props;

		const filter = legacyFilter ? '' : ':pos_configuration_version.pos_configuration.legacy==false';

		setLegacyFilter(filter);
	}

	getProductIngredientPricingsFilterComponents() {
		const { posConfigurationIngredientPricingsDateFilter, legacyFilter } = this.props;

		return (
			<div className="pos-configuration-ingredient-pricing__filter-area">
				<SingleDatePickerInput
					id="posConfigDiscountDatePicker"
					label="Select active date"
					type="single"
					appendToBody
					fixed
					onChange={this._onPosConfigurationIngredientPricingsFilterChange}
					selectedDate={posConfigurationIngredientPricingsDateFilter}
					noClockButton
					clearButtonDisabled={!posConfigurationIngredientPricingsDateFilter}
					clearable
				/>
				<Tooltip
					text={legacyFilter ? phrases.LEGACY_FILTER_TOOLTIP_ACTIVE : phrases.LEGACY_FILTER_TOOLTIP_UNACTIVE}
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							id="legacyFilterButton"
							className="pos-configuration-product-variants-pricing__filter-area__legacy-button"
							onClick={this.toggleLegacyFilter}
							type={legacyFilter ? '' : 'inverted'}
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
						>
							{phrases.LEGACY_BUTTON}
						</Button>
					)}
				/>
			</div>
		);
	}

	getExtraFilters() {
		const { posConfigurationIngredientPricingsDateFilter, legacyFilter } = this.props;

		let filter = posConfigurationIngredientPricingsDateFilter;
		if (legacyFilter && posConfigurationIngredientPricingsDateFilter) filter = `${filter};${legacyFilter}`;
		if (!posConfigurationIngredientPricingsDateFilter) filter = legacyFilter;

		return filter;
	}

	render() {
		const { updateBatchForm, batchFormData, addBatchList, batchList, editBatchListItem, removeBatchListItem } =
			this.props;

		return (
			<>
				<ReactDataWrapper
					accessAreasAllowedToEdit={['Sales Configuration']}
					title={phrases.INGREDIENT_PRICING}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={50}
					reduxKey={reduxKey}
					manual
					editEntry={this.editEntry}
					editableCellsEdit={this.getEditableCells()}
					setInitialEditValues={this.setInitialEditValues}
					customAreaComponents={this.getProductIngredientPricingsFilterComponents()}
					extraFilters={this.getExtraFilters()}
					onModalClose={this.props.resetState}
					// actions={(d) => this.renderDetailsButton(d)}
					actionsWidth={30}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					deleteConfirm
					actionRender={
						<Button type="inverted" label="Add" shadow onClick={() => this.toggleModal()}>
							<Icon name="add" />
						</Button>
					}
				/>

				<IngredientPricingModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchFormData={batchFormData}
					updateBatchForm={updateBatchForm}
					addBatchList={addBatchList}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addPosConfigurationIngredients={addPosConfigurationIngredients}
					fetchPosConfigurationIngredients={fetchPosConfigurationIngredients}
				/>
			</>
		);
	}
}

salesConfigurationIngredientPricings.propTypes = {
	ingredientPricing: PropTypes.object,
	updateIngredientPricing: PropTypes.func,
	setIngredientPricing: PropTypes.func,
	resetState: PropTypes.func,
	updateBatchForm: PropTypes.func,
	addBatchList: PropTypes.func,
	batchFormData: PropTypes.object,
	batchList: PropTypes.object,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	posConfigurationIngredientPricingsDateFilter: PropTypes.string,
	setPosConfigurationIngredientPricingsDateFilter: PropTypes.func,
	setLegacyFilter: PropTypes.func,
	legacyFilter: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setIngredientPricing,
			updateIngredientPricing,
			resetState,
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			setPosConfigurationIngredientPricingsDateFilter,
			setLegacyFilter,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		ingredientPricing: store.salesConfigurationIngredientPricings.data.ingredientPricing,
		batchFormData: store.salesConfigurationIngredientPricings.data.batchFormData,
		posConfigurationIngredientPricingsDateFilter:
			store.salesConfigurationIngredientPricings.posConfigurationIngredientPricingsDateFilter,
		batchList: store.salesConfigurationIngredientPricings.data.batchList,
		legacyFilter: store.salesConfigurationIngredientPricings.legacyFilter,
	};
};

export default connectWithStore(salesConfigurationIngredientPricings, mapStateToProps, mapDispatchToProps);
