'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';

import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';

// phrases/ constants
import phrases from './../../../productVariantGroupProductVariants.phrases';

class ProductVariantGroupProductVariantsBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e.target ? e.target.value : e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: value },
		});
	}

	render() {
		const { batchList, addBatchListItemRow } = this.props;

		return (
			<>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT}</th>

							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((entry, i) => {
							const deleteButtonDisabled = batchList.length === 1;

							return (
								<TableListRow key={entry.identifier}>
									<TableListCell>{i + 1}</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id={`entry-${entry.identifier}`}
											placeholder={
												phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT_PLACEHOLDER
											}
											value={entry.productVariant}
											handleChange={(key, event) =>
												this._editListEntry(event, entry, 'productVariant')
											}
											apiPath="/product/product_variants"
											cache
											clearable={false}
											params={{
												limit: 30,
											}}
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											inputFilterFormat={(input) => `:name=like='%${input}%'`}
										/>
									</TableListCell>

									<TableListCell>
										<Button
											type="negative"
											size="tiny"
											disabled={deleteButtonDisabled}
											onClick={() => this._onRemoveEntry(entry.identifier)}>
											<span className="icon icon--delete" />
										</Button>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
				<ModalRow
					typeAddNewRow
					tooltipText={phrases.MODAL_BATCH_STEP_LIST_TOOLTIP}
					tooltipZIndex={600}
					onClick={addBatchListItemRow}
				/>
			</>
		);
	}
}

ProductVariantGroupProductVariantsBatchStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
};

export default ProductVariantGroupProductVariantsBatchStepList;
