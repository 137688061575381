import {
	SET_ADDON_RECOMMENDATIONS,
	UPDATE_ADDON_RECOMMENDATIONS,
	RESET_ADDON_RECOMMENDATIONS
} from './addonRecommendations.actions';

const defaultState = {
	data: {
		addonRecommendation: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_ADDON_RECOMMENDATIONS: {
		return {
			data: {
				addonRecommendation: payload
			}
		};
	}

	case UPDATE_ADDON_RECOMMENDATIONS: {
		return {
			data: {
				addonRecommendation: {
					...state.data.addonRecommendation,
					...payload
				}
			}
		};
	}

	case RESET_ADDON_RECOMMENDATIONS: {
		return defaultState;
	}

	default:
		return state;
	}
}

export default reducer;
