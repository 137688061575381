'use strict';

class HotbarComponentResultSetAbstract {

	constructor(component) {
		// Set results
		this.results = component.getSelectedValues();
	}

	/**
	 * @function getResultSet
	 * @memberOf HotbarComponentResultSetAbstract
	 * @description Gets results (selected values) for current component
	 */
	getResults() {
		return this.results;
	}
}

export default HotbarComponentResultSetAbstract;