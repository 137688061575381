'use strict';

import { HotbarComponentAbstract } from 'hotdamnbar/factory';

/**
 * Select
 * @param  {string} title
 * @param  {bool} required
 * @param  {object} dataProvider
 * @param  {bool} multi
 */
class Select extends HotbarComponentAbstract {
	constructor(title, required = false, dataProvider, multi = false) {
		super(title, required);

		// Set type
		this.type = 'select';

		/// ///////////////
		// DataProvider //
		/// ///////////////

		this.dataProvider = dataProvider;

		/// ////////
		// Multi //
		/// ////////

		this.multi = multi;
	}

	/**
	 * @function getOptions
	 * @memberOf Select
	 * @description Gets options from data provider attached to component
	 */
	getOptions(filter) {
		return this.dataProvider.getDataAsOptions(filter);
	}

	/**
	 * @function setDataProviderParameter
	 * @memberOf Select
	 * @description Propagates values from view to data provider as parameters
	 */
	setDataProviderParameter(paramName, value) {
		this.dataProvider.setParameter(paramName, value);
	}
}

export default Select;
