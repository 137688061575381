'use strict';

import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './posConfigurationIngredientPricingVariation.phrases';

export function fetchPosConfigurationIngredientVariationPricings(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get(
		'/pos/pos_configuration_ingredient_variations',
		params,
		null,
		state.headers
	)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

export function editPosConfigurationIngredientVariations(payload) {
	return post(
		`/pos/pos_configuration_ingredient_variations/${payload.id}`,
		payload
	)
		.then(res => {
			setFeedback(phrases.INGREDIENT_PRICING_VARIANT_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deletePosConfigurationIngredientVariations
 * @param {Number} id - id of a product variant to delete
 */
export function deletePosConfigurationIngredientVariations(id) {
	return remove(`/pos/pos_configuration_ingredient_variations/${id}`)
		.then(res => {
			setFeedback(phrases.INGREDIENT_PRICING_VARIANT_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

export function addPosConfigurationIngredientVariations(data) {
	return post('/pos/pos_configuration_ingredient_variations', data)
		.then(res => {
			setFeedback(phrases.INGREDIENT_PRICING_VARIANT_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
