import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './shiftClockinCorrectionOptions.phrases';

/**
 * @function fetchShiftClockinCorrectionOptions
 * @description Fetch all shift clockin correction options
 * @param {object} state
 */
export function fetchShiftClockinCorrectionOptions(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/shiftplanning/shift_clocking_correction_options', params, null, state.headers)
		.then((res) => res)
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addShiftClockinCorrectionOptions
 * @description add a shift clockin correction options
 */
export function addShiftClockinCorrectionOptions(data) {
	return post('/shiftplanning/shift_clocking_correction_options', data)
		.then((res) => {
			setFeedback(phrases.ENTRY_ADEDD, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editShiftClockinCorrectionOption
 * @description edit a shift clockin correction option
 */
export function editShiftClockinCorrectionOption(data) {
	return post(`/shiftplanning/shift_clocking_correction_options/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.ENTRY_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteShiftClockinCorrectionOption
 * @description delete a shift clockin correction option
 */
export function deleteShiftClockinCorrectionOption(productId) {
	return remove(`/shiftplanning/shift_clocking_correction_options/${productId}`)
		.then((res) => {
			setFeedback(phrases.ENTRY_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
