'use strict';

import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './productVariantDiscountSet.phrases';

/**
 * @function fetchProductVariantDiscountSets
 * @description Fetch all discounts
 * @param {Number} productVariantId - id of a productVariant to get the discounts for
 * @param {Object} state
 */
export function fetchProductVariantDiscountSets(state, filter = '') {
	let newFilter;
	if (!filter) newFilter = state.filter;
	else newFilter = state.filter ? `${state.filter};${filter}` : filter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: newFilter,
	};

	return get('/sale/discount_product_sets', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchDiscounts
 * @description Fetch all discounts
 */
export function fetchDiscounts(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/sale/discounts', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addProductVariantDiscountSet
 * @param {Object} payload - discount data
 */
export function addProductVariantDiscountSet(payload) {
	return post('/sale/discount_product_set_options', payload)
		.then((res) => {
			setFeedback(phrases.DISCOUNT_SET_ADDED, 1);

			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteProductVariantDiscountSet
 * @description deletes product set
 */
export function deleteProductVariantDiscountSet(productSetId, productVariant) {
	// Set params and filter
	const params = {
		filter: `:product_variant.id=='${productVariant.id}';:discount_product_set.id=='${productSetId}'`,
	};

	return get(`/sale/discount_product_set_options`, params)
		.then((res) => {
			return remove(`/sale/discount_product_set_options/${res.data[0].id}`)
				.then((res) => {
					setFeedback(phrases.DISCOUNT_SET_DELETED, 1);
					return res;
				})
				.catch((err) => {
					const errorMessage = formatErrorMessage(err);
					setFeedback(errorMessage, 0);
				});
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
