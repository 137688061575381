'use strict';

import {
	SET_SHADOW_EMPLOYEES,
	ADD_SHADOW_EMPLOYEE,
	RESET_ALL,
	SET_SHADOW_EMPLOYEE,
	EDIT_SHADOW_EMPLOYEES,
	EDIT_SHADOW_EMPLOYEE,
	REMOVE_SHADOW_EMPLOYEE,
	RESET_SHADOW_EMPLOYEE,
	TOGGLE_ADD_SHADOW_SHIFT_EMPLOYEE_MODAL,
	TOGGLE_EDIT_SHADOW_EMPLOYEE_MODAL
} from './../actions/shiftPlannerShadowEmployees.actions';

const defaultState = {
	defaultShadowEmployee: {},
	data: [],
	modal: {
		loading: false,
		showAddShadowRegularEmployeeModal: false,
		showEditShadowEmployeeModal: false
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_SHADOW_EMPLOYEES: {
		return {
			...state,
			data: payload
		};
	}

	case SET_SHADOW_EMPLOYEE: {
		return {
			...state,
			defaultShadowEmployee: {
				...payload
			}
		};
	}

	case EDIT_SHADOW_EMPLOYEE: {
		return {
			...state,
			defaultShadowEmployee: {
				...payload
			}
		};
	}

	case ADD_SHADOW_EMPLOYEE: {
		return {
			...state,
			data: [
				...state.data,
				{
					id: payload.id,
					name: payload.name
				}
			]
		};
	}

	case EDIT_SHADOW_EMPLOYEES: {
		return {
			...state,
			data: state.data.map(entry => {
				if (entry.id === payload.id) {
					return {
						...entry,
						name: payload.name
					};
				} else return entry;
			})
		};
	}

	case REMOVE_SHADOW_EMPLOYEE: {
		return {
			...state,
			data: state.data.filter(entry => entry.id !== payload.id)
		};
	}

	case RESET_SHADOW_EMPLOYEE: {
		return {
			...state,
			defaultShadowEmployee: {}
		};
	}

	case RESET_ALL: {
		return {
			...defaultState
		};
	}

	case TOGGLE_ADD_SHADOW_SHIFT_EMPLOYEE_MODAL: {
		return {
			...state,
			modal: {
				...state.modal,
				showAddShadowRegularEmployeeModal: !state.modal
					.showAddShadowRegularEmployeeModal
			}
		};
	}

	case TOGGLE_EDIT_SHADOW_EMPLOYEE_MODAL: {
		return {
			...state,
			modal: {
				...state.modal,
				showEditShadowEmployeeModal: !state.modal.showEditShadowEmployeeModal
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
