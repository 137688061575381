'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';

// batch view
import WorkplaceSystemSoftwaresBatchStepList from './components/workplaceSystemSoftwaresBatchStepList';
import WorkplaceSystemSoftwaresBatchStepForm from './components/workplaceSystemSoftwaresBatchStepForm';

// utils
import {
	getWorkplaceSystemSoftwareFilter,
	markValuesWithCanAdd,
} from './../../utils';

// lodash
import _isEmpty from 'lodash/isEmpty';
import _uniqueId from 'lodash/uniqueId';

// phrases
import phrases from '../../workplaceSystemSoftwares.phrases';

// styles
import './workplaceSystemSoftwaresModalBatch.css';

class WorkplaceSystemSoftwaresModalBatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};

		this.modalRef = React.createRef();

		this._batchSubmit = this._batchSubmit.bind(this);
		this._goToStep = this._goToStep.bind(this);
		this._onSubmitForm = this._onSubmitForm.bind(this);
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_onSubmitForm() {
		const {
			batchForm,
			addBatchList,
			systemSoftwareId,
			fetchWorkplaceSystemSoftwares,
			workplaceId,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		let listArray;
		if (systemSoftwareId) {
			listArray = batchForm.workplaces.map((entry) => {
				return {
					identifier: _uniqueId('row-'), // used to identify what to edit on step#2
					workplace: entry,
				};
			});
		} else {
			listArray = batchForm.systemSoftwares.map((entry) => {
				return {
					identifier: _uniqueId('row-'), // used to identify what to edit on step#2
					systemSoftware: entry,
				};
			});
		}

		// get filter to fetch existing values
		const filter = getWorkplaceSystemSoftwareFilter({
			listArray,
			systemSoftwareId,
			workplaceId,
		});

		fetchWorkplaceSystemSoftwares({}, filter)
			.then((res) => {
				// mark values that exist with canAdd: false
				const markedValues = markValuesWithCanAdd({
					listArray,
					res: res.data,
					systemSoftwareId,
				});

				addBatchList(markedValues);

				this._goToStep(2);
			})
			.finally(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	_batchSubmit() {
		const {
			batchList,
			handleClose,
			addWorkplaceSystemSoftwares,
			workplaceId,
			systemSoftwareId,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchList
			.filter((entry) => entry.canAdd)
			.map((entry) => {
				return {
					workplace: systemSoftwareId
						? entry.workplace?.value?.id
						: workplaceId,
					system_software: systemSoftwareId || entry.systemSoftware?.value?.id,
					system_software_version: entry.systemSoftwareVersion?.value?.id,
				};
			});

		addWorkplaceSystemSoftwares({ batch: payload })
			.then(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
				handleClose();
			})
			.catch(() =>
				this.setState(() => ({
					loadingModal: false,
				}))
			);
	}

	render() {
		const {
			modalVisible,
			handleClose,
			batchList,
			removeBatchListItem,
			batchForm,
			updateBatchForm,
			editBatchListItem,
			systemSoftwareId,
			workplacesTableReduxKey,
			workplaceTableMarketValue,
		} = this.props;

		const steps = [
			{
				component: (
					<WorkplaceSystemSoftwaresBatchStepForm
						batchForm={batchForm}
						updateBatchForm={updateBatchForm}
						systemSoftwareId={systemSoftwareId}
						workplacesTableReduxKey={workplacesTableReduxKey}
						workplaceTableMarketValue={workplaceTableMarketValue}
					/>
				),
				title: systemSoftwareId
					? phrases.MODAL_BATCH_STEP_FORM_TITLE_WORKPLACES
					: phrases.MODAL_BATCH_STEP_FORM_TITLE_SYSTEM_SOFTWARES,
				isValid:
					!_isEmpty(batchForm.systemSoftwares) ||
					!_isEmpty(batchForm.workplaces),
				onNext: this._onSubmitForm,
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_NEXT,
				noScroll: false,
			},
			{
				component: (
					<WorkplaceSystemSoftwaresBatchStepList
						batchForm={batchForm}
						updateBatchForm={updateBatchForm}
						batchList={batchList}
						removeBatchListItem={removeBatchListItem}
						editBatchListItem={editBatchListItem}
						systemSoftwareId={systemSoftwareId}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: !!batchList?.length,
				onBack: () => this._goToStep(1),
				onNext: this._batchSubmit,
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return modalVisible ? (
			<ModalStepper
				isOpen={modalVisible}
				steps={steps}
				showStep={false}
				onClose={handleClose}
				ref={this.modalRef}
			/>
		) : null;
	}
}

WorkplaceSystemSoftwaresModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	batchList: PropTypes.array,
	removeBatchListItem: PropTypes.func,
	addWorkplaceSystemSoftwares: PropTypes.func,
	workplaceId: PropTypes.number,
	addBatchList: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchForm: PropTypes.object,
	editBatchListItem: PropTypes.func,
	systemSoftwareId: PropTypes.number,
	fetchWorkplaceSystemSoftwares: PropTypes.func,
	workplacesTableReduxKey: PropTypes.string,
	workplaceTableMarketValue: PropTypes.number,
};

export default WorkplaceSystemSoftwaresModalBatch;
