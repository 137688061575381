export default {
	MONEYBALL_POSITION_REMOVED: 'Moneyball Position Removed',
	ADD_MONEYBALL_POSITION: 'Add Moneyball Position',
	NEW_MONEYBALL_POSITION_CREATED: 'Moneyball Position Created',
	UPDATED_MONEYBALL_POSITION: 'Moneyball Position Updated',
	EDIT_MODAL: 'Edit Moneyball Position',
	COPY_TO_CLIPBOARD: 'Copied to clipboard',
	GRAB_IT_HERE: 'Copying didnt work. Grap it here: ',
	DETAILS: 'Go to details',
	COPY: 'Copy onboarding link',
};
