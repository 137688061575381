import {
	RESET_BASKET_RECOMMENDATION_PRIORITY,
	UPDATE_BATCH_FORM,
} from './basketRecommendationPriorities.actions';

// enums
import enums from './../basketRecommendationPriorities.enums';

const defaultState = {
	data: {
		defaultBasketRecommendationPriority: {},
		batchFormData: {
			type: {
				value: enums.RECOMMENDATION,
				label: enums.RECOMMENDATION,
			},
		},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case RESET_BASKET_RECOMMENDATION_PRIORITY: {
			return {
				...defaultState,
			};
		}

		case UPDATE_BATCH_FORM: {
			return {
				...state,
				data: {
					...state.data,
					batchFormData: {
						...state.data.batchFormData,
						...payload,
					},
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
