import constants from 'services/constants';
import React from 'react';

import { Button, Icon } from 'dumb';

// role hyrearchy
// 1. juicer
// 2. bar manager
// 3. regional manager
// 4. hr manager
// 5. admin

export default [
	{
		tips: [
			{
				tip: 'Toggle between different onboarding states by toggling the buttons above the table on/off.',
			},
			{
				tip: (
					<span>
						Press{' '}
						<Button size="tiny" shadow type="inverted">
							<Icon name="file_download" />
						</Button>{' '}
						to download an onboarding export csv.
					</span>
				),
			},
			{
				tip: 'To export a specific onboarding csv, toggle the filters above the table and click export.',
			},
			{
				tip: 'To download an onboarding csv within a date range, click on the csv button, select dates and click download.',
			},
		],
		accessLevel: constants.juicer,
	},
];
