'use strict';

import { convertBinaryToBase64 } from 'utils';

import phrases from './imageCell.phrases';
import { post } from 'api.vanilla.service';

/**
 * ImageCell
 * @param {string} name
 * @param {object} dataProvider
 */
class ImageCell {
	constructor(id = null, endpoint, objectKey = 'image') {
		// Set type
		this.type = 'imageCell';

		// Name
		this.name = name;

		// objectKey
		this.objectKey = objectKey;

		// Id
		this.id = id;

		// Extensions
		this.extensions = ['png', 'jpg', 'jpeg'];

		this.setEndpoint(endpoint);
	}

	/// //////////
	// Setters //
	/// //////////

	/**
	 * @function setEndpoint
	 * @memberOf ImageCell
	 * @description Setter for dataProvider
	 */
	setEndpoint(endpoint) {
		this.endpoint = endpoint;
	}

	/// //////////
	// Getters //
	/// //////////

	/**
	 * @function getPhrases
	 * @memberOf ImageCell
	 * @description Getter for phrases
	 */
	getPhrases() {
		return phrases;
	}

	/**
	 * @function getExtensionsAsString
	 * @memberOf FileType
	 * @description Gets extensions
	 */
	getExtensions() {
		return this.extensions;
	}

	/**
	 * @function getExtensionsAsString
	 * @memberOf FileType
	 * @description Gets extensions joined with a /
	 */
	getExtensionsAsString() {
		return this.getExtensions().length === 0
			? '*'
			: this.getExtensions().join('/');
	}

	/**
	 * @function getExtensionsAsAccept
	 * @memberOf FileType
	 * @description Gets extensions formatted as accept attribute content
	 */
	getExtensionsAsAccept() {
		return this.getExtensions().length === 0
			? '*'
			: this.getExtensions()
				.map(extension => `.${extension}`)
				.join(',');
	}

	/**
	 * @function getIdentifier
	 * @memberOf ImageCell
	 * @description Gets identifier for current image cell component
	 */
	getIdentifier() {
		return `${this.endpoint}_${this.objectKey}_${this.id}_`;
	}

	/**
	 * @function handleSomething
	 * @memberOf ImageCell
	 * @description Handler for something
	 * @param {object} event
	 */
	handleUploadImage(file, fileName) {
		// Convert file to base64
		return (
			convertBinaryToBase64(file)

				// Initiate upload via dataprovider
				.then(encodedFile => {
					const data = {
						id: this.id,
						[this.objectKey]: {
							filename: fileName,
							data: encodedFile
						}
					};
					return post(`${this.endpoint}/${this.id}`, data);
				})
		);
	}

	/// ////////////
	// Utilities //
	/// ////////////

	/**
	 * @function extractFileExtension
	 * @memberOf FileType
	 * @description Extracts file extension if file has any - otherwise null
	 */
	extractFileExtension(fileName) {
		const fileNameDots = fileName === null ? [] : fileName.split('.');
		return fileNameDots.length > 1 ? fileNameDots.pop() : null;
	}

	/**
	 * @function validateFileExtension
	 * @memberOf FileType
	 * @description Validates file extension based on set extensions
	 */
	validateFileExtension(fileExtension) {
		// Return true if no extensions are set (all allowed)
		return this.getExtensions().length === 0
			? true
			: this.getExtensions().indexOf(fileExtension.toLowerCase()) > -1;
	}

	/**
	 * @function convertBinaryToBase64
	 * @memberOf FileType
	 * @description Converts binary data from selected file to base64 to sent to API
	 */
	convertBinaryToBase64(file) {
		return new Promise(resolve => {
			// Create fileReader
			const fileReader = new FileReader();

			// Set event onloadend to resolve with base64 encoded target result
			fileReader.onloadend = event => {
				const encoded = base64.encode(event.target.result);
				resolve(encoded);
			};

			// Read file and trigger onloadend
			fileReader.readAsBinaryString(file);
		});
	}

	/**
	 * @function extractBase64
	 * @memberOf FileType
	 * @description Extracts base64 from selected file
	 */
	extractBase64(file) {
		return new Promise(resolve => {
			// Create fileReader
			const fileReader = new FileReader();

			// Set event onloadend to resolve with target result
			fileReader.onloadend = event => resolve(event.target.result);

			// Read file
			fileReader.readAsDataURL(file);
		});
	}
}

export default ImageCell;
