'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	addBatchList,
	updateBatchForm,
	removeBatchListItem,

	// regular stuff
	setIngredientTranslation,
	updateIngredientTranslation,
	resetState,
	editBatchListItem,
} from './store/ingredientTranslations.actions';

// services
import {
	fetchIngredientTranslations,
	addIngredientTranslations,
	deleteIngredientTranslations,
	editIngredientTranslation,
	editIngredientTranslations,
} from './ingredientTranslations.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Button, Icon, Input } from 'dumb';
import IngredientTranslationsModalBatch from './components/batch/ingredientTranslationsModalBatch';
// same columns as for product/ingredient_translations
import getProductTranslationsColumns from 'reactDataWrapperColumns/product/productTranslations.columns';

// utils
import moment from 'moment';
import phrases from './ingredientTranslations.phrases';
import { getEditedValues } from 'services/utils';

const reduxKey = '/product/ingredient_translations';

class IngredientTranslations extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment().unix(),
		};

		this.deleteEntry = this.deleteEntry.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.addIngredientTranslationsWrapper = this.addIngredientTranslationsWrapper.bind(
			this
		);
		this.fetchData = this.fetchData.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);

		this.columns = getProductTranslationsColumns();
	}

	deleteEntry(id) {
		return deleteIngredientTranslations(id);
	}

	editEntry(data) {
		const { ingredientTranslation, originalIngredientTranslation } = this.props;

		const editedValues = getEditedValues({
			oldData: originalIngredientTranslation,
			newData: ingredientTranslation,
		});

		const payload = data || {
			id: ingredientTranslation.id,
			name: editedValues.name ?? undefined,
			description: editedValues.description ?? undefined,
		};

		return editIngredientTranslation(payload);
	}

	editMultiple(selectedRows) {
		const { ingredientTranslation } = this.props;

		const payload = {
			...(ingredientTranslation.name && {
				name: ingredientTranslation.name,
			}),
			...(ingredientTranslation.description && {
				description: ingredientTranslation.description,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editIngredientTranslations({
			batch: selectedRowsWithId,
		});
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			language: data.language?.name ?? '',
			name: data.name,
			description: data.description,
		};

		this.props.setIngredientTranslation(payload);
	}

	getEditableCells() {
		const { ingredientTranslation } = this.props;

		return [
			{
				header: 'Language',
				value: <span>{ingredientTranslation.language}</span>,
			},
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={ingredientTranslation?.name ?? ''}
						onChange={(e) => this.editStoreEntry(e.target.value, 'name')}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={ingredientTranslation?.description ?? ''}
						onChange={(e) => this.editStoreEntry(e.target.value, 'description')}
					/>
				),
			},
		];
	}

	editStoreEntry(e, type) {
		this.props.updateIngredientTranslation({
			[type]: e,
		});
	}

	fetchData(state) {
		const { ingredient } = this.props;

		const filter = `:ingredient.id=='${ingredient.id}'`;

		return fetchIngredientTranslations(state, filter);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	addIngredientTranslationsWrapper(data) {
		return addIngredientTranslations(data).then(() =>
			this.setState(() => ({
				key: moment().unix(),
			}))
		);
	}

	render() {
		const {
			batchList,
			removeBatchListItem,
			addBatchList,
			batchForm,
			updateBatchForm,
			ingredient,
			editBatchListItem,
		} = this.props;

		return (
			<>
				<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={`${reduxKey}-${ingredient.id}`}
					manual
					editEntry={this.editEntry}
					editMultiple={this.editMultiple}
					editableCells={this.getEditableCells()}
					setInitialEditValues={this.setInitialEditValues}
					deleteEntry={this.deleteEntry}
					onModalClose={this.props.resetState}
					actionRender={
						<Button
							type="inverted"
							label="Batch"
							shadow
							onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
				/>

				<IngredientTranslationsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					batchForm={batchForm}
					removeBatchListItem={removeBatchListItem}
					addBatchList={addBatchList}
					addIngredientTranslations={this.addIngredientTranslationsWrapper}
					updateBatchForm={updateBatchForm}
					ingredient={ingredient}
					editBatchListItem={editBatchListItem}
				/>
			</>
		);
	}
}

IngredientTranslations.propTypes = {
	resetState: PropTypes.func,
	batchList: PropTypes.array,
	removeBatchListItem: PropTypes.func,
	batchForm: PropTypes.object,
	addBatchList: PropTypes.func,
	updateBatchForm: PropTypes.func,
	ingredient: PropTypes.object,
	ingredientTranslation: PropTypes.object,
	originalIngredientTranslation: PropTypes.object,
	setIngredientTranslation: PropTypes.func,
	updateIngredientTranslation: PropTypes.func,
	editBatchListItem: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			removeBatchListItem,
			resetState,
			addBatchList,
			setIngredientTranslation,
			updateIngredientTranslation,
			editBatchListItem,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		ingredientTranslation:
			store.ingredientTranslations.data.ingredientTranslation,
		originalIngredientTranslation:
			store.ingredientTranslations.data.originalIngredientTranslation,
		batchList: store.ingredientTranslations.data.batchList,
		batchForm: store.ingredientTranslations.data.batchForm,
	};
};

export default connectWithStore(
	IngredientTranslations,
	mapStateToProps,
	mapDispatchToProps
);
