'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonGroup, InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';

import phrases from './../../../salesConfigurationPosConfigurationDiscounts.phrases';

import _isEmpty from 'lodash/isEmpty';

class SalesConfigurationPosConfigurationDiscountsBatchVersion extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};
	}

	editStoreEntry(e, type) {
		const {
			updateBatchForm,
			resetSelection,
			posConfigurationVersionReduxKey,
		} = this.props;

		// if we cleared the select, also clear the selected rows in overlay table
		if (type === 'posConfigurationVersion' && _isEmpty(e)) {
			resetSelection(posConfigurationVersionReduxKey);
		}

		updateBatchForm({
			[type]: e,
		});
	}

	render() {
		const {
			batchFormData,
			toggleOverlay,
			posConfigurationVersionReduxKey,
			posConfigurationVersionDateFilter,
		} = this.props;

		return (
			<>
				<ModalRow>
					<ButtonGroup type="full-width">
						<InputCollectionSelect
							id="posConfigurationVersion"
							className="modal-batch-select"
							label={phrases.MODAL_BATCH_STEP_POS_CONFIGURATION_VERSION}
							placeholder={
								phrases.MODAL_BATCH_STEP_POS_CONFIGURATION_VERSION_PLACEHOLDER
							}
							value={batchFormData.posConfigurationVersion}
							handleChange={(key, value) =>
								this.editStoreEntry(value, 'posConfigurationVersion')
							}
							required
							multi
							cache
							apiPath="/pos/pos_configuration_versions"
							params={{
								limit: 30,
								filter: posConfigurationVersionDateFilter,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>

						<Button
							type="inverted"
							shadow
							size="large"
							onClick={() => toggleOverlay(posConfigurationVersionReduxKey)}>
							<span className="icon icon--more" />
						</Button>
					</ButtonGroup>
				</ModalRow>
			</>
		);
	}
}

SalesConfigurationPosConfigurationDiscountsBatchVersion.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
	resetSelection: PropTypes.func,
	posConfigurationVersionReduxKey: PropTypes.string,
	posConfigurationVersionDateFilter: PropTypes.string,
};

export default SalesConfigurationPosConfigurationDiscountsBatchVersion;
