export const UPDATE_BATCH_FORM =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT/REMOVE_BATCH_LIST_ITEM';
export const SET_DATE_FILTERS =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT/SET_DATE_FILTERS';
export const RESET_STATE =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT/RESET_STATE';
export const SET_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT_LEGACY_FILTER =
	'SALES_CONFIGURATION_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT/SET_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT_LEGACY_FILTER';

export function setLegacyFilter(payload) {
	return {
		type: SET_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT_LEGACY_FILTER,
		payload,
	};
}

export function setDateFilters(payload) {
	return {
		type: SET_DATE_FILTERS,
		payload
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload
	};
}

export function resetState() {
	return {
		type: RESET_STATE
	};
}
