export default {
	TITLE: 'Remove shifts from employee',
	EMPLOYMENT_LABEL: 'Employment',
	EMPLOYMENT_TABLE_TITLE: 'Employments',
	EMPLOYMENT_PLACEHOLDER: 'Select an employment...',
	WORKPLACE_LABEL: 'Workplace',
	WORKPLACE_TABLE_TITLE: 'Workplaces',
	WORKPLACE_PLACEHOLDER: 'Select a workplace...',
	DATE_LABEL: 'Date',
	DATE_PLACEHOLDER: 'Select a date...',
	REMOVE_SHIFTS: 'Remove shifts',
	SUCCESS: 'Shifts removed successfully',
	VIEW_SHIFTS: 'View shifts',
	BACK: 'Back',
	SHIFTS_PAGE_TITLE: 'Shifts',
	SHIFTS_PAGE_ON_NEXT: 'Remove shifts from employee',
	SHIFTS_PAGE_TEXT: 'Following shifts will be affected',
};
