import enums from 'services/enums/collectionSelect';

/**
 * @function getStyles
 * @param {Object} object - contains props that determine the style of collection select
 * @description Returns a style object used to style the react-select v3
 */
export function getStyles({ size, styleType, required }) {
	// colors
	const grey100 = _getColorRgb('--grey-100');
	const grey200 = _getColorRgb('--grey-200');
	const grey300 = _getColorRgb('--grey-300');
	const white = _getColorRgb('--white');
	const grey600 = _getColorRgb('--grey-600');
	const pink200 = _getColorRgb('--pink-200');
	const pink50 = _getColorRgb('--pink-50');
	const brandBlack = _getColorRgb('--brand--black');
	const red400 = _getColorRgb('--red-400');

	// styles that depend on props
	let containerWidth = '100%';

	let controlMinHeight = 42;
	let controlLineHeight = '26px';
	let controlBorderRadius = 0;
	let controlColor = `rgb(${grey600})`;
	let controlBorder = `2px solid rgb(${grey100})`;
	let controlBackgroundColor = `rgb(${white})`;
	let controlBackgroundColorHover = `rgb(${white})`;
	let controlMarginTop = 0;
	let controlBoxShadow = 'none';

	let valueContainerPadding = '2px 6px';
	let valueContainerLineHeight = 0;

	let placeholderPadding = '6px 6px 6px 0';
	let placeholderLineHeight = '28px';

	let fontSize = 15;
	let singleValueLineHeight = '28px';
	let singleValueColor = 'inherit';

	let inputPadding = 'inherit';
	let inputMargin = 'inherit';

	let maxHeight = 'inherit';

	if (size === 'small') {
		controlMinHeight = 33;
		valueContainerPadding = '1px 6px';
		placeholderPadding = 0;
	}
	if (styleType === enums.TYPE_IN_TABLE) {
		controlBorderRadius = 3;
		controlBorder = `1px solid rgba(0,0,0,0.1)`;
		controlMinHeight = 20;
		valueContainerPadding = '0 2px';
		valueContainerLineHeight = '24px';
		placeholderPadding = 0;
		placeholderLineHeight = '24px';
		fontSize = 'inherit';
		maxHeight = 24.5;
	}
	if (styleType === enums.TYPE_JUICER_DETAILS || styleType === enums.TYPE_JUICER_DETAILS_PHONE) {
		fontSize = 14;
		controlLineHeight = 1;
		controlColor = brandBlack;
		controlBackgroundColor = `rgba(${grey100},0.2)`;
		controlBackgroundColorHover = `rgba(${grey100},0.3)`;
		controlMinHeight = 24;
		controlBorder = 'none';
		singleValueLineHeight = '16px';
		valueContainerPadding = 0;
		inputPadding = '0';
		inputMargin = '0';
		singleValueColor = `rgb(${brandBlack})`;
	}
	if (styleType === enums.TYPE_PHONE || styleType === enums.TYPE_JUICER_DETAILS_PHONE) {
		valueContainerPadding = '2px 6px 2px 16px';
		containerWidth = '80px';
	}
	if (styleType === enums.TYPE_MYSHIFTPLANNER) {
		controlBorderRadius = 2;
		controlMinHeight = 34;
		controlMarginTop = 20;
		controlBoxShadow = '0 1px 1px 1px rgba(0, 0, 0, 0.1)';
		controlBorder = 'none';
	}
	if (styleType === enums.TYPE_HOTDAMNBAR) {
		controlColor = 'inherit';
		placeholderLineHeight = '25px';
		controlBorder = 'none';
		controlBackgroundColor = 'transparent';
		controlBackgroundColorHover = 'transparent';
		controlMinHeight = 24;
	}

	return {
		menuPortal: (provided) => ({
			...provided,
			zIndex: 999,
			minWidth: 200,
		}),
		input: (provided) => ({
			...provided,
			width: '100%',
			padding: inputPadding,
			margin: inputMargin,
			...(styleType === enums.TYPE_IN_TABLE && {
				height: 24,
				padding: 0,
				margin: 0,
				textAlign: 'start',
			}),
		}),
		placeholder: (provided) => ({
			...provided,
			padding: placeholderPadding,
			lineHeight: placeholderLineHeight,
			textTransform: 'capitalize',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			maxWidth: 'calc(100% - 8px)',
			fontSize,
			...(styleType === enums.TYPE_HOTDAMNBAR && {
				fontWeight: 100,
				color: 'inherit',
			}),
		}),
		dropdownIndicator: () => ({
			display: 'none',
		}),
		indicatorSeparator: () => ({
			display: 'none',
		}),
		loadingIndicator: () => ({
			display: 'none',
		}),
		menu: (provided) => ({
			...provided,
			borderRadius: 0,
			marginTop: 0,
		}),
		menuList: (provided) => ({
			...provided,
			padding: 0,
		}),
		valueContainer: (provider) => ({
			...provider,
			display: 'flex',
			flexWrap: 'wrap',
			padding: valueContainerPadding,
			lineHeight: valueContainerLineHeight,
		}),
		container: (provider) => ({
			...provider,
			display: 'inline-block',
			width: containerWidth,
			...(required && {
				'::after': {
					position: 'absolute',
					content: '"*"',
					top: 2,
					right: 6,
					color: `rgb(${red400})`,
					lineHeight: '1',
					fontSize: 20,
					fontWeight: 600,
				},
			}),
		}),
		control: (provider, state) => {
			let backgroundColor = controlBackgroundColor;
			if (styleType === enums.TYPE_JUICER_DETAILS) {
				if (!state.isDisabled) backgroundColor = controlBackgroundColor;
			}

			return {
				...provider,
				width: '100%',
				border: controlBorder,
				backgroundColor,
				fontSize,
				fontWeight: '400',
				maxHeight,
				lineHeight: controlLineHeight,
				borderRadius: controlBorderRadius,
				minHeight: controlMinHeight,
				color: controlColor,
				marginTop: controlMarginTop,
				boxShadow: controlBoxShadow,
				opacity: state.isDisabled ? 0.6 : 1,
				':hover': {
					...provider[':hover'],
					boxShadow: controlBoxShadow,
					borderColor: `rgb(${grey100})`,
					cursor: 'pointer',
					backgroundColor: controlBackgroundColorHover,
				},
				':focused': {
					...provider[':focused'],
					boxShadow: 'none',
					borderColor: `rgb(${grey100})`,
				},
			};
		},
		option: (provider, state) => {
			let backgroundColor = null;
			if (state.isSelected)
				backgroundColor = styleType === enums.TYPE_HOTDAMNBAR ? `rgba(${grey200},0.5)` : `rgb(${pink200})`;
			if (state.isFocused)
				backgroundColor = styleType === enums.TYPE_HOTDAMNBAR ? `rgba(${grey100},0.5)` : `rgba(${pink50}, 0.7)`;

			return {
				...provider,
				padding: 4,
				backgroundColor,
				color: `rgb(${grey300})`,
				':active': {
					...provider[':active'],
					backgroundColor: `rgba(${pink50}, 1)`,
				},
				':hover': {
					...provider[':hover'],
					cursor: 'pointer',
				},
			};
		},
		singleValue: (provided) => ({
			...provided,
			lineHeight: singleValueLineHeight,
			fontSize,
			color: singleValueColor,
		}),
		multiValue: (provided) => ({
			...provided,
			margin: 3,
			backgroundColor: `rgb(${pink200})`,
			lineHeight: 1.4,
			borderRadius: 0,
			fontSize,
		}),
		multiValueLabel: (provided) => ({
			...provided,
			maxWidth: 350,
			cursor: 'default',
			padding: '2px 5px',
			display: 'inline-block',
			verticalAlign: 'middle',
		}),
		multiValueRemove: (provided) => ({
			...provided,
			borderRadius: 0,
			borderLeft: '1px solid white',
			':hover': {
				opacity: 0.7,
			},
		}),
	};
}

/**
 * @function _getColorRgb
 * @param {Stgring} colorName - code of the color found in variables.css
 * @description returns rgba color code
 */
function _getColorRgb(colorName) {
	return window.getComputedStyle(document.body).getPropertyValue(colorName);
}
