'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _has from 'lodash/has';
import _every from 'lodash/every';
import _debounce from 'lodash/debounce';

import './searchFilter.css';

class SearchFilter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			query: '',
		};

		this.filter = this.filter.bind(this);
		this.toggleFilterSearch = this.toggleFilterSearch.bind(this);
		this._doFilter = this._doFilter.bind(this);
		this._clearFilterSearch = this._clearFilterSearch.bind(this);
	}

	/**
	 * @function filter
	 * @memberOf COMPONENTS.jtjListFilter
	 * @description Sends filter arguments to angular
	 * @return {object}
	 */
	filter(event) {
		this.props.methods.handleFilterSearch(event.target.value);
	}

	/**
	 * @function toggleFilterSearch
	 * @memberOf COMPONENTS.jtjListFilter
	 * @description Sends filter arguments to angular
	 * @return {object}
	 */
	toggleFilterSearch(event) {
		event.preventDefault();
		event.stopPropagation();
		this.props.methods.handleToggleControls('isSearchFiltering');
		setTimeout(() => {
			this.search.focus();
		}, 250);
	}

	/**
	 * @function _doFilter
	 * @memberOf COMPONENTS.jtjListFilter
	 * @description Wrapper for filter, needed to debounce event properly
	 */
	_doFilter(event) {
		if (!this.props.isSearchFiltering) this.props.methods.handleToggleControls('isSearchFiltering');
		event.preventDefault();
		event.persist();
		this.filter(event);
		this.setState({ query: event.target.value });
	}

	/**
	 * @function _clearFilterSearch
	 * @memberOf COMPONENTS.jtjListFilter
	 * @description Clears search arguments both local and in Angular
	 * @return {object}
	 */
	_clearFilterSearch(event) {
		if (event) event.preventDefault();
		if (this.state.query.length > 0) {
			this.search.value = '';
			this.setState({ query: '' });
			this.props.methods.handleFilterSearch('');
			this.search.focus();
		} else {
			this.props.methods.handleToggleControls('isSearchFiltering');
		}
	}

	/**
	 * @function _setPreviousSearchState
	 * @memberOf COMPONENTS.jtjListFilter
	 * @description Sets previous search state based on query
	 */
	_setPreviousSearchState(query) {
		this.setState({ query: query });
		this.search.value = query;
	}

	/**
	 * @function _updateSearchStateOnMount
	 * @memberOf COMPONENTS.jtjListFilter
	 * @description Updates search state on mount by setting search state
	 * if search filter exists
	 */
	_updateSearchStateOnMount() {
		if (_has(this.props.filters, 'search') && _has(this.props.filters.search, 'query')) {
			this._setPreviousSearchState(this.props.filters.search.query);
		}
	}

	/**
	 * @function _updateSearchStateOnProps
	 * @memberOf COMPONENTS.jtjListFilter
	 * @description Updates search state on props by setting search state
	 * if search filter exists and list title is different (zoom lists)
	 */
	_updateSearchStateOnProps(nextProps) {
		var validationExpressions = [
			nextProps.title !== this.props.title,
			_has(nextProps.filters, 'search') &&
				_has(nextProps.filters.search, 'query') &&
				nextProps.filters.search.query !== this.state.query,
		];
		if (_every(validationExpressions)) {
			this._setPreviousSearchState(nextProps.filters.search.query);
		} else if (!_has(nextProps.filters, 'search') && this.props.isSearchFiltering) {
			this.setState({ query: '' });
			this.search.value = '';
		}
	}

	/**
	 * @function _renderClearButton
	 * @memberOf COMPONENTS.jtjListFilter
	 * @description Renders clear button
	 * @return {object}
	 */
	_renderClearButton() {
		if (this.props.isSearchFiltering) {
			return (
				<button className="search-filter__btn" onClick={this._clearFilterSearch}>
					<span className="search-filter__icon icon icon--clear" />
				</button>
			);
		}
	}

	/**
	 * @function _renderToggleButton
	 * @memberOf COMPONENTS.jtjListFilter
	 * @description Renders search toggle button
	 * @return {object}
	 */
	_renderToggleButton() {
		if (!this.props.isSearchFiltering) {
			return (
				<button className="search-filter__btn" onClick={this.toggleFilterSearch}>
					<span className="search-filter__icon icon icon--search" />
				</button>
			);
		}
	}

	componentDidMount() {
		this.filter = _debounce(this.filter, 600);
		this._updateSearchStateOnMount();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this._updateSearchStateOnProps(nextProps);
	}

	render() {
		if (!this.props.isCollapsed && this.props.settings.canSearch) {
			var formClassNames = cx('search-filter', 'controls__search', {
				'search-filter--enabled': this.props.isSearchFiltering,
			});
			return (
				<div className={formClassNames}>
					<input
						onChange={this._doFilter}
						ref={(input) => (this.search = input)}
						className="search-filter__input"
						type="text"
						name="search"
						placeholder={this.props.translations.SEARCH}
						autoComplete="off"
					/>
					{this._renderToggleButton()}
					{this._renderClearButton()}
				</div>
			);
		} else return null;
	}
}

SearchFilter.propTypes = {
	methods: PropTypes.object.isRequired,
	isSearchFiltering: PropTypes.bool.isRequired,
	searchFilters: PropTypes.object,
	settings: PropTypes.object.isRequired,
	translations: PropTypes.object.isRequired,
};

export default SearchFilter;
