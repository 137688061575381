import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DailyReport from './components/dailyReport.component';
import DailyReportFranchise from './components/dailyReportFranchise.component';
import { Report } from 'dumb/report';
import moment from 'moment';

// Components

class DailyReportComponent extends Component {
	_renderOwnStores() {
		const {
			dailyReport,
			expandedMarkets,
			expandedGroups,
			hotbarData,
			totalColSpan,
			forceExpandMarkets,
			forceExpandGroups,
		} = this.props;

		const subtitle = hotbarData.date.format('YYYY-MM-DD');

		const numberOfAppEntries = (dailyReport.workplaces || []).filter(
			(workplaceEntry) =>
				workplaceEntry.store_area_type.name === 'App' ||
				workplaceEntry.store_area_type.name === 'Undefined'
		).length;

		const operatingStores =
			(dailyReport?.Total?.[0]?.operation_stores ?? 0) - numberOfAppEntries;
		const title = `Daily report : Own stores (${operatingStores})`;

		return (
			<DailyReport
				reportData={dailyReport}
				expandedMarkets={expandedMarkets}
				expandedGroups={expandedGroups}
				forceExpandGroups={forceExpandGroups}
				forceExpandMarkets={forceExpandMarkets}
				title={title}
				subtitle={subtitle}
				hotbarData={hotbarData}
				totalColSpan={totalColSpan}
			/>
		);
	}

	_renderFranchiseStores() {
		const {
			franchiseReport,
			expandedMarkets,
			expandedGroups,
			hotbarData,
			forceExpandMarkets,
			forceExpandGroups,
			totalColSpan,
		} = this.props;

		const subtitle = hotbarData.date.format('YYYY-MM-DD');

		const numberOfAppEntries = (franchiseReport.workplaces || []).filter(
			(workplaceEntry) =>
				workplaceEntry.store_area_type.name === 'App' ||
				workplaceEntry.store_area_type.name === 'Undefined'
		).length;

		const operatingStores =
			(franchiseReport?.Total?.[0]?.operation_stores ?? 0) - numberOfAppEntries;
		const title = `Daily report : Franchise (${operatingStores})`;

		return (
			<DailyReportFranchise
				reportData={franchiseReport}
				expandedMarkets={expandedMarkets}
				expandedGroups={expandedGroups}
				forceExpandGroups={forceExpandGroups}
				forceExpandMarkets={forceExpandMarkets}
				hotbarData={hotbarData}
				title={title}
				subtitle={subtitle}
				totalColSpan={totalColSpan}
			/>
		);
	}

	render() {
		return (
			<Report id={this.props.printId} width={this.props.reportWidth}>
				{this._renderOwnStores()}
				{this._renderFranchiseStores()}
				<span
					style={{
						fontSize: '10px',
						color: '#2b2b2b',
						display: 'inlineBlock',
						marginTop: '15px',
					}}
				>
					{moment().format('LLLL')}
				</span>
			</Report>
		);
	}
}

DailyReportComponent.propTypes = {
	dailyReport: PropTypes.object,
	expandedMarkets: PropTypes.array,
	expandedGroups: PropTypes.array,
	franchiseReport: PropTypes.object,
	hotbarData: PropTypes.object,
	totalColSpan: PropTypes.number,
	printId: PropTypes.string,
	forceExpandMarkets: PropTypes.bool,
	forceExpandGroups: PropTypes.bool,
	reportWidth: PropTypes.number,
};

DailyReportComponent.defaultProps = {
	reportData: [],
};

export default DailyReportComponent;
