import { ActionType } from 'types/redux';
import {
	IVacationDescriptionPreset,
	IVacationescriptionPresetBatch,
} from './../types';

export const SET_VACATION_DESCRIPTION_PRESET =
	'VACATION_DESCRIPTION_PRESETS/SET_VACATION_DESCRIPTION_PRESET';
export const UPDATE_VACATION_DESCRIPTION_PRESET =
	'VACATION_DESCRIPTION_PRESETS/UPDATE_VACATION_DESCRIPTION_PRESET';
export const RESET_VACATION_DESCRIPTION_PRESET =
	'VACATION_DESCRIPTION_PRESETS/RESET_VACATION_DESCRIPTION_PRESET';

export const EDIT_BATCH_LIST_ITEM =
	'VACATION_DESCRIPTION_PRESETS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'VACATION_DESCRIPTION_PRESETS/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'VACATION_DESCRIPTION_PRESETS/ADD_BATCH_LIST_ITEM_ROW';
export const RERENDER_TABLE = 'VACATION_DESCRIPTION_PRESETS/RERENDER_TABLE';

export function setVacationDescriptionPreset(
	payload: IVacationDescriptionPreset
): ActionType {
	return {
		type: SET_VACATION_DESCRIPTION_PRESET,
		payload,
	};
}

type updateVacationDescriptionPreset = Partial<IVacationDescriptionPreset>;
export function updateVacationDescriptionPreset(
	payload: updateVacationDescriptionPreset
): ActionType {
	return {
		type: UPDATE_VACATION_DESCRIPTION_PRESET,
		payload,
	};
}

export function resetVacationDescriptionPreset(): ActionType {
	return {
		type: RESET_VACATION_DESCRIPTION_PRESET,
	};
}

type editBatchListItem = Partial<IVacationescriptionPresetBatch>;
export function editBatchListItem(payload: editBatchListItem): ActionType {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload: string): ActionType {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow(): ActionType {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}

export function rerenderTable(): ActionType {
	return {
		type: RERENDER_TABLE,
	};
}
