import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';
import TileSizesList from './services/tileSizesList.service.js';
import TilePictureAssetsList from './services/tilePictureAssetsList.service.js';

const tileSizesListReduxKey = '/pos/tile_sizes';
const tileSizesList = new TileSizesList(tileSizesListReduxKey);
const tileSizesListProps = tileSizesList.getProps();

const tilePictureAssetsListReduxKey = '/pos/tile_picture_assets';
const tilePictureAssetsList = new TilePictureAssetsList(tilePictureAssetsListReduxKey);
const tilePictureAssetsListProps = tilePictureAssetsList.getProps();

export function TileConfigurations() {
	useEffect(() => {
		tileSizesList.initView();
	}, []);

	return (
		<>
			<ListComponent reduxKey={tileSizesListReduxKey} {...tileSizesListProps} />
			<ListComponent reduxKey={tilePictureAssetsListReduxKey} {...tilePictureAssetsListProps} />
		</>
	);
}
