'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// redux
import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import { setAssetCollection, updateAssetCollection, resetAssetCollection } from './store/assetCollection.actions';

// lodash
import _get from 'lodash/get';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Input, Button, Toggle, Tabs } from 'dumb';
import AssetLeftAligned from './components/assetsLeftAligned/assetLeftAligned.component';
import SubTable from 'reactDataWrapper/components/subTable';
import Products from './../../../product/components/product/product.component';
import ProductVariants from './../../../product/components/productVariant/productVariant.component';

// services
import {
	fetchAllAssetCollections,
	editAssetCollections,
	addAssetCollections,
	deleteAssetCollections,
} from './store/assetCollection.service';

// phrases
import phrases from './assetCollection.phrases';

class AssetCollection extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);

		this.state = {
			pages: null,
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this.fetchData = this.fetchData.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'desc',
				accessor: (d) => _get(d, 'description', ''),
				filterPath: ':description',
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: 'legacy',
				filterPath: ':legacy',
				filterable: false,
				Cell: (d) => {
					return <Input type="checkbox" checked={d.original.legacy} disabled />;
				},
			},
		];
	}

	deleteEntry(id) {
		return deleteAssetCollections(id);
	}

	editEntry() {
		const { defaultValues } = this.props;

		const payload = {
			name: _get(defaultValues, 'name', ''),
			description: _get(defaultValues, 'description', ''),
			legacy: !!defaultValues.legacy,
		};

		return editAssetCollections(defaultValues.id, payload);
	}

	addEntry() {
		const { defaultValues } = this.props;

		const payload = {
			name: _get(defaultValues, 'name', ''),
			description: _get(defaultValues, 'description', ''),
		};

		return addAssetCollections(payload);
	}

	setInitialEditValues(data) {
		this.props.setAssetCollection(data);
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}>
				Legacy
			</Button>
		);
	}

	getEditableCells() {
		const { defaultValues } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="name"
						value={_get(defaultValues, 'name', '')}
						onChange={(event) => this.editStoreEntry(event, 'name')}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="description"
						value={_get(defaultValues, 'description', '')}
						onChange={(event) => this.editStoreEntry(event, 'description')}
					/>
				),
			},
			{
				header: 'Legacy',
				value: (
					<Toggle
						id="legacy"
						toggled={defaultValues.legacy}
						onClick={(event) => this.editStoreEntry(event, 'legacy')}
					/>
				),
			},
		];
	}

	editStoreEntry(e, name) {
		const value = e.target ? e.target.value : e;

		const updateObject = {
			[name]: value,
		};

		this.props.updateAssetCollection(updateObject);
	}

	fetchData(state) {
		return fetchAllAssetCollections(state);
	}

	render() {
		const legacyFilter = this.state.legacyFilterOn ? this.state.legacyFilter : '';

		return (
			<ReactDataWrapper
				accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.ASSET_COLLECTION}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={10}
				reduxKey="pos/asset_collections"
				manual
				subcomponent={(row) => {
					const tabContent = [
						{
							name: phrases.ASSETS,
							component: <AssetLeftAligned title={phrases.ASSETS} collection={row.original} />,
						},
						{
							name: phrases.PRODUCTS,
							component: <Products assetCollectionId={row.original.id} />,
						},
						{
							name: phrases.PRODUCT_VARIANTS,
							component: <ProductVariants assetCollectionId={row.original.id} />,
						},
					];

					return (
						<SubTable>
							<Tabs tabContent={tabContent} />
						</SubTable>
					);
				}}
				editEntry={(e) => this.editEntry(e)}
				editableCells={this.getEditableCells()}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetAssetCollection}
				actionsWidth={60}
				deleteEntry={this.deleteEntry}
				createEntry={this.addEntry}
				customAreaComponents={this.getLegacyFilterButton()}
				extraFilters={legacyFilter}
			/>
		);
	}
}

AssetCollection.propTypes = {
	defaultValues: PropTypes.object,

	updateAssetCollection: PropTypes.func,
	setAssetCollection: PropTypes.func,
	resetAssetCollection: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setAssetCollection,
			updateAssetCollection,
			resetAssetCollection,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultValues: store.assetCollection.data.defaultAssetCollections,
	};
};

export default connectWithStore(AssetCollection, mapStateToProps, mapDispatchToProps);
