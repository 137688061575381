export default {
	NUTRITION_VALUES: 'Nutrition Values',
	GLOBAL_NUTRITION_VALUES: 'Global nutrition values',

	NUTRITION_VALUES_ADDED: 'Nutrition values added',
	NUTRITION_VALUE_EDITED: 'Nutrition value edited',
	NUTRITION_VALUES_EDITED: 'Nutrition values edited',
	NUTRITION_VALUES_DELETED: 'Nutrition values deleted',

	MODAL_BATCH_STEP_MARKET: 'Markets',
	MODAL_BATCH_STEP_MARKET_PLACEHOLDER: 'Select markets...',
	MODAL_BATCH_STEP_MARKET_TABLE_TITLE: 'Markets',
	MODAL_BATCH_STEP_INGREDIENTS: 'Ingredients',
	MODAL_BATCH_STEP_INGREDIENTS_PLACEHOLDER: 'Select ingredients...',
	MODAL_BATCH_STEP_INGREDIENTS_TABLE_TITLE: 'Ingredients',
	MODAL_BATCH_STEP_NUTRITION_CATEGORIES: 'Nutrition categories',
	MODAL_BATCH_STEP_NUTRITION_CATEGORIES_PLACEHOLDER: 'Select nutrition categories...',
	MODAL_BATCH_STEP_NUTRITION_CATEGORIES_TABLE_TITLE: 'Nutrition categories',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'Next',
	MODAL_BATCH_STEP_FORM_TITLE: 'Select entries',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_LIST_INGREDIENT: 'Ingredient',
	MODAL_BATCH_STEP_LIST_NUTRITION_CATEGORY: 'Nutrition category',
	MODAL_BATCH_STEP_LIST_VALUE: 'Value',
	MODAL_BATCH_STEP_VALUE_PLACEHOLDER: 'Enter value...',
	MODAL_BATCH_STEP_LIST_TITLE: 'Confirm entries',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Submit',
	MODAL_BATCH_STEP_LIST_DELETE_ROW_BUTTON_LABEL: 'Submit',
	MODAL_BATCH_STEP_BACK_BUTTON_LABEL: 'Back',
	MODAL_BATCH_STEP_LIST_LEGEND_CANT_ADD: 'Nutrition value already exists',
};
