'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';

// different batch views
import NutritionValuesBatchStepForm from './components/nutritionalValuesBatchStepForm';
import NutritionValuesBatchStepList from './components/nutritionValuesBatchStepList';

// phrases/ constants
import phrases from './../../nutritionValues.phrases';

// utils
import { getFilter, markValuesWithCanAdd } from './../../utils';

// loash
import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';

class NutritionValuesModalBatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};

		this._getIsBatchStepFormValid = this._getIsBatchStepFormValid.bind(this);
		this._onSubmitForm = this._onSubmitForm.bind(this);
		this._batchSubmit = this._batchSubmit.bind(this);

		this.modalRef = React.createRef();
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_onSubmitForm() {
		const { batchFormData, addBatchList, fetchData, useGlobalResource } = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const mappedIngredientValues = batchFormData.ingredients.reduce((acc, currentValue, index) => {
			const values = batchFormData.nutritionCategories.map((entry) => ({
				ingredient: currentValue,
				nutritionCategory: entry,
			}));

			return [...acc, ...values];
		}, []);

		// map ingredient values with markets
		const ingredientValuesMarkets = mappedIngredientValues.reduce((acc, currentValue) => {
			let values = [
				{
					...currentValue,
					identifier: _uniqueId('row-'),
					market: null,
				},
			];

			if (!_isEmpty(batchFormData.markets) && !useGlobalResource) {
				values = batchFormData.markets.map((entry) => ({
					...currentValue,
					identifier: _uniqueId('row-'),
					market: entry,
				}));
			}

			return [...acc, ...values];
		}, []);

		// get already existing values
		const filter = getFilter({
			mappedNutritionValues: ingredientValuesMarkets,
		});
		fetchData({ filter })
			.then((res) => {
				let markedValues = markValuesWithCanAdd({
					existingValues: res.data,
					newValues: ingredientValuesMarkets,
				});

				// sort values to always show ones wa can add first
				markedValues = markedValues.sort((a) => {
					if (a.canAdd) return -1;
				});

				addBatchList(markedValues);

				this._goToStep(2);
			})
			.finally(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	_batchSubmit() {
		const { batchListData, handleClose, batchAddData, useGlobalResource } = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchListData
			.filter((entry) => entry.canAdd)
			.map((entry) => ({
				...(!useGlobalResource && {
					market: _get(entry, 'market.value.id', null),
				}),
				ingredient: _get(entry, 'ingredient.value.id', null),
				nutrition_category: _get(entry, 'nutritionCategory.value.id', null),
				value: entry.value,
			}));

		batchAddData({ batch: payload }, useGlobalResource)
			.then(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
				handleClose();
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	_getIsBatchStepFormValid() {
		const { batchFormData, useGlobalResource } = this.props;

		const ingredientsEmpty = _isEmpty(batchFormData.ingredients);
		const nutritionCategoriesEmpty = _isEmpty(batchFormData.nutritionCategories);
		const marketsEmpty = _isEmpty(batchFormData.markets);

		if (useGlobalResource) {
			return !ingredientsEmpty && !nutritionCategoriesEmpty;
		}

		if (ingredientsEmpty || nutritionCategoriesEmpty || marketsEmpty) return false;
		return true;
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchListData,
			editBatchListItem,
			removeBatchListItem,
			useGlobalResource,
		} = this.props;

		const steps = [
			{
				component: (
					<NutritionValuesBatchStepForm
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						useGlobalResource={useGlobalResource}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE,
				isValid: this._getIsBatchStepFormValid(),
				onNext: this._onSubmitForm,
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				noScroll: false,
				allowOverflow: true,
			},
			{
				component: (
					<NutritionValuesBatchStepList
						batchListData={batchListData}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
						loyaltyProductUrl={this.loyaltyProductUrl}
						useGlobalResource={useGlobalResource}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: this._batchSubmit,
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return modalVisible ? (
			<ModalStepper ref={this.modalRef} isOpen={modalVisible} steps={steps} showStep={false} onClose={handleClose} />
		) : null;
	}
}

NutritionValuesModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	addBatchList: PropTypes.func,
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	batchAddData: PropTypes.func,
	fetchData: PropTypes.func,
	useGlobalResource: PropTypes.bool,
};

export default NutritionValuesModalBatch;
