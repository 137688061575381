import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Tools
import _isEmpty from 'lodash/isEmpty';
// import groupSortOrder from './../utilities/groupSortOrder';

import DailyReportHeaderBaseModel from './dailyReportHeaderBaseModel.component';
import DailyReportContinent from './dailyReportContinent.component';
import DailyReportTotal from './dailyReportTotal.component';
import DailyReportBaseModel from './dailyReportBaseModel.component';

// PureComponents

import { Body, Table, Title, Row, constants } from 'dumb/report';

const TotalGroupEntries = ({ entries, perspective }) => {
	if (!entries) return null;
	return entries.map((entry) => {
		const newEntry = {
			...entry,
			...(entry.group === 'New Store' && {
				month_same_store_sales: {},
				week_same_store_sales: {},
			}),
		};

		return (
			<Row key={entry.id} type={constants.TABLE_TYPES.SUB_TOTAL}>
				<DailyReportBaseModel entry={newEntry} reportType={perspective} />
			</Row>
		);
	});
};

class DailyReport extends PureComponent {
	_renderReport() {
		const {
			reportData,
			hotbarData,
			totalColSpan,
			expandedMarkets,
			expandedGroups,
			phrases,
			forceExpandMarkets,
			forceExpandGroups,
		} = this.props;

		if (_isEmpty(reportData))
			return <div>{phrases.NO_DATA_IN_SELECTED_PERIOD}</div>;

		const continents = reportData.continents.map((entry) => {
			return (
				<DailyReportContinent
					key={entry.id}
					hotbarData={hotbarData}
					totalColSpan={totalColSpan}
					expandedMarkets={expandedMarkets}
					expandedGroups={expandedGroups}
					forceExpandGroups={forceExpandGroups}
					forceExpandMarkets={forceExpandMarkets}
					marketRegions={reportData.marketRegions.filter(
						(reportDataMarketRegion) =>
							reportDataMarketRegion.continent.id === entry.continent.id
					)}
					total={entry}
					reportData={reportData}
				/>
			);
		});

		return continents;
	}

	render() {
		const { title, hotbarData, totalColSpan, reportData, subtitle } =
			this.props;

		return (
			<>
				<Title title={title} />
				<Title title={subtitle} rightAligned />
				<Table marginBottom>
					<DailyReportHeaderBaseModel
						reportType={hotbarData.perspective}
						firstColumnEntry="Markets"
					/>
					<Body>{this._renderReport()}</Body>

					<DailyReportTotal
						hotbarData={hotbarData}
						totalColSpan={totalColSpan}
						reportData={reportData.Total}
					/>
					<Body>
						<TotalGroupEntries
							perspective={this.props.hotbarData.perspective}
							entries={reportData.totalGroups}
						/>
						<TotalGroupEntries
							perspective={this.props.hotbarData.perspective}
							entries={reportData.totalStoreTypeStoreAreaTypeEntry}
						/>
					</Body>
				</Table>
			</>
		);
	}
}

DailyReport.propTypes = {
	reportData: PropTypes.object,
	hotbarData: PropTypes.object,
	phrases: PropTypes.object,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	totalColSpan: PropTypes.number,
	expandedMarkets: PropTypes.array,
	expandedGroups: PropTypes.array,
	forceExpandMarkets: PropTypes.bool,
	forceExpandGroups: PropTypes.bool,
};

DailyReport.defaultProps = {
	subtitle: '',
};

export default DailyReport;
