'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, Icon, InputCollectionSelect } from 'dumb';
import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';
import { ModalRow } from 'dumb/modal';
import { DateFilterSelector } from 'reactDataWrapper/utilities';

// phrases/ constants/ emums
import phrases from '../../../marketShiftAlignmentRules.phrases';
import enums from './../../../marketShiftAlignmentRules.enums';

// utils
import { getSelectOptionsFromEnums } from 'services/utils';

// Tools
import _get from 'lodash/get';

class MarketShiftAlignmentRulesBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		const value = e?.target?.value ?? e;

		// edits the row in the store
		this.props.editBatchListItem({
			identifier: row.identifier,
			[type]: value,
		});
	}

	render() {
		const { batchList, addBatchListItemRow } = this.props;

		return (
			<>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_MARKET}</th>
							<th>{phrases.MODAL_BATCH_STEP_MINIMUM_DEVIATION}</th>
							<th>{phrases.MODAL_BATCH_STEP_MAXIMUM_DEVIATION}</th>
							<th>{phrases.MODAL_BATCH_STEP_REGISTRATION_TYPE}</th>
							<th>{phrases.MODAL_BATCH_STEP_ALIGNMENT_STRATEGY}</th>

							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((entry, i) => {
							const deleteButtonDisabled = batchList.length === 1;

							return (
								<TableListRow key={entry.identifier}>
									<TableListCell>{i + 1}</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id={`name-${entry.identifier}`}
											clearable={false}
											placeholder="Select market..."
											value={entry?.market || null}
											handleChange={(key, e) =>
												this._editListEntry(e, entry, 'market')
											}
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											required
											apiPath="/organization/markets"
											tableTitle="Markets"
											tableColumns={[
												{
													Header: 'Name',
													id: 'name',
													accessor: 'name',
													filterPath: ':name',
												},
												{
													Header: 'Country',
													id: 'country',
													accessor: (d) => _get(d, 'country.name', ''),
													filterPath: ':country.name',
												},
												{
													Header: 'Market region',
													id: 'market_region',
													accessor: (d) => _get(d, 'market_region.name', ''),
													filterPath: ':market_region.name',
												},
												{
													Header: 'Tax label',
													id: 'tax_label',
													accessor: 'tax_label',
													filterPath: ':tax_label',
												},
												{
													Header: 'Opened',
													id: 'opened',
													accessor: 'opened',
													filterPath: ':opened',
													Filter: ({ column }) => (
														<DateFilterSelector
															reduxKey="/administration/markets_market-alignment-rules-batch-modal"
															columnId={column.id}
															dateIdentifier="from"
															zIndex={600}
														/>
													),
												},
												{
													Header: 'Closed',
													id: 'closed',
													accessor: 'closed',
													filterPath: ':closed',
													Filter: ({ column }) => (
														<DateFilterSelector
															reduxKey="/administration/markets_market-alignment-rules-batch-modal"
															columnId={column.id}
															dateIdentifier="to"
															zIndex={600}
														/>
													),
												},
											]}
											tableReduxKey="/administration/markets_market-alignment-rules-batch-modal"
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`minimum-deviation-${entry.identifier}`}
											type="number"
											clearable={false}
											placeholder="Enter minumum deviation..."
											value={entry.minimumDeviation}
											required
											onChange={(event) =>
												this._editListEntry(event, entry, 'minimumDeviation')
											}
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`maximum-deviation-${entry.identifier}`}
											type="number"
											clearable={false}
											placeholder="Enter maximum deviation..."
											value={entry.maximumDeviation}
											required
											onChange={(event) =>
												this._editListEntry(event, entry, 'maximumDeviation')
											}
										/>
									</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											placeholder="Select registration type..."
											id={`registration-type-${entry.identifier}`}
											clearable={false}
											required
											options={getSelectOptionsFromEnums(
												enums.registrationType
											)}
											onChange={(event) =>
												this._editListEntry(event, entry, 'registrationType')
											}
											value={_get(entry, 'registrationType', null)}
										/>
									</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											placeholder="Select alignment strategy..."
											id={`alignment-strategy--${entry.identifier}`}
											clearable={false}
											required
											options={getSelectOptionsFromEnums(
												enums.alignmentStrategy
											)}
											onChange={(event) =>
												this._editListEntry(event, entry, 'alignmentStrategy')
											}
											value={_get(entry, 'alignmentStrategy', null)}
										/>
									</TableListCell>

									<TableListCell>
										<Button
											type="negative"
											size="tiny"
											disabled={deleteButtonDisabled}
											onClick={() => this._onRemoveEntry(entry.identifier)}>
											<Icon name="delete" />
										</Button>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>

				<ModalRow
					typeAddNewRow
					tooltipZIndex={600}
					tooltipText={phrases.MODAL_BATCH_STEP_LIST_TOOLTIP}
					onClick={addBatchListItemRow}
				/>
			</>
		);
	}
}

MarketShiftAlignmentRulesBatchStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
};

export default MarketShiftAlignmentRulesBatchStepList;
