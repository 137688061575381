import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DayPickerRangeController from 'react-dates/lib/components/DayPickerRangeController';
import ReactDOM from 'react-dom';
import moment from 'moment';
import './dayPickerRange.css';

export default class DayPickerRange extends Component {
	constructor(props) {
		super(props);

		// Create portal wrapper
		this.node = document.createElement('div');
		this.node.classList.add('tes321t');

		document.body.appendChild(this.node);

		// Bind methods
		this._onDatesChange = this._onDatesChange.bind(this);
		this._renderPastYears = this._renderPastYears.bind(this);
		this._renderFutureYears = this._renderFutureYears.bind(this);
		this._setEndDate = this._setEndDate.bind(this);
		this._setStartDate = this._setStartDate.bind(this);
		this._setWeekRangeDates = this._setWeekRangeDates.bind(this);

		// Stats
		this.state = {
			focusedInput: null,
		};
	}

	componentWillUnmount() {
		document.body.removeChild(this.node);
	}

	_setWeekRangeDates(startDate, endDate) {
		if (
			this.props.startDate &&
			this.props.endDate &&
			this.props.startDate.format('YYYY-MM-DD') !== this.props.endDate.format('YYYY-MM-DD')
		) {
			startDate = endDate.clone().startOf('isoWeek');
			endDate = endDate.clone().startOf('isoWeek');
		}

		if (endDate.isBefore(startDate)) {
			startDate = endDate.clone().startOf('isoWeek');
			endDate = endDate.clone().startOf('isoWeek');
		}

		if (startDate && endDate && startDate.format('YYYY-MM-DD') !== endDate.format('YYYY-MM-DD')) {
			// Close modal
			this.props.onOutsideClick(false);
		}

		this.props.onDatesChange({ startDate, endDate });
	}

	_setWeekRange(startDate, endDate) {
		this.props.onDatesChange({ startDate, endDate });
		// Close modal
		this.props.onOutsideClick(false);
	}

	_onDatesChange({ startDate, endDate }) {
		if (this.props.type === 'range') {
			return this._setWeekRangeDates(startDate, endDate);
		}
		return this._setWeekRange(startDate);
	}

	_renderCustomCaption({ month, onMonthSelect, onYearSelect }) {
		return (
			<div className="j-day-picker-range-controller__header">
				<select
					value={month.month()}
					onChange={(e) => {
						onMonthSelect(month, e.target.value);
					}}
				>
					{moment.months().map((label, value) => (
						<option key={value} value={value}>
							{label}
						</option>
					))}
				</select>
				<select
					value={month.year()}
					onChange={(e) => {
						onYearSelect(month, e.target.value);
					}}
				>
					{this._renderPastYears()}
					{this._renderFutureYears()}
				</select>
			</div>
		);
	}

	_renderPastYears() {
		const years = [];
		for (let i = 65; i >= 0; i--) {
			years.push(
				<option key={i} value={moment().year() - i}>
					{moment().year() - i}
				</option>
			);
		}
		return years;
	}

	_renderFutureYears() {
		const years = [];
		for (let i = 1; i <= 10; i++) {
			years.push(
				<option key={i + 'i'} value={moment().year() + i}>
					{moment().year() + i}
				</option>
			);
		}
		return years;
	}

	_setStartDate(day) {
		const { type, startDate } = this.props;

		if (type !== 'range') {
			return day.startOf('isoweek');
		}

		return startDate.startOf('isoweek');
	}

	_setEndDate(day) {
		const { type, offsetWeekNumber } = this.props;

		if (type !== 'range') {
			const endDay = day
				.clone()
				.add(offsetWeekNumber - 1, 'w')
				.endOf('isoweek');
			return endDay;
		}

		return day.endOf('isoweek');
	}

	render() {
		const { onOutsideClick, position, startDate, endDate, headerDatePicker, showWeekNumber } = this.props;

		return ReactDOM.createPortal(
			<div className="j-day-picker-range-controller" style={{ ...position }}>
				<DayPickerRangeController
					startDate={startDate} // momentPropTypes.momentObj or null,
					endDate={endDate} // momentPropTypes.momentObj or null,
					onDatesChange={this._onDatesChange} // PropTypes.func.isRequired,
					startDateOffset={(day) => this._setStartDate(day)}
					endDateOffset={(day) => this._setEndDate(day)}
					focusedInput={'startDate'} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
					onOutsideClick={(event) => onOutsideClick(false, event)} // PropTypes.func.isRequired,
					firstDayOfWeek={1}
					renderMonthElement={
						headerDatePicker &&
						((month, onMonthSelect, onYearSelect) => this._renderCustomCaption(month, onMonthSelect, onYearSelect))
					}
					renderDayContents={(day) => {
						return (
							<React.Fragment>
								{showWeekNumber && day.isSame(day.clone().startOf('isoweek'), 'day') && (
									<span className="j-day-picker-range-controller__week-number">{day.format('W')}</span>
								)}
								{day.format('D')}{' '}
							</React.Fragment>
						);
					}}
					keepOpenOnDateSelect
					hideKeyboardShortcutsPanel
				/>
			</div>,
			this.node
		);
	}
}

DayPickerRange.propTypes = {
	startDate: PropTypes.object.isRequired,
	endDate: PropTypes.object,
	onDatesChange: PropTypes.func.isRequired,
	// focusedInput: PropTypes.PropTypes.oneOf(['START_DATE', 'END_DATE']),
	// onFocusChange: PropTypes.func.isRequired,
	onOutsideClick: PropTypes.func.isRequired,
	offsetWeekNumber: PropTypes.number,
	showWeekNumber: PropTypes.bool,
	position: PropTypes.object,
	headerDatePicker: PropTypes.bool,
	type: PropTypes.string,
};
