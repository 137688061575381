'use strict';

import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';

import './checkbox.css';

class CheckBox extends PureComponent {
	render() {
		const {
			label,
			labelPosition,
			onClick,
			checked,
			disabled,
			name,
			noBorder,
			className,
			size
		} = this.props;
		
		return (
			<label
				className={cx('checkbox-legacy', {
					[`checkbox-${labelPosition}`]: labelPosition,
					'checkbox--disabled': disabled,
					[className]: className
				})}>
				{label}
				<input
					disabled={disabled}
					checked={checked}
					onClick={onClick}
					onChange={() => {}}
					name={name || 'overwrite'}
					type="checkbox"
				/>
				<span
					className={cx('checkbox-legacy__tick', {
						'checkbox-legacy__tick--no-border': noBorder,
						[`checkbox-legacy__tick--${size.toLowerCase()}`]: size
					})}
				/>
			</label>
		);
	}
}

CheckBox.defaultProps = {
	label: null,
	labelPosition: null,
	checked: false,
	disabled: false,
	onClick: function() {},
	noBorder: false,
	size: 'medium'
};

CheckBox.propTypes = {
	label: PropTypes.string,
	labelPosition: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	noBorder: PropTypes.bool,
	name: PropTypes.string,
	className: PropTypes.string,
	size: PropTypes.string
};

export default CheckBox;
