import React, { useEffect, useContext, useCallback } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

import store, { persistor } from 'appState/store';

// Actons
import { setAccessToken } from 'services/user/actions';
import { showPageLoader } from 'smart/pageLoader/store/pageLoader.actions';

import {
	PageLoader,
	ClockinDeviationApproval,
	ShiftTransferApproval,
	AppInit,
	InvalidTenantAccount,
	RemoveShiftsFromEmployee,
} from 'smart';
import { Drawer } from 'smart/drawer';
import Appbar from 'smart/appbar/appbar';
import { Box } from '@mui/material';
import { ModalConfirm } from 'dumb';
import ErrorBoundary from './errorBoundary';

// Utils
import { JoeRouterProvider } from './config/routerProvider';
import { useLocation } from 'react-router';
import { withScope, captureException, captureMessage } from '@sentry/browser';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import SendDocuSignDocument from 'common/components/sendDocuSignDocument/sendDocuSignDocument.component.jsx';
import { Helmet } from 'react-helmet';

function App() {
	const {
		accessToken,
		powerBIReports,
		tenants,
		activeTenant,
		activeUser,
		drawerShown,
	} = useSelector((state) => ({
		accessToken: state?.userData?.accessToken,
		powerBIReports: state?.powerBIReport?.reports,
		tenants: state?.userData?.tenants,
		activeTenant: state?.userData?.activeTenant,
		activeUser: state.userData?.user,
		drawerShown: state.appSettings.drawerShown,
	}));
	const { routes, filteredRoutes } = useContext(JoeRouterProvider);
	const { pathname } = useLocation();
	const { isAuthenticated, getAccessTokenSilently, logout, user } = useAuth0();
	const userId = user?.sub.split('|')[1];

	const getToken = useCallback(async () => {
		try {
			// eslint-disable-next-line camelcase
			const { access_token } = await getAccessTokenSilently({
				detailedResponse: true,
			});

			store.dispatch(setAccessToken(access_token));

			// catch potential mfa error (page still gets infinite reload for some reason. withAuthenticationRequired HOC doesnt seem to respect onRedirectCallback key and keeps redirecting regardless). Take a look at Auth0ProviderWithHistory -> onRedirectCallback
		} catch (error) {
			console.error(error);
			console.log('Error when getting token');

			if (error.message.toLowerCase().indexOf('multifactor') !== -1) {
				withScope((scope) => {
					scope.setExtra('error', error);
					captureMessage('Error when getting token', error);
					captureException(error);
				});
				setFeedback(
					'You need to enable multi factor authentication for your user. Please contact HR',
					0
				);
			}
			logout();
		}
	}, [getAccessTokenSilently]);

	// fetch token when we authenticate
	useEffect(() => {
		// don't get token when app in test mode
		if (process.env.CYPRESS_TEST === 'true' || !isAuthenticated) return;

		getToken();
	}, [getToken, isAuthenticated]);

	const handleLogout = useCallback(() => {
		showPageLoader(true, 'Bye bye');
		persistor.purge();
		logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		});
	}, [logout]);

	// remove all padding if we're on a power bi report page
	const getStyles = useCallback(() => {
		return pathname.includes('/reports/power-bi-report/')
			? undefined
			: (theme) => ({
					p: theme.spacing(2),
					pt: theme.spacing(3),
			  });
	}, [pathname]);

	return (
		<Box
			component="main"
			sx={(theme) => ({
				paddingLeft: {
					md: drawerShown
						? `calc(${theme.settings.drawerWidth}px)`
						: theme.spacing(7),
				},
				transition: theme.transitions.create('padding-left', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.enteringScreen,
				}),
			})}
		>
			<Appbar activeUser={activeUser} />

			<Box sx={getStyles()}>
				<Drawer
					activeUser={activeUser}
					filteredRoutes={filteredRoutes}
					userId={userId}
					tenants={tenants}
					activeTenant={activeTenant}
					accessToken={accessToken}
					isAuthenticated={isAuthenticated}
					powerBIReports={powerBIReports}
					handleLogout={handleLogout}
				/>

				<div>
					<div>
						<Helmet>
							<title>Workplanner &ndash; Joe &amp; The Juice</title>
						</Helmet>

						{/* all routing comes from routes object */}
						<ErrorBoundary>{routes}</ErrorBoundary>
					</div>

					<AppInit />
					<ClockinDeviationApproval />
					<SendDocuSignDocument />
					<ShiftTransferApproval />
					<InvalidTenantAccount />
					<RemoveShiftsFromEmployee />
					<ModalConfirm />
				</div>
			</Box>
		</Box>
	);
}

const GuardedApp = withAuthenticationRequired(App, {
	onRedirecting: () => <PageLoader />,
});

export { App, GuardedApp };
