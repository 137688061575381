import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './posTiers.phrases';

/**
 * @function fetchPosTiers
 * @description Fetch all pos tiers
 * @param {object} state
 */
export function fetchPosTiers(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/pos/tiers', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addPosTiers
 * @description adds pos tiers
 */
export function addPosTiers(data) {
	return post('/pos/tiers', data)
		.then((res) => {
			setFeedback(phrases.POS_TIERS_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editPosTier
 * @description edits pos tier
 */
export function editPosTier(data) {
	return post(`/pos/tiers/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.POS_TIER_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editPosTiers
 * @description edits pos tiers
 */
export function editPosTiers(data) {
	return patch('/pos/tiers', data)
		.then((res) => {
			setFeedback(phrases.POS_TIERS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deletePosTiers
 * @description deletes pos tiers
 */
export function deletePosTiers(productId) {
	return remove(`/pos/tiers/${productId}`)
		.then((res) => {
			setFeedback(phrases.POS_TIERS_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
