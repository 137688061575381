'use strict';

import EmailData from './emailData.emailData';
import Subject from './emailData.subject';
import Body from './emailData.body';

class EmailDataFactory {

	/**
	 * @function emailData
	 * @memberOf EmailDataFactory
	 * @param  {object} subject
	 * @param  {object} body
	 * @param  {object} toMailingListDataProvider
	 */
	emailData(subject, body, toMailingListDataProvider) {
		return new EmailData(subject, body, toMailingListDataProvider);
	}

	/**
	 * @function subject
	 * @memberOf EmailDataFactory
	 * @param  {*} template
	 */
	subject(template) {
		return new Subject(template);
	}

	/**
	 * @function body
	 * @memberOf EmailDataFactory
	 * @param  {*} template
	 */
	body(template) {
		return new Body(template);
	}

}

export default EmailDataFactory;