import React from 'react';
import PropTypes from 'prop-types';

// components
import PersonAttributesTable from './components/personAttributesTable/personAttributesTable';
import Users from './components/usersTable/usersTable';
import JuicerOperationalResponsibilities from './components/juicerOperationalResponsibilities/juicerOperationalResponsibilities.component';

import { Tabs } from 'dumb';

// phrases
import phrases from './attributesUsersWrapper.phrases';

const AttributesUsersWrapper = ({ personId, personData }) => {
	const tabContent = [
		{
			name: phrases.USERS,
			component: <Users personId={personId} personData={personData} />,
		},
		{
			name: phrases.PERSON_ATTRIBUTES,
			component: (
				<PersonAttributesTable personId={personId} personData={personData} />
			),
		},
		{
			name: phrases.JUICER_OPERATIONAL_RESPONSIBILITIES,
			component: (
				<JuicerOperationalResponsibilities
					personId={personId}
					personData={personData}
				/>
			),
		},
	];

	return (
		<Tabs
			useLocalStorage
			tabContent={tabContent}
			id="juicerAttributesShiftsWrapper"
		/>
	);
};

AttributesUsersWrapper.propTypes = {
	personId: PropTypes.number,
	personData: PropTypes.object,
};

export default AttributesUsersWrapper;
