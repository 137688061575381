'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Icon, Input } from 'dumb';
import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';

// phrases
import phrases from '../../../ingredientTranslations.phrases';

class IngredientTranslationsBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		const value = e?.target?.value ?? e;

		// edits the row in the store
		this.props.editBatchListItem({
			identifier: row.identifier,
			[type]: value,
		});
	}

	render() {
		const { batchList } = this.props;

		return (
			<>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_LANGUAGE}</th>
							<th>{phrases.MODAL_BATCH_STEP_LIST_NAME}</th>
							<th>{phrases.MODAL_BATCH_STEP_LIST_DESCRIPTION}</th>

							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((entry, i) => {
							const deleteButtonDisabled = batchList.length === 1;

							return (
								<TableListRow key={entry.identifier}>
									<TableListCell>{i + 1}</TableListCell>

									<TableListCell>
										<span>{entry.language?.label ?? ''}</span>
									</TableListCell>

									<TableListCell>
										<Input
											id={`name-${entry.identifier}`}
											value={entry.name ?? ''}
											placeholder={
												phrases.MODAL_BATCH_STEP_LIST_NAME_PLACEHOLDER
											}
											onChange={(e) => this._editListEntry(e, entry, 'name')}
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`description-${entry.identifier}`}
											value={entry.description ?? ''}
											placeholder={
												phrases.MODAL_BATCH_STEP_LIST_DESCRIPTION_PLACEHOLDER
											}
											onChange={(e) =>
												this._editListEntry(e, entry, 'description')
											}
										/>
									</TableListCell>

									<TableListCell>
										<Button
											type="negative"
											size="tiny"
											disabled={deleteButtonDisabled}
											onClick={() => this._onRemoveEntry(entry.identifier)}>
											<Icon name="delete" />
										</Button>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
			</>
		);
	}
}

IngredientTranslationsBatchStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

export default IngredientTranslationsBatchStepList;
