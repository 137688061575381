import {
	UPDATE_JUICER_OPERATIONAL_RESPONSIBILITY,
	RESET_JUICER_OPERATIONAL_RESPONSIBILITY,
} from './juicerOperationalResponsibilities.actions';

const defaultState = {
	data: {
		juicerOperationalResponsibilities: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case UPDATE_JUICER_OPERATIONAL_RESPONSIBILITY: {
			return {
				data: {
					...state.data,
					juicerOperationalResponsibilities: {
						...state.data.juicerOperationalResponsibilities,
						...payload,
					},
				},
			};
		}

		case RESET_JUICER_OPERATIONAL_RESPONSIBILITY: {
			return defaultState;
		}

		default:
			return state;
	}
}

export default reducer;