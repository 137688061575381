'use strict';

import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchProductVariantIngredients
 * @description Fetch addon ingredients (associations)
 * @param {number} addonId
 * @param {object} state
 */
export function fetchProductVariantIngredients(productVariantId, state) {
	const preFilter = `:product_variant.id=='${productVariantId}'`;

	const filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(
		`/product/product_variant_ingredients`,
		params,
		null,
		state.headers
	)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editProductVariantIngredient
 * @description edit
 */
export function editProductVariantIngredient(ingredientId, data) {
	return post(`/product/product_variant_ingredients/${ingredientId}`, data)
		.then((res) => {
			setFeedback('Ingredient edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editProductVariantIngredients
 * @description edit multiple
 */
export function editProductVariantIngredients(data) {
	return patch('/product/product_variant_ingredients', data)
		.then((res) => {
			setFeedback('Ingredients edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function addProductVariantIngredient
 * @description add
 */
export function addProductVariantIngredient(data) {
	return post(`/product/product_variant_ingredients`, data)
		.then((res) => {
			setFeedback('Ingredient added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteProductVariantIngredient
 * @description delete
 */
export function deleteProductVariantIngredient(ingredientId) {
	return remove(`/product/product_variant_ingredients/${ingredientId}`)
		.then((res) => {
			setFeedback('Ingredient deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
