// lodash
import _get from 'lodash/get';

export default function getColumns() {
	return [
		{
			Header: 'Title',
			id: 'title',
			accessor: 'title',
			filterPath: ':title',
		},
		{
			Header: 'Current version',
			id: 'current-version',
			accessor: (d) => _get(d, 'system_software_version.version', ''),
			filterPath: ':system_software_version.version',
		},
	];
}
