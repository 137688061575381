export const RESET_INGREDIENT_CATEGORIES =
	'SALES_CONFIGURATION/RESET_INGREDIENT_CATEGORIES';
export const SET_INGREDIENT_CATEGORIES =
	'SALES_CONFIGURATION/SET_INGREDIENT_CATEGORIES';
export const UPDATE_INGREDIENT_CATEGORIES =
	'SALES_CONFIGURATION/UPDATE_INGREDIENT_CATEGORIES';

/**
 * @param {object} payload
 */
export function setIngredientCategory(payload) {
	return {
		type: SET_INGREDIENT_CATEGORIES,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateIngredientCategory(payload) {
	return {
		type: UPDATE_INGREDIENT_CATEGORIES,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET_INGREDIENT_CATEGORIES
	};
}