'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { Input, Button, Icon, Toggle } from 'dumb';
import Checkbox from 'inputs/checkbox';

import { ReactDataWrapper } from 'reactDataWrapper';
import { submitBatchValues } from 'reactDataWrapper/utilities/batchHelper';
import SubTable from 'reactDataWrapper/components/subTable';

import {
	fetchRawIngredientCategories,
	addRawIngredientCategories,
	deleteRawIngredientCategories,
	editRawIngredientCategory,
	editRawIngredientCategories,
} from './rawIngredientCategories.service';

import {
	setRawIngredientCategoriesModal,
	updateRawIngredientCategories,
	resetState,
	updateBatchForm,
	resetBatch,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,
} from './store/rawIngredientCategories.actions';

// components
import ModalBatch from './components/batch/rawIngredientCategoriesModalBatch';
import GlobalRawIngredients from './components/globalRawIngredients/globalRawIngredients';

// constants/phrases/tools
import phrases from './rawIngredientCategories.phrases';
import _get from 'lodash/get';

class RawIngredientCategories extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
		};

		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.getEditableCells = this.getEditableCells.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this._toggleModal = this._toggleModal.bind(this);
		this.batchAdd = this.batchAdd.bind(this);

		this.reduxKey = '/inventory/raw_ingredient_categories';

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: (d) => _get(d, 'description', ''),
				filterPath: ':description',
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: (d) => _get(d, 'legacy', ''),
				filterPath: ':legacy',
				filterable: false,
				Cell: (d) => <Checkbox noBorder disabled checked={d.original.legacy} />,
			},
		];
	}

	editEntry() {
		const { rawIngredientCategories } = this.props;

		const payload = {
			id: rawIngredientCategories.id,
			name: rawIngredientCategories.name,
			description: rawIngredientCategories.description,
			legacy: rawIngredientCategories.legacy,
		};

		return editRawIngredientCategory(payload);
	}

	editMultiple(selectedRows) {
		const { rawIngredientCategories } = this.props;

		const payload = {
			...(rawIngredientCategories.name && {
				name: rawIngredientCategories.name,
			}),
			...(rawIngredientCategories.description && {
				description: rawIngredientCategories.description,
			}),
			legacy: !!rawIngredientCategories.legacy,
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editRawIngredientCategories({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteRawIngredientCategories(id);
	}

	setInitialEditValues(data) {
		const { setRawIngredientCategoriesModal } = this.props;

		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			legacy: data.legacy,
		};

		setRawIngredientCategoriesModal(payload);
	}

	getEditableCells() {
		const { rawIngredientCategories } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={rawIngredientCategories.name || ''}
						onChange={(e) => this.editStoreEntry('name', e.target.value)}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={rawIngredientCategories.description}
						onChange={(e) => this.editStoreEntry('description', e.target.value)}
					/>
				),
			},
			{
				header: 'Legacy',
				value: (
					<Toggle
						id="legacy"
						onClick={(e) => this.editStoreEntry('legacy', e)}
						toggled={rawIngredientCategories.legacy}
					/>
				),
			},
		];
	}

	editStoreEntry(name, value) {
		const { updateRawIngredientCategories } = this.props;

		const payload = {
			[name]: value,
		};

		updateRawIngredientCategories(payload);
	}

	_toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	batchAdd(payload) {
		return addRawIngredientCategories(payload)
			.then((res) => {
				submitBatchValues({ res, reduxKey: this.reduxKey });
				return res;
			})
			.catch((err) => {
				throw err;
			});
	}

	render() {
		const { batchList, addBatchList, editBatchListItem, removeBatchListItem, updateBatchForm, addBatchListItemRow } =
			this.props;

		return (
			<>
				<ReactDataWrapper
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					accessAreasAllowedToEdit={['Global Sales Configuration', 'Sales Configuration']}
					fetchData={fetchRawIngredientCategories}
					filterable
					defaultPageSize={10}
					reduxKey={this.reduxKey}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					deleteEntry={this.deleteEntry}
					editMultiple={this.editMultiple}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					actionRender={
						<>
							<Button type="inverted" label="Add" shadow onClick={() => this._toggleModal()}>
								<Icon name="add" />
							</Button>
						</>
					}
					subcomponent={(row) => (
						<SubTable>
							<GlobalRawIngredients rawIngredientCategory={row.original} />
						</SubTable>
					)}
				/>

				<ModalBatch
					modalVisible={this.state.showModal}
					handleClose={this._toggleModal}
					updateBatchForm={updateBatchForm}
					addBatchList={addBatchList}
					batchList={batchList}
					addRawIngredientCategories={this.batchAdd}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
				/>
			</>
		);
	}
}

RawIngredientCategories.propTypes = {
	setRawIngredientCategoriesModal: PropTypes.func,
	resetState: PropTypes.func,
	updateRawIngredientCategories: PropTypes.func,
	rawIngredientCategories: PropTypes.object,
	batchList: PropTypes.array,
	addBatchList: PropTypes.func,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	updateBatchForm: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setRawIngredientCategoriesModal,
			updateRawIngredientCategories,
			resetState,
			updateBatchForm,
			resetBatch,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		rawIngredientCategories: store.globalAdministrationRawIngredientCategories.data.rawIngredientCategories,
		batchList: store.globalAdministrationRawIngredientCategories.data.batchList,
	};
};

export default connectWithStore(RawIngredientCategories, mapStateToProps, mapDispatchToProps);
