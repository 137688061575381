import React from 'react';

import { Input } from 'dumb';

import _get from 'lodash/get';

export default function getColumns(assetCollectionId = null) {
	return [
		{
			Header: 'Id',
			id: 'id',
			accessor: (d) => _get(d, 'id', null),
			show: false,
			filterPath: ':id',
		},
		{
			Header: 'Name',
			id: 'name',
			accessor: 'name',
			filterPath: ':name',
		},
		{
			Header: 'Product category',
			id: 'product_category',
			accessor: (d) => _get(d, 'product_category.name', ''),
			filterPath: ':product_category.name',
		},
		...(assetCollectionId
			? []
			: [
					{
						Header: 'Asset Collection',
						id: 'asset_collection',
						accessor: (d) => _get(d, 'asset_collection.name', null),
						filterPath: ':asset_collection.name',
					},
			  ]),
		{
			Header: 'Production Area',
			id: 'production_area',
			accessor: (d) => _get(d, 'production_area.name', null),
			filterPath: ':production_area.name',
		},
		{
			Header: 'Legacy',
			id: 'legacy',
			accessor: 'legacy',
			filterPath: ':legacy',
			filterable: false,
			Cell: (d) => {
				return (
					<Input
						id="checkbox"
						type="checkbox"
						checked={_get(d, 'original.legacy', false)}
						disabled
					/>
				);
			},
		},
		{
			Header: 'Flair',
			id: 'flair',
			accessor: (d) => _get(d, 'flair.name', ''),
			filterPath: ':flair.name',
		},
	];
}
