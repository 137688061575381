'use strict';

import { get, post, remove, patch } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './posConfigurationProductVariantsPricing.phrases';

/**
 * @function fetchSalesConfigurationProductVariantPricings
 * @description Fetch all sales configuration product variant pricings
 */
export function fetchSalesConfigurationProductVariantPricings(
	state,
	filter = ''
) {
	let newFilter = '';
	if (state.filter) newFilter = state.filter;
	if (filter) newFilter = newFilter ? `${newFilter};${filter}` : filter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: newFilter,
	};

	return get(
		'/pos/pos_configuration_product_variants',
		params,
		null,
		state.headers
	)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editSalesConfigurationProductVariantPricing
 * @param {Object} payload - update object
 */
export function editSalesConfigurationProductVariantPricing({ payload, id }) {
	return post(`/pos/pos_configuration_product_variants/${id}`, payload)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_PRICING_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editSalesConfigurationProductVariantPricing
 * @param {Object} payload - update object
 */
export function editSalesConfigurationProductVariantPricings(payload) {
	return patch('/pos/pos_configuration_product_variants', payload)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_PRICING_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteSalesConfigurationProductVariantPricing
 * @param {Number} id - id of a product variant to delete
 */
export function deleteSalesConfigurationProductVariantPricing(id) {
	return remove(`/pos/pos_configuration_product_variants/${id}?cascade=true`)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_PRICING_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

export function addSalesConfigurationProductVariantPricing(data) {
	return post('/pos/pos_configuration_product_variants', data)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_PRICING_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
