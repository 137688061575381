import React, { useRef, useState, useEffect } from 'react';

// interfaces
import { IVacationDescriptionPreset } from '../../types';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import {
	resetVacationDescriptionPreset,
	rerenderTable,
} from './../../store/vacationDescriptionPresets.actions';

// components
import { ModalStepper } from 'dumb';
import VacationDescriptionPresetsModalStep from './components/vacationDescriptionPresetsModalStep';

// services
import { addVacationDescriptionPresets } from './../../vacationDescriptionPresets.service';

// phrases/ enums
import phrases from './../../vacationDescriptionPresets.phrases';

type Props = {
	onClose: () => void;
};

const VacationDescriptionPresetsModalBatch = ({
	onClose,
}: Props): JSX.Element => {
	const modalRef = useRef() as React.LegacyRef<ModalStepper>;
	const [loading, setLoading] = useState(false);
	const dispatch = useAppDispatch();
	const batchList = useAppSelector(
		(store) => store.vacationDescriptionPresets.batchList
	);

	const onNext = () => {
		setLoading(true);

		const payload: IVacationDescriptionPreset[] = batchList.map(
			(entry): IVacationDescriptionPreset => ({
				subject: entry.subject,
				description: entry.description,
			})
		);

		addVacationDescriptionPresets(payload)
			.then(() => {
				setLoading(false);
				dispatch(rerenderTable());
				onClose();
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const getIsStepValid = () => {
		return batchList.every((entry) => {
			if (entry.subject && entry.description) return true;
		});
	};

	const steps = [
		{
			component: <VacationDescriptionPresetsModalStep />,
			title: phrases.MODAL_TITLE,
			onBack: onClose,
			onNext,
			loading,
			isValid: getIsStepValid(),
			confirmButtonLabel: phrases.SUBMIT,
			defaultStyles: false,
			noScroll: false,
		},
	];

	useEffect(() => {
		return () => {
			dispatch(resetVacationDescriptionPreset());
		};
	}, [dispatch]);

	return (
		<ModalStepper
			header={phrases.MODAL_TITLE}
			onClose={onClose}
			ref={modalRef}
			isOpen
			steps={steps}
			showStep={false}
		/>
	);
};

export default VacationDescriptionPresetsModalBatch;
