import { get, remove, post } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { IFetchDataProps } from 'types/reactDataWrapper';
import phrases from '../moneyballPositionsTable.phrases';
import { IMoneyballPositionState } from '../types';

export const fetchMoneyballPositions = (
	state: IFetchDataProps
): Promise<IApiRes> => {
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/hr/moneyball_positions', params, null, state.headers)
		.then((response: IApiRes) => {
			return response;
		})
		.catch((error: Error) => {
			const errorMessage = formatErrorMessage(error);
			setFeedback(errorMessage, 0);
			throw error;
		});
};

export const deleteMoneyballPosition = (id: number): Promise<IApiRes> => {
	return remove(`/hr/moneyball_positions/${id}`)
		.then((response: IApiRes) => {
			setFeedback(phrases.MONEYBALL_POSITION_REMOVED, 1);
			return response;
		})
		.catch((error: Error) => {
			const errorMessage = formatErrorMessage(error);
			setFeedback(errorMessage, 0);
		});
};

export const createMoneyballPosition = (
	payload: IMoneyballPositionState
): Promise<IApiRes> => {
	return post('/hr/moneyball_positions', payload)
		.then((response: IApiRes) => {
			setFeedback(phrases.NEW_MONEYBALL_POSITION_CREATED, 1);
			return response;
		})
		.catch((error: Error) => {
			const errorMessage = formatErrorMessage(error);
			setFeedback(errorMessage, 0);
		});
};

export const updateMoneyballPosition = (
	id: number | undefined,
	payload: IMoneyballPositionState
): Promise<IApiRes> => {
	return post(`/hr/moneyball_positions/${id}`, payload)
		.then((response: IApiRes) => {
			setFeedback(phrases.UPDATED_MONEYBALL_POSITION, 1);
			return response;
		})
		.catch((error: Error) => {
			const errorMessage = formatErrorMessage(error);
			setFeedback(errorMessage, 0);
		});
};
