import { get, post, remove, patch } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './rawIngredientConfigurationRawIngredients.phrases';

/**
 * @function fetchRawIngredientConfigurationRawIngredients
 * @description Fetch all raw ingredient categories
 * @param {object} state
 */
export function fetchRawIngredientConfigurationRawIngredients(state, filter) {
	filter = state.filter ? `${state.filter};${filter}` : filter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter
	};

	return get(
		'/inventory/raw_ingredient_configuration_raw_ingredients',
		params,
		null,
		state.headers
	)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

export function fetchRawIngredients(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get(
		'/inventory/raw_ingredients',
		params,
		null,
		state.headers
	)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addRawIngredientConfigurationRawIngredients
 * @description adds raw ingredient categories
 */
export function addRawIngredientConfigurationRawIngredients(data) {
	return post('/inventory/raw_ingredient_configuration_raw_ingredients', data)
		.then(res => {
			setFeedback(phrases.RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editRawIngredientConfigurationRawIngredient
 * @description edits raw ingredient categories
 */
export function editRawIngredientConfigurationRawIngredient(data) {
	return post(`/inventory/raw_ingredient_configuration_raw_ingredients/${data.id}`, data)
		.then(res => {
			setFeedback(phrases.RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENT_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function editRawIngredientConfigurationRawIngredients
 * @description edits raw ingredient categories
 */
export function editRawIngredientConfigurationRawIngredients(data) {
	return patch('/inventory/raw_ingredient_configuration_raw_ingredients', data)
		.then(res => {
			setFeedback(phrases.RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function deleteRawIngredientConfigurationRawIngredient
 * @description deletes raw ingredient categories
 */
export function deleteRawIngredientConfigurationRawIngredient(id) {
	return remove(`/inventory/raw_ingredient_configuration_raw_ingredients/${id}`)
		.then(res => {
			setFeedback(phrases.RAW_INGREDIENT_CONFIGURATION_RAW_INGREDIENTS_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
