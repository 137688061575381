import React, { Fragment } from 'react';

// interface
import { IRemoveShiftsFromEmployeeShift } from 'types/removeShiftsFromEmployee';

// redux
import { useAppSelector } from '../../../../../appState/storeHooks';

// components
import { Typography, Box, List, ListItem, ListItemText, Divider } from '@mui/material';

// misc
import phrases from '../removeShiftsFromEmployee.phrases';
import constants from 'services/constants';
import moment from 'moment';

const RemoveShiftsFromEmployeePageTwo = (): JSX.Element => {
	const shifts = useAppSelector((state) => state.removeShiftsFromEmployee.metadata.shifts);

	const getShiftTimes = (shift: IRemoveShiftsFromEmployeeShift): string => {
		const fromTime = moment.utc(shift.planned_period.from).format(constants.timeShort);
		const toTime = moment.utc(shift.planned_period.to).format(constants.timeShort);
		const date = moment.utc(shift.planned_period.from).format(constants.shortDate);

		return `${fromTime} - ${toTime} ${date}`;
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Typography variant="body1" fontWeight="bold">
				{phrases.SHIFTS_PAGE_TEXT} ({shifts?.length ?? 0})
			</Typography>
			<List
				sx={{
					overflowY: 'auto',
					width: '100%',
					maxHeight: '300px',
				}}
			>
				{shifts?.map((shift, index) => (
					<Fragment key={shift.id}>
						<ListItem dense secondaryAction={<Typography variant="subtitle2">{getShiftTimes(shift)}</Typography>}>
							<ListItemText primary={`${index + 1}. ${shift.workplace.name}`} />
						</ListItem>
						<Divider component="li" />
					</Fragment>
				))}
			</List>
		</Box>
	);
};

export default RemoveShiftsFromEmployeePageTwo;
