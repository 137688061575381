'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// constants
import constants from 'services/constants';
import phrases from './../../staffDetails.phrases';

// services
import { fetchModuleExecutions, addReview } from './../../staffDetails.service';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// actions
import { setListData } from 'reactDataWrapper/reactDataWrapper.actions';

// enums
import collectionSelectEnums from 'services/enums/collectionSelect';

// lodash
import _get from 'lodash/get';

// utils
import { FilterProvider } from 'reactDataWrapper/utilities';

// components
import {
	Button,
	Icon,
	Overlay,
	InputCollectionSelect,
	Tooltip,
	Legend,
} from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';
import GradeReviewModal from './components/gradeReviewModal';

class TrainingsAndExams extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalOpen: false,
			loading: false,
			savedModuleExecutions: [],
		};

		this.failedColor = '--red-100';

		this.fetchData = this.fetchData.bind(this);
		this.resetState = this.resetState.bind(this);
		this.updateModuleExecution = this.updateModuleExecution.bind(this);
		this.getListOfModuleLevel = this.getListOfModuleLevel.bind(this);
		this.toggleAddReviewCommentModal =
			this.toggleAddReviewCommentModal.bind(this);
		this.getTrainingExamsLegend = this.getTrainingExamsLegend.bind(this);
		this.getCustomRowStyling = this.getCustomRowStyling.bind(this);
		this.editTrainingExamData = this.editTrainingExamData.bind(this);
		this.reduxKey = 'training/persons/qualifications';

		this.columns = [
			{
				Header: 'Module',
				id: 'module',
				accessor: (d) => _get(d, 'module.name', null),
				filterPath: ':module.name',
				filterable: false,
				sortable: false,
			},
			{
				Header: 'Module level',
				id: 'moduleLevel',
				filterPath: ':module.moneyball_position.name',
				sortable: false,
				accessor: (d) => _get(d, 'module.moneyball_position.name', ''),
				Filter: ({ column }) => (
					<FilterProvider
						reduxKey={this.reduxKey}
						columnId={column.id}
						provide={(filterValue, persistToFilterStorage) => {
							return this.renderCollectionSelect({
								column,
								filterValue,
								persistToFilterStorage,
							});
						}}
					/>
				),
			},

			{
				Header: 'Trainer',
				id: 'trainer',
				accessor: (d) =>
					_get(d, 'latest_module_execution.trainer.full_name', ''),
				filterPath: ':latest_module_execution.trainer.full_name',
				filterable: false,
				sortable: false,
			},
			{
				Header: 'Type',
				id: 'type',
				accessor: (d) => _get(d, 'module.type', null),
				filterPath: ':module.type',
				filterable: false,
				sortable: false,
			},
			{
				Header: 'Date',
				id: 'date',
				Cell: (d) => (
					<span className="number">
						{d.value !== null
							? moment.utc(d.value).format(constants.shortDate)
							: ''}
					</span>
				),
				accessor: (d) => _get(d, 'latest_module_execution.period.from', null),
				filterPath: ':latest_module_execution.period.from',
				filterable: false,
				sortable: false,
			},
			{
				Header: 'Workplace',
				id: 'workplace',
				accessor: (d) =>
					_get(d, 'latest_module_execution.workplace.name', null),
				filterPath: ':latest_module_execution.workplace.name',
				filterable: false,
				sortable: false,
			},
			{
				Header: 'Grade level',
				id: 'gradeLevel',
				accessor: (d) =>
					_get(
						d,
						'latest_module_execution.module_grade_level.grade_level.name',
						null
					),
				filterPath:
					':latest_module_execution.module_grade_level.grade_level.name',
				filterable: false,
				sortable: false,
			},
			{
				Header: 'Review grade level',
				id: 'reviewGradeLevel',
				accessor: (d) =>
					_get(d, 'latest_review.module_grade_level.grade_level.name', null),
				filterPath: ':latest_review.module_grade_level.grade_level.name',
				filterable: false,
				sortable: false,
			},
			{
				Header: 'Comment',
				id: 'comment',
				accessor: (d) => _get(d, 'latest_review.comment', null),
				filterPath: ':latest_review.comment',
				filterable: false,
				sortable: false,
			},
			{
				Header: 'Reviewer',
				id: 'reviewer',
				accessor: (d) => _get(d, 'latest_review.reviewer.full_name', null),
				filterPath: ':latest_review.reviewer.full_name',
				filterable: false,
				sortable: false,
			},
		];
	}

	getListOfModuleLevel() {
		const { moduleExecutions } = this.props;

		const filteredByName = moduleExecutions?.map(
			(entry) => entry?.module?.moneyball_position?.name
		);

		const removedDuplicates = [...new Set(filteredByName)];

		const optionsForModuleExecutions = removedDuplicates.map((entry) => {
			return { label: entry, value: entry };
		});

		return optionsForModuleExecutions;
	}

	updateModuleExecution() {
		const { gradeReviewRowData } = this.props;

		this.setState(() => ({ loading: true }));

		const payload = {
			module_execution: gradeReviewRowData.moduleExecutionId,
			module_grade_level: gradeReviewRowData.moduleGradeLevel.value,
			date: moment.utc().format(constants.shortDate),
			...(gradeReviewRowData.comment && {
				comment: gradeReviewRowData.comment,
			}),
		};

		return addReview({
			payload,
			row: gradeReviewRowData.row,
			reduxKey: this.reduxKey,
			isReview: gradeReviewRowData.isReview,
		})
			.then(() => {
				this.setState(() => ({ loading: false }));
				this.toggleAddReviewCommentModal();
			})
			.catch(() => {
				this.setState(() => ({ loading: false }));
			});
	}

	editTrainingExamData(name, e) {
		const { updateGradeReviewRowData } = this.props;

		const value = e.target ? e.target.value : e;

		const payload = {
			[name]: value,
		};

		updateGradeReviewRowData(payload);
	}

	fetchData(state) {
		const { userId } = this.props;

		return fetchModuleExecutions({ employeeId: userId, state }).then(
			(response) => {
				this.setState({ savedModuleExecutions: response.data });
				return response;
			}
		);
	}

	resetState() {
		this.props.resetTrainingExamsData();
	}

	renderAddReviewCommentButton(d) {
		return d.original.latest_module_execution ? (
			<Tooltip
				text={phrases.ADD_REVIEW_TOOLTIP}
				zIndex={500}
				placement="left"
				renderData={(ref, onMouseEnter, onMouseLeave) => (
					<Button
						id="addReviewCommentButton"
						type="inverted"
						shadow
						onClick={(e) => this.toggleAddReviewCommentModal(d.original)}
						size="micro"
						refProp={ref}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
					>
						<Icon name="playlist_add" />
					</Button>
				)}
			/>
		) : null;
	}

	renderCollectionSelect({ column, filterValue, persistToFilterStorage }) {
		return (
			<InputCollectionSelect
				id={column.id}
				name="moduleLevel"
				value={filterValue}
				handleChange={(key, value) => {
					const { moduleExecutions, setListData } = this.props;
					const { savedModuleExecutions } = this.state;

					const filteredValues = moduleExecutions.filter(
						(entry) => entry?.module?.moneyball_position?.name === value?.value
					);

					setListData({
						reduxKey: this.reduxKey,
						listData: value?.label ? filteredValues : savedModuleExecutions,
					});

					persistToFilterStorage({ handle: key, value });
				}}
				styleType={collectionSelectEnums.TYPE_IN_TABLE}
				options={this.getListOfModuleLevel()}
			/>
		);
	}

	toggleAddReviewCommentModal(rowData) {
		const { setGradeReviewRowData, resetGradeReviewRowData } = this.props;
		const { modalOpen } = this.state;

		if (!modalOpen) {
			let payload = {
				id: rowData.id,
				moduleId: rowData.module.id,
				moduleExecutionId: _get(rowData, 'latest_module_execution.id', null),
				...(_get(
					rowData,
					'latest_module_execution.module_grade_level.grade_level.id',
					false
				) && {
					moduleGradeLevel: {
						value:
							rowData.latest_module_execution.module_grade_level.grade_level.id,
						label:
							rowData.latest_module_execution.module_grade_level.grade_level
								.name,
					},
				}),
				row: rowData,
			};

			// if review present, overwrite module grade with review one
			if (rowData.latest_review) {
				payload = {
					...payload,
					comment: _get(rowData, 'latest_review.comment', null),
					moduleGradeLevel: {
						value: _get(
							rowData,
							'latest_review.module_grade_level.grade_level.id',
							null
						),
						label: _get(
							rowData,
							'latest_review.module_grade_level.grade_level.name',
							null
						),
					},
				};
			}

			// find out if review or regular grading
			// if we have a regular grade already then it must be a review
			payload = {
				...payload,
				isReview: !!_get(
					rowData,
					'latest_module_execution.module_grade_level.grade_level.id',
					false
				),
			};

			setGradeReviewRowData(payload);
		} else {
			resetGradeReviewRowData();
		}

		this.setState((prevState) => ({
			modalOpen: !prevState.modalOpen,
		}));
	}

	getCustomRowStyling(rowData) {
		const rowColor = _get(rowData, 'original.latest_review', false)
			? _get(
					rowData,
					'original.latest_review.module_grade_level.grade_level.name',
					false
			  ) === 'Failed'
				? this.failedColor
				: null
			: _get(
					rowData,
					'original.latest_module_execution.module_grade_level.grade_level.name',
					false
			  ) === 'Failed'
			? this.failedColor
			: null;

		if (!rowColor) return {};

		const rowStyle = `rgba(${window
			.getComputedStyle(document.body)
			.getPropertyValue(rowColor)}, 1`;

		return {
			background: rowStyle,
		};
	}

	getTrainingExamsLegend() {
		const legendPoints = [
			{
				bulletColor: `rgba(${window
					.getComputedStyle(document.body)
					.getPropertyValue(this.failedColor)}, 1`,
				label: 'Failed',
			},
		];

		return <Legend points={legendPoints} />;
	}

	render() {
		const { isTrainingVisible, toggleTrainingHistory, gradeReviewRowData } =
			this.props;
		const { modalOpen, loading } = this.state;

		return (
			<>
				<Overlay
					zIndex={500}
					height={520}
					visible={isTrainingVisible}
					onClose={toggleTrainingHistory}
				>
					<div className="staff-details__overlay">
						<ReactDataWrapper
							style={{ height: '400px' }}
							modalZIndex={550}
							title={phrases.TRAININGS_EXAMS_TABLE}
							columns={this.columns}
							fetchData={this.fetchData}
							filterable
							defaultPageSize={10}
							reduxKey={this.reduxKey}
							onModalClose={this.resetState}
							inRowButtons
							manual
							enableMultiSelection={false}
							actionsWidth={30}
							actions={(d) => this.renderAddReviewCommentButton(d)}
							customRowStyling={this.getCustomRowStyling}
							hideIdColumn
							disableFetchCsvButton
							customAreaComponents={this.getTrainingExamsLegend()}
						/>
					</div>
				</Overlay>
				{modalOpen ? (
					<GradeReviewModal
						loading={loading}
						modalOpen={modalOpen}
						gradeReviewRowData={gradeReviewRowData}
						toggleAddReviewCommentModal={this.toggleAddReviewCommentModal}
						updateModuleExecution={this.updateModuleExecution}
						editTrainingExamData={this.editTrainingExamData}
					/>
				) : null}
			</>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setListData,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	const reduxKey = 'training/persons/qualifications';
	return {
		moduleExecutions: store?.listData[reduxKey]?.data?.listData,
	};
};

TrainingsAndExams.propTypes = {
	gradeReviewRowData: PropTypes.object,
	resetTrainingExamsData: PropTypes.func,
	setGradeReviewRowData: PropTypes.func,
	toggleTrainingHistory: PropTypes.func,
	updateGradeReviewRowData: PropTypes.func,
	resetGradeReviewRowData: PropTypes.func,
	isTrainingVisible: PropTypes.bool,
	userId: PropTypes.number,
	moduleExecutions: PropTypes.string,
	setListData: PropTypes.func,
};

export default connectWithStore(
	TrainingsAndExams,
	mapStateToProps,
	mapDispatchToProps
);
