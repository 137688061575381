import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// components
import ButtonNotification from './components/buttonNotification/buttonNotification';

import './button.css';

export default class Button extends PureComponent {
	render() {
		const {
			children,
			className,
			size,
			type,
			group,
			onClick,
			layout,
			state,
			disabled,
			shadow,
			label,
			round,
			floating,
			onKeyDown,
			title,
			tabIndex,
			refProp,
			onMouseEnter,
			onMouseLeave,
			notifications,
			dataCy,
		} = this.props;

		const classNames = cx('j-button', {
			[`${className}`]: className,
			[`j-button--${size}`]: size,
			[`j-button--${type}`]: type,
			'j-button--round': round,
			'j-button--floating': floating,
			[`j-button--${state}`]: state,
			[`j-button--${layout}`]: layout,
			[`j-button--group`]: group,
			[`j-button--shadow`]: shadow,
			[`j-button--label`]: label,
			'j-button--not-clickable': !onClick,
		});

		return (
			<button
				data-cy={dataCy}
				role="button"
				data-label={label}
				className={classNames}
				disabled={disabled}
				onClick={onClick}
				onKeyDown={onKeyDown}
				title={title}
				tabIndex={tabIndex}
				ref={refProp}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				{notifications ? (
					<ButtonNotification notifications={notifications} />
				) : null}
				{children}
			</button>
		);
	}
}

Button.propTypes = {
	// shared props
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	children: PropTypes.any,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	floating: PropTypes.bool,
	group: PropTypes.bool,
	label: PropTypes.string,
	layout: PropTypes.string,
	onClick: PropTypes.func,
	onKeyDown: PropTypes.func,
	round: PropTypes.bool,
	shadow: PropTypes.bool,
	size: PropTypes.oneOf(['small', 'tiny', 'micro', 'large', 'medium']),
	state: PropTypes.string,
	tabIndex: PropTypes.number,
	title: PropTypes.string,
	type: PropTypes.string,
	refProp: PropTypes.func,
	notifications: PropTypes.number,
	dataCy: PropTypes.string,
};
