import {
	UPDATE_INGREDIENT_PRICING_GROUPS,
	SET_INGREDIENT_PRICINGS_GROUPS_TO_EDIT,
	RESET_INGREDIENT_PRICING_GROUPS
} from './ingredientPricingGroups.actions';

const defaultState = {
	ui: {},
	data: {
		ingredientPricing: {},
		editedValues: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_INGREDIENT_PRICINGS_GROUPS_TO_EDIT: {
		return {
			...state,
			data: {
				...state.data,
				editedValues: payload
			}
		};
	}

	case UPDATE_INGREDIENT_PRICING_GROUPS: {
		return {
			...state,
			data: {
				...state.data,
				editedValues: {
					...state.data.editedValues,
					...payload
				}
			}
		};
	}

	case RESET_INGREDIENT_PRICING_GROUPS: {
		return {
			...state,
			...defaultState
		};
	}

	default:
		return state;
	}
}

export default reducer;
