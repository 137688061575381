'use strict';

import { store } from 'appState';

import phrases from '../dynamicPosView.phrases';
import { get } from 'api.vanilla.service';

import {
	setMarketWorkplaceTable,
	setMarketHourlyTable,
	setMarketProductTable,
	setWorkplaceHourlyTable,
	setWorkplaceProductTable,
	toggleTable,
	toggleProductTable,
	setMarketHoverGroup
} from '../dynamicPosView.actions';

/**
 * DynamicPosView
 * @param {string} name
 * @param {object} dataProvider
 */
class DynamicPosView {
	constructor(parameters, filter) {
		// Data providers

		// Get parameters and filter
		this.parameters = parameters;
		this.filter = filter;
	}

	/// //////////
	// Getters //
	/// //////////

	/**
	 * @function getPhrases
	 * @memberOf DynamicPosView
	 * @description Getter for phrases
	 */
	getPhrases() {
		return phrases;
	}

	/// /////////////////
	// Event handlers //
	/// /////////////////

	/**
	 * @function handleToggleTable
	 * @memberOf DynamicPosView
	 * @description Handler for toggling tables in dynamic pos view
	 * @param {object} event
	 */
	handleToggleTable(type, id) {
		const currentState =
			store.getState().dynamicPosView.expandedTables[type][id] || false;
		store.dispatch(toggleTable(type, id, !currentState));
	}

	/**
	 * @function handleToggleProductTable
	 * @memberOf DynamicPosView
	 * @description Handler for toggling product tables in dynamic pos view
	 * @param {object} event
	 */
	handleToggleProductTable(product, id) {
		const currentState =
			store.getState().dynamicPosView.expandedTables.product[product][id] ||
			false;
		store.dispatch(toggleProductTable(product, id, !currentState));
	}

	/**
	 * @function handleGetMarketWorkplaceTable
	 * @memberOf DynamicPosView
	 * @description Handler for getting workplace table
	 * @param {number} market
	 */
	handleGetMarketWorkplaceTable(market) {
		const endpoint = `/reporting/new_pos_reports/markets/${market}/workplace_table`;

		return get(endpoint, {
			filter: this.filter,
			from: this.parameters.from,
			to: this.parameters.to
		}).then(res =>
			store.dispatch(setMarketWorkplaceTable(market, res.data[0]))
		);
	}

	/**
	 * @function handleGetMarketHourlyTable
	 * @memberOf DynamicPosView
	 * @description Handler for getting workplace table
	 * @param {number} market
	 */
	handleGetMarketHourlyTable(market) {
		const endpoint = `/reporting/new_pos_reports/markets/${market}/hourly_table`;

		return get(endpoint, {
			filter: this.filter,
			from: this.parameters.from,
			to: this.parameters.to
		}).then(res => store.dispatch(setMarketHourlyTable(market, res.data[0])));
	}

	/**
	 * @function handleGetMarketProductTable
	 * @memberOf DynamicPosView
	 * @description Handler for getting workplace table
	 * @param {number} market
	 */
	handleGetMarketProductTable(market) {


		const endpoint = `/reporting/new_pos_reports/markets/${market}/product_table`;
		return get(endpoint, {
			filter: this.filter,
			from: this.parameters.from,
			to: this.parameters.to
		}).then(res => store.dispatch(setMarketProductTable(market, res.data[0])));
	}

	/**
	 * @function handleGetMarketAndWorkplaceHourlyTable
	 * @memberOf DynamicPosView
	 * @description Handler for getting workplace table
	 * @param {number} market
	 * @param {number} workplace
	 */
	handleGetMarketAndWorkplaceHourlyTable(market, workplace) {
		const endpoint = `/reporting/new_pos_reports/markets/${market}/workplaces/${workplace}/hourly_table`;
		return get(endpoint, {
			filter: this.filter,
			from: this.parameters.from,
			to: this.parameters.to
		}).then(res =>
			store.dispatch(setWorkplaceHourlyTable(workplace, res.data[0]))
		);
	}

	/**
	 * @function handleGetMarketAndWorkplaceProductTable
	 * @memberOf DynamicPosView
	 * @description Handler for getting workplace table
	 * @param {number} market
	 * @param {number} workplace
	 */
	handleGetMarketAndWorkplaceProductTable(market, workplace) {
		const endpoint = `/reporting/new_pos_reports/markets/${market}/workplaces/${workplace}/product_table`;

		return get(endpoint, {
			filter: this.filter,
			from: this.parameters.from,
			to: this.parameters.to
		}).then(res =>
			store.dispatch(setWorkplaceProductTable(workplace, res.data[0]))
		);
	}

	/**
	 * @function handleStartHover
	 * @memberOf DynamicPosView
	 * @description Handler for starting hover on continent level
	 * @param {string} table
	 * @param {string} group
	 */
	handleStartHover(table, group) {
		store.dispatch(setMarketHoverGroup(table, group));
	}

	/**
	 * @function handleStopHover
	 * @memberOf DynamicPosView
	 * @description Handler for stopping hover on continent level
	 * @param {string} table
	 */
	handleStopHover(table) {
		store.dispatch(setMarketHoverGroup(table, {}));
	}
}

export default DynamicPosView;
