import React from 'react';

// components
import { Box, IconButton as IconButtonMui, InputLabel, CircularProgress, styled } from '@mui/material';

type Props = {
	loading?: boolean;
	label?: string;
	disabled?: boolean;
	onClick?: () => void;
	children: JSX.Element;
};

const StyledIconButton = styled(IconButtonMui)(({ theme }) => ({
	backgroundColor: theme.palette.primary.light,
	borderRadius: 2,
	color: 'black',
}));

const IconButton = ({ loading, label, disabled, onClick, children }: Props): JSX.Element => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			{label && (
				<InputLabel
					sx={{
						fontSize: 12,
					}}
				>
					{label}
				</InputLabel>
			)}
			<StyledIconButton disabled={disabled} onClick={onClick}>
				{loading ? <CircularProgress size={16} /> : children}
			</StyledIconButton>
		</Box>
	);
};

export default IconButton;
