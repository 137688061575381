'use strict';

import constants from './dailyReport.constants';
import { HotbarComponentFactory as h } from 'hotdamnbar/factory';
import {
	HotbarEndpointDataProvider,
	HotbarStaticDataProvider
} from 'hotdamnbar/dataProviders';

const ReportingCurrenciesDataProvider = new HotbarEndpointDataProvider(
	'/reporting/reporting_currencies'
);

// Set option format
ReportingCurrenciesDataProvider.setOptionFormat((entry, resultSet) => ({
	value: entry.id,
	label: entry.currency.code
}));

const DailyReportTypeDataProvider = new HotbarStaticDataProvider([
	{
		value: constants.OPERATION_EXPANDED,
		label: 'Operation - Expanded'
	},
	{
		value: constants.OPERATION_COLLAPSED,
		label: 'Operation - Collapsed'
	},
]);

const DailyReportHotbar = h.hotbar('dailyReportHotbar');
DailyReportHotbar.add(h.singleDate('Date', true));
DailyReportHotbar.add(
	h.select('Reporting Currency', true, ReportingCurrenciesDataProvider)
);
DailyReportHotbar.add(
	h.select('Perspective', true, DailyReportTypeDataProvider)
);

export default DailyReportHotbar;
