'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setFilterProductVariant,
	updateFilterProductVariant,
	resetFilterProductVariant,
} from './store/filterProductVariants.actions';

// services
import {
	fetchFilterProductVariants,
	addFilterProductVariant,
	deleteFilterProductVariant,
} from './filterProductVariants.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';
import { Button, Icon } from 'dumb';
import FilterProductVariantIngredientVariationDefaultReplacements from './components/filterProductVariantIngredientVariationDefaultReplacements/filterProductVariantIngredientVariationDefaultReplacements.component';

import FilterProductVariantsModalBatch from './components/batch/filterProductVariantsModalBatch';

// lodash
import _get from 'lodash/get';

// phrases
import phrases from './filterProductVariants.phrases';

const reduxKey = '/product/filter_product_variants';

class FilterProductVariants extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
		};

		this.deleteEntry = this.deleteEntry.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.addFilterProductVariantWrapper = this.addFilterProductVariantWrapper.bind(
			this
		);
		this.fetchData = this.fetchData.bind(this);

		this.columns = [
			{
				Header: 'Product variant',
				id: 'productVariant',
				accessor: (d) => _get(d, 'product_variant.name', ''),
				filterPath: ':product_variant.name',
			},
		];
	}

	deleteEntry(id) {
		return deleteFilterProductVariant(id);
	}

	fetchData(state) {
		const { filter } = this.props;

		const queryFilter = `:filter.id=='${filter.id}'`;

		return fetchFilterProductVariants(state, queryFilter);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetFilterProductVariant();
	}

	addFilterProductVariantWrapper(data) {
		return addFilterProductVariant(data).then(() => {
			this.setState(() => ({
				key: moment.utc(),
			}));
		});
	}

	render() {
		const {
			batchList,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
			filter,
			batchForm,
			addBatchList,
			updateBatchForm,
		} = this.props;

		return (
			<>
				<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={`${reduxKey}-${filter.id}`}
					manual
					subcomponent={(row) => {
						return (
							<SubTable>
								<FilterProductVariantIngredientVariationDefaultReplacements
									filterProductVariant={row.original}
								/>
							</SubTable>
						);
					}}
					deleteEntry={this.deleteEntry}
					onModalClose={this.props.resetFilterProductVariant}
					actionRender={
						<Button
							type="inverted"
							label="Batch"
							shadow
							onClick={() => this.toggleModal()}>
							<Icon name="add" />
						</Button>
					}
				/>

				<FilterProductVariantsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					batchForm={batchForm}
					updateBatchForm={updateBatchForm}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addFilterProductVariant={this.addFilterProductVariantWrapper}
					addBatchList={addBatchList}
					filter={filter}
				/>
			</>
		);
	}
}

FilterProductVariants.propTypes = {
	resetFilterProductVariant: PropTypes.func,
	batchList: PropTypes.array,
	batchForm: PropTypes.object,
	editBatchListItem: PropTypes.func,
	addBatchList: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	updateBatchForm: PropTypes.func,
	filter: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			updateFilterProductVariant,
			setFilterProductVariant,
			resetFilterProductVariant,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultFilterProductVariant:
			store.filterProductVariants.data.defaultFilterProductVariant,
		batchList: store.filterProductVariants.data.batchList,
		batchForm: store.filterProductVariants.data.batchForm,
	};
};

export default connectWithStore(
	FilterProductVariants,
	mapStateToProps,
	mapDispatchToProps
);
