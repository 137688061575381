'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';

// different batch views
import IngredientConfigurationIngredientsBatchStepForm from './components/ingredientConfigurationIngredientsBatchStepForm';
import IngredientConfigurationIngredientsBatchStepList from './components/ingredientConfigurationIngredientsBatchStepList';

// phrases/ constants
import phrases from './../../ingredientConfigurationIngredients.phrases';

// loash
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';

class IngredientConfigurationIngredienbtsModalBatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};

		this._onSubmitForm = this._onSubmitForm.bind(this);
		this._batchSubmit = this._batchSubmit.bind(this);

		this.modalRef = React.createRef();
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_onSubmitForm() {
		const { batchFormData, addBatchList } = this.props;

		const listArray = batchFormData.ingredients.map((entry) => {
			return {
				identifier: _uniqueId('row-'),
				ingredient: entry,
				availableInApp: !!batchFormData.availableInApp,
			};
		});

		addBatchList(listArray);

		this._goToStep(2);
	}

	_batchSubmit() {
		const {
			batchListData,
			handleClose,
			batchAddData,
			ingredientConfigurationId,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchListData.map((entry) => {
			return {
				ingredient_configuration: ingredientConfigurationId,
				ingredient: entry.ingredient?.value?.id,
				available_in_app: !!entry.availableInApp,
			};
		});

		batchAddData({ batch: payload })
			.then(() => handleClose())
			.finally(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchListData,
			editBatchListItem,
			removeBatchListItem,
			batchAddFilter,
		} = this.props;

		const steps = [
			{
				component: (
					<IngredientConfigurationIngredientsBatchStepForm
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						batchAddFilter={batchAddFilter}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE,
				isValid: !_isEmpty(batchFormData.ingredients),
				onNext: this._onSubmitForm,
				loading: false,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				noScroll: false,
				allowOverflow: true,
			},
			{
				component: (
					<IngredientConfigurationIngredientsBatchStepList
						batchListData={batchListData}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
						loyaltyProductUrl={this.loyaltyProductUrl}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: this._batchSubmit,
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return modalVisible ? (
			<ModalStepper
				className="pos-modal-batch"
				ref={this.modalRef}
				isOpen={modalVisible}
				steps={steps}
				showStep={false}
				onClose={handleClose}
			/>
		) : null;
	}
}

IngredientConfigurationIngredienbtsModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	addBatchList: PropTypes.func,
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	batchAddData: PropTypes.func,
	batchAddFilter: PropTypes.string,
	ingredientConfigurationId: PropTypes.number,
};

export default IngredientConfigurationIngredienbtsModalBatch;
