
import Map from './map';

class MapFactory {

	/**
	 * @function Map
	 * @memberOf MapFactory
	 * @return Map
	 */
	Map() {
		return new Map();
	}

}

export default new MapFactory();