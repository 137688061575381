'use strict';

import {
	UPDATE_POS_CONFIGURATION_ASSOCIATED_INGREDIENT,
	RESET_POS_CONFIGURATION_ASSOCIATED_INGREDIENT,
	SET_POS_CONFIGURATION_ASSOCIATED_INGREDIENT,
	UPDATE_BATCH_FORM,
	ADD_BATCH_LIST,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
} from './posConfigurationAssociatedIngredients.actions';

const defaultState = {
	data: {
		posConfigurationAssociatedIngredient: {},
		batchFormData: {},
		batchListData: [],
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_POS_CONFIGURATION_ASSOCIATED_INGREDIENT:
			return {
				...state,
				data: {
					...state.data,
					posConfigurationAssociatedIngredient: payload,
				},
			};

		case UPDATE_POS_CONFIGURATION_ASSOCIATED_INGREDIENT:
			return {
				...state,
				data: {
					...state.data,

					posConfigurationAssociatedIngredient: {
						...state.data.posConfigurationAssociatedIngredient,
						...payload,
					},
				},
			};

		// batch

		case UPDATE_BATCH_FORM: {
			return {
				...state,
				data: {
					...state.data,
					batchFormData: {
						...state.data.batchFormData,
						...payload,
					},
				},
			};
		}

		case ADD_BATCH_LIST: {
			return {
				...state,
				data: {
					...state.data,
					batchListData: payload,
				},
			};
		}

		case EDIT_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchListData: state.data.batchListData.map((entry) => {
						if (entry.identifier === payload.identifier) return payload;
						return entry;
					}),
				},
			};
		}

		case REMOVE_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchListData: state.data.batchListData.filter(
						(entry) => entry.identifier !== payload
					),
				},
			};
		}

		case RESET_POS_CONFIGURATION_ASSOCIATED_INGREDIENT:
			return defaultState;

		default:
			return state;
	}
}

export default reducer;
