'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { InputCollectionSelect, SingleDatePickerInput } from 'dumb';
import { ModalRow } from 'dumb/modal';

import constants from 'services/constants';
import phrases from './../../../workplacePosConfiguration.phrases';

class WorkplacePosConfigurationBatchStepFormAdd extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};
	}

	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		const value = e.target ? e.target.value || e.target.checked : e;

		updateBatchForm({
			[type]: value,
		});
	}

	render() {
		const {
			batchFormData,
			productRecommendationUrl,
			tileLayoutUrl,
			ingredientConfigurationUrl,
		} = this.props;

		return (
			<>
				<ModalRow>
					<InputCollectionSelect
						id="productRecommendation"
						label={phrases.MODAL_BATCH_STEP_PRODUCT_RECOMMENDATION}
						placeholder={
							phrases.MODAL_BATCH_STEP_PRODUCT_RECOMMENDATION_PLACEHOLDER
						}
						value={batchFormData.productRecommendation}
						handleChange={(key, value) =>
							this.editStoreEntry(value, 'productRecommendation')
						}
						clearable={false}
						cache
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						apiPath={productRecommendationUrl}
						params={{
							limit: 30,
						}}
					/>
				</ModalRow>

				<ModalRow>
					<InputCollectionSelect
						id="posTileLayout"
						label={phrases.MODAL_BATCH_STEP_POS_TILE_LAYOUT}
						placeholder={phrases.MODAL_BATCH_STEP_POS_TILE_LAYOUT_PLACEHOLDER}
						value={batchFormData.posTileLayout}
						handleChange={(key, value) =>
							this.editStoreEntry(value, 'posTileLayout')
						}
						clearable={false}
						cache
						optionFormat={(entry) => ({
							value: entry,
							label: `${entry.name} - ${entry.description}`,
						})}
						apiPath={tileLayoutUrl}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						params={{
							limit: 30,
							filter: `:type=='POS'`,
						}}
					/>
				</ModalRow>

				<ModalRow>
					<InputCollectionSelect
						id="appTileLayout"
						label={phrases.MODAL_BATCH_STEP_APP_TILE_LAYOUT}
						placeholder={phrases.MODAL_BATCH_STEP_APP_TILE_LAYOUT_PLACEHOLDER}
						value={batchFormData.appTileLayout}
						handleChange={(key, value) =>
							this.editStoreEntry(value, 'appTileLayout')
						}
						clearable={false}
						cache
						optionFormat={(entry) => ({
							value: entry,
							label: `${entry.name} - ${entry.description}`,
						})}
						apiPath={tileLayoutUrl}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						params={{
							limit: 30,
							filter: `:type=='App'`,
						}}
					/>
				</ModalRow>

				<ModalRow>
					<InputCollectionSelect
						id="ingredientConfiguration"
						label={phrases.MODAL_BATCH_STEP_INGREDIENT_CONFIGURATION}
						placeholder={
							phrases.MODAL_BATCH_STEP_INGREDIENT_CONFIGURATION_PLACEHOLDER
						}
						value={batchFormData.ingredientConfiguration}
						handleChange={(key, value) =>
							this.editStoreEntry(value, 'ingredientConfiguration')
						}
						clearable={false}
						cache
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						apiPath={ingredientConfigurationUrl}
						params={{
							limit: 30,
						}}
					/>
				</ModalRow>

				<ModalRow>
					<SingleDatePickerInput
						id="activeFrom"
						label={phrases.MODAL_BATCH_STEP_ACTIVE_FROM}
						onChange={(event) => this.editStoreEntry(event, 'activeFrom')}
						selectedDate={moment
							.utc(batchFormData.activeFrom)
							.format(constants.shortDate)}
						noClockButton
					/>
				</ModalRow>

				<ModalRow>
					<SingleDatePickerInput
						id="activeTo"
						label={phrases.MODAL_BATCH_STEP_ACTIVE_TO}
						onChange={(event) => this.editStoreEntry(event, 'activeTo')}
						selectedDate={moment
							.utc(batchFormData.activeTo || '9999-12-31')
							.format(constants.shortDate)}
						noClockButton
					/>
				</ModalRow>
			</>
		);
	}
}

WorkplacePosConfigurationBatchStepFormAdd.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	productRecommendationUrl: PropTypes.string,
	tileLayoutUrl: PropTypes.string,
	ingredientConfigurationUrl: PropTypes.string,
};

export default WorkplacePosConfigurationBatchStepFormAdd;
