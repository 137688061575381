'use strict';

import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './posConfigurationAssociatedIngredients.phrases';

/**
 * @function fetchPosConfigurationIngredientAddons
 * @description Fetch associated ingredients for current product variant
 */
export function fetchPosConfigurationIngredientAddons(productVariantId, state) {
	const preFilter = `:pos_configuration_product_variant.id=='${productVariantId}'`;
	const filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get('/pos/pos_configuration_ingredient_addons', params)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addPosConfigurationIngredientAddons
 * @description adds associated ingredient
 */
export function addPosConfigurationIngredientAddons(data) {
	return post('/pos/pos_configuration_ingredient_addons', data)
		.then((res) => {
			setFeedback(phrases.ASSOCIATED_INGREDIENT_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editPosConfigurationIngredientAddons
 * @description edits associated ingredient
 */
export function editPosConfigurationIngredientAddons(data) {
	return post(`/pos/pos_configuration_ingredient_addons/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.ASSOCIATED_INGREDIENT_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deletePosConfigurationIngredientAddons
 * @description edits associated ingredient
 */
export function deletePosConfigurationIngredientAddons(associatedIngredientId) {
	return remove(
		`/pos/pos_configuration_ingredient_addons/${associatedIngredientId}`
	)
		.then((res) => {
			setFeedback(phrases.ASSOCIATED_INGREDIENT_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
