'use strict';

import { SET_PERSONS } from './myShiftplannerPersons.actions';

const defaultState = {
	persons: [],
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
		case SET_PERSONS: {
			return {
				...state,
				persons: [...payload.data],
			};
		}

		default:
			return state;
	}
}

export default reducer;
