'use strict';

import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const Head = ({ component, table, tables }) => {
	/**
	 * Get column groups
	 * @param  {array} groupContainer
	 */
	const getGroups = groupContainer =>
		groupContainer.map((group, key) => (
			<th
				key={key + group.type}
				className={cx('report-old__group-cell', {
					...group.getModifierClassNames({})
				})}
				colSpan={group.getLength()}
				style={{ width: group.getWidth(tables) + 'px' }}>
				{group.name}
			</th>
		));

	/**
	 * Get column headers
	 * @param  {array} columnContainer
	 */
	const getHeaders = columnContainer =>
		columnContainer.map((column, key) => {
			if (column.colSpan > 0) {
				const styles = cx('report-old__header-cell', {
					'report-old__header-cell--currency':
						column.contentType === 'currency',
					'report-old__header-cell--number': column.contentType === 'number',
					'report-old__header-cell--mixed': column.contentType === 'mixed',
					'report-old__header-cell-left': column.contentType === 'left',
					...column.getModifierClassNames({})
				});
				return (
					<th
						key={key + column.type}
						className={styles}
						colSpan={column.colSpan}
						style={{
							width: column.getWidth(tables) ? column.getWidth(tables) : 'auto'
						}}>
						{column.getName(table.meta)}
					</th>
				);
			} else return null;
		});

	/**
	 * Parses header from layout object
	 */
	const parseHead = () => {
		// Type of children on layout object decides what to render (column groups or not)
		switch (component.getChildrenType()) {
		case 'group':
			return [
				<tr className="report-old__groups" key="groups">
					{getGroups(component.content)}
				</tr>,
				<tr className="report-old__headers" key="headers">
					{component.content.map(group => getHeaders(group.content))}
				</tr>
			];
		case 'column':
			return (
				<tr className="report-old__headers">
					{getHeaders(component.content)}
				</tr>
			);
		}
	};

	return <thead>{parseHead()}</thead>;
};

Head.propTypes = {
	component: PropTypes.object.isRequired,
	table: PropTypes.object.isRequired,
	tables: PropTypes.object.isRequired
};

export default Head;
