import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import AddGroup from './../../../addGroupModal/addGroupModal';
import PosMenuItem from './posMenuItem/posMenuItem.component';

import { store } from 'appState';
import { editGroup } from './../../../addGroupModal/store/addGroupModal.actions';

import './posMenu.css';

class PosMenu extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			showAddGroupModal: false
		};

		this.toggleAddGroupModal = this.toggleAddGroupModal.bind(this);
	}

	/**
	 * toggleAddGroupModal
	 * @param groupId
	 */
	toggleAddGroupModal(e, groupId = null) {
		this.setState(prevState => ({
			showAddGroupModal: !prevState.showAddGroupModal
		}));

		if (groupId) {
			const activeGroup = this.props.items.find(item => item.id === groupId);

			this.setState(() => ({
				activeGroup
			}));

			store.dispatch(editGroup(activeGroup));
		}
	}

	render() {
		const posMenuClassnames = cx('pos-menu', {});
		const { showAddGroupModal, activeGroup } = this.state;
		const {
			addLayoutGroup,
			updateLayoutGroup,
			deleteLayoutGroup,
			items,
			setMenuItemInFocus,
			itemInFocus
		} = this.props;

		return (
			<div className={posMenuClassnames}>
				<div className="pos-menu__inner">
					{items.map((item, i) => {
						const isFocused = item.id === itemInFocus;

						// It item is a temporary menu item at is is not in focus, dont show it.
						if (itemInFocus !== item.id && item.temporary) return;

						return (
							<PosMenuItem
								key={i}
								itemInFocus={isFocused}
								setMenuItemInFocus={setMenuItemInFocus}
								item={item}
								deleteLayoutGroup={deleteLayoutGroup}
								editPosMenuItem={e => this.toggleAddGroupModal(e, item.id)}
							/>
						);
					})}
				</div>

				<button
					className="button button--primary"
					onClick={this.toggleAddGroupModal}>
					Add Group
				</button>

				{showAddGroupModal && (
					<AddGroup
						handleClose={this.toggleAddGroupModal}
						visible={showAddGroupModal}
						groupData={activeGroup}
						title="Add Group"
						nextPosition={items.length + 1}
						updateLayoutGroup={updateLayoutGroup}
						addLayoutGroup={addLayoutGroup}
						isTypePos
					/>
				)}
			</div>
		);
	}
}

PosMenu.propTypes = {
	addLayoutGroup: PropTypes.func,
	updateLayoutGroup: PropTypes.func,
	deleteLayoutGroup: PropTypes.func,
	items: PropTypes.array,
	setMenuItemInFocus: PropTypes.func,
	itemInFocus: PropTypes.number
};

PosMenu.defaultProps = {
	items: []
};

export default PosMenu;
