'use strict';

import React from 'react';
import PropTypes from 'prop-types';

// components
import { Button, Input, Icon, Tooltip } from 'dumb';
import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';

// phrases/ constants
import phrases from './../../../shiftClockinCorrectionOptions.phrases';

const ShiftClockinCorrectionOptionsBatchStepList = ({ batchValues, editBatchValue, deleteRow, addRow }) => {
	const onRemoveEntry = (id) => () => {
		deleteRow(id);
	};

	const editListEntry = (row, type) => (e) => {
		const value = e?.target?.value ?? e;

		// edits the row in the store
		editBatchValue({
			...row,
			[type]: value,
		});
	};

	return (
		<>
			<TableList>
				<thead>
					<tr>
						<th />
						<th>{phrases.COMMENT}</th>

						<th />
					</tr>
				</thead>
				<tbody>
					{batchValues.map((entry, i) => {
						const editButtonDisabled = batchValues.length === 1;

						return (
							<TableListRow key={entry.id}>
								<TableListCell>{i + 1}</TableListCell>

								<TableListCell>
									<Input
										id={`comment-${entry.id}`}
										placeholder={phrases.COMMENT_PLACEHOLDER}
										value={entry.comment || ''}
										onChange={editListEntry(entry, 'comment')}
										required
									/>
								</TableListCell>

								<TableListCell>
									<Button type="negative" size="tiny" disabled={editButtonDisabled} onClick={onRemoveEntry(entry.id)}>
										<Icon name="delete" />
									</Button>
								</TableListCell>
							</TableListRow>
						);
					})}
				</tbody>
			</TableList>
			<div className="modal-batch__add-new-row">
				<Tooltip
					text={phrases.MODAL_BATCH_ADD_ROW_TOOLTIP}
					zIndex="600"
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							type="secondary"
							onClick={addRow}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
							refProp={ref}
						>
							<Icon name="add" />
						</Button>
					)}
				/>
			</div>
		</>
	);
};

ShiftClockinCorrectionOptionsBatchStepList.propTypes = {
	batchValues: PropTypes.array,
	editBatchValue: PropTypes.func,
	deleteRow: PropTypes.func,
	addRow: PropTypes.func,
};

export default ShiftClockinCorrectionOptionsBatchStepList;
