export default function getColumns() {
	return [
		{
			Header: 'Name',
			id: 'name',
			accessor: 'name',
			filterPath: ':name',
		},
		{
			Header: 'Description',
			id: 'description',
			accessor: 'description',
			filterPath: ':description',
		},
		{
			Header: 'Code',
			id: 'code',
			accessor: 'code',
			filterPath: ':code',
		},
	];
}
