'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ButtonLoader, Button, Icon } from 'dumb';

class GoldModelDownloadCsv extends Component {
	constructor(props) {
		super(props);

		this.state = {
			downloadingCsv: false,
		};
		this.handleDownloadCsv = this.handleDownloadCsv.bind(this);
	}

	handleDownloadCsv() {
		this.setState(() => ({ downloadingCsv: true }));
		this.props
			.handleFetchCsv()
			.then(() => {
				this.setState(() => ({ downloadingCsv: false }));
			})
			.catch(() => {
				this.setState(() => ({ downloadingCsv: false }));
			});
	}

	render() {
		return (
			<aside className="gold-model__controls">
				<ul>
					<li data-label="Download">
						<Button
							shadow
							type="inverted"
							onClick={this.handleDownloadCsv}>
							{this.state.downloadingCsv ? (
								<ButtonLoader
									loading={this.state.downloadingCsv}
									theme="dark"
								/>
							) : (
								<Icon name="file_download" />
							)}
						</Button>
					</li>
				</ul>
			</aside>
		);
	}
}

GoldModelDownloadCsv.propTypes = {
	handleFetchCsv: PropTypes.func.isRequired,
};

export default GoldModelDownloadCsv;
