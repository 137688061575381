'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { SingleDatePickerInput, Textarea, InputCollectionSelect } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';

import getMarketColumns from 'reactDataWrapperColumns/administration/markets.columns';
import getShiftTransferPoliciesColumns from 'reactDataWrapperColumns/shiftplanning/shiftTransferPolicies.columns';

import {
	fetchShiftTransferPolicies,
	addShiftTransferPolicy,
	deleteShiftTransferPolicy,
	editShiftTransferPolicy,
} from './shiftTransferPolicies.service';

import {
	setShiftTransferPolicy,
	updateShiftTransferPolicy,
	resetShiftTransferPolicy,
} from './store/shiftTransferPolicies.actions';

// constants/phrases/tools
import phrases from './shiftTransferPolicies.phrases';
import constants from 'services/constants';
import moment from 'moment';
import enums from './shiftTransferPolicies.enums';
import { getSelectOptionsFromEnums, getEditedValues } from 'services/utils';

import './style.css';

const reduxKey = '/shiftplanning/shift_transfer_policies';

class ShiftTransferPolicies extends Component {
	constructor(props) {
		super(props);

		this.fetchData = this.fetchData.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.getEditableCells = this.getEditableCells.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.getExtraFilters = this.getExtraFilters.bind(this);

		this.today = moment.utc().format(constants.shortDate);
		this.infinity = moment.utc('9999-12-31').format(constants.shortDate);

		this.marketOverlayReduxKey = 'shift_transfer_policies-/administration/markets';

		this.columns = getShiftTransferPoliciesColumns(reduxKey, enums);
	}

	addEntry() {
		const { shiftTransferPolicy } = this.props;

		const payload = {
			...(shiftTransferPolicy.market && {
				market: shiftTransferPolicy.market?.value?.id,
			}),
			type: shiftTransferPolicy.type?.value,
			text: shiftTransferPolicy.text,
			period: {
				from: shiftTransferPolicy.activeFrom
					? moment.utc(shiftTransferPolicy.activeFrom).format(constants.shortDate)
					: this.today,
				to: shiftTransferPolicy.activeTo
					? moment.utc(shiftTransferPolicy.activeTo).format(constants.shortDate)
					: this.infinity,
			},
		};

		return addShiftTransferPolicy(payload);
	}

	editEntry() {
		const { shiftTransferPolicy, originalShiftTransferPolicy } = this.props;

		const editedValues = getEditedValues({
			newData: shiftTransferPolicy,
			oldData: originalShiftTransferPolicy,
		});

		const payload = {
			id: shiftTransferPolicy.id,
			...(editedValues.activeTo && {
				period: {
					to: moment.utc(shiftTransferPolicy.activeTo).format(constants.shortDate),
				},
			}),
		};

		return editShiftTransferPolicy(payload);
	}

	deleteEntry(id) {
		return deleteShiftTransferPolicy(id);
	}

	setInitialEditValues(data) {
		const { setShiftTransferPolicy } = this.props;

		const payload = {
			id: data.id,
			market: data.market.name,
			type: data.type,
			text: data.text,
			activeFrom: data.period.from,
			activeTo: data.period.to,
		};

		setShiftTransferPolicy(payload);
	}

	getEditableCells() {
		const { shiftTransferPolicy } = this.props;

		return [
			{
				header: 'Market',
				value: (
					<InputCollectionSelect
						id="market"
						label="Market"
						placeholder={phrases.MARKET}
						clearable={false}
						value={shiftTransferPolicy?.market || null}
						handleChange={(key, e) => this.editStoreEntry('market', e)}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						apiPath="/organization/markets"
						tableTitle={phrases.MARKETS_TABLE_TITLE}
						tableColumns={getMarketColumns(this.marketOverlayReduxKey)}
						tableReduxKey={this.marketOverlayReduxKey}
					/>
				),
			},
			{
				header: 'Type',
				value: (
					<InputCollectionSelect
						id="type"
						placeholder="Select type..."
						value={shiftTransferPolicy?.type || ''}
						onChange={(e) => this.editStoreEntry('type', e)}
						options={getSelectOptionsFromEnums(enums)}
					/>
				),
			},
			{
				header: 'Text',
				value: (
					<Textarea
						id="text"
						rows={3}
						placeholder="Enter text..."
						value={shiftTransferPolicy?.text}
						onChange={(e) => this.editStoreEntry('text', e.target.value)}
					/>
				),
			},
			{
				header: 'Active from',
				value: (
					<SingleDatePickerInput
						id="activeFrom"
						onChange={(event) => this.editStoreEntry('activeFrom', event)}
						selectedDate={
							shiftTransferPolicy.activeFrom
								? moment.utc(shiftTransferPolicy.activeFrom).format(constants.shortDate)
								: ''
						}
						noClockButton
					/>
				),
			},
			{
				header: 'Active to',
				value: (
					<SingleDatePickerInput
						id="activeTo"
						onChange={(event) => this.editStoreEntry('activeTo', event)}
						selectedDate={
							shiftTransferPolicy.activeTo ? moment.utc(shiftTransferPolicy.activeTo).format(constants.shortDate) : ''
						}
						noClockButton
					/>
				),
			},
		];
	}

	getEditableCellsEdit() {
		const { shiftTransferPolicy } = this.props;

		return [
			{
				header: 'Market',
				value: <span>{shiftTransferPolicy.market}</span>,
			},
			{
				header: 'Type',
				value: <span>{shiftTransferPolicy.type}</span>,
			},
			{
				header: 'Text',
				value: (
					<div className="shift-transfer-policies__text">
						<span>{shiftTransferPolicy.text}</span>
					</div>
				),
			},
			{
				header: 'Active from',
				value: <span>{shiftTransferPolicy.activeFrom}</span>,
			},
			{
				header: 'Active to',
				value: (
					<SingleDatePickerInput
						id="activeTo"
						onChange={(event) => this.editStoreEntry('activeTo', event)}
						selectedDate={
							shiftTransferPolicy.activeTo ? moment.utc(shiftTransferPolicy.activeTo).format(constants.shortDate) : ''
						}
						noClockButton
					/>
				),
			},
		];
	}

	editStoreEntry(name, value) {
		const { updateShiftTransferPolicy } = this.props;

		const payload = {
			[name]: value,
		};

		updateShiftTransferPolicy(payload);
	}

	fetchData(state) {
		return fetchShiftTransferPolicies(state);
	}

	getExtraFilters() {
		const { customFilters } = this.props;

		let typeFilter;
		if (customFilters.type) typeFilter = `:type=='${customFilters.type.value}'`;

		return typeFilter;
	}

	render() {
		return (
			<ReactDataWrapper
				title={phrases.TABLE_TITLE}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={10}
				reduxKey={reduxKey}
				manual
				editableCells={this.getEditableCells()}
				editableCellsEdit={this.getEditableCellsEdit()}
				accessAreasAllowedToEdit={['Global HR Configuration']}
				createEntry={this.addEntry}
				editEntry={this.editEntry}
				deleteEntry={this.deleteEntry}
				editMultiple={this.editMultiple}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetShiftTransferPolicy}
				extraFilters={this.getExtraFilters()}
			/>
		);
	}
}

ShiftTransferPolicies.propTypes = {
	setShiftTransferPolicy: PropTypes.func,
	resetShiftTransferPolicy: PropTypes.func,
	updateShiftTransferPolicy: PropTypes.func,
	shiftTransferPolicy: PropTypes.object,
	originalShiftTransferPolicy: PropTypes.object,
	customFilters: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setShiftTransferPolicy,
			updateShiftTransferPolicy,
			resetShiftTransferPolicy,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		shiftTransferPolicy: store.globalAdministrationShiftTransferPolicies.data.shiftTransferPolicy,
		originalShiftTransferPolicy: store.globalAdministrationShiftTransferPolicies.data.originalShiftTransferPolicy,
		customFilters: store.filterSortColumnsData.tables?.[reduxKey]?.custom ?? {},
	};
};

export default connectWithStore(ShiftTransferPolicies, mapStateToProps, mapDispatchToProps);
