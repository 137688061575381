'use strict';

import { get, post, remove, patch } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './productBundleSets.phrases';

/**
 * @function fetchProductBundleSets
 * @description Fetch product bundle sets for a product bundle
 */
export function fetchProductBundleSets(extraFilter, state) {
	const filter = state.filter ? `${state.filter};${extraFilter}` : extraFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter
	};

	return get('/product/bundle_sets', params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

export function fetchProductRecommendations(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get('/pos/product_recommendations', params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editProductBundleSet
 * @param {Object} payload - update product bundle
 */
export function editProductBundleSet(payload) {
	return post(`/product/bundle_sets/${payload.id}`, payload)
		.then(res => {
			setFeedback(phrases.PRODUCT_BUNDLE_SET_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editProductBundleSets
 * @param {Object} payload - update multiple product bundle sets
 */
export function editProductBundleSets(payload) {
	return patch('/product/bundle_sets', payload)
		.then(res => {
			setFeedback(phrases.PRODUCT_BUNDLE_SETS_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteProductBundleSet
 * @param {Number} id - id of a product bundle set to delete
 */
export function deleteProductBundleSet(id) {
	return remove(`/product/bundle_sets/${id}`)
		.then(res => {
			setFeedback(phrases.PRODUCT_BUNDLE_SET_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

export function addSalesConfigurationdefaultProductBundleSet(data) {
	return post('/product/bundle_sets', data)
		.then(res => {
			setFeedback(phrases.PRODUCT_BUNDLE_SETS_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
