import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchAllProductAddons
 * @description Fetch all ingredient addons
 * @param {object} state
 */
export function fetchAllNutritionCategories(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get(`/product/nutrition_categories`, params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editNutritionCategories
 * @description edit
 */
export function editNutritionCategories(categoryId, data) {
	return post(`/product/nutrition_categories/${categoryId}`, data)
		.then(res => {
			setFeedback('Nutrition Category edited', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function addNutritionCategories
 * @description add
 */
export function addNutritionCategories(data) {
	return post(`/product/nutrition_categories`, data)
		.then(res => {
			setFeedback('Nutrition Category added', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteNutritionCategories
 * @description edit
 */
export function deleteNutritionCategories(addonId) {
	return remove(`/product/nutrition_categories/${addonId}`)
		.then(res => {
			setFeedback('Nutrition Category deleted', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
