'use strict';

import { FileTypeResultSet } from 'fileUpload/resultSets';

/**
 * FileUpload
 * @param {string} name
 */
class FileType {
	constructor(name, extensions = [], defaultFileName = null, showAsDefault = false, id = null) {
		// Set type
		this.type = 'fileType';

		// Name
		this.name = name;

		// Extensions
		this.extensions = extensions === null ? [] : extensions;

		// Default fileName
		this.defaultFileName = defaultFileName;

		// Show as default
		this.showAsDefault = showAsDefault;

		// Set file count to 0
		this.fileTypeCount = 0;

		// Number default
		this.number = 0;

		// ID
		this.id = id;

		// File
		this.setFile(null);
		this.setFileName(null);
		this.setFileExtension(null);
	}

	/////////////
	// Setters //
	/////////////

	/**
	 * @function setFile
	 * @memberOf FileType
	 * @description Sets file
	 */
	setFile(file) {
		this.file = file;
	}

	/**
	 * @function setFileName
	 * @memberOf FileType
	 * @description Sets file name
	 */
	setFileName(fileName) {
		this.fileName = fileName;
	}

	/**
	 * @function setFileExtension
	 * @memberOf FileType
	 * @description Sets file extension
	 */
	setFileExtension(fileExtension) {
		this.fileExtension = fileExtension;
	}

	/**
	 * @function setNumber
	 * @memberOf FileType
	 * @description Sets number (based on file type count)
	 */
	setNumber(number) {
		this.number = number;
	}

	/////////////
	// Getters //
	/////////////

	/**
	 * @function getName
	 * @memberOf FileType
	 * @description Gets name
	 */
	getName() {
		return this.name;
	}

	/**
	 * @function getFile
	 * @memberOf FileType
	 * @description Gets file
	 */
	getFile() {
		return this.file;
	}

	/**
	 * @function getFileName
	 * @memberOf FileType
	 * @description Gets file name
	 */
	getFileName() {
		return this.fileName;
	}

	/**
	 * @function getFileExtension
	 * @memberOf FileType
	 * @description Gets file name
	 */
	getFileExtension() {
		return this.fileExtension;
	}

	/**
	 * @function getFileTypeCount
	 * @memberOf FileType
	 * @description Gets file type count
	 */
	getFileTypeCount() {
		return this.fileTypeCount;
	}

	/**
	 * @function getExtensionsAsString
	 * @memberOf FileType
	 * @description Gets extensions
	 */
	getExtensions() {
		return this.extensions;
	}

	/**
	 * @function getShowAsDefault
	 * @memberOf FileType
	 * @description Gets show as default bool
	 */
	getShowAsDefault() {
		return this.showAsDefault;
	}

	/**
	 * @function getExtensionsAsString
	 * @memberOf FileType
	 * @description Gets extensions joined with a /
	 */
	getExtensionsAsString() {
		return this.getExtensions().length === 0
			? '*'
			: this.getExtensions().join('/');
	}

	/**
	 * @function getExtensionsAsAccept
	 * @memberOf FileType
	 * @description Gets extensions formatted as accept attribute content
	 */
	getExtensionsAsAccept() {
		return this.getExtensions().length === 0
			? '*'
			: this.getExtensions().map(extension => `.${extension}`).join(',');
	}

	/**
	 * @function getDefaultFileName
	 * @memberOf FileType
	 * @description Gets default file name based on string or method
	 */
	getDefaultFileName(data) {
		switch (typeof this.defaultFileName) {
			case 'function':
				return data !== null ? this.defaultFileName(data).replace(/\s/g, '_') : null;
			case 'string':
				return this.defaultFileName.replace(/\s/g, '_');
			default:
				return null;
		}
	}

	/**
	 * @function getNumber
	 * @memberOf FileType
	 * @description Gets file type number
	 */
	getNumber() {
		return this.number;
	}

	/**
	 * @function getId
	 * @memberOf FileType
	 * @description Gets file type id (if list is dynamically typically)
	 */
	getId() {
		return this.id;
	}

	/**
	 * @function getResultSet
	 * @memberOf FileType
	 * @description Gets resultSet for file type
	 */
	getResultSet() {
		return new FileTypeResultSet(this);
	}

	/**
	 * @function getIdentifier
	 * @memberOf FileType
	 * @description Gets unique identifier for fileType
	 */
	getIdentifier() {
		return `${this.getName()}_${this.getNumber()}`;
	}

	////////////////////
	// Event handlers //
	////////////////////

	/**
	 * @function handleSetFile
	 * @memberOf FileType
	 * @description Wrapper for setting file, filename and fileExtension
	 */
	handleSetFile(file, fileName, fileExtension) {
		this.setFile(file);
		this.setFileName(fileName);
		this.setFileExtension(fileExtension);
	}

	///////////////
	// Utilities //
	///////////////

	/**
	 * @function addToCount
	 * @memberOf FileType
	 * @description Adds to number of fileTypes selected of same type
	 */
	addToCount() {
		this.fileTypeCount = this.fileTypeCount + 1;
	}

	/**
	 * @function resetCount
	 * @memberOf FileType
	 * @description Resets count for file type
	 */
	resetCount() {
		this.fileTypeCount = 0;
	}

	/**
	 * @function extractFileExtension
	 * @memberOf FileType
	 * @description Extracts file extension if file has any - otherwise null
	 */
	extractFileExtension(fileName) {
		const fileNameDots = fileName === null ? [] : fileName.split('.');
		return fileNameDots.length > 1 ? fileNameDots.pop() : null;
	}

	/**
	 * @function validateFileExtension
	 * @memberOf FileType
	 * @description Validates file extension based on set extensions
	 */
	validateFileExtension(fileExtension) {
		// Return true if no extensions are set (all allowed)
		return this.getExtensions().length === 0
			? true
			: this.getExtensions().indexOf(fileExtension.toLowerCase()) > -1;
	}

	/**
	 * @function removeExtension
	 * @memberOf FileType
	 * @description Removes file extension from file
	 */
	removeExtension(fileName, fileExtension) {
		return fileExtension === null ? fileName : fileName.split(`.${fileExtension}`).join('');
	}

}
export default FileType;