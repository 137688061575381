// constants/ phrases
import constants from 'services/constants';
// moment
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';

import enums from './../shiftClockinsTableColumns.enums';

/**
 * @function getDate
 * @param {Object} { type, entry } - type is from/to and entry is the row object
 * @description returns a value to be shown in final cell. First it checks if
 * final.from/to is present, if not it looks for clocked and if that's not set it returns
 * planned.from/to. Otherwise null
 * @returns string/null
 */
export function getDate({ type, entry }) {
	// check actual final value
	const finalDateMoment = moment(entry.final[type], constants.dateFormat);
	if (finalDateMoment.isValid())
		return finalDateMoment.format(constants.shortDate);

	// check clocked value
	const clockedDateMoment = moment(entry.clocked[type], constants.dateFormat);
	if (clockedDateMoment.isValid())
		return clockedDateMoment.format(constants.shortDate);

	// check planned value
	const plannedDateMoment = moment(entry.planned[type], constants.dateFormat);
	if (plannedDateMoment.isValid())
		return plannedDateMoment.format(constants.shortDate);

	return null;
}

export function getManagedClockinPayload({ row }) {
	// check if to value is before from
	const momentFrom = moment.utc(row.final?.from, constants.dateFormat);
	let momentTo = moment.utc(row.final?.to, constants.dateFormat);

	// if moment to is before from, means that it ends on a second day so add a day
	if (momentTo.isBefore(momentFrom, 'minute')) {
		momentTo = moment.utc(momentTo).add(1, 'days');
	}

	return {
		id: row.id,
		final: {
			from: momentFrom.format(constants.dateFormat),
			to: momentTo.format(constants.dateFormat),
		},
		...(!_isEmpty(row.breaks) && {
			breaks: row.breaks.map((entry) => {
				const breakFrom = entry.final?.from;
				const breakTo = entry.final?.to;

				// check if to value is before from
				const momentBreakFrom = moment.utc(breakFrom, constants.dateFormat);
				let momentBreakTo = moment.utc(breakTo, constants.dateFormat);

				// if moment to is before from, means that it ends on a second day so add a day
				if (momentBreakTo.isBefore(momentBreakFrom, 'minute')) {
					momentBreakTo = moment.utc(momentBreakTo).add(1, 'days');
				}

				return {
					id: entry.id,
					final: {
						from: momentBreakFrom.format(constants.dateFormat),
						to: momentBreakTo.format(constants.dateFormat),
					},
				};
			}),
		}),
	};
}

/**
 * @function getClockinWarningColor
 * @param {Object} Object.planned, Object.clocked, Object.type - periods to compare and type (from/to)
 * @description return null, green or red, depending on if we're losing money, gaining money or no action
 */
export function getClockinWarningColor({ planned, clocked, type }) {
	if (!planned || !clocked) return null;

	const plannedMoment = moment.utc(planned, constants.dateFormat);
	const clockedMoment = moment.utc(clocked, constants.dateFormat);

	if (type === enums.FROM) {
		if (clockedMoment.isBefore(plannedMoment, 'minutes'))
			return _getColorRgb('--danger');
		else if (clockedMoment.isSame(plannedMoment, 'minutes')) return null;
		else return _getColorRgb('--success');
	}
	if (type === enums.TO) {
		if (clockedMoment.isAfter(plannedMoment, 'minutes'))
			return _getColorRgb('--danger');
		else if (clockedMoment.isSame(plannedMoment, 'minutes')) return null;
		else return _getColorRgb('--success');
	}
}

/**
 * @function getBreakWarningColor
 * @param {String} Object.breakPlanned - planned break time
 * @param {String} Object.breakClocked - clocked break time
 * @description return null, green or red, depending on if we're losing money, gaining money or no action
 */
export function getBreakWarningColor({ breakPlanned, breakClocked }) {
	const plannedFromMoment = moment.utc(breakPlanned.from, constants.dateFormat);
	const plannedToMoment = moment.utc(breakPlanned.to, constants.dateFormat);
	const clockedFromMoment = moment.utc(breakClocked.from, constants.dateFormat);
	const clockedToMoment = moment.utc(breakClocked.to, constants.dateFormat);

	const plannedDuration = moment
		.duration(plannedToMoment.diff(plannedFromMoment))
		.asMinutes();
	const clockedDuration = moment
		.duration(clockedToMoment.diff(clockedFromMoment))
		.asMinutes();

	if (clockedDuration === plannedDuration) return null;
	if (clockedDuration > plannedDuration) return _getColorRgb('--danger');
	if (clockedDuration < plannedDuration) return _getColorRgb('--success');
}

export function getIsApproveButtonDisabled({ loading, row }) {
	if (row.status === enums.ALIGNED || row.status === enums.UNPLANNED)
		return true;

	const finalFrom = row.final?.from;
	const finalTo = row.final?.to;

	const periodDeviationsApproved = finalFrom && finalTo;

	let allBreakDeviationsApproved = true;
	// if shift breaks are present, ensure they are approved too
	if (!_isEmpty(row.breaks)) {
		allBreakDeviationsApproved = row.breaks.every((entry) => {
			const breakFinalFrom = entry.final?.from;
			const breakFinalTo = entry.final?.to;

			if (breakFinalFrom && breakFinalTo) return true;
		});
	}

	const buttonEnabled =
		periodDeviationsApproved && allBreakDeviationsApproved && !loading;

	return !buttonEnabled;
}

export function getAreClockinsOnSameDay({ clockedFrom, clockedTo }) {
	const clockedDateFrom = moment.utc(clockedFrom, constants.dateFormat);
	const clockedDateTo = moment.utc(clockedTo, constants.dateFormat);
	const clockedValuesNull = clockedFrom === null && clockedTo === null;

	const clockedValuesOnSameDay = clockedDateFrom.isSame(clockedDateTo, 'day');

	if (clockedValuesNull) return true;

	return clockedValuesOnSameDay;
}

function _getColorRgb(colorName) {
	return window.getComputedStyle(document.body).getPropertyValue(colorName);
}
