'use strict';
import { set as setFeedback } from 'feedback.vanilla.service';
import _floor from 'lodash/floor';
import _isNumber from 'lodash/isNumber';

/**
 * @function navigate
 * @memberOf SERVICES.__models
 * @description
 * Navigates on paginated lists
 * @param  {string/number} indicator  indicates where to navigate
 */

module.exports = function (indicator) {
	return new Promise((resolve, reject) => {
		var state = this.getState();
		var tenth = _floor(state.collectionLength / 10);
		var roundedTenth = (state.params.limit % tenth) * state.params.limit;
		var distance;
		switch (indicator) {
			// move forward one page
			case '+':
				this.setParamOffset(state.params.offset + state.params.limit);
				break;

			// move backward one page
			case '-':
				this.setParamOffset(state.params.offset - state.params.limit);
				break;

			// move forward a tenth part of all pages
			case '++':
				var allPages = _floor(state.collectionLength / state.params.limit) + 1;
				var fallBackDistance = (allPages - 1) * state.params.limit;
				distance =
					state.params.offset + roundedTenth > state.collectionLength
						? fallBackDistance
						: state.params.offset + roundedTenth;
				this.setParamOffset(distance);
				break;

			// move backward a tenth part of all pages
			case '--':
				distance =
					state.params.offset - roundedTenth < 0
						? 0
						: state.params.offset - roundedTenth;
				this.setParamOffset(distance);
				break;

			// move to specific page number
			default:
				this.setParamOffset(
					_isNumber(indicator) ? indicator * state.params.limit : 0
				);
				break;
		}

		// Get collection again with updated filters
		this.loadCollection().then(
			function (collectionData) {
				resolve(collectionData);
			},
			function (response) {
				setFeedback(response.code + ': ' + response.message, 0);
				reject(response);
			}
		);
	});
};
