'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonGroup, InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';

import phrases from './../../../ingredients.phrases';

import _isEmpty from 'lodash/isEmpty';

class SalesConfigurationPosConfigurationDiscountsBatchIngredient extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false
		};
	}

	editStoreEntry(value, type) {
		const { updateBatchForm, resetSelection, ingredientUrl } = this.props;

		// if we cleared the select, also clear the selected rows in overlay table
		if (type === 'ingredient' && _isEmpty(value)) {
			resetSelection(ingredientUrl);
		}

		updateBatchForm({
			[type]: value
		});
	}

	render() {
		const {
			batchFormData,
			toggleOverlay,
			ingredientUrl,
			ingredientsLegacyFilter
		} = this.props;

		return (
			<>
				<ModalRow>
					<ButtonGroup type="full-width">
						<InputCollectionSelect
							id="posConfigurationVersion"
							className="modal-batch-select"
							label={phrases.MODAL_BATCH_STEP_INGREDIENT}
							placeholder={phrases.MODAL_BATCH_STEP_INGREDIENT_PLACEHOLDER}
							value={batchFormData.ingredient}
							handleChange={(key, value) =>
								this.editStoreEntry(value, 'ingredient')
							}
							required
							multi
							cache
							apiPath={ingredientUrl}
							params={{
								limit: 30,
								filter: ingredientsLegacyFilter
							}}
							optionFormat={entry => ({
								value: entry,
								label: entry.name
							})}
							inputFilterFormat={input => `:name=like='%${input}%'`}
						/>

						<Button
							type="inverted"
							shadow
							size="large"
							onClick={() => toggleOverlay(ingredientUrl)}>
							<span className="icon icon--more" />
						</Button>
					</ButtonGroup>
				</ModalRow>
			</>
		);
	}
}

SalesConfigurationPosConfigurationDiscountsBatchIngredient.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
	resetSelection: PropTypes.func,
	ingredientUrl: PropTypes.string,
	ingredientsLegacyFilter: PropTypes.string
};

export default SalesConfigurationPosConfigurationDiscountsBatchIngredient;
