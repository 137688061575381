'use strict';
import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';
import MailinglistRecipientsList from './services/mailinglistRecipientsList.service.js';

const __apiFilter = require('apiFilter.service');

const mailinglistRecipientsShiftplannerReportReduxKey = '/administration/mailinglist_recipients/shiftplanner-report';
const mailinglistRecipientsShiftplannerReportList = new MailinglistRecipientsList(
	mailinglistRecipientsShiftplannerReportReduxKey
);
const mailinglistRecipientsShiftplannerReportListProps = mailinglistRecipientsShiftplannerReportList.getProps();

const mailinglistRecipientsKPIReportListReduxKey = '/administration/mailinglist_recipients/kpi-report';
const mailinglistRecipientsKPIReportList = new MailinglistRecipientsList(mailinglistRecipientsKPIReportListReduxKey);
const mailinglistRecipientsKPIReportListProps = mailinglistRecipientsKPIReportList.getProps();

const mailinglistRecipientsDailyReportOperationReduxKey =
	'/administration/mailinglist_recipients/daily-report-operation-report';
const mailinglistRecipientsDailyReportOperationList = new MailinglistRecipientsList(
	mailinglistRecipientsDailyReportOperationReduxKey
);
const mailinglistRecipientsDailyReportOperationListProps = mailinglistRecipientsDailyReportOperationList.getProps();

const mailinglistRecipientsDailyReportBoardReduxKey =
	'/administration/mailinglist_recipients/daily-report-board-report';
const mailinglistRecipientsDailyReportBoardList = new MailinglistRecipientsList(
	mailinglistRecipientsDailyReportBoardReduxKey
);
const mailinglistRecipientsDailyReportBoardListProps = mailinglistRecipientsDailyReportBoardList.getProps();

const shiftplannerTypeFilter = __apiFilter.create([
	{
		fields: [
			{
				field: 'type',
				operator: '==',
				data: 'Shiftplanner Report',
			},
		],
	},
]);

// Get common filter for querying where type = KPI Report
const kpiTypeFilter = __apiFilter.create([
	{
		fields: [
			{
				field: 'type',
				operator: '==',
				data: 'KPI Report',
			},
		],
	},
]);

// Get common filter for querying where type = Daily Report
const dailyReportOperationTypeFilter = __apiFilter.create([
	{
		fields: [
			{
				field: 'type',
				operator: '==',
				data: 'Daily Report Operation',
			},
		],
	},
]);

// Get common filter for querying where type = Daily Report
const dailyReportBoardTypeFilter = __apiFilter.create([
	{
		fields: [
			{
				field: 'type',
				operator: '==',
				data: 'Daily Report board',
			},
		],
	},
]);

export function MailinglistRecipientsListsContainer() {
	useEffect(() => {
		mailinglistRecipientsShiftplannerReportList.setFilterQuery(shiftplannerTypeFilter);
		mailinglistRecipientsShiftplannerReportList.setData({ type: 'KPI Report' });
		mailinglistRecipientsShiftplannerReportList.setTitle('KPI Report');
		mailinglistRecipientsShiftplannerReportList.initView();

		mailinglistRecipientsKPIReportList.setFilterQuery(kpiTypeFilter);
		mailinglistRecipientsKPIReportList.setData({ type: 'Shiftplanner Report' });
		mailinglistRecipientsKPIReportList.setTitle('Shiftplanner Report');
		mailinglistRecipientsKPIReportList.initView();

		mailinglistRecipientsDailyReportOperationList.setFilterQuery(dailyReportOperationTypeFilter);
		mailinglistRecipientsDailyReportOperationList.setData({ type: 'Daily Report Operation' });
		mailinglistRecipientsDailyReportOperationList.setTitle('Daily Report Operation');
		mailinglistRecipientsDailyReportOperationList.initView();

		mailinglistRecipientsDailyReportBoardList.setFilterQuery(dailyReportBoardTypeFilter);
		mailinglistRecipientsDailyReportBoardList.setData({ type: 'Daily Report Board' });
		mailinglistRecipientsDailyReportBoardList.setTitle('Daily Report Board');
		mailinglistRecipientsDailyReportBoardList.initView();
	}, []);

	return (
		<>
			<ListComponent
				reduxKey={mailinglistRecipientsShiftplannerReportReduxKey}
				{...mailinglistRecipientsShiftplannerReportListProps}
			/>

			<ListComponent
				reduxKey={mailinglistRecipientsKPIReportListReduxKey}
				{...mailinglistRecipientsKPIReportListProps}
			/>

			<ListComponent
				reduxKey={mailinglistRecipientsDailyReportOperationReduxKey}
				{...mailinglistRecipientsDailyReportOperationListProps}
			/>

			<ListComponent
				reduxKey={mailinglistRecipientsDailyReportBoardReduxKey}
				{...mailinglistRecipientsDailyReportBoardListProps}
			/>
		</>
	);
}
