import {
	SET_PRODUCT_SET,
	UPDATE_PRODUCT_SET,
	RESET_PRODUCT_SET
} from './productSets.actions';

const defaultState = {
	ui: {},
	data: {
		defaultProductSet: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_PRODUCT_SET: {
		return {
			...state,
			data: {
				...state.data,
				defaultProductSet: payload
			}
		};
	}

	case UPDATE_PRODUCT_SET: {
		return {
			...state,
			data: {
				...state.data,
				defaultProductSet: {
					...state.data.defaultProductSet,
					name: payload.name
				}
			}
		};
	}

	case RESET_PRODUCT_SET: {
		return {
			...state,
			data: {
				...state.data,
				defaultProductSet: {}
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
