'use strict';

import { ImportCsvModalResultSet } from 'importCsvModal/resultSets';
import phrases from 'importCsvModal/importCsvModal.phrases';

/**
 * ImportCsvModal
 * @param {string} name
 */
class ImportCsvModal {
	constructor(name, onChangeFunction = null) {
		// Set type
		this.type = 'importCsvModal';

		// Name
		this.name = name;

		this.setCsv(null);
		this.setOnChangeFunction(onChangeFunction);
		this.setCsvFileName(null);
		this.setExample(null);

	}

	/////////////
	// Setters //
	/////////////

	setCsv(file) {
		this.file = file;
	}

	setCsvFileName(fileName) {
		this.fileName = fileName;
	}

	setExample(example) {
		this.example = example;
	}

	setOnChangeFunction(onChangeFunction) {
		this.onChange = onChangeFunction;
	}


	/////////////
	// Getters //
	/////////////

	getExample() {
		return this.example;
	}

	getResultSet() {
		return new ImportCsvModalResultSet(this.file, this.fileName);
	}

	/**
	 * @function getPhrases
	 * @memberOf ImportCsvModal
	 * @description Getter for phrases
	 */
	getPhrases() {
		return phrases;
	}

	handleSetCsvProperties(file, fileName) {
		this.setCsv(file);
		this.setCsvFileName(fileName);
	}

	/**
	 * @function handleOnChange
	 * @description Returns resultset. Always return promise
	 */
	handleOnChange() {
		const resultSet = this.getResultSet();
		return this.onChange(resultSet);
	}

}

export default ImportCsvModal;