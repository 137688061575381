'use strict';

import moment from 'moment';
import constants from 'services/constants';
// enums
import enums from './../markets.enums';

import { SET_MARKETS, UPDATE_MARKETS, RESET_MARKETS } from './markets.actions';

const defaultState = {
	data: {
		defaultMarket: {},
		market: {
			name: '',
			taxLabel: '',
			opened: moment.utc().format(constants.shortDate),
			closed: '9999-12-31',
			minuteBasedShiftplaning: false,
			clockinAlignmentRequired: false,
			salaryExportFormat: {
				value: enums.SALARY_EXPORT_FORMAT.DNK,
				label: enums.SALARY_EXPORT_FORMAT.DNK,
			},
			receiptType: {
				value: enums.RECEIPT_TYPE.DEFAULT,
				label: enums.RECEIPT_TYPE.DEFAULT,
			},
		},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_MARKETS: {
			return {
				...state,
				data: {
					defaultMarket: payload,
					market: payload,
				},
			};
		}

		case UPDATE_MARKETS: {
			return {
				...state,
				data: {
					...state.data,
					market: {
						...state.data.market,
						...payload,
					},
				},
			};
		}

		case RESET_MARKETS: {
			return defaultState;
		}

		default:
			return state;
	}
}

export default reducer;
