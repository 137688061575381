'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// rdw actions
import { store } from 'appState';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';

// components
import { ModalStepper, Overlay, Button } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';

// different batch views
import ProductVariantDiscountSetBatchStepForm from './components/productVariantDiscountSetBatchStepForm';
import ProductVariantDiscountSetBatchStepList from './components/productVariantDiscountSetBatchStepList';

// phrases
import phrases from './../../productVariantDiscountSet.phrases';
import constants from 'services/constants';
import moment from 'moment';

// lodash
import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';
import _uniqBy from 'lodash/uniqBy';

class ProductVariantDiscountSetModalBatch extends Component {
	constructor(props) {
		super(props);

		this.today = moment.utc().format(constants.shortDate);
		this.discountUrl = '/sale/discounts';

		this.state = {
			showOverlay: false,
			overlayType: null,
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this.handleToggleOverlay = this.handleToggleOverlay.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.onOverlayOpen = this.onOverlayOpen.bind(this);
		this.handleToggleActiveFilter = this.handleToggleActiveFilter.bind(this);
		this._getIsBatchStepFormValid = this._getIsBatchStepFormValid.bind(this);
		this.onResetSelection = this.onResetSelection.bind(this);
		this._getIsBatchStepListValid = this._getIsBatchStepListValid.bind(this);
		this._batchSubmit = this._batchSubmit.bind(this);

		this.modalRef = React.createRef();

		this.columnsDiscounts = [
			{
				Header: 'Discount name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'desc',
				accessor: (d) => _get(d, 'description', ''),
				filterPath: ':description',
			},
			{
				Header: 'Type',
				id: 'type',
				accessor: (d) => _get(d, 'type', ''),
				filterPath: ':type',
			},
			{
				Header: 'Loyalty type',
				id: 'loyalty_type',
				accessor: (d) => _get(d, 'loyalty_type.name', ''),
				filterPath: ':loyalty_type.name',
			},
			{
				Header: 'Execution Order',
				id: 'execution_order',
				accessor: (d) => _get(d, 'execution_order', ''),
				filterPath: ':execution_order',
			},
			{
				Header: 'Max ingredient addons',
				id: 'max_ingredient_addons',
				accessor: (d) => _get(d, 'max_ingredient_addons', ''),
				filterPath: ':max_ingredient_addons',
			},
			{
				Header: 'Discount code',
				id: 'discount_code',
				accessor: (d) => _get(d, 'discount_code', ''),
				filterPath: ':discount_code',
			},
		];
	}

	handleToggleOverlay(type) {
		const { listDiscounts, updateBatchForm } = this.props;

		if (this.state.showOverlay) {
			const selectedRowsInList = _get(listDiscounts, 'ui.selectedRows', []);

			updateBatchForm({
				discounts: selectedRowsInList.map((entry) => {
					return { label: entry.name, value: entry };
				}),
			});
		}

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
			overlayType: prevState.overlayType ? '' : type,
		}));
	}

	onOverlayOpen() {
		const { batchFormData } = this.props;
		const { overlayType } = this.state;

		if (_isEmpty(batchFormData.discounts)) return;

		// set already selected data in rdw store as clicked
		batchFormData.discounts.map((entry) => {
			store.dispatch(
				actions.addRowToSelection({
					reduxKey: overlayType,
					data: entry.value,
				})
			);
		});
	}

	fetchData(state) {
		const { fetchDiscounts } = this.props;

		return fetchDiscounts(state);
	}

	_onSubmitForm() {
		const { batchFormData, addBatchList } = this.props;

		// get rid of duplciates
		const cleanDiscounts = _uniqBy(batchFormData.discounts, 'value.id');

		const listArray = cleanDiscounts.map((entry) => {
			return {
				identifier: _uniqueId('row-'), // used to identify what to edit on step#2
				discount: entry,
				setName: null,
				postingRow: false,
				rowPosted: false,
			};
		});

		addBatchList(listArray);

		this._goToStep(2);
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_batchSubmit() {
		const {
			batchList,
			handleClose,
			productVariant,
			addProductVariantDiscountSet,
		} = this.props;

		this.setState({ loadingModal: true });

		const payload = batchList.map((entry) => {
			return {
				product_variant: productVariant.id,
				discount_product_set: _get(entry, 'setName.value.id', null),
			};
		});

		const batchPayload = { batch: payload };

		return addProductVariantDiscountSet(batchPayload)
			.then(() => {
				this.setState({ loadingModal: false });
				handleClose();
			})
			.catch(() => {
				this.setState({ loadingModal: false });
			});
	}

	_getIsBatchStepFormValid() {
		const { batchFormData } = this.props;

		return !_isEmpty(batchFormData.discounts);
	}

	handleToggleActiveFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getAdditionalPosConfigurationTableFilters() {
		const { legacyFilterOn } = this.state;

		return (
			<div className="salary-component__filters ">
				<Button
					type={legacyFilterOn ? '' : 'inverted'}
					shadow
					title="Filter active"
					onClick={this.handleToggleActiveFilter}
				>
					{phrases.MODAL_BATCH_STEP_FORM_DISCOUNTS_FILTER}
				</Button>
			</div>
		);
	}

	onResetSelection(reduxKey) {
		const { listDiscounts } = this.props;

		listDiscounts && store.dispatch(actions.resetSelection({ reduxKey }));
	}

	_getIsBatchStepListValid() {
		const { batchList } = this.props;

		const allSetNamesChosen = batchList.every((entry) => entry.setName);

		return allSetNamesChosen;
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchList,
			editBatchListItem,
			removeBatchListItem,
		} = this.props;
		const { showOverlay } = this.state;

		const steps = [
			{
				component: (
					<ProductVariantDiscountSetBatchStepForm
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.handleToggleOverlay}
						discountUrl={this.discountUrl}
						resetSelection={this.onResetSelection}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE,
				isValid: this._getIsBatchStepFormValid(),
				onNext: () => this._onSubmitForm(),
				loading: false,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				noScroll: false,
				allowOverflow: true,
			},
			{
				component: (
					<ProductVariantDiscountSetBatchStepList
						batchList={batchList}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: this._getIsBatchStepListValid(),
				onNext: this._batchSubmit,
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						className="modal-batch"
						ref={this.modalRef}
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={showOverlay ? () => {} : handleClose}
					/>
				)}

				<Overlay
					zIndex={551}
					height={850}
					list
					visible={showOverlay}
					onClose={() => {
						this.handleToggleOverlay();
					}}
				>
					<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
						title={phrases.MODAL_BATCH_STEP_FORM_DISCOUNT_TABLE_TITLE}
						columns={this.columnsDiscounts}
						fetchData={this.fetchData}
						filterable
						disableFetchCsvButton
						enableMultiSelection
						batchSelection
						defaultPageSize={20}
						customAreaComponents={this.getAdditionalPosConfigurationTableFilters()}
						extraFilters={
							this.state.legacyFilterOn ? this.state.legacyFilter : ''
						}
						onInitialization={() => this.onOverlayOpen()}
						reduxKey={this.state.overlayType}
						manual
						style={{
							maxHeight: '720px',
						}}
					/>
				</Overlay>
			</>
		);
	}
}

ProductVariantDiscountSetModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	listDiscounts: PropTypes.object,
	addBatchList: PropTypes.func,
	fetchDiscounts: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addProductVariantDiscountSet: PropTypes.func,
	productVariant: PropTypes.number,
};

export default ProductVariantDiscountSetModalBatch;
