'use strict';

import {
	RESET_STATE,
	UPDATE_MARKET_PAYROLL_PERIOD,
	SET_MARKET_PAYROLL_PERIOD,
} from './organisationMarketPayrollPeriods.actions';

const defaultState = {
	data: {
		marketPayrollPeriod: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_MARKET_PAYROLL_PERIOD: {
		return {
			...state,
			data: {
				marketPayrollPeriod: payload,
			},
		};
	}

	case UPDATE_MARKET_PAYROLL_PERIOD: {
		return {
			...state,
			data: {
				marketPayrollPeriod: {
					...state.data.marketPayrollPeriod,
					...payload,
				},
			},
		};
	}

	case RESET_STATE: {
		return defaultState;
	}

	default:
		return state;
	}
}

export default reducer;
