import _omit from 'lodash/omit';

export function sortHomeScreenRecommendationsUnderTimeSlots(data) {
	return data.reduce((acc, currentValue, index) => {
		// get time slot id
		const timeSlotId = currentValue.tile_layout_time_slot?.id;

		const recommendation = _omit(currentValue, 'tile_layout_time_slot');

		const foundTimeSlot = acc.find((entry) => entry.id === timeSlotId);

		if (foundTimeSlot) {
			return [
				// remove found time slot from acc
				...acc.filter((entry) => entry.id !== timeSlotId),
				{
					...foundTimeSlot,
					homeScreenRecommendations: [
						...foundTimeSlot.homeScreenRecommendations,
						recommendation,
					],
				},
			];
		}

		return [
			...acc,
			{
				...currentValue.tile_layout_time_slot,
				homeScreenRecommendations: [recommendation],
			},
		];
	}, []);
}

export function createHomeScreenRecommendationsPayload({
	timeSlots,
	posTiers,
}) {
	// each of time slots has 4 home screen recommendations, one for each tier (no. depends on tiers we have)
	return timeSlots.reduce((acc, currentValue, index) => {
		const foo = posTiers.map((entry) => ({
			tile_layout_time_slot: currentValue.id,
			tier: entry.id,
		}));

		return [...acc, ...foo];
	}, []);
}

export function formatTimeSlots(timeSlots) {
	return timeSlots.map((entry) => ({
		id: entry.id,
		time: entry.time,
		tile_layout: entry.tile_layout,
		homeScreenRecommendations: [],
	}));
}
