'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Button,
	ButtonGroup,
	InputCollectionSelect,
	Icon,
	SingleDatePickerInput,
} from 'dumb';
import { ModalRow } from 'dumb/modal';
import { Tip } from 'dumb/tips';
import moment from 'moment';

// constants/phrases
import constants from 'services/constants';
import phrases from './../../../posConfigurations.phrases';

// lodash
import _isEmpty from 'lodash/isEmpty';

class PosConfigurationsBatchStepFormDuplicate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};
	}

	editStoreEntry(e, type) {
		const {
			updateBatchForm,
			resetSelection,
			marketsUrl,
			posConfigurationVersionUrl,
		} = this.props;

		let value;

		if (e === null) value = e;
		else value = e.target ? e.target.value : e;

		if (type === 'markets' && _isEmpty(value)) {
			resetSelection(marketsUrl);
		}
		if (type === 'posConfigurationVersions' && _isEmpty(value)) {
			resetSelection(posConfigurationVersionUrl);
		}

		updateBatchForm({
			[type]: value,
		});
	}

	_getSelectedDate(date, name) {
		if (date) return moment.utc(date).format(constants.shortDate);

		return name === 'from'
			? moment.utc().format(constants.shortDate)
			: '9999-12-31';
	}

	render() {
		const {
			batchFormData,
			toggleOverlay,
			marketsUrl,
			posConfigurationVersionUrl,
		} = this.props;

		return (
			<>
				<div className="pos-modal-batch__tip">
					<Tip content={phrases.MODAL_BATCH_STEP_FORM_DUPLICATE_TIP} />
				</div>
				<ModalRow>
					<ButtonGroup type="full-width">
						<InputCollectionSelect
							id="batchPosConfigurationVersionSelect"
							label={phrases.MODAL_BATCH_STEP_POS_CONFIGURATION_VERSION}
							placeholder={
								phrases.MODAL_BATCH_STEP_POS_CONFIGURATION_VERSION_PLACEHOLDER
							}
							value={batchFormData.posConfigurationVersions}
							handleChange={(key, value) =>
								this.editStoreEntry(value, 'posConfigurationVersions')
							}
							multi
							required
							cache
							apiPath="/pos/pos_configuration_versions"
							params={{
								limit: 30,
								filter: `:active.to=ge='${moment
									.utc()
									.format(constants.shortDate)}'`,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>

						<Button
							type="inverted"
							shadow
							size="large"
							onClick={() => toggleOverlay(posConfigurationVersionUrl)}
						>
							<Icon name="more" />
						</Button>
					</ButtonGroup>
				</ModalRow>

				<ModalRow>
					<ButtonGroup type="full-width">
						<InputCollectionSelect
							id="batchMarketSelect"
							label={phrases.MODAL_BATCH_STEP_MARKET}
							placeholder={phrases.MODAL_BATCH_STEP_MARKET_PLACEHOLDER}
							value={batchFormData.markets}
							handleChange={(key, value) =>
								this.editStoreEntry(value, 'markets')
							}
							multi
							cache
							apiPath="/organization/markets"
							params={{
								limit: 30,
								filter: `:closed=ge='${moment
									.utc()
									.format(constants.shortDate)}'`,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>

						<Button
							type="inverted"
							shadow
							size="large"
							onClick={() => toggleOverlay(marketsUrl)}
						>
							<Icon name="more" />
						</Button>
					</ButtonGroup>
				</ModalRow>

				<ModalRow>
					<SingleDatePickerInput
						id="activeFrom"
						label={phrases.MODAL_BATCH_ACTIVE_FROM}
						onChange={(event) => this.editStoreEntry(event, 'activeFrom')}
						selectedDate={this._getSelectedDate(
							batchFormData.activeFrom,
							'from'
						)}
						noClockButton
						clearable
					/>
				</ModalRow>

				<ModalRow>
					<SingleDatePickerInput
						id="activeTo"
						label={phrases.MODAL_BATCH_ACTIVE_TO}
						onChange={(event) => this.editStoreEntry(event, 'activeTo')}
						selectedDate={this._getSelectedDate(batchFormData.activeTo, 'to')}
						noClockButton
						clearable
					/>
				</ModalRow>
			</>
		);
	}
}

PosConfigurationsBatchStepFormDuplicate.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
	resetSelection: PropTypes.func,
	marketsUrl: PropTypes.string,
	posConfigurationVersionUrl: PropTypes.string,
};

export default PosConfigurationsBatchStepFormDuplicate;
