'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Cell from 'reusableComponents/list/cell.component';

import _map from 'lodash/map';

class AccordionRows extends Component {
	constructor(props) {
		super(props);
	}

	/**
	 * @function _renderCells
	 * @memberOf DIRECTIVES.jtjList.component.accordionRows
	 * @description Renders cells in list
	 */
	_renderCells(row) {
		return _map(
			this.props.accordion.methods.handleReturnVisibleHeaders(),
			function(cell, cellKey) {
				return <Cell key={cellKey} cell={cell} row={row} />;
			}
		);
	}

	/**
	 * @function _renderDefaultRow
	 * @memberOf DIRECTIVES.jtjList.component.accordionRows
	 * @description Renders the default row in list
	 */
	_renderDefaultRow(row, rowKey, rowClassNames) {
		return (
			<tr key={rowKey} className={rowClassNames}>
				{this._renderCells(row)}
			</tr>
		);
	}

	/**
	 * @function _renderRows
	 * @memberOf DIRECTIVES.jtjList.component.accordionRows
	 * @description Renders rows in list
	 */
	_renderRows() {
		return _map(
			this.props.accordion.collection,
			function(row, rowKey) {
				var rowClassNames = cx('list__row', 'accordion__row');
				return this._renderDefaultRow(row, rowKey, rowClassNames);
			}.bind(this)
		);
	}

	render() {
		return <tbody>{this._renderRows()}</tbody>;
	}
}

AccordionRows.propTypes = {
	accordion: PropTypes.object.isRequired
};

export default AccordionRows;
