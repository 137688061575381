// lodash
import _uniqId from 'lodash/uniqueId';

import {
	UPDATE_VALUE,
	SET_VALUE,
	RESET_VALUE,

	// batch actions
	BATCH_UPDATE_VALUE,
	BATCH_ADD_ROW,
	BATCH_DELETE_ROW,
} from './shiftClockinCorrectionOptions.actions';

const defaultState = {
	value: {},
	originalValue: {},
	batchValues: [
		{
			id: _uniqId(),
			comment: '',
		},
	],
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_VALUE:
			return {
				value: payload,
				originalValue: payload,
			};

		case UPDATE_VALUE:
			return {
				...state,
				value: {
					...state.value,
					...payload,
				},
			};

		case BATCH_ADD_ROW:
			return {
				...state,
				batchValues: [
					...state.batchValues,
					{
						id: _uniqId(),
						comment: '',
					},
				],
			};

		case BATCH_UPDATE_VALUE:
			return {
				...state,
				batchValues: state.batchValues.map((entry) => {
					if (entry.id === payload.id) return { ...entry, ...payload };

					return entry;
				}),
			};

		case BATCH_DELETE_ROW:
			return {
				...state,
				batchValues: state.batchValues.filter((entry) => entry.id !== payload),
			};

		case RESET_VALUE:
			return defaultState;

		default:
			return state;
	}
}

export default reducer;
