import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './separator.css';

const Separator = ({ size, className }) => {
	const classN = classnames('j-separator', {
		[`j-separator--${size}`]: !!size,
		[`${className}`]: className,
	});

	return <div className={classN} />;
};

Separator.defaultProps = {
	size: 'small',
};

Separator.propTypes = {
	size: PropTypes.string,
	className: PropTypes.string,
};

export default Separator;
