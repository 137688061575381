'use strict';

import {
	UPDATE_PRODUCT_RECOMMENDATION_PRODUCT_VARIANT,
	SET_PRODUCT_RECOMMENDATION_PRODUCT_VARIANT,
	RESET_STATE
} from './productRecommendationProductVariants.actions';

const defaultState = {
	data: {
		productRecommendationProductVariant: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_PRODUCT_RECOMMENDATION_PRODUCT_VARIANT: {
		return {
			...state,
			data: {
				productRecommendationProductVariant: payload
			}
		};
	}

	case UPDATE_PRODUCT_RECOMMENDATION_PRODUCT_VARIANT: {
		return {
			...state,
			data: {
				productRecommendationProductVariant: {
					...state.data.productRecommendationProductVariant,
					...payload
				}
			}
		};
	}

	case RESET_STATE: {
		return {
			...state,
			data: {
				productRecommendationProductVariant:
						defaultState.data.productRecommendationProductVariant
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
