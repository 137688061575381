import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

// Dungeons N' Dragons
import { DragSource, DropTarget } from 'react-dnd';

// util
import _get from 'lodash/get';

import './reorderProductTileLayoutTile.css';

const ReorderProductTileLayoutTile = (props) => {
	const {
		imageAsset,
		tile,
		isDragging,
		connectDragSource,
		connectDropTarget,
	} = props;

	return connectDragSource(
		connectDropTarget(
			<div
				className={cx('reorder-product-tile-layout-tile', {
					'reorder-product-tile-layout-tile--dragging': isDragging,
				})}>
				<div
					className="reorder-product-tile-layout-tile__image"
					style={{
						backgroundImage: `url('${_get(
							imageAsset,
							'resolution_one.url',
							null
						)}')`,
						backgroundPosition: 'center',
						backgroundSize: 'cover',
					}}
				/>
				<div className="reorder-product-tile-layout-tile__content">
					<span className="reorder-product-tile-layout-tile__content__name">
						{tile.name}
					</span>
					<span className="reorder-product-tile-layout-tile__content__description">
						{tile.description}
					</span>
				</div>
			</div>
		)
	);
};

ReorderProductTileLayoutTile.propTypes = {
	tile: PropTypes.object,
	imageAsset: PropTypes.object,
	isDragging: PropTypes.bool,
	connectDragSource: PropTypes.func,
	connectDropTarget: PropTypes.func,
	connectDragPreview: PropTypes.func,
};

export default DropTarget(
	'reorderProductTileLayoutTile',
	{
		hover(props, monitor) {
			const dragIndex = monitor.getItem().index;
			const hoverIndex = props.index;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}

			props.moveCard(dragIndex, hoverIndex);
			monitor.getItem().index = hoverIndex;
		},
	},
	(connect) => ({
		connectDropTarget: connect.dropTarget(),
	})
)(
	DragSource(
		'reorderProductTileLayoutTile',
		{
			beginDrag: (props) => ({
				id: props.id,
				index: props.index,
			}),
		},
		(connect, monitor) => ({
			connectDragSource: connect.dragSource(),
			connectDragPreview: connect.dragPreview(),
			isDragging: monitor.isDragging(),
		})
	)(ReorderProductTileLayoutTile)
);
