'use strict';

import Table from './table.table';
import Group from './table.group';
import Column from './table.column';
import ContentWrapper from './table.contentWrapper';
import Content from './table.content';
import ClassName from './table.className';
import Title from './table.title';
import ColumnWidth from './table.columnWidth';

class TableFactory {

	/**
	 * @function table
	 * @memberOf TableFactory
	 * @param  {array} content
	 * @param  {object} title
	 * @param  {interaction} string
	 * @param  {string} total
	 * @param  {bool} header
	 * @param  {string} className
	 * @param  {string} wrapperClassName
	 */
	table(content, title, interaction, total, header, className, wrapperClassName) {
		return new Table(content, title, interaction, total, header, wrapperClassName);
	}

	/**
	 * @function group
	 * @memberOf TableFactory
	 * @param  {string} name
	 * @param  {array} content
	 * @param  {array} modifierClasses
	 */
	group(name, content, modifierClasses) {
		return new Group(name, content, modifierClasses);
	}

	/**
	 * @function column
	 * @memberOf TableFactory
	 * @param  {string} name
	 * @param  {array or method} content
	 * @param  {method} footContent
	 * @param  {number, object} width
	 * @param  {string} type
	 * @param  {array} modifierClasses
	 */
	column(name, content, width, type, modifierClasses, colSpan) {
		return new Column(name, content, width, type, modifierClasses, colSpan);
	}

	/**
	 * @function contentWrapper
	 * @memberOf TableFactory
	 * @description Combined with "context" prop in rowEntries, is a way if getting different content into rows
	 * @param  {array} body
	 * @param  {array} foot
	 * @param  {array} title
	 */
	contentWrapper({body, foot, title}) {
		return new ContentWrapper({body, foot, title});
	}

	/**
	 * @function content
	 * @memberOf TableFactory
	 * @param  {*} template
	 * @param  {string} type
	 * @param  {*} fallbackTemplate
	 * @param  {string} sourceProp [data*, metadata]
	 */
	content(template, type, fallbackTemplate, sourceProp) {
		return new Content(template, type, fallbackTemplate, sourceProp);
	}

	/**
	 * @function className
	 * @memberOf TableFactory
	 * @param  {string} className
	 * @param  {method} expression
	 */
	className(className, expression) {
		return new ClassName(className, expression);
	}

	/**
	 * @function title
	 * @memberOf TableFactory
	 * @param  {*} template
	 * @param  {*} minWidth
	 */
	title(template, metaTemplate) {
		return new Title(template, metaTemplate);
	}

	/**
	 * @function columnWidth
	 * @memberOf TableFactory
	 * @param  {function} path
	 * @param  {number} minChars
	 * @param  {number} charWidth pixels
	 * @param  {number} bufferWidth pixels
	 */
	columnWidth(path, minChars, charWidth, bufferWidth) {
		return new ColumnWidth(path, minChars, charWidth, bufferWidth);
	}

}

export default TableFactory;