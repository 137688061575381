'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, Icon, InputCollectionSelect } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// phrases/constants
import phrases from './../../../posConfigurations.phrases';
import constants from 'services/constants';
import moment from 'moment';
import { Tip } from 'dumb/tips';
import { Box } from '@mui/material';

// lodash
import _get from 'lodash/get';

class PosConfigurationsBatchStepListDuplicate extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e?.target ? e.target.value : e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: value },
		});
	}

	render() {
		const { batchList } = this.props;

		const newMarket = _get(batchList, '[0].newMarket', false);

		return (
			<>
				<Box sx={{ marginBottom: 1 }}>
					<Tip content={phrases.MODAL_BATCH_TIP} />
				</Box>

				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_NAME}</th>
							<th>{phrases.MODAL_BATCH_DESCRIPTION}</th>
							<th>{phrases.MODAL_BATCH_STEP_MARKET}</th>
							<th>{phrases.MODAL_BATCH_STEP_POS_CONFIGURATION_VERSION}</th>
							<th>{phrases.MODAL_BATCH_ACTIVE_FROM}</th>
							<th>{phrases.MODAL_BATCH_ACTIVE_TO}</th>

							{newMarket && (
								<>
									<th>{phrases.MODAL_BATCH_TO_STAY_TAX}</th>
									<th>{phrases.MODAL_BATCH_TO_GO_TAX}</th>
								</>
							)}

							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((entry, i) => {
							return (
								<TableListRow key={entry.identifier}>
									<TableListCell>{i + 1}</TableListCell>
									<TableListCell>
										<Input
											id={`name-${i}`}
											placeholder={phrases.MODAL_BATCH_STEP_NAME_PLACEHOLDER}
											value={entry.name || ''}
											onChange={(e) => this._editListEntry(e, entry, 'name')}
											required
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`description-${i}`}
											value={entry.description || ''}
											placeholder={phrases.MODAL_BATCH_DESCRIPTION_PLACEHOLDER}
											onChange={(event) =>
												this._editListEntry(event, entry, 'description')
											}
											required
										/>
									</TableListCell>
									<TableListCell>
										{_get(entry, 'market.label', '')}
									</TableListCell>
									<TableListCell>
										{_get(entry, 'posConfigurationVersion.value.name', '')}
									</TableListCell>
									<TableListCell>
										{moment.utc(entry.activeFrom).format(constants.shortDate)}
									</TableListCell>
									<TableListCell>
										{moment.utc(entry.activeTo).format(constants.shortDate)}
									</TableListCell>

									{newMarket && (
										<>
											<TableListCell>
												<InputCollectionSelect
													id={`toStayTax-${i}`}
													placeholder={
														phrases.MODAL_BATCH_TO_STAY_TAX_PLACEHOLDER
													}
													value={entry.toStayTax}
													handleChange={(key, value) =>
														this._editListEntry(value, entry, 'toStayTax')
													}
													cache
													required
													apiPath="/administration/market_tax_rates"
													params={{
														limit: 30,
														filter: `:market.id=='${entry.market.value.id}'`,
													}}
													optionFormat={(entry) => ({
														value: entry,
														label: entry.name,
													})}
													inputFilterFormat={(input) =>
														`:name=like='%${input}%'`
													}
												/>
											</TableListCell>
											<TableListCell>
												<InputCollectionSelect
													id={`toGoTax-${i}`}
													placeholder={
														phrases.MODAL_BATCH_TO_GO_TAX_PLACEHOLDER
													}
													value={entry.toGoTax}
													handleChange={(key, value) =>
														this._editListEntry(value, entry, 'toGoTax')
													}
													cache
													required
													apiPath="/administration/market_tax_rates"
													params={{
														limit: 30,
														filter: `:market.id=='${entry.market.value.id}'`,
													}}
													optionFormat={(entry) => ({
														value: entry,
														label: entry.name,
													})}
													inputFilterFormat={(input) =>
														`:name=like='%${input}%'`
													}
												/>
											</TableListCell>
										</>
									)}

									<TableListCell>
										<Button
											type="negative"
											size="tiny"
											onClick={() => this._onRemoveEntry(entry.identifier)}
										>
											<Icon name="delete" />
										</Button>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
			</>
		);
	}
}

PosConfigurationsBatchStepListDuplicate.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

PosConfigurationsBatchStepListDuplicate.defaultProps = {};

export default PosConfigurationsBatchStepListDuplicate;
