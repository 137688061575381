'use strict';

import {
	RESET_STATE,
	ADD_BATCH_LIST,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	ADD_BATCH_LIST_ITEM_ROW,
	SET_GLOBAL_BUNDLE_RULES
} from './productBundleRules.actions';

// utils
import _uniqueId from 'lodash/uniqueId';

const defaultState = {
	data: {
		batchListData: [
			{
				identifier: _uniqueId('row-'),
			},
		],
		globalBundleRules: []
	},
};

function reducer(state = defaultState, action) {
	const { payload, type } = action;

	switch (type) {
	case ADD_BATCH_LIST: {
		return {
			...state,
			data: {
				...state.data,
				batchListData: payload,
			},
		};
	}

	case EDIT_BATCH_LIST_ITEM: {
		return {
			...state,
			data: {
				...state.data,
				batchListData: state.data.batchListData.map((entry) => {
					if (entry.identifier === payload.identifier) return payload;
					return entry;
				}),
			},
		};
	}

	case REMOVE_BATCH_LIST_ITEM: {
		return {
			...state,
			data: {
				...state.data,
				batchListData: state.data.batchListData.filter(
					(entry) => entry.identifier !== payload
				),
			},
		};
	}

	case ADD_BATCH_LIST_ITEM_ROW: {
		return {
			...state,
			data: {
				...state.data,
				batchListData: [
					...state.data.batchListData,
					{
						identifier: _uniqueId('row-'),
					},
				],
			},
		};
	}

	case SET_GLOBAL_BUNDLE_RULES: {
		return {
			...state,
			data: {
				...state.data,
				globalBundleRules: payload
			}
		};
	}

	case RESET_STATE: {
		return defaultState;
	}

	default:
		// never return default state in default case, Filip!!!
		return state;
	}
}

export default reducer;
