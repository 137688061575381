import { get } from 'api.vanilla.service';
import { store } from 'appState';
import { saveAs } from 'file-saver';
import * as actions from './../dailyReport.actions';
import dailyReportParser from './../utilities/dailyReportParser';

const __endpoints = require('endpoints.service');

export default function fetchDailyReport(
	{ date, perspective, reportingCurrencyId } = {},
	headers
) {
	const dailyReportEndpoint =
		__endpoints.collection.reporting.daily_2_reports.index.path;

	const params = {
		// perspective,
		reporting_currency: reportingCurrencyId,
		date: date.format('YYYY-MM-DD'),
		version: 'V3.0',
	};

	return get(dailyReportEndpoint, params, null, headers).then((response) => {
		// If headers are present, that means we are trying to fetch the data as a csv

		if (headers) {
			const blob = new Blob([response], { type: 'text/csv;charset=utf-8' });
			const fileName = `daily_report_${params.date}.csv`;
			saveAs(blob, fileName);

			// If not, its business as usual
		} else {
			const reportRaw = response.data;

			let franchiseReport = reportRaw.filter(
				(entry) => entry.store_type === 'franchise'
			);
			let ownStoresReport = reportRaw.filter(
				(entry) => entry.store_type !== 'franchise'
			);

			franchiseReport = dailyReportParser(franchiseReport);
			ownStoresReport = dailyReportParser(ownStoresReport);

			store.dispatch(
				actions.setDailyReport(ownStoresReport, franchiseReport, reportRaw)
			);
		}
	});
}
