export default {
	TITLE: 'Market shift deviation resons',
	MARKET_OVERTIME_RULE_ADDED: 'Market shift deviation reasons added!',
	MARKET_OVERTIME_RULE_EDITED: 'Market shift deviation reason edited!',
	MARKET_OVERTIME_RULES_EDITED: 'Market shift deviation reasons edited!',
	MARKET_OVERTIME_RULE_DELETED: 'Market shift deviation reason deleted!',

	// batch form
	MODAL_BATCH_STEP_LIST_TITLE: 'Add deviation reasons',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_MARKET: 'Market',
	MODAL_BATCH_STEP_TYPE: 'Type',
	MODAL_BATCH_STEP_REQUIRES_MANAGER_APPROVAL: 'Requires manager approval',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add deviation reasons',
};
