import { get, post, remove, patch } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './globalRawIngredients.phrases';

/**
 * @function fetchGlobalRawIngredients
 * @description Fetch all raw ingredient categories
 * @param {object} state
 */
export function fetchGlobalRawIngredients(state, filter) {
	filter = state.filter ? `${state.filter};${filter}` : filter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter
	};

	return get('/inventory/global_raw_ingredients', params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addGlobalRawIngredients
 * @description adds raw ingredient categories
 */
export function addGlobalRawIngredients(data) {
	return post('/inventory/global_raw_ingredients', data)
		.then(res => {
			setFeedback(phrases.GLOBAL_RAW_INGREDIENT_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editGlobalRawIngredient
 * @description edits raw ingredient categories
 */
export function editGlobalRawIngredient(data) {
	return post(`/inventory/global_raw_ingredients/${data.id}`, data)
		.then(res => {
			setFeedback(phrases.GLOBAL_RAW_INGREDIENT_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function editGlobalRawIngredients
 * @description edits raw ingredient categories
 */
export function editGlobalRawIngredients(data) {
	return patch('/inventory/global_raw_ingredients', data)
		.then(res => {
			setFeedback(phrases.GLOBAL_RAW_INGREDIENTS_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function deleteGlobalRawIngredients
 * @description deletes raw ingredient categories
 */
export function deleteGlobalRawIngredients(id) {
	return remove(`/inventory/global_raw_ingredients/${id}`)
		.then(res => {
			setFeedback(phrases.GLOBAL_RAW_INGREDIENTS_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
