export default {
	TITLE: 'System software versions',
	SYSTEM_SOFTWARE_VERSIONS_ADDED: 'System software versions added!',
	SYSTEM_SOFTWARE_VERSION_EDITED: 'System software version edited!',
	SYSTEM_SOFTWARE_VERSION_DELETED: 'System software versions deleted!',

	// batch form
	MODAL_BATCH_STEP_LIST_TITLE: 'Add system software versions',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_PLIST: 'Plist',
	MODAL_BATCH_STEP_PLIST_PLACEHOLDER: 'Enter plist url...',
	MODAL_BATCH_STEP_VERSION: 'Version',
	MODAL_BATCH_STEP_VERSION_PLACEHOLDER: 'Enter version...',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add entries',
};
