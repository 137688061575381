'use strict';

import { FileListFactory as f } from 'fileList/factory';
import { FileListEndpointDataProvider } from 'fileList/dataProviders';

// Dataprovider
const EmploymentDocumentsFileListDataProvider = new FileListEndpointDataProvider(
	'/hr/employment_documents'
);

// FileListFactory
const EmploymentDocumentsFileList = f.fileList(
	'employmentDocumentsFileList',
	EmploymentDocumentsFileListDataProvider
);
EmploymentDocumentsFileList.setDateTemplate(d => d.updated);
EmploymentDocumentsFileList.setFileNameTemplate(d => d.document.filename);

export default EmploymentDocumentsFileList;
