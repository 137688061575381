export const UPDATE_BATCH_FORM =
	'SALES_CONFIGURATION_GLOBAL_BUNDLE_RULE/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'SALES_CONFIGURATION_GLOBAL_BUNDLE_RULE/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_GLOBAL_BUNDLE_RULE/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_GLOBAL_BUNDLE_RULE/REMOVE_BATCH_LIST_ITEM';

export const SET_GLOBAL_BUNDLE_RULE =
	'SALES_CONFIGURATION_GLOBAL_BUNDLE_RULE/SET_GLOBAL_BUNDLE_RULE';
export const UPDATE_GLOBAL_BUNDLE_RULE =
	'SALES_CONFIGURATION_GLOBAL_BUNDLE_RULE/UPDATE_GLOBAL_BUNDLE_RULE';
export const RESET_STATE = 'SALES_CONFIGURATION_GLOBAL_BUNDLE_RULE/RESET_STATE';

export function setGlobalBundleRule(payload) {
	return {
		type: SET_GLOBAL_BUNDLE_RULE,
		payload,
	};
}

export function updateGlobalBundleRule(payload) {
	return {
		type: UPDATE_GLOBAL_BUNDLE_RULE,
		payload,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}
