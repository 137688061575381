'use strict';

// Services
import moment from 'moment';
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';
var __endpoints = require('endpoints.service');

export default class EmploymentWorkplaceResponsibilitiesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'role',
				type: 'select',
				canAdd: true,
				canEdit: true,
				required: true,
				reference: {
					key: 'name',
					showSearchList: false,
					list: '/authentication/roles',
				},
			},
			{
				name: 'period',
				type: 'date',
				canAdd: true,
				canEdit: true,
				required: true,
				defaultValue: moment(),
				reference: {
					key: 'from',
					translationKey: 'FROM',
				},
			},
			{
				name: 'period',
				type: 'date',
				canAdd: true,
				canEdit: true,
				reference: {
					key: 'to',
					translationKey: 'TO',
				},
			},
			{
				name: 'location',
				type: 'select',
				canAdd: true,
				canEdit: true,
				required: true,
				reference: {
					key: 'name',
					list: '/organization/workplaces',
				},
			},
		];

		this.settings = {
			name: 'EmploymentWorkplaceResponsibilitiesList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			canCollapse: true,
			alwaysRefresh: true,
			canManipulate: () => validAccess(undefined, ['Employment Admin', 'Person Admin']),

			translationPath: 'ADMINISTRATION.WORKPLACE_RESPONSIBILITIES',
			endpoint:
				__endpoints.collection.administration.workplace_responsibilities,
		};

		this.init();
	}
}
