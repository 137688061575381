'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	addBatchList,
	updateBatchForm,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	resetProductVariantGroupProductVariant,
} from './store/productVariantGroupProductVariants.actions';

// services
import {
	fetchProductVariantGroupProductVariants,
	addProductVariantGroupProductVariants,
	deleteProductVariantGroupProductVariant,
} from './productVariantGroupProductVariants.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Button, Icon } from 'dumb';

import ProductVariantGroupProductVariantsModalBatch from './components/batch/productVariantGroupProductVariantsModalBatch';

// lodash
import _get from 'lodash/get';

// phrases/constants
import phrases from './productVariantGroupProductVariants.phrases';

class ProductVariantGroupProductVariants extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
		};

		this.deleteEntry = this.deleteEntry.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.addProductVariantGroupWrapper = this.addProductVariantGroupWrapper.bind(
			this
		);
		this.fetchData = this.fetchData.bind(this);
		this.fetchData = this.fetchData.bind(this);

		this.columns = [
			{
				Header: 'Product Variant',
				id: 'productVariant',
				accessor: (d) => _get(d, 'product_variant.name', ''),
				filterPath: ':product_variant.name',
			},
		];
	}

	deleteEntry(id) {
		return deleteProductVariantGroupProductVariant(id);
	}

	fetchData(state) {
		const { productVariantGroupId } = this.props;

		const filter = `:product_variant_group.id=='${productVariantGroupId}'`;

		return fetchProductVariantGroupProductVariants(state, filter);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetProductVariantGroupProductVariant();
	}

	addProductVariantGroupWrapper(data) {
		return addProductVariantGroupProductVariants(data).then(() => {
			this.setState(() => ({
				key: moment.utc(),
			}));
		});
	}

	render() {
		const {
			batchList,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
			productVariantGroupId,
			addBatchList,
			updateBatchForm,
			batchForm,
		} = this.props;

		const legacyFilter = this.state.legacyFilterOn
			? this.state.legacyFilter
			: '';

		return (
			<>
				<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={`/product/product_variant_group_product_variants-${productVariantGroupId}`}
					manual
					deleteEntry={this.deleteEntry}
					onModalClose={this.props.resetProductVariantGroupProductVariant}
					actionRender={
						<Button
							type="inverted"
							label="Batch"
							shadow
							onClick={() => this.toggleModal()}>
							<Icon name="add" />
						</Button>
					}
					extraFilters={legacyFilter}
				/>

				<ProductVariantGroupProductVariantsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addProductVariantGroupProductVariants={
						this.addProductVariantGroupWrapper
					}
					productVariantGroupId={productVariantGroupId}
					addBatchList={addBatchList}
					batchForm={batchForm}
					updateBatchForm={updateBatchForm}
				/>
			</>
		);
	}
}

ProductVariantGroupProductVariants.propTypes = {
	resetProductVariantGroupProductVariant: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchList: PropTypes.func,
	productVariantGroupId: PropTypes.number,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			addBatchList,
			updateBatchForm,
			editBatchListItem,
			removeBatchListItem,
			resetProductVariantGroupProductVariant,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		batchList: store.productVariantGroupProductVariants.data.batchList,
		batchForm: store.productVariantGroupProductVariants.data.batchForm,
	};
};

export default connectWithStore(
	ProductVariantGroupProductVariants,
	mapStateToProps,
	mapDispatchToProps
);
