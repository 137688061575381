'use strict';

import React, { ChangeEvent } from 'react';

// interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';
import { ICurrencyDenominationReduxValue } from './types';
import { SelectOption } from 'types/select';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';

// components
import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import getColumns from 'reactDataWrapperColumns/administration/currencyDenominations.columns';

// components
import { Input } from 'dumb';

import {
	fetchCurrencyDenominations,
	editCurrencyDenominations,
} from './currencyDenominations.service';

import {
	setCurrencyDenomination,
	updateCurrencyDenomination,
	resetState,
} from './store/currencyDenominations.actions';

// constants/phrases/tools
import phrases from './currencyDenominations.phrases';
import getEditedValues from 'services/utils/getEditedValues';

const reduxKey = '/administration/currency_denomination';

type Props = {
	id: number;
};

const CurrencyDenominations = ({ id }: Props): JSX.Element => {
	// hooks
	const { currencyDenomination, defaultCurrencyDenomination } = useAppSelector(
		(state) => ({
			currencyDenomination:
				state.currencyDenominations.data.currencyDenomination,
			defaultCurrencyDenomination:
				state.currencyDenominations.data.defaultCurrencyDenomination,
		})
	);
	const dispatch = useAppDispatch();

	const editEntry = () => {
		if (!currencyDenomination.id || !defaultCurrencyDenomination)
			return Promise.resolve(true);

		const editedValues = getEditedValues({
			oldData: defaultCurrencyDenomination,
			newData: currencyDenomination,
		});

		const payload = {
			type: editedValues?.type,
			denomination: editedValues?.denomination,
		};

		return editCurrencyDenominations(currencyDenomination.id, payload);
	};

	const setInitialEditValues = (data: IAdministrationCurrencyDenomination) => {
		const payload: ICurrencyDenominationReduxValue = {
			id: data.id,
			type: data.type,
			denomination: data.denomination,
		};

		dispatch(setCurrencyDenomination(payload));
	};

	const editStoreEntry = (
		name: string,
		value: string | SelectOption | null | boolean
	): void => {
		if (name === 'closed' && value === null) {
			value = '9999-12-31';
		}

		const payload = {
			[name]: value,
		};

		dispatch(updateCurrencyDenomination(payload));
	};

	const getEditableCells = () => {
		return [
			{
				header: 'Denomination',
				value: (
					<Input
						id="denomination"
						placeholder="Enter denomination..."
						value={currencyDenomination.denomination}
						onChange={(event: ChangeEvent<HTMLInputElement>) =>
							editStoreEntry('denomination', event.target.value)
						}
						dataCy="currency-denomination-denomination"
						required
					/>
				),
			},
			{
				header: 'Type',
				value: (
					<Input
						id="type"
						placeholder="Enter type..."
						value={currencyDenomination.type}
						onChange={(event: ChangeEvent<HTMLInputElement>) =>
							editStoreEntry('type', event.target.value)
						}
						dataCy="currency-denomination-type"
						required
					/>
				),
			},
		];
	};

	return (
		<ReactDataWrapper
			title={phrases.TABLE_TITLE}
			dataCy="currency-denominations-table"
			columns={getColumns()}
			fetchData={(
				state: IFetchDataProps
			): ReturnType<typeof fetchCurrencyDenominations> =>
				fetchCurrencyDenominations(id, state)
			}
			filterable
			defaultPageSize={10}
			reduxKey={reduxKey}
			manual
			accessAreasAllowedToEdit={[
				'Global BI Configuration',
				'Administration/CurrencyDenominations::Edit',
				'Organization Admin',
			]}
			editableCells={getEditableCells()}
			actionsWidth={80}
			editEntry={editEntry}
			setInitialEditValues={setInitialEditValues}
			onModalClose={() => dispatch(resetState())}
			onModalCloseConfirm
			compact
		/>
	);
};

export default CurrencyDenominations;
