'use strict';
import { HotbarComponentFactory as h } from 'hotdamnbar/factory';
import { HotbarEndpointDataProvider } from 'hotdamnbar/dataProviders';

const ShiftClockinsHotbar = h.hotbar('shiftClockins');

const MarketsDataProvider = new HotbarEndpointDataProvider(
	'/organization/markets',
	null,
	// option format
	(entry) => ({
		value: entry.id,
		label: entry.name,
		market: entry,
	})
);
MarketsDataProvider.setSortOrder(':sort_order+');

const StoresDataProvider = new HotbarEndpointDataProvider(
	'/organization/workplaces',
	{ market: 'market.id' },
	// option format
	(entry) => ({
		value: entry.id,
		label: entry.name,
		market: entry.market,
	})
);
StoresDataProvider.setSortOrder(':sort_order+');
StoresDataProvider.setTypingFilterParameter('workplace.name');

const EmployeesDataProvider = new HotbarEndpointDataProvider(
	'/hr/employments',
	null,
	(entry) => ({
		value: entry.id,
		label: entry.person.identity.full_name,
	})
);
EmployeesDataProvider.setTypingFilterParameter('person.identity.full_name');

ShiftClockinsHotbar.add(h.select('Markets', false, MarketsDataProvider, false));
ShiftClockinsHotbar.add(h.select('Stores', false, StoresDataProvider, false), {
	market: 'Markets',
});
ShiftClockinsHotbar.add(h.dateRange('Period', true, true));
ShiftClockinsHotbar.add(
	h.select('Employees', false, EmployeesDataProvider, true)
);
ShiftClockinsHotbar.add(h.separator());

export default ShiftClockinsHotbar;
