'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';
import getLanguagesColumns from 'reactDataWrapperColumns/administration/languages.columns';

// phrases
import phrases from './../../../productTranslations.phrases';

class ProductTranslationsBatchStepForm extends Component {
	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		updateBatchForm({
			[type]: e,
		});
	}

	render() {
		const { batchForm } = this.props;

		return (
			<ModalRow>
				<InputCollectionSelect
					id="languages-modal-step-form"
					label={phrases.MODAL_HEADER_LANGUAGE}
					placeholder={phrases.MODAL_HEADER_LANGUAGE_PLACEHOLDER}
					value={batchForm.languages}
					apiPath="/administration/languages"
					params={{
						limit: 30,
					}}
					handleChange={(key, event) => this.editStoreEntry(event, 'languages')}
					inputFilterFormat={(input) => `:ingredient.name=like='%${input}%'`}
					optionFormat={(entry) => ({
						value: entry,
						label: `${entry.name} (${entry.code})`,
					})}
					multi
					tableColumns={getLanguagesColumns()}
					tableTitle={phrases.LANGUAGES_TABLE_TITLE}
					tableReduxKey="product-translations-/administration/languages"
				/>
			</ModalRow>
		);
	}
}

ProductTranslationsBatchStepForm.propTypes = {
	batchForm: PropTypes.object,
	updateBatchForm: PropTypes.func,
};

export default ProductTranslationsBatchStepForm;
