import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import "./card.css";

export default class Card extends PureComponent {
	render() {
		const {
			children,
			className,
			nospace,
			type,
		} = this.props;

		const classN = classnames(
			'j-card', { 
				[`${className}`]: className,
				[`j-card--nospace`]: nospace,
				[`j-card--${type}`]: type
			}
		);

		return ( 
			<div className={classN}>
				{ children }
			</div>
		);
	}
}

Card.propTypes = {
	// shared props
	children: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array,
		PropTypes.element
	]).isRequired,
	className: PropTypes.string,
	nospace: PropTypes.bool,
	type: PropTypes.string,
};