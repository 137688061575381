'use strict';

/**
 * @namespace __regex
 * @memberOf SERVICES
 * @description
 * Wrapper for regular expressions
 */

var regex = {
	email: {
		expression: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
		error: 'Please use proper input format for e-mail (xx@xx.xx)'
	},
	number: {
		expression: /^-?[0-9]\d*(\,(\d+|))?$/,
		error: 'Please type a number. Use comma for decimals.'
	},



	/**
	 * @description Check for numbers formatted like 1.234,56
	 * https://stackoverflow.com/questions/16148034/regex-for-number-with-decimals-and-thousand-separator?answertab=oldest#tab-top
	 */
	excelFormatted: {
		expression: /^(?!0+\.00)(?=.{1,9}(\.|$))(?!0(?!\.))\d{1,3}(,\d{3})*(\.\d+)?$/,
		error: 'Number should be formatted 123.456,78'
	}
};

module.exports = regex;