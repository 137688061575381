import actionTypes from 'shiftPlanner/actions';

const defaultState = {
	data: [],
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.SET_WARNINGS: {
			return {
				...state,
				data: payload,
			};
		}

		case actionTypes.UPDATE_WARNINGS: {
			return {
				...state,
				data: [...state.data, ...payload],
			};
		}

		case actionTypes.REMOVE_WARNINGS_FOR_PERIOD: {
			return {
				...state,
				data: state.data.filter((entry) => entry.date !== payload.date),
			};
		}

		default:
			return state;
	}
}

export default reducer;
