import React, { ChangeEvent } from 'react';

// interfaces
import { SelectOption } from 'types/select';
import { IMarketTagsReduxValue } from './../types';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import { updatemarketTag } from './../store/marketTags.actions';

// components
import { InputCollectionSelect, Input } from 'dumb';
import getMarketColumns from 'reactDataWrapperColumns/organization/markets.columns';

// misc
import phrases from './../marketTags.phrases';

type Props = {
	source: 'market' | 'tag';
};

const MarketTagsEditableCells = ({
	source,
}: Props): { header: string; value: JSX.Element }[] => {
	const dispatch = useAppDispatch();
	// hooks
	const { marketTag } = useAppSelector((state) => ({
		marketTag: state.marketTags.data.marketTag,
	}));

	const editStoreEntry = (
		name: keyof IMarketTagsReduxValue,
		value: SelectOption | null | string
	): void => {
		let valueToSet = value;

		if (
			(name === 'defaultLengthInMinutes' ||
				name === 'minutesFromStartOfShift' ||
				name === 'minutesFromEndOfShift') &&
			value === ''
		) {
			valueToSet = null;
		}

		const payload = {
			[name]: valueToSet,
		};

		dispatch(updatemarketTag(payload));
	};

	return [
		...(source === 'market' && marketTag.singleEditMode
			? [
					{
						header: phrases.TAG,
						value: (
							<InputCollectionSelect
								id="tags"
								dataCy="market-tag-tag-select"
								label={phrases.TAG}
								placeholder={phrases.TAG_PLACEHOLDER}
								value={marketTag.tag}
								handleChange={(key, value) => editStoreEntry('tag', value)}
								required
								cache
								apiPath="/tagging/tags"
								params={{
									limit: 50,
								}}
								optionFormat={(entry) => ({
									value: entry,
									label: entry.name,
								})}
								inputFilterFormat={(input) => `:name=like='%${input}%'`}
							/>
						),
					},
			  ]
			: []),
		...(source === 'tag' && marketTag.singleEditMode
			? [
					{
						header: phrases.MARKET,
						value: (
							<InputCollectionSelect
								id="market"
								dataCy="market-tag-market-select"
								label={phrases.MARKET}
								placeholder={phrases.MARKET_PLACEHOLDER}
								value={marketTag.market}
								handleChange={(key, value) => editStoreEntry('market', value)}
								required
								cache
								apiPath="/organization/markets"
								params={{
									limit: 30,
								}}
								optionFormat={(entry) => ({
									value: entry,
									label: entry.name,
								})}
								inputFilterFormat={(input) => `:name=like='%${input}%'`}
								tableTitle={phrases.MARKETS}
								tableReduxKey="markets-tags-modal-batch/organization/markets"
								tableColumns={getMarketColumns(
									'markets-tags-modal-batch/organization/markets'
								)}
							/>
						),
					},
			  ]
			: []),
		{
			header: phrases.DEFAULT_LENGTH_IN_MINUTES,
			value: (
				<Input
					id="default-length-in-minutes"
					dataCy="market-tag-default-length-in-minutes"
					placeholder={phrases.DEFAULT_LENGTH_IN_MINUTES_PLACEHOLDER}
					type="number"
					required
					value={marketTag.defaultLengthInMinutes}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						editStoreEntry('defaultLengthInMinutes', e.target.value)
					}
				/>
			),
		},
		{
			header: phrases.MINUTES_FROM_START_OF_SHIFT,
			value: (
				<Input
					id="minutes-from-start-of-shift"
					dataCy="market-tag-minutes-from-start-of-shift"
					placeholder={phrases.MINUTES_FROM_START_OF_SHIFT_PLACEHOLDER}
					type="number"
					value={marketTag.minutesFromStartOfShift}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						editStoreEntry('minutesFromStartOfShift', e.target.value)
					}
				/>
			),
		},
		{
			header: phrases.MINUTES_FROM_END_OF_SHIFT,
			value: (
				<Input
					id="minutes-from-end-of-shift"
					dataCy="market-tag-minutes-from-end-of-shift"
					placeholder={phrases.MINUTES_FROM_END_OF_SHIFT_PLACEHOLDER}
					type="number"
					value={marketTag.minutesFromEndOfShift}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						editStoreEntry('minutesFromEndOfShift', e.target.value)
					}
				/>
			),
		},
	];
};

export default MarketTagsEditableCells;
