export const RESET_ALLERGEN_CATEGORY =
	'SALES_CONFIGURATION/RESET_ALLERGEN_CATEGORY';
export const SET_ALLERGEN_CATEGORY =
	'SALES_CONFIGURATION/SET_ALLERGEN_CATEGORY';
export const UPDATE_ALLERGEN_CATEGORY =
	'SALES_CONFIGURATION/UPDATE_ALLERGEN_CATEGORY';

/**
 * @param {object} payload
 */
export function setAllergenCategory(payload) {
	return {
		type: SET_ALLERGEN_CATEGORY,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateAllergenCategory(payload) {
	return {
		type: UPDATE_ALLERGEN_CATEGORY,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET_ALLERGEN_CATEGORY
	};
}