export const RESET_DISCOUNT =
	'SALES_CONFIGURATION/RESET_DISCOUNT';
export const SET_DISCOUNT =
	'SALES_CONFIGURATION/SET_DISCOUNT';
export const UPDATE_DISCOUNT =
	'SALES_CONFIGURATION/UPDATE_DISCOUNT';

/**
 * @param {object} payload
 */
export function setDiscount(payload) {
	return {
		type: SET_DISCOUNT,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateDiscount(payload) {
	return {
		type: UPDATE_DISCOUNT,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetDiscount() {
	return {
		type: RESET_DISCOUNT
	};
}