'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Button,
	Icon,
	ButtonGroup,
	InputCollectionSelect,
	SingleDatePickerInput,
} from 'dumb';
import { ModalRow } from 'dumb/modal';

// constants/phrases
import constants from 'services/constants';
import phrases from './../../../posConfigurationVersions.phrases';

// lodash
import _isEmpty from 'lodash/isEmpty';

class PosConfigurationVersionsBatchStepFormAdd extends Component {
	editStoreEntry(e, type) {
		const { updateBatchForm, resetSelection, posConfigurationUrl } = this.props;

		let value;
		if (e === null) value = e;
		else value = e.target ? e.target.value : e;

		// if we cleared the select, also clear the selected rows in overlay table
		if (type === 'posConfiguration' && _isEmpty(value)) {
			resetSelection(posConfigurationUrl);
		}

		updateBatchForm({
			[type]: value,
		});
	}

	_getSelectedDate(date, name) {
		if (date) return moment.utc(date).format(constants.shortDate);

		return name === 'from'
			? moment.utc().format(constants.shortDate)
			: '9999-12-31';
	}

	getStateOptions() {
		return [
			{
				value: 'Draft',
				label: 'Draft',
			},
			{
				value: 'Published',
				label: 'Published',
			},
		];
	}

	render() {
		const { batchFormData, toggleOverlay, posConfigurationUrl } = this.props;

		return (
			<>
				<ModalRow>
					<ButtonGroup type="full-width">
						<InputCollectionSelect
							id="posConfiguration"
							label={phrases.MODAL_BATCH_STEP_POS_CONFIGURATION}
							placeholder={
								phrases.MODAL_BATCH_STEP_POS_CONFIGURATION_PLACEHOLDER
							}
							value={batchFormData.posConfiguration}
							handleChange={(key, value) =>
								this.editStoreEntry(value, 'posConfiguration')
							}
							required
							multi
							cache
							apiPath="/pos/pos_configurations"
							params={{
								limit: 30,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>

						<Button
							type="inverted"
							shadow
							size="large"
							onClick={() => toggleOverlay(posConfigurationUrl)}>
							<Icon name="more" />
						</Button>
					</ButtonGroup>
				</ModalRow>

				<ModalRow>
					<InputCollectionSelect
						id="state"
						label={phrases.MODAL_BATCH_STEP_STATE}
						placeholder={phrases.MODAL_BATCH_STEP_STATE_PLACEHOLDER}
						value={batchFormData.state}
						handleChange={(key, value) => this.editStoreEntry(value, 'state')}
						options={this.getStateOptions()}
						clearable={false}
					/>
				</ModalRow>

				<ModalRow>
					<SingleDatePickerInput
						id="activeFrom"
						label={phrases.MODAL_BATCH_ACTIVE_FROM}
						onChange={(event) => this.editStoreEntry(event, 'activeFrom')}
						selectedDate={this._getSelectedDate(
							batchFormData.activeFrom,
							'from'
						)}
						noClockButton
						clearable
					/>
				</ModalRow>

				<ModalRow>
					<SingleDatePickerInput
						id="activeTo"
						label={phrases.MODAL_BATCH_ACTIVE_TO}
						onChange={(event) => this.editStoreEntry(event, 'activeTo')}
						selectedDate={this._getSelectedDate(batchFormData.activeTo, 'to')}
						noClockButton
						clearable
					/>
				</ModalRow>
			</>
		);
	}
}

PosConfigurationVersionsBatchStepFormAdd.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
	resetSelection: PropTypes.func,
	posConfigurationUrl: PropTypes.string,
};

export default PosConfigurationVersionsBatchStepFormAdd;
