// Store
import { store } from 'appState';
import * as posConfigurationLayout from '../posConfigurationLayout.actions';

// Tools
import _get from 'lodash/get';
import _mapKeys from 'lodash/mapKeys';
import _isObject from 'lodash/isObject';
import _isEmpty from 'lodash/isEmpty';

// Services
import { set as setFeedback } from 'feedback.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { get, post, remove } from 'api.vanilla.service';

import { getLayoutTiles } from './tileLayoutTiles.service';

/**
 * @function getTileLayout
 * @param {int} layoutId
 * @description
 */
export function getTileLayoutGroups(layoutId) {
	// Loader
	store.dispatch(posConfigurationLayout.fetching(true));

	const filter = `:tile_layout.id=='${layoutId}'`;

	// Set params and filter
	const params = {
		filter
	};

	const uri = `/pos/tile_layout_groups`;
	return get(uri, params).then(response => {
		const layoutGroupsData = response.data;

		if (_isEmpty(layoutGroupsData)) return;

		const groupInFocus =
			layoutGroupsData.find(group => group.show_in_menu) || null;

		// Group by ID
		const groups = _mapKeys(layoutGroupsData, (value, key) => {
			return value.id;
		});

		getLayoutTiles(groupInFocus.id);

		store.dispatch(posConfigurationLayout.setGroupItemInFocus(groupInFocus.id));
		store.dispatch(posConfigurationLayout.setGroups(groups));
	});
}

export function cleanUp() {
	store.dispatch(posConfigurationLayout.cleanUp());
}

/**
 * @function getPosConfigurationsLayoutGroup
 * @param groupId
 * @returns {Promise.<TResult>|*|{anyOf}}
 */
export function getLayoutGroup(groupId, type = 'group') {
	// Get current groupItemInFocus in order to fallback to it if fetch fails
	store.dispatch(posConfigurationLayout.setLoading(true));

	const state = store.getState();

	if (type === 'Group') {
		let groups = state.salesConfigurationPOSLayout.groups;
		const tilesInGroup = state.salesConfigurationPOSLayout.tilesInGroup;

		groups[groupId] = {
			...groups[groupId],
			show_in_menu: true,
			type: 'tile',
			temporary: true,
			referenced_by_tile: tilesInGroup.find(
				tile =>
					tile.tile_layout_sub_group &&
					tile.tile_layout_sub_group.id === groupId
			)
		};

		store.dispatch(posConfigurationLayout.setGroups(groups));
		store.dispatch(posConfigurationLayout.setGroupItemInFocus(groupId));
	}

	// Show spinner
	store.dispatch(posConfigurationLayout.setGroupItemInFocus(groupId));
	store.dispatch(posConfigurationLayout.fetching(true));

	return getLayoutTiles(groupId);
}

/**
 * @function addLayoutGroup
 * @param data
 * @param layoutId
 * @returns {Promise.<TResult>|*|{anyOf}}
 */
export function addLayoutGroup(data, layoutId) {
	// Loader
	store.dispatch(posConfigurationLayout.fetching(true));

	data = {
		...data,
		tile_position_focus: '1',
		tile_layout: layoutId
	};

	const url = `/pos/tile_layout_groups`;
	return post(url, data)
		.then(response => {
			const res = response.data[0];

			// TODO: reducer, potentially refactor how objects are sent. Store as array instead!
			const groups = {
				[res.id]: {
					...res,
					tile_layout: _get(res, 'tile_layout.id', null)
				}
			};

			store.dispatch(posConfigurationLayout.fetching(false));
			store.dispatch(posConfigurationLayout.setGroups(groups));
			return response;
		})
		.catch(e => {
			store.dispatch(posConfigurationLayout.fetching(false));
			setFeedback('Position is already occupied', 0);
			return e;
		});
}

/**
 * @function updateLayoutGroup
 * @param data
 * @param groupId
 * @returns {Promise.<TResult>|*|{anyOf}}
 */
export function updateLayoutGroup(data = {}, groupId = null) {
	// Loader
	store.dispatch(posConfigurationLayout.fetching(true));

	data = {
		...data,
		id: groupId,
		// asset_collection: _get(data, 'asset_collection.id', null),
		tile_layout: _isObject(data.tile_layout)
			? _get(data, 'tile_layout.id', null)
			: data.tile_layout
	};

	// We cant update a group on an already occupied position
	delete data.position;

	// todo if position is the same the update fails. But position is set after order (not by the user). So if the updated group has same position as before, let it pass
	const uri = `/pos/tile_layout_groups/${groupId}`;
	return post(uri, data)
		.then(data => {
			const res = data.data[0];

			// TODO: reducer, potentially refactor how objects are sent. Store as array instead!
			const groups = {
				[res.id]: {
					...res,
					tile_layout: _get(res, 'tile_layout.id', null)
				}
			};

			store.dispatch(posConfigurationLayout.fetching(false));
			store.dispatch(posConfigurationLayout.setGroups(groups));
			return res;
		})
		.catch(err => {
			store.dispatch(posConfigurationLayout.fetching(false));
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return err;
		});
}

/**
 * deleteLayoutGroup
 * @param groupId
 * @returns {Promise.<TResult>|*|{anyOf}}
 */
export function deleteLayoutGroup(groupId = null) {
	// Loader
	store.dispatch(posConfigurationLayout.fetching(true));

	const uri = `/pos/tile_layout_groups/${groupId}`;
	return remove(uri)
		.then(() => {
			store.dispatch(posConfigurationLayout.removeGroup(groupId));
			store.dispatch(posConfigurationLayout.fetching(false));
		})
		.catch(err => {
			store.dispatch(posConfigurationLayout.fetching(false));
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * rotateLayoutGroup
 * @param groupId
 * @returns {Promise.<TResult>|*|{anyOf}}
 */
export function rotateLayoutGroup(updatedGroups, isArray) {
	// Loader
	store.dispatch(posConfigurationLayout.fetching(true));

	let rotations = updatedGroups;

	if (!isArray) {
		rotations = Object.keys(updatedGroups).reduce(
			(acc, key) => {
				acc['rotations'].push({
					id: updatedGroups[key].id,
					position: updatedGroups[key].position
				});
				return acc;
			},
			{ rotations: [] }
		);
	}

	const uri = `/pos/tile_layout_groups/position_rotations`;
	return post(uri, rotations)
		.then(() => {
			store.dispatch(posConfigurationLayout.fetching(false));
		})
		.catch(err => {
			store.dispatch(posConfigurationLayout.fetching(false));
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}
