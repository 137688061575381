'use strict';

export const SET_PERSONS = 'MYSHIFTPLANNER/SET_PERSONS';

export function setPersons(payload) {
	return {
		type: SET_PERSONS,
		payload
	};
}
