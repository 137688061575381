'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// redux
import { connectWithStore } from 'appState';
import Loadable from 'react-loadable';

// components
import { Loader } from 'dumb';
import { Box, Typography } from '@mui/material';

// misc
import phrases from './posConfigurationLayout.phrases';

const PosConfigurationLayout = Loadable({
	loader: () => import('./posConfigurationLayout.container'),
	loading: Loader,
});

class ForecastingPage extends Component {
	render() {
		const { mounted, layoutId, versionId } = this.props;

		// check if a component with the same version id and layour id has already been mounted
		const componentMounted =
			mounted !== null && mounted !== `${layoutId}-${versionId}`;

		return componentMounted ? (
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
				<Typography>{phrases.COMPONENT_MOUNTED_WARNING_MESSAGE}</Typography>
			</Box>
		) : (
			<PosConfigurationLayout layoutId={layoutId} versionId={versionId} />
		);
	}
}

ForecastingPage.propTypes = {
	layoutId: PropTypes.number,
	versionId: PropTypes.number,
	mounted: PropTypes.string,
};

ForecastingPage.defaultProps = {
	canEdit: false,
	enableTabIndex: false,
};

const mapStateToProps = () => (store) => {
	return {
		mounted: store.posConfigurationLayout.mounted,
	};
};

export default connectWithStore(ForecastingPage, mapStateToProps);
