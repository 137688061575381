import {
	SET_ACTIVE_DATES,
	SET_PRIORITIZE,
	SET_OVERWRITE,
	OPENING_HOURS_EDITED,
	SET_DAY_CLOSED,
	EDITABLE_WEEK_UPDATED,
	RESET_STATE,
} from './openingHoursEditor.actions';
import moment from 'moment';

const defaultState = {
	activeDates: {
		from: moment().startOf('isoweek'),
		to: moment('9999-12-31'),
	},
	prioritize: false,
	allowOverwrite: false,
	editableWeek: [],
	fetching: false,
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_ACTIVE_DATES:
			return {
				...state,
				activeDates: { ...state.activeDates, [payload.name]: payload.date },
			};

		case SET_PRIORITIZE:
			return Object.assign({}, state, {
				prioritize: payload.prioritize,
			});

		case SET_OVERWRITE:
			return Object.assign({}, state, {
				allowOverwrite: payload.allowOverwrite,
			});

		case SET_DAY_CLOSED:
			return Object.assign({}, state, {
				...state,
				editableWeek: state.editableWeek.map((day, index) => {
					if (index === Number(payload.index)) {
						day.closed = payload.isClosed;

						day.time = !payload.isClosed
							? {
								from: '10:00:00',
								to: '18:00:00',
								  }
							: null;
					}
					return day;
				}),
			});

		case EDITABLE_WEEK_UPDATED:
			return Object.assign({}, state, {
				...state,
				editableWeek: payload.week,
			});

		case OPENING_HOURS_EDITED:
			return Object.assign({}, state, {
				...state,
				editableWeek: state.editableWeek.map((day, index) => {
					if (index === Number(payload.index)) {
						day.time[payload.handle] = payload.value;
					}
					return day;
				}),
			});

		case RESET_STATE:
			return {
				...defaultState,
			};

		default:
			return state;
	}
}

export default reducer;
