import {
	ADD_ILLNESS,
	UPDATE_ILLNESS,
	SET_ILLNESS,
	RESET_ILLNESS,
	REMOVE_ILLNESS
} from './personIllness.actions';

const defaultState = {
	ui: {
		to: null,
		from: null,
		factor: {
			value: 1,
			label: 'Full day'
		}
	},
	data: []
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case ADD_ILLNESS: {
		return {
			...state,
			data: [...state.data, ...payload]
		};
	}

	case SET_ILLNESS: {
		return {
			...state,
			data: [...state.data, ...payload]
		};
	}

	case UPDATE_ILLNESS: {
		return {
			...state,
			ui: {
				...state.ui,
				...payload
			}
		};
	}

	case REMOVE_ILLNESS: {
		const data = state.data.filter(x => x.id !== payload);

		if (data) {
			return {
				...state,
				data
			};
		} else {
			return {
				...state,
				data: []
			};
		}
	}

	case RESET_ILLNESS: {
		return {
			...state,
			ui: defaultState
		};
	}

	default:
		return state;
	}
}

export default reducer;
