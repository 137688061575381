/* eslint-disable react/display-name */
import React, { ChangeEvent } from 'react';

// interfaces
import { ICompanyReduxValue } from './../../types';
import { SelectOption } from 'types/select';

// components
import {
	Input,
	InputCollectionSelect,
	Toggle,
	SingleDatePickerInput,
} from 'dumb';
import getMarketColumns from 'reactDataWrapperColumns/organization/markets.columns';

// phrases
import phrases from './companyEditableCells.phrases';
import moment, { Moment } from 'moment';
import constants from 'services/constants';

type Props = {
	company: ICompanyReduxValue;
	editStoreEntry: (
		name: string,
		value: string | SelectOption | boolean | null
	) => void;
};

const CompanyEditableCells = ({
	company,
	editStoreEntry,
}: Props): { header: string; value: JSX.Element }[] => {
	return [
		{
			header: 'Name',
			value: (
				<Input
					id="name"
					placeholder="Enter name..."
					value={company.name}
					onChange={(event: ChangeEvent<HTMLInputElement>) =>
						editStoreEntry('name', event.target.value)
					}
					required
				/>
			),
		},
		{
			header: 'Merchant account',
			value: (
				<Input
					id="merchant-account"
					placeholder="Enter merchant account..."
					value={company.merchantAccount}
					onChange={(event: ChangeEvent<HTMLInputElement>) =>
						editStoreEntry('merchantAccount', event.target.value)
					}
				/>
			),
		},
		{
			header: 'Market',
			value: (
				<InputCollectionSelect
					id="market"
					placeholder="Select market..."
					value={company.market}
					handleChange={(_: unknown, value: SelectOption) =>
						editStoreEntry('market', value)
					}
					cache
					apiPath="/administration/markets"
					params={{
						limit: 30,
					}}
					required={!company.editMode}
					disabled={company.editMode}
					clearable={false}
					optionFormat={(entry: any) => ({
						value: entry,
						label: entry.name,
					})}
					inputFilterFormat={(input: string) => `:name=like='%${input}%'`}
					tableColumns={getMarketColumns()}
					tableReduxKey="/administration/markets-company-edit-modal"
					tableTitle={phrases.MARKET_TITLE}
				/>
			),
		},
		{
			header: 'Opened',
			value: (
				<SingleDatePickerInput
					id="opened"
					onChange={(event: Moment) =>
						editStoreEntry('opened', event.format(constants.shortDate))
					}
					selectedDate={company.opened || moment().format(constants.shortDate)}
					noClockButton
					required
				/>
			),
		},
		{
			header: 'Closed',
			value: (
				<SingleDatePickerInput
					id="closed"
					onChange={(event: Moment) =>
						editStoreEntry('closed', event.format(constants.shortDate))
					}
					selectedDate={company.closed || '9999-12-31'}
					noClockButton
					clearable={company.closed !== '9999-12-31'}
				/>
			),
		},
		{
			header: 'Main entity',
			value: (
				<Toggle
					id="main-entity"
					toggled={company.mainEntity}
					onClick={(event: boolean) => editStoreEntry('mainEntity', event)}
				/>
			),
		},

		{
			header: 'Legal entity code',
			value: (
				<Input
					id="legal-entity-code"
					placeholder="Enter legal entity code..."
					value={company.legalEntityCode}
					onChange={(event: ChangeEvent<HTMLInputElement>) => {
						if (event.target.value.length < 5) {
							return editStoreEntry('legalEntityCode', event.target.value);
						}
					}}
				/>
			),
		},
	];
};

export default CompanyEditableCells;
