/* eslint-disable react/prop-types */

// components
import React from 'react';
import { DateFilterSelector } from 'reactDataWrapper/utilities';

// lodash
import _get from 'lodash/get';

export default function getColumns(reduxKey, zIndex) {
	return [
		{
			Header: 'Pos Configuration',
			id: 'posConfiguration',
			accessor: (d) => _get(d, 'pos_configuration.name', ''),
			filterPath: ':pos_configuration.name',
		},
		{
			Header: 'Name',
			id: 'name',
			accessor: (d) => d.name,
			filterPath: ':name',
		},
		{
			Header: 'Description',
			id: 'desc',
			accessor: (d) => d.description,
			filterPath: ':description',
		},
		{
			Header: 'Market',
			id: 'market',
			accessor: (d) => _get(d, 'pos_configuration.market.name', ''),
			filterPath: ':pos_configuration.market.name',
		},
		{
			Header: 'State',
			id: 'state',
			accessor: (d) => d.state,
			filterPath: ':state',
		},
		{
			Header: 'App tile layout',
			id: 'appTileLayout',
			accessor: (d) => _get(d, 'app_tile_layout.name', ''),
			filterPath: ':app_tile_layout.name',
		},
		{
			Header: 'Pos tile layout',
			id: 'posTileLayout',
			accessor: (d) => _get(d, 'pos_tile_layout.name', ''),
			filterPath: ':pos_tile_layout.name',
		},
		{
			Header: 'Delivery tile layout',
			id: 'delivery-tile-layout',
			accessor: (d) => _get(d, 'delivery_tile_layout.name', ''),
			filterPath: ':delivery_tile_layout.name',
		},
		{
			Header: 'Active from',
			id: 'activeFrom',
			width: 100,
			accessor: (d) => _get(d, 'active.from', ''),
			filterPath: ':active.from',
			Filter: ({ column }) => (
				<DateFilterSelector
					reduxKey={reduxKey}
					columnId={column.id}
					dateIdentifier="from"
					zIndex={zIndex}
				/>
			),
		},
		{
			Header: 'Active to',
			id: 'activeTo',
			accessor: (d) => _get(d, 'active.to', ''),
			width: 100,
			filterPath: ':active.to',
			Filter: ({ column }) => (
				<DateFilterSelector
					reduxKey={reduxKey}
					columnId={column.id}
					dateIdentifier="to"
					zIndex={zIndex}
				/>
			),
		},
	];
}
