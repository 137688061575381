'use strict';

import {
	TOGGLE_DAY_SETTINGS_MODAL,
	SET_DAY_SETTINGS_MODAL_DATA
} from './daySettings.actions';

const defaultState = {
	showDaySettingsModal: false,
	dateOfDaySettings: {},
	openingHours: {}
};

function reducer(state = defaultState, action) {
	const {type, payload} = action;
	switch (type) {
		case TOGGLE_DAY_SETTINGS_MODAL:
			
			return Object.assign({}, state, {
				showDaySettingsModal: payload.showDaySettingsModal
			});
		
		case SET_DAY_SETTINGS_MODAL_DATA:
			
			return Object.assign({}, state, {
				openingHours: payload.openingHours,
				dateOfDaySettings: payload.dateOfDaySettings,
			});
		
		default:
			return state;
	}
}

export default reducer;