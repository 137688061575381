'use strict';

import {
	RESET_STATE,
	UPDATE_BATCH_FORM,
	ADD_BATCH_LIST,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	SET_GLOBAL_BUNDLE_RULE,
	UPDATE_GLOBAL_BUNDLE_RULE,
} from './globalBundleRules.actions';

const defaultState = {
	data: {
		defaultGlobalBundleRule: {},
		batchFormData: {},
		batchListData: [],
	},
};

function reducer(state = defaultState, action) {
	const { payload, type } = action;

	switch (type) {
	case UPDATE_BATCH_FORM: {
		return {
			...state,
			data: {
				...state.data,
				batchFormData: {
					...state.data.batchFormData,
					...payload,
				},
			},
		};
	}

	case ADD_BATCH_LIST: {
		return {
			...state,
			data: {
				...state.data,
				batchListData: payload,
			},
		};
	}

	case EDIT_BATCH_LIST_ITEM: {
		return {
			...state,
			data: {
				...state.data,
				batchListData: state.data.batchListData.map((entry) => {
					if (entry.identifier === payload.identifier) return payload;
					return entry;
				}),
			},
		};
	}

	case REMOVE_BATCH_LIST_ITEM: {
		return {
			...state,
			data: {
				...state.data,
				batchListData: state.data.batchListData.filter(
					(entry) => entry.identifier !== payload
				),
			},
		};
	}

	case SET_GLOBAL_BUNDLE_RULE: {
		return {
			...state,
			data: {
				...state.data,
				defaultGlobalBundleRule: payload,
			},
		};
	}

	case UPDATE_GLOBAL_BUNDLE_RULE: {
		return {
			...state,
			data: {
				...state.data,
				defaultGlobalBundleRule: {
					...state.data.defaultGlobalBundleRule,
					...payload,
				},
			},
		};
	}

	case RESET_STATE: {
		return defaultState;
	}

	default:
		// never return default state in default case, Filip!!!
		return state;
	}
}

export default reducer;
