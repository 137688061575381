// src/utils/AuthService.js

import _get from 'lodash/get';
import { store } from 'appState';

/**
 * @function isBetatester
 * @description This is a  small helper function that we can use whenever we
 * want something in the production code that only should be executed/shown when a specific user is logged in.
 * @example
 *
 * render(){
 * 	const showNewAwesomeFeature = isBetatester();
 *
 * 	if(showNewAwesomeFeature){
 * 		return <AwesomeNewComponent shinyProp={aShinyProp} />
 * 	} else return null;
 * }
 *
 * @returns {boolean} - True is the logged in user is one of the beta tester
 */
export default function isBetatester() {
	const userData = store.getState().userData.user;
	const currentUserId = _get(userData, 'user.id', null);

	const betatesterUserIds = [
		25 /* Rose */,
		29 /* Sam */,
		150 /* Garnell */,
		48 /* Have */,
		26 /* schnack */,
		35 /* Tuna */,
		4988 /* Rasmus Rahe */,
		1 /* Kim */,
		7009 /* William Bech, loser -> https://youtu.be/YgSPaXgAdzE */,
		7268 /* Gudas */,
		68 /* Nesje */,
		58 /* Jeppesen */,
		3752 /* Jonas Møller */,
		73 /* STENDER */,
		74 /* SAMMU */
	];
	return betatesterUserIds.includes(currentUserId);
}
