import React from 'react';
// Interface
import { IColumnHeader } from 'types/reactDataWrapper';

// Components
import { DateFilterSelector } from 'reactDataWrapper/utilities';

type Props = {
	reduxKey: string;
};

export default function getColumns({ reduxKey }: Props): IColumnHeader[] {
	return [
		{
			Header: 'Id',
			id: 'id',
			accessor: 'id',
			filterPath: ':id',
		},
		{
			Header: 'Workplace',
			id: 'workplace',
			accessor: 'workplace.name',
			filterPath: ':workplace.name',
		},
		{
			Header: 'From',
			id: 'from',
			accessor: 'period.from',
			filterPath: ':period.from',
			Filter: ({ column }: { column: any }) => (
				<DateFilterSelector
					reduxKey={reduxKey}
					columnId={column.id}
					dateIdentifier="from"
				/>
			),
		},
		{
			Header: 'To',
			id: 'to',
			accessor: 'period.to',
			filterPath: ':period.to',
			Filter: ({ column }: { column: any }) => (
				<DateFilterSelector
					reduxKey={reduxKey}
					columnId={column.id}
					dateIdentifier="to"
				/>
			),
		},
	];
}
