'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	updateBatchForm,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setProductVariantGroup,
	updateProductVariantGroup,
	resetProductVariantGroup,
} from './store/productVariantGroups.actions';

// services
import {
	fetchProductVariantGroups,
	editProductVariantGroup,
	editProductVariantGroups,
	addProductVariantGroups,
	deleteProductVariantGroup,
} from './productVariantGroups.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';
import { Input, Button, Icon, Toggle } from 'dumb';

import ProductVariantGroupsModalBatch from './components/batch/productVariantGroupsModalBatch';
import ProductVariantGroupProductVariants from './components/productVariantGroupProductVariants/productVariantGroupProductVariants.component';

// lodash
import _get from 'lodash/get';

// phrases/constants
import phrases from './productVariantGroups.phrases';

class ProductVariantGroups extends Component {
	constructor(props) {
		super(props);

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.addProductVariantGroupWrapper = this.addProductVariantGroupWrapper.bind(
			this
		);

		this.state = {
			showModal: false,
			key: moment.utc(),
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this.fetchData = this.fetchData.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'desc',
				accessor: (d) => _get(d, 'description', ''),
				filterPath: ':description',
			},
			{
				Header: 'Sort order',
				id: 'sortOrder',
				accessor: (d) => _get(d, 'sort_order', ''),
				filterPath: ':sort_order',
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: 'legacy',
				filterPath: ':legacy',
				filterable: false,
				Cell: (d) => {
					return (
						<Input
							id="checkbox"
							type="checkbox"
							checked={d.original.legacy}
							disabled
						/>
					);
				},
			},
		];
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}>
				Legacy
			</Button>
		);
	}

	editEntry() {
		const { defaultProductVariantGroup } = this.props;

		const payload = {
			id: defaultProductVariantGroup.id,
			name: defaultProductVariantGroup.name,
			description: defaultProductVariantGroup.description,
			sort_order: defaultProductVariantGroup.sortOrder,
			legacy: !!defaultProductVariantGroup.legacy,
		};

		return editProductVariantGroup(payload);
	}

	editMultiple(selectedRows) {
		const { defaultProductVariantGroup } = this.props;

		const payload = {
			...(defaultProductVariantGroup.name && {
				name: defaultProductVariantGroup.name,
			}),
			...(defaultProductVariantGroup.description && {
				description: defaultProductVariantGroup.description,
			}),
			...(defaultProductVariantGroup.sortOrder && {
				sort_order: defaultProductVariantGroup.sortOrder,
			}),
			legacy: !!defaultProductVariantGroup.legacy,
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editProductVariantGroups({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteProductVariantGroup(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			sortOrder: data.sort_order,
			legacy: data.legacy,
		};

		this.props.setProductVariantGroup(payload);
	}

	getEditableCells() {
		const { defaultProductVariantGroup } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={defaultProductVariantGroup.name || ''}
						onChange={(event) => this.editStoreEntry('name', event)}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={defaultProductVariantGroup.description || ''}
						onChange={(event) => this.editStoreEntry('description', event)}
					/>
				),
			},
			{
				header: 'Sort order',
				value: (
					<Input
						id="sort_order"
						type="number"
						placeholder="Enter sort order..."
						value={defaultProductVariantGroup.sortOrder || null}
						onChange={(event) => this.editStoreEntry('sortOrder', event)}
					/>
				),
			},
			{
				header: 'Legacy',
				value: (
					<Toggle
						id="legacy"
						toggled={!!defaultProductVariantGroup.legacy}
						onClick={(event) => this.editStoreEntry('legacy', event)}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const value = e.target ? e.target.value : e;

		const updateObject = {
			[name]: value,
		};

		this.props.updateProductVariantGroup(updateObject);
	}

	fetchData(state) {
		return fetchProductVariantGroups(state);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetProductVariantGroup();
	}

	addProductVariantGroupWrapper(data) {
		return addProductVariantGroups(data).then(() => {
			this.setState(() => ({
				key: moment.utc(),
			}));
		});
	}

	render() {
		const {
			batchList,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
		} = this.props;

		const legacyFilter = this.state.legacyFilterOn
			? this.state.legacyFilter
			: '';

		return (
			<>
				<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey="/product/product_variant_groups"
					manual
					subcomponent={(row) => (
						<SubTable>
							<ProductVariantGroupProductVariants
								productVariantGroupId={_get(row, 'original.id', null)}
							/>
						</SubTable>
					)}
					editableCells={this.getEditableCells()}
					editEntry={(e) => this.editEntry(e)}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetProductVariantGroup}
					actionRender={
						<Button
							type="inverted"
							label="Batch"
							shadow
							onClick={() => this.toggleModal()}>
							<Icon name="add" />
						</Button>
					}
					customAreaComponents={this.getLegacyFilterButton()}
					extraFilters={legacyFilter}
				/>

				<ProductVariantGroupsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addProductVariantGroups={this.addProductVariantGroupWrapper}
				/>
			</>
		);
	}
}

ProductVariantGroups.propTypes = {
	defaultProductVariantGroup: PropTypes.object,

	updateProductVariantGroup: PropTypes.func,
	setProductVariantGroup: PropTypes.func,
	resetProductVariantGroup: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			editBatchListItem,
			removeBatchListItem,
			updateProductVariantGroup,
			setProductVariantGroup,
			resetProductVariantGroup,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultProductVariantGroup:
			store.productVariantGroups.data.defaultProductVariantGroup,
		batchList: store.productVariantGroups.data.batchList,
	};
};

export default connectWithStore(
	ProductVariantGroups,
	mapStateToProps,
	mapDispatchToProps
);
