// interface
import { ActionType } from 'types/redux';
import {
	IVacationDescriptionPreset,
	IVacationescriptionPresetBatch,
	IVacationDescriptionPresetsReduxState,
} from '../types';

// utils
import moment from 'moment';
import _uniqueId from 'lodash/uniqueId';

import {
	SET_VACATION_DESCRIPTION_PRESET,
	UPDATE_VACATION_DESCRIPTION_PRESET,
	RESET_VACATION_DESCRIPTION_PRESET,

	// batch
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	ADD_BATCH_LIST_ITEM_ROW,
	RERENDER_TABLE,
} from './vacationDescriptionPresets.actions';

const defaultState: IVacationDescriptionPresetsReduxState = {
	tableKey: moment().unix(),
	batchList: [
		{
			identifier: _uniqueId('row-'),
			subject: null,
			description: null,
		},
	],
	data: {
		defaultVacationescriptionPreset: null,
		vacationDescriptionPreset: {
			subject: '',
			description: '',
		},
	},
};

function reducer(
	state = defaultState,
	action: ActionType
): IVacationDescriptionPresetsReduxState {
	const { type, payload } = action;

	switch (type) {
	case SET_VACATION_DESCRIPTION_PRESET: {
		const castedPayload = payload as IVacationDescriptionPreset;

		return {
				...state,
				data: {
					vacationDescriptionPreset: castedPayload,
					defaultVacationescriptionPreset: castedPayload,
				},
			};
	}

	case UPDATE_VACATION_DESCRIPTION_PRESET: {
		const castedPayload = payload as Partial<IVacationDescriptionPreset>;

		return {
				...state,
				data: {
					...state.data,
					vacationDescriptionPreset: {
						...state.data.vacationDescriptionPreset,
						...castedPayload,
					},
				},
			};
	}

	case RESET_VACATION_DESCRIPTION_PRESET: {
		return defaultState;
	}

	case RERENDER_TABLE:
		return {
				...state,
				tableKey: moment().unix(),
			};

	case EDIT_BATCH_LIST_ITEM: {
		const castedPayload = payload as Partial<IVacationescriptionPresetBatch>;

		return {
				...state,
				batchList: state.batchList.map((entry) => {
					if (entry.identifier === castedPayload.identifier) {
						return { ...entry, ...castedPayload };
					}
					return entry;
				}),
			};
	}

	case REMOVE_BATCH_LIST_ITEM: {
		const castedPayload = payload as string;

		return {
				...state,
				batchList: state.batchList.filter(
					(entry) => entry.identifier !== castedPayload
				),
			};
	}

	case ADD_BATCH_LIST_ITEM_ROW:
		return {
				...state,
				batchList: [
					...state.batchList,
					{
						...defaultState.batchList[0],
						identifier: _uniqueId('row-'),
					},
				],
			};

	default:
		return state;
	}
}

export default reducer;
