// Types
import { ActionType } from 'types/redux';
import { SelectOption } from 'types/select';
import { Moment } from 'moment';

// Actions
export const SET_WORKPLACE = 'EMPLOMENT_WORKPLACE/SET_WORKPLACE';
export const RESET_REDUX_STATE = 'EMPLOMENT_WORKPLACE/RESET_REDUX_STATE';
export const SET_FROM_DATE = 'EMPLOMENT_WORKPLACE/SET_FROM_DATE';
export const SET_TO_DATE = 'EMPLOMENT_WORKPLACE/SET_TO_DATE';

export function resetReduxState(): ActionType {
	return {
		type: RESET_REDUX_STATE,
	};
}

export function setWorkplace(payload: SelectOption): ActionType {
	return {
		type: SET_WORKPLACE,
		payload,
	};
}

export function setFromDate(payload: Moment | string): ActionType {
	return {
		type: SET_FROM_DATE,
		payload,
	};
}

export function setToDate(payload: Moment | string): ActionType {
	return {
		type: SET_TO_DATE,
		payload,
	};
}
