import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './waitingTimePerformanceGrids.phrases';

/**
 * @function fetchWaitingTimePerformanceGrids
 * @description Fetch all waiting time peformance grids
 * @param {object} state
 */
export function fetchWaitingTimePerformanceGrids(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get(
		'/administration/waiting_time_performance_grids',
		params,
		null,
		state.headers
	)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addWaitingTimePerformanceGrids
 * @description add waiting time peformance grids
 */
export function addWaitingTimePerformanceGrids(data) {
	return post('/administration/waiting_time_performance_grids', data)
		.then((res) => {
			setFeedback(phrases.WAITING_TIME_PERFORMANCE_GRID_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editWaitingTimePerformanceGrid
 * @description edit waiting time peformance grid
 */
export function editWaitingTimePerformanceGrid(data) {
	return post(`/administration/waiting_time_performance_grids/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.WAITING_TIME_PERFORMANCE_GRID_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function editWaitingTimePerformanceGrids
 * @description edit waiting time peformance grids
 */
export function editWaitingTimePerformanceGrids(data) {
	return patch('/administration/waiting_time_performance_grids', data)
		.then((res) => {
			setFeedback(phrases.WAITING_TIME_PERFORMANCE_GRIDS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function deleteWaitingTimePerformanceGrids
 * @description delete waiting time peformance grid
 */
export function deleteWaitingTimePerformanceGrids(id) {
	return remove(`/administration/waiting_time_performance_grids/${id}`)
		.then((res) => {
			setFeedback(phrases.WAITING_TIME_PERFORMANCE_GRID_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
