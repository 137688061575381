import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchProducts
 * @description Fetch all productes values
 * @param {object} state
 */
export function fetchProducts(state, extraFilter) {
	let filter;
	if (state.filter) filter = state.filter;
	if (extraFilter) filter = filter ? `${filter};${extraFilter}` : extraFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(`/product/products`, params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addProducts
 * @description add
 */
export function addProducts(data) {
	return post(`/product/products`, data)
		.then((res) => {
			setFeedback('product added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editProduct
 * @description edit
 */
export function editProduct(data) {
	return post(`/product/products/${data.id}`, data)
		.then((res) => {
			setFeedback('product edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function editProducts
 * @description edit multiple products
 */
export function editProducts(data) {
	return patch('/product/products', data)
		.then((res) => {
			setFeedback('products edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function deleteproducts
 * @description del
 */
export function deleteProducts(productId) {
	return remove(`/product/products/${productId}`)
		.then((res) => {
			setFeedback('product deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
