'use strict';

// Services
import DetailsModel from 'common/components/details/detailsModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');

export default class TrainingGradeScalesDetails extends DetailsModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
		];

		this.settings = {
			name: 'TrainingGradeScales',
			canAdd: true,
			canEdit: true,
			scrollWrapper: false,
			canDelete: true,
			translationPath: 'TRAINING.GRADE_SCALES',
			canManipulate: () => validAccess(undefined, ['Global BI Configuration']),

			endpoint: __endpoints.collection.training.grade_scales,
		};
		this.init();
		return this;
	}
}
