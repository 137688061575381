import {
	UPDATE_EMPLOYMENT_LIST,
	SET_EMPLOYMENT_EDIT_VALUES,
	RESET_EMPLOYMENT_LIST
} from './employmentsList.actions';

const defaultState = {
	ui: {},
	data: {
		employmentEntry: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_EMPLOYMENT_EDIT_VALUES: {
		return {
			...state,
			data: {
				...state.data,
				employmentEntry: payload
			}
		};
	}

	case UPDATE_EMPLOYMENT_LIST: {
		return {
			...state,
			data: {
				...state.data,
				employmentEntry: {
					...state.data.employmentEntry,
					...payload
				}
			}
		};
	}

	case RESET_EMPLOYMENT_LIST: {
		return {
			...state,
			data: {
				...state.data,
				employmentEntry: {}
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
