'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, ButtonGroup } from 'dumb';
import { store, connectWithStore } from 'appState';
import * as actions from './store/toggleColumns.actions';
import { isMobile } from 'detectMobile.vanilla';

// lodash
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';

// styles
import './toggleColumns.css';

class ToggleColumns extends Component {
	constructor(props) {
		super(props);

		this.isMobile = isMobile();

		this._renderColumnButtons = this._renderColumnButtons.bind(this);
		this._initializeColumns = this._initializeColumns.bind(this);
		this._toggleSpecificColumn = this._toggleSpecificColumn.bind(this);
		this._getShouldInitializeColumns = this._getShouldInitializeColumns.bind(
			this
		);
		this._toggleColumn = _debounce(this._toggleColumn, 300, {
			trailing: true,
		}).bind(this);
	}

	_renderColumnButtons() {
		const { columns } = this.props;

		return (
			<div className="toggle-cells__wrapper__overflow__scroll">
				<ButtonGroup>
					{columns.map((value, key) => {
						if (!value.Header) return null;

						const show = value.show ?? true;

						return (
							<Button
								size={this.isMobile ? 'tiny' : 'small'}
								key={key}
								type="inverted"
								state={show ? 'active' : ''}
								shadow
								onClick={() => {
									this._toggleColumn({ value });
								}}>
								{value.Header}
							</Button>
						);
					})}
				</ButtonGroup>
			</div>
		);
	}

	/**
	 * @function _initializeColumns
	 * @param {Object} object - value -> column we want to toggle, reduxKey -> table's reduxKey
	 * @description if table not saved in redux (toggleColumnsData) then format it's columns
	 * to match [{id: false}, {name: true}...] where the key is the column id and value is
	 * whether the column is being showed or not
	 */
	_initializeColumns({ value, reduxKey }) {
		const { columns } = this.props;

		const payload = columns.map((entry) => {
			let isColumnShown = entry.show ?? true;
			// if this is the column we clicked on, toggle it
			if (value.id === entry.id) isColumnShown = !isColumnShown;

			return {
				[entry.id]: isColumnShown,
			};
		});

		store.dispatch(actions.initializeColumns({ value: payload, reduxKey }));
	}

	/**
	 * @function _getShouldInitializeColumns
	 * @description if toggleColumnsData exist it
	 * compares columns passed from the actual table component
	 * with columns saved in local storage and if there are any differences
	 * it reinitializes local storage entry
	 */
	_getShouldInitializeColumns() {
		let { columns, toggleColumnsData } = this.props;

		// if no columns saved in local storage
		if (!toggleColumnsData) return true;

		// remove potentially added rdwInRowButtons
		toggleColumnsData = toggleColumnsData.filter(
			(entry) => Object.keys(entry)[0] !== 'rdwInRowButtons'
		);
		columns = columns.filter((entry) => entry.id !== 'rdwInRowButtons');

		const newColumnAdded = columns.some((entry) => {
			const column = toggleColumnsData.find((entryTwo) => {
				const columnId = Object.keys(entryTwo)[0];

				return columnId === entry.id;
			});

			if (!column) return true;
		});

		const lengthMismatch = columns.length !== toggleColumnsData.length;

		if (newColumnAdded || lengthMismatch) return true;
		return false;
	}

	/**
	 * @function _toggleSpecificColumn
	 * @param {Object} object - object
	 * @param {Object} object.value - column object
	 * @param {String} object.reduxKey - specific id for each table
	 * @param {Array} object.toggleColumnsData - array containing column objects
	 * @description determines whether the column can be toggled
	 */
	_toggleSpecificColumn({ value, reduxKey, toggleColumnsData }) {
		// if current show state is true then we need
		// to check if we can toggle it to false
		if (value.show) {
			// find only hidden columns
			const hiddenColumnsArray = toggleColumnsData.filter((entry) => {
				const columnId = Object.keys(entry)[0];

				if (!entry[columnId]) return true;
				return false;
			});

			// remove column with id rdwInRowButtons if present
			// and check if lengths are same (1 is the column we are trying to hide)
			const shouldEscape =
				toggleColumnsData.filter(
					(entry) => Object.keys(entry)[0] !== 'rdwInRowButtons'
				).length ===
				hiddenColumnsArray.length + 1;

			if (shouldEscape) return;
		}

		const payload = {
			id: value.id,
			show: !value.show,
		};

		store.dispatch(actions.setToggleColumns({ value: payload, reduxKey }));
	}

	/**
	 * @function _toggleColumn
	 * @param {Object} value - column var containing headers, id, accessors..
	 * @description sets the column to visible or hidden
	 */
	_toggleColumn({ value }) {
		const { reduxKey, toggleColumnsData } = this.props;

		const shouldInitialize = this._getShouldInitializeColumns();

		// if we don't have the table in the store
		if (shouldInitialize) {
			this._initializeColumns({
				value,
				reduxKey,
			});
		} else {
			// we have the table in the store and just want to toggle a specific column
			this._toggleSpecificColumn({
				value,
				reduxKey,
				toggleColumnsData,
			});
		}
	}

	render() {
		return (
			<div className="toggle-cells__wrapper">
				<div className="toggle-cells__wrapper__overflow">
					{this._renderColumnButtons()}
				</div>
			</div>
		);
	}
}

ToggleColumns.propTypes = {
	columns: PropTypes.array,
	reduxKey: PropTypes.string,
	toggleColumnsData: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
};

const mapStateToPropsFactory = (initialStore, ownProps) => (store) => {
	return {
		toggleColumnsData: _get(
			store,
			`toggleColumnsData.tableColumns[${ownProps.reduxKey}]`,
			false
		),
	};
};

export default connectWithStore(ToggleColumns, mapStateToPropsFactory);
