// Action constants
import {
	SET_FORECASTING,
	LOADING_FORECASTING,
	TOGGLE_FORECASTING,
	TOGGLE_FORECASTING_GHOST,
	UPDATE_SHIFTPLANNER_FORECASTING,
	CLEAN_FORECASTING
} from './shiftplannerForecasting.actions';

import _isEmpty from 'lodash/isEmpty';

import constants from 'services/constants';

// Utilities
import { groupToShifts, createTimeHourTable, isWithinPeriod } from './utils';

const defaultState = {
	data: {
		forecastingData: {},
		forecastingGhostData: {}
	},
	ui: {
		loading: false,
		timePositionTable: [],
		showForecasting: false,
		showForecastingGhost: false
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case LOADING_FORECASTING:
		return {
			...state,
			ui: { ...state.ui, loading: payload || !state.ui.loading }
		};

	case CLEAN_FORECASTING:
		return {
			...state,
			ui: { ...defaultState.ui },
			data: { ...defaultState.data }
		};

	case TOGGLE_FORECASTING:
		return {
			...state,
			ui: { ...state.ui, showForecasting: !state.ui.showForecasting }
		};

	case TOGGLE_FORECASTING_GHOST:
		return {
			...state,
			ui: {
				...state.ui,
				showForecastingGhost: !state.ui.showForecastingGhost
			}
		};

	case UPDATE_SHIFTPLANNER_FORECASTING: {
		const timeTable = createTimeHourTable(2, 25);

		const { forecastData, shifts, day } = payload;

		if (_isEmpty(forecastData)) return state;

		const forecastingData =
				forecastData[day] &&
				forecastData[day].reduce((acc, forecastEntry) => {
					const timeFrom = moment.utc(
						`${day}T${forecastEntry.from}`,
						constants.dateFormat
					);
					const timeTo = moment.utc(
						`${day}T${forecastEntry.to}`,
						constants.dateFormat
					);

					const actualEmployees = shifts.reduce((a, shift) => {
						const excluded =
							shift.shift_tags &&
							shift.shift_tags.some(
								shiftTag =>
									shiftTag.tag.exclude_productivity &&
									isWithinPeriod(timeFrom, timeTo, shiftTag.period)
							);

						if (excluded) return a;

						const isWithin = isWithinPeriod(
							timeFrom,
							timeTo,
							shift.planned_period
						);

						return isWithin ? a + 1 : a;
					}, 0);

					const constructedObject = {
						...forecastEntry,
						actual: actualEmployees
					};

					return [...acc, constructedObject];
				}, []);

		return {
			...state,
			data: {
				...state.data,
				forecastingData: {
					...state.data.forecastingData,
					[day]: forecastingData
				}
			},
			ui: {
				...state.ui,
				loading: false,
				timePositionTable: timeTable
			}
		};
	}

	case SET_FORECASTING:
		// Creates table with time and respective height
		// this later might be improved by mergind both
		// arrays with reduce instead createing and each time
		// calculating top; plus
		const timeTable = createTimeHourTable(2, 25);

		const { forecastData, shifts } = payload;

		if (!forecastData) return state;

		// Group forecasting data by DATE
		const forecastingData = forecastData.reduce((acc, dayData) => {
			const day = dayData.date;

			const timeFrom = moment.utc(dayData.period.from);
			const timeTo = moment.utc(dayData.period.to);

			const actualEmployees = shifts.reduce((a, shift) => {
				const excluded = shift.shift_tags.some(
					shiftTag =>
						shiftTag.tag.exclude_productivity &&
							isWithinPeriod(timeFrom, timeTo, shiftTag.period)
				);

				if (excluded) return a;

				const isWithin = isWithinPeriod(
					timeFrom,
					timeTo,
					shift.planned_period
				);

				return isWithin ? a + 1 : a;
			}, 0);

			const constructedObject = {
				from: timeFrom.format('HH:mm:ss'),
				to: timeTo.format('HH:mm:ss'),
				employees: dayData.employees,
				actual: actualEmployees,
				top: timeTable.filter(x => x.time === timeFrom.format('HH:mm:ss'))[0]
					.top
			};

			return {
				...acc,
				[day]: acc[day]
					? [...acc[day], constructedObject]
					: [constructedObject]
			};
		}, {});

		// Get forecasting data grouped by from-to shift periods
		const forecastingGhostData = groupToShifts(forecastingData, timeTable);

		return {
			...state,
			data: {
				...state.data,
				forecastingData,
				forecastingGhostData
			},
			ui: {
				...state.ui,
				loading: false,
				timePositionTable: timeTable
			}
		};

	default:
		return state;
	}
}

export default reducer;
