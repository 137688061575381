import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './basketRecommendationPriorities.phrases';

/**
 * @function fetchBasketRecommendationPriorities
 * @description Fetch all product bundles
 * @param {object} state
 */
export function fetchBasketRecommendationPriorities(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get(
		'/pos/basket_recommendation_priorities',
		params,
		null,
		state.headers
	)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addBasketRecommendationPriority
 * @description add product bundle
 */
export function addBasketRecommendationPriority(data) {
	return post('/pos/basket_recommendation_priorities', data)
		.then((res) => {
			setFeedback(phrases.BASKET_RECOMMENDATION_PRIORITY_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editBasketRecommendationPriority
 * @description edit product bundle
 */
export function editBasketRecommendationPriority(data) {
	return post(`/pos/basket_recommendation_priorities/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.BASKET_RECOMMENDATION_PRIORITY_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteBasketRecommendationPriorities
 * @description delete product bundle
 */
export function deleteBasketRecommendationPriorities(id) {
	return remove(`/pos/basket_recommendation_priorities/${id}`)
		.then((res) => {
			setFeedback(phrases.BASKET_RECOMMENDATION_PRIORITIES_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
