import { SET_SHOW_MODAL, SET_METADATA, SET_UI_DATA, RESET_DATA } from './removeShiftsFromEmployee.actions';

// interfaces
import {
	IRemoveShiftsFromEmployeeDefaultState,
	IRemoveShiftsFromEmployeeDefaultStateMetadata,
	IRemoveShiftsFromEmployeeDefaultStateUiData,
} from 'types/removeShiftsFromEmployee';
import { ActionType } from 'types/redux';

// misc
import moment from 'moment';

const defaultState: IRemoveShiftsFromEmployeeDefaultState = {
	showModal: false,
	metadata: {
		personId: null,
		shifts: [],
		workplace: null,
		source: null,
		juicerDetailsReduxKey: null,
	},
	uiData: {
		workplace: null,
		employment: null,
		date: moment.utc(),
	},
};

function reducer(state = defaultState, action: ActionType): IRemoveShiftsFromEmployeeDefaultState {
	const { type, payload } = action;

	switch (type) {
	case SET_SHOW_MODAL:
		return {
				...state,
				showModal: payload as boolean,
			};
	case SET_METADATA:
		return {
				...state,
				metadata: {
					...state.metadata,
					...(payload as IRemoveShiftsFromEmployeeDefaultStateMetadata),
				},
			};
	case SET_UI_DATA:
		return {
				...state,
				uiData: {
					...state.uiData,
					...(payload as IRemoveShiftsFromEmployeeDefaultStateUiData),
				},
			};

	case RESET_DATA:
		return defaultState;

	default:
		return state;
	}
}

export default reducer;
