export default {
	TITLE: 'Employee meal app allowances',
	EMPLOYEE_MEAL_APP_ALLOWANCE_ADDED: 'App allowances added!',
	EMPLOYEE_MEAL_APP_ALLOWANCE_EDITED: 'App allowance edited!',
	EMPLOYEE_MEAL_APP_ALLOWANCES_EDITED: 'App allowances edited!',
	EMPLOYEE_MEAL_APP_ALLOWANCE_DELETED: 'App allowance deleted!',

	// batch form
	MODAL_BATCH_STEP_LIST_TITLE: 'Create app allowances',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_COFFEE: 'Coffee',
	MODAL_BATCH_STEP_COFFEE_PLACEHOLDER: 'Enter coffee amount...',
	MODAL_BATCH_STEP_ANY_ITEM: 'Any item',
	MODAL_BATCH_STEP_ANY_ITEM_PLACEHOLDER: 'Enter any item amount...',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add app allowances',
};
