'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { Button, Icon } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';
import { submitBatchValues } from 'reactDataWrapper/utilities/batchHelper';

import {
	fetchRawIngredientConfigurationRawIngredients,
	fetchRawIngredients,
	addRawIngredientConfigurationRawIngredients,
	deleteRawIngredientConfigurationRawIngredient
} from './rawIngredientConfigurationRawIngredients.service';

import {
	setRawIngredientConfigurationsModal,
	updateRawIngredientConfigurations,
	resetState,
	updateBatchForm,
	resetBatch,
	addBatchList,
	editBatchListItem,
	removeBatchListItem
} from './store/rawIngredientConfigurationRawIngredients.actions';

// components
import ModalBatch from './components/batch/rawIngredientConfigurationRawIngredientsModalBatch';

// constants/phrases/tools
import phrases from './rawIngredientConfigurationRawIngredients.phrases';
import _get from 'lodash/get';

class RawIngredientConfigurationRawIngredients extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false
		};

		this._toggleModal = this._toggleModal.bind(this);
		this.batchAdd = this.batchAdd.bind(this);
		this.fetchData = this.fetchData.bind(this);

		this.reduxKey = '/inventory/raw_ingredient_configuration_raw_ingredients';

		this.columns = [
			{
				Header: 'Raw ingredient',
				id: 'raw_ingredient',
				accessor: d => _get(d, 'raw_ingredient.name', ''),
				filterPath: ':raw_ingredient.name'
			}
		];
	}

	deleteEntry(id) {
		return deleteRawIngredientConfigurationRawIngredient(id);
	}

	_toggleModal() {
		this.setState(prevState => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	batchAdd(payload) {
		const { rawIngredientConfiguration } = this.props;

		return addRawIngredientConfigurationRawIngredients(payload)
			.then(res => {
				const reduxKey = `${this.reduxKey}-${rawIngredientConfiguration.id}`;

				submitBatchValues({ res, reduxKey });
				return res;
			})
			.catch(err => {
				throw err;
			});
	}

	fetchData(state) {
		const { rawIngredientConfiguration } = this.props;

		const filter = `:raw_ingredient_configuration.id=='${rawIngredientConfiguration.id}'`;

		return fetchRawIngredientConfigurationRawIngredients(state, filter);
	}

	render() {
		const {
			batchList,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			updateBatchForm,
			batchFormData,
			listRawIngredients,
			rawIngredientConfiguration
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={`${this.reduxKey}-${rawIngredientConfiguration.id}`}
					manual
					deleteEntry={this.deleteEntry}
					accessAreasAllowedToEdit={['Global Sales Configuration', 'Sales Configuration']}
					onModalClose={this.props.resetState}
					actionRender={
						<Button
							type="inverted"
							label="Add"
							shadow
							onClick={() => this._toggleModal()}>
							<Icon name="add" />
						</Button>
					}
				/>

				<ModalBatch
					modalVisible={this.state.showModal}
					handleClose={this._toggleModal}
					updateBatchForm={updateBatchForm}
					addBatchList={addBatchList}
					batchList={batchList}
					addRawIngredientConfigurationRawIngredients={this.batchAdd}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					batchFormData={batchFormData}
					listRawIngredients={listRawIngredients}
					fetchRawIngredients={fetchRawIngredients}
					rawIngredientConfigurationId={rawIngredientConfiguration.id}
				/>
			</>
		);
	}
}

RawIngredientConfigurationRawIngredients.propTypes = {
	resetState: PropTypes.func,
	batchList: PropTypes.array,
	addBatchList: PropTypes.func,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	updateBatchForm: PropTypes.func,
	rawIngredientConfiguration: PropTypes.object,
	batchFormData: PropTypes.object,
	listRawIngredients: PropTypes.array
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			setRawIngredientConfigurationsModal,
			updateRawIngredientConfigurations,
			resetState,
			updateBatchForm,
			resetBatch,
			addBatchList,
			editBatchListItem,
			removeBatchListItem
		},
		dispatch
	);
};

const mapStateToProps = store => {
	return {
		rawIngredientConfigurationRawIngredients:
			store.globalAdministrationRawIngredientConfigurationRawIngredients.data
				.rawIngredientConfigurationRawIngredients,
		batchList:
			store.globalAdministrationRawIngredientConfigurationRawIngredients.data
				.batchList,
		batchFormData:
			store.globalAdministrationRawIngredientConfigurationRawIngredients.data
				.batchFormData,
		listRawIngredients: store.listData['/inventory/raw_ingredients']
	};
};

export default connectWithStore(
	RawIngredientConfigurationRawIngredients,
	mapStateToProps,
	mapDispatchToProps
);
