'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import moment from 'moment';
import { validAccess } from 'accessControl';
const __endpoints = require('endpoints.service');

export default class MarketEmployeeContributionsList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'contribution',
				type: 'number',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'period',
				type: 'date',
				canAdd: true,
				canEdit: true,
				required: true,
				defaultValue: moment(),
				reference: {
					key: 'from',
					translationKey: 'FROM',
				},
			},
			{
				name: 'period',
				type: 'date',
				canAdd: true,
				canEdit: true,
				reference: {
					key: 'to',
					translationKey: 'TO',
				},
			},
		];
		this.settings = {
			name: 'MarketEmployeeContributionsList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			canManipulate: () => validAccess(undefined, ['Organisation/Markets::Edit', 'Organization Admin']),
			canCollapse: true,
			alwaysRefresh: true,
			translationPath: 'SALARY.EMPLOYEE_CONTRIBUTIONS',
			endpoint: __endpoints.collection.salary.employee_contributions,
		};
		this.init();
		return this;
	}
}
