'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';

import { Tabs, Input, Button, Tooltip, Icon, Modal, Toggle } from 'dumb';
import AddonIngredient from './components/addonIngredient/addonIngredient.component';
import ProductVariantAddosn from './components/productVariantAddosn.component';
import AddonRecommendations from './components/addonRecommendations/addonRecommendations.component';

import _get from 'lodash/get';
import phrases from './productAddons.phrases';

import {
	setProductAddons,
	updateProductAddons,
	resetProductAddons,
} from './store/productAddons.actions';

import {
	fetchAllProductAddons,
	deleteProductAddons,
	editProductAddons,
	addProductAddons,
	duplicateProductAddons,
	editProductAddonsMultiple,
} from './productAddons.service';

// styles
import './productAddons.css';

class ProductAddons extends Component {
	constructor(props) {
		super(props);

		this.state = {
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
			isDuplicateModalOpen: false,
			loading: false,
		};

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editMultipleEntries = this.editMultipleEntries.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.getActionButtons = this.getActionButtons.bind(this);
		this._toggleDuplicateModal = this._toggleDuplicateModal.bind(this);
		this.duplicateProductAddon = this.duplicateProductAddon.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Desc',
				id: 'desc',
				accessor: (d) => _get(d, 'description', ''),
				filterPath: ':description',
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: (d) => _get(d, 'legacy', ''),
				filterPath: ':legacy',
				Cell: (d) => {
					return (
						<Input
							id="checkbox-leg"
							type="checkbox"
							checked={_get(d, 'original.legacy', false)}
							disabled
						/>
					);
				},
			},
		];
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}>
				Legacy
			</Button>
		);
	}

	addEntry() {
		const { defaultValues } = this.props;

		const payload = {
			name: _get(defaultValues, 'name', ''),
			description: _get(defaultValues, 'description', ''),
			legacy: !!defaultValues.legacy,
		};

		return addProductAddons(payload);
	}

	editEntry() {
		const { defaultValues } = this.props;

		const payload = {
			name: _get(defaultValues, 'name', ''),
			description: _get(defaultValues, 'description', ''),
			legacy: !!defaultValues.legacy,
		};

		return editProductAddons(defaultValues.id, payload);
	}

	editMultipleEntries(selectedRows) {
		const { defaultValues } = this.props;

		const payload = {
			...(defaultValues.name && {
				name: defaultValues.name,
			}),
			...(defaultValues.description && {
				description: defaultValues.description,
			}),
			legacy: !!defaultValues.legacy,
		};

		const data = selectedRows.map((entry) => ({
			id: entry.id,
			...payload,
		}));

		return editProductAddonsMultiple({ batch: data });
	}

	duplicateProductAddon() {
		const { defaultValues } = this.props;

		this.setState(() => ({
			loading: true,
		}));

		const payload = {
			name: defaultValues.name,
			description: defaultValues.description,
		};

		duplicateProductAddons(defaultValues.id, payload)
			.then(() => {
				this.setState(() => ({
					loading: false,
				}));

				this._toggleDuplicateModal();
			})
			.catch(() => {
				this.setState(() => ({
					loading: false,
				}));
			});
	}

	deleteEntry(id) {
		return deleteProductAddons(id);
	}

	setInitialEditValues(data) {
		this.props.setProductAddons(data);
	}

	getEditableCells() {
		const { defaultValues } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="name"
						value={_get(defaultValues, 'name', '')}
						onChange={(event) =>
							this.editStoreEntry(event.target.value, 'name')
						}
					/>
				),
			},
			{
				header: 'Desc',
				value: (
					<Input
						id="description"
						placeholder="description"
						value={_get(defaultValues, 'description', '')}
						onChange={(event) =>
							this.editStoreEntry(event.target.value, 'description')
						}
					/>
				),
			},
			{
				header: 'Legacy',
				value: (
					<Toggle
						id="legacy"
						toggled={defaultValues.legacy}
						onClick={(event) => this.editStoreEntry(event, 'legacy')}
					/>
				),
			},
		];
	}

	editStoreEntry(e, name) {
		const payload = {
			[name]: e,
		};

		this.props.updateProductAddons(payload);
	}

	fetchData(state) {
		const { addonId } = this.props;

		const extraFilter = addonId ? `:id=='${addonId}'` : null;

		return fetchAllProductAddons(state, extraFilter);
	}

	_toggleDuplicateModal(productAddon) {
		if (!this.state.isDuplicateModalOpen)
			this.setInitialEditValues(productAddon);
		else this.props.resetProductAddons();

		this.setState((prevState) => ({
			isDuplicateModalOpen: !prevState.isDuplicateModalOpen,
		}));
	}

	getActionButtons() {
		const { listProductAddons } = this.props;

		const selectedProductAddon = _get(listProductAddons, 'ui.selectedRows', []);

		return selectedProductAddon.length === 1 ? (
			<Tooltip
				placement="left"
				text={phrases.TOOLIP_TEXT}
				renderData={(ref, onMouseEnter, onMouseLeave) => (
					<Button
						type="inverted"
						label={phrases.DUPLICATE_PRODUCT_ADDON_BUTTON_LABEL}
						shadow
						onClick={() => this._toggleDuplicateModal(selectedProductAddon[0])}
						refProp={ref}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}>
						<Icon name="content_copy" />
					</Button>
				)}
			/>
		) : null;
	}

	render() {
		const { defaultValues } = this.props;

		const legacyFilter = this.state.legacyFilterOn
			? this.state.legacyFilter
			: '';

		return (
			<>
				<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
					title={phrases.PRODUCT_ADDONS}
					columns={this.columns}
					totalEntries={this.state.totalEntries}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey="product/addons"
					manual
					subcomponent={(row) => {
						const tabContent = [
							{
								name: phrases.ADDON_INGREDIENTS,
								component: <AddonIngredient addon={row.original} />,
							},
							{
								name: phrases.PRODUCT_VARIANT_ADDONS,
								component: (
									<ProductVariantAddosn
										title={phrases.PRODUCT_VARIANT_ADDONS}
										addon={row.original}
									/>
								),
							},
							{
								name: phrases.ADDON_RECOMMENDATIONS,
								component: <AddonRecommendations addon={row.original} />,
							},
						];
						return (
							<SubTable>
								<Tabs tabContent={tabContent} />
							</SubTable>
						);
					}}
					editEntry={(e) => this.editEntry(e)}
					editMultiple={this.editMultipleEntries}
					editableCells={this.getEditableCells()}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetProductAddons}
					actionsWidth={30}
					deleteEntry={this.deleteEntry}
					createEntry={this.addEntry}
					customAreaComponents={this.getLegacyFilterButton()}
					extraFilters={legacyFilter}
					actionRender={this.getActionButtons()}
				/>

				{this.state.isDuplicateModalOpen && (
					<Modal
						header={phrases.DUPLICATE_PRODUCT_ADDON}
						isOpen={this.state.isDuplicateModalOpen}
						loading={this.state.loading}
						type="confirmation"
						confirmButtonLabel={phrases.DUPLICATE_PRODUCT_ADDON_CONFIRM_BUTTON}
						cancelButtonLabel={phrases.DUPLICATE_PRODUCT_ADDON_CANCEL_BUTTON}
						onConfirmClick={this.duplicateProductAddon}
						onCancelClick={this._toggleDuplicateModal}
						onClose={this._toggleDuplicateModal}>
						<div className="product-addons-duplicate-modal">
							<Input
								id="name"
								label={phrases.DUPLICATE_PRODUCT_ADDON_NAME}
								placeholder={phrases.DUPLICATE_PRODUCT_ADDON_NAME_PLACEHOLDER}
								value={_get(defaultValues, 'name', '')}
								onChange={(event) => this.editStoreEntry(event, 'name')}
							/>
							<Input
								id="description"
								label={phrases.DUPLICATE_PRODUCT_ADDON_DESCRIPTION}
								placeholder={
									phrases.DUPLICATE_PRODUCT_ADDON_DESCRIPTION_PLACEHOLDER
								}
								value={_get(defaultValues, 'description', '')}
								onChange={(event) => this.editStoreEntry(event, 'description')}
							/>
						</div>
					</Modal>
				)}
			</>
		);
	}
}

ProductAddons.propTypes = {
	defaultValues: PropTypes.object,
	listProductAddons: PropTypes.object,
	addonId: PropTypes.number,

	updateProductAddons: PropTypes.func,
	setProductAddons: PropTypes.func,
	resetProductAddons: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setProductAddons,
			updateProductAddons,
			resetProductAddons,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultValues: store.productAddons.data.defaultProductAddons,
		listProductAddons: store.listData['product/addons'],
	};
};

export default connectWithStore(
	ProductAddons,
	mapStateToProps,
	mapDispatchToProps
);
