'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class Checkbox extends Component {
	constructor(props) {
		super(props);
		this.focus = this.focus.bind(this);
	}

	focus() {
		this.checkbox.focus();
	}

	render() {
		const { onChange, onKeyDown, initialValue, withBorder } = this.props;

		const checkboxCx = cx('checkbox-legacy', {
			'checkbox-legacy--with-border': withBorder,
		});

		return (
			<div className={checkboxCx}>
				<input
					ref={(input) => {
						this.checkbox = input;
					}}
					type="checkbox"
					defaultChecked={initialValue}
					onKeyDown={onKeyDown}
					onChange={onChange}
				/>
			</div>
		);
	}
}

Checkbox.propTypes = {
	onChange: PropTypes.func.isRequired,
	initialValue: PropTypes.bool,
	withBorder: PropTypes.bool,
};

export default Checkbox;
