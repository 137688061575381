// Store
import { store } from 'appState';
import * as posConfigurationLayout from '../posConfigurationLayout.actions';

// Tools
import { fetchCompleteCollection } from 'api/fetchCompleteCollection';

/**
 * @function getTileAssets
 * @param {array} domain
 * @description Get assets all assets for layout based on Type of layout (App, List or POS)
 */
export function getAssets({ type, layoutId, subType }) {
	const filter =
		subType === 'Grid'
			? `:domain=='${type}';(:type=='Menu Logo',:type=='Product Tile',:type=='Product Ingredients Left',:type=='Product Details',:type=='Product Ingredients Right');(:asset_collection.products.tile_layout_tiles.tile_layout_group.tile_layout.id=='${layoutId}',:asset_collection.tile_layout_groups.tile_layout.id=='${layoutId}')`
			: `:domain=='${type}';(:type=='Menu Logo',:type=='Row Layout Product Detail',:type=='Row Layout Tile');(:asset_collection.products.tile_layout_tiles.tile_layout_group.tile_layout.id=='${layoutId}',:asset_collection.tile_layout_groups.tile_layout.id=='${layoutId}')`;

	return fetchCompleteCollection('/pos/assets', {
		filter,
		limit: 300
	}).then(images => {
		store.dispatch(posConfigurationLayout.setAssets(images));
		return images;
	});
}
