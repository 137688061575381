'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
const __endpoints = require('endpoints.service');
const __regex = require('regex.service');
const __enums = require('enums.service');

export default class GlobalAccountsAccountScheduleAccountsList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'account_schedule',
				type: 'select',
				canAdd: true,
				required: true,
				reference: {
					key: 'name',
					list: '/accounting/account_schedules',
				},
			},
			{
				name: 'type',
				type: 'select',
				canAdd: true,
				canEdit: true,
				required: true,
				options: {
					enums: __enums.accountScheduleAccountTypes,
				},
			},
			{
				name: 'account',
				type: 'number',
				canAdd: true,
				canEdit: true,
				required: true,
				regex: __regex.number,
			},
		];
		this.settings = {
			name: 'GlobalAccountsAccountScheduleAccountsList',
			pageTitle: true,
			canAdd: true,
			canEdit: true,
			canDelete: true,
			alwaysRefresh: true,
			translationPath: 'ACCOUNTING.ACCOUNT_SCHEDULE_ACCOUNTS',
			endpoint: __endpoints.collection.accounting.account_schedule_accounts,
		};
		this.init();
	}
}
