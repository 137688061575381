export default {
	TABLE_TITLE: 'Vacation description presets',
	ENTRY_ADDED: 'Vacation description preset added',
	ENTRY_EDITED: 'Vacation description preset edited',
	ENTRIES_EDITED: 'Vacation description presets edited',
	ENTRIES_DELETED: 'Vacation description preset deleted',

	// batch
	BATCH_ADD: 'Add',
	MODAL_TITLE: 'Add Vacation description presets',
	SUBMIT: 'Submit',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_LIST_SUBJECT: 'Subject',
	MODAL_BATCH_STEP_LIST_DESCRIPTION: 'Description',
	ADD_ROW_TOOLTIP: 'Add row',
	DELETE_ROW: 'Delete row',
};
