import PropTypes from 'prop-types';
import React from 'react';
import constants from './../constants';

import { Row } from './row.component';
import './row.css';
import { RowExpandable } from './rowExpandable.component';

const RowWrapper = React.memo(props => {
	// If we dont give it a render expanded
	if (!props.renderExpanded) return <Row {...props}>{props.children}</Row>;

	switch (props.type) {
	case constants.TABLE_TYPES.TABLE_TOTAL:
	case constants.TABLE_TYPES.SUBTOTAL:
	case constants.TABLE_TYPES.SUB_TOTAL:
	case constants.TABLE_TYPES.SUB_SUBTOTAL:
		return <Row {...props}>{props.children}</Row>;

	case constants.TABLE_TYPES.ENTRY:
		if (!props.totalColSpan)
			console.info('totalColSpan should be added if row is expandable');
		if (!props.renderExpanded)
			console.info('renderExpanded should be added if row is expandable');

		return <RowExpandable {...props}>{props.children}</RowExpandable>;

	default:
		return <Row {...props}>{props.children}</Row>;
	}
});

RowWrapper.displayName = 'RowWrapper';

RowWrapper.defaultProps = {
	className: '',
	index: null,
	renderExpanded: null
};

RowWrapper.propTypes = {
	className: PropTypes.object,
	index: PropTypes.number,
	type: PropTypes.string,
	totalColSpan: PropTypes.number,
	renderExpanded: PropTypes.object,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired
};

export { RowWrapper };
