import React from 'react';
import PropTypes from 'prop-types';

// components
import { Tooltip, Icon, Button } from 'dumb';

// enums
import enums from './../shiftClockinsTableColumns.enums';

// utils
import phrases from './../../../shiftClockinsTable.phrases';
import { getAreClockinsOnSameDay } from './../utils';

const ClockinDeviationCell = ({ deviation, editClockedValue, row, type }) => {
	const onEditClockinClick = () => {
		// type is either from or to
		editClockedValue(row, type);
	};

	const managerExists = !!deviation?.manager;

	let iconName, noManagerApprovalRequired;
	if (deviation?.status === enums.CLOCKIN_DEVIATION.PENDING_CONFIRMATION && managerExists)
		iconName = 'sentiment_dissatisfied';
	if (deviation?.status === enums.CLOCKIN_DEVIATION.PENDING_CONFIRMATION && !managerExists) {
		iconName = 'thumb_up';
		noManagerApprovalRequired = true;
	}
	if (deviation?.status === enums.CLOCKIN_DEVIATION.CONFIRMED) iconName = 'thumb_up';
	if (deviation?.status === enums.CLOCKIN_DEVIATION.DECLINED) iconName = 'thumb_down';

	const managerAndReason = `. ${deviation?.status} by ${deviation?.manager?.full_name ?? ''}`;
	const tooltipText = `${deviation?.deviation_reason?.description}${managerExists ? managerAndReason : ''}`;

	// format dates
	const clockinsOnSameDay = getAreClockinsOnSameDay({
		clockedFrom: row.clocked?.from ?? null,
		clockedTo: row.clocked?.to ?? null,
	});

	return (
		<div className="shift-clockins__table-wrapper__clockin-deviation-icon-wrapper">
			{deviation?.status && (
				<Tooltip
					text={tooltipText}
					visible={!!tooltipText}
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<div
							className="shift-clockins__table-wrapper__clockin-deviation-icon-wrapper__icon-wrapper"
							style={{
								...(noManagerApprovalRequired && {
									opacity: 0.3,
								}),
							}}
						>
							<Icon name={iconName} refProp={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
						</div>
					)}
				/>
			)}
			{!clockinsOnSameDay && (
				<Tooltip
					text={phrases.CLOCKINS_NOT_ON_SAME_DAY}
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							className="shift-clockins__table-wrapper__clockin-deviation-icon-wrapper__button"
							size="micro"
							type="inverted"
							shadow
							onClick={onEditClockinClick}
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
						>
							<Icon name="edit" />
						</Button>
					)}
				/>
			)}
		</div>
	);
};

ClockinDeviationCell.propTypes = {
	deviation: PropTypes.object,
	row: PropTypes.object,
	editClockedValue: PropTypes.func,
	type: PropTypes.string,
};

export default ClockinDeviationCell;
