export const SET_DATA = 'CONFIRM_MODAL/SET_DATA';
export const TOGGLE_MODAL = 'CONFIRM_MODAL/TOGGLE_MODAL';

export const setData = (payload) => ({
	type: SET_DATA,
	payload,
});

export const toggleModal = () => ({
	type: TOGGLE_MODAL,
});
