import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper, ButtonTile } from 'dumb';
import FormSelectLayoutGroups from './components/formSelectLayoutGroups/formSelectLayoutGroups';
import FormSelectLayouts from './components/formSelectLayouts/formSelectLayouts';
import FormSelectProduct from './components/formtSelectProduct/formtSelectProduct';
import ListSummary from './components/listSummary/listSummary';
import DuplicateLayoutListSummary from './components/duplicateLayoutListSummary/duplicateLayoutListSummary';
import FormAddLayout from './components/formAddLayout/formAddLayout';
import ReorderProducts from './components/reorderProducts/reorderProducts';

// service
import {
	submitTileLayoutTiles,
	fetchTileLayoutTiles,
} from '../../tileLayout.service';

import { getFilter, mapData } from '../../tileLayout.utils';

// lodash
import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';
import _uniqBy from 'lodash/uniqBy';
import _isEmpty from 'lodash/isEmpty';

// utils
import {
	getTileLayoutGroupsIds,
	getReorderProductsMetadata,
	getTileLayoutIds,
} from './utils';

// phrases
import phrases from '../../tileLayout.phrases';

import './modalBatch.css';

class ModalBatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};

		this.modalRef = React.createRef();

		this.handleClose = this.handleClose.bind(this);
		this._submitSelectedLayoutGroups =
			this._submitSelectedLayoutGroups.bind(this);
		this._goToFirstStepAndResetData =
			this._goToFirstStepAndResetData.bind(this);
		this.state = {
			submitLoading: false,
		};
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_buildList() {
		const { batchForm } = this.props;
		let listArray = [];

		// We remove all accidental duplicates
		const uniqTileLayoutGroups = _uniqBy(
			batchForm.tile_layout_groups,
			'value.id'
		);
		const uniqProducts = _uniqBy(batchForm.products, 'value.id');

		listArray = uniqTileLayoutGroups.reduce((acc, x) => {
			const list = uniqProducts.map((y) => {
				return {
					identifier: _uniqueId('row-'), // used to identify what to edit on step#2
					tile_layout_group: x.value,
					type: 'Product',
					product: y.value,
					name: y.label,
					description: '',
					title_color: 'ffffff',
					background_color: '464646',
				};
			});

			return [...acc, ...list];
		}, []);

		// Create magical filter for call to get products that already exist in groups & fetch
		const filter = getFilter(uniqTileLayoutGroups, uniqProducts);
		fetchTileLayoutTiles(filter).then((products) => {
			if (products.length) {
				listArray = mapData(listArray, products);
			}

			this.props.addBatchList(listArray);
			this._goToStep(4);
		});
	}

	_onSubmit() {
		const { batchList, handleClose, resetBatch } = this.props;
		this.setState(() => ({ submitLoading: true }));

		let payload = batchList
			.map((row) => {
				if (row.skip) {
					return null;
				}
				return {
					// used to identify what to edit on step#2
					tile_layout_group: row.tile_layout_group.id,
					type: 'Product',
					product: row.product.id,
					name: row.name,
					description: '',
					title_color: '464646',
					background_color: 'ffffff',
					position: null,
				};
			})
			.filter((x) => x);

		payload = {
			batch: payload,
		};

		submitTileLayoutTiles(payload)
			.then(() => {
				this.setState(() => ({ submitLoading: false }));
				resetBatch();
				handleClose();
			})
			.catch(() => {
				this.setState(() => ({ submitLoading: false }));
			});
	}

	addNewLayout() {
		const { addEntry, resetState, handleClose } = this.props;
		this.setState(() => ({ submitLoading: true }));

		addEntry()
			.then(() => {
				resetState();
				this.setState(() => ({ submitLoading: false }));
				handleClose();
			})
			.catch(() => {
				this.setState(() => ({ submitLoading: false }));
			});
	}

	handleClose() {
		const { resetState, handleClose } = this.props;
		resetState();
		handleClose();
	}

	_goToFirstStepAndResetData() {
		this.props.resetState();
		this._goToStep(1);
	}

	_submitSelectedLayoutGroups() {
		const {
			batchForm,
			updateBatchForm,
			fetchTileLayoutTiles,
			fetchProductProducts,
			fetchAssets,
		} = this.props;

		this.setState(() => ({ loadingModal: true }));

		// get tile layout tiles for all selected groups
		const ids = getTileLayoutGroupsIds(batchForm.tile_layout_groups);
		const filter = `:tile_layout_group=IN=${ids}`;
		const layoutIds = getTileLayoutIds({
			tileLayoutGroups: batchForm.tile_layout_groups,
		});

		Promise.all([
			fetchTileLayoutTiles(filter),
			fetchProductProducts({}),
			fetchAssets({ layoutIds }),
		])
			.then((res) => {
				const tileLayoutTiles = res[0];
				const products = res[1];
				const assets = res[2];

				const { uniqueLayouts, uniqueTileLayoutGroups, uniqueTileLayoutTiles } =
					getReorderProductsMetadata({ batchForm, tileLayoutTiles });

				updateBatchForm({
					assets,
					products: products.data,
					tileLayoutTiles,
					uniqueTileLayoutGroups,
					uniqueTileLayoutTiles,
					uniqueLayouts,
					shownTileLayoutTiles: [],
				});
				this.setState(() => ({ loadingModal: false }));
				this._goToStep(9);
			})
			.catch(() => {
				this.setState(() => ({ loadingModal: false }));
			});
	}

	render() {
		const {
			modalVisible,
			batchForm,
			updateBatchForm,
			listLayoutGroupsData,
			listProductsData,
			batchList,
			editBatchListItem,
			removeBatchListItem,
			defaultValues,
			removeDuplicateLayoutListItem,
			editDuplicateLayoutListItem,
			duplicateTileLayout,
			updatePosListRow,
			selectedLayouts,
			updateTileLayoutTilePosition,
			submitPositionRotations,
			markGroupAsSubmitted,
		} = this.props;

		const isValidLayoutGroups = _get(batchForm, 'tile_layout_groups', []);
		const isValidProducts = _get(batchForm, 'products', []);

		const steps = [
			{
				component: (
					<div className="edit-modal-choice__content">
						<ButtonTile
							icon="add"
							type="inverted"
							shadow
							onClick={() => this._goToStep(5)}
						>
							{phrases.NEW_LAYOUT}
						</ButtonTile>
						<ButtonTile
							icon="add"
							type="inverted"
							shadow
							onClick={() => this._goToStep(2)}
						>
							{phrases.INSERT_PRODUCTS}
						</ButtonTile>
						<ButtonTile
							icon="content_copy"
							type="inverted"
							shadow
							onClick={() => this._goToStep(6)}
						>
							{phrases.DUPLICATE_LAYOUT}
						</ButtonTile>
						<ButtonTile
							icon="swap_vert"
							type="inverted"
							shadow
							onClick={() => this._goToStep(8)}
						>
							{phrases.REORDER_PRODUCTS}
						</ButtonTile>
					</div>
				),
				title: 'POS Layout multi add',
				customFooter: <></>,
				loading: false,
				allowOverflow: true,
			},
			{
				component: (
					<FormSelectLayoutGroups
						batchForm={batchForm}
						updateBatchForm={updateBatchForm}
						listLayoutGroupsData={listLayoutGroupsData}
					/>
				),
				title: 'Select group layouts',
				onBack: this._goToFirstStepAndResetData,
				isValid: !!isValidLayoutGroups.length,
				onNext: () => this._goToStep(3),
				loading: false,
				confirmButtonLabel: 'next',
				cancelButtonLabel: 'back',
				noScroll: false,
				allowOverflow: true,
			},
			{
				component: (
					<FormSelectProduct
						batchForm={batchForm}
						updateBatchForm={updateBatchForm}
						listData={listProductsData}
					/>
				),
				title: 'Select products',
				onBack: () => this._goToStep(2),
				isValid: !!isValidProducts.length,
				onNext: () => this._buildList(4),
				loading: false,
				confirmButtonLabel: 'next',
				cancelButtonLabel: 'back',
				noScroll: false,
				allowOverflow: true,
			},
			{
				component: (
					<ListSummary
						batchList={batchList}
						removeBatchListItem={removeBatchListItem}
						editBatchListItem={editBatchListItem}
					/>
				),
				title: 'Review list',
				onBack: () => this._goToStep(3),
				isValid: true,
				onNext: () => this._onSubmit(),
				loading: this.state.submitLoading,
				confirmButtonLabel: 'submit',
				cancelButtonLabel: 'back',
				noScroll: false,
				defaultStyles: false,
			},
			{
				component: (
					<FormAddLayout
						defaultValues={defaultValues}
						updatePosListRow={updatePosListRow}
					/>
				),
				title: 'Add new layout',
				onBack: () => this._goToStep(1),
				isValid: true,
				onNext: () => this.addNewLayout(),
				loading: this.state.submitLoading,
				confirmButtonLabel: 'Submit layout',
				cancelButtonLabel: 'Back',
				noScroll: false,
				allowOverflow: true,
			},

			{
				component: (
					<FormSelectLayouts
						batchForm={batchForm}
						updateBatchForm={updateBatchForm}
					/>
				),
				title: 'Select layouts',
				onBack: () => this._goToStep(1),
				isValid: !_isEmpty(batchForm.tile_layouts),
				onNext: () => this._goToStep(7),
				loading: false,
				confirmButtonLabel: 'next',
				cancelButtonLabel: 'back',
				noScroll: false,
				allowOverflow: true,
			},
			{
				component: (
					<DuplicateLayoutListSummary
						tileLayouts={batchForm.tile_layouts}
						removeDuplicateLayoutListItem={removeDuplicateLayoutListItem}
						editDuplicateLayoutListItem={editDuplicateLayoutListItem}
						duplicateTileLayout={duplicateTileLayout}
						editBatchListItem={editBatchListItem}
					/>
				),
				title: 'Confirm layouts to duplicate',
				onBack: () => this._goToStep(1),
				isValid: true,
				onNext: () => this.handleClose(),
				loading: false,
				confirmButtonLabel: 'Close',
				cancelButtonLabel: 'back',
				defaultStyles: false,
			},
			{
				component: (
					<FormSelectLayoutGroups
						batchForm={batchForm}
						updateBatchForm={updateBatchForm}
						listLayoutGroupsData={listLayoutGroupsData}
						tip={phrases.REORDER_PRODUCTS_SELECT_GROUPS_TIP}
						reorderProductsMode
					/>
				),
				title: phrases.REORDER_PRODUCTS_SELECT_GROUPS_TITLE,
				onBack: this._goToFirstStepAndResetData,
				isValid: !!isValidLayoutGroups.length,
				onNext: this._submitSelectedLayoutGroups,
				loading: this.state.loadingModal,
				confirmButtonLabel: 'next',
				cancelButtonLabel: 'back',
				noScroll: false,
				allowOverflow: true,
			},
			{
				component: (
					<ReorderProducts
						batchForm={batchForm}
						updateBatchForm={updateBatchForm}
						listLayoutGroupsData={listLayoutGroupsData}
						tip={phrases.REORDER_PRODUCTS_SELECT_GROUPS_TIP}
						selectedLayouts={selectedLayouts}
						updateTileLayoutTilePosition={updateTileLayoutTilePosition}
						submitPositionRotations={submitPositionRotations}
						markGroupAsSubmitted={markGroupAsSubmitted}
						setLoading={(loading) =>
							this.setState(() => ({ loadingModal: loading }))
						}
					/>
				),
				title: phrases.REORDER_PRODUCTS,
				onBack: () => this._goToStep(8),
				isValid: true,
				onNext: this.handleClose,
				loading: this.state.loadingModal,
				confirmButtonLabel: 'close',
				cancelButtonLabel: 'back',
				noScroll: false,
				allowOverflow: true,
			},
		];

		return (
			modalVisible && (
				<ModalStepper
					className="modal-batch"
					ref={this.modalRef}
					isOpen={modalVisible}
					steps={steps}
					showStep={false}
					onClose={this.handleClose}
				/>
			)
		);
	}
}

ModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	updateBatchForm: PropTypes.func,
	handleClose: PropTypes.func,
	listLayoutGroupsData: PropTypes.object,
	listProductsData: PropTypes.object,

	batchList: PropTypes.array,
	batchForm: PropTypes.object,
	addBatchList: PropTypes.func,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	resetBatch: PropTypes.func,
	resetState: PropTypes.func,
	addEntry: PropTypes.func,
	defaultValues: PropTypes.object,
	updatePosListRow: PropTypes.func,
	removeDuplicateLayoutListItem: PropTypes.func,
	editDuplicateLayoutListItem: PropTypes.func,
	duplicateTileLayout: PropTypes.func,
	selectedLayouts: PropTypes.array,
	fetchProductProducts: PropTypes.func,
	fetchTileLayoutTiles: PropTypes.func,
	fetchAssets: PropTypes.func,
	updateTileLayoutTilePosition: PropTypes.func,
	submitPositionRotations: PropTypes.func,
	markGroupAsSubmitted: PropTypes.func,
};

ModalBatch.defaultProps = {
	modalVisible: false,
	headers: [],
	editableCells: [],
	loading: false,
};

export default ModalBatch;
