'use strict';

import { ReportFactory as R } from 'report/factory';

const t = R.table();

const AuditSalaryPayrollReportWorkplaceTable = t.table([
	t.column(
		'Person',
		t.contentWrapper({
			body: [t.content((d) => R.data(d, 'employment.person.full_name'))],
			// body: [t.content(d => R.data(d, 'employment.person.identity.full_name'))],
			foot: [t.content('Total')],
		}),
		300
	),
	t.column(
		'Hours',
		[t.content((d) => R.data(d, 'total_hours'), 'numberSingleDecimal')],
		200,
		'number'
	),
	t.column(
		'Total',
		[t.content((d) => R.data(d, 'total_pay'), 'currency')],
		200,
		'currency'
	),
]);

AuditSalaryPayrollReportWorkplaceTable.setTotal(false);

export default AuditSalaryPayrollReportWorkplaceTable;
