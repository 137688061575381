import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ className, children }) => {
	return <tfoot className={className}>{children}</tfoot>;
};

Footer.defaultProps = {
	className: null
};

Footer.propTypes = {
	className: PropTypes.object,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired
};

export default Footer;
