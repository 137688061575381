import React, { Suspense } from 'react';

import { childRoutes } from '../../modules/structure/salesConfiguration/salesConfiguration.childRoutes';

const SalesConfiguration = React.lazy(() =>
	import(
		/* webpackChunkName: "salesConfiguration" */ 'structure/salesConfiguration/salesConfiguration.container'
	)
);

export const salesConfigurationRoutes = () => [
	{
		element: (
			<Suspense fallback={'Loading'}>
				<SalesConfiguration />
			</Suspense>
		),
		path: 'sales-configuration',
		title: 'Sales configuration',
		icon: require('@mui/icons-material/CategoryOutlined').default,
		allowedAccessControlLevels: [
			'Sales Configuration',
			'Sales Configuration View',
		],
		children: childRoutes(),
	},
];
