import { get, post } from 'api.vanilla.service';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { formatErrorMessage } from 'api/helpers';

import phrases from './shiftTransferApproval.phrases';
import moment from 'moment';
import constants from 'services/constants';
import { ShiftTransfer as enums } from 'services/enums';

// utils
import { sortShiftTransfers, sortTransfersChronologically } from './utils';

// redux actions/dispatchers
import {
	dispatchSetShiftTransferModalData,
	dispatchSetPendingShiftTransfers,
	dispatchSetShiftTransfers,
} from './store/shiftTransferApproval.actions';

// utils to notify
import { conditionallyUpdateShifts as conditionallyUpdateShiftsMyShiftplanner } from 'modules/structure/myShiftplanner/utilities/myShiftplannerShift.utilities';
import { conditionallyUpdateShifts as conditionallyUpdateShiftsShiftplanner } from 'shiftPlanner/utilities/conditionallyUpdateShifts';

// lodash
import _isEmpty from 'lodash/isEmpty';

// Abort controller
let abortController = new AbortController();
let signal = abortController.signal;

/**
 * @function fetchShiftTransfers
 * @param {object}
 */
export function fetchShiftTransfers({ userId, fetchPending = false }) {
	return get(
		`/shiftplanning/persons/${userId}/pending_applications_overview`,
		null,
		null,
		null,
		signal
	)
		.then((res) => {
			if (!_isEmpty(res.data)) {
				const { sortedSwaps, sortedTransfers, sortedPendingTransfers } =
					sortShiftTransfers({
						shiftTransfers: res.data[0].shift_transfers,
						swapTransfers: res.data[0].swap_transfers,
						pendingTransfers: res.data[0].pending_shift_transfers,
						userId,
					});

				if (fetchPending) {
					return sortedPendingTransfers;
				}

				dispatchSetShiftTransferModalData({
					shiftTransfers: sortedTransfers,
					swapTransfers: sortedSwaps,
					pendingTransfers: sortedPendingTransfers,
				});
			}

			return res;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
			throw err;
		});
}

/**
 * @function fetchPendingShiftTransfers
 * @param {Object} userId - user to get shift transfes for
 * @description fetches all shift transfes and only sets pending ones - called whenever we want to see pending shift transfers modal
 * @returns {Promise}
 */
export function fetchPendingShiftTransfers({ userId }) {
	return fetchShiftTransfers({ userId, fetchPending: true })
		.then((pendingTransfers) => {
			dispatchSetPendingShiftTransfers(pendingTransfers);
			return pendingTransfers;
		})
		.catch((err) => {
			throw err;
		});
}

/**
 * @function fetchWorkplaceShiftTransfers
 * @param {Object} workplaceId - id of the workplace we want to get pending transfers for
 * @description used in shiftplanner and called whenever we change a workplace we're viewing
 * @returns {Promise}
 */
export function fetchWorkplaceShiftTransfers({ workplaceId }) {
	return get(
		`/shiftplanning/workplaces/${workplaceId}/shift_transfers_pending_manager_approval`
	)
		.then((res) => {
			const shiftTransfers = res.data;

			const sortedTransfers = sortTransfersChronologically(shiftTransfers);

			dispatchSetShiftTransfers(sortedTransfers);

			return sortedTransfers;
		})
		.catch((err) => {
			throw err;
		});
}

/**
 * @function getShiftTransferPolicies
 * @param {object}
 */
export function getShiftTransferPolicies({ marketId }) {
	const now = moment.utc().format(constants.shortDate);
	const filter = `:market.id=='${marketId}';:period.from=le='${now}';:period.to=ge='${now}';:type=='${enums.POLICIES.ACCEPT_SWAP}'`;

	const params = {
		filter,
	};

	return get(`/shiftplanning/shift_transfer_policies`, params)
		.then((res) => res)
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
			throw err;
		});
}

/**
 * @function fetchPotentialWarnings
 * @param {object}
 */
export function fetchPotentialWarnings(data) {
	const params = {
		from: data.from,
		to: data.to,
		workplace: data.workplaceId,
	};

	return get(
		`/shiftplanning/employments/${data.employmentId}/potential_warnings`,
		params
	)
		.then((res) => res)
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
			throw err;
		});
}

/**
 * @function editShiftTransfer
 * @param {object}
 */
export function editShiftTransfer({ data, approvingApplication }) {
	return post(
		`/shiftplanning/shifts/${data.shiftId}/transfers/${data.transferId}`,
		data
	)
		.then((res) => {
			const feedbackText = approvingApplication
				? phrases.SHIFT_TRANSFER_APPROVED
				: phrases.SHIFT_TRANSFER_DECLINED;

			setFeedback(feedbackText, 1);

			// notify shiftplanner and myshiftplanner that a shift got changed
			const shiftToUpdate = {
				...res.data[0].shift,
				last_shift_transfer: { state: res.data[0].state },
			};

			conditionallyUpdateShiftsMyShiftplanner({ shiftToUpdate });
			conditionallyUpdateShiftsShiftplanner({ shiftToUpdate });

			return res;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
			throw err;
		});
}

/**
 * @function editShiftTransferApplication
 * @param {Object} data - data to edit a transfer application
 */
export function editShiftTransferApplication(data) {
	return post(
		`/shiftplanning/shifts/${data.shiftId}/transfers/${data.transferId}/applications/${data.applicationId}`,
		data
	)
		.then((res) => {
			const feedbackText =
				data.operation === enums.OPERATIONS.ACCEPT
					? phrases.SHIFT_ACCEPTED
					: phrases.SHIFT_DECLINED;

			setFeedback(feedbackText, 1);
			return res;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
			throw err;
		});
}

// ! not in use for now
/**
 * @function postOvertimeAcknowledgements
 * @param {object} data object with TYPE and SHIFT_EMPOYEE id
 */
export function postOvertimeAcknowledgements(data) {
	return post('/shiftplanning/overtime_acknowledgements', data).catch(
		(error) => {
			const errorMsg = formatErrorMessage(error);
			setFeedback(errorMsg, 0);
			throw error;
		}
	);
}

/**
 * @function
 * @public
 * @description Aborts an existing call to get shift transfers
 */
export function abortFetchingShiftTransfers() {
	abortController.abort();

	abortController = new AbortController();
	signal = abortController.signal;
}
