'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { InputCollectionSelect, Input, Button, Icon } from 'dumb';

import { ReactDataWrapper } from 'reactDataWrapper';
import ProductVariantIngredientAlternativesModalBatch from './components/batch/productVariantIngredientAlternativesModalBatch';

import {
	fetchProductVariantIngredientAlternatives,
	addProductVariantIngredientAlternatives,
	deleteProductVariantIngredientAlternatives,
	editProductVariantIngredientAlternative,
	editProductVariantIngredientAlternatives,
} from './productVariantIngredientAlternatives.service';

import {
	setProductVariantIngredientAlternative,
	updateProductVariantIngredientAlternative,
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	resetState,
} from './store/productVariantIngredientAlternatives.actions';

// phrases/constants/moment
import phrases from './productVariantIngredientAlternatives.phrases';
import constants from 'services/constants';
import moment from 'moment';
import enums from './productVariantIngredientAlternatives.enums';
import { getEditedValues } from 'services/utils';

// lodash
import _get from 'lodash/get';

const reduxKey = '/product/product_variant_ingredient_alternatives';
class ProductVariantIngredientAlternatives extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showAddModal: false,
			key: moment().format(constants.dateFormat),
			marketFilter: `:closed=ge='${moment.utc().format(constants.shortDate)}'`,
		};

		this.fetchData = this.fetchData.bind(this);
		this.toggleAddModal = this.toggleAddModal.bind(this);
		this.batchAddData = this.batchAddData.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.getEditableCells = this.getEditableCells.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editMultiple = this.editMultiple.bind(this);

		this.columns = [
			...(props.productVariantIngredient
				? []
				: [
						{
							Header: 'Product variant ingredient',
							id: 'product-variant-ingredient',
							accessor: (d) =>
								_get(d, 'product_variant_ingredient.ingredient.name', ''),
							filterPath: ':product_variant_ingredient.ingredient.name',
						},
				  ]),
			{
				Header: 'Product variant',
				id: 'product-variant',
				accessor: (d) =>
					_get(d, 'product_variant_ingredient.product_variant.name', ''),
				filterPath: ':product_variant_ingredient.product_variant.name',
			},
			{
				Header: 'Product variant group',
				id: 'product-variant-group',
				accessor: (d) => _get(d, 'product_variant_group.name', ''),
				filterPath: ':product_variant_group.name',
			},
			{
				Header: 'Ingredient',
				id: 'ingredient',
				accessor: (d) => _get(d, 'ingredient.name', ''),
				filterPath: ':ingredient.name',
			},
			{
				Header: 'Type',
				id: 'type',
				accessor: 'type',
				filterPath: ':type',
			},
			{
				Header: 'Sort order',
				id: 'sort-order',
				accessor: 'sort_order',
				filterPath: ':sort_order',
			},
		];
	}

	editEntry() {
		const {
			productVariantIngredientAlternative,
			defaultProductVariantIngredientAlternative,
		} = this.props;

		const editedValues = getEditedValues({
			newData: productVariantIngredientAlternative,
			oldData: defaultProductVariantIngredientAlternative,
		});

		const payload = {
			id: productVariantIngredientAlternative.id,
			sort_order: editedValues.sortOrder ?? undefined,
			...(productVariantIngredientAlternative.type ===
				enums.ALTERNATIVE_PRODUCTS &&
				editedValues.productVariantGroup && {
					product_variant_group: editedValues.productVariantGroup.id,
				}),
			...(productVariantIngredientAlternative.type ===
				enums.ALTERNATIVE_INGREDIENT &&
				editedValues.ingredient && {
					ingredient: editedValues.ingredient.id,
				}),
		};

		return editProductVariantIngredientAlternative(payload);
	}

	editMultiple(selectedRows) {
		const { productVariantIngredientAlternative } = this.props;

		const payload = {
			...(productVariantIngredientAlternative.sortOrder && {
				sort_order: productVariantIngredientAlternative.sortOrder,
			}),
			...(productVariantIngredientAlternative.productVariantGroup && {
				product_variant_group:
					productVariantIngredientAlternative.productVariantGroup.value?.id,
			}),
			...(productVariantIngredientAlternative.ingredient && {
				ingredient: productVariantIngredientAlternative.ingredient.value?.id,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editProductVariantIngredientAlternatives({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteProductVariantIngredientAlternatives(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			productVariantIngredient:
				data.product_variant_ingredient?.ingredient?.name,
			sortOrder: data.sort_order,
			...(data.type === enums.ALTERNATIVE_PRODUCTS && {
				productVariantGroup: {
					value: data.product_variant_group,
					label: data.product_variant_group.name,
				},
			}),
			...(data.type === enums.ALTERNATIVE_INGREDIENT && {
				ingredient: {
					value: data.ingredient,
					label: data.ingredient.name,
				},
			}),
			type: data.type,
			singleEdit: true,
		};

		this.props.setProductVariantIngredientAlternative(payload);
	}

	getEditableCells() {
		const { productVariantIngredientAlternative, selectedRows } = this.props;

		let showProductVariantGroup = false;
		let showIngredient = false;

		if (selectedRows.length > 1) {
			showProductVariantGroup = selectedRows.every(
				(entry) => entry.type === enums.ALTERNATIVE_PRODUCTS
			);
			showIngredient = selectedRows.every(
				(entry) => entry.type === enums.ALTERNATIVE_INGREDIENT
			);
		} else {
			showProductVariantGroup =
				productVariantIngredientAlternative.type === enums.ALTERNATIVE_PRODUCTS;
			showIngredient =
				productVariantIngredientAlternative.type ===
				enums.ALTERNATIVE_INGREDIENT;
		}

		return [
			{
				header: phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT_INGREDIENT,
				value: (
					<span>
						{productVariantIngredientAlternative.productVariantIngredient}
					</span>
				),
			},
			...(showProductVariantGroup
				? [
						{
							header: phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT_GROUP,
							value: (
								<InputCollectionSelect
									id="product-variant-group"
									placeholder={
										phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT_GROUP_PLACEHOLDER
									}
									value={
										productVariantIngredientAlternative.productVariantGroup
									}
									handleChange={(key, value) =>
										this.editStoreEntry('productVariantGroup', value)
									}
									cache
									clearable={false}
									apiPath="/product/product_variant_groups"
									params={{
										limit: 30,
									}}
									optionFormat={(entry) => ({
										value: entry,
										label: entry.name,
									})}
									inputFilterFormat={(input) => `:name=like='%${input}%'`}
									tableReduxKey="productVariantInredientAlternatives-/product/product_variant_groups"
									tableTitle={phrases.PRODUCT_VARIANT_GROUPS_TITLE}
									tableColumns={[
										{
											Header: 'Name',
											id: 'name',
											accessor: (d) => _get(d, 'name', ''),
											filterPath: ':name',
										},
										{
											Header: 'Description',
											id: 'desc',
											accessor: (d) => _get(d, 'description', ''),
											filterPath: ':description',
										},
										{
											Header: 'Sort order',
											id: 'sortOrder',
											accessor: (d) => _get(d, 'sort_order', ''),
											filterPath: ':sort_order',
										},
										{
											Header: 'Legacy',
											id: 'legacy',
											accessor: 'legacy',
											filterPath: ':legacy',
											filterable: false,
											Cell: (d) => {
												return (
													<Input
														id="checkbox"
														type="checkbox"
														checked={d.original.legacy}
														disabled
													/>
												);
											},
										},
									]}
								/>
							),
						},
				  ]
				: []),
			...(showIngredient
				? [
						{
							header: phrases.MODAL_BATCH_STEP_INGREDIENT,
							value: (
								<InputCollectionSelect
									id="ingredient"
									placeholder={phrases.MODAL_BATCH_STEP_INGREDIENT_PLACEHOLDER}
									value={productVariantIngredientAlternative.ingredient}
									handleChange={(key, value) =>
										this.editStoreEntry('ingredient', value)
									}
									cache
									apiPath="/product/ingredients"
									params={{
										limit: 30,
									}}
									clearable={false}
									optionFormat={(entry) => ({
										value: entry,
										label: entry.name,
									})}
									inputFilterFormat={(input) => `:name=like='%${input}%'`}
									tableReduxKey="productVariantInredientAlternatives-/product/ingredients"
									tableTitle={phrases.INGREDIENTS_TABLE_TITLE}
									tableColumns={[
										{
											Header: 'Name',
											id: 'name',
											accessor: (d) => _get(d, 'name', ''),
											filterPath: ':name',
										},
										{
											Header: 'Ingredient category',
											id: 'ingredient_category',
											accessor: (d) => _get(d, 'ingredient_category.name', ''),
											filterPath: ':ingredient_category.name',
										},
										{
											Header: 'Fuel',
											id: 'fuel',
											accessor: (d) => _get(d, 'fuel', ''),
											filterPath: ':fuel',
										},
										{
											Header: 'Asset Collection',
											id: 'asset_collection',
											accessor: (d) => _get(d, 'asset_collection.name', null),
											filterPath: ':asset_collection.name',
										},
										{
											Header: 'Ingredient Pricing Group',
											id: 'ingredient_pricing_group',
											accessor: (d) =>
												_get(d, 'ingredient_pricing_group.name', null),
											filterPath: ':ingredient_pricing_group.name',
										},
										{
											Header: 'List name',
											id: 'list_name',
											accessor: 'list_name',
											filterPath: ':list_name',
										},
										{
											Header: 'Operation type',
											id: 'operation_type',
											accessor: 'operation_type',
											filterPath: ':operation_type',
										},
										{
											Header: 'Availability administration',
											id: 'availabilityAdministration',
											accessor: 'availability_administration',
											filterPath: ':availability_administration',
											filterable: false,
											Cell: (d) => {
												return (
													<Input
														type="checkbox"
														checked={_get(
															d,
															'original.availability_administration',
															false
														)}
														disabled
													/>
												);
											},
										},
										{
											Header: 'Legacy',
											id: 'legacy',
											accessor: (d) => _get(d, 'legacy', ''),
											filterPath: ':legacy',
											filterable: false,
											Cell: (d) => {
												return (
													<Input
														type="checkbox"
														checked={_get(d, 'original.legacy', false)}
														disabled
													/>
												);
											},
										},
									]}
								/>
							),
						},
				  ]
				: []),
			{
				header: phrases.MODAL_BATCH_STEP_SORT_ORDER,
				value: (
					<Input
						id="sort-order"
						placeholder={phrases.MODAL_BATCH_STEP_SORT_ORDER_PLACEHOLDER}
						type="number"
						value={productVariantIngredientAlternative.sortOrder}
						onChange={(e) => this.editStoreEntry('sortOrder', e.target.value)}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const value = e?.target?.value ?? e;

		const updateObject = {
			[name]: value,
		};

		this.props.updateProductVariantIngredientAlternative(updateObject);
	}

	toggleAddModal() {
		const {
			resetState,
			updateBatchForm,
			productVariantIngredient,
		} = this.props;

		if (!this.state.showAddModal && productVariantIngredient) {
			updateBatchForm({
				productVariantIngredient: [
					{
						value: productVariantIngredient,
						label: `${productVariantIngredient?.product_variant?.name} - ${productVariantIngredient?.ingredient?.name}`,
					},
				],
				productVariantIngredientDisabled: true,
			});
		} else {
			resetState();
		}

		this.setState((prevState) => ({ showAddModal: !prevState.showAddModal }));
	}

	getActionButtons() {
		return (
			<Button type="inverted" label="Add" shadow onClick={this.toggleAddModal}>
				<Icon name="add" />
			</Button>
		);
	}

	fetchData(state) {
		const { productVariantIngredient } = this.props;

		let filter;
		if (productVariantIngredient)
			filter = `:product_variant_ingredient.id=='${productVariantIngredient.id}'`;

		return fetchProductVariantIngredientAlternatives(state, filter);
	}

	batchAddData(data) {
		return addProductVariantIngredientAlternatives(data)
			.then(() => {
				this.setState(() => ({ key: moment().format(constants.dateFormat) }));
			})
			.catch((err) => {
				throw err;
			});
	}

	render() {
		const {
			batchFormData,
			batchListData,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
			updateBatchForm,
			addBatchList,
			productVariantIngredient,
		} = this.props;

		return (
			<>
				<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
					key={this.state.key}
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={
						productVariantIngredient
							? `${reduxKey}-${productVariantIngredient.id}`
							: reduxKey
					}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					deleteEntry={this.deleteEntry}
					editMultiple={this.editMultiple}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					actionRender={this.getActionButtons()}
				/>

				<ProductVariantIngredientAlternativesModalBatch
					modalVisible={this.state.showAddModal}
					handleClose={this.toggleAddModal}
					batchFormData={batchFormData}
					batchListData={batchListData}
					addBatchList={addBatchList}
					updateBatchForm={updateBatchForm}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					batchAddData={this.batchAddData}
				/>
			</>
		);
	}
}

ProductVariantIngredientAlternatives.propTypes = {
	resetState: PropTypes.func,
	batchListData: PropTypes.array,
	batchFormData: PropTypes.object,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	updateBatchForm: PropTypes.func,
	addBatchList: PropTypes.func,
	setProductVariantIngredientAlternative: PropTypes.func,
	updateProductVariantIngredientAlternative: PropTypes.func,
	defaultProductVariantIngredientAlternative: PropTypes.object,
	productVariantIngredientAlternative: PropTypes.object,
	productVariantIngredient: PropTypes.object,
	selectedRows: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			resetState,
			setProductVariantIngredientAlternative,
			updateProductVariantIngredientAlternative,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		batchListData:
			store.productVariantIngredientAlternatives.data.batchListData,
		batchFormData:
			store.productVariantIngredientAlternatives.data.batchFormData,

		productVariantIngredientAlternative:
			store.productVariantIngredientAlternatives.data
				.productVariantIngredientAlternative,
		defaultProductVariantIngredientAlternative:
			store.productVariantIngredientAlternatives.data
				.defaultProductVariantIngredientAlternative,
		selectedRows: store.listData?.[reduxKey]?.ui?.selectedRows ?? [],
	};
};

export default connectWithStore(
	ProductVariantIngredientAlternatives,
	mapStateToProps,
	mapDispatchToProps
);
