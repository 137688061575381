'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import { batch } from 'react-redux';

import {
	// batch stuff
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setPosConfigurationVersion,
	updatePosConfigurationVersion,
	resetState,
	editBatchListItems,
	addBatchListDuplicate,
	revertBatchListItem,
	setBatchListItemLoading,
} from './store/posConfigurationVersions.actions';

// services
import {
	fetchPosConfigurationVersions,
	fetchPosConfigurations,
	editPosConfigurationVersion,
	editBatchPosConfigurationVersions,
	addPosConfigurationVersion,
	deletePosConfigurationVersion,
	duplicatePosConfigurationVersion,
} from './posConfigurationVersions.service';

// table components
import { ReactDataWrapper } from 'reactDataWrapper';
import getColumns from 'reactDataWrapperColumns/pos/posConfigurationVersions.columns';

import {
	Tooltip,
	Input,
	InputCollectionSelect,
	Button,
	Icon,
	SingleDatePickerInput,
} from 'dumb';

import PosConfigurationVersionSubTables from './components/subTables/subTables';
import PosConfigurationVersionsModalBatchAdd from './components/batchAdd/posConfigurationVersionsModalBatchAdd';
import PosConfigurationVersionsModalBatchDuplicateAsNew from './components/batchDuplicateAsNew/posConfigurationVersionsModalBatchDuplicateAsNew';

// lodash
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

// phrases/constants
import phrases from './posConfigurationVersions.phrases';
import constants from 'services/constants';
import moment from 'moment';

const reduxKey = '/pos/pos_configuration_versions';

class PosConfigurationVersions extends Component {
	constructor(props) {
		super(props);

		this.today = moment.utc().format(constants.shortDate);

		this.state = {
			key: moment(),
			showAddModal: false,
			showEditModal: false,
			tableFilterActive: this.today,
			tableFilterLegacy: `:pos_configuration.legacy==false`,
			tableFilterMarket: null,
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleAddModal = this.toggleAddModal.bind(this);
		this._getSelectedDate = this._getSelectedDate.bind(this);
		this.getAdditionalTableFilters = this.getAdditionalTableFilters.bind(this);
		this._onDateFilterChange = this._onDateFilterChange.bind(this);
		this.getTableFilters = this.getTableFilters.bind(this);
		this.toggletableFilterLegacy = this.toggletableFilterLegacy.bind(this);
		// ! part of the overwrite/duplicate
		this.toggleEditModal = this.toggleEditModal.bind(this);

		this.columns = getColumns(
			this.props.batchSelection ? this.props.reduxKey : reduxKey
		);
	}

	editEntry() {
		const { defaultPosConfigurationVersion } = this.props;

		const payload = {
			id: defaultPosConfigurationVersion.id,
			name: defaultPosConfigurationVersion.name,
			description: defaultPosConfigurationVersion.description,
			state: defaultPosConfigurationVersion.state,
			active: {
				from: defaultPosConfigurationVersion.activeFrom
					? moment
							.utc(defaultPosConfigurationVersion.activeFrom)
							.format(constants.shortDate)
					: moment.utc().format(constants.shortDate),
				to: defaultPosConfigurationVersion.activeTo
					? moment
							.utc(defaultPosConfigurationVersion.activeTo)
							.format(constants.shortDate)
					: '9999-12-31',
			},
			pos_tile_layout: _get(
				defaultPosConfigurationVersion,
				'posTileLayout.value.id',
				null
			),
			app_tile_layout: _get(
				defaultPosConfigurationVersion,
				'appTileLayout.value.id',
				null
			),
			delivery_tile_layout: _get(
				defaultPosConfigurationVersion,
				'deliveryTileLayout.value.id',
				null
			),
		};

		return editPosConfigurationVersion(payload);
	}

	editMultiple(selectedRows) {
		const { defaultPosConfigurationVersion } = this.props;

		const payload = {
			...(defaultPosConfigurationVersion.name && {
				name: defaultPosConfigurationVersion.name,
			}),
			...(defaultPosConfigurationVersion.description && {
				description: defaultPosConfigurationVersion.description,
			}),
			...(defaultPosConfigurationVersion.sortOrder && {
				sort_order: defaultPosConfigurationVersion.sortOrder,
			}),
			...(defaultPosConfigurationVersion.appTileLayout?.value && {
				app_tile_layout: defaultPosConfigurationVersion.appTileLayout.value.id,
			}),
			...(defaultPosConfigurationVersion.posTileLayout?.value && {
				pos_tile_layout: defaultPosConfigurationVersion.posTileLayout.value.id,
			}),
			...(defaultPosConfigurationVersion.deliveryTileLayout?.value && {
				delivery_tile_layout:
					defaultPosConfigurationVersion.deliveryTileLayout.value.id,
			}),
			...((defaultPosConfigurationVersion.activeFrom ||
				defaultPosConfigurationVersion.activeTo) && {
				active: {
					...(defaultPosConfigurationVersion.activeFrom && {
						from: defaultPosConfigurationVersion.activeFrom.format(
							constants.shortDate
						),
					}),
					...(defaultPosConfigurationVersion.activeTo && {
						to: defaultPosConfigurationVersion.activeTo.format(
							constants.shortDate
						),
					}),
				},
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editBatchPosConfigurationVersions({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deletePosConfigurationVersion(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			activeFrom: _get(data, 'active.from', null),
			activeTo: _get(data, 'active.to', null),
			state: data.state,
			market: _get(data, 'pos_configuration.market.name', ''),
			posTileLayout: data.pos_tile_layout
				? {
						value: data.pos_tile_layout,
						label: data.pos_tile_layout.name,
				  }
				: null,
			appTileLayout: data.app_tile_layout
				? {
						value: data.app_tile_layout,
						label: data.app_tile_layout.name,
				  }
				: null,
			deliveryTileLayout: data.delivery_tile_layout
				? {
						value: data.delivery_tile_layout,
						label: data.delivery_tile_layout.name,
				  }
				: null,
			posConfiguration: _get(data, 'pos_configuration.name', ''),
			singleEdit: true,
		};

		this.props.setPosConfigurationVersion(payload);
	}

	_getSelectedDate(date, name, singleEditMode) {
		if (date) return moment.utc(date).format(constants.shortDate);

		if (singleEditMode)
			return name === 'from'
				? this.today
				: moment.utc('9999-12-31').format(constants.shortDate);
	}

	getEditableCells() {
		const { defaultPosConfigurationVersion } = this.props;

		const singleEditMode = defaultPosConfigurationVersion.singleEdit;

		return [
			...(singleEditMode
				? [
						{
							header: 'Pos configuration',
							value: (
								<span>{defaultPosConfigurationVersion.posConfiguration}</span>
							),
						},
				  ]
				: []),
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={defaultPosConfigurationVersion.name || ''}
						onChange={(event) => this.editStoreEntry('name', event)}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={defaultPosConfigurationVersion.description || ''}
						onChange={(event) => this.editStoreEntry('description', event)}
					/>
				),
			},
			...(singleEditMode
				? [
						{
							header: 'Market',
							value: <span>{defaultPosConfigurationVersion.market}</span>,
						},
				  ]
				: []),
			{
				header: 'State',
				value: (
					<Input
						id="state"
						placeholder="Enter state..."
						value={defaultPosConfigurationVersion.state || null}
						onChange={(event) => this.editStoreEntry('state', event)}
					/>
				),
			},
			{
				header: 'App tile layout',
				value: (
					<InputCollectionSelect
						id="appTileLayout"
						placeholder="Select App tile layout..."
						value={defaultPosConfigurationVersion.appTileLayout}
						handleChange={(key, value) =>
							this.editStoreEntry('appTileLayout', value)
						}
						cache
						apiPath="/pos/tile_layouts"
						params={{
							limit: 30,
							filter: `:type=='App'`,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						tableTitle="App tile layouts"
						tableReduxKey="/pos/tile_layouts/type=='App'"
						tableColumns={[
							{
								Header: 'Name',
								id: 'name',
								accessor: (d) => _get(d, 'name', ''),
								filterPath: ':name',
							},
							{
								Header: 'Description',
								id: 'description',
								accessor: (d) => _get(d, 'description', ''),
								filterPath: ':description',
							},
							{
								Header: 'Type',
								id: 'type',
								accessor: (d) => _get(d, 'type', null),
								filterPath: ':type',
							},
							{
								Header: 'Rows',
								id: 'rows',
								accessor: (d) => _get(d, 'rows', null),
								filterPath: ':rows',
							},
							{
								Header: 'Columns',
								id: 'columns',
								accessor: (d) => _get(d, 'columns', null),
								filterPath: ':columns',
							},
							{
								Header: 'Sub type',
								id: 'subType',
								accessor: (d) => _get(d, 'sub_type', null),
								filterPath: ':sub_type',
							},
							{
								Header: 'Legacy',
								id: 'legacy',
								accessor: 'legacy',
								filterPath: ':legacy',
								filterable: false,
								Cell: (d) => {
									return (
										<Input
											type="checkbox"
											checked={d.original.legacy}
											disabled
										/>
									);
								},
							},
						]}
					/>
				),
			},
			{
				header: 'Pos tile layout',
				value: (
					<InputCollectionSelect
						id="posTileLayout"
						placeholder="Select Pos tile layout..."
						value={defaultPosConfigurationVersion.posTileLayout}
						handleChange={(key, value) =>
							this.editStoreEntry('posTileLayout', value)
						}
						cache
						apiPath="/pos/tile_layouts"
						params={{
							limit: 30,
							filter: `:type=='POS'`,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						tableTitle="Pos tile layouts"
						tableReduxKey="/pos/tile_layouts/type=='POS'"
						tableColumns={[
							{
								Header: 'Name',
								id: 'name',
								accessor: (d) => _get(d, 'name', ''),
								filterPath: ':name',
							},
							{
								Header: 'Description',
								id: 'description',
								accessor: (d) => _get(d, 'description', ''),
								filterPath: ':description',
							},
							{
								Header: 'Type',
								id: 'type',
								accessor: (d) => _get(d, 'type', null),
								filterPath: ':type',
							},
							{
								Header: 'Rows',
								id: 'rows',
								accessor: (d) => _get(d, 'rows', null),
								filterPath: ':rows',
							},
							{
								Header: 'Columns',
								id: 'columns',
								accessor: (d) => _get(d, 'columns', null),
								filterPath: ':columns',
							},
							{
								Header: 'Sub type',
								id: 'subType',
								accessor: (d) => _get(d, 'sub_type', null),
								filterPath: ':sub_type',
							},
							{
								Header: 'Legacy',
								id: 'legacy',
								accessor: 'legacy',
								filterPath: ':legacy',
								filterable: false,
								Cell: (d) => {
									return (
										<Input
											type="checkbox"
											checked={d.original.legacy}
											disabled
										/>
									);
								},
							},
						]}
					/>
				),
			},
			{
				header: 'Delivery tile layout',
				value: (
					<InputCollectionSelect
						id="deliveryTileLayout"
						placeholder="Select Delivery tile layout..."
						value={defaultPosConfigurationVersion.deliveryTileLayout}
						handleChange={(key, value) =>
							this.editStoreEntry('deliveryTileLayout', value)
						}
						cache
						apiPath="/pos/tile_layouts"
						params={{
							limit: 30,
							filter: `:type=='Delivery'`,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						tableTitle="Delivery tile layouts"
						tableReduxKey="/pos/tile_layouts/type=='Delivery'"
						tableColumns={[
							{
								Header: 'Name',
								id: 'name',
								accessor: (d) => _get(d, 'name', ''),
								filterPath: ':name',
							},
							{
								Header: 'Description',
								id: 'description',
								accessor: (d) => _get(d, 'description', ''),
								filterPath: ':description',
							},
							{
								Header: 'Type',
								id: 'type',
								accessor: (d) => _get(d, 'type', null),
								filterPath: ':type',
							},
							{
								Header: 'Rows',
								id: 'rows',
								accessor: (d) => _get(d, 'rows', null),
								filterPath: ':rows',
							},
							{
								Header: 'Columns',
								id: 'columns',
								accessor: (d) => _get(d, 'columns', null),
								filterPath: ':columns',
							},
							{
								Header: 'Sub type',
								id: 'subType',
								accessor: (d) => _get(d, 'sub_type', null),
								filterPath: ':sub_type',
							},
							{
								Header: 'Legacy',
								id: 'legacy',
								accessor: 'legacy',
								filterPath: ':legacy',
								filterable: false,
								Cell: (d) => {
									return (
										<Input
											type="checkbox"
											checked={d.original.legacy}
											disabled
										/>
									);
								},
							},
						]}
					/>
				),
			},
			{
				header: 'Active from',
				value: (
					<SingleDatePickerInput
						id="activeFrom"
						onChange={(event) => this.editStoreEntry('activeFrom', event)}
						selectedDate={this._getSelectedDate(
							defaultPosConfigurationVersion.activeFrom,
							'from',
							singleEditMode
						)}
						noClockButton
						clearable
					/>
				),
			},
			{
				header: 'Active to',
				value: (
					<SingleDatePickerInput
						id="activeTo"
						onChange={(event) => this.editStoreEntry('activeTo', event)}
						selectedDate={this._getSelectedDate(
							defaultPosConfigurationVersion.activeTo,
							'to',
							singleEditMode
						)}
						noClockButton
						clearable
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		let value;

		if (e === null) value = e;
		else value = e.target ? e.target.value : e;

		const updateObject = {
			[name]: value,
		};

		this.props.updatePosConfigurationVersion(updateObject);
	}

	toggleAddModal() {
		this.setState((prevState) => ({ showAddModal: !prevState.showAddModal }));
		this.props.resetState();
	}

	toggleEditModal() {
		const {
			listPosConfigurationVersions,
			addBatchList,
			addBatchListDuplicate,
		} = this.props;

		if (this.state.showEditModal) this.props.resetState();
		else {
			const selectedRows = _get(
				listPosConfigurationVersions,
				'ui.selectedRows',
				[]
			);

			const listArray = selectedRows.map((entry) => {
				return {
					identifier: entry.id,
					posConfiguration: {
						label: entry.pos_configuration.name,
						value: entry.pos_configuration,
					},
					name: entry.name,
					description: entry.description,
					state: {
						label: entry.state,
						value: entry.state,
					},
					...(entry.app_tile_layout && {
						appTileLayout: {
							value: entry.app_tile_layout,
							label: entry.app_tile_layout.name,
						},
					}),
					...(entry.pos_tile_layout && {
						posTileLayout: {
							value: entry.pos_tile_layout,
							label: entry.pos_tile_layout.name,
						},
					}),
					...(entry.delivery_tile_layout && {
						deliveryTileLayout: {
							value: entry.delivery_tile_layout,
							label: entry.delivery_tile_layout.name,
						},
					}),
					activeFromOld: _get(entry, 'active.from', null),
					activeToOld: _get(entry, 'active.to', null),
					// to disable the revert button
					rowEdited: false,
				};
			});

			batch(() => {
				addBatchList(listArray);
				addBatchListDuplicate(listArray);
			});
		}

		this.setState((prevState) => ({ showEditModal: !prevState.showEditModal }));
	}

	_onDateFilterChange(date) {
		const formattedDate = date
			? moment.utc(date).format(constants.shortDate)
			: '';

		this.setState(() => ({
			tableFilterActive: formattedDate,
		}));
	}

	toggletableFilterLegacy() {
		this.setState((prevState) => ({
			tableFilterLegacy:
				prevState.tableFilterLegacy === ''
					? ':pos_configuration.legacy==false'
					: '',
		}));
	}

	getAdditionalTableFilters() {
		return (
			<div className="pos__pos-configuration-version__table_filter_wrapper">
				<SingleDatePickerInput
					id="posConfigVersionDatePicker"
					label={phrases.TABLE_FILTER_ACTIVE}
					type="single"
					appendToBody
					fixed
					onChange={this._onDateFilterChange}
					selectedDate={this.state.tableFilterActive}
					noClockButton
					clearButtonDisabled={!this.state.tableFilterActive}
					clearable
				/>
				<InputCollectionSelect
					id="posConfigVersionMarketPicker"
					className="pos__pos-configuration-version__table_filter_wrapper__select"
					label={phrases.TABLE_FILTER_MARKET}
					placeholder="Select market..."
					value={this.state.tableFilterMarket}
					handleChange={(key, value) =>
						this.setState(() => ({ tableFilterMarket: value }))
					}
					cache
					apiPath="/organization/markets"
					params={{
						limit: 30,
					}}
					optionFormat={(entry) => ({
						value: entry.id,
						label: entry.name,
					})}
					inputFilterFormat={(input) => `:name=like='%${input}%'`}
				/>
				<Button
					id="tableFilterLegacyFilter"
					className="pos__pos-configuration-version__table_filter_wrapper__button"
					shadow
					onClick={this.toggletableFilterLegacy}
					type={this.state.tableFilterLegacy ? '' : 'inverted'}
				>
					Legacy
				</Button>
			</div>
		);
	}

	getActionButtons() {
		const { listPosConfigurationVersions } = this.props;

		const selectedRows = _get(
			listPosConfigurationVersions,
			'ui.selectedRows',
			[]
		);

		return (
			<>
				<Button
					type="inverted"
					label="Add"
					shadow
					onClick={this.toggleAddModal}
					dataCy="pos-configuration-versions-batch-add-button"
				>
					<Icon name="add" />
				</Button>
				{!_isEmpty(selectedRows) && (
					<Tooltip
						placement="left"
						text={phrases.DUPLICATE_AS_NEW_TOOLTIP}
						renderData={(ref, onMouseEnter, onMouseLeave) => (
							<Button
								type="inverted"
								label={phrases.DUPLICATE_AS_NEW_BUTTON_LABEL}
								shadow
								onClick={this.toggleEditModal}
								refProp={ref}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
							>
								<Icon name="edit" />
							</Button>
						)}
					/>
				)}
			</>
		);
	}

	getTableFilters() {
		const { tableFilterActive, tableFilterMarket, tableFilterLegacy } =
			this.state;

		const dateFilter = tableFilterActive
			? `:active.from=le='${tableFilterActive}';:active.to=ge='${tableFilterActive}'`
			: '';
		const marketFilter = tableFilterMarket
			? `:pos_configuration.market.id=='${tableFilterMarket.value}'`
			: '';

		let filter;
		if (dateFilter) filter = dateFilter;
		if (marketFilter)
			filter = filter ? `${filter};${marketFilter}` : marketFilter;
		if (tableFilterLegacy)
			filter = filter ? `${filter};${tableFilterLegacy}` : tableFilterLegacy;

		return filter;
	}

	render() {
		const {
			batchFormData,
			batchListData,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
			listPosConfigurations,
			listAppTileLayout,
			listPosTileLayout,
			updateBatchForm,
			addBatchList,
			// batch props
			onInitialization,
			reduxKey: batchReduxKey,
			style,
			defaultPageSize,
			batchSelection,
			revertBatchListItem,
			editBatchListItems,
			setBatchListItemLoading,
			batchListItemLoadingId,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					dataCy="pos-configuration-versions-table"
					accessAreasAllowedToEdit={['Sales Configuration']}
					key={this.state.key}
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={fetchPosConfigurationVersions}
					filterable
					defaultPageSize={batchSelection ? defaultPageSize : 50}
					reduxKey={batchSelection ? batchReduxKey : reduxKey}
					style={batchSelection ? style : {}}
					{...(batchSelection && { onInitialization })}
					batchSelection={batchSelection}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					actionRender={this.getActionButtons()}
					customAreaComponents={this.getAdditionalTableFilters()}
					extraFilters={this.getTableFilters()}
					subcomponent={(row) => <PosConfigurationVersionSubTables row={row} />}
				/>

				<PosConfigurationVersionsModalBatchAdd
					modalVisible={this.state.showAddModal}
					handleClose={this.toggleAddModal}
					batchFormData={batchFormData}
					batchListData={batchListData}
					addBatchList={addBatchList}
					updateBatchForm={updateBatchForm}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					batchAddData={addPosConfigurationVersion}
					fetchPosConfigurations={fetchPosConfigurations}
					listPosConfigurations={listPosConfigurations}
				/>

				<PosConfigurationVersionsModalBatchDuplicateAsNew
					modalVisible={this.state.showEditModal}
					handleClose={this.toggleEditModal}
					batchFormData={batchFormData}
					batchListData={batchListData}
					addBatchList={addBatchList}
					updateBatchForm={updateBatchForm}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					batchAddData={duplicatePosConfigurationVersion}
					editBatchListItems={editBatchListItems}
					revertBatchListItem={revertBatchListItem}
					listPosConfigurations={listPosConfigurations}
					listAppTileLayout={listAppTileLayout}
					listPosTileLayout={listPosTileLayout}
					setBatchListItemLoading={setBatchListItemLoading}
					batchListItemLoadingId={batchListItemLoadingId}
				/>
			</>
		);
	}
}

PosConfigurationVersions.propTypes = {
	defaultPosConfigurationVersion: PropTypes.object,
	updatePosConfigurationVersion: PropTypes.func,
	setPosConfigurationVersion: PropTypes.func,
	resetState: PropTypes.func,
	batchListData: PropTypes.array,
	batchFormData: PropTypes.object,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	listPosConfigurations: PropTypes.object,
	listPosConfigurationVersions: PropTypes.object,
	updateBatchForm: PropTypes.func,
	addBatchList: PropTypes.func,
	onInitialization: PropTypes.func,
	reduxKey: PropTypes.string,
	style: PropTypes.object,
	defaultPageSize: PropTypes.number,
	batchSelection: PropTypes.bool,
	editBatchListItems: PropTypes.func,
	addBatchListDuplicate: PropTypes.func,
	revertBatchListItem: PropTypes.func,
	setBatchListItemLoading: PropTypes.func,
	listAppTileLayout: PropTypes.object,
	listPosTileLayout: PropTypes.object,
	batchListItemLoadingId: PropTypes.number,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			updatePosConfigurationVersion,
			setPosConfigurationVersion,
			resetState,
			addBatchListItemRow,
			editBatchListItems,
			addBatchListDuplicate,
			revertBatchListItem,
			setBatchListItemLoading,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultPosConfigurationVersion:
			store.salesConfigurationPosConfigurationVersions.data
				.defaultPosConfigurationVersion,
		batchListData:
			store.salesConfigurationPosConfigurationVersions.data.batchListData,
		batchFormData:
			store.salesConfigurationPosConfigurationVersions.data.batchFormData,
		batchListItemLoadingId:
			store.salesConfigurationPosConfigurationVersions.data
				.batchListItemLoadingId,
		listPosConfigurations:
			store.listData['posConfigurationVersionBatch/pos/pos_configurations'],
		listPosTileLayout:
			store.listData['posConfigurationVersionBatch-pos/pos/tile_layouts'],
		listAppTileLayout:
			store.listData['posConfigurationVersionBatch-app/pos/tile_layouts'],
		listPosConfigurationVersions: store.listData[reduxKey],
	};
};

export default connectWithStore(
	PosConfigurationVersions,
	mapStateToProps,
	mapDispatchToProps
);
