export default {
	TABLE_TITLE: 'Dynamic accounts',
	DYNAMIC_ACCOUNT_ADDED: 'Dynamic account added',
	DYNAMIC_ACCOUNT_EDITED: 'Dynamic account edited',
	DYNAMIC_ACCOUNTS_EDITED: 'Dynamic accounts edited',
	DYNAMIC_ACCOUNTS_DELETED: 'Dynamic account deleted',

	// batch
	MODAL_TITLE: 'Add dynamic accounts',

	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	ACCOUNT_NUMBER: 'Account number',
	TYPE: 'Type',
	ENTRY_TYPE: 'Entry type',
	ADD_ROW_TOOLTIP: 'Click to add row',
	BATCH_ADD: 'Add multiple',

	SUBMIT: 'Confirm',
};
