'use strict';

export const SET_ACTIVE_TILE = 'POS_CONFIGURATIONS/SET_ACTIVE_TILE';
export const SET_VARIANTS_LIST = 'POS_CONFIGURATIONS/SET_VARIANTS_LIST';
export const SET_GROUP_IN_FOCUS = 'POS_CONFIGURATIONS/SET_GROUP_IN_FOCUS';
export const SET_TILES_IN_GROUP = 'POS_CONFIGURATIONS/SET_TILES_IN_GROUP';
export const SET_GROUPS = 'POS_CONFIGURATIONS/SET_GROUPS';
export const SET_GRID_DATA = 'POS_CONFIGURATIONS/SET_GRID_DATA';
export const REMOVE_GROUP = 'POS_CONFIGURATIONS/REMOVE_GROUP';
export const REMOVE_TILE = 'POS_CONFIGURATIONS/REMOVE_TILE';
export const CLEAR_TILES = 'POS_CONFIGURATIONS/CLEAR_TILES';
export const FETCHING = 'POS_CONFIGURATIONS/FETCHING';
export const UPDATE_TILE_POSITION = 'POS_CONFIGURATIONS/UPDATE_TILE_POSITION';
export const UPDATE_TILE = 'POS_CONFIGURATIONS/UPDATE_TILE';
export const ADD_PRODUCTS_VARIANT_TO_TILE =
	'POS_CONFIGURATIONS/ADD_PRODUCTS_VARIANT_TO_TILE';
export const SET_IMAGE_ASSETS = 'POS_CONFIGURATIONS/SET_IMAGE_ASSETS';
export const UPDATE_EXISTING_TILE_PICTURE_ASSET =
	'POS_CONFIGURATIONS/UPDATE_EXISTING_TILE_PICTURE_ASSET';
export const SET_IMAGE_ASSETS_ON_ACTIVE_TILE =
	'POS_CONFIGURATIONS/SET_IMAGE_ASSETS_ON_ACTIVE_TILE';
export const REMOVE_PRODUCTS_VARIANT_FROM_TILE =
	'POS_CONFIGURATIONS/REMOVE_PRODUCTS_VARIANT_FROM_TILE';
export const UPDATE_PRODUCTS_VARIANT_ON_TILE =
	'POS_CONFIGURATIONS/UPDATE_PRODUCTS_VARIANT_ON_TILE';
export const CLEAN_UP = 'POS_CONFIGURATIONS/CLEAN_UP';
export const SET_FULL_VARIANTS_LIST =
	'POS_CONFIGURATIONS/SET_FULL_VARIANTS_LIST';
export const SET_LOADING = 'POS_CONFIGURATIONS/SET_LOADING';
export const SET_MOUNTED = 'POS_CONFIGURATIONS/SET_MOUNTED';

export function cleanUp() {
	return {
		type: CLEAN_UP,
	};
}

export function setLoading(payload) {
	return {
		type: SET_LOADING,
		payload,
	};
}

export function setActiveTile(activeTile = {}) {
	return {
		type: SET_ACTIVE_TILE,
		payload: {
			activeTile,
		},
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function setPosConfigurationsVariantsList(
	posConfigurationsVariantsList = []
) {
	return {
		type: SET_VARIANTS_LIST,
		payload: {
			posConfigurationsVariantsList,
		},
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function setFullPosConfigurationsVariantsList(
	posFullConfigurationsVariantsList = []
) {
	return {
		type: SET_FULL_VARIANTS_LIST,
		payload: posFullConfigurationsVariantsList,
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function setGroupItemInFocus(groupItemInFocus = []) {
	return {
		type: SET_GROUP_IN_FOCUS,
		payload: {
			groupItemInFocus,
		},
	};
}

// /**
//  * @param {string} reportName
//  * @param {object} report
//  */
// export function setImageAssetsOnActiveTile(tilePictureAssetSizes = {}, activeTile = {}) {
// 	return {
// 		type: SET_IMAGE_ASSETS_ON_ACTIVE_TILE,
// 		payload: {
// 			tilePictureAssetSizes,
// 			activeTile
// 		}
// 	};
// }

/**
 * @function setImageAssets
 * @param {string} reportName
 * @param {object} report
 */
export function setImageAssets(imageAssets = []) {
	return {
		type: SET_IMAGE_ASSETS,
		payload: {
			imageAssets,
		},
	};
}

/**
 * @function setImageAssets
 * @param {string} reportName
 * @param {object} report
 */
export function updateExistingTilePictureAsset(imageAsset = {}) {
	return {
		type: UPDATE_EXISTING_TILE_PICTURE_ASSET,
		payload: {
			imageAsset,
		},
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function setTilesInGroup(tilesInGroup = {}) {
	return {
		type: SET_TILES_IN_GROUP,
		payload: {
			tilesInGroup,
		},
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function addProductsVariantToTile(groupTileProductVariant = {}) {
	return {
		type: ADD_PRODUCTS_VARIANT_TO_TILE,
		payload: {
			groupTileProductVariant,
		},
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function updateProductsVariantOnTile(
	tileLayoutTileProductVariant = null,
	tileId = null
) {
	return {
		type: UPDATE_PRODUCTS_VARIANT_ON_TILE,
		payload: {
			tileLayoutTileProductVariant,
			tileId,
		},
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function removeProductsVariantFromTile(
	tileProductTileVariantId = null,
	tileId = null
) {
	return {
		type: REMOVE_PRODUCTS_VARIANT_FROM_TILE,
		payload: {
			tileProductTileVariantId,
			tileId,
		},
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function removeTile(tileId = null) {
	return {
		type: REMOVE_TILE,
		payload: {
			tileId,
		},
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function clearTiles() {
	return {
		type: CLEAR_TILES,
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function setGroups(groups = {}) {
	return {
		type: SET_GROUPS,
		payload: {
			groups,
		},
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function removeGroup(groupId = null) {
	return {
		type: REMOVE_GROUP,
		payload: {
			groupId,
		},
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function setGridData(gridData = {}) {
	return {
		type: SET_GRID_DATA,
		payload: {
			...gridData,
		},
	};
}

/**
 * @param {Boolean} fetching
 */
export function fetching(fetching = false) {
	return {
		type: FETCHING,
		payload: fetching,
	};
}

/**
 * @param {number} updatedTiles
 */
export function updateTilePosition(updatedTiles) {
	return {
		type: UPDATE_TILE_POSITION,
		payload: {
			updatedTiles,
		},
	};
}

/**
 * @param {number} updatedTiles
 */
export function updateTile(tile) {
	return {
		type: UPDATE_TILE,
		payload: {
			tile,
		},
	};
}

export function setMounted(payload) {
	return {
		type: SET_MOUNTED,
		payload,
	};
}
