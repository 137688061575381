'use strict';

import React, { ChangeEvent, useState, MutableRefObject } from 'react';

// interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';
import { IPowerBiReportDatasetReduxValue } from './types';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';

// components
import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import getColumns from 'reactDataWrapperColumns/reporting/powerBiReportDataSets.columns';
import PowerBiReportDataSetsModalBatch from './batch/powerBiReportDataSetsModalBatch';

// components
import { Input, Tooltip, Button, Icon } from 'dumb';

import {
	fetchPowerBiReportDataSets,
	deletePowerBiReportDataSets,
	editPowerBiReportDataSets,
} from './powerBiReportDataSets.service';

import {
	setDynamicAccount,
	updateDynamicAccount,
	resetState,
} from './store/powerBiReportDataSets.actions';

// constants/phrases/tools
import phrases from './powerBiReportDataSets.phrases';

const reduxKey = '/reporting/power_bi_report_datasets';

type Props = {
	id: number;
};

const PowerBiReportDataSets = ({ id }: Props): JSX.Element => {
	// hooks
	const { powerBiReportDataset, tableKey } = useAppSelector((state) => ({
		powerBiReportDataset:
			state.globalAdministrationPowerBIReportDatasets.data.powerBiReportDataset,
		tableKey: state.globalAdministrationPowerBIReportDatasets.tableKey,
	}));
	const dispatch = useAppDispatch();
	const [showBatchModal, setShowBatchModal] = useState(false);

	const editEntry = () => {
		if (!powerBiReportDataset.id) return Promise.resolve(true);

		const payload = {
			dataset_id: powerBiReportDataset.dataset_id,
		};

		return editPowerBiReportDataSets(powerBiReportDataset.id, payload);
	};

	const deleteEntry = (id: number) => {
		return deletePowerBiReportDataSets(id);
	};

	const setInitialEditValues = (data: IReportingPowerBiDataSet) => {
		const payload: IPowerBiReportDatasetReduxValue = {
			id: data.id,
			dataset_id: data.dataset_id,
		};

		dispatch(setDynamicAccount(payload));
	};

	const editStoreEntry = (
		name: keyof IPowerBiReportDatasetReduxValue,
		value: string
	): void => {
		const payload = {
			[name]: value,
		};

		dispatch(updateDynamicAccount(payload));
	};

	const getEditableCells = () => {
		return [
			{
				header: 'Dataset id',
				value: (
					<Input
						id="dataset-id"
						dataCy="edit-modal-dataset-id"
						placeholder="Enter dataset id..."
						value={powerBiReportDataset.dataset_id}
						onChange={(event: ChangeEvent<HTMLInputElement>) =>
							editStoreEntry('dataset_id', event.target.value)
						}
						required
					/>
				),
			},
		];
	};

	const fetchData = (
		state: IFetchDataProps
	): ReturnType<typeof fetchPowerBiReportDataSets> => {
		const filter = `:power_bi_report.id=='${id}'`;

		return fetchPowerBiReportDataSets(state, filter);
	};

	const toggleShowBatchModal = () => setShowBatchModal(!showBatchModal);

	return (
		<>
			<ReactDataWrapper
				key={tableKey}
				title={phrases.TABLE_TITLE}
				dataCy="power-bi-report-datasets-table"
				columns={getColumns()}
				fetchData={fetchData}
				filterable
				defaultPageSize={10}
				reduxKey={`${reduxKey}-${id}`}
				manual
				accessAreasAllowedToEdit={[
					'Organisation/Workplaces::Edit',
					'Organization Admin',
				]}
				editableCells={getEditableCells()}
				editEntry={editEntry}
				deleteEntry={deleteEntry}
				setInitialEditValues={setInitialEditValues}
				onModalClose={() => dispatch(resetState())}
				onModalCloseConfirm
				actionRender={
					<Tooltip
						text={phrases.BATCH_ADD}
						renderData={(
							ref: MutableRefObject<undefined>,
							onMouseEnter: () => void,
							onMouseLeave: () => void
						) => (
							<Button
								label="Add"
								shadow
								type="inverted"
								dataCy="batch-add"
								onClick={toggleShowBatchModal}
								refProp={ref}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
							>
								<Icon name="add" />
							</Button>
						)}
					/>
				}
			/>

			{showBatchModal && (
				<PowerBiReportDataSetsModalBatch
					powerBiReportId={id}
					onClose={toggleShowBatchModal}
				/>
			)}
		</>
	);
};

export default PowerBiReportDataSets;
