import {
	SET_KPIS,
	TOGGLE_FETCHING,
	RESET_KPIS
} from './shiftplannerKPI.actions';

const defaultState = {
	fetching: false
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {

	case SET_KPIS: {
		return {
			...state,
			[payload.weekNumber]: {
				...state[payload.weekNumber],
				...payload.shiftplannerKpis
			}
		};
	}

	case RESET_KPIS: {
		return defaultState;
	}

	case TOGGLE_FETCHING: {
		return {
			...state,
			fetching: payload.fetching
		};
	}

	default:
		return state;
	}
}

export default reducer;