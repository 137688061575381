export default {
	EMPLOYMENT_LIST: 'Employment list',
	EMPLOYMENT_LIST_ADDED: 'Employment added!',
	EMPLOYMENT_LIST_EDITED: 'Employment edited!',
	EMPLOYMENT_LIST_DELETED: 'Employment deleted!',
	ACTIVE_EMPLOYMENTS_ACTIVE: 'Active',
	SELECT_WORKPLACE: 'Select workplace',
	SELECT_TYPE: 'Select type',
	SELECT_COMPANY: 'Select company',
	SELECT_APP_ALLOWANCE: 'Select app allowance...',
	EMPLOYEE_MEAL_APP_ALLOWANCE_TABLE_TITLE: 'App allowances',
	UNDO_TERMINATION_MODAL_HEADER: 'Rolling back a termination will have the following consequences',
	UNDO_TERMINATION_MODAL_ITEM_ONE: 'Termination will be removed from employment',
	UNDO_TERMINATION_MODAL_ITEM_TWO: 'Ending date will be set to 9999-12-31',
	UNDO_TERMINATION_MODAL_ITEM_THREE: 'Terminated employment position ending date will be set to 9999-12-31',
	UNDO_TERMINATION_MODAL_ITEM_FOUR: 'Shifts have to reassigned manually. They will not be assigned when rolling back',
	UNDO_TERMINATION_MODAL_TITLE: 'Rollback termination',
	UNDO_TERMINATION_MODAL_CONFIRM_BUTTON_LABEL: 'Rollback',
	UNDO_TERMINATION: 'Undo Termination',
	TENANT_TRANSFER_TOOLTIP: 'Transfer employment to another tenant',
	TENANT_TRANSFER_BUTTON_LABEL: 'Transfer',
};
