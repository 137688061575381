'use strict';


import _findKey from 'lodash/findKey';
import _includes from 'lodash/includes';

export default class CustomInputService {

	getInputFamily(type) {
		const families = {
			checkbox: ['bool'],
			date: ['date', 'datetime'],
			time: ['time', 'timetime'],
			input: ['string', 'mail', 'tel', 'number', 'currency', 'password'],
			longInput: ['longString'],
			password: ['password'],
			select: ['multi', 'select']
		};
		return _findKey(families, (members) => _includes(members, type));
	}
}