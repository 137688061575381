import React from 'react';
import PropTypes from 'prop-types';

// components
import { Button, Text, ButtonLoader, Tag } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// phrases
import phrases from './../../../../../shiftTransferApproval.phrases';
import { ShiftTransfer as enums } from 'services/enums';

// utils
import { getAppliedPendingShiftTransferMetadata } from './../utils';

const ShiftTransferPendingTransfersApplied = ({
	appliedShifts,
	getShiftDate,
	formatShiftTime,
	getShiftDay,
	updateShiftTransferApplicationWrapper,
	loading,
	personId,
	getApplicantList,
	defaultColor,
}) => {
	const getShiftCell = (entry) => {
		const shiftDate = getShiftDate(entry.shift?.planned_period?.from);
		const timeFrom = formatShiftTime(entry.shift?.planned_period?.from);
		const timeTo = formatShiftTime(entry.shift?.planned_period?.to);
		const shiftDay = getShiftDay(entry.shift?.planned_period?.from);

		const concatenatedText = `${shiftDate} ${timeFrom} - ${timeTo} (${shiftDay})`;

		return (
			<div className="shift-transfer-approval-modal__table-wrapper__row-cell">
				<Text bold size="small" noWrap>
					{concatenatedText}
				</Text>
			</div>
		);
	};

	return (
		<div>
			<Text bold>{phrases.SHIFTS_YOU_APPLIED_FOR}</Text>
			<div className="shift-transfer-approval-modal__table-wrapper">
				<TableList>
					<thead>
						<tr>
							<th width={180}>{phrases.TABLE_LIST_BAR}</th>
							<th width={200}>{phrases.TABLE_LIST_SHIFT}</th>
							<th>{phrases.TABLE_LIST_JUICER}</th>
							<th>{phrases.TABLE_LIST_APPLICANTS}</th>
							<th>{phrases.TABLE_LIST_STATE}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{appliedShifts.map((entry) => {
							const {
								canCancelTransfer,
								canReapplyForShift,
							} = getAppliedPendingShiftTransferMetadata({ entry, personId });

							return (
								<TableListRow key={entry.id}>
									<TableListCell>
										<Text size="small" bold noWrap>
											{entry.shift?.workplace?.name}
										</Text>
									</TableListCell>

									<TableListCell>{getShiftCell(entry)}</TableListCell>

									<TableListCell>
										<div className="shift-transfer-approval-modal__table-wrapper__row-cell">
											<Text bold size="small" noWrap>
												{entry.donor?.person?.full_name ?? ''}
											</Text>
										</div>
									</TableListCell>

									<TableListCell>{getApplicantList(entry)}</TableListCell>

									<TableListCell>
										<Tag color={defaultColor} noMargin>
											<Text size="tiny" bold noWrap>
												{phrases.AWAITING_BM}
											</Text>
										</Tag>
									</TableListCell>

									<TableListCell>
										<div className="shift-transfer-approval-modal__table-wrapper__row-cell shift-transfer-approval-modal__table-wrapper__row-cell--align-end">
											{canCancelTransfer && (
												<Button
													shadow
													type="negative"
													size="small"
													onClick={() =>
														updateShiftTransferApplicationWrapper({
															shiftTransfer: entry,
															operation: enums.OPERATIONS.DECLINE,
														})
													}
													disabled={loading.id === entry.id}>
													{loading.id === entry.id ? (
														<ButtonLoader loading />
													) : (
														phrases.CANCEL_TRANSFER
													)}
												</Button>
											)}
											{canReapplyForShift && (
												<Button
													shadow
													type="success"
													size="small"
													onClick={() =>
														updateShiftTransferApplicationWrapper({
															shiftTransfer: entry,
															operation: enums.OPERATIONS.ACCEPT,
														})
													}
													disabled={loading.id === entry.id}>
													{loading.id === entry.id ? (
														<ButtonLoader loading />
													) : (
														phrases.REAPPLY_FOR_TRANSFER
													)}
												</Button>
											)}
										</div>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
			</div>
		</div>
	);
};

ShiftTransferPendingTransfersApplied.propTypes = {
	appliedShifts: PropTypes.array,
	getShiftDate: PropTypes.func,
	formatShiftTime: PropTypes.func,
	getShiftDay: PropTypes.func,
	updateShiftTransferApplicationWrapper: PropTypes.func,
	loading: PropTypes.bool,
	personId: PropTypes.number,
	getApplicantList: PropTypes.func,
	defaultColor: PropTypes.string,
};

export default ShiftTransferPendingTransfersApplied;
