import constants from 'services/constants';
import moment from 'moment';

export function getActiveEmployments({ row, today }) {
	return row.filter((entry) => {
		const entryStartedMoment = moment.utc(entry.started, constants.shortDate);
		const entryEndedMoment = moment.utc(entry.ended, constants.shortDate);

		if (
			entryStartedMoment.isSameOrBefore(today, 'day') &&
			entryEndedMoment.isSameOrAfter(today, 'day')
		)
			return entry;
	});
}
