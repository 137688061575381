'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { store, connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { SingleDatePickerInput, Input } from 'dumb';

// random
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import phrases from './../vacationsIllness.phrases';
import constants from 'services/constants';

// actions, services
import { updateIllnessData, setIllnessData, resetState } from './store/illnessTable.actions';

import { fetchIllnesses, addIllness, editIllness, deleteIllness } from './store/illnessTable.service';

import { getFactorSelectDisabled, getSelectedDate } from './../vacationsIllness.utilities';

class IllnessContainer extends Component {
	constructor(props) {
		super(props);

		this.addEntry = this.addEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.fetchCsv = this.fetchCsv.bind(this);
		this.getEditableCells = this.getEditableCells.bind(this);
		this.setIllnessData = this.setIllnessData.bind(this);
		this.updateIllnessData = this.updateIllnessData.bind(this);

		this.reduxKey = 'hr/person_illnesses';
		this.columns = [
			{
				Header: 'From',
				id: 'from',
				accessor: (d) => _get(d, 'period.from', null),
				filterPath: ':period.from',
			},
			{
				Header: 'To',
				id: 'to',
				accessor: (d) => _get(d, 'period.to', null),
				filterPath: ':period.to',
			},
			{
				Header: 'Half day',
				width: 65,
				id: 'factor',
				accessor: (d) => d,
				filterPath: ':factor',
				filterable: false,
				Cell: (d) => {
					return <Input type="checkbox" checked={d.original.factor === 0.5} disabled />;
				},
			},
		];
	}

	/**
	 * @method addEntry
	 * @description crates an illness
	 */
	addEntry() {
		const { personId, illnessData, addShiftplannerIllness } = this.props;

		const payload = {
			person: personId,
			period: {
				from: moment.utc(illnessData.from).format(constants.shortDate),
				to: moment.utc(illnessData.to).format(constants.shortDate),
			},
			...(illnessData.factor && { factor: illnessData.factor }),
		};

		return addIllness(payload).then((res) => {
			if (addShiftplannerIllness) {
				addShiftplannerIllness(res.data);
			}
			return res;
		});
	}

	/**
	 * @method editEntry
	 * @description edits an illness
	 */
	editEntry() {
		const { illnessData, illnessFromListData, updateShiftplannerIllness } = this.props;

		// find original illness from table
		const originalIllness = illnessFromListData.find((entry) => entry.id === illnessData.id);

		const fromDateChanged = !moment.utc(illnessData.from).isSame(moment.utc(originalIllness.period.from), 'day');
		const toDateChanged = !moment.utc(illnessData.to).isSame(moment.utc(originalIllness.period.to), 'day');

		// check if period changed from original
		const period = {
			...(fromDateChanged && {
				from: moment.utc(illnessData.from).format(constants.shortDate),
			}),
			...(toDateChanged && {
				to: moment.utc(illnessData.to).format(constants.shortDate),
			}),
		};

		const payload = {
			id: illnessData.id,
			factor: illnessData.factor,
			...(!_isEmpty(period) && { period }),
		};

		return editIllness(payload).then((res) => {
			if (updateShiftplannerIllness) updateShiftplannerIllness(res.data[0]);
			return res;
		});
	}

	/**
	 * @method deleteEntry
	 * @description deletes an illness
	 */
	deleteEntry(id) {
		const { deleteShiftplannerIllness } = this.props;

		return deleteIllness(id).then((res) => {
			if (deleteShiftplannerIllness) deleteShiftplannerIllness(id);
			return res;
		});
	}

	/**
	 * @method setIllnessData
	 * @param {Object} data
	 * @description sets illness data in redux for edit modal
	 */
	setIllnessData(data) {
		const payload = {
			id: data.id,
			from: data.period.from,
			to: data.period.to,
			factor: data.factor,
		};

		this.props.setIllnessData(payload);
	}

	/**
	 * @method fetchData
	 * @param {Object} state
	 * @description gets illnesses
	 */
	fetchData(state) {
		const { personId } = this.props;

		return fetchIllnesses(state, personId);
	}

	/**
	 * @method fetchCsv
	 * @param {Object} state
	 * @description gets illnesses as csv export
	 */
	fetchCsv(state) {
		const { personId } = this.props;

		const headers = {
			Accept: 'application/csv',
		};

		return fetchIllnesses({ ...state, headers }, personId);
	}

	getEditableCells() {
		const { illnessData } = this.props;

		return [
			{
				header: 'From',
				value: (
					<SingleDatePickerInput
						onChange={(date) => this.updateIllnessData('from', date)}
						selectedDate={getSelectedDate('from', illnessData)}
						noClockButton
					/>
				),
			},
			{
				header: 'To',
				value: (
					<SingleDatePickerInput
						onChange={(date) => this.updateIllnessData('to', date)}
						selectedDate={getSelectedDate('to', illnessData)}
						noClockButton
					/>
				),
			},
			{
				header: 'Half day',
				value: (
					<Input
						type="checkbox"
						checked={illnessData.factor === 0.5}
						onChange={(event) => this.updateIllnessData('factor', event)}
						disabled={getFactorSelectDisabled(illnessData)}
					/>
				),
			},
		];
	}

	/**
	 * @method updateIllnessData
	 * @param {String} name - name of the input subject/description/from/to
	 * @param {String} value - value of any of the inputs
	 * @description updates store data with changed value
	 */
	updateIllnessData(name, e) {
		const { updateIllnessData, illnessData } = this.props;

		const value = name === 'factor' ? (e.target.checked ? 0.5 : 1) : e.target ? e.target.value : e;

		// if dates changed set factor to full day again
		// if it did change, check if changed to more then a day
		const setFactorToFullDay =
			name === 'from' || name === 'to'
				? !moment.utc(value).isSame(moment.utc(name === 'from' ? illnessData.to : illnessData.from), 'day')
				: false;

		const payload = {
			[name]: value,
			...(setFactorToFullDay && {
				factor: 1,
			}),
		};

		// set the value in the store
		updateIllnessData(payload);
	}

	render() {
		const { resetState, modalZIndex } = this.props;

		return (
			<ReactDataWrapper
				title={phrases.ILLNESS_TABLE}
				columns={this.columns}
				editEntry={this.editEntry}
				createEntry={this.addEntry}
				fetchData={this.fetchData}
				fetchCsv={this.fetchCsv}
				accessAreasAllowedToEdit={['Employment Admin', 'Person Admin', 'Shiftplan Manager', 'Shiftplan Admin']}
				deleteEntry={(id) => this.deleteEntry(id)}
				editableCells={this.getEditableCells()}
				setInitialEditValues={this.setIllnessData}
				filterable
				defaultPageSize={10}
				reduxKey={this.reduxKey}
				onModalClose={resetState}
				manual
				inRowButtons
				modalZIndex={modalZIndex}
			/>
		);
	}
}

IllnessContainer.propTypes = {
	updateIllnessData: PropTypes.func,
	setIllnessData: PropTypes.func,
	personId: PropTypes.number,
	resetState: PropTypes.func,
	illnessData: PropTypes.object,
	illnessFromListData: PropTypes.array,
	modalZIndex: PropTypes.bool,
	addShiftplannerIllness: PropTypes.bool,
	updateShiftplannerIllness: PropTypes.func,
	deleteShiftplannerIllness: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateIllnessData,
			setIllnessData,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		illnessData: store.illnessTable.data.illnessData,
		illnessFromListData: _get(store, `listData.['hr/person_illnesses'].data.listData`, []),
	};
};

export default connectWithStore(IllnessContainer, mapStateToProps, mapDispatchToProps);
