export default {
	TABLE_TITLE: 'Product bundle sets',
	PRODUCT_RECOMMENDATIONS_TABLE_TITLE: 'Product recommendations',
	ASSET_COLLECTION_TABLE_TITLE: 'Asset collections',
	PRODUCT_BUNDLE_SET_PRODUCT_VARIANTS_TABLE_TITLE: 'Product bundle sets product variants',
	PRODUCT_BUNDLE_SET_TRANSLATIONS_TABLE_TITLE: 'Product bundle set translations',

	PRODUCT_BUNDLE_SETS_ADDED: 'Product bundle sets added!',
	PRODUCT_BUNDLE_SET_EDITED: 'Product bundle set edited!',
	PRODUCT_BUNDLE_SETS_EDITED: 'Product bundle sets edited!',
	PRODUCT_BUNDLE_SET_DELETED: 'Product bundle set deleted!',

	MODAL_BATCH_STEP_FORM_TITLE: 'Add Product bundle sets',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'next',
	MODAL_BATCH_STEP_LIST_TITLE: 'Confirm entries',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'submit',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'back',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_PRODUCT_RECOMMENDATION: 'Product recommendation',
	MODAL_BATCH_STEP_PRODUCT_RECOMMENDATION_PLACEHOLDER: 'Select product recommendation...',
	MODAL_BATCH_STEP_ASSET_COLLECTION: 'Asset collection',
	MODAL_BATCH_STEP_ASSET_COLLECTION_PLACEHOLDER: 'Select Asset collection...',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_SORT_ORDER: 'Sort order',
	MODAL_BATCH_STEP_SORT_ORDER_PLACEHOLDER: 'Enter sort order...',
	MODAL_BATCH_STEP_OPTIONAL: 'Optional',
	MODAL_BATCH_STEP_FORM_PRODUCT_RECOMMENDATIONS_TABLE_TITLE: 'Product recommendations',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a new row',
};
