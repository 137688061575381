'use strict';

// general
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// styles
import './collapsableTable.css';
// third party/ services
import _get from 'lodash/get';

class CollapsableTable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isToggled: false
		};

		this._getTableData = this._getTableData.bind(this);
		this._toggleTable = this._toggleTable.bind(this);
		this._renderFullData = this._renderFullData.bind(this);
		this._renderPartialData = this._renderPartialData.bind(this);
	}

	componentDidMount() {
		// append autofocus via
		const firstElement = document.getElementById('firstElement');
		if (firstElement) {
			firstElement.focus({
				preventScroll: true
			});
		}
	}

	/**
	 * @function _renderFullData
	 * @description Renders the whole data set that is passed the data prop
	 */
	_renderFullData() {
		let { data } = this.props;

		// append autoFocus property on the first element of the array
		if (_get(data, '[0][0].value', false)) {
			data[0][0].value = this._addExtraProp(data[0][0].value, {
				id: 'firstElement'
			});
		}

		return data.map((rowData, index) => {
			return (
				<tr key={index}>
					{rowData.map((cellData, index) => {
						// add tabIndex property to every element
						cellData.value = this._addExtraProp(cellData.value, {
							tabIndex: 0
						});

						// if element is select, add onOpen and onClose to manage the state
						// this will also add it to other elements like image loader but it won't affect the functionality
						if (cellData.value.type !== 'input') {
							cellData.value = this._addExtraProp(cellData.value, {
								onOpen: () => {
									this.selectTouched(true);
								},
								onClose: () => {
									this.selectTouched(false);
								}
							});
						}
						return <td key={cellData.value + index}>{cellData.value}</td>;
					})}
				</tr>
			);
		});
	}

	_renderPartialData() {
		const { numOfInitialRows, data } = this.props;
		let rows = [];

		// append autoFocus property on the first element of the array
		if (_get(data, '[0][0].value', false)) {
			data[0][0].value = this._addExtraProp(data[0][0].value, {
				id: 'firstElement'
			});
		}

		for (let i = 0; i < numOfInitialRows; i++) {
			rows.push(
				<tr key={i} className="collapsable-table__default-row">
					{data[i].map((cellData, index) => {
						// add tabIndex property to every element
						cellData.value = this._addExtraProp(cellData.value, {
							tabIndex: 0
						});

						// if the element isn't a regular input
						if (cellData.value.type !== 'input') {
							cellData.value = this._addExtraProp(cellData.value, {
								onOpen: () => {
									this.selectTouched(true);
								},
								onClose: () => {
									this.selectTouched(false);
								}
							});
						}
						return <td key={cellData.value + index}>{cellData.value}</td>;
					})}
				</tr>
			);
		}

		return rows;
	}

	/**
	 * @function _addExtraProp
	 * @description takes the component and adds extra prop to it
	 * @param {React Component} Component - react component that we want to append props to
	 * @param {object} extraProps - extra props to append
	 */
	_addExtraProp(Component, extraProps) {
		return <Component.type {...Component.props} {...extraProps} />;
	}

	/**
	 * @function selectTouched
	 * @description fires when the select is opened and closed
	 * @param {*} touched - sets the state of the dropwodn to true or false
	 */
	selectTouched(touched) {
		this.props.toggleSelectInput(touched);
	}

	/**
	 * @function _getTableData
	 * @description returns the appropraite data for toggled/untoggled table
	 */
	_getTableData() {
		let { data, collapsable } = this.props;

		let { isToggled } = this.state;

		if (data.length > 0) {
			if (!isToggled && collapsable) return this._renderPartialData();

			return this._renderFullData();
		}
	}

	_toggleTable() {
		this.setState(prevState => ({ isToggled: !prevState.isToggled }));
	}

	render() {
		const { headers, initialTopOffset, collapsable } = this.props;
		const { isToggled } = this.state;

		const offSet = !isToggled ? initialTopOffset : 0;
		const background = !isToggled ? 'initial' : '#fff';

		const tableWrapperStyles = {
			top: `${offSet}px`,
			transition: 'top .2s linear'
		};
		const containerStyles = {
			background: background,
			transition: 'background .2s ease-in'
		};

		return (
			<div className="collapsable-table" style={containerStyles}>
				<div className="collapsable-table__content-wrapper">
					<div
						className="collapsable-table__table-wrapper"
						style={tableWrapperStyles}>
						<div className="collapsable-table__scroll-wrapper">
							<table className="collapsable-table__table">
								<thead className="collapsable-table__header-wrapper">
									<tr>
										{headers.map(header => (
											<th
												style={{ minWidth: header.width, width: header.width }}
												key={header.value}>
												{header.value}
											</th>
										))}
									</tr>
								</thead>
								<tbody className="collapsable-table__body-wrapper">
									{this._getTableData()}
								</tbody>
							</table>
						</div>

						{collapsable && (
							<div className="collapsable-table__toggle-wrapper">
								<div
									className="collapsable-table__toggle"
									onClick={this._toggleTable}>
									{isToggled ? (
										<span className="icon icon--keyboard_arrow_up" />
									) : (
										<span className="icon icon--keyboard_arrow_down" />
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

CollapsableTable.propTypes = {
	data: PropTypes.array,
	headers: PropTypes.array,
	numOfInitialRows: PropTypes.number,
	initialTopOffset: PropTypes.number,
	collapsable: PropTypes.bool,
	renderPartialData: PropTypes.func,
	renderFullData: PropTypes.func,
	toggleSelectInput: PropTypes.func
};

CollapsableTable.defaultProps = {
	data: [],
	headers: [],
	numOfInitialRows: 1,
	initialTopOffset: 0,
	collapsable: true
};

export default CollapsableTable;
