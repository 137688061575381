'use strict';

import _each from 'lodash/each';
import _has from 'lodash/has';

module.exports = function(translations, headers) {
	var settings = this.getSettings();
	var translation;
	_each(headers, function(header) {
		if (_has(header, 'colGroup') && !_has(header, 'colGroupLabel')) {
			translation = 'COLGROUPS.' + settings.translationPath + '.' + header.colGroup.toUpperCase();
			header.colGroupLabel = translations.getSingle(translation);
		}
	});
	this.setHeaders(headers);
};