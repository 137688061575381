'use strict';

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { Icon }  from 'dumb';

import './rollUp.css';

function rollUp(WrappedComponent) {
	class RollUp extends Component {
		constructor(props) {
			super(props);

			// Get rollup-root element to create portal
			this.rootElement = document.getElementById('rollup-root');
		}

		render() {
			const { visible, handleClose, modifierClassName, topIndex } = this.props;

			return ReactDOM.createPortal(
				<CSSTransition
					component="div"
					classNames="roll-up--fade"
					timeout={300}
					in={visible}
					unmountOnExit
					mountOnEnter>
					<div
						key={cx('roll-up', modifierClassName)}
						className={cx('roll-up', modifierClassName, {
							'roll-up__exception': topIndex
						})}>
						<a onClick={handleClose} className="roll-up__close">
							<Icon name="close" />
						</a>
						<WrappedComponent {...this.props} />
					</div>
				</CSSTransition>,
				this.rootElement
			);
		}
	}

	RollUp.defaultProps = {
		visible: false,
		modifierClassName: ''
	};

	RollUp.propTypes = {
		visible: PropTypes.bool.isRequired,
		modifierClassName: PropTypes.string,
		handleClose: PropTypes.func.isRequired,
		topIndex: PropTypes.bool
	};

	return RollUp;
}

export default rollUp;
