'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	setNutritionValue,
	updateNutritionValue,
	resetState,
	updateBatchForm,
	editBatchListItem,
	removeBatchListItem,
	addBatchList,
} from './store/nutritionValues.actions';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import NutritionValuesModalBatch from './components/batch/nutritionValuesModalBatch';
import { Button, Icon, Input } from 'dumb';

// lodash
import _get from 'lodash/get';

// phrases
import phrases from './nutritionValues.phrases';
import moment from 'moment';

// services
import {
	fetchAllNutritionValues,
	deleteNutritionValues,
	editNutritionValues,
	addNutritionValues,
	editBatchNutritionValues,
} from './nutritionValues.service';

// styles
import './nutritionValues.css';

class NutritionValues extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.toggleBatchModal = this.toggleBatchModal.bind(this);

		this.state = {
			pages: null,
			key: moment(),
			showModal: false,
		};

		this.fetchData = this.fetchData.bind(this);
		this.addNutritionValuesWrapper = this.addNutritionValuesWrapper.bind(this);

		this.columns = [
			...(this.props.useGlobalResource
				? []
				: [
						{
							Header: 'Market',
							id: 'market',
							accessor: (d) => _get(d, 'market.name', ''),
							filterPath: ':market.name',
						},
				  ]),
			{
				Header: 'Ingredient',
				id: 'ingname',
				accessor: (d) => _get(d, 'ingredient.name', ''),
				filterPath: ':ingredient.name',
			},
			{
				Header: 'Nutrition Category',
				id: 'nutcat',
				accessor: (d) => _get(d, 'nutrition_category.name', ''),
				filterPath: ':nutrition_category.name',
			},
			{
				Header: 'Value',
				id: 'value',
				accessor: 'value',
				filterPath: ':value',
			},
		];
	}

	editEntry() {
		const { defaultNutritionValues, useGlobalResource } = this.props;

		const payload = {
			value: defaultNutritionValues.value,
		};

		return editNutritionValues(defaultNutritionValues.id, payload, useGlobalResource);
	}

	editMultiple(selectedRows) {
		const { defaultNutritionValues, useGlobalResource } = this.props;

		const list = selectedRows.map((row) => {
			return {
				id: row.id,
				...(defaultNutritionValues.value && {
					value: defaultNutritionValues.value,
				}),
			};
		});

		const payload = {
			batch: list,
		};

		return editBatchNutritionValues(payload, useGlobalResource);
	}

	deleteEntry(id) {
		const { useGlobalResource } = this.props;

		return deleteNutritionValues(id, useGlobalResource);
	}

	setInitialEditValues(data) {
		this.props.setNutritionValue(data);
	}

	getEditableCells() {
		const { defaultNutritionValues, useGlobalResource } = this.props;

		return [
			...(useGlobalResource
				? []
				: [
						{
							header: 'Market',
							value: <span>{_get(defaultNutritionValues, 'market.name', false)}</span>,
						},
				  ]),
			{
				header: 'Ingredient',
				value: <span>{_get(defaultNutritionValues, 'nutrition_category.name', '')}</span>,
			},
			{
				header: 'Nutrition Category',
				value: <span>{_get(defaultNutritionValues, 'ingredient.name', '')}</span>,
			},
			{
				header: 'Value',
				value: (
					<Input
						id="value"
						placeholder="Enter value..."
						value={_get(defaultNutritionValues, 'value', '')}
						onChange={(event) => this.editStoreEntry(event.target.value, 'value')}
					/>
				),
			},
		];
	}

	editStoreEntry(e, type) {
		this.props.updateNutritionValue({
			[type]: e,
		});
	}

	fetchData(state) {
		const { useGlobalResource } = this.props;

		return fetchAllNutritionValues(state, useGlobalResource);
	}

	toggleBatchModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	addNutritionValuesWrapper(data, useGlobalResource) {
		return addNutritionValues(data, useGlobalResource)
			.then(() => {
				this.setState(() => ({ key: moment.utc() }));
			})
			.catch((err) => {
				throw err;
			});
	}

	render() {
		const {
			batchFormData,
			batchListData,
			addBatchList,
			updateBatchForm,
			editBatchListItem,
			removeBatchListItem,
			useGlobalResource,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					accessAreasAllowedToEdit={['Sales Configuration']}
					key={this.state.key}
					title={useGlobalResource ? phrases.GLOBAL_NUTRITION_VALUES : phrases.NUTRITION_VALUES}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={useGlobalResource ? 'pos/global_nutrition_values' : 'pos/nutrition_values'}
					manual
					editEntry={this.editEntry}
					editMultiple={this.editMultiple}
					editableCells={this.getEditableCells()}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					deleteEntry={this.deleteEntry}
					createEntry={this.addEntry}
					actionRender={
						<Button type="inverted" label="Add" shadow onClick={this.toggleBatchModal}>
							<Icon name="add" />
						</Button>
					}
				/>

				<NutritionValuesModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleBatchModal}
					batchFormData={batchFormData}
					batchListData={batchListData}
					addBatchList={addBatchList}
					updateBatchForm={updateBatchForm}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					batchAddData={this.addNutritionValuesWrapper}
					fetchData={this.fetchData}
					useGlobalResource={useGlobalResource}
				/>
			</>
		);
	}
}

NutritionValues.propTypes = {
	defaultNutritionValues: PropTypes.object,
	updateNutritionValue: PropTypes.func,
	setNutritionValue: PropTypes.func,
	resetState: PropTypes.func,
	updateBatchForm: PropTypes.func,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	batchFormData: PropTypes.object,
	batchListData: PropTypes.array,
	addBatchList: PropTypes.func,

	// if this prop passed, we use product/global_nutrition_values resource instead of regular nutrition_values
	// this effectively makes this table a useGlobalResource table (no relation to a market)
	useGlobalResource: PropTypes.bool,
};

NutritionValues.defaultProps = {
	useGlobalResource: false,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setNutritionValue,
			updateNutritionValue,
			resetState,
			updateBatchForm,
			editBatchListItem,
			removeBatchListItem,
			addBatchList,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultNutritionValues: store.productNutritionValues.data.defaultNutritionValues,
		batchFormData: store.productNutritionValues.data.batchFormData,
		batchListData: store.productNutritionValues.data.batchListData,
	};
};

export default connectWithStore(NutritionValues, mapStateToProps, mapDispatchToProps);
