'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	updateBatchForm,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setMarketGracePeriods,
	updateMarketGracePeriods,
	resetState,
} from './store/marketGracePeriods.actions';

// services
import {
	fetchMarketGracePeriods,
	editMarketGracePeriod,
	editMarketGracePeriods,
	addMarketGracePeriod,
	deleteMarketGracePeriod,
} from './marketGracePeriods.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { FilterProvider } from 'reactDataWrapper/utilities';
import { Input, Button, Icon, InputCollectionSelect } from 'dumb';
import MarketGracePeriodsModalBatch from './components/batch/marketGracePeriodsModalBatch';

// phrases/moment/enums
import phrases from './marketGracePeriods.phrases';
import moment from 'moment';
import collectionSelectEnums from 'services/enums/collectionSelect';

// utils
import { getEditedValues } from 'services/utils';

// styles
import './marketGracePeriods.css';

const reduxKey = '/shiftplanning/market_grace_periods';

class MarketGracePeriods extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.addMarketGracePeriodWrapper = this.addMarketGracePeriodWrapper.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.editEntry = this.editEntry.bind(this);

		this.columns = [
			{
				Header: 'Market',
				id: 'market',
				accessor: 'market.name',
				filterPath: ':market.name',
				Filter: ({ column }) => (
					<FilterProvider
						reduxKey={reduxKey}
						columnId={column.id}
						provide={(filterValue, persistToFilterStorage) => (
							<InputCollectionSelect
								id={column.id}
								name="market"
								value={filterValue}
								handleChange={(key, value) => {
									persistToFilterStorage({ handle: key, value });
								}}
								placeholder="Select market..."
								styleType={collectionSelectEnums.TYPE_IN_TABLE}
								apiPath="/administration/markets"
								params={{
									limit: 30,
								}}
							/>
						)}
					/>
				),
			},
			{
				Header: 'Early shift start',
				id: 'early_shift_start',
				accessor: 'early_shift_start',
				filterPath: ':early_shift_start',
			},
			{
				Header: 'Early shift end',
				id: 'early_shift_end',
				accessor: 'early_shift_end',
				filterPath: ':early_shift_end',
			},
			{
				Header: 'Late shift start',
				id: 'late_shift_start',
				accessor: 'late_shift_start',
				filterPath: ':late_shift_start',
			},
			{
				Header: 'Late shift end',
				id: 'late_shift_end',
				accessor: 'late_shift_end',
				filterPath: ':late_shift_end',
			},
			{
				Header: 'Break too short',
				id: 'break_too_short',
				accessor: 'break_too_short',
				filterPath: ':break_too_short',
			},
			{
				Header: 'Break too long',
				id: 'break_too_long',
				accessor: 'break_too_long',
				filterPath: ':break_too_long',
			},
		];
	}

	editEntry() {
		const { marketGracePeriod, defaultMarketGracePeriod } = this.props;

		const valuesToEdit = getEditedValues({
			newData: marketGracePeriod,
			oldData: defaultMarketGracePeriod,
		});

		const payload = {
			id: marketGracePeriod.id,
			early_shift_start: valuesToEdit.earlyShiftStart ?? undefined,
			early_shift_end: valuesToEdit.earlyShiftEnd ?? undefined,
			late_shift_start: valuesToEdit.lateShiftStart ?? undefined,
			late_shift_end: valuesToEdit.lateShiftEnd ?? undefined,
			break_too_short: valuesToEdit.breakTooShort ?? undefined,
			break_too_long: valuesToEdit.breakTooLong ?? undefined,
		};

		return editMarketGracePeriod(payload);
	}

	editMultiple(selectedRows) {
		const { marketGracePeriod } = this.props;

		const payload = {
			...(marketGracePeriod.earlyShiftStart && {
				early_shift_start: marketGracePeriod.earlyShiftStart,
			}),
			...(marketGracePeriod.earlyShiftEnd && {
				early_shift_end: marketGracePeriod.earlyShiftEnd,
			}),
			...(marketGracePeriod.lateShiftStart && {
				late_shift_start: marketGracePeriod.lateShiftStart,
			}),
			...(marketGracePeriod.lateShiftEnd && {
				late_shift_end: marketGracePeriod.lateShiftEnd,
			}),
			...(marketGracePeriod.breakTooShort && {
				break_too_short: marketGracePeriod.breakTooShort,
			}),
			...(marketGracePeriod.breakTooLong && {
				break_too_long: marketGracePeriod.breakTooLong,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editMarketGracePeriods({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteMarketGracePeriod(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			market: data.market.name,
			earlyShiftStart: data.early_shift_start,
			earlyShiftEnd: data.early_shift_end,
			lateShiftStart: data.late_shift_start,
			lateShiftEnd: data.late_shift_end,
			breakTooLong: data.break_too_long,
			breakTooShort: data.break_too_short,
			singleEdit: true,
		};

		this.props.setMarketGracePeriods(payload);
	}

	getEditableCells() {
		const { marketGracePeriod } = this.props;

		return [
			...(marketGracePeriod.singleEdit
				? [
						{
							header: 'Market',
							value: <span>{marketGracePeriod.market}</span>,
						},
				  ]
				: []),
			{
				header: 'Early shift start',
				value: (
					<Input
						id="earlyShiftStart"
						type="number"
						placeholder="Enter early shift start..."
						value={marketGracePeriod.earlyShiftStart}
						onChange={(event) => this.editStoreEntry('earlyShiftStart', event)}
					/>
				),
			},
			{
				header: 'Early shift end',
				value: (
					<Input
						id="earlyShiftEnd"
						type="number"
						placeholder="Enter early shift end..."
						value={marketGracePeriod.earlyShiftEnd}
						onChange={(event) => this.editStoreEntry('earlyShiftEnd', event)}
					/>
				),
			},
			{
				header: 'Late shift start',
				value: (
					<Input
						id="lateShiftStart"
						type="number"
						placeholder="Enter late shift start..."
						value={marketGracePeriod.lateShiftStart}
						onChange={(event) => this.editStoreEntry('lateShiftStart', event)}
					/>
				),
			},
			{
				header: 'Late shift end',
				value: (
					<Input
						id="lateShiftEnd"
						type="number"
						placeholder="Enter late shift end..."
						value={marketGracePeriod.lateShiftEnd}
						onChange={(event) => this.editStoreEntry('lateShiftEnd', event)}
					/>
				),
			},
			{
				header: 'Break too short',
				value: (
					<Input
						id="breakTooShort"
						type="number"
						placeholder="Enter break too short..."
						value={marketGracePeriod.breakTooShort}
						onChange={(event) => this.editStoreEntry('breakTooShort', event)}
					/>
				),
			},
			{
				header: 'Break too long',
				value: (
					<Input
						id="breakTooLong"
						type="number"
						placeholder="Enter break too long..."
						value={marketGracePeriod.breakTooLong}
						onChange={(event) => this.editStoreEntry('breakTooLong', event)}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const value = e?.target?.value ?? e;

		const updateObject = {
			[name]: value,
		};

		this.props.updateMarketGracePeriods(updateObject);
	}

	fetchData(state) {
		return fetchMarketGracePeriods(state);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	addMarketGracePeriodWrapper(data) {
		return addMarketGracePeriod(data).then(() =>
			this.setState(() => ({
				key: moment.utc(),
			}))
		);
	}

	render() {
		const { batchList, editBatchListItem, addBatchListItemRow, removeBatchListItem, customFilters } = this.props;

		return (
			<>
				<ReactDataWrapper
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={reduxKey}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					accessAreasAllowedToEdit={['Global HR Configuration', 'Shiftplan Admin']}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					actionRender={
						<Button type="inverted" label="Batch" shadow onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
					extraFilters={customFilters?.market?.value ? `:market.id=='${customFilters.market.value}'` : ''}
				/>

				<MarketGracePeriodsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addMarketGracePeriod={this.addMarketGracePeriodWrapper}
				/>
			</>
		);
	}
}

MarketGracePeriods.propTypes = {
	marketGracePeriod: PropTypes.object,
	defaultMarketGracePeriod: PropTypes.object,
	updateMarketGracePeriods: PropTypes.func,
	setMarketGracePeriods: PropTypes.func,
	resetState: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	customFilters: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			editBatchListItem,
			removeBatchListItem,
			updateMarketGracePeriods,
			setMarketGracePeriods,
			resetState,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		marketGracePeriod: store.globalAdministrationMarketGracePeriods.data.marketGracePeriod,
		defaultMarketGracePeriod: store.globalAdministrationMarketGracePeriods.data.defaultMarketGracePeriod,
		batchList: store.globalAdministrationMarketGracePeriods.data.batchList,
		customFilters: store.filterSortColumnsData?.tables?.[reduxKey]?.custom,
	};
};

export default connectWithStore(MarketGracePeriods, mapStateToProps, mapDispatchToProps);
