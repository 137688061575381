import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DailyReportBaseModel from './dailyReportBaseModel.component';
import DailyReportMarket from './dailyReportMarket.component';
import sortBySortOrder from './../utilities/sortBySortOrder';
import _isEmpty from 'lodash/isEmpty';
import { store } from 'appState';

import { toggleExpandMarket } from './../dailyReport.actions';

// Components

import { Body, Row, Table, constants } from 'dumb/report';

class DailyReportMarketRegion extends Component {
	/**
	 * @method _onExpandHandler
	 * @memberof DailyReportMarketRegion
	 * @param {Object} entry - The clicked row
	 */
	_onExpandHandler(entry) {
		store.dispatch(toggleExpandMarket(entry.market.id));
	}

	_renderWithTerritories(
		territories,
		workplaces,
		groups,
		territoryStoreAreaTypeEntries,
		marketEntry,
		hotbarData
	) {
		const territoriesTable = territories.map((territoryEntry) => {
			const workplaceTerritories = workplaces.filter(
				(workplaceEntry) =>
					workplaceEntry.territory.id === territoryEntry.territory.id
			);

			const territoryGroup = groups.filter(
				(groupEntry) => groupEntry.territory.id === territoryEntry.territory.id
			);

			const territoryStoreAreaTypeEntriesFiltered = territoryStoreAreaTypeEntries.filter(
				(territoryStoreAreaTypeEntry) =>
					territoryStoreAreaTypeEntry.territory.id ===
					territoryEntry.territory.id
			);

			const isYoung =
				territoryEntry.territory.is_young || workplaceTerritories.length < 20;

			return (
				<Table key={territoryEntry.id}>
					<Body>
						<Row
							type="entry"
							totalColSpan={this.props.totalColSpan}
							onClick={() => this._onExpandHandler(marketEntry)}
							expanded
							renderExpandedHeader={territoryEntry.name}
							renderExpanded={() => (
								<DailyReportMarket
									totalColSpan={this.props.totalColSpan}
									workplaces={workplaceTerritories}
									isYoungMarket={isYoung}
									groups={territoryGroup}
									territoryStoreAreaTypeEntries={
										territoryStoreAreaTypeEntriesFiltered
									}
									expandedGroups={this.props.expandedGroups}
									hotbarData={hotbarData}
									reportData={this.props.reportData}
									total={territoryEntry}
								/>
							)}
						/>
					</Body>
				</Table>
			);
		});

		return (
			<>
				{territoriesTable}
				<Table>
					<Body>
						<Row type="table-total" borderBottom>
							<DailyReportBaseModel
								entry={marketEntry}
								reportType={hotbarData.perspective}
							/>
						</Row>
					</Body>
				</Table>
			</>
		);
	}

	_renderRows() {
		const {
			reportData,
			markets,
			totalColSpan,
			expandedMarkets,
			expandedGroups,
			hotbarData,
			forceExpandMarkets,
			forceExpandGroups,
		} = this.props;

		return !_isEmpty(markets)
			? markets.sort(sortBySortOrder).map((marketEntry, index) => {
					const workplaces = reportData.workplaces.filter(
						(reportDataWorkplace) =>
							reportDataWorkplace.market.id === marketEntry.market.id
					);

					const workplacesWithoutApp = workplaces.filter(
						(reportDataWorkplace) =>
							reportDataWorkplace.store_area_type.name !== 'App' &&
							reportDataWorkplace.store_area_type.name !== 'Undefined'
					);

					marketEntry = {
						...marketEntry,
						name: `${marketEntry.name.toUpperCase()} in ${
							marketEntry.currency_code
						} (${workplacesWithoutApp.length})`,
					};

					const territoryStoreAreaTypeEntries = (
						reportData.territoryStoreAreaTypeEntries || []
					).filter(
						(territoryStoreAreaTypeEntry) =>
							territoryStoreAreaTypeEntry.market.id === marketEntry.market.id
					);

					return (
						<Row
							key={marketEntry.id}
							index={index}
							type="entry"
							totalColSpan={totalColSpan}
							onClick={() => this._onExpandHandler(marketEntry)}
							expanded={
								expandedMarkets.includes(marketEntry.market.id) ||
								forceExpandMarkets
							}
							renderExpandedHeader={marketEntry.name}
							renderExpanded={() => {
								const territories = reportData.territories.filter(
									(reportDataWorkplace) =>
										reportDataWorkplace.market.id === marketEntry.market.id
								);

								const groups = (reportData.groups || []).filter(
									(reportDataWorkplace) =>
										reportDataWorkplace.market.id === marketEntry.market.id
								);

								if (territories.length > 1) {
									return this._renderWithTerritories(
										territories,
										workplaces,
										groups,
										territoryStoreAreaTypeEntries,
										marketEntry,
										hotbarData
									);
								} else
									return (
										<DailyReportMarket
											totalColSpan={totalColSpan}
											workplaces={workplaces}
											isYoungMarket={
												marketEntry.market.is_young || workplaces.length < 20
											}
											territories={territories}
											groups={groups}
											territoryStoreAreaTypeEntries={
												territoryStoreAreaTypeEntries
											}
											expandedGroups={expandedGroups}
											forceExpandGroups={forceExpandGroups}
											hotbarData={hotbarData}
											reportData={reportData}
											total={marketEntry}
										/>
									);
							}}>
							<DailyReportBaseModel
								entry={marketEntry}
								reportType={this.props.hotbarData.perspective}
							/>
						</Row>
					);
			  })
			: null;
	}

	_renderTotal() {
		if (_isEmpty(this.props.total)) return null;

		// Accumulate number of stores in a market region by going through markets in market region and finding workplaces
		var numberOfStores = this.props.markets.reduce((acc, marketEntry) => {
			const stores = this.props.reportData.workplaces.filter((workplaceEntry) => {
				return (
					workplaceEntry.market.id === marketEntry.market.id &&
					workplaceEntry.store_area_type.name !== 'App' &&
					workplaceEntry.store_area_type.name !== 'Undefined'
				);
			});

			return acc + stores.length;
		}, 0);

		const total = {
			...this.props.total,
			name: `${this.props.total.name.toUpperCase()} in ${
				this.props.total.currency_code
			} (${numberOfStores})`,
		};

		return (
			<Row key={this.props.total.id} type="sub-subtotal">
				<DailyReportBaseModel
					entry={total}
					reportType={this.props.hotbarData.perspective}
				/>
			</Row>
		);
	}

	_renderGroups() {
		return (this.props.marketRegionGroups || []).map((entry) => {
			return (
				<Row key={entry.id} type={constants.TABLE_TYPES.GROUP_SUBTOTAL}>
					<DailyReportBaseModel
						entry={entry}
						reportType={this.props.hotbarData.perspective}
					/>
				</Row>
			);
		});
	}

	_renderStoreAreaTypeEntries() {
		return (this.props.marketRegionStoreAreaTypeEntries || []).map(
			(entry, index, arr) => {
				return (
					<Row
						key={entry.id}
						type={constants.TABLE_TYPES.GROUP_SUBTOTAL}
						borderBottom={index === arr.length - 1}>
						<DailyReportBaseModel
							entry={entry}
							reportType={this.props.hotbarData.perspective}
						/>
					</Row>
				);
			}
		);
	}

	render() {
		return (
			<>
				{this._renderRows()}
				{this._renderTotal()}
				{this._renderGroups()}
				{this._renderStoreAreaTypeEntries()}
			</>
		);
	}
}

DailyReportMarketRegion.propTypes = {
	reportData: PropTypes.object,
	hotbarData: PropTypes.object,
	total: PropTypes.object,
	totalColSpan: PropTypes.number,
	markets: PropTypes.array,
	expandedMarkets: PropTypes.array,
	expandedGroups: PropTypes.array,
	marketRegionStoreAreaTypeEntries: PropTypes.array,
	territoryStoreAreaTypeEntries: PropTypes.array,
	marketRegionGroups: PropTypes.array,
	forceExpandMarkets: PropTypes.bool,
	forceExpandGroups: PropTypes.bool,
};

DailyReportMarketRegion.defaultProps = {
	reportData: {},
	total: {},
};

export default DailyReportMarketRegion;
