export const RESET_POS_TIER = 'GLOBAL_ADMINISTRATION_POS_TIERS/RESET_POS_TIER';
export const SET_POS_TIER = 'GLOBAL_ADMINISTRATION_POS_TIERS/SET_POS_TIER';
export const UPDATE_POS_TIER =
	'GLOBAL_ADMINISTRATION_POS_TIERS/UPDATE_POS_TIER';

export const UPDATE_BATCH_FORM = 'GLOBAL_ADMINISTRATION_POS_TIERS/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST = 'GLOBAL_ADMINISTRATION_POS_TIERS/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM = 'GLOBAL_ADMINISTRATION_POS_TIERS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_POS_TIERS/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'GLOBAL_ADMINISTRATION_POS_TIERS/ADD_BATCH_LIST_ITEM_ROW';

/**
 * @param {object} payload
 */
export function setPosTier(payload) {
	return {
		type: SET_POS_TIER,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updatePosTier(payload) {
	return {
		type: UPDATE_POS_TIER,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetPosTier() {
	return {
		type: RESET_POS_TIER
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW
	};
}
