export default {
	TABLE_TITLE: 'Ingredient components',
	GLOBAL_TABLE_TITLE: 'Global ingredient components',
	INGREDIENT_COMPONENTS_ADDED: 'Ingredient components added',
	INGREDIENT_COMPONENT_EDITED: 'Ingredient component edited',
	INGREDIENT_COMPONENTS_EDITED: 'Ingredient components edited',
	INGREDIENT_COMPONENTS_DELETED: 'Ingredient components deleted',

	// modal batch
	MODAL_BATCH_STEP_MARKET_PLACEHOLDER_GLOBAL: 'Select market to overwrite all entries...',
	MODAL_BATCH_STEP_LIST_TITLE: 'Add ingredient components',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_MARKET: 'Market',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_MARKET_PLACEHOLDER: 'Select market...',
	MODAL_BATCH_STEP_LIST_ADD_NEW_ROW_TOOLTIP: 'Add new row',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Submit',
};
