import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './marketOvertimeRules.phrases';

/**
 * @function fetchMarketOvertimeRules
 * @description Fetch all employee meal app allowances
 * @param {object} state
 */
export function fetchMarketOvertimeRules(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/salary/market_overtime_rules', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addMarketOvertimeRule
 * @description add employee meal app allowances
 */
export function addMarketOvertimeRule(data) {
	return post('/salary/market_overtime_rules', data)
		.then((res) => {
			setFeedback(phrases.MARKET_OVERTIME_RULE_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editMarketOvertimeRule
 * @description edit employee meal app allowance
 */
export function editMarketOvertimeRule(data) {
	return post(`/salary/market_overtime_rules/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.MARKET_OVERTIME_RULE_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function editMarketOvertimeRules
 * @description edit employee meal app allowances
 */
export function editMarketOvertimeRules(data) {
	return patch('/salary/market_overtime_rules', data)
		.then((res) => {
			setFeedback(phrases.MARKET_OVERTIME_RULES_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function deleteMarketOvertimeRule
 * @description delete employee meal app allowance
 */
export function deleteMarketOvertimeRule(id) {
	return remove(`/salary/market_overtime_rules/${id}`)
		.then((res) => {
			setFeedback(phrases.MARKET_OVERTIME_RULE_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
