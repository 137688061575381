'use strict';

import React from 'react';
import PropTypes from 'prop-types';

// components
import { Input, InputCollectionSelect } from 'dumb';
import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';
// table columns
import getPowerBiReportTypeColumns from 'reactDataWrapperColumns/reporting/powerBiReportTypes.columns';
import getMarketColumns from 'reactDataWrapperColumns/administration/markets.columns';

// phrases
import phrases from './../../../powerBIReports.phrases';

const CopyPowerBiReportModalGlobalList = ({ globalPowerBiReport, handleEditGlobalValue }) => {
	return (
		<div className="copy-power-bi-report-modal__section">
			<TableList>
				<thead>
					<tr>
						<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
						<th>{phrases.MODAL_BATCH_STEP_NAME}</th>
						<th>{phrases.MODAL_BATCH_STEP_DESCRIPTION}</th>
						<th>{phrases.MODAL_BATCH_STEP_TYPE}</th>
						<th>{phrases.MODAL_BATCH_STEP_MARKET}</th>
						<th>{phrases.MODAL_BATCH_STEP_SORT_ORDER}</th>
						<th>{phrases.MODAL_BATCH_STEP_POWER_BI_ID}</th>
						<th>{phrases.MODAL_BATCH_STEP_ACCESS_GRANTING_AREA}</th>
					</tr>
				</thead>
				<tbody>
					<TableListRow>
						<TableListCell width="30px" />

						<TableListCell width="180px">
							<Input
								id="name"
								placeholder={phrases.MODAL_BATCH_STEP_NAME_PLACEHOLDER}
								value={globalPowerBiReport.name}
								onChange={(event) => handleEditGlobalValue(event, 'name')}
							/>
						</TableListCell>

						<TableListCell width="180px">
							<Input
								id="description"
								placeholder={phrases.MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER}
								value={globalPowerBiReport.description}
								onChange={(event) => handleEditGlobalValue(event, 'description')}
							/>
						</TableListCell>

						<TableListCell width="200px">
							<InputCollectionSelect
								id="type"
								name="type"
								placeholder="Select type..."
								apiPath="/reporting/power_bi_report_types"
								value={globalPowerBiReport.type}
								handleChange={(key, event) => handleEditGlobalValue(event, 'type')}
								params={{
									limit: 30,
								}}
								cache
								optionFormat={(globalPowerBiReport) => ({
									value: globalPowerBiReport,
									label: globalPowerBiReport.name,
								})}
								inputFilterFormat={(input) => `:name=like='%${input}%'`}
								clearable={false}
								tableColumns={getPowerBiReportTypeColumns()}
								tableReduxKey="power-bi-reports/reporting/power_bi_report_types"
								tableTitle={phrases.MODAL_BATCH_STEP_TYPES}
							/>
						</TableListCell>

						<TableListCell width="200px">
							<InputCollectionSelect
								id="market"
								name="market"
								placeholder="Select market..."
								apiPath="/administration/markets"
								value={globalPowerBiReport.market}
								handleChange={(key, event) => handleEditGlobalValue(event, 'market')}
								params={{
									limit: 30,
								}}
								cache
								optionFormat={(globalPowerBiReport) => ({
									value: globalPowerBiReport,
									label: globalPowerBiReport.name,
								})}
								inputFilterFormat={(input) => `:name=like='%${input}%'`}
								clearable={false}
								tableColumns={getMarketColumns('power-bi-reports-copy-report/administration/markets')}
								tableReduxKey="power-bi-reports-copy-report/administration/markets"
								tableTitle={phrases.MODAL_BATCH_STEP_MARKETS}
							/>
						</TableListCell>

						<TableListCell width="180px">
							<Input
								id="sortOrder"
								type="number"
								placeholder={phrases.MODAL_BATCH_STEP_SORT_ORDER_PLACEHOLDER}
								value={globalPowerBiReport.sortOrder}
								onChange={(event) => handleEditGlobalValue(event, 'sortOrder')}
							/>
						</TableListCell>

						<TableListCell width="180px">
							<Input
								id="powerBiId"
								placeholder={phrases.MODAL_BATCH_STEP_POWER_BI_ID_PLACEHOLDER}
								value={globalPowerBiReport.powerBiId}
								onChange={(event) => handleEditGlobalValue(event, 'powerBiId')}
							/>
						</TableListCell>

						<TableListCell width="200px">
							<InputCollectionSelect
								id="accessGrantingArea"
								placeholder={phrases.MODAL_BATCH_STEP_ACCESS_GRANTING_AREA_PLACEHOLDER}
								apiPath="/authentication/areas"
								value={globalPowerBiReport.accessGrantingArea}
								handleChange={(key, event) => handleEditGlobalValue(event, 'accessGrantingArea')}
								params={{
									limit: 30,
								}}
								cache
								optionFormat={(globalPowerBiReport) => ({
									value: globalPowerBiReport,
									label: globalPowerBiReport.name,
								})}
								inputFilterFormat={(input) => `:name=like='%${input}%'`}
								// little hack since they share the same columns
								tableColumns={getPowerBiReportTypeColumns()}
								tableReduxKey="power-bi-reports-global-copy/authentication/areas"
								tableTitle={phrases.MODAL_BATCH_STEP_ACCESS_GRANTING_AREAS}
							/>
						</TableListCell>
					</TableListRow>
				</tbody>
			</TableList>
		</div>
	);
};

CopyPowerBiReportModalGlobalList.propTypes = {
	globalPowerBiReport: PropTypes.object,
	handleEditGlobalValue: PropTypes.func,
};

export default CopyPowerBiReportModalGlobalList;
