export default {
	TITLE: 'Tags',

	TYPE: 'Type',
	TYPE_PLACEHOLDER: 'Select type...',
	SUB_TYPE: 'Subtype',
	SUB_TYPE_PLACEHOLDER: 'Select subtype...',
	NAME: 'Name',
	NAME_PLACEHOLDER: 'Enter name...',
	NAME_ABBREVIATED: 'Name abbreviated ',
	NAME_ABBREVIATED_PLACEHOLDER: 'Enter name abbreviated...',
	GLOBAL_ACCOUNT: 'Global account',
	GLOBAL_ACCOUNT_PLACEHOLDER: 'Select global account...',
	EXCLUDE_IN_PHP: 'Exclude in PHP',
	EXCLUDE_BASE_PAY: 'Exclude base pay',
	EXCLUDE_ADDON: 'Exclude addon',
	EXCLUDE_OVERTIME: 'Exclude overtime',
	CURRENT_YEAR_INDEX: 'Current year index',
	PRIOR_YEAR_INDEX: 'Prior year index',
	COLOR: 'Color',
};
