/* eslint-disable react/prop-types */
import React from 'react';

// components
import { InputCollectionSelect } from 'dumb';
import { DateFilterSelector, FilterProvider } from 'reactDataWrapper/utilities';

import { IColumnHeader } from 'types/reactDataWrapper';
import { SelectOption } from 'types/select';

// enums
import collectionSelectEnums from 'services/enums/collectionSelect';

export default function getColumns(
	reduxKey: string
): IColumnHeader<IAdministrationCompany>[] {
	const columns: IColumnHeader<IAdministrationCompany>[] = [
		{
			Header: 'Name',
			id: 'name',
			accessor: 'name',
			filterPath: ':name',
		},
		{
			Header: 'Account schedule',
			id: 'account-schedule',
			accessor: (d) => d.company_account_schedule?.account_schedule?.name ?? '',
			filterPath: ':company_account_schedule.account_schedule.name',
		},
		{
			Header: 'Market',
			id: 'market',
			accessor: (d) => d.market.name,
			filterPath: ':market.name',
			Filter: ({ column }) => (
				<FilterProvider
					reduxKey={reduxKey}
					columnId={column.id}
					provide={(
						filterValue: SelectOption,
						persistToFilterStorage: ({
							handle,
							value,
						}: {
							handle: string;
							value: SelectOption;
						}) => void
					): JSX.Element => (
						<InputCollectionSelect
							id={column.id}
							name="market"
							value={filterValue}
							handleChange={(key: string, value: SelectOption): void => {
								persistToFilterStorage({ handle: key, value });
							}}
							styleType={collectionSelectEnums.TYPE_IN_TABLE}
							cache
							apiPath="/administration/markets"
							params={{
								limit: 30,
							}}
							optionFormat={(entry: IAdministrationMarket): SelectOption => ({
								value: entry.id,
								label: entry.name,
							})}
							inputFilterFormat={(input: string): string =>
								`:name=like='%${input}%'`
							}
						/>
					)}
				/>
			),
		},
		{
			Header: 'Opened',
			id: 'opened',
			accessor: 'opened',
			filterPath: ':opened',
			Filter: ({ column }) => (
				<DateFilterSelector
					reduxKey={reduxKey}
					columnId={column.id}
					dateIdentifier="from"
				/>
			),
		},
		{
			Header: 'Closed',
			id: 'closed',
			accessor: 'closed',
			filterPath: ':closed',
			Filter: ({ column }) => (
				<DateFilterSelector
					reduxKey={reduxKey}
					columnId={column.id}
					dateIdentifier="from"
				/>
			),
		},
		{
			Header: 'Main entity',
			id: 'main-entity',
			accessor: 'main_entity',
			filterPath: ':main_entity',
		},
		{
			Header: 'Legal entity code',
			id: 'legal-entity-code',
			accessor: 'legal_entity_code',
			filterPath: ':legal_entity_code',
		},
		{
			Header: 'Merchant account',
			id: 'merchant-account',
			accessor: 'merchant_account',
			filterPath: ':merchant_account',
		},
	];

	return columns;
}
