// interface
import { ActionType } from 'types/redux';
import {
	IDynamicAccountReduxValue,
	IDynamicAccountReduxState,
	IDynamicAccountReduxValueBatch,
} from '../types';

// utils
import moment from 'moment';
import _uniqueId from 'lodash/uniqueId';

import {
	SET_DYNAMIC_ACCOUNT,
	UPDATE_DYNAMIC_ACCOUNT,
	RESET_STATE,

	// batch
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	ADD_BATCH_LIST_ITEM_ROW,
	RERENDER_TABLE,
} from './dynamicAccounts.actions';

const defaultState: IDynamicAccountReduxState = {
	tableKey: moment().unix(),
	data: {
		defaultDynamicAccount: null,
		dynamicAccount: {
			accountNumber: null,
			type: null,
			entryType: null,
		},
	},
	batchList: [
		{
			identifier: _uniqueId('row-'),
			accountNumber: null,
			type: null,
			entryType: null,
		},
	],
};

function reducer(
	state = defaultState,
	action: ActionType
): IDynamicAccountReduxState {
	const { type, payload } = action;

	switch (type) {
	case SET_DYNAMIC_ACCOUNT: {
		const castedPayload = payload as IDynamicAccountReduxValue;

		return {
				...state,
				data: {
					dynamicAccount: castedPayload,
					defaultDynamicAccount: castedPayload,
				},
			};
	}

	case UPDATE_DYNAMIC_ACCOUNT: {
		const castedPayload = payload as Partial<IDynamicAccountReduxValue>;

		return {
				...state,
				data: {
					...state.data,
					dynamicAccount: {
						...state.data.dynamicAccount,
						...castedPayload,
					},
				},
			};
	}

	case RESET_STATE: {
		return defaultState;
	}

	case RERENDER_TABLE:
		return {
				...state,
				tableKey: moment().unix(),
			};

	case EDIT_BATCH_LIST_ITEM: {
		const castedPayload = payload as Partial<IDynamicAccountReduxValueBatch>;

		return {
				...state,
				batchList: state.batchList.map((entry) => {
					if (entry.identifier === castedPayload.identifier) {
						return { ...entry, ...castedPayload };
					}
					return entry;
				}),
			};
	}

	case REMOVE_BATCH_LIST_ITEM: {
		const castedPayload = payload as string;

		return {
				...state,
				batchList: state.batchList.filter(
					(entry) => entry.identifier !== castedPayload
				),
			};
	}

	case ADD_BATCH_LIST_ITEM_ROW:
		return {
				...state,
				batchList: [
					...state.batchList,
					{
						...defaultState.batchList[0],
						identifier: _uniqueId('row-'),
					},
				],
			};

	default:
		return state;
	}
}

export default reducer;
