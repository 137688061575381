'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import moment from 'moment';
import {
	// batch stuff
	updateBatchForm,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setEmployeeMealAppAllowance,
	updateEmployeeMealAppAllowance,
	resetState,
} from './store/employeeMealAppAllowances.actions';

// services
import {
	fetchEmployeeMealAppAllowances,
	editEmployeeMealAppAllowance,
	editEmployeeMealAppAllowances,
	addEmployeeMealAppAllowance,
	deleteEmployeeMealAppAllowance,
} from './employeeMealAppAllowances.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Input, Button, Icon } from 'dumb';

import EmployeeMealAppAllowancesModalBatch from './components/batch/employeeMealAppAllowancesModalBatch';

// phrases/constants
import phrases from './employeeMealAppAllowances.phrases';

class EmployeeMealAppAllowances extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.addEmployeeMealAppAllowancesWrapper = this.addEmployeeMealAppAllowancesWrapper.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.editEntry = this.editEntry.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: 'name',
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'desc',
				accessor: 'description',
				filterPath: ':description',
			},
			{
				Header: 'Coffee',
				id: 'coffee',
				accessor: 'coffee',
				filterPath: ':coffee',
			},
			{
				Header: 'Any item',
				id: 'anyItem',
				accessor: 'any_item',
				filterPath: ':any_item',
			},
		];
	}

	editEntry() {
		const { defaultEmployeeMealAppAllowance } = this.props;

		const payload = {
			id: defaultEmployeeMealAppAllowance.id,
			name: defaultEmployeeMealAppAllowance.name,
			description: defaultEmployeeMealAppAllowance.description,
			coffee: defaultEmployeeMealAppAllowance.coffee,
			any_item: defaultEmployeeMealAppAllowance.anyItem,
		};

		return editEmployeeMealAppAllowance(payload);
	}

	editMultiple(selectedRows) {
		const { defaultEmployeeMealAppAllowance } = this.props;

		const payload = {
			...(defaultEmployeeMealAppAllowance.name && {
				name: defaultEmployeeMealAppAllowance.name,
			}),
			...(defaultEmployeeMealAppAllowance.description && {
				description: defaultEmployeeMealAppAllowance.description,
			}),
			...(defaultEmployeeMealAppAllowance.coffee && {
				coffee: defaultEmployeeMealAppAllowance.coffee,
			}),
			...(defaultEmployeeMealAppAllowance.anyItem && {
				any_item: defaultEmployeeMealAppAllowance.anyItem,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editEmployeeMealAppAllowances({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteEmployeeMealAppAllowance(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			coffee: data.coffee,
			anyItem: data.any_item,
		};

		this.props.setEmployeeMealAppAllowance(payload);
	}

	getEditableCells() {
		const { defaultEmployeeMealAppAllowance } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={defaultEmployeeMealAppAllowance.name || ''}
						onChange={(event) => this.editStoreEntry('name', event)}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={defaultEmployeeMealAppAllowance.description || ''}
						onChange={(event) => this.editStoreEntry('description', event)}
					/>
				),
			},
			{
				header: 'Coffee',
				value: (
					<Input
						id="coffee"
						type="number"
						placeholder="Enter coffee..."
						value={defaultEmployeeMealAppAllowance.coffee || ''}
						onChange={(event) => this.editStoreEntry('coffee', event)}
					/>
				),
			},
			{
				header: 'Any item',
				value: (
					<Input
						id="anyItem"
						type="number"
						placeholder="Any item..."
						value={defaultEmployeeMealAppAllowance.anyItem || ''}
						onChange={(event) => this.editStoreEntry('anyItem', event)}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const value = e.target.value;

		const updateObject = {
			[name]: value,
		};

		this.props.updateEmployeeMealAppAllowance(updateObject);
	}

	fetchData(state) {
		return fetchEmployeeMealAppAllowances(state);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	addEmployeeMealAppAllowancesWrapper(data) {
		return addEmployeeMealAppAllowance(data).then(() =>
			this.setState(() => ({
				key: moment.utc(),
			}))
		);
	}

	render() {
		const { batchList, editBatchListItem, addBatchListItemRow, removeBatchListItem } = this.props;

		return (
			<>
				<ReactDataWrapper
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey="/hr/employee_meal_app_allowances"
					manual
					accessAreasAllowedToEdit={['Global HR Configuration']}
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					actionRender={
						<Button type="inverted" label="Batch" shadow onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
				/>

				<EmployeeMealAppAllowancesModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addEmployeeMealAppAllowance={this.addEmployeeMealAppAllowancesWrapper}
				/>
			</>
		);
	}
}

EmployeeMealAppAllowances.propTypes = {
	defaultEmployeeMealAppAllowance: PropTypes.object,

	updateEmployeeMealAppAllowance: PropTypes.func,
	setEmployeeMealAppAllowance: PropTypes.func,
	resetState: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			editBatchListItem,
			removeBatchListItem,
			updateEmployeeMealAppAllowance,
			setEmployeeMealAppAllowance,
			resetState,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultEmployeeMealAppAllowance:
			store.globalAdministrationEmployeeMealAppAllowances.data.defaultEmployeeMealAppAllowance,
		batchList: store.globalAdministrationEmployeeMealAppAllowances.data.batchList,
	};
};

export default connectWithStore(EmployeeMealAppAllowances, mapStateToProps, mapDispatchToProps);
