export default {
	PRODUCT_VARIANT_INGREDIENTS: 'Ingredients',
	PRODUCT_VARIANT_ADDONS: 'Addons',
	PRODUCT_VARIANT: 'Product variant',
	PRODUCT_DISCOUNT_SETS: 'Discount set',
	PRODUCT_VARIANT_PRICINGS: 'Pricings',
	GLOBAL_PRODUCT_DISCOUNTS: 'Global product discounts',
	PRODUCT_VARIANT_WORKPLACE_RELATION: 'Product variant workplace relations',
	TILE_LAYOUT_TILES: 'Layouts',
	TRANSLATIONS: 'Translations',
	NUTRITION_ALLERGEN_TOOLTIP: 'Click to view nutrition/allergen info',
	NUTRITION_ALLERGEN_LABEL: 'Nutrition',
};
