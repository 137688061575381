'use strict';

import React from 'react';
import PropTypes from 'prop-types';

// components
import { Input, InputCollectionSelect } from 'dumb';
import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';
// table columns
import getPowerBiReportTypeColumns from 'reactDataWrapperColumns/reporting/powerBiReportTypes.columns';
import getMarketColumns from 'reactDataWrapperColumns/administration/markets.columns';

// phrases
import phrases from './../../../powerBIReports.phrases';

const CopyPowerBiReportModalSelectedReports = ({ selectedReports, handleEditSelectedValue }) => {
	return (
		<div className="copy-power-bi-report-modal__section">
			<TableList>
				<thead>
					<tr>
						<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
						<th>{phrases.MODAL_BATCH_STEP_NAME}</th>
						<th>{phrases.MODAL_BATCH_STEP_DESCRIPTION}</th>
						<th>{phrases.MODAL_BATCH_STEP_TYPE}</th>
						<th>{phrases.MODAL_BATCH_STEP_MARKET}</th>
						<th>{phrases.MODAL_BATCH_STEP_SORT_ORDER}</th>
						<th>{phrases.MODAL_BATCH_STEP_POWER_BI_ID}</th>
						<th>{phrases.MODAL_BATCH_STEP_ACCESS_GRANTING_AREA}</th>
					</tr>
				</thead>
				<tbody>
					{selectedReports.map((entry, i) => {
						const marketReduxKey = `power-bi-reports-${entry.id}/administration/markets`;

						return (
							<TableListRow key={entry.id}>
								<TableListCell width="30px">{i + 1}</TableListCell>

								<TableListCell width="180px">
									<Input
										id={`name-${entry.id}`}
										placeholder={phrases.MODAL_BATCH_STEP_NAME_PLACEHOLDER}
										value={entry.name || ''}
										onChange={(event) => handleEditSelectedValue(event, 'name', entry.id)}
									/>
								</TableListCell>

								<TableListCell width="180px">
									<Input
										id={`description-${entry.id}`}
										placeholder={phrases.MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER}
										value={entry.description || ''}
										onChange={(event) => handleEditSelectedValue(event, 'description', entry.id)}
									/>
								</TableListCell>

								<TableListCell width="200px">
									<InputCollectionSelect
										id="type"
										name="type"
										placeholder="Select type..."
										apiPath="/reporting/power_bi_report_types"
										value={entry.type}
										handleChange={(key, event) => handleEditSelectedValue(event, 'type', entry.id)}
										params={{
											limit: 30,
										}}
										cache
										optionFormat={(entry) => ({
											value: entry,
											label: entry.name,
										})}
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
										clearable={false}
										tableColumns={getPowerBiReportTypeColumns()}
										tableReduxKey={`power-bi-reports-${entry.id}/reporting/power_bi_report_types`}
										tableTitle={phrases.MODAL_BATCH_STEP_TYPES}
									/>
								</TableListCell>

								<TableListCell width="200px">
									<InputCollectionSelect
										id="market"
										name="market"
										placeholder="Select market..."
										apiPath="/administration/markets"
										value={entry.market}
										handleChange={(key, event) => handleEditSelectedValue(event, 'market', entry.id)}
										params={{
											limit: 30,
										}}
										cache
										optionFormat={(entry) => ({
											value: entry,
											label: entry.name,
										})}
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
										clearable={false}
										tableColumns={getMarketColumns(marketReduxKey)}
										tableReduxKey={marketReduxKey}
										tableTitle={phrases.MODAL_BATCH_STEP_MARKETS}
									/>
								</TableListCell>

								<TableListCell width="180px">
									<Input
										id={`sortOrder-${entry.id}`}
										type="number"
										placeholder={phrases.MODAL_BATCH_STEP_SORT_ORDER_PLACEHOLDER}
										value={entry.sortOrder || ''}
										onChange={(event) => handleEditSelectedValue(event, 'sortOrder', entry.id)}
									/>
								</TableListCell>

								<TableListCell width="180px">
									<Input
										id={`powerBIID-${entry.id}`}
										placeholder={
											phrases.MODAL_BATCH_STEP_POWER_BI_ID_PLACEHOLDER
										}
										value={entry.powerBiId || ''}
										onChange={(event) =>
											handleEditSelectedValue(event, 'powerBiId', entry.id)
										}
									/>
								</TableListCell>

								<TableListCell width="200px">
									<InputCollectionSelect
										id={`accessGrantingArea-${entry.id}`}
										placeholder={phrases.MODAL_BATCH_STEP_ACCESS_GRANTING_AREA_PLACEHOLDER}
										apiPath="/authentication/areas"
										value={entry.accessGrantingArea}
										handleChange={(key, event) => handleEditSelectedValue(event, 'accessGrantingArea', entry.id)}
										params={{
											limit: 30,
										}}
										cache
										optionFormat={(entry) => ({
											value: entry,
											label: entry.name,
										})}
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
										// little hack since they share the same columns
										tableColumns={getPowerBiReportTypeColumns()}
										tableReduxKey={`power-bi-reports-${entry.id}/authentication/areas`}
										tableTitle={phrases.MODAL_BATCH_STEP_ACCESS_GRANTING_AREAS}
									/>
								</TableListCell>
							</TableListRow>
						);
					})}
				</tbody>
			</TableList>
		</div>
	);
};

CopyPowerBiReportModalSelectedReports.propTypes = {
	selectedReports: PropTypes.array,
	handleEditSelectedValue: PropTypes.func,
};

export default CopyPowerBiReportModalSelectedReports;
