import { SET_ONBOARDING_DATA, RESET_ONBOARDING_DATA } from './createOnboardings.actions';

const defaultState = {
	fetching: false,
	onboardingData: {},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
		case SET_ONBOARDING_DATA: {
			return {
				...state,
				onboardingData: {
					...state.onboardingData,
					[payload.key]: payload.val,
				},
			};
		}

		case RESET_ONBOARDING_DATA: {
			return {
				...state,
				onboardingData: defaultState.onboardingData,
			};
		}

		default:
			return state;
	}
}

export default reducer;
