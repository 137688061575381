'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';
const __endpoints = require('endpoints.service');

export default class WorkplaceAttributesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'type',
				type: 'select',
				canAdd: true,
				canEdit: true,
				required: true,
				reference: {
					key: 'name',
					list: '/administration/workplace_attribute_types',
				},
			},
			{
				name: 'value',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
		];

		this.settings = {
			name: 'WorkplaceWorkplaceAttributesList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			canCollapse: true,
			alwaysRefresh: true,
			canManipulate: () => validAccess(undefined, ['Organisation/Workplaces::Edit', 'Organization Admin']),
			translationPath: 'ADMINISTRATION.WORKPLACE_ATTRIBUTES',
			endpoint: __endpoints.collection.administration.workplace_attributes,
		};
		this.init();
		return this;
	}
}
