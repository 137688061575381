export const RESET_DATA = 'POS_CONFIGURATION_TILE_LAYOUTS/RESET_DATA';
export const SET_DATA = 'POS_CONFIGURATION_TILE_LAYOUTS/SET_DATA';
export const UPDATE_DATA =
	'POS_CONFIGURATION_TILE_LAYOUTS/UPDATE_DATA';

export function setLanguages(payload) {
	return {
		type: SET_DATA,
		payload,
	};
}

export function updateLanguages(payload) {
	return {
		type: UPDATE_DATA,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_DATA,
	};
}
