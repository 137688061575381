'use strict';

import React, { PureComponent } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Date from 'reusableComponents/inputs/date/date.component';
import Time from 'reusableComponents/inputs/time.component';
import DateTime from 'reusableComponents/inputs/dateTime.component';
import Select from 'reusableComponents/inputs/select.component';
import Checkbox from 'reusableComponents/inputs/checkbox.legacy.component';
import Color from 'reusableComponents/inputs/color.component';
import DefaultInput from 'reusableComponents/inputs/default.component';
import PasswordInput from 'reusableComponents/inputs/password.component';

import _get from 'lodash/get';
import _has from 'lodash/has';
import _isUndefined from 'lodash/isUndefined';
import _every from 'lodash/every';
import _isNull from 'lodash/isNull';
import _isFunction from 'lodash/isFunction';
import _findKey from 'lodash/findKey';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _isNumber from 'lodash/isNumber';
import _some from 'lodash/some';
import _debounce from 'lodash/debounce';
import _isNil from 'lodash/isNil';

import './dataInput.css';

class DataInput extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			value: '',
			password: '',
			passwordCheck: '',
			isTouched: false,
		};

		this.sendState = this.sendState.bind(this);
		this.getValue = this.getValue.bind(this);
		this.isTouched = this.isTouched.bind(this);
		this.getValidity = this.getValidity.bind(this);

		this._setValue = this._setValue.bind(this);
		this._triggerSubmit = this._triggerSubmit.bind(this);
	}

	/**
	 * @function sendState
	 * @memberOf COMPONENTS.dataInput
	 * @description Sends changed state data to angular
	 */
	sendState(value) {
		var args = {
			header: this.props.header,
			value,
		};
		if (_has(this.props.methods, 'handleInputEvents'))
			this.props.methods.handleInputEvents(args);
	}

	/**
	 * @function getValue
	 * @memberOf COMPONENTS.dataInput
	 * @description Gets value of current input field
	 * @return {string}
	 */
	getValue() {
		return this.state.value;
	}

	/**
	 * @function isTouched
	 * @memberOf COMPONENTS.dataInput
	 * @description Is field touched
	 * @return {bool}
	 */
	isTouched() {
		return this.state.isTouched;
	}

	/**
	 * @function getDefaultData
	 * @memberOf COMPONENTS.dataInput
	 * @description Gets default data given to current header
	 * @return {object}
	 */
	getDefaultData() {
		return this.props.header;
	}

	/**
	 * @function getValidity
	 * @memberOf COMPONENTS.dataInput
	 * @description
	 * - Gets validity of current input field based on required, password and regexs
	 * - Special case for header type password if editing item, as this should be able to be left untouched.
	 * @return {bool}
	 */
	getValidity() {
		var reqValid;

		// Special case:
		// If this.props.isEditing and this.props.header.type === password
		// We only validate for empty if password is actually touched (changed)
		if (this.props.isEditing && this._getInputFamily() === 'password') {
			reqValid = this.state.isTouched ? !!this.getValue() : true;
		}

		// Default is just normal validation on 'required and present'
		else {
			reqValid = _has(this.props.header, 'required')
				? !_isNil(this.getValue())
				: true;
		}

		const value =
			this.state.value && this.state.isTouched
				? this.state.value
				: this.props.header.defaultValue;

		// Set up all validation expressions
		var validationExpressions = [
			reqValid,
			_has(this.props.header, 'regex') &&
			_has(this.state, 'value') &&
			!_isUndefined(value) &&
			!_isNull(value) &&
			value.length > 0
				? this._validateRegExp(this.props.header.regex, this.getValue())
				: true,
			this._getInputFamily() === 'password' ? this._validatePassword() : true,
		];

		// Are they (all) true?
		return _every(validationExpressions);
	}

	/**
	 * @function _triggerSubmit
	 * @memberOf COMPONENTS.dataInput
	 * @description Triggers submit via keycode enter from parent element if existing
	 */
	_triggerSubmit(event) {
		if (_isFunction(this.props.submit) && event.nativeEvent.keyCode === 13) {
			this.props.submit();
		}
	}

	/**
	 * @function _validateRegExp
	 * @memberOf COMPONENTS.dataInput
	 * @description Validates regular expressions if needed
	 */
	_validateRegExp(regex, value) {
		var valid = regex.expression.test(value);
		return valid;
	}

	/**
	 * @function _validatePassword
	 * @memberOf COMPONENTS.dataInput
	 * @description Validates password if needed
	 */
	_validatePassword() {
		return this.state.password === this.state.passwordCheck;
	}

	/**
	 * @function _setValue
	 * @memberOf COMPONENTS.dataInput
	 * @description Sets value as state based on input family
	 */
	_setValue(event) {
		var inputFamily = this._getInputFamily();
		switch (inputFamily) {
			case 'input':
				this.setState({ value: event.target.value });
				this.sendState({ value: event.target.value });
				break;
			case 'checkbox':
				this.setState({ value: event.target.checked });
				this.sendState({ value: event.target.checked });
				break;
			case 'time':
				this.setState({ value: event });
				this.sendState({ value: event });
				break;
			case 'date':
				this.setState({ value: event });
				this.sendState({ value: event });
				break;
			case 'select':
				this.setState({ value: event });
				this.sendState({ value: event });
				break;
			case 'password':
				this.setState({
					value: event.target.value,
					password: this.refs.password.refs.password.value,
					passwordCheck: this.refs.password.refs.passwordCheck.value,
				});
				this.sendState({ value: event.target.value });
				break;
			case 'color':
				this.setState({ value: event.hex.substr(1) });
				this.sendState({ value: event.hex.substr(1) });
				break;
		}
		this.setState({ isTouched: true });
	}

	/**
	 * @function _getInputFamily
	 * @memberOf COMPONENTS.dataInput
	 * @description
	 * Gets input family for current input type to minimize number of 'ifs'
	 * as some of the input types are treated the same styling wise
	 */
	_getInputFamily() {
		var type = this.props.header.type;
		var families = {
			checkbox: ['bool'],
			date: ['date', 'datetime'],
			time: ['time', 'timetime'],
			input: [
				'string',
				'longString',
				'mail',
				'tel',
				'number',
				'currency',
				'existingPassword',
			],
			password: ['password'],
			select: ['multi', 'select'],
			color: ['color'],
		};
		return _findKey(families, function (value, key) {
			if (_includes(value, type)) {
				return key;
			}
		});
	}

	/**
	 * @function _getDefaultValue
	 * @memberOf COMPONENTS.dataInput
	 * @description
	 * Gets default value of any given type input field. Returns empty if not
	 * this.props.isEditing.
	 */
	_getDefaultValue() {
		var inputFamily = this._getInputFamily();
		if (this.props.isEditing) {
			var name = _has(this.props.header, 'reference')
				? this.props.header.reference.newName
				: this.props.header.name;
			switch (inputFamily) {
				case 'input':
					return this.props.item[name];
				case 'checkbox':
					return this.props.item[name];
				case 'date':
					return this.props.item[name];
				case 'time':
					if (_isNull(this.props.item[name])) return moment().startOf('day');
					else return this.props.item[name];
				case 'password':
					return ['12345678', '87654321'];
				case 'select':
					// We must support different type of select options
					// 1: return default value if null
					// 2: options coming from service __enums
					// default: options coming from API via endpoint
					// At some point also multi add functionality for tags, but not yet
					if (_isNull(this.props.item[name])) {
						return '';
					} else if (_has(this.props.header.options, 'enums')) {
						if (
							_has(this.props.item, name) &&
							(!_isEmpty(this.props.item[name]) ||
								_isNumber(this.props.item[name]))
						) {
							return {
								value: this.props.item[name],
								label: this.props.header.options.enums.find(
									({ value }) => value === this.props.item[name]
								)?.label,
							};
						}
					} else if (this.props.item[this.props.header.name]) {
						return {
							value: this.props.item[this.props.header.name].id,
							label: this.props.item[name],
						};
					} else {
						return {
							value: '',
						};
					}
					break;
				case 'color':
					return this.props.item[name];
			}
		} else {
			switch (inputFamily) {
				case 'password':
					return ['', ''];
				case 'checkbox':
					return this.props.header.defaultValue || false;
				default:
					if (_has(this.props.header, 'defaultValue')) {
						this.setState({ isTouched: true });
						return this.props.header.defaultValue;
					} else return null;
			}
		}
	}

	/**
	 * @function _setDefaultState
	 * @memberOf COMPONENTS.dataInput
	 * @description
	 * Sets default state values if this.props.isEditing.item
	 */
	_setDefaultState() {
		var inputFamily = this._getInputFamily();
		if (this.props.isEditing || _has(this.props.header, 'defaultValue')) {
			switch (inputFamily) {
				case 'password':
					this.setState({ value: '' });
					break;
				case 'date': {
					const defaultValue = this._getDefaultValue();
					this.setState({
						value: _isNull(defaultValue) ? defaultValue : moment(defaultValue),
						date: _isNull(defaultValue) ? defaultValue : moment(defaultValue),
					});
					break;
				}
				case 'time':
					this.setState({
						value: moment(this._getDefaultValue(), 'HH-mm-ss'),
						time: moment(this._getDefaultValue(), 'HH-mm-ss'),
					});
					break;
				default:
					this.setState({ value: this._getDefaultValue() });
					break;
			}
		} else {
			switch (inputFamily) {
				case 'checkbox':
					if (_has(this.props.header, 'defaultValue')) {
						this.setState({ isTouched: true });
						return this.props.header.defaultValue;
					} else {
						this.setState({ value: false });
					}

					break;
			}
		}
	}

	/**
	 * @function _inputClassNames
	 * @memberOf COMPONENTS.dataInput
	 * @description Class names from parent react component
	 */
	_inputClassNames() {
		var inputFamily = this._getInputFamily();
		var validityExpressions = [
			this.getValidity() === false,
			_get(this.props, 'header.fieldError', false),
		];
		var inputClassNames = cx(this.props.modifierClassName, 'data-input', {
			'data-input__input':
				inputFamily === 'input' || inputFamily === 'password',
			'data-input--currency': this.props.header.type === 'currency',
			'state--invalid': _some(validityExpressions),
			'state--untouched': !this.state.isTouched,
		});
		return inputClassNames;
	}

	/**
	 * @function _feedbackClassNames
	 * @memberOf COMPONENTS.dataInput
	 * @description Class names for feedback element
	 */
	_feedbackClassNames() {
		return cx('data-input__feedback', {
			'data-input__feedback--error':
				(this.getValidity() === false || this.props.header.fieldError) &&
				this.props.submitted,
		});
	}

	/**
	 * @function _renderInputContents
	 * @memberOf COMPONENTS.dataInput
	 * @description Renders contents of header based on type.
	 * @param  {object} header Object conta ining header data which comes from header (e.g. type = string, date etc.)
	 */
	_renderInputContents(header) {
		if (!_isUndefined(header)) {
			var name = _has(header, 'reference')
				? header.reference.newName
				: header.name;
			var inputFamily = this._getInputFamily();
			switch (inputFamily) {
				case 'time':
					return (
						<Time
							showMinutes
							onChange={this._setValue}
							initialTime={
								moment.isMoment(this.state.value) ? this.state.value : null
							}
						/>
					);
				case 'date':
					if (header.type === 'datetime')
						return (
							<DateTime
								onChange={this._setValue}
								initialDateTime={
									moment.isMoment(this.state.value) ? this.state.value : null
								}
							/>
						);
					else
						return (
							<Date
								onChange={this._setValue}
								type="single"
								showClearDate
								disabled={this.props.disabled}
								initialDate={
									moment.isMoment(this.state.value) ? this.state.value : null
								}
							/>
						);
				case 'checkbox':
					return (
						<Checkbox
							onChange={this._setValue}
							withBorder
							initialValue={this._getDefaultValue()}
						/>
					);
				case 'password':
					return (
						<PasswordInput
							{...this.props}
							header={header}
							onChange={this._setValue}
							onKeyDown={this._triggerSubmit}
							disabled={this.props.disabled}
							defaultValue={this._getDefaultValue()}
							ref="password"
						/>
					);
				case 'select':
					return (
						<Select
							labelTemplate={
								_has(header.reference, 'label')
									? header.reference.label
									: '<%= name %>'
							}
							name={name}
							disabled={this.props.disabled}
							onChange={this._setValue}
							options={
								_has(header, 'options') && _has(header.options, 'options')
									? header.options.options
									: undefined
							}
							searchFields={
								_has(header.reference, 'searchFields')
									? header.reference.searchFields
									: undefined
							}
							searchList={
								_has(header.reference, 'list')
									? header.reference.list
									: undefined
							}
							searchListData={
								_has(header.reference, 'searchListData')
									? header.reference.searchListData
									: undefined
							}
							searchListFilter={
								_has(header.reference, 'searchListFilter')
									? header.reference.searchListFilter
									: undefined
							}
							searchListLimit={_has(header.reference, 'searchListLimit') ? header.reference.searchListLimit : undefined}
							initialValue={this.state.value}
						/>
					);
				case 'color':
					return <Color value={this.state.value} onChange={this._setValue} />;
				default:
					return (
						<DefaultInput
							{...this.props}
							header={header}
							onKeyDown={this._triggerSubmit}
							onChange={this._setValue}
							disabled={this.props.disabled}
							defaultValue={this._getDefaultValue()}
						/>
					);
			}
		}
	}

	/**
	 * @function _renderFeedbackContent
	 * @memberOf COMPONENTS.dataInput
	 * @description Returns content for feedback span
	 * Multiple feedback situations can occur and is based on bools (validationExpressions)
	 * 1. Field error (comes from API)
	 * 2. Regex (is set in service)
	 * 3. Required (is set in service)
	 * @param  {object} header Object containing header data which comes from header (e.g. type = string, date etc.)
	 */
	_renderFeedbackContent(header) {
		var validationExpressions = {
			fieldError: [this.props.submitted, _has(header, 'fieldError')],
			regex: [
				this.props.submitted,
				this.getValue() && this.getValue().length > 0,
				_has(header, 'regex') &&
					!this._validateRegExp(header.regex, this.getValue()),
			],
			required: [_has(header, 'required')],
		};

		if (_every(validationExpressions.fieldError)) {
			return header.fieldError;
		} else if (_every(validationExpressions.regex)) {
			return header.regex.error;
		} else if (_every(validationExpressions.required)) {
			return this.props.translations.REQUIRED;
		}
	}

	componentDidMount() {
		this.sendState = _debounce(this.sendState, 100);
	}

	componentDidMount() {
		this._setDefaultState();
	}

	render() {
		return (
			<span className={this._inputClassNames()}>
				{this._renderInputContents(this.props.header)}
				<span className={this._feedbackClassNames()}>
					{this._renderFeedbackContent(this.props.header)}
				</span>
			</span>
		);
	}
}

DataInput.propTypes = {
	disabled: PropTypes.bool,
	header: PropTypes.object.isRequired,
	isEditing: PropTypes.bool,
	item: PropTypes.object,
	methods: PropTypes.object.isRequired,
	modifierClassName: PropTypes.string,
	submit: PropTypes.func,
	submitted: PropTypes.bool,
	translations: PropTypes.object.isRequired,
};

export default DataInput;
