'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import cx from 'classnames';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import { batch } from 'react-redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import { DateFilterSelector } from 'reactDataWrapper/utilities';

// components
import { InputCollectionSelect, Button, Icon, SingleDatePickerInput, Tooltip } from 'dumb';

// batch components
import WorkplacePosConfigurationModalBatchAdd from './components/batchAdd/workplacePosConfigurationModalBatchAdd';
import WorkplacePosConfigurationModalBatchDuplicateAsNew from './components/batchDuplicateAsNew/workplacePosConfigurationModalBatchDuplicateAsNew';

// constants
import constants from 'services/constants';

// moment
import moment from 'moment';

// lodash
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';

import {
	resetState,
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	setWorkplacePosConfiguration,
	updateWorkplacePosConfiguration,
	setWorkplaceToPosConfigurationDate,
	editBatchListItems,
	addBatchListDuplicate,
	revertBatchListItem,
} from './store/workplacePosConfiguration.actions';

import {
	fetchWorkplaceToPosConfigurationRelations,
	fetchPosConfigurations,
	fetchWorkplaces,
	editWorkplaceToPosConfigurationRelation,
	multiEditWorkplaceToPosConfigurationRelation,
	deleteWorkplaceToPosConfigurationRelation,
	addWorkplaceToPosConfigurationRelation,
	duplicateWorkplaceToPosConfigurationRelation,
} from './workplacePosConfiguration.service';

import phrases from './workplacePosConfiguration.phrases';

import './workplacePosConfiguration.css';

const reduxKey = '/pos/workplace_to_pos_configuration_relations';

class WorkplacePosConfiguration extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showAddModal: false,
			showEditModal: false,
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.toggleAddModal = this.toggleAddModal.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editMultiplePrices = this.editMultiplePrices.bind(this);
		this._onWorkplaceToPosConfigurationFilterChange = this._onWorkplaceToPosConfigurationFilterChange.bind(this);
		this.getWorkplaceToPosConfigurationFilterComponents =
			this.getWorkplaceToPosConfigurationFilterComponents.bind(this);
		this.getActionRender = this.getActionRender.bind(this);
		this.toggleEditModal = this.toggleEditModal.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.toggleBatchAddModal = this.toggleBatchAddModal.bind(this);

		this.columns = [
			{
				Header: 'POS Configuration',
				id: 'posConfiguration',
				accessor: (d) => _get(d, 'pos_configuration.name', null),
				filterPath: ':pos_configuration.name',
			},
			{
				Header: 'Workplace',
				id: 'workplace',
				accessor: (d) => _get(d, 'workplace.name', null),
				filterPath: ':workplace.name',
			},
			{
				Header: 'Product recommendation',
				id: 'productRecommendation',
				accessor: (d) => _get(d, 'product_recommendation.name', null),
				filterPath: ':product_recommendation.name',
			},
			{
				Header: 'Pos tile layout',
				id: 'posTileLayout',
				filterPath: ':pos_tile_layout.name',
				Cell: (d) => {
					const shownPosTileLayout =
						_get(d, 'original.pos_tile_layout.name', false) ||
						_get(d, 'original.pos_configuration.active_pos_configuration_version.pos_tile_layout.name', '');

					const hasPosTileLayout = _has(d, 'original.pos_tile_layout.name');

					return (
						<span
							className={cx('workplace-pos-configuration__tile-layout', {
								'workplace-pos-configuration__tile-layout--no-tile-layout': !hasPosTileLayout,
							})}
						>
							{shownPosTileLayout}
						</span>
					);
				},
			},
			{
				Header: 'App tile layout',
				id: 'appTilePayout',
				filterPath: ':app_tile_layout.name',
				Cell: (d) => {
					const shownAppTileLayout =
						_get(d, 'original.app_tile_layout.name', false) ||
						_get(d, 'original.pos_configuration.active_pos_configuration_version.app_tile_layout.name', '');

					const hasAppTileLayout = _has(d, 'original.app_tile_layout.name');

					return (
						<span
							className={cx('workplace-pos-configuration__tile-layout', {
								'workplace-pos-configuration__tile-layout--no-tile-layout': !hasAppTileLayout,
							})}
						>
							{shownAppTileLayout}
						</span>
					);
				},
			},
			{
				Header: 'Ingredient configuration',
				id: 'ingredientConfiguration',
				accessor: (d) => _get(d, 'ingredient_configuration.name', null),
				filterPath: ':ingredient_configuration.name',
			},
			{
				Header: 'Active from',
				width: 100,
				id: 'activeFrom',
				accessor: (d) => _get(d, 'active.from', null),
				Cell: (d) => {
					return <span>{moment.utc(d.original.active.from).format(constants.shortDate)}</span>;
				},
				filterPath: ':active.from',
				Filter: ({ column }) => <DateFilterSelector reduxKey={reduxKey} columnId={column.id} />,
			},
			{
				Header: 'Active to',
				width: 100,
				id: 'activeTo',
				accessor: (d) => _get(d, 'active.to', null),
				Cell: (d) => {
					return <span>{moment.utc(d.original.active.to).format(constants.shortDate)}</span>;
				},
				filterPath: ':active.to',
				Filter: ({ column }) => <DateFilterSelector reduxKey={reduxKey} columnId={column.id} />,
			},
		];
	}

	editEntry() {
		const { workplacePosConfiguration } = this.props;

		const payload = {
			id: workplacePosConfiguration.id,
			active: {
				from: workplacePosConfiguration.activeFrom
					? moment.utc(workplacePosConfiguration.activeFrom).format(constants.shortDate)
					: null,
				to: workplacePosConfiguration.activeTo
					? moment.utc(workplacePosConfiguration.activeTo).format(constants.shortDate)
					: null,
			},
			product_recommendation: workplacePosConfiguration.productRecommendation?.value ?? null,
			pos_tile_layout: _get(workplacePosConfiguration, 'posTileLayout.value', null),
			app_tile_layout: _get(workplacePosConfiguration, 'appTileLayout.value', null),
			ingredient_configuration: workplacePosConfiguration.ingredientConfiguration?.value ?? null,
		};

		return editWorkplaceToPosConfigurationRelation(payload);
	}

	deleteEntry(id) {
		return deleteWorkplaceToPosConfigurationRelation(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			posConfiguration: _get(data, 'pos_configuration.name', ''),
			workplace: _get(data, 'workplace.name', ''),
			activeFrom: _get(data, 'active.from', null),
			activeTo: _get(data, 'active.to', null),
			...(_get(data, 'product_recommendation.id', false) && {
				productRecommendation: {
					value: _get(data, 'product_recommendation.id', null),
					label: _get(data, 'product_recommendation.name', null),
				},
			}),
			...(_get(data, 'pos_tile_layout.id', false) && {
				posTileLayout: {
					value: _get(data, 'pos_tile_layout.id', null),
					label: _get(data, 'pos_tile_layout.name', null),
				},
			}),
			...(_get(data, 'app_tile_layout.id', false) && {
				appTileLayout: {
					value: _get(data, 'app_tile_layout.id', null),
					label: _get(data, 'app_tile_layout.name', null),
				},
			}),
			...(_get(data, 'ingredient_configuration.id', false) && {
				ingredientConfiguration: {
					value: _get(data, 'ingredient_configuration.id', null),
					label: _get(data, 'ingredient_configuration.name', null),
				},
			}),
		};

		this.props.setWorkplacePosConfiguration(payload);
	}

	getEditableCellsEdit() {
		const { workplacePosConfiguration } = this.props;

		return [
			{
				header: 'POS Configuration',
				value: <span>{workplacePosConfiguration.posConfiguration}</span>,
			},
			{
				header: 'Workplace',
				value: <span>{workplacePosConfiguration.workplace}</span>,
			},
			{
				header: 'Product recommendation',
				value: (
					<InputCollectionSelect
						id="productRecommendation"
						value={workplacePosConfiguration.productRecommendation}
						handleChange={(key, value) => this.editStoreEntry(value, 'productRecommendation')}
						cache
						placeholder="Select product recommendation"
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						apiPath="/pos/product_recommendations"
						params={{
							limit: 30,
						}}
					/>
				),
			},
			{
				header: 'POS tile layout',
				value: (
					<InputCollectionSelect
						id="posTileLayout"
						placeholder="Select Pos tile layout..."
						value={workplacePosConfiguration.posTileLayout}
						handleChange={(key, event) => this.editStoreEntry(event, 'posTileLayout')}
						cache
						optionFormat={(entry) => ({
							value: entry.id,
							label: `${entry.name} - ${entry.description}`,
						})}
						apiPath="/pos/tile_layouts"
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						params={{
							limit: 30,
							filter: `:type=='POS'`,
						}}
					/>
				),
			},
			{
				header: 'App tile layout',
				value: (
					<InputCollectionSelect
						id="appTileLayout"
						placeholder="Select App tile layout..."
						value={workplacePosConfiguration.appTileLayout}
						handleChange={(key, event) => this.editStoreEntry(event, 'appTileLayout')}
						cache
						optionFormat={(entry) => ({
							value: entry.id,
							label: `${entry.name} - ${entry.description}`,
						})}
						apiPath="/pos/tile_layouts"
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						params={{
							limit: 30,
							filter: `:type=='App'`,
						}}
					/>
				),
			},
			{
				header: 'Ingredient configuration',
				value: (
					<InputCollectionSelect
						id="ingredientConfiguration"
						placeholder="Select ingredient configuration..."
						value={workplacePosConfiguration.ingredientConfiguration}
						handleChange={(key, event) => this.editStoreEntry(event, 'ingredientConfiguration')}
						// clearable={false}
						cache
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						apiPath="/pos/ingredient_configurations"
						params={{
							limit: 30,
						}}
					/>
				),
			},
			{
				header: 'Active from',
				value: (
					<SingleDatePickerInput
						id="activeFromDatePicker"
						onChange={(event) => this.editStoreEntry(event, 'activeFrom')}
						selectedDate={
							workplacePosConfiguration.activeFrom
								? moment.utc(workplacePosConfiguration.activeFrom).format(constants.shortDate)
								: ''
						}
						noClockButton
					/>
				),
			},
			{
				header: 'Active to',
				value: (
					<SingleDatePickerInput
						id="activeToDatePicker"
						onChange={(event) => this.editStoreEntry(event, 'activeTo')}
						selectedDate={
							workplacePosConfiguration.activeTo
								? moment.utc(workplacePosConfiguration.activeTo).format(constants.shortDate)
								: ''
						}
						noClockButton
					/>
				),
			},
		];
	}

	editStoreEntry(e, name) {
		const { listWorkplacePosConfiguration, workplacePosConfiguration } = this.props;

		const selectedRows = _get(listWorkplacePosConfiguration, 'ui.selectedRows', []);

		// handle date change if multiple selected accordingly
		let datesObject = {};
		if (selectedRows.length > 1 && name === 'activeFrom' && !workplacePosConfiguration.activeTo) {
			datesObject = {
				activeTo: '9999-12-31',
			};
		}

		const payload = {
			...datesObject,
			[name]: e,
		};

		this.props.updateWorkplacePosConfiguration(payload);
	}

	editMultiplePrices(selectedRows) {
		const { workplacePosConfiguration } = this.props;

		const payload = {
			...(workplacePosConfiguration.activeFrom || workplacePosConfiguration.activeTo
				? {
						active: {
							...(workplacePosConfiguration.activeFrom && {
								from: moment.utc(workplacePosConfiguration.activeFrom).format(constants.shortDate),
							}),
							...(workplacePosConfiguration.activeTo && {
								to: moment.utc(workplacePosConfiguration.activeTo).format(constants.shortDate),
							}),
						},
				  }
				: {}),
			...(_get(workplacePosConfiguration, 'productRecommendation.value', false) && {
				product_recommendation: workplacePosConfiguration.productRecommendation.value,
			}),
			...(_get(workplacePosConfiguration, 'posTileLayout.value', false) && {
				pos_tile_layout: workplacePosConfiguration.posTileLayout.value,
			}),
			...(_get(workplacePosConfiguration, 'appTileLayout.value', false) && {
				app_tile_layout: workplacePosConfiguration.appTileLayout.value,
			}),
			...(_get(workplacePosConfiguration, 'ingredientConfiguration.value', false) && {
				ingredient_configuration: workplacePosConfiguration.ingredientConfiguration.value,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return multiEditWorkplaceToPosConfigurationRelation({
			batch: selectedRowsWithId,
		});
	}

	renderDetailsButton(d) {
		return (
			<Button key="details" type="inverted" shadow onClick={(e) => this.redirect(d.original, e)} size="micro">
				<Icon name="tune" />
			</Button>
		);
	}

	toggleAddModal() {
		if (this.state.showAddModal) this.props.resetState();
		this.setState((prevState) => ({ showAddModal: !prevState.showAddModal }));
	}

	toggleBatchAddModal() {
		const { layout, updateBatchForm, resetState } = this.props;

		batch(() => {
			resetState();
			// if this table is a sub table of layots, pre-set the layout entry
			if (layout) {
				const keyName = layout.type === 'POS' ? 'posTileLayout' : 'appTileLayout';

				const payload = {
					[keyName]: {
						value: layout,
						label: layout.name,
					},
				};

				updateBatchForm(payload);
			}
		});

		this.toggleAddModal();
	}

	_onWorkplaceToPosConfigurationFilterChange(date) {
		const { setWorkplaceToPosConfigurationDate } = this.props;

		const formattedDate = date ? moment.utc(date).format(constants.shortDate) : null;

		setWorkplaceToPosConfigurationDate(formattedDate);
	}

	getWorkplaceToPosConfigurationFilterComponents() {
		const { posWorkplaceToPosConfigurationDate } = this.props;

		return (
			<SingleDatePickerInput
				id="posConfigDiscountDatePicker"
				label="Select active date"
				type="single"
				appendToBody
				fixed
				onChange={this._onWorkplaceToPosConfigurationFilterChange}
				selectedDate={posWorkplaceToPosConfigurationDate || ''}
				noClockButton
				clearButtonDisabled={!posWorkplaceToPosConfigurationDate}
				clearable
			/>
		);
	}

	toggleEditModal() {
		const { listWorkplacePosConfiguration, addBatchList, addBatchListDuplicate } = this.props;

		if (this.state.showEditModal) this.props.resetState();
		else {
			const selectedRows = _get(listWorkplacePosConfiguration, 'ui.selectedRows', []);

			const listArray = selectedRows.map((entry) => {
				return {
					identifier: entry.id,
					posConfiguration: {
						label: entry.pos_configuration.name,
						value: entry.pos_configuration,
					},
					workplace: {
						label: entry.workplace.name,
						value: entry.workplace,
					},
					...(entry.product_recommendation && {
						productRecommendation: {
							value: entry.product_recommendation,
							label: entry.product_recommendation.name,
						},
					}),
					...(entry.pos_tile_layout && {
						posTileLayout: {
							value: entry.pos_tile_layout,
							label: entry.pos_tile_layout.name,
						},
					}),
					...(entry.app_tile_layout && {
						appTileLayout: {
							value: entry.app_tile_layout,
							label: entry.app_tile_layout.name,
						},
					}),
					...(entry.ingredient_configuration && {
						ingredientConfiguration: {
							value: entry.ingredient_configuration,
							label: entry.ingredient_configuration.name,
						},
					}),
					activeFrom: _get(entry, 'active.from', null),
					activeTo: _get(entry, 'active.to', null),
					// to disable the revert button
					rowEdited: false,
				};
			});

			batch(() => {
				addBatchList(listArray);
				addBatchListDuplicate(listArray);
			});
		}

		this.setState((prevState) => ({ showEditModal: !prevState.showEditModal }));
	}

	getActionRender() {
		const { listWorkplacePosConfiguration, workplaceId } = this.props;

		if (workplaceId) return;

		const selectedRows = _get(listWorkplacePosConfiguration, 'ui.selectedRows', []);

		return (
			<>
				<Button type="inverted" label="Add" shadow onClick={this.toggleBatchAddModal}>
					<Icon name="add" />
				</Button>
				{!_isEmpty(selectedRows) && !workplaceId && (
					<Tooltip
						placement="left"
						text={phrases.DUPLICATE_AS_NEW_TOOLTIP}
						renderData={(ref, onMouseEnter, onMouseLeave) => (
							<Button
								type="inverted"
								label={phrases.DUPLICATE_AS_NEW_BUTTON_LABEL}
								shadow
								onClick={this.toggleEditModal}
								refProp={ref}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
							>
								<Icon name="edit" />
							</Button>
						)}
					/>
				)}
			</>
		);
	}

	fetchData(state) {
		const { workplaceId, posWorkplaceToPosConfigurationDate, layout } = this.props;

		let filter;
		if (layout) {
			filter =
				layout.type === 'POS'
					? `(:pos_tile_layout.id=='${layout.id}',(:pos_configuration.pos_configuration_versions.active.from=le='${posWorkplaceToPosConfigurationDate}';:pos_configuration.pos_configuration_versions.active.to=ge='${posWorkplaceToPosConfigurationDate}';:pos_configuration.pos_configuration_versions.pos_tile_layout.id=='${layout.id}';:pos_tile_layout==null))`
					: `(:app_tile_layout.id=='${layout.id}',(:pos_configuration.pos_configuration_versions.active.from=le='${posWorkplaceToPosConfigurationDate}';:pos_configuration.pos_configuration_versions.active.to=ge='${posWorkplaceToPosConfigurationDate}';:pos_configuration.pos_configuration_versions.app_tile_layout.id=='${layout.id}';:app_tile_layout==null))`;
		}
		if (workplaceId) filter = filter ? `${filter}:workplace.id=='${workplaceId}'` : `:workplace.id=='${workplaceId}'`;

		return fetchWorkplaceToPosConfigurationRelations({ state, filter });
	}

	render() {
		const {
			updateBatchForm,
			batchFormData,
			listWorkplaces,
			listPosConfigurations,
			listProductRecommendations,
			listPosTileLayout,
			listAppTileLayout,
			listIngredientConfigurations,
			addBatchList,
			batchListData,
			editBatchListItem,
			removeBatchListItem,
			resetState,
			posWorkplaceToPosConfigurationDate,
			editBatchListItems,
			revertBatchListItem,
			defaultPageSize,
			workplaceId,
			editableCells,
			addEntry,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					accessAreasAllowedToEdit={['Sales Configuration']}
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={defaultPageSize}
					reduxKey={reduxKey}
					manual
					editEntry={() => this.editEntry()}
					{...(workplaceId && {
						editableCells,
						createEntry: addEntry,
					})}
					editableCellsEdit={this.getEditableCellsEdit()}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={resetState}
					actionsWidth={90}
					editMultiple={this.editMultiplePrices}
					deleteEntry={this.deleteEntry}
					deleteConfirm
					customAreaComponents={this.getWorkplaceToPosConfigurationFilterComponents()}
					extraFilters={
						posWorkplaceToPosConfigurationDate
							? `:active.from=le='${posWorkplaceToPosConfigurationDate}';:active.to=ge='${posWorkplaceToPosConfigurationDate}'`
							: null
					}
					actionRender={this.getActionRender()}
				/>

				{/* batch add flow */}
				<WorkplacePosConfigurationModalBatchAdd
					modalVisible={this.state.showAddModal}
					handleClose={this.toggleAddModal}
					batchFormData={batchFormData}
					updateBatchForm={updateBatchForm}
					fetchPosConfigurations={fetchPosConfigurations}
					fetchWorkplaceToPosConfigurationRelations={fetchWorkplaceToPosConfigurationRelations}
					fetchWorkplaces={fetchWorkplaces}
					listPosConfigurations={listPosConfigurations}
					listWorkplaces={listWorkplaces}
					addBatchList={addBatchList}
					batchListData={batchListData}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addWorkplaceToPosConfigurationRelation={addWorkplaceToPosConfigurationRelation}
				/>

				{/* batch duplicate flow */}
				<WorkplacePosConfigurationModalBatchDuplicateAsNew
					modalVisible={this.state.showEditModal}
					handleClose={this.toggleEditModal}
					batchFormData={batchFormData}
					updateBatchForm={updateBatchForm}
					addBatchList={addBatchList}
					batchListData={batchListData}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					duplicateWorkplaceToPosConfigurationRelation={duplicateWorkplaceToPosConfigurationRelation}
					listWorkplaces={listWorkplaces}
					listPosConfigurations={listPosConfigurations}
					listProductRecommendations={listProductRecommendations}
					listPosTileLayout={listPosTileLayout}
					listAppTileLayout={listAppTileLayout}
					listIngredientConfigurations={listIngredientConfigurations}
					editBatchListItems={editBatchListItems}
					revertBatchListItem={revertBatchListItem}
				/>
			</>
		);
	}
}

WorkplacePosConfiguration.defaultProps = {
	defaultPageSize: 50,
};

WorkplacePosConfiguration.propTypes = {
	workplacePosConfiguration: PropTypes.object,
	listPosConfigurations: PropTypes.object,
	listWorkplaces: PropTypes.object,
	listWorkplacePosConfiguration: PropTypes.object,
	listProductRecommendations: PropTypes.object,
	listPosTileLayout: PropTypes.object,
	listAppTileLayout: PropTypes.object,
	listIngredientConfigurations: PropTypes.object,
	setWorkplacePosConfiguration: PropTypes.func,
	resetState: PropTypes.func,
	updateBatchForm: PropTypes.func,
	addBatchList: PropTypes.func,
	batchFormData: PropTypes.object,
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	updateWorkplacePosConfiguration: PropTypes.func,
	setWorkplaceToPosConfigurationDate: PropTypes.func,
	posWorkplaceToPosConfigurationDate: PropTypes.string,
	editBatchListItems: PropTypes.func,
	addBatchListDuplicate: PropTypes.func,
	revertBatchListItem: PropTypes.func,
	workplaceId: PropTypes.number,
	defaultPageSize: PropTypes.number,
	editableCells: PropTypes.array,
	addEntry: PropTypes.func,
	layout: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			resetState,
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			setWorkplacePosConfiguration,
			updateWorkplacePosConfiguration,
			setWorkplaceToPosConfigurationDate,
			editBatchListItems,
			addBatchListDuplicate,
			revertBatchListItem,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		batchFormData: store.workplacePosConfiguration.data.batchFormData,
		batchListData: store.workplacePosConfiguration.data.batchListData,
		posWorkplaceToPosConfigurationDate: store.workplacePosConfiguration.posWorkplaceToPosConfigurationDate,
		listPosConfigurations: store.listData['workplace-pos-configuration-batch-/pos/pos_configurations'],
		listWorkplaces: store.listData['workplace-pos-configuration-batch-/administration/workplaces'],
		listProductRecommendations: store.listData['workplace-pos-configuration-batch-/pos/product_recommendations'],
		listPosTileLayout: store.listData['workplace-pos-configuration-batch-pos-/pos/tile_layouts'],
		listAppTileLayout: store.listData['workplace-pos-configuration-batch-app-/pos/tile_layouts'],
		listIngredientConfigurations: store.listData['workplace-pos-configuration-batch-/pos/ingredient_configurations'],

		listWorkplacePosConfiguration: store.listData[reduxKey],
		workplacePosConfiguration: store.workplacePosConfiguration.data.workplacePosConfiguration,
	};
};

export default connectWithStore(WorkplacePosConfiguration, mapStateToProps, mapDispatchToProps);
