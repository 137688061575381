'use strict';

/**
 * @namespace __enums
 * @memberOf SERVICES
 * @description
 * Wrapper for enums
 */

import tz from 'timeZones.js';

const enums = {
	filterOperators: {
		'==': 'equals',
		'=like=': 'like',
		'=gt=': 'greater than',
		'=lt=': 'less than',
		'=ge=': 'greater than or equal to',
		'=le=': 'less than or equal to',
		time: {
			'==': 'on',
			'=gt=': 'after',
			'=lt=': 'before',
			'=ge=': 'after or on',
			'=le=': 'before or on',
			'=!=': 'between'
		},
		sort: {
			absolute: 'absolute',
			default: 'default'
		}
	},
	productList: [
		{
			value: 'Product',
			label: 'Product'
		},
		{
			value: 'Ingredient',
			label: 'Ingredient'
		}
	],
	tz: tz,
	workplaces: [
		// {
		// 	value: 'Overhead',
		// 	label: 'Overhead'
		// },
		// {
		// 	value: 'HQ',
		// 	label: 'HQ'
		// },
		// {
		// 	value: 'Pipeline',
		// 	label: 'Pipeline'
		// },
		// {
		// 	value: 'Store',
		// 	label: 'Store'
		// },
		{
			value: 'Office',
			label: 'Office'
		},
		{
			value: 'Store',
			label: 'Store'
		}
	],
	workplaceStates: [
		{
			value: 'Lead',
			label: 'Lead'
		},
		{
			value: 'Pipeline',
			label: 'Pipeline'
		},
		{
			value: 'File Card',
			label: 'File Card'
		},
		{
			value: 'Signed',
			label: 'Signed'
		},
		{
			value: 'Operating',
			label: 'Operating'
		},
		{
			value: 'Cancelled',
			label: 'Cancelled'
		},
		{
			value: 'Closed',
			label: 'Closed'
		},
		{
			value: 'Test',
			label: 'Test'
		}
	],

	taxRates: [
		{
			value: 'VAT',
			label: 'VAT'
		},
		{
			value: 'SalesTax',
			label: 'SalesTax'
		}
	],
	taxPurpose: [
		{
			value: 'To Stay',
			label: 'To Stay'
		},
		{
			value: 'To Go',
			label: 'To Go'
		},
		{
			value: 'General',
			label: 'General'
		}
	],
	resourceActions: [
		{
			value: 'create',
			label: 'create'
		},
		{
			value: 'find',
			label: 'find'
		},
		{
			value: 'index',
			label: 'index'
		},
		{
			value: 'update',
			label: 'update'
		},
		{
			value: 'delete',
			label: 'delete'
		},
		{
			value: '*',
			label: '*'
		}
	],
	amountType: [
		{
			value: 'Percent',
			label: 'Percent'
		},
		{
			value: 'Nominal',
			label: 'Nominal'
		}
	],
	frequency: [
		{
			value: 'Hourly',
			label: 'Hourly'
		},
		{
			value: 'Per shift',
			label: 'Per shift'
		},
		{
			value: 'Periodly',
			label: 'Periodly'
		}
	],
	salaryTypes: [
		{
			value: 'PA',
			label: 'PA'
		},
		{
			value: 'FA',
			label: 'FA'
		},
		{
			value: 'FLEX',
			label: 'FLEX'
		}
		// {
		// 	value: 'On Call',
		// 	label: 'On Call'
		// }
	],
	employmentType: [
		{
			value: 'Base Pay',
			label: 'Base Pay'
		},
		{
			value: 'Employee Contribution',
			label: 'Employee Contribution'
		},
		{
			value: 'Addon',
			label: 'Addon'
		},
		{
			value: 'Overtime',
			label: 'Overtime'
		}
	],
	weekdays: [
		{
			value: 0,
			label: 'Monday'
		},
		{
			value: 1,
			label: 'Tuesday'
		},
		{
			value: 2,
			label: 'Wednesday'
		},
		{
			value: 3,
			label: 'Thursday'
		},
		{
			value: 4,
			label: 'Friday'
		},
		{
			value: 5,
			label: 'Saturday'
		},
		{
			value: 6,
			label: 'Sunday'
		}
	],
	salaryExportFormats: [
		{
			value: 'aus',
			label: 'aus'
		},
		{
			value: 'deu',
			label: 'deu'
		},
		{
			value: 'dnk',
			label: 'dnk'
		},
		{
			value: 'fin',
			label: 'fin'
		},
		{
			value: 'swe',
			label: 'swe'
		},
		{
			value: 'nor',
			label: 'nor'
		},
		{
			value: 'gbr',
			label: 'gbr'
		},
		{
			value: 'usa',
			label: 'usa'
		},
		{
			value: 'bel',
			label: 'bel',
		},
	],
	posConfigurationVersions: [
		{
			value: 'Draft',
			label: 'Draft'
		},
		{
			value: 'Published',
			label: 'Published'
		}
	],
	tagsList: [
		{
			value: 'Shiftplan',
			label: 'Shiftplan'
		},
		{
			value: 'Store',
			label: 'Store'
		}
	],
	currencyTypes: [
		{
			value: 'Local',
			label: 'Local'
		},
		{
			value: 'Foreign',
			label: 'Foreign'
		}
	],
	tagsSubtypesList: [
		{
			value: 'None',
			label: 'None'
		},
		{
			value: 'Training',
			label: 'Training'
		},
		{
			value: 'Exams',
			label: 'Exams'
		},
		{
			value: 'Break',
			label: 'Break'
		}
	],
	employmentDocumentTypes: [
		{
			value: 'Other',
			label: 'Other'
		},
		{
			value: 'Contract',
			label: 'Contract'
		},
		{
			value: 'Warning',
			label: 'Warning'
		}
	],
	openingHoursPriority: [
		{
			value: 1,
			label: '1'
		},
		{
			value: 2,
			label: '2'
		},
		{
			value: 3,
			label: '3'
		},
		{
			value: 4,
			label: '4'
		},
		{
			value: 5,
			label: '5'
		}
	],

	trainingModules: [
		{
			value: 'Exams',
			label: 'Exams'
		},
		{
			value: 'Training',
			label: 'Training'
		}
	],

	onboardingPlatform: [
		{
			value: 'Typeform',
			label: 'Typeform'
		}
	],

	badgeCategories: [
		{
			value: 'Training',
			label: 'Training'
		},
		{
			value: 'Exams',
			label: 'Exams'
		},
		{
			value: 'Other',
			label: 'Other'
		}
	],

	forecastingTypes: [
		{
			value: 'Operation',
			label: 'Operation'
		},
		{
			value: 'Board',
			label: 'Board'
		},
		{
			value: 'Scenario Analysis',
			label: 'Scenario Analysis'
		}
	],
	forecastingOwnershipTypes: [
		{
			value: 'Group',
			label: 'Group'
		},
		{
			value: 'Franchise',
			label: 'Franchise'
		},
		{
			value: 'Entire',
			label: 'Entire'
		}
	],
	genders: [
		{
			value: 'Male',
			label: 'Male'
		},
		{
			value: 'Female',
			label: 'Female'
		},
		{
			value: 'Other',
			label: 'Other'
		}
	],
	tileProductTileTypes: [
		{
			label: 'Group',
			value: 'Group'
		},
		{
			label: 'Product',
			value: 'Product'
		}
	],
	categoryGroups: [
		{
			value: 'Juice',
			label: 'Juice'
		},
		{
			value: 'Shake',
			label: 'Shake'
		},
		{
			value: 'Coffee',
			label: 'Coffee'
		},
		{
			value: 'Sandwich',
			label: 'Sandwich'
		},
		{
			value: 'Salad',
			label: 'Salad'
		},
		{
			value: 'Breakfast',
			label: 'Breakfast'
		},
		{
			value: 'Loyalty card',
			label: 'Loyalty card'
		},
		{
			value: 'Misc',
			label: 'Misc'
		}
	],

	accountScheduleAccountTypes: [
		{
			value: 'Debit',
			label: 'Debit'
		},
		{
			value: 'Credit',
			label: 'Credit'
		}
	],

	companyPayrollFrequency: [
		{
			value: 'Even weeks',
			label: 'Even weeks'
		},
		{
			value: 'Odd weeks',
			label: 'Odd weeks'
		},
		{
			value: 'Monthly',
			label: 'Monthly'
		}
	],

	tileSizes: [
		{
			value: 'Full',
			label: 'Full'
		},
		{
			value: 'Half',
			label: 'Half'
		}
	],

	workplaceSizes: [
		{
			value: 'Stockroom',
			label: 'Stockroom'
		},
		{
			value: 'Outdoor Sheltered',
			label: 'Outdoor Sheltered'
		},
		{
			value: 'Outdoor',
			label: 'Outdoor'
		},
		{
			value: 'Sales Other Floor',
			label: 'Sales Other Floor'
		},
		{
			value: 'Indoor Sales',
			label: 'Indoor Sales'
		},
		{
			value: 'Patio',
			label: 'Patio'
		}
	],

	globalAccountTypes: [
		{
			value: 'Store Salary',
			label: 'Store Salary'
		}
	],

	discountTypes: [
		{
			value: 'Loyalty',
			label: 'Loyalty'
		},
		{
			value: 'Employee',
			label: 'Employee'
		},
		{
			value: 'Neighbour',
			label: 'Neighbour'
		}
	],

	onboardingStatus: [
		{
			value: 'Signed',
			label: 'Signed'
		},
		{
			value: 'Unsigned',
			label: 'Unsigned'
		}
	],

	posConfigurationsDiscountTypes: [
		{
			value: 'Loyalty',
			label: 'Loyalty'
		},
		{
			value: 'Employee',
			label: 'Employee'
		},
		{
			value: 'Neighbour',
			label: 'Neighbour'
		}
	],
	roleTypes: [
		{
			value: 'Juicer',
			label: 'Juicer'
		},
		{
			value: 'Shift Manager',
			label: 'Shift Manager'
		},
		{
			value: 'Admin',
			label: 'Admin'
		}
	],
	teamPositionCategories: [
		{
			value: 'Trainee',
			label: 'Trainee'
		},
		{
			value: 'Juicer',
			label: 'Juicer'
		},
		{
			value: 'Manager',
			label: 'Manager'
		}
	],

	currencyDenominationTypes: [
		{
			value: 'Coin',
			label: 'Coin'
		},
		{
			value: 'Banknote',
			label: 'Banknote'
		}
	],

	weekdaysLabels: [
		{
			value: 'Monday',
			label: 'Monday'
		},
		{
			value: 'Tuesday',
			label: 'Tuesday'
		},
		{
			value: 'Wednesday',
			label: 'Wednesday'
		},
		{
			value: 'Thursday',
			label: 'Thursday'
		},
		{
			value: 'Friday',
			label: 'Friday'
		},
		{
			value: 'Saturday',
			label: 'Saturday'
		},
		{
			value: 'Sunday',
			label: 'Sunday'
		}
	]
};

module.exports = enums;
