'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, TableList, InputCleave } from 'dumb';
import phrases from '../../editModalChoice.phrases';
import _get from 'lodash/get';

import { enums } from '../../../../salaryComponents.enums';

class AddNewSalaryComponentList extends Component {
	_onRemoveEntry(row) {
		this.props.removeBatchListItem(row.identifier);
	}

	_editListEntry(e, row, type) {
		const value = e.target ? e.target.value : e;

		// const updateObject = {
		// 	identifier: row.identifier,
		// 	id
		// 	salary_group: row.salary_group,
		// 	global_salary_component: row.global_salary_component,
		// 	name: row.name,
		// 	amount_type: row.amount_type,
		// 	amount: row.amount,
		// 	frequency: row.frequency,
		// 	external_export_id: row.external_export_id,
		// 	active: row.active
		// };

		this.props.editBatchListItem({
			...row,
			...{ [type]: value }
		});
	}

	_editStoreDatesEntry(e, row, type) {
		const value = e.target ? e.target.value : e;

		const active =
			type === 'from'
				? {
						from: value,
						to: _get(batchOptions, 'active.to', '9999-12-31')
				  }
				: {
						from: _get(batchOptions, 'active.from', ''),
						to: value
				  };

		this.props.editBatchListItem({
			...row,
			...{
				active
			}
		});
	}

	render() {
		const { batchList } = this.props;

		return (
			<>
				<TableList>
					<thead>
						<tr>
							<th>#</th>
							<th>{phrases.MARKET}</th>
							<th>{phrases.MONEYBALL_SUB_POSITION}</th>
							<th>{phrases.GLOBAL_SALARY_COMPONENT}</th>
							<th>{phrases.NAME}</th>
							<th>{phrases.AMOUNT_TYPE}</th>
							<th>{phrases.AMOUNT}</th>
							<th>{phrases.FREQUENCY}</th>
							<th>{phrases.FROM}</th>
							<th>{phrases.EXTERNAL_EXPORT_ID}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((x, i) => {
							return (
								<tr key={i}>
									<td>{i + 1}</td>
									<td>{_get(x, 'salary_group.market.name', '')}</td>
									<td>{_get(x, 'salary_group.name', '')}</td>
									<td>{_get(x, 'global_salary_component.name', '')}</td>
									<td>{_get(x, 'name', '')}</td>
									<td>{_get(x, 'amount_type', '')}</td>
									<td>
										<div className="modal-batch__list__cell">
											<Input
												id="ammt"
												type="number"
												min={0}
												value={_get(x, 'amount', '')}
												placeholder="Amount"
												onChange={event =>
													this._editListEntry(event, x, 'amount')
												}
											/>
										</div>
									</td>
									<td>{_get(x, 'frequency', '')}</td>
									<td>
										<div className="modal-batch__list__cell">
											<InputCleave
												id="active-from"
												placeholder="yyyy-mm-dd"
												options={{
													date: true,
													delimiter: '-',
													datePattern: ['Y', 'm', 'd']
												}}
												value={_get(x, 'active.from', '')}
												onChange={event =>
													this._editStoreDatesEntry(event, x, 'from')
												}
											/>
										</div>
									</td>
									<td>{_get(x, 'external_export_id', '')}</td>
									<td>
										<Button
											type="negative"
											size="tiny"
											onClick={() => this._onRemoveEntry(x)}>
											<span className="icon icon--delete" />
										</Button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</TableList>
			</>
		);
	}
}

AddNewSalaryComponentList.propTypes = {
	batchList: PropTypes.array,
	removeBatchListItem: PropTypes.func,
	editBatchListItem: PropTypes.func
};

AddNewSalaryComponentList.defaultProps = {};

export default AddNewSalaryComponentList;
