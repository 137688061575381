'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';

// different batch views
import IngredientPricingBatchStepForm from './components/ingredientPricingBatchStepForm';
import IngredientPricingBatchStepList from './components/ingredientPricingBatchStepList';

// phrases/ constants/ moment
import phrases from './../../salesConfigurationIngredientPricings.phrases';
import constants from 'services/constants';
import moment from 'moment';
import { getFilter, markValuesWithCanAdd } from './../../utils';

// loooodash
import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';

class IngredientPricingModalBatch extends Component {
	constructor(props) {
		super(props);

		this.today = moment.utc().format(constants.shortDate);

		this.state = {
			loadingModal: false,
		};

		this._onSubmitForm = this._onSubmitForm.bind(this);
		this._batchSubmit = this._batchSubmit.bind(this);
		this._getIsBatchStepFormValid = this._getIsBatchStepFormValid.bind(this);
		this._getIsBatchListValid = this._getIsBatchListValid.bind(this);

		this.modalRef = React.createRef();
	}

	_onSubmitForm() {
		const { batchFormData, addBatchList, fetchPosConfigurationIngredients } =
			this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const listArray = batchFormData.posConfigurationVersion.reduce((acc, x) => {
			const list = batchFormData.ingredient.map((y) => {
				return {
					identifier: _uniqueId('row-'), // used to identify what to edit on step#2
					posConfigurationVersion: x.value,
					ingredient: y.value,
					alternativeId: batchFormData.alternativeId,
					toStayPrice: batchFormData.toStayPrice,
					toGoPrice: batchFormData.toGoPrice,
					availableInApp: batchFormData.availableInApp,
					deliveryPrice: batchFormData.deliveryPrice,
				};
			});

			return [...acc, ...list];
		}, []);

		// get filter to get potentially already added values
		const filter = getFilter(listArray);

		fetchPosConfigurationIngredients({ filter })
			.then((res) => {
				const valuesToAdd = markValuesWithCanAdd({ listArray, data: res.data });

				addBatchList(valuesToAdd);

				this._goToStep(2);
			})
			.finally(() =>
				this.setState(() => ({
					loadingModal: false,
				}))
			);
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_batchSubmit() {
		const { batchList, handleClose, addPosConfigurationIngredients } =
			this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchList
			.filter((entry) => entry.canAdd)
			.map((entry) => {
				return {
					pos_configuration_version: _get(
						entry,
						'posConfigurationVersion.id',
						null
					),
					ingredient: _get(entry, 'ingredient.id', null),
					alternative_id: entry.alternativeId,
					to_stay_price: _get(entry, 'toStayPrice', '').replace(',', '.'),
					to_go_price: _get(entry, 'toGoPrice', '').replace(',', '.'),
					available_in_app: !!entry.availableInApp,
					delivery_price: !entry.deliveryPrice
						? null
						: _get(entry, 'deliveryPrice', '').replace(',', '.'),
				};
			});

		addPosConfigurationIngredients({ batch: payload })
			.then(() => {
				handleClose();
				this.setState(() => ({
					loadingModal: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	_getIsBatchStepFormValid() {
		const { batchFormData } = this.props;

		const isPosConfigurationVersionEmpty = _isEmpty(
			batchFormData.posConfigurationVersion
		);
		const isIngredientEmpty = _isEmpty(batchFormData.ingredient);

		if (!isPosConfigurationVersionEmpty && !isIngredientEmpty) return true;
		else return false;
	}

	_getIsBatchListValid() {
		const { batchList } = this.props;

		// if at least one entry can be added, list is valid
		const atLeastOneCanBeAdded = batchList.some((entry) => entry.canAdd);

		return atLeastOneCanBeAdded;
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchList,
			editBatchListItem,
			removeBatchListItem,
		} = this.props;

		const steps = [
			{
				component: (
					<IngredientPricingBatchStepForm
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE,
				isValid: this._getIsBatchStepFormValid(),
				onNext: this._onSubmitForm,
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				noScroll: false,
			},
			{
				component: (
					<IngredientPricingBatchStepList
						batchList={batchList}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: this._getIsBatchListValid(),
				onNext: this._batchSubmit,
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return modalVisible ? (
			<ModalStepper
				ref={this.modalRef}
				isOpen={modalVisible}
				steps={steps}
				showStep={false}
				onClose={handleClose}
			/>
		) : null;
	}
}

IngredientPricingModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	addBatchList: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addPosConfigurationIngredients: PropTypes.func,
	fetchPosConfigurationIngredients: PropTypes.func,
};

export default IngredientPricingModalBatch;
