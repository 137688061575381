import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './workplaceSystemSoftwares.phrases';

/**
 * @function fetchWorkplaceSystemSoftwares
 * @description Fetch all system softwares
 * @param {object} state
 */
export function fetchWorkplaceSystemSoftwares(state, filter) {
	if (state.filter) filter = `${filter};${state.filter}`;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(
		'/administration/workplace_system_softwares',
		params,
		null,
		state.headers
	)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addWorkplaceSystemSoftwares
 * @description add system softwares
 */
export function addWorkplaceSystemSoftwares(data) {
	return post('/administration/workplace_system_softwares', data)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_SYSTEM_SOFTWARES_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editWorkplaceSystemSoftware
 * @description edit system software
 */
export function editWorkplaceSystemSoftware(data) {
	return post(`/administration/workplace_system_softwares/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_SYSTEM_SOFTWARE_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function editWorkplaceSystemSoftwares
 * @description edits multiple workplace system softwares
 */
export function editWorkplaceSystemSoftwares(data) {
	return patch('/administration/workplace_system_softwares', data)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_SYSTEM_SOFTWARES_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function deleteWorkplaceSystemSoftwares
 * @description delete system software
 */
export function deleteWorkplaceSystemSoftwares(id) {
	return remove(`/administration/workplace_system_softwares/${id}`)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_SYSTEM_SOFTWARES_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
