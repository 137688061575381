import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ListComponent from 'common/components/list/list.component';

import CompanyAddressesList from 'organisation/companies/services/companyAddressesList.service.js';
import CompanyToWorkplacesList from 'organisation/companies/services/companyToWorkplacesList.service.js';
import CompanyVatNumbersList from 'organisation/companies/services/companyVatNumbersList.service.js';
import CompanyAccountSchedules from 'organisation/companies/services/companyAccountSchedulesList.service.js';

const companyAddressesListReduxKey = '/administration/company_addresses';
const companyAddressesList = new CompanyAddressesList(
	companyAddressesListReduxKey
);
const companyAddressesListProps = companyAddressesList.getProps();

const companyToWorkplacesListReduxKey =
	'/administration/company_company_to_workplace_relations';
const companyToWorkplacesList = new CompanyToWorkplacesList(
	companyToWorkplacesListReduxKey
);
const companyToWorkplacesListProps = companyToWorkplacesList.getProps();

const companyVatNumbersListReduxKey = '/administration/company_vat_number';
const companyVatNumbersList = new CompanyVatNumbersList(
	companyVatNumbersListReduxKey
);
const companyVatNumbersListProps = companyVatNumbersList.getProps();

const companyAccountSchedulesListReduxKey =
	'/accounting/company_account_schedules';
const companyAccountSchedulesList = new CompanyAccountSchedules(
	companyAccountSchedulesListReduxKey
);
const companyAccountSchedulesListProps = companyAccountSchedulesList.getProps();

export function CompanyDetailsComponent() {
	const { companyId } = useParams();

	const companyIdFilter = `:company.id=='${companyId}'`;

	useEffect(() => {
		companyAddressesList.setFilterQuery(companyIdFilter);
		companyAddressesList.setData({ company: companyId });
		companyAddressesList.initView();

		companyVatNumbersList.setFilterQuery(companyIdFilter);
		companyVatNumbersList.setData({ company: companyId });
		companyVatNumbersList.initView();

		companyToWorkplacesList.setFilterQuery(companyIdFilter);
		companyToWorkplacesList.setData({ company: companyId });
		companyToWorkplacesList.initView();

		companyAccountSchedulesList.setFilterQuery(companyIdFilter);
		companyAccountSchedulesList.setData({ company: companyId });
		companyAccountSchedulesList.initView();
	}, [companyIdFilter, companyId]);

	return (
		<>
			<ListComponent
				reduxKey={companyAddressesListReduxKey}
				{...companyAddressesListProps}
			/>

			<ListComponent
				reduxKey={companyVatNumbersListReduxKey}
				{...companyVatNumbersListProps}
			/>

			<ListComponent
				reduxKey={companyToWorkplacesListReduxKey}
				{...companyToWorkplacesListProps}
			/>

			<ListComponent
				reduxKey={companyAccountSchedulesListReduxKey}
				{...companyAccountSchedulesListProps}
			/>
		</>
	);
}
