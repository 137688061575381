import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import constants from 'services/constants';

/**
 * @function handleToggleOverlayHelper
 * @param {Object} objet - contains all list metadata from redux, all lists redux keys and currently opened table (overlayType)
 * @description finds out what table is open, finds the selected row and adds it to data object under a proper key -> key is based on the tables redux key
 */
export function handleToggleOverlayHelper({
	overlayType,
	posConfigurationReduxKey,
	listPosConfigurations,
	posLayoutReduxKey,
	listPosTileLayout,
	appLayoutReduxKey,
	listAppTileLayout,
}) {
	let data;
	let selectedRowsInList = [];

	if (overlayType === posConfigurationReduxKey) {
		selectedRowsInList = _get(listPosConfigurations, 'ui.selectedRows', []);

		if (!_isEmpty(selectedRowsInList)) {
			data = {
				posConfiguration: {
					label: selectedRowsInList[0].name,
					value: selectedRowsInList[0],
				},
			};
		}
	}
	if (overlayType === posLayoutReduxKey) {
		selectedRowsInList = _get(listPosTileLayout, 'ui.selectedRows', []);

		if (!_isEmpty(selectedRowsInList)) {
			data = {
				posTileLayout: {
					label: selectedRowsInList[0].name,
					value: selectedRowsInList[0],
				},
			};
		}
	}
	if (overlayType === appLayoutReduxKey) {
		selectedRowsInList = _get(listAppTileLayout, 'ui.selectedRows', []);

		if (!_isEmpty(selectedRowsInList)) {
			data = {
				appTileLayout: {
					label: selectedRowsInList[0].name,
					value: selectedRowsInList[0],
				},
			};
		}
	}

	return data;
}

export function getSelectedDate(date, name) {
	if (date) return moment.utc(date).format(constants.shortDate);

	return name === 'to' ? '9999-12-31' : '';
}

export function getStateOptions() {
	return [
		{
			value: 'Draft',
			label: 'Draft',
		},
		{
			value: 'Published',
			label: 'Published',
		},
	];
}
