import React from 'react';
import PropTypes from 'prop-types';

// enums/phrases
import enums from 'services/enums.service';
import phrases from './globalBundleRulesWeekView.phrases';

// components
import { Tooltip } from 'dumb';

// utils
import {
	getStackedBundleCards,
	createTimeHourTable,
	getBundlePlacing,
	getBundleMetaData,
} from './utils';
import constants from 'services/constants';

// lodash
import _isEmpty from 'lodash/isEmpty';

// styles
import './globalBundleRulesWeekView.css';

const GlobalBundleRulesWeekView = (props) => {
	const renderNoData = () => {
		return (
			<div className="global-bundle-rules-week-view__no-data">
				{phrases.NO_DATA}
			</div>
		);
	};

	const renderBundleCards = ({ day }) => {
		const bundles = data.filter((entry) => entry.weekday === day);

		if (_isEmpty(bundles)) return;

		const stackedBundles = getStackedBundleCards({ bundles, bundleMetaData });

		// first param is hour divider and second is hour to pixel ratio
		const tableHours = createTimeHourTable(2, hourToPixelRatio);

		return stackedBundles.bundles.map((entry, index) => {
			const styles = getBundlePlacing({
				bundle: entry,
				tableHours,
				startTimeOfDay: bundleMetaData.startTimeOfDay,
				hourToPixelRatio,
			});

			return (
				<Tooltip
					key={index}
					text={entry.description}
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<div
							className="global-bundle-rules-week-view__day-wrapper__day__main__bundle-card"
							style={{ ...entry.style, ...styles }}
							ref={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}>
							<span>{entry.name}</span>
							<span>
								{moment
									.utc(entry.time.from, constants.dayFormat)
									.format(constants.timeShort)}{' '}
								-{' '}
								{moment
									.utc(entry.time.to, constants.dayFormat)
									.format(constants.timeShort)}
							</span>
						</div>
					)}
				/>
			);
		});
	};

	const { data, hourToPixelRatio } = props;
	const bundleMetaData = getBundleMetaData({ bundles: data, hourToPixelRatio });

	return (
		<div className="global-bundle-rules-week-view">
			<p className="global-bundle-rules-week-view__title">{phrases.TITLE}</p>
			<div className="global-bundle-rules-week-view__day-wrapper">
				{_isEmpty(data)
					? renderNoData()
					: enums.weekdaysLabels.map((entry, index) => {
						return (
							<div
								key={index}
								className="global-bundle-rules-week-view__day-wrapper__day">
								<div className="global-bundle-rules-week-view__day-wrapper__day__header">
									{entry.value}
								</div>
								<div
									className="global-bundle-rules-week-view__day-wrapper__day__main"
									style={{ height: bundleMetaData.height }}>
									{renderBundleCards({ day: entry.value })}
								</div>
							</div>
						);
					  })}
			</div>
		</div>
	);
};

GlobalBundleRulesWeekView.propTypes = {
	data: PropTypes.array,
	hourToPixelRatio: PropTypes.number,
};

GlobalBundleRulesWeekView.defaultProps = {
	hourToPixelRatio: 10,
};

export default GlobalBundleRulesWeekView;
