'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';
const __endpoints = require('endpoints.service');

export default class WorkplaceWorkplaceSalaryAccountsList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'global_account',
				type: 'select',
				canAdd: true,
				canEdit: true,
				required: true,
				reference: {
					key: 'name',
					list: '/accounting/global_accounts',
				},
			},
		];
		this.settings = {
			name: 'WorkplaceWorkplaceSalaryAccountsList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			canCollapse: true,
			alwaysRefresh: true,
			canManipulate: () => validAccess(undefined, ['Organisation/Workplaces::Edit', 'Organization Admin']),
			translationPath: 'ACCOUNTING.WORKPLACE_SALARY_ACCOUNTS',
			endpoint: __endpoints.collection.accounting.workplace_salary_accounts,
		};
		this.init();
		return this;
	}
}
