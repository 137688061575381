export const RESET_STATE = 'GLOBAL_BUNDLE_RULE_TRANSLATION/RESET_STATE';
export const SET_GLOBAL_BUNDLE_RULE_TRANSLATION = 'GLOBAL_BUNDLE_RULE_TRANSLATION/SET_GLOBAL_BUNDLE_RULE_TRANSLATION';
export const UPDATE_GLOBAL_BUNDLE_RULE_TRANSLATION =
	'GLOBAL_BUNDLE_RULE_TRANSLATION/UPDATE_GLOBAL_BUNDLE_RULE_TRANSLATION';

export const EDIT_BATCH_LIST_ITEM = 'GLOBAL_BUNDLE_RULE_TRANSLATION/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM = 'GLOBAL_BUNDLE_RULE_TRANSLATION/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW = 'GLOBAL_BUNDLE_RULE_TRANSLATION/ADD_BATCH_LIST_ITEM_ROW';
export const RERENDER_TABLE = 'GLOBAL_BUNDLE_RULE_TRANSLATION/RERENDER_TABLE';

export function setGlobalBundleRuleTranslation(payload) {
	return {
		type: SET_GLOBAL_BUNDLE_RULE_TRANSLATION,
		payload,
	};
}

export function updateGlobalBundleRuleTranslation(payload) {
	return {
		type: UPDATE_GLOBAL_BUNDLE_RULE_TRANSLATION,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}

export function rerenderTable() {
	return {
		type: RERENDER_TABLE,
	};
}
