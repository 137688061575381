'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	addBatchListItemRow,
	resetState,
	editBatchListItem,
	removeBatchListItem,
	setProductBundleSets,
	updateProductBundleSet,
	setPosConfigurationdefaultProductBundleSetsDateFilter,
} from './store/productBundleSets.actions';

// rDW actions
import { setListData } from 'reactDataWrapper/reactDataWrapper.actions';

// rDW
import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';

// components
import { Button, Input, Icon, Toggle, InputCollectionSelect, Tabs } from 'dumb';
import Checkbox from 'inputs/checkbox';
import ProductBundleSetsModalBatch from './components/batch/productBundleSetsModalBatch';
import ProductBundleSetProductVariants from './components/productBundleSetProductVariants/productBundleSetProductVariants';
import ProductBundleSetTranslations from './components/productBundleSetTranslations/productBundleSetTranslations.component';

// lodash
import _get from 'lodash/get';
import _has from 'lodash/has';

// utils
import { getEditedValues } from 'services/utils';

import {
	fetchProductBundleSets,
	fetchProductRecommendations,
	fetchProductVariants,
	editProductBundleSet,
	editProductBundleSets,
	deleteProductBundleSet,
	addSalesConfigurationdefaultProductBundleSet,
} from './productBundleSets.service';

import moment from 'moment';
import phrases from './productBundleSets.phrases';

class ProductBundleSets extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.batchAddData = this.batchAddData.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);

		this.reduxKey = '/product/bundle_sets';

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => d.name || '',
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: (d) => d.description || '',
				filterPath: ':description',
			},
			{
				Header: 'Sort order',
				id: 'sort_order',
				accessor: (d) => d.sort_order || '',
				filterPath: ':sort_order',
			},
			{
				Header: 'Optional',
				id: 'optional',
				accessor: (d) => d.optional,
				filterPath: ':optional',
				Cell: (d) => <Checkbox checked={d.original.optional} noBorder disabled size="large" />,
			},
			{
				Header: 'Product recommendation',
				id: 'productRecommendation',
				accessor: (d) => _get(d, 'product_recommendation.name', ''),
				filterPath: ':product_recommendation.name',
			},
			{
				Header: 'Asset collection',
				id: 'asset_collection',
				accessor: (d) => _get(d, 'asset_collection.name', ''),
				filterPath: ':asset_collection.name',
				filterable: false,
				sortable: false,
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: 'legacy',
				filterPath: ':legacy',
				filterable: false,
				Cell: (d) => {
					return <Input id="checkbox" type="checkbox" checked={d.original.legacy} disabled />;
				},
			},
		];
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}
			>
				Legacy
			</Button>
		);
	}

	deleteEntry(id) {
		return deleteProductBundleSet(id);
	}

	editEntry() {
		const { productBundleSet, defaultProductBundleSet } = this.props;

		const editedValues = getEditedValues({
			newData: productBundleSet,
			oldData: defaultProductBundleSet,
		});

		const payload = {
			id: productBundleSet.id,
			name: editedValues.name ?? undefined,
			description: editedValues.description ?? undefined,
			sort_order: editedValues.sortOrder ?? undefined,
			...(_has(editedValues, 'assetCollection') && {
				asset_collection: editedValues.assetCollection?.id ?? null,
			}),
			...(_has(editedValues, 'productRecommendation') && {
				product_recommendation: editedValues.productRecommendation?.id ?? null,
			}),
			optional: editedValues.optional ?? undefined,
			legacy: editedValues.legacy ?? undefined,
		};

		return editProductBundleSet(payload);
	}

	editMultiple(selectedRows) {
		const { productBundleSet } = this.props;

		const payload = {
			...(productBundleSet.name && {
				name: productBundleSet.name,
			}),
			...(productBundleSet.description && {
				description: productBundleSet.description,
			}),
			...(productBundleSet.sortOrder && {
				sort_order: productBundleSet.sortOrder,
			}),
			...(productBundleSet.assetCollection && {
				asset_collection: productBundleSet.assetCollection.id,
			}),
			...(productBundleSet.productRecommendation && {
				product_recommendation: productBundleSet.productRecommendation.id,
			}),
			optional: productBundleSet.optional,
			legacy: productBundleSet.legacy,
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editProductBundleSets({
			batch: selectedRowsWithId,
		});
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			sortOrder: data.sort_order,
			...(data.asset_collection && {
				assetCollection: {
					value: data.asset_collection,
					label: data.asset_collection.name,
				},
			}),
			...(data.product_recommendation && {
				productRecommendation: {
					value: data.product_recommendation,
					label: data.product_recommendation.name,
				},
			}),
			optional: !!data.optional,
			legacy: !!data.legacy,
		};

		this.props.setProductBundleSets(payload);
	}

	getEditableCellsEdit() {
		const { productBundleSet } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={productBundleSet.name || ''}
						onChange={(event) => this.editStoreEntry('name', event)}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={productBundleSet.description || ''}
						onChange={(event) => this.editStoreEntry('description', event)}
					/>
				),
			},
			{
				header: 'Sort order',
				value: (
					<Input
						id="type"
						type="number"
						placeholder="Enter sort order..."
						value={productBundleSet.sortOrder || null}
						onChange={(event) => this.editStoreEntry('sortOrder', event)}
					/>
				),
			},
			{
				header: 'Optional',
				value: (
					<Toggle
						id="optional"
						onClick={(event) => this.editStoreEntry('optional', event)}
						toggled={productBundleSet.optional}
					/>
				),
			},
			{
				header: 'Product recommendation',
				value: (
					<InputCollectionSelect
						id="productRecommendation"
						value={productBundleSet.productRecommendation}
						handleChange={(key, value) => {
							this.editStoreEntry('productRecommendation', value);
						}}
						cache
						placeholder="Select product recommendation"
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						apiPath="/pos/product_recommendations"
						params={{
							limit: 30,
						}}
						tableColumns={[
							{
								Header: 'Name',
								id: 'name',
								accessor: 'name',
								filterPath: ':name',
							},
							{
								Header: 'Description',
								id: 'description',
								accessor: 'description',
								filterPath: ':description',
							},
						]}
						tableTitle={phrases.PRODUCT_RECOMMENDATIONS_TABLE_TITLE}
						tableReduxKey="/pos/product_recommendations_product-bundle-sets-edit-modal"
					/>
				),
			},
			{
				header: 'Asset Collection',
				value: (
					<InputCollectionSelect
						id="asset_collection"
						placeholder="Select asset collection"
						value={productBundleSet.assetCollection}
						handleChange={(key, value) => {
							this.editStoreEntry('assetCollection', value);
						}}
						clearable
						cache
						apiPath="/pos/asset_collections"
						params={{
							limit: 30,
							filter: `:legacy=='false'`,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						tableColumns={[
							{
								Header: 'Name',
								id: 'name',
								accessor: 'name',
								filterPath: ':name',
							},
							{
								Header: 'Description',
								id: 'desc',
								accessor: 'description',
								filterPath: ':description',
							},
							{
								Header: 'Legacy',
								id: 'legacy',
								accessor: 'legacy',
								filterPath: ':legacy',
								filterable: false,
								Cell: (d) => {
									return <Input type="checkbox" checked={d.value} disabled />;
								},
							},
						]}
						tableTitle={phrases.ASSET_COLLECTION_TABLE_TITLE}
						tableReduxKey="/pos/asset_collections_product-bundle-sets-edit-modal"
					/>
				),
			},
			{
				header: 'Legacy',
				value: (
					<Toggle
						id="legacy"
						onClick={(event) => this.editStoreEntry('legacy', event)}
						toggled={productBundleSet.legacy}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const value = e?.target?.value ?? e;

		const payload = {
			[name]: value,
		};

		this.props.updateProductBundleSet(payload);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	fetchData(state) {
		const { productBundle } = this.props;

		const productBundleId = productBundle.id;
		const filter = `:bundle=='${productBundleId}'`;

		return fetchProductBundleSets(filter, state);
	}

	batchAddData(data) {
		return addSalesConfigurationdefaultProductBundleSet(data).then(() => {
			this.setState(() => ({ key: moment.utc() }));
		});
	}

	render() {
		const {
			listProductRecommendations,
			batchList,
			editBatchListItem,
			removeBatchListItem,
			resetState,
			productBundle,
			addBatchListItemRow,
		} = this.props;

		const legacyFilter = this.state.legacyFilterOn ? this.state.legacyFilter : '';

		return (
			<>
				<ReactDataWrapper
					accessAreasAllowedToEdit={['Sales Configuration']}
					key={this.state.key}
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={`${this.reduxKey}-${productBundle.id}`}
					manual
					editEntry={() => this.editEntry()}
					editableCellsEdit={this.getEditableCellsEdit()}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={resetState}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					deleteConfirm
					actionRender={
						<Button type="inverted" label="Batch" shadow onClick={() => this.toggleModal()}>
							<Icon name="add" />
						</Button>
					}
					subcomponent={(row) => {
						const tabContent = [
							{
								name: phrases.PRODUCT_BUNDLE_SET_PRODUCT_VARIANTS_TABLE_TITLE,
								component: <ProductBundleSetProductVariants productBundleSet={row.original} />,
							},
							{
								name: phrases.PRODUCT_BUNDLE_SET_TRANSLATIONS_TABLE_TITLE,
								component: <ProductBundleSetTranslations id={row.original?.id ?? null} />,
							},
						];

						return (
							<SubTable>
								<Tabs tabContent={tabContent} />
							</SubTable>
						);
					}}
					customAreaComponents={this.getLegacyFilterButton()}
					extraFilters={legacyFilter}
				/>

				<ProductBundleSetsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					fetchProductRecommendations={fetchProductRecommendations}
					fetchProductVariants={fetchProductVariants}
					listProductRecommendations={listProductRecommendations}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					batchAddData={this.batchAddData}
					productBundleId={productBundle.id}
					addBatchListItemRow={addBatchListItemRow}
				/>
			</>
		);
	}
}

ProductBundleSets.propTypes = {
	productBundleSet: PropTypes.object,
	listProductRecommendations: PropTypes.object,
	setProductBundleSets: PropTypes.func,
	resetState: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	updateProductBundleSet: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	productBundle: PropTypes.object,
	defaultProductBundleSet: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			resetState,
			editBatchListItem,
			removeBatchListItem,
			setProductBundleSets,
			updateProductBundleSet,
			setPosConfigurationdefaultProductBundleSetsDateFilter,
			setListData,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		batchFormData: store.salesConfigurationProductBundleSets.data.batchFormData,
		batchList: store.salesConfigurationProductBundleSets.data.batchList,
		posConfigurationdefaultProductBundleSetsDateFilter:
			store.salesConfigurationProductBundleSets.posConfigurationdefaultProductBundleSetsDateFilter,
		listProductRecommendations: store.listData['/pos/product_recommendations'],
		listProductBundleSets: store.listData['/product/bundle_sets'],
		productBundleSet: store.salesConfigurationProductBundleSets.data.productBundleSet,
		defaultProductBundleSet: store.salesConfigurationProductBundleSets.data.defaultProductBundleSet,
	};
};

export default connectWithStore(ProductBundleSets, mapStateToProps, mapDispatchToProps);
