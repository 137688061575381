import React from 'react';
import { Outlet } from 'react-router-dom';

import { CurrenciesPrMonthReport } from 'finance/currenciesPrMonthReport';
import GoldModelPage from 'finance/goldModel/goldModel.component.js';
import TileDirectory from 'smart/tileDirectory/tileDirectory';

export const financeRoutes = [
	{
		path: 'finance',
		title: 'finance',
		element: <Outlet />,
		icon: require('@mui/icons-material/SavingsOutlined').default,
		children: [
			{
				index: true,
				element: <TileDirectory pathName="finance" />,
			},
			{
				element: <CurrenciesPrMonthReport />,
				path: 'currencies-pr-month',
				title: 'Currencies pr month',
				description: 'View Currencies Pr Month',
				allowedAccessControlLevels: [
					'End of day Admin',
					'Finance/CurrenciesPerMonth::Edit',
				],
			},
			{
				element: <GoldModelPage />,
				path: 'gold-model',
				description: 'View profit and loss',
				allowedAccessControlLevels: [
					'End of day Admin',
					'Finance/GoldModel::Edit',
				],
				title: 'Gold model',
			},
		],
	},
];
