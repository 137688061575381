'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';

// batch view
import AddEditBasketRecommendationPrioritiesForm from './components/addEditBasketRecommendationPrioritiesForm';

// utils
import { formatReplacements } from './../../utils';

// phrases
import phrases from './../../basketRecommendationPriorities.phrases';

import './addEditBasketRecommendationPriorities.css';

class AddEditBasketRecommendationPriorities extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};

		this._batchSubmit = this._batchSubmit.bind(this);
	}

	_batchSubmit() {
		const {
			batchFormData,
			handleClose,
			addBasketRecommendationPriority,
			editBasketRecommendationPriority,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		let payload = {
			...(batchFormData.editMode && {
				id: batchFormData.id,
				legacy: !!batchFormData.legacy,
			}),
			name: batchFormData.name,
			description: batchFormData.description,
			type: batchFormData.type?.value,
			...(batchFormData.sortOrder && { sort_order: batchFormData.sortOrder }),
		};

		// format selected replacemens
		const formattedReplacements = formatReplacements(
			batchFormData.replacements
		);

		payload = {
			...payload,
			...formattedReplacements,
		};

		const funcToExecute = batchFormData.editMode
			? editBasketRecommendationPriority(payload)
			: addBasketRecommendationPriority(payload);

		funcToExecute
			.then(() => {
				handleClose();
			})
			.finally(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	render() {
		const {
			modalVisible,
			handleClose,
			batchFormData,
			updateBatchForm,
		} = this.props;

		const steps = [
			{
				component: (
					<AddEditBasketRecommendationPrioritiesForm
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
					/>
				),
				title: batchFormData.editMode
					? phrases.EDIT_BASKET_RECOMMENDATION_PRIORITY
					: phrases.ADD_BASKET_RECOMMENDATION_PRIORITY,
				isValid: !!batchFormData.name,
				onNext: this._batchSubmit,
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.SUBMIT,
				noScroll: false,
			},
		];

		return modalVisible ? (
			<ModalStepper
				isOpen={modalVisible}
				steps={steps}
				showStep={false}
				onClose={handleClose}
			/>
		) : null;
	}
}

AddEditBasketRecommendationPriorities.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	batchFormData: PropTypes.array,
	updateBatchForm: PropTypes.func,
	addBasketRecommendationPriority: PropTypes.func,
	editBasketRecommendationPriority: PropTypes.func,
};

export default AddEditBasketRecommendationPriorities;
