'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { store } from 'appState';

import {
	Button,
	InputCollectionSelect,
	ButtonGroup,
	Overlay,
	ModalRow,
} from 'dumb';
import { Tip } from 'dumb/tips';
import { ReactDataWrapper } from 'reactDataWrapper';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';
import { fetchLayoutTileGroups } from '../../../../tileLayout.service';

// lodash
import _get from 'lodash/get';

// phrases
import phrases from '../../../../tileLayout.phrases';

// styles
import './formSelectLayoutGroups.css';

class FormSelectLayoutGroups extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
			showOverlay: false,
		};

		this._onOverlayOpen = this._onOverlayOpen.bind(this);

		this.reduxKey = 'tile_layouts/overlay/pos/tile_layout_groups/';

		this.columns = [
			{
				Header: 'Id',
				id: 'id',
				accessor: (d) => _get(d, 'id', null),
				show: false,
				filterPath: ':id',
			},
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', null),
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: (d) => _get(d, 'description', null),
				filterPath: ':description',
			},
			{
				Header: 'Tile layout name',
				id: 'tile_layout',
				accessor: (d) => _get(d, 'tile_layout.name', null),
				filterPath: ':tile_layout.name',
			},
			{
				Header: 'Asset collection name',
				id: 'asset_collection',
				accessor: (d) => _get(d, 'asset_collection.name', null),
				filterPath: ':asset_collection.name',
			},
		];
	}

	editStoreEntry(e, type) {
		const { batchForm } = this.props;

		const payload = {
			...batchForm,
			[type]: e,
		};

		this.props.updateBatchForm(payload);
	}

	_onOverlayOpen(batchForm) {
		const layoutGroups = _get(batchForm, 'tile_layout_groups', []);
		if (layoutGroups.length) {
			layoutGroups.map((x) => {
				store.dispatch(
					actions.addRowToSelection({
						reduxKey: this.reduxKey,
						data: x.value,
					})
				);
			});
		}
	}

	_toggleOverlay() {
		const { listLayoutGroupsData } = this.props;

		if (this.state.showOverlay) {
			let selectedRowsInList = _get(
				listLayoutGroupsData,
				'ui.selectedRows',
				[]
			);

			selectedRowsInList = selectedRowsInList.map((x) => {
				return {
					label: x.name,
					value: x,
				};
			});
			this.editStoreEntry(selectedRowsInList, 'tile_layout_groups');
		}

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
		}));
	}

	fetchData(state) {
		return fetchLayoutTileGroups(state);
	}

	render() {
		const { batchForm, tip, reorderProductsMode } = this.props;

		const filter = `:tile_layout.type=='APP'`;

		return (
			<>
				<ModalRow>
					<ButtonGroup type="full-width">
						<InputCollectionSelect
							id="layout-col"
							required
							label={phrases.TILE_LAYOUT_GROUPS}
							placeholder={phrases.TILE_LAYOUT_GROUPS_SELECT}
							value={batchForm.tile_layout_groups}
							handleChange={(key, value) =>
								this.editStoreEntry(value, 'tile_layout_groups')
							}
							multi
							cache
							apiPath="/pos/tile_layout_groups"
							params={{
								limit: 50,
								filter,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: reorderProductsMode
									? `${entry.name} - ${_get(entry, 'tile_layout.name', '')}`
									: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>

						<Button
							type="inverted"
							shadow
							size="large"
							onClick={() => this._toggleOverlay()}>
							<span className="icon icon--more" />
						</Button>
					</ButtonGroup>
				</ModalRow>

				<Overlay
					zIndex={551}
					height={850}
					visible={this.state.showOverlay}
					onClose={() => this._toggleOverlay()}>
					<div className="modal-batch__overlay">
						<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
							title={phrases.TILE_LAYOUT_GROUPS}
							columns={this.columns}
							fetchData={this.fetchData}
							filterable
							disableFetchCsvButton
							enableMultiSelection
							batchSelection
							defaultPageSize={20}
							onInitialization={() => this._onOverlayOpen(batchForm)}
							reduxKey={this.reduxKey}
							manual
							actionsWidth={0}
							style={{
								maxHeight: '720px',
							}}
							extraFilters={filter}
						/>
					</div>
				</Overlay>

				<div className="form-select-layout-groups__tip">
					<Tip content={tip} />
				</div>
			</>
		);
	}
}

FormSelectLayoutGroups.propTypes = {
	batchForm: PropTypes.object,
	updateBatchForm: PropTypes.func,
	listLayoutGroupsData: PropTypes.object,
	tip: PropTypes.string,
	reorderProductsMode: PropTypes.bool,
};

FormSelectLayoutGroups.defaultProps = {
	batchForm: {},
	tip: phrases.TIP_GROUPS,
	reorderProductsMode: false,
};

export default FormSelectLayoutGroups;
