'use strict';

import React, { useState, MutableRefObject } from 'react';

// interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';
import { IMarketTagsReduxValue, IMarketTagsCRUD } from './types';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import { setmarketTag, resetState } from './store/marketTags.actions';

// components
import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import getColumns from 'reactDataWrapperColumns/shiftplanning/marketTags.columns';
import MarketTagsModalBatch from './components/batch/marketTagsModalBatch';
import MarketTagsEditableCells from './components/marketTagsEditableCells';

// components
import { Button, Tooltip, Icon } from 'dumb';

// services
import {
	fetchMarketTags,
	deleteMarketTags,
	editMarketTag,
	editMarketTags,
} from './marketTags.service';

// constants/phrases/tools
import phrases from './marketTags.phrases';
import getEditedValues from 'services/utils/getEditedValues';

type MarketTagsProps = {
	marketId?: number;
	tagId?: number;
};

const MarketTags = ({ marketId, tagId }: MarketTagsProps): JSX.Element => {
	const reduxKey = `/shiftplanning/market_tags-${marketId ? 'market' : 'tag'}-${
		marketId || tagId
	}`;

	// hooks
	const { marketTag, defaultMarketTag, tableKey } = useAppSelector((state) => ({
		marketTag: state.marketTags.data.marketTag,
		defaultMarketTag: state.marketTags.data.defaultMarketTag,
		tableKey: state.marketTags.tableKey,
	}));
	const dispatch = useAppDispatch();
	const [showBatchModal, setShowBatchModal] = useState(false);

	const editEntry = () => {
		if (!marketTag.id || !defaultMarketTag) return Promise.resolve(true);

		const editedValues = getEditedValues<typeof marketTag>({
			oldData: defaultMarketTag,
			newData: marketTag,
		});

		const payload: Partial<IMarketTagsCRUD> = {
			market: marketId || editedValues?.market?.id,
			tag: tagId || editedValues?.tag?.id,
			default_length_in_minutes: editedValues?.defaultLengthInMinutes,
			minutes_from_start_of_shift: editedValues?.minutesFromStartOfShift,
			minutes_from_end_of_shift: editedValues?.minutesFromEndOfShift,
		};

		return editMarketTag(marketTag.id, payload);
	};

	const editMultiple = (selectedRows: IShiftplanningMarketTag[]) => {
		const payload = {
			default_length_in_minutes: marketTag.defaultLengthInMinutes,
			minutes_from_start_of_shift: marketTag.minutesFromStartOfShift,
			minutes_from_end_of_shift: marketTag.minutesFromEndOfShift,
		};

		const body = selectedRows.map((entry) => ({
			id: entry.id,
			...payload,
		}));

		return editMarketTags(body);
	};

	const deleteEntry = (id: number) => {
		return deleteMarketTags(id);
	};

	const setInitialEditValues = (data: IShiftplanningMarketTag) => {
		const payload: IMarketTagsReduxValue = {
			id: data.id,
			market: {
				value: data.market,
				label: data.market.name,
			},
			tag: {
				value: data.tag,
				label: data.tag.name,
			},
			defaultLengthInMinutes: data.default_length_in_minutes,
			minutesFromStartOfShift: data.minutes_from_start_of_shift,
			minutesFromEndOfShift: data.minutes_from_end_of_shift,
			singleEditMode: true,
		};

		dispatch(setmarketTag(payload));
	};

	const toggleShowBatchModal = () => setShowBatchModal(!showBatchModal);
	const source = marketId ? 'market' : 'tag';

	return (
		<>
			<ReactDataWrapper
				dataCy="market-tags-table"
				key={tableKey}
				title={phrases.TABLE_TITLE}
				columns={getColumns(source)}
				fetchData={(
					state: IFetchDataProps
				): ReturnType<typeof fetchMarketTags> =>
					fetchMarketTags({ marketId, tagId, state })
				}
				filterable
				defaultPageSize={10}
				reduxKey={reduxKey}
				manual
				accessAreasAllowedToEdit={[
					'Organisation/Workplaces::Edit',
					'Organization Admin',
				]}
				editableCells={MarketTagsEditableCells({
					source,
				})}
				actionsWidth={80}
				editEntry={editEntry}
				editMultiple={editMultiple}
				deleteEntry={deleteEntry}
				setInitialEditValues={setInitialEditValues}
				onModalClose={() => dispatch(resetState())}
				onModalCloseConfirm
				actionRender={
					<Tooltip
						text={phrases.BATCH_ADD}
						renderData={(
							ref: MutableRefObject<undefined>,
							onMouseEnter: () => void,
							onMouseLeave: () => void
						) => (
							<Button
								label="Add"
								shadow
								type="inverted"
								onClick={toggleShowBatchModal}
								refProp={ref}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
								dataCy="batch-add-button"
							>
								<Icon name="add" />
							</Button>
						)}
					/>
				}
			/>

			{showBatchModal && (
				<MarketTagsModalBatch
					onClose={toggleShowBatchModal}
					source={source}
					marketId={marketId}
					tagId={tagId}
				/>
			)}
		</>
	);
};

export default MarketTags;
