import {
	SET_MODAL_DATA,
	UPDATE_MODAL_DATA,
	RESET_MODAL_DATA,
	SET_SHOW_MODAL,
} from './clockinDeviationApproval.actions';

const defaultState = {
	data: null,
	showModal: false,
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_MODAL_DATA:
			return {
				...state,
				data: payload,
			};

		case UPDATE_MODAL_DATA:
			return {
				...state,
				data: state.data.map((entry) => {
					if (entry.id === payload.id) return payload;

					return entry;
				}),
			};

		case RESET_MODAL_DATA:
			return defaultState;

		case SET_SHOW_MODAL:
			return {
				...state,
				showModal: payload,
			};

		default:
			return state;
	}
}

export default reducer;
