import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// components
import { DragSource, DropTarget } from 'react-dnd';
import { Modal } from 'dumb';

// phrases
import phrases from './tile.phrases';

// Tools
import cx from 'classnames';
import _flow from 'lodash/flow';
import _get from 'lodash/get';

import './tile.css';

const ItemTypes = {
	TILE: 'tile',
	VARIANT: 'Product-variant',
};

const tileSource = {
	beginDrag(props) {
		return {
			id: props.tile.id,
			index: props.index,
		};
	},
};

const tileTarget = {
	drop(props, monitor) {
		const item = monitor.getItem();
		const type = monitor.getItemType();
		const dragIndex = item.index;
		const hoverIndex = props.index;

		switch (type) {
			case ItemTypes.VARIANT:
				if (!props.tileIsPresent)
					props.openTileModal(
						item,
						props.position,
						props.coordinates,
						item.type
					);

				break;
			case ItemTypes.TILE:
				// Don't replace items with themselves
				if (dragIndex === hoverIndex) {
					return;
				}

				// Time to actually perform the action
				props.onDrop(dragIndex, hoverIndex);

				// Note: we're mutating the monitor item here!
				// Generally it's better to avoid mutations,
				// but it's good here for the sake of performance
				// to avoid expensive index searches.
				monitor.getItem().index = hoverIndex;
				break;
			default:
				console.info('DnD reacting weird. There must be some problem.');
		}
	},

	hover(props, monitor) {
		if (monitor.didDrop()) {
			// If you want, you can check whether some nested
			// target already handled drop
			return;
		}

		// You can do something with it
		monitor.isOver({ shallow: true });
	},
};

class Tile extends PureComponent {
	constructor(props) {
		super(props);

		this.openTileModal = this.openTileModal.bind(this);
		this.removeTile = this.removeTile.bind(this);
		this.showSubgroupInTileView = this.showSubgroupInTileView.bind(this);
		this.toggleDeleteModal = this.toggleDeleteModal.bind(this);

		this.state = {
			isConfirmModalOpen: false,
			isModalLoading: false,
		};
	}

	toggleDeleteModal(e) {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}

		this.setState(() => ({
			isConfirmModalOpen: !this.state.isConfirmModalOpen,
		}));
	}

	removeTile() {
		const { tile, removeTile } = this.props;
		this.setState(() => ({
			isModalLoading: true,
		}));

		if (!tile.id) {
			this.toggleDeleteModal();
			return;
		}

		removeTile(tile.id).finally(() => {
			this.setState(() => ({
				isModalLoading: false,
			}));
			this.toggleDeleteModal();
		});
	}

	showSubgroupInTileView(e) {
		const { tile, setMenuItemInFocus } = this.props;

		e.stopPropagation();
		setMenuItemInFocus(tile.tile_layout_sub_group.id, tile.type);
	}

	openTileModal() {
		const { openTileModal, tile, position, coordinates } = this.props;
		// if it has product_variant its a tile product tile variant
		const type = tile.type || '';

		openTileModal(tile, position, coordinates, type);
	}

	render() {
		const {
			tileIsPresent,
			isDragging,
			backgroundImages,
			connectDragSource,
			connectDropTarget,
			tile,
			coordinates,
			flexWidth,
			isOverCurrent,
		} = this.props;

		const tileProductVariants = tile.tile_layout_tile_product_variants || [];
		// const { showMenu } = this.state;

		const tileClassNames = cx('tile', {
			'tile--dragging': isDragging,
			'tile--is-over': isOverCurrent,
			'tile--occupied': tileIsPresent,
			'tile--single': tileProductVariants.length === 1,
			'tile--double': tileProductVariants.length === 2,
			'tile--group': tile.type === 'Group',
		});

		let tileImage = backgroundImages.filter((x) => x.type === 'Product Tile');
		tileImage = _get(tileImage[0], 'resolution_one.url', null);
		const backgroundImage = tileImage
			? {
					backgroundImage: `url(${tileImage})`,
			  }
			: {};

		const style = {
			color: tile.title_color ? `#${tile.title_color}` : '#000',
			backgroundColor: tile.background_color
				? `#${tile.background_color}`
				: '#fff',
			flexBasis: `${flexWidth}%`,
		};

		const element = connectDropTarget(
			<div
				ref={(elm) => {
					this.tile = elm;
				}}
				className={tileClassNames}
				onClick={this.openTileModal}
				style={{ ...backgroundImage, ...style }}
			>
				<div className="tile__button-group tile__button-group--left">
					<div>
						({coordinates.page}.{coordinates.x}.{coordinates.y})
					</div>
					{tileIsPresent && <div>Type: {tile.type}</div>}
					{tileProductVariants.length > 0 && (
						<div> # of products {tileProductVariants.length}</div>
					)}
				</div>

				{tileIsPresent && (
					<div className="tile__bottom">
						<span style={{ color: style.color }} className="product">
							{tile.name}
						</span>
					</div>
				)}

				<span className="tile__button-group tile__button-group--right">
					<>
						<span
							onClick={this.toggleDeleteModal}
							className="icon icon--delete"
						/>

						{tile.type === 'Group' && (
							<span
								onClick={this.showSubgroupInTileView}
								className="icon icon--folder_open"
							/>
						)}
					</>
				</span>
			</div>
		);

		// If it is an empty tile, dont allow drag
		if (!tileIsPresent) {
			return element;
		}

		// Allow as dragSource if a tile is present
		return (
			<>
				{connectDragSource(element)}

				{this.state.isConfirmModalOpen && (
					<Modal
						header={phrases.MODAL_HEADER}
						onClose={this.toggleDeleteModal}
						confirmButtonLabel={phrases.CONFIRM_DELETE}
						type="confirmation"
						onConfirmClick={this.removeTile}
						onCancelClick={this.toggleDeleteModal}
						isOpen={this.state.isConfirmModalOpe}
						loading={this.state.isModalLoading}
						zIndex="550"
					/>
				)}
			</>
		);
	}
}

Tile.defaultProps = {
	tile: {
		id: null,
		position: null,
		name: '',
		title_color: '',
		background_color: '',
		description: '',
		tile_layout_tile_product_variants: {
			name: null,
		},
	},
};

Tile.propTypes = {
	connectDragSource: PropTypes.func.isRequired,
	connectDropTarget: PropTypes.func.isRequired,
	isDragging: PropTypes.bool.isRequired,
	tileIsPresent: PropTypes.bool,
	position: PropTypes.any,
	openTileModal: PropTypes.func,
	tile: PropTypes.object,
	coordinates: PropTypes.object,
	flexWidth: PropTypes.number,
	setMenuItemInFocus: PropTypes.func,
	removeTile: PropTypes.func,
	isOverCurrent: PropTypes.bool,
	backgroundImages: PropTypes.array,
};

export default _flow(
	DragSource(ItemTypes.TILE, tileSource, (connect, monitor) => ({
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging(),
	})),
	DropTarget(
		[ItemTypes.TILE, ItemTypes.VARIANT],
		tileTarget,
		(connect, monitor) => ({
			connectDropTarget: connect.dropTarget(),
			isOverCurrent: monitor.isOver({ shallow: true }),
		})
	)
)(Tile);
