import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Report from 'common/components/report';

import DailyReport from 'structure/reports/dailyReports/dailyReport.container';
import EmployeeMealsReport from 'structure/reports/employeeMealsReport/employeeMeals.container';
import POSReports from 'structure/reports/posReports/posReports';
import SalaryAuditReportsContainer from 'structure/reports/salary/auditReports.container';
import ShiftplannerReport from 'structure/reports/shiftplannerReport/shiftplannerReport.container';
import TileDirectory from 'smart/tileDirectory/tileDirectory';
// import PowerBiDirectory from 'smart/powerBiDirectory/powerBiDirectory';

const KPIReports = React.lazy(() =>
	import(/* webpackChunkName: "KPIReports" */ 'structure/reports/kpiReport/kpiReport.container')
);

const PowerBIReport = React.lazy(() =>
	import(/* webpackChunkName: "PowerBIReport" */ 'structure/reports/powerBIReport/powerBIReport.container')
);

export const reportsRoutes = [
	{
		element: <Outlet />,
		path: 'reports',
		title: 'reports',
		allowedAccessControlLevels: ['User Admin'],
		icon: require('@mui/icons-material/AssessmentOutlined').default,
		children: [
			{
				index: true,
				element: (
					<div>
						<TileDirectory pathName="reports" />
						{/* <PowerBiDirectory /> */}
					</div>
				),
			},
			{
				element: <DailyReport />,
				path: 'daily-reports',
				section: 'Legacy',
				title: 'Daily reports',
				description: 'View Daily Reports',
				allowedAccessControlLevels: ['Daily Report'],
			},
			{
				element: <EmployeeMealsReport />,
				path: 'employee-meals',
				title: 'Employee meals',
				description: 'View Employee meals report',
				allowedAccessControlLevels: ['Employee Meals Report'],
			},
			{
				element: (
					<Suspense fallback="Loading">
						<KPIReports />
					</Suspense>
				),
				path: 'kpi-report',
				title: 'KPI Report',
				description: 'View KPI report ',
				allowedAccessControlLevels: ['KPI Report'],
			},
			{
				element: <Report report={POSReports} />,
				path: 'pos-reports',
				title: 'POS reports',
				description: 'View POS Reports',
				allowedAccessControlLevels: ['POS Report'],
			},
			{
				element: <SalaryAuditReportsContainer />,
				path: 'salary-audit',
				title: 'Salary audit',
				description: 'View Salary audit Report',
				allowedAccessControlLevels: ['Salary Audit Report'],
			},
			{
				element: <ShiftplannerReport />,
				path: 'shiftplanner-report',
				title: 'Shiftplanner report',
				description: 'View Shiftplanner Report',
				allowedAccessControlLevels: ['Shiftplanner Report'],
			},

			{
				element: (
					<Suspense fallback="Loading">
						<PowerBIReport />
					</Suspense>
				),
				path: 'power-bi-report/:powerBIReportId',
				title: 'Power bi report',
				index: true,
				description: 'View Power BI report',
				allowedAccessControlLevels: ['Employee Meals Report'],
			},
		],
	},
];
