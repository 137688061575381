export default {
	ASSOCIATED_INGREDIENTS_TABLE_NAME: 'Associated ingredients',
	MODAL_HEADER_POS_CONFIGURATION_INGREDIENT: 'Pos configuration ingredient',
	MODAL_HEADER_MINIMUM: 'Minimum',
	MODAL_HEADER_DEFAULT: 'Default',
	MODAL_HEADER_MAXIMUM: 'Maximum',
	MODAL_HEADER_DEDUCTIBLE: 'Deductible',
	MODAL_HEADER_SORT_ORDER: 'Sort order',
	ASSOCIATED_INGREDIENT_ADDED: 'Associated ingredient added!',
	ASSOCIATED_INGREDIENT_EDITED: 'Associated ingredient edited!',
	ASSOCIATED_INGREDIENT_DELETED: 'Associated ingredient deleted!',
	MODAL_BATCH_STEP_POS_CONFIGURATION_INGREDIENT_TABLE_TITLE:
		'Product ingredients',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'Next',
	MODAL_BATCH_STEP_FORM_TITLE: 'Select entries',

	// batch table
	MODAL_BATCH_STEP_LIST_TITLE: 'Confirm entries',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_LIST_LEGEND_CANT_ADD: 'Associated ingredient already exists',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'Back',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Confirm',
};
