'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, Icon, InputCollectionSelect, Toggle } from 'dumb';
import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';
import { ModalRow } from 'dumb/modal';
import { DateFilterSelector } from 'reactDataWrapper/utilities';

// phrases/ enums
import phrases from './../../../marketShiftDeviationReasons.phrases';
import enums from './../../../marketShiftDeviationReasons.enums';

// utils
import { getSelectOptionsFromEnums } from 'services/utils';

// Tools
import _get from 'lodash/get';

class MarketShiftDeviationReasonsBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		const value = e?.target?.value ?? e;

		// edits the row in the store
		this.props.editBatchListItem({
			identifier: row.identifier,
			[type]: value,
		});
	}

	render() {
		const { batchList, addBatchListItemRow } = this.props;

		return (
			<>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_NAME}</th>
							<th>{phrases.MODAL_BATCH_STEP_DESCRIPTION}</th>
							<th>{phrases.MODAL_BATCH_STEP_MARKET}</th>
							<th>{phrases.MODAL_BATCH_STEP_TYPE}</th>
							<th>{phrases.MODAL_BATCH_STEP_REQUIRES_MANAGER_APPROVAL}</th>

							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((entry, i) => {
							const deleteButtonDisabled = batchList.length === 1;

							return (
								<TableListRow key={entry.identifier}>
									<TableListCell>{i + 1}</TableListCell>

									<TableListCell>
										<Input
											id={`name-${entry.identifier}`}
											placeholder="Enter name..."
											value={entry.name}
											required
											onChange={(event) =>
												this._editListEntry(event, entry, 'name')
											}
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`description-${entry.identifier}`}
											clearable={false}
											placeholder="Enter description..."
											value={entry.description}
											onChange={(event) =>
												this._editListEntry(event, entry, 'description')
											}
										/>
									</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id={`market-${entry.identifier}`}
											clearable={false}
											placeholder={phrases.MARKET}
											value={entry?.market || null}
											handleChange={(key, e) =>
												this._editListEntry(e, entry, 'market')
											}
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											required
											params={{
												limit: 300,
											}}
											apiPath="/administration/markets"
											tableTitle="Markets"
											tableColumns={[
												{
													Header: 'Name',
													id: 'name',
													accessor: 'name',
													filterPath: ':name',
												},
												{
													Header: 'Country',
													id: 'country',
													accessor: (d) => _get(d, 'country.name', ''),
													filterPath: ':country.name',
												},
												{
													Header: 'Market region',
													id: 'market_region',
													accessor: (d) => _get(d, 'market_region.name', ''),
													filterPath: ':market_region.name',
												},
												{
													Header: 'Tax label',
													id: 'tax_label',
													accessor: 'tax_label',
													filterPath: ':tax_label',
												},
												{
													Header: 'Opened',
													id: 'opened',
													accessor: 'opened',
													filterPath: ':opened',
													Filter: ({ column }) => (
														<DateFilterSelector
															reduxKey="/administration/markets_market-shift-deviation-reason-batch-modal"
															columnId={column.id}
															dateIdentifier="from"
															zIndex={600}
														/>
													),
												},
												{
													Header: 'Closed',
													id: 'closed',
													accessor: 'closed',
													filterPath: ':closed',
													Filter: ({ column }) => (
														<DateFilterSelector
															reduxKey="/administration/markets_market-shift-deviation-reason-batch-modal"
															columnId={column.id}
															dateIdentifier="to"
															zIndex={600}
														/>
													),
												},
											]}
											tableReduxKey="/administration/markets_market-shift-deviation-reason-batch-modal"
										/>
									</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id={`type-${entry.identifier}`}
											clearable={false}
											placeholder="Select type..."
											options={getSelectOptionsFromEnums(enums)}
											required
											onChange={(e) => this._editListEntry(e, entry, 'type')}
											value={_get(entry, 'type', null)}
										/>
									</TableListCell>

									<TableListCell>
										<Toggle
											id={`requires-manager-approval-${entry.identifier}`}
											toggled={entry.requiresManagerApproval}
											onClick={(e) =>
												this._editListEntry(e, entry, 'requiresManagerApproval')
											}
										/>
									</TableListCell>

									<TableListCell>
										<Button
											type="negative"
											size="tiny"
											disabled={deleteButtonDisabled}
											onClick={() => this._onRemoveEntry(entry.identifier)}>
											<Icon name="delete" />
										</Button>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>

				<ModalRow
					typeAddNewRow
					tooltipZIndex={600}
					tooltipText={phrases.MODAL_BATCH_STEP_LIST_TOOLTIP}
					onClick={addBatchListItemRow}
				/>
			</>
		);
	}
}

MarketShiftDeviationReasonsBatchStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
};

export default MarketShiftDeviationReasonsBatchStepList;
