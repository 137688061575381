export default {
	BACK: 'Back',
	NEXT: 'Next',
	SKIP: 'Skip',
	APPROVED: 'Approved',
	DECLINED: 'Declined',
	CLOSE: 'Close',
	SUBMIT: 'Submit',
	SUBMIT_AND_CLOSE: 'Submit and close',
	SHIFT_TO_ACCEPT: 'Shift to accept',
	ACCEPTED: 'Accepted',
	REJECTED: 'Rejected',
	SHIFT: 'Shift',
	BAR: 'Bar',
	APPLICANTS: 'Applicants',
	MANAGER_PAGE_TIP:
		'Approve or decline an applicant and press next to submit the choice',
	BAR_TRANSFERS: 'Bar transfers',
	SHIFT_TRANSFERS: 'Shift transfers',
	YOUR_TRANSFERS: 'Your transfers',
	APPLIED_SHIFTS: 'Applied shifts',
	STATE: 'State',
	CANNOT_CANCEL_TRANSFER: 'Cannot cancel shift transfer...',
	CANNOT_CANCEL_APPLICATION: 'Cannot cancel shift application...',
	ACTIONS: 'Actions',
};
