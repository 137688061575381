import React, { Component } from 'react';
import PropTypes from 'prop-types';

// redux
import { store } from 'appState';
import { editGroup } from './../../../addGroupModal/store/addGroupModal.actions';

// components
import { Card, Icon } from 'dumb';
import CategoryItem from './categoryItem';
import AddGroup from './../../../addGroupModal/addGroupModal';
import AppLayoutLoader from './../appLayoutLoader/appLayoutLoader';

// helpers
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';

import './categoryList.css';
class CategoryList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			categories: [],
			categoriesPositions: [],
			selectedCategory: {},
			activeGroup: {}
		};

		this.handleToggleAddGroupModal = this.handleToggleAddGroupModal.bind(this);
		this.handleAddItem = this.handleAddItem.bind(this);
		this.handleDeleteClick = this.handleDeleteClick.bind(this);
		this.handleEditItem = this.handleEditItem.bind(this);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (!_isEqual(nextProps.groups, prevState.categories)) {
			const categoriesPositions = Object.keys(nextProps.groups)
				.map(x => nextProps.groups[x])
				.sort((a, b) => {
					return Number(a.position) - Number(b.position);
				});

			return { categories: nextProps.groups, categoriesPositions };
		} else return null;
	}

	moveCard(dragIndex, hoverIndex) {
		// const { updateLayoutGroup } = this.props;
		const { categoriesPositions } = this.state;

		const dragCard = categoriesPositions[dragIndex];
		const groupsClone = _cloneDeep(categoriesPositions);

		groupsClone.splice(dragIndex, 1);
		groupsClone.splice(hoverIndex, 0, dragCard);

		this.setState(() => ({ categoriesPositions: groupsClone }));
	}

	onDrop() {
		const { categoriesPositions } = this.state;

		const payload = categoriesPositions.map((item, index) => {
			return { id: item.id, position: index + 1 };
		});

		this.props.rotateLayoutGroup({ rotations: payload }, true);
	}

	handleToggleAddGroupModal() {
		this.setState(prevState => ({
			showAddGroupModal: !prevState.showAddGroupModal
		}));
	}

	handleAddItem(payload) {
		const { addLayoutGroup, layoutId } = this.props;

		this.setState({ loading: true });

		return addLayoutGroup(payload, layoutId)
			.then(() => {
				this.setState({ loading: false });
			})
			.catch(() => {
				this.setState({ loading: false });
			});
	}

	handleEditItem(groupData) {
		const { updateLayoutGroup } = this.props;

		this.setState({ loading: true });

		return updateLayoutGroup(groupData, groupData.id)
			.then(() => {
				this.setState({ loading: false });
			})
			.catch(() => {
				this.setState({ loading: false });
			});
	}

	handleDeleteClick(id, e) {
		e.preventDefault();
		e.stopPropagation();

		this.props.deleteLayoutGroup(id);
	}

	handleEditClick(groupData, e) {
		e.preventDefault();
		e.stopPropagation();
		store.dispatch(editGroup(groupData));
		this.setState(prevState => ({
			showAddGroupModal: !prevState.showAddGroupModal
		}));
	}

	render() {
		const { imageAssets } = this.props;
		const { categoriesPositions, showAddGroupModal } = this.state;

		return (
			<Card className="loyalty-pos-categories">
				<AppLayoutLoader loading={this.props.fetching} />

				{categoriesPositions.map((item, i) => {
					if (!item.show_in_menu) return null;

					return (
						<CategoryItem
							setMenuItemInFocus={this.props.setMenuItemInFocus}
							item={item}
							key={item.id}
							index={i}
							imageAsset={imageAssets.find(
								asset =>
									item.asset_collection &&
									asset.asset_collection.id === item.asset_collection.id
							)}
							moveCard={(dragIndex, hoverIndex) =>
								this.moveCard(dragIndex, hoverIndex)
							}
							onDrop={() => this.onDrop()}
							handleDeleteClick={this.handleDeleteClick}
							handleEditClick={e => this.handleEditClick(item, e)}
							groupItemInFocus={this.props.groupItemInFocus}
						/>
					);
				})}

				<div
					className="category-item loyalty-pos-categories__placeholder"
					onClick={() => this.handleToggleAddGroupModal()}>
					<Icon name="add" className="loyalty-pos-categories__add-icon" />
					<span className="loyalty-pos-categories__add-text">
						Add menu item
					</span>
				</div>

				<AddGroup
					handleClose={() =>
						this.setState(prevState => ({
							showAddGroupModal: !prevState.showAddGroupModal
						}))
					}
					visible={showAddGroupModal}
					title="Add Group"
					nextPosition={categoriesPositions.length + 1}
					imageAssets={imageAssets}
					updateLayoutGroup={this.handleEditItem}
					addLayoutGroup={this.handleAddItem}
				/>
			</Card>
		);
	}
}

CategoryList.propTypes = {
	imageAssets: PropTypes.array,
	addLayoutGroup: PropTypes.func,
	updateLayoutGroup: PropTypes.func,
	deleteLayoutGroup: PropTypes.func,
	rotateLayoutGroup: PropTypes.func,
	layoutId: PropTypes.number,
	fetching: PropTypes.bool,
	setMenuItemInFocus: PropTypes.func,
	groupItemInFocus: PropTypes.number,
	groups: PropTypes.object
};

export default CategoryList;
