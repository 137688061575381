import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './marketShiftAlignmentRules.phrases';

/**
 * @function fetchMarketShiftAlignmentRules
 * @description Fetch all matket shift alignment rules
 * @param {object} state
 */
export function fetchMarketShiftAlignmentRules(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get(
		'/shiftplanning/market_shift_alignment_rules',
		params,
		null,
		state.headers
	)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addMarketShiftAlignmentRules
 * @description add matket shift alignment rules
 */
export function addMarketShiftAlignmentRules(data) {
	return post('/shiftplanning/market_shift_alignment_rules', data)
		.then((res) => {
			setFeedback(phrases.MARKET_SHIFT_ALIGNMENT_RULE_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editMarketShiftAlignmentRule
 * @description edit matket shift alignment rule
 */
export function editMarketShiftAlignmentRule(data) {
	return post(`/shiftplanning/market_shift_alignment_rules/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.MARKET_SHIFT_ALIGNMENT_RULE_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function editMarketShiftAlignmentRules
 * @description edit matket shift alignment rules
 */
export function editMarketShiftAlignmentRules(data) {
	return patch('/shiftplanning/market_shift_alignment_rules', data)
		.then((res) => {
			setFeedback(phrases.MARKET_SHIFT_ALIGNMENT_RULES_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function deleteMarketShiftAlignmentRule
 * @description delete matket shift alignment rule
 */
export function deleteMarketShiftAlignmentRule(id) {
	return remove(`/shiftplanning/market_shift_alignment_rules/${id}`)
		.then((res) => {
			setFeedback(phrases.MARKET_SHIFT_ALIGNMENT_RULE_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
