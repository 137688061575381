/**
 * Created by mikkelmarkvardsen on 08/08/2016.
 */

/**
 * Constants
 * @type {Object}
 */

export default {
	dayMonthFormat: 'MM/DD',
	timeShort: 'HH:mm',
	hour: 'HH',
	minute: 'mm',
	monthFormat: 'mm',
	minuteFormat: 'mm',
	monthOnlyFormat: 'MMMM',
	nameOfWeekday: 'dddd',
	nameOfWeekdayShort: 'ddd',
	yearOnlyFormat: 'YYYY',
	dateFormat: 'YYYY-MM-DDTHH:mm:ss',
	dayFormat: 'HH:mm:ss',
	shortDate: 'YYYY-MM-DD',
	assignView: 'ASSIGN_VIEW',
	weekView: 'WEEK_VIEW',
	admin: 'Admin',
	barManager: 'Bar Manager',
	regionalManager: 'Regional Manager',
	shiftplan: 'Shiftplan',
	juicer: 'Juicer',
	hrManager: 'HQ HR Manager',
	dragTypes: {
		SHIFT: 'Shift',
		STAFF: 'Staff',
		UNASSIGNED: 'Unassigned',
	},
};
