'use strict';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// import ButtonLoader from 'buttonLoader';
// import ButtonFeedback from 'buttonFeedback';
import { ButtonFeedback, ButtonLoader } from 'dumb';
import cx from 'classnames';

import './buttonFeedbackGroup.css';

export default class ButtonFeedbackGroup extends PureComponent {

	render() {
		const { fetching, feedbackType, theme, noOverlay } = this.props;

		const className = cx('button-feedback-container', {
			'button-feedback-container--overlay': !noOverlay
		});

		const updating = fetching || !!feedbackType;
		return updating ? (
			<div className={className}>
				<ButtonLoader loading={fetching} theme={theme} />
				<ButtonFeedback showFeedback={feedbackType} theme={theme} />
			</div>
		) : null;
	}
}

ButtonFeedbackGroup.propTypes = {
	feedbackType: PropTypes.oneOf(['success', 'error', '']).isRequired,
	theme: PropTypes.oneOf(['light', 'dark']).isRequired,
	fetching: PropTypes.bool,
	noOverlay: PropTypes.bool
};

ButtonFeedbackGroup.defaultProps = {
	feedbackType: '',
	theme: 'light',
	fetching: false,
	noOverlay: false
};