'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { batch } from 'react-redux';

// components
import { Input, InputCollectionSelect, Button, Icon, Tooltip } from 'dumb';
import { PageTips } from 'dumb/tips';
import { ReactDataWrapper } from 'reactDataWrapper';
import PowerBIReportsModalBatch from './components/batch/powerBIReportsModalBatch';
import getPowerBiReportTypeColumns from 'reactDataWrapperColumns/reporting/powerBiReportTypes.columns';
import CopyPowerBiReportModal from './components/copyPowerBiReportModal/copyPowerBiReportModal';
import PowerBiReportDataSets from './components/powerBiReportDataSets/powerBiReportDataSets.container';
import SubTable from 'reactDataWrapper/components/subTable';

import {
	fetchPowerBIReports,
	addPowerBIReports,
	deletePowerBIReports,
	editPowerBIReport,
	editPowerBIReports,
} from './powerBIReports.service';

import {
	// batch stuff
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setPowerBIReport,
	updatePowerBIReport,
	resetPowerBIReport,

	// copy power bi reports
	setSelectedPowerBiReports,
	editGlobalCopyReportValue,
	editSelectedPowerBiReport,
	editMarkets,
} from './store/powerBIReports.actions';
import { resetSelection } from 'reactDataWrapper/reactDataWrapper.actions';

// utils
import { push } from 'redux-first-history';
import store from 'appState/store';
import { getEditedValues } from 'services/utils';
import { formatSelectedPowerBiReports } from './utils';
import tips from './powerBiReports.tips';

// constants/phrases/tools
import phrases from './powerBIReports.phrases';

// lodash
import _get from 'lodash/get';
import _has from 'lodash/has';

import './style.css';

const reduxKey = '/reporting/power_bi_reports';

class PowerBIReports extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
			showCopyReportModal: false,
		};

		this.fetchData = this.fetchData.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.getEditableCells = this.getEditableCells.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.addPowerBIReporWrapper = this.addPowerBIReporWrapper.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.getActionRender = this.getActionRender.bind(this);
		this.toggleCopyReportModal = this.toggleCopyReportModal.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: 'name',
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: 'description',
				filterPath: ':description',
			},
			{
				Header: 'Type',
				id: 'type',
				accessor: (d) => _get(d, 'type.name', ''),
				filterPath: ':type.name',
			},
			{
				Header: 'Market',
				id: 'market',
				accessor: (d) => _get(d, 'market.name', ''),
				filterPath: ':market.name',
			},
			{
				Header: 'Sort Order',
				id: 'sortOrder',
				accessor: 'sort_order',
				filterPath: ':sort_order',
			},
			{
				Header: 'Power BI ID',
				id: 'powerBIID',
				accessor: 'power_bi_id',
				filterPath: ':power_bi_id',
			},
			{
				Header: 'Access granting area',
				id: 'accessGrantingArea',
				accessor: (d) => _get(d, 'access_granting_area.name', ''),
				filterPath: ':access_granting_area.name',
			},
		];
	}

	editEntry() {
		const { powerBIReport, originalPowerBIReport } = this.props;

		const editedValues = getEditedValues({
			newData: powerBIReport,
			oldData: originalPowerBIReport,
		});

		const payload = {
			id: powerBIReport.id,
			name: editedValues.name,
			...(_has(editedValues, 'powerBiId') && {
				power_bi_id: editedValues.powerBiId,
			}),
			...(_has(editedValues, 'description') && {
				description: editedValues.description,
			}),
			...(_has(editedValues, 'sortOrder') && {
				sort_order: editedValues.sortOrder,
			}),
			...(_has(editedValues, 'accessGrantingArea') && {
				access_granting_area: editedValues.accessGrantingArea.id,
			}),
			...(_has(editedValues, 'type') && {
				type: editedValues.type.id,
			}),
		};

		return editPowerBIReport(payload);
	}

	editMultiple(selectedRows) {
		const { powerBIReport } = this.props;

		const payload = {
			...(powerBIReport.name && {
				name: powerBIReport.name,
			}),
			...(powerBIReport.description && {
				description: powerBIReport.description,
			}),
			...(powerBIReport.sortOrder && {
				sort_order: powerBIReport.sortOrder,
			}),
			...(powerBIReport.accessGrantingArea && {
				access_granting_area: powerBIReport.accessGrantingArea.value.id,
			}),
			...(powerBIReport.powerBiId && {
				power_bi_id: powerBIReport.powerBiId,
			}),
			...(powerBIReport.type && {
				type: powerBIReport.type.value.id,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editPowerBIReports({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deletePowerBIReports(id);
	}

	setInitialEditValues(data) {
		const { setPowerBIReport } = this.props;

		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			sortOrder: data.sort_order,
			powerBiId: data.power_bi_id,
			...(data.access_granting_area && {
				accessGrantingArea: {
					value: data.access_granting_area,
					label: data.access_granting_area.name,
				},
			}),
			type: {
				value: data.type,
				label: data.type.name,
			},
			...(data.market && {
				market: data.market.name,
			}),
			singleEdit: true,
		};

		setPowerBIReport(payload);
	}

	getEditableCells() {
		const { powerBIReport } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={powerBIReport?.name || ''}
						onChange={(e) => this.editStoreEntry('name', e.target.value)}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={powerBIReport?.description || ''}
						onChange={(e) => this.editStoreEntry('description', e.target.value)}
					/>
				),
			},
			{
				header: 'Type',
				value: (
					<InputCollectionSelect
						id="type"
						name="type"
						placeholder="Select type..."
						apiPath="/reporting/power_bi_report_types"
						value={powerBIReport.type}
						handleChange={(key, event) => this.editStoreEntry('type', event)}
						params={{
							limit: 30,
						}}
						cache
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						clearable={false}
						tableColumns={getPowerBiReportTypeColumns()}
						tableReduxKey="power-bi-reports/reporting/power_bi_report_types"
						tableTitle={phrases.MODAL_BATCH_STEP_TYPES}
					/>
				),
			},
			...(powerBIReport.singleEdit
				? [
						{
							header: 'Market',
							value: <span>{powerBIReport.market}</span>,
						},
				  ]
				: []),
			{
				header: 'Sort order',
				value: (
					<Input
						id="sortOrder"
						type="number"
						placeholder="Enter sort order..."
						value={powerBIReport?.sortOrder || ''}
						onChange={(e) => this.editStoreEntry('sortOrder', e.target.value)}
					/>
				),
			},
			{
				header: 'Power BI Id',
				value: (
					<Input
						id="powerBiId"
						placeholder="Enter power bi id..."
						value={powerBIReport?.powerBiId || ''}
						onChange={(e) => this.editStoreEntry('powerBiId', e.target.value)}
					/>
				),
			},
			{
				header: 'Access granting area',
				value: (
					<InputCollectionSelect
						id="accessGrantingArea"
						name="accessGrantingArea"
						placeholder="Select access granting area..."
						apiPath="/authentication/areas"
						value={powerBIReport.accessGrantingArea}
						handleChange={(key, event) =>
							this.editStoreEntry('accessGrantingArea', event)
						}
						params={{
							limit: 30,
						}}
						cache
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						// little hack since they share same columns (not counting sort order)
						tableColumns={getPowerBiReportTypeColumns()}
						tableReduxKey="power-bi-reports/authentication/areas"
						tableTitle={phrases.MODAL_BATCH_STEP_ACCESS_GRANTING_AREAS}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const { updatePowerBIReport } = this.props;

		let value;
		if (e === null) value = e;
		else value = e.target ? e.target.value : e;

		const payload = {
			[name]: value,
		};

		updatePowerBIReport(payload);
	}

	fetchData(state) {
		return fetchPowerBIReports(state);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetPowerBIReport();
	}

	addPowerBIReporWrapper(data) {
		return addPowerBIReports(data).then(() => {
			this.setState(() => ({
				key: moment.utc(),
			}));
		});
	}

	_renderLink(e) {
		return (
			<Tooltip
				text={phrases.VIEW_POWER_BI_REPORT_TOOLTIP}
				placement="left"
				renderData={(ref, onMouseEnter, onMouseLeave) => (
					<Button
						type="inverted"
						shadow
						title="Rules"
						onClick={() =>
							store.dispatch(
								push(`/reports/power-bi-report/${e.original.power_bi_id}`)
							)
						}
						size="micro"
						refProp={ref}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
					>
						<Icon name="tune" />
					</Button>
				)}
			/>
		);
	}

	getActionRender() {
		const { selectedRows } = this.props;

		const copyButtonDisabled = selectedRows.length === 0;

		return (
			<>
				<Button
					type="inverted"
					label={phrases.BATCH}
					shadow
					onClick={this.toggleModal}
				>
					<Icon name="add" />
				</Button>
				<Tooltip
					text={phrases.COPY_TOOLTIP}
					placement="left"
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							type="inverted"
							label={phrases.COPY}
							shadow
							onClick={this.toggleCopyReportModal}
							disabled={copyButtonDisabled}
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
						>
							<Icon name="content_copy" />
						</Button>
					)}
				/>
			</>
		);
	}

	toggleCopyReportModal() {
		const {
			setSelectedPowerBiReports,
			selectedRows,
			resetPowerBIReport,
			resetSelection,
		} = this.props;

		// if modal not show, set the data in redux
		if (!this.state.showCopyReportModal) {
			const formattedData = formatSelectedPowerBiReports({ selectedRows });

			setSelectedPowerBiReports(formattedData);
		} else {
			batch(() => {
				resetSelection({ reduxKey });
				resetPowerBIReport();
			});
		}

		this.setState((prevState) => ({
			showCopyReportModal: !prevState.showCopyReportModal,
		}));
	}

	render() {
		const {
			batchList,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
			globalPowerBiReport,
			selectedReports,
			editGlobalCopyReportValue,
			editSelectedPowerBiReport,
			originalSelectedReports,
			markets,
			editMarkets,
			setSelectedPowerBiReports,
		} = this.props;

		return (
			<>
				<PageTips tips={tips} id="powerBiReports" />

				<ReactDataWrapper
					key={this.state.key}
					title={phrases.TABLE_TITLE}
					dataCy="power-bi-reports-table"
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={reduxKey}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					deleteEntry={this.deleteEntry}
					accessAreasAllowedToEdit={[
						'Global BI Configuration',
						'Global HR Configuration',
					]}
					editMultiple={this.editMultiple}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetPowerBIReport}
					actionRender={this.getActionRender()}
					actions={this._renderLink}
					actionsWidth={30}
					subcomponent={(row) => (
						<SubTable>
							<PowerBiReportDataSets id={row.original?.id ?? null} />
						</SubTable>
					)}
				/>

				<PowerBIReportsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addPowerBIReports={this.addPowerBIReporWrapper}
				/>

				{this.state.showCopyReportModal && (
					<CopyPowerBiReportModal
						selectedReports={selectedReports}
						globalPowerBiReport={globalPowerBiReport}
						editGlobalCopyReportValue={editGlobalCopyReportValue}
						editSelectedPowerBiReport={editSelectedPowerBiReport}
						onClose={this.toggleCopyReportModal}
						onSubmit={this.addPowerBIReporWrapper}
						originalSelectedReports={originalSelectedReports}
						markets={markets}
						editMarkets={editMarkets}
						setSelectedPowerBiReports={setSelectedPowerBiReports}
					/>
				)}
			</>
		);
	}
}

PowerBIReports.propTypes = {
	setPowerBIReport: PropTypes.func,
	updatePowerBIReport: PropTypes.func,
	powerBIReport: PropTypes.object,
	originalPowerBIReport: PropTypes.object,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	resetPowerBIReport: PropTypes.func,
	batchList: PropTypes.object,
	selectedRows: PropTypes.array,
	setSelectedPowerBiReports: PropTypes.func,
	editGlobalCopyReportValue: PropTypes.func,
	editSelectedPowerBiReport: PropTypes.func,
	selectedReports: PropTypes.array,
	originalSelectedReports: PropTypes.array,
	globalPowerBiReport: PropTypes.object,
	resetSelection: PropTypes.func,
	editMarkets: PropTypes.func,
	markets: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setPowerBIReport,
			updatePowerBIReport,
			resetPowerBIReport,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			addBatchListItemRow,
			setSelectedPowerBiReports,
			editGlobalCopyReportValue,
			editSelectedPowerBiReport,
			resetSelection,
			editMarkets,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		powerBIReport: store.globalAdministrationPowerBIReports.data.powerBIReport,
		originalPowerBIReport:
			store.globalAdministrationPowerBIReports.data.originalPowerBIReport,
		batchList: store.globalAdministrationPowerBIReports.data.batchList,

		// used for copy-report feature
		selectedRows: store.listData?.[reduxKey]?.ui?.selectedRows ?? [],
		globalPowerBiReport:
			store.globalAdministrationPowerBIReports.data.copyPowerBiReport
				.globalPowerBiReport,
		selectedReports:
			store.globalAdministrationPowerBIReports.data.copyPowerBiReport
				.selectedReports,
		originalSelectedReports:
			store.globalAdministrationPowerBIReports.data.copyPowerBiReport
				.originalSelectedReports,
		markets:
			store.globalAdministrationPowerBIReports.data.copyPowerBiReport.markets,
	};
};

export default connectWithStore(
	PowerBIReports,
	mapStateToProps,
	mapDispatchToProps
);
