import {post} from 'api.vanilla.service';
import {set as setFeedback} from 'feedback.vanilla.service';
import endpoints from 'endpoints.service';

/**
 * @function uploadCurrenciesPrMonthCsv
 * @description Parse and upload csv data
 * @param csvData
 * @returns {Promise.<TResult>}
 */
export default function uploadCurrenciesPrMonthCsv(csvData) {

	return csvData.getResultsAsJSON().then(jsonData => {

		const currenciesPrMonthData = jsonData.map(entry => ({
			code: entry.ISOCODE,
			year: entry.Year,
			month: entry.Month,
			rate: entry.Value
		}));

		const reporting_currency_rates = [
			...currenciesPrMonthData
		];

		return new Promise((resolve, reject) => {
			post(endpoints.collection.reporting.reporting_currency_rates.index.path + '/batches', {reporting_currency_rates}).then(response => {
				resolve(response);
			}, response => {
				let errorMsg = response.code + ': ' + response.message;
				setFeedback(errorMsg, 0);
				reject(errorMsg);
			});

		}, () => {

			// console.log(err);
		});
	});

}