import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './users.phrases';

/**
 * @function fetchUsers
 * @description Fetch all users
 * @param {object} state
 */
export function fetchUsers(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/authentication/users', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addUser
 * @description adds a single user
 */
export function addUser(data) {
	return post('/authentication/users', data)
		.then((res) => {
			setFeedback(phrases.USER_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editUser
 * @description edits a single user
 */
export function editUser(data) {
	return post(`/authentication/users/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.USER_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editUsers
 * @description edits one or more users
 */
export function editUsers(data) {
	return patch('/authentication/users', data)
		.then((res) => {
			setFeedback(phrases.USERS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteUsers
 * @description deletes users
 */
export function deleteUsers(productId) {
	return remove(`/authentication/users/${productId}`)
		.then((res) => {
			setFeedback(phrases.USERS_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function sendActivationCode
 * @description sends a new activation code to the user
 */
export function sendActivationCode(payload) {
	return post('/authentication/user_activation_keys', payload)
		.then(() => {
			setFeedback(phrases.ACTIVATION_CODE_SENT, 1);
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function reset2Fa
 * @description resets multi factor authentication for a user
 */
export function reset2Fa(payload) {
	return post(
		`/authentication/users/${payload.id}/multi_factor_authentication`,
		payload
	)
		.then(() => {
			setFeedback(phrases.TWO_FACTOR_RESET, 1);
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
