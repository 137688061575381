'use strict';

var __endpointFamiliy = require('endpointFamiliy.service');

var _accounting = new __endpointFamiliy('accounting', [
	'account_schedule_accounts',
	'account_schedules',
	'global_accounts',
	'workplace_salary_accounts',
	'company_account_schedules'
]);

var _administration = new __endpointFamiliy('administration', [
	'banks',
	'companies',
	'nationalities',
	'company_addresses',
	'company_bank_accounts',
	'company_capital_transactions',
	'company_fiscal_years',
	'company_to_store_relations',
	'company_to_workplace_relations',
	'company_vat_numbers',
	'continents',
	'countries',
	'country_to_currency_relations',
	'currencies',
	'currency_denominations',
	'currency_rates',
	'market_regions',
	'market_tax_rates',
	'markets',
	'mailinglist_recipients',
	'region_managers',
	'regions',
	'store_area_types',
	'store_continent_tags',
	'store_forecast_tags',
	'store_franchise_fees',
	'store_location_types',
	'store_regions',
	'store_to_workplace_relations',
	'stores',
	'workplace_addresses',
	'workplace_opening_hours',
	'workplace_sizes',
	'workplaces',
	'workplace_attributes',
	'workplace_attribute_types',
	'tax_cards',
	'territories',
	'tax_tables',
	'workplace_responsibilities',
	'region_responsibilities',
	'market_responsibilities',
	'workplace_third_party_references'
]);

var _authentication = new __endpointFamiliy('authentication', [
	'resource_access_control_lists',
	'resource_field_access_control_lists',
	'resources',
	'user_access_tokens',
	'user_activation_keys',
	'user_roles',
	'users'
]);

var _campaigning = new __endpointFamiliy('campaigning', ['loyalty_types']);

var _financial = new __endpointFamiliy('financial', [
	'profit_and_loss_monthly_actuals',
	'profit_and_loss_monthly_forecasts',
	'profit_and_loss_monthly_actuals_batch',
	'profit_and_loss_monthly_forecasts_batch',
	'profit_and_loss_daily_actuals',
	'profit_and_loss_daily_forecasts',
	'profit_and_loss_daily_actuals_batch',
	'profit_and_loss_daily_forecasts_batch',
	'forecasting_scenarios',
	'forecasts',
	'forecast_locations',
	'workplace_rents',
	'store_tags'
]);

var _hr = new __endpointFamiliy('hr', [
	'badge_categories',
	'badge_levels',
	'badges',
	'person_onboardings',
	'employment_available_days',
	'employment_bank_accounts',
	'employment_document_files',
	'employment_documents',
	'employment_ended_reasons',
	'employment_positions',
	'employment_to_salary_entitlement_relations',
	'employment_to_salary_group_relations',
	'employment_to_workplace_relations',
	'employment_vacations',
	'employment_workplaces',
	'employments',
	'global_job_title_levels',
	'global_job_titles',
	'grid_entries',
	'grids',
	'moneyball_positions',
	'moneyball_sub_positions',
	'market_onboardings',
	'onboardings',
	'onboarding_value_types',
	'onboarding_values',
	'person_addresses',
	'person_bank_accounts',
	'person_email_addresses',
	'person_group_members',
	'person_groups',
	'person_personal_information',
	'person_phone_numbers',
	'person_pictures',
	'person_skills',
	'persons',
	'positions',
	'salary_entitlement_global_types',
	'salary_entitlement_rules',
	'salary_entitlements',
	'salary_group_to_salary_entitlement_relations',
	'salary_groups',
	'salary_periods',
	'shift_tag_types',
	'shift_tags',
	'shift_template_skill_requirements',
	'shift_templates',
	'shifts',
	'skills',
	'person_attribute_types'
]);

var _organization = new __endpointFamiliy('organization', [
	'workplaces',
	'contacts'
]);

var _pos = new __endpointFamiliy('pos', [
	'pos_configuration_currencies',
	'pos_configuration_discount_pricings',
	'pos_configuration_discount_product_variants',
	'pos_configuration_discount_ingredients',
	'pos_configuration_discounts',
	'pos_configuration_ingredients',
	'pos_configuration_pricings',
	'pos_configuration_product_variants',
	'pos_configuration_tile_layout_groups',
	'pos_configuration_tile_layout_tiles',
	'pos_configuration_tile_layouts',
	'pos_configuration_versions',
	'pos_configurations',
	'pos_configuration_ingredient_addons',
	'tile_sizes',
	'tile_picture_assets',
	'tile_picture_asset_sizes',
	'workplace_to_pos_configuration_relations',
	'till_stations'
]);

var _profile = new __endpointFamiliy('profile', [
	'person_addresses',
	'person_bank_accounts',
	'person_email_addresses',
	'person_personal_information',
	'person_phone_numbers',
	'person_pictures',
	'persons',
	'users'
]);

var _salary = new __endpointFamiliy('salary', [
	'bar_manager_work_days',
	'company_payroll_periods',
	'employee_contributions',
	'global_salary_components',
	'reporting_salaries',
	'salary_component_rules',
	'salary_components',
	'salary_exports',
	'salary_groups',
	'salary_workplace_exports',
	'work_days',
	'workplace_salary_periods',
	'salary_component_daily_overtime_rules',
	'salary_component_split_shift_rules',
	'salary_component_days_per_week_rules',
	'salary_component_daily_supplement_rules',
	'salary_component_weekly_overtime_rules'
]);

var _reporting = new __endpointFamiliy('reporting', [
	'daily_report_entries',
	'daily_reports',
	'daily_2_reports',
	'key_performance_indicator_report_daily_entries',
	'key_performance_indicator_report_hourly_entries',
	'key_performance_indicator_reports',
	'php_reports',
	'forecasts',
	'pos_reports',
	'pos_reports/total',
	'reporting_currencies',
	'employee_meals_reports',
	'reporting_currency_rates',
	'turnover_achievements',
	'turnover_achievements_rules',
	'shiftplanner_report_daily_entries',
	'shiftplanner_report_hourly_entries',
	'shiftplanner_key_performance_indicators',
	'audit_salary_account_reports',
	'shiftplanner_reports',
	'turnover_extracts',
	'audit_salary_account_reports',
	'audit_salary_payroll_reports',
	'reporting_markets'
]);

var _sale = new __endpointFamiliy('sale', [
	'beginning_of_days',
	'end_of_days',
	'financial_balances',
	'discounts',
	'ingredient_categories',
	'ingredients',
	'member_discount_programs',
	'financial_pos_exports',
	'pos_item_ingredient_variants',
	'pos_item_product_variants',
	'pos_items',
	'pos_session_audits',
	'pos_session_deposits',
	'pos_session_sign_offs',
	'pos_sessions',
	'product_categories',
	'product_variant_categories',
	'product_variants',
	'products',
	'transaction_lines',
	'transactions',
	'drawer_to_bank_exports'
]);

var _product = new __endpointFamiliy('product', [
	'ingredient_categories',
	'ingredients',
	'product_categories',
	'product_variants',
	'products',
	'size_groups',
	'sizes',
	'production_areas'
]);

var _shiftPlanning = new __endpointFamiliy('shiftplanning', [
	'shifts',
	'shift_tags',
	'closed_days',
	'employments',
	'shift_clockings',
	'shift_clocking_corrections',
	'shift_periods',
	'vacations',
	'shift_employees',
	'market_tags',
	'workplace_tags',
	'shiftplan_forecast_values'
]);

var _tagging = new __endpointFamiliy('tagging', ['tags']);

var _social = new __endpointFamiliy('social', ['social_groups']);

var _statistics = new __endpointFamiliy('statistics', [
	'pos_sales_daily_statistics'
]);

var _training = new __endpointFamiliy('training', [
	'grade_levels',
	'grade_scales',
	'module_executions',
	'module_grade_levels',
	'module_categories',
	'modules',
	'reviews',
	'persons'
]);

var _utilities = new __endpointFamiliy('utilities', ['pdfs', 'mails']);

var endpoints = {
	administration: _administration.generate(),
	accounting: _accounting.generate(),
	authentication: _authentication.generate(),
	financial: _financial.generate(),
	campaigning: _campaigning.generate(),
	hr: _hr.generate(),
	organization: _organization.generate(),
	pos: _pos.generate(),
	profile: _profile.generate(),
	reporting: _reporting.generate(),
	sale: _sale.generate(),
	social: _social.generate(),
	product: _product.generate(),
	shiftPlanning: _shiftPlanning.generate(),
	salary: _salary.generate(),
	statistics: _statistics.generate(),
	tagging: _tagging.generate(),
	training: _training.generate(),
	utilities: _utilities.generate()
};

module.exports = endpoints;
