'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// regular stuff
	setLanguages,
	updateLanguages,
	resetState,
} from './store/posConfigurationTileLayouts.actions';

// services
import {
	fetchPosConfigurationTileLayouts,
	editPosConfigurationTileLayouts,
	addPosConfigurationTileLayout,
	deletePosConfigurationTileLayouts,
} from './posConfigurationTileLayouts.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Input, Tabs } from 'dumb';
import SubTable from 'reactDataWrapper/components/subTable';
import getColumns from 'reactDataWrapperColumns/pos/posConfigurationTileLayouts.columns';
import PosConfigurationLayout from './components/posConfigurationLayout/posConfigurationLayout.page';
import PosConfigurationTileLayoutGroupTranslations from './components/posConfigurationTileLayoutGroupTranslations/posConfigurationTileLayoutGroupTranslations.component';

// utils
import { getEditedValues } from 'services/utils';

// phrases
import phrases from './posConfigurationTileLayouts.phrases';

const reduxKey = '/pos/pos_configuration_tile_layouts';

class PosConfigurationTileLayouts extends Component {
	constructor(props) {
		super(props);

		this.addEntry = this.addEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.editEntry = this.editEntry.bind(this);

		this.columns = getColumns();
	}

	addEntry() {
		const { posConfigurationTileLayout, id } = this.props;

		const payload = {
			pos_configuration_version: id,
			name: posConfigurationTileLayout.name ?? undefined,
			description: posConfigurationTileLayout.description ?? undefined,
			rows: posConfigurationTileLayout.rows ?? undefined,
			columns: posConfigurationTileLayout.columns ?? undefined,
		};

		return addPosConfigurationTileLayout(payload);
	}

	editEntry() {
		const { posConfigurationTileLayout, originalPosConfigurationTileLayout } =
			this.props;

		const editedValues = getEditedValues({
			oldData: originalPosConfigurationTileLayout,
			newData: posConfigurationTileLayout,
		});

		const payload = {
			id: posConfigurationTileLayout.id,
			name: editedValues.name ?? undefined,
			description: editedValues.description ?? undefined,
			rows: editedValues.rows ?? undefined,
			columns: editedValues.columns ?? undefined,
		};

		return editPosConfigurationTileLayouts(payload);
	}

	deleteEntry(id) {
		return deletePosConfigurationTileLayouts(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			rows: data.rows,
			columns: data.columns,
		};

		this.props.setLanguages(payload);
	}

	getEditableCells() {
		const { posConfigurationTileLayout } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={posConfigurationTileLayout.name}
						onChange={(e) => this.editStoreEntry('name', e)}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={posConfigurationTileLayout.description}
						onChange={(e) => this.editStoreEntry('description', e)}
					/>
				),
			},
			{
				header: 'Rows',
				value: (
					<Input
						id="rows"
						placeholder="Enter rows..."
						value={posConfigurationTileLayout.rows}
						onChange={(e) => this.editStoreEntry('rows', e)}
					/>
				),
			},
			{
				header: 'Columns',
				value: (
					<Input
						id="columns"
						placeholder="Enter columns..."
						value={posConfigurationTileLayout.columns}
						onChange={(e) => this.editStoreEntry('columns', e)}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const { updateLanguages } = this.props;

		const value = e.target.value;

		const updateObject = {
			[name]: value,
		};

		updateLanguages(updateObject);
	}

	fetchData(state) {
		const { id } = this.props;

		const filter = `:pos_configuration_version.id=='${id}'`;

		return fetchPosConfigurationTileLayouts(state, filter);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	render() {
		const { id } = this.props;

		return (
			<ReactDataWrapper
				accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.TITLE}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={5}
				reduxKey={`${reduxKey}-${id}`}
				manual
				editableCells={this.getEditableCells()}
				createEntry={this.addEntry}
				editEntry={this.editEntry}
				deleteEntry={this.deleteEntry}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetState}
				subcomponent={(row) => {
					const tabContent = [
						{
							name: phrases.TILE_LAYOUT_GROUP,
							component: (
								<PosConfigurationTileLayoutGroupTranslations
									id={row.original.id}
								/>
							),
						},
						{
							name: phrases.POS_CONFIGURATION_LAYOUT,
							component: (
								<PosConfigurationLayout
									versionId={id}
									layoutId={row.original.id}
								/>
							),
						},
					];

					return (
						<SubTable>
							<Tabs tabContent={tabContent} />
						</SubTable>
					);
				}}
			/>
		);
	}
}

PosConfigurationTileLayouts.propTypes = {
	id: PropTypes.number,
	posConfigurationTileLayout: PropTypes.object,
	updateLanguages: PropTypes.func,
	setLanguages: PropTypes.func,
	resetState: PropTypes.func,
	originalPosConfigurationTileLayout: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateLanguages,
			setLanguages,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		posConfigurationTileLayout:
			store.posConfigurationTileLayoutsReducer.data.posConfigurationTileLayout,
		originalPosConfigurationTileLayout:
			store.posConfigurationTileLayoutsReducer.data
				.originalPosConfigurationTileLayout,
	};
};

export default connectWithStore(
	PosConfigurationTileLayouts,
	mapStateToProps,
	mapDispatchToProps
);
