'use strict';

import PropTypes from 'prop-types';
import cx from 'classnames';

import React, { PureComponent } from 'react';

// import ButtonLoader from 'buttonLoader';
import { ButtonLoader, Icon } from 'dumb';

class FileType extends PureComponent {
	constructor(props) {
		super(props);

		this.noErrors = {
			tooManyFiles: false,
			wrongExtension: false
		};

		this.state = {
			dragOver: false,
			...this.noErrors
		};

		this.fileTypeMethods = {
			getFileName: this.getFileName.bind(this),
			handleSetFile: this.handleSetFile.bind(this),
			onDragEnter: this.onDragEnter.bind(this),
			onDragFileDrop: this.onDragFileDrop.bind(this),
			onDragFileEnd: this.onDragFileEnd.bind(this),
			onDragLeave: this.onDragLeave.bind(this),
			onDragOver: this.onDragOver.bind(this),
			onEditFileName: this.onEditFileName.bind(this),
			onRemoveFile: this.onRemoveFile.bind(this),
			onSelectFile: this.onSelectFile.bind(this)
		};
	}

	// Checks for default file name or else just returns "real" file name
	getFileName(uploadedFileName) {
		const { defaultFileNameData, fileType } = this.props;

		// Extract file extension for later use
		const fileExtension = fileType.extractFileExtension(uploadedFileName);

		// Get default file name from service (could be null if not set in configuration)
		const defaultFileName = fileType.getDefaultFileName(defaultFileNameData);

		// Return uploadedFile if null, or return new defaultFileName combined with the extension and perhaps a file count
		return defaultFileName === null
			? uploadedFileName
			: `${defaultFileName}_${fileType.getNumber()}.${fileExtension}`;
	}

	handleSetFile(file, fileName) {
		const { fileUpload, fileType } = this.props;

		// Extract file extension
		const fileExtension = fileType.extractFileExtension(fileName);

		// Remove file extension
		fileName = fileType.removeExtension(fileName, fileExtension);

		// Update state and fileType service via container method
		this.setState(
			() => ({
				dragOver: false
			}),
			fileUpload.handleSetFile(fileType, file, fileName, fileExtension)
		);
	}

	onEditFileName(event) {
		const { fileUpload, fileType } = this.props;

		// Persist event
		event.persist();

		// Extract new fileName
		const fileName = event.target.value;

		// Set file name on input field
		this.fileName = fileName;

		// Update fileType service via container method
		fileUpload.handleSetFile(
			fileType,
			fileType.getFile(),
			fileName,
			fileType.getFileExtension()
		);
	}

	onRemoveFile() {
		// Update
		this.fileTypeMethods.handleSetFile(null, null);
	}

	onSelectFile(event) {
		const { fileType } = this.props;

		// Extract file from event
		const file = event.target.files[0];

		// Validate file extension
		if (
			!fileType.validateFileExtension(fileType.extractFileExtension(file.name))
		) {
			// Alert that extensions are wrong
			this.setState(
				() => ({ wrongExtension: true, dragOver: false }),
				this.fileTypeMethods.handleSetFile(null, null)
			);
			return;
		}

		// Remove all errors and update
		this.setState(
			() => ({ ...this.noErrors }),
			this.fileTypeMethods.handleSetFile(
				file,
				this.fileTypeMethods.getFileName(file.name)
			)
		);
	}

	onDragFileDrop(event) {
		const { fileType } = this.props;

		event.preventDefault();

		// Extract file(s) from event
		const files = event.nativeEvent.dataTransfer.files;

		// Only one file is allowed for drop
		if (files.length !== 1) {
			// Alert that only one file is allowed per file type
			this.setState(
				() => ({ tooManyFiles: true, dragOver: false }),
				this.fileTypeMethods.handleSetFile(null, null)
			);
			return;
		}

		// Get file
		const file = files[0];

		// Validate file extension
		if (
			!fileType.validateFileExtension(fileType.extractFileExtension(file.name))
		) {
			// Alert that extensions are wrong
			this.setState(
				() => ({ wrongExtension: true, dragOver: false }),
				this.fileTypeMethods.handleSetFile(null, null)
			);
			return;
		}

		// Remove all errors and update
		this.setState(
			() => ({ ...this.noErrors }),
			this.fileTypeMethods.handleSetFile(
				file,
				this.fileTypeMethods.getFileName(file.name)
			)
		);
	}

	onDragFileEnd(event) {
		event.preventDefault();

		// Clean up
		event.nativeEvent.dataTransfer.clearData();

		// Reset dragOvere
		this.setState(() => ({ dragOver: false }));
	}

	onDragOver(event) {
		event.preventDefault();
	}

	onDragEnter(event) {
		event.preventDefault();
		this.setState(() => ({ dragOver: true }));
	}

	onDragLeave(event) {
		event.preventDefault();
		this.setState(() => ({ dragOver: false }));
	}

	render() {
		const { fileType, phrases, sending } = this.props;

		const { dragOver, tooManyFiles, wrongExtension } = this.state;

		return (
			<div className="file-upload__file-type">
				<div className="file-upload__file-meta-data">
					<h5>
						{fileType.getName()}{' '}
						{fileType.getNumber() > 1 && `(${fileType.getNumber()})`}
					</h5>
					<p>{fileType.getExtensionsAsString()}</p>
				</div>
				{fileType.getFile() === null ? (
					<div
						className={cx('file-upload__file-drop-zone', {
							'file-upload__file-drop-zone--dropping': dragOver
						})}>
						<input
							type="file"
							id={`${fileType.getIdentifier()}_fileUpload`}
							accept={fileType.getExtensionsAsAccept()}
							onChange={this.fileTypeMethods.onSelectFile}
						/>
						<label
							htmlFor={`${fileType.getIdentifier()}_fileUpload`}
							onDragEnter={this.fileTypeMethods.onDragEnter}
							onDragOver={this.fileTypeMethods.onDragOver}
							onDragLeave={this.fileTypeMethods.onDragLeave}
							onDrop={this.fileTypeMethods.onDragFileDrop}
							onDragEnd={this.fileTypeMethods.onDragFileEnd}>
							<span>{phrases.DROP_FILE}</span>

							{(tooManyFiles || wrongExtension) && (
								<span className="file-upload__file-drop-zone__error">
									{tooManyFiles ? phrases.TOO_MANY_FILES : null}
									{wrongExtension
										? `${
											phrases.WRONG_EXTENSION
										  } ${fileType.getExtensionsAsString()}`
										: null}
								</span>
							)}
						</label>
					</div>
				) : (
					<div
						className={cx('file-upload__file-edit', {
							'file-upload__file-edit--dropping': dragOver
						})}>
						{sending && (
							<div className="file-upload__sending">
								<ButtonLoader theme="light" loading={sending} />
							</div>
						)}
						<div
							className="button file-upload__file-edit__remove"
							onClick={this.fileTypeMethods.onRemoveFile}>
							<Icon name="clear" className="select-clear" />
							<p>{phrases.REMOVE_FILE}</p>
						</div>
						<div
							className="file-upload__file-edit__rename"
							data-extension={fileType.getFileExtension()}>
							<input
								size="1"
								type="text"
								defaultValue={fileType.getFileName()}
								ref={input => (this.fileName = input)}
								onChange={this.fileTypeMethods.onEditFileName}
								onDragEnter={e => e.preventDefault()}
								onDragOver={e => e.preventDefault()}
								onDragLeave={e => e.preventDefault()}
								onDrop={e => e.preventDefault()}
								onDragEnd={e => e.preventDefault()}
							/>
						</div>
					</div>
				)}
			</div>
		);
	}
}

FileType.defaultProps = {
	fileUpload: {}
};

FileType.propTypes = {
	defaultFileNameData: PropTypes.object,
	fileType: PropTypes.object.isRequired,
	fileUpload: PropTypes.object.isRequired,
	fileUploadState: PropTypes.object,
	phrases: PropTypes.object.isRequired,
	sending: PropTypes.bool.isRequired
};

export default FileType;
