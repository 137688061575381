'use strict';

export const SET_MARKET_HOURLY_TABLE = 'DYNAMIC_POS_VIEW/SET_MARKET_HOURLY_TABLE';
export const SET_MARKET_PRODUCT_TABLE = 'DYNAMIC_POS_VIEW/SET_MARKET_PRODUCT_TABLE';
export const SET_MARKET_WORKPLACE_TABLE = 'DYNAMIC_POS_VIEW/SET_MARKET_WORKPLACE_TABLE';
export const SET_WORKPLACE_HOURLY_TABLE = 'DYNAMIC_POS_VIEW/SET_WORKPLACE_HOURLY_TABLE';
export const SET_WORKPLACE_PRODUCT_TABLE = 'DYNAMIC_POS_VIEW/SET_WORKPLACE_PRODUCT_TABLE';
export const TOGGLE_TABLE = 'DYNAMIC_POS_VIEW/TOGGLE_TABLE';
export const TOGGLE_PRODUCT_TABLE = 'DYNAMIC_POS_VIEW/TOGGLE_PRODUCT_TABLE';
export const SET_MARKET_HOVER_GROUP = 'DYNAMIC_POS_VIEW/SET_MARKET_HOVER_GROUP';


/**
 * @param {number} id
 * @param {object} workplaceTable
 */
export function setMarketWorkplaceTable(id, workplaceTable) {
	return {
		type: SET_MARKET_WORKPLACE_TABLE,
		payload: {
			id,
			workplaceTable
		}
	};
}

/**
 * @param {number} id
 * @param {object} hourlyTable
 */
export function setMarketHourlyTable(id, hourlyTable) {
	return {
		type: SET_MARKET_HOURLY_TABLE,
		payload: {
			id,
			hourlyTable
		}
	};
}

/**
 * @param {number} id
 * @param {object} productTable
 */
export function setMarketProductTable(id, productTable) {
	return {
		type: SET_MARKET_PRODUCT_TABLE,
		payload: {
			id,
			productTable
		}
	};
}

/**
 * @param {number} id
 * @param {object} hourlyTable
 */
export function setWorkplaceHourlyTable(id, hourlyTable) {
	return {
		type: SET_WORKPLACE_HOURLY_TABLE,
		payload: {
			id,
			hourlyTable
		}
	};
}

/**
 * @param {number} id
 * @param {object} productTable
 */
export function setWorkplaceProductTable(id, productTable) {
	return {
		type: SET_WORKPLACE_PRODUCT_TABLE,
		payload: {
			id,
			productTable
		}
	};
}


/**
 * @param {string} type
 * @param {string} id
 * @param {boolean} toggled
 */
export function toggleTable(type, id, toggled) {
	return {
		type: TOGGLE_TABLE,
		payload: {
			type,
			id,
			toggled
		}
	};
}

/**
 * @param {string} product
 * @param {string} id
 * @param {boolean} toggled
 */
export function toggleProductTable(product, id, toggled) {
	return {
		type: TOGGLE_PRODUCT_TABLE,
		payload: {
			product,
			id,
			toggled
		}
	};
}

/**
 * @param {string} table
 * @param {string} group
 */
export function setMarketHoverGroup(table, group) {
	return {
		type: SET_MARKET_HOVER_GROUP,
		payload: {
			table,
			group
		}
	};
}
