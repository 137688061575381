'use strict';

export const SET_HOTBAR = 'HOTBAR/SET_HOTBAR';
export const RESET_HOTBARS = 'HOTBAR/RESET_HOTBARS';

/**
 * @param {string} hotbarName
 * @param {object} hotbar
 */
export function setHotbar(hotbarName, hotbar) {
	return {
		type: SET_HOTBAR,
		payload: {
			hotbarName,
			hotbar,
		},
	};
}

/**
 * @function resetHotbars
 */
export function resetHotbars() {
	return {
		type: RESET_HOTBARS,
	};
}
