export default {
	TABLE_TITLE: 'Global Pos configuration discounts',

	POS_CONFIGURATION_DISCOUNTS_ADDED: 'Pos configuration discounts added!',
	POS_CONFIGURATION_DISCOUNT_EDITED: 'Pos configuration discount edited!',
	POS_CONFIGURATION_DISCOUNTS_EDITED: 'Pos configuration discounts edited!',
	POS_CONFIGURATION_DISCOUNTS_DELETED: 'Pos configuration discounts deleted!',

	MODAL_BATCH_STEP_FORM_TITLE: 'Select remaining values',
	MODAL_BATCH_STEP_FORM_TITLE_VERSION: 'Select Pos configuration versions',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'next',
	MODAL_BATCH_STEP_LIST_TITLE: 'Confirm entries',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'submit',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'back',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_POS_CONFIGURATION_VERSION: 'Pos configuration version',
	MODAL_BATCH_STEP_POS_CONFIGURATION_VERSION_PLACEHOLDER:
		'Select Pos configuration version...',
	MODAL_BATCH_STEP_DISCOUNT: 'Discount',
	MODAL_BATCH_STEP_DISCOUNT_PLACEHOLDER: 'Select discount...',
	MODAL_BATCH_STEP_LOYALTY_PRODUCT: 'Loyalty product',
	MODAL_BATCH_STEP_LOYALTY_PRODUCT_PLACEHOLDER: 'Select loyalty product...',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_FORM_DISCOUNTS_TABLE_TITLE: 'Discounts',
	MODAL_BATCH_STEP_FORM_LOYALTY_PRODUCTS_TABLE_TITLE: 'Loyalty products',
	MODAL_BATCH_STEP_FORM_POS_CONFIGURATION_VERSIONS_TABLE_TITLE:
		'Pos configuration versions',
	MODAL_BATCH_STEP_FORM_TAGS_TEXT: 'Selected POS Configuration Versions:',
	LEGACY_FILTER_TOOLTIP_ACTIVE: 'Showing non legacy POS Configurations',
	LEGACY_FILTER_TOOLTIP_UNACTIVE: 'Showing all POS Configurations',
};
