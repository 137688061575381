'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import { setNutritionCategory, updateNutritionCategory, resetState } from './store/nutritionCategories.actions';

// components
import SubTable from 'reactDataWrapper/components/subTable';
import { ReactDataWrapper } from 'reactDataWrapper';
import { Input, InputCollectionSelect, Button, Toggle } from 'dumb';
import getColumns from 'reactDataWrapperColumns/product/nutritionCategories.columns';
import NutritionCategoryTranslations from './component/nutritionCategoryTranslations/nutritionCategoryTranslations.component';

// services
import {
	fetchAllNutritionCategories,
	deleteNutritionCategories,
	editNutritionCategories,
	addNutritionCategories,
} from './store/nutritionCategories.service';

// misc
import _get from 'lodash/get';
import phrases from './nutritionCategories.phrases';
import { enums } from './nutritionCategories.enums';

// Wrapper for react-data
// Documentation can be found here https://react-table.js.org/
class NutritionCategories extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);

		this.state = {
			pages: null,
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this.fetchData = this.fetchData.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);

		this.columns = getColumns();
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}
			>
				Legacy
			</Button>
		);
	}

	deleteEntry(id) {
		return deleteNutritionCategories(id);
	}

	editEntry() {
		const { defaultValues } = this.props;

		const payload = {
			name: defaultValues.name,
			sort_order: defaultValues.sortOrder,
			type: _get(defaultValues, 'type.value', enums.TYPE[0]),
			parent_nutrition_category: _get(defaultValues, 'parentNutritionCategory.id', ''),
			eu_unit: _get(defaultValues, 'euUnit', ''),
			us_unit: _get(defaultValues, 'usUnit', ''),
			legacy: !!defaultValues.legacy,
		};

		return editNutritionCategories(defaultValues.id, payload);
	}

	addEntry() {
		const { defaultValues } = this.props;

		const payload = {
			name: defaultValues.name,
			sort_order: defaultValues.sortOrder,
			type: _get(defaultValues, 'type.value', enums.TYPE[0]),
			parent_nutrition_category: _get(defaultValues, 'parentNutritionCategory.id', ''),
			eu_unit: _get(defaultValues, 'euUnit', ''),
			us_unit: _get(defaultValues, 'usUnit', ''),
		};

		return addNutritionCategories(payload);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			name: data.name,
			sortOrder: data.sort_order,
			type: data.type || 'Nutrient',
			parentNutritionCategory: data.parent_nutrition_category,
			euUnit: _get(data, 'eu_unit', ''),
			usUnit: _get(data, 'us_unit', ''),
			legacy: data.legacy,
		};

		this.props.setNutritionCategory(payload);
	}

	getEditableCells() {
		const { defaultValues } = this.props;

		let filter = '';
		if (defaultValues.id) {
			filter = `:id!='${defaultValues.id}'`;
		}

		return [
			{
				header: 'Category Name',
				value: (
					<Input
						id="name"
						placeholder="name"
						value={_get(defaultValues, 'name', '')}
						onChange={(event) => this.editStoreEntry(event, 'name')}
					/>
				),
			},
			{
				header: 'Parent Nutrition Category',
				value: (
					<InputCollectionSelect
						id="parentNutritionCategory"
						placeholder="select parent category"
						value={
							_get(defaultValues, 'parentNutritionCategory.id', false)
								? {
										value: _get(defaultValues, 'parentNutritionCategory.id', ''),
										label: `${_get(defaultValues, 'parentNutritionCategory.name', '')}`,
								  }
								: null
						}
						handleChange={(key, value) =>
							this.editStoreEntry(
								{
									id: value ? value.value : '',
									name: value ? value.label : '',
								},
								'parentNutritionCategory'
							)
						}
						clearable={false}
						cache
						apiPath="/product/nutrition_categories"
						params={{
							limit: 300,
							filter,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Sort order',
				value: (
					<Input
						id="sortOrder"
						type="number"
						placeholder="sort order"
						value={_get(defaultValues, 'sortOrder', '')}
						onChange={(event) => this.editStoreEntry(event, 'sortOrder')}
					/>
				),
			},
			{
				header: 'Type',
				value: (
					<Input
						id="Type"
						type="select"
						value={_get(defaultValues, 'type', {
							value: enums.TYPE[0],
							label: enums.TYPE[0],
						})}
						onChange={(event) => this.editStoreEntry(event, 'type')}
						options={enums.TYPE.map((x) => ({ value: x, label: x }))}
						clearable={false}
					/>
				),
			},
			{
				header: 'EU unit',
				value: (
					<Input
						id="euUnit"
						placeholder="EU unit"
						value={_get(defaultValues, 'euUnit', '')}
						onChange={(event) => this.editStoreEntry(event, 'euUnit')}
					/>
				),
			},
			{
				header: 'US unit',
				value: (
					<Input
						id="usUnit"
						placeholder="US unit"
						value={_get(defaultValues, 'usUnit', '')}
						onChange={(event) => this.editStoreEntry(event, 'usUnit')}
					/>
				),
			},
			{
				header: 'Legacy',
				value: (
					<Toggle
						id="legacy"
						toggled={defaultValues.legacy}
						onClick={(event) => this.editStoreEntry(event, 'legacy')}
					/>
				),
			},
		];
	}

	editStoreEntry(e, name) {
		const value = e.target ? e.target.value : e;

		const payload = {
			[name]: value,
		};

		this.props.updateNutritionCategory(payload);
	}

	fetchData(state) {
		return fetchAllNutritionCategories(state);
	}

	render() {
		const legacyFilter = this.state.legacyFilterOn ? this.state.legacyFilter : '';
		return (
			<ReactDataWrapper
				accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.NUTRITION_CATEGORIES}
				className="-striped -highlight pos-ingredient-vp"
				columns={this.columns}
				totalEntries={this.state.totalEntries} // Display the total number of pages
				fetchData={this.fetchData} // Request new data when things change
				filterable
				defaultPageSize={10}
				reduxKey="pos/nutrition-category"
				manual // Forces table not to paginate or sort automatically, so we can handle it server-side
				editEntry={(e) => this.editEntry(e)}
				editableCells={this.getEditableCells()}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetState}
				actionsWidth={60}
				deleteEntry={this.deleteEntry}
				createEntry={this.addEntry}
				customAreaComponents={this.getLegacyFilterButton()}
				extraFilters={legacyFilter}
				subcomponent={(row) => (
					<SubTable>
						<NutritionCategoryTranslations id={row.original.id} />
					</SubTable>
				)}
			/>
		);
	}
}

NutritionCategories.propTypes = {
	defaultValues: PropTypes.object,

	updateNutritionCategory: PropTypes.func,
	setNutritionCategory: PropTypes.func,
	resetState: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setNutritionCategory,
			updateNutritionCategory,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultValues: store.productNutritionCategories.data.defaultNutritionCategories,
	};
};

export default connectWithStore(NutritionCategories, mapStateToProps, mapDispatchToProps);
