'use strict';

import {
	RESET_STATE,
	ADD_BATCH_LIST,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	UPDATE_BATCH_FORM,
} from './marketShiftRules.actions';

const defaultState = {
	data: {
		batchFormData: {},
		batchListData: [],
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case UPDATE_BATCH_FORM: {
		return {
			...state,
			data: {
				...state.data,
				batchFormData: {
					...state.data.batchFormData,
					...payload,
				},
			},
		};
	}

	case ADD_BATCH_LIST: {
		return {
			...state,
			data: {
				...state.data,
				batchListData: payload,
			},
		};
	}

	case EDIT_BATCH_LIST_ITEM: {
		return {
			...state,
			data: {
				...state.data,
				batchListData: state.data.batchListData.map((entry) => {
					if (entry.identifier === payload.identifier) return payload;
					return entry;
				}),
			},
		};
	}

	case REMOVE_BATCH_LIST_ITEM: {
		return {
			...state,
			data: {
				...state.data,
				batchListData: state.data.batchListData.filter(
					(entry) => entry.identifier !== payload
				),
			},
		};
	}

	case RESET_STATE: {
		return defaultState;
	}

	default:
		return state;
	}
}

export default reducer;
