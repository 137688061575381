export const SET_MANAGED_SHIFT_CLOCKIN =
	'SHIFT_CLOCKINS/SET_MANAGED_SHIFT_CLOCKIN';
export const UPDATE_MANAGED_SHIFT_CLOCKIN =
	'SHIFT_CLOCKINS/UPDATE_MANAGED_SHIFT_CLOCKIN';
export const RESET_MANAGED_SHIFT_CLOCKINS =
	'SHIFT_CLOCKINS/RESET_MANAGED_SHIFT_CLOCKINS';
export const SET_MODAL_TYPE = 'SHIFT_CLOCKINS/SET_MODAL_TYPE';
export const TOGGLE_MODAL = 'SHIFT_CLOCKINS/TOGGLE_MODAL';
export const SET_STATUS_FILTER = 'SHIFT_CLOCKINS/SET_STATUS_FILTER';
export const SET_ROW_INTERACTED = 'SHIFT_CLOCKINS/SET_ROW_INTERACTED';
export const REMOVE_ROW_INTERACTED = 'SHIFT_CLOCKINS/REMOVE_ROW_INTERACTED';
export const ADD_BREAK_MODAL_UPDATE_BREAK =
	'SHIFT_CLOCKINS/ADD_BREAK_MODAL_UPDATE_BREAK';
export const ADD_BREAK_MODAL_RESET_BREAK =
	'SHIFT_CLOCKINS/ADD_BREAK_MODAL_RESET_BREAK';

export function setManagedShiftClockin(payload) {
	return {
		type: SET_MANAGED_SHIFT_CLOCKIN,
		payload,
	};
}

export function updateManagedShiftClockin(payload) {
	return {
		type: UPDATE_MANAGED_SHIFT_CLOCKIN,
		payload,
	};
}

export function resetManagedShiftClockin() {
	return {
		type: RESET_MANAGED_SHIFT_CLOCKINS,
	};
}

export function setModalType(payload) {
	return {
		type: SET_MODAL_TYPE,
		payload,
	};
}

export function toggleModal() {
	return {
		type: TOGGLE_MODAL,
	};
}

export function setStatusFilter(payload) {
	return {
		type: SET_STATUS_FILTER,
		payload,
	};
}

export function setRowInteracted(payload) {
	return {
		type: SET_ROW_INTERACTED,
		payload,
	};
}

export function removeRowInteracted(payload) {
	return {
		type: REMOVE_ROW_INTERACTED,
		payload,
	};
}

export function addBreakModalUpdateBreak(payload) {
	return {
		type: ADD_BREAK_MODAL_UPDATE_BREAK,
		payload,
	};
}

export function addBreakModalResetBreak(payload) {
	return {
		type: ADD_BREAK_MODAL_RESET_BREAK,
		payload,
	};
}
