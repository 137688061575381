export const RESET_NUTRITION_VALUE =
	'SALES_CONFIGURATION_PRODUCT_PRODUCT_VARIANT_INGREDIENT_NUTRITION_VALUES/RESET_NUTRITION_VALUE';
export const SET_NUTRITION_VALUE =
	'SALES_CONFIGURATION_PRODUCT_PRODUCT_VARIANT_INGREDIENT_NUTRITION_VALUES/SET_NUTRITION_VALUE';
export const UPDATE_NUTRITION_VALUE =
	'SALES_CONFIGURATION_PRODUCT_PRODUCT_VARIANT_INGREDIENT_NUTRITION_VALUES/UPDATE_NUTRITION_VALUE';

/**
 * @param {object} payload
 */
export function setNutritionValue(payload) {
	return {
		type: SET_NUTRITION_VALUE,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function updateNutritionValue(payload) {
	return {
		type: UPDATE_NUTRITION_VALUE,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET_NUTRITION_VALUE,
	};
}
