import React from 'react';
import PropTypes from 'prop-types';

// redux
import { useDispatch, batch } from 'react-redux';
import {
	setManagedShiftClockin,
	setModalType,
	toggleModal,
} from './../../../../../store/shiftClockins.actions';

// components
import { Tooltip, Icon, Button } from 'dumb';

// misc
import moment from 'moment';
import constants from 'services/constants';

// utils
import phrases from './../../../shiftClockinsTable.phrases';
import enums from './../../../../../shiftClockins.enums';

// this component is used to change break dates when they are logged wrongly i.e. when break dates are after shift end dates
const BreakDateAdjustmentCell = ({ breakEntity, row, type }) => {
	const dispatch = useDispatch();

	const onEditClockinClick = () => {
		const payload = {
			row,
			type,
			break: breakEntity,
		};

		batch(() => {
			dispatch(setModalType(enums.MODAL_TYPES.ADJUST_BREAK_DATES));
			dispatch(toggleModal());
			dispatch(setManagedShiftClockin(payload));
		});
	};

	const shiftFinalTo = row?.final?.to;
	const breakClockedValue = breakEntity.clocked?.[type]; // type is either from or to

	const momentBreakClocked = moment.utc(
		breakClockedValue,
		constants.dateFormat
	);
	const momentShiftEnd = moment.utc(shiftFinalTo, constants.dateFormat);

	// if clocked date is after shift end date, show the edit button to correct the date back to shift date
	let flagBreak = false;
	if (momentBreakClocked.isAfter(momentShiftEnd, 'minute')) flagBreak = true;

	return (
		<div className="shift-clockins__table-wrapper__clockin-deviation-icon-wrapper">
			{flagBreak ? (
				<Tooltip
					text={phrases.BREAKS_NOT_ON_SAME_DAY}
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							className="shift-clockins__table-wrapper__clockin-breakEntity-icon-wrapper__button"
							size="micro"
							type="inverted"
							shadow
							onClick={onEditClockinClick}
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
						>
							<Icon name="edit" />
						</Button>
					)}
				/>
			) : (
				// dummy div with 20x20px dimensions used to help asssociate an adjustment button with each break row
				<div className="shift-clockins__table-wrapper__clockin-deviation-icon-wrapper--no-button" />
			)}
		</div>
	);
};

BreakDateAdjustmentCell.propTypes = {
	breakEntity: PropTypes.object,
	row: PropTypes.object,
	type: PropTypes.string,
};

export default BreakDateAdjustmentCell;
