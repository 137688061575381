import React from 'react';

// components
import { ModalRow } from 'dumb/modal';
import { Tip } from 'dumb/tips';

// phrases
import phrases from './../shiftClockinsModal.phrases';

const MarkManagedClockinNoShow = () => {
	return (
		<>
			<ModalRow>
				<Tip content={phrases.TOOLTIP_MARK_CLOCKIN_AS_NO_SHOW} />
			</ModalRow>
			<ModalRow>
				<p className="shift-clockins-modal__mark-as-no-show">
					{phrases.MARK_CLOCKIN_AS_NO_SHOW}
				</p>
			</ModalRow>
		</>
	);
};

export default MarkManagedClockinNoShow;
