/**
 * @function getFilters
 * @param {Object} tileLayoutGroups
 * @param {Object} products
 * @description return filter for
 * filter like -> (:tile_layout_group.id=='90';:product.id=IN=['1','3','28','29','30','31','32','33','34','35']),...
 */
export function getFilter(tileLayoutGroups, products) {
	//
	const productIds = products.reduce((acc, currentValue, index) => {
		const id = currentValue.value.id;

		acc = index === products.length - 1 ? `${acc}'${id}']` : `${acc}'${id}',`;
		return acc;
	}, '[');

	// get array of pos configuration version id's
	return tileLayoutGroups.reduce((acc, currentValue, index) => {
		acc =
			index === tileLayoutGroups.length - 1
				? `${acc}(:tile_layout_group.id=='${currentValue.value.id}';:product.id=IN=${productIds})`
				: `${acc}(:tile_layout_group.id=='${currentValue.value.id}';:product.id=IN=${productIds}),`;
		return acc;
	}, '');
}

/**
 * @function mapData
 * @description map products and remove duplicates in those groups
 */
export function mapData(list, duplicateProducts) {
	const diction = {};

	duplicateProducts.map(prod => {
		if (diction[prod.tile_layout_group.id]) {
			diction[prod.tile_layout_group.id] = [
				...diction[prod.tile_layout_group.id],
				prod.product.id
			];
		} else {
			diction[prod.tile_layout_group.id] = [prod.product.id];
		}
	});

	return list.map(entry => {
		const groupId = entry.tile_layout_group.id;

		if (diction[groupId]) {
			if (diction[groupId].includes(entry.product.id)) {
				const newEntry = {
					...entry,
					skip: true,
				};
				return newEntry;
			}
		}

		return entry;
	});
}
