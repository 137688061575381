import { store } from 'appState';
import * as types from './employmentDocuments.constants';
import { get } from 'api.vanilla.service';
const __endpoints = require('endpoints.service');

// --------------
// Local Actions
// --------------

const positionListFetched = (payload) => ({
	type: types.FETCH_POSITIONLIST,
	payload,
});

// ----------------
// UI Actions
// ----------------

const isLoadingPositions = (payload) => ({
	type: types.LOADING_POSITIONLIST,
	payload,
});
export const selectPosition = (payload) => ({
	type: types.SELECT_POSITIONLIST,
	payload,
});

export const resetState = () => ({
	type: types.RESET_STATE,
});

// --------------
// Server Actions
// --------------

export function fetchEmploymentPositions(personId, employmentId) {
	store.dispatch(isLoadingPositions(true));

	return _getEmploymentPositions(personId, employmentId)
		.then((payload) => {
			const latestValue = {};
			store.dispatch(positionListFetched(payload));

			if (!payload.length) return;

			// Find current job by comparing dates
			let prevDate = payload[0].period.from; // get first date for comparisant
			if (payload.length > 1) {
				payload.map((x) => {
					if (new Date(x.period.from) >= new Date(prevDate)) {
						prevDate = x.period.from;
						latestValue.value = x.id;
						latestValue.label = x.moneyball_sub_position.name;
					}
				});
			} else {
				latestValue.value = payload[0].id;
				latestValue.label = payload[0].moneyball_sub_position.name;
			}

			store.dispatch(selectPosition(latestValue));
		})
		.catch(() => {
			// @TODO: potential error hanlder
			store.dispatch(isLoadingPositions(false));
		})
		.then(() => store.dispatch(isLoadingPositions(false)));
}

function _getEmploymentPositions(personId, employmentId) {
	if (personId === undefined || employmentId === undefined) {
		return Promise.reject(new Error('No personId or employmentId'));
	}

	return get(
		__endpoints.collection.hr.persons.index.path + `/${personId}/employments/${employmentId}/employment_positions`
	).then((response) => {
		const positions = response.data;
		return positions;
	});
}
