export const UPDATE_POS_CONFIGURATION_ASSOCIATED_INGREDIENT =
	'POS_CONFIGURATION_ASSOCIATED_INGREDIENTS/UPDATE_POS_CONFIGURATION_ASSOCIATED_INGREDIENT';
export const RESET_POS_CONFIGURATION_ASSOCIATED_INGREDIENT =
	'POS_CONFIGURATION_ASSOCIATED_INGREDIENTS/RESET_POS_CONFIGURATION_ASSOCIATED_INGREDIENT';
export const SET_POS_CONFIGURATION_ASSOCIATED_INGREDIENT =
	'POS_CONFIGURATION_ASSOCIATED_INGREDIENTS/SET_POS_CONFIGURATION_ASSOCIATED_INGREDIENT';

// batch
export const UPDATE_BATCH_FORM =
	'POS_CONFIGURATION_ASSOCIATED_INGREDIENTS/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'POS_CONFIGURATION_ASSOCIATED_INGREDIENTS/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'POS_CONFIGURATION_ASSOCIATED_INGREDIENTS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'POS_CONFIGURATION_ASSOCIATED_INGREDIENTS/REMOVE_BATCH_LIST_ITEM';

export function updatePosConfigurationAssociatedIngredient(payload) {
	return {
		type: UPDATE_POS_CONFIGURATION_ASSOCIATED_INGREDIENT,
		payload,
	};
}

export function resetPosConfigurationAssociatedIngredient() {
	return {
		type: RESET_POS_CONFIGURATION_ASSOCIATED_INGREDIENT,
	};
}

export function setPosConfigurationAssociatedIngredient(payload) {
	return {
		type: SET_POS_CONFIGURATION_ASSOCIATED_INGREDIENT,
		payload,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}
