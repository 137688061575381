'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import { Input, InputCollectionSelect, Toggle } from 'dumb';

// utils/phrases
import _get from 'lodash/get';
import _has from 'lodash/has';
import phrases from './addonIngredient.phrases';

import {
	setAddonIngredients,
	updateAddonIngredients,
	resetAddonIngredients,
} from './store/addonIngredient.actions';

import {
	fetchAddonIngredients,
	editAddonIngredient,
	deleteAddonIngredient,
	addAddonIngredient,
	editBatchAddonIngredient,
} from './addonIngredient.service';

class AddonIngredient extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this._editStoreEntry = this._editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.fetchData = this.fetchData.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'ingname',
				accessor: (d) => _get(d, 'ingredient.name', 'n/a'),
				filterPath: ':ingredient.name',
			},
			{
				Header: 'Amount',
				id: 'ingamount',
				accessor: (d) => _get(d, 'ingredient_amount', 'n/a'),
				filterPath: ':ingredient_amount',
			},
			{
				Header: 'Deductable',
				id: 'deduct',
				accessor: (d) => _get(d, 'deductable', 'n/a').toString(),
				filterPath: ':deductable',
				filterable: false,
				Cell: (d) => {
					return (
						<Input
							id={`table-row-deductable-${d.original.id}`}
							type="checkbox"
							checked={_get(d, 'original.deductable', false)}
							disabled
						/>
					);
				},
			},
			{
				Header: 'Sort Order',
				id: 'sortorder',
				accessor: (d) => _get(d, 'sort_order', 'n/a'),
				filterPath: ':sort_order',
			},
			{
				Header: 'Minimum',
				id: 'min',
				accessor: (d) => _get(d, 'minimum', 'n/a'),
				filterPath: ':minimum',
			},
			{
				Header: 'Maximum',
				id: 'max',
				accessor: (d) => _get(d, 'maximum', 'n/a'),
				filterPath: ':maximum',
			},
			{
				Header: 'Available in app',
				id: 'availableInApp',
				filterPath: ':available_in_app',
				filterable: false,
				Cell: (d) => {
					return (
						<Input
							id={`table-row-available-in-app-${d.original.id}`}
							type="checkbox"
							checked={_get(d, 'original.available_in_app', false)}
							disabled
						/>
					);
				},
			},
			{
				Header: 'Available for delivery',
				id: 'availableForDelivery',
				filterPath: ':available_for_delivery',
				filterable: false,
				Cell: (d) => {
					return (
						<Input
							id={`table-row-available_for_delivery-${d.original.id}`}
							type="checkbox"
							checked={_get(d, 'original.available_for_delivery', false)}
							disabled
						/>
					);
				},
			},
		];
	}

	addEntry() {
		const { defaultProductAddonsIngredients, addon } = this.props;

		const payload = {
			addon: addon.id,
			ingredient: _get(
				defaultProductAddonsIngredients,
				'ingredient.value',
				null
			),
			minimum: defaultProductAddonsIngredients.minimum || 0,
			maximum: defaultProductAddonsIngredients.maximum || 1,
			sort_order: defaultProductAddonsIngredients.sortOrder,
			ingredient_amount: defaultProductAddonsIngredients.ingredientAmount || 1,
			deductable: !!defaultProductAddonsIngredients.deductable,
			available_in_app: !!defaultProductAddonsIngredients.availableInApp,
		};

		return addAddonIngredient(payload);
	}

	editEntry() {
		const { defaultProductAddonsIngredients } = this.props;

		const payload = {
			ingredient: defaultProductAddonsIngredients.ingredient.value,
			minimum: defaultProductAddonsIngredients.minimum,
			maximum: defaultProductAddonsIngredients.maximum,
			sort_order: defaultProductAddonsIngredients.sortOrder,
			ingredient_amount: defaultProductAddonsIngredients.ingredientAmount,
			deductable: defaultProductAddonsIngredients.deductable,
			available_in_app: defaultProductAddonsIngredients.availableInApp,
			available_for_delivery:
				defaultProductAddonsIngredients.availableForDelivery,
		};

		return editAddonIngredient(defaultProductAddonsIngredients.id, payload);
	}

	editMultiple(selectedRows) {
		const { defaultProductAddonsIngredients } = this.props;

		const list = selectedRows.map((row) => {
			return {
				id: row.id,
				...(defaultProductAddonsIngredients.ingredient && {
					ingredient: defaultProductAddonsIngredients.ingredient.value,
				}),
				...(defaultProductAddonsIngredients.minimum && {
					minimum: defaultProductAddonsIngredients.minimum,
				}),
				...(defaultProductAddonsIngredients.maximum && {
					maximum: defaultProductAddonsIngredients.maximum,
				}),
				...(defaultProductAddonsIngredients.sortOrder && {
					sort_order: defaultProductAddonsIngredients.sortOrder,
				}),
				...(defaultProductAddonsIngredients.ingredientAmount && {
					ingredient_amount: defaultProductAddonsIngredients.ingredientAmount,
				}),
				...(_has(defaultProductAddonsIngredients, 'deductable') && {
					deductable: defaultProductAddonsIngredients.deductable,
				}),
				...(_has(defaultProductAddonsIngredients, 'availableInApp') && {
					available_in_app: defaultProductAddonsIngredients.availableInApp,
				}),
				...(_has(defaultProductAddonsIngredients, 'availableForDelivery') && {
					available_for_delivery:
						defaultProductAddonsIngredients.availableForDelivery,
				}),
			};
		});

		const payload = {
			batch: list,
		};

		return editBatchAddonIngredient(payload);
	}

	deleteEntry(id) {
		return deleteAddonIngredient(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			ingredient: data.ingredient.name,
			minimum: data.minimum,
			maximum: data.maximum,
			deductable: data.deductable,
			sortOrder: data.sort_order,
			ingredientAmount: data.ingredient_amount,
			availableInApp: data.available_in_app,
			availableForDelivery: data.available_for_delivery,
			editMode: true,
		};

		this.props.setAddonIngredients(payload);
	}

	getEditableCells() {
		const { defaultProductAddonsIngredients } = this.props;

		return [
			{
				header: 'Ingredient Name',
				value: (
					<InputCollectionSelect
						id="ingredient"
						placeholder="select ingredient"
						value={defaultProductAddonsIngredients.ingredient}
						handleChange={(key, value) =>
							this._editStoreEntry('ingredient', value)
						}
						clearable={false}
						cache
						apiPath="/product/ingredients"
						params={{
							limit: 30,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Ingredient Amount',
				value: (
					<Input
						id="ingredient_amount"
						placeholder="amount"
						type="number"
						min="0"
						value={defaultProductAddonsIngredients.ingredientAmount || 1}
						onChange={(e) =>
							this._editStoreEntry('ingredientAmount', e.target.value)
						}
					/>
				),
			},
			{
				header: 'Deductable',
				value: (
					<Toggle
						id="deductable"
						toggled={defaultProductAddonsIngredients.deductable}
						onClick={(e) => this._editStoreEntry('deductable', e)}
					/>
				),
			},
			{
				header: 'Sort Order',
				value: (
					<Input
						id="sort_order"
						placeholder="sort order"
						type="number"
						min="0"
						value={defaultProductAddonsIngredients.sortOrder}
						onChange={(e) => this._editStoreEntry('sortOrder', e.target.value)}
					/>
				),
			},
			{
				header: 'Minimum',
				value: (
					<Input
						id="minimum"
						type="number"
						min="0"
						value={defaultProductAddonsIngredients.minimum || 0}
						onChange={(e) => this._editStoreEntry('minimum', e.target.value)}
					/>
				),
			},
			{
				header: 'Maximum',
				value: (
					<Input
						id="maximum"
						type="number"
						min="0"
						value={defaultProductAddonsIngredients.maximum || 1}
						onChange={(e) => this._editStoreEntry('maximum', e.target.value)}
					/>
				),
			},
			{
				header: 'Available in app',
				value: (
					<Toggle
						id="availableInApp"
						toggled={defaultProductAddonsIngredients.availableInApp}
						onClick={(e) => this._editStoreEntry('availableInApp', e)}
					/>
				),
			},
			{
				header: 'Available for delivery',
				value: (
					<Toggle
						id="availableForDelivery"
						toggled={defaultProductAddonsIngredients.availableForDelivery}
						onClick={(e) => this._editStoreEntry('availableForDelivery', e)}
					/>
				),
			},
		];
	}

	getEditableCellsEdit() {
		const { defaultProductAddonsIngredients } = this.props;

		return [
			...(defaultProductAddonsIngredients.editMode
				? [
						{
							header: 'Ingredient Name',
							value: <span>{defaultProductAddonsIngredients.ingredient}</span>,
						},
				  ]
				: []),
			{
				header: 'Ingredient Amount',
				value: (
					<Input
						id="ingredient_amount"
						placeholder="amount"
						type="number"
						min="1"
						value={defaultProductAddonsIngredients.ingredientAmount || 1}
						onChange={(e) =>
							this._editStoreEntry('ingredientAmount', e.target.value)
						}
					/>
				),
			},
			{
				header: 'Deductable',
				value: (
					<Toggle
						id="deductable"
						toggled={defaultProductAddonsIngredients.deductable}
						onClick={(e) => this._editStoreEntry('deductable', e)}
					/>
				),
			},
			{
				header: 'Sort Order',
				value: (
					<Input
						id="sort_order"
						placeholder="sort order"
						type="number"
						min="0"
						value={defaultProductAddonsIngredients.sortOrder}
						onChange={(e) => this._editStoreEntry('sortOrder', e.target.value)}
					/>
				),
			},
			{
				header: 'Minimum',
				value: (
					<Input
						id="minimum"
						type="number"
						min="1"
						value={defaultProductAddonsIngredients.minimum || 0}
						onChange={(e) => this._editStoreEntry('minimum', e.target.value)}
					/>
				),
			},
			{
				header: 'Maximum',
				value: (
					<Input
						id="maximum"
						type="number"
						min="1"
						value={defaultProductAddonsIngredients.maximum || 1}
						onChange={(e) => this._editStoreEntry('maximum', e.target.value)}
					/>
				),
			},
			{
				header: 'Available in app',
				value: (
					<Toggle
						id="availableInApp"
						toggled={defaultProductAddonsIngredients.availableInApp}
						onClick={(e) => this._editStoreEntry('availableInApp', e)}
					/>
				),
			},
			{
				header: 'Available for delivery',
				value: (
					<Toggle
						id="availableForDelivery"
						toggled={defaultProductAddonsIngredients.availableForDelivery}
						onClick={(e) => this._editStoreEntry('availableForDelivery', e)}
					/>
				),
			},
		];
	}

	_editStoreEntry(name, value) {
		this.props.updateAddonIngredients({ [name]: value });
	}

	fetchData(state) {
		const { addon } = this.props;
		return fetchAddonIngredients(addon.id, state);
	}

	render() {
		return (
			<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.TABLE_TITLE}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={25}
				reduxKey={`/product/addon_ingredients/${this.props.addon.id}`}
				manual
				editEntry={(e) => this.editEntry(e)}
				editableCells={this.getEditableCells()}
				editableCellsEdit={this.getEditableCellsEdit()}
				editMultiple={this.editMultiple}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetAddonIngredients}
				deleteEntry={this.deleteEntry}
				createEntry={this.addEntry}
			/>
		);
	}
}

AddonIngredient.propTypes = {
	addon: PropTypes.object,
	defaultProductAddonsIngredients: PropTypes.object,
	updateAddonIngredients: PropTypes.func,
	setAddonIngredients: PropTypes.func,
	resetAddonIngredients: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setAddonIngredients,
			updateAddonIngredients,
			resetAddonIngredients,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultProductAddonsIngredients:
			store.productAddonIngredients.data.defaultProductAddonsIngredients,
	};
};

export default connectWithStore(
	AddonIngredient,
	mapStateToProps,
	mapDispatchToProps
);
