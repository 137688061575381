'use strict';

import prop from 'dot-prop-immutable';

import {
	ADD_FILE_TYPE,
	ADD_FILE_TYPE_TEMPLATE,
	RESET_FILE_TYPES,
	SET_FILE_COUNT
} from './fileUpload.actions';

const defaultState = {
	fileTypeTemplates: {},
	fileTypes: {},
	fileCounts: {}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
		case ADD_FILE_TYPE_TEMPLATE:
			return prop.set(state, `fileTypeTemplates.${payload.fileUploadName}.${payload.fileTypeTemplate.getName()}`, payload.fileTypeTemplate);
		case ADD_FILE_TYPE:
			return prop.set(state, `fileTypes.${payload.fileUploadName}.${payload.fileTypeIdentifier}`, payload.fileType);
		case RESET_FILE_TYPES:
			return prop.set(state, `fileTypes.${payload.fileUploadName}`, {});
		case SET_FILE_COUNT:
			return prop.set(state, `fileCounts.${payload.fileUploadName}`, payload.fileCount);
		default:
			return state;
	}
}

export default reducer;