import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

// lodash
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';

import DateRange from './subcomponents/dateRange.component';
import Select from './subcomponents/select.component';
import SingleDate from './subcomponents/singleDate.component';
import Number from './subcomponents/number.component';

class HotdamnbarComponents extends PureComponent {
	getComponents() {
		const { activeComponent, hotbar, hotbarState, methods } = this.props;

		return Object.keys(hotbar.getComponents()).map((componentIdentifier) => {
			let componentJsx;

			// Shorthand component
			const component = hotbar.getComponents()[componentIdentifier];

			// Get state of component from hotbarState
			const componentState = _get(
				hotbarState,
				`results[${component.title}]`,
				null
			);

			// Set shared properties for all components
			const componentProperties = {
				component,
				componentState,
				phrases: hotbar.getPhrases(),
				handleChange: methods.handleChange,
				handleFocusChange: methods.handleComponentFocusChange,
				toggleStateLoading: methods.toggleStateLoading,
			};

			// Get component jsx based on component type
			switch (component.type) {
				case 'dateRange':
					componentJsx = (
						<DateRange withPortal={false} {...componentProperties} />
					);
					break;
				case 'select':
					componentJsx = <Select {...componentProperties} />;
					break;
				case 'singleDate':
					componentJsx = <SingleDate {...componentProperties} />;
					break;
				case 'number':
					componentJsx = <Number {...componentProperties} />;
					break;
			}

			// Get styles based on component type
			const styles = cx('hotdamnbar__component', {
				'hotdamnbar__component--active': activeComponent
					? component.title === activeComponent.title
					: false,
				'hotdamnbar__date-range': component.type === 'dateRange',
				hotdamnbar__select: component.type === 'select',
				'hotdamnbar__single-date': component.type === 'singleDate',
				hotdamnbar__number: component.type === 'number',
				hotdamnbar__separator: component.type === 'separator',
			});

			// Return component in wrapping div
			return (
				<div
					key={component.title}
					className={styles}
					data-label={`${component.title} ${component.required ? '*' : ''}`}
					onFocus={methods.handleComponentFocus.bind(this, component)}
					onBlur={methods.handleBlur}>
					{componentJsx}
				</div>
			);
		});
	}

	childWrapper(child) {
		return (
			<div className="hotdamnbar__component hotdamnbar__select">{child}</div>
		);
	}

	getChildren() {
		const { children } = this.props;

		if (!children) return;

		if (_isArray(children))
			return children.map((child, index) => {
				if (!child) return null;

				const component = this.childWrapper(child);

				return <component.type {...component.props} key={index} />;
			});

		return this.childWrapper(children);
	}

	render() {
		return (
			<div className="hotdamnbar__components">
				{this.getComponents()}
				{this.getChildren()}
			</div>
		);
	}
}

HotdamnbarComponents.propTypes = {
	activeComponent: PropTypes.object,
	hotbar: PropTypes.object.isRequired,
	hotbarState: PropTypes.object,
	methods: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

export default HotdamnbarComponents;
