import React, { useState } from 'react';
import PropTypes from 'prop-types';

// components
import { Tooltip, Button, Icon, Text, ButtonLoader } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';
import { Tip } from 'dumb/tips';
import ShiftTransferStatus from './../../../shiftTransferStatus';

// phrases
import phrases from './../../../../shiftTransferApproval.phrases';
import { ShiftTransfer as enums } from 'services/enums';

// utils
import {
	formatShiftTime,
	getShiftDate,
	getShiftDay,
} from './../../../../utils';

const ShiftTransferJuicerApprovalList = ({
	swapTransfers,
	handleUpdateShiftTransferApplication,
}) => {
	const getShiftCell = (entry) => {
		const shiftDate = getShiftDate(entry.shift?.planned_period?.from);
		const timeFrom = formatShiftTime(entry.shift?.planned_period?.from);
		const timeTo = formatShiftTime(entry.shift?.planned_period?.to);
		const shiftDay = getShiftDay(entry.shift?.planned_period?.from);

		const concatenatedText = `${shiftDate} ${timeFrom} - ${timeTo} (${shiftDay})`;

		return (
			<div className="shift-transfer-approval-modal__table-wrapper__row-cell">
				<Text bold size="small" noWrap>
					{concatenatedText}
				</Text>
			</div>
		);
	};

	const handleShiftApplication = ({ shiftTransfer, operation }) => {
		const payload = { shiftTransfer, operation };

		// if declining, set loaders
		if (operation === enums.OPERATIONS.DECLINE) {
			setLoading({ id: shiftTransfer.id });

			handleUpdateShiftTransferApplication(payload).finally(() =>
				setLoading(initialLoadingState)
			);
		}
		// if not declining we will open a policy modal and therefor handleUpdateShiftTransferApplication doesn't return a promise
		else handleUpdateShiftTransferApplication(payload);
	};

	const initialLoadingState = { id: null };
	const [loading, setLoading] = useState(initialLoadingState);

	return (
		<>
			<div className="shift-transfer-approval-modal__tip-wrapper">
				<Tip content={phrases.MODAL_OWN_TRANSFERS_TOOLTIP} />
			</div>
			<div className="shift-transfer-approval-modal__table-wrapper">
				<TableList>
					<thead>
						<tr>
							<th>{phrases.TABLE_LIST_SHIFT_OWNER}</th>
							<th>{phrases.TABLE_LIST_SHIFT}</th>
							<th>{phrases.TABLE_LIST_BAR}</th>
							<th>{phrases.TABLE_LIST_STATE}</th>
						</tr>
					</thead>
					<tbody>
						{swapTransfers.map((entry) => {
							// api bug when we decline a swap the state stays Applied, needs to be declined
							const transferAccepted =
								entry.applications?.[0]?.state ===
								enums.APPLICATIONS.AWAITING_MANAGER_APPROVAL;
							const transferDeclined =
								entry.applications?.[0]?.state === enums.APPLICATIONS.DECLINED;

							return (
								<TableListRow key={entry.id}>
									<TableListCell>
										<div className="shift-transfer-approval-modal__table-wrapper__row-cell">
											<Text
												bold
												size="tiny"
												className="shift-transfer-approval-modal__table-wrapper__row-cell__juicer-number">
												{entry.donor?.person?.id ?? ''}
											</Text>
											<Text
												bold
												size="small"
												noWrap
												className="shift-transfer-approval-modal__table-wrapper__row-cell--left-margin">
												{entry.donor?.person?.full_name ?? ''}
											</Text>
										</div>
									</TableListCell>

									<TableListCell>{getShiftCell(entry)}</TableListCell>

									<TableListCell>
										<Text size="small" bold noWrap>
											{entry.shift?.workplace?.name}
										</Text>
									</TableListCell>

									<TableListCell>
										<div className="shift-transfer-approval-modal__table-wrapper__row-cell shift-transfer-approval-modal__table-wrapper__row-cell--align-end">
											{transferAccepted || transferDeclined ? (
												<ShiftTransferStatus
													state={entry.applications?.[0]?.state ?? ''}
													ownTransfer
												/>
											) : (
												<>
													<Tooltip
														text={phrases.ACCEPT}
														zIndex={600}
														renderData={(ref, onMouseEnter, onMouseLeave) => (
															<Button
																shadow
																type="success"
																size="small"
																onClick={() =>
																	handleShiftApplication({
																		shiftTransfer: entry,
																		operation: enums.OPERATIONS.ACCEPT,
																	})
																}
																disabled={loading.id === entry.id}
																refProp={ref}
																onMouseEnter={onMouseEnter}
																onMouseLeave={onMouseLeave}>
																<Icon name="thumb_up" />
															</Button>
														)}
													/>
													<div className="shift-transfer-approval-modal__table-wrapper__row-cell--left-margin">
														<Tooltip
															text={phrases.DECLINE}
															zIndex={600}
															renderData={(ref, onMouseEnter, onMouseLeave) => (
																<Button
																	shadow
																	size="small"
																	type="negative"
																	onClick={() =>
																		handleShiftApplication({
																			shiftTransfer: entry,
																			operation: enums.OPERATIONS.DECLINE,
																		})
																	}
																	refProp={ref}
																	onMouseEnter={onMouseEnter}
																	onMouseLeave={onMouseLeave}>
																	{loading.id === entry.id ? (
																		<ButtonLoader loading />
																	) : (
																		<Icon name="thumb_down" />
																	)}
																</Button>
															)}
														/>
													</div>
												</>
											)}
										</div>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
			</div>
		</>
	);
};

ShiftTransferJuicerApprovalList.propTypes = {
	swapTransfers: PropTypes.array,
	handleUpdateShiftTransferApplication: PropTypes.func,
};

export default ShiftTransferJuicerApprovalList;
