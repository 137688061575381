import React, { Component } from 'react';

// Components
import Ingredients from './components/ingredients/ingredients.component';
import IngredientPricingGroups from './components/ingredientPricingGroups/ingredientPricingGroups.component';
import IngredientsCategories from './components/ingredientCategories/ingredientCategories.component';
import IngredientVariations from './components/ingredientVariations/ingredientVariations.component';
import IngredientConfigurations from './components/ingredientConfigurations/ingredientConfigurations.component';
import ProductVariantIngredientAlternatives from './components/productVariantIngredientAlternatives/productVariantIngredientAlternatives.container';

class IngredientsContainer extends Component {
	render() {
		return (
			<>
				<IngredientsCategories />
				<Ingredients />
				<ProductVariantIngredientAlternatives />
				<IngredientVariations />
				<IngredientConfigurations />
				<IngredientPricingGroups />
			</>
		);
	}
}

IngredientsContainer.propTypes = {};

export default IngredientsContainer;
