export const SET_EDITED_VALUE = 'TRAININGS/SET_EDITED_VALUE';
export const SET_EDITABLE_VALUES = 'TRAININGS/SET_EDIT_VALUES';
export const RESET_STATE = 'TRAININGS/RESET_STATE';

export const editTrainingData = (payload) => {
	return { type: SET_EDITED_VALUE, payload };
};

export const setEditableTrainingData = (payload) => {
	return { type: SET_EDITABLE_VALUES, payload };
};

export const resetState = () => {
	return { type: RESET_STATE };
};
