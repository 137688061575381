'use strict';

import { ReportFactory as R } from 'report/factory';
import {
	ReportEndpointDataProvider,
	ReportDownloadCsvDataProvider,
} from 'report/dataProviders';
import { getParameters } from './../../auditReports.methods';

import endpoints from 'endpoints.service';
import moment from 'moment';

import {
	AuditSalaryPayrollReportTable,
	AuditSalaryPayrollReportWorkplaceTable,
} from './components';

/// ////////////////////////
// Audit Salary Payrolls //
/// ////////////////////////

// Data provider
const AuditSalaryPayrollsDataProvider = new ReportEndpointDataProvider(
	'/reporting/audit_salary_payroll_reports'
);

const AuditSalaryPayrollsDownloadCsvDataProvider =
	new ReportDownloadCsvDataProvider(
		'CSV',
		endpoints.collection.reporting.audit_salary_payroll_reports,
		'audit_salary_payroll_reports'
	);

// Report
const AuditSalaryPayrollsReport = R.report(
	'auditSalaryPayrollsReport',
	AuditSalaryPayrollsDataProvider
);

// Report metadata
const m = R.metadata();
AuditSalaryPayrollsReport.setMetadata(
	m.metadata(
		m.title('Salary payrolls audit'),
		m.subtitle(
			(d) =>
				`${moment(R.data(d, 'period.from')).format('DD-MM-YYYY')} - ${moment(
					R.data(d, 'period.to')
				).format('DD-MM-YYYY')}`
		),
		m.printTitle((d) => R.data(d, 'name'))
	)
);

AuditSalaryPayrollsReport.setParameterMethod(getParameters);
AuditSalaryPayrollsReport.addCsvDataProvider(
	AuditSalaryPayrollsDownloadCsvDataProvider
);

// Components
AuditSalaryPayrollsReport.setComponents({
	AuditSalaryPayrollReportTable,
	AuditSalaryPayrollReportWorkplaceTable,
});

export default AuditSalaryPayrollsReport;
