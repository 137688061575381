'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputPhone, Button, Loader } from 'dumb';
import { isMobile } from 'detectMobile.vanilla';
import phrases from './phrases';

import './inputPhoneWrapper.css';

export default class InputPhoneWrapper extends Component {
	constructor(props) {
		super(props);

		this.state = {
			editedPhoneNumber:
				this.props.phoneNumber === null ? undefined : this.props.phoneNumber,
			editable: false,
			loading: false,
		};

		this.onBlur = this.onBlur.bind(this);
		this.onSelect = this.onSelect.bind(this);
		this.onChange = this.onChange.bind(this);
		this.submitValue = this.submitValue.bind(this);
		this._undoEditing = this._undoEditing.bind(this);
		this.submitEditing = this.submitEditing.bind(this);
	}

	/**
	 * @function onBlur
	 * @description passed down to phone input field to handle blur events
	 */
	onBlur(key) {
		const { toggleInputs } = this.props;

		// if phone number changed, submit it
		if (key !== 27 && this.state.editedPhoneNumber !== this.props.phoneNumber)
			this.submitValue(this.state.editedPhoneNumber);
		else {
			this.setState(() => ({
				editedPhoneNumber: this.props.phoneNumber,
			}));
		}

		toggleInputs({ disabled: true });
	}

	/**
	 * @function onSelect
	 * @description passed down to phone input field to handle select events
	 */
	onSelect() {
		const { toggleInputs } = this.props;
		const { editable } = this.state;

		if (!editable && isMobile()) return;

		toggleInputs({ disabled: false });
	}

	onChange(phoneNumber, submit = false) {
		if (phoneNumber === undefined) return;

		this.setState(() => ({
			editedPhoneNumber: phoneNumber,
		}));

		if (submit) this.submitValue(phoneNumber);
	}

	submitValue(phoneNumber = null) {
		const { onChange, toggleInputs } = this.props;
		const { editedPhoneNumber } = this.state;

		this.setState(() => ({
			loading: true,
		}));

		onChange(phoneNumber || editedPhoneNumber)
			.then(() => {
				// stop the loader
				this.setState(() => ({
					loading: false,
				}));

				// editable to false
				if (isMobile()) this._toggleInputField();

				// blut input fields
				toggleInputs({ disabled: true });
			})
			.catch(() => {
				// loader as well
				this.setState(() => ({
					loading: false,
				}));
			});
	}

	_undoEditing() {
		const { phoneNumber } = this.props;

		if (isMobile()) return;

		// set the value back to initial one
		this.setState(() => ({
			editedPhoneNumber: phoneNumber,
		}));
		this.onBlur();
	}

	submitEditing() {
		// submit the value
		this.submitValue();
	}

	_toggleInputField() {
		this.setState((prevState) => ({
			editable: !prevState.editable,
		}));
	}

	render() {
		const { editedPhoneNumber, toggleInputs, editable, loading } = this.state;
		const { readOnly, action } = this.props;

		return (
			<div className="j-card__phone-wrapper">
				<div className="j-card__phone-wrapper__content">
					<InputPhone
						id="phone"
						placeholder="Enter phone number"
						onBlur={this.onBlur}
						onClick={this.onSelect}
						toggleInputs={toggleInputs}
						readOnly={readOnly}
						onChange={this.onChange}
						phoneNumber={editedPhoneNumber}
						isMobile={isMobile()}
						submitValue={this.submitEditing}
						enableKeyPress
						inJuicerDetails
					/>
					{loading && <Loader size="small" loading />}
					{action && isMobile() && (
						<div className="j-input-smart__action">
							<Button
								// disabled={loading || disabled}
								type="inverted"
								size="tiny"
								onClick={
									editable
										? () => this.submitEditing()
										: () => this._toggleInputField()
								}
								state={editable ? 'success' : 'secondary'}>
								{editable ? (
									<span className="icon icon--done" />
								) : (
									<span className="icon icon--edit" />
								)}
							</Button>
						</div>
					)}
				</div>
				<label>{phrases.PHONE_NUMBER_LABEL}</label>
			</div>
		);
	}
}

InputPhoneWrapper.defaultProps = {
	action: true,
};

InputPhoneWrapper.propTypes = {
	toggleInputs: PropTypes.func,
	onChange: PropTypes.func,
	phoneNumber: PropTypes.string,
	readOnly: PropTypes.bool,
	action: PropTypes.bool,
};
