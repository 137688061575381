import React, { Component } from 'react';
import PropTypes from 'prop-types';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import {
	setWorkplacePosConfiguration,
	updateWorkplacePosConfiguration,
} from './store/workplacePosConfigurationRelationWrapper.actions';

import { addWorkplaceToPosConfigurationRelation } from './workplacePosConfigurationRelationWrapper.service';

// component
import WorkplacePosConfigurationRelation from 'structure/salesConfiguration/components/posWorkplaceRelation/components/workplacePosConfiguration/workplacePosConfiguration.component';
import { InputCollectionSelect, SingleDatePickerInput } from 'dumb';

// lodash
import _get from 'lodash/get';

// constants
import constants from 'services/constants';

// styles
import './workplacePosConfigurationRelationWrapper.css';

class WorkplacePosConfigurationRelationWrapper extends Component {
	constructor(props) {
		super(props);

		this.addEntry = this.addEntry.bind(this);
		this.getEditableCells = this.getEditableCells.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
	}

	addEntry() {
		const { workplacePosConfiguration, workplaceId } = this.props;

		const payload = {
			pos_configuration: _get(
				workplacePosConfiguration,
				'posConfiguration.value',
				null
			),
			workplace: workplaceId,
			product_recommendations: _get(
				workplacePosConfiguration,
				'productRecommendation.value',
				null
			),
			pos_tile_layout: _get(
				workplacePosConfiguration,
				'posTileLayout.value',
				null
			),
			app_tile_layout: _get(
				workplacePosConfiguration,
				'appTileLayout.value',
				null
			),
			ingredient_configuration: _get(
				workplacePosConfiguration,
				'ingredientConfiguration.value',
				null
			),
			active: {
				from: moment
					.utc(workplacePosConfiguration.activeFrom)
					.format(constants.shortDate),
				to: moment
					.utc(workplacePosConfiguration.activeTo || '9999-12-31')
					.format(constants.shortDate),
			},
		};

		return addWorkplaceToPosConfigurationRelation(payload);
	}

	getEditableCells() {
		const { workplacePosConfiguration } = this.props;

		return [
			{
				header: 'POS Configuration',
				value: (
					<InputCollectionSelect
						id="pos_configuration"
						placeholder="Select pos configuration..."
						value={workplacePosConfiguration.posConfiguration}
						handleChange={(key, value) =>
							this.editStoreEntry(value, 'posConfiguration')
						}
						cache
						apiPath="/pos/pos_configurations"
						params={{
							limit: 50,
							filter: ':legacy==false',
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Product recommendation',
				value: (
					<InputCollectionSelect
						id="productRecommendation"
						value={workplacePosConfiguration.productRecommendation}
						handleChange={(key, value) =>
							this.editStoreEntry(value, 'productRecommendation')
						}
						clearable={false}
						cache
						placeholder="Select product recommendation"
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						apiPath="/pos/product_recommendations"
						params={{
							limit: 30,
						}}
					/>
				),
			},
			{
				header: 'POS tile layout',
				value: (
					<InputCollectionSelect
						id="posTileLayout"
						placeholder="Select Pos tile layout..."
						value={workplacePosConfiguration.posTileLayout}
						handleChange={(key, event) =>
							this.editStoreEntry(event, 'posTileLayout')
						}
						clearable={false}
						cache
						optionFormat={(entry) => ({
							value: entry.id,
							label: `${entry.name} - ${entry.description}`,
						})}
						apiPath="/pos/tile_layouts"
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						params={{
							limit: 30,
							filter: `:type=='POS'`,
						}}
					/>
				),
			},
			{
				header: 'App tile layout',
				value: (
					<InputCollectionSelect
						id="appTileLayout"
						placeholder="Select App tile layout..."
						value={workplacePosConfiguration.appTileLayout}
						handleChange={(key, event) =>
							this.editStoreEntry(event, 'appTileLayout')
						}
						clearable={false}
						cache
						optionFormat={(entry) => ({
							value: entry.id,
							label: `${entry.name} - ${entry.description}`,
						})}
						apiPath="/pos/tile_layouts"
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						params={{
							limit: 30,
							filter: `:type=='App'`,
						}}
					/>
				),
			},
			{
				header: 'Ingredient configuration',
				value: (
					<InputCollectionSelect
						id="ingredientConfiguration"
						placeholder="Select ingredient configuration..."
						value={workplacePosConfiguration.ingredientConfiguration}
						handleChange={(key, event) =>
							this.editStoreEntry(event, 'ingredientConfiguration')
						}
						clearable={false}
						cache
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						apiPath="/pos/ingredient_configurations"
						params={{
							limit: 30,
						}}
					/>
				),
			},
			{
				header: 'Active from',
				value: (
					<SingleDatePickerInput
						id="activeFromDatePicker"
						onChange={(event) => this.editStoreEntry(event, 'activeFrom')}
						selectedDate={moment
							.utc(workplacePosConfiguration.activeFrom)
							.format(constants.shortDate)}
						noClockButton
					/>
				),
			},
			{
				header: 'Active to',
				value: (
					<SingleDatePickerInput
						id="activeToDatePicker"
						onChange={(event) => this.editStoreEntry(event, 'activeTo')}
						selectedDate={moment
							.utc(workplacePosConfiguration.activeTo || '9999-12-31')
							.format(constants.shortDate)}
						noClockButton
					/>
				),
			},
		];
	}

	editStoreEntry(e, name) {
		const payload = {
			[name]: e,
		};

		this.props.updateWorkplacePosConfiguration(payload);
	}

	render() {
		const { workplaceId } = this.props;

		return (
			<div className="workplace-pos-configuration-relation-wrapper">
				<WorkplacePosConfigurationRelation
					workplaceId={workplaceId}
					defaultPageSize={10}
					editableCells={this.getEditableCells()}
					addEntry={this.addEntry}
				/>
			</div>
		);
	}
}

WorkplacePosConfigurationRelationWrapper.propTypes = {
	workplaceId: PropTypes.number,
	updateWorkplacePosConfiguration: PropTypes.func,
	workplacePosConfiguration: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setWorkplacePosConfiguration,
			updateWorkplacePosConfiguration,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		workplacePosConfiguration:
			store.workplacePosConfigurationRelationWrapper.data
				.workplacePosConfiguration,
	};
};

export default connectWithStore(
	WorkplacePosConfigurationRelationWrapper,
	mapStateToProps,
	mapDispatchToProps
);
