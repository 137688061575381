'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';

// components
import { Button, Input, Icon, Tabs } from 'dumb';

import IngredientVariationPricingModalBatch from './components/modalBatch/ingredientVariationPricingModalBatch';
import IngredientVariantOptions from './components/ingredientVariantOptions/ingredientVariantOptions.component';
import IngredientAssociationWithProducts from './components/ingredientAssociationWithProducts/ingredientAssociationWithProducts.component';
import SubTable from 'reactDataWrapper/components/subTable';

// constants/ phrases
import phrases from './posConfigurationIngredientPricingVariation.phrases';

import _get from 'lodash/get';

import {
	resetState,
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	setIngredientPricingVariants,
	updateIngredientPricingVariants,
} from './store/posConfigurationIngredientPricingVariation.actions';

import {
	fetchPosConfigurationIngredientVariationPricings,
	editPosConfigurationIngredientVariations,
	deletePosConfigurationIngredientVariations,
	addPosConfigurationIngredientVariations,
} from './posConfigurationIngredientPricingVariation.service';

class PosConfigurationIngredientPricingVariation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);

		this.columns = [
			{
				Header: 'POS Configuration Version',
				id: 'posConfigurationVersion',
				accessor: (d) => _get(d, 'pos_configuration_version.name', null),
				filterPath: ':pos_configuration_version.name',
			},
			{
				Header: 'Ingredient Variant',
				id: 'ingredientVariant',
				accessor: (d) => _get(d, 'name', null),
				filterPath: ':name',
			},
			{
				Header: 'Sort order',
				id: 'sort_order',
				accessor: (d) => _get(d, 'sort_order', null),
				filterPath: ':sort_order',
			},
		];
	}

	deleteEntry(id) {
		return deletePosConfigurationIngredientVariations(id);
	}

	editEntry() {
		const { ingredientPricingVariation } = this.props;

		const payload = {
			id: ingredientPricingVariation.id,
			name: ingredientPricingVariation.ingredientVariant,
			sort_order: ingredientPricingVariation.sortOrder,
		};

		return editPosConfigurationIngredientVariations(payload);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			posConfigurationVersion: _get(
				data,
				'pos_configuration_version.name',
				null
			),
			ingredientVariant: data.name,
			sortOrder: data.sort_order,
		};

		this.props.setIngredientPricingVariants(payload);
	}

	getEditableCellsEdit() {
		const { ingredientPricingVariation } = this.props;

		return [
			{
				header: 'Pos configuration version',
				value: (
					<span>{ingredientPricingVariation.posConfigurationVersion}</span>
				),
			},
			{
				header: 'Ingredient variant',
				value: (
					<Input
						id="ingredientVariant"
						placeholder="Enter ingredient variant"
						value={ingredientPricingVariation.ingredientVariant}
						onChange={(e) =>
							this.editStoreEntry('ingredientVariant', e.target.value)
						}
					/>
				),
			},
			{
				header: 'Sort order',
				value: (
					<Input
						id="sortOrder"
						type="number"
						placeholder="1, 2, 3..."
						onChange={(e) => this.editStoreEntry('sortOrder', e.target.value)}
						value={ingredientPricingVariation.sortOrder}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const payload = {
			[name]: e,
		};

		this.props.updateIngredientPricingVariants(payload);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	render() {
		const {
			updateBatchForm,
			batchFormData,
			listPosConfigurationVersions,
			addBatchList,
			batchList,
			editBatchListItem,
			removeBatchListItem,
			resetState,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					accessAreasAllowedToEdit={['Sales Configuration']}
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={fetchPosConfigurationIngredientVariationPricings}
					filterable
					defaultPageSize={50}
					reduxKey="/pos/pos_configuration_ingredient_variations"
					manual
					editEntry={() => this.editEntry()}
					editableCellsEdit={this.getEditableCellsEdit()}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={resetState}
					actionsWidth={90}
					editMultiple={this.editMultiplePrices}
					deleteEntry={this.deleteEntry}
					deleteConfirm
					actionRender={
						<Button
							type="inverted"
							label="Add"
							shadow
							onClick={() => this.toggleModal()}
						>
							<Icon name="add" />
						</Button>
					}
					subcomponent={(row) => {
						const tabContent = [
							{
								name: 'Variant options',
								component: (
									<div className="pos-ingredient-vp__subcomponent__inner">
										<IngredientVariantOptions
											ingredientVariant={row.original}
											key={row.original.id}
										/>
									</div>
								),
							},
							{
								name: 'Associated products',
								component: (
									<div className="pos-ingredient-vp__subcomponent__inner">
										<IngredientAssociationWithProducts
											ingredientVariant={row.original}
											key={row.original.id}
										/>
									</div>
								),
							},
						];
						return (
							<SubTable>
								<Tabs tabContent={tabContent} />
							</SubTable>
						);
					}}
				/>

				<IngredientVariationPricingModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchFormData={batchFormData}
					updateBatchForm={updateBatchForm}
					listPosConfigurationVersions={listPosConfigurationVersions}
					addBatchList={addBatchList}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addPosConfigurationIngredientVariations={
						addPosConfigurationIngredientVariations
					}
				/>
			</>
		);
	}
}

PosConfigurationIngredientPricingVariation.propTypes = {
	ingredientPricingVariation: PropTypes.object,
	listPosConfigurationVersions: PropTypes.object,
	setIngredientPricingVariants: PropTypes.func,
	resetState: PropTypes.func,
	updateBatchForm: PropTypes.func,
	addBatchList: PropTypes.func,
	batchFormData: PropTypes.object,
	batchList: PropTypes.object,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	updateIngredientPricingVariants: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			resetState,
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			setIngredientPricingVariants,
			updateIngredientPricingVariants,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		batchFormData:
			store.posConfigurationIngredientPricingVariation.data.batchFormData,
		batchList: store.posConfigurationIngredientPricingVariation.data.batchList,
		listPosConfigurationVersions:
			store.listData[
				'ingredient-variation-pricing/pos/pos_configuration_versions'
			],
		ingredientPricingVariation:
			store.posConfigurationIngredientPricingVariation.data
				.ingredientPricingVariation,
	};
};

export default connectWithStore(
	PosConfigurationIngredientPricingVariation,
	mapStateToProps,
	mapDispatchToProps
);
