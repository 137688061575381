import { get, post, remove } from 'api.vanilla.service';
import { set as setFeedback } from 'feedback.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import moment from 'moment';

import _get from 'lodash/get';

const __endpoints = require('endpoints.service');

/**
 * @function fetchTrainings
 * @description Fetch all Training
 * @param {Object} Object with limit, offset, filter and sort
 * @param {String} initialFilter
 * @returns Promise
 */
export function fetchTrainings(
	{ limit, offset, filter, sort },
	initialFilter,
	initialSort
) {
	// Set endpoint based on half_hourly or hourly selection in hotbar
	const contactsEndpoint =
		__endpoints.collection.training.module_executions.index.path;

	// SORTING FIQL
	// Filtering will use AND( ; ) filter since we want to chain the filters
	// Sorting will use the OR( , ) filter since we want to apply only one sorting paramemter at the time

	// apply filters
	if (filter) filter = `${initialFilter};${filter}`;
	else filter = initialFilter;

	// apply sorting
	if (sort) sort = `${initialSort},${sort}`;
	else sort = initialSort;

	// Set params and filter
	const params = {
		limit,
		sort,
		offset,
		filter,
	};

	return get(contactsEndpoint, params);
}

/**
 * @function deleteTraining
 * @description Delete Training
 * @param {Object} Object with id
 * @returns Promise
 */
export function deleteTraining({ id }) {
	const trainingsEndpoint =
		__endpoints.collection.training.module_executions.delete.path + id;

	return remove(trainingsEndpoint);
}

/**
 * @function postPatchTraining
 * @description adds or edits a training/exam
 * @param {Object} id of a training/exam to edit
 * @param {Object} payload
 * @returns Promise
 */
export function postPatchTraining(id, payload) {
	const endpoint = id
		? `/training/module_executions/${id}`
		: '/training/module_executions';

	return post(endpoint, payload)
		.then((res) => res)
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
		});
}

/**
 * @function getPeriodEdit
 * @param {Object} defaultValues
 * @param {Object} editedValues
 * @description gets old values from the store - default values
 * @description gets new values from the store - edited values
 * @description returns a set of new/old date/time values
 * @description in total there's 16 different combinations of from date/time and to date/time since
 * @description they can all be either new or old without any limitations
 */
export function getPeriodEdit({ defaultValues, editedValues }) {
	// initialize date dto's
	// these will hold the newest combination of from date/time and to date/time
	let momentFromDate, momentToDate;

	// get new or old from and to dates, if new entered it will be in editedValues and fetched first
	let fromDate =
		_get(editedValues, 'period.from', null) ||
		_get(defaultValues, 'from', null);
	let toDate =
		_get(editedValues, 'period.to', null) || _get(defaultValues, 'to', null);

	// check if moment format and if not, define the format
	if (!moment.isMoment(fromDate)) {
		fromDate = moment.utc(fromDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
	} else {
		fromDate = moment(fromDate).format('YYYY-MM-DD');
	}
	if (!moment.isMoment(toDate)) {
		toDate = moment.utc(toDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
	} else {
		toDate = moment(toDate).format('YYYY-MM-DD');
	}

	// get old time
	// specify format for safari - probably no need since they are already moment objects
	const oldFromTime = moment.utc(defaultValues.from).format('HH:mm');
	const oldToTime = moment.utc(defaultValues.to).format('HH:mm');

	// if new from time entered
	if (_get(editedValues, 'period.fromTime', false))
		momentFromDate = moment
			.utc(`${fromDate}T${editedValues.period.fromTime}`)
			.format('YYYY-MM-DDTHH:mm:ss');
	else
		momentFromDate = moment
			.utc(`${fromDate}T${oldFromTime}`)
			.format('YYYY-MM-DDTHH:mm:ss');

	// if new to time entered
	if (_get(editedValues, 'period.toTime', false))
		momentToDate = moment
			.utc(`${toDate}T${editedValues.period.toTime}`)
			.format('YYYY-MM-DDTHH:mm:ss');
	else
		momentToDate = moment
			.utc(`${toDate}T${oldToTime}`)
			.format('YYYY-MM-DDTHH:mm:ss');

	return {
		from: momentFromDate,
		to: momentToDate,
	};
}
