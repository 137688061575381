// --------------
// Action Types
// --------------
export const FETCH_POSITIONLIST = 'EMPLOYMENT_DOCUMENTS/FETCH_POSITIONLIST';
export const LOADING_POSITIONLIST = 'EMPLOYMENT_DOCUMENTS/LOADING_POSITIONLIST';
export const SELECT_POSITIONLIST = 'EMPLOYMENT_DOCUMENTS/SELECT_POSITIONLIST';
export const RESET_STATE = 'EMPLOYMENT_DOCUMENTS/RESET_STATE';

// --------------
// Phrases ? Schemas ? Enums... things that are, well, constants :)
// --------------