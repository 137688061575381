'use strict';

import {
	UPDATE_PRODUCT_RECOMMENDATION,
	SET_PRODUCT_RECOMMENDATION,
	RESET_STATE
} from './productRecommendations.actions';

const defaultState = {
	data: {
		productRecommendation: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_PRODUCT_RECOMMENDATION: {
		return {
			...state,
			data: {
				productRecommendation: payload
			}
		};
	}

	case UPDATE_PRODUCT_RECOMMENDATION: {
		return {
			...state,
			data: {
				productRecommendation: {
					...state.data.productRecommendation,
					...payload
				}
			}
		};
	}

	case RESET_STATE: {
		return {
			...state,
			data: {
				productRecommendation: defaultState.data.productRecommendation
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
