'use strict';

/**
 * @function initHeaderFocus
 * @memberOf SERVICES.__models
 * @description
 * Sets focus on first possible input field
 * @param  {array} headers  headers to look for first header in
 */

import _each from 'lodash/each';

module.exports = function(headers) {
	var focused = false;
	_each(headers, function(header) {
		if ((header.canCreate || header.canAdd || header.canEdit) && !focused) {
			header.focus = true;
			focused = true;
		}
	});
	this.setHeaders(headers);
};