import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './organisationMarketShiftRules.phrases';

/**
 * @function fetchMarketShiftRules
 * @description Fetch all product recommendations
 * @param {object} state
 */
export function fetchMarketShiftRules(state, newFilter) {
	const filter = state.filter ? `${state.filter};${newFilter}` : newFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get('/shiftplanning/market_shift_rules', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addMarketShiftRule
 * @description adds waiting time grid
 */
export function addMarketShiftRule(data) {
	return post('/shiftplanning/market_shift_rules', data)
		.then((res) => {
			setFeedback(phrases.MARKET_SHIFT_RULE_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteMarketShiftRule
 * @description deletes waiting time grids
 */
export function deleteMarketShiftRule(productId) {
	return remove(`/shiftplanning/market_shift_rules/${productId}`)
		.then((res) => {
			setFeedback(phrases.MARKET_SHIFT_RULE_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
