import { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR } from './notifier.actions';

// I am planning to renamind this reducer to settingsCenter
// and let it contain all logs, notifications, errors, or such data

const defaultState = {
	notifications: [],
};

export default (state = defaultState, action) => {
	switch (action.type) {
		case ENQUEUE_SNACKBAR:
			return {
				...state,
				notifications: [
					...state.notifications,
					{
						key: action.key,
						...action.notification,
					},
				],
			};

		case CLOSE_SNACKBAR:
			return {
				...state,
				notifications: state.notifications.map((notification) =>
					action.dismissAll || notification.key === action.key
						? { ...notification, dismissed: true }
						: { ...notification }
				),
			};

		case REMOVE_SNACKBAR:
			return {
				...state,
				notifications: state.notifications.filter((notification) => notification.key !== action.key),
			};

		default:
			return state;
	}
};
