import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchIngredientVariation
 * @description Fetch all productes values
 * @param {object} state
 */
export function fetchIngredientVariations(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get(`/product/ingredient_variations`, params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editIngredientVariation
 * @description edit
 */
export function editIngredientVariation(productId, data) {
	return post(`/product/ingredient_variations/${productId}`, data)
		.then(res => {
			setFeedback('IngredientVariation edited', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function addIngredientVariation
 * @description add
 */
export function addIngredientVariation(data) {
	return post(`/product/ingredient_variations`, data)
		.then(res => {
			setFeedback('IngredientVariation added', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteIngredientVariation
 * @description del
 */
export function deleteIngredientVariation(variantId) {
	return remove(`/product/ingredient_variations/${variantId}`)
		.then(res => {
			setFeedback('IngredientVariation deleted', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
