/* eslint-disable react/prop-types */
import React from 'react';

// components
import { Input } from 'dumb';

// lodash
import _get from 'lodash/get';

export default function getColumns(reduxKey) {
	return [
		{
			Header: 'Name',
			id: 'name',
			accessor: (d) => _get(d, 'name', ''),
			filterPath: ':name',
		},
		{
			Header: 'Ingredient category',
			id: 'ingredient_category',
			accessor: (d) => _get(d, 'ingredient_category.name', ''),
			filterPath: ':ingredient_category.name',
		},
		{
			Header: 'Fuel',
			id: 'fuel',
			accessor: (d) => _get(d, 'fuel', ''),
			filterPath: ':fuel',
		},
		{
			Header: 'Asset Collection',
			id: 'asset_collection',
			accessor: (d) => _get(d, 'asset_collection.name', null),
			filterPath: ':asset_collection.name',
		},
		{
			Header: 'Ingredient Pricing Group',
			id: 'ingredient_pricing_group',
			accessor: (d) => _get(d, 'ingredient_pricing_group.name', null),
			filterPath: ':ingredient_pricing_group.name',
		},
		{
			Header: 'List name',
			id: 'list_name',
			accessor: 'list_name',
			filterPath: ':list_name',
		},
		{
			Header: 'Operation type',
			id: 'operation_type',
			accessor: 'operation_type',
			filterPath: ':operation_type',
		},
		{
			Header: 'Availability administration',
			id: 'availabilityAdministration',
			accessor: 'availability_administration',
			filterPath: ':availability_administration',
			filterable: false,
			Cell: (d) => {
				return (
					<Input
						type="checkbox"
						checked={_get(d, 'original.availability_administration', false)}
						disabled
					/>
				);
			},
		},
		{
			Header: 'Legacy',
			id: 'legacy',
			accessor: (d) => _get(d, 'legacy', ''),
			filterPath: ':legacy',
			filterable: false,
			Cell: (d) => {
				return (
					<Input
						type="checkbox"
						checked={_get(d, 'original.legacy', false)}
						disabled
					/>
				);
			},
		},
	];
}
