import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tile from './tile/tile.component';
// import Tile from 'structure/salesConfiguration/components/components/layout/pos/loyaltyPosConfiguration/components/itemCard/itemCard.jsx';

import cx from 'classnames';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import { store } from 'appState';
import * as posConfigurationLayout from './../../../posConfigurationLayout.actions';

import './group.css';

/**
 * @function getImages
 * @description Helper function for extracting correct images. Most importantly because if tile.tile_layout_sub_group is set,
 * The tile acts as a subgroup and therefore needs to fetch the image from the assosiated group
 * @param {Array} images
 * @param {Object} tile
 * @param {Object} groups
 * @returns {Array} array of images
 */
const getImages = (images, tile, groups) => {
	if (!tile) return [];

	if (tile.tile_layout_sub_group) {
		return images.filter(
			image =>
				image.asset_collection.id ===
				_get(groups[tile.tile_layout_sub_group.id], 'asset_collection.id', null)
		);
	}

	return images.filter(
		image =>
			image.asset_collection.id ===
			_get(tile, 'product.asset_collection.id', null)
	);
};

class Group extends Component {
	constructor(props) {
		super(props);

		this.state = {
			grid: props.grid
		};

		this.changeLayout = this.changeLayout.bind(this);
		this.onDrop = this.onDrop.bind(this);
	}

	changeLayout() {
		this.state.grid === 3
			? this.setState(() => ({ grid: 4 }))
			: this.setState(() => ({ grid: 3 }));
	}

	onDrop(dragIndex, hoverIndex) {
		store.dispatch(
			posConfigurationLayout.updateTilePositionInPOS(dragIndex, hoverIndex)
		);

		const { tiles } = this.props;

		const payload = tiles.map((tile, index) => {
			return { id: tile.id, position: tile.position };
		});

		this.props.rotateTiles({ rotations: payload }, true);
	}

	/**
	 * Extract visible grid
	 * @param grid
	 * @param gridSize
	 * @param page
	 * @returns {*}
	 */
	extractVisibleGrid(grid, gridSize, page) {
		return Object.keys(grid).reduce((acc, key) => {
			if (
				Number(key) > (page - 1) * gridSize &&
				Number(key) <= page * gridSize
			) {
				acc = {
					...acc,
					[key]: grid[key]
				};
			}
			return acc;
		}, {});
	}

	getPositionOfTileInGrid(position) {
		const { gridDimensions, gridSize, page } = this.props;

		position = position - 1;
		const pagePosition = position % gridSize;
		const widthOfArray = gridDimensions[1];
		const x = pagePosition % widthOfArray;
		const y = (pagePosition - x) / widthOfArray;

		// Add 1 to x and y to add readable context that corresponds with the api data
		return { page, x: x + 1, y: y + 1 };
	}

	render() {
		const {
			tiles,
			grid,
			openTileModal,
			removeTile,
			page,
			gridSize,
			rotateTiles,
			gridDimensions,
			setMenuItemInFocus,
			imageAssets,
			ui,
			groups
		} = this.props;

		const posContainerClassNames = cx('group', {
			'group--loading': ui.loading
		});

		const visibleGrid = this.extractVisibleGrid(grid, gridSize, page);

		const flexWidth = 100 / gridDimensions[1];

		return (
			!_isEmpty(visibleGrid) && (
				<div className={posContainerClassNames}>
					<div className="group__inner">
						{Object.keys(visibleGrid).map((key, i) => {
							const tile = tiles.find(tile => tile.position === Number(key));
							const isTileOccupied = !_isNil(tile);

							const position = isTileOccupied
								? tile.position
								: visibleGrid[key].position;
							const coordinates = this.getPositionOfTileInGrid(position);

							const backgroundImages = isTileOccupied
								? getImages(imageAssets, tile, groups)
								: [];
							return (
								<Tile
									key={i}
									index={position}
									tile={isTileOccupied ? tile : {}}
									position={position}
									tileIsPresent={isTileOccupied}
									coordinates={coordinates}
									flexWidth={flexWidth}
									backgroundImages={backgroundImages}
									onDrop={this.onDrop}
									setMenuItemInFocus={setMenuItemInFocus}
									rotateTiles={rotateTiles}
									openTileModal={openTileModal}
									removeTile={removeTile}
								/>
							);
						})}
					</div>
				</div>
			)
		);
	}
}

Group.defaultProps = {
	gridSize: null,
	gridDimensions: [],
	page: 0,
	tiles: [],
	grid: {}
};

Group.propTypes = {
	tiles: PropTypes.object,
	imageAssets: PropTypes.array,
	grid: PropTypes.object,
	gridSize: PropTypes.number,
	page: PropTypes.number,
	gridDimensions: PropTypes.array,
	openTileModal: PropTypes.func,
	setMenuItemInFocus: PropTypes.func,
	removeTile: PropTypes.func,
	groups: PropTypes.object,
	rotateTiles: PropTypes.func,
	// fetching: PropTypes.bool,
	ui: PropTypes.object
};

export default Group;
