import {
	RESET_STATE,
	LOADING_TODOS,
	SET_TODOS,
	UPDATE_TODO,
	REMOVE_TODO,
	SET_INPUT,
	RESET_FORM
} from './workplaceTodos.actions';

const defaultState = {
	ui: {
		loading: false,
		modalLoading: false
	},
	data: {
		todos: [],
		formData: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_INPUT: {
		return {
			...state,
			data: {
				...state.data,
				formData: {
					...state.data.formData,
					[payload.type]: payload.value
				}
			}
		};
	}

	case SET_TODOS: {
		return {
			...state,
			data: { ...state.data, todos: payload }
		};
	}

	case LOADING_TODOS: {
		return { ...state, ui: { ...state.ui, loading: payload } };
	}

	case UPDATE_TODO: {
		const without = state.data.todos.filter(x => x.id !== payload.id);

		return {
			...state,
			data: {
				...state.data,
				todos: [...without, ...[payload]]
			},
			ui: { ...state.ui, loading: false }
		};
	}

	case REMOVE_TODO: {
		const todos = state.data.todos.filter(x => x.id !== payload);

		return {
			...state,
			data: {
				...state.data,
				todos
			},
			ui: { ...state.ui, loading: false }
		};
	}

	case RESET_FORM: {
		return {
			...state,
			data: {
				...state.data,
				formData: {}
			}
		};
	}


	case RESET_STATE: {
		return defaultState;
	}

	default:
		return state;
	}
}

export default reducer;
