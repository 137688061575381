'use strict';

import cx from 'classnames';
import _every from 'lodash/every';
import _isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// import { DateRangePicker, SingleDatePicker } from 'react-dates';
import SingleDatePicker from 'react-dates/lib/components/SingleDatePicker';
import DateRangePicker from 'react-dates/lib/components/DateRangePicker';
import DateService from './date.service';
import SimpleDate from './simpleDate.component';
import { Icon } from 'dumb';

import './date.css';
import 'react-dates/initialize';

class Date extends Component {
	constructor(props) {
		super(props);

		this.state = {
			focused: null,
			simpleFocused: null,
			date: null,
			startDate: props.startDate,
			endDate: props.endDate,
			didReceivePropDates: false,
			type: props.type,
		};

		this.nullDate = '31-12-9999';

		this.DateService = new DateService();
		this.translations = this.DateService.GetTranslations();

		this.onFocusChange = this.onFocusChange.bind(this);
		this.onSingleDateChange = this.onSingleDateChange.bind(this);
		this.onSimpleFocusChange = this.onSimpleFocusChange.bind(this);
		this.onRangeDateChange = this.onRangeDateChange.bind(this);
		this.renderCustomCaption = this.renderCustomCaption.bind(this);
		this.renderFutureYears = this.renderFutureYears.bind(this);
		this.initialVisibleMonth = this.initialVisibleMonth.bind(this);
	}

	focus() {
		const { type } = this.props;
		if (type === 'single') this.simpleDate && this.simpleDate.focus();
	}

	initialVisibleMonth(date) {
		if (!moment.isMoment(date) || moment(date).isSame('9999-12-31', 'year'))
			return moment();
		else return date;
	}

	initiateDates(props) {
		const {
			initialDate,
			initialStartDate,
			initialEndDate,
			shouldTriggerOnChange,
			onChange,
		} = props;

		const date = _isString(initialDate) ? moment(initialDate) : initialDate;
		const startDate = _isString(initialStartDate)
			? moment(initialStartDate)
			: initialStartDate;
		const endDate = _isString(initialEndDate)
			? moment(initialEndDate)
			: initialEndDate;

		const valid = [
			!this.state.didReceivePropDates,
			moment.isMoment(date) ||
				moment.isMoment(startDate) ||
				moment.isMoment(endDate),
		];

		if (_every(valid)) {
			this.setState({
				date,
				startDate,
				endDate,
				didReceivePropDates: true,
			});
			if (shouldTriggerOnChange) {
				if (moment.isMoment(date)) onChange(date);
				else if (moment.isMoment(startDate)) onChange({ startDate, endDate });
			}
		}
	}

	onSingleDateChange(date) {
		const { onChange } = this.props;

		this.setState(
			() => ({ date, focused: false, simpleFocused: false }),
			() => {
				onChange(date);
			}
		);
	}

	onRangeDateChange({ startDate, endDate }) {
		const { onChange } = this.props;
		onChange({ startDate, endDate });
		this.setState(() => ({ startDate, endDate }));
	}

	onSimpleFocusChange(focused) {
		const { onFocusChange } = this.props;
		if (focused.simpleFocused) {
			this.setState({ focused: true, simpleFocused: true }, () => {
				onFocusChange({
					focused: this.state.focused,
					simpleFocused: this.state.simpleFocused,
				});
			});
		} else {
			this.setState({ simpleFocused: focused.simpleFocused }, () => {
				onFocusChange({
					focused: this.state.focused,
					simpleFocused: this.state.simpleFocused,
				});
			});
		}
	}

	onFocusChange(focused) {
		const { type, onFocusChange } = this.props;
		if (type === 'single') {
			this.setState(
				(prevState) => ({ focused: prevState.simpleFocused }),
				() => {
					onFocusChange({
						focused: this.state.focused,
						simpleFocused: this.state.simpleFocused,
					});
				}
			);
		} else {
			this.setState((prevState) => ({
				endDate:
					focused === null && prevState.endDate === null
						? prevState.startDate
						: prevState.endDate,
				focused,
			}));
			onFocusChange(focused);
		}
	}

	componentDidMount() {
		if (this.props.type === 'simple') this.setState(() => ({ type: 'simple' }));
		this.initiateDates(this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.initiateDates(nextProps);
	}

	renderCustomCaption({ month, onMonthSelect, onYearSelect }) {
		return (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<select
					value={month.month()}
					onChange={(e) => {
						onMonthSelect(month, e.target.value);
					}}>
					{moment.months().map((label, value) => (
						<option key={value} value={value}>
							{label}
						</option>
					))}
				</select>
				<select
					value={month.year()}
					onChange={(e) => {
						onYearSelect(month, e.target.value);
					}}>
					{this.renderPastYears()}
					{this.renderFutureYears()}
				</select>
			</div>
		);
	}

	renderPastYears() {
		const years = [];
		for (let i = 65; i >= 0; i--) {
			years.push(
				<option key={i} value={moment().year() - i}>
					{moment().year() - i}
				</option>
			);
		}
		return years;
	}

	renderFutureYears() {
		const years = [];
		for (let i = 1; i <= 10; i++) {
			years.push(
				<option key={i + 'i'} value={moment().year() + i}>
					{moment().year() + i}
				</option>
			);
		}
		return years;
	}

	render() {
		const {
			additionalDateFormat,
			displayFormat,
			initialFocused,
			disabled,
			keepOpenOnDateSelect,
			reopenPickerOnClearDate,
			minimumNights,
			showClearDate,
			numberOfMonths,
			firstDayOfWeek,
			openDirection,
			className,
			appendToBody,
			yearFirst,
			selectedDate,
			showWeekNumber,
			isOutsideRange,
			withPortal,
			inTable,
			onClose,
		} = this.props;

		const { date, startDate, endDate, focused, type } = this.state;

		let datePicker;
		// let verticalSpacing = openDirection === 'up' ? -30 : 9;

		if (type === 'single')
			datePicker = [
				<SimpleDate
					key="simpleDate"
					additionalDateFormat={additionalDateFormat}
					ref={(input) => (this.simpleDate = input)}
					onChange={this.onSingleDateChange}
					firstDayOfWeek={firstDayOfWeek}
					minimumNights={minimumNights}
					openDirection={openDirection}
					disabled={disabled}
					onFocusChange={this.onSimpleFocusChange}
					date={selectedDate || date}
					renderMonthElement={this.renderCustomCaption}
					appendToBody={appendToBody}
					hideKeyboardShortcutsPanel
					yearFirst={yearFirst}
				/>,
				<SingleDatePicker
					key="datePicker"
					ref={(input) => (this.dateInput = input)}
					isOutsideRange={isOutsideRange}
					numberOfMonths={numberOfMonths}
					id={moment().toISOString()}
					initialVisibleMonth={() => this.initialVisibleMonth(date)}
					onDateChange={this.onSingleDateChange}
					onFocusChange={this.onFocusChange}
					focused={focused || initialFocused}
					disabled={disabled}
					firstDayOfWeek={firstDayOfWeek}
					openDirection={openDirection}
					placeholder={this.translations.DATE_PLACEHOLDER}
					date={selectedDate || date}
					reopenPickerOnClearDate={reopenPickerOnClearDate}
					keepOpenOnDateSelect={keepOpenOnDateSelect}
					renderMonthElement={this.renderCustomCaption}
					noBorder
					withPortal={withPortal}
					renderDayContents={(day) => {
						return (
							<>
								{showWeekNumber &&
									day.isSame(day.clone().startOf('isoweek'), 'day') && (
										<span className="j-day-picker-range-controller__week-number">
											{day.format('W')}
										</span>
									)}
								{day.format('D')}{' '}
							</>
						);
					}}
					appendToBody={appendToBody}
					hideKeyboardShortcutsPanel
				/>,
				<div key="clearDate" className="date__clear">
					{showClearDate &&
						date !== null &&
						date.format('DD-MM-YYYY') !== this.nullDate && (
							<span
								className="date__clear--clear"
								onClick={() =>
									this.onSingleDateChange(moment(this.nullDate, 'DD-MM-YYYY'))
								}>
								<Icon name="clear" />
							</span>
						)}
				</div>,
			];
		else
			datePicker = (
				<div
					className={cx('date__range-wrapper', {
						'date__range-wrapper--in-table': inTable,
					})}>
					<DateRangePicker
						ref={(input) => (this.dateInput = input)}
						isOutsideRange={isOutsideRange}
						initialVisibleMonth={() => this.initialVisibleMonth(startDate)}
						onDatesChange={this.onRangeDateChange}
						onFocusChange={this.onFocusChange}
						focusedInput={focused || initialFocused}
						numberOfMonths={numberOfMonths}
						displayFormat={displayFormat}
						firstDayOfWeek={firstDayOfWeek}
						minimumNights={minimumNights}
						disabled={disabled}
						withPortal={withPortal}
						openDirection={openDirection}
						startDatePlaceholderText={this.translations.START_DATE_PLACEHOLDER}
						startDate={startDate}
						renderDayContents={(day) => {
							return (
								<>
									{showWeekNumber &&
										day.isSame(day.clone().startOf('isoweek'), 'day') && (
											<span className="j-day-picker-range-controller__week-number">
												{day.format('W')}
											</span>
										)}
									{day.format('D')}{' '}
								</>
							);
						}}
						startDateId="startDate"
						endDateId="endDate"
						endDate={endDate}
						reopenPickerOnClearDates={reopenPickerOnClearDate}
						keepOpenOnDateSelect={keepOpenOnDateSelect}
						renderMonthElement={this.renderCustomCaption}
						appendToBody={appendToBody}
						hideKeyboardShortcutsPanel
						onClose={onClose}
					/>
					{showClearDate && (startDate || endDate) && (
						<Icon
							className="date__range-wrapper__clear"
							name="close"
							onClick={(e) => {
								this.setState(() => ({
									startDate: null,
									endDate: null,
								}));
								this.props.onChange({ startDate: null, endDate: null });
							}}
						/>
					)}
				</div>
			);

		const dateCx = cx('date', {
			'date--disabled': disabled,
			[className]: className,
		});

		return <div className={dateCx}>{datePicker}</div>;
	}
}

Date.defaultProps = {
	displayFormat: 'DD-MM-YYYY',
	onFocusChange: () => {},
	type: 'range',
	reopenPickerOnClearDate: false,
	keepOpenOnDateSelect: false,
	numberOfMonths: 1,
	startDate: null,
	showWeekNumber: true,
	showClearDate: false,
	shouldTriggerOnChange: false,
	endDate: null,
	firstDayOfWeek: 1,
	minimumNights: 1,
	openDirection: 'down',
	appendToBody: false,
	isOutsideRange: () => false,
	withPortal: true,
	zIndex: 500,
	inTable: false,
	onClose: () => {},
};

Date.propTypes = {
	additionalDateFormat: PropTypes.string,
	isOutsideRange: PropTypes.func,
	endDate: PropTypes.object,
	startDate: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	onFocusChange: PropTypes.func,
	displayFormat: PropTypes.string,
	reopenPickerOnClearDate: PropTypes.bool,
	keepOpenOnDateSelect: PropTypes.bool,
	initialFocused: PropTypes.bool,
	showWeekNumber: PropTypes.bool,
	showClearDate: PropTypes.bool,
	disabled: PropTypes.bool,
	minimumNights: PropTypes.number,
	numberOfMonths: PropTypes.number,
	firstDayOfWeek: PropTypes.number,
	type: PropTypes.oneOf(['range', 'single']),
	openDirection: PropTypes.oneOf(['up', 'down']),
	className: PropTypes.string,
	appendToBody: PropTypes.bool,
	yearFirst: PropTypes.bool,
	selectedDate: PropTypes.object,
	withPortal: PropTypes.bool,
	inTable: PropTypes.bool,
	onClose: PropTypes.func,
};

export default Date;
