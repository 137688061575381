export default {
	SALES_CONFIGURATIONS: 'Sales Configurations',
	PRODUCT_CONFIGURATION: 'Product Configuration',
	NUTRITION: 'Nutrition',
	DISCOUNTS: 'Discounts',
	ASSETS: 'Assets',
	LAYUOTS: 'Layouts',
	INGREDIENTS_CONFIGURATION: 'Ingredient Configuration',
	PRICINGS: 'Pricing',
	GLOBAL_PRODUCT_DISCOUNTS: 'Global product discounts',
	POS_WORKPLACE_RELATION: 'POS/Workplace relation',
	RAW_INGREDIENTS: 'Raw ingredients',
	POS: 'POS',
	PUBLISH: 'Publish',
	PUBLISHED: 'Published!',
	MODAL_HEADER: 'Confirm publish changes',
	MODAL_CANCEL: 'Cancel',
	MODAL_TEXT:
		'Publishing will trigger a re-calculation of layouts, prices, etc. that will be server to the App/POS and list',
	OPERATIONAL_EXCELLENCE: 'Operational excellence',
};
