'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import {
	Button,
	InputCollectionSelect,
	SingleDatePickerInput,
	Icon,
	Tooltip,
} from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// duplication row
import WorkplacePosConfigurationBatchStepFormRowDuplicateAsNew from './workplacePosConfigurationBatchStepFormRowDuplicateAsNew';

// phrases/ constants
import phrases from './../../../workplacePosConfiguration.phrases';
import constants from 'services/constants';

// lodash
import _get from 'lodash/get';

class WorkplacePosConfigurationBatchStepListDuplicateAsNew extends Component {
	_onRemoveEntry(id) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(id);
	}

	_editListEntry(e, row, type) {
		// get value from event
		let value;
		if (e === null) value = e;
		else value = e.target ? e.target.value : e;

		// if dates cleared, set them to today and infinite
		if ((type === 'activeFrom' || type === 'activeTo') && value === null) {
			value =
				type === 'activeFrom'
					? moment.utc().format(constants.shortDate)
					: '9999-12-31';
		}

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			[type]: value,
		});
	}

	_getCollectionSelectValue(entry) {
		if (!entry) return;
		return entry.value && entry.label
			? entry
			: {
				value: entry.identifier,
				label: entry.name,
			  };
	}

	render() {
		const {
			batchListData,
			batchFormData,
			updateBatchForm,
			posConfigurationReduxKey,
			ingredientConfigurationReduxKey,
			productRecommendationReduxKey,
			appLayoutReduxKey,
			posLayoutReduxKey,
			toggleOverlay,
			revertBatchListItem,
			loading,
		} = this.props;

		return (
			<>
				<WorkplacePosConfigurationBatchStepFormRowDuplicateAsNew
					batchFormData={batchFormData}
					updateBatchForm={updateBatchForm}
					posConfigurationReduxKey={posConfigurationReduxKey}
					ingredientConfigurationReduxKey={ingredientConfigurationReduxKey}
					productRecommendationReduxKey={productRecommendationReduxKey}
					appLayoutReduxKey={appLayoutReduxKey}
					posLayoutReduxKey={posLayoutReduxKey}
					toggleOverlay={toggleOverlay}
					loading={loading}
				/>
				<TableList className="workplace-pos-configuration__modal-batch__duplicate-as-new__table">
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_POS_CONFIGURATION}</th>
							<th>{phrases.MODAL_BATCH_STEP_WORKPLACE}</th>
							<th>{phrases.MODAL_BATCH_STEP_PRODUCT_RECOMMENDATION}</th>
							<th>{phrases.MODAL_BATCH_STEP_POS_TILE_LAYOUT}</th>
							<th>{phrases.MODAL_BATCH_STEP_APP_TILE_LAYOUT}</th>
							<th>{phrases.MODAL_BATCH_STEP_INGREDIENT_CONFIGURATION}</th>
							<th>{phrases.MODAL_BATCH_STEP_ACTIVE_FROM}</th>
							<th>{phrases.MODAL_BATCH_STEP_ACTIVE_TO}</th>

							<th />
						</tr>
					</thead>
					<tbody>
						{batchListData.map((entry, i) => {
							return (
								<TableListRow key={entry.identifier}>
									<TableListCell width="30px">{i + 1}</TableListCell>
									<TableListCell width="200px">
										<InputCollectionSelect
											id={`${entry.identifier}-pos_configuration`}
											placeholder={
												phrases.MODAL_BATCH_STEP_POS_CONFIGURATION_PLACEHOLDER
											}
											value={entry.posConfiguration}
											handleChange={(key, value) =>
												this._editListEntry(value, entry, 'posConfiguration')
											}
											cache
											apiPath="/pos/pos_configurations"
											params={{
												limit: 50,
												filter: ':legacy==false',
											}}
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											inputFilterFormat={(input) => `:name=like='%${input}%'`}
										/>
									</TableListCell>
									<TableListCell width="180px">
										{_get(entry, 'workplace.name', '')}
										<InputCollectionSelect
											id={`${entry.identifier}-workplace`}
											placeholder={
												phrases.MODAL_BATCH_STEP_WORKPLACE_PLACEHOLDER
											}
											value={entry.workplace}
											handleChange={(key, value) =>
												this._editListEntry(value, entry, 'workplace')
											}
											cache
											apiPath="/administration/workplaces"
											params={{
												limit: 50,
												// filter: `:market.id=IN=${batchFormData.marketsFilter}`,
											}}
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											inputFilterFormat={(input) => `:name=like='%${input}%'`}
										/>
									</TableListCell>

									<TableListCell width="200px">
										<InputCollectionSelect
											id={`${entry.identifier}-productRecommendation`}
											value={this._getCollectionSelectValue(
												entry.productRecommendation
											)}
											handleChange={(key, event) =>
												this._editListEntry(
													event,
													entry,
													'productRecommendation'
												)
											}
											cache
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											apiPath="/pos/product_recommendations"
											params={{
												limit: 30,
											}}
										/>
									</TableListCell>

									<TableListCell width="200px">
										<InputCollectionSelect
											id={`${entry.identifier}-posTileLayout`}
											placeholder={
												phrases.MODAL_BATCH_STEP_POS_TILE_LAYOUT_PLACEHOLDER
											}
											value={this._getCollectionSelectValue(
												entry.posTileLayout
											)}
											handleChange={(key, event) =>
												this._editListEntry(event, entry, 'posTileLayout')
											}
											cache
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											apiPath="/pos/tile_layouts"
											inputFilterFormat={(input) => `:name=like='%${input}%'`}
											params={{
												limit: 30,
												filter: `:type=='POS'`,
											}}
										/>
									</TableListCell>

									<TableListCell width="200px">
										<InputCollectionSelect
											id={`${entry.identifier}-appTileLayout`}
											placeholder={
												phrases.MODAL_BATCH_STEP_APP_TILE_LAYOUT_PLACEHOLDER
											}
											value={this._getCollectionSelectValue(
												entry.appTileLayout
											)}
											handleChange={(key, event) =>
												this._editListEntry(event, entry, 'appTileLayout')
											}
											cache
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											apiPath="/pos/tile_layouts"
											inputFilterFormat={(input) => `:name=like='%${input}%'`}
											params={{
												limit: 30,
												filter: `:type=='App'`,
											}}
										/>
									</TableListCell>

									<TableListCell width="200px">
										<InputCollectionSelect
											id={`${entry.identifier}-ingredientConfiguration`}
											placeholder={
												phrases.MODAL_BATCH_STEP_INGREDIENT_CONFIGURATION_PLACEHOLDER
											}
											value={this._getCollectionSelectValue(
												entry.ingredientConfiguration
											)}
											handleChange={(key, event) =>
												this._editListEntry(
													event,
													entry,
													'ingredientConfiguration'
												)
											}
											cache
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											apiPath="/pos/ingredient_configurations"
											params={{
												limit: 30,
											}}
										/>
									</TableListCell>

									<TableListCell width="180px">
										<SingleDatePickerInput
											id={`${entry.identifier}-activeFrom`}
											onChange={(event) =>
												this._editListEntry(event, entry, 'activeFrom')
											}
											selectedDate={moment
												.utc(entry.activeFrom)
												.format(constants.shortDate)}
											noClockButton
											clearable
										/>
									</TableListCell>

									<TableListCell width="180px">
										<SingleDatePickerInput
											id={`${entry.identifier}-activeTo`}
											onChange={(event) =>
												this._editListEntry(event, entry, 'activeTo')
											}
											selectedDate={moment
												.utc(entry.activeTo)
												.format(constants.shortDate)}
											noClockButton
											clearable
										/>
									</TableListCell>

									<TableListCell width="100px">
										<Tooltip
											zIndex={600}
											text={phrases.MODAL_BATCH_STEP_REVERT_TOOLTIP}
											renderData={(ref, onMouseEnter, onMouseLeave) => (
												<Button
													type="warning"
													size="tiny"
													disabled={!entry.rowEdited}
													onClick={() => revertBatchListItem(entry)}
													refProp={ref}
													onMouseEnter={onMouseEnter}
													onMouseLeave={onMouseLeave}>
													<Icon name="refresh" />
												</Button>
											)}
										/>
										{batchListData.length > 1 && (
											<Button
												type="negative"
												size="tiny"
												onClick={() => this._onRemoveEntry(entry.identifier)}>
												<Icon name="delete" />
											</Button>
										)}
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
			</>
		);
	}
}

WorkplacePosConfigurationBatchStepListDuplicateAsNew.propTypes = {
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	productRecommendationReduxKey: PropTypes.string,
	ingredientConfigurationReduxKey: PropTypes.string,
	posConfigurationReduxKey: PropTypes.string,
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
	revertBatchListItem: PropTypes.func,
	appLayoutReduxKey: PropTypes.string,
	posLayoutReduxKey: PropTypes.string,
	loading: PropTypes.bool,
};

export default WorkplacePosConfigurationBatchStepListDuplicateAsNew;
