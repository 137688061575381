import { ActionType } from 'types/redux';

export const SET_NAME = ' MONEYBALL_POSITION/SET_NAME';
export const SET_DESCRIPTION = 'MONEYBALL_POSITION/SET_DESCRIPTION';
export const SET_BADGE = 'MONEYBALL_POSITION/SET_BADGE';
export const SET_SORT_ORDER = 'MONEYBALL_POSITION/SET_SORT_ORDER';
export const SET_FROM_DATE = 'MONEYBALL_POSITION/SET_FROM_DATE';
export const SET_TO_DATE = 'MONEYBALL_POSITION/SET_TO_DATE';
export const RESET_DATA = 'MONEYBALL_POSITION/RESET_DATA';

export function resetData(): ActionType {
	return {
		type: RESET_DATA,
	};
}

export function setName(payload: string): ActionType {
	return {
		type: SET_NAME,
		payload,
	};
}

export function setDescription(payload: string): ActionType {
	return {
		type: SET_DESCRIPTION,
		payload,
	};
}

export function setBadge(payload: string): ActionType {
	return {
		type: SET_BADGE,
		payload,
	};
}

export function setSortOrder(payload: number | null): ActionType {
	return {
		type: SET_SORT_ORDER,
		payload,
	};
}

export function setFromDate(payload: string): ActionType {
	return {
		type: SET_FROM_DATE,
		payload,
	};
}

export function setToDate(payload: string): ActionType {
	return {
		type: SET_TO_DATE,
		payload,
	};
}
