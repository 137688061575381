import { TOGGLE_MODAL } from './missingMoneyballNotificationModal.actions';

const defaultState = {
	showModal: false
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case TOGGLE_MODAL:
		return {
			...state,
			showModal: payload,
		};

	default:
		return state;
	}
}

export default reducer;
