import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';

import GlobalDiscountsList from './services/globalDiscountsList.service.js';

const globalDiscountsListReduxKey = '/sale/discounts';
const globalDiscountsList = new GlobalDiscountsList(globalDiscountsListReduxKey);
const globalDiscountsListProps = globalDiscountsList.getProps();

export function GlobalDiscounts() {
	useEffect(() => {
		globalDiscountsList.initView();
	}, []);

	return <ListComponent reduxKey={globalDiscountsListReduxKey} {...globalDiscountsListProps} />;
}
