import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { resetState, rerenderTable } from './../../store/globalBundleRuleTranslations.actions';

// components
import { ModalStepper } from 'dumb';
import GlobalBundleRuleTranslationsModalStep from './components/globalBundleRuleTranslationsModalStep';

// services
import { addGlobalBundleRuleTranslations } from './../../globalBundleRuleTranslations.service';

// phrases
import phrases from './../../globalBundleRuleTranslations.phrases';

const GlobalBundleRuleTranslationsModalBatch = ({ id, onClose }) => {
	const modalRef = useRef();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const batchList = useSelector((store) => store.globalBundleRuleTranslations.batchList);

	const onNext = () => {
		setLoading(true);

		const payload = batchList.map((entry) => ({
			global_bundle_rule: id,
			name: entry.name,
			language: entry.language?.value?.id ?? null,
		}));

		addGlobalBundleRuleTranslations({ batch: payload })
			.then(() => {
				setLoading(false);
				dispatch(rerenderTable());
				onClose();
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const steps = [
		{
			component: <GlobalBundleRuleTranslationsModalStep />,
			title: phrases.MODAL_TITLE,
			onBack: onClose,
			onNext,
			loading,
			isValid: true,
			confirmButtonLabel: phrases.SUBMIT,
			defaultStyles: false,
			noScroll: false,
		},
	];

	useEffect(() => {
		return () => {
			dispatch(resetState());
		};
	}, [dispatch]);

	return (
		<ModalStepper header={phrases.MODAL_TITLE} onClose={onClose} ref={modalRef} isOpen steps={steps} showStep={false} />
	);
};

GlobalBundleRuleTranslationsModalBatch.propTypes = {
	onClose: PropTypes.func,
	id: PropTypes.number,
};

export default GlobalBundleRuleTranslationsModalBatch;
