import {
	RESET_STATE,
	LOADING,
	LOADING_PRODUCT,
	EDIT_GROUP
} from './addGroupModal.actions';

const defaultState = {
	ui: {
		loading: false,
		loadingProduct: false
	},
	data: {
		productVariantList: [],
		group: {
			background_color: 'ffffff',
			name: '',
			description: '',
			tile_layout: null,
			asset_collection: null,
			position: null,
			show_in_menu: true,
			tile_position_focus: 1,
			title_color: '464646'
		}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case EDIT_GROUP: {
		return {
			...state,
			data: {
				...state.data,
				group: payload
			}
		};
	}

	case LOADING: {
		return {
			...state,
			ui: { ...state.ui, loading: payload }
		};
	}

	case LOADING_PRODUCT: {
		return {
			...state,
			ui: { ...state.ui, loadingProduct: payload }
		};
	}

	case RESET_STATE: {
		return defaultState;
	}

	default:
		return state;
	}
}

export default reducer;
