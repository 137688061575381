'use strict';

import * as Reports from './auditTypes';

import { getParameters, getFilter } from './auditReports.methods';

class SalaryAuditReports {

	/// /////////////////
	// Event handlers //
	/// /////////////////

	/**
	 * @function handleHotbarChange
	 * @memberOf AuditReport
	 * @description Handler for when hotbar changes
	 * @param {object} hotbar resultSet
	 */
	handleHotbarChange(hotbarResultSet) {
		// Get type of audit
		const type = hotbarResultSet.results.View.getResults()[0].value;

		// Get date parameters
		const parameters = getParameters(hotbarResultSet);
		const filter = getFilter(hotbarResultSet);

		// Switch on type of report
		// Trigger handleChange with no filter and date parameters and resolve with entire report
		switch (type) {
		case 'Account':
			return Reports.SalaryAccounts.handleChange(filter, parameters)
				.then(() => Promise.resolve(Reports.SalaryAccounts));
		case 'Payroll':
			return Reports.SalaryPayrolls.handleChange(filter, parameters)
				.then(() => Promise.resolve(Reports.SalaryPayrolls));
		}
	}
}

export default SalaryAuditReports;
