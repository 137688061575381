import React from 'react';
import PropTypes from 'prop-types';

// components
import { Input, Label } from 'dumb';
import { ModalRow } from 'dumb/modal';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';
import { Tip } from 'dumb/tips';

// phrases
import phrases from './../sendDocuSignDocument.phrases';

// lodash
import _isEmpty from 'lodash/isEmpty';

const SendDocuSignDocumentEditValues = ({
	envelope,
	editEnvelope,
	asEmployee,
	asManager,
}) => {
	const updateEnvelope = (value, name, field) => {
		// construct a payload
		const payload = {
			[name]: value,
		};

		// update the field object with new value
		field = {
			...field,
			...payload,
		};

		// update the field inside employee envelope
		const updatedEnvelope = {
			...envelope,
			fields: envelope.fields.map((entry) => {
				if (
					entry.template_document_field.id === field.template_document_field.id
				)
					// return new field
					return field;

				return entry;
			}),
		};

		editEnvelope(updatedEnvelope);
	};

	let noEnvelopeText;
	if (asManager) noEnvelopeText = phrases.NO_MANAGER_ROLE_CONFIGURED;
	if (asEmployee) noEnvelopeText = phrases.NO_EMPLOYEE_ROLE_CONFIGURED;

	const signingGroupName = envelope.template_role?.signing_group_name ?? '';
	const receiverText = signingGroupName
		? `${phrases.RECEIVER_SIGNING_GROUP} ${signingGroupName}`
		: `${phrases.RECEIVER_EMAIL} ${envelope.email}`;

	return envelope ? (
		<>
			<ModalRow>
				<div className="send-docu-sign-document__document-value-block">
					<Label>{phrases.NAME}</Label>
					<p>{envelope.name}</p>
				</div>
				<div className="send-docu-sign-document__document-value-block">
					<Label>{phrases.EMAIL}</Label>
					<p>{envelope.email}</p>
				</div>
			</ModalRow>
			{asManager && (
				<ModalRow>
					<Tip content={receiverText} />
				</ModalRow>
			)}
			<ModalRow>
				{_isEmpty(envelope.fields) ? (
					<p className="send-docu-sign-document__no-fields">
						{phrases.NO_FIELDS}
					</p>
				) : (
					<TableList>
						<thead>
							<tr>
								<th>{phrases.FIELD}</th>
								<th>{phrases.WP2_VALUE}</th>
								<th>{phrases.FINAL}</th>
								<th />
							</tr>
						</thead>
						<tbody>
							{envelope.fields.map((entry, i) => {
								const wp2ValueNotFound = entry.value === '?';

								return (
									<TableListRow key={entry.id}>
										<TableListCell>
											{entry.template_document_field.name}
										</TableListCell>
										<TableListCell
											{...(wp2ValueNotFound
												? {
														style: {
															color: '#acacac',
														},
												  }
												: {
														onClick: () =>
															updateEnvelope(entry.value, 'final', entry),
												  })}>
											{wp2ValueNotFound ? phrases.NOT_FOUND : entry.value}
										</TableListCell>
										<TableListCell>
											<Input
												id={`final-value-${entry.id}`}
												placeholder="Enter final value..."
												value={entry?.final ?? ''}
												onChange={(e) =>
													updateEnvelope(e.target.value, 'final', entry)
												}
												size="small"
											/>
										</TableListCell>
										<TableListCell />
									</TableListRow>
								);
							})}
						</tbody>
					</TableList>
				)}
			</ModalRow>
		</>
	) : (
		<ModalRow>
			<div>
				<h2 className="send-docu-sign-document__no-role">{noEnvelopeText}</h2>
				<p className="send-docu-sign-document__no-role">
					{phrases.NO_ROLE_TIP_URL}
				</p>
			</div>
		</ModalRow>
	);
};

SendDocuSignDocumentEditValues.propTypes = {
	envelope: PropTypes.object,
	editEnvelope: PropTypes.func,
	asEmployee: PropTypes.bool,
	asManager: PropTypes.bool,
};

export default SendDocuSignDocumentEditValues;
