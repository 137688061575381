import {
	SET_INGREDIENT_VAR_OPTION,
	UPDATE_INGREDIENT_VAR_OPTION,
	RESET_INGREDIENT_VAR_OPTION
} from './ingredientVariationOptions.actions';

const defaultState = {
	ui: {},
	data: {
		ingredientVariationOptRow: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_INGREDIENT_VAR_OPTION: {
		return {
			...state,
			data: {
				...state.data,
				ingredientVariationOptRow: payload
			}
		};
	}

	case UPDATE_INGREDIENT_VAR_OPTION: {
		return {
			...state,
			data: {
				...state.data,
				ingredientVariationOptRow: {
					...state.data.ingredientVariationOptRow,
					ingredient: payload.ingredient,
					sort_order: payload.sort_order,
					ingredient_amount: payload.ingredient_amount
				}
			}
		};
	}

	case RESET_INGREDIENT_VAR_OPTION: {
		return {
			...state,
			data: {
				...state.data,
				ingredientVariationOptRow: {}
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
