// lodash
import _get from 'lodash/get';

// enums
import enums from './../posConfigurationProductVariantsPricing.enums';

/**
 * @function getFilter
 * @param {Object} object - contains posConfigurationsVersions and productVariant
 * @description returns a filter to get all existing product variant pricings to display
 * above the table in last page
 */
export function getFilter({ posConfigurationsVersions, productVariants }) {
	// get pos config version ids
	const posConfigurationVersionIds = posConfigurationsVersions.reduce(
		(acc, currentValue, index) => {
			const id = _get(currentValue, 'value.id', null);

			acc =
				index === posConfigurationsVersions.length - 1
					? `${acc}'${id}']`
					: `${acc}'${id}',`;

			return acc;
		},
		'['
	);

	// get product variant ids
	const productVariantIds = productVariants.reduce(
		(acc, currentValue, index) => {
			const id = _get(currentValue, 'value.id', null);

			acc =
				index === productVariants.length - 1
					? `${acc}'${id}']`
					: `${acc}'${id}',`;

			return acc;
		},
		'['
	);

	return `:pos_configuration_version.id=IN=${posConfigurationVersionIds};:product_variant.id=IN=${productVariantIds}`;
}

/**
 * @function markValuesWithCanAdd
 * @param {Object} object - contains chosen values (listArray) and existing product variant pricings
 * @description loops through existing values and if some of our chosen combinations already exist
 * it marks it with canAdd: false to avoid adding
 */
export function markValuesWithCanAdd({ listArray, data }) {
	return listArray.map((value) => {
		const posConfigurationVersionId = _get(
			value,
			'posConfigurationVersion.value.id',
			null
		);
		const productVariantId = _get(value, 'productVariant.value.id', null);

		// try to find a product variant pricing with the same ids
		const existingProductVariantPricing = data.some(
			(entry) =>
				entry.pos_configuration_version.id === posConfigurationVersionId &&
				entry.product_variant.id === productVariantId
		);

		return {
			...value,
			canAdd: !existingProductVariantPricing,
		};
	});
}

export function calculatePercentagePrices({
	toStayPrice,
	toGoPrice,
	batchFormData,
}) {
	// apply discount rates to prices based on percentage
	let toStayPriceNew =
		toStayPrice * (batchFormData.toStayPricePercentage / 100);
	let toGoPriceNew = toGoPrice * (batchFormData.toGoPricePercentage / 100);

	// round up prices
	switch (_get(batchFormData, 'roundToNearest.value', '1')) {
		case '1':
			toStayPriceNew = Math.round(toStayPriceNew / 1) * 1;
			toGoPriceNew = Math.round(toGoPriceNew / 1) * 1;
			break;
		case '0.5':
			toStayPriceNew = Math.round(toStayPriceNew / 0.5) * 0.5;
			toGoPriceNew = Math.round(toGoPriceNew / 0.5) * 0.5;
			break;
		case '0.25':
			toStayPriceNew = Math.round(toStayPriceNew / 0.25) * 0.25;
			toGoPriceNew = Math.round(toGoPriceNew / 0.25) * 0.25;
			break;
		case '0.1':
			toStayPriceNew = Number(
				(Math.round(toStayPriceNew / 0.1) * 0.1).toFixed(2)
			);
			toGoPriceNew = Number((Math.round(toGoPriceNew / 0.1) * 0.1).toFixed(2));
			break;
		case '0.05':
			toStayPriceNew = Number(
				(Math.round(toStayPriceNew * 20) / 20).toFixed(2)
			);
			toGoPriceNew = Number((Math.round(toGoPriceNew * 20) / 20).toFixed(2));
			break;
		case '0.01':
			toStayPriceNew = Number(
				(Math.round(toStayPriceNew / 0.01) * 0.01).toFixed(3)
			);
			toGoPriceNew = Number(
				(Math.round(toGoPriceNew / 0.01) * 0.01).toFixed(3)
			);
			break;
		default:
			break;
	}

	toStayPriceNew = toStayPriceNew.toString();
	toGoPriceNew = toGoPriceNew.toString();

	return {
		toStayPriceNew,
		toGoPriceNew,
	};
}

export function calculateNominalPrices({
	toStayPrice,
	toGoPrice,
	batchFormData,
}) {
	// apply discount rates to prices based on percentage
	let toStayPriceNew = toStayPrice + parseFloat(batchFormData.toStayPrice);
	let toGoPriceNew = toGoPrice + parseFloat(batchFormData.toGoPrice);

	toStayPriceNew = toStayPriceNew.toString();
	toGoPriceNew = toGoPriceNew.toString();

	return { toStayPriceNew, toGoPriceNew };
}

export function getAdjustmentTypeOptions() {
	return [
		{
			value: enums.NOMINAL,
			label: enums.NOMINAL,
		},
		{
			value: enums.PERCENTAGE,
			label: enums.PERCENTAGE,
		},
	];
}

export function getRoundToNearestOptions() {
	return [
		{
			value: '0.01',
			label: '0.01',
		},
		{
			value: '0.05',
			label: '0.05',
		},
		{
			value: '0.1',
			label: '0.1',
		},
		{
			value: '0.25',
			label: '0.25',
		},
		{
			value: '0.5',
			label: '0.5',
		},
		{
			value: '1',
			label: '1',
		},
	];
}

export function getReduxKey(reduxKey, productVariant) {
	if (productVariant) return `${reduxKey}-${productVariant.id}`;

	return reduxKey;
}
