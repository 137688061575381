'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';
var __endpoints = require('endpoints.service');

export default class CompanyCompanyVatNumbersList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'vat_number',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'start_date',
				type: 'date',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'end_date',
				type: 'date',
				canAdd: true,
				canEdit: true,
			},
		];
		this.settings = {
			name: 'CompanyCompanyVatNumbersList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			canCollapse: true,
			canManipulate: () => validAccess(undefined, ['Organisation/Companies::Edit', 'Organization Admin']),

			alwaysRefresh: true,
			translationPath: 'ADMINISTRATION.COMPANY_VAT_NUMBERS',
			endpoint: __endpoints.collection.administration.company_vat_numbers,
		};
		this.init();
		return this;
	}
}
