export default {
	TITLE: 'Product Variants',
	FILTER_PRODUCT_VARIANT_ADDED: 'Filter product variant added!',
	FILTER_PRODUCT_VARIANT_EDITED: 'Filter Product Variant edited!',
	FILTER_PRODUCT_VARIANTS_EDITED: 'Filter product variants edited!',
	FILTER_PRODUCT_VARIANT_DELETED: 'Filter product variant deleted!',

	// batch form
	MODAL_BATCH_STEP_FORM_TITLE: 'Select product variants',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'Next',
	MODAL_BATCH_STEP_LIST_CANCEL_BUTTON_LABEL: 'Back',
	MODAL_BATCH_STEP_LIST_TITLE: 'Create Filter Product Variants',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_PRODUCT_VARIANT: 'Product variant',
	MODAL_BATCH_STEP_PRODUCT_VARIANT_PLACEHOLDER: 'Select product variant...',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add Filter Product Variants',
};
