'use strict';

export default {
	TITLE_SINGLE: 'Upload file',
	TITLE_MULTI: 'Upload files',
	DROP_FILE: 'Drop file or click to select',
	REMOVE_FILE: 'Remove',
	TOO_MANY_FILES: 'You can only drop one file per type',
	WRONG_EXTENSION: 'Faulty file type - must be ',
	ERROR: 'An error occured - please do try again!',
	ADD_FILETYPE: 'Add filetype to upload queue'
};