'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import { batch } from 'react-redux';
import {
	// batch stuff
	updateBatchForm,
	editBatchListItem,
	removeBatchListItem,

	// regular stuff
	setProductVariantBasketRecommendation,
	basketProductVariantBasketRecommendation,
	resetProductVariantBasketRecommendation,
} from './store/productVariantBasketRecommendations.actions';
// rdw actions
import { editEntry } from 'reactDataWrapper/reactDataWrapper.actions';

// services
import {
	fetchProductVariantBasketRecommendations,
	addBasketRecommendationPriority,
	deleteProductVariantBasketRecommendation,
	addProductVariantBasketRecommendation,
	editProductVariantBasketRecommendation,
	editProductVariantBasketRecommendations,
	editBasketRecommendationPriority,
} from './productVariantBasketRecommendations.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Button, Icon, Loader } from 'dumb';
import AddProductVariantBasketRecommendations from './components/addProductVariantBasketRecommendations/addProductVariantBasketRecommendations.component';

// columns
import { getTableColumns } from './productVariantBasketRecommendations.columns';

// lodash
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';

// utils
import { formatDataForModal } from './utils';

// phrases/enums
import phrases from './productVariantBasketRecommendations.phrases';
import enums from './productVariantBasketRecommendations.enums';

// moment
import moment from 'moment';

// styles
import './productVariantBasketRecommendations.css';

const reduxKey = '/pos/product_variant_basket_recommendations';

class ProductVariantBasketRecommendations extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
			rowLoading: false,
			legacyFilterOn: true,
		};

		this.toggleModal = this.toggleModal.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.addProductVariantBasketRecommendationWrapper = this.addProductVariantBasketRecommendationWrapper.bind(
			this
		);
		this.editProductVariantBasketRecommendationWrapper = this.editProductVariantBasketRecommendationWrapper.bind(
			this
		);
		this.fetchData = this.fetchData.bind(this);
		this.getActionRender = this.getActionRender.bind(this);
		this.openAddModalWrapper = this.openAddModalWrapper.bind(this);
		this.editRow = this.editRow.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);
		this.reRenderTable = this.reRenderTable.bind(this);

		this.columns = getTableColumns({
			editRow: this.editRow,
			rowLoading: this.state.rowLoading,
		});
	}

	/**
	 * @function editRow
	 * @description Custom solution that allows the user to edit basket recommendation priorities directly
	 * from the row
	 */
	editRow({ name, value, basketRecommendationPriorityId, type }) {
		const { listData, editEntry } = this.props;

		const payload = {
			id: basketRecommendationPriorityId,
			[name]: value,
		};

		this.setState(() => ({
			rowLoading: true,
		}));

		editBasketRecommendationPriority(payload)
			.then((res) => {
				// find the entry in question
				// find all entries with the same recommendations/exlusions and replace them
				const priorityRows = (listData.data?.listData ?? []).filter((entry) => {
					if (
						type === enums.RECOMMENDATION &&
						entry.recommendation_priority?.id === res.data[0].id
					)
						return true;
					if (type === enums.EXCLUSION && entry.exclude?.id === res.data[0].id)
						return true;
				});

				batch(() => {
					priorityRows.map((entry) => {
						const updatedRow = {
							...entry,
							...(type === enums.RECOMMENDATION
								? {
										recommendation_priority: res.data[0],
								  }
								: {
										exclude: res.data[0],
								  }),
						};

						editEntry({ reduxKey, entry: updatedRow });
					});
				});
			})
			.finally(() => this.setState(() => ({ rowLoading: false })));
	}

	fetchData(state) {
		const { legacyFilterOn } = this.state;

		return fetchProductVariantBasketRecommendations(state, legacyFilterOn);
	}

	toggleModal() {
		this.setState(
			(prevState) => ({ showModal: !prevState.showModal }),
			() => {
				if (!this.state.showModal)
					this.props.resetProductVariantBasketRecommendation();
			}
		);
	}

	addProductVariantBasketRecommendationWrapper(data) {
		return addProductVariantBasketRecommendation(data).then(this.reRenderTable);
	}

	editProductVariantBasketRecommendationWrapper(data) {
		return editProductVariantBasketRecommendation(data).then(
			this.reRenderTable
		);
	}

	reRenderTable() {
		this.setState(() => ({
			key: moment.utc(),
		}));
	}

	openAddModalWrapper(rowData) {
		const { updateBatchForm } = this.props;

		if (_isArray(rowData) && rowData.length === 1) {
			const payload = formatDataForModal(rowData[0]);

			updateBatchForm(payload);
		}
		if (_isArray(rowData) && rowData.length > 1) {
			const payload = {
				productVariants: rowData.map((entry) => {
					return formatDataForModal(entry);
				}),
			};

			updateBatchForm(payload);
		}

		this.toggleModal();
	}

	deleteEntry(selectedRow) {
		const { editEntry } = this.props;

		if (!selectedRow.productVariantBasketRecommendationId) return;

		this.setState(() => ({ rowLoading: true }));

		deleteProductVariantBasketRecommendation(
			selectedRow.productVariantBasketRecommendationId
		)
			.then(() => {
				const row = {
					id: selectedRow.id,
					product_variant: selectedRow.product_variant,
				};

				editEntry({ reduxKey, entry: row });
			})
			.finally(() => this.setState(() => ({ rowLoading: false })));
	}

	getActionRender() {
		const { listData } = this.props;

		const selectedRows = _get(listData, 'ui.selectedRows', []);

		if (_isEmpty(selectedRows)) return;

		return (
			<>
				<Button
					type="inverted"
					label="Edit"
					shadow
					onClick={() => this.openAddModalWrapper(selectedRows)}>
					<Icon name="edit" />
				</Button>
				<Button
					type="inverted"
					label="Delete"
					shadow
					onClick={() => this.deleteEntry(selectedRows[0])}>
					<Icon name="delete" />
				</Button>
			</>
		);
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}>
				Legacy
			</Button>
		);
	}

	render() {
		const {
			batchListData,
			batchFormData,
			editBatchListItem,
			updateBatchForm,
			removeBatchListItem,
		} = this.props;

		return (
			<>
				<div className="product-variant-basket-recommendation__table-wrapper">
					{this.state.rowLoading && (
						<div className="product-variant-basket-recommendation__table-wrapper__loader">
							<Loader loading />
						</div>
					)}
					<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
						key={this.state.key}
						title={phrases.TITLE}
						columns={this.columns}
						fetchData={this.fetchData}
						filterable
						defaultPageSize={10}
						reduxKey={reduxKey}
						manual
						setInitialEditValues={this.setInitialEditValues}
						onModalClose={this.props.resetProductVariantBasketRecommendation}
						actionRender={this.getActionRender()}
						customAreaComponents={this.getLegacyFilterButton()}
						extraFilters={this.state.legacyFilterOn ? `:legacy=='false'` : ''}
					/>
				</div>

				<AddProductVariantBasketRecommendations
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchListData={batchListData}
					batchFormData={batchFormData}
					updateBatchForm={updateBatchForm}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addProductVariantBasketRecommendationWrapper={
						this.addProductVariantBasketRecommendationWrapper
					}
					editProductVariantBasketRecommendationWrapper={
						this.editProductVariantBasketRecommendationWrapper
					}
					addBasketRecommendationPriority={addBasketRecommendationPriority}
					addProductVariantBasketRecommendation={
						addProductVariantBasketRecommendation
					}
					editProductVariantBasketRecommendations={
						editProductVariantBasketRecommendations
					}
					reRenderTable={this.reRenderTable}
				/>
			</>
		);
	}
}

ProductVariantBasketRecommendations.propTypes = {
	resetProductVariantBasketRecommendation: PropTypes.func,
	batchListData: PropTypes.array,
	listData: PropTypes.object,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	editEntry: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			editBatchListItem,
			removeBatchListItem,
			basketProductVariantBasketRecommendation,
			setProductVariantBasketRecommendation,
			resetProductVariantBasketRecommendation,
			editEntry,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultProductVariantBasketRecommendations:
			store.productVariantBasketRecommendations.data
				.defaultProductVariantBasketRecommendations,
		batchListData: store.productVariantBasketRecommendations.data.batchListData,
		batchFormData: store.productVariantBasketRecommendations.data.batchFormData,
		listData: store.listData[reduxKey],
	};
};

export default connectWithStore(
	ProductVariantBasketRecommendations,
	mapStateToProps,
	mapDispatchToProps
);
