'use strict';

import PropTypes from 'prop-types';
import cx from 'classnames';
import React from 'react';

import RowEntry from './row.entry.component';

const BodyCustomComponent = props => {
	const { component, tables, table } = props;

	/**
	 * Parses body from table content and layout object
	 */
	const parseBody = table.content.map((row, key) => {
		let uniqueRowKey = `${row.id}_Entry_${key}`;
		let uniqueTableKey = `${row.id}_Table_${key}`;

		// Define totalColumns
		let totalColumns;

		// Switch on children type to get total number of columns (groups or columns)
		switch (component.getChildrenType()) {
		case 'group':
			totalColumns = component.content.reduce(
				(acc, value) => acc + value.getLength(),
				0
			);
			break;
		case 'column':
			totalColumns = component.content.length;
			break;
		}

		return [
			<tr key={uniqueTableKey} className={'report-old__custom-component'}>
				<td colSpan={totalColumns}>
					{component.customComponents.map((c, cKey) => {
						return <c.Component key={cKey} {...props} row={row} />;
					})}
				</td>
			</tr>,
			<RowEntry
				key={uniqueRowKey}
				tables={tables}
				context="body"
				data={row.total.data}
				meta={row}
				component={component}
				modifierClassNames={cx({ 'report-old__entry--odd': key % 2 === 0 })}
			/>
		];
	});

	return <tbody>{parseBody}</tbody>;
};

BodyCustomComponent.propTypes = {
	expandedTables: PropTypes.object.isRequired,
	report: PropTypes.object.isRequired,
	table: PropTypes.object.isRequired,
	tables: PropTypes.object.isRequired
};

export default BodyCustomComponent;
