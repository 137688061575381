
export const SET_ACCESS_RIGHTS = 'GOLDMODEL/SET_ACCESS_RIGHTS';
export const SET_COLLECTION = 'GOLDMODEL/SET_COLLECTION';
export const SET_DATERANGE = 'GOLDMODEL/SET_DATERANGE';
export const SET_HOTBAR_FILTER = 'GOLDMODEL/SET_HOTBAR_FILTER';
export const SET_MATRICE = 'GOLDMODEL/SET_MATRICE';
export const UPDATE_WORKPLACE = 'GOLDMODEL/UPDATE_WORKPLACE';
export const SET_SHOW_MATRICE = 'GOLDMODEL/SET_SHOW_MATRICE';
export const SET_PERIOD_TYPE = 'GOLDMODEL/SET_PERIOD_TYPE';

/**
 * @param {array} collection
 */
export function setCollection(collection = {}) {
	return {
		type: SET_COLLECTION,
		payload: collection
	};
}

/**
 * @param {object} dateRange
 */
export function setDateRange(dateRange = {}) {
	return {
		type: SET_DATERANGE,
		payload: dateRange
	};
}

/**
 * @param {array} filter
 */
export function setHotBarFilter(filter = {}) {

	return {
		type: SET_HOTBAR_FILTER,
		payload: filter
	};
}

/**
 * @param {object} matrice
 */
export function setMatrice(matrice = {}) {
	return {
		type: SET_MATRICE,
		payload: matrice
	};
}

/**
 * @param {object} matrice
 */
export function updateWorkplace(tags = [], workplaceId = null) {
	return {
		type: UPDATE_WORKPLACE,
		payload: {
			tags,
			workplaceId
		}
	};
}

/**
 * @param {boolean} showMatrice
 */
export function setShowMatrice(showMatrice = false) {
	return {
		type: SET_SHOW_MATRICE,
		payload: showMatrice
	};
}

/**
 * @param {string} periodType
 */
export function setPeriodType(periodType = {}) {
	return {
		type: SET_PERIOD_TYPE,
		payload: periodType
	};
}