import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { LineHeader, Collapsible } from 'dumb';

import './sectionCollapsable.css';

export default class SectionCollapsable extends PureComponent {
	constructor() {
		super();

		this._toggleSection = this._toggleSection.bind(this);
	}

	_toggleSection() {
		if (!this.props.disabled && this.props.onToggle) {
			this.props.onToggle();
		}
	}

	render() {
		const {
			children,
			className,
			isOpen,
			title,
			disabled,
			onToggle
		} = this.props;

		const classN = classnames('j-section-collapsable', {
			[`${className}`]: className,
			[`j-section-collapsable--disabled`]: disabled,
			[`j-section-collapsable--is-open`]: isOpen
		});

		return (
			<section className={classN}>
				<header
					onClick={() => this._toggleSection()}
					className="j-section-collapsable__header">
					<LineHeader className="j-section-collapsable__header__title">
						{title}
					</LineHeader>
					{onToggle && (
						<span className="j-section-collapsable__close icon icon--chevron_left" />
					)}
				</header>

				<Collapsible
					open={isOpen}
					easing={'ease-out'}
					classParentString="j-section-collapsable__collapsible">
					<div className="j-section-collapsable__content">{children}</div>
				</Collapsible>
			</section>
		);
	}
}

// isOpen used to indicate initial state of open/close
SectionCollapsable.defaultProps = {
	isOpen: true
};

SectionCollapsable.propTypes = {
	// shared props
	title: PropTypes.string,
	isOpen: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.any.isRequired,
	onToggle: PropTypes.func
};
