'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'detectMobile.vanilla';

import { Button, Input, ButtonGroup, InputCollectionSelect, InputCleave, SingleDatePickerInput } from 'dumb';
import { ModalRow } from 'dumb/modal';

import _get from 'lodash/get';
import { enums } from '../../../salaryComponents.enums';
import moment from 'moment';

class BatchStepForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};
	}

	editStoreEntry(e, type) {
		const { batchOptions } = this.props;

		const value = e?.target?.value ?? e;

		this.props.updateBatchForm({
			...batchOptions,
			...{ [type]: value },
		});
	}

	editStoreDatesEntry(e, type) {
		const { batchOptions } = this.props;

		const value = e?.target?.value ?? e;

		const date = moment.isMoment(value) ? value.format('YYYY-MM-DD') : value;

		const updateObject = {
			global_salary_component: batchOptions.global_salary_component,
			salary_group: batchOptions.salary_group,
			name: batchOptions.name,
			amount_type: batchOptions.amount_type,
			amount: batchOptions.amount,
			frequency: batchOptions.frequency,
			active: batchOptions.active,
			external_export_id: batchOptions.external_export_id,
		};

		const data =
			type === 'from'
				? {
						...updateObject,
						...{
							active: {
								from: date,
								to: _get(batchOptions, 'active.to', '9999-12-31'),
							},
						},
				  }
				: {
						...updateObject,
						...{
							active: {
								from: _get(batchOptions, 'active.from', ''),
								to: date,
							},
						},
				  };

		this.props.updateBatchForm(data);
	}

	render() {
		const { batchOptions, toggleOverlay, salaryGroupId } = this.props;

		return (
			<>
				<ModalRow>
					<Input
						label="Name"
						placeholder="Name"
						id="name"
						value={_get(batchOptions, 'name', '')}
						onChange={(event) => this.editStoreEntry(event, 'name')}
					/>
				</ModalRow>

				<ModalRow>
					{!salaryGroupId && (
						<ButtonGroup type="full-width">
							<InputCollectionSelect
								className="modal-batch-select"
								id="salary_groups"
								label="Salary Groups"
								placeholder="Select salary groups"
								value={batchOptions.salary_group}
								handleChange={(key, value) => this.editStoreEntry(value, 'salary_group')}
								multi
								cache
								apiPath="/salary/salary_groups"
								params={{
									limit: 50,
								}}
								optionFormat={(entry) => ({
									value: entry,
									label: entry.name,
								})}
								inputFilterFormat={(input) => `:name=like='%${input}%'`}
							/>

							<Button type="inverted" shadow size="large" onClick={() => toggleOverlay('salary_group')}>
								<span className="icon icon--more" />
							</Button>
						</ButtonGroup>
					)}
				</ModalRow>

				<ModalRow>
					<ButtonGroup type="full-width">
						<InputCollectionSelect
							className="modal-batch-select"
							id="ingprice-col"
							label="Global Salary Component"
							placeholder="Select salary component"
							value={batchOptions.global_salary_component}
							handleChange={(key, value) => this.editStoreEntry(value, 'global_salary_component')}
							multi
							cache
							apiPath="/salary/global_salary_components"
							params={{
								limit: 50,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>

						<Button type="inverted" shadow size="large" onClick={() => toggleOverlay('global_salary_component')}>
							<span className="icon icon--more" />
						</Button>
					</ButtonGroup>
				</ModalRow>

				<ModalRow>
					<Input
						label="Amount type"
						type="select"
						id="amount-type"
						options={enums.AMOUNT_TYPE.map((item, i) => ({
							value: item,
							label: item,
						}))}
						onChange={(event) => this.editStoreEntry(event, 'amount_type')}
						value={_get(batchOptions, 'amount_type', enums.AMOUNT_TYPE[0])}
					/>
				</ModalRow>

				<ModalRow>
					<InputCleave
						label="Amount"
						id="amount"
						placeholder="amount"
						type="number"
						min={0}
						// toit new code nullish-coalescing-operator
						value={batchOptions.value ?? undefined}
						onChange={(event) => this.editStoreEntry(event, 'amount')}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						label="Frequency"
						type="select"
						id="frequency"
						options={enums.FREQUENCY.map((item, i) => ({
							value: item,
							label: item,
						}))}
						onChange={(event) => this.editStoreEntry(event, 'frequency')}
						value={_get(batchOptions, 'frequency', enums.FREQUENCY[0])}
					/>
				</ModalRow>

				<ModalRow>
					<SingleDatePickerInput
						id="active-from"
						label="Active from"
						numberOfMonths={isMobile() ? 1 : 2}
						selectedDate={batchOptions?.active?.from ?? ''}
						onChange={(event) => this.editStoreDatesEntry(event, 'from')}
					/>
				</ModalRow>

				<ModalRow>
					<SingleDatePickerInput
						id="active-to"
						label="Active to"
						numberOfMonths={isMobile() ? 1 : 2}
						selectedDate={batchOptions?.active?.to ?? ''}
						onChange={(event) => this.editStoreDatesEntry(event, 'to')}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						id="external_export_id"
						placeholder="external export id"
						label="External export id"
						value={_get(batchOptions, 'external_export_id', '')}
						onChange={(event) => this.editStoreEntry(event, 'external_export_id')}
					/>
				</ModalRow>
			</>
		);
	}
}

BatchStepForm.propTypes = {
	salaryGroupId: PropTypes.number,
	batchOptions: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
};

BatchStepForm.defaultProps = {};

export default BatchStepForm;
