export default {
	BACK: 'Back',
	NEXT: 'Next',
	APPROVED: 'Approved',
	DECLINED: 'Declined',
	FINISH: 'Finish',
	PREVIEW_STEP_TITLE: 'Verify deviations',
	PREVIEW_STEP_TIP: 'Verify that all deviations are correct',
	DEVIATION_TO_APPROVE: 'Clockin deviation',

	// page modal step
	NAME: 'Name',
	WORKPLACE: 'Workplace',
	SHIFT_TIME: 'Shift time',
	DEVIATION_REASON: 'Deviation reason',
	DEVIAITON_DESCRIPTION: 'Deviation description',
	EVENT: 'Event',
};
