'use strict';

import _map from 'lodash/map';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Cell from 'reusableComponents/list/cell.component';

class Row extends Component {
	constructor(props) {
		super(props);

		this.onKeyDownRow = this.onKeyDownRow.bind(this);
		this.onClickRow = this.onClickRow.bind(this);
	}

	onKeyDownRow(item, key, event) {
		const keyCode = event.nativeEvent.keyCode;
		switch (keyCode) {
		case 13:
			this.props.handleSelect(item);
			break;
		case 38: {
			event.preventDefault();
			this.refs[`row-${key - 1}`].focus();
			break;
		}
		case 40: {
			event.preventDefault();
			this.refs[`row-${key + 1}`].focus();
			break;
		}
		}
	}

	onClickRow(item) {
		this.props.handleSelect(item);
	}

	renderCells(row) {
		const { methods, translations } = this.props;

		return _map(methods.handleReturnVisibleHeaders(), (cell, key) => (
			<Cell
				key={key}
				cell={cell}
				row={row}
				methods={methods}
				translations={translations}
			/>
		));
	}

	renderRows() {
		const { collection } = this.props;

		return _map(collection, (row, key) => {
			return (
				<tr
					onKeyDown={this.onKeyDownRow.bind(this, row, key)}
					ref={`row-${key}`}
					onClick={this.onClickRow.bind(this, row)}
					key={key}
					className="list__row"
					tabIndex={30 + key}>
					{this.renderCells(row)}
				</tr>
			);
		});
	}

	render() {
		return <tbody>{this.renderRows()}</tbody>;
	}
}

Row.proptypes = {
	collection: PropTypes.array.isRequired,
	methods: PropTypes.object.isRequired,
	translations: PropTypes.object.isRequired
};

export default Row;
