import React from 'react';

// components
import { Tip } from 'dumb/tips';

// phrases
import phrases from './../../../marketTags.phrases';

type Props = {
	page: 'one' | 'two';
};

const MarketTagsModalTip = ({ page }: Props): JSX.Element => {
	return (
		<Tip
			content={
				page === 'one'
					? phrases.MODAL_BATCH_TIP_ONE
					: phrases.MODAL_BATCH_TIP_TWO
			}
		/>
	);
};

export default MarketTagsModalTip;
