import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';

import SizesList from './services/sizesList.service.js';

const sizesListReduxKey = '/product/sizes';
const sizesList = new SizesList(sizesListReduxKey);
const sizesListProps = sizesList.getProps();

export function Sizes() {
	useEffect(() => {
		sizesList.initView();
	}, []);

	return <ListComponent reduxKey={sizesListReduxKey} {...sizesListProps} />;
}
