'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	updateBatchForm,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setMarketOvertimeRules,
	updateMarketOvertimeRules,
	resetState,
} from './store/marketOvertimeRules.actions';

// services
import {
	fetchMarketOvertimeRules,
	editMarketOvertimeRule,
	editMarketOvertimeRules,
	addMarketOvertimeRule,
	deleteMarketOvertimeRule,
} from './marketOvertimeRules.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { DateFilterSelector } from 'reactDataWrapper/utilities';
import { Input, Button, Icon, InputCollectionSelect, InputCleave } from 'dumb';
import { types, units } from './marketOvertimeRules.enums';
import MarketOvertimeRulesModalBatch from './components/batch/marketOvertimeRulesModalBatch';

// Tools
import _get from 'lodash/get';

// phrases/constants
import phrases from './marketOvertimeRules.phrases';

const reduxKey = '/salary/market_overtime_rules';

class MarketOvertimeRules extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.addMarketOvertimeRulesWrapper = this.addMarketOvertimeRulesWrapper.bind(
			this
		);
		this.fetchData = this.fetchData.bind(this);
		this.editEntry = this.editEntry.bind(this);

		this.columns = [
			{
				Header: 'Market',
				id: 'name',
				accessor: 'market.name',
				filterPath: ':market.name',
			},
			{
				Header: 'Type',
				id: 'type',
				accessor: 'type',
				filterPath: ':type',
			},
			{
				Header: 'Unit',
				id: 'unit',
				accessor: 'unit',
				filterPath: ':unit',
			},
			{
				Header: 'Amount',
				id: 'amount',
				accessor: 'amount',
				filterPath: ':amount',
			},
			{
				Header: 'Secondd unit',
				id: 'second_unit',
				accessor: 'second_unit',
				filterPath: ':second_unit',
			},
			{
				Header: 'Second amount',
				id: 'second_amount',
				accessor: 'second_amount',
				filterPath: ':second_amount',
			},
			{
				Header: 'Active from',
				id: 'activeFrom',
				width: 100,
				accessor: (d) => _get(d, 'active.from', ''),
				filterPath: ':active.from',
				Filter: ({ column }) => (
					<DateFilterSelector
						reduxKey={reduxKey}
						columnId={column.id}
						dateIdentifier="from"
					/>
				),
			},
			{
				Header: 'Active to',
				id: 'activeTo',
				accessor: (d) => _get(d, 'active.to', ''),
				width: 100,
				filterPath: ':active.to',
				Filter: ({ column }) => (
					<DateFilterSelector
						reduxKey={reduxKey}
						columnId={column.id}
						dateIdentifier="to"
					/>
				),
			},
		];
	}

	getObjectPayload(marketOvertimeRule) {
		return {
			...(marketOvertimeRule?.market?.value && {
				market: marketOvertimeRule.market.value,
			}),
			...(marketOvertimeRule?.second_unit?.value && {
				second_unit: marketOvertimeRule.second_unit.value,
			}),
			...(marketOvertimeRule?.type?.value && {
				type: marketOvertimeRule.type.value,
			}),
			...(marketOvertimeRule?.unit?.value && {
				unit: marketOvertimeRule.unit.value,
			}),

			...(marketOvertimeRule?.amount && {
				amount: marketOvertimeRule.amount,
			}),

			...(marketOvertimeRule?.second_amount && {
				second_amount: marketOvertimeRule.second_amount,
			}),

			...(marketOvertimeRule?.from && {
				active: {
					from: marketOvertimeRule?.from,
					...(marketOvertimeRule?.to && {
						to: marketOvertimeRule.to,
					}),
				},
			}),
		};
	}

	editEntry() {
		const { marketOvertimeRule } = this.props;

		const payload = {
			id: marketOvertimeRule.id,
			...this.getObjectPayload(marketOvertimeRule),
		};

		return editMarketOvertimeRule(payload);
	}

	editMultiple(selectedRows) {
		const { marketOvertimeRule } = this.props;

		const payload = this.getObjectPayload(marketOvertimeRule);

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editMarketOvertimeRules({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteMarketOvertimeRule(id);
	}

	setInitialEditValues(data) {
		const payload = {
			...data,
			...(data?.type && {
				type: {
					label: data.type,
					value: data.type,
				},
			}),
			...(data?.unit && {
				unit: {
					label: data.unit,
					value: data.unit,
				},
			}),
			...(data?.second_unit && {
				second_unit: {
					label: data.second_unit,
					value: data.second_unit,
				},
			}),
			market: {
				value: data.market.id,
				label: data.market.name,
			},
		};

		this.props.setMarketOvertimeRules(payload);
	}

	getEditableCells() {
		const { marketOvertimeRule } = this.props;

		return [
			{
				header: 'Market',
				value: (
					<InputCollectionSelect
						id="market"
						label="Market"
						placeholder={phrases.MARKET}
						clearable={false}
						value={marketOvertimeRule?.market || null}
						onChange={(e) => this.editStoreEntry('market', e)}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						apiPath="/organization/markets"
					/>
				),
			},
			{
				header: 'Type',
				value: (
					<InputCollectionSelect
						placeholder="Select type..."
						id="type"
						label="type"
						clearable={false}
						options={types.map((item, i) => ({
							value: item,
							label: item,
						}))}
						onChange={(e) => this.editStoreEntry('type', e)}
						value={_get(marketOvertimeRule, 'type', null)}
					/>
				),
			},

			{
				header: 'Amount',
				value: (
					<Input
						id="amount"
						type="number"
						placeholder="Enter amount..."
						value={marketOvertimeRule?.amount ?? ''}
						onChange={(event) => this.editStoreEntry('amount', event)}
					/>
				),
			},

			{
				header: 'Unit',
				value: (
					<InputCollectionSelect
						placeholder="Select unit..."
						id="units"
						clearable={false}
						options={units.map((item, i) => ({
							value: item,
							label: item,
						}))}
						onChange={(event) => this.editStoreEntry('unit', event)}
						value={_get(marketOvertimeRule, 'unit', null)}
					/>
				),
			},

			{
				header: 'Second amount',
				value: (
					<Input
						id="second_amount"
						type="number"
						placeholder="Add second amount..."
						value={marketOvertimeRule?.second_amount ?? ''}
						onChange={(event) => this.editStoreEntry('second_amount', event)}
					/>
				),
			},

			{
				header: 'Second unit',
				value: (
					<InputCollectionSelect
						placeholder="Select secondary unit..."
						id="second_unit"
						clearable={false}
						options={units.map((item, i) => ({
							value: item,
							label: item,
						}))}
						onChange={(event) => this.editStoreEntry('second_unit', event)}
						value={_get(marketOvertimeRule, 'second_unit', null)}
					/>
				),
			},

			{
				header: 'From',
				value: (
					<InputCleave
						id="active-from"
						placeholder="yyyy-mm-dd"
						options={{
							date: true,
							delimiter: '-',
							datePattern: ['Y', 'm', 'd'],
						}}
						value={_get(marketOvertimeRule, 'active.from', '')}
						onChange={(event) => this.editStoreDateEntry('from', event)}
					/>
				),
			},
			{
				header: 'To',
				value: (
					<InputCleave
						id="active-to"
						placeholder="yyyy-mm-dd"
						options={{
							date: true,
							delimiter: '-',
							datePattern: ['Y', 'm', 'd'],
						}}
						value={_get(marketOvertimeRule, 'active.to', '9999-12-31')}
						onChange={(event) => this.editStoreDateEntry('to', event)}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const value = e?.target?.value ?? e;

		const updateObject = {
			[name]: value,
		};

		this.props.updateMarketOvertimeRules(updateObject);
	}

	editStoreDateEntry(name, e) {
		const { marketOvertimeRule } = this.props;
		const value = e.target ? e.target.value : e;

		const updateObject = {
			[name]: value,
		};

		const active =
			name === 'from'
				? {
						from: value,
						to: _get(marketOvertimeRule, 'active.to', '9999-12-31'),
				  }
				: {
						from: _get(marketOvertimeRule, 'active.from', ''),
						to: value,
				  };

		const data = {
			...updateObject,
			...{ active },
		};

		this.props.updateMarketOvertimeRules(data);
	}

	fetchData(state) {
		return fetchMarketOvertimeRules(state);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	addMarketOvertimeRulesWrapper(data) {
		return addMarketOvertimeRule(data).then(() =>
			this.setState(() => ({
				key: moment.utc(),
			}))
		);
	}

	render() {
		const {
			batchList,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={reduxKey}
					manual
					editableCells={this.getEditableCells()}
					accessAreasAllowedToEdit={['Global HR Configuration']}
					editEntry={this.editEntry}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					actionRender={
						<Button
							type="inverted"
							label="Batch"
							shadow
							onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
				/>

				<MarketOvertimeRulesModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addMarketOvertimeRule={this.addMarketOvertimeRulesWrapper}
				/>
			</>
		);
	}
}

MarketOvertimeRules.propTypes = {
	marketOvertimeRule: PropTypes.object,

	updateMarketOvertimeRules: PropTypes.func,
	setMarketOvertimeRules: PropTypes.func,
	resetState: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			editBatchListItem,
			removeBatchListItem,
			updateMarketOvertimeRules,
			setMarketOvertimeRules,
			resetState,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		marketOvertimeRule:
			store.globalAdministrationMarketOvertimeRules.data.marketOvertimeRule,
		batchList: store.globalAdministrationMarketOvertimeRules.data.batchList,
	};
};

export default connectWithStore(
	MarketOvertimeRules,
	mapStateToProps,
	mapDispatchToProps
);
