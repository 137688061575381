import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './waitingTimeSchedules.phrases';

/**
 * @function fetchWaitingTimeSchedules
 * @description Fetch waiting time schedules
 * @param {object} state
 */
export function fetchWaitingTimeSchedules(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/app/waiting_time_schedules', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addWaitingTimeSchedule
 * @description add waiting time schedule
 */
export function addWaitingTimeSchedule(data) {
	return post('/app/waiting_time_schedules', data)
		.then((res) => {
			setFeedback(phrases.WAITING_TIME_SCHEDULE_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editWaitingTimeSchedule
 * @description edit waiting time schedule
 */
export function editWaitingTimeSchedule(data) {
	return post(`/app/waiting_time_schedules/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.WAITING_TIME_SCHEDULE_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editWaitingTimeSchedules
 * @description edit waiting time schedules
 */
export function editWaitingTimeSchedules(data) {
	return patch('/app/waiting_time_schedules', data)
		.then((res) => {
			setFeedback(phrases.WAITING_TIME_SCHEDULES_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteWaitingTimeSchedule
 * @description delete filter groups
 */
export function deleteWaitingTimeSchedule(id) {
	return remove(`/app/waiting_time_schedules/${id}`)
		.then((res) => {
			setFeedback(phrases.WAITING_TIME_SCHEDULE_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
