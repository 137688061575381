import React from 'react';
import PropTypes from 'prop-types';

import './report.css';

const report = ({ id, children, width, dataCy }) => {
	return (
		<div id={id} style={{ width }} data-cy={dataCy}>
			<div className="report-wrapper">{children}</div>
		</div>
	);
};

report.defaultProps = {
	id: '',
	dataCy: '',
	width: null,
};

report.propTypes = {
	id: PropTypes.string,
	width: PropTypes.number,
	dataCy: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export default report;
