import {
	SET_ASSET_SUB,
	UPDATE_ASSET_SUB,
	RESET_ASSET_SUB
} from './assetLeftAligned.actions';

const defaultState = {
	data: {
		defaultAssets: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_ASSET_SUB: {
		return {
			...state,
			data: {
				...state.data,
				defaultAssets: payload
			}
		};
	}

	case UPDATE_ASSET_SUB: {
		return {
			...state,
			data: {
				...state.data,
				defaultAssets: {
					...state.data.defaultAssets,
					// asset_collection: payload.asset_collection,
					domain: payload.domain,
					type: payload.type,
					resolution_one: payload.resolution_one,
					resolution_two: payload.resolution_two,
					resolution_three: payload.resolution_three
				}
			}
		};
	}

	case RESET_ASSET_SUB: {
		return {
			...state,
			data: {
				...state.data,
				defaultAssets: {}
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
