import React, { Component } from 'react';
import PropTypes from 'prop-types';

// redux
import { store, connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
// actions
import {
	setModalData,
	updateModalData,
	resetModalData,
} from './store/clockinDeviationApproval.actions';

// components
import { Modal } from 'dumb';
import { Tip } from 'dumb/tips';
import ClockinDeviationApprovalTableList from './components/clockinDeviationApprovalTableList';
import ClockinDeviationApprovalMobileView from './components/mobileView/clockinDeviationApprovalMobileView';

// service
import { editShiftClockinsDeviationApproval } from './clockinDeviationApproval.service';

// phrases/ utils
import phrases from './clockinDeviationApproval.phrases';
import enums from './clockinDeviationApproval.enums';
import { isMobile } from 'detectMobile.vanilla';
import { set as setFeedback } from 'feedback.vanilla.service.js';

// style
import './clockinDeviationApproval.css';

class ClockinDeviationApproval extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		this.isMobile = isMobile();

		this.deviationDeclinedColor = 'ef5350';
		this.deviationApprovedColor = '8AD09E';

		this._toggleModal = this._toggleModal.bind(this);
		this.handleUpdateDeviation = this.handleUpdateDeviation.bind(this);
		this.renderDesktopView = this.renderDesktopView.bind(this);
		this.renderMobileView = this.renderMobileView.bind(this);
		this.checkIfAllDeviationsAddressed = this.checkIfAllDeviationsAddressed.bind(
			this
		);
	}

	checkIfAllDeviationsAddressed() {
		const { data } = this.props;

		const allDeviationsAddressed = data.every((entry) => {
			if (entry.status === enums.CONFIRMED || entry.status === enums.DECLINED)
				return true;
			else return false;
		});

		if (!allDeviationsAddressed)
			setFeedback(phrases.ALL_DEVIATIONS_NEED_TO_BE_ADDRESSED, 0);

		return allDeviationsAddressed;
	}

	_toggleModal() {
		const { resetModalData } = this.props;

		const allDeviationsAddressed = this.checkIfAllDeviationsAddressed();

		if (allDeviationsAddressed) resetModalData();
	}

	handleUpdateDeviation({ deviationId, status }) {
		const { updateModalData } = this.props;

		const data = {
			status,
		};

		return editShiftClockinsDeviationApproval({
			deviationId,
			data,
		}).then((res) => {
			const payload = {
				...res.data[0],
			};

			updateModalData(payload);
		});
	}

	renderDesktopView() {
		const { data } = this.props;

		return (
			<Modal
				header={
					<span className="clockin-deviation-approval-modal__title">
						{phrases.MODAL_TITLE}
					</span>
				}
				isOpen={!!data}
				onClose={this._toggleModal}
				onConfirmClick={this._toggleModal}
				confirmButtonLabel={phrases.CLOSE}>
				<div className="clockin-deviation-approval-modal__tip-wrapper">
					<Tip content={phrases.MODAL_TIP} />
				</div>

				<ClockinDeviationApprovalTableList
					data={data}
					updateDeviation={this.handleUpdateDeviation}
					deviationApprovedColor={this.deviationApprovedColor}
					deviationDeclinedColor={this.deviationDeclinedColor}
				/>
			</Modal>
		);
	}

	renderMobileView() {
		const { data, resetModalData } = this.props;

		return (
			<ClockinDeviationApprovalMobileView
				data={data}
				updateDeviation={this.handleUpdateDeviation}
				resetModalData={resetModalData}
				deviationApprovedColor={this.deviationApprovedColor}
				deviationDeclinedColor={this.deviationDeclinedColor}
				checkIfAllDeviationsAddressed={this.checkIfAllDeviationsAddressed}
			/>
		);
	}

	render() {
		const { showModal } = this.props;

		if (!showModal) return null;

		if (this.isMobile) return this.renderMobileView();
		else return this.renderDesktopView();
	}
}

ClockinDeviationApproval.propTypes = {
	updateModalData: PropTypes.func,
	data: PropTypes.object,
	resetModalData: PropTypes.func,
	showModal: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setModalData,
			updateModalData,
			resetModalData,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		data: store.clockinDeviationApproval.data,
		showModal: store.clockinDeviationApproval.showModal,
	};
};

export default connectWithStore(
	ClockinDeviationApproval,
	mapStateToProps,
	mapDispatchToProps
);
