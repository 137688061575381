'use strict';

import Hotbar from './hotbar';
import DateRange from './hotbar.dateRange';
import Select from './hotbar.select';
import SingleDate from './hotbar.singleDate';
import Separator from './hotbar.separator';
import Number from './hotbar.number';

class HotbarFactory {

	/**
	 * @function hotbar
	 * @memberOf HotbarFactory
	 * @param  {string} title
	 */
	hotbar(title) {
		return new Hotbar(title);
	}

	/**
	 * @function dateRange
	 * @memberOf HotbarFactory
	 * @param  {string} title
	 * @param  {bool} required
	 * @param  {bool} optionalEndDate
	 * @param  {object} defaultStartDate
	 * @param  {object} defaultEndDate
	 */
	dateRange(title, required, optionalEndDate, defaultStartDate, defaultEndDate) {
		return new DateRange(title, required, optionalEndDate, defaultStartDate, defaultEndDate);
	}

	/**
	 * @function select
	 * @memberOf HotbarFactory
	 * @param  {string} title
	 * @param  {bool} required
	 * @param  {object} dataProvider
	 * @param  {bool} multi  multi select
	 */
	select(title, required, dataProvider, multi) {
		return new Select(title, required, dataProvider, multi);
	}

	/**
	 * @function singleDate
	 * @memberOf HotbarFactory
	 * @param  {string} title
	 * @param  {bool} required
	 * @param  {object} defaultDate
	 */
	singleDate(title, required, defaultDate) {
		return new SingleDate(title, required, defaultDate);
	}

	/**
	 * @function number
	 * @memberOf HotbarFactory
	 * @param  {string} title
	 * @param  {bool} required
	 * @param  {string} placeholder
	 */
	number(title, required, placeholder) {
		return new Number(title, required, placeholder);
	}

	/**
	 * @function separator
	 * @memberOf HotbarFactory
	 */
	separator() {
		return new Separator();
	}
}

export default new HotbarFactory();