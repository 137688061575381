export const RESET_STATE = 'GLOBAL_INGREDIENT_COMPONENT_TRANSLATION/RESET_STATE';
export const SET_GLOBAL_INGREDIENT_COMPONENT_TRANSLATION =
	'GLOBAL_INGREDIENT_COMPONENT_TRANSLATION/SET_GLOBAL_INGREDIENT_COMPONENT_TRANSLATION';
export const UPDATE_GLOBAL_INGREDIENT_COMPONENT_TRANSLATION =
	'GLOBAL_INGREDIENT_COMPONENT_TRANSLATION/UPDATE_GLOBAL_INGREDIENT_COMPONENT_TRANSLATION';

export const EDIT_BATCH_LIST_ITEM = 'GLOBAL_INGREDIENT_COMPONENT_TRANSLATION/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM = 'GLOBAL_INGREDIENT_COMPONENT_TRANSLATION/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW = 'GLOBAL_INGREDIENT_COMPONENT_TRANSLATION/ADD_BATCH_LIST_ITEM_ROW';
export const RERENDER_TABLE = 'GLOBAL_INGREDIENT_COMPONENT_TRANSLATION/RERENDER_TABLE';

export function setGlobalIngredientComponentTranslation(payload) {
	return {
		type: SET_GLOBAL_INGREDIENT_COMPONENT_TRANSLATION,
		payload,
	};
}

export function updateGlobalIngredientComponentTranslation(payload) {
	return {
		type: UPDATE_GLOBAL_INGREDIENT_COMPONENT_TRANSLATION,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}

export function rerenderTable() {
	return {
		type: RERENDER_TABLE,
	};
}
