export const RESET_PRODUCT_VARIANT_BASKET_RECOMMENDATION =
	'SALES_CONFIGURATION_PRODUCT_VARIANT_BASKET_RECOMMENDATIONS/RESET_PRODUCT_VARIANT_BASKET_RECOMMENDATION';
export const SET_PRODUCT_VARIANT_BASKET_RECOMMENDATION =
	'SALES_CONFIGURATION_PRODUCT_VARIANT_BASKET_RECOMMENDATIONS/SET_PRODUCT_VARIANT_BASKET_RECOMMENDATION';
export const UPDATE_PRODUCT_VARIANT_BASKET_RECOMMENDATION =
	'SALES_CONFIGURATION_PRODUCT_VARIANT_BASKET_RECOMMENDATIONS/UPDATE_PRODUCT_VARIANT_BASKET_RECOMMENDATION';

export const UPDATE_BATCH_FORM =
	'SALES_CONFIGURATION_PRODUCT_VARIANT_BASKET_RECOMMENDATIONS/UPDATE_BATCH_FORM';
// export const ADD_BATCH_LIST =
// 	'SALES_CONFIGURATION_PRODUCT_VARIANT_BASKET_RECOMMENDATIONS/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_PRODUCT_VARIANT_BASKET_RECOMMENDATIONS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_PRODUCT_VARIANT_BASKET_RECOMMENDATIONS/REMOVE_BATCH_LIST_ITEM';

export function setProductVariantBasketRecommendation(payload) {
	return {
		type: SET_PRODUCT_VARIANT_BASKET_RECOMMENDATION,
		payload,
	};
}

export function basketProductVariantBasketRecommendation(payload) {
	return {
		type: UPDATE_PRODUCT_VARIANT_BASKET_RECOMMENDATION,
		payload,
	};
}

export function resetProductVariantBasketRecommendation() {
	return {
		type: RESET_PRODUCT_VARIANT_BASKET_RECOMMENDATION,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

// export function addBatchList(payload) {
// 	return {
// 		type: ADD_BATCH_LIST,
// 		payload,
// 	};
// }

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}
