import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { store, connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { Modal } from 'dumb';
import { toggleModal } from './store/missingMoneyballNotificationModal.actions';
import phrases from './missingMoneyballNotificationModal.phrases';
import './missingMoneyballNotificationModal.css';
import TYPE_ENUMS from './missingMoneyballNotificationModal.enum';

class MissingMoneyballNotificationModal extends Component {
	constructor(props) {
		super(props);

		this._toggleModal = this._toggleModal.bind(this);
	}

	_toggleModal() {
		const { toggleModal } = this.props;

		toggleModal(null);
	}

	render() {
		const { showModal } = this.props;
		const isActive = !!showModal;

		let message = phrases.MESSAGE_OTHER;
		switch (showModal) {
			case TYPE_ENUMS.NO_EMPLOYMENT_START:
				message = phrases.MESSAGE_NO_EMPLOYMENT_START;
				break;
			case TYPE_ENUMS.NO_USER_REFERENCE:
				message = phrases.MESSAGE_NO_USER_REFERENCE;
				break;
		}

		return isActive ? (
			<Modal
				header={phrases.HEADER}
				isOpen={isActive}
				onClose={this._toggleModal}
				onConfirmClick={this._toggleModal}
				confirmButtonLabel={phrases.SUBMIT}>
				<p>{message}</p>
				<p>{phrases.MESSAGE_OUTRO}</p>
			</Modal>
		) : null;
	}
}

MissingMoneyballNotificationModal.propTypes = {
	showModal: PropTypes.any,
	toggleModal: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			toggleModal,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		showModal: store.missingMoneyballNotificationModal.showModal,
	};
};

export default connectWithStore(
	MissingMoneyballNotificationModal,
	mapStateToProps,
	mapDispatchToProps
);
