'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import HeaderRow from 'reusableComponents/list/headerRow.component';
import Pagination from 'reusableComponents/list/pagination.component';
import ColumnSelect from 'reusableComponents/list/controls/columnSelect.component';
import Rows from './rows.component';
import SumRow from './sumRow.component';

import _each from 'lodash/each';
import _has from 'lodash/has';
import _some from 'lodash/some';

class Default extends Component {
	/**
	 * @function _getMinimumWidth
	 * @memberOf DIRECTIVES.jtjList.component
	 * @description Calculates minimum width for table wrapper
	 * @return {number}
	 */
	_getMinimumWidth() {
		var minWidth = this.props.settings.minWidthBuffer;
		_each(this.props.headers, function (header) {
			if (_has(header, 'width')) {
				minWidth = minWidth + header.width;
			}
		});
		return minWidth;
	}

	/**
	 * @function _renderSumRow
	 * @description Renders sum row if any of the visible headers has it enabled
	 */
	_renderSumRow() {
		if (_some(this.props.methods.handleReturnVisibleHeaders(), 'summarize'))
			return <SumRow {...this.props} />;
	}

	render() {
		var listClassNames = cx('list', {
			'list--tiny': this.props.settings.tinyText,
			'list--is-editing': this.props.isEditing || this.props.isAdding,
		});

		var listStyle = {
			minWidth: this._getMinimumWidth(),
		};

		return (
			<div>
				<ColumnSelect {...this.props} />
				<table
					className={listClassNames}
					style={listStyle}
					id={`${this.props.title}List`}>
					<HeaderRow {...this.props} />
					<Rows {...this.props} />
					{this._renderSumRow()}
					<Pagination {...this.props} />
				</table>
			</div>
		);
	}
}

Default.propTypes = {
	headers: PropTypes.array.isRequired,
	settings: PropTypes.object.isRequired,
};

export default Default;
