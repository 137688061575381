export default {
	HEADING__ACTIVATE: 'Set your password',
	HEADING__RESET: 'Reset your password',
	HEADING__SUCCESS: 'Wooo!',
	HEADING__FAIL: 'Woah, fail!',
	SUBHEADING__SUCCESS: 'Success. Have a juicy day!',
	ERROR__OOPS: 'Ooops - something went wrong! Try opening link again or contact HR. Maybe you were looking for these:',
	BUTTON__SUBMIT: 'Activate',
	BUTTON__RESET: 'Reset password',
	BUTTON__HOME: 'Homepage',
	PASSWORD_LENGTH_PASS: 'Length at least 10 characters',
	PASSWORD_UPERCASE_PASS: 'At least one upercase letter',
	PASSWORD_LOWERCASE_PASS: 'At least one lowercase letter',
	PASSWORD_ONE_DIGIT: 'At least one digit',
	PASSWORD_SPECIAL_CHARACTER: 'At least one special character',
	PASSWORD_MATCH: 'Passwords match',
	EMAIL_PLACEHOLDER: 'Enter your email...',
	EMAIL: 'Email',
};
