'use strict';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import cx from 'classnames';

import { ChromePicker, TwitterPicker } from 'react-color';

class Color extends PureComponent {
	constructor(props) {
		super(props);

		const color =
			props.value && props.value.includes('#')
				? props.value
				: `#${props.value}`;
		this.state = {
			color,
		};

		this.onChange = this.onChange.bind(this);
		this.onChangeComplete = this.onChangeComplete.bind(this);
		this.getType = this.getType.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.state.color !== nextProps.color) {
			const color = nextProps.value.includes('#')
				? nextProps.value
				: `#${nextProps.value}`;
			this.setState(() => ({ color }));
		}
	}

	onChange(color) {
		this.setState(() => ({ color }));
	}

	onChangeComplete(color) {
		const { onChange } = this.props;
		onChange(color);
	}

	/**
	 * Get type of colorpicker based on prop "type"
	 */
	getType() {
		const { type, triange } = this.props;
		switch (type) {
			case 'Chrome':
				return (
					<ChromePicker
						color={this.state.color}
						onChange={this.onChange}
						onChangeComplete={this.onChangeComplete}
					/>
				);
			case 'Twitter':
				return (
					<TwitterPicker
						color={this.state.color}
						triangle={triange}
						onChange={this.onChange}
						onChangeComplete={this.onChangeComplete}
					/>
				);
			default:
				return (
					<ChromePicker
						color={this.state.color}
						onChange={this.onChange}
						onChangeComplete={this.onChangeComplete}
					/>
				);
		}
	}

	render() {
		const colorCx = cx('color');

		return <div className={colorCx}>{this.getType()}</div>;
	}
}

Color.propTypes = {
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
	type: PropTypes.string,
	triange: PropTypes.string,
	color: PropTypes.string,
};

Color.defaultProps = {
	type: 'Chrome',
	triange: 'hide',
	value: '',
};

export default Color;
