'use strict';
import React from 'react';
// import { fuzzySearch } from 'utils';
import { isStateAccessible } from 'accessControl';
import PowerBIReport from 'reports/powerBIReport/powerBIReport.container';
// import _isEmpty from 'lodash/isEmpty';
import _isFunction from 'lodash/isFunction';
import _get from 'lodash/get';

export function getNavigationItems({ routes, user }) {
	return routes.reduce((acc, currentValue) => {
		// first filter parent
		const hidden = _isFunction(currentValue.hidden)
			? currentValue.hidden()
			: currentValue.hidden;

		// check if state accessible
		const stateAccessible = isStateAccessible(
			currentValue,
			_get(user, 'userRoles', [])
		);

		if (!hidden && stateAccessible) {
			// check if child items accessible

			const children = currentValue.children
				? currentValue.children.filter((childItem) => {
						const hidden = _isFunction(childItem.hidden)
							? childItem.hidden()
							: childItem.hidden;
						return (
							!hidden &&
							isStateAccessible(childItem, _get(user, 'userRoles', []))
						);
				  })
				: [];

			return [
				...acc,
				{
					...currentValue,
					children,
				},
			];
		}
		return acc;
	}, []);
}

/**
 * @function flattenRoutes
 * @param {array} routes - get current version of routes array (with children)
 * @description takes all routes (which are stored in context) and then flatten 1 level children to a list
 * ALSO creates new key = fullPath each item would have full path
 */
export const flattenRoutes = (routes) => {
	return routes.reduce((globalRoutes, currentParent) => {
		if (currentParent.index) return globalRoutes;

		const parent = { ...currentParent, fullPath: currentParent.path };

		// first filter parent
		if (parent.children && parent.children.length) {
			// if parent has children, filter them
			const children = parent.children
				.map((child) => {
					if (child.index) return;
					return { ...child, fullPath: `${parent.path}/${child.path}` };
				})
				.filter((x) => x);

			return [...globalRoutes, parent, ...children];
		}

		// check if state accessible
		return [...globalRoutes, parent];
	}, []);
};

/**
 * @function formatPowerBIReports
 * @param {Object} object - contains power bi reports
 * @description formats power bi reports response to match
 * the structure of other nav items
 */
export function formatPowerBIReports({ data }) {
	if (!data.length) return [];

	const returnArray = data
		.reduce((acc, currentValue) => {
			const isGlobalReport = _getIsReportGlobal({
				reports: data,
				currentReportPowerBiReportId: currentValue.power_bi_id,
			});

			// find if that same global report is already added to acc
			const globalReportAlreadyAdded =
				isGlobalReport &&
				acc.find(
					(entry) =>
						entry.powerBIReportId === currentValue.power_bi_id &&
						entry.section === 'Global'
				);

			// if global report and already added, don't add
			if (globalReportAlreadyAdded) return acc;

			const navigationItem = {
				...((currentSection === undefined ||
					currentSection !== currentValue?.market?.name) && {
					section: currentValue?.market?.name,
				}),
				...(isGlobalReport && {
					section: 'Global',
				}),
				element: <PowerBIReport powerBIReportId={currentValue.power_bi_id} />,
				path: `power-bi-report/${currentValue.power_bi_id}`,
				description: isGlobalReport
					? 'Global report'
					: currentValue?.market?.name,
				title: currentValue.name,
				allowedAccessControlLevels: [currentValue.access_granting_area?.name],
				// this key will be remove in the next function executed on the array (Array.prototype.reduce)
				// we add an extra key to be able to identify the navigation items
				powerBIReportId: currentValue.power_bi_id,
			};

			return [...acc, navigationItem];
		}, [])
		// sort sections alphabetically
		.sort((a, b) => {
			if (a.section === 'Global') return -1;
			if (a.section > b.section) return 1;
			if (a.section < b.section) return -1;

			return 0;
		});

	// remove sections key
	let currentSection;
	const arraySortedUnderSections = returnArray.map((entry, index) => {
		if (index === 0) {
			currentSection = entry.section;
			return entry;
		}

		if (currentSection === entry.section) {
			delete entry.section;
			return entry;
		}

		currentSection = entry.section;
		return entry;
	});

	return arraySortedUnderSections;
}

/**
 * @function getTransportItemChildren
 * @param {Object} itemsToRender/userRoles (logged in user roles)
 * @description loops through items to render, finds out which ones we aren't allow to see, sorts them under sections
 * and then sorts sections alphabetically and lastly, sorts items under each section alphabetically
 * @returns {Array}
 */
export function getTransportItemChildren({ itemsToRender, userRoles }) {
	const menuItemChildren = itemsToRender
		.reduce((acc, currentValue) => {
			// find out if we can show the tile
			const canShowTile = isStateAccessible(currentValue, userRoles);

			if (!canShowTile) return acc;

			const section = currentValue.data?.section ?? '';

			// find if section exists
			let sectionObject = acc.find((entry) => entry.section === section);

			if (sectionObject) {
				// clear out the sectionObject from the acc
				acc = acc.filter((entry) => entry.section !== section);

				sectionObject = {
					...sectionObject,
					children: [...sectionObject.children, currentValue],
				};
			} else {
				sectionObject = {
					section,
					children: [currentValue],
				};
			}

			acc = [...acc, sectionObject];

			return acc;
		}, [])
		// sort sections
		.sort((a, b) => {
			if (a.section > b.section) return 1;
			if (a.section < b.section) return -1;
			return 0;
		})
		// sort card inside each section
		.map((entry) => {
			return {
				...entry,
				children: entry.children.sort((a, b) => {
					const aTitle = a.data?.title;
					const bTitle = b.data?.title;

					if (!aTitle || !bTitle) return 0;

					if (aTitle > bTitle) return 1;
					if (aTitle < bTitle) return -1;
					return 0;
				}),
			};
		});

	return menuItemChildren;
}

// report is considered global if the number of reports that point to the same power bi report (by power bi id) is the same as the total number of markets
// example: we have 30 reports out of which we have 3 unique markets (every report belongs to a market) -> Denmark, Sweden and Norway
// if we then have 3 reports that have the same power bi report and each of them belongs to a different market -> Denmark, Sweden and Norway, then we create 1 global report out of those 3
function _getIsReportGlobal({ reports, currentReportPowerBiReportId }) {
	// get all unique markets to be able to sort repoets under global afterwards
	// if this lenght is 1, then no global reports
	const uniqueMarketIds = reports.reduce((acc, currentValue) => {
		if (!acc.includes(currentValue.market.id)) {
			return [...acc, currentValue.market.id];
		}
		return acc;
	}, []);

	// if we only have reports from one market, then it's not a global report
	if (uniqueMarketIds.length === 1) return false;

	// get all reports with the same power bi report id
	const reportsWithSamePowerBiId = reports.filter(
		(entry) => entry.power_bi_id === currentReportPowerBiReportId
	);

	// means that this report can be found in all existing markets and therefor it's a global report
	const isGlobalReport =
		reportsWithSamePowerBiId.length === uniqueMarketIds.length;

	return isGlobalReport;
}
