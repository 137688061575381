'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';
const __endpoints = require('endpoints.service');
const __enums = require('enums.service');

export default class WorkplaceSizesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'type',
				type: 'select',
				canAdd: true,
				canEdit: true,
				required: true,
				options: {
					enums: __enums.workplaceSizes,
				},
			},
			{
				name: 'size',
				type: 'number',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'retail',
				type: 'bool',
				canAdd: true,
				canEdit: true,
			},
		];
		this.settings = {
			name: 'WorkplaceWorkplaceSizesList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			canCollapse: true,
			alwaysRefresh: true,
			canManipulate: () => validAccess(undefined, ['Organisation/Workplaces::Edit', 'Organization Admin']),
			translationPath: 'ADMINISTRATION.WORKPLACE_SIZES',
			endpoint: __endpoints.collection.administration.workplace_sizes,
		};
		this.init();
		return this;
	}
}
