'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Store
import { bindActionCreators } from 'redux';
import { connectWithStore } from 'appState';
import { resetState, editGroup } from './store/addGroupModal.actions';

// Components
import { ModalStepper } from 'dumb';
import AddGroupForm from './components/addGroupForm';
import AddSelectImage from './components/addSelectImage';

// Other
import './addGroupModal.css';
import phrases from './addGroupModal.phrases';

class AddGroupModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeGroup: {},
		};

		this.modalRef = React.createRef();

		// Binds
		this._goToStep = this._goToStep.bind(this);
		this._handleClose = this._handleClose.bind(this);
		this.onGroupFormUpdate = this.onGroupFormUpdate.bind(this);
		this._renderGroupStep = this._renderGroupStep.bind(this);
	}

	onGroupFormUpdate(data) {
		this.props.editGroup(data);
	}

	_onGroupSubmit() {
		const {
			groupData,
			layoutGroupId,
			addLayoutGroup,
			updateLayoutGroup,
			nextPosition,
		} = this.props;

		groupData.position = groupData.position ? groupData.position : nextPosition;

		let promise = null;
		if (groupData.id) {
			promise = updateLayoutGroup(groupData, groupData.id);
		} else {
			promise = addLayoutGroup(groupData, layoutGroupId);
		}

		return promise.finally(() => {
			this._handleClose();
		});
	}

	_handleClose() {
		this.props.resetState();
		this.modalRef.current.goToStep(1);
		this.props.handleClose();
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_renderGroupStep() {
		const { groupData, isTypePos } = this.props;

		return (
			<AddGroupForm
				onGroupFormUpdate={this.onGroupFormUpdate}
				groupData={groupData}
				isTypePos={isTypePos}
			/>
		);
	}

	_renderSelectImageGroupStep() {
		const { groupData, imageAssets } = this.props;

		return (
			<AddSelectImage
				productTile={groupData}
				imageAssets={imageAssets}
				onImageUpdate={this.onGroupFormUpdate}
			/>
		);
	}

	render() {
		const { visible, loading, groupData, loadingTile } = this.props;

		const { activeGroup } = this.state;

		const steps = [
			{
				component: this._renderGroupStep(),
				title: activeGroup.id ? 'Edit group' : 'Add group',
				isValid: groupData.name,
				onNext: () => this._goToStep(2),
				loading: loading || loadingTile,
				confirmButtonLabel: activeGroup.id ? phrases.UPDATE : phrases.CONTINUE,
			},
			{
				component: this._renderSelectImageGroupStep(),
				title: 'Select a product image',
				isValid: groupData.name,
				onNext: () => this._onGroupSubmit(),
				onBack: () => this._goToStep(1),
				loading: loading,
				confirmButtonLabel: activeGroup.id ? phrases.UPDATE : phrases.CREATE,
				cancelButtonLabel: phrases.BACK,
			},
		];

		return (
			<ModalStepper
				ref={this.modalRef}
				isOpen={visible}
				steps={steps}
				showStep={false}
				onClose={this._handleClose}
			/>
		);
	}
}

AddGroupModal.propTypes = {
	// Passed in props
	visible: PropTypes.bool,
	handleClose: PropTypes.func,
	layoutGroupId: PropTypes.number,
	loadingTile: PropTypes.bool,
	imageAssets: PropTypes.array,

	// Store props
	groupData: PropTypes.object,
	resetState: PropTypes.func,
	nextPosition: PropTypes.number,
	addLayoutGroup: PropTypes.func,
	updateLayoutGroup: PropTypes.func,
	editGroup: PropTypes.func,
	loading: PropTypes.bool,
	isTypePos: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			resetState,
			editGroup,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		productVariantList:
			store.salesConfigurationLoyaltyAddGroup.data.productVariantList,
		productTile: store.salesConfigurationLoyaltyAddGroup.data.productTile,
		groupData: store.salesConfigurationLoyaltyAddGroup.data.group,
		loading: store.salesConfigurationLoyaltyAddGroup.ui.loading,
		loadingProduct: store.salesConfigurationLoyaltyAddGroup.ui.loadingProduct,
	};
};

export default connectWithStore(
	AddGroupModal,
	mapStateToProps,
	mapDispatchToProps
);
