'use strict';

import {
	SET_WORKFORCE_NEWS_MODAL,
	UPDATE_WORKFORCE_NEWS,
	RESET_WORKFORCE_NEWS_MODAL,
} from './workforceNews.actions';

const defaultState = {
	data: {
		workforceNews: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_WORKFORCE_NEWS_MODAL: {
		return {
			...state,
			data: {
				workforceNews: payload
			}
		};
	}

	case UPDATE_WORKFORCE_NEWS: {
		return {
			...state,
			data: {
				workforceNews: {
					...state.data.workforceNews,
					...payload
				}
			}
		};
	}

	case RESET_WORKFORCE_NEWS_MODAL: {
		return {
			...state,
			data: {
				workforceNews: defaultState.data.workforceNews
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
