export default {
	CELL_TYPES: {
		NUMBER: 'number',
		INTEGER: 'integer',
		INTEGER_DIVIDED_BY_THOUSAND: 'integerDividedByThousand',
		CURRENCY_NO_DECIMAL: 'currencyNoDecimal',
		CURRENCY: 'currency',
		PERCENTAGE: 'percentage',
		PERCENTAGE_NO_DECIMAL: 'percentageNoDecimal',
		NUMBER_SINGLE_DECIMAL: 'numberSingleDecimal',
		NUMBER_NO_DECIMAL: 'numberNoDecimal'
	},
	TABLE_TYPES: {
		TABLE_TOTAL: 'table-total',
		SUB_TOTAL: 'sub-total',
		SUB_SUBTOTAL: 'sub-subtotal',
		GROUP_SUBTOTAL: 'group-subtotal',
		GROUP_TOTAL: 'group-total',
		ENTRY: 'entry'
	}
};
