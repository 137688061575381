'use strict';

import HotbarDataProviderAbstract from './hotbar.dataProvider.abstract';

class HotbarStaticDataProvider extends HotbarDataProviderAbstract {
	constructor(data) {
		super();

		// Begin with null
		this.data = null;

		// Set new data
		this.setData(data);
	}

	/**
	 * @function getData
	 * @memberOf HotbarStaticDataProvider
	 * @description Gets data
	 */
	getData() {
		return this.data;
	}

	/**
	 * @function getDataAsOptions
	 * @memberOf HotbarStaticDataProvider
	 * @description Gets data formatted as options [{value, label}, ...]
	 */
	getDataAsOptions() {
		return new Promise(resolve => resolve(this.getData()));
	}

	/**
	 * @function setData
	 * @memberOf HotbarStaticDataProvider
	 * @description Sets data formatted as options [{value, label}, ...]
	 * @param {array}
	 */
	setData(data) {
		// Check data for type

		this.data = data;
	}
}

export default HotbarStaticDataProvider;
