import {
	UPDATE_DOCUSIGN_TEMPLATE,
	SET_DOCUSIGN_TEMPLATE,
	RESET_STATE,
} from './docuSignTemplateRoles.actions';

const defaultState = {
	data: {
		docusignTemplateRole: {},
		defaultDocusignTemplateRole: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_DOCUSIGN_TEMPLATE: {
			return {
				data: {
					...state.data,
					docusignTemplateRole: payload,
					defaultDocusignTemplateRole: payload,
				},
			};
		}

		case UPDATE_DOCUSIGN_TEMPLATE: {
			return {
				data: {
					...state.data,
					docusignTemplateRole: {
						...state.data.docusignTemplateRole,
						...payload,
					},
				},
			};
		}

		case RESET_STATE: {
			return {
				...defaultState,
			};
		}

		default:
			return state;
	}
}

export default reducer;
