'use strict';

var headers = {
	TAGS: {
		TAG: 'Tag',
		FROM: 'From',
		TO: 'To',
		TAGS: 'Tags',
		SUBTYPE: 'Subtype',
		TYPE: 'Type',
		NAME: 'Name',
		NAME_ABBREVIATED: 'Name abbreviated',
		COLOR: 'Color',
		EXCLUDE_PRODUCTIVITY: 'Exclude in PHP',
		EMPLOYMENT: 'Employment',
		GLOBAL_ACCOUNT: 'Global account',
		EXCLUDE_BASE_PAY: 'Exclude base pay',
		EXCLUDE_ADDON: 'Exclude addon',
		CURRENT_YEAR_INDEX: 'Current year index',
		PRIOR_YEAR_INDEX: 'Prior year index',
		EXCLUDE_OVERTIME: 'Exclude overtime'
	}
};

var titles = {
	TAGS: 'Tags'
};

module.exports = {
	headers: headers,
	titles: titles
};
