'use strict';

import { get, remove, post } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service';

/**
 * @function fetchPersonAttributes
 * @description Fetch person attributes
 * @param {Object} Object with limit, offset, filter and sort
 * @param {String} initialFilter
 * @returns Promise
 */
export function fetchPersonAttributes(
	{ limit, offset, filter, sort },
	initialFilter
) {
	if (filter) {
		filter = `${initialFilter};${filter}`;
	} else {
		filter = initialFilter;
	}

	// Set params and filter
	const params = {
		limit,
		sort,
		offset,
		filter
	};

	return get('/hr/person_attributes', params).catch(err => {
		const error = formatErrorMessage(err);
		setFeedback(error, 0);
	});
}

/**
 * @function deletePersonAttribute
 * @description Delete person attribute
 * @param {Object} Object with id
 * @returns Promise
 */
export function deletePersonAttribute(personAttributeId) {
	return remove(`/hr/person_attributes/${personAttributeId}`)
		.then(res => {
			setFeedback('Person attribute deleted!', 1);
			return res;
		})
		.catch(err => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
			throw err;
		});
}

/**
 * @function updatePersonAttribute
 * @description Edit person attribute
 * @param {number} vacationId with id
 * @param {Object} payload
 * @returns Promise
 */
export function updatePersonAttribute(userId, payload) {
	const userEndpoint = `/hr/person_attributes/${userId}`;

	return post(userEndpoint, payload)
		.then(res => {
			setFeedback('Person attribute edited!', 1);
			return res;
		})
		.catch(err => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
		});
}

/**
 * @function createPersonAttribute
 * @description crate person attribute
 * @param {number} personId with id
 * @param {Object} id with id
 * @returns Promise
 */
export function createPersonAttribute(payload) {
	return post('/hr/person_attributes', payload)
		.then(res => {
			setFeedback('Person attribute created!', 1);
			return res;
		})
		.catch(err => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
		});
}
