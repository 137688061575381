'use strict';

import { get, post, remove, patch } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { fetchCompleteCollection } from 'api/fetchCompleteCollection';

import phrases from './workplacePosConfiguration.phrases';

/**
 * @function fetchWorkplaceToPosConfigurationRelations
 * @description Fetch all workplace to pos configuration relations
 */
export function fetchWorkplaceToPosConfigurationRelations({
	state = {},
	shouldFetchCompleteCollection = false,
	filter,
}) {
	let newFilter;
	if (state.filter) newFilter = state.filter;
	if (filter) newFilter = newFilter ? `${newFilter};${filter}` : filter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: newFilter,
	};

	const request = shouldFetchCompleteCollection
		? fetchCompleteCollection(
			`/pos/workplace_to_pos_configuration_relations`,
			params,
			state.headers
		  )
		: get(
			`/pos/workplace_to_pos_configuration_relations`,
			params,
			null,
			state.headers
		  );

	return Promise.resolve(request)
		.then((res) => {
			res = shouldFetchCompleteCollection
				? {
					data: res,
				  }
				: res;

			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

export function fetchPosConfigurations(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/pos/pos_configurations', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

export function fetchWorkplaces(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/administration/workplaces', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editWorkplaceToPosConfigurationRelation
 * @param {Object} payload - update object
 */
export function editWorkplaceToPosConfigurationRelation(payload) {
	return post(
		`/pos/workplace_to_pos_configuration_relations/${payload.id}`,
		payload
	)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_TO_POS_CONFIGURATION_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function multiEditWorkplaceToPosConfigurationRelation
 * @param {Object} payload - update object
 */
export function multiEditWorkplaceToPosConfigurationRelation(payload) {
	return patch('/pos/workplace_to_pos_configuration_relations', payload)
		.then((res) => {
			setFeedback(phrases.MULTI_WORKPLACE_TO_POS_CONFIGURATION_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteWorkplaceToPosConfigurationRelation
 * @param {Number} id - id of a workplace to pos config to delete
 */
export function deleteWorkplaceToPosConfigurationRelation(id) {
	return remove(`/pos/workplace_to_pos_configuration_relations/${id}`)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_TO_POS_CONFIGURATION_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

export function addWorkplaceToPosConfigurationRelation(data) {
	return post('/pos/workplace_to_pos_configuration_relations', data)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_TO_POS_CONFIGURATION_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

export function duplicateWorkplaceToPosConfigurationRelation(data) {
	return post(
		'/pos/workplace_to_pos_configuration_relations/create_and_end_previous_version',
		data
	)
		.then((res) => {
			setFeedback(phrases.WORKPLACE_TO_POS_CONFIGURATION_DUPLICATED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
