'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setSystemSoftwares,
	updateSystemSoftwares,
	resetState,
} from './store/systemSoftwareVersions.actions';

// services
import {
	fetchSystemSoftwareVersions,
	editSystemSoftwareVersion,
	addSystemSoftwareVersions,
	deleteSystemSoftwareVersion,
} from './systemSoftwareVersions.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Input, Button, Icon } from 'dumb';
import SystemSoftwareVersionsModalBatch from './components/batch/systemSoftwareVersionsModalBatch';
import getColumns from 'reactDataWrapperColumns/administration/systemSoftwareVersions.columns';

// utils
import { getEditedValues } from 'services/utils';

// moment
import moment from 'moment';

// phrases
import phrases from './systemSoftwareVersions.phrases';

const reduxKey = '/administration/system_software_versions';

class SystemSoftwareVersions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment().unix(),
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.addSystemSoftwareVersionsWrapper = this.addSystemSoftwareVersionsWrapper.bind(
			this
		);
		this.fetchData = this.fetchData.bind(this);
		this.editEntry = this.editEntry.bind(this);

		this.columns = getColumns();
	}

	editEntry() {
		const {
			systemSoftwareVersions,
			originalSystemSoftwareVersions,
		} = this.props;

		const editedValues = getEditedValues({
			oldData: originalSystemSoftwareVersions,
			newData: systemSoftwareVersions,
		});

		const payload = {
			id: systemSoftwareVersions.id,
			version: editedValues.version ?? undefined,
			plist: editedValues.plist ?? undefined,
		};

		return editSystemSoftwareVersion(payload);
	}

	deleteEntry(id) {
		return deleteSystemSoftwareVersion(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			version: data.version,
			plist: data.plist,
			singleEdit: true,
		};

		this.props.setSystemSoftwares(payload);
	}

	getEditableCells() {
		const { systemSoftwareVersions } = this.props;

		return [
			{
				header: 'Version',
				value: <span>{systemSoftwareVersions.version}</span>,
			},
			{
				header: 'Plist',
				value: (
					<Input
						id="plist"
						placeholder="Enter plist..."
						value={systemSoftwareVersions.plist}
						onChange={(e) => this.editStoreEntry('plist', e)}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const { updateSystemSoftwares } = this.props;

		const value = e.target.value;

		const updateObject = {
			[name]: value,
		};

		updateSystemSoftwares(updateObject);
	}

	fetchData(state) {
		const { systemSoftwareId } = this.props;

		const filter = `:system_software.id=='${systemSoftwareId}'`;

		return fetchSystemSoftwareVersions(state, filter);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	addSystemSoftwareVersionsWrapper(data) {
		return addSystemSoftwareVersions(data).then(() =>
			this.setState(() => ({
				key: moment().unix(),
			}))
		);
	}

	render() {
		const {
			batchList,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
			systemSoftwareId,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					accessAreasAllowedToEdit={['Global Sales Configuration']}

					defaultPageSize={10}
					reduxKey={`${reduxKey}-${systemSoftwareId}`}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					deleteEntry={this.deleteEntry}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					enableSingleSelection
					enableMultiSelection={false}
					actionRender={
						<Button
							type="inverted"
							label="Batch"
							shadow
							onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
				/>

				<SystemSoftwareVersionsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addSystemSoftwareVersions={this.addSystemSoftwareVersionsWrapper}
					systemSoftwareId={systemSoftwareId}
				/>
			</>
		);
	}
}

SystemSoftwareVersions.propTypes = {
	systemSoftwareVersions: PropTypes.object,
	updateSystemSoftwares: PropTypes.func,
	setSystemSoftwares: PropTypes.func,
	resetState: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	originalSystemSoftwareVersions: PropTypes.object,
	systemSoftwareId: PropTypes.number,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			editBatchListItem,
			removeBatchListItem,
			updateSystemSoftwares,
			setSystemSoftwares,
			resetState,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		systemSoftwareVersions:
			store.globalAdministrationSystemSoftwareVersions.data
				.systemSoftwareVersions,
		originalSystemSoftwareVersions:
			store.globalAdministrationSystemSoftwareVersions.data
				.originalSystemSoftwareVersions,
		batchList: store.globalAdministrationSystemSoftwareVersions.data.batchList,
	};
};

export default connectWithStore(
	SystemSoftwareVersions,
	mapStateToProps,
	mapDispatchToProps
);
