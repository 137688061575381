'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';
const __endpoints = require('endpoints.service');

export default class CountriesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
			},
			{
				name: 'alpha_2_code',
				type: 'string',
			},
			{
				name: 'alpha_3_code',
				type: 'string',
			},
			{
				name: 'numeric_code',
				type: 'string',
			},
			{
				name: 'iso_3166_2_code',
				type: 'string',
			},
			{
				name: 'nationality',
				type: 'select',
				reference: {
					key: 'name',
					list: '/administration/nationalities',
				},
			},
		];
		this.settings = {
			name: 'CountriesList',
			hasDetails: true,
			pageTitle: true,
			canManipulate: () => validAccess(undefined, ['Global BI Configuration']),
			translationPath: 'ADMINISTRATION.COUNTRIES',
			endpoint: __endpoints.collection.administration.countries,
		};
		this.init();
		return this;
	}
}
