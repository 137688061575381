// utils
import { ShiftTransfer as enums } from 'services/enums';
import constants from 'services/constants';
import moment from 'moment';
import { validAccess } from 'accessControl';

// lodash
import _isEmpty from 'lodash/isEmpty';

/**
 * @function getIsShiftInTransferState
 * @param {Object} shift - shift object
 * @description determines whether the shift is in a "active" transfer state
 * @returns {Boolean}
 */
export function getIsShiftInTransferState({ shift, personId }) {
	// if no transfer, don't show as in transfer
	if (_isEmpty(shift.last_shift_transfer)) return false;

	const shiftplanManager = validAccess(undefined, ['Shiftplan Manager']);
	const ourShift = shift.shift_employees?.[0]?.employment?.person?.id === personId;
	const publicShift = shift.last_shift_transfer.type === enums.TRANSFER_TYPE.PUBLIC;
	const shiftNotApplicable =
		shift.last_shift_transfer.state === enums.TRANSFER.RETRACTED ||
		shift.last_shift_transfer.state === enums.TRANSFER.DECLINED ||
		shift.last_shift_transfer.state === enums.TRANSFER.APPROVED;

	// if logged in user cannot apply for the shift, don't show as in transfer
	if (shiftNotApplicable) return false;

	// if manager or above, always show shifts in progress
	if (shiftplanManager) return true;

	// if logged in user is a juicer, and someone elses shift transfer is public, show it
	if (!shiftplanManager && !ourShift && publicShift) return true;

	// if logged in user is juicer and it's his shift, show it in progress
	if (!shiftplanManager && ourShift) return true;

	return false;
}

/**
 * @func getShiftActionToPerform
 * @param {Object} params
 * @description gets the updated shift from shift transfer modal and
 * return an appropirate action to be taken on the shift (add/update/remove) or null
 * if the shift shouldn't be updated
 * In Shiftplanner case we always return the update action as long as the shift falls
 * withing the period we are currently viewing
 * @returns {String/Null} enum action or null
 */
export function getShiftActionToPerform({
	shiftToUpdate,
	persons,
	currentStartOfPeriod,
	endOfPeriod,
	shifts,
	isShiftplanner,
}) {
	if (!shiftToUpdate) return null;

	let shiftWithinPeriod = false;

	const shiftStartMoment = moment.utc(shiftToUpdate?.planned_period?.from, constants.dateFormat);
	const shiftEndMoment = moment.utc(shiftToUpdate?.planned_period?.to, constants.dateFormat);

	if (shiftStartMoment.isSameOrAfter(currentStartOfPeriod, 'day') && shiftEndMoment.isSameOrBefore(endOfPeriod))
		shiftWithinPeriod = true;

	// if shift not within period, exit
	if (!shiftWithinPeriod) return null;

	const shiftAction = isShiftplanner
		? enums.SHIFT_ACTIONS.UPDATE
		: _getShiftsForMyShiftplanner({
				shiftToUpdate,
				shifts,
				persons,
		  });

	return shiftAction;
}

function _getShiftsForMyShiftplanner({ shiftToUpdate, shifts, persons }) {
	const shiftCurrentlyVisible = shifts.find((entry) => entry.id === shiftToUpdate.id);
	const shiftOwnerVisible = persons.find(
		(entry) => entry.id === shiftToUpdate?.shift_employees[0]?.employment?.person?.id
	);

	// if owner changed to one we are not viewing, remove the shift
	if (!shiftOwnerVisible && shiftCurrentlyVisible) return enums.SHIFT_ACTIONS.REMOVE;
	// if owner visible and shift visible, update the shift
	if (shiftOwnerVisible && shiftCurrentlyVisible) return enums.SHIFT_ACTIONS.UPDATE;
	// if owner visible but no shift, add the shift
	if (shiftOwnerVisible && !shiftCurrentlyVisible) return enums.SHIFT_ACTIONS.ADD;
}

export function getHumanReadableState(state) {
	if (state === enums.APPLICATIONS.AWAITING_APPLICANT_APPROVAL) return 'Awaiting juicer';
	if (state === enums.APPLICATIONS.AWAITING_MANAGER_APPROVAL) return 'Awaiting BM';

	return state;
}
