
import WorkplaceAddress from './workplaceAddress';

class WorkplaceAddressFactory {

	WorkplaceAddress(workplaceId) {
		return new WorkplaceAddress(workplaceId);
	}

}

export default new WorkplaceAddressFactory();