'use strict';

import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';

// import ButtonLoader from 'buttonLoader';
import { ButtonLoader } from 'dumb';

import HourlyView from './../hourlyView/hourlyView.component';
import ProductSplitView from './../productSplitView/productSplitView.component';

class StoresViewBody extends PureComponent {
	getLengthOfColumns() {
		return Object.keys(this.props.columns).reduce((acc, key) => {
			// Get column in question
			const column = this.props.columns[key];
			acc = acc + Object.keys(column.cells).length;
			return acc;
		}, 0);
	}

	getContents(contentContainer, width, data) {
		let mappedContents = [];

		for (let i = 0, len = contentContainer.length; i < len; i++) {
			const content = contentContainer[i];

			// Get styles
			const styles = cx('report-old__content', {
				'report-old__content--currency': content.contentType === 'currency'
			});
			mappedContents = [
				...mappedContents,
				<span key={i} className={styles}>
					{content.getContent(data, width) || '\u00a0'}
				</span>
			];
		}
		return mappedContents;
	}

	getColumns(row) {
		const {
			columns,
			tables,
			startHover,
			stopHover,
			expandedTables
		} = this.props;

		const expandedProducts = expandedTables.product;

		return Object.keys(columns).map(columnKey => {
			// Get column in question
			const column = columns[columnKey];

			// Iterate over column cells
			return Object.keys(column.cells).map((cellKey, index) => {
				// Get cell in question
				const cell = column.cells[cellKey];

				const defaultClassNames = {
					'report-old__cell--first': index === 0, // first entry
					'report-old__cell--currency': cell.contentType === 'currency', // Right aligned
					'report-old__cell--number': cell.contentType === 'number', // Right aligned
					'report-old__cell--mixed': cell.contentType === 'mixed', // Center aligned
					'report-old__cell--left': cell.contentType === 'left', // left aligned
					'report-old__cell--hover': column.action,
					'dynamic-pos-view__product-split--active--light':
						expandedProducts[column.group] &&
						expandedProducts[column.group][row.id],
					'dynamic-pos-view__hourly--active--light':
						expandedTables.hourly &&
						expandedTables.hourly[row.id] &&
						column.group === 'average'
				};

				// Combine default class names with modifier class names
				const styles = cx('report-old__cell', {
					...defaultClassNames,
					...cell.getModifierClassNames(row.content)
				});

				// Get width
				const width = cell.getWidth(tables) ? cell.getWidth(tables) : 'auto';

				return column.action ? (
					<td
						onMouseOver={() => startHover(column.group)}
						onMouseOut={() => stopHover()}
						onClick={() => column.action(row)}
						key={cellKey}
						className={styles}
						style={{ width }}>
						{this.getContents(cell.getContent(), width, row.data)}
					</td>
				) : (
					<td key={cellKey} className={styles} style={{ width }}>
						{this.getContents(cell.getContent(), width, row.data)}
					</td>
				);
			});
		});
	}

	getRows() {
		const {
			dynamicPosViewState,
			expandedTables,
			workplaceTable,
			loading,
			phrases
		} = this.props;

		const lengthOfColumns = this.getLengthOfColumns();

		return (
			workplaceTable.content &&
			workplaceTable.content.map((row, key) => {
				return [
					<tr key={key + '__entry'} className="report-old__entry">
						{this.getColumns(row)}
					</tr>,

					expandedTables.hourly[row.id] && (
						<tr key={key + '__averages'} className="report-old__subtable">
							<td colSpan={lengthOfColumns}>
								{loading.hourly === row.id ? (
									<p className="report-old__lazy-table-fetching">
										<ButtonLoader theme="dark" loading /> {phrases.FETCHING}{' '}
										{phrases.HOURLY} ({row.data.workplace.name})
									</p>
								) : (
									<HourlyView
										{...{
											...this.props,
											hourlyTable:
												dynamicPosViewState.workplaceHourlyTables[
													row.data.workplace.id
												]
										}}
									/>
								)}
							</td>
						</tr>
					),

					Object.keys(expandedTables.product).filter(
						product => expandedTables.product[product][row.id]
					).length > 0 && (
						<tr key={key + '__productSplit'} className="report-old__subtable">
							<td colSpan={lengthOfColumns}>
								{loading.product === row.id ? (
									<p className="report-old__lazy-table-fetching">
										<ButtonLoader theme="dark" loading /> {phrases.FETCHING}{' '}
										{phrases.PRODUCT_SPLIT} ({row.data.workplace.name})
									</p>
								) : (
									<ProductSplitView
										{...{
											...this.props,
											expandedId: row.id,
											expandedProducts: expandedTables.product,
											productTable:
												dynamicPosViewState.workplaceProductTables[
													row.data.workplace.id
												]
										}}
									/>
								)}
							</td>
						</tr>
					)
				];
			})
		);
	}

	render() {
		return <tbody>{this.getRows()}</tbody>;
	}
}

StoresViewBody.defaultProps = {
	workplaceTable: {}
};

StoresViewBody.propTypes = {
	columns: PropTypes.object.isRequired,
	startHover: PropTypes.func.isRequired,
	stopHover: PropTypes.func.isRequired,
	tables: PropTypes.object.isRequired,
	expandedTables: PropTypes.object.isRequired,
	workplaceTable: PropTypes.object.isRequired
};

export default StoresViewBody;
