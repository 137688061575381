/* eslint-disable react/prop-types */
import { IColumnHeader } from 'types/reactDataWrapper';

export default function getColumns(): IColumnHeader<IShiftplanningVacationDescriptionPresets>[] {
	const columns: IColumnHeader<IShiftplanningVacationDescriptionPresets>[] = [
		{
			Header: 'Subject',
			id: 'subject',
			accessor: 'subject',
			filterPath: ':subject',
		},
		{
			Header: 'Description',
			id: 'description',
			accessor: 'description',
			filterPath: ':description',
		},
	];

	return columns;
}
