import React from 'react';

/**
 * @function renderCustomCaption
 * @description
 */
export function renderCustomCaption({ month, onMonthSelect, onYearSelect }) {
	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<select
				value={month.month()}
				onChange={e => {
					onMonthSelect(month, e.target.value);
				}}>
				{moment.months().map((label, value) => (
					<option key={value} value={value}>
						{label}
					</option>
				))}
			</select>
			<select
				value={month.year()}
				onChange={e => {
					onYearSelect(month, e.target.value);
				}}>
				{renderPastYears()}
				{renderFutureYears()}
			</select>
		</div>
	);
}

export function renderPastYears() {
	let years = [];
	for (let i = 65; i >= 0; i--) {
		years.push(
			<option key={i} value={moment().year() - i}>
				{moment().year() - i}
			</option>
		);
	}
	return years;
}

export function renderFutureYears() {
	let years = [];
	for (let i = 1; i <= 10; i++) {
		years.push(
			<option key={i + 'i'} value={moment().year() + i}>
				{moment().year() + i}
			</option>
		);
	}
	return years;
}