'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import getProductColumns from 'reactDataWrapperColumns/product/products.columns';
import SubTable from 'reactDataWrapper/components/subTable';
import AssetLeftAligned from './components/assetsLeftAligned/assetLeftAligned.component';
import ProductVariants from './../productVariant/productVariant.component';
import TileLayouts from './components/tileLayouts/tileLayouts.component';
import ProductTranslations from './components/productTranslations/productTranslations.container';

import { Input, InputCollectionSelect, Tabs, Button, Toggle } from 'dumb';

import { setProduct, updateProduct, resetState } from './store/product.actions';

import {
	fetchProducts,
	editProduct,
	editProducts,
	addProducts,
	deleteProducts,
} from './product.service';

import _get from 'lodash/get';
import moment from 'moment';
import phrases from './product.phrases';
class Product extends Component {
	constructor(props) {
		super(props);

		this.state = {
			key: moment(),
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.reloadProductTable = this.reloadProductTable.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.editEntry = this.editEntry.bind(this);

		this.columns = getProductColumns(this.props.assetCollectionId);
	}

	addEntry() {
		const { defaultProduct, assetCollectionId } = this.props;

		const payload = {
			name: defaultProduct.name,
			product_category: _get(defaultProduct, 'product_category.value.id', null),
			...((defaultProduct.asset_collection || assetCollectionId) && {
				asset_collection:
					_get(defaultProduct, 'asset_collection.value', false) ||
					assetCollectionId,
			}),
			...(defaultProduct.flair && { flair: defaultProduct.flair.value }),
			...(defaultProduct.product_area && {
				production_area: defaultProduct.production_area.value,
			}),
		};

		return addProducts(payload);
	}

	editEntry(data) {
		const { defaultProduct, assetCollectionId } = this.props;

		const payload = data || {
			id: defaultProduct.id,
			name: defaultProduct.name,
			...((defaultProduct.asset_collection || assetCollectionId) && {
				asset_collection:
					_get(defaultProduct, 'asset_collection.value', false) ||
					assetCollectionId,
			}),
			...(defaultProduct.production_area && {
				production_area: defaultProduct.production_area.value,
			}),
			...(defaultProduct.flair && {
				flair: defaultProduct.flair.value,
			}),
			legacy: !!defaultProduct.legacy,
		};

		return editProduct(payload);
	}

	editMultiple(selectedRows) {
		const { defaultProduct } = this.props;

		const payload = {
			...(defaultProduct.name && {
				name: defaultProduct.name,
			}),
			...(defaultProduct.asset_collection && {
				asset_collection: defaultProduct.asset_collection.value,
			}),
			...(defaultProduct.production_area && {
				production_area: defaultProduct.production_area.value,
			}),
			...(defaultProduct.flair && {
				flair: defaultProduct.flair.value,
			}),
			legacy: defaultProduct.legacy ?? undefined,
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editProducts({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteProducts(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			name: data.name,
			product_category: _get(data, 'product_category.name', ''),
			legacy: data.legacy,
			...(data.asset_collection && {
				asset_collection: {
					label: _get(data, 'asset_collection.name', ''),
					value: data.asset_collection.id,
				},
			}),
			...(data.production_area && {
				production_area: {
					label: _get(data, 'production_area.name', ''),
					value: data.production_area.id,
				},
			}),
			...(data.flair && {
				flair: {
					label: _get(data, 'flair.name', ''),
					value: data.flair.id,
				},
			}),
			editMode: true,
		};

		this.props.setProduct(payload);
	}

	getEditableCells() {
		const { defaultProduct, assetCollectionId } = this.props;

		return [
			{
				header: 'Product name',
				value: (
					<Input
						id="name"
						required
						placeholder="Name"
						value={_get(defaultProduct, 'name', '')}
						onChange={(e) => this.editStoreEntry(e.target.value, 'name')}
					/>
				),
			},
			{
				header: 'Product category',
				value: defaultProduct.editMode ? (
					<span>{defaultProduct.product_category}</span>
				) : (
					<InputCollectionSelect
						id="product_category"
						placeholder="Select category"
						value={defaultProduct.product_category}
						handleChange={(key, value) => {
							this.editStoreEntry(value, 'product_category');
						}}
						required
						cache
						apiPath="/product/product_categories"
						params={{
							limit: 30,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						tableTitle="Product categories"
						tableColumns={[
							{
								Header: 'Name',
								id: 'name',
								accessor: 'name',
								filterPath: ':name',
							},
							{
								Header: 'Category group',
								id: 'cateogryGroup',
								accessor: 'category_group',
								filterPath: ':category_group',
							},
							{
								Header: 'Legacy',
								id: 'legacy',
								accessor: 'legacy',
								filterPath: ':legacy',
								Cell: (d) => {
									return (
										<Input
											type="checkbox"
											checked={_get(d, 'original.legacy', false)}
											disabled
										/>
									);
								},
							},
						]}
						tableReduxKey="/product/product_categories-add/edit_modal"
					/>
				),
			},
			...(assetCollectionId
				? []
				: [
						{
							header: 'Asset Collection',
							value: (
								<InputCollectionSelect
									id="asset_collection"
									placeholder="Select asset collection"
									value={defaultProduct.asset_collection}
									handleChange={(key, value) => {
										this.editStoreEntry(value, 'asset_collection');
									}}
									clearable
									cache
									apiPath="/pos/asset_collections"
									params={{
										limit: 30,
									}}
									optionFormat={(entry) => ({
										value: entry.id,
										label: entry.name,
									})}
									inputFilterFormat={(input) => `:name=like='%${input}%'`}
								/>
							),
						},
				  ]),
			{
				header: 'Production Areas',
				value: (
					<InputCollectionSelect
						id="production_area"
						placeholder="Select Production Area"
						value={defaultProduct.production_area}
						handleChange={(key, value) => {
							this.editStoreEntry(value, 'production_area');
						}}
						clearable
						cache
						apiPath="/product/production_areas"
						params={{
							limit: 30,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Flair',
				value: (
					<InputCollectionSelect
						id="flair"
						placeholder="Select flair"
						value={defaultProduct.flair}
						handleChange={(key, value) => {
							this.editStoreEntry(value, 'flair');
						}}
						clearable
						cache
						apiPath="/pos/flairs"
						params={{
							limit: 30,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Legacy',
				value: (
					<Toggle
						id="legacy"
						toggled={!!defaultProduct.legacy}
						onClick={(value) => {
							this.editStoreEntry(value, 'legacy');
						}}
					/>
				),
			},
		];
	}

	editStoreEntry(e, type) {
		this.props.updateProduct({
			[type]: e,
		});
	}

	reloadProductTable() {
		this.setState(() => ({ key: moment() }));
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}>
				Legacy
			</Button>
		);
	}

	fetchData(state) {
		const { assetCollectionId } = this.props;

		const filter = assetCollectionId
			? `:asset_collection.id=='${assetCollectionId}'`
			: null;

		return fetchProducts(state, filter);
	}

	render() {
		const { setActiveTab } = this.props;

		const legacyFilter = this.state.legacyFilterOn
			? this.state.legacyFilter
			: '';

		return (
			<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
				key={this.state.key}
				title={phrases.PRODUCT}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={10}
				reduxKey="product/products"
				manual
				createEntry={this.addEntry}
				editEntry={this.editEntry}
				editMultiple={this.editMultiple}
				deleteEntry={this.deleteEntry}
				editableCells={this.getEditableCells()}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetState}
				showSearch
				subcomponent={(row) => {
					const tabContent = [
						{
							name: phrases.ASSETS,
							component: (
								<AssetLeftAligned
									reduxKey={`product/filter-${row.original.id}-pos/assets/${
										row.original.asset_collection?.id || ''
									}-aligned`}
									productId={row.original.id}
									editProduct={this.editEntry}
									assetCollectionId={row.original.asset_collection?.id}
									reloadProductTable={this.reloadProductTable}
								/>
							),
						},
						{
							name: phrases.TILE_LAYOUTS,
							component: (
								<TileLayouts
									product={row.original}
									setActiveTabSalesConfiguration={setActiveTab}
								/>
							),
						},
						{
							name: phrases.PRODUCT_VARIANTS,
							component: <ProductVariants product={row.original} />,
						},
						{
							name: phrases.TRANSLATIONS,
							component: <ProductTranslations product={row.original} />,
						},
					];

					return (
						<SubTable>
							<Tabs tabContent={tabContent} />
						</SubTable>
					);
				}}
				customAreaComponents={this.getLegacyFilterButton()}
				extraFilters={legacyFilter}
			/>
		);
	}
}

Product.propTypes = {
	defaultProduct: PropTypes.object,
	assetCollectionId: PropTypes.number,

	updateProduct: PropTypes.func,
	setProduct: PropTypes.func,
	resetState: PropTypes.func,
	setActiveTab: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setProduct,
			updateProduct,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultProduct: store.product.data.defaultProduct,
	};
};

export default connectWithStore(
	Product,
	mapStateToProps,
	mapDispatchToProps
);
