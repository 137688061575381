export default {
	TITLE: 'Entries',
	WAITING_TIME_SCHEDULE_ENTRY_ADDED: 'Entry added!',
	WAITING_TIME_SCHEDULE_ENTRY_EDITED: 'Entry edited!',
	WAITING_TIME_SCHEDULE_ENTRIES_EDITED: 'Entries edited!',
	WAITING_TIME_SCHEDULE_ENTRY_DELETED: 'Entry deleted!',

	// batch form
	MODAL_BATCH_STEP_LIST_TITLE: 'Create entries',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_PRODUCTS: 'No. of Products',
	MODAL_BATCH_STEP_PRODUCTS_PLACEHOLDER: 'Enter no. of products...',
	MODAL_BATCH_STEP_MINUTES: 'Minutes',
	MODAL_BATCH_STEP_MINUTES_PLACEHOLDER: 'Enter no. of minutes...',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add entries',
};
