export const SET_CLOSED_DAYS = 'SET_CLOSED_DAYS/SET_CLOSED_DAYS';
export const UPDATE_CLOSED_DAYS = 'SET_CLOSED_DAYS/UPDATE_CLOSED_DAYS';

/**
 * @function setClosedDays
 * @param {object} closedDays
 */
export function setClosedDays(closedDays) {
	
	return {
		type: SET_CLOSED_DAYS,
		payload: {closedDays}
	};
}

/**
 * @function setClosedDays
 * @param {object} closedDay
 */
export function updateClosedDays(closedDay) {
	const key = closedDay.date;
	return {
		type: UPDATE_CLOSED_DAYS,
		payload: {
			key,
			closedDay
		}
	};
}
