import {
	SET_ASSET_COLLECTION,
	UPDATE_ASSET_COLLECTION,
	RESET_ASSET_COLLECTION,
} from './assetCollection.actions';

const defaultState = {
	ui: {},
	data: {
		defaultAssetCollections: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_ASSET_COLLECTION: {
			return {
				...state,
				data: {
					...state.data,
					defaultAssetCollections: payload,
				},
			};
		}

		case UPDATE_ASSET_COLLECTION: {
			return {
				...state,
				data: {
					...state.data,
					defaultAssetCollections: {
						...state.data.defaultAssetCollections,
						...payload,
					},
				},
			};
		}

		case RESET_ASSET_COLLECTION: {
			return {
				...state,
				data: {
					...state.data,
					defaultAssetCollections: {},
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
