import { SET_FILTER, RESET_FILTER } from './transactionsLookup.actions';

const defaultState = {
	filter: {},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_FILTER:
			return {
				...state,
				filter: {
					...state.filter,
					...payload,
				},
			};

		case RESET_FILTER:
			return defaultState;

		default:
			return state;
	}
}

export default reducer;
