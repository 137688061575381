import React from 'react';
import PropTypes from 'prop-types';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setUiData } from '../store/tenantTransferModal.actions';

// components
import { InputCollectionSelect, SingleDatePickerInput, Input } from 'dumb';
import { ModalRow } from 'dumb/modal';
import { Tip } from 'dumb/tips';

// utils
import moment from 'moment';
import constants from 'services/constants';
import { getUserOptions } from './../utils';
import { getSelectOptions } from 'services/utils';

// phrases
import phrases from './../tenantTransferModal.phrases';

const TenantTransferModalStepTwo = ({ tenantOptions, employment }) => {
	const dispatch = useDispatch();
	const {
		user,
		company,
		startDate,
		endDate,
		hasFoodPermit,
		inSalaryExport,
		salaryExportId,
		administrativeOnly,
		phone,
		email,
		terminateCurrentEmployment,
	} = useSelector((store) => store.tenantTransfer.ui);

	const modalDisabled = !user;
	const userSelectOptions = getUserOptions(tenantOptions.users ?? []);
	const employmentText = `${
		employment?.current_employment_position?.moneyball_sub_position?.moneyball_position?.name ?? ''
	} at ${employment?.company?.name ?? ''}`;
	const terminationExists = !!employment?.termination?.id ?? false;

	return (
		<>
			<ModalRow>
				<InputCollectionSelect
					id="user-select"
					label={phrases.STEP_TWO_USERS_LABEL}
					placeholder={phrases.STEP_TWO_USERS_PLACEHOLDER}
					value={user}
					handleChange={(key, value) => dispatch(setUiData({ user: value }))}
					cache
					options={userSelectOptions}
					optionFormat={(entry) => ({
						value: entry,
						label: entry.name,
					})}
					inputFilterFormat={(input) => `:name=like='%${input}%'`}
				/>
			</ModalRow>

			{/* elements bellow are disabled before we select a user */}
			<ModalRow>
				<InputCollectionSelect
					id="company-select"
					label={phrases.STEP_TWO_COMPANY_LABEL}
					placeholder={phrases.STEP_TWO_COMPANY_PLACEHOLDER}
					value={company}
					handleChange={(key, value) => dispatch(setUiData({ company: value }))}
					cache
					options={getSelectOptions(tenantOptions.companies ?? [])}
					optionFormat={(entry) => ({
						value: entry,
						label: entry.name,
					})}
					inputFilterFormat={(input) => `:name=like='%${input}%'`}
					disabled={modalDisabled}
				/>
			</ModalRow>

			<ModalRow>
				<SingleDatePickerInput
					id="start-date"
					numberOfMonths={1}
					label={phrases.STEP_TWO_START_DATE_LABEL}
					placeholder={phrases.STEP_TWO_START_DATE_PLACEHOLDER}
					onChange={(date) => dispatch(setUiData({ startDate: date }))}
					selectedDate={moment.utc(startDate).format(constants.shortDate)}
					appendToBody
					noClockButton
					disabled={modalDisabled}
				/>
			</ModalRow>

			<ModalRow>
				<SingleDatePickerInput
					id="end-date"
					numberOfMonths={1}
					label={phrases.STEP_TWO_END_DATE_LABEL}
					placeholder={phrases.STEP_TWO_END_DATE_PLACEHOLDER}
					onChange={(date) => dispatch(setUiData({ endDate: date }))}
					selectedDate={moment.utc(endDate).format(constants.shortDate)}
					appendToBody
					noClockButton
					disabled={modalDisabled}
				/>
			</ModalRow>

			<ModalRow>
				<Input
					id="has-food-permit"
					label={phrases.STEP_TWO_HAS_FOOD_PERMIT_LABEL}
					type="checkbox"
					checked={hasFoodPermit}
					onChange={(event) => dispatch(setUiData({ hasFoodPermit: event.target.checked }))}
					disabled={modalDisabled}
				/>
			</ModalRow>

			<ModalRow>
				<Input
					id="in-salary-export"
					label={phrases.STEP_TWO_IN_SALARY_EXPORT_LABEL}
					type="checkbox"
					checked={inSalaryExport}
					onChange={(event) => dispatch(setUiData({ inSalaryExport: event.target.checked }))}
					disabled={modalDisabled}
				/>
			</ModalRow>

			<ModalRow>
				<Input
					id="administrative-only"
					label={phrases.STEP_TWO_ADMINISTRATIVE_ONLY_LABEL}
					type="checkbox"
					checked={administrativeOnly}
					onChange={(event) => dispatch(setUiData({ administrativeOnly: event.target.checked }))}
					disabled={modalDisabled}
				/>
			</ModalRow>

			<ModalRow>
				<Input
					id="salary-export-id"
					label={phrases.STEP_TWO_SALARY_EXPORT_ID_LABEL}
					placeholder={phrases.STEP_TWO_SALARY_EXPORT_ID_PLACEHOLDER}
					type="string"
					value={salaryExportId}
					onChange={(event) => dispatch(setUiData({ salaryExportId: event.target.value }))}
					disabled={modalDisabled}
				/>
			</ModalRow>

			<ModalRow>
				<Input
					id="email"
					label={phrases.STEP_TWO_EMAIL_LABEL}
					placeholder={phrases.STEP_TWO_EMAIL_PLACEHOLDER}
					type="string"
					value={email}
					onChange={(event) => dispatch(setUiData({ email: event.target.value }))}
					disabled={modalDisabled}
				/>
			</ModalRow>

			<ModalRow>
				<Input
					id="phone"
					type="number"
					label={phrases.STEP_TWO_PHONE_LABEL}
					placeholder={phrases.STEP_TWO_PHONE_PLACEHOLDER}
					value={phone}
					onChange={(event) => dispatch(setUiData({ phone: event.target.value }))}
					disabled={modalDisabled}
				/>
			</ModalRow>

			{!terminationExists && (
				<>
					<ModalRow className="tenant-transfer-modal__terminate-tenant-section">
						<Tip content={employmentText} />
					</ModalRow>

					{/* we add a bit of spacing under this section for importance */}
					<ModalRow>
						<Input
							id="terminate-current-employment"
							label={phrases.TERMINATE_CURRENT_EMPLOYMENT}
							type="checkbox"
							checked={terminateCurrentEmployment}
							onChange={(event) => dispatch(setUiData({ terminateCurrentEmployment: event.target.checked }))}
							disabled={modalDisabled}
						/>
					</ModalRow>
				</>
			)}
		</>
	);
};

TenantTransferModalStepTwo.propTypes = {
	tenantOptions: PropTypes.object,
	employment: PropTypes.object,
};

export default TenantTransferModalStepTwo;
