export const SET_WARNINGS = 'SHIFTS/SET_WARNINGS';
export const UPDATE_WARNINGS = 'SHIFTS/UPDATE_WARNINGS';
export const REMOVE_WARNINGS_FOR_PERIOD = 'SHIFTS/REMOVE_WARNINGS_FOR_PERIOD';

export function setWarnings(payload) {
	return {
		type: SET_WARNINGS,
		payload,
	};
}

export function updateWarnings(payload) {
	return {
		type: UPDATE_WARNINGS,
		payload,
	};
}

export function removeWarningsForPeriod(payload) {
	return {
		type: REMOVE_WARNINGS_FOR_PERIOD,
		payload,
	};
}
