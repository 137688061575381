import {
	UPDATE_POS_LIST_ROW,
	SET_POS_LISTS,
	RESET_POS_LISTS,
	UPDATE_BATCH_FORM,
	RESET_BATCH,
	BATCH_LIST_ADD,
	BATCH_LIST_EDIT_ITEM,
	EDIT_DUPLICATE_LAYOUT_LIST_ITEM,
	BATCH_LIST_REMOVE_ITEM,
	REMOVE_DUPLICATE_LAYOUT_LIST_ITEM,
	UPDATE_TILE_LAYOUT_TILE_POSITION,
	MARK_GROUP_AS_SUBMITTED,
} from './tileLayout.actions';

const defaultState = {
	data: {
		tileLayoutRow: {},
		batchForm: {
			tile_layouts: [],
		},
		batchList: [],
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_POS_LISTS: {
		return {
			...state,
			data: {
				...state.data,
				tileLayoutRow: payload,
			},
		};
	}

	case UPDATE_POS_LIST_ROW: {
		return {
			...state,
			data: {
				...state.data,
				tileLayoutRow: {
					...state.data.tileLayoutRow,
					...payload,
				},
			},
		};
	}

	case RESET_POS_LISTS: {
		return {
			...state,
			data: {
				...state.data,
				tileLayoutRow: {},
				batchForm: {},
			},
		};
	}

	case UPDATE_BATCH_FORM: {
		return {
			...state,
			data: {
				...state.data,
				batchForm: {
					...state.data.batchForm,
					...payload,
				},
			},
		};
	}

	case BATCH_LIST_ADD: {
		return {
			...state,
			data: {
				...state.data,
				batchList: payload,
			},
		};
	}

	case RESET_BATCH: {
		return {
			...state,
			data: {
				...state.data,
				batchOptions: defaultState.data.batchOptions,
				batchList: defaultState.data.batchList,
			},
		};
	}

	case BATCH_LIST_EDIT_ITEM: {
		const batchList = state.data.batchList.map((x) => {
			if (x.identifier === payload.identifier) {
				return { ...x, ...payload };
			} else {
				return x;
			}
		});

		return {
			...state,
			data: {
				...state.data,
				batchList,
			},
		};
	}

	case EDIT_DUPLICATE_LAYOUT_LIST_ITEM: {
		const tileLayouts = state.data.batchForm.tile_layouts.map((x) => {
			if (x.value.id === payload.id) {
				return {
					...x,
					value: {
						...x.value,
						...payload,
					},
				};
			} else {
				return x;
			}
		});

		return {
			...state,
			data: {
				...state.data,
				batchForm: {
					...state.data.batchForm,
					tile_layouts: tileLayouts,
				},
			},
		};
	}

	case BATCH_LIST_REMOVE_ITEM: {
		const batchList = state.data.batchList.filter(
			(x) => x.identifier !== payload
		);

		return {
			...state,
			data: {
				...state.data,
				batchList: batchList,
			},
		};
	}
	case REMOVE_DUPLICATE_LAYOUT_LIST_ITEM: {
		const tileLayouts = state.data.batchForm.tile_layouts.filter(
			(x) => x.value.id !== payload
		);

		return {
			...state,
			data: {
				...state.data,
				batchForm: {
					...state.data.batchForm,
					tile_layouts: tileLayouts,
				},
			},
		};
	}

	case UPDATE_TILE_LAYOUT_TILE_POSITION: {
		const { dragIndex, hoverIndex } = payload;

		let shownTileLayoutTilesCopy = [
			...state.data.batchForm.shownTileLayoutTiles,
		];

		// swap the elements in question
		[
			shownTileLayoutTilesCopy[dragIndex],
			shownTileLayoutTilesCopy[hoverIndex],
		] = [
			shownTileLayoutTilesCopy[hoverIndex],
			shownTileLayoutTilesCopy[dragIndex],
		];

		// set new positions
		shownTileLayoutTilesCopy = shownTileLayoutTilesCopy.map(
			(entry, index) => ({ ...entry, position: index + 1 })
		);

		return {
			...state,
			data: {
				...state.data,
				batchForm: {
					...state.data.batchForm,
					shownTileLayoutTiles: shownTileLayoutTilesCopy,
				},
			},
		};
	}

	case MARK_GROUP_AS_SUBMITTED: {
		return {
			...state,
			data: {
				...state.data,
				batchForm: {
					...state.data.batchForm,
					uniqueTileLayoutGroups: state.data.batchForm.uniqueTileLayoutGroups.map(
						(entry) => {
							if (entry.name === payload.name)
								return {
									...entry,
									submitted: true,
								};

							return entry;
						}
					),
				},
			},
		};
	}

	default:
		return state;
	}
}

export default reducer;
