import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './tag.css';

export default class Tag extends PureComponent {
	render() {
		const {
			children,
			className,
			onClick,
			color,
			active,
			type,
			refProp,
			onMouseEnter,
			onMouseLeave,
			noSpacing,
			noMargin,
		} = this.props;

		const classN = classnames('j-tag', {
			[`${className}`]: className,
			'j-tag--active': active,
			[`j-tag--${type}`]: type,
			'j-tag--no-click': !onClick,
			'j-tag--no-spacing': noSpacing,
			'j-tag--no-margin': noMargin,
		});

		const style = {
			backgroundColor: type ? null : `#${color}`,
		};

		return (
			<>
				{onClick ? (
					<button
						style={style}
						onClick={onClick}
						className={classN}
						ref={refProp}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}>
						{type === 'bullet' && (
							<span
								className="j-tag__circle"
								style={{ backgroundColor: `#${color}` }}
							/>
						)}
						{children}
					</button>
				) : (
					<div
						style={style}
						onClick={onClick}
						className={classN}
						ref={refProp}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}>
						{type === 'bullet' && (
							<span
								className="j-tag__circle"
								style={{ backgroundColor: `#${color}` }}
							/>
						)}
						{children}
					</div>
				)}
			</>
		);
	}
}

Tag.defaultProps = {
	type: '',
};

Tag.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	className: PropTypes.string,
	type: PropTypes.string,
	onClick: PropTypes.func,
	color: PropTypes.string,
	active: PropTypes.bool,
	refProp: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	noSpacing: PropTypes.bool,
	noMargin: PropTypes.bool,
};
