export const RESET_STATE = 'DOCUSIGN_TEMPLATES/RESET_STATE';
export const SET_DOCUSIGN_TEMPLATE = 'DOCUSIGN_TEMPLATES/SET_DOCUSIGN_TEMPLATE';
export const UPDATE_DOCUSIGN_TEMPLATE =
	'DOCUSIGN_TEMPLATES/UPDATE_DOCUSIGN_TEMPLATE';

// configure docu sign actions
export const SET_CONFIGURE_DOCUSIGN_DATA =
	'DOCUSIGN_TEMPLATES/SET_CONFIGURE_DOCUSIGN_DATA';

export function setDocuSignTemplate(payload) {
	return {
		type: SET_DOCUSIGN_TEMPLATE,
		payload,
	};
}

export function updateDocuSisnTemplate(payload) {
	return {
		type: UPDATE_DOCUSIGN_TEMPLATE,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function setConfigureDocuSignData(payload) {
	return {
		type: SET_CONFIGURE_DOCUSIGN_DATA,
		payload,
	};
}
