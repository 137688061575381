'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';

// batch view
import ProductBundlesBatchStepList from './components/productsBundlesBatchStepList';

// phrases
import phrases from './../../productBundles.phrases';

import './productBundlesModalBatch.css';

class ProductBundlesModalBatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false
		};
	}

	_batchSubmit() {
		const { batchList, handleClose, addProductBundle } = this.props;

		this.setState(() => ({
			loadingModal: true
		}));

		const payload = batchList.map(entry => {
			return {
				name: entry.name,
				description: entry.description,
				...(entry.sortOrder && { sort_order: entry.sortOrder })
			};
		});

		addProductBundle({ batch: payload })
			.then(() => {
				handleClose();
				this.setState(() => ({
					loadingModal: false
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false
				}));
			});
	}

	render() {
		const {
			modalVisible,
			handleClose,
			batchList,
			editBatchListItem,
			removeBatchListItem,
			addBatchListItemRow
		} = this.props;

		const steps = [
			{
				component: (
					<ProductBundlesBatchStepList
						batchList={batchList}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
						productRecommendationUrl={this.productRecommendationUrl}
						tileLayoutUrl={this.tileLayoutUrl}
						ingredientConfigurationUrl={this.ingredientConfigurationUrl}
						addBatchListItemRow={addBatchListItemRow}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false
			}
		];

		return modalVisible ? (
			<ModalStepper
				isOpen={modalVisible}
				steps={steps}
				showStep={false}
				onClose={handleClose}
			/>
		) : null;
	}
}

ProductBundlesModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	addProductBundle: PropTypes.func
};

export default ProductBundlesModalBatch;
