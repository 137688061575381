'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import phrases from './importCsvModal.phrases';
import ImportCsvModal from './importCsvModal.component';
import { Icon }  from 'dumb';

class ImportCsvModalContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		this.phrases = phrases;

		this.state = {
			csvImportError: false,
			csvImportSuccess: false,
			importingCsv: false,
			showModal: false
		};

		this.onChange = this.onChange.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
	}

	onChange(file, fileName) {
		const { config } = this.props;

		config.handleSetCsvProperties(file, fileName);

		return config.handleOnChange();
	}

	toggleModal() {
		this.setState(prevState => ({ showModal: !prevState.showModal }));
	}

	render() {
		const { config } = this.props;

		return (
			<a onClick={this.toggleModal} key={config.name} data-label={config.name}>
				<Icon name="file_upload" />

				<ImportCsvModal
					importing={this.state.importingCsv}
					translations={this.phrases}
					importError={this.state.csvImportError}
					importSuccess={this.state.csvImportSuccess}
					handleClose={this.toggleModal}
					onChange={this.onChange}
					example={config.getExample()}
					visible={this.state.showModal}
				/>
			</a>
		);
	}
}

ImportCsvModalContainer.propTypes = {
	config: PropTypes.object.isRequired
};

export default ImportCsvModalContainer;
