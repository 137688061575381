/**
 *
 * @function getIsPasswordValid
 * @description send password string and retrieve error message
 * @param {string} password
 * @export
 * @returns {string, undefined} Error message or undefined if correct
 */
export function getIsPasswordValid(password, passwordRepeat = null) {
	const lowerCaseR = /[a-z]/g;
	const upperCaseR = /[A-Z]/g;
	const numberCaseR = /[0-9]/g;
	const specialCharacterR = /[^A-z\s\d][\\\^]?/g;

	const lenghtCase = password.length > 9;
	const lowerCase = !!password.match(lowerCaseR);
	const upperCase = !!password.match(upperCaseR);
	const numberCase = !!password.match(numberCaseR);
	const specialCharacterCase = !!password.match(specialCharacterR);

	const validateCase = {
		lenghtCase,
		lowerCase,
		upperCase,
		numberCase,
		specialCharacterCase,
		...(passwordRepeat && {
			passwordsMatch: passwordRepeat === password,
		}),
	};

	return validateCase;
}
