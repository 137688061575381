import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ListComponent from 'common/components/list/list.component';
import { useParams } from 'react-router-dom';

import MarketOnboardingsList from './services/marketOnboardingsList.service.js';
import MarketTaxRatesList from './services/marketTaxRatesList.service.js';
import MarketEmployeeContributionList from './services/marketEmployeeContributionsList.service.js';
import MarketShiftRules from './components/organisationMarketShiftRules/organisationMarketShiftRules.container.jsx';
import MarketPayrollPeriods from './components/organisationMarketPayrollPeriods/organisationMarketPayrollPeriods.container.jsx';
import MarketTags from './../../globalAdministration/tags/components/marketTags/marketTags.container';

const marketTaxRatesListReduxKey = '/administration/market_tax_rates';
const marketTaxRatesList = new MarketTaxRatesList(marketTaxRatesListReduxKey);
const marketTaxRatesListProps = marketTaxRatesList.getProps();

const marketOnboardingListReduxKey = '/hr/market_onboardings';
const marketOnboardingList = new MarketOnboardingsList(
	marketOnboardingListReduxKey
);
const marketOnboardingListProps = marketOnboardingList.getProps();

const marketEmployeeContributionListReduxKey = '/salary/employee_contributions';
const marketEmployeeContributionList = new MarketEmployeeContributionList(
	marketEmployeeContributionListReduxKey
);
const marketEmployeeContributionListProps =
	marketEmployeeContributionList.getProps();

export const MarketDetailsComponent = () => {
	const { marketId } = useParams();
	const marketIdFilter = `:market.id=='${marketId}'`;

	useEffect(() => {
		marketTaxRatesList.setFilterQuery(marketIdFilter);
		marketTaxRatesList.setData({ market: marketId });
		marketTaxRatesList.initView();

		marketOnboardingList.setFilterQuery(marketIdFilter);
		marketOnboardingList.setData({ market: marketId });
		marketOnboardingList.initView();

		marketEmployeeContributionList.setFilterQuery(marketIdFilter);
		marketEmployeeContributionList.setData({ market: marketId });
		marketEmployeeContributionList.initView();
	}, [marketId, marketIdFilter]);

	return (
		<>
			<ListComponent
				reduxKey={marketTaxRatesListReduxKey}
				{...marketTaxRatesListProps}
			/>

			<ListComponent
				reduxKey={marketOnboardingListReduxKey}
				{...marketOnboardingListProps}
			/>

			<ListComponent
				reduxKey={marketEmployeeContributionListReduxKey}
				{...marketEmployeeContributionListProps}
			/>

			<MarketTags marketId={marketId} />

			<MarketShiftRules marketId={marketId} />

			<MarketPayrollPeriods marketId={marketId} />
		</>
	);
};

MarketDetailsComponent.propTypes = {
	marketId: PropTypes.number,
};
