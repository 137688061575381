import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';

import ProductsList from './services/productsList.service.js';

const productsListReduxKey = '/product/products';
const productsList = new ProductsList(productsListReduxKey);
const productsListProps = productsList.getProps();

export function Products() {
	useEffect(() => {
		productsList.initView();
	}, []);

	return <ListComponent reduxKey={productsListReduxKey} {...productsListProps} />;
}
