'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { InputCollectionSelect } from 'dumb';

// redux/services
import {
	fetchAddonIngredients,
	deleteAddonIngredient,
	addAddonIngredient,
	editAddonIngredient
} from './addonIngredient.service';
import {
	resetState,
	setAddonIngredient,
	updateAddonIngredient
} from './store/addonIngredient.actions';

// phrases/utils
import phrases from './../productAddonRecommendations.phrases';
import _get from 'lodash/get';

class AddonIngredients extends Component {
	constructor(props) {
		super(props);

		this.fetchData = this.fetchData.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'addonIngredientName',
				accessor: d => _get(d, 'addon_ingredient.addon.name', ''),
				filterPath: ':addon_ingredient.addon.name'
			},
			{
				Header: 'Ingredient',
				id: 'IngredientName',
				accessor: d => _get(d, 'addon_ingredient.ingredient.name', ''),
				filterPath: ':Ingredient.ingredient.name'
			}
		];
	}

	addEntry() {
		const { productAddonRecommendationId, addonIngredient } = this.props;

		const payload = {
			addon_ingredient: _get(addonIngredient, 'addonIngredient.value', null),
			addon_recommendation: productAddonRecommendationId
		};

		return addAddonIngredient(payload);
	}

	editEntry() {
		const { addonIngredient } = this.props;

		const payload = {
			id: addonIngredient.id,
			addon_ingredient: addonIngredient.addonIngredient.value
		};

		return editAddonIngredient(payload);
	}

	deleteEntry(id) {
		return deleteAddonIngredient(id);
	}

	setInitialEditValues(data) {
		const { setAddonIngredient } = this.props;

		const payload = {
			id: data.id,
			addonIngredient: {
				value: _get(data, 'addon_ingredient.id', null),
				label: _get(data, 'addon_ingredient.name', null)
			}
		};

		setAddonIngredient(payload);
	}

	getEditableCells() {
		const { addonIngredient, addonId } = this.props;

		return [
			{
				header: 'Ingredient',
				value: (
					<InputCollectionSelect
						id="addonIngredient"
						placeholder="Select ingredient"
						value={addonIngredient.addonIngredient || null}
						handleChange={(name, event) => this.editStoreEntry(name, event)}
						clearable
						cache
						apiPath="/product/addon_ingredients"
						params={{
							limit: 30,
							filter: `:addon.id=='${addonId}'`
						}}
						optionFormat={entry => ({
							value: entry.id,
							label: entry.ingredient.name
						})}
						inputFilterFormat={input => `:ingredient.name=like='%${input}%'`}
					/>
				)
			}
		];
	}

	editStoreEntry(name, event) {
		const { updateAddonIngredient } = this.props;

		const payload = {
			[name]: event
		};

		updateAddonIngredient(payload);
	}

	fetchData(state) {
		return fetchAddonIngredients(state);
	}

	render() {
		const { productAddonRecommendationId, resetState } = this.props;

		return (
			<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.TABLE_TITLE_INNER}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={10}
				reduxKey="/product/addon_recommendation_addon_ingredients"
				extraFilters={`:addon_recommendation.id=='${productAddonRecommendationId}'`}
				manual
				editableCells={this.getEditableCells()}
				actionsWidth={60}
				createEntry={this.addEntry}
				editEntry={this.editEntry}
				deleteEntry={this.deleteEntry}
				editMultiple={this.editMultiple}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={resetState}
				
			/>
		);
	}
}

AddonIngredients.propTypes = {
	addonIngredient: PropTypes.object,
	productAddonRecommendationId: PropTypes.number.isRequired,
	addonId: PropTypes.number.isRequired,
	resetState: PropTypes.func,
	setAddonIngredient: PropTypes.func,
	updateAddonIngredient: PropTypes.func
};

const mapStateToProps = store => {
	return {
		addonIngredient:
			store.salesConfigurationAddonRecommendationsIngredients.data
				.addonIngredient
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			resetState,
			setAddonIngredient,
			updateAddonIngredient
		},
		dispatch
	);
};

export default connectWithStore(
	AddonIngredients,
	mapStateToProps,
	mapDispatchToProps
);
