'use strict';

import { get, post, remove, patch } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './posConfigurationIngredientPricingGroups.phrases';
import { fetchCompleteCollection } from 'api/fetchCompleteCollection';

/**
 * @function fetchPosConfigurationIngredientPricingGroups
 * @description Fetch all ingredient categories
 * @param {object} state
 */
export function fetchPosConfigurationIngredientPricingGroups(
	state,
	shouldFetchCompleteCollection = false
) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	const request = shouldFetchCompleteCollection
		? fetchCompleteCollection(
			'/pos/pos_configuration_ingredient_pricing_groups',
			params
		  )
		: get(
			'/pos/pos_configuration_ingredient_pricing_groups',
			params,
			null,
			state.headers
		  );

	return Promise.resolve(request)
		.then(res => {
			res = shouldFetchCompleteCollection
				? {
					data: res
				  }
				: res;

			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function addPosConfigurationIngredientPricingGroup
 * @description add
 */
export function addPosConfigurationIngredientPricingGroup(data) {
	return post('/pos/pos_configuration_ingredient_pricing_groups', data)
		.then(res => {
			setFeedback(phrases.INGREDIENT_PRICING_GROUP_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editPosConfigurationIngredientPricingGroup
 * @description edit
 */
export function editPosConfigurationIngredientPricingGroup(data) {
	return post(
		`/pos/pos_configuration_ingredient_pricing_groups/${data.id}`,
		data
	)
		.then(res => {
			setFeedback(phrases.INGREDIENT_PRICING_GROUP_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function editPosConfigurationIngredientPricingGroup
 * @description edit
 */
export function batchEditPosConfigurationIngredientPricingGroup(data) {
	return patch('/pos/pos_configuration_ingredient_pricing_groups', data)
		.then(res => {
			setFeedback(phrases.INGREDIENT_PRICING_GROUPS_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function deletePosConfigurationIngredientPricingGroup
 * @description del
 */
export function deletePosConfigurationIngredientPricingGroup(productId) {
	return remove(`/pos/pos_configuration_ingredient_pricing_groups/${productId}`)
		.then(res => {
			setFeedback(phrases.INGREDIENT_PRICING_GROUP_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

export function addPosConfigurationIngredientPricingGroups(data) {
	return post('/pos/pos_configuration_ingredient_pricing_groups', data)
		.then(res => {
			setFeedback(phrases.INGREDIENT_PRICING_GROUPS_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
