import { get, post, remove } from 'api.vanilla.service';

// utils
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { saveAs } from 'file-saver';

// mics
import phrases from './markets.phrases';
import moment from 'moment';
import constants from 'services/constants';

/**
 * @function fetchMarkets
 * @description Fetch all markets
 * @param {object} state
 */
export function fetchMarkets(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/administration/markets', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchMarketTipsCsv
 * @description Fetch market tips in a CSV format
 * @param {object} state
 */
export function fetchMarketTipsCsv({ from, to, marketId }) {
	const params = {
		from,
		to,
	};

	const headers = {
		Accept: 'application/csv',
	};

	return get(`/salary/markets/${marketId}/tips`, params, null, headers)
		.then((res) => {
			const blob = new Blob([res], { type: 'text/csv;charset=utf-8' });
			const fileName = `salary-market-tips_${moment().format(
				constants.shortDate
			)}.csv`;
			saveAs(blob, fileName);
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editMarkets
 * @description edits markets
 */
export function editMarkets(data) {
	return post(`/administration/markets/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.MARKETS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function addMarktets
 * @description adds markets
 */
export function addMarktets(data) {
	return post('/administration/markets', data)
		.then((res) => {
			setFeedback(phrases.MARKETS_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteMarktets
 * @description deletes markets
 */
export function deleteMarktets(productId) {
	return remove(`/administration/markets/${productId}`)
		.then((res) => {
			setFeedback(phrases.MARKETS_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
