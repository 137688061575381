import { SelectOption } from 'types/select';
import { EntryTypeEnum, TypeEnum } from 'types/enums';

export function getEntryTypeSelectOptions(): SelectOption[] {
	return Object.keys(EntryTypeEnum).map((entry) => ({
		value: entry,
		label: entry,
	}));
}

export function getTypeSelectOptions(): SelectOption[] {
	return Object.values(TypeEnum).map((value) => ({
		value,
		label: value,
	}));
}

export function getEntryTypeLabel(
	entryType:
		| 'Turnover'
		| 'Difference'
		| 'MobilePayment'
		| 'CardPayment'
		| 'JoeAppDeliveryTurnover'
		| 'JoeAppDeliveryMobilePayment'
		| 'ForeignCurrency'
		| 'BeginCash'
		| 'EndCash'
		| 'DrawerToBank'
): string {
	const entryTypeValue = Object.values(EntryTypeEnum).indexOf(
		// super haram castings - only needed because we cannot reuse same enums for data model interfaces
		entryType as EntryTypeEnum
	);

	const entryTypeLabel = Object.keys(EntryTypeEnum)[entryTypeValue];

	return entryTypeLabel;
}
