import { get, post, remove, patch } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchIngredients
 * @description Fetch all productes values
 * @param {object} state
 */
export function fetchIngredients(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get(`/product/ingredients`, params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editIngredients
 * @description edit
 */
export function editIngredients(productId, data) {
	return post(`/product/ingredients/${productId}`, data)
		.then((res) => {
			setFeedback('ingredients edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editBatchIngredients
 * @description edit
 */
export function editBatchIngredients(data) {
	return patch(`/product/ingredients`, data)
		.then((res) => {
			setFeedback('ingredients edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function addIngredients
 * @description add
 */
export function addIngredients(data) {
	return post(`/product/ingredients`, data)
		.then((res) => {
			setFeedback('ingredients added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteIngredients
 * @description del
 */
export function deleteIngredients(productId) {
	return remove(`/product/ingredients/${productId}`)
		.then((res) => {
			setFeedback('ingredients deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function fetchIngredientCategories
 * @description Fetch all productes values
 * @param {object} state
 */
export function fetchIngredientCategories(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get(`/product/ingredient_categories`, params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchIngredientPricingGroups
 * @description Fetch all asset collections
 * @param {object} state
 */
export function fetchIngredientPricingGroups(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get(`/product/ingredient_pricing_groups`, params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}
