import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import AuthService from 'auth.service';
import _debounce from 'lodash/debounce';

// Actions

const getUserAccessRolesAndTenants = _debounce(
	(userId) => {
		AuthService.getUserAccessRoles(userId);
		AuthService.getUserTenants(userId);
	},
	50,
	{ trailing: true }
);

const AccessRightsProvider = ({ children }) => {
	const { accessToken } = useSelector((state) => ({
		accessToken: state?.userData?.accessToken,
	}));

	const { user } = useAuth0();
	const userId = user?.sub.split('|')[1];

	useEffect(() => {
		if (!userId || !accessToken) return;
		getUserAccessRolesAndTenants(userId);
	}, [userId, accessToken]);

	return <>{children}</>;
};

AccessRightsProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AccessRightsProvider;
