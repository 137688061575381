'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, ButtonGroup, InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';

import constants from 'services/constants';
import phrases from './../../../posConfigurationIngredientPricingVariation.phrases';

class IngredientVariationPricingStepForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};
	}

	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		const value = e.target ? e.target.value : e;

		updateBatchForm({
			[type]: value,
		});
	}

	render() {
		const {
			batchFormData,
			toggleOverlay,
			posConfigurationVersionReduxKey,
		} = this.props;

		return (
			<>
				<ModalRow>
					<ButtonGroup type="full-width">
						<InputCollectionSelect
							className="modal-batch-select"
							id="pos_configuration_version"
							label={phrases.MODAL_BATCH_STEP_POS_CONFIGURATION_VERSION}
							placeholder="Select configuration version"
							value={batchFormData.posConfigurationVersion}
							handleChange={(key, value) =>
								this.editStoreEntry(value, 'posConfigurationVersion')
							}
							multi
							cache
							apiPath="/pos/pos_configuration_versions"
							params={{
								limit: 50,
								filter: `:active.to=ge='${moment
									.utc()
									.format(constants.shortDate)}'`,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.pos_configuration.name,
							})}
							inputFilterFormat={(input) =>
								`:pos_configuration.name=like='%${input}%'`
							}
						/>

						<Button
							type="inverted"
							shadow
							size="large"
							onClick={() => toggleOverlay(posConfigurationVersionReduxKey)}>
							<span className="icon icon--more" />
						</Button>
					</ButtonGroup>
				</ModalRow>

				<ModalRow>
					<Input
						id="ingredientVariant"
						label={phrases.MODAL_BATCH_STEP_INGREDIENT_VARIANT}
						placeholder="Ingredient variant"
						onChange={(event) =>
							this.editStoreEntry(event, 'ingredientVariant')
						}
						value={batchFormData.ingredientVariant || ''}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						label={phrases.MODAL_BATCH_STEP_SORT_ORDER}
						id="sortOrder"
						type="number"
						placeholder="1234,56"
						onChange={(event) => this.editStoreEntry(event, 'sortOrder')}
						value={batchFormData.sortOrder || ''}
					/>
				</ModalRow>
			</>
		);
	}
}

IngredientVariationPricingStepForm.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
	posConfigurationVersionReduxKey: PropTypes.string,
};

export default IngredientVariationPricingStepForm;
