export const RESET_POS_LISTS =
	'SALES_CONFIGURATION_TILE_LAYOUTS/RESET_POS_LISTS';
export const SET_POS_LISTS = 'SALES_CONFIGURATION_TILE_LAYOUTS/SET_POS_LISTS';
export const UPDATE_POS_LIST_ROW =
	'SALES_CONFIGURATION_TILE_LAYOUTS/UPDATE_POS_LIST_ROW';

export const UPDATE_BATCH_FORM =
	'SALES_CONFIGURATION_TILE_LAYOUTS/UPDATE_BATCH_FORM';
export const RESET_BATCH = 'SALES_CONFIGURATION_TILE_LAYOUTS/RESET_BATCH';
export const BATCH_LIST_ADD = 'SALES_CONFIGURATION_TILE_LAYOUTS/BATCH_LIST_ADD';
export const BATCH_LIST_EDIT_ITEM =
	'SALES_CONFIGURATION_TILE_LAYOUTS/BATCH_LIST_EDIT_ITEM';
export const EDIT_DUPLICATE_LAYOUT_LIST_ITEM =
	'SALES_CONFIGURATION_TILE_LAYOUTS/EDIT_DUPLICATE_LAYOUT_LIST_ITEM';
export const BATCH_LIST_REMOVE_ITEM =
	'SALES_CONFIGURATION_TILE_LAYOUTS/BATCH_LIST_REMOVE_ITEM';
export const REMOVE_DUPLICATE_LAYOUT_LIST_ITEM =
	'SALES_CONFIGURATION_TILE_LAYOUTS/REMOVE_DUPLICATE_LAYOUT_LIST_ITEM';
export const UPDATE_TILE_LAYOUT_TILE_POSITION =
	'SALES_CONFIGURATION_TILE_LAYOUTS/UPDATE_TILE_LAYOUT_TILE_POSITION';
export const MARK_GROUP_AS_SUBMITTED =
	'SALES_CONFIGURATION_TILE_LAYOUTS/MARK_GROUP_AS_SUBMITTED';

/**
 * @param {object} payload
 */
export function setPOSList(payload) {
	return {
		type: SET_POS_LISTS,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function updatePosListRow(payload) {
	return {
		type: UPDATE_POS_LIST_ROW,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET_POS_LISTS,
	};
}

// Batch optionse and custom list functions

/**
 * @param {object} payload
 */
export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function addBatchList(payload) {
	return {
		type: BATCH_LIST_ADD,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetBatch() {
	return {
		type: RESET_BATCH,
	};
}

/**
 * @param {object} payload
 */
export function editBatchListItem(payload) {
	return {
		type: BATCH_LIST_EDIT_ITEM,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function editDuplicateLayoutListItem(payload) {
	return {
		type: EDIT_DUPLICATE_LAYOUT_LIST_ITEM,
		payload,
	};
}

/**
 * @param {string} payload ID
 */
export function removeBatchListItem(payload) {
	return {
		type: BATCH_LIST_REMOVE_ITEM,
		payload,
	};
}
/**
 * @param {string} payload ID
 */
export function removeDuplicateLayoutListItem(payload) {
	return {
		type: REMOVE_DUPLICATE_LAYOUT_LIST_ITEM,
		payload,
	};
}

export function updateTileLayoutTilePosition(payload) {
	return {
		type: UPDATE_TILE_LAYOUT_TILE_POSITION,
		payload,
	};
}

export function markGroupAsSubmitted(payload) {
	return {
		type: MARK_GROUP_AS_SUBMITTED,
		payload,
	};
}
