
import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';
import IngredientsList from './services/ingredientsList.service.js';

const ingredientsListReduxKey = '/product/ingredients';
const ingredientsList = new IngredientsList(ingredientsListReduxKey);
const ingredientsListProps = ingredientsList.getProps();

export function Ingredients() {
	useEffect(() => {
		ingredientsList.initView();
	}, []);

	return <ListComponent reduxKey={ingredientsListReduxKey} {...ingredientsListProps} />;
}
