'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import { Button, Input, TableList, InputCleave } from 'dumb';
import phrases from '../../editModalChoice.phrases';
import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';

import './updateRatesList.css';

class UpdateRatesList extends Component {
	componentDidMount() {
		this.props.setEntryUpdateForm(this._mapIdentifier());
	}

	componentWillUnmount() {
		this.props.setEntryUpdateForm(this._mapIdentifier());
	}

	_editListEntry(e, row, type) {
		const value = e.target ? e.target.value : e;

		this.props.editEntryUpdateForm({
			...row,
			...{ [type]: value }
		});
	}

	_mapIdentifier() {
		return this.props.rows.map(x => {
			return { ...x, identifier: _uniqueId('row-') };
		});
	}

	_editStoreDatesEntry(e) {
		const value = e.target ? e.target.value : e;
		this.props.editDateUpdate(value);
	}

	_deleteEntry(row) {
		this.props.editEntryUpdateDelete(row.id);
	}

	render() {
		const { rows, rateEditRows, rateEffectiveFrom } = this.props;

		// let showLegend = false;
		const isNewTimeValid = moment(
			rateEffectiveFrom,
			'YYYY-MM-DD',
			true
		).isValid();

		const showLegend = rateEditRows.some(
			x =>
				isNewTimeValid &&
				moment(rateEffectiveFrom, 'YYYY-MM-DD').isBefore(x.active.from)
		);

		return (
			<>
				<div className="update-list__search">
					<InputCleave
						label="New rates affective from"
						id="active-from"
						placeholder="yyyy-mm-dd"
						options={{
							date: true,
							delimiter: '-',
							datePattern: ['Y', 'm', 'd']
						}}
						required
						value={rateEffectiveFrom}
						onChange={event => this._editStoreDatesEntry(event)}
					/>
				</div>

				<TableList>
					<thead>
						<tr>
							<th>#</th>
							<th>{phrases.MARKET}</th>
							<th>{phrases.MONEYBALL_SUB_POSITION}</th>
							<th>{phrases.GLOBAL_SALARY_COMPONENT}</th>
							<th>{phrases.NAME}</th>
							<th>{phrases.FROM}</th>
							<th>{phrases.TO}</th>
							<th>{phrases.AMOUNT}</th>
							<th>{phrases.NEW_AMOUNT}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{rateEditRows.map((x, i) => {
							const originalAmount = _get(rows[i], 'amount', '');
							const amountFormated = numeral(parseFloat(originalAmount)).format(
								'0,0.00'
							);

							const fromTime = _get(x, 'active.from', '');
							const className = isNewTimeValid
								? moment(rateEffectiveFrom, 'YYYY-MM-DD').isBefore(fromTime) &&
								  'update-list__not-apply'
								: '';

							return (
								<tr className={className} key={i}>
									<td>{i + 1}</td>
									<td>{_get(x, 'salary_group.market.name', '')}</td>
									<td>{_get(x, 'salary_group.name', '')}</td>
									<td>{_get(x, 'global_salary_component.name', '')}</td>
									<td>{_get(x, 'name', '')}</td>
									<td>{fromTime}</td>
									<td>{_get(x, 'active.to', '')}</td>
									<td>{amountFormated}</td>
									<td>
										<div className="modal-batch__list__cell">
											<Input
												id="rate"
												type="number"
												min={0}
												value={_get(x, 'amount', '')}
												placeholder="amount"
												onChange={event =>
													this._editListEntry(event, x, 'amount')
												}
											/>
										</div>
									</td>
									<td>
										<Button
											type="negative"
											size="tiny"
											onClick={() =>
												this._editListEntry(originalAmount, x, 'amount')
											}>
											{phrases.RESET}
										</Button>
										<Button
											type="negative"
											size="tiny"
											onClick={() => this._deleteEntry(x)}>
											{phrases.REMOVE}
										</Button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</TableList>

				<div className="update-list__legend">
					{showLegend && (
						<>
							<span />
							<p> - {phrases.AFFECTIVE_TIME}</p>
						</>
					)}
				</div>
			</>
		);
	}
}

UpdateRatesList.propTypes = {
	rows: PropTypes.array,
	rateEditRows: PropTypes.array,
	editEntryUpdateForm: PropTypes.func,
	setEntryUpdateForm: PropTypes.func,
	editDateUpdate: PropTypes.func,
	editEntryUpdateDelete: PropTypes.func,
	rateEffectiveFrom: PropTypes.string
};

UpdateRatesList.defaultProps = {};

export default UpdateRatesList;
