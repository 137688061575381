'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';

// batch view
import IngredientComponentsBatchStepList from './components/ingredientComponentsBatchStepList';

// phrases
import phrases from './../../ingredientComponents.phrases';

class IngredientComponentsModalBatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};

		this._batchSubmit = this._batchSubmit.bind(this);
	}

	_batchSubmit() {
		const { batchList, handleClose, addIngredientComponents, ingredientId, useGlobalResource } = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchList.map((entry) => {
			return {
				ingredient: ingredientId,
				name: entry.name,
				...(entry.market && !useGlobalResource && { market: entry.market.value.id }),
			};
		});

		addIngredientComponents({ batch: payload }, useGlobalResource)
			.then(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
				handleClose();
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	render() {
		const {
			modalVisible,
			handleClose,
			batchList,
			editBatchListItem,
			removeBatchListItem,
			addBatchListItemRow,
			updateBatchForm,
			batchForm,
			useGlobalResource,
		} = this.props;

		let stepValid = false;
		if (useGlobalResource) stepValid = batchList?.every((entry) => entry.name) ?? false;
		else stepValid = batchList?.every((entry) => entry.market && entry.name) ?? false;

		const steps = [
			{
				component: (
					<IngredientComponentsBatchStepList
						batchList={batchList}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
						addBatchListItemRow={addBatchListItemRow}
						updateBatchForm={updateBatchForm}
						batchForm={batchForm}
						useGlobalResource={useGlobalResource}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: stepValid,
				onNext: this._batchSubmit,
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return modalVisible ? (
			<ModalStepper isOpen={modalVisible} steps={steps} showStep={false} onClose={handleClose} />
		) : null;
	}
}

IngredientComponentsModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	addIngredientComponents: PropTypes.func,
	batchForm: PropTypes.object,
	updateBatchForm: PropTypes.func,
	ingredientId: PropTypes.number,
	useGlobalResource: PropTypes.bool,
};

export default IngredientComponentsModalBatch;
