'use strict';

import EmailDataContentAbstract from './emailData.content.abstract';

/**
 * Body
 * @param  {*} template
 */
class Body extends EmailDataContentAbstract  {
	constructor(template) {
		super(template);

		// Set type
		this.type = 'body';
	}
}

export default Body;