import React from 'react';
import PropTypes from 'prop-types';

// components
import { Button, SectionCollapsable } from 'dumb';
import { ModalRow } from 'dumb/modal';

// phrases/ enums
import globalPhrases from './../../../../../shiftTransferApproval.phrases';
import phrases from './../../../shiftTransferApprovalMobileView.phrases';
import { ShiftTransfer as enums } from 'services/enums';

const ShiftTransferPendingTransfersMobalViewPageOwn = ({
	shiftTransfer,
	canCancelTransfer,
	updateShiftTransfer,
}) => {
	const handlePendingShiftTransfer = (e) => {
		const operation = enums.OPERATIONS.RETRACT;

		const payload = {
			operation,
			shiftTransfer,
		};

		updateShiftTransfer(payload);
	};

	if (!canCancelTransfer) return null;

	return (
		<ModalRow className="shift-transfer-approval-modal__mobile-view__section">
			<SectionCollapsable title={phrases.ACTIONS}>
				<div className="shift-transfer-approval-modal__mobile-view__action">
					<Button shadow type="negative" onClick={handlePendingShiftTransfer}>
						{globalPhrases.CANCEL_TRANSFER}
					</Button>
				</div>
			</SectionCollapsable>
		</ModalRow>
	);
};

ShiftTransferPendingTransfersMobalViewPageOwn.propTypes = {
	shiftTransfer: PropTypes.object,
	canCancelTransfer: PropTypes.bool,
	updateShiftTransfer: PropTypes.func,
};

export default ShiftTransferPendingTransfersMobalViewPageOwn;
