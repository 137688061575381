'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, ButtonGroup, InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';

import constants from 'services/constants';
import phrases from './../../posConfigurationIngredientPricingGroups.phrases';

class IngredientPricingGroupStepForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};
	}

	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		const value = e.target ? e.target.value || e.target.checked : e;

		updateBatchForm({
			[type]: value,
		});
	}

	render() {
		const {
			batchFormData,
			toggleOverlay,
			ingredientPricingGroupReduxKey,
			posConfigurationVersionReduxKey,
		} = this.props;

		return (
			<>
				<ModalRow>
					<ButtonGroup type="full-width">
						<InputCollectionSelect
							className="modal-batch-select"
							id="pos_configuration_version"
							label={phrases.MODAL_BATCH_STEP_POS_CONFIGURATION_VERSION}
							placeholder="Select POS configuration version"
							value={batchFormData.posConfigurationVersion}
							handleChange={(key, value) =>
								this.editStoreEntry(value, 'posConfigurationVersion')
							}
							multi
							cache
							apiPath="/pos/pos_configuration_versions"
							params={{
								limit: 50,
								filter: `:active.to=ge='${moment
									.utc()
									.format(constants.shortDate)}'`,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.pos_configuration.name,
							})}
							inputFilterFormat={(input) =>
								`:pos_configuration.name=like='%${input}%'`
							}
						/>

						<Button
							type="inverted"
							shadow
							size="large"
							onClick={() => toggleOverlay(posConfigurationVersionReduxKey)}>
							<span className="icon icon--more" />
						</Button>
					</ButtonGroup>
				</ModalRow>

				<ModalRow>
					<ButtonGroup type="full-width">
						<InputCollectionSelect
							className="modal-batch-select"
							id="product_variant"
							label={phrases.MODAL_BATCH_STEP_INGREDIENT_PRICING_GROUP}
							placeholder="Select ingredient pricing group"
							value={batchFormData.ingredientPricingGroup}
							handleChange={(key, value) =>
								this.editStoreEntry(value, 'ingredientPricingGroup')
							}
							multi
							cache
							apiPath="/product/ingredient_pricing_groups"
							params={{
								limit: 50,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>

						<Button
							type="inverted"
							shadow
							size="large"
							onClick={() => toggleOverlay(ingredientPricingGroupReduxKey)}>
							<span className="icon icon--more" />
						</Button>
					</ButtonGroup>
				</ModalRow>

				<ModalRow>
					<Input
						label={phrases.MODAL_BATCH_STEP_TO_STAY_PRICE}
						id="to_stay_price"
						type="number"
						placeholder="1234,56"
						onChange={(event) => this.editStoreEntry(event, 'toStayPrice')}
						value={batchFormData.toStayPrice || ''}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						label={phrases.MODAL_BATCH_STEP_TO_GO_PRICE}
						id="to_go_price"
						type="number"
						placeholder="1234,56"
						onChange={(event) => this.editStoreEntry(event, 'toGoPrice')}
						value={batchFormData.toGoPrice || ''}
					/>
				</ModalRow>

				{/* <ModalRow>
					<Input
						id="checkbox"
						type="checkbox"
						label={phrases.MODAL_BATCH_STEP_AVAILABLE_IN_APP}
						checked={batchFormData.availableInApp || false}
						onChange={e => this.editStoreEntry(e, 'availableInApp')}
					/>
				</ModalRow> */}
			</>
		);
	}
}

IngredientPricingGroupStepForm.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
	posConfigurationVersionReduxKey: PropTypes.string,
	ingredientPricingGroupReduxKey: PropTypes.string,
};

export default IngredientPricingGroupStepForm;
