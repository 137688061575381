export const SET_TENANTS = 'TENANT_TRANSFER/SET_TENANTS';
export const SET_TENANT_OPTIONS = 'TENANT_TRANSFER/SET_TENANT_OPTIONS';
export const SET_UI_DATA = 'TENANT_TRANSFER/SET_UI_DATA';
export const RESET_DATA = 'TENANT_TRANSFER/RESET_DATA';
export const SET_EMPLOYMENT_TERMINATED = 'TENANT_TRANSFER/SET_EMPLOYMENT_TERMINATED';

export function setTenants(payload) {
	return {
		type: SET_TENANTS,
		payload,
	};
}

export function setTenantOptions(payload) {
	return {
		type: SET_TENANT_OPTIONS,
		payload,
	};
}

export function setUiData(payload) {
	return {
		type: SET_UI_DATA,
		payload,
	};
}

export function resetData() {
	return {
		type: RESET_DATA,
	};
}

export function setEmploymentTerminated(payload) {
	return {
		type: SET_EMPLOYMENT_TERMINATED,
		payload,
	};
}
