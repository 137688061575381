'use strict';

import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { formatErrorMessage } from 'api/helpers';
import cx from 'classnames';
import constants from 'services/constants';

// Component specific
import 'react-table/react-table.css';
import './reactDataWrapper.css';
import * as actions from './reactDataWrapper.actions';
import * as filterActions from './filterSortColumnsData.actions';
import { addFilter, addSorting, getTrProps } from './utilities';

// lodash
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import _isEmpty from 'lodash/isEmpty';
import _isFunction from 'lodash/isFunction';

import store from 'appState/store';
import connectWithStore from 'appState/connectWithStore';
import { saveAs } from 'file-saver';
import { validAccess } from 'accessControl';

import HotkeysService from 'hotkeys.service';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import moment from 'moment';

// Components
import { Button, ButtonGroup, Collapsible, Icon } from 'dumb';
import { Typography } from '@mui/material';
import EditTableCellModal from './components/editTableCellModal/editTableCellModal.container';
import Actions from './components/actions.component';
import ToggleColumns from './components/toggleColumns/toggleColumns.component';

// Wrapper for react-data
// Documentation can be found here https://react-table.js.org/
class ReactTableWrapper extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			modalLoading: false,
			editMode: false,
			createMode: false,
			loading: false,
			inlineDelete: null,
			activeComponents: false,
			showColumnToggle: false,
			searchFilter: null,
		};

		this.instance = React.createRef();

		this.fetchData = _debounce(this.fetchData, 500, {
			trailing: true,
		}).bind(this);

		this._onFilteredChange = this._onFilteredChange.bind(this);
		this._onSortedChange = this._onSortedChange.bind(this);
		this._onPageChange = this._onPageChange.bind(this);
		this.toggleEditModal = this.toggleEditModal.bind(this);
		this.resetSelection = this.resetSelection.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this._getOnSubmit = this._getOnSubmit.bind(this);
		this.editMultipleRows = this.editMultipleRows.bind(this);
		this.toggleEditEntry = this.toggleEditEntry.bind(this);
		this.createEntry = this.createEntry.bind(this);
		this.toggleCreateModal = this.toggleCreateModal.bind(this);
		this.deleteRows = this.deleteRows.bind(this);
		this.fetchDataAsCvs = this.fetchDataAsCvs.bind(this);
		this.selectAll = this.selectAll.bind(this);
		this.toggleColumns = this.toggleColumns.bind(this);
		this._getColumns = this._getColumns.bind(this);
		this.clearTableFiltersSorting = this.clearTableFiltersSorting.bind(this);
		this.handleSearchInputChange = _debounce(
			this.handleSearchInputChange,
			300,
			{ trailing: true }
		).bind(this);

		this.idColumn = {
			Header: 'Id',
			id: 'ID',
			show: false,
			accessor: 'id', // String-based value accessors!
			maxWidth: 60,
			filterPath: ':id',
		};
		this.columns = this._getColumns();
	}

	componentDidMount() {
		const { onInitialization, reduxKey, tableInitialized } = this.props;

		HotkeysService.defineHotkeys({ keys: 'shift, cmd, ctrl' });

		// if table already initialized don't reset ui data
		if (tableInitialized) {
			store.dispatch(actions.initializeData({ reduxKey }));
		} else {
			store.dispatch(actions.initializeReducer({ reduxKey }));
		}

		if (onInitialization) onInitialization();

		// small hack for adding placeholders to filter input fields
		document
			.querySelectorAll('div.rt-th > input')
			.forEach((filter) => (filter.placeholder = 'Search..'));
	}

	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		if (this.props.extraFilters !== prevProps.extraFilters) {
			this.fetchData();
		}

		if (prevProps.preventFetch === true && this.props.preventFetch === false)
			this.fetchData();
	}

	_getColumns() {
		const { columns, hideIdColumn } = this.props;

		let columnsToReturn;

		if (
			this.props.inRowButtons &&
			(this.props.deleteEntry || this.props.editEntry)
		) {
			columnsToReturn = [
				...columns,
				{
					Header: '',
					width: this.props.actionsWidth || 75,
					sortable: false,
					resizable: false,
					filterable: false,
					className: 'u-align-right',
					id: 'rdwInRowButtons',
					accessor: (d) => _get(d, 'id', null),
					Cell: (d) => {
						return (
							<ButtonGroup>
								{this.props.actions && this.props.actions(d)}

								{this.props.editEntry && (
									<Button
										type="inverted"
										shadow
										onClick={(e) => this.toggleEditEntry(d, e)}
										size="micro">
										<Icon name="edit" />
									</Button>
								)}
							</ButtonGroup>
						);
					},
				},
			];
		} else if (this.props.actions) {
			const actionsSection = {
				Header: '',
				width: this.props.actionsWidth || 75,
				sortable: false,
				resizable: false,
				filterable: false,
				id: 'rdwInRowButtons',
				accessor: (d) => _get(d, 'id', null),
				className: 'sticky',
				Cell: (d) => {
					return (
						<ButtonGroup type="full-width">
							{this.props.actions && this.props.actions(d)}
						</ButtonGroup>
					);
				},
			};

			columnsToReturn = [...columns, actionsSection];
		} else {
			columnsToReturn = columns;
		}

		return [...(!hideIdColumn ? [this.idColumn] : []), ...columnsToReturn];
	}

	toggleModal() {
		// reset the initial values to an empty object and remove the editing mode modal flag
		if (this.state.showModal) {
			this.props.onModalClose();
			this.setState(() => ({ editMode: false, createMode: false }));
		}
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
	}

	toggleEditEntry({ original }, e) {
		e.preventDefault();
		e.stopPropagation();

		const { setInitialEditValues, selectedRows } = this.props;

		// if (original)
		this.setState({ editMode: true });

		if (selectedRows.length === 1 || original)
			setInitialEditValues(original || selectedRows[0]);

		this.toggleModal();
	}

	_onPageChange(page) {
		const { reduxKey } = this.props;

		store.dispatch(
			actions.setPageNumber({
				reduxKey,
				page,
			})
		);
		store.dispatch(actions.setExpandedToDefault({ reduxKey }));
	}

	_onSortedChange(sorting) {
		const { reduxKey } = this.props;

		store.dispatch(filterActions.setListSorting({ reduxKey, sorting }));
		store.dispatch(actions.setExpandedToDefault({ reduxKey }));
	}

	resetSelection() {
		const { reduxKey } = this.props;
		store.dispatch(actions.resetSelection({ reduxKey }));
	}

	_onFilteredChange(filter) {
		const { reduxKey } = this.props;

		// add the filter
		store.dispatch(
			filterActions.setListFilter({
				reduxKey,
				filter,
			})
		);

		store.dispatch(actions.setExpandedToDefault({ reduxKey }));
	}

	toggleColumns() {
		this.setState((prevState) => ({
			activeComponents: !prevState.activeComponents,
		}));
	}

	toggleEditModal(showEditModal, e) {
		const { reduxKey } = this.props;
		store.dispatch(actions.showEditModal({ reduxKey, showEditModal }));
	}

	toggleCreateModal() {
		this.setState(() => ({ createMode: true }));
		this.toggleModal();
	}

	/**
	 * @function fetchDataAsCvs
	 * @description Handler for fetching data as CSV. Fetches from the fetchData method, unless a override fetchCsv method is passed
	 */
	fetchDataAsCvs() {
		const {
			fetchCsv,
			fetchData,
			filterSortColumnsData,
			columns,
			extraFilters,
			reduxKey,
		} = this.props;

		const filter = addFilter(
			filterSortColumnsData.filters,
			[this.idColumn, ...columns],
			extraFilters
		);
		const sort = addSorting(filterSortColumnsData.sorting, [
			this.idColumn,
			...columns,
		]);

		const headers = {
			Accept: 'application/csv',
		};

		//  If a fetchCsv method is passed, use that. If not, use the default
		const fetchCsvMethod = fetchCsv || fetchData;

		return fetchCsvMethod({ filter, sort, headers }).then((csv) => {
			if (!csv)
				console.info('No csv data. Did you forget to return the promise?');

			const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });

			// outputs filename in format of "redux_key__2019_04_24"
			const csvFileName = `${reduxKey.replace('/', '_')}__${moment().format(
				'YYYY-MM-DD'
			)}.csv`;
			const fileName = csvFileName;
			saveAs(blob, fileName);
		});
	}

	fetchData(state) {
		const {
			columns,
			defaultSorted,
			extraFilters,
			fetchData,
			filterSortColumnsData,
			listSearchFilter,
			page,
			preventFetch,
			reduxKey,
		} = this.props;
		if (preventFetch) return;

		// if no fetchData function go out
		if (!fetchData || !columns.length) return null;
		this.setState(() => ({ loading: true }));

		// manually fetch state from instance
		if (_isEmpty(state)) {
			state = {
				pageSize: this.instance.current.state.pageSize,
			};
		}

		// calculate total pages
		const offset = Math.ceil(state.pageSize * page);

		// get filters from table filters, extra passed filters and the ones from columns array
		const filter = addFilter(
			filterSortColumnsData.filters,
			[this.idColumn, ...columns],
			extraFilters,
			listSearchFilter
		);

		// get sorting from table sorting, columns array and default sorting if passed
		const sort = addSorting(
			filterSortColumnsData.sorting,
			[this.idColumn, ...columns],
			defaultSorted
		);

		// call the function with params and handle the response
		return fetchData({
			offset,
			limit: state.pageSize,
			filter,
			sort,
			filterArray: filterSortColumnsData.filters,
		})
			.then((response) => {
				this.setState(() => ({ loading: false }));
				if (!response) return;
				store.dispatch(
					actions.setListData({ reduxKey, listData: response.data })
				);

				// page is from redux and pageSize is from reactDataWrapper's internal state
				const newState = {
					page,
					pageSize: state.pageSize,
				};

				this._setListMetaData(newState, response);
			})
			.catch(() => {
				this.setState(() => ({ loading: false }));
			});
	}

	/**
	 * @function selectAll
	 * @package
	 */
	selectAll() {
		const { reduxKey, listData } = this.props;

		listData.map((value, index) => {
			store.dispatch(
				actions.toggleRow({ reduxKey, data: { ...value, index } })
			);
		});
	}

	/**
	 * @function handleSearchInputChange
	 * @description filters api with data
	 */
	handleSearchInputChange(value) {
		const { columns, reduxKey } = this.props;

		// add search filter to every column
		const listSearchFilter = columns.map((column) => {
			return {
				value,
				filterPath: column.filterPath,
			};
		});

		store.dispatch(actions.setListSearchFilter({ reduxKey, listSearchFilter }));

		this.fetchData(this.props);
	}

	clearTableFiltersSorting() {
		const { reduxKey } = this.props;

		store.dispatch(filterActions.clearListFiltersSorting(reduxKey));
	}

	_renderActionButtons({ canEdit }) {
		const {
			selectedRows,
			createEntry,
			disableFetchCsvButton,
			actionRender,
			editMultiple,
			editEntry,
			showSearch,
			deleteMultiple,
			deleteEntry,
			filterSortColumnsData,
			batchSelection,
			enableMultiSelection,
			enableSingleSelection,
			hideMenu,
		} = this.props;

		const canDelete =
			(_isFunction(deleteMultiple) || _isFunction(deleteEntry)) && canEdit;
		const canSingleEdit = _isFunction(editEntry) && canEdit;
		const canMultiEdit = _isFunction(editMultiple) && canEdit;
		const canManipulate = !!createEntry && canEdit;

		return (
			<Actions
				toggleEditModal={this.toggleEditEntry}
				canMultiEdit={canMultiEdit}
				canSingleEdit={canSingleEdit}
				toggleCreateModal={this.toggleCreateModal}
				resetSelection={this.resetSelection}
				fetchCsv={this.fetchDataAsCvs}
				canDelete={canDelete}
				deleteRows={this.deleteRows}
				selectedRows={selectedRows}
				canManipulate={canManipulate}
				fetchData={this.fetchData}
				disableFetchCsvButton={disableFetchCsvButton}
				toggleColumns={this.toggleColumns}
				selectAll={this.selectAll}
				render={() => <>{actionRender}</>}
				showSearch={showSearch}
				onInputChange={this.handleSearchInputChange}
				filterSortColumnsData={filterSortColumnsData}
				clearTableFiltersSorting={this.clearTableFiltersSorting}
				batchSelection={batchSelection}
				enableMultiSelection={enableMultiSelection}
				hideMenu={hideMenu}
				enableSingleSelection={enableSingleSelection}
			/>
		);
	}

	_renderCustomArea() {
		const { customAreaComponents } = this.props;

		if (!customAreaComponents) return null;
		return (
			<div className="react-table-wrapper__filter-area">
				{customAreaComponents}
			</div>
		);
	}

	/*
	 * @function _setListMetaData
	 * @description Get basic information regarding the list and aplies to reducer, to format name, pages, etc.
	 */
	_setListMetaData(state = this.props, response = null) {
		// get number of entries from the state
		const totalEntries = response
			? response.paging.total_entries
			: this.props.totalEntries;
		// get number of pages
		const pages = Math.ceil(totalEntries / state.pageSize);
		// get redux key
		const { reduxKey } = this.props;

		// setting date in the store
		store.dispatch(
			actions.setListMetaData({
				reduxKey,
				metadata: {
					pages,
					page: state.page,
					pageSize: state.pageSize,
					totalEntries,
				},
			})
		);
	}

	editRow() {
		const { editEntry, reduxKey } = this.props;
		this.setState({ modalLoading: true });

		editEntry()
			.then((entry) => {
				if (entry) {
					this.toggleModal();
					this.setState({ modalLoading: false });

					store.dispatch(actions.editEntry({ reduxKey, entry: entry.data[0] }));
					store.dispatch(actions.resetSelection({ reduxKey }));
				} else {
					// If false, maybe nothing was changed (value returned FALSE)
					this.setState({ modalLoading: false });
					this.toggleModal();
				}
			})
			.catch(() => {
				this.setState({ modalLoading: false });
			});
	}

	editMultipleRows() {
		const { editMultiple, selectedRows, reduxKey } = this.props;

		// const promises = editMultiple(selectedRows); // <-- possible solution for mixed data calls
		this.setState({ modalLoading: true });

		// Promise.all(promises)
		return editMultiple(selectedRows)
			.then((res) => {
				const values = res.data;
				this.setState({ modalLoading: false });

				// clear out the selected rows from the store. Only if success
				store.dispatch(actions.resetSelection({ reduxKey }));

				values.forEach((value) => {
					store.dispatch(actions.editEntry({ reduxKey, entry: value }));
				});

				this.toggleModal();
			})
			.catch(() => {
				this.setState(() => ({ modalLoading: false }));
			});
	}

	deleteRows() {
		const {
			selectedRows,
			reduxKey,
			deleteMultiple,
			deleteEntry,
			removeEntryAfterDelete,
		} = this.props;

		const promise = deleteMultiple
			? deleteMultiple(selectedRows)
			: Promise.all(selectedRows.map((row) => deleteEntry(row.id)));

		return promise
			.then(() => {
				this.setState(() => ({ modalLoading: false }));
				store.dispatch(actions.resetSelection({ reduxKey }));
				this._setListMetaData();

				if (!removeEntryAfterDelete) return;
				selectedRows.forEach((row) => {
					if (!row.id) return;
					store.dispatch(actions.removeEntry({ reduxKey, id: row.id }));
				});
				store.dispatch(actions.resetSelection({ reduxKey }));
				this._setListMetaData();
			})
			.catch((e) => {
				this.setState(() => ({ modalLoading: false }));
			});
	}

	createEntry() {
		const { createEntry, reduxKey, page, pageSize, totalEntries } = this.props;
		this.setState(() => ({ modalLoading: true }));

		createEntry()
			// createEntry()
			.then((entry) => {
				if (entry) {
					this.setState(() => ({ modalLoading: false }));
					this.toggleModal();

					// jump to fist page
					store.dispatch(
						actions.setPageNumber({
							reduxKey,
							page: 0,
						})
					);

					// if current page isn't first page then re-fetch the data for the first page
					if (page !== 0) this.fetchData(this.props);
					else {
						// if we are on the first page and we don't have enough entries then add one
						// also check if data[0] exists - in case we want to prevent adding some rows to the reducer
						if (pageSize > totalEntries && _get(entry, 'data[0]', false)) {
							store.dispatch(
								actions.addEntry({ reduxKey, entry: entry.data[0] })
							);
						}
					}
				} else {
					this.setState(() => ({ modalLoading: false }));
				}
			})
			.catch((e) => {
				const errorMsg = formatErrorMessage(e);
				setFeedback(errorMsg, 0);
				this.setState(() => ({ modalLoading: false }));
			});
	}

	// ! delete the first if and test it out once we get to the create part
	_getOnSubmit() {
		if (this.state.createMode) return this.createEntry();

		if (this.props.selectedRows.length <= 1 && this.state.editMode)
			return this.editRow();

		if (this.props.selectedRows.length > 1) return this.editMultipleRows();

		return this.createEntry();
	}

	render() {
		const {
			listData,
			pages,
			editableCells,
			subcomponent,
			editableCellsEdit,
			title,
			onExpandChange,
			defaultSorted,
			expanded,
			style,
			reduxKey,
			filterable,
			compact,
			showPagination,
			filterSortColumnsData,
			modalZIndex,
			totalEntries,
			selectedRows,
			enableMultiSelection,
			batchSelection,
			lastSelectedRow,
			customRowStyling,
			defaultPageSize,
			editModalTip,
			enableSingleSelection,
			onModalCloseConfirm,
			accessAreasAllowedToEdit,
			dataCy,
		} = this.props;

		const canEdit = validAccess(undefined, [
			...accessAreasAllowedToEdit,
			constants.admin,
		]);

		let updatedColumns = this.columns;
		if (this.props.toggleColumnsData) {
			updatedColumns = updatedColumns.map((column) => {
				let show = column.show ?? true;
				this.props.toggleColumnsData.map((toggledObject) => {
					const columnId = Object.keys(toggledObject)[0];

					// if we found the column and value changed
					if (column.id === columnId && column.show !== toggledObject[columnId])
						show = toggledObject[columnId];
				});

				const newColumn = {
					...column,
					show,
				};

				return newColumn;
			});
		}

		const classNames = cx('react-table-wrapper', {
			'react-table-wrapper--compact': compact,
		});

		return (
			<div className={classNames} data-cy={dataCy}>
				<div className="react-table-wrapper__header">
					{title && (
						<Typography variant="h1" component="h2">
							{title} {totalEntries ? <span>({totalEntries})</span> : null}
						</Typography>
					)}
					{this._renderActionButtons({ canEdit })}
				</div>

				{this._renderCustomArea()}

				<Collapsible
					open={this.state.activeComponents}
					easing="ease-out"
					classParentString="react-table-wrapper__collapsible">
					<ToggleColumns columns={updatedColumns} reduxKey={reduxKey} />
				</Collapsible>

				<EditTableCellModal
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					editableCells={
						this.state.editMode && editableCellsEdit
							? editableCellsEdit
							: editableCells
					}
					onConfirmClick={this._getOnSubmit}
					loading={this.state.modalLoading}
					modalZIndex={modalZIndex}
					editModalTip={editModalTip}
					onModalCloseConfirm={onModalCloseConfirm}
				/>

				<ReactTable
					page={this.props.page}
					data={listData}
					ref={this.instance}
					className="-striped -highlight"
					columns={updatedColumns}
					manual
					pageSizeOptions={[5, 10, 20, 25, 50, 100, 300]}
					expanded={expanded}
					{...(!batchSelection && {
						SubComponent: subcomponent,
					})}
					defaultPageSize={defaultPageSize}
					onPageChange={this._onPageChange}
					onSortedChange={this._onSortedChange}
					onFilteredChange={this._onFilteredChange}
					onExpandedChange={(newExpanded, index, event, row) => {
						if (onExpandChange) {
							onExpandChange(newExpanded, index, event, row);
						} else
							store.dispatch(
								actions.onExpandChange({ reduxKey, newExpanded, index, event })
							);
					}}
					pages={pages} // Display the total number of pages
					loading={this.state.loading}
					onFetchData={this.fetchData} // Request new data when things change
					showPagination={showPagination}
					filterable={filterable}
					getTrProps={(state, rowInfo) =>
						getTrProps({
							state,
							rowInfo,
							reduxKey,
							selectedRows,
							enableMultiSelection,
							batchSelection,
							listData,
							lastSelectedRow,
							customRowStyling,
							enableSingleSelection,
						})
					}
					style={style}
					defaultSorted={filterSortColumnsData.sorting || defaultSorted}
					defaultFiltered={filterSortColumnsData.filters}
				/>
			</div>
		);
	}
}

ReactTableWrapper.defaultProps = {
	listData: [],
	columns: [],
	inRowButtons: false,
	preventFetch: false,
	enableMultiSelection: true,
	defaultSorted: [],
	accessAreasAllowedToEdit: [],
	fetchCsv: null,
	onExpandChange: null,
	page: 0,
	style: {},
	filterSectionOpen: false,
	filterable: true,
	showPagination: true,
	showSearch: false,
	deleteConfirm: false,
	hideIdColumn: false,
	removeEntryAfterDelete: true,
	batchSelection: false,
	enableSingleSelection: false,
	hideMenu: false,
	onModalCloseConfirm: false,
	dataCy: 'react-data-wrapper',
};

ReactTableWrapper.propTypes = {
	reduxKey: PropTypes.array.isRequired,
	title: PropTypes.string,
	listData: PropTypes.array,
	defaultSorted: PropTypes.array,
	columns: PropTypes.array,
	selectedRows: PropTypes.array,
	page: PropTypes.number,
	pages: PropTypes.number,
	pageSize: PropTypes.number,
	fetchData: PropTypes.func,
	editMultiple: PropTypes.func,
	preventFetch: PropTypes.bool,
	enableMultiSelection: PropTypes.bool,
	deleteEntry: PropTypes.func,
	subcomponent: PropTypes.func,
	onExpandChange: PropTypes.func,
	onInitialization: PropTypes.func,
	expanded: PropTypes.object,
	editEntry: PropTypes.func,
	lastSelectedRow: PropTypes.object,
	editableCells: PropTypes.array,
	editableCellsEdit: PropTypes.array,
	createEntry: PropTypes.func,
	setInitialEditValues: PropTypes.func,
	onModalClose: PropTypes.func,
	actions: PropTypes.func,
	actionsWidth: PropTypes.number,
	deleteMultiple: PropTypes.func,
	inRowButtons: PropTypes.bool,
	totalEntries: PropTypes.number,
	extraFilters: PropTypes.string,
	actionRender: PropTypes.element,
	customAreaComponents: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.bool,
	]),
	batchSelection: PropTypes.bool,
	compact: PropTypes.bool,
	style: PropTypes.object,
	toggleColumnsData: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
	customRowStyling: PropTypes.func,
	filterable: PropTypes.bool,
	showPagination: PropTypes.bool,
	filterSortColumnsData: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]),
	// csv related props
	fetchCsv: PropTypes.func,
	disableFetchCsvButton: PropTypes.bool,
	showSearch: PropTypes.bool,
	listSearchFilter: PropTypes.array,
	modalZIndex: PropTypes.number,
	hideIdColumn: PropTypes.bool,
	tableInitialized: PropTypes.bool,
	removeEntryAfterDelete: PropTypes.bool,
	enableSingleSelection: PropTypes.bool,
	defaultPageSize: PropTypes.number,
	editModalTip: PropTypes.string,
	accessAreasAllowedToEdit: PropTypes.array,
	hideMenu: PropTypes.bool,
	onModalCloseConfirm: PropTypes.bool,
	dataCy: PropTypes.string,
};

const mapStateToPropsFactory = (initialStore, ownProps) => (store) => {
	return {
		totalEntries: _get(
			store,
			`listData.[${ownProps.reduxKey}].ui.totalEntries`,
			0
		),
		expanded: _get(
			store,
			`listData.[${ownProps.reduxKey}].ui.expanded`,
			ownProps.expanded
		),
		page: _get(store, `listData.[${ownProps.reduxKey}].ui.page`, 0),
		pages: _get(store, `listData.[${ownProps.reduxKey}].ui.pages`, null),
		pageSize: _get(
			store,
			`listData.[${ownProps.reduxKey}].ui.pageSize`,
			ownProps.pageSize
		),
		listData: _get(store, `listData.[${ownProps.reduxKey}].data.listData`, []),
		selectedRows: _get(
			store,
			`listData.[${ownProps.reduxKey}].ui.selectedRows`,
			[]
		),
		lastSelectedRow: _get(
			store,
			`listData.[${ownProps.reduxKey}].ui.lastSelectedRow`,
			null
		),
		listSearchFilter: _get(
			store,
			`listData.[${ownProps.reduxKey}].data.listSearchFilter`,
			[]
		),
		toggleColumnsData: _get(
			store,
			`toggleColumnsData.tableColumns[${ownProps.reduxKey}]`,
			false
		),
		filterSortColumnsData: _get(
			store,
			`filterSortColumnsData.tables[${ownProps.reduxKey}]`,
			[]
		),
		tableInitialized: !!_get(store, `listData.[${ownProps.reduxKey}]`, false),
		areas: store.userData?.user.areas,
	};
};

export default connectWithStore(ReactTableWrapper, mapStateToPropsFactory);
