export default {
	TABLE_TITLE: 'Ingredients',

	DISCOUNT_INGREDIENTS_ADDED: 'Discount ingredients added',
	DISCOUNT_INGREDIENT_EDITED: 'Discount ingredient edited',
	DISCOUNT_INGREDIENT_DELETED: 'Discount ingredient deleted',

	MODAL_BATCH_STEP_FORM_TITLE: 'Select remaining values',
	MODAL_BATCH_STEP_FORM_TITLE_VERSION: 'Select Ingredients',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'next',
	MODAL_BATCH_STEP_LIST_TITLE: 'Confirm entries',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'submit',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'back',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_INGREDIENT: 'Ingredient',
	MODAL_BATCH_STEP_INGREDIENT_PLACEHOLDER: 'Select ingredient...',
	MODAL_BATCH_STEP_DISCOUNT: 'Discount',
	MODAL_BATCH_STEP_FORM_INGREDIENTS_TABLE_TITLE: 'Ingredients',
	MODAL_BATCH_STEP_FORM_TAGS_TEXT: 'Selected POS Configuration Versions:',
	MODAL_BATCH_STEP_LIST_LEGEND_CANT_ADD: 'Discount Ingredient already exists'
};
