import _uniqBy from 'lodash/uniqBy';

import {
	SET_EMPLOYEE_MEALS_REPORT,
	SET_EMPLOYEE_MEALS_REPORT_PR_WORKPLACE,
	SET_EMPLOYEE_MEALS_REPORT_PR_PERSON,
	LOADING_REPORT,
	SET_HOTBAR_DATA,
	SHOW_PRODUCTS_PR_SHIFT,
	CLEAN_DATA,
	SET_EMPLOYEE,
	SET_MARKET,
	SET_WORKPLACE,
	CLEAN_REPORT_DATA
} from './employeeMeals.actions';

const defaultState = {
	ui: {
		loadingReport: false,
		showProductsPrShift: 'All',
		selectedPerson: null,
		selectedMarket: null,
		selectedWorkplace: null
	},
	data: {
		employeeMealsReport: {},
		hotbarData: {},
		employeeMealsReportPrWorkplace: [],
		employeeMealsReportPrPerson: []
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
	case SET_EMPLOYEE: {
		return {
			...state,
			ui: {
				...state.ui,
				selectedPerson: payload
			}
		};
	}

	case SET_MARKET: {
		return {
			...state,
			ui: {
				...state.ui,
				selectedMarket: payload
			}
		};
	}

	case SET_WORKPLACE: {
		return {
			...state,
			ui: {
				...state.ui,
				selectedWorkplace: payload
			}
		};
	}

	case LOADING_REPORT: {
		return {
			...state,
			ui: {
				...state.ui,
				loadingReport: payload.loadingReport
			}
		};
	}

	case SET_EMPLOYEE_MEALS_REPORT: {
		return {
			...state,
			data: {
				...state.data,
				...payload
			}
		};
	}

	case SET_EMPLOYEE_MEALS_REPORT_PR_WORKPLACE: {
		return {
			...state,
			data: {
				...state.data,
				employeeMealsReportPrWorkplace: _uniqBy(
					[
						...state.data.employeeMealsReportPrWorkplace,
						...payload.employeeMealsReportPrWorkplace
					],
					'id'
				)
			}
		};
	}

	case SET_EMPLOYEE_MEALS_REPORT_PR_PERSON: {
		return {
			...state,
			data: {
				...state.data,
				employeeMealsReportPrPerson: _uniqBy(
					[
						...state.data.employeeMealsReportPrPerson,
						...payload.employeeMealsReportPrPerson
					],
					'id'
				)
			}
		};
	}

	case SET_HOTBAR_DATA: {
		return {
			...state,
			data: {
				...state.data,
				...payload
			}
		};
	}

	case SHOW_PRODUCTS_PR_SHIFT: {
		return {
			...state,
			ui: {
				...state.ui,
				showProductsPrShift: payload.showProductsPrShift
			}
		};
	}

	case CLEAN_DATA: {
		return {
			...state,
			data: defaultState.data
		};
	}

	case CLEAN_REPORT_DATA: {
		return {
			...state,
			data: {
				...state.data,
				employeeMealsReport: defaultState.data.employeeMealsReport,
				employeeMealsReportPrWorkplace:
						defaultState.data.employeeMealsReportPrWorkplace,
				employeeMealsReportPrPerson:
						defaultState.data.employeeMealsReportPrPerson
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
