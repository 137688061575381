export default {
	NO_DATA_IN_SELECTED_PERIOD: 'No Data in selected period',
	TABLE_HEADER_EMPLOYEE: 'Employee',
	TABLE_HEADER_GROSS_TURNOVER: 'Gross turnover',
	TABLE_HEADER_HOURS: 'Hours',
	TABLE_HEADER_PRODUCTS: 'Products',
	TABLE_HEADER_PRODUCTS_PER_SHIFTS: 'Products per shifts',
	TABLE_HEADER_SHIFTS: 'Shifts',
	TABLE_HEADER_WORKPLACE: 'Workplace'
};
