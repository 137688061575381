'use strict';

import constants from 'services/constants';
import moment from 'moment';
import {
	RESET_STATE,
	UPDATE_BATCH_FORM,
	ADD_BATCH_LIST,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	SET_DATE_FILTERS,
	SET_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT_LEGACY_FILTER,
} from './salesConfigurationPosConfigurationDiscountProductVariant.actions';

const todayDate = moment.utc().format(constants.shortDate);
const getTableFilter = (date) => {
	if (!date) return '';

	return `:pos_configuration_product_variant.pos_configuration_version.active.from=le='${date}';:pos_configuration_product_variant.pos_configuration_version.active.to=ge='${date}'`;
};

const getPosConfigurationDiscountFilter = (date) => {
	if (!date) return '';

	return `:pos_configuration_version.active.from=le='${date}';:pos_configuration_version.active.to=ge='${date}'`;
};

const defaultState = {
	posConfigurationDiscountProductVariantDateFilter: getTableFilter(todayDate),
	batchFormData: {
		posConfigurationDiscountFilter: getPosConfigurationDiscountFilter(
			todayDate
		),
	},
	batchListData: [],
	legacyFilter:
		':pos_configuration_product_variant.pos_configuration_version.pos_configuration.legacy==false',
};

function reducer(state = defaultState, action) {
	const { payload, type } = action;

	switch (type) {
		case SET_DATE_FILTERS: {
			return {
				...state,
				posConfigurationDiscountProductVariantDateFilter: getTableFilter(
					payload
				),
				batchFormData: {
					...state.batchFormData,
					posConfigurationDiscountFilter: getPosConfigurationDiscountFilter(
						payload
					),
				},
			};
		}

		case UPDATE_BATCH_FORM: {
			return {
				...state,
				batchFormData: {
					...state.batchFormData,
					...payload,
				},
			};
		}

		case ADD_BATCH_LIST: {
			return {
				...state,
				batchListData: payload,
			};
		}

		case EDIT_BATCH_LIST_ITEM: {
			return {
				...state,
				batchListData: state.batchListData.map((entry) => {
					if (entry.identifier === payload.identifier) return payload;
					return entry;
				}),
			};
		}

		case REMOVE_BATCH_LIST_ITEM: {
			return {
				...state,
				batchListData: state.batchListData.filter(
					(entry) => entry.identifier !== payload
				),
			};
		}

		case SET_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT_LEGACY_FILTER: {
			return {
				...state,
				legacyFilter: payload,
			};
		}

		case RESET_STATE: {
			return {
				...defaultState,
				posConfigurationDiscountProductVariantDateFilter:
					state.posConfigurationDiscountProductVariantDateFilter,
			};
		}

		default:
			// never return default state in default case, Filip!!!
			return state;
	}
}

export default reducer;
