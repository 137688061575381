'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import moment from 'moment';

// components
import { Button, Icon } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';
import MarketShiftRulesModalBatch from './components/batch/marketShiftRulesModalBatch';

import {
	fetchMarketShiftRules,
	addMarketShiftRule,
	deleteMarketShiftRule,
	fetchMarkets,
} from './marketShiftRules.service';

import {
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	resetState,
} from './store/marketShiftRules.actions';

// phrases/constants/enums
import phrases from './marketShiftRules.phrases';
import constants from 'services/constants';

// lodash
import _get from 'lodash/get';

class MarketShiftRules extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showAddModal: false,
			key: moment().format(constants.dateFormat),
			marketFilter: `:closed=ge='${moment.utc().format(constants.shortDate)}'`,
		};

		this.fetchData = this.fetchData.bind(this);
		this.toggleAddModal = this.toggleAddModal.bind(this);
		this.batchAddData = this.batchAddData.bind(this);

		this.reduxKey = '/shiftplanning/market_shift_rules';

		this.columns = [
			{
				Header: 'Market',
				id: 'market',
				accessor: (d) => _get(d, 'market.name', ''),
				filterPath: ':market.name',
			},
			{
				Header: 'Rule',
				id: 'rule',
				accessor: 'type',
				filterPath: ':type',
			},
		];
	}

	deleteEntry(id) {
		return deleteMarketShiftRule(id);
	}

	toggleAddModal() {
		this.setState((prevState) => ({ showAddModal: !prevState.showAddModal }));
		this.props.resetState();
	}

	getActionButtons() {
		return (
			<Button type="inverted" label="Add" shadow onClick={this.toggleAddModal}>
				<Icon name="add" />
			</Button>
		);
	}

	fetchData(state) {
		return fetchMarketShiftRules(state);
	}

	batchAddData(data) {
		return addMarketShiftRule(data)
			.then(() => {
				this.setState(() => ({ key: moment().format(constants.dateFormat) }));
			})
			.catch((err) => {
				throw err;
			});
	}

	render() {
		const {
			batchFormData,
			batchListData,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
			updateBatchForm,
			addBatchList,
			listMarkets,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					key={this.state.key}
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={this.reduxKey}
					manual
					deleteEntry={this.deleteEntry}
					editMultiple={this.editMultiple}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetWaitingTimeGridModal}
					actionRender={this.getActionButtons()}
				/>

				<MarketShiftRulesModalBatch
					modalVisible={this.state.showAddModal}
					handleClose={this.toggleAddModal}
					batchFormData={batchFormData}
					batchListData={batchListData}
					addBatchList={addBatchList}
					updateBatchForm={updateBatchForm}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					batchAddData={this.batchAddData}
					fetchMarkets={fetchMarkets}
					listMarkets={listMarkets}
				/>
			</>
		);
	}
}

MarketShiftRules.propTypes = {
	resetWaitingTimeGridModal: PropTypes.func,

	resetState: PropTypes.func,
	batchListData: PropTypes.array,
	batchFormData: PropTypes.object,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	updateBatchForm: PropTypes.func,
	addBatchList: PropTypes.func,
	listMarkets: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		batchListData: store.globalAdministrationMarketShiftRules.data.batchListData,
		batchFormData: store.globalAdministrationMarketShiftRules.data.batchFormData,
		listMarkets: store.listData['market-shift-rules-batch-/product/product_variants'],
	};
};

export default connectWithStore(MarketShiftRules, mapStateToProps, mapDispatchToProps);
