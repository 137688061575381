'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import moment from 'moment';
const __endpoints = require('endpoints.service');

export default class CountryMarketsList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'opened',
				type: 'date',
				canAdd: true,
				canEdit: true,
				required: true,
				defaultValue: moment(),
			},
			{
				name: 'closed',
				type: 'date',
				canAdd: true,
				canEdit: true,
			},
		];
		this.settings = {
			name: 'CountryMarketsList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			canCollapse: true,
			alwaysRefresh: true,
			translationPath: 'ADMINISTRATION.MARKETS',
			endpoint: __endpoints.collection.administration.markets,
		};
		this.init();
		return this;
	}
}
