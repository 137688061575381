export const SET_WORKPLACE_WEATHER = 'WEATER/SET_WORKPLACE_WEATHER';
export const CLEAR_WORKPLACE_WEATHER = 'WEATER/CLEAR_WORKPLACE_WEATHER';

/**
 * @param {string} argument
 */
export function setWorkplaceWeather(weather) {
	return {
		type: SET_WORKPLACE_WEATHER,
		payload: {
			weather
		}
	};
}

/**
 * @param {string} argument
 */
export function clearWorkplaceWeather(weather) {
	return {
		type: CLEAR_WORKPLACE_WEATHER,
		payload: {
			weather
		}
	};
}