'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import { setIngredientCategory, updateIngredientCategory, resetState } from './store/ingredientCategories.actions';

import {
	fetchIngredientCategories,
	editIngredientCategories,
	addIngredientCategories,
	deleteIngredientCategories,
} from './ingredientCategories.service';

import _get from 'lodash/get';
import phrases from './ingredientCategories.phrases';
import { Input, Button, Toggle } from 'dumb';

// Wrapper for react-data
// Documentation can be found here https://react-table.js.org/
class Ingredients extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);

		this.state = {
			pages: null,
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this.fetchData = this.fetchData.bind(this);

		this.columns = [
			{
				Header: 'Category name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},

			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: 'legacy',
				filterPath: ':legacy',
				filterable: false,
				Cell: (d) => {
					return <Input type="checkbox" checked={d.original.legacy} disabled />;
				},
			},
		];
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}
			>
				Legacy
			</Button>
		);
	}

	deleteEntry(id) {
		return deleteIngredientCategories(id);
	}

	editEntry() {
		const { defaultValues } = this.props;

		const payload = {
			name: _get(defaultValues, 'name', ''),
			legacy: !!defaultValues.legacy,
		};

		return editIngredientCategories(defaultValues.id, payload);
	}

	addEntry() {
		const { defaultValues } = this.props;

		const payload = {
			name: _get(defaultValues, 'name', ''),
		};

		return addIngredientCategories(payload);
	}

	setInitialEditValues(data) {
		this.props.setIngredientCategory(data);
	}

	getEditableCells(e) {
		const { defaultValues } = this.props;

		return [
			{
				header: 'Category name',
				value: (
					<Input
						id="name"
						placeholder="name"
						value={_get(defaultValues, 'name', '')}
						onChange={(event) => this.editStoreEntry(event.target.value, 'name')}
					/>
				),
			},
			{
				header: 'Legacy',
				value: (
					<Toggle
						id="legacy"
						toggled={defaultValues.legacy}
						onClick={(event) => this.editStoreEntry(event, 'legacy')}
					/>
				),
			},
		];
	}

	editStoreEntry(e, name) {
		const updateObject = {
			[name]: e,
		};

		this.props.updateIngredientCategory(updateObject);
	}

	fetchData(state) {
		return fetchIngredientCategories(state);
	}

	render() {
		const legacyFilter = this.state.legacyFilterOn ? this.state.legacyFilter : '';
		return (
			<ReactDataWrapper
				accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.INGREDIENT_CATEGORIES}
				className="-striped -highlight"
				columns={this.columns}
				totalEntries={this.state.totalEntries} // Display the total number of pages
				fetchData={this.fetchData} // Request new data when things change
				filterable
				defaultPageSize={10}
				reduxKey="product/ingredient_categories"
				manual // Forces table not to paginate or sort automatically, so we can handle it server-side
				editEntry={(e) => this.editEntry(e)}
				editableCells={this.getEditableCells()}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetState}
				actionsWidth={60}
				deleteEntry={this.deleteEntry}
				createEntry={this.addEntry}
				customAreaComponents={this.getLegacyFilterButton()}
				extraFilters={legacyFilter}
			/>
		);
	}
}

Ingredients.propTypes = {
	defaultValues: PropTypes.object,

	updateIngredientCategory: PropTypes.func,
	setIngredientCategory: PropTypes.func,
	resetState: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setIngredientCategory,
			updateIngredientCategory,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultValues: store.ingredientCategories.data.ingredientCatEntry,
	};
};

export default connectWithStore(Ingredients, mapStateToProps, mapDispatchToProps);
