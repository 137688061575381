import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// redux
import { useDispatch, batch } from 'react-redux';
// actions
import { setRowInteracted } from './../../../../../store/shiftClockins.actions';
import { editEntry } from 'reactDataWrapper/reactDataWrapper.actions';

// components
import { InputCleave, Tooltip } from 'dumb';

// constants/ phrases
import constants from 'services/constants';

// lodash
import _debounce from 'lodash/debounce';
import _uniqueId from 'lodash/uniqueId';

// moment
import moment from 'moment';

const ManagedClockinsFinalCell = ({
	rowId,
	value,
	date,
	update,
	row,
	reduxKey,
	dayDifference,
	dayDifferenceTooltip,
}) => {
	const updateRowEntry = ({ value }) => {
		// cleave's onChange function gets stale row value hence putting it in the reference -> https://css-tricks.com/dealing-with-stale-props-and-states-in-reacts-functional-components/
		const updatedObject = update(rowRef.current, value.date);

		dispatch(editEntry({ reduxKey, entry: updatedObject }));
	};
	const onInputChange = (value) => {
		const valueEmpty = value === '';

		// verify input - use cases where if passes - '15:3', '08:'', '09:1', '1'...
		if (!moment(value, constants.timeShort, true).isValid() && !valueEmpty)
			return;

		// construct the whole time
		const finalTime = `${date}T${value}:00+00:00`;

		const payload = {
			date: valueEmpty ? null : finalTime,
		};

		batch(() => {
			dispatch(setRowInteracted(rowId));
			updateRowEntry({ value: payload });
		});
	};
	const onInputChangeDebounced = _debounce(onInputChange, 300, {
		trailing: true,
	});

	const formattedDate = value
		? moment.utc(value, constants.dateFormat).format(constants.timeShort)
		: '';

	const dispatch = useDispatch();
	const rowRef = useRef(row);

	useEffect(() => {
		if (rowRef && row) rowRef.current = row;
	}, [row]);

	return (
		<div className="shift-clockins__table-wrapper__cell-wrapper__cell__final">
			<InputCleave
				id={_uniqueId('final-')}
				placeholder="HH:mm"
				value={formattedDate}
				noStyle
				options={{
					time: true,
					timePattern: ['h', 'm'],
				}}
				onChange={(e) => onInputChangeDebounced(e.target.value)}
			/>
			{Number(dayDifference) > 0 ? (
				<Tooltip
					text={dayDifferenceTooltip || ''}
					visible={!!dayDifferenceTooltip}
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<span
							ref={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
							className="shift-clockins__table-wrapper__cell-wrapper__cell__final__extra-day">
							+{dayDifference}
						</span>
					)}
				/>
			) : null}
		</div>
	);
};

ManagedClockinsFinalCell.propTypes = {
	value: PropTypes.string,
	date: PropTypes.string,
	rowId: PropTypes.string,
	update: PropTypes.func,
	row: PropTypes.object,
	reduxKey: PropTypes.string,
	dayDifference: PropTypes.string,
	dayDifferenceTooltip: PropTypes.string,
};

export default ManagedClockinsFinalCell;
