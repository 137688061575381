'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import { DateFilterSelector } from 'reactDataWrapper/utilities';

// components
import { InputCollectionSelect, Button, Input, Icon, SingleDatePickerInput, Tooltip } from 'dumb';
import SalesConfigurationModalBatch from './components/productVariantsPricingModalBatch/productVariantsPricingModalBatch';
import NominalPercentageEdit from './components/nominalPercentageEdit/nominalPercentageEdit';
import SubTables from './components/subTables/subTables';

// constants
import constants from 'services/constants';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import {
	resetState,
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	setProductVariantsPricing,
	updateProductVariantPricing,
	setPosConfigurationProductVariantPricingsDateFilter,
	setLegacyFilter,
} from './store/posConfigurationProductVariantsPricing.actions';

import {
	fetchSalesConfigurationProductVariantPricings,
	editSalesConfigurationProductVariantPricing,
	editSalesConfigurationProductVariantPricings,
	deleteSalesConfigurationProductVariantPricing,
	addSalesConfigurationProductVariantPricing,
} from './posConfigurationProductVariantsPricing.service';
import { getReduxKey } from './utils';

import phrases from './posConfigurationProductVariantsPricing.phrases';

import moment from 'moment';

import './posConfigurationProductVariantsPricing.css';

const reduxKey = '/pos/pos_configuration_product_variants';

class PosConfigurationProductVariantsPricing extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showAddModal: false,
			showEditModal: false,
			key: moment(),
		};

		this._editProductVariantPricing = this._editProductVariantPricing.bind(this);
		this.toggleAddModal = this.toggleAddModal.bind(this);
		this.toggleEditModal = this.toggleEditModal.bind(this);
		this._onPosConfigurationProductVariantPricingsFilterChange =
			this._onPosConfigurationProductVariantPricingsFilterChange.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editMultiplePrices = this.editMultiplePrices.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);
		this.getExtraFilters = this.getExtraFilters.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.getActionRender = this.getActionRender.bind(this);

		this.columns = [
			{
				Header: 'POS Configuration',
				id: 'posConfiguration',
				accessor: (d) => _get(d, 'pos_configuration_version.pos_configuration.name', null),
				filterPath: ':pos_configuration_version.pos_configuration.name',
			},
			{
				Header: 'Pos configuration description',
				id: 'posConfigurationDescription',
				accessor: (d) => _get(d, 'pos_configuration_version.pos_configuration.description', null),
				filterPath: ':pos_configuration_version.pos_configuration.description',
			},
			{
				Header: 'POS Configuration Version',
				id: 'posConfigurationVersion',
				accessor: (d) => _get(d, 'pos_configuration_version.name', null),
				filterPath: ':pos_configuration_version.name',
			},
			{
				Header: 'Active from',
				width: 100,
				id: 'activeFrom',
				accessor: (d) => _get(d, 'pos_configuration_version.active.from', null),
				Cell: (d) => {
					return (
						<span>{moment.utc(d.original.pos_configuration_version.active.from).format(constants.shortDate)}</span>
					);
				},
				filterPath: ':pos_configuration_version.active.from',
				Filter: ({ column }) => <DateFilterSelector reduxKey={reduxKey} columnId={column.id} />,
			},
			{
				Header: 'Active to',
				width: 100,
				id: 'activeTo',
				accessor: (d) => _get(d, 'pos_configuration_version.active.to', null),
				Cell: (d) => {
					return <span>{moment.utc(d.original.pos_configuration_version.active.to).format(constants.shortDate)}</span>;
				},
				filterPath: ':pos_configuration_version.active.to',
				Filter: ({ column }) => <DateFilterSelector reduxKey={reduxKey} columnId={column.id} />,
			},
			...(this.props.productVariant
				? []
				: [
						{
							Header: 'Product variant',
							id: 'product',
							accessor: (d) => _get(d, 'product_variant.name', null),
							filterPath: ':product_variant.name',
						},
				  ]),
			{
				Header: 'Product type',
				id: 'category',
				accessor: (d) => _get(d, 'product_variant.product.product_category.name', null),
				filterPath: ':product_variant.product.product_category.name',
			},
			{
				Header: 'Market',
				id: 'market',
				accessor: (d) => _get(d, 'pos_configuration_version.pos_configuration.market.name', null),
				filterPath: ':pos_configuration_version.pos_configuration.market.name',
			},
			{
				Header: 'Available in app',
				width: 120,
				id: 'available_in_app',
				accessor: (d) => _get(d, 'available_in_app', null),
				filterPath: ':available_in_app',
				Cell: (d) => {
					return (
						<Input
							id="avl-i-app-check"
							type="checkbox"
							checked={_get(d, 'original.available_in_app', false)}
							disabled
						/>
					);
				},
				filterable: false,
			},
			{
				Header: 'Alternative Id',
				width: 80,
				id: 'cat',
				accessor: (d) => _get(d, 'alternative_id', null),
				filterPath: ':alternative_id',
			},
			{
				Header: 'To Stay Price',
				width: 80,
				id: 'tsprice',
				accessor: 'to_stay_price',
				filterPath: ':to_stay_price',
			},
			{
				Header: 'To Stay Tax',
				width: 90,
				id: 'tstax',
				accessor: (d) => _get(d, 'to_stay_tax.rate', null),
				filterPath: ':to_stay_tax.rate',
			},
			{
				Header: 'To Go Price',
				width: 80,
				id: 'tgprice',
				accessor: 'to_go_price',
				filterPath: ':to_go_price',
			},
			{
				Header: 'To Go Tax',
				width: 90,
				id: 'tgtax',
				accessor: (d) => _get(d, 'to_go_tax.rate', null),
				filterPath: ':to_go_tax.rate',
			},
			{
				Header: 'Delivery price',
				width: 90,
				id: 'delivery-price',
				accessor: 'delivery_price',
				filterPath: ':delivery_price',
			},
			{
				Header: 'Delivery tax',
				width: 90,
				id: 'delivery-tax',
				accessor: (d) => _get(d, 'delivery_tax.rate', null),
				filterPath: ':delivery_tax.rate',
			},
		];
	}

	deleteEntry(id) {
		return deleteSalesConfigurationProductVariantPricing(id);
	}

	editEntry() {
		const { defaultProductVariantPricing } = this.props;

		const payload = {
			...(defaultProductVariantPricing.toGoPrice && {
				to_go_price: defaultProductVariantPricing.toGoPrice,
			}),
			...(defaultProductVariantPricing.toStayPrice && {
				to_stay_price: defaultProductVariantPricing.toStayPrice,
			}),
			...(defaultProductVariantPricing.toGoTax && {
				to_go_tax: defaultProductVariantPricing.toGoTax.value,
			}),
			...(defaultProductVariantPricing.toStayTax && {
				to_stay_tax: defaultProductVariantPricing.toStayTax.value,
			}),
			delivery_price:
				defaultProductVariantPricing.deliveryPrice === '' ? null : defaultProductVariantPricing.deliveryPrice,
			...(defaultProductVariantPricing.deliveryTax && {
				delivery_tax: defaultProductVariantPricing.deliveryTax.value,
			}),
			...(defaultProductVariantPricing.alternativeId && {
				alternative_id: `${defaultProductVariantPricing.alternativeId}`,
			}),
			available_in_app: defaultProductVariantPricing.availableInApp,
		};

		return editSalesConfigurationProductVariantPricing({
			payload,
			id: defaultProductVariantPricing.id,
		});
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			marketId: data.pos_configuration_version.pos_configuration.market.id,
			productVariant: data.product_variant.name,
			productType: data.product_variant.product.product_category.name,
			alternativeId: data.alternative_id,
			toStayPrice: data.to_stay_price,
			toGoPrice: data.to_go_price,
			toStayTax: {
				value: data.to_stay_tax.id,
				label: data.to_stay_tax.rate,
			},
			toGoTax: {
				value: data.to_go_tax.id,
				label: data.to_go_tax.rate,
			},
			deliveryPrice: data.delivery_price,
			deliveryTax: data.delivery_tax
				? {
						value: data.delivery_tax.id,
						label: data.delivery_tax.rate,
				  }
				: null,
			availableInApp: data.available_in_app,
		};

		this.props.setProductVariantsPricing(payload);
	}

	getEditableCellsEdit() {
		const { defaultProductVariantPricing, listPosConfigurationProductVariants, productVariant } = this.props;

		// get selected rows from listPos... and check if more then 1 market present
		const selectedRows = _get(listPosConfigurationProductVariants, 'ui.selectedRows', []);

		const numberOfMarkets = selectedRows.reduce((acc, currentRow) => {
			// get row's market
			const rowMarketId = currentRow.pos_configuration_version.pos_configuration.market.id;

			if (acc.includes(rowMarketId)) return acc;

			acc = [...acc, rowMarketId];

			return acc;
		}, []).length;

		// if multi edit and only 1 market present - get marketId from first selected row
		const marketIdFromSelectedRow = !_isEmpty(selectedRows)
			? selectedRows[0].pos_configuration_version.pos_configuration.market.id
			: null;

		return [
			{
				header: 'Product variant',
				value: (
					// if productVariant passed from parent component, always default to showing it
					<span>{productVariant?.name || defaultProductVariantPricing.productVariant}</span>
				),
			},
			{
				header: 'Product type',
				value: <span>{defaultProductVariantPricing.productType}</span>,
			},
			{
				header: 'Alternative Id',
				value: (
					<Input
						id="alternativeId"
						placeholder="Enter Alternative id..."
						value={defaultProductVariantPricing.alternativeId}
						onChange={(e) => this._editProductVariantPricing(e.target.value, 'alternativeId')}
						disabled={numberOfMarkets > 1}
					/>
				),
			},
			{
				header: 'To Stay Price',
				value: (
					<Input
						id="toStayPrice"
						type="number"
						placeholder="Enter To stay price..."
						onChange={(e) => this._editProductVariantPricing(e.target.value, 'toStayPrice')}
						value={defaultProductVariantPricing.toStayPrice}
					/>
				),
			},
			{
				header: 'To Stay Tax',
				value: (
					<InputCollectionSelect
						id="toStayTax"
						value={defaultProductVariantPricing.toStayTax}
						handleChange={(key, value) => this._editProductVariantPricing(value, 'toStayTax')}
						clearable={false}
						cache
						placeholder="Select To stay tax..."
						optionFormat={(entry) => ({
							value: entry.id,
							label: `${entry.name} (${entry.rate}%)`,
						})}
						apiPath="/administration/market_tax_rates"
						params={{
							limit: 30,
							filter: `(:market.id=='${defaultProductVariantPricing.marketId || marketIdFromSelectedRow}')`,
						}}
						disabled={numberOfMarkets > 1}
					/>
				),
			},
			{
				header: 'To Go Price',
				value: (
					<Input
						id="toGoPrice"
						type="number"
						placeholder="Enter To go price..."
						onChange={(e) => this._editProductVariantPricing(e.target.value, 'toGoPrice')}
						value={defaultProductVariantPricing.toGoPrice}
					/>
				),
			},
			{
				header: 'To Go Tax',
				value: (
					<InputCollectionSelect
						id="toGoTax"
						value={defaultProductVariantPricing.toGoTax}
						handleChange={(key, value) => this._editProductVariantPricing(value, 'toGoTax')}
						clearable={false}
						cache
						placeholder="Select To go tax..."
						optionFormat={(entry) => ({
							value: entry.id,
							label: `${entry.name} (${entry.rate}%)`,
						})}
						apiPath="/administration/market_tax_rates"
						params={{
							limit: 30,
							filter: `(:market.id=='${defaultProductVariantPricing.marketId || marketIdFromSelectedRow}')`,
						}}
						disabled={numberOfMarkets > 1}
					/>
				),
			},
			{
				header: 'Delivery Price',
				value: (
					<Input
						id="delivery-price"
						type="number"
						placeholder="Enter delivery price..."
						onChange={(e) => this._editProductVariantPricing(e.target.value, 'deliveryPrice')}
						value={defaultProductVariantPricing.deliveryPrice}
					/>
				),
			},
			{
				header: 'Delivery Tax',
				value: (
					<InputCollectionSelect
						id="delivery-tax"
						value={defaultProductVariantPricing.deliveryTax}
						handleChange={(key, value) => this._editProductVariantPricing(value, 'deliveryTax')}
						clearable={false}
						cache
						placeholder="Select Delivery tax..."
						optionFormat={(entry) => ({
							value: entry.id,
							label: `${entry.name} (${entry.rate}%)`,
						})}
						apiPath="/administration/market_tax_rates"
						params={{
							limit: 30,
							filter: `(:market.id=='${defaultProductVariantPricing.marketId || marketIdFromSelectedRow}')`,
						}}
						disabled={numberOfMarkets > 1}
					/>
				),
			},
			{
				header: 'Available in app',
				value: (
					<Input
						id="availableInApp"
						type="checkbox"
						placeholder="Available in app..."
						checked={defaultProductVariantPricing.availableInApp}
						onChange={(e) => this._editProductVariantPricing(e.target.checked, 'availableInApp')}
						disabled={numberOfMarkets > 1}
					/>
				),
			},
		];
	}

	_editProductVariantPricing(e, name) {
		const payload = {
			[name]: e,
		};

		this.props.updateProductVariantPricing(payload);
	}

	editMultiplePrices(selectedRows) {
		const { defaultProductVariantPricing } = this.props;

		const payload = {
			...(defaultProductVariantPricing.toGoPrice && {
				to_go_price: defaultProductVariantPricing.toGoPrice,
			}),
			...(defaultProductVariantPricing.toStayPrice && {
				to_stay_price: defaultProductVariantPricing.toStayPrice,
			}),
			...(defaultProductVariantPricing.toGoTax && {
				to_go_tax: defaultProductVariantPricing.toGoTax.value,
			}),
			...(defaultProductVariantPricing.toStayTax && {
				to_stay_tax: defaultProductVariantPricing.toStayTax.value,
			}),
			...(defaultProductVariantPricing.deliveryPrice && {
				delivery_price: defaultProductVariantPricing.deliveryPrice,
			}),
			...(defaultProductVariantPricing.deliveryTax && {
				delivery_tax: defaultProductVariantPricing.deliveryTax.value,
			}),
			...(defaultProductVariantPricing.alternativeId && {
				alternative_id: defaultProductVariantPricing.alternativeId,
			}),
			available_in_app: defaultProductVariantPricing.availableInApp,
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editSalesConfigurationProductVariantPricings({
			batch: selectedRowsWithId,
		});
	}

	// redirect(data, e) {
	// 	e.stopPropagation();

	// 	const pricingId = data.id;
	// 	const posConfigurationVersionId = _get(
	// 		data,
	// 		'pos_configuration_version.id',
	// 		null
	// 	);
	// 	const posConfigurationId = _get(
	// 		data,
	// 		'pos_configuration_version.pos_configuration.id',
	// 		null
	// 	);

	// 	go(
	// 		'jtj.globalAdministration.pos-configuration.details.pricings.product-variant-details',
	// 		{
	// 			id: posConfigurationId,
	// 			versionId: posConfigurationVersionId,
	// 			pricingId,
	// 		}
	// 	);
	// }

	// renderDetailsButton(d) {
	// 	return (
	// 		<Button
	// 			key="details"
	// 			type="inverted"
	// 			shadow
	// 			onClick={(e) => this.redirect(d.original, e)}
	// 			size="micro">
	// 			<Icon name="tune" />
	// 		</Button>
	// 	);
	// }

	toggleAddModal() {
		const { updateBatchForm, productVariant, resetState } = this.props;

		// if product variant passed from parent component
		// this table is referenced in salesConfiguration -> products -> productVariants
		if (!this.state.showModal && productVariant) {
			const payload = {
				productVariantPropPassed: true,
				productVariant: [
					{
						value: productVariant,
						label: productVariant.name,
					},
				],
			};

			updateBatchForm(payload);
		}

		resetState();
		this.setState((prevState) => ({ showAddModal: !prevState.showAddModal }));
	}

	toggleEditModal() {
		const { listPosConfigurationProductVariantPricings, addBatchList } = this.props;

		if (!this.state.showEditModal) {
			const selectedRows = listPosConfigurationProductVariantPricings?.ui?.selectedRows;

			const formattedSelectedRows = selectedRows.map((entry) => ({
				identifier: entry.id,
				toStayPrice: entry.to_stay_price,
				toGoPrice: entry.to_go_price,
				productVariantName: entry.product_variant?.name,
				posConfigurationVersionName: entry.pos_configuration_version?.name,
				posConfigurationName: entry.pos_configuration_version?.pos_configuration?.name,
			}));

			// set selected rows in redux
			addBatchList(formattedSelectedRows);
		} else this.props.resetState();

		this.setState((prevState) => ({
			showEditModal: !prevState.showEditModal,
		}));
	}

	_onPosConfigurationProductVariantPricingsFilterChange(date) {
		const { setPosConfigurationProductVariantPricingsDateFilter } = this.props;

		const formattedDate = date ? moment.utc(date).format(constants.shortDate) : null;

		const filterString = formattedDate
			? `:pos_configuration_version.active.from=le='${formattedDate}';:pos_configuration_version.active.to=ge='${formattedDate}'`
			: '';

		setPosConfigurationProductVariantPricingsDateFilter(filterString);
	}

	toggleLegacyFilter() {
		const { setLegacyFilter, legacyFilter } = this.props;

		const filter = legacyFilter ? '' : ':pos_configuration_version.pos_configuration.legacy==false';

		setLegacyFilter(filter);
	}

	getProductVariantPricingFilterComponents() {
		const { posConfigurationProductVariantPricingsDateFilter, legacyFilter } = this.props;

		return (
			<div className="pos-configuration-product-variants-pricing__filter-area">
				<SingleDatePickerInput
					id="posConfigDiscountDatePicker"
					label="Select active date"
					type="single"
					appendToBody
					fixed
					onChange={this._onPosConfigurationProductVariantPricingsFilterChange}
					selectedDate={posConfigurationProductVariantPricingsDateFilter}
					noClockButton
					clearButtonDisabled={!posConfigurationProductVariantPricingsDateFilter}
					clearable
				/>
				<Tooltip
					text={legacyFilter ? phrases.LEGACY_FILTER_TOOLTIP_ACTIVE : phrases.LEGACY_FILTER_TOOLTIP_UNACTIVE}
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							id="legacyFilterButton"
							className="pos-configuration-product-variants-pricing__filter-area__legacy-button"
							onClick={this.toggleLegacyFilter}
							type={legacyFilter ? '' : 'inverted'}
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}>
							Legacy
						</Button>
					)}
				/>
			</div>
		);
	}

	getExtraFilters() {
		const { posConfigurationProductVariantPricingsDateFilter, legacyFilter } = this.props;

		let filter = posConfigurationProductVariantPricingsDateFilter;
		if (legacyFilter && posConfigurationProductVariantPricingsDateFilter) filter = `${filter};${legacyFilter}`;
		if (!posConfigurationProductVariantPricingsDateFilter) filter = legacyFilter;

		return filter;
	}

	fetchData(data) {
		const { productVariant } = this.props;

		const filter = productVariant ? `:product_variant.id=='${productVariant.id}'` : '';

		return fetchSalesConfigurationProductVariantPricings(data, filter);
	}

	getActionRender() {
		const { listPosConfigurationProductVariantPricings } = this.props;

		const selectedRows = listPosConfigurationProductVariantPricings?.ui?.selectedRows;

		return (
			<>
				<Button type="inverted" label="Batch" shadow onClick={this.toggleAddModal}>
					<Icon name="add" />
				</Button>
				{!_isEmpty(selectedRows) && (
					<Tooltip
						text={phrases.NOMINAL_PERCENTAGE_EDIT}
						placement="left"
						renderData={(ref, onMouseEnter, onMouseLeave) => (
							<Button
								type="inverted"
								label="Adjust prices"
								shadow
								onClick={this.toggleEditModal}
								refProp={ref}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}>
								<Icon name="edit" />
							</Button>
						)}
					/>
				)}
			</>
		);
	}

	render() {
		const {
			updateBatchForm,
			batchFormData,
			listProductVariants,
			listPosConfigurationVersions,
			addBatchList,
			batchList,
			editBatchListItem,
			removeBatchListItem,
			resetState,
			productVariant,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					accessAreasAllowedToEdit={['Sales Configuration']}
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={50}
					reduxKey={getReduxKey(reduxKey, productVariant)}
					manual
					editEntry={() => this.editEntry()}
					editableCellsEdit={this.getEditableCellsEdit()}
					setInitialEditValues={this.setInitialEditValues}
					customAreaComponents={this.getProductVariantPricingFilterComponents()}
					extraFilters={this.getExtraFilters()}
					onModalClose={resetState}
					// actions={(d) => this.renderDetailsButton(d)}
					actionsWidth={30}
					editMultiple={this.editMultiplePrices}
					deleteEntry={this.deleteEntry}
					deleteConfirm
					actionRender={this.getActionRender()}
					subcomponent={(row) => <SubTables row={row} />}
				/>

				<SalesConfigurationModalBatch
					modalVisible={this.state.showAddModal}
					handleClose={this.toggleAddModal}
					batchFormData={batchFormData}
					updateBatchForm={updateBatchForm}
					fetchSalesConfigurationProductVariantPricings={fetchSalesConfigurationProductVariantPricings}
					listPosConfigurationVersions={listPosConfigurationVersions}
					listProductVariants={listProductVariants}
					addBatchList={addBatchList}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addSalesConfigurationProductVariantPricing={addSalesConfigurationProductVariantPricing}
					productVariant={productVariant}
				/>

				<NominalPercentageEdit
					modalVisible={this.state.showEditModal}
					handleClose={this.toggleEditModal}
					batchFormData={batchFormData}
					updateBatchForm={updateBatchForm}
					addBatchList={addBatchList}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					editSalesConfigurationProductVariantPricings={editSalesConfigurationProductVariantPricings}
					reduxKey={getReduxKey(reduxKey, productVariant)}
					removeBatchListItem={removeBatchListItem}
				/>
			</>
		);
	}
}

PosConfigurationProductVariantsPricing.propTypes = {
	defaultProductVariantPricing: PropTypes.object,
	listPosConfigurationVersions: PropTypes.object,
	listProductVariants: PropTypes.object,
	setProductVariantsPricing: PropTypes.func,
	resetState: PropTypes.func,
	marketId: PropTypes.number,
	updateBatchForm: PropTypes.func,
	addBatchList: PropTypes.func,
	batchFormData: PropTypes.object,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	updateProductVariantPricing: PropTypes.func,
	setPosConfigurationProductVariantPricingsDateFilter: PropTypes.func,
	posConfigurationProductVariantPricingsDateFilter: PropTypes.string,
	listPosConfigurationProductVariants: PropTypes.object,
	legacyFilter: PropTypes.string,
	setLegacyFilter: PropTypes.func,
	productVariant: PropTypes.object,
	listPosConfigurationProductVariantPricings: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			resetState,
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			setProductVariantsPricing,
			updateProductVariantPricing,
			setPosConfigurationProductVariantPricingsDateFilter,
			setLegacyFilter,
		},
		dispatch
	);
};

const mapStateToProps = (store, ownProps) => {
	const tableReduxKey = getReduxKey(reduxKey, ownProps.productVariant);

	return {
		batchFormData: store.posConfigurationProductVariantsPricing.data.batchFormData,
		batchList: store.posConfigurationProductVariantsPricing.data.batchList,
		posConfigurationProductVariantPricingsDateFilter:
			store.posConfigurationProductVariantsPricing.posConfigurationProductVariantPricingsDateFilter,
		legacyFilter: store.posConfigurationProductVariantsPricing.legacyFilter,
		listPosConfigurationVersions: store.listData['batch-list/pos/pos_configuration_versions'],
		listProductVariants: store.listData['batch-list/product/product_variants'],
		listPosConfigurationProductVariants: store.listData['/pos/pos_configuration_product_variants'],
		listPosConfigurationProductVariantPricings: store.listData[tableReduxKey],
		defaultProductVariantPricing: store.posConfigurationProductVariantsPricing.data.productVariantPricing,
	};
};

export default connectWithStore(PosConfigurationProductVariantsPricing, mapStateToProps, mapDispatchToProps);
