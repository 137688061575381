'use strict';

import _has from 'lodash/has';
import _some from 'lodash/some';

module.exports = {
	listActions: function (props) {
		const actions = [
			{
				method: props.methods.handleToggleIsBarFiltering,
				active: [props.isBarFiltering],
				icon: 'icon--filter_list',
				validExpressions: [
					!props.settings.canSelect,
					!props.isCollapsed,
					props.settings.canFilterBar,
					!props.isSearchList,
				],
				name: 'filter',
			},
			{
				method: props.methods.handleToggleControls.bind(this, 'isColumnSelecting'),
				active: [props.isColumnSelecting],
				icon: 'icon--view_week',
				validExpressions: [props.settings.canToggleColumns, !props.isCollapsed],
				name: 'columns',
			},
			{
				method: props.methods.handleToggleAddEditRow.bind(this, 'add'),
				active: [props.isAdding],
				icon: 'icon--playlist_add',
				validExpressions: [
					props.settings.canAdd,
					props.settings.canManipulate(),
					_some(props.headers, 'canAdd'),
					!props.isCollapsed,
					!props.isSearchList,
				],
				name: 'add',
			},
			{
				method: props.settings.canExportWithRange
					? props.methods.handleSetIsCSVDateRangeVisible
					: props.methods.handleCSVExport,
				icon: 'icon--file_download',
				validExpressions: [
					props.collectionLength > 0,
					props.settings.canExport,
					!props.isCollapsed,
					!props.isSearchList,
				],
				name: 'csvexport',
			},
			{
				method: props.methods.handleTogglePrintView,
				icon: 'icon--print',
				validExpressions: [
					props.collectionLength > 0,
					props.settings.printConfig,
					!props.isCollapsed,
					!props.isSearchList,
				],
				name: 'print',
			},
			{
				method: props.methods.handleNavigate.bind(this, 'create'),
				icon: 'icon--add',
				validExpressions: [
					props.settings.canCreate,
					props.settings.canManipulate(),
					!props.isCollapsed,
					!props.isSearchList,
				],
				name: 'add',
			},
			{
				method: props.methods.handleRefreshCollection,
				icon: 'icon--refresh',
				validExpressions: [!props.isCollapsed],
				name: 'refresh',
			},
			{
				method: props.methods.handleToggleControls.bind(this, 'isCollapsed'),
				active: [props.isCollapsed],
				icon: {
					'icon--visibility': props.isCollapsed,
					'icon--visibility_off': !props.isCollapsed,
				},
				validExpressions: [props.settings.canCollapse],
				name: 'collapse',
			},
		];
		return actions;
	},
	params: {
		offset: 0,
		limit: 50,
	},

	settings: {
		allowEmpty: false,
		accordions: [],
		alwaysRefresh: true,
		callbacks: [],
		canAdd: false,
		canCollapse: false,
		canCreate: false,
		canDelete: false,
		canEdit: false,
		canExport: true,
		canFilterBar: true,
		canLock: false,
		canSearch: true,
		canSelect: false,
		canToggleColumns: true,
		canZoom: false,
		collapseHeader: false,
		defaultValue: false,
		hasDetails: false,
		canManipulate: () => false,
		hotbarConfig: null,
		minWidthBuffer: 250,
		pageTitle: false,
		scrollWrapper: false,
		tinyText: true,
	},
	state: {
		accordions: {},
		accordionsBase: [],
		collection: [],
		collectionLength: null,
		data: {},
		error: {},
		delayedRefresh: false,
		editRowId: null,
		filterOperators: {},
		filters: {},
		headers: [],
		hotbar: {},
		isAdding: false,
		isBarFiltering: false,
		isCollapsed: false,
		isColumnSelecting: false,
		isCreating: false,
		isEditing: false,
		isExporting: false,
		isSearchFiltering: false,
		isSearchList: false,
		listActions: function () {},
		model: {},
		params: {},
		rowActions: function (props, row) {
			const actions = [
				{
					method: props.methods.handleNavigate.bind(this, 'details', row),
					icon: 'icon--mode_edit',
					validExpressions: [props.settings.hasDetails, props.settings.canManipulate()],
					name: 'edit',
				},
				{
					method: props.methods.handleToggleAddEditRow.bind(this, 'edit', row),
					icon: 'icon--edit',
					validExpressions: [props.settings.canEdit, props.settings.canManipulate(), _some(props.headers, 'canEdit')],
					name: 'edit',
				},
				{
					method: props.methods.handleDeleteItem.bind(this, row),
					icon: 'icon--delete',
					validExpressions: [props.settings.canDelete, props.settings.canManipulate()],
					name: 'delete',
				},
				{
					method: props.methods.handleToggleAccordion.bind(this, row),
					icon: {
						'icon--more_vert': !_has(props.accordions, row.id),
						'icon--close': _has(props.accordions, row.id),
					},
					validExpressions: [props.settings.canAccordion],
					name: 'fold',
				},
			];
			return actions;
		},
		selectedRowId: null,
		showList: true,
		sort: {},
		title: undefined,
		toggleShowControls: false,
		togglePrintView: false,
	},
};
