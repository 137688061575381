import { get } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import constants from 'services/constants';

/**
 * @function fetchEmployments
 * @description Fetch employments
 * @param {object} state
 */
export function fetchEmployments(state) {
	let filter;

	// if headers not passed add the ended filter
	if (!state.headers) {
		const preFilter = `:ended=ge='${moment().format(constants.shortDate)}'`;
		filter = state.filter ? `${preFilter};${state.filter}` : preFilter;
	}

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		from: state.from,
		to: state.to,
		filter
	};

	return get('/hr/employments', params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
