export const RESET_STATE =
	'GLOBAL_ADMINISTRATION_WAITING_TIME_PERFORMANCE_GRIDS/RESET_STATE';
export const SET_WAITING_TIME_PERFORMANCE_GRIDS =
	'GLOBAL_ADMINISTRATION_WAITING_TIME_PERFORMANCE_GRIDS/SET_WAITING_TIME_PERFORMANCE_GRIDS';
export const UPDATE_WAITING_TIME_PERFORMANCE_GRIDS =
	'GLOBAL_ADMINISTRATION_WAITING_TIME_PERFORMANCE_GRIDS/UPDATE_WAITING_TIME_PERFORMANCE_GRIDS';

export const EDIT_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_WAITING_TIME_PERFORMANCE_GRIDS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_WAITING_TIME_PERFORMANCE_GRIDS/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'GLOBAL_ADMINISTRATION_WAITING_TIME_PERFORMANCE_GRIDS/ADD_BATCH_LIST_ITEM_ROW';

export function setWaitingTimePerformanceGrids(payload) {
	return {
		type: SET_WAITING_TIME_PERFORMANCE_GRIDS,
		payload,
	};
}

export function updateWaitingTimePerformanceGrids(payload) {
	return {
		type: UPDATE_WAITING_TIME_PERFORMANCE_GRIDS,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}
