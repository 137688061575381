export default {
	TITLE: 'Basket recommendation priorities',
	BASKET_RECOMMENDATION_PRIORITY_ADDED: 'Basket recommendation priority added!',
	BASKET_RECOMMENDATION_PRIORITY_EDITED:
		'Basket recommendation priority edited!',
	BASKET_RECOMMENDATION_PRIORITIES_DELETED:
		'Basket recommendation priorities deleted!',
	ADD: 'Add',
	EDIT: 'Edit',

	ADD_BASKET_RECOMMENDATION_PRIORITY: 'Create basket recommendation priorities',
	EDIT_BASKET_RECOMMENDATION_PRIORITY: 'Edit basket recommendation priorities',
	NAME: 'Name',
	NAME_PLACEHOLDER: 'Enter name...',
	DESCRIPTION: 'Description',
	DESCRIPTION_PLACEHOLDER: 'Enter description...',
	RECOMMENDATION: 'Recommendation',
	TYPE: 'Type',
	TYPE_PLACEHOLDER: 'Select type...',
	SORT_ORDER: 'Sort order',
	SORT_ORDER_PLACEHOLDER: 'Enter sort order...',
	ADD_REPLACEMENTS: 'Add replacements',
	REPLACEMENT: 'Replacement',
	REPLACEMENT_PLACEHOLDER: 'Select replacement...',
	LEGACY: 'Legacy',
	SUBMIT: 'Submit',
};
