'use strict';

// Services
import DetailsModel from 'common/components/details/detailsModel.service';
const __endpoints = require('endpoints.service');
const __enums = require('enums.service');

export default class TagsDetails extends DetailsModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'type',
				type: 'select',
				canCreate: true,
				canEdit: true,
				required: true,
				options: {
					enums: __enums.tagsList,
				},
				span: 2,
			},
			{
				name: 'subtype',
				type: 'select',
				canCreate: true,
				canEdit: true,
				required: true,
				options: {
					enums: __enums.tagsSubtypesList,
				},
				span: 2,
			},
			{
				name: 'name',
				type: 'string',
				canCreate: true,
				canEdit: true,
				required: true,
				span: 2,
			},
			{
				name: 'name_abbreviated',
				type: 'string',
				canAdd: true,
				canEdit: true,
				canCreate: true,
			},
			{
				name: 'global_account',
				type: 'select',
				canCreate: true,
				canEdit: true,
				reference: {
					key: 'name',
					list: '/accounting/global_accounts',
				},
				span: 2,
			},
			{
				name: 'exclude_productivity',
				type: 'bool',
				canCreate: true,
				canEdit: true,
				span: 2,
			},
			{
				name: 'exclude_base_pay',
				type: 'bool',
				canCreate: true,
				canEdit: true,
				span: 2,
			},
			{
				name: 'exclude_addon',
				type: 'bool',
				canCreate: true,
				canEdit: true,
				span: 2,
			},
			{
				name: 'exclude_overtime',
				type: 'bool',
				canCreate: true,
				canEdit: true,
				span: 2,
			},
			{
				name: 'current_year_index',
				type: 'bool',
				canAdd: true,
				canEdit: true,
			},

			{
				name: 'prior_year_index',
				type: 'bool',
				canAdd: true,
				canEdit: true,
			},

			{
				name: 'color',
				type: 'color',
				canCreate: true,
				canEdit: true,
				required: true,
				span: 2,
			},
		];
		this.settings = {
			name: 'TagsDetails',
			canCreate: true,
			canEdit: true,
			canDelete: true,
			translationPath: 'TAGGING.TAGS',
			endpoint: __endpoints.collection.tagging.tags,
		};
		this.init();
		return this;
	}
}
