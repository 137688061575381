'use strict';

import { ReportFactory as R } from 'report/factory';

const t = R.table();

// Base columns keyed out
const columns = {
	emptyTotal: [
		t.column('', [
			t.content('')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.total.turnover'), 9), 'currency', [t.className('pos-reports__column-separator')]),
		t.column('', [
			t.content('')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.total.products'), 9), 'number'),
		t.column('', [
			t.content('')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.total.employees'), 9), 'number'),
		t.column('', [
			t.content('')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.total.php_max'), 9), 'currency')
	],
	total: [
		t.column('Turnover', [
			t.content(d => R.data(d, 'total.turnover'), 'currencyNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.total.turnover'), 9), 'currency', [t.className('pos-reports__column-separator')]),
		t.column('Products', [
			t.content(d => R.data(d, 'total.products'), 'numberNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.total.products'), 9), 'number'),
		t.column('Employee', [
			t.content(d => R.data(d, 'total.employees'), 'numberNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.total.employees'), 9), 'number'),
		t.column('PHP Max', [
			t.content(d => R.data(d, 'total.php_max'), 'currencyNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.total.php_max'), 9), 'currency')
	],
	// emptyAverage: [
	// 	t.column('', [
	// 		t.content('')
	// 	], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.average.turnover'), 9), 'currency', [t.className('pos-reports__column-separator')]),
	// 	t.column('', [
	// 		t.content('')
	// 	], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.average.products'), 9), 'number'),
	// 	t.column('', [
	// 		t.content('')
	// 	], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.average.employees'), 9), 'number'),
	// 	t.column('', [
	// 		t.content('')
	// 	], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.average.php_max'), 9), 'currency')
	// ],
	// average: [
	// 	t.column('Turnover', [
	// 		t.content(d => R.data(d, 'average.turnover'), 'currencyNoDecimal')
	// 	], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.average.turnover'), 9), 'currency', [t.className('pos-reports__column-separator')]),
	// 	t.column('Products', [
	// 		t.content(d => R.data(d, 'average.products'), 'numberNoDecimal')
	// 	], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.average.products'), 9), 'number'),
	// 	t.column('Employee', [
	// 		t.content(d => R.data(d, 'average.employees'), 'numberNoDecimal')
	// 	], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.average.employees'), 9), 'number'),
	// 	t.column('PHP Max', [
	// 		t.content(d => R.data(d, 'average.php_max'), 'currencyNoDecimal')
	// 	], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.average.php_max'), 9), 'currency')
	// ],
	productSplit: [
		t.column('Juice', [
			t.content(d => R.data(d, 'categories.juice.turnover'), 'currencyNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.categories.juice.turnover'), 10), 'currency', [t.className('pos-reports__column-separator')]),
		t.column('#', [
			t.content(d => R.data(d, 'categories.juice.products'), 'numberNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.categories.juice.products'), 8), 'number'),
		t.column('%', [
			t.content(d => R.data(d, 'categories.juice.product_percentage'), 'percentageNoDecimal')
		], 40, 'number'),
		t.column('Shake', [
			t.content(d => R.data(d, 'categories.shake.turnover'), 'currencyNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.categories.shake.turnover'), 10), 'currency', [t.className('pos-reports__column-separator')]),
		t.column('#', [
			t.content(d => R.data(d, 'categories.shake.products'), 'numberNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.categories.shake.products'), 8), 'number'),
		t.column('%', [
			t.content(d => R.data(d, 'categories.shake.product_percentage'), 'percentageNoDecimal')
		], 40, 'number'),
		t.column('Sandwich', [
			t.content(d => R.data(d, 'categories.sandwich.turnover'), 'currencyNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.categories.sandwich.turnover'), 10), 'currency', [t.className('pos-reports__column-separator')]),
		t.column('#', [
			t.content(d => R.data(d, 'categories.sandwich.products'), 'numberNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.categories.sandwich.products'), 8), 'number'),
		t.column('%', [
			t.content(d => R.data(d, 'categories.sandwich.product_percentage'), 'percentageNoDecimal')
		], 40, 'number'),
		t.column('Coffee', [
			t.content(d => R.data(d, 'categories.coffee.turnover'), 'currencyNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.categories.coffee.turnover'), 10), 'currency', [t.className('pos-reports__column-separator')]),
		t.column('#', [
			t.content(d => R.data(d, 'categories.coffee.products'), 'numberNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.categories.coffee.products'), 8), 'number'),
		t.column('%', [
			t.content(d => R.data(d, 'categories.coffee.product_percentage'), 'percentageNoDecimal')
		], 40, 'number'),
		t.column('Loyalty', [
			t.content(d => R.data(d, 'categories.loyalty_card.turnover'), 'currencyNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.categories.loyalty_card.turnover'), 10), 'currency', [t.className('pos-reports__column-separator')]),
		t.column('#', [
			t.content(d => R.data(d, 'categories.loyalty_card.products'), 'numberNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.categories.loyalty_card.products'), 8), 'number'),
		t.column('%', [
			t.content(d => R.data(d, 'categories.loyalty_card.product_percentage'), 'percentageNoDecimal')
		], 40, 'number'),
		t.column('Breakfast', [
			t.content(d => R.data(d, 'categories.breakfast.turnover'), 'currencyNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.categories.breakfast.turnover'), 10), 'currency', [t.className('pos-reports__column-separator')]),
		t.column('#', [
			t.content(d => R.data(d, 'categories.breakfast.products'), 'numberNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.categories.breakfast.products'), 8), 'number'),
		t.column('%', [
			t.content(d => R.data(d, 'categories.breakfast.product_percentage'), 'percentageNoDecimal')
		], 40, 'number'),

		t.column('Counter product', [
			t.content(d => R.data(d, 'categories.counter_product.turnover'), 'currencyNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.categories.counter_product.turnover'), 10), 'currency', [t.className('pos-reports__column-separator')]),
		t.column('#', [
			t.content(d => R.data(d, 'categories.counter_product.products'), 'numberNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.categories.counter_product.products'), 8), 'number'),
		t.column('%', [
			t.content(d => R.data(d, 'categories.counter_product.product_percentage'), 'percentageNoDecimal')
		], 40, 'number'),

		t.column('Salad', [
			t.content(d => R.data(d, 'categories.salad.turnover'), 'currencyNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.categories.salad.turnover'), 10), 'currency', [t.className('pos-reports__column-separator')]),
		t.column('#', [
			t.content(d => R.data(d, 'categories.salad.products'), 'numberNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.categories.salad.products'), 8), 'number'),
		t.column('%', [
			t.content(d => R.data(d, 'categories.salad.product_percentage'), 'percentageNoDecimal')
		], 40, 'number'),


		t.column('Topping', [
			t.content(d => R.data(d, 'categories.topping.turnover'), 'currencyNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.categories.topping.turnover'), 10), 'currency', [t.className('pos-reports__column-separator')]),
		t.column('#', [
			t.content(d => R.data(d, 'categories.topping.products'), 'numberNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.categories.topping.products'), 8), 'number'),
		t.column('%', [
			t.content(d => R.data(d, 'categories.topping.product_percentage'), 'percentageNoDecimal')
		], 40, 'number'),

		t.column('Misc.', [
			t.content(d => R.data(d, 'categories.misc.turnover'), 'currencyNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.categories.misc.turnover'), 10), 'currency', [t.className('pos-reports__column-separator')]),
		t.column('#', [
			t.content(d => R.data(d, 'categories.misc.products'), 'numberNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.categories.misc.products'), 8), 'number'),
		t.column('%', [
			t.content(d => R.data(d, 'categories.misc.product_percentage'), 'percentageNoDecimal')
		], 40, 'number')
	],
	emptyEmployeeMeals: [
		t.column('', [
			t.content('')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.employee_meal.products'), 9), 'currency', [t.className('pos-reports__column-separator')]),
		t.column('', [
			t.content('')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.employee_meal.percentage'), 9), 'number')
	],
	employeeMeals: [
		t.column('Products', [
			t.content(d => R.data(d, 'employee_meal.products'), 'currencyNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.employee_meal.products'), 9), 'currency', [t.className('pos-reports__column-separator')]),
		t.column('%', [
			t.content(d => R.data(d, 'employee_meal.percentage'), 'numberNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.employee_meal.percentage'), 9), 'number')
	],
	emptyDiscounts: [
		t.column('', [
			t.content('')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.discount_group.employee.products'), 9), 'number', [t.className('pos-reports__column-separator')]),
		t.column('', [
			t.content('')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.discount_group.employee.percentage'), 9), 'percentageNoDecimal'),
		t.column('', [
			t.content('')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.discount_group.employee_meal.products'), 9), 'number', [t.className('pos-reports__column-separator')]),
		t.column('', [
			t.content('')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.discount_group.employee_meal.percentage'), 9), 'percentageNoDecimal'),
		t.column('', [
			t.content('')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.discount_group.loyalty.products'), 9), 'number', [t.className('pos-reports__column-separator')]),
		t.column('', [
			t.content('')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.discount_group.loyalty.percentage'), 9), 'percentageNoDecimal'),
		t.column('', [
			t.content('')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.discount_group.neighbour.products'), 9), 'number', [t.className('pos-reports__column-separator')]),
		t.column('', [
			t.content('')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.discount_group.neighbour.percentage'), 9), 'percentageNoDecimal')
	],
	discounts: [
		t.column('Employee', [
			t.content(d => R.data(d, 'discount_group.employee.products'), 'currencyNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.discount_group.employee.products'), 9), 'number', [t.className('pos-reports__column-separator')]),
		t.column('%', [
			t.content(d => R.data(d, 'discount_group.employee.percentage'), 'percentageNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.discount_group.employee.percentage'), 9), 'number'),
		t.column('Employee Meal', [
			t.content(d => R.data(d, 'discount_group.employee_meal.products'), 'currencyNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.discount_group.employee_meal.products'), 9), 'number', [t.className('pos-reports__column-separator')]),
		t.column('%', [
			t.content(d => R.data(d, 'discount_group.employee_meal.percentage'), 'percentageNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.discount_group.employee_meal.percentage'), 9), 'number'),
		t.column('Loayalty', [
			t.content(d => R.data(d, 'discount_group.loyalty.products'), 'currencyNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.discount_group.loyalty.products'), 9), 'number', [t.className('pos-reports__column-separator')]),
		t.column('%', [
			t.content(d => R.data(d, 'discount_group.loyalty.percentage'), 'percentageNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.discount_group.loyalty.percentage'), 9), 'number'),
		t.column('Neighbour', [
			t.content(d => R.data(d, 'discount_group.neighbour.products'), 'currencyNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.discount_group.neighbour.products'), 9), 'number', [t.className('pos-reports__column-separator')]),
		t.column('%', [
			t.content(d => R.data(d, 'discount_group.neighbour.percentage'), 'percentageNoDecimal')
		], t.columnWidth(d => R.data(d, 'entities["POS REPORT: WORLD"].total.data.discount_group.neighbour.percentage'), 9), 'number')
	]
};

// First column width
const firstColumnWidth = 160;

class PosReportBaseColumnsService {

	/**
	 * @function _withGroups
	 * @memberOf PosReportBaseColumnsService
	 * @description
	 * Returns columns wrapped in groups
	 */
	_withGroups() {
		return [
			t.group('Total', columns.total, [t.className('pos-reports__column-separator')]),
			// t.group('Average', columns.average, [t.className('pos-reports__column-separator')]),
			t.group('Product Split', columns.productSplit, [t.className('pos-reports__column-separator')]),
			t.group('Employee Meals', columns.employeeMeals, [t.className('pos-reports__column-separator')]),
			t.group('Discounts', columns.discounts, [t.className('pos-reports__column-separator')])
		];
	}

	/**
	 * @function _withoutGroups
	 * @memberOf PosReportBaseColumnsService
	 * @description
	 * Returns columns
	 */
	_withoutGroups() {
		return [
			...columns.total,
			// ...columns.average,
			...columns.productSplit,
			...columns.employeeMeals,
			...columns.discounts
		];
	}

	/**
	 * @function generateProductSplit
	 * @memberOf PosReportBaseColumnsService
	 * @description
	 * Returns columns
	 * @param {bool} withGroups should groups be included or not
	 */
	generateProductSplit() {
		return [
			...columns.emptyTotal,
			// ...columns.emptyAverage,
			...columns.productSplit,
			...columns.emptyEmployeeMeals,
			...columns.discounts
		];
	}

	/**
	 * @function generate
	 * @memberOf PosReportBaseColumnsService
	 * @description
	 * Returns columns
	 * @param {bool} withGroups should groups be included or not
	 */
	generate(withGroups) {
		return withGroups ? this._withGroups() : this._withoutGroups();
	}

	/**
	 * @function getFirstColumnWidth
	 * @memberOf PosReportBaseColumnsService
	 * @description
	 * Returns width of first column
	 */
	getFirstColumnWidth() {
		return firstColumnWidth;
	}
}

export default new PosReportBaseColumnsService();