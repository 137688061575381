export const RESET_PRODUCT_CATEGORY =
	'SALES_CONFIGURATION/RESET_PRODUCT_CATEGORY';
export const SET_PRODUCT_CATEGORY =
	'SALES_CONFIGURATION/SET_PRODUCT_CATEGORY';
export const UPDATE_PRODUCT_CATEGORY =
	'SALES_CONFIGURATION/UPDATE_PRODUCT_CATEGORY';

/**
 * @param {object} payload
 */
export function setProductCategory(payload) {
	return {
		type: SET_PRODUCT_CATEGORY,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateProductCategory(payload) {
	return {
		type: UPDATE_PRODUCT_CATEGORY,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET_PRODUCT_CATEGORY
	};
}