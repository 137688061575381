/* eslint-disable react/display-name */
import React, { ChangeEvent } from 'react';

// interfaces
import { IVacationDescriptionPreset } from '../../types';

// components
import { Input } from 'dumb';

type Props = {
	vacationDescriptionPreset: IVacationDescriptionPreset;
	editStoreEntry: (
		name: keyof IVacationDescriptionPreset,
		value: string | null
	) => void;
};

const VacationDescriptionPresetsEditableCells = ({
	vacationDescriptionPreset,
	editStoreEntry,
}: Props): { header: string; value: JSX.Element }[] => {
	return [
		{
			header: 'Subject',
			value: (
				<Input
					id="subject"
					placeholder="Enter subject..."
					value={vacationDescriptionPreset.subject}
					onChange={(event: ChangeEvent<HTMLInputElement>) =>
						editStoreEntry('subject', event.target.value)
					}
					required
					dataCy="market-responsibilities-edit-subject"
				/>
			),
		},
		{
			header: 'Description',
			value: (
				<Input
					id="description"
					placeholder="Enter description..."
					value={vacationDescriptionPreset.description}
					onChange={(event: ChangeEvent<HTMLInputElement>) =>
						editStoreEntry('description', event.target.value)
					}
					required
					dataCy="market-responsibilities-edit-description"
				/>
			),
		},
	];
};

export default VacationDescriptionPresetsEditableCells;
