import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './waitingTimeScheduleEntries.phrases';

/**
 * @function fetchWaitingTimeScheduleEntries
 * @description Fetch all waiting time schedule entries
 * @param {object} state
 * @param {String} filter
 */
export function fetchWaitingTimeScheduleEntries(state, filter) {
	const newFilter = state.filter ? `${state.filter};${filter}` : filter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: newFilter,
	};

	return get('/app/waiting_time_schedule_entries', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addWaitingTimeScheduleEntry
 * @description add waiting time schedule entry
 */
export function addWaitingTimeScheduleEntry(data) {
	return post('/app/waiting_time_schedule_entries', data)
		.then((res) => {
			setFeedback(phrases.WAITING_TIME_SCHEDULE_ENTRY_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editWaitingTimeScheduleEntry
 * @description edit waiting time schedule entry
 */
export function editWaitingTimeScheduleEntry(data) {
	return post(`/app/waiting_time_schedule_entries/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.WAITING_TIME_SCHEDULE_ENTRY_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editWaitingTimeScheduleEntries
 * @description edit waiting time schedule entries
 */
export function editWaitingTimeScheduleEntries(data) {
	return patch('/app/waiting_time_schedule_entries', data)
		.then((res) => {
			setFeedback(phrases.WAITING_TIME_SCHEDULE_ENTRIES_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteWaitingTimeScheduleEntry
 * @description delete waiting time schedule entries
 */
export function deleteWaitingTimeScheduleEntry(id) {
	return remove(`/app/waiting_time_schedule_entries/${id}`)
		.then((res) => {
			setFeedback(phrases.WAITING_TIME_SCHEDULE_ENTRY_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
