import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './productAddonRecommendations.phrases';

/**
 * @function fetchProductAddonRecommendation
 * @description Fetch all addon recommendations
 * @param {object} state
 */
export function fetchProductAddonRecommendation(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get('/product/addon_recommendations', params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editProductAddonRecommendation
 * @description edits addon recommendation
 */
export function editProductAddonRecommendation(data) {
	return post(`/product/addon_recommendations/${data.id}`, data)
		.then(res => {
			setFeedback(phrases.ADDON_RECOMMENDATION_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function addProductAddonRecommendation
 * @description adds addon recommendation
 */
export function addProductAddonRecommendation(data) {
	return post('/product/addon_recommendations', data)
		.then(res => {
			setFeedback(phrases.ADDON_RECOMMENDATION_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteProductAddonRecommendation
 * @description deletes addon recommendations
 */
export function deleteProductAddonRecommendation(productId) {
	return remove(`/product/addon_recommendations/${productId}?cascade=true`)
		.then(res => {
			setFeedback(phrases.ADDON_RECOMMENDATION_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
