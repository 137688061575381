export default {
	ATTACHMENT_ADDED: 'Attachment Added',
	ATTACHMENT_REMOVED: 'Attachment Removed',
	ATTACHMENT_UPDATED: 'Attachment Updated',
	STORE_FRONT_IMAGE: 'STORE FRONT IMAGE',
	UPLOAD_IMAGE: 'UPLOAD Image',
	SAVE: 'SAVE',
	DELETE: 'DELETE',
	UPDATE: 'UPDATE',
	DROP_AN_IMAGE: 'Drop an image here',
	FILE_NOT_SUPPORTED: 'This file type is not supported',
};
