// interfaces
import { ActionType } from 'types/redux';
import {
	IRemoveShiftsFromEmployeeDefaultStateMetadata,
	IRemoveShiftsFromEmployeeDefaultStateUiData,
} from 'types/removeShiftsFromEmployee';

// actions
export const SET_SHOW_MODAL = 'REMOVE_SHIFTS_FROM_EMPLOYEE/SET_SHOW_MODAL';
export const SET_METADATA = 'REMOVE_SHIFTS_FROM_EMPLOYEE/SET_METADATA';
export const SET_UI_DATA = 'REMOVE_SHIFTS_FROM_EMPLOYEE/SET_UI_DATA';
export const RESET_DATA = 'REMOVE_SHIFTS_FROM_EMPLOYEE/RESET_DATA';

export const setShowModal = (payload: boolean): ActionType => ({
	type: SET_SHOW_MODAL,
	payload,
});

export const setMetadata = (payload: IRemoveShiftsFromEmployeeDefaultStateMetadata): ActionType => ({
	type: SET_METADATA,
	payload,
});

export const setUiData = (payload: IRemoveShiftsFromEmployeeDefaultStateUiData): ActionType => ({
	type: SET_UI_DATA,
	payload,
});

export const resetData = (): ActionType => ({
	type: RESET_DATA,
});
