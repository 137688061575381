'use strict';

import {
	SET_WAITING_TIME_GRID_MODAL,
	UPDATE_WAITING_TIME_GRID,
	RESET_WAITING_TIME_GRID_MODAL,
	SET_WAITING_TIME_GRID_ENTRY_MODAL,
	UPDATE_WAITING_TIME_GRID_ENTRY,
	RESET_WAITING_TIME_GRID_ENTRY_MODAL
} from './waitingTimeGrids.actions';

const defaultState = {
	data: {
		waitingTimeGrid: {},
		waitingTimeGridEntries: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_WAITING_TIME_GRID_MODAL: {
		return {
			...state,
			data: {
				waitingTimeGrid: payload
			}
		};
	}

	case UPDATE_WAITING_TIME_GRID: {
		return {
			...state,
			data: {
				waitingTimeGrid: {
					...state.data.waitingTimeGrid,
					...payload
				}
			}
		};
	}

	case RESET_WAITING_TIME_GRID_MODAL: {
		return {
			...state,
			data: {
				waitingTimeGrids: defaultState.data.waitingTimeGrids
			}
		};
	}



	// ________ WAITING TIME GRID ENTRIES _________

	

	case SET_WAITING_TIME_GRID_ENTRY_MODAL: {
		return {
			...state,
			data: {
				waitingTimeGridEntry: payload
			}
		};
	}


	case UPDATE_WAITING_TIME_GRID_ENTRY: {
		return {
			...state,
			data: {
				waitingTimeGridEntry: {
					...state.data.waitingTimeGridEntry,
					...payload
				}
			}
		};
	}

	case RESET_WAITING_TIME_GRID_ENTRY_MODAL: {
		return {
			...state,
			data: {
				waitingTimeGridEntries: defaultState.data.waitingTimeGridEntries
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
