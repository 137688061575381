import { SET_SHOW_MODAL } from './invalidTenantAccount.actions';

const initialState = {
	showModal: false,
};

function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_SHOW_MODAL:
			return {
				...state,
				showModal: action.payload,
			};
		default:
			return state;
	}
}

export default reducer;
