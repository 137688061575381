'use strict';

/**
 * @param {object} resultSet
 */
class FileUploadResultSet {

	constructor(fileUpload) {
		// Set empty results
		this.results = {};

		// Add fileTypes component to resultSet
		this.addFileTypeResultSets(fileUpload.getFileTypes());
	}

	/////////////
	// Helpers //
	/////////////

	/**
	 * @function addFileTypeResultSets
	 * @memberOf FileUploadResultSet
	 * @description Add resultset for all chosen fileTypes
	 * @param {object} fileTypes
	 */
	addFileTypeResultSets(fileTypes) {
		Object.keys(fileTypes).filter(key => fileTypes[key].getFile() !== null).forEach(key => this.addFileTypeResultSet(fileTypes[key]));
	}

	/**
	 * @function addFileTypeResultSet
	 * @memberOf FileUploadResultSet
	 * @description Add resultset for indiviual component
	 * @param {object} component
	 */
	addFileTypeResultSet(fileType) {
		this.results[fileType.getIdentifier()] = fileType.getResultSet();
	}
}

export default FileUploadResultSet;