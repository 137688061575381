'use strict';

var headers = {
	PRODUCT_AREAS: {
		NAME: 'Name',
		DESCRIPTION: 'Description'
	}
};

var titles = {
	PRODUCT_AREAS: 'Product areas'
};

module.exports = {
	headers: headers,
	titles: titles
};
