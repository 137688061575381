'use strict';

import _has from 'lodash/has';
import _each from 'lodash/each';
import _omit from 'lodash/omit';
import _last from 'lodash/last';
import { set as setFeedback } from 'feedback.vanilla.service';

// Services
var __apiFilter = require('apiFilter.service');

module.exports = function (query) {
	return new Promise((resolve, reject) => {
		var state = this.getState();

		// Only add search filter if query has length > 0.
		if (query.trim().length > 0) {
			// Define fields base array
			var fields = [];

			// Loop through headers to add search fields
			_each(state.headers, function (header) {
				if (header.canFilter) {
					// Find fieldName based on if header has reference to child resource or not
					// Is also based on if there is a query included in the reference.
					var fieldName;
					if (_has(header, 'reference')) {
						fieldName = _has(header.reference, 'query')
							? header.name +
							  '.' +
							  header.reference.query +
							  '.' +
							  header.reference.key
							: header.name + '.' + header.reference.key;
					} else fieldName = header.name;

					// Push to array
					fields.push({
						field: fieldName,
						operator: '=like=',
						data: '%' + query + '%',
						joiningOperator: ',',
					});
				}
			});

			// Remove trailing comma on last entry
			fields[fields.length - 1] = _omit(_last(fields), 'joiningOperator');

			// Set search filter
			this.setFilterSearch({
				filter: __apiFilter.create([{ fields: fields }]),
				query: query,
			});
		}

		// Otherwise omit search filter
		else this.setFilterSearch(undefined);

		// Reset offset
		this.setParamOffset(0);

		// Get collection again with updated filters
		this.loadCollection().then(
			function (collectionData) {
				resolve(collectionData);
			},
			function (response) {
				setFeedback(response.code + ': ' + response.message, 0);
				reject(response);
			}
		);
	});
};
