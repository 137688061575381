import { ActionType } from 'types/redux';
import {
	IPowerBiReportDatasetReduxValue,
	IPowerBiReportDatasetReduxValueBatch,
} from '../types';

export const SET_POWER_BI_REPORT_DATA_SET =
	'POWER_BI_REPORT_DATA_SET/SET_POWER_BI_REPORT_DATA_SET';
export const UPDATE_POWER_BI_REPORT_DATA_SET =
	'POWER_BI_REPORT_DATA_SET/UPDATE_POWER_BI_REPORT_DATA_SET';
export const RESET_STATE = 'POWER_BI_REPORT_DATA_SET/RESET_STATE';

// batch stuff
export const EDIT_BATCH_LIST_ITEM =
	'POWER_BI_REPORT_DATA_SET/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'POWER_BI_REPORT_DATA_SET/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'POWER_BI_REPORT_DATA_SET/ADD_BATCH_LIST_ITEM_ROW';
export const RERENDER_TABLE = 'POWER_BI_REPORT_DATA_SET/RERENDER_TABLE';

export function setDynamicAccount(
	payload: IPowerBiReportDatasetReduxValue
): ActionType {
	return {
		type: SET_POWER_BI_REPORT_DATA_SET,
		payload,
	};
}

type updateDynamicAccount = Partial<IPowerBiReportDatasetReduxValue>;
export function updateDynamicAccount(
	payload: updateDynamicAccount
): ActionType {
	return {
		type: UPDATE_POWER_BI_REPORT_DATA_SET,
		payload,
	};
}

export function resetState(): ActionType {
	return {
		type: RESET_STATE,
	};
}

type editBatchListItem = Partial<IPowerBiReportDatasetReduxValueBatch>;
export function editBatchListItem(payload: editBatchListItem): ActionType {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload: string): ActionType {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow(): ActionType {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}

export function rerenderTable(): ActionType {
	return {
		type: RERENDER_TABLE,
	};
}
