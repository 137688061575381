export const RESET_PRODUCT_SET_OPTIONS =
	'SALES_CONFIGURATION_DISCOUNT_PRODUCT_SET_OPTIONS/SET_DISCOUNT_PRODUCT_SET_OPTIONS/RESET_OPTIONS';
export const SET_PRODUCT_SET_OPTION =
	'SALES_CONFIGURATION_DISCOUNT_PRODUCT_SET_OPTIONS/SET_DISCOUNT_PRODUCT_SET_OPTIONS/SET_OPTION';
export const UPDATE_PRODUCT_SET_OPTION =
	'SALES_CONFIGURATION_DISCOUNT_PRODUCT_SET_OPTIONS/SET_DISCOUNT_PRODUCT_SET_OPTIONS/UPDATE_OPTION';

export const RESET_BATCH =
	'SALES_CONFIGURATION_DISCOUNT_PRODUCT_SET_OPTIONS/SET_DISCOUNT_PRODUCT_SET_OPTIONS/RESET_BATCH';
export const UPDATE_BATCH =
	'SALES_CONFIGURATION_DISCOUNT_PRODUCT_SET_OPTIONS/SET_DISCOUNT_PRODUCT_SET_OPTIONS/UPDATE_BATCH';

/**
 * @param {object} payload
 */
export function setProductSetOption(payload) {
	return {
		type: SET_PRODUCT_SET_OPTION,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateProductSetOptions(payload) {
	return {
		type: UPDATE_PRODUCT_SET_OPTION,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetProductSetOptions() {
	return {
		type: RESET_PRODUCT_SET_OPTIONS
	};
}

/**
 * @param {object} payload
 */
export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetBatch() {
	return {
		type: RESET_BATCH
	};
}
