import React from 'react';
import PropTypes from 'prop-types';

const Body = ({ className, children }) => {
	return <tbody className={className}>{children}</tbody>;
};

Body.defaultProps = {
	className: null,
	title: ''
};

Body.propTypes = {
	className: PropTypes.object,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired
};

export default Body;
