'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MarketsView from './subcomponents/marketsView/marketsView.component';

class DynamicPosViewComponent extends Component {
	render() {
		return (
			<div className="dynamic-pos-view">
				<MarketsView {...this.props} />
			</div>
		);
	}
}

DynamicPosViewComponent.defaultProps = {
	dynamicPosView: {},
	dynamicPosViewState: {}
};

DynamicPosViewComponent.propTypes = {
	dynamicPosView: PropTypes.object.isRequired,
	dynamicPosViewState: PropTypes.object.isRequired
};

export default DynamicPosViewComponent;
