'use strict';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

// components
import { Modal } from 'dumb';
import { Box, Typography } from '@mui/material';
import Date from './../../inputs/date/date.component';

// utils
import moment, { Moment } from 'moment';
import phrases from './csvDateRange.phrases';

type Props = {
	handleCSVExport: ({
		from,
		to,
	}: {
		from: string;
		to: string;
	}) => Promise<unknown>;
	handleClose: () => void;
};

const CsvDateRange = ({ handleCSVExport, handleClose }: Props): JSX.Element => {
	const [dates, setDates] = useState({
		from: moment().subtract(1, 'month').format('YYYY-MM-DD'),
		to: moment().format('YYYY-MM-DD'),
	});
	const [loading, setLoading] = useState(false);

	const handleDownloadCSV = () => {
		setLoading(true);

		handleCSVExport(dates)
			.then(() => {
				setLoading(false);
				handleClose();
			})
			.catch(() => setLoading(false));
	};

	const handleDateChange = (dates: { startDate: Moment; endDate: Moment }) => {
		const formattedDates = {
			from: dates.startDate && dates.startDate.format('YYYY-MM-DD'),
			to: dates.endDate && dates.endDate.format('YYYY-MM-DD'),
		};

		setDates(formattedDates);
	};

	return (
		<Modal
			header="Select dates"
			dataCy="csv-date-range-modal"
			loading={loading}
			onClose={handleClose}
			onConfirmClick={handleDownloadCSV}
			confirmButtonLabel={phrases.DOWNLOAD}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<Typography variant="body1">{phrases.CSV_DATE_RANGE_HEADER}</Typography>
				<Date
					onChange={handleDateChange}
					type="range"
					initialStartDate={dates.from}
					initialEndDate={dates.to}
					openDirection="down"
					showClearDate={false}
				/>
			</Box>
		</Modal>
	);
};

CsvDateRange.propTypes = {
	handleCSVExport: PropTypes.func,
	handleClose: PropTypes.func,
};

export default CsvDateRange;
