'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input, InputCollectionSelect, Toggle } from 'dumb';
import { ModalRow } from 'dumb/modal';

// lodash
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

// phrases/enums
import phrases from './../../../basketRecommendationPriorities.phrases';
import enums from './../../../basketRecommendationPriorities.enums';

class AddEditBasketRecommendationPrioritiesForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};

		this._changePriorityType = this._changePriorityType.bind(this);
	}

	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		const value = e.target ? e.target.value : e;

		updateBatchForm({
			[type]: value,
		});
	}

	editReplacement(value, name) {
		const { batchFormData } = this.props;

		// filter out the same product variant - if already selected
		let replacements = batchFormData.replacements || [];
		replacements = replacements.filter((entry) => entry.name !== name);

		replacements = [
			...replacements,
			...(value
				? [
						{
							name,
							value,
						},
				  ]
				: []),
		];

		this.editStoreEntry(replacements, 'replacements');
	}

	_getReplacementValue(id) {
		const { batchFormData } = this.props;

		const productVariantValue = (batchFormData.replacements || []).find(
			(entry) => entry.name === `productVariant-${id}`
		);

		return productVariantValue?.value ?? null;
	}

	_changePriorityType(key, value) {
		const { batchFormData } = this.props;

		// if type changed from recommendation to exclusion, delete all replacements with index higher then 2
		if (
			batchFormData.type?.value === enums.RECOMMENDATION &&
			value.value === enums.EXCLUSION
		) {
			if (!_isEmpty(batchFormData.replacements)) {
				const replacements = batchFormData.replacements.filter(
					(entry, index) => index < 3
				);
				this.editStoreEntry(replacements, 'replacements');
			}
		}

		this.editStoreEntry(value, 'type');
	}

	render() {
		const { batchFormData } = this.props;

		return (
			<>
				<ModalRow>
					<Input
						id="name"
						required
						label={phrases.NAME}
						placeholder={phrases.NAME_PLACEHOLDER}
						onChange={(event) => this.editStoreEntry(event, 'name')}
						value={batchFormData.name}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						id="description"
						label={phrases.DESCRIPTION}
						placeholder={phrases.DESCRIPTION_PLACEHOLDER}
						onChange={(event) => this.editStoreEntry(event, 'description')}
						value={batchFormData.description}
					/>
				</ModalRow>

				<ModalRow>
					<InputCollectionSelect
						id="type"
						label={phrases.TYPE}
						handleChange={this._changePriorityType}
						value={batchFormData.type}
						options={[
							{
								value: enums.RECOMMENDATION,
								label: enums.RECOMMENDATION,
							},
							{
								value: enums.EXCLUSION,
								label: enums.EXCLUSION,
							},
						]}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						id="sortOrder"
						type="number"
						label={phrases.SORT_ORDER}
						placeholder={phrases.SORT_ORDER_PLACEHOLDER}
						onChange={(event) => this.editStoreEntry(event, 'sortOrder')}
						value={batchFormData.sortOrder}
					/>
				</ModalRow>

				{batchFormData.editMode && (
					<ModalRow>
						<Toggle
							label={phrases.LEGACY}
							onClick={(e) => this.editStoreEntry(e, 'legacy')}
							toggled={batchFormData.legacy}
						/>
					</ModalRow>
				)}

				<ModalRow>
					<div className="basket-recommendation-priority__scroll-area-wrapper">
						<div className="basket-recommendation-priority__scroll-area-wrapper__header">
							<span>{phrases.ADD_REPLACEMENTS}</span>
							{!!(batchFormData.replacements || []).length && (
								<span className="basket-recommendation-priority__scroll-area-wrapper__header__amount">
									({batchFormData.replacements.length})
								</span>
							)}
						</div>
						<div className="basket-recommendation-priority__scroll-area-wrapper__scroll">
							{[
								...Array(
									batchFormData.type?.value === enums.EXCLUSION ? 3 : 15
								).keys(),
							].map((entry) => (
								<ModalRow key={entry}>
									<InputCollectionSelect
										id={`replacement-${entry}`}
										label={`${phrases.REPLACEMENT} ${entry + 1}`}
										placeholder={phrases.REPLACEMENT_PLACEHOLDER}
										value={this._getReplacementValue(entry)}
										handleChange={(key, value) =>
											this.editReplacement(value, `productVariant-${entry}`)
										}
										cache
										apiPath="/product/product_variant_groups"
										params={{
											limit: 30,
										}}
										optionFormat={(entry) => ({
											value: entry,
											label: entry.name,
										})}
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
										tableColumns={[
											{
												Header: 'Name',
												id: 'name',
												accessor: (d) => _get(d, 'name', ''),
												filterPath: ':name',
											},
											{
												Header: 'Description',
												id: 'desc',
												accessor: (d) => _get(d, 'description', ''),
												filterPath: ':description',
											},
											{
												Header: 'Sort order',
												id: 'sortOrder',
												accessor: (d) => _get(d, 'sort_order', ''),
												filterPath: ':sort_order',
											},
											{
												Header: 'Legacy',
												id: 'legacy',
												accessor: 'legacy',
												filterPath: ':legacy',
												filterable: false,
												Cell: (d) => {
													return (
														<Input
															id="checkbox"
															type="checkbox"
															checked={d.original.legacy}
															disabled
														/>
													);
												},
											},
										]}
										tableTitle="Product variants"
										tableReduxKey={`basketRecommendationPriorities-/pos/product_variants-${entry}`}
									/>
								</ModalRow>
							))}
						</div>
					</div>
				</ModalRow>
			</>
		);
	}
}

AddEditBasketRecommendationPrioritiesForm.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
};

export default AddEditBasketRecommendationPrioritiesForm;
