import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './productTranslations.phrases';

/**
 * @function fetchProductTranslations
 * @description Fetch all product translations
 * @param {object} state
 */
export function fetchProductTranslations(state, filter) {
	if (state.filter) filter = `${filter};${state.filter}`;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get('/product/product_translations', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addProductTranslations
 * @description add product translations
 */
export function addProductTranslations(data) {
	return post('/product/product_translations', data)
		.then((res) => {
			setFeedback(phrases.PRODUCT_TRANSLATIONS_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editProductTranslation
 * @description edit product translations
 */
export function editProductTranslation(data) {
	return post(`/product/product_translations/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.PRODUCT_TRANSLATION_EDIT, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editProductTranslations
 * @description edit product translations
 */
export function editProductTranslations(data) {
	return patch('/product/product_translations', data)
		.then((res) => {
			setFeedback(phrases.PRODUCT_TRANSLATIONS_EDIT, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteProductTranslations
 * @description delete product translation
 */
export function deleteProductTranslations(id) {
	return remove(`/product/product_translations/${id}`)
		.then((res) => {
			setFeedback(phrases.PRODUCT_TRANSLATIONS_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
