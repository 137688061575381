'use strict';

import { HotbarComponentResultSetAbstract } from 'hotdamnbar/resultSets';

class HotbarSingleDateResultSet extends HotbarComponentResultSetAbstract {

	/**
	 * @function getDate
	 * @memberOf HotbarSingleDateResultSet
	 * @description Gets start date from date range
	 */
	getDate() {
		return this.results !== null ? this.results : null;
	}

}

export default HotbarSingleDateResultSet;