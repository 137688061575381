import { store } from 'appState';

export const SET_MODAL_DATA = 'CLOCKIN_DEVIATION_MODAL/SET_MODAL_DATA';
export const RESET_MODAL_DATA = 'CLOCKIN_DEVIATION_MODAL/RESET_MODAL_DATA';
export const UPDATE_MODAL_DATA = 'CLOCKIN_DEVIATION_MODAL/UPDATE_MODAL_DATA';
export const SET_SHOW_MODAL = 'CLOCKIN_DEVIATION_MODAL/SET_SHOW_MODAL';

export const setModalData = (payload) => ({
	type: SET_MODAL_DATA,
	payload,
});

export const resetModalData = () => ({
	type: RESET_MODAL_DATA,
});

export const updateModalData = (payload) => ({
	type: UPDATE_MODAL_DATA,
	payload,
});

export const setShowModal = (payload) => ({
	type: SET_SHOW_MODAL,
	payload,
});

/**
 * @function dispatchSetToggleClockinDeviationModalData
 * @description set data for modal
 */
export const dispatchSetToggleClockinDeviationModalData = (payload) => {
	store.dispatch(setModalData(payload));
};
