import React from 'react';

// components
import { Input, Tag } from 'dumb';

// utils
import moment from 'moment';
import constants from 'services/constants';

export default function getColumns() {
	return [
		{
			Header: 'Type',
			id: 'type',
			accessor: 'type',
			filterPath: ':type',
		},
		{
			Header: 'Subtype',
			id: 'subtype',
			accessor: 'subtype',
			filterPath: ':subtype',
		},
		{
			Header: 'Name',
			id: 'name',
			accessor: 'name',
			filterPath: ':name',
		},
		{
			Header: 'Name abbreviated',
			id: 'name_abbreviated',
			accessor: 'name_abbreviated',
			filterPath: ':name_abbreviated',
		},
		{
			Header: 'Exclude in PHP',
			id: 'exclude_productivity',
			accessor: 'exclude_productivity',
			filterPath: ':exclude_productivity',
			Cell: (d) => <Input id="exclude_productivity" type="checkbox" checked={d.value} disabled />,
			filterable: false,
		},
		{
			Header: 'Global account',
			id: 'global_account',
			accessor: (d) => d.global_account?.name ?? '',
			filterPath: ':global_account.name',
		},
		{
			Header: 'Exclude base pay',
			id: 'exclude_base_pay',
			accessor: 'exclude_base_pay',
			filterPath: ':exclude_base_pay',
			Cell: (d) => <Input id="exclude_base_pay" type="checkbox" checked={d.value} disabled />,
			filterable: false,
		},
		{
			Header: 'Exclude addon',
			id: 'exclude_addon',
			accessor: 'exclude_addon',
			filterPath: ':exclude_addon',
			Cell: (d) => <Input id="exclude_addon" type="checkbox" checked={d.value} disabled />,
			filterable: false,
		},
		{
			Header: 'Exclude overtime',
			id: 'exclude_overtime',
			accessor: 'exclude_overtime',
			filterPath: ':exclude_overtime',
			Cell: (d) => <Input id="exclude_overtime" type="checkbox" checked={d.value} disabled />,
			filterable: false,
		},
		{
			Header: 'Current year index',
			id: 'current_year_index',
			accessor: 'current_year_index',
			filterPath: ':current_year_index',
			Cell: (d) => <Input id="current_year_index" type="checkbox" checked={d.value} disabled />,
			filterable: false,
		},
		{
			Header: 'Prior year index',
			id: 'prior_year_index',
			accessor: 'prior_year_index',
			filterPath: ':prior_year_index',
			Cell: (d) => <Input id="prior_year_index" type="checkbox" checked={d.value} disabled />,
			filterable: false,
		},
		{
			Header: 'Color',
			id: 'color',
			accessor: 'color',
			filterPath: ':color',
			Cell: (d) =>
				d.value ? (
					<Tag id="color" color={d.value}>
						{d.value}
					</Tag>
				) : null,
		},
		{
			Header: 'Created',
			id: 'created',
			accessor: 'created',
			filterPath: ':created',
			Cell: (d) => formatDate(d.value),
			show: false,
		},
		{
			Header: 'Updated',
			id: 'updated',
			accessor: 'updated',
			filterPath: ':updated',
			Cell: (d) => formatDate(d.value),
			show: false,
		},
	];
}

function formatDate(date) {
	return <span>{moment.utc(date).format(constants.shortDate)}</span>;
}
