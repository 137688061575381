'use strict';

/**
 * @namespace translations
 * @memberOf SERVICES
 * @description
 * Handle translations through Polyglot
 */
import _each from 'lodash/each';
import _last from 'lodash/last';
import Polyglot from 'node-polyglot';

const polyglot = new Polyglot();

polyglot.extend({
	COLGROUPS: {
		POS: require('translations/endpoints/pos').colgroups,
		SALE: require('translations/endpoints/sale').colgroups,
		REPORTING: require('translations/endpoints/reporting').colgroups
	},
	COMPONENTS: require('translations/components'),
	ERRORS: require('translations/errors'),
	HEADERS: {
		ADMINISTRATION: require('translations/endpoints/administration').headers,
		ACCOUNTING: require('translations/endpoints/accounting').headers,
		AUTHENTICATION: require('translations/endpoints/authentication').headers,
		FINANCIAL: require('translations/endpoints/financial').headers,
		CAMPAIGNING: require('translations/endpoints/campaigning').headers,
		HR: require('translations/endpoints/hr').headers,
		POS: require('translations/endpoints/pos').headers,
		PROFILE: require('translations/endpoints/profile').headers,
		SHIFTPLANNING: require('translations/endpoints/shiftPlanning').headers,
		SALARY: require('translations/endpoints/salary').headers,
		SALE: require('translations/endpoints/sale').headers,
		SOCIAL: require('translations/endpoints/social').headers,
		TAGGING: require('translations/endpoints/tagging').headers,
		REPORTING: require('translations/endpoints/reporting').headers,
		TRAINING: require('translations/endpoints/training').headers,
		PRODUCTS: require('translations/endpoints/products').headers
	},
	LIST_TITLES: {
		ADMINISTRATION: require('translations/endpoints/administration').titles,
		ACCOUNTING: require('translations/endpoints/accounting').titles,
		AUTHENTICATION: require('translations/endpoints/authentication').titles,
		FINANCIAL: require('translations/endpoints/financial').titles,
		CAMPAIGNING: require('translations/endpoints/campaigning').titles,
		HR: require('translations/endpoints/hr').titles,
		POS: require('translations/endpoints/pos').titles,
		PROFILE: require('translations/endpoints/profile').titles,
		SHIFTPLANNING: require('translations/endpoints/shiftPlanning').titles,
		SALARY: require('translations/endpoints/salary').titles,
		SALE: require('translations/endpoints/sale').titles,
		SOCIAL: require('translations/endpoints/social').titles,
		TAGGING: require('translations/endpoints/tagging').titles,
		REPORTING: require('translations/endpoints/reporting').titles,
		TRAINING: require('translations/endpoints/training').titles,
		PRODUCTS: require('translations/endpoints/products').titles
	},
	MODELS: {
		HEADERS: require('translations/models').headers,
		COLGROUPS: require('translations/models').colgroups
	},
	SHARED: require('translations/shared')
});

/// /////////
// Public //
/// /////////

/**
 * @function getObject
 * @memberOf SERVICES.translations
 * @description Returns object of translations based on array
 * @param {array} translationsArray
 */
export function getObject(translationsArray) {
	var translationsObject = {};
	_each(translationsArray, function(entry) {
		var name = _last(entry.split('.'));
		translationsObject[name] = polyglot.t(entry);
	});
	return translationsObject;
}

/**
 * @function getSingle
 * @memberOf SERVICES.translations
 * @description Returns single translation
 * @param {string} key
 */
export function getSingle(key) {
	return polyglot.t(key);
}
