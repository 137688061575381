export default {
	MODAL_TYPES: {
		MARK_MANAGED_CLOCKIN_AS_NO_SHOW: 'Mark managed clockin as no show',
		ADD_BREAK: 'Add a new break',
		ADJUST_CLOCKED:
			'Adjust clocked values where they are more then a day apart',
		ADJUST_BREAK_DATES: 'Adjust break dates when they are on another day',
	},
	STATUS_FILTER_ALIGNED: 'Aligned',
	STATUS_FILTER_UNALIGNED: 'Unaligned',
};
