'use strict';

import { ReportFactory as R } from 'report/factory';
import {
	ReportEndpointDataProvider,
	ReportDownloadCsvDataProvider,
} from 'report/dataProviders';
import endpoints from 'endpoints.service';
import moment from 'moment';

import PosReportHotbar from './posReports.hotbar';
import { getParameters, getFilter } from './posReports.methods';
import {
	PosReportWorldTable,
	PosReportFranchiseTable,
	PosReportContinentTable,
	MarketsView,
	StoresView,
	HourlyView,
	ProductSplitView,
} from './components';

import './posReports.css';

// Data provider
const PosReportDataProvider = new ReportEndpointDataProvider(
	'/reporting/new_pos_reports'
);
const ShortPosReportDownloadCsvDataProvider = new ReportDownloadCsvDataProvider(
	'Total',
	endpoints.collection.reporting['pos_reports/total'],
	'total-pos-report'
);
const PosReportDownloadCsvDataProvider = new ReportDownloadCsvDataProvider(
	'Daily',
	endpoints.collection.reporting.pos_reports,
	'pos-report'
);

// Report
const PosReport = R.report(
	'posReports',
	PosReportDataProvider,
	PosReportHotbar
);

PosReport.addCsvDataProvider(PosReportDownloadCsvDataProvider);
PosReport.addCsvDataProvider(ShortPosReportDownloadCsvDataProvider);

// Report metadata
const m = R.metadata();
PosReport.setMetadata(
	m.metadata(
		m.title(''),
		m.subtitle(''),
		m.printTitle(
			(d) =>
				`POS Report ${moment(R.data(d, 'period.from')).format(
					'DD-MM-YYYY'
				)} - ${moment(R.data(d, 'period.to')).format('DD-MM-YYYY')}`
		)
	)
);

// Filter and parameters
PosReport.setParameterMethod(getParameters);
PosReport.setFilterMethod(getFilter);

// Components
PosReport.setComponents({
	PosReportWorldTable,
	PosReportFranchiseTable,
	PosReportContinentTable,
	MarketsView,
	StoresView,
	HourlyView,
	ProductSplitView,
});

export default PosReport;
