'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { connectWithStore } from 'appState';

import FileList from './fileList.component';

import './fileList.css';

class FileListContainer extends Component {

	componentDidMount() {
		// Update data
		this.props.fileList.handleUpdateFileList();
	}

	render() {
		const { fileList, fileListState, selectedPositionId, categoryList } =
			this.props;

		return (
			<FileList
				{...{
					fileList,
					fileListState,
					selectedPositionId,
					phrases: fileList.getPhrases(),
					categoryList,
				}}
			/>
		);
	}
}

FileListContainer.propTypes = {
	fileList: PropTypes.object.isRequired,
	selectedPositionId: PropTypes.number,
	categoryList: PropTypes.array,
};

const mapStateToPropsFactory = (initialStore, ownProps) => (store) => ({
	fileListState: {
		data: _get(store, `fileList.fileLists[${ownProps.fileList.name}]`, []),
		loading: _get(store, `fileList.loading[${ownProps.fileList.name}]`, false),
	},
});

export default connectWithStore(FileListContainer, mapStateToPropsFactory);
