'use strict';
import * as translations from 'translations.service';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _has from 'lodash/has';
import DetailsModel from 'common/components/details/detailsModel.service';

import { UserService } from 'user';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { formatErrorMessage } from 'api/helpers';
import { post } from 'api.vanilla.service';
import { setShowModalAndData } from 'modules/common/components/sendDocuSignDocument/store/sendDocuSignDocument.actions';
import moment from 'moment';
import phrases from './../onboardings.phrases';
import config from 'config';
// Services
const __endpoints = require('endpoints.service');

export default class OnboardingsDetails extends DetailsModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'market',
				type: 'select',
				reference: {
					key: 'name',
					list: '/administration/markets',
				},
				span: 3,
			},
			{
				name: 'person',
				type: 'select',
				reference: {
					key: 'full_name',
					list: '/hr/persons',
				},
				span: 4,
			},
			{
				name: 'status',
				type: 'string',
				canAdd: true,
			},
			{
				name: 'received',
				type: 'string',
				canAdd: true,
			},
			{
				name: 'signed',
				type: 'string',
			},
			{
				name: 'signed_by',
				type: 'select',
				reference: {
					key: 'first_name',
					searchFields: ['full_name'],
					label: '<%= full_name %> (<%= id %>)',
					list: '/hr/persons',
				},
				span: 4,
			},
		];

		this.detailsActions = function (props) {
			return [
				{
					method: props.methods.handleSignOnboarding.bind(this, props.itemId),
					class: 'icon--check',
					conditionalClassNames: [
						{
							condition: (value) => !_isNil(value),
							className: 'button--signed',
							conditionalHeader: 'signed',
						},
					],

					translation: (item) => (!_isNil(item.signed) ? 'Signed' : 'Sign'),
					validExpressions: [],
					name: 'sign',
				},

				{
					method: props.methods.handleResend.bind(this, props.itemId),
					class: 'icon--check',
					conditionalClassNames: [
						{
							condition: (value) => !_isNil(value),
							className: 'button--signed',
							conditionalHeader: 'signed',
						},
					],

					translation: () => 'Resend onboarding',
					validExpressions: [],
					name: 'resend',
				},

				{
					method: props.methods.handleClientMailSend.bind(this, props.itemId, props.item),
					class: 'icon--check',
					conditionalClassNames: [
						{
							condition: (value) => !_isNil(value),
							className: 'button--signed',
							conditionalHeader: 'signed',
						},
					],

					translation: () => 'Send via mail client',
					validExpressions: [],
					name: 'clientSend',
				},

				...(config.featureFlags.enableDocuSign
					? [
							{
								method: props.methods.handleSendDocument.bind(this, props.itemId, props.item),
								conditionalClassNames: [
									{
										condition: (value) => !_isNil(value),
										className: 'button--send-document',
										conditionalHeader: 'signed',
									},
								],
								translation: () => 'Send document',
								validExpressions: [],
								name: 'clientSend',
							},
					  ]
					: []),
			];
		};

		this.settings = {
			name: 'OnboardingDetails',
			translationPath: 'HR.ONBOARDINGS',
			endpoint: __endpoints.collection.hr.onboardings,
		};
		this.init();
		return this;
	}

	handleClientMailSend(id, item) {
		const email = item.person?.person_email_address?.email_address ?? '';
		const bodyText = 'Your onboarding link for Joe and The Juice: ';
		const onBoarding = item.typeform_link;
		const mailToLink = 'mailto:' + email + '?subject=Your onboarding!&body=' + bodyText + onBoarding;
		window.open(mailToLink);
	}

	handleResend(id) {
		resendReminderOnboarding(id);
	}

	handleSendDocument(onboardingId, onboarding) {
		// setFeedback
		const employmentPositionId = onboarding.person?.current_employment_position?.id ?? null;

		if (!employmentPositionId) setFeedback(phrases.NO_EMPLOYMENT_POSITION, 0);
		else {
			const payload = {
				employmentPositionId,
			};

			setShowModalAndData(payload);
		}
	}

	/**
	 * @function handleSubNavigate
	 * @description
	 * Handles navigation to sub pages
	 */
	handleSignOnboarding(id, event) {
		event.preventDefault();
		const state = this.getState();
		let item = state.item;

		if (item.signed !== null) {
			setFeedback('Onboarding is already signed', 0);
			return;
		}

		// if (item.received === null) {
		// 	__feedbackData.set('Onboarding is not yet received', 0);
		// 	return;
		// }
		// Get current user
		const userData = UserService.getUser();
		const person = _get(userData, 'user.person', null);

		if (!person) {
			setFeedback('User has no person referenced', 0);
			return;
		}

		const data = {
			signed: moment().format('YYYY-MM-DDTHH:mm:ss'),
			status: 'Signed',
			signed_by: person.id,
		};

		// Post data to API endpoint to update item
		return post(this.getSettings().endpoint.update.path + id, data)
			.then((response) => {
				item = _has(response, 'data') ? this.itemFormat(response.data[0]) : {};
				const formattedItem = this.itemFormat(item);

				this.setItem(formattedItem);

				setFeedback(translations.getSingle('COMPONENTS.DETAILS.UPDATED'), 1);
			})
			.catch((err) => {
				setFeedback(err.data.message, 0);
			});
	}

	/**
	 * @function extendMethods
	 * @description
	 * Extend methods object from listModel
	 */
	extendMethods() {
		return Object.assign(this.getMethods(), {
			handleSignOnboarding: this.handleSignOnboarding.bind(this),
			handleResend: this.handleResend.bind(this),
			handleClientMailSend: this.handleClientMailSend.bind(this),
			handleSendDocument: this.handleSendDocument.bind(this),
		});
	}

	/**
	 * @function extendTranslations
	 * @description
	 * Extend methods object from listModel
	 */
	extendTranslations() {
		return Object.assign(this.getTranslations(), translations.getObject(['COMPONENTS.LIST.SIGN']));
	}

	/**
	 * @function getProps
	 * @description
	 * Overwrite getProps with extended methods
	 */
	getProps() {
		return Object.assign(this.getState(), {
			methods: this.extendMethods(),
			settings: this.getSettings(),
			translations: this.extendTranslations(),
		});
	}
}

/**
 * @function resendReminderOnboarding
 */
function resendReminderOnboarding(id) {
	return post(`/hr/onboardings/${id}/reminders`, {})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}
