import { ActionType } from 'types/redux';
import { IMoneyballPositionState } from '../types';
import {
	SET_NAME,
	SET_DESCRIPTION,
	SET_BADGE,
	SET_SORT_ORDER,
	SET_FROM_DATE,
	SET_TO_DATE,
	RESET_DATA,
} from './moneyballPositionsTable.actions';
import moment from 'moment';
import constants from 'services/constants';

const defaultState: IMoneyballPositionState = {
	name: '',
	description: '',
	badge: '',
	sort_order: 0,
	active: {
		from: moment().format(constants.shortDate),
		to: moment('9999-12-31').format(constants.shortDate),
	},
};

function reducer(
	state = defaultState,
	action: ActionType
): IMoneyballPositionState {
	const { type, payload } = action;

	switch (type) {
		case SET_NAME:
			return {
				...state,
				name: payload as string,
			};

		case SET_DESCRIPTION:
			return {
				...state,
				description: payload as string,
			};

		case RESET_DATA: {
			return defaultState;
		}

		case SET_BADGE:
			return {
				...state,
				badge: payload as string,
			};

		case SET_SORT_ORDER:
			return {
				...state,
				sort_order: payload as number,
			};

		case SET_FROM_DATE:
			return {
				...state,
				active: {
					...state.active,
					from: payload as string,
				},
			};

		case SET_TO_DATE:
			return {
				...state,
				active: {
					...state.active,
					to: payload as string,
				},
			};

		default:
			return state;
	}
}

export default reducer;
