'use strict';

/**
 * ColumnWidth
 * @param  {*} template
 * @param  {string} contentType
 */
class ColumnWidth {
	constructor(path, minChars = 5, charWidth = 6, bufferWidth = 0) {
		// Set type
		this.type = 'columnWidth';

		this.setPath(path);

		this.columnWidth = null;

		this.minChars = minChars;
		this.charWidth = charWidth;
		this.bufferWidth = bufferWidth;
	}

	/// //////////
	// Helpers //
	/// //////////

	extractNumberOfChars(content) {
		return Math.round(content).toString().length;
	}

	calculateDotWidth(numberOfChars) {
		return Math.floor(numberOfChars / 3) * this.charWidth;
	}

	/// //////////
	// Setters //
	/// //////////

	/**
	 * @function setMinWidth
	 * @memberOf ColumnWidth
	 * @description Setter for minWidth
	 */
	setMinWidth(minWidth) {
		this.minWidth = minWidth;
	}

	/**
	 * @function setPath
	 * @memberOf ColumnWidth
	 * @description Setter for path
	 */
	setPath(path) {
		this.path = path;
	}

	/**
	 * @function setColumnWidth
	 * @memberOf ColumnWidth
	 * @description Setter for width
	 */
	setColumnWidth(width) {
		this.columnWidth = width;
	}

	/// //////////
	// Getters //
	/// //////////

	/**
	 * @function getColumnWidth
	 * @memberOf ColumnWidth
	 * @description Setter for width
	 */
	getColumnWidth() {
		return this.columnWidth;
	}

	/**
	 * @function getMinWidth
	 * @memberOf ColumnWidth
	 * @description Getter for minWidth
	 */
	getMinWidth() {
		return this.minWidth;
	}

	/**
	 * @function getWidth
	 * @memberOf ColumnWidth
	 * @description Getter for path
	 * @param {object} data
	 */
	getWidth(data) {
		if (this.getColumnWidth()) return this.getColumnWidth();

		// Extract number of chars
		const numberOfChars = this.extractNumberOfChars(this.path(data));

		// Calculate width
		const width = Math.max(...[
			this.minChars * this.charWidth + this.bufferWidth,
			(numberOfChars * this.charWidth) + this.calculateDotWidth(numberOfChars) + this.bufferWidth
		]);

		// Set width for later use
		this.setColumnWidth(width);
	}
}

export default ColumnWidth;