'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	updateProductVariantAddon,
	resetProductVariantAddon,
	setProductVariantAddon,
} from './store/productVariantAddons.actions';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';
import { InputCollectionSelect, Button, Input, Toggle } from 'dumb';
import ProductAddons from './../../../../../productAddons/productAddons.component';

// lodash
import _get from 'lodash/get';

// services
import {
	fetchProductVariantAddons,
	deleteProductVariantAddon,
	addProductVariantAddon,
	editProductVariantAddon,
} from './productVariantAddons.service';

class ProductVariantAddons extends Component {
	constructor(props) {
		super(props);

		this.state = { legacyFilterOn: true, legacyFilter: `:legacy==false` };

		this._editStoreEntry = this._editStoreEntry.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);

		this.columns = [
			{
				Header: 'Product Addon',
				id: 'addon',
				accessor: (d) => _get(d, 'addon.name', null),
				filterPath: ':addon.name',
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: 'legacy',
				filterPath: ':legacy',
				filterable: false,
				Cell: (d) => {
					return (
						<Input
							id="legacy"
							type="checkbox"
							checked={d.original.legacy}
							disabled
						/>
					);
				},
			},
		];
	}

	addEntry() {
		const { productVariantAddonData, productVariant } = this.props;

		const payload = {
			product_variant: productVariant.id,
			addon: _get(productVariantAddonData, 'addon.value', null),
		};

		return addProductVariantAddon(payload);
	}

	editEntry() {
		const { productVariantAddonData, productVariant } = this.props;

		const payload = {
			product_variant: productVariant.id,
			addon: _get(productVariantAddonData, 'addon.value', null),
			legacy: !!productVariantAddonData.legacy,
		};

		return editProductVariantAddon(productVariantAddonData.id, payload);
	}

	deleteEntry(id) {
		return deleteProductVariantAddon(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			legacy: data.legacy,
			addon: {
				value: data.addon.id,
				label: data.addon.name,
			},
			editMode: true,
		};

		this.props.setProductVariantAddon(payload);
	}

	getEditableCells() {
		const { productVariantAddonData } = this.props;

		return [
			{
				header: 'Product Addon',
				value: (
					<InputCollectionSelect
						id="addon"
						placeholder="select addon"
						value={productVariantAddonData.addon}
						handleChange={(key, value) => this._editStoreEntry(value, 'addon')}
						clearable
						cache
						apiPath="/product/addons"
						params={{
							limit: 300,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			...(productVariantAddonData.editMode
				? [
						{
							header: 'Legacy',
							value: (
								<Toggle
									id="legacy"
									toggled={productVariantAddonData.legacy}
									onClick={(event) => this._editStoreEntry(event, 'legacy')}
								/>
							),
						},
				  ]
				: []),
		];
	}

	_editStoreEntry(value, name) {
		this.props.updateProductVariantAddon({
			[name]: value,
		});
	}

	fetchData(state) {
		const { productVariant } = this.props;
		return fetchProductVariantAddons(productVariant.id, state);
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}>
				Legacy
			</Button>
		);
	}

	render() {
		const legacyFilter = this.state.legacyFilterOn
			? this.state.legacyFilter
			: '';

		return (
			<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
				title={this.props.title}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={10}
				reduxKey={`product-pos/product_variant_addons/${this.props.productVariant.id}`}
				manual
				editableCells={this.getEditableCells()}
				onModalClose={this.props.resetProductVariantAddon}
				actionsWidth={30}
				deleteEntry={this.deleteEntry}
				createEntry={this.addEntry}
				editEntry={this.editEntry}
				setInitialEditValues={this.setInitialEditValues}
				customAreaComponents={this.getLegacyFilterButton()}
				extraFilters={legacyFilter}
				subcomponent={(row) => (
					<SubTable>
						<ProductAddons addonId={row.original.addon?.id} />
					</SubTable>
				)}
			/>
		);
	}
}

ProductVariantAddons.propTypes = {
	productVariant: PropTypes.object,
	productVariantAddonData: PropTypes.object,
	title: PropTypes.string,

	updateProductVariantAddon: PropTypes.func,
	resetProductVariantAddon: PropTypes.func,
	setProductVariantAddon: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateProductVariantAddon,
			resetProductVariantAddon,
			setProductVariantAddon,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		productVariantAddonData:
			store.productProductVariantAddons.data.productVariantAddonData,
	};
};

export default connectWithStore(
	ProductVariantAddons,
	mapStateToProps,
	mapDispatchToProps
);
