import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';

import NationalitiesList from './services/nationalitiesList.service.js';

const nationalitiesListReduxKey = '/administration/nationalities';
const nationalitiesList = new NationalitiesList(nationalitiesListReduxKey);
const nationalitiesListProps = nationalitiesList.getProps();

export function Nationalities() {
	useEffect(() => {
		nationalitiesList.initView();
	}, []);

	return <ListComponent reduxKey={nationalitiesListReduxKey} {...nationalitiesListProps} />;
}
