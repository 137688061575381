export const RESET_ILLNESS = 'PERSON_ILLNESS/RESET_ILLNESS';
export const SET_ILLNESS = 'PERSON_ILLNESS/SET_ILLNESS';
export const UPDATE_ILLNESS = 'PERSON_ILLNESS/UPDATE_ILLNESS';
export const ADD_ILLNESS = 'PERSON_ILLNESS/ADD_ILLNESS';
export const REMOVE_ILLNESS = 'PERSON_ILLNESS/REMOVE_ILLNESS';

/**
 * @param {object} payload
 */
export function setIllness(payload) {
	return {
		type: SET_ILLNESS,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateIllness(payload) {
	return {
		type: UPDATE_ILLNESS,
		payload
	};
}

/**
 * @param {object} payload
 */
export function addIllness(payload) {
	return {
		type: ADD_ILLNESS,
		payload
	};
}

/**
 * @param {object} payload
 */
export function removeIllness(payload) {
	return {
		type: REMOVE_ILLNESS,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetIllness() {
	return {
		type: RESET_ILLNESS
	};
}
