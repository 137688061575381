import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './productVariantIngredientAlternatives.phrases';

/**
 * @function fetchProductVariantIngredientAlternatives
 * @description Fetch all product variant ingredient alternatives
 * @param {object} state
 */
export function fetchProductVariantIngredientAlternatives(state, filter) {
	let newFilter;
	if (filter) newFilter = filter;
	if (state.filter)
		newFilter = newFilter ? `${newFilter};${state.filter}` : state.filter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: newFilter,
	};

	return get(
		'/product/product_variant_ingredient_alternatives',
		params,
		null,
		state.headers
	)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addProductVariantIngredientAlternatives
 * @description adds product variant ingredient alternatives
 */
export function addProductVariantIngredientAlternatives(data) {
	return post('/product/product_variant_ingredient_alternatives', data)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_INGREDIENT_ALTERNATIVES_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editProductVariantIngredientAlternative
 * @description edits product variant ingredient alternative
 */
export function editProductVariantIngredientAlternative(data) {
	return post(
		`/product/product_variant_ingredient_alternatives/${data.id}`,
		data
	)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_INGREDIENT_ALTERNATIVE_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editProductVariantIngredientAlternatives
 * @description edits product variant ingredient alternatives
 */
export function editProductVariantIngredientAlternatives(data) {
	return patch('/product/product_variant_ingredient_alternatives', data)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_INGREDIENT_ALTERNATIVES_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteProductVariantIngredientAlternatives
 * @description deletes product variant ingredient alternatives
 */
export function deleteProductVariantIngredientAlternatives(productId) {
	return remove(`/product/product_variant_ingredient_alternatives/${productId}`)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_INGREDIENT_ALTERNATIVES_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
