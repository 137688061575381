'use strict';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';

import cx from 'classnames';

// import ButtonLoader from 'buttonLoader';
import { ButtonLoader } from 'dumb';

import Head from './head.component';
import Foot from './foot.component';
import Body from './body.component';
import BodyFoldable from './body.foldable.component';
import BodyFoldableInverted from './body.foldableInverted.component';
import BodyUnfoldedInverted from './body.unfoldedInverted.component';
import BodySummed from './body.summed.component';
import BodySubtable from './body.subtable.component';
import BodyCustomComponentUndfoldedInverted from './body.customComponent.unfoldedInverted.component';

class TableDefault extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			table: props.table,
			lazyContent: null,
			lazyContentLoaded: false
		};

		this.checkForLazyContentInSubtable = this.checkForLazyContentInSubtable.bind(
			this
		);
		this.loadLazyContentInTable = this.loadLazyContentInTable.bind(this);
	}

	checkForLazyContentInSubtable() {
		let { component, table, report } = this.props;
		let { lazyContent } = this.state;

		// Should we consider lazy content for next level?
		if (component.getDataProvider && component.getDataProvider()) {
			// Set lazy content state to make as prop for coming level
			lazyContent = () =>
				component.handleGetLazyContent(report.name, table.title);

			// Set content
			this.setState(() => ({
				lazyContent
			}));
		}
	}

	loadLazyContentInTable() {
		let { lazyContent } = this.props;
		lazyContent &&
			lazyContent().then(table => {
				this.setState(() => ({
					table,
					lazyContentLoaded: true
				}));
			});
	}

	/**
	 * Gets body based on table type
	 */
	getBody() {
		const { component, expandedTables } = this.props;
		const { lazyContent, table } = this.state;

		const sharedProps = {
			...this.props,
			table,
			lazyContent
		};

		switch (component.tableType) {
		case 'customComponentUnfoldedInverted':
			return (
				<BodyCustomComponentUndfoldedInverted
					{...{ ...sharedProps, expandedTables }}
				/>
			);
		case 'foldable':
			return <BodyFoldable {...{ ...sharedProps, expandedTables }} />;
		case 'foldableInverted':
			return <BodyFoldableInverted {...{ ...sharedProps, expandedTables }} />;
		case 'unfoldedInverted':
			return <BodyUnfoldedInverted {...{ ...sharedProps }} />;
		case 'summed':
			return <BodySummed {...this.props} />;
		case 'subtable':
			return <BodySubtable {...this.props} />;
		default:
			return <Body {...{ ...sharedProps }} />;
		}
	}

	componentDidMount() {
		this.loadLazyContentInTable();
		this.checkForLazyContentInSubtable();
	}

	render() {
		const { lazyContent, component, phrases } = this.props;
		const { lazyContentLoaded, table } = this.state;

		return lazyContent && !lazyContentLoaded ? (
			<p className="report-old__lazy-table-fetching">
				<ButtonLoader theme="dark" loading /> {phrases.FETCHING}
			</p>
		) : (
			<table className={cx('report-old__table', component.className)}>
				{component.header && <Head {...this.props} />}
				{table.content !== null && this.getBody()}
				{component.total && table.total && table.total !== null && (
					<Foot {...this.props} />
				)}
			</table>
		);
	}
}

TableDefault.propTypes = {
	component: PropTypes.object.isRequired,
	expandedTables: PropTypes.object,
	table: PropTypes.object.isRequired
};

export default TableDefault;
