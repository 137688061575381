'use strict';

import { get } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service';

/**
 * @function fetchShifts
 * @description Fetch person attributes
 * @param {Object} Object with limit, offset, filter and sort
 * @param {String} initialFilter
 * @returns Promise
 */
export function fetchShifts(state, filter, headers) {
	let newFilter = filter;
	if (state.filter) newFilter = `${newFilter};${state.filter}`;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: newFilter,
	};

	return get('/shiftplanning/shifts', params, null, headers)
		.then((res) => res)
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
		});
}
