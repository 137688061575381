'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';

import ProductSets from './components/productSets/productSets.component';
import DiscountIngredients from './components/ingredients/ingredients.component';

import _get from 'lodash/get';

import { enums } from './discounts.enums';
import phrases from './discounts.phrases';

import { setDiscount, updateDiscount, resetDiscount } from './store/discounts.actions';

import { fetchAllDiscounts, editDiscount, addDiscount, deleteDiscount } from './store/discounts.service';
import { Input, Tabs, InputCollectionSelect, Button, Toggle } from 'dumb';

// Wrapper for react-data
// Documentation can be found here https://react-table.js.org/
class Discounts extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);

		this.reduxKey = 'sale/discounts';

		this.state = {
			pages: null,
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this.fetchData = this.fetchData.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);

		this.columns = [
			{
				Header: 'Discount name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'desc',
				accessor: (d) => _get(d, 'description', ''),
				filterPath: ':description',
			},
			{
				Header: 'Type',
				id: 'type',
				accessor: (d) => _get(d, 'type', ''),
				filterPath: ':type',
			},
			{
				Header: 'Loyalty type',
				id: 'loyalty_type',
				accessor: (d) => _get(d, 'loyalty_type.name', ''),
				filterPath: ':loyalty_type.name',
			},
			{
				Header: 'Execution Order',
				id: 'execution_order',
				accessor: (d) => _get(d, 'execution_order', ''),
				filterPath: ':execution_order',
			},
			{
				Header: 'Max ingredient addons',
				id: 'max_ingredient_addons',
				accessor: (d) => _get(d, 'max_ingredient_addons', ''),
				filterPath: ':max_ingredient_addons',
			},
			{
				Header: 'Discount code',
				id: 'discount_code',
				accessor: (d) => _get(d, 'discount_code', ''),
				filterPath: ':discount_code',
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: 'legacy',
				filterPath: ':legacy',
				filterable: false,
				Cell: (d) => {
					return <Input type="checkbox" checked={d.original.legacy} disabled />;
				},
			},
		];
	}

	deleteEntry(id) {
		return deleteDiscount(id);
	}

	editEntry() {
		const { defaultValues } = this.props;

		const payload = {
			id: defaultValues.id,
			name: _get(defaultValues, 'name', ''),
			description: _get(defaultValues, 'description', ''),
			execution_order: _get(defaultValues, 'execution_order', ''),
			max_ingredient_addons: _get(defaultValues, 'max_ingredient_addons', ''),
			discount_code: _get(defaultValues, 'discount_code', ''),
			legacy: !!defaultValues.legacy,
		};

		return editDiscount(defaultValues.id, payload);
	}

	addEntry() {
		const { defaultValues } = this.props;

		const payload = {
			name: _get(defaultValues, 'name', ''),
			description: _get(defaultValues, 'description', ''),
			type: _get(defaultValues, 'type', enums.TYPES[0]),
			loyalty_type: _get(defaultValues, 'loyalty_type.id', ''),
			execution_order: _get(defaultValues, 'execution_order', ''),
			max_ingredient_addons: _get(defaultValues, 'max_ingredient_addons', ''),
			discount_code: _get(defaultValues, 'discount_code', ''),
		};

		return addDiscount(payload);
	}

	setInitialEditValues(data) {
		this.props.setDiscount(data);
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}
			>
				Legacy
			</Button>
		);
	}

	getEditableCells(e) {
		const { defaultValues } = this.props;

		return [
			{
				header: 'Discount name',
				value: (
					<Input
						id="name"
						placeholder="name"
						value={_get(defaultValues, 'name', '')}
						onChange={(event) => this.editStoreEntry(event, 'name')}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="description"
						value={_get(defaultValues, 'description', '')}
						onChange={(event) => this.editStoreEntry(event, 'description')}
					/>
				),
			},
			{
				header: 'Type',
				value: (
					<Input
						id="type"
						type="select"
						value={_get(defaultValues, 'type', {
							value: enums.TYPES[0],
							label: enums.TYPES[0],
						})}
						onChange={(event) => this.editStoreEntry(event, 'type')}
						options={enums.TYPES.map((x) => ({ value: x, label: x }))}
						clearable={false}
					/>
				),
			},
			{
				header: 'Loyalty type',
				value: (
					<InputCollectionSelect
						id="loyalty_type"
						placeholder="select loyalty type"
						value={
							_get(defaultValues, 'loyalty_type.id', '')
								? {
										value: _get(defaultValues, 'loyalty_type.id', ''),
										label: `${_get(defaultValues, 'loyalty_type.name', '')}`,
								  }
								: null
						}
						handleChange={(key, value) =>
							this.editStoreEntry(
								{
									id: value ? value.value : '',
									name: value ? value.label : '',
								},
								'loyalty_type'
							)
						}
						clearable={false}
						cache
						apiPath="/campaigning/loyalty_types"
						params={{
							limit: 300,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Execution Order',
				value: (
					<Input
						id="execution order"
						placeholder="execution order"
						type="number"
						min="0"
						value={_get(defaultValues, 'execution_order', '')}
						onChange={(event) => this.editStoreEntry(event, 'execution_order')}
					/>
				),
			},
			{
				header: 'Max ingredient addons',
				value: (
					<Input
						id="max indredient addons"
						placeholder="max indredient addons"
						type="number"
						min="0"
						value={_get(defaultValues, 'max_ingredient_addons', '')}
						onChange={(event) => this.editStoreEntry(event, 'max_ingredient_addons')}
					/>
				),
			},
			{
				header: 'Discount code',
				value: (
					<Input
						id="discount code"
						placeholder="discount code"
						value={_get(defaultValues, 'discount_code', '')}
						onChange={(event) => this.editStoreEntry(event, 'discount_code')}
					/>
				),
			},
			{
				header: 'Legacy',
				value: (
					<Toggle
						id="legacy"
						toggled={defaultValues.legacy}
						onClick={(event) => this.editStoreEntry(event, 'legacy')}
					/>
				),
			},
		];
	}

	editStoreEntry(e, name) {
		const value = e.target ? e.target.value : e;

		const updateObject = {
			[name]: value,
		};

		this.props.updateDiscount(updateObject);
	}

	fetchData(state) {
		return fetchAllDiscounts(state);
	}

	render() {
		const {
			// batchSelection props
			onInitialization,
			reduxKey: batchReduxKey,
			style,
			defaultPageSize,
			batchSelection,
		} = this.props;

		const legacyFilter = this.state.legacyFilterOn ? this.state.legacyFilter : '';
		return (
			<ReactDataWrapper
				accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.DISCOUNTS}
				defaultPageSize={batchSelection ? defaultPageSize : 10}
				reduxKey={batchSelection ? batchReduxKey : this.reduxKey}
				style={batchSelection ? style : {}}
				{...(batchSelection && { onInitialization })}
				batchSelection={batchSelection}
				columns={this.columns}
				totalEntries={this.state.totalEntries} // Display the total number of pages
				fetchData={this.fetchData} // Request new data when things change
				filterable
				manual // Forces table not to paginate or sort automatically, so we can handle it server-side
				subcomponent={(row) => {
					const tabContent = [
						{
							name: phrases.PRODUCT_INGREDIENTS,
							component: <DiscountIngredients title={phrases.PRODUCT_INGREDIENTS} discount={row.original} />,
						},
						{
							name: phrases.PRODUCT_SETS,
							component: <ProductSets title={phrases.PRODUCT_SETS} discount={row.original} />,
						},
					];
					return (
						<SubTable>
							<Tabs tabContent={tabContent} />
						</SubTable>
					);
				}}
				editEntry={(e) => this.editEntry(e)}
				editableCells={this.getEditableCells()}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetDiscount}
				actionsWidth={60}
				deleteEntry={this.deleteEntry}
				createEntry={this.addEntry}
				customAreaComponents={this.getLegacyFilterButton()}
				extraFilters={legacyFilter}
			/>
		);
	}
}

Discounts.propTypes = {
	defaultValues: PropTypes.object,

	updateDiscount: PropTypes.func,
	setDiscount: PropTypes.func,
	resetDiscount: PropTypes.func,
	onInitialization: PropTypes.func,
	reduxKey: PropTypes.string,
	style: PropTypes.object,
	defaultPageSize: PropTypes.number,
	batchSelection: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setDiscount,
			updateDiscount,
			resetDiscount,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultValues: store.discounts.data.defaultDiscount,
	};
};

export default connectWithStore(Discounts, mapStateToProps, mapDispatchToProps);
