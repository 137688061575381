export const SET_METADATA = 'ADYEN_RECONCILIATION_MATRIX/SET_METADATA';
export const SET_MAPPED_DATA = 'ADYEN_RECONCILIATION_MATRIX/SET_MAPPED_DATA';
export const SET_TOTAL_VALUES = 'ADYEN_RECONCILIATION_MATRIX/SET_TOTAL_VALUES';
export const RESET_TABLE_DATA = 'ADYEN_RECONCILIATION_MATRIX/RESET_TABLE_DATA';

export const SET_ADJUST_MODAL_DATA =
	'ADYEN_RECONCILIATION_MATRIX/SET_ADJUST_MODAL_DATA';
export const UPDATE_ADJUST_MODAL_DATA =
	'ADYEN_RECONCILIATION_MATRIX/UPDATE_ADJUST_MODAL_DATA';
export const SET_SIGN_MODAL_DATA =
	'ADYEN_RECONCILIATION_MATRIX/SET_SIGN_MODAL_DATA';
export const CLEAR_MODAL_DATA = 'ADYEN_RECONCILIATION_MATRIX/CLEAR_MODAL_DATA';
export const SET_DEPOSIT_TYPE = 'ADYEN_RECONCILIATION_MATRIX/SET_DEPOSIT_TYPE';
export const UPDATE_CELL_VALUE =
	'ADYEN_RECONCILIATION_MATRIX/UPDATE_CELL_VALUE';
export const UPDATE_SIGN_MODAL_DATA =
	'ADYEN_RECONCILIATION_MATRIX/UPDATE_SIGN_MODAL_DATA';
export const SET_PRINT_VIEW_METADATA =
	'ADYEN_RECONCILIATION_MATRIX/SET_PRINT_VIEW_METADATA';
export const SET_FILTER_OPERATOR =
	'ADYEN_RECONCILIATION_MATRIX/SET_FILTER_OPERATOR';
export const SET_FILTER_DIFFERENCE =
	'ADYEN_RECONCILIATION_MATRIX/SET_FILTER_DIFFERENCE';

export function setMetadata(payload) {
	return {
		type: SET_METADATA,
		payload,
	};
}

export function setMappedData(payload) {
	return {
		type: SET_MAPPED_DATA,
		payload,
	};
}

export function setTotalValues(payload) {
	return {
		type: SET_TOTAL_VALUES,
		payload,
	};
}

export function resetTableData() {
	return {
		type: RESET_TABLE_DATA,
	};
}

export function setAdjustModalData(payload) {
	return {
		type: SET_ADJUST_MODAL_DATA,
		payload,
	};
}

export function updateAdjustModalData(payload) {
	return {
		type: UPDATE_ADJUST_MODAL_DATA,
		payload,
	};
}

export function setSignModalData(payload) {
	return {
		type: SET_SIGN_MODAL_DATA,
		payload,
	};
}

export function clearModalData() {
	return {
		type: CLEAR_MODAL_DATA,
	};
}

export function setDepositType(payload) {
	return {
		type: SET_DEPOSIT_TYPE,
		payload,
	};
}

export function updateCellValue(payload) {
	return {
		type: UPDATE_CELL_VALUE,
		payload,
	};
}

export function updateSignModalData(payload) {
	return {
		type: UPDATE_SIGN_MODAL_DATA,
		payload,
	};
}

export function setPrintViewMetadata(payload) {
	return {
		type: SET_PRINT_VIEW_METADATA,
		payload,
	};
}

export function setFilterOperator(payload) {
	return {
		type: SET_FILTER_OPERATOR,
		payload,
	};
}

export function setFilterDifference(payload) {
	return {
		type: SET_FILTER_DIFFERENCE,
		payload,
	};
}
