export default {
	TITLE: 'Market grace periods',
	MARKET_GRACE_PERIOD_ADDED: 'Market grace periods added!',
	MARKET_GRACE_PERIOD_EDITED: 'Market grace period edited!',
	MARKET_GRACE_PERIODS_EDITED: 'Market grace periods edited!',
	MARKET_GRACE_PERIOD_DELETED: 'Market grace period deleted!',

	// batch form
	MODAL_BATCH_STEP_LIST_TITLE: 'Add market grace periods',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	BATCH_ADD_TIP: 'Shift times and break times are in minutes',
	// table headers
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_MARKET: 'Market',
	MODAL_BATCH_STEP_EARLY_SHIFT_START: 'Early shift start',
	MODAL_BATCH_STEP_EARLY_SHIFT_END: 'Early shift end',
	MODAL_BATCH_STEP_LATE_SHIFT_START: 'Late shift start',
	MODAL_BATCH_STEP_LATE_SHIFT_END: 'Late shift end',
	MODAL_BATCH_STEP_BREAK_TOO_SHORT: 'Break too short',
	MODAL_BATCH_STEP_BREAK_TOO_LONG: 'Break too long',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add grace periods',
};
