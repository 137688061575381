'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalRow } from 'dumb/modal';
import { InputCollectionSelect } from 'dumb';
import { Tip } from 'dumb/tips';
import getColumns from 'reactDataWrapperColumns/pos/tileLayouts.columns';

// utils
import phrases from '../../../../tileLayout.phrases';

// styles
import './formSelectLayouts.css';

class FormSelectLayouts extends Component {
	editStoreEntry(e, type) {
		const { batchForm } = this.props;

		const payload = {
			...batchForm,
			[type]: e,
		};

		this.props.updateBatchForm(payload);
	}

	render() {
		const { batchForm } = this.props;

		return (
			<div className="modal-batch__content">
				<ModalRow>
					<InputCollectionSelect
						id="layout-col"
						required
						label={phrases.TILE_LAYOUTS}
						placeholder={phrases.TILE_LAYOUTS_SELECT}
						value={batchForm.tile_layouts}
						handleChange={(key, value) =>
							this.editStoreEntry(value, 'tile_layouts')
						}
						multi
						cache
						apiPath="/pos/tile_layouts"
						params={{
							limit: 30,
							filter: `:legacy==false`,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						tableTitle={phrases.TILE_LAYOUT_GROUPS}
						tableReduxKey="tile_layouts/overlay/pos/tile_layouts"
						tableColumns={getColumns()}
					/>
				</ModalRow>

				<div className="form-select-layout__tip">
					<Tip content={phrases.TIP_GROUPS} />
				</div>
			</div>
		);
	}
}

FormSelectLayouts.propTypes = {
	batchForm: PropTypes.object,
	updateBatchForm: PropTypes.func,
};

FormSelectLayouts.defaultProps = {
	batchForm: {},
};

export default FormSelectLayouts;
