'use strict';

export default {
	EMAILS: 'Emails',
	EXTRA_EMAILS: 'Extra Emails',
	SUBJECT: 'Subject',
	CONTENT: 'Message',
	ATTACHMENTS: 'Attachments',
	BUTTON_TEXT: 'Send Email',
	BUTTON_TEXT_ERROR: 'Something went wrong!',
	BUTTON_TEXT_SUCCESS: 'Success!',
	TOGGLE_ALL: 'Toggle all'
};
