'use strict';

import {
	UPDATE_INGREDIENT_CONFIGURATION,
	RESET_INGREDIENT_CONFIGURATION,
	SET_INGREDIENT_CONFIGURATION
} from './ingredientConfigurations.actions';

const defaultState = {
	data: {
		ingredientConfiguration: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_INGREDIENT_CONFIGURATION: {
		return {
			...state,
			data: {
				...state.data,
				ingredientConfiguration: payload
			}
		};
	}

	case UPDATE_INGREDIENT_CONFIGURATION: {
		return {
			...state,
			data: {
				ingredientConfiguration: {
					...state.data.ingredientConfiguration,
					...payload
				}
			}
		};
	}

	case RESET_INGREDIENT_CONFIGURATION: {
		return defaultState;
	}

	default:
		return state;
	}
}

export default reducer;
