'use strict';

import React, { Component } from 'react';

import { ReactDataWrapper } from 'reactDataWrapper';
import { fetchContacts } from './contacts.service';
import _get from 'lodash/get';

class ContactsContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			contacts: [],
			pages: null,
			loading: true,
			selectedCompany: null,
			selectedMarket: null,
		};

		this.reduxKey = 'hr/contacts';

		this.fetchData = this.fetchData.bind(this);

		this.columns = [
			{
				Header: 'Person id',
				id: 'person_ID',
				accessor: 'person.id', // String-based value accessors!
				width: 75,
				filterPath: ':person.id',
			},
			{
				Header: 'Badge',
				width: 75,
				id: 'badge',
				accessor: (d) => _get(d, 'person.current_moneyball_sub_position.badge', null),
				// filterPath: [':person.identity.first_name', ':person.identity.middle_name' ,':person.identity.first_name'], // should be prefixed with a :-sign, as it is used for filtering and sorting
				filterPath: ':person.current_moneyball_sub_position.badge',
				cellActions: {
					canEdit: true,
				},
			},
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => d.person?.identity?.full_name ?? '',
				filterPath: ':person.identity.full_name',
			},
			{
				Header: 'Workplace',
				id: 'workplace',
				accessor: (d) => _get(d, 'workplace.name', null),
				filterPath: ':workplace.name',
			},

			{
				Header: 'Email',
				id: 'email',
				accessor: (d) => _get(d, 'person.person_email_address.email_address', null),

				Cell: (val) => <a href={`mailto:${val.value}`}>{val.value}</a>,
				filterPath: ':person.person_email_address.email_address', // should be prefixed with a :-sign, as it is used for filtering and sorting
			},
			{
				Header: 'Phone Number',
				id: 'phoneNumber',
				accessor: (d) => _get(d, 'person.person_phone_number.phone_number', null),
				width: 150,
				Cell: (val) => (
					<span>
						{/* <PhoneInput value={props.value} disabled /> */}
						{val.value}
					</span>
				),
				filterPath: ':person.person_phone_number.phone_number', // should be prefixed with a :-sign, as it is used for filtering and sorting
			},

			{
				Header: 'Market',
				width: 150,
				id: 'market',
				accessor: (d) => _get(d, 'person.identity.market.name', null),
				filterPath: ':person.identity.market.name',
			},
		];
	}

	fetchData(state) {
		// Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
		// You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
		this.setState(() => ({ loading: true }));
		// Request the data however you want.  Here, we'll use our mocked service we created earlier

		return fetchContacts(state)
			.then((response) => {
				// Now just get the rows of data to your React Table (and update anything else like total pages or loading)
				this.setState(() => ({
					loading: false,
				}));
				return response;
			})
			.catch(() => {
				this.setState(() => ({
					loading: false,
				}));
			});
	}

	render() {
		return (
			<ReactDataWrapper
				title="Contact list"
				className="-striped -highlight"
				columns={this.columns}
				totalEntries={this.state.totalEntries} // Display the total number of pages
				loading={this.state.loading}
				fetchData={this.fetchData} // Request new data when things change
				filterable
				disableFetchCsvButton
				enableMultiSelection={false}
				defaultPageSize={50}
				reduxKey={this.reduxKey}
				manual // Forces table not to paginate or sort automatically, so we can handle it server-side
			/>
		);
	}
}

ContactsContainer.defaultProps = {
	data: [],
	columns: [],
};

export default ContactsContainer;
