'use strict';

/**
 * @namespace __endpointFamily
 * @memberOf SERVICES
 * @description
 * Wrapper for EndpointFamily class
 */

import config from 'config';
import _each from 'lodash/each';
const changeCase = require('change-case');

const apiHost = config.api.protocol + '://' + config.api.host;
const apiUri = config.api.version
	? config.api.path + config.api.version
	: config.api.path;

function _generateUrl(category, name, hasParams) {
	const params = hasParams ? '/' : '';
	return apiHost + apiUri + '/' + category + '/' + name + params;
}

function _generatePath(category, name, hasParams) {
	const params = hasParams ? '/' : '';
	return '/' + category + '/' + name + params;
}

/**
 * @class EndpointFamily
 * @param {Array}  members  Members of the family
 * @param {String} category Name of the category
 * @memberOf SERVICES.__endpointFamily
 */
function EndpointFamily(category, members) {
	this.members = members;
	this.category = category;
}

/**
 * @method EndpointFamily.prototype.generate
 * @description Generate family of endpoints, based on members and name
 * @return {Object} Endpoint familiy based on below template
 * @memberOf SERVICES.__endpointFamily
 */
EndpointFamily.prototype.generate = function() {
	const _this = this;
	const family = {};
	_each(_this.members, function(name) {
		family[name] = {
			index: {
				url: _generateUrl(_this.category, name),
				path: _generatePath(_this.category, name),
				resource: changeCase.pascal(name),
				method: 'GET',
				action: 'index'
			},
			find: {
				url: _generateUrl(_this.category, name, true),
				path: _generatePath(_this.category, name, true),
				resource: changeCase.pascal(name),
				method: 'GET',
				action: 'find'
			},
			create: {
				url: _generateUrl(_this.category, name),
				path: _generatePath(_this.category, name),
				resource: changeCase.pascal(name),
				method: 'POST',
				action: 'create'
			},
			update: {
				url: _generateUrl(_this.category, name, true),
				path: _generatePath(_this.category, name, true),
				resource: changeCase.pascal(name),
				method: 'POST',
				action: 'update'
			},
			delete: {
				url: _generateUrl(_this.category, name, true),
				path: _generatePath(_this.category, name, true),
				resource: changeCase.pascal(name),
				method: 'DELETE',
				action: 'delete'
			}
		};
	});
	return family;
};

module.exports = EndpointFamily;
