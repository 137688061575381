import React from 'react';
import PropTypes from 'prop-types';

import { ModalRow, Input } from 'dumb';
import { Tip } from 'dumb/tips';

import phrases from './../../../assetLeftAligned.phrases';

const AddAssetCollection = (props) => {
	const {
		defaultAssetCollection,
		updateDefaultAssetCollection,
		assigningToProduct,
	} = props;

	const editStoreEntry = (value, name) => {
		const payload = {
			[name]: value,
		};

		updateDefaultAssetCollection(payload);
	};

	return (
		<>
			<div className="pos-modal-batch__tip">
				<Tip
					content={
						assigningToProduct
							? phrases.MODAL_ADD_ASSET_COLLECTION_TIP_PRODUCT
							: phrases.MODAL_ADD_ASSET_COLLECTION_TIP_FILTER
					}
				/>
			</div>
			<ModalRow>
				<Input
					id="name"
					label={phrases.MODAL_NAME}
					placeholder={phrases.MODAL_NAME_PLACEHOLDER}
					value={defaultAssetCollection.name || ''}
					onChange={(event) => editStoreEntry(event.target.value, 'name')}
				/>
			</ModalRow>
			<ModalRow>
				<Input
					id="description"
					label={phrases.MODAL_DESCRIPTION}
					placeholder={phrases.MODAL_DESCRIPTION_PLACEHOLDER}
					value={defaultAssetCollection.description || ''}
					onChange={(event) =>
						editStoreEntry(event.target.value, 'description')
					}
				/>
			</ModalRow>
		</>
	);
};

AddAssetCollection.propTypes = {
	defaultAssetCollection: PropTypes.object,
	updateDefaultAssetCollection: PropTypes.func,
	assigningToProduct: PropTypes.bool,
};

export default AddAssetCollection;
