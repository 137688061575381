export const SET_PRODUCT_RECOMMENDATION =
	'SALES_CONFIGURATION_PRODUCT_RECOMMENDATIONS/SET_PRODUCT_RECOMMENDATION';
export const UPDATE_PRODUCT_RECOMMENDATION =
	'SALES_CONFIGURATION_PRODUCT_RECOMMENDATIONS/UPDATE_PRODUCT_RECOMMENDATION';
export const RESET_STATE =
	'SALES_CONFIGURATION_PRODUCT_RECOMMENDATIONS/RESET_STATE';

/**
 * @param {object} payload
 */
export function setProductRecommendation(payload) {
	return {
		type: SET_PRODUCT_RECOMMENDATION,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateProductRecommendation(payload) {
	return {
		type: UPDATE_PRODUCT_RECOMMENDATION,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET_STATE
	};
}
