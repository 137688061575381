'use strict';

/**
 * @namespace __detailsModel
 * @memberOf SERVICES
 * @description
 * Model for all details components
 * @example
 * var details = new __detailsModel();
 */

// Services
import * as translations from 'translations.service';

// Tools
import { push } from 'redux-first-history';

import _has from 'lodash/has';
import _merge from 'lodash/merge';
import _extend from 'lodash/extend';
import _union from 'lodash/union';
import { set as setFeedback } from 'feedback.vanilla.service';
import { confirmModal } from 'utils';
import store from 'appState/store';
const defaults = require('./details.defaults');

/// //////////////
// Constructor //
/// //////////////

export default class DetailsModel {
	constructor(reduxKey) {
		this.reduxKey = reduxKey;
		return this;
	}

	/// ///////////////////////////////
	// Action dispatchers / Setters //
	/// ///////////////////////////////

	initReducer() {
		return store.dispatch({
			type: 'LEGACY_DETAILS__INIT_REDUCER',
			payload: {
				reduxKey: this.reduxKey,
			},
		});
	}

	/**
	 * @param {object} data
	 */
	setData(data) {
		return store.dispatch({
			type: 'LEGACY_DETAILS__SET_DATA',
			payload: {
				reduxKey: this.reduxKey,
				value: data,
			},
		});
	}

	/**
	 * @param {array} headers
	 */
	setHeaders(headers) {
		return store.dispatch({
			type: 'LEGACY_DETAILS__SET_HEADERS',
			payload: {
				reduxKey: this.reduxKey,
				value: headers,
			},
		});
	}

	/**
	 * @param {boolean} isCreating
	 */
	setIsCreating(isCreating) {
		return store.dispatch({
			type: 'LEGACY_DETAILS__SET_IS_CREATING',
			payload: {
				reduxKey: this.reduxKey,
				value: isCreating,
			},
		});
	}

	/**
	 * @param {boolean} isEditing
	 */
	setIsEditing(isEditing) {
		return store.dispatch({
			type: 'LEGACY_DETAILS__SET_IS_EDITING',
			payload: {
				reduxKey: this.reduxKey,
				value: isEditing,
			},
		});
	}

	/**
	 * @param {object} item
	 */
	setItem(item) {
		return store.dispatch({
			type: 'LEGACY_DETAILS__SET_ITEM',
			payload: {
				reduxKey: this.reduxKey,
				value: item,
			},
		});
	}

	/**
	 * @param {number} id
	 */
	setItemId(id) {
		return store.dispatch({
			type: 'LEGACY_DETAILS__SET_ITEM_ID',
			payload: {
				reduxKey: this.reduxKey,
				value: id,
			},
		});
	}

	/**
	 * @param {function} listActions
	 */
	setDetailsActions(detailsActions) {
		return store.dispatch({
			type: 'LEGACY_DETAILS__SET_DETAILS_ACTIONS',
			payload: {
				reduxKey: this.reduxKey,
				value: detailsActions,
			},
		});
	}

	/**
	 * @param {function} listActions
	 */
	clearData(detailsActions) {
		return store.dispatch({
			type: 'LEGACY_DETAILS__CLEAR_DATA',
			payload: {
				reduxKey: this.reduxKey,
			},
		});
	}

	/// //////////////////
	// Helpers, shared //
	/// //////////////////
	initApiErrorHeaders(headers, errors) {
		return require('helpers/initApiErrorHeaders').call(this, headers, errors);
	}

	initDefaultHeaders(headers) {
		return require('helpers/initDefaultHeaders').call(this, translations, headers);
	}

	initDefaultHeaderPermissions(headers) {
		return require('helpers/initDefaultHeaderPermissions').call(this, headers);
	}

	initHeaderEnumOptions(headers) {
		return require('helpers/initHeaderEnumOptions').call(this, headers);
	}

	initHeaderFocus(headers) {
		return require('helpers/initHeaderFocus').call(this, headers);
	}

	initHeaderHash(headers) {
		return require('helpers/initHeaderHash').call(this, headers);
	}

	initHeaderReferenceName(headers) {
		return require('helpers/initHeaderReferenceName').call(this, headers);
	}

	initHeaderTranslations(headers) {
		return require('helpers/initHeaderTranslations').call(this, translations, headers);
	}

	cleanData(data) {
		return require('helpers/cleanData').call(this, data);
	}

	collectionFormat(collection) {
		return require('helpers/collectionFormat').call(this, collection);
	}

	itemCreate(data) {
		return require('helpers/itemCreate').call(this, data);
	}

	itemDelete(id) {
		return require('helpers/itemDelete').call(this, id);
	}

	itemFormat(item) {
		return require('helpers/itemFormat').call(this, item);
	}

	itemUpdate(data, id) {
		return require('helpers/itemUpdate').call(this, data, id);
	}

	fetchItem(endpoint, id, params) {
		return require('helpers/fetchItem').call(this, endpoint, id, params);
	}

	runModelCallbacks() {
		return require('helpers/runModelCallbacks').call(this);
	}

	/// ///////////////////
	// Helpers, private //
	/// ///////////////////
	loadItem(id) {
		return require('./helpers/loadItem').call(this, id);
	}

	/// //////////////////////////
	// Model specific handlers //
	/// //////////////////////////
	/**
	 * @function handleCreateEditItem
	 * @memberOf SERVICES.__detailsModel
	 * @description handler for creating and editing items
	 * @param  {object} args arguments containing data to create or update items with
	 */
	handleCreateEditItem(args) {
		if (args.type === 'create') {
			this.itemCreate(args.headers).then(
				function (item) {
					this.setIsEditing(true);
					this.setIsCreating(false);
					this.setItem(item);
					setFeedback(translations.getSingle('COMPONENTS.DETAILS.CREATED'), 1);

					const url = window.location.pathname;
					const newUrl = url.slice(0, url.lastIndexOf('/'));
					store.dispatch(push(`${newUrl}/${item.id}`));
				}.bind(this)
			);
		} else if (args.type === 'edit') {
			this.itemUpdate(args.headers, args.id).then(
				function (item) {
					this.setItem(item);
					setFeedback(translations.getSingle('COMPONENTS.DETAILS.UPDATED'), 1);
				}.bind(this)
			);
		}
	}

	/**
	 * @function handleDeleteItem
	 * @memberOf SERVICES.__detailsModel
	 * @description handler for deleting items via modal confirmation
	 * @param  {object} item item to delete
	 */
	handleDeleteItem(item) {
		confirmModal.show({
			data: {
				header: translations.getSingle('COMPONENTS.DETAILS.CONFIRM_DELETE_TITLE'),
				subheader: translations.getSingle('COMPONENTS.DETAILS.CONFIRM_DELETE_MESSAGE'),
				confirmLabel: translations.getSingle('COMPONENTS.DETAILS.CONFIRM_DELETE_RESOLVE'),
				cancelLabel: translations.getSingle('COMPONENTS.DETAILS.CONFIRM_DELETE_REJECT'),
			},
			onConfirm: () => {
				return this.itemDelete(item.id).then((id) => {
					setFeedback(translations.getSingle('COMPONENTS.DETAILS.DELETED'), 1);
					const url = window.location.pathname;
					const newUrl = url.slice(0, url.lastIndexOf('/'));
					store.dispatch(push(newUrl));
				});
			},
			onCancel: (error) => {
				Error('Something went wrong', error);
			},
		});
	}

	/// /////////////////////////////////////////////////
	// Props = State, Methods, Translations, Settings //
	/// /////////////////////////////////////////////////
	/**
	 * @function getMethods
	 * @memberOf SERVICES.__detailsModel
	 * @description returns handler methods for components
	 * @return {object} handler methods
	 */
	getMethods() {
		return {
			feedback: setFeedback,
			handleCreateEditItem: this.handleCreateEditItem.bind(this),
			handleDeleteItem: this.handleDeleteItem.bind(this),
			handleClearData: this.clearData.bind(this),
			handleInitView: this.initView.bind(this),
		};
	}

	/**
	 * @function getSettings
	 * @memberOf SERVICES.__detailsModel
	 * @description returns settings for components
	 * @return {object} settings
	 */
	getSettings() {
		return this.settings;
	}

	/**
	 * @function getState
	 * @memberOf SERVICES.__listModel
	 * @description returns state for components
	 * @return {object} current state
	 */
	getState() {
		return store.getState()?.legacyDetailsData[this.reduxKey];
	}

	/**
	 * @function getTranslations
	 * @memberOf SERVICES.__detailsModel
	 * @description returns translations for components
	 * @return {object} translations
	 */
	getTranslations() {
		return translations.getObject([
			'COMPONENTS.DETAILS.SAVE',
			'COMPONENTS.DETAILS.CREATE',
			'COMPONENTS.DETAILS.NEW',
			'COMPONENTS.DETAILS.BACK',
			'COMPONENTS.DETAILS.DELETE',
			'COMPONENTS.INPUTS.INPUT__REPEAT',
			'COMPONENTS.INPUTS.SELECT__NO_RESULTS',
			'COMPONENTS.INPUTS.SELECT__SEARCH',
			'COMPONENTS.INPUTS.REQUIRED',
			'COMPONENTS.INPUTS.INVALID_FIELDS',
		]);
	}

	/**
	 * @function getProps
	 * @memberOf SERVICES.__detailsModel
	 * @description returns props for components
	 * @return {object} props
	 */
	getProps() {
		return _extend(this.getState(), {
			methods: this.getMethods(),
			settings: this.getSettings(),
			translations: this.getTranslations(),
		});
	}

	/// /////////////
	// Initiation //
	/// /////////////
	/**
	 * @function initView
	 * @memberOf SERVICES.__detailsModel
	 * @description
	 * Initiates view
	 */
	initView() {
		return new Promise((resolve) => {
			const state = this.getState();
			// If no itemId then state is creating
			if (!state.itemId) {
				this.setItem({});
				this.setIsEditing(false);
				this.setIsCreating(true);
				resolve();
			}

			// Else state is editing
			else {
				this.loadItem(state.itemId).then(
					function (item) {
						this.setItem(item);
						this.setIsEditing(true);
						this.setIsCreating(false);
						resolve(item);
					}.bind(this)
				);
			}
		});
	}

	/**
	 * @function init
	 * @memberOf SERVICES.__detailsModel
	 * @description
	 * Initiates model: settings, headers, view
	 */
	init() {
		// Merge default settings and model settings
		this.settings = _merge({}, defaults.settings, this.settings);

		// Make defaultState this state
		this.state = _merge({}, defaults.state);

		this.initReducer();

		// Make default listActions and model listActions part of state
		this.setDetailsActions(
			function (props) {
				return _has(this, 'detailsActions')
					? _union(defaults.detailsActions(props), this.detailsActions(props))
					: defaults.detailsActions(props);
			}.bind(this)
		);

		// Make headers part of state
		this.setHeaders(this.headers);

		// Add default headers to state headers
		this.initDefaultHeaders(this.getState().headers);

		// Add default permissions to state headers
		this.initDefaultHeaderPermissions(this.getState().headers);

		// Translate labels for state headers
		this.initHeaderTranslations(this.getState().headers);

		// Init header hash
		this.initHeaderHash(this.getState().headers);

		// Add reference name to headers
		this.initHeaderReferenceName(this.getState().headers);

		// Add enum options to headers
		this.initHeaderEnumOptions(this.getState().headers);

		// Add focus to first available header
		this.initHeaderFocus(this.getState().headers);
	}
}
