'use strict';

import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './posConfigurationVersions.phrases';

/**
 * @function fetchPosConfigurations
 * @description Fetch all pos configurations
 */
export function fetchPosConfigurations(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/pos/pos_configurations', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchMarkets
 * @description Fetch all markets
 */
export function fetchMarkets(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/organization/markets', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchPosConfigurationVersions
 * @description Fetch all pos configuration versions
 */
export function fetchPosConfigurationVersions(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/pos/pos_configuration_versions', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchPosConfigurationTileLayouts
 * @description Fetch pos configuration tile layout for the version, so we can show the tile layouts
 */
export function fetchPosConfigurationTileLayouts(posConfigurationVersion) {
	// Set params and filter
	const params = {
		filter: `:pos_configuration_version.id=='${posConfigurationVersion}'`,
	};

	return get('/pos/pos_configuration_tile_layouts', params)
		.then((res) => res)
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

export function addPosConfigurationVersion(data) {
	return post('/pos/pos_configuration_versions', data)
		.then((res) => {
			setFeedback(
				data.batch
					? phrases.POS_CONFIGURATION_VERSIONS_ADDED
					: phrases.POS_CONFIGURATION_VERSION_ADDED,
				1
			);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

export function duplicatePosConfigurationVersion(data) {
	return post(
		'/pos/pos_configuration_versions/create_and_end_previous_version',
		data
	)
		.then((res) => {
			setFeedback(phrases.POS_CONFIGURATION_VERSIONS_DUPLICATED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editPosConfigurationVersion
 * @param {Object} payload - update object
 */
export function editPosConfigurationVersion(payload) {
	return post(`/pos/pos_configuration_versions/${payload.id}`, payload)
		.then((res) => {
			setFeedback(phrases.POS_CONFIGURATION_VERSION_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editBatchPosConfigurationVersions
 * @param {Object} payload - update object
 */
export function editBatchPosConfigurationVersions(payload) {
	return patch('/pos/pos_configuration_versions', payload)
		.then((res) => {
			setFeedback(phrases.POS_CONFIGURATION_VERSIONS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deletePosConfigurationVersion
 * @param {Number} id - id of pos config version to delete
 */
export function deletePosConfigurationVersion(id) {
	return remove(`/pos/pos_configuration_versions/${id}`)
		.then((res) => {
			setFeedback(phrases.POS_CONFIGURATION_VERSION_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
