import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './filterProductVariants.phrases';

/**
 * @function fetchFilterProductVariants
 * @description Fetch all filter product variants
 * @param {object} state
 */
export function fetchFilterProductVariants(state, filter) {
	const newFilter = state.filter ? `${state.filter};${filter}` : filter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: newFilter,
	};

	return get('/product/filter_product_variants', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addFilterProductVariant
 * @description add filter product variant
 */
export function addFilterProductVariant(data) {
	return post('/product/filter_product_variants', data)
		.then((res) => {
			setFeedback(phrases.FILTER_PRODUCT_VARIANT_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editFilterProductVariant
 * @description edit filter product variant
 */
export function editFilterProductVariant(data) {
	return post(`/product/filter_product_variants/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.FILTER_PRODUCT_VARIANT_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editFilterProductVariants
 * @description edit filter product variants
 */
export function editFilterProductVariants(data) {
	return patch('/product/filter_product_variants', data)
		.then((res) => {
			setFeedback(phrases.FILTER_PRODUCT_VARIANTS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteFilterProductVariant
 * @description delete filter product variants
 */
export function deleteFilterProductVariant(id) {
	return remove(`/product/filter_product_variants/${id}`)
		.then((res) => {
			setFeedback(phrases.FILTER_PRODUCT_VARIANT_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
