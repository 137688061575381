import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchProductSet
 * @description Fetch all discounts
 * @param {object} state
 */
export function fetchProductSet(discountId, state) {
	let preFilter = `:discount.id=='${discountId}'`;

	let filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter
	};

	return get(`/sale/discount_product_sets`, params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editProductSet
 * @description edit
 */
export function editProductSet(addonId, data) {
	return post(`/sale/discount_product_sets/${addonId}`, data)
		.then(res => {
			setFeedback('Product set edited', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function addProductSet
 * @description add
 */
export function addProductSet(data) {
	return post(`/sale/discount_product_sets`, data)
		.then(res => {
			setFeedback('Product set added', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteProductSet
 * @description edit
 */
export function deleteProductSet(productSetId) {
	return remove(`/sale/discount_product_sets/${productSetId}`)
		.then(res => {
			setFeedback('Product set deleted', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
