'use strict';

import _get from 'lodash/get';

/**
 * Data
 * @param  {object} data
 * @param  {string} path
 * @param  {string} defaultValue
 * @description
 * Wrapper for lodash _get
 */
const Data = (data, path, defaultValue = null) => _get(data, path, defaultValue);
export default Data;