
import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './employeeMealAppAllowances.phrases';

/**
 * @function fetchEmployeeMealAppAllowances
 * @description Fetch all employee meal app allowances
 * @param {object} state
 */
export function fetchEmployeeMealAppAllowances(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/hr/employee_meal_app_allowances', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addEmployeeMealAppAllowance
 * @description add employee meal app allowances
 */
export function addEmployeeMealAppAllowance(data) {
	return post('/hr/employee_meal_app_allowances', data)
		.then((res) => {
			setFeedback(phrases.EMPLOYEE_MEAL_APP_ALLOWANCE_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editEmployeeMealAppAllowance
 * @description edit employee meal app allowance
 */
export function editEmployeeMealAppAllowance(data) {
	return post(`/hr/employee_meal_app_allowances/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.EMPLOYEE_MEAL_APP_ALLOWANCE_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function editEmployeeMealAppAllowances
 * @description edit employee meal app allowances
 */
export function editEmployeeMealAppAllowances(data) {
	return patch('/hr/employee_meal_app_allowances', data)
		.then((res) => {
			setFeedback(phrases.EMPLOYEE_MEAL_APP_ALLOWANCES_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function deleteEmployeeMealAppAllowance
 * @description delete employee meal app allowance
 */
export function deleteEmployeeMealAppAllowance(id) {
	return remove(`/hr/employee_meal_app_allowances/${id}`)
		.then((res) => {
			setFeedback(phrases.EMPLOYEE_MEAL_APP_ALLOWANCE_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
