import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// redux
import { useDispatch, batch } from 'react-redux';
// actions
import { setRowInteracted } from './../../../../../store/shiftClockins.actions';
import { editEntry } from 'reactDataWrapper/reactDataWrapper.actions';

// components
import { Tooltip } from 'dumb';

// constants/ phrases
import constants from 'services/constants';
import phrases from './../../../shiftClockinsTable.phrases';

// moment
import moment from 'moment';

const ManagedClockinsTimeCell = ({
	rowId,
	value,
	textColor = null,
	update,
	row,
	reduxKey,
}) => {
	const updateRowEntry = ({ value }) => {
		const updatedObject = update(row, value.date);

		dispatch(editEntry({ reduxKey, entry: updatedObject }));
	};

	const getIfPersonDidntClockOut = () => {
		const date = moment
			.utc(value, constants.dateFormat)
			.format(constants.shortDate);

		return date === '9999-12-31';
	};

	const handleOnClick = () => {
		if (showInfinity) return;

		const payload = {
			date: value,
		};

		batch(() => {
			dispatch(setRowInteracted(rowId));
			updateRowEntry({ value: payload });
		});
	};

	// eslint-disable-next-line react/prop-types
	const getDateSpan = ({ ref, onMouseEnter, onMouseLeave }) => {
		let showValue;
		if (date) showValue = date;
		if (!date) showValue = '--:--';
		if (showInfinity) showValue = '∞';

		return (
			<span
				className={classNames(
					'shift-clockins__table-wrapper__cell-wrapper__cell',
					{
						'shift-clockins__table-wrapper__cell-wrapper__cell--no-click':
							showInfinity,
					}
				)}
				onClick={handleOnClick}
				{...(!!textColor && {
					style: {
						color: `rgb(${textColor})`,
					},
				})}
				{...(ref &&
					onMouseEnter &&
					onMouseLeave && {
						ref,
						onMouseEnter,
						onMouseLeave,
					})}
			>
				{showValue}
			</span>
		);
	};

	const date = value
		? moment.utc(value, constants.dateFormat).format(constants.timeShort)
		: null;
	const showInfinity = getIfPersonDidntClockOut();
	const dispatch = useDispatch();

	return date ? (
		<Tooltip
			text={phrases.NO_CLOCK_OUT_TOOLTIP}
			visible={showInfinity}
			renderData={(ref, onMouseEnter, onMouseLeave) =>
				getDateSpan({ ref, onMouseEnter, onMouseLeave })
			}
		/>
	) : (
		getDateSpan({})
	);
};

ManagedClockinsTimeCell.propTypes = {
	value: PropTypes.string,
	textColor: PropTypes.string,
	rowId: PropTypes.string,
	update: PropTypes.func,
	row: PropTypes.object,
	reduxKey: PropTypes.string,
};

export default ManagedClockinsTimeCell;
