import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './headerCell.css';

const HeaderCell = React.memo(
	({
		content,
		colSpan,
		separate,
		alignLeft,
		alignRight,
		tooltip,
		width,
		style
	}) => {
		const classNames = classnames('report-head-td', {
			'report-head-td--separate': separate,
			'report-head-td--align-left': alignLeft,
			'report-head-td--align-right': alignRight
		});

		return (
			<th
				style={{ ...style }}
				colSpan={colSpan}
				className={classNames}
				title={tooltip || content}>
				<span style={{ width }}>{content}</span>
			</th>
		);
	}
);

HeaderCell.defaultProps = {
	modifierClassNames: [],
	content: '',
	colSpan: null,
	width: null,
	style: {},
	separate: false,
	alignLeft: false,
	alignRight: false
};

HeaderCell.propTypes = {
	content: PropTypes.string.isRequired,
	tooltip: PropTypes.string,
	colSpan: PropTypes.integer,
	width: PropTypes.integer,
	style: PropTypes.object,
	separate: PropTypes.bool,
	alignLeft: PropTypes.bool,
	alignRight: PropTypes.bool
};

export default HeaderCell;
