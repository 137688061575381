import {
	UPDATE_SALARY_GROUPS,
	SET_SALARY_GROUPS,
	RESET_SALARY_GROUPS,
	UPDATE_BATCH,
	RESET_BATCH,
	BATCH_LIST_ADD,
	BATCH_LIST_EDIT_ITEM,
	BATCH_LIST_REMOVE_ITEM,
} from './salaryGroups.actions';

const defaultState = {
	data: {
		salaryGroup: {},
		initialSalaryGroup: {},
		batchOptions: {
			name: '',
			moneyball_sub_position: [],
			market: [],
			active: {},
		},
		batchList: [],
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_SALARY_GROUPS: {
			return {
				...state,
				data: {
					...state.data,
					salaryGroup: payload,
					initialSalaryGroup: payload,
				},
			};
		}

		case UPDATE_SALARY_GROUPS: {
			return {
				...state,
				data: {
					...state.data,
					salaryGroup: {
						...state.data.salaryGroup,
						...payload,
					},
				},
			};
		}

		case RESET_SALARY_GROUPS: {
			return {
				...state,
				data: {
					...state.data,
					salaryGroup: {},
				},
			};
		}

		case UPDATE_BATCH: {
			return {
				...state,
				data: {
					...state.data,
					batchOptions: {
						...state.data.batchOptions,
						...payload,
					},
				},
			};
		}

		case BATCH_LIST_ADD: {
			return {
				...state,
				data: {
					...state.data,
					batchList: payload,
				},
			};
		}

		case RESET_BATCH: {
			return {
				...state,
				data: {
					...state.data,
					batchOptions: defaultState.data.batchOptions,
					batchList: defaultState.data.batchList,
				},
			};
		}

		case BATCH_LIST_EDIT_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchList: state.data.batchList.map((entry) => {
						if (payload.identifier === entry.identifier)
							return {
								...entry,
								...payload,
							};

						return entry;
					}),
				},
			};
		}

		case BATCH_LIST_REMOVE_ITEM: {
			const batchList = state.data.batchList.filter(
				(x) => x.identifier !== payload
			);

			return {
				...state,
				data: {
					...state.data,
					batchList,
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
