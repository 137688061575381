'use strict';

import CustomComponent from './customComponent.customComponent';

class CustomComponentFactory {
	/**
	 * @function customComponent
	 * @memberOf CustomComponentFactory
	 * @param  {string} title
	 * @param  {object} component
	 */
	customComponent(title, component) {
		return new CustomComponent(title, component);
	}

}

export default CustomComponentFactory;