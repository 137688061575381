export const SET_SHADOW_EMPLOYEES = 'SHADOW_EMPLOYEE/SET_SHADOW_EMPLOYEES';
export const SET_SHADOW_EMPLOYEE = 'SHADOW_EMPLOYEE/SET_SHADOW_EMPLOYEE';
export const ADD_SHADOW_EMPLOYEE = 'SHADOW_EMPLOYEE/ADD_SHADOW_EMPLOYEE';
export const EDIT_SHADOW_EMPLOYEES = 'SHADOW_EMPLOYEE/EDIT_SHADOW_EMPLOYEES';
export const EDIT_SHADOW_EMPLOYEE = 'SHADOW_EMPLOYEE/EDIT_SHADOW_EMPLOYEE';
export const REMOVE_SHADOW_EMPLOYEE = 'SHADOW_EMPLOYEE/REMOVE_SHADOW_EMPLOYEE';
export const RESET_ALL = 'SHADOW_EMPLOYEE/RESET_ALL';
export const RESET_SHADOW_EMPLOYEE = 'SHADOW_EMPLOYEE/RESET_SHADOW_EMPLOYEE';
export const TOGGLE_ADD_SHADOW_SHIFT_EMPLOYEE_MODAL =
	'SHADOW_EMPLOYEE/TOGGLE_ADD_SHADOW_SHIFT_EMPLOYEE_MODAL';
export const TOGGLE_EDIT_SHADOW_EMPLOYEE_MODAL =
	'SHADOW_EMPLOYEE/TOGGLE_EDIT_SHADOW_EMPLOYEE_MODAL';
export const SET_SHADOW_EMPLOYEE_MODAL_LOADING =
	'SHADOW_EMPLOYEE/SET_SHADOW_EMPLOYEE_MODAL_LOADING';

export function setShadowEmployees(payload) {
	return {
		type: SET_SHADOW_EMPLOYEES,
		payload
	};
}

export function setShadowEmployee(payload) {
	return {
		type: SET_SHADOW_EMPLOYEE,
		payload
	};
}

export function editShadowEmployees(payload) {
	return {
		type: EDIT_SHADOW_EMPLOYEES,
		payload
	};
}

export function editShadowEmployee(payload) {
	return {
		type: EDIT_SHADOW_EMPLOYEE,
		payload
	};
}

export function addShadowEmployee(payload) {
	return {
		type: ADD_SHADOW_EMPLOYEE,
		payload
	};
}

export function removeShadowEmployee(payload) {
	return {
		type: REMOVE_SHADOW_EMPLOYEE,
		payload
	};
}

export function resetAll() {
	return {
		type: RESET_ALL
	};
}

export function resetShadowEmployee() {
	return {
		type: RESET_SHADOW_EMPLOYEE
	};
}

export function toggleAddEmployeeModal() {
	return {
		type: TOGGLE_ADD_SHADOW_SHIFT_EMPLOYEE_MODAL
	};
}

export function toggleEditShadowEmployeeModal() {
	return {
		type: TOGGLE_EDIT_SHADOW_EMPLOYEE_MODAL
	};
}

export function setShadowEmployeeModalLoading(payload) {
	return {
		type: SET_SHADOW_EMPLOYEE_MODAL_LOADING,
		payload
	};
}
