'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Button, Icon } from 'dumb';
import cx from 'classnames';

// lodash
import _isEmpty from 'lodash/isEmpty';

const tileSource = {
	beginDrag(props) {
		// output is method that formats the output based on passed data
		return props.output(props.data);
	},
};

function collect(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		connectDragPreview: connect.dragPreview(),
		isDragging: monitor.isDragging(),
	};
}

const tileType = {
	VARIANT: 'Product-variant',
};

class DraggableRow extends Component {
	constructor(props) {
		super(props);

		this.state = {
			rowHovered: false,
		};

		this.onRowHover = this.onRowHover.bind(this);
		this.onRowLeave = this.onRowLeave.bind(this);
	}

	componentDidMount() {
		// Use empty image as a drag preview so browsers don't draw it
		// and we can draw whatever we want on the custom drag layer instead.
		this.props.isDragLayer &&
			this.props.connectDragPreview(getEmptyImage(), {
				// IE fallback: specify that we'd rather screenshot the node
				// when it already knows it's being dragged so we can hide it with CSS.
				captureDraggingState: true,
			});
	}

	_onClickWrapper(data) {
		const { onClick } = this.props;

		// if on click function passed
		if (onClick) onClick(data);
	}

	onRowHover() {
		const { isRowHovered, data } = this.props;

		this.setState(() => ({ rowHovered: true }));

		// send row value if it exists
		const value = !_isEmpty(data) ? data[0].value : null;

		isRowHovered(true, value);
	}

	onRowLeave() {
		const { isRowHovered } = this.props;

		this.setState(() => ({ rowHovered: false }));

		isRowHovered(false);
	}

	render() {
		const {
			isDragging,
			connectDragSource,
			data,
			index,
			activeRows,
			onClick,
		} = this.props;
		const { rowHovered } = this.state;

		return connectDragSource(
			<tr
				onMouseEnter={this.onRowHover}
				onMouseLeave={this.onRowLeave}
				key={index}
				className={activeRows.includes(index) ? 'row--active' : ''}
				style={{ opacity: isDragging ? 0.5 : 1 }}>
				{data.map((cellData, index) => {
					return index === data.length - 1 ? (
						<td
							className={cx(null, {
								'smart-table__body-wrapper__cell--clickable': !!onClick,
							})}
							key={cellData.value + index}>
							{cellData.value}
							{onClick && rowHovered && (
								<Button
									className="smart-table__body-wrapper__cell--clickable__button"
									onClick={() => this._onClickWrapper(data[0].data)}>
									<Icon name="add" />
								</Button>
							)}
						</td>
					) : (
						<td key={cellData.value + index}>{cellData.value}</td>
					);
				})}
			</tr>
		);
	}
}

DraggableRow.propTypes = {
	data: PropTypes.array,
	index: PropTypes.number,
	activeRows: PropTypes.array,
	connectDragPreview: PropTypes.func,
	connectDragSource: PropTypes.func,
	isDragging: PropTypes.bool,
	isDragLayer: PropTypes.bool,
	onClick: PropTypes.func,
	isRowHovered: PropTypes.func,
};

DraggableRow.defaultProps = {
	data: [],
	isDragLayer: true,
	output: (e) => ({ id: e.id, name: e.value }),
	isRowHovered: () => {},
};

export default DragSource(tileType.VARIANT, tileSource, collect)(DraggableRow);
