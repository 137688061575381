'use strict';

export default {
	CONTINUE: 'Continue',
	BACK: 'Back',
	CREATE: 'Create',
	UPDATE: 'Update',
	FOLDER: 'Folder',
	PRODUCT: 'Product',
	PLEASE_SELECT_YOUR_DESTINY: 'Please select your destiny:'
};
