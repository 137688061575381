'use strict';

import ReactDOM from 'react-dom';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Manager, Reference, Popper } from 'react-popper';
import './tooltip.css';

const Tooltip = ({ renderData, text, visible, placement, zIndex }) => {
	const [isShown, setIsShown] = useState(false);

	const onMouseEnter = () => {
		if (visible) setIsShown(true);
	};

	const onMouseLeave = () => {
		setIsShown(false);
	};

	return (
		<Manager>
			<Reference>
				{({ ref }) => renderData(ref, onMouseEnter, onMouseLeave)}
			</Reference>

			{isShown &&
				ReactDOM.createPortal(
					<Popper placement={placement}>
						{({ placement, ref, style }) => {
							return (
								<div
									className={cx('j-tooltip', {
										[`j-tooltip--${placement}`]: placement,
									})}
									ref={ref}
									style={{ ...style, zIndex }}
									data-placement={placement}>
									{text}
								</div>
							);
						}}
					</Popper>,
					document.querySelector('#popper')
				)}
		</Manager>
	);
};

Tooltip.defaultProps = {
	visible: true,
	placement: 'bottom',
};

Tooltip.propTypes = {
	renderData: PropTypes.func,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	visible: PropTypes.bool,
	placement: PropTypes.string,
	zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Tooltip;
