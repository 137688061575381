'use strict';

import {
	RESET_STATE,
	UPDATE_BATCH_FORM,
	ADD_BATCH_LIST,
	REMOVE_BATCH_LIST_ITEM,
	SET_INGREDIENT_TRANSLATION,
	UPDATE_INGREDIENT_TRANSLATION,
	EDIT_BATCH_LIST_ITEM,
} from './ingredientTranslations.actions';

const defaultState = {
	data: {
		ingredientTranslation: {},
		originalIngredientTranslation: {},
		batchForm: {},
		batchList: [],
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_INGREDIENT_TRANSLATION:
			return {
				...state,
				data: {
					...state.data,
					ingredientTranslation: payload,
					originalIngredientTranslation: payload,
				},
			};

		case UPDATE_INGREDIENT_TRANSLATION: {
			return {
				...state,
				data: {
					...state.data,
					ingredientTranslation: {
						...state.data.ingredientTranslation,
						...payload,
					},
				},
			};
		}
		// batch
		case UPDATE_BATCH_FORM: {
			return {
				...state,
				data: {
					...state.data,
					batchForm: {
						...state.data.batchForm,
						...payload,
					},
				},
			};
		}

		case ADD_BATCH_LIST: {
			return {
				...state,
				data: {
					...state.data,
					batchList: payload,
				},
			};
		}

		case EDIT_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchList: state.data.batchList.map((entry) => {
						if (entry.identifier === payload.identifier)
							return {
								...entry,
								...payload,
							};

						return entry;
					}),
				},
			};
		}

		case REMOVE_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchList: state.data.batchList.filter(
						(entry) => entry.identifier !== payload
					),
				},
			};
		}

		case RESET_STATE:
			return defaultState;

		default:
			return state;
	}
}

export default reducer;
