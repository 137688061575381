import React, { Component } from 'react';
import PropTypes from 'prop-types';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import { toggleModal, setData } from './store/confirmModal.actions';

import _get from 'lodash/get';

// Components
import { Modal } from 'dumb';

import phrases from '../modal.phrases';

class ConfirmModal extends Component {
	constructor(props) {
		super(props);

		this._onClose = this._onClose.bind(this);
		this._onConfirm = this._onConfirm.bind(this);
		this._handleUnmount = this._handleUnmount.bind(this);
	}

	_handleUnmount() {
		const { setData, toggleModal } = this.props;

		setData(null);
		toggleModal();
	}

	_onClose() {
		const { data } = this.props;
		const { onCancel, callback } = data;

		// Clear store
		if (onCancel) onCancel();
		this._handleUnmount();
		if (callback) callback();
	}

	_onConfirm() {
		const { data } = this.props;
		const { onConfirm, callback } = data;

		// Send data!
		if (onConfirm) onConfirm();
		this._handleUnmount();
		if (callback) callback();
	}

	render() {
		// data now comes from redux, not directly passed
		const { data, isOpen } = this.props;

		const content = _get(data, 'data', {});
		const { header, confirmLabel, cancelLabel, messages, subheader } = content;

		return isOpen ? (
			<Modal
				closeConfirm={false}
				disableKeyBindings={false}
				header={header || phrases.HEADER}
				defaultStyles
				isOpen={isOpen}
				loading={false}
				type="confirmation"
				onClose={this._onClose}
				onCancelClick={this._onClose}
				onConfirmClick={this._onConfirm}
				confirmButtonLabel={confirmLabel || phrases.CONFIRM}
				cancelButtonLabel={cancelLabel || phrases.CANCEL}>
				<p>{subheader || phrases.SUBHEADER}</p>
				{messages ? (
					<ul className="add-shift__messages">
						{messages.map((x, i) => {
							return <li key={i}>{x}</li>;
						})}
					</ul>
				) : null}
			</Modal>
		) : null;
	}
}

ConfirmModal.propTypes = {
	// Store props
	data: PropTypes.shape({
		onConfirm: PropTypes.func,
		callback: PropTypes.func,
		onCancel: PropTypes.func,
		data: PropTypes.shape({
			header: PropTypes.string,
			confirmLabel: PropTypes.string,
			cancelLabel: PropTypes.string,
			messages: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
			subheader: PropTypes.string,
		}),
	}),
	setData: PropTypes.func,
	toggleModal: PropTypes.func,
	isOpen: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setData,
			toggleModal,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		data: store.confirmModalReducer.data,
		isOpen: store.confirmModalReducer.isOpen,
	};
};

export default connectWithStore(
	ConfirmModal,
	mapStateToProps,
	mapDispatchToProps
);
