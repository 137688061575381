'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setWaitingTimeScheduleEntry,
	updateWaitingTimeScheduleEntry,
	resetWaitingTimeScheduleEntry,
} from './store/waitingTimeScheduleEntries.actions';

// services
import {
	fetchWaitingTimeScheduleEntries,
	editWaitingTimeScheduleEntry,
	editWaitingTimeScheduleEntries,
	addWaitingTimeScheduleEntry,
	deleteWaitingTimeScheduleEntry,
} from './waitingTimeScheduleEntries.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Input, Button, Icon } from 'dumb';

import WaitingTimeScheduleEntriesModalBatch from './components/batch/waitingTimeScheduleEntriesModalBatch';

// phrases
import phrases from './waitingTimeScheduleEntries.phrases';

const reduxKey = '/app/waiting_time_schedule_entries';

class WaitingTimeScheduleEntries extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.addWaitingTimeScheduleEntryWrapper = this.addWaitingTimeScheduleEntryWrapper.bind(
			this
		);
		this.fetchData = this.fetchData.bind(this);
		this.editEntry = this.editEntry.bind(this);

		this.columns = [
			{
				Header: 'Products',
				id: 'products',
				accessor: 'products',
				filterPath: ':products',
				filterable: false,
			},
			{
				Header: 'Minutes',
				id: 'minutes',
				accessor: 'minutes',
				filterPath: ':minutes',
				filterable: false,
			},
		];
	}

	editEntry() {
		const { defaultWaitingTimeScheduleEntry } = this.props;

		const payload = {
			id: defaultWaitingTimeScheduleEntry.id,
			products: defaultWaitingTimeScheduleEntry.products,
			minutes: defaultWaitingTimeScheduleEntry.minutes,
		};

		return editWaitingTimeScheduleEntry(payload);
	}

	editMultiple(selectedRows) {
		const { defaultWaitingTimeScheduleEntry } = this.props;

		const payload = {
			...(defaultWaitingTimeScheduleEntry.products && {
				products: defaultWaitingTimeScheduleEntry.products,
			}),
			...(defaultWaitingTimeScheduleEntry.minutes && {
				minutes: defaultWaitingTimeScheduleEntry.minutes,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editWaitingTimeScheduleEntries({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteWaitingTimeScheduleEntry(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			products: data.products,
			minutes: data.minutes,
			singleEdit: true,
		};

		this.props.setWaitingTimeScheduleEntry(payload);
	}

	getEditableCells() {
		const { defaultWaitingTimeScheduleEntry } = this.props;

		return [
			...(defaultWaitingTimeScheduleEntry.singleEdit
				? [
						{
							header: 'Products',
							value: (
								<Input
									id="products"
									type="number"
									placeholder="Enter no. of products..."
									value={defaultWaitingTimeScheduleEntry.products || ''}
									onChange={(event) => this.editStoreEntry('products', event)}
								/>
							),
						},
				  ]
				: []),
			{
				header: 'Minutes',
				value: (
					<Input
						id="minutes"
						type="number"
						placeholder="Enter no. of minutes..."
						value={defaultWaitingTimeScheduleEntry.minutes || ''}
						onChange={(event) => this.editStoreEntry('minutes', event)}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const value = e.target.value;

		const updateObject = {
			[name]: value,
		};

		this.props.updateWaitingTimeScheduleEntry(updateObject);
	}

	fetchData(state) {
		const { waitingTimeSchedule } = this.props;

		const filter = `:waiting_time_schedule.id=='${waitingTimeSchedule.id}'`;

		return fetchWaitingTimeScheduleEntries(state, filter);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetWaitingTimeScheduleEntry();
	}

	addWaitingTimeScheduleEntryWrapper(data) {
		return addWaitingTimeScheduleEntry(data).then(() => {
			this.setState(() => ({
				key: moment.utc(),
			}));
		});
	}

	render() {
		const {
			batchList,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
			waitingTimeSchedule,
		} = this.props;

		return (
			<>
				<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={`${reduxKey}-${waitingTimeSchedule.id}`}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetWaitingTimeScheduleEntry}
					actionRender={
						<Button
							type="inverted"
							label="Batch"
							shadow
							onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
				/>

				<WaitingTimeScheduleEntriesModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addWaitingTimeScheduleEntry={this.addWaitingTimeScheduleEntryWrapper}
					waitingTimeSchedule={waitingTimeSchedule}
				/>
			</>
		);
	}
}

WaitingTimeScheduleEntries.propTypes = {
	defaultWaitingTimeScheduleEntry: PropTypes.object,

	updateWaitingTimeScheduleEntry: PropTypes.func,
	setWaitingTimeScheduleEntry: PropTypes.func,
	resetWaitingTimeScheduleEntry: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	waitingTimeSchedule: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			editBatchListItem,
			removeBatchListItem,
			updateWaitingTimeScheduleEntry,
			setWaitingTimeScheduleEntry,
			resetWaitingTimeScheduleEntry,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultWaitingTimeScheduleEntry:
			store.waitingTimeScheduleEntries.data.defaultWaitingTimeScheduleEntry,
		batchList: store.waitingTimeScheduleEntries.data.batchList,
	};
};

export default connectWithStore(
	WaitingTimeScheduleEntries,
	mapStateToProps,
	mapDispatchToProps
);
