export const SET_RAW_INGREDIENT_CATEGORIES_MODAL =
	'GLOBAL_ADMINISTRATION_RAW_INGREDIENT_CATEGORIES/SET_WAITING_TIME_GRIDS';
export const UPDATE_RAW_INGREDIENT_CATEGORIES =
	'GLOBAL_ADMINISTRATION_RAW_INGREDIENT_CATEGORIES/UPDATE_RAW_INGREDIENT_CATEGORIES';
export const RESET_STATE =
	'GLOBAL_ADMINISTRATION_RAW_INGREDIENT_CATEGORIES/RESET_STATE';

export const ADD_BATCH_LIST =
	'GLOBAL_ADMINISTRATION_RAW_INGREDIENT_CATEGORIES/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_RAW_INGREDIENT_CATEGORIES/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_RAW_INGREDIENT_CATEGORIES/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'GLOBAL_ADMINISTRATION_RAW_INGREDIENT_CATEGORIES/ADD_BATCH_LIST_ITEM_ROW';

export function setRawIngredientCategoriesModal(payload) {
	return {
		type: SET_RAW_INGREDIENT_CATEGORIES_MODAL,
		payload
	};
}
export function updateRawIngredientCategories(payload) {
	return {
		type: UPDATE_RAW_INGREDIENT_CATEGORIES,
		payload
	};
}

export function resetState() {
	return {
		type: RESET_STATE
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW
	};
}
