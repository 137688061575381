'use strict';

/**
 * @function initHeaderHash
 * @memberOf SERVICES.__models
 * @description
 * Sets unique hash on headers for further reference
 * @param  {array} headers  headers to add hash to
 */

import _each from 'lodash/each';

// Services
var __headerHash = require('headerHash.service');


module.exports = function(headers) {
	_each(headers, function(header) {
		header.hash = __headerHash(header);
	});
	this.setHeaders(headers);
};