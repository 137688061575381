'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Default extends Component {
	constructor(props) {
		super(props);

		this.focus = this.focus.bind(this);
	}

	focus() {
		this.defaultInput.focus();
	}

	render() {
		const {
			defaultValue,
			disabled,
			forceText,
			header,
			onChange,
			onKeyDown
		} = this.props;

		if (!forceText && header.type === 'longString') {
			return (
				<textarea
					ref={input => {
						this.defaultInput = input;
					}}
					autoComplete="off"
					autoFocus={header.focus}
					onKeyDown={onKeyDown}
					onChange={onChange}
					name={header.name}
					placeholder={header.label}
					disabled={disabled}
					defaultValue={defaultValue}
				/>
			);
		} else {
			var inputType;
			switch (header.type) {
			case 'existingPassword':
				inputType = 'password';
				break;
			case 'mail':
				inputType = 'email';
				break;
			case 'number':
				inputType = 'text';
				break;
			case 'tel':
				inputType = 'tel';
				break;
			default:
				inputType = 'text';
				break;
			}
			return (
				<input
					ref={input => {
						this.defaultInput = input;
					}}
					type={inputType}
					autoComplete="off"
					autoFocus={header.focus}
					onKeyDown={onKeyDown}
					onChange={onChange}
					name={header.name}
					placeholder={header.label}
					disabled={disabled}
					defaultValue={defaultValue}
				/>
			);
		}
	}
}

Default.propTypes = {
	defaultValue: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
		PropTypes.array,
		PropTypes.number
	]),
	disabled: PropTypes.bool,
	forceText: PropTypes.bool,
	header: PropTypes.object,
	onChange: PropTypes.func,
	onKeyDown: PropTypes.func
};

export default Default;
