import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';

import TurnoverAchievementsList from './services/turnoverAchievementsList.service.js';

const turnoverAchievementsListReduxKey = '/reporting/turnover_achievements';
const turnoverAchievementsList = new TurnoverAchievementsList(turnoverAchievementsListReduxKey);
const turnoverAchievementsListProps = turnoverAchievementsList.getProps();

export function TurnoverAchievements() {
	useEffect(() => {
		turnoverAchievementsList.initView();
	}, []);

	return <ListComponent reduxKey={turnoverAchievementsListReduxKey} {...turnoverAchievementsListProps} />;
}
