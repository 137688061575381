'use strict';

import {
	RESET_PRODUCT_VARIANT_ADDON,
	UPDATE_PRODUCT_VARIANT_ADDON,
	SET_PRODUCT_VARIANT_ADDON,
} from './productVariantAddons.actions';

const defaultState = {
	data: {
		productVariantAddonData: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_PRODUCT_VARIANT_ADDON: {
			return {
				data: {
					productVariantAddonData: payload,
				},
			};
		}

		case UPDATE_PRODUCT_VARIANT_ADDON: {
			return {
				data: {
					productVariantAddonData: {
						...state.data.productVariantAddonData,
						...payload,
					},
				},
			};
		}

		case RESET_PRODUCT_VARIANT_ADDON: {
			return {
				data: {
					productVariantAddonData: defaultState.data.productVariantAddonData,
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
