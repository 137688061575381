import { get, post, remove, patch } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './addonIngredient.phrases';

/**
 * @function fetchAddonIngredients
 * @description Fetch addon ingredients (associations)
 * @param {number} addonId
 * @param {object} state
 */
export function fetchAddonIngredients(addonId, state) {
	const preFilter = `:addon.id=='${addonId}'`;

	const filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter
	};

	return get('/product/addon_ingredients', params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addAddonIngredient
 * @description adds addon ingredient
 */
export function addAddonIngredient(data) {
	return post('/product/addon_ingredients', data)
		.then(res => {
			setFeedback(phrases.ADDON_INGREDIENT_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editAddonIngredient
 * @description edits a single addon ingredient
 */
export function editAddonIngredient(ingredientId, data) {
	return post(`/product/addon_ingredients/${ingredientId}`, data)
		.then(res => {
			setFeedback(phrases.ADDON_INGREDIENT_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editBatchAddonIngredient
 * @description edit multiple addon ingredients
 */
export function editBatchAddonIngredient(data) {
	return patch('/product/addon_ingredients', data)
		.then(res => {
			setFeedback(phrases.ADDON_INGREDIENTS_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteAddonIngredient
 * @description deletes single/multiple addon ingredient/s
 */
export function deleteAddonIngredient(ingredientId) {
	return remove(`/product/addon_ingredients/${ingredientId}`)
		.then(res => {
			setFeedback(phrases.ADDON_INGREDIENT_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
