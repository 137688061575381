'use strict';

/**
 * @function initHeaderTranslations
 * @memberOf SERVICES.__models
 * @description
 * Translates headers based on {@link translations} and settings.translationPath
 * @param  {service} translations  {@link translations}
 * @param  {array} headers  headers to add translations to
 */

import _each from 'lodash/each';
import _has from 'lodash/has';

module.exports = function(translations, headers) {
	var translation;
	var settings = this.getSettings();
	_each(headers, function(header) {
		if (!header.label) {
			if (_has(header, 'reference') && (_has(header.reference, 'translationPath') || _has(header.reference, 'translationKey'))) {
				var key = _has(header.reference, 'translationKey') ? header.reference.translationKey : header.reference.key;
				var translationPath = _has(header.reference, 'translationPath') ? header.reference.translationPath : settings.translationPath;
				translation = 'HEADERS.' + translationPath + '.' + key.toUpperCase();
			}
			else if (_has(header, 'visibleName')) {
				translation = 'HEADERS.' + settings.translationPath + '.' + header.visibleName.toUpperCase();
			}
			else {
				translation = 'HEADERS.' + settings.translationPath + '.' + header.name.toUpperCase();
			}
			header.label = translations.getSingle(translation);
		}
	});
	this.setHeaders(headers);
};