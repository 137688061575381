'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { Typography } from '@mui/material';
import { TableList } from 'dumb';

// utils
import _isArray from 'lodash/isArray';
import _get from 'lodash/get';
import phrases from '../terminateEmployee.phrases';
import constants from 'services/constants';
import moment from 'moment';

class ShiftsTable extends Component {
	render() {
		const { assignedShifts, employment, selectedDate } = this.props;

		const employmentId = (employment && employment.value) || null;

		const affectedShifts = assignedShifts.filter((x) => {
			return employmentId === _get(x, 'shift_employees[0].employment.id', -1);
		});

		const showAssignedShifts = _isArray(assignedShifts) && assignedShifts?.length > 0;

		return (
			<div className="terminate-modal__table">
				{showAssignedShifts ? (
					<>
						<Typography variant="body1">
							Currently, {affectedShifts.length} will be affected by the termination.
						</Typography>
						<TableList>
							<thead>
								<tr>
									<th>{phrases.WORKPLACE}</th>
									<th>{phrases.DATE}</th>
									<th>{phrases.TIME}</th>
								</tr>
							</thead>
							<tbody>
								{(showAssignedShifts ? assignedShifts : []).map((shift) => {
									// Compare group id
									// Compare to selected date
									const shiftDate = _get(shift, 'work_day.date', null);

									const rowActive =
										employmentId === _get(shift, 'shift_employees[0].employment.id', -1) &&
										selectedDate.utc().isSameOrBefore(moment.utc(shiftDate, constants.shortDate), 'day');

									const className = rowActive ? 'terminate-modal__table__row-active' : '';

									return (
										<tr key={shift.id} className={className}>
											<td>{_get(shift, 'workplace.name', 'n/a')}</td>

											<td>{moment(_get(shift, 'planned_period.from', '')).format('YYYY-MM-DD')}</td>
											<td>
												{moment(shift.planned_period.from).format('HH:MM A')} -{' '}
												{moment(shift.planned_period.to).format('HH:MM A')}
											</td>
										</tr>
									);
								})}
							</tbody>
						</TableList>
					</>
				) : (
					phrases.NO_ASSIGNED_SHIFTS
				)}
			</div>
		);
	}
}

ShiftsTable.defaultProps = {
	assignedShifts: [],
};

ShiftsTable.propTypes = {
	assignedShifts: PropTypes.array,
	employment: PropTypes.object,
	selectedDate: PropTypes.object,
};

export default ShiftsTable;
