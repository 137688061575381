'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'modal/modal.component';
import cx from 'classnames';

// Components
// import ButtonLoader from 'buttonLoader';
import { ButtonLoader } from 'dumb';
import CheckboxInput from 'reusableComponents/input/checkboxInput.js';
import InputText from 'reusableComponents/input/text.component';
import TextArea from 'reusableComponents/input/textArea/textArea.component';

// Services
import { set as setFeedback } from 'feedback.vanilla.service.js';
import regex from 'regex.service';

// local service
import { sendMail } from './mailTo.service';

// lodash
import _isArray from 'lodash/isArray';
import _get from 'lodash/get';

import phrases from './mailTo.phrases';

// css
import './mailTo.css';

class MailToComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedEmails: props.emails,
			allToggled: false,
			loading: false,
			emailData: {
				subject: props.subject,
				content: props.content,
				bcc: [],
				cc: [],
				recipients: [],
				extraRecipients: [],
				files: {}
			}
		};

		this._toggleAllEmails = this._toggleAllEmails.bind(this);
		this._toggleAddedEmails = this._toggleAddedEmails.bind(this);
		this._submitEmails = this._submitEmails.bind(this);
	}

	componentDidMount() {
		this._onChange('bcc', this.state.selectedEmails);
	}

	_toggleAllEmails() {
		const selectedEmails = this.state.allToggled ? this.props.emails : [];
		this.setState(prevState => ({
			selectedEmails,
			allToggled: !prevState.allToggled
		}));
	}

	_toggleAddedEmails(handler, event) {
		const value = event.target ? event.target.value : event;
		let selectedEmails;

		if (this.state.selectedEmails.includes(value)) {
			selectedEmails = this.state.selectedEmails.filter(
				email => email !== value
			);
		} else {
			selectedEmails = [...this.state.selectedEmails, value];
		}
		this.setState(() => ({ selectedEmails }));
	}

	_onChange(handler = null, event = null) {
		if (handler === 'bcc' || handler === 'extraRecipients') {
			!_isArray(event) ? (event = event.target.value.split(',')) : null;
		} else {
			event = _get(event, 'target.value', event);
		}

		this.setState(prevState => ({
			emailData: {
				...prevState.emailData,
				[handler]: event
			}
		}));
	}

	/**
	 * @function _submitEmails
	 * @description Formats emailData object and submits to passed function
	 * @private
	 * @memberof MailToComponent
	 */
	_submitEmails() {
		let invalidEmails = [];

		// Concatenate all emails in bcc and extreRecepients and test if they are valid email addresses
		const emails = [
			...this.state.selectedEmails,
			...this.state.emailData.extraRecipients
		].filter(mail => {
			mail = mail.trim();

			// filter and return mail if it is a valid expression. If not, push to array of invalid emails
			const valid = regex.email.expression.test(mail);
			if (valid) {
				return mail;
			} else {
				invalidEmails.push(mail);
			}
		});

		// Destructure to omit the extraRecipients
		const { extraRecipients, ...emailData } = this.state.emailData;

		// Construct email data to be submitted
		const data = {
			...emailData,
			bcc: emails,
			files: this.props.files
		};

		this.setState(() => ({
			loading: true
		}));

		sendMail(data)
			.then(() => {
				// Show emails if any are invalid
				invalidEmails.length > 0 &&
					setFeedback(
						`Could not send to following emails : ${invalidEmails.toString()}`,
						0
					);

				this.setState(() => ({
					success: true,
					error: false,
					loading: false
				}));

				setTimeout(() => {
					this.setState(() => ({ error: null, success: null }));
					this.props.handleClose();
				}, 5000);
			})
			.catch(() => {
				this.setState(() => ({
					success: false,
					error: true,
					loading: false
				}));
			});
	}

	render() {
		const {
			emailFormValid,
			filesLoaded,
			files,
			emails,
			subject,
			content
		} = this.props;

		const { error, success, loading, selectedEmails } = this.state;

		let mailToClassnames = cx('mail-to');

		let mailToButtonClassnames = cx('mail-to__button button button--primary', {
			'mail-to__button--error': error
		});

		let buttonText = phrases.BUTTON_TEXT;
		if (error) {
			buttonText = phrases.BUTTON_TEXT_ERROR;
		} else if (success) {
			buttonText = phrases.BUTTON_TEXT_SUCCESS;
		}

		const fileNames = files ? Object.keys(files).map(key => key) : [];

		return (
			<div className={mailToClassnames}>
				<fieldset className="mail-to__field mail-to__field--emails">
					<div className="mail-to__field__emails-header">
						<label className="mail-to__input">{phrases.EMAILS}</label>
						<button
							className="button button--primary"
							onClick={this._toggleAllEmails}>
							{phrases.TOGGLE_ALL}
						</button>
					</div>
					<div className="mail-to__field__emails-container">
						{emails.map((mail, index) => {
							// Find parent Index
							const checked = selectedEmails.includes(mail);
							return (
								<CheckboxInput
									key={index}
									handleChange={e => this._toggleAddedEmails('bcc', e)}
									checked={checked}
									value={mail}
									text={mail}
								/>
							);
						})}
					</div>
				</fieldset>

				<fieldset className="mail-to__field">
					<label className="mail-to__input">{phrases.EXTRA_EMAILS}</label>
					<span className="data-input data-input__input">
						<InputText
							type="text"
							focus
							onChange={e => this._onChange('extraRecipients', e)}
							placeholder="aabb@joejuice.com, ..."
						/>
					</span>
				</fieldset>

				<fieldset className="mail-to__field">
					<label className="mail-to__input">{phrases.SUBJECT}</label>
					<span className="data-input data-input__input">
						<InputText
							type="text"
							focus
							onChange={e => this._onChange('subject', e)}
							placeholder="Subject"
							defaultValue={subject}
						/>
					</span>
				</fieldset>

				<fieldset className="mail-to__field">
					<label className="mail-to__input">{phrases.CONTENT}</label>
					<span className="data-input data-input__input">
						<TextArea
							onChange={e => this._onChange('content', e)}
							resize={false}
							placeholder="Content"
							defaultValue={content}
						/>
					</span>
				</fieldset>

				<fieldset className="mail-to__field mail-to__field--attachments">
					<label className="mail-to__input">{phrases.ATTACHMENTS}</label>

					{filesLoaded ? (
						fileNames.map((fileName, index) => (
							<span key={index} className="icon icon--attachment">
								{fileName}
							</span>
						))
					) : (
						<ButtonLoader theme={'dark'} loading />
					)}
				</fieldset>

				<button
					disabled={!emailFormValid || loading}
					onClick={this._submitEmails}
					className={mailToButtonClassnames}>
					{!loading && <span>{buttonText}</span>}

					<ButtonLoader theme={'dark'} loading={loading} />

					{error === true && <span className="icon icon--error" />}

					{success === true && <span className="icon icon--thumb_up" />}
				</button>
			</div>
		);
	}
}

MailToComponent.defaultProps = {
	filesLoaded: false,
	emails: [],
	error: null,
	success: null,
	emailFormValid: false,
	subject: null,
	content: null,
	submit: () => {
		console.info('Submit not passed');
	}
};

MailToComponent.propTypes = {
	filesLoaded: PropTypes.bool,
	emails: PropTypes.array,

	handleClose: PropTypes.func,
	files: PropTypes.object,
	subject: PropTypes.string,
	content: PropTypes.string,
	emailFormValid: PropTypes.bool
};

export default Modal(MailToComponent);
