'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');

export default class TrainingGradeLevelsList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'value',
				type: 'number',
				canAdd: true,
				canEdit: true,
				required: false,
			},
			{
				name: 'color',
				type: 'color',
				canAdd: true,
				canEdit: true,
				required: false,
			},
		];

		this.settings = {
			name: 'TrainingGradeScales',
			canAdd: true,
			canEdit: true,
			hasDetails: true,
			scrollWrapper: false,
			canDelete: true,
			translationPath: 'TRAINING.GRADE_LEVELS',
			canManipulate: () => validAccess(undefined, ['Global BI Configuration']),

			endpoint: __endpoints.collection.training.grade_levels,
		};
		this.init();
		return this;
	}
}
