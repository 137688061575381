import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DailyReportBaseModel from './dailyReportBaseModel.component';

// PureComponents

import { Body, Row } from 'dumb/report';

class DailyReportTotal extends PureComponent {
	_renderTotal() {
		const { reportData, hotbarData } = this.props;
		return reportData.map((entry, index) => {
			return (
				<Row key={entry.id} index={index} type={'table-total'}>
					<DailyReportBaseModel
						entry={entry}
						reportType={hotbarData.perspective}
					/>
				</Row>
			);
		});
	}

	render() {
		return <Body>{this._renderTotal()}</Body>;
	}
}

DailyReportTotal.propTypes = {
	reportData: PropTypes.array,
	hotbarData: PropTypes.object
};

DailyReportTotal.defaultProps = {
	reportData: []
};

export default DailyReportTotal;
