// interface
import { ActionType } from 'types/redux';
import {
	IMarketResponsibilityBatch,
	IMarketResponsibilityEdit,
	IMarketResponsibilityReduxState,
} from '../types';

// utils
import moment from 'moment';
import constants from 'services/constants';
import _uniqueId from 'lodash/uniqueId';

import {
	SET_MARKET_RESPONSIBILITY,
	UPDATE_MARKET_RESPONSIBILITY,
	RESET_MARKET_RESPONSIBILITY,

	// batch
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	ADD_BATCH_LIST_ITEM_ROW,
	RERENDER_TABLE,
} from './marketResponsibilities.actions';

const defaultState: IMarketResponsibilityReduxState = {
	tableKey: moment().unix(),
	batchList: [
		{
			identifier: _uniqueId('row-'),
			role: null,
			location: null,
			from: moment.utc().format(constants.shortDate),
			to: '9999-12-31',
		},
	],
	data: {
		defaultMarketResponsibility: null,
		marketResponsibility: {
			role: '',
			location: '',
			from: moment.utc().format(constants.shortDate),
			to: '9999-12-31',
		},
	},
};

function reducer(
	state = defaultState,
	action: ActionType
): IMarketResponsibilityReduxState {
	const { type, payload } = action;

	switch (type) {
	case SET_MARKET_RESPONSIBILITY: {
		const castedPayload = payload as IMarketResponsibilityEdit;

		return {
				...state,
				data: {
					marketResponsibility: castedPayload,
					defaultMarketResponsibility: castedPayload,
				},
			};
	}

	case UPDATE_MARKET_RESPONSIBILITY: {
		const castedPayload = payload as Partial<IMarketResponsibilityEdit>;

		return {
				...state,
				data: {
					...state.data,
					marketResponsibility: {
						...state.data.marketResponsibility,
						...castedPayload,
					},
				},
			};
	}

	case RESET_MARKET_RESPONSIBILITY: {
		return defaultState;
	}

	case RERENDER_TABLE:
		return {
				...state,
				tableKey: moment().unix(),
			};

	case EDIT_BATCH_LIST_ITEM: {
		const castedPayload = payload as Partial<IMarketResponsibilityBatch>;

		return {
				...state,
				batchList: state.batchList.map((entry) => {
					if (entry.identifier === castedPayload.identifier) {
						return { ...entry, ...castedPayload };
					}
					return entry;
				}),
			};
	}

	case REMOVE_BATCH_LIST_ITEM: {
		const castedPayload = payload as string;

		return {
				...state,
				batchList: state.batchList.filter(
					(entry) => entry.identifier !== castedPayload
				),
			};
	}

	case ADD_BATCH_LIST_ITEM_ROW:
		return {
				...state,
				batchList: [
					...state.batchList,
					{
						...defaultState.batchList[0],
						identifier: _uniqueId('row-'),
					},
				],
			};

	default:
		return state;
	}
}

export default reducer;
