export default function getSelectOptions(data, extraProps = {}) {
	if (!data || data?.length === 0) return [];

	const { value, label } = extraProps;

	return data.map((entry) => ({
		value: value ? entry[value] : entry.id,
		label: label ? entry[label] : entry.name,
	}));
}
