import React from 'react';

// redux
import store from 'appState/store';
import { ChildRouteType } from './components/salesConfigurationTabs/types';

// pages
import SalesConfigurationReadOnly from './components/salesConfigurationReadOnly/salesConfigurationReadOnly.container';
import ProductContainer from './components/product/product.container';
import NutritionContainer from './components/nutrition/nutrition.container';
import AssetContainer from './components/assets/assets.container';
import LayoutsContainer from './components/layout/layout.container';
import DiscountsContainer from './components/discounts/discounts.container';
import IngredientsContainer from './components/ingredients/ingredients.container';
import PricingContainer from './components/pricing/pricing.container';
import GlobalDiscounts from './components/globalDiscounts/globalDiscounts.container';
import PosWorkplaceRelation from './components/posWorkplaceRelation/posWorkplaceRelation.container';
import Pos from './components/pos/pos.container';
import OperationalExcellence from './components/operationalExcellence/operationalExcellence.container';

// Phrases
import phrases from './salesConfiguration.phrases';

export const childRoutes = (): ChildRouteType[] => {
	const data = store.getState();

	const haveSalesConfigurationAdminAccess =
		data.userData?.user?.areas?.includes('Sales Configuration');

	return haveSalesConfigurationAdminAccess
		? [
				{
					element: <ProductContainer />,
					path: 'product-configuration',
					title: 'Product Configuration',
					allowedAccessControlLevels: [
						'Organization Admin',
						'Organization View',
					],
					name: phrases.PRODUCT_CONFIGURATION,
				},
				{
					element: <IngredientsContainer />,
					path: 'ingredients',
					title: 'Ingredients',
					allowedAccessControlLevels: [
						'Organization Admin',
						'Organization View',
					],
					name: phrases.INGREDIENTS_CONFIGURATION,
				},
				{
					element: <NutritionContainer />,
					path: 'nutrition',
					title: 'Nutrition',
					allowedAccessControlLevels: [
						'Organization Admin',
						'Organization View',
					],
					name: phrases.NUTRITION,
				},
				{
					element: <DiscountsContainer />,
					path: 'discounts',
					title: 'Discounts',
					allowedAccessControlLevels: [
						'Organization Admin',
						'Organization View',
					],
					name: phrases.DISCOUNTS,
				},
				{
					element: <AssetContainer />,
					path: 'assets',
					title: 'Assets',
					allowedAccessControlLevels: [
						'Organization Admin',
						'Organization View',
					],
					name: phrases.ASSETS,
				},
				{
					element: <LayoutsContainer />,
					path: 'layouts',
					title: 'Layouts',
					allowedAccessControlLevels: [
						'Organization Admin',
						'Organization View',
					],
					name: phrases.LAYUOTS,
				},
				{
					element: <PricingContainer />,
					path: 'pricing',
					title: 'Pricing',
					allowedAccessControlLevels: [
						'Organization Admin',
						'Organization View',
					],
					name: phrases.PRICINGS,
				},
				{
					element: <GlobalDiscounts />,
					path: 'global-discounts',
					title: 'Global Discounts',
					allowedAccessControlLevels: [
						'Organization Admin',
						'Organization View',
					],
					name: phrases.GLOBAL_PRODUCT_DISCOUNTS,
				},
				{
					element: <PosWorkplaceRelation />,
					path: 'pos-workplace-relation',
					title: 'POS/Workplace Relation',
					allowedAccessControlLevels: [
						'Organization Admin',
						'Organization View',
					],
					name: phrases.POS_WORKPLACE_RELATION,
				},
				{
					element: <Pos />,
					path: 'pos',
					title: 'POS',
					allowedAccessControlLevels: [
						'Organization Admin',
						'Organization View',
					],
					name: phrases.POS,
				},
				{
					element: <OperationalExcellence />,
					path: 'operational-excellence',
					title: 'Operational Excellence',
					allowedAccessControlLevels: [
						'Organization Admin',
						'Organization View',
					],
					name: phrases.OPERATIONAL_EXCELLENCE,
				},
		  ]
		: [
				{
					title: phrases.PRODUCT_CONFIGURATION,
					name: phrases.PRODUCT_CONFIGURATION,
					path: '',
					element: (
						<SalesConfigurationReadOnly
							haveSalesConfigurationReadOnlyAccess={
								haveSalesConfigurationAdminAccess
							}
						/>
					),
				},
		  ];
};
