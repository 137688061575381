import { post } from 'api.vanilla.service';

/**
 * @function addSalaryComponents
 * @description Fetch all discounts
 * @param {object} state
 */
export function addSalaryComponents(data) {
	return post(`/salary/salary_components`, data)
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
}