import {
	UPDATE_WAITING_TIME_PERFORMANCE_GRID_ENTRIES,
	SET_WAITING_TIME_PERFORMANCE_GRID_ENTRIES,
	RESET_STATE,
	ADD_BATCH_LIST,
	REMOVE_BATCH_LIST_ITEM,
	UPDATE_BATCH_FORM,
} from './workplaceWaitingTimePerformanceGrids.actions';

// utils
import _uniqueId from 'lodash/uniqueId';
import constants from 'services/constants';
import moment from 'moment';

const defaultState = {
	data: {
		workplaceWaitingTimePerformanceGrids: {},
		defaultWorkplaceWaitingTimePerformanceGrids: {},
		batchList: [
			{
				identifier: _uniqueId('row-'),
			},
		],
		batchForm: {
			periodFrom: moment.utc().format(constants.shortDate),
			periodTo: '9999-12-31',
		},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case UPDATE_BATCH_FORM: {
			return {
				...state,
				data: {
					...state.data,
					batchForm: {
						...state.data.batchForm,
						...payload,
					},
				},
			};
		}

		case SET_WAITING_TIME_PERFORMANCE_GRID_ENTRIES: {
			return {
				data: {
					...state.data,
					workplaceWaitingTimePerformanceGrids: payload,
					defaultWorkplaceWaitingTimePerformanceGrids: payload,
				},
			};
		}

		case UPDATE_WAITING_TIME_PERFORMANCE_GRID_ENTRIES: {
			return {
				data: {
					...state.data,
					workplaceWaitingTimePerformanceGrids: {
						...state.data.workplaceWaitingTimePerformanceGrids,
						...payload,
					},
				},
			};
		}

		case RESET_STATE: {
			return {
				...defaultState,
			};
		}

		case ADD_BATCH_LIST: {
			return {
				...state,
				data: {
					...state.data,
					batchList: payload,
				},
			};
		}

		case REMOVE_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchList: state.data.batchList.filter(
						(entry) => entry.identifier !== payload
					),
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
