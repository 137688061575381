/**
 * @function getWorkplaceFilter
 * @param {Array} entries
 * @description gets a filter to omit already existing workplaces when creating new grids
 * @returns {String}
 */
export function getWorkplaceFilter(entries) {
	const arrayOfIds = entries.reduce((acc, currentValue, index) => {
		const id = currentValue.workplace.id;

		const accCopy =
			index === entries.length - 1 ? `${acc}'${id}']` : `${acc}'${id}',`;

		return accCopy;
	}, '[');

	const filter = `:workplace.id=!in=${arrayOfIds};:legacy==false`;

	return filter;
}
