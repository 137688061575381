'use strict';

import PropTypes from 'prop-types';
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import React, { PureComponent } from 'react';

import { ReactDataWrapper } from 'reactDataWrapper';
import { Input, InputCollectionSelect } from 'dumb';

// third party
import _get from 'lodash/get';

// actions
import { editPersonAttribute, setPersonAttribute, resetState } from './store/personAttributesTable.actions';

// api service
import {
	fetchPersonAttributes,
	deletePersonAttribute,
	updatePersonAttribute,
	createPersonAttribute,
} from './store/personAttributesTable.service';

// phrases
import Phrases from './personAttributes.phrases';

class PersonAttributesTable extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
		};

		this.fetchData = this.fetchData.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.createPersonAttribute = this.createPersonAttribute.bind(this);

		this.columns = [
			{
				Header: 'Id',
				width: 50,
				id: 'id',
				accessor: (d) => _get(d, 'id', null),
				filterPath: ':id',
				show: false,
			},
			{
				Header: 'Type',
				id: 'name',
				accessor: (d) => _get(d, 'person_attribute_type.name', null),
				filterPath: ':name',
				sortable: false,
			},
			{
				Header: 'Value',
				id: 'value',
				accessor: (d) => _get(d, 'value', null),
				filterPath: ':value',
				sortable: false,
			},
		];
	}

	/**
	 * @function deleteEntry
	 * @param {Number} id
	 * @description deletes a user
	 */
	deleteEntry(id) {
		return deletePersonAttribute(id);
	}

	/**
	 * @function editEntry
	 * @description edits a user
	 */
	editEntry() {
		const { personAttributeToEdit } = this.props;
		const personAttributeId = personAttributeToEdit.id;

		const payload = {
			value: _get(personAttributeToEdit, 'value', ''),
		};

		return updatePersonAttribute(personAttributeId, payload);
	}

	/**
	 * @function setInitialEditValues
	 * @param {Object} data
	 * @description sets vacation data in the edit modal
	 */
	setInitialEditValues(data) {
		this.props.setPersonAttribute({
			id: data.id,
			type: data.person_attribute_type,
			value: data.value,
		});
	}

	/**
	 * @function fetchData
	 * @param {Object} state
	 * @description get's data for the reactDataWrapper
	 */
	fetchData(state) {
		const { personId } = this.props;

		this.setState(() => ({ loading: true }));

		return fetchPersonAttributes(
			{
				offset: state.offset,
				limit: state.limit,
				sort: state.sort,
				filter: state.filter,
			},
			`(:person.id=='${personId}')`
		)
			.then((response) => {
				this.setState(() => ({
					loading: false,
				}));

				return response;
			})
			.catch(() => {
				this.setState(() => ({
					loading: false,
				}));
			});
	}

	getEditableCells() {
		const { personAttributeToEdit } = this.props;
		return [
			{
				header: 'Type',
				value: (
					<InputCollectionSelect
						id="personAttributeType"
						placeholder="select person attribute"
						value={
							_get(personAttributeToEdit, 'type.name', false)
								? {
										value: _get(personAttributeToEdit, 'type.id', ''),
										label: `${_get(personAttributeToEdit, 'type.name', '')}`,
								  }
								: null
						}
						handleChange={(key, value) =>
							this.editPersonAttributesData('type', {
								id: value ? value.value : '',
								name: value ? value.label : '',
							})
						}
						cache
						apiPath="/hr/person_attribute_types"
						params={{
							limit: 300,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Value',
				value: (
					<Input
						id="value"
						onChange={(event) => this.editPersonAttributesData('value', event)}
						value={_get(personAttributeToEdit, 'value', '')}
					/>
				),
			},
		];
	}

	getEditableCellsEdit() {
		const { personAttributeToEdit } = this.props;
		return [
			{
				header: 'Type',
				value: <span>{_get(personAttributeToEdit, 'type.name', '')}</span>,
			},
			{
				header: 'Value',
				value: (
					<Input
						id="value"
						onChange={(event) => this.editPersonAttributesData('value', event)}
						value={_get(personAttributeToEdit, 'value', '')}
					/>
				),
			},
		];
	}

	/**
	 * @function editPersonAttributesData
	 * @param {String} name - name of the Input
	 * @param {String} e - event
	 */
	editPersonAttributesData(name, e) {
		const { editPersonAttribute, personAttributeToEdit } = this.props;

		const value = e.target ? e.target.value : e;

		const payload = {
			...personAttributeToEdit,
			[name]: value,
		};

		// set the value in the store
		editPersonAttribute(payload);
	}

	createPersonAttribute() {
		const { personAttributeToEdit, personId } = this.props;

		const payload = {
			person: personId,
			person_attribute_type: _get(personAttributeToEdit, 'type.id', ''),
			value: _get(personAttributeToEdit, 'value', ''),
		};

		return createPersonAttribute(payload);
	}

	render() {
		const { totalEntries, loading } = this.state;
		const { resetState } = this.props;

		return (
			<ReactDataWrapper
				filterable={false}
				showPagination={false}
				title={Phrases.PERSON_ATTRIBUTES_TABLE}
				className="-striped -highlight"
				columns={this.columns}
				totalEntries={totalEntries}
				loading={loading}
				accessAreasAllowedToEdit={['Employment Admin', 'Person Admin']}
				createEntry={this.createPersonAttribute}
				editEntry={() => this.editEntry()}
				fetchData={this.fetchData}
				deleteEntry={(id) => this.deleteEntry(id)}
				editableCells={this.getEditableCells()}
				editableCellsEdit={this.getEditableCellsEdit()}
				setInitialEditValues={this.setInitialEditValues}
				defaultPageSize={5}
				reduxKey="/hr/person_attribute_types"
				onModalClose={resetState}
				manual
				inRowButtons
				actionsWidth={60}
			/>
		);
	}
}

PersonAttributesTable.defaultProps = {
	columns: [],
};

PersonAttributesTable.propTypes = {
	editPersonAttribute: PropTypes.func,
	setPersonAttribute: PropTypes.func,
	personAttributeToEdit: PropTypes.object,
	personId: PropTypes.number,
	resetState: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			editPersonAttribute,
			setPersonAttribute,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		personAttributeToEdit: store.juicerPersonAttributesTable.personAttributeToEdit,
	};
};

export default connectWithStore(PersonAttributesTable, mapStateToProps, mapDispatchToProps);
