import {
	SET_LIST_FILTER,
	SET_LIST_SORT,
	SET_CUSTOM_COLUMN_FILTER,
	CLEAR_LIST_FILTERS_SORTING
} from './filterSortColumnsData.actions';

const defaultState = {
	persist: true,
	tables: {}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_LIST_FILTER: {
		return {
			...state,
			tables: {
				...state.tables,
				[payload.reduxKey]: {
					...state.tables[payload.reduxKey],
					filters: payload.filter
				}
			}
		};
	}

	case SET_LIST_SORT: {
		return {
			...state,
			tables: {
				...state.tables,
				[payload.reduxKey]: {
					...state.tables[payload.reduxKey],
					sorting: payload.sorting
				}
			}
		};
	}

	case SET_CUSTOM_COLUMN_FILTER: {
		return {
			...state,
			tables: {
				...state.tables,
				[payload.reduxKey]: {
					...state.tables[payload.reduxKey],
					custom: state.tables[payload.reduxKey]
						? {
							...state.tables[payload.reduxKey].custom,
							[payload.handle]: payload.value
							  }
						: {
							[payload.handle]: payload.value
							  }
				}
			}
		};
	}

	case CLEAR_LIST_FILTERS_SORTING: {
		// copy all tables
		const tablesCopy = {...state.tables};

		// check if exists and delete the table from the object
		tablesCopy[payload] && delete tablesCopy[payload];

		return {
			...state,
			tables: tablesCopy
		};
	}

	default:
		return state;
	}
}

export default reducer;
