import ReactDOMServer from 'react-dom/server';
import { post } from 'api.vanilla.service';
import moment from 'moment';

// Tools
import { saveAs } from 'file-saver';
import _flatten from 'lodash/flatten';
import _isObject from 'lodash/isObject';
import * as Sentry from '@sentry/browser';

// Other
import phrases from './printView.phrases';

const __endpoints = require('endpoints');

export default class PrintViewService {
	constructor(options = null, customStyles = {}, size = null) {
		if (this.options && !_isObject(this.options))
			console.info('this.options should be of type of object');
		if (this.customStyles && !_isObject(this.customStyles))
			console.info('this.options should be of type of object');

		this.options = options;
		this.customStyles = customStyles;
	}

	setOptions(options) {
		this.options = options;
	}

	_getStyles() {
		// remove style that causes CORS errors
		// for now it's only google fonts that causing the issue
		const keysWithCorrectStyles = Object.keys(document.styleSheets).filter(
			(entry, index) => {
				const value = Object.values(document.styleSheets)[index];

				if (
					value.href &&
					value.href.indexOf('https://fonts.googleapis.com') !== -1
				) {
					console.warn('Found a style that isnt supported', value.href, value);

					Sentry.withScope((scope) => {
						scope.setExtra(
							'info',
							`Found a style that isnt supported ${value.href}`
						);
						Sentry.captureMessage('Error in PrintService', 'error');
					});

					return false;
				}

				return true;
			}
		);

		const styles = _flatten(
			keysWithCorrectStyles.map((styleSheetKey) =>
				Object.keys(document.styleSheets[styleSheetKey].cssRules).map(
					(cssRuleKey) =>
						document.styleSheets[styleSheetKey].cssRules[cssRuleKey].cssText
				)
			)
		).join(' ');

		return styles;
	}

	_generateHTML() {
		const elementToPrint = document.getElementById(this.options.id);
		const elementHTML = elementToPrint.outerHTML;

		const customStyle = Object.keys(this.customStyles).map((key) => {
			return key + ' { ' + this.customStyles[key] + ' } ';
		});

		const elementDimensions = {
			width: this.options.width || elementToPrint.getBoundingClientRect().width,
			height:
				this.options.height || elementToPrint.getBoundingClientRect().height,
		};

		const size = this.options.size
			? this.options.size
			: `${elementDimensions.width / 96 + 0.2}in ${
					elementDimensions.height / 92 + 0.45
			  }in`;

		const styles = this._getStyles();

		const bodyPadding = this.options.padding ? this.options.padding : 'inherit';
		const bodyMarging = this.options.margin ? this.options.margin : '0.1in';

		return `
			<!DOCTYPE html>
			<html>
			<head>
				<meta name="author" content="JOE AND THE JUICE WORKPLANNER"/>
				<meta name="subject" content="${
					this.options.title || 'WORKPLANNER PDF EXPORT'
				}"/>
				<meta name="date" content=${moment().format('YYYY-MM-DD')}/>

				<link href="https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700|Roboto:300,400,700|Oswald:400,300" rel="stylesheet">

				<title>Pdf</title>
				<style>

					${customStyle[0]}

					@font-face {
						font-family: 'Oswald';
						font-weight: 400;
						font-style: normal;
						font-display: swap;
					}

					@font-face {
						font-family: 'Oswald';
						font-weight: 300;
						font-style: normal;
						font-display: swap;
					}

					@font-face {
						font-family: 'Roboto';
						font-weight: 400;
						font-style: italic;
						font-display: swap;
					}

					@font-face {
						font-family: 'Roboto';
						font-weight: 700;
						font-style: italic;
						font-display: swap;
					}

					@font-face {
						font-family: 'Roboto';
						font-weight: 300;
						font-style: normal;
						font-display: swap;
					}

					@font-face {
						font-family: 'Roboto';
						font-weight: 700;
						font-style: normal;
						font-display: swap;
					}

					@font-face {
						font-family: 'Roboto';
						font-weight: 300;
						font-style: italic;
						font-display: swap;
					}

					@font-face {
						font-family: 'Roboto';
						font-weight: 400;
						font-style: normal;
						font-display: swap;
					}

					@font-face {
						font-family: 'Roboto Condensed';
						font-weight: 300;
						font-style: italic;
						font-display: swap;
					}

					@font-face {
						font-family: 'Roboto Condensed';
						font-weight: 400;
						font-style: italic;
						font-display: swap;
					}

					@font-face {
						font-family: 'Roboto Condensed';
						font-weight: 700;
						font-style: italic;
						font-display: swap;
					}

					@font-face {
						font-family: 'Roboto Condensed';
						font-weight: 400;
						font-style: normal;
						font-display: swap;
					}

					@font-face {
						font-family: 'Roboto Condensed';
						font-weight: 700;
						font-style: normal;
						font-display: swap;
					}

					@font-face {
						font-family: 'Roboto Condensed';
						font-weight: 300;
						font-style: normal;
						font-display: swap;
					}

					body {
						font-family: 'Roboto Condensed', 'Helvetica', sans-serif;
						font-weight: 300;
					}

					.report-old__table-wrapper h3 {
						margin: 0 0 5px;
						font-family: 'Roboto Condensed', 'Helvetica', sans-serif;
						font-size: .9em;
						font-weight: 400;
    					letter-spacing: .05em;
    					text-transform: uppercase;
					}

					.daily-reports--Operation .daily-report__market-table tbody .report-old__entry:not(.report-old__entry--summed) .daily-report__lc--ok,
					.daily-reports--Operation .daily-report__young-market-table tbody .report-old__entry:not(.report-old__entry--summed) .daily-report__lc--ok {
						color: #7cb342;
					}

					.daily-reports--Operation .daily-report__market-table tbody .report-old__entry:not(.report-old__entry--summed) .daily-report__lc--mid,
					.daily-reports--Operation .daily-report__young-market-table tbody .report-old__entry:not(.report-old__entry--summed) .daily-report__lc--mid {
						color: #f4b127;
					}

					.daily-reports--Operation .daily-report__market-table tbody .report-old__entry:not(.report-old__entry--summed) .daily-report__lc--low,
					.daily-reports--Operation .daily-report__young-market-table tbody .report-old__entry:not(.report-old__entry--summed) .daily-report__lc--low {
						color: #ef5350;
					}


					@media print {

						html {
							background: white !important;
						}

						body {
							font-size: 12pt;
							margin: 0;
							background: white !important;
						}

						* {
							box-shadow: none!important;
						}

						div {
							page-break-before: avoid !important;
							page-break-inside: avoid !important;
							page-break-after: avoid !important;
						}
					}

					${styles}

					@page {
						size: ${size} !important;
						margin: ${bodyMarging} !important;
						background: white !important;
					}

					body {
						margin:0;
						padding: ${bodyPadding};
					}
				</style>

				</head>
				<body>
					${elementHTML}
				</body>
			</html>
		`;
	}

	/**
	 * @function _generateHTMLString
	 * @memberOf printView service
	 * @description generates HTML that is being sent to docraptor
	 * @param content stringified html content
	 */
	_generateHTMLString(content) {
		const elementHTML = content
			? ReactDOMServer.renderToString(content)
			: '<h2>Missing content</h2>';

		let size;
		if (_isObject(this.options.size)) {
			size = `${this.options.size.width / 96 + 0.2}in ${
				this.options.size.height / 92 + 0.2
			}in !important;`;
		} else {
			size = this.options.size;
		}

		const styles = this._getStyles();

		return `
			<!DOCTYPE html>
			<html>
			<head>
				<meta name="author" content="Joe & The Juice: Audits"/>
				<meta name="subject" content="${
					(this.options && this.options.title) || 'WORKPLANNER PDF EXPORT'
				}"/>
				<meta name="date" content=${moment().format('YYYY-MM-DD')}/>

				<link href="https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700|Roboto:300,400,700|Oswald:400,300" rel="stylesheet">

				<title>Joe & The Juice: Audits ${moment().format('YYYY')}</title>
				<style>


					body {
						font-family: 'Roboto Condensed', 'Helvetica', sans-serif;
						margin: 0 !important;
						padding: 0 !important;
						font-weight: 300;
					}

					.td-small {
						width: 120px;
					}

					.td-mid {
						width: 140px;
					}

					.td-small, .td-mid {
						text-align: right;
					}

					.td-left {
						text-align: left;
					}
					.td-right {
						text-align: right;
					}

					div {
						page-break-before: avoid !important;
						page-break-inside: avoid !important;
						page-break-after: avoid !important;
					}


					@media print {

						html {
							background: white !important;
						}

						body {
							padding: 0 !important;
							margin: 0 !important;
							font-size: 12pt;
							background: white !important;
						}

						* {
							box-shadow: none!important;
						}

						div {
							page-break-before: avoid !important;
							page-break-inside: avoid !important;
							page-break-after: avoid !important;
						}

						@page {
							size: ${size};
							margin: 0.05in;
							padding: 0.05in;
							background: white !important;
						}

					}


					${styles}

				</style>

				</head>
				<body>
					${elementHTML}
				</body>
			</html>
		`;
	}

	/**
	 * @function printToPdf
	 * @memberOf COMPONENTS.PrintView
	 * @description Handler for printing as PDF
	 */
	printToPdf(content, overrideTitle) {
		const title =
			(this.options && this.options.title) || 'WORKPLANNER PDF EXPORT';
		const data = {
			content: content
				? this._generateHTMLString(content)
				: this._generateHTML(),
			name: title,
		};

		return new Promise((resolve, reject) => {
			post(__endpoints.utilities.pdfs.create.path, data)
				.then((blob) => {
					saveAs(blob, `${overrideTitle || title}.pdf`);
					resolve();
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	/**
	 * @function printToXlsx
	 * @memberOf COMPONENTS.PrintView
	 * @description Handler for printing as PDF
	 */
	printToXlsx() {
		// Find report table elements
		const elementToPrintWrapper = document.getElementById(this.options.id);
		const elementsToPrint = elementToPrintWrapper.getElementsByClassName(
			this.options.xlsxElementClass
		);

		// Reduce to html
		const html = Array.from(elementsToPrint).reduce((acc, element) => {
			acc = acc + element.outerHTML;
			return acc;
		}, '');

		const data = {
			content: `<tables>${html}</tables>`,
			name: this.options.title || 'WORKPLANNER XLSX EXPORT',
			type: 'xlsx',
		};

		return new Promise((resolve, reject) => {
			post(__endpoints.utilities.pdfs.create.path, data).then(
				(blob) => {
					saveAs(blob, `${this.options.title}.xlsx`);
					resolve();
				},
				(err) => {
					reject(err);
				}
			);
		});
	}

	/**
	 * @function printToPdf
	 * @memberOf COMPONENTS.PrintView
	 * @description Handler for printing as PDF
	 */
	returnPDFAsBase64(content) {
		const data = {
			content: content
				? this._generateHTMLString(content)
				: this._generateHTML(),
			name: this.options.title || 'WORKPLANNER PDF EXPORT',
		};
		const reader = new window.FileReader();

		return new Promise((resolve, reject) => {
			post(__endpoints.utilities.pdfs.create.path, data).then(
				(blob) => {
					reader.readAsDataURL(blob);
					reader.onloadend = () => {
						resolve(reader.result);
					};
				},
				(err) => {
					reject(err);
				}
			);
		});
	}

	/**
	 * @function getMethods
	 * @memberOf COMPONENTS.PrintView
	 * @description Getter for component methods
	 */
	getMethods() {
		return {
			printToXlsx: this.printToXlsx.bind(this),
			printToPdf: this.printToPdf.bind(this),
			returnPDFAsBase64: this.returnPDFAsBase64.bind(this),
		};
	}

	/**
	 * @function getMethods
	 * @memberOf COMPONENTS.PrintView
	 * @description Getter for component phrases
	 */
	getPhrases() {
		return phrases;
	}
}
