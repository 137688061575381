export const RESET_PRODUCT_ADDONS_ENTRIES =
	'SALES_CONFIGURATION/RESET_PRODUCT_ADDONS_ENTRIES';
export const SET_PRODUCT_ADDONS_ENTRIES =
	'SALES_CONFIGURATION/SET_PRODUCT_ADDONS_ENTRIES';
export const UPDATE_PRODUCT_ADDONS_ENTRIES =
	'SALES_CONFIGURATION/UPDATE_PRODUCT_ADDONS_ENTRIES';

export const RESET_PRODUCT_VARIANT_ADDONS =
	'SALES_CONFIGURATION/RESET_PRODUCT_VARIANT_ADDONS';
export const SET_PRODUCT_VARIANT_ADDONS =
	'SALES_CONFIGURATION/SET_PRODUCT_VARIANT_ADDONS';
export const UPDATE_PRODUCT_VARIANT_ADDONS =
	'SALES_CONFIGURATION/UPDATE_PRODUCT_VARIANT_ADDONS';

/**
 * @param {object} payload
 */
export function setProductAddons(payload) {
	return {
		type: SET_PRODUCT_ADDONS_ENTRIES,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateProductAddons(payload) {
	return {
		type: UPDATE_PRODUCT_ADDONS_ENTRIES,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetProductAddons() {
	return {
		type: RESET_PRODUCT_ADDONS_ENTRIES
	};
}

// PRODUCT VARIANT INGREDIENT VARIATIONS //

/**
 * @param {object} payload
 */
export function setProductVariantAddons(payload) {
	return {
		type: SET_PRODUCT_VARIANT_ADDONS,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateProductVariantAddons(payload) {
	return {
		type: UPDATE_PRODUCT_VARIANT_ADDONS,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetProductVariantAddons() {
	return {
		type: RESET_PRODUCT_VARIANT_ADDONS
	};
}
