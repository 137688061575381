export const UPDATE_ILLNESS = 'JUICER_DETAILS_ILLNESSES_TABLE/UPDATE_ILLNESS';
export const SET_ILLNESS = 'JUICER_DETAILS_ILLNESSES_TABLE/SET_ILLNESS';
export const RESET_STATE = 'JUICER_DETAILS_ILLNESSES_TABLE/RESET_STATE';

export const updateIllnessData = payload => {
	return { type: UPDATE_ILLNESS, payload };
};

export const setIllnessData = payload => {
	return { type: SET_ILLNESS, payload };
};

export const resetState = () => {
	return { type: RESET_STATE };
};
