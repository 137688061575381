'use strict';

import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';

import { store } from 'appState';

import phrases from 'fileUpload/fileUpload.phrases';
import { FileUploadFactory as f } from 'fileUpload/factory';
import { FileUploadResultSet } from 'fileUpload/resultSets';
import {
	setFileCount,
	addFileType,
	addFileTypeTemplate,
	resetFileTypes
} from 'fileUpload/fileUpload.actions';

/**
 * FileUpload
 * @param {string} name
 * @description In this class we have both the fileTypes and the fileTypeTemplates.
 * The latter are set in the configuration, and the fileTypes are added dynamically when the
 * user chooses to do so. It is the exact same object and the reason to add fileTypes BASED ON fileTypeTemplates
 * is to avoid inheritance between the two when e.g. counting the number of a specific file type.
 */
class FileUpload {
	constructor(name) {
		// Set type
		this.type = 'fileUpload';

		// Name
		this.name = name;
	}

	/////////////
	// Setters //
	/////////////

	/**
	 * @function setFileTypeTemplates
	 * @memberof FileUpload
	 * @description Adds possible fileType to fileUpload
	 * @param {array/object} fileTypeTemplates
	 */
	setFileTypeTemplates(fileTypeTemplates) {
		this.fileTypeTemplates = fileTypeTemplates;
	}

	/////////////
	// Getters //
	/////////////

	/**
	 * @function getPhrases
	 * @memberof FileUpload
	 * @description Getter for phrases
	 */
	getPhrases() {
		return phrases;
	}

	/**
	 * @function getFileTypes
	 * @memberof FileUpload
	 * @description Getter for file types
	 */
	getFileTypes() {
		return _get(store.getState(), `fileUpload.fileTypes[${this.name}]`, {});
	}

	/**
	 * @function getFileTypeTemplates
	 * @memberof FileUpload
	 * @description Getter for possible file types
	 */
	getFileTypeTemplates() {
		return _get(
			store.getState(),
			`fileUpload.fileTypeTemplates[${this.name}]`,
			{}
		);
	}

	/**
	 * @function getFileTypesAsArray
	 * @memberof FileUpload
	 * @description Getter for file types as array
	 */
	getFileTypesAsArray() {
		return [
			...Object.keys(this.getFileTypes()).map(
				fileType => this.getFileTypes()[fileType]
			)
		];
	}

	/**
	 * @function getFileTypesLength
	 * @memberof FileUpload
	 * @description Getter for file types length
	 */
	getFileTypesLength() {
		return Object.keys(this.getFileTypes()).length;
	}

	/**
	 * @function getFileTypesFiles
	 * @memberof FileUpload
	 * @description Getter for files set in file types
	 */
	getFileTypesFiles() {
		return this.getFileTypesAsArray().filter(
			fileType => fileType.getFile() !== null
		);
	}

	/**
	 * @function getFileTypeTemplatesAsArray
	 * @memberof FileUpload
	 * @description Getter for possible file types as array
	 */
	getFileTypeTemplatesAsArray() {
		return [
			...Object.keys(this.getFileTypeTemplates()).map(
				fileType => this.getFileTypeTemplates()[fileType]
			)
		];
	}

	////////////////////
	// Event handlers //
	////////////////////

	/**
	 * @function handleSetFile
	 * @memberof FileUpload
	 * @description Handler for setting file and file name via file types
	 */
	handleSetFile(fileType, file, fileName, fileExtension) {
		// Set file
		this.getFileTypes()[fileType.getIdentifier()].handleSetFile(
			file,
			fileName,
			fileExtension
		);

		// Update file count (naive way to update file attached to each fileType and secure rerender)
		store.dispatch(setFileCount(this.name, this.getFileTypesFiles().length));
	}

	/**
	 * @function handleGetResultSet
	 * @memberof FileUpload
	 * @description Handler for getting resultSet
	 */
	handleGetResultSet() {
		return new Promise(resolve => resolve(new FileUploadResultSet(this)));
	}

	/**
	 * @function handleFormatFileTypeTemplatesAsOptions
	 * @memberof FileUpload
	 * @description Getter for filetypes formatted as select options (value/label)
	 */
	handleFormatFileTypeTemplatesAsOptions(fileTypeTemplates) {
		// Format as array
		fileTypeTemplates = [
			...Object.keys(fileTypeTemplates).map(
				fileType => fileTypeTemplates[fileType]
			)
		];

		// Sort by name
		fileTypeTemplates = _sortBy(fileTypeTemplates, [
			fileType => fileType.getName()
		]);

		// Map as options
		return fileTypeTemplates.map(fileType => ({
			value: fileType.getName(),
			label: `${fileType.getName()} (${fileType.getExtensionsAsString()})`
		}));
	}

	/**
	 * @function handleResetFileTypes
	 * @memberof FileUpload
	 * @description Handler for resetting all file types
	 */
	handleResetFileTypes() {
		this.initFileTypeTemplates();

		// Reset file types and templates
		this.resetFileTypesAndTemplates();

		// Show default file types
		this.showDefaultFileTypeTemplates();
	}

	/////////////
	// Utility //
	/////////////

	/**
	 * @function addFileTypeTemplate
	 * @memberof FileUpload
	 * @description Adds single fileType to fileUpload
	 * @param {object} fileType
	 */
	addFileTypeTemplate(fileType) {
		store.dispatch(addFileTypeTemplate(this.name, fileType));
	}

	/**
	 * @function initFileTypeTemplates
	 * @memberof FileUpload
	 * @description Adds possible fileType to fileUpload
	 * @param {array/object} fileTypes
	 */
	initFileTypeTemplates() {
		// Check if array
		// Loop through and add to fileUpload
		if (Array.isArray(this.fileTypeTemplates)) {
			this.fileTypeTemplates.forEach(fileType =>
				this.addFileTypeTemplate(fileType)
			);
		}
	}

	/**
	 * @function showDefaultFileTypeTemplates
	 * @memberof FileUpload
	 * @description Shows default file types set.
	 */
	showDefaultFileTypeTemplates() {
		this.getFileTypeTemplatesAsArray().forEach(fileTypeTemplate => {
			if (fileTypeTemplate.getShowAsDefault())
				this.handleAddFileType(fileTypeTemplate.getName());
		});
	}

	/**
	 * @function resetFileTypesAndTemplates
	 * @memberof FileUpload
	 * @description Reset file types and templates as configured
	 */
	resetFileTypesAndTemplates() {
		// Reset templates count to 0
		this.getFileTypeTemplatesAsArray().forEach(fileTypeTemplate =>
			fileTypeTemplate.resetCount()
		);

		// Reset selected filetypes
		store.dispatch(resetFileTypes(this.name));

		// Reset file count
		store.dispatch(setFileCount(this.name, 0));
	}

	/**
	 * @function addFileType
	 * @memberof FileUpload
	 * @description Adds fileType to fileUpload
	 */
	handleAddFileType(fileTypeTemplateOption) {
		// Get template
		const fileTypeTemplate = this.getFileTypeTemplates()[
			fileTypeTemplateOption
		];

		// Add to count of current fileTypeTemplate
		fileTypeTemplate.addToCount();

		// Identifier to be (key) for current file in fileTypes
		const fileTypeIdentifier = `${fileTypeTemplateOption}_${fileTypeTemplate.fileTypeCount}`;

		// New f.fileType
		const fileType = f.fileType(
			fileTypeTemplate.getName(),
			fileTypeTemplate.getExtensions(),
			typeof fileTypeTemplate.defaultFileName === 'function'
				? d => fileTypeTemplate.defaultFileName(d)
				: fileTypeTemplate.defaultFileName,
			false,
			fileTypeTemplate.getId()
		);

		// Add new instantiated f.fileType to selected fileTypes in store, with name and fileTypeCount as key
		// We add a new fileType, based on the fileTypeTemplate, to avoid inheritage between the two
		store.dispatch(addFileType(this.name, fileTypeIdentifier, fileType));

		// Set number in current list of fileTypes
		store
			.getState()
			.fileUpload.fileTypes[this.name][fileTypeIdentifier].setNumber(
				fileTypeTemplate.getFileTypeCount()
			);
	}
}

export default FileUpload;
