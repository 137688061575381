'use strict';

import DynamicPosView from './dynamicPosView';

class DynamicPosViewFactory {

	/**
	 * @function dynamicPosView
	 * @memberOf DynamicPosViewFactory
	 * @param {string} name
	 * @param {object} dataProvider
	 */
	dynamicPosView(name, dataProvider) {
		return new DynamicPosView(name, dataProvider);
	}

}

export default new DynamicPosViewFactory();