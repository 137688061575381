import React, { useState } from 'react';
import PropTypes from 'prop-types';

// redux
import { useDispatch, useSelector } from 'react-redux';
import {
	setGlobalIngredientComponentTranslation,
	updateGlobalIngredientComponentTranslation,
	resetState,
} from './store/homeScreenRecommendationTranslations.actions';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';
import HomeScreenRecommendationTranslationsModalBatch from './components/batch/homeScreenRecommendationTranslationsModalBatch';
import { Tooltip, Icon, Button, Input } from 'dumb';

// services
import {
	fetchAllHomeScreenRecommendations,
	fetchAllHomeScreenRecommendationTranslations,
	deleteHomeScreenRecommendationTranslations,
	editHomeScreenRecommendationTranslations,
} from './homeScreenRecommendationTranslations.service';

// utils
import { getEditedValues } from 'services/utils';

// phrases
import phrases from './homeScreenRecommendationTranslations.phrases';

const homeScreenRecommendationColumns = [
	{
		Header: 'Name',
		id: 'description',
		accessor: 'description',
		filterPath: ':description',
	},
	{
		Header: 'Tier',
		id: 'tier',
		accessor: (d) => d.tier?.name ?? '',
		filterPath: ':tier.name',
	},
	{
		Header: 'Product 1',
		id: 'product-1',
		accessor: (d) => d.product_1?.name ?? '',
		filterPath: ':product_1.name',
	},
	{
		Header: 'Product 2',
		id: 'product-2',
		accessor: (d) => d.product_2?.name ?? '',
		filterPath: ':product_2.name',
	},
	{
		Header: 'Product 3',
		id: 'product-3',
		accessor: (d) => d.product_3?.name ?? '',
		filterPath: ':product_3.name',
	},
	{
		Header: 'Product 4',
		id: 'product-4',
		accessor: (d) => d.product_4?.name ?? '',
		filterPath: ':product_4.name',
	},
	{
		Header: 'Product 5',
		id: 'product-5',
		accessor: (d) => d.product_5?.name ?? '',
		filterPath: ':product_5.name',
	},
	{
		Header: 'Product 6',
		id: 'product-6',
		accessor: (d) => d.product_6?.name ?? '',
		filterPath: ':product_6.name',
	},
];

const homeScreenRecommendationTranslationColumns = [
	{
		Header: 'Description',
		id: 'description',
		accessor: 'description',
		filterPath: ':description',
	},
	{
		Header: 'Language',
		id: 'language',
		accessor: 'language.name',
		filterPath: ':language.name',
	},
];

const HomeScreenRecommendationTranslations = ({ id }) => {
	const dispatch = useDispatch();
	const { tableKey, homeScreenRecommendationTranslation, originalHomeScreenRecommendationTranslation } = useSelector(
		(store) => store.homeScreenRecommendationTranslations
	);

	const [showBatchModal, setShowBatchModal] = useState(false);

	// crud
	const fetchHomeScreenRecommendationTranslations = (id, state) =>
		fetchAllHomeScreenRecommendationTranslations(id, state);
	const fetchHomeScreenRecommendations = (state) => fetchAllHomeScreenRecommendations(id, state);
	const editEntry = () => {
		const valuesToEdit = getEditedValues({
			newData: homeScreenRecommendationTranslation,
			oldData: originalHomeScreenRecommendationTranslation,
		});

		const payload = {
			id: homeScreenRecommendationTranslation.id,
			description: valuesToEdit.description,
		};

		return editHomeScreenRecommendationTranslations(payload);
	};
	const deleteEntry = (id) => deleteHomeScreenRecommendationTranslations(id);

	// crud prep
	const setInitialEditValues = (data) => {
		const body = {
			id: data.id,
			description: data.description,
			language: data.language.name,
		};

		dispatch(setGlobalIngredientComponentTranslation(body));
	};
	const getEditableCells = () => [
		{
			header: 'Description',
			value: (
				<Input
					id="description"
					placeholder={phrases.DESCRIPTION}
					value={homeScreenRecommendationTranslation.description}
					onChange={(event) =>
						dispatch(updateGlobalIngredientComponentTranslation({ description: event.target.value }))
					}
				/>
			),
		},
		{
			header: 'Language',
			value: <span>{homeScreenRecommendationTranslation.language}</span>,
		},
	];

	const toggleBatchModal = () => setShowBatchModal(!showBatchModal);
	const onRdwModalClose = () => dispatch(resetState());

	return (
		<>
			<ReactDataWrapper
				accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.TITLE}
				columns={homeScreenRecommendationColumns}
				fetchData={fetchHomeScreenRecommendations}
				filterable
				defaultPageSize={10}
				reduxKey={`${id}-/pos/tile_layout_home_screen_recommendations`}
				manual
				subcomponent={(row) => (
					<SubTable>
						<>
							<ReactDataWrapper
								key={tableKey}
								accessAreasAllowedToEdit={['Sales Configuration']}
								title={phrases.TITLE}
								columns={homeScreenRecommendationTranslationColumns}
								fetchData={(state) => fetchHomeScreenRecommendationTranslations(row.original?.id ?? null, state)}
								filterable
								defaultPageSize={10}
								reduxKey={`${row.original?.id ?? null}-pos/tile_layout_home_screen_recommendation_translations`}
								manual
								editableCells={getEditableCells()}
								setInitialEditValues={setInitialEditValues}
								onModalClose={onRdwModalClose}
								deleteEntry={deleteEntry}
								editEntry={editEntry}
								actionRender={
									<Tooltip
										text={phrases.BATCH_ADD}
										renderData={(ref, onMouseEnter, onMouseLeave) => (
											<Button
												label="Add"
												shadow
												type="inverted"
												onClick={toggleBatchModal}
												refProp={ref}
												onMouseEnter={onMouseEnter}
												onMouseLeave={onMouseLeave}
											>
												<Icon name="add" />
											</Button>
										)}
									/>
								}
							/>

							{showBatchModal && (
								<HomeScreenRecommendationTranslationsModalBatch
									onClose={toggleBatchModal}
									homeScreenRecommendationTranslationId={row.original?.id ?? null}
								/>
							)}
						</>
					</SubTable>
				)}
			/>
		</>
	);
};

HomeScreenRecommendationTranslations.propTypes = {
	id: PropTypes.number,
};

export default HomeScreenRecommendationTranslations;
