import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import { Loader } from 'dumb';

import './appLayoutLoader.css';

export default function AppLayoutLoader(props) {
	return (
		<div
			className={cx('app-layout-loader', {
				'app-layout-loader--loading': props.loading
			})}>
			{props.loading && <Loader />}
		</div>
	);
}

AppLayoutLoader.defaultProps = {
	loading: false
};

AppLayoutLoader.propTypes = {
	loading: PropTypes.bool
};
