'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, InputCollectionSelect } from 'dumb';
import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';

// phrases
import phrases from './../../../productBundleSetProductVariants.phrases';

// lodash
import _get from 'lodash/get';

class ProductBundleSetProductVariantsBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: e },
		});
	}

	render() {
		const { batchList, productVariantUrl } = this.props;

		return (
			<TableList>
				<thead>
					<tr>
						<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
						<th>{phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT}</th>
						<th>{phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT}</th>

						<th />
					</tr>
				</thead>
				<tbody>
					{batchList.map((entry, i) => {
						return (
							<TableListRow key={entry.identifier}>
								<TableListCell>{i + 1}</TableListCell>
								<TableListCell>
									{_get(entry, 'productBundleSet.name', '')}
								</TableListCell>
								<TableListCell>
									<InputCollectionSelect
										id={`${entry.identifier}-product_variant`}
										className="modal-batch-select"
										placeholder={
											phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT_PLACEHOLDER
										}
										value={entry.productVariant}
										handleChange={(key, value) =>
											this._editListEntry(value, entry, 'productVariant')
										}
										cache
										apiPath={productVariantUrl}
										params={{
											limit: 50,
										}}
										optionFormat={(entry) => ({
											value: entry,
											label: entry.name,
										})}
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
									/>
								</TableListCell>
								<TableListCell>
									<Button
										id={`${entry.identifier}-delete`}
										type="negative"
										size="tiny"
										onClick={() => this._onRemoveEntry(entry.identifier)}>
										<span className="icon icon--delete" />
									</Button>
								</TableListCell>
							</TableListRow>
						);
					})}
				</tbody>
			</TableList>
		);
	}
}

ProductBundleSetProductVariantsBatchStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	productVariantUrl: PropTypes.string,
};

ProductBundleSetProductVariantsBatchStepList.defaultProps = {};

export default ProductBundleSetProductVariantsBatchStepList;
