'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { store } from 'appState';
// import cx from 'classnames';

import { ModalStepper } from 'dumb';

import BatchStepForm from './components/batchStepForm';
import BatchStepList from './components/batchStepList';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';

import _uniqueId from 'lodash/uniqueId';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

class ModalBatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showOverlay: false,
			overlayType: '',
			loadingModal: false,
			allowOverflow: true,
		};

		this.modalRef = React.createRef();

		// Binds
		this._goToStep = this._goToStep.bind(this);
		this._batchSubmit = this._batchSubmit.bind(this);
		this.toggleOverlay = this.toggleOverlay.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.onOverlayOpen = this.onOverlayOpen.bind(this);

		this.columns = [
			{
				Header: 'Category name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
		];
	}

	_goToStep(step) {
		this.setState(() => ({
			allowOverflow: step === 1,
		}));

		this.modalRef.current.goToStep(step);
	}

	toggleOverlay(type) {
		const { ingredientCategories, ingredientPricingGroups } = this.props;

		if (this.state.showOverlay) {
			switch (this.state.overlayType) {
				case 'ingredient_category': {
					let selectedRowsInList = _get(ingredientCategories, 'ui.selectedRows', []);
					selectedRowsInList = selectedRowsInList.map((x) => {
						return {
							label: x.name,
							value: x,
						};
					});
					this.editStoreEntry(selectedRowsInList, 'ingredient_category');
					break;
				}
				case 'ingredient_pricing_group': {
					let selectedRowsInPricingGroups = _get(ingredientPricingGroups, 'ui.selectedRows', []);
					selectedRowsInPricingGroups = selectedRowsInPricingGroups.map((x) => {
						return {
							label: x.name,
							value: x,
						};
					});
					this.editStoreEntry(selectedRowsInPricingGroups, 'ingredient_pricing_group');
					break;
				}
				default:
				// nothing happens :)
			}
		}

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
			overlayType: prevState.overlayType ? '' : type,
		}));
	}

	editStoreEntry(e, type) {
		const { batchOptions } = this.props;

		const value = e && e.target ? e.target.value : e;

		this.props.updateBatchForm({
			...batchOptions,
			...{ [type]: value },
		});
	}

	fetchData(state) {
		if (this.state.overlayType === 'ingredient_pricing_group') {
			// ingredient_pricing_groups
			return this.props.fetchIngredientPricingGroups(state);
		} else {
			// ingredient_category
			return this.props.fetchIngredientCategories(state);
		}
	}

	onOverlayOpen() {
		const { batchOptions } = this.props;
		const { overlayType } = this.state;

		switch (overlayType) {
			case 'ingredient_category':
				if (batchOptions.ingredient_category.length) {
					// Copy ingredient cats from select to
					batchOptions.ingredient_category.map((x) => {
						store.dispatch(
							actions.toggleRow({
								reduxKey: 'product/ingredient_categories/batch',
								data: x.value,
							})
						);
					});
				}
				break;

			case 'ingredient_pricing_group':
				if (batchOptions.ingredient_pricing_group.length) {
					// Copy assets coll from select to
					batchOptions.ingredient_pricing_group.map((x) => {
						store.dispatch(
							actions.toggleRow({
								reduxKey: 'product/ingredient_pricing_groups/batch',
								data: x.value,
							})
						);
					});
				}
				break;
		}
	}

	_onSubmitForm() {
		const { batchOptions } = this.props;

		// Arrange new lsit
		const listArray = batchOptions.ingredient_category.reduce((acc, x) => {
			const list = batchOptions.ingredient_pricing_group.map((y) => {
				return {
					identifier: _uniqueId('row-'), // used to identify what to edit on step#2
					name: batchOptions.name,
					fuel: batchOptions.fuel,
					ingredient_category: x.value,
					asset_collection: {
						label: _get(batchOptions, 'asset_collection.value.name', null),
						value: _get(batchOptions, 'asset_collection.value', null),
					},
					ingredient_pricing_group: y.value,
					listName: batchOptions.listName,
					operationType: batchOptions.operationType,
					availabilityAdministration: batchOptions.availabilityAdministration,
				};
			});

			return [...acc, ...list];
		}, []);
		this.props.addBatchList(listArray);

		this._goToStep(2);
	}

	_batchSubmit() {
		const { batchList, handleClose } = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		let payload = batchList.map((x) => {
			return {
				name: x.name,
				fuel: x.fuel,
				ingredient_pricing_group: _get(x, 'ingredient_pricing_group.id', ''),
				ingredient_category: _get(x, 'ingredient_category.id', ''),
				asset_collection: _get(x, 'asset_collection.value.id'),
				...(x.listName && {
					list_name: x.listName,
				}),
				...(x.operationType && {
					operation_type: x.operationType.value,
				}),
				availability_administration: !!x.availabilityAdministration,
			};
		});

		payload = {
			batch: payload,
		};

		this.props
			.addIngredients(payload)

			.then((e) => {
				handleClose();
				this.setState(() => ({
					loadingModal: false,
				}));
			})

			.catch((e) => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	render() {
		const {
			modalVisible,
			handleClose,
			batchOptions,
			updateBatchForm,
			removeBatchListItem,
			editBatchListItem,
			batchList,
		} = this.props;
		const { showOverlay } = this.state;

		const isValidStep = !_isEmpty(batchOptions.ingredient_category) && !_isEmpty(batchOptions.ingredient_pricing_group);
		// const isValidSubmit = !batchList.some(x => x.name === '' || x.fuel === '');

		const steps = [
			{
				component: (
					<BatchStepForm
						batchOptions={batchOptions}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.toggleOverlay}
					/>
				),
				title: 'Batch add',
				isValid: isValidStep,
				onNext: () => this._onSubmitForm(),
				loading: false,
				confirmButtonLabel: 'next',
				defaultStyles: true,
				noScroll: true,
			},
			{
				component: (
					<BatchStepList
						batchList={batchList}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
					/>
				),
				title: 'Confirm entries',
				isValid: true,
				onNext: () => this._batchSubmit(),
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: 'submit',
				cancelButtonLabel: 'back',
				defaultStyles: false,
			},
		];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						className="modal-batch"
						ref={this.modalRef}
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={showOverlay ? () => {} : handleClose}
						closeConfirm
						allowOverflow={this.state.allowOverflow}
					/>
				)}

				{/* {showOverlay && ( */}
				{/* <Overlay
					zIndex={551}
					height={isMobile() ? 480 : 680}
					list
					visible={showOverlay}
					onClose={this.toggleOverlay}>
					<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
						title={
							this.state.overlayType === 'ingredient_pricing_group'
								? 'Ingredient Pricing Groups'
								: 'Ingredient Categories'
						}
						className="-striped -highlight"
						columns={this.columns}
						totalEntries={this.state.totalEntries} // Display the total number of pages
						fetchData={this.fetchData} // Request new batchOptions when things change
						filterable
						disableFetchCsvButton
						enableMultiSelection
						batchSelection
						defaultPageSize={20}
						onInitialization={() => this.onOverlayOpen()}
						reduxKey={
							this.state.overlayType === 'ingredient_pricing_group'
								? 'product/ingredient_pricing_groups/batch'
								: 'product/ingredient_categories/batch'
						}
						manual
						actionsWidth={0}
						style={{
							maxHeight: '720px'
						}}
					/>
				</Overlay> */}
			</>
		);
	}
}

ModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	batchOptions: PropTypes.object,
	ingredientCategories: PropTypes.object,
	ingredientPricingGroups: PropTypes.object,
	batchList: PropTypes.array,

	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	addBatchList: PropTypes.func,
	fetchIngredientCategories: PropTypes.func,
	fetchIngredientPricingGroups: PropTypes.func,
	addIngredients: PropTypes.func,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

ModalBatch.defaultProps = {
	modalVisible: false,
	headers: [],
	editableCells: [],
	loading: false,
};

export default ModalBatch;
