/**
 * Return number of shifts on day with most shifts
 * @param shifts
 * @returns {*}<
 */
export default function sortShifts(shifts = []) {
	shifts.sort((a, b) => {
		let tmpTimeA = Date.parse(a.planned_period.from);
		let tmpTimeB = Date.parse(b.planned_period.from);

		if (tmpTimeA > tmpTimeB) {
			return 1;
		} else if (tmpTimeA < tmpTimeB) {
			return -1;

			// If start time is the same, compare end times
		} else if (tmpTimeA === tmpTimeB) {
			tmpTimeA = Date.parse(a.planned_period.to);
			tmpTimeB = Date.parse(b.planned_period.to);
			if (tmpTimeA > tmpTimeB) {
				return 1;
			} else if (tmpTimeA < tmpTimeB) {
				return -1;
			}
		}

		// a must be equal to b
		return 0;
	});

	return shifts;
}
