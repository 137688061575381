export const SET_SHIFT_TRANSFER_POLICY =
	'SHIFT_TRANSFER_POLICY/SET_WAITING_TIME_GRIDS';
export const UPDATE_SHIFT_TRANSFER_POLICY =
	'SHIFT_TRANSFER_POLICY/UPDATE_SHIFT_TRANSFER_POLICY';
export const RESET_SHIFT_TRANSFER_POLICY =
	'SHIFT_TRANSFER_POLICY/RESET_SHIFT_TRANSFER_POLICY';

export function setShiftTransferPolicy(payload) {
	return {
		type: SET_SHIFT_TRANSFER_POLICY,
		payload,
	};
}

export function updateShiftTransferPolicy(payload) {
	return {
		type: UPDATE_SHIFT_TRANSFER_POLICY,
		payload,
	};
}

export function resetShiftTransferPolicy() {
	return {
		type: RESET_SHIFT_TRANSFER_POLICY,
	};
}
