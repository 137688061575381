import { get, post, remove } from 'api.vanilla.service';
import { fetchCompleteCollection } from 'api/fetchCompleteCollection';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './homeScreenRecommendations.phrases';
// utils
import { sortHomeScreenRecommendationsUnderTimeSlots } from './utils';

/**
 * @function fetchTileLayoutTimeSlots
 * @description Fetch all tile layout time slots
 */
export function fetchTileLayoutTimeSlots(filter) {
	// Set params and filter
	const params = {
		limit: 300,
		filter,
	};

	return get('/pos/tile_layout_time_slots', params)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchTileLayoutHomeScreenRecommendations
 * @description Fetch all tile layout home screen recommendations
 */
export function fetchTileLayoutHomeScreenRecommendations(filter) {
	// Set params and filter
	const params = {
		limit: 300,
		filter,
	};

	return get('/pos/tile_layout_home_screen_recommendations', params)
		.then((res) => {
			return sortHomeScreenRecommendationsUnderTimeSlots(res.data);
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchPosTiers
 * @description Fetch all pos tiers
 * @param {object} state
 */
export function fetchPosTiers(filter) {
	// Set params and filter
	const params = {
		limit: 300,
		filter,
	};

	return get('/pos/tiers', params)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchProducts
 * @description Fetch all products
 * @param {object} state
 */
export function fetchProducts() {
	const filter = `:legacy=='false'`;

	// Set params and filter
	const params = {
		limit: 300,
		filter,
	};

	return fetchCompleteCollection('/product/products', params)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addPosTileLayoutTimeSlots
 * @description add pos tile layout time slots
 */
export function addPosTileLayoutTimeSlots(data) {
	return post('/pos/tile_layout_time_slots', data)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editPosTileLayoutTimeSlots
 * @description edit pos tile layout time slot
 */
export function editPosTileLayoutTimeSlots(data) {
	return post(`/pos/tile_layout_time_slots/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.TILE_LAYOUT_TIME_SLOT_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deletePosTileLayoutTimeSlots
 * @description delete pos tile layout time slot
 */
export function deletePosTileLayoutTimeSlots(data) {
	return remove(`/pos/tile_layout_time_slots/${data.id}?cascade=true`)
		.then((res) => {
			setFeedback(phrases.TILE_LAYOUT_TIME_SLOT_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function addTileLayoutHomeScreenRecommendations
 * @description add tile layout home scree recommendations
 */
export function addTileLayoutHomeScreenRecommendations(data) {
	return post('/pos/tile_layout_home_screen_recommendations', data)
		.then((res) => {
			setFeedback(phrases.TILE_LAYOUT_HOME_SCREEN_RECOMMENDATIONS_ADDED, 1);
			return sortHomeScreenRecommendationsUnderTimeSlots(res.data);
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editTileLayoutHomeScreenRecommendations
 * @description edit tile layout home screen recommendations
 */
export function editTileLayoutHomeScreenRecommendations(data) {
	return post(`/pos/tile_layout_home_screen_recommendations/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.TILE_LAYOUT_HOME_SCREEN_RECOMMENDATIONS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function duplicateTileLayoutTimeSlots
 * @description duplicate tile layout home screen recommendations
 */
export function duplicateTileLayoutTimeSlots(
	tileLayoutToDuplicateTo,
	body
) {
	return post(
		`/pos/tile_layouts/${tileLayoutToDuplicateTo}/tile_layout_time_slots`,
		body
	)
		.then((res) => {
			setFeedback(
				phrases.TILE_LAYOUT_HOME_SCREEN_RECOMMENDATIONS_DUPLICATED,
				1
			);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
