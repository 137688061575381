import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';

type Props = {
	src: string;
};

export default function ImageBox({ src }: Props): JSX.Element {
	const [height, setHeight] = useState(0);
	const [width, setWidth] = useState(0);

	useEffect(() => {
		const img = new Image();
		img.src = src;

		try {
			img.onload = function () {
				setHeight(img.height);
				setWidth(img.width);
			};
		} catch (error: any) {
			console.error(
				'Error happened when trying to get workplace image dimensions',
				error.message
			);
		}
	}, [src]);

	const showImageDimensions = !!height && !!width;

	return (
		<Box
			sx={{
				marginTop: 2,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
			}}
			data-cy="store-front-image-box"
		>
			<Box
				sx={{
					height: 350,
					width: 'auto',
					backgroundImage: `url(${src})`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'contain',
				}}
				data-cy="store-front-image-box-image"
			/>

			{showImageDimensions && (
				<Box
					sx={{
						display: 'flex',
						marginTop: 2,
					}}
					data-cy="store-front-image-box-dimensions"
				>
					<Typography variant="subtitle2">Height: {height}px</Typography>
					<Box
						sx={{
							marginLeft: 1,
						}}
					>
						<Typography variant="subtitle2">Width: {width}px</Typography>
					</Box>
				</Box>
			)}
		</Box>
	);
}
