import React, { useState } from 'react';
import PropTypes from 'prop-types';

// redux
import { useDispatch, useSelector } from 'react-redux';
import {
	setProductBundleTranslation,
	updateProductBundleTranslation,
	resetState,
} from './store/productBundleTranslations.actions';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import ProductBundleTranslationsModalBatch from './components/batch/productBundleTranslationsModalBatch';
import { Tooltip, Icon, Button, Input } from 'dumb';

// services
import {
	fetchAllProductBundleTranslations,
	deleteProductBundleTranslation,
	editProductBundleTranslations,
} from './productBundleTranslations.service';

// utils
import { getEditedValues } from 'services/utils';

// phrases
import phrases from './productBundleTranslations.phrases';

const columns = [
	{
		Header: 'Name',
		id: 'name',
		accessor: 'name',
		filterPath: ':name',
	},
	{
		Header: 'Language',
		id: 'language',
		accessor: 'language.name',
		filterPath: ':language.name',
	},
];

const ProductBundleTranslations = ({ id }) => {
	const dispatch = useDispatch();
	const { tableKey, productBundleTranslation, originalProductBundleTranslation } = useSelector(
		(store) => store.productBundleTranslations
	);

	const [showBatchModal, setShowBatchModal] = useState(false);

	// crud
	const fetchData = (state) => fetchAllProductBundleTranslations(id, state);
	const editEntry = () => {
		const valuesToEdit = getEditedValues({
			newData: productBundleTranslation,
			oldData: originalProductBundleTranslation,
		});

		const payload = {
			id: productBundleTranslation.id,
			name: valuesToEdit.name,
		};

		return editProductBundleTranslations(payload);
	};
	const deleteEntry = (id) => deleteProductBundleTranslation(id);

	// crud prep
	const setInitialEditValues = (data) => {
		const body = {
			id: data.id,
			name: data.name,
			language: data.language.name,
		};

		dispatch(setProductBundleTranslation(body));
	};
	const getEditableCells = () => [
		{
			header: 'Name',
			value: (
				<Input
					id="name"
					placeholder={phrases.NAME}
					value={productBundleTranslation.name}
					onChange={(event) => dispatch(updateProductBundleTranslation({ name: event.target.value }))}
				/>
			),
		},
		{
			header: 'Language',
			value: <span>{productBundleTranslation.language}</span>,
		},
	];

	const toggleBatchModal = () => setShowBatchModal(!showBatchModal);
	const onRdwModalClose = () => dispatch(resetState());

	return (
		<>
			<ReactDataWrapper
				key={tableKey}
				accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.TITLE}
				columns={columns}
				fetchData={fetchData}
				filterable
				defaultPageSize={10}
				reduxKey={`${id}-product/bundle_translations`}
				manual
				editableCells={getEditableCells()}
				setInitialEditValues={setInitialEditValues}
				onModalClose={onRdwModalClose}
				deleteEntry={deleteEntry}
				editEntry={editEntry}
				actionRender={
					<Tooltip
						text={phrases.BATCH_ADD}
						renderData={(ref, onMouseEnter, onMouseLeave) => (
							<Button
								label="Add"
								shadow
								type="inverted"
								onClick={toggleBatchModal}
								refProp={ref}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
							>
								<Icon name="add" />
							</Button>
						)}
					/>
				}
			/>

			{showBatchModal && <ProductBundleTranslationsModalBatch onClose={toggleBatchModal} id={id} />}
		</>
	);
};

ProductBundleTranslations.propTypes = {
	id: PropTypes.number,
};

export default ProductBundleTranslations;
