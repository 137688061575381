import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './productVariantGroupProductVariants.phrases';

/**
 * @function fetchProductVariantGroupProductVariants
 * @description Fetch all product variant groups
 * @param {object} state
 */
export function fetchProductVariantGroupProductVariants(state, filter) {
	const newFilter = state.filer ? `${state.filter};${filter}` : filter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: newFilter,
	};

	return get(
		'/product/product_variant_group_product_variants',
		params,
		null,
		state.headers
	)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addProductVariantGroupProductVariants
 * @description add product variant group
 */
export function addProductVariantGroupProductVariants(data) {
	return post('/product/product_variant_group_product_variants', data)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_GROUP_PRODUCT_VARIANT_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteProductVariantGroupProductVariant
 * @description delete product variant group
 */
export function deleteProductVariantGroupProductVariant(id) {
	return remove(`/product/product_variant_group_product_variants/${id}`)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_GROUP_PRODUCT_VARIANT_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
