'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';

// batch view
import FilterProductVariantIngiredientVariationDefaultReplacementsBatchStepList from './components/filterProductVariantIngredientVariationDefaultReplacementsBatchStepList';

// phrases
import phrases from './../../filterProductVariantIngredientVariationDefaultReplacements.phrases';

// lodash
import _get from 'lodash/get';

import './filterProductVariantIngredientVariationDefaultReplacementsModalBatch.css';

class FilterProductVariantIngredientVariationDefaultReplacementsModalBatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};
	}

	_batchSubmit() {
		const {
			batchList,
			handleClose,
			addFilterProductVariantIngredientVariationDefaultReplacement,
			filterProductVariant,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchList.map((entry) => {
			return {
				filter_product_variant: filterProductVariant.id,
				product_variant_ingredient: _get(
					entry,
					'productVariantIngredient.value.id',
					null
				),
				replacement_ingredient_variation_option: _get(
					entry,
					'ingredientVariationOption.value.id',
					null
				),
				sort_order: entry.sortOrder,
			};
		});

		addFilterProductVariantIngredientVariationDefaultReplacement({
			batch: payload,
		})
			.then(() => {
				handleClose();
				this.setState(() => ({
					loadingModal: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	render() {
		const {
			modalVisible,
			handleClose,
			batchList,
			editBatchListItem,
			removeBatchListItem,
			addBatchListItemRow,
			filterProductVariant,
		} = this.props;

		const steps = [
			{
				component: (
					<FilterProductVariantIngiredientVariationDefaultReplacementsBatchStepList
						batchList={batchList}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
						productRecommendationUrl={this.productRecommendationUrl}
						tileLayoutUrl={this.tileLayoutUrl}
						ingredientConfigurationUrl={this.ingredientConfigurationUrl}
						addBatchListItemRow={addBatchListItemRow}
						filterProductVariant={filterProductVariant}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return modalVisible ? (
			<ModalStepper
				isOpen={modalVisible}
				steps={steps}
				showStep={false}
				onClose={handleClose}
			/>
		) : null;
	}
}

FilterProductVariantIngredientVariationDefaultReplacementsModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	addFilterProductVariantIngredientVariationDefaultReplacement: PropTypes.func,
	filterProductVariant: PropTypes.object,
};

export default FilterProductVariantIngredientVariationDefaultReplacementsModalBatch;
