export default {
	TITLE: 'Product bundles',
	PRODUCT_BUNDLE_ADDED: 'Product bundles added!',
	PRODUCT_BUNDLE_EDITED: 'Product bundle edited!',
	PRODUCT_BUNDLES_EDITED: 'Product bundles edited!',
	PRODUCT_BUNDLE_DELETED: 'Product bundle deleted!',
	PRODUCT_BUDLE_SETS: 'Product bundle sets',
	PRODUCT_BUNDLE_RULES: 'Product bundle rules',
	PRODUCT_BUNDLE_TRANSLATIONS: 'Product bundle translations',

	// batch form
	MODAL_BATCH_STEP_LIST_TITLE: 'Create product bundles',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_SORT_ORDER: 'Sort order',
	MODAL_BATCH_STEP_SORT_ORDER_PLACEHOLDER: 'Enter sort order...',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add Product bundles',
};
