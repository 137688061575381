import {
	UPDATE_WORKPLACE_OPERATIONAL_RESPONSIBILITY,
	RESET_WORKPLACE_OPERATIONAL_RESPONSIBILITY,
} from './workplaceOperationalResponsibilities.actions';

const defaultState = {
	data: {
		workplaceOperationalResponsibilities: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case UPDATE_WORKPLACE_OPERATIONAL_RESPONSIBILITY: {
			return {
				data: {
					...state.data,
					workplaceOperationalResponsibilities: {
						...state.data.workplaceOperationalResponsibilities,
						...payload,
					},
				},
			};
		}

		case RESET_WORKPLACE_OPERATIONAL_RESPONSIBILITY: {
			return defaultState;
		}

		default:
			return state;
	}
}

export default reducer;
