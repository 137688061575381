import { fetchCompleteCollection } from 'api/fetchCompleteCollection';
import { post } from 'api.vanilla.service';
/**
 * @function getOpeningHours
 * @memberOf OpeningHours
 * @description Handler for adding something
 * @param {object}
 * @returns {Promise.<TResult>}
 */
export function getOpeningHours(workplaceId, from, to) {
	// General parameters
	const parameters = {
		from: from.format('YYYY-MM-DD'),
		to: to.format('YYYY-MM-DD')
	};

	const url = `/organization/workplaces/${workplaceId}/opening_hours`;
	return fetchCompleteCollection(url, parameters);
}

/**
 * @function postOpeningHours
 * @memberOf OpeningHours
 * @description Handler for adding something
 * @param {object}
 * @returns {Promise.<TResult>}
 */
export function postOpeningHours(workplaceId, period, data) {
	const url = `/organization/workplaces/${workplaceId}/opening_hours?from=${period.from.format(
		'YYYY-MM-DD'
	)}&to=${period.to.format('YYYY-MM-DD')}`;

	return post(url, data);
}
