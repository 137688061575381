import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';

// Store
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import * as actions from './store/employmentDocuments.actions';

// Components
import FileUpload from 'fileUpload';
import FileList from 'fileList';
import SelectInput from 'react-select';

// Other & Utils
import EmploymentDocuments from './employmentDocuments';
import EmploymentDocumentsFileUpload from './employmentDocuments.fileUpload';
import EmploymentDocumentsFileList from './employmentDocuments.fileList';

import './employmentDocuments.css';

class EmploymentDocumentsContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			options: null,
		};

		// Initiate EmploymentDocuments
		this.EmploymentDocuments = new EmploymentDocuments(props.employmentId, EmploymentDocumentsFileList);

		// Set filter on FileList
		EmploymentDocumentsFileList.setFilter(this.EmploymentDocuments.handleGetEmploymentIdFilter());
	}

	componentDidMount() {
		this.fetchEmploymentPositions();
	}

	componentWillUnmount() {
		this.props.resetState();
	}

	componentDidUpdate(prevProps) {
		if (this.props.employmentPositions !== prevProps.employmentPositions) {
			// Default select
			const general = [
				{
					value: null,
					label: this.EmploymentDocuments.getPhrases().STANDARD_UPLOAD_TITLE,
				},
			];

			// { value: ID, label: company-name }
			const options = this.props.employmentPositions.map((x) => ({
				value: x.id,
				label: x.moneyball_sub_position.name,
			}));
			this.setState(() => ({ options: [...options, ...general] }));
		}
	}

	fetchEmploymentPositions() {
		actions.fetchEmploymentPositions(this.props.personId, this.props.employmentId);
	}

	handleChange(data = {}) {
		const { selectedPosition } = this.props;

		if (!_isEqual(data, selectedPosition)) {
			this.props.selectPosition(data);
		}
	}

	render() {
		const { options } = this.state;
		const { loadingPositionList, selectedPosition } = this.props;

		return (
			<div className="employment-documents">
				{/* @TODO:  loader for component level */}

				<h2>{this.EmploymentDocuments.getPhrases().TITLE}</h2>
				<div className="employment-documents__wrapper">
					<FileUpload
						fileUpload={EmploymentDocumentsFileUpload}
						defaultFileNameData={this.props.defaultFileNameData}
						onChange={(event) => this.EmploymentDocuments.handleFileUploadChange(event, selectedPosition.value)}>
						<div className="employment-documents__options-group">
							<h5 className="employment-documents__position-label">
								{this.EmploymentDocuments.getPhrases().SELECT_OPTION_LABEL}
							</h5>
							<SelectInput
								className="employment-documents__position-select"
								name="employment-documents__position-select"
								clearable={false}
								openOnFocus
								onChange={this.handleChange.bind(this)}
								placeholder={this.EmploymentDocuments.getPhrases().SELECT_OPTION_PLACEHOLDER}
								isLoading={loadingPositionList}
								options={options}
								value={selectedPosition}
							/>
						</div>
					</FileUpload>

					<FileList
						fileList={EmploymentDocumentsFileList}
						selectedPositionId={selectedPosition.value}
						categoryList={options}
					/>
				</div>
			</div>
		);
	}
}

EmploymentDocumentsContainer.propTypes = {
	employmentId: PropTypes.number,
	personId: PropTypes.number,
	defaultFileNameData: PropTypes.object.isRequired,
	employmentPositions: PropTypes.array,
	resetState: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			selectPosition: actions.selectPosition,
			resetState: actions.resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		employmentPositions: store.employmentDocuments.data.employmentPositions,
		loadingPositionList: store.employmentDocuments.ui.loadingPositions,
		selectedPosition: store.employmentDocuments.current.selectedPosition,
	};
};

export default connectWithStore(EmploymentDocumentsContainer, mapStateToProps, mapDispatchToProps);
