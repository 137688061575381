import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './productRecommendationProductVariants.phrases';

/**
 * @function fetchProductRecommendation
 * @description Fetch all product recommendations
 * @param {object} state
 */
export function fetchProductRecommendationProductVariants(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get(
		'/pos/product_recommendation_product_variants',
		params,
		null,
		state.headers
	)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addProductRecommendation
 * @description adds addon recommendation
 */
export function addProductRecommendationProductVariant(data) {
	return post('/pos/product_recommendation_product_variants', data)
		.then(res => {
			setFeedback(phrases.PRODUCT_RECOMMENDATION_PRODUCT_VARIANT_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteProductRecommendation
 * @description deletes addon recommendations
 */
export function deleteProductRecommendationProductVariant(id) {
	return remove(`/pos/product_recommendation_product_variants/${id}`)
		.then(res => {
			setFeedback(phrases.PRODUCT_RECOMMENDATION_PRODUCT_VARIANT_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
