'use strict';

import PropTypes from 'prop-types';
import cx from 'classnames';
import React from 'react';

import RowEntry from './row.entry.component';

const Body = props => {
	const { tables, table, component } = props;

	/**
	 * Parses body from table content and layout object
	 */
	const parseBody = table.content.map((row, key) =>
		row.data ? (
			<RowEntry
				key={`${row.type}_${key}`}
				tables={tables}
				context="body"
				data={row.data}
				meta={row}
				component={component}
				modifierClassNames={cx({ 'report-old__entry--odd': key % 2 === 0 })}
			/>
		) : null
	);

	return <tbody>{parseBody}</tbody>;
};

Body.propTypes = {
	component: PropTypes.object.isRequired,
	table: PropTypes.object.isRequired,
	tables: PropTypes.object.isRequired
};

export default Body;
