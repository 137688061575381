'use strict';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import _has from 'lodash/has';
import _every from 'lodash/every';
import _isNull from 'lodash/isNull';
import _isEmpty from 'lodash/isEmpty';
import _isNaN from 'lodash/isNaN';

import _some from 'lodash/some';
import _map from 'lodash/map';
import _each from 'lodash/each';
import _without from 'lodash/without';
import _get from 'lodash/get';
import { withNavigateAndLocation } from 'services/utils';

import { connectWithStore } from 'appState';
import { copyToClipboard } from 'utils';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import './details.css';

// Reusable components
import DataInput from 'reusableComponents/dataInput/dataInput.component';
import { Tooltip } from 'dumb/index';

class Details extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			submitted: false,
		};

		this.update = this.update.bind(this);
		this.back = this.back.bind(this);
		this.delete = this.delete.bind(this);
		this.new = this.new.bind(this);
		this.onBoardingLink = this.onBoardingLink.bind(this);
	}

	/**
	 * @function update
	 * @memberOf DIRECTIVES.jtjDetails.component
	 * @description Saves new entry
	 * @param {object} event The click event
	 */
	update(event) {
		event.preventDefault();
		const args = {};
		const headers = {};
		let header, name, value;

		// Set as submitted to activate validation
		this.setState({ submitted: true });

		// Check for validity
		const valid = _every(this.refs, function (ref) {
			return ref.getValidity();
		});
		if (valid) {
			// Loop through each header and build header object
			_each(this.refs, function (ref) {
				// Only add edit data to object if actually touched or checkbox
				const validToAdd = [ref.isTouched(), ref._getInputFamily() === 'checkbox'];
				if (_some(validToAdd)) {
					// All contents from header model
					header = ref.getDefaultData();

					// Value of whatever type input field
					value = ref.getValue();

					// Name
					name = _has(header, 'reference') ? header.reference.newName : header.name;

					// Build headers object with old data and new data
					headers[name] = header;
					headers[name].data = value;
				}
			});

			// Add to args
			args.headers = headers;
			if (this.props.isCreating) args.type = 'create';
			else if (this.props.isEditing) {
				args.type = 'edit';
				args.id = this.props.item.id;
			}

			// Broadcast to Angular
			this.props.methods.handleCreateEditItem(args);
		} else this.props.methods.feedback(this.props.translations.INVALID_FIELDS, 0); // Send feedback to user
	}

	/**
	 * @function back
	 * @memberOf DIRECTIVES.jtjDetails.component
	 * @description Navigate back
	 */
	back() {
		this.props.navigate(-1);
	}

	onBoardingLink() {
		const onBoardingLink = `https://joejuice.typeform.com/to/sjC5NR?onboarding=${this.props.item.id}`;

		try {
			copyToClipboard(onBoardingLink);
			this.setState(() => ({ copied: true }));
		} catch (error) {
			setFeedback('Copying didnt work. Grap it here: ' + onBoardingLink, 0, 15000);
		}
	}

	/**
	 * @function delete
	 * @memberOf DIRECTIVES.jtjDetails.component
	 * @description Deletes item
	 * @param {object} item The item object to delete
	 */
	delete(item) {
		this.props.methods.handleDeleteItem(item);
	}

	/**
	 * @function new
	 * @memberOf DIRECTIVES.jtjDetails.component
	 * @description Navigates to new instance of same page
	 */
	new() {
		const url = window.location.pathname;

		const lastSegment = url.substring(url.lastIndexOf('/') + 1);
		const newUrl = _isNaN(lastSegment) ? url : url.slice(0, url.lastIndexOf('/'));
		this.props.navigate(`${newUrl}/new`);
	}

	/**
	 * @function _inputWrapperClassNames
	 * @memberOf DIRECTIVES.jtjDetails.component
	 * @description Class names
	 */
	_inputWrapperClassNames() {
		return cx('details__form-field', {
			'state--submitted': this.state.submitted,
		});
	}

	/**
	 * @function _renderMainAction
	 * @memberOf DIRECTIVES.jtjDetails.component
	 * @description Renders main action button for form based on various expressions
	 */
	_renderMainAction() {
		const validExpressions = {
			create: [this.props.isCreating, this.props.settings.canCreate],
			edit: [this.props.isEditing, this.props.settings.canEdit],
		};

		if (_every(validExpressions.create)) {
			return (
				<button className="button details__button details__button--submit" type="submit">
					{this.props.translations.CREATE}
				</button>
			);
		} else if (_every(validExpressions.edit)) {
			return (
				<button className="button details__button details__button--submit" type="submit">
					{this.props.translations.SAVE}
				</button>
			);
		}
	}

	componentWillUnmount() {
		this.props.methods.handleClearData();
	}

	// componentDidMount() {
	// 	this.props.methods.handleInitView();
	// }

	/**
	 * @function _renderNewAction
	 * @memberOf DIRECTIVES.jtjDetails.component
	 * @description Renders delete button for form if allowed
	 */
	_renderNewAction() {
		const validExpressions = [this.props.isEditing, this.props.settings.canCreate];
		if (!_every(validExpressions)) return null;

		return (
			<a onClick={this.new} className="button details__button details__button--other">
				{this.props.translations.NEW}
			</a>
		);
	}

	/**
	 * @function _renderDeleteAction
	 * @memberOf DIRECTIVES.jtjDetails.component
	 * @description Renders delete button for form if allowed
	 */
	_renderDeleteAction() {
		const validExpressions = [this.props.isEditing, this.props.settings.canDelete];
		if (!_every(validExpressions)) return null;
		return (
			<a onClick={this.delete.bind(this, this.props.item)} className="button details__button details__button--other">
				{this.props.translations.DELETE}
			</a>
		);
	}

	_conditionalClass(action, item) {
		if (!_has(action, 'conditionalClassNames')) return;

		const conditionalClassNamesObj = {};
		action.conditionalClassNames.map((c) => {
			conditionalClassNamesObj[c.className] = c.condition(item[c.conditionalHeader], item);
		});

		return conditionalClassNamesObj;
	}

	/**
	 * @function _renderListActions
	 * @memberOf DIRECTIVES.jtjList.component
	 * @description Renders all actions for current list based on listActions from state
	 */
	_renderCustomActions() {
		return _without(
			_map(this.props.detailsActions(this.props), (action, key) => {
				const buttonClass = cx('button details__button details__button--other', {
					'button--pink': action.active && _every(action.active),
					...this._conditionalClass(action, this.props.item),
				});

				const translations = action.translation
					? action.translation(this.props.item)
					: this.props.translations[action.name.toUpperCase()];

				return (
					<button key={key} type="button" onClick={action.method.bind(this)} className={buttonClass}>
						{translations}
					</button>
				);
			}),
			undefined
		);
	}

	/**
	 * @function _renderInputs
	 * @memberOf DIRECTIVES.jtjDetails.component
	 * @description Renders input fields for details component
	 */
	_renderInputs() {
		return this.props.headers.map((header, headerKey) => {
			const name = _has(header, 'reference') ? header.reference.newName : header.name;
			const spanClass = _has(header, 'span')
				? 'details__form-field--span-' + header.span
				: 'details__form-field--span-2';
			const validExpressions = {
				create: [this.props.isCreating, !header.hidden],
				edit: [this.props.isEditing, !_isEmpty(this.props.item), !header.hidden],
				enabled: [
					this.props.isCreating && header.canCreate,
					this.props.isEditing && header.canEdit,
					(this.props.isEditing || this.props.isCreating) && header.canFillIfNull && _isNull(this.props.item[name]),
				],
			};

			if (_every(validExpressions.create) || _every(validExpressions.edit)) {
				const disabled = !_some(validExpressions.enabled) || header.disabled === true;
				const key = _isEmpty(this.props.item) ? headerKey : this.props.item.id.toString() + headerKey;
				return (
					<fieldset key={key} className={cx(this._inputWrapperClassNames(), spanClass)}>
						<label className="details__label">{header.label}</label>
						<DataInput
							{...this.props}
							header={header}
							ref={name}
							submitted={this.state.submitted}
							disabled={disabled}
							item={this.props.item}
						/>
					</fieldset>
				);
			}
		});
	}

	render() {
		// if (_isEmpty(this.props.item)) return null;

		return (
			<form noValidate className="details" onSubmit={this.update} autoComplete="off">
				<div className="details__form-field-wrapper">{this._renderInputs()}</div>

				<div className="details__button-wrapper">
					{this._renderMainAction()}
					{this._renderNewAction()}
					{this._renderDeleteAction()}

					{this._renderCustomActions()}
					<Tooltip
						text={'Copy onboarding link to clipboard'}
						zIndex="600"
						renderData={(ref, onMouseEnter, onMouseLeave) => (
							<button
								shadow
								ref={ref}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
								onClick={this.onBoardingLink}
								type="button"
								className={'button details__button details__button--other'}>
								{this.state.copied ? 'Copied' : 'Copy onboarding link'}
							</button>
						)}
					/>

					<a onClick={this.back} className="button details__button details__button--other details__button--back">
						{this.props.translations.BACK}
					</a>
				</div>
			</form>
		);
	}
}

Details.propTypes = {
	headers: PropTypes.array.isRequired,
	isCreating: PropTypes.bool,
	isEditing: PropTypes.bool,
	item: PropTypes.object,
	methods: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
	translations: PropTypes.object.isRequired,
};

Details.defaultProps = {
	detailsActions: () => {},
	translations: {},
};

const mapStateToPropsFactory = (initialStore, ownProps) => (store) => {
	return {
		data: _get(store, `legacyDetailsData.[${ownProps.reduxKey}].data`, {}),
		headers: _get(store, `legacyDetailsData.[${ownProps.reduxKey}].headers`, []),
		isCreating: _get(store, `legacyDetailsData.[${ownProps.reduxKey}].isCreating`, false),
		isEditing: _get(store, `legacyDetailsData.[${ownProps.reduxKey}].isEditing`, false),

		params: _get(store, `legacyDetailsData.[${ownProps.reduxKey}].params`, {}),
		item: _get(store, `legacyDetailsData.[${ownProps.reduxKey}].item`, {}),
		itemId: _get(store, `legacyDetailsData.[${ownProps.reduxKey}].itemId`, NaN),
	};
};

export default withNavigateAndLocation(connectWithStore(Details, mapStateToPropsFactory));
