'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';
import { Tip } from 'dumb/tips';

// page building blocks
import CopyPowerBiReportModalMarkets from './components/copyPowerBiReportModalMarkets';
import CopyPowerBiReportModalGlobalList from './components/copyPowerBiReportModalGlobalList';
import CopyPowerBiReportModalSelectedReports from './components/copyPowerBiReportModalSelectedReports';

// utils
import { mapReportsToMarkets, getPayloadToSubmit } from './utils';
import _isEmpty from 'lodash/isEmpty';

// phrases
import phrases from './../../powerBIReports.phrases';

// styles
import './copyPowerBiReportModal.css';

class CopyPowerBiReportModal extends Component {
	constructor(props) {
		super(props);

		this.modalRef = React.createRef();

		this.state = {
			loading: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleEditSelectedValue = this.handleEditSelectedValue.bind(this);
		this.handleEditGlobalValue = this.handleEditGlobalValue.bind(this);
		this.goToStep = this.goToStep.bind(this);
		this.onFirstStepDone = this.onFirstStepDone.bind(this);
		this.goToFirstStep = this.goToFirstStep.bind(this);
	}

	goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	handleEditGlobalValue(event, name) {
		const { editGlobalCopyReportValue } = this.props;

		const value = event?.target?.value ?? event;

		const payload = {
			[name]: value,
		};

		editGlobalCopyReportValue(payload);
	}

	handleEditSelectedValue(event, name, id) {
		const { editSelectedPowerBiReport } = this.props;

		const value = event?.target?.value ?? event;

		const payload = {
			id,
			[name]: value,
		};

		editSelectedPowerBiReport(payload);
	}

	handleSubmit() {
		const {
			selectedReports,
			onClose,
			onSubmit,
			originalSelectedReports,
		} = this.props;

		this.setState({ loading: true });

		const payload = getPayloadToSubmit({
			selectedReports,
			originalSelectedReports,
		});

		onSubmit({ batch: payload })
			.then(() => {
				this.setState({ loading: false });
				onClose();
			})
			.catch(() => {
				this.setState({ loading: false });
			});
	}

	onFirstStepDone() {
		const { markets, selectedReports, setSelectedPowerBiReports } = this.props;

		if (!_isEmpty(markets)) {
			const mappedReports = mapReportsToMarkets({
				markets: markets,
				reports: selectedReports,
			});

			setSelectedPowerBiReports(mappedReports);
		}

		this.goToStep(2);
	}

	goToFirstStep() {
		this.goToStep(1);
	}

	render() {
		const {
			selectedReports,
			globalPowerBiReport,
			onClose,
			markets,
			editMarkets,
		} = this.props;

		const steps = [
			{
				component: (
					<CopyPowerBiReportModalMarkets
						markets={markets}
						editMarkets={editMarkets}
					/>
				),
				title: phrases.MODAL_GLOBAL_MARKETS_TITLE,
				isValid: true,
				onNext: this.onFirstStepDone,
				loading: false,
				confirmButtonLabel: phrases.MODAL_GLOBAL_MARKETS_BUTTON_LABEL,
				noScroll: false,
				allowOverflow: true,
			},
			{
				component: (
					<>
						<div className="copy-power-bi-report-modal__tip-wrapper">
							<Tip content={phrases.MODAL_COPY_REPORT_TIP} />
						</div>

						<CopyPowerBiReportModalGlobalList
							globalPowerBiReport={globalPowerBiReport}
							handleEditGlobalValue={this.handleEditGlobalValue}
						/>

						<div className="copy-power-bi-report-modal--top-margin">
							<CopyPowerBiReportModalSelectedReports
								selectedReports={selectedReports}
								handleEditSelectedValue={this.handleEditSelectedValue}
							/>
						</div>
					</>
				),
				title: phrases.MODAL_COPY_REPORT_TITLE,
				isValid: true,
				onNext: this.handleSubmit,
				onBack: this.goToFirstStep,
				loading: false,
				confirmButtonLabel: phrases.MODAL_COPY_REPORT_CONFIRM,
				cancelButtonLabel: phrases.MODAL_COPY_REPORT_BACK,
				noScroll: false,
				allowOverflow: false,
			},
		];

		return (
			<ModalStepper
				ref={this.modalRef}
				isOpen
				steps={steps}
				showStep={false}
				onClose={onClose}
			/>
		);
	}
}

CopyPowerBiReportModal.propTypes = {
	selectedReports: PropTypes.array,
	originalSelectedReports: PropTypes.array,
	globalPowerBiReport: PropTypes.object,
	editGlobalCopyReportValue: PropTypes.func,
	editSelectedPowerBiReport: PropTypes.func,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	editMarkets: PropTypes.func,
	setSelectedPowerBiReports: PropTypes.func,
	markets: PropTypes.array,
};

export default CopyPowerBiReportModal;
