'use strict';

import Metadata from './metadata.metadata';
import Title from './metadata.title';
import Subtitle from './metadata.subtitle';
import PrintTitle from './metadata.printTitle';

class MetadataFactory {

	/**
	 * @function titles
	 * @memberOf MetadataFactory
	 * @param  {object} title
	 * @param  {object} subtitle
	 * @param  {object} printTitle
	 */
	metadata(title, subtitle, printTitle) {
		return new Metadata(title, subtitle, printTitle);
	}

	/**
	 * @function title
	 * @memberOf MetadataFactory
	 * @param  {*} template
	 */
	title(template) {
		return new Title(template);
	}

	/**
	 * @function subtitle
	 * @memberOf MetadataFactory
	 * @param  {*} template
	 */
	subtitle(template) {
		return new Subtitle(template);
	}

	/**
	 * @function printTitle
	 * @memberOf MetadataFactory
	 * @param  {*} template
	 */
	printTitle(template) {
		return new PrintTitle(template);
	}

}

export default MetadataFactory;