import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';

import PersonAttributeTypesList from './services/personAttributeTypesList.service.js';

const personAttributeTypesListReduxKey = '/hr/person_attribute_types';
const personAttributeTypesList = new PersonAttributeTypesList(personAttributeTypesListReduxKey);
const personAttributeTypesListProps = personAttributeTypesList.getProps();

export function PersonAttributeTypes() {
	useEffect(() => {
		personAttributeTypesList.initView();
	}, []);

	return <ListComponent reduxKey={personAttributeTypesListReduxKey} {...personAttributeTypesListProps} />;
}
