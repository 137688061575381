import React, { Component } from 'react';

// Components
import NutritionCategories from './components/nutritionCategories/nutritionCategories.component';
import NutritionValues from './components/nutritionValues/nutritionValues.component';
import IngredientAllergens from './components/ingredientAllergens/ingredientAllergens.component';
// import Allergens from './components/allergens/allergens.component';
import AllergenCategories from './components/allergenCategories/allergenCategories.component';

class NutritionContainer extends Component {
	render() {
		return (
			<>
				<NutritionCategories />
				<NutritionValues />
				{/* GLOBAL meaning that we're using the global resource for this table (excludes market) */}
				<NutritionValues useGlobalResource />
				<AllergenCategories />
				<IngredientAllergens />
				{/* GLOBAL meaning that we're using the global resource for this table (excludes market) */}
				<IngredientAllergens useGlobalResource />
			</>
		);
	}
}

NutritionContainer.propTypes = {};

export default NutritionContainer;
