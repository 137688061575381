import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './shiftTransferPolicies.phrases';

/**
 * @function fetchShiftTransferPolicies
 * @description Fetch all shift transfer policies
 * @param {object} state
 */
export function fetchShiftTransferPolicies(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get(
		'/shiftplanning/shift_transfer_policies',
		params,
		null,
		state.headers
	)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addShiftTransferPolicy
 * @description adds shift transfer policies
 */
export function addShiftTransferPolicy(data) {
	return post('/shiftplanning/shift_transfer_policies', data)
		.then((res) => {
			setFeedback(phrases.SHIFT_TRANSFER_POLICY_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editShiftTransferPolicy
 * @description edits shift transfer policies
 */
export function editShiftTransferPolicy(data) {
	return post(`/shiftplanning/shift_transfer_policies/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.SHIFT_TRANSFER_POLICY_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function deleteShiftTransferPolicy
 * @description deletes shift transfer policies
 */
export function deleteShiftTransferPolicy(productId) {
	return remove(`/shiftplanning/shift_transfer_policies/${productId}`)
		.then((res) => {
			setFeedback(phrases.SHIFT_TRANSFER_POLICY_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
