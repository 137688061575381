import {
	UPDATE_DOCUSIGN_TEMPLATE_DOCUMENT_FIELD,
	SET_DOCUSIGN_TEMPLATE_DOCUMENT_FIELD,
	RESET_STATE,
} from './docuSignTemplateDocumentFields.actions';

const defaultState = {
	data: {
		docusignTemplateDocumentField: {},
		defaultDocusignTemplateDocumentField: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_DOCUSIGN_TEMPLATE_DOCUMENT_FIELD: {
			return {
				data: {
					...state.data,
					docusignTemplateDocumentField: payload,
					defaultDocusignTemplateDocumentField: payload,
				},
			};
		}

		case UPDATE_DOCUSIGN_TEMPLATE_DOCUMENT_FIELD: {
			return {
				data: {
					...state.data,
					docusignTemplateDocumentField: {
						...state.data.docusignTemplateDocumentField,
						...payload,
					},
				},
			};
		}

		case RESET_STATE: {
			return {
				...defaultState,
			};
		}

		default:
			return state;
	}
}

export default reducer;
