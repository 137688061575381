import React from 'react';
import PropTypes from 'prop-types';

// components
import { Modal, InputCleave, Icon, Button } from 'dumb';
import { ModalRow } from 'dumb/modal';

// phrases
import phrases from './../../homeScreenRecommendations.phrases';

const AddTimeSlotModal = ({
	onClose,
	batchTimeSlots,
	updateBatchTimeSlot,
	addNewTimeSlotRow,
	deleteTimeSlotRow,
	onConfirmClick,
	loading,
}) => {
	const updateTimeSlotEntry = (value, identifier, type) => {
		const payload = {
			identifier,
			[type]: value,
		};

		updateBatchTimeSlot(payload);
	};

	const deleteRowButtonDisabled = batchTimeSlots.length === 1;

	return (
		<Modal
			isOpen
			loading={loading}
			onClose={onClose}
			onConfirmClick={onConfirmClick}
			header={phrases.ADD_TIME_SLOT}>
			{batchTimeSlots.map((entry, index) => (
				<ModalRow key={index}>
					<div className="home-screen-recommendations__modal-row">
						<InputCleave
							id={`time-from-${index}`}
							tabIndex={1}
							label={phrases.TIME_FROM}
							options={{
								time: true,
								timePattern: ['h', 'm'],
							}}
							value={entry.timeFrom}
							placeholder="hh:mm"
							onChange={(event) =>
								updateTimeSlotEntry(
									event.target.value,
									entry.identifier,
									'timeFrom'
								)
							}
							autoComplete="off"
						/>
						<InputCleave
							id={`time-to-${index}`}
							tabIndex={1}
							label={phrases.TIME_TO}
							options={{
								time: true,
								timePattern: ['h', 'm'],
							}}
							value={entry.timeTo}
							placeholder="hh:mm"
							onChange={(event) =>
								updateTimeSlotEntry(
									event.target.value,
									entry.identifier,
									'timeTo'
								)
							}
							autoComplete="off"
						/>
						<Button
							type="negative"
							onClick={() =>
								deleteTimeSlotRow({ identifier: entry.identifier })
							}
							disabled={deleteRowButtonDisabled}>
							<Icon name="delete" />
						</Button>
					</div>
				</ModalRow>
			))}
			<ModalRow
				typeAddNewRow
				tooltipText={phrases.ADD_NEW_ROW_TOOLTIP}
				tooltipZIndex={600}
				onClick={addNewTimeSlotRow}
			/>
		</Modal>
	);
};

AddTimeSlotModal.propTypes = {
	onClose: PropTypes.func,
	updateBatchTimeSlot: PropTypes.func,
	addNewTimeSlotRow: PropTypes.func,
	deleteTimeSlotRow: PropTypes.func,
	onConfirmClick: PropTypes.func,
	batchTimeSlots: PropTypes.array,
	loading: PropTypes.bool,
};

export default AddTimeSlotModal;
