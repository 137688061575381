import {
	TOGGLE_MODAL,
	SET_MODAL_DATA,
	UPDATE_MODAL_DATA,
	SET_RESPONSIBILITY,
	UPDATE_RESPONSIBILITY,
	RESET_RESPONSIBILITY,
} from './operationalResponsibilityOverview.actions';

const defaultState = {
	modalVisible: false,
	modalData: {
		type: null,
		data: {},
		originalResponsibility: {},
		responsibility: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case TOGGLE_MODAL:
			return {
				...state,
				modalVisible: !state.modalVisible,
			};

		case SET_MODAL_DATA:
			return {
				...state,
				modalData: {
					...state.modalData,
					type: payload.type,
					data: payload.data,
				},
			};

		case UPDATE_MODAL_DATA:
			return {
				...state,
				modalData: {
					...state.modalData,
					data: {
						...state.modalData.data,
						...payload,
					},
				},
			};

		case SET_RESPONSIBILITY:
			return {
				...state,
				modalData: {
					...state.modalData,
					originalResponsibility: payload,
					responsibility: payload,
				},
			};

		case UPDATE_RESPONSIBILITY:
			return {
				...state,
				modalData: {
					...state.modalData,
					responsibility: {
						...state.modalData.responsibility,
						...payload,
					},
				},
			};

		case RESET_RESPONSIBILITY:
			return {
				...state,
				modalData: {
					...state.modalData,
					responsibility: defaultState.modalData.responsibility,
					originalResponsibility: defaultState.modalData.originalResponsibility,
				},
			};

		default:
			return state;
	}
}

export default reducer;
