'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	resetState,
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem
} from './store/ingredients.actions';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Button, Icon } from 'dumb';
import SalesConfigurationDiscountIngredientsModalBatch from './components/batch/salesConfigurationDiscountIngredientsModalBatch';

// lodash
import _get from 'lodash/get';

import {
	fetchDiscountIngredients,
	addDiscountIngredient,
	deleteDiscountIngredient,
	fetchIngredients
} from './ingredients.service';

import phrases from './ingredients.phrases';

class Ingredients extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false
		};

		this.deleteEntry = this.deleteEntry.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.toggleModal = this.toggleModal.bind(this);

		this.reduxKey = '/sale/discount_ingredients';

		this.columns = [
			{
				Header: 'Ingredient',
				id: 'name',
				accessor: d => _get(d, 'ingredient.name', null),
				filterPath: ':ingredient.name'
			}
		];
	}

	deleteEntry(id) {
		return deleteDiscountIngredient(id);
	}

	fetchData(state) {
		const { discount } = this.props;
		return fetchDiscountIngredients(discount.id, state);
	}

	toggleModal() {
		this.setState(prevState => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	render() {
		const {
			updateBatchForm,
			batchFormData,
			listIngredients,
			addBatchList,
			batchList,
			editBatchListItem,
			removeBatchListItem,
			discount
		} = this.props;

		return (
			<>
				<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={`${this.reduxKey}/${this.props.discount.id}`}
					manual
					onModalClose={this.props.resetState}
					deleteEntry={this.deleteEntry}
					actionRender={
						<Button
							type="inverted"
							label="Add"
							shadow
							onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
				/>

				<SalesConfigurationDiscountIngredientsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchFormData={batchFormData}
					updateBatchForm={updateBatchForm}
					fetchDiscountIngredients={fetchDiscountIngredients}
					fetchIngredients={fetchIngredients}
					listIngredients={listIngredients}
					addBatchList={addBatchList}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					batchAddData={addDiscountIngredient}
					discount={discount}
				/>
			</>
		);
	}
}

Ingredients.propTypes = {
	discount: PropTypes.object,
	resetState: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	listIngredients: PropTypes.array,
	addBatchList: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			resetState,
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem
		},
		dispatch
	);
};

const mapStateToProps = (initialStore, ownProps) => store => {
	return {
		dataList:
			store.listData[`/sale/discount_ingredients/${ownProps.discount.id}`],
		batchFormData: store.discountsIngredients.data.batchFormData,
		batchList: store.discountsIngredients.data.batchList,
		listIngredients: store.listData['/product/ingredients']
	};
};

export default connectWithStore(
	Ingredients,
	mapStateToProps,
	mapDispatchToProps
);
