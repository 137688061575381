import { CLEAR_GLOBAL_NOTIFICATOINS, ADD_GLOBAL_NOTIFICATIONS } from '../actions/globalNotifications.actions';

const defaultState = {
	notifications: [],
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case ADD_GLOBAL_NOTIFICATIONS:
			return {
				...state,
				notifications: payload,
			};

		case CLEAR_GLOBAL_NOTIFICATOINS:
			return {
				...state,
				notifications: defaultState.notifications,
			};

		default:
			return state;
	}
}

export default reducer;
