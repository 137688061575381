'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import { Button, Input, Icon } from 'dumb';

import _get from 'lodash/get';

import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import * as actions from 'reactDataWrapper/reactDataWrapper.actions';
import {
	setPosConfigurationDiscountProductVariant,
	updatePosConfigurationDiscountProductVariant,
	resetPosConfigurationDiscountProductVariant,
} from './store/posConfigurationDiscountProductVariants.actions';

import {
	fetchPosConfigurationDiscounts,
	fetchPosConfigurationDiscountProductVariants,
	editPosConfigurationDiscountProductVariants,
	addPosConfigurationDiscountProductVariants,
	deletePosConfigurationDiscountProductVariants,
} from './posConfigurationDiscountProductVariants.service';

import phrases from './posConfigurationDiscountProductVariants.phrases';

import { mergeIngredientDiscounts } from './utils/posConfigurationDiscountProductVariants.util';

class PosConfigurationDiscountProductVariants extends Component {
	constructor(props) {
		super(props);

		this._deleteEntry = this._deleteEntry.bind(this);
		this._editPrice = this._editPrice.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.fetchData = this.fetchData.bind(this);

		this.state = {
			pages: null,
			loading: true,
		};

		this.reduxKey = '/pos_configuration_discount_product_variants';
		this.columns = [
			{
				Header: 'Product name',
				id: 'ingredientAssociatedDiscountsProductName',
				accessor: (d) => _get(d, 'name', null),
				filterPath: ':name',
			},
			{
				Header: 'To Stay Price',
				width: 80,
				id: 'ingredientAssociatedDiscountsToStayPrice',
				accessor: (d) => _get(d, 'discounts.to_stay_discount_price', null),
				sortable: false,
				filterable: false,
			},
			{
				Header: 'To Go Price',
				width: 80,
				id: 'ingredientAssociatedDiscountsToGoPrice',
				accessor: (d) => _get(d, 'discounts.to_go_discount_price', null),
				sortable: false,
				filterable: false,
			},
		];
	}

	editEntry() {
		const {
			productVariantId,
			posConfigurationDiscountProductVariantAssociatedDiscount,
		} = this.props;
		// if to stay or to go present edit, otherwise add new
		const toStay = _get(
			posConfigurationDiscountProductVariantAssociatedDiscount,
			'to_stay_discount_price',
			null
		);
		const toGo = _get(
			posConfigurationDiscountProductVariantAssociatedDiscount,
			'to_go_discount_price',
			null
		);
		const discountVariantId = _get(
			posConfigurationDiscountProductVariantAssociatedDiscount,
			'discounts.id',
			null
		);

		// if submitted and no prices changed, return
		if (!toStay && !toGo) return Promise.resolve(false);

		let payload = {
			...(toStay && { to_stay_discount_price: Number(toStay) }),
			...(toGo && { to_go_discount_price: Number(toGo) }),
		};

		// edit
		if (discountVariantId) {
			return editPosConfigurationDiscountProductVariants(
				discountVariantId,
				payload
			).then((res) => {
				// replace old discount value with edited and return
				return {
					data: [
						{
							...posConfigurationDiscountProductVariantAssociatedDiscount,
							discounts: res.data[0],
						},
					],
				};
			});
		}
		// add new
		else {
			payload = {
				...payload,
				pos_configuration_product_variant: productVariantId,
				pos_configuration_discount:
					posConfigurationDiscountProductVariantAssociatedDiscount.id,
			};

			return addPosConfigurationDiscountProductVariants(payload).then((res) => {
				return {
					data: [
						{
							...posConfigurationDiscountProductVariantAssociatedDiscount,
							discounts: res.data[0],
						},
					],
				};
			});
		}
	}

	setInitialEditValues(data) {
		this.props.setPosConfigurationDiscountProductVariant(data);
	}

	getEditableCells() {
		const {
			posConfigurationDiscountProductVariantAssociatedDiscount,
		} = this.props;

		return [
			{
				header: phrases.MODAL_HEADER_PRODUCT_NAME,
				value: (
					<span>
						{_get(
							posConfigurationDiscountProductVariantAssociatedDiscount,
							'name',
							'Name'
						)}
					</span>
				),
			},
			{
				header: phrases.MODAL_HEADER_TO_STAY_PRICE,
				value: (
					<Input
						type="number"
						placeholder="123,56"
						min="0"
						onChange={(event) =>
							this._editPrice('to_stay_discount_price', event)
						}
						defaultValue={_get(
							posConfigurationDiscountProductVariantAssociatedDiscount,
							'discounts.to_stay_discount_price',
							''
						)}
					/>
				),
			},
			{
				header: phrases.MODAL_HEADER_TO_GO_PRICE,
				value: (
					<Input
						type="number"
						placeholder="123,56"
						onChange={(event) => this._editPrice('to_go_discount_price', event)}
						min="0"
						defaultValue={_get(
							posConfigurationDiscountProductVariantAssociatedDiscount,
							'discounts.to_go_discount_price',
							''
						)}
					/>
				),
			},
		];
	}

	_editPrice(name, e) {
		const payload = {
			[name]: e.target.value,
		};

		this.props.updatePosConfigurationDiscountProductVariant(payload);
	}

	fetchData(state) {
		const { posConfigurationId, productVariantId } = this.props;
		this.setState(() => ({ loading: true }));

		return fetchPosConfigurationDiscounts(posConfigurationId, state)
			.then((res) => {
				if (res.data.length === 0) {
					this.setState(() => ({ loading: false }));
					return;
				}

				return fetchPosConfigurationDiscountProductVariants(
					productVariantId,
					''
				)
					.then((values) => {
						this.setState(() => ({ loading: false }));
						return mergeIngredientDiscounts(res, values);
					})
					.catch((err) => {
						const errorMessage = formatErrorMessage(err);
						setFeedback(errorMessage, 0);
					});
			})
			.catch(() => {
				this.setState(() => ({ loading: false }));
			});
	}

	_deleteEntry(object, e) {
		const { editEntry } = this.props;

		e.stopPropagation();

		return deletePosConfigurationDiscountProductVariants(
			object.discounts.id
		).then(() => {
			editEntry({
				reduxKey: this.reduxKey,
				entry: {
					...object,
					discounts: {},
				},
			});
		});
	}

	renderDeleteButton(d) {
		const id = _get(d, 'original.discounts.id', null);
		return id ? (
			<Button
				type="inverted"
				shadow
				onClick={(e) => this._deleteEntry(d.original, e)}
				size="micro">
				<Icon name="delete" />
			</Button>
		) : null;
	}

	render() {
		return (
			<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.TABLE_TITLE}
				columns={this.columns}
				totalEntries={this.state.totalEntries}
				loading={this.state.loading}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={10}
				reduxKey={this.reduxKey}
				manual
				editEntry={() => this.editEntry()}
				setInitialEditValues={this.setInitialEditValues}
				editableCells={this.getEditableCells()}
				onModalClose={this.props.resetPosConfigurationDiscountProductVariant}
				actions={(d) => this.renderDeleteButton(d)}
				actionsWidth={90}
				inRowButtons
				{...this.state}
			/>
		);
	}
}

PosConfigurationDiscountProductVariants.propTypes = {
	productVariantId: PropTypes.number,
	posConfigurationId: PropTypes.number,
	posConfigurationDiscountProductVariantAssociatedDiscount: PropTypes.object,

	updatePosConfigurationDiscountProductVariant: PropTypes.func,
	setPosConfigurationDiscountProductVariant: PropTypes.func,
	resetPosConfigurationDiscountProductVariant: PropTypes.func,
	editEntry: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setPosConfigurationDiscountProductVariant,
			updatePosConfigurationDiscountProductVariant,
			resetPosConfigurationDiscountProductVariant,
			editEntry: actions.editEntry,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		posConfigurationDiscountProductVariantAssociatedDiscount:
			store.posConfigurationDiscountProductVariants.data
				.posConfigurationDiscountProductVariantAssociatedDiscount,
	};
};

export default connectWithStore(
	PosConfigurationDiscountProductVariants,
	mapStateToProps,
	mapDispatchToProps
);
