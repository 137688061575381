import {
	SET_WORKPLACE_EXPECTED_WAITING_TIMES,
	UPDATE_WORKPLACE_EXPECTED_WAITING_TIMES,
	RESET_WORKPLACE_EXPECTED_WAITING_TIMES,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	ADD_BATCH_LIST_ITEM_ROW,
} from './workplaceExpectedWaitingTimes.actions';

// utils
import _uniqueId from 'lodash/uniqueId';

const defaultState = {
	data: {
		defaultWorkplaceExpectedWaitingTimes: {},
		batchList: [
			{
				identifier: _uniqueId('row-'),
			},
		],
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_WORKPLACE_EXPECTED_WAITING_TIMES: {
			return {
				data: {
					...state.data,
					defaultWorkplaceExpectedWaitingTimes: payload,
				},
			};
		}

		case UPDATE_WORKPLACE_EXPECTED_WAITING_TIMES: {
			return {
				data: {
					...state.data,
					defaultWorkplaceExpectedWaitingTimes: {
						...state.data.defaultWorkplaceExpectedWaitingTimes,
						...payload,
					},
				},
			};
		}

		case RESET_WORKPLACE_EXPECTED_WAITING_TIMES: {
			return defaultState;
		}

		case EDIT_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchList: state.data.batchList.map((entry) => {
						if (entry.identifier === payload.identifier)
							return { ...entry, ...payload };
						return entry;
					}),
				},
			};
		}

		case REMOVE_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchList: state.data.batchList.filter(
						(entry) => entry.identifier !== payload
					),
				},
			};
		}

		case ADD_BATCH_LIST_ITEM_ROW: {
			return {
				...state,
				data: {
					...state.data,
					batchList: [
						...state.data.batchList,
						{
							...defaultState.data.batchList[0],
							identifier: _uniqueId('row-'),
						},
					],
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
