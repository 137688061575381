export const RESET_PRODUCT =
	'SALES_CONFIGURATION/RESET_PRODUCT';
export const SET_PRODUCT =
	'SALES_CONFIGURATION/SET_PRODUCT';
export const UPDATE_PRODUCT =
	'SALES_CONFIGURATION/UPDATE_PRODUCT';

/**
 * @param {object} payload
 */
export function setProduct(payload) {
	return {
		type: SET_PRODUCT,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateProduct(payload) {
	return {
		type: UPDATE_PRODUCT,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET_PRODUCT
	};
}