import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// components
import { Box, TextField, Autocomplete, Button, CircularProgress, styled, Grid } from '@mui/material';
// misc
import _isEmpty from 'lodash/isEmpty';
import phrases from './workplaceAddress.phrases';

// because I need to access theme object and we cannot use useTheme in class components
const StyledTextField = styled(TextField)(({ theme }) => ({
	width: '100%',
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
	width: '100%',
}));

class WorkplaceAddress extends PureComponent {
	constructor(props) {
		super(props);

		// What should be valid
		this.validate = ['city', 'zip', 'address', 'country'];

		this.state = {
			workplaceData: {
				...props.addressObjectDefault,
			},
			countries: [],
			isCountrySelectLoading: false,
			loading: false,
		};

		this.submitHandler = this.submitHandler.bind(this);
		this._fetchCountriesByValue = this._fetchCountriesByValue.bind(this);
		this.setWorkplaceData = this.setWorkplaceData.bind(this);
	}

	componentDidMount() {
		this._fetchCountriesByValue();

		this.props.getWorkplaceAddress(this.props.workplaceId).then((results) => {
			this.setWorkplaceData(results);
		});
	}

	setWorkplaceData(results) {
		if (_isEmpty(results)) return;
		this.setState({
			workplaceData: {
				...this.state.workplaceData,
				...results,
				country: { label: results.country.name, value: results.country.id },
			},
		});
	}

	_validateInput(activeGroup) {
		return this.validate.map((k) => !!activeGroup[k]).every((x) => x === true);
	}

	submitHandler(event) {
		event.preventDefault();

		this.setState({ loading: true });

		this.props
			.saveAddress(this.state.workplaceData)
			.then((workplaceData) => {
				this.setWorkplaceData(workplaceData);
				this.props.updatePostUpdate();
			})
			.finally(() => this.setState({ loading: false }));
	}

	updateInput(target, value) {
		// Targets that must be parsed into float
		const floatEr = ['latitude', 'longitude'];

		// Targets that must set the marker
		const sendToMarker = ['address', 'zip', 'latitude', 'longitude'];

		if (this.state.workplaceData[target] === value) return;

		if (floatEr.indexOf(target) !== -1) {
			if (value !== '') value = parseFloat(value);
		}

		this.setState(
			() => ({
				workplaceData: {
					...this.state.workplaceData,
					[target]: value,
				},
			}),
			() => {
				if (sendToMarker.indexOf(target) !== -1 && value !== '') {
					if (floatEr.indexOf(target) === -1)
						this.props.markerController(this.state.workplaceData, this.props.markerType.address);
					else this.props.markerController(this.state.workplaceData, this.props.markerType.location);
				}
			}
		);
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		if (newProps.notSavedData) {
			const data = {
				...this.state.workplaceData,
				...newProps.notSavedData,
			};

			this.setState(() => ({ workplaceData: data }));
		}
	}

	_formatCountryData(countries) {
		return countries.map((country) => ({
			label: country.name,
			value: country.id,
		}));
	}

	_fetchCountriesByValue(value) {
		const { fetchCountries } = this.props;

		this.setState(() => ({
			isCountrySelectLoading: true,
		}));

		fetchCountries(value).then((countries) => {
			const formattedCountries = this._formatCountryData(countries.data);
			this.setState(() => ({
				countries: formattedCountries,
				isCountrySelectLoading: false,
			}));
		});
	}

	render() {
		const { countries, workplaceData, loading } = this.state;

		const formIsValid = this._validateInput(workplaceData);

		const saveButtonDisabled = !formIsValid || loading;

		return (
			<form type="form" onSubmit={this.submitHandler}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StyledTextField
							label={phrases.STREET_NAME_AND_NUMBER}
							placeholder="Example Address 3E, 69"
							value={this.state.workplaceData.address}
							onChange={(e) => this.updateInput('address', e.target.value)}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StyledTextField
							label={phrases.ZIP_CODE}
							placeholder="E.g 1100"
							value={this.state.workplaceData.zip}
							onChange={(e) => this.updateInput('zip', e.target.value)}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StyledTextField
							label={phrases.CITY}
							placeholder="E.g København K"
							value={this.state.workplaceData.city}
							onChange={(e) => this.updateInput('city', e.target.value)}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StyledAutocomplete
							id="countries-select"
							value={this.state.workplaceData.country}
							options={countries}
							placeholder="Select a country..."
							isOptionEqualToValue={(option, value) => option.value === value.value}
							onChange={(e, value) => this.updateInput('country', value)}
							getOptionLabel={(option) => option.label}
							renderInput={(params) => (
								<TextField {...params} label={phrases.COUNTRY} placeholder={phrases.COUNTRY_PLACEHOLDER} />
							)}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StyledTextField
							inputProps={{
								sx: {
									'::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
										'-webkit-appearance': 'none',
										margin: 0,
									},
								},
							}}
							type="number"
							label={phrases.LATITUDE}
							placeholder={phrases.LATITUDE_PLACEHOLDER}
							value={this.state.workplaceData.latitude}
							onChange={(e) => this.updateInput('latitude', e.target.value)}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<StyledTextField
							inputProps={{
								sx: {
									'::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
										'-webkit-appearance': 'none',
										margin: 0,
									},
								},
							}}
							type="number"
							label={phrases.LONGITUDE}
							placeholder={phrases.LONGITUDE_PLACEHOLDER}
							value={this.state.workplaceData.longitude}
							onChange={(e) => this.updateInput('longitude', e.target.value)}
						/>
					</Grid>
				</Grid>

				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						my: 1,
					}}
				>
					<Button variant="contained" type="submit" disabled={saveButtonDisabled}>
						{loading ? (
							<CircularProgress sx={{ width: '16px !important', height: '16px !important' }} />
						) : (
							phrases.SAVE_BUTTON
						)}
					</Button>
				</Box>
			</form>
		);
	}
}

WorkplaceAddress.defaultProps = {
	waitingForApi: false,
};

WorkplaceAddress.propTypes = {
	getWorkplaceAddress: PropTypes.func.isRequired,
	fetchCountries: PropTypes.func.isRequired,
	saveAddress: PropTypes.func.isRequired,
	markerController: PropTypes.func.isRequired,
	markerType: PropTypes.object.isRequired,
	addressObjectDefault: PropTypes.object.isRequired,
	notSavedData: PropTypes.object,
	updatePostUpdate: PropTypes.func.isRequired,
	waitingForApi: PropTypes.bool,
	workplaceId: PropTypes.number,
};

export default WorkplaceAddress;
