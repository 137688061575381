'use strict';

import addShiftModal from 'shiftPlanner/components/addShift/addShift.reducer';
import daySettingsModal from 'shiftPlanner/components/daySettings/daySettings.reducer';
import phpReport from 'shiftPlanner/components/phpOverview/store/phpReport.reducer';
import shifts from './shifts.reducer';
import weatherForecast from './shiftPlannerWeather.reducer';
import shiftPlanner from './shiftPlanner.reducer';
import shiftPlannerKPI from 'shiftPlanner/components/shiftplannerKPI/shiftplannerKPI.reducer';
import workDays from 'shiftPlanner/components/dayHeader/services/workDays/workDays.reducer';
import shiftPlannerClockings from './shiftPlannerClockings.reducer';
import shiftPlannerForecasting from 'shiftPlanner/components/weekviewContainer/weekView/forecasting/store/shiftPlannerForecasting.reducer';
import shiftPlannerDayReview from 'shiftPlanner/components/dayReview/store/dayReview.reducer';
import shiftPlannerWorkplaceAdministration from 'shiftPlanner/components/shiftPlannerWorkplaceAdministration/shiftPlannerWorkplaceAdministration.reducer';
import weeklyRequirements from 'shiftPlanner/components/shiftPlannerWorkplaceAdministration/components/weeklyRequirements/store/weeklyRequirements.reducer';
import shadowEmployee from './shiftPlannerShadowEmployees.reducer';
import workplaceTodos from 'shiftPlanner/components/workplaceTodos/store/workplaceTodos.reducer';
import warnings from './warnings.reducer';

export {
	addShiftModal,
	daySettingsModal,
	phpReport,
	shifts,
	shiftPlanner,
	shiftPlannerKPI,
	shiftPlannerClockings,
	workDays,
	weatherForecast,
	shiftPlannerForecasting,
	shiftPlannerDayReview,
	shiftPlannerWorkplaceAdministration,
	weeklyRequirements,
	shadowEmployee,
	workplaceTodos,
	warnings,
};
