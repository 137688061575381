'use strict';

import { HotbarComponentAbstract } from 'hotdamnbar/factory';

/**
 * SingleDate
 * @param  {string} title
 * @param  {bool} required
 * @param  {object} defaultDate
 */
class SingleDate extends HotbarComponentAbstract {

	constructor(title, required = false, defaultDate = null) {
		super(title, required);

		// Set type
		this.type = 'singleDate';

		//////////////////
		// Default date //
		//////////////////

		this.defaultDate = defaultDate;
	}

}

export default SingleDate;