'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	setIngredient,
	updateIngredient,
	resetState,
	updateBatchForm,
	resetBatch,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
} from './store/ingredients.actions';

// components
import { Button, Input, InputCollectionSelect, Icon, Toggle, Tabs } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';
import ModalBatch from './components/modalBatch/modalBatch.container';
import SubTable from 'reactDataWrapper/components/subTable';
import IngredientTranslations from './components/ingredientTranslations/ingredientTranslations.container';
import IngredientComponents from './components/ingredientComponents/ingredientComponents.container';
import getColumns from 'reactDataWrapperColumns/product/ingredients.columns';

import {
	fetchIngredients,
	editIngredients,
	addIngredients,
	deleteIngredients,
	fetchIngredientCategories,
	fetchIngredientPricingGroups,
	editBatchIngredients,
} from './ingredients.service';

// utils
import { convertBinaryToBase64 } from 'utils';
import { getOperationTypeOptions } from './utils';

// lodash
import _get from 'lodash/get';

// phrases
import phrases from './ingredients.phrases';

class Ingredients extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.editMultiple = this.editMultiple.bind(this);

		this.reduxKey = 'product/ingredients';

		this.state = {
			showModal: false,
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this.fetchData = this.fetchData.bind(this);
		this._toggleModal = this._toggleModal.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);

		this.columns = getColumns();
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}
			>
				Legacy
			</Button>
		);
	}

	deleteEntry(id) {
		return deleteIngredients(id);
	}

	editMultiple(selectedRows) {
		const { ingredientEntry } = this.props;

		const data = {
			name: ingredientEntry.name,
			...(ingredientEntry.ingredientCategory && {
				ingredient_category: ingredientEntry.ingredientCategory.value,
			}),
			...(ingredientEntry.assetCollection && {
				asset_collection: ingredientEntry.assetCollection.value,
			}),
			...(ingredientEntry.ingredientPricingGroup && {
				ingredient_pricing_group: ingredientEntry.ingredientPricingGroup.value,
			}),
			fuel: ingredientEntry.fuel,
			legacy: !!ingredientEntry.legacy,
		};

		const list = selectedRows.map((row) => {
			return {
				id: row.id,
				...data,
			};
		});

		const payload = {
			batch: list,
		};

		return editBatchIngredients(payload);
	}

	editEntry() {
		const { ingredientEntry } = this.props;

		const payload = {
			name: ingredientEntry.name,
			...(ingredientEntry.ingredientCategory && {
				ingredient_category: ingredientEntry.ingredientCategory.value,
			}),
			...(ingredientEntry.assetCollection && {
				asset_collection: ingredientEntry.assetCollection.value,
			}),
			...(ingredientEntry.ingredientPricingGroup && {
				ingredient_pricing_group: ingredientEntry.ingredientPricingGroup.value,
			}),
			fuel: ingredientEntry.fuel,
			...(ingredientEntry.listName && {
				list_name: ingredientEntry.listName,
			}),
			...(ingredientEntry.operationType && {
				operation_type: ingredientEntry.operationType.value,
			}),
			availability_administration: !!ingredientEntry.availabilityAdministration,
			legacy: !!ingredientEntry.legacy,
		};

		// Clean object for undefined values

		return editIngredients(ingredientEntry.id, payload);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			name: data.name,
			ingredientCategory: _get(data, 'ingredient_category.name', ''),
			...(data.asset_collection && {
				assetCollection: {
					value: data.asset_collection.id,
					label: data.asset_collection.name,
				},
			}),
			...(data.ingredient_pricing_group && {
				ingredientPricingGroup: {
					value: data.ingredient_pricing_group.id,
					label: data.ingredient_pricing_group.name,
				},
			}),
			fuel: data.fuel,
			listName: data.list_name,
			operationType: {
				label: data.operation_type,
				value: data.operation_type,
			},
			availabilityAdministration: data.availability_administration,
			legacy: data.legacy,
		};

		this.props.setIngredient(payload);
	}

	getEditableCellsEdit(e) {
		const { ingredientEntry } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="name"
						value={_get(ingredientEntry, 'name', '')}
						onChange={(event) => this.editStoreEntry(event, 'name')}
					/>
				),
			},
			{
				header: 'Ingredient category',
				value: <span>{ingredientEntry.ingredientCategory}</span>,
			},
			{
				header: 'Fuel',
				value: (
					<Input
						id="fuel"
						placeholder="fuel"
						value={_get(ingredientEntry, 'fuel', '')}
						onChange={(event) => this.editStoreEntry(event, 'fuel')}
					/>
				),
			},
			{
				header: 'Asset collection',
				value: (
					<InputCollectionSelect
						placeholder="Select asset collection..."
						value={ingredientEntry.assetCollection}
						handleChange={(key, value) => this.editStoreEntry(value, 'assetCollection')}
						clearable={false}
						cache
						apiPath="/pos/asset_collections"
						params={{
							limit: 30,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Ingredient pricing group',
				value: (
					<InputCollectionSelect
						placeholder="Select Ingredient pricing group..."
						value={ingredientEntry.ingredientPricingGroup}
						handleChange={(key, value) => this.editStoreEntry(value, 'ingredientPricingGroup')}
						clearable={false}
						cache
						apiPath="/product/ingredient_pricing_groups"
						params={{
							limit: 30,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'List name',
				value: (
					<Input
						id="listName"
						placeholder="Enter list name..."
						value={ingredientEntry.listName}
						onChange={(event) => this.editStoreEntry(event, 'listName')}
					/>
				),
			},
			{
				header: 'Operation type',
				value: (
					<InputCollectionSelect
						id="operationType"
						placeholder="Select operation type..."
						value={ingredientEntry?.operationType}
						handleChange={(key, value) => this.editStoreEntry(value, 'operationType')}
						cache
						clearable={false}
						options={getOperationTypeOptions()}
					/>
				),
			},
			{
				header: 'Availability administration',
				value: (
					<Toggle
						id="availabilityAdministration"
						toggled={ingredientEntry.availabilityAdministration}
						onClick={(e) => this.editStoreEntry(e, 'availabilityAdministration')}
					/>
				),
			},
			{
				header: 'Legacy',
				value: (
					<Toggle
						id="legacy"
						toggled={_get(ingredientEntry, 'legacy', false)}
						onClick={(event) => this.editStoreEntry(event, 'legacy')}
					/>
				),
			},
		];
	}

	editStoreImage(type, e) {
		this.setState({ [type]: true });

		convertBinaryToBase64(e)
			.then((image64) => {
				this.setState({ [type]: false });
				this.editStoreEntry(
					{
						filename: e.name,
						data: image64,
					},
					type
				);
			})
			.catch((e) => this.setState({ [type]: false }));
	}

	editStoreEntry(e, name) {
		const value = e.target ? e.target.value : e;

		const updateObject = {
			[name]: value,
		};

		this.props.updateIngredient(updateObject);
	}

	fetchData(state) {
		return fetchIngredients(state);
	}

	_toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetBatch();
	}

	render() {
		const {
			// batchSelection props
			onInitialization,
			reduxKey: batchReduxKey,
			style,
			defaultPageSize,
			batchSelection,
		} = this.props;

		const legacyFilter = this.state.legacyFilterOn ? this.state.legacyFilter : '';
		return (
			<>
				<ReactDataWrapper
					accessAreasAllowedToEdit={['Sales Configuration']}
					title={phrases.INGREDIENTS}
					columns={this.columns}
					defaultPageSize={batchSelection ? defaultPageSize : 10}
					reduxKey={batchSelection ? batchReduxKey : this.reduxKey}
					style={batchSelection ? style : {}}
					{...(batchSelection && { onInitialization })}
					batchSelection={batchSelection}
					fetchData={this.fetchData}
					filterable
					pageSize={10}
					manual
					editEntry={(e) => this.editEntry(e)}
					editMultiple={this.editMultiple}
					editableCellsEdit={this.getEditableCellsEdit()}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					deleteEntry={this.deleteEntry}
					actionRender={
						<>
							<Button type="inverted" label="Batch" shadow onClick={() => this._toggleModal()}>
								<Icon name="add" />
							</Button>
						</>
					}
					customAreaComponents={this.getLegacyFilterButton()}
					extraFilters={legacyFilter}
					subcomponent={(row) => {
						const tabContent = [
							{
								name: phrases.INGREDIENT_TRANSLATIONS,
								component: <IngredientTranslations ingredient={row.original} />,
							},
							{
								name: phrases.INGREDIENT_COMPONENTS,
								component: <IngredientComponents ingredient={row.original} />,
							},

							// GLOBAL meaning that we're using the global resource for this table (excludes market)
							{
								name: phrases.GLOBAL_INGREDIENT_COMPONENTS,
								component: <IngredientComponents ingredient={row.original} useGlobalResource />,
							},
						];

						return (
							<SubTable>
								<Tabs tabContent={tabContent} />
							</SubTable>
						);
					}}
				/>

				<ModalBatch
					modalVisible={this.state.showModal}
					handleClose={this._toggleModal}
					batchOptions={this.props.batchOptions}
					updateBatchForm={this.props.updateBatchForm}
					fetchIngredientCategories={fetchIngredientCategories}
					fetchIngredientPricingGroups={fetchIngredientPricingGroups}
					ingredientCategories={this.props.ingredientCategories}
					ingredientPricingGroups={this.props.ingredientPricingGroups}
					addBatchList={this.props.addBatchList}
					batchList={this.props.batchList}
					addIngredients={addIngredients}
					editBatchListItem={this.props.editBatchListItem}
					removeBatchListItem={this.props.removeBatchListItem}
				/>
			</>
		);
	}
}

Ingredients.propTypes = {
	ingredientEntry: PropTypes.object,
	batchOptions: PropTypes.object,
	ingredientCategories: PropTypes.object,
	ingredientPricingGroups: PropTypes.object,
	batchList: PropTypes.array,

	updateIngredient: PropTypes.func,
	setIngredient: PropTypes.func,
	resetState: PropTypes.func,
	updateBatchForm: PropTypes.func,
	resetBatch: PropTypes.func,
	addBatchList: PropTypes.func,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	onInitialization: PropTypes.func,
	reduxKey: PropTypes.string,
	style: PropTypes.object,
	defaultPageSize: PropTypes.number,
	batchSelection: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setIngredient,
			updateIngredient,
			resetState,
			updateBatchForm,
			resetBatch,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		ingredientEntry: store.ingredients.data.ingredientEntry,
		batchOptions: store.ingredients.data.batchOptions,
		batchList: store.ingredients.data.batchList,
		ingredientCategories: store.listData['product/ingredient_categories/batchSelection'],
		ingredientPricingGroups: store.listData['product/ingredient_pricing_groups/batchSelection'],
	};
};

export default connectWithStore(Ingredients, mapStateToProps, mapDispatchToProps);
