export default {
	TABLE_TITLE: 'POS configuration versions',
	TABLE_FILTER_ACTIVE: 'Active',
	TABLE_FILTER_MARKET: 'Market',
	POS_CONFIGURATION_VERSION_ADDED: 'POS configuration version added',
	POS_CONFIGURATION_VERSIONS_ADDED: 'POS configuration versions added',
	POS_CONFIGURATION_VERSIONS_DUPLICATED:
		'POS configuration versions ended and duplicated',
	POS_CONFIGURATION_VERSION_EDITED: 'POS configuration version edited',
	POS_CONFIGURATION_VERSIONS_EDITED: 'POS configurations version edited',
	POS_CONFIGURATION_VERSION_DELETED: 'POS configuration version deleted',
	DUPLICATE_AS_NEW_TOOLTIP:
		'Overwrite existing POS configuration versions and create new',
	DUPLICATE_AS_NEW_BUTTON_LABEL: 'Duplicate as new',
	CURRENCY_TOOLTIP: 'Currencies',

	// batch
	MODAL_BATCH_STEP_FORM_TITLE: 'Select entries',
	MODAL_BATCH_STEP_FORM_TITLE_EDIT: 'Duplicate pos configuration versions',

	MODAL_BATCH_STEP_FORM_TITLE_ADD: 'Add POS configuration(s)',
	MODAL_BATCH_STEP_MARKET: 'Market',
	MODAL_BATCH_STEP_MARKET_PLACEHOLDER: 'Select market...',

	MODAL_BATCH_STEP_POS_CONFIGURATION: 'POS Configuration',
	MODAL_BATCH_STEP_POS_CONFIGURATION_PLACEHOLDER: 'Select POS Configuration...',

	MODAL_BATCH_TABLE_TITLE: 'POS Configurations',
	MODAL_BATCH_ACTIVE_FROM_NEW: 'New Active from',
	MODAL_BATCH_ACTIVE_TO_NEW: 'New Active to',
	MODAL_BATCH_ACTIVE_FROM: 'Active from',
	MODAL_BATCH_ACTIVE_TO: 'Active to',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_STATE: 'State',
	MODAL_BATCH_STEP_STATE_PLACEHOLDER: 'Select state...',
	MODAL_BATCH_STEP_APP_TILE_LAYOUT: 'App tile layout',
	MODAL_BATCH_STEP_APP_TILE_LAYOUT_PLACEHOLDER: 'Select app tile layout...',
	MODAL_BATCH_STEP_APP_TILE_LAYOUT_TABLE_TITLE: 'App tile layouts',
	MODAL_BATCH_STEP_POS_TILE_LAYOUT: 'Pos tile layout',
	MODAL_BATCH_STEP_POS_TILE_LAYOUT_PLACEHOLDER: 'Select pos tile layout',
	MODAL_BATCH_STEP_POS_TILE_LAYOUT_TABLE_TITLE: 'Pos tile layouts',
	MODAL_BATCH_STEP_DELIVERY_TILE_LAYOUT: 'Delivery tile layout',
	MODAL_BATCH_STEP_DELIVERY_TILE_LAYOUT_PLACEHOLDER:
		'Select delivery tile layout...',
	MODAL_BATCH_STEP_DELIVERY_TILE_LAYOUT_TABLE_TITLE: 'Delivery tile layouts',
	MODAL_BATCH_STEP_ACTIVE: 'Active',
	MODAL_BATCH_STEP_FORM_MARKET_TABLE_TITLE: 'Markets',
	MODAL_BATCH_STEP_FORM_POS_CONFIGURATION_VERSION_TABLE_TITLE:
		'Pos configuration versions',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'Next',
	MODAL_BATCH_STEP_LIST_TITLE: 'Confirm entries',
	MODAL_BATCH_STEP_LIST_CLOSE_BUTTON_LABEL: 'Close',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'Back',
	MODAL_BATCH_STEP_FORM_TABLE_FILTER: 'Legacy',
	MODAL_BATCH_STEP_REVERT_TOOLTIP: 'Revert',
	MODAL_BATCH_STEP_GLOBAL_ROW_TIP: 'Select values to overwrite all rows',
	MODAL_BATCH_STEP_DUPLICATE_AS_NEW_LIST_TITLE:
		'End old and create new entries',
};
