import RowStyles from './rowStyles';
import selectRow from './selectRow';
import HotkeysService from 'hotkeys.service';
import * as actions from './../reactDataWrapper.actions';
import { store } from 'appState';
import { batch } from 'react-redux';

// lodash
import _get from 'lodash/get';

/**
 * @function getTdProps
 * @description Override method for getting row info. In this case used for toggling multiple row selection
 * @param {*} state
 * @param {*} rowInfo
 */
export default function getTrProps({
	_,
	rowInfo,
	reduxKey,
	selectedRows,
	enableSingleSelection,
	enableMultiSelection,
	batchSelection,
	listData,
	lastSelectedRow,
	customRowStyling,
}) {
	if (!rowInfo)
		return {
			onClick: (e, handleOriginal) => {
				if (handleOriginal) {
					handleOriginal();
				}
			},
		};

	// if custom row styling passed
	let customStyling = {};
	if (customRowStyling) customStyling = customRowStyling(rowInfo);

	if (enableSingleSelection) {
		return {
			style: {
				...customStyling,
				background: selectedRows.some((selectedRow) => {
					return selectedRow.id === rowInfo.original.id;
				})
					? `rgba(${window
							.getComputedStyle(document.body)
							.getPropertyValue(RowStyles.SELECTED)}, 0.2)`
					: _get(customStyling, 'background', false)
					? customStyling.background
					: null,
			},
			onClick: (e, handleOriginal) => {
				batch(() => {
					// normal click, w/o hotkeys so clear out all rows and add a new one
					store.dispatch(actions.resetSelection({ reduxKey }));
					store.dispatch(
						actions.toggleRow({
							reduxKey,
							data: {
								...rowInfo.original,
								index: rowInfo.index,
							},
						})
					);
					store.dispatch(
						actions.setLastRowSelected({
							reduxKey,
							data: {
								...rowInfo.original,
								index: rowInfo.index,
							},
						})
					);
				});

				if (handleOriginal) {
					handleOriginal();
				}
			},
		};
	}

	// Disable rowclick functionality
	if (!enableMultiSelection) {
		if (customRowStyling) {
			return {
				style: customStyling,
				onClick: (e, handleOriginal) => {
					store.dispatch(
						actions.toggleRow({
							reduxKey,
							data: {
								...rowInfo.original,
								index: rowInfo.index,
							},
						})
					);

					if (handleOriginal) {
						handleOriginal();
					}
				},
			};
		}
		return {};
	}

	return {
		onClick: (e, handleOriginal) => {
			// if using hotkeys
			if (
				batchSelection ||
				HotkeysService.isPressed('command') ||
				HotkeysService.isPressed('cmd') ||
				HotkeysService.isPressed('ctrl') ||
				HotkeysService.isPressed('shift')
			) {
				if (HotkeysService.isPressed('shift')) {
					if (selectedRows.length > 0) {
						// here we should call the function and if it returns truthy value, manipulate the shift select
						selectRow({
							reduxKey,
							rowInfo,
							selectedRows,
							listData,
							lastSelectedRow,
						});
					} else {
						store.dispatch(
							actions.toggleRow({
								reduxKey,
								data: {
									...rowInfo.original,
									index: rowInfo.index,
								},
							})
						);
					}
				} else {
					// add the new row to the store
					store.dispatch(
						actions.toggleRow({
							reduxKey,
							data: {
								...rowInfo.original,
								index: rowInfo.index,
							},
						})
					);
				}
			} else {
				// normal click, w/o hotkeys so clear out all rows and add a new one
				store.dispatch(actions.resetSelection({ reduxKey }));
				store.dispatch(
					actions.toggleRow({
						reduxKey,
						data: {
							...rowInfo.original,
							index: rowInfo.index,
						},
					})
				);
			}

			store.dispatch(
				actions.setLastRowSelected({
					reduxKey,
					data: {
						...rowInfo.original,
						index: rowInfo.index,
					},
				})
			);

			// IMPORTANT! React-Table uses onClick internally to trigger
			// events like expanding SubComponents and pivots.
			// By default a custom 'onClick' handler will override this functionality.
			// If you want to fire the original onClick handler, call the
			// 'handleOriginal' function.
			if (handleOriginal) handleOriginal();
		},
		style: {
			...customStyling,
			background: selectedRows.some((selectedRow) => {
				return selectedRow.id === rowInfo.original.id;
			})
				? `rgba(${window
						.getComputedStyle(document.body)
						.getPropertyValue(RowStyles.SELECTED)}, 0.2)`
				: _get(customStyling, 'background', false)
				? customStyling.background
				: null,
		},
	};
}
