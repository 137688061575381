// lodash
import _get from 'lodash/get';

/**
 * @function getEditedValues
 * @param {Object.newData} object - values from edit modal
 * @param {Object.oldData} object - values from table row
 * @description goes through the newData object and compares the values with old values - initial values
 * @returns values that are edited
 */

type Props<T> = {
	newData: T;
	oldData: T;
};
export default function getEditedValues<T extends { [key: string]: any }>({
	newData,
	oldData,
}: Props<T>):
	| Partial<{
			[key in keyof T]?: any;
	  }>
	| undefined {
	try {
		return Object.keys(newData).reduce((acc, currentValue) => {
			const editedEntry = _get(
				newData,
				`[${currentValue}].value`,
				newData[currentValue]
			);

			const oldEntry = _get(
				oldData,
				`[${currentValue}].value`,
				oldData[currentValue]
			);

			// if value from edit object and old value is the same, do nothing
			if (editedEntry === oldEntry) return acc;

			// values are changes, i.e. edited so add to the array
			return {
				...acc,
				[currentValue]: editedEntry,
			};
		}, {});
	} catch (error) {
		console.error(error);
	}
}
