'use strict';

import {
	ADD_REVIEWS,
	UPDATE_REVIEW,
	SET_REVIEWER,
	RESET_REVIEWS
} from './trainingHistoryReview.actions';

const defaultState = {
	reviews: [],
	reviewer: []
};

/**
 * @function _getReviewState
 * @memberOf TrainingHistoryReview
 * @description Helper function to extract the state of the review
 * @param {object} review
 * @return {array} formatted resultset
 */

function getReviewState(review = {}) {
	if (review.latest_review) {
		return 'EMPLOYEE_HAVE_BEEN_REVIEWED';
	} else if (
		review.module &&
		review.latest_module_execution &&
		!review.latest_module_execution.module_grade_level
	) {
		return 'EMPLOYEE_HAVE_EXECUTED_MODULE';
	} else if (review.module && !review.latest_module_execution) {
		return 'EMPLOYEE_SHOULD_EXECUTE_MODULE';
	} else if (
		review.module &&
		review.latest_module_execution &&
		review.latest_module_execution.module_grade_level &&
		!review.latest_review
	) {
		return 'EMPLOYEE_HAVE_BEEN_GRADED';
	} else {
		console.error('Something is wrong');
	}
}

/**
 * @function formatResultSet
 * @memberOf TrainingHistoryReview
 * @description Handler for something
 * @param {object} resultSet
 * @return {array} formatted resultset
 */
function formatResultSet(review = {}, loggedInUser) {
	// Define default module values

	const reviewState = getReviewState(review);

	return {
		reviewState,
		moduleId: review.module.id,
		moduleExecutionId: review.latest_module_execution
			? review.latest_module_execution.id
			: null,
		type: review.module.type,
		module: review.module.name,
		workplace: review.latest_module_execution
			? review.latest_module_execution.workplace.name
			: undefined,
		date: review.latest_module_execution
			? review.latest_module_execution.period.from
			: undefined,
		trainer: review.latest_module_execution
			? review.latest_module_execution.trainer.full_name
			: undefined,
		reviewer: review.latest_review
			? review.latest_review.reviewer
			: loggedInUser,
		grade: {
			...(reviewState === 'EMPLOYEE_HAVE_BEEN_REVIEWED' && {
				label: review.latest_review.module_grade_level.grade_level.name,
				value: review.latest_review.module_grade_level.grade_level.id
			}),
			...(reviewState === 'EMPLOYEE_HAVE_BEEN_GRADED' && {
				label:
					review.latest_module_execution.module_grade_level.badge_level.name,
				value: review.latest_module_execution.module_grade_level.badge_level.id
			})
		},
		comment: review.latest_review ? review.latest_review.comment : ''
	};
}

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
	case ADD_REVIEWS: {
		let { reviews } = payload;

		return {
			...state,
			reviews: reviews.map(review => ({
				...formatResultSet(review, state.reviewer)
			}))
		};
	}

	case UPDATE_REVIEW: {
		let { updatedReview, index } = payload;

		return {
			...state,
			reviews: state.reviews.map((review, i) => {
				if (i === index) {

					return {
						...review,
						grade: {
							value: updatedReview.module_grade_level.badge_level.id,
							label: updatedReview.module_grade_level.badge_level.name
						},
						reviewer: {
							person: { ...updatedReview.reviewer }
						},
						comment: updatedReview.comment,
						reviewState: 'EMPLOYEE_HAVE_BEEN_REVIEWED'
					};
				}
				return review;
			})
		};
	}

	case SET_REVIEWER: {
		let { reviewer } = payload;

		return {
			...state,
			reviewer
		};
	}

	case RESET_REVIEWS: {
		return {
			...state,
			reviews: defaultState.reviews
		};
	}

	default:
		return state;
	}
}

export default reducer;
