'use strict';

var headers = {
	PERSON_ATTRIBUTE_TYPES: {
		NAME: 'Name',
	},

	BADGE_CATEGORIES: {
		NAME: 'Name',
		DESCRIPTION: 'Description',
		TYPE: 'Type',
	},
	BADGE_LEVELS: {
		NAME: 'Name',
		IMAGE: 'Image',
		DESCRIPTION: 'Description',
		TYPE: 'Type',
	},
	BADGES: {
		NAME: 'Name',
		IMAGE: 'Image',
		MINIATURE: 'Miniature',
		DESCRIPTION: 'Description',
		TYPE: 'Type',
	},
	EMPLOYMENTS: {
		ID: 'Id',
		PERSON: 'Person',
		COMPANY: 'Company',
		STARTED: 'Started',
		ENDED: 'Ended',
		ACTIVE: 'Active',
		PERSON_PICTURES: 'Avatar',
		FIRST_NAME: 'First name',
		MIDDLE_NAME: 'Middle name',
		LAST_NAME: 'Last name',
		PERSON_EMAIL_ADDRESS: 'E-mail',
		PERSON_PHONE_NUMBER: 'Phone number',
		PERSON_ADDRESSES: 'Addresses',
		BIRTH_DATE: 'Birthday',
		PERSON_ID: 'Juicer ID',
		HAS_CONTRACT: 'Contract',
		HAS_CODE_OF_CONDUCT: 'COC',
		HAS_RESIGNATION: 'Resignation',
		GLOBAL_MONEYBALL_LEVEL_BADGE: 'Badge',
		GLOBAL_MONEYBALL_LEVEL_NAME: 'Moneyball level category',
		GLOBAL_MONEYBALL_NAME: 'Moneyball name',
		GLOBAL_MONEYBALL_BADGE: 'Moneyball badge',
		WORKPLACE: 'Workplace',
		INCLUDE_IN_SALARY_EXPORT: 'Payroll',
		NATIONALITY: 'Nationality',
	},
	MONEYBALL_POSITIONS: {
		ID: 'Id',
		NAME: 'Name',
		DESCRIPTION: 'Description',
		BADGE: 'Badge',
		SORT_ORDER: 'Sort_order',
		FROM: 'FROM',
		TO: 'To',
	},
	MONEYBALL_SUB_POSITIONS: {
		ID: 'Id',
		NAME: 'Name',
		DESCRIPTION: 'Description',
		PRODUCTIVITY: 'Productivity',
		BADGE: 'Badge',
		TEAM_POSITION_CATEGORY: 'Team position category',
		ROLE: 'Role',
		CAN_TRAIN: 'Can Train',
		SORT_ORDER: 'Sort order',
		FROM: 'From',
		TO: 'To',
	},

	ONBOARDINGS: {
		ID: 'Id',
		MARKET: 'Market',
		CREATED: 'Created',
		PERSON: 'Person',
		RECEIVED: 'Received',
		SIGNED: 'Signed',
		STATUS: 'Status',
		SIGNED_BY: 'Signed by',
	},

	ONBOARDING_VALUES: {
		ONBOARDING: 'Onboarding',
		ONBOARDING_VALUE_TYPE: 'Onboarding value type',
		EXISTING_VALUE: 'WP2',
		NEW_VALUE: 'Onbarding',
		RESULT: 'Updated WP2',
	},

	MARKET_ONBOARDINGS: {
		MARKET: 'Market',
		ONBOARDING_PLATFORM: 'Onboarding platform',
		ONBOARDING_ID: 'Onboarding id',
	},

	CREATE_ONBOARDING: {
		MARKET: 'Market',
		FIRST_NAME: 'First name',
		LAST_NAME: 'Last name',
		EMAIL_ADDRESS: 'Email address',
		PHONE_NUMBER: 'Phone number',
		START_DATE: 'Start date',
		COMPANY: 'Company',
		MONEYBALL_SUB_POSITION: 'Moneyball sub position',
		SALARY_GROUP: 'Salary group',
		EMPLOYMENT_TYPE: 'Employment type',
	},

	EMPLOYMENTS_BANK: {
		PERSON: 'Person',
		COMPANY: 'Company',
		STARTED: 'Started',
		ENDED: 'Ended',
		ACTIVE: 'Active',
		PERSON_PICTURES: 'Avatar',
		FIRST_NAME: 'First name',
		MIDDLE_NAME: 'Middle name',
		LAST_NAME: 'Last name',
		PERSON_EMAIL_ADDRESS: 'E-mail',
		PERSON_PHONE_NUMBER: 'Phone number',
		PERSON_ADDRESSES: 'Addresses',
		BIRTH_DATE: 'Birthday',
		PERSON_ID: 'Juicer ID',
		HAS_CONTRACT: 'Has contract',
		GLOBAL_MONEYBALL_LEVEL_BADGE: 'Moneyball level badge',
		GLOBAL_MONEYBALL_LEVEL_NAME: 'Moneyball level category',
		GLOBAL_MONEYBALL_NAME: 'Moneyball name',
		GLOBAL_MONEYBALL_BADGE: 'Moneyball badge',
		WORKPLACE: 'Workplace',
	},
	EMPLOYMENTS_LIST: {
		PERSON: 'Person',
		COMPANY: 'Company',
		STARTED: 'Started',
		ENDED: 'Ended',
		FIRST_NAME: 'First name',
		MIDDLE_NAME: 'Middle name',
		LAST_NAME: 'Last name',
		CURRENT_MONEYBALL_SUB_POSITION: 'Moneyball',
		WORKPLACE: 'Workplace',
		INCLUDE_IN_SALARY_EXPORT: 'In salary export',
		SALARY_EXPORT_ID: 'Salary export id',
	},
	EMPLOYMENT_WORKPLACES: {
		WORKPLACE: 'WORKPLACE',
		COMPANY: 'Company',
		FROM: 'From',
		TO: 'To',
	},
	EMPLOYMENT_POSITIONS: {
		MONEYBALL_SUB_POSITION: 'Moneyball Sub Position',
		SALARY_GROUP: 'Salary group',
		TYPE: 'Type',
		FROM: 'From',
		TO: 'To',
		SALARY_COMPONENTS: 'Salary components',
		SALARY_COMPONENTS_AMOUNT: 'Salary components amount',
	},
	GLOBAL_JOB_TITLES: {
		ACTIVE: 'Active',
		NAME: 'Name',
		DESCRIPTION: 'Description',
		PRODUCTIVITY: 'Productivity',
		SORT_ORDER: 'Sort order',
		BADGE: 'Badge',
		MARKET: 'Market',
		MONEYBALL_POSITION: 'Moneyball position',
		TO: 'To',
		FROM: 'From',
	},
	GLOBAL_JOB_TITLE_LEVELS: {
		MONEYBALL_POSITION: 'Moneyball position',
		NAME: 'Name',
		DESCRIPTION: 'Description',
		PRODUCTIVITY: 'Productivity',
		SORT_ORDER: 'Sort order',
		BADGE: 'Badge',
		ACTIVE: 'Active',
		TO: 'To',
		FROM: 'From',
	},
	GRIDS: {
		NAME: 'Name',
	},
	GRID_ENTRIES: {
		EMPLOYEES: 'Employees',
		MINIMUM: 'Slow',
		OPTIMAL: 'Optimal',
		MAXIMUM: 'Maximum',
	},
	PERSON_GROUPS: {
		NAME: 'Name',
		DESCRIPTION: 'Description',
		MEMBER_DISCOUNT_PROGRAM: 'Member discount program',
	},
	PERSON_GROUP_MEMBERS: {
		PERSON: 'Person',
		PERSON_GROUP: 'Group',
	},
	PERSONS: {
		ID: 'Id',
		PERSON_PICTURES: 'Avatar',
		PERSON: 'Person',
		FIRST_NAME: 'First name',
		MARKET: 'Market',
		MIDDLE_NAME: 'Middle name',
		LAST_NAME: 'Last name',
		FULL_NAME: 'Name',
		NAME: 'Name',
		COMPANY: 'Company',
		PERSON_EMAIL_ADDRESS: 'E-mail',
		PERSON_EMAIL_ADDRESSES: 'E-mail',
		PERSON_PHONE_NUMBER: 'Phone number',
		PERSON_ADDRESSES: 'Addresses',
		BIRTH_DATE: 'Birthday',
		ACTIVE: 'Active',
		STARTED: 'Started',
		ENDED: 'Ended',
		GENDER: 'Gender',
		NATIONALITY: 'Nationality',
	},
	PERSON_ADDRESSES: {
		ADDRESS: 'Address',
		COUNTRY: 'Country',
	},
	PERSON_BANK_ACCOUNTS: {
		REGISTRATION_NUMBER: 'Registration number',
		ACCOUNT_NUMBER: 'Account number',
		BANK: 'Bank',
	},
	PERSON_EMAIL_ADDRESSES: {
		EMAIL_ADDRESS: 'Email address',
	},
	PERSON_PERSONAL_INFORMATION: {
		SOCIAL_SECURITY_NUMBER: 'Social security number',
	},
	PERSON_PHONE_NUMBERS: {
		PHONE_NUMBER: 'Phone number',
	},
	EMPLOYMENT_BANK_ACCOUNTS: {
		BANK: 'Bank',
		ACCOUNT_NUMBER: 'Account number',
		REGISTRATION_NUMBER: 'Reg number',
		SORT_CODE: 'Sort code',
		IBAN: 'Iban',
		SWIFT: 'SWIFT',
	},
};

var titles = {
	BADGE_LEVELS: 'Badge Levels',
	BADGES: 'Module images',
	BADGE_CATEGORIES: 'Badge Categories',
	EMPLOYMENTS_LIST: 'Employments',
	EMPLOYMENTS: 'Juicers',
	EMPLOYMENTS_BANK: 'Juicer Bank',
	MONEYBALL_POSITIONS: 'Moneyball positions',
	MONEYBALL_SUB_POSITIONS: 'Moneyball sub positions',
	PERSON_GROUPS: 'Groups',
	PERSON_GROUP_MEMBERS: 'Group members',
	PERSONS: 'People',
	PERSON_ADDRESSES: 'Addresses',
	PERSON_BANK_ACCOUNTS: 'Bank accounts',
	PERSON_EMAIL_ADDRESSES: 'E-mail addresses',
	PERSON_PERSONAL_INFORMATION: 'Personal information',
	PERSON_PHONE_NUMBERS: 'Phone numbers',
	GLOBAL_JOB_TITLES: 'Job titles',
	EMPLOYMENT_WORKPLACES: 'Employment Workplaces',
	EMPLOYMENT_POSITIONS: 'Employment Positions',
	GLOBAL_JOB_TITLE_LEVELS: 'Levels',
	GRIDS: 'Grids',
	GRID_ENTRIES: 'Grid entries',
	ONBOARDINGS: 'Juicer Onboarding',
	ONBOARDING_DETAILS: 'Onboarding details',
	ONBOARDING_VALUES: 'Onboarding values',
	MARKET_ONBOARDINGS: 'Market onboardings',
	EMPLOYMENT_BANK_ACCOUNTS: 'Employment bank accounts',
	PERSON_ATTRIBUTE_TYPES: 'Person attribute types',
};

module.exports = {
	headers: headers,
	titles: titles,
};
