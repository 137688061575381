export const ADD_BATCH_LIST =
	'SALES_CONFIGURATION_PRODUCT_BUNDLE_RULE/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_PRODUCT_BUNDLE_RULE/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_PRODUCT_BUNDLE_RULE/REMOVE_BATCH_LIST_ITEM';

export const ADD_BATCH_LIST_ITEM_ROW =
	'SALES_CONFIGURATION_PRODUCT_BUNDLE_RULE/ADD_BATCH_LIST_ITEM_ROW';
export const RESET_STATE =
	'SALES_CONFIGURATION_PRODUCT_BUNDLE_RULE/RESET_STATE';
export const SET_GLOBAL_BUNDLE_RULES =
	'SALES_CONFIGURATION_PRODUCT_BUNDLE_RULE/SET_GLOBAL_BUNDLE_RULES';

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function setGlobalBundleRules(payload) {
	return {
		type: SET_GLOBAL_BUNDLE_RULES,
		payload
	};
}
