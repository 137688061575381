export default {
	TILE_LAYOUT_HOME_SCREEN_RECOMMENDATIONS_ADDED:
		'Home screen recommendations added',
	TILE_LAYOUT_HOME_SCREEN_RECOMMENDATIONS_EDITED:
		'Home screen recommendations edited',
	TILE_LAYOUT_TIME_SLOT_EDITED: 'Time slot edited',
	TILE_LAYOUT_TIME_SLOT_DELETED: 'Time slot deleted',
	TILE_LAYOUT_HOME_SCREEN_RECOMMENDATIONS_DUPLICATED:
		'Home screen recommendations duplicated',

	NO_TIME_SLOTS_ADDED_TIP: 'No time slots added, click the button to begin',
	ADD_TIME_SLOT: 'Add time slots',
	EDIT_TIME_SLOT: 'Edit time slot',
	NO_TIME_SLOTS_ADDED: 'No time slots added',
	TIME_FROM: 'Time from',
	TIME_TO: 'Time to',
	ADD_NEW_ROW_TOOLTIP: 'Add new time slot row',
	DELETE_TIME_SLOT_CONFIRMATION:
		'Are you sure you want to delete the time slot and all associated recommendations?',
	DELETE_TIME_SLOT: 'Delete time slot',
	DELETE_TIME_SLOT_CONFIRM: 'Delete',
	CLICK_TO_ADD_TIP:
		'Clicking on the product in the product list will add that product to the next available unoccupied slot',
	DUPLICATE_TIP: 'Duplicate home screen recommendation',
	DUPLICATE_TIME_SLOT: 'Select layout to copy to',
	DUPLICATE_TIME_SLOT_TIP:
		'Home screen recommendations will be duplicated to the selected layout',
	DUPLICATE_CONFIRM_LABEL: 'Duplicate',
};
