'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Tools
import phrases from './../employeeMeals.phrases';
import constants from 'dumb/report/constants';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { getStringBasedOnSelectedValues } from './../utilities/utils';

// Components
import EmployeeMealsPrWorkplacesReport from './employeeMealsPrWorkplace.component';
import EmployeeMealsPrPerson from './employeeMealsPrPerson.component';
import { Loader } from 'dumb';
import {
	Body,
	Head,
	HeaderCell,
	Row,
	Table,
	TableCell,
	Title,
	Report
} from 'dumb/report';

class EmployeeMealsMarkets extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expandedRows: new Set(),
			loading: []
		};

		this._renderWorkplaceMealsPrWorkplacesReport = this._renderWorkplaceMealsPrWorkplacesReport.bind(
			this
		);
	}

	/**
	 * @method _onExpandHandler
	 * @memberof EmployeeMealsMarkets
	 * @param {integer} rowNumber - the index of the row being clicked
	 * @param {Object} entry - The clicked entry
	 */
	_onExpandHandler(rowNumber, entry) {
		const { selectedPerson, hotbarData } = this.props;
		const workplace = entry.workplace;

		this.state.expandedRows.has(rowNumber)
			? this.state.expandedRows.delete(rowNumber)
			: this.state.expandedRows.add(rowNumber);

		this.setState(
			prevState => ({
				loading: [...prevState.loading, rowNumber], // add rowNum to array
				expandedRows: prevState.expandedRows
			}),
			() => {
				if (_isEmpty(selectedPerson)) {
					// tweak workplace object so that the page doesn't crash
					const betterWorkplaceObject = {
						...workplace,
						marketId: _get(workplace, 'market.id', null)
					};

					/* Get Workplaces */
					this.props
						.fetchEmployeeMealsReportPrWorkplace(
							hotbarData,
							betterWorkplaceObject
						)
						.then(() => {
							this.setState(() => ({
								loading: this.state.loading.filter(x => x !== rowNumber)
							}));
						});
				} else {
					/* Get Filtered Persons */
					this.props
						.fetchEmployeeMealsPrEmployee(
							workplace.market.id,
							workplace.id,
							selectedPerson.value
						)
						.then(() => {
							this.setState(() => ({
								loading: this.state.loading.filter(x => x !== rowNumber) // remove from array
							}));
						});
				}
			}
		);
	}

	_renderRows() {
		return !_isEmpty(this.props.reportData.stores)
			? this.props.reportData.stores.map((entry, index) => {
				return (
					<Row
						key={entry.id}
						index={index}
						type="entry"
						totalColSpan={this.props.totalColSpan}
						onClick={() => this._onExpandHandler(index, entry)}
						expanded={this.state.expandedRows.has(index)}
						renderExpanded={rowNumber => {
							const selectedPerson = this.props.selectedPerson;
							const workplaceId = this.props.reportData.stores[rowNumber]
								.workplace.id;
							const marketId = this.props.reportData.stores[rowNumber]
								.workplace.market.id;

							// Get data by person or workplace
							const reportData = selectedPerson
								? this.props.employeeMealsReportPrPerson.filter(
									entry => entry.person.id === selectedPerson.value
									  )
								: this.props.employeeMealsReportPrWorkplace.filter(
									entry => entry.workplace.id === workplaceId
									  );

							/*  Loader */
							if (this.state.loading.includes(index) && _isEmpty(reportData))
								return <Loader />;

							if (selectedPerson) {
								/* Get Filtered Persons */
								/* Filter only selected row stores data */
								const filteredData = reportData.filter(
									entry => entry.workplace.id === workplaceId
								);

								return (
									<EmployeeMealsPrPerson
										totalColSpan={this.props.totalColSpan}
										reportData={filteredData}
									/>
								);
							} else {
								/* Get Workplaces */
								return this._renderWorkplaceMealsPrWorkplacesReport({
									reportData,
									marketId
								});
							}
						}}>
						<TableCell
							colSpan="3"
							content={_get(entry, 'workplace.name', null)}
							alignLeft
						/>

						<TableCell
							cellType={constants.CELL_TYPES.INTEGER}
							colSpan="2"
							alignRight
							separate
							content={_get(entry, 'employee_meal.gross_turnover', null)}
						/>

						<TableCell
							cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
							colSpan="2"
							alignRight
							separate
							content={_get(entry, 'employee_meal.hours', null)}
						/>
						<TableCell
							cellType={constants.CELL_TYPES.INTEGER}
							colSpan="2"
							alignRight
							separate
							content={_get(entry, 'employee_meal.products', null)}
						/>
						<TableCell
							cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
							colSpan="2"
							alignRight
							separate
							content={_get(entry, 'employee_meal.products_per_shifts', null)}
						/>
						<TableCell
							cellType={constants.CELL_TYPES.INTEGER}
							colSpan="2"
							alignRight
							separate
							content={_get(entry, 'employee_meal.shifts', null)}
						/>
					</Row>
				);
			  })
			: null;
	}

	_renderWorkplaceMealsPrWorkplacesReport({ reportData, marketId }) {
		return (
			<div className="employee-meals-report__workplaces">
				<Table marginBottom fixed>
					<Body>
						<EmployeeMealsPrWorkplacesReport
							totalColSpan={this.props.totalColSpan}
							showProductsPrShift={this.props.showProductsPrShift}
							employeeMealsReportPrWorkplace={
								this.props.employeeMealsReportPrWorkplace
							}
							employeeMealsReportPrPerson={
								this.props.employeeMealsReportPrPerson
							}
							fetchEmployeeMealsReportPrWorkplace={
								this.props.fetchEmployeeMealsReportPrWorkplace
							}
							fetchEmployeeMealsPrEmployee={
								this.props.fetchEmployeeMealsPrEmployee
							}
							reportData={reportData}
							marketId={marketId}
						/>
					</Body>
				</Table>
			</div>
		);
	}

	_renderTotals() {
		const total = this.props.reportData.total || {};
		return (
			!_isEmpty(total) && (
				<Row type="total">
					<TableCell colSpan="3" content="Total" alignLeft />
					<TableCell
						separate
						colSpan="2"
						cellType={constants.CELL_TYPES.INTEGER}
						content={total.employee_meal.gross_turnover}
						alignRight
					/>
					<TableCell
						separate
						colSpan="2"
						cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
						content={total.employee_meal.hours}
						alignRight
					/>
					<TableCell
						separate
						colSpan="2"
						cellType={constants.CELL_TYPES.INTEGER}
						content={total.employee_meal.products}
						alignRight
					/>
					<TableCell
						separate
						colSpan="2"
						cellType={constants.CELL_TYPES.NUMBER_SINGLE_DECIMAL}
						content={total.employee_meal.products_per_shifts}
						alignRight
					/>
					<TableCell
						separate
						colSpan="2"
						cellType={constants.CELL_TYPES.INTEGER}
						content={total.employee_meal.shifts}
						alignRight
					/>
				</Row>
			)
		);
	}

	render() {
		const { marketName, period, selectedPerson } = this.props;

		const reportTitle = `Employee meals for ${getStringBasedOnSelectedValues([
			...(selectedPerson ? [selectedPerson.label] : []),
			marketName
		])}`;

		return (
			<Report>
				<Title title={reportTitle} />
				<Title title={period} rightAligned />
				<Table fixed>
					<Head>
						<Row>
							<HeaderCell
								colSpan="3"
								content={phrases.TABLE_HEADER_WORKPLACE}
								alignLeft
							/>
							<HeaderCell
								separate
								colSpan="2"
								content={phrases.TABLE_HEADER_GROSS_TURNOVER}
							/>
							<HeaderCell
								separate
								colSpan="2"
								content={phrases.TABLE_HEADER_HOURS}
							/>
							<HeaderCell
								separate
								colSpan="2"
								content={phrases.TABLE_HEADER_PRODUCTS}
							/>
							<HeaderCell
								separate
								colSpan="2"
								content={phrases.TABLE_HEADER_PRODUCTS_PER_SHIFTS}
							/>
							<HeaderCell
								separate
								colSpan="2"
								content={phrases.TABLE_HEADER_SHIFTS}
							/>
						</Row>
					</Head>

					<Body>
						{this._renderRows()}
						{this._renderTotals()}
					</Body>
				</Table>
			</Report>
		);
	}
}

EmployeeMealsMarkets.propTypes = {
	reportData: PropTypes.object,
	showProductsPrShift: PropTypes.string,
	marketName: PropTypes.string,
	totalColSpan: PropTypes.number,
	employeeMealsReportPrWorkplace: PropTypes.array,
	employeeMealsReportPrPerson: PropTypes.array,
	fetchEmployeeMealsReportPrWorkplace: PropTypes.func,
	fetchEmployeeMealsPrEmployee: PropTypes.func,
	selectedPerson: PropTypes.object,
	hotbarData: PropTypes.object,
	period: PropTypes.string
};

EmployeeMealsMarkets.defaultProps = {
	reportData: {}
};

export default EmployeeMealsMarkets;
