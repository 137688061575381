// interface
import { ActionType } from 'types/redux';
import {
	ICurrencyDenominationReduxValue,
	ICurrencyDenominationReduxState,
} from '../types';

import {
	SET_CURRENCY_DENOMINATION,
	UPDATE_CURRENCY_DENOMINATION,
	RESET_STATE,
} from './currencyDenominations.actions';

const defaultState: ICurrencyDenominationReduxState = {
	data: {
		defaultCurrencyDenomination: null,
		currencyDenomination: {
			type: null,
			denomination: null,
		},
	},
};

function reducer(
	state = defaultState,
	action: ActionType
): ICurrencyDenominationReduxState {
	const { type, payload } = action;

	switch (type) {
	case SET_CURRENCY_DENOMINATION: {
		const castedPayload = payload as ICurrencyDenominationReduxValue;

		return {
				...state,
				data: {
					currencyDenomination: castedPayload,
					defaultCurrencyDenomination: castedPayload,
				},
			};
	}

	case UPDATE_CURRENCY_DENOMINATION: {
		const castedPayload = payload as Partial<ICurrencyDenominationReduxValue>;

		return {
				...state,
				data: {
					...state.data,
					currencyDenomination: {
						...state.data.currencyDenomination,
						...castedPayload,
					},
				},
			};
	}

	case RESET_STATE: {
		return defaultState;
	}

	default:
		return state;
	}
}

export default reducer;
