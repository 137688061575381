export default {
	TITLE: 'Nutrition/Allergen info',
	DATE_LABEL: 'Date',
	DATE_PLACEHOLDER: 'Select date...',
	WORKPLACE_LABEL: 'Workplace',
	WORKPLACE_PLACEHOLDER: 'Select workplace...',
	VIEW_ALLERGENS_NUTRITION_LABEL: 'View allergens/nutrition info',
	VIEW_NUTRITION_LABEL: 'View nutrition',
	ALLERGENS: 'Allergens',
	NUTRITION_INFO: 'Nutrition info',
	BACK: 'Back',
	NO_DATA: 'No data',
};
