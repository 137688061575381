'use strict';

import { ReportFactory as R } from 'report/factory';
import PosReportBaseColumnsService from './posReportBaseColumns.service';

const t = R.table();

const ProductSplitView = t.table([
	t.column('Product split',
		t.contentWrapper({
			body: [t.content(d => `${R.data(d, 'product.name').toUpperCase()}`)],
		}), PosReportBaseColumnsService.getFirstColumnWidth()
	),
	...PosReportBaseColumnsService.generateProductSplit()
]);

ProductSplitView.setHeader(false);
ProductSplitView.setTotal(false);

export default ProductSplitView;