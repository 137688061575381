'use strict';

import { HotbarComponentAbstract } from 'hotdamnbar/factory';

/**
 * DateRange
 * @param  {string} title
 * @param  {bool} required
 * @param  {bool} optionalEndDate
 * @param  {object} defaultStartDate
 * @param  {object} defaultEndDate
 */
class DateRange extends HotbarComponentAbstract {

	constructor(title, required = false, optionalEndDate = false, defaultStartDate = null, defaultEndDate = null) {
		super(title, required);

		// Set type
		this.type = 'dateRange';

		///////////////////////
		// Optional end date //
		///////////////////////

		this.optionalEndDate = optionalEndDate;

		//////////////////
		// Default date //
		//////////////////

		this.defaultStartDate = defaultStartDate;
		this.defaultEndDate = defaultEndDate;
	}

	/**
	 * @function isValid
	 * @memberOf DateRange
	 * @description Overwrite default return true with at check of
	 */
	isValid() {
		return (this.value !== null && this.value.startDate !== null && (this.optionalEndDate ? true : this.value.endDate !== null));
	}

}

export default DateRange;