'use strict';

import { EDIT_USER, SET_USER, RESET_STATE } from './usersTable.actions';

const initialState = {
	userToEdit: {}
};

export default (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
	case SET_USER:
		return {
			...state,
			userToEdit: payload
		};

	case EDIT_USER:
		return {
			...state,
			userToEdit: {
				...state.userToEdit,
				...payload
			}
		};

	case RESET_STATE:
		return {
			...initialState
		};

	default:
		return state;
	}
};
