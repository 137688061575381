import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import "./table-simple.css";

export default class TableSimple extends PureComponent {
	render() {
		const { className, children, type } = this.props;

		const classN = classnames('j-table-simple', {
			[`j-table-simple--${type}`]: type,
			[`${className}`]: className
		});

		return (
			<table className={classN}>
				{children}

				{/* In case there are issues with rendering */}
				{/* {React.Children.map(children, (child, i) =>
					<div key={i} className="staff-details__section">
						{child}
					</div>
				)} */}
			</table>
		);
	}
}

TableSimple.propTypes = {
	// shared props
	className: PropTypes.string,
	children: PropTypes.node,
	type: PropTypes.string

	// methods
};
