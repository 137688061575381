import React from 'react';
import PropTypes from 'prop-types';

// components
import { Tabs } from 'dumb';
import SubTable from 'reactDataWrapper/components/subTable';

// tables
import ProductVariantIngredients from './components/productVariantIngredients/productVariantIngredients.component';
import ProductVariantAddons from './components/productVariantAddons/productVariantAddons.component';
import ProductVariantDiscountSet from './components/productVariantDiscountSet/productVariantDiscountSet.component';
import ProductVariantWorkplaceRelations from './components/productVariantWorkplaceRelations/productVariantWorkplaceRelations.component';
import SalesConfigurationPosConfigurationDiscountProductVariant from './../../../../../globalDiscounts/components/salesConfigurationPosConfigurationDiscountProductVariant/salesConfigurationPosConfigurationDiscountProductVariant.component';
import TileLayouts from './../../../../../layout/tileLayouts/tileLayout.component';
import ProductVariantPricings from './../../../../../pricing/components/posConfigurationProductVariantsPricing/posConfigurationProductVariantsPricing.container';
import ProductVariantTranslations from './components/productVariantTranslations/productVariantTranslations.container';

// phrases
import phrases from './../../productVariant.phrases';

const SubTablesWrapper = ({ row, haveSalesConfigurationReadOnlyAccess }) => {
	const tabContent = !haveSalesConfigurationReadOnlyAccess
		? [
				{
					name: phrases.PRODUCT_VARIANT_INGREDIENTS,
					component: (
						<ProductVariantIngredients productVariant={row.original} />
					),
				},
				{
					name: phrases.PRODUCT_VARIANT_ADDONS,
					component: (
						<ProductVariantAddons
							title={phrases.PRODUCT_VARIANT_ADDONS}
							productVariant={row.original}
						/>
					),
				},
				{
					name: phrases.PRODUCT_DISCOUNT_SETS,
					component: (
						<ProductVariantDiscountSet
							title={phrases.PRODUCT_DISCOUNT_SETS}
							productVariant={row.original}
						/>
					),
				},
				{
					name: phrases.PRODUCT_VARIANT_PRICINGS,
					component: <ProductVariantPricings productVariant={row.original} />,
				},
				{
					name: phrases.GLOBAL_PRODUCT_DISCOUNTS,
					component: (
						<SalesConfigurationPosConfigurationDiscountProductVariant
							productVariant={row.original}
						/>
					),
				},
				{
					name: phrases.PRODUCT_VARIANT_WORKPLACE_RELATION,
					component: (
						<ProductVariantWorkplaceRelations productVariant={row.original} />
					),
				},
				{
					name: phrases.TILE_LAYOUT_TILES,
					component: <TileLayouts productVariantId={row.original.id} />,
				},
				{
					name: phrases.TRANSLATIONS,
					component: (
						<ProductVariantTranslations productVariant={row.original} />
					),
				},
		  ]
		: [
				{
					name: phrases.PRODUCT_VARIANT_INGREDIENTS,
					component: (
						<ProductVariantIngredients
							title={phrases.PRODUCT_VARIANT_INGREDIENTS}
							productVariant={row.original}
						/>
					),
				},
		  ];

	return (
		<SubTable>
			<Tabs tabContent={tabContent} />
		</SubTable>
	);
};

SubTablesWrapper.propTypes = {
	row: PropTypes.object,
	haveSalesConfigurationReadOnlyAccess: PropTypes.bool,
};

export default SubTablesWrapper;
