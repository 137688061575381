import {
	SET_ASSET,
	UPDATE_ASSET,
	RESET_ASSET,
	SET_IMG_RESIZER_USED
} from './assets.actions';

const defaultState = {
	data: {
		defaultAssets: {},
		imgResizerUsed: false
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_ASSET: {
		return {
			data: {
				...state.data,
				defaultAssets: payload
			}
		};
	}

	case UPDATE_ASSET: {
		return {
			data: {
				...state.data,
				defaultAssets: {
					...state.data.defaultAssets,
					...payload
				}
			}
		};
	}

	case RESET_ASSET: {
		return {
			data: {
				defaultAssets: defaultState.data.defaultAssets
			}
		};
	}

	case SET_IMG_RESIZER_USED: {
		return {
			data: {
				...state.data,
				imgResizerUsed: payload
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
