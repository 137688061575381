'use strict';

import ImportCsvModal from './importCsvModal';

class ImportCsvModalFactory {
	
	constructor(){
	}

	/**
	 * @function importCsvModal
	 * @memberOf ImportCsvModalFactory
	 * @param {string} name
	 */
	importCsvModal(name) {
		return new ImportCsvModal(name);
	}
	

	
}

export default new ImportCsvModalFactory();