'use strict';

import cx from 'classnames';
// import ImageCell from 'list/parts/imageCell';
import _each from 'lodash/each';
import _every from 'lodash/every';
import _filter from 'lodash/filter';
import _has from 'lodash/has';
import _isNull from 'lodash/isNull';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import _template from 'lodash/template';
import _head from 'lodash/head';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CustomInput from './customInput/customInput.component';

class Cell extends Component {
	/**
	 * @function _conditionalClass
	 * @memberOf DIRECTIVES.jtjList.component.cell
	 * @description Checks every cell for conditionalClassNames
	 * @param  {object} cell Object containing cell data which comes from header (e.g. type = string, date etc.)
	 * @param  {object} row  Object containing row data which is specific to each row
	 * @return {object}      ClassName and bool
	 */
	_conditionalClass(cell, row) {
		if (_has(cell, 'conditionalClassNames')) {
			var name = _has(cell, 'reference') ? cell.reference.newName : cell.name;
			var conditionalClassNamesObj = {};
			_each(cell.conditionalClassNames, c => {
				conditionalClassNamesObj[c.className] = c.condition(row[name], row);
			});
			return conditionalClassNamesObj;
		} else return {};
	}

	/**
	 * @function _renderFirstColumnContent
	 * @memberOf DIRECTIVES.jtjList.component.cell
	 * @description Renders span with first column content
	 * @param  {object} cell  Object containing cell data
	 * @param  {object} row  Object containing row data
	 */
	_renderFirstColumnContent(cell, row) {
		// Get name for cell
		var name = _has(cell, 'reference') ? cell.reference.newName : cell.name;

		// Render
		if (!_isUndefined(row) && !_isNull(row[name])) {
			// Set Styles
			var styles = {
				transform: 'translate(' + this.props.firstColumnOffset + 'px, 0px)',
				opacity: this.props.firstColumnOffset > 0 ? 1 : 0,
				visibility: this.props.firstColumnOffset > 0 ? 'visible' : 'hidden'
			};

			return (
				<span
					style={styles}
					className="list__first-column-value"
					data-first-column={row[name]}
				/>
			);
		} else return null;
	}

	/**
	 * @function _renderRowAction
	 * @memberOf DIRECTIVES.jtjList.component.cell
	 * @description Renders custom action for current row based on headers with type="action"
	 * @param  {object} cell  Object containing cell data
	 * @param  {object} row  Object containing row data
	 * @param  {object} cssClasses
	 * @param  {object} styling
	 */
	_renderRowAction(cell, row, cssClasses, styling) {
		// Get name for cell
		var name = _has(cell, 'reference') ? cell.reference.newName : cell.name;

		// Get value
		var value = row[name];

		// Get action object
		var action = cell.action(this.props, value, row);

		// Set icons
		var iconClass = cx(
			'list__cell-contents',
			'list__cell-contents--link',
			'list__cell-contents--center',
			'icon',
			action.icon
		);

		// Render button function if validExpressions are true
		if (_every(action.validExpressions)) {
			return (
				<td
					onClick={action.method.bind(this)}
					style={styling}
					className={cx(cssClasses, 'list__cell__action-column')}>
					<a
						data-text={this.props.translations[action.name.toUpperCase()]}
						className={iconClass}
					/>
				</td>
			);
		}

		// Render disabled button if not - this is a difference from the other row actions, as their cells are not
		// rendered if the expressions are untrue. This is because it is often affected by value in this case, and the
		// user is interested in seeing a button no matter value/state.
		else
			return (
				<td
					style={styling}
					className={cx(
						cssClasses,
						'list__cell__action-column',
						'list__cell__action-column--disabled'
					)}>
					<a
						data-text={this.props.translations[action.name.toUpperCase()]}
						className={cx(iconClass)}
					/>
				</td>
			);
	}

	/**
	 * @function _renderCellContents
	 * @memberOf DIRECTIVES.jtjList.component.cell
	 * @description Renders contents of cell based on type.
	 * @param  {object} cell Object containing cell data which comes from header (e.g. type = string, date etc.)
	 * @param  {object} row  Object containing row data which is specific to each row
	 * @return {object}      Cell contents in JSX
	 */
	_renderCellContents(cell, row) {
		var name = _has(cell, 'reference') ? cell.reference.newName : cell.name;
		var t = cell.type;

		if (!_isUndefined(row) && !_isNull(row[name])) {
			// Get type

			// Get classes for current cell
			var spanClasses = cx('list__cell-contents', {
				'list__cell-contents--long-string': t === 'longString',
				'list__cell-contents--currency': t === 'currency',
				'list__cell-contents--nowrap':
					t === 'mail' ||
					t === 'date' ||
					t === 'datetime' ||
					t === 'time' ||
					t === 'timetime',
				'list__cell-contents--image': t === 'image',
				'list__cell-contents--center': t === 'action',
				'list__cell-contents--link': t === 'action',
				'list__cell-contents--color': t === 'color'
			});

			// Get value
			var value;
			switch (t) {
			// Default text string
			case 'id':
			case 'string':
			case 'select':
			case 'mail':
			case 'longString':
				// Check for enums, in case we need to pair a value from server with label from __enums
				if (_has(cell, 'options.enums')) {
					var filteredValues = _filter(cell.options.enums, {
						value: row[name]
					});
					value =
							filteredValues.length > 0
								? _head(filteredValues).label
								: row[name];
				} else if (_has(cell, 'labelTemplate')) {
					value = _template(cell.labelTemplate)(row);
				} else value = row[name];
				break;

				// Number
			case 'number':
				value = numeral(parseFloat(row[name])).format('0,0.00');
				break;

				// Boolean
			case 'bool':
				value = cx('list__cell-contents__bool', {
					'list__cell-contents__bool--false': !row[name],
					'list__cell-contents__bool icon icon--check': row[name]
				});
				break;

				// Array data
			case 'multi':
				value =
						!_isUndefined(row[name]) && row[name].length > 0 ? row[name] : [];
				break;

				// Image source
			case 'image':
				value = _has(row, 'base64')
					? 'data:image/png;base64,' + row.base64
					: '/images/ui/people-avatar.png';
				break;

				// Currency
			case 'currency':
				value = numeral(parseFloat(row[name])).format('0,0.00');
				break;

				// Date, time, datetime,
			case 'date':
				const date = moment.utc(row[name]);
				value = moment(date).isSame('9999-12-31', 'day')
					? ''
					: date.format('DD-MM-YYYY');
				break;
			case 'time':
				value = moment.utc(row[name], 'hh:mm:ss').format('HH:mm:ss');
				break;
			case 'timetime':
				value = moment
					.utc(row[name], 'hh:mm:ss')
					.format('YYYY-MM-DD hh:mm:ss a');
				break;
			case 'datetime':
				value = moment.utc(row[name]).format('YYYY-MM-DD hh:mm:ss a');
				break;
			case 'color':
				value = row[name];
				break;
			case 'empty':
				value = '';
				break;
			case 'imageCell':
				if (!cell.imageCell) throw new Error('cell.imageCell is not defined');

				value = cell.imageCell(row);
				break;

			case 'custom':
				if (!cell.custom) throw new Error('cell.custom is not defined');

				value = cell.custom(row);
				break;
			}

			// Render
			switch (t) {
			case 'id':
			case 'string':
			case 'number':
			case 'select':
			case 'mail':
			case 'longString':
			case 'currency':
			case 'date':
			case 'time':
			case 'timetime':
			case 'datetime':
			case 'empty':
			case 'imageCell':
			case 'custom':
				return <span className={spanClasses}>{value}</span>;
			case 'color':
				return (
					<span className={spanClasses}>
						<span
							data-color={`#${value}`}
							style={{ background: `#${value}` }}
						/>
					</span>
				);
			case 'bool':
				return (
					<span className={spanClasses}>
						<span className={value} />
					</span>
				);
			case 'image':
				return (
					<div className={spanClasses}>
						<img src={value} alt="" />
					</div>
				);
			case 'multi':
				return _map(value, function(item, index) {
					return (
						<span key={index} className="list__cell-contents">
							{item[cell.reference.key]}
						</span>
					);
				});
			}
		} else if (_has(cell, 'valueIfNull'))
			return <span className="list__cell-contents">{cell['valueIfNull']}</span>;
	}

	render() {
		if (this.props.cell) {
			// Define defaults - cell, row, markup, styling and cx
			var cell = this.props.cell;
			var row = this.props.row;
			var cellStyles = cell.styles || {};
			var cellClassNames = cx(
				cell.className || '',
				this._conditionalClass(cell, row),
				'list__cell'
			);

			if (_has(cell, 'action'))
				return this._renderRowAction(cell, row, cellClassNames, cellStyles);
			else if (_has(cell, 'customInput'))
				return (
					<td className={cellClassNames} style={cellStyles}>
						<CustomInput
							header={cell}
							row={row}
							onChange={cell.customInput.onChange}
						/>
					</td>
				);
			else
				return (
					<td className={cellClassNames} style={cellStyles}>
						{this._renderCellContents(this.props.cell, this.props.row)}
						{this.props.firstColumn
							? this._renderFirstColumnContent(this.props.cell, this.props.row)
							: null}
					</td>
				);
		} else return null;
	}
}

Cell.propTypes = {
	cell: PropTypes.object.isRequired,
	firstColumn: PropTypes.bool,
	firstColumnOffset: PropTypes.number,
	row: PropTypes.object,
	translations: PropTypes.object
};

export default Cell;
