import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon } from 'dumb';

import './buttonTile.css';

export default class ButtonTile extends PureComponent {
	render() {
		const {
			children,
			className,
			size,
			type,
			onClick,
			disabled,
			shadow,
			onKeyDown,
			title,
			icon,
			refProp,
			onMouseEnter,
			onMouseLeave,
		} = this.props;

		const classNames = cx('j-button-tile', {
			[`${className}`]: className,
			[`j-button-tile--${size}`]: size,
			[`j-button-tile--${type}`]: type,
			[`j-button-tile--shadow`]: shadow,
		});

		return (
			<button
				className={classNames}
				disabled={disabled}
				onClick={onClick}
				onKeyDown={onKeyDown}
				title={title}
				ref={refProp}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}>
				{icon && <Icon name={icon} />}
				<span>{children}</span>
			</button>
		);
	}
}

ButtonTile.propTypes = {
	// shared props
	className: PropTypes.string,
	children: PropTypes.any,
	type: PropTypes.string,
	size: PropTypes.string,
	onClick: PropTypes.func,
	onKeyDown: PropTypes.func,
	disabled: PropTypes.bool,
	shadow: PropTypes.bool,
	title: PropTypes.string,
	icon: PropTypes.string,
	refProp: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
};
