'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
const __endpoints = require('endpoints.service');

export default class CurrencyCurrencyRatesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'rate',
				type: 'number',
			},
			{
				name: 'start_date',
				type: 'date',
			},
			{
				name: 'end_date',
				type: 'date',
			},
		];
		this.settings = {
			name: 'CurrencyCurrencyRatesList',
			alwaysRefresh: true,
			canCollapse: true,
			translationPath: 'ADMINISTRATION.CURRENCY_RATES',
			endpoint: __endpoints.collection.administration.currency_rates,
		};
		this.init();
		return this;
	}
}
