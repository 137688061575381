import constants from 'services/constants';
import moment from 'moment';
import {
	RESET_INGREDIENT_PRICING,
	SET_INGREDIENT_PRICING,
	UPDATE_INGREDIENT_PRICING,
	UPDATE_BATCH_FORM,
	ADD_BATCH_LIST,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	SET_POS_CONFIGURATION_INGREDIENT_PRICINGS_DATE_FILTER,
	SET_POS_CONFIGURATION_INGREDIENT_PRICINGS_LEGACY_FILTER
} from './salesConfigurationIngredientPricings.actions';

const todayDate = moment.utc().format(constants.shortDate);
const posConfigurationIngredientPricingsDateFilter = `:pos_configuration_version.active.from=le='${todayDate}';:pos_configuration_version.active.to=ge='${todayDate}'`;

const defaultState = {
	data: {
		ingredientPricing: {},
		batchFormData: {
			availableInApp: true
		},
		batchList: []
	},
	posConfigurationIngredientPricingsDateFilter,
	legacyFilter: ':pos_configuration_version.pos_configuration.legacy==false',
	legacyFilterOn: true,
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case UPDATE_BATCH_FORM: {
		return {
			...state,
			data: {
				...state.data,
				batchFormData: {
					...state.data.batchFormData,
					...payload
				}
			}
		};
	}

	case ADD_BATCH_LIST: {
		return {
			...state,
			data: {
				...state.data,
				batchList: payload
			}
		};
	}

	case EDIT_BATCH_LIST_ITEM: {
		return {
			...state,
			data: {
				...state.data,
				batchList: state.data.batchList.map(entry => {
					if (entry.identifier === payload.identifier) return payload;
					return entry;
				})
			}
		};
	}

	case REMOVE_BATCH_LIST_ITEM: {
		return {
			...state,
			data: {
				...state.data,
				batchList: state.data.batchList.filter(
					entry => entry.identifier !== payload
				)
			}
		};
	}

	case SET_INGREDIENT_PRICING: {
		return {
			...state,
			data: {
				...state.data,
				ingredientPricing: payload
			}
		};
	}

	case UPDATE_INGREDIENT_PRICING: {
		return {
			...state,
			data: {
				...state.data,
				ingredientPricing: {
					...state.data.ingredientPricing,
					...payload
				}
			}
		};
	}
	case SET_POS_CONFIGURATION_INGREDIENT_PRICINGS_DATE_FILTER: {
		return {
			...state,
			posConfigurationIngredientPricingsDateFilter: payload
		};
	}

	case SET_POS_CONFIGURATION_INGREDIENT_PRICINGS_LEGACY_FILTER: {
		return {
			...state,
			legacyFilter: payload,
		};
	}

	case RESET_INGREDIENT_PRICING: {
		return {
			...state,
			data: defaultState.data
		};
	}

	default:
		return state;
	}
}

export default reducer;
