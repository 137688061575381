'use strict';

/**
 * CustomComponent
 * @param  {string} title
 * @param  {object} component
 */
class CustomComponent {
	constructor(title, component) {
		// Set type
		this.type = 'customComponent';

		// Set title
		this.title = title;

		// Set component
		this.Component = component;
	}
}

export default CustomComponent;