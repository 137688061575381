// src/utils/AuthService.js

import { LocalStorageService } from 'localStorage';

import LogRocket from 'logrocket';
import { store } from 'appState';

import { setUser, clearUser } from 'user/actions';

import * as Sentry from '@sentry/browser';

class UserService {
	constructor() {
		this.setUser = this.setUser.bind(this);
		this.clearUser = this.clearUser.bind(this);
		this.getUser = this.getUser.bind(this);
		this.clearUser = this.clearUser.bind(this);
	}

	setUser(user = {}) {
		// Saves user token to local storage

		store.dispatch(setUser(user));
		// User is logged in. Set context of Sentry
		Sentry.setUser({ email: user.email, id: user.username });
		const logrocketId = user?.user?.person ? user.user.person.id : user.user.username;
		LogRocket.identify(logrocketId);
	}

	/**
	 * @function getUser
	 * @description Returns user from local storage
	 */
	getUser() {
		return store?.getState()?.userData?.user;
	}

	/**
	 * @function clearUser
	 * @description Removes user from localStorage and from class
	 */
	clearUser() {
		return new Promise((resolve) => {
			store.dispatch(clearUser());
			LocalStorageService.removeItem('user', null);
			resolve();
		});
	}
}

export default new UserService();
