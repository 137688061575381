import React from 'react';
import PropTypes from 'prop-types';

// components
import { Modal } from 'dumb';
import { ModalRow } from 'dumb/modal';

// phrases
import phrases from './../../homeScreenRecommendations.phrases';

const DeleteTimeSlotModal = ({ onClose, onConfirmClick, loading }) => {
	return (
		<Modal
			isOpen
			loading={loading}
			onClose={onClose}
			onConfirmClick={onConfirmClick}
			header={phrases.DELETE_TIME_SLOT}
			confirmButtonLabel={phrases.DELETE_TIME_SLOT_CONFIRM}
			type="confirmation">
			<ModalRow>
				<span>{phrases.DELETE_TIME_SLOT_CONFIRMATION}</span>
			</ModalRow>
		</Modal>
	);
};

DeleteTimeSlotModal.propTypes = {
	onClose: PropTypes.func,
	onConfirmClick: PropTypes.func,
	loading: PropTypes.bool,
};

export default DeleteTimeSlotModal;
