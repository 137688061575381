export const RESET_FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENT = 'SALES_CONFIGURATION_FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENTS/RESET_FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENT';
export const SET_FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENT = 'SALES_CONFIGURATION_FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENTS/SET_FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENT';
export const UPDATE_FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENT = 'SALES_CONFIGURATION_FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENTS/UPDATE_FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENT';

export const UPDATE_BATCH_FORM =
	'SALES_CONFIGURATION_FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENTS/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'SALES_CONFIGURATION_FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENTS/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENTS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENTS/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'SALES_CONFIGURATION_FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENTS/ADD_BATCH_LIST_ITEM_ROW';

/**
 * @param {object} payload
 */
export function setFilterProductVariantIngredientVariationDefaultReplacement(payload) {
	return {
		type: SET_FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENT,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function updateFilterProductVariantIngredientVariationDefaultReplacement(payload) {
	return {
		type: UPDATE_FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENT,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetFilterProductVariantIngredientVariationDefaultReplacement() {
	return {
		type: RESET_FILTER_PRODUCT_VARIANT_INGREDIENT_VARIATION_DEFAULT_REPLACEMENT,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}
