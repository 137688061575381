import {
	SET_TABLE_FILTER,
	SET_DUPLICATE_LIST_DATA,
	CLEAR_TABLE_FILTERS,
} from './productVariantWorkplaceRelations.actions';

const initialState = {
	tableFilters: {},
	duplicateListData: [],
};

function reducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_TABLE_FILTER:
			return {
				...state,
				tableFilters: {
					...state.tableFilters,
					...payload,
				},
			};

		case CLEAR_TABLE_FILTERS:
			return {
				...state,
				tableFilters: initialState.tableFilters,
			};

		case SET_DUPLICATE_LIST_DATA:
			return {
				...state,
				duplicateListData: payload,
			};

		default:
			return state;
	}
}

export default reducer;
