'use strict';

export const LOADING_REPORT = 'KPI_REPORT/LOADING_REPORT';
export const SET_HOTBAR_DATA = 'KPI_REPORT/SET_HOTBAR_DATA';
export const CLEAN_DATA = 'KPI_REPORT/CLEAN_DATA';
export const SET_KPI_REPORT = 'KPI_REPORT/SET_KPI_REPORT';
// export const TOGGLE_EXPAND_ALL_MARKETS = 'KPI_REPORT/TOGGLE_EXPAND_ALL_MARKETS';
export const TOGGLE_EXPAND_ROW = 'KPI_REPORT/TOGGLE_EXPAND_ROW';
// export const DISABLE_ROW_INTERACTION = 'KPI_REPORT/DISABLE_ROW_INTERACTION';
// export const SET_DAILY_REPORT_EMAIL_RECIPIENTS = 'KPI_REPORT/SET_DAILY_REPORT_EMAIL_RECIPIENTS';

export function toggleExpandRow(payload) {
	return {
		type: TOGGLE_EXPAND_ROW,
		payload
	};
}

export function setLoadingReport(loadingReport) {
	return {
		type: LOADING_REPORT,
		payload: {
			loadingReport
		}
	};
}

export function cleanData() {
	return {
		type: CLEAN_DATA
	};
}

export function setHotbarData(hotbarData) {
	return {
		type: SET_HOTBAR_DATA,
		payload: {
			hotbarData
		}
	};
}

export function setKpiReport(payload) {
	return {
		type: SET_KPI_REPORT,
		payload
	};
}
