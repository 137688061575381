export const RESET_STATE =
	'GLOBAL_ADMINISTRATION_WORKPLACE_WAITING_TIME_PERFORMANCE_GRIDS/RESET_STATE';
export const SET_WAITING_TIME_PERFORMANCE_GRID_ENTRIES =
	'GLOBAL_ADMINISTRATION_WORKPLACE_WAITING_TIME_PERFORMANCE_GRIDS/SET_WAITING_TIME_PERFORMANCE_GRID_ENTRIES';
export const UPDATE_WAITING_TIME_PERFORMANCE_GRID_ENTRIES =
	'GLOBAL_ADMINISTRATION_WORKPLACE_WAITING_TIME_PERFORMANCE_GRIDS/UPDATE_WAITING_TIME_PERFORMANCE_GRID_ENTRIES';

export const UPDATE_BATCH_FORM =
	'GLOBAL_ADMINISTRATION_WORKPLACE_WAITING_TIME_PERFORMANCE_GRIDS/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'GLOBAL_ADMINISTRATION_WORKPLACE_WAITING_TIME_PERFORMANCE_GRIDS/ADD_BATCH_LIST';
export const REMOVE_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_WORKPLACE_WAITING_TIME_PERFORMANCE_GRIDS/REMOVE_BATCH_LIST_ITEM';

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function setWaitingTimePerformanceGridEntries(payload) {
	return {
		type: SET_WAITING_TIME_PERFORMANCE_GRID_ENTRIES,
		payload,
	};
}

export function updateWaitingTimePerformanceGridEntries(payload) {
	return {
		type: UPDATE_WAITING_TIME_PERFORMANCE_GRID_ENTRIES,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}
