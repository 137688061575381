'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './menuItem.css';

const MenuItem = ({
	children,
	onClick,
	leftSection,
	rightSection,
	disabled,
	active
}) => {
	const onMenuItemClick = () => {
		!disabled && onClick();
	};

	return (
		<div
			className={cx('j-menu-item', {
				'j-menu-item--clickable': !!onClick,
				'j-menu-item--disabled': disabled,
				'j-menu-item--active': active
			})}
			onClick={onMenuItemClick}>
			{leftSection && (
				<div className="j-menu-item__left-section">{leftSection}</div>
			)}
			<div className="j-menu-item__middle-section">
				<span>{children}</span>
			</div>
			{rightSection && (
				<div className="j-menu-item__right-section">
					<span>{rightSection}</span>
				</div>
			)}
		</div>
	);
};

MenuItem.defaultProps = {
	leftSection: null,
	rightSection: null,
	disabled: false,
	onClick: () => {},
	active: false
};

MenuItem.propTypes = {
	children: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	leftSection: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
	rightSection: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
	disabled: PropTypes.bool,
	active: PropTypes.bool
};

export default MenuItem;
