import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'dumb';

import './defaultFooter.css';

class DefaultFooter extends Component {
	render() {
		const { buttonLabel, onClick, valid, tabIndex } = this.props;

		return (
			<div className="default-footer">
				<Button
					dataCy="modal-footer-default-submit-button"
					type="success"
					layout="full-width"
					onClick={onClick}
					tabIndex={tabIndex}
					disabled={!valid}
				>
					{buttonLabel}
				</Button>
			</div>
		);
	}
}

DefaultFooter.defaultProps = {
	buttonLabel: 'Save',
};

DefaultFooter.propTypes = {
	onClick: PropTypes.func,
	buttonLabel: PropTypes.string,
	valid: PropTypes.bool,
	tabIndex: PropTypes.number,
};

export default DefaultFooter;
