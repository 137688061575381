import _uniqBy from 'lodash/uniqBy';

import {
	SET_SHIFTPLANNER_REPORT,
	SET_SHIFTPLANNER_REPORT_PR_WORKPLACE,
	LOADING_REPORT,
	SET_HOTBAR_DATA,
	SHOW_HALF_HOURLY,
	CLEAN_DATA,
} from './shiftplannerReport.actions';


const defaultState = {
	ui: {
		loadingReport: false,
		showHalfHourly: false,
	},
	data: {
		shiftplannerReport: {},
		hotbarData: {},
		shiftplannerReportPrWorkplace: [],
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
	case LOADING_REPORT: {
		return {
			...state,
			ui: {
				...state.ui,
				loadingReport: payload.loadingReport
			}
		};
	}

	case SET_SHIFTPLANNER_REPORT: {
		return {
			...state,
			data: {
				...state.data,
				...payload
			},
		};
	}

	case SET_SHIFTPLANNER_REPORT_PR_WORKPLACE: {

		return {
			...state,
			data: {
				...state.data,
				shiftplannerReportPrWorkplace: _uniqBy([...state.data.shiftplannerReportPrWorkplace, ...payload.shiftplannerReportPrWorkplace], 'id')
			},
		};
	}

	case SET_HOTBAR_DATA: {
		return {
			...state,
			data: {
				...state.data,
				...payload
			},
		};
	}

	case SHOW_HALF_HOURLY: {
		return {
			...state,
			ui: {
				...state.ui,
				showHalfHourly: payload.showHalfHourly
			}
		};
	}

	case CLEAN_DATA: {
		return {
			...state,
			data: defaultState.data,
		};
	}

	default:
		return state;
	}
}

export default reducer;
