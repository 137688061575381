import {
	ADD_LOADER,
	SET_COLLECTION,
	SET_PRINT_DATA,
	SET_DATERANGE,
	SET_MARKET,
	SET_STORES,
	SET_HOTBAR_FILTER,
	SET_TOGGLE_PRINT_VIEW,
	SET_HEADER,
	SET_SHOW_SALARY_EXPORTS,
	SET_PERIOD_BOOKED,
} from './financialPosReport.actions';
import moment from 'moment';

const defaultState = {
	collection: [],
	printData: [],
	market: null,
	stores: [],
	hotbarFilter: [],
	dateRange: {
		afterDate: moment(),
		beforeDate: moment(),
	},
	matrice: {},
	params: {
		offset: 0,
		limit: 300,
	},
	showSalaryExports: false,
	activePrintView: null,
	isPeriodBooked: false,
	loaders: [],
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case ADD_LOADER: {
			let loaders = state.loaders;
			if (loaders.some((x) => x === payload)) {
				loaders = loaders.filter((x) => x !== payload);
			} else {
				loaders = [...loaders, payload];
			}

			return {
				...state,
				loaders,
			};
		}

		case SET_COLLECTION: {
			return {
				...state,
				collection: payload,
			};
		}

		case SET_DATERANGE: {
			return {
				...state,
				dateRange: payload,
			};
		}

		case SET_MARKET: {
			return {
				...state,
				market: payload,
			};
		}

		case SET_STORES: {
			return {
				...state,
				stores: payload,
			};
		}

		case SET_SHOW_SALARY_EXPORTS: {
			return {
				...state,
				showSalaryExports: payload,
			};
		}

		case SET_TOGGLE_PRINT_VIEW: {
			return {
				...state,
				activePrintView: payload,
			};
		}

		case SET_HOTBAR_FILTER: {
			return {
				...state,
				hotbarFilter: payload,
			};
		}

		case SET_PRINT_DATA: {
			return {
				...state,
				printData: payload,
			};
		}

		case SET_HEADER: {
			return {
				...state,
				header: payload,
			};
		}

		case SET_PERIOD_BOOKED:
			return {
				...state,
				isPeriodBooked: payload,
			};
		default:
			return state;
	}
}

export default reducer;
