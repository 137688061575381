'use strict';

// react stuff
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Input, Toggle, Button, Icon } from 'dumb';
import AssociatedIngredientsModalBatch from './components/associatedIngredientsModalBatch/associatedIngredientsModalBatch';

// services/ actions
import {
	fetchPosConfigurationIngredientAddons,
	addPosConfigurationIngredientAddons,
	editPosConfigurationIngredientAddons,
	deletePosConfigurationIngredientAddons,
} from './posConfigurationAssociatedIngredients.service';
import {
	updatePosConfigurationAssociatedIngredient,
	resetPosConfigurationAssociatedIngredient,
	setPosConfigurationAssociatedIngredient,
	// batch
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
} from './store/posConfigurationAssociatedIngredients.actions';

// phrases/constants/moment
import phrases from './posConfigurationAssociatedIngredients.phrases';
import constants from 'services/constants';
import moment from 'moment';

// utils
import _get from 'lodash/get';

class PosConfigurationAssociatedIngredients extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment(),
		};

		this.fetchData = this.fetchData.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addAssociatedIngredientsWrapper = this.addAssociatedIngredientsWrapper.bind(this);
		this.getActionRender = this.getActionRender.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.editEntry = this.editEntry.bind(this);

		this.columns = [
			{
				Header: 'Product ingredient',
				id: 'associated_ingredient_product_ingredient',
				accessor: (d) => _get(d, 'pos_configuration_ingredient.ingredient.name', ''),
				filterPath: ':pos_configuration_ingredient.ingredient.name',
			},
			{
				Header: 'Minimum',
				id: 'associated_ingredient_minimum',
				accessor: (d) => _get(d, 'minimum', ''),
				filterPath: ':minimum',
			},
			{
				Header: 'Default',
				id: 'associated_ingredient_default',
				accessor: (d) => _get(d, 'default', ''),
				filterPath: ':default',
			},
			{
				Header: 'Maximum',
				id: 'associated_ingredient_maximum',
				accessor: (d) => _get(d, 'maximum', ''),
				filterPath: ':maximum',
			},
			{
				Header: 'Deductible',
				id: 'associated_ingredient_deductible',
				accessor: (d) => _get(d, 'deductible', ''),
				Cell: (d) => {
					return <Input type="checkbox" disabled checked={d.original.deductible} />;
				},
				filterPath: ':deductible',
				filterable: false,
			},
			{
				Header: 'Sort order',
				id: 'associated_ingredient_sort_order',
				accessor: (d) => _get(d, 'sort_order', ''),
				filterPath: ':sort_order',
			},
			{
				Header: 'Created at',
				id: 'associated_ingredient_created',
				accessor: (d) => _get(d, 'created', ''),
				filterPath: ':created',
				show: false,
				Cell: (d) => {
					return <span>{moment.utc(d.original.created).format(constants.dateFormat)}</span>;
				},
			},
			{
				Header: 'Updated at',
				id: 'associated_ingredient_updated',
				accessor: (d) => _get(d, 'updated', ''),
				filterPath: ':updated',
				show: false,
				Cell: (d) => {
					return <span>{moment.utc(d.original.updated).format(constants.dateFormat)}</span>;
				},
			},
		];
	}

	editEntry() {
		const { posConfigurationAssociatedIngredient } = this.props;

		const payload = {
			id: posConfigurationAssociatedIngredient.id,
			minimum: posConfigurationAssociatedIngredient.minimum,
			default: posConfigurationAssociatedIngredient.default,
			maximum: posConfigurationAssociatedIngredient.maximum,
			deductible: posConfigurationAssociatedIngredient.deductible,
			sort_order: posConfigurationAssociatedIngredient.sortOrder,
		};

		return editPosConfigurationIngredientAddons(payload);
	}

	deleteEntry(id) {
		return deletePosConfigurationIngredientAddons(id);
	}

	getEditableCellsEdit() {
		const { posConfigurationAssociatedIngredient } = this.props;

		return [
			{
				header: phrases.MODAL_HEADER_POS_CONFIGURATION_INGREDIENT,
				value: <span>{_get(posConfigurationAssociatedIngredient, 'posConfigurationIngredient', null)}</span>,
			},
			{
				header: phrases.MODAL_HEADER_MINIMUM,
				value: (
					<Input
						id="associatedIngredientMinimum"
						placeholder="Enter minimum"
						type="number"
						onChange={(event) => this.editStoreEntry(event, 'minimum')}
						value={posConfigurationAssociatedIngredient.minimum}
					/>
				),
			},
			{
				header: phrases.MODAL_HEADER_DEFAULT,
				value: (
					<Input
						id="associatedIngredientDefault"
						placeholder="Enter default"
						type="number"
						onChange={(event) => this.editStoreEntry(event, 'default')}
						value={posConfigurationAssociatedIngredient.default}
					/>
				),
			},
			{
				header: phrases.MODAL_HEADER_MAXIMUM,
				value: (
					<Input
						id="associatedIngredientMaximum"
						placeholder="Enter maximum"
						type="number"
						onChange={(event) => this.editStoreEntry(event, 'maximum')}
						value={posConfigurationAssociatedIngredient.maximum}
					/>
				),
			},
			{
				header: phrases.MODAL_HEADER_DEDUCTIBLE,
				value: (
					<Toggle
						id="associatedIngredientDeductible"
						toggled={posConfigurationAssociatedIngredient.deductible}
						onClick={(event) => this.editStoreEntry(event, 'deductible')}
					/>
				),
			},
			{
				header: phrases.MODAL_HEADER_SORT_ORDER,
				value: (
					<Input
						id="associatedIngredientSortOrder"
						type="number"
						value={posConfigurationAssociatedIngredient.sortOrder}
						onChange={(event) => this.editStoreEntry(event, 'sortOrder')}
					/>
				),
			},
		];
	}

	editStoreEntry(e, name) {
		const { updatePosConfigurationAssociatedIngredient } = this.props;

		const value = e.target ? (_get(e, 'target.value', false) ? e.target.value : e.target.checked) : e;

		const payload = {
			[name]: value,
		};

		updatePosConfigurationAssociatedIngredient(payload);
	}

	setInitialEditValues(data) {
		const { setPosConfigurationAssociatedIngredient } = this.props;

		const payload = {
			id: data.id,
			posConfigurationIngredient: data.pos_configuration_ingredient.ingredient.name,
			minimum: data.minimum,
			default: data.default,
			maximum: data.maximum,
			deductible: data.deductible,
			sortOrder: data.sort_order,
		};

		setPosConfigurationAssociatedIngredient(payload);
	}

	fetchData(state) {
		const { productVariantId } = this.props;

		return fetchPosConfigurationIngredientAddons(productVariantId, state);
	}

	addAssociatedIngredientsWrapper(data) {
		return addPosConfigurationIngredientAddons(data)
			.then(() => {
				this.setState(() => ({ key: moment() }));
			})
			.catch((err) => {
				throw err;
			});
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));

		this.props.resetPosConfigurationAssociatedIngredient();
	}

	getActionRender() {
		return (
			<Button type="inverted" label="Batch" shadow onClick={this.toggleModal}>
				<Icon name="add" />
			</Button>
		);
	}

	render() {
		const {
			updateBatchForm,
			batchFormData,
			addBatchList,
			batchListData,
			editBatchListItem,
			removeBatchListItem,
			resetPosConfigurationAssociatedIngredient,
			productVariantId,
			posConfigurationVersionId,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					accessAreasAllowedToEdit={['Sales Configuration']}
					key={this.state.key}
					title={phrases.ASSOCIATED_INGREDIENTS_TABLE_NAME}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey="pos/pos_configuration_associated_ingredients"
					manual
					editEntry={this.editEntry}
					editableCellsEdit={this.getEditableCellsEdit()}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={resetPosConfigurationAssociatedIngredient}
					deleteEntry={this.deleteEntry}
					actionRender={this.getActionRender()}
				/>

				<AssociatedIngredientsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchFormData={batchFormData}
					updateBatchForm={updateBatchForm}
					addBatchList={addBatchList}
					batchListData={batchListData}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addAssociatedIngredientsWrapper={this.addAssociatedIngredientsWrapper}
					productVariantId={productVariantId}
					fetchPosConfigurationIngredientAddons={fetchPosConfigurationIngredientAddons}
					posConfigurationVersionId={posConfigurationVersionId}
				/>
			</>
		);
	}
}

PosConfigurationAssociatedIngredients.propTypes = {
	productVariantId: PropTypes.number,
	posConfigurationVersionId: PropTypes.number,
	posConfigurationAssociatedIngredient: PropTypes.object,
	updatePosConfigurationAssociatedIngredient: PropTypes.func,
	resetPosConfigurationAssociatedIngredient: PropTypes.func,
	setPosConfigurationAssociatedIngredient: PropTypes.func,
	updateBatchForm: PropTypes.func,
	addBatchList: PropTypes.func,
	batchFormData: PropTypes.object,
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

const mapStateToProps = (store) => {
	return {
		posConfigurationAssociatedIngredient:
			store.posConfigurationAssociatedIngredients.data.posConfigurationAssociatedIngredient,
		batchFormData: store.posConfigurationAssociatedIngredients.data.batchFormData,
		batchListData: store.posConfigurationAssociatedIngredients.data.batchListData,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updatePosConfigurationAssociatedIngredient,
			resetPosConfigurationAssociatedIngredient,
			setPosConfigurationAssociatedIngredient,
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
		},
		dispatch
	);
};

export default connectWithStore(PosConfigurationAssociatedIngredients, mapStateToProps, mapDispatchToProps);
