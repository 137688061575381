import { store } from 'appState';

export const PAGELOADER_TOGGLE = 'PAGELOADER/PAGELOADER_TOGGLE';

export const toggleLoader = payload => ({ type: PAGELOADER_TOGGLE, payload });

/**
 * @function showPageLoader
 * @description page size laoder
 */
export const showPageLoader = (loading, message = '') => {
	const payload = { loading, message };

	store.dispatch(toggleLoader(payload));
};
