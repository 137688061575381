export const RESET_ASSET = 'SALES_CONFIGURATION/RESET_ASSET';
export const SET_ASSET = 'SALES_CONFIGURATION/SET_ASSET';
export const UPDATE_ASSET = 'SALES_CONFIGURATION/UPDATE_ASSET';
export const SET_IMG_RESIZER_USED = 'SALES_CONFIGURATION/SET_IMG_RESIZER_USED';

/**
 * @param {object} payload
 */
export function setAsset(payload) {
	return {
		type: SET_ASSET,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateAsset(payload) {
	return {
		type: UPDATE_ASSET,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetAssets() {
	return {
		type: RESET_ASSET
	};
}

export function setImgResizerUsed(payload) {
	return {
		type: SET_IMG_RESIZER_USED,
		payload
	};
}
