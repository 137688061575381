'use strict';

import { setCookie } from 'cookies';

/**
 * @function toggleListCollapse
 * @memberOf SERVICES.__models
 * @description
 * Toggle collapse on list
 */

module.exports = function () {
	var state = this.getState();
	var settings = this.getSettings();
	setCookie(settings.name + '-collapsed', state.isCollapsed);
};
