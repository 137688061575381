export const RESET_VALUE = 'SHIFT_CLOCKIN_CORRECTION_OPTIONS/RESET_VALUE';
export const SET_VALUE = 'SHIFT_CLOCKIN_CORRECTION_OPTIONS/SET_VALUE';
export const UPDATE_VALUE = 'SHIFT_CLOCKIN_CORRECTION_OPTIONS/UPDATE_VALUE';

// batch actions
export const BATCH_UPDATE_VALUE = 'SHIFT_CLOCKIN_CORRECTION_OPTIONS/BATCH_UPDATE_VALUE';
export const BATCH_ADD_ROW = 'SHIFT_CLOCKIN_CORRECTION_OPTIONS/BATCH_ADD_ROW';
export const BATCH_DELETE_ROW = 'SHIFT_CLOCKIN_CORRECTION_OPTIONS/BATCH_DELETE_ROW';

export const setValue = (payload) => ({
	type: SET_VALUE,
	payload,
});

export const updateValue = (payload) => ({
	type: UPDATE_VALUE,
	payload,
});

export const resetState = () => ({
	type: RESET_VALUE,
});

export const batchUpdateValue = (payload) => ({
	type: BATCH_UPDATE_VALUE,
	payload,
});

export const batchAddRow = () => ({
	type: BATCH_ADD_ROW,
});

export const batchDeleteRow = (payload) => ({ type: BATCH_DELETE_ROW, payload });
