import { get, post, remove, patch } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchAllNutritionValues
 * @description Fetch all nutrition values
 * @param {object} state
 */
export function fetchAllNutritionValues(state, filter) {
	const newFilter = state.filter ? `${state.filter};${filter}` : filter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: newFilter,
	};

	return get('/product/nutrition_values', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editNutritionValues
 * @description edit
 */
export function editNutritionValues(nutritionId, data) {
	return post(`/product/nutrition_values/${nutritionId}`, data)
		.then((res) => {
			setFeedback('Nutrient value edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function editBatchNutritionValues
 * @description edit
 */
export function editBatchNutritionValues(data) {
	return patch(`/product/nutrition_values`, data)
		.then((res) => {
			setFeedback('Nutrition values edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function addNutritionValues
 * @description add
 */
export function addNutritionValues(data) {
	return post(`/product/nutrition_values`, data)
		.then((res) => {
			setFeedback('Nutrient value added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteNutritionValues
 * @description del
 */
export function deleteNutritionValues(nutritionId) {
	return remove(`/product/nutrition_values/${nutritionId}`)
		.then((res) => {
			setFeedback('Nutrient value deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
