import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';

// Action
import { setActiveTenant } from 'services/user/actions';
import { resetHotbars } from 'hotdamnbar/hotdamnbar.actions';

// Ui
import JoeLogoIcon from 'dumb/icons/joeLogoIcon';
import { MenuItem, FormControl, Box, Select, Fade } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';

const selectStyles = makeStyles((theme) => ({
	root: {
		width: '100%',

		'& .MuiSelect-select': {
			padding: 0,
		},

		'& .MuiOutlinedInput-notchedOutline': {
			outline: 'none !important',
			border: 'none !important',
		},
	},

	selectIcon: {
		pointerEvents: 'none',
		position: 'absolute',
		right: 16,
		color: theme.palette.background.white,
	},

	paper: {
		backgroundColor: theme.palette.secondary.main,
		boxShadow: theme.shadows[3],
		borderRadius: 0,
		left: `0 !important`,

		'& ul': {
			padding: 0,
		},
	},
}));

const JoeMenuItem = styled(MenuItem)(({ theme }) => ({
	color: '#fff',
	padding: theme.spacing(2),
	width: '100%',
	fontSize: 14,
	fontWeight: 600,
	letterSpacing: '0.5px',
	textTransform: 'uppercase',
}));

const TenantSelect = ({ toggled }) => {
	const dispatch = useDispatch();
	const selectClasses = selectStyles();

	const { tenants, activeTenant } = useSelector((state) => ({
		tenants: state.userData?.tenants,
		activeTenant: state.userData?.activeTenant,
	}));

	const handleSelectTenant = (e) => {
		const value = e.target.value;
		const activeTenant = tenants.find((tenant) => tenant.id === value);
		dispatch(setActiveTenant(activeTenant));

		dispatch(resetHotbars());

		// Throwback, like an good ol' rock song
		setTimeout(() => {
			window.location.reload();
		}, 0);
	};

	return tenants.length === 1 ? (
		<Box sx={{ width: '100%', display: 'flex' }}>
			<JoeMenuItem
				onClick={() => dispatch(push(window.location.origin))}
				sx={{ pl: 1.75 }}
			>
				<Box sx={{ width: '28px', height: '28px', mr: 2 }}>
					<JoeLogoIcon sx={{ width: '28px', height: '28px' }} />
				</Box>
				<Fade in={toggled} {...(toggled ? { timeout: 1000 } : {})}>
					<span>Joe and The Juice</span>
				</Fade>
			</JoeMenuItem>
		</Box>
	) : (
		<Box sx={{ width: '100%', display: 'flex' }}>
			<FormControl
				sx={{
					width: '100%',
				}}
			>
				<Select
					fullWidth
					native={false}
					classes={{
						root: selectClasses.root,
					}}
					IconComponent={() =>
						toggled ? (
							<KeyboardArrowDownIcon className={selectClasses.selectIcon} />
						) : null
					}
					MenuProps={{
						classes: selectClasses,
					}}
					sx={{
						'& .MuiSelect-select': {
							padding: 0,
						},
					}}
					value={activeTenant?.id ?? ''}
					labelId="select-tentant"
					onChange={handleSelectTenant}
					renderValue={(selected) => {
						const selectedTenant = tenants.find(
							(tenant) => tenant?.id === selected
						);
						return (
							<JoeMenuItem sx={{ pl: 1.75 }}>
								<Box sx={{ width: '28px', height: '28px', mr: 2 }}>
									<JoeLogoIcon sx={{ width: '28px', height: '28px' }} />
								</Box>
								<Fade in={toggled} {...(toggled ? { timeout: 1000 } : {})}>
									<span>{selectedTenant?.name}</span>
								</Fade>
							</JoeMenuItem>
						);
					}}
				>
					<JoeMenuItem disabled>Select tenant</JoeMenuItem>

					{tenants.map((tenant) => {
						return (
							<JoeMenuItem key={tenant?.name} value={tenant?.id}>
								{tenant?.name}
							</JoeMenuItem>
						);
					})}
				</Select>
			</FormControl>
		</Box>
	);
};

TenantSelect.propTypes = {
	toggled: PropTypes.bool,
};

export default TenantSelect;
