'use strict';

/**
 * @param {object} accordions
 */
exports.initReducer = function (value) {
	return {
		type: 'INIT_REDUCER',
		value,
	};
};

/**
 * @param {object} accordions
 */
exports.setAccordions = function (value) {
	return {
		type: 'SET_ACCORDIONS',
		value,
	};
};

/**
 * @param {array} accordionsBase
 */
exports.setAccordionsBase = function (value) {
	return {
		type: 'SET_ACCORDIONS_BASE',
		value,
	};
};

/**
 * @param {array} collection
 */
exports.setCollection = function (value) {
	return {
		type: 'SET_COLLECTION',
		value,
	};
};

/**
 * @param {number} collectionLength
 */
exports.setCollectionLength = function (value) {
	return {
		type: 'SET_COLLECTION_LENGTH',
		value,
	};
};

/**
 * @param {object} data
 */
exports.setData = function (value) {
	return {
		type: 'SET_DATA',
		value,
	};
};

/**
 * @param {boolean} delayedRefresh
 */
exports.setDelayedRefresh = function (value) {
	return {
		type: 'SET_DELAYED_REFRESH',
		value,
	};
};

/**
 * @param {number} editRowId
 */
exports.setEditRowId = function (value) {
	return {
		type: 'SET_EDIT_ROW_ID',
		value,
	};
};

/**
 * @param {object} filterBarFilter
 */
exports.setFilterFilterBar = function (value) {
	return {
		type: 'SET_FILTER_FILTERBAR',
		value,
	};
};

/**
 * @param {object} filterOperators
 */
exports.setFilterOperators = function (value) {
	return {
		type: 'SET_FILTER_OPERATORS',
		value,
	};
};

/**
 * @param {object} queryFilter
 */
exports.setFilterQuery = function (value) {
	return {
		type: 'SET_FILTER_QUERY',
		value,
	};
};

/**
 * @param {object} searchFilter
 */
exports.setFilterSearch = function (value) {
	return {
		type: 'SET_FILTER_SEARCH',
		value,
	};
};

/**
 * @param {object} filters
 */
exports.setFilters = function (value) {
	return {
		type: 'SET_FILTERS',
		value,
	};
};

/**
 * @param {array} headers
 */
exports.setHeaderFilterBar = function (value) {
	return {
		type: 'SET_HEADER_FILTERBAR',
		value,
	};
};

/**
 * @param {array} headers
 */
exports.setHeaders = function (value) {
	return {
		type: 'SET_HEADERS',
		value,
	};
};

/**
 * @param {object} hotbar
 */
exports.setHotbar = function (value) {
	return {
		type: 'SET_HOTBAR',
		value,
	};
};

/**
 * @param {boolean} isAdding
 */
exports.setIsAdding = function (value) {
	return {
		type: 'SET_IS_ADDING',
		value,
	};
};

/**
 * @param {boolean} isBarFiltering
 */
exports.setIsBarFiltering = function (value) {
	return {
		type: 'SET_IS_BAR_FILTERING',
		value,
	};
};

/**
 * @param {boolean} isCollapsed
 */
exports.setIsCollapsed = function (value) {
	return {
		type: 'SET_IS_COLLAPSED',
		value,
	};
};

/**
 * @param {boolean} isCreating
 */
exports.setIsCreating = function (value) {
	return {
		type: 'SET_IS_CREATING',
		value,
	};
};

/**
 * @param {boolean} isColumnSelecting
 */
exports.setIsColumnSelecting = function (value) {
	return {
		type: 'SET_IS_COLUMN_SELECTING',
		value,
	};
};

exports.setIsCSVDateRangeVisible = function (value) {
	return {
		type: 'SET_CSV_DATE_RANGE_VISIBLE',
		value,
	};
};

/**
 * @param {boolean} isEditing
 */
exports.setIsEditing = function (value) {
	return {
		type: 'SET_IS_EDITING',
		value,
	};
};

/**
 * @param {boolean} isExporting
 */
exports.setIsExporting = function (value) {
	return {
		type: 'SET_IS_EXPORTING',
		value,
	};
};

/**
 * @param {boolean} isSearchFiltering
 */
exports.setIsSearchFiltering = function (value) {
	return {
		type: 'SET_IS_SEARCH_FILTERING',
		value,
	};
};

/**
 * @param {boolean} isSearchList
 */
exports.setIsSearchList = function (value) {
	return {
		type: 'SET_IS_SEARCH_LIST',
		value,
	};
};

/**
 * @param {function} listActions
 */
exports.setListActions = function (value) {
	return {
		type: 'SET_LIST_ACTIONS',
		value,
	};
};

/**
 * @param {number} limit
 */
exports.setParamLimit = function (value) {
	return {
		type: 'SET_PARAM_LIMIT',
		value,
	};
};

/**
 * @param {number} offset
 */
exports.setParamOffset = function (value) {
	return {
		type: 'SET_PARAM_OFFSET',
		value,
	};
};

/**
 * @param {string} offset
 */
exports.setParamSort = function (value) {
	return {
		type: 'SET_PARAM_SORT',
		value,
	};
};

/**
 * @param {object} params
 */
exports.setParams = function (value) {
	return {
		type: 'SET_PARAMS',
		value,
	};
};

/**
 * @param {function} rowActions
 */
exports.setRowActions = function (value) {
	return {
		type: 'SET_ROW_ACTIONS',
		value,
	};
};

/**
 * @param {boolean} showList
 */
exports.setShowList = function (value) {
	return {
		type: 'SET_SHOW_LIST',
		value,
	};
};

/**
 * @param {number} selectedRowId
 */
exports.setSelectedRowId = function (value) {
	return {
		type: 'SET_SELECTED_ROW_ID',
		value,
	};
};

/**
 * @param {object} column
 */
exports.setSortColumn = function (value) {
	return {
		type: 'SET_SORT_COLUMN',
		value,
	};
};

/**
 * @param {string} order
 */
exports.setSortOrder = function (value) {
	return {
		type: 'SET_SORT_ORDER',
		value,
	};
};

/**
 * @param {string} title
 */
exports.setTitle = function (value) {
	return {
		type: 'SET_TITLE',
		value,
	};
};

/**
 * @param {string} title
 */
exports.setPrintViewHeader = function (value) {
	return {
		type: 'SET_PRINT_VIEW_HEADER',
		value,
	};
};

/**
 * @param {string} title
 */
exports.togglePrint = function (value) {
	return {
		type: 'TOGGLE_PRINT_VIEW',
		value,
	};
};

/**
 * @param {string} title
 */
exports.setError = function (value) {
	return {
		type: 'SET_ERROR',
		value,
	};
};
