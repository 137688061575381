'use strict';

import FileList from './fileList';

class FileListFactory {

	/**
	 * @function fileList
	 * @memberOf FileListFactory
	 * @param {string} name
	 * @param {object} dataProvider
	 * @param {boolean} canDelete
	 * @param {boolean} canDownload
	 */
	fileList(name, dataProvider, canDelete, canDownload) {
		return new FileList(name, dataProvider, canDelete, canDownload);
	}

}

export default new FileListFactory();