import {
	UPDATE_INGREDIENT_COMPONENT,
	SET_INGREDIENT_COMPONENT,
	RESET_INGREDIENT_COMPONENT,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	ADD_BATCH_LIST_ITEM_ROW,
	UPDATE_BATCH_FORM,
} from './ingredientComponents.actions';

// utils
import _uniqueId from 'lodash/uniqueId';

const defaultState = {
	data: {
		initialIngredientComponent: {},
		ingredientComponent: {},
		batchList: [
			{
				identifier: _uniqueId('row-'),
				name: '',
				ingredient: null,
				market: null,
			},
		],
		batchForm: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_INGREDIENT_COMPONENT: {
			return {
				data: {
					initialIngredientComponent: payload,
					ingredientComponent: payload,
				},
			};
		}

		case UPDATE_INGREDIENT_COMPONENT: {
			return {
				data: {
					...state.data,
					ingredientComponent: {
						...state.data.ingredientComponent,
						...payload,
					},
				},
			};
		}

		case RESET_INGREDIENT_COMPONENT: {
			return {
				...defaultState,
			};
		}

		case EDIT_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchList: state.data.batchList.map((entry) => {
						if (entry.identifier === payload.identifier)
							return {
								...entry,
								...payload,
							};
						return entry;
					}),
				},
			};
		}

		case REMOVE_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchList: state.data.batchList.filter(
						(entry) => entry.identifier !== payload
					),
				},
			};
		}

		case ADD_BATCH_LIST_ITEM_ROW: {
			return {
				...state,
				data: {
					...state.data,
					batchList: [
						...state.data.batchList,
						{
							...defaultState.data.batchList[0],
							identifier: _uniqueId('row-'),
						},
					],
				},
			};
		}

		case UPDATE_BATCH_FORM:
			return {
				...state,
				data: {
					...state.data,
					batchForm: {
						...state.data.batchForm,
						...payload,
					},
				},
			};

		default:
			return state;
	}
}

export default reducer;
