'use strict';

import _debounce from 'lodash/debounce';
import _each from 'lodash/each';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SelectInput from 'react-select';
import Filter from './filter.component';

import './filterBar.css';

var __filterId = require('filterId.service');

class FilterBar extends Component {
	constructor(props) {
		super(props);

		this.doQuickFilter = this.doQuickFilter.bind(this);
		this.doDebounceFilter = this.doDebounceFilter.bind(this);
		this.doFilter = this.doFilter.bind(this);

		this._addFilter = this._addFilter.bind(this);
	}

	/**
	 * @function doFilter
	 * @memberOf COMPONENTS.filterBar
	 * @description Calls filterBar method from directive
	 */
	doQuickFilter(header, filter, operator, identifier) {
		this.props.methods.handleFilterBarSearch(
			header,
			filter,
			operator,
			identifier
		);
	}

	/**
	 * @function doFilter
	 * @memberOf COMPONENTS.filterBar
	 * @description Calls filterBar method from directive
	 */
	doDebounceFilter(header, filter, operator, identifier, sortType) {
		this.props.methods.handleFilterBarSearch(
			header,
			filter,
			operator,
			identifier,
			sortType
		);
	}

	/**
	 * @function doFilter
	 * @memberOf COMPONENTS.filterBar
	 * @description Calls filterBar method, either debounced or not
	 */
	doFilter(header, filter, operator, identifier, sortType, debounce) {
		if (debounce)
			this.doDebounceFilter(header, filter, operator, identifier, sortType);
		else this.doQuickFilter(header, filter, operator, identifier, sortType);
	}

	/**
	 * @function _debounceFilter
	 * @memberOf COMPONENTS.filterBar
	 * @description Adds debounce for filter, in order to not overload the API
	 */
	_debounceFilter() {
		this.doDebounceFilter = _debounce(this.doDebounceFilter, 400);
	}

	/**
	 * @function _addFilter
	 * @memberOf COMPONENTS.filterBar
	 * @description Adds filter to current filterbar
	 */
	_addFilter(event) {
		// Header is based on header.name and if there is a reference in that header
		// This is how we support multiple headers of the same name, but with different child ressources
		// Normally we go the other way around and check if the header _has reference,
		// but here we have to find the header first and not just check if there is a reference

		var header = !_isEmpty(_filter(this.props.headers, { name: event.value }))
			? _find(this.props.headers, { name: event.value })
			: _find(this.props.headers, { reference: { newName: event.value } });
		if (header)
			this.props.methods.handleToggleFilterBarElement(
				header,
				__filterId(event.value)
			);
	}

	/**
	 * @function _renderFilterAdd
	 * @memberOf COMPONENTS.filterBar
	 * @description Adds filter bar controls (add)
	 */
	_renderFilterAdd() {
		var headerOptions = [];
		_each(this.props.headers, function(header) {
			var colGroup =
				_has(header, 'colGroupLabel') && !_has(header, 'emptyColGroup')
					? header.colGroupLabel + ', '
					: '';
			var name = _has(header, 'reference')
				? header.reference.newName
				: header.name;
			if (
				header.type !== 'multi' &&
				header.type !== 'image' &&
				!header.alwaysHidden &&
				header.canFilter
			) {
				headerOptions.push({
					value: name,
					label: colGroup + header.label
				});
			}
		});
		if (headerOptions.length > 0) {
			return (
				<div className="filter-bar__form-field filter-bar__filter filter-bar__filter--add">
					<span className="filter-bar__filter__header">
						{this.props.translations.ADD_FILTER}
					</span>
					<span className="data-input">
						<div className="select">
							<SelectInput
								onChange={this._addFilter}
								name="addFilter"
								searchable
								clearable={false}
								options={headerOptions}
							/>
						</div>
					</span>
				</div>
			);
		}
	}

	/**
	 * @function _renderFilters
	 * @memberOf COMPONENTS.filterBar
	 * @description Adds content from all filters and begin filtering
	 */
	_renderFilters() {
		return _map(
			this.props.headers,
			function(header) {
				return _map(
					header.filterBar,
					function(filter) {
						return (
							<Filter
								{...this.props}
								key={filter.filterId}
								filter={filter}
								header={header}
								doFilter={this.doFilter}
								doDebounceFilter={this.doDebounceFilter}
							/>
						);
					}.bind(this)
				);
			}.bind(this)
		);
	}

	componentDidMount() {
		this._debounceFilter();
	}

	render() {
		if (
			this.props.isBarFiltering &&
			!_isEmpty(this.props.headers) &&
			!this.props.isSearchList
		) {
			return (
				<div className="filter-bar">
					{this._renderFilters()}
					{this._renderFilterAdd()}
				</div>
			);
		} else return null;
	}
}

FilterBar.propTypes = {
	headers: PropTypes.array.isRequired,
	isBarFiltering: PropTypes.bool,
	methods: PropTypes.object.isRequired,
	translations: PropTypes.object.isRequired
};

export default FilterBar;
