'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';

// different batch views
import SalesConfigurationPosConfigurationDiscountProductVariantBatchDiscountsAddStepForm from './components/salesConfigurationPosConfigurationDiscountProductVariantBatchDiscountsAddStepForm';
import SalesConfigurationPosConfigurationDiscountProductVariantBatchProductVariantsAddStepForm from './components/salesConfigurationPosConfigurationDiscountProductVariantBatchProductVariantsAddStepForm';
import SalesConfigurationPosConfigurationDiscountProductVariantBatchDiscountAmountsAddStepForm from './components/salesConfigurationPosConfigurationDiscountProductVariantBatchDiscountAmountsAddStepForm';
import SalesConfigurationPosConfigurationDiscountProductVariantBatchListAdd from './components/salesConfigurationPosConfigurationDiscountProductVariantBatchListAdd';

// phrases/ moment
import phrases from './../../salesConfigurationPosConfigurationDiscountProductVariant.phrases';
import constants from 'services/constants';
import moment from 'moment';

// utils
import {
	mapPosConfigurationProductVariantsForDiscounts,
	getPosConfigurationProductVariantFilter,
	getPosConfigurationDiscountProductVariantFilter,
	markSelectedValuesWithCanAdd,
} from './../../utils/utils';
import { set as setFeedback } from 'feedback.vanilla.service.js';

// loooodash
import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';
import _uniqBy from 'lodash/uniqBy';

class SalesConfigurationPosConfigurationDiscountProductVariantModalBatchAdd extends Component {
	constructor(props) {
		super(props);

		this.today = moment.utc().format(constants.shortDate);

		this.state = {
			legacyFilterOn: true,
			legacyFilter: `:legacy=='false'`,
			allowModalOverflow: true,
		};

		this._handleOnDiscountStepNext = this._handleOnDiscountStepNext.bind(this);
		this._handleOnAmountsStepBack = this._handleOnAmountsStepBack.bind(this);
		this._onSubmitForm = this._onSubmitForm.bind(this);

		this.modalRef = React.createRef();
	}

	_onSubmitForm() {
		const {
			batchFormData,
			addBatchList,
			fetchPosConfigurationProductVariants,
			fetchPosConfigurationDiscountProductVariants,
			productVariant,
		} = this.props;

		this.setState(() => ({ loadingModal: true }));

		// get rid of duplicate discounts and ingredients (we can have duplicates because of the tables)
		const cleanDiscounts = _uniqBy(
			batchFormData.posConfigurationDiscount,
			'value.id'
		);
		const cleanProductVariants = productVariant
			? [{ value: productVariant }]
			: _uniqBy(batchFormData.productVariants, 'value.id');

		const listArray = cleanDiscounts.reduce((acc, x) => {
			const list = cleanProductVariants.map((y) => {
				return {
					identifier: _uniqueId('row-'), // used to identify what to edit on step#4
					posConfigurationDiscount: x.value,
					productVariant: y.value,
				};
			});

			return [...acc, ...list];
		}, []);

		// create super cool filter that will fetch pos config prod variants - we might not get all of them since they technically don't have to exist
		const posConfigurationProductVariantFilter = getPosConfigurationProductVariantFilter(
			{
				discountArray: cleanDiscounts,
				productVariantArray: cleanProductVariants,
			}
		);

		const fetchCompleteCollection = true;

		fetchPosConfigurationProductVariants(
			{ filter: posConfigurationProductVariantFilter },
			fetchCompleteCollection
		).then((res) => {
			if (_isEmpty(res.data)) {
				setFeedback(
					phrases.MODAL_BATCH_NO_POS_CONFIGURATION_PRODUCT_VARIANTS_FOUND,
					0
				);

				this.setState(() => ({ loadingModal: false }));

				return;
			}

			// add pos configuration product variant objects to mapped data
			const listArrayWithPosConfigurationProductVariantIds = mapPosConfigurationProductVariantsForDiscounts(
				{
					discountData: listArray,
					posConfigurationProductVariants: res.data,
					batchFormData,
				}
			);

			// filter that gets all existing discount product variants for our selected pos configuration discounts and posConfigurationDiscountProductVariants
			const posConfigurationDiscountProductVariantsFilter = getPosConfigurationDiscountProductVariantFilter(
				{
					posConfigurationDiscount: cleanDiscounts,
					productVariantArray: cleanProductVariants,
				}
			);

			fetchPosConfigurationDiscountProductVariants({
				shouldFetchCompleteCollection: fetchCompleteCollection,
				posConfigurationDiscountProductVariantsFilter,
			}).then((res) => {
				// if pos config dis prod var already exists, add 'canAdd: false'
				const filteredListArray = markSelectedValuesWithCanAdd({
					listArrayWithPosConfigurationProductVariantIds,
					posConfigurationDiscountProductVariants: res.data,
				});

				addBatchList(filteredListArray);

				this.setState(() => ({ loadingModal: false }));

				this._goToStep(4);
			});
		});
	}

	_goToStep(step) {
		if (step === 4) this.setState(() => ({ allowModalOverflow: false }));
		if (step !== 4 && this.state.allowModalOverflow === false)
			this.setState(() => ({ allowModalOverflow: true }));

		this.modalRef.current.goToStep(step);
	}

	_batchSubmit() {
		const {
			batchListData,
			handleClose,
			addPosConfigurationDiscountProductVariant,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchListData
			// remove all values that have a canAdd flag set to false
			.filter((entry) => entry.canAdd)
			// map through the rest and construct a payload object
			.map((entry) => {
				return {
					pos_configuration_discount: _get(
						entry,
						'posConfigurationDiscount.id',
						null
					),
					pos_configuration_product_variant: _get(
						entry,
						'posConfigurationProductVariant.id',
						null
					),
					to_stay_discount_price: _get(entry, 'toStayDiscount', 0).replace(
						',',
						'.'
					),
					to_go_discount_price: _get(entry, 'toGoDiscount', 0).replace(
						',',
						'.'
					),
				};
			});

		addPosConfigurationDiscountProductVariant({ batch: payload })
			.then(() => {
				handleClose();
				this.setState(() => ({
					loadingModal: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	getProductVariantsFilter() {
		const { batchFormData } = this.props;
		const { legacyFilterOn, legacyFilter } = this.state;

		let filterStarter =
			':discount_product_set_options.discount_product_set.discount.pos_configuration_discounts.id=in=[';

		filterStarter = legacyFilterOn
			? `${legacyFilter};${filterStarter}`
			: filterStarter;

		return _get(batchFormData, 'posConfigurationDiscount', []).reduce(
			(acc, currentValue, index) => {
				acc =
					batchFormData.posConfigurationDiscount.length - 1 === index
						? `${acc}'${currentValue.value.id}']`
						: `${acc}'${currentValue.value.id}',`;

				return acc;
			},
			filterStarter
		);
	}

	_handleOnDiscountStepNext() {
		const { productVariant } = this.props;

		if (productVariant) this._goToStep(3);
		else this._goToStep(2);
	}

	_handleOnAmountsStepBack() {
		const { productVariant } = this.props;

		if (productVariant) this._goToStep(1);
		else this._goToStep(2);
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchListData,
			editBatchListItem,
			removeBatchListItem,
			productVariant,
			posConfigurationDiscountProductVariantDateFilter,
		} = this.props;

		const productVariantsFilter = this.getProductVariantsFilter();

		// if productVariant passed it means we're using this table as a sub table of product variants table
		// in that case we skip the 2nd step i.e. no need to select product variants and we go straight to 3rd step

		const steps = [
			{
				component: (
					<SalesConfigurationPosConfigurationDiscountProductVariantBatchDiscountsAddStepForm
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						productVariant={productVariant}
						posConfigurationDiscountProductVariantDateFilter={
							posConfigurationDiscountProductVariantDateFilter
						}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_DISCOUNTS,
				isValid: !_isEmpty(batchFormData.posConfigurationDiscount),
				onNext: this._handleOnDiscountStepNext,
				loading: false,
				confirmButtonLabel: productVariant
					? phrases.MODAL_BATCH_STEP_FORM_SELECT_DISCOUNT_AMOUNTS
					: phrases.MODAL_BATCH_STEP_FORM_SELECT_PRODUCT_VARIANTS,
				noScroll: false,
			},
			{
				component: (
					<SalesConfigurationPosConfigurationDiscountProductVariantBatchProductVariantsAddStepForm
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						productVariantsFilter={productVariantsFilter}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_PRODUCT_VARIANTS,
				isValid: !_isEmpty(batchFormData.productVariants),
				onNext: () => this._goToStep(3),
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel:
					phrases.MODAL_BATCH_STEP_FORM_SELECT_DISCOUNT_AMOUNTS,
				cancelButtonLabel:
					phrases.MODAL_BATCH_STEP_FORM_PRODUCT_VARIANTS_BACK_BUTTON_LABEL,
				noScroll: false,
			},
			{
				component: (
					<SalesConfigurationPosConfigurationDiscountProductVariantBatchDiscountAmountsAddStepForm
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_DISCOUNT_AMOUNT,
				isValid: true,
				onNext: this._onSubmitForm,
				onBack: this._handleOnAmountsStepBack,
				loading: this.state.loadingModal,
				confirmButtonLabel:
					phrases.MODAL_BATCH_STEP_FORM_DISCOUNT_AMOUNTS_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: productVariant
					? phrases.MODAL_BATCH_STEP_FORM_PRODUCT_VARIANTS_BACK_BUTTON_LABEL
					: phrases.MODAL_BATCH_STEP_FORM_DISCOUNT_AMOUNTS_BACK_BUTTON_LABEL,
				noScroll: false,
			},
			{
				component: (
					<SalesConfigurationPosConfigurationDiscountProductVariantBatchListAdd
						batchListData={batchListData}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				onBack: () => this._goToStep(3),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						className="pos-configuration-discount-product-variant"
						ref={this.modalRef}
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={handleClose}
						allowOverflow={this.state.allowModalOverflow}
					/>
				)}
			</>
		);
	}
}

SalesConfigurationPosConfigurationDiscountProductVariantModalBatchAdd.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	addBatchList: PropTypes.func,
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addPosConfigurationDiscountProductVariant: PropTypes.func,
	fetchPosConfigurationDiscountProductVariants: PropTypes.func,
	fetchPosConfigurationProductVariants: PropTypes.func,
	productVariant: PropTypes.object,
	posConfigurationDiscountProductVariantDateFilter: PropTypes.string,
};

export default SalesConfigurationPosConfigurationDiscountProductVariantModalBatchAdd;
