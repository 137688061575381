'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	updateBatchForm,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setMarketShiftDeviationReason,
	updateMarketShiftDeviationReasons,
	resetState,
} from './store/marketShiftDeviationReasons.actions';

// services
import {
	fetchMarketShiftDeviationReasons,
	editMarketShiftDeviationReason,
	editMarketShiftDeviationReasons,
	addMarketShiftDeviationReason,
	deleteMarketShiftDeviationReason,
} from './marketShiftDeviationReasons.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Input, Button, Icon, InputCollectionSelect, Toggle } from 'dumb';
import MarketShiftDeviationReasonsModalBatch from './components/batch/marketShiftDeviationReasonsModalBatch';
import { FilterProvider } from 'reactDataWrapper/utilities';

// utils
import { getSelectOptionsFromEnums, getEditedValues } from 'services/utils';

// moment
import moment from 'moment';

// lodash
import _get from 'lodash/get';

// phrases/enums
import phrases from './marketShiftDeviationReasons.phrases';
import collectionSelectEnums from 'services/enums/collectionSelect';
import enums from './marketShiftDeviationReasons.enums';

const reduxKey = '/shiftplanning/market_shift_deviation_reasons';

class MarketShiftDeviationReason extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.addMarketShiftDeviationReasonWrapper = this.addMarketShiftDeviationReasonWrapper.bind(
			this
		);
		this.fetchData = this.fetchData.bind(this);
		this.editEntry = this.editEntry.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: 'name',
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: 'description',
				filterPath: ':description',
			},
			{
				Header: 'Market',
				id: 'market',
				accessor: (d) => _get(d, 'market.name', ''),
				filterPath: ':market.name',
				Filter: ({ column }) => (
					<FilterProvider
						reduxKey={reduxKey}
						columnId={column.id}
						provide={(filterValue, persistToFilterStorage) => (
							<InputCollectionSelect
								id={column.id}
								name="market"
								value={filterValue}
								handleChange={(key, value) => {
									persistToFilterStorage({ handle: key, value });
								}}
								placeholder="Select market..."
								styleType={collectionSelectEnums.TYPE_IN_TABLE}
								apiPath="/administration/markets"
								params={{
									limit: 30,
								}}
							/>
						)}
					/>
				),
			},
			{
				Header: 'Type',
				id: 'type',
				accessor: 'type',
				filterPath: ':type',
			},
			{
				Header: 'Requires manager approval',
				id: 'requires-manager-approval',
				accessor: 'requires_manager_approval',
				filterPath: ':requires_manager_approval',
				filterable: false,
				Cell: (d) => <Input type="checkbox" checked={d.value} disabled />,
			},
		];
	}

	editEntry() {
		const {
			marketShiftDeviationReason,
			defaultMarketShiftDeviationReason,
		} = this.props;

		const editedValues = getEditedValues({
			newData: marketShiftDeviationReason,
			oldData: defaultMarketShiftDeviationReason,
		});

		const payload = {
			id: marketShiftDeviationReason.id,
			name: editedValues.name ?? undefined,
			description: editedValues.description ?? undefined,
			type: editedValues.type ?? undefined,
			requires_manager_approval:
				editedValues.requiresManagerApproval ?? undefined,
		};

		return editMarketShiftDeviationReason(payload);
	}

	editMultiple(selectedRows) {
		const { marketShiftDeviationReason } = this.props;

		const payload = {
			...(marketShiftDeviationReason.name && {
				name: marketShiftDeviationReason.name,
			}),
			...(marketShiftDeviationReason.description && {
				description: marketShiftDeviationReason.description,
			}),
			...(marketShiftDeviationReason.type && {
				type: marketShiftDeviationReason.type.value,
			}),
			requires_manager_approval:
				marketShiftDeviationReason.requiresManagerApproval ?? undefined,
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editMarketShiftDeviationReasons({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteMarketShiftDeviationReason(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			market: data.market.name,
			type: {
				value: data.type,
				label: data.type,
			},
			requiresManagerApproval: data.requires_manager_approval,
			singleEdit: true,
		};

		this.props.setMarketShiftDeviationReason(payload);
	}

	getEditableCells() {
		const { marketShiftDeviationReason } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={marketShiftDeviationReason.name}
						required
						onChange={(event) => this.editStoreEntry('name', event)}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={marketShiftDeviationReason.description}
						required
						onChange={(event) => this.editStoreEntry('description', event)}
					/>
				),
			},
			...(marketShiftDeviationReason.singleEdit
				? [
						{
							header: 'Market',
							value: <span>{marketShiftDeviationReason.market}</span>,
						},
				  ]
				: []),
			{
				header: 'Type',
				value: (
					<InputCollectionSelect
						id="type"
						placeholder="Select type..."
						clearable={false}
						options={getSelectOptionsFromEnums(enums)}
						onChange={(e) => this.editStoreEntry('type', e)}
						value={marketShiftDeviationReason.type}
					/>
				),
			},
			{
				header: 'Requires manager approval',
				value: (
					<Toggle
						id="requiresManagerApproval"
						onClick={(e) => this.editStoreEntry('requiresManagerApproval', e)}
						toggled={marketShiftDeviationReason.requiresManagerApproval}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const value = e?.target?.value ?? e;

		const updateObject = {
			[name]: value,
		};

		this.props.updateMarketShiftDeviationReasons(updateObject);
	}

	fetchData(state) {
		return fetchMarketShiftDeviationReasons(state);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	addMarketShiftDeviationReasonWrapper(data) {
		return addMarketShiftDeviationReason(data).then(() =>
			this.setState(() => ({
				key: moment.utc(),
			}))
		);
	}

	render() {
		const {
			batchList,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
			customFilters,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={reduxKey}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					setInitialEditValues={this.setInitialEditValues}
					accessAreasAllowedToEdit={['Global HR Configuration', 'Shiftplan Admin']}
					onModalClose={this.props.resetState}
					actionRender={
						<Button
							type="inverted"
							label="Batch"
							shadow
							onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
					extraFilters={
						customFilters?.market?.value
							? `:market.id=='${customFilters.market.value}'`
							: ''
					}
				/>

				<MarketShiftDeviationReasonsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addMarketShiftDeviationReason={
						this.addMarketShiftDeviationReasonWrapper
					}
				/>
			</>
		);
	}
}

MarketShiftDeviationReason.propTypes = {
	marketShiftDeviationReason: PropTypes.object,

	updateMarketShiftDeviationReasons: PropTypes.func,
	setMarketShiftDeviationReason: PropTypes.func,
	resetState: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	customFilters: PropTypes.object,
	defaultMarketShiftDeviationReason: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			editBatchListItem,
			removeBatchListItem,
			updateMarketShiftDeviationReasons,
			setMarketShiftDeviationReason,
			resetState,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		marketShiftDeviationReason:
			store.globalAdministrationMarketShiftDeviationReasons.data
				.marketShiftDeviationReason,
		defaultMarketShiftDeviationReason:
			store.globalAdministrationMarketShiftDeviationReasons.data
				.defaultMarketShiftDeviationReason,
		batchList:
			store.globalAdministrationMarketShiftDeviationReasons.data.batchList,
		customFilters: store.filterSortColumnsData?.tables?.[reduxKey]?.custom,
	};
};

export default connectWithStore(
	MarketShiftDeviationReason,
	mapStateToProps,
	mapDispatchToProps
);
