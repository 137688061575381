import React, { Component } from 'react';
import PropTypes from 'prop-types';

import WorkplaceAddress from './workplaceAddress.component';
import Map from '../map/map.container';
import { WorkplaceAddressFactory } from './factory';
import { Box } from '@mui/material';
import { Card } from 'dumb';

class WorkplaceAddressContainer extends Component {
	constructor(props) {
		super(props);

		// Marker types that we can send to the Geocoder
		this.MARKER_TYPE = {
			address: 'address',
			location: 'location',
		};

		// Wait interval before updating the marker when someone is writing to input fields
		this.WAIT_INTERVAL = 1500;

		// Default, empty address object
		this.ADDRESS_DEFAULT = {
			id: null,
			address: '',
			zip: '',
			city: '',
			workplace: {
				name: '',
				id: null,
			},
			country: null,
			latitude: '',
			longitude: '',
		};

		// Factory with logic functions
		this.workplaceAddressFactory = new WorkplaceAddressFactory.WorkplaceAddress(props.workplaceId);

		// Setting the timer related to markerBeforeChange method to null
		this.timer = null;

		this.state = {
			notSavedData: {},
			markerLocation: {},
			mapKey: 1,
		};

		// Binding to use 'this'
		this.addressController = this.addressController.bind(this);
		this.markerBeforeChange = this.markerBeforeChange.bind(this);
		this.markerController = this.markerController.bind(this);
		this.updatePostUpdate = this.updatePostUpdate.bind(this);
		this.waitingForApiHandler = this.waitingForApiHandler.bind(this);
	}

	/**
	 * @description Getting called from children components. Updating the latitude, longitude and marker
	 * @param updatedData
	 * @param currentData
	 * @param updateMarker
	 */
	addressController(updatedData, updateMarker = true) {
		const returnedAddress = this.workplaceAddressFactory.addressController(updatedData);

		if (returnedAddress) {
			this.setState(() => ({
				notSavedData: returnedAddress,
			}));

			if (updateMarker) this.markerController(this.state.notSavedData, this.MARKER_TYPE.location);
		}
	}

	/**
	 * @description Getting called when someone is changing the address related input fields
	 * @param markerLocation
	 * @param type
	 */
	markerBeforeChange(markerLocation, type) {
		clearTimeout(this.timer);
		this.timer = setTimeout(this.markerController, this.WAIT_INTERVAL, markerLocation, type);
	}

	/**
	 * @description Changing the location of the marker
	 * @param markerLocation
	 * @param type
	 */
	markerController(markerLocation, type) {
		let markerObject = {};

		if (type === this.MARKER_TYPE.address && markerLocation.zip && markerLocation.address) {
			markerObject = {
				address: markerLocation.address,
				componentRestrictions: {
					postalCode: markerLocation.zip,
				},
			};
		} else if (type === this.MARKER_TYPE.location) {
			markerObject = {
				location: {
					lat: parseFloat(markerLocation.latitude),
					lng: parseFloat(markerLocation.longitude),
				},
			};
		}

		if (this.state.markerLocation !== markerObject) {
			this.setState(() => ({
				markerLocation: markerObject,
			}));
		}
	}

	/**
	 * @description Setting default everything after hitting the 'Save' button, and rerendering the map component with the mapKey
	 */
	updatePostUpdate() {
		this.setState(() => ({
			mapKey: this.state.mapKey + 1,
			notSavedData: {},
			markerLocation: {},
		}));
	}

	/**
	 * @description Determining whether the API call has been finished, or not. Used to disable the 'Save button'
	 * @param waitingForApi
	 */
	waitingForApiHandler(waitingForApi) {
		this.setState(() => ({
			waitingForApi,
		}));
	}

	render() {
		return (
			<div>
				<h2>{this.workplaceAddressFactory.getPhrases().TITLE}</h2>

				<Box mb={4}>
					<Card>
						<WorkplaceAddress
							getWorkplaceAddress={this.workplaceAddressFactory.getWorkplaceAddress}
							fetchCountries={this.workplaceAddressFactory.fetchCountries}
							notSavedData={this.state.notSavedData}
							saveAddress={this.workplaceAddressFactory.sendForm}
							markerController={this.markerBeforeChange}
							markerType={this.MARKER_TYPE}
							workplaceId={this.props.workplaceId}
							addressObjectDefault={this.ADDRESS_DEFAULT}
							updatePostUpdate={this.updatePostUpdate}
							waitingForApi={this.state.waitingForApi}
						/>

						<Map
							getWorkplaceAddress={this.workplaceAddressFactory.getWorkplaceAddress}
							addressController={this.addressController}
							markerLocation={this.state.markerLocation}
							workplaceId={this.props.workplaceId}
							addressObjectDefault={this.ADDRESS_DEFAULT}
							mapKey={this.state.mapKey}
							waitingForApiHandler={this.waitingForApiHandler}
						/>
					</Card>
				</Box>
			</div>
		);
	}
}

WorkplaceAddressContainer.propTypes = {
	workplaceId: PropTypes.number,
};

export default WorkplaceAddressContainer;
