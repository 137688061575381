export const SET_WAITING_TIME_GRID_MODAL =
	'WAITING_TIME_GRIDS/SET_WAITING_TIME_GRIDS';
export const UPDATE_WAITING_TIME_GRID =
	'WAITING_TIME_GRIDS/UPDATE_WAITING_TIME_GRID';
export const RESET_WAITING_TIME_GRID_MODAL =
	'WAITING_TIME_GRIDS/RESET_WAITING_TIME_GRID_MODAL';

// ------ ENTRIES ---------

export const RESET_WAITING_TIME_GRID_ENTRY_MODAL =
	'SET_WAITING_TIME_GRID_ENTRIES/RESET_WAITING_TIME_GRID_ENTRY_MODAL';

export const UPDATE_WAITING_TIME_GRID_ENTRY =
	'SET_WAITING_TIME_GRID_ENTRIES/UPDATE_WAITING_TIME_GRID_ENTRY';
export const SET_WAITING_TIME_GRID_ENTRY_MODAL =
	'WAITING_TIME_GRID_ENTRIES/SET_WAITING_TIME_GRID_ENTRY';

/**
 * @param {object} payload
 */
export function setWaitingTimeGridModal(payload) {
	return {
		type: SET_WAITING_TIME_GRID_MODAL,
		payload
	};
}
/**
 * @param {object} payload
 */
export function updateWaitingTimeGrid(payload) {
	return {
		type: UPDATE_WAITING_TIME_GRID,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetWaitingTimeGridModal() {
	return {
		type: RESET_WAITING_TIME_GRID_MODAL
	};
}

// ------ ENTRIES ---------

/**
 * @param {object} payload
 */
export function setWaitingTimeGridEntryModal(payload) {
	return {
		type: SET_WAITING_TIME_GRID_ENTRY_MODAL,
		payload
	};
}
/**
 * @param {object} payload
 */
export function updateWaitingTimeGridEntry(payload) {
	return {
		type: UPDATE_WAITING_TIME_GRID_ENTRY,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetWaitingTimeGridEntryModal() {
	return {
		type: RESET_WAITING_TIME_GRID_ENTRY_MODAL
	};
}
