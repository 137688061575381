'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Date from './date/date.component';
import Time from './time.component';
import './dateTime.css';

class DateTime extends Component {
	constructor(props) {
		super(props);

		this.state = {
			date: null,
			time: moment('00:00:00', 'HH-mm-ss')
		};

		this.focus = this.focus.bind(this);
		this.onChangeDate = this.onChangeDate.bind(this);
		this.onChangeTime = this.onChangeTime.bind(this);
	}

	focus() {
		this.dateInput.focus();
	}

	onChangeDate(date) {
		const { onChange } = this.props;
		this.setState({ date });
		if (moment.isMoment(date) && moment.isMoment(this.state.time)) {
			const dateTime = moment(
				`${date.format('DD-MM-YYYY')} ${this.state.time.format('HH-mm-ss')}`,
				'DD-MM-YYYY HH-mm-ss'
			);
			onChange(dateTime);
		}
	}

	onChangeTime(time) {
		const { onChange } = this.props;
		this.setState({ time });
		if (moment.isMoment(this.state.date) && moment.isMoment(time)) {
			const dateTime = moment(
				`${this.state.date.format('DD-MM-YYYY')} ${time.format('HH-mm-ss')}`,
				'DD-MM-YYYY HH-mm-ss'
			);
			onChange(dateTime);
		}
	}

	render() {
		const { initialDateTime } = this.props;

		const dateTimeCx = cx('date-time');

		return (
			<div className={dateTimeCx}>
				<Date
					ref={input => (this.dateInput = input)}
					type="single"
					initialDate={initialDateTime}
					onChange={this.onChangeDate}
				/>
				<Time
					showMinutes
					showSeconds
					initialTime={initialDateTime}
					onChange={this.onChangeTime}
				/>
			</div>
		);
	}
}

DateTime.propTypes = {
	onChange: PropTypes.func.isRequired,
	initialDateTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default DateTime;
