// interface
import { ActionType } from 'types/redux';
import { ICompanyReduxValue, ICompaniesReduxState } from './../types';

// utils
import moment from 'moment';
import constants from 'services/constants';

import {
	SET_COMPANY,
	UPDATE_COMPANY,
	RESET_COMPANY,
} from './companies.actions';

const defaultState: ICompaniesReduxState = {
	data: {
		defaultCompany: null,
		company: {
			name: '',
			market: null,
			mainEntity: true,
			merchantAccount: '',
			opened: moment.utc().format(constants.shortDate),
			closed: '9999-12-31',
			legalEntityCode: null,
		},
	},
};

function reducer(
	state = defaultState,
	action: ActionType
): ICompaniesReduxState {
	const { type, payload } = action;

	switch (type) {
	case SET_COMPANY: {
		const castedPayload = payload as ICompanyReduxValue;

		return {
				...state,
				data: {
					company: castedPayload,
					defaultCompany: castedPayload,
				},
			};
	}

	case UPDATE_COMPANY: {
		const castedPayload = payload as Partial<ICompanyReduxValue>;

		return {
				...state,
				data: {
					...state.data,
					company: {
						...state.data.company,
						...castedPayload,
					},
				},
			};
	}

	case RESET_COMPANY: {
		return defaultState;
	}

	default:
		return state;
	}
}

export default reducer;
