function _fallbackCopyTextToClipboard(text) {
	const textArea = document.createElement('textarea');
	textArea.value = text;

	// Avoid scrolling to bottom
	textArea.style.top = '0';
	textArea.style.left = '0';
	textArea.style.position = 'fixed';

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	try {
		document.execCommand('copy');
	} catch (err) {
		console.error('Fallback: Oops, unable to copy', err);
		return err;
	}

	document.body.removeChild(textArea);
}

/**
 * @function copyTextToClipboard
 * @description copys to clipboard with fallback if navigator.clipboard is not supported
 * @export
 * @param {String} text
 * @returns void
 */
export default function copyTextToClipboard(text) {
	if (!navigator.clipboard) {
		_fallbackCopyTextToClipboard(text);
	}
	navigator.clipboard.writeText(text).then(
		function () {},
		function (err) {
			console.error('Async: Could not copy text: ', err);
			return err;
		}
	);
}
