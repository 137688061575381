export const UPDATE_SHIFT_EMPLOYEE =
	'SHIFTPLANNER_EMPLOYEES/UPDATE_SHIFT_EMPLOYEE';
export const SET_REGIONAL_MANAGERS =
	'SHIFTPLANNER_EMPLOYEES/SET_REGIONAL_MANAGERS';
export const SET_EMPLOYEE_ADD_EMPLOYEE_MODAL =
	'SHIFTPLANNER_EMPLOYEES/SET_EMPLOYEE_ADD_EMPLOYEE_MODAL';
export const ADD_EMPLOYEE_TO_WORKPLACE =
	'SHIFTPLANNER_EMPLOYEES/ADD_EMPLOYEE_TO_WORKPLACE';
export const RESET_EMPLOYEE_FOR_WORKPLACE =
	'SHIFTPLANNER_EMPLOYEES/RESET_EMPLOYEE_FOR_WORKPLACE';
export const REMOVE_WORKPLACE_EMPLOYMENT =
	'SHIFTPLANNER_EMPLOYEES/REMOVE_WORKPLACE_EMPLOYMENT';
export const ADD_EMPLOYEE_TO_EXTERNAL_EMPLOYMENTS =
	'SHIFTPLANNER_EMPLOYEES/ADD_EMPLOYEE_TO_EXTERNAL_EMPLOYMENTS';
export const REMOVE_EXTERNAL_EMPLOYMENT =
	'SHIFTPLANNER_EMPLOYEES/REMOVE_EXTERNAL_EMPLOYMENT';
export const REMOVE_EMPLOYMENT_WORKPLACE =
	'SHIFTPLANNER_EMPLOYEES/REMOVE_EMPLOYMENT_WORKPLACE';
export const SET_SENIORITIES = 'SHIFTPLANNER_EMPLOYEES/SET_SENIORITIES';

/**
 * @function setRegionalManagers
 * @param regionalManagers : []
 * @returns {{type: string, payload: {regionalManagers: Array}}}
 */
export function setRegionalManagers(regionalManagers = []) {
	return {
		type: SET_REGIONAL_MANAGERS,
		payload: {
			regionalManagers,
		},
	};
}

/**
 * @function setRegionalManagers
 * @param regionalManagers : []
 * @returns {{type: string, payload: {regionalManagers: Array}}}
 */
export function updateShiftEmployment(shiftEmployee = {}) {
	return {
		type: UPDATE_SHIFT_EMPLOYEE,
		payload: {
			shiftEmployee,
		},
	};
}

export function setEmployeeAddEmployeeModal(payload) {
	return {
		type: SET_EMPLOYEE_ADD_EMPLOYEE_MODAL,
		payload,
	};
}

export function addEmployeeToWorkplace(payload) {
	return {
		type: ADD_EMPLOYEE_TO_WORKPLACE,
		payload,
	};
}

export function resetEmployeeForWorkplace() {
	return {
		type: RESET_EMPLOYEE_FOR_WORKPLACE,
	};
}

export function removeWorkplaceEmployment(payload) {
	return {
		type: REMOVE_WORKPLACE_EMPLOYMENT,
		payload,
	};
}

export function addEmployeeToExternalEmployments(payload) {
	return {
		type: ADD_EMPLOYEE_TO_EXTERNAL_EMPLOYMENTS,
		payload,
	};
}

export function removeExternalEmployment(payload) {
	return {
		type: REMOVE_EXTERNAL_EMPLOYMENT,
		payload,
	};
}

export function removeWorkplaceFromEmployeesWorkplaces(payload) {
	return {
		type: REMOVE_EMPLOYMENT_WORKPLACE,
		payload,
	};
}

export function setSeniorities(payload) {
	return {
		type: SET_SENIORITIES,
		payload,
	};
}
