import { post } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function createOnboarding
 * @description Create onboarding
 */
export function createOnboarding({
	market,
	firstName,
	lastName,
	emailAddress,
	phoneNumber,
	startDate,
	company,
	employmentType,
	moneyballSubPosition,
	salaryGroup,
}) {
	const payload = {
		market: market?.value,
		first_name: firstName,
		last_name: lastName,
		email_address: emailAddress,
		phone_number: phoneNumber,
		start_date: startDate,
		company: company?.value,
		employment_type: employmentType?.value,
		moneyball_sub_position: moneyballSubPosition?.value,
		salary_group: salaryGroup?.value,
	};

	return post('/hr/person_onboardings', payload)
		.then((res) => {
			setFeedback('onboarding created', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
