import { getEditedValues } from 'services/utils';
import _has from 'lodash/has';

/**
 * @function mapReportsToMarkets
 * @param {Object} markes, repors
 * @description creats extra reports for all new markets that were selected - if a report already has a market then don't create the new one
 * @example Report A with market 1, report B with market 2. We select market 2 and 3 -> result: [report A - market 1, report A - market 2, report A - market 3, report B - market 2, report B - market 3]
 * @returns {Array}
 */
export function mapReportsToMarkets({ markets, reports }) {
	const newReports = reports.reduce((acc, currentValue) => {
		const reportMarketId = currentValue.market?.value?.id ?? null;

		// loop through the markets and get markets that our current reports doesnt have
		const newMarkets = markets.filter(
			(market) => market.value?.id !== reportMarketId
		);

		// construct new reports from these markets
		const newReports = newMarkets.map((newMarket) => ({
			// spread the report stuff
			...currentValue,
			// overwrite with the new market
			market: newMarket,
		}));

		// add all to accumulator
		const newAcc = [...acc, currentValue, ...newReports];

		return newAcc;
	}, []);

	return newReports;
}

/**
 * @function getPayloadToSubmit
 * @param {Object} selectedReports, originalSelectedReports
 * @description gets payload to submit
 * @return {Array}
 */
export function getPayloadToSubmit({
	selectedReports,
	originalSelectedReports,
}) {
	const payload = selectedReports.map((entry) => {
		const originalPowerBiReport = originalSelectedReports.find(
			(entryTwo) => entryTwo.id === entry.id
		);

		const editedValues = getEditedValues({
			newData: entry,
			oldData: originalPowerBiReport,
		});

		return {
			// necessary field
			power_bi_report: entry.id,

			// optional fields
			...(_has(editedValues, 'name') && {
				name: editedValues.name,
			}),
			...(_has(editedValues, 'description') && {
				description: editedValues.description,
			}),
			...(_has(editedValues, 'powerBiId') && {
				power_bi_id: editedValues.powerBiId,
			}),
			...(_has(editedValues, 'sortOrder') && {
				sort_order: editedValues.sortOrder,
			}),
			...(_has(editedValues, 'accessGrantingArea') && {
				access_granting_area: editedValues.accessGrantingArea?.id ?? null,
			}),
			...(_has(editedValues, 'market') && {
				market: editedValues.market.id,
			}),
			...(_has(editedValues, 'type') && {
				type: editedValues.type.id,
			}),
		};
	});

	return payload;
}
