'use strict';

import React from 'react';

import PropTypes from 'prop-types';

import './menuSection.css';

const MenuSection = ({ children, label }) => {
	return (
		<div className="j-menu-section">
			{label && <label className="j-menu-section__label">{label}</label>}
			{children}
		</div>
	);
};

MenuSection.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	label: PropTypes.string,
};

export default MenuSection;
