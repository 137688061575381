export const RESET_INGREDIENT_COMPONENT =
	'INGREDIENT_COMPONENTS/RESET_INGREDIENT_COMPONENT';
export const SET_INGREDIENT_COMPONENT =
	'INGREDIENT_COMPONENTS/SET_INGREDIENT_COMPONENT';
export const UPDATE_INGREDIENT_COMPONENT =
	'INGREDIENT_COMPONENTS/UPDATE_INGREDIENT_COMPONENT';

export const UPDATE_BATCH_FORM = 'INGREDIENT_COMPONENTS/UPDATE_BATCH_FORM';
export const EDIT_BATCH_LIST_ITEM =
	'INGREDIENT_COMPONENTS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'INGREDIENT_COMPONENTS/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'INGREDIENT_COMPONENTS/ADD_BATCH_LIST_ITEM_ROW';

/**
 * @param {object} payload
 */
export function setIngredientComponent(payload) {
	return {
		type: SET_INGREDIENT_COMPONENT,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function updateIngredientComponent(payload) {
	return {
		type: UPDATE_INGREDIENT_COMPONENT,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetIngredientComponent() {
	return {
		type: RESET_INGREDIENT_COMPONENT,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}
