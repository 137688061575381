'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// rdw actions
import { store } from 'appState';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';
import { batch } from 'react-redux';

// components
import { ModalStepper } from 'dumb';

// different batch views
import NominalPercentageStepForm from './components/nominalPercentageEditStepForm';
import NominalPercentageStepList from './components/nominalPercentageEditStepList';

// phrases/ enums
import phrases from './../../posConfigurationProductVariantsPricing.phrases';
import enums from './../../posConfigurationProductVariantsPricing.enums';

// utils
import {
	calculatePercentagePrices,
	calculateNominalPrices,
} from './../../utils';

// loooodash
import _get from 'lodash/get';

// styles
import './nominalPercentageEdit.css';

class NominalPercentageEdit extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showOverlay: false,
			allowModalOverflow: true,
		};

		this._onSubmitForm = this._onSubmitForm.bind(this);
		this._batchSubmit = this._batchSubmit.bind(this);

		this.modalRef = React.createRef();
	}

	_onSubmitForm() {
		const { batchFormData, batchList, addBatchList } = this.props;

		const adjustmentTypeNominal =
			_get(batchFormData, 'adjustmentType.value', '') === enums.NOMINAL;

		// add new discounts
		const decoratedListData = batchList.map((entry) => {
			if (adjustmentTypeNominal) {
				const calculatedPrices = calculateNominalPrices({
					toStayPrice: entry.toStayPrice,
					toGoPrice: entry.toGoPrice,
					batchFormData,
				});

				return {
					...entry,
					toStayPriceNew: calculatedPrices.toStayPriceNew,
					toGoPriceNew: calculatedPrices.toGoPriceNew,
				};
			} else {
				// calculate prices based on percentages chosen
				const calculatedPrices = calculatePercentagePrices({
					toStayPrice: entry.toStayPrice,
					toGoPrice: entry.toGoPrice,
					batchFormData,
				});

				return {
					...entry,
					toStayPriceNew: calculatedPrices.toStayPriceNew,
					toGoPriceNew: calculatedPrices.toGoPriceNew,
				};
			}
		});

		addBatchList(decoratedListData);

		this._goToStep(2);
	}

	_goToStep(step) {
		if (step === 2) this.setState(() => ({ allowModalOverflow: false }));
		if (step !== 2 && this.state.allowModalOverflow === false)
			this.setState(() => ({ allowModalOverflow: true }));

		this.modalRef.current.goToStep(step);
	}

	_batchSubmit() {
		const {
			batchList,
			handleClose,
			editSalesConfigurationProductVariantPricings,
			reduxKey,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchList.map((entry) => {
			return {
				id: entry.identifier,
				...(entry.toGoPriceNew && {
					to_go_price: entry.toGoPriceNew.replace(',', '.'),
				}),
				...(entry.toStayPriceNew && {
					to_stay_price: entry.toStayPriceNew.replace(',', '.'),
				}),
			};
		});

		editSalesConfigurationProductVariantPricings({ batch: payload })
			.then((res) => {
				// actions bellow mimic rDW's procedure when editing multiple rows
				batch(() => {
					// reset table selection
					store.dispatch(actions.resetSelection({ reduxKey }));

					res.data.map((value) => {
						store.dispatch(actions.editEntry({ reduxKey, entry: value }));
					});
				});

				handleClose();
			})
			.finally(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchList,
			editBatchListItem,
			removeBatchListItem,
		} = this.props;

		const steps = [
			{
				component: (
					<NominalPercentageStepForm
						batchFormData={batchFormData}
						batchList={batchList}
						updateBatchForm={updateBatchForm}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_ADJUST_PRICES,
				isValid: true,
				onNext: this._onSubmitForm,
				loading: this.state.loadingModal,
				confirmButtonLabel:
					phrases.MODAL_BATCH_STEP_FORM_NOMINAL_PERCENTAGE_CONFIRM,
				noScroll: false,
			},
			{
				component: (
					<NominalPercentageStepList
						batchList={batchList}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: this._batchSubmit,
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return modalVisible ? (
			<ModalStepper
				ref={this.modalRef}
				isOpen={modalVisible}
				steps={steps}
				showStep={false}
				onClose={handleClose}
				allowOverflow={this.state.allowModalOverflow}
			/>
		) : null;
	}
}

NominalPercentageEdit.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	addBatchList: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	editSalesConfigurationProductVariantPricings: PropTypes.func,
	reduxKey: PropTypes.string,
};

export default NominalPercentageEdit;
