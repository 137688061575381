'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

import { Button, Input, Toggle, Icon, Legend, Tag } from 'dumb';

// phrases
import phrases from './../../../posConfigurationAssociatedIngredients.phrases';

class AssociatedIngredientsStepList extends Component {
	constructor(props) {
		super(props);

		this.cantAddBulletColor = 'ffe699';
	}

	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e.target ? e.target.value || e.target.checked : e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: value },
		});
	}

	render() {
		const { batchListData } = this.props;

		const deleteButtonDisabled = batchListData.length === 1;

		return (
			<>
				<div className="associated-ingredients-modal-batch__legend">
					<Legend
						points={[
							{
								bulletColor: `#${this.cantAddBulletColor}`,
								label: phrases.MODAL_BATCH_STEP_LIST_LEGEND_CANT_ADD,
							},
						]}
					/>
				</div>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_HEADER_POS_CONFIGURATION_INGREDIENT}</th>
							<th>{phrases.MODAL_HEADER_MINIMUM}</th>
							<th>{phrases.MODAL_HEADER_DEFAULT}</th>
							<th>{phrases.MODAL_HEADER_MAXIMUM}</th>
							<th>{phrases.MODAL_HEADER_DEDUCTIBLE}</th>
							<th>{phrases.MODAL_HEADER_SORT_ORDER}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{batchListData.map((entry, i) => {
							const rowDisabled = !entry.canAdd;

							return (
								<TableListRow
									key={entry.identifier}
									type={rowDisabled && 'light'}>
									<TableListCell>
										{rowDisabled && (
											<Tag type="bullet" color={this.cantAddBulletColor} />
										)}
										{i + 1}
									</TableListCell>
									<TableListCell>
										{entry.posConfigurationIngredient?.label}
									</TableListCell>

									<TableListCell>
										<Input
											id={`minimum-${entry.identifier}`}
											placeholder="Enter minimum..."
											type="number"
											onChange={(event) =>
												this._editListEntry(event, entry, 'minimum')
											}
											value={entry.minimum}
											disabled={rowDisabled}
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`default-${entry.identifier}`}
											placeholder="Enter default..."
											type="number"
											onChange={(event) =>
												this._editListEntry(event, entry, 'default')
											}
											value={entry.default}
											disabled={rowDisabled}
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`maximum-${entry.identifier}`}
											placeholder="Enter maximum..."
											type="number"
											onChange={(event) =>
												this._editListEntry(event, entry, 'maximum')
											}
											value={entry.maximum}
											disabled={rowDisabled}
										/>
									</TableListCell>

									<TableListCell>
										<Toggle
											id={`deductible-${entry.identifier}`}
											toggled={entry.deductible}
											onClick={(e) =>
												this._editListEntry(e, entry, 'deductible')
											}
											disabled={rowDisabled}
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`sort-order-${entry.identifier}`}
											placeholder="Enter sort order..."
											type="number"
											onChange={(event) =>
												this._editListEntry(event, entry, 'sortOrder')
											}
											value={entry.sortOrder}
											disabled={rowDisabled}
										/>
									</TableListCell>

									<TableListCell>
										<Button
											type="negative"
											size="tiny"
											disabled={deleteButtonDisabled}
											onClick={() => this._onRemoveEntry(entry.identifier)}>
											<Icon name="delete" />
										</Button>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
			</>
		);
	}
}

AssociatedIngredientsStepList.propTypes = {
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

AssociatedIngredientsStepList.defaultProps = {};

export default AssociatedIngredientsStepList;
