import constants from 'dumb/report/constants';
import moment from 'moment';

import _get from 'lodash/get';

const tableEntries = {
	marketEntry: 'ShiftplannerReportMarketEntry', // <-----------------
	workplaceEntry: 'ShiftplannerReportWorkplaceEntry',
};

/**
 * @description Magic parser function. Takes all array entries and maps it to
 * const data = {
 * 	    [Market1]: {
 * 			[weeknumber]: {
 * 				[stores]: [],
 * 				[total]: {}
 *		},
 * 			[weeknumber]: {
 * 				[stores]: [],
 * 				[total]: {}
 * 			}
 * 		},
 * 		[Market1]: {
 * 			[weeknumber]: {
 * 				[stores]: [],
 * 				[total]: {}
 * 			},
 * 			[weeknumber]: {
 * 				[stores]: [],
 * 				[total]: {}
 * 			}
 * 		},
 * }
 * @param {*} tables
 */
export default function employeeMealsReportParser(tables) {
	// Sort entries to get market entries first
	const reportsSplit = tables
		.sort((a) => (a.entry_type === 'ShiftplannerReportMarketEntry' ? -1 : 1))
		.reduce((acc, entry) => {
			switch (entry.entry_type) {
				// Construct
				case tableEntries.marketEntry: {
					const marketName = _get(entry, 'market.name', null);
					const weekNumber = moment(entry.period.from, 'YYYY-MM-DD').isoWeek();
					const type = entry.type;

					acc = {
						...acc,
						[marketName]: {
							...(acc[marketName] && acc[marketName]),
							[weekNumber]: {
								total: {
									...(type === 'Week' && {
										...entry,
										summary: _calculateSummaryPercentages(entry.summary),
										name: 'Total',
										rowType: constants.TABLE_TYPES.TABLE_TOTAL,
									}),
								},
								stores: [],
							},
							average: {
								total: {
									...(type === 'Average'
										? {
												...entry,
												summary: _calculateSummaryPercentages(entry.summary),
												name: 'Total',
												rowType: constants.TABLE_TYPES.TABLE_TOTAL,
										  }
										: acc[marketName] &&
										  acc[marketName].average &&
										  acc[marketName].average.total),
								},
								stores: [],
							},
						},
					};

					break;
				}

				// these cases go to the inner
				case tableEntries.workplaceEntry: {
					const marketName = _get(entry, 'workplace.market.name', null);
					const weekNumber = moment(entry.period.from, 'YYYY-MM-DD').isoWeek();
					const type = entry.type;

					// Type week goes here
					if (type === 'Week') {
						acc = {
							...acc,
							[marketName]: {
								...(acc[marketName] && acc[marketName]),
								[weekNumber]: {
									...(acc[marketName][weekNumber] &&
										acc[marketName][weekNumber]),
									stores: [
										...acc[marketName][weekNumber].stores,
										{
											...entry,
											summary: _calculateSummaryPercentages(entry.summary),
											name: entry.workplace.name,
											rowType: constants.TABLE_TYPES.ENTRY,
										},
									],
								},
							},
						};

						// Type Averages goes here
					} else {
						acc = {
							...acc,
							[marketName]: {
								...(acc[marketName] && acc[marketName]),
								average: {
									...acc[marketName].average,
									stores: [
										...acc[marketName].average.stores,
										{
											...entry,
											summary: _calculateSummaryPercentages(entry.summary),
											name: entry.workplace.name,
											rowType: constants.TABLE_TYPES.ENTRY,
										},
									],
								},
							},
						};
					}

					return acc;
				}

				default:
					break;
			}

			return acc;
		}, {});

	return {
		...reportsSplit,
	};
}

export function parseReportsPerWorkplace(data) {
	return data.map((entry) => {
		return {
			...entry,
			summary: _calculateSummaryPercentages(entry.summary),
		};
	});
}

/**
 * @function _calculateSummaryPercentages
 * @param {Object} summary
 * @description calculates following summary stats as a percentage of total of these summed
 * Summary Slow, Summary Optimal, Summary Busy, Summary Too Busy
 */
function _calculateSummaryPercentages(summary) {
	// sum hours
	const hoursSum =
		parseInt(summary.slow_hours) +
		parseInt(summary.optimal_hours) +
		parseInt(summary.busy_hours) +
		parseInt(summary.too_busy_hours);

	return {
		...summary,
		slow_hours: (parseInt(summary.slow_hours) / hoursSum) * 100,
		optimal_hours: (parseInt(summary.optimal_hours) / hoursSum) * 100,
		busy_hours: (parseInt(summary.busy_hours) / hoursSum) * 100,
		too_busy_hours: (parseInt(summary.too_busy_hours) / hoursSum) * 100,
	};
}
