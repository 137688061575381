import {
	MODAL_TOGGLED,
	DATE_CHANGED,
	OPENED_DATE_CHANGED,
	CLOSED_DATE_CHANGED,
	WORKPLACE_SELECTED,
	WORKPLACES_FETCHED,
	REGISTER_VALUE_CHANGED,
	DESCRIPTION_VALUE_CHANGED,
	CLEAR_STATE
} from './actions';
import moment from 'moment';

const defaultState = {
	ui: {
		isModalToggled: false,
		workplaces: []
	},
	posSession: {
		date: moment(),
		opened: {
			date: moment(),
			hour: '00',
			minute: '00',
			modified: false
		},
		closed: {
			date: moment(),
			hour: '00',
			minute: '00',
			modified: false
		},
		workplace: {},
		register: '',
		description: ''
	},
	workplaces: []
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
	case MODAL_TOGGLED:
		return { ...state, ui: { ...state.ui, isModalToggled: payload }};

	case DATE_CHANGED:
		return { ...state, posSession: { ...state.posSession, date: payload, opened: {...state.posSession.opened, date: payload}, closed: {...state.posSession.closed, date: payload}}};

	case OPENED_DATE_CHANGED:
		return { ...state, posSession: { ...state.posSession, opened: { ...state.posSession.opened, [payload.type]: payload.time, modified: true }}};

	case CLOSED_DATE_CHANGED:
		return { ...state, posSession: { ...state.posSession, closed: { ...state.posSession.closed, [payload.type]: payload.time, modified: true }}};

	case WORKPLACE_SELECTED:
		return { ...state, posSession: { ...state.posSession, workplace: payload }};

	case REGISTER_VALUE_CHANGED:
		return { ...state, posSession: { ...state.posSession, register: payload }};

	case DESCRIPTION_VALUE_CHANGED:
		return { ...state, posSession: { ...state.posSession, description: payload }};

	case WORKPLACES_FETCHED:
		let uiWorkplaces = payload.map(workplace => {return { label: workplace.name, value: workplace.id };});
		return {...state, workplaces: payload, ui: { ...state.ui, workplaces: uiWorkplaces }};

	case CLEAR_STATE:
		return { ...defaultState };
		
	default:
		return state;
	}
}

export default reducer;