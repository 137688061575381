// lodash
import _isEmpty from 'lodash/isEmpty';

/**
 * @function getWorkplaceSystemSoftwareFilter
 * @param {Object}
 * @description returns a filter that will be used to fetch already existing values
 * We do this to avoid trying to add already existing values that would otherwise cause
 * a unique constraint violated error
 */
export function getWorkplaceSystemSoftwareFilter({
	listArray,
	systemSoftwareId,
	workplaceId,
}) {
	const valueIds = listArray.reduce((acc, currentValue, index) => {
		const id = systemSoftwareId
			? currentValue.workplace?.value?.id ?? null
			: currentValue.systemSoftware?.value?.id ?? null;

		acc = index === listArray.length - 1 ? `${acc}'${id}']` : `${acc}'${id}',`;

		return acc;
	}, '[');

	return systemSoftwareId
		? `:workplace.id=IN=${valueIds};:system_software=='${systemSoftwareId}'`
		: `:system_software.id=IN=${valueIds};:workplace.id=='${workplaceId}'`;
}

/**
 * @function markValuesWithCanAdd
 * @param {Object} - contains mapped values, values from the api and current system software id (if table under global system softwares)
 * @description maps through every listArray value and if it already exist
 * i.e. workplace found in res object, mark it with canAdd: false, otherwise
 * add canAdd: true
 */
export function markValuesWithCanAdd({ listArray, res, systemSoftwareId }) {
	return listArray.map((entry) => {
		const objectId = systemSoftwareId
			? entry.workplace.value.id
			: entry.systemSoftware.value.id;

		const found = res.find((value) => {
			if (systemSoftwareId && value.workplace.id === objectId) return true;
			if (!systemSoftwareId && value.system_software.id === objectId)
				return true;
			return false;
		});

		return {
			...entry,
			canAdd: _isEmpty(found),
		};
	});
}
