import * as types from './employmentDocuments.constants';

const defaultState = {
	ui: {
		loadingPositions: false,
	},
	data: {
		employmentPositions: [],
	},
	current: {
		selectedPosition: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case types.FETCH_POSITIONLIST:
			return { ...state, data: { ...state.data, employmentPositions: payload } };

		case types.LOADING_POSITIONLIST:
			return { ...state, ui: { ...state.ui, loadingPositions: payload } };

		case types.SELECT_POSITIONLIST:
			return { ...state, current: { ...state.current, selectedPosition: payload } };

		case types.RESET_STATE:
			return defaultState;

		default:
			return state;
	}
}

export default reducer;
