'use strict';

export const SET_ACTIVE_TILE = 'POS_CONF_LAYOUT/SET_ACTIVE_TILE';
export const SET_ACTIVE_TILE_TYPE = 'POS_CONF_LAYOUT/SET_ACTIVE_TILE_TYPE';
export const SET_FETCHING_TILE = 'POS_CONF_LAYOUT/SET_FETCHING_TILE';
export const SET_SHOW_ADD_TILE_MODAL =
	'POS_CONF_LAYOUT/SET_SHOW_ADD_TILE_MODAL';
export const SET_VARIANTS_LIST = 'POS_CONF_LAYOUT/SET_VARIANTS_LIST';
export const SET_GROUP_IN_FOCUS = 'POS_CONF_LAYOUT/SET_GROUP_IN_FOCUS';
export const SET_TILES_IN_GROUP = 'POS_CONF_LAYOUT/SET_TILES_IN_GROUP';
export const SET_GROUPS = 'POS_CONF_LAYOUT/SET_GROUPS';
export const SET_GRID_DATA = 'POS_CONF_LAYOUT/SET_GRID_DATA';
export const REMOVE_GROUP = 'POS_CONF_LAYOUT/REMOVE_GROUP';
export const REMOVE_TILE = 'POS_CONF_LAYOUT/REMOVE_TILE';
export const UPDATE_POSITIONS_IN_APP =
	'POS_CONF_LAYOUT/UPDATE_POSITIONS_IN_APP';
export const UPDATE_TILE_POSITION_IN_POS =
	'POS_CONF_LAYOUT/UPDATE_TILE_POSITION_IN_POS';
export const CLEAR_TILES = 'POS_CONF_LAYOUT/CLEAR_TILES';
export const FETCHING = 'POS_CONF_LAYOUT/FETCHING';
export const UPDATE_TILE_POSITION = 'POS_CONF_LAYOUT/UPDATE_TILE_POSITION';
export const UPDATE_TILE = 'POS_CONF_LAYOUT/UPDATE_TILE';
export const ADD_PRODUCTS_VARIANT_TO_TILE =
	'POS_CONF_LAYOUT/ADD_PRODUCTS_VARIANT_TO_TILE';
export const SET_IMAGE_ASSETS = 'POS_CONF_LAYOUT/SET_IMAGE_ASSETS';
export const REMOVE_PRODUCTS_VARIANT_FROM_TILE =
	'POS_CONF_LAYOUT/REMOVE_PRODUCTS_VARIANT_FROM_TILE';
export const UPDATE_PRODUCTS_VARIANT_ON_TILE =
	'POS_CONF_LAYOUT/UPDATE_PRODUCTS_VARIANT_ON_TILE';
export const CLEAN_UP = 'POS_CONF_LAYOUT/CLEAN_UP';
export const SET_FULL_VARIANTS_LIST = 'POS_CONF_LAYOUT/SET_FULL_VARIANTS_LIST';
export const SET_LOADING = 'POS_CONF_LAYOUT/SET_LOADING';

export function cleanUp() {
	return {
		type: CLEAN_UP
	};
}

export function setLoading(payload) {
	return {
		type: SET_LOADING,
		payload
	};
}

export function setActiveTile(activeTile = {}) {
	return {
		type: SET_ACTIVE_TILE,
		payload: {
			activeTile
		}
	};
}

export function setActiveTileType(payload) {
	return {
		type: SET_ACTIVE_TILE_TYPE,
		payload
	};
}

export function setFetchingTile(payload) {
	return {
		type: SET_FETCHING_TILE,
		payload
	};
}

export function setShowAddTileModal(payload) {
	return {
		type: SET_SHOW_ADD_TILE_MODAL,
		payload
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function setPosConfigurationsVariantsList(
	posConfigurationsVariantsList = []
) {
	return {
		type: SET_VARIANTS_LIST,
		payload: {
			posConfigurationsVariantsList
		}
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function setFullPosConfigurationsVariantsList(
	posFullConfigurationsVariantsList = []
) {
	return {
		type: SET_FULL_VARIANTS_LIST,
		payload: posFullConfigurationsVariantsList
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function setGroupItemInFocus(groupItemInFocus = []) {
	return {
		type: SET_GROUP_IN_FOCUS,
		payload: {
			groupItemInFocus
		}
	};
}

/**
 * @function setAssets
 */
export function setAssets(payload) {
	return {
		type: SET_IMAGE_ASSETS,
		payload
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function setTilesInGroup(tilesInGroup = []) {
	return {
		type: SET_TILES_IN_GROUP,
		payload: {
			tilesInGroup
		}
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function addProductsVariantToTile(groupTileProductVariant = {}) {
	return {
		type: ADD_PRODUCTS_VARIANT_TO_TILE,
		payload: {
			groupTileProductVariant
		}
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function updateProductsVariantOnTile(
	tileLayoutTileProductVariant = null,
	tileId = null
) {
	return {
		type: UPDATE_PRODUCTS_VARIANT_ON_TILE,
		payload: {
			tileLayoutTileProductVariant,
			tileId
		}
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function removeProductsVariantFromTile(
	tileProductTileVariantId = null,
	tileId = null
) {
	return {
		type: REMOVE_PRODUCTS_VARIANT_FROM_TILE,
		payload: {
			tileProductTileVariantId,
			tileId
		}
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function removeTile(tileId = null) {
	return {
		type: REMOVE_TILE,
		payload: {
			tileId
		}
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function clearTiles() {
	return {
		type: CLEAR_TILES
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function setGroups(groups = {}) {
	return {
		type: SET_GROUPS,
		payload: {
			groups
		}
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function removeGroup(groupId = null) {
	return {
		type: REMOVE_GROUP,
		payload: {
			groupId
		}
	};
}

/**
 * @param {string} reportName
 * @param {object} report
 */
export function setGridData(gridData = {}) {
	return {
		type: SET_GRID_DATA,
		payload: {
			...gridData
		}
	};
}

/**
 * @param {Boolean} fetching
 */
export function fetching(fetching = false) {
	return {
		type: FETCHING,
		payload: fetching
	};
}

/**
 * @param {number} updatedTiles
 * @deprecated
 */
export function updateTilePositionInPOS(dragIndex, hoverIndex) {
	return {
		type: UPDATE_TILE_POSITION_IN_POS,
		payload: {
			dragIndex,
			hoverIndex
		}
	};
}

/**
 * @param {number} updatedTiles
 */
export function updatePositionsInApp(dragIndex, hoverIndex) {
	return {
		type: UPDATE_POSITIONS_IN_APP,
		payload: {
			dragIndex,
			hoverIndex
		}
	};
}

/**
 * @param {number} updatedTiles
 */
export function updateTile(tile) {
	return {
		type: UPDATE_TILE,
		payload: {
			tile
		}
	};
}
