'use strict';

import PropTypes from 'prop-types';
import SelectInput from 'react-select';
import React from 'react';
const FileTypePicker = ({ fileUpload, fileUploadState, phrases }) => (
	<div className="file-upload__file-type-picker">
		<h5>{phrases.ADD_FILETYPE}</h5>
		<div className="file-upload__select">
			<SelectInput
				clearable={false}
				ignoreAccents={false}
				openOnFocus
				multi={false}
				name="file-type-picker"
				onChange={event => fileUpload.handleAddFileType(event.value)}
				options={fileUpload.handleFormatFileTypeTemplatesAsOptions(
					fileUploadState.fileTypeTemplates
				)}
				searchable
				value={null}
			/>
		</div>
	</div>
);

FileTypePicker.defaultProps = {
	fileUpload: {}
};

FileTypePicker.propTypes = {
	fileUpload: PropTypes.object.isRequired,
	phrases: PropTypes.object.isRequired
};

export default FileTypePicker;
