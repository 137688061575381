'use strict';

import ReportDataProviderAbstract from './report.dataProvider.abstract';
import { get } from 'api.vanilla.service';

class ReportEndpointDataProvider extends ReportDataProviderAbstract {
	constructor(endpoint) {
		super();

		// Set new collection
		this.setEndpoint(endpoint);

		this.abortController = undefined;
	}

	/**
	 * @function setEndpoint
	 * @memberOf ReportCollectionDataProvider
	 * @description Set endpoint
	 * @param {string}
	 */
	setEndpoint(endpoint) {
		this.endpoint = endpoint;
	}

	/**
	 * @function getResultSet
	 * @memberOf ReportEndpointDataProvider
	 * @description Gets data via paginator
	 * @param {object} filter
	 * @param {object} parameters
	 */
	getResultSet(filter, parameters, abortController) {
		if (this.abortController !== undefined) {
			// Cancel the previous request
			this.abortController.abort();
		}

		// Feature detect
		if ('AbortController' in window) {
			this.abortController = new AbortController();
			this.signal = this.abortController.signal;
		}

		const params = {
			filter,
			...parameters
		};

		return get(
			this.endpoint,
			params,
			undefined,
			undefined,
			this.signal
		).finally(() => {
			this.abortController = undefined;
			this.signal = undefined;
		});
	}
}

export default ReportEndpointDataProvider;
