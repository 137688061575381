export const SET_INGREDIENT_ASSOCIATION_WITH_PRODUCTS =
	'SALES_CONFIGURATION_INGREDIENT_ASSOCIATION_WITH_PRODUCTS/SET_INGREDIENT_ASSOCIATION_WITH_PRODUCTS';
export const UPDATE_INGREDIENT_ASSOCIATION_WITH_PRODUCTS =
	'SALES_CONFIGURATION_INGREDIENT_ASSOCIATION_WITH_PRODUCTS/UPDATE_INGREDIENT_ASSOCIATION_WITH_PRODUCTS';
export const RESET_STATE =
	'SALES_CONFIGURATION_INGREDIENT_ASSOCIATION_WITH_PRODUCTS/RESET_STATE';

export function setIngredientAssociationWithProducts(payload) {
	return {
		type: SET_INGREDIENT_ASSOCIATION_WITH_PRODUCTS,
		payload
	};
}

export function updateIngredientAssociationWithProducts(payload) {
	return {
		type: UPDATE_INGREDIENT_ASSOCIATION_WITH_PRODUCTS,
		payload
	};
}

export function resetState() {
	return {
		type: RESET_STATE
	};
}
