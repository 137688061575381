export const RESET_STATE = 'WORKPLACE_SYSTEM_SOFTWARES/RESET_STATE';
export const SET_WORKPLACE_SYSTEM_SOFTWARES =
	'WORKPLACE_SYSTEM_SOFTWARES/SET_WORKPLACE_SYSTEM_SOFTWARES';
export const UPDATE_WORKPLACE_SYSTEM_SOFTWARES =
	'WORKPLACE_SYSTEM_SOFTWARES/UPDATE_WORKPLACE_SYSTEM_SOFTWARES';

export const UPDATE_BATCH_FORM = 'WORKPLACE_SYSTEM_SOFTWARES/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST = 'WORKPLACE_SYSTEM_SOFTWARES/ADD_BATCH_LIST';
export const REMOVE_BATCH_LIST_ITEM =
	'WORKPLACE_SYSTEM_SOFTWARES/REMOVE_BATCH_LIST_ITEM';
export const EDIT_BATCH_LIST_ITEM =
	'WORKPLACE_SYSTEM_SOFTWARES/EDIT_BATCH_LIST_ITEM';

export function setWorkplaceSystemSoftwares(payload) {
	return {
		type: SET_WORKPLACE_SYSTEM_SOFTWARES,
		payload,
	};
}

export function updateWorkplaceSystemSoftwares(payload) {
	return {
		type: UPDATE_WORKPLACE_SYSTEM_SOFTWARES,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}
