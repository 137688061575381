'use strict';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';
import _compact from 'lodash/compact';

/**
 * @function mapPosConfigurationIngredientsForDiscounts
 * @param {Object} object - contains our selected values and retrived values from api call
 * @description adds posConfigurationIngredient object to our mapped data object
 * if it matches for ingredientId and posConfigurationVersionId
 * THIS IS USEFUL BECAUSE LATER WE FILTER OUT VALUES THAT ALREADY HAVE A posConfigurationDiscountIngredients added
 */
export function mapPosConfigurationIngredientsForDiscounts({
	discountData,
	posConfigurationIngredients,
	batchFormData
}) {
	return discountData.map(discount => {
		const ingredientId = _get(discount, 'ingredient.id', null);
		const posConfigurationVersionId = _get(
			discount,
			'posConfigurationDiscount.pos_configuration_version.id',
			null
		);

		// find match for ingredient id and pos config version id
		const posConfigurationIngredient = posConfigurationIngredients.find(
			entry => {
				if (
					entry.pos_configuration_version.id === posConfigurationVersionId &&
					entry.ingredient.id === ingredientId
				)
					return true;
				return false;
			}
		);

		// add discount values based on discount type choosen
		// nominal chosen
		if (_get(batchFormData, 'discountType.value', '') === 'Nominal') {
			discount = {
				...discount,
				toStayDiscount: batchFormData.toStayDiscount,
				toGoDiscount: batchFormData.toGoDiscount
			};
		}
		// percentage chosen
		else {
			const toStayPrice = _get(
				posConfigurationIngredient,
				'to_stay_price',
				null
			);
			const toGoPrice = _get(posConfigurationIngredient, 'to_go_price', null);

			// calculate results
			const calculatedPrices = calculateDiscounts({
				toStayPrice,
				toGoPrice,
				batchFormData
			});

			discount = {
				...discount,
				toStayDiscount: calculatedPrices.toStayDiscount.toString(),
				toGoDiscount: calculatedPrices.toGoDiscount.toString()
			};
		}

		return {
			...discount,
			posConfigurationIngredient: posConfigurationIngredient || null
		};
	});
}

/**
 * @function getPosConfigurationIngredientFilter
 * @param {Objet} object - contains array of mapped values for table in last step
 * @description returns a filter that will get us all posConfigurationProductVariants for any of produtVariant and posConfigurationVersion combo we choose
 */
export function getPosConfigurationIngredientFilter({
	discountsArray,
	ingredientsArray
}) {
	// get array of ingredient id's
	const ingredientIds = ingredientsArray.reduce((acc, currentValue, index) => {
		const id = currentValue.value.id;

		acc =
			index === ingredientsArray.length - 1
				? `${acc}'${id}']`
				: `${acc}'${id}',`;
		return acc;
	}, '[');

	// put all pos config versions in an array so we can eliminate duplicates
	let posConfigVersionArray = discountsArray.map(entry => {
		return _get(entry, 'value.pos_configuration_version.id', null);
	});

	// in case no pos_configuration_version.id
	posConfigVersionArray = _compact(posConfigVersionArray);
	// we can have multiple same ids
	posConfigVersionArray = _uniq(posConfigVersionArray);

	// get array of discount id's
	const posConfigurationVersionIds = posConfigVersionArray.reduce(
		(acc, currentValue, index) => {
			const id = currentValue;

			acc =
				index === posConfigVersionArray.length - 1
					? `${acc}'${id}']`
					: `${acc}'${id}',`;
			return acc;
		},
		'['
	);

	return `:pos_configuration_version.id=IN=${posConfigurationVersionIds};:ingredient.id=IN=${ingredientIds}`;
}

/**
 * @function getPosConfigurationDiscountIngredientFilter
 * @param {Object} object - contains retrived posConfigIngredients from api and selected posConfigurationDiscounts
 * @description returns a filter used to retrive all existing posConfigDisIngredients so we can filter out our current
 * selected ones and mark as 'canAdd: false'
 * filter like -> :pos_configuration_discount.id=IN=['1', '2', '3'...];:pos_configuration_ingredient.ingredient.id=IN=['1', '2'...]
 */
export function getPosConfigurationDiscountIngredientFilter({
	ingredients,
	posConfigurationDiscount
}) {
	// get array of ingredient id's
	const ingredientIds = ingredients.reduce((acc, currentValue, index) => {
		const ingredientId = currentValue.value.id;

		acc =
			index === ingredients.length - 1
				? `${acc}'${ingredientId}']`
				: `${acc}'${ingredientId}',`;
		return acc;
	}, '[');

	// get array of discount id's
	const discountIds = posConfigurationDiscount.reduce(
		(acc, currentValue, index) => {
			const discountId = currentValue.value.id;

			acc =
				index === posConfigurationDiscount.length - 1
					? `${acc}'${discountId}']`
					: `${acc}'${discountId}',`;
			return acc;
		},
		'['
	);

	return `:pos_configuration_discount.id=IN=${discountIds};:pos_configuration_ingredient.ingredient.id=IN=${ingredientIds}`;
}

/**
 * @function markSelectedValuesWithCanAdd
 * @param {Object} object - contains selected data with posConfigProdVariants and posConfigurationDiscountIngredient retrived from the api
 * @description loops through our selected values and if we get a match for posConfigurationDiscountId and posConfigurationIngredientId
 * it means that discount already exists hence adding 'canAdd: false'
 */
export function markSelectedValuesWithCanAdd({
	list,
	posConfigurationDiscountIngredient
}) {
	return list.map(entry => {
		// if posConfigurationIngredient is faulty (due to "bad" discount/ingredient combo, return)
		if (!entry.posConfigurationIngredient) return { ...entry, canAdd: false };

		const posConfigurationDiscountId = entry.posConfigurationDiscount.id;
		const posConfigurationIngredientId = entry.posConfigurationIngredient.id;

		// try to find pos configuration discount ingredient
		const entryExists = posConfigurationDiscountIngredient.find(
			entry =>
				_get(entry, 'pos_configuration_discount.id', null) ===
					posConfigurationDiscountId &&
				_get(entry, 'pos_configuration_ingredient.id', null) ===
					posConfigurationIngredientId
		);

		return {
			...entry,
			canAdd: _isEmpty(entryExists),
			// spread discount amounts if already exists (just for visuals)
			...(_get(entryExists, 'to_stay_discount_price', false) && {
				toStayDiscount: entryExists.to_stay_discount_price
			}),
			...(_get(entryExists, 'to_go_discount_price', false) && {
				toGoDiscount: entryExists.to_go_discount_price
			})
		};
	});
}

function calculateDiscounts({ toStayPrice, toGoPrice, batchFormData }) {
	// apply discount rates to prices based on percentage
	let toStayDiscount =
		toStayPrice * (batchFormData.toStayDiscountPercentage / 100);
	let toGoDiscount = toGoPrice * (batchFormData.toGoDiscountPercentage / 100);

	// round up prices
	switch (_get(batchFormData, 'roundToNearest.value', '1')) {
	case '1':
		toStayDiscount = Math.round(toStayDiscount / 1) * 1;
		toGoDiscount = Math.round(toGoDiscount / 1) * 1;
		break;
	case '0.5':
		toStayDiscount = Math.round(toStayDiscount / 0.5) * 0.5;
		toGoDiscount = Math.round(toGoDiscount / 0.5) * 0.5;
		break;
	case '0.25':
		toStayDiscount = Math.round(toStayDiscount / 0.25) * 0.25;
		toGoDiscount = Math.round(toGoDiscount / 0.25) * 0.25;
		break;
	case '0.1':
		toStayDiscount = Number(
			(Math.round(toStayDiscount / 0.1) * 0.1).toFixed(2)
		);
		toGoDiscount = Number((Math.round(toGoDiscount / 0.1) * 0.1).toFixed(2));
		break;
	case '0.05':
		toStayDiscount = Number(
			(Math.round(toStayDiscount * 20) / 20).toFixed(2)
		);
		toGoDiscount = Number((Math.round(toGoDiscount * 20) / 20).toFixed(2));
		break;
	case '0.01':
		toStayDiscount = Number(
			(Math.round(toStayDiscount / 0.01) * 0.01).toFixed(3)
		);
		toGoDiscount = Number(
			(Math.round(toGoDiscount / 0.01) * 0.01).toFixed(3)
		);
		break;
	default:
		break;
	}

	return {
		toStayDiscount,
		toGoDiscount
	};
}
