export const RESET_SHIFTPLAN_SENIORITY =
	'GLOBAL_ADMINISTRATION_SHIFTPLAN_SENIORITY/RESET_SHIFTPLAN_SENIORITY';
export const SET_SHIFTPLAN_SENIORITY =
	'GLOBAL_ADMINISTRATION_SHIFTPLAN_SENIORITY/SET_SHIFTPLAN_SENIORITY';
export const UPDATE_SHIFTPLAN_SENIORITY =
	'GLOBAL_ADMINISTRATION_SHIFTPLAN_SENIORITY/UPDATE_SHIFTPLAN_SENIORITY';

export const EDIT_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_SHIFTPLAN_SENIORITY/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'GLOBAL_ADMINISTRATION_SHIFTPLAN_SENIORITY/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'GLOBAL_ADMINISTRATION_SHIFTPLAN_SENIORITY/ADD_BATCH_LIST_ITEM_ROW';

/**
 * @param {object} payload
 */
export function setProductBundle(payload) {
	return {
		type: SET_SHIFTPLAN_SENIORITY,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function updateProductBundle(payload) {
	return {
		type: UPDATE_SHIFTPLAN_SENIORITY,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetProductBundle() {
	return {
		type: RESET_SHIFTPLAN_SENIORITY,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}
