'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonGroup, InputCollectionSelect, Tag } from 'dumb';
import { ModalRow } from 'dumb/modal';

import _uniqBy from 'lodash/uniqBy';
import _get from 'lodash/get';

import phrases from './../../../workplacePosConfiguration.phrases';

class WorkplacePosConfigurationBatchWorkplacesAdd extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};

		this._getWorkplaceTags = this._getWorkplaceTags.bind(this);
	}

	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		const value = e.target ? e.target.value || e.target.checked : e;

		updateBatchForm({
			[type]: value,
		});
	}

	_getWorkplaceTags() {
		const { batchFormData } = this.props;

		// get rid of workplace duplicates
		const workplaces = _uniqBy(
			batchFormData.posConfiguration,
			'value.market.id'
		);

		return workplaces.map((entry, index) => {
			return (
				<Tag color="dddddd" key={index}>
					{_get(entry, 'value.market.name', null)}
				</Tag>
			);
		});
	}

	render() {
		const { batchFormData, toggleOverlay, workplaceReduxKey } = this.props;

		return (
			<>
				<div className="workplace-pos-configuration__modal-batch__workplace-markets">
					<span>Showing workplaces for following markets:</span>
					<div className="workplace-pos-configuration__modal-batch__workplace-markets__market-tags">
						{this._getWorkplaceTags()}
					</div>
				</div>
				<ModalRow>
					<ButtonGroup type="full-width">
						<InputCollectionSelect
							className="workplace-pos-configuration__modal-batch-select"
							id="workplace"
							label={phrases.MODAL_BATCH_STEP_WORKPLACE}
							placeholder={phrases.MODAL_BATCH_STEP_WORKPLACE_PLACEHOLDER}
							value={batchFormData.workplace}
							handleChange={(key, value) =>
								this.editStoreEntry(value, 'workplace')
							}
							multi
							cache
							apiPath="/administration/workplaces"
							params={{
								limit: 50,
								filter: `:market.id=IN=${batchFormData.marketsFilter}`,
							}}
							optionFormat={(entry) => ({
								value: entry,
								label: entry.name,
							})}
							inputFilterFormat={(input) => `:name=like='%${input}%'`}
						/>

						<Button
							type="inverted"
							shadow
							size="large"
							onClick={() => toggleOverlay(workplaceReduxKey)}>
							<span className="icon icon--more" />
						</Button>
					</ButtonGroup>
				</ModalRow>
			</>
		);
	}
}

WorkplacePosConfigurationBatchWorkplacesAdd.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
	workplaceReduxKey: PropTypes.string,
};

export default WorkplacePosConfigurationBatchWorkplacesAdd;
