'use strict';

var headers = {
	ID: 'ID',
	CREATED: 'Created',
	UPDATED: 'Last updated'
};

var colgroups = {
	ID: 'Id',
	TIMESTAMPS: 'Time stamps'
};

module.exports = {
	headers: headers,
	colgroups: colgroups
};