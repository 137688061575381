'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class TimePicker extends Component {
	constructor(props) {
		super(props);

		this.onContentChange = this.onContentChange.bind(this);
	}

	defineValue(key, value) {
		var onlyNumbersReg = new RegExp('^[0-9]+$');

		if (!onlyNumbersReg.test(value)) {
			return '00';
		}

		if (key === 'hour' && value > 23) {
			return 23;
		}

		if (key === 'minute' && value > 59) {
			return 59;
		}

		return value;
	}

	onContentChange(key, value) {
		const { onChange } = this.props;
		const finalValue = this.defineValue(key, value);

		onChange(key, finalValue);
	}

	render() {
		const { hourValue, minuteValue, disabled } = this.props;

		return (
			<div
				className={cx('time-picker', {
					'time-picker__disabled': !(hourValue && minuteValue)
				})}>
				<input
					className={cx('time-input', { 'time-input__disabled': !hourValue })}
					value={hourValue || '--'}
					onFocus={event => {
						event.target.select();
					}}
					onChange={e => this.onContentChange('hour', e.target.value)}
					maxLength="2"
					disabled={disabled}
				/>
				:
				<input
					className={cx('time-input', { 'time-input__disabled': !minuteValue })}
					value={minuteValue || '--'}
					onFocus={event => {
						event.target.select();
					}}
					onChange={e => this.onContentChange('minute', e.target.value)}
					maxLength="2"
					disabled={disabled}
				/>
				{!isNaN(hourValue) && hourValue > 11 ? 'PM' : 'AM'}
			</div>
		);
	}
}

TimePicker.defaultProps = {
	hourValue: '--',
	minuteValue: '--',
	disabled: false
};

TimePicker.propTypes = {
	hourValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	minuteValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func,
	disabled: PropTypes.bool
};

export default TimePicker;
