'use strict';
import { store } from 'appState';
import { enqueueSnackbar } from 'smart/notifier/notifier.actions';

/**
 * @namespace __feedbackData
 * @memberOf SERVICES
 * @description
 * Broadcast feedback events and data to {@link DIRECTIVES.jtjFeedback}.
 */

/**
 * Feedback types
 * @type {Object}
 */
const _feedbackTypes = {
	0: 'error',
	1: 'success',
};

/**
 * @function set
 * @memberOf SERVICES.__feedback
 * @paramn options = {autoHideDuration, autoHideDuration, anchorOrigin, action...}
 * @description Sets feedback data
 */
export function set(message, type, options = {}) {
	type = _feedbackTypes[type];
	store.dispatch(
		enqueueSnackbar({
			message,
			options: {
				variant: type,
				...options,
			},
		})
	);
}
