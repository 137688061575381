import {
	SET_PRODUCT_ADDONS_INGREDIENTS,
	UPDATE_PRODUCT_ADDONS_INGREDIENTS,
	RESET_PRODUCT_ADDONS_INGREDIENTS
} from './addonIngredient.actions';

const defaultState = {
	data: {
		defaultProductAddonsIngredients: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_PRODUCT_ADDONS_INGREDIENTS: {
		return {
			...state,
			data: {
				defaultProductAddonsIngredients: payload
			}
		};
	}

	case UPDATE_PRODUCT_ADDONS_INGREDIENTS: {
		return {
			...state,
			data: {
				defaultProductAddonsIngredients: {
					...state.data.defaultProductAddonsIngredients,
					...payload
				}
			}
		};
	}

	case RESET_PRODUCT_ADDONS_INGREDIENTS: {
		return defaultState;
	}

	default:
		return state;
	}
}

export default reducer;
