'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input, InputCollectionSelect, Toggle } from 'dumb';
import { ModalRow } from 'dumb/modal';

// utils
import { getOperationTypeOptions } from './../../../utils';

import _get from 'lodash/get';

class BatchStepForm extends Component {
	editStoreEntry(e, type) {
		const { batchOptions } = this.props;

		let value;
		if (e === null) value = e;
		else value = e.target ? e.target.value : e;

		this.props.updateBatchForm({
			...batchOptions,
			...{ [type]: value },
		});
	}

	render() {
		const { batchOptions } = this.props;

		return (
			<>
				<ModalRow>
					<Input
						label="Name"
						placeholder="Enter name..."
						id="name"
						value={_get(batchOptions, 'name', '')}
						onChange={(event) => this.editStoreEntry(event, 'name')}
					/>
				</ModalRow>

				<ModalRow>
					<InputCollectionSelect
						className="modal-batch-select"
						id="ing-cat"
						label="Ingredient category"
						placeholder="Select ingredient category"
						value={batchOptions?.ingredient_category}
						handleChange={(key, value) =>
							this.editStoreEntry(value, 'ingredient_category')
						}
						multi
						cache
						apiPath="/product/ingredient_categories"
						params={{
							limit: 300,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						tableColumns={[
							{
								Header: 'Name',
								id: 'name',
								accessor: (d) => _get(d, 'name', ''),
								filterPath: ':name',
							},
						]}
						tableTitle="Ingredient categories"
						tableReduxKey="/product/ingredient_categories-ingredients-batch_add_modal"
					/>
				</ModalRow>

				<ModalRow>
					<Input
						label="Fuel"
						id="fuel"
						placeholder="Enter fuel..."
						value={_get(batchOptions, 'fuel', '')}
						onChange={(event) => this.editStoreEntry(event, 'fuel')}
					/>
				</ModalRow>

				<ModalRow>
					<InputCollectionSelect
						className="modal-batch-select"
						id="asset-col1"
						label="Asset collection"
						placeholder="Select asset collection..."
						value={batchOptions?.asset_collection}
						handleChange={(key, value) =>
							this.editStoreEntry(value, 'asset_collection')
						}
						cache
						clearable={false}
						apiPath="/pos/asset_collections"
						params={{
							limit: 300,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						tableColumns={[
							{
								Header: 'Name',
								id: 'name',
								accessor: (d) => _get(d, 'name', ''),
								filterPath: ':name',
							},
							{
								Header: 'Description',
								id: 'desc',
								accessor: (d) => _get(d, 'description', ''),
								filterPath: ':description',
							},
							{
								Header: 'Legacy',
								id: 'legacy',
								accessor: 'legacy',
								filterPath: ':legacy',
								filterable: false,
								Cell: (d) => {
									return (
										<Input
											type="checkbox"
											checked={d.original.legacy}
											disabled
										/>
									);
								},
							},
						]}
						tableTitle="Asset Collections"
						tableReduxKey="/pos/asset_collections-ingredients-batch_add_modal"
					/>
				</ModalRow>

				<ModalRow>
					<InputCollectionSelect
						className="modal-batch-select"
						id="ingprice-col"
						label="Ingredient pricing group"
						placeholder="Select ingredient pricing grourp"
						value={batchOptions?.ingredient_pricing_group}
						handleChange={(key, value) =>
							this.editStoreEntry(value, 'ingredient_pricing_group')
						}
						multi
						cache
						apiPath="/product/ingredient_pricing_groups"
						params={{
							limit: 300,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						tableColumns={[
							{
								Header: 'Name',
								id: 'name',
								accessor: (d) => _get(d, 'name', ''),
								filterPath: ':name',
							},
						]}
						tableTitle="Ingredient pricing groups"
						tableReduxKey="/product/ingredient_pricing_groups-ingredients-batch_add_modal"
					/>
				</ModalRow>

				<ModalRow>
					<Input
						label="List name"
						id="listName"
						placeholder="Enter list name..."
						value={_get(batchOptions, 'listName', '')}
						onChange={(event) => this.editStoreEntry(event, 'listName')}
					/>
				</ModalRow>

				<ModalRow>
					<InputCollectionSelect
						id="operationType"
						label="Operation type"
						placeholder="Select operation type..."
						value={batchOptions?.operationType}
						handleChange={(key, value) =>
							this.editStoreEntry(value, 'operationType')
						}
						cache
						clearable={false}
						options={getOperationTypeOptions()}
					/>
				</ModalRow>

				<ModalRow>
					<Toggle
						id="availabilityAdministration"
						label="Availability administration"
						toggled={batchOptions.availabilityAdministration}
						onClick={(e) =>
							this.editStoreEntry(e, 'availabilityAdministration')
						}
					/>
				</ModalRow>
			</>
		);
	}
}

BatchStepForm.propTypes = {
	batchOptions: PropTypes.object,
	updateBatchForm: PropTypes.func,
};

export default BatchStepForm;
