'use strict';

// All valid extensions
const validExtensions = {
	PDF: ['pdf'],
	PNG: ['png'],
	XLS: ['xls', 'xlsx'],
	CSV: ['csv'],
	JPG: ['jpg']
};

/**
 * @function getExtensions
 * @memberOf Extensions
 * @param  {array} extensionKeys
 * @return {array}
 */
const get = extensionKeys => {
	const extensions = extensionKeys.reduce((acc, key) => {
		// Skip if none exists
		if (validExtensions[key] === undefined) return acc;

		// Add to accumulator
		acc = [...acc, ...validExtensions[key]];

		// Return accumulated extensions
		return acc;
	}, []);

	// Return
	return extensions;
};

export default get;