import React, { Component } from 'react';
import PropTypes from 'prop-types';

// redux
import { batch } from 'react-redux';

// components
import { Card, Modal } from 'dumb';

import ItemRow from './components/itemRow/itemRow';
import ItemGrid from './components/itemGrid/itemGrid';
import ItemDropZone from './components/itemDropZone/itemDropZone';
import AppLayoutLoader from './../appLayoutLoader/appLayoutLoader';

import AddTileModal from './../../../addTileModal/addTileModal';

// utils
import _get from 'lodash/get';
import _maxBy from 'lodash/maxBy';
import cx from 'classnames';

// enums/ phrases
import enums from './posLayout.enums';
import phrases from './posLayout.phrases';

import './posLayout.css';

class PosLayout extends Component {
	constructor(props) {
		super(props);

		this.openTileModalWrapper = this.openTileModalWrapper.bind(this);
		this.moveCard = this.moveCard.bind(this);
		this.onDrop = this.onDrop.bind(this);
		this.toggleDeleteModal = this.toggleDeleteModal.bind(this);

		this.state = {
			isConfirmModalOpen: false,
			isModalLoading: false,
			currentDeletId: null,
		};
	}

	toggleDeleteModal(id) {
		this.setState(() => ({
			currentDeletId: this.state.currentDeletId ? null : id,
			isConfirmModalOpen: !this.state.isConfirmModalOpen,
		}));
	}

	openTileModalWrapper(tile, mode = enums.ADD) {
		const {
			editProductTile,
			setActiveTile,
			setActiveTileType,
			setShowAddTileModal,
			tilesInGroup,
		} = this.props;

		const position = _maxBy(Object.values(tilesInGroup), (group) =>
			parseInt(group.position, 10)
		);

		let editProductTileFunctionProps = {};

		switch (mode) {
			case enums.ADD:
				editProductTileFunctionProps = {
					background_color: 'ffffff',
					title_color: '464646',
					type: 'Product',
				};
				break;
			case enums.ADD_DROP:
				editProductTileFunctionProps = {
					id: tile.id,
					name: _get(tile, 'label', 'unnamed!'),
					product: { name: tile.label, id: tile.value },
					position: position + 1,
					type: 'Product',
				};
				break;
			case enums.EDIT:
				editProductTileFunctionProps = tile;
				break;
			default:
				break;
		}

		batch(() => {
			editProductTile(editProductTileFunctionProps);
			setActiveTile(tile);
			setActiveTileType('Product');
			setShowAddTileModal(true);
		});
	}

	moveCard(dragIndex, hoverIndex) {
		const { updatePositionsInApp } = this.props;

		updatePositionsInApp(dragIndex, hoverIndex);
	}

	onDrop() {
		const { tilesInGroup, rotateTiles } = this.props;

		const payload = tilesInGroup.map((tile, index) => {
			return { id: tile.id, position: index + 1 };
		});

		rotateTiles({ rotations: payload }, true);
	}

	handleRemoveTile() {
		const { removeTile } = this.props;

		this.setState(() => ({
			isModalLoading: true,
		}));

		removeTile(this.state.currentDeletId)
			.then(() => {
				this.setState(() => ({
					isModalLoading: false,
					currentDeletId: null,
					isConfirmModalOpen: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					isModalLoading: false,
				}));
			});
	}

	render() {
		const {
			tilesInGroup,
			imageAssets,
			showAddTileModal,
			setShowAddTileModal,
			layoutId,
			activeTile,
			updateTile,
			addTile,
			fetching,
			posConfigurationsVariantsList,
			groupItemInFocus,
			subType,
			disabled,
		} = this.props;

		return (
			<>
				<Card
					className={cx('pos-layout', {
						'pos-layout__row': subType !== 'Grid',
					})}>
					{fetching && <AppLayoutLoader loading />}

					{disabled && (
						<div className="pos-layout__disabled">
							<span>{phrases.LAYOUT_DISABLED}</span>
						</div>
					)}
					<div className="pos-layout__content-wrapper">
						{tilesInGroup
							.sort((a, b) => a.position - b.position)
							.map((entry, index) => {
								// for grid we do filter on asset collection id first and then
								// we take the [0] entry res one to show - double check it's right!
								const imageAsset =
									subType === 'Grid'
										? imageAssets.find(
												(asset) =>
													asset.asset_collection.id ===
														_get(entry, 'product.asset_collection.id', null) &&
													asset.type === 'Product Tile'
										  )
										: imageAssets.find(
												(asset) =>
													asset.asset_collection.id ===
														_get(entry, 'product.asset_collection.id', null) &&
													asset.type === 'Row Layout Tile'
										  );

								return subType === 'Grid' ? (
									<ItemGrid
										index={index}
										id={entry.id}
										key={entry.id}
										name={entry.name}
										description={entry.description}
										backgroundColor={entry.background_color}
										textColor={entry.title_color}
										moveCard={(dragIndex, hoverIndex) =>
											this.moveCard(dragIndex, hoverIndex)
										}
										onDrop={this.onDrop}
										imageAsset={imageAsset}
										removeTile={this.toggleDeleteModal}
										openTileModal={() =>
											this.openTileModalWrapper(entry, enums.EDIT)
										}
									/>
								) : (
									<ItemRow
										id={entry.id}
										index={index}
										key={entry.id}
										imageAsset={imageAsset}
										tile={entry}
										removeTile={this.toggleDeleteModal}
										editTile={(tile) =>
											this.openTileModalWrapper(tile, enums.EDIT)
										}
										moveCard={(dragIndex, hoverIndex) =>
											this.moveCard(dragIndex, hoverIndex)
										}
										onDrop={this.onDrop}
									/>
								);
							})}

						{!disabled && (
							<ItemDropZone
								openTileModal={() =>
									this.openTileModalWrapper({ type: 'Product' })
								}
								onDrop={(props) =>
									this.openTileModalWrapper(props, enums.ADD_DROP)
								}
								subTypeRow={subType !== 'Grid'}
							/>
						)}
					</div>
				</Card>

				{showAddTileModal && (
					<AddTileModal
						isOpen={showAddTileModal}
						handleClose={() => {
							setShowAddTileModal(false);
						}}
						layoutId={layoutId}
						imageAssets={imageAssets}
						addTile={addTile}
						updateTile={updateTile}
						layoutGroupId={groupItemInFocus}
						posConfigurationsVariantsList={posConfigurationsVariantsList}
						activeTile={activeTile}
						subTypeRow={subType !== 'Grid'}
					/>
				)}

				{this.state.isConfirmModalOpen && (
					<Modal
						header="Are you sure?"
						onClose={this.toggleDeleteModal}
						confirmButtonLabel="Yes, delete!"
						type="confirmation"
						onConfirmClick={() => this.handleRemoveTile()}
						onCancelClick={this.toggleDeleteModal}
						isOpen={this.state.isConfirmModalOpe}
						loading={this.state.isModalLoading}
						zIndex="550"
					/>
				)}
			</>
		);
	}
}

PosLayout.propTypes = {
	tilesInGroup: PropTypes.array,
	imageAssets: PropTypes.array,
	removeTile: PropTypes.func,
	setActiveTile: PropTypes.func,
	editProductTile: PropTypes.func,
	setActiveTileType: PropTypes.func,
	setShowAddTileModal: PropTypes.func,
	showAddTileModal: PropTypes.bool,
	fetching: PropTypes.bool,
	layoutId: PropTypes.number,
	activeTile: PropTypes.object,
	updateTile: PropTypes.func,
	addTile: PropTypes.func,
	posConfigurationsVariantsList: PropTypes.array,
	groupItemInFocus: PropTypes.number,
	updatePositionsInApp: PropTypes.func,
	rotateTiles: PropTypes.func,
	subType: PropTypes.string,
	disabled: PropTypes.bool,
};

export default PosLayout;
