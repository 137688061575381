import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './shiftplanSeniority.phrases';

/**
 * @function fetchShiftplanSeniorities
 * @description Fetch all shiftplanning seniorities
 * @param {object} state
 */
export function fetchShiftplanSeniorities(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/shiftplanning/seniority_levels', params, null, state.headers)
		.then((res) => res)
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addShiftplanSeniority
 * @description add product bundle
 */
export function addShiftplanSeniority(data) {
	return post('/shiftplanning/seniority_levels', data)
		.then((res) => {
			setFeedback(phrases.SHIFTPLAN_SENIORITY_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editShiftplanSeniority
 * @description edit product bundle
 */
export function editShiftplanSeniority(data) {
	return post(`/shiftplanning/seniority_levels/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.SHIFTPLAN_SENIORITY_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editShiftplanSeniorities
 * @description edit product bundles
 */
export function editShiftplanSeniorities(data) {
	return patch('/shiftplanning/seniority_levels', data)
		.then((res) => {
			setFeedback(phrases.SHIFTPLAN_SENIORITIES_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteShiftplanSeniority
 * @description delete product bundle
 */
export function deleteShiftplanSeniority(id) {
	return remove(`/shiftplanning/seniority_levels/${id}`)
		.then((res) => {
			setFeedback(phrases.SHIFTPLAN_SENIORITY_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
