'use strict';

var headers = {
	REPORTING_MARKETS: {
		NAME: 'Name'
	},
	KPI: {
		WORKPLACE_NAME: 'Workplace',
		MARKET_NAME: 'Market',
		TURNOVER: 'Turnover',
		EMPLOYEES: 'Employees',
		PRODUCTS: 'Products',
		HOUR: 'Hour',
		KPI_REPORT_HOURLY_LIST: 'Hourly',
		TURNOVER_JUICE: 'Juice',
		PRODUCTS_JUICE: '# Juice',
		TURNOVER_SHAKE: 'Shake',
		PRODUCTS_SHAKE: '# Shake',
		TURNOVER_SANDWICH: 'Sandwich',
		PRODUCTS_SANDWICH: '# Sandwich',
		TURNOVER_COFFEE: 'Coffee',
		PRODUCTS_COFFEE: '# Coffee',
		TURNOVER_LOYALTY_CARD: 'Loyalty card',
		PRODUCTS_LOYALTY_CARD: '# Loyalty card'
	},
	DAILY: {
		WORKPLACE_NAME: 'Workplace',
		MARKET_NAME: 'Market',
		TURNOVER: 'Turnover',
		PERFORMANCE: 'Performance',
		MONDAY: 'Monday',
		TUESDAY: 'Tuesday',
		WEDNESDAY: 'Wednesday',
		THURSDAY: 'Thursday',
		FRIDAY: 'Friday',
		SATURDAY: 'Saturday',
		SUNDAY: 'Sunday',
		MONTH_TO_DATE: 'To M2D',
		END_OF_MONTH: 'To EOM',
		LAST_4_WEEKS: 'L4W',
		LAST_8_WEEKS: 'L8W',
		LAST_12_WEEKS: 'L12W',
		RUNNING_3_MONTHS: 'R3M',
		RUNNING_6_MONTHS: 'R6M',
		RUNNING_9_MONTHS: 'R9M',
		RUNNING_12_MONTHS: 'R12M'
	},
	SHIFTPLANNER: {
		ID: 'Id',
		HOUR: 'Hour',
		WORKPLACE_NAME: 'Workplace',
		MARKET_NAME: 'Market',
		EMPLOYEES: 'Emp',
		PERFORMANCE: 'Performance',
		OPTIMAL_EMPLOYEES: 'Optim',
		PRODUCTS: 'Prod',
		SHIFTPLANNER_REPORT_HOURLY_LIST: 'Hourly',
		WORKPLACE: 'Workplace',
		MONDAY: 'Monday',
		TUESDAY: 'Tuesday',
		WEDNESDAY: 'Wednesday',
		THURSDAY: 'Thursday',
		FRIDAY: 'Friday',
		SATURDAY: 'Saturday',
		SUNDAY: 'Sunday',
		AVERAGE_PRODUCTS_SOLD_HOURLY: 'P/H',
		BUSY_HOURS: 'Busy',
		OPTIMAL_HOURS: 'Optimal',
		SLOW_HOURS: 'Slow',
		TOO_BUSY_HOURS: 'Too slow'
	},
	REPORTING_CURRENCIES: {
		CURRENCY: 'Currency'
	},
	TURNOVER_ACHIEVEMENTS: {
		NAME: 'Name',
		BADGE: 'Badge',
		SORT_ORDER: 'Sort order'
	},
	TURNOVER_ACHIEVEMENTS_RULES: {
		NAME: 'Name',
		TO: 'To',
		FROM: 'From',
		PERCENTAGE: 'Percentage',
		TURNOVER_ACHIEVEMENT: 'Turnover achievement'
	}
};

var titles = {
	KPI: 'Key Performance Indicator',
	DAILY: 'Daily Reporting Stats',
	SHIFTPLANNER: 'Shiftplanner Report',
	REPORTING_CURRENCIES: 'Reporting Currencies',
	TURNOVER_ACHIEVEMENTS: 'Turnover achievements',
	TURNOVER_ACHIEVEMENTS_RULES: 'Turnover achievements rules',
	REPORTING_MARKETS: 'Reporting Markets'
};

var colgroups = {
	KPI: {
		WORKPLACE: 'Workplace',
		TOTAL: 'Total',
		AVERAGE: 'Average',
		PRODUCT_SPLIT: 'Product Split'
	},
	DAILY: {
		WORKPLACE: 'Workplace',
		TURNOVER: 'Turnover',
		PERFORMANCE: 'Performance',
		WEEKINDEX: 'Week Index',
		MONTHINDEX: 'Month Index'
	},
	SHIFTPLANNER: {
		WORKPLACE: 'Workplace',
		MONDAY: 'Monday',
		TUESDAY: 'Tuesday',
		WEDNESDAY: 'Wednesday',
		THURSDAY: 'Thursday',
		FRIDAY: 'Friday',
		SATURDAY: 'Saturday',
		SUNDAY: 'Sunday',
		SUMMARY: 'SUMMARY'
	}
};

module.exports = {
	headers: headers,
	titles: titles,
	colgroups: colgroups
};
