import phrases from '../../workplaceAddress/workplaceAddress.phrases';

import { set as setFeedback } from 'feedback.vanilla.service';
import { get, post } from 'api.vanilla.service';

class WorkplaceAddress {
	constructor(workplaceId) {
		this.workplaceId = workplaceId;

		this.addressController = this.addressController.bind(this);
		this.sendForm = this.sendForm.bind(this);
		this.getWorkplaceAddress = this.getWorkplaceAddress.bind(this);
		this.fetchCountries = this.fetchCountries.bind(this);
	}

	/**
	 * @function getWorkplace
	 * @memberOf WorkplaceAddress
	 * @description Getter for workplace
	 */
	getWorkplace(workplaceId) {
		return get(`/organization/workplaces/${workplaceId}`).then((response) => {
			return response.data[0];
		});
	}

	/**
	 * @function getWorkplaceAddress
	 * @memberOf WorkplaceAddress
	 * @description Getter for workplaceAddress
	 */
	getWorkplaceAddress(workplaceId) {
		const filter = `:workplace.id=='${workplaceId}'`;

		return get('/administration/workplace_addresses', { filter }).then((response) => {
			return response.data[0];
		});
	}

	/**
	 * @function fetchCountries
	 * @memberOf WorkplaceAddress
	 * @description Getter for countries
	 */
	fetchCountries(value = '') {
		const params = {
			filter: `:country.name=like='%${value}%'`,
			limit: 300,
		};

		return get('/administration/countries', params);
	}

	/**
	 * @function getPhrases
	 * @memberOf WorkplaceAddress
	 * @description Getter for phrases
	 */
	getPhrases() {
		return phrases;
	}

	/**
	 * @function addressController
	 * @memberOf WorkplaceAddress
	 * @description Formatter for sub-components for changing needed variables
	 */
	addressController(updatedData) {
		return {
			longitude: updatedData.longitude ? updatedData.longitude : '',
			latitude: updatedData.latitude ? updatedData.latitude : '',
		};
	}

	/**
	 * @function formatDataForSending
	 * @memberOf WorkplaceAddress
	 * @description Formatting the data for the collections
	 */
	formatDataForSending(data) {
		return {
			country: data.country.value,
			workplace: this.workplaceId,
			zip: data.zip,
			city: data.city,
			address: data.address,
			latitude: data.latitude,
			longitude: data.longitude,
		};
	}

	/**
	 * @function sendForm
	 * @memberOf WorkplaceAddress
	 * @description Calling the collection to update / create workplace address
	 */
	sendForm(data) {
		const phrases = this.getPhrases();

		const postData = this.formatDataForSending(data);

		const url = data.id ? `/administration/workplace_addresses/${data.id}` : '/administration/workplace_addresses';

		return post(url, postData)
			.then((result) => {
				result = result.data[0];

				if (result) {
					setFeedback(phrases.ADDRESS_UPDATED, 1);
				} else setFeedback(phrases.UNKNOWN_ERROR, 0);

				return result;
			})
			.catch((err) => setFeedback(err.message, 0));
	}
}

export default WorkplaceAddress;
