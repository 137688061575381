export const RESET_PRODUCT_ADDONS_INGREDIENTS =
	'SALES_CONFIGURATION/RESET_PRODUCT_ADDONS_INGREDIENTS';
export const SET_PRODUCT_ADDONS_INGREDIENTS =
	'SALES_CONFIGURATION/SET_PRODUCT_ADDONS_INGREDIENTS';
export const UPDATE_PRODUCT_ADDONS_INGREDIENTS =
	'SALES_CONFIGURATION/UPDATE_PRODUCT_ADDONS_INGREDIENTS';

/**
 * @param {object} payload
 */
export function setAddonIngredients(payload) {
	return {
		type: SET_PRODUCT_ADDONS_INGREDIENTS,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateAddonIngredients(payload) {
	return {
		type: UPDATE_PRODUCT_ADDONS_INGREDIENTS,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetAddonIngredients() {
	return {
		type: RESET_PRODUCT_ADDONS_INGREDIENTS
	};
}
