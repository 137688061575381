
import { SET_EDITED_VALUE, SET_EDITABLE_VALUES, RESET_STATE } from './trainings.actions';

const initialState = {
	editedValues: {},
	defaultValues: {}
};

export default (state = initialState, action) => {
	switch (action.type) {

	case SET_EDITED_VALUE:
		return {
			...state,
			editedValues: { ...state.editedValues, ...action.payload }
		};

	case SET_EDITABLE_VALUES:
		return {
			...state,
			defaultValues: action.payload
		};
	
	case RESET_STATE:
		return {
			...initialState
		};

	default:
		return state;
	}
};
