import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './productBundles.phrases';

/**
 * @function fetchProductBundles
 * @description Fetch all product bundles
 * @param {object} state
 */
export function fetchProductBundles(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get('/product/bundles', params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addProductBundle
 * @description add product bundle
 */
export function addProductBundle(data) {
	return post('/product/bundles', data)
		.then(res => {
			setFeedback(phrases.PRODUCT_BUNDLE_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editProductBundle
 * @description edit product bundle
 */
export function editProductBundle(data) {
	return post(`/product/bundles/${data.id}`, data)
		.then(res => {
			setFeedback(phrases.PRODUCT_BUNDLE_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function editProductBundles
 * @description edit product bundles
 */
export function editProductBundles(data) {
	return patch('/product/bundles', data)
		.then(res => {
			setFeedback(phrases.PRODUCT_BUNDLES_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function deleteProductBundle
 * @description delete product bundle
 */
export function deleteProductBundle(id) {
	return remove(`/product/bundles/${id}`)
		.then(res => {
			setFeedback(phrases.PRODUCT_BUNDLE_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
