'use strict';

import base64 from 'base-64';

class FileTypeResultSet {

	constructor(fileType) {
		// Set fileType
		this.fileType = fileType;
	}

	/////////////
	// Helpers //
	/////////////

	/**
	 * @function _compileResultSet
	 * @memberOf FileTypeResultSet
	 * @description
	 * Combines methods from fileType to
	 * @param {object} resultSet
	 * @private
	 */
	_compileResultSet(fileType) {
		return new Promise(resolve => {
			// Begin creation of new File
			let newFile;

			// Get file from fileType
			const file = fileType.getFile();

			// Create fileReader
			const fileReader = new FileReader();

			// Set onloadevent and trigger creation of newFile with data from fileType.getFile();
			fileReader.onloadend = event => {

				const encoded = base64.encode(event.target.result);

				// Generate new file object with data as Base64
				newFile = {
					filename: `${fileType.getFileName()}.${fileType.getFileExtension()}`,
					type: file.type,
					data: encoded,
					lastModified: file.lastModified,
					templateName: fileType.getName(),
					id: fileType.getId()
				};

				resolve(newFile);
			};

			// Read file and trigger onloadend
			fileReader.readAsBinaryString(file);
		});
	}

	/////////////
	// Setters //
	/////////////


	/////////////
	// Getters //
	/////////////

	/**
	 * @function getResults
	 * @memberOf FileTypeResultSet
	 * @description
	 * Gets results from resultSet
	 */
	getResults() {
		return this._compileResultSet(this.fileType);
	}

}

export default FileTypeResultSet;