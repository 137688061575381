import {
	SET_PERSON_DATA,
	SET_LOADER,
	PERSIST_ADDRESS_DATA,
	CREATE_ADDRESS_DATA,
	SET_CUSTOM_DATA,
	SET_PERSON_PERSONAL_INFORMATION,
	SET_SENIORITY,
	RESET_SENIORITY,
} from './juicerDetails.actions';

const initialState = {
	ui: {
		loading: true,
	},
	optimistic: {},
	data: {
		personData: {},
		personPersonalInformation: {},
		seniority: {},
	},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_LOADER:
			return { ...state, ui: { ...state.ui, loading: action.payload } };

		case SET_PERSON_DATA:
			return {
				...state,
				data: {
					...state.data,
					personData: {
						...state.data.personData,
						...action.payload,
					},
				},
			};

		case SET_PERSON_PERSONAL_INFORMATION:
			return {
				...state,
				data: {
					...state.data,
					personPersonalInformation: action.payload,
				},
			};

		case SET_CUSTOM_DATA: {
			const personData = action.payload.handle
				? {
						...state.data.personData,
						[action.payload.handle]: action.payload.data,
				  }
				: {
						...state.data.personData,
						...action.payload.data,
				  };

			return {
				...state,
				data: {
					...state.data,
					personData,
				},
			};
		}

		case CREATE_ADDRESS_DATA:
			return {
				...state,
				optimistic: {
					...state.data.optimistic,
					addressData: {
						...state.optimistic.addressData,
						[action.payload.handle]: action.payload.value,
					},
				},
			};

		case PERSIST_ADDRESS_DATA:
			return {
				...state,
				data: {
					...state.data,
					personData: {
						...state.data.personData,
						person_address: action.payload,
					},
				},
				optimistic: {
					addressData: {},
				},
			};

		case SET_SENIORITY:
			return {
				...state,
				data: {
					...state.data,
					seniority: action.payload,
				},
			};

		case RESET_SENIORITY:
			return {
				...state,
				data: {
					...state.data,
					seniority: initialState.data.seniority,
				},
			};

		default:
			return state;
	}
};
