'use strict';

import { ReportFactory as R } from 'report/factory';
import PosReportBaseColumnsService from './posReportBaseColumns.service';

const t = R.table();

const marketsView = t.table([
	t.column('Market', [
		t.content(d => `${R.data(d, 'market.name').toUpperCase()}`, 'truncated')
	], PosReportBaseColumnsService.getFirstColumnWidth()),
	...PosReportBaseColumnsService.generate()
]);

export default marketsView;