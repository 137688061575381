import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchAllHomeScreenRecommendations
 * @description Fetch all allergen translations
 */
export function fetchAllHomeScreenRecommendations(id, state) {
	const preFilter = `:tile_layout_time_slot.tile_layout=='${id}'`;
	const filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(`/pos/tile_layout_home_screen_recommendations`, params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchAllHomeScreenRecommendationTranslations
 * @description Fetch all allergen translations
 */
export function fetchAllHomeScreenRecommendationTranslations(id, state) {
	const preFilter = `:tile_layout_home_screen_recommendation.id=='${id}'`;
	const filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(`/pos/tile_layout_home_screen_recommendation_translations`, params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addHomeScreenRecommendationTranslations
 * @description add
 */
export function addHomeScreenRecommendationTranslations(data) {
	return post(`/pos/tile_layout_home_screen_recommendation_translations`, data)
		.then((res) => {
			setFeedback('Home screen recommendation translations added', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editHomeScreenRecommendationTranslations
 */
export function editHomeScreenRecommendationTranslations(data) {
	return post(`/pos/tile_layout_home_screen_recommendation_translations/${data.id}`, data)
		.then((res) => {
			setFeedback('Home screen recommendation translations edited', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteHomeScreenRecommendationTranslations
 */
export function deleteHomeScreenRecommendationTranslations(id) {
	return remove(`/pos/tile_layout_home_screen_recommendation_translations/${id}`)
		.then((res) => {
			setFeedback('Home screen recommendation translations deleted', 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
