/**
 * @function formatReplacements
 * @param {Array} replacements - array of selected replacements
 * @description formats selected replacements to format to match
 * {group_1: 1, group_2: 14, group_3: 10 }
 */
export function formatReplacements(replacements = []) {
	const selectedReplacements = replacements.reduce(
		(acc, currentValue, index) => {
			const entry = {
				[`group_${index + 1}`]: currentValue.value?.value?.id ?? null,
			};

			return {
				...acc,
				...entry,
			};
		},
		{}
	);

	// EDGE CASE
	// in case we are editing and a value originally comes with 5 replacements and we remove 3 of them
	// our replacements array will be of length 2 and therefor the values we removed won't be sent to the api
	// as null
	// solution: calculate the difference between 15 and length of selected replacements and fill it up with nulls
	const difference = 15 - replacements.length;

	const nullifiedReplacements = [...Array(difference).keys()].reduce(
		(acc, currentValue) => {
			const entry = {
				[`group_${currentValue + 1 + replacements.length}`]: null,
			};

			return {
				...acc,
				...entry,
			};
		},
		{}
	);

	return {
		...selectedReplacements,
		...nullifiedReplacements,
	};
}

/**
 * @function formatReplacementsToArray
 * @param {Object} selectedRow - row from rDW
 * @description because api returns replacements as group_1, group_2... we need to throw
 * them into an array that fits the way we format the data
 */
export function formatReplacementsToArray(selectedRow) {
	// remove all keys that are not groups 1 - 15
	const keys = Object.keys(selectedRow).filter(
		(entry) => entry.indexOf('group_') !== -1
	);

	return keys
		.map((entry, index) => {
			if (!selectedRow[entry]) return;

			return {
				name: `productVariant-${index}`,
				value: {
					label: selectedRow[entry].name,
					value: selectedRow[entry],
				},
			};
		})
		.filter((x) => x);
}
