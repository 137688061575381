'use strict';

import { post } from 'api.vanilla.service';
import * as Sentry from '@sentry/browser';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service';
				
import phrases from './employmentDocuments.phrases';

class EmploymentDocuments {
	constructor(id, fileList) {
		this.employmentId = id;
		this.fileList = fileList;

	}

	/// //////////
	// Getters //
	/// //////////

	/**
	 * @function getPhrases
	 * @memberOf EmploymentDocuments
	 * @description Getter for component phrases
	 */
	getPhrases() {
		return phrases;
	}

	/// ///////////
	// Handlers //
	/// ///////////

	/**
	 * @function handleFileUploadChange
	 * @memberOf EmploymentDocuments
	 * @description Handler for dealing with changes from file upload component
	 * @param {object} resultSet - file upload object.
	 * @param {number} employmentPositionId - employment position id to associate file with.
	 */
	handleFileUploadChange(resultSet, employmentPositionId, personId) {
		try {
			const results = resultSet.results || [];

			// Get array of promises - one entry for each file
			const filePromises = Object.keys(results).map((key) =>
				results[key]
					.getResults()
					.then((file) => this.uploadFile(file, employmentPositionId))
			);

			// Return and resolve/catch
			return Promise.all(filePromises).then(() =>
				this.fileList.handleUpdateFileList()
			);
		} catch (error) {
			Sentry.withScope((scope) => {
				scope.setExtra('error', error);
				Sentry.captureException(error);
				Sentry.captureMessage('Error in handleFileUploadChange', 'error');
			});
		}
	}

	/**
	 * @function handleGetEmploymentIdFilter
	 * @memberOf EmploymentDocuments
	 * @description Handler for getting filter based on employment id, needed for fileList data provider
	 */
	handleGetEmploymentIdFilter() {
		return `:employment.id=='${this.employmentId}'`;
	}

	/// ////////////
	// Utilities //
	/// ////////////

	/**
	 * @function uploadFile
	 * @memberOf EmploymentDocuments
	 * @description Handler for uploading employment document file to Workplanner API
	 * @param {object} file
	 * @param {number} employmentPositionId - associated emplyment position id.
	 */
	uploadFile(file, employmentPositionId, personId) {
		const data = {
			employment: this.employmentId,
			type: file.templateName,
			employment_position: employmentPositionId,
			person: personId,
			document: {
				filename: file.filename,
				data: file.data,
			},
		};

		return post('/hr/employment_documents', data).catch((error) => {
			const errorMessage = formatErrorMessage(error);
			setFeedback(errorMessage, 0);
		});
	}
}

export default EmploymentDocuments;
