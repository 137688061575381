import { PAGELOADER_TOGGLE } from './pageLoader.actions';

const defaultState = {
	loading: false,
	message: 'Welcome'
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case PAGELOADER_TOGGLE:
		return Object.assign({}, state, {
			loading: payload.loading,
			message: payload.message
		});

	default:
		return state;
	}
}

export default reducer;
