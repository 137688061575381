'use strict';
import _omitBy from 'lodash/omitBy';
import _findKey from 'lodash/findKey';
import _uniqBy from 'lodash/uniqBy';

import {
	ADD_PRODUCTS_VARIANT_TO_TILE,
	CLEAR_TILES,
	FETCHING,
	REMOVE_GROUP,
	REMOVE_PRODUCTS_VARIANT_FROM_TILE,
	REMOVE_TILE,
	SET_ACTIVE_TILE,
	SET_GRID_DATA,
	SET_GROUP_IN_FOCUS,
	SET_GROUPS,
	SET_IMAGE_ASSETS,
	SET_TILES_IN_GROUP,
	SET_VARIANTS_LIST,
	UPDATE_PRODUCTS_VARIANT_ON_TILE,
	UPDATE_POSITIONS_IN_APP,
	UPDATE_TILE_POSITION_IN_POS,
	SET_ACTIVE_TILE_TYPE,
	SET_FETCHING_TILE,
	SET_SHOW_ADD_TILE_MODAL,

	// Is the one below used?
	UPDATE_TILE_POSITION,
	UPDATE_TILE,
	CLEAN_UP,
	SET_FULL_VARIANTS_LIST,
	SET_LOADING
} from './posConfigurationLayout.actions';

const defaultState = {
	ui: {
		loading: false
	},
	activeTile: {},
	activeTileType: null,
	fetchingTile: false,
	showAddTileModal: false,
	grid: {},
	gridMetaData: {},
	gridSize: null,
	groupItemInFocus: null,
	groups: {},
	assets: [],
	posConfigurationsVariantsList: [],
	tilesInGroup: [],
	posFullConfigurationsVariantsList: []
};

function posReducer(state = defaultState, action) {
	let copy = null;
	const { type, payload } = action;
	switch (type) {
	case SET_FULL_VARIANTS_LIST:
		return { ...state, posFullConfigurationsVariantsList: payload };

	case SET_TILES_IN_GROUP:
		return {
			...state,
			tilesInGroup: _uniqBy(
				[...state.tilesInGroup, ...payload.tilesInGroup],
				'id'
			)
		};

	case SET_LOADING:
		return {
			...state,
			ui: { ...state.ui, loading: payload }
		};

	case UPDATE_TILE:
		return {
			...state,
			tilesInGroup: state.tilesInGroup.map(tile => {
				if (tile.id === payload.tile.id) return payload.tile;
				return tile;
			})
		};

	case SET_GROUP_IN_FOCUS:
		return {
			...state,
			groupItemInFocus: payload.groupItemInFocus
		};

	case SET_ACTIVE_TILE:
		return {
			...state,
			activeTile: payload.activeTile
		};

	case REMOVE_TILE: {
		copy = state.tilesInGroup.slice();

		// Remove from object where id === payload
		copy = _omitBy(copy, o => {
			return o.id === payload.tileId;
		});

		return {
			...state,
			tilesInGroup: state.tilesInGroup.filter(
				tile => tile.id !== payload.tileId
			)
		};
	}

	case CLEAR_TILES:
		return {
			...state,
			tilesInGroup: []
		};

	case SET_VARIANTS_LIST:
		return {
			...state,
			posConfigurationsVariantsList: payload.posConfigurationsVariantsList
		};

	case SET_GROUPS:
		return {
			...state,
			groups: {
				...state.groups,
				...payload.groups
			}
		};

	case REMOVE_GROUP:
		copy = Object.assign({}, state.groups);
		delete copy[payload.groupId];

		return {
			...state,
			groups: {
				...copy
			}
		};

	case UPDATE_TILE_POSITION_IN_POS: {
		const { dragIndex, hoverIndex } = payload;

		let tilesInGroupCopy = state.tilesInGroup.slice();

		const draggedItem = tilesInGroupCopy.find(
			tile => tile.position === dragIndex
		);

		const hoveredItem = tilesInGroupCopy.find(
			tile => tile.position === hoverIndex
		);

		tilesInGroupCopy = tilesInGroupCopy.map((tile, index) => {
			// if dragged tile is dropped over an empty position
			if (tile.id === draggedItem.id && tile.position !== hoverIndex)
				return {
					...draggedItem,
					position: hoverIndex
				};

			// If dragged item is dropped on a tile with existing product,
			// and the current tile in the map is the hovered one
			if (draggedItem && hoveredItem && hoveredItem.id === tile.id)
				return {
					...hoveredItem,
					position: dragIndex
				};

			// If dragged item is dropped on a tile with existing product,
			// and the current tile in the map is the dragged one
			if (draggedItem && hoveredItem && draggedItem.id === tile.id)
				return {
					...draggedItem,
					position: hoverIndex
				};
			return tile;
		});

		return Object.assign({}, state, {
			tilesInGroup: tilesInGroupCopy
		});
	}

	case UPDATE_POSITIONS_IN_APP: {
		const { dragIndex, hoverIndex } = payload;

		let tilesInGroupCopy = state.tilesInGroup.slice();

		const draggedItem = {
			...state.tilesInGroup[dragIndex],
			position: hoverIndex + 1
		};

		const hoveredItem = {
			...state.tilesInGroup[hoverIndex],
			position: dragIndex + 1
		};

		tilesInGroupCopy = tilesInGroupCopy.map((tile, index) => {
			if (index === hoverIndex) return hoveredItem;
			if (index === dragIndex) return draggedItem;
			return tile;
		});

		return Object.assign({}, state, {
			tilesInGroup: tilesInGroupCopy.map((tile, index) => {
				if (index === hoverIndex) return hoveredItem;
				if (index === dragIndex) return draggedItem;
				return tile;
			})
		});
	}

	case UPDATE_TILE_POSITION:
		return Object.assign({}, state, {
			tilesInGroup: payload.updatedTiles
		});

	case ADD_PRODUCTS_VARIANT_TO_TILE: {
		const tilesInGroup = state.tilesInGroup;
		const groupTileProductVariant = payload.groupTileProductVariant;
		const tileProductVariantOwner = groupTileProductVariant.tile_layout_tile;

		const tileProductOwner = _findKey(tilesInGroup, [
			'id',
			tileProductVariantOwner
		]);
		let posConfigurationLayoutTileProductTileVariants =
				tilesInGroup[tileProductOwner].tile_layout_tile_product_variants || [];
		posConfigurationLayoutTileProductTileVariants = _uniqBy(
			[
				...posConfigurationLayoutTileProductTileVariants,
				groupTileProductVariant
			],
			'id'
		);

		// When adding a new tile with a corresponding tileProductVariant, 'tile_layout_tile_product_variants' is null, where it should be an array with the corresponding variants
		return {
			...state,
			tilesInGroup: {
				...tilesInGroup,
				[tileProductOwner]: {
					...tilesInGroup[tileProductOwner],
					tile_layout_tile_product_variants: posConfigurationLayoutTileProductTileVariants
				}
			},
			activeTile: {
				...state.activeTile,
				tile_layout_tile_product_variants: posConfigurationLayoutTileProductTileVariants
			}
		};
	}

	case REMOVE_PRODUCTS_VARIANT_FROM_TILE:
		const { tileProductTileVariantId, tileId } = payload;

		const foundKey = _findKey(state.tilesInGroup, ['id', tileId]);

		if (!foundKey) return state;

		return {
			...state,
			tilesInGroup: state.tilesInGroup[foundKey]
				? {
					...state.tilesInGroup,
					[foundKey]: {
						...state.tilesInGroup[foundKey],
						tile_layout_tile_product_variants: state.tilesInGroup[
							foundKey
						].tile_layout_tile_product_variants.filter(
							tileProductTileVariant =>
								tileProductTileVariant.id !== tileProductTileVariantId
						)
					}
					  }
				: state.tilesInGroup,

			activeTile: {
				...state.activeTile,
				tile_layout_tile_product_variants: state.tilesInGroup[
					foundKey
				].tile_layout_tile_product_variants.filter(
					tileProductTileVariant =>
						tileProductTileVariant.id !== tileProductTileVariantId
				)
			}
		};

	case UPDATE_PRODUCTS_VARIANT_ON_TILE:
		const tileToUpdate = _findKey(state.tilesInGroup, ['id', payload.tileId]);

		return {
			...state,
			tilesInGroup: {
				...state.tilesInGroup,
				[tileToUpdate]: {
					...state.tilesInGroup[tileToUpdate],
					tile_layout_tile_product_variants: state.tilesInGroup[
						tileToUpdate
					].tile_layout_tile_product_variants.map(tileProductTileVariant =>
						tileProductTileVariant.id ===
							payload.tileLayoutTileProductVariant.id
							? {
								...tileProductTileVariant,
								...payload.tileLayoutTileProductVariant
								  }
							: tileProductTileVariant
					)
				}
			},
			activeTile: {
				...state.activeTile,
				tile_layout_tile_product_variants: state.tilesInGroup[
					tileToUpdate
				].tile_layout_tile_product_variants.map(tileProductTileVariant =>
					tileProductTileVariant.id ===
						payload.tileLayoutTileProductVariant.id
						? {
							...tileProductTileVariant,
							...payload.tileLayoutTileProductVariant
							  }
						: tileProductTileVariant
				)
			}
		};

	case SET_IMAGE_ASSETS:
		const assets = [...state.assets, ...payload];

		return {
			...state,
			assets: _uniqBy(assets, assets => assets.id)
		};

	case CLEAN_UP:
		return {
			...defaultState
		};

	case SET_GRID_DATA:
		// Payload in this case consists of grid, gridMetaData and gridSize
		// and prop.set dont seem to accept my data unless i use prop.merge.
		// But prop.merge is 10 times slower than object assign in this case
		return Object.assign({}, state, payload);

		// Fetching data. This should be called in collections
	case FETCHING:
		// return prop.set(state, 'fetching', payload);
		return {
			...state,
			fetching: payload
		};

	case SET_ACTIVE_TILE_TYPE:
		return {
			...state,
			activeTileType: payload
		};

	case SET_FETCHING_TILE:
		return {
			...state,
			fetchingTile: payload
		};

	case SET_SHOW_ADD_TILE_MODAL:
		return {
			...state,
			showAddTileModal: payload
		};

	default:
		return state;
	}
}

export default posReducer;
