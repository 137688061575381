'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import { InputCollectionSelect } from 'dumb';

import _get from 'lodash/get';

import {
	setIngredientAssociationWithProducts,
	updateIngredientAssociationWithProducts,
	resetState,
} from './store/ingredientAssociationWithProducts.actions';

import {
	fetchProductVariantIngredientVariations,
	deleteProductVariantIngredientVariations,
	addProductVariantIngredientVariations,
} from './ingredientAssociationWithProducts.service';

import phrases from './ingredientAssociationWithProducts.phrases';

class IngredientAssociationWithProducts extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.fetchData = this.fetchData.bind(this);

		this.columns = [
			{
				Header: 'Product Associated',
				id: 'productId',
				accessor: (d) =>
					_get(
						d,
						'pos_configuration_product_variant.product_variant.name',
						'n/a'
					),
				filterPath: ':pos_configuration_product_variant.product_variant.name',
			},
		];
	}

	deleteEntry(id) {
		return deleteProductVariantIngredientVariations(id);
	}

	addEntry() {
		const { ingredientAssociationWithProduct, ingredientVariant } = this.props;

		const payload = {
			pos_configuration_ingredient_variation: ingredientVariant.id,
			pos_configuration_product_variant: _get(
				ingredientAssociationWithProduct,
				'productVariant.value',
				null
			),
		};

		return addProductVariantIngredientVariations(payload);
	}

	setInitialEditValues(data) {
		this.props.setIngredientAssociationWithProducts(data);
	}

	getEditableCells() {
		const { ingredientAssociationWithProduct, ingredientVariant } = this.props;

		return [
			{
				header: 'Product Associated',
				value: (
					<InputCollectionSelect
						value={ingredientAssociationWithProduct.productVariant}
						handleChange={(key, value) =>
							this.editStoreEntry('productVariant', value)
						}
						clearable={false}
						cache
						apiPath="/pos/pos_configuration_product_variants"
						params={{
							limit: 300,
							filter: `:pos_configuration_version.id=='${_get(
								ingredientVariant,
								'pos_configuration_version.id',
								''
							)}'`,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.product_variant.name,
						})}
						inputFilterFormat={(input) =>
							`:product_variant.name=like='%${input}%'`
						}
					/>
				),
			},
		];
	}

	editStoreEntry(name, value) {
		this.props.updateIngredientAssociationWithProducts({
			[name]: value,
		});
	}

	fetchData(state) {
		const { ingredientVariant } = this.props;

		state = {
			...state,
			filter: state.filter
				? `${state.filter};:pos_configuration_ingredient_variation.id=='${ingredientVariant.id}'`
				: `:pos_configuration_ingredient_variation.id=='${ingredientVariant.id}'`,
		};

		return fetchProductVariantIngredientVariations(state);
	}

	render() {
		return (
			<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.INGREDIENT_PRODUCT_ASSOCIATION}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={5}
				reduxKey={`pos/product_variant_ingredient_variations/${this.props.ingredientVariant.id}`}
				manual
				editableCells={this.getEditableCells()}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetState}
				actionsWidth={30}
				deleteEntry={this.deleteEntry}
				createEntry={this.addEntry}
			/>
		);
	}
}

IngredientAssociationWithProducts.propTypes = {
	ingredientVariant: PropTypes.object,
	ingredientAssociationWithProduct: PropTypes.object,
	updateIngredientAssociationWithProducts: PropTypes.func,
	setIngredientAssociationWithProducts: PropTypes.func,
	resetState: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setIngredientAssociationWithProducts,
			updateIngredientAssociationWithProducts,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		ingredientAssociationWithProduct:
			store.salesConfigurationPosConfigurationIngredientAssociationWithProducts
				.data.ingredientAssociationWithProduct,
	};
};

export default connectWithStore(
	IngredientAssociationWithProducts,
	mapStateToProps,
	mapDispatchToProps
);
