import { get, post } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { store } from 'appState';
import moment from 'moment';

export const SET_EMPLOYMENTS = 'EMPLOYMENTS_CARD/SET_EMPLOYMENTS';
export const SET_EDITABLE_EMPLOYMENT =
	'EMPLOYMENTS_CARD/SET_EDITABLE_EMPLOYMENT';
export const SET_MODAL_VISIBLITY = 'EMPLOYMENTS_CARD/SET_MODAL_VISIBLITY';
export const EDIT_ROW_DATA = 'EMPLOYMENTS_CARD/EDIT_ROW_DATA';
export const SET_EDITED_EMPLOYMENT = 'EMPLOYMENTS_CARD/SET_EDITED_EMPLOYMENT';
export const ADD_NEW_EMPLOYMENT = 'EMPLOYMENTS_CARD/ADD_NEW_EMPLOYMENT';
export const TOGGLE_LOADING = 'EMPLOYMENTS_CARD/TOGGLE_LOADING';

export const fetchEmployments = (personId) => {
	const apiUrl = '/hr/employments';
	const filter = `(:person.id=='${personId}')`;
	const params = {
		filter,
	};
	store.dispatch(toggleLoader(true));

	return get(apiUrl, params)
		.then((employments) => {
			store.dispatch(setEmployments(employments.data.reverse()));
			store.dispatch(toggleLoader(false));
		})
		.catch((err) => {
			setFeedback(formatErrorMessage(err), 0);
			store.dispatch(toggleLoader(false));
		});
};

export const postEditedEmployment = (employmentId, payload) => {
	const apiUrl = `/hr/employments/${employmentId}`;

	return post(apiUrl, payload)
		.then((employment) => {
			store.dispatch(setEditedEmployment(employment.data[0]));
			store.dispatch(setModalVisibility(false));
			setFeedback('Employment Edited', 1);
		})
		.catch((err) => {
			setFeedback(formatErrorMessage(err), 0);
		});
};

export const postNewEmployment = (personId, payload) => {
	const apiUrl = `/hr/employments`;
	const fromattedPayload = {
		person: personId,
		started: moment(),
		ended: moment('9999-31-12'),
		...payload,
		company: payload.company.id,
	};

	post(apiUrl, fromattedPayload)
		.then((employment) => {
			setFeedback('Employment Created', 1);
			store.dispatch(addNewEmployment(employment.data[0]));
			store.dispatch(setModalVisibility(false));
		})
		.catch((err) => {
			setFeedback(formatErrorMessage(err), 0);
		});
};

export const setEmployments = (employments) => {
	return { type: SET_EMPLOYMENTS, employments };
};

export const setEditedEmployment = (employment) => {
	return { type: SET_EDITED_EMPLOYMENT, employment };
};

export const addNewEmployment = (employment) => {
	return { type: ADD_NEW_EMPLOYMENT, employment };
};

export const setModalVisibility = (payload, isEditMode = false) => {
	return { type: SET_MODAL_VISIBLITY, payload, isEditMode };
};

export const setEditableEmployment = (employment) => {
	return { type: SET_EDITABLE_EMPLOYMENT, employment };
};

export const toggleLoader = (payload) => {
	return { type: TOGGLE_LOADING, payload };
};

export const editRowData = (payload) => {
	return { type: EDIT_ROW_DATA, payload };
};
