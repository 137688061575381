import React from 'react';
import PropTypes from 'prop-types';

// components
import { Text, Tag } from 'dumb';

// utils
import { getHumanReadableState } from 'services/shiftTransfer';

// enums
import enums from './../shiftTransferApproval.enums';
import { ShiftTransfer as ShiftTransferEnums } from 'services/enums';

const ShiftTransferStatus = ({ state, ownTransfer }) => {
	let tagColor = enums.COLORS.DEFAULT;
	if (state === ShiftTransferEnums.APPLICATIONS.AWAITING_MANAGER_APPROVAL) {
		tagColor = enums.COLORS.POSITIVE;
	}
	if (state === ShiftTransferEnums.APPLICATIONS.DECLINED)
		tagColor = enums.COLORS.NEGATIVE;

	const stateToShow = getHumanReadableState(state);

	return (
		<div className="shift-transfer-approval-modal__table-wrapper__row-cell">
			<Tag color={tagColor} noMargin>
				<Text size="tiny" bold noWrap>
					{stateToShow}
				</Text>
			</Tag>
		</div>
	);
};

ShiftTransferStatus.propTypes = {
	state: PropTypes.string,
	ownTransfer: PropTypes.bool,
};

export default ShiftTransferStatus;
