import React, { useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setValue, updateValue, resetState } from './store/shiftClockinCorrectionOptions.actions';

// components
import { Input, Button, Icon } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';
import getColumns from 'reactDataWrapperColumns/shiftplanning/shiftClockinCorrectionOptions.columns';
import ShiftClockinCorrectionOptionsModalBatch from './components/batch/shiftClockinCorrectionOptionsModalBatch';

// services
import {
	fetchShiftClockinCorrectionOptions,
	editShiftClockinCorrectionOption,
	deleteShiftClockinCorrectionOption,
} from './shiftClockinCorrectionOptions.service';
import getEditedValues from 'services/utils/getEditedValues';

// utils/ phrases
import moment from 'moment';
import phrases from './shiftClockinCorrectionOptions.phrases';

const ShiftClockinCorrectionOptions = () => {
	const [tableKey, setTableKey] = useState(moment().unix());
	const [batchModalOpen, setBatchModalOpen] = useState(false);
	const dispatch = useDispatch();
	const { value, originalValue } = useSelector((store) => store.shiftClockinCorrectionOptions);

	const editEntry = () => {
		const editedValues = getEditedValues({
			newData: value,
			oldData: originalValue,
		});

		const body = {
			id: value.id,
			comment: editedValues.comment ?? undefined,
		};

		return editShiftClockinCorrectionOption(body);
	};
	const deleteEntry = (id) => {
		return deleteShiftClockinCorrectionOption(id);
	};
	const setInitialEditValues = (row) => {
		const payload = {
			id: row.id,
			comment: row.comment,
			editMode: true,
		};

		dispatch(setValue(payload));
	};
	const onResetState = () => dispatch(resetState());
	const onChange = (value, name) => {
		const payload = {
			[name]: value,
		};

		dispatch(updateValue(payload));
	};
	const getEditableCells = () => [
		{
			header: phrases.COMMENT,
			value: (
				<Input
					id="name"
					required
					placeholder={phrases.COMMENT_PLACEHOLDER}
					value={value.comment}
					onChange={(e) => onChange(e.target.value, 'comment')}
				/>
			),
		},
	];
	const toggleBatchModal = () => {
		if (batchModalOpen) onResetState();

		setBatchModalOpen(!batchModalOpen);
	};
	const getActionRender = () => {
		return (
			<Button type="inverted" label={phrases.BATCH_ADD} shadow onClick={toggleBatchModal}>
				<Icon name="add" />
			</Button>
		);
	};
	const onSubmit = () => {
		setTableKey(moment.unix());
		toggleBatchModal();
	};

	return (
		<>
			<ReactDataWrapper
				key={tableKey}
				title={phrases.TITLE}
				accessAreasAllowedToEdit={['Global HR Configuration', 'Shiftplan Admin']}
				columns={getColumns()}
				fetchData={fetchShiftClockinCorrectionOptions}
				filterable
				defaultPageSize={10}
				reduxKey="/shiftplanning/shift_clocking_correction_options"
				manual
				editEntry={editEntry}
				deleteEntry={deleteEntry}
				editableCells={getEditableCells()}
				setInitialEditValues={setInitialEditValues}
				onModalClose={onResetState}
				actionRender={getActionRender()}
			/>

			{batchModalOpen && <ShiftClockinCorrectionOptionsModalBatch onClose={toggleBatchModal} onSubmit={onSubmit} />}
		</>
	);
};

export default ShiftClockinCorrectionOptions;
