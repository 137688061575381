'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, Icon } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// phrases
import phrases from './../../../salesConfigurationPosConfigurationDiscountProductVariant.phrases';

class SalesConfigurationPosConfigurationDiscountProductVariantBatchListEdit extends Component {
	constructor(props) {
		super(props);

		this.noPosConfigurationProductVariantBulletColor = 'ff7476';
		this.posConfigurationDiscountProductVariantExistsBulletColor = 'ffe699';
	}

	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e.target ? e.target.value : e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: value },
		});
	}

	render() {
		const { batchListData } = this.props;

		return (
			<TableList>
				<thead>
					<tr>
						<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
						<th>{phrases.MODAL_BATCH_STEP_LIST_POS_CONFIGURATION_NAME}</th>
						<th>
							{phrases.MODAL_BATCH_STEP_LIST_POS_CONFIGURATION_VERSION_NAME}
						</th>
						<th>{phrases.MODAL_BATCH_STEP_LIST_PRODUCT_VARIANT}</th>
						<th>{phrases.MODAL_BATCH_STEP_LIST_POS_CONFIGURATION_DISCOUNT}</th>
						<th>
							{
								phrases.MODAL_BATCH_STEP_LIST_POS_CONFIGURATION_PRODUCT_VARIANT_TO_STAY_PRICE
							}
						</th>
						<th>
							{
								phrases.MODAL_BATCH_STEP_LIST_POS_CONFIGURATION_PRODUCT_VARIANT_TO_GO_PRICE
							}
						</th>

						<th>{phrases.MODAL_BATCH_STEP_LIST_TO_STAY_DISCOUNT}</th>

						<th>{phrases.MODAL_BATCH_STEP_LIST_TO_STAY_DISCOUNT_NEW}</th>

						<th>{phrases.MODAL_BATCH_STEP_LIST_TO_GO_DISCOUNT}</th>

						<th>{phrases.MODAL_BATCH_STEP_LIST_TO_GO_DISCOUNT_NEW}</th>

						<th />
					</tr>
				</thead>
				<tbody>
					{batchListData.map((entry, i) => {
						return (
							<TableListRow key={entry.identifier}>
								<TableListCell>{i + 1}</TableListCell>
								<TableListCell>{entry.posConfigurationName}</TableListCell>
								<TableListCell>
									{entry.posConfigurationVersionName}
								</TableListCell>
								<TableListCell>{entry.productVariantName}</TableListCell>
								<TableListCell>
									{entry.posConfigurationDiscountName}
								</TableListCell>
								<TableListCell>{entry.toStayPrice}</TableListCell>
								<TableListCell>{entry.toGoPrice}</TableListCell>

								<TableListCell>
									<Input
										id={`${entry.identifier}-toStayDiscount`}
										type="number"
										value={entry.toStayDiscount}
										disabled
									/>
								</TableListCell>

								<TableListCell>
									<Input
										id={`${entry.identifier}-toStayDiscountNew`}
										type="number"
										value={entry.toStayDiscountNew}
										placeholder="New to stay discount"
										onChange={(event) =>
											this._editListEntry(event, entry, 'toStayDiscountNew')
										}
										required
									/>
								</TableListCell>

								<TableListCell>
									<Input
										id={`${entry.identifier}-toGoDiscount`}
										type="number"
										value={entry.toGoDiscount}
										disabled
									/>
								</TableListCell>

								<TableListCell>
									<Input
										id={`${entry.identifier}-toGoDiscountNew`}
										type="number"
										value={entry.toGoDiscountNew}
										placeholder="New to go discount"
										onChange={(event) =>
											this._editListEntry(event, entry, 'toGoDiscountNew')
										}
										required
									/>
								</TableListCell>

								<TableListCell>
									<Button
										type="negative"
										size="tiny"
										onClick={() => this._onRemoveEntry(entry.identifier)}>
										<Icon name="delete" />
									</Button>
								</TableListCell>
							</TableListRow>
						);
					})}
				</tbody>
			</TableList>
		);
	}
}

SalesConfigurationPosConfigurationDiscountProductVariantBatchListEdit.propTypes = {
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

SalesConfigurationPosConfigurationDiscountProductVariantBatchListEdit.defaultProps = {};

export default SalesConfigurationPosConfigurationDiscountProductVariantBatchListEdit;
