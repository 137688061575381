export default {
	RECEIVED: 'Received',
	SETTLED: 'Settled',
	OUTSTANDING: 'Outstanding',

	D2B: 'D2B',
	D2B_FOREIGN: 'D2B Foreign',
	D2B_LOCAL: 'D2B Local',
	CR: 'Concessions Receivable',
	AUDIT_DESCRIPTION: 'Audit via Drawer To Bank',
	FINANCIAL: 'Financial'
};
