export default {
	TABLE_TITLE: 'Workplace waiting time grids',
	WORKPLACE_WAITING_TIME_GRID_ADDED: 'Workplace waiting time grids added',
	WORKPLACE_WAITING_TIME_GRID_EDITED: 'Workplace waiting time grid edited',
	WORKPLACE_WAITING_TIME_GRIDS_EDITED: 'Workplace waiting time grids edited',
	WORKPLACE_WAITING_TIME_GRIDS_DELETED: 'Workplace waiting time grid deleted',
	MULTI_EDIT_TOOLTIP: 'Overwrite existing POS configuration versions',

	// batch
	MODAL_BATCH_STEP_FORM_TITLE: 'Select entries',
	MODAL_BATCH_STEP_WORKPLACE: 'Workplace',
	MODAL_BATCH_STEP_WORKPLACE_PLACEHOLDER: 'Select workplace...',
	MODAL_BATCH_TABLE_TITLE: 'Workplaces',
	MODAL_BATCH_FROM: 'From',
	MODAL_BATCH_TO: 'To',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'Next',
	MODAL_BATCH_STEP_LIST_TITLE: 'Confirm entries',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Submit',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'Back',
	MODAL_BATCH_STEP_FORM_TABLE_FILTER: 'Operating',
};
