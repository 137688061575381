import React, { ChangeEvent } from 'react';

// interfaces
import { IDynamicAccountReduxValueBatch } from './../../../types';
import { SelectOption } from 'types/select';
import { EntryTypeEnum, TypeEnum } from 'types/enums';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import {
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,
} from './../../../store/dynamicAccounts.actions';

// components
import { InputCollectionSelect, Input } from 'dumb';
import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';
import { Box, IconButton, Tooltip as MuiTooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

// utils
import {
	getTypeSelectOptions,
	getEntryTypeSelectOptions,
} from '../../../dynamicAccounts.utils';

// phrases
import phrases from './../../../dynamicAccounts.phrases';

const DynamicAccountsModalStep = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const batchList = useAppSelector((store) => store.dynamicAccounts.batchList);

	const handleUpdateEntry = (
		row: IDynamicAccountReduxValueBatch,
		type: keyof IDynamicAccountReduxValueBatch,
		value: string | null | SelectOption
	) => {
		const payload: Partial<IDynamicAccountReduxValueBatch> = {
			identifier: row.identifier,
			[type]: value,
		};

		dispatch(editBatchListItem(payload));
	};
	const handleDeleteRow = (identifier: string) => () =>
		dispatch(removeBatchListItem(identifier));
	const handleAddRow = () => dispatch(addBatchListItemRow());

	return (
		<>
			<TableList>
				<thead>
					<tr>
						<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
						<th>{phrases.ACCOUNT_NUMBER}</th>
						<th>{phrases.TYPE}</th>
						<th>{phrases.ENTRY_TYPE}</th>

						<th />
					</tr>
				</thead>
				<tbody>
					{batchList.map((entry, i) => {
						const deleteRowButtonDisabled = batchList.length === 1;

						return (
							<TableListRow key={entry.identifier}>
								<TableListCell>{i + 1}</TableListCell>

								<TableListCell>
									<Input
										id="account-number"
										placeholder="Enter account number..."
										value={entry.accountNumber}
										onChange={(event: ChangeEvent<HTMLInputElement>) =>
											handleUpdateEntry(
												entry,
												'accountNumber',
												event.target.value
											)
										}
										type="number"
										required
									/>
								</TableListCell>

								<TableListCell>
									<InputCollectionSelect
										id="type"
										placeholder="Select type..."
										value={entry.type}
										handleChange={(_: string, value: SelectOption<TypeEnum>) =>
											handleUpdateEntry(entry, 'type', value)
										}
										clearable={false}
										options={getTypeSelectOptions()}
										required
									/>
								</TableListCell>

								<TableListCell>
									<InputCollectionSelect
										id="entry-type"
										placeholder="Select entry type..."
										value={entry.entryType}
										handleChange={(
											_: string,
											value: SelectOption<EntryTypeEnum>
										) => handleUpdateEntry(entry, 'entryType', value)}
										clearable={false}
										options={getEntryTypeSelectOptions()}
										required
									/>
								</TableListCell>

								<TableListCell>
									<IconButton
										onClick={handleDeleteRow(entry.identifier)}
										disabled={deleteRowButtonDisabled}
									>
										<DeleteIcon
											sx={{
												color: (theme) => theme.palette.warning.main,
											}}
										/>
									</IconButton>
								</TableListCell>
							</TableListRow>
						);
					})}
				</tbody>
			</TableList>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					mt: 2,
				}}
			>
				<MuiTooltip title={phrases.ADD_ROW_TOOLTIP}>
					<IconButton onClick={handleAddRow}>
						<AddIcon />
					</IconButton>
				</MuiTooltip>
			</Box>
		</>
	);
};

export default DynamicAccountsModalStep;
