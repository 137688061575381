import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ListComponent from 'common/components/list/list.component';
import DetailsComponent from 'common/components/details/details.component';

import CountriesDetails from './services/countriesDetails.service';
import CountryToCurrencyList from './services/countryCountryToCurrencyList.service';
import CountryMarketsList from './services/countryMarketsList.service';

const countriesDetailsReduxKey = '/administration/countries-details';
const countriesDetails = new CountriesDetails(countriesDetailsReduxKey);
const countriesDetailsProps = countriesDetails.getProps();

const countryToCurrencyListReduxKey = '/administration/country_to_currency_relations';
const countryToCurrencyList = new CountryToCurrencyList(countryToCurrencyListReduxKey);
const countryToCurrencyListProps = countryToCurrencyList.getProps();

const countryMarketsListReduxKey = '/administration/markets';
const countryMarketsList = new CountryMarketsList(countryMarketsListReduxKey);
const countryMarketsListProps = countryMarketsList.getProps();

export function CountriesDetailsContainer() {
	const { countryId } = useParams();

	const countryIdFilter = `:country.id=='${countryId}'`;
	countriesDetails.setItemId(countryId);

	useEffect(() => {
		// Fetch selected employment position
		countriesDetails.initView();

		countryMarketsList.setFilterQuery(countryIdFilter);
		countryMarketsList.setData({
			moneyball_position: countryId,
		});

		countryToCurrencyList.setFilterQuery(countryIdFilter);
		countryToCurrencyList.setData({
			moneyball_position: countryId,
		});
		countryToCurrencyList.initView();
	}, [countryId, countryIdFilter]);

	return (
		<>
			<DetailsComponent reduxKey={countriesDetailsReduxKey} {...countriesDetailsProps} />

			<ListComponent reduxKey={countryToCurrencyListReduxKey} {...countryToCurrencyListProps} />
			<ListComponent reduxKey={countryMarketsListReduxKey} {...countryMarketsListProps} />
		</>
	);
}
