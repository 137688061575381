'use strict';

export const SET_AUTHENTICATION_AREAS =
	'ACCESS_CONTROL_ADMINISTRATION/SET_AUTHENTICATION_AREAS';
export const SET_AUTHENTICATION_ROLES =
	'ACCESS_CONTROL_ADMINISTRATION/SET_AUTHENTICATION_ROLES';
export const SET_ROLE_AREAS = 'ACCESS_CONTROL_ADMINISTRATION/SET_ROLE_AREAS';
export const ADD_ROLE_AREA = 'ACCESS_CONTROL_ADMINISTRATION/ADD_ROLE_AREA';
export const REMOVE_ROLE_AREA =
	'ACCESS_CONTROL_ADMINISTRATION/REMOVE_ROLE_AREA';
export const SET_SEARCH_KEYWORD =
	'ACCESS_CONTROL_ADMINISTRATION/SET_SEARCH_KEYWORD';

export const EDIT_AUTHENTICATION_ROLE_MODAL_DATA =
	'ACCESS_CONTROL_ADMINISTRATION/EDIT_AUTHENTICATION_ROLE_MODAL_DATA';
export const CLEAR_AUTHENTICATION_ROLE_MODAL_DATA =
	'ACCESS_CONTROL_ADMINISTRATION/CLEAR_AUTHENTICATION_ROLE_MODAL_DATA';
export const DELETE_AUTHENTICATION_ROLE =
	'ACCESS_CONTROL_ADMINISTRATION/DELETE_AUTHENTICATION_ROLE';
export const SET_AUTHENTICATION_ROLE_MODAL_DATA =
	'ACCESS_CONTROL_ADMINISTRATION/SET_AUTHENTICATION_ROLE_MODAL_DATA';

export const EDIT_AUTHENTICATION_ROLE =
	'ACCESS_CONTROL_ADMINISTRATION/EDIT_AUTHENTICATION_ROLE';
export const RESET_DATA = 'ACCESS_CONTROL_ADMINISTRATION/RESET_DATA';

// user lookup modal
export const RESET_USER = 'ACCESS_CONTROL_ADMINISTRATION/RESET_USER';
export const SET_USER = 'ACCESS_CONTROL_ADMINISTRATION/SET_USER';
export const SET_USER_DATA = 'ACCESS_CONTROL_ADMINISTRATION/SET_USER_DATA';

export function setAuthenticationAreas(payload) {
	return {
		type: SET_AUTHENTICATION_AREAS,
		payload,
	};
}

export function setAuthenticationRoles(payload) {
	return {
		type: SET_AUTHENTICATION_ROLES,
		payload,
	};
}

export function setRoleAreas(payload) {
	return {
		type: SET_ROLE_AREAS,
		payload,
	};
}

export function addRoleArea(payload) {
	return {
		type: ADD_ROLE_AREA,
		payload,
	};
}

export function removeRoleArea(payload) {
	return {
		type: REMOVE_ROLE_AREA,
		payload,
	};
}

export function setSearchKeyword(payload) {
	return {
		type: SET_SEARCH_KEYWORD,
		payload,
	};
}

export function deleteAuthenticationRole(payload) {
	return {
		type: DELETE_AUTHENTICATION_ROLE,
		payload,
	};
}

export function editAuthenticationRole(payload) {
	return {
		type: EDIT_AUTHENTICATION_ROLE,
		payload,
	};
}

export function editAuthenticationRoleModalData(payload) {
	return {
		type: EDIT_AUTHENTICATION_ROLE_MODAL_DATA,
		payload,
	};
}

export function clearAuthenticationRoleModalData() {
	return {
		type: CLEAR_AUTHENTICATION_ROLE_MODAL_DATA,
	};
}

export function setAuthenticationRoleModalData(payload) {
	return {
		type: SET_AUTHENTICATION_ROLE_MODAL_DATA,
		payload,
	};
}

export function resetData(payload) {
	return {
		type: RESET_DATA,
		payload,
	};
}

// user modal actions
export function resetUserData() {
	return {
		type: RESET_USER,
	};
}

export function setUser(payload) {
	return {
		type: SET_USER,
		payload,
	};
}

export function setUserData(payload) {
	return {
		type: SET_USER_DATA,
		payload,
	};
}
