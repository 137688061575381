import { createTheme } from '@mui/material/styles';

// Pallete
let theme = createTheme({
	palette: {
		primary: {
			light: '#fff',
			// light: 'rgba(248, 224, 234, 1)',
			main: 'rgba(247, 193, 217, 1)',
			// dark: 'rgba(183, 145, 163, 1)',
			contrastText: '#24272a',
		},
		secondary: {
			light: 'rgba(79, 82, 85, 1)', //    #4f5255
			main: 'rgba(62, 64, 67, 1)', // #3e4043
			dark: 'rgba(36, 39, 42, 1)', // #24272a
			contrastText: '#fff',
		},
		background: {
			default: '#fff',
			drawer: 'rgba(36, 39, 42, 1)',
			white: '#fff',
			input: '#eee',
		},
		error: {
			light: '#e57373',
			main: 'rgba(238, 126, 103, 1)',
			dark: '#d32f2f',
			contrastText: '#fff',
		},
		success: {
			main: '#7cb342',
		},
		text: {
			primary: 'rgba(18, 18, 18, 1)',
			secondary: 'rgba(62, 64, 67, 1)',
			disabled: 'rgba(0, 0, 0, 0.38)',
			white: '#fff',
		},
		customColours: {
			navbarDevelopMain: 'rgba(183, 145, 163, 1)',
			navbarDevelopSecondary: 'rgba(255, 255, 255, 0.2)',
		},
	},
});

// Typography
theme = createTheme(theme, {
	typography: {
		fontSize: 16,
		htmlFontSize: 16,

		// fontFamily: [
		// 	'Roboto Condensed',
		// 	'Roboto',
		// 	'Helvetica Neue',
		// 	'sans-serif',
		// ].join(','),

		fontFamily: '"Roboto Condensed", "Roboto", "Helvetica Neue", sans-serif',

		fontFamilyHeader: [
			'Oswald',
			'Roboto Condensed',
			'Arial',
			'Helvetica',
			'sans-serif',
		].join(','),
	},
});

theme = createTheme(theme, {
	typography: {
		allVariants: {
			fontFamily: theme.typography.fontFamily,
		},

		// Main
		body1: {
			fontSize: 14,
		},

		// Secondary
		body2: {
			fontSize: 12,
		},

		h1: {
			fontFamily: theme.typography.fontFamilyHeader,
			fontSize: 30,
			fontWeight: 400,
			textTransform: 'uppercase',
			letterSpacing: '1.4875px',

			[theme.breakpoints.up('sm')]: {
				fontSize: 28,
			},
		},

		h4: {
			fontFamily: theme.typography.fontFamilyHeader,
			fontSize: 24,
			textTransform: 'uppercase',
			letterSpacing: '0.5px',

			[theme.breakpoints.up('sm')]: {
				fontSize: 28,
			},
		},

		// Tile header styling
		h5: {
			fontFamily: theme.typography.fontFamily,
			fontSize: 16,
			textTransform: 'uppercase',

			[theme.breakpoints.up('sm')]: {
				fontSize: 16,
			},
		},

		// Tile header styling
		h6: {
			fontFamily: theme.typography.fontFamily,
			fontSize: 14,
			fontWeight: 600,
			textTransform: 'uppercase',

			[theme.breakpoints.up('sm')]: {
				fontSize: 15,
			},
		},

		// This is for the WARNING MESSAGES ON modal, boards, and anywhere we need
		caption: {
			fontSize: 16,

			[theme.breakpoints.up('md')]: {
				fontSize: 16,
			},
		},

		overline: {
			fontSize: 12,
			lineHeight: 1,
			textTransform: 'none',

			[theme.breakpoints.up('md')]: {
				fontSize: 13,
			},
		},
	},

	components: {
		MuiAppBar: {
			styleOverrides: {
				root: {
					backgroundColor: theme.palette.background.white,
					boxShadow: 'none',
				},
			},
		},

		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: 'bold',
					borderRadius: 0,
				},
			},
			defaultProps: {
				variant: 'contained',
				color: 'secondary',
				disableElevation: true,
			},

			variants: [
				{
					props: { size: 'xsmall' },
					style: {
						padding: 0,
						fontSize: 12,
					},
				},
			],
		},

		MuiTextField: {
			defaultProps: {
				variant: 'outlined',
				placeholder: 'Enter...',
			},

			styleOverrides: {
				root: {
					'& .MuiOutlinedInput-root': {
						fontFamily: theme.typography.fontFamily,

						'&:hover .MuiOutlinedInput-notchedOutline': {
							borderColor: theme.palette.primary.main,
						},
					},

					'& .MuiOutlinedInput-notchedOutline': {
						borderWidth: '2px !important',
					},

					'& .MuiOutlinedInput-input': {
						padding: '16px 12px',
						fontFamily: theme.typography.fontFamily,
					},
				},
			},
		},

		MuiTab: {
			styleOverrides: {
				root: {
					color: 'rgba(18, 18, 18, 1)',
				},
			},
		},
		MuiSkeleton: {
			defaultProps: {
				animation: 'wave',
			},
		},
		MuiBadge: {
			defaultProps: {
				color: 'primary',
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				// https://github.com/mui-org/material-ui/issues/29842
				root: {
					'&.Mui-selected': {
						backgroundColor: 'rgba(247, 193, 217, 1) !important',
					},
				},
			},
		},

		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: 12,
				},
			},
		},
		MuiModal: {
			styleOverrides: {
				root: {
					zIndex: 470, // same z-index as our dumb modal
				},
			},
		},
	},

	shape: {
		borderRadius: 0,
	},

	shadows: [
		'none',
		'rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px', // 0
		...theme.shadows,
	],

	zIndex: {
		drawer: 250,
	},

	settings: {
		drawerWidth: 300,
	},
});

console.log(theme);

export default theme;
