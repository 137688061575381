'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import SubTable from 'reactDataWrapper/components/subTable';

import { Input, Button, Toggle } from 'dumb';
import IngredientsVariationsOptions from './components/ingredientVariationOptions/ingredientVariationOptions.component';

import {
	setIngredientVariations,
	updateIngredientVariation,
	resetState,
} from './store/ingredientVariations.actions';

import {
	fetchIngredientVariations,
	editIngredientVariation,
	addIngredientVariation,
	deleteIngredientVariation,
} from './store/ingredientVariations.service';

import _get from 'lodash/get';
import phrases from './ingredientVariations.phrases';

// Wrapper for react-data
// Documentation can be found here https://react-table.js.org/
class IngredientsVariations extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);

		this.state = {
			pages: null,
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this.fetchData = this.fetchData.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', ''),
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'desc',
				accessor: (d) => _get(d, 'description', ''),
				filterPath: ':description',
			},
			{
				Header: 'Sort order',
				id: 'sortord',
				accessor: (d) => _get(d, 'sort_order', ''),
				filterPath: ':sort_order',
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: 'legacy',
				filterPath: ':legacy',
				filterable: false,
				Cell: (d) => {
					return <Input type="checkbox" checked={d.original.legacy} disabled />;
				},
			},
		];
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}>
				Legacy
			</Button>
		);
	}

	deleteEntry(id) {
		return deleteIngredientVariation(id);
	}

	editEntry() {
		const { defaultValues } = this.props;

		const payload = {
			name: _get(defaultValues, 'name', ''),
			sort_order: _get(defaultValues, 'sort_order', null),
			description: _get(defaultValues, 'description', ''),
			legacy: !!defaultValues.legacy,
		};

		return editIngredientVariation(defaultValues.id, payload);
	}

	addEntry() {
		const { defaultValues } = this.props;

		const payload = {
			name: _get(defaultValues, 'name', ''),
			sort_order: _get(defaultValues, 'sort_order', null),
			description: _get(defaultValues, 'description', ''),
		};

		return addIngredientVariation(payload);
	}

	setInitialEditValues(data) {
		this.props.setIngredientVariations(data);
	}

	getEditableCells(e) {
		const { defaultValues } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="name"
						value={_get(defaultValues, 'name', '')}
						onChange={(event) => this.editStoreEntry(event, 'name')}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="description"
						value={_get(defaultValues, 'description', '')}
						onChange={(event) => this.editStoreEntry(event, 'description')}
					/>
				),
			},
			{
				header: 'Sort Order',
				value: (
					<Input
						id="sortOrder"
						placeholder="Sort Order"
						value={_get(defaultValues, 'sort_order', null)}
						onChange={(event) => this.editStoreEntry(event, 'sort_order')}
					/>
				),
			},
			{
				header: 'Legacy',
				value: (
					<Toggle
						id="legacy"
						toggled={defaultValues.legacy}
						onClick={(event) => this.editStoreEntry(event, 'legacy')}
					/>
				),
			},
		];
	}

	editStoreEntry(e, name) {
		const value = e.target ? e.target.value : e;

		const updateObject = {
			[name]: value,
		};

		this.props.updateIngredientVariation(updateObject);
	}

	fetchData(state) {
		return fetchIngredientVariations(state);
	}

	render() {
		const legacyFilter = this.state.legacyFilterOn
			? this.state.legacyFilter
			: '';

		return (
			<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.INGREDIENT_VARIATIONS}
				className="-striped -highlight"
				columns={this.columns}
				totalEntries={this.state.totalEntries} // Display the total number of pages
				fetchData={this.fetchData} // Request new data when things change
				filterable
				defaultPageSize={10}
				reduxKey="product/ingredient_variations"
				manual // Forces table not to paginate or sort automatically, so we can handle it server-side
				subcomponent={(row) => (
					<SubTable>
						<IngredientsVariationsOptions ingredientVariant={row.original} />
					</SubTable>
				)}
				editEntry={(e) => this.editEntry(e)}
				editableCells={this.getEditableCells()}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetState}
				actionsWidth={60}
				deleteEntry={this.deleteEntry}
				createEntry={this.addEntry}
				customAreaComponents={this.getLegacyFilterButton()}
				extraFilters={legacyFilter}
			/>
		);
	}
}

IngredientsVariations.propTypes = {
	defaultValues: PropTypes.object,

	updateIngredientVariation: PropTypes.func,
	setIngredientVariations: PropTypes.func,
	resetState: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setIngredientVariations,
			updateIngredientVariation,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultValues: store.ingredientVariations.data.ingredientVariation,
	};
};

export default connectWithStore(
	IngredientsVariations,
	mapStateToProps,
	mapDispatchToProps
);
