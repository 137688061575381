'use strict';

import _isEmpty from 'lodash/isEmpty';
import _findIndex from 'lodash/findIndex';
import _each from 'lodash/each';
import _has from 'lodash/has';
import _isNull from 'lodash/isNull';
import _isObject from 'lodash/isObject';
import _omit from 'lodash/omit';
import _last from 'lodash/last';
import moment from 'moment';
/**
 * @function filterBarSearch
 * @memberOf SERVICES.__models
 * @description
 * Filters collection from API endpoint based on filter bar
 * @param  {object} header  header to add/remove filter to/from
 * @param  {string/object} filter  the filter query
 * @param  {object} operator  the operator to use
 * @param  {string} filterId  unique filer id
 */

// Services
var __apiFilter = require('apiFilter.service');

module.exports = function (header, filter, operator, filterId, sortType) {
	return new Promise((resolve, reject) => {
		// if param filter is not empty, isFiltering is true
		var isFiltering = !_isEmpty(filter);

		// set filterObj (re-use filterId)
		var filterObj = {
			filterId: filterId,
			active: isFiltering,
			filter: isFiltering ? filter : undefined,
			operator: isFiltering ? operator : undefined,
			sortType: isFiltering ? sortType : undefined,
		};

		// check for unique filter combination in filter array
		var filterIndex = _findIndex(header.filterBar, function (f) {
			return f.filterId === filterId;
		});
		var headerIndex = _findIndex(this.getState().headers, function (h) {
			return h.hash === header.hash;
		});

		// adjust filter array object with new params
		this.setHeaderFilterBar(filterObj, headerIndex, filterIndex);

		// Define fields base array
		var fields = [];

		// Loop through each header and generate filter
		_each(this.getState().headers, function (header) {
			_each(header.filterBar, function (headerFilter) {
				// Only run through this is current headerFilter is active
				if (headerFilter.active) {
					// Find fieldName based on if header has reference to child resource or not
					// Is also based on if there is a query included in the reference.
					var fieldName;
					if (_has(header, 'reference') && header.type === 'select') {
						fieldName = _has(header.reference, 'query')
							? header.name + '.' + header.reference.query + '.' + 'id'
							: header.name + '.' + 'id';
					} else if (_has(header, 'reference')) {
						fieldName = _has(header.reference, 'query')
							? header.name +
							  '.' +
							  header.reference.query +
							  '.' +
							  header.reference.key
							: header.name + '.' + header.reference.key;
					} else {
						fieldName = header.name;
					}

					// Find data based on which type of filterHeader
					var data;

					// Date range flow
					if (header.type === 'date') {
						const dates = headerFilter.filter;
						const startDateData = moment.isMoment(dates.startDate)
							? dates.startDate.format('YYYY-MM-DD')
							: null;
						const endDateData = moment.isMoment(dates.endDate)
							? dates.endDate.format('YYYY-MM-DD')
							: null;
						let dateFields;

						if (!_isNull(startDateData) && !_isNull(endDateData)) {
							dateFields = [
								{
									field: fieldName,
									operator: '=ge=',
									data: startDateData,
									joiningOperator: ';',
								},
								{
									field: fieldName,
									operator: '=le=',
									data: endDateData,
									joiningOperator: ';',
								},
							];
						} else if (!_isNull(startDateData)) {
							dateFields = [
								{
									field: fieldName,
									operator: '==',
									data: startDateData,
									joiningOperator: ';',
								},
							];
						} else dateFields = [];

						// Push to array
						fields = [...fields, ...dateFields];
					}

					// Normal flow
					else {
						switch (header.type) {
							case 'bool':
								data = headerFilter.filter.value === true ? 1 : 0;
								break;
							case 'time':
								data = moment(headerFilter.filter).format('HH:mm:ss');
								break;
							case 'datetime':
								data = moment
									.utc(headerFilter.filter)
									.format('YYYY-MM-DDTHH:mm:ssZ');
								break;
							case 'timetime':
								data = moment
									.utc(headerFilter.filter)
									.format('YYYY-MM-DDTHH:mm:ssZ');
								break;
							case 'select':
								data = headerFilter.filter.value;
								break;

							// currency, mail, number, string
							default:
								data = headerFilter.filter;
								break;
						}

						// Set operator and data based on operator
						var operator = _isObject(headerFilter.operator)
							? // operator coming from component
							  _last(headerFilter.operator.value.split('.'))
							: // operator coming from initFilterBar - remember to chop off all string before .
							  headerFilter.operator.substring(
									headerFilter.operator.indexOf('.') + 1
							  );

						data = operator === '=like=' ? '%' + data + '%' : data;

						// Initiate field object
						var fieldObj = {
							field: fieldName,
							operator: operator,
							data: data,
							joiningOperator: ';',
						};

						// Decode sort type
						var sortType = _isObject(headerFilter.sortType)
							? // sort type coming from component
							  _last(headerFilter.sortType.value.split('.'))
							: // sort type coming from initFilterBar - remember to chop off all string before .
							  sortType;

						// Set sort type
						switch (sortType) {
							case 'absolute':
								fieldObj.absolute = true;
								break;
						}

						// Push to array
						fields.push(fieldObj);
					}
				}
			});
		});

		// Set search filter if any fields
		if (fields.length > 0) {
			// Remove trailing comma on last entry
			fields[fields.length - 1] = _omit(_last(fields), 'joiningOperator');

			// Set search filter
			this.setFilterFilterBar(__apiFilter.create([{ fields: fields }]));
		}

		// Otherwise omit search filter
		else this.setFilterFilterBar(undefined);

		// Reset offset
		this.setParamOffset(0);

		// Resolve
		resolve();
	});
};
