import { get, post, remove, patch } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './rawIngredientConfigurations.phrases';

/**
 * @function fetchRawIngredientConfigurations
 * @description Fetch all raw ingredient categories
 * @param {object} state
 */
export function fetchRawIngredientConfigurations(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get(
		'/inventory/raw_ingredient_configurations',
		params,
		null,
		state.headers
	)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addRawIngredientConfigurations
 * @description adds raw ingredient categories
 */
export function addRawIngredientConfigurations(data) {
	return post('/inventory/raw_ingredient_configurations', data)
		.then(res => {
			setFeedback(phrases.RAW_INGREDIENT_CONFIGURATIONS_ADDED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editRawIngredientConfiguration
 * @description edits raw ingredient categories
 */
export function editRawIngredientConfiguration(data) {
	return post(`/inventory/raw_ingredient_configurations/${data.id}`, data)
		.then(res => {
			setFeedback(phrases.RAW_INGREDIENT_CONFIGURATION_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function editRawIngredientConfigurations
 * @description edits raw ingredient categories
 */
export function editRawIngredientConfigurations(data) {
	return patch('/inventory/raw_ingredient_configurations', data)
		.then(res => {
			setFeedback(phrases.RAW_INGREDIENT_CONFIGURATIONS_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function deleteRawIngredientConfiguration
 * @description deletes raw ingredient categories
 */
export function deleteRawIngredientConfiguration(id) {
	return remove(`/inventory/raw_ingredient_configurations/${id}`)
		.then(res => {
			setFeedback(phrases.RAW_INGREDIENT_CONFIGURATIONS_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
