'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, Tag, Legend } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// phrases
import phrases from './../../../salesConfigurationPosConfigurationDiscountProductVariant.phrases';

// lodash
import _get from 'lodash/get';

class SalesConfigurationPosConfigurationDiscountProductVariantBatchListAdd extends Component {
	constructor(props) {
		super(props);

		this.noPosConfigurationProductVariantBulletColor = 'ff7476';
		this.posConfigurationDiscountProductVariantExistsBulletColor = 'ffe699';
	}

	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e.target ? e.target.value : e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: value },
		});
	}

	render() {
		const { batchListData } = this.props;

		return (
			<>
				<div className="pos-configuration-discount-product-variant__legend">
					<Legend
						points={[
							{
								bulletColor: `#${this.posConfigurationDiscountProductVariantExistsBulletColor}`,
								label:
									phrases.MODAL_BATCH_STEP_LIST_LEGEND_POS_CONFIGURATION_DISCOUNT_PRODUCT_VARIANT_EXISTS,
							},
							{
								bulletColor: `#${this.noPosConfigurationProductVariantBulletColor}`,
								label:
									phrases.MODAL_BATCH_STEP_LIST_LEGEND_NO_POS_CONFIGURATION_PRODUCT_VARIANT,
							},
						]}
					/>
				</div>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_LIST_POS_CONFIGURATION_NAME}</th>
							<th>
								{phrases.MODAL_BATCH_STEP_LIST_POS_CONFIGURATION_VERSION_NAME}
							</th>
							<th>{phrases.MODAL_BATCH_STEP_LIST_PRODUCT_VARIANT}</th>
							<th>
								{phrases.MODAL_BATCH_STEP_LIST_POS_CONFIGURATION_DISCOUNT}
							</th>
							<th>
								{
									phrases.MODAL_BATCH_STEP_LIST_POS_CONFIGURATION_PRODUCT_VARIANT_TO_STAY_PRICE
								}
							</th>
							<th>
								{
									phrases.MODAL_BATCH_STEP_LIST_POS_CONFIGURATION_PRODUCT_VARIANT_TO_GO_PRICE
								}
							</th>

							<th>{phrases.MODAL_BATCH_STEP_LIST_TO_STAY_DISCOUNT}</th>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TO_GO_DISCOUNT}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{batchListData.map((entry, i) => {
							const rowDisabled = !entry.canAdd;

							return (
								<TableListRow
									key={entry.identifier}
									type={rowDisabled && 'light'}>
									<TableListCell>
										{rowDisabled && (
											<Tag
												type="bullet"
												color={
													entry.posConfigurationProductVariant
														? this
															.posConfigurationDiscountProductVariantExistsBulletColor
														: this.noPosConfigurationProductVariantBulletColor
												}
											/>
										)}
										{i + 1}
									</TableListCell>
									<TableListCell>
										{_get(
											entry,
											'posConfigurationDiscount.pos_configuration_version.pos_configuration.name',
											''
										)}
									</TableListCell>
									<TableListCell>
										{_get(
											entry,
											'posConfigurationDiscount.pos_configuration_version.name',
											''
										)}
									</TableListCell>
									<TableListCell>
										{_get(entry, 'productVariant.name', '')}
									</TableListCell>
									<TableListCell>
										{_get(entry, 'posConfigurationDiscount.name', '')}
									</TableListCell>
									<TableListCell>
										{_get(
											entry,
											'posConfigurationProductVariant.to_stay_price',
											''
										)}
									</TableListCell>
									<TableListCell>
										{_get(
											entry,
											'posConfigurationProductVariant.to_go_price',
											''
										)}
									</TableListCell>

									<TableListCell>
										<Input
											id={`${entry.identifier}-toStayDiscount`}
											type="number"
											value={entry.toStayDiscount}
											placeholder="To stay discount"
											onChange={(event) =>
												this._editListEntry(event, entry, 'toStayDiscount')
											}
											required
											disabled={rowDisabled}
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`${entry.identifier}-toGoDiscount`}
											type="number"
											value={entry.toGoDiscount}
											placeholder="To go discount"
											onChange={(event) =>
												this._editListEntry(event, entry, 'toGoDiscount')
											}
											required
											disabled={rowDisabled}
										/>
									</TableListCell>

									<TableListCell>
										{!rowDisabled && (
											<Button
												type="negative"
												size="tiny"
												onClick={() => this._onRemoveEntry(entry.identifier)}>
												<span className="icon icon--delete" />
											</Button>
										)}
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
			</>
		);
	}
}

SalesConfigurationPosConfigurationDiscountProductVariantBatchListAdd.propTypes = {
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

SalesConfigurationPosConfigurationDiscountProductVariantBatchListAdd.defaultProps = {};

export default SalesConfigurationPosConfigurationDiscountProductVariantBatchListAdd;
