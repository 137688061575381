'use strict';

import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './productVariantAddons.phrases';

/**
 * @function fetchProductVariantAddons
 * @description Fetch Product variant addons
 * @param {number} productVariantId
 * @param {object} state
 */
export function fetchProductVariantAddons(productVariantId, state) {
	const preFilter = `:product_variant.id=='${productVariantId}'`;
	// let preFilter = '';

	const filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(`/product/product_variant_addons`, params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addProductVariantAddons
 * @description add
 */
export function addProductVariantAddon(data) {
	return post(`/product/product_variant_addons`, data)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_ADDON_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editProductVariantAddon
 * @description edit
 */
export function editProductVariantAddon(id, data) {
	return post(`/product/product_variant_addons/${id}`, data)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_ADDON_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteProductVariantAddons
 * @description delete
 */
export function deleteProductVariantAddon(productVariantId) {
	return remove(`/product/product_variant_addons/${productVariantId}`)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_ADDON_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
