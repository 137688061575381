import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './buttonLoader.css';

export default class ButtonLoader extends PureComponent {
	render() {
		const { loading, theme, size } = this.props;

		const loaderClassNames = cx('button-loader', {
			'button-loader--dark': theme === 'dark',
			'button-loader--light': theme === 'light',
			[`button-loader--${size}`]: !!size
		});

		return loading && <span className={loaderClassNames} />;
	}
}

ButtonLoader.defaultProps = {
	loading: false,
	theme: 'dark',
	size: ''
};

ButtonLoader.propTypes = {
	loading: PropTypes.bool.isRequired,
	theme: PropTypes.oneOf(['dark', 'light', '']),
	size: PropTypes.oneOf(['big', 'small', ''])
};
