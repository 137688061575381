'use strict';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

// components
import { Icon } from 'dumb';

// mui
import { Box, SxProps, Typography } from '@mui/material';

const contentStyling: SxProps = {
	margin: 0,
	fontSize: (theme: any) => theme.typography.body2.fontSize,
	textAlign: 'center',
	marginBottom: '2px',
};

type Props = {
	styles?: SxProps;
	onTipClick?: () => void;
	onTipCloseClick?: () => void;
	content: string;
	icon?: string;
	iconClose?: string;
	hoverable?: boolean;
} & typeof defaultProps;

const defaultProps = {
	onTipClick: () => {},
	onTipCloseClick: () => {},
	icon: 'lightbulb_outline',
	iconClose: 'highlight_off',
	hoverable: false,
	styles: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
};

function Tip({
	onTipClick,
	onTipCloseClick,
	content,
	icon,
	iconClose,
	hoverable,
	styles,
}: Props): JSX.Element {
	const _toggleHoverState = () => {
		if (hoverable) {
			setTipHovered(!tipHovered);
		}
	};

	const onTipClickWrapper = () => {
		hoverable && onTipClick();
	};

	const onTipCloseClickWrapper = () => {
		hoverable && onTipCloseClick();
	};

	const [tipHovered, setTipHovered] = useState(false);

	return (
		<Box
			sx={{
				...styles,
				...(hoverable && {
					cursor: 'pointer',
				}),
			}}>
			<Box
				sx={{ marginRight: (theme) => theme.spacing(0.5) }}
				onClick={onTipCloseClickWrapper}
				onMouseEnter={_toggleHoverState}
				onMouseLeave={_toggleHoverState}>
				{tipHovered ? <Icon name={iconClose} /> : <Icon name={icon} />}
			</Box>

			<Typography sx={contentStyling} onClick={onTipClickWrapper}>
				{content}
			</Typography>
		</Box>
	);
}

Tip.defaultProps = defaultProps;

Tip.propTypes = {
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onTipClick: PropTypes.func,
	onTipCloseClick: PropTypes.func,
	icon: PropTypes.string,
	iconClose: PropTypes.string,
	hoverable: PropTypes.bool,
	styles: PropTypes.object,
};

export default Tip;
