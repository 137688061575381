export default {
	INGREDIENT_PRICING: 'Ingredient pricing',
	INGREDIENTS_ADDED: 'Ingredients pricings added',
	INGREDIENT_EDITED: 'Ingredient pricing edited',
	INGREDIENT_DELETED: 'Ingredient pricing deleted',

	MODAL_BATCH_STEP_FORM_TITLE: 'Batch add',
	MODAL_BATCH_STEP_POS_CONFIGURATION_VERSION: 'Pos configuration version',
	MODAL_BATCH_STEP_POS_CONFIGURATION_VERSIONS: 'Pos configuration versions',
	MODAL_BATCH_STEP_INGREDIENT: 'Ingredient',
	MODAL_BATCH_STEP_INGREDIENTS: 'Ingredients',
	MODAL_BATCH_STEP_ALTERNATIVE_ID: 'Alternative id',
	MODAL_BATCH_STEP_TO_STAY_PRICE: 'To stay price',
	MODAL_BATCH_STEP_TO_GO_PRICE: 'To go price',
	MODAL_BATCH_STEP_DELIVERY_PRICE: 'Delivery price',
	MODAL_BATCH_STEP_AVAILABLE_IN_APP: 'Available in app',
	MODAL_BATCH_STEP_TIP:
		'Select any combination of ingredients and pos configuration versions.',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'Next',

	MODAL_BATCH_STEP_LIST_TITLE: 'Confirm entries',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Submit',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'Back',

	MODAL_BATCH_STEP_FORM_POS_CONFIGURATION_VERSIONS_TABLE_TITLE:
		'POS Configuration Versions',
	MODAL_BATCH_STEP_FORM_INGREDIENTS_TABLE_TITLE: 'Ingredients',
	LEGACY_BUTTON: 'Legacy',
	LEGACY_FILTER_TOOLTIP_ACTIVE: 'Showing non legacy POS Configurations',
	LEGACY_FILTER_TOOLTIP_UNACTIVE: 'Showing all POS Configurations',
	MODAL_BATCH_STEP_LIST_LEGEND_CANT_ADD: 'Values already exist',
};
