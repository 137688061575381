export default function getColumns() {
	return [
		{
			Header: 'Name',
			id: 'name',
			accessor: 'name',
			filterPath: ':name',
		},
		{
			Header: 'Description',
			id: 'description',
			accessor: 'description',
			filterPath: ':description',
		},
		{
			Header: 'Rows',
			id: 'rows',
			accessor: 'rows',
			filterPath: ':rows',
		},
		{
			Header: 'Columns',
			id: 'columns',
			accessor: 'columns',
			filterPath: ':columns',
		},
	];
}
