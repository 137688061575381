'use strict';

import { SET_COLUMNS, INITIALIZE_COLUMNS } from './toggleColumns.actions';

const defaultState = {
	tableColumns: {},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
		case SET_COLUMNS: {
			const { value, reduxKey } = payload;

			return {
				...state,
				tableColumns: {
					...state.tableColumns,
					[reduxKey]: state.tableColumns[reduxKey].map((entry) => {
						const columnId = Object.keys(entry)[0];

						if (columnId === value.id) {
							return {
								[value.id]: value.show,
							};
						}
						return entry;
					}),
				},
			};
		}

		case INITIALIZE_COLUMNS: {
			const { value, reduxKey } = payload;

			return {
				...state,
				tableColumns: {
					...state.tableColumns,
					[reduxKey]: value,
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
