'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input, InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';

import phrases from './../../salesConfigurationPosConfigurationDiscountIngredients.phrases';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

class ModalBatchStepFormDiscountAmounts extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false
		};
	}

	editStoreEntry(e, type) {
		const { updateBatchForm } = this.props;

		const value = _get(e, 'target', false) ? e.target.value : e;

		updateBatchForm({
			[type]: value
		});
	}

	render() {
		const { batchFormData } = this.props;

		const showNominalInputs =
			_get(batchFormData, 'discountType.value', '') === 'Nominal';
		const showPercentageInputs =
			_get(batchFormData, 'discountType.value', '') === 'Percentage' &&
			!_isEmpty(batchFormData.roundToNearest);

		return (
			<>
				<ModalRow>
					<div className="modal-batch__content__row__discount-type_round">
						<InputCollectionSelect
							id="discountType"
							placeholder="Nominal/ percentage"
							label="Discount type"
							options={[
								{
									value: 'Nominal',
									label: 'Nominal'
								},
								{
									value: 'Percentage',
									label: 'Percentage'
								}
							]}
							value={batchFormData.discountType}
							onChange={value => this.editStoreEntry(value, 'discountType')}
						/>
						{_get(batchFormData, 'discountType.value', '') === 'Percentage' && (
							<InputCollectionSelect
								id="roundToNearest"
								placeholder="1, 0.5, 0.25, 0.1, 0.05, 0.01"
								label="Round to nearest"
								options={[
									{
										value: '0.01',
										label: '0.01'
									},
									{
										value: '0.05',
										label: '0.05'
									},
									{
										value: '0.1',
										label: '0.1'
									},
									{
										value: '0.25',
										label: '0.25'
									},
									{
										value: '0.5',
										label: '0.5'
									},
									{
										value: '1',
										label: '1'
									}
								]}
								value={batchFormData.roundToNearest}
								onChange={value => this.editStoreEntry(value, 'roundToNearest')}
							/>
						)}
					</div>
				</ModalRow>

				<ModalRow>
					<Input
						id="toStayDiscount"
						type="number"
						disabled={!showNominalInputs && !showPercentageInputs}
						label={
							showNominalInputs
								? phrases.MODAL_BATCH_STEP_DISCOUNT_AMOUNTS_TO_STAY_DISCOUNT
								: phrases.MODAL_BATCH_STEP_DISCOUNT_AMOUNTS_TO_STAY_DISCOUNT_PERCENTAGE
						}
						placeholder={
							showNominalInputs
								? phrases.MODAL_BATCH_STEP_DISCOUNT_AMOUNTS_PLACEHOLDER
								: phrases.MODAL_BATCH_STEP_DISCOUNT_AMOUNTS_PLACEHOLDER_PERCENTAGE
						}
						value={
							showNominalInputs
								? batchFormData.toStayDiscount
								: batchFormData.toStayDiscountPercentage
						}
						onChange={value =>
							showNominalInputs
								? this.editStoreEntry(value, 'toStayDiscount')
								: this.editStoreEntry(value, 'toStayDiscountPercentage')
						}
					/>
				</ModalRow>
				<ModalRow>
					<Input
						id="toGoDiscount"
						type="number"
						disabled={!showNominalInputs && !showPercentageInputs}
						label={
							showNominalInputs
								? phrases.MODAL_BATCH_STEP_DISCOUNT_AMOUNTS_TO_GO_DISCOUNT
								: phrases.MODAL_BATCH_STEP_DISCOUNT_AMOUNTS_TO_GO_DISCOUNT_PERCENTAGE
						}
						placeholder={
							showNominalInputs
								? phrases.MODAL_BATCH_STEP_DISCOUNT_AMOUNTS_PLACEHOLDER
								: phrases.MODAL_BATCH_STEP_DISCOUNT_AMOUNTS_PLACEHOLDER_PERCENTAGE
						}
						value={
							showNominalInputs
								? batchFormData.toGoDiscount
								: batchFormData.toGoDiscountPercentage
						}
						onChange={value =>
							showNominalInputs
								? this.editStoreEntry(value, 'toGoDiscount')
								: this.editStoreEntry(value, 'toGoDiscountPercentage')
						}
					/>
				</ModalRow>
			</>
		);
	}
}

ModalBatchStepFormDiscountAmounts.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func
};

export default ModalBatchStepFormDiscountAmounts;
