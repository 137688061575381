import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'detectMobile.vanilla';

import ContentLoader from 'react-content-loader';

// Components
import { Card, InputSmart } from 'dumb';
import InputPhoneWrapper from './components/inputPhoneWrapper/inputPhoneWrapper';

// Utils
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

// Phrases
import phrases from './../../juicerDetails.phrases';

class ContactsCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			disabled: true
		};

		this.toggleInputs = this.toggleInputs.bind(this);
		this._changePhoneNumberInput = this._changePhoneNumberInput.bind(this);
	}

	getLoaderLines() {
		if (!isMobile())
			return (
				<>
					<rect x="0" y="0" rx="3" ry="3" width={200} height="40" />,
					<rect x="0" y="50" rx="3" ry="3" width={350} height="40" />,
					<rect x="0" y="100" rx="3" ry="3" width={350} height="40" />
				</>
			);

		return (
			<>
				<rect x="0" y="0" rx="3" ry="3" width={200} height="37" />,
				<rect x="0" y="50" rx="3" ry="3" width={350} height="37" />,
				<rect x="0" y="100" rx="3" ry="3" width={350} height="37" />
			</>
		);
	}

	_onContactInfoChange(handle, params) {
		const { postCustomData, personData } = this.props;

		params.payload = {
			...(!params.id && { person: personData.id }),
			[params.key]: params.value
		};

		return postCustomData(params, handle);
	}

	/**
	 * @function _onContactInfoChange
	 * @param {Number} phoneNumber
	 * @description wrapper for
	 */
	_changePhoneNumberInput(phoneNumber) {
		const { personData } = this.props;

		return this._onContactInfoChange('person_phone_number', {
			uri: 'person_phone_numbers',
			id: _get(personData, 'person_phone_number.id', null),
			value: phoneNumber,
			key: 'phone_number'
		});
	}

	/**
	 * @function toggleInputsActive
	 * @description sets the state of the component as being used or unsed
	 * @description therefor toggling all inputs in the group as enabled or disabled
	 * @param active {boolean}
	 */
	toggleInputs({ disabled }) {
		// check data type
		if (typeof disabled !== 'boolean') return;

		this.setState(() => ({ disabled }));
	}

	render() {
		const { personData, loading } = this.props;

		return (
			<div className="contacts-card">
				<Card className="juicer-details__small">
					{loading && (
						<ContentLoader
							height={140}
							speed={2}
							primaryColor="#f3f3f3"
							secondaryColor="#ecebeb">
							{this.getLoaderLines()}
						</ContentLoader>
					)}
					{!loading && !_isEmpty(personData) && (
						<Fragment>
							<h3 className="card-name">{phrases.CONTACT_DETAILS_CARD}</h3>
							<InputSmart
								id="email"
								type="email"
								placeholder="email@joejuice.com"
								label="Primary Email"
								customParams={value => ({
									uri: 'person_email_addresses',
									id: _get(personData, 'person_email_address.id', null),
									value,
									key: 'email_address'
								})}
								onChange={params =>
									this._onContactInfoChange('person_email_address', params)
								}
								value={_get(
									personData,
									'person_email_address.email_address',
									''
								)}
								tabIndex={0}
								toggleInputs={this.toggleInputs}
								readOnly={this.state.disabled}
							/>
							<InputPhoneWrapper
								phoneNumber={_get(
									personData,
									'person_phone_number.phone_number',
									''
								)}
								onChange={this._changePhoneNumberInput}
								readOnly={this.state.disabled}
								toggleInputs={this.toggleInputs}
							/>
						</Fragment>
					)}
				</Card>
			</div>
		);
	}
}

ContactsCard.propTypes = {
	personData: PropTypes.object,
	loading: PropTypes.bool,
	postCustomData: PropTypes.func
};

export default ContactsCard;
