'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');
const __enums = require('enums.service');
const __apiFilter = require('apiFilter.service');

export default class SalaryComponentsRulesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'workplace',
				type: 'select',
				canAdd: true,
				canEdit: true,
				valueIfNull: '*',
				reference: {
					list: '/organization/workplaces',
					key: 'name',
				},
			},
			{
				name: 'weekday',
				type: 'select',
				canAdd: true,
				canEdit: true,
				valueIfNull: '*',
				options: {
					enums: __enums.weekdays,
				},
			},
			{
				name: 'tag',
				type: 'select',
				canAdd: true,
				canEdit: true,
				valueIfNull: '*',
				reference: {
					list: '/tagging/tags',
					key: 'name',
					searchListFilter: __apiFilter.create([
						{
							fields: [
								{
									field: 'type',
									operator: '==',
									data: 'Shiftplan',
								},
							],
						},
					]),
				},
			},
			{
				name: 'time',
				type: 'time',
				canAdd: true,
				canEdit: true,
				valueIfNull: '*',
				reference: {
					key: 'from',
					translationKey: 'FROM',
				},
			},
			{
				name: 'time',
				type: 'time',
				canAdd: true,
				canEdit: true,
				valueIfNull: '*',
				reference: {
					key: 'to',
					translationKey: 'TO',
				},
			},
		];
		this.settings = {
			name: 'SalaryComponentsRulesList',
			allowEmpty: true,
			alwaysRefresh: true,
			canAdd: true,
			canEdit: true,
			canDelete: true,
			canManipulate: () => validAccess(undefined, ['Salary Admin']),
			translationPath: 'SALARY.SALARY_COMPONENTS_RULES',
			endpoint: __endpoints.collection.salary.salary_component_rules,
		};
		this.init();
		return this;
	}
}
