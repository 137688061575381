import {
	UPDATE_DOCUSIGN_TEMPLATE,
	SET_DOCUSIGN_TEMPLATE,
	RESET_STATE,
	SET_CONFIGURE_DOCUSIGN_DATA,
} from './docuSignTemplates.actions';

const defaultState = {
	data: {
		docuSignTemplate: {},
		defaultDocuSignTemplate: {},
		configureDocuSignData: {},
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_DOCUSIGN_TEMPLATE: {
			return {
				data: {
					...state.data,
					docuSignTemplate: payload,
					defaultDocuSignTemplate: payload,
				},
			};
		}

		case UPDATE_DOCUSIGN_TEMPLATE: {
			return {
				data: {
					...state.data,
					docuSignTemplate: {
						...state.data.docuSignTemplate,
						...payload,
					},
				},
			};
		}

		case RESET_STATE: {
			return {
				...defaultState,
			};
		}

		case SET_CONFIGURE_DOCUSIGN_DATA: {
			return {
				...state,
				data: {
					...state.data,
					configureDocuSignData: {
						...state.data.configureDocuSignData,
						...payload,
					},
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
