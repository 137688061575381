import React, { useRef, useState, useEffect } from 'react';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import {
	resetState,
	rerenderTable,
} from './../store/powerBiReportDataSets.actions';

// components
import { ModalStepper } from 'dumb';
import PowerBiReportDataSetsModalStep from './components/powerBiReportDataSetsModalStep';

// services
import { addPowerBiReportDataSets } from './../powerBiReportDataSets.service';

// phrases/ enums
import phrases from './../powerBiReportDataSets.phrases';

type Props = {
	onClose: () => void;
	powerBiReportId: number;
};

const PowerBiReportDataSetsModalBatch = ({
	onClose,
	powerBiReportId,
}: Props): JSX.Element => {
	const modalRef = useRef() as React.LegacyRef<ModalStepper>;
	const [loading, setLoading] = useState(false);
	const dispatch = useAppDispatch();
	const batchList = useAppSelector(
		(store) => store.globalAdministrationPowerBIReportDatasets.batchList
	);

	const onNext = () => {
		setLoading(true);

		const payload = batchList.map((entry) => {
			return {
				power_bi_report: powerBiReportId,
				dataset_id: entry.dataset_id,
			};
		});

		addPowerBiReportDataSets({ batch: payload })
			.then(() => {
				setLoading(false);
				dispatch(rerenderTable());
				onClose();
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const steps = [
		{
			component: <PowerBiReportDataSetsModalStep />,
			title: phrases.MODAL_TITLE,
			onBack: onClose,
			onNext,
			loading,
			isValid: true,
			confirmButtonLabel: phrases.SUBMIT,
			defaultStyles: false,
			noScroll: false,
		},
	];

	useEffect(() => {
		return () => {
			dispatch(resetState());
		};
	}, [dispatch]);

	return (
		<ModalStepper
			dataCy="power-bi-report-datasets-modal-batch"
			header={phrases.MODAL_TITLE}
			onClose={onClose}
			ref={modalRef}
			isOpen
			steps={steps}
			showStep={false}
		/>
	);
};

export default PowerBiReportDataSetsModalBatch;
