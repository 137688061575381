'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'dumb';

import GoldModelImportCsv from './idealModelImportCsv.component';

class IdealModelControl extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeImportCsv: false
		};
		this.onImportCsvOpenHandler = this.onImportCsvOpenHandler.bind(this);
		this.onImportCsvCloseHandler = this.onImportCsvCloseHandler.bind(this);
	}

	onImportCsvOpenHandler() {
		this.setState({ activeImportCsv: true });
	}

	onImportCsvCloseHandler() {
		this.setState({ activeImportCsv: false });
	}

	render() {
		const { translations } = this.props;

		return (
			<aside className="gold-model__controls">
				<ul>
					<li data-label={translations.IDEAL_IMPORT}>
						<Button
							shadow
							type="inverted"
							onClick={this.onImportCsvOpenHandler}>
							<Icon name="publish" />
						</Button>
					</li>
				</ul>
				<GoldModelImportCsv
					visible={this.state.activeImportCsv}
					handleClose={this.onImportCsvCloseHandler}
					modifierClassName="gold-model"
					{...this.props}
				/>
			</aside>
		);
	}
}

IdealModelControl.propTypes = {
	translations: PropTypes.object.isRequired
};

export default IdealModelControl;
