'use strict';

import { get, post, remove, patch } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { fetchCompleteCollection } from 'api/fetchCompleteCollection';

import phrases from './salesConfigurationPosConfigurationDiscounts.phrases';

/**
 * @function fetchPosConfigurationDiscounts
 * @description Fetch product bundle sets for a product bundle
 */
export function fetchPosConfigurationDiscounts(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/pos/pos_configuration_discounts', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

export function fetchPosConfigurationProductVariants(state) {
	// Set params and filter
	const params = {
		limit: 300,
		sort: state.sort,
		offset: 0,
		filter: state.filter,
	};

	return fetchCompleteCollection(
		'/pos/pos_configuration_product_variants',
		params,
		null,
		state.headers
	)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

export function addPosConfigurationDiscounts(data) {
	return post('/pos/pos_configuration_discounts', data)
		.then((res) => {
			setFeedback(phrases.POS_CONFIGURATION_DISCOUNTS_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editPosConfigurationDiscount
 * @param {Object} payload - update pos configuration discount
 */
export function editPosConfigurationDiscount(payload) {
	return post(`/pos/pos_configuration_discounts/${payload.id}`, payload)
		.then((res) => {
			setFeedback(phrases.POS_CONFIGURATION_DISCOUNT_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editPosConfigurationDiscounts
 * @param {Object} payload - update multiple pos configuration discounts
 */
export function editPosConfigurationDiscounts(payload) {
	return patch('/pos/pos_configuration_discounts', payload)
		.then((res) => {
			setFeedback(phrases.POS_CONFIGURATION_DISCOUNTS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deletePosConfigurationDiscounts
 * @param {Number} id - id of a pos configuration discounts to delete
 */
export function deletePosConfigurationDiscounts(id) {
	return remove(`/pos/pos_configuration_discounts/${id}`)
		.then((res) => {
			setFeedback(phrases.POS_CONFIGURATION_DISCOUNTS_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
