'use strict';

export const RESET_EMPLOYMENT_LIST = 'PERSON_DETAILS/RESET_EMPLOYMENT_LIST';
export const SET_EMPLOYMENT_EDIT_VALUES =
	'PERSON_DETAILS/SET_EMPLOYMENT_EDIT_VALUES';
export const UPDATE_EMPLOYMENT_LIST = 'PERSON_DETAILS/UPDATE_EMPLOYMENT_LIST';

/**
 * @param {object} payload
 */
export function setEmploymentEditValues(payload) {
	return {
		type: SET_EMPLOYMENT_EDIT_VALUES,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateEmploymentList(payload) {
	return {
		type: UPDATE_EMPLOYMENT_LIST,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET_EMPLOYMENT_LIST
	};
}
