/* eslint-disable react/prop-types */
import { IColumnHeader } from 'types/reactDataWrapper';

// utils to get enum labels
import { getEntryTypeLabel } from '../../../../../../structure/globalAdministration/dynamicAccounts/dynamicAccounts.utils';

export default function getColumns(): IColumnHeader<IFinancialDynamicAccount>[] {
	const columns: IColumnHeader<IFinancialDynamicAccount>[] = [
		{
			Header: 'Account number',
			id: 'account-number',
			accessor: 'account_number',
			filterPath: ':account_number',
		},
		{
			Header: 'Type',
			id: 'type',
			accessor: 'type',
			filterPath: ':type',
		},
		{
			Header: 'Entry type',
			id: 'entry-type',
			accessor: 'entry_type',
			filterPath: ':entry_type',
			Cell: (d) => {
				const entryTypeLabel = getEntryTypeLabel(d.original.entry_type);

				return entryTypeLabel;
			},
		},
	];

	return columns;
}
