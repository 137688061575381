export default {
	TITLE: 'Basket recommendations',
	PRODUCT_VARIANT_BASKET_RECOMMENDATION_ADDED: 'Groups added!',
	PRODUCT_VARIANT_BASKET_RECOMMENDATION_EDITED: 'Group edited!',
	PRODUCT_VARIANT_BASKET_RECOMMENDATION_DELETED:
		'Recommendations/exclusions removed!',
	PRODUCT_VARIANT_BASKET_PRIORITY_EDITED: 'Basket priority edited!',
	PRODUCT_VARIANT_BASKET_RECOMMENDATIONS_EDITED: 'Basket priorities edited!',

	// custom in row button
	ADD_BASKET_RECOMMENDATION_PRIORITY: 'Add/assign recommendation priority',

	// batch form
	EXCLUSION: 'Exclusion',
	EXCLUSION_PLACEHOLDER: 'Select existing basket exclusion...',
	RECOMMENDATION_PLACEHOLDER: 'Select existing basket recommendation...',
	NAME: 'Name',
	NAME_PLACEHOLDER: 'Enter name...',
	DESCRIPTION: 'Description',
	DESCRIPTION_PLACEHOLDER: 'Enter description...',
	SORT_ORDER: 'Sort order',
	SORT_ORDER_PLACEHOLDER: 'Enter sort order...',
	ADD_RECOMMENDATION_PAGE_TITLE: 'Add existing/new basket recommendation',
	ADD_RECOMMENDATION_PAGE_TITLE_TOOLTIP:
		'Click to add a new basket recommendation',
	EXCLUSION_PAGE_TITLE: 'Add existing/new basket exclusion',
	EXCLUSION_PAGE_TOOLTIP: 'Click to add a new basket exclusion',
	ADD_RECOMMENDATION_TITLE: 'Add basket recommendation',
	ADD_EXCLUSION_TITLE: 'Add basket exclusion',
	CONFIRM_BUTTON_LABEL: 'Next',
	BACK_BUTTON_LABEL: 'Back',
	ADD_RECOMMENDATIONS: 'Add recommendations',
	ADD_EXCLUSIONS: 'Add exclusions',
	REPLACEMENT: 'Replacement',
	REPLACEMENTS: 'Replacements',
	REPLACEMENT_PLACEHOLDER: 'Select replacement...',
	ADD: 'Add',
	RECOMMENDATION: 'Recommendation',
	PREVIEW: 'Preview',
	SUBMIT: 'Submit',
	BACK_TO_BEGINNING: 'Back to start',
	SELECTED_PRODUCT_VARIANTS: 'Selected product variants',
	SELECTED_PRODUCT_VARIANTS_TOOLTIP:
		'Recommendations and exlusions will be applied to all selected products',
};
