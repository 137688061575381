'use strict';

import React, { ChangeEvent, MutableRefObject } from 'react';

// interfaces
import { IFetchDataProps } from 'types/reactDataWrapper';
import { ICurrencyReduxValue } from './types';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import { push } from 'redux-first-history';
import {
	setDynamicAccount,
	updateDynamicAccount,
	resetState,
} from './store/currencies.actions';

// components
import ReactDataWrapper from 'reactDataWrapper/reactDataWrapper.component';
import getColumns from 'reactDataWrapperColumns/administration/currencies.columns';

// components
import { Input, Button, Tooltip, Icon } from 'dumb';

import {
	fetchCurrencies,
	// deleteCurrencies,
	editCurrencies,
} from './currencies.service';

// constants/phrases/tools
import phrases from './currencies.phrases';
import getEditedValues from 'services/utils/getEditedValues';

const reduxKey = '/financial/dynstamics_accounts';

const Currencies = (): JSX.Element => {
	// hooks
	const { currency, defaultCurrency } = useAppSelector((state) => ({
		currency: state.currencies.data.currency,
		defaultCurrency: state.currencies.data.defaultCurrency,
	}));
	const dispatch = useAppDispatch();

	const editEntry = () => {
		if (!currency.id || !defaultCurrency) return Promise.resolve(true);

		const editedValues = getEditedValues({
			oldData: defaultCurrency,
			newData: currency,
		});

		const payload = {
			supported_decimals: editedValues?.supportedDecimals,
		};

		return editCurrencies(currency.id, payload);
	};

	// const deleteEntry = (id: number) => {
	// 	return deleteCurrencies(id);
	// };

	const setInitialEditValues = (data: IAdministrationCurrencies) => {
		const payload: ICurrencyReduxValue = {
			id: data.id,
			code: data.code,
			description: data.description,
			supportedDecimals: data.supported_decimals,
			currentCurrencyRate: data.current_currency_rate,
		};

		dispatch(setDynamicAccount(payload));
	};

	const editStoreEntry = (
		name: keyof ICurrencyReduxValue,
		value: number
	): void => {
		const payload = {
			[name]: value,
		};

		dispatch(updateDynamicAccount(payload));
	};

	const getEditableCells = () => {
		return [
			{
				header: 'Code',
				value: <span>{currency.code}</span>,
			},
			{
				header: 'Description',
				value: <span>{currency.description}</span>,
			},
			{
				header: 'Supported decimals',
				value: (
					<Input
						id="entry-type"
						type="number"
						placeholder="Enter supported decimals (2 or 3)..."
						dataCy="currencies-supported-decimals-input"
						value={currency.supportedDecimals}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							editStoreEntry('supportedDecimals', Number(e.target.value))
						}
					/>
				),
			},
		];
	};

	const goToDetailsPage = (id: number) => () => {
		dispatch(push(`${window.location.pathname}/${id}`));
	};

	return (
		<ReactDataWrapper
			title={phrases.TABLE_TITLE}
			dataCy="currencies-table"
			columns={getColumns()}
			fetchData={(state: IFetchDataProps): ReturnType<typeof fetchCurrencies> =>
				fetchCurrencies(state)
			}
			filterable
			defaultPageSize={25}
			reduxKey={reduxKey}
			manual
			accessAreasAllowedToEdit={[
				'Global BI Configuration',
				'Administration/Currencies::Edit',
				'Organization Admin',
			]}
			editableCells={getEditableCells()}
			editEntry={editEntry}
			// deleteEntry={deleteEntry}
			setInitialEditValues={setInitialEditValues}
			onModalClose={() => dispatch(resetState())}
			onModalCloseConfirm
			actionsWidth={30}
			compact
			actions={(d: { original: IAdministrationCurrencies }) => (
				<Tooltip
					text={phrases.DETAILS_TOOLTIP}
					placement="left"
					renderData={(
						ref: MutableRefObject<undefined>,
						onMouseEnter: () => void,
						onMouseLeave: () => void
					) => (
						<Button
							shadow
							type="inverted"
							onClick={goToDetailsPage(d.original.id)}
							size="micro"
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
						>
							<Icon name="tune" />
						</Button>
					)}
				/>
			)}
		/>
	);
};

export default Currencies;
