export default {
	registrationType: {
		CLOCK_IN: 'Clock-in',
		CLOCK_OUT: 'Clock-out',
		BREAK: 'Break',
	},
	alignmentStrategy: {
		ALIGN_TO_CLOCKED: 'Align to clocked',
		ALIGN_TO_SHIFT: 'Align to shift',
		MANUAL: 'Manual',
	},
};
