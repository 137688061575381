'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import moment from 'moment';

// components
import { Input, SingleDatePickerInput, Textarea } from 'dumb';
import { ReactDataWrapper } from 'reactDataWrapper';
import { DateFilterSelector } from 'reactDataWrapper/utilities';

import { fetchWorkforceNews, addWorkforceNews, deleteWorkforceNews, editWorkforceNews } from './workforceNews.service';

import {
	setWorkforceNewsModal,
	updateWorkforceNews,
	setWaitingTimeGridEntryModal,
	updateWaitingTimeGridEntry,
	resetWorkforceNewsModal,
	resetWaitingTimeGridEntryModal,
} from './store/workforceNews.actions';

// constants/phrases/tools
import phrases from './workforceNews.phrases';
import constants from 'services/constants';
import _get from 'lodash/get';

import './style.css';

const reduxKey = '/shiftplanning/workforce_news';

class WorkforceNews extends Component {
	constructor(props) {
		super(props);

		this.fetchData = this.fetchData.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.getEditableCells = this.getEditableCells.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);

		this.today = moment.utc().format(constants.shortDate);
		this.infinity = moment.utc('9999-12-31').format(constants.shortDate);
		this.regex = new RegExp('^(http|https)://', 'i');
		this.contentTemplate =
			'<div><p>Description goes here</p><ol><li>Fine-Tune Operation ?</li><li>Door OPEN 🤮</li><li>Music Playing ?</li></ol></div>';

		this.columns = [
			{
				Header: 'Title',
				id: 'title',
				accessor: (d) => _get(d, 'title', ''),
				filterPath: ':title',
			},
			{
				Header: 'Link',
				id: 'link',
				accessor: (d) => _get(d, 'link', ''),
				filterPath: ':link',
				Cell: (d) => {
					return (
						<a
							href={d.original.link}
							target="_blank"
							rel="noopener noreferrer"
							style={{
								textDecoration: 'underline',
							}}>
							{d.original.link}
						</a>
					);
				},
			},
			{
				Header: 'Content',
				id: 'content',
				accessor: (d) => _get(d, 'content', ''),
				filterPath: ':content',
				Cell: (d) => {
					return (
						<div className="workforce-news__content-wrapper" dangerouslySetInnerHTML={{ __html: d.original.content }} />
					);
				},
				width: 700,
			},
			{
				Header: 'Active from',
				id: 'activeFrom',
				accessor: (d) => _get(d, 'period.from', ''),
				filterPath: ':period.from',
				width: 150,
				Filter: ({ column }) => <DateFilterSelector reduxKey={reduxKey} columnId={column.id} />,
			},
			{
				Header: 'Active to',
				id: 'activeTo',
				accessor: (d) => _get(d, 'period.to', ''),
				filterPath: ':period.to',
				width: 150,
				Filter: ({ column }) => <DateFilterSelector reduxKey={reduxKey} columnId={column.id} />,
			},
		];
	}

	addEntry() {
		const { workforceNews } = this.props;

		const payload = {
			title: workforceNews.title,
			content: workforceNews.content,
			link: this._checkLink(workforceNews.link),
			period: {
				from: workforceNews.activeFrom || this.today,
				to: workforceNews.activeTo || this.infinity,
			},
		};

		return addWorkforceNews(payload);
	}

	editEntry() {
		const { workforceNews } = this.props;

		const payload = {
			id: workforceNews.id,
			title: workforceNews.title,
			content: workforceNews.content,
			link: workforceNews.link,
			period: {
				from: workforceNews.activeFrom,
				to: workforceNews.activeTo,
			},
		};

		return editWorkforceNews(payload);
	}

	deleteEntry(id) {
		return deleteWorkforceNews(id);
	}

	setInitialEditValues(data) {
		const { setWorkforceNewsModal } = this.props;

		const payload = {
			id: data.id,
			title: data.title,
			link: data.link,
			content: data.content,
			activeFrom: data.period.from,
			activeTo: data.period.to,
		};

		setWorkforceNewsModal(payload);
	}

	_getSelectedDate(date) {
		if (date) return moment.utc(date).format(constants.shortDate);

		return name === 'activeFrom' ? this.today : this.infinity;
	}

	/**
	 * @method _checkLink
	 * @param {String} link - link pointing to data
	 * @description checks if link contains http:// or https:// prefix
	 * and if it doesn't - adds it
	 */
	_checkLink(link) {
		const linkMatch = this.regex.test(link);

		if (linkMatch) return link;
		else return `https://${link}`;
	}

	getEditableCells() {
		const { workforceNews } = this.props;

		return [
			{
				header: 'Title',
				value: (
					<Input
						id="title"
						placeholder="Enter title..."
						value={workforceNews?.title || ''}
						onChange={(e) => this.editStoreEntry('title', e.target.value)}
					/>
				),
			},
			{
				header: 'Link',
				value: (
					<Input
						id="link"
						placeholder="Enter link..."
						value={workforceNews?.link}
						onChange={(e) => this.editStoreEntry('link', e.target.value)}
					/>
				),
			},
			{
				header: 'Content',
				value: (
					<Textarea
						id="content"
						rows={3}
						placeholder="Enter content..."
						value={workforceNews?.content || this.contentTemplate}
						onChange={(e) => this.editStoreEntry('content', e.target.value)}
					/>
				),
			},
			{
				header: 'Active from',
				value: (
					<SingleDatePickerInput
						id="activeFrom"
						onChange={(event) => this.editStoreEntry('activeFrom', event)}
						selectedDate={this._getSelectedDate(_get(workforceNews, 'activeFrom', this.today))}
						noClockButton
					/>
				),
			},
			{
				header: 'Active to',
				value: (
					<SingleDatePickerInput
						id="activeTo"
						onChange={(event) => this.editStoreEntry('activeTo', event)}
						selectedDate={this._getSelectedDate(_get(workforceNews, 'activeTo', ''))}
						noClockButton
					/>
				),
			},
		];
	}

	editStoreEntry(name, value) {
		const { updateWorkforceNews } = this.props;

		if (name === 'activeFrom' || name === 'activeTo') {
			value = moment.utc(value).format(constants.shortDate);
		}

		const payload = {
			[name]: value,
		};

		updateWorkforceNews(payload);
	}

	fetchData(state) {
		return fetchWorkforceNews(state);
	}

	render() {
		return (
			<ReactDataWrapper
				title={phrases.TABLE_TITLE}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={5}
				reduxKey={reduxKey}
				manual
				editableCells={this.getEditableCells()}
				actionsWidth={60}
				createEntry={this.addEntry}
				editEntry={this.editEntry}
				deleteEntry={this.deleteEntry}
				editMultiple={this.editMultiple}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetWorkforceNewsModal}
				accessAreasAllowedToEdit={['Global Sales Configuration', 'Sales Configuration']}
				inRowButtons
			/>
		);
	}
}

WorkforceNews.propTypes = {
	setWorkforceNewsModal: PropTypes.func,
	resetWorkforceNewsModal: PropTypes.func,
	updateWorkforceNews: PropTypes.func,
	workforceNews: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setWorkforceNewsModal,
			updateWorkforceNews,
			updateWaitingTimeGridEntry,
			setWaitingTimeGridEntryModal,
			resetWorkforceNewsModal,
			resetWaitingTimeGridEntryModal,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		workforceNews: store.globalAdministrationWorkforceNews.data.workforceNews,
	};
};

export default connectWithStore(WorkforceNews, mapStateToProps, mapDispatchToProps);
