import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'dumb';

import './confirmationFooter.css';

const ConfirmationFooter = ({
	onConfirmClick,
	onCancelClick,
	confirmButtonLabel,
	cancelButtonLabel,
	valid,
	tabIndex,
	onClose,
}) => {
	return (
		<div className="confirmation-footer">
			<ButtonGroup layout="full-width">
				<Button
					group
					layout="full-width"
					type="negative"
					onClick={onCancelClick || onClose}
					tabIndex={tabIndex}
					dataCy="modal-cancel-button"
				>
					<span>{cancelButtonLabel}</span>
				</Button>
				<Button
					dataCy="modal-footer-default-submit-button"
					group
					state="success"
					layout="full-width"
					type="success"
					disabled={!valid}
					onClick={onConfirmClick}
					tabIndex={tabIndex}
				>
					<span>{confirmButtonLabel}</span>
				</Button>
			</ButtonGroup>
		</div>
	);
};

ConfirmationFooter.defaultProps = {
	confirmButtonLabel: 'Save',
	cancelButtonLabel: 'Cancel',
};

ConfirmationFooter.propTypes = {
	onConfirmClick: PropTypes.func,
	onCancelClick: PropTypes.func,
	confirmButtonLabel: PropTypes.string,
	cancelButtonLabel: PropTypes.string,
	valid: PropTypes.any,
	tabIndex: PropTypes.number,
	onClose: PropTypes.func,
};

export default memo(ConfirmationFooter);
