'use strict';

import ListModel from 'common/components/list/listModel.service';

// Services
var __endpoints = require('endpoints.service');

export default class SizeCategoriesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
		];
		this.settings = {
			name: 'SizeCategoriesList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			translationPath: 'SALE.SIZE_GROUPS',
			endpoint: __endpoints.collection.product.size_groups,
		};
		this.init();
		return this;
	}
}
