export default {
	TITLE: 'WORKPLACE ADDRESS ',
	REQUIRED: 'Required',
	STREET_NAME_AND_NUMBER: 'Street name and number:',
	ZIP_CODE: 'Zip code:',
	CITY: 'City:',
	COUNTRY: 'Country:',
	LATITUDE: 'Latitude:',
	LONGITUDE: 'Longitude',
	SAVE_BUTTON: 'Save',
	ADDRESS_UPDATED: 'Address has been updated!',
	UNKNOWN_ERROR: 'Something went wrong, try again later...',
	LATITUDE_PLACEHOLDER: 'Enter latitude...',
	LONGITUDE_PLACEHOLDER: 'Enter longitude...',
	COUNTRY_PLACEHOLDER: 'Select a country...',
};
