export default {
	TABLE_TITLE: 'Raw ingredient configurations',
	RAW_INGREDIENT_CONFIGURATIONS_ADDED: 'Raw ingredient configurations added',
	RAW_INGREDIENT_CONFIGURATION_EDITED: 'Raw ingredient configuration edited',
	RAW_INGREDIENT_CONFIGURATIONS_EDITED: 'Raw ingredient configurations edited',
	RAW_INGREDIENT_CONFIGURATIONS_DELETED:
		'Raw ingredient configurations deleted',

	// modal batch stuff
	MODAL_BATCH_STEP_LIST_TITLE: 'Add Raw ingredient configurations',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'submit',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row.'
};
