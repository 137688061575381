export default {
	TITLE: 'Groups',
	PRODUCT_VARIANT_GROUP_ADDED: 'Groups added!',
	PRODUCT_VARIANT_GROUP_EDITED: 'Group edited!',
	PRODUCT_VARIANT_GROUPS_EDITED: 'Groups edited!',
	PRODUCT_VARIANT_GROUP_DELETED: 'Group deleted!',
	PRODUCT_VARIANT_GROUP_PRODUCT_BUNDLE: 'Product variants',

	// batch form
	MODAL_BATCH_STEP_LIST_TITLE: 'Create product variant groups',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_SORT_ORDER: 'Sort order',
	MODAL_BATCH_STEP_SORT_ORDER_PLACEHOLDER: 'Enter sort order...',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add Product variant groups',
};
