'use strict';

import React from 'react';

// components
import SalesConfigurationPosConfigurationDiscountProductVariant from './components/salesConfigurationPosConfigurationDiscountProductVariant/salesConfigurationPosConfigurationDiscountProductVariant.component';
import SalesConfigurationPosConfigurationDiscountIngredients from './components/salesConfigurationPosConfigurationDiscountIngredients/salesConfigurationPosConfigurationDiscountIngredients.component';
import SalesConfigurationPosConfigurationDiscounts from './components/salesConfigurationPosConfigurationDiscounts/salesConfigurationPosConfigurationDiscounts.component';

function PricingContainer() {
	return (
		<>
			{/* REMEMBER TO MOVE THIS TABLE TO THE BOTTOM! */}
			<SalesConfigurationPosConfigurationDiscounts />
			<SalesConfigurationPosConfigurationDiscountProductVariant />
			<SalesConfigurationPosConfigurationDiscountIngredients />
		</>
	);
}

export default PricingContainer;
