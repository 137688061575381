'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// rdw actions
import { store } from 'appState';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';

// components
import { ModalStepper, Overlay } from 'dumb';

// tables for batch view
import PosConfigurationVersionTable from './../../../../../pos/components/posConfigurationVersions/posConfigurationVersions.component';
import ProductVariantTable from './../../../../../product/components/productVariant/productVariant.component';

// different batch views
import ProductVariantsPricingBatchStepForm from './components/productVariantsPricingBatchStepForm';
import ProductVariantsPricingBatchStepList from './components/productVariantsPricingBatchStepList';

// phrases
import phrases from './../../posConfigurationProductVariantsPricing.phrases';

// utils
import { getFilter, markValuesWithCanAdd } from './../../utils';

// loooodash
import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';
import _uniqBy from 'lodash/uniqBy';

class SalesConfigurationModalBatch extends Component {
	constructor(props) {
		super(props);

		this.posConfigurationVersionReduxKey =
			'batch-list/pos/pos_configuration_versions';
		this.productVariantReduxKey = 'batch-list/product/product_variants';

		this.state = {
			showOverlay: false,
			overlayType: null,
			loadingModal: false,
			allowOverflow: false,
		};

		this.handleToggleOverlay = this.handleToggleOverlay.bind(this);
		this.onOverlayOpen = this.onOverlayOpen.bind(this);
		this._getIsBatchStepFormValid = this._getIsBatchStepFormValid.bind(this);
		this.resetSelectedRows = this.resetSelectedRows.bind(this);

		this.modalRef = React.createRef();
	}

	handleToggleOverlay(type) {
		const {
			listPosConfigurationVersions,
			listProductVariants,
			updateBatchForm,
		} = this.props;

		if (this.state.showOverlay) {
			if (this.state.overlayType === this.posConfigurationVersionReduxKey) {
				const selectedRowsInList = _get(
					listPosConfigurationVersions,
					'ui.selectedRows',
					[]
				);

				updateBatchForm({
					posConfigurationVersion: selectedRowsInList.map((entry) => {
						return { label: entry.pos_configuration.name, value: entry };
					}),
				});
			} else {
				const selectedRowsInList = _get(
					listProductVariants,
					'ui.selectedRows',
					[]
				);

				updateBatchForm({
					productVariant: selectedRowsInList.map((entry) => {
						return { label: entry.name, value: entry };
					}),
				});
			}
		}

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
			overlayType: prevState.overlayType ? '' : type,
		}));
	}

	onOverlayOpen() {
		const { batchFormData } = this.props;
		const { overlayType } = this.state;

		const dataToSetColumnsSelected =
			overlayType === this.posConfigurationVersionReduxKey
				? batchFormData.posConfigurationVersion
				: batchFormData.productVariant;

		if (_isEmpty(dataToSetColumnsSelected)) return;
		// set already selected data in rdw store as clicked
		dataToSetColumnsSelected.map((entry) => {
			store.dispatch(
				actions.addRowToSelection({
					reduxKey: overlayType,
					data: entry.value,
				})
			);
		});
	}

	_onSubmitForm() {
		const {
			batchFormData,
			addBatchList,
			fetchSalesConfigurationProductVariantPricings,
		} = this.props;

		this.setState(() => ({ loadingModal: true }));

		const cleanPosConfigurationVersions = _uniqBy(
			batchFormData.posConfigurationVersion,
			'value.id'
		);
		const cleanProductVariants = _uniqBy(
			batchFormData.productVariant,
			'value.id'
		);

		const listArray = cleanPosConfigurationVersions.reduce((acc, x) => {
			const list = cleanProductVariants.map((y) => {
				return {
					identifier: _uniqueId('row-'), // used to identify what to edit on step#2
					posConfigurationVersion: x,
					productVariant: y,
					alternativeId: batchFormData.alternativeId,
					toStayPrice: batchFormData.toStayPrice,
					toStayTax: batchFormData.toStayTax,
					toGoPrice: batchFormData.toGoPrice,
					toGoTax: batchFormData.toGoTax,
					deliveryTax: batchFormData.deliveryTax,
					deliveryPrice: batchFormData.deliveryPrice,
					availableInApp: batchFormData.availableInApp,
				};
			});

			return [...acc, ...list];
		}, []);

		const filter = getFilter({
			posConfigurationsVersions: cleanPosConfigurationVersions,
			productVariants: cleanProductVariants,
		});

		fetchSalesConfigurationProductVariantPricings({ filter }).then((res) => {
			this.setState(() => ({ loadingModal: false }));

			const markedListArray = markValuesWithCanAdd({
				listArray,
				data: res.data,
			});

			addBatchList(markedListArray);

			this._goToStep(2);
		});
	}

	_goToStep(step) {
		this.setState(() => ({ allowOverflow: step === 1 }));

		this.modalRef.current.goToStep(step);
	}

	_batchSubmit() {
		const {
			batchList,
			handleClose,
			addSalesConfigurationProductVariantPricing,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchList
			.filter((entry) => entry.canAdd)
			.map((entry) => {
				return {
					pos_configuration_version: _get(
						entry,
						'posConfigurationVersion.value.id',
						null
					),
					product_variant: _get(entry, 'productVariant.value.id', null),
					alternative_id: entry.alternativeId,
					to_stay_price: _get(entry, 'toStayPrice', '').replace(',', '.'),
					to_go_price: _get(entry, 'toGoPrice', '').replace(',', '.'),
					to_stay_tax: _get(entry, 'toStayTax.value', null),
					to_go_tax: _get(entry, 'toGoTax.value', null),
					delivery_tax: _get(entry, 'deliveryTax.value', null),
					delivery_price: entry.deliveryPrice
						? _get(entry, 'deliveryPrice', '').replace(',', '.')
						: null,
					...(entry.availableInApp && {
						available_in_app: entry.availableInApp,
					}),
				};
			});

		addSalesConfigurationProductVariantPricing({ batch: payload })
			.then(() => {
				handleClose();
				this.setState(() => ({
					loadingModal: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	_getIsBatchStepFormValid() {
		const { batchFormData } = this.props;

		const isPosConfigurationVersionEmpty = _isEmpty(
			batchFormData.posConfigurationVersion
		);
		const isProductVariantEmpty = _isEmpty(batchFormData.productVariant);

		if (!isPosConfigurationVersionEmpty && !isProductVariantEmpty) return true;
		else return false;
	}

	resetSelectedRows(reduxKey) {
		const { listProductVariants, listPosConfigurationVersions } = this.props;

		listProductVariants && store.dispatch(actions.resetSelection({ reduxKey }));
		listPosConfigurationVersions &&
			store.dispatch(actions.resetSelection({ reduxKey }));
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchList,
			editBatchListItem,
			removeBatchListItem,
			productVariant,
		} = this.props;
		const { showOverlay } = this.state;

		const steps = [
			{
				component: (
					<ProductVariantsPricingBatchStepForm
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.handleToggleOverlay}
						resetSelectedRows={this.resetSelectedRows}
						posConfigurationVersionReduxKey={
							this.posConfigurationVersionReduxKey
						}
						productVariantReduxKey={this.productVariantReduxKey}
						productVariant={productVariant}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE,
				isValid: this._getIsBatchStepFormValid(),
				onNext: () => this._onSubmitForm(),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
			},
			{
				component: (
					<ProductVariantsPricingBatchStepList
						batchList={batchList}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				onBack: () => this._goToStep(1),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						className="modal-batch"
						ref={this.modalRef}
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={showOverlay ? () => {} : handleClose}
						allowOverflow={this.state.allowOverflow}
					/>
				)}

				<Overlay
					zIndex={551}
					height={850}
					list
					visible={showOverlay}
					onClose={() => {
						this.handleToggleOverlay();
					}}>
					{this.state.overlayType === this.posConfigurationVersionReduxKey && (
						<PosConfigurationVersionTable
							batchSelection
							onInitialization={() => this.onOverlayOpen()}
							reduxKey={this.state.overlayType}
							style={{
								maxHeight: '720px',
							}}
							defaultPageSize={20}
						/>
					)}
					{this.state.overlayType === this.productVariantReduxKey && (
						<ProductVariantTable
							batchSelection
							onInitialization={() => this.onOverlayOpen()}
							reduxKey={this.state.overlayType}
							style={{
								maxHeight: '720px',
							}}
							defaultPageSize={20}
						/>
					)}
				</Overlay>
			</>
		);
	}
}

SalesConfigurationModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	listPosConfigurationVersions: PropTypes.object,
	listProductVariants: PropTypes.object,
	addBatchList: PropTypes.func,
	fetchSalesConfigurationProductVariantPricings: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addSalesConfigurationProductVariantPricing: PropTypes.func,
	productVariant: PropTypes.object,
};

export default SalesConfigurationModalBatch;
