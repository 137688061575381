// Store
import { store } from 'appState';
import * as posConfigurationLayout from '../posConfigurationLayout.actions';

// Services
import { set as setFeedback } from 'feedback.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { fetchCompleteCollection } from 'api/fetchCompleteCollection';

/**
 * getProducts
 * @param versionId
 * @returns {Promise.<TResult>|*|{anyOf}}
 */
export function getProducts(versionId = null) {
	// @TODO MOVE TO NEW addModal

	// Loader
	store.dispatch(posConfigurationLayout.fetching(true));

	const uri = `/product/products`;
	return fetchCompleteCollection(uri, {
		// filter: `:pos_configuration_version.id=='${versionId}'`,
		offset: 0,
		limit: 300
	})
		.then(data => {
			const posConfigurationsVariantsList = data.map(product => {
				// return VariantList
				return {
					label: product.name,
					value: product.id,
					product_category: product.product_category,
					flair: product.flair
				};
			});

			store.dispatch(posConfigurationLayout.fetching(false));
			store.dispatch(
				posConfigurationLayout.setPosConfigurationsVariantsList(
					posConfigurationsVariantsList
				)
			);
			store.dispatch(
				posConfigurationLayout.setFullPosConfigurationsVariantsList(
					posConfigurationsVariantsList
				)
			);
			return posConfigurationsVariantsList;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}
	