'use strict';

import _uniqBy from 'lodash/uniqBy';

import {
	SET_WORKPLACE_WEATHER,
	CLEAR_WORKPLACE_WEATHER
} from '../actions/shiftPlannerWeather.actions';

const defaultState = {
	weather: []
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
	case SET_WORKPLACE_WEATHER: {
		const weather = _uniqBy([...state.weather, ...payload.weather], 'id');
		return {
			...state,
			weather
		};
	}
	case CLEAR_WORKPLACE_WEATHER: {
		return {
			...state,
			weather: {}
		};
	}

	default:
		return state;
	}
}

export default reducer;
