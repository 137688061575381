export const SET_FILTER_DISTANCE = 'SHIFT_MARKETPLACE/SET_FILTER_DISTANCE';
export const SET_FILTERED_WORKPLACES = 'SHIFT_MARKETPLACE/SET_FILTERED_WORKPLACES';
export const SET_SELECTED_WORKPLACE = 'SHIFT_MARKETPLACE/SET_SELECTED_WORKPLACE';
export const SET_NEAREST_WORKPLACES = 'SHIFT_MARKETPLACE/SET_NEAREST_WORKPLACES';
export const RESET_STATE = 'SHIFT_MARKETPLACE/RESET_STATE';

export function setFilterDistance(payload) {
	return {
		type: SET_FILTER_DISTANCE,
		payload,
	};
}

export function setFilteredWorkplaces(payload) {
	return {
		type: SET_FILTERED_WORKPLACES,
		payload,
	};
}

export function setSelectedWorkplace(payload) {
	return {
		type: SET_SELECTED_WORKPLACE,
		payload,
	};
}

export function setNearestWorkplaces(payload) {
	return {
		type: SET_NEAREST_WORKPLACES,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}
