'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';
// utils
import cx from 'classnames';

import {
	Button,
	Input,
	InputCollectionSelect,
	Toggle,
	Legend,
	Tag,
} from 'dumb';

// phrases
import phrases from './../../../posConfigurationProductVariantsPricing.phrases';

// lodash
import _get from 'lodash/get';

class ProductVariantsPricingStepList extends Component {
	constructor(props) {
		super(props);

		this.cantAddBulletColor = 'ffe699';
	}

	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e.target ? e.target.value || e.target.checked : e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: value },
		});
	}

	render() {
		const { batchList } = this.props;

		return (
			<>
				<div className="modal-batch__legend">
					<Legend
						points={[
							{
								bulletColor: `#${this.cantAddBulletColor}`,
								label: phrases.MODAL_BATCH_STEP_LIST_LEGEND_CANT_ADD,
							},
						]}
					/>
				</div>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_POS_CONFIGURATION_VERSION}</th>
							<th>{phrases.MODAL_BATCH_STEP_PRODUCT_VARIANT}</th>
							<th>{phrases.MODAL_BATCH_STEP_ALTERNATIVE_ID}</th>
							<th>{phrases.MODAL_BATCH_STEP_TO_STAY_PRICE}</th>
							<th>{phrases.MODAL_BATCH_STEP_TO_STAY_TAX}</th>
							<th>{phrases.MODAL_BATCH_STEP_TO_GO_PRICE}</th>
							<th>{phrases.MODAL_BATCH_STEP_TO_GO_TAX}</th>
							<th>{phrases.MODAL_BATCH_STEP_DELIVERY_PRICE}</th>
							<th>{phrases.MODAL_BATCH_STEP_DELIVERY_TAX}</th>
							<th>{phrases.MODAL_BATCH_STEP_AVAILABLE_IN_APP}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((entry, i) => {
							const rowDisabled = !entry.canAdd;

							return (
								<TableListRow key={entry.identifier} disabled={rowDisabled}>
									<TableListCell>
										{rowDisabled && (
											<Tag type="bullet" color={this.cantAddBulletColor} />
										)}
										{i + 1}
									</TableListCell>
									<TableListCell>
										{_get(entry, 'posConfigurationVersion.label', '')}
									</TableListCell>
									<TableListCell>
										{_get(entry, 'productVariant.label', '')}
									</TableListCell>

									<TableListCell>
										<Input
											id={`alternativeId-${entry.identifier}`}
											placeholder="Alternative Id"
											type="number"
											value={_get(entry, 'alternativeId', '')}
											onChange={(e) =>
												this._editListEntry(e, entry, 'alternativeId')
											}
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`toStayPrice-${entry.identifier}`}
											type="number"
											value={entry.toStayPrice}
											placeholder="To stay price"
											onChange={(event) =>
												this._editListEntry(event, entry, 'toStayPrice')
											}
											required
										/>
									</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id={`stepListToStayTax-${entry.identifier}`}
											handleChange={(key, value) =>
												this._editListEntry(value, entry, 'toStayTax')
											}
											clearable={false}
											cache
											placeholder="Select To Stay Tax"
											optionFormat={(entry) => ({
												value: entry.id,
												label: `${entry.name} (${entry.rate}%)`,
											})}
											value={entry.toStayTax || undefined}
											apiPath="/administration/market_tax_rates"
											params={{
												limit: 30,
												filter: `(:market.id=='${_get(
													entry,
													'posConfigurationVersion.value.pos_configuration.market.id'
												)}')`,
											}}
											required
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`toGoPrice-${entry.identifier}`}
											type="number"
											value={entry.toGoPrice}
											placeholder="To go price"
											onChange={(event) =>
												this._editListEntry(event, entry, 'toGoPrice')
											}
											required
										/>
									</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id={`batchListToGoTax-${entry.identifier}`}
											handleChange={(key, value) =>
												this._editListEntry(value, entry, 'toGoTax')
											}
											value={entry.toGoTax || null}
											clearable={false}
											cache
											placeholder="Select To Go Tax"
											optionFormat={(entry) => ({
												value: entry.id,
												label: `${entry.name} (${entry.rate}%)`,
											})}
											apiPath="/administration/market_tax_rates"
											params={{
												limit: 30,
												filter: `(:market.id=='${_get(
													entry,
													'posConfigurationVersion.value.pos_configuration.market.id'
												)}')`,
											}}
											inputFilterFormat={(input) =>
												`:name=like='%${input}%',:rate=like='%${input}%',:market.name=like='%${input}%'`
											}
											required
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`delivery-price-${entry.identifier}`}
											type="number"
											placeholder="Enter delivery price..."
											onChange={(event) =>
												this._editListEntry(event, entry, 'deliveryPrice')
											}
											value={entry.deliveryPrice}
										/>
									</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id={`delivery-tax-${entry.identifier}`}
											value={entry.deliveryTax}
											handleChange={(key, value) =>
												this._editListEntry(value, entry, 'deliveryTax')
											}
											clearable={false}
											cache
											placeholder="Select Delivery tax..."
											optionFormat={(entry) => ({
												value: entry.id,
												label: `${entry.name} (${entry.rate}%)`,
											})}
											apiPath="/administration/market_tax_rates"
											params={{
												limit: 30,
											}}
										/>
									</TableListCell>

									<TableListCell>
										<Toggle
											id="available-in-app"
											toggled={entry.availableInApp}
											onClick={(event) =>
												this._editListEntry(event, entry, 'availableInApp')
											}
										/>
									</TableListCell>
									<TableListCell>
										<Button
											type="negative"
											size="tiny"
											onClick={() => this._onRemoveEntry(entry.identifier)}>
											<span className="icon icon--delete" />
										</Button>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
			</>
		);
	}
}

ProductVariantsPricingStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

ProductVariantsPricingStepList.defaultProps = {};

export default ProductVariantsPricingStepList;
