'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	updateBatchForm,
	resetBasketRecommendationPriority,
} from './store/basketRecommendationPriorities.actions';
import { resetSelection } from 'reactDataWrapper/reactDataWrapper.actions';

// services/utils
import {
	fetchBasketRecommendationPriorities,
	editBasketRecommendationPriority,
	addBasketRecommendationPriority,
	deleteBasketRecommendationPriorities,
} from './basketRecommendationPriorities.service';
import { formatReplacementsToArray } from './utils';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Button, Icon } from 'dumb';
import AddEditBasketRecommendationPriorities from './components/batch/addEditBasketRecommendationPriorities';

// columns
import { getTableColumns } from './basketRecommendationPriorities.columns';

// phrases/constants
import phrases from './basketRecommendationPriorities.phrases';

const reduxKey = 'pos/basket_recommendation_priorities';

class BasketRecommendationPriorities extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.addProductBundleWrapper = this.addProductBundleWrapper.bind(this);
		this.editProductBundleWrapper = this.editProductBundleWrapper.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);
		this.getActionRender = this.getActionRender.bind(this);

		this.columns = getTableColumns();
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}>
				Legacy
			</Button>
		);
	}

	deleteEntry(id) {
		return deleteBasketRecommendationPriorities(id);
	}

	setInitialEditValues() {
		const { listData } = this.props;

		const selectedRows = listData?.ui?.selectedRows || [];

		if (!selectedRows) return;

		const payload = {
			id: selectedRows[0].id,
			name: selectedRows[0].name,
			description: selectedRows[0].description,
			sortOrder: selectedRows[0].sort_order,
			type: {
				value: selectedRows[0].type,
				label: selectedRows[0].type,
			},
			legacy: selectedRows.legacy,
			replacements: formatReplacementsToArray(selectedRows[0]),
			editMode: true,
		};

		this.props.updateBatchForm(payload);
		this.toggleModal();
	}

	fetchData(state) {
		return fetchBasketRecommendationPriorities(state);
	}

	toggleModal() {
		if (this.state.showModal) this.props.resetBasketRecommendationPriority();
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
	}

	addProductBundleWrapper(data) {
		return addBasketRecommendationPriority(data).then(() => {
			this.setState(() => ({
				key: moment.utc(),
			}));
			this.props.resetSelection({ reduxKey });
		});
	}

	editProductBundleWrapper(data) {
		return editBasketRecommendationPriority(data).then(() => {
			this.setState(() => ({
				key: moment.utc(),
			}));
			this.props.resetSelection({ reduxKey });
		});
	}

	getActionRender() {
		const { listData } = this.props;

		const selectedRows = listData?.ui?.selectedRows || [];
		const rowSelected = selectedRows.length === 1;

		return (
			<>
				<Button
					type="inverted"
					label={phrases.ADD}
					shadow
					onClick={this.toggleModal}>
					<Icon name="add" />
				</Button>
				{rowSelected && (
					<Button
						type="inverted"
						label={phrases.EDIT}
						shadow
						onClick={this.setInitialEditValues}>
						<Icon name="edit" />
					</Button>
				)}
			</>
		);
	}

	render() {
		const { batchFormData, updateBatchForm } = this.props;

		const legacyFilter = this.state.legacyFilterOn
			? this.state.legacyFilter
			: '';

		return (
			<>
				<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={reduxKey}
					manual
					deleteEntry={this.deleteEntry}
					onModalClose={this.props.resetBasketRecommendationPriority}
					actionRender={this.getActionRender()}
					customAreaComponents={this.getLegacyFilterButton()}
					extraFilters={legacyFilter}
					enableSingleSelection
				/>

				<AddEditBasketRecommendationPriorities
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchFormData={batchFormData}
					updateBatchForm={updateBatchForm}
					addBasketRecommendationPriority={this.addProductBundleWrapper}
					editBasketRecommendationPriority={this.editProductBundleWrapper}
				/>
			</>
		);
	}
}

BasketRecommendationPriorities.propTypes = {
	resetBasketRecommendationPriority: PropTypes.func,
	batchFormData: PropTypes.array,
	updateBatchForm: PropTypes.func,
	resetSelection: PropTypes.func,
	listData: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			resetBasketRecommendationPriority,
			resetSelection,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultBasketRecommendationPriority:
			store.basketRecommendationPriorities.data
				.defaultBasketRecommendationPriority,
		batchFormData: store.basketRecommendationPriorities.data.batchFormData,
		listData: store.listData[reduxKey],
	};
};

export default connectWithStore(
	BasketRecommendationPriorities,
	mapStateToProps,
	mapDispatchToProps
);
