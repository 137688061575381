import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Tools

import DailyReportHeaderBaseModel from './dailyReportHeaderBaseModel.component';
import DailyReportTotal from './dailyReportTotal.component';
import DailyReportBaseModel from './dailyReportBaseModel.component';
// Components
import { Body, Table, Title, Row, constants } from 'dumb/report';
import DailyReportMarketRegion from './dailyReportMarketRegion.component';

const TotalGroupEntries = ({ entries, perspective }) => {
	if (!entries) return null;
	return entries.map(entry => {
		const newEntry = {
			...entry,
			...(entry.group === 'New Store' && {
				month_same_store_sales: {},
				week_same_store_sales: {}
			})
		};

		return (
			<Row key={entry.id} type={constants.TABLE_TYPES.SUB_TOTAL}>
				<DailyReportBaseModel entry={newEntry} reportType={perspective} />
			</Row>
		);
	});
};

class DailyReportFranchise extends Component {
	_renderReport() {
		const {
			reportData,
			totalColSpan,
			expandedMarkets,
			hotbarData,
			expandedGroups,
			forceExpandGroups,
			forceExpandMarkets
		} = this.props;

		return (
			<DailyReportMarketRegion
				totalColSpan={totalColSpan}
				reportData={reportData}
				hotbarData={hotbarData}
				expandedMarkets={expandedMarkets}
				expandedGroups={expandedGroups}
				forceExpandGroups={forceExpandGroups}
				forceExpandMarkets={forceExpandMarkets}
				markets={reportData.markets}
			/>
		);
	}

	render() {
		const {
			title,
			hotbarData,
			totalColSpan,
			reportData,
			subtitle
		} = this.props;

		return (
			<>
				<Title title={title} />
				<Title title={subtitle} rightAligned />
				<Table>
					<DailyReportHeaderBaseModel
						reportType={hotbarData.perspective}
						firstColumnEntry="Markets"
					/>
					<Body>{this._renderReport()}</Body>

					<DailyReportTotal
						hotbarData={hotbarData}
						totalColSpan={totalColSpan}
						reportData={reportData.Total}
					/>
					<Body>
						<TotalGroupEntries
							perspective={this.props.hotbarData.perspective}
							entries={reportData.totalGroups}
						/>
						<TotalGroupEntries
							perspective={this.props.hotbarData.perspective}
							entries={reportData.totalStoreTypeStoreAreaTypeEntry}
						/>
					</Body>
				</Table>
			</>
		);
	}
}

DailyReportFranchise.propTypes = {
	reportData: PropTypes.object,
	hotbarData: PropTypes.object,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	totalColSpan: PropTypes.number,
	expandedMarkets: PropTypes.array,
	expandedGroups: PropTypes.array,
	forceExpandMarkets: PropTypes.bool,
	forceExpandGroups: PropTypes.bool
};

DailyReportFranchise.defaultProps = {
	subtitle: ''
};

export default DailyReportFranchise;
