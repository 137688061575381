'use strict';

import PropTypes from 'prop-types';
import { store, connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import { post } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import { ReactDataWrapper } from 'reactDataWrapper';

import { Input, Button, Icon, Tooltip, ButtonLoader } from 'dumb';
import phrases from './../../../../juicerDetails.phrases';

// third party
import _get from 'lodash/get';

// actions
import { editUsers, setUsers, resetState } from './store/usersTable.actions';
// RDW actions for adding a new user custom way
import * as rdwActions from 'reactDataWrapper/reactDataWrapper.actions';

// api service
import { fetchUsers, editUser, createUser, reset2Fa } from './usersTable.service';

import UserModal from './components/userModal';

/**
 * @function sendActivationCode
 * @description sends a new activation code to the user
 */
export function sendActivationCode(payload) {
	return post('/authentication/user_activation_keys', payload)
		.then(() => {
			setFeedback(phrases.ACTIVATION_CODE_SENT, 1);
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

class UsersTable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			showUserModal: false,
			toggleUser: false,
			reseting2Fa: null,
		};

		this.fetchData = this.fetchData.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.getAddUserButton = this.getAddUserButton.bind(this);
		this.toggleUserModal = this.toggleUserModal.bind(this);
		this.addUser = this.addUser.bind(this);
		this.toggleUser = this.toggleUser.bind(this);
		this.getToggleUserText = this.getToggleUserText.bind(this);

		this.reduxKey = 'juicer-details-/authentication/users';

		this.columns = [
			{
				Header: 'Email',
				id: 'email',
				accessor: (d) => _get(d, 'email', null),
				filterPath: ':email',
			},
			{
				Header: 'Name',
				id: 'name',
				accessor: (d) => _get(d, 'name', null),
				filterPath: ':name',
			},
			{
				Header: 'UserName',
				id: 'userName',
				accessor: (d) => _get(d, 'username', null),
				filterPath: ':username',
			},
			{
				Header: 'Active',

				id: 'active',
				accessor: (d) => _get(d, 'active', null),
				filterPath: ':active',
				filterable: false,
				Cell: (d) => {
					return (
						<Input
							type="checkbox"
							checked={_get(d.original, 'active', false)}
							disabled
						/>
					);
				},
			},
			{
				Header: 'Require 2 factor',
				id: 'two_factor_authentication_required',
				accessor: (d) => _get(d, 'two_factor_authentication_required', null),
				filterPath: ':two_factor_authentication_required',
				filterable: false,
				Cell: (d) => {
					return (
						<Input
							type="checkbox"
							checked={d.original.two_factor_authentication_required}
							disabled
						/>
					);
				},
			},
			{
				Header: 'Beta tester',
				id: 'is_stage_user',
				accessor: (d) => _get(d, 'is_stage_user', null),
				filterPath: ':is_stage_user',
				filterable: false,
				Cell: (d) => {
					return (
						<Input
							type="checkbox"
							checked={d.original.is_stage_user}
							disabled
						/>
					);
				},
			},
		];
	}

	sendActivationCodeWrapper(email) {
		if (!email) return;

		const payload = {
			email,
		};

		this.setState(() => ({ sendingActivationCodeEmail: email }));

		sendActivationCode(payload).then(() =>
			this.setState(() => ({ sendingActivationCodeEmail: null }))
		);
	}

	/**
	 * @function editEntry
	 * @description edits a user
	 */
	editEntry() {
		const { userToEdit } = this.props;
		const userEntryId = userToEdit.id;

		const payload = {
			email: userToEdit.email,
			name: userToEdit.name,
			id: userToEdit.id,
			is_stage_user: userToEdit.isStageUser,
			two_factor_authentication_required:
				userToEdit.twoFactorAuthenticationRequired,
		};

		return editUser(userEntryId, payload);
	}

	/**
	 * @function setInitialEditValues
	 * @param {Object} { email, name, id}
	 * @description sets vacation data in the edit modal
	 */
	setInitialEditValues({
		email,
		name,
		id,
		active,
		is_stage_user,
		two_factor_authentication_required,
	}) {
		this.props.setUsers({
			email,
			name,
			id,
			active,
			isStageUser: is_stage_user,
			twoFactorAuthenticationRequired: two_factor_authentication_required,
		});
	}

	/**
	 * @function fetchData
	 * @param {Object} state
	 * @description get's data for the reactDataWrapper
	 */
	fetchData(state) {
		const { personId } = this.props;

		this.setState(() => ({ loading: true }));

		return fetchUsers(
			{
				offset: state.offset,
				limit: state.limit,
				sort: state.sort,
				filter: state.filter,
			},
			`(:person.id=='${personId}')`
		)
			.then((response) => {
				this.setState(() => ({
					loading: false,
				}));

				return response;
			})
			.catch(() => {
				this.setState(() => ({
					loading: false,
				}));
			});
	}

	getEditableCellsEdit() {
		const { userToEdit } = this.props;

		return [
			{
				header: 'Email',
				value: (
					<Input
						id="email"
						onChange={(event) => this.editUserData('email', event.target.value)}
						value={_get(userToEdit, 'email', '')}
					/>
				),
			},
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						onChange={(event) => this.editUserData('name', event.target.value)}
						value={_get(userToEdit, 'name', '')}
					/>
				),
			},
			{
				header: 'Require 2 factor on login',
				value: (
					<Input
						id="two_factor_authentication_required"
						type="checkbox"
						onChange={(event) =>
							this.editUserData(
								'twoFactorAuthenticationRequired',
								event.target.checked
							)
						}
						checked={userToEdit.twoFactorAuthenticationRequired}
					/>
				),
			},
			{
				header: 'Beta User',
				value: (
					<Input
						id="is_stage_user"
						type="checkbox"
						onChange={(event) =>
							this.editUserData('isStageUser', event.target.checked)
						}
						checked={userToEdit.isStageUser}
					/>
				),
			},
		];
	}

	/**
	 * @function editUserData
	 * @param {String} name - name of the Input
	 * @param {String} e - event
	 */
	editUserData(name, value) {
		const { editUsers, userToEdit } = this.props;

		const payload = {
			...userToEdit,
			[name]: value,
		};

		// set the value in the store
		editUsers(payload);
	}

	reset2Fa(id) {
		this.setState(() => ({
			reseting2Fa: id,
		}));

		const payload = {
			id,
			reset: true,
		};

		reset2Fa(payload).finally(() =>
			this.setState(() => ({ reseting2Fa: null }))
		);
	}

	/**
	 * @function _renderLink
	 * @param {*} e
	 * @description renders a link to the old user details page
	 */
	_renderLink(e) {
		const email = e.original?.email ?? '';
		const id = e.original?.id ?? null;

		const sendingActivationCode =
			this.state.sendingActivationCodeEmail === email;
		const reseting2Fa = this.state.reseting2Fa === id;

		return (
			<>
				<Tooltip
					text="Send activation key"
					placement="left"
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							id="sendActivationKey"
							shadow
							type="inverted"
							size="micro"
							onClick={() => {
								this.sendActivationCodeWrapper(email);
							}}
							disabled={sendingActivationCode}
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}>
							{sendingActivationCode ? (
								<ButtonLoader loading />
							) : (
								<Icon name="vpn_key" />
							)}
						</Button>
					)}
				/>
				<Tooltip
					placement="right"
					text={phrases.USERS_TABLE_ADD_USER_TOGGLE_USER_TOOLTIP}
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
							refProp={ref}
							type="inverted"
							shadow
							title="Toggle user"
							onClick={() => this.toggleUserModal(true, e.original)}
							size="micro">
							<Icon name="flip_to_front" />
						</Button>
					)}
				/>
				<Tooltip
					text={phrases.RESET_2FA}
					placement="left"
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							id="reset-2fa"
							shadow
							type="inverted"
							size="micro"
							onClick={() => {
								this.reset2Fa(id);
							}}
							disabled={reseting2Fa}
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}>
							{reseting2Fa ? <ButtonLoader loading /> : <Icon name="replay" />}
						</Button>
					)}
				/>
			</>
		);
	}

	toggleUserModal(toggleUser = false, userData) {
		this.setState(
			(prevState) => ({
				showUserModal: !prevState.showUserModal,
				toggleUser,
			}),
			() => {
				if (toggleUser) {
					this.setInitialEditValues(userData);
				}
			}
		);
	}

	addUser() {
		const { personData, personId } = this.props;

		const payload = {
			name: _get(personData, 'full_name', ''),
			username: personId,
			email: _get(personData, 'person_email_address.email_address', ''),
			active: true,
			person: personId,
		};

		return createUser(payload)
			.then((res) => {
				// add to rdw list
				store.dispatch(
					rdwActions.addEntry({
						reduxKey: this.reduxKey,
						entry: res.data[0],
					})
				);
				// close the modal
				this.toggleUserModal();
			})
			.catch(() => {
				this.toggleUserModal();
			});
	}

	getAddUserButton() {
		return (
			<Button
				label={phrases.USERS_TABLE_ADD_USER_BUTTON}
				type="inverted"
				shadow
				onClick={() => this.toggleUserModal(false, undefined)}>
				<Icon name="add" />
			</Button>
		);
	}

	toggleUser() {
		const { userToEdit } = this.props;

		this.setState(() => ({ loading: true }));

		const payload = {
			id: userToEdit.id,
			active: !userToEdit.active,
		};

		return editUser(userToEdit.id, payload)
			.then((res) => {
				this.setState(() => ({ loading: false }));

				store.dispatch(
					rdwActions.editEntry({
						reduxKey: this.reduxKey,
						entry: res.data[0],
					})
				);

				this.toggleUserModal();
			})
			.catch(() => {
				this.setState(() => ({ loading: false }));
				this.toggleUserModal();
			});
	}

	getToggleUserText() {
		const { userToEdit } = this.props;

		const userActive = userToEdit.active;

		return userActive
			? phrases.USERS_TABLE_ADD_USER_MODAL_TEXT_TOGGLE_USER_UNACTIVE
			: phrases.USERS_TABLE_ADD_USER_MODAL_TEXT_TOGGLE_USER_ACTIVE;
	}

	render() {
		const { resetState } = this.props;

		return (
			<>
				{this.state.showUserModal && (
					<UserModal
						isOpen={this.state.showUserModal}
						onClose={() => this.toggleUserModal(false)}
						loading={this.state.loading}
						onConfirmClick={
							this.state.toggleUser ? this.toggleUser : this.addUser
						}
						header={
							this.state.toggleUser
								? phrases.USERS_TABLE_ADD_USER_MODAL_HEADER_TOGGLE_USER
								: phrases.USERS_TABLE_ADD_USER_MODAL_HEADER
						}
						content={
							this.state.toggleUser
								? this.getToggleUserText()
								: phrases.USERS_TABLE_ADD_USER_MODAL_TEXT
						}
						confirmButtonLabel={
							this.state.toggleUser
								? phrases.USERS_TABLE_TOGGLE_USER_BUTTON
								: phrases.USERS_TABLE_ADD_USER_BUTTON
						}
					/>
				)}
				<ReactDataWrapper
					filterable={false}
					showPagination={false}
					title={phrases.USERS_TABLE}
					columns={this.columns}
					loading={this.state.loading}
					editEntry={() => this.editEntry()}
					fetchData={this.fetchData}
					accessAreasAllowedToEdit={['Employment Admin', 'Person Admin', 'User Admin']}
					editableCellsEdit={this.getEditableCellsEdit()}
					setInitialEditValues={this.setInitialEditValues}
					defaultPageSize={5}
					reduxKey={this.reduxKey}
					onModalClose={resetState}
					manual
					inRowButtons
					enableMultiSelection={false}
					actions={(d) => this._renderLink(d)}
					actionsWidth={120}
					actionRender={this.getAddUserButton()}
				/>
			</>
		);
	}
}

UsersTable.defaultProps = {
	columns: [],
};

UsersTable.propTypes = {
	editUsers: PropTypes.func,
	setUsers: PropTypes.func,
	userToEdit: PropTypes.object,
	personId: PropTypes.number,
	resetState: PropTypes.func,
	personData: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			editUsers,
			setUsers,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		userToEdit: store.juicerUsersTable.userToEdit,
	};
};

export default connectWithStore(
	UsersTable,
	mapStateToProps,
	mapDispatchToProps
);
