/**
 * @function _extractGridData
 * @memberOf PosConfigurationLayoutService
 * @description Extract length of grid based on
 * @param result
 * @param entries
 * @returns {*}
 * @ja
 */
export function extractGridData(result = [], entries = []) {
	const gridData = result.map(key => {
		const gridMetaData = {
			name: entries.tile_layouts[key].name,
			description: entries.tile_layouts[key].description
		};

		const gridDimensions = [
			entries.tile_layouts[key].rows,
			entries.tile_layouts[key].columns
		];

		const gridSize = this._getSizeOfGrid(gridDimensions);
		const grid = this._constructEmptyGrid();

		return { gridSize, gridMetaData, grid, gridDimensions };
	});

	return gridData[0];
}

export function extractGridDataNew(layoutData) {
	const gridMetaData = {
		name: layoutData.name,
		description: layoutData.description
	};

	const gridDimensions = [layoutData.rows, layoutData.columns];

	const gridSize = _getSizeOfGrid(gridDimensions);
	const grid = _constructEmptyGrid();

	return { gridSize, gridMetaData, grid, gridDimensions };
}

/**
 * @description Extract length of grid based on grid ([row, column])
 * @function _getSizeOfLayout
 * @memberOf COMPONENTS.PosConfigurationLayout
 * @param grid
 * @returns {*}
 * @private
 */
export function _getSizeOfGrid(grid = []) {
	return grid[0] * grid[1];
}

/**
 * Construct empty associative array with each entry being equal to the gridsize
 * @param gridSize : 50 is maximum allowed by api
 * @returns {*}
 * @private
 */
export function _constructEmptyGrid(gridSize = 50) {
	const tileBaseModel = {
		product: 'number',
		type: 'string',
		tile_layout_sub_group: 'number',
		name: 'string',
		description: 'string',
		position: 'number',
		title_color: 'string',
		background_color: 'string'
	};

	return [...new Array(gridSize)].reduce((acc, i, x) => {
		acc[x + 1] = {
			...tileBaseModel,
			position: x + 1
		};
		return acc;
	}, {});
}
