import { store } from 'appState';
import * as actions from './../employeeMeals.actions';
import { get } from 'api.vanilla.service';
var __endpoints = require('endpoints.service');

export default function fetchEmployeeMealsReportPrWorkplace(
	{ endDate, startDate, reportingCurrencyId } = {},
	workplace = {}
) {
	const employeeMealsReportEndpoint = `${
		__endpoints.collection.reporting.employee_meals_reports.index.path
	}/markets/${workplace.marketId}/workplaces/${
		workplace.id
	}/workplace_table?from=${startDate.format('YYYY-MM-DD')}&to=${endDate.format(
		'YYYY-MM-DD'
	)}&reporting_currency=${reportingCurrencyId}&type=Entries`;

	return get(employeeMealsReportEndpoint).then(response => {
		store.dispatch(actions.setEmployeeMealsReportPrWorkplace(response.data));
	});
}
