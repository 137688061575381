'use strict';

import { get, remove, post } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './../vacationsIllness.phrases';

function _getEndpoint(customEndpoint) {
	return customEndpoint || '/shiftplanning/vacations';
}

/**
 * @function fetchVacations
 * @description Fetch vacations
 * @param {Object} Object with limit, offset, filter, headers and sort
 * @param {String} initialFilter
 * @returns Promise
 */
export function fetchVacations(
	{ limit, offset, filter, sort, headers },
	personId,
	customEndpoint
) {
	filter = filter
		? `${filter};:person.id=='${personId}'`
		: `:person.id=='${personId}'`;

	// Set params and filter
	const params = {
		limit,
		sort,
		offset,
		filter,
	};

	const endpoint = _getEndpoint(customEndpoint);

	return get(endpoint, params, null, headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
		});
}

/**
 * @function createVacation
 * @description Creates a vacation
 * @param {Object} payload person, period, subject & description (both optional)
 * @returns Promise
 */
export function createVacation(payload, customEndpoint) {
	const endpoint = _getEndpoint(customEndpoint);

	return post(endpoint, payload)
		.then((res) => {
			setFeedback(phrases.VACATION_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
		});
}

/**
 * @function editVacation
 * @description Edits a vacation
 * @param {number} vacationId with id
 * @param {Object} payload - vacation id, person id, period, subject & description (both optional)
 * @returns Promise
 */
export function editVacation(payload, customEndpoint) {
	const endpoint = _getEndpoint(customEndpoint);

	return post(`${endpoint}/${payload.id}`, payload)
		.then((res) => {
			setFeedback(phrases.VACATION_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
		});
}

/**
 * @function deleteVacation
 * @description Delete Training
 * @param {Object} Object with id
 * @returns Promise
 */
export function deleteVacation(vacationId, customEndpoint) {
	const endpoint = _getEndpoint(customEndpoint);

	return remove(`${endpoint}/${vacationId}`)
		.then((res) => {
			setFeedback(phrases.VACATION_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
			throw err;
		});
}
