'use strict';

import {
	RESET_STATE,
	SET_INGREDIENT_ASSOCIATION_WITH_PRODUCTS,
	UPDATE_INGREDIENT_ASSOCIATION_WITH_PRODUCTS
} from './ingredientAssociationWithProducts.actions';

const defaultState = {
	data: {
		ingredientAssociationWithProduct: {}
	}
};

function reducer(state = defaultState, action) {
	const { payload, type } = action;

	switch (type) {
	case SET_INGREDIENT_ASSOCIATION_WITH_PRODUCTS: {
		return {
			...state,
			data: {
				...state.data,
				ingredientAssociationWithProduct: payload
			}
		};
	}

	case UPDATE_INGREDIENT_ASSOCIATION_WITH_PRODUCTS: {
		return {
			...state,
			data: {
				...state.data,
				ingredientAssociationWithProduct: {
					...state.data.ingredientAssociationWithProduct,
					...payload
				}
			}
		};
	}

	case RESET_STATE: {
		return defaultState;
	}

	default:
		// never return default state in default case, Filip!!!
		return state;
	}
}

export default reducer;
