'use strict';

module.exports = {
	detailsActions: function() {
		var actions = [];
		return actions;
	},
	settings: {
		canCreate: false,
		canEdit: false,
		canFillIfNull: false,
		canDelete: false,
		callbacks: []
	},
	state: {
		data: {},
		headers: [],
		isCreating: false,
		isEditing: false,
		item: {},
		itemId: NaN,
		params: {},
		methods: []
	}
};