'use strict';

import { ReportFactory as R } from 'report/factory';
import PosReportBaseColumnsService from './posReportBaseColumns.service';

const t = R.table();

const HourlyView = t.table([
	t.column('Hours',
		t.contentWrapper({
			body: [t.content(d => `${R.data(d, 'hour')}-${R.data(d, 'hour')+1}`)],
		}), PosReportBaseColumnsService.getFirstColumnWidth()
	),
	...PosReportBaseColumnsService.generate()
]);

HourlyView.setHeader(false);
HourlyView.setTotal(false);

export default HourlyView;