import LogRocket from 'logrocket';
import { trackWithLogRocket } from 'tracking';

import { isProduction } from 'environment';

/*
 * @function addUsersnap
 * @description Add usersnap and append logrocket session url to snap
 */
export function addUsersnapService() {
	if (isProduction()) {
		window.onUsersnapCXLoad = function (api) {
			api.init();
			window.Usersnap = api;

			api.on('open', function (event) {
				LogRocket.getSessionURL((sessionsUrl) => {
					console.info('logrocket session url -', sessionsUrl);
					trackWithLogRocket('Usersnap Sent');
					event.api.setValue(
						'customData',
						JSON.stringify({
							logRocketSessionUrl: sessionsUrl,
						})
					);
				});
			});
		};

		const script = document.createElement('script');
		script.defer = 1;
		script.src = `https://widget.usersnap.com/load/0db43bd4-b058-4f25-9927-9b36fc86c34f?onload=onUsersnapCXLoad`;
		document.getElementsByTagName('head')[0].appendChild(script);
	}
}

export function openUsersnap() {
	const userSnap = window.Usersnap;
	if (userSnap) userSnap.open();
}
