import React from 'react';
import PropTypes from 'prop-types';
import './subtitle.css';

const Subtitle = ({ subtitle }) => {
	return <h4 className="report-subtitle">{subtitle}</h4>;
};

Subtitle.defaultProps = {
	subtitle: ''
};

Subtitle.propTypes = {
	subtitle: PropTypes.string.isRequired
};

export default Subtitle;
