export const RESET_SALARY_GROUPS = 'SALARY_GROUP/RESET_SALARY_GROUPS';
export const SET_SALARY_GROUPS = 'SALARY_GROUP/SET_SALARY_GROUPS';
export const UPDATE_SALARY_GROUPS = 'SALARY_GROUP/UPDATE_SALARY_GROUPS';
export const UPDATE_BATCH = 'SALARY_GROUP/UPDATE_BATCH';
export const RESET_BATCH = 'SALARY_GROUP/RESET_BATCH';
export const BATCH_LIST_ADD = 'SALARY_GROUP/BATCH_LIST_ADD';
export const BATCH_LIST_EDIT_ITEM = 'SALARY_GROUP/BATCH_LIST_EDIT_ITEM';
export const BATCH_LIST_REMOVE_ITEM = 'SALARY_GROUP/BATCH_LIST_REMOVE_ITEM';

/**
 * @param {object} payload
 */
export function setSalaryGroups(payload) {
	return {
		type: SET_SALARY_GROUPS,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateSalaryGroups(payload) {
	return {
		type: UPDATE_SALARY_GROUPS,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET_SALARY_GROUPS
	};
}

/**
 * @param {object} payload
 */
export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH,
		payload
	};
}

/**
 * @param {object} payload
 */
export function addBatchList(payload) {
	return {
		type: BATCH_LIST_ADD,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetBatch() {
	return {
		type: RESET_BATCH
	};
}

/**
 * @param {object} payload
 */
export function editBatchListItem(payload) {
	return {
		type: BATCH_LIST_EDIT_ITEM,
		payload
	};
}

/**
 * @param {string} payload ID
 */
export function removeBatchListItem(payload) {
	return {
		type: BATCH_LIST_REMOVE_ITEM,
		payload
	};
}
