'use strict';
import moment from 'moment';
import {
	SET_TERMINATION_DATE,
	SET_TERMINATION_REASON,
	SET_ASSIGNED_SHIFTS,
	SET_TERMINATION_REASONS,
	RESET_STATE,
	SET_EMPLOYMENT_DOCUMENT,
	TOGGLE_MODAL_VISIBLE,
	SET_DEFAULT_EMPLOYMENT_DATA,
	RESET_TERMINATE_THROUGH_TENANT_TRANSFER_DATA,
} from './terminateEmployee.actions';

const defaultState = {
	ui: {
		modalVisible: false,
		defaultEmployment: null,
	},
	data: {
		date: moment.utc(),
		selectedReason: null,
		shifts: [],
		reasons: [],
		resignationDocument: null,
		otherDocument: null,
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	switch (type) {
		case TOGGLE_MODAL_VISIBLE:
			return {
				...state,
				ui: {
					...state.ui,
					modalVisible: !state.ui.modalVisible,
				},
			};

		case SET_DEFAULT_EMPLOYMENT_DATA:
			return {
				...state,
				ui: {
					...state.ui,
					defaultEmployment: payload,
				},
			};

		case SET_TERMINATION_DATE:
			return { ...state, data: { ...state.data, date: payload } };

		case SET_TERMINATION_REASON:
			return { ...state, data: { ...state.data, selectedReason: payload } };

		case SET_ASSIGNED_SHIFTS: {
			const shifts = payload;
			return { ...state, data: { ...state.data, shifts } };
		}

		case SET_TERMINATION_REASONS: {
			const reasons = payload.map((reason) => {
				return { value: reason.id, label: reason.description };
			});

			return { ...state, data: { ...state.data, reasons } };
		}

		case RESET_STATE:
			return { ...defaultState, ui: state.ui };

		case RESET_TERMINATE_THROUGH_TENANT_TRANSFER_DATA:
			return { ...state, ui: defaultState.ui };

		case SET_EMPLOYMENT_DOCUMENT: {
			return {
				...state,
				data: {
					...state.data,
					[payload.name]: {
						filename: payload.filename,
						data: payload.data,
						type: payload.type,
					},
				},
			};
		}

		default:
			return state;
	}
}

export default reducer;
