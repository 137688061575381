import React from 'react';
import PropTypes from 'prop-types';

// components
import { ModalRow, InputCollectionSelect } from 'dumb';

// phrases
import phrases from './../../../assetLeftAligned.phrases';

const AssignAssetCollection = (props) => {
	const { defaultAssetCollection, updateDefaultAssetCollection } = props;

	const editStoreEntry = (value, name) => {
		const payload = {
			[name]: value,
		};

		updateDefaultAssetCollection(payload);
	};

	return (
		<ModalRow>
			<InputCollectionSelect
				id="assetCollection"
				label={phrases.MODAL_ASSET_COLLECTION}
				placeholder={phrases.MODAL_ASSET_COLLECTION_PLACEHOLDER}
				value={defaultAssetCollection.assetCollection}
				handleChange={(key, value) => editStoreEntry(value, 'assetCollection')}
				clearable={false}
				cache
				apiPath="/pos/asset_collections"
				params={{
					limit: 30,
				}}
				optionFormat={(entry) => ({
					value: entry.id,
					label: entry.name,
				})}
				inputFilterFormat={(input) => `:name=like='%${input}%'`}
			/>
		</ModalRow>
	);
};

AssignAssetCollection.propTypes = {
	defaultAssetCollection: PropTypes.object,
	updateDefaultAssetCollection: PropTypes.func,
};

export default AssignAssetCollection;
