import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './ingredientComponents.phrases';

function getUrl(useGlobalResource) {
	return useGlobalResource ? '/product/global_ingredient_components' : '/product/ingredient_components';
}

/**
 * @function fetchIngredientComponents
 * @description Fetch all ingredient components
 * @param {object} state
 */
export function fetchIngredientComponents({ state, filter }, useGlobalResource) {
	if (state.filter) filter = `${filter};${state.filter}`;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	const url = getUrl(useGlobalResource);

	return get(url, params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addIngredientComponents
 * @description adds ingredient components
 */
export function addIngredientComponents(data, useGlobalResource) {
	const url = getUrl(useGlobalResource);

	return post(url, data)
		.then((res) => {
			setFeedback(phrases.INGREDIENT_COMPONENTS_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editIngredientComponent
 * @description edits ingredient component
 */
export function editIngredientComponent(data, useGlobalResource) {
	const url = getUrl(useGlobalResource);

	return post(`${url}/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.INGREDIENT_COMPONENT_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editIngredientComponents
 * @description edits ingredient components
 */
export function editIngredientComponents(data, useGlobalResource) {
	const url = getUrl(useGlobalResource);

	return patch(url, data)
		.then((res) => {
			setFeedback(phrases.INGREDIENT_COMPONENTS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteIngredientComponents
 * @description deletes ingredient components
 */
export function deleteIngredientComponents(productId, useGlobalResource) {
	const url = getUrl(useGlobalResource);

	return remove(`${url}/${productId}`)
		.then((res) => {
			setFeedback(phrases.INGREDIENT_COMPONENTS_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
