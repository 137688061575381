export default {
	TITLE: 'Product variants',
	PRODUCT_VARIANT_GROUP_PRODUCT_VARIANT_ADDED: 'Product variants added!',
	PRODUCT_VARIANT_GROUP_PRODUCT_VARIANT_EDITED: 'Product variant edited!',
	PRODUCT_VARIANT_GROUP_PRODUCT_VARIANTS_EDITED: 'Product variants edited!',
	PRODUCT_VARIANT_GROUP_PRODUCT_VARIANT_DELETED: 'Product variant deleted!',

	// batch form
	MODAL_BATCH_STEP_FORM_TITLE: 'Select product variants',
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'Next',
	MODAL_BATCH_STEP_LIST_TITLE: 'Create product variant group product variants',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_PRODUCT_VARIANT: 'Product variant',
	MODAL_BATCH_STEP_PRODUCT_VARIANT_PLACEHOLDER: 'Select product variant...',
	MODAL_BATCH_STEP_LIST_CANCEL_BUTTON_LABEL: 'Back',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add Product variants',
};
