export const RESET_STATE = 'PRODUCT_TRANSLATIONS/RESET_STATE';
export const SET_PRODUCT_TRANSLATION =
	'PRODUCT_TRANSLATIONS/SET_PRODUCT_TRANSLATION';
export const UPDATE_PRODUCT_TRANSLATION =
	'PRODUCT_TRANSLATIONS/UPDATE_PRODUCT_TRANSLATION';

// batch
export const UPDATE_BATCH_FORM = 'PRODUCT_TRANSLATIONS/UPDATE_BATCH_FORM';
export const EDIT_BATCH_LIST_ITEM = 'PRODUCT_TRANSLATIONS/EDIT_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST = 'PRODUCT_TRANSLATIONS/ADD_BATCH_LIST';
export const REMOVE_BATCH_LIST_ITEM =
	'PRODUCT_TRANSLATIONS/REMOVE_BATCH_LIST_ITEM';

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function setProductTranslation(payload) {
	return {
		type: SET_PRODUCT_TRANSLATION,
		payload,
	};
}

export function updateProductTranslation(payload) {
	return {
		type: UPDATE_PRODUCT_TRANSLATION,
		payload,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}
