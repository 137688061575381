'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	resetState,
	updateBatchForm,
	addBatchList,
	editBatchListItem,
	removeBatchListItem,
	setProductBundleSetProductVariants,
	updateProductBundleSetProductVariants,
	setPosConfigurationdefaultProductBundleSetsDateFilter,
} from './store/productBundleSetProductVariants.actions';

import { ReactDataWrapper } from 'reactDataWrapper';

// components
import { Button, Icon } from 'dumb';

import ProductBundleSetsProductVariantModalBatch from './components/batch/productBundleSetProductVariantsModalBatch';

import _get from 'lodash/get';

import {
	fetchProductBundleSetProductVariants,
	fetchProductVariants,
	deleteProductBundleSetProductVariants,
	addProductBundleSetProductVariants,
} from './productBundleSetProductVariants.service';

import phrases from './productBundleSetProductVariants.phrases';

class ProductBundleSetProductVariants extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
		};

		this.toggleModal = this.toggleModal.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.batchAddData = this.batchAddData.bind(this);

		this.reduxKey = '/product/bundle_set_product_variant';

		this.columns = [
			{
				Header: 'Product variant',
				id: 'productVariant',
				accessor: (d) => _get(d, 'product_variant.name', ''),
				filterPath: ':product_variant.name',
			},
		];
	}

	deleteEntry(id) {
		return deleteProductBundleSetProductVariants(id);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	fetchData(state) {
		const { productBundleSet } = this.props;

		const productBundleId = productBundleSet.id;
		const filter = `:bundle_set.id=='${productBundleId}'`;

		return fetchProductBundleSetProductVariants(filter, state);
	}

	batchAddData(data) {
		return addProductBundleSetProductVariants(data).then(() => {
			this.setState(() => ({ key: moment.utc() }));
		});
	}

	render() {
		const {
			updateBatchForm,
			batchFormData,
			listProductVariants,
			addBatchList,
			batchList,
			editBatchListItem,
			removeBatchListItem,
			resetState,
			productBundleSet,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					accessAreasAllowedToEdit={['Global BI Configuration', 'Global HR Configuration', 'Sales Configuration']}
					key={this.state.key}
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={`${this.reduxKey}-${productBundleSet.id}`}
					manual
					onModalClose={resetState}
					deleteEntry={this.deleteEntry}
					deleteConfirm
					actionRender={
						<Button type="inverted" label="Batch" shadow onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
				/>

				<ProductBundleSetsProductVariantModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchFormData={batchFormData}
					updateBatchForm={updateBatchForm}
					fetchProductVariants={fetchProductVariants}
					listProductVariants={listProductVariants}
					addBatchList={addBatchList}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					batchAddData={this.batchAddData}
					productBundleSet={productBundleSet}
				/>
			</>
		);
	}
}

ProductBundleSetProductVariants.propTypes = {
	listProductVariants: PropTypes.object,
	resetState: PropTypes.func,
	updateBatchForm: PropTypes.func,
	addBatchList: PropTypes.func,
	batchFormData: PropTypes.object,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	productBundleSet: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			resetState,
			updateBatchForm,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			setProductBundleSetProductVariants,
			updateProductBundleSetProductVariants,
			setPosConfigurationdefaultProductBundleSetsDateFilter,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		batchFormData: store.salesConfigurationProductBundleSetProductVariants.data.batchFormData,
		batchList: store.salesConfigurationProductBundleSetProductVariants.data.batchList,
		posConfigurationdefaultProductBundleSetsDateFilter:
			store.salesConfigurationProductBundleSetProductVariants.posConfigurationdefaultProductBundleSetsDateFilter,
		listProductVariants: store.listData['/product/product_variants'],
		defaultProductBundleSetProductVariant:
			store.salesConfigurationProductBundleSetProductVariants.data.defaultProductBundleSetProductVariant,
	};
};

export default connectWithStore(ProductBundleSetProductVariants, mapStateToProps, mapDispatchToProps);
