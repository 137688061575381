export const SET_ADDON_RECOMMENDATION =
	'SALES_CONFIGURATION_ADDON_RECOMMENDATIONS/SET_ADDON_RECOMMENDATION';
export const UPDATE_ADDON_RECOMMENDATION =
	'SALES_CONFIGURATION_ADDON_RECOMMENDATIONS/UPDATE_ADDON_RECOMMENDATION';
export const RESET_STATE =
	'SALES_CONFIGURATION_ADDON_RECOMMENDATIONS/RESET_STATE';

/**
 * @param {object} payload
 */
export function setProductAddonRecommendation(payload) {
	return {
		type: SET_ADDON_RECOMMENDATION,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateProductAddonRecommendation(payload) {
	return {
		type: UPDATE_ADDON_RECOMMENDATION,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET_STATE
	};
}
