import _get from 'lodash/get';

export default function getColumns() {
	return [
		{
			Header: 'Language',
			id: 'language',
			accessor: (d) => _get(d, 'language.name'),
			filterPath: ':language.name',
		},
		{
			Header: 'Name',
			id: 'name',
			accessor: 'name',
			filterPath: ':name',
		},
		{
			Header: 'Description',
			id: 'description',
			accessor: 'description',
			filterPath: ':description',
		},
	];
}
