'use strict';

import { TOGGLE_WORKPLACE_ADMINISTRATION, RESET_REVIEWS } from './shiftPlannerWorkplaceAdministration.actions';

const defaultState = {
	ui: {
		showWorkplaceAdministration: false
	},
	data: {

	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;
	
	switch (type) {
	case TOGGLE_WORKPLACE_ADMINISTRATION: {
		return {
			...state,
			ui:{
				showWorkplaceAdministration: !state.ui.showWorkplaceAdministration
			}
		};
	}

	case RESET_REVIEWS: {
		return {
			...state,
			reviews: defaultState.reviews
		};
	}

	default:
		return state;
	}
}

export default reducer;
