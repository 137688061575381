import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { ModalStepper } from 'dumb';
import ShiftTransferApprovalMobileViewPage from './components/shiftTransferApprovalMobileViewPage/shiftTransferApprovalMobileViewPage';
import ShiftTransferPendingTransfersMobileViewPage from './components/shiftTransferPendingTransfersMobileViewPage/shiftTransferPendingTransfersMobileViewPage';

// utils
import phrases from './shiftTransferApprovalMobileView.phrases';
import {
	getApplicationsReadyToSubmit,
	getIfAnyApplicationInteracted,
	getConfirmButtonLabel,
	getSwapApplicationToSubmit,
	getOperationlessApplicationDecisions,
} from './utils';
import { ShiftTransfer as enums } from 'services/enums';

// style
import './shiftTransferApprovalMobileView.css';

class ShiftTransferApprovalMobileView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};

		this._goToStep = this._goToStep.bind(this);
		this._goStepBack = this._goStepBack.bind(this);
		this._conditionallySubmitApplications = this._conditionallySubmitApplications.bind(
			this
		);
		this._resetApplicationDecisions = this._resetApplicationDecisions.bind(
			this
		);
		this._updatePendingApplicationWrapper = this._updatePendingApplicationWrapper.bind(
			this
		);
		this._getTransferSteps = this._getTransferSteps.bind(this);
		this._getPendingTransferSteps = this._getPendingTransferSteps.bind(this);
		this.updateShiftTransferWrapper = this.updateShiftTransferWrapper.bind(
			this
		);
		this.updateShiftTransferApplicationWrapper = this.updateShiftTransferApplicationWrapper.bind(
			this
		);

		this.modalRef = React.createRef();
	}

	_goToStep({ index, lastStep }) {
		if (lastStep) this.props.onClose();

		this.modalRef.current.goToStep(index + 2);
	}

	_goStepBack(index) {
		this.modalRef.current.goToStep(index);
	}

	_resetApplicationDecisions({ shiftTransfer }) {
		const { setShiftTransferApplication } = this.props;

		// reset application decisions back to default
		const applicationDecisions = getOperationlessApplicationDecisions({
			applications: shiftTransfer.applications,
		});

		setShiftTransferApplication(applicationDecisions);
	}

	_updatePendingApplicationWrapper(data) {
		const { handleUpdateShiftTransferApplication } = this.props;

		if (data.operation === enums.OPERATIONS.DECLINE)
			return handleUpdateShiftTransferApplication(data);
		else {
			handleUpdateShiftTransferApplication(data);
			return Promise.resolve(true);
		}
	}

	_conditionallySubmitApplications({
		index,
		shiftTransfer,
		lastStep,
		ownTransfer,
	}) {
		const {
			applicationDecisions,
			updateShiftTransfer,
			handleUpdateShiftTransferApplication,
		} = this.props;

		const anyApplicationInteracted = getIfAnyApplicationInteracted({
			applicationDecisions,
		});

		// if we haven't approved or declined any of the applications, no need to submit anything
		// so just go to the next step/application
		if (!anyApplicationInteracted) {
			this._goToStep({ index, lastStep });
			return;
		}

		this.setState(() => ({ loadingModal: true }));

		let arrayOfFunctions;
		if (ownTransfer) {
			const data = getSwapApplicationToSubmit({
				shiftTransfer,
				applicationDecisions,
			});

			arrayOfFunctions = [handleUpdateShiftTransferApplication(data)];
		} else {
			// if any of the applications interacted, submit them to the api
			const data = getApplicationsReadyToSubmit({
				shiftTransfer,
				applicationDecisions,
			});

			arrayOfFunctions = data.map((entry) => {
				return updateShiftTransfer(entry);
			});
		}

		Promise.all(arrayOfFunctions)
			.then(() => {
				this._resetApplicationDecisions({ shiftTransfer });
			})
			.finally(() => {
				this.setState(() => ({ loadingModal: false }));
			});
	}

	_getTransferSteps() {
		const {
			shiftTransfers,
			swapTransfers,
			applicationDecisions,
			setShiftTransferApplication,
		} = this.props;

		const combinedTransfers = [...swapTransfers, ...shiftTransfers];
		return combinedTransfers.map((entry, index) => {
			// check whether index belongs to swap transfers
			const ownTransfer = index < swapTransfers.length;
			const lastStep = combinedTransfers.length - 1 === index;
			const title = ownTransfer
				? phrases.SHIFT_TRANSFERS
				: phrases.BAR_TRANSFERS;

			const onNext = () =>
				this._conditionallySubmitApplications({
					index,
					shiftTransfer: entry,
					lastStep,
					ownTransfer,
				});
			const anyApplicationInteracted = getIfAnyApplicationInteracted({
				applicationDecisions,
			});
			const confirmButtonLabel = getConfirmButtonLabel({
				lastStep,
				anyApplicationInteracted,
			});

			return {
				component: (
					<ShiftTransferApprovalMobileViewPage
						key={entry.id}
						shiftTransfer={entry}
						setShiftTransferApplication={setShiftTransferApplication}
						applicationDecisions={applicationDecisions}
						ownTransfer={ownTransfer}
					/>
				),
				title,
				isValid: true,
				onNext,
				...(index > 0 && {
					onBack: () => this._goStepBack(index),
					cancelButtonLabel: phrases.BACK,
				}),
				loading: this.state.loadingModal,
				confirmButtonLabel,
				noScroll: false,
			};
		});
	}

	_getPendingTransferSteps() {
		const { pendingTransfers, user } = this.props;

		const personId = user?.user?.person?.id;
		return pendingTransfers.map((entry, index) => {
			const ownTransfer = entry.donor?.person?.id === personId;
			const lastStep = pendingTransfers.length - 1 === index;
			const title = ownTransfer
				? phrases.YOUR_TRANSFERS
				: phrases.APPLIED_SHIFTS;
			const confirmButtonLabel = lastStep ? phrases.CLOSE : phrases.NEXT;

			const onNext = () => this._goToStep({ index, lastStep });

			return {
				component: (
					<ShiftTransferPendingTransfersMobileViewPage
						key={entry.id}
						shiftTransfer={entry}
						ownTransfer={ownTransfer}
						personId={personId}
						updateShiftTransfer={this.updateShiftTransferWrapper}
						updateShiftTransferApplicationWrapper={
							this.updateShiftTransferApplicationWrapper
						}
					/>
				),
				title,
				isValid: true,
				onNext,
				...(index > 0 && {
					onBack: () => this._goStepBack(index),
					cancelButtonLabel: phrases.BACK,
				}),
				loading: this.state.loadingModal,
				confirmButtonLabel,
				noScroll: false,
			};
		});
	}

	updateShiftTransferWrapper(payload) {
		const { updateShiftTransfer } = this.props;

		this.setState(() => ({ loadingModal: true }));

		updateShiftTransfer(payload).finally(() =>
			this.setState(() => ({ loadingModal: false }))
		);
	}

	updateShiftTransferApplicationWrapper(payload) {
		const { handleUpdateShiftTransferApplication } = this.props;

		payload = {
			...payload,
			applied: true,
		};

		// if declining, set loaders
		if (payload.operation === enums.OPERATIONS.DECLINE) {
			this.setState(() => ({ loadingModal: true }));

			handleUpdateShiftTransferApplication(payload).finally(() =>
				this.setState(() => ({ loadingModal: false }))
			);
		}
		// if not declining we will open a policy modal and therefor handleUpdateShiftTransferApplication doesn't return a promise
		else handleUpdateShiftTransferApplication(payload);
	}

	render() {
		const { onClose, showPendingTransfersModal } = this.props;

		const steps = showPendingTransfersModal
			? this._getPendingTransferSteps()
			: this._getTransferSteps();

		return (
			<ModalStepper
				ref={this.modalRef}
				isOpen
				steps={steps}
				onClose={onClose}
				showStep
			/>
		);
	}
}

ShiftTransferApprovalMobileView.propTypes = {
	shiftTransfers: PropTypes.array,
	swapTransfers: PropTypes.array,
	updateShiftTransfer: PropTypes.func,
	onClose: PropTypes.func,
	setShiftTransferApplication: PropTypes.func,
	applicationDecisions: PropTypes.array,
	handleUpdateShiftTransferApplication: PropTypes.func,
	pendingTransfers: PropTypes.array,
	user: PropTypes.object,
	showPendingTransfersModal: PropTypes.bool,
};

export default ShiftTransferApprovalMobileView;
