'use strict';
import { store } from 'appState';

import { setTableExpanded, setTableContent } from 'report/report.actions';

// import { TableContentResultSet } from 'report/resultSets';

/**
 * Table
 * @param  {array} content
 */
class Table {
	constructor(
		content,
		title = null,
		tableType = 'default',
		total = true,
		header = true,
		className = '',
		wrapperClassName = ''
	) {
		// Set type
		this.type = 'table';

		// Set variant options default
		this.setTitle(title);
		this.setTableType(tableType);
		this.setTotal(total);
		this.setHeader(header);
		this.setClassName(className);
		this.setWrapperClassName(wrapperClassName);
		this.setDataProvider(null);

		/// //////////
		// Content //
		/// //////////

		// Check content for correct type

		this.children = content.reduce((acc, value) => {
			if (acc.indexOf(value.type) === -1) acc = [...acc, value.type];
			return acc;
		}, []);

		this.content = content;
	}

	/// //////////
	// Setters //
	/// //////////

	/**
	 * @function setLazySubTableKey
	 * @memberOf Table
	 * @description Setter for lazySubTableKey
	 */
	setLazySubTableKey(lazySubTableKey) {
		this.lazySubTableKey = lazySubTableKey;
	}

	/**
	 * @function setDataProvider
	 * @memberOf Table
	 * @description Setter for dataProvider
	 */
	setDataProvider(dataProvider) {
		this.dataProvider = dataProvider;
	}

	/**
	 * @function setCustomComponents
	 * @memberOf Table
	 * @description
	 * Setter for custom component
	 */
	setCustomComponents(components) {
		this.customComponents = components;
	}

	/**
	 * @function setTitle
	 * @memberOf Table
	 * @description
	 * Setter for title - if table needs a title to be shown
	 */
	setTitle(title) {
		this.title = title;
	}

	/**
	 * @function setTableType
	 * @memberOf Table
	 * @description
	 * Setter for tableType - decisive for which table type
	 */
	setTableType(tableType) {
		this.tableType = tableType;
	}

	/**
	 * @function setTotal
	 * @memberOf Table
	 * @description
	 * Setter for total - where (if) to render
	 */
	setTotal(total) {
		this.total = total;
	}

	/**
	 * @function setHeader
	 * @memberOf Table
	 * @description
	 * Setter for header - render or not
	 */
	setHeader(header) {
		this.header = header;
	}

	/**
	 * @function setClassName
	 * @memberOf Table
	 * @description
	 * Setter for classname
	 */
	setClassName(className) {
		this.className = className;
	}

	/**
	 * @function setWrapperClassName
	 * @memberOf Table
	 * @description Setter for wrapperClassName
	 */
	setWrapperClassName(wrapperClassName) {
		this.wrapperClassName = wrapperClassName;
	}

	/// //////////
	// Getters //
	/// //////////

	/**
	 * @function getChildrenType
	 * @memberOf Table
	 * @description
	 * Returns type of children
	 */
	getChildrenType() {
		return this.children[0];
	}

	/**
	 * @function getWidth
	 * @memberOf Table
	 * @description
	 * Returns width of table based on specified column widths
	 */
	getWidth(data) {
		switch (this.getChildrenType()) {
		case 'group':
			return this.content.reduce(
				(groupAcc, groupValue) =>
					groupAcc +
						groupValue.content.reduce(
							(columnAcc, columnValue) =>
								columnAcc + columnValue.getWidth(data),
							0
						),
				0
			);
		case 'column':
			return this.content.reduce(
				(acc, value) => acc + value.getWidth(data),
				0
			);
		}
	}

	/**
	 * @function getTitle
	 * @memberOf Table
	 * @description
	 * Returns title
	 */
	getTitle(data) {
		if (this.title === null) return;
		else return this.title.getTemplate(data);
	}

	/**
	 * @function getMetaTitle
	 * @memberOf Table
	 * @description
	 * Returns title
	 */
	getMetaTitle(data) {
		if (this.title === null || this.title.getMetaTemplate(data) === null)
			return;
		else return this.title.getMetaTemplate(data);
	}

	/**
	 * @function getDataProvider
	 * @memberOf Table
	 * @description Getter for dataProvider
	 */
	getDataProvider() {
		return this.dataProvider;
	}

	/**
	 * @function getLazySubTableKey
	 * @memberOf Table
	 * @description Getter for lazySubTableKey
	 */
	getLazySubTableKey() {
		return this.lazySubTableKey;
	}

	/// /////////////////
	// Event handlers //
	/// /////////////////

	/**
	 * @function handleTableToggleExpand
	 * @memberOf Table
	 * @description Handler for when a table is toggled
	 * @param {string} reportName unique identifier
	 * @param {string} tableName unique identifier
	 * @param {boolean} expanded
	 */
	handleTableToggleExpand(reportName, tableName, expanded) {
		store.dispatch(
			setTableExpanded(
				reportName,
				tableName,
				typeof expanded === 'boolean' ? !expanded : true
			)
		);
	}

	/**
	 * @function handleGetLazyContent
	 * @memberOf Table
	 * @description Handler for lazyloading content
	 */
	handleGetLazyContent(reportName, tableName) {
		// Handle change in report context
		return (
			this.dataProvider
				.getResultSet()

				// Dispatch report resultSet
				.then(resultSet => {
					store.dispatch(
						setTableContent(reportName, tableName, resultSet.getFirst().content)
					);
					return Promise.resolve(resultSet.getFirst());
				})

				// Error handling
				.catch(error => ErrorHandlerService.throw(error))
		);
	}

	/// ////////
	// Other //
	/// ////////

	enableLazySubTable(tableKey, dataProvider) {
		this.setDataProvider(dataProvider);
		this.setLazySubTableKey(tableKey);
	}
}

export default Table;
