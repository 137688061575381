import React from 'react';

import { FileUpload } from 'dumb';

// lodash
import _get from 'lodash/get';

export default function getColumns({
	loadingTrList,
	editUploadImage,
	assetCollectionId,
}) {
	return [
		{
			Header: 'Name',
			id: 'name',
			accessor: (d) => _get(d, 'name', ''),
			filterPath: ':name',
		},
		{
			Header: 'Product',
			id: 'prod',
			accessor: (d) => _get(d, 'product.name', ''),
			filterPath: ':product.name',
		},

		{
			Header: 'Size',
			id: 'size',
			accessor: (d) => _get(d, 'size.name', ''),
			filterPath: ':size.name',
		},
		{
			Header: 'Bundle',
			id: 'bundle',
			accessor: (d) => _get(d, 'bundle.name', ''),
			filterPath: ':bundle.name',
		},
		{
			Header: 'Fuel',
			id: 'fuel',
			accessor: (d) => _get(d, 'fuel', ''),
			filterPath: ':fuel',
		},
		{
			Header: 'Points',
			id: 'points',
			accessor: (d) => _get(d, 'points.points', ''),
			filterPath: ':points.points',
			filterable: false,
			sortable: false,
		},
		{
			Header: 'Image',
			id: 'image',
			accessor: (d) => _get(d, 'image.filename', ''),
			filterPath: ':image.filename',
			filterable: false,
			sortable: false,
			Cell: (d) => {
				const url = _get(d, 'original.image.url', false);
				let file = null;
				if (url) {
					file = {
						url,
						filename: _get(d, 'original.image.filename', ''),
					};
				}
				return (
					<FileUpload
						onChange={(file) => editUploadImage('image', file, d)}
						file={file}
						thumbnail
						disabled={loadingTrList.some((x) => x.row === d.viewIndex)}
						loading={loadingTrList.some(
							(x) => x.row === d.viewIndex && x.id === d.column.id
						)}
					/>
				);
			},
		},
		...(assetCollectionId
			? []
			: [
					{
						Header: 'Asset Collection',
						id: 'asset_collection',
						accessor: (d) => _get(d, 'asset_collection.name', null),
						filterPath: ':asset_collection.name',
					},
			  ]),
		{
			Header: 'Legacy',
			id: 'legacy',
			accessor: (d) => _get(d, 'legacy', ''),
			filterPath: ':legacy',
			Cell: (d) => {
				return (
					<input
						id="legacy"
						type="checkbox"
						checked={_get(d, 'original.legacy', false)}
						disabled
					/>
				);
			},
		},
		{
			Header: 'Available for delivery',
			id: 'availableForDelivery',
			accessor: 'available_for_delivery',
			filterPath: ':available_for_delivery',
			Cell: (d) => {
				return (
					<input
						id="availableForDelivery"
						type="checkbox"
						checked={_get(d, 'original.available_for_delivery', false)}
						disabled
					/>
				);
			},
		},
	];
}
