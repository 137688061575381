'use strict';

import PropTypes from 'prop-types';
import cx from 'classnames';
import _get from 'lodash/get';
import React from 'react';

import RowEntry from './row.entry.component';
import RowSubtable from './row.subtable.component';

const BodyFoldable = props => {
	const {
		component,
		expandedTables,
		report,
		tables,
		table,
		lazyContent
	} = props;

	/**
	 * Parses body from table content and layout object
	 */
	const parseBody = table.content.map((row, key) => {
		let uniqueRowKey = `${_get(
			row,
			'meta.data.unique_identifier',
			component.lazySubTableKey + key
		)}_Entry`;
		let uniqueTableKey = `${_get(
			row,
			'meta.data.unique_identifier',
			component.lazySubTableKey + key
		)}_Table`;

		return [
			<RowEntry
				key={uniqueRowKey}
				tables={tables}
				activeInteractionClassName={{
					'report-old__entry--foldable--unfolded':
						expandedTables[uniqueTableKey]
				}}
				context="body"
				data={lazyContent || !row.total ? row.data : row.total.data}
				meta={row}
				interaction={component.handleTableToggleExpand.bind(
					this,
					report.name,
					uniqueTableKey,
					expandedTables[uniqueTableKey]
				)}
				component={component}
				modifierClassNames={cx({ 'report-old__entry--odd': key % 2 === 0 })}
			/>,
			expandedTables[uniqueTableKey] && (
				<RowSubtable
					key={uniqueTableKey}
					activeInteractionClassName={{
						'report-old__subtable--foldable--unfolded':
							expandedTables[uniqueTableKey]
					}}
					data={row}
					{...props}
				/>
			)
		];
	});

	return <tbody>{parseBody}</tbody>;
};

BodyFoldable.propTypes = {
	expandedTables: PropTypes.object.isRequired,
	report: PropTypes.object.isRequired,
	table: PropTypes.object.isRequired,
	tables: PropTypes.object.isRequired
};

export default BodyFoldable;
