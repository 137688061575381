'use strict';

import {
	SET_AUTHENTICATION_AREAS,
	SET_AUTHENTICATION_ROLES,
	SET_ROLE_AREAS,
	SET_SEARCH_KEYWORD,
	ADD_ROLE_AREA,
	REMOVE_ROLE_AREA,
	EDIT_AUTHENTICATION_ROLE_MODAL_DATA,
	CLEAR_AUTHENTICATION_ROLE_MODAL_DATA,
	DELETE_AUTHENTICATION_ROLE,
	SET_AUTHENTICATION_ROLE_MODAL_DATA,
	EDIT_AUTHENTICATION_ROLE,
	RESET_DATA,
	RESET_USER,
	SET_USER,
	SET_USER_DATA,
} from './accessControlAdministration.actions';

const defaultState = {
	authenticationAreas: [],
	authenticationRoles: [],
	roleAreas: [],
	searchKeyword: '',
	authenticationRoleModalData: {},
	userLookup: {
		userData: null,
		user: null,
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_AUTHENTICATION_AREAS:
			return {
				...state,
				authenticationAreas: payload,
			};

		case SET_AUTHENTICATION_ROLES:
			return {
				...state,
				authenticationRoles: [...state.authenticationRoles, ...payload],
			};

		case SET_ROLE_AREAS:
			return {
				...state,
				roleAreas: payload,
			};

		case ADD_ROLE_AREA:
			return {
				...state,
				roleAreas: [...state.roleAreas, payload],
			};

		case REMOVE_ROLE_AREA:
			return {
				...state,
				roleAreas: state.roleAreas.filter((entry) => entry.id !== payload),
			};

		case SET_SEARCH_KEYWORD:
			return {
				...state,
				searchKeyword: payload,
			};

		case EDIT_AUTHENTICATION_ROLE_MODAL_DATA:
			return {
				...state,
				authenticationRoleModalData: {
					...state.authenticationRoleModalData,
					...payload,
				},
			};

		case CLEAR_AUTHENTICATION_ROLE_MODAL_DATA:
			return {
				...state,
				authenticationRoleModalData: defaultState.authenticationRoleModalData,
			};

		case DELETE_AUTHENTICATION_ROLE:
			return {
				...state,
				authenticationRoles: state.authenticationRoles.filter(
					(entry) => entry.id !== payload.id
				),
			};

		case SET_AUTHENTICATION_ROLE_MODAL_DATA:
			return {
				...state,
				authenticationRoleModalData: payload,
			};

		case EDIT_AUTHENTICATION_ROLE:
			return {
				...state,
				authenticationRoles: state.authenticationRoles.map((entry) => {
					if (entry.id === payload.id) return payload;
					return entry;
				}),
			};

		case RESET_DATA:
			return defaultState;

		case RESET_USER:
			return {
				...state,
				userLookup: defaultState.userLookup,
			};

		case SET_USER:
			return {
				...state,
				userLookup: {
					...state.userLookup,
					user: payload,
				},
			};

		case SET_USER_DATA:
			return {
				...state,
				userLookup: {
					...state.userLookup,
					userData: payload,
				},
			};

		default:
			return state;
	}
}

export default reducer;
