export default {
	INGREDIENT_CONFIGURATION_INGREDIENTS_TABLE_TITLE:
		'Ingredient Configuration Ingredients',
	INGREDIENT_CONFIGURATION_INGREDIENT_ADDED:
		'Ingredient configuration ingredient added!',
	INGREDIENT_CONFIGURATION_INGREDIENT_EDITED:
		'Ingredient configuration ingredient edited!',
	INGREDIENT_CONFIGURATION_INGREDIENTS_EDITED:
		'Ingredient configuration ingredients edited!',
	INGREDIENT_CONFIGURATION_INGREDIENT_DELETED:
		'Ingredient configuration ingredient deleted!',
	INGREDIENT_CONFIGURATION_INGREDIENTS_MODAL_HEADER_INGREDIENT: 'Ingredient',
	INGREDIENT_CONFIGURATION_INGREDIENTS_MODAL_HEADER_AVAILABLE_IN_APP:
		'Available in app',
	INGREDIENTS_TABLE_TITLE: 'Ingredients',

	// batch
	MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL: 'Next',
	MODAL_BATCH_STEP_FORM_TITLE: 'Select ingredients',
	MODAL_BATCH_STEP_LIST_TITLE: 'Confirm entries',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Submit',
	MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL: 'Back',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
};
