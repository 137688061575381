'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// rdw actions
import { store } from 'appState';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';
import { batch } from 'react-redux';

// components
import { ModalStepper, Overlay } from 'dumb';

// different batch views
import WorkplacePosConfigurationBatchStepListDuplicateAsNew from './components/workplacePosConfigurationBatchStepListDuplicateAsNew';

// table components for overlay
import PosConfigurationsTable from './../../../../../pos/components/posConfigurations/posConfigurations.component';
import ProductRecommendationsTable from './../../../../../product/components/productRecommendations/productRecommendations.component';
import IngredientConfigurationsTable from './../../../../../ingredients/components/ingredientConfigurations/ingredientConfigurations.component';
import TileLayoutsTable from './../../../../../layout/tileLayouts/tileLayout.component';

// phrases, constants
import phrases from './../../workplacePosConfiguration.phrases';
import constants from 'services/constants';

// utils
import { handleToggleOverlayHelper } from './utils';

// loooodash
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

class WorkplacePosConfigurationModalBatchDuplicateAsNew extends Component {
	constructor(props) {
		super(props);

		this.today = moment.utc().format(constants.shortDate);

		this.posConfigurationReduxKey =
			'workplace-pos-configuration-batch-/pos/pos_configurations';
		this.productRecommendationReduxKey =
			'workplace-pos-configuration-batch-/pos/product_recommendations';
		this.posLayoutReduxKey =
			'workplace-pos-configuration-batch-pos-/pos/tile_layouts';
		this.appLayoutReduxKey =
			'workplace-pos-configuration-batch-app-/pos/tile_layouts';
		this.ingredientConfigurationReduxKey =
			'workplace-pos-configuration-batch-/pos/ingredient_configurations';

		this.state = {
			showOverlay: false,
			overlayType: null,
			activeFilterOn: true,
			activeFilter: `:active.to=ge='${this.today}'`,
			allowOverflow: true,
			legacyFilterOn: true,
			legacyFilter: ':legacy==false',
			workplaceFilterOn: true,
			workplaceFilter: `:closed=ge='${this.today}'`,
		};

		this.handleToggleOverlay = this.handleToggleOverlay.bind(this);
		this.onOverlayOpen = this.onOverlayOpen.bind(this);
		this.updateBatchFormWrapper = this.updateBatchFormWrapper.bind(this);

		this.modalRef = React.createRef();
	}

	handleToggleOverlay(type) {
		const {
			listPosConfigurations,
			listProductRecommendations,
			listPosTileLayout,
			listAppTileLayout,
			listIngredientConfigurations,
		} = this.props;

		if (this.state.showOverlay) {
			const data = handleToggleOverlayHelper({
				overlayType: this.state.overlayType,
				posConfigurationReduxKey: this.posConfigurationReduxKey,
				listPosConfigurations,
				productRecommendationReduxKey: this.productRecommendationReduxKey,
				listProductRecommendations,
				ingredientConfigurationReduxKey: this.ingredientConfigurationReduxKey,
				listIngredientConfigurations,
				posLayoutReduxKey: this.posLayoutReduxKey,
				listPosTileLayout,
				appLayoutReduxKey: this.appLayoutReduxKey,
				listAppTileLayout,
			});

			if (!_isEmpty(data)) this.updateBatchFormWrapper(data);
		}

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
			overlayType: prevState.overlayType ? '' : type,
		}));
	}

	onOverlayOpen() {
		const { batchFormData } = this.props;

		let dataToSetColumnsSelected;
		switch (this.state.overlayType) {
			case this.posConfigurationReduxKey:
				dataToSetColumnsSelected = batchFormData.posConfiguration;
				break;
			case this.productRecommendationReduxKey:
				dataToSetColumnsSelected = batchFormData.productRecommendation;
				break;
			case this.ingredientConfigurationReduxKey:
				dataToSetColumnsSelected = batchFormData.ingredientConfiguration;
				break;
			case this.appLayoutReduxKey:
				dataToSetColumnsSelected = batchFormData.appTileLayout;
				break;
			case this.posLayoutReduxKey:
				dataToSetColumnsSelected = batchFormData.posTileLayout;
				break;
			default:
				break;
		}

		if (_isEmpty(dataToSetColumnsSelected)) return;

		// set already selected data in rdw store as clicked
		store.dispatch(
			actions.addRowToSelection({
				reduxKey: this.state.overlayType,
				data: dataToSetColumnsSelected.value,
			})
		);
	}

	_batchSubmit() {
		const {
			batchListData,
			handleClose,
			duplicateWorkplaceToPosConfigurationRelation,
		} = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchListData.map((entry) => {
			return {
				id: entry.identifier,
				pos_configuration: _get(entry, 'posConfiguration.value.id', null),
				workplace: _get(entry, 'workplace.value.id', null),
				...(entry.productRecommendation && {
					product_recommendation: _get(
						entry,
						'productRecommendation.value.id',
						null
					),
				}),
				...(entry.ingredientConfiguration && {
					ingredient_configuration: _get(
						entry,
						'ingredientConfiguration.value.id',
						null
					),
				}),
				...(entry.posTileLayout && {
					pos_tile_layout: _get(entry, 'posTileLayout.value.id', null),
				}),
				...(entry.appTileLayout && {
					app_tile_layout: _get(entry, 'appTileLayout.value.id', null),
				}),
				active: {
					from: moment
						.utc(_get(entry, 'activeFrom', null))
						.format(constants.shortDate),
					to: moment
						.utc(_get(entry, 'activeTo', null))
						.format(constants.shortDate),
				},
			};
		});

		duplicateWorkplaceToPosConfigurationRelation({ batch: payload })
			.then(() => {
				handleClose();
				this.setState(() => ({
					loadingModal: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	updateBatchFormWrapper(data) {
		const { updateBatchForm, editBatchListItems } = this.props;

		batch(() => {
			editBatchListItems(data);
			updateBatchForm(data);
		});
	}

	_submitPosConfigurations() {
		const { batchFormData, updateBatchForm } = this.props;

		// get all market id's to filter workplaces on
		const marketsFilter = `:market.id=='${_get(
			batchFormData,
			'posConfiguration.value.market.id',
			null
		)}'`;

		updateBatchForm({
			marketsFilter,
		});

		this._goToStep(2);
	}

	render() {
		const {
			modalVisible,
			batchFormData,
			handleClose,
			batchListData,
			editBatchListItem,
			removeBatchListItem,
			revertBatchListItem,
		} = this.props;

		const steps = [
			{
				component: (
					<WorkplacePosConfigurationBatchStepListDuplicateAsNew
						batchFormData={batchFormData}
						updateBatchForm={this.updateBatchFormWrapper}
						batchListData={batchListData}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
						posConfigurationReduxKey={this.posConfigurationReduxKey}
						ingredientConfigurationReduxKey={
							this.ingredientConfigurationReduxKey
						}
						productRecommendationReduxKey={this.productRecommendationReduxKey}
						appLayoutReduxKey={this.appLayoutReduxKey}
						posLayoutReduxKey={this.posLayoutReduxKey}
						toggleOverlay={this.handleToggleOverlay}
						revertBatchListItem={revertBatchListItem}
						loading={this.state.loadingModal}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_DUPLICATE_AS_NEW_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						ref={this.modalRef}
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={this.state.showOverlay ? () => {} : handleClose}
						allowOverflow={false}
					/>
				)}

				<Overlay
					zIndex={551}
					height={850}
					list
					visible={this.state.showOverlay}
					onClose={() => {
						this.handleToggleOverlay();
					}}>
					{this.state.overlayType === this.posConfigurationReduxKey && (
						<PosConfigurationsTable
							batchSelection
							reduxKey={this.state.overlayType}
							onInitialization={this.onOverlayOpen}
							defaultPageSize={20}
							style={{
								maxHeight: '720px',
							}}
							enableSingleSelection
						/>
					)}
					{this.state.overlayType === this.productRecommendationReduxKey && (
						<ProductRecommendationsTable
							batchSelection
							reduxKey={this.state.overlayType}
							onInitialization={this.onOverlayOpen}
							defaultPageSize={20}
							style={{
								maxHeight: '720px',
							}}
							enableSingleSelection
						/>
					)}
					{this.state.overlayType === this.ingredientConfigurationReduxKey && (
						<IngredientConfigurationsTable
							batchSelection
							reduxKey={this.state.overlayType}
							onInitialization={this.onOverlayOpen}
							defaultPageSize={20}
							style={{
								maxHeight: '720px',
							}}
							enableSingleSelection
						/>
					)}
					{this.state.overlayType === this.appLayoutReduxKey && (
						<TileLayoutsTable
							batchSelection
							reduxKey={this.state.overlayType}
							onInitialization={this.onOverlayOpen}
							defaultPageSize={20}
							style={{
								maxHeight: '720px',
							}}
							enableSingleSelection
							type="App"
						/>
					)}
					{this.state.overlayType === this.posLayoutReduxKey && (
						<TileLayoutsTable
							batchSelection
							reduxKey={this.state.overlayType}
							onInitialization={this.onOverlayOpen}
							defaultPageSize={20}
							style={{
								maxHeight: '720px',
							}}
							enableSingleSelection
							type="POS"
						/>
					)}
				</Overlay>
			</>
		);
	}
}

WorkplacePosConfigurationModalBatchDuplicateAsNew.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	listPosConfigurations: PropTypes.object,
	listProductRecommendations: PropTypes.object,
	listPosTileLayout: PropTypes.object,
	listAppTileLayout: PropTypes.object,
	listIngredientConfigurations: PropTypes.object,
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	duplicateWorkplaceToPosConfigurationRelation: PropTypes.func,
	editBatchListItems: PropTypes.func,
	revertBatchListItem: PropTypes.func,
};

export default WorkplacePosConfigurationModalBatchDuplicateAsNew;
