'use strict';
// Services
import React from 'react';
import ImageCell from 'imageCell';
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');

export default class TrainingModuleGradeLevelsList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'image',
				type: 'imageCell',
				canSort: false,
				canFilter: false,
				imageCell(row) {
					return (
						<ImageCell
							id={row.id}
							image={row.badge_level.image}
							objectKey={'image'}
							endpoint={'/training/module_grade_levels'}
						/>
					);
				},
			},
			{
				name: 'miniature',
				type: 'imageCell',
				canSort: false,
				canFilter: false,
				imageCell(row) {
					return (
						<ImageCell
							id={row.id}
							image={row.badge_level.miniature}
							objectKey={'miniature'}
							endpoint={'/training/module_grade_levels'}
						/>
					);
				},
			},
			{
				name: 'badge_level',
				type: 'number',
				canAdd: true,
				canEdit: true,
				required: true,
				reference: {
					key: 'name',
					list: '/training/grade_levels',
				},
			},
		];

		this.settings = {
			name: 'TrainingGradeScales',
			canAdd: false,
			canEdit: false,
			scrollWrapper: false,
			alwaysRefresh: true,
			canDelete: true,
			translationPath: 'TRAINING.MODULE_GRADE_LEVELS',
			canManipulate: () => validAccess(undefined, ['Global BI Configuration']),

			endpoint: __endpoints.collection.training.module_grade_levels,
		};
		this.init();
		return this;
	}
}
