import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import constants from './dailyReport.constants';

import { connectWithStore, store } from 'appState';
import * as actions from './dailyReport.actions';
import { batch } from 'react-redux';

// Components
import { Button, Loader, ButtonLoader } from 'dumb';
import Hotdamnbar from 'hotdamnbar';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';

import DailyReportReportContainerHotbar from './dailyReport.hotbar';
import DailyReportComponent from './dailyReport.component';
import MailToComponent from 'mailToComponent/mailTo.component';
import PrintService from 'printView/printView.service';

// Tools
import { fetchDailyReport, fetchDailyReportEmailRecipients } from './services';

// Other
import phrases from './dailyReport.phrases';
import './dailyReport.css';

const changeCase = require('change-case');

const emailBody = `
Best regards,

Business Intelligence

JOE & THE JUICE
Østergade 26
1100 København K
`;

const reportSizes = {
	[constants.OPERATION_EXPANDED]: { width: 1530, height: 12000 },
	[constants.OPERATION_COLLAPSED]: { width: 1530, height: 2600 },
};

class DailyReportReportContainer extends PureComponent {
	constructor(props) {
		super(props);
		this.phrases = phrases;

		this.totalColSpan = 38;

		this.printId = 'dailyReport';
		this.title = 'daily Report';

		this.state = {
			loadingPdf: false,
			loadingCsv: false,
			files: null,
			reportWidth: reportSizes[constants.OPERATION_EXPANDED].width,
			showMailComponent: false,
		};

		this.printOptions = {
			id: this.printId,
			landscape: true,
			title: this.title,
			margin: '0',
			padding: '0',
		};

		this._printToPDF = this._printToPDF.bind(this);
		this._printToCsv = this._printToCsv.bind(this);
		this._toggleShowMailComponent = this._toggleShowMailComponent.bind(this);
		this._renderMailToComponent = this._renderMailToComponent.bind(this);
		this.onHotbarChange = this.onHotbarChange.bind(this);
		this.handleFetchDailyReport = this.handleFetchDailyReport.bind(this);

		this.PrintService = new PrintService(this.printOptions);
	}

	/**
	 * @function _printToCsv
	 * @description Download data as csv
	 */
	_printToCsv() {
		this.setState(() => ({
			loadingCsv: true,
		}));
		fetchDailyReport(this.props.hotbarData, {
			Accept: 'application/csv',
		}).then(() => {
			this.setState(() => ({
				loadingCsv: false,
			}));
		});
	}

	/**
	 * @function _printToCsv
	 * @description Download data as csv
	 * @memberof DailyReportReportContainer
	 */
	_printToPDF() {
		this.setState(() => ({
			loadingPdf: true,
		}));
		this._renderReportAsPdf({
			perspective: this.props.hotbarData.perspective,
			forceExpandMarkets:
				this.props.hotbarData.perspective === constants.OPERATION_EXPANDED,
			forceExpandGroups:
				this.props.hotbarData.perspective === constants.OPERATION_EXPANDED,
			saveAsFile: true,
		}).then(() => {
			this.setState(() => ({
				loadingPdf: false,
			}));
		});
	}

	/**
	 * @function onHotbarChange
	 * @param {Object} event
	 * @memberof DailyReportReportContainer
	 */
	onHotbarChange(event) {
		// Initiate loading and reset report
		store.dispatch(actions.cleanData());
		store.dispatch(actions.setLoadingReport(true));

		const date = event.results.Date.getDate();
		const perspective = event.results.Perspective.results[0].value;
		const expanded = perspective === constants.OPERATION_EXPANDED;
		const reportingCurrencyId = event.results['Reporting Currency'].results
			? event.results['Reporting Currency'].results[0].value
			: null;

		const hotbarData = {
			date,
			reportingCurrencyId,
			perspective,
		};

		store.dispatch(actions.setHotbarData(hotbarData));

		const reportWidth = expanded
			? reportSizes[constants.OPERATION_EXPANDED].width
			: reportSizes[constants.OPERATION_COLLAPSED].width;

		this.setState(() => ({
			reportWidth,
		}));
		// Treat hotbar changes
		this.handleFetchDailyReport(hotbarData, expanded);
	}

	/**
	 * @function handleFetchDailyReport
	 * @description Handler for fetching daily report
	 * @param {Object} hotbarData
	 * @memberof DailyReportReportContainer
	 */
	handleFetchDailyReport(hotbarData, expand) {
		fetchDailyReport(hotbarData)
			.then(() => {
				batch(() => {
					store.dispatch(actions.setLoadingReport(false));
					store.dispatch(actions.toggleExpandAllMarkets(expand));
					store.dispatch(actions.toggleExpandAllGroups(expand));
				});
			})
			.catch(() => {
				store.dispatch(actions.setLoadingReport(false));
			});
	}

	/**
	 *
	 * @function _renderReportAsPdf
	 * @private
	 * @description Returns reports as pdf. It shadow renders the report and pushed it to docraptor
	 * @param {Object} { perspective, forceExpandMarkets, forceExpandGroups, saveAsFile }
	 * @returns Promise
	 * @memberof DailyReportReportContainer
	 */
	_renderReportAsPdf({
		perspective,
		forceExpandMarkets,
		forceExpandGroups,
		saveAsFile,
	}) {
		const hotbarData = {
			...this.props.hotbarData,
			perspective,
		};

		const content = (
			<div id={this.printId}>
				{this._renderReport(hotbarData, forceExpandMarkets, forceExpandGroups)}
			</div>
		);

		// Set title of pdf. Only relevant if saved as a pdf file
		const title = `daily_report_${changeCase.snakeCase(
			perspective
		)}_${hotbarData.date.format('DD-MM-YYYY')}`;

		this.PrintService.setOptions({
			...this.printOptions,
			margin: '0',
			padding: '0',
			title,
			size: reportSizes[perspective],
		});

		if (saveAsFile) return this.PrintService.printToPdf(content);
		else return this.PrintService.returnPDFAsBase64(content);
	}

	/**
	 *
	 * @function _toggleShowMailComponent
	 * @description When the mailToComponent is toggled, we start fetching recipients and start returning the report(s) as base64 pdf to be send to the mail service
	 * @param {*} showMailComponent
	 * @returns
	 * @memberof DailyReportReportContainer
	 */
	_toggleShowMailComponent(showMailComponent) {
		if (!showMailComponent) return;

		fetchDailyReportEmailRecipients().then((recipients) => {
			store.dispatch(actions.setDailyReportEmailRecipients(recipients));
			this.setState(() => ({
				showMailComponent,
			}));
		});

		// const promiseMarket = this._renderReportAsPdf({
		// 	perspective: constants.OPERATION_EXPANDED,
		// 	forceExpandMarkets: false,
		// 	forceExpandGroups: false,
		// });

		const promiseStores = this._renderReportAsPdf({
			perspective: constants.OPERATION_EXPANDED,
			forceExpandMarkets: true,
			forceExpandGroups: true,
		});

		Promise.all([promiseStores]).then((data) => {
			const [expandedFile] = data;

			const files = {
				'Daily Report - Expanded.pdf': expandedFile.split(',')[1],
				// 'Daily Report - collapsed.pdf': collapsedFile.split(',')[1],
			};

			this.setState(() => ({
				showMailComponent,
				files,
			}));
		});
	}

	/**
	 * @function _renderReport
	 * @private
	 * @param {Object} [printHotbarData=null]
	 *
	 *
	 * @returns
	 * @memberof DailyReportReportContainer
	 */
	_renderReport(
		printHotbarData = null,
		forceExpandMarkets = false,
		forceExpandGroups = false
	) {
		const {
			hotbarData,
			expandedMarkets,
			expandedGroups,
			dailyReport,
			franchiseReport,
		} = this.props;

		if (_isEmpty(franchiseReport) && _isEmpty(dailyReport))
			return <div>{phrases.NO_DATA_IN_SELECTED_PERIOD}</div>;

		return (
			<DailyReportComponent
				franchiseReport={franchiseReport}
				dailyReport={dailyReport}
				expandedMarkets={expandedMarkets}
				expandedGroups={expandedGroups}
				forceExpandGroups={forceExpandGroups}
				forceExpandMarkets={forceExpandMarkets}
				totalColSpan={this.totalColSpan}
				hotbarData={printHotbarData !== null ? printHotbarData : hotbarData}
				reportWidth={this.state.reportWidth}
				phrases={this.phrases}
			/>
		);
	}

	_renderMailToComponent() {
		const { showMailComponent, files } = this.state;
		const { emailRecipients, hotbarData } = this.props;

		const filesLoaded = !_isNil(this.state.files);

		return (
			<MailToComponent
				handleClose={() =>
					this.setState(() => ({ showMailComponent: false, files: null }))
				}
				onChange={this.onChange}
				submit={this.sendEmail}
				phrases={this.phrases.MAIL_TO_COMPONENT}
				visible={showMailComponent}
				emails={emailRecipients}
				error={this.state.error}
				success={this.state.success}
				files={files}
				filesLoaded={filesLoaded}
				loading={
					this.state.mailLoading || this.state.pdfLoading || this.state.loading
				}
				emailFormValid={filesLoaded}
				zIndex={461}
				subject={
					hotbarData && hotbarData.date
						? `Daily Report ${hotbarData.date.format('DD-MM-YYYY')}`
						: ''
				}
				content={emailBody}
			/>
		);
	}

	render() {
		const { loadingReport, dailyReport, franchiseReport } = this.props;

		return (
			<div className="daily-report">
				<div className="daily-report__header">
					<Hotdamnbar
						onChange={this.onHotbarChange}
						hotbar={DailyReportReportContainerHotbar}
					/>

					{!loadingReport &&
						!_isEmpty(franchiseReport) &&
						!_isEmpty(dailyReport) && (
							<div>
								<Button
									type="inverted"
									shadow
									label="CSV"
									onClick={this._printToCsv}>
									{this.state.loadingCsv ? (
										<ButtonLoader
											loading={this.state.loadingCsv}
											theme="dark"
										/>
									) : (
										<span className="icon icon--file_download" />
									)}
								</Button>

								<Button
									type="inverted"
									shadow
									label="PDF"
									onClick={this._printToPDF}>
									{this.state.loadingPdf ? (
										<ButtonLoader
											loading={this.state.loadingPdf}
											theme="dark"
										/>
									) : (
										<span className="icon icon--file_download" />
									)}
								</Button>

								<Button
									type="inverted"
									shadow
									label="Export"
									onClick={() => this._toggleShowMailComponent(true)}>
									<span className="icon icon--mail" />
								</Button>
							</div>
						)}
				</div>

				{loadingReport ? (
					<Loader />
				) : (
					<div className="daily-report__wrapper">{this._renderReport()}</div>
				)}
				{this._renderMailToComponent()}
			</div>
		);
	}
}

DailyReportReportContainer.propTypes = {
	dailyReport: PropTypes.object,
	franchiseReport: PropTypes.object,
	hotbarData: PropTypes.object,
	expandedMarkets: PropTypes.array,
	expandedGroups: PropTypes.array,
	emailRecipients: PropTypes.array,
	loadingReport: PropTypes.bool,
};

const mapStateToPropsFactory = (store) => {
	return {
		dailyReportRaw: store.dailyReport.data.dailyReportRaw,
		dailyReport: store.dailyReport.data.dailyReport,
		franchiseReport: store.dailyReport.data.franchiseReport,
		hotbarData: store.dailyReport.data.hotbarData,
		loadingReport: store.dailyReport.ui.loadingReport,
		expandedMarkets: store.dailyReport.ui.expandedMarkets,
		expandedGroups: store.dailyReport.ui.expandedGroups,
		emailRecipients: store.dailyReport.data.emailRecipients,
	};
};

export default connectWithStore(
	DailyReportReportContainer,
	mapStateToPropsFactory
);
