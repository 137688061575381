'use strict';

// Services
import __enums from 'enums.service';
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');

export default class MarketMarketOnboardingsList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'onboarding_platform',
				type: 'select',
				canAdd: true,
				canEdit: true,
				options: {
					enums: __enums.onboardingPlatform,
				},
			},
			{
				name: 'onboarding_id',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
		];

		this.settings = {
			name: 'MarketMarketOnboardingsList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			canCollapse: true,
			canManipulate: () => validAccess(undefined, ['Organisation/Markets::Edit', 'Organization Admin']),
			alwaysRefresh: true,
			translationPath: 'HR.MARKET_ONBOARDINGS',
			endpoint: __endpoints.collection.hr.market_onboardings,
		};
		this.init();
		return this;
	}
}
