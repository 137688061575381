export default {
	PRODUCT_VARIANT: 'Product variant',
	PRODUCT_SETS: 'Product sets',

	// modal batch stuff
	MODAL_BATCH_TITLE: 'Add product variants',
	MODAL_BATCH_CONFIRM_BUTTON_TEXT: 'Submit values',
	MODAL_BATCH_STEP_FORM_TIP:
		'Select Product Variants and click Submit Values to add.',
	MODAL_BATCH_OVERLAY_TABLE_TITLE: 'Product variants',
	MODAL_BATCH_VALUES_SUBMITTED: 'Product options added!'
};
