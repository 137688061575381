'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, ButtonGroup, InputCollectionSelect, Icon } from 'dumb';
import { ModalRow } from 'dumb/modal';

// lodash
import _isEmpty from 'lodash/isEmpty';

import constants from 'services/constants';
import phrases from './../../../posConfigurations.phrases';

class PosConfigurationsBatchStepFormAdd extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false
		};
	}

	editStoreEntry(e, type) {
		const { updateBatchForm, resetSelection, marketsUrl } = this.props;

		const value = e.target ? e.target.value : e;

		if (type === 'markets' && _isEmpty(value)) {
			resetSelection(marketsUrl);
		}

		updateBatchForm({
			[type]: value
		});
	}

	render() {
		const { batchFormData, toggleOverlay, marketsUrl } = this.props;

		return (
			<>
				<ModalRow>
					<ButtonGroup type="full-width">
						<InputCollectionSelect
							id="batchMarketSelect"
							label={phrases.MODAL_BATCH_STEP_MARKET}
							placeholder={phrases.MODAL_BATCH_STEP_MARKET_PLACEHOLDER}
							value={batchFormData.markets}
							handleChange={(key, value) =>
								this.editStoreEntry(value, 'markets')
							}
							multi
							cache
							apiPath="/organization/markets"
							params={{
								limit: 30,
								filter: `:closed=ge='${moment
									.utc()
									.format(constants.shortDate)}'`
							}}
							optionFormat={entry => ({
								value: entry,
								label: entry.name
							})}
							inputFilterFormat={input => `:name=like='%${input}%'`}
						/>

						<Button
							type="inverted"
							shadow
							size="large"
							onClick={() => toggleOverlay(marketsUrl)}>
							<Icon name="more" />
						</Button>
					</ButtonGroup>
				</ModalRow>

				<ModalRow>
					<Input
						id="name"
						required
						label={phrases.MODAL_BATCH_STEP_NAME}
						placeholder={phrases.MODAL_BATCH_STEP_NAME_PLACEHOLDER}
						onChange={event => this.editStoreEntry(event, 'name')}
						value={batchFormData.name || ''}
					/>
				</ModalRow>

				<ModalRow>
					<Input
						id="description"
						label={phrases.MODAL_BATCH_DESCRIPTION}
						placeholder={phrases.MODAL_BATCH_DESCRIPTION_PLACEHOLDER}
						onChange={event => this.editStoreEntry(event, 'description')}
						value={batchFormData.description || ''}
					/>
				</ModalRow>
			</>
		);
	}
}

PosConfigurationsBatchStepFormAdd.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
	resetSelection: PropTypes.func,
	marketsUrl: PropTypes.string
};

export default PosConfigurationsBatchStepFormAdd;
