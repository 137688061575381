'use strict';

import { ReportFactory as R } from 'report/factory';
import PosReportBaseColumnsService from './posReportBaseColumns.service';

const t = R.table();

const PosReportWorldTable = t.table([
	t.group('Group', [
		t.column('Group',
			t.contentWrapper({
				body: [t.content(d => `${R.data(d, 'meta.data.continent.name').toUpperCase()} in ${R.data(d, 'meta.data.currency_code')}`, 'truncated', null, 'meta')],
				foot: [t.content('Total')],
			}), PosReportBaseColumnsService.getFirstColumnWidth()
		),
	]),
	...PosReportBaseColumnsService.generate(true)
]);

PosReportWorldTable.setTableType('unfoldedInverted');

PosReportWorldTable.setTitle(t.title(
	d => R.data(d, 'title'),
	d => ` (${R.data(d, 'period.from')} - ${R.data(d, 'period.to')})`
));
PosReportWorldTable.setClassName('pos-reports__world-table');

export default PosReportWorldTable;