'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { store, connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	updateJuicerOperationalResponsibilities,
	resetJuicerOperationalResponsibilities,
} from './store/juicerOperationalResponsibilities.actions';

// services
import {
	fetchJuicerOperationalResponsibilities,
	addJuicerOperationalResponsibilities,
} from './juicerOperationalResponsibilities.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { FilterProvider } from 'reactDataWrapper/utilities';
import { InputCollectionSelect, SingleDatePickerInput } from 'dumb';
import getColumns from 'reactDataWrapperColumns/administration/workplaces.columns';

// lodash
import _get from 'lodash/get';

// utils
import moment from 'moment';
import { getSelectOptionsFromEnums } from 'services/utils';
import enums from './juicerOperationalResponsibilities.enums';

// phrases/constants/enums
import phrases from './juicerOperationalResponsibilities.phrases';
import constants from 'services/constants';
import collectionSelectEnums from 'services/enums/collectionSelect';

const reduxKey = 'workplace-details/hr/operational_responsibilities';

class JuicerOperationalResponsibilities extends Component {
	constructor(props) {
		super(props);

		this.state = {
			key: moment(),
			showModal: false,
			tableFilterActive: moment.utc().format(constants.shortDate),
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.getTableDateSelector = this.getTableDateSelector.bind(this);
		this.getExtraFilters = this.getExtraFilters.bind(this);
		this.onDateFilterChange = this.onDateFilterChange.bind(this);
		this.addEntry = this.addEntry.bind(this);

		this.columns = [
			{
				Header: 'Workplace',
				id: 'workplace',
				accessor: (d) => _get(d, 'workplace.name', ''),
				filterPath: `:workplace.name`,
			},
			{
				Header: 'Responsibility',
				id: 'responsibility',
				accessor: 'responsibility',
				filterPath: `:responsibility`,
				Filter: ({ column }) => (
					<FilterProvider
						reduxKey={reduxKey}
						columnId={column.id}
						provide={(filterValue, persistToFilterStorage) => (
							<InputCollectionSelect
								id={column.id}
								name="responsibility-filter"
								value={filterValue}
								handleChange={(key, value) => {
									persistToFilterStorage({ handle: 'responsibility', value });
								}}
								placeholder="Select responsibility..."
								styleType={collectionSelectEnums.TYPE_IN_TABLE}
								options={getSelectOptionsFromEnums(enums)}
							/>
						)}
					/>
				),
			},
			{
				Header: 'From',
				id: 'from',
				accessor: (d) => _get(d, 'period.from', ''),
				filterPath: `:period.from`,
				filterable: false,
			},
			{
				Header: 'To',
				id: 'to',
				accessor: (d) => _get(d, 'period.to', ''),
				filterPath: `:period.to`,
				filterable: false,
			},
		];
	}

	_getSelectedDate(date, name) {
		if (date) return moment.utc(date).format(constants.shortDate);

		return name === 'from'
			? moment.utc().format(constants.shortDate)
			: '9999-12-31';
	}

	addEntry() {
		const { personId, juicerOperationalResponsibilities } = this.props;

		const payload = {
			workplace:
				juicerOperationalResponsibilities.workplace?.value?.id ?? undefined,
			person: personId,
			responsibility:
				juicerOperationalResponsibilities.responsibility?.value ?? undefined,
			period: {
				from: juicerOperationalResponsibilities.activeFrom
					? moment
							.utc(
								juicerOperationalResponsibilities.activeFrom,
								constants.dateFormat
							)
							.format(constants.shortDate)
					: moment.utc().format(constants.shortDate),
				to: juicerOperationalResponsibilities.activeTo
					? moment
							.utc(
								juicerOperationalResponsibilities.activeTo,
								constants.dateFormat
							)
							.format(constants.shortDate)
					: '9999-12-31',
			},
		};

		return addJuicerOperationalResponsibilities(payload)
			.then((res) => {
				this.setState(() => ({ key: moment() }));
				return res;
			});
	}

	getEditableCells() {
		const { juicerOperationalResponsibilities } = this.props;

		return [
			{
				header: 'Workplace',
				value: (
					<InputCollectionSelect
						id="workplace"
						placeholder="Select a workplace..."
						value={juicerOperationalResponsibilities.workplace}
						handleChange={(key, value) =>
							this.editStoreEntry('workplace', value)
						}
						cache
						apiPath="/administration/workplaces"
						params={{
							limit: 30,
							filter: `:legacy==false`,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						tableColumns={getColumns()}
						tableTitle={phrases.WORKPLACE_TITLE}
						tableReduxKey="juicer-details-/administration/workplaces"
					/>
				),
			},
			{
				header: 'Responsibility',
				value: (
					<InputCollectionSelect
						id="responsiblity"
						placeholder="Select a responsibility..."
						value={juicerOperationalResponsibilities.responsibility}
						handleChange={(key, value) =>
							this.editStoreEntry('responsibility', value)
						}
						options={getSelectOptionsFromEnums(enums)}
						clearable={false}
					/>
				),
			},
			{
				header: 'Active from',
				value: (
					<SingleDatePickerInput
						id="activeFrom"
						onChange={(event) => this.editStoreEntry('activeFrom', event)}
						selectedDate={this._getSelectedDate(
							juicerOperationalResponsibilities.activeFrom,
							'from'
						)}
						noClockButton
					/>
				),
			},
			{
				header: 'Active to',
				value: (
					<SingleDatePickerInput
						id="activeTo"
						onChange={(event) => this.editStoreEntry('activeTo', event)}
						selectedDate={this._getSelectedDate(
							juicerOperationalResponsibilities.activeTo,
							'to'
						)}
						noClockButton
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const { updateJuicerOperationalResponsibilities } = this.props;

		const value = e?.target?.value ?? e;

		const updateObject = {
			[name]: value,
		};

		updateJuicerOperationalResponsibilities(updateObject);
	}

	fetchData(state) {
		const { personId } = this.props;

		const filter = `:person.id=='${personId}'`;

		return fetchJuicerOperationalResponsibilities({ state, filter });
	}

	getExtraFilters() {
		const { customFilter } = this.props;
		const { tableFilterActive } = this.state;

		if (!tableFilterActive && !customFilter?.responsibility) return;

		const dateFilter = tableFilterActive
			? `:period.from=le='${tableFilterActive}';:period.to=ge='${tableFilterActive}'`
			: '';
		const responsibilityFilter = customFilter?.responsibility
			? `:responsibility=='${customFilter.responsibility.value}'`
			: '';

		let filter;
		if (dateFilter) filter = dateFilter;
		if (responsibilityFilter)
			filter = filter
				? `${filter};${responsibilityFilter}`
				: responsibilityFilter;

		return filter;
	}

	getTableDateSelector() {
		return (
			<SingleDatePickerInput
				id="operationalResponsibilitiesDatePicker"
				label={phrases.TABLE_FILTER_ACTIVE}
				type="single"
				appendToBody
				fixed
				onChange={this.onDateFilterChange}
				selectedDate={this.state.tableFilterActive}
				noClockButton
				clearButtonDisabled={!this.state.tableFilterActive}
				clearable
			/>
		);
	}

	onDateFilterChange(e) {
		if (!e) {
			this.setState(() => ({ tableFilterActive: '' }));
			return;
		}

		const date = e.format(constants.shortDate);

		this.setState(() => ({ tableFilterActive: date }));
	}

	render() {
		const { resetJuicerOperationalResponsibilities } = this.props;

		return (
			<ReactDataWrapper
				key={this.state.key}
				title={phrases.TITLE}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={5}
				reduxKey={reduxKey}
				manual
				accessAreasAllowedToEdit={['Employment Admin', 'Person Admin']}
				actionsWidth={30}
				defaultSorted={[
					{
						id: 'from',
						desc: false,
					},
				]}
				createEntry={this.addEntry}
				editableCells={this.getEditableCells()}
				enableMultiSelection={false}
				enableSingleSelection
				customAreaComponents={this.getTableDateSelector()}
				extraFilters={this.getExtraFilters()}
				onModalClose={resetJuicerOperationalResponsibilities}
			/>
		);
	}
}

JuicerOperationalResponsibilities.propTypes = {
	juicerOperationalResponsibilities: PropTypes.object,
	updateJuicerOperationalResponsibilities: PropTypes.func,
	resetJuicerOperationalResponsibilities: PropTypes.func,
	customFilter: PropTypes.object,
	personId: PropTypes.number,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateJuicerOperationalResponsibilities,
			resetJuicerOperationalResponsibilities,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		juicerOperationalResponsibilities:
			store.juicerOperationalResponsibilities.data
				.juicerOperationalResponsibilities,
		customFilter: store.filterSortColumnsData?.tables?.[reduxKey]?.custom,
	};
};

export default connectWithStore(
	JuicerOperationalResponsibilities,
	mapStateToProps,
	mapDispatchToProps
);
