'use strict';

import _has from 'lodash/has';
import _keyBy from 'lodash/keyBy';

import { ReportComponentResultSetAbstract } from 'report/resultSets';

class ReportTablesResultSet extends ReportComponentResultSetAbstract {
	constructor(resultSet) {
		super();

		// Set results
		this.setResults(resultSet);
	}

	/// //////////
	// Setters //
	/// //////////

	/**
	 * @function setResults
	 * @memberOf ReportTablesResultSet
	 * @description
	 * Extract tables from resultSet and sets as result
	 * @param {object} resultSet
	 */
	setResults(resultSet) {
		const isCollectionBased = !!resultSet.getFirst;

		const report = isCollectionBased ? resultSet.getFirst() : resultSet.data[0];

		const entities = resultSet?.results?.entities ?? report;

		if (Array.isArray(report.tables) && _has(entities, 'tables')) {
			this.results = {
				result: isCollectionBased ? report.tables : report.tables.map(table=> table.title),
				entities: isCollectionBased ? entities.tables : _keyBy(entities.tables, table => table.title)
			};
		} else this.results = null;
	}

	/// //////////
	// Getters //
	/// //////////

	/**
	 * @function getResults
	 * @memberOf ReportTablesResultSet
	 * @description
	 * Gets results
	 */
	getResults() {
		return this.results || null;
	}
}

export default ReportTablesResultSet;
