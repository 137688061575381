export const RESET_INGREDIENT_ALLERGEN =
	'SALES_CONFIGURATION_PRODUCT_PRODUCT_VARIANT_INGREDIENT_ALLERGENS/RESET_INGREDIENT_ALLERGEN';
export const SET_INGREDIENT_ALLERGEN =
	'SALES_CONFIGURATION_PRODUCT_PRODUCT_VARIANT_INGREDIENT_ALLERGENS/SET_INGREDIENT_ALLERGEN';
export const UPDATE_INGREDIENT_ALLERGEN =
	'SALES_CONFIGURATION_PRODUCT_PRODUCT_VARIANT_INGREDIENT_ALLERGENS/UPDATE_INGREDIENT_ALLERGEN';

/**
 * @param {object} payload
 */
export function setIngredientAllergen(payload) {
	return {
		type: SET_INGREDIENT_ALLERGEN,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function updateIngredientAllergen(payload) {
	return {
		type: UPDATE_INGREDIENT_ALLERGEN,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetState() {
	return {
		type: RESET_INGREDIENT_ALLERGEN,
	};
}
