import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchAllAssetCollections
 * @description Fetch all asset collections
 * @param {object} state
 */
export function fetchAllAssetCollections(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get(`/pos/asset_collections`, params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editAssetCollections
 * @description edit
 */
export function editAssetCollections(collectionId, data) {
	return post(`/pos/asset_collections/${collectionId}`, data)
		.then(res => {
			setFeedback('Asset collection edited', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function addAssetCollections
 * @description add
 */
export function addAssetCollections(data) {
	return post(`/pos/asset_collections`, data)
		.then(res => {
			setFeedback('Asset collection added', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteAssetCollections
 * @description del
 */
export function deleteAssetCollections(collectionId) {
	return remove(`/pos/asset_collections/${collectionId}`)
		.then(res => {
			setFeedback('Asset collection deleted', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
