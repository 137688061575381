export const SET_WORKPLACE =
	'WORKPLACES/SET_WORKPLACE';
export const UPDATE_WORKPLACE =
	'WORKPLACES/UPDATE_WORKPLACE';
export const RESET_WORKPLACE =
	'WORKPLACES/RESET_WORKPLACE';

export function setWorkplace(payload) {
	return {
		type: SET_WORKPLACE,
		payload
	};
}

export function updateWorkplace(payload) {
	return {
		type: UPDATE_WORKPLACE,
		payload
	};
}

export function resetWorkplace() {
	return {
		type: RESET_WORKPLACE
	};
}