'use strict';

import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './posConfigurations.phrases';

/**
 * @function fetchPosConfigurations
 * @description Fetch all pos configurations
 */
export function fetchPosConfigurations(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get('/pos/pos_configurations', params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchMarkets
 * @description Fetch all markets
 */
export function fetchMarkets(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get('/organization/markets', params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

export function addPosConfiguration(data, duplicate = false) {
	return post('/pos/pos_configurations', data)
		.then(res => {
			let feedbackMessage = phrases.POS_CONFIGURATION_ADDED;
			if (data.batch) feedbackMessage = phrases.POS_CONFIGURATIONS_ADDED;
			if (duplicate) feedbackMessage = phrases.POS_CONFIGURATIONS_DUPLICATED;

			setFeedback(feedbackMessage, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editPosConfiguration
 * @param {Object} payload - update object
 */
export function editPosConfiguration(payload) {
	return post(`/pos/pos_configurations/${payload.id}`, payload)
		.then(res => {
			setFeedback(phrases.POS_CONFIGURATION_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editBatchPosConfiguration
 * @param {Object} payload - update object
 */
export function editBatchPosConfiguration(payload) {
	return patch('/pos/pos_configurations', payload)
		.then(res => {
			setFeedback(phrases.POS_CONFIGURATIONS_EDITED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deletePosConfiguration
 * @param {Number} id - id of pos config to delete
 */
export function deletePosConfiguration(id) {
	return remove(`/pos/pos_configurations/${id}`)
		.then(res => {
			setFeedback(phrases.POS_CONFIGURATION_DELETED, 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
