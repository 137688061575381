export default {
	TITLE: 'Filter groups',
	FILTER_GROUP_ADDED: 'Filter groups added!',
	FILTER_GROUP_EDITED: 'Filter group edited!',
	FILTER_GROUPS_EDITED: 'Filter groups edited!',
	FILTER_GROUP_DELETED: 'Filter group deleted!',

	// sub table
	FILTERS: 'Filters',

	// batch form
	MODAL_BATCH_STEP_LIST_TITLE: 'Create Filter groups',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_NAME: 'Name',
	MODAL_BATCH_STEP_NAME_PLACEHOLDER: 'Enter name...',
	MODAL_BATCH_STEP_DESCRIPTION: 'Description',
	MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER: 'Enter description...',
	MODAL_BATCH_STEP_SORT_ORDER: 'Sort order',
	MODAL_BATCH_STEP_SORT_ORDER_PLACEHOLDER: 'Enter sort order...',
	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add Filter Groups',
};
