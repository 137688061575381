import phrases from '../map.phrases';
import { set as setFeedback } from 'feedback.vanilla.service';

class Map {

	constructor() {

		this.getPlace = this.getPlace.bind(this);

	}

	/**
	 * @function getPhrases
	 * @memberOf Map
	 * @description Getter for phrases
	 */
	getPhrases() {
		return phrases;
	}

	/**
	 * @function getPlace
	 * @memberOf Map
	 * @description Getting the place from Google
	 * @param objectToSend ObjectToSend: {address: 'ADDRESS'}, {location: {lat: LAT, lng: LNG}}
	 */
	getPlace(objectToSend) {

		const phrases = this.getPhrases();

		return new Promise((resolve) => {

			if (window.google) {
				const geocoder = new window.google.maps.Geocoder();
				geocoder.geocode(objectToSend, (results, status) => {
					if (status === 'OK') {

						const marker = {
							// Longitude should be only coming from Geocoder if we didn't move the marker
							longitude: objectToSend.location ? objectToSend.location.lng : parseFloat(results[0].geometry.location.lng()),

							// Latitude should be only coming from Geocoder if we didn't move the marker
							latitude: objectToSend.location ? objectToSend.location.lat : parseFloat(results[0].geometry.location.lat()),

							// Pass the zip code back from Geocoder for later use
							secondary_zip: this.getZipCode(results[0].address_components),

							// Pass the city back from Geocoder for later use
							secondary_city: this.getCity(results[0].address_components),

							// Pass the address back from Geocoder for later use
							secondary_address: this.getStreetNameAndNumber(results[0].address_components),

							// Pass the recommended, formatted address back, to show over the marker
							recommended_address: results[0].formatted_address
						};

						resolve(marker);

					} else {

						//if (status === 'ZERO_RESULTS')
						//setFeedback(phrases.ZERO_RESULTS, 0);

						if (status === 'OVER_QUERY_LIMIT')
							setFeedback(phrases.OVER_QUERY_LIMIT, 0);

						else
							setFeedback(phrases.UNKNOWN_ERROR, 0);

					}
				});
			}
		});

	}

	/**
	 * @function getType
	 * @memberOf Map
	 * @description Helper function, get Type from Google API, such as 'address' or 'zip'
	 */
	getType(addressComponents, addressType) {
		for (let i = 0; i < addressComponents.length; i++) {

			if (addressComponents[i].types.find((type) => (type === addressType) ? true : false))
				return addressComponents[i].long_name;
		}
	}

	/**
	 * @function getZipCode
	 * @memberOf Map
	 * @description Getting the zip code with the getType helper
	 */
	getZipCode(addressComponents) {
		return this.getType(addressComponents, 'postal_code');
	}

	/**
	 * @function getCity
	 * @memberOf Map
	 * @description Getting the city with the getType helper
	 */
	getCity(addressComponents) {
		return this.getType(addressComponents, 'postal_town');
	}

	/**
	 * @function getStreetNameAndNumber
	 * @memberOf Map
	 * @description Getting the full address with the getType helper
	 */
	getStreetNameAndNumber(addressComponents) {
		const streetName = this.getType(addressComponents, 'route');
		const streetNumber = this.getType(addressComponents, 'street_number');

		return streetName + ' ' + streetNumber;
	}

}

export default Map;