import {
	SET_PRODUCT_VARIANT,
	UPDATE_PRODUCT_VARIANT,
	RESET_PRODUCT_VARIANT
} from './productVariant.actions';

const defaultState = {
	data: {
		productVariantData: {}
	}
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case SET_PRODUCT_VARIANT: {
		return {
			data: {
				productVariantData: payload
			}
		};
	}

	case UPDATE_PRODUCT_VARIANT: {
		return {
			data: {
				productVariantData: {
					...state.data.productVariantData,
					...payload
				}
			}
		};
	}

	case RESET_PRODUCT_VARIANT: {
		return {
			data: {
				productVariantData: defaultState.data.productVariantData
			}
		};
	}

	default:
		return state;
	}
}

export default reducer;
