'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import {
	// batch stuff
	updateBatchForm,
	editBatchListItem,
	removeBatchListItem,
	addBatchListItemRow,

	// regular stuff
	setWaitingTimePerformanceGrids,
	updateWaitingTimePerformanceGrids,
	resetState,
} from './store/waitingTimePerformanceGrids.actions';

// services
import {
	fetchWaitingTimePerformanceGrids,
	editWaitingTimePerformanceGrid,
	editWaitingTimePerformanceGrids,
	addWaitingTimePerformanceGrids,
	deleteWaitingTimePerformanceGrids,
} from './waitingTimePerformanceGrids.service';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Input, Button, Icon, Tabs } from 'dumb';
import WaitingTimePerformanceGridsModalBatch from './components/batch/waitingTimePerformanceGridsModalBatch';
import SubTable from 'reactDataWrapper/components/subTable';
import WaitingTimePerformanceGridEntries from './components/waitingTimePerformanceGridEntries/waitingTimePerformanceGridEntries.component';
import WorkplaceWaitingTimePerformanceGrids from './components/workplaceWaitingTimePerformanceGrids/workplaceWaitingTimePerformanceGrids.component';

// utils
import { getEditedValues } from 'services/utils';

// moment
import moment from 'moment';

// phrases
import phrases from './waitingTimePerformanceGrids.phrases';

const reduxKey = '/administration/waiting_time_performance_grids';

class WaitingTimePerformanceGrids extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			key: moment.utc(),
		};

		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.editMultiple = this.editMultiple.bind(this);
		this.addWaitingTimePerformanceGridsWrapper = this.addWaitingTimePerformanceGridsWrapper.bind(
			this
		);
		this.fetchData = this.fetchData.bind(this);
		this.editEntry = this.editEntry.bind(this);

		this.columns = [
			{
				Header: 'Name',
				id: 'name',
				accessor: 'name',
				filterPath: ':name',
			},
			{
				Header: 'Description',
				id: 'description',
				accessor: 'description',
				filterPath: ':description',
			},
		];
	}

	editEntry() {
		const {
			waitingTimePerformanceGrids,
			defaultWaitingTimePerformanceGrids,
		} = this.props;

		const editedValues = getEditedValues({
			oldData: defaultWaitingTimePerformanceGrids,
			newData: waitingTimePerformanceGrids,
		});

		const payload = {
			id: waitingTimePerformanceGrids.id,
			name: editedValues.name ?? undefined,
			description: editedValues.description ?? undefined,
		};

		return editWaitingTimePerformanceGrid(payload);
	}

	editMultiple(selectedRows) {
		const { waitingTimePerformanceGrids } = this.props;

		const payload = {
			...(waitingTimePerformanceGrids.name && {
				name: waitingTimePerformanceGrids.name,
			}),
			...(waitingTimePerformanceGrids.description && {
				description: waitingTimePerformanceGrids.description,
			}),
		};

		const selectedRowsWithId = selectedRows.map((row) => {
			return {
				id: row.id,
				...payload,
			};
		});

		return editWaitingTimePerformanceGrids({
			batch: selectedRowsWithId,
		});
	}

	deleteEntry(id) {
		return deleteWaitingTimePerformanceGrids(id);
	}

	setInitialEditValues(data) {
		const payload = {
			id: data.id,
			name: data.name,
			description: data.description,
			singleEdit: true,
		};

		this.props.setWaitingTimePerformanceGrids(payload);
	}

	getEditableCells() {
		const { waitingTimePerformanceGrids } = this.props;

		return [
			{
				header: 'Name',
				value: (
					<Input
						id="name"
						placeholder="Enter name..."
						value={waitingTimePerformanceGrids.name}
						onChange={(e) => this.editStoreEntry('name', e)}
					/>
				),
			},
			{
				header: 'Description',
				value: (
					<Input
						id="description"
						placeholder="Enter description..."
						value={waitingTimePerformanceGrids.description}
						onChange={(e) => this.editStoreEntry('description', e)}
					/>
				),
			},
		];
	}

	editStoreEntry(name, e) {
		const { updateWaitingTimePerformanceGrids } = this.props;

		const value = e.target.value;

		const updateObject = {
			[name]: value,
		};

		updateWaitingTimePerformanceGrids(updateObject);
	}

	fetchData(state) {
		return fetchWaitingTimePerformanceGrids(state);
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	addWaitingTimePerformanceGridsWrapper(data) {
		return addWaitingTimePerformanceGrids(data).then(() =>
			this.setState(() => ({
				key: moment.utc(),
			}))
		);
	}

	render() {
		const {
			batchList,
			editBatchListItem,
			addBatchListItemRow,
			removeBatchListItem,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					key={this.state.key}
					title={phrases.TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={reduxKey}
					manual
					editableCells={this.getEditableCells()}
					editEntry={this.editEntry}
					accessAreasAllowedToEdit={['Global Sales Configuration', 'Sales Configuration']}
					editMultiple={this.editMultiple}
					deleteEntry={this.deleteEntry}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					actionRender={
						<Button
							type="inverted"
							label="Batch"
							shadow
							onClick={this.toggleModal}>
							<Icon name="add" />
						</Button>
					}
					subcomponent={(row) => {
						const tabContent = [
							{
								name: phrases.WAITING_TIME_PERFORMANCE_GRID_ENTRIES_TITLE,
								component: (
									<WaitingTimePerformanceGridEntries
										waitingTimePerformanceGridId={row.original.id}
									/>
								),
							},
							{
								name: phrases.WORKPLACE_WAITING_TIME_PERFORMANCE_GRIDS_TITLE,
								component: (
									<WorkplaceWaitingTimePerformanceGrids
										waitingTimePerformanceGridId={row.original.id}
									/>
								),
							},
						];

						return (
							<SubTable>
								<Tabs tabContent={tabContent} />
							</SubTable>
						);
					}}
				/>

				<WaitingTimePerformanceGridsModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchList={batchList}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					addBatchListItemRow={addBatchListItemRow}
					addWaitingTimePerformanceGrids={
						this.addWaitingTimePerformanceGridsWrapper
					}
				/>
			</>
		);
	}
}

WaitingTimePerformanceGrids.propTypes = {
	waitingTimePerformanceGrids: PropTypes.object,
	updateWaitingTimePerformanceGrids: PropTypes.func,
	setWaitingTimePerformanceGrids: PropTypes.func,
	resetState: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	defaultWaitingTimePerformanceGrids: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateBatchForm,
			editBatchListItem,
			removeBatchListItem,
			updateWaitingTimePerformanceGrids,
			setWaitingTimePerformanceGrids,
			resetState,
			addBatchListItemRow,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		waitingTimePerformanceGrids:
			store.globalAdministrationWaitingTimePerformanceGrids.data
				.waitingTimePerformanceGrids,
		defaultWaitingTimePerformanceGrids:
			store.globalAdministrationWaitingTimePerformanceGrids.data
				.defaultWaitingTimePerformanceGrids,
		batchList:
			store.globalAdministrationWaitingTimePerformanceGrids.data.batchList,
	};
};

export default connectWithStore(
	WaitingTimePerformanceGrids,
	mapStateToProps,
	mapDispatchToProps
);
