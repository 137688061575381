'use strict';

import React from 'react';
import PropTypes from 'prop-types';

// components
import CsvDateRange from './csvDateRange.component';

type Props = {
	handleCSVExport: ({
		from,
		to,
	}: {
		from: string;
		to: string;
	}) => Promise<unknown>;
	handleClose: () => void;
	visible: boolean;
};

const CsvDateRangeContainer = ({
	handleCSVExport,
	handleClose,
	visible,
}: Props) => {
	return visible ? (
		<CsvDateRange
			handleCSVExport={handleCSVExport}
			handleClose={handleClose}
		/>
	) : null;
};

// leaving prop types here as this is an existing component, used in many places and therefor we need some type awareness
CsvDateRangeContainer.propTypes = {
	handleCSVExport: PropTypes.func,
	handleClose: PropTypes.func,
	visible: PropTypes.bool,
};

export default CsvDateRangeContainer;
