import { store } from 'appState';
import * as actions from './../shiftplannerReport.actions';
import { get } from 'api.vanilla.service';

import { parseReportsPerWorkplace } from './../utilities/shiftplannerReportParser';

var __endpoints = require('endpoints.service');

const entriesTypes = {
	HOURLY: 'HOURLY',
	HALF_HOURLY: 'HALF_HOURLY',
};

export default function fetchShiftplannerReportPrWorkplace(
	{ entriesType = 'hourly' } = {},
	entry = {}
) {
	// Set endpoint based on half_hourly or hourly selection in hotbar
	const shiftplannerHourlyReportEndpoint =
		entriesType.value === entriesTypes.HOURLY
			? `${__endpoints.collection.reporting.shiftplanner_reports.index.path}/markets/${entry.workplace.market.id}/workplaces/${entry.workplace.id}/hourly_entries`
			: `${__endpoints.collection.reporting.shiftplanner_reports.index.path}/markets/${entry.workplace.market.id}/workplaces/${entry.workplace.id}/half_hourly_entries`;

	// Set params and filter
	const params = {
		from: entry.period.from,
		to: entry.period.to,
		type: entry.type,
	};

	return get(shiftplannerHourlyReportEndpoint, params).then((response) => {
		const report = parseReportsPerWorkplace(response.data);
		store.dispatch(actions.setShiftplannerReportPrWorkplace(report));
	});
}
