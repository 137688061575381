'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, InputCollectionSelect, Icon, Tooltip } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// phrases/constants
import phrases from './../../../productBundleRules.phrases';
import constants from 'services/constants';

class ProductBundleRulesBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: e },
		});
	}

	render() {
		const { batchListData, addBatchListItemRow } = this.props;

		return (
			<>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_GLOBAL_BUNDLE_RULE}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{batchListData.map((entry, i) => {
							return (
								<TableListRow key={entry.identifier}>
									<TableListCell>{i + 1}</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id={`globaBundleRule-${entry.identifier}`}
											apiPath="/product/global_bundle_rules"
											params={{
												limit: 30,
											}}
											placeholder={
												phrases.MODAL_BATCH_STEP_GLOBAL_BUNDLE_RULE_PLACEHOLDER
											}
											value={entry.globalBundleRule}
											handleChange={(key, value) =>
												this._editListEntry(value, entry, 'globalBundleRule')
											}
											cache
											optionFormat={(entry) => ({
												value: entry,
												label: `${entry.name} (${moment(
													entry.time.from,
													constants.dayFormat
												).format(constants.timeShort)} - ${moment(
													entry.time.to,
													constants.dayFormat
												).format(constants.timeShort)})`,
											})}
											inputFilterFormat={(input) => `:name=like='%${input}%'`}
											required
										/>
									</TableListCell>

									<TableListCell>
										<Button
											id={`${entry.identifier}-delete`}
											type="negative"
											size="tiny"
											onClick={() => this._onRemoveEntry(entry.identifier)}>
											<span className="icon icon--delete" />
										</Button>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
				<div className="modal-batch__add-new-row">
					<Tooltip
						text={phrases.MODAL_BATCH_STEP_LIST_TOOLTIP}
						zIndex="600"
						renderData={(ref, onMouseEnter, onMouseLeave) => (
							<Button
								type="secondary"
								onClick={addBatchListItemRow}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
								refProp={ref}>
								<Icon name="add" />
							</Button>
						)}
					/>
				</div>
			</>
		);
	}
}

ProductBundleRulesBatchStepList.propTypes = {
	batchListData: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
};

export default ProductBundleRulesBatchStepList;
