'use strict';

import MetadataTitleAbstract from './metadata.title.abstract';

/**
 * PrintTitle
 * @param  {*} template
 */
class PrintTitle extends MetadataTitleAbstract  {
	constructor(template) {
		super(template);

		// Set type
		this.type = 'printTitle';
	}
}

export default PrintTitle;