import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// styles
import './tableListCell.css';

const TableListCell = (props) => {
	return (
		<td
			className={cx('table-list-cell', {
				[`${props.className}`]: !!props.className,
				...(props.onClick && {
					'table-list-cell--clickable': true,
				}),
			})}
			width={props.width}
			colSpan={props.colSpan}
			style={props.style}
			{...(props.onClick && { onClick: props.onClick })}>
			{props.children}
		</td>
	);
};

TableListCell.defaultProps = {
	width: null,
	colSpan: 1,
	style: {},
};

TableListCell.propTypes = {
	children: PropTypes.node,
	width: PropTypes.string,
	className: PropTypes.string,
	colSpan: PropTypes.number,
	style: PropTypes.object,
	onClick: PropTypes.func,
};

export default TableListCell;
