'use strict';

import { UPDATE_HOTBAR_FILTER } from './salesControl.actions';

const defaultState = {
	hotbarFilter: ''
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
	case UPDATE_HOTBAR_FILTER: {
		return {
			...state,
			hotbarFilter: payload
		};
	}
 

	default:
		return state;
	}
}

export default reducer;
