import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { SmartTable, Card } from 'dumb';
import RowDragLayer from './components/rowDragLayer.component';

import constants from '../utilities/constants';
import './postItemList.css';

export default class PosItemList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
		};

		this.onRowItemClick = this.onRowItemClick.bind(this);
	}

	onRowItemClick(clickedItem) {
		const { addTile, groupItemInFocus, lastInLine } = this.props;
		this.setState(() => ({ loading: true }));

		const position = lastInLine ? Number(lastInLine.position) + 1 : 1;

		const tile = {
			background_color: 'ffffff',
			name: clickedItem.label,
			tile_layout_sub_group: null,
			product: clickedItem.value,
			position,
			title_color: '464646',
			type: constants.TILE_TYPE.PRODUCT,
			tile_layout_group: groupItemInFocus,
			description: '',
		};

		addTile(tile)
			.then(() => {
				this.setState(() => ({ loading: false }));
			})
			.catch(() => {
				this.setState(() => ({ loading: false }));
			});
	}

	_formatData() {
		const { posConfigurationsVariantsList } = this.props;

		// Format data in collumns
		// For example if added fourth column (no headr, this data will be passed as well)
		return posConfigurationsVariantsList.map((product) => {
			return [
				{
					value: product.product_category.name,
					data: { ...product, type: constants.TILE_TYPE.VARIANT }, // Passed as [0] object!
				},
				{ value: product.label },
			];
		});
	}

	render() {
		const { enableRowClick } = this.props;

		const headers = [
			{ value: 'Product Category', width: 100, sortable: true },
			{ value: 'Product Variant', width: 300, sortable: true },
			// { value: `Prices\n(to go/to stay)`, width: 99, sortable: true }
		];

		return (
			<Card className="pos-item-list">
				<SmartTable
					headers={headers}
					data={this._formatData()}
					draggableRows
					theme="dark"
					search
					formatDraggableOutput={(object) => object[0].data} // Passed as [0] object!
					onRowItemClick={enableRowClick && this.onRowItemClick}
					loading={this.state.loading}
				/>
				<RowDragLayer />
			</Card>
		);
	}
}

PosItemList.propTypes = {
	posConfigurationsVariantsList: PropTypes.array,
	addTile: PropTypes.func,
	groupItemInFocus: PropTypes.number,
	lastInLine: PropTypes.object,
	enableRowClick: PropTypes.bool,
};

PosItemList.defaultProps = {
	posConfigurationsVariantsList: [],
};
