import React, { Component } from 'react';

// Components
import Discounts from './components/discounts/discounts.component';

class DiscountsContainer extends Component {
	render() {
		return (
			<>
				<Discounts />
			</>
		);
	}
}

DiscountsContainer.propTypes = {};

export default DiscountsContainer;
