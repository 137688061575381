import _get from 'lodash/get';
import { store } from 'appState';

import * as actions from './../reactDataWrapper.actions';

export function submitBatchValues({ res, reduxKey }) {
	// need to add res to the reducer if needed
	// update metadata like UI
	// return same res back
	// if table has more then a single page then update metadata like no of pages and crap like that

	// get current page size
	const state = store.getState();
	const pageSize = _get(state, `listData.[${reduxKey}].ui.pageSize`, null);
	const page = _get(state, `listData.[${reduxKey}].ui.page`, 0);
	let totalEntries = _get(state, `listData.[${reduxKey}].ui.totalEntries`, 0);

	res.data.map(entry => {
		if (pageSize - totalEntries >= 1) {
			store.dispatch(actions.addEntry({ reduxKey, entry }));
			totalEntries += 1;
		}
	});

	const pages = Math.ceil(totalEntries / pageSize);

	// need to call set list metadata
	store.dispatch(
		actions.setListMetaData({
			reduxKey,
			metadata: {
				pages,
				page,
				pageSize,
				totalEntries
			}
		})
	);
}
