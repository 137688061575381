import React, { useState } from 'react';
import PropTypes from 'prop-types';

// components
import { Button, Icon, Tooltip, ButtonLoader, Legend, Tag } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// phrases/ constants/ utils
import phrases from './../clockinDeviationApproval.phrases';
import enums from './../clockinDeviationApproval.enums';

// utils
import { formatShiftTime, getShiftDate, getShiftDay } from './../utils';

const ClockinDeviationApprovalTableList = ({
	data,
	updateDeviation,
	deviationApprovedColor,
	deviationDeclinedColor,
}) => {
	const handleDeviation = (entry, status) => {
		setUpdatingDeviation({ id: entry.id, status });

		return updateDeviation({
			deviationId: entry.id,
			status,
		}).then(() => setUpdatingDeviation(null));
	};

	// hooks
	const [updatingDeviation, setUpdatingDeviation] = useState(null);

	return (
		<>
			<div className="clockin-deviation-approval-modal__legend-wrapper">
				<Legend
					points={[
						{
							bulletColor: `#${deviationDeclinedColor}`,
							label: phrases.DECLINED,
						},
						{
							bulletColor: `#${deviationApprovedColor}`,
							label: phrases.APPROVED,
						},
					]}
				/>
			</div>
			<div className="clockin-deviation-approval-modal__table-wrapper">
				<TableList>
					<thead>
						<tr>
							<th>{phrases.TABLE_LIST_NAME}</th>
							<th>{phrases.TABLE_LIST_DATE}</th>
							<th>{phrases.TABLE_LIST_SHIFT}</th>
							<th>{phrases.TABLE_LIST_WORKPLACE}</th>
							<th>{phrases.TABLE_LIST_DEVIATION_REASON_TYPE}</th>
							<th>{phrases.TABLE_LIST_EVENT}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{data.map((entry) => {
							let bulletColor;
							if (entry.status === enums.CONFIRMED)
								bulletColor = deviationApprovedColor;
							if (entry.status === enums.DECLINED)
								bulletColor = deviationDeclinedColor;

							return (
								<TableListRow key={entry.id}>
									<TableListCell>
										<div className="clockin-deviation-approval-modal__table-wrapper__row-cell">
											{bulletColor && <Tag type="bullet" color={bulletColor} />}
											{entry.employment?.person?.full_name ?? ''}
										</div>
									</TableListCell>
									<TableListCell>
										<div className="clockin-deviation-approval-modal__table-wrapper__row-cell">
											{getShiftDate(entry.shift?.planned?.from)}
											<span className="clockin-deviation-approval-modal__table-wrapper__row-cell__weekday">
												({getShiftDay(entry.shift?.planned?.from)})
											</span>
										</div>
									</TableListCell>
									<TableListCell>
										{formatShiftTime(entry.shift?.planned?.from)} -{' '}
										{formatShiftTime(entry.shift?.planned?.to)}
									</TableListCell>
									<TableListCell>{entry.workplace?.name ?? ''}</TableListCell>
									<TableListCell>
										{entry.deviation_reason?.type}
										<Tooltip
											text={entry.deviation_reason?.description ?? ''}
											zIndex={600}
											renderData={(ref, onMouseEnter, onMouseLeave) => (
												<Icon
													name="info"
													className="clockin-deviation-approval-modal__table-wrapper__info-icon-wrapper"
													refProp={ref}
													onMouseEnter={onMouseEnter}
													onMouseLeave={onMouseLeave}
												/>
											)}
										/>
									</TableListCell>
									<TableListCell>{entry.delta_description}</TableListCell>
									<TableListCell>
										<div className="clockin-deviation-approval-modal__table-wrapper__row-cell clockin-deviation-approval-modal__table-wrapper__row-cell--left-margin">
											<Tooltip
												text={phrases.APPROVE}
												zIndex={600}
												renderData={(ref, onMouseEnter, onMouseLeave) => (
													<Button
														shadow
														type="success"
														size="small"
														onClick={() =>
															handleDeviation(entry, enums.CONFIRMED)
														}
														refProp={ref}
														onMouseEnter={onMouseEnter}
														onMouseLeave={onMouseLeave}>
														{updatingDeviation?.id === entry.id &&
														updatingDeviation?.status === enums.CONFIRMED ? (
															<ButtonLoader loading />
														) : (
															<Icon name="thumb_up" />
														)}
													</Button>
												)}
											/>
											<div className="clockin-deviation-approval-modal__table-wrapper__row-cell--left-margin">
												<Tooltip
													text={phrases.DECLINE}
													zIndex={600}
													renderData={(ref, onMouseEnter, onMouseLeave) => (
														<Button
															shadow
															size="small"
															type="negative"
															onClick={() =>
																handleDeviation(entry, enums.DECLINED)
															}
															refProp={ref}
															onMouseEnter={onMouseEnter}
															onMouseLeave={onMouseLeave}>
															{updatingDeviation?.id === entry.id &&
															updatingDeviation?.status === enums.DECLINED ? (
																<ButtonLoader loading />
															) : (
																<Icon name="thumb_down" />
															)}
														</Button>
													)}
												/>
											</div>
										</div>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>
			</div>
		</>
	);
};

ClockinDeviationApprovalTableList.propTypes = {
	data: PropTypes.object,
	updateDeviation: PropTypes.func,
	deviationApprovedColor: PropTypes.string,
	deviationDeclinedColor: PropTypes.string,
};

export default ClockinDeviationApprovalTableList;
