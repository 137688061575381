import {
	UPDATE_NUTRITION_VALUE,
	SET_NUTRITION_VALUE,
	RESET_STATE,
	UPDATE_BATCH_FORM,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	ADD_BATCH_LIST,
} from './nutritionValues.actions';

const defaultState = {
	data: {
		defaultNutritionValues: {},
		batchFormData: {},
		batchListData: [],
	},
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case UPDATE_BATCH_FORM: {
			return {
				...state,
				data: {
					...state.data,
					batchFormData: {
						...state.data.batchFormData,
						...payload,
					},
				},
			};
		}

		case EDIT_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchListData: state.data.batchListData.map((entry) => {
						if (entry.identifier === payload.identifier)
							return {
								...entry,
								...payload,
							};

						return entry;
					}),
				},
			};
		}

		case REMOVE_BATCH_LIST_ITEM: {
			return {
				...state,
				data: {
					...state.data,
					batchListData: state.data.batchListData.filter(
						(entry) => entry.identifier !== payload.identifier
					),
				},
			};
		}

		case ADD_BATCH_LIST: {
			return {
				...state,
				data: {
					...state.data,
					batchListData: payload,
				},
			};
		}

		case SET_NUTRITION_VALUE: {
			return {
				...state,
				data: {
					...state.data,
					defaultNutritionValues: payload,
				},
			};
		}

		case UPDATE_NUTRITION_VALUE: {
			return {
				...state,
				data: {
					...state.data,
					defaultNutritionValues: {
						...state.data.defaultNutritionValues,
						...payload,
					},
				},
			};
		}

		case RESET_STATE: {
			return defaultState;
		}

		default:
			return state;
	}
}

export default reducer;
