import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchProductSetOptions
 * @description Fetch all product set options
 * @param {object} state
 */
export function fetchProductSetOptions(productSetId, state) {
	const preFilter = `:discount_product_set.id=='${productSetId}'`;
	const filter = state.filter ? `${preFilter};${state.filter}` : preFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter
	};

	return get(`/sale/discount_product_set_options`, params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchProductVariants
 * @description Fetch all product set options
 * @param {object} state
 */
export function fetchProductVariants(state, excludedIds) {
	const excludeFilter = `:id=!IN=[${excludedIds}]`;
	const filter = state.filter
		? `${state.filter};${excludeFilter}`
		: excludeFilter;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter
	};

	return get(`/product/product_variants`, params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editProductSetOption
 * @description edit
 */
export function editProductSetOption(addonId, data) {
	return post(`/sale/discount_product_set_options/${addonId}`, data)
		.then(res => {
			setFeedback('Product set edited', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function addProductSetOption
 * @description add
 */
export function addProductSetOption(data) {
	return post(`/sale/discount_product_set_options`, data)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteProductSetOption
 * @description edit
 */
export function deleteProductSetOption(productSetOptionId) {
	return remove(`/sale/discount_product_set_options/${productSetOptionId}`)
		.then(res => {
			setFeedback('Product set deleted', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
