import React, { useState } from 'react';
import PropTypes from 'prop-types';

// components
import { Input, InputCollectionSelect } from 'dumb';
import ColorPicker from '../../flairs/components/colorPicker/colorPicker';

// phrases
import phrases from './../tags.phrases';

// utils
import enums from 'enums.service';

// styles
import './../style.css';

export default function TagsGetEditableCells({ value, onChange }) {
	const [colorPickerOpen, setColorPickerOpen] = useState(false);

	const onColorClick = () => setColorPickerOpen(!colorPickerOpen);
	const onColorChange = (color) => {
		onChange(color, 'color');
		setColorPickerOpen(false);
	};

	return [
		{
			header: phrases.TYPE,
			value: value.editMode ? (
				<span>{value.type}</span>
			) : (
				<InputCollectionSelect
					id="type"
					placeholder={phrases.TYPE_PLACEHOLDER}
					value={value.type}
					handleChange={(key, value) => {
						onChange(value, 'type');
					}}
					required
					cache
					options={enums.tagsList}
					optionFormat={(entry) => ({
						value: entry,
						label: entry.name,
					})}
				/>
			),
		},
		{
			header: phrases.SUB_TYPE,
			value: value.editMode ? (
				<span>{value.subType}</span>
			) : (
				<InputCollectionSelect
					id="sub-type"
					placeholder={phrases.SUB_TYPE_PLACEHOLDER}
					value={value.subType}
					handleChange={(key, value) => {
						onChange(value, 'subType');
					}}
					required
					cache
					options={enums.tagsSubtypesList}
					optionFormat={(entry) => ({
						value: entry,
						label: entry.name,
					})}
				/>
			),
		},
		{
			header: phrases.NAME,
			value: (
				<Input
					id="name"
					required
					placeholder={phrases.NAME_PLACEHOLDER}
					value={value.name}
					onChange={(e) => onChange(e.target.value, 'name')}
				/>
			),
		},
		{
			header: phrases.NAME_ABBREVIATED,
			value: (
				<Input
					id="name-abbreviated"
					placeholder={phrases.NAME_ABBREVIATED_PLACEHOLDER}
					value={value.nameAbbreviated}
					onChange={(e) => onChange(e.target.value, 'nameAbbreviated')}
				/>
			),
		},
		{
			header: phrases.EXCLUDE_IN_PHP,
			value: (
				<Input
					id="exclude-productivity"
					type="checkbox"
					value={value.excludeProductivity}
					onChange={(e) => onChange(e.target.checked, 'excludeProductivity')}
				/>
			),
		},
		{
			header: phrases.GLOBAL_ACCOUNT,
			value: (
				<InputCollectionSelect
					id="global-account"
					placeholder={phrases.GLOBAL_ACCOUNT_PLACEHOLDER}
					value={value.globalAccount}
					handleChange={(key, value) => {
						onChange(value, 'globalAccount');
					}}
					cache
					apiPath="/accounting/global_accounts"
					params={{
						limit: 30,
					}}
					optionFormat={(entry) => ({
						value: entry,
						label: entry.name,
					})}
					inputFilterFormat={(input) => `:name=like='%${input}%'`}
				/>
			),
		},
		{
			header: phrases.EXCLUDE_BASE_PAY,
			value: (
				<Input
					id="exclude-base-pay"
					type="checkbox"
					value={value.excludeBasePay}
					onChange={(e) => onChange(e.target.checked, 'excludeBasePay')}
				/>
			),
		},
		{
			header: phrases.EXCLUDE_ADDON,
			value: (
				<Input
					id="exclude-addon"
					type="checkbox"
					value={value.excludeAddon}
					onChange={(e) => onChange(e.target.checked, 'excludeAddon')}
				/>
			),
		},
		{
			header: phrases.EXCLUDE_OVERTIME,
			value: (
				<Input
					id="exclude-overtime"
					type="checkbox"
					value={value.excludeOvertime}
					onChange={(e) => onChange(e.target.checked, 'excludeOvertime')}
				/>
			),
		},
		{
			header: phrases.CURRENT_YEAR_INDEX,
			value: (
				<Input
					id="current-year-index"
					type="checkbox"
					value={value.currentYearIndex}
					onChange={(e) => onChange(e.target.checked, 'currentYearIndex')}
				/>
			),
		},
		{
			header: phrases.PRIOR_YEAR_INDEX,
			value: (
				<Input
					id="prior-year-index"
					type="checkbox"
					value={value.priorYearIndex}
					onChange={(e) => onChange(e.target.checked, 'priorYearIndex')}
				/>
			),
		},
		{
			header: phrases.COLOR,
			value: (
				<div className="tag__edit-modal-container">
					<ColorPicker
						isOpen={colorPickerOpen}
						onClick={onColorClick}
						onColorChange={onColorChange}
						value={value.color}
					/>
				</div>
			),
		},
	];
}

TagsGetEditableCells.propTypes = {
	value: PropTypes.object,
	onChange: PropTypes.func,
};
