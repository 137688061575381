'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import { InputCollectionSelect, Button, Input } from 'dumb';

import _get from 'lodash/get';

import {
	setProductVariantAddons,
	updateProductVariantAddons,
	resetProductVariantAddons,
} from '../store/productAddons.actions';

import {
	fetchProductVariantAddons,
	deleteProductVariantAddons,
	addProductVariantAddons,
} from '../productAddons.service';

// Wrapper for react-data
// Documentation can be found here https://react-table.js.org/
class ProductVariantAddosn extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this._editEntry = this._editEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);

		this.state = {
			pages: null,
			legacyFilterOn: true,
			legacyFilter: `:legacy==false`,
		};

		this.fetchData = this.fetchData.bind(this);

		this.getLegacyFilterButton = this.getLegacyFilterButton.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);

		this.columns = [
			{
				Header: 'Product Variant',
				id: 'prodvar',
				accessor: (d) => _get(d, 'product_variant.name', null),
				filterPath: ':product_variant.name',
			},
			{
				Header: 'Legacy',
				id: 'legacy',
				accessor: 'legacy',
				filterPath: ':legacy',
				filterable: false,
				Cell: (d) => {
					return <Input id="checkbox" type="checkbox" checked={d.original.legacy} disabled />;
				},
			},
		];
	}

	toggleLegacyFilter() {
		this.setState((prevState) => ({
			legacyFilterOn: !prevState.legacyFilterOn,
		}));
	}

	getLegacyFilterButton() {
		return (
			<Button
				id="legacyFilterButton"
				size="tiny"
				onClick={this.toggleLegacyFilter}
				type={this.state.legacyFilterOn ? '' : 'inverted'}
			>
				Legacy
			</Button>
		);
	}

	deleteEntry(id) {
		return deleteProductVariantAddons(id);
	}

	addEntry() {
		const { defaultValues, addon } = this.props;

		const payload = {
			addon: addon.id,
			product_variant: _get(defaultValues, 'product_variant.id', null),
		};

		return addProductVariantAddons(payload);
	}

	setInitialEditValues(data) {
		this.props.setProductVariantAddons(data);
	}

	getEditableCells() {
		const { defaultValues } = this.props;

		return [
			{
				header: 'Product Variant',
				value: (
					<InputCollectionSelect
						id="product_variant"
						placeholder="select product"
						value={{
							value: _get(defaultValues, 'product_variant.id', ''),
							label: `${_get(defaultValues, 'product_variant.name', '')}`,
						}}
						handleChange={(key, value) =>
							this._editEntry({
								id: value ? value.value : '',
								name: value ? value.label : '',
							})
						}
						clearable
						cache
						apiPath="/product/product_variants"
						params={{
							limit: 300,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
		];
	}

	_editEntry(productVariant) {
		this.props.updateProductVariantAddons({
			product_variant: productVariant,
		});
	}

	fetchData(state) {
		const { addon } = this.props;
		return fetchProductVariantAddons(addon.id, state);
	}

	render() {
		const legacyFilter = this.state.legacyFilterOn ? this.state.legacyFilter : '';

		return (
			<ReactDataWrapper
				accessAreasAllowedToEdit={['Sales Configuration']}
				title={this.props.title}
				className="-striped -highlight"
				columns={this.columns}
				totalEntries={this.state.totalEntries} // Display the total number of pages
				fetchData={this.fetchData} // Request new data when things change
				filterable
				defaultPageSize={10}
				reduxKey={`pos/product_variant_addons/${this.props.addon.id}`}
				manual
				editableCells={this.getEditableCells()}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetProductVariantAddons}
				actionsWidth={30}
				deleteEntry={this.deleteEntry}
				createEntry={this.addEntry}
				customAreaComponents={this.getLegacyFilterButton()}
				extraFilters={legacyFilter}
			/>
		);
	}
}

ProductVariantAddosn.propTypes = {
	addon: PropTypes.object,
	defaultValues: PropTypes.object,
	title: PropTypes.string,

	updateProductVariantAddons: PropTypes.func,
	setProductVariantAddons: PropTypes.func,
	resetProductVariantAddons: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setProductVariantAddons,
			updateProductVariantAddons,
			resetProductVariantAddons,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultValues: store.productAddons.data.defaultProductVariantAddons,
	};
};

export default connectWithStore(ProductVariantAddosn, mapStateToProps, mapDispatchToProps);
