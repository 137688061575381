'use strict';

import PropTypes from 'prop-types';
import cx from 'classnames';
import React from 'react';

import { Backdrop } from 'dumb';
import HotbarComponents from './hotdamnbar.components';

const Hotdamnbar = ({
	activeComponent,
	hotbar,
	hotbarState,
	loading,
	methods,
	onBlurCallback,
	children,
}) => {
	const styles = cx('hotdamnbar', {
		'hotdamnbar--active': activeComponent !== null,
	});

	return (
		<div className={styles}>
			<Backdrop
				isOpen={activeComponent !== null}
				closeBackdrop={() => {}}
				zIndex={200}
			/>
			<HotbarComponents
				{...{ activeComponent, hotbar, hotbarState, methods, onBlurCallback }}>
				{children}
			</HotbarComponents>

			{loading && (
				<div className="hotdamnbar__spinner">
					<div className="hotdamnbar__spinner__spinner" />
				</div>
			)}
		</div>
	);
};

Hotdamnbar.propTypes = {
	activeComponent: PropTypes.object,
	hotbar: PropTypes.object.isRequired,
	loading: PropTypes.bool,
	methods: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
	onBlurCallback: PropTypes.bool,
};

export default Hotdamnbar;
