import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import phrases from './productVariantBasketRecommendations.phrases';
import {
	getProductVariantBasketRecommendationFilter,
	mapProductVariantsToBasketRecommendations,
	mapBasketRecommendationsToProductVariants,
	getProductCategoryFilter,
	getNameFilter,
	getIdFilter,
	constructFinalProductVariantFilter,
} from './utils';

import _isEmpty from 'lodash/isEmpty';

/**
 * @function fetchProductVariantBasketRecommendations
 * @description Extremely custom way of fetching product variants and associated product variant basket recommendations
 */
export function fetchProductVariantBasketRecommendations(
	state,
	legacyFilterOn
) {
	// if basket recommendation filters present, reverse the fetching
	// (i.e. only show rows where the recommendations exist)
	const basketRecommendationsFiltersExist = _isEmpty(state.filterArray)
		? false
		: state.filterArray.find(
				(entry) => entry.id === 'recommendation' || entry.id === 'exclusion'
		  );

	if (basketRecommendationsFiltersExist) {
		return _fetchProductVariantBasketRecommendations({ state }).then(
			(resProductVariantBasketRecommendations) => {
				return _fetchProductVariants({
					state,
					legacyFilterOn,
					productVariantBasketRecommendations:
						resProductVariantBasketRecommendations.data,
				}).then((resProductVariants) => {
					const mappedData = mapProductVariantsToBasketRecommendations({
						productVariants: resProductVariants.data,
						productVariantBasketRecommendations:
							resProductVariantBasketRecommendations.data,
					});

					return {
						...resProductVariantBasketRecommendations,
						data: mappedData,
					};
				});
			}
		);
	} else {
		return _fetchProductVariants({ state, legacyFilterOn }).then(
			(resProductVariants) => {
				return _fetchProductVariantBasketRecommendations({
					state,
					productVariants: resProductVariants.data,
				}).then((resProductVariantBasketRecommendations) => {
					const mappedData = mapBasketRecommendationsToProductVariants({
						productVariants: resProductVariants.data,
						productVariantBasketRecommendations:
							resProductVariantBasketRecommendations.data,
					});

					return {
						...resProductVariants,
						data: mappedData,
					};
				});
			}
		);
	}
}

/**
 * @function addBasketRecommendationPriority
 * @description add basket recommendation priorities
 */
export function addBasketRecommendationPriority(data) {
	return post('/pos/basket_recommendation_priorities', data)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editBasketRecommendationPriority
 * @description edit basket recommendation priorities
 */
export function editBasketRecommendationPriority(data) {
	return post(`/pos/basket_recommendation_priorities/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_BASKET_PRIORITY_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function addProductVariantBasketRecommendation
 * @description add basket recommendation
 */
export function addProductVariantBasketRecommendation(data) {
	return post('/pos/product_variant_basket_recommendations', data)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_BASKET_RECOMMENDATION_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editProductVariantBasketRecommendation
 * @description edit basket recommendation
 */
export function editProductVariantBasketRecommendation(data) {
	return post(`/pos/product_variant_basket_recommendations/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_BASKET_RECOMMENDATION_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function editProductVariantBasketRecommendations
 * @description edit basket recommendation
 */
export function editProductVariantBasketRecommendations(data) {
	return patch('/pos/product_variant_basket_recommendations', data)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_BASKET_RECOMMENDATIONS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteProductVariantBasketRecommendation
 * @description delete product variant recommendation group
 */
export function deleteProductVariantBasketRecommendation(id) {
	return remove(`/pos/product_variant_basket_recommendations/${id}`)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_BASKET_RECOMMENDATION_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

// PRIVATE FUNCTIONS USED FOR FETCHING DATA FOR RDW

function _fetchProductVariants({
	state,
	productVariantBasketRecommendations = [],
	legacyFilterOn,
}) {
	// get filters
	const nameFilter = getNameFilter(state);
	const idFilter = getIdFilter(productVariantBasketRecommendations);
	const categoryFilter = getProductCategoryFilter(state);

	const finalFilter = constructFinalProductVariantFilter({
		nameFilter,
		idFilter,
		categoryFilter,
		legacyFilterOn,
	});

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: finalFilter,
	};

	return get('/product/product_variants', params, null, state.headers)
		.then((res) => res)
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

function _fetchProductVariantBasketRecommendations({
	state,
	productVariants = [],
}) {
	const filter = getProductVariantBasketRecommendationFilter(
		productVariants,
		state.filterArray
	);

	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get('/pos/product_variant_basket_recommendations', params)
		.then((res) => res)
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}
