import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// components
import { Tooltip, Button, Icon } from 'dumb';

import './modalRow.css';

const ModalRow = (props) => {
	const {
		children,
		className,
		typeAddNewRow,
		tooltipText,
		tooltipZIndex,
		onClick,
	} = props;

	const cn = cx('modal-row', {
		[`${className}`]: className,
		'modal-row--add-new-row': !!typeAddNewRow,
	});

	return (
		<div className={cn}>
			{typeAddNewRow ? (
				<Tooltip
					text={tooltipText}
					zIndex={tooltipZIndex}
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							type="secondary"
							onClick={onClick}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
							refProp={ref}>
							<Icon name="add" />
						</Button>
					)}
				/>
			) : (
				children
			)}
		</div>
	);
};

ModalRow.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	typeAddNewRow: PropTypes.bool,
	tooltipText: PropTypes.string,
	tooltipZIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onClick: PropTypes.func,
};

export default ModalRow;
