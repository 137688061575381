export const RESET_STATE = 'INGREDIENT_TRANSLATIONS/RESET_STATE';
export const SET_INGREDIENT_TRANSLATION =
	'INGREDIENT_TRANSLATIONS/SET_INGREDIENT_TRANSLATION';
export const UPDATE_INGREDIENT_TRANSLATION =
	'INGREDIENT_TRANSLATIONS/UPDATE_INGREDIENT_TRANSLATION';

// batch
export const UPDATE_BATCH_FORM = 'INGREDIENT_TRANSLATIONS/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST = 'INGREDIENT_TRANSLATIONS/ADD_BATCH_LIST';
export const REMOVE_BATCH_LIST_ITEM =
	'INGREDIENT_TRANSLATIONS/REMOVE_BATCH_LIST_ITEM';
export const EDIT_BATCH_LIST_ITEM =
	'INGREDIENT_TRANSLATIONS/EDIT_BATCH_LIST_ITEM';

export function setIngredientTranslation(payload) {
	return {
		type: SET_INGREDIENT_TRANSLATION,
		payload,
	};
}

export function updateIngredientTranslation(payload) {
	return {
		type: UPDATE_INGREDIENT_TRANSLATION,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}
