export const SET_POWER_BI_REPORT_TYPES =
	'POWER_BI_REPORT_TYPES/SET_POWER_BI_REPORT_TYPES';
export const UPDATE_POWER_BI_REPORT_TYPES =
	'POWER_BI_REPORT_TYPES/UPDATE_POWER_BI_REPORT_TYPES';
export const RESET_POWER_BI_REPORT_TYPES =
	'POWER_BI_REPORT_TYPES/RESET_POWER_BI_REPORT_TYPES';

export const ADD_BATCH_LIST = 'POWER_BI_REPORT_TYPES/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'POWER_BI_REPORT_TYPES/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'POWER_BI_REPORT_TYPES/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'POWER_BI_REPORT_TYPES/ADD_BATCH_LIST_ITEM_ROW';

export function setPowerBIReportType(payload) {
	return {
		type: SET_POWER_BI_REPORT_TYPES,
		payload,
	};
}

export function updatePowerBIReportType(payload) {
	return {
		type: UPDATE_POWER_BI_REPORT_TYPES,
		payload,
	};
}

export function resetPowerBIReportType() {
	return {
		type: RESET_POWER_BI_REPORT_TYPES,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}
