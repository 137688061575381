'use strict';

import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './globalBundleRules.phrases';

/**
 * @function fetchGlobalBundleRules
 * @description Fetch all global bundle rules
 */
export function fetchGlobalBundleRules(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/product/global_bundle_rules', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function fetchMarkets
 * @description Fetch all markets
 */
export function fetchMarkets(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter,
	};

	return get('/organization/markets', params, null, state.headers)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

export function addGlobalBundleRules(data) {
	return post('/product/global_bundle_rules', data)
		.then((res) => {
			setFeedback(phrases.GLOBAL_BUNDLE_RULES_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editGlobalBundleRule
 * @param {Object} payload - update object
 */
export function editGlobalBundleRule(payload) {
	return post(`/product/global_bundle_rules/${payload.id}`, payload)
		.then((res) => {
			setFeedback(phrases.GLOBAL_BUNDLE_RULE_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editGlobalBundleRules
 * @param {Object} payload - update object
 */
export function editGlobalBundleRules(payload) {
	return patch('/product/global_bundle_rules', payload)
		.then((res) => {
			setFeedback(phrases.GLOBAL_BUNDLE_RULES_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteGlobalBundleRule
 * @param {Number} id - id of a global bundle rule to delete
 */
export function deleteGlobalBundleRule(id) {
	return remove(`/product/global_bundle_rules/${id}`)
		.then((res) => {
			setFeedback(phrases.GLOBAL_BUNDLE_RULE_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
