import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

// Dungeons N' Dragons
import { DropTarget } from 'react-dnd';

// components
import { Loader, Icon } from 'dumb';
import { TableListCell } from 'dumb/tables/table-list';

// styles
import './homeScreenRecommendationsDroppableCell.css';

const HomeScreenRecommendationsDroppableCell = ({
	product,
	connectDropTarget,
	isOverCurrent,
	loading,
	cellActive,
	valueToAdd,
	removeProduct,
	homeScreenRecommendationId,
	productNumber,
}) => {
	const onRemoveProduct = () => {
		removeProduct({
			homeScreenRecommendationId,
			productNumber,
		});
	};

	const [isOver, setIsOVer] = useState(false);

	const showValueToAdd = !product && cellActive;
	const showProduct = !!product;
	const showLoader = !!loading;
	const showCloseIcon = isOver && product && !loading;

	return (
		<TableListCell
			className={cx('home-screen-recommendations-table__cell', {
				'home-screen-recommendations-table__cell--hovered':
					isOverCurrent || cellActive,
				'home-screen-recommendations-table__cell--with-value-to-add':
					!product && cellActive && valueToAdd,
			})}>
			{connectDropTarget(
				<div
					className="home-screen-recommendations-table__cell__inner"
					onMouseEnter={() => setIsOVer(true)}
					onMouseLeave={() => setIsOVer(false)}>
					{showValueToAdd && <span>{valueToAdd}</span>}
					{showProduct && <span>{product.name}</span>}
					{showLoader && (
						<Loader
							className="home-screen-recommendations-table__cell__inner__loader"
							loading
						/>
					)}
					<Icon
						className={cx(
							'home-screen-recommendations-table__cell__inner__close-icon',
							{
								'home-screen-recommendations-table__cell__inner__close-icon--visible': showCloseIcon,
							}
						)}
						name="close"
						onClick={onRemoveProduct}
					/>
				</div>
			)}
		</TableListCell>
	);
};

HomeScreenRecommendationsDroppableCell.propTypes = {
	connectDropTarget: PropTypes.func,
	isOverCurrent: PropTypes.bool,
	loading: PropTypes.bool,
	product: PropTypes.object,
	cellActive: PropTypes.bool,
	valueToAdd: PropTypes.string,
	removeProduct: PropTypes.func,
	homeScreenRecommendationId: PropTypes.number,
	productNumber: PropTypes.number,
};

export default DropTarget(
	'Product-variant',
	{
		drop(props, monitor) {
			const item = monitor.getItem();

			const { productNumber, homeScreenRecommendationId } = props;

			props.onDrop({ item, productNumber, homeScreenRecommendationId });
		},

		hover(props, monitor) {
			// You can do something with it
			monitor.isOver({ shallow: true });
		},
	},
	(connect, monitor) => ({
		connectDropTarget: connect.dropTarget(),
		isOverCurrent: monitor.isOver({ shallow: true }),
		canDrop: monitor.canDrop(),
	})
)(HomeScreenRecommendationsDroppableCell);
