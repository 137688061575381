import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './title.css';

const Title = ({ title, rightAligned }) => {
	const className = cx('report-title', {
		'report-title--right-aligned': rightAligned
	});

	return <h3 className={className}>{title}</h3>;
};

Title.defaultProps = {
	className: '',
	title: ''
};

Title.propTypes = {
	title: PropTypes.string.isRequired,
	rightAligned: PropTypes.bool
};

export default Title;
