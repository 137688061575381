export const RESET_PRODUCT_SET =
	'SALES_CONFIGURATION/RESET_PRODUCT_SET';
export const SET_PRODUCT_SET =
	'SALES_CONFIGURATION/SET_PRODUCT_SET';
export const UPDATE_PRODUCT_SET =
	'SALES_CONFIGURATION/UPDATE_PRODUCT_SET';

/**
 * @param {object} payload
 */
export function setProductSet(payload) {
	return {
		type: SET_PRODUCT_SET,
		payload
	};
}

/**
 * @param {object} payload
 */
export function updateProductSet(payload) {
	return {
		type: UPDATE_PRODUCT_SET,
		payload
	};
}

/**
 * @param {object} payload
 */
export function resetProductSet() {
	return {
		type: RESET_PRODUCT_SET
	};
}