export default {
	TITLE: 'Market shift alignment rules',
	MARKET_SHIFT_ALIGNMENT_RULE_ADDED: 'Market shift alignment rules added!',
	MARKET_SHIFT_ALIGNMENT_RULE_EDITED: 'Market shift alignment rule edited!',
	MARKET_SHIFT_ALIGNMENT_RULES_EDITED: 'Market shift alignment rules edited!',
	MARKET_SHIFT_ALIGNMENT_RULE_DELETED: 'Market shift alignment rule deleted!',

	// batch form
	MODAL_BATCH_STEP_LIST_TITLE: 'Add market shift alignment rules',
	MODAL_BATCH_STEP_LIST_TOOLTIP: 'Click to add a row',
	MODAL_BATCH_STEP_LIST_TABLE_NUMBER: '#',
	MODAL_BATCH_STEP_MARKET: 'Market',
	MODAL_BATCH_STEP_MINIMUM_DEVIATION: 'Minimum deviation',
	MODAL_BATCH_STEP_MAXIMUM_DEVIATION: 'Maximum deviation',
	MODAL_BATCH_STEP_REGISTRATION_TYPE: 'Registration type',
	MODAL_BATCH_STEP_ALIGNMENT_STRATEGY: 'Alignment strategy',

	MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL: 'Add alignment rules',
};
