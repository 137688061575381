/**
 * Created by mikkelmarkvardsen on 22/09/2016.
 */

import extractExternalEmployees from '../utilities/extractExternalEmployees';
import sortShifts from 'utils/sort/sortShifts';
import getSortOrderFromWorkplaceEmployment from 'utilities/getSortOrderFromWorkplaceEmployment';

import mapEarliestAndLatestOpeningHours from '../utilities/mapEarliestAndLatestOpeningHours';
import constants from 'services/constants';

import _get from 'lodash/get';
import _union from 'lodash/union';
import _uniqBy from 'lodash/uniqBy';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import actionsTypes from 'shiftPlanner/actions';

const initialState = {
	isMounted: false,
	activeView: constants.assignView,
	currentStartOfPeriod: moment.utc().startOf('isoweek').isoWeekday(1),
	endOfPeriod: moment.utc().endOf('isoweek'),
	earliestOpeningHour: null,
	emails: [],
	employmentWorkplaces: [],
	weekviewDateRangeType: 'week',
	externalEmployments: [],
	clockingsEmployments: [],
	isExporting: false,
	latestClosingHour: null,
	numberOfWeeksToShow: 1,
	openingHours: {},
	person: null,
	readOnlyRights: true,
	regionalManagers: [],
	shiftBeingUpdated: null,
	shifts: [],
	vacations: [],
	illnesses: [],
	showPhpReport: false,
	workplaceEmployments: [],
	workplaceId: null,
	workplaceDetails: {},
	employeeAddToWorkplace: undefined,
	seniorities: [],
	tagTypes: [],
};

/**
 * @function reducer
 * @description Reduces state to new state
 * @param  {object} state  current state
 * @param  {object} action action to reduce from
 * @return {object}        reduced state
 */
function reducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case 'SET_SHIFTS': {
			const workplaceEmployments = state.workplaceEmployments;
			let earliestOpeningHour = state.earliestOpeningHour;
			let latestClosingHour = state.latestClosingHour;
			const openingHours = state.openingHours;

			// filter away shifts that doesnt belong to current workplace.
			// Fixes an edge case where multiple request for shifts in different bars can be in flight
			let shifts = payload.filter((shift) => shift.workplace.id === state.workplaceDetails.id);

			const currentStartOfPeriod = state.currentStartOfPeriod.clone();
			const endOfPeriod = state.endOfPeriod;

			const externalEmployments = extractExternalEmployees(
				shifts,
				currentStartOfPeriod,
				endOfPeriod,
				workplaceEmployments
			);

			[earliestOpeningHour, latestClosingHour] = mapEarliestAndLatestOpeningHours(openingHours, shifts);

			shifts = sortShifts(shifts);
			shifts = _uniqBy([...state.shifts, ...shifts], 'id');

			return Object.assign({}, state, {
				externalEmployments: [...externalEmployments],
				shifts,
				earliestOpeningHour,
				latestClosingHour,
			});
		}

		case 'ADD_SHIFT': {
			const workplaceEmployments = state.workplaceEmployments;
			let earliestOpeningHour = state.earliestOpeningHour;
			let latestClosingHour = state.latestClosingHour;
			const currentStartOfPeriod = state.currentStartOfPeriod.clone();
			const endOfPeriod = state.endOfPeriod;
			const openingHours = state.openingHours;

			const shifts = _uniqBy([...state.shifts, action.payload], 'id');

			const externalEmployments = extractExternalEmployees(
				shifts,
				currentStartOfPeriod,
				endOfPeriod,
				workplaceEmployments
			);

			[earliestOpeningHour, latestClosingHour] = mapEarliestAndLatestOpeningHours(openingHours, shifts);

			return Object.assign({}, state, {
				externalEmployments,
				shifts,
				earliestOpeningHour,
				latestClosingHour,
			});
		}

		case actionsTypes.MERGE_CLOCKINGS_TO_SHIFTS: {
			// 1. Cross reference with existing workplace employees
			let peopleAreNotAssigned = payload
				.map((x) => {
					const existsInWorkplace = state.workplaceEmployments.find((y) => y.employment.person.id === x.person.id);

					// Return only the checkins without workplace assignment
					if (!existsInWorkplace) return x;
				})
				.filter((i) => i);

			// 2. Cross reference with existing external employees
			peopleAreNotAssigned = peopleAreNotAssigned.reduce((acc, x) => {
				const existsInExternal = x && state.externalEmployments.find((y) => y.employment.person.id === x.person.id);

				// If it exist in external, skip, else push new
				acc = existsInExternal ? acc : [...acc, ...[x]];
				return acc;
			}, []);

			// 3. Build missing people shift objects
			if (peopleAreNotAssigned) {
				const clockingsEmployments = peopleAreNotAssigned.reduce((acc, clocking) => {
					// Check if the same person already had clocking
					const personExists = acc && clocking && acc.find((x) => x.employment.person.id === clocking.person.id);

					// if he was already created, just add clocking ELSE create new one
					if (personExists) {
						return acc.map((x) => {
							if (x.employment.person.id === clocking.person.id) {
								x.shift_clockings = [...x.shift_clockings, ...[clocking]];
								return x;
							} else return x;
						});
					} else {
						const newShift = {
							actual_period: {
								from: null,
								to: null,
							},
							employment: {
								id: null,
								person: clocking ? clocking.person : {},
								company: {},
								workplace: {},
								current_moneyball_sub_position: {},
							},
							id: null,
							planned_period: {
								from: null,
								to: null,
							},
							shift_clockings: [clocking],
						};
						return [...acc, ...[newShift]];
					}
				}, []);

				return Object.assign({}, state, {
					clockingsEmployments,
				});
			} else {
				return state;
			}
		}

		case actionsTypes.MERGE_REVIEW_SHIFTS: {
			const newClocking = payload.data.shift_employees[0].shift_clockings[0];
			const workplaceEmployments = state.workplaceEmployments;
			const currentStartOfPeriod = state.currentStartOfPeriod.clone();
			const endOfPeriod = state.endOfPeriod;

			const shiftExists = state.shifts.some((shift) => shift.id === payload.data.id);

			let shifts = [];

			if (shiftExists) {
				shifts = state.shifts.map((shift) =>
					shift.id === payload.data.id
						? {
								...shift,
								planned_period: payload.data.planned_period
									? Object.assign({}, payload.data.planned_period)
									: shift.planned_period,
								shift_employees: shift.shift_employees.map((shiftEmployee) => {
									return {
										...shiftEmployee,
										shift_clockings:
											shiftEmployee.shift_clockings && shiftEmployee.shift_clockings.length
												? shiftEmployee.shift_clockings.map((shiftClocking) => {
														return {
															...shiftClocking,
															...newClocking,
														};
												  })
												: [newClocking],
									};
								}),
						  }
						: shift
				);
			} else {
				shifts = [...state.shifts, payload.data];
			}

			let externalEmployments = extractExternalEmployees(
				shifts,
				currentStartOfPeriod,
				endOfPeriod,
				workplaceEmployments
			);
			externalEmployments = [...state.externalEmployments, ...externalEmployments];

			return Object.assign({}, state, {
				shifts,
				externalEmployments: _uniqBy(externalEmployments, 'id'),
			});

			// return state;
		}

		case 'UPDATE_SHIFT': {
			const workplaceEmployments = state.workplaceEmployments;
			const currentStartOfPeriod = state.currentStartOfPeriod.clone();
			const endOfPeriod = state.endOfPeriod;
			let earliestOpeningHour = state.earliestOpeningHour;
			let latestClosingHour = state.latestClosingHour;
			const openingHours = state.openingHours;

			const shifts = state.shifts.map((shift) => (shift.id === action.payload.id ? action.payload : shift));

			[earliestOpeningHour, latestClosingHour] = mapEarliestAndLatestOpeningHours(openingHours, shifts);

			// Extract external employments
			const externalEmployments = extractExternalEmployees(
				shifts,
				currentStartOfPeriod,
				endOfPeriod,
				workplaceEmployments
			);

			return Object.assign({}, state, {
				externalEmployments,
				shifts,
				earliestOpeningHour,
				latestClosingHour,
			});
		}

		case 'CLEAN': {
			const openingHours = {};
			const earliestOpeningHour = null;
			const latestClosingHour = null;
			const workplaceEmployments = [];
			const externalEmployments = [];
			const shifts = [];
			const emails = [];

			return Object.assign({}, state, {
				earliestOpeningHour,
				externalEmployments,
				latestClosingHour,
				openingHours,
				emails,
				shifts,
				workplaceEmployments,
			});
		}
		case actionsTypes.REMOVE_CLOCKING_FROM_SHIFT: {
			return Object.assign({}, state, {
				shifts: state.shifts.map((shift) =>
					shift.id === payload.shiftId
						? {
								...shift,
								shift_employees: [
									{
										...shift.shift_employees[0],
										shift_clockings: shift.shift_employees[0].shift_clockings.filter((clocking) => {
											return clocking.id !== payload.clocking.id;
										}),
									},
								],
						  }
						: shift
				),
			});
		}
		case actionsTypes.REMOVE_SHIFT: {
			const shifts = state.shifts;

			const currentStartOfPeriod = state.currentStartOfPeriod.clone();
			const endOfPeriod = state.endOfPeriod;
			const workplaceEmployments = state.workplaceEmployments;

			let externalEmployments = extractExternalEmployees(
				shifts,
				currentStartOfPeriod,
				endOfPeriod,
				workplaceEmployments
			);
			externalEmployments = [...state.externalEmployments, ...externalEmployments];

			return Object.assign({}, state, {
				externalEmployments: _uniqBy(externalEmployments, 'id'),
				shifts: shifts.filter((shift) => shift.id !== action.payload),
			});
		}

		case 'REMOVE_SHIFTS_FROM_PERIOD': {
			let shifts = state.shifts;
			const workplaceEmployments = state.workplaceEmployments;
			const currentStartOfPeriod = state.currentStartOfPeriod.clone();

			const momentStartOfPeriod = moment.utc(action.payload.from, constants.shortDate);
			const momentEndOfPeriod = moment.utc(action.payload.to, constants.shortDate);

			shifts = shifts.filter((shift) => {
				if (
					moment.utc(shift.planned_period.from, constants.dateFormat).isSameOrAfter(momentStartOfPeriod, 'day') &&
					moment.utc(shift.planned_period.to, constants.dateFormat).isSameOrBefore(momentEndOfPeriod, 'day')
				)
					return false;
				else return true;
			});

			const externalEmployments = extractExternalEmployees(
				shifts,
				currentStartOfPeriod,
				momentEndOfPeriod,
				workplaceEmployments
			);

			return Object.assign({}, state, {
				externalEmployments,
				shifts,
			});
		}

		case 'UPDATE_EMPLOYMENT': {
			const shifts = state.shifts;
			const workplaceEmployments = state.workplaceEmployments;

			shifts.map((shift, i) => {
				if (shift.id === action.payload.shift.id) {
					shift.shift_employees.map((shiftEmployee) => {
						if (shiftEmployee.id === action.payload.id) {
							shift.shift_employees = action.payload;
						}
					});
				} else return shift;
			});

			const currentStartOfPeriod = state.currentStartOfPeriod.clone();
			const endOfPeriod = state.endOfPeriod;
			const externalEmployments = extractExternalEmployees(
				shifts,
				currentStartOfPeriod,
				endOfPeriod,
				workplaceEmployments
			);

			return Object.assign({}, state, {
				externalEmployments,
				shifts,
			});
		}

		case actionsTypes.UPDATE_SHIFT_EMPLOYEE: {
			const workplaceEmployments = state.workplaceEmployments;
			const currentStartOfPeriod = state.currentStartOfPeriod.clone();
			const endOfPeriod = state.endOfPeriod;

			const shifts = state.shifts.map((shift) => {
				if (shift.id !== payload.shiftEmployee.shift.id) return shift;
				// return a shfit instance and null-ify the shadow_employee key
				// that way we make sure we remove the shadow employee regardless
				// of if the shift was moved from his block or unassigned
				return {
					...shift,
					shadow_employee: null,
					shift_employees: [payload.shiftEmployee],
				};
			});

			const externalEmployments = extractExternalEmployees(
				shifts,
				currentStartOfPeriod,
				endOfPeriod,
				workplaceEmployments
			);

			return Object.assign({}, state, {
				externalEmployments,
				shifts,
			});
		}

		case 'REMOVE_SHIFT_EMPLOYEE': {
			const shiftEmployeeId = payload.shiftEmployeeId;

			const workplaceEmployments = state.workplaceEmployments;

			const currentStartOfPeriod = state.currentStartOfPeriod.clone();
			const endOfPeriod = state.endOfPeriod;

			const shifts = state.shifts.map((shift) => ({
				...shift,
				shift_employees: [...shift.shift_employees.filter((shiftEmployee) => shiftEmployee.id !== shiftEmployeeId)],
			}));

			const externalEmployments = extractExternalEmployees(
				shifts,
				currentStartOfPeriod,
				endOfPeriod,
				workplaceEmployments
			);

			return Object.assign({}, state, {
				externalEmployments,
				shifts,
			});
		}

		case 'REMOVE_SHIFT_TAG': {
			const tagId = payload.tagId;

			const shifts = state.shifts.map((shift) => ({
				...shift,
				shift_tags: [...shift.shift_tags.filter((shiftTag) => shiftTag.id !== tagId)],
			}));

			return Object.assign({}, state, {
				shifts,
			});
		}

		case 'SET_WORKPLACE_EMPLOYMENTS': {
			let workplaceEmployments = action.payload;
			const shifts = state.shifts;
			const currentStartOfPeriod = state.currentStartOfPeriod.clone();
			const endOfPeriod = state.endOfPeriod;

			// Filter out duplicate persons if a person
			workplaceEmployments = _uniqBy(
				workplaceEmployments,
				(workplaceEmployment) => workplaceEmployment.employment.person.id
			);

			// Sort by moneyball positions
			workplaceEmployments = workplaceEmployments.sort((a, b) => {
				const sortOrderB = getSortOrderFromWorkplaceEmployment(a);
				const sortOrderA = getSortOrderFromWorkplaceEmployment(b);

				if (sortOrderA > sortOrderB) return 1;
				if (sortOrderA < sortOrderB) return -1;

				return 0;
			});

			const externalEmployments = extractExternalEmployees(
				shifts,
				currentStartOfPeriod,
				endOfPeriod,
				workplaceEmployments
			);

			return Object.assign({}, state, {
				workplaceEmployments,
				externalEmployments,
			});
		}

		case actionsTypes.REMOVE_WORKPLACE_EMPLOYMENT: {
			return {
				...state,
				workplaceEmployments: state.workplaceEmployments.filter((entry) => entry.id !== action.payload.id),
			};
		}

		case actionsTypes.ADD_EMPLOYEE_TO_EXTERNAL_EMPLOYMENTS: {
			return {
				...state,
				externalEmployments: [...state.externalEmployments, payload],
			};
		}

		case 'SET_EMPLOYMENT_WORKPLACES': {
			const employmentWorkplaces = action.payload;

			return Object.assign({}, state, {
				employmentWorkplaces,
			});
		}

		case actionsTypes.REMOVE_EMPLOYMENT_WORKPLACE: {
			return {
				...state,
				employmentWorkplaces: state.employmentWorkplaces.filter((entry) => entry.id !== payload.id),
			};
		}

		case 'SET_PERSON_DETAILS': {
			const person = action.payload;
			const workplaceEmployments = state.workplaceEmployments;
			const externalEmployments = state.externalEmployments;
			const regionalManagers = state.regionalManagers;
			const personEmailAddress = _get(person, 'person_email_address.email_address', []);
			const emails = state.isExporting ? _union(state.emails, [personEmailAddress]) : [];

			return Object.assign({}, state, {
				emails,
				workplaceEmployments: [
					...workplaceEmployments.map((workplaceEmployment) =>
						workplaceEmployment.employment.person.id === person.id
							? {
									...workplaceEmployment,
									employment: {
										...workplaceEmployment.employment,
										person,
									},
							  }
							: workplaceEmployment
					),
				],
				externalEmployments: [
					...externalEmployments.map((externalEmployment) =>
						externalEmployment.employment.person.id === person.id
							? {
									...externalEmployment,
									employment: {
										...externalEmployment.employment,
										person: {
											...externalEmployment.employment.person,
											...person,
										},
									},
							  }
							: externalEmployment
					),
				],
				regionalManagers: [
					...regionalManagers.map((regionalManager) =>
						regionalManager.employment.person.id === person.id
							? {
									...regionalManager,
									employment: {
										...regionalManager.employment,
										person: {
											...regionalManager.employment.person,
											...person,
										},
									},
							  }
							: regionalManager
					),
				],
			});
		}

		case actionsTypes.SET_REGIONAL_MANAGERS: {
			return Object.assign({}, state, {
				regionalManagers: payload.regionalManagers,
			});
		}

		case 'SHOW_CALENDAR_VIEW': {
			return Object.assign({}, state, {
				showCalendarView: action.payload,
			});
		}

		case 'TOGGLE_PHP_REPORT': {
			return Object.assign({}, state, {
				showPhpReport: !state.showPhpReport,
			});
		}

		case 'CHANGE_SHIFTPLANNER_VIEW': {
			const activeView = action.payload;

			return Object.assign({}, state, {
				activeView,
			});
		}

		case 'NUMBER_OF_WEEKS_TO_SHOW': {
			let numberOfWeeksToShow = action.payload;
			if (numberOfWeeksToShow < 1) numberOfWeeksToShow = 1;
			return Object.assign({}, state, {
				numberOfWeeksToShow,
			});
		}

		case actionsTypes.SET_START_OF_PERIOD: {
			const currentStartOfPeriod = action.payload;
			let shifts = state.shifts;
			shifts = sortShifts(shifts);

			return Object.assign({}, state, {
				shifts,
				currentStartOfPeriod,
			});
		}

		case actionsTypes.SET_END_OF_PERIOD: {
			const endOfPeriod = action.payload;

			const currentStartOfPeriod = state.currentStartOfPeriod.clone();
			const shifts = state.shifts;
			const workplaceEmployments = state.workplaceEmployments;

			const externalEmployments = extractExternalEmployees(
				shifts,
				currentStartOfPeriod,
				endOfPeriod,
				workplaceEmployments
			);

			return Object.assign({}, state, {
				endOfPeriod,
				externalEmployments,
			});
		}

		case actionsTypes.TOGGLE_WEEKVIEW_DATE_RANGE_TYPE: {
			return Object.assign({}, state, {
				weekviewDateRangeType: state.weekviewDateRangeType === 'week' ? 'month' : 'week',
			});
		}

		case 'SET_IS_EXPORTING_SHIFTPLAN': {
			const isExporting = action.payload;
			const currentStartOfPeriod = state.currentStartOfPeriod.clone();

			return Object.assign({}, state, {
				isExporting,
				currentStartOfPeriod,
			});
		}

		case 'SET_OPENING_HOURS': {
			const openingHours = action.payload;
			let earliestOpeningHour = state.earliestOpeningHour;
			let latestClosingHour = state.latestClosingHour;
			const shifts = state.shifts;

			[earliestOpeningHour, latestClosingHour] = mapEarliestAndLatestOpeningHours(openingHours, shifts);

			return Object.assign({}, state, {
				earliestOpeningHour,
				latestClosingHour,
				openingHours,
			});
		}

		case 'UPDATE_OPENING_HOURS': {
			let openingHours = state.openingHours;
			let earliestOpeningHour = state.earliestOpeningHour;
			let latestClosingHour = state.latestClosingHour;
			const shifts = state.shifts;

			const updatedOpening = action.payload;

			const activeFrom = moment.utc(updatedOpening.active.from);
			if (!openingHours) openingHours = {};
			openingHours[activeFrom.format(constants.shortDate)] = updatedOpening;

			[earliestOpeningHour, latestClosingHour] = mapEarliestAndLatestOpeningHours(openingHours, shifts);

			return Object.assign({}, state, {
				openingHours,
				earliestOpeningHour,
				latestClosingHour,
			});
		}

		case 'UPDATE_SINGLE_OPENING_HOUR': {
			const openingHours = { ...state.openingHours, ...action.payload };
			const [earliestOpeningHour, latestClosingHour] = mapEarliestAndLatestOpeningHours(openingHours, state.shifts);

			return Object.assign({}, state, {
				earliestOpeningHour,
				latestClosingHour,
				openingHours,
			});
		}

		case actionsTypes.SET_VACATIONS: {
			const vacations = _uniqBy([...state.vacations, ...payload], 'id');
			return {
				...state,
				vacations,
			};
		}

		case actionsTypes.UPDATE_VACATION: {
			return {
				...state,
				vacations: state.vacations.map((entry) => {
					if (entry.id === payload.id) return payload;
					return entry;
				}),
			};
		}

		case actionsTypes.DELETE_VACATION: {
			const filteredVacation = state.vacations.filter((x) => x.id !== payload);
			return {
				...state,
				vacations: filteredVacation,
			};
		}

		case actionsTypes.SET_ILLNESS: {
			const illnesses = _uniqBy([...state.illnesses, ...payload], 'id');
			return {
				...state,
				illnesses,
			};
		}

		case actionsTypes.UPDATE_ILLNESS: {
			return {
				...state,
				illnesses: state.illnesses.map((entry) => {
					if (entry.id === payload.id) return payload;
					else return entry;
				}),
			};
		}

		case actionsTypes.DELETE_ILLNESS: {
			const filteredIllnesses = state.illnesses.filter((x) => x.id !== payload);
			return {
				...state,
				illnesses: filteredIllnesses,
			};
		}

		case 'SET_WORKPLACE_DETAILS': {
			return Object.assign({}, state, {
				workplaceDetails: action.payload,
			});
		}

		case actionsTypes.TOGGLE_READ_ONLY_RIGHTS: {
			return Object.assign({}, state, {
				readOnlyRights: !state.readOnlyRights,
			});
		}

		case actionsTypes.REMOVE_TRAINING_MODULE_FROM_SHIFT: {
			const shiftIdToRemoveFrom = payload.shiftId;
			const trainingModuleToRemove = payload.trainingModule.id;
			const shiftTagToRemove = payload.trainingModule.shift_tag.id;

			return Object.assign({}, state, {
				shifts: state.shifts.map((shift) =>
					shift.id === shiftIdToRemoveFrom
						? {
								...shift,
								module_executions: [
									...shift.module_executions.filter(
										(moduleExectution) => moduleExectution.id !== trainingModuleToRemove
									),
								],
								shift_tags: [...shift.shift_tags.filter((shiftTag) => shiftTag.id !== shiftTagToRemove)],
						  }
						: shift
				),
			});
		}

		case actionsTypes.ADD_TRAINING_MODULE_TO_SHIFT: {
			// Congratulations, you have added a module execution.
			// But now you want to show it to the user...
			// We find the day of the module execution and the id of the shift we want to add the module execution to by mapping through
			// the shifts on that particular day.
			// consisting of existing module executions and the new one.
			// When we find the id of the shift that matches to one the module has been added to, return a new array of module executions
			const shiftIdToAddTo = payload.trainingModule.shift_tag.shift.id;
			const shiftTag = payload.trainingModule.shift_tag;

			return Object.assign({}, state, {
				shifts: state.shifts.map((shift) =>
					shift.id === shiftIdToAddTo
						? {
								...shift,
								module_executions: [...shift.module_executions, payload.trainingModule],
								shift_tags: [...shift.shift_tags, shiftTag],
						  }
						: shift
				),
			});
		}

		case actionsTypes.UPDATE_EXISTING_TRAINING_MODULE: {
			// Congratulations, you have added a module execution.
			// But now you want to show it to the user...
			// We find the day of the module execution and the id of the shift we want to add the module execution to by mapping through
			// the shifts on that particular day.
			// consisting of existing module executions and the new one.
			// When we find the id of the shift that matches to one the module has been added to, return a new array of module executions
			const trainingModuleID = payload.trainingModule.id;
			const shiftIdToAddTo = payload.trainingModule.shift_tag.shift.id;
			const shiftTag = payload.trainingModule.shift_tag;

			return Object.assign({}, state, {
				shifts: state.shifts.map((shift) =>
					shift.id === shiftIdToAddTo
						? {
								...shift,
								shift_tags: [...shift.shift_tags, shiftTag],
								module_executions: shift.module_executions.map((moduleExecution) =>
									moduleExecution.id === trainingModuleID
										? {
												...moduleExecution,
												...payload.trainingModule,
										  }
										: moduleExecution
								),
						  }
						: shift
				),
			});
		}

		case actionsTypes.REMOVE_PERSON_SHIFTS: {
			// Filter out personId and currentDay
			const newShifts = state.shifts.filter((x) => {
				const timeFrom = _get(x, 'shift_employees[0].actual_period.from', '');
				return (
					_get(x, 'shift_employees[0].employment.person.id', '') !== payload.personId ||
					!moment(timeFrom, 'YYYY-MM-DDTHH:ss:00+00:00').isSame(moment(payload.date), 'day')
				);
			});

			return {
				...state,
				shifts: newShifts,
			};
		}

		case actionsTypes.ADD_PERSON_SHIFTS: {
			return {
				...state,
				shifts: [...state.shifts, ...payload],
			};
		}

		case actionsTypes.DELETE_SHADOW_EMPLOYEE_SHIFTS: {
			return {
				...state,
				shifts: [
					...state.shifts.filter((entry) => entry.shadow_employee === null || entry.shadow_employee.id !== payload.id),
				],
			};
		}

		case actionsTypes.SET_EMPLOYEE_ADD_EMPLOYEE_MODAL: {
			return {
				...state,
				employeeAddToWorkplace: payload,
			};
		}

		case actionsTypes.ADD_EMPLOYEE_TO_WORKPLACE: {
			return {
				...state,
				workplaceEmployments: [...state.workplaceEmployments, payload],
			};
		}

		case actionsTypes.REMOVE_EXTERNAL_EMPLOYMENT: {
			return {
				...state,
				externalEmployments: state.externalEmployments.filter((entry) => entry.id !== payload.id),
			};
		}

		case actionsTypes.RESET_EMPLOYEE_FOR_WORKPLACE: {
			return {
				...state,
				employeeAddToWorkplace: initialState.employeeAddToWorkplace,
			};
		}

		case actionsTypes.MARK_SHIFTS_WITH_UNGRADED_MODULE_EXECUTIONS: {
			return {
				...state,
				shifts: state.shifts.map((entry) => {
					const shiftPlannedDay = _get(entry, 'planned_period.from', null);

					if (!shiftPlannedDay) return entry;

					const sameDates = moment(payload.date, constants.shortDate).isSame(
						moment(shiftPlannedDay, constants.dateFormat),
						'day'
					);

					const unassignedShift = _isEmpty(entry.shift_employees);

					if (_isEmpty(entry.module_executions) || !sameDates || unassignedShift) return entry;

					const isAnyUngraded = entry.module_executions.some(
						(moduleExecution) => moduleExecution.module_grade_level === null
					);

					return {
						...entry,
						...(isAnyUngraded && {
							submittedWithUngradedModuleExecution: true,
						}),
					};
				}),
			};
		}

		case actionsTypes.UPDATE_SHIFTS_WITH_UNGRADED_MODULE_EXECUTIONS: {
			return {
				...state,
				shifts: state.shifts.map((entry) => {
					if (entry.id === payload.id) {
						return {
							...entry,
							submittedWithUngradedModuleExecution: payload.hasUngradedModuleExecution,
						};
					}

					return entry;
				}),
			};
		}

		case actionsTypes.SET_SENIORITIES: {
			return {
				...state,
				seniorities: payload,
			};
		}

		case actionsTypes.SET_TAG_TYPES: {
			return {
				...state,
				tagTypes: payload,
			};
		}

		case actionsTypes.SET_SHIFTPLANNER_MOUNTED: {
			return {
				...state,
				isMounted: payload,
			};
		}

		case actionsTypes.REMOVE_SHIFTS_FROM_EMPLOYEE_BY_ID: {
			return {
				...state,
				shifts: state.shifts.map((shift) => {
					const makeShiftUnassigned = payload.some((shiftIdToRemove) => shiftIdToRemove === shift.id);

					if (makeShiftUnassigned)
						return {
							...shift,
							shift_employees: [],
						};

					return shift;
				}),
			};
		}

		default:
			return state;
	}
}

export default reducer;
