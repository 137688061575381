'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonGroup, InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';

import phrases from './../../../productVariantDiscountSet.phrases';

import _isEmpty from 'lodash/isEmpty';

class ProductVariantDiscountSetBatchStepForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingModal: false,
		};
	}

	editStoreEntry(e, type) {
		const { updateBatchForm, resetSelection, discountUrl } = this.props;

		if (_isEmpty(e)) {
			resetSelection(discountUrl);
		}

		updateBatchForm({
			[type]: e,
		});
	}

	render() {
		const { batchFormData, toggleOverlay, discountUrl } = this.props;

		return (
			<ModalRow>
				<ButtonGroup type="full-width">
					<InputCollectionSelect
						id="discount"
						className="modal-batch-select"
						label={phrases.MODAL_BATCH_STEP_DISCOUNT}
						placeholder={phrases.MODAL_BATCH_STEP_DISCOUNT_PLACEHOLDER}
						value={batchFormData.discounts}
						handleChange={(key, value) =>
							this.editStoreEntry(value, 'discounts')
						}
						multi
						cache
						apiPath={discountUrl}
						params={{
							limit: 50,
							filter: ':legacy==false',
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>

					<Button
						type="inverted"
						shadow
						size="large"
						onClick={() => toggleOverlay(discountUrl)}>
						<span className="icon icon--more" />
					</Button>
				</ButtonGroup>
			</ModalRow>
		);
	}
}

ProductVariantDiscountSetBatchStepForm.propTypes = {
	batchFormData: PropTypes.object,
	updateBatchForm: PropTypes.func,
	toggleOverlay: PropTypes.func,
	discountUrl: PropTypes.string,
	resetSelection: PropTypes.func,
};

export default ProductVariantDiscountSetBatchStepForm;
