'use strict';

// redux stuff
import { store } from 'appState';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service';
import { get, post } from 'api.vanilla.service';

/**
 * @function getDetails
 * @memberOf staffDetails.services
 * @description Get details for person
 * @param {object} personId : perosn ID
 * @param {object} marketId : market ID
 */
export const getDetails = (personId = null, marketId = null) => {
	const endPoint = `/organization/markets/${marketId}/contacts?filter=:person=='${personId}'`;

	return get(endPoint).then((res) => {
		return res.data[0];
	});
};

/**
 * @function updateDetails
 * @memberOf staffDetails.services
 * @description Update details for person
 * @param {object} id : id of person
 * @param {object} data : data object with
 */
export const updateDetails = (id = null, data = {}) => {
	const endPoint = `/organization/contacts/${id}`;

	return post(endPoint, data)
		.then((res) => {
			return res.data[0];
		})
		.catch((error) => {
			const errorMsg = formatErrorMessage(error);
			setFeedback(errorMsg, 0);
			throw error;
		});
};

/**
 * @function getPin
 * @memberOf SERVICES.Shifts
 * @description Get Pin
 * @param {string} personId : id of person for pin
 */
export const getPin = (id) => {
	return get('/profile/person_pins/' + id).then((data) => {
		return data.data && data.data[0];
	});
};

/**
 * @function updatePin
 * @memberOf SERVICES.Shifts
 * @description Update pin
 * @param {object} personId : id of person for pin
 * @param {number} pin : pincode
 */
export const updatePin = (id = null, pin = null) => {
	const data = { id, pin };

	return post('/profile/person_pins/' + id, data)
		.then((data) => {
			return data.data && data.data[0];
		})
		.catch((error) => {
			const errorMsg = formatErrorMessage(error);
			setFeedback(errorMsg, 0);
			throw error;
		});
};

export function fetchModuleExecutions({ employeeId, state }) {
	const filter = `:module.id=='1'`;

	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(`/training/persons/${employeeId}/qualifications`, params).then(
		(res) => {
			return {
				data: res.data
					.map((entry, index) => {
						// manually add an id cause we aint getting one!
						return {
							id: index,
							...entry,
						};
					})
					.sort(
						(a, b) =>
							a?.module?.moneyball_position?.sort_order -
							b?.module?.moneyball_position?.sort_order
					),
			};
		}
	);
}

export function addReview({ payload, row, reduxKey, isReview }) {
	const endpoint = isReview
		? '/training/reviews'
		: `/training/module_executions/${payload.module_execution}`;

	return post(endpoint, payload)
		.then((res) => {
			// update row entry in react data wrapper list data
			const moduleExecutionToUpdate = isReview
				? {
						...row,
						latest_review: {
							...row.latest_review,
							module_grade_level: res.data[0]?.module_grade_level,
							reviewer: res.data[0]?.reviewer,
							comment: res.data[0]?.comment,
						},
				  }
				: {
						...row,
						latest_module_execution: {
							...row.latest_module_execution,
							module_grade_level: res.data[0]?.module_grade_level,
						},
				  };

			store.dispatch(
				actions.editEntry({ reduxKey, entry: moduleExecutionToUpdate })
			);
		})
		.catch((error) => {
			const errorMsg = formatErrorMessage(error);
			setFeedback(errorMsg, 0);
			throw error;
		});
}

export function fetchSeniority({ personId }) {
	return get(`/shiftplanning/persons/${personId}/seniority`)
		.then((res) => res)
		.catch((err) => {
			throw err;
		});
}
