'use strict';

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ListComponent from 'common/components/list/list.component';
import DetailsComponent from 'common/components/details/details.component';

import TrainingGradeScalesDetails from './services/trainingGradeScalesDetails.service';
import TrainingGradeLevelsList from './services/trainingGradeLevelsList.service';

const trainingGradeScalesDetailsReduxKey = '/training/grade_scales-details';
const trainingGradeScalesDetails = new TrainingGradeScalesDetails(trainingGradeScalesDetailsReduxKey);
const trainingGradeScalesDetailsProps = trainingGradeScalesDetails.getProps();

const trainingGradeLevelsListReduxKey = '/training/grade_levels';
const trainingGradeLevelsList = new TrainingGradeLevelsList(trainingGradeLevelsListReduxKey);
const trainingGradeLevelsListProps = trainingGradeLevelsList.getProps();

export function TrainingGradeScalesDetailsContainer() {
	const { scaleId } = useParams();

	const scaleIdFilter = `:grade_scale.id=='${scaleId}'`;
	trainingGradeScalesDetails.setItemId(scaleId);

	useEffect(() => {
		// Fetch selected employment position
		trainingGradeScalesDetails.initView();

		trainingGradeLevelsList.setFilterQuery(scaleIdFilter);
		trainingGradeLevelsList.setData({
			grade_scale: scaleId,
		});
		trainingGradeLevelsList.initView();
	}, [scaleId, scaleIdFilter]);

	return (
		<>
			<DetailsComponent reduxKey={trainingGradeScalesDetailsReduxKey} {...trainingGradeScalesDetailsProps} />

			<ListComponent reduxKey={trainingGradeLevelsListReduxKey} {...trainingGradeLevelsListProps} />
		</>
	);
}
