import { get, post } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

// utils
import { formatRecepients } from './utils';

// phrases/ enums
import phrases from './sendDocuSignDocument.phrases';
import enums from './sendDocuSignDocument.enums';

/**
 * @function fetchContract
 * @description Fetch contract for employment position
 * @param {object} state
 */
export function fetchContract(state) {
	return get(
		`/signing/employment_positions/${state.employmentPositionId}/contract/recipients`
	)
		.then((res) => formatRecepients(res))
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function fetchAppendix
 * @description Fetch appendix for employment position
 * @param {object} state
 */
export function fetchAppendix(state) {
	return get(
		`/signing/employment_positions/${state.employmentPositionId}/appendix/recipients`
	)
		.then((res) => formatRecepients(res))
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function createEnvelope
 * @description creates envelopes for recepients
 */
export function createEnvelope({ type, employmentPositionId, payload }) {
	const sendingContract = type === enums.SEND_CONTRACT;

	const url = sendingContract
		? `/signing/employment_positions/${employmentPositionId}/contract/envelopes`
		: `/signing/employment_positions/${employmentPositionId}/appendix/envelopes`;

	return post(url, payload)
		.then((res) => {
			const feedbackMessage = sendingContract
				? phrases.CONTRACT_SENT
				: phrases.APPENDIX_SENT;

			setFeedback(feedbackMessage, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
