'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');
const __enums = require('enums.service');

export default class GlobalSalaryComponentsList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'type',
				type: 'select',
				canAdd: true,
				canEdit: true,
				options: {
					enums: __enums.employmentType,
				},
			},
			{
				name: 'name',
				canAdd: true,
				canEdit: true,
				type: 'string',
			},
			{
				name: 'description',
				canAdd: true,
				canEdit: true,
				type: 'string',
			},
			{
				name: 'active',
				type: 'date',
				canAdd: true,
				canEdit: true,
				reference: {
					key: 'from',
					translationKey: 'FROM',
				},
			},
			{
				name: 'active',
				type: 'date',
				canAdd: true,
				canEdit: true,
				reference: {
					key: 'to',
					translationKey: 'TO',
				},
			},
		];
		this.settings = {
			name: 'GlobalSalaryComponentsList',
			pageTitle: true,
			canAdd: true,
			canEdit: true,
			canDelete: true,
			canManipulate: () => validAccess(undefined, ['Global BI Configuration']),

			translationPath: 'SALARY.GLOBAL_SALARY_COMPONENTS',
			endpoint: __endpoints.collection.salary.global_salary_components,
		};
		this.init();
		return this;
	}
}
