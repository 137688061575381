import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchIngredientCategories
 * @description Fetch all ingredient categories
 * @param {object} state 
 */
export function fetchIngredientCategories(state) {

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get(
		`/product/ingredient_categories`,
		params,
		null,
		state.headers
	)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editIngredientCategories
 * @description edit
 */
export function editIngredientCategories(catId, data) {
	return post(`/product/ingredient_categories/${catId}`, data)
		.then(res => {
			setFeedback('ingredients edited', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function addIngredientCategories
 * @description add
 */
export function addIngredientCategories(data) {
	return post(`/product/ingredient_categories`, data)
		.then(res => {
			setFeedback('ingredients added', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteIngredientCategories
 * @description del
 */
export function deleteIngredientCategories(productId) {
	return remove(`/product/ingredient_categories/${productId}`)
		.then(res => {
			setFeedback('ingredients deleted', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}