import base64 from 'base-64';

/**
 * @function convertBinaryToBase64
 * @memberOf FileType
 * @description Converts binary data from selected file to base64 to sent to API
 */
export default function convertBinaryToBase64(file) {
	return new Promise(resolve => {
		// Create fileReader
		const fileReader = new FileReader();

		// Set event onloadend to resolve with base64 encoded target result
		fileReader.onloadend = event => {
			const encoded = base64.encode(event.target.result);
			resolve(encoded);
		};

		// Read file and trigger onloadend
		fileReader.readAsBinaryString(file);
	});
}
