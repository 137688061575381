'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';
import { get } from 'api.vanilla.service';
// Components
// import { SectionCollapsable } from 'dumb';
import { ImageTileSelector } from 'dumb';

/**
 * @function
 * @description Format the assets and finds to first non-empty resolution object and returns it mapped to fit the ImageTileSelector component
 * @param {*} imageAssets
 * @returns {Object}
 */
const imageHelper = (imageAssets = []) => {
	const imageKeys = ['resolution_one', 'resolution_two', 'resolution_three'];

	if (!imageAssets) return;

	return imageAssets.map((image) => {
		return imageKeys
			.filter((key) => !_isEmpty(image[key]))
			.map((key) => {
				return {
					name: image[key].filename,
					src: image[key].url,
					id: image.asset_collection.id,
				};
			})[0];
	});
};

const getSelectedImage = (productTile, images) => {
	if (!productTile) return {};
	else if (_isObject(productTile.asset_collection)) {
		const image = images.find(
			(image) => image.id === productTile.asset_collection.id
		);

		return {
			id: productTile.asset_collection.id,
			name: image.name || '',
		};
	} else {
		const attachedImage = images.find(
			(image) => image.id === productTile.asset_collection
		);
		return {
			id: productTile.asset_collection,
			name: attachedImage ? attachedImage.name : attachedImage,
		};
	}
};

class AddSelectImage extends Component {
	constructor(props) {
		super(props);

		// Binds
		this.selectImage = this.selectImage.bind(this);
		this.imageSearch = this.imageSearch.bind(this);
	}

	selectImage(selectedImage) {
		const { productTile } = this.props;

		const data = {
			...productTile,
			asset_collection:
				_isEmpty(productTile.asset_collection) ||
				productTile.asset_collection.id !== selectedImage.id
					? selectedImage.id
					: null,
		};

		this.props.onImageUpdate(data);
	}

	imageSearch(value) {
		if (!value)
			return new Promise((resolve) => {
				resolve(imageHelper(this.props.imageAssets));
			});

		const filter = `:type=='Menu Logo';:asset_collection.name=like='%${value}%'`;
		return get('/pos/assets', { filter }).then((res) => {
			return imageHelper(res.data);
		});
	}

	render() {
		const images = imageHelper(this.props.imageAssets);
		const selectedImage = getSelectedImage(this.props.productTile, images);

		return (
			<div className="pos-tile-modal__select-image">
				<ImageTileSelector
					images={images}
					onSearch={this.imageSearch}
					onSelect={this.selectImage}
					selectedImage={selectedImage}
					initialNumOfImages={20}
					height={250}
				/>
			</div>
		);
	}
}

AddSelectImage.propTypes = {
	// selectedImage: PropTypes.object,
	productTile: PropTypes.object,
	onImageUpdate: PropTypes.func,
	imageAssets: PropTypes.arrayOf(
		PropTypes.shape({
			src: PropTypes.string.isRequired,
			id: PropTypes.number.isRequired,
			name: PropTypes.number.isRequired,
		})
	).isRequired,
};

export default AddSelectImage;
