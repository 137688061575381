import { get, post, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

/**
 * @function fetchIngredientPricingGroups
 * @description Fetch all ingredient categories
 * @param {object} state
 */
export function fetchIngredientPricingGroups(state) {
	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter: state.filter
	};

	return get(`/product/ingredient_pricing_groups`, params, null, state.headers)
		.then(res => {
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function editIngredientPricingGroup
 * @description edit
 */
export function editIngredientPricingGroup(catId, data) {
	return post(`/product/ingredient_pricing_groups/${catId}`, data)
		.then(res => {
			setFeedback('Ingredient pricing group edited', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			return Promise.reject;
		});
}

/**
 * @function addIngredientPricingGroup
 * @description add
 */
export function addIngredientPricingGroup(data) {
	return post(`/product/ingredient_pricing_groups`, data)
		.then(res => {
			setFeedback('Ingredient pricing group added', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function deleteIngredientPricingGroup
 * @description del
 */
export function deleteIngredientPricingGroup(productId) {
	return remove(`/product/ingredient_pricing_groups/${productId}`)
		.then(res => {
			setFeedback('Ingredient pricing group deleted', 1);
			return res;
		})
		.catch(err => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
