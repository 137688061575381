'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import { InputCollectionSelect } from 'dumb';

import _get from 'lodash/get';

import {
	setPosConfigurationProductVariantIngredientVariation,
	updatePosConfigurationProductVariantIngredientVariation,
	resetPosConfigurationProductVariantIngredientVariation,
} from './store/posConfigurationProductVariantIngredientVariations.actions';

import {
	fetchPosConfigurationProductVariantIngredientVariations,
	addPosConfigurationProductVariantIngredientVariation,
	deletePosConfigurationProductVariantIngredientVariation,
} from './posConfigurationProductVariantIngredientVariations.service';

import phrases from './posConfigurationProductVariantIngredientVariations.phrases';

class PosConfigurationProductVariantIngredientVariations extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);

		this.state = {
			pages: null,
		};

		this.fetchData = this.fetchData.bind(this);

		this.columns = [
			{
				Header: 'Ingredient variation',
				id: 'pos_configuration_ingredient_variation',
				accessor: (d) =>
					_get(d, 'pos_configuration_ingredient_variation.name', ''),
				filterPath: ':pos_configuration_ingredient_variation.name',
			},
		];
	}

	deleteEntry(id) {
		return deletePosConfigurationProductVariantIngredientVariation(id);
	}

	addEntry() {
		const {
			posConfigurationProductVariantIngredientVariation,
			productVariantId,
		} = this.props;

		const payload = {
			pos_configuration_product_variant: productVariantId,
			pos_configuration_ingredient_variation: _get(
				posConfigurationProductVariantIngredientVariation,
				'posConfigurationIngredientVariation.value',
				null
			),
		};

		return addPosConfigurationProductVariantIngredientVariation(payload);
	}

	setInitialEditValues(data) {
		this.props.setPosConfigurationProductVariantIngredientVariation(data);
	}

	getEditableCells() {
		const {
			posConfigurationProductVariantIngredientVariation,
			posConfigurationId,
		} = this.props;

		return [
			{
				header: phrases.MODAL_HEADER_INGREDIENT_VARIATION,
				value: (
					<InputCollectionSelect
						placeholder="Select ingredient variation"
						value={
							posConfigurationProductVariantIngredientVariation.posConfigurationIngredientVariation ||
							null
						}
						onChange={(value) =>
							this.editStoreEntry(value, 'posConfigurationIngredientVariation')
						}
						clearable={false}
						cache
						apiPath="/pos/pos_configuration_ingredient_variations"
						params={{
							filter: `:pos_configuration_version.id=='${posConfigurationId}'`,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
		];
	}

	editStoreEntry(e, name) {
		const payload = {
			[name]: e,
		};

		this.props.updatePosConfigurationProductVariantIngredientVariation(payload);
	}

	fetchData(state) {
		return fetchPosConfigurationProductVariantIngredientVariations(
			this.props.productVariantId,
			state
		);
	}

	render() {
		return (
			<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.TABLE_TITLE}
				columns={this.columns}
				totalEntries={this.state.totalEntries}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={5}
				reduxKey="pos/pos_configuration_product_variant_ingredient_variations"
				manual
				editableCells={this.getEditableCells()}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={
					this.props.resetPosConfigurationProductVariantIngredientVariation
				}
				actionsWidth={30}
				deleteEntry={this.deleteEntry}
				inRowButtons
				createEntry={this.addEntry}
				{...this.state}
			/>
		);
	}
}

PosConfigurationProductVariantIngredientVariations.propTypes = {
	productVariantId: PropTypes.number,
	posConfigurationId: PropTypes.number,
	posConfigurationProductVariantIngredientVariation: PropTypes.object,

	updatePosConfigurationProductVariantIngredientVariation: PropTypes.func,
	setPosConfigurationProductVariantIngredientVariation: PropTypes.func,
	resetPosConfigurationProductVariantIngredientVariation: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setPosConfigurationProductVariantIngredientVariation,
			updatePosConfigurationProductVariantIngredientVariation,
			resetPosConfigurationProductVariantIngredientVariation,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		posConfigurationProductVariantIngredientVariation:
			// store.posConfigurationProductDiscountDetails.data
			store.posConfigurationProductVariantIngredientVariations.data
				.posConfigurationProductVariantIngredientVariation,
	};
};

export default connectWithStore(
	PosConfigurationProductVariantIngredientVariations,
	mapStateToProps,
	mapDispatchToProps
);
