'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class ProductSplitViewHeader extends Component {
	getColumns() {
		const { expandedProducts, expandedId, columns, tables } = this.props;

		return Object.keys(columns).map(columnKey => {
			// Get column in question
			const column = columns[columnKey];

			// Iterate over column cells
			return Object.keys(column.cells).map((cellKey, index) => {
				// Get cell in question
				const cell = column.cells[cellKey];

				const styles = cx('report-old__header-cell', {
					'report-old__header-cell--first': index === 0, // first entry

					'report-old__header-cell--currency': cell.contentType === 'currency',
					'report-old__header-cell--number': cell.contentType === 'number',
					'report-old__header-cell--mixed': cell.contentType === 'mixed',
					'report-old__header-cell--left': cell.contentType === 'left',
					'dynamic-pos-view__product-split--active':
						column.group &&
						expandedProducts[column.group] &&
						expandedProducts[column.group][expandedId],
					...cell.getModifierClassNames({})
				});

				return (
					<th
						key={cellKey + cell.type}
						className={styles}
						colSpan={cell.colSpan}
						style={{
							width: cell.getWidth(tables) ? cell.getWidth(tables) : 'auto'
						}}>
						{cell.getName()}
					</th>
				);
			});
		});
	}

	render() {
		return (
			<thead>
				<tr className="report-old__headers">{this.getColumns()}</tr>
			</thead>
		);
	}
}

ProductSplitViewHeader.propTypes = {
	expandedProducts: PropTypes.object,
	expandedId: PropTypes.string,
	tables: PropTypes.object.isRequired,
	columns: PropTypes.object.isRequired
};

export default ProductSplitViewHeader;
