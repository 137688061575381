'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { store, connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import getMarketsColumns from 'reactDataWrapperColumns/administration/markets.columns';
import { Button, Icon, Tooltip } from 'dumb';
import {
	MarketEditableCells,
	temporaryModal,
} from './components/marketEditableCells/marketEditableCells';
import { push } from 'redux-first-history';
import { Box } from '@mui/material';
import MarketTips from './components/marketTips';

import {
	fetchMarkets,
	// addMarktets,
	deleteMarktets,
	editMarkets,
} from './markets.service';

import {
	setMarkets,
	updateMarkets,
	setWaitingTimeGridEntryModal,
	updateWaitingTimeGridEntry,
	resetMarkets,
	resetWaitingTimeGridEntryModal,
} from './store/markets.actions';

// constants/phrases
import phrases from './markets.phrases';
import constants from 'services/constants';
import { getEditedValues } from 'services/utils';
import moment from 'moment';

// lodash
import _has from 'lodash/has';

const reduxKey = '/administration/markets';

class MarketsList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showMarketTips: false,
			marketTipId: null,
		};

		this.fetchData = this.fetchData.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);
		this.editEntry = this.editEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.getExtraFilters = this.getExtraFilters.bind(this);
		this.getRowActions = this.getRowActions.bind(this);
		this.toggleShowMarketTips = this.toggleShowMarketTips.bind(this);

		this.columns = getMarketsColumns(reduxKey);
	}

	toggleShowMarketTips(marketId) {
		if (!this.state.showMarketTips) this.setState({ marketTipId: marketId });
		if (this.state.showMarketTips) this.setState({ marketTipId: null });

		this.setState((prevState) => ({
			showMarketTips: !prevState.showMarketTips,
		}));
	}

	addEntry() {
		return Promise.reject(
			new Error('CONTACT FREDERIK TROJABORG JULIAN AT FTJ@JOEJUICE.COM')
		);
		// const { market } = this.props;

		// const payload = {
		// 	name: market.name,
		// 	salary_export_format: market.salaryExportFormat.value,
		// 	...(market.country && {
		// 		country: market.country.value.id,
		// 	}),
		// 	...(market.taxLabel && {
		// 		tax_label: market.taxLabel,
		// 	}),
		// 	...(market.sortOrder && {
		// 		sort_order: market.sortOrder,
		// 	}),
		// 	...(market.marketRegion && {
		// 		market_region: market.marketRegion.value.id,
		// 	}),
		// 	precise_shiftplanning: !!market.minuteBasedShiftplaning,
		// 	clocking_alignment_required: !!market.clockinAlignmentRequired,
		// 	break_registration: !!market.breakRegistration,
		// 	auto_close_day: !!market.autoCloseDay,
		// 	require_approval_for_employee_meals:
		// 		!!market.requireApprovalForEmployeeMeals,
		// 	skip_end_of_day_count:
		// 		!!market.skipEndOfDayCount,
		// 	receipt_type: market.receiptType?.value,
		// 	opened: market.opened,
		// 	...(market.closed && {
		// 		closed: market.closed,
		// 	}),
		// 	...(market.defaultBreakTag && {
		// 		default_break_tag: market.defaultBreakTag.value.id,
		// 	}),
		// 	...(market.defaultSalaryGroup && {
		// 		default_salary_group: market.defaultSalaryGroup.value.id,
		// 	}),
		// 	...(market.language && {
		// 		language: market.language.value,
		// 	}),
		// };

		// return addMarktets(payload);
	}

	editEntry() {
		const { market, defaultMarket } = this.props;

		const editedValues = getEditedValues({
			oldData: defaultMarket,
			newData: market,
		});

		const payload = {
			id: market.id,
			name: editedValues.name ?? undefined,
			salary_export_format: editedValues.salaryExportFormat ?? undefined,
			...(_has(editedValues, 'taxLabel') && {
				tax_label: editedValues.taxLabel,
			}),
			...(_has(editedValues, 'sortOrder') && {
				sort_order: editedValues.sortOrder,
			}),
			...(_has(editedValues, 'defaultSalaryGroup') && {
				default_salary_group: editedValues.defaultSalaryGroup
					? editedValues.defaultSalaryGroup.id
					: null,
			}),
			precise_shiftplanning: editedValues.minuteBasedShiftplaning ?? undefined,
			clocking_alignment_required:
				editedValues.clockinAlignmentRequired ?? undefined,
			break_registration: editedValues.breakRegistration ?? undefined,
			auto_close_day: editedValues.autoCloseDay ?? undefined,
			require_approval_for_employee_meals:
				editedValues.requireApprovalForEmployeeMeals ?? undefined,
			skip_end_of_day_count: editedValues.skipEndOfDayCount ?? undefined,
			receipt_type: editedValues.receiptType ?? undefined,
			opened: editedValues.opened ?? undefined,
			closed: editedValues.closed ?? undefined,
			...(_has(editedValues, 'defaultBreakTag') && {
				default_break_tag: editedValues.defaultBreakTag
					? editedValues.defaultBreakTag.id
					: null,
			}),
			...(_has(editedValues, 'language') && {
				language: editedValues.language ? editedValues.language : null,
			}),
		};

		return editMarkets(payload);
	}

	deleteEntry(id) {
		return deleteMarktets(id);
	}

	setInitialEditValues(data) {
		const { setMarkets } = this.props;

		const payload = {
			id: data.id,
			name: data.name,
			salaryExportFormat: {
				value: data.salary_export_format,
				label: data.salary_export_format,
			},
			...(data.country && {
				country: data.country.name,
			}),
			...(data.tax_label && {
				taxLabel: data.tax_label,
			}),
			...(data.sort_order && {
				sortOrder: data.sort_order,
			}),
			...(data.market_region && {
				marketRegion: data.market_region.name,
			}),
			minuteBasedShiftplaning: data.precise_shiftplanning,
			clockinAlignmentRequired: data.clocking_alignment_required,
			breakRegistration: data.break_registration,
			autoCloseDay: data.auto_close_day,
			requireApprovalForEmployeeMeals: data.require_approval_for_employee_meals,
			skipEndOfDayCount: data.skip_end_of_day_count,
			receiptType: {
				value: data.receipt_type,
				label: data.receipt_type,
			},
			opened: data.opened,
			closed: data.closed,
			...(data.default_break_tag && {
				defaultBreakTag: data.default_break_tag.name,
			}),
			...(data.default_salary_group && {
				defaultSalaryGroup: {
					value: data.default_salary_group,
					label: data.default_salary_group.name,
				},
			}),
			...(data.language && {
				language: {
					value: data.language.id,
					label: data.language.name,
				},
			}),
			editMode: true,
		};

		setMarkets(payload);
	}

	editStoreEntry(name, value) {
		const { updateMarkets } = this.props;

		if (name === 'opened' || name === 'closed') {
			value = moment.utc(value).format(constants.shortDate);
		}

		const payload = {
			[name]: value,
		};

		updateMarkets(payload);
	}

	fetchData(state) {
		return fetchMarkets(state);
	}

	getExtraFilters() {
		const { customFilters } = this.props;

		if (!customFilters?.country?.value) return;

		return `:country.id=='${customFilters.country.value}'`;
	}

	getRowActions(d) {
		const goToMarketDetails = () => {
			store.dispatch(push(`${window.location.pathname}/${d.value}`));
		};
		const onShowMarketTipsModal = () => {
			this.toggleShowMarketTips(d.value);
		};

		return (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<Tooltip
					text={phrases.TIPS_EXPORT_TOOLTIP}
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							type="inverted"
							shadow
							dataCy="markets-table-export-tips-button"
							onClick={onShowMarketTipsModal}
							size="micro"
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}>
							<Icon name="attach_money" />
						</Button>
					)}
				/>
				<Box ml={0.5}>
					<Tooltip
						text={phrases.DETAILS_TOOLTIP}
						placement="left"
						renderData={(ref, onMouseEnter, onMouseLeave) => (
							<Button
								type="inverted"
								shadow
								onClick={goToMarketDetails}
								size="micro"
								refProp={ref}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}>
								<Icon name="tune" />
							</Button>
						)}
					/>
				</Box>
			</Box>
		);
	}

	render() {
		return (
			<>
				{this.state.showMarketTips && this.state.marketTipId && (
					<MarketTips
						onClose={this.toggleShowMarketTips}
						marketId={this.state.marketTipId}
						visible={this.state.showMarketTips}
					/>
				)}

				<ReactDataWrapper
					dataCy="markets-table"
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={20}
					reduxKey={reduxKey}
					manual
					editableCells={temporaryModal()}
					editableCellsEdit={MarketEditableCells({
						market: this.props.market,
						editStoreEntry: this.editStoreEntry,
					})}
					createEntry={this.addEntry}
					editEntry={this.editEntry}
					compact
					accessAreasAllowedToEdit={[
						'Organisation/Markets::Edit',
						'Organization Admin',
					]}
					deleteEntry={this.deleteEntry}
					editMultiple={this.editMultiple}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetMarkets}
					actions={this.getRowActions}
					actionsWidth={60}
					extraFilters={this.getExtraFilters()}
				/>
			</>
		);
	}
}

MarketsList.propTypes = {
	setMarkets: PropTypes.func,
	resetMarkets: PropTypes.func,
	updateMarkets: PropTypes.func,
	market: PropTypes.object,
	defaultMarket: PropTypes.object,
	customFilters: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setMarkets,
			updateMarkets,
			updateWaitingTimeGridEntry,
			setWaitingTimeGridEntryModal,
			resetMarkets,
			resetWaitingTimeGridEntryModal,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		market: store.markets.data.market,
		defaultMarket: store.markets.data.defaultMarket,
		customFilters: store.filterSortColumnsData.tables?.[reduxKey]?.custom,
	};
};

export default connectWithStore(
	MarketsList,
	mapStateToProps,
	mapDispatchToProps
);
