export const SET_MARKETS = 'MARKETS/SET_MARKETS';
export const UPDATE_MARKETS = 'MARKETS/UPDATE_MARKETS';
export const RESET_MARKETS = 'MARKETS/RESET_MARKETS';

/**
 * @param {object} payload
 */
export function setMarkets(payload) {
	return {
		type: SET_MARKETS,
		payload,
	};
}
/**
 * @param {object} payload
 */
export function updateMarkets(payload) {
	return {
		type: UPDATE_MARKETS,
		payload,
	};
}

/**
 * @param {object} payload
 */
export function resetMarkets() {
	return {
		type: RESET_MARKETS,
	};
}
