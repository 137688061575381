import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DailyReportMarketRegion from './dailyReportMarketRegion.component';
import DailyReportBaseModel from './dailyReportBaseModel.component';
import sortBySortOrder from './../utilities/sortBySortOrder';
// import groupSortOrder from './../utilities/groupSortOrder';
import _isEmpty from 'lodash/isEmpty';

// Components

// TABLE_TOTAL: 'table-total',
// SUB_TOTAL: 'sub-total',
// SUB_SUBTOTAL: 'sub-subtotal',
// GROUP_TOTAL: 'group-total',
// ENTRY: 'entry'

import { Row, constants } from 'dumb/report';
const ContinentGroupEntries = ({ entries, perspective, borderBottom }) => {
	return entries.sort(sortBySortOrder).map((entry, index) => {
		const newEntry = {
			...entry,
			...(entry.group === 'New Store' && {
				month_same_store_sales: {},
				week_same_store_sales: {},
			}),
		};

		return (
			<Row
				key={entry.id}
				type={constants.TABLE_TYPES.SUB_TOTAL}
				borderBottom={borderBottom && index === entries.length - 1}
			>
				<DailyReportBaseModel entry={newEntry} reportType={perspective} />
			</Row>
		);
	});
};

class DailyReportContinent extends Component {
	_renderRows() {
		const {
			reportData,
			marketRegions,
			totalColSpan,
			expandedMarkets,
			expandedGroups,
			hotbarData,
			forceExpandMarkets,
			forceExpandGroups,
		} = this.props;

		return !_isEmpty(marketRegions)
			? marketRegions.sort(sortBySortOrder).map((entry) => {
					const markets = reportData.markets.filter(
						(reportDataMarket) => reportDataMarket.market_region.id === entry.market_region.id
					);

					const marketRegionGroups = (reportData.marketRegionGroupEntries || []).filter(
						(marketRegionGroupEntry) => marketRegionGroupEntry.market_region.id === entry.market_region.id
					);
					const marketRegionStoreAreaTypeEntries = (reportData.marketRegionStoreAreaTypeEntries || []).filter(
						(marketRegionStoreAreaTypeEntry) =>
							marketRegionStoreAreaTypeEntry.market_region.id === entry.market_region.id
					);

					return (
						<DailyReportMarketRegion
							total={entry}
							key={entry.id}
							totalColSpan={totalColSpan}
							hotbarData={hotbarData}
							reportData={reportData}
							expandedMarkets={expandedMarkets}
							expandedGroups={expandedGroups}
							forceExpandMarkets={forceExpandMarkets}
							forceExpandGroups={forceExpandGroups}
							markets={markets}
							marketRegionGroups={marketRegionGroups}
							marketRegionStoreAreaTypeEntries={marketRegionStoreAreaTypeEntries}
						/>
					);
			  })
			: null;
	}

	_renderTotal() {
		const { reportData, total, hotbarData } = this.props;

		const continentGroupEntries = (reportData.continentGroups || []).filter(
			(reportDataWorkplace) => reportDataWorkplace.continent.id === total.continent.id
		);
		const continentStoreAreaTypeEntries = (reportData.continentStoreAreaTypeEntry || []).filter(
			(reportDataWorkplace) => reportDataWorkplace.continent.id === total.continent.id
		);

		const numberOfAppEntries = (reportData.workplaces || []).filter((workplaceEntry) => {
			return (
				total.continent.id === workplaceEntry.continent.id &&
				(workplaceEntry.store_area_type.name === 'App' || workplaceEntry.store_area_type.name === 'Undefined')
			);
		}).length;

		const totalEntry = {
			...total,
			name: `${total.name.toUpperCase()} in ${total.currency_code} (${total.operation_stores - numberOfAppEntries})`,
		};

		return !_isEmpty(total) ? (
			<>
				<Row key={total.id} type="total">
					<DailyReportBaseModel entry={totalEntry} reportType={hotbarData.perspective} />
				</Row>

				<ContinentGroupEntries perspective={hotbarData.perspective} entries={continentGroupEntries} />
				<ContinentGroupEntries
					perspective={hotbarData.perspective}
					entries={continentStoreAreaTypeEntries}
					borderBottom
				/>
			</>
		) : null;
	}

	render() {
		return (
			<>
				{this._renderRows()}
				{this._renderTotal()}
			</>
		);
	}
}

DailyReportContinent.propTypes = {
	reportData: PropTypes.object,
	hotbarData: PropTypes.object,
	marketRegions: PropTypes.array,
	expandedMarkets: PropTypes.array,
	expandedGroups: PropTypes.array,
	totalColSpan: PropTypes.number,
	total: PropTypes.object,
	forceExpandMarkets: PropTypes.bool,
	forceExpandGroups: PropTypes.bool,
};

DailyReportContinent.defaultProps = {
	reportData: {},
};

export default DailyReportContinent;
