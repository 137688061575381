'use strict';
import _has from 'lodash/has';
import _merge from 'lodash/merge';
import { formatErrorMessage } from 'api/helpers';
import { post } from 'api.vanilla.service';
import { set as setFeedback } from 'feedback.vanilla.service';

/**
 * @function itemCreate
 * @memberOf SERVICES.__models
 * @description
 * Create item from provided data
 * @param  {service} __feedbackData  {@link __feedbackData}
 * @param  {object} data  data to create item based on
 */

module.exports = function (data) {
	return new Promise((resolve, reject) => {
		const state = this.getState();

		// Post data to API endpoint to create
		post(this.getSettings().endpoint.create.path, _merge({}, state.data, this.cleanData(data))).then(
			function (response) {
				const formattedItem = _has(response, 'data') ? this.itemFormat(response.data[0]) : {};

				// Run callbacks
				this.runModelCallbacks();

				// Reset API errors
				this.initApiErrorHeaders(state.headers);

				// Resolve formatted item
				resolve(formattedItem);
			}.bind(this),
			function (response) {
				// Set API errors
				this.initApiErrorHeaders(state.headers, response.data);

				const errorMsg = formatErrorMessage(response);

				// Give feedback
				setFeedback(errorMsg, 0);

				// Reject
				reject(response);
			}.bind(this)
		);
	});
};
