import React, { useEffect } from 'react';
import ListComponent from 'common/components/list/list.component';

import ReportingMarketsList from './services/reportingMarketsList.service.js';

const reportingMarketsListReduxKey = '/reporting/reporting_markets';
const reportingMarketsList = new ReportingMarketsList(reportingMarketsListReduxKey);
const reportingMarketsListProps = reportingMarketsList.getProps();

export function ReportingMarkets() {
	useEffect(() => {
		reportingMarketsList.initView();
	}, []);

	return <ListComponent reduxKey={reportingMarketsListReduxKey} {...reportingMarketsListProps} />;
}
