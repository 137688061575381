'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, InputCollectionSelect } from 'dumb';
import { TableList, TableListRow, TableListCell } from 'dumb/tables/table-list';

// phrases
import phrases from './../../../productVariantDiscountSet.phrases';

// lodash
import _get from 'lodash/get';

class ProductVariantDiscountSetBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		this.props.editBatchListItem({
			...row,
			[type]: e,
		});
	}

	_getIsButtonDisabled(entry) {
		// check if set name entered
		if (!entry.setName) return true;

		return entry.rowPosted || entry.postingRow;
	}

	render() {
		const { batchList } = this.props;

		return (
			<TableList>
				<thead>
					<tr>
						<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
						<th>{phrases.MODAL_BATCH_STEP_DISCOUNT}</th>
						<th>{phrases.MODAL_BATCH_STEP_SET_NAME}</th>
						<th />
					</tr>
				</thead>
				<tbody>
					{batchList.map((entry, i) => {
						const { discount, rowPosted, postingRow } = entry;
						const discountId = _get(discount, 'value.id', null);

						return (
							<TableListRow key={entry.identifier} type={rowPosted && 'light'}>
								<TableListCell>{i + 1}</TableListCell>
								<TableListCell>
									{_get(entry, 'discount.value.name', '')}
								</TableListCell>

								<TableListCell>
									<InputCollectionSelect
										id={`setName-${entry.identifier}`}
										className="modal-batch-select"
										placeholder={phrases.MODAL_BATCH_STEP_SET_NAME_PLACEHOLDER}
										value={entry.setName}
										handleChange={(key, value) =>
											this._editListEntry(value, entry, 'setName')
										}
										cache
										apiPath="/sale/discount_product_sets"
										params={{
											limit: 50,
											filter: `:discount.id=='${discountId}'`,
										}}
										optionFormat={(entry) => ({
											value: entry,
											label: entry.name,
										})}
										inputFilterFormat={(input) => `:name=like='%${input}%'`}
										disabled={rowPosted}
										clearable={false}
									/>
								</TableListCell>

								<TableListCell>
									<Button
										disabled={rowPosted || postingRow}
										type="negative"
										size="tiny"
										onClick={() => this._onRemoveEntry(entry.identifier)}
									>
										<span className="icon icon--delete" />
									</Button>
								</TableListCell>
							</TableListRow>
						);
					})}
				</tbody>
			</TableList>
		);
	}
}

ProductVariantDiscountSetBatchStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
};

export default ProductVariantDiscountSetBatchStepList;
