/* eslint-disable react/prop-types */
import React from 'react';

// components
import { Input } from 'dumb';

// lodash
import _get from 'lodash/get';

export default function getColumns(reduxKey) {
	return [
		{
			Header: 'Category Name',
			id: 'name',
			accessor: (d) => _get(d, 'name', ''),
			filterPath: ':name',
		},
		{
			Header: 'Parent Nutrition Category',
			id: 'parentcat',
			accessor: (d) => _get(d, 'parent_nutrition_category.name', ''),
			filterPath: ':parent_nutrition_category.name',
		},
		{
			Header: 'Sort order',
			id: 'sortord',
			accessor: (d) => _get(d, 'sort_order', ''),
			filterPath: ':sort_order',
		},
		{
			Header: 'Type',
			id: 'type',
			accessor: (d) => _get(d, 'type', ''),
			filterPath: ':type',
		},
		{
			Header: 'EU Unit',
			id: 'euUnits',
			accessor: (d) => _get(d, 'eu_unit', ''),
			filterPath: ':eu_unit',
		},
		{
			Header: 'US Unit',
			id: 'usUnits',
			accessor: (d) => _get(d, 'us_unit', ''),
			filterPath: ':us_unit',
		},
		{
			Header: 'Legacy',
			id: 'legacy',
			accessor: 'legacy',
			filterPath: ':legacy',
			filterable: false,
			Cell: (d) => {
				return <Input type="checkbox" checked={d.original.legacy} disabled />;
			},
		},
	];
}
