import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ListComponent from 'common/components/list/list.component';
import DetailsComponent from 'common/components/details/details.component';

import TrainingModulesDetails from './services/trainingModulesDetails.service';
import TrainingModuleGradeLevelsList from './services/trainingModuleGradeLevelsList.service';
import TrainingModuleBadgesList from './services/trainingModuleBadgesList.service';

const trainingModulesDetailsReduxKey = '/training/modules-details';
const trainingModulesDetails = new TrainingModulesDetails(trainingModulesDetailsReduxKey);
const trainingModulesDetailsProps = trainingModulesDetails.getProps();

const trainingModuleGradeLevelsListReduxKey = '/training/module_grade_levels';
const trainingModuleGradeLevelsList = new TrainingModuleGradeLevelsList(trainingModuleGradeLevelsListReduxKey);
const trainingModuleGradeLevelsListProps = trainingModuleGradeLevelsList.getProps();

const trainingModuleBadgesListReduxKey = '/hr/badges';
const trainingModuleBadgesList = new TrainingModuleBadgesList(trainingModuleBadgesListReduxKey);
const trainingModuleBadgesListProps = trainingModuleBadgesList.getProps();

export function TrainingModulesDetailsContainer() {
	const { moduleId } = useParams();

	const moduleIdFilter = `:module.id=='${moduleId}'`;
	trainingModulesDetails.setItemId(moduleId);

	useEffect(() => {
		// Fetch selected employment position
		trainingModulesDetails.initView().then((trainingModule) => {
			const badgefilter = `:badge.id=='${trainingModule.badge.id}'`;
			trainingModuleGradeLevelsList.setFilterQuery(moduleIdFilter);
			trainingModuleGradeLevelsList.setData({
				module: moduleId,
			});
			trainingModuleGradeLevelsList.initView();

			trainingModuleBadgesList.setFilterQuery(badgefilter);
			trainingModuleBadgesList.setData({
				id: trainingModule.badge.id,
			});
			trainingModuleBadgesList.initView();
		});
	}, [moduleId, moduleIdFilter]);

	return (
		<>
			<DetailsComponent reduxKey={trainingModulesDetailsReduxKey} {...trainingModulesDetailsProps} />

			<ListComponent reduxKey={trainingModuleBadgesListReduxKey} {...trainingModuleBadgesListProps} />
			<ListComponent reduxKey={trainingModuleGradeLevelsListReduxKey} {...trainingModuleGradeLevelsListProps} />
		</>
	);
}
