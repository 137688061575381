'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// rdw actions
import { store } from 'appState';
import * as actions from 'reactDataWrapper/reactDataWrapper.actions';

// components
import { ModalStepper, Overlay } from 'dumb';

// utils
import { isMobile } from 'detectMobile.vanilla';

// tables for batch view
import PosConfigurationVersionTable from './../../../../../pos/components/posConfigurationVersions/posConfigurationVersions.component';
import DiscountsTable from './../../../../../discounts/components/discounts/discounts.component';

// different batch views
import SalesConfigurationPosConfigurationDiscountsBatchStepForm from './components/salesConfigurationPosConfigurationDiscountsBatchStepForm';
import SalesConfigurationPosConfigurationDiscountsBatchStepList from './components/salesConfigurationPosConfigurationDiscountsBatchStepList';
import SalesConfigurationPosConfigurationDiscountsBatchVersion from './components/salesConfigurationPosConfigurationDiscountsBatchVersion';

// phrases/ constants
import phrases from './../../salesConfigurationPosConfigurationDiscounts.phrases';
import constants from 'services/constants';

// loash
import _get from 'lodash/get';
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';
import _uniqBy from 'lodash/uniqBy';

// styles
import './salesConfigurationPosConfigurationDiscountsModalBatch.css';

class SalesConfigurationPosConfigurationDiscountsModalBatch extends Component {
	constructor(props) {
		super(props);

		this.discountUrlReduxKey =
			'global-pos-configuration-discounts/sale/discounts';
		this.loyaltyProductUrl = '/pos/pos_configuration_product_variants';
		this.posConfigurationVersionReduxKey =
			'global-pos-configuration-discounts/pos/pos_configuration_versions';

		this.todayDate = moment.utc().format(constants.shortDate);

		this.state = {
			showOverlay: false,
			overlayType: null,
			posConfigurationVersionDateFilter: `:active.from=le='${this.todayDate}';:active.to=ge='${this.todayDate}'`,
		};

		this.handleToggleOverlay = this.handleToggleOverlay.bind(this);
		this.onOverlayOpen = this.onOverlayOpen.bind(this);
		this._getIsBatchStepFormValid = this._getIsBatchStepFormValid.bind(this);
		this.resetSelectedRows = this.resetSelectedRows.bind(this);

		this.modalRef = React.createRef();
	}

	handleToggleOverlay(type) {
		const {
			listPosConfigurationVersions,
			listDiscounts,
			updateBatchForm,
		} = this.props;

		if (this.state.overlayType === this.posConfigurationVersionReduxKey) {
			const selectedRowsInList = _get(
				listPosConfigurationVersions,
				'ui.selectedRows',
				[]
			);

			updateBatchForm({
				posConfigurationVersion: selectedRowsInList.map((entry) => {
					return { label: entry.name, value: entry };
				}),
			});
		} else {
			const selectedRowsInList = _get(listDiscounts, 'ui.selectedRows', []);

			updateBatchForm({
				discount: selectedRowsInList.map((entry) => {
					return { label: entry.name, value: entry };
				}),
			});
		}

		this.setState((prevState) => ({
			showOverlay: !prevState.showOverlay,
			overlayType: prevState.overlayType ? '' : type,
		}));
	}

	onOverlayOpen() {
		const { batchFormData } = this.props;
		const { overlayType } = this.state;

		if (overlayType === this.discountUrlReduxKey) {
			if (_isEmpty(batchFormData.discount)) return;

			batchFormData.discount.map((entry) => {
				store.dispatch(
					actions.addRowToSelection({
						reduxKey: overlayType,
						data: entry.value,
					})
				);
			});
		} else {
			if (_isEmpty(batchFormData.posConfigurationVersion)) return;

			batchFormData.posConfigurationVersion.map((entry) => {
				store.dispatch(
					actions.addRowToSelection({
						reduxKey: overlayType,
						data: entry.value,
					})
				);
			});
		}
	}

	_onSubmitForm() {
		const { batchFormData, addBatchList } = this.props;

		// clear duplicates
		const cleanDiscounts = _uniqBy(batchFormData.discount, 'value.id');
		const cleanPosConfigurationVersions = _uniqBy(
			batchFormData.posConfigurationVersion,
			'value.id'
		);

		const listArray = cleanPosConfigurationVersions.reduce(
			(acc, currentValue) => {
				const values = cleanDiscounts.map((entry) => {
					return {
						identifier: _uniqueId('row-'),
						posConfigurationVersion: currentValue,
						discount: entry,
						name: batchFormData.name,
						description: batchFormData.description,
					};
				});

				return [...acc, ...values];
			},
			[]
		);

		addBatchList(listArray);

		this._goToStep(3);
	}

	_goToStep(step) {
		this.modalRef.current.goToStep(step);
	}

	_batchSubmit() {
		const { batchList, handleClose, batchAddData } = this.props;

		this.setState(() => ({
			loadingModal: true,
		}));

		const payload = batchList.map((entry) => {
			return {
				pos_configuration_version: _get(
					entry,
					'posConfigurationVersion.value.id',
					null
				),
				discount: entry.discount.value.id,
				...(entry.loyaltyProduct && {
					loyalty_product: _get(entry, 'loyaltyProduct.value.id', null),
				}),
				name: entry.name,
				description: entry.description,
			};
		});

		batchAddData({ batch: payload })
			.then(() => {
				handleClose();

				this.resetSelectedRows(this.discountUrlReduxKey);
				this.resetSelectedRows(this.loyaltyProductUrl);
				this.resetSelectedRows(this.posConfigurationVersionReduxKey);

				this.setState(() => ({
					loadingModal: false,
				}));
			})
			.catch(() => {
				this.setState(() => ({
					loadingModal: false,
				}));
			});
	}

	_getIsBatchStepFormValid() {
		const { batchFormData } = this.props;

		return (
			batchFormData.discount && batchFormData.name && batchFormData.description
		);
	}

	_getIsBatchStepFormVersionValid() {
		const { batchFormData } = this.props;

		return !_isEmpty(batchFormData.posConfigurationVersion);
	}

	resetSelectedRows(reduxKey) {
		const { listDiscounts, listPosConfigurationVersions } = this.props;

		if (reduxKey === this.discountUrlReduxKey)
			listDiscounts && store.dispatch(actions.resetSelection({ reduxKey }));
		else
			listPosConfigurationVersions &&
				store.dispatch(actions.resetSelection({ reduxKey }));
	}

	render() {
		const {
			modalVisible,
			updateBatchForm,
			batchFormData,
			handleClose,
			batchList,
			editBatchListItem,
			removeBatchListItem,
		} = this.props;
		const { showOverlay, posConfigurationVersionDateFilter } = this.state;

		const steps = [
			{
				component: (
					<SalesConfigurationPosConfigurationDiscountsBatchVersion
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.handleToggleOverlay}
						posConfigurationVersionReduxKey={
							this.posConfigurationVersionReduxKey
						}
						resetSelection={this.resetSelectedRows}
						posConfigurationVersionDateFilter={
							posConfigurationVersionDateFilter
						}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE_VERSION,
				isValid: this._getIsBatchStepFormVersionValid(),
				onNext: () => this._goToStep(2),
				loading: false,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				noScroll: false,
				allowOverflow: true,
			},
			{
				component: (
					<SalesConfigurationPosConfigurationDiscountsBatchStepForm
						batchFormData={batchFormData}
						updateBatchForm={updateBatchForm}
						toggleOverlay={this.handleToggleOverlay}
						discountUrlReduxKey={this.discountUrlReduxKey}
						resetSelection={this.resetSelectedRows}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_FORM_TITLE,
				isValid: this._getIsBatchStepFormValid(),
				onNext: () => this._onSubmitForm(),
				loading: false,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_FORM_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				noScroll: false,
				allowOverflow: true,
			},
			{
				component: (
					<SalesConfigurationPosConfigurationDiscountsBatchStepList
						batchList={batchList}
						editBatchListItem={editBatchListItem}
						removeBatchListItem={removeBatchListItem}
						loyaltyProductUrl={this.loyaltyProductUrl}
					/>
				),
				title: phrases.MODAL_BATCH_STEP_LIST_TITLE,
				isValid: true,
				onNext: () => this._batchSubmit(),
				onBack: () => this._goToStep(2),
				loading: this.state.loadingModal,
				confirmButtonLabel: phrases.MODAL_BATCH_STEP_LIST_CONFIRM_BUTTON_LABEL,
				cancelButtonLabel: phrases.MODAL_BATCH_STEP_LIST_BACK_BUTTON_LABEL,
				defaultStyles: false,
				noScroll: false,
			},
		];

		return (
			<>
				{modalVisible && (
					<ModalStepper
						className="modal-batch"
						ref={this.modalRef}
						isOpen={modalVisible}
						steps={steps}
						showStep={false}
						onClose={showOverlay ? () => {} : handleClose}
					/>
				)}

				<Overlay
					zIndex={551}
					// height={isMobile() ? 480 : 680}
					list
					visible={showOverlay}
					onClose={() => {
						this.handleToggleOverlay();
					}}
					render={(height) => {
						return (
							<>
								{this.state.overlayType ===
									this.posConfigurationVersionReduxKey && (
									<PosConfigurationVersionTable
										batchSelection
										defaultPageSize={20}
										onInitialization={() => this.onOverlayOpen()}
										reduxKey={this.state.overlayType}
										style={{
											maxHeight: `${height - 130}px`,
										}}
									/>
								)}
								{this.state.overlayType === this.discountUrlReduxKey && (
									<DiscountsTable
										batchSelection
										defaultPageSize={20}
										onInitialization={() => this.onOverlayOpen()}
										reduxKey={this.state.overlayType}
										style={{
											maxHeight: `${height - 130}px`,
										}}
									/>
								)}
							</>
						);
					}}
				/>
			</>
		);
	}
}

SalesConfigurationPosConfigurationDiscountsModalBatch.propTypes = {
	modalVisible: PropTypes.bool,
	handleClose: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	listDiscounts: PropTypes.object,
	listPosConfigurationVersions: PropTypes.object,
	addBatchList: PropTypes.func,
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	batchAddData: PropTypes.func,
};

export default SalesConfigurationPosConfigurationDiscountsModalBatch;
