import React from 'react';
import PropTypes from 'prop-types';

// components
import { InputCleave } from 'dumb';
import { ModalRow } from 'dumb/modal';

// utils
import moment from 'moment';
import constants from 'services/constants';
import phrases from './../shiftClockinsModal.phrases';

const MarkManagedClockinAddBreak = ({
	breakObject,
	addBreakModalUpdateBreak,
	getBreakEndsOnAnotherDay,
}) => {
	const updateBreakTimes = (name, value) => {
		const isValueValid = moment(value, constants.timeShort, true).isValid();

		if (!isValueValid) return;

		const data = {
			[name]: value,
		};

		addBreakModalUpdateBreak(data);
	};

	const breakEndsOnAnotherDay = getBreakEndsOnAnotherDay();

	return (
		<ModalRow>
			<InputCleave
				id="from"
				label="From"
				options={{
					time: true,
					timePattern: ['h', 'm'],
				}}
				placeholder="hh:mm"
				value={breakObject.from || ''}
				onChange={(e) => updateBreakTimes('from', e.target.value)}
			/>
			<div className="shift-clockins-modal__time-picker-wrapper">
				<InputCleave
					id="to"
					value={breakObject.to || ''}
					label="To"
					options={{
						time: true,
						timePattern: ['h', 'm'],
					}}
					placeholder="hh:mm"
					onChange={(e) => updateBreakTimes('to', e.target.value)}
				/>
				{breakEndsOnAnotherDay && (
					<span className="shift-clockins-modal__time-picker-wrapper__extra-day">
						{phrases.EXTRA_DAY}
					</span>
				)}
			</div>
		</ModalRow>
	);
};

MarkManagedClockinAddBreak.propTypes = {
	breakObject: PropTypes.object,
	addBreakModalUpdateBreak: PropTypes.func,
	getBreakEndsOnAnotherDay: PropTypes.func,
};

export default MarkManagedClockinAddBreak;
