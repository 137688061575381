'use strict';

import { get } from 'api.vanilla.service';

/**
 * @function fetchCollection
 * @memberOf SERVICES.__models
 * @description
 * Fetch collection from API endpoint. Formats collection before resolving. Returns as array where [collection, collectionLength]
 * @param  {string} endpoint  endpoint to get
 * @param  {object} params  params to get endpoint with
 */

import _has from 'lodash/has';

module.exports = function ( endpoint, params, headers = undefined) {
	return get(endpoint, params, undefined, headers).then(
		function (response) {
			const formattedCollection = _has(response, 'data')
				? this.collectionFormat(response.data)
				: [];
			const collectionLength = _has(response, 'paging')
				? response.paging.total_entries
				: 0;
			return [formattedCollection, collectionLength];
		}.bind(this)
	);
};
