'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

// redux
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';
import { batch } from 'react-redux';

// redux actions
import {
	setTableFilter,
	setDuplicateListData,
	clearTableFilters,
} from './store/productVariantWorkplaceRelations.actions';
import { setListData } from 'reactDataWrapper/reactDataWrapper.actions';

// components
import { Input, SingleDatePickerInput, Toggle, Button, Icon } from 'dumb';
import { Tip } from 'dumb/tips';
import { ReactDataWrapper } from 'reactDataWrapper';

// lodash
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

// phrases/constants
import phrases from './productVariantWorkplaceRelations.phrases';
import constants from 'services/constants';
import moment from 'moment';

// utils
import { getFilteredListData } from './utils';

import { fetchProductVariantsWorkplaceRelations } from './productVariantWorkplaceRelations.service';

// styles
import './productVariantWorkplaceRelations.css';

class ProductVariantWorkplaceRelations extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tableFilterDate: moment.utc().format(constants.shortDate),
		};

		this.fetchData = this.fetchData.bind(this);
		this._onDateFilterChange = this._onDateFilterChange.bind(this);
		this.setTableFilterWrapper = this.setTableFilterWrapper.bind(this);
		this.clearTableFilterWrapper = this.clearTableFilterWrapper.bind(this);

		this.reduxKey = `/product/product_variants/${this.props.productVariant.id}/workplace_relations`;

		this.columns = [
			{
				Header: 'Workplace',
				id: 'workplace',
				accessor: (d) => _get(d, 'workplace.name', ''),
				sortable: false,
				Filter: ({ column }) => (
					<input
						className="product-variant-workplace-relations__filter-input"
						id={column.id}
						placeholder="Search for workplace..."
						value={this.props.tableFilters.workplace || ''}
						onChange={(e) => this.setTableFilterWrapper('workplace', e.target.value)}
					/>
				),
			},
			{
				Header: 'Market',
				id: 'market',
				accessor: (d) => _get(d, 'market.name', ''),
				sortable: false,
				Filter: ({ column }) => (
					<input
						id={column.id}
						className="product-variant-workplace-relations__filter-input"
						placeholder="Search for market..."
						value={this.props.tableFilters.market || ''}
						onChange={(e) => this.setTableFilterWrapper('market', e.target.value)}
					/>
				),
			},
			{
				Header: 'Available on POS',
				id: 'availableOnPos',
				accessor: 'available_on_pos',
				sortable: false,
				Cell: (d) => {
					return (
						<Input
							id="legacy-available"
							type="checkbox"
							checked={_get(d, 'original.available_on_pos', false)}
							disabled
						/>
					);
				},
				Filter: ({ column }) => (
					<div className="product-variant-workplace-relations__toggle-wrapper">
						<Toggle
							id={column.id}
							toggled={this.props.tableFilters.availableOnPos}
							onClick={(event) => this.setTableFilterWrapper('availableOnPos', event)}
							size="small"
						/>
					</div>
				),
			},
			{
				Header: 'Available on App',
				id: 'availableOnApp',
				accessor: 'available_on_app',
				sortable: false,
				Cell: (d) => {
					return (
						<Input
							id="legacy-available"
							type="checkbox"
							checked={_get(d, 'original.available_on_app', false)}
							disabled
						/>
					);
				},
				Filter: ({ column }) => (
					<div className="product-variant-workplace-relations__toggle-wrapper">
						<Toggle
							id={column.id}
							toggled={this.props.tableFilters.availableOnApp}
							onClick={(event) => this.setTableFilterWrapper('availableOnApp', event)}
							size="small"
						/>
					</div>
				),
			},
		];
	}

	setTableFilterWrapper(name, value) {
		const { setTableFilter, duplicateListData, setListData } = this.props;

		const payload = {
			[name]: value,
		};

		setTableFilter(payload);

		const filteredListData = getFilteredListData({ duplicateListData });

		setListData({
			reduxKey: this.reduxKey,
			listData: filteredListData,
		});
	}

	clearTableFilterWrapper() {
		const { clearTableFilters, setListData, duplicateListData } = this.props;

		batch(() => {
			clearTableFilters();
			setListData({
				reduxKey: this.reduxKey,
				listData: duplicateListData,
			});
		});
	}

	_onDateFilterChange(date) {
		const formattedDate = date ? moment.utc(date).format(constants.shortDate) : null;

		const filterString = formattedDate || '';

		this.setState(() => ({
			tableFilterDate: filterString,
		}));
	}

	getDateSelector() {
		const { tableFilters } = this.props;

		return (
			<>
				<SingleDatePickerInput
					id="productVariantWorkplaceRelationsDatePicker"
					label={phrases.TABLE_FILTER_DATE}
					type="single"
					appendToBody
					fixed
					onChange={this._onDateFilterChange}
					selectedDate={this.state.tableFilterDate}
					noClockButton
					clearButtonDisabled={!this.state.tableFilterDate}
				/>
				{!_isEmpty(tableFilters) && (
					<Button type="primary" shadow onClick={this.clearTableFilterWrapper} label={phrases.CLEAR_TABLE_FILTES}>
						<Icon name="clear" />
					</Button>
				)}
			</>
		);
	}

	fetchData(state) {
		const { productVariant, setDuplicateListData } = this.props;

		return fetchProductVariantsWorkplaceRelations({
			state,
			productVariantId: productVariant.id,
			date: moment.utc().format(constants.shortDate),
		}).then((res) => {
			const data = res.data;

			setDuplicateListData(data);

			return res;
		});
	}

	render() {
		return (
			<>
				<Tip className="product-variant-workplace-relations__tip" content={phrases.TIP} />
				<ReactDataWrapper
					accessAreasAllowedToEdit={['Sales Configuration']}
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={this.reduxKey}
					manual
					style={{ maxHeight: 380 }}
					customAreaComponents={this.getDateSelector()}
					extraFilters={this.state.tableFilterDate}
					showPagination={false}
				/>
			</>
		);
	}
}

ProductVariantWorkplaceRelations.propTypes = {
	productVariant: PropTypes.object,
	tableFilters: PropTypes.object,
	setTableFilter: PropTypes.func,
	setListData: PropTypes.func,
	setDuplicateListData: PropTypes.func,
	clearTableFilters: PropTypes.func,
	duplicateListData: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setTableFilter,
			setListData,
			setDuplicateListData,
			clearTableFilters,
		},
		dispatch
	);
};

const mapStateToProps = (store, ownProps) => {
	return {
		tableFilters: store.productVariantWorkplaceRelations.tableFilters,
		duplicateListData: store.productVariantWorkplaceRelations.duplicateListData,
		listData: store.listData[`/product/product_variants/${ownProps.productVariant.id}/workplace_relations`],
	};
};

export default connectWithStore(ProductVariantWorkplaceRelations, mapStateToProps, mapDispatchToProps);
