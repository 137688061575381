import { SET_DATA, TOGGLE_MODAL } from './confirmModal.actions';

const defaultState = {
	data: null,
	isOpen: false,
};

function reducer(state = defaultState, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_DATA:
			return {
				...state,
				data: payload,
			};

		case TOGGLE_MODAL:
			return {
				...state,
				isOpen: !state.isOpen,
			};

		default:
			return state;
	}
}

export default reducer;
