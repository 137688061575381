export const SET_KPIS = 'SHIFTPLANNER_KPI/SET_KPIS';
export const TOGGLE_FETCHING = 'SHIFTPLANNER_KPI/TOGGLE_FETCHING';
export const RESET_KPIS = 'SHIFTPLANNER_KPI/RESET_KPIS';

export function setKpis(shiftplannerKpis, weekNumber) {
	return {
		type: SET_KPIS,
		payload: { shiftplannerKpis, weekNumber }
	};
}

export function resetKpis() {
	return {
		type: RESET_KPIS
	};
}

export function toggleFetching(fetching) {

	return {
		type: TOGGLE_FETCHING,
		payload: { fetching }
	};
}
