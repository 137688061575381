export const RESET_PRODUCT_VARIANT_GROUP_PRODUCT_VARIANT =
	'SALES_CONFIGURATION_PRODUCT_VARIANT_GROUPS/RESET_PRODUCT_VARIANT_GROUP_PRODUCT_VARIANT';

export const UPDATE_BATCH_FORM =
	'SALES_CONFIGURATION_PRODUCT_VARIANT_GROUPS/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST =
	'SALES_CONFIGURATION_FILTER_PRODUCT_VARIANTS/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_PRODUCT_VARIANT_GROUPS/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'SALES_CONFIGURATION_PRODUCT_VARIANT_GROUPS/REMOVE_BATCH_LIST_ITEM';
export const ADD_BATCH_LIST_ITEM_ROW =
	'SALES_CONFIGURATION_PRODUCT_VARIANT_GROUPS/ADD_BATCH_LIST_ITEM_ROW';

export function resetProductVariantGroupProductVariant() {
	return {
		type: RESET_PRODUCT_VARIANT_GROUP_PRODUCT_VARIANT,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function addBatchListItemRow() {
	return {
		type: ADD_BATCH_LIST_ITEM_ROW,
	};
}

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}
