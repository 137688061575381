import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Inputs
import Select from 'react-select';
import { Label, Icon, Tooltip } from 'dumb';

// utils
import { copyToClipboard } from 'services/utils';
import { set as setFeedback } from 'feedback.vanilla.service.js';

// phrases
import phrases from './input.phrases';

import './input.css';

export default class Input extends PureComponent {
	constructor(props) {
		super(props);

		this.copyToClipboard = this.copyToClipboard.bind(this);
	}

	copyToClipboard() {
		const { value } = this.props;

		if (!value) return;

		try {
			copyToClipboard(value);
			setFeedback(phrases.COPIED_TO_CLIPBOARD, 1, 5000, true);
		} catch (error) {
			setFeedback(
				`${phrases.COPIED_TO_CLIPBOARD_ERROR}: ${value}`,
				0,
				10000,
				true
			);
		}
	}

	render() {
		const {
			id,
			type,
			label,
			className,
			value,
			options,
			onChange,
			disabled,
			placeholder,
			clearable,
			required,
			size,
			enableValueCopy,
			copyTooltipZindex,
			fullWidth,
			dataCy,
		} = this.props;

		const classN = classnames('j-input', {
			'j-input--required': required,
			'j-input--label': label,
			'j-input--disabled': disabled,
			'j-input--full-width': fullWidth,
			[`j-input--${size}`]: !!size,
			className,
		});

		return (
			<div className={classN}>
				{/* Label */}
				{label && <Label htmlFor={id}>{label}</Label>}

				{/* Text, Number... */}
				{type !== 'select' && (
					<input
						className="j-input__input"
						placeholder={placeholder}
						type={type}
						id={id}
						disabled={disabled}
						name={id}
						value={value}
						onChange={onChange}
						{...this.props}
						clearable="true" // non-existant prop for inputs, however prompts warning if true passed as default
						data-cy={dataCy}
					/>
				)}

				{/* Select */}
				{type === 'select' && (
					<Select
						className="j-input__select"
						placeholder={placeholder}
						id={id}
						name={id}
						value={value}
						options={options}
						onChange={onChange}
						disabled={disabled}
						clearable={clearable}
					/>
				)}

				{enableValueCopy && type !== 'select' && (
					<Tooltip
						text={phrases.COPY_TO_CLIPBOARD}
						zIndex={copyTooltipZindex}
						renderData={(ref, onMouseEnter, onMouseLeave) => (
							<button
								className={classnames('j-input__copy-content', {
									'j-input__copy-content--label': label,
								})}
								onClick={this.copyToClipboard}
								ref={ref}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
							>
								<Icon name="content_copy" />
							</button>
						)}
					/>
				)}
			</div>
		);
	}
}

Input.propTypes = {
	// shared props
	id: PropTypes.string.isRequired,
	type: PropTypes.string,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	value: PropTypes.any, // ANY
	placeholder: PropTypes.string,
	clearable: PropTypes.bool,
	required: PropTypes.bool,
	size: PropTypes.string,
	enableValueCopy: PropTypes.bool,
	fullWidth: PropTypes.bool,
	copyTooltipZindex: PropTypes.number,
	dataCy: PropTypes.string,

	// react-select props
	options: PropTypes.arrayOf(Object),

	// methods
	onChange: PropTypes.func,
};

Input.defaultProps = {
	type: 'text',
	clearable: true,
	enableValueCopy: false,
	fullWidth: false,
	copyTooltipZindex: 0,
};
