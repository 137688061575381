'use strict';

import constants from 'services/constants';
import moment from 'moment';
import {
	RESET_STATE,
	UPDATE_BATCH_FORM,
	ADD_BATCH_LIST,
	EDIT_BATCH_LIST_ITEM,
	REMOVE_BATCH_LIST_ITEM,
	SET_POS_CONFIGURATION_DISCOUNT_INGREDIENT,
	UPDATE_POS_CONFIGURATION_DISCOUNT_INGREDIENT,
	SET_POS_CONFIGURATION_DISCOUNT_INGREDIENT_DATE_FILTER,
	SET_POS_CONFIGURATION_DISCOUNT_INGREDIENT_LEGACY_FILTER,
} from './salesConfigurationPosConfigurationDiscountIngredients.actions';

const todayDate = moment.utc().format(constants.shortDate);
const posConfigurationDiscountTableFilter = `:pos_configuration_discount.pos_configuration_version.active.from=le='${todayDate}';:pos_configuration_discount.pos_configuration_version.active.to=ge='${todayDate}'`;

const defaultState = {
	posConfigurationDiscountIngredient: {},
	posConfigurationDiscountIngredientDateFilter:
		posConfigurationDiscountTableFilter,
	batchFormData: {
		posConfigurationDiscountTableFilter: posConfigurationDiscountTableFilter,
	},
	batchListData: [],
	legacyFilter:
		':pos_configuration_discount.pos_configuration_version.pos_configuration.legacy==false',
};

function reducer(state = defaultState, action) {
	const { payload, type } = action;

	switch (type) {
		case SET_POS_CONFIGURATION_DISCOUNT_INGREDIENT_DATE_FILTER: {
			return {
				...state,
				posConfigurationDiscountIngredientDateFilter: payload,
			};
		}

		case UPDATE_BATCH_FORM: {
			return {
				...state,
				batchFormData: {
					...state.batchFormData,
					...payload,
				},
			};
		}

		case ADD_BATCH_LIST: {
			return {
				...state,
				batchListData: payload,
			};
		}

		case EDIT_BATCH_LIST_ITEM: {
			return {
				...state,
				batchListData: state.batchListData.map((entry) => {
					if (entry.identifier === payload.identifier) return payload;
					return entry;
				}),
			};
		}

		case REMOVE_BATCH_LIST_ITEM: {
			return {
				...state,
				batchListData: state.batchListData.filter(
					(entry) => entry.identifier !== payload
				),
			};
		}

		case SET_POS_CONFIGURATION_DISCOUNT_INGREDIENT: {
			return {
				...state,
				posConfigurationDiscountIngredient: payload,
			};
		}

		case UPDATE_POS_CONFIGURATION_DISCOUNT_INGREDIENT: {
			return {
				...state,
				posConfigurationDiscountIngredient: {
					...state.posConfigurationDiscountIngredient,
					...payload,
				},
			};
		}

		case SET_POS_CONFIGURATION_DISCOUNT_INGREDIENT_LEGACY_FILTER: {
			return {
				...state,
				legacyFilter: payload,
			};
		}

		case RESET_STATE: {
			return {
				...defaultState,
				posConfigurationDiscountIngredientDateFilter:
					state.posConfigurationDiscountIngredientDateFilter,
			};
		}

		default:
			// never return default state in default case, Filip!!!
			return state;
	}
}

export default reducer;
