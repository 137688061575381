import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { resetState, rerenderTable } from './../../store/homeScreenRecommendationTranslations.actions';

// components
import { ModalStepper } from 'dumb';
import HomeScreenRecommendationTranslationsModalStep from './components/homeScreenRecommendationTranslationsModalStep';

// services
import { addHomeScreenRecommendationTranslations } from './../../homeScreenRecommendationTranslations.service';

// phrases
import phrases from './../../homeScreenRecommendationTranslations.phrases';

const HomeScreenRecommendationTranslationsModalBatch = ({ homeScreenRecommendationTranslationId, onClose }) => {
	const modalRef = useRef();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const batchList = useSelector((store) => store.homeScreenRecommendationTranslations.batchList);

	const onNext = () => {
		setLoading(true);

		const payload = batchList.map((entry) => ({
			tile_layout_home_screen_recommendation: homeScreenRecommendationTranslationId,
			description: entry.description,
			language: entry.language?.value?.id ?? null,
		}));

		addHomeScreenRecommendationTranslations({ batch: payload })
			.then(() => {
				setLoading(false);
				dispatch(rerenderTable());
				onClose();
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const steps = [
		{
			component: <HomeScreenRecommendationTranslationsModalStep />,
			title: phrases.MODAL_TITLE,
			onBack: onClose,
			onNext,
			loading,
			isValid: true,
			confirmButtonLabel: phrases.SUBMIT,
			defaultStyles: false,
			noScroll: false,
		},
	];

	useEffect(() => {
		return () => {
			dispatch(resetState());
		};
	}, [dispatch]);

	return (
		<ModalStepper header={phrases.MODAL_TITLE} onClose={onClose} ref={modalRef} isOpen steps={steps} showStep={false} />
	);
};

HomeScreenRecommendationTranslationsModalBatch.propTypes = {
	onClose: PropTypes.func,
	homeScreenRecommendationTranslationId: PropTypes.number,
};

export default HomeScreenRecommendationTranslationsModalBatch;
