import { get, post, patch, remove } from 'api.vanilla.service';
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

import phrases from './productVariantTranslations.phrases';

/**
 * @function fetchProductVariantTranslations
 * @description Fetch all product variant translations
 * @param {object} state
 */
export function fetchProductVariantTranslations(state, filter) {
	if (state.filter) filter = `${filter};${state.filter}`;

	// Set params and filter
	const params = {
		limit: state.limit,
		sort: state.sort,
		offset: state.offset,
		filter,
	};

	return get(
		'/product/product_variant_translations',
		params,
		null,
		state.headers
	)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
		});
}

/**
 * @function addProductVariantTranslations
 * @description add product variant translations
 */
export function addProductVariantTranslations(data) {
	return post('/product/product_variant_translations', data)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_TRANSLATIONS_ADDED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editProductVariantTranslation
 * @description edit product variant translation
 */
export function editProductVariantTranslation(data) {
	return post(`/product/product_variant_translations/${data.id}`, data)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_TRANSLATION_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function editProductVariantTranslations
 * @description edit product translations
 */
export function editProductVariantTranslations(data) {
	return patch('/product/product_variant_translations', data)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_TRANSLATIONS_EDITED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}

/**
 * @function deleteProductVariantTranslations
 * @description delete product variant translation
 */
export function deleteProductVariantTranslations(id) {
	return remove(`/product/product_variant_translations/${id}`)
		.then((res) => {
			setFeedback(phrases.PRODUCT_VARIANT_TRANSLATIONS_DELETED, 1);
			return res;
		})
		.catch((err) => {
			const errorMessage = formatErrorMessage(err);
			setFeedback(errorMessage, 0);
			throw err;
		});
}
