'use strict';

import PropTypes from 'prop-types';
import React from 'react';
import RowEntry from './row.entry.component';

const Foot = ({ component, table, tables }) => {
	/**
	 * Parses footer from layout object
	 */
	const parseFoot = () => {
		// Define data base
		const total = table.total;

		// Switch on row.type
		// These cases comes from the collection, and tells us about
		// what kind of content we should expect in each row
		// as this could be both a normal row (Entry) or a new table (Subtable)
		switch (total.type) {
		case 'Entry':
			return (
				<RowEntry
					context="foot"
					data={total.data}
					meta={table}
					component={component}
					tables={tables}
				/>
			);
		}
	};

	return <tfoot>{parseFoot()}</tfoot>;
};

Foot.propTypes = {
	component: PropTypes.object.isRequired,
	table: PropTypes.object.isRequired,
	tables: PropTypes.object.isRequired
};

export default Foot;
