'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {  connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

import { ReactDataWrapper } from 'reactDataWrapper';
import { InputCollectionSelect, Input } from 'dumb';

import _get from 'lodash/get';

import {
	setIngredientAllergen,
	updateIngredientAllergen,
	resetState,
} from './store/productVariantIngredientAllergens.actions';

import {
	fetchIngredientAllergenes,
	editIngredientAllergenes,
	addIngredientAllergenes,
	deleteIngredientAllergenes,
} from './productVariantIngredientAllergens.service';

import phrases from './productVariantIngredientAllergens.phrases';
import { enums } from './productVariantIngredientAllergens.enums';

class ProductVariantIngredientAllergens extends Component {
	constructor(props) {
		super(props);

		this.deleteEntry = this.deleteEntry.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.addEntry = this.addEntry.bind(this);

		this.state = {
			pages: null,
		};

		this.fetchData = this.fetchData.bind(this);

		this.columns = [
			{
				Header: 'Degree of',
				id: 'degree_of',
				accessor: (d) => _get(d, 'degree_of', ''),
				filterPath: ':degree_of',
			},
			{
				Header: 'Allergen',
				id: 'allergen',
				accessor: (d) => _get(d, 'allergen.name', ''),
				filterPath: ':allergen.name',
			},
			{
				Header: 'Market',
				id: 'market',
				accessor: (d) => _get(d, 'market.name', ''),
				filterPath: ':market.name',
				Cell: (d) => {
					return (
						<>
							{_get(d, 'original.market.name', false) ? (
								<span>{_get(d, 'original.market.name', '')}</span>
							) : (
								<span style={{ color: '#999999' }}>(global)</span>
							)}
						</>
					);
				},
			},
		];
	}

	addEntry() {
		const { defaultIngredientAllergen, ingredient } = this.props;

		const payload = {
			degree_of: _get(defaultIngredientAllergen, 'degree_of.value', 'Contains'),
			ingredient: ingredient && ingredient.id,
			allergen: _get(defaultIngredientAllergen, 'allergen.id', ''),
			market: _get(defaultIngredientAllergen, 'market.id', ''),
		};

		return addIngredientAllergenes(payload);
	}

	editEntry() {
		const { defaultIngredientAllergen } = this.props;

		const payload = {
			degree_of: _get(defaultIngredientAllergen, 'degree_of.value', 'Contains'),
			allergen: _get(defaultIngredientAllergen, 'allergen.id', ''),
			id: defaultIngredientAllergen.id,
		};

		return editIngredientAllergenes(defaultIngredientAllergen.id, payload);
	}

	deleteEntry(id) {
		return deleteIngredientAllergenes(id);
	}

	setInitialEditValues(data) {
		this.props.setIngredientAllergen(data);
	}

	getEditableCells() {
		const { defaultIngredientAllergen, ingredient } = this.props;

		return [
			{
				header: 'Ingredient',
				value: <span>{ingredient.name}</span>,
			},
			{
				header: 'Degree of',
				value: (
					<Input
						id="degree_of"
						type="select"
						value={_get(defaultIngredientAllergen, 'degree_of', {
							value: enums.DEGREE_OF[0],
							label: enums.DEGREE_OF[0],
						})}
						onChange={(event) => this.editStoreEntry(event, 'degree_of')}
						options={enums.DEGREE_OF.map((x) => ({ value: x, label: x }))}
						clearable={false}
					/>
				),
			},
			{
				header: 'Allergen',
				value: (
					<InputCollectionSelect
						id="allergen"
						placeholder="select allergen"
						value={
							_get(defaultIngredientAllergen, 'allergen.id', false)
								? {
									value: _get(defaultIngredientAllergen, 'allergen.id', ''),
									label: `${_get(
										defaultIngredientAllergen,
										'allergen.name',
										''
									)}`,
								  }
								: null
						}
						handleChange={(key, value) =>
							this.editStoreEntry(
								{
									id: value ? value.value : '',
									name: value ? value.label : '',
								},
								'allergen'
							)
						}
						clearable={false}
						cache
						apiPath="/product/allergens"
						params={{
							limit: 300,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Market',
				value: (
					<InputCollectionSelect
						id="market"
						placeholder="select market"
						value={
							_get(defaultIngredientAllergen, 'market.id', false)
								? {
									value: _get(defaultIngredientAllergen, 'market.id', ''),
									label: `${_get(
										defaultIngredientAllergen,
										'market.name',
										''
									)}`,
								  }
								: null
						}
						handleChange={(key, value) =>
							this.editStoreEntry(
								{
									id: value ? value.value : '',
									name: value ? value.label : '',
								},
								'market'
							)
						}
						clearable={false}
						cache
						apiPath="/organization/markets"
						params={{
							limit: 300,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
		];
	}

	getEditableCellsEdit() {
		const { defaultIngredientAllergen } = this.props;

		return [
			{
				header: 'Ingredient',
				value: (
					<span>{_get(defaultIngredientAllergen, 'ingredient.name', '')}</span>
				),
			},
			{
				header: 'Degree of',
				value: (
					<Input
						id="degree_of"
						type="select"
						value={_get(defaultIngredientAllergen, 'degree_of', {
							value: enums.DEGREE_OF[0],
							label: enums.DEGREE_OF[0],
						})}
						onChange={(event) => this.editStoreEntry(event, 'degree_of')}
						options={enums.DEGREE_OF.map((x) => ({ value: x, label: x }))}
						clearable={false}
					/>
				),
			},
			{
				header: 'Allergen',
				value: (
					<InputCollectionSelect
						id="allergen"
						placeholder="select allergen"
						value={
							_get(defaultIngredientAllergen, 'allergen.id', false)
								? {
									value: _get(defaultIngredientAllergen, 'allergen.id', ''),
									label: `${_get(
										defaultIngredientAllergen,
										'allergen.name',
										''
									)}`,
								  }
								: null
						}
						handleChange={(key, value) =>
							this.editStoreEntry(
								{
									id: value ? value.value : '',
									name: value ? value.label : '',
								},
								'allergen'
							)
						}
						clearable={false}
						cache
						apiPath="/product/allergens"
						params={{
							limit: 300,
						}}
						optionFormat={(entry) => ({
							value: entry.id,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				),
			},
			{
				header: 'Market',
				value: (
					<span>{_get(defaultIngredientAllergen, 'market.name', '')}</span>
				),
			},
		];
	}

	editStoreEntry(e, type) {
		const { defaultIngredientAllergen } = this.props;
		const value = e.target ? e.target.value : e;

		const updateObject = {
			allergen: defaultIngredientAllergen.allergen,
			market: defaultIngredientAllergen.market,
			degree_of: defaultIngredientAllergen.degree_of,
		};

		this.props.updateIngredientAllergen({
			...updateObject,
			...{ [type]: value },
		});
	}

	fetchData(state) {
		const { ingredient } = this.props;

		const filter = `:ingredient.id=='${ingredient.id}'`;

		return fetchIngredientAllergenes(state, filter);
	}

	render() {
		const { ingredient } = this.props;

		return (
			<ReactDataWrapper 
accessAreasAllowedToEdit={['Sales Configuration']}
				title={phrases.INGREDIENT_ALLERGENS}
				columns={this.columns}
				fetchData={this.fetchData}
				filterable
				defaultPageSize={10}
				reduxKey={`product-productVariant-ingredient-pos/ingredient-allergenes-${ingredient.id}`}
				manual
				editEntry={(e) => this.editEntry(e)}
				editableCells={this.getEditableCells()}
				editableCellsEdit={this.getEditableCellsEdit()}
				setInitialEditValues={this.setInitialEditValues}
				onModalClose={this.props.resetState}
				deleteEntry={this.deleteEntry}
				createEntry={this.addEntry}
			/>
		);
	}
}

ProductVariantIngredientAllergens.propTypes = {
	defaultIngredientAllergen: PropTypes.object,
	ingredient: PropTypes.object,
	updateIngredientAllergen: PropTypes.func,
	setIngredientAllergen: PropTypes.func,
	resetState: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setIngredientAllergen,
			updateIngredientAllergen,
			resetState,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		defaultIngredientAllergen:
			store.productProductVariantIngredientAllergens.data
				.defaultIngredientAllergen,
	};
};

export default connectWithStore(
	ProductVariantIngredientAllergens,
	mapStateToProps,
	mapDispatchToProps
);
