'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';

const __endpoints = require('endpoints.service');

export default class TerritoriesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'id',
				type: 'id',
				hidden: true,
			},
			{
				name: 'name',
				type: 'string',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'market',
				type: 'select',
				canCreate: true,
				required: true,
				canAdd: true,
				reference: {
					key: 'name',
					list: '/administration/markets',
				},
			},

			{
				name: 'opened',
				type: 'date',
				canAdd: true,
				canEdit: true,
				required: true,
				canCreate: true,
			},
			{
				name: 'closed',
				type: 'date',
				canAdd: true,
				canEdit: true,
				required: true,
				canCreate: true,
			},
		];
		this.settings = {
			name: 'TerritoriesList',
			canAdd: true,
			canDelete: true,
			canManipulate: () => validAccess(undefined, ['Organisation/Territories::Edit', 'Organization Admin']),
			canEdit: true,
			pageTitle: true,
			translationPath: 'ADMINISTRATION.TERRITORIES',
			endpoint: __endpoints.collection.administration.territories,
		};
		this.init();
		return this;
	}
}
