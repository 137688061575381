'use strict';

import React, { ChangeEvent } from 'react';

// redux
import { useAppSelector, useAppDispatch } from 'appState/storeHooks';
import { editBatchForm } from './../../../store/marketTags.actions';

// interfaces
import { IMarketTagsReduxStepFormValue } from './../../../types';
import { SelectOption } from 'types/select';

import { Input, InputCollectionSelect } from 'dumb';
import { ModalRow } from 'dumb/modal';
import getMarketColumns from 'reactDataWrapperColumns/organization/markets.columns';
import MarketTagsModalTip from './marketTagsModalTip';

import phrases from './../../../marketTags.phrases';

type Props = {
	source: 'market' | 'tag';
};

const MarketTagsModalForm = ({ source }: Props) => {
	const dispatch = useAppDispatch();
	const { batchForm } = useAppSelector((store) => ({
		batchForm: store.marketTags.batchForm,
	}));

	const editStoreEntry = (
		e: SelectOption | number | null,
		type: keyof IMarketTagsReduxStepFormValue
	) => {
		const payload = {
			[type]: e,
		};

		dispatch(editBatchForm(payload));
	};

	return (
		<>
			<ModalRow>
				<MarketTagsModalTip page="one" />
			</ModalRow>

			<ModalRow>
				{source === 'tag' ? (
					<InputCollectionSelect
						id="market"
						dataCy="market-tag-market-select"
						label={phrases.MARKET}
						placeholder={phrases.MARKET_PLACEHOLDER}
						value={batchForm.markets as SelectOption | null}
						handleChange={(key, value) => editStoreEntry(value, 'markets')}
						multi
						required
						cache
						apiPath="/organization/markets"
						params={{
							limit: 30,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
						tableTitle={phrases.MARKETS}
						tableReduxKey="markets-tags-modal-batch/organization/markets"
						tableColumns={getMarketColumns(
							'markets-tags-modal-batch/organization/markets'
						)}
					/>
				) : (
					<InputCollectionSelect
						id="tags"
						dataCy="market-tag-tag-select"
						label={phrases.TAG}
						placeholder={phrases.TAG_PLACEHOLDER}
						value={batchForm.tags as SelectOption | null}
						handleChange={(key, value) => editStoreEntry(value, 'tags')}
						multi
						required
						cache
						apiPath="/tagging/tags"
						params={{
							limit: 50,
						}}
						optionFormat={(entry) => ({
							value: entry,
							label: entry.name,
						})}
						inputFilterFormat={(input) => `:name=like='%${input}%'`}
					/>
				)}
			</ModalRow>

			<ModalRow>
				<Input
					id="default-length-in-minutes"
					label={phrases.DEFAULT_LENGTH_IN_MINUTES}
					dataCy="market-tag-default-length-in-minutes"
					placeholder={phrases.DEFAULT_LENGTH_IN_MINUTES_PLACEHOLDER}
					type="number"
					value={batchForm.defaultLengthInMinutes}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						editStoreEntry(
							e.target.value as unknown as number,
							'defaultLengthInMinutes'
						)
					}
				/>
			</ModalRow>

			<ModalRow>
				<Input
					id="minutes-from-start-of-shift"
					dataCy="market-tag-minutes-from-start-of-shift"
					disabled={!!batchForm.minutesFromEndOfShift}
					label={phrases.MINUTES_FROM_START_OF_SHIFT}
					placeholder={phrases.MINUTES_FROM_START_OF_SHIFT_PLACEHOLDER}
					type="number"
					value={batchForm.minutesFromStartOfShift}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						editStoreEntry(
							e.target.value as unknown as number,
							'minutesFromStartOfShift'
						)
					}
				/>
			</ModalRow>

			<ModalRow>
				<Input
					id="minutes-from-end-of-shift"
					dataCy="market-tag-minutes-from-end-of-shift"
					disabled={!!batchForm.minutesFromStartOfShift}
					label={phrases.MINUTES_FROM_END_OF_SHIFT}
					placeholder={phrases.MINUTES_FROM_END_OF_SHIFT_PLACEHOLDER}
					type="number"
					value={batchForm.minutesFromEndOfShift}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						editStoreEntry(
							e.target.value as unknown as number,
							'minutesFromEndOfShift'
						)
					}
				/>
			</ModalRow>
		</>
	);
};

export default MarketTagsModalForm;
