'use strict';

// Services
import ListModel from 'common/components/list/listModel.service';
import { validAccess } from 'accessControl';
const __endpoints = require('endpoints.service');

export default class CompanyToWorkplacesList extends ListModel {
	constructor(reduxKey) {
		super(reduxKey);
		this.headers = [
			{
				name: 'company',
				type: 'select',
				canAdd: true,
				canEdit: true,
				required: true,
				reference: {
					key: 'name',
					list: '/organization/companies',
				},
			},
			{
				name: 'start_date',
				type: 'date',
				canAdd: true,
				canEdit: true,
				required: true,
			},
			{
				name: 'end_date',
				type: 'date',
				canAdd: true,
				canEdit: true,
			},
		];
		this.settings = {
			name: 'CompanyCompanyToWorkplacesList',
			canAdd: true,
			canEdit: true,
			canDelete: true,
			canManipulate: () =>
				validAccess(undefined, [
					'Organisation/Companies::Edit',
					'Organization Admin',
				]),

			canCollapse: true,
			alwaysRefresh: true,
			translationPath: 'ADMINISTRATION.COMPANY_TO_WORKPLACE_RELATIONS',
			endpoint:
				__endpoints.collection.administration.company_to_workplace_relations,
		};
		this.init();
		return this;
	}
}
