'use strict';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import { connectWithStore } from 'appState';
import { bindActionCreators } from 'redux';

// components
import { ReactDataWrapper } from 'reactDataWrapper';
import { Button, Input, Tooltip, SingleDatePickerInput } from 'dumb';
import { DateFilterSelector } from 'reactDataWrapper/utilities';

// lodash
import _get from 'lodash/get';

// utils
import { formatErrorMessage } from 'api/helpers';
import { set as setFeedback } from 'feedback.vanilla.service.js';

// phrases/constants
import constants from 'services/constants';
import phrases from './salesConfigurationPosConfigurationDiscountIngredients.phrases';

// batch modal
import ModalBatch from './components/modalBatch.component';

import {
	setPosConfigurationDiscountIngredient,
	setPosConfigurationDiscountIngredientDateFilter,
	addBatchList,
	updateBatchForm,
	resetState,
	editBatchListItem,
	removeBatchListItem,
	updatePosConfigurationDiscountIngredient,
	setLegacyFilter,
} from './store/salesConfigurationPosConfigurationDiscountIngredients.actions';

import {
	fetchPosConfigurationIngredients,
	fetchPosConfigurationDiscountIngredients,
	addPosConfigurationDiscountIngredient,
	editDiscountIngredient,
	deletePosConfigurationDiscountIngredient,
} from './salesConfigurationPosConfigurationDiscountIngredients.service';

// hary styles
import './salesConfigurationPosConfigurationDiscountIngredients.css';

const reduxKey = 'sales_configuration/pos_configuration_ingredients';

class SalesConfigurationPosConfigurationDiscountIngredients extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
		};

		this.toggleModal = this.toggleModal.bind(this);
		this.editStoreEntry = this.editStoreEntry.bind(this);
		this.setInitialEditValues = this.setInitialEditValues.bind(this);
		this.getAdditionalPosConfigurationDiscountTableFilters =
			this.getAdditionalPosConfigurationDiscountTableFilters.bind(this);
		this._onPosConfigurationDiscountTableFilterChange =
			this._onPosConfigurationDiscountTableFilterChange.bind(this);
		this.toggleLegacyFilter = this.toggleLegacyFilter.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.deleteEntry = this.deleteEntry.bind(this);

		this.columns = [
			{
				Header: 'Id',
				id: 'DIID',
				accessor: 'realId',
				filterPath: ':id',
				show: false,
				width: 50,
			},
			{
				Header: 'POS Configuration',
				id: 'DIposConfiguration',
				accessor: (d) =>
					_get(
						d,
						'pos_configuration_ingredient.pos_configuration_version.pos_configuration.name',
						null
					),
				filterPath:
					':pos_configuration_ingredient.pos_configuration_version.pos_configuration.name',
			},
			{
				Header: 'POS Configuration Version',
				id: 'DIposConfigurationVersion',
				accessor: (d) =>
					_get(
						d,
						'pos_configuration_ingredient.pos_configuration_version.name',
						null
					),
				filterPath:
					':pos_configuration_ingredient.pos_configuration_version.name',
				width: 170,
			},
			{
				Header: 'Pos configuration description',
				id: 'DIposConfigurationDescription',
				show: false,
				accessor: (d) =>
					_get(
						d,
						'pos_configuration_ingredient.pos_configuration_version.description',
						null
					),
				filterPath:
					':pos_configuration_ingredient.pos_configuration_version.description',
			},

			{
				Header: 'Active from',
				width: 100,
				id: 'DIactiveFrom',
				accessor: (d) =>
					_get(
						d,
						'pos_configuration_ingredient.pos_configuration_version.active.from',
						null
					),
				Cell: (d) => {
					if (
						!d.original?.pos_configuration_ingredient.pos_configuration_version
							?.active
					)
						return null;
					return (
						<span>
							{moment
								.utc(
									d.original.pos_configuration_ingredient
										.pos_configuration_version.active.from
								)
								.format(constants.shortDate)}
						</span>
					);
				},
				filterPath:
					':pos_configuration_ingredient.pos_configuration_version.active.from',
				Filter: ({ column }) => (
					<DateFilterSelector reduxKey={reduxKey} columnId={column.id} />
				),
			},
			{
				Header: 'Active to',
				width: 100,
				id: 'DIactiveTo',
				accessor: (d) =>
					_get(
						d,
						'pos_configuration_ingredient.pos_configuration_version.active.to',
						null
					),
				Cell: (d) => {
					if (
						!d.original?.pos_configuration_ingredient.pos_configuration_version
							?.active
					)
						return null;

					return (
						<span>
							{moment
								.utc(
									d.original.pos_configuration_ingredient
										.pos_configuration_version.active.to
								)
								.format(constants.shortDate)}
						</span>
					);
				},
				filterPath:
					':pos_configuration_ingredient.pos_configuration_version.active.to',
				Filter: ({ column }) => (
					<DateFilterSelector reduxKey={reduxKey} columnId={column.id} />
				),
			},

			{
				Header: 'Discount name',
				id: 'DIposConfigurationDiscountName',
				accessor: (d) => _get(d, 'pos_configuration_discount.name', null),
				filterPath: ':pos_configuration_discount.name',
			},
			{
				Header: 'Ingredient',
				id: 'DIingredient',
				accessor: (d) =>
					_get(d, 'pos_configuration_ingredient.ingredient.name', null),
				filterPath: ':pos_configuration_ingredient.ingredient.name',
			},
			{
				Header: 'Ingredient Category',
				width: 80,
				id: 'DIcat',
				accessor: (d) =>
					_get(
						d,
						'pos_configuration_ingredient.ingredient.ingredient_category.name',
						null
					),
				filterPath:
					':pos_configuration_ingredient.ingredient.ingredient_category.name',
			},
			{
				Header: 'Market',
				id: 'DImarket',
				accessor: (d) =>
					_get(
						d,
						'pos_configuration_ingredient.pos_configuration_version.pos_configuration.market.name',
						null
					),
				filterPath:
					':pos_configuration_ingredient.pos_configuration_version.pos_configuration.market.name',
			},
			{
				Header: 'Available in app',
				width: 100,
				id: 'DIavailableInAppCheckbox',
				accessor: (d) =>
					_get(d, 'pos_configuration_ingredient.available_in_app', null),
				Cell: (d) => {
					return (
						<Input
							id="availableInAppTableCheckbox"
							disabled
							checked={d.original}
							type="checkbox"
						/>
					);
				},
				filterPath: ':pos_configuration_ingredient.available_in_app',
				filterable: false,
			},
			{
				Header: 'Alternative Id',
				width: 80,
				id: 'cat',
				accessor: (d) =>
					_get(d, 'pos_configuration_ingredient.alternative_id', null),
				filterPath: ':pos_configuration_ingredient.alternative_id',
			},
			{
				Header: 'To Stay Price',
				width: 80,
				id: 'DItsprice',
				accessor: 'pos_configuration_ingredient.to_stay_price',
				filterPath: ':pos_configuration_ingredient.to_stay_price',
			},
			{
				Header: 'To Stay Discount',
				width: 120,
				id: 'DItsdiscount',
				accessor: 'to_stay_discount_price',
				filterPath: ':to_stay_discount_price',
			},
			{
				Header: 'To Go Price',
				width: 80,
				id: 'DItgprice',
				accessor: 'pos_configuration_ingredient.to_go_price',
				filterPath: ':pos_configuration_ingredient.to_go_price',
			},
			{
				Header: 'To Go Discount',
				width: 120,
				id: 'DItgdiscount',
				accessor: 'to_go_discount_price',
				filterPath: ':to_go_discount_price',
			},
		];
	}

	editEntry() {
		const { posConfigurationDiscountIngredient } = this.props;

		const body = {
			to_stay_discount_price: posConfigurationDiscountIngredient.toStayDiscount,
			to_go_discount_price: posConfigurationDiscountIngredient.toGoDiscount,
		};

		return editDiscountIngredient(posConfigurationDiscountIngredient.id, body);
	}

	setInitialEditValues(data) {
		const payload = {
			...data,
			toStayDiscount: data?.to_stay_discount_price ?? null,
			toGoDiscount: data?.to_go_discount_price ?? null,
		};

		this.props.setPosConfigurationDiscountIngredient(payload);
	}

	getEditableCells() {
		const { posConfigurationDiscountIngredient } = this.props;

		const editableCells = [
			{
				header: 'Ingredient',
				value: (
					<span>
						{_get(
							posConfigurationDiscountIngredient,
							'pos_configuration_ingredient.ingredient.name',
							''
						)}
					</span>
				),
			},
			{
				header: 'Category',
				value: (
					<span>
						{_get(
							posConfigurationDiscountIngredient,
							'pos_configuration_ingredient.ingredient.ingredient_category.name',
							''
						)}
					</span>
				),
			},
			{
				header: 'To Stay Price',
				value: (
					<span>
						{_get(
							posConfigurationDiscountIngredient,
							'pos_configuration_ingredient.to_stay_price',
							''
						)}
					</span>
				),
			},
			{
				header: 'To Go Price',
				value: (
					<span>
						{_get(
							posConfigurationDiscountIngredient,
							'pos_configuration_ingredient.to_go_price',
							''
						)}
					</span>
				),
			},
			{
				header: 'Available in app',
				value: (
					<Input
						id="availableInAppModalCheckbox"
						type="checkbox"
						checked={_get(
							posConfigurationDiscountIngredient,
							'pos_configuration_ingredient.available_in_app',
							false
						)}
						disabled
					/>
				),
			},
			{
				header: 'Pos configuration discount',
				value: (
					<span>
						{
							posConfigurationDiscountIngredient?.pos_configuration_discount
								?.name
						}
					</span>
				),
			},
			{
				header: 'To Stay Discount',
				value: (
					<Input
						id="to-stay-discount"
						type="number"
						dataCy="pos-configuration-discount-ingredients-to-stay-discount"
						placeholder="1234,56"
						onChange={(e) => this.editStoreEntry('toStayDiscount', e)}
						value={posConfigurationDiscountIngredient.toStayDiscount}
					/>
				),
			},
			{
				header: 'To Go Discount',
				value: (
					<Input
						id="to-go-discount"
						type="number"
						dataCy="pos-configuration-discount-ingredients-to-go-discount"
						placeholder="1234,56"
						onChange={(e) => this.editStoreEntry('toGoDiscount', e)}
						value={posConfigurationDiscountIngredient.toGoDiscount}
					/>
				),
			},
		];

		return editableCells;
	}

	editStoreEntry(name, e) {
		const value = e?.target?.value ?? e;

		const payload = {
			[name]: value,
		};

		this.props.updatePosConfigurationDiscountIngredient(payload);
	}

	fetchData(state) {
		return fetchPosConfigurationDiscountIngredients(state?.filter ?? '')
			.then((res) => {
				return res;
			})
			.catch((err) => {
				const errorMessage = formatErrorMessage(err);
				setFeedback(errorMessage, 0);
			});
	}

	toggleModal() {
		this.setState((prevState) => ({ showModal: !prevState.showModal }));
		this.props.resetState();
	}

	_onPosConfigurationDiscountTableFilterChange(date) {
		const { setPosConfigurationDiscountIngredientDateFilter } = this.props;

		const formattedDate = date
			? moment.utc(date).format(constants.shortDate)
			: null;

		const filterString = formattedDate
			? `:pos_configuration_discount.pos_configuration_version.active.from=le='${formattedDate}';:pos_configuration_discount.pos_configuration_version.active.to=ge='${formattedDate}'`
			: '';

		setPosConfigurationDiscountIngredientDateFilter(filterString);
	}

	toggleLegacyFilter() {
		const { setLegacyFilter, legacyFilter } = this.props;

		const filter = legacyFilter
			? ''
			: ':pos_configuration_discount.pos_configuration_version.pos_configuration.legacy==false';

		setLegacyFilter(filter);
	}

	getAdditionalPosConfigurationDiscountTableFilters() {
		const { posConfigurationDiscountIngredientDateFilter, legacyFilter } =
			this.props;

		return (
			<div className="pos-configuration-discount-ingredient__filter-area">
				<SingleDatePickerInput
					id="DIposConfigDiscountDatePicker"
					label="Select active date"
					type="single"
					appendToBody
					fixed
					onChange={this._onPosConfigurationDiscountTableFilterChange}
					selectedDate={posConfigurationDiscountIngredientDateFilter}
					noClockButton
					clearButtonDisabled={!posConfigurationDiscountIngredientDateFilter}
					clearable
				/>
				<Tooltip
					text={
						legacyFilter
							? phrases.LEGACY_FILTER_TOOLTIP_ACTIVE
							: phrases.LEGACY_FILTER_TOOLTIP_UNACTIVE
					}
					renderData={(ref, onMouseEnter, onMouseLeave) => (
						<Button
							id="legacyFilterButton"
							className="pos-configuration-product-variants-pricing__filter-area__legacy-button"
							onClick={this.toggleLegacyFilter}
							type={legacyFilter ? '' : 'inverted'}
							refProp={ref}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
						>
							Legacy
						</Button>
					)}
				/>
			</div>
		);
	}

	getExtraFilters() {
		const { posConfigurationDiscountIngredientDateFilter, legacyFilter } =
			this.props;

		let filter = posConfigurationDiscountIngredientDateFilter;
		if (legacyFilter && posConfigurationDiscountIngredientDateFilter)
			filter = `${filter};${legacyFilter}`;
		if (!posConfigurationDiscountIngredientDateFilter) filter = legacyFilter;
		return filter;
	}

	deleteEntry(id) {
		return deletePosConfigurationDiscountIngredient(id);
	}

	render() {
		const {
			batchFormData,
			listPosConfigurationDiscounts,
			updateBatchForm,
			listIngredients,
			addBatchList,
			batchListData,
			editBatchListItem,
			removeBatchListItem,
		} = this.props;

		return (
			<>
				<ReactDataWrapper
					dataCy="pos-configuration-discount-ingredients-table"
					accessAreasAllowedToEdit={['Sales Configuration']}
					title={phrases.TABLE_TITLE}
					columns={this.columns}
					fetchData={this.fetchData}
					filterable
					defaultPageSize={10}
					reduxKey={reduxKey}
					manual
					editEntry={() => this.editEntry()}
					deleteEntry={this.deleteEntry}
					editableCells={this.getEditableCells()}
					setInitialEditValues={this.setInitialEditValues}
					onModalClose={this.props.resetState}
					actionsWidth={30}
					actionRender={
						<Button
							type="inverted"
							label="Batch"
							shadow
							onClick={() => this.toggleModal()}
						>
							<span className="icon icon--add" />
						</Button>
					}
					customAreaComponents={this.getAdditionalPosConfigurationDiscountTableFilters()}
					extraFilters={this.getExtraFilters()}
					hideIdColumn
				/>
				<ModalBatch
					modalVisible={this.state.showModal}
					handleClose={this.toggleModal}
					batchFormData={batchFormData}
					updateBatchForm={updateBatchForm}
					listPosConfigurationDiscounts={listPosConfigurationDiscounts}
					listIngredients={listIngredients}
					addBatchList={addBatchList}
					batchListData={batchListData}
					editBatchListItem={editBatchListItem}
					removeBatchListItem={removeBatchListItem}
					fetchPosConfigurationIngredients={fetchPosConfigurationIngredients}
					addPosConfigurationDiscountIngredient={
						addPosConfigurationDiscountIngredient
					}
					fetchPosConfigurationDiscountIngredients={
						fetchPosConfigurationDiscountIngredients
					}
				/>
			</>
		);
	}
}

SalesConfigurationPosConfigurationDiscountIngredients.propTypes = {
	updatePosConfigurationDiscountIngredient: PropTypes.func,
	setPosConfigurationDiscountIngredient: PropTypes.func,
	resetState: PropTypes.func,
	updateBatchForm: PropTypes.func,
	batchFormData: PropTypes.object,
	listPosConfigurationDiscounts: PropTypes.object,
	addBatchList: PropTypes.func,
	batchListData: PropTypes.object,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	setPosConfigurationDiscountIngredientDateFilter: PropTypes.func,
	posConfigurationDiscountIngredient: PropTypes.object,
	listIngredients: PropTypes.array,
	posConfigurationDiscountIngredientDateFilter: PropTypes.string,
	setLegacyFilter: PropTypes.func,
	legacyFilter: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setPosConfigurationDiscountIngredient,
			updatePosConfigurationDiscountIngredient,
			updateBatchForm,
			resetState,
			addBatchList,
			editBatchListItem,
			removeBatchListItem,
			setPosConfigurationDiscountIngredientDateFilter,
			setLegacyFilter,
		},
		dispatch
	);
};

const mapStateToProps = (store) => {
	return {
		batchFormData:
			store.salesConfigurationPosConfigurationDiscountIngredient.batchFormData,
		batchListData:
			store.salesConfigurationPosConfigurationDiscountIngredient.batchListData,
		listPosConfigurationDiscounts:
			store.listData['/pos/pos_configuration_discounts'],
		listIngredients: store.listData['/product/ingredients'],
		posConfigurationDiscountIngredient:
			store.salesConfigurationPosConfigurationDiscountIngredient
				.posConfigurationDiscountIngredient,
		posConfigurationDiscountIngredientDateFilter:
			store.salesConfigurationPosConfigurationDiscountIngredient
				.posConfigurationDiscountIngredientDateFilter,
		legacyFilter:
			store.salesConfigurationPosConfigurationDiscountIngredient.legacyFilter,
	};
};

export default connectWithStore(
	SalesConfigurationPosConfigurationDiscountIngredients,
	mapStateToProps,
	mapDispatchToProps
);
