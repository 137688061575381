'use strict';

import Papa from 'papaparse';

class ImportCsvModalResultSet {

	constructor(file = null, fileName = null) {

		// Set empty results
		this.results = {
			file: file,
			fileName: fileName,
		};

	}

	/**
	 * @returns {{file: (*|EmploymentDocumentsUpload.state.file|{}|file|ImportCsvModal.state.file|GoldModelImportCsv.state.file), fileName: (*|string)}|*}
	 */
	getResults(){
		return this.results;
	}

	/**
	 * @function getResultsAsJSON
	 * @description getResultsAsJSON
	 * @param period
	 * @param personIds
	 * @returns {Promise.<TResult>}
	 */
	getResultsAsJSON() {
		return new Promise((resolve, reject) => {
			Papa.parse(this.results.file, {
				header: true,
				dynamicTyping: true,
				skipEmptyLines: true,
				newline: '',
				error: error => {
					reject([error]);
				},
				complete: results => {
					if (results.errors.length > 0) reject(results.errors);
					else {
						resolve(results.data);
					}
				}
			});
		});
	}

}

export default ImportCsvModalResultSet;