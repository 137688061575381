'use strict';

var headers = {
	SOCIAL_GROUPS: {
		NAME: 'Name',
		FACEBOOK_GROUP: 'Facebook Group',
		WORKPLACE: 'Workplace',
		MARKET: 'Market',
		SYNCHRONIZE_TO_FACEBOOK: 'Manage Facebook group'
	}
};

var titles = {
	SOCIAL_GROUPS: 'Social groups',
};

module.exports = {
	headers: headers,
	titles: titles
};