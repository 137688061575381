'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { Button, TableList, Input, Icon, Tooltip } from 'dumb';
import { Tip } from 'dumb/tips';
import ColorPicker from './../../colorPicker/colorPicker';

// phrases/ constants
import phrases from './../../../flairs.phrases';

class FlairsModalStepList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			colorPickerOpen: null
		};
	}

	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e.target ? e.target.value : e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: value }
		});

		if (type === 'titleColor' || type === 'backgroundColor')
			this.setState(() => ({ colorPickerOpen: null }));
	}

	_toggleColorSelector(rowId, type) {
		this.setState(() => ({
			colorPickerOpen: {
				rowId,
				type
			}
		}));
	}

	_closeColorSelectors() {
		this.setState(() => ({
			colorPickerOpen: null
		}));
	}

	render() {
		const { batchList, addBatchListItemRow } = this.props;
		const { colorPickerOpen } = this.state;

		const maxRows = batchList.length > 9;

		return (
			<>
				<div className="flairs-modal-batch__tip-wrapper">
					<Tip content={`${phrases.MODAL_BATCH_LIST_TIP}`} />
				</div>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_NAME}</th>
							<th>{phrases.MODAL_BATCH_STEP_DESCRIPTION}</th>
							<th>{phrases.MODAL_BATCH_STEP_EXTERNAL_NAME}</th>
							<th>{phrases.MODAL_BATCH_STEP_EXTERNAL_DESCRIPTION}</th>
							<th>{phrases.MODAL_BATCH_STEP_FORM_TITLE_COLOR}</th>
							<th>{phrases.MODAL_BATCH_STEP_FORM_BACKGROUND_COLOR}</th>
							<th>{phrases.MODAL_BATCH_STEP_FORM_SORT_ORDER}</th>

							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((entry, i) => {
							const titleColorSelectorOpen =
								colorPickerOpen &&
								colorPickerOpen.rowId === entry.identifier &&
								colorPickerOpen.type === 'title';
							const backgroundColorSelectorOpen =
								colorPickerOpen &&
								colorPickerOpen.rowId === entry.identifier &&
								colorPickerOpen.type === 'background';

							return (
								<tr key={entry.identifier}>
									<td>{i + 1}</td>

									<td>
										<div className="flairs-modal-batch__list__cell">
											<Input
												id={`name-${i}`}
												required
												placeholder={phrases.MODAL_BATCH_STEP_NAME_PLACEHOLDER}
												value={entry.name || ''}
												onChange={event =>
													this._editListEntry(event, entry, 'name')
												}
											/>
										</div>
									</td>

									<td>
										<div className="flairs-modal-batch__list__cell">
											<Input
												id={`description-${i}`}
												placeholder={
													phrases.MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER
												}
												value={entry.description || ''}
												onChange={event =>
													this._editListEntry(event, entry, 'description')
												}
											/>
										</div>
									</td>

									<td>
										<div className="flairs-modal-batch__list__cell">
											<Input
												id={`externalName-${i}`}
												placeholder={
													phrases.MODAL_BATCH_STEP_EXTERNAL_NAME_PLACEHOLDER
												}
												required
												value={entry.externalName || ''}
												onChange={event =>
													this._editListEntry(event, entry, 'externalName')
												}
											/>
										</div>
									</td>

									<td>
										<div className="flairs-modal-batch__list__cell">
											<Input
												id={`externalDescription-${i}`}
												placeholder={
													phrases.MODAL_BATCH_STEP_EXTERNAL_DESCRIPTION_PLACEHOLDER
												}
												value={entry.externalDescription || ''}
												onChange={event =>
													this._editListEntry(
														event,
														entry,
														'externalDescription'
													)
												}
											/>
										</div>
									</td>

									<td>
										<div className="flairs-modal-batch__list__cell">
											<ColorPicker
												isOpen={titleColorSelectorOpen}
												onClick={() => {
													if (titleColorSelectorOpen)
														this._closeColorSelectors();
													else
														this._toggleColorSelector(
															entry.identifier,
															'title'
														);
												}}
												onColorChange={e =>
													this._editListEntry(e, entry, 'titleColor')
												}
												value={entry?.titleColor}
											/>
										</div>
									</td>

									<td>
										<div className="flairs-modal-batch__list__cell">
											<ColorPicker
												isOpen={backgroundColorSelectorOpen}
												onClick={() => {
													if (backgroundColorSelectorOpen)
														this._closeColorSelectors();
													else
														this._toggleColorSelector(
															entry.identifier,
															'background'
														);
												}}
												onColorChange={e =>
													this._editListEntry(e, entry, 'backgroundColor')
												}
												value={entry?.backgroundColor}
											/>
										</div>
									</td>

									<td>
										<div className="flairs-modal-batch__list__cell">
											<Input
												id={`sortOrder-${i}`}
												type="number"
												placeholder={
													phrases.MODAL_BATCH_STEP_FORM_SORT_ORDER_PLACEHOLDER
												}
												value={entry.sortOrder || ''}
												onChange={event =>
													this._editListEntry(event, entry, 'sortOrder')
												}
											/>
										</div>
									</td>

									<td>
										<Button
											id={`delete-${i}`}
											type="negative"
											size="tiny"
											onClick={() => this._onRemoveEntry(entry.identifier)}>
											<span className="icon icon--delete" />
										</Button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</TableList>
				<div className="flairs-modal-batch__add-new-row">
					<Tooltip
						text={
							maxRows
								? phrases.MODAL_BATCH_STEP_LIST_TOOLTIP_MAX_ROWS
								: phrases.MODAL_BATCH_STEP_LIST_TOOLTIP
						}
						zIndex={600}
						renderData={(ref, onMouseEnter, onMouseLeave) => (
							<Button
								type="secondary"
								onClick={addBatchListItemRow}
								disabled={maxRows}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
								refProp={ref}>
								<Icon name="add" />
							</Button>
						)}
					/>
				</div>
			</>
		);
	}
}

FlairsModalStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func
};

export default FlairsModalStepList;
