export default {
	TIME: 'Time',
	GREY: 'Grey',
	PURPLE: 'Purple',
	YELLOW: 'Yellow',
	PINK: 'Pink',
	DESCRIPTION: 'Description',
	TILE: 'Tile',
	DESCRIPTION_PLACEHOLDER: 'Enter description...',
	CLICK_TO_ADD_RECOMMENDATIONS: 'Click to add recommendations'
};
