'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, Toggle, InputCollectionSelect } from 'dumb';
import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';
import { ModalRow } from 'dumb/modal';

// phrases
import phrases from './../../../productBundleSets.phrases';

class ProductBundleSetsBatchStepList extends Component {
	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		// get value from event
		const value = e?.target?.value ?? e;

		// edits the row in the store
		this.props.editBatchListItem({
			...row,
			...{ [type]: value },
		});
	}

	render() {
		const {
			batchList,
			productRecommendationUrl,
			addBatchListItemRow,
			assetCollectionUrl,
		} = this.props;

		return (
			<>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_NAME}</th>
							<th>{phrases.MODAL_BATCH_STEP_DESCRIPTION}</th>
							<th>{phrases.MODAL_BATCH_SORT_ORDER}</th>
							<th>{phrases.MODAL_BATCH_STEP_OPTIONAL}</th>
							<th>{phrases.MODAL_BATCH_STEP_PRODUCT_RECOMMENDATION}</th>
							<th>{phrases.MODAL_BATCH_STEP_ASSET_COLLECTION}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((entry, i) => {
							const deleteButtonDisabled = batchList.length === 1;

							return (
								<TableListRow key={entry.identifier}>
									<TableListCell>{i + 1}</TableListCell>

									<TableListCell>
										<Input
											id={`${entry.identifier}-name}`}
											placeholder={phrases.MODAL_BATCH_STEP_NAME_PLACEHOLDER}
											value={entry.name || ''}
											onChange={(event) =>
												this._editListEntry(event, entry, 'name')
											}
											required
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`${entry.identifier}-description`}
											placeholder={
												phrases.MODAL_BATCH_STEP_DESCRIPTION_PLACEHOLDER
											}
											value={entry.description || ''}
											onChange={(event) =>
												this._editListEntry(event, entry, 'description')
											}
											required
										/>
									</TableListCell>
									<TableListCell>
										<Input
											id={`${entry.identifier}-sortOrder`}
											type="number"
											placeholder={
												phrases.MODAL_BATCH_STEP_SORT_ORDER_PLACEHOLDER
											}
											value={entry.sortOrder || ''}
											onChange={(event) =>
												this._editListEntry(event, entry, 'sortOrder')
											}
										/>
									</TableListCell>
									<TableListCell>
										<Toggle
											id={`${entry.identifier}-optional`}
											toggled={entry.optional}
											onClick={(event) =>
												this._editListEntry(event, entry, 'optional')
											}
											type={i % 2 === 0 ? 'light' : 'dark'}
										/>
									</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id={`${entry.identifier}-product-recommendation`}
											placeholder={
												phrases.MODAL_BATCH_STEP_PRODUCT_RECOMMENDATION_PLACEHOLDER
											}
											value={entry.productRecommendation}
											handleChange={(key, value) =>
												this._editListEntry(
													value,
													entry,
													'productRecommendation'
												)
											}
											cache
											apiPath={productRecommendationUrl}
											params={{
												limit: 30,
											}}
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											inputFilterFormat={(input) => `:name=like='%${input}%'`}
											tableColumns={[
												{
													Header: 'Name',
													id: 'name',
													accessor: 'name',
													filterPath: ':name',
												},
												{
													Header: 'Description',
													id: 'description',
													accessor: 'description',
													filterPath: ':description',
												},
											]}
											tableTitle={phrases.PRODUCT_RECOMMENDATIONS_TABLE_TITLE}
											tableReduxKey="/pos/product_recommendations_product-bundle-sets-edit-modal"
										/>
									</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id={`${entry.identifier}-asset-collection`}
											placeholder={
												phrases.MODAL_BATCH_STEP_ASSET_COLLECTION_PLACEHOLDER
											}
											value={entry.assetCollection}
											handleChange={(key, value) =>
												this._editListEntry(value, entry, 'assetCollection')
											}
											cache
											apiPath={assetCollectionUrl}
											params={{
												limit: 30,
												filter: `:legacy=='false'`,
											}}
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											inputFilterFormat={(input) => `:name=like='%${input}%'`}
											tableColumns={[
												{
													Header: 'Name',
													id: 'name',
													accessor: 'name',
													filterPath: ':name',
												},
												{
													Header: 'Description',
													id: 'desc',
													accessor: 'description',
													filterPath: ':description',
												},
												{
													Header: 'Legacy',
													id: 'legacy',
													accessor: 'legacy',
													filterPath: ':legacy',
													filterable: false,
													Cell: (d) => {
														return (
															<Input
																type="checkbox"
																checked={d.value}
																disabled
															/>
														);
													},
												},
											]}
											tableTitle={phrases.ASSET_COLLECTION_TABLE_TITLE}
											tableReduxKey="/pos/asset_collections_product-bundle-sets-edit-modal"
										/>
									</TableListCell>

									<TableListCell>
										<Button
											id={`${entry.identifier}-delete`}
											type="negative"
											size="tiny"
											disabled={deleteButtonDisabled}
											onClick={() => this._onRemoveEntry(entry.identifier)}>
											<span className="icon icon--delete" />
										</Button>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>

				<ModalRow
					tooltipText={phrases.MODAL_BATCH_STEP_LIST_TOOLTIP}
					tooltipZIndex={600}
					onClick={addBatchListItemRow}
					typeAddNewRow
				/>
			</>
		);
	}
}

ProductBundleSetsBatchStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	productRecommendationUrl: PropTypes.string,
	addBatchListItemRow: PropTypes.func,
	assetCollectionUrl: PropTypes.string,
};

export default ProductBundleSetsBatchStepList;
