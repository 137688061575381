'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Input, Icon, InputCollectionSelect } from 'dumb';
import { TableList, TableListCell, TableListRow } from 'dumb/tables/table-list';
import { ModalRow } from 'dumb/modal';
import { Tip } from 'dumb/tips';
import { DateFilterSelector } from 'reactDataWrapper/utilities';

// phrases
import phrases from '../../../marketGracePeriods.phrases';

// lodash
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

class MarketGracePeriodsBatchStepList extends Component {
	constructor(props) {
		super(props);

		this._getMarketFilter = this._getMarketFilter.bind(this);
	}

	_onRemoveEntry(identifier) {
		const { removeBatchListItem } = this.props;

		removeBatchListItem(identifier);
	}

	_editListEntry(e, row, type) {
		const value = e?.target?.value ?? e;

		// edits the row in the store
		this.props.editBatchListItem({
			identifier: row.identifier,
			[type]: value,
		});
	}

	_getMarketFilter() {
		const { batchList } = this.props;

		if (batchList.length === 1) return '';

		const marketIds = batchList
			.map((entry) => entry.market?.value?.id)
			.filter((e) => e);

		if (_isEmpty(marketIds)) return '';

		const marketIdsArray = marketIds.reduce((acc, currentValue, index) => {
			acc =
				index === marketIds.length - 1
					? `${acc}'${currentValue}']`
					: `${acc}'${currentValue}',`;

			return acc;
		}, '[');

		return `:id=!IN=${marketIdsArray}`;
	}

	render() {
		const { batchList, addBatchListItemRow } = this.props;

		return (
			<>
				<div className="market-grace-periods__tip-wrapper">
					<Tip content={phrases.BATCH_ADD_TIP} />
				</div>
				<TableList>
					<thead>
						<tr>
							<th>{phrases.MODAL_BATCH_STEP_LIST_TABLE_NUMBER}</th>
							<th>{phrases.MODAL_BATCH_STEP_MARKET}</th>
							<th>{phrases.MODAL_BATCH_STEP_EARLY_SHIFT_START}</th>
							<th>{phrases.MODAL_BATCH_STEP_EARLY_SHIFT_END}</th>
							<th>{phrases.MODAL_BATCH_STEP_LATE_SHIFT_START}</th>
							<th>{phrases.MODAL_BATCH_STEP_LATE_SHIFT_END}</th>
							<th>{phrases.MODAL_BATCH_STEP_BREAK_TOO_SHORT}</th>
							<th>{phrases.MODAL_BATCH_STEP_BREAK_TOO_LONG}</th>

							<th />
						</tr>
					</thead>
					<tbody>
						{batchList.map((entry, i) => {
							const deleteButtonDisabled = batchList.length === 1;

							return (
								<TableListRow key={entry.identifier}>
									<TableListCell>{i + 1}</TableListCell>

									<TableListCell>
										<InputCollectionSelect
											id={`name-${entry.identifier}`}
											placeholder="Select market..."
											clearable={false}
											value={entry?.market || null}
											handleChange={(key, value) =>
												this._editListEntry(value, entry, 'market')
											}
											optionFormat={(entry) => ({
												value: entry,
												label: entry.name,
											})}
											required
											params={{
												filter: this._getMarketFilter(),
											}}
											apiPath="/administration/markets"
											tableTitle="Markets"
											tableColumns={[
												{
													Header: 'Name',
													id: 'name',
													accessor: 'name',
													filterPath: ':name',
												},
												{
													Header: 'Country',
													id: 'country',
													accessor: (d) => _get(d, 'country.name', ''),
													filterPath: ':country.name',
												},
												{
													Header: 'Market region',
													id: 'market_region',
													accessor: (d) => _get(d, 'market_region.name', ''),
													filterPath: ':market_region.name',
												},
												{
													Header: 'Tax label',
													id: 'tax_label',
													accessor: 'tax_label',
													filterPath: ':tax_label',
												},
												{
													Header: 'Opened',
													id: 'opened',
													accessor: 'opened',
													filterPath: ':opened',
													Filter: ({ column }) => (
														<DateFilterSelector
															reduxKey="/administration/markets-markets_market-grace-periods-batch-modal"
															columnId={column.id}
															dateIdentifier="from"
															zIndex={600}
														/>
													),
												},
												{
													Header: 'Closed',
													id: 'closed',
													accessor: 'closed',
													filterPath: ':closed',
													Filter: ({ column }) => (
														<DateFilterSelector
															reduxKey="/administration/markets-markets_market-grace-periods-batch-modal"
															columnId={column.id}
															dateIdentifier="to"
															zIndex={600}
														/>
													),
												},
											]}
											tableReduxKey="/administration/markets-markets_market-grace-periods-batch-modal"
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`early-shift-start-${entry.identifier}`}
											type="number"
											clearable={false}
											placeholder="Enter early shift start..."
											value={entry.earlyShiftStart}
											onChange={(event) =>
												this._editListEntry(event, entry, 'earlyShiftStart')
											}
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`early-shift-end-${entry.identifier}`}
											type="number"
											clearable={false}
											placeholder="Enter early shift end..."
											value={entry.earlyShiftEnd}
											onChange={(event) =>
												this._editListEntry(event, entry, 'earlyShiftEnd')
											}
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`late-shift-start-${entry.identifier}`}
											type="number"
											clearable={false}
											placeholder="Enter late shift start..."
											value={entry.lateShiftStart}
											onChange={(event) =>
												this._editListEntry(event, entry, 'lateShiftStart')
											}
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`late-shift-end-${entry.identifier}`}
											type="number"
											clearable={false}
											placeholder="Enter late shift end..."
											value={entry.lateShiftEnd}
											onChange={(event) =>
												this._editListEntry(event, entry, 'lateShiftEnd')
											}
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`break-too-short-${entry.identifier}`}
											type="number"
											clearable={false}
											placeholder="Enter break too short..."
											value={entry.breakTooShort}
											onChange={(event) =>
												this._editListEntry(event, entry, 'breakTooShort')
											}
										/>
									</TableListCell>

									<TableListCell>
										<Input
											id={`break-too-long-${entry.identifier}`}
											type="number"
											clearable={false}
											placeholder="Enter break too long..."
											value={entry.breakTooLong}
											onChange={(event) =>
												this._editListEntry(event, entry, 'breakTooLong')
											}
										/>
									</TableListCell>

									<TableListCell>
										<Button
											type="negative"
											size="tiny"
											disabled={deleteButtonDisabled}
											onClick={() => this._onRemoveEntry(entry.identifier)}>
											<Icon name="delete" />
										</Button>
									</TableListCell>
								</TableListRow>
							);
						})}
					</tbody>
				</TableList>

				<ModalRow
					typeAddNewRow
					tooltipZIndex={600}
					tooltipText={phrases.MODAL_BATCH_STEP_LIST_TOOLTIP}
					onClick={addBatchListItemRow}
				/>
			</>
		);
	}
}

MarketGracePeriodsBatchStepList.propTypes = {
	batchList: PropTypes.array,
	editBatchListItem: PropTypes.func,
	removeBatchListItem: PropTypes.func,
	addBatchListItemRow: PropTypes.func,
};

export default MarketGracePeriodsBatchStepList;
