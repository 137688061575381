export const SET_DAY_OCCUPATION = 'SHIFTPLANNER_DAY_REVIEW/SET_DAY_OCCUPATION';

// export const DAYS_OVERVIEW_FETCH_SUCCESS =
// 	'SHIFTPLANNER_DAY_REVIEW/DAYS_OVERVIEW_FETCH_SUCCESS';

export const SET_REVIEW_COMMENT = 'SHIFTPLANNER_DAY_REVIEW/SET_REVIEW_COMMENT';
export const DAY_SELECTED = 'SHIFTPLANNER_DAY_REVIEW/DAY_SELECTED';
export const WORKPLACE_SELECTED = 'SHIFTPLANNER_DAY_REVIEW/WORKPLACE_SELECTED';
export const RESET_STATE = 'SHIFTPLANNER_DAY_REVIEW/RESET_STATE';
export const LOADING = 'SHIFTPLANNER_DAY_REVIEW/LOADING';
export const SET_DAY_REVIEW_VISIBLE =
	'SHIFTPLANNER_DAY_REVIEW/SET_DAY_REVIEW_VISIBLE';
export const SET_CLOCKINGS_DATA = 'SHIFTPLANNER_DAY_REVIEW/SET_CLOCKINGS_DATA';
export const UPDATE_CLOCKING_DATA =
	'SHIFTPLANNER_DAY_REVIEW/UPDATE_CLOCKING_DATA';
export const REMOVE_CLOCKING_BREAK =
	'SHIFTPLANNER_DAY_REVIEW/REMOVE_CLOCKING_BREAK';
export const REMOVE_CLOCKING = 'SHIFTPLANNER_DAY_REVIEW/REMOVE_CLOCKING';
export const UPDATE_SHIFT_REVIEW =
	'SHIFTPLANNER_DAY_REVIEW/UPDATE_SHIFT_REVIEW';
export const SET_CLOCK_OUT_TIME = 'SHIFTPLANNER_DAY_REVIEW/SET_CLOCK_OUT_TIME';
export const UPDATE_ROGUE_CLOCKING =
	'SHIFTPLANNER_DAY_REVIEW/UPDATE_ROGUE_CLOCKING';
export const REMOVE_ASSOCIATED = 'SHIFTPLANNER_DAY_REVIEW/REMOVE_ASSOCIATED';
export const ADD_SHIFTS_TO_REVIEW =
	'SHIFTPLANNER_DAY_REVIEW/ADD_SHIFTS_TO_REVIEW';
export const ADD_CLOCKINGS_TO_REVIEW =
	'SHIFTPLANNER_DAY_REVIEW/ADD_CLOCKINGS_TO_REVIEW';

/**
 * @param {boolean} isVisible
 */
export function toggleDayReview(payload) {
	return {
		type: SET_DAY_REVIEW_VISIBLE,
		payload,
	};
}

// export function dayReviewFetchSuccess(dayReview) {
// 	return {
// 		type: DAYS_OVERVIEW_FETCH_SUCCESS,
// 		payload: dayReview
// 	};
// }

export function setSelectedDate(day) {
	return {
		type: DAY_SELECTED,
		payload: day,
	};
}

export function setWorkplaceId(workplaceId) {
	return {
		type: WORKPLACE_SELECTED,
		payload: workplaceId,
	};
}

export function setLoader(isLoading) {
	return {
		type: LOADING,
		payload: isLoading,
	};
}

export function setDayOccupation(id, occupation) {
	return {
		type: SET_DAY_OCCUPATION,
		payload: { id, occupation },
	};
}

export function setComment(comment) {
	return {
		type: SET_REVIEW_COMMENT,
		payload: comment,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}

/**
 * @param {object} dataObject
 */
export function setClockingsData(payload) {
	return {
		type: SET_CLOCKINGS_DATA,
		payload,
	};
}

/**
 * @param {object} dataObject
 */
export function updateShiftReview(payload) {
	return {
		type: UPDATE_SHIFT_REVIEW,
		payload,
	};
}

/**
 * @param {object} statusObjec
 */
export function updateClockingData(payload) {
	return {
		type: UPDATE_CLOCKING_DATA,
		payload,
	};
}

/**
 * @param {object} statusObjec
 */
export function setClockOutTime(payload) {
	return {
		type: SET_CLOCK_OUT_TIME,
		payload,
	};
}

/**
 * @param {object} breakObject
 */
export function removeClockingBreak(payload) {
	return {
		type: REMOVE_CLOCKING_BREAK,
		payload,
	};
}

/**
 * @param {number} clockingId
 */
export function removeReviewClocking(payload) {
	return {
		type: REMOVE_CLOCKING,
		payload,
	};
}

/**
 * @param {number} clockingId
 */
export function updateRogueClocking(payload) {
	return {
		type: UPDATE_ROGUE_CLOCKING,
		payload,
	};
}

/**
 * @param {number} personId
 */
export function removeAssociatedShiftsClockings(payload) {
	return {
		type: REMOVE_ASSOCIATED,
		payload,
	};
}

/**
 * @param {number} shifts
 */
export function addShiftsToReview(payload) {
	return {
		type: ADD_SHIFTS_TO_REVIEW,
		payload,
	};
}

/**
 * @param {number} clockings
 */
export function addClockingsToReview(payload) {
	return {
		type: ADD_CLOCKINGS_TO_REVIEW,
		payload,
	};
}
