import React from 'react';

// lodash
import _get from 'lodash/get';

import { DateFilterSelector } from 'reactDataWrapper/utilities';

type Props = {
	reduxKey: string;
};

const columns = ({ reduxKey }: Props) => {
	return [
		{
			Header: 'Name',
			id: 'name',
			accessor: 'name',
			filterPath: ':name',
		},
		{
			Header: 'Description',
			id: 'description',
			accessor: 'description',
			filterPath: ':description',
		},
		{
			Header: 'Productivity',
			id: 'productivity',
			accessor: 'productivity',
			filterPath: ':productivity',
		},
		{
			Header: 'Badge',
			id: 'badge',
			accessor: 'badge',
			filterPath: ':badge',
		},
		{
			Header: 'Team position category',
			id: 'team-position-category',
			accessor: 'team_position_category',
			filterPath: ':team_position_category',
		},
		{
			Header: 'Role',
			id: 'role',
			accessor: (d: IHrMoneyballSubPositions) => _get(d, 'role.name', ''),
			filterPath: ':role.name',
		},
		{
			Header: 'Sort order',
			id: 'sort-order',
			accessor: 'sort_order',
			filterPath: ':sort_order',
		},
		{
			Header: 'Can train',
			id: 'can-train',
			accessor: 'can_train',
			filterPath: ':can_train',
			filterable: false,
			Cell: (d: IHrMoneyballSubPositions) => {
				return (
					<input type="checkbox" disabled checked={d.original?.can_train} />
				);
			},
		},
		{
			Header: 'Active from',
			id: 'active-from',
			accessor: (d: IHrMoneyballSubPositions) => _get(d, 'active.from', ''),
			filterPath: ':active.from',
			Filter: ({
				column,
			}: {
				column: {
					id: string;
				};
			}) => (
				<DateFilterSelector
					reduxKey={reduxKey}
					columnId={column.id}
					dateIdentifier="from"
				/>
			),
		},
		{
			Header: 'Active to',
			id: 'active-to',
			accessor: (d: IHrMoneyballSubPositions) => _get(d, 'active.to', ''),
			filterPath: ':active.to',
			Filter: ({ column }: { column: { id: string } }) => (
				<DateFilterSelector
					reduxKey={reduxKey}
					columnId={column.id}
					dateIdentifier="to"
				/>
			),
		},
	];
};

export default columns;
