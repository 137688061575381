export const UPDATE_BATCH_FORM = 'MARKET_SHIFT_RULES/UPDATE_BATCH_FORM';
export const ADD_BATCH_LIST = 'MARKET_SHIFT_RULES/ADD_BATCH_LIST';
export const EDIT_BATCH_LIST_ITEM = 'MARKET_SHIFT_RULES/EDIT_BATCH_LIST_ITEM';
export const REMOVE_BATCH_LIST_ITEM =
	'MARKET_SHIFT_RULES/REMOVE_BATCH_LIST_ITEM';
export const RESET_STATE = 'MARKET_SHIFT_RULES/RESET_STATE';

export function updateBatchForm(payload) {
	return {
		type: UPDATE_BATCH_FORM,
		payload,
	};
}

export function addBatchList(payload) {
	return {
		type: ADD_BATCH_LIST,
		payload,
	};
}

export function editBatchListItem(payload) {
	return {
		type: EDIT_BATCH_LIST_ITEM,
		payload,
	};
}

export function removeBatchListItem(payload) {
	return {
		type: REMOVE_BATCH_LIST_ITEM,
		payload,
	};
}

export function resetState() {
	return {
		type: RESET_STATE,
	};
}
