'use strict';

import cx from 'classnames';
import _each from 'lodash/each';
import _findIndex from 'lodash/findIndex';
import _has from 'lodash/has';
import _remove from 'lodash/remove';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class ColumnSelect extends Component {
	constructor(props) {
		super(props);
	}

	/**
	 * @function _getColGroups
	 * @memberOf DIRECTIVES.jtjList.component.headers
	 * @description Returns the colGroups to render column groups based on
	 * @return {array} array containing the colGroups if any
	 */
	_getColGroups() {
		var colGroups = [];
		_each(this.props.headers, function(header) {
			// Do we have colGroup in header?
			if (_has(header, 'colGroup')) {
				// Is colGroup existing in array already? - then add to counter
				var colGroupIndex = _findIndex(colGroups, function(cG) {
					return cG.name === header.colGroup;
				});
				if (header.emptyColGroup) colGroups.push({ empty: true });
				else if (colGroupIndex > -1)
					colGroups[colGroupIndex].length = colGroups[colGroupIndex].length + 1;
				// Or not - then create
				else {
					colGroups.push({
						name: header.colGroup,
						label: header.colGroupLabel,
						length: 1
					});
				}
			}
		});
		return colGroups;
	}

	/**
	 * @function _renderColGroups
	 * @memberOf DIRECTIVES.jtjList.component.headers
	 * @description Renders column groups
	 */
	_renderColGroups() {
		var colGroupBrutto = this._getColGroups();
		var colGroupNetto = this._getColGroups();
		_remove(colGroupNetto, 'empty');
		if (colGroupNetto.length > 0) {
			var colGroupsRender = _map(
				colGroupBrutto,
				function(group, key) {
					if (group.empty)
						return <th key={key} className="list__header-cell" />;
					else {
						return (
							<th
								key={key}
								colSpan={group.length}
								className="list__header-cell list__header-cell--colgroup">
								<span className="list__cell-contents">{group.label}</span>
							</th>
						);
					}
				}
			);

			return <tr className="list__row--colgroup">{colGroupsRender}</tr>;
		}
	}

	/**
	 * @function _renderColumns
	 * @memberOf jtjList.component.default
	 * @description Renders columns
	 */
	_renderColumns() {
		var AllHeaders = _map(
			this.props.headers,
			function(cell, key) {
				var cellClassNames = cx('list__header-cell', {
					'list__header-cell--hidden': _has(cell, 'localHidden')
						? cell.localHidden
						: cell.hidden,
					'list__header-cell--visible': _has(cell, 'localHidden')
						? !cell.localHidden
						: !cell.hidden
				});
				var headerText = cell.label.replace(/ /g, '\xA0');
				if (
					!cell.onlyFilterBar &&
					!cell.alwaysHidden &&
					cell.type !== 'action'
				) {
					return (
						<th
							key={key}
							onClick={this.props.methods.handleToggleColumn.bind(this, cell)}
							className={cellClassNames}>
							<span className="list__cell-contents">{headerText}</span>
						</th>
					);
				}
			}.bind(this)
		);
		return (
			<thead>
				{this._renderColGroups()}
				<tr>{AllHeaders}</tr>
			</thead>
		);
	}

	render() {
		if (this.props.isColumnSelecting) {
			return (
				<table className="list list--tiny columnselect__list-wrapper">
					{this._renderColumns()}
				</table>
			);
		} else return null;
	}
}

ColumnSelect.propTypes = {
	headers: PropTypes.array.isRequired,
	columnSelect: PropTypes.bool,
	methods: PropTypes.object.isRequired
};

export default ColumnSelect;
