import _get from 'lodash/get';

/**
 * @function getWaitingTimeValue
 * @description returns the value of of the average waiting time in minutes
 * @param {Object} entry
 * @param {String} day
 * @returns {Integer} val - seconds divided by 60 to get it in minutes or null if value isnt present
 */
export default function getWaitingTimeValue(entry, day) {
	const val = _get(entry, `${day}.average_waiting_time`, null);
	return val ? val / 60 : val;
}
