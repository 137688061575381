'use strict';

export default {
	PRINT_VIEW: 'Export view',
	CONTROLS: {
		PDF: 'PDF',
		XLSX: 'XLSX',
		MAIL: 'Mail'
	}
};
