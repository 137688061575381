'use strict';

import Papa from 'papaparse';
import * as translations from 'translations.service';
import { get, post, patch, remove } from 'api.vanilla.service';
import moment from 'moment';
import _intersection from 'lodash/intersection';
import _omit from 'lodash/omit';
import _last from 'lodash/last';
import _mapValues from 'lodash/mapValues';
import _has from 'lodash/has';
import _each from 'lodash/each';
import _clone from 'lodash/clone';
import _size from 'lodash/size';
import _pickBy from 'lodash/pickBy';
import _isEmpty from 'lodash/isEmpty';
import _isNull from 'lodash/isNull';
import { saveAs } from 'file-saver';
import { set as setFeedback } from 'feedback.vanilla.service';

import fetchCompleteCollection from 'services/api/fetchCompleteCollection/fetchCompleteCollection';

import * as actions from './../actions/goldModel.actions';
import { store } from 'appState';

// Services
const __apiFilter = require('apiFilter.service');
const __endpoints = require('endpoints.service');

export default class GoldModel {
	constructor() {
		this.init();

		return this;
	}

	/// //////////////////
	// Helpers, shared //
	/// //////////////////
	collectionFormat(collection) {
		return require('helpers/collectionFormat').call(this, collection);
	}

	fetchCompleteCollection(endpoint, params, headers) {
		return fetchCompleteCollection(endpoint, params, headers);
	}

	fetchCollection(endpoint, params, headers) {
		return require('helpers/fetchCollection').call(
			this,
			endpoint,
			params,
			headers
		);
	}

	itemFormat(item) {
		return require('helpers/itemFormat').call(this, item);
	}

	/// ///////////////////
	// Helpers, private //
	/// ///////////////////
	/**
	 * @function getDateRange
	 * @memberOf SERVICES.__goldModel
	 * @description helper for getting dateRange based on hotbar dateRange filter
	 */
	getDateRange() {
		const period = this.getState().hotbarFilter.Period;
		store.dispatch(
			actions.setDateRange({
				afterDate: period.getStartDate().clone(),
				beforeDate: period.getEndDate().clone(),
			})
		);
	}

	/**
	 * @function getWorkplaceFilterFields
	 * @memberOf SERVICES.__goldModel
	 * @description helper for getting filter fields that describes workplaces based on markets
	 */
	getWorkplaceFilterFields() {
		// Add params and search filters from hotbar
		let hotbarWorkplaceMarkets = this.getState().hotbarFilter.Markets.results;
		hotbarWorkplaceMarkets = hotbarWorkplaceMarkets.map((entry) => ({
			field: 'workplace.market.id',
			operator: '==',
			data: entry.value,
			joiningOperator: ';',
		}));
		return hotbarWorkplaceMarkets;
	}

	/**
	 * @function getActualFilter
	 * @memberOf SERVICES.__goldModel
	 * @description helper for getting filter that describes deposits to get from API
	 */
	getMonthlyFilter(afterDate, beforeDate, division = null) {
		const workplaceId = this.getState().hotbarFilter.Markets.results[0].value;
		const perspective = this.getState().hotbarFilter.View.results[0].value;
		let filter = `((:year=gt='${afterDate.format(
			'YYYY'
		)}';:year=lt='${beforeDate.format(
			'YYYY'
		)}'),((:month=ge='${afterDate.format('M')}';:year=='${afterDate.format(
			'YYYY'
		)}');(:month=le='${beforeDate.format('M')}';:year=='${beforeDate.format(
			'YYYY'
		)}')),('${afterDate.format('YYYY')}'!='${beforeDate.format(
			'YYYY'
		)}';:month=ge='${afterDate.format('M')}';:year=='${afterDate.format(
			'YYYY'
		)}'),('${afterDate.format('YYYY')}'!='${beforeDate.format(
			'YYYY'
		)}';:month=le='${beforeDate.format('M')}';:year=='${beforeDate.format(
			'YYYY'
		)}'));:workplace.market.id=='${workplaceId}'`;
		if (division) filter = filter + `;:perspective=='${perspective}'`;
		return { filter };
	}

	/**
	 * @function getActualFilter
	 * @memberOf SERVICES.__goldModel
	 * @description helper for getting filter that describes deposits to get from API
	 */
	getDailyFilter(afterDate, beforeDate) {
		const dayFilterBase = [
			{
				joiningOperator: ',',
				fields: [
					{
						field: 'date',
						operator: '=ge=',
						data: afterDate.format('YYYY-MM-DD'),
					},
				],
			},
			{
				joiningOperator: ';',
				fields: [
					{
						field: 'date',
						operator: '=le=',
						data: beforeDate.format('YYYY-MM-DD'),
					},
				],
			},
			{
				fields: this.getWorkplaceFilterFields(),
			},
		];
		const dayFilter = __apiFilter.create(dayFilterBase);
		return { filter: dayFilter };
	}

	/**
	 * @function getForecastFilter
	 * @memberOf SERVICES.__goldModel
	 * @description helper for getting filter that describes deposits to get from API
	 */
	getForecastFilter() {
		const hotbarDateFilter = this.getState().hotbarFilter.dateRange;
		const dateFilterBase = [
			{
				joiningOperator: ';',
				fields: [
					...hotbarDateFilter,
					{
						field: 'type',
						operator: '==',
						data: 'Deposit bag',
					},
				],
			},
			{
				fields: this.getWorkplaceFilterFields(),
			},
		];
		const dateFilter = __apiFilter.create(dateFilterBase);
		return { filter: dateFilter };
	}

	/**
	 * @function getBars
	 * @memberOf SERVICES.__goldModel
	 * @description helper for getting complete list of bars, based on hot bar parameters
	 */
	getBars() {
		// Add params and search filters from hotbar
		let hotbarBarsFilterFields = this.getState().hotbarFilter.Markets.results;
		hotbarBarsFilterFields = hotbarBarsFilterFields.map((entry) => ({
			field: 'market',
			operator: '==',
			data: entry.value,
			joiningOperator: ';',
		}));
		hotbarBarsFilterFields[hotbarBarsFilterFields.length - 1] = _omit(
			_last(hotbarBarsFilterFields),
			'joiningOperator'
		);

		return get('/administration/stores', {
			filter: __apiFilter.create([{ fields: hotbarBarsFilterFields }]),
			sort: ':sort_order+',
			limit: 300,
		}).then((response) => {
			return response.data.reduce(
				(acc, obj) => ({ ...acc, [obj.id]: obj }),
				{}
			);
		});
	}

	/**
	 * @function getDates
	 * @memberOf SERVICES.__goldModel
	 * @description helper for getting complete list of bars, based on hot bar parameters
	 */
	getDates() {
		const state = this.getState();
		const periodType = state.periodType;
		const dateRange = state.dateRange;
		const dates = {};

		// Make date dynamic based on const afterDate
		let date = dateRange.afterDate;
		switch (periodType) {
			case 'daily':
				// Add date as property while lower than beforeDate
				while (date <= dateRange.beforeDate) {
					dates[date.format('DD-MM-YYYY')] = {};
					date = date.clone().add(1, 'day');
				}
				break;
			case 'monthly':
				// Add date as property while lower than beforeDate
				while (date <= dateRange.beforeDate) {
					dates[date.format('MM-YYYY')] = {};
					date = date.clone().add(1, 'month');
				}
				break;
		}
		return dates;
	}

	/**
	 * @function initMatrice
	 * @memberOf SERVICES.__goldModel
	 * @description helper initializing matrice with dates and bars
	 */
	initMatrice() {
		return new Promise((resolve, reject) => {
			// Make matrice ready
			const matrice = {};

			// Get dates
			const dates = this.getDates();

			// Get list of relevant bars and move on
			this.getBars().then((bars) => {
				_each(bars, (bar) => {
					matrice[bar.id] = {
						name: bar.name,
						id: bar.id,
						sort_order: bar.sort_order,
						dates: Object.assign({}, dates),
					};
				});

				// Resolve matrice
				resolve(matrice);
			});
		});
	}

	/**
	 * @function handleFetchCsv
	 * @memberOf SERVICES.__goldModel
	 * @description Handler for downloading csv
	 */
	handleFetchCsv() {
		// SOM SOY = forecast

		const csvHeader = {
			Accept: 'application/csv',
		};

		const perspective = this.getState().hotbarFilter.View.results[0].value;
		const csvEndpoint =
			perspective === 'SOM' || perspective === 'SOY' ? 'forecasts' : 'actuals';

		return this.getData({ csvHeader, csvEndpoint }).then((csv) => {
			const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
			const fileName = `gold_model-${moment().format('YYYYMMDD_HHmmss')}.csv`;
			saveAs(blob, fileName);
		});
	}

	/**
	 * @function getData
	 * @memberOf SERVICES.__goldModel
	 * @description helper for getting complete dataset, based on hot bar parameters and both actuals and forecasts
	 */
	getData(csvData) {
		return new Promise((resolve) => {
			const state = this.getState();
			const settings = this.getSettings();
			const periodType = state.periodType;

			// Base for collection
			let data = [];

			// Figure out the division of actuals and forecasts in dataset, based on dateRange and todays date
			const today = moment();
			const dateRange = state.dateRange;
			const isTodayInDateRange = today.isBetween(
				dateRange.afterDate,
				dateRange.beforeDate
			);
			let division;
			let params;
			let endpoint;
			if (isTodayInDateRange) division = 'combined';
			else if (today.isBefore(dateRange.afterDate)) division = 'forecasts';
			else if (today.isAfter(dateRange.beforeDate)) division = 'actuals';

			// Override endpoint if fetching a csv
			if (csvData && csvData.csvEndpoint) division = csvData.csvEndpoint;

			// Create collection of filters to consume based on division (actuals, forecasts / single, combined)
			const dateFilters = {
				single: {
					actuals: {
						daily: this.getDailyFilter(
							dateRange.afterDate,
							dateRange.beforeDate
						),
						monthly: this.getMonthlyFilter(
							dateRange.afterDate,
							dateRange.beforeDate
						),
					},
					forecasts: {
						daily: this.getDailyFilter(
							dateRange.afterDate,
							dateRange.beforeDate
						),
						monthly: this.getMonthlyFilter(
							dateRange.afterDate,
							dateRange.beforeDate,
							division
						),
					},
				},
				combined: {
					actuals: {
						daily: this.getDailyFilter(
							dateRange.afterDate,
							today.clone().add(-1, 'month')
						),
						monthly: this.getMonthlyFilter(
							dateRange.afterDate,
							today.clone().add(-1, 'month')
						),
					},
					forecasts: {
						daily: this.getDailyFilter(
							today.clone().startOf('month'),
							dateRange.beforeDate
						),
						monthly: this.getMonthlyFilter(
							today,
							dateRange.beforeDate,
							division
						),
					},
				},
			};

			// Get
			switch (division) {
				case 'actuals': {
					// Get params (filter) and endpoint for actual data
					params = Object.assign(
						{},
						state.params,
						dateFilters.single.actuals[periodType]
					);
					endpoint = settings.endpoints.actuals[periodType];
					break;
				}
				case 'forecasts': {
					// Get params (filter) and endpoint for forecast data
					params = Object.assign(
						{},
						state.params,
						dateFilters.single.forecasts[periodType]
					);
					endpoint = settings.endpoints.forecasts[periodType];
					break;
				}
				case 'combined': {
					// Get params (filter) and endpoint(s) for combined data
					params = {
						actuals: Object.assign(
							{},
							state.params,
							dateFilters.combined.actuals[periodType]
						),
						forecasts: Object.assign(
							{},
							state.params,
							dateFilters.combined.forecasts[periodType]
						),
					};
					endpoint = {
						actuals: settings.endpoints.actuals[periodType],
						forecasts: settings.endpoints.forecasts[periodType],
					};
					break;
				}
			}
			switch (division) {
				case 'actuals':
				case 'forecasts': {
					this.fetchCompleteCollection(
						endpoint.index.path,
						params,
						csvData ? csvData.csvHeader : null
					).then((collectionData) => {
						resolve(collectionData);
					});
					break;
				}
				case 'combined': {
					this.fetchCompleteCollection(
						endpoint.actuals.index.path,
						params.actuals,
						csvData ? csvData.csvHeader : null
					)
						.then((collectionData) => {
							return this.fetchCompleteCollection(
								endpoint.forecasts.index.path,
								params.forecasts,
								csvData ? csvData.csvHeader : null
							);
						})
						.then((collectionData) => {
							data = csvData
								? data + collectionData
								: [...data, ...collectionData];
							resolve(data);
						});
					break;
				}
			}
		});
	}

	/**
	 * @function mergeDataIntoMatrice
	 * @memberOf SERVICES.__goldModel
	 * @description helper for merging all deposit data into matrice
	 */
	mergeDataIntoMatrice(data) {
		return new Promise((resolve) => {
			const state = this.getState();
			const periodType = state.periodType;

			// Get copy of matrice in state
			const matrice = _mapValues(this.getState().matrice, _clone);

			// Loop through all deposits and place them in the matrice
			switch (periodType) {
				case 'daily':
					_each(data, (d) => {
						if (_has(matrice, d.workplace.id)) {
							const date = moment(`${d.date}`, 'YYYY-MM-DD').format(
								'DD-MM-YYYY'
							);
							if (_has(matrice[d.workplace.id].dates, date)) {
								matrice[d.workplace.id].dates[date] = d;
								matrice[d.workplace.id].workplace = d.workplace;
							}
						}
					});
					break;
				case 'monthly':
					_each(data, (d) => {
						if (_has(matrice, d.workplace.id)) {
							const date = moment(`${d.year}-${d.month}`, 'YYYY-MM').format(
								'MM-YYYY'
							);
							if (_has(matrice[d.workplace.id].dates, date)) {
								matrice[d.workplace.id].dates[date] = d;
								matrice[d.workplace.id].workplace = d.workplace;
							}
						}
					});
					break;
			}
			resolve(matrice);
		});
	}

	/**
	 * @function transformMatrice
	 * @memberOf SERVICES.__goldModel
	 * @description helper for merting all deposit data into matrice
	 */
	transformMatrice(matrice) {
		return new Promise((resolve) => {
			// Transform matrice (object) to "normal" collection
			const collection = Object.keys(matrice)
				.map((key) => {
					return Object.assign({}, { id: key }, matrice[key]);
				})
				.sort((a, b) => {
					// Return collection to sort order
					if (a.sort_order < b.sort_order) return -1;
					if (a.sort_order > b.sort_order) return 1;
					return 0;
				});

			// Resolve and return
			resolve(collection);
		});
	}

	/**
	 * @function getProfitLossItem
	 * @memberOf SERVICES.__goldModel
	 * @description helper for getting the profit and loss item for data object key
	 */
	getProfitLossItem(row) {
		if (_has(row, 'P&L_Item')) {
			switch (row['P&L_Item'].toLowerCase()) {
				case 'turnover':
					return 'turnover';
				case 'cost of goods sold':
					return 'cgs';
				case 'salary':
					return 'salary';
				case 'direct operational cost':
					return 'doc';
				case 'location cost':
					return 'location';
				default:
					return row['P&L_Item'].toLowerCase();
			}
		}
	}

	/// //////////////////////////
	// Model specific handlers //
	/// //////////////////////////
	/**
	 * @function handleEditTag
	 * @memberOf SERVICES.__goldModel
	 * @description handler for adding tag
	 */
	handleUpdateTag(id, data) {
		return new Promise((resolve, reject) => {
			// Get settings
			const settings = this.getSettings();

			// Build tag data
			const tagData = {
				id,
				period: {
					from: data.from,
					to: data.to,
				},
			};

			// Post data
			return post(settings.endpoints.goldModelTags.update.path + id, tagData)
				.then((data) => {
					setFeedback(translations.getSingle('COMPONENTS.DETAILS.UPDATED'), 1);
					resolve(data.data[0]);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	/// //////////////////////////
	// Model specific handlers //
	/// //////////////////////////
	/**
	 * @function handleAddTag
	 * @memberOf SERVICES.__goldModel
	 * @description handler for adding tag
	 */
	handleAddTag(data) {
		return new Promise((resolve, reject) => {
			// Get settings
			const settings = this.getSettings();

			// Build tag data
			const tagData = {
				workplace: data.workplace,
				tag: data.tag,
				period: {
					from: data.from,
					to: data.to,
				},
			};

			// Post data
			return post(settings.endpoints.goldModelTags.create.path, tagData)
				.then((data) => {
					setFeedback(translations.getSingle('COMPONENTS.DETAILS.CREATED'), 1);
					resolve(data.data[0]);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	/**
	 * @function handleHotbarChange
	 * @memberOf SERVICES.__goldModel
	 * @description handler for when hotbar filter changes
	 */
	handleHotbarChange({ results }) {
		store.dispatch(actions.setHotBarFilter(results));
		this.getDateRange();
		this.initView();
	}

	/**
	 * @function handleGetDetails
	 * @memberOf SERVICES.__goldModel
	 * @description handler for loading details from actuals and forecasts, based on id
	 */
	handleGetDetails(data) {
		return new Promise((resolve) => {
			const settings = this.getSettings();
			const periodType = this.getState().periodType;
			const perspective = this.getState().hotbarFilter.View.results[0].value;
			const dataDetails = {};
			let filterBase;
			switch (periodType) {
				case 'daily':
					filterBase = [
						{
							fields: [
								{
									field: 'date',
									operator: '==',
									data: moment(data.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
									joiningOperator: ';',
								},
								{
									field: 'workplace.id',
									operator: '==',
									data: data.workplace,
								},
							],
						},
					];
					break;
				case 'monthly':
					filterBase = [
						{
							fields: [
								{
									field: 'month',
									operator: '==',
									data: data.month,
									joiningOperator: ';',
								},
								{
									field: 'year',
									operator: '==',
									data: data.year,
									joiningOperator: ';',
								},
								{
									field: 'workplace.id',
									operator: '==',
									data: data.workplace,
								},
							],
						},
					];
					break;
			}
			const params = { filter: __apiFilter.create(filterBase) };
			this.fetchCollection(
				settings.endpoints.actuals[periodType].index.path,
				params,
				true
			)
				.then((collectionData) => {
					dataDetails.actuals =
						_size(collectionData[0]) > 0 ? collectionData[0][0] : {};

					const filterBase = [
						{
							fields: [
								{
									field: 'month',
									operator: '==',
									data: data.month,
									joiningOperator: ';',
								},
								{
									field: 'year',
									operator: '==',
									data: data.year,
									joiningOperator: ';',
								},
								{
									field: 'workplace.id',
									operator: '==',
									data: data.workplace,
									joiningOperator: ';',
								},
								{
									field: 'perspective',
									operator: '==',
									data: perspective,
								},
							],
						},
					];
					const params = { filter: __apiFilter.create(filterBase) };

					return this.fetchCollection(
						settings.endpoints.forecasts[periodType].index.path,
						params,
						true
					);
				})
				.then((collectionData) => {
					dataDetails.forecasts =
						_size(collectionData[0]) > 0 ? collectionData[0][0] : {};
					resolve(dataDetails);
				});
		});
	}

	/**
	 * @function handleSubmitChange
	 * @memberOf SERVICES.__goldModel
	 * @description handler for submitting change
	 */
	handleSubmitChange(changeData, metaData) {
		return new Promise((resolve) => {
			const periodType = this.getState().periodType;
			const settings = this.getSettings();
			const perspective = this.getState().hotbarFilter.View.results[0].value;

			// Filter out any empty headers (null)
			const changedActuals = _pickBy(
				changeData.actuals,
				(value) => !_isNull(value)
			);
			const changedForecasts = _pickBy(
				changeData.forecasts,
				(value) => !_isNull(value)
			);

			// Set metaData if we are about to CREATE and not UPDATE (based on ID)
			const actualsMetaData = {
				workplace: metaData.workplace,
				year: metaData.year,
				month: metaData.month,
				date: metaData.date,
				adjusted: true,
				perspective,
			};
			const forecastsMetaData = {
				workplace: metaData.workplace,
				year: metaData.year,
				month: metaData.month,
				date: metaData.date,
				adjusted: true,
				perspective,
			};

			// Get endpoint for Actuals based on ID exists
			const actualsEndpointPath = _isNull(metaData.actualsId)
				? settings.endpoints.actuals[periodType].create.path
				: settings.endpoints.actuals[periodType].update.path +
				  metaData.actualsId;

			// Get endpoint for Forecasts based on ID exists
			const forecastsEndpointPath = _isNull(metaData.forecastsId)
				? settings.endpoints.forecasts[periodType].create.path
				: settings.endpoints.forecasts[periodType].update.path +
				  metaData.forecastsId;

			// Get Promise to use - either empty if nothing should be sent (no headers present) or a post method
			const actualsMethod = _isEmpty(changedActuals)
				? new Promise((resolve) => resolve())
				: post(
						actualsEndpointPath,
						Object.assign(changedActuals, actualsMetaData)
				  );
			const forecastsMethod = _isEmpty(changedForecasts)
				? new Promise((resolve) => resolve())
				: post(
						forecastsEndpointPath,
						Object.assign(changedForecasts, forecastsMetaData)
				  );

			// Update the two items and update matrice
			actualsMethod
				.then(() => {
					return forecastsMethod;
				})
				.then(() => {
					return this.initMatrice();
				})
				.then((matrice) => {
					store.dispatch(actions.setMatrice(matrice));
					return this.getData();
				})
				.then((data) => {
					return this.mergeDataIntoMatrice(data);
				})
				.then((matrice) => {
					store.dispatch(actions.setMatrice(matrice));
					return this.transformMatrice(matrice);
				})
				.then((collection) => {
					store.dispatch(actions.setCollection(collection));

					setFeedback(translations.getSingle('COMPONENTS.DETAILS.UPDATED'), 1);

					// Resolve
					resolve();
				});
		});
	}

	/**
	 * @function parseCsvEntry
	 * @memberOf SERVICES.__goldModel
	 * @description Helper function for parsing fields in csv. Stringified numbers should
	 */
	parseCsvEntry(entry = null, negate = false) {
		if (typeof entry === 'string') entry = entry.replace(',', '.');
		return negate ? -Number(entry) : Number(entry);
	}

	/**
	 * @function handleImportCsv
	 * @memberOf SERVICES.__goldModel
	 * @description handler for importing CSV files
	 */
	handleImportCsv(file, type, periodType, perspectiveType, ideal = false) {
		const settings = this.getSettings();
		const endpoint =
			type === 'forecasts'
				? settings.endpoints.forecasts.batch[periodType]
				: settings.endpoints.actuals.batch[periodType];

		return new Promise((resolve, reject) => {
			Papa.parse(file, {
				header: true,
				dynamicTyping: true,
				skipEmptyLines: true,
				newline: '',
				error: (error) => {
					reject(error);
				},
				complete: (results) => {
					const requiredValidation = !ideal
						? ['WorkplannerID', 'Year', 'Month', 'Day']
						: ['WorkplannerID', 'Year', 'Month', 'IdealSalaryPercentage'];

					const presentHeaders = _intersection(
						results.meta.fields,
						requiredValidation
					);

					if (requiredValidation.length !== presentHeaders.length) {
						// somethingsomething
						const errorMessage = !ideal
							? 'Make sure that the first columns are labeled "WorkplannerID;Year;Month;Day"'
							: 'Make sure that the first columns are labeled "WorkplannerID;Year;Month;IdealSalaryPercentage"';
						reject(errorMessage);
						return;
					}
					if (results.errors.length > 0) {
						reject(results.errors[0].message);
					} else {
						const data = results.data.map((row) => {
							if (ideal)
								return {
									workplace: row.WorkplannerID || null,
									year: row.Year,
									month: row.Month,
									ideal_salary_percentage: row.IdealSalaryPercentage,
								};
							return {
								workplace: row.WorkplannerID || null,
								turnover: this.parseCsvEntry(row.Turnover),
								cgs: this.parseCsvEntry(row.COGS, true),
								doc: this.parseCsvEntry(row.DOC, true),
								pos_turnover: this.parseCsvEntry(row.PosTurnover),
								delivery_turnover: this.parseCsvEntry(row.DeliveryTurnover),
								app_instore_turnover: this.parseCsvEntry(
									row.AppInstoreTurnover
								),
								app_loyalty_turnover: this.parseCsvEntry(
									row.AppLoyaltyTurnover
								),
								app_preorder_turnover: this.parseCsvEntry(
									row.AppPreorderTurnover
								),
								salary: this.parseCsvEntry(row.Salary, true),
								location: this.parseCsvEntry(row.Location, true),
								payback: this.parseCsvEntry(row.Payback),
								ideal_salary_percentage: row.IdealSalaryPercentage,
								...(type !== 'actuals' && {
									perspective: perspectiveType,
								}),
								...(periodType === 'daily'
									? {
											date: moment(
												`${row.Year}-${row.Month}-${row.Day}`,
												'YYYY-M-D'
											).format('YYYY-MM-DD'),
									  }
									: {
											year: row.Year,
											month: row.Month,
									  }),
							};
						});

						post(endpoint.create.path, { data })
							.then(
								() => {
									resolve();
								},
								(err) => {
									reject(err.data);
								}
							)
							.catch((err) => reject(err.data));
					}
				},
			});
		});
	}

	/**
	 * @function handleGetTags
	 * @memberOf SERVICES.__goldModel
	 * @description handler for getting tags list
	 */
	handleGetGoldModelTags(workplace, date) {
		const filter = this.handleGetGoldModelTagListFilter(workplace, date);

		return get('/financial/store_tags', { filter }).then((response) => {
			const tags = response?.data ?? [];
			store.dispatch(actions.updateWorkplace(tags, workplace));

			return tags;
		});
	}

	/**
	 * @function handleGetTagListFilter
	 * @memberOf SERVICES.__goldModel
	 * @description handler for getting filter for tags list
	 */
	handleGetTagListFilter() {
		return __apiFilter.create([
			{
				fields: [
					{
						field: 'type',
						operator: '==',
						data: 'Store',
					},
				],
			},
		]);
	}

	/**
	 * @function handleGetGoldModelTagListFilter
	 * @memberOf SERVICES.__goldModel
	 * @description handler for getting filter for tags list
	 */
	handleGetGoldModelTagListFilter(workplace, date) {
		const periodType = this.getState().periodType;
		let filter;
		switch (periodType) {
			case 'daily': {
				const compareDate = moment(date, 'MM-DD-YYYY').format('YYYY-MM-DD');
				filter = [
					{
						fields: [
							{
								field: 'workplace',
								operator: '==',
								data: workplace,
								joiningOperator: ';',
							},
							{
								field: 'period.from',
								operator: '=le=',
								data: compareDate,
								joiningOperator: ';',
							},
							{
								field: 'period.to',
								operator: '=ge=',
								data: compareDate,
							},
						],
					},
				];
				break;
			}
			case 'monthly': {
				const monthStart = moment(date, 'MM-YYYY').format('YYYY-MM-DD');
				const monthEnd = moment(date, 'MM-YYYY')
					.endOf('month')
					.format('YYYY-MM-DD');
				filter = [
					{
						fields: [
							{
								field: 'workplace',
								operator: '==',
								data: workplace,
								joiningOperator: ';',
							},
							{
								field: 'period.from',
								operator: '=lt=',
								data: monthEnd,
								joiningOperator: ';',
							},
							{
								field: 'period.to',
								operator: '=gt=',
								data: monthStart,
							},
						],
					},
				];
				break;
			}
		}
		return __apiFilter.create(filter);
	}

	/**
	 * @function handleRemoveTag
	 * @memberOf SERVICES.__goldModel
	 * @description handler for removing tag
	 */
	handleRemoveTag(tagId) {
		return new Promise((resolve, reject) => {
			// Get settings
			const settings = this.getSettings();

			// Post data
			return remove(settings.endpoints.goldModelTags.delete.path + tagId)
				.then(() => {
					setFeedback(translations.getSingle('COMPONENTS.DETAILS.DELETED'), 1);
					resolve();
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	/**
	 * @function handleSetPeriodType
	 * @memberOf SERVICES.__goldModel
	 * @description handler for setting period type
	 */
	handleSetPeriodType(type) {
		store.dispatch(actions.setPeriodType(type));
		this.initMatrice()
			.then((matrice) => {
				store.dispatch(actions.setMatrice(matrice));
				return this.getData();
			})
			.then((data) => {
				return this.mergeDataIntoMatrice(data);
			})
			.then((matrice) => {
				store.dispatch(actions.setMatrice(matrice));
				return this.transformMatrice(matrice);
			})
			.then((collection) => {
				store.dispatch(actions.setShowMatrice(true));
				store.dispatch(actions.setCollection(collection));
			});
	}

	/// ////////////////
	// Props = State //
	/// ////////////////
	/**
	 * @function getMethods
	 * @memberOf SERVICES.__goldModel
	 * @description returns handler methods for components
	 * @return {object} handler methods
	 */
	getMethods() {
		return {
			handleAddTag: this.handleAddTag.bind(this),
			handleGetGoldModelTags: this.handleGetGoldModelTags.bind(this),
			handleGetTagListFilter: this.handleGetTagListFilter.bind(this),
			handleGetGoldModelTagListFilter: this.handleGetTagListFilter.bind(this),
			handleSubmitChange: this.handleSubmitChange.bind(this),
			handleHotbarChange: this.handleHotbarChange.bind(this),
			handleGetDetails: this.handleGetDetails.bind(this),
			handleImportCsv: this.handleImportCsv.bind(this),
			handleFetchCsv: this.handleFetchCsv.bind(this),
			handleRemoveTag: this.handleRemoveTag.bind(this),
			handleUpdateTag: this.handleUpdateTag.bind(this),
			handleSetPeriodType: this.handleSetPeriodType.bind(this),
		};
	}

	/**
	 * @function getSettings
	 * @memberOf SERVICES.__goldModel
	 * @description returns settings for components
	 * @return {object} settings
	 */
	getSettings() {
		return this.settings;
	}

	/**
	 * @function getState
	 * @memberOf SERVICES.__goldModel
	 * @description returns state for components
	 * @return {object} current state
	 */
	getState() {
		return store.getState().goldModel;
	}

	/**
	 * @function getTranslations
	 * @memberOf SERVICES.__goldModel
	 * @description returns translations for components
	 * @return {object} translations
	 */
	getTranslations() {
		return translations.getObject([
			'COMPONENTS.GOLD_MODEL.CONTENT_TYPES.TURNOVER',
			'COMPONENTS.GOLD_MODEL.CONTENT_TYPES.LOCATION',
			'COMPONENTS.GOLD_MODEL.CONTENT_TYPES.SALARY',
			'COMPONENTS.GOLD_MODEL.CONTENT_TYPES.DOC',
			'COMPONENTS.GOLD_MODEL.CONTENT_TYPES.CGS',
			'COMPONENTS.GOLD_MODEL.CONFIRM',
			'COMPONENTS.GOLD_MODEL.CLOSE',
			'COMPONENTS.GOLD_MODEL.ACTUALS',
			'COMPONENTS.GOLD_MODEL.FORECASTS',
			'COMPONENTS.GOLD_MODEL.IMPORT',
			'COMPONENTS.GOLD_MODEL.TITLE',
			'COMPONENTS.GOLD_MODEL.TYPES',
			'COMPONENTS.GOLD_MODEL.IDEAL_IMPORT',
			'COMPONENTS.GOLD_MODEL.IMPORTS.PICK_CSV',
			'COMPONENTS.GOLD_MODEL.IMPORTS.UPLOAD_CSV',
			'COMPONENTS.GOLD_MODEL.IMPORTS.UPLOAD',
			'COMPONENTS.GOLD_MODEL.IMPORTS.BOARD',
			'COMPONENTS.GOLD_MODEL.IMPORTS.SOM',
			'COMPONENTS.GOLD_MODEL.IMPORTS.SOY',
			'COMPONENTS.GOLD_MODEL.IMPORTS.OPERATION',
			'COMPONENTS.GOLD_MODEL.IMPORTS.ERROR',
			'COMPONENTS.GOLD_MODEL.IMPORTS.SUCCESS',
			'COMPONENTS.GOLD_MODEL.TAGS.HEADING',
			'COMPONENTS.GOLD_MODEL.TAGS.ADD_TAG',
			'COMPONENTS.GOLD_MODEL.CSVEXAMPLE',
			'COMPONENTS.GOLD_MODEL.DOWNLOAD_EXAMPLE',
			'COMPONENTS.GOLD_MODEL.PERIOD_TYPES',
			'COMPONENTS.GOLD_MODEL.MONTHLY',
			'COMPONENTS.GOLD_MODEL.DAILY',
		]);
	}

	/**
	 * @function getProps
	 * @memberOf SERVICES.__goldModel
	 * @description returns props for components
	 * @return {object} props
	 */
	getProps() {
		return Object.assign(this.getState(), {
			methods: this.getMethods(),
			translations: this.getTranslations(),
		});
	}

	/// /////////////
	// Initiation //
	/// /////////////
	/**
	 * @function initView
	 * @memberOf SERVICES.__goldModel
	 * @description
	 * Initiates model
	 */
	initView() {
		store.dispatch(actions.setShowMatrice(false));
		this.initMatrice()
			.then((matrice) => {
				store.dispatch(actions.setMatrice(matrice));
				return this.getData();
			})
			.then((data) => {
				return this.mergeDataIntoMatrice(data);
			})
			.then((matrice) => {
				store.dispatch(actions.setMatrice(matrice));
				return this.transformMatrice(matrice);
			})
			.then((collection) => {
				store.dispatch(actions.setShowMatrice(true));
				store.dispatch(actions.setCollection(collection));
			});
	}

	/**
	 * @function init
	 * @memberOf SERVICES.__goldModel
	 * @description
	 * Initiates model
	 */
	init() {
		// Make defaultState this state
		this.state = { ...defaultState };

		// Merge defaultSettings this settings
		this.settings = { ...defaultSettings };
	}
}

/**
 * Set prototype reference for all subClasses
 */
GoldModel.prototype.constructor = GoldModel;

/// ////////
// State //
/// ////////

/**
 * Initial state
 * @type {Object}
 */
var defaultState = {
	collection: [],
	dateRange: {},
	hotbarFilter: [],
	matrice: {},
	params: {
		offset: 0,
		limit: 300,
	},
	showMatrice: false,
	periodType: 'monthly',
};

/**
 * Default settings
 * @type {Object}
 */
var defaultSettings = {
	endpoints: {
		actuals: {
			daily: __endpoints.collection.financial.profit_and_loss_daily_actuals,
			monthly: __endpoints.collection.financial.profit_and_loss_monthly_actuals,
			batch: {
				daily:
					__endpoints.collection.financial.profit_and_loss_daily_actuals_batch,
				monthly:
					__endpoints.collection.financial
						.profit_and_loss_monthly_actuals_batch,
			},
		},
		forecasts: {
			daily: __endpoints.collection.financial.profit_and_loss_daily_forecasts,
			monthly:
				__endpoints.collection.financial.profit_and_loss_monthly_forecasts,
			batch: {
				daily:
					__endpoints.collection.financial
						.profit_and_loss_daily_forecasts_batch,
				monthly:
					__endpoints.collection.financial
						.profit_and_loss_monthly_forecasts_batch,
			},
		},
		goldModelTags: __endpoints.collection.financial.store_tags,
	},
};
