import { get, post } from 'api.vanilla.service';
import { set as setFeedback } from 'feedback.vanilla.service.js';
import { formatErrorMessage } from 'api/helpers';

import phrases from './clockinDeviationApproval.phrases';
import enums from './clockinDeviationApproval.enums';

// redux actions/dispatchers
import { dispatchSetToggleClockinDeviationModalData } from './store/clockinDeviationApproval.actions';

// lodash
import _isEmpty from 'lodash/isEmpty';

export function fetchShiftClockinsDeviationApprovals({ userId }) {
	return get(
		`/shiftplanning/persons/${userId}/shift_clocking_deviation_approvals`
	)
		.then((res) => {
			if (!_isEmpty(res.data)) {
				dispatchSetToggleClockinDeviationModalData(res.data);
			}

			return res;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
			throw err;
		});
}

export function editShiftClockinsDeviationApproval({ deviationId, data }) {
	return post(
		`/shiftplanning/shift_clocking_deviation_approvals/${deviationId}`,
		data
	)
		.then((res) => {
			const feedbackText =
				data.status === enums.CONFIRMED
					? phrases.CLOCKIN_DEVIATION_APPROVED
					: phrases.CLOCKIN_DEVIATION_DECLINED;

			setFeedback(feedbackText, 1);
			return res;
		})
		.catch((err) => {
			const error = formatErrorMessage(err);
			setFeedback(error, 0);
			throw err;
		});
}
